import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  DataFilterValue,
  DateFilterRange,
  FiltersComponent,
  KeyedSelectionModel,
  ValueFilterSelection,
} from '@cohesity/helix';
import {AutoDestroyable, Memoize} from '@cohesity/utils';
import { TranslateService } from '@ngx-translate/core';
import moment, { Moment } from 'moment';
import { Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { DialogService, ScopeSelectorService } from 'src/app/core/services';
import { AvailableExtensionPoints } from 'src/app/plugin-shared';
import { Environment, RecoveryAction } from 'src/app/shared';
import {
  ProtectionGroupRunSelectorModalComponent,
  RunSelectorResponse,
} from '../../containers/protection-group-run-selector-modal/protection-group-run-selector-modal.component';
import {
  SnapshotSelectorModalComponent
} from '../../containers/snapshot-selector-modal/snapshot-selector-modal.component';
import { ProtectionGroupSearchResult } from '../../model/protection-group-search-result';
import { RestorePointSelection } from '../../model/restore-point-selection';
import { RestoreSearchResult } from '../../model/restore-search-result';
import { getVmIcon } from '../../model/vm-icon';
import { ObjectSearchService } from '../../services/object-search.service';

/**
 * Returns whether two RestoreSearchResult objects are equal by comparing relevant fields from both the objects.
 *
 * @param object1 The first object.
 * @param object2 The second object.
 *
 * @returns `true` if both supplied objects are equal, `false` otherwise.
 */
export function areRestoreObjectsEqual(object1: RestoreSearchResult, object2: RestoreSearchResult) {
  return object1 && object2 && object1.id === object2.id && object1.protectionGroupId === object2.protectionGroupId;
}

/**
 * Returns true if selection and snapshot are the same.
 *
 * @param selection  Selection of search result
 * @param snapshot  Snapshot from selection.
 * @returns True if selection and snapshot are the same.
 */
export function isSelectionEqual(selection: RestoreSearchResult, snapshot: RestorePointSelection): boolean {
  if (snapshot.objectInfo?.resultType === ProtectionGroupSearchResult.protectionGroupResultType) {
    return selection === snapshot.objectInfo;
  }
  return areRestoreObjectsEqual(selection.defaultRestorePointSelection?.objectInfo, snapshot.objectInfo);
}

/**
 * Async snapshot and latest point in time (for CDP) when selecting a restore search result.
 */
export interface SelectionSnapshot {
  snapshot$: Observable<RestorePointSelection>;
  latestPointInTime$: Observable<number>;
}

/**
 * This component allows searching for servers to browse.
 * Make sure objectSearchProvider is defined for the component that use it.
 */
@Component({
  selector: 'coh-search-servers',
  templateUrl: './search-servers.component.html',
  styleUrls: [ './search-servers.component.scss' ],
  encapsulation: ViewEncapsulation.None,
})
export class SearchServersComponent extends AutoDestroyable implements OnInit, AfterViewInit {
  /**
   * The util helps for the main recovery flow.
   */
  @Input() type: 'vms' | 'view' | 'dbs' = 'view';

  /**
   * Form control for selected restore point selections (snapshots/servers).
   */
  @Input() form: FormControl<RestorePointSelection[]>;

  /**
   * Get snapshot from selected restore search result.
   * In addition to get the default snapshot from selection (restore search result), this function also notifies
   * parent to retrieve data associated with selected snapshot (if user changes snapshot from the dialog), as well
   * as ask parent to provide the latest point in time in the CDP case.
   *
   * @param snapshot to be added.
   */
  @Input() getSnapshot: (
    selection?: RestoreSearchResult,
    snapshot?: RestorePointSelection,
    run?: RunSelectorResponse,
  ) => SelectionSnapshot;

  /**
   * The filters component is used to update the object search.
   */
  @ViewChild(FiltersComponent, { static: true }) private filtersComponent: FiltersComponent;

  /**
   * Selection data model.
   */
  selection: KeyedSelectionModel<RestoreSearchResult>;

  /**
   * Default 7 day preselected date range for date filter.
   */
  readonly dateRange: DateFilterRange<Moment> = {
    start: moment().subtract(6, 'd').startOf('d'),
    end: moment().endOf('d')
  };

  /**
   * A reference to the restore snapshot selection detail extension point definition.
   * This is used by plugins (like vulscanner) to add information about a selected snapshot.
   */
  snapshotExtensionPoint = AvailableExtensionPoints.restoreSnapshotSelectionDetailComponent;

  /**
   * Environment for the clones.
   */
  environments: Environment[] = [];

  /**
   * Search Environments.
   */
  searchEnvironments: Environment[] = [Environment.kVMware];

  /**
   *  Variable declared to get total records returned by indexed-objects api endpoint.
   */
  totalReturnedRecords = 0;

  /**
   * All filter options grouped by filter key.
   */
  filterValues: Record<string, ValueFilterSelection[]> = {};

  /**
   * Number of the latest point in time, for CDP snapshots.
   */
  private latestPointInTime$: Observable<number>;

  /**
   * Gets the correct icon for a given object.
   *
   * @param   object   The object search result.
   * @returns A helix icon to display.
   */
  @Memoize()
  getIcon(object: RestoreSearchResult): string {
    return this.type === 'vms' ? getVmIcon(object) : this.type === 'view' ? 'helix:view' : null;
  }

  constructor(
    private dialogService: DialogService,
    readonly scopeSelectorService: ScopeSelectorService,
    readonly searchService: ObjectSearchService,
    private translate: TranslateService,
  ) {
    super();

    // Set total records count returned by indexed-objects api endpoint.
    this.searchService.searchResults$.pipe(
      this.untilDestroy(),
    ).subscribe(result => {
      if (result) {
        this.totalReturnedRecords = result.length;
      }
    });
  }

  ngOnInit() {
    this.selection = new KeyedSelectionModel(
      (object: RestoreSearchResult) => object.id,
      this.type !== 'view',
    );
    if (this.type === 'view') {
      this.environments = [Environment.kView];
    } else if (this.type === 'vms') {
      this.environments = [Environment.kHyperV, Environment.kVMware];
    }

    this.selection.changed.pipe(
      this.untilDestroy(),
    ).subscribe(change => {
      change.added?.forEach(selection => {
        if (this.getSnapshot) {
          const result = this.getSnapshot(selection);

          result.snapshot$?.pipe(
            this.untilDestroy(),
          ).subscribe(snapshot => {
            this.form.setValue([...(this.form?.value || []), snapshot]);
            this.form.updateValueAndValidity();
          });
          if (result.latestPointInTime$) {
            this.latestPointInTime$ = result.latestPointInTime$;
          }
        } else {
          this.form.setValue([ ...(this.form?.value || []), selection.defaultRestorePointSelection ]);
          this.form.updateValueAndValidity();
        }
      });
      change.removed?.forEach(selection => {
        this.form.setValue([
          ...this.form.value.filter(s => !isSelectionEqual(selection, s))
        ]);
      });
      this.form.updateValueAndValidity();
    });
  }

  ngAfterViewInit() {
    // Initialize object filters with pre-selected environments.
    const selectedEnvironments = this.environments.map(environment => ({
      label: this.translate.instant(`enum.environment.${environment}`),
      value: environment,
    }));

    this.filtersComponent.setValue('environment', selectedEnvironments);
    this.filtersComponent.lockFilter('environment', '');

    // Update filters whenever they are changed.
    this.filtersComponent.filterValues$.pipe(
      this.untilDestroy(),
    ).subscribe(filterValues => {
      this.searchService.filterValues = filterValues as DataFilterValue<any>[];
    });
  }

  /**
   * Get filter values by filter name and id.
   */
  getFilterValue(key: string, value: string | number): any {
    return this.filterValues?.[key]?.find(filterValue => filterValue.value === value);
  }

  /**
   * Show snapshot dialog and allow user the select snapshot.
   *
   * @param index  Index of snapshot to be changed.
   */
  editSnapshotAt(index: number) {
    const snapshot = this.form.value?.[index];

    if (snapshot) {
      const { objectInfo, restorePointId, archiveTargetInfo } = snapshot;
      let snapshot$: Observable<RestorePointSelection>;

      if (objectInfo?.resultType === ProtectionGroupSearchResult.protectionGroupResultType) {
        // Protection Group case
        snapshot$ = this.dialogService.showDialog(ProtectionGroupRunSelectorModalComponent, {
          environment: objectInfo.environment,
          groupIcon: 'helix:group-virtual-machine',
          objectIcon: 'helix:object-vm',
          protectionGroupName: objectInfo.name,
          protectionGroupId: objectInfo.id,
          runId: restorePointId,
          archiveTargetId: archiveTargetInfo?.targetId,
        }).pipe(
          this.untilDestroy(),
          filter(result => !!result),
          switchMap((result: RunSelectorResponse) => this.getSnapshot(null, snapshot, result)?.snapshot$),
        );
      } else {
        // Object case.
        let dialog$: Observable<RestorePointSelection>;
        const dialogData = {
          object: snapshot,

          // TODO: Check what recovery action to be used.
          recoveryAction: RecoveryAction.CloneAppView,
        };

        if (snapshot.timestampUsecs === -1) {
          // CDP case, based on RecoverVmComponent.
          dialog$ = this.latestPointInTime$.pipe(
            filter(latestPointInTime => !!latestPointInTime),
            switchMap((latestPointInTime: number) => {
              snapshot.timestampUsecs = latestPointInTime;
              return this.dialogService.showDialog(SnapshotSelectorModalComponent, {
                ...dialogData,
                defaultViewSwitcherView: 'timeline',
              }) as Observable<RestorePointSelection>;
            }),
          );
        } else {
          dialog$ = this.dialogService.showDialog(SnapshotSelectorModalComponent, dialogData);
        }

        snapshot$ = dialog$.pipe(
          this.untilDestroy(),
          filter(result => !!result),
          switchMap((result: RestorePointSelection) => this.getSnapshot(null, result)?.snapshot$),
        );
      }

      snapshot$?.subscribe((newSnapshot: RestorePointSelection) => {
        this.form.setValue([
          ...this.form.value.map(s => s === snapshot ? newSnapshot : s)
        ]);
        this.form.updateValueAndValidity();
      });
    }
  }

  /**
   * Remove selection when user remove snapshot.
   *
   * @param snapshot  Snapshot object.
   */
  removeSnapshot(snapshot: RestorePointSelection) {
    const selection = this.selection.selected.find(selected => isSelectionEqual(selected, snapshot));

    // Let selection subscriber remove snapshot as well.
    if (selection) {
      this.selection.deselect(selection);
    }
  }
}
