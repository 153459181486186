import { Directive, ElementRef, Injector } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

@Directive({
  selector: 'ajs-health-analytics',
})
export class HealthAnalyticsComponent extends UpgradeComponent {
  constructor(injector: Injector, elementRef: ElementRef) {
    super('cAlertsAnalyticsComponent', elementRef, injector);
  }
}
