// Controller: Source Details - Settings tab controllers

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.sources')
    .controller('sourceDetailsSettingsController',
      sourceDetailsSettingsControllerFn);

  function sourceDetailsSettingsControllerFn(_, $state, $scope, evalAJAX,
    SourceInfo, ENUM_ENV_TYPE, ENV_GROUPS, PHYSICAL_SERVER_STATUS,
    PHYSICAL_SERVER_STATUS_CLASSNAME, ENUM_HOST_TYPE, FEATURE_FLAGS) {

    var $ctrl = this;

    _.assign($ctrl, {
      $onInit: onInit,
      ENUM_ENV_TYPE: ENUM_ENV_TYPE,
      ENUM_HOST_TYPE: ENUM_HOST_TYPE,
      ENV_GROUPS: ENV_GROUPS,
      PHYSICAL_SERVER_STATUS: PHYSICAL_SERVER_STATUS,
      PHYSICAL_SERVER_STATUS_CLASSNAME: PHYSICAL_SERVER_STATUS_CLASSNAME,
      getAgentStatus: getAgentStatus,
      getStatusError: getStatusError,
      sourceInfo: SourceInfo,
      isRegistrationPending: isRegistrationPending,
      showCapConcurrentDatastores: showCapConcurrentDatastores,
      showCapConcurrentBackups: showCapConcurrentBackups,
    });

    /**
     * init function
     */
    function onInit() {
      $ctrl.sourceSettingsLoaded = true;
    }

    /**
     * Helper function to check the status of agent.
     *
     * @method     _isHealthy
     * @param      {object}   agent The physical agent object.
     * @return     {boolean}  True if agent status is healthy,
     *                        False otherwise.
     */
    function _isHealthy(agent) {
      return agent.status === 'kHealthy';
    }

    /**
     * Gets the status by going through all the nodes.
     *
     * @method     getAgentStatus
     */
    function getAgentStatus() {
      var status = _.get($ctrl,
        'sourceInfo.rootNode.physicalProtectionSource.agents[0].status');

      // if agents array is empty, set status as unreachable
      if (!status) {
        status = 'kUnreachable';
      } else {
        $ctrl.sourceInfo.rootNode.physicalProtectionSource.
          agents.some(_isHealthy) ? status = 'kHealthy' : status;
      }
      $ctrl.sourceInfo.rootNode.physicalProtectionSource.agentStatus = {
        'serverStatusClass':$ctrl.PHYSICAL_SERVER_STATUS_CLASSNAME[status],
        'serverStatus':$ctrl.PHYSICAL_SERVER_STATUS[status],
      };
    }

    /**
     * Gets the status error by checking various properties.
     *
     * @return     {String}  The status error or undefined.
     */
    function getStatusError() {
      getAgentStatus();
      return $ctrl.sourceInfo.registrationInfo.authenticationErrorMessage ||
        $ctrl.sourceInfo.registrationInfo.refreshErrorMessage;
    }

    /**
     * Returns true if the registration for the source is in progress.
     *
     * @return {boolean} false if authenticationStatus is kfinished
     */
    function isRegistrationPending() {
      return $ctrl.sourceInfo.registrationInfo.authenticationStatus !==
        'kFinished';
    }

    /**
     * Returns true if cap current datastores feature is available.
     *
     * @return {boolean} true if the feature is enabled.
     */
    function showCapConcurrentDatastores() {
      var rootNode = $ctrl.sourceInfo.rootNode;

      switch (rootNode.environment) {
        case 'kVMware':
          return FEATURE_FLAGS.enableVmwareConcurrentStreams &&
            rootNode.vmWareProtectionSource.type !== 'kvCloudDirector';
        case 'kHyperV':
          return FEATURE_FLAGS.enableHypervConcurrentStreams;
        case 'kKVM':
          return FEATURE_FLAGS.enableKvmConcurrentStreams;
        case 'kAcropolis':
          return FEATURE_FLAGS.enableAcropolisConcurrentStreams;
        default:
          return false;
      }
    }

    /**
     * Returns true if cap current backups feature is available.
     *
     * @return {boolean} true if the feature is enabled and it's VM VCenter.
     */
    function showCapConcurrentBackups() {
      var rootNode = $ctrl.sourceInfo.rootNode;

      return FEATURE_FLAGS.enableVmwareConcurrentBackups &&
        rootNode.environment === 'kVMware' &&
        rootNode.vmWareProtectionSource.type === 'kVCenter';
    }

    /**
     * used to show spinner to notfiy user source is being refreshed
     */
    $scope.$on('source-refresh-started', function refreshStarted() {
      $ctrl.sourceSettingsLoaded = false;
    });

    /**
     * when source is refreshed set the new sourceInfo details so settings
     * table is updated
     */
    $scope.$on('source-refresh-done', function refreshDone(e, opt) {
      $ctrl.sourceInfo = opt.sourceInfo;

      $ctrl.sourceSettingsLoaded = true;
    });
  }
})(angular);
