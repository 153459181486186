import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';

/**
 * Data structure for the dialog component.
 */
interface DialogContext {
  /**
   * Title of dialog.
   */
  title?: string;

  /**
   * Message or body of dialog.
   */
  message: string;

  /**
   * Indicates whether message contains HTML.
   */
  isHtml?: boolean;

  /**
   * Cancel button label.
   */
  cancelLabel?: string;

  /**
   * Confirmation button label.
   */
  confirmLabel?: string;

  /**
   * The keyword the user has to type by default.
   */
  keywordLabel?: string;
}

/**
 * A generic delete confirmation dialog that allows user to customize message, title, and button labels.
 */
@Component({
  selector: 'coh-delete-confirmation-dialog',
  templateUrl: './delete-confirmation-dialog.component.html',
  styleUrls: ['./delete-confirmation-dialog.component.scss']
})
export class DeleteConfirmationDialogComponent {
  /**
   * Title of dialog.
   */
  title: string;

  /**
   * Message/Body of dialog.  Default to a generic 'Are you sure?' message
   */
  message = 'areYouSure';

  /**
   * Indicates whether message contains HTML..
   */
  isHtml: boolean;

  /**
   * Cancel button label. Default to 'Cancel'.
   */
  cancelLabel = 'cancel';

  /**
   * Confirmation button label. Default to 'Delete'.
   */
  confirmLabel = 'delete';

  /**
   * The keyword the user has to type by default.
   */
  keywordLabel = 'yes';

  /**
   * Form group to validate.
   */
  formGroup: UntypedFormGroup;

  constructor(
    public dialogRef: MatDialogRef<DeleteConfirmationDialogComponent>,
    private fb: UntypedFormBuilder,
    private translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public context: DialogContext
  ) {
    if (context) {
      if (context.message) {
        this.message = context.message;
      }
      if (context.confirmLabel) {
        this.confirmLabel = context.confirmLabel;
      }
      if (context.cancelLabel) {
        this.cancelLabel = context.cancelLabel;
      }
      if (context.keywordLabel) {
        this.keywordLabel = context.keywordLabel;
      }
      this.title = context.title;
      this.isHtml = context.isHtml;
    }

    this.formGroup = this.fb.group({
      confirmDelete: new UntypedFormControl(null, [
        Validators.required,
        Validators.pattern(
          new RegExp(`^${this.translateService.instant(this.keywordLabel).toLocaleUpperCase()}$`)
        )
      ]),
    });
  }

  /**
   * Method called to emit delete event.
   */
  onDelete() {
    if (this.formGroup.valid) {
      this.dialogRef.close(true);
    }
  }
}
