/**
 * Development license for yfiles.
 */
export const yfilesDevLicense = {
  company: 'Cohesity',
  contact: 'Satish Menon',
  date: '06/19/2024',
  distribution: false,
  domains: ['*'],
  email: 'satish.menon@cohesity.com',
  expires: '07/20/2025',
  fileSystemAllowed: true,
  licensefileversion: '1.1',
  localhost: true,
  oobAllowed: true,
  package: 'complete',
  product: 'yFiles for HTML',
  projectname: 'Floating Developer Seats',
  subscription: '06/20/2025',
  type: 'project',
  version: '2.6',
  watermark: 'yFiles HTML Development License',
  key: '8971b8a805dd464d6ef66901029a95f1797915c4',
};
