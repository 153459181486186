import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { CohesityHelixModule } from '@cohesity/helix';
import { TranslateModule } from '@ngx-translate/core';

import { AnomalyChartModule } from '../anomaly-chart';
import { AnomalyTrendsComponent } from './anomaly-trends.component';

const COMPONENTS = [AnomalyTrendsComponent];

@NgModule({
  imports: [AnomalyChartModule, CohesityHelixModule, CommonModule, TranslateModule],
  exports: [...COMPONENTS],
  declarations: [...COMPONENTS],
})
export class AnomalyTrendsModule {}
