// Component: SNMP Configuration

;(function (angular, undefined) {
  'use strict';

  angular
    .module('C.snmp', [])
    .constant('SNMP_CONST', snmpConstants())
    .config(configFn);

  function configFn($stateProvider) {
    $stateProvider
      .state('snmp-view', {
        url: '^/admin/snmp',
        help: 'admin_snmp',
        title: 'SNMP Configuration',
        canAccess: 'CLUSTER_VIEW',
        component: 'viewSnmp',
      })
      .state('snmp-modify', {
        url: '^/admin/snmp/edit',
        help: 'admin_snmp_edit',
        title: 'Edit SNMP Configuration',
        canAccess: 'CLUSTER_MODIFY',
        parentState: 'snmp-view',
        component: 'editSnmp',
      });
  }

  function snmpConstants() {
    return {
      authProtocol: [
        'kNone',
        'kAuthMD5',
        'kAuthSHA',
      ],
      privProtocol: [
        'kNone',
        'kPrivDES',
        'kPrivAES',
      ],
      v3SecurityLevel: [
        'kNoAuthNoPriv',
        'kAuthNoPriv',
        'kAuthPriv',
      ],
      operationType: [
        'kOperationEnable',
        'kOperationDisable',
      ],
      version: [
        'kSnmpV2',
        'kSnmpV3',
      ],
      userType: [
        'kReadUser',
        'kReadWriteUser',
        'kTrapUser',
      ],
      userKey: [
        'trapUser',
        'readUser',
        'writeUser',
      ],
      defaultAgentPort: 161,
      defaultTrapPort: 162,
    };
  }
})(angular);
