<h2 mat-dialog-title>{{ 'dms.port.requirementTitle' | translate: { name: data.name } }}</h2>
<div mat-dialog-content>
  <ng-container *ngIf="!loading; else spinner">
    <coh-connection-port-table
      *ngIf="region?.provisionStatus?.status === 'Completed'; else noInfo"
      [ports]="connectionService.portInformation$ | async">
    </coh-connection-port-table>
  </ng-container>
</div>
<mat-dialog-actions align="end">
  <button mat-flat-button cogCancelButton mat-dialog-close
    cogDataId="view-port-close-btn">
    {{'close' | translate}}
  </button>
</mat-dialog-actions>

<ng-template #spinner>
  <cog-spinner></cog-spinner>
</ng-template>

<ng-template #noInfo>
  <p>{{ 'dms.port.noInfo' | translate }}</p>
</ng-template>
