import { ReportColumnConfigs } from '../common-report-items/custom-reports.service';
import { ReportSection } from '../iris-reporting.model';

/**
 * This is the layout config for the protected object report.
 */
export const systemProtectionReport: ReportSection[] = [
  {
    title: 'reporting.summary',
    items: [
      {
        id: 'statlistItem',
        componentIds: ['1501'],
        componentNames: ['SystemProtectionSummary'],
        params: {
          items: [
            {
              title: 'reporting.totalSystems',
              translate: true,
              measurement: {
                valueKey: 'systemId',
                aggregation: 'count',
                dataType: 'number',
              },
            },
            {
              title: 'reporting.successRate',
              translate: true,
              measurement: {
                valueKey: 'countGroupId',
                dataType: 'percent',
                filters: [
                  {
                    filterKey: 'status',
                    value: 'kSuccess',
                  },
                  {
                    filterKey: 'status',
                    value: 'kWarning',
                  },
                ],
                aggregation: 'percent',
              },
            },
            {
              title: 'reporting.totalRuns',
              translate: true,
              measurement: {
                valueKey: 'countGroupId',
                dataType: 'number',
              }
            },
            {
              title: 'reporting.totalSuccessful',
              translate: true,
              measurement: {
                valueKey: 'countGroupId',
                dataType: 'number',
                filters: [
                  {
                    filterKey: 'status',
                    value: 'kSuccess',
                  },
                  {
                    filterKey: 'status',
                    value: 'kWarning',
                  },
                ],
              },
            },
            {
              title: 'reporting.success',
              translate: true,
              measurement: {
                valueKey: 'countGroupId',
                dataType: 'number',
                filter: {
                  filterKey: 'status',
                  value: 'kSuccess',
                },
              },
            },
            {
              title: 'reporting.warning',
              translate: true,
              measurement: {
                valueKey: 'countGroupId',
                dataType: 'number',
                filter: {
                  filterKey: 'status',
                  value: 'kWarning',
                },
              },
            },
            {
              title: 'reporting.failed',
              translate: true,
              measurement: {
                valueKey: 'countGroupId',
                dataType: 'number',
                filter: {
                  filterKey: 'status',
                  value: 'kFailure',
                },
              },
            },
            {
              title: 'reporting.canceled',
              translate: true,
              measurement: {
                valueKey: 'countGroupId',
                dataType: 'number',
                filter: {
                  filterKey: 'status',
                  value: 'kCanceled',
                },
              },
            },
            {
              title: 'reporting.skipped',
              translate: true,
              measurement: {
                valueKey: 'countGroupId',
                dataType: 'number',
                filter: {
                  filterKey: 'status',
                  value: 'kSkipped',
                },
              },
            },
            {
              title: 'reporting.running',
              translate: true,
              measurement: {
                valueKey: 'countGroupId',
                dataType: 'number',
                filter: {
                  filterKey: 'status',
                  value: 'kRunning',
                },
              },
            },
          ],
        },
      },
    ],
  },
  {
    items: [
      {
        id: 'chartItem',
        componentIds: ['1502'],
        componentNames: ['ProtectionRunsByActivityType'],
        title: 'reporting.protectionRunsByActivityType',
        params: {
          chartType: 'pie',
          measurements: [
            {
              valueKey: 'sumTotalBackups',
            },
          ],
          dimensions: [
            {
              dimensionKey: 'activityType',
            },
          ],
        },
      },
      {
        id: 'chartItem',
        componentIds: ['1002'],
        componentNames: ['StatusByActivity'],
        title: 'reporting.runStatusByActivityType',
        params: {
          chartType: 'column',
          measurements: [
            {
              valueKey: 'countGroupId',
            },
          ],
          dimensions: [
            {
              dimensionKey: 'activityType',
            },
            {
              dimensionKey: 'status',
            },
          ],
        },
      },
    ],
  },
  {
    title: 'reporting.data',
    isFullData: true,
    items: [
      {
        id: 'tableItem',
        componentIds: ['1500'],
        componentNames: ['AllSystemProtection'],
        params: {
          columns: [
            {
              title: 'systemName',
              translate: true,
              key: 'systemName',
              icon: {
                dataType: 'clusterIcon',
              },
              primary: {
                key: 'systemName',
                dataType: 'text',
              },
            },
            {
              title: 'activityType',
              translate: true,
              key: 'activityType',
              primary: {
                key: 'activityType',
                dataType: 'activityType',
              },
            },
            {
              title: 'reporting.totalRuns',
              translate: true,
              key: 'totalBackups',
              primary: {
                key: 'totalBackups',
                dataType: 'number',
              },
            },
            {
              title: 'reporting.successRate',
              translate: true,
              key: 'successPercentage',
              primary: {
                key: 'successPercentage',
                dataType: 'number',
                renderParams: {
                  suffix: '%',
                  format: '1.0-2',
                  limit: 0.01,
                },
              },
            },
            {
              title: 'reporting.success',
              translate: true,
              key: 'successfulBackups',
              primary: {
                key: 'successfulBackups',
                dataType: 'number',
              },
            },
            {
              title: 'reporting.warning',
              translate: true,
              key: 'warningBackups',
              primary: {
                key: 'warningBackups',
                dataType: 'number',
              },
            },
            {
              title: 'reporting.failed',
              translate: true,
              key: 'failedBackups',
              primary: {
                key: 'failedBackups',
                dataType: 'number',
              },
            },
            {
              title: 'reporting.canceled',
              translate: true,
              enabled: false,
              key: 'canceledBackups',
              primary: {
                key: 'canceledBackups',
                dataType: 'number',
              },
            },
            {
              title: 'reporting.skipped',
              translate: true,
              enabled: false,
              key: 'skippedBackups',
              primary: {
                key: 'skippedBackups',
                dataType: 'number',
              },
            },
            {
              title: 'reporting.running',
              translate: true,
              enabled: false,
              key: 'runningBackups',
              primary: {
                key: 'runningBackups',
                dataType: 'number',
              },
            },
          ],
        },
      },
    ],
  },
];


/**
 * This is metadata needed to determine available chart customizations.
 */
export const systemProtectionColumns: ReportColumnConfigs = {
  activityType: {
    aggs: [],
    canGroup: true,
    dataType: 'text',
  },
  policyId: {
    aggs: ['count'],
    dataType: 'uuid'
  },
  systemName: {
    aggs: [],
    canGroup: true,
    maxGroupingValues: 5,
    dataType: 'text',
  },
  systemId: {
    aggs: ['count'],
    dataType: 'uuid',
  },
};
