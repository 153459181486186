import { Component, Input, ViewEncapsulation } from '@angular/core';

import { NavItem } from '../../nav-item/nav-item.model';

/**
 * Action menu item component.
 */
@Component({
  selector: 'cog-action-menu-item',
  templateUrl: './action-menu-item.component.html',
  styleUrls: ['./action-menu-item.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ActionMenuItemComponent {

  /**
   * The item to render
   */
  @Input() item: NavItem;

  /**
   * Whether to include the icon in the menu. This defaults to false per UX guidelines.
   */
  @Input() showIcon = false;

  /**
   * Show bigger menu item if size = lg.
   */
  @Input() size: 'lg' | 'md';
}
