import { AppModuleConfig, StateAccessContext, StateAccessMap } from 'src/app/app-module.config';

const configureAccess = (ctx: StateAccessContext) => ctx.isDmsUser && ctx.FEATURE_FLAGS.activityListing;

export const ObjectActivityConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'object-activity.**',
      url: '/activity/',
      loadChildren: () => import('./object-activity.module').then(m => m.ObjectActivityModule),
    }
  ],
  allClusterMap: {
    'object-activity.listing': true,
  },
  getStateAccessMap(): StateAccessMap {
    return {
      'object-activity.listing': configureAccess,
    };
  }
};
