<coh-source-special-parameters [form]="form"
  [nodeContext]="nodeContext"
  (dialogOpened)="updateForm()"
  (saved)="onSaved()"
  [dialogHeaderTemplate]="dialogHeaderTemplate">
  <ng-container *ngIf="form" [formGroup]="form">
    <div class="sfdc-exclude-fields-option">
      <div class="auto-truncate fields-container">
        {{'excludedFields' | translate}} : {{ getExcludedFields() }}
      </div>
      <div class="fields-selection-container"
        *ngIf="(fieldsLoading$ | async) === false; else spinner">
        <mat-form-field>
          <mat-label>{{associateLabel | translate}}</mat-label>
          <mat-select cogDataId="field-exclusion-multi-select"
            [inheritDataId]="true"
            formControlName="excludeFields" multiple>
            <mat-option *ngFor="let field of fields$ | async"
              cogDataId="option-{{field.name}}"
              [inheritDataId]="true"
              [value]="field.name">
              {{field.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </ng-container>
</coh-source-special-parameters>
<ng-template #dialogHeaderTemplate let-dialogRef="dialogRef">
  <div class="sfdc-options-dialog-header">
    <button class="back-button"
      (click)="dialogRef.close(false)"
      cogDataId="sfdc-options-back-button"
      mat-icon-button
      type="button">
      <cog-icon [shape]="backButtonIcon || 'arrow_back'"></cog-icon>
    </button>
    <h1 mat-dialog-title matTooltip="{{'serverOptions.sfdcEnv' | translate: {name: nodeContext.node.name} }}"
      matTooltipPosition="below">
      {{'serverOptions.sfdcEnv' | translate: {name: nodeContext.node.name} }}
    </h1>
  </div>
</ng-template>
<ng-template #spinner>
  <cog-spinner></cog-spinner>
</ng-template>
