import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { BehaviorSubject, Observable, Subscription, iif } from 'rxjs';

import featureFlagJson from '../../../feature-flags.json';
import { environment } from 'src/environments/environment';

/**
 * A type to represent a feature flag key.
 */
export type FeatureFlag = keyof typeof featureFlagJson;

/**
 * Feature flags interface, map of key/name and boolean.
 */
export type FeatureFlags = {
  [k in FeatureFlag]?: boolean | string | number;
};

/**
 * Represents the collection of feature flags inside the JSON file.
 */
export type FeatureFlagMap = Record<FeatureFlag, boolean | string | number | { value: boolean; epic: string }>;

/**
 * Local storage key for storing UI feature flag overrides.
 */
const localStorageKey = 'C.featureFlags';

/**
 * Cache of UI based feature flag override values.
 */
let uiFeatureFlags: FeatureFlags = {};

/**
 * Cache of feature flags returned from API, initialized with json file contents.
 */
let apiFeatureFlags: FeatureFlags = {};

/**
 * from the data of type `FeatureFlagMap` which can have a object as value we are converting it to type `FeatureFlags`.
 * which will have only primitive type values.
 *
 * @param featureFlagsMap
 * @returns FeatureFlags type object
 */
export const getParsedFeatureFlagData = (featureFlagsMap: FeatureFlagMap): FeatureFlags => {
  const featureFlags = {};
  for (const [key, value] of Object.entries(featureFlagsMap)) {
    featureFlags[key] = Object.prototype.hasOwnProperty.call(value, 'value') ? value['value'] : value;
  }
  return featureFlags;
};

/**
 * Parse the feature-flags.json into a simple object with boolean values since
 * the values in feature-flags.json can either be boolean, or an object with
 * "epic" and "value" key.
 */
apiFeatureFlags = getParsedFeatureFlagData(<FeatureFlagMap>featureFlagJson);

/**
 * Angular replacement for legacy FEATURE_FLAGS constant.
 */
@Injectable({
  providedIn: 'root'
})
export class FeatureFlagsService {

  /**
   * Default URL for loading backend set of feature flags.
   */
  readonly featureFlagsUrl = 'featureFlags';

  /**
   * Default URL for loading account specific feature flags.
   */
  readonly accountFeatureFlagsUrl = '/v2/mcm/featureFlags';

  /**
   * Observable of feature flags, initialized to the json file.
   */
  featureFlags$ = new BehaviorSubject<FeatureFlags>({
    ...apiFeatureFlags,
    ...this.uiFlags,
  });

  /**
   * Observable to know if account features are loaded.
   */
  accountFeature$ = new BehaviorSubject<FeatureFlags>(null);

  /**
   * Data from feature-flags.json file.
   */
  get featureFlags(): any {
    return this.featureFlags$.value;
  }

  constructor(private http: HttpClient) {}

  /**
   * Loads and parses featureFlags backend request.
   *
   * @return Subscription when feature flags service is resolved.
   */
  init(): Subscription {
    return this.http.get(this.featureFlagsUrl).subscribe((data: FeatureFlagMap) => {
      if (data) {
        const parsedFlags = getParsedFeatureFlagData(data);
        apiFeatureFlags = {
          ...apiFeatureFlags,
          ...parsedFlags
        };
      }
      this.updateFlags();
    });
  }

  /**
   * Loads the account specific flags that are enabled.
   */
  loadAccountSpecificFlags() {

    // If the account feature are already loaded. Then skip it.
    if (this.accountFeature$.value) {
      return;
    }

    iif(
      () => environment.heliosInFrame,
      (this.http.get(`/${this.featureFlagsUrl}`) as Observable<FeatureFlagMap>),
      this.http.get(this.accountFeatureFlagsUrl) as Observable<FeatureFlagMap>
    ).subscribe((data: FeatureFlagMap) => {
      const parsedFlags = getParsedFeatureFlagData(data);
      apiFeatureFlags = {
        ...apiFeatureFlags,
        ...parsedFlags
      };
      this.accountFeature$.next(parsedFlags);
      this.updateFlags();
    });
  }

  /**
   * Returns true if specified feature flag is truthy.
   *
   * @deprecated Use flagEnabled and IrisContextService instead
   * @param flagName Feature flag name.
   * @return  True when specified feature flag is truthy, false otherwise.
   */
  enabled(flagName: FeatureFlag): boolean {
    // Coerce the value to boolean in case the feature flag being requested does not exist.
    return !!this.featureFlags[flagName];
  }

  /**
   * Indicates if a particular flag is disabled.
   *
   * @deprecated Use flagEnabled and IrisContextService instead
   * @param flagName Feature flag name.
   * @return  True when specified feature flag is disabled, false when enabled.
   */
  disabled(flagName: FeatureFlag): boolean {
    return !this.enabled(flagName);
  }

  /**
   * Provides any UI based feature flag overrides from local storage.
   */
  get uiFlags(): FeatureFlags {
    /**
     * adding getParsedFeatureFlagData to check if localstorage have some flag value of object type then parse it
     * to return FeatureFlag Types
     */
    return uiFeatureFlags = getParsedFeatureFlagData(JSON.parse(localStorage.getItem(localStorageKey)) || {});
  }

  /**
   * Updates a feature flag UI override value.
   *
   * @param flagKey
   * @param newValue
   */
  setUiFlag(flagKey: string, newValue: boolean) {
    uiFeatureFlags[flagKey] = newValue;
    localStorage.setItem(localStorageKey, JSON.stringify(uiFeatureFlags));
    this.updateFlags();
  }

  /**
   * Clears any/all UI based feature flag overrides.
   */
  clearUiFlags() {
    uiFeatureFlags = {};
    localStorage.removeItem(localStorageKey);
    this.updateFlags();
  }

  /**
   * update the FeatureFlags$ behavior subject.
   */
  updateFlags() {
    this.featureFlags$.next({
      ...apiFeatureFlags,
      ...this.uiFlags,
    });
  }
}

/**
 * Downgrade FeatureFlagsService for legacy AngularJS use.
 */
declare const angular;
angular
  .module('C')
  .factory('featureFlagsService', downgradeInjectable(FeatureFlagsService) as any);

/**
 * Mock featureFlagsService for AngularJS unit tests use.
 * See example for use in AJS unit test.
 *
 * @example
 *
 *    beforeAll(angular.mock.module('C.featureFlagsService.Mock'))
 */
angular
  .module('C.featureFlagsService.Mock', [])
  .factory('featureFlagsService', () => ({
    fakeFlag1: true,
    fakeFlag2: false
  }));
