import { InjectionToken, Type } from '@angular/core';

/**
 * This injection token is used to map an id for a component to it's Type. A lazy loaded module
 * could define expose more than one dynamic components
 */
export const DYNAMIC_COMPONENTS = new InjectionToken<DynamicComponentEntry[]>('DYNAMIC_COMPONENTS');

/**
 * This injection token is used to list all of the dynamic components that the application knows
 * about. It functions similarly to a list of routes with lazy loaded components.
 */
export const DYNAMIC_COMPONENT_MANIFESTS = new InjectionToken<any>('DYNAMIC_COMPONENT_MANIFESTS');

/**
 * An entry for a dynamic component.
 */
export interface DynamicComponentEntry {
  /**
   * The component id should be unique and match the one defined in the manifest
   */
  componentId: string;

  /**
   * The component associated with the id. The component should also be declared
   * as an entry component.
   */
  component: Type<any>;
}

export interface DynamicComponentManifest {
  /**
   * Unique identifier, used in the application to retrieve a ComponentFactory.
   */
  componentId: string;

  /**
   * Path to component module.
   */
  loadChildren: () => Type<any> | Promise<Type<any>>;
}
