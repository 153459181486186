<mat-label>{{ label }}</mat-label>
<div class="flex-row end-align slider-form-field">
  <mat-slider [formControl]="control"
    color="primary"
    thumbLabel
    [max]="max"
    [min]="min">
  </mat-slider>
  <mat-form-field class="col-xs-3 margin-bottom-xs">
    <input readonly
      [inheritDataId]="true"
      cogDataId="slider-value"
      [value]="control?.value"
      matInput>
    <span *ngIf="suffix" matSuffix>{{suffix}}</span>
  </mat-form-field>
</div>