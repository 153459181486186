import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AutoTableFilter, AutoTableColumnConfig } from './auto-table.model';
import { AutoTableSource } from './auto-table-source';
import { TableDataSource } from '../table/table-data-source';
import { HelixIntlService } from '../../helix-intl.service';
import { SelectionModel } from '@angular/cdk/collections';
import { TableComponent } from '../table/table.component';

/**
 * An auto table automatically renders columns and cells based on its config input.
 */
@Component({
  selector: 'cog-auto-table',
  templateUrl: './auto-table.component.html',
  styleUrls: ['./auto-table.component.scss'],
})
export class AutoTableComponent implements OnChanges, OnDestroy, OnInit {
  /**
   * Column configs include the column names and information needed to render each
   * column cell.
   */
  @Input() columns: AutoTableColumnConfig[];

  /**
   * Table name for caching page size.
   */
  @Input() name: string;

  /**
   * The table's data
   */
  @Input() data: any[];

  /**
   * Set to true to disable showing the header row.
   */
  @Input() noHeader = false;

  /**
   * Top align header text if true.
   */
  @Input() alignHeader = false;

  /**
   * Whether to show the table pagination controls
   */
  @Input() paginate = false;

  /**
   * How many items to show per page, if pagination is enabled.
   */
  @Input() pageSize = 25;

  /**
   * Whether to include a generic search filter.
   */
  @Input() showFilter = false;

  /**
   * Perform server side sort, filter if true.
   */
  @Input() isAsyncData = false;

  /**
   * Timestamp for display in table header.
   */
  @Input() columnDate: number;

  /**
   * Date format to display in header.
   */
  @Input() dateFormat: string;

  /**
   * Tooltip suffix for header.
   */
  @Input() tooltipSuffixKey: string;

  /**
   * Emit sort event when user changes sort.
   */
  @Output() filterChange = new EventEmitter<AutoTableFilter>();

  /**
   * Optional selection model for the auto table
   */
  @Input() selection: SelectionModel<any>;

  /**
   * An array of the column ids, derived from the column config.
   */
  columnIds: string[];

  /**
   * Table data source to support async filter, sort, pagination.
   */
  dataSource: TableDataSource<any> | AutoTableSource = new TableDataSource([]);

  /**
   * Stores filter change including sort, filter, pagination.
   */
  filter: AutoTableFilter = {};

  /**
   * Used for subscription clean up for filterChangeSubject.
   */
  private destroy = new Subject<void>();

  /**
   * A reference to the table component instance.
   */
  @ViewChild(TableComponent, { static: true}) tableComponent: TableComponent<any>;

  /**
   * Optional input to hook up translations for the column.
   */
  @Input() translate: (key, params?) => string = key => key;

  get loading$(): Observable<boolean> {
    if (this.isAsyncData && this.dataSource) {
      return (this.dataSource as AutoTableSource).loading$;
    } else {
      return of(false);
    }
  }

  constructor(readonly intl: HelixIntlService) {}

  ngOnInit() {
    // TODO: consider moving the filter change logic to table-item component
    //   when adding filter support.
    if (this.isAsyncData) {
      const dataSource = this.dataSource as AutoTableSource;

      dataSource.filterChangeSubject
        .pipe(takeUntil(this.destroy))
        .subscribe(filter => {
          this.filter = filter;
          dataSource.autoTableFilter = filter;
          this.filterChange.emit(filter);
        });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isAsyncData) {
      if (this.isAsyncData) {
        this.dataSource = new AutoTableSource(this.filter);
      } else {
        this.dataSource = new TableDataSource([]);
      }
    }
    if (changes.data && this.dataSource) {
      (this.dataSource as any).data = this.data;
    }
    if (changes.columns) {
      this.columnIds = this.columns
        .filter(column => column.enabled !== false)
        .map(column => column.key);
    }
  }

  ngOnDestroy() {
    this.destroy.next();
  }

  /**
   * Gets tooltip text translation.
   *
   * @param tooltipText  Tooltip text for translation.
   * @returns translated tooltip text.
   */
  getTooltip(tooltipText: string): string | null {
    if (!tooltipText) {
      return null;
    }

    const translation = this.translate(tooltipText);

    if (translation === tooltipText) {
      return null;
    }
    if (this.tooltipSuffixKey) {
      return [translation, this.translate(this.tooltipSuffixKey)].join(' ');
    }
    return translation;
  }
}
