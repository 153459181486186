import { CellColorStates } from './cell-colors.constants';

/**
 * list of protection status field values
 */
export enum ProtectionStatus {
  protected = 'protected',
  unprotected = 'unprotected',
}

/**
 * translation key mapping for all the filterable fields
 */
export const protectionStatusValueLabelKey: Record<ProtectionStatus, string> = {
  [ProtectionStatus.protected]: 'protected',
  [ProtectionStatus.unprotected]: 'unprotected',
};

/**
 * A map of protection status to the background cell color
 */
export const protectionStatusColors: Record<ProtectionStatus, CellColorStates> = {
  [ProtectionStatus.protected]: CellColorStates.ok,
  [ProtectionStatus.unprotected]: CellColorStates.unknown,
};
