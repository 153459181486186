// Module: VLANs

;(function (angular, undefined) {
  'use strict';

  angular
    .module('C.vlan', [])
    .config(configFn);

  function configFn($stateProvider) {
    $stateProvider
      .state('vlan', {
        url: '^/platform/vlans/{mode}/{id}',
        params: {
          // edit or create a new vlan.
          mode: { type: 'string', value: '' },
          id: { type: 'string', value: '' },
        },
        help: 'admin_cluster_vlans',
        title: 'Vlan',
        canAccess: 'VLAN_MODIFY',
        templateUrl: 'app/platform/vlans/detail/detail.html',
        controller: 'vlanDetailCtrl as $ctrl',
        parentState: 'networking.vlans',
        resolve: {
          pageConfig: function getPageConfig($stateParams) {
            return {
              mode: $stateParams.mode,
              id: $stateParams.id,
            };
          },

          // mocking modal instance while rendering in page view
          $uibModalInstance: angular.noop,
        },
      });
  }

})(angular);
