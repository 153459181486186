import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, Validators } from '@angular/forms';
import { SecurityAdvisorServiceApi, SecurityCriteria, SecurityPlan } from '@cohesity/api/secops';
import { AjaxHandlerService } from '@cohesity/utils';
import {
  ArrayPropertyKey,
  ArrayPropertyValue,
  Controls,
  DataInput,
  NgxFormWithArrayControls,
  NgxRootFormComponent,
  takeUntilDestroyed,
} from 'ngx-sub-form';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { SecurityCriteriaFormModel, SecurityRuleFormModel } from '../../models';


/**
 * Component to create/update security rule.
 */
@Component({
  selector: 'dg-pa-security-rules-modify-page',
  templateUrl: './security-rules-modify-page.component.html',
  styleUrls: ['./security-rules-modify-page.component.scss'],
})
export class SecurityRulesModifyPageComponent
  extends NgxRootFormComponent<SecurityPlan, SecurityRuleFormModel>
  implements OnInit, NgxFormWithArrayControls<SecurityCriteriaFormModel> {
  /**
   * Data Input
   */
  @DataInput() dataInput: Required<SecurityPlan>;

  /**
   * Inform parent that rule has been modified
   */
  @Output() backClick = new EventEmitter<string>();

  /**
   * Id of rule which needs to be modified
   */
  @Input() ruleId: string;

  /**
   * Submit status of the component.
   */
  submitting = false;

  /**
   * Data Output
   */
  dataOutput: EventEmitter<SecurityPlan>;

  /**
   * Response after successful submission.
   */
  submitResponse: SecurityPlan;

  /**
   * Checking mode edit or create
   */
  isEditMode: boolean;

  /**
   * Constructor
   */
  constructor(
    private ajaxHandlerService: AjaxHandlerService,
    private securityAdvisorServiceApi: SecurityAdvisorServiceApi,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super();
  }

  /**
   * OnInit lifecycle hook.
   */
  ngOnInit() {
    // Needed by ngx-subform library.
    super.ngOnInit();
    this.isEditMode = !!this.ruleId;
    this.fetchSecurityRuleDetails(this.ruleId);
  }

  /**
   * Return form controls from security rule form model
   */
  getFormControls(): Controls<SecurityRuleFormModel> {
    return {
      name: new UntypedFormControl(null, [Validators.required]),
      id: new UntypedFormControl(),
      securityCriteriaList: new UntypedFormArray([]),
      accessControlList: new UntypedFormControl(),
      configurationList: new UntypedFormControl(),
      smartFilesList: new UntypedFormControl(),
    };
  }

  /**
   * Default form values.
   *
   * Returns Security rule form model
   */
  getDefaultValues(): SecurityRuleFormModel {
    return {
      id: null,
      name: null,
    };
  }

  /**
   * Getting security rule details.
   *
   * @param ruleId Security rule id.
   */
  fetchSecurityRuleDetails(ruleId?: string) {
    if (ruleId) {
      this.securityAdvisorServiceApi
        .GetSecurityPlanDetails(ruleId)
        .pipe(takeUntilDestroyed(this))
        .subscribe(result => {
          this.dataInput = result as Required<SecurityPlan>;
          this.changeDetectorRef.detectChanges();
        }, this.ajaxHandlerService.errorMessage);
    } else {
      this.securityAdvisorServiceApi.GetSecurityCriteriaList()
        .pipe(takeUntilDestroyed(this))
        .subscribe(result => {
          this.dataInput = {
            securityCriteriaList: result.securityCriteriaList,
            name: '',
          } as Required<SecurityPlan>;
          this.changeDetectorRef.detectChanges();
        }, this.ajaxHandlerService.errorMessage);
    }
  }

  /**
   * ngx-subform utility method to create form array control.
   */
  createFormArrayControl(
    key: ArrayPropertyKey<SecurityCriteriaFormModel> | undefined,
    value: ArrayPropertyValue<SecurityCriteriaFormModel>
  ): UntypedFormControl {
    return new UntypedFormControl(value);
  }

  /**
   * Submitting changes of security rules.
   */
  submitSecurityRule() {
    const data = this.transformFromFormGroup(this.formGroupValues);
    this.submitting = true;

    const submitSecurityRuleUpdate$: Observable<SecurityPlan> = this.isEditMode ?
      this.securityAdvisorServiceApi.UpdateSecurityPlan({ id: data.id, body: data }) :
      this.securityAdvisorServiceApi.CreateSecurityPlan(data);

    submitSecurityRuleUpdate$.pipe(
      takeUntilDestroyed(this),
      finalize(() => this.submitting = false)
    ).subscribe(() => {
      this.backClick.emit();
    }, this.ajaxHandlerService.errorMessage);
  }

  /**
   * Transforms api model to form group model.
   *
   * Returns Security Rule form model
   */
  protected transformToFormGroup(securityRule: SecurityPlan): SecurityRuleFormModel {
    return {
      name: securityRule.name,
      id: securityRule.id,
      securityCriteriaList: this.transformSecurityCriteriaFormModel(securityRule.securityCriteriaList),
      accessControlList: {
        criteriaList: this.transformSecurityCriteriaFormModel(securityRule.securityCriteriaList, 'accessControl'),
      },
      configurationList: {
        criteriaList: this.transformSecurityCriteriaFormModel(securityRule.securityCriteriaList, 'configuration'),
      },
      smartFilesList: {
        criteriaList: this.transformSecurityCriteriaFormModel(securityRule.securityCriteriaList, 'smartFiles'),
      },
    };
  }

  /**
   * Transforms api model to form group model.
   *
   * Returns Security Plan form model
   */
  protected transformFromFormGroup(formModel: SecurityRuleFormModel): SecurityPlan {
    return {
      name: formModel.name,
      id: formModel.id,
      securityCriteriaList: [
        ...formModel.accessControlList.criteriaList,
        ...formModel.configurationList.criteriaList,
        ...formModel.smartFilesList.criteriaList,
      ],
    };
  }

  /**
   * Get security criteria form model.
   *
   * @param securityCriteriaList Security criteria list.
   * @param category Contains data of each security criteria list
   *
   * Returns Security Criteria form model
   */
  private transformSecurityCriteriaFormModel(
    securityCriteriaList: SecurityCriteria[],
    category?: string
  ): SecurityCriteriaFormModel[] {
    return securityCriteriaList
      .filter(securityCriteria => !category || securityCriteria.category === category)
      .map(securityCriteria => ({
        name: securityCriteria.name,
        id: securityCriteria.id,
        isEnabled: securityCriteria.isEnabled,
      }));
  }
}
