import { ChangeDetectionStrategy, Component, ContentChild, HostBinding, Input, ViewEncapsulation } from '@angular/core';

import {
  DashcardChartComponent,
  DashcardFooterComponent,
  DashcardLegendComponent,
  DashcardTopComponent,
} from './../dashcard-layouts-common.component';

/**
 * Dashboard standard card layout component.
 *
 * @example
 * <cog-dashcard-layout-standard>
 *   <cog-dashcard-top>
 *     TOP CONTENT!
 *   </cog-dashcard-top>
 *   <cog-dashcard-chart>
 *     CHART CONTENT!
 *   </cog-dashcard-chart>
 *   <cog-dashcard-legend>
 *     LEGEND CONTENT!
 *   </cog-dashcard-legend>
 *   <cog-dashcard-footer>
 *     FOOTER CONTENT!
 *   </cog-dashcard-footer>
 * </cog-dashcard-layout-standard>
 */
@Component({
  selector: 'cog-dashcard-layout-standard',
  templateUrl: './dashcard-layout-standard.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./dashcard-layout-standard.component.scss'],
})
export class DashcardLayoutStandardComponent {
  /**
   * ContentChild of the "Top" child component. Used for layout purposes.
   */
  @ContentChild(DashcardTopComponent, {static: false}) dashcardTop: DashcardTopComponent;

  /**
   * ContentChild of the "Chart" child component. Used for layout purposes.
   */
  @ContentChild(DashcardChartComponent, {static: false}) dashcardChart: DashcardChartComponent;

  /**
   * ContentChild of the "Legend" child component. Used for layout purposes.
   */
  @ContentChild(DashcardLegendComponent, {static: false}) dashcardLegend: DashcardLegendComponent;

  /**
   * ContentChild of the "Footer" child component. Used for layout purposes.
   */
  @ContentChild(DashcardFooterComponent, {static: false}) dashcardFooter: DashcardFooterComponent;

  /**
   * Indicates if implementation is just a chart (no top content, footer is fine).
   */
  @HostBinding('class.chart-only') get chartOnly(): boolean {
    return !this.dashcardTop && !!this.dashcardChart;
  }

  /**
   * Indicates if implementation is just a chart without legend.
   */
  @HostBinding('class.chart-without-legend') get noLegend(): boolean {
    return !this.dashcardLegend && !!this.dashcardChart;
  }

  /**
   * Indicates if implementation includes a DashcardFooterComponent.
   */
  @HostBinding('class.has-footer') get hasFooter(): boolean {
    return !!this.dashcardFooter;
  }

  /**
   * Swaps the position of the chart and legend if invert is set to true.
   */
  @HostBinding('class.inverted') @Input() invert = false;
}
