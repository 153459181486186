import { Component } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { CaModel } from '../certificate-authority-interface/certificate-authority-interface.model';

@Component({
  selector: 'coh-certificate-authority-settings-dialog',
  templateUrl: './certificate-authority-settings-dialog.component.html',
})
export class CertificateAuthoritySettingsDialogComponent {

  /**
   * List of possible type to select
   */
  interfaceSelectedCertificates: CaModel['typeOfCa'][] = [];

  /**
   * Constructor
   *
   * @param dialogRef
   */
  constructor(
    private dialogRef: MatDialogRef<CertificateAuthoritySettingsDialogComponent>,
  ) {}

  /**
   * Set the list of selected interfaces
   *
   * @param selectedCertificates
   */
  selectedCertificates(selectedCertificates: CaModel['typeOfCa'][]) {
    this.interfaceSelectedCertificates = selectedCertificates;
  }

  /**
   * Close dialog and send the selected interfaces
   */
  onSave() {
    this.dialogRef.close(this.interfaceSelectedCertificates);
  }
}
