<form [formGroup]="sourceThrottlingFormGroup" cogDataId="source-throttling">
  <div class="margin-bottom">
    <mat-slide-toggle class="margin-bottom"
      [checked]="enabled"
      [inheritDataId]="true"
      cogDataId="enabled"
      (change)="toggleSourceThrottling($event)">
      {{enabled | boolLabel}}
    </mat-slide-toggle>
  </div>
  <div class="margin-left-lg" *ngIf="enabled">
    <p>{{'protectionGroups.throttling.description' | translate}}</p>
    <div class="row margin-bottom margin-left-xs">
      <h5>{{'fullBackups' | translate}}</h5>
      <div class="row margin-top">
        <!-- Data Reduction ratio -->
        <coh-range-slider
          class="col-xs-6"
          [control]="maxMetadataFetchFullControl"
          [label]="'parallelMetadataFetch' | translate"
          suffix="%">
        </coh-range-slider>

        <!-- Change rate -->
        <coh-range-slider
          class="col-xs-6"
          [control]="maxReadWriteFullControl"
          [label]="'parallelReadsFromSource' | translate"
          suffix="%">
        </coh-range-slider>
      </div>
    </div>
    <div class="row margin-bottom margin-left-xs">
      <h5>{{'incrementalBackups' | translate}}</h5>
      <div class="row margin-top">
        <!-- Data Reduction ratio -->
        <coh-range-slider
          class="col-xs-6"
          [control]="maxMetadataFetchIncrementalControl"
          [label]="'parallelMetadataFetch' | translate"
          suffix="%">
        </coh-range-slider>

        <!-- Change rate -->
        <coh-range-slider
          class="col-xs-6"
          [control]="maxReadWriteIncrementalControl"
          [label]="'parallelReadsFromSource' | translate"
          suffix="%">
        </coh-range-slider>
      </div>
    </div>
  </div>
</form>
