import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ProtectionSourceNode } from '@cohesity/api/v1';
import { DataTreeNodeContext, DataTreeNodeDetail } from '@cohesity/helix';

import { KubernetesSourceDataNode } from '../kubernetes-source-data-node';
import { KubernetesPvcFilterType, TypeSectionRule } from '../kubernetes.constants';
import { KubernetesQuiesceMode } from 'src/app/shared/constants/kubernetes.constants';
import { KubernetesLabel } from '@cohesity/api/v2';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';

// The limits for the value of timeouts (in minutes).
const timeoutLimits = { min: 1, max: 525_600 /** 1 year */ };

/**
 * Interface for kubernetes pre, post scripts
 */
export interface KubernetesScript {
  /** Container */
  container?: string;

  /** Command to be excuted. */
  command?: string;

  /** Whether to fail on error or not. */
  failOnError: boolean;

  /** Timeout for script. */
  timeout?: number;
}

/**
 * Interface for pvc info
 */
export interface PvcInfo {
  /** Name of the pvc */
  name: string;

  /** Id of pvc */
  id: number;
}

@Component({
  selector: 'coh-kubernetes-source-options',
  templateUrl: './kubernetes-source-options.component.html',
  styleUrls: ['./kubernetes-source-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class KubernetesSourceOptionsComponent implements DataTreeNodeDetail<KubernetesSourceDataNode>, OnInit {
  /**
   * The node context, including info about the node and it's selection status.
   */
  @Input() nodeContext: DataTreeNodeContext<KubernetesSourceDataNode>;

  /**
   * Expose quiesce mode.
   */
  readonly kubernetesQuiesceMode = KubernetesQuiesceMode;

  /**
   * pvcs of namespace.
   */
  pvcs: PvcInfo[];

  /**
   * Make timeout limits available to template.
   */
  timeoutLimits = timeoutLimits;

  /**
   * Type of sections in rules
   */
  typeSectionRule = TypeSectionRule;

  /**
   * Whether to expand rule or not.
   */
  ruleExpand: boolean[] = [];

  /**
   * Quiesce Rules flag.
   */
  get isKubernetesQuiesceRulesEnabled(): boolean {
    return flagEnabled(this.irisCtx.irisContext, 'enableKubernetesQuiesceRules');
  }

  /**
   * Gets the node from the nodeContext.
   */
  get node(): KubernetesSourceDataNode {
    return this.nodeContext.node;
  }

  /**
   * Gets the current options for the node. This either gets them from the selection options, or
   * the default params for the node.
   */
  get currentOptions() {
    return this.nodeContext.selection.getOptionsForNode(this.node.id) || {};
  }

  /**
   * Gets the list of excluded pvcs, if any, from the special parameters.
   */
  get excludePvcs() {
    return (this.currentOptions.kubernetesSpecialParameters || {}).excludePvcs;
  }

  /**
   * Gets the list of included disks, if any, from the special parameters.
   */
  get includePvcs() {
    return (this.currentOptions.kubernetesSpecialParameters || {}).includePvcs;
  }

  /**
   * Gets quiesce mode, if any, from the special parameters.
   */
  get quiesceMode() {
    return (this.currentOptions.kubernetesSpecialParameters || {}).quiesceMode;
  }

  /**
   * Gets quiesce mode, if any, from the special parameters.
   */
  get quiesceRules() {
    return (this.currentOptions.kubernetesSpecialParameters || {}).quiesceRules;
  }

  /**
   * Get the labels form control.
   *
   * @return   FormArray of labels controls.
   */
  get quiesceRulesControl(): FormArray {
    return this.form.get('quiesceRules') as FormArray;
  }

  /**
   * The form to contain the options.
   */
  form: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder,
    private cdr: ChangeDetectorRef,
    private irisCtx: IrisContextService,
    ) {}

  ngOnInit() {
    // Holds the available pvcs that can be excluded or included.
    this.pvcs = ((this.node.data?.nodes || [])
      .map((node: ProtectionSourceNode) => ({ id: node.protectionSource.id, name: node.protectionSource.name })));
  }

  /**
   * Updates the form based on the current options setting in preparation for displaying the form
   * dialog.
   */
  updateForm() {
  // Map the excluded/included pvc list from the job selection to the actual pvc options
  // that are available for the host.
    let filterType = KubernetesPvcFilterType.Include;

    if (this.excludePvcs?.length) {
      filterType = KubernetesPvcFilterType.Exclude;
    }

    const selectedPvcs =
      filterType === KubernetesPvcFilterType.Include ? this.includePvcs : this.excludePvcs;

    const pvcOptions = (selectedPvcs || []).map(selectedPvc => {
      const { id } = selectedPvc;

      return this.pvcs.find(pvc =>
        pvc.id === id);
      }).filter(Boolean);
    this.form = this.fb.group({
      enablePvcFilter: [!!pvcOptions.length, Validators.required],
      type: [filterType, Validators.required],
      pvcs: [pvcOptions],
      quiesceMode: [this.quiesceMode],
      quiesceRules: new FormArray([]),
    });

    for (let i = 0; i < this.quiesceRules?.length; i++) {
      this.addQuiesceRule();
      this.quiesceRules[i].podSelector?.forEach(label =>
        this.addTypeFormControl(i, this.typeSectionRule.labels, label));
      this.quiesceRules[i].preSnapshotScripts?.forEach(script =>
        this.addTypeFormControl(i, this.typeSectionRule.preScript, script));
      this.quiesceRules[i].postSnapshotScripts?.forEach(script =>
        this.addTypeFormControl(i, this.typeSectionRule.postScript, script));
    }
  }

  /**
   * Updates the selection options after the form has been saved and the dialog has been closed.
   */
   onSaved() {
    const { type, pvcs } = this.form.value;
    const options = {
      sourceId: this.node.id,
      kubernetesSpecialParameters: {
        excludePvcs: type === KubernetesPvcFilterType.Exclude && pvcs.length ?
          pvcs.map(pvc => ({
            id: pvc.id,
            name: pvc.name,
          })) :
          undefined,
        includePvcs: type === KubernetesPvcFilterType.Include && pvcs.length ?
          pvcs.map(pvc => ({
            id: pvc.id,
            name: pvc.name,
          })) :
          undefined,
        quiesceMode: this.form.value.quiesceMode,
        quiesceRules: this.form.value.quiesceRules,
      },
    };
    this.nodeContext.selection.setOptionsForNode(this.node.id, options);
    this.cdr.detectChanges();
  }

  /**
   * Triggers on toggling pvcInclusionExclusion.
   */
  togglePvcs() {
    if (!this.form.value.enablePvcFilter) {
      this.form.get('pvcs').setValue([]);
    }
  }

  /**
   * Triggers on toggling pvcInclusionExclusion type.
   * Reset the include/exclude pvc selection.
   */
  toggleType() {
    this.form.get('pvcs').setValue([]);
  }

  /**
   * Remove a certain pvc from the selected pvcs.
   *
   * @params  pvcToRemove is the pvc to be removed.
   */
  removePvc(pvcToRemove: PvcInfo) {
    const pvcs = this.form.get('pvcs').value;
    this.form.get('pvcs').setValue(pvcs.filter(pvc => pvc !== pvcToRemove));
  }

  /**
   * Add new rule.
   */
  addQuiesceRule() {
    this.quiesceRulesControl.push(this.fb.group({
      podSelector: new FormArray([], Validators.required),
      preSnapshotScripts: new FormArray([], Validators.required),
      postSnapshotScripts: new FormArray([], Validators.required),
    }));
    this.ruleExpand.push(true);
  }

  /**
   * Function to remove a form control item from the rules form
   * controls array.
   *
   * @param   index   Index of the item to be removed.
   */
  removeQuiesceRuleControl(index: number) {
    this.quiesceRulesControl.removeAt(index);
    this.ruleExpand.splice(index, 1);
  }

  /**
   * Get the type form control.
   *
   * @param   index   Index of rule.
   * @param   type   Type of section to be retrieved.
   *
   * @return FormArray of type controls.
   */
  typeControl(index: number, type: TypeSectionRule): FormArray {
    const ruleForm = this.quiesceRulesControl.controls[index];
    if (type === this.typeSectionRule.labels) {
      return ruleForm?.get('podSelector') as FormArray;
    }
    return type === this.typeSectionRule.preScript ? ruleForm.get('preSnapshotScripts') as FormArray :
      ruleForm.get('postSnapshotScripts') as FormArray;

  }

  /**
   * Add a new type control.
   *
   * @param   index   Index of rule.
   * @param   type   Type of section to be retrieved.
   * @param   typeValue   Value of type.
   */
  addTypeFormControl(index: number, type: TypeSectionRule, typeValue: (KubernetesLabel | KubernetesScript)) {
    const typeControl = this.typeControl(index, type);
    if (type === 'labels') {
      const {key, value} = typeValue as KubernetesLabel || {};

      typeControl.push(
        new UntypedFormGroup({
          key: new UntypedFormControl(key, Validators.required),
          value: new UntypedFormControl(value, Validators.required)
        })
      );
    } else {
      const {container, command, failOnError, timeout} = typeValue as KubernetesScript || {};
      const timeoutValidators = [
        Validators.pattern('^[0-9]*$'),
        Validators.min(timeoutLimits.min),
        Validators.max(timeoutLimits.max),
      ];

      typeControl.push(
        new UntypedFormGroup({
          container: new UntypedFormControl(container),
          command: new UntypedFormControl(command, Validators.required),
          failOnError: new UntypedFormControl(failOnError),
          timeout: new UntypedFormControl(timeout, timeoutValidators),
        })
      );
    }
  }

  /**
   * Function to remove a form control item from the type form
   * controls array.
   *
   * @param   ruleIndex   Index of the rule to be removed.
   * @param   typeIndex   Index of the type to be removed.
   */
  removeTypeFormControl(ruleIndex: number, typeIndex: number, type: TypeSectionRule) {
    this.typeControl(ruleIndex, type).removeAt(typeIndex);
  }

  /**
   * Function to toggle rule section.
   *
   * @param   index   Index of rule to expand or shrink.
   */
  toggleRuleExpand(index: number){
    this.ruleExpand[index] = !this.ruleExpand[index];
  }
}
