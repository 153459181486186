/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityV2ApiConfiguration as __Configuration } from '../cohesity-v2api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { FirewallIPSets } from '../models/firewall-ipsets';
import { FirewallProfileParams } from '../models/firewall-profile-params';
import { SuccessResp } from '../models/success-resp';
import { FirewallProfileNamesParams } from '../models/firewall-profile-names-params';
import { FirewallProfiles } from '../models/firewall-profiles';
@Injectable({
  providedIn: 'root',
})
class FirewallService extends __BaseService {
  static readonly ListFirewallIPSetsPath = '/network/firewall/ip-sets';
  static readonly UpdateFirewallIPSetsPath = '/network/firewall/ip-sets';
  static readonly UpdateFirewallProfilePath = '/network/firewall/profile';
  static readonly RemoveFirewallProfilesPath = '/network/firewall/profile/remove';
  static readonly ListFirewallProfilesPath = '/network/firewall/profiles';
  static readonly UpdateFirewallProfilesPath = '/network/firewall/profiles';
  static readonly ResetFirewallProfilePath = '/network/firewall/profiles/reset';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * List all firewall IP sets
   *
   * List all firewall IP sets.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  ListFirewallIPSetsResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<FirewallIPSets>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/network/firewall/ip-sets`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FirewallIPSets>;
      })
    );
  }
  /**
   * List all firewall IP sets
   *
   * List all firewall IP sets.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  ListFirewallIPSets(regionId?: string,
    accessClusterId?: number): __Observable<FirewallIPSets> {
    return this.ListFirewallIPSetsResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as FirewallIPSets)
    );
  }

  /**
   * Update firewall IP sets
   *
   * Update firewall IP sets.
   * @param params The `FirewallService.UpdateFirewallIPSetsParams` containing the following parameters:
   *
   * - `body`: Specifies the update request parameters.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateFirewallIPSetsResponse(params: FirewallService.UpdateFirewallIPSetsParams): __Observable<__StrictHttpResponse<FirewallIPSets>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/network/firewall/ip-sets`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FirewallIPSets>;
      })
    );
  }
  /**
   * Update firewall IP sets
   *
   * Update firewall IP sets.
   * @param params The `FirewallService.UpdateFirewallIPSetsParams` containing the following parameters:
   *
   * - `body`: Specifies the update request parameters.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateFirewallIPSets(params: FirewallService.UpdateFirewallIPSetsParams): __Observable<FirewallIPSets> {
    return this.UpdateFirewallIPSetsResponse(params).pipe(
      __map(_r => _r.body as FirewallIPSets)
    );
  }

  /**
   * Update firewall profiles & their attachments.
   *
   * Update the firewall profiles and/or their attachments.
   * @param params The `FirewallService.UpdateFirewallProfileParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to configure firewall profiles and/or their attachments.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateFirewallProfileResponse(params: FirewallService.UpdateFirewallProfileParams): __Observable<__StrictHttpResponse<FirewallProfileParams>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/network/firewall/profile`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FirewallProfileParams>;
      })
    );
  }
  /**
   * Update firewall profiles & their attachments.
   *
   * Update the firewall profiles and/or their attachments.
   * @param params The `FirewallService.UpdateFirewallProfileParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to configure firewall profiles and/or their attachments.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateFirewallProfile(params: FirewallService.UpdateFirewallProfileParams): __Observable<FirewallProfileParams> {
    return this.UpdateFirewallProfileResponse(params).pipe(
      __map(_r => _r.body as FirewallProfileParams)
    );
  }

  /**
   * Remove firewall profiles.
   *
   * Remove firewall profiles and their attachments.
   * @param params The `FirewallService.RemoveFirewallProfilesParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to remove firewall profiles and their attachments.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  RemoveFirewallProfilesResponse(params: FirewallService.RemoveFirewallProfilesParams): __Observable<__StrictHttpResponse<SuccessResp>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/network/firewall/profile/remove`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SuccessResp>;
      })
    );
  }
  /**
   * Remove firewall profiles.
   *
   * Remove firewall profiles and their attachments.
   * @param params The `FirewallService.RemoveFirewallProfilesParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to remove firewall profiles and their attachments.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  RemoveFirewallProfiles(params: FirewallService.RemoveFirewallProfilesParams): __Observable<SuccessResp> {
    return this.RemoveFirewallProfilesResponse(params).pipe(
      __map(_r => _r.body as SuccessResp)
    );
  }

  /**
   * List all firewall profiles.
   *
   * List the firewall profiles & their attachments.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  ListFirewallProfilesResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<FirewallProfiles>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/network/firewall/profiles`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FirewallProfiles>;
      })
    );
  }
  /**
   * List all firewall profiles.
   *
   * List the firewall profiles & their attachments.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  ListFirewallProfiles(regionId?: string,
    accessClusterId?: number): __Observable<FirewallProfiles> {
    return this.ListFirewallProfilesResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as FirewallProfiles)
    );
  }

  /**
   * Update firewall profiles & their attachments.
   *
   * Update the firewall profiles and/or their attachments.
   * @param params The `FirewallService.UpdateFirewallProfilesParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to configure firewall profiles and/or their attachments.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateFirewallProfilesResponse(params: FirewallService.UpdateFirewallProfilesParams): __Observable<__StrictHttpResponse<FirewallProfiles>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/network/firewall/profiles`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FirewallProfiles>;
      })
    );
  }
  /**
   * Update firewall profiles & their attachments.
   *
   * Update the firewall profiles and/or their attachments.
   * @param params The `FirewallService.UpdateFirewallProfilesParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to configure firewall profiles and/or their attachments.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateFirewallProfiles(params: FirewallService.UpdateFirewallProfilesParams): __Observable<FirewallProfiles> {
    return this.UpdateFirewallProfilesResponse(params).pipe(
      __map(_r => _r.body as FirewallProfiles)
    );
  }

  /**
   * Reset firewall profiles.
   *
   * Reset firewall profiles, ip-sets & their attachments.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  ResetFirewallProfileResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/network/firewall/profiles/reset`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Reset firewall profiles.
   *
   * Reset firewall profiles, ip-sets & their attachments.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  ResetFirewallProfile(regionId?: string,
    accessClusterId?: number): __Observable<null> {
    return this.ResetFirewallProfileResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module FirewallService {

  /**
   * Parameters for UpdateFirewallIPSets
   */
  export interface UpdateFirewallIPSetsParams {

    /**
     * Specifies the update request parameters.
     */
    body: FirewallIPSets;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateFirewallProfile
   */
  export interface UpdateFirewallProfileParams {

    /**
     * Specifies the parameters to configure firewall profiles and/or their attachments.
     */
    body: FirewallProfileParams;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for RemoveFirewallProfiles
   */
  export interface RemoveFirewallProfilesParams {

    /**
     * Specifies the parameters to remove firewall profiles and their attachments.
     */
    body: FirewallProfileNamesParams;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateFirewallProfiles
   */
  export interface UpdateFirewallProfilesParams {

    /**
     * Specifies the parameters to configure firewall profiles and/or their attachments.
     */
    body: FirewallProfiles;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { FirewallService }
