import { Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { TagStatus } from './tag.model';

@Component({
  selector: 'cog-tag',
  template: `<ng-content></ng-content>`,
  styleUrls: ['./tag.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TagComponent {
  /**
   * The desired styling/coloring of the tag.
   */
  @Input() status: TagStatus = 'info';

  /**
   * Applies the appopriate class for the requested style.
   */
  @HostBinding('class')
  get classString(): string {
    return this.status;
  }
}
