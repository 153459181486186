import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Api } from '@cohesity/api/private';
import { QuorumServiceApi } from '@cohesity/api/quorum';
import { httpClientCacheProxy } from '@cohesity/utils';
import { BehaviorSubject } from 'rxjs';

/**
 * Subject for reloading quorum groups.
 */
const reloadSubject$ = new BehaviorSubject<null>(null);

@Injectable({
  providedIn: 'root'
})
export class EnhancedQuorumServiceApiService extends QuorumServiceApi {
  /**
   * Observable for subject for reloading quorum groups.
   */
  reload$ = reloadSubject$.asObservable();

  constructor(httpClient: HttpClient) {
    super({rootUrl: Api.quorumRoot}, httpClientCacheProxy(httpClient, reloadSubject$));

    return this;
  }
}
