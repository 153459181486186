import { Component, OnInit } from '@angular/core';
import { PopoverRef } from '@cohesity/helix';
import { AagDataMap } from 'src/app/shared/sql/services/sql-utility.service';


@Component({
  selector: 'coh-sql-aag-details-popover',
  templateUrl: './sql-aag-details-popover.component.html',
  styleUrls: ['./sql-aag-details-popover.component.scss'],
})
export class SqlAagDetailsPopoverComponent implements OnInit {
  /**
   * Aag data of sql aag details popover component
   */
  aagData: AagDataMap;

  constructor(readonly popover: PopoverRef<any>) {}

  ngOnInit() {
    this.aagData = this.popover.data.data;
  }
}
