<coh-source-special-parameters [form]="form" [nodeContext]="nodeContext"
  [label]="excludeEbsVolumes?.length ?
    (excludeEbsVolumes.length === 1 ? ('cSourceTreeNode.volumesExcludedSingular' | translate) :
      ('cSourceTreeNode.volumesExcludedPlural' | translate : {n: excludeEbsVolumes.length})) :
    ('cSourceTreeNode.allVolumes' | translate)"
  (dialogOpened)="updateForm()"
  (saved)="onSave()"
  [dialogHeaderTemplate]="dialogHeaderTemplate">
  <div *ngIf="form" [formGroup]="form" class="aws-ec2-options">
    <div class="c-tile">
      <div class="info margin-top">
        {{'job.settings.excludeEbsVolumes' | translate}}
      </div>
    </div>

    <ng-template #blankCard>
      <h1>{{ 'job.settings.noEbsVolumes' | translate }}</h1>
    </ng-template>

    <ng-container *ngIf="ebsVolumes?.length; else blankCard">
      <dl class="c-dl" formArrayName="volumes">
        <div class="dl-row">
          <dd class="aws-ec2-options-customized-dd">
            <div class="aws-ec2-options-name-header">
              {{'name' | translate}}
            </div>
          </dd>
          <dt class="aws-ec2-options-customized-dt">
            <dl class="c-dl-clean">
              <dt>
                {{'id' | translate}}
              </dt>
              <dd>
                {{'type' | translate}}
              </dd>
            </dl>
          </dt>
        </div>
        <div class="dl-row" *ngFor="let volume of volumeFormArray.controls; let i = index">
          <dd class="aws-ec2-options-customized-dd">
            <div class="ebs-volume-details">
              <mat-checkbox [formControlName]="i"
                cogDataId="ebs-volume-{{ebsVolumes[i].id}}"
                matTooltip="{{ebsVolumes[i].isRootDevice ? ('job.settings.rootEbsVolumeTooltip' | translate) : null}}"
                matTooltipPosition="after">
              </mat-checkbox>
              <div class="c-media c-media-align-center margin-left-sm">
                <cog-icon shape="helix:object-volume"></cog-icon>
                <div class="c-media-body margin-left-sm">
                  <div class="flex-truncate-wrapper">
                    <div class="flex-truncate-text detail-label user-data-sm auto-truncate"
                      [matTooltip]="ebsVolumes[i].name ? ebsVolumes[i].name : 'naNotAvailable' | translate"
                      matTooltipPosition="after">
                      {{ ebsVolumes[i].name ? ebsVolumes[i].name : 'naNotAvailable' | translate }}
                    </div>
                    <small class="margin-left-sm aws-ec2-options-size">{{ ebsVolumes[i].sizeBytes | byteSize }}</small>
                  </div>
                  <div class="flex-truncate-wrapper">
                    <div class="flex-truncate-text">
                      <small>{{ ebsVolumes[i].deviceName }}</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </dd>
          <dt class="aws-ec2-options-customized-dt">
            <dl class="c-dl-clean">
              <dt>{{ ebsVolumes[i].id }}</dt>
              <dd>{{ ebsVolumes[i].type }}</dd>
            </dl>
          </dt>
        </div>
      </dl>
    </ng-container>

    <cog-banner status="info" *ngIf="showExcludeEbsVolumesWarning">
      {{'job.settings.excludeEbsVolumesWarning' | translate}}
    </cog-banner>
  </div>
</coh-source-special-parameters>

<ng-template #dialogHeaderTemplate let-dialogRef="dialogRef">
  <div class="aws-ec2-options-dialog-header">
    <button class="back-button"
      (click)="dialogRef.close(false)"
      cogDataId="ec2-options-back-button"
      mat-icon-button
      type="button">
      <cog-icon [shape]="backButtonIcon || 'arrow_back'"></cog-icon>
    </button>
    <h1 mat-dialog-title matTooltip="{{'serverOptions.volumesFor' | translate: {name: nodeContext.node.name} }}"
      matTooltipPosition="below">
      {{'serverOptions.volumesFor' | translate: {name: nodeContext.node.name} }}
    </h1>
  </div>
</ng-template>
