import { ClusterName, DataSource, ObjectId } from '@cohesity/api/argus';
import { SearchObject } from '@cohesity/api/v2';
import { MomentDatePipe } from '@cohesity/helix';
import { ClusterIdentifierId, getClusterIdentifier, sanitizeFileName, userTimezone } from '@cohesity/utils';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';

import { SearchObjectPerSource } from '../scan.models';

/**
 * Extract cluster uuid from Resource uuid.
 *
 * @param objectUuid Resource uuid in clusterId:clusterIncarnationId:resourceId format.
 * @returns The cluster uuid in clusterId:clusterIncarnationId format.
 */
export const extractClusterUuid = (objectUuid: ObjectId): string =>
  (objectUuid || '').split(':').slice(0, 2).join(':');

/**
 * Extract Resource id from Resource uuid.
 *
 * @param objectUuid Resource uuid in clusterId:clusterIncarnationId:resourceId format.
 * @returns The resource Id.
 */
export const extractObjectId = (objectUuid: ObjectId): number => {
  const resourceId = extractObjectUuidParts(objectUuid)[2];

  return resourceId || null;
};

/**
 * Extract ids from Resource uuid.
 *
 * @param objectUuid Resource uuid in clusterId:clusterIncarnationId:resourceId format.
 * @returns Array of ids.
 */
export const extractObjectUuidParts = (objectUuid: ObjectId): number[] =>
  (objectUuid || '').split(':').map(id => +id);


/**
 * Gets a default scan name for a DC/TH task.
 *
 * @param   taskNameKey   The key to use for translating the task name
 * @returns A default task name.
 */
export const getDefaultTaskName = (
  translateService: TranslateService,
  momentDatePipe: MomentDatePipe,
  taskNameKey: string
) => {
  const dateString = [
    momentDatePipe.transform(new Date(), 'mediumDate'),
    momentDatePipe.transform(new Date(), 'shortTime'),
  ].join(' ');

  return sanitizeFileName(translateService.instant(taskNameKey, { dateString }));
};

export const getDefaultStartTime = () => {
  const currTime = moment();
  return {
    hour: currTime.hours(),
    minute: currTime.minutes(),
    timeZone: userTimezone,
  };
};

export const getDataSourceNameByClusterIdentifierIdMap = (
  dataSources: DataSource[],
): Map<ClusterIdentifierId, ClusterName> => {
  const out = new Map<ClusterIdentifierId, ClusterName>();
  (dataSources || []).forEach(dataSource => out.set(getClusterIdentifier(dataSource.cohesityParams), dataSource.name));
  return out;
};

/**
 * Multiplexes protected objects per each protection info
 *
 * @param object protected object
 * @returns list of search objects
 */
export const multiplySearchObjectBySource = (
  object: SearchObject,
  clusterNameByClusterIdentifierIdMap: Map<ClusterIdentifierId, ClusterName>,
  isProtectedGroups = true,
): SearchObjectPerSource[] => {
  const objectProtectionInfos = object.objectProtectionInfos;
  const searchObjects = [];
  objectProtectionInfos.forEach(objectProtectionInfo => {
    // ignoring unprotected objects
    if (isProtectedGroups && !objectProtectionInfo.protectionGroups?.length) {
      return;
    }
    searchObjects.push({
      id: `${getClusterIdentifier(objectProtectionInfo)}:${objectProtectionInfo.objectId}`,
      objectProtectionInfo: {
        name: clusterNameByClusterIdentifierIdMap.get(getClusterIdentifier(objectProtectionInfo)),
        ...objectProtectionInfo,
      },
      sourceInfo: object?.sourceInfo,
      object,
    });
  });
  return searchObjects;
};
