<div class="gauge-chart-wrapper">
  <span class="gauge-hole-content">
    <ng-content></ng-content>
  </span>

  <highcharts-chart
    [Highcharts]="Highcharts"
    [options]="chartOptions"
    [ngClass]="colorSetClass">
  </highcharts-chart>
</div>
