import { AppModuleConfig, StateAccessContext, StateAccessMap } from 'src/app/app-module.config';

const downloadsAccess = (ctx: StateAccessContext) =>
  ctx.FEATURE_FLAGS.ngHeliosClusterUpgrade
    && ctx.isHelios
    && !ctx.isHeliosOnPrem
    && !ctx.isHeliosTenantUser
    && (ctx.CLUSTER_UPGRADE || ctx.CLUSTER_MAINTENANCE);

/**
 * Summary dashboard is single cluster state.
 * This tab should only be visible in all cluster state.
 */
const singleClusterState = 'dashboards';

export const DownloadsConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'cluster-downloads.**',
      url: '/cluster-downloads',
      loadChildren: () => import('./downloads.module').then(m => m.DownloadsModule),
    },
  ],
  allClusterMap: {
    'cluster-downloads': {
      heliosOnly: true,
      allClustersState: 'cluster-downloads',
      singleClusterState,
    },
  },
  getStateAccessMap(): StateAccessMap {
    return {
      'cluster-downloads': downloadsAccess,
    };
  },
};
