// Component: cNetworkInterface

;(function(angular, undefined) {
  'use strict';

  /**
   * @ngdoc component
   * @name C.network:cNetworkInterface
   * @function
   *
   * @description
   * Reusable component provides an easy means of selecting a network interface
   *
   * @example
   * <c-network-interface default-iface-name="$ctrl.cluster._ifaceName"
   *   selected-interface="$ctrl.cluster._selectedInterface"
   *   auto-select="$ctrl.cluster._autoSelect">
   * </c-network-interface>
   */
  var options = {
    bindings: {
      // @type   {[boolean]=true}   Show without waiting for getting interfaces
      forceShow: '<?',

      // @type   {[boolean]=true}   auto select or select from interfaces
      autoSelect: '<?',

      // @type   {[string]=""}   Interface to select once they are loaded, picks
      //                         first vlan if empty
      defaultIfaceName: '<?',

      // @type   {[array]=[]}   List of types of interfaces to show,
      //                        empty means all
      interfaceTypes: '<?',

      // @type   {[string]="interfaceSettings"}   header string to translate
      header: '@?',

      // @type   {object}   object that will contain the selected interface
      selectedInterface: '=',

    },

    controller: 'cNetworkInterfaceCtrlFn',
    templateUrl: 'app/global/c-network-interface/c-network-interface.html',
  };

  angular
    .module('C.network', [])
    .controller('cNetworkInterfaceCtrlFn', cNetworkInterfaceCtrlFn)
    .component('cNetworkInterface', options);

  function cNetworkInterfaceCtrlFn(NetworkService, VlanService, evalAJAX, $q,
    TenantService, UserService) {
    var $ctrl = this;

    _.assign($ctrl, {
      interfaces: [],
      setSelectedInterface: setSelectedInterface,
      // Component lifecycle methods
      $onInit: $onInit,
    });

    /**
     * Initialize component
     *
     * @method   $onInit
     */
    function $onInit() {
      $ctrl.autoSelect = $ctrl.autoSelect === undefined? true: $ctrl.autoSelect;
      $ctrl.forceShow = $ctrl.forceShow === undefined? true: $ctrl.forceShow;
      // Fetch interfaces
      getInterfaces();
    }

    /**
     * Set interface to undefined or appropriate selected iface
     *
     * @method   setSelectedInterface
     */
    function setSelectedInterface() {
      if ($ctrl.autoSelect) {
        $ctrl.selectedInterface = undefined;
      } else if ($ctrl.interfaces.length) {
        $ctrl.selectedInterface = _.find($ctrl.interfaces,
          function matchIfaceNameOrGroup(iface) {
            return (iface.ifaceName === $ctrl.defaultIfaceName) ||
                   (iface.ifaceGroup === $ctrl.defaultIfaceName);
        });
        if (!$ctrl.selectedInterface) {
          $ctrl.selectedInterface = $ctrl.interfaces[0];
        }
      }
    }
    /**
     * Get list of interfaces
     *
     * @method    getInterfaces
     */
    function getInterfaces() {
      $ctrl.loadingInterfaces = true;
      var vlanFilterParams = {};

      // For tenant user, include tenant info and skip bond interfaces.
      if (UserService.isTenantUser()) {
        vlanFilterParams = TenantService.extendWithTenantParams({
          _includeTenantInfo: true,
        });

        // To filter out bond interfaces only for SP user.
        _.assign(vlanFilterParams, {skipPrimaryAndBondIface: true});
      }

      // Get vlan interfaces with tenant info to filter out network interfaces.
      var promises = {
        vlans: VlanService.getVlans(vlanFilterParams),
        interfaces: NetworkService.getInterfaces($ctrl.interfaceTypes)
      };
      $q.all(promises).then(
        function gotInterfaces(response) {
          var vlanInterfaces = response.vlans.map(
            function getIfaceName(vlan) {
              return vlan.ifaceGroupName || vlan.vlanName;
            }
          );
          $ctrl.interfaces = response.interfaces.filter(

            // For tenant user, Interface should be present in vlan interfaces.
            function eachInterface(item) {
                return item.ifaceGroup &&
                (!UserService.isTenantUser() || vlanInterfaces.includes(item.ifaceGroup));
            });
          setSelectedInterface();
        })
        .catch(evalAJAX.errorMessage)
        .finally(function getInterfacesFinally(){
          $ctrl.loadingInterfaces = false;
        });
    }
  }
})(angular);
