/* tslint:disable */

/**
 * Specifies Shield type
 */
export enum ShieldType {
  ANTI_RANSOMWARE = 'ANTI_RANSOMWARE',
  BRUTE_FORCE_ATTACK = 'BRUTE_FORCE_ATTACK',
  GEO_FENCING_BREACH = 'GEO_FENCING_BREACH',
  INSIDER_THREAT = 'INSIDER_THREAT',
  SENSITIVE_DATA_LEAK = 'SENSITIVE_DATA_LEAK',
  SERVICE_PROVIDER_ALERTS = 'SERVICE_PROVIDER_ALERTS',
  SMARTFILES = 'SMARTFILES',
  THREAT_DETECTION = 'THREAT_DETECTION',
  WIDE_ACCESS = 'WIDE_ACCESS',
  UNKNOWN = 'UNKNOWN'
}
