<div [formGroup]="formGroup" class="flex-row vertical-align">
  <mat-form-field class="flex-grow">
    <input matInput
      [formControlName]="formControlNames.value"
      [placeholder]="label">
  </mat-form-field>
  <div class="flex-row flex-basis-sm">
    <button mat-icon-button
      *ngIf="allowRemove"
      type="button"
      cogDataId="remove-item"
      (click)="onRemoveItem()">
      <cog-icon shape="remove_circle_outline"></cog-icon>
    </button>
    <button mat-icon-button
      *ngIf="allowAdd"
      type="button"
      cogDataId="add-item"
      (click)="onAddItem()">
      <cog-icon shape="add_circle_outline"></cog-icon>
    </button>
  </div>
</div>