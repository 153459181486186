<coh-source-special-parameters [form]="form"
  [nodeContext]="nodeContext"
  [label]="systemBackupEnabled ? ('bmr' | translate) : ''"
  (dialogOpened)="updateForm()"
  (saved)="onSaved()">
  <ng-container *ngIf="form" [formGroup]="form">
    <mat-slide-toggle formControlName="enableSystemBackup" cogDataId="toggle-bmr-backup">
      {{'takeBMRBackup' | translate}}
    </mat-slide-toggle>
  </ng-container>
</coh-source-special-parameters>
