import { Component, EventEmitter, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatCalendar, MatCalendarCellCssClasses } from '@angular/material/datepicker';
import moment from 'moment';

import { CalendarHeaderComponent } from './calendar-header/calendar-header.component';

/**
 * @description
 * Customized Material Calendar component with custom header and option to mark special dates with dot.
 *
 * @example
 *  <coh-calendar
 *    [markedDates]="markedDates"
 *    [activeDates]="activeDates"
 *    [selectedDate]="selectedDate$ | async"
 *    (dateChanged)="dateChangeHandler($event)">
 *  </coh-calendar>
 */
@Component({
  selector: 'coh-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CalendarComponent<D> {

  /**
   * Reference to calendar.
   */
  @ViewChild(MatCalendar, { static: true })
  private calendar: MatCalendar<D>;

  /**
   * Calendar custom header component.
   */
  readonly headerComponent = CalendarHeaderComponent;

  /**
   * Date that will be marked with red dot on the calendar.
   */
  @Input() markedDates: D[];

  /**
   * Dates that should be enabled in calendar.
   * Leave undefined to enable all dates.
   */
  private _activeDates: D[];

  /**
   * Sets active dates.
   */
  @Input() set activeDates(activeDates: D[]) {
    this._activeDates = activeDates;

    if (this.calendar && this.calendar.monthView) {
      this.calendar.updateTodaysDate();
    }
  }

  /**
   * Returns active dates.
   */
  get activeDates(): D[] {
    return this._activeDates;
  }

  /**
   * Calendar selected date.
   */
  private _selectedDate: D;

  /**
   * Sets calendar selected date.
   */
  @Input() set selectedDate(date: D) {
    this._selectedDate = date;

    setTimeout(() => this.calendar.activeDate = date);
  }

  /**
   * Returns calendar selected date.
   */
  get selectedDate(): D {
    return this._selectedDate;
  }

  /**
   * Emits date instance when calendar date changes.
   */
  @Output() readonly dateChanged = new EventEmitter<D>();

  /**
   * Calendar function to set CSS selectors for specific dates.
   */
  calendarCssClass = (date: Date): MatCalendarCellCssClasses => {
    const dayClasses = new Set();
    const checkDate = moment(date).startOf('day');

    if (Array.isArray(this._activeDates)) {
      if (this._activeDates.find(d => moment(d).isSame(checkDate, 'day'))) {
        dayClasses.add('active-date');
      }
    }

    if (this.markedDates) {
      const found = this.markedDates.find(d => moment(d).startOf('day').isSame(checkDate));
      if (found) {
        dayClasses.add('marked-date');
      }
    }

    return dayClasses;
  };

  /**
   * Calendar function to disable non-active dates.
   */
  dateFilter = (date: Date): boolean => {
    if (!Array.isArray(this.activeDates)) {
      return true;
    }

    const mDate = moment(date).startOf('day');
    return !!this.activeDates.find(d => mDate.isSame(moment(d).startOf('day')));
  };

  /**
   * Handles calendar date change event and updates currently selected date.
   */
  dateChangeHandler(date: D) {
    this._selectedDate = date;
    this.calendar.activeDate = date;

    this.dateChanged.emit(date);
  }
}
