import { IrisContext } from './iris-context.model';

/**
 * Returns true if the current user is in SmartFiles scope.
 *
 * @param irisContext The current iris context.
 * @returns boolean True if the user in SmartFiles context.
 */
export function isSmartFilesScope(irisContext: IrisContext): boolean {
  return Boolean(irisContext?.selectedScope?._serviceType === 'smartFiles');
}
