import { AppModuleConfig, StateAccessMap, StateAccessContext } from 'src/app/app-module.config';

const firewallAccess = (ctx: StateAccessContext) => ctx.CLUSTER_MODIFY;

export const FirewallConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'networking.firewall.**',
      url: '^/platform/firewall',
      loadChildren: () => import('./firewall.module').then(m => m.FirewallModule)
    },
  ],
  getStateAccessMap(): StateAccessMap {
    return {
      'networking.firewall': firewallAccess,
    };
  }
};
