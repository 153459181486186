import { Component, Input } from '@angular/core';

import { GlobalSearchItem } from '../../models/global-search-item.model';
import { GlobalSearchResultType } from '../../models/global-search-result-type.model';

@Component({
  selector: 'coh-global-search-item-icon',
  templateUrl: './global-search-item-icon.component.html',
  styleUrls: ['./global-search-item-icon.component.scss']
})
export class GlobalSearchItemIconComponent {
  /**
   * The search result item.
   */
  @Input() object: GlobalSearchItem;

  /**
   * The enum of available search result types.
   */
  globalSearchResultType = GlobalSearchResultType;
}
