import { Injectable } from '@angular/core';
import { Transition } from '@uirouter/core';
import { AjsClusterService } from 'src/app/shared/ng-upgrade/services';

import { ScopeSelectorService, UserService } from '../../services';
import { GuardPriority, StateGuard } from '../state-guard';

/**
 * Ensures that the state transition is allowed.
 */
@Injectable({
  providedIn: 'root'
})
export class SafeTransitionGuard implements StateGuard {
  /**
   * This guard should be run before gaurds with App priority
   */
  guardPriority = GuardPriority.SafeTransition;


  constructor(
    private scopeSelectorService: ScopeSelectorService,
    private userService: UserService,
    private ajsClusterService: AjsClusterService,
  ) {}

  /**
   * Perform state access check and hydrate necessary iris shared states like
   * 1. selected remote cluster scope.
   * 2. list of remote cluster.
   *
   * @param  trans  The current Transition object.
   */
  onStart(trans: Transition) {
    // do nothing if not logged or cluster is not created yet because app.js is taking care of those currently.
    if (!this.userService.loginData || !this.ajsClusterService.clusterState.found) {
      return true;
    }

    // Ensures that state transition are happening on a safe context.
    this.scopeSelectorService.ensureSafeTransition(trans);
  }
}
