<cog-table name="recovery-volume-mapping-table"
  [source]="volumeMapping || []">
  <table mat-table
    matSort
    matSortActive="originalVolume"
    matSortDirection="asc"
    [trackBy]="trackByOriginalVolume">

    <ng-container matColumnDef="originalVolume">
      <th mat-header-cell cogDataId="volume-mapping-original-volume-col" *matHeaderCellDef mat-sort-header>
        {{ 'sourceVolume' | translate }}
      </th>
      <td mat-cell *matCellDef="let mapping">
        {{ mapping.originalVolume }}
      </td>
    </ng-container>

    <ng-container matColumnDef="mountedVolume">
      <th mat-header-cell cogDataId="volume-mapping-mounted-volume-col" *matHeaderCellDef mat-sort-header>
        {{ 'targetVolume' | translate }}
      </th>
      <td mat-cell *matCellDef="let mapping">
        {{ mapping.mountedVolume }}
      </td>
    </ng-container>

    <ng-container matColumnDef="fileSystemType">
      <th *matHeaderCellDef cogDataId="volume-mapping-file-system-col" mat-header-cell mat-sort-header>
        {{ 'fileSystem' | translate }}
      </th>
      <td mat-cell *matCellDef="let mapping">
        {{ mapping.fileSystemType }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr mat-row *matRowDef="let row; columns: columns"
      [cogDataId]="'volume-mapping-anchor-' + (row.originalVolume | lowercase)"></tr>
  </table>
</cog-table>
