import { Component, Input, ViewEncapsulation } from '@angular/core';
import { ViewsSummary } from '@cohesity/api/v2';

/**
 * Summary data for Views glance bar.
 *
 * @example
 *   <coh-views-glancebar summaryData="summaryData"></coh-views-glancebar>
 */
@Component({
  selector: 'coh-views-glancebar',
  templateUrl: './views-glancebar.component.html',
  styleUrls: ['./views-glancebar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ViewsGlancebarComponent {
  /**
   * Summary data.
   */
  @Input() summaryData: ViewsSummary;

  constructor() {}

}
