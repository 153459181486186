import { Component, Input, OnInit } from '@angular/core';
import { StateService } from '@uirouter/core';
import { StateManagementService } from 'src/app/core/services';

import { RouterTab } from './router-tab';

/**
 * Wrapper around mat-tabs to integrate ui-router navigation. See RouterTab type
 * for definitions.
 *
 * This Component provides it's own uiView to place rendered states into.
 * However, you can define a target view in your StateDeclaration object and as
 * long as you provide that uiView in the template, that will be used instead.
 *
 * @example
 *   <coh-router-tabs [tabs]="tabsList"></coh-router-tabs>
 */
@Component({
  selector: 'coh-router-tabs',
  styleUrls: ['router-tabs.component.scss'],
  templateUrl: './router-tabs.component.html',
})
export class RouterTabsComponent implements OnInit {
  /**
   * Possible tabs for display, looped through to derive a list of tabs that
   * are accessible to the user.
   */
  @Input() set tabs(tabs: RouterTab[]) {
    this.accessibleTabs = (tabs || []).filter(
      tab => this.stateMgmtService.canUserAccessState(tab.routeName, tab.routeParams)
    );
  }

  /**
   * By default, router tabs render a router outlet. Turn this off to disable it and let the parent component
   * be responsible for the view.
   */
  @Input() renderView = true;

  /**
   * Filtered list of tabs based on user's ability to access them.
   */
  accessibleTabs: RouterTab[] = [];

  // The detected default tab. If not specified in the supplied `tabs` list, the
  // first is used automatically.
  public defaultTab?: RouterTab;

  /**
   * If there is only one tab accessible to the user,
   * hide it since it's not really a tabset.
   */
  @Input() hideSingleTab = false;

  /**
   * Whether to show the tabs or not,
   * based on length of accessibleTabs array
   * and hideSingleTab input.
   */
  get showTabs() {
    return this.accessibleTabs.length > (this.hideSingleTab ? 1 : 0);
  }

  constructor(
    private $state: StateService,
    private stateMgmtService: StateManagementService
  ) { }

  /**
   * Checks if the given tab is the currently active state.
   *
   * @method   isCurrentState
   * @param    tab   The tab to check for currency.
   */
  public isCurrentState(tab: RouterTab): boolean {
    // Using $state.includes rather than $state.is because if any child states
    // are active, we want this tab to show active too.
    // If route params are supplied then it will check for strict equality against
    // the current active route params object.
    return this.$state.includes(tab.routeName, tab.routeParams);
  }

  /**
   * Wrapper around $state.go.
   *
   * @method   changeState
   * @param    tab   The tab & state to transition to.
   */
  public changeState(tab: RouterTab) {
    this.$state.go(tab.routeName, tab.routeParams, tab.routeOptions);
  }

  /**
   * Initialize this Component.
   *
   * @method    ngOnInit
   */
  ngOnInit() {
    const hasActiveTab: boolean = this.accessibleTabs.some((tab) => this.isCurrentState(tab));

    // Find the assigned default tab, or use the first in the list.
    this.defaultTab = this.accessibleTabs.find(tab => tab.isDefault) || this.accessibleTabs[0];

    // If no active route and a default was defined, set that tab active.
    if (!hasActiveTab && this.defaultTab) {
      this.changeState(this.defaultTab);
    }
  }
}
