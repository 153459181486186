import { Injectable } from '@angular/core';
import { UIRouterGlobals } from '@uirouter/core';

import { AppStateService } from './app-state.service';
import { HomeService } from './home.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalSearchRedirectService {

  constructor(
    private appStateService: AppStateService,
    private homeService: HomeService,
    private uiRouterGlobals: UIRouterGlobals,
  ) {
  }

  /**
   * Listen to cluster selection change and transition out of search page if the
   * selected cluster is not compatible.
   */
  initGlobalSearchStateHandler() {
    this.appStateService.selectedScope$.subscribe(cluster => {
      if (this.uiRouterGlobals.current.name === 'search' && cluster?._serviceType === 'dms') {
        // TODO(pg): Instead of going to home, go to the compatible search state
        // with the filters and search string transformed.
        this.homeService.goHome();
      }
    });
  }
}
