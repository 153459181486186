import { AppModuleConfig, StateAccessContext, StateAccessMap } from 'src/app/app-module.config';
import { environment } from 'src/environments/environment';

export const FeatureFlagsConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'feature-flags.**',
      url: '/feature-flags',
      loadChildren: () => import('./feature-flags.module').then(m => m.FeatureFlagsModule),
    },
  ],
  allClusterMap: {
    'feature-flags': {
      singleClusterState: 'feature-flags',
      allClustersState: 'feature-flags',
      globalContext: true,
    },
  },
  getStateAccessMap(): StateAccessMap {
    return {
      'feature-flags': (ctx: StateAccessContext) =>
        environment.production
          // If the app is in production mode, hide feature flags page for all
          // non admin users, tenant users and users on production Helios.
          ? ctx.CLUSTER_MODIFY && !ctx.isTenantUser && !environment.heliosProduction
          : true,
    };
  }
};
