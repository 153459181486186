import { ReportColumnConfigs } from '../common-report-items/custom-reports.service';
import { ReportSection } from '../iris-reporting.model';
import {
  commonReportConfigItems,
  getStorageConsumptionColumns,
  storageConsumptionCommonColumns,
} from './common-report-config-items';

/**
 * This is the layout config for the storage consumption by protection group report.
 */
export const storageConsumptionProtectionGroupReport: ReportSection[] = [
  {
    title: 'reporting.summary',
    items: [
      {
        id: 'statlistItem',
        componentIds: ['401'],
        componentNames: ['StorageConsumptionGlanceBar'],
        params: {
          items: [
            {
              title: 'protectionGroups',
              translate: true,
              measurement: {
                valueKey: 'countGroupId',
                dataType: 'number',
              },
            },
            ...commonReportConfigItems.storageConsumption.glanceBar,
          ],
        },
      },
    ],
  },
  {
    items: [
      {
        id: 'chartItem',
        title: 'reporting.storageConsumedWithResiliencyByType',
        componentIds: ['402'],
        componentNames: ['StorageConsumptionResiliencyByType'],
        params: {
          chartType: 'pie',
          measurements: [
            {
              valueKey: 'sumScResiliencyBytes',
              dataType: 'bytes'
            },
          ],
          dimensions: [
            {
              dimensionKey: 'environment',
              maxValues: 3,
            }
          ],
        },
      },
      {
        id: 'chartItem',
        title: 'reporting.sourceDataStorageEfficiencyByType',
        componentIds: ['403'],
        componentNames: ['SourceDataStorageEfficiencyByType'],
        params: {
          chartType: 'column',
          measurements: [
            {
              valueKey: 'sumDataIngestedRetainedBytes',
              dataType: 'bytes'
            },
            {
              valueKey: 'sumScRetainedBytes',
              dataType: 'bytes'
            },
          ],
          dimensions: [
            {
              dimensionKey: 'environment',
              maxValues: 3,
            }
          ],
        },
      },
    ],
  },
  {
    title: 'reporting.data',
    isFullData: true,
    items: [
      {
        id: 'tableItem',
        componentIds: ['400'],
        componentNames: ['AllStorageConsumptionByProtectionGroups'],
        params: {
          columns: [
            {
              title: 'protectionGroup',
              translate: true,
              key: 'groupName',
              icon: {
                key: 'environment',
                dataType: 'envGroupIcon',
              },
              primary: {
                key: 'groupName',
                dataType: 'text',
              },
              subtitle: {
                key: 'environment',
                dataType: 'environmentName',
              },
            },
            {
              title: 'source',
              enabled: true,
              translate: 'true',
              key: 'sourceNames',
              primary: {
                key: 'sourceNames',
                dataType: 'textList',
                renderParams: {
                  isArray: true,
                },
              },
            },
            {
              title: 'policy',
              enabled: false,
              translate: 'true',
              key: 'policyName',
              primary: {
                key: 'policyName',
                dataType: 'text',
              },
            },
            {
              title: 'system',
              enabled: true,
              translate: 'true',
              key: 'system',
              primary: {
                key: 'system',
                dataType: 'text',
              },
            },
            {
              title: 'organization',
              enabled: false,
              translate: true,
              key: 'tenantName',
              primary: {
                key: 'tenantName',
                dataType: 'text',
                renderParams: {
                  emptyKey: 'naNotAvailable',
                },
              },
              featureFlag: 'ngReportSPView',
            },
            ...getStorageConsumptionColumns('AllStorageConsumptionByProtectionGroups'),
          ],
        },
      },
    ],
  },
];

/**
 * This is metadata needed to determine available chart customizations.
 */
export const storageConsumptionProtectionGroupColumns: ReportColumnConfigs = {
  groupName: {
    aggs: [],
    canGroup: true,
    maxGroupingValues: 5,
    dataType: 'text',
  },
  groupId: {
    aggs: ['count'],
    dataType: 'uuid',
  },
  environment: {
    aggs: ['count'],
    canGroup: true,
    maxGroupingValues: 3,
    dataType: 'text',
  },
  system: {
    aggs: [],
    canGroup: true,
    maxGroupingValues: 5,
    dataType: 'text',
  },
  systemId: {
    aggs: ['count'],
    dataType: 'uuid',
  },
  policyId: {
    aggs: ['count'],
    dataType: 'uuid'
  },
  policyName: {
    aggs: [],
    canGroup: true,
    maxGroupingValues: 5,
    dataType: 'text'
  },
  ...storageConsumptionCommonColumns,
};
