/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DlpIntegration } from '../models/dlp-integration';
import { DlpIntegrationActionParams } from '../models/dlp-integration-action-params';
import { DlpIntegrationActionResponse } from '../models/dlp-integration-action-response';
import { DlpIntegrationId } from '../models/dlp-integration-id';
import { DlpIntegrationIdList } from '../models/dlp-integration-id-list';
import { DlpIntegrationParams } from '../models/dlp-integration-params';
import { DlpVendorTypeList } from '../models/dlp-vendor-type-list';
import { GetDlpIntegrationRunsResponse } from '../models/get-dlp-integration-runs-response';
import { GetDlpIntegrationsResponse } from '../models/get-dlp-integrations-response';

@Injectable({
  providedIn: 'root',
})
export class DcDlpIntegrationsApiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getDlpIntegrations
   */
  static readonly GetDlpIntegrationsPath = '/dlp/integrations';

  /**
   * Get a list of DLP integrations.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDlpIntegrations()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDlpIntegrations$Response(params?: {

    /**
     * Specifies the filter by integration ids.
     */
    ids?: DlpIntegrationIdList;

    /**
     * Specifies filter by vendorTypes for the DLP integration.
     */
    vendorTypes?: DlpVendorTypeList;

  }): Observable<StrictHttpResponse<GetDlpIntegrationsResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DcDlpIntegrationsApiService.GetDlpIntegrationsPath, 'get');
    if (params) {

      rb.query('ids', params.ids, {});
      rb.query('vendorTypes', params.vendorTypes, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetDlpIntegrationsResponse>;
      })
    );
  }

  /**
   * Get a list of DLP integrations.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDlpIntegrations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDlpIntegrations(params?: {

    /**
     * Specifies the filter by integration ids.
     */
    ids?: DlpIntegrationIdList;

    /**
     * Specifies filter by vendorTypes for the DLP integration.
     */
    vendorTypes?: DlpVendorTypeList;

  }): Observable<GetDlpIntegrationsResponse> {

    return this.getDlpIntegrations$Response(params).pipe(
      map((r: StrictHttpResponse<GetDlpIntegrationsResponse>) => r.body as GetDlpIntegrationsResponse)
    );
  }

  /**
   * Path part for operation createDlpIntegration
   */
  static readonly CreateDlpIntegrationPath = '/dlp/integrations';

  /**
   * Create a new DLP integration.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createDlpIntegration()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createDlpIntegration$Response(params: {
      body: DlpIntegrationParams
  }): Observable<StrictHttpResponse<DlpIntegration>> {

    const rb = new RequestBuilder(this.rootUrl, DcDlpIntegrationsApiService.CreateDlpIntegrationPath, 'post');
    if (params) {


      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DlpIntegration>;
      })
    );
  }

  /**
   * Create a new DLP integration.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createDlpIntegration$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createDlpIntegration(params: {
      body: DlpIntegrationParams
  }): Observable<DlpIntegration> {

    return this.createDlpIntegration$Response(params).pipe(
      map((r: StrictHttpResponse<DlpIntegration>) => r.body as DlpIntegration)
    );
  }

  /**
   * Path part for operation getDlpIntegrationById
   */
  static readonly GetDlpIntegrationByIdPath = '/dlp/integrations/{id}';

  /**
   * Get DLP integration by ID.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDlpIntegrationById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDlpIntegrationById$Response(params: {

    /**
     * Specifies the DLP integration id.
     */
    id: DlpIntegrationId;

  }): Observable<StrictHttpResponse<DlpIntegration>> {

    const rb = new RequestBuilder(this.rootUrl, DcDlpIntegrationsApiService.GetDlpIntegrationByIdPath, 'get');
    if (params) {

      rb.path('id', params.id, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DlpIntegration>;
      })
    );
  }

  /**
   * Get DLP integration by ID.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDlpIntegrationById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDlpIntegrationById(params: {

    /**
     * Specifies the DLP integration id.
     */
    id: DlpIntegrationId;

  }): Observable<DlpIntegration> {

    return this.getDlpIntegrationById$Response(params).pipe(
      map((r: StrictHttpResponse<DlpIntegration>) => r.body as DlpIntegration)
    );
  }

  /**
   * Path part for operation updateDlpIntegration
   */
  static readonly UpdateDlpIntegrationPath = '/dlp/integrations/{id}';

  /**
   * Update the DLP integration.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateDlpIntegration()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateDlpIntegration$Response(params: {

    /**
     * Specifies the DLP integration id.
     */
    id: DlpIntegrationId;
      body: DlpIntegrationParams
  }): Observable<StrictHttpResponse<DlpIntegration>> {

    const rb = new RequestBuilder(this.rootUrl, DcDlpIntegrationsApiService.UpdateDlpIntegrationPath, 'put');
    if (params) {

      rb.path('id', params.id, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DlpIntegration>;
      })
    );
  }

  /**
   * Update the DLP integration.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateDlpIntegration$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateDlpIntegration(params: {

    /**
     * Specifies the DLP integration id.
     */
    id: DlpIntegrationId;
      body: DlpIntegrationParams
  }): Observable<DlpIntegration> {

    return this.updateDlpIntegration$Response(params).pipe(
      map((r: StrictHttpResponse<DlpIntegration>) => r.body as DlpIntegration)
    );
  }

  /**
   * Path part for operation deleteDlpIntegration
   */
  static readonly DeleteDlpIntegrationPath = '/dlp/integrations/{id}';

  /**
   * Delete DLP integration.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteDlpIntegration()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteDlpIntegration$Response(params: {

    /**
     * Specifies the DLP integration id.
     */
    id: DlpIntegrationId;

  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, DcDlpIntegrationsApiService.DeleteDlpIntegrationPath, 'delete');
    if (params) {

      rb.path('id', params.id, {});

    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete DLP integration.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteDlpIntegration$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteDlpIntegration(params: {

    /**
     * Specifies the DLP integration id.
     */
    id: DlpIntegrationId;

  }): Observable<void> {

    return this.deleteDlpIntegration$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation performActionOnDlpIntegration
   */
  static readonly PerformActionOnDlpIntegrationPath = '/dlp/integrations/actions';

  /**
   * Perform Actions on DLP Integration.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `performActionOnDlpIntegration()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  performActionOnDlpIntegration$Response(params: {
      body: DlpIntegrationActionParams
  }): Observable<StrictHttpResponse<DlpIntegrationActionResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DcDlpIntegrationsApiService.PerformActionOnDlpIntegrationPath, 'post');
    if (params) {


      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DlpIntegrationActionResponse>;
      })
    );
  }

  /**
   * Perform Actions on DLP Integration.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `performActionOnDlpIntegration$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  performActionOnDlpIntegration(params: {
      body: DlpIntegrationActionParams
  }): Observable<DlpIntegrationActionResponse> {

    return this.performActionOnDlpIntegration$Response(params).pipe(
      map((r: StrictHttpResponse<DlpIntegrationActionResponse>) => r.body as DlpIntegrationActionResponse)
    );
  }

  /**
   * Path part for operation getDlpIntegrationRuns
   */
  static readonly GetDlpIntegrationRunsPath = '/dlp/integrations/{id}/runs';

  /**
   * Get the list of runs for a DLP integration.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDlpIntegrationRuns()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDlpIntegrationRuns$Response(params: {

    /**
     * Specifies the DLP integration id.
     */
    id: DlpIntegrationId;

  }): Observable<StrictHttpResponse<GetDlpIntegrationRunsResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DcDlpIntegrationsApiService.GetDlpIntegrationRunsPath, 'get');
    if (params) {

      rb.path('id', params.id, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetDlpIntegrationRunsResponse>;
      })
    );
  }

  /**
   * Get the list of runs for a DLP integration.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDlpIntegrationRuns$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDlpIntegrationRuns(params: {

    /**
     * Specifies the DLP integration id.
     */
    id: DlpIntegrationId;

  }): Observable<GetDlpIntegrationRunsResponse> {

    return this.getDlpIntegrationRuns$Response(params).pipe(
      map((r: StrictHttpResponse<GetDlpIntegrationRunsResponse>) => r.body as GetDlpIntegrationRunsResponse)
    );
  }

}
