<div>
  <div class="dl-row">
    <dt>{{ 'metastoreAddress' | translate }}</dt>
    <dd>{{ hiveParams?.metastore }}</dd>
  </div>
  <div class="dl-row">
    <dt>{{ 'metastorePort' | translate }}</dt>
    <dd>{{ hiveParams?.thriftPort }}</dd>
  </div>
  <div class="dl-row" *ngIf="hiveParams?.kerberosPrincipal">
    <dt>{{ 'kerberosPrincipal' | translate }}</dt>
    <dd>{{ hiveParams?.kerberosPrincipal }}</dd>
  </div>
</div>
