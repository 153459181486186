import { Component, EventEmitter, Input, Output } from '@angular/core';

/**
 * Show task status or progress bar if it is running.
 *
 * @example
 * <coh-pulse-status [status]="status" [taskPath]="progressTaskId" (taskFinish)="taskFinish.emit()">
 * </coh-pulse-status>
 */
@Component({
  selector: 'coh-pulse-status',
  styleUrls: ['./pulse-status.component.scss'],
  templateUrl: './pulse-status.component.html',
})
export class PulseStatusComponent {
  /**
   * Task status.
   */
  @Input() status: string;

  /**
   * Task path for progress monitoring.
   */
  @Input() taskPath: string;

  /**
   * Notify parent when its progress completes.
   */
  @Output() readonly taskFinish = new EventEmitter<void>();

  /**
   * Flag to show progress bar or not.
   */
  get showProgress(): boolean {
    return (this.status === 'Running' || this.status === 'kRunning') && !!this.taskPath;
  }
}
