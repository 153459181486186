import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { MetricType } from './../../security-shared.models';

/**
 * This pipe returns translated metric type.
 */
@Pipe({
  name: 'metricType',
  pure: true,
})
export class MetricTypePipe implements PipeTransform {
  /**
   * translation key mapping for frequently used chart names.
   */
  private readonly metricTypeTranslations: { [key in MetricType]: string } = {
    [MetricType.kBytesWritten]: 'anomaly.chartMetric.dataWritten',
    [MetricType.kCompressionRatio]: 'anomaly.chartMetric.entropy',
    [MetricType.kDeduplicationRatio]: 'anomaly.chartMetric.dedupeRatio',
    [MetricType.kFilesAdded]: 'anomaly.chartMetric.filesAdded',
    [MetricType.kFilesChanged]: 'anomaly.chartMetric.filesChanged',
    [MetricType.kFilesDeleted]: 'anomaly.chartMetric.filesDeleted',
    [MetricType.kFilesUnchanged]: 'anomaly.chartMetric.filesUnchanged',
  };

  constructor(private translateService: TranslateService) {}

  /**
   * Return the transformed metric type.
   *
   * @param metricType The metric type.
   * @returns The translated metric type.
   */
  transform(metricType: MetricType | string): string | null {
    const translateKey = this.metricTypeTranslations[metricType] || null;

    return translateKey ? this.translateService.instant(translateKey) : null;
  }
}
