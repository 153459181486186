import { Injectable } from '@angular/core';

/**
 * These are all of the strings used by the Helix application. This implementation
 * is split into a separate interface and class so that applications which override
 * these strings will throw an error if they do not implement the entire interface.
 */
export interface HelixIntlStrings {
  clear: string;
  close: string;
  cohesity: string;
  copyToClipboard: string;
  darkModeDisable: string;
  darkModeEnable: string;
  dataFilters: {
    apply: string;
    clearFilters: string;
    filtersColon: string;
    search: string;
    dateRange: {
      timeframe: {
        [key: string]: string;
      };
    };
    moreFilters(count: number);
    appliedFilters(count: number);
    maxRangeWarning(maxRange: number);
  };
  dateRange: {
    startDate: string;
    endDate: string;
    startTime: string;
    endTime: string;
  };
  duration: {
    [key: string]: string;
  };
  noResults: string;
  naNotAvailable: string;
  onlyShowLinesWithDifferences: string;
  open: string;
  search: string;
  selectRow: string;
  settingsListDisabledTooltip: string;
  skipToPrimaryContent: string;
  table: {
    checkBoxHeader: string;
    firstPageLabel: string;
    itemsPerPageLabel: string;
    lastPageLabel: string;
    nextPageLabel: string;
    previousPageLabel: string;
    multiAction: {
      selectTablePageItems: string;
      clearAll: string;
      selectAllItems: (count: number) => string;
      selectedItems: (count: number) => string;
    };
    rangeLabel(start: number, end: number, length: number): string;
    rangeLabelMore(start: number, end: number): string;
    rangeLabelNone(length: number): string;
  };
  timeframe: {
    [key: string]: string;
  };
}

/**
 * Default translation strings for Helix. Applications can override this by specifying
 * a replacement service in their root module that implements HelixIntlStrings.
 *
 * @example
 * // Define a class that implements the service.
 * export class AppIntlService implements HelixIntlStrings {
 * ...
 * }
 *
 *
 * // Configure the app to use the new class.
 * ...
 * providers: [
 *   { provide: HelixIntlService, useClass: AppIntlService }
 * ]
 */
@Injectable({
  providedIn: 'root'
})
export class HelixIntlService implements HelixIntlStrings {
  banner = {
    dismiss: 'Dismiss Notice',
    next: 'Next Notice',
    previous: 'Previous Notice',
    title: 'System Notices'
  };
  clear = 'Clear';
  close = 'Close';
  cohesity = 'Cohesity';
  copyToClipboard = 'Copy to Clipboard';
  darkModeDisable = 'Disable Dark Mode';
  darkModeEnable = 'Enable Dark Mode';
  dataFilters = {
    apply: 'Apply',
    clearFilters: 'Clear',
    filtersColon: 'Filters:',
    search: 'Search',
    searchResult: (count = 0, total = 0) => `Showing ${count} of ${total} results`,
    zeroResult: '0 results',
    dateRange: {
      timeframe: {
        past1Hour: 'Past Hour',
        past12Hours: 'Past 12 Hours',
        past24Hours: 'Past 24 Hours',
        past7Days: 'Past 7 Days',
        past30Days: 'Past 30 Days',
        past60Days: 'Past 60 Days',
        past90Days: 'Past 90 Days',
        past180Days: 'Past 180 Days',
        past365Days: 'Past 365 Days',
        pastMonth: 'Past Month',
        past3Months: 'Past 3 Months',
        past6Months: 'Past 6 Months',
        pastYear: 'Past Year',
        currentMonth: 'Current Month',
        currentYear: 'Current Year',
        day: '1 Day',
        week: '7 Days',
        month: '30 Days',
        quarter: '13 Weeks',
        halfYear: '6 Months',
        year: '1 Year',
        custom: 'Custom',
        forever: 'Forever',
      },
    },
    moreFilters: (count = 0) => {
      let ret = 'More Filters';

      if (count > 0) {
        ret = `${count} ${ret}`;
      }

      return ret;
    },
    appliedFilters: (count = 0) => `${count} Filters Applied`,
    maxRangeWarning: (maxRange) => `Date range limited to ${maxRange} days`
  };
  dateRange = {
    startDate: 'Start Date',
    endDate: 'End Date',
    startTime: 'Start Time',
    endTime: 'End Time',
  };
  duration = {
    y: 'y',
    mo: 'mo',
    w: 'w',
    d: 'd',
    h: 'h',
    m: 'm',
    s: 's'
  };
  next = 'Next';
  noResults = 'No results found';
  naNotAvailable = '-';
  noDataAvailable = 'No data available';
  onlyShowLinesWithDifferences = 'Only Show Lines with Differences';
  open = 'Open';
  previous = 'Previous';
  search = 'Search';
  selected = 'Selected';
  selectRow = 'Select Row';
  settingsListDisabledTooltip = 'Fix invalid form values before closing.';
  skipToPrimaryContent = 'Skip to Primary Content';
  switchTheme= 'Switch Theme';
  table = {
    checkBoxHeader: 'Selection',
    firstPageLabel: 'First Page',
    itemsPerPageLabel: 'Items per page',
    lastPageLabel: 'Last Page',
    nextPageLabel: 'Next Page',
    previousPageLabel: 'Previous Page',
    rangeLabel: (start: number, end: number, length: number) => `${start} - ${end} of ${length}`,
    rangeLabelMore: (start: number, end: number) => `${start} - ${end} of ${end}+`,
    rangeLabelNone: (length: number) => `0 of ${length}`,
    multiAction: {
      allItemsSelected: 'All Items Selected',
      selectTablePageItems: 'Select all on page',
      clearAll: 'Unselect all',
      selectAllItems: count => `Select all ${count} items`,
      selectedItems: count => `Selected ${count} items`
    }
  };
  timeframe = {
    Past1Hour: 'Past Hour',
    Past12Hours: 'Past 12 Hours',
    Past24Hours: 'Past 24 Hours',
    Past7Days: 'Past 7 Days',
    Past30Days: 'Past 30 Days',
    Past60Days: 'Past 60 Days',
    Past90Days: 'Past 90 Days',
    Past180Days: 'Past 180 Days',
    Past365Days: 'Past 365 Days',
    PastMonth: 'Past Month',
    Past3Months: 'Past 3 Months',
    Past6Months: 'Past 6 Months',
    PastYear: 'Past Year',
    CurrentMonth: 'Current Month',
    CurrentYear: 'Current Year',
    Day: '1 Day',
    Week: '7 Days',
    Month: '30 Days',
    Quarter: '13 Weeks',
    HalfYear: '6 Months',
    Year: '1 Year',
    Custom: 'Custom',
    Forever: 'Forever',
  };
  today = 'Today';
}
