import { Component, Input } from '@angular/core';
import { SnapshotTag } from '@cohesity/api/v2';

@Component({
  selector: 'dg-ar-anomaly-tags',
  templateUrl: './anomaly-tags.component.html',
  styleUrls: ['./anomaly-tags.component.scss'],
})
export class AnomalyTagsComponent {
  /**
   * Anomaly tags
   */
  @Input() tags: SnapshotTag[];
}
