<!-- ACL Permissions component-->
<button
  *ngIf="!readOnly"
  mat-stroked-button
  class="margin-bottom"
  color="primary"
  type="button"
  cogDataId="add-new-{{shares ? 'share-level' : 'ntfs'}}-permission-action"
  (click)="addPermission()">
  {{'add' | translate}}
</button>

<cog-table name="acl-permisions-list-table"
  *ngIf="(permissionList?.length > 0) && domainPrincipalsHash"
  [source]="permissionList">
  <table mat-table>
    <ng-container matColumnDef="principal">
      <th mat-header-cell cogDataId="principal-col" *matHeaderCellDef>
        {{'principal' | translate}}
      </th>
      <td mat-cell *matCellDef="let item" cogDataId="sid-{{item.sid}}">
        {{ getPrincipalName(item) }}
      </td>
    </ng-container>

    <ng-container matColumnDef="domain">
      <th mat-header-cell cogDataId="domain" *matHeaderCellDef>
        {{'domain' | translate}}
      </th>
      <td mat-cell *matCellDef="let item" cogDataId="permission-{{item.type}}">
        {{domainPrincipalsHash[item.sid]?.domain || ('naNotAvailable' | translate)}}
      </td>
    </ng-container>

    <ng-container matColumnDef="type">
      <th mat-header-cell cogDataId="permission-type" *matHeaderCellDef>
        {{'type' | translate}}
      </th>
      <td mat-cell *matCellDef="let item" cogDataId="permission-{{item.type}}">
        {{smbPermissions.types[item.type] | translate}}
      </td>
    </ng-container>

    <ng-container matColumnDef="appliesTo">
      <th mat-header-cell cogDataId="applies-to-col" *matHeaderCellDef>
        {{'appliesTo' | translate}}
      </th>
      <td mat-cell *matCellDef="let item" cogDataId="principal-mode-{{item.mode}}">
        {{smbPermissions.modes[item.mode] | translate}}
      </td>
    </ng-container>

    <ng-container matColumnDef="permissions">
      <th mat-header-cell cogDataId="permissions-col" *matHeaderCellDef>
        {{'permissions' | translate}}
      </th>
      <td mat-cell *matCellDef="let item" cogDataId="permission-access-{{item.access}}">
        {{smbPermissions.access[item.access] | translate}}
      </td>
    </ng-container>

    <!-- Actions Menu -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell id="item-actions-col" *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let item; index as i" (click)="$event.stopPropagation()">
        <button mat-icon-button
          type="button"
          *ngIf="!readOnly"
          (click)="removePermission(item)"
          cogDataId="remove-{{shares ? 'share-level' : 'ntfs-level'}}-permission-{{i}}-button">
          <cog-icon shape="helix:clear"></cog-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</cog-table>

<div class="c-message-inline icon-message warn"
  *ngIf="!permissionList?.length">
  <span *ngIf="shares">{{'shares.warning.noPermissionsSpecified' | translate}}</span>
  <span *ngIf="!shares">{{'smbPermissions.warning.noPermissionsSpecified' | translate}}</span>
</div>
