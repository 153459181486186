import { Classification, ThreatProtection } from '@cohesity/api/v1';

import { daysRemaining, getEntitlementInfo } from './common-utils';
import { IrisContext } from './iris-context.model';
import { EntitlementKeys } from '../app-configs';


/** Datahawk product page link */
export const dgProductPage = 'https://www.cohesity.com/products/datahawk/';

/** Data hawk SKUs */
export enum SkuType {
  threatProtect = 'threatProtection',
  dataClassification = 'dataClassification'
}

/**
 * Checks if user can access Data Security Posture Management related components
 *
 * @param irisContext Iris context
 * @returns True if DSOM user
 */
export function isDspmUser(irisContext: IrisContext): boolean {
  return hasDataClassificationSku(irisContext) && irisContext?.user?.salesforceAccount?.isDMaaSUser;
}

/**
 * Returns true if the current user is a DGaaS (Data Governance) configured user
 * that is currently active, or is an expired DGaaS user.
 *
 * @param irisContext The current iris context.
 * @param includeExpired Whether to take into account if user has expired subscription.
 * @returns boolean True if user is DGaaS user.
 */
export function isDGaaSUser(irisContext: IrisContext, includeExpired = false): boolean {
  return hasDataClassificationEntitlement(irisContext) ?
    Boolean(irisContext?.user?.salesforceAccount?.isDGaaSUser) &&
    (hasDataClassificationSku(irisContext, includeExpired) || hasThreatProtectionSku(irisContext, includeExpired)) :
    Boolean(irisContext?.user?.salesforceAccount?.isDGaaSUser);
}

/**
 * Returns true if the current user is in DGaaS scope and has active subscription.
 * By passing an additional parameter for includeExpired, this function can be used
 * to deterine if the user has an expired subscription, which is useful to warn
 * returning user of expired subscription.
 *
 * @param irisContext The current iris context.
 * @param includeExpired Whether to take into account if user has expired subscription.
 * @returns boolean True if the user in DGaaS context.
 */
export function isDGaasScope(irisContext: IrisContext, includeExpired = false): boolean {
  return Boolean(isDGaaSUser(irisContext, includeExpired) && irisContext?.selectedScope?._serviceType === 'dgaas');
}

/**
 * Returns true if there is any extra entitlement information from the crm service
 *
 * @param irisContext The current iris context.
 * @returns true if any extra information is available on entitlement
 */
export function hasDataClassificationEntitlement(irisContext: IrisContext): boolean {
  return !!getEntitlementInfo(irisContext, EntitlementKeys.Classification);
}


/**
 * Provides the details for classification subscription.
 *
 * @param irisContext The current iris context.
 * @returns Classification if the current users is subscribed to classification entitlement or null.
 */
export function dataClassificationEntitlement(irisContext: IrisContext): Classification | null {
  return irisContext?.user?.subscriptionInfo?.classification;
}

/**
 * Indicates if the user allowed to view classification details.
 * This method falls back to data govern entitlement if user is not subscribed to classification
 *
 * @param irisContext The current iris context.
 * @param includeExpired Whether to take into account if user has expired subscription.
 * @returns boolean True if user is allowed to view classification details.
 */
export function hasDataClassificationSku(irisContext: IrisContext, includeExpired = false): boolean {
  return Boolean(includeExpired ?
    dataClassificationEntitlement(irisContext) :
    dataClassificationEntitlement(irisContext)?.isActive);
}

/**
 * Indicates if showing classification subscription expiry banner is necessary.
 *
 * @param irisContext The current iris context.
 * @returns boolean True if classification subscription expiries in 10 days or less.
 */
export function canShowDataClassificationSubExpiryBanner(irisContext: IrisContext): boolean {
  return isDataClassificationFreeTrialAccount(irisContext) && dataClassificationDaysRemaining(irisContext) <= 10;
}

/**
 * Indicates if the user is currently using a classification free trial.
 *
 * @param irisContext The current iris context.
 * @returns boolean True if user is using a classification free trial.
 */
export function isDataClassificationFreeTrialAccount(irisContext: IrisContext): boolean {
  return Boolean(dataClassificationEntitlement(irisContext)?.isFreeTrial);
}

/**
 * Provides the number of days remaining in classification free trial or subscription.
 *
 * @param irisContext The current iris context.
 * @returns number of days remaining in classification free trial or subscription.
 */
export function dataClassificationDaysRemaining(irisContext: IrisContext): number | null {
  const entitlement = dataClassificationEntitlement(irisContext);
  return daysRemaining(entitlement?.endDate);
}

/**
 * Indicates if the user is using a classification free trial that has expired.
 *
 * @param irisContext The current iris context.
 * @returns boolean True if classification free trial has expired.
 */
export function isDataClassificationFreeTrailExpired(irisContext: IrisContext): boolean {
  const entitlement = dataClassificationEntitlement(irisContext);
  return entitlement?.isFreeTrial && !entitlement?.isActive;
}

/**
 * Indicates if the user is using a classification subscription that has expired.
 *
 * @param irisContext The current iris context.
 * @returns boolean True if classification subscription has expired.
 */
export function isDataClassificationExpired(irisContext: IrisContext): boolean {
  const entitlement = dataClassificationEntitlement(irisContext);
  return !entitlement?.isActive && daysRemaining(entitlement?.endDate) === 0;
}

/**
 * Provides the details for threat protection subscription.
 *
 * @param irisContext The current iris context.
 * @returns Threat Protection if the current users is subscribed to classification entitlement or null.
 */
export function threatProtectionEntitlement(irisContext: IrisContext): ThreatProtection | null {
  return irisContext?.user?.subscriptionInfo?.threatProtection;
}

/**
 * Indicates if the user allowed to view Threat protection details.
 * This method falls back to data govern entitlement if user is not subscribed to threat protection
 *
 * @param irisContext The current iris context.
 * @param includeExpired
 * @returns boolean True if user is allowed to view threat protection details.
 */
export function hasThreatProtectionSku(irisContext: IrisContext, includeExpired = false): boolean {
  return Boolean(includeExpired ?
    threatProtectionEntitlement(irisContext) :
    threatProtectionEntitlement(irisContext)?.isActive);
}

/**
 * Indicates if showing threat protection subscription expiry banner is necessary.
 *
 * @param irisContext The current iris context.
 * @returns boolean True if threat protection subscription expiries in 10 days or less.
 */
export function canShowThreatProtectionSubExpiryBanner(irisContext: IrisContext): boolean {
  return threatProtectionEntitlement(irisContext) && threatProtectionDaysRemaining(irisContext) <= 10;
}

/**
 * Indicates if the user is currently using a threat protection free trial.
 *
 * @param irisContext The current iris context.
 * @returns boolean True if user is using a threat protection free trial.
 */
export function isThreatProtectionFreeTrialAccount(irisContext: IrisContext): boolean {
  return Boolean(threatProtectionEntitlement(irisContext)?.isFreeTrial);
}

/**
 * Provides the number of days remaining in threat protection free trial or subscription.
 *
 * @param irisContext The current iris context.
 * @returns number of days remaining in threat protection free trial or subscription.
 */
export function threatProtectionDaysRemaining(irisContext: IrisContext): number | null {
  const entitlement = threatProtectionEntitlement(irisContext);
  return daysRemaining(entitlement?.endDate);
}

/**
 * Indicates if the user is using a threat protection free trial that has expired.
 *
 * @param irisContext The current iris context.
 * @returns boolean True if threat protection free trial has expired.
 */
export function isThreatProtectionTrailExpired(irisContext: IrisContext): boolean {
  const entitlement = threatProtectionEntitlement(irisContext);
  return entitlement?.isFreeTrial && !entitlement?.isActive;
}

/**
 * Indicates if the user is using a threat protection subscription that has expired.
 *
 * @param irisContext The current iris context.
 * @returns boolean True if threat protection subscription has expired.
 */
export function isThreatProtectionExpired(irisContext: IrisContext): boolean {
  const entitlement = threatProtectionEntitlement(irisContext);
  return !entitlement?.isActive && daysRemaining(entitlement?.endDate) === 0;
}

/**
 * Indicates if the given sku is active or not for the user
 *
 * @param irisContext The current iris context.
 * @param sku sku to check
 * @returns boolean True if threat protection subscription has expired.
 */
export function hasSku(irisContext: IrisContext, sku: SkuType): boolean {
  switch (sku) {
    case SkuType.dataClassification:
      return hasDataClassificationSku(irisContext);
    case SkuType.threatProtect:
      return hasThreatProtectionSku(irisContext);
    default:
      return false;
  }
}

/**
 * Indicates if the given sku is expired or not for the user
 *
 * @param irisContext The current iris context.
 * @param sku sku to check
 * @returns boolean True if threat protection subscription has expired.
 */
export function isSkuExpired(irisContext: IrisContext, sku: SkuType): boolean {
  switch (sku) {
    case SkuType.dataClassification:
      return !isDataClassificationExpired(irisContext);
    case SkuType.threatProtect:
      return !isThreatProtectionExpired(irisContext);
    default:
      return false;
  }
}

// TODO: usage limit reached on sku check function
