import {
  AfterViewInit,
  Component, ElementRef, EventEmitter,
  Output, ViewChild
} from '@angular/core';
import { FormControl, FormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { AutoDestroyable } from '@cohesity/utils';

@Component({
  selector: 'coh-upload-import-external-certificate-interface',
  templateUrl: './upload-import-external-certificate-interface.component.html',
  styleUrls: ['./upload-import-external-certificate-interface.component.scss'],
})
export class UploadImportExternalCertificateInterfaceComponent extends AutoDestroyable implements AfterViewInit {

  @Output()
  interfaceDataFile:
    EventEmitter<any> = new EventEmitter<any>();

  status = 'beforeUpload';

  formGroup = new FormGroup({
    uploadFile: new UntypedFormControl(null),
    passcode: new FormControl(null, Validators.required),
  });

  uploadFileName: string;

  /**
   * Reference to input of file type.
   */
  @ViewChild('fileInput') fileInput: ElementRef;

  constructor() {
    super();
  }

  /**
   * AfterViewInit lifecycle hook.
   */
  ngAfterViewInit () {
    this.formGroup.get('passcode').valueChanges.pipe(
      this.untilDestroy(),
    ).subscribe(() => {
      this.handleInterfaceData();
    });
  }

  /**
   * Send a object with content, file name and pass code
   */
  handleInterfaceData() {
    this.interfaceDataFile.emit(
      {
        uploadFile: this.formGroup.controls.uploadFile.value,
        uploadFileName: this.uploadFileName,
        passcode: this.formGroup.controls.passcode.value,
      }
    );
  }

  /**
   * Handler for upload button click.
   */
  uploadClicked() {
    this.fileInput.nativeElement.click();
  }

  /**
   * Handler for file input change event.
   *
   * @param   event    Change event
   */
  fileInputChanged(event: Event) {
    const fileInput = event.target as HTMLInputElement;
    const files = fileInput.files || new FileList();
    const file = (fileInput).files[0];
    const reader = new FileReader();

    reader.onload = () => {
      console.log('File path to be used on API Integration: ', this.formGroup.controls.uploadFile.value);
      console.log('File content to be used on API Integration: ', reader.result);
    };

    reader.readAsText(file);
    this.uploadFileName = files[0].name;
    this.status = 'afterUpload';
  }

  /**
   * Handler for file input delete.
   *
   */
  deleteFile() {
    this.uploadFileName = '';
    this.formGroup.patchValue({uploadFile: null});
    this.formGroup.patchValue({passcode: null});

    this.interfaceDataFile.emit(
      {
        uploadFile: this.formGroup.controls.uploadFile.value,
        uploadFileName: this.uploadFileName,
        passcode: this.formGroup.controls.passcode.value,
      }
    );
  }
}
