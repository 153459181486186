// Component: cTreeNodeNodePub

;(function(angular, undefined) {
  'use strict';

  var modName = 'C.entityTree';
  var componentName = 'cEntityTreeNodePub';
  var options = {
    bindings: {

      /**
       * Required protection Job setting.
       *
       * @type   {Object}   job    protection job setting
       */
      job: '=?',

      /**
       * Required sources Node
       *
       * @type   {Object[]}   tree    list of sources
       */
      node: '=',

      /**
       * Optional provide whole tree reference to update node's ancestors when
       * it is removed.
       *
       * @type   {Object}   jobTree   The whole tree reference.
       */
      jobTree: '=?',

      /**
       * Optional options setting.
       *
       * @type   {Object}   options    custom options for entity tree
       */
      options: '=?',
    },
    controller: 'EntityTreeNodePubCtrl',
    templateUrl: 'app/global/c-entity-tree/c-entity-tree-node-pub.html',
  };

  /**
   * default entity tree options
   *
   * @type  {Object}   defaultOptions
   * @type  {boolean}  defaultOptions.isExcludedBranch      is excluded branch
   * @type  {boolean}  defaultOptions.isRootNode            is root node
   */
  var defaultOptions = {
    isExcludedBranch: false,
    isRootNode: true,
  };

  angular
    .module(modName)
    .controller('EntityTreeNodePubCtrl', cEntityTreeNodeControllerFn)
    .component(componentName, options);

  /**
   * @ngdoc component
   * @name C.entityTree:cEntityTreeNode
   * @function
   *
   * @description
   * component to display a tree branch/node on page
   *
   * @example
     <c-entity-tree-node-pub
      job="$ctrl.job"
      node="node"
      options="$ctrl.options"></c-entity-tree-node-pub>
  */
  function cEntityTreeNodeControllerFn(
    _, PubSourceServiceUtil, PubJobServiceFormatter, PubJobService,
    ENUM_HOST_TYPE, ENV_GROUPS, PUB_TO_PRIVATE_ENV_STRUCTURES, FEATURE_FLAGS) {

    var $ctrl = this;
    var onUpdate;

    /**
     * Initialization function.
     *
     * @method   $onInit
     */
    $ctrl.$onInit = function $onInit() {
      _.assign($ctrl, {
        ENUM_HOST_TYPE: ENUM_HOST_TYPE,
        ENV_GROUPS: ENV_GROUPS,
        FEATURE_FLAGS: FEATURE_FLAGS,
        environment: $ctrl.node.protectionSource.environment,
      });

      $ctrl.options = angular.merge({}, defaultOptions, ($ctrl.options || {}));

      onUpdate = (typeof $ctrl.options.onUpdate === 'function') ?
        $ctrl.options.onUpdate : angular.noop;
    };

    /**
     * Launches interface (via cSlideModal) to edit backupParamas for the
     * provided node. On successful resolve of cSlideModal, saves the
     * backupParms to a temp params object for later processing.
     *
     * @method   editBackupParams
     */
    $ctrl.editBackupParams = function editBackupParams() {
      PubJobService.editBackupParams($ctrl.node, $ctrl.job);
    };

    /**
     * Determines which node removal action to take and executes it.
     *
     * @method   removeFromJob
     * @param    {object}   node   The node to remove.
     */
    $ctrl.removeFromJob = function removeFromJob(node) {
      // If this is a SQL System DB, use this action to remove mulitple items
      // simultaneously.
      if (node._isSystemDb) {
        // Uses _removeNodeFromJob internally.
        return _removeSystemDbsFromJob(node);
      }

      // Otherwise, remove just this one node.
      _removeNodeFromJob(node);
    };

    /**
     * Removes the given node from the job.
     *
     * @method   _removeNodeFromJob
     * @param    {object}   node   The node to remove from the Job.
     */
    function _removeNodeFromJob(node) {
      var isSelected = node._isSelected;
      var actionFn = node._isTagBranch ?
        findAndRemoveTagSource : findAndRemoveStandardNode.bind(null, node);
      var jobArr = isSelected ? 'sourceIds' : 'excludeSourceIds';
      var indexToRemove;

      if (node._isTagBranch) {
        jobArr = isSelected ? 'vmTagIds' : 'excludeVmTagIds';
      }

      indexToRemove = _.findIndex($ctrl.job[jobArr], actionFn);

      if (indexToRemove !== -1) {
        $ctrl.job[jobArr].splice(indexToRemove, 1);

        // if its a pseudo tag tree, remove it
        if (node._type === 'kTag') {
          PubJobServiceFormatter.removeTagBranch(
            node.protectionSource.id, $ctrl.jobTree.tree);
        }
      }

      onUpdate();
    }

    /**
     * Given one System DB node, finds the rest of the selected System DB ndoes
     * from teh same SQL Instance and removes them simultaneously.
     *
     * @method   _removeSystemDbsFromJob
     * @param    {any}   node   The starting node to use for removing all system
     *                          dbs of the same instance.
     */
    function _removeSystemDbsFromJob(node) {
      // Exit early if the given node isn't a systemDb. This shouldn't happen.
      if (!node._isSystemDb) { return; }

      _.forEach(
        $ctrl.job._selectedSources,
        function removeSystemDbsOnSameInstance(otherNode) {
          // If this iteration node is a system db AND on the same instance,
          // remove it.
          if (otherNode._isSystemDb &&
            PubSourceServiceUtil.isSameSqlInstance(node, otherNode)) {
            _removeNodeFromJob(otherNode);
          }
        }
      );
    }

    /**
     * excludes the node (and sub nodes) from auto protection
     *
     * @method    excludeNode
     * @param     {object}  [$event]   DOM click event
     */
    $ctrl.excludeNode = function excludeNode($event) {
      if ($event) {
        $event.stopPropagation();
      }

      $ctrl.job.excludeSourceIds.push($ctrl.node.protectionSource.id);

      PubJobServiceFormatter.unselectNode($ctrl.node, {
        autoProtect: $ctrl.node._isAncestorAutoProtected,
        tagAutoProtect: $ctrl.node._isTagAutoProtected,
        excluding: true,

        selectedObjectsCounts: $ctrl.jobTree.selectedCounts,
        tree: $ctrl.jobTree.tree,
      });

      onUpdate();
    };

    /**
     * Determines whether to show the edit icon in a given tree node.
     *
     * TODO: Decrease the complexity of this logic.
     *
     * @method     showTreeNodeEditIcon
     * @return     {boolean}  returns true if should show edit icon
     */
    $ctrl.showTreeNodeEditIcon = function showTreeNodeEditIcon() {
      var protectionSource = $ctrl.node.protectionSource;
      var jobEnvironment = $ctrl.job.environment;
      var kPhysicalEntityKey =
        PUB_TO_PRIVATE_ENV_STRUCTURES.kPhysical.publicEntityKey;

      /**
       * NOTE: Per ENG-19121, temporarily hide the edit icon for SQL jobs
       * because QA hasn't qualified it and will not attempt to qualify it until
       * a customer asks for the feature. Once ready to qualify, remove the
       * following line of code: $ctrl.job.environment === 'kSQL' &&
        $ctrl.job._parentSource.protectionSource.environment === 'kVMware'
       */

      return !(jobEnvironment === 'kSQL' &&
        $ctrl.job._parentSource.protectionSource.environment === 'kVMware') &&

        // Job type is NOT Physical File-based AND
        jobEnvironment !== 'kPhysicalFiles' &&

        // Object can host MS SQL Server AND
        ((jobEnvironment === 'kSQL' &&
          $ctrl.ENV_GROUPS.sqlHosts.includes(protectionSource.environment)) ||
          jobEnvironment !== 'kSQL') &&

        // Object is not MS SQL Cluster AND
        (!protectionSource[kPhysicalEntityKey] ||
        protectionSource[kPhysicalEntityKey].type !== 'kWindowsCluster') &&

        // Object is a physical host or a virtual host or a oracle database AND
        (
          ($ctrl.node._environment === 'kVMware' &&
            $ctrl.node._type === 'kVirtualMachine') ||
          ($ctrl.node._environment === 'kPhysical' &&
            $ctrl.node._type === 'kHost') ||
          (FEATURE_FLAGS.oracleMultiNodeChannelBackup &&
            $ctrl.node._environment === 'kOracle' &&
            $ctrl.node._type === 'kDatabase')
        ) &&

        // For SQL job, it needs to be volume based and not a VMware host AND
        (
          jobEnvironment !== 'kSQL' ||
          (_.get($ctrl.job, '_envParams.backupType') === 'kSqlVSSVolume' &&
            $ctrl.node._environment !== 'kVMware')
        ) &&

        // Object is selected AND
        $ctrl.node._isSelected &&

        // Action icons enabled AND
        $ctrl.options.showActionIcons &&

        // Not an excluded branch
        !$ctrl.options.isExcludedBranch;
    };

    /**
     * Find and remove a standard node from the job.
     *
     * NOTE: This can be used as an Array#some iterator using
     *   list.some(findAndRemoveStandardNode.bind(null, node))
     *
     * @method   findAndRemoveStandardNode
     * @param    {object}    thisNode    The "fixed" node to compare against.
     * @param    {number}    sourceId    The current sourceId
     * @return   {boolean}   True if the source id was found and removed, false
     *                       otherwise. False will stop iteration.
     */
    function findAndRemoveStandardNode(thisNode, sourceId) {
      var selectionFn;

      if (sourceId === thisNode.protectionSource.id) {
        selectionFn = thisNode._isSelected ?
          PubJobServiceFormatter.unselectNode :
          PubJobServiceFormatter.selectNode;

        // if node is getting removed from job then cleanup node source special
        // parameters as well
        _removeSourceSpecialParams(thisNode);

        selectionFn(thisNode, {
          // common options
          autoProtect: thisNode._isAutoProtected || thisNode._isAncestorAutoProtected,
          tagAutoProtect: thisNode._isTagAutoProtected,

          // select only options
          ancestorAutoProtect: thisNode._isAncestorAutoProtected,
          unexclude: thisNode._isExcluded,

          // unselect only options
          excluding: false,


          tree: $ctrl.jobTree.tree,
          selectedObjectsCounts: $ctrl.jobTree.selectedCounts,
        });

        return true;
      }

      return false;
    }

    /**
     * used in conjunction with array iterator (Array.prototype.some()) to find
     * and remove a tag(s) from the job.
     *
     * @method   findAndRemoveTagSource
     * @param    {number}    tagIds     The current tagIds
     * @param    {number}    index      The index
     * @param    {array}     idsArray   The vmTagIds/excludeVmTagIds array being
     *                                  walked
     * @return   {boolean}   true if the tags were identified and remove, false
     *                       otherwise. indicates if the iteration should stop
     */
    function findAndRemoveTagSource(tagIds, index, idsArray) {
      var foundTagIds;
      var selectionFn;

      if (tagIds.length !== $ctrl.node.protectionSource.id.length) {
        return false;
      }

      foundTagIds = angular.equals(tagIds, $ctrl.node.protectionSource.id);

      if (foundTagIds) {
        selectionFn = $ctrl.node._isSelected ?
          PubJobServiceFormatter.unselectNode :
          PubJobServiceFormatter.selectNode;

        // walk and unselect children of the tag
        $ctrl.node.nodes.forEach(function unselectChildren(child) {
          // if node is getting removed from job then cleanup node source special
          // parameters as well
          if (child._isSelected) {
            PubJobServiceFormatter
              .removeSourceSpecialParameters($ctrl.job, child);
          }

          /**
           * if removing tag auto protection rule (node._isSelected === true)
           * then un-select all the descendent protected nodes.
           *
           * if removing tag exclusion rule (node._isSelected === false)
           * then select the node which is part of already auto protected branch
           */
          if ($ctrl.node._isSelected || child._isAutoProtectedDescendant) {
            selectionFn(child, {
              // common options
              autoProtect: child._isAutoProtected,
              tagAutoProtect: child._isTagAutoProtected || child._isTagExcluded,

              // select only options
              ancestorAutoProtect: child._isAncestorAutoProtected,
              unexclude: child._isExcluded,

              // unselect only options
              excluding: false,

              tree: $ctrl.jobTree.tree,
              selectedObjectsCounts: $ctrl.jobTree.selectedCounts,
            });
          }
        });
      }

      return foundTagIds;
    }

    /**
     * Recursively looks into nodes property (array of objects) to cleanup node
     * source special parameters
     *
     * @method  _removeSourceSpecialParams
     * @param   {object}  node  The node to be removed
     */
    function _removeSourceSpecialParams(node) {

      if (node._isSelected && node._isLeaf) {
        PubJobServiceFormatter.removeSourceSpecialParameters($ctrl.job, node);
      }

      if (_.isArray(node.nodes)) {
        node.nodes.forEach(_removeSourceSpecialParams);
      }
    }

  }

})(angular);
