<h4>{{ 'generateCertificateSigningRequestInterface.title' | translate }}</h4>

<div class="form" [formGroup]="formGroup">
    <mat-form-field class="input">
        <mat-label>{{ 'generateCertificateSigningRequestInterface.commonName' | translate }}</mat-label>
        <input matInput formControlName="commonName">
        <mat-error *ngIf="formGroup.get('commonName').hasError('required')">
            {{ 'errors.required' | translate }}
        </mat-error>
    </mat-form-field>

    <mat-form-field class="input">
        <mat-label>{{'generateCertificateSigningRequestInterface.subjectAlternativeValues' | translate}}</mat-label>
        <mat-chip-list #subjectiveAlternativeNameList>
            <mat-chip
                *ngFor="let name of subjectiveAlternativeNameValues; index as i"
                cogDataId="subjective-alternative-name-input-chip-{{i}}"
                [inheritDataId]="true"
                (removed)="removeSubjectiveAlternativeName(i)"
            >
                {{name}}
                <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
            <input
                matInput
                [matChipInputFor]="subjectiveAlternativeNameList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                [matChipInputAddOnBlur]="true"
                (matChipInputTokenEnd)="addSubjectiveAlternativeName($event)"
                (paste)="pasteSubjectiveAlternativeNameInput($event)"
                (blur)="validateSubjectiveAlternativeNameFormControl()"
                contenteditable='true'
                cogDataId="subjective-alternative-name-input"
                [inheritDataId]="true">
            <mat-error *ngIf="formGroup.get('subjectiveAlternativeName').hasError('required')">
                {{ 'errors.required' | translate }}
            </mat-error>
        </mat-chip-list>
    </mat-form-field>

    <mat-form-field class="input">
        <mat-label>{{ 'generateCertificateSigningRequestInterface.organization' | translate }}</mat-label>
        <input matInput formControlName="organization">
        <mat-error *ngIf="formGroup.get('organization').hasError('required')">
            {{ 'errors.required' | translate }}
        </mat-error>
    </mat-form-field>

    <mat-form-field class="input">
        <mat-label>{{ 'generateCertificateSigningRequestInterface.departament' | translate }}</mat-label>
        <input matInput formControlName="department">
        <mat-error *ngIf="formGroup.get('department').hasError('required')">
            {{ 'errors.required' | translate }}
        </mat-error>
    </mat-form-field>

    <mat-form-field class="input">
        <mat-label>{{ 'generateCertificateSigningRequestInterface.city' | translate }}</mat-label>
        <input matInput formControlName="city">
        <mat-error *ngIf="formGroup.get('city').hasError('required')">
            {{ 'errors.required' | translate }}
        </mat-error>
    </mat-form-field>

    <mat-form-field class="input">
        <mat-label>{{ 'generateCertificateSigningRequestInterface.state' | translate }}</mat-label>
        <input matInput formControlName="state">
        <mat-error *ngIf="formGroup.get('state').hasError('required')">
            {{ 'errors.required' | translate }}
        </mat-error>
    </mat-form-field>

    <mat-form-field class="input">
        <mat-label>{{ 'generateCertificateSigningRequestInterface.country' | translate }}</mat-label>
        <input matInput formControlName="country">
        <mat-error *ngIf="formGroup.get('country').hasError('required')">
            {{ 'errors.required' | translate }}
        </mat-error>
    </mat-form-field>
</div>