import { SelectionModel } from '@angular/cdk/collections';
import { Injectable } from '@angular/core';
import { ProtectionSourceNode } from '@cohesity/api/v1';
import { IrisContextService, isDmsScope } from '@cohesity/iris-core';
import { from, Observable, of, Subject } from 'rxjs';
import { catchError, finalize, map, take } from 'rxjs/operators';
import { DialogService, PassthroughOptionsService } from 'src/app/core/services';
import { AjsUpgradeService } from 'src/app/core/services/ajs-upgrade.service';

import { ENV_GROUPS, envTypes } from '../../constants';
import { DecoratedProtectionSourceNode } from '../../models/decorated-protection-source.model';

export interface ProtectionSourceGroup {
  groupName: string;
  sources: ProtectionSourceNode[];
  sourceType: string;
}

@Injectable({
  providedIn: 'root',
})
export class ParentSourceSelectorService {
  /**
   * Instances of legacy Ajs Source and PubSource services.
   */
  private ajsSourceService: any;
  private ajsPubSourceService: any;

  /**
   * Used to track the selected targets across all component instances.
   */
  private selection = new SelectionModel<number>(true, []);

  /**
   * Subject to keep track whether sources is loaded.
   */
  sourcesLoaded$ = new Subject<boolean>();

  /**
   * Creates a new service.
   *
   * @param   ajsUpgrade         The ajs upgrade service to get legacy services.
   */
  constructor(
    ajsUpgrade: AjsUpgradeService,
    readonly dialogService: DialogService,
    readonly irisCtx: IrisContextService,
    private passthroughOptionsService: PassthroughOptionsService,
  ) {
    this.ajsSourceService = ajsUpgrade.get('SourceService');
    this.ajsPubSourceService = ajsUpgrade.get('PubSourceService');
  }

  /**
   * Sets the selection for a protection source.
   *
   * @param   sourceId    The protection source id to select.
   * @param   selected   Whether to select or deselect the source.
   */
  public setSelection(sourceId: number, selected: boolean) {
    if (selected) {
      this.selection.select(sourceId);
    } else {
      this.selection.deselect(sourceId);
    }
  }

  /**
   * Checks if a protection source is selected or not.
   *
   * @param    sourceId   The protection source id to check.
   * @return   Whether the source id is selected or not.
   */
  public isSelected(sourceId: number): boolean {
    return this.selection.isSelected(sourceId);
  }

  /**
   * Fetch the protection source nodes.
   *
   * @param   environments   The list of supported environments.
   * @param   onlyOwn        Whether get own root nodes or all.
   * @return  Observable of DecoratedProtectionSourceNode array.
   */
  fetchSources(environments: string[], onlyOwn: boolean = false): Observable<DecoratedProtectionSourceNode[]> {
    // Reset status before fetching sources.
    this.sourcesLoaded$.next(false);

    // Get a fn to get own root nodes or all based on onlyOwn.
    const getRootNodeFn = this.ajsPubSourceService[onlyOwn ?
      'getOwnRootNodes' : 'getRootNodes'];

    const params = { environments: environments, headers: this.passthroughOptionsService.requestHeaders };

    return from(getRootNodeFn(params)).pipe(
      // Close the subscription after it fires once.
      take(1),

      // Update sources loaded status
      finalize(() => this.sourcesLoaded$.next(true)),
    ) as Observable<DecoratedProtectionSourceNode[]>;
  }

  /**
   * Display a modal to register new source, and returns an observable
   * of the modal result. When a new source is added, it will update the list.
   *
   * @param   environment      The default environment when the modal is displayed.
   * @param   allowedEnvTypes  Array of allowed environment ids for adding new source.
   * @param   useClassicSourceRegistration  Use the old system registration popup if true
   * @param   [connectionId] bifrost connection to associate the source with
   * @returns An observable of a partially implemented protection source node. It should include just
   *          enough information took refetch sources and maintain the new selection.
   */
  registerNew(
    environment: string,
    allowedEnvTypes: number[],
    useClassicSourceRegistration = false,
    connectionId?: number
  ): Observable<any> {

    let registrationDialog: Observable<any>;

    // This switch will handle newly supported source registration, while deprecating the
    // angular JS dependencies.
    switch (true) {

      // DMaaS source registrations.
      case isDmsScope(this.irisCtx.irisContext) && !useClassicSourceRegistration:
        switch (true) {
          case ENV_GROUPS.nas.includes(envTypes[environment]):
            registrationDialog = this.dialogService.showDialog('add-dms-nas-dialog');
            break;
          case environment === 'kO365':
            registrationDialog = this.dialogService.showDialog('add-dms-office365-dialog');
            break;
          case environment === 'kAWS':
            registrationDialog = this.dialogService.showDialog('add-dms-aws-ec2-dialog');
            break;
          case environment === 'kAzure':
            registrationDialog = this.dialogService.showDialog('add-dms-azure-registration-dialog');
            break;
          case environment === 'kSfdc':
            registrationDialog = this.dialogService.showDialog('add-dms-sfdc-dialog');
            break;
          case environment === 'kUDA':
            registrationDialog = this.dialogService.showDialog('add-dms-uda-dialog');
            break;
          default:
            registrationDialog = this.dialogService.showDialog('add-dms-hypervisor-dialog');
        }

        break;

      // Handles all the source registration from the source service.
      default:
        registrationDialog = from(this.ajsSourceService
          .registerSourceSlider(envTypes[environment], allowedEnvTypes, undefined, 'from-takeover', connectionId));
    }

    return registrationDialog.pipe(
      // Handle AJS rejected promise.
      catchError(() => of(null)),
      map((newSource: any) => {
        if (!newSource) {
          return;
        }
        // Most registrations are made using the private api and will have
        // an entity object in the response. Newer pages use the v1 api.
        // The source id for helios v2 api is returned in sourceId.
        return newSource.entity ? {
          id: newSource.entity.id,
          type: envTypes[newSource.entity.type],
          name: newSource.entity.displayName,
        } : {
          id: newSource.id || newSource.sourceId,
          type: newSource.environment,
          name: newSource.name,
        };
      }),
    );
  }
}
