import { Component, EventEmitter, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatLegacyMenuPanel as MatMenuPanel, MatLegacyMenuTrigger as MatMenuTrigger, LegacyMenuPositionX as MenuPositionX } from '@angular/material/legacy-menu';

import { HelixIntlService } from '../../../../helix-intl.service';
import { DataFilterItem } from '../../comparators/data-filter';

/**
 * Data structure for disable Apply button
 */
export interface DisableApplyButton {
  disable: boolean;
  customButtonLabel: string;
}

/**
 * @description
 * Component is used to display Helix filter components inside the menu.
 * This component will be used automatically when `FilterDefDirective` `quickFilter` property set to true.
 */
@Component({
  selector: 'cog-quick-filter',
  templateUrl: './quick-filter.component.html',
  styleUrls: ['./quick-filter.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class QuickFilterComponent {

  /**
   * Reference to MatMenuTrigger instance.
   */
  @ViewChild(MatMenuTrigger, { static: false }) matTrigger: MatMenuTrigger;

  /**
   * Allows us to add the panelId that this button triggers for A11y
   */
  @Input() matMenuTriggerFor: MatMenuPanel;

  /**
   * Button label shown by default.
   */
  @Input() label: string;

  /**
   * Indicates a matMenu xPosition value to be used if auto positioning
   * isn't working as desired/expected.
   */
  @Input() xPosition: MenuPositionX = 'after';

  /**
   * Note inside the open menu, hidden by default.
   */
  @Input() note = '';

  /**
   * If the apply button is hidden, the filter should automatically apply filters
   */
  @Input() hideApplyButton = false;

  /**
   * Indicates if all buttons should be hidden. Assumes the filter implementing
   * this will manage dismissing the menu.
   */
  @Input() hideButtons = false;

  /**
   * If true, implementation is responsible for managing the matMenu implementation.
   * This is useful for nested-value-property-filter as in order to initiate the
   * sub menus correctly (this MAT mechanism doesn't work across components).
   */
  @Input() selfManagedMenu = false;

  /**
   * Button click event emitter.
   */
  @Output() opened = new EventEmitter<MouseEvent>();

  /**
   * Whether to show clear button.
   */
  @Input() noClear = false;

  /**
   * Dispatched event when filter is cleared.
   */
  @Output() readonly cleared = new EventEmitter();

  /**
   * Dispatched event when filter is applied.
   */
  @Output() readonly applied = new EventEmitter();

  /**
   * Whether the associated menu with the quick filter is opened.
   */
  @Input() isMenuOpen = false;

  /**
   * Filter tags.
   */
  @Input() filterTags: DataFilterItem[];

  /**
   * Message to show when a filter is locked. If this is not set, the filter is unlocked.
   */
  @Input() lockedMessage: string;

  /**
   * Apply form styling for filter.
   */
  @Input() formStyle = false;

  /**
   * Apply form styling for filter.
   */
  @Input() preLabel = '';

  /**
   * disable key to pe propagated on mat menu
   */
  @Input() disableArrowsKeyMenu = false;

  /**
   * Disable apply button if defined.
   */
  @Input() disableApplyButton: DisableApplyButton;

  /**
   * Trigger event when custom button is clicked.
   */
  @Output() customButtonClicked = new EventEmitter<void>();

  /**
   * Emit event when menu is closed.
   */
  @Output() menuClose = new EventEmitter<void>();

  /**
   * Determine if the filter is locked or not. An empty locked message still counts as locked.
   */
  get isLocked(): boolean {
    return !!this.lockedMessage || this.lockedMessage === '';
  }

  /**
   * Returns true if filter tags is not empty implying filter selection is active.
   */
  get isSelected(): boolean {
    return this.filterTags && this.filterTags.length > 0;
  }

  constructor(public intl: HelixIntlService) {}

  /**
   * Handles menu button click event and emits `opened` event.
   */
  clickHandler(event: MouseEvent) {
    this.opened.emit(event);
  }

  /**
   * Handles form click. Primary purpose is to prevent menu dialog from closing.
   */
  formClick(event: MouseEvent) {
    event.stopPropagation();
  }

  /**
   * Handles form keydown. Primary purpose is to prevent menu dialog from closing.
   */
  formKeyDown(event: KeyboardEvent) {
    if (this.disableArrowsKeyMenu &&
      ['Tab', 'Enter', 'ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(event.code)) {
      event.stopPropagation();
    }
  }

  /**
   * Removes all values from current tag filters.
   */
  clearTags() {
    this.filterTags.forEach(filter => filter.remove());
  }

  /**
   * Handles "Apply" filters button click.
   */
  applyFilters() {
    this.applied.emit();
    this.dismiss();
  }

  /**
   * Handles "Clear" button click.
   */
  clearFilters() {
    this.cleared.emit();
  }

  /**
   * This label is generated slightly differently from the normal label
   * that appears in the filter component.  It communicates what the
   * filter name is as well as what the selected item is.
   */
  get ariaLabel(): string {
    if (this.filterTags && this.filterTags.length > 0) {
      return `${this.label} - ${this.getLabel()} - ${this.intl.selected}`;
    } else {
      return this.getLabel();
    }

  };

  /**
   * Returns button label based on selected filter tags.
   */
  getLabel(): string {
    // Add a trailing space to the `preLabel` which comes as "Label:"
    const preLabel = this.preLabel ? this.preLabel + ' ' : '';

    if (this.filterTags && this.filterTags.length > 0) {
      const [ { label } ] = this.filterTags;
      if (this.filterTags.length === 1) {
        return preLabel + label;
      } else {
        return `${preLabel}${label} +${this.filterTags.length - 1}`;
      }
    } else {
      return this.label;
    }
  }

  /**
   * Closes quick filter menu popup.
   */
  dismiss() {
    if (this.matTrigger) {
      this.matTrigger.closeMenu();
    }
  }
}
