import { Injectable } from '@angular/core';
import { HeliosMfa, HeliosSaasMfa, MFAServiceApi } from '@cohesity/api/v2';
import { SnackBarService } from '@cohesity/helix';
import { TranslateService } from '@ngx-translate/core';
import { StateService, UIRouterGlobals } from '@uirouter/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, finalize, take } from 'rxjs/operators';
import { MfaFormType } from 'src/app/modules/helios-access-management-shared';
import { DeleteConfirmationDialogComponent } from '@cohesity/shared-dialogs';

import { ClusterService } from './cluster.service';
import { DialogService } from './dialog.service';
import { FeatureFlagsService } from './feature-flags.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class HeliosMfaService {
  /**
   * Behavior Subject to hold the MFA preferences value.
   */
  private mfaPreferencesBehaviorSubject$ = new BehaviorSubject<HeliosMfa>(null);

  /**
   * Observable for MFA preferences value.
   */
  mfaPreferences$ = this.mfaPreferencesBehaviorSubject$.asObservable();

  /**
   * Behavior Subject to hold whether an MFA call is pending.
   */
  private loadingBehaviorSubject$ = new BehaviorSubject<boolean>(false);

  /**
   * Observable for MFA call is pending value.
   */
  loading$ = this.loadingBehaviorSubject$.asObservable();

  /**
   * Map of UI MFA status values and corresponding API values.
   */
  mfaStatusValues: Record<MfaFormType['mfaStatus'], HeliosSaasMfa['mfaStatus']> = {
    optIn: 'OptIn',
    optOut: 'OptOut',
    pending: 'Pending',
  };

  constructor(
    private mfaServiceApi: MFAServiceApi,
    private userService: UserService,
    private clusterService: ClusterService,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private snackBarService: SnackBarService,
    private uiRouterGlobals: UIRouterGlobals,
    private stateService: StateService,
    private featureFlagsService: FeatureFlagsService,
  ) {
    this.init();
  }

  /**
   * Function to call MFA API and load the behavior subject.
   */
  getMfAPreferences() {
    this.loadingBehaviorSubject$.next(true);

    this.mfaServiceApi.GetMfaPreferences().pipe(
      finalize(() => this.loadingBehaviorSubject$.next(false)),
    ).subscribe(
      value => {
        // Routes that should not redirect to mfa prompt.
        const ignoreRouteNames = ['helios-mfa-prompt', 'logout'];

        // Go to the prompt page if Helios MFA config comes back as Unknown
        // and the user is not on mfa prompt page or trying to logout.
        if (value?.heliosSaasConfig?.mfaStatus === 'Unknown'
        && this.featureFlagsService.enabled('mfaHeliosSaas')
        && this.featureFlagsService.enabled('mfaHeliosSaasPrompt')
        && this.userService.user?.privs?.MCM_MODIFY_MFA
        && !ignoreRouteNames.some(routeToIgnore => this.uiRouterGlobals.current.name.startsWith(routeToIgnore))) {
          this.stateService.go('helios-mfa-prompt');
        }

        this.mfaPreferencesBehaviorSubject$.next(value);
      }
    );
  }

  /**
   * Function to initialize the MFA Preferences behavior subject.
   */
  init() {
    combineLatest([
      this.userService.user$,
      this.clusterService.basicClusterInfo$,
    ]).pipe(
      filter(([user, basicClusterInfo]) =>
        user?.privilegeIds?.length && basicClusterInfo?.mcmMode
      ),
      take(1),
    ).subscribe(() => this.getMfAPreferences());
  }

  /**
   * Function to reset the MFA Preferences behavior subject.
   */
  reset() {
    this.getMfAPreferences();
  }

  /**
   * When the user chooses to "Opt Out". Show the Opt Out notice dialog.
   */
  showOptOutNotice(): Observable<any> {
    return this.dialogService.showDialog(DeleteConfirmationDialogComponent, {
      title: 'mfa.prompt.optOut.dialog.title',
      message: `
        <p>${this.translateService.instant('mfa.prompt.optOut.dialog.content.one')}</p>
        <p>${this.translateService.instant('mfa.prompt.optOut.dialog.content.two')}</p>
        <p>${this.translateService.instant('mfa.prompt.optOut.dialog.content.three')}</p>`,
      confirmLabel: 'accept',
      keywordLabel: 'iAgree',
      isHtml: true,
    });
  }

  /**
   * Function to convert the MFA form value into API model and make the API call.
   *
   * @param value The form value.
   * @param deploymentType The deployment type (SaaS or OnPrem).
   * @return The observable for the API request.
   */
  saveMfaConfig(
    value: MfaFormType,
    deploymentType: HeliosMfa['deploymentType'] = 'HeliosSaas'
  ): Observable<HeliosMfa> {
    this.loadingBehaviorSubject$.next(true);

    return this.mfaServiceApi.UpdateMfaPreferences(
      this.getMfaFormValue(value, deploymentType)
    ).pipe(
      finalize(() => {
        this.reset();
        this.loadingBehaviorSubject$.next(false);
      }),
    );
  }

  /**
   * Function to get the API SaaS config value from the form value.
   *
   * @param value The form value.
   * @return The SaaS config value.
   */
  getHeliosSaasConfigValue(value: MfaFormType): HeliosSaasMfa {
    const result: HeliosSaasMfa = {
      mfaStatus: this.mfaStatusValues[value.mfaStatus],
    };

    if (value.mfaStatus === 'optOut') {
      result.optOutConfig = {
        eulaRead: true,
        reason: value.reason
      };

      if (value.reason === 'Other') {
        result.optOutConfig.reasonOther = value.reasonOther;
      }
    }

    return result;
  }

  /**
   * Function to get the API MFA config value from the form value.
   *
   * @param value The form value.
   * @param deploymentType The deployment type (SaaS or OnPrem).
   * @return The MFA config value.
   */
  getMfaFormValue(value: MfaFormType, deploymentType: HeliosMfa['deploymentType']): HeliosMfa {
    const result: HeliosMfa = {deploymentType};

    switch (deploymentType) {
      case 'HeliosSaas':
        result.heliosSaasConfig = this.getHeliosSaasConfigValue(value);
        break;
      case 'HeliosOnPrem':
        throw new Error('HeliosOnPrem MFA Service Not Implemented');
    }

    return result;
  }

  /**
   * Function to show the MFA snackbar message on MFA config change.
   *
   * @param value The MFA value.
   */
  showMfaSnackbarMessage(value: HeliosMfa) {
    let translateKey;

    if (value?.heliosSaasConfig?.mfaStatus) {
      switch (value.heliosSaasConfig.mfaStatus) {
        case 'OptIn':
          translateKey = 'mfaEnforced';
          break;
        case 'OptOut':
          translateKey = 'mfaOptedOut';
          break;
      }
    } else if (value?.heliosOnPremConfig) {
      translateKey = value.heliosOnPremConfig?.mfa ? 'mfaEnforced' : 'mfaOptedOut';
    }

    this.snackBarService.open(this.translateService.instant(translateKey));
  }
}
