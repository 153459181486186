import { ProtectionSourceNode } from '@cohesity/api/v1';
import {
  DataTreeControl,
  DataTreeFilter,
  DataTreeFilterUtils,
  DataTreeNode,
  DataTreeTransformer,
} from '@cohesity/helix';
import { SourceTreeFilters, TagAttribute, ViewFilterOption, ViewFilterType } from '@cohesity/iris-source-tree';
import { map } from 'rxjs/operators';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';
import { Environment } from 'src/app/shared/constants';

import { GcpSourceDataNode } from './gcp-source-data-node';

/**
 * Provide view filters for gcp sources. This includes options to filter for
 * physical, or flat hierarchies as well as by tags.
 */
export class GcpViewFilters<T extends GcpSourceDataNode> {

  /**
   * These are the default view filters.
   */
  defaultViewFilters: ViewFilterOption[];

  constructor(
    private filters: SourceTreeFilters<T>,
    private dataTransformer: DataTreeTransformer<ProtectionSourceNode>,
    private treeControl: DataTreeControl<T>,
    private irisCtx: IrisContextService,
  ) {
    this.defaultViewFilters = [
      {
        id: ViewFilterType.Physical,
        tooltip: 'sourceTreePub.tooltips.physicalView',
        filter: this.filterPhysicalChildren,
        icon: 'helix:hierarchy-physical',
      },
      {
        id: ViewFilterType.Flat,
        tooltip: 'sourceTreePub.tooltips.flatVmView',
        filter: this.filterVMChildren,
        icon: 'helix:hierarchy-flat',
      },
      flagEnabled(this.irisCtx.irisContext, 'gcpAutoprotectEnabled') && {
        filter: this.filters.selectedTags$.pipe(map((tags: TagAttribute[]) => this.getTagFilter(tags))),
        tooltip: 'tags',
        icon: 'helix:hierarchy-tag',
        isTag: true,
        id: ViewFilterType.Tag,
      },
    ].filter(Boolean);
    this.filters.setViewFilters(this.defaultViewFilters);
  }

  /**
   * Filter callback function to show the phsyical tree hierarchy of a vcenter.
   */
  filterPhysicalChildren: DataTreeFilter<any> = (nodes: GcpSourceDataNode[]) =>
    DataTreeFilterUtils.hierarchyExcludeFilter(nodes, node => {
      const env = node.envSource;
      return ['kTag', 'kLabel', 'kMetadata'].includes(env.type);
    });

  /**
   * Filter callback to show a flat list of vms from a vcenter.
   */
  filterVMChildren: DataTreeFilter<any> = (nodes: GcpSourceDataNode[]) => {
    const seenNodes = new Set<number | string>();
    return nodes
      .filter(node => {
        const matched = node.envSource.type === 'kVirtualMachine';
        if (!matched || seenNodes.has(node.id)) {
          return false;
        }
        seenNodes.add(node.id);
        return true;
      })
      .map(node => this.dataTransformer.transformData(node.data, 0));
  };

  /**
   * Create a data tree filter from a set of selected tags
   *
   * @param   tags   The tags to filter by
   * @returs  A filter function that will select the desired tags.
   */
  getTagFilter(tags: TagAttribute[]): DataTreeFilter<any> {
    // If there are no tags specified yet, show a flat list of all vms
    if (!tags || !tags.length) {
      return this.filterVMChildren;
    }
    return (nodes: T[]) => {
      const tagIds = tags.map(tag => Number(tag.id));
      const tagNames = tags.map(tag => tag.name);

      const tagNode = this.createTagNode(tagIds, nodes, tagNames);
      const taggedNodes: DataTreeNode<any>[] = [tagNode];
      taggedNodes.push(...tagNode.data.nodes.map(node => this.dataTransformer.transformData(node, 1)));
      this.treeControl.expand(tagNode);

      return taggedNodes;
    };
  }

  /**
   * Create a dummy node to add to the root of the tree. This should have a level of 0 and all
   * of it's children will be at level 1.
   *
   * @param   tags         One more tags represented by the node.
   * @param   taggedNodes  Any nodes that are matched by this one.
   * @returns A new tag node that can be added to the tree.
   */
  createTagNode(tagIds: number[], allNodes: T[], tagNames: string[] = []): T {
    if (!tagNames || !tagNames.length && tagIds.length) {
      tagNames = tagIds.map(tagId => allNodes.find(node => node.isTag && node.id === tagId))
        .map(node => node?.name)
        .filter(Boolean);
    }

    // Find all of the matching tagged nodes
    const taggedNodes = this.filterTaggedNodes(allNodes, tagIds);

    return this.dataTransformer.transformData(
      {
        protectionSource: {
          // The tag id  may contain ids for multiple tags
          id: tagIds.join('_') as any,

          // Include all of the tags in the name
          name: tagNames.join(', '),
          environment: Environment.kGCP,
          gcpProtectionSource: {
            type: 'kTag',
          },
        },
        nodes: taggedNodes.map(node => node.data),
      },
      0
    ) as T;
  }

  /**
   * Filter a list of nodes for all nodes that match all of the tag ids.
   *
   * @param   nodes  Any nodes that are matched by this one.
   * @param   tagIds         One more tags represented by the node.
   * @returns A new tag node that can be added to the tree.
   */
  private filterTaggedNodes(nodes: T[], tagIds: number[]): T[] {
    const seenNodes = new Set<number | string>();
    return nodes.filter(node => {
      // Make sure that we don't return duplicates.
      if (!node.tagIds || seenNodes.has(node.id)) {
        return false;
      }
      seenNodes.add(node.id);

      // Must match all of the requested tags.
      return tagIds.every(searchTag => node.tagIds.find(tagId => tagId === searchTag));
    });
  }
}
