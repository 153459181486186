// Stats Service

;(function(angular, undefined) {
  'use strict';

  angular.module('C').service('StatsService', statsServiceFn);

  function statsServiceFn($http, $q, API, DateTimeService, evalAJAX) {

    var statsSvc = {
      getEntitiesSchema: getEntitiesSchema,
      getEntities: getEntities,
      getDefaultRollupInterval: getDefaultRollupInterval,
      getRollupIntervalSecsPerRange: getRollupIntervalSecsPerRange,
      getTimeRangeParams: getTimeRangeParams,
      getTimeSeries: getTimeSeries,
      getFakeSeries: getFakeSeries,
      getMetricInterval: getMetricInterval,
      buildIntervalDataSet: buildIntervalDataSet,
      buildRawDataSet: buildRawDataSet,
    };

    // three minutes
    var defaultRollupInterval = 180;
    var intervalMapping = {};
    var rollupIntervalSecsPerRange = {
      day: 3600,
      week: 86400,
      month: 604800,
      quarter: 18144000,

      // TODO: make custom value a function that scales smartly based on
      // dates{} returned from cDateRanger
      custom: 3600,
    };
    var entitiesSchema;


    ////////////////////////////////////////
    // PUBLIC METHODS
    ////////////////////////////////////////

    /**
     * returns a copy of getRollupIntervalSecsPerRange{}
     *
     * @return     {object}  hash of rollupIntervalSecs per range string
     */
    function getRollupIntervalSecsPerRange() {
      return angular.copy(getRollupIntervalSecsPerRange);
    }

    /**
     * gets a list of possible entity schemas for stats queries. A copy of
     * this response is kept in the service to reduce lookups
     *
     * @param      {Object}   params  object of API defined query parameters
     * @return     {promise}  returns a promise
     */
    function getEntitiesSchema(params) {
      var deferred = $q.defer();

      // used our cached version if it exists and internal
      // schemas weren't specifically requested
      if (entitiesSchema && !params.includeInternalSchemas) {
        deferred.resolve(angular.copy(entitiesSchema));
      } else {
        $http({
          method: 'get',
          url: API.public('statistics/entitiesSchema'),
          params: params
        }).then(
          function getSuccess(response) {
            entitiesSchema = response.data || [];
            entitiesSchema.forEach(function schemaMappingFn(entity) {
              if (!intervalMapping[entity.name]) {
                intervalMapping[entity.name] = {};
              }
              entity.timeSeriesDescriptorVec.forEach(
                function timeSeriesMappingFn(timeSeries) {
                  if (timeSeries.aggregationDescriptor &&
                    timeSeries.aggregationDescriptor.aggregationIntervalSec) {
                    intervalMapping[entity.name][timeSeries.metricName] =
                      timeSeries.aggregationDescriptor.aggregationIntervalSec;
                  } else {
                    intervalMapping[entity.name][timeSeries.metricName] =
                      defaultRollupInterval;
                  }

                  // `metricDescriptiveName` is optional and we fall back to
                  // `metricName`.
                  timeSeries.metricDescriptiveName =
                    timeSeries.metricDescriptiveName || timeSeries.metricName;
                }
              );
            });
            deferred.resolve(angular.copy(entitiesSchema));
          },
          deferred.reject
        );
      }

      return deferred.promise;
    }

    /**
     * takes an optional search params object, runs an ajax call to get restore tasks,
     * and returns a promise that should result in the desired data
     * @param  {Object} [restoreJobParams] optional restoreJobParams object, which will be converted to query string
     * @return {promise}                   returns a promise
     */
    function getEntities(entitiesParams) {
      return $http({
        method: 'get',
        url: API.public('statistics/entities'),
        params: entitiesParams,
        cache: true
      }).then(
        function getEntitiesSuccess(response) {
          return Array.isArray(response.data) ? response.data : [];
        }
      );
    }

    /**
     * Gets the default rollup.
     *
     * @return     {Integer}  The default rollup.
     */
    function getDefaultRollupInterval() {
      return defaultRollupInterval;
    }

    /**
     * returns parameters used with StatsService.getTimeSeries
     *
     * @param      {String}   range           time range, possible options:
     *                                        'day', 'week', 'month',
     *                                        'twoMonths', 'quarter'
     * @param      {Integer}  startTimeMsecs  starting point for parameters
     * @return     {object}   { startTimeMsecs, endTimeMsecs }
     */
    function getTimeRangeParams(range, startTimeMsecs) {

      // only used if startTimeMsecs isn't provided
      var endTimeDate = new Date();

      var timeShift = {
        'day': 86400000,
        'week': 604800000,
        'month': 2592000000,
        'twoMonths': 2592000000 * 2,
        'quarter': 604800000 * 13
      };

      var startTime;
      var endTime;

      if (startTimeMsecs) {
        startTime = startTimeMsecs;
        endTime = startTimeMsecs + timeShift[range];
      } else {
        // If Day is selected, roll the current time back to beginning of
        // the hour and then convert to milliseconds.
        if (range === 'day') {
          endTimeDate = DateTimeService.beginningOfDay(endTimeDate);
          endTime = endTimeDate.getTime() + timeShift.day;
          startTime = endTime - timeShift[range];
          // else range = week, month, or twoMonths is selected
          // shift endtime to beginning of tomorrow and convert to milliseconds
        } else {
          endTimeDate = DateTimeService.beginningOfDay(endTimeDate);
          endTimeDate.setDate(endTimeDate.getDate() + 1);
          endTime = endTimeDate.getTime();
          startTime = endTime - timeShift[range];
        }
      }

      return {
        endTimeMsecs: endTime,
        startTimeMsecs: startTime
      };
    }

    /**
     * get the backend defined interval for a provided schema/metric
     *
     * @param      {String}   schemaName  The schema name
     * @param      {String}   metricName  The metric name
     * @return     {Integer}  The metric interval.
     */
    function getMetricInterval(schemaName, metricName) {
      var interval = getDefaultRollupInterval();
      if (intervalMapping.hasOwnProperty(schemaName) &&
        intervalMapping[schemaName].hasOwnProperty(metricName)) {
        interval = intervalMapping[schemaName][metricName];
      }
      return interval;
    }

    /**
     * Returns a series of data over a period of time.
     * This function intentionally updates params by reference
     * to fill in any missing info. If you don't want your params
     * object updated, pass in a copy.
     *
     * TODO: consider processing data returned using buildIntervalDataSet()
     * and/or buildRawDataSet() as this is basically what all controllers
     * accessing this data are doing after recieving response
     *
     * @param  {object} params {
     *                      entityId: {int} - required,
     *                      schemaName: {string} - required,
     *                      metricName: {string} - required,
     *                      range: {String} time range
     *                          possible values: 'day', 'week', 'month', 'twoMonths'
     *                          not used by API, but used by getTimeRangeParams
     *                          only needed of startTimeMsecs or endTimeMsecs isn't provided
     *                      startTimeMsecs: {milliseconds} - required by api
     *                          optional for function as we will calculate if not provided
     *                      endTimeMsecs: {milliseconds} - optional
     *                      rollupFunction: {string} - optional
     *                          if rollupFunction isn't provided, raw data is fetched
     *                      rollupIntervalSecs: {int} - optional
     *                      rollupIntervalMultiplier {int} - optional
     *                          if present scales rollupIntervalSecs up to
     *                          limit size of data set
     *                   }
     * @return {Promise} promise to resolve request for stats data
     *                   resolves and rejects with server response
     */
    function getTimeSeries(params) {

      var deferred = $q.defer();
      var intervalMappingDeferred = $q.defer();
      var timeParams;

      params = params || {};

      if (!params.startTimeMsecs || !params.endTimeMsecs) {
        angular.merge(params, getTimeRangeParams(params.range, params.startTimeMsecs));
      }

      if (Object.keys(intervalMapping).length) {
        // intervalMapping is already setup, resolve!
        intervalMappingDeferred.resolve('ok');
      } else {
        // call getEntitiesSchema, as it will build
        // the interval mapping. This should only
        // happen once per application load.
        getEntitiesSchema().finally(
          function getEntitiesSchemaFinally() {
            intervalMappingDeferred.resolve('ok');
          }
        );
      }

      intervalMappingDeferred.promise.finally(
        function intervalFinally() {
          // if a rollupFunction was provided, an interval must also
          // be provided. If not specified, look it up in the mapping
          if (params.rollupFunction && !params.rollupIntervalSecs) {
            params.rollupIntervalSecs = getMetricInterval(params.schemaName, params.metricName);
          }
          if (params.rollupIntervalMultiplier) {
            params.rollupIntervalSecs = params.rollupIntervalSecs * params.rollupIntervalMultiplier;
          }

          $http({
            method: 'get',
            url: API.public('statistics/timeSeriesStats'),
            params: params,
            // cache stats if endtime is provided and isn't a future date
            cache: params.endTimeMsecs &&
              params.endTimeMsecs <= Date.clusterNow()
          }).then(
            deferred.resolve,
            deferred.reject
          );

        }
      );

      return deferred.promise;
    }


    /**
     * returns a fake, mostly empty data set to add to a chart for the
     * purposes of making irregular based (raw) data line up correctly with
     * interval based data sets.
     *
     * @param      {Object}  params            as constructed for
     *                                         StatsService.getTimeSeries or
     *                                         returned from
     *                                         StatsService.getTimeRangeParams
     * @param      {Float}   placeholderValue  optional param which allows
     *                                         for defining the value used
     *                                         in the placeholder for the
     *                                         purposes of scaling a graph's
     *                                         sizing
     * @return     {Object}  series to be dropped straight into highcharts
     *                       series array
     */
    function getFakeSeries(params, placeholderValue) {
      var fakeApiData;
      // make a copy of params to avoid updating the passed in object, as
      // it likely doesn't have a rollupIntervalSecs value on purpose
      // since its raw data
      params = angular.copy(params);
      params.rollupIntervalSecs = params.rollupIntervalSecs || getDefaultRollupInterval();
      placeholderValue = placeholderValue ? placeholderValue : 0;
      fakeApiData = [{
        timestampMsecs: params.startTimeMsecs + (params.rollupIntervalSecs * 1000),
        data: {
          int64Value: placeholderValue
        }
      }];
      return {
        name: 'Placeholder',
        data: buildIntervalDataSet(fakeApiData, params),
        pointInterval: params.rollupIntervalSecs * 1000,
        pointStart: params.startTimeMsecs,
        color: 'transparent',
        zIndex: 1,
        enableMouseTracking: false,
        placeholder: true
      };
    }

    /**
     * returns a normalized dataset for time series chart
     *
     * @param      {Object}  data    stats data returned from API
     * @param      {Object}  params  params object as constructed for or
     *                               modified by by getTimeSeries
     * @return     {Array}   ordered Array of data points based on interval
     */
    function buildIntervalDataSet(data, params) {

      var rollup = params.rollupIntervalSecs * 1000;
      var startTime = params.startTimeMsecs;
      var endTime = params.endTimeMsecs;
      var dataPointCount = (endTime - startTime) / rollup;
      var dataSet = [];
      var index;

      // Instantiate empty dataSet Array
      for (var n = 0; n < dataPointCount; n++) {
        var ms = startTime + (rollup * n);
        dataSet.push({
          time: ms,
          data: null
        });
      }

      // place API returned data in the appropriate place
      // within our array
      angular.forEach(data, function dataLoopFn(obj) {
        // timestampMsecs is the end of the rollup window
        index = ((obj.timestampMsecs - startTime) / rollup) - 1;

        if (index >= 0 && index < dataPointCount) {
          dataSet[index].data = obj.data;
        }
      });

      // map our dataSet array so we get a clean list of values,
      // dropping timestamps on the floor, as our chart will handle
      // this aspect via datetime series type
      dataSet = dataSet.map(function dataMapFn(obj) {

        if (!obj.data) {
          return null;
        }

        if (obj.data.hasOwnProperty('int64Value')) {
          return obj.data.int64Value;
        } else if (obj.data.hasOwnProperty('doubleValue')) {
          return obj.data.doubleValue;
        }

        return null;
      });

      return dataSet;
    }

    /**
     * returns a normalized dataset for raw data plotting
     * @param  {Object} data        [description]
     * @return {Array}              Array of dataPoint objects {x: milliseconds, y: data value}
     */
    function buildRawDataSet(data) {

      data = data.map(function(item) {
        var dataValue;

        if (item.data.hasOwnProperty('int64Value')) {
          dataValue = item.data.int64Value;
        } else if (item.data.hasOwnProperty('doubleValue')) {
          dataValue = item.data.doubleValue;
        }

        return [
          item.timestampMsecs,
          dataValue
        ];
      });

      return data;
    }

    return statsSvc;
  }

}(angular));
