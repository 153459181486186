import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Controls, NgxSubFormComponent, subformComponentProviders, TypedFormGroup } from 'ngx-sub-form';
import { Environment } from 'src/app/shared/constants';

import { InstantVolumeMountCommonOptionsForm } from '../../model/instant-volume-mount-common-options-form';

/**
 * This component provides common options for file recoveries that are used across all
 * adapters. The UI for the target section of the form should be provided via content-projection
 *
 * @example
 * <coh-instant-volume-mount-common-options-form [isReadOnly]="isReadOnly"
 *   [formControl]="formControl">
 *   <ng-container [formGroup]="optionsForm">
 *     <coh-instant-volume-mount-vm-target-options [availableSources]="vmFileService.vmTargetsData$ | async"
 *       [environment]="environment"
 *       [isReadOnly]="isReadOnly"
 *       [formControl]="targetControl">
 *     </coh-instant-volume-mount-vm-target-options>
 *   </ng-container>
 * </coh-instant-volume-mount-common-options-form>
 */
@Component({
  selector: 'coh-instant-volume-mount-common-options-form',
  templateUrl: './instant-volume-mount-common-options-form.component.html',
  providers: [...subformComponentProviders(InstantVolumeMountCommonOptionsFormComponent)],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InstantVolumeMountCommonOptionsFormComponent
  extends NgxSubFormComponent<InstantVolumeMountCommonOptionsForm> {

  /**
   * If the component should be displayed as read-only.
   */
  @Input() readOnly: boolean;

  /**
   * The parent form group
   */
  @Input() parent: TypedFormGroup<InstantVolumeMountCommonOptionsForm>;

  /**
   * Environment
   */
  @Input() environment: Environment;

  /**
   * Show readOnlyMount control or not
   */
  get showReadOnlyMount(): boolean {
    return this.environment === Environment.kPhysical;
  }

  /**
   * Gets the form controls for configuring IVM.
   *
   * @returns  The common options controls for IVM.
   */
  protected getFormControls(): Controls<InstantVolumeMountCommonOptionsForm> {
    return {
      readOnlyMount: new UntypedFormControl(false, null),
      clusterInterface: new UntypedFormControl(null, Validators.required),
      taskName: new UntypedFormControl(null, Validators.required),
    };
  }
}
