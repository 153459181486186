<div
  class="item-wrapper"
  cogDataId="item-{{ item.displayName }}"
  [inheritDataId]="true"
  [class.item-wrapper-lg]="size === 'lg'">
  <!-- nav icon -->
  <cog-icon
    class="nav-icon"
    [class.nav-icon-lg]="size === 'lg'"
    *ngIf="showIcon && item.icon" [shape]="item.icon"
    [ariaLabel]="item.displayName">
  </cog-icon>

  <!-- nav inner content -->
  <div class="nav-inner">
    <ng-content></ng-content>
  </div>
</div>
