// Cluster Service

;(function(angular, undefined) {

  angular.module('C').service('SmtpService', smtpServiceFn);

  function smtpServiceFn($http, $q, API) {

    return {
      getSMTPConf: getSMTPConf,
      updateSMTPConf: updateSMTPConf,
      sendTestEmail: sendTestEmail,
    };

    ////////////////////////////////////////
    // PUBLIC METHODS
    ////////////////////////////////////////
    // Method to Create a new cluster
    // params: ['id'] or ['url'], name, subnetIP, subnetMask
    // response: session cookie, user object


    function getSMTPConf() {
      return $http({
        method: 'get',
        url: API.private('smtpServer'),
      }).then(
        function getSmtpSuccess(response) {
          return response.data || {};
        }
      );
    }

    function updateSMTPConf(data) {
      return $http({
        method: 'put',
        url: API.private('smtpServer'),
        data: data,
      });
    }

    function sendTestEmail(email) {
      return $http({
        method: 'post',
        url: API.private('smtpServer/testMail'),
        data: {
          recipient: email,
        },
      });
    }

  }

})(angular);
