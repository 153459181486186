import { AppModuleConfig, StateAccessContext, StateAccessMap } from 'src/app/app-module.config';

const businessServicesAccess = (ctx: StateAccessContext) => ctx.FEATURE_FLAGS.keplerEnabled;

export const BusinessServicesConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'business-services.**',
      url: '/business-services',
      loadChildren: () => import('./business-services.module').then(m => m.BusinessServicesModule),
    },
  ],
  allClusterMap: {
    'business-services': {
      heliosOnly: true,
      allClustersState: 'business-services'
    }
  },
  getStateAccessMap(): StateAccessMap {
    return {
      'business-services': businessServicesAccess,
    };
  },
};
