import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CardSectionComponent } from './card-section/card-section.component';
import { CardSectionFiltersComponent } from './card-section-filters/card-section-filters.component';
import { CardSectionGlancebarComponent } from './card-section-glancebar/card-section-glancebar.component';

const COMPONENTS = [
  CardSectionComponent,
  CardSectionFiltersComponent,
  CardSectionGlancebarComponent,
];

@NgModule({
  imports: [
    CommonModule,
    ...COMPONENTS
  ],
  exports: [
    ...COMPONENTS,
  ]
})
export class CardModule { }
