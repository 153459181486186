import { UntypedFormControl, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { skipWhile, startWith, takeUntil } from 'rxjs/operators';
import { mapErrorMessage } from '../form-utils';

import { DayVal, TimePeriodValue } from '@cohesity/utils';
import {
  TimePeriodOptions,
  TimePeriodSelectorForm
} from '../time-period-selector/time-period-selector-form';
import { TypedFormGroup } from '../typed-form-group';

/**
 * Enum for policy run schedule unit.
 */
export enum PolicyScheduleUnit {
  Runs = 'Runs',
  Minutes = 'Minutes',
  Hours = 'Hours',
  Days = 'Days',
  Weeks = 'Weeks',
  Months = 'Months',
  Years = 'Years',
  Daily = 'Daily',
}

/**
 * Define a type containing all possible day values.
 */
type Day = null | 'Sunday' | 'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Saturday';

/**
 * ScheduleSelectorValue interface.
 */
export interface ScheduleSelectorValue {
  timePeriod: TimePeriodValue;
  days?: Day[];
  day?: Day;
  dayCount?: TimePeriodValue;
  dayOfYear?: TimePeriodValue;
  dayOfMonth?: number;
}

/**
 * Default value of Schedule Selector.
 */
const defaultValue: ScheduleSelectorValue = {
  timePeriod: { value: 1, type: PolicyScheduleUnit.Days },
  days: [DayVal[new Date().getDay()]] as any,
  day: 'Sunday',
  dayCount: { value: 1, type: 'First' },
  dayOfYear: { value: 1, type: 'First' },
  dayOfMonth: new Date().getDate()
};

/**
 * List of all possible periodicity type options.
 */
export const periodicityTypeOptions: TimePeriodOptions[] = [
  {
    value: PolicyScheduleUnit.Minutes,
    label: 'minute',
    labelPlural: 'minutes',
  },
  {
    value: PolicyScheduleUnit.Hours,
    label: 'hour',
    labelPlural: 'hours',
  },
  {
    value: PolicyScheduleUnit.Days,
    label: 'day',
    labelPlural: 'days',
  },
  {
    value: PolicyScheduleUnit.Weeks,
    label: 'week',
    hideValue: true,
  },
  {
    value: PolicyScheduleUnit.Months,
    label: 'month',
    hideValue: true,
  },
  {
    value: PolicyScheduleUnit.Years,
    label: 'year',
    hideValue: true,
  },
];

/**
 * List of all possible dayCount type options for monthly periodicity.
 */
const dayCountTypeOptions: TimePeriodOptions[] = [
  {
    value: 'First',
    label: 'enum.dayCountInMonth.first',
    hideValue: true,
  },
  {
    value: 'Second',
    label: 'enum.dayCountInMonth.second',
    hideValue: true,
  },
  {
    value: 'Third',
    label: 'enum.dayCountInMonth.third',
    hideValue: true,
  },
  {
    value: 'Fourth',
    label: 'enum.dayCountInMonth.fourth',
    hideValue: true,
  },
  {
    value: 'Last',
    label: 'enum.dayCountInMonth.last',
    hideValue: true,
  },
  {
    value: 'Date',
    label: 'date',
    hideValue: true,
  }
];

/**
 * List of all possible day of year type options for yearly periodicity.
 */
const dayOfYearTypeOptions: TimePeriodOptions[] = [
  {
    value: 'First',
    label: 'enum.dayOfYear.first',
    hideValue: true,
  },
  {
    value: 'Last',
    label: 'enum.dayOfYear.last',
    hideValue: true,
  },
];

const defaultTimeSelectorValue = {
  Days: 1,
  Hours: 1,
  Minutes: 10,
  Months: 1,
  Weeks: 1,
};

/**
 * Initializes Schedule Selector form group controls with available options.
 *
 * @param    initialValue   Initial value of the form group
 * @param    typeOptions    Supported time period type options list.
 * @param    isCbsEnabled   Whether calendar based scheduling is enabled.
 * @returns  The form group.
 */
function initializeScheduleSelectorForm(
  initialValue: ScheduleSelectorValue,
  typeOptions: TimePeriodOptions[],
  isCbsEnabled: boolean) {
  let dayCountOptions = dayCountTypeOptions;

  // If CBS is not enabled, 'Date' option is not applicable in "Monthly" schedule.
  if (!isCbsEnabled) {
    dayCountOptions = dayCountOptions.filter(opt => opt.value !== 'Date');
  }

  return {
    timePeriod: new TimePeriodSelectorForm(initialValue.timePeriod, typeOptions),
    days: new UntypedFormControl({
      value : (initialValue.days || [DayVal[new Date().getDay()]]),
      disabled: true
    }, Validators.required),
    day: new UntypedFormControl({
      value : (initialValue.day),
      disabled: true
    }, Validators.required),
    dayCount: new TimePeriodSelectorForm(initialValue.dayCount, dayCountOptions),
    dayOfYear: new TimePeriodSelectorForm(initialValue.dayOfYear, dayOfYearTypeOptions),
    dayOfMonth: new UntypedFormControl(initialValue.dayOfMonth, [
      Validators.required,
      Validators.min(1),
      Validators.max(31)]),
  };
}

/**
 * Schedule Selector Form Group.
 */
export class ScheduleSelectorForm extends TypedFormGroup<ScheduleSelectorValue> {

  /**
   * Gets the timePeriod form control.
   *
   * @return   The timePeriod form control.
   */
  get timePeriod(): TimePeriodSelectorForm {
    return this.get('timePeriod') as TimePeriodSelectorForm;
  }

  /**
   * Gets the dayCount form control.
   *
   * @return   The dayCount form control.
   */
  get dayCount(): TimePeriodSelectorForm {
    return this.get('dayCount') as TimePeriodSelectorForm;
  }

  /**
   * Gets the dayOfYear form control.
   *
   * @return   The dayOfYear form control.
   */
  get dayOfYear(): TimePeriodSelectorForm {
    return this.get('dayOfYear') as TimePeriodSelectorForm;
  }

  /**
   * Gets the dayOfMonth form control.
   *
   * @return   The dayOfMonth form control.
   */
  get dayOfMonth(): UntypedFormControl {
    return this.get('dayOfMonth') as UntypedFormControl;
  }

  /**
   * Gets the day form control.
   *
   * @return   The day form control.
   */
  get day(): UntypedFormControl {
    return this.get('day') as UntypedFormControl;
  }

  /**
   * Gets the days form control.
   *
   * @return   The days form control.
   */
  get days(): UntypedFormControl {
    return this.get('days') as UntypedFormControl;
  }

  /**
   * List of type options which require days of week selector.
   */
  get typeOptionsWithDaysofWeek(): string[] {
    return ['Weeks', 'Months'];
  }

  /**
   * Clean up subscriptions.
   */
  private _destroy = new Subject<any>();

  /**
   * Constructs new Schedule Selector FormGroup.
   *
   * @param  initialValue   Value to initialize the form to.
   * @param  typeOptions    Time period type options list.
   * @param  isCbsEnabled     Whether calendar based scheduling is enabled.
   */
  constructor(
    initialValue: ScheduleSelectorValue = defaultValue,
    typeOptions: TimePeriodOptions[] = periodicityTypeOptions,
    isCbsEnabled = false) {
    super(initializeScheduleSelectorForm(initialValue, typeOptions, isCbsEnabled));

    // On changing type option
    this.timePeriod.typeControl.valueChanges
      .pipe(
        startWith(this.timePeriod.typeControl.value),
        skipWhile(() => this.timePeriod.typeControl.pristine),
        takeUntil(this._destroy)
      )
      .subscribe(type => {

        // Reset valueControl to default
        this.timePeriod.valueControl.setValue(
          defaultTimeSelectorValue[type], { onlySelf: true }
        );

        // Reset daysofWeek selector on changing type option
        if (type === PolicyScheduleUnit.Weeks) {
          this.days.setValue(defaultValue.days);
          this.dayCount.setValue(defaultValue.dayCount);
        } else if (type === PolicyScheduleUnit.Months) {
          this.day.setValue(defaultValue.day);
          this.dayCount.setValue(defaultValue.dayCount);
          this.dayOfMonth.setValue(defaultValue.dayOfMonth);
        } else if (type === PolicyScheduleUnit.Years) {
          this.dayOfYear.setValue(defaultValue.dayOfYear);
        }
      });
  }

  /**
   * Cleanup any subscriptions in the form.
   */
  cleanup() {
    this._destroy.next();
    this._destroy.complete();
  }

  /**
   * Overrides the parent class to either enable or disable daysOfWeek control
   * and dayCount control based on the type option.
   *
   * @param   opts   The options to pass to the super class.
   */
  updateValueAndValidity(opts: { onlySelf?: boolean; emitEvent?: boolean } = {}) {
    if (this.enabled) {
      const timePeriodType = this.timePeriod.typeControl.value;

      // Disable/enable controls based on time period type.
      if (timePeriodType === PolicyScheduleUnit.Weeks) {
        this.days.enable({ onlySelf: true, emitEvent: false });
      } else {
        this.days.disable({ onlySelf: true, emitEvent: false });
      }

      if (timePeriodType === PolicyScheduleUnit.Months) {
        this.dayCount.enable({ onlySelf: true, emitEvent: false });
        const dayCountValue = this.dayCount?.typeControl?.value;
        if (dayCountValue === 'Date') {
          this.dayOfMonth.enable({ onlySelf: true, emitEvent: false });
          this.day.disable({ onlySelf: true, emitEvent: false });
        } else {
          this.day.enable({ onlySelf: true, emitEvent: false });
          this.dayOfMonth.disable({ onlySelf: true, emitEvent: false });
        }
      } else {
        this.day.disable({ onlySelf: true, emitEvent: false });
        this.dayCount.disable({ onlySelf: true, emitEvent: false });
        this.dayOfMonth.disable({ onlySelf: true, emitEvent: false });
      }

      if (timePeriodType === PolicyScheduleUnit.Years) {
        this.dayOfYear.enable({ onlySelf: true, emitEvent: false });
      } else {
        this.dayOfYear.disable({ onlySelf: true, emitEvent: false });
      }
    } else {

      // When the form group is disabled, all child controls should be disabled as well.
      // However, it does not happen in this form group, hence manually disable all of them.
      Object.keys(this.controls).forEach(key => {
        this.controls[key].disable({ onlySelf: true, emitEvent: false });
      });
    }

    super.updateValueAndValidity(opts);
  }

  /**
   * Return an error message when there is a form validation error.
   *
   * @param controlName The form control name to get the error from.
   */
  getErrorMessage(controlName: string): string {
    return mapErrorMessage(this[controlName]);
  }
}
