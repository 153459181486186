import { Directive, ElementRef, Injector, Input } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';
import { PrivilegeInfo } from '@cohesity/api/v1';

@Directive({
  selector: 'ajs-role-privileges'
})
export class RolePrivilegesComponent extends UpgradeComponent {
  /**
   * Optional value to indicate editing an existing role.
   */
  @Input() editMode: boolean;

  /**
   * Filter privileges based on predicate. One of 'none', 'accessManagement',
   * 'helios' and 'nonHelios'.
   */
  @Input() filter: string;

  /**
   * Hash of privileges grouped by category. Optional if readonly.
   */
  @Input() privileges: PrivilegeInfo[];

  /**
   * Array of all available privileges. Optional, without this
   * the component fires additional API calls.
   */
  @Input() availablePrivileges: any[];

  /**
   * Optional value to indicate read only view.
   */
  @Input() readOnly: boolean;

  /**
   * Role object which is pushed to the API.
   */
  @Input() role: any;

  constructor(injector: Injector, elementRef: ElementRef) {
    super('cRolePrivileges', elementRef, injector);
  }
}
