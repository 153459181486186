<ng-container [formGroup]="formGroup">
  <h3 class="margin-bottom-lg">{{ 'accessScope' | translate }}</h3>

  <div class="flex-row margin-bottom-lg grid-item">
    <mat-slide-toggle formControlName="enableAccessScope" cogDataId="access-scope-toggle">
      {{ 'enableAccessScope' | translate }}
    </mat-slide-toggle>
  </div>

  <mat-form-field *ngIf="formGroup.controls.enableAccessScope.value" class="flex-row grid-item">
    <mat-label>{{ 'name' | translate }}</mat-label>
    <!-- TODO: add spinner on load -->
    <!-- <cog-spinner *ngIf="loading$ | async" [inline]="true" matPrefix size="xs"></cog-spinner> -->
    <coh-searchable-select
      cogDataId="access-scope-selector"
      formControlName="accessScopes"
      [items]="accessScopes$ | async"
      [allowMultiple]="true"
      [searchFn]="entityDisplayNameSearchFn"
      [compareWith]="scopeComparatorFn"
      [optionDataIdFn]="optionDataIdGeneratorFn"
      [searchPlaceholderLabel]="'search' | translate"
      [searchNoEntriesFoundLabel]="'noResultsFound' | translate"
      [addButtonLabel]="hasManagePrivilege ? ('accessScope' | translate) : ''"
      (addItemButtonClick)="onAddAccessScopeClick()">
      <ng-template cohSearchableSelectItemTemplate let-scope>{{ scope?.name }}</ng-template>
    </coh-searchable-select>

    <mat-error *ngIf="formGroup.controls.accessScopes.errors?.required">
      {{ 'errors.required' | translate }}
    </mat-error>
  </mat-form-field>
</ng-container>

<cog-spinner *ngIf="loading$ | async" [isOverlay]="true"></cog-spinner>
