/**
 * Cloud target.
 */
export enum CloudTarget {
  kAmazon = 'enum.cloudTarget.kAmazon',
  kAmazonC2S = 'enum.cloudTarget.kAmazonC2S',
  kAmazonS3StandardIA = 'enum.cloudTarget.kAmazonS3StandardIA',
  kAWSGovCloud = 'enum.cloudTarget.kAWSGovCloud',
  kAzure = 'enum.cloudTarget.kAzure',
  kAzureArchive = 'enum.cloudTarget.kAzureArchive',
  kAzureGovCloud = 'enum.cloudTarget.kAzureGovCloud',
  kAzureStandard = 'enum.cloudTarget.kAzureStandard',
  kColdline = 'enum.cloudTarget.kColdline',
  kGlacier = 'enum.cloudTarget.kGlacier',
  kGoogle = 'enum.cloudTarget.kGoogle',
  kGoogleDRA = 'enum.cloudTarget.kGoogleDRA',
  kGoogleStandard = 'enum.cloudTarget.kGoogleStandard',
  kNAS = 'enum.cloudTarget.kNAS',
  kNearline = 'enum.cloudTarget.kNearline',
  kOracle = 'enum.cloudTarget.kOracle',
  kOracleTierArchive = 'enum.cloudTarget.kOracleTierArchive',
  kOracleTierStandard = 'enum.cloudTarget.kOracleTierStandard',
  kQStarTape = 'enum.cloudTarget.kQStarTape',
  kS3 = 'enum.cloudTarget.kS3',
  kS3Compatible = 'enum.cloudTarget.kS3Compatible',
}
