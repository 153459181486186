import { Component, Input, OnInit } from '@angular/core';
import { StatusIcon } from '../models/status-icon.model';

/**
 * @description
 * This component  will truncate a number of status icons for display within a
 * table cell and show count for the truncated status icons. On hovering over
 * the count, it will display the truncated icons in a tooltip
 *
 * @example
 * <status-icon-container icons="icons" max="2"></status-icon-container>
 */
@Component({
  selector: 'coh-status-icon-container',
  templateUrl: 'status-icon-container.component.html',
  styleUrls: ['status-icon-container.component.scss'],
})
export class StatusIconContainerComponent implements OnInit {
  /**
   * The object list for which we are setting up the status icons for
   */
  @Input() icons: StatusIcon[];

  /**
   * The maximum number of icons to show and rest of the icons are truncated
   */
  @Input() max = 3;

  /**
   * Determines if we want to show the truncated icons count or not
   */
  public showMoreIcon = false;

  /**
   * Determines when to show truncated icons panel
   */
  public showIconPanel = false;

  /**
   * The truncated icons count
   */
  public hiddenCount = 0;

  public displayIcons: StatusIcon[];

  public hiddenIcons: StatusIcon[];

  /**
   * Initializes the component and sets some default values.
   */
  ngOnInit() {
    if (!this.icons) {
      return;
    }
    this.displayIcons = this.icons;
    if (this.icons.length > this.max) {
      this.showMoreIcon = true;
      this.displayIcons = this.icons.slice(0, this.max);
      this.hiddenIcons = this.icons.slice(this.max);
      this.hiddenCount = this.icons.length - this.max;
    }
  }
}
