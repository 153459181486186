<div class="div-filter-input-field">
  <mat-form-field class="filter-input-field"
    floatLabel="never">
    <input cogDataId="object-search-input"
      type="text"
      matInput
      cohAutoFocus
      [placeholder]="placeholderText"
      [formControl]="searchControl"
      (keyup.enter)="keyUp.next(searchControl.value)">

    <mat-progress-bar class="searching" mode="indeterminate" *ngIf="searchPending">
    </mat-progress-bar>
  </mat-form-field>
</div>
<div>
  <button class="submit-object-search" *ngIf="!autoSearchEnabled" mat-button matSuffix
    cogDataId="submit-object-search"
    (click)="queryChanged.emit(searchControl.value)"
    cdkFocusInitial>
    {{ 'search' | translate }}
  </button>
</div>