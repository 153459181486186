import { Component, forwardRef, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators
} from '@angular/forms';
import { delay } from 'rxjs/operators';
import { ItemPickerFormControl } from '@cohesity/shared-forms';

/**
 * Interface for include and exclude paths.
 */
export interface IncludeExcludePaths {
  include: string[];
  exclude: string[];
}

@Component({
  selector: 'coh-include-exclude-paths',
  templateUrl: './include-exclude-paths.component.html',
  styleUrls: ['./include-exclude-paths.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IncludeExcludePathsComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => IncludeExcludePathsComponent),
      multi: true
    }
  ],
})
export class IncludeExcludePathsComponent extends ItemPickerFormControl<IncludeExcludePaths>
  implements Validator, OnInit {
  /**
   * Form group to contain all include and exclude paths.
   */
  formGroup = new UntypedFormGroup({
    include: new UntypedFormArray([]),
    exclude: new UntypedFormArray([]),
  });

  /**
   * Parent component can pass a custom validation function to use. For example,
   * a certain use case might require at least one include path required
   * (e.g, NAS Exclusions).
   */
  @Input() customValidation: Function;

  /**
   * List of default include and exclude paths to init this form control with.
   */
  @Input() defaultPaths: IncludeExcludePaths;

  /**
   * Option to show folder icon as matInput suffix.
   */
  @Input() showFolderIcon = false;

  /**
   * Option to show Include path label.
   */
  @Input() includeLabel: string;

  /**
   * Option to show placeholder for path input.
   */
  @Input() pathPlaceholder: string;

  /**
   * Option to show Exclude path label.
   */
  @Input() excludeLabel: string;

  constructor() {
    super();

    // Without delay(0), adding default value does not properly work.
    this.formGroup.valueChanges.pipe(delay(0)).subscribe(value => {
      this.value = value;
    });
  }

  /**
   * Custom validation function to validate this form control
   *
   * @param   control   This form control which contains the paths
   * @return   null if valid, otherwise anything else for invalid.
   */
  validate(control: AbstractControl): ValidationErrors | null {
    if (this.customValidation) {
      // If a custom validation function is provided, use that for validation.
      return this.customValidation(control);
    }

    // Otherwise fallback to default validation, which is, if any include or
    // exclude paths exist, they're required.
    return this.formGroup.valid ? null : {error: true};
  }

  /**
   * Component init.
   */
  ngOnInit() {
    if (this.defaultPaths) {
      // If default include and exclude paths were specified
      for (const type of ['include', 'exclude']) {
        for (const path of this.defaultPaths[type]) {
          // The default paths are an array of strings. For each string item,
          // a new form control for the FormArray needs to be constructed which
          // is seeded with the default value.
          this.addPathFormControl(type, path);
        }
      }
    }
  }

  /**
   * Function to add a new item to the include or exclude paths array.
   *
   * @param   type   One of 'include' or 'exclude'.
   * @param   value   Default value for the path
   */
  addPathFormControl(type, value = '') {
    (this.formGroup.get(type) as UntypedFormArray).push(
      new UntypedFormControl(value, Validators.required)
    );
  }

  /**
   * Function to return the form controls array of include or exclude paths.
   *
   * @param   type   One of 'include' or 'exclude'.
   * @return   Array of form controls for the particular path type.
   */
  getPathFormControls(type): AbstractControl[] {
    return (this.formGroup.get(type) as UntypedFormArray).controls;
  }

  /**
   * Function to remove a form control item from the include or exclude form
   * controls array.
   *
   * @param   type   One of 'include' or 'exclude'.
   * @param   index   Index of the item to be removed.
   */
  removePathFormControl(type, index) {
    (this.formGroup.get(type) as UntypedFormArray).removeAt(index);
  }

  /**
   * Indicates if a particular path is allowed to be removed from the ruleset.
   *
   * @param   type   One of 'include' or 'exclude'.
   */
  canRemovePath(type): boolean {
    return type === 'exclude' || (this.formGroup.get('include') as UntypedFormArray).length > 1;
  }
}
