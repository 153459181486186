import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { SeriesPieOptions } from 'highcharts';
import { ObservableInput } from 'ngx-observable-input';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

/**
 * Component to show the progresss (%) in a donut chart.
 *
 * @example
 *  <cog-progress-donut-chart [percentFinished]="80">
 *  </cog-progress-donut-chart>
 *
 *  <cog-progress-donut-chart percentFinished="20"
 *    [hideLabel]="true">
 *  </cog-progress-donut-chart>
 */
@Component({
  selector: 'cog-progress-donut-chart',
  templateUrl: './progress-donut-chart.component.html',
  styleUrls: ['./progress-donut-chart.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProgressDonutChartComponent implements OnInit, OnDestroy {
  /**
   * The progress completed in percentage used as an observable.
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @ObservableInput() @Input('percentFinished') percentFinished$: Observable<number>;

  /**
   * Clean up observable subscriptions when component is destroyed.
   */
  private readonly destroy$ = new Subject();

  /**
   * The actual display value for the progress.
   */
  percentValue = 0;

  /**
   * Whether to show/hide the label.
   */
  @Input() hideLabel = false;

  /**
   * To show the loader or not.
   */
  @Input() isLoading = false;

  /**
   * Custom chart options for donut chart.
   */
  readonly customChartOptions = {
    plotOptions: {
      pie: {
        animation: false,
        enableMouseTracking: false,
        innerSize: '70%',
      }
    }
  };

  /**
   * The series data required to render the chart.
   */
  seriesData: SeriesPieOptions[];

  ngOnInit() {
    this.percentFinished$
      .pipe(takeUntil(this.destroy$))
      .subscribe(percentFinished => {
        this.percentValue = isNaN(Number(percentFinished)) ? 0 : Number(percentFinished);

        this.seriesData = [{
          type: 'pie',
          data: [this.percentValue, 100 - this.percentValue],
        }];
      });
  }

  /**
   * Trigger destroy subject to clean up subscriptions.
   */
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
