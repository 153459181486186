import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { DataTreeNode, DataTreeNodeContext, DataTreeSelection, SelectionOptions } from '@cohesity/helix';
import { IrisContextService, isDmsScope } from '@cohesity/iris-core';
import { AutoDestroyable } from '@cohesity/utils';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { debounceTime, filter, map } from 'rxjs/operators';

import { SourceSelection, SourceTreeService } from '../shared';
import { BaseSourceTreeComponent } from '../shared/base-source-tree.component';

/**
 * Summary object for all of the possible summary counts. These each map to
 * a list of nodes that can be shown for details.
 */
interface SourceTreeSelectionSummary {
  /**
   * These are manually selected node.
   */
  selected: DataTreeNode<any>[];

  /**
   * Auto selected nodes, including tags
   */
  autoSelected: DataTreeNode<any>[];

  /**
   * Excluded nodes, including tags
   */
  excluded: DataTreeNode<any>[];

  /**
   * Nodes with custom object settings.
   */
  objectSettings: DataTreeNode<any>[];

  /**
   * Nodes whose protection will be overwritten with the current settings.
   */
  overwrite: DataTreeNode<any>[];

  /**
   * This is only present if the node environment implements isLeaf. It is the total
   * number of items protected with manual and auto protection.
   */
  total: DataTreeNode<any>[];
}

/**
 * Summary detail properties.
 */
type SummaryDetail = 'total' | 'selected' | 'autoSelected' | 'excluded' | 'objectSettings';

@Component({
  selector: 'coh-source-tree-summary',
  templateUrl: './source-tree-summary.component.html',
  styleUrls: ['./source-tree-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SourceTreeSummaryComponent extends AutoDestroyable implements OnChanges {
  /**
   * If true, always show the summary numbers, even if nothing selected.
   */
  @Input() showZeroSelections = false;

  /**
   * If set, do not show details table on a stat item click.
   */
  @Input() disableDetails = false;

  /**
   * If set, show the details as an inline list.
   */
  @HostBinding('class.inline') @Input() inline = false;

  /**
   * If inline, show the source name if passed.
   */
  @Input() inlineSourceName: string;

  /**
   * Hide the edit special params button when read only is set.
   */
  @Input() readOnly = false;

  /**
   * The name of the location where the source is being protected.
   * This is only visible in inline mode.
   */
  @Input() locationName: string;

  /**
   * The id of the object currently being edited if any.
   */
  @Input() editingObjectId: number;

  /**
   * Event emits if the user does something to modify the selection from this component, without drilling down to
   * the full source tree.
   */
  @Output() selectionUpdated = new EventEmitter<void>();

  /**
   * The currently selected detail section. This determines whether to show the list below
   * the object summary or not.
   */
  focusedDetail: SummaryDetail;

  /**
   * Connected source tree component
   */
  private _sourceTree: BaseSourceTreeComponent<any, any>;

  /**
   * Set the source tree, and update subscriptions based on it.
   */
  @Input() set sourceTree(sourceTree: BaseSourceTreeComponent<any, any>) {
    this.cleanUpSubscriptions();
    this._sourceTree = sourceTree;
    if (sourceTree) {
      this.initSubscriptions();
      sourceTree.sourceTreeInitalized.pipe(this.untilDestroy()).subscribe(() => this.initSubscriptions());
    }
  }

  /**
   * Translation key to use for the total count. This is would be specific to the type of
   * environment the tree is showing.
   */
  @Input() selectedCountLabel: string;

  /**
   * A subject that emits whenever the selection summary changes.
   */
  selectionSummary = new BehaviorSubject<Partial<SourceTreeSelectionSummary>>(null);

  /**
   * Uses the tree service to look whether to include special params. Some environments,
   * use special params internally as part of the job and don't allow the user to specify any.
   */
  get includeSpecialParams(): boolean {
    return !this.treeService.hideSpecialParamsInSummary;
  }

  /**
   * Uses the tree service to determine whether we can calculate the total based on the leaf count.
   * Environments that don't use auto protection or tags and usually ignore this since the selected
   * number will be the same as the total number.
   */
  get canCalculateLeaves(): boolean {
    return !!this.treeService.isLeaf;
  }

  /**
   * Looks up the tree service controlling the source tree.
   */
  get treeService(): SourceTreeService<any, any> {
    return this._sourceTree.treeService;
  }

  /**
   * Returns a list of detail nodes based on the currently focused detail category.
   */
  get detailNodes$(): Observable<DataTreeNode<any>[]> {
    if (!this.focusedDetail) {
      return null;
    }
    return this.selectionSummary.pipe(map(summary => summary[this.focusedDetail]));
  }

  /**
   * Return an observable of valid selection summary items.
   */
  get validSelectionSummaryItems$(): Observable<Partial<SourceTreeSelectionSummary>> {
    return this.selectionSummary.pipe(filter(value => value && Object.values(value).some(item => item && item.length)));
  }

  /**
   * Gets a list of summary items to show. This is slightly different for sources which
   * don't support calculating leaf nodes.
   */
  get summaryItems(): { label: string; property: SummaryDetail }[] {
    // Explicit check for false here. If the value has not been set, we should default to true.
    const showSeparateTotal = this.canCalculateLeaves && this.treeService.usesAutoProtection !== false;
    return [
      showSeparateTotal && {
        label: this.selectedCountLabel,
        property: 'total',
      },
      {
        label: showSeparateTotal ? 'manuallyProtected' : this.selectedCountLabel,
        property: 'selected',
      },
      {
        label: 'autoProtected',
        property: 'autoSelected',
      },
      {
        label: 'exclusions',
        property: 'excluded',
      },
      {
        label: 'objectLevelSettings',
        property: 'objectSettings',
      },
      {
        label: 'replaceProtection',
        property: 'overwrite',
      },
    ].filter(item => !!item) as any;
  }

  constructor(
    private cdr: ChangeDetectorRef,
    private irisContext: IrisContextService,
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.disableDetails && !this.disableDetails) {
      this.focusedDetail = null;
    }
  }

  /**
   * Returns a list of all selected and auto selected nodes in the current selection.
   * If we can't calculate leaves, this returns undefined.
   *
   * @param   nodes   The list of nodes to search.
   * @param   currentSelection The  full node selection.
   * @returns All nodes that will be included by this selection.
   */
  getAllSelectedNodes(treeData: DataTreeNode<any>[], currentSelection: DataTreeSelection<any>): DataTreeNode<any>[] {
    if (!this.canCalculateLeaves) {
      return undefined;
    }
    const nodeMap = new Map<string | number, DataTreeNode<any>>();

    // Auto Selection and Exclusion checks are potentially expensive, so it's worth avoiding them when possible
    const selection = this.treeService.dataTreeSelection;
    const checkAutoSelection = currentSelection.autoSelected.length > 0;
    const checkExclusion = checkAutoSelection && currentSelection.excluded.length > 0;

    treeData.forEach(node => {
      if (!this.treeService.isLeaf(node)) {
        return;
      }
      const selected = selection.isSelected(node);
      const autoSelected =
        ((checkAutoSelection && selection.isAutoSelected(node)) || selection.isAncestorAutoSelected(node)) &&
        !node.isGloballyExcluded && !node.ineligibleForAutoSelect;
      const excluded = (checkExclusion && selection.isExcluded(node)) || selection.isAncestorExcluded(node);
      if (selected || (autoSelected && !excluded)) {
        nodeMap.set(node.id, node);
      }
    });
    return [...nodeMap.values()].sort((a, b) => this.treeService.getName(a).localeCompare(this.treeService.getName(b)));
  }

  /**
   * Gets the detail context for a given node. This is included in the node detail context to simplify
   * node calculations. Note that this is a somewhat expensive call, and should only be made for the
   * nodes that are currently showing in the table and never for the entire set of all selected nodes.
   *
   * @param   node   The current node.
   * @returns A node context object.
   */
  getNodeDetailContext(node): DataTreeNodeContext<DataTreeNode<any>> {
    const selection = this.treeService.dataTreeSelection;
    const treeControl = this.treeService.treeControl;
    return {
      node: node,
      selection: selection,
      treeControl: treeControl,
      selected: selection.isSelected(node),
      autoSelected: selection.isAutoSelected(node),
      excluded: selection.isExcluded(node),
      ancestorAutoSelected: selection.isAncestorAutoSelected(node),
      ancestorExcluded: selection.isAncestorExcluded(node),
      canSelect: selection.canSelectNode(node),
      canAutoSelect: selection.canAutoSelectNode(node),
      canExclude: selection.canExcludeNode(node),
    };
  }

  /**
   * Looks up nodes that have custom settings applied to them.
   *
   * @param   nodes   The list of nodes to search.
   * @param   options  The current set of options.
   * @returns All of the nodes with sepecial params.
   */
  getObjectSettingsNodes(treeData: DataTreeNode<any>[], options: SelectionOptions) {
    if (!options || !this.includeSpecialParams) {
      return [];
    }
    return treeData.filter(node => !!options[node.id]);
  }

  /**
   * Subscribe to changes in the selection and tree structure. This check is potentially expensive,
   * so be careful not to traverse the tree list if it is not necessary.
   */
  initSubscriptions() {
    if (!this._sourceTree.dataTreeSelection) {
      return;
    }
    const selection = this._sourceTree.dataTreeSelection.changes$.pipe(debounceTime(10));
    const treeData = this._sourceTree.dataTreeSource.flattenedData$;
    combineLatest([treeData, selection])
      .pipe(this.untilDestroy())
      .subscribe(([data, selected]) => {
        if (!selected || (!selected.selected.length && !selected.autoSelected.length)) {
          this.selectionSummary.next({
            selected: [],
            autoSelected: [],
            excluded: [],
            objectSettings: [],
            overwrite: [],
            total: [],
          });
        } else {
          const allSelected = this.getAllSelectedNodes(data, selected);

          const transformedSelection = this._sourceTree.selection;
          this.selectionSummary.next({
            selected: this.getSelectedNodes(selected, transformedSelection),
            autoSelected: selected.autoSelected,
            excluded: selected.excluded,
            objectSettings: this.getObjectSettingsNodes(allSelected || data, selected.options),
            overwrite: this.treeService.getAlreadyProtectedObjects(this.editingObjectId),
            total: allSelected,
          });
        }
        this.cdr.detectChanges();
      });
  }

  /**
   * Removes objects from the curernt selection, either by deselection or exclusion.
   *
   * @param objects The objects to deselect.
   */
  deselectObjects(objects: DataTreeNode<any>[]) {
    const selection = this.treeService.dataTreeSelection;
    objects.forEach(object => {
      if (selection.isSelected(object)) {
        selection.toggleNodeSelection(object);
      } else if (selection.isAutoSelected(object)) {
        selection.toggleNodeAutoSelection(object);
      } else if (selection.isAncestorAutoSelected(object)) {
        selection.toggleNodeExclude(object);
      }
    });

    // It takes a moment for new selection to resolve itself, so if we don't wait a moment here, form won't get the
    // updated selectoin.
    setTimeout(() => this.selectionUpdated.emit(), 10);
  }

  /**
   * Updates the detail focus whenever a section is clicked. If the section is already selected
   * it will be toggled off.
   *
   * @param   focus   The property to focus on.
   */
  toggleDetailFocus(focus: SummaryDetail) {
    this.focusedDetail = focus !== this.focusedDetail ? focus : null;
  }

  /**
   * Returns true if special param settings should be disabled
   * when object is autoprotected in dms context.
   *
   * @param  ctx   DataTreeNodeContext
   * @returns   True if the special parameters outlet should be disabled.
   */
  isDmsAutoProtectSpecialParamsDisabled(ctx: DataTreeNodeContext<DataTreeNode<any>>) {
    return isDmsScope(this.irisContext.irisContext) &&
      ctx.ancestorAutoSelected &&
      Boolean((ctx.node as any).disableDmsAutoProtectSpecialParams);
  }

  /**
   * Gets manually selected nodes.
   *
   * If we can calulcate leaves, this is just a subset of selected nodes that are leafs.
   * If we can't, we will callback to using the SourceSelection sourceIds - auto selection count.
   *
   * @param    currentSelection   The current selection.
   * @param    sourceTreeValue    The transformed source selection with just ids.
   * @returns  The list of selected objects.
   */
  private getSelectedNodes(
    currentSelection: DataTreeSelection<any>,
    sourceTreeValue: SourceSelection
  ): DataTreeNode<any>[] {
    if (!this.canCalculateLeaves) {
      const sourceIds = sourceTreeValue.sourceIds || [];
      return currentSelection.selected.filter(node => sourceIds.includes(node.id));
    }
    return currentSelection.selected.filter(node => this.treeService.isLeaf(node));
  }
}
