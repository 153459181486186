// COMPONENT: cTreeNode

;(function(angular, undefined) {
  'use strict';

  var modName = 'C.entityTree';
  var componentName = 'cEntityTreeNode';
  var options = {
    bindings: {
      job: '=?',
      jobObjCounts: '=?',
      jobTagSets: '=?',
      node: '=',
      options: '=?',
    },
    controller: 'EntityTreeNodeCtrl',
    templateUrl: 'app/global/c-entity-tree/c-entity-tree-node.html',
  };

  var defaultOptions = {
    isExcludedBranch: false,
    isRootNode: true,
  };

  angular
    .module(modName)
    .controller('EntityTreeNodeCtrl', cEntityTreeNodeControllerFn)
    .component(componentName, options);

  /**
   * @ngdoc component
   * @name C.entityTree:cEntityTreeNode
   * @function
   *
   * @description
   * component to display a tree branch/node on page
   *
   * @example
     <c-entity-tree-node node="node" options="$ctrl.options" job="$ctrl.job"></c-entity-tree-node>
  */
  function cEntityTreeNodeControllerFn($rootScope, SourceService,
    JobService, ENUM_HOST_TYPE, ENV_GROUPS) {

    var ctrl = this;
    var onUpdate;

    /**
     * launches interface (via cSlideModal) to edit
     * backupParamas for the provided node. On successful
     * resolve of cSlideModal, saves the backupParms to a
     * temp params object for later processing
     */
    ctrl.editBackupParams = function editBackupParams() {

      JobService.editBackupParams(ctrl.node, ctrl.job).then(
        function optionsSelected(newBackupSourceParams) {

          if (newBackupSourceParams) {
            ctrl.node._backupSourceParams = newBackupSourceParams;
            if (newBackupSourceParams.physicalParams) {
              ctrl.node._isSystemBackupEnabled =
                newBackupSourceParams.physicalParams.enableSystemBackup;
            }
          } else {
            ctrl.node._backupSourceParams = undefined;
            ctrl.node._isSystemBackupEnabled = undefined;
          }

        }
      );

    };

    /**
     * removes this node from the jobs sources or excludeSources
     * depending on the node's state
     */
    ctrl.removeFromJob = function removeFromJob() {
      var jobArr = ctrl.node._isSelected ? 'sources' : 'excludeSources';

      ctrl.job[jobArr].some(
        (ctrl.node.entities) ?
          findAndRemoveTagSource : findAndRemoveStandardNode
      );

      onUpdate();

    };

    /**
     * excludes the node (and sub nodes) from auto protection
     *
     * @param      {object}  $event  click event, if called from DOM action
     */
    ctrl.excludeNode = function excludeNode($event) {

      if ($event) {
        $event.stopPropagation();
      }

      ctrl.job.excludeSources = ctrl.job.excludeSources || [];
      ctrl.job.excludeSources.push({
        entities: [ctrl.node.entity],
      });

      SourceService.unselectNode(ctrl.node, {
        autoProtect: ctrl.node._isAncestorAutoProtected,
        tagAutoProtect: ctrl.node._isTagAutoProtected,
        selectedObjectsCounts: ctrl.jobObjCounts,
      });

      onUpdate();

    };

    /**
     * Determines whether to show the edit icon in a given tree node.
     *
     * @method     showTreeNodeEditIcon
     * @return     {boolean}  returns true if should show edit icon
     */
    ctrl.showTreeNodeEditIcon = function showTreeNodeEditIcon() {
      // If Job is SQL and not configured as Volume-based, don't show edit icon.
      if (ctrl.job.type === 3 &&
        ctrl.job.envBackupParams.sqlBackupJobParams.fullBackupType !== 0) {
        return false;
      }

      /*
       * NOTE: Per ENG-19121, temporarily hide the edit icon for SQL jobs
       * because QA hasn't qualified it and will not attempt to qualify it
       * until a customer asks for the feature. Once ready to qualify, remove
       * the following line of code.
       */
      if (ctrl.job.type === 3 && ctrl.job.parentSource.type === 1) {
        return false;
      }

      // Job type is NOT Physical File-based AND
      return ctrl.job.type !== 13 &&

        // Object can host MS SQL Server AND
        ENV_GROUPS.sqlHosts.includes(ctrl.node.entity.type) &&

        // Object is not MS SQL Cluster AND
        (!ctrl.node.entity.physicalEntity ||
          ctrl.node.entity.physicalEntity.type !== 2) &&

        // Object is a leaf node or other hosts that have app entities as their
        // leaf nodes.
        (ctrl.node._isPhysicalHost || ctrl.node._isVirtualHost ||
          ctrl.node._isLeaf) &&

        // Object is selected AND
        ctrl.node._isSelected &&

        // Action icons enabled AND
        ctrl.options.showActionIcons &&

        // Not an excluded branch
        !ctrl.options.isExcludedBranch;
    };

    ctrl.getFSProtocols = SourceService.getFSProtocols;

    /**
     * used in conjunction with array iterator (Array.prototype.some()) to find
     * and remove a standard entity node from the job
     *
     * @param      {object}   source      The current source
     * @param      {integer}  index       The index of the current source in the
     *                                    sourcesArr
     * @param      {array}    sourcesArr  The sources/excludeSources array being
     *                                    walked
     * @return     {boolean}  true if the source was identified and remove,
     *                        false otherwise. indicates of the iteration should
     *                        stop
     */
    function findAndRemoveStandardNode(source, index, sourcesArr) {

      var selectionFn;

      if (SourceService.isSameEntity(source.entities[0], ctrl.node.entity)) {
        sourcesArr.splice(index, 1);

        selectionFn = ctrl.node._isSelected ?
          SourceService.unselectNode : SourceService.selectNode;

        selectionFn(ctrl.node, {
          autoProtect: ctrl.node._isAutoProtected,
          selectedObjectsCounts: ctrl.jobObjCounts,
          tagAutoProtect: ctrl.node._isTagAutoProtected,
          unexclude: ctrl.node._isExcluded,
        });

        return true;
      }
    }

    /**
     * used in conjunction with array iterator (Array.prototype.some()) to find
     * and remove a tag source from the job, also removes the tagSet from
     * jobTagSets[]
     *
     * @param      {object}   source      The current source object to check
     * @param      {integer}  index       The index
     * @param      {array}    sourcesArr  The sources/excludeSources array being
     *                                    walked
     * @return     {boolean}  true if the source was identified and remove,
     *                        false otherwise. indicates of the iteration should
     *                        stop
     */
    function findAndRemoveTagSource(source, index, sourcesArr) {
      var foundSource;
      var selectionFn;

      if (source.entities.length !== ctrl.node.entities.length) {
        return false;
      }

      foundSource = source.entities.every(function matchAllEntities(entity) {
        return ctrl.node.entities.some(function someMatch(nodeEntity) {
          return SourceService.isSameEntity(entity, nodeEntity);
        });
      });

      if (foundSource) {
        sourcesArr.splice(index, 1);

        selectionFn = ctrl.node._isSelected ?
          SourceService.unselectNode : SourceService.selectNode;

        // walk and unselect children of the tag
        ctrl.node.children.forEach(function unselectChildren(child) {
          selectionFn(child, {
            tagAutoProtect: true,
            selectedObjectsCounts: ctrl.jobObjCounts,
          });
        });

        removeTagSet();
      }

      return foundSource;
    }

    /**
     * remove this tagSet/node from jobTagSets[]
     */
    function removeTagSet() {
      var tagSetIndex = [].concat(ctrl.jobTagSets).indexOf(ctrl.node);

      if (~tagSetIndex) {
        ctrl.jobTagSets.splice(tagSetIndex, 1);
      }
    }

    /**
     * Controller initialization function
     *
     * @method $onInit
     */
    ctrl.$onInit = function $onInit() {
      onUpdate = (typeof ctrl.options.onUpdate === 'function') ?
        ctrl.options.onUpdate : angular.noop;

      ctrl.enumHostType = ENUM_HOST_TYPE;
      ctrl.ENV_GROUPS = ENV_GROUPS;
      ctrl.envType = ctrl.node.entity.type;

      // TODO(Tauseef): Remove text on rootScope.
      ctrl.text = $rootScope.text.cEntityTreeNode;
      ctrl.options = _.assign({}, defaultOptions, ctrl.options);
    };

    /**
     * Handle click on an item and notify change for this node
     *
     * @method   onSelectedForBackupChange
     */
    ctrl.onSelectedForBackupChange = function onSelectedForBackupChange() {
      onUpdate(ctrl.node);
    };
  }

})(angular);
