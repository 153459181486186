<h3>{{ 'viewIssues' | translate }}</h3>

<!-- Filter -->
<cog-filters #filters>
  <ng-container>
    <cog-value-property-filter
      *cogFilterDef="
        let params = params;
        let filter;
        quickFilter: true;
        key: 'fixStatus';
        label: 'issue' | translate;
        filterType: 'valueProperty'"
      [filterDefParams]="params"
      [filter]="filter"
      [allowMultiple]="false"
      [autoApply]="true"
      [filterValues]="filterValuesList.fixStatus"
      [title]="'issue' | translate">
    </cog-value-property-filter>
  </ng-container>
  <ng-container>
    <cog-value-property-filter
      *cogFilterDef="
        let params = params;
        let filter;
        quickFilter: true;
        key: 'clusterName';
        label: 'cluster' | translate;
        filterType: 'valueProperty'"
      [filterDefParams]="params"
      [filter]="filter"
      [allowMultiple]="true"
      [autoApply]="true"
      [filterValues]="filterValuesList.cluster"
      [title]="'cluster' | translate">
    </cog-value-property-filter>
  </ng-container>
</cog-filters>

<!-- Issues table -->
<cog-table name="issue-table"
  class="issue-table margin-top"
  [source]="issueData"
  [filters]="filters.filterValues$ | async">
  <table multiTemplateDataRows mat-table matSort matSortActive="fixStatus" matSortDirection="desc">
    <!-- Icon Column -->
    <ng-container matColumnDef="icon">
      <th mat-header-cell *matHeaderCellDef mat-sort-header cogDataId="issue-icon-header"></th>
      <td mat-cell *matCellDef="let row;index as i" cogDataId="fixed-status-icon-{{i}}">
        <cog-icon [shape]="row.isFixed ? 'helix:status-success!success' : 'helix:status-info!critical'"></cog-icon>
      </td>
    </ng-container>

    <!-- Is fixed Column -->
    <ng-container matColumnDef="fixStatus">
      <th mat-header-cell mat-sort-header *matHeaderCellDef cogDataId="fix-status-header">
        {{ 'fixStatus' | translate }}
      </th>
      <td mat-cell *matCellDef="let row;index as i" cogDataId="fixed-status-{{i}}">
        {{ row.fixStatus | translate }}
      </td>
    </ng-container>

    <!-- Issue Column -->
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef cogDataId="description-header">{{ 'description' | translate }}</th>
      <td mat-cell *matCellDef="let row;index as i" [matTooltip]="row.description" cogDataId="description-{{i}}">
        <div class="flex-truncate-wrapper">
          <div class="flex-truncate-text">
            {{ row.description }}
          </div>
        </div>
      </td>
    </ng-container>

    <!-- Cluster Column -->
    <ng-container matColumnDef="clusterName">
      <th mat-header-cell mat-sort-header *matHeaderCellDef cogDataId="cluster-header">
        {{ 'cluster' | translate }}
      </th>
      <td mat-cell *matCellDef="let row;index as i" cogDataId="clustername-{{i}}">
        {{ row.clusterName }}
      </td>
    </ng-container>

    <!-- Suggestions Column -->
    <ng-container matColumnDef="suggestions">
      <th mat-header-cell *matHeaderCellDef cogDataId="suggestion-header">
        {{ 'suggestions' | translate }}
      </th>
      <td mat-cell *matCellDef="let row;index as i" [matTooltip]="row.suggestion" cogDataId="suggestion-{{i}}">
        <div class="flex-truncate-wrapper" cogDataId="suggestion-{{i}}">
          <div class="flex-truncate-text">
            {{ row.suggestion }}
          </div>
        </div>
      </td>
    </ng-container>

    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
    <ng-container matColumnDef="expandedDetail">
      <td class="issue-detail" mat-cell *matCellDef="let row" [attr.colspan]="columns.length">
        <div class="row-container" [@expandCollapse]="row === expandedRow ? 'expanded' : 'collapsed'">
          <div class="header-details">
            <h5 class="title">{{ 'clusters' | translate }}</h5>
            <p>{{ row.clusterName }}</p>
            <h5 class="title">{{ 'issue' | translate }}</h5>
            <p>{{ row.description }}</p>
            <h5 class="title">{{ 'suggestions' | translate }}</h5>
            <a target="_blank" href="{{row.documentUrl}}">{{ row.suggestion }}</a>
          </div>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: columns;let index = index"
      class="issue-common-row"
      [class.expanded-row]="expandedRow === row"
      (click)="!row.isFixed && expandedRow = expandedRow === row ? null : row">
    </tr>
    <tr
      mat-row
      *matRowDef="let row; columns: ['expandedDetail']"
      [ngClass]="{ 'issue-detail-row': expandedRow === row, 'issue-detail-row-hidden': expandedRow !== row }">
    </tr>

    <!-- Loading Footer -->
    <ng-container matColumnDef="loading">
      <td [attr.colspan]="columns.length" mat-footer-cell *matFooterCellDef>
        <cog-spinner></cog-spinner>
      </td>
    </ng-container>
    <tr class="loading-row" mat-footer-row [class.hidden]="!loading" *matFooterRowDef="['loading']"></tr>

    <!-- NoData Footer -->
    <ng-container matColumnDef="noIssue">
      <td [attr.colspan]="columns.length" mat-footer-cell *matFooterCellDef>
        {{ 'noResultsFound' | translate }}
      </td>
    </ng-container>
    <tr
      class="nodata-row"
      mat-footer-row
      [class.hidden]="loading || issueData?.length"
      *matFooterRowDef="['noIssue']">
    </tr>
  </table>
  <mat-paginator></mat-paginator>
</cog-table>
