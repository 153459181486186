import { HttpClient } from '@angular/common/http';
import { Injectable, Optional } from '@angular/core';
import { Api } from '@cohesity/api/private';
import { ProtectionGroup, ProtectionGroups, ProtectionGroupServiceApi, ProtectionGroupRuns } from '@cohesity/api/v2';
import { flagEnabled, IrisContextService, isClusterScope, isMcm, isRpaasScope } from '@cohesity/iris-core';
import { Observable } from 'rxjs';

import { PassthroughOptionsService } from './passthrough-options.service';

/**
 * Certain protection group CRUD actions can be done using MCM as well. This
 * service calls the MCM endpoints instead of OnPrem endpoints when in MCM mode.
 * The benefit of using MCM endpoints is that those requests get intercepted and
 * ETL gets updated without a lag.
 *
 * An MCM version of GetProtectionGroups to be able to fetch protection groups
 * across regions and clusters.
 */
@Injectable({
  providedIn: 'root'
})
export class HybridProtectionGroupApiService extends ProtectionGroupServiceApi {
  constructor(
    @Optional() private irisContextService: IrisContextService,
    httpClient: HttpClient,
    private passthroughOptionsService: PassthroughOptionsService,
  ) {
    super({ rootUrl: Api.v2Root }, httpClient);

    return new Proxy(this, {
      get: function(target, prop) {
        return function(...args) {
          // Intercept protection group API calls and add passthrough params
          // to it.
          return target[prop](
            target.passthroughOptionsService.getPassthroughOptionsParams(args[0])
          );
        };
      }
    });
  }

  /**
   * Use MCM api for protection group calls.
   */
  get useMcmApi(): boolean {
    return isMcm(this.irisContextService.irisContext)
      && !isClusterScope(this.irisContextService.irisContext)
      && !isRpaasScope(this.irisContextService.irisContext)
      && !this.passthroughOptionsService.accessClusterId;
  }

  /**
   * The Create, Get Collection, Update and Delete have an MCM function.
   * Use that when in mcm mode.
   */

  CreateProtectionGroup(params: ProtectionGroupServiceApi.CreateProtectionGroupParams): Observable<ProtectionGroup> {
    if (this.useMcmApi) {
      return super.CreateMcmProtectionGroup(params.body, params.regionId);
    }

    return super.CreateProtectionGroup(params);
  }

  GetProtectionGroups(params: ProtectionGroupServiceApi.GetMcmProtectionGroupsParams |
   ProtectionGroupServiceApi.GetProtectionGroupsParams): Observable<ProtectionGroups> {
    if (this.useMcmApi) {
      return super.GetMcmProtectionGroups(params);
    }

    return super.GetProtectionGroups({
      ...params,
      useCachedData: flagEnabled(this.irisContextService.irisContext, 'useMagnetoCachedData')
    });
  }

  UpdateProtectionGroup(params: ProtectionGroupServiceApi.UpdateProtectionGroupParams): Observable<ProtectionGroup> {
    if (this.useMcmApi) {
      return super.UpdateMcmProtectionGroup(params);
    }

    return super.UpdateProtectionGroup(params);
  }

  DeleteProtectionGroup(params: ProtectionGroupServiceApi.DeleteProtectionGroupParams): Observable<null> {
    if (this.useMcmApi) {
      return super.DeleteMcmProtectionGroup(params);
    }

    return super.DeleteProtectionGroup(params);
  }

  GetProtectionGroupRuns(params:
    ProtectionGroupServiceApi.GetProtectionGroupRunsParams): Observable<ProtectionGroupRuns> {
    return super.GetProtectionGroupRuns({
      ...params,
      useCachedData: flagEnabled(this.irisContextService.irisContext, 'useMagnetoCachedData')
    });
  }
}
