import { ReportColumnConfigs } from '../common-report-items/custom-reports.service';
import { ReportSection } from '../iris-reporting.model';
import {
  commonReportConfigItems,
  getStorageConsumptionColumns,
  storageConsumptionCommonColumns,
} from './common-report-config-items';

/**
 * This is the layout config for the storage consumption by views report.
 */
export const storageConsumptionViewReport: ReportSection[] = [
  {
    title: 'reporting.summary',
    items: [
      {
        id: 'statlistItem',
        componentIds: ['1401'],
        componentNames: ['StorageConsumptionViewsSummary'],
        params: {
          items: [
            {
              title: 'reporting.viewCount',
              translate: true,
              measurement: {
                valueKey: 'countViewId',
                dataType: 'number',
              },
            },
            ...commonReportConfigItems.storageConsumption.glanceBar,
          ],
        },
      },
    ],
  },
  {
    title: 'reporting.data',
    isFullData: true,
    items: [
      {
        id: 'tableItem',
        componentIds: ['1400'],
        componentNames: ['AllStorageConsumptionByViews'],
        params: {
          columns: [
            {
              title: 'viewName',
              translate: true,
              key: 'viewName',
              icon: {
                dataType: 'viewIcon',
              },
              primary: {
                key: 'viewName',
                dataType: 'text',
              },
              subtitle: {
                key: 'viewBoxName',
                dataType: 'text',
              },
            },
            {
              title: 'organization',
              enabled: false,
              translate: true,
              key: 'tenantName',
              primary: {
                key: 'tenantName',
                dataType: 'text',
                renderParams: {
                  emptyKey: 'naNotAvailable',
                },
              },
              featureFlag: 'ngReportSPView',
            },
            {
              title: 'system',
              translate: 'true',
              key: 'systemName',
              primary: {
                key: 'systemName',
                dataType: 'text',
              },
            },
            ...getStorageConsumptionColumns('AllStorageConsumptionByViews'),
          ],
        },
      },
    ],
  },
];

/**
 * This is metadata needed to determine available chart customizations.
 */
export const storageConsumptionViewColumns: ReportColumnConfigs = {
  viewName: {
    aggs: [],
    canGroup: true,
    maxGroupingValues: 5,
    dataType: 'text',
  },
  viewId: {
    aggs: ['count'],
    dataType: 'uuid',
  },
  viewBoxName: {
    aggs: [],
    canGroup: true,
    maxGroupingValues: 5,
    dataType: 'text',
  },
  viewBoxId: {
    aggs: ['count'],
    dataType: 'uuid',
  },
  systemName: {
    aggs: [],
    canGroup: true,
    maxGroupingValues: 5,
    dataType: 'text',
  },
  systemId: {
    aggs: ['count'],
    dataType: 'uuid',
  },
  ...storageConsumptionCommonColumns,
};
