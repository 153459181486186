import { AppModuleConfig, StateAccessContext, StateAccessMap } from 'src/app/app-module.config';

const protectModifyRds = (ctx: StateAccessContext) =>
  ctx.workflow.backupAndRecovery.kRDSSnapshotManager;


const protectModifyAurora = (ctx: StateAccessContext) =>
  ctx.workflow.backupAndRecovery.kAuroraSnapshotManager;

const protectModifyAwsDynamoDB = (ctx: StateAccessContext) =>
  ctx.workflow.backupAndRecovery.kAwsDynamoDB;

export const AwsDbConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'protection-builder-rds.**',
      url: '/protection/protect/rds',
      loadChildren: () => import('./aws-db.module').then(m => m.AwsDbModule),
    },
    {
      name: 'protection-builder-aurora.**',
      url: '/protection/protect/aurora',
      loadChildren: () => import('./aws-db.module').then(m => m.AwsDbModule),
    },
    {
      name: 'protection-builder-aws-dynamodb.**',
      url: '/protection/protect/aws-dynamodb',
      loadChildren: () => import('./aws-db.module').then(m => m.AwsDbModule),
    },
  ],
  getStateAccessMap(): StateAccessMap {
    return {
      'protection-builder-rds.create': protectModifyRds,
      'protection-builder-rds.edit': protectModifyRds,
      'protection-builder-aurora.create': protectModifyAurora,
      'protection-builder-aurora.edit': protectModifyAurora,
      'protection-builder-aws-dynamodb.create': protectModifyAwsDynamoDB,
      'protection-builder-aws-dynamodb.edit': protectModifyAwsDynamoDB,
    };
  },
};
