// Component: Download bifrost component.

;(function(angular, undefined) {
  'use strict';

  angular.module('C.tenants')
    .controller('DownloadBifrostCtrl', DownloadBifrostCtrlFn);

  function DownloadBifrostCtrlFn(_, TenantService, FEATURE_FLAGS) {
    var $ctrl = this;
    _.assign($ctrl, {
      selectedPlatform: null,
      onDownloadClick: onDownloadClick,
      platforms: [
        {
          label: 'deploymentPlatform.vmware',
          value: 'vmware'
        },
        {
          label: 'deploymentPlatform.hyperV',
          value: 'hyperv'
        },
      ],
      isHyperVMTEnabled: FEATURE_FLAGS.enableHyperVMT
    });

    /**
     * On click handler for the download Hyx action.
     *
     * @method   onDownloadClick
     */
    function onDownloadClick() {
      if (!$ctrl.instruction || !$ctrl.instruction.data) {
        return;
      }

      let platform;
      if ($ctrl.isHyperVMTEnabled) {
        // When hyperV is enabled, we need a selection of the platform to continue.
        if (!$ctrl.selectedPlatform) {
          return;
        }

        platform = $ctrl.selectedPlatform.value;
      } else {
        // When hyperV is disabled, the only platform available is 'vmware'.
        platform = 'vmware';
      }

      TenantService.getTenantProxy('image', $ctrl.instruction.data.tenantId, null, platform);
      return;
    }
  }

})(angular);
