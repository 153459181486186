import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { CohesityHelixModule, DataIdModule } from '@cohesity/helix';
import { TranslateModule } from '@ngx-translate/core';

import { PostureAdvisorScoreComponent } from './posture-advisor-score.component';

@NgModule({
  imports: [
    CohesityHelixModule,
    CommonModule,
    DataIdModule,
    TranslateModule,
  ],
  exports: [PostureAdvisorScoreComponent],
  declarations: [PostureAdvisorScoreComponent],
})
export class PostureAdvisorScoreModule {}
