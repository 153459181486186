import { Component, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { AppSwitcherConfig } from '@cohesity/helix';
import { getConfigByKey, IrisContextService } from '../../../iris-context';
import { appPillarsAppUrl, appPillarsBaseUrl, Pillar, pillars } from '../../app-pillars.model';
import { AppPillarsService } from '../../app-pillars.service';
import { mergePillarsOverrides } from '../../app-pillars.utils';

/**
 * Extends AppSwitcherConfig to support children (tree structure).
 */
interface AppPillarConfig extends AppSwitcherConfig<string> {
  apps?: AppSwitcherConfig<string>[];
}

@Component({
  selector: 'coh-app-pillars-switcher',
  templateUrl: './app-pillars-switcher.component.html',
  styleUrls: ['./app-pillars-switcher.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppPillarsSwitcherComponent {
  /**
   * App configurations list.
   */
  _appConfigs: AppSwitcherConfig<string>[];

  @Input() set appConfigs(appConfigs: AppSwitcherConfig<string>[]) {
    this._appConfigs = appConfigs;

    // Create the app nav structure for 5 pillars.
    this.apps = [{
      name: 'appPillars.title',
      icon: 'helix:cloud',
      urlSegment: appPillarsBaseUrl,
    }];

    const globalApp = this.appConfigs?.find(a => a.app === 'global');
    const pillarOverrides = getConfigByKey<Partial<Pillar>[]>(this.irisContextService.irisContext, 'appPillarsOverrides', []);
    const mergedPillars = mergePillarsOverrides(pillars, pillarOverrides);

    if (globalApp) {
      this.apps.push({
        ...globalApp,
        icon: 'helix:pillar-global',
      });
    }

    this.apps.push(
      ...mergedPillars.map(pillar => ({
        name: pillar.title,
        icon: `helix:pillar-${pillar.type}`,
        apps: pillar.items.filter(item => {
          if (item.hidden) {
            // This app has been removed through app config
            return false;
          }

          if (!this.appPillarsService.hideUnsubscribedServices) {
            return true;
          }

          // An app exists for the pillar item
          return this.appConfigs?.some(a => a.app === item.app);
        }).map(item => {
          const appConfig = this.appConfigs?.find(a => a.app === item.app);

          if (appConfig) {
            return {
              ...appConfig,
              name: item.title,
            };
          }
          return {
            name: item.title,
            urlSegment: appPillarsAppUrl + item.app,
          };
        }),

        // Remove pillars without any apps
      })).filter(pillar =>!!pillar.apps.length),
    );
  };

  get appConfigs(): AppSwitcherConfig<string>[] {
    return this._appConfigs;
  }

  /**
   * App structure for 5 pillars.
   */
  apps: AppPillarConfig[];

  /**
   * Allow control of menu open/close
   */
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  constructor(
    private irisContextService: IrisContextService,
    private appPillarsService: AppPillarsService,
  ) {
    // Trigger update this.apps when user changes preferences.
    this.appPillarsService.hideUnsubscribedServices$.subscribe(() => {
      this.appConfigs = this._appConfigs;
    });
  }

  /**
   * Navigated to app by reloading the page. Copied from app-switcher component.
   *
   * @param app The app config.
   * @param event The mouse click event.
   */
  goto(app: AppSwitcherConfig<string>, event: MouseEvent) {
    if (typeof app.go === 'function') {
      // Prevent the url href from activating.
      event.preventDefault();
      event.stopPropagation();
      app.go();
      this.trigger.closeMenu();
    }
  }

  /**
   * Gets href property for app. Copied from app-switcher component.
   *
   * @param app Application object.
   */
  getAppHref = (app: AppSwitcherConfig<string>) => [
    app.urlSegment,
    new URLSearchParams([...Object.entries(app.params || {})]).toString(),
  ].filter(Boolean).join('?');
}
