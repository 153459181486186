/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityApiConfiguration as __Configuration } from '../cohesity-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { UpgradePhysicalAgentsMessage } from '../models/upgrade-physical-agents-message';
import { UpgradePhysicalServerAgents } from '../models/upgrade-physical-server-agents';
import { ProtectionSourceNode } from '../models/protection-source-node';
import { RegisteredApplicationServer } from '../models/registered-application-server';
import { ProtectionSource } from '../models/protection-source';
import { UpdateApplicationServerParameters } from '../models/update-application-server-parameters';
import { RegisterApplicationServersParameters } from '../models/register-application-servers-parameters';
import { UnRegisterApplicationServersParameters } from '../models/un-register-application-servers-parameters';
import { RunDiagnosticsMessage } from '../models/run-diagnostics-message';
import { ExchangeDagHostsResponse } from '../models/exchange-dag-hosts-response';
import { ProtectedVmInfo } from '../models/protected-vm-info';
import { RegisterProtectionSourceParameters } from '../models/register-protection-source-parameters';
import { GetRegistrationInfoResponse } from '../models/get-registration-info-response';
import { SqlAagHostAndDatabases } from '../models/sql-aag-host-and-databases';
import { UpdateProtectionSourceParameters } from '../models/update-protection-source-parameters';
@Injectable({
  providedIn: 'root',
})
class ProtectionSourcesService extends __BaseService {
  static readonly DownloadPhysicalAgentPath = '/public/physicalAgents/download';
  static readonly UpgradePhysicalAgentsPath = '/public/physicalAgents/upgrade';
  static readonly ListProtectionSourcesPath = '/public/protectionSources';
  static readonly ListApplicationServersPath = '/public/protectionSources/applicationServers';
  static readonly UpdateApplicationServersPath = '/public/protectionSources/applicationServers';
  static readonly RegisterApplicationServersPath = '/public/protectionSources/applicationServers';
  static readonly UnregisterApplicationServersPath = '/public/protectionSources/applicationServers/{id}';
  static readonly ListDataStoreInformationPath = '/public/protectionSources/datastores';
  static readonly RunDiagnosticsPath = '/public/protectionSources/diagnostics/{id}';
  static readonly ListExchangeDagHostsPath = '/public/protectionSources/exchangeDagHosts';
  static readonly GetProtectionSourcesObjectsPath = '/public/protectionSources/objects';
  static readonly GetProtectionSourcesObjectByIdPath = '/public/protectionSources/objects/{id}';
  static readonly ListProtectedObjectsPath = '/public/protectionSources/protectedObjects';
  static readonly RefreshProtectionSourceByIdPath = '/public/protectionSources/refresh/{id}';
  static readonly RegisterProtectionSourcePath = '/public/protectionSources/register';
  static readonly ListProtectionSourcesRegistrationInfoPath = '/public/protectionSources/registrationInfo';
  static readonly ListProtectionSourcesRootNodesPath = '/public/protectionSources/rootNodes';
  static readonly ListSqlAagHostsAndDatabasesPath = '/public/protectionSources/sqlAagHostsAndDatabases';
  static readonly ListVirtualMachinesPath = '/public/protectionSources/virtualMachines';
  static readonly UnregisterProtectionSourcePath = '/public/protectionSources/{id}';
  static readonly UpdateProtectionSourcePath = '/public/protectionSources/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Download the physical agent for a host type.
   *
   * Host type could be Linux, Windows, AIX.
   * @param params The `ProtectionSourcesService.DownloadPhysicalAgentParams` containing the following parameters:
   *
   * - `hostType`: Specifies the host type for which user wants to download the
   *   physical agent.
   *   'kLinux' indicates the Linux operating system.
   *   'kWindows' indicates the Microsoft Windows operating system.
   *   'kAix' indicates the IBM AIX operating system.
   *   'kSolaris' indicates the Oracle Solaris operating system.
   *   'kSapHana' indicates the Sap Hana database system developed by SAP SE.
   *   'kOther' indicates the other types of operating system.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `pkgType`: Specifies the Linux installer package type applicable only to Linux
   *   OS and the value can be any of ("kScript","kRPM", "kSuseRPM", "kDEB")
   *   'kScript' indicates a script based agent installer.
   *   'kRPM' indicates a RPM agent installer.
   *   'kSuseRPM' indicates a Open Suse RPM installer.
   *   'kDEB' indicates a Debian agent installer.
   *
   * - `agentType`: Specifies agent type. Can be "kGo" for go agent and "kJava"
   *   for java agent and "kCpp" for c++ agent.
   *   'kCpp' indicates a c++ agent.
   *   'kJava' indicates a java agent.
   *   'kGo' indicates a go agent.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  DownloadPhysicalAgentResponse(params: ProtectionSourcesService.DownloadPhysicalAgentParams): __Observable<__StrictHttpResponse<Array<number>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.hostType != null) __params = __params.set('hostType', params.hostType.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.pkgType != null) __params = __params.set('pkgType', params.pkgType.toString());
    if (params.agentType != null) __params = __params.set('agentType', params.agentType.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/physicalAgents/download`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<number>>;
      })
    );
  }
  /**
   * Download the physical agent for a host type.
   *
   * Host type could be Linux, Windows, AIX.
   * @param params The `ProtectionSourcesService.DownloadPhysicalAgentParams` containing the following parameters:
   *
   * - `hostType`: Specifies the host type for which user wants to download the
   *   physical agent.
   *   'kLinux' indicates the Linux operating system.
   *   'kWindows' indicates the Microsoft Windows operating system.
   *   'kAix' indicates the IBM AIX operating system.
   *   'kSolaris' indicates the Oracle Solaris operating system.
   *   'kSapHana' indicates the Sap Hana database system developed by SAP SE.
   *   'kOther' indicates the other types of operating system.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `pkgType`: Specifies the Linux installer package type applicable only to Linux
   *   OS and the value can be any of ("kScript","kRPM", "kSuseRPM", "kDEB")
   *   'kScript' indicates a script based agent installer.
   *   'kRPM' indicates a RPM agent installer.
   *   'kSuseRPM' indicates a Open Suse RPM installer.
   *   'kDEB' indicates a Debian agent installer.
   *
   * - `agentType`: Specifies agent type. Can be "kGo" for go agent and "kJava"
   *   for java agent and "kCpp" for c++ agent.
   *   'kCpp' indicates a c++ agent.
   *   'kJava' indicates a java agent.
   *   'kGo' indicates a go agent.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  DownloadPhysicalAgent(params: ProtectionSourcesService.DownloadPhysicalAgentParams): __Observable<Array<number>> {
    return this.DownloadPhysicalAgentResponse(params).pipe(
      __map(_r => _r.body as Array<number>)
    );
  }

  /**
   * Initiate a request to upgrade Cohesity agents on one or more Physical Servers
   * registered on the Cohesity Cluster.
   *
   * If the request is successful, the Cohesity agents on the specified
   * Physical Servers are upgraded to the agent release
   * currently available from this Cohesity Cluster.
   * For example if the Cluster is upgraded from 3.7.1 to 4.0,
   * the agents on the specified Physical Servers can be upgraded to 4.0 using
   * this POST operation.
   * To get the agentIds to pass into this operation, call
   * GET /public/protectionSources with the environment set to 'KPhysical'.
   * In addition this GET operation returns the agentUpgradability field, that
   * indicates if an agent can be upgraded. Use the agentUpgradability field
   * to determine which Physical Servers to upgrade using this
   * POST /public/physicalAgents/upgrade operation.
   *
   * WARNING: Only agents at a particular Cohesity release can be
   * upgraded using this operation.
   * See the Cohesity online help for details.
   *
   * Returns the status of the upgrade initiation.
   * @param params The `ProtectionSourcesService.UpgradePhysicalAgentsParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`: Request to upgrade agents on Physical Servers.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpgradePhysicalAgentsResponse(params: ProtectionSourcesService.UpgradePhysicalAgentsParams): __Observable<__StrictHttpResponse<UpgradePhysicalAgentsMessage>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    __body = params.body;
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/physicalAgents/upgrade`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UpgradePhysicalAgentsMessage>;
      })
    );
  }
  /**
   * Initiate a request to upgrade Cohesity agents on one or more Physical Servers
   * registered on the Cohesity Cluster.
   *
   * If the request is successful, the Cohesity agents on the specified
   * Physical Servers are upgraded to the agent release
   * currently available from this Cohesity Cluster.
   * For example if the Cluster is upgraded from 3.7.1 to 4.0,
   * the agents on the specified Physical Servers can be upgraded to 4.0 using
   * this POST operation.
   * To get the agentIds to pass into this operation, call
   * GET /public/protectionSources with the environment set to 'KPhysical'.
   * In addition this GET operation returns the agentUpgradability field, that
   * indicates if an agent can be upgraded. Use the agentUpgradability field
   * to determine which Physical Servers to upgrade using this
   * POST /public/physicalAgents/upgrade operation.
   *
   * WARNING: Only agents at a particular Cohesity release can be
   * upgraded using this operation.
   * See the Cohesity online help for details.
   *
   * Returns the status of the upgrade initiation.
   * @param params The `ProtectionSourcesService.UpgradePhysicalAgentsParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`: Request to upgrade agents on Physical Servers.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpgradePhysicalAgents(params: ProtectionSourcesService.UpgradePhysicalAgentsParams): __Observable<UpgradePhysicalAgentsMessage> {
    return this.UpgradePhysicalAgentsResponse(params).pipe(
      __map(_r => _r.body as UpgradePhysicalAgentsMessage)
    );
  }

  /**
   * Returns the registered Protection Sources and their Object subtrees.
   *
   * If no parameters are specified, all Protection Sources that are registered
   * on the Cohesity Cluster are returned.
   * In addition, an Object subtree gathered from each Source is returned.
   * For example, the Cohesity Cluster interrogates a Source VMware vCenter Server
   * and creates an hierarchical Object subtree that mirrors the
   * Inventory tree on vCenter Server.
   * The contents of the Object tree are returned as a "nodes" hierarchy
   * of "protectionSource"s.
   * Specifying parameters can alter the results that are returned.
   * @param params The `ProtectionSourcesService.ListProtectionSourcesParams` containing the following parameters:
   *
   * - `useCachedData`: Specifies whether we can serve the GET request to the read replica cache.
   *   setting this to true ensures that the API request is served
   *   to the read replica. setting this to false will serve the request to
   *   the master.
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which objects are to be
   *   returned.
   *
   * - `sids`: Filter the object subtree for the sids given in the list.
   *
   * - `requestInitiatorType`: Specifies the type of the request. Possible values are UIUser and UIAuto,
   *   which means the request is triggered by user or is an auto refresh
   *   request. Services like magneto will use this to determine the priority
   *   of the requests, so that it can more intelligently handle overload
   *   situations by prioritizing higher priority requests.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `pruneNonCriticalInfo`: Specifies whether to prune non critical info within entities.
   *   Incase of VMs, virtual disk information will be pruned.
   *   Incase of Office365, metadata about user entities will be pruned.
   *   This can be used to limit the size of the response by caller.
   *
   * - `pageSize`: Specifies the maximum number of entities to be returned within the page.
   *
   * - `numLevels`: Specifies the expected number of levels from the root node to be returned
   *   in the entity hierarchy response.
   *
   * - `nodeId`: Specifies the entity id for the Node at any level within the Source entity
   *   hierarchy whose children are to be paginated.
   *
   * - `isSecurityGroup`: If set to true, Groups which are security enabled will be returned.
   *
   * - `includeVMFolders`: Set this parameter to true to also return kVMFolder object types found in
   *   the Source in addition to their Object subtrees.
   *   By default, VM folder objects are not returned.
   *
   * - `includeSystemVApps`: Set this parameter to true to also return system VApp object types found
   *   in the Source in addition to their Object subtrees.
   *   By default, VM folder objects are not returned.
   *
   * - `includeSourceCredentials`: If specified, then crednetial for the registered sources will be included.
   *   Credential is first encrypted with internal key and then reencrypted with
   *   user supplied 'encryption_key'.
   *
   * - `includeSfdcFields`: Set this parameter to true to also return fields of the object found
   *   in the Source in addition to their Object subtrees.
   *   By default, Sfdc object fields are not returned.
   *
   * - `includeNetworks`: Set this parameter to true to also return kNetwork object types found in
   *   the Source in addition to their Object subtrees.
   *   By default, network objects are not returned.
   *
   * - `includeExternalMetadata`: Specifies if entity external metadata should be included within the
   *   response to get entity hierarchy call.
   *
   * - `includeEntityPermissionInfo`: If specified, then a list of entites with permissions assigned to them are
   *   returned.
   *
   * - `includeDatastores`: Set this parameter to true to also return kDatastore object types found in
   *   the Source in addition to their Object subtrees.
   *   By default, datastores are not returned.
   *
   * - `id`: Return the Object subtree for the passed in Protection Source id.
   *
   * - `hasValidOnedrive`: If set to true, only users with valid onedrive will be returned.
   *
   * - `hasValidMailbox`: If set to true, only users with valid mailbox will be returned.
   *
   * - `getTeamsChannels`: Specifies whether to get the list of the channels for a given M365
   *   Teams object. This should be set to true only when a single Id
   *   belonging to M365 Teams object is provided in the query params.
   *
   * - `excludeTypes`: Filter out the Object types (and their subtrees) that match the passed
   *   in types such as 'kVCenter', 'kFolder', 'kDatacenter',
   *   'kComputeResource', 'kResourcePool', 'kDatastore', 'kHostSystem',
   *   'kVirtualMachine', etc.
   *   For example, set this parameter to 'kResourcePool' to
   *   exclude Resource Pool Objects from being returned.
   *
   * - `excludeOffice365Types`: Specifies the Object types to be filtered out for Office 365 that match
   *   the passed in types such as 'kDomain', 'kOutlook', 'kMailbox', etc.
   *   For example, set this parameter to 'kMailbox' to exclude Mailbox Objects
   *   from being returned.
   *
   * - `excludeKubernetesTypes`: Specifies the Object types to be filtered out for Kubernetes that match
   *   the passed in types such as 'kService'.
   *   For example, set this parameter to 'kService' to exclude services
   *   from being returned.
   *
   * - `excludeAzureTypes`: Specifies the Object types to be filtered out for Azure that match the
   *   passed in types such as 'kVirtualMachine', 'kSQLDatabase'.
   *   For example, set this parameter to 'kVirtualMachine' to exclude VM instances
   *   from being returned.
   *
   * - `excludeAwsTypes`: Specifies the Object types to be filtered out for AWS that match the
   *   passed in types such as 'kEC2Instance', 'kRDSInstance', 'kAuroraCluster',
   *   'kTag', 'kAuroraTag', 'kRDSTag', kS3Bucket, kS3Tag.
   *   For example, set this parameter to 'kEC2Instance' to exclude ec2 instance
   *   from being returned.
   *
   * - `environments`: Return only Protection Sources that match the passed in environment type
   *   such as 'kVMware', 'kSQL', 'kView' 'kPhysical', 'kPuppeteer', 'kPure',
   *   'kNetapp', 'kGenericNas', 'kHyperV', 'kAcropolis', or 'kAzure'.
   *   For example, set this parameter to 'kVMware' to only return the Sources
   *   (and their Object subtrees) found in the 'kVMware'
   *   (VMware vCenter Server) environment.
   *
   *   NOTE: 'kPuppeteer' refers to Cohesity's Remote Adapter.
   *
   * - `environment`: This field is deprecated. Use environments instead.
   *   deprecated: true
   *
   * - `encryptionKey`: Key to be used to encrypt the source credential. If
   *   include_source_credentials is set to true this key must be specified.
   *
   * - `beforeCursorEntityId`: Specifies the entity id upto which the items are to be returned.
   *
   * - `allUnderHierarchy`: AllUnderHierarchy specifies if objects of all the tenants under the
   *   hierarchy of the logged in user's organization should be returned.
   *
   * - `afterCursorEntityId`: Specifies the entity id starting from which the items are to be returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  ListProtectionSourcesResponse(params: ProtectionSourcesService.ListProtectionSourcesParams): __Observable<__StrictHttpResponse<Array<ProtectionSourceNode>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.useCachedData != null) __params = __params.set('useCachedData', params.useCachedData.toString());
    (params.tenantIds || []).forEach(val => {if (val != null) __params = __params.append('tenantIds', val.toString())});
    (params.sids || []).forEach(val => {if (val != null) __params = __params.append('sids', val.toString())});
    if (params.requestInitiatorType != null) __headers = __headers.set('requestInitiatorType', params.requestInitiatorType.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.pruneNonCriticalInfo != null) __params = __params.set('pruneNonCriticalInfo', params.pruneNonCriticalInfo.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.numLevels != null) __params = __params.set('numLevels', params.numLevels.toString());
    if (params.nodeId != null) __params = __params.set('nodeId', params.nodeId.toString());
    if (params.isSecurityGroup != null) __params = __params.set('isSecurityGroup', params.isSecurityGroup.toString());
    if (params.includeVMFolders != null) __params = __params.set('includeVMFolders', params.includeVMFolders.toString());
    if (params.includeSystemVApps != null) __params = __params.set('includeSystemVApps', params.includeSystemVApps.toString());
    if (params.includeSourceCredentials != null) __params = __params.set('includeSourceCredentials', params.includeSourceCredentials.toString());
    if (params.includeSfdcFields != null) __params = __params.set('includeSfdcFields', params.includeSfdcFields.toString());
    if (params.includeNetworks != null) __params = __params.set('includeNetworks', params.includeNetworks.toString());
    if (params.includeExternalMetadata != null) __params = __params.set('includeExternalMetadata', params.includeExternalMetadata.toString());
    if (params.includeEntityPermissionInfo != null) __params = __params.set('includeEntityPermissionInfo', params.includeEntityPermissionInfo.toString());
    if (params.includeDatastores != null) __params = __params.set('includeDatastores', params.includeDatastores.toString());
    if (params.id != null) __params = __params.set('id', params.id.toString());
    if (params.hasValidOnedrive != null) __params = __params.set('hasValidOnedrive', params.hasValidOnedrive.toString());
    if (params.hasValidMailbox != null) __params = __params.set('hasValidMailbox', params.hasValidMailbox.toString());
    if (params.getTeamsChannels != null) __params = __params.set('getTeamsChannels', params.getTeamsChannels.toString());
    (params.excludeTypes || []).forEach(val => {if (val != null) __params = __params.append('excludeTypes', val.toString())});
    (params.excludeOffice365Types || []).forEach(val => {if (val != null) __params = __params.append('excludeOffice365Types', val.toString())});
    (params.excludeKubernetesTypes || []).forEach(val => {if (val != null) __params = __params.append('excludeKubernetesTypes', val.toString())});
    (params.excludeAzureTypes || []).forEach(val => {if (val != null) __params = __params.append('excludeAzureTypes', val.toString())});
    (params.excludeAwsTypes || []).forEach(val => {if (val != null) __params = __params.append('excludeAwsTypes', val.toString())});
    (params.environments || []).forEach(val => {if (val != null) __params = __params.append('environments', val.toString())});
    if (params.environment != null) __params = __params.set('environment', params.environment.toString());
    if (params.encryptionKey != null) __params = __params.set('encryptionKey', params.encryptionKey.toString());
    if (params.beforeCursorEntityId != null) __params = __params.set('beforeCursorEntityId', params.beforeCursorEntityId.toString());
    if (params.allUnderHierarchy != null) __params = __params.set('allUnderHierarchy', params.allUnderHierarchy.toString());
    if (params.afterCursorEntityId != null) __params = __params.set('afterCursorEntityId', params.afterCursorEntityId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/protectionSources`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ProtectionSourceNode>>;
      })
    );
  }
  /**
   * Returns the registered Protection Sources and their Object subtrees.
   *
   * If no parameters are specified, all Protection Sources that are registered
   * on the Cohesity Cluster are returned.
   * In addition, an Object subtree gathered from each Source is returned.
   * For example, the Cohesity Cluster interrogates a Source VMware vCenter Server
   * and creates an hierarchical Object subtree that mirrors the
   * Inventory tree on vCenter Server.
   * The contents of the Object tree are returned as a "nodes" hierarchy
   * of "protectionSource"s.
   * Specifying parameters can alter the results that are returned.
   * @param params The `ProtectionSourcesService.ListProtectionSourcesParams` containing the following parameters:
   *
   * - `useCachedData`: Specifies whether we can serve the GET request to the read replica cache.
   *   setting this to true ensures that the API request is served
   *   to the read replica. setting this to false will serve the request to
   *   the master.
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which objects are to be
   *   returned.
   *
   * - `sids`: Filter the object subtree for the sids given in the list.
   *
   * - `requestInitiatorType`: Specifies the type of the request. Possible values are UIUser and UIAuto,
   *   which means the request is triggered by user or is an auto refresh
   *   request. Services like magneto will use this to determine the priority
   *   of the requests, so that it can more intelligently handle overload
   *   situations by prioritizing higher priority requests.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `pruneNonCriticalInfo`: Specifies whether to prune non critical info within entities.
   *   Incase of VMs, virtual disk information will be pruned.
   *   Incase of Office365, metadata about user entities will be pruned.
   *   This can be used to limit the size of the response by caller.
   *
   * - `pageSize`: Specifies the maximum number of entities to be returned within the page.
   *
   * - `numLevels`: Specifies the expected number of levels from the root node to be returned
   *   in the entity hierarchy response.
   *
   * - `nodeId`: Specifies the entity id for the Node at any level within the Source entity
   *   hierarchy whose children are to be paginated.
   *
   * - `isSecurityGroup`: If set to true, Groups which are security enabled will be returned.
   *
   * - `includeVMFolders`: Set this parameter to true to also return kVMFolder object types found in
   *   the Source in addition to their Object subtrees.
   *   By default, VM folder objects are not returned.
   *
   * - `includeSystemVApps`: Set this parameter to true to also return system VApp object types found
   *   in the Source in addition to their Object subtrees.
   *   By default, VM folder objects are not returned.
   *
   * - `includeSourceCredentials`: If specified, then crednetial for the registered sources will be included.
   *   Credential is first encrypted with internal key and then reencrypted with
   *   user supplied 'encryption_key'.
   *
   * - `includeSfdcFields`: Set this parameter to true to also return fields of the object found
   *   in the Source in addition to their Object subtrees.
   *   By default, Sfdc object fields are not returned.
   *
   * - `includeNetworks`: Set this parameter to true to also return kNetwork object types found in
   *   the Source in addition to their Object subtrees.
   *   By default, network objects are not returned.
   *
   * - `includeExternalMetadata`: Specifies if entity external metadata should be included within the
   *   response to get entity hierarchy call.
   *
   * - `includeEntityPermissionInfo`: If specified, then a list of entites with permissions assigned to them are
   *   returned.
   *
   * - `includeDatastores`: Set this parameter to true to also return kDatastore object types found in
   *   the Source in addition to their Object subtrees.
   *   By default, datastores are not returned.
   *
   * - `id`: Return the Object subtree for the passed in Protection Source id.
   *
   * - `hasValidOnedrive`: If set to true, only users with valid onedrive will be returned.
   *
   * - `hasValidMailbox`: If set to true, only users with valid mailbox will be returned.
   *
   * - `getTeamsChannels`: Specifies whether to get the list of the channels for a given M365
   *   Teams object. This should be set to true only when a single Id
   *   belonging to M365 Teams object is provided in the query params.
   *
   * - `excludeTypes`: Filter out the Object types (and their subtrees) that match the passed
   *   in types such as 'kVCenter', 'kFolder', 'kDatacenter',
   *   'kComputeResource', 'kResourcePool', 'kDatastore', 'kHostSystem',
   *   'kVirtualMachine', etc.
   *   For example, set this parameter to 'kResourcePool' to
   *   exclude Resource Pool Objects from being returned.
   *
   * - `excludeOffice365Types`: Specifies the Object types to be filtered out for Office 365 that match
   *   the passed in types such as 'kDomain', 'kOutlook', 'kMailbox', etc.
   *   For example, set this parameter to 'kMailbox' to exclude Mailbox Objects
   *   from being returned.
   *
   * - `excludeKubernetesTypes`: Specifies the Object types to be filtered out for Kubernetes that match
   *   the passed in types such as 'kService'.
   *   For example, set this parameter to 'kService' to exclude services
   *   from being returned.
   *
   * - `excludeAzureTypes`: Specifies the Object types to be filtered out for Azure that match the
   *   passed in types such as 'kVirtualMachine', 'kSQLDatabase'.
   *   For example, set this parameter to 'kVirtualMachine' to exclude VM instances
   *   from being returned.
   *
   * - `excludeAwsTypes`: Specifies the Object types to be filtered out for AWS that match the
   *   passed in types such as 'kEC2Instance', 'kRDSInstance', 'kAuroraCluster',
   *   'kTag', 'kAuroraTag', 'kRDSTag', kS3Bucket, kS3Tag.
   *   For example, set this parameter to 'kEC2Instance' to exclude ec2 instance
   *   from being returned.
   *
   * - `environments`: Return only Protection Sources that match the passed in environment type
   *   such as 'kVMware', 'kSQL', 'kView' 'kPhysical', 'kPuppeteer', 'kPure',
   *   'kNetapp', 'kGenericNas', 'kHyperV', 'kAcropolis', or 'kAzure'.
   *   For example, set this parameter to 'kVMware' to only return the Sources
   *   (and their Object subtrees) found in the 'kVMware'
   *   (VMware vCenter Server) environment.
   *
   *   NOTE: 'kPuppeteer' refers to Cohesity's Remote Adapter.
   *
   * - `environment`: This field is deprecated. Use environments instead.
   *   deprecated: true
   *
   * - `encryptionKey`: Key to be used to encrypt the source credential. If
   *   include_source_credentials is set to true this key must be specified.
   *
   * - `beforeCursorEntityId`: Specifies the entity id upto which the items are to be returned.
   *
   * - `allUnderHierarchy`: AllUnderHierarchy specifies if objects of all the tenants under the
   *   hierarchy of the logged in user's organization should be returned.
   *
   * - `afterCursorEntityId`: Specifies the entity id starting from which the items are to be returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  ListProtectionSources(params: ProtectionSourcesService.ListProtectionSourcesParams): __Observable<Array<ProtectionSourceNode>> {
    return this.ListProtectionSourcesResponse(params).pipe(
      __map(_r => _r.body as Array<ProtectionSourceNode>)
    );
  }

  /**
   * Returns the registered Application Servers and their Object subtrees.
   *
   * Given the root node id of a Protection Source tree, returns the list of
   * Application Servers registered under that tree based on the filters.
   * @param params The `ProtectionSourcesService.ListApplicationServersParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `protectionSourcesRootNodeId`: Specifies the Protection Source Id of the root node of a Protection
   *   Sources tree. A root node represents a registered Source on the
   *   Cohesity Cluster, such as a vCenter Server.
   *
   * - `protectionSourceId`: Specifies the Protection Source Id of the 'kPhysical' or 'kVMware'
   *   entity in the Protection Source tree hosting the applications.
   *
   * - `environment`: Specifies the environment such as 'kPhysical' or 'kVMware' of the
   *   Protection Source tree.
   *   overrideDescription: true
   *   Supported environment types such as 'kView', 'kSQL', 'kVMware', etc.
   *   NOTE: 'kPuppeteer' refers to Cohesity's Remote Adapter.
   *   'kVMware' indicates the VMware Protection Source environment.
   *   'kHyperV' indicates the HyperV Protection Source environment.
   *   'kSQL' indicates the SQL Protection Source environment.
   *   'kView' indicates the View Protection Source environment.
   *   'kPuppeteer' indicates the Cohesity's Remote Adapter.
   *   'kPhysical' indicates the physical Protection Source environment.
   *   'kPure' indicates the Pure Storage Protection Source environment.
   *   'Nimble' indicates the Nimble Storage Protection Source environment.
   *   'kAzure' indicates the Microsoft's Azure Protection Source environment.
   *   'kNetapp' indicates the Netapp Protection Source environment.
   *   'kAgent' indicates the Agent Protection Source environment.
   *   'kGenericNas' indicates the Generic Network Attached Storage Protection
   *   Source environment.
   *   'kAcropolis' indicates the Acropolis Protection Source environment.
   *   'kPhsicalFiles' indicates the Physical Files Protection Source environment.
   *   'kIsilon' indicates the Dell EMC's Isilon Protection Source environment.
   *   'kGPFS' indicates IBM's GPFS Protection Source environment.
   *   'kKVM' indicates the KVM Protection Source environment.
   *   'kAWS' indicates the AWS Protection Source environment.
   *   'kExchange' indicates the Exchange Protection Source environment.
   *   'kHyperVVSS' indicates the HyperV VSS Protection Source
   *   environment.
   *   'kOracle' indicates the Oracle Protection Source environment.
   *   'kGCP' indicates the Google Cloud Platform Protection Source environment.
   *   'kFlashBlade' indicates the Flash Blade Protection Source environment.
   *   'kAWSNative' indicates the AWS Native Protection Source environment.
   *   'kO365' indicates the Office 365 Protection Source environment.
   *   'kO365Outlook' indicates Office 365 outlook Protection Source environment.
   *   'kHyperFlex' indicates the Hyper Flex Protection Source environment.
   *   'kGCPNative' indicates the GCP Native Protection Source environment.
   *   'kAzureNative' indicates the Azure Native Protection Source environment.
   *   'kKubernetes' indicates a Kubernetes Protection Source environment.
   *   'kElastifile' indicates Elastifile Protection Source environment.
   *   'kAD' indicates Active Directory Protection Source environment.
   *   'kRDSSnapshotManager' indicates AWS RDS Protection Source environment.
   *   'kCassandra' indicates Cassandra Protection Source environment.
   *   'kMongoDB' indicates MongoDB Protection Source environment.
   *   'kCouchbase' indicates Couchbase Protection Source environment.
   *   'kHdfs' indicates Hdfs Protection Source environment.
   *   'kHive' indicates Hive Protection Source environment.
   *   'kHBase' indicates HBase Protection Source environment.
   *
   * - `application`: Specifies the application such as 'kSQL', 'kExchange' running on the
   *   Protection Source.
   *   overrideDescription: true
   *   Supported environment types such as 'kView', 'kSQL', 'kVMware', etc.
   *   NOTE: 'kPuppeteer' refers to Cohesity's Remote Adapter.
   *   'kVMware' indicates the VMware Protection Source environment.
   *   'kHyperV' indicates the HyperV Protection Source environment.
   *   'kSQL' indicates the SQL Protection Source environment.
   *   'kView' indicates the View Protection Source environment.
   *   'kPuppeteer' indicates the Cohesity's Remote Adapter.
   *   'kPhysical' indicates the physical Protection Source environment.
   *   'kPure' indicates the Pure Storage Protection Source environment.
   *   'Nimble' indicates the Nimble Storage Protection Source environment.
   *   'kAzure' indicates the Microsoft's Azure Protection Source environment.
   *   'kNetapp' indicates the Netapp Protection Source environment.
   *   'kAgent' indicates the Agent Protection Source environment.
   *   'kGenericNas' indicates the Generic Network Attached Storage Protection
   *   Source environment.
   *   'kAcropolis' indicates the Acropolis Protection Source environment.
   *   'kPhsicalFiles' indicates the Physical Files Protection Source environment.
   *   'kIsilon' indicates the Dell EMC's Isilon Protection Source environment.
   *   'kGPFS' indicates IBM's GPFS Protection Source environment.
   *   'kKVM' indicates the KVM Protection Source environment.
   *   'kAWS' indicates the AWS Protection Source environment.
   *   'kExchange' indicates the Exchange Protection Source environment.
   *   'kHyperVVSS' indicates the HyperV VSS Protection Source
   *   environment.
   *   'kOracle' indicates the Oracle Protection Source environment.
   *   'kGCP' indicates the Google Cloud Platform Protection Source environment.
   *   'kFlashBlade' indicates the Flash Blade Protection Source environment.
   *   'kAWSNative' indicates the AWS Native Protection Source environment.
   *   'kO365' indicates the Office 365 Protection Source environment.
   *   'kO365Outlook' indicates Office 365 outlook Protection Source environment.
   *   'kHyperFlex' indicates the Hyper Flex Protection Source environment.
   *   'kGCPNative' indicates the GCP Native Protection Source environment.
   *   'kAzureNative' indicates the Azure Native Protection Source environment.
   *   'kKubernetes' indicates a Kubernetes Protection Source environment.
   *   'kElastifile' indicates Elastifile Protection Source environment.
   *   'kAD' indicates Active Directory Protection Source environment.
   *   'kRDSSnapshotManager' indicates AWS RDS Protection Source environment.
   *   'kCassandra' indicates Cassandra Protection Source environment.
   *   'kMongoDB' indicates MongoDB Protection Source environment.
   *   'kCouchbase' indicates Couchbase Protection Source environment.
   *   'kHdfs' indicates Hdfs Protection Source environment.
   *   'kHive' indicates Hive Protection Source environment.
   *   'kHBase' indicates HBase Protection Source environment.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  ListApplicationServersResponse(params: ProtectionSourcesService.ListApplicationServersParams): __Observable<__StrictHttpResponse<Array<RegisteredApplicationServer>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.protectionSourcesRootNodeId != null) __params = __params.set('protectionSourcesRootNodeId', params.protectionSourcesRootNodeId.toString());
    if (params.protectionSourceId != null) __params = __params.set('protectionSourceId', params.protectionSourceId.toString());
    if (params.environment != null) __params = __params.set('environment', params.environment.toString());
    if (params.application != null) __params = __params.set('application', params.application.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/protectionSources/applicationServers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<RegisteredApplicationServer>>;
      })
    );
  }
  /**
   * Returns the registered Application Servers and their Object subtrees.
   *
   * Given the root node id of a Protection Source tree, returns the list of
   * Application Servers registered under that tree based on the filters.
   * @param params The `ProtectionSourcesService.ListApplicationServersParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `protectionSourcesRootNodeId`: Specifies the Protection Source Id of the root node of a Protection
   *   Sources tree. A root node represents a registered Source on the
   *   Cohesity Cluster, such as a vCenter Server.
   *
   * - `protectionSourceId`: Specifies the Protection Source Id of the 'kPhysical' or 'kVMware'
   *   entity in the Protection Source tree hosting the applications.
   *
   * - `environment`: Specifies the environment such as 'kPhysical' or 'kVMware' of the
   *   Protection Source tree.
   *   overrideDescription: true
   *   Supported environment types such as 'kView', 'kSQL', 'kVMware', etc.
   *   NOTE: 'kPuppeteer' refers to Cohesity's Remote Adapter.
   *   'kVMware' indicates the VMware Protection Source environment.
   *   'kHyperV' indicates the HyperV Protection Source environment.
   *   'kSQL' indicates the SQL Protection Source environment.
   *   'kView' indicates the View Protection Source environment.
   *   'kPuppeteer' indicates the Cohesity's Remote Adapter.
   *   'kPhysical' indicates the physical Protection Source environment.
   *   'kPure' indicates the Pure Storage Protection Source environment.
   *   'Nimble' indicates the Nimble Storage Protection Source environment.
   *   'kAzure' indicates the Microsoft's Azure Protection Source environment.
   *   'kNetapp' indicates the Netapp Protection Source environment.
   *   'kAgent' indicates the Agent Protection Source environment.
   *   'kGenericNas' indicates the Generic Network Attached Storage Protection
   *   Source environment.
   *   'kAcropolis' indicates the Acropolis Protection Source environment.
   *   'kPhsicalFiles' indicates the Physical Files Protection Source environment.
   *   'kIsilon' indicates the Dell EMC's Isilon Protection Source environment.
   *   'kGPFS' indicates IBM's GPFS Protection Source environment.
   *   'kKVM' indicates the KVM Protection Source environment.
   *   'kAWS' indicates the AWS Protection Source environment.
   *   'kExchange' indicates the Exchange Protection Source environment.
   *   'kHyperVVSS' indicates the HyperV VSS Protection Source
   *   environment.
   *   'kOracle' indicates the Oracle Protection Source environment.
   *   'kGCP' indicates the Google Cloud Platform Protection Source environment.
   *   'kFlashBlade' indicates the Flash Blade Protection Source environment.
   *   'kAWSNative' indicates the AWS Native Protection Source environment.
   *   'kO365' indicates the Office 365 Protection Source environment.
   *   'kO365Outlook' indicates Office 365 outlook Protection Source environment.
   *   'kHyperFlex' indicates the Hyper Flex Protection Source environment.
   *   'kGCPNative' indicates the GCP Native Protection Source environment.
   *   'kAzureNative' indicates the Azure Native Protection Source environment.
   *   'kKubernetes' indicates a Kubernetes Protection Source environment.
   *   'kElastifile' indicates Elastifile Protection Source environment.
   *   'kAD' indicates Active Directory Protection Source environment.
   *   'kRDSSnapshotManager' indicates AWS RDS Protection Source environment.
   *   'kCassandra' indicates Cassandra Protection Source environment.
   *   'kMongoDB' indicates MongoDB Protection Source environment.
   *   'kCouchbase' indicates Couchbase Protection Source environment.
   *   'kHdfs' indicates Hdfs Protection Source environment.
   *   'kHive' indicates Hive Protection Source environment.
   *   'kHBase' indicates HBase Protection Source environment.
   *
   * - `application`: Specifies the application such as 'kSQL', 'kExchange' running on the
   *   Protection Source.
   *   overrideDescription: true
   *   Supported environment types such as 'kView', 'kSQL', 'kVMware', etc.
   *   NOTE: 'kPuppeteer' refers to Cohesity's Remote Adapter.
   *   'kVMware' indicates the VMware Protection Source environment.
   *   'kHyperV' indicates the HyperV Protection Source environment.
   *   'kSQL' indicates the SQL Protection Source environment.
   *   'kView' indicates the View Protection Source environment.
   *   'kPuppeteer' indicates the Cohesity's Remote Adapter.
   *   'kPhysical' indicates the physical Protection Source environment.
   *   'kPure' indicates the Pure Storage Protection Source environment.
   *   'Nimble' indicates the Nimble Storage Protection Source environment.
   *   'kAzure' indicates the Microsoft's Azure Protection Source environment.
   *   'kNetapp' indicates the Netapp Protection Source environment.
   *   'kAgent' indicates the Agent Protection Source environment.
   *   'kGenericNas' indicates the Generic Network Attached Storage Protection
   *   Source environment.
   *   'kAcropolis' indicates the Acropolis Protection Source environment.
   *   'kPhsicalFiles' indicates the Physical Files Protection Source environment.
   *   'kIsilon' indicates the Dell EMC's Isilon Protection Source environment.
   *   'kGPFS' indicates IBM's GPFS Protection Source environment.
   *   'kKVM' indicates the KVM Protection Source environment.
   *   'kAWS' indicates the AWS Protection Source environment.
   *   'kExchange' indicates the Exchange Protection Source environment.
   *   'kHyperVVSS' indicates the HyperV VSS Protection Source
   *   environment.
   *   'kOracle' indicates the Oracle Protection Source environment.
   *   'kGCP' indicates the Google Cloud Platform Protection Source environment.
   *   'kFlashBlade' indicates the Flash Blade Protection Source environment.
   *   'kAWSNative' indicates the AWS Native Protection Source environment.
   *   'kO365' indicates the Office 365 Protection Source environment.
   *   'kO365Outlook' indicates Office 365 outlook Protection Source environment.
   *   'kHyperFlex' indicates the Hyper Flex Protection Source environment.
   *   'kGCPNative' indicates the GCP Native Protection Source environment.
   *   'kAzureNative' indicates the Azure Native Protection Source environment.
   *   'kKubernetes' indicates a Kubernetes Protection Source environment.
   *   'kElastifile' indicates Elastifile Protection Source environment.
   *   'kAD' indicates Active Directory Protection Source environment.
   *   'kRDSSnapshotManager' indicates AWS RDS Protection Source environment.
   *   'kCassandra' indicates Cassandra Protection Source environment.
   *   'kMongoDB' indicates MongoDB Protection Source environment.
   *   'kCouchbase' indicates Couchbase Protection Source environment.
   *   'kHdfs' indicates Hdfs Protection Source environment.
   *   'kHive' indicates Hive Protection Source environment.
   *   'kHBase' indicates HBase Protection Source environment.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  ListApplicationServers(params: ProtectionSourcesService.ListApplicationServersParams): __Observable<Array<RegisteredApplicationServer>> {
    return this.ListApplicationServersResponse(params).pipe(
      __map(_r => _r.body as Array<RegisteredApplicationServer>)
    );
  }

  /**
   * Modifies the registration parameters of Application Servers in a Protection
   * Source.
   *
   * Returns the Protection Source whose registration parameters of its
   * Application Servers are modified upon success.
   * @param params The `ProtectionSourcesService.UpdateApplicationServersParams` containing the following parameters:
   *
   * - `body`: Request to modify the Application Servers registration of a Protection
   *   Source.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateApplicationServersResponse(params: ProtectionSourcesService.UpdateApplicationServersParams): __Observable<__StrictHttpResponse<ProtectionSource>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/public/protectionSources/applicationServers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ProtectionSource>;
      })
    );
  }
  /**
   * Modifies the registration parameters of Application Servers in a Protection
   * Source.
   *
   * Returns the Protection Source whose registration parameters of its
   * Application Servers are modified upon success.
   * @param params The `ProtectionSourcesService.UpdateApplicationServersParams` containing the following parameters:
   *
   * - `body`: Request to modify the Application Servers registration of a Protection
   *   Source.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateApplicationServers(params: ProtectionSourcesService.UpdateApplicationServersParams): __Observable<ProtectionSource> {
    return this.UpdateApplicationServersResponse(params).pipe(
      __map(_r => _r.body as ProtectionSource)
    );
  }

  /**
   * Register a Protection Source as running one or more Application Servers like
   * Microsoft SQL servers or Microsoft Exchange servers.
   *
   * Registering Application Servers will help Cohesity Cluster such that any
   * application specific data can be backed up.
   *
   * Returns the Protection Source registered upon success.
   * @param params The `ProtectionSourcesService.RegisterApplicationServersParams` containing the following parameters:
   *
   * - `body`: Request to register Application Servers in a Protection Source.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  RegisterApplicationServersResponse(params: ProtectionSourcesService.RegisterApplicationServersParams): __Observable<__StrictHttpResponse<ProtectionSource>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/protectionSources/applicationServers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ProtectionSource>;
      })
    );
  }
  /**
   * Register a Protection Source as running one or more Application Servers like
   * Microsoft SQL servers or Microsoft Exchange servers.
   *
   * Registering Application Servers will help Cohesity Cluster such that any
   * application specific data can be backed up.
   *
   * Returns the Protection Source registered upon success.
   * @param params The `ProtectionSourcesService.RegisterApplicationServersParams` containing the following parameters:
   *
   * - `body`: Request to register Application Servers in a Protection Source.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  RegisterApplicationServers(params: ProtectionSourcesService.RegisterApplicationServersParams): __Observable<ProtectionSource> {
    return this.RegisterApplicationServersResponse(params).pipe(
      __map(_r => _r.body as ProtectionSource)
    );
  }

  /**
   * Unregister Application Servers like Microsoft SQL servers or Microsoft Exchange
   * servers running on a Protection Source.
   *
   * Unregistering Application Servers will fail if the Protection Source is
   * currently being backed up.
   *
   * Returns the Protection Source whose Application Servers are unregistered upon
   * success.
   * @param params The `ProtectionSourcesService.UnregisterApplicationServersParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the Protection Source to unregister the
   *   Application Servers. If the Protection Source is currently being
   *   backed up, unregister operation will fail.
   *
   * - `body`: Request to register a protection source.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UnregisterApplicationServersResponse(params: ProtectionSourcesService.UnregisterApplicationServersParams): __Observable<__StrictHttpResponse<ProtectionSource>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/public/protectionSources/applicationServers/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ProtectionSource>;
      })
    );
  }
  /**
   * Unregister Application Servers like Microsoft SQL servers or Microsoft Exchange
   * servers running on a Protection Source.
   *
   * Unregistering Application Servers will fail if the Protection Source is
   * currently being backed up.
   *
   * Returns the Protection Source whose Application Servers are unregistered upon
   * success.
   * @param params The `ProtectionSourcesService.UnregisterApplicationServersParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the Protection Source to unregister the
   *   Application Servers. If the Protection Source is currently being
   *   backed up, unregister operation will fail.
   *
   * - `body`: Request to register a protection source.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UnregisterApplicationServers(params: ProtectionSourcesService.UnregisterApplicationServersParams): __Observable<ProtectionSource> {
    return this.UnregisterApplicationServersResponse(params).pipe(
      __map(_r => _r.body as ProtectionSource)
    );
  }

  /**
   * Returns the datastore information in VMware environment.
   * @param params The `ProtectionSourcesService.ListDataStoreInformationParams` containing the following parameters:
   *
   * - `sourceId`: Specifies the id of the virtual machine in vmware environment.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  ListDataStoreInformationResponse(params: ProtectionSourcesService.ListDataStoreInformationParams): __Observable<__StrictHttpResponse<Array<ProtectionSource>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sourceId != null) __params = __params.set('sourceId', params.sourceId.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/protectionSources/datastores`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ProtectionSource>>;
      })
    );
  }
  /**
   * Returns the datastore information in VMware environment.
   * @param params The `ProtectionSourcesService.ListDataStoreInformationParams` containing the following parameters:
   *
   * - `sourceId`: Specifies the id of the virtual machine in vmware environment.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  ListDataStoreInformation(params: ProtectionSourcesService.ListDataStoreInformationParams): __Observable<Array<ProtectionSource>> {
    return this.ListDataStoreInformationResponse(params).pipe(
      __map(_r => _r.body as Array<ProtectionSource>)
    );
  }

  /**
   * Collect diagnostics of the protection source for a host type.
   *
   * If the request is successful, the diagnostics script is triggered on Cohesity
   * agent which generates a tarball containing various diagnostics and uploads it
   * to the Cohesity cluster. Host type could be Linux, Windows.
   * @param params The `ProtectionSourcesService.RunDiagnosticsParams` containing the following parameters:
   *
   * - `id`: Specifies the entity id.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  RunDiagnosticsResponse(params: ProtectionSourcesService.RunDiagnosticsParams): __Observable<__StrictHttpResponse<RunDiagnosticsMessage>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/protectionSources/diagnostics/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RunDiagnosticsMessage>;
      })
    );
  }
  /**
   * Collect diagnostics of the protection source for a host type.
   *
   * If the request is successful, the diagnostics script is triggered on Cohesity
   * agent which generates a tarball containing various diagnostics and uploads it
   * to the Cohesity cluster. Host type could be Linux, Windows.
   * @param params The `ProtectionSourcesService.RunDiagnosticsParams` containing the following parameters:
   *
   * - `id`: Specifies the entity id.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  RunDiagnostics(params: ProtectionSourcesService.RunDiagnosticsParams): __Observable<RunDiagnosticsMessage> {
    return this.RunDiagnosticsResponse(params).pipe(
      __map(_r => _r.body as RunDiagnosticsMessage)
    );
  }

  /**
   * Returns information about all the exchange hosts that belong to an Exchange
   * DAG.
   * @param params The `ProtectionSourcesService.ListExchangeDagHostsParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `protectionSourceId`: Specifies the Protection Source Id of the Exchange DAG source.
   *
   * - `endpoint`: Specifies the endpoint of Exchange DAG or a host which is member of
   *   Exchange DAG or a standalone exchange server.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  ListExchangeDagHostsResponse(params: ProtectionSourcesService.ListExchangeDagHostsParams): __Observable<__StrictHttpResponse<ExchangeDagHostsResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.protectionSourceId != null) __params = __params.set('protectionSourceId', params.protectionSourceId.toString());
    if (params.endpoint != null) __params = __params.set('endpoint', params.endpoint.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/protectionSources/exchangeDagHosts`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ExchangeDagHostsResponse>;
      })
    );
  }
  /**
   * Returns information about all the exchange hosts that belong to an Exchange
   * DAG.
   * @param params The `ProtectionSourcesService.ListExchangeDagHostsParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `protectionSourceId`: Specifies the Protection Source Id of the Exchange DAG source.
   *
   * - `endpoint`: Specifies the endpoint of Exchange DAG or a host which is member of
   *   Exchange DAG or a standalone exchange server.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  ListExchangeDagHosts(params: ProtectionSourcesService.ListExchangeDagHostsParams): __Observable<ExchangeDagHostsResponse> {
    return this.ListExchangeDagHostsResponse(params).pipe(
      __map(_r => _r.body as ExchangeDagHostsResponse)
    );
  }

  /**
   * List details about Protection Source objects.
   *
   * Returns the Protection Source objects corresponding to the specified ids.
   * @param params The `ProtectionSourcesService.GetProtectionSourcesObjectsParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `objectIds`: Specifies the ids of the Protection Source objects to return.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetProtectionSourcesObjectsResponse(params: ProtectionSourcesService.GetProtectionSourcesObjectsParams): __Observable<__StrictHttpResponse<Array<ProtectionSource>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    (params.objectIds || []).forEach(val => {if (val != null) __params = __params.append('objectIds', val.toString())});
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/protectionSources/objects`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ProtectionSource>>;
      })
    );
  }
  /**
   * List details about Protection Source objects.
   *
   * Returns the Protection Source objects corresponding to the specified ids.
   * @param params The `ProtectionSourcesService.GetProtectionSourcesObjectsParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `objectIds`: Specifies the ids of the Protection Source objects to return.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetProtectionSourcesObjects(params: ProtectionSourcesService.GetProtectionSourcesObjectsParams): __Observable<Array<ProtectionSource>> {
    return this.GetProtectionSourcesObjectsResponse(params).pipe(
      __map(_r => _r.body as Array<ProtectionSource>)
    );
  }

  /**
   * Get details about a single Protection Source Object.
   *
   * Returns the Protection Source object corresponding to the specified id.
   * @param params The `ProtectionSourcesService.GetProtectionSourcesObjectByIdParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the Protection Source to return.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetProtectionSourcesObjectByIdResponse(params: ProtectionSourcesService.GetProtectionSourcesObjectByIdParams): __Observable<__StrictHttpResponse<ProtectionSource>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/protectionSources/objects/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ProtectionSource>;
      })
    );
  }
  /**
   * Get details about a single Protection Source Object.
   *
   * Returns the Protection Source object corresponding to the specified id.
   * @param params The `ProtectionSourcesService.GetProtectionSourcesObjectByIdParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the Protection Source to return.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetProtectionSourcesObjectById(params: ProtectionSourcesService.GetProtectionSourcesObjectByIdParams): __Observable<ProtectionSource> {
    return this.GetProtectionSourcesObjectByIdResponse(params).pipe(
      __map(_r => _r.body as ProtectionSource)
    );
  }

  /**
   * Returns the list of protected Objects in a registered Protection Source.
   * @param params The `ProtectionSourcesService.ListProtectedObjectsParams` containing the following parameters:
   *
   * - `id`: Specifies the Id of a registered Protection Source of the type
   *   given in environment.
   *
   * - `environment`: Specifies the environment type of the registered Protection Source
   *   such as 'kVMware', 'kSQL', 'kView' 'kPhysical', 'kPuppeteer', 'kPure',
   *   'kNetapp', 'kGenericNas', 'kHyperV', 'kAcropolis', or 'kAzure'.
   *   For example, set this parameter to 'kVMware' if the registered
   *   Protection Source is of 'kVMware' environment type.
   *   Supported environment types such as 'kView', 'kSQL', 'kVMware', etc.
   *   NOTE: 'kPuppeteer' refers to Cohesity's Remote Adapter.
   *   'kVMware' indicates the VMware Protection Source environment.
   *   'kHyperV' indicates the HyperV Protection Source environment.
   *   'kSQL' indicates the SQL Protection Source environment.
   *   'kView' indicates the View Protection Source environment.
   *   'kPuppeteer' indicates the Cohesity's Remote Adapter.
   *   'kPhysical' indicates the physical Protection Source environment.
   *   'kPure' indicates the Pure Storage Protection Source environment.
   *   'Nimble' indicates the Nimble Storage Protection Source environment.
   *   'kAzure' indicates the Microsoft's Azure Protection Source environment.
   *   'kNetapp' indicates the Netapp Protection Source environment.
   *   'kAgent' indicates the Agent Protection Source environment.
   *   'kGenericNas' indicates the Generic Network Attached Storage Protection
   *   Source environment.
   *   'kAcropolis' indicates the Acropolis Protection Source environment.
   *   'kPhsicalFiles' indicates the Physical Files Protection Source environment.
   *   'kIsilon' indicates the Dell EMC's Isilon Protection Source environment.
   *   'kGPFS' indicates IBM's GPFS Protection Source environment.
   *   'kKVM' indicates the KVM Protection Source environment.
   *   'kAWS' indicates the AWS Protection Source environment.
   *   'kExchange' indicates the Exchange Protection Source environment.
   *   'kHyperVVSS' indicates the HyperV VSS Protection Source
   *   environment.
   *   'kOracle' indicates the Oracle Protection Source environment.
   *   'kGCP' indicates the Google Cloud Platform Protection Source environment.
   *   'kFlashBlade' indicates the Flash Blade Protection Source environment.
   *   'kAWSNative' indicates the AWS Native Protection Source environment.
   *   'kO365' indicates the Office 365 Protection Source environment.
   *   'kO365Outlook' indicates Office 365 outlook Protection Source environment.
   *   'kHyperFlex' indicates the Hyper Flex Protection Source environment.
   *   'kGCPNative' indicates the GCP Native Protection Source environment.
   *   'kAzureNative' indicates the Azure Native Protection Source environment.
   *   'kKubernetes' indicates a Kubernetes Protection Source environment.
   *   'kElastifile' indicates Elastifile Protection Source environment.
   *   'kAD' indicates Active Directory Protection Source environment.
   *   'kRDSSnapshotManager' indicates AWS RDS Protection Source environment.
   *   'kCassandra' indicates Cassandra Protection Source environment.
   *   'kMongoDB' indicates MongoDB Protection Source environment.
   *   'kCouchbase' indicates Couchbase Protection Source environment.
   *   'kHdfs' indicates Hdfs Protection Source environment.
   *   'kHive' indicates Hive Protection Source environment.
   *   'kHBase' indicates HBase Protection Source environment.
   *
   * - `requestInitiatorType`: Specifies the type of the request. Possible values are UIUser and UIAuto,
   *   which means the request is triggered by user or is an auto refresh
   *   request. Services like magneto will use this to determine the priority
   *   of the requests, so that it can more intelligently handle overload
   *   situations by prioritizing higher priority requests.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `includeRpoSnapshots`: If true, then the Protected Objects protected by RPO policies will also
   *   be returned.
   *
   * - `allUnderHierarchy`: AllUnderHierarchy specifies if objects of all the tenants under the
   *   hierarchy of the logged in user's organization should be returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  ListProtectedObjectsResponse(params: ProtectionSourcesService.ListProtectedObjectsParams): __Observable<__StrictHttpResponse<Array<ProtectedVmInfo>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.id != null) __params = __params.set('id', params.id.toString());
    if (params.environment != null) __params = __params.set('environment', params.environment.toString());

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.includeRpoSnapshots != null) __params = __params.set('includeRpoSnapshots', params.includeRpoSnapshots.toString());
    if (params.allUnderHierarchy != null) __params = __params.set('allUnderHierarchy', params.allUnderHierarchy.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/protectionSources/protectedObjects`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ProtectedVmInfo>>;
      })
    );
  }
  /**
   * Returns the list of protected Objects in a registered Protection Source.
   * @param params The `ProtectionSourcesService.ListProtectedObjectsParams` containing the following parameters:
   *
   * - `id`: Specifies the Id of a registered Protection Source of the type
   *   given in environment.
   *
   * - `environment`: Specifies the environment type of the registered Protection Source
   *   such as 'kVMware', 'kSQL', 'kView' 'kPhysical', 'kPuppeteer', 'kPure',
   *   'kNetapp', 'kGenericNas', 'kHyperV', 'kAcropolis', or 'kAzure'.
   *   For example, set this parameter to 'kVMware' if the registered
   *   Protection Source is of 'kVMware' environment type.
   *   Supported environment types such as 'kView', 'kSQL', 'kVMware', etc.
   *   NOTE: 'kPuppeteer' refers to Cohesity's Remote Adapter.
   *   'kVMware' indicates the VMware Protection Source environment.
   *   'kHyperV' indicates the HyperV Protection Source environment.
   *   'kSQL' indicates the SQL Protection Source environment.
   *   'kView' indicates the View Protection Source environment.
   *   'kPuppeteer' indicates the Cohesity's Remote Adapter.
   *   'kPhysical' indicates the physical Protection Source environment.
   *   'kPure' indicates the Pure Storage Protection Source environment.
   *   'Nimble' indicates the Nimble Storage Protection Source environment.
   *   'kAzure' indicates the Microsoft's Azure Protection Source environment.
   *   'kNetapp' indicates the Netapp Protection Source environment.
   *   'kAgent' indicates the Agent Protection Source environment.
   *   'kGenericNas' indicates the Generic Network Attached Storage Protection
   *   Source environment.
   *   'kAcropolis' indicates the Acropolis Protection Source environment.
   *   'kPhsicalFiles' indicates the Physical Files Protection Source environment.
   *   'kIsilon' indicates the Dell EMC's Isilon Protection Source environment.
   *   'kGPFS' indicates IBM's GPFS Protection Source environment.
   *   'kKVM' indicates the KVM Protection Source environment.
   *   'kAWS' indicates the AWS Protection Source environment.
   *   'kExchange' indicates the Exchange Protection Source environment.
   *   'kHyperVVSS' indicates the HyperV VSS Protection Source
   *   environment.
   *   'kOracle' indicates the Oracle Protection Source environment.
   *   'kGCP' indicates the Google Cloud Platform Protection Source environment.
   *   'kFlashBlade' indicates the Flash Blade Protection Source environment.
   *   'kAWSNative' indicates the AWS Native Protection Source environment.
   *   'kO365' indicates the Office 365 Protection Source environment.
   *   'kO365Outlook' indicates Office 365 outlook Protection Source environment.
   *   'kHyperFlex' indicates the Hyper Flex Protection Source environment.
   *   'kGCPNative' indicates the GCP Native Protection Source environment.
   *   'kAzureNative' indicates the Azure Native Protection Source environment.
   *   'kKubernetes' indicates a Kubernetes Protection Source environment.
   *   'kElastifile' indicates Elastifile Protection Source environment.
   *   'kAD' indicates Active Directory Protection Source environment.
   *   'kRDSSnapshotManager' indicates AWS RDS Protection Source environment.
   *   'kCassandra' indicates Cassandra Protection Source environment.
   *   'kMongoDB' indicates MongoDB Protection Source environment.
   *   'kCouchbase' indicates Couchbase Protection Source environment.
   *   'kHdfs' indicates Hdfs Protection Source environment.
   *   'kHive' indicates Hive Protection Source environment.
   *   'kHBase' indicates HBase Protection Source environment.
   *
   * - `requestInitiatorType`: Specifies the type of the request. Possible values are UIUser and UIAuto,
   *   which means the request is triggered by user or is an auto refresh
   *   request. Services like magneto will use this to determine the priority
   *   of the requests, so that it can more intelligently handle overload
   *   situations by prioritizing higher priority requests.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `includeRpoSnapshots`: If true, then the Protected Objects protected by RPO policies will also
   *   be returned.
   *
   * - `allUnderHierarchy`: AllUnderHierarchy specifies if objects of all the tenants under the
   *   hierarchy of the logged in user's organization should be returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  ListProtectedObjects(params: ProtectionSourcesService.ListProtectedObjectsParams): __Observable<Array<ProtectedVmInfo>> {
    return this.ListProtectedObjectsResponse(params).pipe(
      __map(_r => _r.body as Array<ProtectedVmInfo>)
    );
  }

  /**
   * Refresh the Object hierarchy of the Protection Sources tree.
   *
   * Force an immediate refresh between the specified Protection Source tree
   * on the Cohesity Cluster and the Inventory tree
   * in the associated vCenter Server.
   *
   * For example if a new VM is added to the vCenter Server, after a refresh,
   * a new Protection Source node for this VM is added to the Protection Sources
   * tree.
   *
   * Success indicates the forced refresh has been completed. For larger sources it
   * is possible for the operation to timeout before the force refresh has been
   * completed. This timeout can be increased by modifying the
   * 'iris_post_timeout_msecs_to_magneto' gflag on the Iris service.
   * @param params The `ProtectionSourcesService.RefreshProtectionSourceByIdParams` containing the following parameters:
   *
   * - `id`: Id of the root node of the Protection Sources tree to refresh.
   *
   *   Force a refresh of the Object hierarchy for the passed in Protection
   *   Sources Id.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   */
  RefreshProtectionSourceByIdResponse(params: ProtectionSourcesService.RefreshProtectionSourceByIdParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/protectionSources/refresh/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Refresh the Object hierarchy of the Protection Sources tree.
   *
   * Force an immediate refresh between the specified Protection Source tree
   * on the Cohesity Cluster and the Inventory tree
   * in the associated vCenter Server.
   *
   * For example if a new VM is added to the vCenter Server, after a refresh,
   * a new Protection Source node for this VM is added to the Protection Sources
   * tree.
   *
   * Success indicates the forced refresh has been completed. For larger sources it
   * is possible for the operation to timeout before the force refresh has been
   * completed. This timeout can be increased by modifying the
   * 'iris_post_timeout_msecs_to_magneto' gflag on the Iris service.
   * @param params The `ProtectionSourcesService.RefreshProtectionSourceByIdParams` containing the following parameters:
   *
   * - `id`: Id of the root node of the Protection Sources tree to refresh.
   *
   *   Force a refresh of the Object hierarchy for the passed in Protection
   *   Sources Id.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   */
  RefreshProtectionSourceById(params: ProtectionSourcesService.RefreshProtectionSourceByIdParams): __Observable<null> {
    return this.RefreshProtectionSourceByIdResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Register a Protection Source.
   *
   * Register a Protection Source on the Cohesity Cluster.
   * It could be the root node of a vCenter Server or a physical server.
   *
   * Returns the newly registered Protection Source upon success.
   * @param params The `ProtectionSourcesService.RegisterProtectionSourceParams` containing the following parameters:
   *
   * - `body`: Request to register a protection source.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  RegisterProtectionSourceResponse(params: ProtectionSourcesService.RegisterProtectionSourceParams): __Observable<__StrictHttpResponse<ProtectionSource>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/protectionSources/register`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ProtectionSource>;
      })
    );
  }
  /**
   * Register a Protection Source.
   *
   * Register a Protection Source on the Cohesity Cluster.
   * It could be the root node of a vCenter Server or a physical server.
   *
   * Returns the newly registered Protection Source upon success.
   * @param params The `ProtectionSourcesService.RegisterProtectionSourceParams` containing the following parameters:
   *
   * - `body`: Request to register a protection source.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  RegisterProtectionSource(params: ProtectionSourcesService.RegisterProtectionSourceParams): __Observable<ProtectionSource> {
    return this.RegisterProtectionSourceResponse(params).pipe(
      __map(_r => _r.body as ProtectionSource)
    );
  }

  /**
   * Returns the registration and protection information of the registered
   * Protection Sources.
   * @param params The `ProtectionSourcesService.ListProtectionSourcesRegistrationInfoParams` containing the following parameters:
   *
   * - `useCachedData`: Specifies whether we can serve the GET request to the aux master.
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which objects are to be
   *   returned.
   *
   * - `sids`: Filter the registered root nodes for the sids given in the list.
   *
   * - `requestInitiatorType`: Specifies the type of the request. Possible values are UIUser and UIAuto,
   *   which means the request is triggered by user or is an auto refresh
   *   request. Services like magneto will use this to determine the priority
   *   of the requests, so that it can more intelligently handle overload
   *   situations by prioritizing higher priority requests.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `pruneNonCriticalInfo`: Specifies whether to prune non critical info within entities.
   *   Incase of VMs, virtual disk information will be pruned.
   *   Incase of Office365, metadata about user entities will be pruned.
   *   This can be used to limit the size of the response by caller.
   *
   * - `maintenanceStatus`: Specifies the maintenance status of a source
   *   'UnderMaintenance' indicates the source is currently under maintenance.
   *   'ScheduledMaintenance' indicates the source is scheduled for maintenance.
   *   'NotConfigured' indicates maintenance is not configured on the source.
   *
   * - `includeSourceCredentials`: If specified, then crednetial for the registered sources will be included.
   *   Credential is first encrypted with internal key and then reencrypted with
   *   user supplied 'encryption_key'.
   *
   * - `includeExternalMetadata`: Specifies if entity external metadata should be included within the
   *   response to get entity hierarchy call.
   *
   * - `includeEntityPermissionInfo`: If specified, then a list of entities with permissions assigned to them are
   *   returned.
   *
   * - `includeApplicationsTreeInfo`: Specifies whether to return applications tree info or not.
   *
   * - `ids`: Return only the registered root nodes whose Ids are given in the list.
   *
   * - `environments`: Return only Protection Sources that match the passed in environment type
   *   such as 'kVMware', 'kSQL', 'kView' 'kPhysical', 'kPuppeteer', 'kPure',
   *   'kNetapp', 'kGenericNas', 'kHyperV', 'kAcropolis', or 'kAzure'.
   *   For example, set this parameter to 'kVMware' to only return the Sources
   *   (and their Object subtrees) found in the 'kVMware'
   *   (VMware vCenter Server) environment.
   *
   *   NOTE: 'kPuppeteer' refers to Cohesity's Remote Adapter.
   *
   * - `encryptionKey`: Key to be used to encrypt the source credential. If
   *   include_source_credentials is set to true this key must be specified.
   *
   * - `allUnderHierarchy`: AllUnderHierarchy specifies if objects of all the tenants under the
   *   hierarchy of the logged in user's organization should be returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  ListProtectionSourcesRegistrationInfoResponse(params: ProtectionSourcesService.ListProtectionSourcesRegistrationInfoParams): __Observable<__StrictHttpResponse<GetRegistrationInfoResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.useCachedData != null) __params = __params.set('useCachedData', params.useCachedData.toString());
    (params.tenantIds || []).forEach(val => {if (val != null) __params = __params.append('tenantIds', val.toString())});
    (params.sids || []).forEach(val => {if (val != null) __params = __params.append('sids', val.toString())});
    if (params.requestInitiatorType != null) __headers = __headers.set('requestInitiatorType', params.requestInitiatorType.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.pruneNonCriticalInfo != null) __params = __params.set('pruneNonCriticalInfo', params.pruneNonCriticalInfo.toString());
    if (params.maintenanceStatus != null) __params = __params.set('maintenanceStatus', params.maintenanceStatus.toString());
    if (params.includeSourceCredentials != null) __params = __params.set('includeSourceCredentials', params.includeSourceCredentials.toString());
    if (params.includeExternalMetadata != null) __params = __params.set('includeExternalMetadata', params.includeExternalMetadata.toString());
    if (params.includeEntityPermissionInfo != null) __params = __params.set('includeEntityPermissionInfo', params.includeEntityPermissionInfo.toString());
    if (params.includeApplicationsTreeInfo != null) __params = __params.set('includeApplicationsTreeInfo', params.includeApplicationsTreeInfo.toString());
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    (params.environments || []).forEach(val => {if (val != null) __params = __params.append('environments', val.toString())});
    if (params.encryptionKey != null) __params = __params.set('encryptionKey', params.encryptionKey.toString());
    if (params.allUnderHierarchy != null) __params = __params.set('allUnderHierarchy', params.allUnderHierarchy.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/protectionSources/registrationInfo`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetRegistrationInfoResponse>;
      })
    );
  }
  /**
   * Returns the registration and protection information of the registered
   * Protection Sources.
   * @param params The `ProtectionSourcesService.ListProtectionSourcesRegistrationInfoParams` containing the following parameters:
   *
   * - `useCachedData`: Specifies whether we can serve the GET request to the aux master.
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which objects are to be
   *   returned.
   *
   * - `sids`: Filter the registered root nodes for the sids given in the list.
   *
   * - `requestInitiatorType`: Specifies the type of the request. Possible values are UIUser and UIAuto,
   *   which means the request is triggered by user or is an auto refresh
   *   request. Services like magneto will use this to determine the priority
   *   of the requests, so that it can more intelligently handle overload
   *   situations by prioritizing higher priority requests.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `pruneNonCriticalInfo`: Specifies whether to prune non critical info within entities.
   *   Incase of VMs, virtual disk information will be pruned.
   *   Incase of Office365, metadata about user entities will be pruned.
   *   This can be used to limit the size of the response by caller.
   *
   * - `maintenanceStatus`: Specifies the maintenance status of a source
   *   'UnderMaintenance' indicates the source is currently under maintenance.
   *   'ScheduledMaintenance' indicates the source is scheduled for maintenance.
   *   'NotConfigured' indicates maintenance is not configured on the source.
   *
   * - `includeSourceCredentials`: If specified, then crednetial for the registered sources will be included.
   *   Credential is first encrypted with internal key and then reencrypted with
   *   user supplied 'encryption_key'.
   *
   * - `includeExternalMetadata`: Specifies if entity external metadata should be included within the
   *   response to get entity hierarchy call.
   *
   * - `includeEntityPermissionInfo`: If specified, then a list of entities with permissions assigned to them are
   *   returned.
   *
   * - `includeApplicationsTreeInfo`: Specifies whether to return applications tree info or not.
   *
   * - `ids`: Return only the registered root nodes whose Ids are given in the list.
   *
   * - `environments`: Return only Protection Sources that match the passed in environment type
   *   such as 'kVMware', 'kSQL', 'kView' 'kPhysical', 'kPuppeteer', 'kPure',
   *   'kNetapp', 'kGenericNas', 'kHyperV', 'kAcropolis', or 'kAzure'.
   *   For example, set this parameter to 'kVMware' to only return the Sources
   *   (and their Object subtrees) found in the 'kVMware'
   *   (VMware vCenter Server) environment.
   *
   *   NOTE: 'kPuppeteer' refers to Cohesity's Remote Adapter.
   *
   * - `encryptionKey`: Key to be used to encrypt the source credential. If
   *   include_source_credentials is set to true this key must be specified.
   *
   * - `allUnderHierarchy`: AllUnderHierarchy specifies if objects of all the tenants under the
   *   hierarchy of the logged in user's organization should be returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  ListProtectionSourcesRegistrationInfo(params: ProtectionSourcesService.ListProtectionSourcesRegistrationInfoParams): __Observable<GetRegistrationInfoResponse> {
    return this.ListProtectionSourcesRegistrationInfoResponse(params).pipe(
      __map(_r => _r.body as GetRegistrationInfoResponse)
    );
  }

  /**
   * Returns the top level (root) Protection Sources with registration information.
   *
   * Returns the root Protection Sources and the registration information for
   * each of these Sources.
   * @param params The `ProtectionSourcesService.ListProtectionSourcesRootNodesParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `id`: Return the registration information for the Protection Source id.
   *
   * - `environments`: Return only the root Protection Sources that match the passed in
   *   environment type such as 'kVMware', 'kSQL', 'kView', 'kPuppeteer',
   *   'kPhysical', 'kPure', 'kNetapp', 'kGenericNas', 'kHyperV', 'kAcropolis'
   *   'kAzure'.
   *   For example, set this parameter to 'kVMware' to
   *   only return the root Protection Sources found in the 'kVMware'
   *   (VMware vCenter) environment. In addition, the registration
   *   information for each Source is returned.
   *
   *   NOTE: 'kPuppeteer' refers to Cohesity's Remote Adapter.
   *
   * - `environment`: This field is deprecated. Use environments instead.
   *   deprecated: true
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  ListProtectionSourcesRootNodesResponse(params: ProtectionSourcesService.ListProtectionSourcesRootNodesParams): __Observable<__StrictHttpResponse<Array<ProtectionSourceNode>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.id != null) __params = __params.set('id', params.id.toString());
    (params.environments || []).forEach(val => {if (val != null) __params = __params.append('environments', val.toString())});
    if (params.environment != null) __params = __params.set('environment', params.environment.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/protectionSources/rootNodes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ProtectionSourceNode>>;
      })
    );
  }
  /**
   * Returns the top level (root) Protection Sources with registration information.
   *
   * Returns the root Protection Sources and the registration information for
   * each of these Sources.
   * @param params The `ProtectionSourcesService.ListProtectionSourcesRootNodesParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `id`: Return the registration information for the Protection Source id.
   *
   * - `environments`: Return only the root Protection Sources that match the passed in
   *   environment type such as 'kVMware', 'kSQL', 'kView', 'kPuppeteer',
   *   'kPhysical', 'kPure', 'kNetapp', 'kGenericNas', 'kHyperV', 'kAcropolis'
   *   'kAzure'.
   *   For example, set this parameter to 'kVMware' to
   *   only return the root Protection Sources found in the 'kVMware'
   *   (VMware vCenter) environment. In addition, the registration
   *   information for each Source is returned.
   *
   *   NOTE: 'kPuppeteer' refers to Cohesity's Remote Adapter.
   *
   * - `environment`: This field is deprecated. Use environments instead.
   *   deprecated: true
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  ListProtectionSourcesRootNodes(params: ProtectionSourcesService.ListProtectionSourcesRootNodesParams): __Observable<Array<ProtectionSourceNode>> {
    return this.ListProtectionSourcesRootNodesResponse(params).pipe(
      __map(_r => _r.body as Array<ProtectionSourceNode>)
    );
  }

  /**
   * Returns the registered Protection Sources and their Object subtrees.
   *
   * Given a list of Protection Source Ids registered as SQL servers, returns
   * AAGs found and the databases in AAG(Always on Availablity Group).
   * @param params The `ProtectionSourcesService.ListSqlAagHostsAndDatabasesParams` containing the following parameters:
   *
   * - `sqlProtectionSourceIds`: Specifies a list of Ids of Protection Sources registered as SQL servers.
   *   These sources may have one or more SQL databases in them. Some of them
   *   may be part of AAGs(Always on Availability Group).
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return List SQL AAG hosts and databases response.
   * Success
   */
  ListSqlAagHostsAndDatabasesResponse(params: ProtectionSourcesService.ListSqlAagHostsAndDatabasesParams): __Observable<__StrictHttpResponse<Array<SqlAagHostAndDatabases>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.sqlProtectionSourceIds || []).forEach(val => {if (val != null) __params = __params.append('sqlProtectionSourceIds', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/protectionSources/sqlAagHostsAndDatabases`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<SqlAagHostAndDatabases>>;
      })
    );
  }
  /**
   * Returns the registered Protection Sources and their Object subtrees.
   *
   * Given a list of Protection Source Ids registered as SQL servers, returns
   * AAGs found and the databases in AAG(Always on Availablity Group).
   * @param params The `ProtectionSourcesService.ListSqlAagHostsAndDatabasesParams` containing the following parameters:
   *
   * - `sqlProtectionSourceIds`: Specifies a list of Ids of Protection Sources registered as SQL servers.
   *   These sources may have one or more SQL databases in them. Some of them
   *   may be part of AAGs(Always on Availability Group).
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return List SQL AAG hosts and databases response.
   * Success
   */
  ListSqlAagHostsAndDatabases(params: ProtectionSourcesService.ListSqlAagHostsAndDatabasesParams): __Observable<Array<SqlAagHostAndDatabases>> {
    return this.ListSqlAagHostsAndDatabasesResponse(params).pipe(
      __map(_r => _r.body as Array<SqlAagHostAndDatabases>)
    );
  }

  /**
   * Returns the Virtual Machines in a vCenter Server.
   *
   * Returns all Virtual Machines found in all the vCenter Servers registered
   * on the Cohesity Cluster that match the filter criteria specified using
   * parameters.
   * If an id is specified, only VMs found in the specified vCenter Server
   * are returned.
   * Only VM Objects are returned.
   * Other VMware Objects such as datacenters are not returned.
   * @param params The `ProtectionSourcesService.ListVirtualMachinesParams` containing the following parameters:
   *
   * - `vCenterId`: Limit the VMs returned to the set of VMs found in a specific
   *   vCenter Server.
   *   Pass in the root Protection Source id for the vCenter Server
   *   to search for VMs.
   *
   * - `uuids`: Limit the returned VMs to those that exactly match the passed in UUIDs.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `pruneNonCriticalInfo`: Specifies whether to prune non-critical info within entities.
   *   In case of VMs, virtual disk information will be pruned.
   *
   * - `protected`: Limit the returned VMs to those that have been protected
   *   by a Protection Job.
   *   By default, both protected and unprotected VMs are returned.
   *
   * - `names`: Limit the returned VMs to those that exactly match the passed in VM name.
   *   To match multiple VM names, specify multiple "names" parameters that each
   *   specify a single VM name.
   *   The string must exactly match the passed in VM name and wild cards are
   *   not supported.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  ListVirtualMachinesResponse(params: ProtectionSourcesService.ListVirtualMachinesParams): __Observable<__StrictHttpResponse<Array<ProtectionSource>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.vCenterId != null) __params = __params.set('vCenterId', params.vCenterId.toString());
    (params.uuids || []).forEach(val => {if (val != null) __params = __params.append('uuids', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.pruneNonCriticalInfo != null) __params = __params.set('pruneNonCriticalInfo', params.pruneNonCriticalInfo.toString());
    if (params.protected != null) __params = __params.set('protected', params.protected.toString());
    (params.names || []).forEach(val => {if (val != null) __params = __params.append('names', val.toString())});
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/protectionSources/virtualMachines`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ProtectionSource>>;
      })
    );
  }
  /**
   * Returns the Virtual Machines in a vCenter Server.
   *
   * Returns all Virtual Machines found in all the vCenter Servers registered
   * on the Cohesity Cluster that match the filter criteria specified using
   * parameters.
   * If an id is specified, only VMs found in the specified vCenter Server
   * are returned.
   * Only VM Objects are returned.
   * Other VMware Objects such as datacenters are not returned.
   * @param params The `ProtectionSourcesService.ListVirtualMachinesParams` containing the following parameters:
   *
   * - `vCenterId`: Limit the VMs returned to the set of VMs found in a specific
   *   vCenter Server.
   *   Pass in the root Protection Source id for the vCenter Server
   *   to search for VMs.
   *
   * - `uuids`: Limit the returned VMs to those that exactly match the passed in UUIDs.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `pruneNonCriticalInfo`: Specifies whether to prune non-critical info within entities.
   *   In case of VMs, virtual disk information will be pruned.
   *
   * - `protected`: Limit the returned VMs to those that have been protected
   *   by a Protection Job.
   *   By default, both protected and unprotected VMs are returned.
   *
   * - `names`: Limit the returned VMs to those that exactly match the passed in VM name.
   *   To match multiple VM names, specify multiple "names" parameters that each
   *   specify a single VM name.
   *   The string must exactly match the passed in VM name and wild cards are
   *   not supported.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  ListVirtualMachines(params: ProtectionSourcesService.ListVirtualMachinesParams): __Observable<Array<ProtectionSource>> {
    return this.ListVirtualMachinesResponse(params).pipe(
      __map(_r => _r.body as Array<ProtectionSource>)
    );
  }

  /**
   * Unregister a previously registered Protection Source.
   * @param params The `ProtectionSourcesService.UnregisterProtectionSourceParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the Protection Source to unregister.
   *   If the Protection Source is currently being backed up, unregister
   *   operation will fail.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   */
  UnregisterProtectionSourceResponse(params: ProtectionSourcesService.UnregisterProtectionSourceParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/public/protectionSources/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Unregister a previously registered Protection Source.
   * @param params The `ProtectionSourcesService.UnregisterProtectionSourceParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the Protection Source to unregister.
   *   If the Protection Source is currently being backed up, unregister
   *   operation will fail.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   */
  UnregisterProtectionSource(params: ProtectionSourcesService.UnregisterProtectionSourceParams): __Observable<null> {
    return this.UnregisterProtectionSourceResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Update a previously registered Protection Source with new details.
   * @param params The `ProtectionSourcesService.UpdateProtectionSourceParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the Protection Source to update.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`: Request to update protection source.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateProtectionSourceResponse(params: ProtectionSourcesService.UpdateProtectionSourceParams): __Observable<__StrictHttpResponse<ProtectionSourceNode>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    __body = params.body;
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/public/protectionSources/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ProtectionSourceNode>;
      })
    );
  }
  /**
   * Update a previously registered Protection Source with new details.
   * @param params The `ProtectionSourcesService.UpdateProtectionSourceParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the Protection Source to update.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`: Request to update protection source.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateProtectionSource(params: ProtectionSourcesService.UpdateProtectionSourceParams): __Observable<ProtectionSourceNode> {
    return this.UpdateProtectionSourceResponse(params).pipe(
      __map(_r => _r.body as ProtectionSourceNode)
    );
  }
}

module ProtectionSourcesService {

  /**
   * Parameters for DownloadPhysicalAgent
   */
  export interface DownloadPhysicalAgentParams {

    /**
     * Specifies the host type for which user wants to download the
     * physical agent.
     * 'kLinux' indicates the Linux operating system.
     * 'kWindows' indicates the Microsoft Windows operating system.
     * 'kAix' indicates the IBM AIX operating system.
     * 'kSolaris' indicates the Oracle Solaris operating system.
     * 'kSapHana' indicates the Sap Hana database system developed by SAP SE.
     * 'kOther' indicates the other types of operating system.
     */
    hostType: 'kLinux' | 'kWindows' | 'kAix' | 'kSolaris' | 'kSapHana' | 'kOther';

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Specifies the Linux installer package type applicable only to Linux
     * OS and the value can be any of ("kScript","kRPM", "kSuseRPM", "kDEB")
     * 'kScript' indicates a script based agent installer.
     * 'kRPM' indicates a RPM agent installer.
     * 'kSuseRPM' indicates a Open Suse RPM installer.
     * 'kDEB' indicates a Debian agent installer.
     */
    pkgType?: 'kScript' | 'kRPM' | 'kSuseRPM' | 'kDEB';

    /**
     * Specifies agent type. Can be "kGo" for go agent and "kJava"
     * for java agent and "kCpp" for c++ agent.
     * 'kCpp' indicates a c++ agent.
     * 'kJava' indicates a java agent.
     * 'kGo' indicates a go agent.
     */
    agentType?: 'kCpp' | 'kJava' | 'kGo';

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpgradePhysicalAgents
   */
  export interface UpgradePhysicalAgentsParams {

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Request to upgrade agents on Physical Servers.
     */
    body?: UpgradePhysicalServerAgents;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for ListProtectionSources
   */
  export interface ListProtectionSourcesParams {

    /**
     * Specifies whether we can serve the GET request to the read replica cache.
     * setting this to true ensures that the API request is served
     * to the read replica. setting this to false will serve the request to
     * the master.
     */
    useCachedData?: boolean;

    /**
     * TenantIds contains ids of the tenants for which objects are to be
     * returned.
     */
    tenantIds?: Array<string>;

    /**
     * Filter the object subtree for the sids given in the list.
     */
    sids?: Array<string>;

    /**
     * Specifies the type of the request. Possible values are UIUser and UIAuto,
     * which means the request is triggered by user or is an auto refresh
     * request. Services like magneto will use this to determine the priority
     * of the requests, so that it can more intelligently handle overload
     * situations by prioritizing higher priority requests.
     */
    requestInitiatorType?: string;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Specifies whether to prune non critical info within entities.
     * Incase of VMs, virtual disk information will be pruned.
     * Incase of Office365, metadata about user entities will be pruned.
     * This can be used to limit the size of the response by caller.
     */
    pruneNonCriticalInfo?: boolean;

    /**
     * Specifies the maximum number of entities to be returned within the page.
     */
    pageSize?: number;

    /**
     * Specifies the expected number of levels from the root node to be returned
     * in the entity hierarchy response.
     */
    numLevels?: number;

    /**
     * Specifies the entity id for the Node at any level within the Source entity
     * hierarchy whose children are to be paginated.
     */
    nodeId?: number;

    /**
     * If set to true, Groups which are security enabled will be returned.
     */
    isSecurityGroup?: boolean;

    /**
     * Set this parameter to true to also return kVMFolder object types found in
     * the Source in addition to their Object subtrees.
     * By default, VM folder objects are not returned.
     */
    includeVMFolders?: boolean;

    /**
     * Set this parameter to true to also return system VApp object types found
     * in the Source in addition to their Object subtrees.
     * By default, VM folder objects are not returned.
     */
    includeSystemVApps?: boolean;

    /**
     * If specified, then crednetial for the registered sources will be included.
     * Credential is first encrypted with internal key and then reencrypted with
     * user supplied 'encryption_key'.
     */
    includeSourceCredentials?: boolean;

    /**
     * Set this parameter to true to also return fields of the object found
     * in the Source in addition to their Object subtrees.
     * By default, Sfdc object fields are not returned.
     */
    includeSfdcFields?: boolean;

    /**
     * Set this parameter to true to also return kNetwork object types found in
     * the Source in addition to their Object subtrees.
     * By default, network objects are not returned.
     */
    includeNetworks?: boolean;

    /**
     * Specifies if entity external metadata should be included within the
     * response to get entity hierarchy call.
     */
    includeExternalMetadata?: boolean;

    /**
     * If specified, then a list of entites with permissions assigned to them are
     * returned.
     */
    includeEntityPermissionInfo?: boolean;

    /**
     * Set this parameter to true to also return kDatastore object types found in
     * the Source in addition to their Object subtrees.
     * By default, datastores are not returned.
     */
    includeDatastores?: boolean;

    /**
     * Return the Object subtree for the passed in Protection Source id.
     */
    id?: number;

    /**
     * If set to true, only users with valid onedrive will be returned.
     */
    hasValidOnedrive?: boolean;

    /**
     * If set to true, only users with valid mailbox will be returned.
     */
    hasValidMailbox?: boolean;

    /**
     * Specifies whether to get the list of the channels for a given M365
     * Teams object. This should be set to true only when a single Id
     * belonging to M365 Teams object is provided in the query params.
     */
    getTeamsChannels?: boolean;

    /**
     * Filter out the Object types (and their subtrees) that match the passed
     * in types such as 'kVCenter', 'kFolder', 'kDatacenter',
     * 'kComputeResource', 'kResourcePool', 'kDatastore', 'kHostSystem',
     * 'kVirtualMachine', etc.
     * For example, set this parameter to 'kResourcePool' to
     * exclude Resource Pool Objects from being returned.
     */
    excludeTypes?: Array<'kVCenter' | 'kFolder' | 'kDatacenter' | 'kComputeResource' | 'kClusterComputeResource' | 'kResourcePool' | 'kDatastore' | 'kHostSystem' | 'kVirtualMachine' | 'kVirtualApp' | 'kStandaloneHost' | 'kStoragePod' | 'kNetwork' | 'kDistributedVirtualPortgroup' | 'kTagCategory' | 'kTag'>;

    /**
     * Specifies the Object types to be filtered out for Office 365 that match
     * the passed in types such as 'kDomain', 'kOutlook', 'kMailbox', etc.
     * For example, set this parameter to 'kMailbox' to exclude Mailbox Objects
     * from being returned.
     */
    excludeOffice365Types?: Array<'kDomain' | 'kOutlook' | 'kMailbox' | 'kUsers' | 'kUser' | 'kGroups' | 'kGroup' | 'kSites' | 'kSite' | 'kPublicFolders' | 'kPublicFolder' | 'kTeams' | 'kTeam'>;

    /**
     * Specifies the Object types to be filtered out for Kubernetes that match
     * the passed in types such as 'kService'.
     * For example, set this parameter to 'kService' to exclude services
     * from being returned.
     */
    excludeKubernetesTypes?: Array<'kService'>;

    /**
     * Specifies the Object types to be filtered out for Azure that match the
     * passed in types such as 'kVirtualMachine', 'kSQLDatabase'.
     * For example, set this parameter to 'kVirtualMachine' to exclude VM instances
     * from being returned.
     */
    excludeAzureTypes?: Array<'kVirtualMachine' | 'kSQLDatabase' | 'kSQLServer' | 'kSQLManagedInstance'>;

    /**
     * Specifies the Object types to be filtered out for AWS that match the
     * passed in types such as 'kEC2Instance', 'kRDSInstance', 'kAuroraCluster',
     * 'kTag', 'kAuroraTag', 'kRDSTag', kS3Bucket, kS3Tag.
     * For example, set this parameter to 'kEC2Instance' to exclude ec2 instance
     * from being returned.
     */
    excludeAwsTypes?: Array<'kEC2Instance' | 'kRDSInstance' | 'kAuroraCluster' | 'kS3Bucket' | 'kTag' | 'kRDSTag' | 'kAuroraTag' | 'kS3Tag' | 'kDynamoDBTable'>;

    /**
     * Return only Protection Sources that match the passed in environment type
     * such as 'kVMware', 'kSQL', 'kView' 'kPhysical', 'kPuppeteer', 'kPure',
     * 'kNetapp', 'kGenericNas', 'kHyperV', 'kAcropolis', or 'kAzure'.
     * For example, set this parameter to 'kVMware' to only return the Sources
     * (and their Object subtrees) found in the 'kVMware'
     * (VMware vCenter Server) environment.
     *
     * NOTE: 'kPuppeteer' refers to Cohesity's Remote Adapter.
     */
    environments?: Array<'kVMware' | 'kHyperV' | 'kSQL' | 'kView' | 'kPuppeteer' | 'kPhysical' | 'kPure' | 'kNimble' | 'kAzure' | 'kIbmFlashSystem' | 'kNetapp' | 'kAgent' | 'kGenericNas' | 'kAcropolis' | 'kPhysicalFiles' | 'kIsilon' | 'kGPFS' | 'kKVM' | 'kAWS' | 'kExchange' | 'kHyperVVSS' | 'kOracle' | 'kGCP' | 'kFlashBlade' | 'kAWSNative' | 'kO365' | 'kO365Outlook' | 'kHyperFlex' | 'kGCPNative' | 'kAzureNative' | 'kKubernetes' | 'kElastifile' | 'kAD' | 'kRDSSnapshotManager' | 'kCassandra' | 'kMongoDB' | 'kCouchbase' | 'kHdfs' | 'kHive' | 'kHBase' | 'kUDA' | 'kSfdc' | 'kSAPHANA'>;

    /**
     * This field is deprecated. Use environments instead.
     * deprecated: true
     */
    environment?: string;

    /**
     * Key to be used to encrypt the source credential. If
     * include_source_credentials is set to true this key must be specified.
     */
    encryptionKey?: string;

    /**
     * Specifies the entity id upto which the items are to be returned.
     */
    beforeCursorEntityId?: number;

    /**
     * AllUnderHierarchy specifies if objects of all the tenants under the
     * hierarchy of the logged in user's organization should be returned.
     */
    allUnderHierarchy?: boolean;

    /**
     * Specifies the entity id starting from which the items are to be returned.
     */
    afterCursorEntityId?: number;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for ListApplicationServers
   */
  export interface ListApplicationServersParams {

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Specifies the Protection Source Id of the root node of a Protection
     * Sources tree. A root node represents a registered Source on the
     * Cohesity Cluster, such as a vCenter Server.
     */
    protectionSourcesRootNodeId?: number;

    /**
     * Specifies the Protection Source Id of the 'kPhysical' or 'kVMware'
     * entity in the Protection Source tree hosting the applications.
     */
    protectionSourceId?: number;

    /**
     * Specifies the environment such as 'kPhysical' or 'kVMware' of the
     * Protection Source tree.
     * overrideDescription: true
     * Supported environment types such as 'kView', 'kSQL', 'kVMware', etc.
     * NOTE: 'kPuppeteer' refers to Cohesity's Remote Adapter.
     * 'kVMware' indicates the VMware Protection Source environment.
     * 'kHyperV' indicates the HyperV Protection Source environment.
     * 'kSQL' indicates the SQL Protection Source environment.
     * 'kView' indicates the View Protection Source environment.
     * 'kPuppeteer' indicates the Cohesity's Remote Adapter.
     * 'kPhysical' indicates the physical Protection Source environment.
     * 'kPure' indicates the Pure Storage Protection Source environment.
     * 'Nimble' indicates the Nimble Storage Protection Source environment.
     * 'kAzure' indicates the Microsoft's Azure Protection Source environment.
     * 'kNetapp' indicates the Netapp Protection Source environment.
     * 'kAgent' indicates the Agent Protection Source environment.
     * 'kGenericNas' indicates the Generic Network Attached Storage Protection
     * Source environment.
     * 'kAcropolis' indicates the Acropolis Protection Source environment.
     * 'kPhsicalFiles' indicates the Physical Files Protection Source environment.
     * 'kIsilon' indicates the Dell EMC's Isilon Protection Source environment.
     * 'kGPFS' indicates IBM's GPFS Protection Source environment.
     * 'kKVM' indicates the KVM Protection Source environment.
     * 'kAWS' indicates the AWS Protection Source environment.
     * 'kExchange' indicates the Exchange Protection Source environment.
     * 'kHyperVVSS' indicates the HyperV VSS Protection Source
     * environment.
     * 'kOracle' indicates the Oracle Protection Source environment.
     * 'kGCP' indicates the Google Cloud Platform Protection Source environment.
     * 'kFlashBlade' indicates the Flash Blade Protection Source environment.
     * 'kAWSNative' indicates the AWS Native Protection Source environment.
     * 'kO365' indicates the Office 365 Protection Source environment.
     * 'kO365Outlook' indicates Office 365 outlook Protection Source environment.
     * 'kHyperFlex' indicates the Hyper Flex Protection Source environment.
     * 'kGCPNative' indicates the GCP Native Protection Source environment.
     * 'kAzureNative' indicates the Azure Native Protection Source environment.
     * 'kKubernetes' indicates a Kubernetes Protection Source environment.
     * 'kElastifile' indicates Elastifile Protection Source environment.
     * 'kAD' indicates Active Directory Protection Source environment.
     * 'kRDSSnapshotManager' indicates AWS RDS Protection Source environment.
     * 'kCassandra' indicates Cassandra Protection Source environment.
     * 'kMongoDB' indicates MongoDB Protection Source environment.
     * 'kCouchbase' indicates Couchbase Protection Source environment.
     * 'kHdfs' indicates Hdfs Protection Source environment.
     * 'kHive' indicates Hive Protection Source environment.
     * 'kHBase' indicates HBase Protection Source environment.
     */
    environment?: 'kVMware' | 'kHyperV' | 'kSQL' | 'kView' | 'kPuppeteer' | 'kPhysical' | 'kPure' | 'kIbmFlashSystem' | 'kNimble' | 'kAzure' | 'kNetapp' | 'kAgent' | 'kGenericNas' | 'kAcropolis' | 'kPhysicalFiles' | 'kIsilon' | 'kGPFS' | 'kKVM' | 'kAWS' | 'kExchange' | 'kHyperVVSS' | 'kOracle' | 'kGCP' | 'kFlashBlade' | 'kAWSNative' | 'kO365' | 'kO365Outlook' | 'kHyperFlex' | 'kGCPNative' | 'kAzureNative' | 'kKubernetes' | 'kElastifile' | 'kAD' | 'kRDSSnapshotManager' | 'kCassandra' | 'kMongoDB' | 'kCouchbase' | 'kHdfs' | 'kHive' | 'kHBase' | 'kSAPHANA';

    /**
     * Specifies the application such as 'kSQL', 'kExchange' running on the
     * Protection Source.
     * overrideDescription: true
     * Supported environment types such as 'kView', 'kSQL', 'kVMware', etc.
     * NOTE: 'kPuppeteer' refers to Cohesity's Remote Adapter.
     * 'kVMware' indicates the VMware Protection Source environment.
     * 'kHyperV' indicates the HyperV Protection Source environment.
     * 'kSQL' indicates the SQL Protection Source environment.
     * 'kView' indicates the View Protection Source environment.
     * 'kPuppeteer' indicates the Cohesity's Remote Adapter.
     * 'kPhysical' indicates the physical Protection Source environment.
     * 'kPure' indicates the Pure Storage Protection Source environment.
     * 'Nimble' indicates the Nimble Storage Protection Source environment.
     * 'kAzure' indicates the Microsoft's Azure Protection Source environment.
     * 'kNetapp' indicates the Netapp Protection Source environment.
     * 'kAgent' indicates the Agent Protection Source environment.
     * 'kGenericNas' indicates the Generic Network Attached Storage Protection
     * Source environment.
     * 'kAcropolis' indicates the Acropolis Protection Source environment.
     * 'kPhsicalFiles' indicates the Physical Files Protection Source environment.
     * 'kIsilon' indicates the Dell EMC's Isilon Protection Source environment.
     * 'kGPFS' indicates IBM's GPFS Protection Source environment.
     * 'kKVM' indicates the KVM Protection Source environment.
     * 'kAWS' indicates the AWS Protection Source environment.
     * 'kExchange' indicates the Exchange Protection Source environment.
     * 'kHyperVVSS' indicates the HyperV VSS Protection Source
     * environment.
     * 'kOracle' indicates the Oracle Protection Source environment.
     * 'kGCP' indicates the Google Cloud Platform Protection Source environment.
     * 'kFlashBlade' indicates the Flash Blade Protection Source environment.
     * 'kAWSNative' indicates the AWS Native Protection Source environment.
     * 'kO365' indicates the Office 365 Protection Source environment.
     * 'kO365Outlook' indicates Office 365 outlook Protection Source environment.
     * 'kHyperFlex' indicates the Hyper Flex Protection Source environment.
     * 'kGCPNative' indicates the GCP Native Protection Source environment.
     * 'kAzureNative' indicates the Azure Native Protection Source environment.
     * 'kKubernetes' indicates a Kubernetes Protection Source environment.
     * 'kElastifile' indicates Elastifile Protection Source environment.
     * 'kAD' indicates Active Directory Protection Source environment.
     * 'kRDSSnapshotManager' indicates AWS RDS Protection Source environment.
     * 'kCassandra' indicates Cassandra Protection Source environment.
     * 'kMongoDB' indicates MongoDB Protection Source environment.
     * 'kCouchbase' indicates Couchbase Protection Source environment.
     * 'kHdfs' indicates Hdfs Protection Source environment.
     * 'kHive' indicates Hive Protection Source environment.
     * 'kHBase' indicates HBase Protection Source environment.
     */
    application?: 'kVMware' | 'kHyperV' | 'kSQL' | 'kView' | 'kPuppeteer' | 'kPhysical' | 'kPure' | 'kIbmFlashSystem' | 'kNimble' | 'kAzure' | 'kNetapp' | 'kAgent' | 'kGenericNas' | 'kAcropolis' | 'kPhysicalFiles' | 'kIsilon' | 'kGPFS' | 'kKVM' | 'kAWS' | 'kExchange' | 'kHyperVVSS' | 'kOracle' | 'kGCP' | 'kFlashBlade' | 'kAWSNative' | 'kO365' | 'kO365Outlook' | 'kHyperFlex' | 'kGCPNative' | 'kAzureNative' | 'kKubernetes' | 'kElastifile' | 'kAD' | 'kRDSSnapshotManager' | 'kCassandra' | 'kMongoDB' | 'kCouchbase' | 'kHdfs' | 'kHive' | 'kHBase' | 'kUDA' | 'kSfdc' | 'kSAPHANA';

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateApplicationServers
   */
  export interface UpdateApplicationServersParams {

    /**
     * Request to modify the Application Servers registration of a Protection
     * Source.
     */
    body: UpdateApplicationServerParameters;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for RegisterApplicationServers
   */
  export interface RegisterApplicationServersParams {

    /**
     * Request to register Application Servers in a Protection Source.
     */
    body: RegisterApplicationServersParameters;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UnregisterApplicationServers
   */
  export interface UnregisterApplicationServersParams {

    /**
     * Specifies a unique id of the Protection Source to unregister the
     * Application Servers. If the Protection Source is currently being
     * backed up, unregister operation will fail.
     */
    id: number;

    /**
     * Request to register a protection source.
     */
    body: UnRegisterApplicationServersParameters;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for ListDataStoreInformation
   */
  export interface ListDataStoreInformationParams {

    /**
     * Specifies the id of the virtual machine in vmware environment.
     */
    sourceId: number;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for RunDiagnostics
   */
  export interface RunDiagnosticsParams {

    /**
     * Specifies the entity id.
     */
    id: number;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for ListExchangeDagHosts
   */
  export interface ListExchangeDagHostsParams {

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Specifies the Protection Source Id of the Exchange DAG source.
     */
    protectionSourceId?: number;

    /**
     * Specifies the endpoint of Exchange DAG or a host which is member of
     * Exchange DAG or a standalone exchange server.
     */
    endpoint?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetProtectionSourcesObjects
   */
  export interface GetProtectionSourcesObjectsParams {

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Specifies the ids of the Protection Source objects to return.
     */
    objectIds?: Array<number>;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetProtectionSourcesObjectById
   */
  export interface GetProtectionSourcesObjectByIdParams {

    /**
     * Specifies a unique id of the Protection Source to return.
     */
    id: number;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for ListProtectedObjects
   */
  export interface ListProtectedObjectsParams {

    /**
     * Specifies the Id of a registered Protection Source of the type
     * given in environment.
     */
    id: number;

    /**
     * Specifies the environment type of the registered Protection Source
     * such as 'kVMware', 'kSQL', 'kView' 'kPhysical', 'kPuppeteer', 'kPure',
     * 'kNetapp', 'kGenericNas', 'kHyperV', 'kAcropolis', or 'kAzure'.
     * For example, set this parameter to 'kVMware' if the registered
     * Protection Source is of 'kVMware' environment type.
     * Supported environment types such as 'kView', 'kSQL', 'kVMware', etc.
     * NOTE: 'kPuppeteer' refers to Cohesity's Remote Adapter.
     * 'kVMware' indicates the VMware Protection Source environment.
     * 'kHyperV' indicates the HyperV Protection Source environment.
     * 'kSQL' indicates the SQL Protection Source environment.
     * 'kView' indicates the View Protection Source environment.
     * 'kPuppeteer' indicates the Cohesity's Remote Adapter.
     * 'kPhysical' indicates the physical Protection Source environment.
     * 'kPure' indicates the Pure Storage Protection Source environment.
     * 'Nimble' indicates the Nimble Storage Protection Source environment.
     * 'kAzure' indicates the Microsoft's Azure Protection Source environment.
     * 'kNetapp' indicates the Netapp Protection Source environment.
     * 'kAgent' indicates the Agent Protection Source environment.
     * 'kGenericNas' indicates the Generic Network Attached Storage Protection
     * Source environment.
     * 'kAcropolis' indicates the Acropolis Protection Source environment.
     * 'kPhsicalFiles' indicates the Physical Files Protection Source environment.
     * 'kIsilon' indicates the Dell EMC's Isilon Protection Source environment.
     * 'kGPFS' indicates IBM's GPFS Protection Source environment.
     * 'kKVM' indicates the KVM Protection Source environment.
     * 'kAWS' indicates the AWS Protection Source environment.
     * 'kExchange' indicates the Exchange Protection Source environment.
     * 'kHyperVVSS' indicates the HyperV VSS Protection Source
     * environment.
     * 'kOracle' indicates the Oracle Protection Source environment.
     * 'kGCP' indicates the Google Cloud Platform Protection Source environment.
     * 'kFlashBlade' indicates the Flash Blade Protection Source environment.
     * 'kAWSNative' indicates the AWS Native Protection Source environment.
     * 'kO365' indicates the Office 365 Protection Source environment.
     * 'kO365Outlook' indicates Office 365 outlook Protection Source environment.
     * 'kHyperFlex' indicates the Hyper Flex Protection Source environment.
     * 'kGCPNative' indicates the GCP Native Protection Source environment.
     * 'kAzureNative' indicates the Azure Native Protection Source environment.
     * 'kKubernetes' indicates a Kubernetes Protection Source environment.
     * 'kElastifile' indicates Elastifile Protection Source environment.
     * 'kAD' indicates Active Directory Protection Source environment.
     * 'kRDSSnapshotManager' indicates AWS RDS Protection Source environment.
     * 'kCassandra' indicates Cassandra Protection Source environment.
     * 'kMongoDB' indicates MongoDB Protection Source environment.
     * 'kCouchbase' indicates Couchbase Protection Source environment.
     * 'kHdfs' indicates Hdfs Protection Source environment.
     * 'kHive' indicates Hive Protection Source environment.
     * 'kHBase' indicates HBase Protection Source environment.
     */
    environment: 'kVMware' | 'kHyperV' | 'kSQL' | 'kView' | 'kPuppeteer' | 'kPhysical' | 'kPure' | 'kIbmFlashSystem' | 'kNimble' | 'kAzure' | 'kNetapp' | 'kAgent' | 'kGenericNas' | 'kAcropolis' | 'kPhysicalFiles' | 'kIsilon' | 'kGPFS' | 'kKVM' | 'kAWS' | 'kExchange' | 'kHyperVVSS' | 'kOracle' | 'kGCP' | 'kFlashBlade' | 'kAWSNative' | 'kO365' | 'kO365Outlook' | 'kHyperFlex' | 'kGCPNative' | 'kAzureNative' | 'kKubernetes' | 'kElastifile' | 'kAD' | 'kRDSSnapshotManager' | 'kCassandra' | 'kMongoDB' | 'kCouchbase' | 'kHdfs' | 'kHive' | 'kHBase' | 'kSAPHANA';

    /**
     * Specifies the type of the request. Possible values are UIUser and UIAuto,
     * which means the request is triggered by user or is an auto refresh
     * request. Services like magneto will use this to determine the priority
     * of the requests, so that it can more intelligently handle overload
     * situations by prioritizing higher priority requests.
     */
    requestInitiatorType?: string;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * If true, then the Protected Objects protected by RPO policies will also
     * be returned.
     */
    includeRpoSnapshots?: boolean;

    /**
     * AllUnderHierarchy specifies if objects of all the tenants under the
     * hierarchy of the logged in user's organization should be returned.
     */
    allUnderHierarchy?: boolean;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for RefreshProtectionSourceById
   */
  export interface RefreshProtectionSourceByIdParams {

    /**
     * Id of the root node of the Protection Sources tree to refresh.
     *
     * Force a refresh of the Object hierarchy for the passed in Protection
     * Sources Id.
     */
    id: number;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for RegisterProtectionSource
   */
  export interface RegisterProtectionSourceParams {

    /**
     * Request to register a protection source.
     */
    body: RegisterProtectionSourceParameters;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for ListProtectionSourcesRegistrationInfo
   */
  export interface ListProtectionSourcesRegistrationInfoParams {

    /**
     * Specifies whether we can serve the GET request to the aux master.
     */
    useCachedData?: boolean;

    /**
     * TenantIds contains ids of the tenants for which objects are to be
     * returned.
     */
    tenantIds?: Array<string>;

    /**
     * Filter the registered root nodes for the sids given in the list.
     */
    sids?: Array<string>;

    /**
     * Specifies the type of the request. Possible values are UIUser and UIAuto,
     * which means the request is triggered by user or is an auto refresh
     * request. Services like magneto will use this to determine the priority
     * of the requests, so that it can more intelligently handle overload
     * situations by prioritizing higher priority requests.
     */
    requestInitiatorType?: string;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Specifies whether to prune non critical info within entities.
     * Incase of VMs, virtual disk information will be pruned.
     * Incase of Office365, metadata about user entities will be pruned.
     * This can be used to limit the size of the response by caller.
     */
    pruneNonCriticalInfo?: boolean;

    /**
     * Specifies the maintenance status of a source
     * 'UnderMaintenance' indicates the source is currently under maintenance.
     * 'ScheduledMaintenance' indicates the source is scheduled for maintenance.
     * 'NotConfigured' indicates maintenance is not configured on the source.
     */
    maintenanceStatus?: 'UnderMaintenance' | 'ScheduledMaintenance' | 'NotConfigured';

    /**
     * If specified, then crednetial for the registered sources will be included.
     * Credential is first encrypted with internal key and then reencrypted with
     * user supplied 'encryption_key'.
     */
    includeSourceCredentials?: boolean;

    /**
     * Specifies if entity external metadata should be included within the
     * response to get entity hierarchy call.
     */
    includeExternalMetadata?: boolean;

    /**
     * If specified, then a list of entities with permissions assigned to them are
     * returned.
     */
    includeEntityPermissionInfo?: boolean;

    /**
     * Specifies whether to return applications tree info or not.
     */
    includeApplicationsTreeInfo?: boolean;

    /**
     * Return only the registered root nodes whose Ids are given in the list.
     */
    ids?: Array<number>;

    /**
     * Return only Protection Sources that match the passed in environment type
     * such as 'kVMware', 'kSQL', 'kView' 'kPhysical', 'kPuppeteer', 'kPure',
     * 'kNetapp', 'kGenericNas', 'kHyperV', 'kAcropolis', or 'kAzure'.
     * For example, set this parameter to 'kVMware' to only return the Sources
     * (and their Object subtrees) found in the 'kVMware'
     * (VMware vCenter Server) environment.
     *
     * NOTE: 'kPuppeteer' refers to Cohesity's Remote Adapter.
     */
    environments?: Array<'kVMware' | 'kSQL' | 'kView' | 'kPuppeteer' | 'kPhysical' | 'kPure' | 'kIbmFlashSystem' | 'kNetapp' | 'kGenericNas' | 'kHyperV' | 'kAcropolis' | 'kAzure' | 'kPhysicalFiles' | 'kIsilon' | 'kGPFS' | 'kKVM' | 'kAWS' | 'kExchange' | 'kHyperVVSS' | 'kOracle' | 'kGCP' | 'kFlashBlade' | 'kAWSNative' | 'kVCD' | 'kO365' | 'kO365Outlook' | 'kHyperFlex' | 'kGCPNative' | 'kKubernetes' | 'kCassandra' | 'kMongoDB' | 'kCouchbase' | 'kHdfs' | 'kHive' | 'kHBase' | 'kUDA' | 'kSfdc' | 'kSAPHANA'>;

    /**
     * Key to be used to encrypt the source credential. If
     * include_source_credentials is set to true this key must be specified.
     */
    encryptionKey?: string;

    /**
     * AllUnderHierarchy specifies if objects of all the tenants under the
     * hierarchy of the logged in user's organization should be returned.
     */
    allUnderHierarchy?: boolean;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for ListProtectionSourcesRootNodes
   */
  export interface ListProtectionSourcesRootNodesParams {

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Return the registration information for the Protection Source id.
     */
    id?: number;

    /**
     * Return only the root Protection Sources that match the passed in
     * environment type such as 'kVMware', 'kSQL', 'kView', 'kPuppeteer',
     * 'kPhysical', 'kPure', 'kNetapp', 'kGenericNas', 'kHyperV', 'kAcropolis'
     * 'kAzure'.
     * For example, set this parameter to 'kVMware' to
     * only return the root Protection Sources found in the 'kVMware'
     * (VMware vCenter) environment. In addition, the registration
     * information for each Source is returned.
     *
     * NOTE: 'kPuppeteer' refers to Cohesity's Remote Adapter.
     */
    environments?: Array<'kVMware' | 'kHyperV' | 'kSQL' | 'kView' | 'kPuppeteer' | 'kPhysical' | 'kPure' | 'kIbmFlashSystem' | 'kNimble' | 'kAzure' | 'kNetapp' | 'kAgent' | 'kGenericNas' | 'kAcropolis' | 'kPhysicalFiles' | 'kIsilon' | 'kGPFS' | 'kKVM' | 'kAWS' | 'kExchange' | 'kHyperVVSS' | 'kOracle' | 'kGCP' | 'kFlashBlade' | 'kAWSNative' | 'kO365' | 'kO365Outlook' | 'kHyperFlex' | 'kGCPNative' | 'kAzureNative' | 'kKubernetes' | 'kElastifile' | 'kAD' | 'kRDSSnapshotManager' | 'kCassandra' | 'kMongoDB' | 'kCouchbase' | 'kHdfs' | 'kHive' | 'kHBase' | 'kUDA' | 'kSfdc' | 'kSAPHANA'>;

    /**
     * This field is deprecated. Use environments instead.
     * deprecated: true
     */
    environment?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for ListSqlAagHostsAndDatabases
   */
  export interface ListSqlAagHostsAndDatabasesParams {

    /**
     * Specifies a list of Ids of Protection Sources registered as SQL servers.
     * These sources may have one or more SQL databases in them. Some of them
     * may be part of AAGs(Always on Availability Group).
     */
    sqlProtectionSourceIds: Array<number>;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for ListVirtualMachines
   */
  export interface ListVirtualMachinesParams {

    /**
     * Limit the VMs returned to the set of VMs found in a specific
     * vCenter Server.
     * Pass in the root Protection Source id for the vCenter Server
     * to search for VMs.
     */
    vCenterId?: number;

    /**
     * Limit the returned VMs to those that exactly match the passed in UUIDs.
     */
    uuids?: Array<string>;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Specifies whether to prune non-critical info within entities.
     * In case of VMs, virtual disk information will be pruned.
     */
    pruneNonCriticalInfo?: boolean;

    /**
     * Limit the returned VMs to those that have been protected
     * by a Protection Job.
     * By default, both protected and unprotected VMs are returned.
     */
    protected?: boolean;

    /**
     * Limit the returned VMs to those that exactly match the passed in VM name.
     * To match multiple VM names, specify multiple "names" parameters that each
     * specify a single VM name.
     * The string must exactly match the passed in VM name and wild cards are
     * not supported.
     */
    names?: Array<string>;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UnregisterProtectionSource
   */
  export interface UnregisterProtectionSourceParams {

    /**
     * Specifies a unique id of the Protection Source to unregister.
     * If the Protection Source is currently being backed up, unregister
     * operation will fail.
     */
    id: number;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateProtectionSource
   */
  export interface UpdateProtectionSourceParams {

    /**
     * Specifies a unique id of the Protection Source to update.
     */
    id: number;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Request to update protection source.
     */
    body?: UpdateProtectionSourceParameters;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { ProtectionSourcesService }
