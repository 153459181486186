import { ActiveDirectoryPrincipal } from '@cohesity/api/v1';

/**
 * Interface for Domain Principal.
 */
export interface DomainPrincipal extends ActiveDirectoryPrincipal {
  builtIn?: boolean;
}

export const SMB_WELL_KNOWN_PRINCIPALS: DomainPrincipal[] = [
  {
    // 'Everyone'
    fullName: 'Everyone',
    objectClass: 'kGroup',
    sid: 'S-1-1-0',
    builtIn: true,
  },
  {
    // 'Administrators'
    fullName: 'Administrators',
    objectClass: 'kGroup',
    sid: 'S-1-5-32-544',
    builtIn: true,
  },
  {
    // 'Users'
    fullName: 'Users',
    objectClass: 'kGroup',
    sid: 'S-1-5-32-545',
    builtIn: true,
  },
  {
    // 'Guests'
    fullName: 'Guests',
    objectClass: 'kGroup',
    sid: 'S-1-5-32-546',
    builtIn: true,
  },
  {
    // 'Power Users'
    fullName: 'Power Users',
    objectClass: 'kGroup',
    sid: 'S-1-5-32-547',
    builtIn: true,
  },
  {
    // 'Account Operators'
    fullName: 'Account Operators',
    objectClass: 'kGroup',
    sid: 'S-1-5-32-548',
  },
  {
    // 'Server Operators'
    fullName: 'Server Operators',
    objectClass: 'kGroup',
    sid: 'S-1-5-32-549',
  },
  {
    // 'Print Operators'
    fullName: 'Print Operators',
    objectClass: 'kGroup',
    sid: 'S-1-5-32-550',
  },
  {
    // 'Backup Operators'
    fullName: 'Backup Operators',
    objectClass: 'kGroup',
    sid: 'S-1-5-32-551',
    builtIn: true,
  },
  {
    // 'Replicators'
    fullName: 'Replicators',
    objectClass: 'kGroup',
    sid: 'S-1-5-32-552',
  },
  {
    // 'Storage Replica Administrators'
    fullName: 'Storage Replica Administrators',
    objectClass: 'kGroup',
    sid: 'S-1-5-32-582',
  }
];
