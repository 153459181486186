<!-- Ntfs Root Permissions Component -->
<h2 translate>ntfsRootPermissions</h2>
<div class="row no-margin margin-bottom-lg" *ngIf="domainPrincipalsHash">
  <coh-user-at-domain-picker
    cogDataId="ntfs-root-permissions"
    [sid]="formGroupControls.ownerSid.value"
    [principalsHash]="domainPrincipalsHash"
    [domains]="domains"
    [readOnly]="formGroup.disabled || isDisabled"
    (sidChange)="formGroupControls.ownerSid.setValue($event)">
  </coh-user-at-domain-picker>
</div>
<h2 translate>permissions</h2>
<coh-acl-permissions
  [id]="'ntfs-root'"
  [showWarningWhenChangePermission]="true"
  [domainPrincipalsHash]="domainPrincipalsHash"
  [permissionList]="formGroupControls.permissions.value"
  [shares]="false"
  [trustedAdDomains]="domains"
  (updatedPermissions)="onPermissionsChange($event)"
  [readOnly]="formGroup.disabled || isDisabled">
</coh-acl-permissions>
