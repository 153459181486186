import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { Observable } from 'rxjs';

/**
 * The default configuration for launching dialogs. These can be overridden at
 * runtime.
 */
export const defaultDialogConfig: MatDialogConfig = {
  ariaLabel: 'Dialog',
  role: 'dialog',
  autoFocus: true,
  closeOnNavigation: true,
  disableClose: true,
  restoreFocus: true,
};

/**
 * A convenience service to launch dialogs.
 */
@Injectable({ providedIn: 'root' })
export class DialogService {
  constructor(private dialog: MatDialog) {}

  /**
   * Creates and launches a dialog with the specified component.
   *
   * @param component The component type to use as the contents.
   * @param config The additional configuration for the dialog.
   * @returns An observable of the dialog response.
   */
  open<C, R, D = any>(component: ComponentType<C>, config: MatDialogConfig<D> = {}): Observable<R> {
    return this.dialog.open(component, { ...defaultDialogConfig, ...config }).afterClosed();
  }
}
