<cog-statlist itemSpacing="lg" valueSize="md">
  <cog-statlist-item *ngIf="!isNoSqlHadoopEnviroment && objectCount > 0" [label]="objectLabel">
    <!-- Without the wrapper inside of a flex display, the auto truncate style will mess up the height of the label -->
    <div class="auto-truncate-wrapper">
      <!-- Carve out less space if there is one and only one Group selected, as it will be spelled out. -->
      <span class="auto-truncate"
        [class.protection-group-name-item]="protectionGroups.length !== 1"
        [class.user-data-lg]="protectionGroups.length === 1"
        [matTooltip]="objects.length === 1 && protectionGroups?.length === 0 ? objectName : null">
        {{objects.length === 1 && protectionGroups?.length === 0 ? objectName : objectCount | number}}
      </span>
    </div>
  </cog-statlist-item>

  <ng-container *ngIf="isNoSqlHadoopEnviroment">
    <cog-statlist-item *ngIf="protectionGroups.length > 0" label="{{ 'job' | translate }}">
      <div class="auto-truncate-wrapper">
        <span class="auto-truncate protection-group-name-item"
          [matTooltip]="protectionGroupName">
          {{protectionGroupName}}
        </span>
      </div>
    </cog-statlist-item>

    <cog-statlist-item *ngIf="parentObjectCount > 0" [label]="parentObjectLabel">
      <div class="auto-truncate-wrapper">
        <span class="auto-truncate"
          [matTooltip]="parentObjectCount === 1 ? parentObjectName : null">
          {{parentObjectName}}
        </span>
      </div>
    </cog-statlist-item>

    <cog-statlist-item *ngIf="leafObjectCount > 0" [label]="leafObjectLabel">
      <div class="auto-truncate-wrapper">
        <span class="auto-truncate"
          [matTooltip]="leafObjectCount === 1 ? leafObjectName : null">
          {{leafObjectName}}
        </span>
      </div>
    </cog-statlist-item>
  </ng-container>

  <cog-statlist-item *ngIf="selection?.length && showSnapshotLabel" [label]="snapshotLabel || ('snapshot' | translate)">
    <span *ngIf="isLatestSnapshot">{{'latest' | translate}}</span>
    <span *ngIf="isMixedSnapshot && !isLatestSnapshot">{{'mixed' | translate}}</span>

    <span *ngIf="!isMixedSnapshot && !isLatestSnapshot">
      <ng-container *ngIf="selection[0]?.isPointInTime">
        {{ snapshotTimestampUsecs | cogDate : 'MMM d, yyyy h:mm:ssa' }}
      </ng-container>

      <ng-container *ngIf="!selection[0]?.isPointInTime">{{ snapshotTimestampUsecs | cogDate }}</ng-container>
    </span>
  </cog-statlist-item>

  <cog-statlist-item *ngIf="snapshotLocationType && showLocationLabel"
    [label]="'location' | translate">
    <div [ngSwitch]="snapshotLocationType" class="location-container">
      <!-- Show the region name as location name in dms. -->
      <ng-container *ngSwitchCase="'dms'">
        <cog-icon shape="helix:cloud"></cog-icon>
        <div class="auto-truncate-wrapper">
          <span
            class="auto-truncate location-item"
            [matTooltip]="uiRouterGlobals.params?.regionId | translate">
            {{uiRouterGlobals.params?.regionId | translate }}
          </span>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="'local'">
        <cog-icon [shape]="'local' | backupTargetIcon"></cog-icon>
        {{ 'local' | translate}}
      </ng-container>

      <!-- Handle archival case, and pass ownershipContext to support FortKnox -->
      <ng-container *ngSwitchCase="'archival'">
        <cog-icon [shape]="'Archival' |
          backupTargetIcon : archivalTargetInfo">
        </cog-icon>
        <div class="auto-truncate-wrapper">
          <span
            class="auto-truncate location-item"
            [matTooltip]="archivalTargetInfo?.targetName">
            {{ archivalTargetInfo?.targetName }}
          </span>
        </div>
      </ng-container>

      <!-- Storage array shows the targetName if available, otherwise just the storageArray label -->
      <ng-container *ngSwitchCase="'storageArray'">
        <cog-icon [shape]="'StorageArraySnapshot' | backupTargetIcon">
        </cog-icon>
        <div class="auto-truncate-wrapper">
          <span
            class="auto-truncate location-item"
            [matTooltip]="archivalTargetInfo?.targetName || 'storageArray' | translate">
            {{ archivalTargetInfo?.targetName || 'storageArray' | translate }}
          </span>
        </div>
      </ng-container>

      <!-- In general, we shouldn't allow mixed targets since Magneto doesn't support it. If it does show up though,
      we will not show an icon -->
      <ng-container *ngSwitchCase="'mixed'">
        {{ 'mixed' | translate}}
      </ng-container>
    </div>
  </cog-statlist-item>
  <div class="view-selected-object" *ngIf="recoveryVmwareUiImprovementEnabled">
    <a (click)="viewSelectedObjects()" cogDataId="view-selected-objects"> {{'recover.vm.viewSelectedObjects' | translate}} </a>
  </div>
  <ng-content></ng-content>
</cog-statlist>
