<div class="content-wrapper">
  <h2 class="heading margin-bottom" [translate]="logoutHeading"></h2>

  <a *ngIf="displayLoginLink" cogDataId="anchor-sign-in-again" (click)="handleLoginAgain()">
    {{'login.again' | translate}}
  </a>

  <div *ngIf="displayCopyright" class="copyright-notice">
    {{ 'copyrightNg' | translate : { year: copyrightYear, company: ( companyName || 'cohesityInc' ) | translate } }}
  </div>
</div>
