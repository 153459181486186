<!-- recursively render the menu items -->
<mat-menu #childMenu="matMenu" [overlapTrigger]="false" class="cog-actions-menu-panel">
  <ng-content></ng-content>
  <span *ngFor="let child of navList">
    <!-- Handle branch node menu items -->
    <span *ngIf="child.subNavList?.length > 0 && !child.hidden" [class.disabled]="child.disabled">
      <cog-actions-menu #menu
        [itemTemplate]="itemTemplate"
        [navList]="child.subNavList"
        [size]="size">
      </cog-actions-menu>
      <div mat-menu-item [matMenuTriggerFor]="menu?.childMenu" [class.mat-menu-item-lg]="size === 'lg'">
        <ng-container *ngTemplateOutlet="menuItemTemplate; context: { $implicit: child }">
        </ng-container>
      </div>
    </span>
    <!-- Handle leaf node menu items -->
    <div data-toggle="tooltip" data-placement="bottom"
      [title]="child.disabled ? child.disabledTooltip : ''">
      <span
        [style]="child.disabled ? 'pointer-events: none;' : ''"
        mat-menu-item
        *ngIf="!child.subNavList?.length && !child.hidden"
        [class.disabled]="child.disabled"
        [class.mat-menu-item-lg]="size === 'lg'"
        (keydown.enter)="handleEnterKey($event, child)">
        <ng-container *ngTemplateOutlet="menuItemTemplate; context: { $implicit: child }">
        </ng-container>
      </span>
    </div>
  </span>
</mat-menu>

<!-- This is a default item template for the actions menu. It does not handle routing
  let-ctx will hold the provided $implicit context to the template details can
  be found here https://angular.io/api/common/NgTemplateOutlet#ngTemplateOutletContext -->
<ng-template #defaultItemTemplate let-item>
  <cog-action-menu-item [item]="item"
    cogDataId="item" [inheritDataId]="true">
    {{item.displayName}}
  </cog-action-menu-item>
</ng-template>
