import { inject, Injectable } from '@angular/core';
import { IS_IBM_AQUA_ENV } from '@cohesity/shared/core';
import { datadogRum } from '@datadog/browser-rum';
import { flagEnabled, IrisContextService, isMcmGovCloud, isMcmSaaS } from '../iris-context';

/**
 * Interface for datadog initialization options.
 */
interface InitDatadogOptions {
  /**
   * The environment string to put in datadog.
   */
  environment: string;

  /**
   * The application version to put in datadog.
   */
  version: string;
}

/**
 * Service to initialize Datadog RUM.
 *
 * Datadog RUM is used to track performance of the application.
 * RUM stands for "Real User Monitoring".
 */
@Injectable({
  providedIn: 'root',
})
export abstract class CoreMonitoringService {
  /**
   * Determines whether the UI is running in IBM Aqua mode.
   */
  readonly isIBMAquaEnv = inject(IS_IBM_AQUA_ENV);

  constructor(private irisContextService: IrisContextService) {
  }

  /**
   * Initializes Datadog RUM.
   */
  initDatadog(options: InitDatadogOptions) {
    if (!flagEnabled(this.irisContextService.irisContext, 'datadogRumEnabled') ||
      !isMcmSaaS(this.irisContextService.irisContext) ||
      this.isIBMAquaEnv ||
      isMcmGovCloud(this.irisContextService.irisContext)) {
      // Datadog RUM is only enabled for Helios SaaS environment.
      return;
    }

    // Initialize datadog
    datadogRum.init({
      applicationId: '14b50541-a06c-4076-a5a4-8509e2fb9c21',
      clientToken: 'pub83464ef25754fb23b65a4ff340339cae',
      site: 'datadoghq.com',
      service: 'helios',
      env: options.environment,
      // Specify a version number to identify the deployed version  in Datadog
      version: options.version,
      sessionSampleRate: 100,
      // Disable session recording
      sessionReplaySampleRate: 0,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
    });
  }
}
