// MODULE: Recover Local Task Detail Page

;(function(angular, undefined) {
  'use strict';

  angular.module('C.recovery')
    .controller('recoveryTaskLocalDetailController',
      recoveryTaskLocalDetailControllerFn);

  /**
   * Recovery Task Local Detail Controller Fn
   *
   * @function   recoveryTaskLocalDetailControllerFn
   */
  function recoveryTaskLocalDetailControllerFn(_, $rootScope, $scope, $state,
    $stateParams, $timeout, $translate, cUtils, evalAJAX, SourceService,
    RestoreService, JobService, PollTaskStatus, DateTimeService,
    ENUM_RESTORE_TASK_STATUS, ENUM_UI_CLONE_OBJECT_STATUS_CLASSNAME,
    ENUM_UI_CLONE_OBJECT_STATUS, ENV_GROUPS, cMessage, ENUM_RESTORE_TYPE,
    PhysicalServerRecoveryService, OFFICE365_GROUPS) {

    var $ctrl = this;
    var tmpTotals = {
      success: 0,
      errors: 0,
      running: 0
    };

    // use 1 min as poll interval
    var pollInterval = 60 * 1000;

    // Timeout holder
    var reloadTimeout;

    // @type  {array}  - Holder for VM app objects list.
    var vmAppObjects;

    // Hash of JobNames by ID.
    var jobNames = {};

    // Lookup map to get the object(restoreEntity) from its taskPath
    var objectLookupByTaskPath = {};

    _.assign($ctrl, {
      $onInit: $onInit,

      canRetryRestoreTask: RestoreService.canRetryRestoreTask,
      getFileOrFolderSourcePath: getFileOrFolderSourcePath,
      getFolderDestinationPath: getFolderDestinationPath,
      retryRestoreTask: RestoreService.retryRestoreTask,
    });

    _.assign($scope, {
      ENV_GROUPS: ENV_GROUPS,
      ENUM_RESTORE_TYPE: ENUM_RESTORE_TYPE,
      ENUM_RESTORE_TASK_STATUS: ENUM_RESTORE_TASK_STATUS,
      ENUM_UI_CLONE_OBJECT_STATUS: ENUM_UI_CLONE_OBJECT_STATUS,
      ENUM_UI_CLONE_OBJECT_STATUS_CLASSNAME:
        ENUM_UI_CLONE_OBJECT_STATUS_CLASSNAME,
      OFFICE365_GROUPS: OFFICE365_GROUPS,
      text: $rootScope.text.protectionRecoveryDetailLocal,
      dataReady: false,
      restoreTask: {},
      restoreTaskType: undefined,
      pollData: {},
      totals: {
        success: 0,
        errors: 0,
        running: 0,
      },
      objects: [],
      expandedRows: [],
      restoreTaskSubTaskVec: [],
      expandedTasks: false,

      //Methods
      downloadFile: downloadFile,
      teardownTask: teardownTask,
      cancelTask: cancelTask,
      canCancelTask: RestoreService.canCancelTask,
      isTaskDestroyed: RestoreService.isTaskDestroyed,
      isTaskDestroying: RestoreService.isTaskDestroying,
      showAagStepsModal: showAagStepsModal,
      getTargetVolumeLabel:
        PhysicalServerRecoveryService.getTargetVolumeLabel,
    });

    /**
     * Initialize this controller.
     *
     * @method   $onInit
     */
    function $onInit() {
      getTaskDetail().then(function postInit(task) {
        if ($ctrl.isMultiStageRestore) {
          _initDbMigration();
        } else if ($scope.restoreTaskType === ENUM_RESTORE_TYPE.kRecoverDisks) {
          _initVmdk();
        }
      });
    }

    /**
     * get the file or folder source path for a given recovery task object
     *
     * @method   getFileOrFolderSourcePath
     * @param    {object}   obj   task object
     * @return   {string}   file/folder path.
     */
    function getFileOrFolderSourcePath(obj) {
      var path = _.get(obj, 'fileOrFolderNameAndPath.path');
      return cUtils.transformToOsSpecificPath(path,
        _.get(obj, '_normalizedEntity.hostType'));
    }

    /**
     * get the folder destination path for a given recovery task object
     *
     * @method   getFolderDestinationPath
     * @param    {object}   obj   task object
     * @return   {string}   file/folder path.
     */
    function getFolderDestinationPath(obj) {
      return cUtils.transformToOsSpecificPath(obj.destinationDir,
        _.get(obj, '_normalizedEntity.hostType'));
    }

    /**
     * get the Smb mount path for Exchange recovery.
     *
     * @method   getSmbMountPath
     * @return   {string}   The smb mount path.
     */
    function getSmbMountPath() {
      return _.get($scope.restoreTask,
        'performRestoreTaskState.restoreAppTaskState.restoreAppParams' +
        '.restoreAppObjectVec[0].restoreParams.exchangeRestoreParams' +
        '.viewOptions.mountPoint');
    }

    /**
     * Shows the AAG manual steps modal.
     *
     * @method   showAagStepsModal
     * @return   {object}   Promise to resolve/reject with the modal's response.
     */
    function showAagStepsModal() {
      var cart = $scope.restoreTask.performRestoreTaskState.restoreAppTaskState
        .restoreAppParams.restoreAppObjectVec;
      return RestoreService.showAagStepsModal(cart, true, 'recover');
    }

    /**
     * Tear down this task.
     *
     * @method     teardownTask
     */
    function teardownTask() {
      // Show a confirmation modal before destroying.
      RestoreService.teardownTaskModal($scope.restoreTask)
        .then(function teardownStarted() {
          // call for a reload so user can see when destroy has completed
          // automatically
          getTaskDetailsOnTimeout();
        });
    }

    /**
     * Cancels this task.
     *
     * @method     cancelTask
     */
    function cancelTask() {
      var cancelTaskParam = {
        id: $stateParams.id,
      };

      var restoreInfo = $scope.restoreTask.performRestoreTaskState.restoreInfo;

      var restoreFilesTaskState =
        $scope.restoreTask.performRestoreTaskState.restoreFilesTaskState;

      if (restoreInfo) {
        _.merge(cancelTaskParam, {
          entityType: restoreInfo.type,
        });
      }

      // For file restore task, entityType is availabe on restoreFilesInfo
      if (restoreFilesTaskState && restoreFilesTaskState.restoreFilesInfo) {
        _.merge(cancelTaskParam, {
          entityType: restoreFilesTaskState.restoreFilesInfo.type,
        });
      }

      // Show a confirmation modal before canceling.
      RestoreService.cancelTaskModal(cancelTaskParam).then(
        getTaskDetailsOnTimeout);
    }

    /**
     * Calls the getTask function on a timeout
     *
     * @method     getTaskDetailsOnTimeout
     */
    function getTaskDetailsOnTimeout() {
      reloadTimeout = $timeout(getTaskDetail, 5000);
    }

    /**
     *  Calls RestoreService to update current task,
     *  helps set correct status in the ui
     *
     * @method refreshTaskDetails
     */
    function refreshTaskDetails() {
      RestoreService.getTask($stateParams.id).then(
        function getRefreshSuccess(task) {
          $scope.restoreTask = Array.isArray(task) ? task[0] : task;
        },
        evalAJAX.errorMessage
      );
    }

    /**
     * Gets the task detail.
     *
     * @method     getTaskDetail
     * @param      {boolean}   noUpdateTaskRequired   dont invoke
     *                         updateTaskDetails when noUpdateTaskRequired true
     */
    function getTaskDetail(noUpdateTaskRequired) {
      return RestoreService.getTask($stateParams.id).then(
        function getTaskSuccess(task) {
          if (!task || !task.length) {
            // Invalid data response, likely an invalid ID was passed to API.
            $state.go('recover');
            return;
          }

          $scope.restoreTask = [].concat(task)[0];
          $scope.dataReady = true;

          // For AD, the task type is 4 (kRecoverApp), and the
          // restoreInfo type is 29 (kAD).
          if (_.get($scope,
            'restoreTask._envTaskParams.restoreAppTaskState.restoreAppParams.type') === 29) {
            $scope.isAdRecoverTask = true;
          }

          // For Exchange, the task type is 4 (kRecoverApp), and the
          // restoreInfo type is 17 (kExchange).
          if (_.get($scope,
            'restoreTask._envTaskParams.restoreAppTaskState.restoreAppParams.type') === 17) {
            $scope.isExchangeRecoverTask = true;
            $scope.smbMountPath = getSmbMountPath();
          }

          $scope.canTearDownTask =
            RestoreService.canTearDownTask($scope.restoreTask);

          // Update Task Totals
          $scope.totals = angular.copy(tmpTotals);

          // Set Recover Task Type based on available properties
          $scope.restoreTaskType =
            $scope.restoreTask.performRestoreTaskState.base.type;

          $ctrl.isMultiStageRestore = $scope.restoreTask._isMultiStageRestore;

          // The template has a few differences for VM recovery tasks
          $scope.isVmRecovery = [1, 2].includes($scope.restoreTaskType);

          // Set Task Level Status
          $scope.restoreTask._taskStatus =
            RestoreService.getFileRestoreTaskStatus($scope.restoreTask.performRestoreTaskState.base);

          // File or PST export
          $scope.isFileDownloadTask = [14, 25].includes($scope.restoreTaskType);

          if (OFFICE365_GROUPS.office365RecoveryTypes.includes(
            $scope.restoreTaskType)) {
            $scope.isOffice365RecoverTask = true;
            $scope.isOutlookRecoveryTask =
              $scope.restoreTaskType === ENUM_RESTORE_TYPE.kRecoverEmails;
            $scope.isOneDriveRecoveryTask =
              $scope.restoreTaskType === ENUM_RESTORE_TYPE.kRecoverO365Drive;
            $scope.isSharePointRecoveryTask =
              $scope.restoreTaskType === ENUM_RESTORE_TYPE.kRecoverSites;
            $scope.isPstExportRecoveryTask =
              $scope.restoreTaskType === ENUM_RESTORE_TYPE.kConvertToPst;
          }

          if ($ctrl.isMultiStageRestore) {
            $ctrl.latestSyncTask = _.last($scope.restoreTask.restoreSubTaskWrapperProtoVec);
          }

          if (noUpdateTaskRequired) {
            return;
          }

          // For VM recoveries, get VM jobs for additional metadata
          if ($scope.isVmRecovery) {
            getBackupJobs(cUtils.onlyNumbers(ENV_GROUPS.hypervisor)).finally(updateTaskDetails);
          } else {
            updateTaskDetails();
          }

          return $scope.restoreTask;
        },
        evalAJAX.errorMessage
      );
    }

    /**
     * Update task details specific to restore task type
     *
     * @method     updateTaskDetails
     */
    function updateTaskDetails() {
      switch ($scope.restoreTaskType) {
        // kRecoverFiles
        case 3:
          $scope.objects = _.merge(
            $scope.restoreTask.performRestoreTaskState.restoreFilesTaskState
              .restoreFilesInfo.restoreFilesResultVec,
            $scope.restoreTask.performRestoreTaskState.restoreFilesTaskState
              .restoreParams.restoredFileInfoVec,
          );
          // As FLR recovery allows only one source object, it's safe writing objects[0] here.
          $scope.objects.forEach(
            object => _.merge(object, $scope.restoreTask.performRestoreTaskState.objects[0])
          );
          $scope.restoreTask.uiStatusObject =
            RestoreService.getFileRestoreTaskStatus(
              $scope.restoreTask.performRestoreTaskState.base);

          // Reset tmpTotals
          tmpTotals = {
            success: 0,
            errors: 0,
            running: 0
          };
          processFilesTask($scope.restoreTask);
          break;

        case 14:
        case 25:
          $scope.objects =
            _.get($scope.restoreTask, 'restoreInfo.restoreEntityVec',
              _.get($scope.restoreTask.performRestoreTaskState, "objects", []));

          setDownloadReady();
          break;

        // kRecoverApp (SQL & Oracle) and expose as view and
        // kAd (Active Directory)
        case ENUM_RESTORE_TYPE.kRecoverApp:
        case ENUM_RESTORE_TYPE.kCloneAppView:
          // The logic below is not used for Active Directory Recoveries
          if ($scope.isAdRecoverTask) {
            break;
          }
          vmAppObjects = $scope.restoreTask.performRestoreTaskState.restoreAppTaskState.restoreAppParams.restoreAppObjectVec || [];
          $scope.performRestore = !!$scope.restoreTask.performRestoreTaskState.restoreAppTaskState.restoreAppParams.ownerRestoreInfo.performRestore;

          if ($scope.performRestore) {
            // This is a VM recovery so we don't have individual database
            // names. Let's populate $scope.objects with the parent VM.
            $scope.objects = [].concat(
              $scope.restoreTask.performRestoreTaskState.restoreAppTaskState.restoreAppParams.ownerRestoreInfo.ownerObject
            );
          } else {
            // As of 2.8, status monitoring for individual DB's within a SQL
            // Recovery is unsupported. We need to simply extract a list of
            // DB's to display their name.
            $scope.objects = vmAppObjects.reduce(
              function eachAppObject(objects, appObject) {
                if (appObject.appEntity) {
                  objects.push(appObject.appEntity);
                }
                return objects;
              },
              []
            );

          }
          processSQLTask($scope.restoreTask);
          break;

        // kRecoverSanVolumes
        case 8:
          $scope.objects = $scope.restoreTask.performRestoreTaskState.objects;
          processSanTask($scope.restoreTask);
          break;

        // Physical server volume recover task
        case 12:
        // Prepare task detail table data
          $scope.volumeRecoverSubTasks = prepareVolumeRecoverSubTasks(
            $scope.restoreTask);
          break;

        // Process task as if of type kRecoverVMs. Includes processing of
        // types 6 and 10 (kMountVolumes and kMountFileVolume) until discreet
        // processing is required.
        default:
          $scope.objects = $scope.restoreTask.performRestoreTaskState.objects;
          processVMTask($scope.restoreTask);
      }

      // Get job data
      if ($scope.objects.length && $scope.objects[0].jobId) {

        JobService
          .getJob($scope.objects[0].jobId)
          .then(
            function getJobSuccess(job) {
              $scope.job = job;
            },
            evalAJAX.errorMessage
          );
      }
    }

    /**
     * Gets backup jobs and processes the list of jobs.
     *
     * @method     getBackupJobs
     * @param      {array}   [envTypes]  List of env types to query
     * @return     {object}  The promise
     */
    function getBackupJobs(envTypes) {
      var params = {
        envTypes: envTypes
      };
      return JobService.getJobs(params).then(
        createJobNameHash,
        evalAJAX.errorMessage);
    }

    /**
     * Creates a hash of jobNames by jobId.
     *
     * @method     createJobNameHash
     * @param      {array}  jobs    The array of jobs
     */
    function createJobNameHash(jobs) {
      jobs.forEach(function forEachJob(job) {
        jobNames[job.jobId] = job.name;
      });
    }

    /**
     * Common helper method to generate a cPulse url from a given task object.
     *
     * NOTE: This may eventually diverge based on recovery type.
     *
     * @method    generatePulseUrl
     * @param     {object}   task   The restoreTask object derive the url from.
     * @returns   {string|undefined}   The URL generated, or undefined if not
     *                                 applicable.
     */
    function generatePulseUrl(task) {
      // If the requisite properties are missing, bail out early.
      if (!task && !task.performRestoreTaskState &&
        !task.performRestoreTaskState.progressMonitorTaskPath) {
        return;
      }

      return [
        'progressMonitors?taskPathVec=',
        task.performRestoreTaskState.progressMonitorTaskPath,
        '&includeFinishedTasks=true&excludeSubTasks=false'
      ].join('');
    }

    /**
     * Recover VM specific operations
     * @param  {object} task
     */
    function processVMTask(task) {
      // Set endpoint for task API call
      var taskStatusUrl = generatePulseUrl(task);

      // Loop though Objects
      $scope.objects.forEach(
        function updateObjectStatus(subTask, index) {
          // relocate start and end times for a given subTask into ._progress object
          $scope.restoreTask.performRestoreTaskState.objects[index]._progress.startTimeSecs =
            $scope.restoreTask.performRestoreTaskState.objects[index].startTimeUsecs / 1000000;
          $scope.restoreTask.performRestoreTaskState.objects[index]._progress.endTimeSecs =
            $scope.restoreTask.performRestoreTaskState.objects[index].endTimeUsecs / 1000000;

          // For SharePoint recovery task, update the startTime from restore task base.
          // The startTimeUsecs derived from object is the snapshot time, not
          // the actual startTime of the restore task.
          if ($scope.isSharePointRecoveryTask) {
            var baseStartTime =
              _.get($scope.restoreTask, 'performRestoreTaskState.base.startTimeUsecs');
            if (baseStartTime) {
              $scope.restoreTask.performRestoreTaskState.objects[index]._progress.startTimeSecs =
                baseStartTime / 1000000;
            }
          }

          // Increment totals
          incrementTotalsCount(subTask);

          subTask.jobName = jobNames[subTask.jobId];
        }
      );

      if (taskStatusUrl) {
        // If the task is not finished
        // we need to kick off the progress monitor loop
        if (task.performRestoreTaskState.base.status !== 3) {
          // Instantiate a poll if we have active tasks
          PollTaskStatus.getTask(
            taskStatusUrl, pollInterval, 10, canTerminatePolling).then(
            function successPulse(r) {
              if (r && r._pollingStatus !== 'error') {
                angular.extend($scope.pollData, r[0].taskVec[0]);

                // calling refreshTaskDetails to refresh restoreTask object & status
                refreshTaskDetails();
              } else {
                $scope.pollError = true;
              }
            },
            undefined,
            function notifyPulse(r) {
              angular.extend($scope.pollData, r[0].taskVec[0]);
            }
          );
        } else {
          // Just ping the progress monitor once so we can get the sub task data
          PollTaskStatus.getTaskNoPoll(taskStatusUrl).then(
            function getTaskSuccess(r) {
              $scope.pollData = r.data.resultGroupVec[0].taskVec[0];
            }
          );
        }
      }
    }

    /**
     * Adds publicStatus property on the object
     *
     * @method   addPublicStatus
     * @param    {object} object
     */
    function addPublicStatus(object) {
      // add public status on the object
      if (objectLookupByTaskPath[object._taskPath]) {
        _.merge(object, {
          publicStatus: objectLookupByTaskPath[object._taskPath].publicStatus,
          status: objectLookupByTaskPath[object._taskPath].status,
        });
      }
    }

    /**
     * Determines ability to terminate the polling when task is finishes.
     *
     * @method   canTerminatePolling
     * @return   {boolean}   True if restoreTask is finished or cancelled,
     *                       False otherwise.
     */
    function canTerminatePolling() {
      return [3, 7].includes(
        $scope.restoreTask.performRestoreTaskState.base.status);
    }

    /**
     * Recover SAN specific operations
     * @param  {object} task
     */
    function processSanTask(task) {
      // Set endpoint for task API call
      var taskStatusUrl = generatePulseUrl(task);

      // Loop though Objects
      $scope.objects.forEach(
        function updateObjectStatus(subTask, index) {
          // relocate start and end times for a given subTask into ._progress object
          $scope.restoreTask.performRestoreTaskState.objects[index]._progress.startTimeSecs =
            $scope.restoreTask.performRestoreTaskState.objects[index].startTimeUsecs / 1000000;
          $scope.restoreTask.performRestoreTaskState.objects[index]._progress.endTimeSecs =
            $scope.restoreTask.performRestoreTaskState.objects[index].endTimeUsecs / 1000000;

          // Increment totals
          incrementTotalsCount(subTask);
        }
      );

      if (taskStatusUrl) {
        // If the task is not finished
        // we need to kick off the progress monitor loop
        if (task.performRestoreTaskState.base.status !== 3) {
          // Instantiate a poll if we have active tasks
          PollTaskStatus.getTask(taskStatusUrl, pollInterval, 10).then(
            function successPulse(r) {
              if (r && r._pollingStatus !== 'error') {
                angular.extend($scope.pollData, r);
                // calling refreshTaskDetails to refresh restoreTask object & status.
                refreshTaskDetails();
              } else {
                $scope.pollError = true;
              }
            },
            undefined,
            function notifyPulse(r) {
              angular.extend($scope.pollData, r[0].taskVec[0]);
            }
          );
        } else {
          // Just ping the progress monitor once so we can get the sub task data
          PollTaskStatus.getTaskNoPoll(taskStatusUrl).then(
            function getTaskSuccess(r) {
              $scope.pollData = r.data;
            }
          );
        }
      }
    }

    /**
     * Recover File specific operations
     * @param  {object} restoreTask
     */
    function processFilesTask(restoreTask) {
      $scope.restoreTaskEndpoint = generatePulseUrl(restoreTask);

      $scope.objects.forEach(
        function processSubTask(subTask, index) {
          subTask.uiStatusObject = RestoreService.getFileRestoreTaskStatus(subTask);
          subTask.fileOrFolderNameAndPath = RestoreService.getRestoreFileOrFolderNameAndPath(subTask.restoredFileInfo.absolutePath);
          subTask.progressMonitorTaskPath = _.get(
            restoreTask, 'performRestoreTaskState.objectsProgressMonitorTaskPaths.' + index);
          subTask.publicStatus = subTask.status;
          subTask._isStatusOk = !subTask.error && subTask.status === 2;
          subTask._isStatusCritical = subTask.error && subTask.status === 2;
          subTask._taskPath = subTask.progressMonitorTaskPath;
          // Increment totals
          incrementTotalsCount(subTask, true);
        }
      );

      // If the task is running we need to kick off the progress
      // monitor loop
      if ($scope.restoreTask.performRestoreTaskState.base.status !==
          ENUM_RESTORE_TYPE.kRestoreFiles) {
        // Instantiate a poll if we have active tasks
        PollTaskStatus.getTask($scope.restoreTaskEndpoint, pollInterval, 10).then(
          function successPulse(r) {
            if (r && r._pollingStatus !== 'error') {
              $scope.pollData = angular.extend({}, r[0].taskVec[0]);

              // calling refreshTaskDetails to refresh restoreTask object & status
              refreshTaskDetails();
            } else {
              $scope.pollError = true;
            }
          },
          undefined,
          function notifyPulse(r) {
            $scope.pollData = angular.extend({}, r[0].taskVec[0]);
          }
        );
      } else {
        // Just ping the progress monitor once so we can get the sub task data
        PollTaskStatus.getTaskNoPoll($scope.restoreTaskEndpoint).then(
          function getTaskSuccess(r) {
            $scope.pollData = r.data;
          }
        );
      }

      // Update Totals
      $scope.totals = angular.copy(tmpTotals);
    }

    /**
     * Sets the download ready state. Polling download task object until
     * download is ready.
     *
     * @method   setDownloadReady
     */
    function setDownloadReady() {

      $scope.restoreTaskEndpoint = generatePulseUrl($scope.restoreTask);

      // Set up the objects for display in the details table.
      // For PST export, we reuse processVMTask to display pulse log.
      if ($scope.restoreTaskType === ENUM_RESTORE_TYPE.kConvertToPst) {
        $scope.objects = $scope.restoreTask.performRestoreTaskState.objects;
        processVMTask($scope.restoreTask);
      } else {
        $scope.objects =
          RestoreService.decorateRestoreTaskObjects($scope.restoreTask);
      }

      // If it is not download task, return
      if (!$scope.isFileDownloadTask) {
        return;
      }

      // Polling for isCloudDownloadReady state only for in-progress download
      // task
      if ($scope.restoreTask.performRestoreTaskState.base.publicStatus ===
        'kRunning') {
        $scope.isDownloadReady = false;
        getTaskDetailsOnTimeout();
      } else {
        $scope.isDownloadReady = true;
        $scope.downloadFileName =
          RestoreService.getDownloadFileName($scope.restoreTask);
        $timeout.cancel(reloadTimeout);
      }
    }

    /**
     * Download the local snapshot from download task.
     *
     * @method   downloadFile
     */
    function downloadFile() {
      var taskState = $scope.restoreTask.performRestoreTaskState;

      if (!taskState.objects[0]) {
        return;
      }

      RestoreService.downloadFileFromRecoveryTask(taskState);

      // Set cMessage data
      cMessage.success({
        textKey: 'protectionRecoveryDetail.downloadSuccessText',
        textKeyContext: {
          restoreTask: $scope.restoreTask,
          fileName: $scope.downloadFileName,
        },
      });
    }

    /**
     * Recover SQL specific operations
     * @param  {object} task
     */
    function processSQLTask(task) {
      // Set endpoint for task API call
      $scope.restoreTaskEndpoint = generatePulseUrl(task);

      if ($scope.restoreTaskEndpoint) {
        return;
      }
      // If the task is running we need to kick off the progress
      // monitor loop
      if ($scope.restoreTask.performRestoreTaskState.base.status !== 3) {
        // Instantiate a poll if we have active tasks
        PollTaskStatus.getTask($scope.restoreTaskEndpoint, pollInterval, 10).then(
          function successPulse(r) {
            if (r && r._pollingStatus !== 'error') {
              $scope.pollData = angular.extend({}, r[0].taskVec[0]);
            } else {
              $scope.pollError = true;
            }
          },
          undefined,
          function notifyPulse(r) {
            $scope.pollData = angular.extend({}, r[0].taskVec[0]);
          }
        );
      } else {
        // Just ping the progress monitor once so we can get the sub task data
        PollTaskStatus.getTaskNoPoll($scope.restoreTaskEndpoint).then(
          function getTaskSuccess(r) {
            $scope.pollData = r.data;
          }
        );
      }
    }

    /**
     * Prepare task detail table data. Table has source and target mapping
       along with pulse details. To construct a complete row of table, we
       will have to consolidate the data from different sources. MappingVec
       contains just guuid of source and target, use volumeInfoVec to lookup
       a source/target labels. Each row also has pulse progress detail
       associated with each volume recover sub task. Use parent task detail
       pulse progress monitor which contains all the require sub tasks.
     *
     * @method   prepareVolumeRecoverSubTasks
     * @param    {object}   task   The task
     * @return   {Array}    Volume recover table data
     */
    function prepareVolumeRecoverSubTasks(task) {
      // Volume recover table data
      var volumeRecoverSubTasks = [];

      // Lookup map to get the name of source volume from its guuid
      var sourceVolumeLookup = {};

      // Lookup map to get the name of target volume from its guuid
      var targetVolumeLookup = {};

      // Create source volume lookup
      task.performRestoreTaskState.objects[0].entity.physicalEntity
        .volumeInfoVec.forEach(
          function eachSourceVolume(sourceVolume) {
            if (sourceVolume.mountPointVec || sourceVolume.volumeLabel) {
              // In case of non-lvm volumes, volumeguid doesn't come.
              // Ignore such volumes.
              if (sourceVolume.volumeGuid) {
                sourceVolumeLookup[sourceVolume.volumeGuid.toLowerCase()] =
                  sourceVolume;
              }
            }
          });

      // Create target volume lookup
      if (task.performRestoreTaskState.recoverVolumesTaskState) {
        task.performRestoreTaskState.recoverVolumesTaskState.
          params.targetEntity.physicalEntity.volumeInfoVec.forEach(
            function eachTargetVolume(targetVolume) {
              // In case of non-lvm volumes, volumeguid doesn't come.
              // Ignore such volumes.
              if (targetVolume.volumeGuid) {
                targetVolumeLookup[targetVolume.volumeGuid.toLowerCase()] =
                  targetVolume;
              }
            });
      }

      // create table row data for each entry of mappingVec
      if (task.performRestoreTaskState.recoverVolumesTaskState) {
        task.performRestoreTaskState.recoverVolumesTaskState
          .params.mappingVec.forEach(
            function rowData(row) {
              volumeRecoverSubTasks.push({
                srcGuid: row.srcGuid.toLowerCase(),
                dstGuid: row.dstGuid.toLowerCase(),
                sourceVolume: sourceVolumeLookup[row.srcGuid.toLowerCase()],
                sourceVolumeLabel: _.get(
                  sourceVolumeLookup[row.srcGuid.toLowerCase()],
                    'mountPointVec.0', _.get(
                    sourceVolumeLookup[row.srcGuid.toLowerCase()],
                    'volumeLabel')),
                targetVolume: targetVolumeLookup[row.dstGuid.toLowerCase()],
                targetVolumeLabel: targetVolumeLookup[row.dstGuid.toLowerCase()].volumeLabel,
              });
            });
      }

      updateVolumeRecoverSubTasksWithProgress(task);

      return volumeRecoverSubTasks;
    }

    /**
     * Update the volume recover table data with progress monitor details
     *
     * @method   updateVolumeRecoverSubTasksWithProgress
     */
    function updateVolumeRecoverSubTasksWithProgress(task) {
      // Set endpoint for task API call
      var taskStatusUrl = generatePulseUrl($scope.restoreTask);

      if (taskStatusUrl) {
        // If the task is not finished
        // we need to kick off the progress monitor loop
        if (task.performRestoreTaskState.base.status !== 3) {
          // Instantiate a poll if we have active tasks
          PollTaskStatus.getTask(taskStatusUrl, pollInterval, 10).then(
            function successPulse(r) {
              if (r && r._pollingStatus !== 'error') {
                angular.extend($scope.pollData, r[0].taskVec[0]);

                // calling refreshTaskDetails to refresh restoreTask object
                // & status
                refreshTaskDetails();
              } else {
                $scope.pollError = true;
              }
            },
            undefined,
            function notifyPulse(r) {
              angular.extend($scope.pollData, r[0].taskVec[0]);
            }
          );
        } else {
          // Just ping the progress monitor once so we can get the sub task data
          PollTaskStatus.getTaskNoPoll(taskStatusUrl).then(
            function getTaskSuccess(r) {
              $scope.pollData = r.data.resultGroupVec[0].taskVec[0];
            }
          );
        }
      }
    }

    /**
     * Gets the volume recover sub tasks lookups.
     *
     * @method   getVolumeRecoverSubTasksLookups
     * @param    {object}   subTasks   The sub tasks
     * @return   {object}   The volume recover sub tasks lookups.
     */
    function getVolumeRecoverSubTasksLookups(subTasks) {
      var volumeRecoverSubTasksLookups = {};

      // taskResultVec can be undefined on first call.
      _.get($scope, ['restoreTask', 'performRestoreTaskState',
        'recoverVolumesTaskState', 'taskResultVec'], [])
        .forEach(function forEach(taskResult) {
          subTasks[0].subTaskVec[0].subTaskVec.forEach(
            function eachSubTask(subTask) {
              if(taskResult.progressMonitorTaskPath === subTask.taskPath) {
                subTask._error = taskResult.error;
                volumeRecoverSubTasksLookups[taskResult.dstGuid.toLowerCase()] =
                  subTask;
              }
            });
      });

      return volumeRecoverSubTasksLookups;
    }

    /**
     * Creates progressMonitorTaskPath vs restoreEntity map.
     */
    function createObjectByTaskPathLookupMapUtil() {
      var restoreInfo = $scope.restoreTask.performRestoreTaskState.restoreInfo;

      // File base task different handling as they return a different proto
      // and don't have restoreEntityVec in performRestoreTaskState.
      if($scope.restoreTaskType === ENUM_RESTORE_TYPE.kRestoreFiles) {
        if ($scope.objects) {
          $scope.objects.forEach(function createLookupMap(restoreEntity){
            objectLookupByTaskPath[restoreEntity.progressMonitorTaskPath] =
              restoreEntity;
          });
        }
        return;
      }


      if (restoreInfo && restoreInfo.restoreEntityVec) {
        restoreInfo.restoreEntityVec.forEach(
          function createLookupMap(restoreEntity){
            objectLookupByTaskPath[restoreEntity.progressMonitorTaskPath] =
              restoreEntity;

            // Create a list of warnings
            $scope.objects.forEach(function updateWarnings(object, index) {
              if ($scope.objects[index].entity.id ===
                restoreEntity.entity.id) {
                $scope.objects[index].warnings =
                  _.map(restoreEntity.warnings,
                    function mapWarnings(warning) {
                      return warning.errorMsg;
                    });
              }
            });
        });
      }
    }

    /**
     * Updates progress monitoring data for each active task
     * based on results from poll.
     *
     * @method    updateSubTasks
     */
    function updateSubTasks() {
      var subTasks = [];

      // Assign SubTasks to a var because the response format changes depending
      // on what mood pulse is in. We need to be smart about where we locate our
      // subTasks
      if ($scope.pollData.subTaskVec) {
        subTasks = $scope.pollData.subTaskVec;
      } else if ($scope.pollData.resultGroupVec &&
        $scope.pollData.resultGroupVec[0].taskVec &&
        $scope.pollData.resultGroupVec[0].taskVec[0].subTaskVec) {
        subTasks = $scope.pollData.resultGroupVec[0].taskVec[0].subTaskVec;
      }

      if (subTasks.length) {

        // Reset tmpTotals
        tmpTotals = {
          success: 0,
          errors: 0,
          running: 0,
        };

        // Fetch the restoreTask
        getTaskDetail(true).then(function createObjectByTaskPathLookupMap() {
          createObjectByTaskPathLookupMapUtil();

          // Loop though all subTaskVecs
          subTasks.forEach(
            function filterTasks(subTask) {
              // Set new taskStatus var based on converted status
              var taskStatus = taskStatusConverter(subTask.progress.status.type);

              // Create a new statusObject to be used with incrementTotalsCount
              var statusObject = angular.copy(subTask.progress.status);
              var percentFinishedString = '';
              var endTimeString = '';

              // overwrite statusObject.status with converted status type
              statusObject.status = taskStatus;

              // API returns some tasks which do not correlate directly to objects
              // Let's filter out those tasks based on subTaskVec
              if (subTask.subTaskVec) {

                incrementTotalsCount(statusObject);

                // Assign a few vars to update our recover task
                percentFinishedString = [
                  cUtils.round(subTask.progress.percentFinished),
                  '% ',
                  $scope.text.completed
                ].join('');

                endTimeString = subTask.progress.expectedTimeRemainingSecs ?
                  [
                    DateTimeService.secsToTime(
                      subTask.progress.expectedTimeRemainingSecs).time,
                    $scope.text.remaining
                  ].join(' ') :
                  '';

                // Loop through each object in our restoreTask data set. We will
                // match the taskPath of each object with the taskPath of each
                // subTask returned from progress monitor API.
                $scope.objects.some(
                  function evaluateObject(object, objectIndex) {
                    var objectTaskPath = object._taskPath ||
                      object.progressMonitorTaskPath;

                    if (objectTaskPath !== subTask.taskPath) {
                      return false;
                    }

                    // object._normalizedEntity is not guaranteed to be present.
                    object._normalizedEntity =
                      SourceService.normalizeEntity(object.entity);

                    // _normalizeEntity is same for all subtasks for file
                    // restore. So we need different identifier to toggle
                    // pulse tasks for subtasks.
                    if ($scope.restoreTaskType === ENUM_RESTORE_TYPE.kRestoreFiles) {
                      object.toggleIndex = objectIndex;
                    } else {
                      object.toggleIndex = object._normalizedEntity.id;
                    }

                    // Finally update the base task
                    object._progress = {
                      percentage: subTask.progress.percentFinished,
                      barLabel: percentFinishedString,
                      statusLabel: endTimeString,
                      endTimeSecs: subTask.progress.endTimeSecs,
                      startTimeSecs: subTask.progress.startTimeSecs,
                      eventVecs: subTask.progress.eventVec ||
                        ($scope.pollData.resultGroupVec &&
                          $scope.pollData.resultGroupVec[0].taskVec[0].progress.eventVec) ||
                        [],
                      message: object.error && object.error.errorMsg,
                    };

                    if (object.warnings && object.warnings.length) {
                      object._progress.message =
                        object.warnings.concat(object._progress.message);
                    } else {
                      object._progress.message = [object._progress.message];
                    }

                    // Add publicStatus on object
                    addPublicStatus(object);

                    object._subTasks = subTask;

                    return true;
                  }
                );
              } else if ([ENUM_RESTORE_TYPE.kMountVolumes,
                ENUM_RESTORE_TYPE.kMountFileVolume,
                ENUM_RESTORE_TYPE.kSystem,
                ENUM_RESTORE_TYPE.kRecoverDisks].includes($scope.restoreTaskType)) {
                // kSystem, kMountVolumes, kMountFileVolume and kRecoverDisks
                // progress is a bit different. Update each object in the
                // restoreTask with progress info.
                $scope.objects.forEach(function eachObject(object) {
                  // Finally update the object with all this new info
                  angular.extend(object, {
                    _normalizedEntity: object._normalizedEntity ||
                      SourceService.normalizeEntity(object.entity),
                    _subTasks: subTask,
                    _progress: {
                      percentage: subTask.progress.percentFinished,
                      barLabel: percentFinishedString,
                      statusLabel: endTimeString,
                      endTimeSecs: subTask.progress.endTimeSecs,
                      startTimeSecs: subTask.progress.startTimeSecs,
                      eventVecs: subTask.progress.eventVec ||
                        ($scope.pollData.resultGroupVec &&
                         $scope.pollData.resultGroupVec[0].taskVec[0].progress.eventVec) ||
                         $scope.pollData.progress.eventVec ||
                        [],
                      message: object.error && object.error.errorMsg,
                    }
                  });

                  // Add publicStatus on object
                  addPublicStatus(object);
                });
              }
            }
          );

          // kRecoverVolumes progress is different. Update each object in the
          // $scope.volumeRecoverSubTasks with progress info.
          if ($scope.restoreTaskType === 12) {
            updateVolumeRecoverSubTasks(subTasks);
          }

          $scope.totals = angular.copy(tmpTotals);

          // Check the tear down condition again when task is updated
          $scope.canTearDownTask =
            RestoreService.canTearDownTask($scope.restoreTask);
        });
      }
    }

    /**
     * Updates progress monitoring data for each volume recover sub task
     * based on results from poll.
     *
     * @method    updateVolumeRecoverSubTasks
     */
    function updateVolumeRecoverSubTasks(subTasks) {
      // Lookup map to get the sub task of volume recover from its guuid
      var volumeRecoverSubTasksProgressLookups;

      volumeRecoverSubTasksProgressLookups =
        getVolumeRecoverSubTasksLookups(subTasks);

      // If lookup is empty, exit early.
      if (_.keys(volumeRecoverSubTasksProgressLookups).length === 0) {
        return;
      }

      $scope.volumeRecoverSubTasks.forEach(function forEach(task) {
        var percentFinishedString = '';
        var endTimeString = '';
        var subTask = volumeRecoverSubTasksProgressLookups[task.srcGuid];

        // Update the percentFinished string
        percentFinishedString = [
          cUtils.round(subTask.progress.percentFinished),
          $scope.text.completed
        ].join('% ');

        // Update the endTImeString if still in progress
        if (subTask.progress.expectedTimeRemainingSecs > 0) {
          endTimeString = [
            DateTimeService.secsToTime(
              subTask.progress.expectedTimeRemainingSecs).time,
            $scope.text.remaining
          ].join(' ');
        }

        angular.extend(task, {
          status: taskStatusConverter(subTask.progress.status.type),
          error: subTask._error,
          message: subTask._error && subTask._error.errorMsg,
          _progress: {
            percentage: subTask.progress.percentFinished,
            barLabel: percentFinishedString,
            statusLabel: endTimeString,
            endTimeSecs: subTask.progress.endTimeSecs,
            startTimeSecs: subTask.progress.startTimeSecs,
            durationSecs: (subTask.progress.endTimeSecs -
                subTask.progress.startTimeSecs),
          }
        });

      });
    }

    // Watch the pollData object for changes from getTasks
    $scope.$watchCollection('pollData', updateSubTasks);

    /**
     * Converts progress monitor TaskStatus enum type
     * to RestoreEntityStatus enum type
     * Progress Monitor uses a different enum type key to distinguish status of tasks
     * @param  {int} status returned from progress monitor
     * @return {int}        transformed int for RestoreEntityStatus key
     */
    function taskStatusConverter(status) {
      switch (status) {
        case 0:
          // Task is still running
          status = 1;
          break;
        case 1:
          // Task succeded
          status = 4;
          break;
        case 2:
          // Task failed
          status = 5;
          break;
        case 3:
          // Task failed
          status = 5;
      }
      return status;
    }

    /**
     * Compares the status property of a status object
     * Updates tmpTotals object for display purposes
     * @param  {Object} statusObject
     * @param  {Boolean} isFileRecovery this recover task is of type kRecoverFiles
     * @return {Void}
     */
    function incrementTotalsCount(statusObject, isFileRecovery) {
      if (isFileRecovery) {
        // File Recovery Tasks
        // We display only success or error totals in the 'at a glance' bar
        if (statusObject.status === 2) {
          if (statusObject.error) {
            tmpTotals.errors++;
          } else {
            tmpTotals.success++;
          }
        }

      } else {
        // VM/SQL Recovery Tasks
        // We display running, success, and error in the 'at a glance' bar
        switch (statusObject.status) {
          case 4:
            if (statusObject.error) {
              tmpTotals.errors++;
              break;
            }
            tmpTotals.success++;
            break;
          case 5:
            tmpTotals.errors++;
            break;
          default:
            tmpTotals.running++;
        }
      }
    }

    /**
     * Toggles the taskId of expanded rows in $scope.expandedRows
     * @param {string}  uuid, uuid of a task row to be expanded/collapsed
     */
    $scope.toggleExpandedRow = function toggleExpandedRow(uuid) {
      if ($scope.expandedRows[uuid]) {
        delete $scope.expandedRows[uuid];
      } else {
        $scope.expandedRows[uuid] = true;
      }
    };

    /**
     * Callback for file recovery progress monitoring API call
     * @param  {Object} r response from pulse
     */
    $scope.restoreTaskCallback = function restoreTaskCallback(r) {
      if (r.progress && r.progress.eventVec) {
        // Set SubTask Event Vecs if they are present
        $scope.restoreTaskSubTaskVec = r.progress.eventVec;
      }

      if (['completed', 'error'].includes(r)) {
        // We done here, let's git to getting. Refresh task data from Magneto
        getTaskDetailsOnTimeout();
      }
    };

    /**
     * Callback for sql or active directory recovery progress monitoring API call
     * @param  {Object} r response from pulse
     */
    $scope.appTaskCallback = function appTaskCallback(r) {
      if (r.progress && r.progress.eventVec) {
        // Set SubTask Event Vecs if they are present
        $scope.restoreTaskSubTaskVec = r.progress.eventVec;
      }

      if (typeof(r) === 'string') {
        // We done here, let's hide the progress monitor
        $scope.restoreTaskEndpoint = null;
      }
    };

    /**
     * Mutli-stage Restore/DB Migration specific initialization routine.
     *
     * @funciton   _initDbMigration
     */
    function _initDbMigration() {
      // Sets up ui-router tabs for this view.
      $ctrl.dbMigrationTabsConfig = [
        {
          heading: $translate.instant('details'),
          route: 'recover-detail-local.db-migration-details',
          params: {
            taskId: $scope.restoreTask.performRestoreTaskState.base.taskId,
            migrationTask: $scope.restoreTask,
          },
        },
        {
          heading: $translate.instant('settings'),
          route: 'recover-detail-local.db-migration-settings',
          params: {
            restoreTask: $scope.restoreTask,
          },
        },
      ];

      // Set the entry page.
      if ($state.current.name === 'recover-detail-local') {
        $state.go('recover-detail-local.db-migration-details',
          {
            taskId: $scope.restoreTask.performRestoreTaskState.base.taskId,
            migrationTask: $scope.restoreTask,
          }
        );
      }
    }

    /**
     * Restore vmdk specific initialization routine.
     *
     * @funciton   _initVmdk
     */
    function _initVmdk() {
      $ctrl.vmdkTabsConfig = [{
        headingKey: 'details',
        route: 'recover-detail-local.vmdk-details',
      }, {
        headingKey: 'activityLog',
        route: 'recover-detail-local.vmdk-log',
      }];

      // Set the entry page.
      if ($state.current.name === 'recover-detail-local') {
        $state.go('recover-detail-local.vmdk-details');
      }
    }
  }

})(angular);
