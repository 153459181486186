/**
 * The App status of the data source
 */
export enum AppStatus {
  // The cluster is connected to helios
  connected = 'connected',
  // The data source is enabled for smartfiles shield and logs are getting pulled.
  enabled = 'enabled',
  // The data source is getting enabled and one time pull is in progress.
  enabling = 'enabling',
  // The DataHawk Engines is installed and data source is not enabled for smartfiles shield.
  installed = 'installed',
  // The DataHawk Engines is getting installed and data source is getting enabled for smartfiles shield.
  installing = 'installing',
  // The cluster is not connected to helios
  notConnected = 'notConnected',
}

/**
 * The sorting order for AppStatus
 */
export const appStatusSortOrder: {[key in AppStatus]: number} = {
  [AppStatus.enabled]: 6,
  [AppStatus.enabling]: 5,
  [AppStatus.installed]: 4,
  [AppStatus.installing]: 3,
  [AppStatus.connected]: 2,
  [AppStatus.notConnected]: 1,
};

/**
 * The list of sorted app status values
 *
 * NOTE: sorted as per appStatusSortOrder
 */
export const sortedAppStatus = (Object.keys(AppStatus) as AppStatus[]).sort((a, b) => {
  // ordered as per appStatusSortOrder
  if (appStatusSortOrder[a] < appStatusSortOrder[b]) {
    return 1;
  }
  if (appStatusSortOrder[a] > appStatusSortOrder[b]) {
    return -1;
  }
  return 0;
});

/**
 * The app status for which data source is already enabled or getting enabled.
 */
export const enabledOrEnablingStatus = new Set([AppStatus.enabled, AppStatus.enabling]);

/**
 * The set of app status represents not started status.
 */
export const notStartedStatus = new Set([
  AppStatus.enabled,
  AppStatus.enabling,
  AppStatus.installed,
  AppStatus.installing,
]);
