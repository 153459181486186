<div class="form" [formGroup]="formGroup">
    <div class="header">
        <h4>{{ 'generateCohesityCertificateInterface.subtitle' | translate }}</h4>
        <a href="/" class="link">{{ 'learnMore' | translate}}</a>
    </div>

    <mat-form-field class="input margin-top">
        <mat-label>{{ 'generateCohesityCertificateInterface.commonName' | translate }}</mat-label>
        <input matInput formControlName="commonName">
        <mat-error *ngIf="formGroup.get('commonName').hasError('required')">
            {{ 'errors.required' | translate }}
        </mat-error>
    </mat-form-field>

    <mat-form-field class="input">
        <mat-label>{{ 'generateCohesityCertificateInterface.subjectAlternativeValues' | translate}}</mat-label>
        <mat-chip-list #subjectiveAlternativeNameList>
            <mat-chip
                *ngFor="let name of subjectiveAlternativeNameValues; index as i"
                cogDataId="subjective-alternative-name-input-chip-{{i}}"
                [inheritDataId]="true"
                (removed)="removeSubjectiveAlternativeName(i)"
            >
                {{name}}
                <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
            <input
                matInput
                [matChipInputFor]="subjectiveAlternativeNameList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                [matChipInputAddOnBlur]="true"
                (matChipInputTokenEnd)="addSubjectiveAlternativeName($event)"
                (paste)="pasteSubjectiveAlternativeNameInput($event)"
                (blur)="validateSubjectiveAlternativeNameFormControl()"
                contenteditable='true'
                cogDataId="subjective-alternative-name-input"
                [inheritDataId]="true">
            <mat-error *ngIf="formGroup.get('subjectiveAlternativeName').hasError('required')">
                {{ 'errors.required' | translate }}
            </mat-error>
        </mat-chip-list>
    </mat-form-field>

    <div>
        <div class="validity-date">
            <mat-form-field class="input validity">
                <mat-label>{{ 'generateCohesityCertificateInterface.validity' | translate}}</mat-label>
                <input
                    matInput
                    formControlName="validity"
                    type="number">
                <mat-error *ngIf="formGroup.get('validity').hasError('required')">
                    {{ 'errors.required' | translate }}
                </mat-error>
            </mat-form-field>
            <mat-form-field class="date-select">
                <mat-label>{{ 'errors.required' | translate }}</mat-label>
                <mat-select formControlName="period">
                    <mat-option
                        class="option-list"
                        *ngFor="let option of periods"
                        [value]="option">
                        {{option}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="validity-info">
            {{ 'generateCohesityCertificateInterface.validityInfo' | translate}}
        </div>
    </div>

    <mat-form-field class="input margin-top">
        <mat-label>{{ 'generateCohesityCertificateInterface.privateKey' | translate}}</mat-label>
        <input matInput formControlName="privateKey">
    </mat-form-field>

    <mat-form-field class="input">
        <mat-label>{{ 'generateCohesityCertificateInterface.organization' | translate }}</mat-label>
        <input matInput formControlName="organization">
        <mat-error *ngIf="formGroup.get('organization').hasError('required')">
            {{ 'errors.required' | translate }}
        </mat-error>
    </mat-form-field>

    <mat-form-field class="input">
        <mat-label>{{ 'generateCohesityCertificateInterface.departament' | translate }}</mat-label>
        <input matInput formControlName="department">
        <mat-error *ngIf="formGroup.get('department').hasError('required')">
            {{ 'errors.required' | translate }}
        </mat-error>
    </mat-form-field>

    <mat-form-field class="input">
        <mat-label>{{ 'generateCohesityCertificateInterface.city' | translate }}</mat-label>
        <input matInput formControlName="city">
        <mat-error *ngIf="formGroup.get('city').hasError('required')">
            {{ 'errors.required' | translate }}
        </mat-error>
    </mat-form-field>

    <mat-form-field class="input">
        <mat-label>{{ 'generateCohesityCertificateInterface.state' | translate }}</mat-label>
        <input matInput formControlName="state">
        <mat-error *ngIf="formGroup.get('state').hasError('required')">
            {{ 'errors.required' | translate }}
        </mat-error>
    </mat-form-field>

    <mat-form-field class="input">
        <mat-label>{{ 'generateCohesityCertificateInterface.country' | translate }}</mat-label>
        <input matInput formControlName="country">
        <mat-error *ngIf="formGroup.get('country').hasError('required')">
            {{ 'errors.required' | translate }}
        </mat-error>
    </mat-form-field>
</div>