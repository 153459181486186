/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityV2ApiConfiguration as __Configuration } from '../cohesity-v2api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { StaticRoutes } from '../models/static-routes';
import { StaticRouteParams } from '../models/static-route-params';
@Injectable({
  providedIn: 'root',
})
class RoutesService extends __BaseService {
  static readonly ListStaticRoutesPath = '/network/routes';
  static readonly AddStaticRoutePath = '/network/routes';
  static readonly UpdateStaticRoutePath = '/network/routes/{routeId}';
  static readonly DeleteStaticRoutePath = '/network/routes/{routeId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * List all static routes
   *
   * List the static routes for the Cohesity Cluster.
   * @param params The `RoutesService.ListStaticRoutesParams` containing the following parameters:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `interfaceGroups`: Specifies the network interfaces name to filter.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  ListStaticRoutesResponse(params: RoutesService.ListStaticRoutesParams): __Observable<__StrictHttpResponse<StaticRoutes>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    (params.interfaceGroups || []).forEach(val => {if (val != null) __params = __params.append('interfaceGroups', val.toString())});
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/network/routes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<StaticRoutes>;
      })
    );
  }
  /**
   * List all static routes
   *
   * List the static routes for the Cohesity Cluster.
   * @param params The `RoutesService.ListStaticRoutesParams` containing the following parameters:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `interfaceGroups`: Specifies the network interfaces name to filter.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  ListStaticRoutes(params: RoutesService.ListStaticRoutesParams): __Observable<StaticRoutes> {
    return this.ListStaticRoutesResponse(params).pipe(
      __map(_r => _r.body as StaticRoutes)
    );
  }

  /**
   * Configure a static route
   *
   * Configure a static route on an interface.
   * @param params The `RoutesService.AddStaticRouteParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to configure a static route on an interface.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  AddStaticRouteResponse(params: RoutesService.AddStaticRouteParams): __Observable<__StrictHttpResponse<StaticRouteParams>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/network/routes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<StaticRouteParams>;
      })
    );
  }
  /**
   * Configure a static route
   *
   * Configure a static route on an interface.
   * @param params The `RoutesService.AddStaticRouteParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to configure a static route on an interface.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  AddStaticRoute(params: RoutesService.AddStaticRouteParams): __Observable<StaticRouteParams> {
    return this.AddStaticRouteResponse(params).pipe(
      __map(_r => _r.body as StaticRouteParams)
    );
  }

  /**
   * Update a static route
   *
   * Update static route details.
   * @param params The `RoutesService.UpdateStaticRouteParams` containing the following parameters:
   *
   * - `routeId`: Specify the unique identifier for the route.
   *
   * - `body`: Specifies the update request parameters.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateStaticRouteResponse(params: RoutesService.UpdateStaticRouteParams): __Observable<__StrictHttpResponse<StaticRouteParams>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/network/routes/${encodeURIComponent(params.routeId)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<StaticRouteParams>;
      })
    );
  }
  /**
   * Update a static route
   *
   * Update static route details.
   * @param params The `RoutesService.UpdateStaticRouteParams` containing the following parameters:
   *
   * - `routeId`: Specify the unique identifier for the route.
   *
   * - `body`: Specifies the update request parameters.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateStaticRoute(params: RoutesService.UpdateStaticRouteParams): __Observable<StaticRouteParams> {
    return this.UpdateStaticRouteResponse(params).pipe(
      __map(_r => _r.body as StaticRouteParams)
    );
  }

  /**
   * Delete a static route
   *
   * Delete a static route on a network interface.
   * @param params The `RoutesService.DeleteStaticRouteParams` containing the following parameters:
   *
   * - `routeId`: Specify the unique identifier for the route.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteStaticRouteResponse(params: RoutesService.DeleteStaticRouteParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/network/routes/${encodeURIComponent(params.routeId)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete a static route
   *
   * Delete a static route on a network interface.
   * @param params The `RoutesService.DeleteStaticRouteParams` containing the following parameters:
   *
   * - `routeId`: Specify the unique identifier for the route.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteStaticRoute(params: RoutesService.DeleteStaticRouteParams): __Observable<null> {
    return this.DeleteStaticRouteResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module RoutesService {

  /**
   * Parameters for ListStaticRoutes
   */
  export interface ListStaticRoutesParams {

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Specifies the network interfaces name to filter.
     */
    interfaceGroups?: Array<string>;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for AddStaticRoute
   */
  export interface AddStaticRouteParams {

    /**
     * Specifies the parameters to configure a static route on an interface.
     */
    body: StaticRouteParams;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateStaticRoute
   */
  export interface UpdateStaticRouteParams {

    /**
     * Specify the unique identifier for the route.
     */
    routeId: string;

    /**
     * Specifies the update request parameters.
     */
    body: StaticRouteParams;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for DeleteStaticRoute
   */
  export interface DeleteStaticRouteParams {

    /**
     * Specify the unique identifier for the route.
     */
    routeId: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { RoutesService }
