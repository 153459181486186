import { NgModule } from '@angular/core';

import { RouterLinkAnchorDirective, RouterLinkDirective } from './router-link/router-link.directive';

/**
 * App Module. This is the root angular module.
 */
@NgModule({
  declarations: [RouterLinkDirective, RouterLinkAnchorDirective],
  exports: [RouterLinkDirective, RouterLinkAnchorDirective],
})
export class IrisRouterModule {}
