<form [formGroup]="formGroup" cogDataId="update-password-dialog" (ngSubmit)="updatePassword()">

  <!-- Dialog title -->
  <h1 mat-dialog-title>
    {{(dialogData.isPasswordSet ? 'updatePassword' : 'setPassword') | translate}}</h1>
  <h3>{{'username' | translate}} - {{userName}}</h3>

  <!-- Dialog content -->
  <mat-dialog-content>
    <mat-form-field *ngIf="dialogData.isPasswordSet">
      <mat-label translate>currentPassword</mat-label>
      <input matInput type="password" cogDataId="current-password"
        [inheritDataId]="true" formControlName="currentPassword">
      <mat-error cogDataId="current-password-error-required" *ngIf="formGroupErrors?.currentPassword?.required">
        {{'errors.required' | translate}}
      </mat-error>
      <mat-error cogDataId="current-password-error-minlength" *ngIf="formGroupErrors?.currentPassword?.minlength">
        <span>{{ 'passwordMinNum' | translate: {minPassword: 15} }}</span>
      </mat-error>
    </mat-form-field>

    <coh-change-password-form
      formControlName="newPassword"
      [minPasswordLength]="15"
      [passwordRulesPopover]="popoverComponent"
      [popoverData]="popoverData">
    </coh-change-password-form>
  </mat-dialog-content>

  <!-- Dialog footer with buttons -->
  <mat-dialog-actions align="end">
    <button type="button" cogDataId="cancel-button" [inheritDataId]="true" color="primary"
      cogCancelButton mat-button mat-dialog-close>
      {{'cancel' | translate}}
    </button>
    <button type="button" cogDataId="confirm-button" color="primary"
      [loading]="loading" [disabled]="loading"
      [inheritDataId]="true" cogSubmitButton mat-raised-button>
      {{(dialogData.isPasswordSet ? 'update' : 'set') | translate}}
    </button>
  </mat-dialog-actions>
</form>
