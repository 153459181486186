// Component: GlobalSearchSnapshot

;(function(angular, undefined) {
  'use strict';

  angular.module('C.globalSearch')
    .controller('GlobalSearchSnapshotCtrl', GlobalSearchSnapshotCtrlFn)
    .component('globalSearchSnapshot', {
      bindings: {
        // @type {Object}  The selected result as provided via global search.
        selectedResult: '<',
      },
      controller: 'GlobalSearchSnapshotCtrl',
      templateUrl:
        'app/global-search/global-search-snapshot/global-search-snapshot.html',
    });

  function GlobalSearchSnapshotCtrlFn(moment, $timeout, GlobalSearchService) {
    var $ctrl = this;

    // Controller methods
    $ctrl.calendarDateChange = calendarDateChange;
    $ctrl.getPreviousOrNextTimestamp = getPreviousOrNextTimestamp;
    $ctrl.selectPreviousOrNext = selectPreviousOrNext;

    /**
     * Function to load the previous or next month's calendar when the first or
     * last day of the month, respectively, is selected. This is used for
     * previous/next arrows for the c-snapshot-picker.
     */
    function calendarDateChange() {
      var calendarDate = moment($ctrl.selectedResult.calendarDate);
      var calendarDateValue = calendarDate.valueOf();
      var isFirst =
        moment(calendarDate).startOf('month').valueOf() === calendarDateValue;
      var isLast =
        moment(calendarDate).endOf('month').valueOf() === calendarDateValue;

      if (isFirst) {
        // If first day, go to previous month, and then using moment, adjust
        // range to have the entire month.
        calendarDate.subtract(1, 'days');
      }

      if (isLast) {
        // Do the opposite if last day.
        calendarDate.add(1, 'days');
      }

      if (isFirst || isLast) {
        GlobalSearchService.getSnapshotsForTimeRange(
          calendarDate.startOf('month').toUsecDate(),
          calendarDate.endOf('month').toUsecDate()
        );
      }
    }

    /**
     * Function to return the previous or next day's timestamp of the selected
     * calendar date
     *
     * @param     {string}   direction   One of 'previous' or 'next'
     * @returns   {number}               Milliseconds timestamp can be passed to
     *                                   Date constructor
     */
    function getPreviousOrNextTimestamp(direction) {
      var dateObj = moment($ctrl.selectedResult.calendarDate);

      if (direction === 'previous') {
        dateObj.subtract(1, 'day');
      }

      if (direction === 'next') {
        dateObj.add(1, 'day');
      }

      return dateObj.valueOf();
    }

    /**
     * Function to select the previous or next date in context of the current
     * selected date for the c-snapshot-picker
     *
     * @param   {string}   direction   One of 'previous' or 'next'
     */
    function selectPreviousOrNext(direction) {
      var timestamp = getPreviousOrNextTimestamp(direction);

      if ($ctrl.selectedResult.usableDatesMap[timestamp]) {
        // Only select the timestamp if snapshots for that timestamp exist
        _selectResultCalendarDate(timestamp);
      }
    }


    /**
     * Function to update the c-snapshot-picker component with a new date.
     * This is used when going previous or next within the c-snapshot-picker.
     *
     * @param   {number}   startTimeMsecs   Milliseconds timestamp of new
     *                                      date to select
     */
    function _selectResultCalendarDate(startTimeMsecs) {
      var dateObj = new Date(startTimeMsecs);

      // Just updating the calendarDate reference in c-snapshot-picker does not
      // work. So remove the component by setting the calendarDate to null,
      // and then add it again immediately by setting the calendarDate to
      // the new date.
      $ctrl.selectedResult.calendarDate = null;

      // Maintain this loading variable to prevent visual jumps
      $ctrl.selectedResult.loadingSnapshots = true;

      $timeout(function updateSelectedResultCalendarDate() {
        $ctrl.selectedResult.calendarDate = dateObj;
        $ctrl.selectedResult.loadingSnapshots = false;
        calendarDateChange();
      }, 0);
    }
  }
})(angular);
