<form [formGroup]="formGroup">
  <div class="form-container flex-column">
    <div class="form-container-align-bottom flex-column">
      <!-- Dns Server input -->
      <mat-form-field class="dns-name-field margin-right-sm">
        <mat-label>{{'zoneName' | translate}}</mat-label>
        <input cogDataId="'dns-server-input-' + {{index}}" matInput formControlName="dnsServer">
        <mat-error *ngIf="formGroup?.controls?.dnsServer?.errors?.required">
          {{'errors.required' | translate}}
        </mat-error>
      </mat-form-field>

      <!-- List of Server VIP addresses. -->
      <mat-form-field class="dns-name-field margin-left-sm margin-right-sm">
        <mat-label>{{ 'dnsServerVips' | translate }}</mat-label>
        <mat-chip-list #vipsList formControlName="dnsServerVips">
          <mat-chip *ngFor="let vipAdress of formGroup?.controls?.dnsServerVips?.value; index as i"
            cogDataId="'vip-input-chip' + {{i}}"
            (removed)="remove(vipAdress)">
            {{ vipAdress }}
            <cog-icon size="sm" shape="close" matChipRemove class="chip-close-icon"></cog-icon>
          </mat-chip>
          <input matInput [inheritDataId]="true"
            [matChipInputFor]="vipsList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            [matChipInputAddOnBlur]="true"
            (matChipInputTokenEnd)="addVip($event)"
            (paste)="pasteVipsInput($event)"
            contenteditable='true'
            cogDataId="vip-input">
        </mat-chip-list>
        <mat-error *ngIf="formGroup?.controls?.dnsServerVips?.errors?.ipError">
          {{'errors.patterns.IP' | translate}}
        </mat-error>
        <mat-error *ngIf="formGroup?.controls?.dnsServerVips?.errors?.required">
          {{'errors.required' | translate}}
        </mat-error>
      </mat-form-field>

      <!-- List of resolved VIP addresses. -->
      <mat-form-field class="dns-name-field margin-left-sm margin-right-sm">
        <mat-label>{{ 'resolvedVips' | translate }}</mat-label>
        <mat-chip-list #resolvedVipsList formControlName="resolvedVips">
          <mat-chip *ngFor="let vipAdress of formGroup?.controls?.resolvedVips?.value; index as i"
            cogDataId="'resolved-vip-input-chip' + {{i}}"
            [selectable]="true"
            (removed)="remove(vipAdress, true)">
            {{ vipAdress }}
            <cog-icon size="sm" shape="close" matChipRemove class="chip-close-icon"></cog-icon>
          </mat-chip>
          <input matInput [inheritDataId]="true"
            [matChipInputFor]="resolvedVipsList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            [matChipInputAddOnBlur]="true"
            (matChipInputTokenEnd)="addVip($event, true)"
            (paste)="pasteVipsInput($event, true)"
            contenteditable='true'
            cogDataId="resolved-vip-input">
        </mat-chip-list>
        <mat-error *ngIf="formGroup?.controls?.resolvedVips?.errors?.ipError">
          {{'errors.patterns.IP' | translate}}
        </mat-error>
      </mat-form-field>
    </div>
  </div>
</form>
