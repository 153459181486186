import { Clipboard } from '@angular/cdk/clipboard';
import { Component, Input } from '@angular/core';
import { SnackBarService } from '@cohesity/helix';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'coh-dms-token',
  templateUrl: './dms-token.component.html',
  styleUrls: ['./dms-token.component.scss']
})
export class DmsTokenComponent {

  /**
   * Holds the value to be rendered.
   */
  @Input() value: any;

  /**
   * Placeholder to be shown if value is not supplied.
   */
  @Input() placeholder = 'waitingForData';

  /**
   * Custom file name to be used for downloading.
   */
  @Input() fileName = 'data.txt';

  /**
   * Hide the download button.
   */
  @Input() hideDownload = false;

  /**
   * Application type for downloading content.
   */
  @Input() applicationType = 'text/plain';

  /**
   * Display token timeout timer in seconds.
   */
  @Input() tokenTimeout: number;

  /**
   * constructor
   */
  constructor(
    private clipboard: Clipboard,
    private snackBarService: SnackBarService,
    private translateService: TranslateService,
  ) { }

  /**
   * Copies the content to clipboard.
   */
  copy() {
    if (!this.value) {
      return;
    }

    this.clipboard.copy(String(this.value));
    const msg = this.translateService.instant('copiedToClipboard', {
      item: 'Data',
    });
    this.snackBarService.open(msg, 'success');
  }

  /**
   * Triggers the download of a file.
   */
  download() {
    if (!this.value) {
      return;
    }

    // Encode the data to be downloaded.
    const encodedValue = encodeURIComponent(this.value);
    const downloadData = `data:${this.applicationType};charset=utf-8,${encodedValue}`;

    // Construct the download anchor tag.
    const element = document.createElement('a');
    element.setAttribute('href', downloadData);
    element.setAttribute('download', this.fileName);
    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();
    document.body.removeChild(element);
  }
}
