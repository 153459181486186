import { NavItem } from '@cohesity/helix';
import { flagEnabled, IrisContext } from '@cohesity/iris-core';
import { TranslateService } from '@ngx-translate/core';

/**
 * Generates the navigation list for Sizer/Platform Insights based on the feature enablement.
 *
 * @param translate translation service
 * @param irisCtx iris context
 * @returns navigation list for Sizer/Platform Insights
 */
export const getNavList = (translate: TranslateService, irisCtx: IrisContext): NavItem[] => {
  const navItems: NavItem[] = [
    flagEnabled(irisCtx, 'liveSizerEnabled') && {
      displayName: translate.instant('forecast'),
      icon: 'insights',
      subNavList: [
        flagEnabled(irisCtx, 'liveSizerEnabled') && {
          displayName: translate.instant('capacityPlanning'),
          href: '/resource-planning',
        },
        flagEnabled(irisCtx, 'simulationEnabled') && {
          displayName: translate.instant('simulation'),
          state: 'simulation.list',
          href: '/../monitoring/simulation/list',
        },
      ],
    },
    !flagEnabled(irisCtx, 'liveSizerEnabled') && flagEnabled(irisCtx, 'simulationEnabled') && {
      displayName: translate.instant('simulation'),
      state: 'simulation.list',
      href: '/../monitoring/simulation/list',
      icon: 'insights',
    },
    {
      displayName: translate.instant('reports'),
      state: 'reporting.list',
      href: '/../reporting/list',
      icon: 'assessment!outline',
      activeStates: ['reporting.detail', 'reporting.schedule'],
    }].filter(Boolean) as NavItem[];

  return navItems;
};
