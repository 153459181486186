import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IrisContextService, appConfigMap, isDmsScope } from '@cohesity/iris-core';

import { HasCustomRBACPermissions } from '@cohesity/data-govern/shared';
import { supportedEnvironment, supportedRecoveryAction } from '../snapshot-recovery.constants';
import { DialogData } from '../snapshot-recovery.model';

@Component({
  selector: 'dg-td-snapshot-recovery-plugin',
  templateUrl: './snapshot-recovery-plugin.component.html',
  styleUrls: ['./snapshot-recovery-plugin.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SnapshotRecoveryPluginComponent {
  @Input() data: DialogData;

  /**
   * path to redirect to data govern
   */
  readonly dataGovernPath = `${appConfigMap.securityCenter.urlSegment}/threat-detection`;

  /**
   * Indicates weather snapshot recovery plugin is supported for a given user, environment and recovery action.
   */
  get isSupported() {
    return HasCustomRBACPermissions(['DGAAS_VIEW_THREAT_SCAN', 'DGAAS_VIEW'], this.irisCtx.irisContext) &&
      !isDmsScope(this.irisCtx.irisContext) &&
      supportedRecoveryAction.has(this.data?.recoveryAction) &&
      supportedEnvironment.has(this.data?.object?.objectInfo?.environment);
  }

  constructor(private irisCtx: IrisContextService) { }

}
