<h1 mat-dialog-title translate>switchAccount</h1>

<div mat-dialog-content>
  <coh-company-account-table
    *ngIf="!isSupportUser"
    [selection]="selection"
    [accounts]="accounts$ | async"
  ></coh-company-account-table>
  <ng-container *ngIf="isSupportUser">
    <mat-form-field>
      <input matInput
        cogDataId="input-switch-account-dialog-case-id"
        [formControl]="caseIdControl"
        [placeholder]="'supportCaseId' | translate"
        type="text">
    </mat-form-field>
    <span *ngIf="errorMessage" class="mat-error">
        {{errorMessage}}
    </span>
  </ng-container>
</div>

<div mat-dialog-actions align="end">
  <button
    cogDataId="btn-switch-account-dialog-close"
    mat-button
    mat-dialog-close
    cdkFocusInitial>
    {{ 'cancel' | translate }}
  </button>
  <button
    cogDataId="btn-switch-account-dialog"
    mat-flat-button
    color="primary"
    [disabled]="(isSupportUser && !caseIdControl.value) || (!isSupportUser && !selection.selected?.length)"
    (click)="switchAccount()">
    {{ 'switch' | translate }}
  </button>
</div>
