/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ResourceId } from '../models/resource-id';
import { ResourceType } from '../models/resource-type';
import { Resources } from '../models/resources';

@Injectable({
  providedIn: 'root',
})
export class ResourcesApiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getResources
   */
  static readonly GetResourcesPath = '/resources';

  /**
   * Gets the list of hierarchical authorization resources.
   *
   * This API is used for fetching details of all resources that can be used for creating access scopes. These resources are added internally when respective resource is created or registered with Helios. This API can be used for constructing the complete authorization hierarchy of the available resources in Helios.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getResources()` instead.
   *
   * This method doesn't expect any request body.
   */
  getResources$Response(params: {

    /**
     * Specifies the unique account identifier of the user&#x27;s Salesforce account.
     */
    account_id: string;

    /**
     * Specifies the unique identifier for the user&#x27;s tenant ID. This field is mandatory if resources are fetched for BaaS entities.
     */
    tenant_id?: string;

    /**
     * Specifies list of resource identifiers for which the resources need to be fetched. This will only return the matching list resources from authorization hierarchy. To include the entire sub tree nodes for given resource ID, clients must include &#x27;includeChildHierarchy&#x27; as true. The &#x27;resourceTypes&#x27; filter must not be set if &#x27;resourceIds&#x27; are provided. If both filters are provided then API request will throw a validation error.
     */
    resourceIds?: null | Array<ResourceId>;

    /**
     * Specifies the list of resource types. Based on provided types, only nodes belonging of the provided type will be returned. To include the entire sub tree nodes for given resource type, clients must include &#x27;includeChildHierarchy&#x27; as true. The &#x27;resourceIds&#x27; filter must not be set if &#x27;resourceTypes&#x27; are provided. If both filters are provided then API request will throw a validation error.
     */
    resourceTypes?: Array<ResourceType>;

    /**
     * Setting this true will include entire sub tree nodes from the provided resourceIds or resourceTypes. This filter is ignored if both resourceIds and resourceTypes are empty.
     */
    includeChildHierarchy?: boolean;

  }): Observable<StrictHttpResponse<Resources>> {

    const rb = new RequestBuilder(this.rootUrl, ResourcesApiService.GetResourcesPath, 'get');
    if (params) {

      rb.header('account_id', params.account_id, {});
      rb.header('tenant_id', params.tenant_id, {});
      rb.query('resourceIds', params.resourceIds, {});
      rb.query('resourceTypes', params.resourceTypes, {});
      rb.query('includeChildHierarchy', params.includeChildHierarchy, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Resources>;
      })
    );
  }

  /**
   * Gets the list of hierarchical authorization resources.
   *
   * This API is used for fetching details of all resources that can be used for creating access scopes. These resources are added internally when respective resource is created or registered with Helios. This API can be used for constructing the complete authorization hierarchy of the available resources in Helios.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getResources$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getResources(params: {

    /**
     * Specifies the unique account identifier of the user&#x27;s Salesforce account.
     */
    account_id: string;

    /**
     * Specifies the unique identifier for the user&#x27;s tenant ID. This field is mandatory if resources are fetched for BaaS entities.
     */
    tenant_id?: string;

    /**
     * Specifies list of resource identifiers for which the resources need to be fetched. This will only return the matching list resources from authorization hierarchy. To include the entire sub tree nodes for given resource ID, clients must include &#x27;includeChildHierarchy&#x27; as true. The &#x27;resourceTypes&#x27; filter must not be set if &#x27;resourceIds&#x27; are provided. If both filters are provided then API request will throw a validation error.
     */
    resourceIds?: null | Array<ResourceId>;

    /**
     * Specifies the list of resource types. Based on provided types, only nodes belonging of the provided type will be returned. To include the entire sub tree nodes for given resource type, clients must include &#x27;includeChildHierarchy&#x27; as true. The &#x27;resourceIds&#x27; filter must not be set if &#x27;resourceTypes&#x27; are provided. If both filters are provided then API request will throw a validation error.
     */
    resourceTypes?: Array<ResourceType>;

    /**
     * Setting this true will include entire sub tree nodes from the provided resourceIds or resourceTypes. This filter is ignored if both resourceIds and resourceTypes are empty.
     */
    includeChildHierarchy?: boolean;

  }): Observable<Resources> {

    return this.getResources$Response(params).pipe(
      map((r: StrictHttpResponse<Resources>) => r.body as Resources)
    );
  }

}
