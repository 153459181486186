import { Injectable } from '@angular/core';

import { BroadcastChannelService } from './broadcast-channel.service';

@Injectable({
  providedIn: 'root'
})
export class AutoAppCloseLogoutService {
  constructor(private broadcastChannelService: BroadcastChannelService) {
  }

  /**
   * Counter to keep track of number of tabs currently opened.
   */
  numOpenedTabs = 0;

  /**
   * Function to setup auto logout of the app when closing the app's browser
   * tab.
   */
  initAutoLogout() {
    if (!navigator || !navigator.sendBeacon) {
      // Navigator is not available on IE.
      return;
    }

    this.numOpenedTabs = Number(localStorage.getItem('numOpenedTabs') || 0);

    localStorage.setItem('numOpenedTabs', String(++this.numOpenedTabs));

    // Update the opened tabs counter.
    window.onstorage = () => {
      this.numOpenedTabs = Number(localStorage.getItem('numOpenedTabs') || 0);
    };

    // Log user out when trying to navigate away from the session or closing
    // the session tab.
    window.addEventListener('beforeunload', () => {
      localStorage.setItem('numOpenedTabs', String(--this.numOpenedTabs));

      if (this.numOpenedTabs !== 0) {
        // Only log the user out if there are no other tabs opened.
        return;
      }

      navigator.sendBeacon('/logout', '');
      this.broadcastChannelService.postMessage('autoAppCloseLogout');
    });
  }
}
