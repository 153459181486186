/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityKeplerApiConfiguration as __Configuration } from '../cohesity-kepler-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { DrPlansResponse } from '../models/dr-plans-response';
import { DrPlan } from '../models/dr-plan';
import { CreateDrPlanRequest } from '../models/create-dr-plan-request';
import { UpdateDrPlanRequest } from '../models/update-dr-plan-request';
import { DataPool } from '../models/data-pool';
import { DrPlanDetails } from '../models/dr-plan-details';
import { GetDrPlanDetailsRequest } from '../models/get-dr-plan-details-request';
import { DrPlanActionResponse } from '../models/dr-plan-action-response';
import { PerformDrPlanActionRequest } from '../models/perform-dr-plan-action-request';
import { DrPlanReadinessTestResponse } from '../models/dr-plan-readiness-test-response';
import { DrPlanExecutionsResponse } from '../models/dr-plan-executions-response';
import { DRPlanProtectionOptions } from '../models/drplan-protection-options';
import { ReadinessHistory } from '../models/readiness-history';
import { ObjectSnapshotInfoResponse } from '../models/object-snapshot-info-response';
import { GetDrPlanObjectSnapshotRequest } from '../models/get-dr-plan-object-snapshot-request';
@Injectable({
  providedIn: 'root',
})
class DisasterRecoveryService extends __BaseService {
  static readonly GetDrPlansPath = '/site-continuity/dr-plans';
  static readonly CreateDrPlanPath = '/site-continuity/dr-plans';
  static readonly GetDrPlanByIdPath = '/site-continuity/dr-plans/{id}';
  static readonly UpdateDrPlanByIdPath = '/site-continuity/dr-plans/{id}';
  static readonly DeleteDrPlanByIdPath = '/site-continuity/dr-plans/{id}';
  static readonly GetDrPlanDataPoolByIdPath = '/site-continuity/dr-plans/{id}/data-pool';
  static readonly GetDrPlanDetailsByIdPath = '/site-continuity/dr-plans/{id}/details';
  static readonly PerformDrPlanActionByIdPath = '/site-continuity/dr-plans/{id}/actions';
  static readonly GetDrPlanReadinessTestsByPlanIdPath = '/site-continuity/dr-plans/{id}/readiness-tests';
  static readonly GetDrPlanExecutionsByPlanIdPath = '/site-continuity/dr-plans/{id}/executions';
  static readonly GetDrPlanProtectionOptionsByIdPath = '/site-continuity/dr-plans/{id}/protection-options';
  static readonly GetReadinessHistoryPath = '/site-continuity/readiness-history';
  static readonly GetDrPlanObjectSnapshotsPath = '/site-continuity/dr-plans/{id}/object-snapshots';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get a list of DR plans
   *
   * Gets a list of the available DR plans as per the filter criteria.
   * @param params The `DisasterRecoveryService.GetDrPlansParams` containing the following parameters:
   *
   * - `statuses`: Filter DR plans by list of statuses.
   *
   * - `states`: Filter DR plans by list of states.
   *
   * - `slaStatus`: Filter DR plans by their SLA statuses.
   *
   * - `siteIds`: Filter DR plans by list of site IDs.
   *
   * - `ids`: Filter DR plans by Id.
   *
   * - `healthStatuses`: Filter DR plans by their health statuses. If this filter is specified then only active DR plans having the specified statuses will be returned.
   *
   * - `datapoolIds`: Specifies the Ids of the datapools to filter.
   *
   * - `datapoolId`: Specifies the Id of the datapool to filter.
   *
   * @return Specifies the list of DR plans.
   */
  GetDrPlansResponse(params: DisasterRecoveryService.GetDrPlansParams): __Observable<__StrictHttpResponse<DrPlansResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.statuses || []).forEach(val => {if (val != null) __params = __params.append('statuses', val.toString())});
    (params.states || []).forEach(val => {if (val != null) __params = __params.append('states', val.toString())});
    if (params.slaStatus != null) __params = __params.set('slaStatus', params.slaStatus.toString());
    (params.siteIds || []).forEach(val => {if (val != null) __params = __params.append('siteIds', val.toString())});
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    (params.healthStatuses || []).forEach(val => {if (val != null) __params = __params.append('healthStatuses', val.toString())});
    (params.datapoolIds || []).forEach(val => {if (val != null) __params = __params.append('datapoolIds', val.toString())});
    if (params.datapoolId != null) __params = __params.set('datapoolId', params.datapoolId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/site-continuity/dr-plans`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DrPlansResponse>;
      })
    );
  }
  /**
   * Get a list of DR plans
   *
   * Gets a list of the available DR plans as per the filter criteria.
   * @param params The `DisasterRecoveryService.GetDrPlansParams` containing the following parameters:
   *
   * - `statuses`: Filter DR plans by list of statuses.
   *
   * - `states`: Filter DR plans by list of states.
   *
   * - `slaStatus`: Filter DR plans by their SLA statuses.
   *
   * - `siteIds`: Filter DR plans by list of site IDs.
   *
   * - `ids`: Filter DR plans by Id.
   *
   * - `healthStatuses`: Filter DR plans by their health statuses. If this filter is specified then only active DR plans having the specified statuses will be returned.
   *
   * - `datapoolIds`: Specifies the Ids of the datapools to filter.
   *
   * - `datapoolId`: Specifies the Id of the datapool to filter.
   *
   * @return Specifies the list of DR plans.
   */
  GetDrPlans(params: DisasterRecoveryService.GetDrPlansParams): __Observable<DrPlansResponse> {
    return this.GetDrPlansResponse(params).pipe(
      __map(_r => _r.body as DrPlansResponse)
    );
  }

  /**
   * Create a DR plan
   *
   * Creates a new DR plan using the specified data pools, scripts and delays.
   * @param body Specifies the parameters to create a new DR plan.
   * @return Response of a successful DR plan creation.
   */
  CreateDrPlanResponse(body: CreateDrPlanRequest): __Observable<__StrictHttpResponse<DrPlan>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/site-continuity/dr-plans`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DrPlan>;
      })
    );
  }
  /**
   * Create a DR plan
   *
   * Creates a new DR plan using the specified data pools, scripts and delays.
   * @param body Specifies the parameters to create a new DR plan.
   * @return Response of a successful DR plan creation.
   */
  CreateDrPlan(body: CreateDrPlanRequest): __Observable<DrPlan> {
    return this.CreateDrPlanResponse(body).pipe(
      __map(_r => _r.body as DrPlan)
    );
  }

  /**
   * Get a DR plan by ID
   *
   * Gets the details of a DR plan using it's ID.
   * @param params The `DisasterRecoveryService.GetDrPlanByIdParams` containing the following parameters:
   *
   * - `id`: Specifies the ID of the DR plan.
   *
   * - `includeViewShareInfo`: If specified as true, then the response includes Cohesity View share info for NFS/SMB/S3 protocol, sourced from active Primary/DR site.
   *
   * @return Specifies the response of a successful get DR plan operation.
   */
  GetDrPlanByIdResponse(params: DisasterRecoveryService.GetDrPlanByIdParams): __Observable<__StrictHttpResponse<DrPlan>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.includeViewShareInfo != null) __params = __params.set('includeViewShareInfo', params.includeViewShareInfo.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/site-continuity/dr-plans/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DrPlan>;
      })
    );
  }
  /**
   * Get a DR plan by ID
   *
   * Gets the details of a DR plan using it's ID.
   * @param params The `DisasterRecoveryService.GetDrPlanByIdParams` containing the following parameters:
   *
   * - `id`: Specifies the ID of the DR plan.
   *
   * - `includeViewShareInfo`: If specified as true, then the response includes Cohesity View share info for NFS/SMB/S3 protocol, sourced from active Primary/DR site.
   *
   * @return Specifies the response of a successful get DR plan operation.
   */
  GetDrPlanById(params: DisasterRecoveryService.GetDrPlanByIdParams): __Observable<DrPlan> {
    return this.GetDrPlanByIdResponse(params).pipe(
      __map(_r => _r.body as DrPlan)
    );
  }

  /**
   * Update a DR plan by ID
   *
   * Updates the details of a DR plan using it's ID.
   * @param params The `DisasterRecoveryService.UpdateDrPlanByIdParams` containing the following parameters:
   *
   * - `id`: Specifies the ID of the DR plan.
   *
   * - `body`: Specifies the request parameters to update the details of the DR plan.
   *
   * @return Response of a successful DR plan update.
   */
  UpdateDrPlanByIdResponse(params: DisasterRecoveryService.UpdateDrPlanByIdParams): __Observable<__StrictHttpResponse<DrPlan>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/site-continuity/dr-plans/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DrPlan>;
      })
    );
  }
  /**
   * Update a DR plan by ID
   *
   * Updates the details of a DR plan using it's ID.
   * @param params The `DisasterRecoveryService.UpdateDrPlanByIdParams` containing the following parameters:
   *
   * - `id`: Specifies the ID of the DR plan.
   *
   * - `body`: Specifies the request parameters to update the details of the DR plan.
   *
   * @return Response of a successful DR plan update.
   */
  UpdateDrPlanById(params: DisasterRecoveryService.UpdateDrPlanByIdParams): __Observable<DrPlan> {
    return this.UpdateDrPlanByIdResponse(params).pipe(
      __map(_r => _r.body as DrPlan)
    );
  }

  /**
   * Delete a DR plan by ID
   *
   * Deletes the details of a DR plan using it's ID.
   * @param params The `DisasterRecoveryService.DeleteDrPlanByIdParams` containing the following parameters:
   *
   * - `id`: Specifies the ID of the DR plan.
   *
   * - `force`: If this is set to true, then the DR plan will be deleted forcefully. Some of the related entities (protection groups, policies etc.) may need to be cleaned up manually on the source cluster.
   */
  DeleteDrPlanByIdResponse(params: DisasterRecoveryService.DeleteDrPlanByIdParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.force != null) __params = __params.set('force', params.force.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/site-continuity/dr-plans/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete a DR plan by ID
   *
   * Deletes the details of a DR plan using it's ID.
   * @param params The `DisasterRecoveryService.DeleteDrPlanByIdParams` containing the following parameters:
   *
   * - `id`: Specifies the ID of the DR plan.
   *
   * - `force`: If this is set to true, then the DR plan will be deleted forcefully. Some of the related entities (protection groups, policies etc.) may need to be cleaned up manually on the source cluster.
   */
  DeleteDrPlanById(params: DisasterRecoveryService.DeleteDrPlanByIdParams): __Observable<null> {
    return this.DeleteDrPlanByIdResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get data pool of DR plan
   *
   * Gets data pool's information using DR plan ID.
   * @param id Specifies the ID of DR plan.
   * @return Specifies the response of a successful get DR plan datapool operation.
   */
  GetDrPlanDataPoolByIdResponse(id: number): __Observable<__StrictHttpResponse<DataPool>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/site-continuity/dr-plans/${encodeURIComponent(id)}/data-pool`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DataPool>;
      })
    );
  }
  /**
   * Get data pool of DR plan
   *
   * Gets data pool's information using DR plan ID.
   * @param id Specifies the ID of DR plan.
   * @return Specifies the response of a successful get DR plan datapool operation.
   */
  GetDrPlanDataPoolById(id: number): __Observable<DataPool> {
    return this.GetDrPlanDataPoolByIdResponse(id).pipe(
      __map(_r => _r.body as DataPool)
    );
  }

  /**
   * Returns DR plan details based on passed parameters in payload.
   *
   * Returns DR plan details based on passed parameters in payload.
   * @param params The `DisasterRecoveryService.GetDrPlanDetailsByIdParams` containing the following parameters:
   *
   * - `id`: The ID of the DR plan.
   *
   * - `body`: Request parameters to get DR plan details.
   *
   * @return Response of a successful get DR plan detail operation.
   */
  GetDrPlanDetailsByIdResponse(params: DisasterRecoveryService.GetDrPlanDetailsByIdParams): __Observable<__StrictHttpResponse<DrPlanDetails>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/site-continuity/dr-plans/${encodeURIComponent(params.id)}/details`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DrPlanDetails>;
      })
    );
  }
  /**
   * Returns DR plan details based on passed parameters in payload.
   *
   * Returns DR plan details based on passed parameters in payload.
   * @param params The `DisasterRecoveryService.GetDrPlanDetailsByIdParams` containing the following parameters:
   *
   * - `id`: The ID of the DR plan.
   *
   * - `body`: Request parameters to get DR plan details.
   *
   * @return Response of a successful get DR plan detail operation.
   */
  GetDrPlanDetailsById(params: DisasterRecoveryService.GetDrPlanDetailsByIdParams): __Observable<DrPlanDetails> {
    return this.GetDrPlanDetailsByIdResponse(params).pipe(
      __map(_r => _r.body as DrPlanDetails)
    );
  }

  /**
   * Perform action on a DR plan
   *
   * Performs an action on a DR plan using it's ID.
   * @param params The `DisasterRecoveryService.PerformDrPlanActionByIdParams` containing the following parameters:
   *
   * - `id`: The ID of the DR plan.
   *
   * - `body`: Request parameters to perform an action on the DR plan.
   *
   * @return Specifies the response of an action on the DR plan.
   */
  PerformDrPlanActionByIdResponse(params: DisasterRecoveryService.PerformDrPlanActionByIdParams): __Observable<__StrictHttpResponse<DrPlanActionResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/site-continuity/dr-plans/${encodeURIComponent(params.id)}/actions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DrPlanActionResponse>;
      })
    );
  }
  /**
   * Perform action on a DR plan
   *
   * Performs an action on a DR plan using it's ID.
   * @param params The `DisasterRecoveryService.PerformDrPlanActionByIdParams` containing the following parameters:
   *
   * - `id`: The ID of the DR plan.
   *
   * - `body`: Request parameters to perform an action on the DR plan.
   *
   * @return Specifies the response of an action on the DR plan.
   */
  PerformDrPlanActionById(params: DisasterRecoveryService.PerformDrPlanActionByIdParams): __Observable<DrPlanActionResponse> {
    return this.PerformDrPlanActionByIdResponse(params).pipe(
      __map(_r => _r.body as DrPlanActionResponse)
    );
  }

  /**
   * List DR plan's readiness tests
   *
   * Gets a list of the available readiness tests of a DR plan using plan's ID.
   * @param params The `DisasterRecoveryService.GetDrPlanReadinessTestsByPlanIdParams` containing the following parameters:
   *
   * - `id`: The ID of the DR plan.
   *
   * - `statuses`: Returns only the tests which have the specified statuses.
   *
   * - `startTime`: Returns only the tests run after this timestamp.
   *
   * @return Response of a successful get DR plan readiness tests operation.
   */
  GetDrPlanReadinessTestsByPlanIdResponse(params: DisasterRecoveryService.GetDrPlanReadinessTestsByPlanIdParams): __Observable<__StrictHttpResponse<DrPlanReadinessTestResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    (params.statuses || []).forEach(val => {if (val != null) __params = __params.append('statuses', val.toString())});
    if (params.startTime != null) __params = __params.set('startTime', params.startTime.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/site-continuity/dr-plans/${encodeURIComponent(params.id)}/readiness-tests`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DrPlanReadinessTestResponse>;
      })
    );
  }
  /**
   * List DR plan's readiness tests
   *
   * Gets a list of the available readiness tests of a DR plan using plan's ID.
   * @param params The `DisasterRecoveryService.GetDrPlanReadinessTestsByPlanIdParams` containing the following parameters:
   *
   * - `id`: The ID of the DR plan.
   *
   * - `statuses`: Returns only the tests which have the specified statuses.
   *
   * - `startTime`: Returns only the tests run after this timestamp.
   *
   * @return Response of a successful get DR plan readiness tests operation.
   */
  GetDrPlanReadinessTestsByPlanId(params: DisasterRecoveryService.GetDrPlanReadinessTestsByPlanIdParams): __Observable<DrPlanReadinessTestResponse> {
    return this.GetDrPlanReadinessTestsByPlanIdResponse(params).pipe(
      __map(_r => _r.body as DrPlanReadinessTestResponse)
    );
  }

  /**
   * List DR plan's executions
   *
   * Gets a list of the available executions of a DR plan using plan's ID.
   * @param params The `DisasterRecoveryService.GetDrPlanExecutionsByPlanIdParams` containing the following parameters:
   *
   * - `id`: The ID of the DR plan.
   *
   * - `statuses`: Returns only the executions which have the specified statuses.
   *
   * - `startTime`: Returns only the executions which are run after this timestamp.
   *
   * @return Response of a successful get DR plan executions operation.
   */
  GetDrPlanExecutionsByPlanIdResponse(params: DisasterRecoveryService.GetDrPlanExecutionsByPlanIdParams): __Observable<__StrictHttpResponse<DrPlanExecutionsResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    (params.statuses || []).forEach(val => {if (val != null) __params = __params.append('statuses', val.toString())});
    if (params.startTime != null) __params = __params.set('startTime', params.startTime.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/site-continuity/dr-plans/${encodeURIComponent(params.id)}/executions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DrPlanExecutionsResponse>;
      })
    );
  }
  /**
   * List DR plan's executions
   *
   * Gets a list of the available executions of a DR plan using plan's ID.
   * @param params The `DisasterRecoveryService.GetDrPlanExecutionsByPlanIdParams` containing the following parameters:
   *
   * - `id`: The ID of the DR plan.
   *
   * - `statuses`: Returns only the executions which have the specified statuses.
   *
   * - `startTime`: Returns only the executions which are run after this timestamp.
   *
   * @return Response of a successful get DR plan executions operation.
   */
  GetDrPlanExecutionsByPlanId(params: DisasterRecoveryService.GetDrPlanExecutionsByPlanIdParams): __Observable<DrPlanExecutionsResponse> {
    return this.GetDrPlanExecutionsByPlanIdResponse(params).pipe(
      __map(_r => _r.body as DrPlanExecutionsResponse)
    );
  }

  /**
   * Get the protection related options for a given DR plan
   *
   * Gets the protection related options using DR plan ID.
   * @param id Specifies the ID of DR plan.
   * @return Response of a successful get DR plan protection options operation.
   */
  GetDrPlanProtectionOptionsByIdResponse(id: number): __Observable<__StrictHttpResponse<DRPlanProtectionOptions>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/site-continuity/dr-plans/${encodeURIComponent(id)}/protection-options`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DRPlanProtectionOptions>;
      })
    );
  }
  /**
   * Get the protection related options for a given DR plan
   *
   * Gets the protection related options using DR plan ID.
   * @param id Specifies the ID of DR plan.
   * @return Response of a successful get DR plan protection options operation.
   */
  GetDrPlanProtectionOptionsById(id: number): __Observable<DRPlanProtectionOptions> {
    return this.GetDrPlanProtectionOptionsByIdResponse(id).pipe(
      __map(_r => _r.body as DRPlanProtectionOptions)
    );
  }

  /**
   * Get readiness history of sites
   *
   * Returns readiness history either for a specified site or aggregated for sites. If fromTimeUsecs and toTimeUsecs are not specified then it returns snapshots for the last 24 hours.
   * @param params The `DisasterRecoveryService.GetReadinessHistoryParams` containing the following parameters:
   *
   * - `toTimeUsecs`: Specifies the timestamp in Unix time epoch in microseconds to get readiness history. All the readiness values having timestamp less than toTimeUsecs will be returned.
   *
   * - `siteId`: The ID of the site for which readiness should be returned. If this is not specified then the aggregated history of all the sites will be returned.
   *
   * - `fromTimeUsecs`: Specifies the timestamp in Unix time epoch in microseconds to get readiness history. All the readiness values having timestamp greater than or equal to fromTimeUsecs will be returned.
   *
   * @return Response of a successful get readiness history operation.
   */
  GetReadinessHistoryResponse(params: DisasterRecoveryService.GetReadinessHistoryParams): __Observable<__StrictHttpResponse<ReadinessHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.toTimeUsecs != null) __params = __params.set('toTimeUsecs', params.toTimeUsecs.toString());
    if (params.siteId != null) __params = __params.set('siteId', params.siteId.toString());
    if (params.fromTimeUsecs != null) __params = __params.set('fromTimeUsecs', params.fromTimeUsecs.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/site-continuity/readiness-history`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ReadinessHistory>;
      })
    );
  }
  /**
   * Get readiness history of sites
   *
   * Returns readiness history either for a specified site or aggregated for sites. If fromTimeUsecs and toTimeUsecs are not specified then it returns snapshots for the last 24 hours.
   * @param params The `DisasterRecoveryService.GetReadinessHistoryParams` containing the following parameters:
   *
   * - `toTimeUsecs`: Specifies the timestamp in Unix time epoch in microseconds to get readiness history. All the readiness values having timestamp less than toTimeUsecs will be returned.
   *
   * - `siteId`: The ID of the site for which readiness should be returned. If this is not specified then the aggregated history of all the sites will be returned.
   *
   * - `fromTimeUsecs`: Specifies the timestamp in Unix time epoch in microseconds to get readiness history. All the readiness values having timestamp greater than or equal to fromTimeUsecs will be returned.
   *
   * @return Response of a successful get readiness history operation.
   */
  GetReadinessHistory(params: DisasterRecoveryService.GetReadinessHistoryParams): __Observable<ReadinessHistory> {
    return this.GetReadinessHistoryResponse(params).pipe(
      __map(_r => _r.body as ReadinessHistory)
    );
  }

  /**
   * Get DR plan objects' snapshots.
   *
   * Returns the snapshots information about the objects inside the DR plan.
   * @param params The `DisasterRecoveryService.GetDrPlanObjectSnapshotsParams` containing the following parameters:
   *
   * - `id`: Specifies the ID of the DR plan.
   *
   * - `body`: Request parameters to get DR plan Object Snapshot details.
   *
   * @return Response of a successful get snapshot object detail operation.
   */
  GetDrPlanObjectSnapshotsResponse(params: DisasterRecoveryService.GetDrPlanObjectSnapshotsParams): __Observable<__StrictHttpResponse<ObjectSnapshotInfoResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/site-continuity/dr-plans/${encodeURIComponent(params.id)}/object-snapshots`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ObjectSnapshotInfoResponse>;
      })
    );
  }
  /**
   * Get DR plan objects' snapshots.
   *
   * Returns the snapshots information about the objects inside the DR plan.
   * @param params The `DisasterRecoveryService.GetDrPlanObjectSnapshotsParams` containing the following parameters:
   *
   * - `id`: Specifies the ID of the DR plan.
   *
   * - `body`: Request parameters to get DR plan Object Snapshot details.
   *
   * @return Response of a successful get snapshot object detail operation.
   */
  GetDrPlanObjectSnapshots(params: DisasterRecoveryService.GetDrPlanObjectSnapshotsParams): __Observable<ObjectSnapshotInfoResponse> {
    return this.GetDrPlanObjectSnapshotsResponse(params).pipe(
      __map(_r => _r.body as ObjectSnapshotInfoResponse)
    );
  }
}

module DisasterRecoveryService {

  /**
   * Parameters for GetDrPlans
   */
  export interface GetDrPlansParams {

    /**
     * Filter DR plans by list of statuses.
     */
    statuses?: Array<'NotReady' | 'Syncing' | 'Activating' | 'FailoverReady' | 'FailoverRunning' | 'FailoverFailed' | 'TeardownReady' | 'TeardownRunning' | 'TeardownFailed' | 'FailoverDone' | 'FailoverPartiallyDone' | 'PlannedFailoverPartiallyDone' | 'PrepareFailbackRunning' | 'PrepareFailbackFailed' | 'FailbackReady' | 'FailbackRunning' | 'FailbackFailed' | 'FailbackDone' | 'FailbackPartiallyDone' | 'PlannedFailbackPartiallyDone' | 'PrepareFailoverRunning' | 'PrepareFailoverFailed' | 'ReplicatingForFailover' | 'ReplicatingForFailback' | 'ReplicationForFailoverFailed' | 'ReplicationForFailbackFailed' | 'ReplicationForFailoverScheduled' | 'ReplicationForFailbackScheduled' | 'StandbyFailoverDone' | 'PlannedFailoverCanceling' | 'PlannedFailoverCancelationFailed' | 'PlannedFailoverRunning' | 'PlannedFailoverFailed' | 'PlannedFailoverFinalizing' | 'PlannedFailoverFinalizationFailed' | 'PlannedFailbackRunning' | 'PlannedFailbackFailed' | 'PlannedFailbackCanceling' | 'PlannedFailbackCancelationFailed' | 'PlannedFailbackFinalizing' | 'PlannedFailbackFinalizationFailed'>;

    /**
     * Filter DR plans by list of states.
     */
    states?: Array<'Draft' | 'Active'>;

    /**
     * Filter DR plans by their SLA statuses.
     */
    slaStatus?: 'Met' | 'Missed';

    /**
     * Filter DR plans by list of site IDs.
     */
    siteIds?: Array<string>;

    /**
     * Filter DR plans by Id.
     */
    ids?: Array<number>;

    /**
     * Filter DR plans by their health statuses. If this filter is specified then only active DR plans having the specified statuses will be returned.
     */
    healthStatuses?: Array<'Healthy' | 'Warning' | 'Unhealthy'>;

    /**
     * Specifies the Ids of the datapools to filter.
     */
    datapoolIds?: Array<string>;

    /**
     * Specifies the Id of the datapool to filter.
     */
    datapoolId?: string;
  }

  /**
   * Parameters for GetDrPlanById
   */
  export interface GetDrPlanByIdParams {

    /**
     * Specifies the ID of the DR plan.
     */
    id: number;

    /**
     * If specified as true, then the response includes Cohesity View share info for NFS/SMB/S3 protocol, sourced from active Primary/DR site.
     */
    includeViewShareInfo?: boolean;
  }

  /**
   * Parameters for UpdateDrPlanById
   */
  export interface UpdateDrPlanByIdParams {

    /**
     * Specifies the ID of the DR plan.
     */
    id: number;

    /**
     * Specifies the request parameters to update the details of the DR plan.
     */
    body?: UpdateDrPlanRequest;
  }

  /**
   * Parameters for DeleteDrPlanById
   */
  export interface DeleteDrPlanByIdParams {

    /**
     * Specifies the ID of the DR plan.
     */
    id: number;

    /**
     * If this is set to true, then the DR plan will be deleted forcefully. Some of the related entities (protection groups, policies etc.) may need to be cleaned up manually on the source cluster.
     */
    force?: boolean;
  }

  /**
   * Parameters for GetDrPlanDetailsById
   */
  export interface GetDrPlanDetailsByIdParams {

    /**
     * The ID of the DR plan.
     */
    id: number;

    /**
     * Request parameters to get DR plan details.
     */
    body?: GetDrPlanDetailsRequest;
  }

  /**
   * Parameters for PerformDrPlanActionById
   */
  export interface PerformDrPlanActionByIdParams {

    /**
     * The ID of the DR plan.
     */
    id: number;

    /**
     * Request parameters to perform an action on the DR plan.
     */
    body?: PerformDrPlanActionRequest;
  }

  /**
   * Parameters for GetDrPlanReadinessTestsByPlanId
   */
  export interface GetDrPlanReadinessTestsByPlanIdParams {

    /**
     * The ID of the DR plan.
     */
    id: number;

    /**
     * Returns only the tests which have the specified statuses.
     */
    statuses?: Array<'Queued' | 'InProgress' | 'Succeeded' | 'Failed'>;

    /**
     * Returns only the tests run after this timestamp.
     */
    startTime?: number;
  }

  /**
   * Parameters for GetDrPlanExecutionsByPlanId
   */
  export interface GetDrPlanExecutionsByPlanIdParams {

    /**
     * The ID of the DR plan.
     */
    id: number;

    /**
     * Returns only the executions which have the specified statuses.
     */
    statuses?: Array<'Queued' | 'InProgress' | 'Succeeded' | 'Failed'>;

    /**
     * Returns only the executions which are run after this timestamp.
     */
    startTime?: number;
  }

  /**
   * Parameters for GetReadinessHistory
   */
  export interface GetReadinessHistoryParams {

    /**
     * Specifies the timestamp in Unix time epoch in microseconds to get readiness history. All the readiness values having timestamp less than toTimeUsecs will be returned.
     */
    toTimeUsecs?: number;

    /**
     * The ID of the site for which readiness should be returned. If this is not specified then the aggregated history of all the sites will be returned.
     */
    siteId?: number;

    /**
     * Specifies the timestamp in Unix time epoch in microseconds to get readiness history. All the readiness values having timestamp greater than or equal to fromTimeUsecs will be returned.
     */
    fromTimeUsecs?: number;
  }

  /**
   * Parameters for GetDrPlanObjectSnapshots
   */
  export interface GetDrPlanObjectSnapshotsParams {

    /**
     * Specifies the ID of the DR plan.
     */
    id: number;

    /**
     * Request parameters to get DR plan Object Snapshot details.
     */
    body?: GetDrPlanObjectSnapshotRequest;
  }
}

export { DisasterRecoveryService }
