import { AppModuleConfig, StateAccessContext, StateAccessMap } from 'src/app/app-module.config';

const dataMigrationModify = (ctx: StateAccessContext) =>
  ctx.FEATURE_FLAGS.ngProtectionGroupGlobalModify &&
  ctx.FEATURE_FLAGS.ngProtectionGroupDataMigrationModify &&
  ctx.PROTECTION_MODIFY;

export const DataMigrationConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'protection-builder-data-migration.**',
      url: '/protection/protect/data-migration',
      loadChildren: () => import('./data-migration.module').then(m => m.DataMigrationModule),
    },
  ],
  getStateAccessMap(): StateAccessMap {
    return {
      'protection-builder-data-migration.create': dataMigrationModify,
      'protection-builder-data-migration.edit': dataMigrationModify,
    };
  },
};
