import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Controls, NgxSubFormComponent, subformComponentProviders } from 'ngx-sub-form';
import { NoEmptyStringValidator } from '@cohesity/shared-forms';
import { TextInputListItem } from '../text-input-list.model';

/**
 * Defines a single item of the text input list component.
 */
@Component({
  selector: 'coh-text-input-list-item',
  templateUrl: './text-input-list-item.component.html',
  providers: subformComponentProviders(TextInputListItemComponent),
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextInputListItemComponent extends NgxSubFormComponent<TextInputListItem> {

  /**
   * Label to show for the input field.
   */
  @Input() label: string;

  /**
   * Whether to allow addition of a new input field.
   */
  @Input() allowAdd = true;

  /**
   * Whether to allow removal of this input field.
   */
  @Input() allowRemove = true;

  /**
   * Output emitter to add list item.
   */
  @Output() addItem = new EventEmitter();

  /**
   * Output emitter to remove list item.
   */
  @Output() removeItem = new EventEmitter();

  /**
   * Handler for adding list item.
   */
  onAddItem() {
    this.addItem.emit();
  }

  /**
   * Handler for removing list item.
   */
  onRemoveItem() {
    this.removeItem.emit();
  }

  protected getFormControls(): Controls<TextInputListItem> {
    return {
      value: new UntypedFormControl(null, [Validators.required, NoEmptyStringValidator]),
    };
  }
}
