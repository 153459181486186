<mat-radio-group *ngIf="!readOnly" class="no-margin-bottom" [formControl]="control">
  <mat-radio-button
    *ngFor="let option of options"
    cogDataId="radio-group-option-{{option.name}}"
    [value]="option"
    class="margin-right-lg"
    [checked]="option === control.value">
    {{option.name | translate}}
  </mat-radio-button>
</mat-radio-group>
<cog-settings-list *ngIf="readOnly"
  class="recover-to-settings-list"
  [alwaysOpen]="true"
  [readOnly]="true"
  [formGroup]="formGroup">
  <cog-settings-list-item [label]="label" cogFormGroup>
    <cog-settings-list-item-value-display>
      {{control.value.name | translate}}
    </cog-settings-list-item-value-display>
  </cog-settings-list-item>
</cog-settings-list>
