import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { CohesityHelixModule } from '@cohesity/helix';
import { TranslateModule } from '@ngx-translate/core';

import { HealthChecksPopoverComponent } from './health-checks-popover/health-checks-popover.component';
import { SourceHealthCheckMatrixComponent } from './source-health-check-matrix/source-health-check-matrix.component';

const COMPONENTS = [
  HealthChecksPopoverComponent,
  SourceHealthCheckMatrixComponent,
];

@NgModule({
  declarations: [ ...COMPONENTS ],
  exports: [ ...COMPONENTS ],
  imports: [
    CohesityHelixModule,
    CommonModule,
    TranslateModule.forChild(),
  ],
})
export class SourceHealthChecksModule { }
