import { environment } from 'src/environments/environment';

/**
 * Class for building api urls. The methods are all static, and it is
 * compatible with the old angular js API const definitions.
 */
export class Routes {
  /**
   * Route to JSON file that provides a mapping of Cohesity Agent install files per OS.
   */
  public static readonly agentsDataUri = '/files/bin/installers/agent_installer_files.json';

  /**
   * Routes to OS versions of Iris CLI executable.
   */
  public static readonly cliBinary = {
    darwin: '/files/bin/cli/darwin-x86_64/iris_cli',
    linux: '/files/bin/cli/linux-x86_64/iris_cli',
    windows: '/files/bin/cli/windows-x86_64/iris_cli.exe',
  };

  /**
   * File of a list of known/documented alerts.
   * Note: This file seems to be missing. It might be a mistake or this functionality should be cleaned up.
   */
  public static readonly helpAlertList = 'alerts_known.txt';

  /**
   * Help URL index file. Used when deriving a context sensitive help (CSH) url.
   */
  public static readonly helpIndex = 'index.htm';

  /**
   * Cohesity's URL for Help Docs site.
   */
  public static readonly docsUrlCohesity = 'https://docs.cohesity.com';

  /**
   * IBM's URL for Help Docs site.
   */
  public static readonly docsUrlIBM = 'https://www.ibm.com/docs/en/SSDR5G6_prod/defender/c_defender.html';

  /**
   * Various segments of a help URL.
   */
  public static readonly helpRoot = {
    remote: environment.ibmAqua ? Routes.docsUrlIBM : Routes.docsUrlCohesity,
    heliosSuffix: environment.ibmAqua ? '' : '/WebHelios/',
    local: '/docs/UserGuide/',
    index: 'index.htm'
  };

  /**
   * Help URL for local server - used for heliosOnprem for now
   */
  public static readonly localRoot = {
    remote: '/',
    heliosSuffix: 'docs/mcm/on-premises/',
    index: 'overview.htm'
  };

  /**
   * Versioned sub directories for use in generating an apporpriate help link.
   */
  public static readonly helpSubdir = {
    azureCloud: 'WebCEAzure/UserGuide/',
    amazonCloud: 'WebCEAWS/UserGuide/',
    googleCloud: 'WebCEGCP/UserGuide/',
    physical: 'Web/UserGuide/',
  };

  /**
   * The URL to the public facing Cohesity marketplace.
   */
  public static readonly marketplace = 'https://marketplace.cohesity.com';

  /**
   * The url for the Cohesity support portal.
   */
  public static readonly support = 'https://support.cohesity.com';

  /**
   * Path/URL to the new API documentation page.
   */
  public static readonly apiDoc = '/docs/api';

  /**
   * Path/URL to the REST API v1 documentation.
   */
  public static readonly restApiV1Doc = '/docs/api/v1';

  /**
   * Path/URL to the REST API v2 documentation.
   */
  public static readonly restApiV2Doc = '/docs/api/v2';

  /**
   * Path/URL to the REST API documentation.
   */
  public static readonly restApiDocRoot = '/docs/restApiDocs/browse';
}
