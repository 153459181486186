import { IrisContext } from '@cohesity/iris-core';
import { CanAccessEnvBase, Environment, EnvItems } from '@cohesity/iris-shared-constants';
import { Ng2StateDeclaration } from '@uirouter/angular';
import { RawParams } from '@uirouter/core';
import { AppServiceType } from 'src/app/app-services';
import { AllClustersSupportDetails } from 'src/app/core/state/app-state-declaration';
import { BifrostCapabilities } from 'src/app/shared/constants/bifrost.constants';

/**
 * Can Access callback type for states
 */
export type CanAccess = (context: StateAccessContext) => boolean;

/**
 * Future states support a subset of properties from Ng2StateDeclaration. Only
 * these properties should be used for for future states and no others.
 */
export type NgFutureStateDeclaration = Pick<Ng2StateDeclaration, 'name' | 'url' | 'loadChildren'>;

/**
 * Type AllClusterMap - this is a map of state names to all cluster support. If not
 * specified, the value will be treated as false. See documentation for AllClustersSupportDetails
 * for more information.
 */
export interface AllClusterMap {
  [key: string]: boolean | AllClustersSupportDetails;
}

/**
 * The IRIS workflows.
 */
export enum Workflow {
  /**
   * The primary adaptor's backup and restore workflow.
   */
  backupAndRecovery = 'backupAndRecovery',

  /**
   * The adaptor's browse file and folder workflow.
   *
   * NOTE: browsing workflow would be disabled when adaptor's backupAndRecovery is disabled and this dependency is
   *       implemented by AdaptorAccessService.canAccessWorkflow() method.
   */
  browsing = 'browsing',

  /**
   * The adaptor's test & dev aka clone workflow.
   *
   * NOTE: clone workflow would be disabled when adaptor's backupAndRecovery is disabled and this dependency is
   *       implemented by AdaptorAccessService.canAccessWorkflow() method.
   */
  clone = 'clone',

  /**
   * The adaptor's kuiper workflow.
   *
   * NOTE: kuiper workflow would be disabled when adaptor's backupAndRecovery is disabled and this dependency is
   *       implemented by AdaptorAccessService.canAccessWorkflow() method.
   */
  kuiper = 'kuiper',

  /**
   * The adaptor's database migration workflow.
   *
   * NOTE: DB migration workflow would be disabled when adaptor's backupAndRecovery is disabled and this dependency is
   *       implemented by AdaptorAccessService.canAccessWorkflow() method.
   */
  dbMigration = 'dbMigration',

  /**
   * The adaptor's NAS data migration workflow.
   *
   * NOTE: NAS Data migration workflow would be disabled when adaptor's backupAndRecovery is disabled and this
   *       dependency is implemented by AdaptorAccessService.canAccessWorkflow() method.
   */
  dataMigration = 'dataMigration',
}

/**
 * CanAccess workflow callback method used to determine whether a logged-in user can access the provided workflow.
 */
export type CanAccessEnv = (ctx: EnvAccessContext, workflow: Workflow) => boolean;

/**
 * Indicates whether a logged-in user can access an environment or not.
 *
 * @example
 * const EnvAccessResult: EnvAccessResult = {
 *   Environment.kVMWare: true,
 *   Environment.kPure: false,
 *   Environment.kO365: false,
 *   ...
 * };
 */
export type EnvAccessResult = CanAccessEnvBase<boolean>;

/**
 * Indicates whether a logged-in user can access a workflow.
 * WorkflowAccessContext is a map of workflow to EnvAccessResult.
 *
 * @example
 * const workflow: WorkflowAccessContext = {
 *   backupAndRecovery: {
 *      Environment.kVMWare: true,
 *      Environment.kPure: false,
 *      Environment.kSQL: true,
 *      ...
 *   },
 *   clone: {
 *      Environment.kVMWare: true,
 *      Environment.kPure: false,
 *      Environment.kSQL: true,
 *      ...
 *   },
 *   kuiper: {
 *      Environment.kVMWare: true,
 *      Environment.kPure: false,
 *      Environment.kSQL: flase,
 *      ...
 *   }
 * };
 */
export type WorkflowAccessContext = {
  readonly [K in Workflow]: EnvAccessResult;
};

/**
 * The workflow access context used by CanAccessEnv callbacks to determine whether an environment can be accessed
 * or not by logged-in user.
 */
export interface EnvAccessContext {
  /**
   * feature flags will be populated from feature-flag.json file.
   *
   * @example
   * getStateAccessMap(): StateAccessMap {
   *   return {
   *     'cloud': (ctx: StateAccessContext) => ctx.FEATURE_FLAGS.ngCloudDashboard,
   *   };
   * }
   */
  readonly FEATURE_FLAGS: {
    [key: string]: boolean;
  };

  /**
   * bifrost capabilities of current user, empty if current user is not a bifrost tenant
   *
   * @example
   * getStateAccessMap(): StateAccessMap {
   *   return {
   *     'cloud': (ctx: StateAccessContext) => ctx.BIFROST_CAPABILITIES.BR_kVMWare_kStandaloneHost,
   *   };
   * }
   */
  readonly BIFROST_CAPABILITIES: BifrostCapabilities;

  /**
   * capabilities supported by the current cluster, empty if current user is not a bifrost tenant
   *
   * @example
   * getStateAccessMap(): StateAccessMap {
   *   return {
   *     'cloud': (ctx: StateAccessContext) => ctx.CLUSTER_CAPABILITIES.BR_kVMWare_kStandaloneHost,
   *   };
   * }
   */
  readonly CLUSTER_CAPABILITIES: BifrostCapabilities;

  /**
   * Determines whether the logged-in user is a tenant user or not use this to disable access to a states for tenants.
   *
   * @example
   * getStateAccessMap(): StateAccessMap {
   *   return {
   *     'cloud': (ctx: StateAccessContext) => ctx.FEATURE_FLAGS.ngCloudDashboard && !ctx.isTenantUser,
   *   };
   * }
   */
  readonly isTenantUser: boolean;

  /**
   * Determines whether the logged-in user is a restricted user or not.
   *
   * @example
   * getStateAccessMap(): StateAccessMap {
   *   return {
   *     'dashboards.summary': (ctx: StateAccessContext) => !ctx.isTenantUser && !ctx.isRestrictedUser,
   *   };
   * }
   */
  readonly isRestrictedUser: boolean;

  /**
   * Indicates whether the logged-in user is a cluster user or not. This means
   * the user is not operating in mcm/helios (they are on a cluster) or is operating in
   * mcm mode with clusters registered/connected to the system (not a service only user).
   *
   * @example
   * getStateAccessMap(): StateAccessMap {
   *   return {
   *     'recover-office365': (ctx: StateAccessContext) => ctx.isClusterUser,
   *   };
   * }
   */
  readonly isClusterUser: boolean;

  /**
   * Determines whether the logged-in user is a Helios(BaaS) tenant user or not.
   * NOTE: Helios(BaaS) user is a special kind of tenant user hence for him above isTenantUser will also be true.
   *
   * @example
   * getStateAccessMap(): StateAccessMap {
   *   return {
   *     // physical server recovery is not supported by Helios tenant users but it is supported for cluster user or
   *     // on-prem tenant users.
   *     'recover-physical-server': (ctx: StateAccessContext) => !ctx.isHeliosTenantUser,
   *   };
   * }
   */
  readonly isHeliosTenantUser: boolean;

  /**
   * Determines whether the logged-in user is a bifrost tenant user or not.
   * NOTE: bifrost tenant user is a special kind of tenant user hence for him above isTenantUser will also be true.
   *
   * @example
   * getStateAccessMap(): StateAccessMap {
   *   return {
   *     // physical server recovery is not supported by Helios tenant users but it is supported for cluster user or
   *     // on-prem tenant users.
   *     'recover-physical-server': (ctx: StateAccessContext) => !ctx.isBifrostTenantUser,
   *   };
   * }
   */
  readonly isBifrostTenantUser: boolean;

  /**
   * Determines whether the logged-in user is a DMaaS user or not.
   *
   * @example
   * getStateAccessMap(): StateAccessMap {
   *   return {
   *     // DMS Dashboard should only be accessed by a DMS User
   *     'dashboards.dms': (ctx: StateAccessContext) => !ctx.isDmsUser,
   *   };
   * }
   */
  readonly isDmsUser: boolean;

  /**
   * Determines whether the logged-in user is a DRaaS user or not.
   *
   * @example
   * getStateAccessMap(): StateAccessMap {
   *   return {
   *     'sources': (ctx: StateAccessContext) => !ctx.isDraasUser,
   *   };
   * }
   */
  readonly isDraasUser: boolean;

  /**
   * Determines whether the logged-in user is a DGaaS user or not.
   *
   * @example
   * getStateAccessMap(): StateAccessMap {
   *   return {
   *     'argus': (ctx: StateAccessContext) => !ctx.isDGaaSUser,
   *   };
   * }
   */
  readonly isDGaaSUser: boolean;

  /**
   * Determines whether the logged-in user is a Data Insights user or not.
   *
   * @example
   * getStateAccessMap(): StateAccessMap {
   *   return {
   *     'gaia': (ctx: StateAccessContext) => !ctx.isGaiaUser,
   *   };
   * }
   */
  readonly isGaiaUser: boolean;

  /**
   * Determines whether the logged-in user is a Data Insights user or not.
   *
   * @example
   * getStateAccessMap(): StateAccessMap {
   *   return {
   *     'gaia': (ctx: StateAccessContext) => !ctx.isGaiaUser,
   *   };
   * }
   */
  readonly isSizerUser: boolean;

  /**
   * Determines whether the UI runs in Helios mode.
   *
   * @example
   * getStateAccessMap(): StateAccessMap {
   *   return {
   *     'helios-access-management.sso': (ctx: StateAccessContext) => !ctx.isHelios,
   *   }
   * }
   */
  readonly isHelios: boolean;

  /**
   * Determines whether the UI runs in Helios SaaS mode.
   *
   * @example
   * getStateAccessMap(): StateAccessMap {
   *   return {
   *     'helios-access-management.sso': (ctx: StateAccessContext) => !ctx.isHeliosSaaS,
   *   }
   * }
   */
  readonly isHeliosSaaS: boolean;

  /**
   * Determines whether the UI runs in Helios On-Prem mode.
   *
   * @example
   * getStateAccessMap(): StateAccessMap {
   *   return {
   *     'helios-access-management.sso': (ctx: StateAccessContext) => !ctx.isHeliosOnPrem,
   *   }
   * }
   */
  readonly isHeliosOnPrem: boolean;

  /**
   * Determines whether the cluster is an IBM cloud cluster.
   *
   * @example
   * getStateAccessMap(): StateAccessMap {
   *   return {
   *     'dms-connections': (ctx: StateAccessContext) => ctx.isIbmBaas,
   *   }
   * }
   */
  readonly isIbmBaas?: boolean;

  /**
   * The current iris context.
   */
  readonly irisContext: IrisContext;

  /**
   * The current service scope/service type
   */
  readonly activeScope: string | 'allClusters' | 'cluster' | AppServiceType;
}

/**
 * The state access context used by CanAccess callbacks to determine whether an state can be accessed or not by
 * logged-in user.
 */
export interface StateAccessContext extends EnvAccessContext {
  /**
   * This will be populated with values from Role.privileges.
   *
   * @example
   * getStateAccessMap(): StateAccessMap {
   *   return {
   *     'view-tenant': (ctx: StateAccessContext) => ctx.ORGANIZATION_VIEW,
   *     'add-tenant': (ctx: StateAccessContext) => ctx.ORGANIZATION_MODIFY,
   *     'edit-tenant': (ctx: StateAccessContext) => ctx.ORGANIZATION_MODIFY,
   *   };
   * }
   */
  [key: string]: any;

  /**
   * The target state name.
   */
  readonly stateName: string;

  /**
   * The target state params.
   */
  readonly stateParams: RawParams;

  /**
   * Indicates whether the logged-in user can access an environment's workflow or not.
   *
   * @example
   * getStateAccessMap(): StateAccessMap {
   *   return {
   *     // allow access to protection builder state only when logged-in user can access any one of the environment
   *     // that builder page support.
   *     'protection-builder': (ctx: StateAccessContext) => {
   *       return (ctx.stateParams.environments || []).some(env => ctx.workflow.backupAndRecovery[env]);
   *     },
   *    'restore-vm': (ctx: StateAccessContext) => {
   *       return (ctx.stateParams.environments || []).some(env => ctx.workflow.backupAndRecovery[env]);
   *     },
   *
   *    // allow access to clone vm workflow when logged-in user can access to any one of the environment that
   *    // clone-vm page support.
   *    'clone-vm': (ctx: StateAccessContext) => {
   *       return (ctx.stateParams.environments || []).some(env => ctx.workflow.clone[env]);
   *     },
   *    'kuiper-vm': (ctx: StateAccessContext) => {
   *       return (ctx.stateParams.environments || []).some(env => ctx.workflow.kuiper[env]);
   *     },
   *   };
   * }
   */
  readonly workflow: WorkflowAccessContext;

  /**
   * Determines whether any one of the environments is accessible by the user for the provided workflow.
   * NOTE: this method is defined at AdaptorAccessService.
   *
   * @example
   * import { recoveryGroup } from 'src/app/shared/constants';
   *
   * const canAccessNasRecovery = (ctx) => {
   *   return ctx.RESTORE_MODIFY && ctx.canAccessSomeEnv(recoveryGroup.nas);
   * };
   *
   * const canAccessVmRecovery = (ctx) => {
   *   return ctx.RESTORE_MODIFY && ctx.canAccessSomeEnv(recoveryGroup.vm);
   * };
   *
   * const canAccessVmClone = (ctx) => {
   *   return ctx.CLONE_MODIFY && ctx.canAccessSomeEnv(recoveryGroup.vm);
   * };
   *
   * @param   environment   The environments.
   * @param   workflow      The workflow.
   * @return  Return true if any one of the environments is accessible else false.
   */
  readonly canAccessSomeEnv: (environments: Environment[], workflow?: Workflow) => boolean;

  /**
   * Determines whether any one of the environment items is accessible by the user for the provided workflow.
   * NOTE: this method is defined at AdaptorAccessService.
   *
   * @example
   * import { hypervisorSources, nasSources } from 'src/app/shared/constants';
   *
   * const canAccessHypervisor = (ctx) => {
   *   return ctx.PROTECTION_SOURCE_MODIFY && ctx.canAccessSomeEnvItems(hypervisorSources);
   * };
   *
   * const canAccessNasSources = (ctx) => {
   *   return ctx.PROTECTION_SOURCE_MODIFY && ctx.canAccessSomeEnvItems(nasSources);
   * };
   *
   * @param   environment   The environments.
   * @param   workflow      The workflow.
   * @return  Return true if any one of the environment items is accessible else false.
   */
  readonly canAccessSomeEnvItems: (envItems: EnvItems[], workflow?: Workflow) => boolean;
}

/**
 * Type for State Access Maps - this is a map of state names to can access callback
 * functions.
 */
export interface StateAccessMap {
  [key: string]: CanAccess;
}

/**
 * Each feature module should create an AppModuleConfig file to configure lazy
 * loaded states as well as behavior for access states or managing helios behavior.
 */
export interface AppModuleConfig {
  /**
   * This is the main lazy loaded state definition for the feature. It should not define an individual component
   * but should use loadChildren to lazily load modules. Since it's a point to other modules, it does not need
   * state parameters, or other custom properties assigned to it.
   *
   * @example
   * {
   *   name: 'dashboard.**',
   *   url: '/dashboard',
   *   loadChildren: './modules/dashboards/dashboards.module#DashboardsModule',
   * }
   */
  futureStates: NgFutureStateDeclaration[];

  /**
   * This replaces 'allClustersSupport' support for angular states. Each key should be the name of a state, or
   * a valid allClustersSupport value.
   *
   * @example
   * {
   *   'security': {
   *     heliosOnly: true,
   *     allClustersState: 'security',
   *   },
   * }
   */
  allClusterMap?: AllClusterMap;

  /**
   * This replaces 'canAccess' for angular states. Each key should be the name of a state, and the value is a
   * callback function that returns true if state access is allowed. This is implemented as a function so that
   * the access map callbacks are created 'lazily.' Trying to define them immediately can cause issues with
   * compilation and result in cryptic error messages. If you do see strange error messages after modifying
   * any of these files run the build with AOT enabled and it should give more helpful output.
   *
   * @example
   * {
   *   'active-directory-restore-snapshot':
   *      (ctx: StateAccessContext) => ctx.RESTORE_MODIFY && ctx.FEATURE_FLAGS.activeDirectorySourceEnabled,
   * }
   */
  getStateAccessMap(): StateAccessMap;
}

/**
 * This enum is used to store different types of context that can be required by
 * the canUserAccessState method.
 */
export enum AccessContextType {
  Default,
  ClusterCapability
}

/**
 * AccessContext is an object which includes both the types of access context
 * stateAccessContext and ajsStateAccessContext.
 */
export interface AccessContext {
  stateAccessContext: StateAccessContext;
  ajsStateAccessContext: StateAccessContext;
}
