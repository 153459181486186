// Component: sql db status

;(function(angular, undefined) {

  var componentConfig = {
    controller: 'SqlDbStatusController',
    templateUrl: 'app/global/c-widgets/sql/db-status/db-status.html',
    bindings: {
      data: '<',
    },
  };

  angular.module('C.widgets')
    .controller('SqlDbStatusController', sqlDbStatusCtrlFn)
    .component('wSqlDbStatus', componentConfig);

  /**
   * @ngdoc component
   * @name    C.widgets:dbStatus
   *
   * @param   {Object}   data   db status should look like this:
   *                            {
   *                              count: string;
   *                              online: string;
   *                              offline: string;
   *                              restoring: string;
   *                              recovering: string;
   *                              pendingRecovery: string;
   *                              suspect: string;
   *                              emergency: string;
   *                            }
   *
   * @description
   * Widget for SQL database status.
   *
   * @example
   * <w-sql-db-status data="dbStatus"></w-sql-db-status>
   */
  function sqlDbStatusCtrlFn(_, CHART_COLORS) {
    var $ctrl = this;
    var chartDimension = 50;


    _.assign($ctrl, {
      $onChanges: $onChanges
    });

    /**
     * Update the chart object whenever the status changes
     *
     * @param   {SimpleChanges}   changes
     */
    function $onChanges(changes) {
      if (changes.data) {
        $ctrl.chart = updateChart();
        $ctrl.stats = updateStatus();
      }
    }

    /**
     * Update the stat list config
     *
     * @method    updateStatus
     * @returns   {Object}    statConfigObject
     */
    function updateStatus() {
      return !$ctrl.data ? [] : [
        {
          label: 'restoring',
          value: $ctrl.data.restoring,
        }, {
          label: 'recovering',
          value: $ctrl.data.recovering,
        }, {
          label: 'pendingRecovery',
          value: $ctrl.data.pendingRecovery,
        }, {
          label: 'suspect',
          value: $ctrl.data.suspect,
        }, {
          label: 'emergency',
          value: $ctrl.data.emergency,
        },
      ];
    }

    /**
     * Generate the chart object from db status
     * If the status isn't set, return a chart object with loading set to true
     *
     * @method    updateChart
     * @returns   {Object}    chartObject
     */
    function updateChart() {
      var percent = 0;
      if (!$ctrl.data) {
        return {
          loading: true,
          chartType: 'pie',
          series: [],
          chart: { height: chartDimension, width: chartDimension }
        };
      }
      if ($ctrl.data.count) {
        percent = Math.floor(($ctrl.data.online / $ctrl.data.count) * 100);
      }
      return {
        chartType: 'pie',
        loading: false,
        title: {
          text: [percent, '%'].join(''),
          align: 'center',
          verticalAlign: 'middle',
          x: 0,
          y: 14,
          margin: 0,
          widthAdjust: 0,
          style: {
            fontSize: '15px',
            color: CHART_COLORS.textGrey,
          },
        },
        legend: {
          enabled: false
        },
        chart: {
          height: chartDimension,
          width: chartDimension,
          backgroundColor: 'transparent'
        },
        colors: [CHART_COLORS.colorPrimary, CHART_COLORS.plotBorder],
        tooltip: {
          enabled: false
        },
        plotOptions: {
          pie: {
            size: chartDimension,
            innerSize: [(100 - (8 / chartDimension) * 100), '%'].join(''),
            center: [null, null],
            cursor: null,
            dataLabels: {
              enabled: false
            },
          }
        },
        series: [{
          type: 'pie',
          name: 'databases',
          states: {
            hover: false
          },
          data: [
            ['', $ctrl.data.online || 0],
            ['', percent ? ($ctrl.data.count - $ctrl.data.online) : 1],
          ]
        }],
      };

    }
  }

})(angular);
