import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AngularMaterialModule } from '../../angular-material.module';
import { IconModule } from '../icon/icon.module';
import { MenuComponent } from './menu.component';

const COMPONENTS = [
  MenuComponent,
];

@NgModule({
  imports: [
    AngularMaterialModule,
    CommonModule,
    IconModule,
  ],
  declarations: COMPONENTS,
  exports: COMPONENTS
})
export class MenuModule { }
