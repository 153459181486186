// Controller: Top Files Report

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.reports')
    .controller('reportsFilesTopController', reportsFilesTopControllerFn);

  function reportsFilesTopControllerFn($rootScope, $scope, $state, $stateParams,
   $filter, ReportsService, SourceService, evalAJAX, cUtils) {

    $scope.SourceService = SourceService;

    angular.extend($scope.text, $rootScope.text.reportsFilesTopfilesTopfiles);

    // disabled the sidebar filters
    $scope.filterConfig.showReportFilter = false;
    $scope.filterConfig.showTimeFilter = false;
    $scope.filterConfig.showNDaysFilter = false;
    $scope.filterConfig.showStatusFilter = false;
    $scope.filterConfig.showJobNameFilter = false;
    $scope.filterConfig.showParentSourceFilter = false;
    $scope.filterConfig.showViewBoxFilter = false;
    $scope.filterConfig.showApplyFiltersButton = false;

    $scope.dataReady = false;
    $scope.files = [];

    /**
     * Loads all the necessary data for the report.
     *
     * @method   getData
     */
    function getData() {
      var _obj;
      var params = {};
      $scope.dataReady = false;
      $scope.files = [];
      if ($scope.selectedFilters.viewBox.id) {
        params.viewBoxIds = [$scope.selectedFilters.viewBox.id];
      }
      if ($stateParams.objId) {
        params.objectIds = [$stateParams.objId];
      }
      if ($stateParams.jobId) {
        params.jobIds = [parseInt($stateParams.jobId, 10)];
      }
      ReportsService.getStorageByFileTop(params).then(function(r) {
        if (evalAJAX.success(r) && r.data) {
          r.data.map(function(file) {
            _obj = angular.extend({}, file);
            _obj._registeredSource = SourceService.getEntityName(file.fileEntry.registeredSource);
            _obj._protectedObject = SourceService.getEntityName(file.fileEntry.objectId);
            $scope.files.push(_obj);
          });
          buildDataChart(r.data);
        }
        $scope.dataReady = true;
        $scope.topVMsStorageChart.loading = false;
      });
    }

    /**
     * Build data objects for files by size chart
     *
     * @method   buildDataChart
     * @param    {object}   data   The data
     */
    function buildDataChart(data) {
      var bytesData = [];
      var bytesCategories = [];
      var sizeUnit;
      var chartMaxJobsToDisplay = 20;

      var processedData = $filter('filter')(data, function(value, index) {
        return value.fileEntry.hasOwnProperty('logicalSizeBytes');
      });
      processedData = $filter('orderBy')(processedData, 'fileEntry.logicalSizeBytes', true);
      processedData = $filter('limitTo')(processedData, chartMaxJobsToDisplay);
      $scope.topVMsStorageChart.categories = [];

      angular.forEach(processedData, function(object, index) {
        // find the units for the largest job and use it for our yAxis labels and conversions
        if (index === 0) {
          sizeUnit = cUtils.bytesToSize(object.fileEntry.logicalSizeBytes).unit;
        }
        bytesData.push([
          object.fileEntry.filename,
          cUtils.bytesToUnit(object.fileEntry.logicalSizeBytes, sizeUnit)
        ]);
        bytesCategories.push(object.fileEntry.filename);
      });

      // update chart labels to match scale/unit of largest item
      $scope.topVMsStorageChart.yAxis.labels.format = '{value} ' + sizeUnit;
      $scope.topVMsStorageChart.yAxis.title.text = sizeUnit;
      $scope.topVMsStorageChart.tooltip.pointFormat = '<b>{point.y:.1f} ' + sizeUnit + '</b>';

      $scope.topVMsStorageChart.series[0].data = bytesData;
      $scope.topVMsStorageChart.xAxis.categories = bytesCategories;
    }

    // Config object for topVMsStorageChart
    $scope.topVMsStorageChart = {
      chartType: 'basic',
      loading: true,
      series: [{
        name: 'MB',
        data: []
      }],
      yAxis: {
        labels: {
          format: '{value} MB'
        },
        allowDecimals: false,
        title: {
          text: null
        }
      },
      tooltip: {
        pointFormat: '<b>{point.y:.1f} MB</b>'
      },
      xAxis: {
        categories: [],
        labels: {
          rotation: -45,
          style: {
            whiteSpace: "nowrap"
          },
          formatter: function() {
            // do truncation here and return string
            if (this.value.length > 15) {
              return this.value.slice(0, 15) + '...';
            } else {
              return this.value;
            }
          }
        }
      }
    };

    // run setup function on first load
    getData();

    // then listen for request to run it again
    $scope.$on('applyFilters', getData);
  }

})(angular);
