import { threatTagNames } from '@cohesity/shared/tags';
import { CellColorStates } from './cell-colors.constants';

/**
 * An enum for all possible threat statuses that could be displayed in the threats column
 */
export enum ThreatStatus {
  detected = 'detected',
  not_found = 'not_found',
  no_scans = 'no_scans',
  unknown = 'unknown',
}

/**
 * A map of threat status to the corresponding display label key
 */
export const threatStatusLabelKeys: Record<ThreatStatus, string> = {
  [ThreatStatus.detected]: 'detected',
  [ThreatStatus.not_found]: 'kNone',
  [ThreatStatus.unknown]: '-',
  [ThreatStatus.no_scans]: 'scanNow'
};

/**
 * A map of threat status to the background cell color
 */
export const threatStatusColors: Record<ThreatStatus, CellColorStates> = {
  [ThreatStatus.detected]: CellColorStates.high,
  [ThreatStatus.not_found]: CellColorStates.ok,
  [ThreatStatus.unknown]: CellColorStates.unknown,
  [ThreatStatus.no_scans]: CellColorStates.info,
};

/**
 * Mapped tag names for the classifications statuses
 */
export const threatsTagNames: Record<ThreatStatus, string> = {
  [ThreatStatus.detected]: threatTagNames.detected,
  [ThreatStatus.not_found]: threatTagNames.none,
  [ThreatStatus.no_scans]: '',
  [ThreatStatus.unknown]: ''
};
