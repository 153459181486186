import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import { Ipv4AddressValidator, Ipv6AddressValidator } from '@cohesity/shared-forms';
import { AutoDestroyable } from '@cohesity/utils';
import { filter, takeUntil } from 'rxjs/operators';
import { IpAddressFamily } from 'src/app/shared/constants';
import { DnsDelegationZone } from '../internal-dns.models';

/**
 * This component is to add dns server and vips mapping entry.
 *
 * @example
 *   <coh-dns-vips-entry [ipPreference]="ipPreference" [index]="i" [formGroup]="formGroup"></coh-dns-vips-entry>
 */
@Component({
  selector: 'coh-dns-vips-entry',
  templateUrl: './dns-vips-entry.component.html',
  styleUrls: ['./dns-vips-entry.component.scss']
})
export class DnsVipsEntryComponent extends AutoDestroyable implements OnChanges {
  /**
   * IP address preference of the vip address entries.
   */
  @Input() ipPreference?: IpAddressFamily;

  /**
   * Index of this components form control in form array.
   */
  @Input() index?: number;

  /**
   * Form group for the data.
   */
  @Input() model: DnsDelegationZone;

  /**
   * Dispatched event when add form control button is clicked.
   */
  @Output() readonly addRow = new EventEmitter();

  /**
   * Dispatched event when add form control button is clicked.
   */
  @Output() readonly removeRow = new EventEmitter<number>();

  /**
   * Form group for the data.
   */
  formGroup: UntypedFormGroup;

  /**
   * Separators for VIP input field.
   */
  readonly separatorKeysCodes: number[] = [ENTER, COMMA, SPACE];

  /**
   * Set of vip address entered by user.
   */
  private vipAddressesSet: Set<string> = new Set();

  /**
   * Set of vip resolved address entered by user.
   */
  private vipResolvedAddressesSet: Set<string> = new Set();

  constructor() {
    super();
    this.formGroup = new UntypedFormGroup({
      dnsServer: new UntypedFormControl('', [Validators.required]),
      dnsServerVips: new UntypedFormControl([], [Validators.required]),
      resolvedVips: new UntypedFormControl([])
    });

    this.formGroup.valueChanges
      .pipe(takeUntil(this._destroy),
        filter(() => this.formGroup.valid))
      .subscribe(formValue => {
        this.model.dnsZoneName = formValue.dnsServer.trim();
        this.model.dnsZoneVips = formValue.dnsServerVips;
        this.model.dnsZoneResolvedVips = formValue.resolvedVips;
      });
  }

  /**
   * Listen for changes to the inputs and outputs and update the component
   * bindings if necessary.
   *
   * @param   changes   sumary of changed properties on the component.
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes.model && changes.model.firstChange) {
      // Set form control values.
      this.formGroup.controls.dnsServer.setValue(changes.model.currentValue.dnsZoneName, {emitEvent: false});
      this.formGroup.controls.dnsServerVips.setValue(changes.model.currentValue.dnsZoneVips || [], {emitEvent: false});
      this.formGroup.controls.resolvedVips.setValue(changes.model.currentValue.dnsZoneResolvedVips ||
        [], {emitEvent: false});
      this.vipAddressesSet = new Set(this.formGroup.controls.dnsServerVips.value);
      this.vipResolvedAddressesSet = new Set(this.formGroup.controls.resolvedVips.value);
    }
  }

  /**
   * Helper function to add vip to list of vips entries.
   *
   * @param vipAddress VIP address.
   * @param isResolvedVip True if vip address is a resolved one (in the resolvedVip control), otherwise false.
   */
  private addVipToControl(vipAddress: string, isResolvedVip = false) {
    const validValues: string[] = [];

    let currentAddressesSet: Set<string>;
    let currentControl: AbstractControl;

    if (isResolvedVip) {
      currentAddressesSet = this.vipResolvedAddressesSet;
      currentControl = this.formGroup.controls.resolvedVips;
    } else {
      currentAddressesSet = this.vipAddressesSet;
      currentControl = this.formGroup.controls.dnsServerVips;
    }

    if (vipAddress && !currentAddressesSet.has(vipAddress)) {
      validValues.push(vipAddress);
      currentAddressesSet.add(vipAddress);
    }
    const vips = [...currentAddressesSet];

    if (vips.some(vip => {
      const validator = this.ipPreference === IpAddressFamily.Ipv4 ? Ipv4AddressValidator : Ipv6AddressValidator;
      return validator(new UntypedFormControl(vip));
    })) {
      currentControl.setErrors({ipError: true});
      currentAddressesSet.delete(vipAddress);
    } else {
      currentControl.setValue(vips);
    }
  }

  /**
   * Handler for adding a new input value to list of chips.
   *
   * @param event Input Event.
   * @param isResolvedVip True if vip address is a resolved one (in the resolvedVip control), otherwise false.
   */
  addVip(event: MatChipInputEvent, isResolvedVip = false) {
    const value = event.value.trim();
    // Clear the input value.
    event.input.value = '';
    this.addVipToControl(value, isResolvedVip);
  }

  /**
   * Handler for paste event on subnet ips entries input.
   *
   * @param event Event of type ClipboardEvent.
   * @param isResolvedVip True if vip address is a resolved one (in the resolvedVip control), otherwise false.
   */
  pasteVipsInput(event: ClipboardEvent, isResolvedVip = false) {
    const clipboardData = event.clipboardData;
    const clipboardText = clipboardData.getData('text');
    event.preventDefault();

    clipboardText.split(/;|,|\n/).forEach(ip => {
      const ipInput = ip.trim();
      if (ipInput) {
        this.addVipToControl(ipInput, isResolvedVip);
      }
    });
  }

  /**
   * Handler for removing an item from chip list.
   *
   * @param vipAddress vipAddress to be removed in the list.
   * @param isResolvedVip True if vip address is a resolved one (in the resolvedVip control), otherwise false.
   */
  remove(vipAddress: string, isResolvedVip: boolean = false) {
    if (isResolvedVip) {
      this.vipResolvedAddressesSet.delete(vipAddress);
      this.formGroup.controls.resolvedVips.setValue([...this.vipResolvedAddressesSet]);
    } else {
      this.vipAddressesSet.delete(vipAddress);
      this.formGroup.controls.dnsServerVips.setValue([...this.vipAddressesSet]);
    }
  }

  /**
   * Returns whether the form is valid or not.
   */
  isFormValid() {
    return this.formGroup.valid;
  }
}
