import { Component, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { BaseProtectionBuilderComponent } from '../../base-protection-builder/base-protection-builder.component';

@Component({
  selector: 'coh-settings-list-cache-optimization',
  templateUrl: './settings-list-cache-optimization.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class SettingsListCacheOptimizationComponent
  extends BaseProtectionBuilderComponent<boolean, any> {
  /**
   * Default cache-optimization value.
   */
  _value = false;

  /**
   * Override of addFormControl from BaseProtectionBuilderComponent so this component's FormControl isn't required.
   */
  addFormControl() {
    this.formGroup.addControl(this.name, new UntypedFormControl(this.value));
  }

  /**
   * Form control init.
   */
  initFormControl() {
    if (this.protectionGroup?.cacheOptimization) {
      this.value = !!this.protectionGroup.cacheOptimization;
      this.formControl.setValue(this.value);
    }
  }
}
