// MODULE: cStepper

;(function(angular, undefined) {

  angular.module('C.stepper', [])
    .directive('cStepper', cStepperFn);

  /**
   * @ngdoc directive
   * @name cStepper
   * @restrict AE
   *
   * @description
   * Displays a step map for multistep flows.
   *
   * @param   {Array}   steps array of step objects
   * @current {Integer} index of the currently active step, 1 based.
   *                    This param can be ommitted if the steps array objecst
   *                    include an 'active' boolean property to indicate current step
   *
   * @example
   <example module="cStepperExample">
   <file name="index.html">
   <div ng-controller="ExampleController">
      <!-- alternatively, current can be set to $getActiveIndex() if using multi-step-container directive -->
      <c-stepper steps="exampleSteps" current="currentStep"></c-stepper>
   </div>

   <script>
      angular.module('cStepperExample', [])
        .controller('ExampleController', ['$scope', function($scope) {
          $scope.exampleSteps = [{
            // DEPRECATED, use titleKey
            // string for step title
            title: 'Step Name'
            // string key from ui.json to display
            titleKey: 'flowName.stringKey'
            // active: true // optional property, to be used as an alternative to 'current' param
          }, {
            // string key from ui.json to display
            titleKey: 'flowName.stringKey'
            // active: false // optional property
          }];

          // if using multi-step-container directive, this can be automated
          // by assigning current="$getActiveIndex()" on the c-stepper directive
          $scope.currentStep = 1;
      }]);
   </script>
   </file>
   </example>
   *
   */



  function cStepperFn() {
    return {
      restrict: 'AE',
      replace: true,
      scope: {
        steps: '<',
        current: '<',
        size: '@?',
      },
      templateUrl: 'app/global/c-stepper/c-stepper.html',
      link: cStepperLinkFn,
    };

    function cStepperLinkFn(scope, elem, attrs) {

      // watch for changes to the steps object, and normalize the array.
      scope.$watch(
        'steps',
        function stepsChanged(newSteps) {
          if (Array.isArray(scope.steps)) {
            scope.steps.forEach(function transformStepFn(step) {
              step.titleKey = step.titleKey || step.title || step.label;
            });
          }
        },
        true
      );
    }
  }

})(angular);
