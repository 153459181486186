// Component: AD/LDAP Mini View Component

;(function(angular, undefined) {
  'use strict';

  var modName = 'C.tenants';
  var componentName = 'adLdapKeystoneMiniView';

  var configOptions = {
    bindings: {
      /**
       * Optional provide the inViewMode flag and if true then don't allow
       * editing.
       *
       * @type  {Boolean}  [inViewMode=undefined]
       */
      inViewMode: '<?',

      // @type  {Object}  tenant   The tenant object.
      tenant: '<',
    },
    controller: 'AdLdapDisplayCtrlFn',
    templateUrl: 'app/admin/access-management/tenants/ad-ldap-mini-view/ad-ldap-mini-view.html',
  };

  /**
   * @ngdoc component
   * @name C.tenants:adLdapMiniView
   *
   * @description
   * Shows all the AD/LDAP inside a mini accordion view. Takes in
   * tenant as a binding, inside which we are able to view/edit the
   * active directories and the LDAPs.
   *
   * @example
   * <ad-ldap-keystone-mini-view tenant="$ctrl.tenant"
   *  in-view-mode="true">
   * </ad-ldap-keystone-mini-view>
   *
   */
  angular.module(modName)
    .controller('AdLdapDisplayCtrlFn', adLdapDisplayCtrlFn)
    .component(componentName, configOptions);

    function adLdapDisplayCtrlFn(_) {
      var $ctrl = this;

      // declare component objects and methods
      _.assign($ctrl, {
        keystoneInput: {},
        keystoneOutput: {},
        assignKeystoneInput: assignKeystoneInput,
        unAssignKeystoneChange: unAssignKeystoneChange,

        // component life cycle methods
        $onInit: $onInit,
        $onChanges: $onChanges,
      });

      /**
       * Activate the controller
       *
       * @method   $onInit
       */
      function $onInit() {
        $ctrl.assignKeystoneInput();
        $ctrl.keystoneOutput = {
          unAssignKeystoneChange: $ctrl.unAssignKeystoneChange,
        };
      }

      /**
       * Handle tenant updates
       *
       * @method    $onChanges
       * @param     {object}   changes   angularjs component change object
       */
      function $onChanges(changes) {
        if(changes.tenant) {
          $ctrl.assignKeystoneInput();
        }
      }

      /**
       * Helper function to assign KeystoneInput object.
       */
      function assignKeystoneInput() {
        $ctrl.keystoneInput = {
          enableDelete: false,
          enableUnAssign: true,
          keystoneList: [$ctrl.tenant._keystone],
        };
      }

      /**
       * helper function to un-assign keystone from a organization
       *
       *  @param   {Object}   keystone   The keystone to be un-assigned.
       */
      function unAssignKeystoneChange (keystone) {
        if (keystone) {
          $ctrl.tenant._keystone = undefined;
          $ctrl.tenant._operatorRoles = undefined;
        };
      }

    }

})(angular);
