import { Directive, ElementRef, HostBinding, Input } from '@angular/core';

/**
 * Directive for floating form menu.
 *
 * @example
 *   <mat-card cogFloatingFormMenu section="formSection">
 *     <button mat-icon-button>
 *       <cog-icon shape="add"></cog-icon>
 *     </button>
 *   </mat-card>
 */
@Directive({
  selector: '[cogFloatingFormMenu]'
})
export class FloatingFormMenuDirective {

  /**
   * Controls the visibility of the floating form menu.
   * By default, set to true.
   */
  @HostBinding('class.hidden') isHidden = true;

  /**
   * Floating form menu top position in px;
   */
  public top = 0;

  /**
   * HostBinding for top position.
   */
  @HostBinding('style.top') get topPosition() {
    return `${this.top}px`;
  }

  /**
   * Associate form section. A floating form menu can be associated
   * with multiple form groups with the same section identifier.
   */
  @Input() section = '';

  constructor(private elementRef: ElementRef) { }

  /**
   * Checks if an element is contained within the host.
   *
   * @param    element   HTML element.
   * @returns  true if the host contains the element.
   */
  containsElement(element: HTMLElement | Element): boolean {
    return this.elementRef.nativeElement.contains(element);
  }
}
