import { ViewportSize } from './reflow.service';

/**
 * These are the various reflow rules that define how a dashboard
 * will reconfigure itself and it's tiles when the viewport changes.
 *
 */
export type DashboardReflowRule =
  | 'default'
  | '3-cols'
  | '2-cols'
  | '3-cols-collapse@md'
  | '3-cols-collapse@sm';

/**
 * This type maps a rule name to a json config that
 * defines how that rule behaves at different viewports.
 */
export type DashboardReflowRuleMap = {
  [key in DashboardReflowRule]: ViewportToDashboardGridConfig;
};

/**
 * Dashboards can have 2 or 3 columns.  And as the viewport shrinks,
 * the number of columns also shrinks, and the tiles within the
 * dashboard change how many columns they span accordingly.
 *
 * The basic model maps a viewport size to a dashboard configuration.
 *
 */
export type ViewportToDashboardGridConfig = {
  [key in ViewportSize]: DashboardGridConfig;
};

/**
 * This config defines the number of columns in the gridlist
 * and how many columns a tile spans according to it's size.
 */
export interface DashboardGridConfig {
  columns: number;
  tileConfig: TileConfig;
}

/**
 * Tiles express their general size requirements with this enum
 */
export enum TileSizeEnum {
  'lg' = 'lg',
  'md' = 'md',
  'sm' = 'sm',
}

export type TileSize = TileSizeEnum.lg | TileSizeEnum.md | TileSizeEnum.sm;

/**
 * The model that maps tileSize to colspans
 */
export type TileConfig = {
  [key in TileSizeEnum]: number;
};

/**
 * The default tile size to colspan map, useful to initialize
 * tiles.
 */
export const defaultTileConfig: TileConfig = {
  lg: 3,
  md: 2,
  sm: 1,
};

/**
 * Configuration for 3 columns dashboards
 */
const config3Columns: DashboardGridConfig = {
  columns: 3,
  tileConfig: defaultTileConfig,
};

/**
 * Configuration for 2 column dashboards
 */
const config2Columns: DashboardGridConfig = {
  columns: 2,
  tileConfig: {
    lg: 2,
    md: 2,
    sm: 1,
  },
};

/**
 * Configuration for 1 column dashboards
 */
const config1Columns: DashboardGridConfig = {
  columns: 1,
  tileConfig: {
    lg: 1,
    md: 1,
    sm: 1,
  },
};

export const defaultGridConfig = config3Columns;

export const defaultReflowGridConfigs: ViewportToDashboardGridConfig = {
  xl: config3Columns,
  lg: config3Columns,
  md: config2Columns,
  sm: config1Columns,
  xs: config1Columns,
};

const reflow3ColsWrapAtMd: ViewportToDashboardGridConfig = {
  xl: config3Columns,
  lg: config3Columns,
  md: config1Columns,
  sm: config1Columns,
  xs: config1Columns,
};

const reflow3ColsWrapAtSm: ViewportToDashboardGridConfig = {
  xl: config3Columns,
  lg: config3Columns,
  md: config3Columns,
  sm: config1Columns,
  xs: config1Columns,
};

const reflow2Columns: ViewportToDashboardGridConfig = {
  xl: config2Columns,
  lg: config2Columns,
  md: config2Columns,
  sm: config1Columns,
  xs: config1Columns,
};

/**
 * For the gridlist in the dashboard, these are the most common configurations.
 * UI Devs can just pass in the key ( a DashboardReflowRule )
 * and the DashboardGridConfig can be looked up in this map.
 *
 * * For reference, this final object resembles:
 *
 *{
 *  "3-cols": {
 *       "lg": {
 *            "columns": 3,
 *            "tileConfig": {
 *              "lg": 3,
 *              "md": 2,
 *              "sm": 1
 *          }
 *      },
 *      "md": {
 *          "columns": 2,
 *          "tileConfig": {
 *              "lg": 2,
 *              "md": 2,
 *              "sm": 1
 *          }
 *      },
 *      ...
 *  },
 *  ...
 *}
 */
export const dashboardReflowConfigMap: DashboardReflowRuleMap = {
  default: defaultReflowGridConfigs,
  '3-cols': defaultReflowGridConfigs,
  '2-cols': reflow2Columns,
  '3-cols-collapse@md': reflow3ColsWrapAtMd,
  '3-cols-collapse@sm': reflow3ColsWrapAtSm,
};
