import { Component, HostListener, Input, Optional } from '@angular/core';
import { NavItem, SnackBarService } from '@cohesity/helix';
import { AutoDestroyable } from '@cohesity/utils';
import { TranslateService } from '@ngx-translate/core';
import { throwError } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ClusterService } from 'src/app/core/services';
import { ProtectionRun } from '../../models';
import { MenuItem, ProtectionRunService, ProtectionRunsService } from '../../services';

/**
 * @description
 * Protection Group item menu with preselected actions.
 *
 * @example
 *   <coh-group-menu [group]="myProtectionGroup"></coh-group-menu>
 */
@Component({
  selector: 'coh-runs-menu',
  templateUrl: './runs-menu.component.html',
  styleUrls: ['./runs-menu.component.scss'],
})
export class RunsMenuComponent extends AutoDestroyable {
  /**
   * Sets protection run instance.
   */
  @Input() set run(run: ProtectionRun) {
    this._run = run;
    this.menuItems = this.runService.getMenu(run);
  }


  /**
   * Returns protection run instance.
   */
  get run(): ProtectionRun {
    return this._run;
  }

  /**
   * when we want to disable from parent
   */
  @Input() disabled = false;

  /**
   * Return true if cluster is NGCE.
   */
  get cloudEditionEnabled(): boolean {
    return this.clusterService.isClusterNGCE;
  }

  /**
   * Protection run instance.
   */
  private _run: ProtectionRun;

  /**
   * Menu items for run item.
   */
  menuItems: NavItem[] = this.runService.InitialRunMenuItems;

  /**
   * Menu busy flag.
   */
  isLoading = false;

  /**
   * Constructor for RunsMenuComponent
   *
   * @param pgdService Injected `ProtectionRunsService` instance shared by `ProtectionGroupDetailsComponent`.
   * @param runService Injected `ProtectionRunService` instance.
   */
  constructor(
    @Optional() private pgdService: ProtectionRunsService,
    private clusterService: ClusterService,
    private runService: ProtectionRunService,
    private translate: TranslateService,
    private snackBarService: SnackBarService,
  ) {
    super();
  }

  /**
   * Prevents triggering outside mouse events when this component is clicked.
   */
  @HostListener('click', ['$event']) stopEventPropagation = (e: MouseEvent) => e.stopPropagation();

  /**
   * Handles edit run button click.
   *
   * @param item NavItem instance passed by `NavItemMenuComponent`.
   */
  actionHandler(menuItem: NavItem) {
    this.isLoading = true;
    this.runService
      .actionHandler( menuItem, this.run)
      .pipe(
        this.untilDestroy(),
        tap({
          next: () => (this.isLoading = false),
          error: e => {
            this.isLoading = false;
            return throwError(e);
          },
        })
      )
      .subscribe(
        () => {
          if (this.pgdService) {
            if (menuItem.displayName ===  MenuItem.EditRun) {
              this.snackBarService.open(this.translate.instant('editRunModal.saved'));
            }
            this.pgdService.reloadTable();
            this.pgdService.reloadCalendar();
          }
        },
        () => (this.isLoading = false)
      );
  }
}
