import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isUndefined, map, omitBy } from 'lodash';
import { Observable } from 'rxjs';

import { Api } from '../api.constants';
import { PassthroughOptions, ReadDirResult } from '../private-models';

/**
 * Params for for looking available snapshots for a given file.
 */
export interface GetFileVersionsParams {
  /**
   * Specifies the name of the file or folder to find in the snapshots.
   */
  filename: string;

  /**
   * Specifies the id of the Protection Source object (such as a VM) to search.
   */
  entityId: number;

  /**
   * Specifies the id of the Job that captured the snapshots.
   */
  jobId: number;

  /**
   * Specifies the Cohesity Cluster id where the Job was created.
   */
  clusterId: number;

  /**
   * Specifies the incarnation id of the Cohesity Cluster where the Job was created.
   */
  clusterIncarnationId: number;
}

export interface GetVolumeInfoParams {
  attemptNum: number;
  /**
   * Specifies the Cohesity Cluster id where the Job was created.
   */
  clusterId: number;
  /**
   * Specifies the incarnation id of the Cohesity Cluster where the Job was created.
   */
  clusterIncarnationId: number;
  /**
   * Specifies the id of the Protection Source object (such as a VM) to search.
   */
  entityId: number;
  /**
   * Specifies the id of the Job that captured the snapshots.
   */
  jobId: number;
  /**
   * The job instance to search
   */
  jobInstanceId: number;

  /**
   * The start time of the job instance to search.
   */
  jobStartTimeUsecs?: number;

  /**
   * Usually the same as the job instance, different if this is a replication?
   */
  jobUidObjectId: number;

  /**
   * Whether to return stat info for files.
   */
  statFileEntries: boolean;

  /**
   * Whether to use librarian for indexed data.
   */
  useLibrarian: boolean;

  /**
   * PointInTimeUsecs is the time to get volume info from previously available
   * snapshot before this time.
   */
  pointInTimeUsecs?: number;
}

export interface GetDirectoryListParams extends Partial<GetVolumeInfoParams> {
  /**
   * Optional param used to fetch additional entries for a directory.
   */
  cookie?: string | number;

  /**
   * The path, not including the volume of the directory to look up.
   */
  dirPath: string;

  /**
   * The name of the Protection source environment.
   */
  protectionSourceEnvironment?: string;

  /**
   * Cookie from the volme info api.
   */
  volumeInfoCookie?: string | number;

  /**
   * The name of the volume containing the directory.
   */
  volumeName?: string;

  /**
   * The view name.
   */
  viewName?: string;

  /**
   * The storage domain id.
   */
  viewBoxId?: string | number;

  /**
   * Optional param used to fetch total number of records in a single api call.
   */
  maxEntries?: number;
}

export interface GetFileStatParams extends Partial<GetVolumeInfoParams> {
  /**
   * Cookie from the volme info api.
   */
  volumeInfoCookie?: string | number;

  /**
   * The name of the volume containing the directory.
   */
  volumeName?: string;

  /**
   * The path, not including the volume of the file to look up.
   */
  filePath: string;

  /**
   * The view name.
   */
  viewName?: string;

  /**
   * The storage domain id.
   */
  viewBoxId?: string | number;
}

/**
 * This is a private api implementation for the files api.
 */
@Injectable({
  providedIn: 'root',
})
export class FilesApiService {
  constructor(private http: HttpClient) {}

  /**
   * Uses the private API to lookup up file versions information.
   *
   * @param   params   Params to pass to the file versions api.
   * @param   headers  Optional passthrough headers
   * @returns An observable stream of the result. Type info is not available for private apis.
   */
  getFileVersions(params: GetFileVersionsParams, headers?: PassthroughOptions): Observable<any> {
    return this.http.get<any>(Api.private('file', 'versions'), {
      headers: this.toHeaderParams(headers),
      params: {
        // httpClient requires that all get params be converted to string or string[].
        filename: params.filename,
        entityId: params.entityId.toString(),
        jobId: params.jobId.toString(),
        clusterId: params.clusterId.toString(),
        clusterIncarnationId: params.clusterIncarnationId.toString(),
      },
    });
  }

  /**
   * Uses the private API to look up Volume info
   *
   * @param   param   Params to pass to the volume api
   * @param   headers  Optional passthrough headers
   * @returns An observable of the volumes list. Type info is not available for private apis.
   */
  getVolumeInfo(params: GetVolumeInfoParams, headers?: PassthroughOptions): Observable<any> {
    const stringParams = Object.keys(params).reduce((tmpParams, key) => {
      if (params[key]) {
        tmpParams[key] = params[key] && params[key].toString();
      }
      return tmpParams;
    }, {});

    // Use sanitizeParameters method instead of delegating url encoding to
    // angular by using 'params' key since angular doesn't encode characters
    // like semicolon implicitly which could be present in dirPath.
    return this.http.get<any>(Api.private('vm', 'volumeInfo?' + this.sanitizeParameters(stringParams)), {
      headers: this.toHeaderParams(headers)
    });
  }

  /**
   * Uses the private API to list files in a directory
   *
   * @param   param   Params to pass to the fies api
   * @param   headers  Optional passthrough headers
   * @returns An observable of the directory list. Type info is not available for private apis.
   */
  getDirectoryList(params: GetDirectoryListParams, headers?: PassthroughOptions): Observable<ReadDirResult> {
    const stringParams = Object.keys(params).reduce((tmpParams, key) => {
      if (params[key]) {
        tmpParams[key] = params[key] && params[key].toString();
      }
      return tmpParams;
    }, {});

    // Use sanitizeParameters method instead of delegating url encoding to
    // angular by using 'params' key since angular doesn't encode characters
    // like semicolon implicitly which could be present in dirPath.
    //
    // TODO(Shishir): You can safely use the HttpParams for this/base-service
    // as well.
    return this.http.get<any>(Api.private('vm', 'directoryList?' + this.sanitizeParameters(stringParams)), {
      headers: this.toHeaderParams(headers)
    });
  }

  /**
   * Uses the private API to look up a file
   *
   * @param   param   Params to pass to the fies api
   * @param   headers  Optional passthrough headers
   * @returns An observable of the file stat into. Type info is not available for private apis.
   */
  getFileStat(params: GetFileStatParams, headers?: PassthroughOptions): Observable<any> {
    const stringParams = Object.keys(params).reduce((tmpParams, key) => {
      if (params[key]) {
        tmpParams[key] = params[key] && params[key].toString();
      }
      return tmpParams;
    }, {});

    return this.http.get<any>(Api.private('file', 'stat?' + this.sanitizeParameters(stringParams)), {
      headers: this.toHeaderParams(headers)
    });
  }

  // TODO: Shishir -> Duplicating this for now.
  // Once monorepo separation for libs and utilities is finalized,
  // use HttpParam(encodingStategy) implementation for this.
  /**
   * Convert an object into a url param string
   * eg: {a: 1, b: '2/3'} => a=1&b=2%2D3
   *
   * @param   params The params object to be converted
   * @returns The converted url string
   */
  private sanitizeParameters(params: any): string {
    return map(omitBy(params, isUndefined), (val, key) => {
      return [key, encodeURIComponent(val)].join('=');
    }).join('&');
  }

  /**
   * Convert passthrough options to headers
   *
   * @param headers passthrough options
   * @returns http headers
   */
  private toHeaderParams(headers: PassthroughOptions): HttpHeaders {
    if (headers?.accessClusterId) {
      return new HttpHeaders({
        accessClusterId: headers.accessClusterId.toString()
      });
    }
    if (headers?.regionId) {
      return new HttpHeaders({
        regionId: headers.regionId
      });
    }
    return null;
  }
}
