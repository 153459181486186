import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Directive,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { OPEN_CLOSE_ANIMATION } from '@cohesity/helix';
import { BehaviorSubject } from 'rxjs';

/**
 * Mark the form section summary with this directive.
 */
@Directive({
  selector: '[cohFormSectionSummary]',
})
export class FormSectionSummaryDirective {}

/**
 * Mark the form edit section with this directive.
 */
@Directive({
  selector: '[cohFormSectionEdit]',
})
export class FormSectionEditDirective {}

/**
 * Use this to add additional buttons that need to show in the edit
 */
@Directive({
  selector: '[cohFormSectionActions]',
})
export class FormSectionActionsDirective {}

/**
 * Use this to add banners with additional information about the recovery workflow
 */
@Directive({
  selector: '[cohFormSectionInfo]',
})
export class FormSectionInfoDirective {}

/**
 * This component is used to show a form section that can be toggled back and forth between
 * an edit mode and a view mode. The summary mode is switched to whenever the 'save' button is
 * clicked and the edit mode is switched to whenever the 'edit' button is clicked.
 *
 */
@Component({
  selector: 'coh-form-section-with-summary',
  templateUrl: './form-section-with-summary.component.html',
  styleUrls: ['./form-section-with-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [OPEN_CLOSE_ANIMATION],
})
export class FormSectionWithSummaryComponent {
  /**
   * Whether the edit section can be saved. If this is false the next button will
   * be disabled.
   */
  @Input() canSave = true;

  /**
   * Whether the form should always be on edit mode.
   */
  @Input() alwaysEdit = false;

  /**
   * Whether the component is in editing mode. While in editing mode the edit form section
   * will be shown. When not, the summary section will be shown.
   */
  @HostBinding('class.editing') @Input() set editing(editing: boolean) {
    this.editingSubject.next(editing);
  }

  /**
   * Get the current value of the editing property
   */
  get editing(): boolean {
    return this.editingSubject.value;
  }

  /**
   * Track the editing value as a subject.
   */
  private editingSubject = new BehaviorSubject<boolean>(true);

  /**
   * An observable of the editing value. This is very similar to editingChange, except that editingChange is only
   * emitted when this.setEditing() is called. editing is guaranteed to be updated any all changes to the property.
   */
  editing$ = this.editingSubject.asObservable();

  /**
   * Event for editing change. This enables two way binding for the editing property.
   */
  @Output() editingChange = new EventEmitter<boolean>();

  /**
   * The label to show for the button that saves the component and triggers showing the summary.
   */
  @Input() saveLabel: boolean;

  /**
   * The overall title for the component.
   */
  @Input() title: string;

  /**
   * Render this instead of a save button if it is present and let the parent manage the section logic.
   */
  @ContentChild(FormSectionActionsDirective) actionsOutlet;

  /**
   * Outlet to display any additional information about the workflow.
   */
  @ContentChild(FormSectionInfoDirective) infoOutlet;

  /**
   * Sets the editing property and emits the change event.
   *
   * @param   editing  The new editing value.
   */
  setEditing(editing) {
    // Avoid going to summary mode if edit is always allowed.
    if(!this.alwaysEdit) {
      this.editing = editing;
    }

    this.editingChange.emit(editing);
  }
}
