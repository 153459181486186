import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LoadingMaskDirective } from './loading-mask.directive';

@NgModule({
  imports: [CommonModule],
  exports: [LoadingMaskDirective],
  declarations: [LoadingMaskDirective],
})
export class LoadingMaskModule {}
