// Module: View Box Management Page

;(function(angular, undefined) {
  'use strict';

  angular.module('C.viewBoxes', ['C.seeMore', 'C.retention', 'C.kmsSelector'])
    .config(configFn)
    .controller('viewboxesController', viewboxesControllerFn);

  function configFn($stateProvider) {
    var viewAccess = 'STORAGE_DOMAIN_VIEW && CLUSTER_VIEW';
    var modifyAccess = 'STORAGE_DOMAIN_MODIFY';

    $stateProvider
      .state('cluster.viewboxes', {
        url: '^/platform/storagedomains?{partition}&{internal}',
        reloadOnSearch: false,
        help: 'admin_cluster_viewboxes',
        title: 'Manage Storage Domains',
        canAccess: viewAccess,
        parentState: 'cluster',
        params: {
          partition: { type: 'string', _persistOnImpersonation: true, },
          internal: { type: 'string', _persistOnImpersonation: true, },
        },
        views: {
          'cluster-content@cluster': {
            templateUrl: 'app/platform/viewboxes/viewboxes.html',
            controller: 'viewboxesController'
          }
        }
      })
      .state('viewbox', {
        url: '^/platform/storagedomain/{id}',
        help: 'platform_viewboxes_details',
        title: 'Storage Domain Detail',
        canAccess: viewAccess,
        params: {
          id: { type: 'string' },
        },
        templateUrl: 'app/platform/viewboxes/detail/detail.html',
        controller: 'viewboxDetailController',
        parentState: 'cluster.viewboxes',
      })

      //viewbox-edit flow can be accessed in a slider:
      // SlideModalService.editViewBoxModal(viewBoxId).then()
      .state('viewbox-edit', {
        url: '^/platform/storagedomains/edit/{viewBoxId}',

        // due to the optional state/modal implementation of this flow,
        // help for sub steps are defined in modify.js $scope.steps[]
        // and are applied dynamically in modify.js $scope.updateHelpId()
        help: '',
        title: 'Edit Storage Domain',
        canAccess: modifyAccess,
        params: {
          viewBoxId: { type: 'string' },
        },
        templateUrl: 'app/platform/viewboxes/modify/modify.html',
        controller: 'viewBoxModifyController',
        parentState: 'cluster.viewboxes',
      })

      // viewbox-new flow can be access in a slider:
      // SlideModalService.newViewBoxModal().then()
      .state('viewbox-new', {
        url: '^/platform/storagedomains/new',

        // due to the optional state/modal implementation of this flow,
        // help for sub steps are defined in modify.js $scope.steps[]
        // and are applied dynamically in modify.js $scope.updateHelpId()
        help: '',
        title: 'Create New Storage Domain',
        canAccess: modifyAccess,
        parentState: 'cluster.viewboxes',
        templateUrl: 'app/platform/viewboxes/modify/modify.html',
        controller: 'viewBoxModifyController',
      });
  }

  function viewboxesControllerFn($scope, $state, cMessage, ViewBoxService,
    ViewBoxModalService, ClusterService, evalAJAX, TenantService, $q) {

    var filterPartitionId;

    $scope.loadingViewBoxes = true;
    $scope.viewBoxes = [];

    $scope.filter = {
      tenantIds: [],
    };

    /**
     * activation/initialization function
     */
    function activate() {
      getViewBoxes();
      getClusterInfo();
    }

    /**
     * interfaces ClusterService to update $rootScope.clusterInfo. This ensures
     * that $rootScope.clusterInfo.stats referenced in the template are recent
     */
    function getClusterInfo() {
      $scope.updatingClusterInfo = true;
      ClusterService.getClusterInfo({ fetchStats: true }, {}, false).finally(
        function getClusterInfoSuccess() {
          $scope.updatingClusterInfo = false;
        }
      );
    }

    /**
     * Adds a context menu to the provided View Box
     *
     * @param      {object}  viewBox  The View Box
     * @return     {object}  the View Box with a _contextMenu added
     */
    function addContextMenuToViewBox(viewBox) {
      viewBox._contextMenu = [];

      viewBox._contextMenu.push({
        translateKey: 'edit',
        icon: 'icn-edit',
        state: 'viewbox-edit',
        stateParams: {
          viewBoxId: viewBox.id
        },
      });

      if (viewBox._allowViewCreation) {
        viewBox._contextMenu.push({
          translateKey: 'viewBoxes.createViewInViewBox',
          icon: 'icn-add-view',
          disabled: ClusterService.clusterInfo._isDisaggregatedStorage,
          action: function addViewActionFn() {
            ViewBoxService.createViewInViewBox(viewBox.id);
          },
        });
      }

      if ($scope.FEATURE_FLAGS.deleteViewBox &&
        viewBox.removalState === 'kDontRemove') {
        viewBox._contextMenu.push({
          translateKey: 'delete',
          icon: 'icn-delete',
          action: function delActionFn() {
            deleteViewBox(viewBox);
          },
        });
      }

      return viewBox;
    }

    /**
     * calls the API and gets the current list of View Boxes
     *
     * @method     getViewBoxes
     */
    function getViewBoxes() {
      var params = TenantService.extendWithTenantParams({
        includeStats: true,
        includeHidden: !!$state.params.internal,
        _includeTenantInfo: true,
      }, $scope.filter.tenantIds);
      params.includeTenants = params.allUnderHierarchy;

      var promises = {
        viewBoxes: ViewBoxService.getViewBoxes(params),

        // Request to store cluster type in $rootScope.
        hardwareInfo: ClusterService.getHardwareInfo()
      };

      $scope.viewBoxes = [];
      $scope.loadingViewBoxes = true;
      $q.allSettled(promises).then(function allFetched(responses) {
        if (responses.viewBoxes) {
          $scope.viewBoxes = responses.viewBoxes.resp.map(addContextMenuToViewBox);
        }
      }, evalAJAX.errorMessage
      ).finally(
        function getViewBoxesFinally() {
          $scope.loadingViewBoxes = false;
          $scope.minNodes = $scope.clusterInfo.supportedConfig.minNodesAllowed;
        }
      );
    }

    /**
     * presents a challenge modal bofore making an API request to delete an NFS View
     *
     * @method   deleteViewBox
     * @param    {Object}     viewBox     Viewbox to be deleted.
     */
    function deleteViewBox(viewBox) {
      ViewBoxModalService.showDeleteModal(viewBox).then(
        function deletedViewBox() {
          $scope.viewBoxes.splice(_getViewBoxIndex(viewBox.id), 1);
          cMessage.success({
            textKey: 'viewBoxDetail.deleteSuccess.title',
          });
        },
        _.noop
      );
    };

    /**
     * finds and returns the index of the provided viewBoxId from within
     * $scope.viewBoxes for the sake of updating the array post
     * edit/deleted
     *
     * @param      {Integer}  viewBoxId  of the ViewBox index to find
     * @return     {Integer}  index value of the ViewBox within
     *                        $scope.viewBoxes
     */
    function _getViewBoxIndex(viewBoxId) {
      var len = $scope.viewBoxes.length;
      var index = 0;
      for (index; index < len; index++) {
        if ($scope.viewBoxes[index].id === viewBoxId) {
          return index;
        }
      }
      return null;
    }

    /**
     * Filter view boxes
     *
     * @method   getViewBoxes
     */
    $scope.filterViewBoxes = function filterViewBoxes() {
      getViewBoxes();
    };

    /**
     * Sorts the compression policy values for st-table. Returns an arbitrary
     * numeric sort value.
     *
     * @param      {Object}  row     The row
     * @return     {Number}  sort value
     */
    $scope.sortCompressionPolicy = function sortCompressionPolicy(row) {
      switch (true) {
        case row.storagePolicy.compressionPolicy === 'kCompressionNone':
          return 1;

        case row.storagePolicy.inlineCompress:
          return 2;

        default:
          return 3;
      }
    };

    activate();
  }

})(angular);
