/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityApiConfiguration as __Configuration } from '../cohesity-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { AccessToken } from '../models/access-token';
import { AccessTokenCredential } from '../models/access-token-credential';
@Injectable({
  providedIn: 'root',
})
class AccessTokensService extends __BaseService {
  static readonly GenerateAccessTokenPath = '/public/accessTokens';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Generate an Access Token.
   *
   * Before making other REST API requests, your REST client must make a
   * 'POST /public/accessToken' request with a valid Cohesity username and
   * password. This POST request returns an access token and type
   * in the response that is generated by the Cohesity Cluster.
   * Subsequent requests to other Cohesity REST API operations must
   * specify the returned access token and type by setting 'Authorization'
   * in the http header in the following format:
   *
   * Authorization: token_type access_token
   *
   * The generated token is valid for 24 hours. If a request is made with
   * an expired token, the 'Token expired' error message is returned.
   * Add code to your REST client to check for this error and request
   * another access token before reissuing the request.
   * @param params The `AccessTokensService.GenerateAccessTokenParams` containing the following parameters:
   *
   * - `body`: Request to generate access token.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GenerateAccessTokenResponse(params: AccessTokensService.GenerateAccessTokenParams): __Observable<__StrictHttpResponse<AccessToken>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/accessTokens`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AccessToken>;
      })
    );
  }
  /**
   * Generate an Access Token.
   *
   * Before making other REST API requests, your REST client must make a
   * 'POST /public/accessToken' request with a valid Cohesity username and
   * password. This POST request returns an access token and type
   * in the response that is generated by the Cohesity Cluster.
   * Subsequent requests to other Cohesity REST API operations must
   * specify the returned access token and type by setting 'Authorization'
   * in the http header in the following format:
   *
   * Authorization: token_type access_token
   *
   * The generated token is valid for 24 hours. If a request is made with
   * an expired token, the 'Token expired' error message is returned.
   * Add code to your REST client to check for this error and request
   * another access token before reissuing the request.
   * @param params The `AccessTokensService.GenerateAccessTokenParams` containing the following parameters:
   *
   * - `body`: Request to generate access token.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GenerateAccessToken(params: AccessTokensService.GenerateAccessTokenParams): __Observable<AccessToken> {
    return this.GenerateAccessTokenResponse(params).pipe(
      __map(_r => _r.body as AccessToken)
    );
  }
}

module AccessTokensService {

  /**
   * Parameters for GenerateAccessToken
   */
  export interface GenerateAccessTokenParams {

    /**
     * Request to generate access token.
     */
    body: AccessTokenCredential;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { AccessTokensService }
