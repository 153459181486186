<cog-filters *ngIf="!optimizedListView">
  <cog-date-range-filter *cogFilterDef="let filter;
      let params = params;
      key: 'dateRange';
      label: 'date' | translate;
      filterType: 'dateRange';
      quickFilter: true"
    [filter]="filter"
    [useTimeframe]="true"
    [filterDefParams]="params">
  </cog-date-range-filter>
</cog-filters>

<cog-spinner *ngIf="loading"></cog-spinner>
<cog-table *ngIf="!loading"
  [source]="(filteredSnapshots$ | async) || []"
  [selection]="selection"
  [canSelectRowFn]="canSelectRow"
  [selectRowTooltipFn]="selectRowTooltip"
  name="snapshot-selector-table"
  [persistSelection]="true">
  <table mat-table multiTemplateDataRows>
    <coh-table-column-extension [position]="2"
      [extensionPoint]="snapshotExtensionPoint"
      [getRowDataFn]="getExtensionRowData">
    </coh-table-column-extension>

    <!-- Snapshot Timestamp -->
    <ng-container matColumnDef="snapshotTimestampUsecs">
      <th mat-header-cell *matHeaderCellDef>{{'snapshotTime' | translate}}</th>
      <td mat-cell *matCellDef="let row">
        {{ row.snapshotTimestampUsecs | cohDate }}
      </td>
    </ng-container>

    <!-- Last Modified Timestamp -->
    <ng-container matColumnDef="lastModifiedTimeUsecs">
      <th mat-header-cell *matHeaderCellDef>{{'modified' | translate}}</th>
      <td mat-cell *matCellDef="let row" [ngSwitch]="row.lastModifiedTimeUsecs > 0">
        <ng-container *ngSwitchCase="true">
          {{ row.lastModifiedTimeUsecs | cohDate }}
        </ng-container>

        <ng-container *ngSwitchDefault>
          {{ 'naNotAvailable' | translate }}
        </ng-container>
      </td>
    </ng-container>

    <!-- Run type -->
    <ng-container matColumnDef="runType">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'backupType' | translate }}
      </th>
      <td mat-cell *matCellDef="let row">
        <ng-container *ngIf="row.runType">{{ 'enum.jobRunType.' + row.runType | translate }}</ng-container>
        <ng-container *ngIf="!row.runType">-</ng-container>
      </td>
    </ng-container>

    <!-- Snapshot Size -->
    <ng-container matColumnDef="sizeBytes">
      <th mat-header-cell *matHeaderCellDef>{{'size' | translate}}</th>
      <td mat-cell *matCellDef="let row">
        {{ row.sizeBytes | byteSize }}
      </td>
    </ng-container>

    <!-- Tags -->
    <ng-container matColumnDef="tags">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row; index as i; dataIndex as dataIndex">
        <coh-colored-tags [tags]="getSnapshotTags(row)" cogDataId="row-{{i || dataIndex}}-taglist"></coh-colored-tags>
      </td>
    </ng-container>

    <!-- Snapshot Locations -->
    <ng-container matColumnDef="targets">
      <th [class.hide-for-csm]="hideBackupType" mat-header-cell *matHeaderCellDef>{{'location' | translate}}</th>
      <td [class.hide-for-csm]="hideBackupType" mat-cell
        *matCellDef="let row"
        cogDataId="snapshot-{{row.defaultSnapshot?.id}}">
        <span *ngIf="row.localSnapshot"
          class="margin-right-sm">
          <button (click)="selectSnapshot(row.localSnapshot.id)"
            mat-icon-button
            type="button"
            [color]="value?.id === row.localSnapshot.id && 'primary'"
            [matTooltip]="getTargetTooltipData(row.localSnapshot) | translate"
            cogDataId="select-local"
            [inheritDataId]="true">
            <cog-icon
              [shape]="(row.localSnapshot.runType === 'kStorageArraySnapshot'? row.localSnapshot.runType : 'local')
               | backupTargetIcon">
            </cog-icon>
          </button>
        </span>
        <span *ngFor="let snapshot of [row.archiveSnapshots || [], row.cloudVaultSnapshots || []].flat()"
          class="margin-right-sm"
          [matTooltip]="snapshotSelectorUtilsService.getSnapshotTooltip(snapshot)">
          <button mat-icon-button
            type="button"
            [color]="value?.id === snapshot.id && 'primary'"
            [disabled]="snapshotSelectorUtilsService.getSnapshotIsDisabled(snapshot)"
            (click)="selectSnapshot(snapshot.id)"
            cogDataId="select-{{snapshot.externalTargetInfo?.targetName}}"
            [inheritDataId]="true">
            <cog-icon [shape]="snapshotSelectorUtilsService.getSnapshotIcon(snapshot)">
            </cog-icon>
          </button>
        </span>
      </td>
    </ng-container>
    <ng-container matColumnDef="tapeAlert">
      <td mat-cell
        *matCellDef="let row"
        colspan="50">
        <div class="tape-message-detail" [@openClose]="showTapeMessage(row) ? 'open' : 'close'">
          <div class="c-message-inline icon-message info">
            {{'vmSnapshotSelectModal.tapeSelected.text' | translate}}
          </div>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
    <tr mat-row class="snapshot-row"
      *matRowDef="let row; columns: tableColumns"
      [class.snapshot-expanded-row]="showTapeMessage(row)">
    </tr>
    <tr mat-row class="tape-message-row" *matRowDef="let row; columns: ['tapeAlert']"></tr>
  </table>
  <mat-paginator></mat-paginator>
</cog-table>
