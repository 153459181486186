import { AppModuleConfig, StateAccessMap, StateAccessContext } from 'src/app/app-module.config';

const ipmiAccess = (ctx: StateAccessContext) => (ctx.FEATURE_FLAGS.ipmiSettingsEnabled ||
  ctx.FEATURE_FLAGS.ngNetworking) && ctx.CLUSTER_MODIFY;

export const IpmiConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'networking.ipmi.**',
      url: '^/platform/networking/ipmi',
      loadChildren: () => import('./ipmi.module').then(m => m.IpmiModule)
    },
    {
      name: 'ipmi-setup.**',
      url: '^/platform/networking/ipmi/setup',
      loadChildren: () => import('./ipmi.module').then(m => m.IpmiModule)
    },
  ],
  getStateAccessMap(): StateAccessMap {
    return {
      'networking.ipmi': ipmiAccess,
      'networking.ipmi.settings': ipmiAccess,
      'ipmi-setup': ipmiAccess,
    };
  }
};
