<cog-settings-list-item [formGroup]="formGroup"
  [invalid]="formControl.invalid"
  [label]="'endDate' | translate">
  <cog-settings-list-item-value-display>
    {{endDateFormGroup.get('enabled').value ?
      (endDateFormGroup.get('value').value | date: 'MMM d, y') :
      ('never' | translate)}}
  </cog-settings-list-item-value-display>
  <cog-settings-list-item-value-editor [formGroup]="endDateFormGroup"
    cogDataId="settings-end-date">
    <mat-slide-toggle [inheritDataId]="true"
      cogDataId="toggle"
      formControlName="enabled">
      {{'endDate' | translate}}
    </mat-slide-toggle>
    <mat-form-field *ngIf="endDateFormGroup.get('enabled').value">

      <!-- The minimum date is today's date as end date cannot be in the past -->
      <input formControlName="value"
        [inheritDataId]="true"
        cogDataId="date-value"
        [matDatepicker]="endDatePicker"
        matInput>
      <mat-datepicker-toggle [for]="endDatePicker"
        cogDataId="end-date-calendar-toggle"
        matSuffix>
      </mat-datepicker-toggle>
      <mat-datepicker #endDatePicker>
      </mat-datepicker>
    </mat-form-field>
  </cog-settings-list-item-value-editor>
</cog-settings-list-item>
