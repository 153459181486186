/**
 * Constant which maps public status of kValues to corresponding string values
 */
export enum PublicStatusMapping {
  kAccepted = 'accepted',
  kSuccess = 'success',
  kFailure = 'error',
  kCanceled = 'canceled',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  kCancelled = 'canceled',
  kRunning = 'running',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  kError = 'error',
}
