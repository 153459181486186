/* eslint-disable @typescript-eslint/no-namespace */
import { StorageDomain } from '@cohesity/api/v2';
import { get, compact } from 'lodash';

export namespace KmsModel {
  /**
   * The different KMS Key Types
   */
  export enum KeyTypes {
    kInternalKMS = 'kInternalKMS',
    kCryptsoftKMS = 'kCryptsoftKMS',
    kAwsKMS = 'kAwsKMS',
    kAzureKMS = 'kAzureKMS',
  }

  /**
   * The display labels for different KMS Key Types
   */
  export const KeyTypeLabels = [{
    label: 'internalKms',
    value: KeyTypes.kInternalKMS,
  }, {
    label: 'kmipCompliant',
    value: KeyTypes.kCryptsoftKMS,
  }, {
    label: 'aws',
    value: KeyTypes.kAwsKMS,
  }, {
    label: 'azure',
    value: KeyTypes.kAzureKMS,
  }];

  /**
   * The different Authentication Methods for AWS KMS
   */
  export enum AuthMethods {
    IamUser = 'kUseIAMUser',
    IamRole = 'kUseIAMRole',
  }

  /**
   * The kms key configuration properties
   */
  export interface FormModel {
    id: number;
    name: string;
    keyConfiguration: {
      keyType: string;
      protocolVersion?: string;
      serverIp?: string;
      serverIpList?: string[];
      additionalServerAddress?: string[];
      port?: number;
      authenticationMethod?: string;
      arn?: string;
      accessKeyId?: string;
      secretAccessKey?: string;
      iamRoleArn?: string;
      verifySSL?: boolean;
      azureClientId?: string;
      azureClientSecretKey?: string;
      azureKmsAuthMethod?: string;
      azureTenantId?: string;
      customerSecretName?: string;
      azureSecretAccessKey?: string;
      keyvaultKeyName?: string;
      keyvaultUrl?: string;
      vaultOwner?: string;
    };
    certificates: {
      clientCertificate?: string;
      caCertificate?: string;
      clientKey?: string;
    };
    storageConfiguration: {
      storageDomains: StorageDomain[];
      externalTargets: any;
    };
  }

  export interface ApiModel {
    id: number;
    keyName: string;
    serverType: string;
    cryptsoftKms?: {
      kmipProtocolVersion?: string;
      serverIp?: string;
      serverPort?: number;
      clientCertificate?: string;
      caCertificate?: string;
      clientKey?: string;
      additionalServerAddress?: string[];
    };
    awsKms?: {
      cmkArn?: string;
      accessKeyId?: string;
      secretAccessKey?: string;
      authMethod?: string;
      iamRoleArn?: string;
      verifySSL?: boolean;
    };
    azureKms?: {
      azureClientId?: string;
      azureClientSecretKey?: string;
      azureKmsAuthMethod?: string;
      azureTenantId?: string;
      customerSecretName?: string;
      azureSecretAccessKey?: string;
      keyvaultKeyName?: string;
      keyvaultUrl?: string;
      vaultOwner?: string;
    };
    viewBoxIdList?: number[];
    vaultIdList?: number[];
  }

  /**
   * Converts a kms key api object to a form model.
   *
   * @param   value   The api protection policy response.
   * @return  The policy form model.
   */
  export function toFormModel(apiValue: ApiModel, views?: StorageDomain[], vaults?: any): FormModel {
    const { id, keyName, serverType, cryptsoftKms, awsKms, azureKms, viewBoxIdList, vaultIdList } = apiValue;

    return {
      id,
      name: keyName,
      keyConfiguration: {
        keyType: serverType,
        protocolVersion: get(cryptsoftKms, 'kmipProtocolVersion'),
        serverIp: get(cryptsoftKms, 'serverIp'),
        additionalServerAddress: get(cryptsoftKms, 'additionalServerAddress'),
        port: get(cryptsoftKms, 'serverPort'),
        authenticationMethod: get(awsKms, 'authMethod'),
        arn: get(awsKms, 'cmkArn'),
        accessKeyId: get(awsKms, 'accessKeyId'),
        secretAccessKey: get(awsKms, 'secretAccessKey'),
        iamRoleArn: get(awsKms, 'iamRoleArn'),
        verifySSL: get(awsKms, 'verifySSL'),
        azureClientId: get(azureKms, 'azureClientId'),
        azureClientSecretKey: get(azureKms, 'azureClientSecretKey'),
        azureKmsAuthMethod: get(azureKms, 'azureKmsAuthMethod'),
        azureTenantId: get(azureKms, 'azureTenantId'),
        customerSecretName: get(azureKms, 'customerSecretName'),
        azureSecretAccessKey: get(azureKms, 'azureSecretAccessKey'),
        keyvaultKeyName: get(azureKms, 'keyvaultKeyName'),
        keyvaultUrl: get(azureKms, 'keyvaultUrl'),
        vaultOwner: get(azureKms, 'vaultOwner'),
      },
      certificates: {
        clientCertificate: get(cryptsoftKms, 'clientCertificate'),
        caCertificate: get(cryptsoftKms, 'caCertificate'),
        clientKey: get(cryptsoftKms, 'clientKey'),
      },
      storageConfiguration: {
        storageDomains: compact((viewBoxIdList || []).map(vb => (views || []).find(view => view.id === vb))),
        externalTargets: compact((vaultIdList || []).map(vault => (vaults || []).find(vt => vt.id === vault))),
      }
    };
  }

  /**
   * Converts a kms key form model to an api model.
   *
   * @param   value   The kms key form model.
   * @return  The api kms key model.
   */
  export function fromFormModel(formValue: FormModel, isEditMode: boolean): ApiModel {
    const { id, name, keyConfiguration, certificates, storageConfiguration } = formValue;
    const viewBoxIdList = storageConfiguration.storageDomains.map(sd => sd.id);
    const vaultIdList = storageConfiguration.externalTargets.map(et => et.id);

    return {
      id: id || undefined,
      keyName: name,
      serverType: keyConfiguration.keyType,
      cryptsoftKms: keyConfiguration.keyType === KeyTypes.kCryptsoftKMS ? {
        kmipProtocolVersion: keyConfiguration.protocolVersion,
        serverIp: keyConfiguration.serverIpList[0] || '',
        serverPort: keyConfiguration.port,
        clientCertificate: certificates.clientCertificate,
        caCertificate: certificates.caCertificate,
        clientKey: certificates.clientKey,
        additionalServerAddress: keyConfiguration.serverIpList.slice(1, keyConfiguration.serverIpList.length)
      } : undefined,
      awsKms: keyConfiguration.keyType === KeyTypes.kAwsKMS ? {
        authMethod: keyConfiguration.authenticationMethod,
        cmkArn: keyConfiguration.arn,
        accessKeyId: keyConfiguration.accessKeyId,
        secretAccessKey: keyConfiguration.secretAccessKey,
        iamRoleArn: keyConfiguration.iamRoleArn,
        verifySSL: keyConfiguration.verifySSL,
      } : undefined,
      azureKms: keyConfiguration.keyType === KeyTypes.kAzureKMS ? {
        azureClientId: keyConfiguration.azureClientId,
        azureClientSecretKey: keyConfiguration.azureClientSecretKey,
        azureKmsAuthMethod: keyConfiguration.azureKmsAuthMethod,
        azureTenantId: keyConfiguration.azureTenantId,
        customerSecretName: keyConfiguration.customerSecretName,
        azureSecretAccessKey: keyConfiguration.azureSecretAccessKey,
        keyvaultKeyName: keyConfiguration.keyvaultKeyName,
        keyvaultUrl: keyConfiguration.keyvaultUrl,
        vaultOwner: keyConfiguration.vaultOwner,
      } : undefined,
      viewBoxIdList: viewBoxIdList.length && !isEditMode ? viewBoxIdList : undefined,
      vaultIdList: vaultIdList.length && !isEditMode ? vaultIdList : undefined,
    };
  }
}
