import { RegisteredSourceInfo } from '@cohesity/api/v1';
import { KeyValuePair, McmSourceRegistration } from '@cohesity/api/v2';
import { FieldType, FormField, FormPanel } from '@cohesity/helix';
import { DynamicForm } from './models';
import {
  CustomArgDisplayEntry,
  Environment,
  ManagedConnectorProtectionSettings,
  UdaEnvToSourceType,
  UdaSourceType,
} from '../constants';

/**
 * Convert the hostOsType to the translation key.
 *
 * @param   hostOsType   host os type.
 * @return translation key for the host os type.
 */
export function getHostOSTypeLabelKey(hostOsType: string): string {
  return 'hostType.' + hostOsType;
}

/**
 * Converts the string value of the generic form fields to its specific type.
 * This is used to get the values in the correct format before populating a form
 * field in an edit workflow.
 *
 * @param   type   Type of the form field.
 * @param   value   Value of the field in form of string.
 * @returns Value of the field according to the field type.
 */
export function convertFieldValues(type: FieldType, value: string): (string | number | boolean) {
  switch (type) {
    case 'boolean':
      return value === 'true' ? true : false;
    case 'number':
      return value ? +value : null;
    case 'password':
      // Nullify any password field. These will also get filtered out in the IRIS API
      // response. This is just a failsafe.
      return null;
    default:
      return value;
  }
}

/**
 * The default value is defined for any one of the configs or it may be null. This
 * function returns the default value which is defined.
 *
 * @param   typeConfig   Parameters relating to field type.
 * @returns Default value for the form field.
 */
export function getCustomFieldDefaultValue(field: FormField): (string | number | boolean) {
  if (field.type === 'boolean' && !field?.typeConfig?.booleanConfig?.defaultValue) {
    return false;
  }
  if (!field.typeConfig) {
    return null;
  }
  return field.typeConfig?.booleanConfig?.defaultValue ??
    field.typeConfig?.numberConfig?.defaultValue ??
    field.typeConfig?.stringConfig?.defaultValue ??
    field.typeConfig?.radioGroupConfig?.defaultValue ?? null;
}

/**
 * Gather all form fields from whithin a form panel and add their config objects
 * to a map. Instead of returning a config map, this function takes in the map
 * (to which the configs need to be added to) as a function argument. This is a
 * side effect but is needed to reduce the number of time the returned config
 * needs to be copied over to the caller's map.
 *
 * @param   panel   Form panel configuration object.
 * @param   formFieldMap   Form field map to which all field config objects need
 *          to be added to.
 * @param   filterPasswordFields   Whether to filter out password field configs
 *          while adding to map.
 */
function getFormFieldsMapFromFormPanel(
  panel: FormPanel,
  formFieldMap: Map<string, FormField>,
  filterPasswordFields = true
) {
  (panel?.fields || []).forEach((field) => {

    // Although password fields are encrypted and will get filtered by backend,
    // this is just a failsafe.
    if (field.type === 'password' && filterPasswordFields) {
      return;
    }

    formFieldMap.set(field.key, field);

    // Radio group buttons can thelseves have nested form panels. Traverse form
    // field in such panels as well.
    if (field.type === 'radioGroup') {
      const radioGroupConfig = field?.typeConfig?.radioGroupConfig;
      (radioGroupConfig?.radioButtons || []).forEach((radioButton) => {
        if (radioButton?.formPanel) {
          getFormFieldsMapFromFormPanel(radioButton.formPanel, formFieldMap);
        }
      });
    }
  });
}

/**
 * Gather all form fields from whithin a dynamic form config object and return
 * their config objects in a map.
 *
 * @param   dynamicForm   Dynamic form config for which all form field config
 *          objects need to be compiled into a map.
 * @param   filterPasswordFields   Whether to filter out password field configs
 *          while adding to map.
 * @returns Form field config map for a dynamic form. The keys in the map are
 *          the form field keys, which map to the form field config object.
 */
export function getFormFieldMapForDynamicForm(
  dynamicForm: DynamicForm,
  filterPasswordFields = true
): Map<string, FormField> {
  const formFieldMap = new Map<string, FormField>();

  (dynamicForm?.panels || []).forEach((panel) => {
    getFormFieldsMapFromFormPanel(panel, formFieldMap, filterPasswordFields);
  });

  return formFieldMap;
}

/**
 * Get the label and values for all form fields for which non-null values have
 * been provided by the user. For a radio button form field, in order to get
 * the correct value, we need to traverse the set of radio buttons within the
 * group and get the correct label for the selected value.
 *
 * @param   formFieldMap   Map of form field config objects key'd by field's
 *                         key.
 * @param   argumentsMap   Map of user specified custom argument key'd by the
 *                         form field's key.
 * @returns List of non-null values for a given group of form fields.
 */
 function getNonNullValuesForAFormFieldMap (
  formFieldMap: Map<string, FormField>,
  argumentsMap: { [key in string]: string }
): CustomArgDisplayEntry[] {
  const settings: CustomArgDisplayEntry[] = [];

  // Iterate over all form fields and check if values for them have been
  // provided by the user in the custom arguments map. When found, add to
  // result.
  Array.from(formFieldMap.entries()).forEach((entry) => {

    const formFieldConfig = entry[1];
    const argValue: string = argumentsMap[entry[0] /* Form field key*/];

    if (argValue != null && (typeof argValue != 'undefined')) {
      let argValueText = argValue;
      if (formFieldConfig.type === 'radioGroup') {

        // The value to be displayed for a radio group should be the label of the
        // radio button which was selected by the user.
        const selectedRadioButton = formFieldConfig.typeConfig?.radioGroupConfig?.radioButtons?.find(
          (button) => button.value === argValue
        );
        argValueText = selectedRadioButton.label;
      }
      settings.push({
        label: formFieldConfig.label,
        value: argValueText
      });
    }
  });

  return settings;
}

/**
 * Get a list of custom arguments for rendering on details page for various UDA
 * workflows like registration, protection job, recover job, etc. The arguments
 * are added to the result array in the order in which they are defined in the
 * dynamic form config. This ensures that they appear in the order in which the
 * user enters them during the CREATE workflows.
 *
 * For every form field descriptor that exists in the dynamic form, we check if
 * a value for it has been provided in the customArguments list. For every find,
 * we add it to the result.
 *
 * @param   dynamicForm   Dynamic form config for which the custom arguments
 *          need to be collated.
 * @param   customArguments   A map of argument key and argument value.
 * @returns List of custom arguments for display in various UDA workflows.
 */
export function getCustomArgumentsListForDisplay(
  dynamicForm: DynamicForm,
  customArguments: KeyValuePair[]
): CustomArgDisplayEntry[] {
  if (!dynamicForm || !customArguments?.length) {
    return [];
  }

  // Extract all form fields from dynamic form into a map of <field key, field config>.
  // This allows for quick checks later.
  const formFieldMap = getFormFieldMapForDynamicForm(dynamicForm);

  // Convert custom arguments list to a map for quick checks.
  const sourceArgumentsMap = customArguments.reduce((acc, current) => {
    acc[current.key] = current.value;
    return acc;
  }, {} as { [key in string]: string });

  return getNonNullValuesForAFormFieldMap(formFieldMap, sourceArgumentsMap);
}

/**
 * Get the protection job settings for a managed UDA connector. The returned
 * settings are grouped by panels. Settings within a panel are added only if
 * these any value for the setting has been specified by the user.
 *
 * @param   dynamicForm   Dynamic form config for which the settings need to be
 *          collated.
 * @param   customArguments   List of custom backup arguments.
 * @returns List of objects containing the settings for each panel in a
 *          managed connector's protection UI.
 */
export function getManagedUdaConnectorSettingsList(
  dynamicForm: DynamicForm,
  customArguments: KeyValuePair[]
): ManagedConnectorProtectionSettings[] {

  if (!dynamicForm || !customArguments?.length) {
    return [];
  }

  // Convert the custom args list into a map for easy access later.
  const argumentsMap = customArguments.reduce((acc, current) => {
    acc[current.key] = current.value;
    return acc;
  }, {} as { [key in string]: string });

  const settings: ManagedConnectorProtectionSettings[] = [];

  // Traverse each panel and check if any custom arguments have been specified
  // within it. If any are found within the panel, then add the panel to the
  // result.
  (dynamicForm?.panels || []).forEach((panel) => {
    const formFieldMap = new Map<string, FormField>();
    getFormFieldsMapFromFormPanel(panel, formFieldMap, true);
    const panelSettings: CustomArgDisplayEntry[] = getNonNullValuesForAFormFieldMap(formFieldMap, argumentsMap);
    if (panelSettings?.length) {
      settings.push({
        title: panel.title,
        settings: panelSettings
      });
    }
  });

  return settings;
}

/**
 * Getter for the 'source type' for a UDA source.
 *
 * @param   registrationInfo   V1 registered source information for the UDA source.
 * @returns The 'source type' for the UDA source.
 */
export function getSourceTypeFromV1SourceRegistration(registrationInfo: RegisteredSourceInfo): UdaSourceType {
  if (!registrationInfo || !registrationInfo?.accessInfo?.environment) {
    return null;
  }

  const environment = registrationInfo?.accessInfo?.environment as Environment;

  let sourceType: UdaSourceType = null;
  if (environment === Environment.kUDA) {
    sourceType = registrationInfo?.udaParams?.sourceType as UdaSourceType;
  } else {
    sourceType =  UdaEnvToSourceType[environment];
  }

  return sourceType || null;
}

/**
 * Getter for the 'source type' for a UDA MCM source.
 *
 * @param   sourceRegistration   UDA MCM source registration information.
 * @returns The 'source type' for the UDA source.
 */
 export function getSourceTypeFromMcmSourceRegistration(sourceRegistration: McmSourceRegistration): UdaSourceType {
  if (!sourceRegistration || !sourceRegistration?.environment) {
    return null;
  }

  const environment = sourceRegistration?.environment;

  let sourceType: UdaSourceType = null;
  if (environment === Environment.kUDA) {
    sourceType = sourceRegistration?.udaParams?.sourceType as UdaSourceType;
  } else {
    sourceType =  UdaEnvToSourceType[environment];
  }

  return sourceType || null;
}

/**
 * Getter for the host OS type for a UDA source.
 *
 * @param   registrationInfo   V1 registered source information for the UDA source.
 * @returns The host OS type for the UDA source.
 */
export function getHostOsTypeFromV1SourceRegistration(registrationInfo: RegisteredSourceInfo): string {
  if (!registrationInfo || !registrationInfo?.accessInfo?.environment) {
    return null;
  }

  let hostOsType: string = null;
  const environment = registrationInfo?.accessInfo?.environment as Environment;

  switch(environment) {
    case Environment.kUDA:
      hostOsType = registrationInfo?.udaParams?.hostType;

    // Add more case as and when new UDA FCC environment types are added.
  }

  // Control should never reach here.
  return hostOsType || null;
}
