import { CellColorStates } from './cell-colors.constants';

/**
 * An enum for posture risks
 */
export enum PostureRisk {
  highRisk = 'highRisk',
  mediumRisk = 'mediumRisk',
  lowRisk = 'lowRisk',
  unknown = 'unknown',
}

/**
 * An enum for all possible posture risk statuses.
 */
export enum PostureRiskStatus {
  highRisk = 'high',
  mediumRisk = 'medium',
  lowRisk = 'low',
  unknown = 'unknown',
}

/**
 * A map of posture risk status to the corresponding display label key.
 */
export const PostureRiskStatusLabelKeys: Record<PostureRiskStatus, string> = {
  [PostureRiskStatus.highRisk]: 'high',
  [PostureRiskStatus.mediumRisk]: 'medium',
  [PostureRiskStatus.lowRisk]: 'low',
  [PostureRiskStatus.unknown]: '-',
};

/**
 * A map of posture risk status to the background cell color.
 */
export const PostureRiskStatusColors: Record<PostureRiskStatus, CellColorStates> = {
  [PostureRiskStatus.highRisk]: CellColorStates.high,
  [PostureRiskStatus.mediumRisk]: CellColorStates.medium,
  [PostureRiskStatus.lowRisk]: CellColorStates.ok,
  [PostureRiskStatus.unknown]: CellColorStates.unknown,
};

/**
 * Mapped tag names for the posture risk statuses.
 */
export const postureRiskTagNames: Record<PostureRiskStatus, string> = {
  [PostureRiskStatus.highRisk]: 'High Posture Risk',
  [PostureRiskStatus.mediumRisk]: 'Medium Posture Risk',
  [PostureRiskStatus.lowRisk]: 'Low Posture Risk',
  [PostureRiskStatus.unknown]: '',
};
