import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import {
  BANNER_PRIORITY,
  BannerConfig,
  BannerStatus,
  parseIconSvgName,
  statusToIconMap,
  WindowRef,
} from '@cohesity/helix';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { finalize, map, takeUntil } from 'rxjs/operators';

import { IrisContext, IrisContextService, isGaiaScope } from '../../iris-context';
import { BaseBannerConfig } from './base-banner.config';

@Injectable({
  providedIn: 'root',
})
export class OfflineBannerConfig extends BaseBannerConfig {
  /** Unique banner representation */
  id = 'offline-banner';

  bannerStatus: BannerStatus = 'warn';

  private _destroy = new Subject<void>();

  get onLine() {
    return this.windowRef.nativeWindow.navigator.onLine;
  }

  isOnLine$ = new BehaviorSubject(this.onLine);

  isListening = false;

  /**
   * provides banner config as observable
   */
  bannerInfo$ = combineLatest([
    this.irisCtx.irisContext$,
    this.isOnLine$.asObservable(),
  ]).pipe(
    map(([ctx, isOnLine]) => ([{
      id: this.id,
      priority: BANNER_PRIORITY.HIGH,
      isVisible: this.allowBanner(ctx) && !isOnLine,
      status: this.bannerStatus,
      allowClose: false,
      text: this.translateService.instant('offlineBannerText'),
    } as BannerConfig])),
    finalize(() => {
      this._destroy.next();
      this.stopListener();
    }),
  );

  constructor(
    private iconRegistry: MatIconRegistry,
    private irisCtx: IrisContextService,
    private translateService: TranslateService,
    private windowRef: WindowRef,
  ) {
    super();
    this.getPrerequisite()
      .pipe(takeUntil(this._destroy))
      .subscribe();
    this.startListener();
  }

  getPrerequisite() {
    const parsedShape = parseIconSvgName(statusToIconMap[this.bannerStatus]);
    return this.iconRegistry.getNamedSvgIcon(parsedShape[1], parsedShape[0]);
  }

  /**
   * Allow banner based on context
   *
   * @param ctx iris context
   * @returns true/false based on context
   */
  allowBanner(ctx: IrisContext) {
    return (
      this.isLoggedIn(ctx) &&
      !this.isBannerAcknowledged(this.id) &&
      isGaiaScope(ctx)
    );
  }

  startListener() {
    if (!this.isListening) {
      window.addEventListener('offline', this.updateIsOnLine);
      window.addEventListener('online', this.updateIsOnLine);
      this.isListening = true;
    }
  }

  stopListener() {
    if (this.isListening) {
      window.removeEventListener('offline', this.updateIsOnLine);
      window.removeEventListener('online', this.updateIsOnLine);
      this.isListening = false;
    }
  }

  updateIsOnLine = () => {
    this.isOnLine$.next(this.onLine);
  };
}
