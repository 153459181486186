<cog-settings-list-item [formGroup]="formGroup"
  *ngIf="formControl"
  [invalid]="formControl.invalid"
  [label]="'abortInBlackouts' | translate">
  <cog-settings-list-item-value-display>
    {{formControl.value | boolLabel}}
  </cog-settings-list-item-value-display>
  <cog-settings-list-item-value-editor [formGroup]="formGroup"
    cogDataId="settings-abort-in-blackouts">
    <mat-slide-toggle [formControlName]="name"
      [inheritDataId]="true"
      cogDataId="toggle">
      {{'abortInBlackouts' | translate}}
    </mat-slide-toggle>
    <mat-hint>
      {{'protectionGroups.abortInBlackoutsHint' | translate}}
    </mat-hint>
  </cog-settings-list-item-value-editor>
</cog-settings-list-item>
