// Component: c-field-actions.

;(function(angular, undefined) {
  'use strict';

  angular.module('C.cFilters')
    .component('cFieldActions', {
      require: {
        // find c-filters controller who is exposing apply all, abort all and
        // clear all filter API's.
        cFilters: '^^cFilters',

        // find c-field controller to get access to c-field API interface.
        cField: '^^cField',
      },
      templateUrl: 'app/global/c-filters/c-field-actions.html',
    });

})(angular);
