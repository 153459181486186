import { HttpClient } from '@angular/common/http';
import { Injectable, Optional } from '@angular/core';
import { Api } from '@cohesity/api/private';
import { GetRegistrationInfoResponse, ProtectionSourceNode, ProtectionSourcesServiceApi } from '@cohesity/api/v1';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ListProtectionSourcesService extends ProtectionSourcesServiceApi {
  constructor(
    @Optional() private irisContextService: IrisContextService,
    httpClient: HttpClient,
  ) {
    super({ rootUrl: Api.root }, httpClient);
  }

  ListProtectionSources(
    params: ProtectionSourcesServiceApi.ListProtectionSourcesParams):
  Observable<ProtectionSourceNode[]> {
    return super.ListProtectionSources({
      ...params,
      useCachedData: flagEnabled(this.irisContextService.irisContext, 'useMagnetoCachedData'),
    });
  }

  ListProtectionSourcesRegistrationInfo(
    params: ProtectionSourcesServiceApi.ListProtectionSourcesRegistrationInfoParams):
  Observable<GetRegistrationInfoResponse> {
    return super.ListProtectionSourcesRegistrationInfo({
      ...params,
      useCachedData: flagEnabled(this.irisContextService.irisContext, 'useMagnetoCachedData'),
    });
  }
}
