import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Incidence } from '@cohesity/api/argus';
import { AppStatus } from '@cohesity/data-govern/shared';
import { ThAdapterAccessService } from '@cohesity/data-govern/threat-detection';
import { emptyLinkFn, emptyLinkParamsFn, SkuType } from '@cohesity/iris-core';

@Component({
  selector: 'dg-ar-th-scan-run-stats',
  templateUrl: './th-scan-run-stats.component.html',
  styleUrls: ['./th-scan-run-stats.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ThScanRunStatsComponent {

  /** The incidence details */
  @Input() incidence: Incidence;

  /** The app installation status */
  @Input() appStatus: AppStatus;

  /**
   * The state to use for install app.
   */
  @Input() getInstallAppLinkFn = emptyLinkFn;

  /**
   * Return the install app link params.
   */
  @Input() getInstallAppLinkParamsFn = emptyLinkParamsFn;

  /** Sku type enum */
  readonly SkuTypeEnum = SkuType;

  /** The last threat detection scan details */
  get lastThRunDetails() {
    return this.incidence?.antiRansomwareDetails?.lastThreatScanDetails;
  }

  constructor(public thAdapterAccessService: ThAdapterAccessService) {}
}
