// HTTP Interceptor Service: 202 checker
// Intercept 202 http response

;(function(angular, undefined) {
  'use strict';

  angular.module('C')
  .service('Http202Interceptor', Http202InterceptorFn)
  .config(function Intercept202Config($httpProvider) {
    $httpProvider.interceptors.push('Http202Interceptor');
  });

  function Http202InterceptorFn(_, $q, $injector, $translate) {
    var genericProxied202Message = 'quorum.approvalErrorMessage';
    var genericRetryProxied202Message = 'quorum.approvalErrorMessageRetry';
    var $rootScope;
    var NgRemoteClusterService;
    var cMessage;

    return {
      response: function responseInterceptor(resp) {
        $rootScope = $rootScope || $injector.get('$rootScope');
        NgRemoteClusterService =
          NgRemoteClusterService || $injector.get('NgRemoteClusterService');
        cMessage = cMessage || $injector.get('cMessage');

        const quorumResponse = resp.status === 202 &&
          resp.data && resp.data.quorumResponse;

        if (quorumResponse) {
            throw {
              name: 'QuorumError',
              options: {
                type: 'ApprovalRequired',
                id: quorumResponse?.id,
                isRequestExistsAlready: quorumResponse?.isRequestExistsAlready,
              }
            }
          }

        return resp;
      }
    };
  }

})(angular);
