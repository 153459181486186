import {
  ChangeDetectionStrategy, Component,
  OnChanges, SimpleChanges
} from '@angular/core';
import { Validators } from '@angular/forms';
import { NumberConfig, BaseFormFieldDirective } from '../../models/index';

/**
 * Render a number field on the UI screen
 *
 * @example
 * <cog-number-form-field [label]="label" ...></cog-number-form-field>
 */
@Component({
  selector: 'cog-number-form-field',
  templateUrl: './number-form-field.component.html',
  styleUrls: ['./number-form-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NumberFormFieldComponent extends BaseFormFieldDirective<NumberConfig> implements OnChanges {

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.config || changes.control) {
      this.control.clearValidators();
      this.control.setValidators([
        this.config?.required ?
          Validators.required : Validators.nullValidator,
        this.config?.minimumValue ?
          Validators.min(this.config.minimumValue) : Validators.nullValidator,
        this.config?.maximumValue ?
          Validators.max(this.config.maximumValue) : Validators.nullValidator
      ]);

      // This component could have been reset. Upon re-init, if the control has
      // no value assigned to it, and a default value exists in it's config,
      // assign that to control.
      if (!this.control.value && !!this.config?.defaultValue) {
        this.control.setValue(this.config?.defaultValue);
      }
      this.control.updateValueAndValidity();
    }
  }
}
