import flattenDeep from 'lodash/flattenDeep';
import uniq from 'lodash/uniq';

/**
 * Combines multiple strings, arrays of strings into a single string of unique values by ignoring falsy values and
 * joining by the default join operator. This is used for generating filter values for teh object search API.
 *
 * @param stringLists list of strings or list of array of strings
 * @returns combined string of unique values
 */
export function getCombinedUniqueString<T extends string = string>(...stringLists: (T | T[])[]): T {
  return uniq(flattenDeep(stringLists)).filter(Boolean).join() as T;
}
