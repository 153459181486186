import { Injectable } from '@angular/core';
import { ProtectedObject } from '@cohesity/api/v2';
import { NavItem } from '@cohesity/helix';
import { StateService } from '@uirouter/core';
import { Environment, PassthroughOptions } from 'src/app/shared';

import { ProtectionGroupService } from '../protection-group-shared';
import { ObjectActionCreator } from './object-action-creator';
import { SimpleObjectInfo } from './object-menu-provider';
import { ProtectedObjectsService } from './protected-objects.service';

@Injectable({
  providedIn: 'root',
})
export class DbObjectActionCreator extends ObjectActionCreator {
  constructor(
    objectsService: ProtectedObjectsService,
    protectionGroupService: ProtectionGroupService,
    stateService: StateService) {
    super(objectsService, protectionGroupService, stateService);
  }

  /**
   * Creates and returns a db action for the supplied protected object, based on the supplied type.
   *
   * @param simpleObject The simple object.
   * @param object The protected object.
   * @param type The type of the db action.
   * @param objectOptions The object's passthrough options.
   * @returns The recovery NavItem object.
   */
  createDbAction(
    simpleObject: SimpleObjectInfo,
    object: ProtectedObject,
    type: 'migrate' | 'clone',
    objectOptions?: PassthroughOptions
  ): NavItem {
    if (simpleObject?.restorePointSelection?.snapshot?.ownershipContext === 'FortKnox') {
      if (type === 'migrate') {
        return;
      }
    }

    if (simpleObject?.restorePointSelection?.snapshot?.externalTargetInfo?.ownershipContext === 'FortKnox') {
      if (type === 'migrate') {
        return;
      }
    }

    if (!object.latestSnapshotsInfo[0]) {
      return;
    }

    let dbType;
    let icon;

    if (type === 'clone') {
      icon = 'helix:object-db-clone';
    }

    switch (object.environment) {
      case Environment.kSQL:
        dbType = 'sql';
        break;
      case Environment.kOracle:
        dbType = 'oracle';
    }

    if (!dbType) {
      return;
    }

    return {
      displayName: type === 'migrate' ? 'migrateDatabase' : 'cloneDatabase',
      state: type === 'migrate' ? 'migrate-db.options' : 'clone-db.options',
      icon,
      stateParams: {
        dbType,
        jobId: object.latestSnapshotsInfo[0].protectionGroupId.split(':').pop(),
        entityId: object.id,
        jobInstanceId: object.latestSnapshotsInfo[0].runInstanceId,
        cid: objectOptions.accessClusterId,
        regionId: objectOptions.regionId,
        archiveId: simpleObject?.restorePointSelection?.snapshot?.externalTargetInfo?.targetId,
      },
    };
  }
}
