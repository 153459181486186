import { Directive, HostBinding, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import * as Highcharts from 'highcharts';
import { HighchartsChartComponent } from 'highcharts-angular';
import { merge } from 'lodash';
import { Observable, ReplaySubject } from 'rxjs';

/**
 * Custom time label formats
 */
export const customDateTimeLabelFormats: Highcharts.AxisDateTimeLabelFormatsOptions = {
  millisecond: { main: '%H:%M:%S.%L' },
  second: { main: '%H:%M:%S' },
  minute: { main: '%H:%M' },
  hour: { main: '%l:%M%P' },
  day: { main: '%m/%d' },
  week: { main: '%m/%d' },
  month: { main: '%b \'%y' },
  year: { main: '%Y' }
};

/**
 * @description
 * Base class that will add common properties and behaviors to Highcharts (highcharts-angular) based components.
 *
 * @example
 *    // Template:
 *    <highcharts-chart class="bubble-chart"
 *      [Highcharts]="Highcharts"
 *      [options]="chartOptions">
 *    </highcharts-chart>
 *
 *   // Specify which series chart is using as part of ChartComponent generic
 *   class AwesomeChartComponent extends ChartComponent<SeriesLineOptions> {
 *     constructor() {
 *       // Pass default chart config.
 *       super({
 *        chart: {
 *          type: 'line'
 *        }
 *       });
 *     }
 *
 *     render() {
 *       // If need to update chart options, update chart config before rendering.
 *       this.chartOptions.series = [1, 2, 3];
 *
 *       // Call this after chart options are updated.
 *       super.render();
 *     }
 *   }
 */
@Directive()
export abstract class HighchartsComponent<S extends Highcharts.SeriesOptionsType> implements OnInit, OnChanges {

  /**
   * This class will look for <highcharts-chart> component in the template
   * to automatically update chart instance when Highcharts sets it asynchronously.
   */
  @ViewChild(HighchartsChartComponent, { static: true }) highchartsChartComponent: HighchartsChartComponent;

  /**
   * Utilize highcharts-theme.scss for theming.
   */
  @HostBinding('class.cog-chart') cssClass = true;

  /**
   * Custom Highcharts options that will override default chart options.
   */
  @Input() customChartOptions: Highcharts.Options = {};

  /**
   * When set, this forces a reflow immediately after the chart object is first
   * initialized.
   */
  @Input() reflowOnFirstRender = false;

  /**
   * When set, this adds a class that will make the cursor a pointer on hover
   * of the series in any chart.
   */
  @HostBinding('class.clickable-chart') @Input() isClickable = false;

  /**
   * Highcharts imports.
   */
  readonly Highcharts: typeof Highcharts = Highcharts;

  /**
   * Default Highcharts chart options will be used for rendering chart.
   */
  chartOptions: Highcharts.Options;

  /**
   * Highcharts Chart instance.
   */
  protected _chart: Highcharts.Chart;

  /**
   * Returns Highcharts Chart instance.
   */
  get chart(): Highcharts.Chart {
    return this._chart;
  }

  /**
   * Chart series data.
   */
  protected _seriesData: S[] = [];

  /**
   * Sets chart series data.
   */
  @Input() set seriesData(seriesData: S[]) {
    this.clearSeries();

    this._seriesData = seriesData;
    this.chartOptions.series = seriesData;

    this.render();
  }

  /**
   * Returns chart series data.
   */
  get seriesData(): S[] {
    return this._seriesData;
  }

  /**
   * Emitted when chart instance is available asynchronously.
   */
  private chartCreated = new ReplaySubject<Highcharts.Chart>();

  /**
   * Returns an observable of Highcharts instance when it's created.
   */
  get chartInstance$(): Observable<Highcharts.Chart> {
    return this.chartCreated.asObservable();
  }

  /**
   * Constructor.
   *
   * @param  defaultChartOptions  Default chart options config that will be used by subclass.
   */
  constructor(defaultChartOptions = {}) {
    this.chartOptions = defaultChartOptions;
  }

  /**
   * Initialize component, set chart instance async and populate default chart options
   * with custom chart options if available.
   */
  ngOnInit() {
    // Populate chart instance async when it becomes available.
    this.highchartsChartComponent.chartInstance.subscribe(chart => this.setChartInstance(chart));

    // Mix default and custom chart options.
    this.chartOptions = merge(this.chartOptions, this.customChartOptions);

    this.render();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.customChartOptions && this.customChartOptions) {
      // Mix default and custom chart options.
      this.chartOptions = merge(this.chartOptions, this.customChartOptions);
      this.render();
    }
  }

  /**
   * Sets Chart instance async by Highcharts' chartInstance callback.
   */
  setChartInstance(chart: Highcharts.Chart) {
    this._chart = chart;
    this.chartCreated.next(chart);
    this.render();
    if (this.reflowOnFirstRender) {
      this.reflow();
    }
  }

  /**
   * Removes all series from chart.
   */
  clearSeries() {
    if (this._chart) {
      const { series } = this._chart;
      while (series[0]) {
        series[0].remove();
      }
    }
  }

  /**
   * Updates chart using current chart options.
   */
  render() {
    const { _chart, chartOptions } = this;
    if (_chart) {
      _chart.update(chartOptions, true, true, false);
    }
  }

  /**
   * Recalculates chart dimensions.
   */
  reflow() {
    if (this._chart) {
      setTimeout(() => this._chart.reflow());
    }
  }
}
