import { NavService } from './../app-layout/nav.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserPanelComponent } from './user-panel.component';
import { SharedModule } from '../../shared';

const components = [
  UserPanelComponent
];

@NgModule({
  imports: [
    CommonModule,
    SharedModule
  ],
  providers: [
    NavService
  ],
  declarations: components,
  exports: components
})
export class UserPanelModule { }
