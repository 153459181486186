// Component: Office 365 Recovery Cart

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.office365RecoveryCart', [])
    .controller('Office365RecoveryCartCtrl', office365RecoveryCartCtrlFn)
    .component('office365RecoveryCart', {
      bindings: {
        // @type    {Object}   Object containing the modal instance.
        modalInstance: '<?',
      },
      templateUrl:
        '/app/protection/recovery/office365/cart/cart-modal.html',
      controller: 'Office365RecoveryCartCtrl',
    });

  /**
   * @ngdoc component
   * @name C.office365RecoveryCart:office365RecoveryCart
   * @function
   *
   * @description
   * Component to hold office 365 entities such as Mailbox, emails or folders
   * during recovery.
   */
  function office365RecoveryCartCtrlFn(_, RecoveryStore) {
    var $ctrl = this;

    _.assign($ctrl, {
      // Lifecycle methods.
      $onInit: $onInit,

      // Methods exposed to template.
      cancel: cancel,
    });

    /**
     * @method   $onInit
     *
     * Component initialization hook.
     */
    function $onInit() {
      $ctrl.shared = RecoveryStore.get();
      $ctrl.recoveryType = 'mailbox';
    }

    /**
     * @method   cancel
     *
     * Closes the modal instance.
     */
    function cancel() {
      $ctrl.modalInstance.close();
    }
  }
})(angular);