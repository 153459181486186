// Factory: ReportsUtil
import { getConfigByKey } from '@cohesity/iris-core';

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.reports')
    .factory('ReportsUtil', ReportsUtilFn);

  function ReportsUtilFn(_, $uibModal, ExternalTargetService, JobService,
    SourceService, ViewBoxService, ViewService, cUtils, ENUM_HOST_TYPE,
    ENV_GROUPS, ClusterService, $state, $q, ActiveDirectoryService,
    PHYSICAL_SERVER_STATUS, GET_ENTITIES_OF_TYPE_LEAVES, FEATURE_FLAGS, NgIrisContextService) {

    // Load feature enabler for email.
    const hideEmailScheduler = getConfigByKey(NgIrisContextService.irisContext, 'reportsAjs.hideEmailScheduler', false);

    /**
     * This utility function turns an array found in the config map
     * into a map with the array values as keys and true as values.
     * This is just useful for quick lookup rather than looping.
     *
     * ['one', 'two', 'three]
     * becomes
     * {
     *  'one': true,
     *  'two': true,
     *  'three': true
     * }
     *
     * @param key - The key to look up in the config
     * @returns a map
     */
    function getConfigByKeyAsMap(key) {
      return getConfigByKey(NgIrisContextService.irisContext, key, [])
      .reduce((hiddens, next) => {
        hiddens[next] = true;
        return hiddens;
      }, {});
    }

    var ReportsUtil = {
      getBasicClusterInfo: getBasicClusterInfo,
      getEntities: getEntities,
      getEntitiesHash: getEntitiesHash,
      getExternalTargets: getExternalTargets,
      getJobs: getJobs,
      getReportConfig: getReportConfig,
      getSources: getSources,
      getPrincipals: getPrincipals,
      getViewBoxes: getViewBoxes,
      getViews: getViews,
      openEmailScheduler: openEmailScheduler,
      getConfigByKeyAsMap,
    };

    var reportConfigs = {
      kAgentDeploymentReport: getkAgentDeploymentReport,
      kArchivalSummaryReport: getkArchivalSummaryReport,
      kAvailableLocalSnapshotsReport: getkAvailableLocalSnapshotsReport,
      kBackupSummaryReport: getkBackupSummaryReport,
      kDataTransferredToExternalTargetsReport:
        getkDataTransferredToExternalTargetsReport,
      kFailedObjectsReport: getkFailedObjectsReport,
      kObjectsProtectedByMultipleGroupsReport: getkObjectsProtectedByMultipleGroupsReport,
      kProtectionDetailsPerObjectReport: getkProtectionDetailsPerObjectReport,
      kProtectionJobsInventoryAndScheduleReport:
        getkProtectionJobsInventoryAndScheduleReport,
      kProtectionSummaryByObjectTypeReport:
        getkProtectionSummaryByObjectTypeReport,
      kRecoverSummaryReport: getkRecoverSummaryReport,
      kSourceGrowthAndVarianceReport: getkSourceGrowthAndVarianceReport,
      kStorageConsumedByBackupReport: getkStorageConsumedByBackupReport,
      kStorageConsumedByFileCategoriesReport:
        getkStorageConsumedByFileCategoriesReport,
      kStorageConsumedByServersReport: getkStorageConsumedByServersReport,
      kStorageConsumedByTenantPerViewBoxReport:
        getkStorageConsumedByTenantPerViewBoxReport,
      kStorageConsumedByTenantsReport: getkStorageConsumedByTenantsReport,
      kStorageConsumedByViewBoxReport: getkStorageConsumedByViewBoxReport,
      kUnprotectedVMsReport: getkUnprotectedVMsReport,
      kUserQuotasReport: getkUserQuotasReport,
      kDirQuotasReport: getkDirQuotasReport,
      kGdprReport: getKGdprReport,
      kProtectionRunsSummaryReport: getkProtectionRunsSummaryReport,
      kProtectedObjectsTrendsReport: getkProtectedObjectsTrendsReport,
    };

    var entitiesHash = {};

    /**
     * opens the email scheduler modal / component
     *
     * @param      {object}  reportFilters  the reportFilters selected to be
     *                                      pass to the c-reports-controls
     *                                      component in the email scheduler
     *                                      modal
     * @param      {object}  reportConfig   the reportConfig which decides what
     *                                      to show and hide in the
     *                                      c-reports-controls component
     * @param      {object}  dataset        contains necessary data to populate
     *                                      c-reports-controls component filter
     *                                      fields.
     * @param      {object}  isEditMode     Render for edit mode else create
     *                                      mode.
     * @param      {object}  mcmMode        Render email scheduler for MCM mode.
     * @return     {object}  $uibModal instance
     */
    function openEmailScheduler(reportFilters, reportConfig, dataset,
      isEditMode, mcmMode) {
      return $uibModal.open({
        modalFade: true,
        backdrop: 'static',
        size: 'md',
        component: 'cEmailScheduler',
        resolve: {
          filters: function() {
            return reportFilters;
          },
          sidebarConfig: function() {
            return reportConfig;
          },
          filtersDataset: function() {
            return dataset;
          },
          isEditMode: _.constant(isEditMode),
          mcmMode: _.constant(mcmMode),
        },
      }).result.then(function onCloseScheduler(response) {
        return response;
      });
    }

    /**
     * Issues the API to get basic Cluster info. Response is not consumed here.
     * This function ensures the info has been cached.
     *
     * @return     {Object}  API promise
     */
    function getBasicClusterInfo() {
      return ClusterService.getBasicClusterInfo();
    }

    /**
     * wrapper function to get targets
     *
     * @return    {object}    promise
     */
    function getExternalTargets() {
      return ExternalTargetService.getTargets();
    }

    /**
     * gets our viewboxes for the viewbox filter
     *
     * @return    {array}    array of viewbox objects with name and id
     */
    function getViewBoxes() {
      return ViewBoxService.getViewBoxes().then(
        function viewBoxSuccess(viewBoxes) {
          return viewBoxes.map(function mapViewBoxes(viewBox) {
            return {
              name: viewBox.name,
              value: viewBox.id,
            };
        });
      });
    }

    /**
     * gets entities for object type filter
     *
     * @method   getEntities
     * @param    {boolean}   allObjects   Indicates if all objects should be
     *                                    retrieved. If not provided only
     *                                    protected objects will be loaded.
     * @return   {array}     array of entity objects with name and id
     */
    function getEntities(allObjects) {
      return SourceService.getEntitiesOfType(
        _.assign({
            isProtected: allObjects ? undefined : true,
            allUnderHierarchy: true
          },
          GET_ENTITIES_OF_TYPE_LEAVES
        )
      ).then(function builEntitiesArray(entities) {
         return entities.map(function processEntity(entity) {
          entitiesHash[entity.id] = entity;
          return {
            name: entity.displayName,
            id: entity.id,
          };
        });
      });
    }

    /**
     * Provides a reference to the entitiesHash created in getEntities(). Done
     * by reference so this can be called indepdently of getEntities() and not
     * have a race condition.
     *
     * @method   getEntitiesHash
     * @return   {object}   The entities hash.
     */
    function getEntitiesHash() {
      return entitiesHash;
    }

    /**
     * get jobs to for typeahead jobs filter
     *
     * @param     {object}    params    params for getJobs API call
     *
     * @return    {array}              array of job objects with name and id
     */
    function getJobs(params) {
      params = angular.extend({
        allUnderHierarchy: true,
        excludeTasks: true,
      }, params);

      return JobService.getJobs(params).then(function buildJobsArray(jobs) {
        return (jobs || []).map(function processJob(job) {
          return {
            name: job.name,
            id: job.jobId,
          };
        });
      });
    }

    /**
     * get sources for the registered sources filter
     *
     * @param     {object}     params      params object for API call
     *
     * @param     {boolean}    useCache    if true returns cached sources
     *
     * @return    {array}                  array of sources object with name
     *                                     and the source entity that contains
     *                                     id
     */
    function getSources(params, useCache) {
      var reqParams = _.assign({
        onlyReturnOneLevel: true,
        envTypes: cUtils.onlyNumbers(ENV_GROUPS.all),
      }, params);
      // setup our list of sources
      // second param true flag allows this call to use a cached version,
      // since we don't need the nitty gritty details to be correct
      return SourceService.getSources(reqParams, useCache).then(
        function getSourcesSuccess(res) {
          if (res.entityHierarchy && res.entityHierarchy.children.length) {
            return res.entityHierarchy.children.map(
              function mapSources(source) {
                return {
                  name: source.entity.displayName,
                  value: source.entity,
                };
              });
          }
        });
    }

    /**
     * Gets filtered list of Views.
     *
     * @param      {Object}  params  The request object
     * @return     {Array}   array of Views
     */
    function getViews(params) {
      _.assign({
        filters: true,
        matchPartialNames: true,
        includeInactive: false,

        // This function is exclusively used in a typeahead. No need for a large
        // result set, especially if a single character is queried.
        maxCount: 100,
      }, params);

      return ViewService.getViews(params);
    }

    /**
     * Gets the full Principal object for a given SID. This is used to seed the
     * cUserAtDomainPicker component.
     *
     * @param      {String}  sid     The sid
     * @return     {Array}   array of Users
     */
    function getPrincipals(sid) {
      if (sid) {
        return ActiveDirectoryService.getPrincipals([].concat(sid));
      } else {
        return $q.resolve([]);
      }
    }

    /**
     * will return the sidebar config of a report. we need to put these configs
     * here so they can be shared when editing a schedule or creating a new
     * schedule
     *
     * @param     {string}    reportType    the kValue of the report
     *
     * @return    {function}                Function which resolves the sidebar
     *                                      config for a report
     */
    function getReportConfig(reportType) {
      return function callbackWithArgs() {
        var actualCallback = reportConfigs[reportType] || _.constant({});

        // Extend the config with the report type.
        return _.assign(actualCallback.apply(this, arguments), {
          reportType: reportType,
          hideEmailScheduler,
        });
      };
    }

    /**
     * Gets the report details of all the back up objects.
     *
     * @method    getkBackupSummaryReport
     *
     * @return    {object}                  The object containing the details.
     */
    function getkBackupSummaryReport(withoutDataPromises) {
      return {
        showReportsSelector: true,
        showHeader: true,
        showDateRangeFilter: true,
        showRegisteredSourceFilter: true,
        showApplyFiltersBtn: true,
        showStatusFilter: true,
        showTenantsFilter: true,
        enableTypeAhead: true,
        typeAheadLabel: 'reports.typeAheadLabel.jobs',
        typeAheadDatasetKey: 'jobId',
        datasetPromiseObject: withoutDataPromises ? undefined : {
          typeAheadData: ReportsUtil.getJobs(),
          registeredSources: ReportsUtil.getSources(),
        },
      };
    }

    /**
     * Gets the filter options for recover reports.
     *
     * @method   getkRecoverSummaryReport
     *
     * @return   {object}   The object containing the options.
     */
    function getkRecoverSummaryReport() {
      return {
        showApplyFiltersBtn: true,
        showDateRangeFilter: true,
        showHeader: true,
        showReportsSelector: true,
        showTenantsFilter: true,
      };
    }

    function getkUnprotectedVMsReport() {
      return {
        showHeader: true,
        showApplyFiltersBtn: true,
        showReportsSelector: true,
        showReportTimeSpanFilter: false,
        showTenantsFilter: true,
      };
    }

    function getkStorageConsumedByBackupReport(withoutDataPromises) {
      return {
        showHeader: true,
        showApplyFiltersBtn: true,
        showReportsSelector: true,
        showRegisteredSourceFilter: true,
        showNDaysFilter: true,
        showViewBoxFilter: true,
        showTenantsFilter: true,
        datasetPromiseObject: withoutDataPromises ? undefined : {
          registeredSources: ReportsUtil.getSources(),
          viewBoxes: ReportsUtil.getViewBoxes(),
        },
      };
    }

    function getkStorageConsumedByServersReport(withoutDataPromises) {
      return {
        showHeader: true,
        showApplyFiltersBtn: true,
        showReportsSelector: true,
        showRegisteredSourceFilter: true,
        showNDaysFilter: true,
        showViewBoxFilter: true,
        enableTypeAhead: true,
        typeAheadLabel: 'reports.typeAheadLabel.jobs',
        typeAheadDatasetKey: 'jobId',
        showTenantsFilter:
          FEATURE_FLAGS.tenantStorageConsumedByServerReportEnabled,
        datasetPromiseObject: withoutDataPromises ? undefined : {
          typeAheadData: ReportsUtil.getJobs(),
          registeredSources: ReportsUtil.getSources(),
          viewBoxes: ReportsUtil.getViewBoxes(),
        },
      };
    }

    function getkStorageConsumedByViewBoxReport() {
      return {
        showHeader: true,
        showApplyFiltersBtn: true,
        showReportsSelector: true,
        showNDaysFilter: true,
      };
    }

    function getkStorageConsumedByTenantsReport() {
      return {
        showHeader: true,
        showApplyFiltersBtn: true,
        showReportsSelector: true,
        showNDaysFilter: true,
        showTenantsFilter: true,
      };
    }

    function getkStorageConsumedByTenantPerViewBoxReport(withoutDataPromises) {
      return {
        showHeader: true,
        showApplyFiltersBtn: true,
        showReportsSelector: true,
        showTenantsFilter: true,
        showViewBoxesFilter: true,
        datasetPromiseObject: withoutDataPromises ? undefined : {
          viewBoxes: ReportsUtil.getViewBoxes(),
        },
      };
    }

    function getkSourceGrowthAndVarianceReport(withoutDataPromises) {
      return {
        showHeader: true,
        showApplyFiltersBtn: true,
        showReportsSelector: true,
        showRegisteredSourceFilter: true,
        showNDaysFilter: true,
        datasetPromiseObject: withoutDataPromises ? undefined : {
          registeredSources: ReportsUtil.getSources(),
        },
      };
    }

    function getkStorageConsumedByFileCategoriesReport() {
      return {
        showReportsSelector: true,
        showHeader: true,
        showNDaysFilter: true,
        showApplyFiltersBtn: true,
      };
    }

    function getkProtectionJobsInventoryAndScheduleReport(withoutDataPromises) {
      return {
        showHeader: true,
        showApplyFiltersBtn: true,
        showReportsSelector: true,
        showVmNameFilter: true,
        enableTypeAhead: true,
        showReportTimeSpanFilter: false,
        typeAheadLabel: 'reports.typeAheadLabel.jobs',
        typeAheadDatasetKey: 'jobId',
        datasetPromiseObject: withoutDataPromises ? undefined : {
          typeAheadData: ReportsUtil.getJobs(),
        },
      };
    }

    function getkAvailableLocalSnapshotsReport(withoutDataPromises) {
      return {
        showHeader: true,
        showApplyFiltersBtn: true,
        showReportsSelector: true,
        showDateRangeFilter: true,
        enableTypeAhead: true,
        typeAheadLabel: 'reports.typeAheadLabel.jobs',
        typeAheadDatasetKey: 'jobId',
        datasetPromiseObject: withoutDataPromises ? undefined : {
          typeAheadData: ReportsUtil.getJobs(),
        },
      };
    }

    function getkProtectionDetailsPerObjectReport(withoutDataPromises) {
      return {
        showHeader: true,
        showApplyFiltersBtn: true,
        showReportsSelector: true,
        showDateRangeFilter: true,
        showStatusFilter: true,
        enableMultiSelectTypeAhead: true,
        multiSelectLimitTo: 30,
        multiSelectTypeAheadLabel: 'reports.typeAheadLabel.objects',
        multiSelectTypeAheadDatasetKey: 'objectId',
        datasetPromiseObject: withoutDataPromises ? undefined : {
          multiSelectTypeAheadData: ReportsUtil.getEntities(true),
        },
      };
    }

    function getkFailedObjectsReport(withoutDataPromises) {
      return {
        showHeader: true,
        showApplyFiltersBtn: true,
        showReportsSelector: true,
        showDateRangeFilter: true,
        showConsecutiveFailuresFilter: true,
        showObjectTypeFilter: true,
        showTenantsFilter: true,
        showStatusFilter: false,
        enableTypeAhead: true,
        typeAheadLabel: 'reports.typeAheadLabel.jobs',
        typeAheadDatasetKey: 'jobId',
        getJobs: true,
        datasetPromiseObject: withoutDataPromises ? undefined : {
          typeAheadData: ReportsUtil.getJobs(),
        },
      };
    }

    function getkProtectionSummaryByObjectTypeReport(withoutDataPromises) {
      return {
        showHeader: true,
        showApplyFiltersBtn: true,
        showReportsSelector: true,
        showDateRangeFilter: true,
        showObjectTypeFilter: true,
        showTenantsFilter: true,
        enableTypeAhead: true,
        multiSelectLimitTo: 30,
        typeAheadLabel: 'reports.typeAheadLabel.jobs',
        typeAheadDatasetKey: 'jobId',
        datasetPromiseObject: withoutDataPromises ? undefined : {
          typeAheadData: ReportsUtil.getJobs({
            excludeTasks: true,
            envTypes: cUtils.onlyNumbers(ENV_GROUPS.objectTypes)
          }),
        },
      };
    }

    function getkArchivalSummaryReport(withoutDataPromises) {
      return {
        showReportsSelector: true,
        showHeader: true,
        showDateRangeFilter: true,
        showApplyFiltersBtn: true,
        datasetPromiseObject: withoutDataPromises ? undefined : {
          externalTargets: ReportsUtil.getExternalTargets(),
        },
      };
    }

    function getkDataTransferredToExternalTargetsReport(withoutDataPromises) {
      return {
        showReportsSelector: true,
        showHeader: true,
        showDateRangeFilter: true,
        showTargetNameFilter: true,
        showTargetTypeFilter: true,
        showApplyFiltersBtn: true,
        datasetPromiseObject: withoutDataPromises ? undefined : {
          externalTargets: ReportsUtil.getExternalTargets(),
        },
      };
    }

    function getkUserQuotasReport(withoutDataPromises) {
      return {
        showHeader: true,
        showApplyFiltersBtn: true,
        showReportsSelector: true,
        showDateRangeFilter: false,
        showStatusFilter: false,
        showReportTimeSpanFilter: false,

        enableTypeAhead: true,
        typeAheadLabel: 'viewName',
        typeAheadDatasetKey: 'viewName',

        enableUserAtDomainPicker: true,
        userAtDomainPickerLabel: 'adUser',
        showUnixInput: false,

        showExcludeUsersWithinAlertThreshold: true,

        datasetPromiseObject: withoutDataPromises ? undefined : {
          // Fetches list of Active Directories.
          activeDirectories: ActiveDirectoryService.getActiveDirectories(),

          // Gets list of Views for typeahead.
          typeAheadData: ReportsUtil.getViews({}),

          // Fetches pre-selected principals.
          initialPrincipals: ReportsUtil.getPrincipals($state.params.sid),

          // Bootstraps the list of domains.
          basicClusterInfo: ReportsUtil.getBasicClusterInfo(),
        },
      };
    }

    /**
     * Gets the initial data for report: Directory Quotas on a View.
     *
     * @method   getkDirQuotasReport
     * @param    {boolean}    withoutDataPromises    False to suppress initial
     *                                               data query.
     * @return   {object}     The object containing the initial report data.
     */
    function getkDirQuotasReport(withoutDataPromises) {
      return {
        // General sidebar config.
        showHeader: true,
        showApplyFiltersBtn: true,
        showReportsSelector: true,
        showDateRangeFilter: false,
        showStatusFilter: false,
        showReportTimeSpanFilter: false,

        // Config for the `typeAhead` filter.
        enableTypeAhead: true,
        typeAheadLabel: 'viewName',
        typeAheadDatasetKey: 'viewName',

        // Initial API request config.
        datasetPromiseObject: withoutDataPromises ? undefined : {
          // Gets list of Views for typeAhead.
          typeAheadData: ReportsUtil.getViews(),
        },
      };
    }

    /**
     * Provides the GDPR Objects Summary Reports configuration.
     *
     * @method   getKGdprReport
     * @return   {object}   Controls configuration for GDPR Objects Report
     */
    function getKGdprReport(withoutDataPromises) {
      return {
        showHeader: true,
        showApplyFiltersBtn: true,
        showReportsSelector: true,
        showDateRangeFilter: true,

        // TODO: enable this when the API gets fixed. currently it filters out
        // leaf objects rather than adding them.
        // showRegisteredSourceFilter: true,

        // TODO: mocks show a 'accessible by' user filter. API does not have
        // support for it.
        // enableUserAtDomainPicker: true,

        enableMultiSelectTypeAhead: true,
        multiSelectLimitTo: 30,
        multiSelectTypeAheadLabel: 'reports.typeAheadLabel.objects',
        multiSelectTypeAheadDatasetKey: 'objectId',

        datasetPromiseObject: withoutDataPromises ? undefined : {
          multiSelectTypeAheadData: ReportsUtil.getEntities(true),

          // TODO: this populates the above showRegisteredSourceFilter
          registeredSources: ReportsUtil.getSources(),

          // TODO: this populates the above enableUserAtDomainPicker
          // initialPrincipals: ReportsUtil.getPrincipals($state.params.sid),
        },
      };
    }

    /**
     * Provides the Job Runs Summary Report's configuration.
     *
     * @method   getkProtectionRunsSummaryReport
     * @return   {object}   Controls configuration for specific report
     */
    function getkProtectionRunsSummaryReport() {
      return {
        showHeader: true,
        showApplyFiltersBtn: true,
        showReportsSelector: true,
        showDateRangeFilter: true,
        showTenantsFilter: true,
      };
    }

    /**
     * Provides the Protected Objects Trends Report's configuration.
     *
     * @method   getkProtectedObjectsTrendsReport
     * @return   {object}   Controls configuration for specific report
     */
    function getkProtectedObjectsTrendsReport(withoutDataPromises) {
      return {
        showHeader: true,
        showApplyFiltersBtn: true,
        showReportsSelector: true,
        showDateRangeFilter: true,
        showObjectTypeFilter: true,
        showTenantsFilter: true,
        schedulerOptions: {
          htmlOnly: true,
        },
        showRollupFilter: true,
        showTimezoneFilter: true,
        enableMultiSelectTypeAhead: true,
        showSingleSelectRegisteredSourceFilter: true,
        enableTypeAhead: true,
        multiSelectLimitTo: 30,
        multiSelectTypeAheadLabel: 'reports.typeAheadLabel.objects',
        multiSelectTypeAheadDatasetKey: 'protectedObjectIds',
        typeAheadLabel: 'reports.typeAheadLabel.jobs',
        typeAheadDatasetKey: 'jobId',
        datasetPromiseObject: withoutDataPromises ? undefined : {
          typeAheadData: ReportsUtil.getJobs(),
          multiSelectTypeAheadData: ReportsUtil.getEntities(true),
          registeredSources: ReportsUtil.getSources(),
        },
      };
    }

    /**
     * Provides the Agent Status Summary Reports configuration.
     *
     * @method   getkAgentDeploymentReport
     * @return   {object}
     */
    function getkAgentDeploymentReport(withoutDataPromises) {
      return {
        showAgentHealthType: true,
        showAgentOsType: true,
        showAgentVersionFilter: true,
        showApplyFiltersBtn: true,
        showHeader: true,
        showReportsSelector: true,

        datasetPromiseObject: withoutDataPromises ? undefined : {
          agentHealthTypes: _convertObjectToArray(PHYSICAL_SERVER_STATUS),
          agentOsTypes: _convertObjectToArray(ENUM_HOST_TYPE),
        }
      };
    }

    /**
     * Provides the Objects Protected by Multiple Protection Group Report's configuration.
     *
     * @method   getkObjectsProtectedByMultipleGroupsReport
     * @return   {object}   Controls configuration for specific report
     */
    function getkObjectsProtectedByMultipleGroupsReport() {
      return {
        showReportsSelector: true,
      };
    }

    /**
     * Convert a key value pair into a array of object with name and value.
     * Currently nested object is not supported
     *
     * @method   _convertObjectToArray
     * @param    {object}    obj    An object to be converted
     */
    function _convertObjectToArray(obj) {
      var convertedArr;
      if (!obj) {
        return convertedArr;
      }

      convertedArr = [];
      Object.keys(obj).forEach(function convertToArr(key) {
        if (_.isNaN(_.toNumber(key))) {
          convertedArr.push({
            name: key,
            value: obj[key],
          });
        }
      });

      return convertedArr;
    }

    return ReportsUtil;
  }
})(angular);
