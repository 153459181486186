<span *ngFor="let i of paddingArray" class="spacer"></span>
<button
  type="button"
  mat-icon-button
  (click)="toggleRowExand()"
  *ngIf="treeControl.isExpandable(node)"
  cogDataId="toggle-row-expand"
  [inheritDataId]="true"
>
  <cog-icon
    class="mat-icon-rtl-mirror"
    shape="{{ treeControl.isExpanded(this.node) ? 'expand_more' : 'chevron_right' }}"
  >
  </cog-icon>
</button>

<!-- use a disabled button to provide padding for tree leaf -->
<button mat-icon-button disabled *ngIf="!treeControl.isExpandable(node) && node.level > 0"></button>
