import { Component, Input } from '@angular/core';
import { AagAndDatabases } from '@cohesity/api/v1';


@Component({
  selector: 'coh-sql-aag-details',
  templateUrl: './sql-aag-details.component.html',
  styleUrls: ['./sql-aag-details.component.scss']
})
export class SqlAagDetailsComponent {
  @Input() data: AagAndDatabases;
}
