import { Component, forwardRef, Inject, Optional, ViewEncapsulation } from '@angular/core';
import { LegacyDateAdapter as DateAdapter } from '@angular/material/legacy-core';
import { MatCalendar, yearsPerPage } from '@angular/material/datepicker';

// Component instance unique ID counter
let nextUniqueId = 0;

/**
 * @description
 * Customized Material Calendar header component for `coh-calendar`.
 */
@Component({
  selector: 'coh-calendar-header',
  templateUrl: './calendar-header.component.html',
  styleUrls: ['./calendar-header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CalendarHeaderComponent<D> {

  /**
   * Unique ID counter. Incremented on each instance creation.
   */
  private readonly _uniqueId = `coh-calendar-header-${++nextUniqueId}-`;

  /**
   * Returns the unique id for the visual hidden input.
   */
  get uniqueId(): string {
    return this._uniqueId;
  }

  constructor(
    @Inject(forwardRef(() => MatCalendar)) public calendar: MatCalendar<D>,
    @Optional() private dateAdapter: DateAdapter<D>) {}


  /**
   * Changes calendar view based on direction.
   *
   * @param  dir  Direction to where calendar changes. Increase by month (1) or decrease by month (-1).
   */
  private changeView(dir: 1 | -1) {
    const isMonthView = this.calendar.currentView === 'month';
    const isYearView = this.calendar.currentView === 'year';
    this.calendar.activeDate = isMonthView ? this.dateAdapter.addCalendarMonths(this.calendar.activeDate, dir) :
      this.dateAdapter.addCalendarYears(this.calendar.activeDate, isYearView ? dir : dir * yearsPerPage);

    this.calendar.selectedChange.emit(this.calendar.activeDate);
  }

  /**
   * Handles "Today" button click.
   */
  todayClicked() {
    this.calendar.activeDate = this.dateAdapter.today();
    this.calendar.currentView = 'month';
    this.calendar.selectedChange.emit(this.calendar.activeDate);
  }

  /**
   * Handles user clicks on the previous button.
   */
  previousClicked() {
    this.changeView(-1);
  }

  /**
   * Handles user clicks on the next button.
   */
  nextClicked() {
    this.changeView(1);
  }
}
