// Component: cViewBoxMeta info

;(function(angular, undefined) {
  'use strict';

  /**
   * @ngdoc component
   * @name C.viewBoxMeta:cViewBoxMeta
   *
   * @param   {object}   viewBox   The View Box for which to show meta data.
   *
   * @description
   *   Displays an inline list of View Box meta data.
   *
   * @example
   *   <c-view-box-meta view-box="$ctrl.viewBox"></c-view-box-meta>
   */
  var componentConfig = {
    templateUrl: 'app/global/c-view-box-meta/c-view-box-meta.html',
    bindings: {
      /**
       * Selected viewbox config object
       *
       * @type   {Object}
       */
      viewBox: '<',
    },
  };

  angular.module('C.viewBoxMeta', [])
    .component('cViewBoxMeta', componentConfig);

})(angular);
