import { AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FileChangeType } from '@cohesity/api/argus';
import { FilterDefDirective, FiltersComponent, ValueFilterSelection } from '@cohesity/helix';
import { TranslateService } from '@ngx-translate/core';

/** The default set of change type filters */
export const defaultChangeTypeFilters = [
  FileChangeType.KAddedOrModified,
  FileChangeType.KDeleted,
];

/**
 * Get the filter values for file change type.
 *
 * @param fileChangeTypes The list of file change type.
 * @param translateService The translate service.
 * @returns The list of filter values for change type.
 */
export const getFileChangeTypeFilterValues = (
  fileChangeTypes: FileChangeType[],
  translateService: TranslateService
): ValueFilterSelection[] => fileChangeTypes.map(
  status => ({ label: translateService.instant(`argus.enum.fileChangeType.${status}`), value: status })
);

@Component({
  selector: 'dg-ar-change-type-filter',
  templateUrl: './change-type-filter.component.html',
})
export class ChangeTypeFilterComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  /**
   * name of the filter
   */
  readonly filterName = 'changeTypes';

  /** The default list of change type filters */
  private defaultChangeTypeFilters = getFileChangeTypeFilterValues(defaultChangeTypeFilters, this.translateService);

  /**
   * list of selected change type that should be selected automatically
   */
  @Input() selectedChangeTypes: FileChangeType[];

  /**
   * changeType filter value
   */
  @Input() changeTypeFilters: ValueFilterSelection[] = this.defaultChangeTypeFilters;

  /** The change type filter values */
  get changeTypeFilterValues() {
    return this.changeTypeFilters || this.defaultChangeTypeFilters || [];
  }

  /**
   * The filter def directive for the filter
   */
  @ViewChild(FilterDefDirective, { static: true }) filterDef: FilterDefDirective;

  constructor(private filters: FiltersComponent, private translateService: TranslateService) {}

  ngOnInit() {
    if (this.filters && this.filterDef) {
      this.filters.addFilterDef(this.filterDef);
    }
  }

  ngAfterViewInit(): void {
    this.initSelection();
  }

  ngOnDestroy() {
    if (this.filters && this.filterDef) {
      this.filters.removeFilterDef(this.filterDef);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedChangeTypes || changes.changeTypeFilters) {
      this.initSelection();
    }
  }

  /**
   * selects all the patterns in selected patterns list
   */
  private initSelection() {
    if (this.selectedChangeTypes?.length && this.changeTypeFilterValues?.length) {
      const selectedChangeTypeFilterValues =
        this.changeTypeFilterValues.filter(item => this.selectedChangeTypes?.includes(item.value)) || [];
      this.filters.setValue(this.filterName, selectedChangeTypeFilterValues);
    }
  }
}
