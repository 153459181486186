<cog-settings-list-item
  cogFormGroup
  [label]="label">
  <cog-settings-list-item-value-display>
    {{ formGroupValues.pstPasswordEnabled | boolLabel}}
  </cog-settings-list-item-value-display>
  <cog-settings-list-item-value-editor>
    <mat-slide-toggle [formControl]="formGroupControls.pstPasswordEnabled"
      [inheritDataId]="true"
      cogDataId="password-protection">
      {{ formGroupValues.pstPasswordEnabled | boolLabel}}
    </mat-slide-toggle>
    <!-- PST Password -->
    <div class="pst-password-wr" [hidden]="!formGroupValues.pstPasswordEnabled">
      <mat-form-field>
        <mat-label>{{'office365Restore.pstPassword' | translate}}</mat-label>
        <input matInput
          cogDataId="pst-password-input"
          [inheritDataId]="true"
          type="password"
          [formControl]="formGroupControls.pstPassword"
          #passwordInput>
        <cog-password-visibility-button matSuffix [input]="passwordInput"></cog-password-visibility-button>
        <mat-error>{{ 'errors.required' | translate }}</mat-error>
      </mat-form-field>
    </div>
  </cog-settings-list-item-value-editor>
</cog-settings-list-item>
