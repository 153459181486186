import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AffectedFile } from '@cohesity/api/argus';
import { Api } from '@cohesity/api/private';
import {
  DownloadFilesAndFoldersParams,
  FileSearchResults,
  RestoreTask,
  RestoreTasksServiceApi,
} from '@cohesity/api/v1';
import {
  ArchivalCopy,
  CreateRecoveryRequest,
  RecoverAcropolisParams,
  RecoverAwsParams,
  RecoverAzureParams,
  RecoverExchangeParams,
  RecoverFlashbladeParams,
  RecoverGenericNasParams,
  RecoverHyperVParams,
  RecoverIsilonParams,
  RecoverKvmParams,
  RecoverNetappParams,
  RecoverPhysicalParams,
  RecoverPureParams,
  RecoverSqlParams,
  RecoverVmwareParams,
  Recovery,
  RecoveryServiceApi,
} from '@cohesity/api/v2';
import { MomentDatePipe } from '@cohesity/helix';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';
import { Environment } from '@cohesity/iris-shared-constants';
import { BoolLabelPipe, NaLabelPipe, sanitizeFileName } from '@cohesity/utils';
import { TranslateService } from '@ngx-translate/core';
import { isNil, merge } from 'lodash';
import { Observable } from 'rxjs';

import {
  AnomalyAlert,
  AnomalyRecoveryOperation,
  DataPoint,
  FileRecoveryMetadata,
  PropertyMapObject,
  RecoverObjectType,
  RecoverySource,
  RecoveryTaskRequest,
  RestoreTaskInfo,
  SnapshotCopyInfo,
} from './../security-shared.models';
import {
  booleanValueFormatterFn,
  flattenRecoveryParams,
  naLabelValueFormatterFn,
  recoveryActionValueFormatterFn,
  recoveryProcessTypeValueFormatterFn,
  recoveryToOriginalValueFormatterFn,
  ValueFormatter,
  vmPowerStateValueFormatterFn,
  vmRecoverToNewValueFormatterFn,
} from './recovery-task.utils';

export interface RecoveryParams<T = object> {
  body: T;
  labels: RecoverySettingLabel[];
}

export interface RecoverySettingLabel {
  property: string;
  label: string;
  valueFormatter: ValueFormatter<any>;
}

export interface RecoverySetting {
  label: string;
  value: string;
}

export interface EnvironmentRecoverySetting {
  environment: Environment;
  recoverySettings: RecoverySetting[];
}

export interface GetRecoveryTaskResponse {
  environment: Environment;
  task: RecoveryTaskRequest;
  operation: AnomalyRecoveryOperation;
  params: RecoveryParams;
}

export type GetRecoveryParams<T> = (args: {
  properties: PropertyMapObject;
  snapshotInfo: SnapshotCopyInfo;
  recoverySource: RecoverySource;
  globalVaultId?: string;
}) => RecoveryParams<T>;

@Injectable({
  providedIn: 'root',
})
export class RecoveryTaskService {
  constructor(
    private boolLabelPipe: BoolLabelPipe,
    private datePipe: MomentDatePipe,
    private http: HttpClient,
    private irisCtx: IrisContextService,
    private naLabelPipe: NaLabelPipe,
    private recoveryServiceApi: RecoveryServiceApi,
    private translateService: TranslateService,
  ) { }

  /**
   * Gets a default task name for a recovery task.
   *
   * @param   taskNameKey   The key to use for translating the task name
   * @returns A default task name.
   */
  private getDefaultTaskName(taskNameKey = 'recovery.defaultTaskName'): string {
    const dateString = [
      this.datePipe.transform(new Date(), 'mediumDate'),
      this.datePipe.transform(new Date(), 'shortTime'),
    ].join(' ');

    return sanitizeFileName(this.translateService.instant(taskNameKey, { dateString }));
  }

  /**
   * Return the download file and folder request params for a given alert and snapshot.
   *
   * @param anomalyAlert The alert.
   * @param dataPoint The data point for a the selected snapshot.
   * @returns The download file and folder request params.
   */
  getDownloadTask(
    anomalyAlert: AnomalyAlert,
    dataPoint: DataPoint,
    files: AffectedFile[]
  ): DownloadFilesAndFoldersParams {
    const { clusterId, properties } = anomalyAlert;
    const { jobInstanceId, timestampUsecs } = dataPoint;

    return {
      name: this.getDefaultTaskName('recovery.files.defaultTaskName'),
      filesAndFoldersInfo: (files || []).map(fileEntity => ({
        absolutePath: fileEntity.filePath,
        isDirectory: false,
      })),
      sourceObjectInfo: {
        jobId: +properties.jobId,
        jobRunId: +jobInstanceId,
        jobUid: {
          clusterId,
          clusterIncarnationId: +properties.clusterIncarnationId,
          id: +properties.jobId,
        },
        protectionSourceId: +properties.entityId,
        startedTimeUsecs: +timestampUsecs,
      },
    };
  }

  /**
   * Creates download task of selected files.
   *
   * @param body  Selected files info
   */
  createDownloadFilesTask(body: DownloadFilesAndFoldersParams): Observable<RestoreTask> {
    const url = Api.public('restore/downloadFilesAndFolders');
    return this.http.post<RestoreTask>(url, body, {
      headers: { clusterId: body.sourceObjectInfo.jobUid.clusterId.toString() },
    });
  }

  /**
   * Return the files recovery task request params for a given alert and snapshot.
   *
   * @param anomalyAlert The alert.
   * @param dataPoint The data point for a the selected snapshot.
   * @param filenames   object  file to be recovered
   * @param creds   object    username and password of location
   */
  getFileRecoveryTask(
    anomalyAlert: AnomalyAlert,
    dataPoint: DataPoint,
    filenames: [string],
    dialogMetadata: FileRecoveryMetadata
  ): RestoreTasksServiceApi.CreateRestoreFilesTaskParams {
    const { jobId, cid, clusterIncarnationId, entityId, object, parentId, environment } = anomalyAlert.properties || {};

    const restoreFilesParam: RestoreTasksServiceApi.CreateRestoreFilesTaskParams = {
      body: {
        name: this.getDefaultTaskName('recovery.defaultRecoverFilesTaskName'),
        continueOnError: true,
        sourceObjectInfo: {
          environment: Environment[environment],
          jobId: +jobId,
          jobRunId: +dataPoint?.jobInstanceId,
          jobUid: {
            clusterId: +cid,
            clusterIncarnationId: +clusterIncarnationId,
            id: +jobId,
          },
          protectionSourceId: +entityId,
          sourceName: object,
          startedTimeUsecs: +dialogMetadata.snapshotTimestampUsec,
        },
        targetSourceId: +entityId,
        filenames,
      },
      accessClusterId: +cid,
    };

    // Using switch here as we are very likely to add support for more platforms in future.
    switch (Environment[environment]) {
      case Environment.kVMware:
        merge(restoreFilesParam.body, {
          targetParentSourceId: +parentId,
          username: dialogMetadata.username,
          password: dialogMetadata.password,
        });
        break;
    }

    return restoreFilesParam;
  }

  /**
   * Creates object based recovery task for VMware.
   *
   * @param snapshotId Unique snapshot identifier used for recovery
   * @param regionId The region id.
   * @param accessClusterId The access cluster id.
   * @returns The recovery talk observable.
   */
  createObjectBasedRecoveryRecovery(
    snapshotId: string,
    regionId?: string,
    accessClusterId?: number
  ): Observable<Recovery> {
    const body = this.getVMwareObjectBasedRecoveryParams(snapshotId);
    return this.recoveryServiceApi.CreateRecovery({ body, regionId, accessClusterId });
  }

  /**
   * Constructs object based recovery task params.
   *
   * @param snapshotId Unique snapshot identifier used for recovery
   * @returns The object based recovery task params.
   */
  private getVMwareObjectBasedRecoveryParams(snapshotId: string): CreateRecoveryRequest {
    return {
      name: this.getDefaultTaskName(),
      snapshotEnvironment: Environment.kVMware,
      vmwareParams: {
        objects: [{ snapshotId }],
        recoveryAction: 'RecoverVMs',
        recoverVmParams: {
          targetEnvironment: Environment.kVMware,
          vmwareTargetParams: {
            recoveryTargetConfig: {
              recoverToNewSource: false,
              originalSourceConfig: {
                networkConfig: {
                  detachNetwork: true,
                  disableNetwork: true,
                },
              },
            },
            renameRecoveredVmsParams: {
              prefix: 'copy-',
            },
            powerOnVms: true,
            recoveryProcessType: 'CopyRecovery',
          },
        },
      },
    };
  }

  /**
   * Creates recovery task.
   *
   * @param request  Object containing entityId, jobId etc.
   * @returns The recovery task observer.
   */
  createRecovery(request: RestoreTaskInfo): Observable<FileSearchResults | Recovery | any> {
    let url: string;

    if (request.environment === Environment.kView) {
      switch (request.operation) {
        case AnomalyRecoveryOperation.restoreFiles:
          url = Api.public('restore/files');
          break;
        case AnomalyRecoveryOperation.clone:
          url = Api.private('clone');
          break;
        case AnomalyRecoveryOperation.restore:
        default:
          url = Api.private('restore');
          break;
      }

      return this.http.post<any>(url, request.task, {
        headers: { clusterId: request.clusterId.toString() },
      });
    }

    return this.recoveryServiceApi.CreateRecovery({
      body: request.task,
      accessClusterId: request.clusterId,
      regionId: request.regionId,
    });
  }

  /**
   * Generates list display labels for recovery settings for all the know environemnts
   *
   * @returns list of recovery setting along with there display values for every known environment
   */
  getRecoverySettings = (): EnvironmentRecoverySetting[] => this.getRecoveryTasks().map(({ environment, params }) => {
    if (!params) {
      return null;
    }

    const recoveryParamsValues = flattenRecoveryParams(params.body, {}, '');
    const recoverySettings = (params.labels || []).map(({ label, property, valueFormatter }) => {
      const value = recoveryParamsValues[property] ?? null;
      const formatterValue = valueFormatter && !isNil(value) ? valueFormatter(value) : null;

      if (!label || !formatterValue) {
        return null;
      }

      return {
        label: this.translateService.instant(label),
        value: formatterValue,
      };
    }).filter(Boolean);

    if (!recoverySettings.length) {
      return null;
    }

    return {
      environment,
      recoverySettings,
    };
  }).filter(Boolean);

  /**
   * Return the recovery task for all environments.
   *
   * @param recoverySource The recovery source.
   * @param properties Property map object containing anomaly details
   * @param snapshotInfo The snapshot details.
   * @param globalVaultId Recovery global vault ID.
   * @returns The recovery task request params and operation.
   */
  getRecoveryTasks = (
    recoverySource = RecoverySource.local,
    properties: PropertyMapObject = {},
    snapshotInfo: SnapshotCopyInfo = {},
    globalVaultId: string = null,
  ) => Object.values(Environment).map(environment => this.getRecoveryTask(
    environment,
    recoverySource,
    properties,
    snapshotInfo,
    globalVaultId,
  )).filter(Boolean);

  /**
   * Return the object recovery task request params for a given alert and snapshot.
   *
   * @param environment The environment.
   * @param recoverySource The recovery source.
   * @param properties Property map object containing anomaly details
   * @param snapshotInfo The snapshot details.
   * @param globalVaultId Recovery global vault ID.
   * @returns The recovery task request params and operation.
   */
  getRecoveryTask(
    environment: Environment,
    recoverySource: RecoverySource,
    properties: PropertyMapObject,
    snapshotInfo: SnapshotCopyInfo,
    globalVaultId?: string
  ): GetRecoveryTaskResponse {
    let task: RecoveryTaskRequest;
    let operation = AnomalyRecoveryOperation.restore;
    let params;

    const notSupportedEnvs = new Set<Environment>([
      Environment.kSQL,
      Environment.kAzure,
      Environment.kGenericNas,
      Environment.kAcropolis,
      Environment.kAWS,
      Environment.kFlashBlade,
      Environment.kKVM,
      Environment.kAzureNative,
      Environment.kAWSNative,
    ]);

    if (notSupportedEnvs.has(environment) && !flagEnabled(this.irisCtx.irisContext, 'ngRecoveryForSupportedAdapter')) {
      return null;
    }

    switch (environment) {
      case Environment.kVMware:
        params = this.getVMwareRecoveryParams({ properties, snapshotInfo, recoverySource, globalVaultId });
        task = {
          name: this.getDefaultTaskName('recovery.vm.defaultTaskName'),
          snapshotEnvironment: Environment.kVMware,
          vmwareParams: params.body,
        };
        break;

      case Environment.kPhysical:
        params = this.getPhysicalRecoveryParams({ properties, snapshotInfo, recoverySource, globalVaultId });
        task = {
          name: this.getDefaultTaskName('recovery.physical.defaultTaskName'),
          snapshotEnvironment: Environment.kPhysical,
          physicalParams: params.body,
        };
        break;

      case Environment.kView:
        params = this.getViewRecoveryParams({ properties, snapshotInfo: null, recoverySource: null });
        operation = AnomalyRecoveryOperation.clone;
        task = {
          name: this.getDefaultTaskName('clone.view.defaultTaskName'),
          snapshotEnvironment: Environment.kView,
          objects: [
            {
              entity: {
                parentId: Number(properties.parentId),
              },
            },
          ],
          viewName: `${properties.object}-Copy`,

        };
        break;

      case Environment.kHyperV:
        params = this.getHypervRecoveryParams({ properties, snapshotInfo, recoverySource, globalVaultId });
        task = {
          name: this.getDefaultTaskName('recovery.vm.defaultTaskName'),
          snapshotEnvironment: Environment.kVMware,
          hypervParams: params.body,
        };
        break;

      case Environment.kNetapp:
        params = this.getNetappRecoveryParams({ properties, snapshotInfo, recoverySource, globalVaultId });
        task = {
          name: this.getDefaultTaskName('recovery.sv.defaultTaskName'),
          snapshotEnvironment: Environment.kVMware,
          netappParams: params.body,
        };
        break;

      case Environment.kIsilon:
        params = this.getIsilonRecoveryParams({ properties, snapshotInfo, recoverySource, globalVaultId });
        task = {
          name: this.getDefaultTaskName('recovery.sv.defaultTaskName'),
          snapshotEnvironment: Environment.kVMware,
          isilonParams: params.body,
        };
        break;

      case Environment.kPure:
        params = this.getPureRecoveryParams({ properties, snapshotInfo, recoverySource, globalVaultId });
        task = {
          name: this.getDefaultTaskName('recovery.sv.defaultTaskName'),
          snapshotEnvironment: Environment.kVMware,
          pureParams: params.body,
        };
        break;

      case Environment.kSQL:
        params = this.getSqlRecoveryParams({ properties, snapshotInfo, recoverySource: null });
        task = {
          name: this.getDefaultTaskName(),
          snapshotEnvironment: Environment.kSQL,
          mssqlParams: params.body,
        };
        break;

      case Environment.kAzure:
        params = this.getAzureRecoveryParams({ properties, snapshotInfo, recoverySource, globalVaultId });
        task = {
          name: this.getDefaultTaskName(),
          snapshotEnvironment: Environment.kAzure,
          azureParams: params.body,
        };
        break;

      case Environment.kAzureNative:
        params = this.getAzureNativeRecoveryParams({ properties, snapshotInfo, recoverySource, globalVaultId });
        task = {
          name: this.getDefaultTaskName('recovery.vm.defaultTaskName'),
          snapshotEnvironment: Environment.kAzure,
          azureParams: params.body,
        };
        break;

      case Environment.kGenericNas:
        params = this.getGenericNASRecoveryParams({ properties, snapshotInfo, recoverySource, globalVaultId });
        task = {
          name: this.getDefaultTaskName(),
          snapshotEnvironment: Environment.kGenericNas,
          genericNasParams: params.body,
        };
        break;

      case Environment.kAcropolis:
        params = this.getAcropolisRecoveryParams({ properties, snapshotInfo, recoverySource, globalVaultId });
        task = {
          name: this.getDefaultTaskName(),
          snapshotEnvironment: Environment.kAcropolis,
          acropolisParams: params.body,
        };
        break;

      case Environment.kKVM:
        params = this.getKvmRecoveryParams({ properties, snapshotInfo, recoverySource, globalVaultId });
        task = {
          name: this.getDefaultTaskName(),
          snapshotEnvironment: Environment.kKVM,
          kvmParams: params.body,
        };
        break;

      case Environment.kAWS:
        params = this.getAwsRecoveryParams({ properties, snapshotInfo, recoverySource, globalVaultId });
        task = {
          name: this.getDefaultTaskName('recovery.defaultRecoverFilesTaskName'),
          snapshotEnvironment: Environment.kAWS,
          awsParams: params.body,
        };
        break;

      case Environment.kAWSNative:
        params = this.getAwsNativeRecoveryParams({ properties, snapshotInfo, recoverySource, globalVaultId });
        task = {
          name: this.getDefaultTaskName('recovery.vm.defaultTaskName'),
          snapshotEnvironment: Environment.kAWS,
          awsParams: params.body,
        };
        break;

      case Environment.kExchange:
        params = this.getExchangeRecoveryParams({ properties, snapshotInfo, recoverySource: null });
        task = {
          name: this.getDefaultTaskName('recovery.exchange.defaultTaskName'),
          snapshotEnvironment: Environment.kExchange,
          exchangeParams: params.body,
        };
        break;

      case Environment.kFlashBlade:
        params = this.getFlashBladeRecoveryParams({ properties, snapshotInfo, recoverySource, globalVaultId });
        task = {
          name: this.getDefaultTaskName('recovery.defaultRecoverFilesTaskName'),
          snapshotEnvironment: Environment.kFlashBlade,
          flashbladeParams: params.body,
        };
        break;
    }

    if (!params || !task) {
      return null;
    }

    return { environment, task, operation, params };
  }

  /**
   * Constructs the recovery object properties for V2 recovery.
   *
   * @param   snapshotInfo   The snapshot information.
   * @param   recoverySource   The recovery source.
   * @param   globalVaultId   Recovery global vault ID.
   * @returns The object properties to be sent for recovery.
   */
  private getRecoverObjectSettings(
    snapshotInfo: SnapshotCopyInfo,
    recoverySource: RecoverySource,
    globalVaultId?: string
  ): RecoverObjectType {
    if (!snapshotInfo || !recoverySource) {
      return null;
    }

    let selectedSnapshotId = snapshotInfo.localSnapshot;
    let archivalSnapshot: ArchivalCopy;
    const isArchivalSnapshot = recoverySource === RecoverySource.vault;

    if (isArchivalSnapshot) {
      if (globalVaultId) {
        archivalSnapshot = snapshotInfo.archivalSnapshot.find(snapshot =>
          globalVaultId ? snapshot.globalVaultId === globalVaultId : snapshot.isRPaas);
      } else {
        archivalSnapshot = snapshotInfo.archivalSnapshot[0];
      }

      // For RPaaS, make sure we have an RPaaS archived data or revert back to local.
      selectedSnapshotId = archivalSnapshot?.snapshotId || selectedSnapshotId;
    }

    return [
      {
        snapshotId: selectedSnapshotId,
        archivalTargetInfo: archivalSnapshot
          ? {
            targetId: archivalSnapshot.archivalTarget?.targetId,
            targetName: archivalSnapshot.archivalTarget?.name,
            ownershipContext: 'FortKnox',
          }
          : null,
      },
    ];
  }

  /**
   * VM specific recovery parameters containing which will be merged with base task
   *
   * @param properties Property map object containing anomaly details
   * @param snapshotInfo The snapshot information.
   * @param recoverySource The recovery source.
   * @param globalVaultId Recovery global vault ID.
   * @returns The recovery task params.
   */
  private getVMwareRecoveryParams: GetRecoveryParams<RecoverVmwareParams> = ({
    snapshotInfo,
    recoverySource,
    globalVaultId,
  }) => {
    const body: RecoverVmwareParams = {
      objects: this.getRecoverObjectSettings(snapshotInfo, recoverySource, globalVaultId),
      recoveryAction: 'RecoverVMs',
      recoverVmParams: {
        targetEnvironment: 'kVMware',
        vmwareTargetParams: {
          powerOnVms: true,
          attemptDifferentialRestore: false,
          continueOnError: false,
          overwriteExistingVm: true,
          recoveryTargetConfig: {
            recoverToNewSource: false,
          },
          recoveryProcessType: 'InstantRecovery',
        },
      },
    };
    const labels = [
      {
        property: 'recoverVmParams.vmwareTargetParams.recoveryProcessType',
        label: 'recoveryType',
        valueFormatter: recoveryProcessTypeValueFormatterFn(this.translateService),
      },
      {
        property: 'recoverVmParams.vmwareTargetParams.overwriteExistingVm',
        label: 'existingVmHandling',
        valueFormatter: booleanValueFormatterFn(this.boolLabelPipe, 'overwriteExistingVm', null),
      },
      {
        property: 'recoverVmParams.vmwareTargetParams.powerOnVms',
        label: 'powerState',
        valueFormatter: vmPowerStateValueFormatterFn(this.boolLabelPipe),
      },
      {
        property: 'recoverVmParams.vmwareTargetParams.recoveryTargetConfig.recoverToNewSource',
        label: 'recoverTo',
        valueFormatter: vmRecoverToNewValueFormatterFn(this.boolLabelPipe),
      },
      {
        property: 'recoveryAction',
        label: null,
        valueFormatter: null,
      },
      {
        property: 'recoverVmParams.targetEnvironment',
        label: null,
        valueFormatter: null,
      },
      {
        property: 'recoverVmParams.vmwareTargetParams.attemptDifferentialRestore',
        label: null,
        valueFormatter: null,
      },
      {
        property: 'recoverVmParams.vmwareTargetParams.continueOnError',
        label: null,
        valueFormatter: null,
      },
    ];

    return { body, labels };
  };

  /**
   * Physical specific recovery parameters containing which will be merged with base task
   *
   * @param properties Property map object containing anomaly details
   * @param snapshotInfo The snapshot information.
   * @param recoverySource The recovery source.
   * @param globalVaultId Recovery global vault ID.
   * @returns The recovery task params.
   */
  private getPhysicalRecoveryParams: GetRecoveryParams<RecoverPhysicalParams> = ({
    properties,
    snapshotInfo,
    recoverySource,
    globalVaultId,
  }) => {
    const body: RecoverPhysicalParams = {
      objects: this.getRecoverObjectSettings(snapshotInfo, recoverySource, globalVaultId),
      recoveryAction: 'InstantVolumeMount',
      mountVolumeParams: {
        targetEnvironment: 'kPhysical',
        physicalTargetParams: {
          mountToOriginalTarget: true,
          newTargetConfig: {
            mountTarget: {
              id: Number(properties.entityId),
            },
          },
        },
      },
    };

    const labels = [
      {
        property: 'recoveryAction',
        label: 'recoveryType',
        valueFormatter: recoveryActionValueFormatterFn(this.translateService),
      },
      {
        property: 'mountVolumeParams.physicalTargetParams.mountToOriginalTarget',
        label: 'recoverTo',
        valueFormatter: recoveryToOriginalValueFormatterFn(this.boolLabelPipe),
      },
      {
        property: 'mountVolumeParams.targetEnvironment',
        label: null,
        valueFormatter: null,
      },
      {
        property: 'mountVolumeParams.physicalTargetParams.newTargetConfig.mountTarget.id',
        label: null,
        valueFormatter: null,
      },
    ];

    return { body, labels };
  };

  /**
   * View specific recovery parameters containing which will be merged with base task
   *
   * @param properties Property map object containing anomaly details
   * @param snapshotInfo The snapshot information.
   * @param recoverySource The recovery source.
   * @returns The recovery task params.
   */
  private getViewRecoveryParams: GetRecoveryParams<RecoveryTaskRequest['viewParams']> = ({
    properties,
  }) => {
    const body: RecoveryTaskRequest['viewParams'] = {
      sourceViewName: properties.object,
      cloneViewName: `${properties.object}-Copy`,
      qos: {
        // QoS Policy: TestAndDevHigh
        // This is static id taken from /configs/go/data/qos_principals.go
        id: 6,
      },
      recoveryAction: 'DownloadFilesAndFolders',
    };

    const labels: RecoverySettingLabel[] = [
      {
        property: 'recoveryAction',
        label: 'recoveryType',
        valueFormatter: recoveryActionValueFormatterFn(this.translateService),
      },
      {
        property: 'cloneViewName',
        label: 'addSuffix',
        valueFormatter: () => this.translateService.instant('copy'),
      },
      {
        property: 'qos.id',
        label: 'qosPolicy',
        valueFormatter: () => this.translateService.instant('testAndDevHigh'),
      },
    ];

    return { body, labels };
  };

  /**
   * HyperV specific recovery parameters containing which will be merged with base task
   *
   * @param properties Property map object containing anomaly details
   * @param snapshotInfo The snapshot information.
   * @param recoverySource The recovery source.
   * @param globalVaultId Recovery global vault ID.
   * @returns The recovery task params.
   */
  private getHypervRecoveryParams: GetRecoveryParams<RecoverHyperVParams> = ({
    snapshotInfo,
    recoverySource,
    globalVaultId,
  }) => {
    const body: RecoverHyperVParams =  {
      recoveryAction: 'RecoverVMs',
      objects: this.getRecoverObjectSettings(snapshotInfo, recoverySource, globalVaultId),
      recoverVmParams: {
        targetEnvironment: 'kHyperV',
        hypervTargetParams: {
          continueOnError: false,
          powerOnVms: true,
          renameRecoveredVmsParams: {
            prefix: `${this.translateService.instant('recover')}-`,
            suffix: `-${this.translateService.instant('vm')}`,
          },
          preserveUuids: true,
          instantRecovery: true,
          recoveryTargetConfig: {
            recoverToNewSource: false,
          },
        },
      },
    };

    const labels: RecoverySettingLabel[] = [
      {
        property: 'recoveryAction',
        label: 'recoveryType',
        valueFormatter: recoveryActionValueFormatterFn(this.translateService),
      },
      {
        property: 'recoverVmParams.hypervTargetParams.powerOnVms',
        label: 'powerState',
        valueFormatter: vmPowerStateValueFormatterFn(this.boolLabelPipe),
      },
      {
        property: 'recoverVmParams.hypervTargetParams.renameRecoveredVmsParams.prefix',
        label: 'addPrefix',
        valueFormatter: naLabelValueFormatterFn(this.naLabelPipe, this.translateService),
      },
      {
        property: 'recoverVmParams.hypervTargetParams.renameRecoveredVmsParams.suffix',
        label: 'addSuffix',
        valueFormatter: naLabelValueFormatterFn(this.naLabelPipe, this.translateService),
      },
      {
        property: 'recoverVmParams.hypervTargetParams.preserveUuids',
        label: 'existingVmHandling',
        valueFormatter: booleanValueFormatterFn(this.boolLabelPipe, 'overwriteExistingVm', null),
      },
      {
        property: 'recoverVmParams.hypervTargetParams.recoveryTargetConfig.recoverToNewSource',
        label: 'recoverTo',
        valueFormatter: vmRecoverToNewValueFormatterFn(this.boolLabelPipe),
      },
      {
        property: 'recoverVmParams.targetEnvironment',
        label: null,
        valueFormatter: null,
      },
      {
        property: 'recoverVmParams.hypervTargetParams.continueOnError',
        label: null,
        valueFormatter: null,
      },
      {
        property: 'recoverVmParams.hypervTargetParams.instantRecovery',
        label: null,
        valueFormatter: null,
      },
    ];

    return { body, labels };
  };

  /**
   * NetApp & Isilon file based recovery parameters
   *
   * @param properties  Property map object containing anomaly details
   */
  private getFileBasedSvRecoveryParams(properties: PropertyMapObject, jobRunId: number, startedTimeUsecs: number) {
    return {
      name: this.getDefaultTaskName('recovery.sv.defaultTaskName'),
      sourceObjectInfo: {
        jobId: Number(properties.jobId),
        jobUid: {
          clusterId: Number(properties.cid),
          clusterIncarnationId: Number(properties.clusterIncarnationId),
          id: Number(properties.jobId),
        },
        jobRunId,
        startedTimeUsecs,
        protectionSourceId: Number(properties.entityId),
      },
      targetSourceId: Number(properties.entityId),
      targetParentSourceId: Number(properties.parentId),
      isFileBasedVolumeRestore: true,
      filenames: ['/'],
      overwrite: true,
      preserveAttributes: true,
      continueOnError: false,
    };
  }

  /**
   * NetApp file based recovery parameters
   *
   * @param properties Property map object containing anomaly details
   * @param snapshotInfo The snapshot information.
   * @param recoverySource The recovery source.
   * @param globalVaultId Recovery global vault ID.
   * @returns The recovery task params.
   */
  private getNetappRecoveryParams: GetRecoveryParams<RecoverNetappParams> = ({
    snapshotInfo,
    recoverySource,
    globalVaultId,
  }) => {
    const body: RecoverNetappParams = {
      objects: this.getRecoverObjectSettings(snapshotInfo, recoverySource, globalVaultId),
      recoveryAction: 'RecoverFiles',
      recoverFileAndFolderParams: {
        targetEnvironment: 'kNetapp',
        filesAndFolders: [],
        netappTargetParams: {
          recoverToNewSource: false,
          originalSourceConfig: {
            recoverToOriginalPath: true,
            continueOnError: false,
            overwriteExistingFile: true,
            preserveFileAttributes: true,
          },
        },
      },
    };

    const labels: RecoverySettingLabel[] = [
      {
        property: 'recoveryAction',
        label: 'recoveryType',
        valueFormatter: recoveryActionValueFormatterFn(this.translateService),
      },
      {
        property: 'recoverFileAndFolderParams.netappTargetParams.originalSourceConfig.overwriteExistingFile',
        label: 'overrideOriginals',
        valueFormatter: booleanValueFormatterFn(this.boolLabelPipe),
      },
      {
        property: 'recoverFileAndFolderParams.netappTargetParams.originalSourceConfig.recoverToOriginalPath',
        label: 'recoverTo',
        valueFormatter: recoveryToOriginalValueFormatterFn(this.boolLabelPipe),
      },
      {
        property: 'recoverFileAndFolderParams.netappTargetParams.originalSourceConfig.continueOnError',
        label: null,
        valueFormatter: null,
      },
      {
        property: 'recoverFileAndFolderParams.netappTargetParams.originalSourceConfig.preserveFileAttributes',
        label: null,
        valueFormatter: null,
      },
      {
        property: 'recoverFileAndFolderParams.targetEnvironment',
        label: null,
        valueFormatter: null,
      },
      {
        property: 'recoverFileAndFolderParams.netappTargetParams.recoverToNewSource',
        label: null,
        valueFormatter: null,
      },
    ];

    return { body, labels };
  };

  /**
   * Isilon file based recovery parameters
   *
   * @param properties Property map object containing anomaly details
   * @param snapshotInfo The snapshot information.
   * @param recoverySource The recovery source.
   * @param globalVaultId Recovery global vault ID.
   * @returns The recovery task params.
   */
  private getIsilonRecoveryParams: GetRecoveryParams<RecoverIsilonParams> = ({
    snapshotInfo,
    recoverySource,
    globalVaultId,
  }) => {
    const body: RecoverIsilonParams = {
      objects: this.getRecoverObjectSettings(snapshotInfo, recoverySource, globalVaultId),
      recoveryAction: 'RecoverFiles',
      recoverFileAndFolderParams: {
        targetEnvironment: 'kIsilon',
        filesAndFolders: [],
        isilonTargetParams: {
          recoverToNewSource: false,
          originalSourceConfig: {
            recoverToOriginalPath: true,
            continueOnError: false,
            overwriteExistingFile: true,
            preserveFileAttributes: true,
          },
        },
      },
    };

    const labels: RecoverySettingLabel[] = [
      {
        property: 'recoveryAction',
        label: 'recoveryType',
        valueFormatter: recoveryActionValueFormatterFn(this.translateService),
      },
      {
        property: 'recoverFileAndFolderParams.isilonTargetParams.originalSourceConfig.overwriteExistingFile',
        label: 'overrideOriginals',
        valueFormatter: booleanValueFormatterFn(this.boolLabelPipe),
      },
      {
        property: 'recoverFileAndFolderParams.isilonTargetParams.originalSourceConfig.recoverToOriginalPath',
        label: 'recoverTo',
        valueFormatter: recoveryToOriginalValueFormatterFn(this.boolLabelPipe),
      },
      {
        property: 'recoverFileAndFolderParams.targetEnvironment',
        label: null,
        valueFormatter: null,
      },
      {
        property: 'recoverFileAndFolderParams.isilonTargetParams.recoverToNewSource',
        label: null,
        valueFormatter: null,
      },
      {
        property: 'recoverFileAndFolderParams.isilonTargetParams.originalSourceConfig.continueOnError',
        label: null,
        valueFormatter: null,
      },
      {
        property: 'recoverFileAndFolderParams.isilonTargetParams.originalSourceConfig.preserveFileAttributes',
        label: null,
        valueFormatter: null,
      },
    ];
    return { body, labels };
  };

  /**
   * Pure specific recovery parameters
   *
   * @param properties Property map object containing anomaly details
   * @param snapshotInfo The snapshot information.
   * @param recoverySource The recovery source.
   * @param globalVaultId Recovery global vault ID.
   * @returns The recovery task params.
   */
  private getPureRecoveryParams: GetRecoveryParams<RecoverPureParams> = ({
    snapshotInfo,
    recoverySource,
    globalVaultId,
  }) => {
    const body: RecoverPureParams = {
      objects: this.getRecoverObjectSettings(snapshotInfo, recoverySource, globalVaultId),
      recoveryAction: 'RecoverSanVolumes',
      recoverSanVolumeParams: {
        targetEnvironment: 'kPure',
        pureTargetParams: {
          recoverToNewSource: false,
          originalSourceConfig: {
            renameRecoveredVolumeParams: {
              prefix: `${this.translateService.instant('recover')}-`,
              suffix: `-${this.translateService.instant('volume')}`,
            },
          },
        },
      },
    };
    const labels: RecoverySettingLabel[] = [
      {
        property: 'recoveryAction',
        label: 'recoveryType',
        valueFormatter: recoveryActionValueFormatterFn(this.translateService),
      },
      {
        property: 'recoverSanVolumeParams.pureTargetParams.originalSourceConfig.renameRecoveredVolumeParams.prefix',
        label: 'addPrefix',
        valueFormatter: naLabelValueFormatterFn(this.naLabelPipe, this.translateService),
      },
      {
        property: 'recoverSanVolumeParams.pureTargetParams.originalSourceConfig.renameRecoveredVolumeParams.suffix',
        label: 'addSuffix',
        valueFormatter: naLabelValueFormatterFn(this.naLabelPipe, this.translateService),
      },
      {
        property: 'recoverSanVolumeParams.pureTargetParams.recoverToNewSource',
        label: 'recoverTo',
        valueFormatter: vmRecoverToNewValueFormatterFn(this.boolLabelPipe),
      },
      {
        property: 'recoverSanVolumeParams.targetEnvironment',
        label: null,
        valueFormatter: null,
      },
    ];

    return { body, labels };
  };

  /**
   * Get sql recovery params.
   *
   * @param properties Property map object containing anomaly details
   * @param snapshotInfo The snapshot information.
   * @param recoverySource The recovery source.
   * @returns The recovery task params.
   */
  private getSqlRecoveryParams: GetRecoveryParams<RecoverSqlParams> = ({
    snapshotInfo,
  }) => {
    const body: RecoverSqlParams = {
      recoveryAction: 'RecoverApps',
      recoverAppParams: [
        {
          snapshotId: snapshotInfo.localSnapshot,
          targetEnvironment: 'kSQL',
          sqlTargetParams: {
            recoverToNewSource: false,
            originalSourceConfig: {
              keepCdc: false,
              multiStageRestoreOptions: {
                enableAutoSync: false,
                enableMultiStageRestore: false,
              },
              nativeRecoveryWithClause: 'with clause',
              overwritingPolicy: 'Overwrite',
              restoreTimeUsecs: 123,
              secondaryDataFilesDirList: [
                {
                  directory: './',
                  filenamePattern: '.txt',
                },
              ],
              withNoRecovery: false,
            },
          },
        },
      ],
    };
    const labels: RecoverySettingLabel[] = [
      {
        property: 'recoveryAction',
        label: 'recoveryType',
        valueFormatter: recoveryActionValueFormatterFn(this.translateService),
      },
      {
        property: 'recoverAppParams.0.sqlTargetParams.recoverToNewSource',
        label: 'recoverTo',
        valueFormatter: vmRecoverToNewValueFormatterFn(this.boolLabelPipe),
      },
      {
        property: 'recoverAppParams.0.sqlTargetParams.originalSourceConfig.overwritingPolicy',
        label: 'overwrite',
        valueFormatter: naLabelValueFormatterFn(this.naLabelPipe, this.translateService, null),
      },
      {
        property: 'recoverAppParams.0.targetEnvironment',
        label: null,
        valueFormatter: null
      },
      {
        property: 'recoverAppParams.0.sqlTargetParams.originalSourceConfig.keepCdc',
        label: null,
        valueFormatter: null,
      },
      {
        property: 'recoverAppParams.0.sqlTargetParams.originalSourceConfig.multiStageRestoreOptions.enableAutoSync',
        label: null,
        valueFormatter: null,
      },
      {
        property:
          'recoverAppParams.0.sqlTargetParams.originalSourceConfig.multiStageRestoreOptions.enableMultiStageRestore',
        label: null,
        valueFormatter: null,
      },
      {
        property: 'recoverAppParams.0.sqlTargetParams.originalSourceConfig.nativeRecoveryWithClause',
        label: null,
        valueFormatter: null,
      },
      {
        property: 'recoverAppParams.0.sqlTargetParams.originalSourceConfig.restoreTimeUsecs',
        label: null,
        valueFormatter: null,
      },
      {
        property: 'recoverAppParams.0.sqlTargetParams.originalSourceConfig.secondaryDataFilesDirList.0.directory',
        label: null,
        valueFormatter: null,
      },
      {
        property: 'recoverAppParams.0.sqlTargetParams.originalSourceConfig.secondaryDataFilesDirList.0.filenamePattern',
        label: null,
        valueFormatter: null,
      },
      {
        property: 'recoverAppParams.0.sqlTargetParams.originalSourceConfig.withNoRecovery',
        label: null,
        valueFormatter: null,
      },
    ];

    return { body, labels };
  };

  /**
   * Get azure recovery prams.
   *
   * @param properties Property map object containing anomaly details
   * @param snapshotInfo The snapshot information.
   * @param recoverySource The recovery source.
   * @param globalVaultId Recovery global vault ID.
   * @returns The recovery task params.
   */
  private getAzureRecoveryParams: GetRecoveryParams<RecoverAzureParams> = ({
    snapshotInfo,
    recoverySource,
    globalVaultId,
  }) => {
    const body: RecoverAzureParams = {
      recoveryAction: 'RecoverFiles',
      objects: this.getRecoverObjectSettings(snapshotInfo, recoverySource, globalVaultId),
      recoverFileAndFolderParams: {
        targetEnvironment: 'kAzure',
        filesAndFolders: [],
        azureTargetParams: {
          recoverToOriginalTarget: true,
          continueOnError: false,
          overwriteExisting: true,
          preserveAttributes: true,
        },
      },
    };

    const labels: RecoverySettingLabel[] = [
      {
        property: 'recoveryAction',
        label: 'recoveryType',
        valueFormatter: recoveryActionValueFormatterFn(this.translateService),
      },
      {
        property: 'recoverFileAndFolderParams.azureTargetParams.recoverToOriginalTarget',
        label: 'recoverTo',
        valueFormatter: recoveryToOriginalValueFormatterFn(this.boolLabelPipe),
      },
      {
        property: 'recoverFileAndFolderParams.azureTargetParams.overwriteExisting',
        label: 'overwrite',
        valueFormatter: booleanValueFormatterFn(this.boolLabelPipe),
      },
      {
        property: 'recoverFileAndFolderParams.targetEnvironment',
        label: null,
        valueFormatter: null,
      },
      {
        property: 'recoverFileAndFolderParams.azureTargetParams.continueOnError',
        label: null,
        valueFormatter: null,
      },
      {
        property: 'recoverFileAndFolderParams.azureTargetParams.preserveAttributes',
        label: null,
        valueFormatter: null,
      },
    ];

    return { body, labels };
  };

  /**
   * Get azure native recovery prams.
   *
   * @param properties Property map object containing anomaly details
   * @param snapshotInfo The snapshot information.
   * @param recoverySource The recovery source.
   * @param globalVaultId Recovery global vault ID.
   * @returns The recovery task params.
   */
  private getAzureNativeRecoveryParams: GetRecoveryParams<RecoverAzureParams> = ({
    snapshotInfo,
    recoverySource,
    globalVaultId,
  }) => {
    const body: RecoverAzureParams = {
      recoveryAction: 'RecoverVMs',
      objects: this.getRecoverObjectSettings(snapshotInfo, recoverySource, globalVaultId),
      recoverVmParams: {
        targetEnvironment: 'kAzure',
        azureTargetParams: {
          powerOnVms: true,
          continueOnError: false,
          recoveryTargetConfig: {
            recoverToNewSource: false,
          },
          renameRecoveredVmsParams: {
            prefix: `${this.translateService.instant('recover')}-`,
            suffix: `-${this.translateService.instant('volume')}`,
          },
        },
      },
    };

    const labels: RecoverySettingLabel[] = [
      {
        property: 'recoveryAction',
        label: 'recoveryType',
        valueFormatter: recoveryActionValueFormatterFn(this.translateService),
      },
      {
        property: 'recoverVmParams.azureTargetParams.powerOnVms',
        label: 'powerState',
        valueFormatter: vmPowerStateValueFormatterFn(this.boolLabelPipe),
      },
      {
        property: 'recoverVmParams.azureTargetParams.recoveryTargetConfig.recoverToNewSource',
        label: 'recoverTo',
        valueFormatter: vmRecoverToNewValueFormatterFn(this.boolLabelPipe),
      },
      {
        property: 'recoverVmParams.azureTargetParams.renameRecoveredVmsParams.prefix',
        label: 'addPrefix',
        valueFormatter: naLabelValueFormatterFn(this.naLabelPipe, this.translateService),
      },
      {
        property: 'recoverVmParams.azureTargetParams.renameRecoveredVmsParams.suffix',
        label: 'addSuffix',
        valueFormatter: naLabelValueFormatterFn(this.naLabelPipe, this.translateService),
      },
      {
        property: 'recoverVmParams.targetEnvironment',
        label: null,
        valueFormatter: null,
      },
      {
        property: 'recoverVmParams.azureTargetParams.continueOnError',
        label: null,
        valueFormatter: null,
      },
    ];

    return { body, labels };
  };

  /**
   * Get generic NAS recovery params.
   *
   * @param properties Property map object containing anomaly details
   * @param snapshotInfo The snapshot information.
   * @param recoverySource The recovery source.
   * @param globalVaultId Recovery global vault ID.
   * @returns The recovery task params.
   */
  private getGenericNASRecoveryParams: GetRecoveryParams<RecoverGenericNasParams> = ({
    snapshotInfo,
    recoverySource,
    globalVaultId,
  }) => {
    const body: RecoverGenericNasParams = {
      recoveryAction: 'RecoverFiles',
      objects: this.getRecoverObjectSettings(snapshotInfo, recoverySource, globalVaultId),
      recoverFileAndFolderParams: {
        targetEnvironment: 'kGenericNas',
        filesAndFolders: [],
        genericNasTargetParams: {
          recoverToNewSource: false,
          originalSourceConfig: {
            recoverToOriginalPath: true,
            continueOnError: false,
            overwriteExistingFile: true,
            preserveFileAttributes: true,
          },
        },
      },
    };

    const labels: RecoverySettingLabel[] = [
      {
        property: 'recoveryAction',
        label: 'recoveryType',
        valueFormatter: recoveryActionValueFormatterFn(this.translateService),
      },
      {
        property: 'recoverFileAndFolderParams.genericNasTargetParams.originalSourceConfig.recoverToOriginalPath',
        label: 'recoverTo',
        valueFormatter: recoveryToOriginalValueFormatterFn(this.boolLabelPipe),
      },
      {
        property: 'recoverFileAndFolderParams.genericNasTargetParams.originalSourceConfig.overwriteExistingFile',
        label: 'overwrite',
        valueFormatter: booleanValueFormatterFn(this.boolLabelPipe),
      },
      {
        property: 'recoverFileAndFolderParams.targetEnvironment',
        label: null,
        valueFormatter: null,
      },
      {
        property: 'recoverFileAndFolderParams.genericNasTargetParams.recoverToNewSource',
        label: null,
        valueFormatter: null,
      },
      {
        property: 'recoverFileAndFolderParams.genericNasTargetParams.originalSourceConfig.continueOnError',
        label: null,
        valueFormatter: null,
      },
      {
        property: 'recoverFileAndFolderParams.genericNasTargetParams.originalSourceConfig.preserveFileAttributes',
        label: null,
        valueFormatter: null,
      },
    ];

    return { body, labels };
  };

  /**
   * Get acropolis recovery task params.
   *
   * @param properties Property map object containing anomaly details
   * @param snapshotInfo The snapshot information.
   * @param recoverySource The recovery source.
   * @param globalVaultId Recovery global vault ID.
   * @returns The recovery task params.
   */
  private getAcropolisRecoveryParams: GetRecoveryParams<RecoverAcropolisParams> = ({
    snapshotInfo,
    recoverySource,
    globalVaultId,
  }) => {
    const body: RecoverAcropolisParams = {
      recoveryAction: 'RecoverFiles',
      objects: this.getRecoverObjectSettings(snapshotInfo, recoverySource, globalVaultId),
      recoverFileAndFolderParams: {
        targetEnvironment: 'kAcropolis',
        filesAndFolders: [],
        acropolisTargetParams: {
          preserveAttributes: true,
          recoverToOriginalTarget: true,
          continueOnError: false,
          overwriteExisting: true,
        },
      },
    };

    const labels: RecoverySettingLabel[] = [
      {
        property: 'recoveryAction',
        label: 'recoveryType',
        valueFormatter: recoveryActionValueFormatterFn(this.translateService),
      },
      {
        property: 'recoverFileAndFolderParams.acropolisTargetParams.recoverToOriginalTarget',
        label: 'recoverTo',
        valueFormatter: recoveryToOriginalValueFormatterFn(this.boolLabelPipe),
      },
      {
        property: 'recoverFileAndFolderParams.acropolisTargetParams.overwriteExisting',
        label: 'overwrite',
        valueFormatter: booleanValueFormatterFn(this.boolLabelPipe),
      },
      {
        property: 'recoverFileAndFolderParams.targetEnvironment',
        label: null,
        valueFormatter: null,
      },
      {
        property: 'recoverFileAndFolderParams.acropolisTargetParams.preserveAttributes',
        label: null,
        valueFormatter: null,
      },
      {
        property: 'recoverFileAndFolderParams.acropolisTargetParams.continueOnError',
        label: null,
        valueFormatter: null,
      },
    ];

    return { body, labels };
  };

  /**
   * Get KVM recovery tasks params.
   *
   * @param properties Property map object containing anomaly details
   * @param snapshotInfo The snapshot information.
   * @param recoverySource The recovery source.
   * @param globalVaultId Recovery global vault ID.
   * @returns The recovery task params.
   */
  private getKvmRecoveryParams: GetRecoveryParams<RecoverKvmParams> = ({
    snapshotInfo,
    recoverySource,
    globalVaultId,
  }) => {
    const body: RecoverKvmParams = {
      recoveryAction: 'RecoverVMs',
      objects: this.getRecoverObjectSettings(snapshotInfo, recoverySource, globalVaultId),
      recoverVmParams: {
        targetEnvironment: 'kKVM',
        kvmTargetParams: {
          continueOnError: false,
          powerOnVms: true,
          renameRecoveredVmsParams: {
            prefix: `${this.translateService.instant('recover')}-`,
            suffix: `-${this.translateService.instant('volume')}`,
          },
          recoveryTargetConfig: {
            recoverToNewSource: false,
          },
        },
      },
    };

    const labels: RecoverySettingLabel[] = [
      {
        property: 'recoveryAction',
        label: 'recoveryType',
        valueFormatter: recoveryActionValueFormatterFn(this.translateService),
      },
      {
        property: 'recoverVmParams.kvmTargetParams.powerOnVms',
        label: 'powerState',
        valueFormatter: vmPowerStateValueFormatterFn(this.boolLabelPipe),
      },
      {
        property: 'recoverVmParams.kvmTargetParams.renameRecoveredVmsParams.prefix',
        label: 'addPrefix',
        valueFormatter: naLabelValueFormatterFn(this.naLabelPipe, this.translateService),
      },
      {
        property: 'recoverVmParams.kvmTargetParams.renameRecoveredVmsParams.suffix',
        label: 'addSuffix',
        valueFormatter: naLabelValueFormatterFn(this.naLabelPipe, this.translateService),
      },
      {
        property: 'recoverVmParams.targetEnvironment',
        label: null,
        valueFormatter: null,
      },
      {
        property: 'recoverVmParams.kvmTargetParams.continueOnError',
        label: null,
        valueFormatter: null,
      },
      {
        property: 'recoverVmParams.kvmTargetParams.recoveryTargetConfig.recoverToNewSource',
        label: null,
        valueFormatter: vmRecoverToNewValueFormatterFn(this.boolLabelPipe),
      },
    ];

    return { body, labels };
  };

  /**
   * Get AWS recovery task params.
   *
   * @param properties Property map object containing anomaly details
   * @param snapshotInfo The snapshot information.
   * @param recoverySource The recovery source.
   * @param globalVaultId Recovery global vault ID.
   * @returns The recovery task params.
   */
  private getAwsRecoveryParams: GetRecoveryParams<RecoverAwsParams> = ({
    snapshotInfo,
    recoverySource,
    globalVaultId,
  }) => {
    const body: RecoverAwsParams = {
      recoveryAction: 'RecoverFiles',
      objects: this.getRecoverObjectSettings(snapshotInfo, recoverySource, globalVaultId),
      recoverFileAndFolderParams: {
        targetEnvironment: 'kAWS',
        filesAndFolders: [],
        awsTargetParams: {
          recoverToOriginalTarget: true,
          continueOnError: false,
          overwriteExisting: true,
          originalTargetConfig: {
            recoverToOriginalPath: true,
          },
        },
      },
    };

    const labels: RecoverySettingLabel[] = [
      {
        property: 'recoveryAction',
        label: 'recoveryType',
        valueFormatter: recoveryActionValueFormatterFn(this.translateService),
      },
      {
        property: 'recoverFileAndFolderParams.awsTargetParams.overwriteExisting',
        label: 'overwrite',
        valueFormatter: booleanValueFormatterFn(this.boolLabelPipe),
      },
      {
        property: 'recoverFileAndFolderParams.awsTargetParams.originalTargetConfig.recoverToOriginalPath',
        label: 'recoverTo',
        valueFormatter: recoveryToOriginalValueFormatterFn(this.boolLabelPipe),
      },
      {
        property: 'recoverFileAndFolderParams.targetEnvironment',
        label: null,
        valueFormatter: null,
      },
      {
        property: 'recoverFileAndFolderParams.awsTargetParams.recoverToOriginalTarget',
        label: null,
        valueFormatter: null,
      },
      {
        property: 'recoverFileAndFolderParams.awsTargetParams.continueOnError',
        label: null,
        valueFormatter: null,
      },
    ];

    return { body, labels };
  };

  /**
   * Get AWS native recovery task prams.
   *
   * @param properties Property map object containing anomaly details
   * @param snapshotInfo The snapshot information.
   * @param recoverySource The recovery source.
   * @param globalVaultId Recovery global vault ID.
   * @returns The recovery task params.
   */
  private getAwsNativeRecoveryParams: GetRecoveryParams<RecoverAwsParams> = ({
    snapshotInfo,
    recoverySource,
    globalVaultId,
  }) => {
    const body: RecoverAwsParams = {
      recoveryAction: 'RecoverVMs',
      objects: this.getRecoverObjectSettings(snapshotInfo, recoverySource, globalVaultId),
      recoverVmParams: {
        targetEnvironment: 'kAWS',
        awsTargetParams: {
          continueOnError: false,
          powerOnVms: true,
          recoveryTargetConfig: {
            recoverToNewSource: false,
          },
          renameRecoveredVmsParams: {
            prefix: `${this.translateService.instant('recover')}-`,
            suffix: `-${this.translateService.instant('volume')}`,
          },
        },
      },
    };

    const labels: RecoverySettingLabel[] = [
      {
        property: 'recoveryAction',
        label: 'recoveryType',
        valueFormatter: recoveryActionValueFormatterFn(this.translateService),
      },
      {
        property: 'recoverVmParams.awsTargetParams.powerOnVms',
        label: 'powerState',
        valueFormatter: vmPowerStateValueFormatterFn(this.boolLabelPipe),
      },
      {
        property: 'recoverVmParams.awsTargetParams.renameRecoveredVmsParams.prefix',
        label: 'addPrefix',
        valueFormatter: naLabelValueFormatterFn(this.naLabelPipe, this.translateService),
      },
      {
        property: 'recoverVmParams.awsTargetParams.renameRecoveredVmsParams.suffix',
        label: 'addSuffix',
        valueFormatter: naLabelValueFormatterFn(this.naLabelPipe, this.translateService),
      },
      {
        property: 'recoverVmParams.targetEnvironment',
        label: null,
        valueFormatter: null,
      },
      {
        property: 'recoverVmParams.awsTargetParams.continueOnError',
        label: null,
        valueFormatter: null,
      },
      {
        property: 'recoverVmParams.awsTargetParams.recoveryTargetConfig.recoverToNewSource',
        label: null,
        valueFormatter: null,
      },
    ];

    return { body, labels };
  };

  /**
   * Get exchange recovery task params.
   *
   * @param properties Property map object containing anomaly details
   * @param snapshotInfo The snapshot information.
   * @param recoverySource The recovery source.
   * @returns The recovery task params.
   */
  private getExchangeRecoveryParams: GetRecoveryParams<RecoverExchangeParams> = ({
    snapshotInfo,
  }) => {
    const body: RecoverExchangeParams = {
      recoveryAction: 'RecoverApps',
      recoverAppParams: {
        targetEnvironment: 'kExchange',
        exchangeTargetParams: {
          object: {
            snapshotId: snapshotInfo.localSnapshot,
          },
        },
      },
    };

    const labels: RecoverySettingLabel[] = [
      {
        property: 'recoveryAction',
        label: 'recoveryType',
        valueFormatter: recoveryActionValueFormatterFn(this.translateService),
      },
      {
        property: 'recoverAppParams.targetEnvironment',
        label: null,
        valueFormatter: null,
      },
    ];

    return { body, labels };
  };

  /**
   * Get Flash blade recovery task params.
   *
   * @param properties Property map object containing anomaly details
   * @param snapshotInfo The snapshot information.
   * @param recoverySource The recovery source.
   * @param globalVaultId Recovery global vault ID.
   * @returns The recovery task params.
   */
  private getFlashBladeRecoveryParams: GetRecoveryParams<RecoverFlashbladeParams> = ({
    snapshotInfo,
    recoverySource,
    globalVaultId,
  }) => {
    const body: RecoverFlashbladeParams = {
      recoveryAction: 'RecoverFiles',
      objects: this.getRecoverObjectSettings(snapshotInfo, recoverySource, globalVaultId),
      recoverFileAndFolderParams: {
        targetEnvironment: 'kFlashBlade',
        filesAndFolders: [],
        flashbladeTargetParams: {
          recoverToNewSource: false,
          originalSourceConfig: {
            recoverToOriginalPath: true,
            continueOnError: false,
            overwriteExistingFile: true,
          },
        },
      },
    };

    const labels: RecoverySettingLabel[] = [
      {
        property: 'recoveryAction',
        label: 'recoveryType',
        valueFormatter: recoveryActionValueFormatterFn(this.translateService),
      },
      {
        property: 'recoverFileAndFolderParams.flashbladeTargetParams.originalSourceConfig.recoverToOriginalPath',
        label: 'recoverTo',
        valueFormatter: booleanValueFormatterFn(this.boolLabelPipe, 'originalLocation'),
      },
      {
        property: 'recoverFileAndFolderParams.flashbladeTargetParams.originalSourceConfig.overwriteExistingFile',
        label: 'overwrite',
        valueFormatter: booleanValueFormatterFn(this.boolLabelPipe),
      },
      {
        property: 'recoverFileAndFolderParams.flashbladeTargetParams.originalSourceConfig.continueOnError',
        label: null,
        valueFormatter: null,
      },
      {
        property: 'recoverFileAndFolderParams.targetEnvironment',
        label: null,
        valueFormatter: null,
      },
      {
        property: 'recoverFileAndFolderParams.flashbladeTargetParams.recoverToNewSource',
        label: null,
        valueFormatter: null,
      },
    ];

    return { body, labels };
  };
}
