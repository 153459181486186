import { ChangeDetectionStrategy, Component, OnChanges } from '@angular/core';
import { HighchartsDataConfigService } from '@cohesity/helix';
import { Options, SeriesPieOptions } from 'highcharts';

import { CustomChartInput } from '../../../iris-reporting.model';
import { ChartReportsService } from '../../chart-reports.service';
import { BaseReportItemRendererComponent } from '../base-report-item-renderer.component';
import { statusColors } from '../../report-item.model';

/**
 * A donut chart report item.
 */
@Component({
  selector: 'iris-rpt-donut-chart-item',
  templateUrl: './donut-chart-item.component.html',
  styleUrls: ['./donut-chart-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DonutChartItemComponent extends BaseReportItemRendererComponent implements OnChanges, CustomChartInput {
  /**
   * The chart's series, transformed from the input.
   */
  donutChartSeries: SeriesPieOptions[] = [];

  /**
   * Values to use for the legend. The index is used to map to the correct highcharts
   * color, and the datatype is passed to a data renderer to render the data type correctly.
   * The values are split into two separate arrays to be displayed as columns
   */
  legendValues: {
    name: string;
    value: number;
    index: number;
    dataType: string;
  }[][] = [];

  /**
   * Custom options for the bubble chart.
   */
  options: Options = {
    plotOptions: {
      pie: {
        ...this.chartPlotOptions,
      },
    },
  };

  /**
   * The the sum of the legend values
   */
  get totalValue(): number {
    return this.legendValues.reduce(
      (total, column) => total + column.reduce((itemTotal, item) => itemTotal + item.value, 0),
      0
    );
  }

  /**
   * Get the data type for the legend values
   */
  get dataType(): string {
    return this.legendValues[0][0].dataType;
  }

  /**
   * Given a chart config, determine whether this chart can be used to render it or now.
   *
   * @param input The chart input
   * @returns True if this component can render it.
   */
  static canRender(input: CustomChartInput): boolean {
    return input.dimensions?.length === 1 && input.measurements?.length === 1;
  }

  constructor(private chartService: ChartReportsService, private highchartConfigService: HighchartsDataConfigService) {
    super();
  }

  ngOnChanges() {
    const {seriesData, xValues, originalXValues} =
      this.chartService.getChartData(this.data, this.measurements, this.dimensions);
    this.options.plotOptions.pie.animation = {
      duration: this.isPrintMedia ? 200 : 1000,
    };

    const dataConfig = this.highchartConfigService.getConfigForDataType(this.measurements[0].dataType);
    this.options.tooltip = {
      pointFormatter: dataConfig.tooltipPointFormatter,
    };

    // This forces the chart to redraw with the updated options
    this.options = { ...this.options };

    this.donutChartSeries = [...seriesData.entries()].map(([metricName, seriesValue]) => ({
      type: 'pie',
      name: this.translate(metricName),
      data: seriesValue.data.map((value, index) => ({
        name: xValues && this.translate(xValues[index] as string),
        y: value,
        dataType: seriesValue.dataType,
        custom: this.getCustomData(seriesValue?.originalKey, originalXValues[index]),
        className: statusColors[originalXValues[index]] ? `chart-series-${statusColors[originalXValues[index]]}` : null
      })),
    }));

    const allValues: DonutChartItemComponent['legendValues'][number] = [];
    this.donutChartSeries.forEach(series => {
      allValues.push(
        ...series.data.map((point: any, index) => ({
          name: point.name,
          value: point.y,
          dataType: point.dataType,
          index,
          className: point.className
        }))
      );
    });
    const splitOn = Math.ceil(allValues.length / 2);
    this.legendValues = [[...allValues.slice(0, splitOn)], [...allValues.slice(splitOn)]];
  }
}
