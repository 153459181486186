<mat-card-content class="cog-dashcard-layout-top" *ngIf="!chartOnly">
  <ng-content select="cog-dashcard-top"></ng-content>
</mat-card-content>
<mat-divider [inset]="true" *ngIf="!chartOnly"></mat-divider>
<mat-card-content>
  <div class="cog-dashcard-chart-wrapper">
    <div class="cog-dashcard-chart-container">
      <ng-content select="cog-dashcard-chart"></ng-content>
    </div>
    <div class="cog-dashcard-legend-container" *ngIf="!noLegend">
      <ng-content select="cog-dashcard-legend"></ng-content>
    </div>
  </div>
  <div class="cog-dashcard-footer" *ngIf="hasFooter">
    <ng-content select="cog-dashcard-footer"></ng-content>
  </div>
</mat-card-content>
