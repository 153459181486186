import { AppModuleConfig, StateAccessMap } from 'src/app/app-module.config';

export const ErrorConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'unauthorized.**',
      url: '/unauthorized',
      loadChildren: () => import('./error.module').then(m => m.ErrorModule)
    },
  ],
  getStateAccessMap(): StateAccessMap {
    return {
      'unauthorized': () => true,
    };
  }
};
