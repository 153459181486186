/* tslint:disable */

/**
 * Specifies tag UI color.
 */
export enum TagUiColor {
  Red = 'Red',
  Orange = 'Orange',
  Yellow = 'Yellow',
  Purple = 'Purple',
  Blue = 'Blue',
  Green = 'Green',
  Grey = 'Grey'
}
