import { ParserRuleContext, RecognitionException, Vocabulary } from 'antlr4ts';
import { ErrorNode } from 'antlr4ts/tree/ErrorNode';
import { TerminalNode } from 'antlr4ts/tree/TerminalNode';

import { CursorLocation, Result, Suggestion } from './advanced-search.models';

/**
 * Enquote the input text by double quotes if the text contains space.
 *
 * @example
 * maybeEnquote("India::IN") // `India::IN`
 * maybeEnquote("United States::US") // `'United States::US'`
 *
 * @param text The text to enquote.
 * @param always The optional always if present and true then always enquote the text.
 * @returns Return the enquoted text.
 */
export const maybeEnquote = (text: string, always = false): string => {
  const shouldEnquote = always || /\s/i.test(text);
  return shouldEnquote ? `'${text}'` : text;
};

/**
 * Return the escaped pattern by escaping RegExp restricted character.
 *
 * @param pattern The regex pattern.
 * @returns The escaped pattern.
 */
export const escapeRegExpPattern = (pattern: string): string =>
  // $& means the whole matched string
  (pattern ?? '').replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
;

/**
 * Replace the provided text at the given location within the source text.
 *
 * @param sourceTxt The source text.
 * @param text The new text to be replace.
 * @param cursorLocation The location where to replace the new text.
 * @returns The updated test.
 */
export const replaceText = (sourceTxt: string, text: string, cursorLocation: CursorLocation): string => {
  const pre = sourceTxt.slice(0, cursorLocation.start);
  const post = sourceTxt.slice(cursorLocation.end + 1);

  return [pre, (text || ''), post].join('');
};

/**
 * Return true if the text location is at the end of the search query else return false.
 *
 * @param searchQuery The input search query.
 * @param textLocation The text location.
 * @returns Return true if the text location is at the end of the search query else return false.
 */
export const isLastToken = (searchQuery: string, textLocation: CursorLocation): boolean => {
  const endLoc = (searchQuery || '').length;
  return endLoc === textLocation.end + 1 || endLoc === textLocation.end;
};

/**
 * Get updated search query after replacing selected result's value at the given location.
 *
 * @param searchQuery The input search query.
 * @param result The result.
 * @param cursorLocation The text location.
 * @returns The updated search query.
 */
export const getResultValue = (searchQuery: string, result: Result, cursorLocation: CursorLocation): string => {
  const endLoc = searchQuery.length;
  const textLocation: CursorLocation = cursorLocation || { start: endLoc, end: endLoc };
  const isLast = isLastToken(searchQuery, textLocation);

  return replaceText(searchQuery, (result.value || '') + (isLast ? ' ' : ''), textLocation);
};

/**
 * Get sorted results by highlighted text before others.
 *
 * @param results The list of results.
 * @returns The list of sorted results.
 */
export const sortResults = (results: Result[]): Result[] => (results || []).sort((first, second) => {
  // first is less than second by some ordering criterion.
  if (first.labelParts?.highlighted && !second.labelParts?.highlighted) {
    return -1;
  }

  // first is greater than second by the ordering criterion.
  if (!first.labelParts?.highlighted && second.labelParts?.highlighted) {
    return 1;
  }

  // first must be equal to second.
  return 0;
});

/**
 * Enrich the results with model values and sort them.
 *
 * @param results The list of results.
 * @param searchQuery The search query.
 * @param suggestion The suggestion.
 * @returns The list of enriched results.
 */
export const enrichResults = (results: Result[], searchQuery: string, suggestion: Suggestion): Result[] => {
  const textRegex = new RegExp(`^(${escapeRegExpPattern(suggestion?.text)})(.*)`, 'i');
  const resultList = (results || []).map(result => {
    const [, highlighted, remaining] = (result.value || '').match(textRegex) || [];

    return {
      ...result,
      modelValue: getResultValue(searchQuery, result, suggestion.textLocation),
      labelParts: { highlighted, remaining: remaining ?? result.value },
    };
  });

  return sortResults(resultList);
};

/**
 * Enrich the suggestion's results with model values and sort them.
 *
 * @param searchQuery The search query.
 * @param suggestion The suggestion.
 * @returns The enriched suggestion.
 */
export const enrichSuggestion = (searchQuery: string, suggestion: Suggestion): Suggestion => ({
  ...suggestion,
  results: enrichResults(suggestion?.results, searchQuery, suggestion),
  resultValues: enrichResults(suggestion?.resultValues, searchQuery, suggestion),
});

/**
 * Get the suggestion results from the exception.
 *
 * @param exception The exception object.
 * @param vocabulary The grammar's vocabulary.
 * @returns The suggestion results.
 */
export const getResults = (exception: RecognitionException, vocabulary: Vocabulary): Result[] =>
  exception.expectedTokens.toArray().map(token => {
    // slice out single quotes around the literal values eg.
    // '=' => =
    // '!=' => !=
    let literalName = vocabulary.getLiteralName(token)?.slice(1, -1);
    const symbolicName = vocabulary.getSymbolicName(token);

    // Getting values for symbolic constants like K_AND, K_OR, K_IN, K_IS, K_DESC, K_ASC etc
    if (symbolicName.match(/^K_/g)) {
      literalName = symbolicName.replace(/^K_/, '');
    }

    return {
      label: symbolicName || vocabulary.getDisplayName(token),
      value: literalName || null,
    };
  });

/**
 * Get the node's text location with-in search query.
 *
 * @param node The AST node.
 * @returns The node's text location.
 */
export const getNodeLocation = (node: ParserRuleContext | TerminalNode): CursorLocation => {
  if (node instanceof TerminalNode) {
    return {
      start: node.symbol.startIndex,
      end: Math.max(node.symbol.startIndex, node.symbol.stopIndex),
    };
  }

  return {
    start: node.start.startIndex,
    end: Math.max((node.stop ?? node.start).stopIndex, node.start.startIndex),
  };
};

/**
 * Find the selected node.
 *
 * @param ctx The AST node.
 * @returns The selected AST node.
 */
export const getSelectedNode = (nodes: ParserRuleContext[], cursorLocation: CursorLocation): ParserRuleContext =>
  (nodes || []).find((node, index, nodeList) => {
    const location = getNodeLocation(node);
    const prevNode = nodeList[index - 1];

    // example "|      status = value       "
    // example "   |   status = value       "
    // example "      |status = value       "
    const prevNodeLoc = prevNode ? getNodeLocation(prevNode) : { start: -1, end: -1 };

    return prevNodeLoc.end + 1 <= cursorLocation.end && cursorLocation.end <= location.end + 1;
  });

/**
 * Get the list of leaf nodes.
 *
 * @param ctx The AST node.
 * @returns The list of leaf nodes.
 */
export const getLeafNodes = (ctx: ParserRuleContext): ParserRuleContext[] => {
  const children = (ctx.children || []) as ParserRuleContext[];

  // node w/o any children are leaf nodes.
  if (!children.length) {
    if (ctx instanceof ErrorNode) {
      const errorNodeLoc = getNodeLocation(ctx);

      // consider parent node as leaf for nodes suggested by ANTLR when it is trying to recover from the error using
      // DefaultErrorStrategy which will contain the node location as -1.
      if (errorNodeLoc.start === -1 && errorNodeLoc.end === -1) {
        return [ctx.parent];
      }
    }

    return [ctx];
  }

  // recursively goto each child and collect leaf nodes.
  return children.reduce((acc, child) => ([...acc, ...getLeafNodes(child)]), [] as ParserRuleContext[]);
};

/**
 * Find the parent node which is an instance of one of the provided ctx types.
 *
 * @param node The AST node.
 * @param ctxTypes The ctx type.
 * @returns The found parent node.
 */
export const getParent = (node: ParserRuleContext, ctxTypes: any[]): ParserRuleContext => {
  if (!node) {
    return;
  }

  let foundNode: ParserRuleContext;

  // find whether node is an instance of any one of the provided ctx types.
  ctxTypes.find(ctxType => {
    if (node instanceof ctxType) {
      foundNode = node;
      return true;
    }
  });

  // return found node else check for the parent node.
  return foundNode || getParent(node.parent, ctxTypes);
};
