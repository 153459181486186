import {
  ComponentFactory,
  Type,
  Inject,
  Injectable,
  Injector,
  NgModuleRef,
  Compiler,
  NgModuleFactory,
} from '@angular/core';
import { of, from, Observable, throwError } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import {
  DynamicComponentManifest,
  DynamicComponentEntry,
  DYNAMIC_COMPONENTS,
  DYNAMIC_COMPONENT_MANIFESTS,
} from './dynamic-component-manifest';

/**
 * This service is used to load components from lazy loaded modules.
 */
@Injectable({
  providedIn: 'root'
})
export class DynamicComponentLoaderService {
  constructor(
    @Inject(DYNAMIC_COMPONENT_MANIFESTS)
    private manifests: DynamicComponentManifest[],
    private compiler: Compiler,
    private injector: Injector
  ) {}

  /**
   * This looks up a component id in the component manifest, loads it's module,
   * and then creates a component factory for it.
   *
   * @param   componentId   The id of the component. This is defined in the component manifest
   *                        and the lazy loaded component
   * @param   injector      An optional injector to use. Otherwise the application injector will be
   *                        used to create the component.
   * @return  An observable that will resolve with the component factory. This can be added to a
   *          ViewContainerRef.
   */
  getComponentFactory<T>(componentId: string, injector?: Injector): Observable<ComponentFactory<T>> {
    return this.load<T>(componentId, injector)
    .pipe(
      map(ngModuleRef => this.loadFactory(ngModuleRef, componentId))
    );
  }

  /**
   * Looks up the module and loads the module ref.
   *
   * @param   componentId   The id of the component. This is defined in the component manifest
   *                        and the lazy loaded component
   * @param   injector      An optional injector to use. Otherwise the application injector will be
   *                        used to create the component.
   * @return  An observable of the loaded ng module.
   */
  private load<T>(componentId: string, injector?: Injector): Observable<NgModuleRef<T>> {
    const manifest = this.manifests.find(m => m.componentId === componentId);
    if (!manifest) {
      throwError(`DynamicComponentLoader: Unknown componentId "${componentId}"`);
    }
    const moduleLoader = manifest.loadChildren();

    return (moduleLoader instanceof Promise ? from(moduleLoader) : of(moduleLoader)).pipe(
      switchMap((moduleOrFactory: Type<T> | NgModuleFactory<T>) => {
        if (moduleOrFactory instanceof NgModuleFactory) {
          return of(moduleOrFactory);
        }
        return this.compiler.compileModuleAsync(moduleOrFactory);
      }),
      map(ngModuleFactory => (ngModuleFactory).create(injector || this.injector))
    );
  }

  /**
   * Once the module is loaded, look up the component and create the component factory.
   *
   * @param   ngModuleRef   An instance of the lazy loaded module
   * @param   componentId   The component id to look up and load
   * @return  The component factory for the id. Throws an error if it does not exist.
   */
  private loadFactory<T>(ngModuleRef: NgModuleRef<any>, componentId: string): ComponentFactory<T> {
    const dynamicComponents: DynamicComponentEntry[] = ngModuleRef.injector.get(DYNAMIC_COMPONENTS, null);

    if (!dynamicComponents) {
      throw new Error(
        `DynamicComponentLoder: Dynamic module for componentId ${componentId}` +
          'does not contain DYNAMIC_COMPONENTS as a provider'
      );
    }
    const dynamicComponentType: DynamicComponentEntry = dynamicComponents.find(
      item => item.componentId === componentId
    );

    if (!dynamicComponentType) {
      throw new Error(`DynamicComponentLoder: Module does not provide a component with id: ${componentId}`);
    }

    return ngModuleRef.componentFactoryResolver.resolveComponentFactory<T>(dynamicComponentType.component);
  }
}
