import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { AutoDestroyable, getMsecsToTimePeriodValue, getTimePeriodtoMsecsValue, splitTimeValue } from '@cohesity/utils';
import { pairwise, startWith } from 'rxjs/operators';

import {
  defaultTimePeriodOptions,
  LimitInterval,
  TimePeriodOptions,
  TimePeriodSelectorForm,
} from '../time-period-selector/time-period-selector-form';

/**
 * An extension of time-period-selector component which splits the input value into two time-period-selectors.
 *
 * @example
 *   const timePeriod = new FormControl(...) // value can be in msecs.
 *
 *   <coh-dual-time-period-selector timePeriod="timePeriod"
 *      timePeriodOptions="..."
 *      valueLimit="...">
 *   </coh-dual-time-period-selector>
 *
 *   // timePeriod.value would of the type of initial value provided to
 *   // FormControl.
 */
@Component({
  selector: 'coh-dual-time-period-selector',
  templateUrl: './dual-time-period-selector.component.html',
  styleUrls: ['./dual-time-period-selector.component.scss'],
})
export class DualTimePeriodSelectorComponent extends AutoDestroyable implements OnInit {
  /**
   * The form control. Value should be passed in msecs preferrably. Although, it
   * can also be passed as TimePeriodValue.
   */
  @Input() timePeriod: UntypedFormControl;

  /**
   * The time period options.
   */
  @Input() timePeriodOptions: TimePeriodOptions[] = defaultTimePeriodOptions;

  /**
   * The valueLimit (in Days).
   */
  @Input() valueLimit: LimitInterval = {
    min: 1,
    max: 365000,
  };

  /**
   * Internal form array used inside the component to display one or more input selectors.
   */
  innerForm: UntypedFormArray;

  constructor() {
    super();
  }

  ngOnInit() {
    this.timePeriod.valueChanges
      .pipe(
        this.untilDestroy(),
        startWith(null),
        pairwise(),
      )
      .subscribe(([old, val]) => {
        if (old !== val) {
          const [quotient, remainder] = splitTimeValue(val);
          this.innerForm.setValue([
            getMsecsToTimePeriodValue(quotient),
            getMsecsToTimePeriodValue(remainder)
          ], { emitEvent: false });
          this.innerForm.updateValueAndValidity();
        }
      });

    this.innerForm = new UntypedFormArray([]);

    // timePeriod value can be in msecs or TimePeriodValue.
    const msecsVal =
      typeof this.timePeriod?.value === 'number'
        ? this.timePeriod?.value
        : getTimePeriodtoMsecsValue(this.timePeriod?.value);

    const [quot, rem] = splitTimeValue(msecsVal);

    this.innerForm.push(new TimePeriodSelectorForm(quot, this.timePeriodOptions, this.valueLimit));
    this.innerForm.push(new TimePeriodSelectorForm(rem, this.timePeriodOptions, this.valueLimit));

    this.innerForm.valueChanges.pipe(this.untilDestroy()).subscribe(val => {
      let combinedVal = 0;
      val.forEach(element => {
        combinedVal += getTimePeriodtoMsecsValue(element);
      });
      this.timePeriod.setValue(
        typeof this.timePeriod?.value === 'number' ? combinedVal : getMsecsToTimePeriodValue(combinedVal)
      );
    });
  }
}
