// Controller: Reports: Summary

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.reports')
    .controller('reportsJobsSummaryController', reportsJobsSummaryControllerFn);

  function reportsJobsSummaryControllerFn(
    $rootScope, $scope, $state, $q, DateTimeService, JobRunsService,
    JobService, ExternalTargetService, evalAJAX, SourceService, ReportsService,
    ENUM_BACKUP_JOB_STATUS, CHART_COLORS, ReportsUtil, cUtils, $translate,
    ENUM_BACKUP_JOB_STATUS_LABEL_CLASSNAME, cReportsControlsUtil,
    FEATURE_FLAGS) {

    $scope.text = $rootScope.text.reportsJobsSummary;

    //convenience functions and variables
    $scope.jobs = [];
    $scope.getData = getData;
    $scope.downloadBackupSummaryReport = downloadBackupSummaryReport;
    $scope.hiddenColumns = ReportsUtil.getConfigByKeyAsMap('reportsAjs.jobsSummary.hiddenColumns');

    $scope.ENUM_BACKUP_JOB_STATUS = ENUM_BACKUP_JOB_STATUS;
    $scope.ENUM_BACKUP_JOB_STATUS_LABEL_CLASSNAME =
      ENUM_BACKUP_JOB_STATUS_LABEL_CLASSNAME;
    $scope.FEATURE_FLAGS = FEATURE_FLAGS;

    $scope.summaryControlsConfig =
      ReportsUtil.getReportConfig('kBackupSummaryReport')();

    $state.current.help = 'reports_jobs';

    $scope.jobStatusChart = {
      chartType: 'donut',
      loading: true,
      colors: [
        CHART_COLORS.brand,
        CHART_COLORS.green,
        CHART_COLORS.yellow,
        CHART_COLORS.red,
        CHART_COLORS.gold,
      ],
      chart: {
        height: 250,
      },
      series: [{
        type: 'pie',
        name: 'jobs',
        data: [],
      }]
    };

    /**
     * activate function for initial report and listeners setup
     */
    function activate() {
      var defaultFilters;

      defaultFilters = cReportsControlsUtil.getDefaultFilters({
        registeredSourceIds: [],
        typeAhead: {},
      });

      getData(defaultFilters);
    }

    /**
     * returns params object for api call
     *
     * @param      {object}    filters    filters from c-reports-controls
     *
     * @return    {object}               filter values to be passed to API
     */
    function getParams(filters) {
      return ReportsService.extendWithTenantParams({
        ids: filters.jobId,
        startTimeUsecs: filters.timeObject.from.toUsecDate(),
        endTimeUsecs: filters.timeObject.until.toUsecDate(),
        onlyReturnJobDescription: false,
        statuses: filters.runStatus,
        sourceIds: filters.registeredSourceIds || [],
        _includeTenantInfo: true,
      });
    }

    /**
     * requests a csv download via JobRunsService
     */
    function downloadBackupSummaryReport() {
      var params = getParams(cReportsControlsUtil.filters);
      params.outputFormat = 'csv';

      JobRunsService.getJobSummary(params);
    }

    /**
     * calls the API via JobRunsService and sets up the report
     */
    function getData(filters) {
      $scope.dataReady = false;
      $scope.jobStatusChart.loading = true;

      var promiseObject = {
        jobSummary: JobRunsService.getJobSummary(getParams(filters)),
      };

      // To correctly populate the _copyTasks array onto each job,
      // JobRunsService requires `targetNameMapById` to be popluated. If the
      // user goes directly to the reports state targetNameMapById is not
      // populated.
      if ($rootScope.user.privs.CLUSTER_EXTERNAL_TARGET_VIEW &&
        angular.equals({}, ExternalTargetService.targetNameMapById)) {
        promiseObject.externalTargets = ExternalTargetService.getTargets();
      }

      buildRunsChart(filters);

      // TODO (Saleh): Add getJobRuns API call inside buildRunsChart to promise
      // object
      $q.all(promiseObject).then(function getJobSummarySuccess(res) {
        parseJobSummaryData(res.jobSummary.data);
      }, evalAJAX.errorMessage)
      .finally(function getJobSummaryFinally() {
        $scope.jobStatusChart.loading = false;
        $scope.dataReady = true;
      });
    }

    function isSourceAvailable(source) {
      return SourceService.parentSourceIds.includes(source.id);
    }

    function parseJobSummaryData(backupJobs) {
      var totalJobCount = 0;

      // hashed per ENUM_BACKUP_JOB_STATUS
      var jobCounts = {
        0: 0,
        1: 0,
        2: 0,
        2.1: 0,
        2.2: 0,
        2.3: 0,
        3: 0,
        3.1: 0,
      };

      // empty the jobs runs object array before rebuilding it
      $scope.jobs.length = 0;

      var job;

      for (var index = 0; index < backupJobs.length; index++) {
        job = backupJobs[index];

        job._jobRuns = (job.backupJobSummary.numFailedJobRuns || 0) +
          (job.backupJobSummary.numSuccessfulJobRuns || 0) +
          (job.backupJobSummary.numCancelledJobRuns || 0);

        // Add JobRuns to collection for smart table
        if (job.backupJobSummary.lastProtectionRun) {
          job._lastRunStatus =
            JobService.getStatus(job.backupJobSummary.lastProtectionRun.backupRun);
        }

        job._normalizedParent =
          SourceService.normalizeEntity(job.backupJobSummary.jobDescription.parentSource);

        job._sourceAvailable = isSourceAvailable(job._normalizedParent);

        job._copyTasks = JobRunsService.getCopyTasks(
          job.backupJobSummary.lastProtectionRun,
          job.backupJobSummary.jobDescription.jobUid,
          job.backupJobSummary.jobDescription.isActive
        );

        // Add a bandwidth property which is data read/duration (usecs) if
        // Feature Flag is enabled.
        if (FEATURE_FLAGS.networkBandwidthForBackupJobSummaryReport) {
          var bandwidth = job.backupJobSummary.totalBytesReadFromSource !== 0 ?
            job.backupJobSummary.totalBytesReadFromSource*1000000/
            job.backupJobSummary.avgRunTimeUsecs : 0;
          job._bandwidth = $translate.instant('nPerSec',
            {n: cUtils.bytesToSize(bandwidth, 2).string });
        }

        $scope.jobs.push(job);
        // only add the job to our chart if it has a last run
        if (angular.isDefined(job._lastRunStatus)) {
          // increment status counter for job runs chart
          jobCounts[job._lastRunStatus]++;

          // increment total job count
          totalJobCount++;
        }
      }

      // only displaying Running, Success, Error, Canceled
      $scope.jobStatusChart.series[0].data = [
        [ENUM_BACKUP_JOB_STATUS[1], (jobCounts[1] + jobCounts[3.1])],
        [ENUM_BACKUP_JOB_STATUS[2.1], jobCounts[2.1]],
        [ENUM_BACKUP_JOB_STATUS[2.3], jobCounts[2.3]],
        [ENUM_BACKUP_JOB_STATUS[2.2], jobCounts[2.2]],
        [ENUM_BACKUP_JOB_STATUS[3], jobCounts[3]],
      ];
    }


    $scope.jobsRunningChart = {
      // start and end date are displayed in HTML below the chart
      startDate: null,
      endDate: null,
      chartType: 'stacked',
      loading: true,
      series: [{
        name: $scope.text.jobsRunningChart?.labelRunning,
        data: []
      }, {
        name: $scope.text.jobsRunningChart?.labelSuccess,
        data: []
      }, {
        name: $scope.text.jobsRunningChart?.labelWarning,
        data: []
      }, {
        name: $scope.text.jobsRunningChart?.labelError,
        data: []
      }, {
        name: $scope.text.jobsRunningChart?.labelCanceled,
        data: []
      }],
      chart: {
        height: 250,
        marginBottom: 35,
        spacingBottom: 0
      },
      legend: {
        align: 'left',
        verticalAlign: 'top',
        x: -8,
        y: -16
      },
      tooltip: {
        pointFormat: '<b>{point.y:.1f} jobs</b>'
      },
      yAxis: {
        allowDecimals: false,
        title: {
          text: 'Job Runs',
          align: 'high',
          offset: 35,
          rotation: 90,
          y: 115,
          style: {
            fontSize: '11px',
            lineHeight: '12px',
            color: '#868686'
          }
        }
      },
      xAxis: {
        categories: [],
        labels: {
          staggerLines: 1,
          step: 2
        }
      },
      plotOptions: {
        series: {
          pointWidth: 12
        }
      }
    };

    function buildRunsChart(filters) {

      var seriesActiveData = [];
      var seriesSuccessData = [];
      var seriesErrorData = [];
      var seriesWarningData = [];
      var seriesCanceledData = [];

      // properties of runsPerHour will be structured like (0-23)
      // 0: {
      //     numActiveRuns: 0,
      //     numSuccessfulRuns: 0,
      //     numWarningRuns: 0,
      //     numErrorRuns: 0,
      //     numViolationRuns: 0,
      //     startUsecs: ####,
      //     endUsecs: ####
      // }
      var categories = [];
      var dayAgoHour;
      var currentHourMaxUsecs;
      var currentHourMinUsecs;
      var job;
      var jobNameLower;
      var jobRun;
      var jobStartTime;
      var jobEndTime;
      var hourStart;
      var hourEnd;
      var runsPerHour = {};
      var totalRuns = 0;
      var usecsPerHour = 3600000000;
      var now = new Date(Date.clusterNow());
      var dayAgo = new Date(Date.clusterNow());
      var categoryDate = new Date(Date.clusterNow());
      var preferredDateFormat = DateTimeService.getPreferredDateFormat();
      var categoriesAxisTimeFormat = DateTimeService.getShortHourMeridianFormat();

      // adjust dayAgo back a day
      dayAgo.setDate(dayAgo.getDate() - 1);

      // categories start one day ago
      categoryDate.setDate(categoryDate.getDate() - 1);

      // but shift forward an hour, as we only want 23 hours ago + current hour
      categoryDate.setHours(categoryDate.getHours() + 1);

      $scope.jobsRunningChart.loading = true;


      $scope.jobsRunningChart.startDate = DateTimeService.formatDate(dayAgo, preferredDateFormat);
      $scope.jobsRunningChart.endDate = DateTimeService.formatDate(now, preferredDateFormat);

      for (var hourIndex = 0; hourIndex < 24; hourIndex++) {
        categories.push(DateTimeService.formatDate(categoryDate, categoriesAxisTimeFormat));
        categoryDate.setHours(categoryDate.getHours() + 1);
      }

      $scope.jobsRunningChart.xAxis.categories = categories;

      var runsParams = ReportsService.extendWithTenantParams({
        endTimeUsecs: DateTimeService.dateToUsecs(now),
        startTimeUsecs: DateTimeService.dateToUsecs(dayAgo),
        numRuns: 1000,
        excludeTasks: true,
      });

      JobRunsService.getJobRuns(runsParams).then(
        function getJobRunsSuccess(jobs) {

        // create an array of sourceIds based on currently selected filters
        var sourceIds = filters.registeredSourceIds.map(function(source) {
          return source.id;
        });

        if (jobs.length) {
          currentHourMinUsecs = DateTimeService.dateToUsecs(dayAgo);
          currentHourMaxUsecs = DateTimeService.dateToUsecs(dayAgo) + usecsPerHour;

          for (var hour = 0; hour < 24; hour++) {
            runsPerHour[hour] = {
              numActiveRuns: 0,
              numSuccessfulRuns: 0,
              numErrorRuns: 0,
              numWarningRuns: 0,
              numCanceledRuns: 0,
              numSlaViolationRuns: 0,
              startUsecs: currentHourMinUsecs,
              endUsecs: currentHourMaxUsecs,
            };

            // increment our max and min values
            currentHourMinUsecs = currentHourMaxUsecs + 1;
            currentHourMaxUsecs = currentHourMaxUsecs + usecsPerHour;
          }

          for (var jobIndex = 0; jobIndex < jobs.length; jobIndex++) {
            job = jobs[jobIndex];
            jobNameLower = job.backupJobRuns.jobDescription.name.toLowerCase();

            // if a job name and/or source filter is specified,
            // don't plot this chart job if its not a match
            // TODO: if API ever supports these filters do this via API call
            if ((!filters.typeAhead.name || ~jobNameLower.indexOf(filters.typeAhead.name.toLowerCase())) && (!sourceIds.length || ~sourceIds.indexOf(job.backupJobRuns.jobDescription.parentSource.id))) {

              for (var runIndex = 0; runIndex < job.backupJobRuns.protectionRuns.length; runIndex++) {
                jobRun = job.backupJobRuns.protectionRuns[runIndex].backupRun;

                if (!jobRun || !jobRun.base) {
                    continue;
                }

                jobStartTime = jobRun.base.startTimeUsecs;
                jobEndTime = jobRun.base.endTimeUsecs;
                jobRun.base.status = JobService.getStatus(jobRun);

                for (var x = 0; x < 24; x++) {
                  hourStart = runsPerHour[x].startUsecs;
                  hourEnd = runsPerHour[x].endUsecs;

                  if (jobStartTime >= hourStart && jobStartTime <= hourEnd) {
                    switch (jobRun.base.status) {
                      case 2.1:
                        runsPerHour[x].numSuccessfulRuns++;
                        break;
                      case 2.2:
                        runsPerHour[x].numErrorRuns++;
                        break;
                      case 2.3:
                        runsPerHour[x].numWarningRuns++;
                        break;
                      case 3:
                        runsPerHour[x].numCanceledRuns++;
                        break;
                      default:
                      runsPerHour[x].numActiveRuns++;
                    }

                    // exit the loop, as the match was found and counted
                    break;
                  }
                }
              }
            }
          }

          for (var y = 0; y < 24; y++) {
            seriesActiveData.push(runsPerHour[y].numActiveRuns);
            seriesSuccessData.push(runsPerHour[y].numSuccessfulRuns);
            seriesWarningData.push(runsPerHour[y].numWarningRuns);
            seriesErrorData.push(runsPerHour[y].numErrorRuns);
            seriesCanceledData.push(runsPerHour[y].numCanceledRuns);
          }

          $scope.jobsRunningChart.series[0].data = seriesActiveData;
          $scope.jobsRunningChart.series[1].data = seriesSuccessData;
          $scope.jobsRunningChart.series[2].data = seriesWarningData;
          $scope.jobsRunningChart.series[3].data = seriesErrorData;
          $scope.jobsRunningChart.series[4].data = seriesCanceledData;
        }
      },
        evalAJAX.errorMessage
      ).finally(
        function getJobRunsFinally() {
          $scope.jobsRunningChart.loading = false;
        }
      );
    }

    activate();
  }
})(angular);
