<div class="app-pillar-container">
  <div class="app-pillar-content-container">
    <div class="app-pillar-content">
      <coh-app-entitlement-banner [app]="app"></coh-app-entitlement-banner>
      <coh-app-paywall-message [app]="app" *ngIf="!showClusterClaimComponent"></coh-app-paywall-message>
      <coh-app-cluster-message [appName]="app.title" *ngIf="showClusterClaimComponent"></coh-app-cluster-message>
    </div>
    <div class="app-pillar-icon" [class]="'app-pillar-icon-' + app.pillar"></div>
  </div>
</div>
