import { Environment } from '../env';

/**
 * Type definition for mapping environments to it's source icons.
 */
export type SourceIconType = { [K in Environment]?: string };

/**
 * Source Environment type to helix icon name mappings.
 */
export const SourceTypeToIconMap: SourceIconType = {
  kO365: 'helix:object-o365',
  kSfdc: 'helix:object-salesforce',
};
