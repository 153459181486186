import { ReportColumnConfigs } from '../common-report-items/custom-reports.service';
import { ReportSection } from '../iris-reporting.model';

/**
 * This is the layout config for the protected/unprotected objects (all objects) report.
 */
export const allObjectsReport: ReportSection[] = [
  {
    title: 'reporting.summary',
    items: [
      {
        id: 'statlistItem',
        componentIds: ['103'],
        componentNames: ['ProtectedUnprotectedObjectsCount'],
        params: {
          items: [
            {
              title: 'reporting.protectedObjects',
              translate: true,
              showChart: true,
              measurement: {
                valueKey: 'countObjectUuid',
                dataType: 'percent',
                filter: {
                  filterKey: 'protectionStatus',
                  value: 'Protected',
                },
                aggregation: 'percent',
              },
            },
            {
              title: 'reporting.totalSources',
              translate: true,
              measurement: {
                valueKey: 'sourceUuid',
                aggregation: 'count',
                dataType: 'number',
              },
            },
            {
              title: 'reporting.totalObjects',
              translate: true,
              measurement: {
                valueKey: 'countObjectUuid',
                dataType: 'number',
              },
            },
            {
              title: 'reporting.protectedObjects',
              translate: true,
              measurement: {
                valueKey: 'countObjectUuid',
                dataType: 'number',
                filter: {
                  filterKey: 'protectionStatus',
                  value: 'Protected',
                },
              },
            },
            {
              title: 'reporting.unprotectedObjects',
              translate: true,
              measurement: {
                valueKey: 'countObjectUuid',
                dataType: 'number',
                filter: {
                  filterKey: 'protectionStatus',
                  value: 'Unprotected',
                },
              },
            },
          ],
        },
      },
    ],
  },
  {
    items: [
      {
        id: 'chartItem',
        componentIds: ['102'],
        componentNames: ['ProtectedUnprotectedObjectsByEnvironment'],
        title: 'reporting.protectionStatusByObjectType',
        params: {
          chartType: 'column',
          measurements: [
            {
              valueKey: 'countObjectUuid',
            },
          ],
          dimensions: [
            {
              dimensionKey: 'environment',
              maxValues: 3,
            },
            {
              dimensionKey: 'protectionStatus',
            },
          ],
        },
      },
      {
        id: 'chartItem',
        componentIds: ['101'],
        componentNames: ['UnprotectedObjectsBySource'],
        title: 'reporting.unprotectedItemsBySource',
        params: {
          chartType: 'pie',
          measurements: [
            {
              valueKey: 'countObjectUuid',
            },
          ],
          dimensions: [
            {
              dimensionKey: 'sourceName',
              maxValues: 3,
            },
          ],
        },
      },
    ],
  },
  {
    title: 'reporting.data',
    isFullData: true,
    items: [
      {
        id: 'tableItem',
        componentIds: ['100'],
        componentNames: ['AllObjects'],
        params: {
          columns: [
            {
              title: 'objectName',
              translate: true,
              key: 'objectName',
              icon: {
                key: 'objectType',
                dataType: 'objectTypeIcon',
                renderParams: {
                  environmentKey: 'environment',
                  protectionStatusKey: 'protectionStatus',
                },
              },
              primary: {
                key: 'objectName',
                dataType: 'text',
              },
              subtitle: {
                key: 'environment',
                dataType: 'environmentName',
              },
            },
            {
              title: 'protectionStatus',
              translate: true,
              key: 'protectionStatus',
              primary: {
                key: 'protectionStatus',
                dataType: 'text',
                renderParams: {
                  translate: true,
                },
              },
            },
            {
              title: 'source',
              translate: true,
              key: 'sourceName',
              primary: {
                key: 'sourceName',
                dataType: 'text',
              },
            },
            {
              title: 'organization',
              enabled: false,
              translate: true,
              key: 'tenantNames',
              primary: {
                key: 'tenantNames',
                dataType: 'textList',
                renderParams: {
                  isArray: true,
                  emptyKey: 'naNotAvailable',
                },
              },
              featureFlag: 'ngReportSPView',
            },
            {
              title: 'system',
              translate: 'true',
              key: 'systems',
              primary: {
                key: 'systems',
                dataType: 'textList',
                renderParams: {
                  isArray: true,
                },
              },
            },
            {
              title: 'logicalData',
              translate: true,
              key: 'logicalSize',
              primary: {
                key: 'logicalSize',
                dataType: 'bytes',
              },
            },
          ],
        },
      },
    ],
  },
];

/**
 * This is metadata needed to determine available chart customizations.
 */
export const allObjectsColumns: ReportColumnConfigs = {
  objectName: {
    aggs: [],
    canGroup: true,
    maxGroupingValues: 5,
    dataType: 'text',
  },
  objectUuid: {
    aggs: ['count'],
    dataType: 'uuid',
  },
  environment: {
    aggs: ['count'],
    canGroup: true,
    maxGroupingValues: 3,
    dataType: 'text',
  },
  logicalSize: {
    aggs: ['sum', 'min', 'max', 'avg'],
    dataType: 'bytes',
  },
  protectionStatus: {
    aggs: [],
    canGroup: true,
    dataType: 'text',
  },
  sourceName: {
    aggs: [],
    canGroup: true,
    maxGroupingValues: 5,
    dataType: 'text',
  },
  sourceUuid: {
    aggs: ['count'],
    dataType: 'uuid',
  },
  systems: {
    aggs: [],
  },
};
