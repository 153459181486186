// COMPONENT: Recover as Mount Point: Options step

;(function(angular, undefined) {
  'use strict';

  var moduleName = 'C.recoverAsMountPoint';

  angular
    .module(moduleName)
    .controller('recoverAsMountPointOptionsController', recoverAsMountPointOptionsControllerFn);

  function recoverAsMountPointOptionsControllerFn($rootScope, $scope, $q, SourceService,
    SearchService, $stateParams, SlideModalService, cMessage, FEATURE_FLAGS) {

    angular.extend($scope, {
      // GENERAL SCOPE VARS
      showHostCredentials: {},
      showObjectCredentials: {},
      entity: $scope.shared.cart[0],

      // TEXT STRINGS
      text: $rootScope.text['recover.mountPoint.options'],

      // ERRORS
      errorText: $rootScope.text.protectionRecoveryMountPointMountPointOptions1ErrorText,

      // SCOPE METHODS
      selectRestorePoint: selectRestorePoint,
      transformViewTagString: transformViewTagString,
      toggleHostCredentials: toggleHostCredentials,
      toggleVolumes: toggleVolumes,
      selectTarget: selectTarget,
      displayAgentOption: displayAgentOption,
      credentialsRequired: credentialsRequired,
    });


    // METHODS
    /**
     * Initialize all the things!
     *
     * @method     recoverAsMountPointOptionsInit
     */
    function recoverAsMountPointOptionsInit() {
      if ($stateParams.entityId && $stateParams.jobId) {
        // This is an abbreviated flow
        setupAbbreviatedFlow();
      } else if (!$scope.shared.cart.length) {
        // Nothing to recover.
        $scope.startFlowOver();
      }
    }

    /**
     * Applies the selected volumes from cMultiselect to the task model
     *
     * @method   toggleVolumes
     * @param    {array}   volumes   The list of selected volumes. Can be empty.
     */
    function toggleVolumes(volumes) {
      $scope.shared.task.mountVolumesParams.volumeNameVec = volumes
        .map(function volumeVecMapper(volume) {
          return volume.value || volume.name;
        });
    }

    /**
     * Open the Browse for Server Modal and select a new Target.
     *
     * @method     selectTarget
     */
    function selectTarget() {
      // Determine entityType (vm or physical)
      var envType = $scope.shared.task.objects[0].entity.type;
      var filters = {
        // Manually excluding standalone ESXi targets until supported
        excludeTypes: [10],
        requireVmTools: false,
        singleSelect: true,
      };
      var hostType;

      if (envType === 6) {
        // Physical Server environment, set host type (windows/linux) filtering
        hostType = $scope.shared.task.objects[0].entity.physicalEntity.hostType;
      }

      // Open modal with type restrictions
      $scope.browseForLeafEntities(envType, hostType, filters)
        .then($scope.updateTaskWithTargetEntity);
    }

    /**
     * Toggles visibility of the credentials fields for each VM object in
     * the cart. If hiding, this clears any entered values.
     *
     * @method     toggleHostCredentials
     * @param      {object}  entity  The VM entity
     */
    function toggleHostCredentials(entity) {
      if ($scope.showCredentials[entity._id]) {
        $scope.showCredentials[entity._id] = false;
        delete $scope.shared.task.restoreAppParams.credentials;
      } else {
        $scope.showCredentials[entity._id] = true;
      }
    }

    /**
     * Transformer for ui-select[tagging] of new View names
     *
     * @method     transformViewTagString
     * @param      {string}  value   The new tag/View name
     * @return     {Object}  New object compatible with the server's
     *                       response
     */
    function transformViewTagString(value) {
      return { name: value };
    }

    /**
     * Function to make some scope changes for Abbreviated flow
     *
     * @method     setupAbbreviatedFlow
     */
    function setupAbbreviatedFlow() {
      var promiseObjects = {};
      var foundMatch;
      var targetEntity;

      $scope.shared.isAbbreviated = true;
      $scope.isLoading = true;

      promiseObjects = {
        entityList: SearchService.serverSearch({
          entityIds: $stateParams.entityId,
          jobIds: $stateParams.jobId,
          registeredSourceIds: $stateParams.hostId,
        }),
        targetEntity: $stateParams.mountTarget ? SourceService.getEntitiesById($stateParams.mountTarget.id) : undefined,
      };

      $q.all(promiseObjects).then(function getData(responses) {
        // Found the IVM entity
        if (responses.entityList && responses.entityList.length) {
          foundMatch = responses.entityList.some(function findMatchingEntity(entity) {
            // Using == here because these $stateParams are strings
            if ($stateParams.entityId == entity.vmDocument.objectId.entity.id &&
              $stateParams.jobId == entity._jobId) {

              // If we've selected a jobInstanceId, match it and assign that
              // snapshot
              if ($stateParams.jobInstanceId) {
                entity.vmDocument.versions.some(run => {
                  if (run.instanceId.jobInstanceId == $stateParams.jobInstanceId) {
                    entity._snapshot = run;
                    return true;
                  }
                });
              }

              // Add it to the cart!
              $scope.addToCart(entity);
              $scope.entity = entity.vmDocument.objectId.entity;
              return true;
            }
          });
        }

        // No matching entity was found, show an error
        if (!foundMatch) {
          cMessage.error({
            textKey: 'recover.mountPoint.options.noEntityFound',
          });
        }

        // The target entity exists locally in this cluster
        if (responses.targetEntity) {
          targetEntity = Array.isArray(responses.targetEntity) && responses.targetEntity[0];
          if (_.isObject(targetEntity)) {
            _.assign($scope.shared.task, {
              // parentId will exist for VM but not physical.
              restoreParentSource: $stateParams.mountTarget.parentId ?
                {id: $stateParams.mountTarget.parentId} : undefined,
              mountVolumesParams: {
                // preserve old volumeNameVec value
                volumeNameVec: _.get($scope,
                  'shared.task.mountVolumesParams.volumeNameVec'),
                targetEntity: $stateParams.mountTarget,
                useExistingAgent: $stateParams.resubmitRecoveryObject.useExistingAgent,
              }
            });

            $scope.shared.ivmEnvParam = $scope.getIvmEnvParams();
            $scope.shared.task.mountVolumesParams[$scope.shared.ivmEnvParam] = {
              bringDisksOnline: $stateParams.resubmitRecoveryObject[$scope.shared.ivmEnvParam].bringDisksOnline,
              targetEntityCredentials: undefined,
            };
          } else {
            cMessage.error({
              textKey: 'recover.mountPoint.options.noTargetEntityFound',
            });
          }
        }
      })
      .finally(function getServerFinally() {
        // Set the selected volumes.
        if ($stateParams.volumeInfoVec) {
          _toggleSelectedOptions($stateParams.volumeInfoVec);
          toggleVolumes($scope.selectedOptions);
        }
        $scope.isLoading = false;
      });
    }

    /**
     * Open the snapshot selector to let the user choose a different restore
     * point.
     *
     * @method     selectRestorePoint
     * @param      {object}  row     The search result entity in question
     */
    function selectRestorePoint(row) {
      var modalOpts = {
        templateUrl: 'app/protection/recovery/common/snapshot-selector/common.snapshot-selector.html',
        controller: 'commonRestoreSnapshotModalController',
        size: 'lg',
        resolve: {
          task: angular.copy($scope.shared.task),
          entity: row
        }
      };

      SlideModalService
        .newModal(modalOpts)
        .then(function snapshotSelectedFn(resp) {

          angular.extend(row, {
            _snapshot: resp.snapshot,
            _archiveTarget: resp.archiveTarget
          });

          $scope.updateTaskWithSnapshot(row._snapshot);
          $scope.updateTaskWithArchiveTarget(row._archiveTarget);
          $scope.getVolumes();
        });
    }

    /**
     * Apply volumeInfoVec to selectedOptions so that volumeInfoVecs will be shown
     * as pills.
     *
     * @method  _toggleSelectedOptions
     * @param   {object} volumeInfoVec
     */
    function _toggleSelectedOptions(volumeInfoVec){
      $scope.selectedOptions = volumeInfoVec.map(
        function mapVolumeInfo(volume) {
          return {name: volume.displayName};
        });
    }

    /**
     * Determine to show agent option or not. Only show agent option if the
     * target server is a VMware.
     *
     * @method     displayAgentOption
     * @returns    {Boolean}  Return true if it's a VM and the feature flag is
     *                        on.
     */
    function displayAgentOption() {
      var osTypeRestriction = FEATURE_FLAGS.persistentAgentForWindowsOnly ?
        $stateParams.osType === 'Windows' : true;

      return FEATURE_FLAGS.enablePersistentAgentOption &&
        _.get($scope.shared.task.mountVolumesParams, 'targetEntity.vmwareEntity') &&
        osTypeRestriction;
    }

    /**
     * Decide if the credentials are required or not. If the user selects to use
     * existing agent, the credentials are NOT required; if agent options are
     * not displayed, follow the old rules.
     *
     * @method    credentialsRequired
     * @returns   {Boolean}   Return true if the credentials are required.
     */
    function credentialsRequired() {
      if ($scope.displayAgentOption()) {
        return !$scope.shared.task.mountVolumesParams.useExistingAgent;
      }
      return ($scope.entity._isVM && $scope.entity._entityKey !== 'hypervEntity');
    }

    // Initialize!
    recoverAsMountPointOptionsInit();
  }

})(angular);
