// Custom Events go here

;(function(angular, undefined) {

  angular.module('C.events', [])
    .directive('cEnter', cEnterDirectiveFn)
    .directive('tagOnBlur', tagOnBlurDirectiveFn);

  /**
   * bind keydown and keypress of [ENTER] key to evaluate the assigned
   * expression
   *
   * @return     {Object}  directive options
   */
  function cEnterDirectiveFn() {

    return {
      link: cEnterFn,
    };

    function cEnterFn(scope, element, attrs) {
      element.bind(
        'keydown keypress',
        function onKeyFn(event) {
          if (event.which === 13) {
            scope.$apply(function scopeApplyFn() {
              scope.$eval(attrs.cEnter);
            });

            event.preventDefault();
          }
        }
      );
    }
  }

  /**
   * Submits uiSelect input tag on blur of the field.
   *
   * @param      {Function}  $timeout  AngularJS's $timeout service
   * @return     {Object}    directive options
   */
  function tagOnBlurDirectiveFn($timeout) {

    return {
      require: 'uiSelect',
      link: tagOnBlurLinkFn,
    };

    function tagOnBlurLinkFn(scope, elm, attrs, ctrl) {
      ctrl.searchInput.on('blur', function onBlur() {
        if (ctrl.items.length || ctrl.tagging.isActivated) {
          $timeout(function tagOnBlurTimeout() {
            ctrl.searchInput.triggerHandler('tagged');
            var newItem = ctrl.search;
            if (ctrl.tagging.fct) {
              newItem = ctrl.tagging.fct(newItem);
            }
            if (newItem) {
              ctrl.select(newItem, true);
            }
          });
        }
      });
    }
  }

})(angular);
