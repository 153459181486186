import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { VolumeInfo } from '@cohesity/api/v2';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';
import { Controls, NgxSubFormComponent, subformComponentProviders, takeUntilDestroyed } from 'ngx-sub-form';
import { finalize } from 'rxjs/operators';
import { RecoveryOsType } from 'src/app/shared/constants';
import { transformToWindowsPath } from '../../model/recovery-file-object';
import { RestorePointSelection } from '../../model/restore-point-selection';
import { VolumeSelectionForm } from '../../model/volume-selection-form';
import { ObjectsService } from '../../services/objects.service';

/**
 * This component provides volume selection options for recovery form
 *
 * @example
 * <coh-volume-selection-form
 *   [readOnly]="readOnly"
 *   [selectedVolumes]="selectedVolumes"
 *   [restorePoint]="restorePoint$ | async"
 *   [formControlName]="formControlNames.volumeOptions">
 * </coh-volume-selection-form>
 */
@Component({
  selector: 'coh-volume-selection-form',
  templateUrl: './volume-selection-form.component.html',
  styleUrls: ['./volume-selection-form.component.scss'],
  providers: [...subformComponentProviders(VolumeSelectionFormComponent)],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VolumeSelectionFormComponent extends NgxSubFormComponent<VolumeSelectionForm> implements OnInit {
  /**
   * If the component should be displayed as read-only.
   */
  @Input() readOnly: boolean;

  /**
   * Initial select all volumes option.
   */
  @Input() selectAllVolumes: boolean;

  /**
   * Initial selected volumes.
   */
  @Input() selectedVolumes: string[];

  /**
   * OS Type.
   */
  @Input() osType: RecoveryOsType;

  /** Indicates whether the data is being loaded. */
  loading = false;

  /**
   * The restore point, i.e., specific snapshot selected by user.
   */
  private _restorePoint: RestorePointSelection;

  /**
   * Sets restore point and get volumes.
   */
  @Input() set restorePoint(restorePoint: RestorePointSelection) {
    if (!restorePoint) {
      return;
    }

    this._restorePoint = restorePoint;
    this.getVolumes(restorePoint);
  }

  /**
   * Gets restore point.
   */
  get restorePoint(): RestorePointSelection {
    return this._restorePoint;
  }

  /**
   * List of volumes based on selected parent object.
   */
  volumes: VolumeInfo[];

  /**
   * Stores snapshotId used to retrieve volumes and avoid making the same API call again.
   * Sometimes it messed up the pre-selected value when the 2nd unnecessary call is made.
   */
  prevSnapshotId: string;

  /**
   * Constructor.
   */
  constructor(
    private cdr: ChangeDetectorRef,
    private objectsService: ObjectsService,
    private irisContextService: IrisContextService
  ) {
    super();
  }

  /**
   * Get Volumes.
   *
   * @param  snapshotId  object snapshot ID
   */
  getVolumes(restorePoint: RestorePointSelection) {
    this.loading = true;

    this.objectsService
      .getVolumes({
        snapshotId: restorePoint.restorePointId,
        includeSupportedOnly: false,
        useCachedData: flagEnabled(this.irisContextService.irisContext, 'useMagnetoCachedData'),
        ...(restorePoint.isPointInTime ? { pointInTimeUsecs: restorePoint.timestampUsecs } : null),
      })
      .pipe(
        takeUntilDestroyed(this),
        finalize(() => {
          if (this.volumes && this.volumes.length) {

            // pre-populate selected volumes (for resubmit)
            if (this.selectedVolumes && this.selectedVolumes.length) {
              const selectedVolumes = this.volumes.filter(volume => this.selectedVolumes.includes(volume.name));

              this.formGroupControls.volumes.setValue(selectedVolumes);
              this.selectedVolumes = undefined;
            }

            // If select all volumes option is not selected, user need to select individual volumes
            if (!this.selectAllVolumes) {
              this.formGroupControls.volumes.setValidators(Validators.required);
            }
            this.formGroupControls.selectAllVolumes.setValidators(Validators.required);
          } else {

            // If volumes is not available, user must select all volumes option and volume selection is not required.
            this.formGroupControls.selectAllVolumes.setValidators([Validators.required, Validators.requiredTrue]);
            this.formGroupControls.volumes.clearValidators();
          }

          this.formGroupControls.volumes.updateValueAndValidity();
          this.loading = false;
          this.cdr.detectChanges();
        })
      )
      .subscribe((volumes: VolumeInfo[]) => {
        this.volumes = volumes;
      });
  }

  /**
   * Get selected volumes for display.
   *
   * @returns  Selected volumes string
   */
  getSelectedVolumes(): string {
    const selectedVolumes = this.formGroupControls.volumes.value.map(
      selectedVolume => (selectedVolume?.name));
    if (selectedVolumes?.length) {
      if (this.osType === RecoveryOsType.kWindows) {
        return selectedVolumes.map(transformToWindowsPath).join(', ');
      }
      return selectedVolumes.join(', ');
    }
    return '';
  }

  /**
   * Gets default values for form.
   */
  getDefaultValues(): Partial<VolumeSelectionForm> {
    return {
      selectAllVolumes: false,
      volumes: [],
    };
  }

  /**
   * Gets the form controls for configuring azure vms.
   *
   * @returns  The volume selection options controls.
   */
  protected getFormControls(): Controls<VolumeSelectionForm> {
    const { selectAllVolumes, volumes } = this.getDefaultValues();

    return {
      selectAllVolumes: new UntypedFormControl(selectAllVolumes, Validators.required),
      volumes: new UntypedFormControl(volumes, null),
    };
  }

  /**
   * Initialization.
   */
  ngOnInit() {
    this.formGroupControls.selectAllVolumes.valueChanges.pipe(takeUntilDestroyed(this)).subscribe(selectAllVolumes => {
      if (selectAllVolumes) {
        this.formGroupControls.volumes.clearValidators();
      } else {
        this.formGroupControls.volumes.setValidators(Validators.required);
      }
      this.formGroupControls.volumes.updateValueAndValidity();
    });
  }
}
