import { ReportFilterType } from './filters-types';

/**
 * This maps the filter types to labels. This is a separate file to avoid circular dependency.
 * It is used by both reporting page and pdf report.
 */
export const filterLabelMap: Record<ReportFilterType, string> = {
  activityType: 'activityType',
  backupStatus: 'backupStatus',
  backupStatusLabel: 'backupStatus',
  connectionStatus: 'connectionStatus',
  date: 'dateRange',
  envGroup: 'objectType',
  environment: 'objectType',
  groupEnvGroup: 'groupType',
  groupEnvironment: 'groupType',
  groupId: 'protectionGroup',
  lastRunStatus: 'runStatus',
  lastRunStatusLabel: 'lastRunStatus',
  m365: 'objectType',
  m365App: 'apps',
  managedBy: 'reporting.managedBy',
  messageCode: 'code',
  objectSnapshotStatus: 'reporting.snapshotStatus',
  objectStatus: 'reporting.objectStatus',
  objectUuid: 'object',
  objectType: 'objectType',
  policyId: 'policy',
  protectionStatus: 'protectionStatus',
  runStatus: 'runStatus',
  slaStatus: 'slaStatus',
  snapshotStatus: 'reporting.snapshotStatus',
  snapshotType: 'reporting.snapshotType',
  sourceEnvGroup: 'sourceType',
  sourceM365: 'source',
  sourceUuid: 'source',
  sourceUuids: 'source',
  systemId: 'system',
  targetId: 'targetName',
  targetType: 'targetType',
  taskStatus: 'status',
  tenantsId: 'reporting.organizations',
  tenantId: 'reporting.organizations',
};
