/**
 * Returns percent change of current value from previous one.
 */
// TODO(alex.logashov): Move to Helix?
export const calcChange = (currVal: number, prevVal: number): number => {
  if (prevVal === 0 || currVal === prevVal) {
    return 0;
  }

  return Math.round(((currVal - prevVal) / prevVal) * 100);
};

/**
 * Converts degrees to radians.
 */
export const convertRadToDeg = (input: number): number => input * (180 / Math.PI);

/**
 * Converts radians to degrees.
 */
export const convertDegToRad = (input: number): number => input * (Math.PI / 180);
