import { TemplateRef, Directive } from '@angular/core';
import { DataTreeNode } from './shared/data-tree.model';
import { DataTreeSelectionModel } from './shared/data-tree-selection-model';
import { DataTreeControl } from './shared/data-tree-control';

/**
 * This represents the selection status for a given node and will be passed as the context to the template
 * in the detail directive.
 */
export interface DataTreeNodeContext<T extends DataTreeNode<any>> {
  /**
   * A reference to the current node.
   */
  readonly node: T;

  /**
   * The node selection model
   */
  readonly selection: DataTreeSelectionModel<T>;

  /**
   * The data tree control.
   */
  readonly treeControl: DataTreeControl<T>;
  /**
   * True if the node is currently selected.
   */
  readonly selected: boolean;

  /**
   * True if the node can not be selected. Note: check this last, other values take precedence over this one.
   */
  readonly canSelect: boolean;

  /**
   * True if the node can not be selected. Note: check this last, other values take precedence over this one.
   */
  readonly canAutoSelect: boolean;

  /**
   * True if the node can not be selected. Note: check this last, other values take precedence over this one.
   */
  readonly canExclude: boolean;

  /**
   * True if the node has been explicitly auto selected.
   */
  readonly autoSelected: boolean;

  /**
   * True if the node has been explictly excluded
   */
  readonly excluded: boolean;

  /**
   * True if a node's ancestor has been auto selected.
   */
  readonly ancestorAutoSelected: boolean;

  /**
   * True if a node's ancestor has been excluded.
   */
  readonly ancestorExcluded: boolean;
}

/**
 * This is a structural directive used to specify the content for a data tree node. The $implicit
 * context will include the value of the current node.
 *
 * @example
 * <cog-data-tree>
 *  <div *cogDataTreeDetail="let ctx" class="detail">{{ctx.node.name}</div>
 * </cog-data-tree>
 */
@Directive({
  selector: '[cogDataTreeDetail]',
})
export class DataTreeDetailDirective {
  constructor(public template: TemplateRef<DataTreeNodeContext<any>>) {
  }
}
