import { Component, Input, ContentChild, ViewEncapsulation, Renderer2, Inject,
  OnDestroy, OnInit, EventEmitter, Output } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { DOCUMENT } from '@angular/common';

/**
 * Transcludes actions content in page dialog.
 */
@Component({
  selector: 'coh-page-dialog-actions',
  template: '<ng-content></ng-content>',
})
export class PageDialogActionsComponent {
}

/**
 * Transcludes nav content in page dialog.
 */
@Component({
  selector: 'coh-page-dialog-nav',
  template: '<ng-content></ng-content>',
})
export class PageDialogNavComponent {
}

/**
 * Transcludes body content in page dialog.
 */
@Component({
  selector: 'coh-page-dialog-content',
  template: '<ng-content></ng-content>',
})
export class PageDialogContentComponent {
}

/**
 * PageDialog component.
 * Use it in your dialog component to show
 * standard fullscreen page-dialog. Custom action buttons can be
 * specified using coh-page-dialog-actions and main content should
 * be placed in coh-page-dialog-content. In addition to this,
 * material dialog must have at least following config.
 * {
 *   panelClass: 'page-dialog-panel'
 * }
 * TODO(mithun): Create a service which takes care of config.
 */
@Component({
  selector: 'coh-page-dialog',
  styleUrls: ['./page-dialog.component.scss'],
  templateUrl: './page-dialog.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class PageDialogComponent implements OnInit, OnDestroy {
  private document?: Document;

  constructor(
    private renderer: Renderer2,
    private dialogRef: MatDialogRef<any>,
    @Inject(DOCUMENT) document: any
  ) {
    this.document = document as Document;
  }


  /*
   *  Disable dialog close state
   */
  @Input() disableDialogClose = false;

  /*
   * Title of the page dialog.
   */
  @Input() pageDialogTitle: string;

  /*
   * Event handler for cancel.
   */
  @Output() cancelClick = new EventEmitter<any>();

  /*
   * Page dialog actions. If provided, displays actions on top right.
   */
  @ContentChild(PageDialogActionsComponent) pageDialogActionsComponent: PageDialogActionsComponent;

  /*
   * Page dialog navigation. If provided, displays a second toolbar row with nav links.
   */
  @ContentChild(PageDialogNavComponent) pageDialogNavComponent: PageDialogNavComponent;

  /**
   * Initialization function.
   */
  ngOnInit() {
    this.renderer.addClass(this.document.body, 'has-page-dialog');
  }

  /**
   * Destroy function.
   */
  ngOnDestroy() {
    this.renderer.removeClass(this.document.body, 'has-page-dialog');
  }

  /*
   * Handles navigate back action.
   */
  cancel() {
    if (!this.disableDialogClose) {
      this.dialogRef.close();
      return;
    }
    this.cancelClick.emit();
  }
}
