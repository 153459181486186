/**
 * One bit as a fraction of one byte.
 */
const BIT = 1 / 8;

/**
 * One byte.
 */
const BYTE = 1;

/**
 * Scaling options for bytes.
 */
export type ByteScale = 'base2' | 'base10';

/**
 * The bits and Bytes file size scaled values from 1 bytes to 1 pib.
 */
export enum FileSize {
  bit = BIT,
  byte = BYTE,
  kilobit = 1024 * bit,
  kilobyte = 1024 * byte,
  megabit = 1024 * kilobit,
  megabyte = 1024 * kilobyte,
  gigabit = 1024 * megabit,
  gigabyte = 1024 * megabyte,
  terabit = 1024 * gigabit,
  terabyte = 1024 * gigabyte,
  petabit = 1024 * terabit,
  petabyte = 1024 * terabyte,
}

/**
 * The bits and Bytes file size scaled values from 1 bytes to 1 pb.
 */
export enum FileSizeBase10 {
  bit = BIT,
  byte = BYTE,
  kilobit = 1000 * bit,
  kilobyte = 1000 * byte,
  megabit = 1000 * kilobit,
  megabyte = 1000 * kilobyte,
  gigabit = 1000 * megabit,
  gigabyte = 1000 * megabyte,
  terabit = 1000 * gigabit,
  terabyte = 1000 * gigabyte,
  petabit = 1000 * terabit,
  petabyte = 1000 * terabyte,
}

/**
 * Map to look up units from size numbers.
 */
export const FileSizeBytesUnits = {
  Bytes: FileSize.byte,
  KiB: FileSize.kilobyte,
  MiB: FileSize.megabyte,
  GiB: FileSize.gigabyte,
  TiB: FileSize.terabyte,
  PiB: FileSize.petabyte,
  KB: FileSizeBase10.kilobyte,
  MB: FileSizeBase10.megabyte,
  GB: FileSizeBase10.gigabyte,
  TB: FileSizeBase10.terabyte,
  PB: FileSizeBase10.petabyte,
};
