/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityApiConfiguration as __Configuration } from '../cohesity-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { McmActiveAlertsStats } from '../models/mcm-active-alerts-stats';
import { FileDistributionStatsList } from '../models/file-distribution-stats-list';
import { McmPerformanceStats } from '../models/mcm-performance-stats';
import { ProtectionRunsStats } from '../models/protection-runs-stats';
import { LastProtectionRunStats } from '../models/last-protection-run-stats';
import { ProtectedObjectsSummary } from '../models/protected-objects-summary';
import { RestoreStats } from '../models/restore-stats';
import { McmStorageStats } from '../models/mcm-storage-stats';
import { VaultStats } from '../models/vault-stats';
import { VaultProviderStatsList } from '../models/vault-provider-stats-list';
import { VaultRunStatsSummary } from '../models/vault-run-stats-summary';
import { ViewStatsSnapshot } from '../models/view-stats-snapshot';
import { ViewProtocolStatsList } from '../models/view-protocol-stats-list';
import { ViewTimeSeriesStatsList } from '../models/view-time-series-stats-list';
@Injectable({
  providedIn: 'root',
})
class HeliosStatsService extends __BaseService {
  static readonly McmActiveAlertsStatsPath = '/public/mcm/stats/alerts';
  static readonly McmGetFileDistributionStatsPath = '/public/mcm/stats/files';
  static readonly McmPerformanceStatsPath = '/public/mcm/stats/performance';
  static readonly McmProtectionRunsStatsPath = '/public/mcm/stats/protectionRuns';
  static readonly McmGetLastProtectionRunStatsPath = '/public/mcm/stats/protectionRuns/lastRun';
  static readonly McmProtectionSummaryPath = '/public/mcm/stats/protectionSummary';
  static readonly McmRestoreStatsPath = '/public/mcm/stats/restores';
  static readonly McmStorageStatsPath = '/public/mcm/stats/storage';
  static readonly McmGetVaultStatsPath = '/public/mcm/stats/vaults';
  static readonly McmGetVaultProviderStatsPath = '/public/mcm/stats/vaults/providers';
  static readonly McmGetVaultRunStatsPath = '/public/mcm/stats/vaults/runs';
  static readonly McmGetViewStatsPath = '/public/mcm/stats/views';
  static readonly McmGetViewProtocolStatsPath = '/public/mcm/stats/views/protocols';
  static readonly McmGetViewTimeSeriesStatsPath = '/public/mcm/stats/views/timeSeries';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Compute the stats on active alerts.
   *
   * Compute the stats on active alerts.
   * @param params The `HeliosStatsService.McmActiveAlertsStatsParams` containing the following parameters:
   *
   * - `startTimeUsecs`: Specifies the start time Unix time epoch in microseconds from which the active alerts stats are computed.
   *
   * - `endTimeUsecs`: Specifies the end time Unix time epoch in microseconds to which the active alerts stats are computed.
   *
   * - `regionIds`: Filter by a list of region ids.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `excludeStatsByCluster`: Specifies if stats of active alerts per cluster needs to be excluded. If set to false (default value), stats of active alerts per cluster is included in the response. If set to true, only aggregated stats summary will be present in the response.
   *
   * - `clusterIdentifiers`: Specifies the list of cluster identifiers. Format is clusterId:clusterIncarnationId.
   *
   * - `alertSources`: Specifies a list of alert origination source. If not specified, all alerts from all the sources are considered in the response.
   *
   * @return Success
   */
  McmActiveAlertsStatsResponse(params: HeliosStatsService.McmActiveAlertsStatsParams): __Observable<__StrictHttpResponse<McmActiveAlertsStats>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startTimeUsecs != null) __params = __params.set('startTimeUsecs', params.startTimeUsecs.toString());
    if (params.endTimeUsecs != null) __params = __params.set('endTimeUsecs', params.endTimeUsecs.toString());
    (params.regionIds || []).forEach(val => {if (val != null) __params = __params.append('regionIds', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.excludeStatsByCluster != null) __params = __params.set('excludeStatsByCluster', params.excludeStatsByCluster.toString());
    (params.clusterIdentifiers || []).forEach(val => {if (val != null) __params = __params.append('clusterIdentifiers', val.toString())});
    (params.alertSources || []).forEach(val => {if (val != null) __params = __params.append('alertSources', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/mcm/stats/alerts`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<McmActiveAlertsStats>;
      })
    );
  }
  /**
   * Compute the stats on active alerts.
   *
   * Compute the stats on active alerts.
   * @param params The `HeliosStatsService.McmActiveAlertsStatsParams` containing the following parameters:
   *
   * - `startTimeUsecs`: Specifies the start time Unix time epoch in microseconds from which the active alerts stats are computed.
   *
   * - `endTimeUsecs`: Specifies the end time Unix time epoch in microseconds to which the active alerts stats are computed.
   *
   * - `regionIds`: Filter by a list of region ids.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `excludeStatsByCluster`: Specifies if stats of active alerts per cluster needs to be excluded. If set to false (default value), stats of active alerts per cluster is included in the response. If set to true, only aggregated stats summary will be present in the response.
   *
   * - `clusterIdentifiers`: Specifies the list of cluster identifiers. Format is clusterId:clusterIncarnationId.
   *
   * - `alertSources`: Specifies a list of alert origination source. If not specified, all alerts from all the sources are considered in the response.
   *
   * @return Success
   */
  McmActiveAlertsStats(params: HeliosStatsService.McmActiveAlertsStatsParams): __Observable<McmActiveAlertsStats> {
    return this.McmActiveAlertsStatsResponse(params).pipe(
      __map(_r => _r.body as McmActiveAlertsStats)
    );
  }

  /**
   * Compute the file distribution statistics on a given entity like cluster or storage domain.
   *
   * Compute the file distribution statistics on a given entity like cluster or storage domain.
   * @param params The `HeliosStatsService.McmGetFileDistributionStatsParams` containing the following parameters:
   *
   * - `entityType`: Specifies the entity type on which file distribution stats are computed.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `clusterIdentifiers`: Specifies the list of cluster identifiers. Format is clusterId:clusterIncarnationId.
   *
   * @return Success
   */
  McmGetFileDistributionStatsResponse(params: HeliosStatsService.McmGetFileDistributionStatsParams): __Observable<__StrictHttpResponse<FileDistributionStatsList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.entityType != null) __params = __params.set('entityType', params.entityType.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    (params.clusterIdentifiers || []).forEach(val => {if (val != null) __params = __params.append('clusterIdentifiers', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/mcm/stats/files`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FileDistributionStatsList>;
      })
    );
  }
  /**
   * Compute the file distribution statistics on a given entity like cluster or storage domain.
   *
   * Compute the file distribution statistics on a given entity like cluster or storage domain.
   * @param params The `HeliosStatsService.McmGetFileDistributionStatsParams` containing the following parameters:
   *
   * - `entityType`: Specifies the entity type on which file distribution stats are computed.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `clusterIdentifiers`: Specifies the list of cluster identifiers. Format is clusterId:clusterIncarnationId.
   *
   * @return Success
   */
  McmGetFileDistributionStats(params: HeliosStatsService.McmGetFileDistributionStatsParams): __Observable<FileDistributionStatsList> {
    return this.McmGetFileDistributionStatsResponse(params).pipe(
      __map(_r => _r.body as FileDistributionStatsList)
    );
  }

  /**
   * Compute the stats on performance for the specified Clusters.
   *
   * Compute the stats on performance.
   * @param regionId This field represents the region id.
   * @param clusterIdentifiers Specifies the list of cluster identifiers. Format is clusterId:clusterIncarnationId.
   * @return Success
   */
  McmPerformanceStatsResponse(regionId?: string,
    clusterIdentifiers?: Array<string>): __Observable<__StrictHttpResponse<McmPerformanceStats>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    (clusterIdentifiers || []).forEach(val => {if (val != null) __params = __params.append('clusterIdentifiers', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/mcm/stats/performance`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<McmPerformanceStats>;
      })
    );
  }
  /**
   * Compute the stats on performance for the specified Clusters.
   *
   * Compute the stats on performance.
   * @param regionId This field represents the region id.
   * @param clusterIdentifiers Specifies the list of cluster identifiers. Format is clusterId:clusterIncarnationId.
   * @return Success
   */
  McmPerformanceStats(regionId?: string,
    clusterIdentifiers?: Array<string>): __Observable<McmPerformanceStats> {
    return this.McmPerformanceStatsResponse(regionId, clusterIdentifiers).pipe(
      __map(_r => _r.body as McmPerformanceStats)
    );
  }

  /**
   * Compute the stats on Protection Runs.
   *
   * Compute the stats on Protection Runs.
   * @param params The `HeliosStatsService.McmProtectionRunsStatsParams` containing the following parameters:
   *
   * - `status`: Specifies the Protection Run status for which stats has to be computed.
   *
   * - `startTimeUsecs`: Specifies the start time in Unix timestamp epoch in microseconds from which Protection Runs stats are computed.
   *
   * - `endTimeUsecs`: Specifies the end time in Unix timestamp epoch in microseconds to which Protection Runs stats are computed.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `clusterIdentifiers`: Specifies the list of cluster identifiers. Format is clusterId:clusterIncarnationId.
   *
   * @return Success
   */
  McmProtectionRunsStatsResponse(params: HeliosStatsService.McmProtectionRunsStatsParams): __Observable<__StrictHttpResponse<ProtectionRunsStats>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.status != null) __params = __params.set('status', params.status.toString());
    if (params.startTimeUsecs != null) __params = __params.set('startTimeUsecs', params.startTimeUsecs.toString());
    if (params.endTimeUsecs != null) __params = __params.set('endTimeUsecs', params.endTimeUsecs.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    (params.clusterIdentifiers || []).forEach(val => {if (val != null) __params = __params.append('clusterIdentifiers', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/mcm/stats/protectionRuns`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ProtectionRunsStats>;
      })
    );
  }
  /**
   * Compute the stats on Protection Runs.
   *
   * Compute the stats on Protection Runs.
   * @param params The `HeliosStatsService.McmProtectionRunsStatsParams` containing the following parameters:
   *
   * - `status`: Specifies the Protection Run status for which stats has to be computed.
   *
   * - `startTimeUsecs`: Specifies the start time in Unix timestamp epoch in microseconds from which Protection Runs stats are computed.
   *
   * - `endTimeUsecs`: Specifies the end time in Unix timestamp epoch in microseconds to which Protection Runs stats are computed.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `clusterIdentifiers`: Specifies the list of cluster identifiers. Format is clusterId:clusterIncarnationId.
   *
   * @return Success
   */
  McmProtectionRunsStats(params: HeliosStatsService.McmProtectionRunsStatsParams): __Observable<ProtectionRunsStats> {
    return this.McmProtectionRunsStatsResponse(params).pipe(
      __map(_r => _r.body as ProtectionRunsStats)
    );
  }

  /**
   * Compute stats on last Protection Run for every job.
   *
   * Compute stats on last Protection Run for every job.
   * @param params The `HeliosStatsService.McmGetLastProtectionRunStatsParams` containing the following parameters:
   *
   * - `toTimeUsecs`: Specifies the time in Unix timestamp epoch in microsecond which filters all the runs started before this value. If not specified, this will be set to 24 hours ahead of fromTimeUsecs parameter.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `fromTimeUsecs`: Specifies the time in Unix timestamp epoch in microsecond which filters all the runs started after this value. If not specified, this will be set to 24 hours prior to toTimeUsecs parameter.
   *
   * - `clusterIdentifiers`: Specifies the list of cluster identifiers. Format is clusterId:clusterIncarnationId.
   *
   * @return Success
   */
  McmGetLastProtectionRunStatsResponse(params: HeliosStatsService.McmGetLastProtectionRunStatsParams): __Observable<__StrictHttpResponse<LastProtectionRunStats>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.toTimeUsecs != null) __params = __params.set('toTimeUsecs', params.toTimeUsecs.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.fromTimeUsecs != null) __params = __params.set('fromTimeUsecs', params.fromTimeUsecs.toString());
    (params.clusterIdentifiers || []).forEach(val => {if (val != null) __params = __params.append('clusterIdentifiers', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/mcm/stats/protectionRuns/lastRun`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LastProtectionRunStats>;
      })
    );
  }
  /**
   * Compute stats on last Protection Run for every job.
   *
   * Compute stats on last Protection Run for every job.
   * @param params The `HeliosStatsService.McmGetLastProtectionRunStatsParams` containing the following parameters:
   *
   * - `toTimeUsecs`: Specifies the time in Unix timestamp epoch in microsecond which filters all the runs started before this value. If not specified, this will be set to 24 hours ahead of fromTimeUsecs parameter.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `fromTimeUsecs`: Specifies the time in Unix timestamp epoch in microsecond which filters all the runs started after this value. If not specified, this will be set to 24 hours prior to toTimeUsecs parameter.
   *
   * - `clusterIdentifiers`: Specifies the list of cluster identifiers. Format is clusterId:clusterIncarnationId.
   *
   * @return Success
   */
  McmGetLastProtectionRunStats(params: HeliosStatsService.McmGetLastProtectionRunStatsParams): __Observable<LastProtectionRunStats> {
    return this.McmGetLastProtectionRunStatsResponse(params).pipe(
      __map(_r => _r.body as LastProtectionRunStats)
    );
  }

  /**
   * Compute the Protection Summary of clusters.
   *
   * Compute the Protection Summary of clusters.
   * @param params The `HeliosStatsService.McmProtectionSummaryParams` containing the following parameters:
   *
   * - `regionIds`: Specifies the list of region ids. Only applicable in case of DMaaS.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `excludeTypes`: Specifies the environment types to exclude.
   *
   * - `clusterIdentifiers`: Specifies the list of cluster identifiers. Format is clusterId:clusterIncarnationId.
   *
   * @return Success
   */
  McmProtectionSummaryResponse(params: HeliosStatsService.McmProtectionSummaryParams): __Observable<__StrictHttpResponse<ProtectedObjectsSummary>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.regionIds || []).forEach(val => {if (val != null) __params = __params.append('regionIds', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    (params.excludeTypes || []).forEach(val => {if (val != null) __params = __params.append('excludeTypes', val.toString())});
    (params.clusterIdentifiers || []).forEach(val => {if (val != null) __params = __params.append('clusterIdentifiers', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/mcm/stats/protectionSummary`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ProtectedObjectsSummary>;
      })
    );
  }
  /**
   * Compute the Protection Summary of clusters.
   *
   * Compute the Protection Summary of clusters.
   * @param params The `HeliosStatsService.McmProtectionSummaryParams` containing the following parameters:
   *
   * - `regionIds`: Specifies the list of region ids. Only applicable in case of DMaaS.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `excludeTypes`: Specifies the environment types to exclude.
   *
   * - `clusterIdentifiers`: Specifies the list of cluster identifiers. Format is clusterId:clusterIncarnationId.
   *
   * @return Success
   */
  McmProtectionSummary(params: HeliosStatsService.McmProtectionSummaryParams): __Observable<ProtectedObjectsSummary> {
    return this.McmProtectionSummaryResponse(params).pipe(
      __map(_r => _r.body as ProtectedObjectsSummary)
    );
  }

  /**
   * Compute the statistics on the Restore tasks on the cluster.
   *
   * Compute the statistics on the Restore tasks on the cluster based on the provided time interval.
   * @param params The `HeliosStatsService.McmRestoreStatsParams` containing the following parameters:
   *
   * - `startTimeUsecs`: Specifies the start time Unix time epoch in microseconds from which the restore stats are computed.
   *
   * - `endTimeUsecs`: Specifies the end time Unix time epoch in microseconds to which the restore stats are computed.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `clusterIdentifiers`: Specifies the list of cluster identifiers. Format is clusterId:clusterIncarnationId.
   *
   * @return Success
   */
  McmRestoreStatsResponse(params: HeliosStatsService.McmRestoreStatsParams): __Observable<__StrictHttpResponse<RestoreStats>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startTimeUsecs != null) __params = __params.set('startTimeUsecs', params.startTimeUsecs.toString());
    if (params.endTimeUsecs != null) __params = __params.set('endTimeUsecs', params.endTimeUsecs.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    (params.clusterIdentifiers || []).forEach(val => {if (val != null) __params = __params.append('clusterIdentifiers', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/mcm/stats/restores`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RestoreStats>;
      })
    );
  }
  /**
   * Compute the statistics on the Restore tasks on the cluster.
   *
   * Compute the statistics on the Restore tasks on the cluster based on the provided time interval.
   * @param params The `HeliosStatsService.McmRestoreStatsParams` containing the following parameters:
   *
   * - `startTimeUsecs`: Specifies the start time Unix time epoch in microseconds from which the restore stats are computed.
   *
   * - `endTimeUsecs`: Specifies the end time Unix time epoch in microseconds to which the restore stats are computed.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `clusterIdentifiers`: Specifies the list of cluster identifiers. Format is clusterId:clusterIncarnationId.
   *
   * @return Success
   */
  McmRestoreStats(params: HeliosStatsService.McmRestoreStatsParams): __Observable<RestoreStats> {
    return this.McmRestoreStatsResponse(params).pipe(
      __map(_r => _r.body as RestoreStats)
    );
  }

  /**
   * Compute Storage stats.
   *
   * Compute Storage stats.
   * @param regionId This field represents the region id.
   * @param clusterIdentifiers Specifies the list of cluster identifiers. Format is clusterId:clusterIncarnationId.
   * @return Success
   */
  McmStorageStatsResponse(regionId?: string,
    clusterIdentifiers?: Array<string>): __Observable<__StrictHttpResponse<McmStorageStats>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    (clusterIdentifiers || []).forEach(val => {if (val != null) __params = __params.append('clusterIdentifiers', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/mcm/stats/storage`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<McmStorageStats>;
      })
    );
  }
  /**
   * Compute Storage stats.
   *
   * Compute Storage stats.
   * @param regionId This field represents the region id.
   * @param clusterIdentifiers Specifies the list of cluster identifiers. Format is clusterId:clusterIncarnationId.
   * @return Success
   */
  McmStorageStats(regionId?: string,
    clusterIdentifiers?: Array<string>): __Observable<McmStorageStats> {
    return this.McmStorageStatsResponse(regionId, clusterIdentifiers).pipe(
      __map(_r => _r.body as McmStorageStats)
    );
  }

  /**
   * Compute the usage statistics on vaults.
   *
   * Compute the usage statistics on vaults.
   * @param regionId This field represents the region id.
   * @param clusterIdentifiers Specifies the list of cluster identifiers. Format is clusterId:clusterIncarnationId.
   * @return Success
   */
  McmGetVaultStatsResponse(regionId?: string,
    clusterIdentifiers?: Array<string>): __Observable<__StrictHttpResponse<VaultStats>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    (clusterIdentifiers || []).forEach(val => {if (val != null) __params = __params.append('clusterIdentifiers', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/mcm/stats/vaults`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<VaultStats>;
      })
    );
  }
  /**
   * Compute the usage statistics on vaults.
   *
   * Compute the usage statistics on vaults.
   * @param regionId This field represents the region id.
   * @param clusterIdentifiers Specifies the list of cluster identifiers. Format is clusterId:clusterIncarnationId.
   * @return Success
   */
  McmGetVaultStats(regionId?: string,
    clusterIdentifiers?: Array<string>): __Observable<VaultStats> {
    return this.McmGetVaultStatsResponse(regionId, clusterIdentifiers).pipe(
      __map(_r => _r.body as VaultStats)
    );
  }

  /**
   * Compute the size and count of entities on vaults.
   *
   * Compute the size and count of entities on vaults.
   * @param params The `HeliosStatsService.McmGetVaultProviderStatsParams` containing the following parameters:
   *
   * - `runType`: Specifies the type of the runs.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `clusterIdentifiers`: Specifies the list of cluster identifiers. Format is clusterId:clusterIncarnationId.
   *
   * @return Success
   */
  McmGetVaultProviderStatsResponse(params: HeliosStatsService.McmGetVaultProviderStatsParams): __Observable<__StrictHttpResponse<VaultProviderStatsList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.runType != null) __params = __params.set('runType', params.runType.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    (params.clusterIdentifiers || []).forEach(val => {if (val != null) __params = __params.append('clusterIdentifiers', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/mcm/stats/vaults/providers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<VaultProviderStatsList>;
      })
    );
  }
  /**
   * Compute the size and count of entities on vaults.
   *
   * Compute the size and count of entities on vaults.
   * @param params The `HeliosStatsService.McmGetVaultProviderStatsParams` containing the following parameters:
   *
   * - `runType`: Specifies the type of the runs.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `clusterIdentifiers`: Specifies the list of cluster identifiers. Format is clusterId:clusterIncarnationId.
   *
   * @return Success
   */
  McmGetVaultProviderStats(params: HeliosStatsService.McmGetVaultProviderStatsParams): __Observable<VaultProviderStatsList> {
    return this.McmGetVaultProviderStatsResponse(params).pipe(
      __map(_r => _r.body as VaultProviderStatsList)
    );
  }

  /**
   * Compute the statistics on protection runs to or from a vault.
   *
   * Compute the statistics on protection runs to or from a vault and return a trend line of the result.
   * @param params The `HeliosStatsService.McmGetVaultRunStatsParams` containing the following parameters:
   *
   * - `startTimeUsecs`: Specifies the start time Unix time epoch in microseconds from which the vault run stats are computed.
   *
   * - `runType`: Specifies the type of the run.
   *
   * - `interval`: Specifies the interval by which runs will be grouped together in the returned trend line.
   *
   * - `endTimeUsecs`: Specifies the end time Unix time epoch in microseconds to which the vault run stats are computed.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `clusterIdentifiers`: Specifies the list of cluster identifiers. Format is clusterId:clusterIncarnationId.
   *
   * @return Success
   */
  McmGetVaultRunStatsResponse(params: HeliosStatsService.McmGetVaultRunStatsParams): __Observable<__StrictHttpResponse<VaultRunStatsSummary>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startTimeUsecs != null) __params = __params.set('startTimeUsecs', params.startTimeUsecs.toString());
    if (params.runType != null) __params = __params.set('runType', params.runType.toString());
    if (params.interval != null) __params = __params.set('interval', params.interval.toString());
    if (params.endTimeUsecs != null) __params = __params.set('endTimeUsecs', params.endTimeUsecs.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    (params.clusterIdentifiers || []).forEach(val => {if (val != null) __params = __params.append('clusterIdentifiers', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/mcm/stats/vaults/runs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<VaultRunStatsSummary>;
      })
    );
  }
  /**
   * Compute the statistics on protection runs to or from a vault.
   *
   * Compute the statistics on protection runs to or from a vault and return a trend line of the result.
   * @param params The `HeliosStatsService.McmGetVaultRunStatsParams` containing the following parameters:
   *
   * - `startTimeUsecs`: Specifies the start time Unix time epoch in microseconds from which the vault run stats are computed.
   *
   * - `runType`: Specifies the type of the run.
   *
   * - `interval`: Specifies the interval by which runs will be grouped together in the returned trend line.
   *
   * - `endTimeUsecs`: Specifies the end time Unix time epoch in microseconds to which the vault run stats are computed.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `clusterIdentifiers`: Specifies the list of cluster identifiers. Format is clusterId:clusterIncarnationId.
   *
   * @return Success
   */
  McmGetVaultRunStats(params: HeliosStatsService.McmGetVaultRunStatsParams): __Observable<VaultRunStatsSummary> {
    return this.McmGetVaultRunStatsResponse(params).pipe(
      __map(_r => _r.body as VaultRunStatsSummary)
    );
  }

  /**
   * Compute the statistics on Views.
   *
   * Compute the statistics on Views.
   * @param params The `HeliosStatsService.McmGetViewStatsParams` containing the following parameters:
   *
   * - `numTopViews`: Specifies the number of views for which stats has to be computed. Specifying this field will return the Views sorted in the descending order on the metric specified. If specified, minimum value is 1.
   *
   * - `metric`: Specifies the metric to which stats has to be sorted.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `clusterIdentifiers`: Specifies the list of cluster identifiers. Format is clusterId:clusterIncarnationId.
   *
   * @return Success
   */
  McmGetViewStatsResponse(params: HeliosStatsService.McmGetViewStatsParams): __Observable<__StrictHttpResponse<ViewStatsSnapshot>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.numTopViews != null) __params = __params.set('numTopViews', params.numTopViews.toString());
    if (params.metric != null) __params = __params.set('metric', params.metric.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    (params.clusterIdentifiers || []).forEach(val => {if (val != null) __params = __params.append('clusterIdentifiers', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/mcm/stats/views`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ViewStatsSnapshot>;
      })
    );
  }
  /**
   * Compute the statistics on Views.
   *
   * Compute the statistics on Views.
   * @param params The `HeliosStatsService.McmGetViewStatsParams` containing the following parameters:
   *
   * - `numTopViews`: Specifies the number of views for which stats has to be computed. Specifying this field will return the Views sorted in the descending order on the metric specified. If specified, minimum value is 1.
   *
   * - `metric`: Specifies the metric to which stats has to be sorted.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `clusterIdentifiers`: Specifies the list of cluster identifiers. Format is clusterId:clusterIncarnationId.
   *
   * @return Success
   */
  McmGetViewStats(params: HeliosStatsService.McmGetViewStatsParams): __Observable<ViewStatsSnapshot> {
    return this.McmGetViewStatsResponse(params).pipe(
      __map(_r => _r.body as ViewStatsSnapshot)
    );
  }

  /**
   * Compute the protocol statistics on Views.
   *
   * Compute the protocol statistics on Views.
   * @param regionId This field represents the region id.
   * @param clusterIdentifiers Specifies the list of cluster identifiers. Format is clusterId:clusterIncarnationId.
   * @return Success
   */
  McmGetViewProtocolStatsResponse(regionId?: string,
    clusterIdentifiers?: Array<string>): __Observable<__StrictHttpResponse<ViewProtocolStatsList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    (clusterIdentifiers || []).forEach(val => {if (val != null) __params = __params.append('clusterIdentifiers', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/mcm/stats/views/protocols`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ViewProtocolStatsList>;
      })
    );
  }
  /**
   * Compute the protocol statistics on Views.
   *
   * Compute the protocol statistics on Views.
   * @param regionId This field represents the region id.
   * @param clusterIdentifiers Specifies the list of cluster identifiers. Format is clusterId:clusterIncarnationId.
   * @return Success
   */
  McmGetViewProtocolStats(regionId?: string,
    clusterIdentifiers?: Array<string>): __Observable<ViewProtocolStatsList> {
    return this.McmGetViewProtocolStatsResponse(regionId, clusterIdentifiers).pipe(
      __map(_r => _r.body as ViewProtocolStatsList)
    );
  }

  /**
   * Compute the time series stats of one view on one cluster.
   *
   * Compute the time series stats of one view on one cluster.
   * @param params The `HeliosStatsService.McmGetViewTimeSeriesStatsParams` containing the following parameters:
   *
   * - `viewId`: Specifies the view id.
   *
   * - `metric`: Specifies the metric to fetch for the view.
   *
   * - `clusterIdentifier`: Specifies the cluster where the view exists.Format is clusterId:clusterIncarnationId.
   *
   * - `regionId`: This field represents the region id.
   *
   * @return Success
   */
  McmGetViewTimeSeriesStatsResponse(params: HeliosStatsService.McmGetViewTimeSeriesStatsParams): __Observable<__StrictHttpResponse<ViewTimeSeriesStatsList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.viewId != null) __params = __params.set('viewId', params.viewId.toString());
    if (params.metric != null) __params = __params.set('metric', params.metric.toString());
    if (params.clusterIdentifier != null) __params = __params.set('clusterIdentifier', params.clusterIdentifier.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/mcm/stats/views/timeSeries`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ViewTimeSeriesStatsList>;
      })
    );
  }
  /**
   * Compute the time series stats of one view on one cluster.
   *
   * Compute the time series stats of one view on one cluster.
   * @param params The `HeliosStatsService.McmGetViewTimeSeriesStatsParams` containing the following parameters:
   *
   * - `viewId`: Specifies the view id.
   *
   * - `metric`: Specifies the metric to fetch for the view.
   *
   * - `clusterIdentifier`: Specifies the cluster where the view exists.Format is clusterId:clusterIncarnationId.
   *
   * - `regionId`: This field represents the region id.
   *
   * @return Success
   */
  McmGetViewTimeSeriesStats(params: HeliosStatsService.McmGetViewTimeSeriesStatsParams): __Observable<ViewTimeSeriesStatsList> {
    return this.McmGetViewTimeSeriesStatsResponse(params).pipe(
      __map(_r => _r.body as ViewTimeSeriesStatsList)
    );
  }
}

module HeliosStatsService {

  /**
   * Parameters for McmActiveAlertsStats
   */
  export interface McmActiveAlertsStatsParams {

    /**
     * Specifies the start time Unix time epoch in microseconds from which the active alerts stats are computed.
     */
    startTimeUsecs: number;

    /**
     * Specifies the end time Unix time epoch in microseconds to which the active alerts stats are computed.
     */
    endTimeUsecs: number;

    /**
     * Filter by a list of region ids.
     */
    regionIds?: Array<string>;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Specifies if stats of active alerts per cluster needs to be excluded. If set to false (default value), stats of active alerts per cluster is included in the response. If set to true, only aggregated stats summary will be present in the response.
     */
    excludeStatsByCluster?: null | boolean;

    /**
     * Specifies the list of cluster identifiers. Format is clusterId:clusterIncarnationId.
     */
    clusterIdentifiers?: Array<string>;

    /**
     * Specifies a list of alert origination source. If not specified, all alerts from all the sources are considered in the response.
     */
    alertSources?: Array<'kCluster' | 'kHelios'>;
  }

  /**
   * Parameters for McmGetFileDistributionStats
   */
  export interface McmGetFileDistributionStatsParams {

    /**
     * Specifies the entity type on which file distribution stats are computed.
     */
    entityType: 'kCluster' | 'kStorageDomain';

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Specifies the list of cluster identifiers. Format is clusterId:clusterIncarnationId.
     */
    clusterIdentifiers?: Array<string>;
  }

  /**
   * Parameters for McmProtectionRunsStats
   */
  export interface McmProtectionRunsStatsParams {

    /**
     * Specifies the Protection Run status for which stats has to be computed.
     */
    status: 'kSuccess' | 'kFailure' | 'kWarning' | 'kCanceled';

    /**
     * Specifies the start time in Unix timestamp epoch in microseconds from which Protection Runs stats are computed.
     */
    startTimeUsecs: number;

    /**
     * Specifies the end time in Unix timestamp epoch in microseconds to which Protection Runs stats are computed.
     */
    endTimeUsecs: number;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Specifies the list of cluster identifiers. Format is clusterId:clusterIncarnationId.
     */
    clusterIdentifiers?: Array<string>;
  }

  /**
   * Parameters for McmGetLastProtectionRunStats
   */
  export interface McmGetLastProtectionRunStatsParams {

    /**
     * Specifies the time in Unix timestamp epoch in microsecond which filters all the runs started before this value. If not specified, this will be set to 24 hours ahead of fromTimeUsecs parameter.
     */
    toTimeUsecs?: number;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Specifies the time in Unix timestamp epoch in microsecond which filters all the runs started after this value. If not specified, this will be set to 24 hours prior to toTimeUsecs parameter.
     */
    fromTimeUsecs?: number;

    /**
     * Specifies the list of cluster identifiers. Format is clusterId:clusterIncarnationId.
     */
    clusterIdentifiers?: Array<string>;
  }

  /**
   * Parameters for McmProtectionSummary
   */
  export interface McmProtectionSummaryParams {

    /**
     * Specifies the list of region ids. Only applicable in case of DMaaS.
     */
    regionIds?: null | Array<string>;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Specifies the environment types to exclude.
     */
    excludeTypes?: Array<'kVMware' | 'kHyperV' | 'kSQL' | 'kView' | 'kPuppeteer' | 'kPhysical' | 'kPure' | 'kIbmFlashSystem' | 'kNimble' | 'kAzure' | 'kNetapp' | 'kAgent' | 'kGenericNas' | 'kAcropolis' | 'kPhysicalFiles' | 'kIsilon' | 'kGPFS' | 'kKVM' | 'kAWS' | 'kExchange' | 'kHyperVVSS' | 'kOracle' | 'kGCP' | 'kFlashBlade' | 'kAWSNative' | 'kVCD' | 'kO365' | 'kO365Outlook' | 'kHyperFlex' | 'kGCPNative' | 'kAzureNative' | 'kAD' | 'kAWSSnapshotManager' | 'kSAPHANA'>;

    /**
     * Specifies the list of cluster identifiers. Format is clusterId:clusterIncarnationId.
     */
    clusterIdentifiers?: null | Array<string>;
  }

  /**
   * Parameters for McmRestoreStats
   */
  export interface McmRestoreStatsParams {

    /**
     * Specifies the start time Unix time epoch in microseconds from which the restore stats are computed.
     */
    startTimeUsecs: number;

    /**
     * Specifies the end time Unix time epoch in microseconds to which the restore stats are computed.
     */
    endTimeUsecs: number;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Specifies the list of cluster identifiers. Format is clusterId:clusterIncarnationId.
     */
    clusterIdentifiers?: Array<string>;
  }

  /**
   * Parameters for McmGetVaultProviderStats
   */
  export interface McmGetVaultProviderStatsParams {

    /**
     * Specifies the type of the runs.
     */
    runType: 'kArchived' | 'kRestored';

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Specifies the list of cluster identifiers. Format is clusterId:clusterIncarnationId.
     */
    clusterIdentifiers?: Array<string>;
  }

  /**
   * Parameters for McmGetVaultRunStats
   */
  export interface McmGetVaultRunStatsParams {

    /**
     * Specifies the start time Unix time epoch in microseconds from which the vault run stats are computed.
     */
    startTimeUsecs: number;

    /**
     * Specifies the type of the run.
     */
    runType: 'kArchive' | 'kRestore';

    /**
     * Specifies the interval by which runs will be grouped together in the returned trend line.
     */
    interval: 'kHour' | 'kDay' | 'kWeek';

    /**
     * Specifies the end time Unix time epoch in microseconds to which the vault run stats are computed.
     */
    endTimeUsecs: number;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Specifies the list of cluster identifiers. Format is clusterId:clusterIncarnationId.
     */
    clusterIdentifiers?: Array<string>;
  }

  /**
   * Parameters for McmGetViewStats
   */
  export interface McmGetViewStatsParams {

    /**
     * Specifies the number of views for which stats has to be computed. Specifying this field will return the Views sorted in the descending order on the metric specified. If specified, minimum value is 1.
     */
    numTopViews: null | number;

    /**
     * Specifies the metric to which stats has to be sorted.
     */
    metric: null | 'kSystemUsageBytes' | 'kChunkBytesPhysical' | 'kNumBytesWritten' | 'kNumBytesRead' | 'kPeakReadThroughput' | 'kPeakWriteThroughput';

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Specifies the list of cluster identifiers. Format is clusterId:clusterIncarnationId.
     */
    clusterIdentifiers?: Array<string>;
  }

  /**
   * Parameters for McmGetViewTimeSeriesStats
   */
  export interface McmGetViewTimeSeriesStatsParams {

    /**
     * Specifies the view id.
     */
    viewId: null | string;

    /**
     * Specifies the metric to fetch for the view.
     */
    metric: null | 'kReadThroughput' | 'kWriteThroughput' | 'kNumBytesRead' | 'kNumBytesWritten' | 'kLogicalUsage' | 'kPhysicalUsage';

    /**
     * Specifies the cluster where the view exists.Format is clusterId:clusterIncarnationId.
     */
    clusterIdentifier: null | string;

    /**
     * This field represents the region id.
     */
    regionId?: string;
  }
}

export { HeliosStatsService }
