// MODULE: Job Details

angular.module('C.jobDetails')
    .controller(
        'jobDetailsProtectionStatisticsController', ['$rootScope', '$scope', '$timeout', '$state', 'DateTimeService', 'JobService', 'JobRunsService', 'evalAJAX', 'cUtils', 'ENUM_BACKUP_JOB_FIELD',
            function($rootScope, $scope, $timeout, $state, DateTimeService, JobService, JobRunsService, evalAJAX, cUtils, ENUM_BACKUP_JOB_FIELD) {

                // convenience functions and variables
                $scope.JobService = JobService;

                /**
                 * Text translations for Reports Filter module
                 * @type {Object}
                 */
                $scope.text = angular.extend($scope.text, $rootScope.text.protectionJobsDetailsJobStatistics);

                /**
                 * respond to cDateRanger date changes updates restoreParams object
                 * and then initiates an api call to update $scope data
                 *
                 * @method     updateDateParams
                 * @return     {Void}
                 */
                $scope.updateDateParams = function updateDateParams() {
                    $scope.selectedFilters.timeObject.from = DateTimeService.dateToUsecs($scope.dates.startDate);
                    $scope.selectedFilters.timeObject.until = DateTimeService.dateToUsecs($scope.dates.endDate);
                    getJobRuns();
                    $scope.getData();
                };


                /**
                 * Number of job runs to show per chart
                 * @type {Number}
                 */
                $scope.runsPerChart = 20;

                /**
                 * Configuration Object for BytesPerRun Chart
                 * @type {Object}
                 */
                $scope.bytesPerRunChart = {
                    chartType: 'line',
                    loading: true,
                    chart: {
                        height: 300
                    },
                    xAxis: {
                        categories: [],
                        allowDecimals: false
                    },
                    yAxis: {
                        min: 0,
                        title: {
                            text: 'MiB'
                        }
                    },
                    tooltip: {
                        pointFormat: '<b>{point.y} MiB</b>'
                    },
                    series: [{
                        name: 'Data Read per run (MiB)',
                        data: []
                    }]
                };

                /**
                 * Configuration Object for RatioPerRun Chart
                 * @type {Object}
                 */
                $scope.throughputPerRunChart = {
                    chartType: 'line',
                    loading: true,
                    chart: {
                        height: 300
                    },
                    xAxis: {
                        categories: [],
                        allowDecimals: false
                    },
                    yAxis: {
                        min: 0,
                        title: {
                            text: 'MiB/Sec'
                        }
                    },
                    tooltip: {
                        pointFormat: '<b>{point.y} MiB/Sec</b>'
                    },
                    series: [{
                        name: 'MiB/Sec Ratio per run',
                        data: []
                    }]
                };

                /**
                 * Gets Job Runs
                 * @return {Object} Job Runs
                 */
                function getJobRuns() {

                    var runsParams = {
                        id: $state.params.id,
                        excludeTasks: true,
                    };

                    // If time filters are set, then add them to the runsParams
                    if (typeof $scope.selectedFilters.timeObject.from === 'number') {
                        runsParams.startTimeUsecs = $scope.selectedFilters.timeObject.from;
                    }
                    if (typeof $scope.selectedFilters.timeObject.until === 'number') {
                        runsParams.endTimeUsecs = $scope.selectedFilters.timeObject.until;
                    }

                    JobRunsService.getJobRuns(runsParams).then(
                        function getJobRunsSuccess(jobs) {
                            $scope.bytesPerRunChart.series[0].data = [];
                            $scope.throughputPerRunChart.series[0].data = [];
                            $scope.jobRuns = [];

                            if (!jobs.length) {
                                return;
                            }

                            // Loop through each job Run result
                            angular.forEach(
                                jobs[0].backupJobRuns.protectionRuns,
                                parseDataForCharts
                            );
                        },
                        evalAJAX.errorMessage
                    ).finally(
                        function getJobRunsFinally() {
                            $scope.throughputPerRunChart.loading = false;
                            $scope.bytesPerRunChart.loading = false;
                        }
                    );

                }


                /**
                 * Prepare data for charts
                 */
                function parseDataForCharts(object, index) {
                    var megabytes;
                    var seconds;
                    var throughput;

                    if (index < $scope.runsPerChart) {
                        megabytes = cUtils.bytesToMegabytes(object.backupRun.base.totalBytesReadFromSource);

                        $scope.bytesPerRunChart.series[0].data.unshift(megabytes);
                        $scope.bytesPerRunChart.xAxis.categories.push(index + 1);

                        throughput = 0;
                        // skip calculations if totalBytesReadFromSource is undefined,
                        // otherwise we get NaN for one of the chart values which
                        // prevents line connection
                        if (object.backupRun.base.totalBytesReadFromSource !== undefined) {
                            seconds = (object.backupRun.base.endTimeUsecs - object.backupRun.base.startTimeUsecs) !== 0 ? (object.backupRun.base.endTimeUsecs - object.backupRun.base.startTimeUsecs) / 1000000 : 1;
                            throughput = cUtils.round(megabytes / seconds, 2);
                        }

                        $scope.throughputPerRunChart.series[0].data.unshift(throughput);
                        $scope.throughputPerRunChart.xAxis.categories.push(index + 1);
                    }
                }

                /**
                 * Returns strings based on change obejects from JobAudit API
                 * @param  {object} job
                 * @return {string} string of changes
                 */
                $scope.evalChanges = function evalChanges(job) {
                    if (job.changes) {
                        var readableChanges = [];
                        for (var i = 0; i < job.changes.length; i++) {
                            readableChanges.push(ENUM_BACKUP_JOB_FIELD[job.changes[i]]);
                        }
                        var changeString = readableChanges.join(", ");
                        return changeString;
                    } else {
                        if (job.before && job.before.backupJob) {
                            return $scope.text.noChanges;
                        } else {
                            return $scope.text.jobCreated;
                        }
                    }
                };

                getJobRuns();
            }
        ]);
