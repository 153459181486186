import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AttributeFilter } from '@cohesity/api/reporting';
import { IrisContextService, isAllClustersScope } from '@cohesity/iris-core';
import { attributeToUrl } from '@cohesity/iris-reporting';
import { AjaxHandlerService, AutoDestroyable } from '@cohesity/utils';
import { StateService } from '@uirouter/core';
import { SeriesPieOptions } from 'highcharts';
import { finalize } from 'rxjs/operators';

import { ProtectionCardService, ProtectionData } from './protection-card.service';

export type ProtectedUnprotectedStatusParams = 'protected' | 'Protected' | 'unprotected' | 'Unprotected';

/**
 * @description
 * Protection card for single and multi cluster dashboard.
 *
 * @example
 *  <coh-protection-card></coh-protection-card>
 */
@Component({
  selector: 'coh-protection-card',
  templateUrl: './protection-card.component.html',
  styleUrls: ['./protection-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [ ProtectionCardService ]
})
export class ProtectionCardComponent extends AutoDestroyable implements OnInit {

  /**
   * Custom Pie Chart Options.
   */
  readonly customChartOptions = {
    tooltip: { ...this.formatTooltip() },
  };

  /**
   * Indicates if the user is currently operating in "All Clusters" scope.
   * True if so, false otherwise (single cluster).
   */
  allClusters: boolean;

  /**
   * Protection card header stats title.
   */
  statsTitle = '';

  /**
   * Protection card data.
   */
  protectionData: ProtectionData;

  /**
   * Protection series data. This is a replication of protectionData.series with
   * series name set the blank for screen reader.
   */
  protectionSeriesData: SeriesPieOptions[];

  /**
   * Indicates whether new data is being fetched from the API.
   */
  isLoading = false;

  /**
   * Provide percentage of storage consumed.
   */
  get protectionPct() {
    const { totalProtected = 0, totalUnprotected = 0 } = this.protectionData;
    const totalStorage = totalProtected + totalUnprotected;

    // Prevent division by zero.
    if (!totalStorage) {
      return 0;
    }

    return ((totalProtected / totalStorage) * 100).toFixed();
  }

  constructor(
    private ajsEvalAjaxService: AjaxHandlerService,
    private change: ChangeDetectorRef,
    private state: StateService,
    private irisContextService: IrisContextService,
    private protectionCardService: ProtectionCardService,
  ) {
    super();
  }

  /**
   * Gets params values for protected/unprotected reporting deeplink and
   * returns as url params
   *
   * @param paramTrigger
   * @returns
   */
  static getProtectedUnprotectedReportParams(paramTrigger?: ProtectedUnprotectedStatusParams):
    { [key: string]: string[] | string } {
    const reportParams: AttributeFilter[] = [];

    switch (paramTrigger.toLowerCase()) {
      case 'protected':
        reportParams.push({
          attribute: 'protectionStatus',
          filterType: 'In',
          inFilterParams: {
            attributeDataType: 'String',
            attributeLabels: ['Protected'],
            stringFilterValues: ['Protected'],
          },
        });
        break;
      case 'unprotected':
        reportParams.push({
          attribute: 'protectionStatus',
          filterType: 'In',
          inFilterParams: {
            attributeDataType: 'String',
            attributeLabels: ['Unprotected'],
            stringFilterValues: ['Unprotected'],
          },
        });
        break;
    }

    return attributeToUrl(reportParams);
  }

   /**
    * Initialize component and load stats and chart data.
    */
  ngOnInit() {
    this.isLoading = true;
    this.allClusters = isAllClustersScope(this.irisContextService.irisContext);
    this.protectionCardService.getProtectionData()
      .pipe(
        this.untilDestroy(),
        finalize(() => {
          this.isLoading = false;
          this.change.markForCheck();
        }))
      .subscribe((protectionData: ProtectionData) => {
        this.protectionData = protectionData;

        // This is a hack to fix screen reader which appends unnecessary
        // series name in aria-label.
        this.protectionSeriesData = protectionData.series?.map(series => {
          series.events = {
            click: $event => {
              // in single cluster mode, the whole card is clickable and sends to jobs.
              if (!this.allClusters) {
                return;
              }
              $event.preventDefault();
              $event.stopPropagation();
              this.goToReport(
                <ProtectedUnprotectedStatusParams>$event.point.name
              );
            },
          };
          return series;
        });
      },
      this.ajsEvalAjaxService.errorMessage);
  }
  /**
   * Custom tooltip formatter for highcharts series.
   */
  formatTooltip() {
    return {
      formatter() {
        return this.point.description;
      }
    };
  }

  /**
   * The method sends the user to the associated report with the correct status prefilled.
   *
   * @param statusParam - Filters the report by appropriate status
   * @returns
   */
  goToReport(statusParam: ProtectedUnprotectedStatusParams = 'Protected') {
    // in single cluster mode, the whole card is clickable and sends to jobs.
    if (!this.allClusters) {
      return;
    }
    this.state.go('reporting.detail', { id: 'protected-unprotected', ...ProtectionCardComponent.getProtectedUnprotectedReportParams(statusParam) });
  }
}
