/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityV2ApiConfiguration as __Configuration } from '../cohesity-v2api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { FilteredObjectsResponseBody } from '../models/filtered-objects-response-body';
import { FilterObjectsRequest } from '../models/filter-objects-request';
import { GetProtectedObjectsResponse } from '../models/get-protected-objects-response';
import { ObjectsActionRequest } from '../models/objects-action-request';
import { ObjectsLastRun } from '../models/objects-last-run';
import { AssociateEntityMetadataResult } from '../models/associate-entity-metadata-result';
import { AssociateEntityMetadataRequest } from '../models/associate-entity-metadata-request';
import { LocalGlobalObjectIdList } from '../models/local-global-object-id-list';
import { ObjectIdentifiersParams } from '../models/object-identifiers-params';
import { CancelObjectRunsResults } from '../models/cancel-object-runs-results';
import { CancelObjectRunsRequest } from '../models/cancel-object-runs-request';
import { ProtectedObjectInfo } from '../models/protected-object-info';
import { ObjectActionRequest } from '../models/object-action-request';
import { FileFolderInfo } from '../models/file-folder-info';
import { ObjectBrowseRequest } from '../models/object-browse-request';
import { GetPITRangesProtectedObjectResponseBody } from '../models/get-pitranges-protected-object-response-body';
import { GetObjectRunsResponseBody } from '../models/get-object-runs-response-body';
import { ObjectProtectionRunSummary } from '../models/object-protection-run-summary';
import { SnapshotDiffResult } from '../models/snapshot-diff-result';
import { SnapshotDiffParams } from '../models/snapshot-diff-params';
import { GetObjectSnapshotsResponseBody } from '../models/get-object-snapshots-response-body';
import { ObjectSnapshot } from '../models/object-snapshot';
import { UpdateObjectSnapshotRequest } from '../models/update-object-snapshot-request';
import { ObjectStats } from '../models/object-stats';
import { ObjectWithChildren } from '../models/object-with-children';
import { GetIndexedObjectSnapshotsResponseBody } from '../models/get-indexed-object-snapshots-response-body';
import { ObjectSnapshotIdResult } from '../models/object-snapshot-id-result';
import { ObjectSnapshotIdParams } from '../models/object-snapshot-id-params';
import { GetEntityMetadataResult } from '../models/get-entity-metadata-result';
import { ConstructMetaInfoResult } from '../models/construct-meta-info-result';
import { ConstructMetaInfoRequest } from '../models/construct-meta-info-request';
import { ConstructMetaInfoParams } from '../models/construct-meta-info-params';
import { ObjectSnapshotVolumeInfo } from '../models/object-snapshot-volume-info';
import { DeletedProtectedObjectsResponseBody } from '../models/deleted-protected-objects-response-body';
import { SourceHierarchyObjectSummaries } from '../models/source-hierarchy-object-summaries';
import { McmObjectsActivity } from '../models/mcm-objects-activity';
import { GetMcmObjectsActivityReqParams } from '../models/get-mcm-objects-activity-req-params';
import { McmObjectActivity } from '../models/mcm-object-activity';
import { McmObjectLastRunActivities } from '../models/mcm-object-last-run-activities';
import { GetMcmObjectsLastRunReqParams } from '../models/get-mcm-objects-last-run-req-params';
import { UpdateObjectsRunsMetadataParams } from '../models/update-objects-runs-metadata-params';
import { ObjectArchivalRunStats } from '../models/object-archival-run-stats';
import { GetProtectedObjectsResult } from '../models/get-protected-objects-result';
import { McmObjectSummaryResult } from '../models/mcm-object-summary-result';
import { McmTenantObjectIds } from '../models/mcm-tenant-object-ids';
import { McmTenantObjectIdsParams } from '../models/mcm-tenant-object-ids-params';
import { McmTenantObjectProtections } from '../models/mcm-tenant-object-protections';
import { McmTenantProtectedObjectsParams } from '../models/mcm-tenant-protected-objects-params';
@Injectable({
  providedIn: 'root',
})
class ObjectService extends __BaseService {
  static readonly FilterObjectsPath = '/data-protect/filter/objects';
  static readonly GetProtectedObjectsOfAnyTypePath = '/data-protect/objects';
  static readonly ObjectsActionsPath = '/data-protect/objects/actions';
  static readonly GetObjectsLastRunPath = '/data-protect/objects/last-run';
  static readonly AssociateEntityMetadataPath = '/data-protect/objects/metadata';
  static readonly GetObjectIdentifiersPath = '/data-protect/objects/object-identifiers';
  static readonly CancelObjectRunsPath = '/data-protect/objects/runs/cancel';
  static readonly GetProtectedObjectOfAnyTypeByIdPath = '/data-protect/objects/{id}';
  static readonly PerformActionOnObjectPath = '/data-protect/objects/{id}/actions';
  static readonly BrowseObjectContentsPath = '/data-protect/objects/{id}/browse';
  static readonly GetPITRangesForProtectedObjectPath = '/data-protect/objects/{id}/pit-ranges';
  static readonly GetObjectRunsPath = '/data-protect/objects/{id}/runs';
  static readonly GetObjectRunByRunIdPath = '/data-protect/objects/{id}/runs/{runId}';
  static readonly GetSnapshotDiffPath = '/data-protect/objects/{id}/snapshot-diff';
  static readonly InternalApiGetSnapshotDiffPath = '/data-protect/objects/{id}/snapshotDiff';
  static readonly GetObjectSnapshotsPath = '/data-protect/objects/{id}/snapshots';
  static readonly UpdateObjectSnapshotPath = '/data-protect/objects/{id}/snapshots/{snapshotId}';
  static readonly GetObjectStatsPath = '/data-protect/objects/{id}/stats';
  static readonly GetObjectTreePath = '/data-protect/objects/{id}/tree';
  static readonly GetAllIndexedObjectSnapshotsPath = '/data-protect/objects/{objectId}/indexed-objects/snapshots';
  static readonly GetIndexedObjectSnapshotsPath = '/data-protect/objects/{objectId}/protection-groups/{protectionGroupId}/indexed-objects/snapshots';
  static readonly GetObjectSnapshotIdPath = '/data-protect/objects/{objectId}/snapshotId';
  static readonly GetEntityMetadataPath = '/data-protect/objects/{sourceId}/metadata';
  static readonly GetObjectSnapshotInfoPath = '/data-protect/snapshots/{snapshotId}';
  static readonly ConstructMetaInfoPath = '/data-protect/snapshots/{snapshotId}/meta-info';
  static readonly InternalApiConstructMetaInfoPath = '/data-protect/snapshots/{snapshotId}/metaInfo';
  static readonly GetObjectSnapshotVolumeInfoPath = '/data-protect/snapshots/{snapshotId}/volume';
  static readonly GetDeletedProtectedObjectsPath = '/data-protect/sources/{sourceId}/deleted-protected-objects';
  static readonly GetSourceHierarchyObjectsPath = '/data-protect/sources/{sourceId}/objects';
  static readonly GetMcmObjectsActivityPath = '/mcm/data-protect/objects/activity';
  static readonly GetMcmObjectsActivityByIdPath = '/mcm/data-protect/objects/activity/{id}';
  static readonly GetMcmObjectsLastRunActivityPath = '/mcm/data-protect/objects/last-run-activity';
  static readonly UpdateObjectsRunsMetadataPath = '/mcm/data-protect/objects/runs/metadata';
  static readonly GetMcmObjectSnapshotsPath = '/mcm/data-protect/objects/snapshots';
  static readonly GetMcmObjectStatsPath = '/mcm/data-protect/objects/stats';
  static readonly GetObjectArchivalRunStatsPath = '/mcm/data-protect/objects/stats/archival-runs';
  static readonly GetProtectedObjectsPath = '/mcm/data-protect/protected-objects';
  static readonly GetMcmObjectSummaryPath = '/mcm/objects/summary';
  static readonly McmGetTenantObjectIdsPath = '/mcm/tenants/object-ids';
  static readonly McmGetTenantObjectProtectionsPath = '/mcm/tenants/protected-objects';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * List all the filtered objects.
   *
   * List all the filtered objects using given regular expressions and wildcard supported search strings. We are currenly supporting this for only SQL adapter.
   * @param params The `ObjectService.FilterObjectsParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to filter objects.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  FilterObjectsResponse(params: ObjectService.FilterObjectsParams): __Observable<__StrictHttpResponse<FilteredObjectsResponseBody>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/data-protect/filter/objects`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FilteredObjectsResponseBody>;
      })
    );
  }
  /**
   * List all the filtered objects.
   *
   * List all the filtered objects using given regular expressions and wildcard supported search strings. We are currenly supporting this for only SQL adapter.
   * @param params The `ObjectService.FilterObjectsParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to filter objects.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  FilterObjects(params: ObjectService.FilterObjectsParams): __Observable<FilteredObjectsResponseBody> {
    return this.FilterObjectsResponse(params).pipe(
      __map(_r => _r.body as FilteredObjectsResponseBody)
    );
  }

  /**
   * Get Objects.
   *
   * Get Objects Configurations.
   * @param params The `ObjectService.GetProtectedObjectsOfAnyTypeParams` containing the following parameters:
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which objects are to be returned.
   *
   * - `storageDomainId`: Filter by Storage Domain id. Only Objects protected to this Storage Domain will be returned.
   *
   * - `requestInitiatorType`: Specifies the type of request from UI, which is used for services like magneto to determine the priority of requests.
   *
   * - `regionIds`: Filter by a list of region ids.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `policyIds`: Filter by Policy ids that are associated with Protected Objects.
   *
   * - `parentId`: Filter by Parent Id. Parent id is a unique object Id which may contain protected objects underneath in the source tree.
   *
   * - `onlyProtectedObjects`: If true, the response will include only objects which have been protected.
   *
   * - `onlyLeafObjects`: If true, the response will include only the leaf level objects.
   *
   * - `onlyAutoProtectedObjects`: If true, the response will include only the auto protected objects.
   *
   * - `objectActionKeys`: Filter by ObjectActionKey, uniquely represent protection of an object. An object can be protected in multiple ways but atmost once for a given combination of ObjectActionKey, when specified Only objects of given action_key are returned for corresponding object id. The vec's size needs to be of either length one or same as the length of 'ids'. If the length of objectActionKey is one, it will be repeated as many number of times equal to the length of objectIds, as mandated by backend validation. If the length of objectActionKey and object ids are same then it will be passed as it is.
   *
   * - `maxCount`: Specifies the max number of objects to return.
   *
   * - `includeTenants`: If true, the response will include Objects which were protected by all tenants which the current user has permission to see. If false, then only objects protected by the current user will be returned.
   *
   * - `includeLastRunInfo`: If true, the response will include information about the last protection run on this object.
   *
   * - `ids`: Filter by a list of Object ids.
   *
   * - `environments`: Filter by environment types such as 'kVMware', 'kView', etc. Only Protected objects protecting the specified environment types are returned.
   *
   * - `cookie`: Specifies the pagination cookie.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetProtectedObjectsOfAnyTypeResponse(params: ObjectService.GetProtectedObjectsOfAnyTypeParams): __Observable<__StrictHttpResponse<GetProtectedObjectsResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.tenantIds || []).forEach(val => {if (val != null) __params = __params.append('tenantIds', val.toString())});
    if (params.storageDomainId != null) __params = __params.set('storageDomainId', params.storageDomainId.toString());
    if (params.requestInitiatorType != null) __headers = __headers.set('requestInitiatorType', params.requestInitiatorType.toString());
    (params.regionIds || []).forEach(val => {if (val != null) __params = __params.append('regionIds', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    (params.policyIds || []).forEach(val => {if (val != null) __params = __params.append('policyIds', val.toString())});
    if (params.parentId != null) __params = __params.set('parentId', params.parentId.toString());
    if (params.onlyProtectedObjects != null) __params = __params.set('onlyProtectedObjects', params.onlyProtectedObjects.toString());
    if (params.onlyLeafObjects != null) __params = __params.set('onlyLeafObjects', params.onlyLeafObjects.toString());
    if (params.onlyAutoProtectedObjects != null) __params = __params.set('onlyAutoProtectedObjects', params.onlyAutoProtectedObjects.toString());
    (params.objectActionKeys || []).forEach(val => {if (val != null) __params = __params.append('objectActionKeys', val.toString())});
    if (params.maxCount != null) __params = __params.set('maxCount', params.maxCount.toString());
    if (params.includeTenants != null) __params = __params.set('includeTenants', params.includeTenants.toString());
    if (params.includeLastRunInfo != null) __params = __params.set('includeLastRunInfo', params.includeLastRunInfo.toString());
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    (params.environments || []).forEach(val => {if (val != null) __params = __params.append('environments', val.toString())});
    if (params.cookie != null) __params = __params.set('cookie', params.cookie.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/data-protect/objects`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetProtectedObjectsResponse>;
      })
    );
  }
  /**
   * Get Objects.
   *
   * Get Objects Configurations.
   * @param params The `ObjectService.GetProtectedObjectsOfAnyTypeParams` containing the following parameters:
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which objects are to be returned.
   *
   * - `storageDomainId`: Filter by Storage Domain id. Only Objects protected to this Storage Domain will be returned.
   *
   * - `requestInitiatorType`: Specifies the type of request from UI, which is used for services like magneto to determine the priority of requests.
   *
   * - `regionIds`: Filter by a list of region ids.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `policyIds`: Filter by Policy ids that are associated with Protected Objects.
   *
   * - `parentId`: Filter by Parent Id. Parent id is a unique object Id which may contain protected objects underneath in the source tree.
   *
   * - `onlyProtectedObjects`: If true, the response will include only objects which have been protected.
   *
   * - `onlyLeafObjects`: If true, the response will include only the leaf level objects.
   *
   * - `onlyAutoProtectedObjects`: If true, the response will include only the auto protected objects.
   *
   * - `objectActionKeys`: Filter by ObjectActionKey, uniquely represent protection of an object. An object can be protected in multiple ways but atmost once for a given combination of ObjectActionKey, when specified Only objects of given action_key are returned for corresponding object id. The vec's size needs to be of either length one or same as the length of 'ids'. If the length of objectActionKey is one, it will be repeated as many number of times equal to the length of objectIds, as mandated by backend validation. If the length of objectActionKey and object ids are same then it will be passed as it is.
   *
   * - `maxCount`: Specifies the max number of objects to return.
   *
   * - `includeTenants`: If true, the response will include Objects which were protected by all tenants which the current user has permission to see. If false, then only objects protected by the current user will be returned.
   *
   * - `includeLastRunInfo`: If true, the response will include information about the last protection run on this object.
   *
   * - `ids`: Filter by a list of Object ids.
   *
   * - `environments`: Filter by environment types such as 'kVMware', 'kView', etc. Only Protected objects protecting the specified environment types are returned.
   *
   * - `cookie`: Specifies the pagination cookie.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetProtectedObjectsOfAnyType(params: ObjectService.GetProtectedObjectsOfAnyTypeParams): __Observable<GetProtectedObjectsResponse> {
    return this.GetProtectedObjectsOfAnyTypeResponse(params).pipe(
      __map(_r => _r.body as GetProtectedObjectsResponse)
    );
  }

  /**
   * Actions on Objects
   *
   * Specifies the request to perform various actions on multiple objects.
   * @param params The `ObjectService.ObjectsActionsParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to execute actions on given list of objects.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  ObjectsActionsResponse(params: ObjectService.ObjectsActionsParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/data-protect/objects/actions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Actions on Objects
   *
   * Specifies the request to perform various actions on multiple objects.
   * @param params The `ObjectService.ObjectsActionsParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to execute actions on given list of objects.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  ObjectsActions(params: ObjectService.ObjectsActionsParams): __Observable<null> {
    return this.ObjectsActionsResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get last protection run of objects.
   *
   * Get last protection run of objects.
   * @param params The `ObjectService.GetObjectsLastRunParams` containing the following parameters:
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which objects are to be returned.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `paginationCookie`: Specifies the pagination cookie with which subsequent parts of the response can be fetched.
   *
   * - `includeTenants`: If true, the response will include Objects which belongs to all tenants which the current user has permission to see.
   *
   * - `ids`: Specifies a list of object ids, only last runs for these objects will be returned.
   *
   * - `count`: Specifies the number of objects to be fetched for the specified pagination cookie.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetObjectsLastRunResponse(params: ObjectService.GetObjectsLastRunParams): __Observable<__StrictHttpResponse<ObjectsLastRun>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.tenantIds || []).forEach(val => {if (val != null) __params = __params.append('tenantIds', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.paginationCookie != null) __params = __params.set('paginationCookie', params.paginationCookie.toString());
    if (params.includeTenants != null) __params = __params.set('includeTenants', params.includeTenants.toString());
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    if (params.count != null) __params = __params.set('count', params.count.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/data-protect/objects/last-run`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ObjectsLastRun>;
      })
    );
  }
  /**
   * Get last protection run of objects.
   *
   * Get last protection run of objects.
   * @param params The `ObjectService.GetObjectsLastRunParams` containing the following parameters:
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which objects are to be returned.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `paginationCookie`: Specifies the pagination cookie with which subsequent parts of the response can be fetched.
   *
   * - `includeTenants`: If true, the response will include Objects which belongs to all tenants which the current user has permission to see.
   *
   * - `ids`: Specifies a list of object ids, only last runs for these objects will be returned.
   *
   * - `count`: Specifies the number of objects to be fetched for the specified pagination cookie.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetObjectsLastRun(params: ObjectService.GetObjectsLastRunParams): __Observable<ObjectsLastRun> {
    return this.GetObjectsLastRunResponse(params).pipe(
      __map(_r => _r.body as ObjectsLastRun)
    );
  }

  /**
   * Associate Metadata with Entity
   *
   * Associates metadata with entities in the entity hierarchy. This metadata can be of various types (eg. Credentials). Returns a list of entity id and corresponding errors encountered (if any) while associating metadata with that entity. Note that a partial success response is possible where we succeed in associating metadata with some of the entities but fail for others. The API also expects the entities being updated belong to same source.
   * @param params The `ObjectService.AssociateEntityMetadataParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to associate metadata with entities in the entity hierarchy.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  AssociateEntityMetadataResponse(params: ObjectService.AssociateEntityMetadataParams): __Observable<__StrictHttpResponse<AssociateEntityMetadataResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/data-protect/objects/metadata`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AssociateEntityMetadataResult>;
      })
    );
  }
  /**
   * Associate Metadata with Entity
   *
   * Associates metadata with entities in the entity hierarchy. This metadata can be of various types (eg. Credentials). Returns a list of entity id and corresponding errors encountered (if any) while associating metadata with that entity. Note that a partial success response is possible where we succeed in associating metadata with some of the entities but fail for others. The API also expects the entities being updated belong to same source.
   * @param params The `ObjectService.AssociateEntityMetadataParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to associate metadata with entities in the entity hierarchy.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  AssociateEntityMetadata(params: ObjectService.AssociateEntityMetadataParams): __Observable<AssociateEntityMetadataResult> {
    return this.AssociateEntityMetadataResponse(params).pipe(
      __map(_r => _r.body as AssociateEntityMetadataResult)
    );
  }

  /**
   * Get Object Identifiers
   *
   * Get Object Identifiers.
   * @param params The `ObjectService.GetObjectIdentifiersParams` containing the following parameters:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `localIds`: Get the object identifier matching specified local IDs.
   *
   * - `globalIds`: Get the object identifier matching specified global IDs.
   *
   * - `body`: Extra parameters that can be specified to get object identifiers.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetObjectIdentifiersResponse(params: ObjectService.GetObjectIdentifiersParams): __Observable<__StrictHttpResponse<LocalGlobalObjectIdList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    (params.localIds || []).forEach(val => {if (val != null) __params = __params.append('localIds', val.toString())});
    (params.globalIds || []).forEach(val => {if (val != null) __params = __params.append('globalIds', val.toString())});
    __body = params.body;
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/data-protect/objects/object-identifiers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LocalGlobalObjectIdList>;
      })
    );
  }
  /**
   * Get Object Identifiers
   *
   * Get Object Identifiers.
   * @param params The `ObjectService.GetObjectIdentifiersParams` containing the following parameters:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `localIds`: Get the object identifier matching specified local IDs.
   *
   * - `globalIds`: Get the object identifier matching specified global IDs.
   *
   * - `body`: Extra parameters that can be specified to get object identifiers.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetObjectIdentifiers(params: ObjectService.GetObjectIdentifiersParams): __Observable<LocalGlobalObjectIdList> {
    return this.GetObjectIdentifiersResponse(params).pipe(
      __map(_r => _r.body as LocalGlobalObjectIdList)
    );
  }

  /**
   * Cancel object runs.
   *
   * Cancel object runs for object based protection. This does not apply to Group based protection.
   * @param params The `ObjectService.CancelObjectRunsParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to cancel object runs.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CancelObjectRunsResponse(params: ObjectService.CancelObjectRunsParams): __Observable<__StrictHttpResponse<CancelObjectRunsResults>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/data-protect/objects/runs/cancel`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CancelObjectRunsResults>;
      })
    );
  }
  /**
   * Cancel object runs.
   *
   * Cancel object runs for object based protection. This does not apply to Group based protection.
   * @param params The `ObjectService.CancelObjectRunsParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to cancel object runs.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CancelObjectRuns(params: ObjectService.CancelObjectRunsParams): __Observable<CancelObjectRunsResults> {
    return this.CancelObjectRunsResponse(params).pipe(
      __map(_r => _r.body as CancelObjectRunsResults)
    );
  }

  /**
   * Get an Object.
   *
   * Get Object configurations for given object id.
   * @param params The `ObjectService.GetProtectedObjectOfAnyTypeByIdParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the Object.
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which objects are to be returned.
   *
   * - `storageDomainId`: Filter by Storage Domain id. Only Objects protected to this Storage Domain will be returned.
   *
   * - `requestInitiatorType`: Specifies the type of request from UI, which is used for services like magneto to determine the priority of requests.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `onlyProtectedObjects`: If true, the response will include only objects which have been protected.
   *
   * - `onlyLeafObjects`: If true, the response will include only the leaf level objects.
   *
   * - `onlyAutoProtectedObjects`: If true, the response will include only the auto protected objects.
   *
   * - `objectActionKey`: Filter by ObjectActionKey, uniquely represent protection of an object. An object can be protected in multiple ways but atmost once for a given combination of ObjectActionKey, when specified Only objects of given action_key are returned for corresponding object id and this vec's size needs to be same as 'id'.
   *
   * - `includeTenants`: If true, the response will include Objects which were protected by all tenants which the current user has permission to see. If false, then only objects protected by the current user will be returned.
   *
   * - `includeLastRunInfo`: If true, the response will include information about the last protection run on this object.
   *
   * - `environments`: Filter by environment types such as 'kVMware', 'kView', etc. Only Protected objects protecting the specified environment types are returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetProtectedObjectOfAnyTypeByIdResponse(params: ObjectService.GetProtectedObjectOfAnyTypeByIdParams): __Observable<__StrictHttpResponse<ProtectedObjectInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    (params.tenantIds || []).forEach(val => {if (val != null) __params = __params.append('tenantIds', val.toString())});
    if (params.storageDomainId != null) __params = __params.set('storageDomainId', params.storageDomainId.toString());
    if (params.requestInitiatorType != null) __headers = __headers.set('requestInitiatorType', params.requestInitiatorType.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.onlyProtectedObjects != null) __params = __params.set('onlyProtectedObjects', params.onlyProtectedObjects.toString());
    if (params.onlyLeafObjects != null) __params = __params.set('onlyLeafObjects', params.onlyLeafObjects.toString());
    if (params.onlyAutoProtectedObjects != null) __params = __params.set('onlyAutoProtectedObjects', params.onlyAutoProtectedObjects.toString());
    (params.objectActionKey || []).forEach(val => {if (val != null) __params = __params.append('objectActionKey', val.toString())});
    if (params.includeTenants != null) __params = __params.set('includeTenants', params.includeTenants.toString());
    if (params.includeLastRunInfo != null) __params = __params.set('includeLastRunInfo', params.includeLastRunInfo.toString());
    (params.environments || []).forEach(val => {if (val != null) __params = __params.append('environments', val.toString())});
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/data-protect/objects/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ProtectedObjectInfo>;
      })
    );
  }
  /**
   * Get an Object.
   *
   * Get Object configurations for given object id.
   * @param params The `ObjectService.GetProtectedObjectOfAnyTypeByIdParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the Object.
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which objects are to be returned.
   *
   * - `storageDomainId`: Filter by Storage Domain id. Only Objects protected to this Storage Domain will be returned.
   *
   * - `requestInitiatorType`: Specifies the type of request from UI, which is used for services like magneto to determine the priority of requests.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `onlyProtectedObjects`: If true, the response will include only objects which have been protected.
   *
   * - `onlyLeafObjects`: If true, the response will include only the leaf level objects.
   *
   * - `onlyAutoProtectedObjects`: If true, the response will include only the auto protected objects.
   *
   * - `objectActionKey`: Filter by ObjectActionKey, uniquely represent protection of an object. An object can be protected in multiple ways but atmost once for a given combination of ObjectActionKey, when specified Only objects of given action_key are returned for corresponding object id and this vec's size needs to be same as 'id'.
   *
   * - `includeTenants`: If true, the response will include Objects which were protected by all tenants which the current user has permission to see. If false, then only objects protected by the current user will be returned.
   *
   * - `includeLastRunInfo`: If true, the response will include information about the last protection run on this object.
   *
   * - `environments`: Filter by environment types such as 'kVMware', 'kView', etc. Only Protected objects protecting the specified environment types are returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetProtectedObjectOfAnyTypeById(params: ObjectService.GetProtectedObjectOfAnyTypeByIdParams): __Observable<ProtectedObjectInfo> {
    return this.GetProtectedObjectOfAnyTypeByIdResponse(params).pipe(
      __map(_r => _r.body as ProtectedObjectInfo)
    );
  }

  /**
   * Perform an action on an object.
   *
   * Perform an action on an object. Depending on the object environment type, different actions are available.
   * @param params The `ObjectService.PerformActionOnObjectParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the Object.
   *
   * - `body`: Specifies the parameters to perform an action on an object.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  PerformActionOnObjectResponse(params: ObjectService.PerformActionOnObjectParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/data-protect/objects/${encodeURIComponent(params.id)}/actions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Perform an action on an object.
   *
   * Perform an action on an object. Depending on the object environment type, different actions are available.
   * @param params The `ObjectService.PerformActionOnObjectParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the Object.
   *
   * - `body`: Specifies the parameters to perform an action on an object.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  PerformActionOnObject(params: ObjectService.PerformActionOnObjectParams): __Observable<null> {
    return this.PerformActionOnObjectResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Fetch the contents (files & folders) for the specified object.
   *
   * Fetch the contents (files & folders) of the specified path inside the specified object.
   * @param params The `ObjectService.BrowseObjectContentsParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the Object.
   *
   * - `body`: Specifies the parameters to fetch contents of an object.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  BrowseObjectContentsResponse(params: ObjectService.BrowseObjectContentsParams): __Observable<__StrictHttpResponse<FileFolderInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/data-protect/objects/${encodeURIComponent(params.id)}/browse`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FileFolderInfo>;
      })
    );
  }
  /**
   * Fetch the contents (files & folders) for the specified object.
   *
   * Fetch the contents (files & folders) of the specified path inside the specified object.
   * @param params The `ObjectService.BrowseObjectContentsParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the Object.
   *
   * - `body`: Specifies the parameters to fetch contents of an object.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  BrowseObjectContents(params: ObjectService.BrowseObjectContentsParams): __Observable<FileFolderInfo> {
    return this.BrowseObjectContentsResponse(params).pipe(
      __map(_r => _r.body as FileFolderInfo)
    );
  }

  /**
   * Get PIT ranges for an object
   *
   * Returns the ranges in various types like time, SCN etc. within which the specified protected object can be restored to any Point in time.
   * @param params The `ObjectService.GetPITRangesForProtectedObjectParams` containing the following parameters:
   *
   * - `id`: Specifies the ID of the protected object.
   *
   * - `toTimeUsecs`: If specified, return the restore ranges that lie before this timestamp. This parameter is specified as the timestamp in Unix time epoch in microseconds.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `protectionGroupIds`: If specified, return only the points in time corresponding to these protection group IDs.
   *
   * - `fromTimeUsecs`: If specified, return the restore ranges that lie after this timestamp. This parameter is specified as the timestamp in Unix time epoch in microseconds.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetPITRangesForProtectedObjectResponse(params: ObjectService.GetPITRangesForProtectedObjectParams): __Observable<__StrictHttpResponse<GetPITRangesProtectedObjectResponseBody>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.toTimeUsecs != null) __params = __params.set('toTimeUsecs', params.toTimeUsecs.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    (params.protectionGroupIds || []).forEach(val => {if (val != null) __params = __params.append('protectionGroupIds', val.toString())});
    if (params.fromTimeUsecs != null) __params = __params.set('fromTimeUsecs', params.fromTimeUsecs.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/data-protect/objects/${encodeURIComponent(params.id)}/pit-ranges`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetPITRangesProtectedObjectResponseBody>;
      })
    );
  }
  /**
   * Get PIT ranges for an object
   *
   * Returns the ranges in various types like time, SCN etc. within which the specified protected object can be restored to any Point in time.
   * @param params The `ObjectService.GetPITRangesForProtectedObjectParams` containing the following parameters:
   *
   * - `id`: Specifies the ID of the protected object.
   *
   * - `toTimeUsecs`: If specified, return the restore ranges that lie before this timestamp. This parameter is specified as the timestamp in Unix time epoch in microseconds.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `protectionGroupIds`: If specified, return only the points in time corresponding to these protection group IDs.
   *
   * - `fromTimeUsecs`: If specified, return the restore ranges that lie after this timestamp. This parameter is specified as the timestamp in Unix time epoch in microseconds.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetPITRangesForProtectedObject(params: ObjectService.GetPITRangesForProtectedObjectParams): __Observable<GetPITRangesProtectedObjectResponseBody> {
    return this.GetPITRangesForProtectedObjectResponse(params).pipe(
      __map(_r => _r.body as GetPITRangesProtectedObjectResponseBody)
    );
  }

  /**
   * Get the list of runs for an object.
   *
   * Get the runs for a particular object.
   * @param params The `ObjectService.GetObjectRunsParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the object.
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which objects are to be returned.
   *
   * - `startTimeUsecs`: Filter by a start time when the run starts. Specify the start time as a Unix epoch Timestamp (in microseconds).
   *
   * - `runTypes`: Filter by run type. Only protection run matching the specified types will be returned.
   *
   * - `runId`: Specifies a unique id of the run.
   *
   * - `replicationObjectStatus`: Specifies a list of status for the object in the replication run.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `paginationCookie`: Specifies the pagination cookie with which subsequent parts of the response can be fetched. Users can use this to get next runs
   *
   * - `numRuns`: Specifies the max number of runs. If not specified, at most 100 runs will be returned.
   *
   * - `localBackupObjectStatus`: Specifies a list of status for the object in the backup run.
   *
   * - `includeTenants`: If true, the response will include Protection Group Runs which were created by all tenants which the current user has permission to see. If false, then only Protection Group Runs created by the current user will be returned.
   *
   * - `excludeNonRestorableRuns`: Specifies whether to exclude non restorable runs. Run is treated restorable only if there is at least one object snapshot (which may be either a local or an archival snapshot) which is not deleted or expired. Default value is false.
   *
   * - `endTimeUsecs`: Filter by a end time when the run starts. Specify the start time as a Unix epoch Timestamp (in microseconds).
   *
   * - `cloudSpinRunStatus`: Specifies a list of status for the object in the cloud spin run.
   *
   * - `archivalObjectStatus`: Specifies a list of status for the object in the archival run.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetObjectRunsResponse(params: ObjectService.GetObjectRunsParams): __Observable<__StrictHttpResponse<GetObjectRunsResponseBody>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    (params.tenantIds || []).forEach(val => {if (val != null) __params = __params.append('tenantIds', val.toString())});
    if (params.startTimeUsecs != null) __params = __params.set('startTimeUsecs', params.startTimeUsecs.toString());
    (params.runTypes || []).forEach(val => {if (val != null) __params = __params.append('runTypes', val.toString())});
    if (params.runId != null) __params = __params.set('runId', params.runId.toString());
    (params.replicationObjectStatus || []).forEach(val => {if (val != null) __params = __params.append('replicationObjectStatus', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.paginationCookie != null) __params = __params.set('paginationCookie', params.paginationCookie.toString());
    if (params.numRuns != null) __params = __params.set('numRuns', params.numRuns.toString());
    (params.localBackupObjectStatus || []).forEach(val => {if (val != null) __params = __params.append('localBackupObjectStatus', val.toString())});
    if (params.includeTenants != null) __params = __params.set('includeTenants', params.includeTenants.toString());
    if (params.excludeNonRestorableRuns != null) __params = __params.set('excludeNonRestorableRuns', params.excludeNonRestorableRuns.toString());
    if (params.endTimeUsecs != null) __params = __params.set('endTimeUsecs', params.endTimeUsecs.toString());
    (params.cloudSpinRunStatus || []).forEach(val => {if (val != null) __params = __params.append('cloudSpinRunStatus', val.toString())});
    (params.archivalObjectStatus || []).forEach(val => {if (val != null) __params = __params.append('archivalObjectStatus', val.toString())});
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/data-protect/objects/${encodeURIComponent(params.id)}/runs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetObjectRunsResponseBody>;
      })
    );
  }
  /**
   * Get the list of runs for an object.
   *
   * Get the runs for a particular object.
   * @param params The `ObjectService.GetObjectRunsParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the object.
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which objects are to be returned.
   *
   * - `startTimeUsecs`: Filter by a start time when the run starts. Specify the start time as a Unix epoch Timestamp (in microseconds).
   *
   * - `runTypes`: Filter by run type. Only protection run matching the specified types will be returned.
   *
   * - `runId`: Specifies a unique id of the run.
   *
   * - `replicationObjectStatus`: Specifies a list of status for the object in the replication run.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `paginationCookie`: Specifies the pagination cookie with which subsequent parts of the response can be fetched. Users can use this to get next runs
   *
   * - `numRuns`: Specifies the max number of runs. If not specified, at most 100 runs will be returned.
   *
   * - `localBackupObjectStatus`: Specifies a list of status for the object in the backup run.
   *
   * - `includeTenants`: If true, the response will include Protection Group Runs which were created by all tenants which the current user has permission to see. If false, then only Protection Group Runs created by the current user will be returned.
   *
   * - `excludeNonRestorableRuns`: Specifies whether to exclude non restorable runs. Run is treated restorable only if there is at least one object snapshot (which may be either a local or an archival snapshot) which is not deleted or expired. Default value is false.
   *
   * - `endTimeUsecs`: Filter by a end time when the run starts. Specify the start time as a Unix epoch Timestamp (in microseconds).
   *
   * - `cloudSpinRunStatus`: Specifies a list of status for the object in the cloud spin run.
   *
   * - `archivalObjectStatus`: Specifies a list of status for the object in the archival run.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetObjectRuns(params: ObjectService.GetObjectRunsParams): __Observable<GetObjectRunsResponseBody> {
    return this.GetObjectRunsResponse(params).pipe(
      __map(_r => _r.body as GetObjectRunsResponseBody)
    );
  }

  /**
   * Get a run for an object.
   *
   * Get a run for an object.
   * @param params The `ObjectService.GetObjectRunByRunIdParams` containing the following parameters:
   *
   * - `runId`: Specifies the id of the run.
   *
   * - `id`: Specifies a unique id of the object.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetObjectRunByRunIdResponse(params: ObjectService.GetObjectRunByRunIdParams): __Observable<__StrictHttpResponse<ObjectProtectionRunSummary>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/data-protect/objects/${encodeURIComponent(params.id)}/runs/${encodeURIComponent(params.runId)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ObjectProtectionRunSummary>;
      })
    );
  }
  /**
   * Get a run for an object.
   *
   * Get a run for an object.
   * @param params The `ObjectService.GetObjectRunByRunIdParams` containing the following parameters:
   *
   * - `runId`: Specifies the id of the run.
   *
   * - `id`: Specifies a unique id of the object.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetObjectRunByRunId(params: ObjectService.GetObjectRunByRunIdParams): __Observable<ObjectProtectionRunSummary> {
    return this.GetObjectRunByRunIdResponse(params).pipe(
      __map(_r => _r.body as ObjectProtectionRunSummary)
    );
  }

  /**
   * Get diff between two snapshots of a given object.
   *
   * Get diff (files added/deleted) between two snapshots of a given object.
   * @param params The `ObjectService.GetSnapshotDiffParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `body`:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetSnapshotDiffResponse(params: ObjectService.GetSnapshotDiffParams): __Observable<__StrictHttpResponse<SnapshotDiffResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/data-protect/objects/${encodeURIComponent(params.id)}/snapshot-diff`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SnapshotDiffResult>;
      })
    );
  }
  /**
   * Get diff between two snapshots of a given object.
   *
   * Get diff (files added/deleted) between two snapshots of a given object.
   * @param params The `ObjectService.GetSnapshotDiffParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `body`:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetSnapshotDiff(params: ObjectService.GetSnapshotDiffParams): __Observable<SnapshotDiffResult> {
    return this.GetSnapshotDiffResponse(params).pipe(
      __map(_r => _r.body as SnapshotDiffResult)
    );
  }

  /**
   * Get diff between two snapshots of a given object.
   *
   * Get diff (files added/deleted) between two snapshots of a given object.
   * @param params The `ObjectService.InternalApiGetSnapshotDiffParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `body`:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  InternalApiGetSnapshotDiffResponse(params: ObjectService.InternalApiGetSnapshotDiffParams): __Observable<__StrictHttpResponse<SnapshotDiffResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/data-protect/objects/${encodeURIComponent(params.id)}/snapshotDiff`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SnapshotDiffResult>;
      })
    );
  }
  /**
   * Get diff between two snapshots of a given object.
   *
   * Get diff (files added/deleted) between two snapshots of a given object.
   * @param params The `ObjectService.InternalApiGetSnapshotDiffParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `body`:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  InternalApiGetSnapshotDiff(params: ObjectService.InternalApiGetSnapshotDiffParams): __Observable<SnapshotDiffResult> {
    return this.InternalApiGetSnapshotDiffResponse(params).pipe(
      __map(_r => _r.body as SnapshotDiffResult)
    );
  }

  /**
   * List the snapshots for a given object.
   *
   * List the snapshots for a given object.
   * @param params The `ObjectService.GetObjectSnapshotsParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the Object.
   *
   * - `toTimeUsecs`: Specifies the timestamp in Unix time epoch in microseconds to filter Object's snapshots which were taken before this value.
   *
   * - `snapshotActions`: Specifies a list of recovery actions. Only snapshots that applies to these actions will be returned.
   *
   * - `runTypes`: Filter by run type. Only protection run matching the specified types will be returned. By default, CDP hydration snapshots are not included, unless explicitly queried using this field.
   *
   * - `runStartToTimeUsecs`: Specifies the timestamp in Unix time epoch in microseconds to filter Object's snapshots which were run before this value.
   *
   * - `runStartFromTimeUsecs`: Specifies the timestamp in Unix time epoch in microseconds to filter Object's snapshots which were run after this value.
   *
   * - `runInstanceIds`: Filter by a list run instance ids. If specified, only snapshots created by these protection runs will be returned.
   *
   * - `regionIds`: Filter by a list of region ids.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `protectionGroupIds`: If specified, this returns only the snapshots of the specified object ID, which belong to the provided protection group IDs.
   *
   * - `objectActionKeys`: Filter by ObjectActionKey, which uniquely represents protection of an object. An object can be protected in multiple ways but atmost once for a given combination of ObjectActionKey. When specified, only snapshots matching given action keys are returned for corresponding object.
   *
   * - `fromTimeUsecs`: Specifies the timestamp in Unix time epoch in microseconds to filter Object's snapshots which were taken after this value.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetObjectSnapshotsResponse(params: ObjectService.GetObjectSnapshotsParams): __Observable<__StrictHttpResponse<GetObjectSnapshotsResponseBody>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.toTimeUsecs != null) __params = __params.set('toTimeUsecs', params.toTimeUsecs.toString());
    (params.snapshotActions || []).forEach(val => {if (val != null) __params = __params.append('snapshotActions', val.toString())});
    (params.runTypes || []).forEach(val => {if (val != null) __params = __params.append('runTypes', val.toString())});
    if (params.runStartToTimeUsecs != null) __params = __params.set('runStartToTimeUsecs', params.runStartToTimeUsecs.toString());
    if (params.runStartFromTimeUsecs != null) __params = __params.set('runStartFromTimeUsecs', params.runStartFromTimeUsecs.toString());
    (params.runInstanceIds || []).forEach(val => {if (val != null) __params = __params.append('runInstanceIds', val.toString())});
    (params.regionIds || []).forEach(val => {if (val != null) __params = __params.append('regionIds', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    (params.protectionGroupIds || []).forEach(val => {if (val != null) __params = __params.append('protectionGroupIds', val.toString())});
    (params.objectActionKeys || []).forEach(val => {if (val != null) __params = __params.append('objectActionKeys', val.toString())});
    if (params.fromTimeUsecs != null) __params = __params.set('fromTimeUsecs', params.fromTimeUsecs.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/data-protect/objects/${encodeURIComponent(params.id)}/snapshots`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetObjectSnapshotsResponseBody>;
      })
    );
  }
  /**
   * List the snapshots for a given object.
   *
   * List the snapshots for a given object.
   * @param params The `ObjectService.GetObjectSnapshotsParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the Object.
   *
   * - `toTimeUsecs`: Specifies the timestamp in Unix time epoch in microseconds to filter Object's snapshots which were taken before this value.
   *
   * - `snapshotActions`: Specifies a list of recovery actions. Only snapshots that applies to these actions will be returned.
   *
   * - `runTypes`: Filter by run type. Only protection run matching the specified types will be returned. By default, CDP hydration snapshots are not included, unless explicitly queried using this field.
   *
   * - `runStartToTimeUsecs`: Specifies the timestamp in Unix time epoch in microseconds to filter Object's snapshots which were run before this value.
   *
   * - `runStartFromTimeUsecs`: Specifies the timestamp in Unix time epoch in microseconds to filter Object's snapshots which were run after this value.
   *
   * - `runInstanceIds`: Filter by a list run instance ids. If specified, only snapshots created by these protection runs will be returned.
   *
   * - `regionIds`: Filter by a list of region ids.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `protectionGroupIds`: If specified, this returns only the snapshots of the specified object ID, which belong to the provided protection group IDs.
   *
   * - `objectActionKeys`: Filter by ObjectActionKey, which uniquely represents protection of an object. An object can be protected in multiple ways but atmost once for a given combination of ObjectActionKey. When specified, only snapshots matching given action keys are returned for corresponding object.
   *
   * - `fromTimeUsecs`: Specifies the timestamp in Unix time epoch in microseconds to filter Object's snapshots which were taken after this value.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetObjectSnapshots(params: ObjectService.GetObjectSnapshotsParams): __Observable<GetObjectSnapshotsResponseBody> {
    return this.GetObjectSnapshotsResponse(params).pipe(
      __map(_r => _r.body as GetObjectSnapshotsResponseBody)
    );
  }

  /**
   * Update an object snapshot.
   *
   * Update an object snapshot.
   * @param params The `ObjectService.UpdateObjectSnapshotParams` containing the following parameters:
   *
   * - `snapshotId`: Specifies the id of the snapshot.<br> Note: 1. If the snapshotid of one of the apps is specified, it applies for all the databases in the Protection Run.<br> 2. In case of volume based jobs, please specify the snapshotid of the source not the database. if source snapshot is specified, applied to source snapshot. if database snapshotid is specified in case of volume based jobs, then it is applicable for host's snapshot.
   *
   * - `id`: Specifies the id of the Object.
   *
   * - `body`: Specifies the parameters update an object snapshot.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateObjectSnapshotResponse(params: ObjectService.UpdateObjectSnapshotParams): __Observable<__StrictHttpResponse<ObjectSnapshot>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/data-protect/objects/${encodeURIComponent(params.id)}/snapshots/${encodeURIComponent(params.snapshotId)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ObjectSnapshot>;
      })
    );
  }
  /**
   * Update an object snapshot.
   *
   * Update an object snapshot.
   * @param params The `ObjectService.UpdateObjectSnapshotParams` containing the following parameters:
   *
   * - `snapshotId`: Specifies the id of the snapshot.<br> Note: 1. If the snapshotid of one of the apps is specified, it applies for all the databases in the Protection Run.<br> 2. In case of volume based jobs, please specify the snapshotid of the source not the database. if source snapshot is specified, applied to source snapshot. if database snapshotid is specified in case of volume based jobs, then it is applicable for host's snapshot.
   *
   * - `id`: Specifies the id of the Object.
   *
   * - `body`: Specifies the parameters update an object snapshot.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateObjectSnapshot(params: ObjectService.UpdateObjectSnapshotParams): __Observable<ObjectSnapshot> {
    return this.UpdateObjectSnapshotResponse(params).pipe(
      __map(_r => _r.body as ObjectSnapshot)
    );
  }

  /**
   * Get stats for a given object.
   *
   * Get stats for a given object.
   * @param params The `ObjectService.GetObjectStatsParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the Object.
   *
   * - `regionIds`: Filter by a list of region ids.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetObjectStatsResponse(params: ObjectService.GetObjectStatsParams): __Observable<__StrictHttpResponse<ObjectStats>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    (params.regionIds || []).forEach(val => {if (val != null) __params = __params.append('regionIds', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/data-protect/objects/${encodeURIComponent(params.id)}/stats`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ObjectStats>;
      })
    );
  }
  /**
   * Get stats for a given object.
   *
   * Get stats for a given object.
   * @param params The `ObjectService.GetObjectStatsParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the Object.
   *
   * - `regionIds`: Filter by a list of region ids.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetObjectStats(params: ObjectService.GetObjectStatsParams): __Observable<ObjectStats> {
    return this.GetObjectStatsResponse(params).pipe(
      __map(_r => _r.body as ObjectStats)
    );
  }

  /**
   * Get the objects tree hierarchy for for an Object.
   *
   * Get the objects tree hierarchy for for an Object. If the object does not have a hierarchy then a single object will be returned.
   * @param params The `ObjectService.GetObjectTreeParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the Object.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetObjectTreeResponse(params: ObjectService.GetObjectTreeParams): __Observable<__StrictHttpResponse<ObjectWithChildren>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/data-protect/objects/${encodeURIComponent(params.id)}/tree`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ObjectWithChildren>;
      })
    );
  }
  /**
   * Get the objects tree hierarchy for for an Object.
   *
   * Get the objects tree hierarchy for for an Object. If the object does not have a hierarchy then a single object will be returned.
   * @param params The `ObjectService.GetObjectTreeParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the Object.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetObjectTree(params: ObjectService.GetObjectTreeParams): __Observable<ObjectWithChildren> {
    return this.GetObjectTreeResponse(params).pipe(
      __map(_r => _r.body as ObjectWithChildren)
    );
  }

  /**
   * Get snapshots of indexed object.
   *
   * Get snapshots of indexed object.
   * @param params The `ObjectService.GetAllIndexedObjectSnapshotsParams` containing the following parameters:
   *
   * - `objectId`: Specifies the object id.
   *
   * - `indexedObjectName`: Specifies the indexed object name.
   *
   * - `useCachedData`: Specifies whether we can serve the GET request to the read replica cache. There is a lag of 15 seconds between the read replica and primary data source.
   *
   * - `toTimeUsecs`: Specifies the timestamp in Unix time epoch in microseconds to filter indexed object's snapshots which are taken before this value.
   *
   * - `runTypes`: Filter by run type. Only protection run matching the specified types will be returned. By default, CDP hydration snapshots are not included, unless explicitly queried using this field.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `protectionGroupId`: Specifies the protection group id.
   *
   * - `objectActionKey`: Filter by ObjectActionKey, which uniquely represents backup type for a given version. An object can be protected in multiple ways but atmost once for a given combination of ObjectActionKey and ObjectId. When specified, only versions of given ObjectActionKey are returned for corresponding object id.
   *
   * - `includeIndexedSnapshotsOnly`: Specifies whether to only return snapshots which are indexed. In an indexed snapshot files are guaranteed to exist, while in a non-indexed snapshot files may not exist.
   *
   * - `fromTimeUsecs`: Specifies the timestamp in Unix time epoch in microseconds to filter indexed object's snapshots which are taken after this value.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetAllIndexedObjectSnapshotsResponse(params: ObjectService.GetAllIndexedObjectSnapshotsParams): __Observable<__StrictHttpResponse<GetIndexedObjectSnapshotsResponseBody>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.indexedObjectName != null) __params = __params.set('indexedObjectName', params.indexedObjectName.toString());
    if (params.useCachedData != null) __params = __params.set('useCachedData', params.useCachedData.toString());
    if (params.toTimeUsecs != null) __params = __params.set('toTimeUsecs', params.toTimeUsecs.toString());
    (params.runTypes || []).forEach(val => {if (val != null) __params = __params.append('runTypes', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.protectionGroupId != null) __params = __params.set('protectionGroupId', params.protectionGroupId.toString());
    if (params.objectActionKey != null) __params = __params.set('objectActionKey', params.objectActionKey.toString());
    if (params.includeIndexedSnapshotsOnly != null) __params = __params.set('includeIndexedSnapshotsOnly', params.includeIndexedSnapshotsOnly.toString());
    if (params.fromTimeUsecs != null) __params = __params.set('fromTimeUsecs', params.fromTimeUsecs.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/data-protect/objects/${encodeURIComponent(params.objectId)}/indexed-objects/snapshots`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetIndexedObjectSnapshotsResponseBody>;
      })
    );
  }
  /**
   * Get snapshots of indexed object.
   *
   * Get snapshots of indexed object.
   * @param params The `ObjectService.GetAllIndexedObjectSnapshotsParams` containing the following parameters:
   *
   * - `objectId`: Specifies the object id.
   *
   * - `indexedObjectName`: Specifies the indexed object name.
   *
   * - `useCachedData`: Specifies whether we can serve the GET request to the read replica cache. There is a lag of 15 seconds between the read replica and primary data source.
   *
   * - `toTimeUsecs`: Specifies the timestamp in Unix time epoch in microseconds to filter indexed object's snapshots which are taken before this value.
   *
   * - `runTypes`: Filter by run type. Only protection run matching the specified types will be returned. By default, CDP hydration snapshots are not included, unless explicitly queried using this field.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `protectionGroupId`: Specifies the protection group id.
   *
   * - `objectActionKey`: Filter by ObjectActionKey, which uniquely represents backup type for a given version. An object can be protected in multiple ways but atmost once for a given combination of ObjectActionKey and ObjectId. When specified, only versions of given ObjectActionKey are returned for corresponding object id.
   *
   * - `includeIndexedSnapshotsOnly`: Specifies whether to only return snapshots which are indexed. In an indexed snapshot files are guaranteed to exist, while in a non-indexed snapshot files may not exist.
   *
   * - `fromTimeUsecs`: Specifies the timestamp in Unix time epoch in microseconds to filter indexed object's snapshots which are taken after this value.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetAllIndexedObjectSnapshots(params: ObjectService.GetAllIndexedObjectSnapshotsParams): __Observable<GetIndexedObjectSnapshotsResponseBody> {
    return this.GetAllIndexedObjectSnapshotsResponse(params).pipe(
      __map(_r => _r.body as GetIndexedObjectSnapshotsResponseBody)
    );
  }

  /**
   * Get snapshots of indexed object.
   *
   * Get snapshots of indexed object.
   * @param params The `ObjectService.GetIndexedObjectSnapshotsParams` containing the following parameters:
   *
   * - `protectionGroupId`: Specifies the protection group id.
   *
   * - `objectId`: Specifies the object id.
   *
   * - `indexedObjectName`: Specifies the indexed object name.
   *
   * - `useCachedData`: Specifies whether we can serve the GET request to the read replica cache. There is a lag of 15 seconds between the read replica and primary data source.
   *
   * - `toTimeUsecs`: Specifies the timestamp in Unix time epoch in microseconds to filter indexed object's snapshots which are taken before this value.
   *
   * - `runTypes`: Filter by run type. Only protection run matching the specified types will be returned. By default, CDP hydration snapshots are not included, unless explicitly queried using this field.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `objectActionKey`: Filter by ObjectActionKey, which uniquely represents backup type for a given version. An object can be protected in multiple ways but atmost once for a given combination of ObjectActionKey and ObjectId. When specified, only versions of given ObjectActionKey are returned for corresponding object id.
   *
   * - `includeIndexedSnapshotsOnly`: Specifies whether to only return snapshots which are indexed. In an indexed snapshots file are guaranteed to exist, while in a non-indexed snapshots file may not exist.
   *
   * - `fromTimeUsecs`: Specifies the timestamp in Unix time epoch in microseconds to filter indexed object's snapshots which are taken after this value.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetIndexedObjectSnapshotsResponse(params: ObjectService.GetIndexedObjectSnapshotsParams): __Observable<__StrictHttpResponse<GetIndexedObjectSnapshotsResponseBody>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.indexedObjectName != null) __params = __params.set('indexedObjectName', params.indexedObjectName.toString());
    if (params.useCachedData != null) __params = __params.set('useCachedData', params.useCachedData.toString());
    if (params.toTimeUsecs != null) __params = __params.set('toTimeUsecs', params.toTimeUsecs.toString());
    (params.runTypes || []).forEach(val => {if (val != null) __params = __params.append('runTypes', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.objectActionKey != null) __params = __params.set('objectActionKey', params.objectActionKey.toString());
    if (params.includeIndexedSnapshotsOnly != null) __params = __params.set('includeIndexedSnapshotsOnly', params.includeIndexedSnapshotsOnly.toString());
    if (params.fromTimeUsecs != null) __params = __params.set('fromTimeUsecs', params.fromTimeUsecs.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/data-protect/objects/${encodeURIComponent(params.objectId)}/protection-groups/${encodeURIComponent(params.protectionGroupId)}/indexed-objects/snapshots`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetIndexedObjectSnapshotsResponseBody>;
      })
    );
  }
  /**
   * Get snapshots of indexed object.
   *
   * Get snapshots of indexed object.
   * @param params The `ObjectService.GetIndexedObjectSnapshotsParams` containing the following parameters:
   *
   * - `protectionGroupId`: Specifies the protection group id.
   *
   * - `objectId`: Specifies the object id.
   *
   * - `indexedObjectName`: Specifies the indexed object name.
   *
   * - `useCachedData`: Specifies whether we can serve the GET request to the read replica cache. There is a lag of 15 seconds between the read replica and primary data source.
   *
   * - `toTimeUsecs`: Specifies the timestamp in Unix time epoch in microseconds to filter indexed object's snapshots which are taken before this value.
   *
   * - `runTypes`: Filter by run type. Only protection run matching the specified types will be returned. By default, CDP hydration snapshots are not included, unless explicitly queried using this field.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `objectActionKey`: Filter by ObjectActionKey, which uniquely represents backup type for a given version. An object can be protected in multiple ways but atmost once for a given combination of ObjectActionKey and ObjectId. When specified, only versions of given ObjectActionKey are returned for corresponding object id.
   *
   * - `includeIndexedSnapshotsOnly`: Specifies whether to only return snapshots which are indexed. In an indexed snapshots file are guaranteed to exist, while in a non-indexed snapshots file may not exist.
   *
   * - `fromTimeUsecs`: Specifies the timestamp in Unix time epoch in microseconds to filter indexed object's snapshots which are taken after this value.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetIndexedObjectSnapshots(params: ObjectService.GetIndexedObjectSnapshotsParams): __Observable<GetIndexedObjectSnapshotsResponseBody> {
    return this.GetIndexedObjectSnapshotsResponse(params).pipe(
      __map(_r => _r.body as GetIndexedObjectSnapshotsResponseBody)
    );
  }

  /**
   * Get snapshot id for a given object.
   *
   * Get snapshot id for object for given params.
   * @param params The `ObjectService.GetObjectSnapshotIdParams` containing the following parameters:
   *
   * - `objectId`: Specifies the object id.
   *
   * - `body`: Specifies the parameters to fetch snapshot id for object.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetObjectSnapshotIdResponse(params: ObjectService.GetObjectSnapshotIdParams): __Observable<__StrictHttpResponse<ObjectSnapshotIdResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/data-protect/objects/${encodeURIComponent(params.objectId)}/snapshotId`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ObjectSnapshotIdResult>;
      })
    );
  }
  /**
   * Get snapshot id for a given object.
   *
   * Get snapshot id for object for given params.
   * @param params The `ObjectService.GetObjectSnapshotIdParams` containing the following parameters:
   *
   * - `objectId`: Specifies the object id.
   *
   * - `body`: Specifies the parameters to fetch snapshot id for object.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetObjectSnapshotId(params: ObjectService.GetObjectSnapshotIdParams): __Observable<ObjectSnapshotIdResult> {
    return this.GetObjectSnapshotIdResponse(params).pipe(
      __map(_r => _r.body as ObjectSnapshotIdResult)
    );
  }

  /**
   * Get Metadata of Entities
   *
   * Gets entity metadata for entities. This can be used as a input for the PUT API.
   * @param params The `ObjectService.GetEntityMetadataParams` containing the following parameters:
   *
   * - `sourceId`: Specifies the source ID for which objects should be returned.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `entityIds`: EntityIds contains ids of the entities for which objects are to be returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetEntityMetadataResponse(params: ObjectService.GetEntityMetadataParams): __Observable<__StrictHttpResponse<GetEntityMetadataResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    (params.entityIds || []).forEach(val => {if (val != null) __params = __params.append('entityIds', val.toString())});
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/data-protect/objects/${encodeURIComponent(params.sourceId)}/metadata`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetEntityMetadataResult>;
      })
    );
  }
  /**
   * Get Metadata of Entities
   *
   * Gets entity metadata for entities. This can be used as a input for the PUT API.
   * @param params The `ObjectService.GetEntityMetadataParams` containing the following parameters:
   *
   * - `sourceId`: Specifies the source ID for which objects should be returned.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `entityIds`: EntityIds contains ids of the entities for which objects are to be returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetEntityMetadata(params: ObjectService.GetEntityMetadataParams): __Observable<GetEntityMetadataResult> {
    return this.GetEntityMetadataResponse(params).pipe(
      __map(_r => _r.body as GetEntityMetadataResult)
    );
  }

  /**
   * Get details of object snapshot.
   *
   * Get details of object snapshot.
   * @param params The `ObjectService.GetObjectSnapshotInfoParams` containing the following parameters:
   *
   * - `snapshotId`: Specifies the snapshot id.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetObjectSnapshotInfoResponse(params: ObjectService.GetObjectSnapshotInfoParams): __Observable<__StrictHttpResponse<ObjectSnapshot>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/data-protect/snapshots/${encodeURIComponent(params.snapshotId)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ObjectSnapshot>;
      })
    );
  }
  /**
   * Get details of object snapshot.
   *
   * Get details of object snapshot.
   * @param params The `ObjectService.GetObjectSnapshotInfoParams` containing the following parameters:
   *
   * - `snapshotId`: Specifies the snapshot id.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetObjectSnapshotInfo(params: ObjectService.GetObjectSnapshotInfoParams): __Observable<ObjectSnapshot> {
    return this.GetObjectSnapshotInfoResponse(params).pipe(
      __map(_r => _r.body as ObjectSnapshot)
    );
  }

  /**
   * Construct meta info for any workflow from object snapshot and some other information.
   *
   * Construct meta info from object snapshot and some additional params.
   * @param params The `ObjectService.ConstructMetaInfoParams` containing the following parameters:
   *
   * - `snapshotId`: Specifies the snapshot id.
   *
   * - `body`: Specifies the parameters to construct meta info for desired workflow.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  ConstructMetaInfoResponse(params: ObjectService.ConstructMetaInfoParams): __Observable<__StrictHttpResponse<ConstructMetaInfoResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/data-protect/snapshots/${encodeURIComponent(params.snapshotId)}/meta-info`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ConstructMetaInfoResult>;
      })
    );
  }
  /**
   * Construct meta info for any workflow from object snapshot and some other information.
   *
   * Construct meta info from object snapshot and some additional params.
   * @param params The `ObjectService.ConstructMetaInfoParams` containing the following parameters:
   *
   * - `snapshotId`: Specifies the snapshot id.
   *
   * - `body`: Specifies the parameters to construct meta info for desired workflow.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  ConstructMetaInfo(params: ObjectService.ConstructMetaInfoParams): __Observable<ConstructMetaInfoResult> {
    return this.ConstructMetaInfoResponse(params).pipe(
      __map(_r => _r.body as ConstructMetaInfoResult)
    );
  }

  /**
   * Construct meta info for any workflow from object snapshot and some other information.
   *
   * Construct meta info from object snapshot and some additional params.
   * @param params The `ObjectService.InternalApiConstructMetaInfoParams` containing the following parameters:
   *
   * - `snapshotId`: Specifies the snapshot id.
   *
   * - `body`: Specifies the parameters to construct meta info for desired workflow.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  InternalApiConstructMetaInfoResponse(params: ObjectService.InternalApiConstructMetaInfoParams): __Observable<__StrictHttpResponse<ConstructMetaInfoResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/data-protect/snapshots/${encodeURIComponent(params.snapshotId)}/metaInfo`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ConstructMetaInfoResult>;
      })
    );
  }
  /**
   * Construct meta info for any workflow from object snapshot and some other information.
   *
   * Construct meta info from object snapshot and some additional params.
   * @param params The `ObjectService.InternalApiConstructMetaInfoParams` containing the following parameters:
   *
   * - `snapshotId`: Specifies the snapshot id.
   *
   * - `body`: Specifies the parameters to construct meta info for desired workflow.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  InternalApiConstructMetaInfo(params: ObjectService.InternalApiConstructMetaInfoParams): __Observable<ConstructMetaInfoResult> {
    return this.InternalApiConstructMetaInfoResponse(params).pipe(
      __map(_r => _r.body as ConstructMetaInfoResult)
    );
  }

  /**
   * Get volume info of object snapshot.
   *
   * Get volume info of object snapshot.
   * @param params The `ObjectService.GetObjectSnapshotVolumeInfoParams` containing the following parameters:
   *
   * - `snapshotId`: Specifies the snapshot id.
   *
   * - `useCachedData`: Specifies whether we can serve the GET request to the read replica cache. There is a lag of 15 seconds between the read replica and primary data source.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `pointInTimeUsecs`: Specifies the point-in-time timestamp (in usecs from epoch) between snapshots for which the volume info is to be returned.
   *
   * - `includeSupportedOnly`: Specifies whether to only return supported volumes.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetObjectSnapshotVolumeInfoResponse(params: ObjectService.GetObjectSnapshotVolumeInfoParams): __Observable<__StrictHttpResponse<ObjectSnapshotVolumeInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.useCachedData != null) __params = __params.set('useCachedData', params.useCachedData.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.pointInTimeUsecs != null) __params = __params.set('pointInTimeUsecs', params.pointInTimeUsecs.toString());
    if (params.includeSupportedOnly != null) __params = __params.set('includeSupportedOnly', params.includeSupportedOnly.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/data-protect/snapshots/${encodeURIComponent(params.snapshotId)}/volume`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ObjectSnapshotVolumeInfo>;
      })
    );
  }
  /**
   * Get volume info of object snapshot.
   *
   * Get volume info of object snapshot.
   * @param params The `ObjectService.GetObjectSnapshotVolumeInfoParams` containing the following parameters:
   *
   * - `snapshotId`: Specifies the snapshot id.
   *
   * - `useCachedData`: Specifies whether we can serve the GET request to the read replica cache. There is a lag of 15 seconds between the read replica and primary data source.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `pointInTimeUsecs`: Specifies the point-in-time timestamp (in usecs from epoch) between snapshots for which the volume info is to be returned.
   *
   * - `includeSupportedOnly`: Specifies whether to only return supported volumes.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetObjectSnapshotVolumeInfo(params: ObjectService.GetObjectSnapshotVolumeInfoParams): __Observable<ObjectSnapshotVolumeInfo> {
    return this.GetObjectSnapshotVolumeInfoResponse(params).pipe(
      __map(_r => _r.body as ObjectSnapshotVolumeInfo)
    );
  }

  /**
   * List deleted protected objects.
   *
   * List of objects which are deleted but protected by object protection.
   * @param params The `ObjectService.GetDeletedProtectedObjectsParams` containing the following parameters:
   *
   * - `sourceId`: Specifies the source ID for which objects should be returned.
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which objects are to be returned.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `maxCount`: Specifies the max number of objects to return.
   *
   * - `includeTenants`: If true, the response will include objects which were protected by all tenants which the current user has permission to see. If false, then only objects protected by the current user will be returned.
   *
   * - `cookie`: Specifies the pagination cookie.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetDeletedProtectedObjectsResponse(params: ObjectService.GetDeletedProtectedObjectsParams): __Observable<__StrictHttpResponse<DeletedProtectedObjectsResponseBody>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    (params.tenantIds || []).forEach(val => {if (val != null) __params = __params.append('tenantIds', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.maxCount != null) __params = __params.set('maxCount', params.maxCount.toString());
    if (params.includeTenants != null) __params = __params.set('includeTenants', params.includeTenants.toString());
    if (params.cookie != null) __params = __params.set('cookie', params.cookie.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/data-protect/sources/${encodeURIComponent(params.sourceId)}/deleted-protected-objects`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DeletedProtectedObjectsResponseBody>;
      })
    );
  }
  /**
   * List deleted protected objects.
   *
   * List of objects which are deleted but protected by object protection.
   * @param params The `ObjectService.GetDeletedProtectedObjectsParams` containing the following parameters:
   *
   * - `sourceId`: Specifies the source ID for which objects should be returned.
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which objects are to be returned.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `maxCount`: Specifies the max number of objects to return.
   *
   * - `includeTenants`: If true, the response will include objects which were protected by all tenants which the current user has permission to see. If false, then only objects protected by the current user will be returned.
   *
   * - `cookie`: Specifies the pagination cookie.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetDeletedProtectedObjects(params: ObjectService.GetDeletedProtectedObjectsParams): __Observable<DeletedProtectedObjectsResponseBody> {
    return this.GetDeletedProtectedObjectsResponse(params).pipe(
      __map(_r => _r.body as DeletedProtectedObjectsResponseBody)
    );
  }

  /**
   * List objects on a source which can be used for data protection.
   *
   * List objects which can be used for data protection.
   * @param params The `ObjectService.GetSourceHierarchyObjectsParams` containing the following parameters:
   *
   * - `sourceId`: Specifies the source ID for which objects should be returned.
   *
   * - `vmwareObjectTypes`: Specifies the VMware object types to filter objects.
   *
   * - `useCachedData`: Specifies whether we can serve the GET request to the read replica cache. There is a lag of 15 seconds between the read replica and primary data source.
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which objects are to be returned.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `pureObjectTypes`: Specifies the Pure object types to filter objects.
   *
   * - `physicalObjectTypes`: Specifies the Physical object types to filter objects.
   *
   * - `parentId`: Specifies the parent ID under which objects should be returned.
   *
   * - `oracleObjectTypes`: Specifies the Oracle object types to filter objects.
   *
   * - `o365ObjectTypes`: Specifies the Office 365 object types to filter objects.
   *
   * - `nimbleObjectTypes`: Specifies the Nimble object types to filter objects.
   *
   * - `netappObjectTypes`: Specifies the Netapp object types to filter objects.
   *
   * - `mssqlObjectTypes`: Specifies the MSSQL object types to filter objects.
   *
   * - `mongodbObjectTypes`: Specifies the Mongo DB object types to filter objects.
   *
   * - `kvmObjectTypes`: Specifies the KVM object types to filter objects.
   *
   * - `kubernetesObjectTypes`: Specifies the Kubernetes object types to filter objects.
   *
   * - `isilonObjectTypes`: Specifies the Isilon object types to filter objects.
   *
   * - `includeTenants`: If true, the response will include Objects which belongs to all tenants which the current user has permission to see.
   *
   * - `hypervObjectTypes`: Specifies the HyperV object types to filter objects.
   *
   * - `hiveObjectTypes`: Specifies the Hive object types to filter objects.
   *
   * - `hdfsObjectTypes`: Specifies the HDFS object types to filter objects.
   *
   * - `hbaseObjectTypes`: Specifies the Hbase object types to filter objects.
   *
   * - `gpfsObjectTypes`: Specifies the GPFS object types to filter objects.
   *
   * - `genericNasObjectTypes`: Specifies the generic NAS object types to filter objects.
   *
   * - `gcpObjectTypes`: Specifies the GCP object types to filter objects.
   *
   * - `flashbladeObjectTypes`: Specifies the Flashblade object types to filter objects.
   *
   * - `exchangeObjectTypes`: Specifies the Exchange object types to filter objects.
   *
   * - `elastifileObjectTypes`: Specifies the Elastifile object types to filter objects.
   *
   * - `couchbaseObjectTypes`: Specifies the Couchbase object types to filter objects.
   *
   * - `cassandraObjectTypes`: Specifies the Cassandra object types to filter objects.
   *
   * - `azureObjectTypes`: Specifies the Azure object types to filter objects.
   *
   * - `awsObjectTypes`: Specifies the AWS object types to filter objects.
   *
   * - `adObjectTypes`: Specifies the AD object types to filter objects.
   *
   * - `acropolisObjectTypes`: Specifies the Acropolis object types to filter objects.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetSourceHierarchyObjectsResponse(params: ObjectService.GetSourceHierarchyObjectsParams): __Observable<__StrictHttpResponse<SourceHierarchyObjectSummaries>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    (params.vmwareObjectTypes || []).forEach(val => {if (val != null) __params = __params.append('vmwareObjectTypes', val.toString())});
    if (params.useCachedData != null) __params = __params.set('useCachedData', params.useCachedData.toString());
    (params.tenantIds || []).forEach(val => {if (val != null) __params = __params.append('tenantIds', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    (params.pureObjectTypes || []).forEach(val => {if (val != null) __params = __params.append('pureObjectTypes', val.toString())});
    (params.physicalObjectTypes || []).forEach(val => {if (val != null) __params = __params.append('physicalObjectTypes', val.toString())});
    if (params.parentId != null) __params = __params.set('parentId', params.parentId.toString());
    (params.oracleObjectTypes || []).forEach(val => {if (val != null) __params = __params.append('oracleObjectTypes', val.toString())});
    (params.o365ObjectTypes || []).forEach(val => {if (val != null) __params = __params.append('o365ObjectTypes', val.toString())});
    (params.nimbleObjectTypes || []).forEach(val => {if (val != null) __params = __params.append('nimbleObjectTypes', val.toString())});
    (params.netappObjectTypes || []).forEach(val => {if (val != null) __params = __params.append('netappObjectTypes', val.toString())});
    (params.mssqlObjectTypes || []).forEach(val => {if (val != null) __params = __params.append('mssqlObjectTypes', val.toString())});
    (params.mongodbObjectTypes || []).forEach(val => {if (val != null) __params = __params.append('mongodbObjectTypes', val.toString())});
    (params.kvmObjectTypes || []).forEach(val => {if (val != null) __params = __params.append('kvmObjectTypes', val.toString())});
    (params.kubernetesObjectTypes || []).forEach(val => {if (val != null) __params = __params.append('kubernetesObjectTypes', val.toString())});
    (params.isilonObjectTypes || []).forEach(val => {if (val != null) __params = __params.append('isilonObjectTypes', val.toString())});
    if (params.includeTenants != null) __params = __params.set('includeTenants', params.includeTenants.toString());
    (params.hypervObjectTypes || []).forEach(val => {if (val != null) __params = __params.append('hypervObjectTypes', val.toString())});
    (params.hiveObjectTypes || []).forEach(val => {if (val != null) __params = __params.append('hiveObjectTypes', val.toString())});
    (params.hdfsObjectTypes || []).forEach(val => {if (val != null) __params = __params.append('hdfsObjectTypes', val.toString())});
    (params.hbaseObjectTypes || []).forEach(val => {if (val != null) __params = __params.append('hbaseObjectTypes', val.toString())});
    (params.gpfsObjectTypes || []).forEach(val => {if (val != null) __params = __params.append('gpfsObjectTypes', val.toString())});
    (params.genericNasObjectTypes || []).forEach(val => {if (val != null) __params = __params.append('genericNasObjectTypes', val.toString())});
    (params.gcpObjectTypes || []).forEach(val => {if (val != null) __params = __params.append('gcpObjectTypes', val.toString())});
    (params.flashbladeObjectTypes || []).forEach(val => {if (val != null) __params = __params.append('flashbladeObjectTypes', val.toString())});
    (params.exchangeObjectTypes || []).forEach(val => {if (val != null) __params = __params.append('exchangeObjectTypes', val.toString())});
    (params.elastifileObjectTypes || []).forEach(val => {if (val != null) __params = __params.append('elastifileObjectTypes', val.toString())});
    (params.couchbaseObjectTypes || []).forEach(val => {if (val != null) __params = __params.append('couchbaseObjectTypes', val.toString())});
    (params.cassandraObjectTypes || []).forEach(val => {if (val != null) __params = __params.append('cassandraObjectTypes', val.toString())});
    (params.azureObjectTypes || []).forEach(val => {if (val != null) __params = __params.append('azureObjectTypes', val.toString())});
    (params.awsObjectTypes || []).forEach(val => {if (val != null) __params = __params.append('awsObjectTypes', val.toString())});
    (params.adObjectTypes || []).forEach(val => {if (val != null) __params = __params.append('adObjectTypes', val.toString())});
    (params.acropolisObjectTypes || []).forEach(val => {if (val != null) __params = __params.append('acropolisObjectTypes', val.toString())});
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/data-protect/sources/${encodeURIComponent(params.sourceId)}/objects`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SourceHierarchyObjectSummaries>;
      })
    );
  }
  /**
   * List objects on a source which can be used for data protection.
   *
   * List objects which can be used for data protection.
   * @param params The `ObjectService.GetSourceHierarchyObjectsParams` containing the following parameters:
   *
   * - `sourceId`: Specifies the source ID for which objects should be returned.
   *
   * - `vmwareObjectTypes`: Specifies the VMware object types to filter objects.
   *
   * - `useCachedData`: Specifies whether we can serve the GET request to the read replica cache. There is a lag of 15 seconds between the read replica and primary data source.
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which objects are to be returned.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `pureObjectTypes`: Specifies the Pure object types to filter objects.
   *
   * - `physicalObjectTypes`: Specifies the Physical object types to filter objects.
   *
   * - `parentId`: Specifies the parent ID under which objects should be returned.
   *
   * - `oracleObjectTypes`: Specifies the Oracle object types to filter objects.
   *
   * - `o365ObjectTypes`: Specifies the Office 365 object types to filter objects.
   *
   * - `nimbleObjectTypes`: Specifies the Nimble object types to filter objects.
   *
   * - `netappObjectTypes`: Specifies the Netapp object types to filter objects.
   *
   * - `mssqlObjectTypes`: Specifies the MSSQL object types to filter objects.
   *
   * - `mongodbObjectTypes`: Specifies the Mongo DB object types to filter objects.
   *
   * - `kvmObjectTypes`: Specifies the KVM object types to filter objects.
   *
   * - `kubernetesObjectTypes`: Specifies the Kubernetes object types to filter objects.
   *
   * - `isilonObjectTypes`: Specifies the Isilon object types to filter objects.
   *
   * - `includeTenants`: If true, the response will include Objects which belongs to all tenants which the current user has permission to see.
   *
   * - `hypervObjectTypes`: Specifies the HyperV object types to filter objects.
   *
   * - `hiveObjectTypes`: Specifies the Hive object types to filter objects.
   *
   * - `hdfsObjectTypes`: Specifies the HDFS object types to filter objects.
   *
   * - `hbaseObjectTypes`: Specifies the Hbase object types to filter objects.
   *
   * - `gpfsObjectTypes`: Specifies the GPFS object types to filter objects.
   *
   * - `genericNasObjectTypes`: Specifies the generic NAS object types to filter objects.
   *
   * - `gcpObjectTypes`: Specifies the GCP object types to filter objects.
   *
   * - `flashbladeObjectTypes`: Specifies the Flashblade object types to filter objects.
   *
   * - `exchangeObjectTypes`: Specifies the Exchange object types to filter objects.
   *
   * - `elastifileObjectTypes`: Specifies the Elastifile object types to filter objects.
   *
   * - `couchbaseObjectTypes`: Specifies the Couchbase object types to filter objects.
   *
   * - `cassandraObjectTypes`: Specifies the Cassandra object types to filter objects.
   *
   * - `azureObjectTypes`: Specifies the Azure object types to filter objects.
   *
   * - `awsObjectTypes`: Specifies the AWS object types to filter objects.
   *
   * - `adObjectTypes`: Specifies the AD object types to filter objects.
   *
   * - `acropolisObjectTypes`: Specifies the Acropolis object types to filter objects.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetSourceHierarchyObjects(params: ObjectService.GetSourceHierarchyObjectsParams): __Observable<SourceHierarchyObjectSummaries> {
    return this.GetSourceHierarchyObjectsResponse(params).pipe(
      __map(_r => _r.body as SourceHierarchyObjectSummaries)
    );
  }

  /**
   * Get Object activity on Helios.
   *
   * Get object activity on Helios. Activity includes Protection Group Runs and Recoveries.
   * @param params The `ObjectService.GetMcmObjectsActivityParams` containing the following parameters:
   *
   * - `regionIds`: Filter by a list of region ids.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `body`: Request parameters to filter object activity.
   *
   * @return Success
   */
  GetMcmObjectsActivityResponse(params: ObjectService.GetMcmObjectsActivityParams): __Observable<__StrictHttpResponse<McmObjectsActivity>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.regionIds || []).forEach(val => {if (val != null) __params = __params.append('regionIds', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/mcm/data-protect/objects/activity`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<McmObjectsActivity>;
      })
    );
  }
  /**
   * Get Object activity on Helios.
   *
   * Get object activity on Helios. Activity includes Protection Group Runs and Recoveries.
   * @param params The `ObjectService.GetMcmObjectsActivityParams` containing the following parameters:
   *
   * - `regionIds`: Filter by a list of region ids.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `body`: Request parameters to filter object activity.
   *
   * @return Success
   */
  GetMcmObjectsActivity(params: ObjectService.GetMcmObjectsActivityParams): __Observable<McmObjectsActivity> {
    return this.GetMcmObjectsActivityResponse(params).pipe(
      __map(_r => _r.body as McmObjectsActivity)
    );
  }

  /**
   * Get Object activity on Helios.
   *
   * Get object activity on Helios by ID. Activity includes Protection Group Runs and Recoveries.
   * @param id Specifies the id of the Activity.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  GetMcmObjectsActivityByIdResponse(id: string,
    regionId?: string): __Observable<__StrictHttpResponse<McmObjectActivity>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/mcm/data-protect/objects/activity/${encodeURIComponent(id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<McmObjectActivity>;
      })
    );
  }
  /**
   * Get Object activity on Helios.
   *
   * Get object activity on Helios by ID. Activity includes Protection Group Runs and Recoveries.
   * @param id Specifies the id of the Activity.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  GetMcmObjectsActivityById(id: string,
    regionId?: string): __Observable<McmObjectActivity> {
    return this.GetMcmObjectsActivityByIdResponse(id, regionId).pipe(
      __map(_r => _r.body as McmObjectActivity)
    );
  }

  /**
   * Get last protection run of objects.
   *
   * Get last protection run activity of objects.
   * @param params The `ObjectService.GetMcmObjectsLastRunActivityParams` containing the following parameters:
   *
   * - `regionIds`: Specifies a list of region ids. Only records from clusters having these region ids will be returned.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `clusterIdentifiers`: Specifies the list of cluster identifiers. Format is clusterId:clusterIncarnationId. Only records from clusters having these identifiers will be returned.
   *
   * - `body`: Request parameters to filter object last runs.
   *
   * @return Success
   */
  GetMcmObjectsLastRunActivityResponse(params: ObjectService.GetMcmObjectsLastRunActivityParams): __Observable<__StrictHttpResponse<McmObjectLastRunActivities>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.regionIds || []).forEach(val => {if (val != null) __params = __params.append('regionIds', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    (params.clusterIdentifiers || []).forEach(val => {if (val != null) __params = __params.append('clusterIdentifiers', val.toString())});
    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/mcm/data-protect/objects/last-run-activity`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<McmObjectLastRunActivities>;
      })
    );
  }
  /**
   * Get last protection run of objects.
   *
   * Get last protection run activity of objects.
   * @param params The `ObjectService.GetMcmObjectsLastRunActivityParams` containing the following parameters:
   *
   * - `regionIds`: Specifies a list of region ids. Only records from clusters having these region ids will be returned.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `clusterIdentifiers`: Specifies the list of cluster identifiers. Format is clusterId:clusterIncarnationId. Only records from clusters having these identifiers will be returned.
   *
   * - `body`: Request parameters to filter object last runs.
   *
   * @return Success
   */
  GetMcmObjectsLastRunActivity(params: ObjectService.GetMcmObjectsLastRunActivityParams): __Observable<McmObjectLastRunActivities> {
    return this.GetMcmObjectsLastRunActivityResponse(params).pipe(
      __map(_r => _r.body as McmObjectLastRunActivities)
    );
  }

  /**
   * Update multiple runs of one or more objects.
   *
   * Update metadata of multiple runs of one or more objects. Currently we support enabling the legal hold on the runs as the action in this put request.
   * @param body Specifies the parameters to update the legal hold and other (future) parameters of one or more runs of one or more objects.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   */
  UpdateObjectsRunsMetadataResponse(body: UpdateObjectsRunsMetadataParams,
    regionId?: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/mcm/data-protect/objects/runs/metadata`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Update multiple runs of one or more objects.
   *
   * Update metadata of multiple runs of one or more objects. Currently we support enabling the legal hold on the runs as the action in this put request.
   * @param body Specifies the parameters to update the legal hold and other (future) parameters of one or more runs of one or more objects.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   */
  UpdateObjectsRunsMetadata(body: UpdateObjectsRunsMetadataParams,
    regionId?: string): __Observable<null> {
    return this.UpdateObjectsRunsMetadataResponse(body, regionId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * List the snapshots for a given object.
   *
   * List the snapshots for a given object.
   * @param params The `ObjectService.GetMcmObjectSnapshotsParams` containing the following parameters:
   *
   * - `globalId`: Specifies the global id of the Object.
   *
   * - `uuid`: Specifies the uuid of the Object. This field is deprecated.
   *
   * - `toTimeUsecs`: Specifies the timestamp in Unix time epoch in microseconds to filter Object's snapshots which are taken before this value.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `fromTimeUsecs`: Specifies the timestamp in Unix time epoch in microseconds to filter Object's snapshots which are taken after this value.
   *
   * @return Success
   */
  GetMcmObjectSnapshotsResponse(params: ObjectService.GetMcmObjectSnapshotsParams): __Observable<__StrictHttpResponse<GetObjectSnapshotsResponseBody>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.globalId != null) __params = __params.set('globalId', params.globalId.toString());
    if (params.uuid != null) __params = __params.set('uuid', params.uuid.toString());
    if (params.toTimeUsecs != null) __params = __params.set('toTimeUsecs', params.toTimeUsecs.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.fromTimeUsecs != null) __params = __params.set('fromTimeUsecs', params.fromTimeUsecs.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/mcm/data-protect/objects/snapshots`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetObjectSnapshotsResponseBody>;
      })
    );
  }
  /**
   * List the snapshots for a given object.
   *
   * List the snapshots for a given object.
   * @param params The `ObjectService.GetMcmObjectSnapshotsParams` containing the following parameters:
   *
   * - `globalId`: Specifies the global id of the Object.
   *
   * - `uuid`: Specifies the uuid of the Object. This field is deprecated.
   *
   * - `toTimeUsecs`: Specifies the timestamp in Unix time epoch in microseconds to filter Object's snapshots which are taken before this value.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `fromTimeUsecs`: Specifies the timestamp in Unix time epoch in microseconds to filter Object's snapshots which are taken after this value.
   *
   * @return Success
   */
  GetMcmObjectSnapshots(params: ObjectService.GetMcmObjectSnapshotsParams): __Observable<GetObjectSnapshotsResponseBody> {
    return this.GetMcmObjectSnapshotsResponse(params).pipe(
      __map(_r => _r.body as GetObjectSnapshotsResponseBody)
    );
  }

  /**
   * Get stats for a given object.
   *
   * Get stats for a given object.
   * @param params The `ObjectService.GetMcmObjectStatsParams` containing the following parameters:
   *
   * - `globalId`: Specifies the global id of the Object. This field is required because we only fetches snapshots stats for one object.
   *
   * - `regionIds`: Specifies a list of region ids. Only records from clusters having these region ids will be returned.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `entityHash`: Specifies the entity hash global id of the Object. This field is deprecated.
   *
   * - `clusterIdentifiers`: Specifies the list of cluster identifiers. Format is clusterId:clusterIncarnationId. Only records from clusters having these identifiers will be returned.
   *
   * @return Success
   */
  GetMcmObjectStatsResponse(params: ObjectService.GetMcmObjectStatsParams): __Observable<__StrictHttpResponse<ObjectStats>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.globalId != null) __params = __params.set('globalId', params.globalId.toString());
    (params.regionIds || []).forEach(val => {if (val != null) __params = __params.append('regionIds', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.entityHash != null) __params = __params.set('entityHash', params.entityHash.toString());
    (params.clusterIdentifiers || []).forEach(val => {if (val != null) __params = __params.append('clusterIdentifiers', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/mcm/data-protect/objects/stats`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ObjectStats>;
      })
    );
  }
  /**
   * Get stats for a given object.
   *
   * Get stats for a given object.
   * @param params The `ObjectService.GetMcmObjectStatsParams` containing the following parameters:
   *
   * - `globalId`: Specifies the global id of the Object. This field is required because we only fetches snapshots stats for one object.
   *
   * - `regionIds`: Specifies a list of region ids. Only records from clusters having these region ids will be returned.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `entityHash`: Specifies the entity hash global id of the Object. This field is deprecated.
   *
   * - `clusterIdentifiers`: Specifies the list of cluster identifiers. Format is clusterId:clusterIncarnationId. Only records from clusters having these identifiers will be returned.
   *
   * @return Success
   */
  GetMcmObjectStats(params: ObjectService.GetMcmObjectStatsParams): __Observable<ObjectStats> {
    return this.GetMcmObjectStatsResponse(params).pipe(
      __map(_r => _r.body as ObjectStats)
    );
  }

  /**
   * Get the object archival run stats.
   *
   * Get the stats for objects in archival runs.
   * @param params The `ObjectService.GetObjectArchivalRunStatsParams` containing the following parameters:
   *
   * - `toTimeUsecs`: Specifies the time in Unix timestamp epoch in microsecond which filters all the recoveries started before this value. The default value is the current time.
   *
   * - `rpaasRegionIds`: Filter by a list of region ids. This is used for Rpaas only.
   *
   * - `rpaasOnly`: Specifies whether the archival runs are only for RPaaS.
   *
   * - `rpaasGlobalVaultIds`: Filter by a list of FortKnox global vault ids. This is used for FortKnox only. When rpaasGlobalVaultIds is specified, rpaasRegionIds will be ignored.
   *
   * - `regionIds`: Filter by a list of region ids.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `fromTimeUsecs`: Specifies the time in Unix timestamp epoch in microsecond which filters all the recoveries started after this value. The default value is 7 days ago from now.
   *
   * - `clusterIdentifiers`: Filter by a list of cluster identifiers in format of clusterId:clusterIncarnationId.
   *
   * @return Success
   */
  GetObjectArchivalRunStatsResponse(params: ObjectService.GetObjectArchivalRunStatsParams): __Observable<__StrictHttpResponse<ObjectArchivalRunStats>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.toTimeUsecs != null) __params = __params.set('toTimeUsecs', params.toTimeUsecs.toString());
    (params.rpaasRegionIds || []).forEach(val => {if (val != null) __params = __params.append('rpaasRegionIds', val.toString())});
    if (params.rpaasOnly != null) __params = __params.set('rpaasOnly', params.rpaasOnly.toString());
    (params.rpaasGlobalVaultIds || []).forEach(val => {if (val != null) __params = __params.append('rpaasGlobalVaultIds', val.toString())});
    (params.regionIds || []).forEach(val => {if (val != null) __params = __params.append('regionIds', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.fromTimeUsecs != null) __params = __params.set('fromTimeUsecs', params.fromTimeUsecs.toString());
    (params.clusterIdentifiers || []).forEach(val => {if (val != null) __params = __params.append('clusterIdentifiers', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/mcm/data-protect/objects/stats/archival-runs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ObjectArchivalRunStats>;
      })
    );
  }
  /**
   * Get the object archival run stats.
   *
   * Get the stats for objects in archival runs.
   * @param params The `ObjectService.GetObjectArchivalRunStatsParams` containing the following parameters:
   *
   * - `toTimeUsecs`: Specifies the time in Unix timestamp epoch in microsecond which filters all the recoveries started before this value. The default value is the current time.
   *
   * - `rpaasRegionIds`: Filter by a list of region ids. This is used for Rpaas only.
   *
   * - `rpaasOnly`: Specifies whether the archival runs are only for RPaaS.
   *
   * - `rpaasGlobalVaultIds`: Filter by a list of FortKnox global vault ids. This is used for FortKnox only. When rpaasGlobalVaultIds is specified, rpaasRegionIds will be ignored.
   *
   * - `regionIds`: Filter by a list of region ids.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `fromTimeUsecs`: Specifies the time in Unix timestamp epoch in microsecond which filters all the recoveries started after this value. The default value is 7 days ago from now.
   *
   * - `clusterIdentifiers`: Filter by a list of cluster identifiers in format of clusterId:clusterIncarnationId.
   *
   * @return Success
   */
  GetObjectArchivalRunStats(params: ObjectService.GetObjectArchivalRunStatsParams): __Observable<ObjectArchivalRunStats> {
    return this.GetObjectArchivalRunStatsResponse(params).pipe(
      __map(_r => _r.body as ObjectArchivalRunStats)
    );
  }

  /**
   * Get the protected objects
   *
   * List the protected objects
   * @param params The `ObjectService.GetProtectedObjectsParams` containing the following parameters:
   *
   * - `sourceId`: Specifies the registered source id. All the protected object that exist within this source will be returned.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `includeDeletedObjects`: Specifies if the objects is deleted from the source tree
   *
   * @return Success
   */
  GetProtectedObjectsResponse(params: ObjectService.GetProtectedObjectsParams): __Observable<__StrictHttpResponse<GetProtectedObjectsResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sourceId != null) __params = __params.set('sourceId', params.sourceId.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.includeDeletedObjects != null) __params = __params.set('includeDeletedObjects', params.includeDeletedObjects.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/mcm/data-protect/protected-objects`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetProtectedObjectsResult>;
      })
    );
  }
  /**
   * Get the protected objects
   *
   * List the protected objects
   * @param params The `ObjectService.GetProtectedObjectsParams` containing the following parameters:
   *
   * - `sourceId`: Specifies the registered source id. All the protected object that exist within this source will be returned.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `includeDeletedObjects`: Specifies if the objects is deleted from the source tree
   *
   * @return Success
   */
  GetProtectedObjects(params: ObjectService.GetProtectedObjectsParams): __Observable<GetProtectedObjectsResult> {
    return this.GetProtectedObjectsResponse(params).pipe(
      __map(_r => _r.body as GetProtectedObjectsResult)
    );
  }

  /**
   * Get the summary for a given object.
   *
   * Get the objects summary across clusters.
   * @param params The `ObjectService.GetMcmObjectSummaryParams` containing the following parameters:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `environments`: Filter by environment types such as 'kVMware', 'kView', etc. Only Protected objects protecting the specified environment types are returned.
   *
   * - `clusterIdentifiers`: Specifies the list of cluster identifiers. Format is clusterId:clusterIncarnationId. Only records from clusters having these identifiers will be returned.
   *
   * @return Success
   */
  GetMcmObjectSummaryResponse(params: ObjectService.GetMcmObjectSummaryParams): __Observable<__StrictHttpResponse<McmObjectSummaryResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    (params.environments || []).forEach(val => {if (val != null) __params = __params.append('environments', val.toString())});
    (params.clusterIdentifiers || []).forEach(val => {if (val != null) __params = __params.append('clusterIdentifiers', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/mcm/objects/summary`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<McmObjectSummaryResult>;
      })
    );
  }
  /**
   * Get the summary for a given object.
   *
   * Get the objects summary across clusters.
   * @param params The `ObjectService.GetMcmObjectSummaryParams` containing the following parameters:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `environments`: Filter by environment types such as 'kVMware', 'kView', etc. Only Protected objects protecting the specified environment types are returned.
   *
   * - `clusterIdentifiers`: Specifies the list of cluster identifiers. Format is clusterId:clusterIncarnationId. Only records from clusters having these identifiers will be returned.
   *
   * @return Success
   */
  GetMcmObjectSummary(params: ObjectService.GetMcmObjectSummaryParams): __Observable<McmObjectSummaryResult> {
    return this.GetMcmObjectSummaryResponse(params).pipe(
      __map(_r => _r.body as McmObjectSummaryResult)
    );
  }

  /**
   * GetTenantObjectIds
   *
   * Get the object IDs for a given DMaaS tenant given a list of object hashes.
   * @param body Specifies the parameters to fetch object IDs.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  McmGetTenantObjectIdsResponse(body: McmTenantObjectIdsParams,
    regionId?: string): __Observable<__StrictHttpResponse<McmTenantObjectIds>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/mcm/tenants/object-ids`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<McmTenantObjectIds>;
      })
    );
  }
  /**
   * GetTenantObjectIds
   *
   * Get the object IDs for a given DMaaS tenant given a list of object hashes.
   * @param body Specifies the parameters to fetch object IDs.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  McmGetTenantObjectIds(body: McmTenantObjectIdsParams,
    regionId?: string): __Observable<McmTenantObjectIds> {
    return this.McmGetTenantObjectIdsResponse(body, regionId).pipe(
      __map(_r => _r.body as McmTenantObjectIds)
    );
  }

  /**
   * GetTenantObjectProtections
   *
   * Get the protected objects for a given DMaaS tenant.
   * @param body Specifies the parameters to fetch protected objects.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  McmGetTenantObjectProtectionsResponse(body: McmTenantProtectedObjectsParams,
    regionId?: string): __Observable<__StrictHttpResponse<McmTenantObjectProtections>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/mcm/tenants/protected-objects`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<McmTenantObjectProtections>;
      })
    );
  }
  /**
   * GetTenantObjectProtections
   *
   * Get the protected objects for a given DMaaS tenant.
   * @param body Specifies the parameters to fetch protected objects.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  McmGetTenantObjectProtections(body: McmTenantProtectedObjectsParams,
    regionId?: string): __Observable<McmTenantObjectProtections> {
    return this.McmGetTenantObjectProtectionsResponse(body, regionId).pipe(
      __map(_r => _r.body as McmTenantObjectProtections)
    );
  }
}

module ObjectService {

  /**
   * Parameters for FilterObjects
   */
  export interface FilterObjectsParams {

    /**
     * Specifies the parameters to filter objects.
     */
    body: FilterObjectsRequest;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetProtectedObjectsOfAnyType
   */
  export interface GetProtectedObjectsOfAnyTypeParams {

    /**
     * TenantIds contains ids of the tenants for which objects are to be returned.
     */
    tenantIds?: Array<string>;

    /**
     * Filter by Storage Domain id. Only Objects protected to this Storage Domain will be returned.
     */
    storageDomainId?: number;

    /**
     * Specifies the type of request from UI, which is used for services like magneto to determine the priority of requests.
     */
    requestInitiatorType?: 'UIUser' | 'UIAuto' | 'Helios';

    /**
     * Filter by a list of region ids.
     */
    regionIds?: Array<string>;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Filter by Policy ids that are associated with Protected Objects.
     */
    policyIds?: Array<string>;

    /**
     * Filter by Parent Id. Parent id is a unique object Id which may contain protected objects underneath in the source tree.
     */
    parentId?: number;

    /**
     * If true, the response will include only objects which have been protected.
     */
    onlyProtectedObjects?: boolean;

    /**
     * If true, the response will include only the leaf level objects.
     */
    onlyLeafObjects?: boolean;

    /**
     * If true, the response will include only the auto protected objects.
     */
    onlyAutoProtectedObjects?: boolean;

    /**
     * Filter by ObjectActionKey, uniquely represent protection of an object. An object can be protected in multiple ways but atmost once for a given combination of ObjectActionKey, when specified Only objects of given action_key are returned for corresponding object id. The vec's size needs to be of either length one or same as the length of 'ids'. If the length of objectActionKey is one, it will be repeated as many number of times equal to the length of objectIds, as mandated by backend validation. If the length of objectActionKey and object ids are same then it will be passed as it is.
     */
    objectActionKeys?: Array<'kVMware' | 'kHyperV' | 'kVCD' | 'kAzure' | 'kGCP' | 'kKVM' | 'kAcropolis' | 'kAWS' | 'kAWSNative' | 'kAwsS3' | 'kAWSSnapshotManager' | 'kRDSSnapshotManager' | 'kAuroraSnapshotManager' | 'kAwsRDSPostgresBackup' | 'kAwsAuroraPostgres' | 'kAwsDynamoDB' | 'kAwsRDSPostgres' | 'kPhysical' | 'kPhysicalFiles' | 'kGPFS' | 'kElastifile' | 'kNetapp' | 'kGenericNas' | 'kIsilon' | 'kFlashBlade' | 'kPure' | 'kIbmFlashSystem' | 'kSQL' | 'kExchange' | 'kAD' | 'kOracle' | 'kView' | 'kRemoteAdapter' | 'kO365' | 'kO365PublicFolders' | 'kO365Teams' | 'kO365Group' | 'kO365Exchange' | 'kO365OneDrive' | 'kO365Sharepoint' | 'kKubernetes' | 'kCassandra' | 'kMongoDB' | 'kCouchbase' | 'kHdfs' | 'kHive' | 'kHBase' | 'kUDA' | 'kSfdc' | 'kSAPHANA'>;

    /**
     * Specifies the max number of objects to return.
     */
    maxCount?: number;

    /**
     * If true, the response will include Objects which were protected by all tenants which the current user has permission to see. If false, then only objects protected by the current user will be returned.
     */
    includeTenants?: boolean;

    /**
     * If true, the response will include information about the last protection run on this object.
     */
    includeLastRunInfo?: boolean;

    /**
     * Filter by a list of Object ids.
     */
    ids?: Array<number>;

    /**
     * Filter by environment types such as 'kVMware', 'kView', etc. Only Protected objects protecting the specified environment types are returned.
     */
    environments?: Array<'kVMware' | 'kHyperV' | 'kVCD' | 'kAzure' | 'kGCP' | 'kKVM' | 'kAcropolis' | 'kAWS' | 'kAWSNative' | 'kAwsS3' | 'kAWSSnapshotManager' | 'kRDSSnapshotManager' | 'kAuroraSnapshotManager' | 'kAwsRDSPostgresBackup' | 'kAwsAuroraPostgres' | 'kAwsDynamoDB' | 'kAwsRDSPostgres' | 'kPhysical' | 'kPhysicalFiles' | 'kGPFS' | 'kElastifile' | 'kNetapp' | 'kGenericNas' | 'kIsilon' | 'kFlashBlade' | 'kPure' | 'kIbmFlashSystem' | 'kSQL' | 'kExchange' | 'kAD' | 'kOracle' | 'kView' | 'kRemoteAdapter' | 'kO365' | 'kO365PublicFolders' | 'kO365Teams' | 'kO365Group' | 'kO365Exchange' | 'kO365OneDrive' | 'kO365Sharepoint' | 'kKubernetes' | 'kCassandra' | 'kMongoDB' | 'kCouchbase' | 'kHdfs' | 'kHive' | 'kHBase' | 'kUDA' | 'kSfdc' | 'kSAPHANA'>;

    /**
     * Specifies the pagination cookie.
     */
    cookie?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for ObjectsActions
   */
  export interface ObjectsActionsParams {

    /**
     * Specifies the parameters to execute actions on given list of objects.
     */
    body: ObjectsActionRequest;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetObjectsLastRun
   */
  export interface GetObjectsLastRunParams {

    /**
     * TenantIds contains ids of the tenants for which objects are to be returned.
     */
    tenantIds?: Array<string>;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Specifies the pagination cookie with which subsequent parts of the response can be fetched.
     */
    paginationCookie?: string;

    /**
     * If true, the response will include Objects which belongs to all tenants which the current user has permission to see.
     */
    includeTenants?: boolean;

    /**
     * Specifies a list of object ids, only last runs for these objects will be returned.
     */
    ids?: Array<number>;

    /**
     * Specifies the number of objects to be fetched for the specified pagination cookie.
     */
    count?: number;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for AssociateEntityMetadata
   */
  export interface AssociateEntityMetadataParams {

    /**
     * Specifies the parameters to associate metadata with entities in the entity hierarchy.
     */
    body: AssociateEntityMetadataRequest;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetObjectIdentifiers
   */
  export interface GetObjectIdentifiersParams {

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Get the object identifier matching specified local IDs.
     */
    localIds?: Array<number>;

    /**
     * Get the object identifier matching specified global IDs.
     */
    globalIds?: Array<string>;

    /**
     * Extra parameters that can be specified to get object identifiers.
     */
    body?: ObjectIdentifiersParams;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CancelObjectRuns
   */
  export interface CancelObjectRunsParams {

    /**
     * Specifies the parameters to cancel object runs.
     */
    body: CancelObjectRunsRequest;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetProtectedObjectOfAnyTypeById
   */
  export interface GetProtectedObjectOfAnyTypeByIdParams {

    /**
     * Specifies the id of the Object.
     */
    id: number;

    /**
     * TenantIds contains ids of the tenants for which objects are to be returned.
     */
    tenantIds?: Array<string>;

    /**
     * Filter by Storage Domain id. Only Objects protected to this Storage Domain will be returned.
     */
    storageDomainId?: number;

    /**
     * Specifies the type of request from UI, which is used for services like magneto to determine the priority of requests.
     */
    requestInitiatorType?: 'UIUser' | 'UIAuto' | 'Helios';

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * If true, the response will include only objects which have been protected.
     */
    onlyProtectedObjects?: boolean;

    /**
     * If true, the response will include only the leaf level objects.
     */
    onlyLeafObjects?: boolean;

    /**
     * If true, the response will include only the auto protected objects.
     */
    onlyAutoProtectedObjects?: boolean;

    /**
     * Filter by ObjectActionKey, uniquely represent protection of an object. An object can be protected in multiple ways but atmost once for a given combination of ObjectActionKey, when specified Only objects of given action_key are returned for corresponding object id and this vec's size needs to be same as 'id'.
     */
    objectActionKey?: Array<'kVMware' | 'kHyperV' | 'kVCD' | 'kAzure' | 'kGCP' | 'kKVM' | 'kAcropolis' | 'kAWS' | 'kAWSNative' | 'kAwsS3' | 'kAWSSnapshotManager' | 'kRDSSnapshotManager' | 'kAuroraSnapshotManager' | 'kAwsRDSPostgresBackup' | 'kAwsAuroraPostgres' | 'kAwsDynamoDB' | 'kAwsRDSPostgres' | 'kPhysical' | 'kPhysicalFiles' | 'kGPFS' | 'kElastifile' | 'kNetapp' | 'kGenericNas' | 'kIsilon' | 'kFlashBlade' | 'kPure' | 'kIbmFlashSystem' | 'kSQL' | 'kExchange' | 'kAD' | 'kOracle' | 'kView' | 'kRemoteAdapter' | 'kO365' | 'kO365PublicFolders' | 'kO365Teams' | 'kO365Group' | 'kO365Exchange' | 'kO365OneDrive' | 'kO365Sharepoint' | 'kKubernetes' | 'kCassandra' | 'kMongoDB' | 'kCouchbase' | 'kHdfs' | 'kHive' | 'kHBase' | 'kUDA' | 'kSfdc' | 'kSAPHANA'>;

    /**
     * If true, the response will include Objects which were protected by all tenants which the current user has permission to see. If false, then only objects protected by the current user will be returned.
     */
    includeTenants?: boolean;

    /**
     * If true, the response will include information about the last protection run on this object.
     */
    includeLastRunInfo?: boolean;

    /**
     * Filter by environment types such as 'kVMware', 'kView', etc. Only Protected objects protecting the specified environment types are returned.
     */
    environments?: Array<'kVMware' | 'kHyperV' | 'kVCD' | 'kAzure' | 'kGCP' | 'kKVM' | 'kAcropolis' | 'kAWS' | 'kAWSNative' | 'kAwsS3' | 'kAWSSnapshotManager' | 'kRDSSnapshotManager' | 'kAuroraSnapshotManager' | 'kAwsRDSPostgresBackup' | 'kAwsAuroraPostgres' | 'kAwsDynamoDB' | 'kAwsRDSPostgres' | 'kPhysical' | 'kPhysicalFiles' | 'kGPFS' | 'kElastifile' | 'kNetapp' | 'kGenericNas' | 'kIsilon' | 'kFlashBlade' | 'kPure' | 'kIbmFlashSystem' | 'kSQL' | 'kExchange' | 'kAD' | 'kOracle' | 'kView' | 'kRemoteAdapter' | 'kO365' | 'kO365PublicFolders' | 'kO365Teams' | 'kO365Group' | 'kO365Exchange' | 'kO365OneDrive' | 'kO365Sharepoint' | 'kKubernetes' | 'kCassandra' | 'kMongoDB' | 'kCouchbase' | 'kHdfs' | 'kHive' | 'kHBase' | 'kUDA' | 'kSfdc' | 'kSAPHANA'>;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for PerformActionOnObject
   */
  export interface PerformActionOnObjectParams {

    /**
     * Specifies the id of the Object.
     */
    id: number;

    /**
     * Specifies the parameters to perform an action on an object.
     */
    body: ObjectActionRequest;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for BrowseObjectContents
   */
  export interface BrowseObjectContentsParams {

    /**
     * Specifies the id of the Object.
     */
    id: number;

    /**
     * Specifies the parameters to fetch contents of an object.
     */
    body: ObjectBrowseRequest;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetPITRangesForProtectedObject
   */
  export interface GetPITRangesForProtectedObjectParams {

    /**
     * Specifies the ID of the protected object.
     */
    id: number;

    /**
     * If specified, return the restore ranges that lie before this timestamp. This parameter is specified as the timestamp in Unix time epoch in microseconds.
     */
    toTimeUsecs?: number;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * If specified, return only the points in time corresponding to these protection group IDs.
     */
    protectionGroupIds?: Array<string>;

    /**
     * If specified, return the restore ranges that lie after this timestamp. This parameter is specified as the timestamp in Unix time epoch in microseconds.
     */
    fromTimeUsecs?: number;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetObjectRuns
   */
  export interface GetObjectRunsParams {

    /**
     * Specifies a unique id of the object.
     */
    id: number;

    /**
     * TenantIds contains ids of the tenants for which objects are to be returned.
     */
    tenantIds?: Array<string>;

    /**
     * Filter by a start time when the run starts. Specify the start time as a Unix epoch Timestamp (in microseconds).
     */
    startTimeUsecs?: number;

    /**
     * Filter by run type. Only protection run matching the specified types will be returned.
     */
    runTypes?: Array<'kAll' | 'kHydrateCDP' | 'kSystem' | 'kStorageArraySnapshot' | 'kIncremental' | 'kFull' | 'kLog'>;

    /**
     * Specifies a unique id of the run.
     */
    runId?: string;

    /**
     * Specifies a list of status for the object in the replication run.
     */
    replicationObjectStatus?: Array<'kInProgress' | 'kSuccessful' | 'kFailed' | 'kWaitingForNextAttempt' | 'kWarning' | 'kCurrentAttemptPaused' | 'kCurrentAttemptResuming' | 'kCurrentAttemptPausing' | 'kWaitingForOlderBackupRun'>;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Specifies the pagination cookie with which subsequent parts of the response can be fetched. Users can use this to get next runs
     */
    paginationCookie?: string;

    /**
     * Specifies the max number of runs. If not specified, at most 100 runs will be returned.
     */
    numRuns?: number;

    /**
     * Specifies a list of status for the object in the backup run.
     */
    localBackupObjectStatus?: Array<'kInProgress' | 'kSuccessful' | 'kFailed' | 'kWaitingForNextAttempt' | 'kWarning' | 'kCurrentAttemptPaused' | 'kCurrentAttemptResuming' | 'kCurrentAttemptPausing' | 'kWaitingForOlderBackupRun'>;

    /**
     * If true, the response will include Protection Group Runs which were created by all tenants which the current user has permission to see. If false, then only Protection Group Runs created by the current user will be returned.
     */
    includeTenants?: boolean;

    /**
     * Specifies whether to exclude non restorable runs. Run is treated restorable only if there is at least one object snapshot (which may be either a local or an archival snapshot) which is not deleted or expired. Default value is false.
     */
    excludeNonRestorableRuns?: boolean;

    /**
     * Filter by a end time when the run starts. Specify the start time as a Unix epoch Timestamp (in microseconds).
     */
    endTimeUsecs?: number;

    /**
     * Specifies a list of status for the object in the cloud spin run.
     */
    cloudSpinRunStatus?: Array<'kInProgress' | 'kSuccessful' | 'kFailed' | 'kWaitingForNextAttempt' | 'kWarning' | 'kCurrentAttemptPaused' | 'kCurrentAttemptResuming' | 'kCurrentAttemptPausing' | 'kWaitingForOlderBackupRun'>;

    /**
     * Specifies a list of status for the object in the archival run.
     */
    archivalObjectStatus?: Array<'kInProgress' | 'kSuccessful' | 'kFailed' | 'kWaitingForNextAttempt' | 'kWarning' | 'kCurrentAttemptPaused' | 'kCurrentAttemptResuming' | 'kCurrentAttemptPausing' | 'kWaitingForOlderBackupRun'>;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetObjectRunByRunId
   */
  export interface GetObjectRunByRunIdParams {

    /**
     * Specifies the id of the run.
     */
    runId: string;

    /**
     * Specifies a unique id of the object.
     */
    id: number;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetSnapshotDiff
   */
  export interface GetSnapshotDiffParams {
    id: number;
    body: SnapshotDiffParams;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for InternalApiGetSnapshotDiff
   */
  export interface InternalApiGetSnapshotDiffParams {
    id: number;
    body: SnapshotDiffParams;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetObjectSnapshots
   */
  export interface GetObjectSnapshotsParams {

    /**
     * Specifies the id of the Object.
     */
    id: number;

    /**
     * Specifies the timestamp in Unix time epoch in microseconds to filter Object's snapshots which were taken before this value.
     */
    toTimeUsecs?: number;

    /**
     * Specifies a list of recovery actions. Only snapshots that applies to these actions will be returned.
     */
    snapshotActions?: Array<'RecoverVMs' | 'RecoverFiles' | 'InstantVolumeMount' | 'RecoverVmDisks' | 'MountVolumes' | 'RecoverVApps' | 'RecoverRDS' | 'RecoverAurora' | 'RecoverS3Buckets' | 'RecoverApps' | 'RecoverNasVolume' | 'RecoverPhysicalVolumes' | 'RecoverSystem' | 'RecoverSanVolumes' | 'RecoverNamespaces' | 'RecoverObjects' | 'DownloadFilesAndFolders' | 'RecoverPublicFolders' | 'RecoverVAppTemplates' | 'RecoverMailbox' | 'RecoverOneDrive' | 'RecoverMsTeam' | 'RecoverMsGroup' | 'RecoverSharePoint' | 'ConvertToPst' | 'RecoverSfdcRecords' | 'DownloadChats' | 'RecoverAzureEntraID'>;

    /**
     * Filter by run type. Only protection run matching the specified types will be returned. By default, CDP hydration snapshots are not included, unless explicitly queried using this field.
     */
    runTypes?: Array<'kRegular' | 'kFull' | 'kLog' | 'kSystem' | 'kHydrateCDP' | 'kStorageArraySnapshot'>;

    /**
     * Specifies the timestamp in Unix time epoch in microseconds to filter Object's snapshots which were run before this value.
     */
    runStartToTimeUsecs?: number;

    /**
     * Specifies the timestamp in Unix time epoch in microseconds to filter Object's snapshots which were run after this value.
     */
    runStartFromTimeUsecs?: number;

    /**
     * Filter by a list run instance ids. If specified, only snapshots created by these protection runs will be returned.
     */
    runInstanceIds?: Array<number>;

    /**
     * Filter by a list of region ids.
     */
    regionIds?: Array<string>;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * If specified, this returns only the snapshots of the specified object ID, which belong to the provided protection group IDs.
     */
    protectionGroupIds?: Array<string>;

    /**
     * Filter by ObjectActionKey, which uniquely represents protection of an object. An object can be protected in multiple ways but atmost once for a given combination of ObjectActionKey. When specified, only snapshots matching given action keys are returned for corresponding object.
     */
    objectActionKeys?: Array<'kVMware' | 'kHyperV' | 'kVCD' | 'kAzure' | 'kGCP' | 'kKVM' | 'kAcropolis' | 'kAWS' | 'kAWSNative' | 'kAwsS3' | 'kAWSSnapshotManager' | 'kRDSSnapshotManager' | 'kAuroraSnapshotManager' | 'kAwsRDSPostgresBackup' | 'kAwsAuroraPostgres' | 'kAwsDynamoDB' | 'kAwsRDSPostgres' | 'kPhysical' | 'kPhysicalFiles' | 'kGPFS' | 'kElastifile' | 'kNetapp' | 'kGenericNas' | 'kIsilon' | 'kFlashBlade' | 'kPure' | 'kIbmFlashSystem' | 'kSQL' | 'kExchange' | 'kAD' | 'kOracle' | 'kView' | 'kRemoteAdapter' | 'kO365' | 'kO365PublicFolders' | 'kO365Teams' | 'kO365Group' | 'kO365Exchange' | 'kO365OneDrive' | 'kO365Sharepoint' | 'kKubernetes' | 'kCassandra' | 'kMongoDB' | 'kCouchbase' | 'kHdfs' | 'kHive' | 'kHBase' | 'kUDA' | 'kSfdc' | 'kSAPHANA'>;

    /**
     * Specifies the timestamp in Unix time epoch in microseconds to filter Object's snapshots which were taken after this value.
     */
    fromTimeUsecs?: number;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateObjectSnapshot
   */
  export interface UpdateObjectSnapshotParams {

    /**
     * Specifies the id of the snapshot.<br> Note: 1. If the snapshotid of one of the apps is specified, it applies for all the databases in the Protection Run.<br> 2. In case of volume based jobs, please specify the snapshotid of the source not the database. if source snapshot is specified, applied to source snapshot. if database snapshotid is specified in case of volume based jobs, then it is applicable for host's snapshot.
     */
    snapshotId: string;

    /**
     * Specifies the id of the Object.
     */
    id: number;

    /**
     * Specifies the parameters update an object snapshot.
     */
    body: UpdateObjectSnapshotRequest;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetObjectStats
   */
  export interface GetObjectStatsParams {

    /**
     * Specifies the id of the Object.
     */
    id: number;

    /**
     * Filter by a list of region ids.
     */
    regionIds?: Array<string>;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetObjectTree
   */
  export interface GetObjectTreeParams {

    /**
     * Specifies the id of the Object.
     */
    id: number;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetAllIndexedObjectSnapshots
   */
  export interface GetAllIndexedObjectSnapshotsParams {

    /**
     * Specifies the object id.
     */
    objectId: number;

    /**
     * Specifies the indexed object name.
     */
    indexedObjectName: string;

    /**
     * Specifies whether we can serve the GET request to the read replica cache. There is a lag of 15 seconds between the read replica and primary data source.
     */
    useCachedData?: boolean;

    /**
     * Specifies the timestamp in Unix time epoch in microseconds to filter indexed object's snapshots which are taken before this value.
     */
    toTimeUsecs?: number;

    /**
     * Filter by run type. Only protection run matching the specified types will be returned. By default, CDP hydration snapshots are not included, unless explicitly queried using this field.
     */
    runTypes?: Array<'kRegular' | 'kFull' | 'kLog' | 'kSystem' | 'kHydrateCDP' | 'kStorageArraySnapshot'>;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Specifies the protection group id.
     */
    protectionGroupId?: string;

    /**
     * Filter by ObjectActionKey, which uniquely represents backup type for a given version. An object can be protected in multiple ways but atmost once for a given combination of ObjectActionKey and ObjectId. When specified, only versions of given ObjectActionKey are returned for corresponding object id.
     */
    objectActionKey?: 'kVMware' | 'kHyperV' | 'kVCD' | 'kAzure' | 'kGCP' | 'kKVM' | 'kAcropolis' | 'kAWS' | 'kAWSNative' | 'kAwsS3' | 'kAWSSnapshotManager' | 'kRDSSnapshotManager' | 'kAuroraSnapshotManager' | 'kAwsRDSPostgresBackup' | 'kAwsRDSPostgres' | 'kAwsAuroraPostgres' | 'kAwsDynamoDB' | 'kPhysical' | 'kPhysicalFiles' | 'kGPFS' | 'kElastifile' | 'kNetapp' | 'kGenericNas' | 'kIsilon' | 'kFlashBlade' | 'kPure' | 'kIbmFlashSystem' | 'kSQL' | 'kExchange' | 'kAD' | 'kOracle' | 'kView' | 'kRemoteAdapter' | 'kO365' | 'kO365PublicFolders' | 'kO365Teams' | 'kO365Group' | 'kO365Exchange' | 'kO365OneDrive' | 'kO365Sharepoint' | 'kKubernetes' | 'kCassandra' | 'kMongoDB' | 'kCouchbase' | 'kHdfs' | 'kHive' | 'kHBase' | 'kUDA' | 'kSfdc' | 'kSAPHANA';

    /**
     * Specifies whether to only return snapshots which are indexed. In an indexed snapshot files are guaranteed to exist, while in a non-indexed snapshot files may not exist.
     */
    includeIndexedSnapshotsOnly?: boolean;

    /**
     * Specifies the timestamp in Unix time epoch in microseconds to filter indexed object's snapshots which are taken after this value.
     */
    fromTimeUsecs?: number;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetIndexedObjectSnapshots
   */
  export interface GetIndexedObjectSnapshotsParams {

    /**
     * Specifies the protection group id.
     */
    protectionGroupId: string;

    /**
     * Specifies the object id.
     */
    objectId: number;

    /**
     * Specifies the indexed object name.
     */
    indexedObjectName: string;

    /**
     * Specifies whether we can serve the GET request to the read replica cache. There is a lag of 15 seconds between the read replica and primary data source.
     */
    useCachedData?: boolean;

    /**
     * Specifies the timestamp in Unix time epoch in microseconds to filter indexed object's snapshots which are taken before this value.
     */
    toTimeUsecs?: number;

    /**
     * Filter by run type. Only protection run matching the specified types will be returned. By default, CDP hydration snapshots are not included, unless explicitly queried using this field.
     */
    runTypes?: Array<'kRegular' | 'kFull' | 'kLog' | 'kSystem' | 'kHydrateCDP' | 'kStorageArraySnapshot'>;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Filter by ObjectActionKey, which uniquely represents backup type for a given version. An object can be protected in multiple ways but atmost once for a given combination of ObjectActionKey and ObjectId. When specified, only versions of given ObjectActionKey are returned for corresponding object id.
     */
    objectActionKey?: 'kVMware' | 'kHyperV' | 'kVCD' | 'kAzure' | 'kGCP' | 'kKVM' | 'kAcropolis' | 'kAWS' | 'kAWSNative' | 'kAwsS3' | 'kAWSSnapshotManager' | 'kRDSSnapshotManager' | 'kAuroraSnapshotManager' | 'kAwsRDSPostgresBackup' | 'kAwsRDSPostgres' | 'kAwsAuroraPostgres' | 'kAwsDynamoDB' | 'kPhysical' | 'kPhysicalFiles' | 'kGPFS' | 'kElastifile' | 'kNetapp' | 'kGenericNas' | 'kIsilon' | 'kFlashBlade' | 'kPure' | 'kIbmFlashSystem' | 'kSQL' | 'kExchange' | 'kAD' | 'kOracle' | 'kView' | 'kRemoteAdapter' | 'kO365' | 'kO365PublicFolders' | 'kO365Teams' | 'kO365Group' | 'kO365Exchange' | 'kO365OneDrive' | 'kO365Sharepoint' | 'kKubernetes' | 'kCassandra' | 'kMongoDB' | 'kCouchbase' | 'kHdfs' | 'kHive' | 'kHBase' | 'kUDA' | 'kSfdc' | 'kSAPHANA';

    /**
     * Specifies whether to only return snapshots which are indexed. In an indexed snapshots file are guaranteed to exist, while in a non-indexed snapshots file may not exist.
     */
    includeIndexedSnapshotsOnly?: boolean;

    /**
     * Specifies the timestamp in Unix time epoch in microseconds to filter indexed object's snapshots which are taken after this value.
     */
    fromTimeUsecs?: number;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetObjectSnapshotId
   */
  export interface GetObjectSnapshotIdParams {

    /**
     * Specifies the object id.
     */
    objectId: number;

    /**
     * Specifies the parameters to fetch snapshot id for object.
     */
    body: ObjectSnapshotIdParams;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetEntityMetadata
   */
  export interface GetEntityMetadataParams {

    /**
     * Specifies the source ID for which objects should be returned.
     */
    sourceId: number;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * EntityIds contains ids of the entities for which objects are to be returned.
     */
    entityIds?: Array<number>;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetObjectSnapshotInfo
   */
  export interface GetObjectSnapshotInfoParams {

    /**
     * Specifies the snapshot id.
     */
    snapshotId: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for ConstructMetaInfo
   */
  export interface ConstructMetaInfoParams {

    /**
     * Specifies the snapshot id.
     */
    snapshotId: string;

    /**
     * Specifies the parameters to construct meta info for desired workflow.
     */
    body: ConstructMetaInfoRequest;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for InternalApiConstructMetaInfo
   */
  export interface InternalApiConstructMetaInfoParams {

    /**
     * Specifies the snapshot id.
     */
    snapshotId: string;

    /**
     * Specifies the parameters to construct meta info for desired workflow.
     */
    body: ConstructMetaInfoParams;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetObjectSnapshotVolumeInfo
   */
  export interface GetObjectSnapshotVolumeInfoParams {

    /**
     * Specifies the snapshot id.
     */
    snapshotId: string;

    /**
     * Specifies whether we can serve the GET request to the read replica cache. There is a lag of 15 seconds between the read replica and primary data source.
     */
    useCachedData?: boolean;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Specifies the point-in-time timestamp (in usecs from epoch) between snapshots for which the volume info is to be returned.
     */
    pointInTimeUsecs?: number;

    /**
     * Specifies whether to only return supported volumes.
     */
    includeSupportedOnly?: boolean;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetDeletedProtectedObjects
   */
  export interface GetDeletedProtectedObjectsParams {

    /**
     * Specifies the source ID for which objects should be returned.
     */
    sourceId: number;

    /**
     * TenantIds contains ids of the tenants for which objects are to be returned.
     */
    tenantIds?: Array<string>;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Specifies the max number of objects to return.
     */
    maxCount?: number;

    /**
     * If true, the response will include objects which were protected by all tenants which the current user has permission to see. If false, then only objects protected by the current user will be returned.
     */
    includeTenants?: boolean;

    /**
     * Specifies the pagination cookie.
     */
    cookie?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetSourceHierarchyObjects
   */
  export interface GetSourceHierarchyObjectsParams {

    /**
     * Specifies the source ID for which objects should be returned.
     */
    sourceId: number;

    /**
     * Specifies the VMware object types to filter objects.
     */
    vmwareObjectTypes?: Array<'kVCenter' | 'kStandaloneHost' | 'kvCloudDirector' | 'kFolder' | 'kDatacenter' | 'kComputeResource' | 'kClusterComputeResource' | 'kResourcePool' | 'kDatastore' | 'kHostSystem' | 'kVirtualMachine' | 'kVirtualApp' | 'kStoragePod' | 'kNetwork' | 'kDistributedVirtualPortgroup' | 'kTagCategory' | 'kTag' | 'kOpaqueNetwork' | 'kOrganization' | 'kVirtualDatacenter' | 'kCatalog' | 'kOrgMetadata' | 'kStoragePolicy' | 'kVirtualAppTemplate'>;

    /**
     * Specifies whether we can serve the GET request to the read replica cache. There is a lag of 15 seconds between the read replica and primary data source.
     */
    useCachedData?: boolean;

    /**
     * TenantIds contains ids of the tenants for which objects are to be returned.
     */
    tenantIds?: Array<string>;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Specifies the Pure object types to filter objects.
     */
    pureObjectTypes?: Array<'kStorageArray' | 'kVolume' | 'kPureProtectionGroup'>;

    /**
     * Specifies the Physical object types to filter objects.
     */
    physicalObjectTypes?: Array<'kGroup' | 'kHost' | 'kWindowsCluster' | 'kOracleRACCluster' | 'kOracleAPCluster'>;

    /**
     * Specifies the parent ID under which objects should be returned.
     */
    parentId?: null | number;

    /**
     * Specifies the Oracle object types to filter objects.
     */
    oracleObjectTypes?: Array<'kRACRootContainer' | 'kRootContainer' | 'kHost' | 'kDatabase' | 'kTableSpace' | 'kTable' | 'kPDB'>;

    /**
     * Specifies the Office 365 object types to filter objects.
     */
    o365ObjectTypes?: Array<'kDomain' | 'kOutlook' | 'kMailbox' | 'kUsers' | 'kGroups' | 'kSites' | 'kUser' | 'kGroup' | 'kSite' | 'kApplication' | 'kGraphUser' | 'kPublicFolders' | 'kPublicFolder' | 'kTeams' | 'kTeam' | 'kRootPublicFolder' | 'kO365Exchange' | 'kO365OneDrive' | 'kO365Sharepoint'>;

    /**
     * Specifies the Nimble object types to filter objects.
     */
    nimbleObjectTypes?: Array<'kStorageArray' | 'kVolume'>;

    /**
     * Specifies the Netapp object types to filter objects.
     */
    netappObjectTypes?: Array<'kCluster' | 'kVserver' | 'kVolume'>;

    /**
     * Specifies the MSSQL object types to filter objects.
     */
    mssqlObjectTypes?: Array<'kInstance' | 'kDatabase' | 'kAAG' | 'kAAGRootContainer' | 'kRootContainer' | 'kAAGDatabase'>;

    /**
     * Specifies the Mongo DB object types to filter objects.
     */
    mongodbObjectTypes?: Array<'kCluster' | 'kDatabase' | 'kCollection'>;

    /**
     * Specifies the KVM object types to filter objects.
     */
    kvmObjectTypes?: Array<'kOVirtManager' | 'kStandaloneHost' | 'kDatacenter' | 'kCluster' | 'kHost' | 'kVirtualMachine' | 'kNetwork' | 'kStorageDomain' | 'kVNicProfile'>;

    /**
     * Specifies the Kubernetes object types to filter objects.
     */
    kubernetesObjectTypes?: Array<'kCluster' | 'kNamespace' | 'kService' | 'kPVC' | 'kPersistentVolumeClaim' | 'kPersistentVolume' | 'kLabel'>;

    /**
     * Specifies the Isilon object types to filter objects.
     */
    isilonObjectTypes?: Array<'kCluster' | 'kZone' | 'kMountPoint'>;

    /**
     * If true, the response will include Objects which belongs to all tenants which the current user has permission to see.
     */
    includeTenants?: boolean;

    /**
     * Specifies the HyperV object types to filter objects.
     */
    hypervObjectTypes?: Array<'kSCVMMServer' | 'kStandaloneHost' | 'kStandaloneCluster' | 'kHostGroup' | 'kHypervHost' | 'kHostCluster' | 'kVirtualMachine' | 'kNetwork' | 'kDatastore' | 'kTag' | 'kCustomProperty'>;

    /**
     * Specifies the Hive object types to filter objects.
     */
    hiveObjectTypes?: Array<'kCluster' | 'kDatabase' | 'kTable'>;

    /**
     * Specifies the HDFS object types to filter objects.
     */
    hdfsObjectTypes?: Array<'kCluster'>;

    /**
     * Specifies the Hbase object types to filter objects.
     */
    hbaseObjectTypes?: Array<'kCluster' | 'kNamespace' | 'kTable'>;

    /**
     * Specifies the GPFS object types to filter objects.
     */
    gpfsObjectTypes?: Array<'kCluster' | 'kFilesystem' | 'kFileset'>;

    /**
     * Specifies the generic NAS object types to filter objects.
     */
    genericNasObjectTypes?: Array<'kGroup' | 'kHost'>;

    /**
     * Specifies the GCP object types to filter objects.
     */
    gcpObjectTypes?: Array<'kIAMUser' | 'kProject' | 'kRegion' | 'kAvailabilityZone' | 'kVirtualMachine' | 'kVPC' | 'kSubnet' | 'kNetworkSecurityGroup' | 'kInstanceType' | 'kLabel' | 'kMetadata' | 'kTag' | 'kVPCConnector'>;

    /**
     * Specifies the Flashblade object types to filter objects.
     */
    flashbladeObjectTypes?: Array<'kStorageArray' | 'kFileSystem'>;

    /**
     * Specifies the Exchange object types to filter objects.
     */
    exchangeObjectTypes?: Array<'kRootContainer' | 'kDAGRootContainer' | 'kExchangeNode' | 'kExchangeDAGDatabaseCopy' | 'kExchangeStandaloneDatabase' | 'kExchangeDAG' | 'kExchangeDAGDatabase'>;

    /**
     * Specifies the Elastifile object types to filter objects.
     */
    elastifileObjectTypes?: Array<'kCluster' | 'kContainer'>;

    /**
     * Specifies the Couchbase object types to filter objects.
     */
    couchbaseObjectTypes?: Array<'kCluster' | 'kBucket'>;

    /**
     * Specifies the Cassandra object types to filter objects.
     */
    cassandraObjectTypes?: Array<'kCluster' | 'kKeyspace' | 'kTable'>;

    /**
     * Specifies the Azure object types to filter objects.
     */
    azureObjectTypes?: Array<'kTenant' | 'kSubscription' | 'kResourceGroup' | 'kVirtualMachine' | 'kStorageAccount' | 'kStorageKey' | 'kStorageContainer' | 'kStorageBlob' | 'kNetworkSecurityGroup' | 'kVirtualNetwork' | 'kSubnet' | 'kComputeOptions' | 'kSnapshotManagerPermit' | 'kTag' | 'kAvailabilitySet'>;

    /**
     * Specifies the AWS object types to filter objects.
     */
    awsObjectTypes?: Array<'kIAMUser' | 'kRegion' | 'kAvailabilityZone' | 'kEC2Instance' | 'kVPC' | 'kSubnet' | 'kNetworkSecurityGroup' | 'kInstanceType' | 'kKeyPair' | 'kTag' | 'kRDSOptionGroup' | 'kRDSParameterGroup' | 'kRDSInstance' | 'kRDSSubnet' | 'kRDSTag' | 'kAuroraTag' | 'kAuroraCluster' | 'kAccount' | 'kSubTaskPermit' | 'kS3Bucket' | 'kS3Tag' | 'kKmsKey'>;

    /**
     * Specifies the AD object types to filter objects.
     */
    adObjectTypes?: Array<'kRootContainer' | 'kDomainController'>;

    /**
     * Specifies the Acropolis object types to filter objects.
     */
    acropolisObjectTypes?: Array<'kPrismCentral' | 'kStandaloneCluster' | 'kOtherHypervisorCluster' | 'kCluster' | 'kHost' | 'kVirtualMachine' | 'kNetwork' | 'kStorageContainer'>;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetMcmObjectsActivity
   */
  export interface GetMcmObjectsActivityParams {

    /**
     * Filter by a list of region ids.
     */
    regionIds?: Array<string>;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Request parameters to filter object activity.
     */
    body?: GetMcmObjectsActivityReqParams;
  }

  /**
   * Parameters for GetMcmObjectsLastRunActivity
   */
  export interface GetMcmObjectsLastRunActivityParams {

    /**
     * Specifies a list of region ids. Only records from clusters having these region ids will be returned.
     */
    regionIds?: Array<string>;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Specifies the list of cluster identifiers. Format is clusterId:clusterIncarnationId. Only records from clusters having these identifiers will be returned.
     */
    clusterIdentifiers?: Array<string>;

    /**
     * Request parameters to filter object last runs.
     */
    body?: GetMcmObjectsLastRunReqParams;
  }

  /**
   * Parameters for GetMcmObjectSnapshots
   */
  export interface GetMcmObjectSnapshotsParams {

    /**
     * Specifies the global id of the Object.
     */
    globalId: string;

    /**
     * Specifies the uuid of the Object. This field is deprecated.
     */
    uuid?: string;

    /**
     * Specifies the timestamp in Unix time epoch in microseconds to filter Object's snapshots which are taken before this value.
     */
    toTimeUsecs?: number;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Specifies the timestamp in Unix time epoch in microseconds to filter Object's snapshots which are taken after this value.
     */
    fromTimeUsecs?: number;
  }

  /**
   * Parameters for GetMcmObjectStats
   */
  export interface GetMcmObjectStatsParams {

    /**
     * Specifies the global id of the Object. This field is required because we only fetches snapshots stats for one object.
     */
    globalId: string;

    /**
     * Specifies a list of region ids. Only records from clusters having these region ids will be returned.
     */
    regionIds?: Array<string>;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Specifies the entity hash global id of the Object. This field is deprecated.
     */
    entityHash?: string;

    /**
     * Specifies the list of cluster identifiers. Format is clusterId:clusterIncarnationId. Only records from clusters having these identifiers will be returned.
     */
    clusterIdentifiers?: Array<string>;
  }

  /**
   * Parameters for GetObjectArchivalRunStats
   */
  export interface GetObjectArchivalRunStatsParams {

    /**
     * Specifies the time in Unix timestamp epoch in microsecond which filters all the recoveries started before this value. The default value is the current time.
     */
    toTimeUsecs?: null | number;

    /**
     * Filter by a list of region ids. This is used for Rpaas only.
     */
    rpaasRegionIds?: Array<string>;

    /**
     * Specifies whether the archival runs are only for RPaaS.
     */
    rpaasOnly?: null | boolean;

    /**
     * Filter by a list of FortKnox global vault ids. This is used for FortKnox only. When rpaasGlobalVaultIds is specified, rpaasRegionIds will be ignored.
     */
    rpaasGlobalVaultIds?: Array<string>;

    /**
     * Filter by a list of region ids.
     */
    regionIds?: Array<string>;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Specifies the time in Unix timestamp epoch in microsecond which filters all the recoveries started after this value. The default value is 7 days ago from now.
     */
    fromTimeUsecs?: null | number;

    /**
     * Filter by a list of cluster identifiers in format of clusterId:clusterIncarnationId.
     */
    clusterIdentifiers?: Array<string>;
  }

  /**
   * Parameters for GetProtectedObjects
   */
  export interface GetProtectedObjectsParams {

    /**
     * Specifies the registered source id. All the protected object that exist within this source will be returned.
     */
    sourceId?: null | number;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Specifies if the objects is deleted from the source tree
     */
    includeDeletedObjects?: null | boolean;
  }

  /**
   * Parameters for GetMcmObjectSummary
   */
  export interface GetMcmObjectSummaryParams {

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Filter by environment types such as 'kVMware', 'kView', etc. Only Protected objects protecting the specified environment types are returned.
     */
    environments?: Array<'kVMware' | 'kHyperV' | 'kVCD' | 'kAzure' | 'kGCP' | 'kKVM' | 'kAcropolis' | 'kAWS' | 'kAWSNative' | 'kAwsS3' | 'kAWSSnapshotManager' | 'kRDSSnapshotManager' | 'kAuroraSnapshotManager' | 'kAwsRDSPostgresBackup' | 'kAwsAuroraPostgres' | 'kAwsDynamoDB' | 'kAwsRDSPostgres' | 'kPhysical' | 'kPhysicalFiles' | 'kGPFS' | 'kElastifile' | 'kNetapp' | 'kGenericNas' | 'kIsilon' | 'kFlashBlade' | 'kPure' | 'kIbmFlashSystem' | 'kSQL' | 'kExchange' | 'kAD' | 'kOracle' | 'kView' | 'kRemoteAdapter' | 'kO365' | 'kO365PublicFolders' | 'kO365Teams' | 'kO365Group' | 'kO365Exchange' | 'kO365OneDrive' | 'kO365Sharepoint' | 'kKubernetes' | 'kCassandra' | 'kMongoDB' | 'kCouchbase' | 'kHdfs' | 'kHive' | 'kHBase' | 'kUDA' | 'kSfdc' | 'kSAPHANA'>;

    /**
     * Specifies the list of cluster identifiers. Format is clusterId:clusterIncarnationId. Only records from clusters having these identifiers will be returned.
     */
    clusterIdentifiers?: Array<string>;
  }
}

export { ObjectService }
