import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { RemoteCluster, RemoteClusters, RemoteClustersServiceApi, StorageDomain } from '@cohesity/api/v2';
import { ProtectionPolicyResponse, ReplicationConfig, ViewServiceApi } from '@cohesity/api/v2';
import { IrisContextService } from '@cohesity/iris-core';
import { AjaxHandlerService } from '@cohesity/utils';
import { combineLatest, of, Subject } from 'rxjs';
import { PassthroughOptionsService } from 'src/app/core/services';
import { filter, map, shareReplay, switchMap } from 'rxjs/operators';
import { StorageDomainSelectorComponent } from 'src/app/shared';
import { ShowHideEditControlWrapperComponent } from 'src/app/shared/show-hide-edit-control';

import { ProtectionItemName, StorageDomainProtectionItemProperties } from '../../../models';
import { ModifyProtectionService } from '../../../services/modify-protection.service';
import { BaseProtectionBuilderComponent } from '../../base-protection-builder/base-protection-builder.component';

@Component({
  selector: 'coh-settings-list-storage-domain',
  templateUrl: './settings-list-storage-domain.component.html',
  styleUrls: ['./settings-list-storage-domain.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SettingsListStorageDomainComponent
  extends BaseProtectionBuilderComponent<StorageDomain, StorageDomainProtectionItemProperties> {
  /**
   * Variable to store storage domain selector component reference for protection creation flow.
   */
  private _sdSelector: StorageDomainSelectorComponent;

  /**
   * Boolean to check if user has already setup SnapDiff configuration.
   */
  isSnapMirrorConfigDone = false;

  /**
   * Static value denoting the storage domain name.
   */
  storageDomainName: string;

  /**
   * Getter for storage domain selector component reference for protection creation flow.
   */
  get sdSelector(): StorageDomainSelectorComponent {
    return this._sdSelector;
  }

  /**
   * Setter for storage domain selector component reference for protection creation flow.
   */
  @ViewChild(StorageDomainSelectorComponent) set sdSelector(value: StorageDomainSelectorComponent) {
    this._sdSelector = value;

    if (value) {
      this.setupSdCompatible();
    }
  }

  /**
   * Reference to the quick protection wrapper. This is present only when this
   * component is used in a quick protect context.
   */
  @ViewChild(ShowHideEditControlWrapperComponent) protectionItemWrapper: ShowHideEditControlWrapperComponent;

  /**
   * Specifies whether the storage domain is incompatible with the policy.
   */
  isSdInCompatible$ = new Subject<boolean>();

  constructor(
    private ajaxHandlerService: AjaxHandlerService,
    private irisContextService: IrisContextService,
    private modifyProtectionService: ModifyProtectionService,
    private remoteClusterService: RemoteClustersServiceApi,
    readonly passthroughOptions: PassthroughOptionsService,
    private viewsServiceApi: ViewServiceApi,
  ) {
    super();
  }

  /**
   * Function to set the observable for isSdInCompatible$.
   */
  setupSdCompatible() {
    const hasRemoteClusterPriv = this.irisContextService.irisContext.privs.CLUSTER_REMOTE_VIEW;

    combineLatest([
      this.sdSelector.valueChange,
      this.modifyProtectionService.onControlChange(ProtectionItemName.Policy),

      // Keep and replay last list of remoteCluster since it is not likely to change.
      hasRemoteClusterPriv
        ? this.remoteClusterService.GetRemoteClusters({purpose: ['Replication']}).pipe(shareReplay(1))
        : of([])
    ]).pipe(
      this.untilDestroy(),
      filter(([sdId, policy, remoteClusters]) =>
        !!sdId && !!policy && !!(remoteClusters as RemoteClusters).remoteClusters?.length),

      // Check storage domain Rx compatibility.
      map(([sdId, policy, remoteClusters]) =>
        !this.isStorageDomainCompatible(sdId, policy, (remoteClusters as RemoteClusters).remoteClusters))
    ).subscribe(
      (isSDCompatible: boolean) => this.isSdInCompatible$.next(isSDCompatible),
      error => this.ajaxHandlerService.handler(error));
  }

  /**
   * Checks if the selected storage domain is compatible with the policy replication targets.
   *
   * @param   storageDomainId  Id of the current storage domain.
   * @param   policy           The selected policy.
   * @param   remoteClusters   List of registered remote clusters.
   * @return  true if storageDomain is compatible.
   */
  private isStorageDomainCompatible(
    storageDomainId: number,
    policy: ProtectionPolicyResponse,
    remoteClusters: RemoteCluster[]
  ): boolean {
    const { replicationTargets = [] } = policy.remoteTargetPolicy || {};

    return replicationTargets.every((replication: ReplicationConfig) => {
      const { targetType } = replication;

      // Skip when target type is AWS.
      if (targetType === 'AWS') {
        return true;
      }

      const remoteCluster = remoteClusters.find(
        cluster => cluster.clusterId === replication.remoteTargetConfig.clusterId) || { };
      return (remoteCluster.replicationParams?.storageDomainPairs || [])
        .some(storageDomainPair=> storageDomainPair.localStorageDomainId === storageDomainId);
    });
  }

  initFormControl() {
    const sourceFormControl = this.formGroup.parent?.get(ProtectionItemName.SnapMirrorBackupComponent);

    if (sourceFormControl) {

      // if Snap mirror config exists then fetch the storage domain Id corresponding to the
      // selected view.
      if (sourceFormControl.value?.isSnapMirrorBackup) {
        this.isSnapMirrorConfigDone = sourceFormControl.value?.isSnapMirrorBackup;
        this.viewsServiceApi.GetViews({
          viewIds: [sourceFormControl.value.snapMirrorConfig?.view?.viewId],
          includeInternalViews: false,
          includeTenants: false,
        }).pipe(
          this.untilDestroy()
        ).subscribe(({ views, count }) => {
          if (!count) {
            return;
          }
          this.formGroup.patchValue({ storageDomain: views[0].storageDomainId });
        });
      }

      // For any further changes to the view dropdown, fetch it's appropriate storage domain Id.
      sourceFormControl.valueChanges.pipe(
        switchMap(value => {
          this.isSnapMirrorConfigDone = value?.isSnapMirrorBackup;
          if (this.isSnapMirrorConfigDone) {
            return this.viewsServiceApi.GetViews({
              viewIds: [sourceFormControl.value.snapMirrorConfig?.view?.viewId],
              includeInternalViews: false,
              includeTenants: false,
            });
          }
        }),
        this.untilDestroy()
        ).subscribe(({ views, count }) => {
          if (!count) {
            return;
          }
          this.formGroup.patchValue({ storageDomain: views[0]?.storageDomainId });
        });
    }
  }
}
