import { Component } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Controls, NgxSubFormComponent, subformComponentProviders } from 'ngx-sub-form';

import { SecurityCriteriaFormModel } from '../../models';

@Component({
  selector: 'dg-pa-security-criteria-select-component',
  templateUrl: './security-criteria-select.component.html',
  styleUrls: ['./security-criteria-select.component.scss'],
  providers: subformComponentProviders(SecurityCriteriaSelectComponent),
})
export class SecurityCriteriaSelectComponent extends NgxSubFormComponent<SecurityCriteriaFormModel> {
  /**
   * Open and close mat-expansion-panel
   */
  panelOpenState = false;

  /**
   * Get default values if data is not present
   */
  getDefaultValues(): Partial<SecurityCriteriaFormModel> {
    return {
      name: '',
      id: 0,
      isEnabled: false,
    };
  }

  /**
   * Get the form controls needed for the sub form.
   *
   * @returns   The form controls object.
   */
  protected getFormControls(): Controls<SecurityCriteriaFormModel> {
    return {
      name: new UntypedFormControl(null),
      id: new UntypedFormControl(null),
      isEnabled: new UntypedFormControl(null),
    };
  }
}
