<cog-settings-list-item [invalid]="control.invalid"
  cogFormGroup
  [label]="label || ('preserveCustomAttributes' | translate)">
  <cog-settings-list-item-value-display>
    {{control.value | boolLabel}}
  </cog-settings-list-item-value-display>
  <cog-settings-list-item-value-editor>
    <mat-slide-toggle [formControl]="control"
      [disabled]="isDisabled"
      [inheritDataId]="true"
      cogDataId="preserve-custom-attributes">
      {{label || ('preserveCustomAttributes' | translate)}}
    </mat-slide-toggle>
  </cog-settings-list-item-value-editor>
</cog-settings-list-item>
