import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ScanHealth } from '@cohesity/api/argus';

@Component({
  selector: 'dg-td-threat-matched-status',
  templateUrl: './threat-matched-status.component.html',
  styleUrls: ['./threat-matched-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ThreatMatchedStatusComponent {
  /** TH scan run health status */
  @Input() health: ScanHealth;

  /** Ioc Matches */
  @Input() count = 0;
}
