/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityV2ApiConfiguration as __Configuration } from '../cohesity-v2api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { SyslogServers } from '../models/syslog-servers';
import { SyslogServer } from '../models/syslog-server';
import { SyslogAuditTag } from '../models/syslog-audit-tag';
import { SyslogServerStatus } from '../models/syslog-server-status';
@Injectable({
  providedIn: 'root',
})
class SyslogService extends __BaseService {
  static readonly GetSyslogServersPath = '/syslog';
  static readonly AddSyslogServerPath = '/syslog';
  static readonly RemoveSyslogServersPath = '/syslog';
  static readonly GetSyslogAuditTagsPath = '/syslog/audit-tags';
  static readonly UpdateSyslogAuditTagsPath = '/syslog/audit-tags';
  static readonly GetSupportedSyslogProgramNamesPath = '/syslog/program-names';
  static readonly GetSyslogServerByIdPath = '/syslog/{id}';
  static readonly UpdateSyslogServerByIdPath = '/syslog/{id}';
  static readonly RemoveSyslogServerPath = '/syslog/{id}';
  static readonly PatchSyslogServerByIdPath = '/syslog/{id}';
  static readonly GetSyslogServerStatusByIdPath = '/syslog/{id}/status';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get list of syslog servers.
   *
   * Get list of syslog servers.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetSyslogServersResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<SyslogServers>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/syslog`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SyslogServers>;
      })
    );
  }
  /**
   * Get list of syslog servers.
   *
   * Get list of syslog servers.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetSyslogServers(regionId?: string,
    accessClusterId?: number): __Observable<SyslogServers> {
    return this.GetSyslogServersResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as SyslogServers)
    );
  }

  /**
   * Add Syslog Server
   *
   * Add a new syslog server
   * @param params The `SyslogService.AddSyslogServerParams` containing the following parameters:
   *
   * - `body`: Specifies parameters to add syslog server.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  AddSyslogServerResponse(params: SyslogService.AddSyslogServerParams): __Observable<__StrictHttpResponse<SyslogServer>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/syslog`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SyslogServer>;
      })
    );
  }
  /**
   * Add Syslog Server
   *
   * Add a new syslog server
   * @param params The `SyslogService.AddSyslogServerParams` containing the following parameters:
   *
   * - `body`: Specifies parameters to add syslog server.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  AddSyslogServer(params: SyslogService.AddSyslogServerParams): __Observable<SyslogServer> {
    return this.AddSyslogServerResponse(params).pipe(
      __map(_r => _r.body as SyslogServer)
    );
  }

  /**
   * Remove syslog servers
   *
   * Delete all syslog servers.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  RemoveSyslogServersResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/syslog`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Remove syslog servers
   *
   * Delete all syslog servers.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  RemoveSyslogServers(regionId?: string,
    accessClusterId?: number): __Observable<null> {
    return this.RemoveSyslogServersResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get cluster audit tags.
   *
   * Get cluster audit tags.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetSyslogAuditTagsResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<SyslogAuditTag>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/syslog/audit-tags`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SyslogAuditTag>;
      })
    );
  }
  /**
   * Get cluster audit tags.
   *
   * Get cluster audit tags.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetSyslogAuditTags(regionId?: string,
    accessClusterId?: number): __Observable<SyslogAuditTag> {
    return this.GetSyslogAuditTagsResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as SyslogAuditTag)
    );
  }

  /**
   * Update cluster audit tags.
   *
   * Update cluster audit tags.
   * @param params The `SyslogService.UpdateSyslogAuditTagsParams` containing the following parameters:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `body`: Specifies syslog audit tag to update.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateSyslogAuditTagsResponse(params: SyslogService.UpdateSyslogAuditTagsParams): __Observable<__StrictHttpResponse<SyslogAuditTag>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    __body = params.body;
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/syslog/audit-tags`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SyslogAuditTag>;
      })
    );
  }
  /**
   * Update cluster audit tags.
   *
   * Update cluster audit tags.
   * @param params The `SyslogService.UpdateSyslogAuditTagsParams` containing the following parameters:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `body`: Specifies syslog audit tag to update.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateSyslogAuditTags(params: SyslogService.UpdateSyslogAuditTagsParams): __Observable<SyslogAuditTag> {
    return this.UpdateSyslogAuditTagsResponse(params).pipe(
      __map(_r => _r.body as SyslogAuditTag)
    );
  }

  /**
   * Get supported program names.
   *
   * Get supported program names to configure for a syslog server.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetSupportedSyslogProgramNamesResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<Array<string>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/syslog/program-names`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<string>>;
      })
    );
  }
  /**
   * Get supported program names.
   *
   * Get supported program names to configure for a syslog server.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetSupportedSyslogProgramNames(regionId?: string,
    accessClusterId?: number): __Observable<Array<string>> {
    return this.GetSupportedSyslogProgramNamesResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as Array<string>)
    );
  }

  /**
   * Get a syslog server by id.
   *
   * Get a syslog server by id.
   * @param params The `SyslogService.GetSyslogServerByIdParams` containing the following parameters:
   *
   * - `id`: Specifies the id of syslog server.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetSyslogServerByIdResponse(params: SyslogService.GetSyslogServerByIdParams): __Observable<__StrictHttpResponse<SyslogServer>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/syslog/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SyslogServer>;
      })
    );
  }
  /**
   * Get a syslog server by id.
   *
   * Get a syslog server by id.
   * @param params The `SyslogService.GetSyslogServerByIdParams` containing the following parameters:
   *
   * - `id`: Specifies the id of syslog server.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetSyslogServerById(params: SyslogService.GetSyslogServerByIdParams): __Observable<SyslogServer> {
    return this.GetSyslogServerByIdResponse(params).pipe(
      __map(_r => _r.body as SyslogServer)
    );
  }

  /**
   * Update a syslog server by id.
   *
   * Update syslog server by id.
   * @param params The `SyslogService.UpdateSyslogServerByIdParams` containing the following parameters:
   *
   * - `id`: Specifies the id of syslog server.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `body`: Specifies the body of syslog server body to update.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateSyslogServerByIdResponse(params: SyslogService.UpdateSyslogServerByIdParams): __Observable<__StrictHttpResponse<SyslogServer>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    __body = params.body;
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/syslog/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SyslogServer>;
      })
    );
  }
  /**
   * Update a syslog server by id.
   *
   * Update syslog server by id.
   * @param params The `SyslogService.UpdateSyslogServerByIdParams` containing the following parameters:
   *
   * - `id`: Specifies the id of syslog server.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `body`: Specifies the body of syslog server body to update.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateSyslogServerById(params: SyslogService.UpdateSyslogServerByIdParams): __Observable<SyslogServer> {
    return this.UpdateSyslogServerByIdResponse(params).pipe(
      __map(_r => _r.body as SyslogServer)
    );
  }

  /**
   * Remove syslog server by id
   *
   * Delete syslog server by id.
   * @param params The `SyslogService.RemoveSyslogServerParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the syslog server.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  RemoveSyslogServerResponse(params: SyslogService.RemoveSyslogServerParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/syslog/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Remove syslog server by id
   *
   * Delete syslog server by id.
   * @param params The `SyslogService.RemoveSyslogServerParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the syslog server.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  RemoveSyslogServer(params: SyslogService.RemoveSyslogServerParams): __Observable<null> {
    return this.RemoveSyslogServerResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Patch a syslog server by id.
   *
   * Patch syslog server by id.
   * @param params The `SyslogService.PatchSyslogServerByIdParams` containing the following parameters:
   *
   * - `id`: Specifies the id of syslog server.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `body`: Specifies the body of syslog server fields to patch.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  PatchSyslogServerByIdResponse(params: SyslogService.PatchSyslogServerByIdParams): __Observable<__StrictHttpResponse<SyslogServer>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    __body = params.body;
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/syslog/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SyslogServer>;
      })
    );
  }
  /**
   * Patch a syslog server by id.
   *
   * Patch syslog server by id.
   * @param params The `SyslogService.PatchSyslogServerByIdParams` containing the following parameters:
   *
   * - `id`: Specifies the id of syslog server.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `body`: Specifies the body of syslog server fields to patch.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  PatchSyslogServerById(params: SyslogService.PatchSyslogServerByIdParams): __Observable<SyslogServer> {
    return this.PatchSyslogServerByIdResponse(params).pipe(
      __map(_r => _r.body as SyslogServer)
    );
  }

  /**
   * Get a syslog server reachability status.
   *
   * Check syslog server reachability by given Id.
   * @param params The `SyslogService.GetSyslogServerStatusByIdParams` containing the following parameters:
   *
   * - `id`: Specifies the id of syslog server.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetSyslogServerStatusByIdResponse(params: SyslogService.GetSyslogServerStatusByIdParams): __Observable<__StrictHttpResponse<SyslogServerStatus>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/syslog/${encodeURIComponent(params.id)}/status`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SyslogServerStatus>;
      })
    );
  }
  /**
   * Get a syslog server reachability status.
   *
   * Check syslog server reachability by given Id.
   * @param params The `SyslogService.GetSyslogServerStatusByIdParams` containing the following parameters:
   *
   * - `id`: Specifies the id of syslog server.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetSyslogServerStatusById(params: SyslogService.GetSyslogServerStatusByIdParams): __Observable<SyslogServerStatus> {
    return this.GetSyslogServerStatusByIdResponse(params).pipe(
      __map(_r => _r.body as SyslogServerStatus)
    );
  }
}

module SyslogService {

  /**
   * Parameters for AddSyslogServer
   */
  export interface AddSyslogServerParams {

    /**
     * Specifies parameters to add syslog server.
     */
    body: SyslogServer;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateSyslogAuditTags
   */
  export interface UpdateSyslogAuditTagsParams {

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Specifies syslog audit tag to update.
     */
    body?: SyslogAuditTag;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetSyslogServerById
   */
  export interface GetSyslogServerByIdParams {

    /**
     * Specifies the id of syslog server.
     */
    id: number;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateSyslogServerById
   */
  export interface UpdateSyslogServerByIdParams {

    /**
     * Specifies the id of syslog server.
     */
    id: number;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Specifies the body of syslog server body to update.
     */
    body?: SyslogServer;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for RemoveSyslogServer
   */
  export interface RemoveSyslogServerParams {

    /**
     * Specifies a unique id of the syslog server.
     */
    id: number;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for PatchSyslogServerById
   */
  export interface PatchSyslogServerByIdParams {

    /**
     * Specifies the id of syslog server.
     */
    id: number;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Specifies the body of syslog server fields to patch.
     */
    body?: SyslogServer;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetSyslogServerStatusById
   */
  export interface GetSyslogServerStatusByIdParams {

    /**
     * Specifies the id of syslog server.
     */
    id: number;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { SyslogService }
