import { Injectable } from '@angular/core';
import { ClusterConfig } from '@cohesity/iris-core';
import { BehaviorSubject } from 'rxjs';
import { AjsClusterService } from 'src/app/shared/ng-upgrade/services';

/**
 * App State service is used to holds the properties that shared across iris app which include
 *
 * 1. current and previously selected scope along with is context switching property.
 * 2. list of remote cluster.
 *
 * TODO(veetesh): move below properties here which are currently kept with ajs services.
 * 3. basic cluster info.
 * 4. selected locale.
 * 5. cluster info.
 * 6. tenant impersonation state.
 */
@Injectable({
  providedIn: 'root'
})
export class AppStateService {
  constructor(private ajsClusterService: AjsClusterService) {}

  /**
   * getter for selected scope.
   *
   * @returns The selected scope.
   */
  get selectedScope(): ClusterConfig {
    return this._selectedScope.value;
  }

  /**
   * setter for selected scope.
   *
   * @param  cluster  The cluster to select.
   */
  set selectedScope(cluster: ClusterConfig) {
    this.prevSelectedScope = this._selectedScope.value || cluster;

    this._selectedScope.next(cluster);

    // update ajs clusterInfo name when all cluster is selected.
    if (cluster && cluster._allClusters) {
      this.ajsClusterService.clusterInfo.name = cluster.name;
    }
  }

  /**
   * Holds the selected cluster which is exposed outside via selectedCluster
   * getter/setter.
   */
  private _selectedScope: BehaviorSubject<ClusterConfig> = new BehaviorSubject<ClusterConfig>(undefined);

  /**
   * Holds the observable for the selected cluster.
   */
  selectedScope$ = this._selectedScope.asObservable();

  /**
   * Holds the previously selected cluster used while deciding the safe context switch state.
   */
  prevSelectedScope: ClusterConfig;

  /**
   * Determine whether context switching is in progress or not.
   */
  isSwitchingContext = false;

  /**
   * The list of available scopes, including remote clusters, services, all clusters, global.
   * TODO: Update this property name to reflect the reality it has morphed into.
   */
  remoteClusterList: ClusterConfig[];

  /**
   * During app-scope switching, cluster in transition is an intermediate state which
   * points to target set. It can be also looked as future value of {@link selectedScope}
   * after transition completes.
   */
  clusterInTransition: ClusterConfig = {};

  /**
   * Cleanup the internally cached data on cache busting event.
   */
  bustCache() {
    this.selectedScope = null;
    this.remoteClusterList = null;
  }

  /**
   * Return an id for a cluster config.
   *
   * @param cluster The cluster config.
   * @return The id of cluster config.
   */
  getClusterConfigId(cluster: ClusterConfig): string {
    return [
      cluster._allClusters ? 'allClusters' : '',
      cluster._globalContext ? 'global' : '',
      cluster._cohesityService ? cluster._serviceType : '',
      cluster._nonCluster ? '' : cluster.clusterId,
    ].join(':');
  }
}
