// Component: Recover RDS
import { recoveryGroup } from 'src/app/shared/constants';

;(function (angular, undefined) {
  'use strict';

  var moduleName = 'C.rdsRecovery';

  angular
    .module(moduleName, ['C.recoveryStore'])
    .config(ConfigFn)
    .controller('RDSRecoveryParentCtrl', rdsRecoveryParentCtrlFn)
    .component('rdsRecovery', {
      bindings: {
        // @type   {object}   Specifies the config for the page.
        pageConfig: '<?',
      },
      templateUrl:
        'app/protection/recovery/rds/rds.parent.html',
      controller: 'RDSRecoveryParentCtrl',
    });

  /**
   * Specifies the state names along with their URL mappings.
   *
   * @method   ConfigFn
   * @param   {object}   $stateProvider       Provides interface to declare
   *                                          custom states.
   * @param   {object}   $urlRouterProvider   Responsible for watching change
   *                                          in $location.
   */
  function ConfigFn($stateProvider) {
    var canAccessCondition = ctx => {
      return ctx.RESTORE_MODIFY &&
        ctx.canAccessSomeEnvItems(recoveryGroup.rds);
    };

    $stateProvider
      .state('recover-rds', {
        redirectTo: function() {
          return { state: 'recover-rds.search' };
        },
        url: '/protection/recovery/rds',
        title: 'Recover RDS',
        canAccess: canAccessCondition,
        help: 'protection_recovery_rds',
        component: 'rdsRecovery',
      })
      .state('recover-rds.search', {
        url: '/search',
        title: 'Recover RDS Search',
        canAccess: canAccessCondition,
        help: 'protection_recovery_rds',
        views: {
          'rdsCanvas@recover-rds': {
            component: 'rdsRecoverySearch',
          },
        },
      })
      .state('recover-rds.options', {
        url: '/options?{jobId}&{entityId}&{jobInstanceId}',
        title: 'Recover RDS Options',
        canAccess: canAccessCondition,
        help: 'protection_recovery_rds',
        views: {
          'rdsCanvas@recover-rds': {
            component: 'rdsRecoveryOptions',
          },
        },
        params: {
          entityId: { type: 'string' },
          jobId: { type: 'string' },
          jobInstanceId: { type: 'string' },
          jobUid: null,
          jobRunStartTime: null,
          resubmitRecoveryObject: null,
        },
        resolve: {
          jobId: function getJobId($transition$) {
            return $transition$.params().jobId;
          },
          jobInstanceId: function getJobInstanceId($transition$) {
            return $transition$.params().jobInstanceId;
          },
          entityId: function getEntityId($transition$) {
            return $transition$.params().entityId;
          },
          jobUid: function getEntityId($transition$) {
            return $transition$.params().jobUid;
          },
          jobRunStartTime: function getJobRunStartTime($transition$) {
            return $transition$.params().jobRunStartTime;
          },
          resubmitRecoveryObject:
            function getResubmitRecoveryObject($transition$) {
              return $transition$.params().resubmitRecoveryObject;
            },
        },
      });
  }

  /**
   * @ngdoc component
   * @name C.rdsRecovery:rdsRecovery
   * @function
   *
   * @description
   * Component for handling the Office 365 recovery.
   */
  function rdsRecoveryParentCtrlFn(RecoveryStore) {
    var $ctrl = this;

    _.assign($ctrl, {
      // Lifecycle methods.
      $onInit: $onInit,
      $onDestroy: $onDestroy,
    });

    /**
     * Component initialization hook.
     *
     * @method   $onInit
     */
    function $onInit() {
      _initializeSharedData();
    }

    /**
     * Component destructor hook.
     *
     * @method   $onDestroy
     */
    function $onDestroy() {
      RecoveryStore.clear();
    }

    /**
     * Initializes shared data.
     *
     * @method   _initializeSharedData
     */
    function _initializeSharedData() {
      RecoveryStore.clear();
      _.assign($ctrl, {
        shared: RecoveryStore.init({
          isRecover: true,
          cart: [],
          filters: [],
          filterLookups: {
            jobs: [],
            registeredSources: [],
          },
          searchId: 'rds',
        }),
      });
    }
  }

})(angular);
