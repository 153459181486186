import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { SnackBarService } from '@cohesity/helix';
import { AjaxHandlerService, AutoDestroyable } from '@cohesity/utils';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';

import { SecurityService } from '../../services';
import { AnomalyAlert } from '../../security-shared.models';

/**
 * Component to show information before user proceeds with ignore operation
 */
@Component({
  templateUrl: './ignore-confirmation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IgnoreConfirmationComponent extends AutoDestroyable {
  /** Indicates whether we are loading data. */
  isSubmitting = false;

  /** Translation key for template */
  ignoreConfirmationTranslationKey = 'anomalyDetails.ignoreConfirmationWithUntagging';

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: AnomalyAlert,
    private ajaxHandlerService: AjaxHandlerService,
    private cdr: ChangeDetectorRef,
    private dialogRef: MatDialogRef<IgnoreConfirmationComponent, boolean>,
    private securityService: SecurityService,
    private snackBarService: SnackBarService,
    private translateService: TranslateService
  ) {
    super();
  }

  /**
   * Handles the confirm actions.
   */
  confirm() {
    this.isSubmitting = true;
    this.cdr.detectChanges();
    this.securityService
      .updateAnomalyState(this.data, 'Suppressed')
      .pipe(
        this.untilDestroy(),
        finalize(() => {
          this.isSubmitting = false;
          this.cdr.detectChanges();
        })
      )
      .subscribe(() => {
        this.snackBarService.open(this.translateService.instant('anomalyRecovery.ignoreSuccess'));
        this.dialogRef.close(true);
      }, this.ajaxHandlerService.handler);
  }
}
