import { StreamMode, TargetDetail, TargetType } from '@cohesity/api/alert-stream';

import { IntegrationActionType, IntegrationStatus } from '../integration-config.model';

/**
 * Maintains integration target type to name mapping.
 */
export const SiemSoarSecurityIntegrationNames: Partial<Record<TargetType, TargetType>> = {
  CrowdStrike: 'CrowdStrike',
  ServiceNow: 'ServiceNow',
  MicrosoftSentinal: 'MicrosoftSentinal',
};

/**
 * Maintains integration target type to name mapping
 */
export const ConfigurationModes: Partial<Record<TargetType, StreamMode>> = {
  CrowdStrike: 'Push',
  ServiceNow: 'Pull',
};

/**
 * List of integration types.
 */
export type IntegrationTypes = 'CrowdStrike' | 'Splunk' | 'IBMQradar' | 'MicrosoftSentinal' | 'ServiceNow';

/**
 * List of supported integrations.
 */
export const IntegrationsList: IntegrationTypes[] = [
  'CrowdStrike',
  'Splunk',
  'IBMQradar',
  'MicrosoftSentinal',
  'ServiceNow',
];

/**
 * The integration model.
 */
export interface Stream {
  /**
   * The id of the integration.
   */
  id: string;

  /**
   * Name of the integration.
   */
  name: string;

  /**
   * The version of the integration.
   */
  version: string;

  /**
   * The status of the integration.
   */
  status: IntegrationStatus;

  /**
   * List of valid menu actions for the integration row.
   */
  menuActions: IntegrationActionType[];

  /**
   * The target type (crowstrike, servicenow ...)
   */
  targetType: TargetDetail['type'];

  /**
   * Error message associated with the integration.
   */
  errorMessage?: string;
}
