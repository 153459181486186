import { AppModuleConfig, StateAccessContext, StateAccessMap } from 'src/app/app-module.config';

/**
 * allows only if feature flag enabled
 *
 * @param ctx gives all context data
 * @returns mfaHeliosOnPrem
 */
const mfaAccess = (ctx: StateAccessContext) => ctx.FEATURE_FLAGS.mfaHeliosOnPrem;

export const HeliosOnpremLoginConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'helios-onprem-mfa.**',
      url: '^/helios-onprem-mfa',
      loadChildren: () => import('./login.module').then(m => m.LoginModule),
    },
    {
      name: 'helios-onprem-mfa-verify.**',
      url: '^/helios-onprem-mfa-verify',
      loadChildren: () => import('./login.module').then(m => m.LoginModule),
    },
  ],
  getStateAccessMap(): StateAccessMap {
    return {
      'helios-onprem-mfa': mfaAccess,
      'helios-onprem-mfa-verify': mfaAccess,
    };
  }
};
