import { Injectable } from '@angular/core';
import { SnackBarService } from '@cohesity/helix';
import {
  AppConfig,
  appList,
  AppName,
  ClusterConfig,
  clusterIdentifiers,
  getAccessibleAppConfigs,
  IrisContextService,
  isAllClustersScope,
  isDmsOnlyUser,
  isDmsUser,
  isHeliosTenantUser,
  isMcm,
} from '@cohesity/iris-core';
import { TranslateService } from '@ngx-translate/core';
import { RawParams, StateService, Transition, UIRouterGlobals } from '@uirouter/core';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AppServiceConfig, AppServiceManagerService } from 'src/app/app-services';
import { CustomizationService } from 'src/app/core/services/customization.service';
import { AjsRemoteClusterService } from 'src/app/shared/ng-upgrade/services';

import { StateContext } from '../state/state-context';
import { AjsUpgradeService } from './ajs-upgrade.service';
import { AppStateService } from './app-state.service';
import { ClusterService } from './cluster.service';
import { HomeService } from './home.service';
import { RemoteClusterService } from './remote-cluster.service';
import { StateManagementService } from './state-management.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class ScopeSelectorService {
  /** Local storage key for selectedCluster. */
  private readonly selectClusterStorageKey = 'selectedCluster';

  /**
   * Injected ajs services.
   */
  private localStorageService: any;

  /**
   * An observable of app configs that can be passed directly to the app switcher component.
   * This includes all app services, global, and all clusters views.
   */
  availableAppConfigs$ = this.irisContext.irisContext$.pipe(map(() => this.getAvailableServiceConfigs()));

  constructor(
    private ajsRemoteClusterService: AjsRemoteClusterService,
    private ajsUpgrade: AjsUpgradeService,
    private appServiceManager: AppServiceManagerService,
    private appStateService: AppStateService,
    private clusterService: ClusterService,
    private homeService: HomeService,
    private remoteClusterService: RemoteClusterService,
    private state: StateService,
    private stateManagementService: StateManagementService,
    private userService: UserService,
    private irisContext: IrisContextService,
    private snackbar: SnackBarService,
    private translate: TranslateService,
    private uiRouterGlobals: UIRouterGlobals,
    private customizationService: CustomizationService,
  ) {
    this.localStorageService = this.ajsUpgrade.get('localStorageService');
  }

  /**
   * Initialize the cluster context bases on
   * 1. cluster mode like on-prem or helios.
   * 2. if cid is present in the url then select that cluster and used by Helios global search for deeplinks to protect
   *    an object/VM found in the search result for a specific cluster.
   * 3. if cluster in stored in the local storage then select that to give user
   *    a consistent cluster context if he opens the url in new tab.
   * TODO: try utilizing the user personalization services to store selected
   * cluster instead of local storage.
   *
   * @param  trans  The current Transition object.
   * @param  ignoreStoredCluster If set, ignores the stored cluster while performing the initial selection.
   * @returns  The Promise which will be resolved alway either immediately when context switch not needed or
   * resolve asynchronously after switching to the right context.
   */
  performInitialSelection(trans: Transition, ignoreStoredCluster: boolean = false): Promise<any> {
    const params = trans.params();
    let clusterToSelect: ClusterConfig;
    const queriedCluster = this.getQueriedCluster(params);
    // If `ignoreStoredCluster` is set, we will ignore the stored cluster information while doing the scope selection.
    // This is particularly used to change scope from micro-frontend apps by passing the serviceType in the URL
    // parameter.
    const storedCluster = ignoreStoredCluster ? null : this.getStoredCluster();

    // Typed as any, because there are some check on this that seem like they are assuming this is
    // is a cluster config object.
    const clusterInfo = this.irisContext.irisContext.clusterInfo as ClusterConfig;

    // 1st preference given to queried cluster then store cluster then choose
    // the cluster based on env i.e. helios or Baas inside helios.
    switch (true) {
      // select the cluster found from the cid present in the url.
      case !!queriedCluster:
        clusterToSelect = queriedCluster;
        break;

      // select cluster stored in local storage.
      case !!storedCluster:
        clusterToSelect = storedCluster;
        break;


      // select cluster in helios mode.
      case isMcm(this.irisContext.irisContext): {
        const landingApp = this.customizationService?.userCustomizations?.heliosPreferences?.heliosDefaultLandingApp;
        const defaultApp = landingApp !== 'cohesityDataCloud' && appList.find(app => app.app === landingApp)?.app;
        const defaultContext = defaultApp && this.appStateService.remoteClusterList.find(
          app => app._serviceType === defaultApp
        );
        const globalContext = this.appStateService.remoteClusterList.find(cluster => cluster._globalContext);

        if (defaultContext) {
          // If the user has picked a default landing app, use that.
          clusterToSelect = defaultContext;
        } else if (globalContext && !isHeliosTenantUser(this.irisContext.irisContext)) {
          // If global context is available, select that as that is a superset
          // of other scopes, except for tenant user, whose default dashboard should be all clusters.
          clusterToSelect = globalContext;
        } else if (isDmsOnlyUser(this.irisContext.irisContext)) {
          // If the user only has access to dmaas, select that by default.
          clusterToSelect = this.appStateService.remoteClusterList.find(cluster => cluster._serviceType === 'dms');
        } else {
          // Otherwise select the all clusters scope, or first available cluster.
          clusterToSelect = this.appStateService.remoteClusterList.find(
            cluster => cluster._allClusters
          ) || this.appStateService.remoteClusterList[0];
        }
        break;
      }
      // select access cluster by default and consider on-prem mode.
      default:
        clusterToSelect = this.remoteClusterService.getAccessCluster();
        break;
    }

    if (params.regionId) {
      // If loading the app initially with regionId, force select the
      // DataProtect scope.
      // TODO: Doing this check anywhere else doesn't work, and this may
      // not be entirely accurate if non DataProtect pages with "regionId"
      // param get introduced (they don't exist at the time of putting this
      // fix).
      clusterToSelect = this.appStateService.remoteClusterList.find(
        cluster => cluster._serviceType === 'dms'
      );
    }

    let isSameCluster: boolean;

    if (clusterToSelect._nonCluster) {
      isSameCluster = (clusterToSelect._globalContext && clusterInfo._globalContext) ||
        (clusterToSelect._allClusters && clusterInfo._allClusters) ||
        (clusterToSelect._serviceType === clusterInfo._serviceType);
    } else {
      // Indicate whether cluster to select and access cluster are same and used to prevent additional context switch
      // during initialization.
      isSameCluster = clusterToSelect.clusterId === clusterInfo.id;
    }

    // verifying whether next state is safe w/o changing the context by
    // pretending current state & next state is same.
    const safeState = this.stateManagementService.getSafeContextSwitchState(
      trans.$to().self, clusterToSelect, clusterToSelect);

    // goto safe state if current state is not appropriate for current context.
    if (safeState) {
      this.state.go(safeState.name, safeState.params, {reload: true});
    } else if (!isSameCluster) {
      return this.switchScope(clusterToSelect, true);
    }

    // Initializing the scope if scope switching not needed.
    this.appStateService.selectedScope = clusterToSelect;
    return Promise.resolve();
  }

  /**
   * Determines whether provided cluster can be selected or not.
   *
   * @param  cluster  The cluster to Test.
   * @returns  Return true is select cluster should be disabled.
   */
  disableSelect(cluster: ClusterConfig) {
    return isMcm(this.irisContext.irisContext) &&
      !cluster.connectedToCluster &&
      !cluster._nonCluster;
  }

  /**
   * Find the full ClusterConfig object.
   *
   * @param  cluster  The ClusterConfig object or crtieria to search.
   * @param  search   Find the full ClusterConfig object from the parameters if true.
   * @returns  Return ClusterConfig object or undefined if it is disabled.
   */
  findCluster(cluster: ClusterConfig, search = false): ClusterConfig {
    if (cluster._cohesityService) {
      return this.appStateService.remoteClusterList.find(c => cluster._serviceType === c._serviceType);
    }

    if (search) {
      cluster = this.appStateService.remoteClusterList.find((c: ClusterConfig) =>
        c.clusterId === cluster.clusterId && c.clusterIncarnationId === cluster.clusterIncarnationId
      );
    }
    return this.disableSelect(cluster) ? undefined : cluster;
  }

  /**
   * Context change for moving to services and landing on their homepage. This
   * will not run any logic to try to put the user on a specific page and should
   * not be changed.
   *
   * @param cluster   The "cluster" to switch to. NOTE: Probably shouldn't use
   *                  this function for actual cluster switching.
   */
  basicScopeSwitch(cluster: ClusterConfig): Observable<void> {
    const clusterInfo$ = cluster._nonCluster ?
      of(null) :
      this.clusterService.getClusterInfo(true, {accessClusterId: cluster.clusterId});
    return clusterInfo$.pipe(
      tap(() => {
        const hasServiceTypeInUrl = !!this.uiRouterGlobals.params?.serviceType;

        this.updateScope(cluster);
        this.setScopeHome(cluster);

        // Remove serviceType route param because updateScope will ensure active app is visible.
        // Pass in the _defaultState in cluster config if exists. If not, then undefined value will
        // fall through to global default.
        const options = { custom: { stateName: cluster._defaultState}};
        this.homeService.goHome(options, hasServiceTypeInUrl ? { serviceType: '' } : null);
      })
    );
  }

  /**
   * Sets the home state via the HomeService based on App Service config.
   *
   * @param cluster   the "cluster" to get the home state value from.
   */
  setScopeHome(cluster: ClusterConfig) {
    const appService = this.appServiceManager.getServiceByClusterConfig(cluster);
    if (appService?.homeState) {
      this.homeService.set(appService.homeState);
    } else {
      this.homeService.resetToDefault();
    }
  }

  /**
   * Manages updating selectedScope in AppStateService and updating local storage
   * for internal consumption.
   *
   * @param cluster    The cluster which scope should be updated to.
   */
  updateScope(cluster: ClusterConfig) {
    this.appStateService.selectedScope = cluster;

    // store selected cluster which will be auto selected next time for the user.
    this.localStorageService.set('selectedCluster', cluster);
  }

  /**
   * Select the provided cluster.
   *
   * @param  cluster  The cluster to select.
   * @param  suppressReload  If present and true then don't reload the state it will be assumed that user is already on
   * the right cluster context.
   * @param  search   Find the full ClusterConfig object from the parameters if true.
   * @returns  The Promise which will be resolved alway either immediately when context switch not needed or
   * resolve asynchronously after switching to the right context.
   */
  switchScope(cluster: ClusterConfig, suppressReload = false, search = false): Promise<any> {
    cluster = this.findCluster(cluster, search);

    // do nothing for already selected cluster or undefined/disabled cluster.
    if (!cluster || (this.appStateService.selectedScope &&
      this.appStateService.selectedScope === cluster)) {
      return Promise.resolve();
    }

    this.setScopeHome(cluster);

    // get safe state for current selection.
    const safeState = this.stateManagementService.getSafeContextSwitchState(
      this.uiRouterGlobals.current, this.appStateService.selectedScope || {}, cluster);

    // change the cluster context.
    this.appStateService.isSwitchingContext = true;

    return this.ajsRemoteClusterService.changeClusterContext(cluster, suppressReload, safeState)
      .then(() => {
        this.updateScope(cluster);
      })
      .catch(() => {
        // Since DMaaS user has access to only all cluster, we will be in a loop until
        // the cluster is resolved. So logout the user and inform the error.
        if (isDmsUser(this.irisContext.irisContext)) {
          this.snackbar.open(this.translate.instant('dms.accessError'), 'error');
          this.userService.logout();
          return;
        }

        // got API error while switching the context show the error and switch to a safe context.
        this.snackbar.open(
          this.translate.instant('altClusterSelector.problemConnectingToRemote', { clusterName: cluster.name}),
          'error'
        );

        if (isMcm(this.irisContext.irisContext)) {
          // If running in helios mode, In the event of an API error return to all clusters dashboard.
          this.homeService.goHome();
          // TODO: Find desired "cluster" instead of assumings its in index 0.
          return this.switchScope(this.appStateService.remoteClusterList[0], true);
        } else {
          // If running in on-prem/SPOG mode, In the event of an API error restore the previously selected cluster.
          return this.switchScope(this.appStateService.selectedScope || this.remoteClusterService.getAccessCluster());
        }
      })
      .finally(() => {
        this.appStateService.isSwitchingContext = false;
      });
  }

  /**
   * Ensures safe transition b/w state as per selected context else switch the context.
   *
   * @param  trans  The current Transition object.
   */
  ensureSafeTransition(trans: Transition) {
    const params = trans.params();
    const toState = trans.$to().self;
    let safeState: StateContext;

    // When the app is switching context to a different cluster, do not check for transition.
    // This will lead to unwanted check when migrating from all clusters to a single cluster state.
    // If the state is not supported in single cluster.
    if (this.appStateService.isSwitchingContext) {
      return;
    }

    if (this.userService.isSelfServiceUser()) {
      return true;
    }

    // get cluster queried from the url used mainly by deeplinks from global search.
    const queriedCluster = this.getQueriedCluster(params);

    // verifying target state is safe for the queried cluster.
    if (queriedCluster) {
      safeState = this.stateManagementService.getSafeContextSwitchState(
        toState, this.appStateService.selectedScope, queriedCluster);

      // changing context to queried cluster and skip reloading as navigation is already in progress.
      this.switchScope(queriedCluster, true);
    }

    // preventing current state transition and goto safe state.
    if (safeState) {
      trans.abort();
      this.state.go(safeState.name, safeState.params, {reload: true});
    }
  }

  /**
   * Get the cluster info for provided cid in the state params also ensures that
   * cluster should be connected in case of helios.
   *
   * @param  params  Optionally if provided use provided state param else get current state params.
   * @returns  The found cluster.
   */
  getQueriedCluster(params?: RawParams): ClusterConfig {
    params = params || this.uiRouterGlobals.params;

    const cid = +params.cid;
    const regionId = params.regionId;
    const serviceType = params.serviceType;

    if (!cid && !regionId && !serviceType) {
      return;
    }

    if (cid) {
      // find and ensure queried cluster is connected currently.
      return this.appStateService.remoteClusterList.find((cluster: ClusterConfig) =>
        !cluster._allClusters && cluster.clusterId === cid &&
          (isMcm(this.irisContext.irisContext) ? cluster.connectedToCluster : true));
    }

    if (serviceType) {
      const config = this.appServiceManager.getServiceByType(serviceType)?.clusterConfigPartial;
      if (config) {
        return this.appStateService.remoteClusterList.find((cluster: ClusterConfig) =>
          this.appStateService.getClusterConfigId(cluster) === this.appStateService.getClusterConfigId(config)
        );
      }
    }

    // Otherwise if there is a region id, select the DataProtect scope.
    return this.appStateService.remoteClusterList.find(cluster =>
      cluster._allClusters && cluster._serviceType === 'dms'
    );
  }

  /**
   * Get the cluster from local storage and ensures that cluster should be connected in case of helios.
   *
   * @returns  The found cluster.
   */
  getStoredCluster(): ClusterConfig {
    const storedCluster: ClusterConfig = this.localStorageService.get(this.selectClusterStorageKey);

    if (!storedCluster) {
      return;
    }

    if (storedCluster._cohesityService) {
      return this.appStateService.remoteClusterList.find(
        cluster => storedCluster._serviceType === cluster._serviceType
      );
    }

    if (storedCluster._globalContext) {
      // Global context cluster does not have an associated id, so check for it
      // here.
      return this.appStateService.remoteClusterList.find(cluster => cluster._globalContext);
    }

    if (storedCluster._allClusters) {
      return this.appStateService.remoteClusterList.find(cluster => cluster._allClusters);
    }

    // find and ensure store cluster is connected currently.
    return this.appStateService.remoteClusterList.find((cluster: ClusterConfig) =>
      cluster.clusterId === storedCluster.clusterId &&
        (isMcm(this.irisContext.irisContext) ? cluster.connectedToCluster : true));
  }

  /**
   * Convenience method to switch to the last known scope.
   *
   * @returns Returns a boolean which signifies whether the last scope was found
   *   in the history and it had been switched to.
   */
  switchToLastScope(): boolean {
    const lastScope = this.appStateService.prevSelectedScope;

    // The `lastScope` can be an empty object too.
    if (lastScope && Object.keys(lastScope)?.length) {
      this.switchScope(lastScope);
      return true;
    }

    return false;
  }

  /**
   * Gets mcm mode (MCM Saas/ MCM on-prem).
   *
   * @deprecated   Use iris context util: isMcm(ctx)
   */
  get isMcm(): boolean {
    return isMcm(this.irisContext.irisContext);
  }

  /**
   * Gets Cluster ID.
   *
   * @deprecated   Use iris context util: clusterScopeIdentifier(ctx)
   */
  get clusterId(): number {
    return this.irisContext.irisContext.clusterInfo.id;
  }

  /**
   * Checks scope and add cluster id params if needed to the existing params.
   *
   * @param  params  The parameters sent to the API call.
   * @returns        Updated parameters with selected cluster ids.
   */
  getScopedParams(params?: object): object | string[] {
    const ctx = this.irisContext.irisContext;

    if (isMcm(ctx) && !isAllClustersScope(ctx)) {
      return params
        ? { clusterIdentifiers: clusterIdentifiers(ctx), ...params }
        : clusterIdentifiers(ctx);
    }

    return params;
  }

  /**
   * An observable of app configs that can be passed directly to the app switcher component.
   * This includes all app services, global, and all clusters views.
   *
   * @returns A list of app configs
   */
  getAvailableServiceConfigs(): AppConfig[] {
    const serviceConfigMap = this.appServiceManager.getAvailableServices()
      .reduce((configAcc, serviceConfig) => {
        configAcc[serviceConfig.appName] = serviceConfig;
        return configAcc;
      }, {} as Record<AppName, AppServiceConfig>);

    return getAccessibleAppConfigs(this.irisContext.irisContext).map(config => ({
      ...config,

      // Skipping go() function for micro frontend apps which dont need any states.
      go: config.publicPath ? null : () => {
        this.basicScopeSwitch(serviceConfigMap[config.name].clusterConfigPartial).subscribe();
      }
    }));
  }
}
