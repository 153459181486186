import { BannerStatus } from '@cohesity/helix';
import { TranslateService } from '@ngx-translate/core';

import { IrisContext, flagEnabled } from '../../../iris-context';
import { BaseBannerConfig } from '../base-banner.config';
import { CrmAppEntitlementData, DerivedSubscriptionExpirationData } from '../crm-entitlement-banners/crm-entitlement.model';

export interface DeprecatedSubsExpiryData extends DerivedSubscriptionExpirationData, CrmAppEntitlementData {
  /**
   * threshold limit (number of days) after which status of banner is changed to warning
   * if value is provided by user then that value isused else a default threshold of 3 days is set
   */
  warnThreshold?: number;
}

/**
 * data type for for making abstract function type checking easier
 */
export type ToSubsExpiryData = (ctx: IrisContext) => DeprecatedSubsExpiryData;

export abstract class SubsExpiryBannerConfig extends BaseBannerConfig {
  /**
   * get relevant subscription expiry data out of given arguments
   *
   * @param ctx iris context for extracting subscription info
   * @returns relevant expiry info for corresponding service
   */
  abstract toSubsExpiryData: ToSubsExpiryData;

  /**
   * data relevant to subscription
   */
  data: DeprecatedSubsExpiryData = {
    trialExpired: false,
    daysRemaining: null,
    appName: null,
    warnThreshold: null,
  };

  constructor(protected translateService: TranslateService) {
    super();
  }

  /**
   * Provides a status value for the free trial banner.
   */
  getStatus(): BannerStatus {
    if (this.data.trialExpired) {
      return 'critical';
    }

    if (this.data.daysRemaining < (this.data.warnThreshold || 3)) {
      return 'warn';
    }

    return 'info';
  }

  /**
   * Make the banner persistent if the trial is expired.
   */
  allowClose(): boolean {
    return !this.data.trialExpired;
  }

  /**
   * Gets the translated text based on context
   *
   * @returns translated text
   */
  getText(): string {
    const translateKey = this.data.trialExpired ? 'subscriptionBanner.expired' : 'subscriptionBanner.active';
    return this.translateService.instant(translateKey, { n: this.data.daysRemaining, appName: this.data.appName });
  }

  /**
   *
   * @param ctx iris context
   * @returns true/false based on context
   */
  allowBanner(ctx: IrisContext) {
    return !flagEnabled(ctx, 'bannerNotificationCrmService');
  };
}
