// Generated from libs/grammar/gcp-volume-exclusion/Gcp.g4 by ANTLR 4.9.0-SNAPSHOT


import { ATN } from "antlr4ts/atn/ATN";
import { ATNDeserializer } from "antlr4ts/atn/ATNDeserializer";
import { FailedPredicateException } from "antlr4ts/FailedPredicateException";
import { NotNull } from "antlr4ts/Decorators";
import { NoViableAltException } from "antlr4ts/NoViableAltException";
import { Override } from "antlr4ts/Decorators";
import { Parser } from "antlr4ts/Parser";
import { ParserRuleContext } from "antlr4ts/ParserRuleContext";
import { ParserATNSimulator } from "antlr4ts/atn/ParserATNSimulator";
import { ParseTreeListener } from "antlr4ts/tree/ParseTreeListener";
import { ParseTreeVisitor } from "antlr4ts/tree/ParseTreeVisitor";
import { RecognitionException } from "antlr4ts/RecognitionException";
import { RuleContext } from "antlr4ts/RuleContext";
//import { RuleVersion } from "antlr4ts/RuleVersion";
import { TerminalNode } from "antlr4ts/tree/TerminalNode";
import { Token } from "antlr4ts/Token";
import { TokenStream } from "antlr4ts/TokenStream";
import { Vocabulary } from "antlr4ts/Vocabulary";
import { VocabularyImpl } from "antlr4ts/VocabularyImpl";

import * as Utils from "antlr4ts/misc/Utils";

import { GcpListener } from "./GcpListener";
import { GcpVisitor } from "./GcpVisitor";


export class GcpParser extends Parser {
	public static readonly OPEN_PAREN = 1;
	public static readonly CLOSE_PAREN = 2;
	public static readonly K_AND = 3;
	public static readonly K_OR = 4;
	public static readonly EQ = 5;
	public static readonly CONTAINS = 6;
	public static readonly IDENTIFIER = 7;
	public static readonly SPACES = 8;
	public static readonly RULE_parse = 0;
	public static readonly RULE_expr = 1;
	public static readonly RULE_exp = 2;
	public static readonly RULE_key = 3;
	public static readonly RULE_value = 4;
	public static readonly RULE_operator = 5;
	public static readonly RULE_orOperator = 6;
	public static readonly RULE_andOperator = 7;
	// tslint:disable:no-trailing-whitespace
	public static readonly ruleNames: string[] = [
		"parse", "expr", "exp", "key", "value", "operator", "orOperator", "andOperator",
	];

	private static readonly _LITERAL_NAMES: Array<string | undefined> = [
		undefined, "'('", "')'", undefined, undefined, "'='", "'~'",
	];
	private static readonly _SYMBOLIC_NAMES: Array<string | undefined> = [
		undefined, "OPEN_PAREN", "CLOSE_PAREN", "K_AND", "K_OR", "EQ", "CONTAINS",
		"IDENTIFIER", "SPACES",
	];
	public static readonly VOCABULARY: Vocabulary = new VocabularyImpl(GcpParser._LITERAL_NAMES, GcpParser._SYMBOLIC_NAMES, []);

	// @Override
	// @NotNull
	public get vocabulary(): Vocabulary {
		return GcpParser.VOCABULARY;
	}
	// tslint:enable:no-trailing-whitespace

	// @Override
	public get grammarFileName(): string { return "Gcp.g4"; }

	// @Override
	public get ruleNames(): string[] { return GcpParser.ruleNames; }

	// @Override
	public get serializedATN(): string { return GcpParser._serializedATN; }

	protected createFailedPredicateException(predicate?: string, message?: string): FailedPredicateException {
		return new FailedPredicateException(this, predicate, message);
	}

	constructor(input: TokenStream) {
		super(input);
		this._interp = new ParserATNSimulator(GcpParser._ATN, this);
	}
	// @RuleVersion(0)
	public parse(): ParseContext {
		let _localctx: ParseContext = new ParseContext(this._ctx, this.state);
		this.enterRule(_localctx, 0, GcpParser.RULE_parse);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 17;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			if (_la === GcpParser.OPEN_PAREN) {
				{
				this.state = 16;
				this.expr(0);
				}
			}

			this.state = 19;
			this.match(GcpParser.EOF);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}

	public expr(): ExprContext;
	public expr(_p: number): ExprContext;
	// @RuleVersion(0)
	public expr(_p?: number): ExprContext {
		if (_p === undefined) {
			_p = 0;
		}

		let _parentctx: ParserRuleContext = this._ctx;
		let _parentState: number = this.state;
		let _localctx: ExprContext = new ExprContext(this._ctx, _parentState);
		let _prevctx: ExprContext = _localctx;
		let _startState: number = 2;
		this.enterRecursionRule(_localctx, 2, GcpParser.RULE_expr, _p);
		try {
			let _alt: number;
			this.enterOuterAlt(_localctx, 1);
			{
			{
			_localctx = new ParenExpContext(_localctx);
			this._ctx = _localctx;
			_prevctx = _localctx;

			this.state = 22;
			this.match(GcpParser.OPEN_PAREN);
			this.state = 23;
			this.exp(0);
			this.state = 24;
			this.match(GcpParser.CLOSE_PAREN);
			}
			this._ctx._stop = this._input.tryLT(-1);
			this.state = 32;
			this._errHandler.sync(this);
			_alt = this.interpreter.adaptivePredict(this._input, 1, this._ctx);
			while (_alt !== 2 && _alt !== ATN.INVALID_ALT_NUMBER) {
				if (_alt === 1) {
					if (this._parseListeners != null) {
						this.triggerExitRuleEvent();
					}
					_prevctx = _localctx;
					{
					{
					_localctx = new OrExpContext(new ExprContext(_parentctx, _parentState));
					this.pushNewRecursionContext(_localctx, _startState, GcpParser.RULE_expr);
					this.state = 26;
					if (!(this.precpred(this._ctx, 2))) {
						throw this.createFailedPredicateException("this.precpred(this._ctx, 2)");
					}
					this.state = 27;
					this.orOperator();
					this.state = 28;
					this.expr(3);
					}
					}
				}
				this.state = 34;
				this._errHandler.sync(this);
				_alt = this.interpreter.adaptivePredict(this._input, 1, this._ctx);
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.unrollRecursionContexts(_parentctx);
		}
		return _localctx;
	}

	public exp(): ExpContext;
	public exp(_p: number): ExpContext;
	// @RuleVersion(0)
	public exp(_p?: number): ExpContext {
		if (_p === undefined) {
			_p = 0;
		}

		let _parentctx: ParserRuleContext = this._ctx;
		let _parentState: number = this.state;
		let _localctx: ExpContext = new ExpContext(this._ctx, _parentState);
		let _prevctx: ExpContext = _localctx;
		let _startState: number = 4;
		this.enterRecursionRule(_localctx, 4, GcpParser.RULE_exp, _p);
		try {
			let _alt: number;
			this.enterOuterAlt(_localctx, 1);
			{
			{
			_localctx = new AtomExprContext(_localctx);
			this._ctx = _localctx;
			_prevctx = _localctx;

			this.state = 36;
			this.key();
			this.state = 37;
			this.operator();
			this.state = 38;
			this.value();
			}
			this._ctx._stop = this._input.tryLT(-1);
			this.state = 46;
			this._errHandler.sync(this);
			_alt = this.interpreter.adaptivePredict(this._input, 2, this._ctx);
			while (_alt !== 2 && _alt !== ATN.INVALID_ALT_NUMBER) {
				if (_alt === 1) {
					if (this._parseListeners != null) {
						this.triggerExitRuleEvent();
					}
					_prevctx = _localctx;
					{
					{
					_localctx = new AndExpContext(new ExpContext(_parentctx, _parentState));
					this.pushNewRecursionContext(_localctx, _startState, GcpParser.RULE_exp);
					this.state = 40;
					if (!(this.precpred(this._ctx, 2))) {
						throw this.createFailedPredicateException("this.precpred(this._ctx, 2)");
					}
					this.state = 41;
					this.andOperator();
					this.state = 42;
					this.exp(3);
					}
					}
				}
				this.state = 48;
				this._errHandler.sync(this);
				_alt = this.interpreter.adaptivePredict(this._input, 2, this._ctx);
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.unrollRecursionContexts(_parentctx);
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public key(): KeyContext {
		let _localctx: KeyContext = new KeyContext(this._ctx, this.state);
		this.enterRule(_localctx, 6, GcpParser.RULE_key);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 49;
			this.match(GcpParser.IDENTIFIER);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public value(): ValueContext {
		let _localctx: ValueContext = new ValueContext(this._ctx, this.state);
		this.enterRule(_localctx, 8, GcpParser.RULE_value);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 51;
			this.match(GcpParser.IDENTIFIER);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public operator(): OperatorContext {
		let _localctx: OperatorContext = new OperatorContext(this._ctx, this.state);
		this.enterRule(_localctx, 10, GcpParser.RULE_operator);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 53;
			_la = this._input.LA(1);
			if (!(_la === GcpParser.EQ || _la === GcpParser.CONTAINS)) {
			this._errHandler.recoverInline(this);
			} else {
				if (this._input.LA(1) === Token.EOF) {
					this.matchedEOF = true;
				}

				this._errHandler.reportMatch(this);
				this.consume();
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public orOperator(): OrOperatorContext {
		let _localctx: OrOperatorContext = new OrOperatorContext(this._ctx, this.state);
		this.enterRule(_localctx, 12, GcpParser.RULE_orOperator);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 55;
			this.match(GcpParser.K_OR);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public andOperator(): AndOperatorContext {
		let _localctx: AndOperatorContext = new AndOperatorContext(this._ctx, this.state);
		this.enterRule(_localctx, 14, GcpParser.RULE_andOperator);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 57;
			this.match(GcpParser.K_AND);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}

	public sempred(_localctx: RuleContext, ruleIndex: number, predIndex: number): boolean {
		switch (ruleIndex) {
		case 1:
			return this.expr_sempred(_localctx as ExprContext, predIndex);

		case 2:
			return this.exp_sempred(_localctx as ExpContext, predIndex);
		}
		return true;
	}
	private expr_sempred(_localctx: ExprContext, predIndex: number): boolean {
		switch (predIndex) {
		case 0:
			return this.precpred(this._ctx, 2);
		}
		return true;
	}
	private exp_sempred(_localctx: ExpContext, predIndex: number): boolean {
		switch (predIndex) {
		case 1:
			return this.precpred(this._ctx, 2);
		}
		return true;
	}

	public static readonly _serializedATN: string =
		"\x03\uC91D\uCABA\u058D\uAFBA\u4F53\u0607\uEA8B\uC241\x03\n>\x04\x02\t" +
		"\x02\x04\x03\t\x03\x04\x04\t\x04\x04\x05\t\x05\x04\x06\t\x06\x04\x07\t" +
		"\x07\x04\b\t\b\x04\t\t\t\x03\x02\x05\x02\x14\n\x02\x03\x02\x03\x02\x03" +
		"\x03\x03\x03\x03\x03\x03\x03\x03\x03\x03\x03\x03\x03\x03\x03\x03\x03\x07" +
		"\x03!\n\x03\f\x03\x0E\x03$\v\x03\x03\x04\x03\x04\x03\x04\x03\x04\x03\x04" +
		"\x03\x04\x03\x04\x03\x04\x03\x04\x07\x04/\n\x04\f\x04\x0E\x042\v\x04\x03" +
		"\x05\x03\x05\x03\x06\x03\x06\x03\x07\x03\x07\x03\b\x03\b\x03\t\x03\t\x03" +
		"\t\x02\x02\x04\x04\x06\n\x02\x02\x04\x02\x06\x02\b\x02\n\x02\f\x02\x0E" +
		"\x02\x10\x02\x02\x03\x03\x02\x07\b\x028\x02\x13\x03\x02\x02\x02\x04\x17" +
		"\x03\x02\x02\x02\x06%\x03\x02\x02\x02\b3\x03\x02\x02\x02\n5\x03\x02\x02" +
		"\x02\f7\x03\x02\x02\x02\x0E9\x03\x02\x02\x02\x10;\x03\x02\x02\x02\x12" +
		"\x14\x05\x04\x03\x02\x13\x12\x03\x02\x02\x02\x13\x14\x03\x02\x02\x02\x14" +
		"\x15\x03\x02\x02\x02\x15\x16\x07\x02\x02\x03\x16\x03\x03\x02\x02\x02\x17" +
		"\x18\b\x03\x01\x02\x18\x19\x07\x03\x02\x02\x19\x1A\x05\x06\x04\x02\x1A" +
		"\x1B\x07\x04\x02\x02\x1B\"\x03\x02\x02\x02\x1C\x1D\f\x04\x02\x02\x1D\x1E" +
		"\x05\x0E\b\x02\x1E\x1F\x05\x04\x03\x05\x1F!\x03\x02\x02\x02 \x1C\x03\x02" +
		"\x02\x02!$\x03\x02\x02\x02\" \x03\x02\x02\x02\"#\x03\x02\x02\x02#\x05" +
		"\x03\x02\x02\x02$\"\x03\x02\x02\x02%&\b\x04\x01\x02&\'\x05\b\x05\x02\'" +
		"(\x05\f\x07\x02()\x05\n\x06\x02)0\x03\x02\x02\x02*+\f\x04\x02\x02+,\x05" +
		"\x10\t\x02,-\x05\x06\x04\x05-/\x03\x02\x02\x02.*\x03\x02\x02\x02/2\x03" +
		"\x02\x02\x020.\x03\x02\x02\x0201\x03\x02\x02\x021\x07\x03\x02\x02\x02" +
		"20\x03\x02\x02\x0234\x07\t\x02\x024\t\x03\x02\x02\x0256\x07\t\x02\x02" +
		"6\v\x03\x02\x02\x0278\t\x02\x02\x028\r\x03\x02\x02\x029:\x07\x06\x02\x02" +
		":\x0F\x03\x02\x02\x02;<\x07\x05\x02\x02<\x11\x03\x02\x02\x02\x05\x13\"" +
		"0";
	public static __ATN: ATN;
	public static get _ATN(): ATN {
		if (!GcpParser.__ATN) {
			GcpParser.__ATN = new ATNDeserializer().deserialize(Utils.toCharArray(GcpParser._serializedATN));
		}

		return GcpParser.__ATN;
	}

}

export class ParseContext extends ParserRuleContext {
	public EOF(): TerminalNode { return this.getToken(GcpParser.EOF, 0); }
	public expr(): ExprContext | undefined {
		return this.tryGetRuleContext(0, ExprContext);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return GcpParser.RULE_parse; }
	// @Override
	public enterRule(listener: GcpListener): void {
		if (listener.enterParse) {
			listener.enterParse(this);
		}
	}
	// @Override
	public exitRule(listener: GcpListener): void {
		if (listener.exitParse) {
			listener.exitParse(this);
		}
	}
	// @Override
	public accept<Result>(visitor: GcpVisitor<Result>): Result {
		if (visitor.visitParse) {
			return visitor.visitParse(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class ExprContext extends ParserRuleContext {
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return GcpParser.RULE_expr; }
	public copyFrom(ctx: ExprContext): void {
		super.copyFrom(ctx);
	}
}
export class OrExpContext extends ExprContext {
	public expr(): ExprContext[];
	public expr(i: number): ExprContext;
	public expr(i?: number): ExprContext | ExprContext[] {
		if (i === undefined) {
			return this.getRuleContexts(ExprContext);
		} else {
			return this.getRuleContext(i, ExprContext);
		}
	}
	public orOperator(): OrOperatorContext {
		return this.getRuleContext(0, OrOperatorContext);
	}
	constructor(ctx: ExprContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: GcpListener): void {
		if (listener.enterOrExp) {
			listener.enterOrExp(this);
		}
	}
	// @Override
	public exitRule(listener: GcpListener): void {
		if (listener.exitOrExp) {
			listener.exitOrExp(this);
		}
	}
	// @Override
	public accept<Result>(visitor: GcpVisitor<Result>): Result {
		if (visitor.visitOrExp) {
			return visitor.visitOrExp(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
export class ParenExpContext extends ExprContext {
	public OPEN_PAREN(): TerminalNode { return this.getToken(GcpParser.OPEN_PAREN, 0); }
	public exp(): ExpContext {
		return this.getRuleContext(0, ExpContext);
	}
	public CLOSE_PAREN(): TerminalNode { return this.getToken(GcpParser.CLOSE_PAREN, 0); }
	constructor(ctx: ExprContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: GcpListener): void {
		if (listener.enterParenExp) {
			listener.enterParenExp(this);
		}
	}
	// @Override
	public exitRule(listener: GcpListener): void {
		if (listener.exitParenExp) {
			listener.exitParenExp(this);
		}
	}
	// @Override
	public accept<Result>(visitor: GcpVisitor<Result>): Result {
		if (visitor.visitParenExp) {
			return visitor.visitParenExp(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class ExpContext extends ParserRuleContext {
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return GcpParser.RULE_exp; }
	public copyFrom(ctx: ExpContext): void {
		super.copyFrom(ctx);
	}
}
export class AndExpContext extends ExpContext {
	public exp(): ExpContext[];
	public exp(i: number): ExpContext;
	public exp(i?: number): ExpContext | ExpContext[] {
		if (i === undefined) {
			return this.getRuleContexts(ExpContext);
		} else {
			return this.getRuleContext(i, ExpContext);
		}
	}
	public andOperator(): AndOperatorContext {
		return this.getRuleContext(0, AndOperatorContext);
	}
	constructor(ctx: ExpContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: GcpListener): void {
		if (listener.enterAndExp) {
			listener.enterAndExp(this);
		}
	}
	// @Override
	public exitRule(listener: GcpListener): void {
		if (listener.exitAndExp) {
			listener.exitAndExp(this);
		}
	}
	// @Override
	public accept<Result>(visitor: GcpVisitor<Result>): Result {
		if (visitor.visitAndExp) {
			return visitor.visitAndExp(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
export class AtomExprContext extends ExpContext {
	public key(): KeyContext {
		return this.getRuleContext(0, KeyContext);
	}
	public operator(): OperatorContext {
		return this.getRuleContext(0, OperatorContext);
	}
	public value(): ValueContext {
		return this.getRuleContext(0, ValueContext);
	}
	constructor(ctx: ExpContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: GcpListener): void {
		if (listener.enterAtomExpr) {
			listener.enterAtomExpr(this);
		}
	}
	// @Override
	public exitRule(listener: GcpListener): void {
		if (listener.exitAtomExpr) {
			listener.exitAtomExpr(this);
		}
	}
	// @Override
	public accept<Result>(visitor: GcpVisitor<Result>): Result {
		if (visitor.visitAtomExpr) {
			return visitor.visitAtomExpr(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class KeyContext extends ParserRuleContext {
	public IDENTIFIER(): TerminalNode { return this.getToken(GcpParser.IDENTIFIER, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return GcpParser.RULE_key; }
	// @Override
	public enterRule(listener: GcpListener): void {
		if (listener.enterKey) {
			listener.enterKey(this);
		}
	}
	// @Override
	public exitRule(listener: GcpListener): void {
		if (listener.exitKey) {
			listener.exitKey(this);
		}
	}
	// @Override
	public accept<Result>(visitor: GcpVisitor<Result>): Result {
		if (visitor.visitKey) {
			return visitor.visitKey(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class ValueContext extends ParserRuleContext {
	public IDENTIFIER(): TerminalNode { return this.getToken(GcpParser.IDENTIFIER, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return GcpParser.RULE_value; }
	// @Override
	public enterRule(listener: GcpListener): void {
		if (listener.enterValue) {
			listener.enterValue(this);
		}
	}
	// @Override
	public exitRule(listener: GcpListener): void {
		if (listener.exitValue) {
			listener.exitValue(this);
		}
	}
	// @Override
	public accept<Result>(visitor: GcpVisitor<Result>): Result {
		if (visitor.visitValue) {
			return visitor.visitValue(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class OperatorContext extends ParserRuleContext {
	public EQ(): TerminalNode | undefined { return this.tryGetToken(GcpParser.EQ, 0); }
	public CONTAINS(): TerminalNode | undefined { return this.tryGetToken(GcpParser.CONTAINS, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return GcpParser.RULE_operator; }
	// @Override
	public enterRule(listener: GcpListener): void {
		if (listener.enterOperator) {
			listener.enterOperator(this);
		}
	}
	// @Override
	public exitRule(listener: GcpListener): void {
		if (listener.exitOperator) {
			listener.exitOperator(this);
		}
	}
	// @Override
	public accept<Result>(visitor: GcpVisitor<Result>): Result {
		if (visitor.visitOperator) {
			return visitor.visitOperator(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class OrOperatorContext extends ParserRuleContext {
	public K_OR(): TerminalNode { return this.getToken(GcpParser.K_OR, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return GcpParser.RULE_orOperator; }
	// @Override
	public enterRule(listener: GcpListener): void {
		if (listener.enterOrOperator) {
			listener.enterOrOperator(this);
		}
	}
	// @Override
	public exitRule(listener: GcpListener): void {
		if (listener.exitOrOperator) {
			listener.exitOrOperator(this);
		}
	}
	// @Override
	public accept<Result>(visitor: GcpVisitor<Result>): Result {
		if (visitor.visitOrOperator) {
			return visitor.visitOrOperator(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class AndOperatorContext extends ParserRuleContext {
	public K_AND(): TerminalNode { return this.getToken(GcpParser.K_AND, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return GcpParser.RULE_andOperator; }
	// @Override
	public enterRule(listener: GcpListener): void {
		if (listener.enterAndOperator) {
			listener.enterAndOperator(this);
		}
	}
	// @Override
	public exitRule(listener: GcpListener): void {
		if (listener.exitAndOperator) {
			listener.exitAndOperator(this);
		}
	}
	// @Override
	public accept<Result>(visitor: GcpVisitor<Result>): Result {
		if (visitor.visitAndOperator) {
			return visitor.visitAndOperator(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


