import { Component, OnInit } from '@angular/core';
import { BannerServiceApi } from '@cohesity/api/v1';
import { AjaxHandlerService } from '@cohesity/utils';


@Component({
  selector: 'coh-login-banner',
  templateUrl: './login-banner.component.html',
  styleUrls: ['./login-banner.component.scss']
})
export class LoginBannerComponent implements OnInit {

  /**
   * The Banner content
   */
  content: string;

  /**
   * constructor
   */
  constructor(private bannerService: BannerServiceApi,
    private ajaxHandler: AjaxHandlerService) { }

  /**
   * On Init lifecycle event.
   */
  ngOnInit() {
    this.bannerService.GetBanner({}).subscribe(
      data => this.content = data.content,
      err => this.ajaxHandler.errorMessage(err),
    );
  }

}
