
<h1 mat-dialog-title> {{ 'recovery.vm.protectionGroupSelection' | translate }} </h1>
<div mat-dialog-content>
  <p> {{ 'recovery.vm.protectionGroupSelectionQuestion' | translate: { count: protectedGroupObjects?.length } }} </p>
  <h5> {{ 'recovery.vm.protectionGroupSelectionList' | translate }} </h5>

  <cog-table
    name="group-run-objects-selector-table"
    [source]="protectedGroupObjects">

    <table mat-table>
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef>{{'name' | translate}}</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <cog-icon class="margin-right" [shape]="'helix:object-vm'"></cog-icon>
          <span class="user-data-xl auto-truncate object-name">{{row.name}}</span>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="['name']"></mat-header-row>
      <mat-row *matRowDef="let row; columns: ['name']"></mat-row>
    </table>
    <mat-paginator [length]="protectedGroupObjects?.length"></mat-paginator>
  </cog-table>
</div>

<div mat-dialog-actions align="end">
  <button
    mat-button
    mat-dialog-close
    color="primary"
    cogDataId="cancel">
    {{ 'cancel' | translate }}
  </button>
  <button
    (click)="onConfirm()"
    mat-flat-button
    type="submit"
    color="primary"
    cdkFocusInitial
    cogDataId="confirm">
    {{ 'confirm' | translate }}
  </button>
</div>
