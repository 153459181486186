<!-- File extension list Component -->
<div class="flex-row" *ngIf="enableAction">
  <mat-form-field class="file-extension-field">
    <input matInput
      [formControl]="fileExtension"
      cogDataId="file-extension-input"
      (keydown.enter)="addExtensions()">
    <mat-hint translate>views.extensions.hint</mat-hint>
    <mat-error *ngIf="fileExtension.hasError('pattern')"
               [cogDataId]="'file-extension-invalid-error'">
      {{ 'errors.invalid' | translate }}
    </mat-error>
  </mat-form-field>

  <div class="padding-lg">
    <button
      mat-stroked-button
      type="button"
      color="primary"
      cogDataId="add-file-extensions"
      [disabled]="fileExtension.hasError('pattern')"
      (click)="addExtensions()">
      <cog-icon shape="add"></cog-icon>
      {{'add' | translate}}
    </button>
  </div>
</div>
<mat-list class="extension-list" *ngIf="extensionList?.length">
  <mat-list-item class="extension-item" *ngFor="let extension of extensionList;
    let index = index">
    <div class="file-extension-field">.{{extension}}</div>
    <button mat-button
      *ngIf="enableAction"
      type="button"
      cogDataId="remove-extension-{{index}}"
      (click)="removeExtension(extension)">
      <cog-icon shape="clear"></cog-icon>
    </button>
  </mat-list-item>
  <mat-list-item *ngIf="!message" class="extension-item">
    {{ (isS3Only ? 'views.extensions.totalCountObjectKeySuffixes' : 'views.extensions.totalCountFileTypes') | translate: {
      num: extensionList.length,
      type: (mode === 'Blacklist' ? 'denied' : 'allowed') | translate
    } }}
  </mat-list-item>

  <mat-list-item *ngIf="message" class="extension-item">
    {{message}}
  </mat-list-item>
</mat-list>
