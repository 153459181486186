import { ChangeDetectionStrategy, Component, forwardRef, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SnapshotReplicas_Replica } from '@cohesity/api/private';
import { Subject } from 'rxjs';

@Component({
  selector: 'coh-snapshot-target-selector',
  templateUrl: './snapshot-target-selector.component.html',
  styleUrls: ['./snapshot-target-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SnapshotTargetSelectorComponent),
      multi: true,
    },
  ],
})
export class SnapshotTargetSelectorComponent implements ControlValueAccessor, OnChanges {

  /**
   * The list of available snapshot replicas to choose from. By the time
   * the list gets here it should already have filtered out replication types
   * and only have local or archived replicas.
   */
  @Input() replicas: SnapshotReplicas_Replica[];

  /**
   * The selected target replica. This defaults to the first value in the list
   * if it is not set.
   */
  @Input() selectedTarget: SnapshotReplicas_Replica;

  /**
   * If this is set to true, the user can click the icon to select a target
   * if not, it is shown in a 'read only' model.
   */
  @Input() allowSelection = true;

  /**
   * Change event for whenever the selected target changes.
   */
  @Output() selectedTargetChange = new Subject<SnapshotReplicas_Replica>();

  /**
   * Returns true if the given target is local (type 1).
   *
   * @param   target   The target to check.
   * @return  True if the target is local.
   */
  isLocal(target: SnapshotReplicas_Replica): boolean {
    return target.target.type === 1;
  }

  /**
   * When the user makes a selection, updated the selected target and
   * dispatch a change event.
   *
   * @param   target  The target selected by the user.
   */
  selectSnapshotTarget(target: SnapshotReplicas_Replica) {
    if (this.allowSelection) {
      this.selectedTarget = target;
      this.selectedTargetChange.next(this.selectedTarget);
      this.propagateChange(this.selectedTarget);
    }
  }

  /**
   * Whenever the list of replicas is changed, select the first item
   * in the list.
   *
   * @param   changes   A map of change objects
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes.replicas && this.replicas) {
      this.selectSnapshotTarget(this.replicas[0]);
    }
  }

  /**
   * Callback to pass the change event back to a form control. Defaults to a noop
   */
  propagateChange = (_value: SnapshotReplicas_Replica) => {};

  /**
   * Update the view with a value passed from a form
   *
   * @param   value   the new form control value
   */
  writeValue(value: SnapshotReplicas_Replica) {
    this.selectedTarget = value;
  }

  /**
   * Registers a change event handler to use to propogate changes
   *
   * @param   fn   the callback function
   */
  registerOnChange(fn: (value: SnapshotReplicas_Replica) => any) {
    this.propagateChange = fn;
  }

  /**
   * Register on touched. Not currently used, but required by the interface
   */
  registerOnTouched() { }
}
