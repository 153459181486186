import { ReportColumnConfigs } from '../common-report-items/custom-reports.service';
import { ReportSection } from '../iris-reporting.model';

/**
 * This is the layout config for the protection runs report.
 */
export const protectionActivitiesReport: ReportSection[] = [
  {
    title: 'reporting.summary',
    items: [
      {
        id: 'statlistItem',
        componentIds: ['1001'],
        componentNames: ['ProtectionActivitySummary'],
        params: {
          items: [
            {
              title: 'reporting.successRate',
              translate: true,
              showChart: true,
              measurement: {
                valueKey: 'countGroupId',
                dataType: 'percent',
                filters: [
                  {
                    filterKey: 'status',
                    value: 'kSuccess',
                  },
                  {
                    filterKey: 'status',
                    value: 'kWarning',
                  },
                ],
                aggregation: 'percent',
              },
            },
            {
              title: 'reporting.totalRuns',
              translate: true,
              measurement: {
                valueKey: 'countGroupId',
                dataType: 'number',
              }
            },
            {
              title: 'reporting.totalSuccessful',
              translate: true,
              measurement: {
                valueKey: 'countGroupId',
                dataType: 'number',
                filters: [
                  {
                    filterKey: 'status',
                    value: 'kSuccess',
                  },
                  {
                    filterKey: 'status',
                    value: 'kWarning',
                  },
                ],
              },
            },
            {
              title: 'reporting.success',
              translate: true,
              measurement: {
                valueKey: 'countGroupId',
                dataType: 'number',
                filter: {
                  filterKey: 'status',
                  value: 'kSuccess',
                },
              },
            },
            {
              title: 'reporting.warning',
              translate: true,
              measurement: {
                valueKey: 'countGroupId',
                dataType: 'number',
                filter: {
                  filterKey: 'status',
                  value: 'kWarning',
                },
              },
            },
            {
              title: 'reporting.failed',
              translate: true,
              measurement: {
                valueKey: 'countGroupId',
                dataType: 'number',
                filter: {
                  filterKey: 'status',
                  value: 'kFailure',
                },
              },
            },
            {
              title: 'reporting.canceled',
              translate: true,
              measurement: {
                valueKey: 'countGroupId',
                dataType: 'number',
                filter: {
                  filterKey: 'status',
                  value: 'kCanceled',
                },
              },
            },
            {
              title: 'reporting.skipped',
              translate: true,
              measurement: {
                valueKey: 'countGroupId',
                dataType: 'number',
                filter: {
                  filterKey: 'status',
                  value: 'kSkipped',
                },
              },
            },
            {
              title: 'reporting.running',
              translate: true,
              measurement: {
                valueKey: 'countGroupId',
                dataType: 'number',
                filter: {
                  filterKey: 'status',
                  value: 'kRunning',
                },
              },
            },
            {
              title: 'reporting.slaMet',
              translate: true,
              measurement: {
                valueKey: 'countGroupId',
                dataType: 'number',
                filter: {
                  filterKey: 'slaStatus',
                  value: 'Met',
                },
              },
            },
            {
              title: 'reporting.slaMissed',
              translate: true,
              measurement: {
                valueKey: 'countGroupId',
                dataType: 'number',
                filter: {
                  filterKey: 'slaStatus',
                  value: 'Missed',
                },
              },
            },
            // {
            //   title: 'reporting.totalProtectionGroups',
            //   translate: true,
            //   measurement: {
            //     valueKey: 'countGroupId',
            //     dataType: 'number',
            //   }
            // },
          ],
        },
      },
    ],
  },
  {
    items: [
      {
        title: 'reporting.runStatusByActivityType',
        id: 'chartItem',
        componentIds: ['1002'],
        componentNames: ['StatusByActivity'],
        params: {
          chartType: 'column',
          measurements: [
            {
              valueKey: 'countGroupId',
              dataType: 'number'
            },
          ],
          dimensions: [
            {
              dimensionKey: 'activityType',
              maxValues: 3,
            },
            {
              dimensionKey: 'status',
              maxValues: 3,
            },
          ],
        },
      },
      {
        title: 'reporting.runStatusByObjectType',
        id: 'chartItem',
        componentIds: ['1003'],
        componentNames: ['StatusByEnvironment'],
        params: {
          chartType: 'column',
          measurements: [
            {
              valueKey: 'countGroupId',
              dataType: 'number'
            },
          ],
          dimensions: [
            {
              dimensionKey: 'environment',
              maxValues: 3,
            },
            {
              dimensionKey: 'status',
              maxValues: 3,
            },
          ],
        },
      },
    ],
  },
  {
    title: 'reporting.data',
    isFullData: true,
    items: [
      {
        id: 'tableItem',
        componentIds: ['1000'],
        componentNames: ['AllRuns'],
        params: {
          columns: [
            {
              title: 'startTime',
              translate: true,
              key: 'runStartTimeUsecs',
              icon: {
                key: 'status',
                dataType: 'statusIcon',
              },
              primary: {
                key: 'runStartTimeUsecs',
                dataType: 'date',
                renderParams: {
                  usecs: true,
                },
              },
              // subtitle: {
              //   key: 'backupType',
              //   dataType: 'backupType',
              // },
            },
            {
              title: 'duration',
              translate: true,
              key: 'durationUsecs',
              sortStart: 'desc',
              primary: {
                key: 'durationUsecs',
                dataType: 'duration',
                renderParams: {
                  usecs: true,
                },
              },
            },
            {
              title: 'endTime',
              translate: true,
              key: 'endTimeUsecs',
              primary: {
                key: 'endTimeUsecs',
                dataType: 'date',
                renderParams: {
                  usecs: true,
                },
              },
            },
            {
              title: 'groupName',
              translate: true,
              key: 'groupName',
              icon: {
                key: 'environment',
                dataType: 'envGroupIcon',
              },
              primary: {
                key: 'groupName',
                dataType: 'text',
              },
              subtitle: {
                key: 'environment',
                dataType: 'environmentName',
              },
            },
            // {
            //   title: 'source',
            //   enabled: true,
            //   translate: 'true',
            //   key: 'sourceName',
            //   primary: {
            //     key: 'sourceName',
            //     dataType: 'text',
            //   },
            // },
            {
              title: 'policy',
              enabled: true,
              translate: 'true',
              key: 'policyName',
              primary: {
                key: 'policyName',
                dataType: 'text',
              },
              subtitle: {
                key: 'policyRetentionDays',
                dataType: 'text',
                renderParams: {
                  translate: true,
                  translateValue: 'reporting.retainValueD'
                }
              },
            },
            {
              title: 'organization',
              enabled: false,
              translate: true,
              key: 'tenantName',
              primary: {
                key: 'tenantName',
                dataType: 'text',
                renderParams: {
                  emptyKey: 'naNotAvailable',
                },
              },
              featureFlag: 'ngReportSPView',
            },
            {
              title: 'system',
              enabled: true,
              translate: 'true',
              key: 'systemName',
              primary: {
                key: 'systemName',
                dataType: 'text',
              },
            },
            {
              title: 'activityType',
              translate: true,
              key: 'activityType',
              primary: {
                key: 'activityType',
                dataType: 'activityType',
              },
            },
            {
              title: 'target',
              enabled: false,
              translate: true,
              key: 'targetName',
              primary: {
                key: 'targetName',
                dataType: 'text',
              },
            },
            {
              title: 'sla',
              enabled: true,
              translate: true,
              key: 'slaStatus',
              primary: {
                key: 'slaStatus',
                dataType: 'enumType',
              },
            },
            {
              title: 'dataRead',
              enabled: false,
              translate: true,
              key: 'dataRead',
              primary: {
                key: 'dataRead',
                dataType: 'bytes',
              },
            },
            {
              title: 'dataWritten',
              enabled: false,
              translate: true,
              key: 'dataWritten',
              primary: {
                key: 'dataWritten',
                dataType: 'bytes',
              },
            },
            {
              title: 'logicalDataTransferred',
              enabled: false,
              translate: true,
              key: 'logicalDataTransferred',
              primary: {
                key: 'logicalDataTransferred',
                dataType: 'bytes',
              },
            },
            {
              title: 'physicalDataTransferred',
              enabled: false,
              translate: true,
              key: 'physicalDataTransferred',
              primary: {
                key: 'physicalDataTransferred',
                dataType: 'bytes',
              },
            }
          ],
        },
      },
    ],
  },
];

/**
 * This is metadata needed to determine available chart customizations.
 */
export const protectionActivitiesColumns: ReportColumnConfigs = {
  status: {
    aggs: [],
    canGroup: true,
    dataType: 'text',
  },
  runStartTimeUsecs: {
    aggs: [],
    dataType: 'date'
  },
  policyId: {
    aggs: ['count'],
    dataType: 'uuid'
  },
  policyName: {
    aggs: [],
    canGroup: true,
    maxGroupingValues: 5,
    dataType: 'text'
  },
  groupName: {
    aggs: [],
    canGroup: true,
    maxGroupingValues: 5,
    dataType: 'text',
  },
  groupId: {
    aggs: ['count'],
    dataType: 'uuid',
  },
  environment: {
    aggs: ['count'],
    canGroup: true,
    maxGroupingValues: 3,
    dataType: 'text',
  },
  activityType: {
    aggs: ['count'],
    canGroup: true,
    dataType: 'text',
  },
  systemName: {
    aggs: [],
    canGroup: true,
    maxGroupingValues: 5,
    dataType: 'text',
  },
  targetName: {
    aggs: [],
    canGroup: true,
    maxGroupingValues: 5,
    dataType: 'text',
  },
  systemId: {
    aggs: ['count'],
    dataType: 'uuid',
  },
  slaStatus: {
    aggs: ['count'],
    canGroup: true,
    maxGroupingValues: 3,
    dataType: 'text',
  },
};
