// Module: Recovery config

;(function(angular, undefined) {
  'use strict';

  var moduleDeps = [
    'C.createRecoverButton',
    'C.dbRestore',
    'C.PulseEventLogs',
    'C.restoreService',
    'C.searchService',
  ];

  angular.module('C.recovery', moduleDeps)
    .config(ConfigFn);

  /**
   * States Config: Recovery
   */
  function ConfigFn($stateProvider) {
    // Access permissions for DB Migration task detail states
    var DB_MIGRATION_ACCESS_PERMISSIONS =
      'FEATURE_FLAGS.databaseMigration && RESTORE_VIEW'

    $stateProvider
      .state('recover', {
        url: '^/protection/recovery',
        help: 'protection_recovery',
        title: 'Recovery Management',
        canAccess: 'RESTORE_VIEW',
        redirectTo: 'recover-local',
      })
      .state('recover-local', {
        url: '^/protection/recovery/local',
        help: 'protection_recovery',
        title: 'Recovery Management - Local Task Listing',
        canAccess: 'RESTORE_VIEW',
        params: {
          recoveryDateRange: { type: 'string', value: null },
        },
        parentState: 'recover',
        templateUrl: 'app/protection/recovery/recovery.html',
        controller: 'recoveryController as $ctrl',
      })
      .state('recover-cloud', {
        url: '^/protection/recovery/cloud',
        help: 'protection_recovery',
        title: 'Recovery Management - Cloud Recovery Task Listing',
        canAccess: 'RESTORE_VIEW',
        params: {
          recoveryDateRange: { type: 'string', value: null },
        },
        parentState: 'recover',
        templateUrl: 'app/protection/recovery/recovery.html',
        controller: 'recoveryController as $ctrl',
      })
      .state('recover-tape', {
        url: '^/protection/recovery/tape',
        help: 'protection_recovery',
        title: 'Recovery Management - Tape Recovery Task Listing',
        canAccess: 'RESTORE_VIEW',
        params: {
          recoveryDateRange: { type: 'string', value: null },
        },
        parentState: 'recover',
        templateUrl: 'app/protection/recovery/recovery.html',
        controller: 'recoveryController as $ctrl',
      })
      .state('recover-detail-local', {
        url: '^/protection/recovery/detail/local/{id}/?{force&}',
        help: 'protection_recovery',
        title: 'Recovery Management - Local Recover Task Detail',
        canAccess: 'RESTORE_VIEW',
        templateUrl: 'app/protection/recovery/detail/local.html',
        controller: 'recoveryTaskLocalDetailController as $ctrl',
        parentState: 'recover-local',
        params: {
          id: { type: 'string' },
          isMultiStageRestore: false,
          force: { type: 'cBool' },
        },
        redirectTo: function redirector(trans) {
          var stateParams = trans.params();

          // If this is a multi-stage restore (HotStandby/DB Migration),
          // redirect to the details route within.
          if (stateParams.isMultiStageRestore) {
            return {
              state: 'recover-detail-local.db-migration-details',
              params: stateParams,
            };
          }

          // Otherwise just continue to the normal detail route.
          return;
        },
      })
      .state('recover-detail-archive', {
        url: '^/protection/recovery/detail/archive/{id}/?{force&}',
        help: 'protection_recovery',
        title: 'Recovery Management - Archive Recover Task Detail',
        canAccess: 'RESTORE_VIEW',
        params: {
          id: { type: 'string' },
          force: { type: 'cBool' },
        },
        templateUrl: 'app/protection/recovery/detail/archive.html',
        controller: 'recoveryTaskArchiveDetailController as $ctrl',
        parentState: 'recover-cloud',
      })

      // Multi-stage Recovery specific sub-views.
      .state('recover-detail-local.db-migration-details', {
        url: 'details',
        help: 'protection_recovery',
        title: 'Database Migration Details',
        canAccess: DB_MIGRATION_ACCESS_PERMISSIONS,
        parentState: 'recover-local',
        component: 'cDbMigrationTaskDetails',
        params: {
          taskId: undefined,
        },
        resolve: {
          taskId: function($transition$) {
            return +$transition$.params().id;
          },
        },
      })
      .state('recover-detail-local.db-migration-settings', {
        url: 'settings',
        help: 'protection_recovery',
        title: 'Database Migration Settings',
        canAccess: DB_MIGRATION_ACCESS_PERMISSIONS,
        parentState: 'recover-local',
        component: 'cDbMigrationTaskSettings',
        params: {
          restoreTask: undefined,
        },
        resolve: {
          restoreTask: function($transition$) {
            return $transition$.params().restoreTask;
          },
        },
      })
      .state('recover-detail-local.vmdk-details', {
        url: 'vmdk-details',
        title: 'VM Disk Details',
        canAccess: 'RESTORE_VIEW',
      })
      .state('recover-detail-local.vmdk-log', {
        url: 'vmdk-log',
        title: 'VM Disk Details',
        canAccess: 'RESTORE_VIEW',
        parentState: 'recover-local',
      })
      .state('recover-detail-archive.vmdk-details', {
        url: 'vmdk-details',
        title: 'VM Disk Details',
        canAccess: 'RESTORE_VIEW',
        parentState: 'recover-cloud',
      })
      .state('recover-detail-archive.vmdk-log', {
        url: 'vmdk-log',
        title: 'VM Disk Details',
        canAccess: 'RESTORE_VIEW',
        parentState: 'recover-cloud',
      });

  }

})(angular);
