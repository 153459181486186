import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import {
  ChangeDetectionStrategy,
  Component,
  ComponentRef,
  Injector,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';

import { DynamicFormComponent } from '../../model/dynamic-form-component';

/**
 * This component can be used as a portal to dynamically add a form component into a parent form.
 * This class will configure an Injector with the form information and create a ComponentPortal
 * to render.
 *
 * @example
 * <coh-dynamic-form-portal [component]="vmParamsComponent"
 *    [control]="formGroupControls.objectParams"
 *     [parentForm]="formGroup" >
 *  </coh-dynamic-form-portal>
 */
@Component({
  selector: 'coh-dynamic-form-portal',
  template: `
    <ng-template *ngIf="component"
      (attached)="onAttached($event)"
      [cdkPortalOutlet]="formPortal">
    </ng-template>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicFormPortalComponent implements OnChanges {
  /**
   * The component type to create. The component must implement the DynamicFormComponent class.
   * Generally, this component is a wrapper around the actual form component that is created with
   * the formControl directive.
   */
  @Input() component: ComponentType<DynamicFormComponent<any>>;

  /**
   * The form control being created
   */
  @Input() control: AbstractControl;

  /**
   * The parent form group.
   */
  @Input() parentForm: UntypedFormGroup;

  /**
   * Any additional properties passed to the portal component.
   */
  @Input() properties: any;

  /**
   * The form portal for the currently set component.
   */
  formPortal: ComponentPortal<DynamicFormComponent<any>>;

  /**
   * A reference to the created component
   */
  private componentRef: ComponentRef<any>;

  constructor(private injector: Injector) {}

  /**
   * Handle the component being attacked.
   *
   * @param componentRef A reference to the newly created component
   */
  onAttached(componentRef: ComponentRef<any>) {
    this.componentRef = componentRef;

    if (this.componentRef) {
      this.componentRef.instance.properties = this.properties;
    }
  }

  /**
   * Update the form options portal whenever the component input is changed. This assumes
   * that the form controls themselves do not change once they have been created.
   */
  ngOnChanges(changes: SimpleChanges) {
    if (!changes.component || !this.component) {
      return;
    }

    const injector = Injector.create({
      parent: this.injector,
      providers: [
        { provide: AbstractControl, useValue: this.control },
        { provide: UntypedFormGroup, useValue: this.parentForm },
      ],
    });

    this.formPortal = new ComponentPortal(this.component, undefined, injector);

    if (this.componentRef) {
      this.componentRef.instance.properties = this.properties;
    }
  }
}
