import { ComponentType } from '@angular/cdk/portal';
import { Component, EventEmitter, HostBinding, Inject, Injector, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { CreateRecoveryRequest } from '@cohesity/api/v2';
import { Environment } from '@cohesity/iris-shared-constants';
import { merge } from 'lodash';
import { Controls, DataInput, NgxRootFormComponent } from 'ngx-sub-form';
import { RestoreService } from 'src/app/modules/restore/restore-shared';
import { OracleRecoveryForm } from 'src/app/shared';

import { CreateRecoveryForm } from '../../model/create-recovery-form';
import { DynamicFormComponent } from '../../model/dynamic-form-component';
import { Recovery } from '../../model/recovery';
import { RecoveryFormContextService } from '../../model/recovery-form-context';
import {
  RECOVERY_FORM_OPTIONS,
  RecoveryFormConfig,
  RecoveryFormOptions,
} from '../../model/recovery-form-options-provider';

/**
 * Recovery Options component for the recovery detail page options tab content.
 *
 * @example
 * <coh-recovery-options [recovery]="recovery"></coh-recovery-options>
 */
@Component({
  selector: 'coh-recovery-options',
  styleUrls: ['./recovery-options.component.scss'],
  templateUrl: './recovery-options.component.html',
  providers: [RecoveryFormContextService],
  encapsulation: ViewEncapsulation.None
})
export class RecoveryOptionsComponent extends NgxRootFormComponent<CreateRecoveryRequest, CreateRecoveryForm<any>>
  implements OnInit  {
  /**
   * Recovery
   */
  @Input() recovery: Recovery;

  /**
   * This is a list of all of the registered form options for each adapter.
   */
  private recoveryFormOptions: RecoveryFormOptions = {};

  /**
   * This displays the read-only vm params form inside of a cdk component portal.
   */
  formComponent: ComponentType<DynamicFormComponent<any>>;

  /**
   * Data input can be used to initialize the form value.
   */
  @DataInput()
  dataInput: Required<CreateRecoveryRequest>;

  /**
   * Data output contains the output of the form.
   */
  dataOutput: EventEmitter<CreateRecoveryRequest> = new EventEmitter();

  /**
   * Adds "read-only" class to component if form context is in read only mode.
   */
  @HostBinding('class.read-only') get isReadOnly() {
    return this.recoveryContext.isReadOnly;
  }

  constructor(
    private injector: Injector,
    private recoveryContext: RecoveryFormContextService,
    public recoverService: RestoreService,
    @Inject(RECOVERY_FORM_OPTIONS) recoveryFormConfigs: RecoveryFormOptions[]
  ) {
    super();
    merge(this.recoveryFormOptions, ...recoveryFormConfigs);
  }

  /**
   * Initialization.
   */
  ngOnInit() {
    const {
      formComponent,
      formTransformer
    }: RecoveryFormConfig = this.getRecoveryFormConfig();

    // Set this value so that individual form components can initialize themselves based on this object.
    this.recoveryContext.initialRecoveryConfig = this.recovery.reference;
    this.recoveryContext.isReadOnly = true;

    if (formComponent && formTransformer) {
      const transformer = this.injector.get(formTransformer);

      this.formComponent = formComponent;

      const recoveryReference = transformer.transformToRecoveryForm(<CreateRecoveryRequest>this.recovery.reference);
      this.formGroupControls.objectParams.setValue(recoveryReference.objectParams);

      if (this.recovery.environment === Environment.kVMware || this.recovery.environment === Environment.kAzure) {
        const objects = this.recoverService.getObjectSelectionsFromRecovery(this.recovery.reference);
        this.formGroupControls.objects.setValue(objects);
      }

      if (this.recovery.environment === Environment.kOracle) {
        const config: OracleRecoveryForm = transformer.transformToRecoveryForm(this.recovery.reference);

        this.formGroup.addControl('taskType', new UntypedFormControl(config.taskType));
        this.formGroup.addControl('recoveryType', new UntypedFormControl(config.recoveryType));
        this.formGroup.addControl('oracleArchiveLogInfo', new UntypedFormControl(config.oracleArchiveLogInfo));
      }
      this.formGroupControls.objectParams.markAsPristine();
    }
  }

  /**
   * Get the form configuration for the specified environment.
   *
   * @returns Configuration used to load the form component and transform the data model.
   */
  private getRecoveryFormConfig(): RecoveryFormConfig {
    const config = this.recoveryFormOptions[this.recovery.environment] &&
      this.recoveryFormOptions[this.recovery.environment][this.recovery.action];

    if (!config) {
      throw new Error('Missing Form Options Config');
    }
    return config;
  }

  /**
   * Initializes the form controls.
   *
   * @returns  The form's controls.
   */
  protected getFormControls(): Controls<CreateRecoveryForm<any>> {
    return {
      objects: new UntypedFormControl(null, Validators.required),
      objectParams: new UntypedFormControl(null, Validators.required),
    };
  }
}
