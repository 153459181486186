import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Ability, ClusterIdentifierId, DataSource, DataSourcesApiService, Environment } from '@cohesity/api/argus';
import { App, McmAppsManagementServiceApi, McmClusterInfo, McmClusterServiceApi } from '@cohesity/api/private';
import { GetLinkFn, GetLinkParamsFn } from '@cohesity/iris-core';
import { getClusterIdentifier } from '@cohesity/utils';
import { cloneDeep } from 'lodash';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { catchError, finalize, map, switchMap, take } from 'rxjs/operators';

import { AppStatus } from '../filer-app-status-pipe';
import { ClusterInfo } from './argus-app.model';

/**
 * The App installation status.
 */
export interface AppInstallationStatus {
  clusterByClusterIdentifierId: Map<ClusterIdentifierId, ClusterInfo>;
  clusterList: ClusterInfo[];
  connectedToHelios: Map<string, McmClusterInfo>;
  dataSourceByClusterIdentifierId: Map<string, DataSource>;
}

/**
 * Stores NAS filer app name
 * It will be used while filtering cluster list in getApps api
 */
const filerAppNames = (() => (
  [
    localStorage.getItem('argus.nasAppName'),
    'DataHawk Engines',
    'DataGovern Connector',
  ].map(name => (name || '').trim().toLowerCase()).filter(Boolean)
))();

/**
 * The pruned and sorted list of apps.
 *
 * @param apps The list of apps.
 * @returns The pruned and sorted list of apps.
 */
const pruneApps = (apps: App[]): App[] => (
  (apps || [])
    .filter(app => filerAppNames.includes(app.metadata?.name?.toLowerCase()?.trim()))
    .sort((first, second) => {
      const firstName = first.metadata?.name?.toLowerCase()?.trim();
      const secondName = second.metadata?.name?.toLowerCase()?.trim();

      const firstIndex = filerAppNames.indexOf(firstName);
      const secondIndex = filerAppNames.indexOf(secondName);

      // a is less than b by some ordering criterion
      if (firstIndex < secondIndex) {
        return -1;
      }

      // a is greater than b by the ordering criterion
      if (firstIndex > secondIndex) {
        return 1;
      }

      // a must be equal to b
      return 0;
    })
);

/**
 * Implements logic for the primary application navigation. All of the logic
 * in this service originated in _nav.js, and should produce equivalent results.
 */
@Injectable({ providedIn: 'root' })
export class ArgusAppService {
  /** The cached argus app details */
  private argusApp$ = new BehaviorSubject<App>(null);

  /** The cached argus app details */
  get argusApp() {
    return this.argusApp$.value;
  }

  /** Indicates whrather argus app details are fetched or not */
  private isArgusAppFetched = false;

  /** The cached app installation status */
  appInstallationStatus: AppInstallationStatus;

  constructor(
    private dataSourcesApiService: DataSourcesApiService,
    private mcmAppsManagementServiceApi: McmAppsManagementServiceApi,
    private mcmClusterServiceApi: McmClusterServiceApi,
    private router: Router,
  ) {}

  /** The install app link */
  getInstallAppLinkFn$ = this.argusApp$.pipe(
    map(argusApp => {
      const getLinkFn: GetLinkFn = () => {
        if (!argusApp?.appId) {
          return this.router.createUrlTree([`/apps-management/browse-apps`]).toString();
        }

        return this.router.createUrlTree([`/apps-management/apps/${argusApp?.appId}/details/overview`]).toString();
      };

      return getLinkFn;
    }),
  );

  /**
   * Intalize the service and cache the argus app details
   */
  init() {
    return this.getArgusApp().pipe(catchError(() => of(null)));
  }

  /** Return the install app link params. */
  getInstallAppLinkParamsFn: GetLinkParamsFn = () => ({ getApp: true, serviceType: 'clusterManager' });

  /**
   * Get the argus app details.
   *
   * @param force If present the don't use cached value.
   * @returns The argus app details.
   */
  getArgusApp(force = false) {
    if (!force && this.isArgusAppFetched) {
      return this.argusApp$;
    }

    return this.mcmAppsManagementServiceApi.getApps().pipe(
      finalize(() => this.isArgusAppFetched = true),
      switchMap(apps => {
        this.argusApp$.next(pruneApps(apps)?.[0] || null);
        return this.argusApp$;
      }),
    );
  }

  /**
   * Get the app installation status for given clusters or all clusters.
   *
   * @param clusterIdentifiers The filter by cluster if not present then fetch all clusters.
   * @param force If present the don't use cached value.
   * @returns The clusters with status and few additional helper maps.
   */
  getAppInstallationStatus(
    clusterIdentifiers: ClusterIdentifierId[] = [],
    force = false,
  ): Observable<AppInstallationStatus> {
    const filterByClusterIdentifiers = new Set(clusterIdentifiers);

    return of(!force && this.appInstallationStatus).pipe(
      switchMap(shouldUseCache => {
        if (shouldUseCache) {
          return of(this.appInstallationStatus);
        }

        return combineLatest([
          this.mcmClusterServiceApi.getUpgradeInfo(),
          this.argusApp$,
          this.dataSourcesApiService.getRegisteredDataSources({
            environments: [Environment.kCohesity],
            // fetching both enabled and disabled data source which is required to determine the apps
            // installation status.
            abilities: [Ability.enabled, Ability.disabled],
          }),
        ]).pipe(
          map(([{ upgradeInfo }, argusApp, { sources }]) => {
            const connectedToHelios = new Map<ClusterIdentifierId, McmClusterInfo>();
            const installedClusters = new Set<ClusterIdentifierId>();
            const enabledClusters = new Map<ClusterIdentifierId, DataSource>();
            const enablingClusters = new Map<ClusterIdentifierId, DataSource>();
            const dataSourceByClusterIdentifierId = new Map<ClusterIdentifierId, DataSource>();

            // collecting the cluster which are connected to helios.
            (upgradeInfo || []).forEach(cluster => {
              if (cluster.connectedToCluster) {
                connectedToHelios.set(getClusterIdentifier(cluster), cluster);
              }
            });

            // collecting the cluster where argus app is installed.
            (argusApp?.clusters || []).forEach(cluster => {
              installedClusters.add(getClusterIdentifier({ ...cluster, clusterIncarnationId: cluster.incarnationId }));
            });

            // collecting the cluster where data govern is enabled and logs are being processed.
            (sources || []).forEach(source => {
              const { cohesityParams, enabled, progress } = source;
              const clusterIdentifierId = getClusterIdentifier(cohesityParams);

              if (progress && enabled) {
                enablingClusters.set(clusterIdentifierId, source);
              } else if (cohesityParams && enabled) {
                enabledClusters.set(clusterIdentifierId, source);
              }

              // assuming app is installed when we are missing argus app installation status
              // from /mcm/apps API and having disabled data source entry.
              if (!installedClusters.has(clusterIdentifierId) && !enabled) {
                installedClusters.add(clusterIdentifierId);
              }

              // collecting the data source which is later used by views step to show the view to data source mapping.
              dataSourceByClusterIdentifierId.set(clusterIdentifierId, source);
            });

            // constructing the clusters list with additional properties like status and data source id.
            const clusterList = (upgradeInfo || []).map(cluster => {
              const id = getClusterIdentifier(cluster);
              const status = (
                // Its enabled when there exist an enabled Argus data source.
                enabledClusters.has(id) ? AppStatus.enabled : (
                  // Its not connected when the cluster is not reachable from helios this can happen
                  // because of intermittent network failure and its used to prevent AD cred authenticated
                  // thorough such cluster w/o which we can't enable the cluster.
                  !connectedToHelios.has(id) ? AppStatus.notConnected : (
                    // Its connected when Argus app is not installed but cluster is reachable from helios.
                    !installedClusters.has(id) ? AppStatus.connected : (
                      // Its enabling when Argus data source registration is in progress and Argus app is installed.
                      enablingClusters.has(id) ? AppStatus.enabling : (
                        // Its installing until NAS app is successfully able to register a disabled Argus data source
                        // which can be enabled using NAS onboarding workflow.
                        dataSourceByClusterIdentifierId.has(id) ? AppStatus.installed : AppStatus.installing
                      )
                    )
                  )
                )
              );

              return { ...cluster, status } as ClusterInfo;
            });

            this.appInstallationStatus = {
              clusterByClusterIdentifierId: null,
              clusterList,
              connectedToHelios,
              dataSourceByClusterIdentifierId,
            } as AppInstallationStatus;

            return this.appInstallationStatus;
          }),
        );
      }),
      take(1),
      map(appInstallationStatus => {
        // applying the filter by cluster if clusterIdentifiers are specified.
        const clusterList = appInstallationStatus.clusterList.filter(cluster =>
          filterByClusterIdentifiers.size ? filterByClusterIdentifiers.has(getClusterIdentifier(cluster)) : true
        );

        const clusterByClusterIdentifierId = new Map<ClusterIdentifierId, ClusterInfo>();
        clusterList.forEach(cluster => clusterByClusterIdentifierId.set(getClusterIdentifier(cluster), cluster));

        return { ...cloneDeep(appInstallationStatus), clusterList, clusterByClusterIdentifierId };
      }),
    );
  }
}
