import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Type } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { CohesityHelixModule } from '@cohesity/helix';

import { ComponentLoaderComponent } from './component-loader/component-loader.component';
import { DYNAMIC_COMPONENT_MANIFESTS, DYNAMIC_COMPONENTS, DynamicComponentManifest } from './dynamic-component-manifest';

const angular = (<any>window).angular;

/**
 * This is a technique for loading components that are defined in lazy loaded
 * modules. This is mostly going to be useful for loading and displaying dialogs for
 * components. Without this, any exposed components would need to be defined in the
 * shared module. The concepts and much of the code for this module are borrowed from
 * https://github.com/devboosts/dynamic-component-loader
 *
 */
@NgModule({
  declarations: [ComponentLoaderComponent],
  imports: [CommonModule, CohesityHelixModule],
  exports: [ComponentLoaderComponent],
})
export class DynamicComponentLoaderModule {
  static forRoot(manifests: DynamicComponentManifest[]): ModuleWithProviders<DynamicComponentLoaderModule> {
    return {
      ngModule: DynamicComponentLoaderModule,
      providers: [
        // provider for DynamicComponentLoader to analyze
        { provide: DYNAMIC_COMPONENT_MANIFESTS, useValue: manifests },
      ],
    };
  }

  static forChild(component: Type<any>, id: string): ModuleWithProviders<DynamicComponentLoaderModule> {
    return {
      ngModule: DynamicComponentLoaderModule,
      providers: [
        // provider for DynamicComponentLoader to analyze
        { provide: DYNAMIC_COMPONENTS, useValue: { componentId: id, component: component }, multi: true },
      ],
    };
  }
}

angular
  .module('C')
  .directive('ng2CohComponentLoader', downgradeComponent({ component: ComponentLoaderComponent }));
