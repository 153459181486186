import { Component, Inject } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

/**
 * Model for the response from the New Permission dialog.
 */
export interface NewPermission {
  sid: string;
  type: string;
  access?: string;
  mode?: string;
}

/**
 * SMB Permissions for file access. Used in Create/Edit View flows.
 */
export const SMB_PERMISSIONS = {
  types: {
    default: [
      {
        label: 'smbPermissions.types.allow',
        value: 'Allow',
      },
      {
        label: 'smbPermissions.types.deny',
        value: 'Deny',
      },
    ],
    snapshots: [
      {
        label: 'smbPermissions.types.allow',
        value: 'Allow',
      },
      {
        label: 'smbPermissions.types.deny',
        value: 'Deny',
      },
    ],
  },
  modes: [
    {
      label: 'smbPermissions.modes.folderSubFoldersAndFiles',
      value: 'FolderSubFoldersAndFiles',
    },
    {
      label: 'smbPermissions.modes.folderAndSubFolders',
      value: 'FolderAndSubFolders',
    },
    {
      label: 'smbPermissions.modes.folderAndFiles',
      value: 'FolderAndFiles',
    },
    {
      label: 'smbPermissions.modes.folderOnly',
      value: 'FolderOnly',
    },
    {
      label: 'smbPermissions.modes.subFoldersAndFilesOnly',
      value: 'SubFoldersAndFilesOnly',
    },
    {
      label: 'smbPermissions.modes.subFoldersOnly',
      value: 'SubFoldersOnly',
    },
    {
      label: 'smbPermissions.modes.filesOnly',
      value: 'FilesOnly',
    },
  ],
  access: [
    {
      label: 'smbPermissions.access.fullControl',
      value: 'FullControl',
    },
    {
      label: 'smbPermissions.access.readWrite',
      value: 'ReadWrite',
    },
    {
      label: 'smbPermissions.access.modify',
      value: 'Modify',
    },
    {
      label: 'smbPermissions.access.readOnly',
      value: 'ReadOnly',
    },
    {
      label: 'smbPermissions.access.superUser',
      value: 'SuperUser',
    },
  ],
  accessOptions: {
    default: [
      {
        label: 'smbPermissions.access.fullControl',
        value: 'FullControl',
      },
      {
        label: 'smbPermissions.access.readWrite',
        value: 'ReadWrite',
      },
      {
        label: 'smbPermissions.access.modify',
        value: 'Modify',
      },
      {
        label: 'smbPermissions.access.readOnly',
        value: 'ReadOnly',
      },
    ],
    shares: [
      {
        label: 'smbPermissions.access.fullControl',
        value: 'FullControl',
      },
      {
        label: 'smbPermissions.access.modify',
        value: 'Modify',
      },
      {
        label: 'smbPermissions.access.readOnly',
        value: 'ReadOnly',
      },
    ],
  },
};

@Component({
  selector: 'coh-principal-dialog',
  templateUrl: './principal-dialog.component.html',
  styleUrls: ['./principal-dialog.component.scss']
})
export class PrincipalDialogComponent {
  /**
   * Permission formgroup control.
   */
  permissionForm: UntypedFormGroup;

  /**
   * SMB Permissions.
   */
  smbPermissions = SMB_PERMISSIONS;

  /**
   * Provides access options.
   */
  get accessOptions(): object[] {
    return this.smbPermissions.accessOptions[this.data.shares ? 'shares' : 'default'];
  }

  /**
   * Provides permission types.
   */
  get types(): object[] {
    return this.smbPermissions.types[this.data.snapshots ? 'snapshots' : 'default'];
  }

  constructor(
    public dialogRef: MatDialogRef<PrincipalDialogComponent>,
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    // Init form.
    this.permissionForm = this.fb.group({
      sid: [null, [Validators.required, this.duplicatePermissionValidator]],
      type: ['Allow'],
      mode: ['FolderSubFoldersAndFiles'],
      access: ['FullControl'],
    });

    // Update with default permission settings based on access value.
    this.permissionForm.controls.access.valueChanges.subscribe((newValue) => {
      if (newValue === 'SuperUser') {
        this.permissionForm.controls.type.setValue('Allow');
        this.permissionForm.controls.type.disable();
      } else {
        this.permissionForm.controls.type.enable();
      }
    });
  }

  /**
   * Validates whether duplicate sid exists or not.
   */
  duplicatePermissionValidator: ValidatorFn = (control: AbstractControl) => {
    const value = control.value;

    // Ignore if no values.
    if (!value) {
      return null;
    }

    const duplicateEntry = (this.data.permissionList || []).find(permission =>
      permission.sid.toLowerCase() === value.toLowerCase());

    return duplicateEntry ? { duplicate: true } : null;
  };

  /**
   * Method called on submitting form.
   */
  onSubmit() {
    const value: NewPermission = this.permissionForm.getRawValue();

    if (this.data.shares || this.data.snapshots) {
      delete value.mode;
    }
    if (this.data.snapshots) {
      delete value.access;
    }

    this.dialogRef.close(value);
  }

}
