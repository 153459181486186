// Component: cWhitelist

;(function(angular, undefined) {
  'use strict';

  var configOptions = {
    controller: 'whitelistCtrl',
    templateUrl: 'app/global/c-whitelist/c-whitelist.html',

    // Attribute bindings
    bindings: {
      /**
       * Indicates whether the the model is for the global Whitelist.
       * Default false.
       *
       * @type   {Boolean}
       */
      global: '<?',

      /**
       * The incoming Whitelist data model.
       *
       * @type   {Array}
       */
      whitelist: '=',

      /**
       * Indicates whether NFS options should be hidden.
       *
       * @type   {Boolean}
       */
      hideNfs: '<?',

      /**
       * Indicates whether SMB options should be hidden.
       *
       * @type   {Boolean}
       */
      hideSmb: '<?',

      /**
       * Indicates whether S3 options should be hidden.
       *
       * @type   {Boolean}
       */
      hideS3: '<?',

      /**
       * Callback function after adding or deleting a whitelist.
       *
       * @type   {Function}
       */
      callback: '<?',

      /**
       * Indicates whether the form is read-only.
       *
       * @type   {Boolean}
       */
      readOnly: '<?',
    },
  };

  var accessList = ['kReadWrite', 'kReadOnly', 'kDisabled'];
  var nfsSquashOptions = [
    { label: 'yes', value: true },
    { label: 'no', value: false },
  ];
  var newEntryDefaults = {
    nfsAccess: 'kReadWrite',
    smbAccess: 'kReadWrite',
    s3Access: 'kReadWrite',
    nfsRootSquash: false,
    nfsAllSquash: false,
  }

  angular.module('C.whitelist', [])
    .controller('whitelistCtrl', WhitelistCtrlFn)
    .component('cWhitelist', configOptions);

  /**
   * $ngdoc Component
   * @name C.whitelist:whitelist
   *
   * @methodOf angular.Module|AUTO.
   * @function
   * @description
   *   Provides an editable table of whitelist data.
   *
   * @example
       <c-whitelist whitelist="$ctrl.view.subnetWhitelist"></c-whitelist>
       <c-whitelist whitelist="$ctrl.whitelist" global="true"></c-whitelist>
   */
  function WhitelistCtrlFn(_, $q, ClusterService, evalAJAX, FEATURE_FLAGS) {
    var $ctrl = this;

    var isGlobalWhitelist;

    angular.extend($ctrl, {
      form: {},

      nfsAccessList: _.cloneDeep(accessList),
      nfsSquashOptions: _.cloneDeep(nfsSquashOptions),
      showAllSquash: FEATURE_FLAGS.nfsAllSquash,
      smbAccessList: _.cloneDeep(accessList),
      s3AccessList: _.cloneDeep(accessList),
      tempEntry: {
        _isEditing: true,
      },

      $onInit: $onInit,
      addEntry: addEntry,
      addNewRow: addNewRow,
      cancelEditEntry: cancelEditEntry,
      deleteEntry: deleteEntry,
      saveEntry: saveEntry,
      startEditEntry: startEditEntry,
    });

    /**
     * Initialize this component.
     *
     * @method     init
     */
    function $onInit() {
      isGlobalWhitelist = !!$ctrl.global;
      $ctrl.whitelist = _transformWhitelist($ctrl.whitelist);
      $ctrl.hideNfs = !!$ctrl.hideNfs;
      $ctrl.hideSmb = !!$ctrl.hideSmb;
      $ctrl.hideS3 = !!$ctrl.hideS3;
      $ctrl.readOnly = !!$ctrl.readOnly;
      $ctrl.callback = $ctrl.callback ||
        function dummyCallback(whitelist) {
          return $q.resolve({ subnetWhitelist: whitelist });
        };
    }

    /**
     * Adds new subnet to the Whitelist.
     *
     * @method    addEntry
     */
    function addEntry() {
      var tempWhitelist;

      _untransformSubnet($ctrl.newEntry);

      $ctrl.form.$submitted = true;

      if ($ctrl.form.$invalid) {
        return;
      }

      // Clone the current whitelist (and newly added entry) into a temporary
      // variable until update is successfully completed.
      tempWhitelist = _.cloneDeep($ctrl.whitelist || []).concat($ctrl.newEntry);

      if (isGlobalWhitelist) {
        // Update the global Whitelist via service call.
        return _updateGlobalWhitelist(tempWhitelist);
      }

      $ctrl.callback(tempWhitelist).then(
        function addNewWhitelistEntry(share) {
          // View updated successfully. Now update the local Whitelist model.
          _updateViewAfterAddEntry(share.subnetWhitelist);
        }
      );
    }

    /**
     * Saves updated subnets to the Whitelist.
     *
     * @method  saveEntry
     * @param   {Object}  existingEntry  Existing whitelist entry to be updated.
     * @param   {Object}  tempEntry      Edited, but not saved, whitelist entry.
     */
    function saveEntry(existingEntry, tempEntry) {
      // Clone the current whitelist into a temporary variable until update is
      // successfully completed.
      var tempWhitelist = _.cloneDeep($ctrl.whitelist);

      // Find and update the temp entry corresponding to the modified subnet.
      tempWhitelist.some(function updateEditedEntry(subnet) {
        if (subnet.ip === tempEntry.ip &&
          (subnet.netmaskIp4 === tempEntry.netmaskIp4 ||
          subnet.netmaskBits === tempEntry.netmaskBits)) {
          _.assign(subnet, _untransformSubnet(tempEntry));
          return true;
        }
      });

      $ctrl.editForm.$submitted = true;

      if ($ctrl.editForm.$invalid) {
        return;
      }

      if (isGlobalWhitelist) {
        // Update the global Whitelist via service call.
        return _updateGlobalWhitelist(tempWhitelist);
      }

      $ctrl.callback(tempWhitelist).then(
        function savedWhitelistEntry() {
          // View updated successfully. Now update the local Whitelist model
          // with the updated entry.
          _.assign(existingEntry, tempEntry);
          existingEntry._isEditing = $ctrl.isEditing = false;
          $ctrl.editForm.$submitted = false;
        }
      );
    }

    /**
     * Cancels the edit mode for specified Whitelist entry.
     *
     * @method  cancelEditEntry
     * @param   {Object}  entry  Whitelist entry to cancel editing.
     */
    function cancelEditEntry(entry) {
      // Clear the temp model.
      $ctrl.tempEntry = undefined;
      entry._isEditing = $ctrl.isEditing = false;
    }

    /**
     * Initiates the edit mode for specified Whitelist entry.
     *
     * @method  startEditEntry
     * @param   {Object}  entry  Whitelist entry to start editing.
     */
    function startEditEntry(entry) {
      // Copy current entry's model to a temp model.
      $ctrl.tempEntry = _.cloneDeep(entry);
      entry._isEditing = $ctrl.isEditing = true;
    }

    /**
     * Removes specified subnet from the Whitelist.
     *
     * @method    deleteEntry
     * @param     {Number}    index     index of entry to delete
     */
    function deleteEntry(index) {
      // Clone the current whitelist into a temporary variable until update is
      // successfully completed.
      var tempWhitelist = _.cloneDeep($ctrl.whitelist);

      // Remove the deleted entry from the temp list.
      tempWhitelist.splice(index, 1);

      if (isGlobalWhitelist) {
        // Update the global Whitelist via service call.
        return _updateGlobalWhitelist(tempWhitelist);
      }

      $ctrl.callback(tempWhitelist).then(
        function deletedWhitelistEntry() {
          // View updated successfully. Now update the local Whitelist model.
          $ctrl.whitelist = _transformWhitelist(tempWhitelist);
        }
      );
    }

    /**
     * Adds a row for entering new subnet.
     *
     * @method    addNewRow
     */
    function addNewRow() {
      $ctrl.isEditing = true;
      $ctrl.newEntry = _.cloneDeep(newEntryDefaults);
    }

    /**
     * Issues the service call to update the global Whitelist.
     *
     * @method    _updateGlobalWhitelist
     * @param     {Array}    tempWhitelist    locally updated whitelist for put
     * @returns   {Object}   Promise to update the global whitelist.
     */
    function _updateGlobalWhitelist(tempWhitelist) {
      $ctrl.updatingWhitelist = true;
      return ClusterService.updateSubnetWhitelist(tempWhitelist).then(
        function updateGlobalWhitelistSuccess(updatedWhitelist) {
          return _updateViewAfterAddEntry(updatedWhitelist);
        },
        evalAJAX.errorMessage
      ).finally(function updateGlobalWhitelistFinally() {
        $ctrl.updatingWhitelist = false;
      });
    }

    /**
     * Updates the view after successful `addEntry`.
     *
     * @method    _updateViewAfterAddEntry
     * @param     {Array}    tempWhitelist    locally updated whitelist
     */
    function _updateViewAfterAddEntry(tempWhitelist) {
      $ctrl.whitelist = _transformWhitelist(tempWhitelist);
      _.set($ctrl, 'form.$submitted', false);
      _.set($ctrl, 'editForm.$submitted', false);
      $ctrl.newEntry = undefined;
      $ctrl.isEditing = false;
    }

    /**
     * Compile an aggregate ip/mask string from either the ipv4 or ipv6
     * fields.
     *
     * @method   _transformWhitelist
     */
    function _transformWhitelist(subnetList) {
      return (subnetList || []).map(function decorateSubnet(subnet) {
        var mask = parseInt(subnet.netmaskBits, 10) > -1 ?
          +subnet.netmaskBits : subnet.netmaskIp4;
        subnet._ip = subnet.ip + '/' + mask;
        return subnet;
      });
    }

    /**
     * Untransform ip/mask to individual params.
     *
     * @method   untransformSubnet
     * @param    {object}   subnet    Subnet object.
     */
    function _untransformSubnet(subnet) {
      var parts;
      var mask;

      if (!subnet || !subnet._ip) {
        return subnet;
      }

      parts = subnet._ip.split('/');
      subnet.ip = parts[0];
      mask = parts[1];

      if (mask.substr(':') > -1) {
        subnet.netmaskIp4 = undefined;
        subnet.netmaskBits = +mask;
      } else {
        subnet.netmaskIp4 = mask;
        subnet.netmaskBits = undefined;
      }

      return subnet;
    }
  }

})(angular);
