<div [formGroup]="protectionGroupFormGroup">
  <mat-radio-group formControlName="type">
    <mat-radio-button id="radio-group-new-group" [value]="protectionGroupOptions.new"
      cogDataId="radio-button-new-group">
      {{'newGroup' | translate}}
    </mat-radio-button>
    <mat-radio-button
      id="radio-group-existing-group" [value]="protectionGroupOptions.existing"
      [disabled]="!groups?.length"
      [matTooltip]="'noProtectionGroupsFound' | translate"
      [matTooltipDisabled]="groups?.length"
      cogDataId="radio-group-existing-group">
      {{'existingGroup' | translate}}
    </mat-radio-button>
  </mat-radio-group>

  <!-- Protection Group Name when new group is selected -->
  <mat-form-field *ngIf="groupTypeFormControl.value === protectionGroupOptions.new"
    class="margin-top">
    <mat-label>{{'name' | translate}}</mat-label>
    <input formControlName="name"
      id="group-name"
      matInput
      required>
    <mat-error>
      {{'errors.required' | translate}}
    </mat-error>
  </mat-form-field>

  <!-- Protection Group Selection when existing group is selected-->
  <mat-form-field *ngIf="groupTypeFormControl.value === protectionGroupOptions.existing"
    class="margin-top">
    <mat-label>{{'group' | translate}}</mat-label>
    <textarea type="text"
      [placeholder]="'selectGroup' | translate"
      matInput
      cdkTextareaAutosize
      cdkAutosizeMinRows="1"
      cdkAutosizeMaxRows="5"
      [formControl]="groupsFilterControl"
      [matAutocomplete]="auto"
      required>
    </textarea>
    <mat-error *ngIf="groupsFilterControl?.errors?.required">{{'errors.required' | translate}}</mat-error>
    <mat-error *ngIf="groupsFilterControl?.errors?.invalidOption">{{'errors.invalidOption' | translate}}</mat-error>
    <mat-autocomplete #auto="matAutocomplete"
      requireSelection
      [displayWith]="displayWithFn"
      (optionSelected)="onSelectionChange($event)"
      cogDataId="protection-group-select"
      id="protection-group-select">
      <mat-option *ngFor="let group of filteredGroups"
        [matTooltip]="group.name"
        [cogDataId]="group.name | lowercase"
        [inheritDataId]="true"
        [value]="group">
        {{group.name}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>
