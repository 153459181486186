<form
  [formGroup]="runDeleteForm"
  cohValidateOnSubmit
  (submit)="onDelete()">
  <h1 mat-dialog-title>
    {{ 'deleteSnapshots' | translate }}
  </h1>
  <div mat-dialog-content>
    <div class="margin-top margin-bottom">
      <mat-radio-group
        class="margin-top-xs margin-bottom-xs deletion-scope"
        cogDataId="radio-group"
        [inheritDataId]="true"
        formControlName="snapshotDeletion"
      >
        <mat-radio-button value="local">{{'deleteLocalSnapshots.title' | translate}}</mat-radio-button>
        <mat-radio-button value="all">{{'deleteAllSnapshots.title' | translate}}</mat-radio-button>
      </mat-radio-group>
    </div>
    <cog-banner
      *ngIf="isDeletionLocal"
      status="info"
      class="margin-top">
      <p>{{ 'deleteLocalSnapshots.body' | translate }}</p>
    </cog-banner>
    <cog-banner
      *ngIf="isDeletionAll"
      status="info"
      class="margin-top">
      <p>{{ 'deleteAllSnapshots.body' | translate }}</p>
    </cog-banner>
  </div>
  <div mat-dialog-actions align="end">
    <button
      mat-button
      mat-dialog-close
      color="primary"
      cogDataId="cancel-delete">
      {{ 'cancel' | translate }}
    </button>
    <button
      mat-flat-button
      type="submit"
      color="primary"
      cdkFocusInitial
      cogDataId="delete">
      {{ 'delete' | translate }}
    </button>
  </div>
</form>
