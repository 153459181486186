import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Api } from '@cohesity/api/private';
import { StateService } from '@uirouter/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AjsUpgradeService } from 'src/app/core/services';

interface Button {
  /** function to be called on button click */
  callback: CallableFunction;

  /** Lable for button */
  text: string;
}

export interface ExpiryTime {
  /** if its a classified user or normal user */
  isClassified: boolean;

  /** if license is a POC license */
  isTrial: boolean;

  /** if license claimed is a manual */
  isManualLicense: boolean;

  /** if a license has been deployed to cluster */
  licenseDeployed: boolean;

  /** License Expiry time in Epoch format */
  licenseExpiryTimestamp: number;
}

export interface Notification {
  /** interface with button text key and callback function */
  button: Button;

  /** Msg of notification */
  textKey: string;

  /** Title of notification */
  titleKey: string;

  /** type of notification */
  type: 'warn' | 'info' | 'error' | 'success';
}

@Injectable({
  providedIn: 'root',
})
export class LicenseNotificationService {
  /**
   * Injected Ajs service.
   */
  private dateTimeService: any;
  private heliosService: any;

  /** pubic varible to get list of notifications */
  public notifications: Notification[];

  constructor(private ajsUpgrade: AjsUpgradeService, private http: HttpClient, private stateService: StateService) {
    this.heliosService = ajsUpgrade.get('HeliosService');
    this.dateTimeService = ajsUpgrade.get('DateTimeService');
  }

  /**
   * service to fetch expiry time
   *
   * @returns An observable containing list of notification
   */
  getNotifications(): Observable<Notification[]> {
    return this.getLicenseExpiryTime().pipe(map((response) => this.checkForNotifications(response)));
  }

  /**
   * service to fetch expiry time,
   *
   * @returns An Observable containing ExpiryTime API data
   */
  getLicenseExpiryTime(): Observable<ExpiryTime> {
    return this.http.get<ExpiryTime>(Api.private('nexus/license/expiry_date'));
  }

  /**
   * Fn to find out any notification from expiry_date data
   *
   * @param expiry_date API response for expiry_date
   * @returns list of notification
   */
  checkForNotifications(expiryDate: ExpiryTime): Notification[] {
    const notifications: Notification[] = [];
    const expiryTime = expiryDate.licenseExpiryTimestamp * 1000;
    const licenseDeployed = expiryDate.licenseDeployed;
    const isClassified = expiryDate.isClassified;

    // we will show undismissable notifications only after expiry time has alread passed
    if (expiryTime <= this.dateTimeService.clusterNow()) {
      if (!licenseDeployed) {
        notifications.push({
          type: 'warn',
          titleKey: 'licenseNotDeployed',
          textKey: 'clusterClaim.gracePeriodOverMessage',
          button: {
            text: 'addLicense',
            callback: () =>
              this.stateService.go('cluster-connect-dark-site'),
          },
        });
      }

      // if license is deployed, helios is not connected, and its not a classified user
      if (licenseDeployed && !this.heliosService.heliosStatus._isRegistered
        && !isClassified) {
        notifications.push({
          type: 'warn',
          titleKey: 'licenseExpired',
          textKey: 'license.licenseRenewNote',
          button: {
            text: 'generate',
            callback: () => {
              this.stateService.go('license',
                { openModal: 'openUploadDownloadFileModal' });
            },
          },
        });
      }
    }

    return notifications;
  }
}
