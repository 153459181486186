<cog-settings-list-item [invalid]="control.invalid"
  cogFormGroup
  [label]="'continueOnError' | translate">
  <cog-settings-list-item-value-display>
    {{ control.value | boolLabel}}
  </cog-settings-list-item-value-display>
  <cog-settings-list-item-value-editor>
    <mat-slide-toggle [formControl]="control"
      [inheritDataId]="true"
      cogDataId="continue-on-error">
      {{label || ('recover.continueOnError' | translate)}}
    </mat-slide-toggle>
  </cog-settings-list-item-value-editor>
</cog-settings-list-item>
