import { Component, HostListener, Input, ViewEncapsulation } from '@angular/core';
import { IconSize } from '@cohesity/helix';
import { IrisContextService, isDmsScope } from '@cohesity/iris-core';
import { TranslateService } from '@ngx-translate/core';

import { iconSettings, StatusObject, TaskStatus } from '../constants';

/**
 * Icon to show status, including when there is a list of statuses.
 *
 * @example
 * <coh-status-icon type="cluster" [status]="item.status"></coh-status-icon>
 * <coh-status-icon type="replication" [status]="replications"></coh-status-icon>
 */
@Component({
  selector: 'coh-status-icon',
  styleUrls: ['./status-icon.component.scss'],
  templateUrl: './status-icon.component.html',
  encapsulation: ViewEncapsulation.None
})
export class StatusIconComponent {
  /**
   * List of status objects
   */
  @Input() status: StatusObject[];

  /**
   * Icon size.
   */
  @Input() size: IconSize = 'md';

  /**
   * Prevents triggering outside mouse events when this component is clicked.
   */
  @HostListener('click', ['$event']) stopEventPropagation = (e: MouseEvent) => e.stopPropagation();

  /**
   * Constructor.
   */
  constructor(private translate: TranslateService, private irisContext: IrisContextService) {}

  /**
   * Gets display text for tooltip or menu item.
   */
  getDisplayText({ name, status, type }: StatusObject): string {

    let isDmsCloud = false;

    // For dms, we don't want to show the s3 backup target, we want something more generic.
    if (isDmsScope(this.irisContext.irisContext) && type === 'cloud') {
      name =  this.translate.instant('backup');
      isDmsCloud = true;
    }
    if (!status || !['replication', 'tape', 'cloud', 'cloud-spin', 'sla', 'status', 'cluster'].includes(type)) {
      return name || this.translate.instant('noStatus');
    }

    const statusText = this.translate.instant(iconSettings[type]?.labels?.[status] || status);

    /**
     * For all statuses except 'Paused', the name and statusText used as a message for the tooltip,
     * In the case of 'Paused' status, the full tooltip message is already in name,
     *
     * Example for error status
     * statusText: Error
     * name: Encountered backup errors, please download error logs for more details
     * tooltip: Encountered backup errors, please download error logs for more details Error
     *
     * Example for pause status
     * statusText: Paused
     * name: Job Paused on <date> by <userName> <pausedNote>
     * tooltip: Job Paused on <date> by <userName> <pausedNote>
     */
    if (!isDmsCloud && status === TaskStatus.Paused && name) {
      return name;
    }

    if (name) {
      return [name, statusText].join(' ');
    }
    return statusText || this.translate.instant('noStatus');
  }

  /**
   * Function to return the aria label based on the displayed text.
   *
   * @param statusObject The status object.
   * @return The aria label.
   */
  getAriaLabel(statusObject: StatusObject) {
    // The displayed text might be a paragraph of text, in which case, just
    // return the first line, which will be the status.
    return this.getDisplayText(statusObject)?.split('\n')?.[0];
  }
}
