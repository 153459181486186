/**
 * Data model for useExistingAgent.
 */
export interface UseExistingAgentOption {
  /**
   * Name for display.
   */
  name: string;

  /**
   * Value for sending to the backend.
   */
  value: boolean;
}

/**
 * Data structure for radio group component.
 */
export const useExistingAgentValues: UseExistingAgentOption[] = [
  {
    name: 'autoDeployCohesityAgent',
    value: false
  },
  {
    name: 'useExistingCohesityAgent',
    value: true
  }
];
