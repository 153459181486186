import { Injectable } from '@angular/core';
import { SourceSpecialParameter } from '@cohesity/api/v1';
import {
  CancelObjectRunsParams,
  CancelObjectRunsResults,
  ObjectServiceApi,
  PauseActionObjectLevelParams,
  ProtectdObjectsActionRequest,
  ProtectedObjectActionResponse,
  ProtectedObjectInfo,
  ProtectedObjectServiceApi,
  UnprotectActionObjectLevelParams,
} from '@cohesity/api/v2';
import { SnackBarService } from '@cohesity/helix';
import { AjaxHandlerService } from '@cohesity/utils';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';
import { DialogService } from 'src/app/core/services';
import { AwsLeafType, CloudJobType, Environment, Office365CSMEnvironmentTypes, PassthroughOptions } from 'src/app/shared';

import {
  ObjectRunNowAction,
  ObjectRunNowDialogComponent,
  ObjectUnprotectAction,
  ObjectUnprotectDialogComponent,
} from './components';
import { SimpleObjectInfo } from './object-menu-provider';
import { ObjectProtectAction, ObjectProtectionEnvironmentType } from './object-protect-action.constants';

/**
 * Interface for passing an objects array.
 */
export interface ProtectedObjectItems {
  /**
   * Array of objects returned by V2 Protected Objects API. This is not always
   * present as some protected objects, which do not have any snapshots and
   * were deleted, will not have this response.
   */
  protectedObjects?: ProtectedObjectInfo[];

  /**
   * Array of simple objects constructed in the UI using some API response.
   */
  simpleObjects?: SimpleObjectInfo[];
}

/**
 * This service executes actions on protected objects.
 * TODO(Alex): This needs to be extended to show confirm dialogs in cases where the
 * action for one or more of the objects is not valid.
 */
@Injectable({
  providedIn: 'root',
})
export class ProtectedObjectsService {
  constructor(
    private api: ProtectedObjectServiceApi,
    private dialogService: DialogService,
    private objectsApi: ObjectServiceApi,
    private ajaxHandler: AjaxHandlerService,
    private snackbar: SnackBarService,
    private translate: TranslateService
  ) {}

  /**
   * Check whether an object run is currently in progress
   *
   * @param   object   The object to check.
   * @returns True if any tasks from the last run are still in progress.
   */
  isObjectRunning(object: ProtectedObjectInfo): boolean {
    const lastRun = object.lastRun;
    if (!lastRun) {
      return false;
    }

    // Check all of the run sub tasks to see if any are running
    const subTasks = [
      lastRun.localSnapshotInfo?.snapshotInfo,
      ...(lastRun.archivalInfo?.archivalTargetResults || []),
      ...(lastRun.cloudSpinInfo?.cloudSpinTargetResults || []),
      ...(lastRun.replicationInfo?.replicationTargetResults || []),
    ];
    return subTasks.some(task => [
      'Accepted',
      'kCurrentAttemptPaused',
      'kInProgress',
      'kWaitingForNextAttempt',
      'Running'
    ].includes(task?.status));
  }

  /**
   * Cancel protection run for one or more objects.
   *
   * @param   objects  The objects to cancel runs for.
   * @param   objectOptions  The object's passthrough options.
   * @returns An observable of the api response.
   */
  cancelRun(
    objects: ProtectedObjectInfo[],
    objectOptions: PassthroughOptions = {},
    objectActionKey?: ProtectdObjectsActionRequest['objectActionKey'],
  ): Observable<CancelObjectRunsResults> {
    const data = {
      confirmButtonLabel: 'cancelRun',
      declineButtonLabel: 'close',
      title: 'cancelRun',
      copy: 'areYouSure',
    };

    return this.dialogService.simpleDialog(null, data).pipe(
      filter(Boolean),
      switchMap(() => {
        const runningObjects = objects.filter(object => this.isObjectRunning(object));
        return this.objectsApi
          .CancelObjectRuns({
            body: {
              objectRuns: runningObjects.map(object => ({
                objectId: object.id,
                ...this.getAdditionalCancelRunRequestParams(object, objectActionKey),
              })),
            },
            ...objectOptions,
          });
      }),
      tap(
        () => this.snackbar.open(this.translate.instant('protectedObjectDialog.objectsRunCanceled')),
        err => this.ajaxHandler.handler(err)
      )
    );
  }

  /**
   * Pause protection for one or more objects.
   *
   * @param   objects           The objects to pause protection for.
   * @param   objectOptions     The object's passthrough options.
   * @param   objectActionKey   The object's action key specifying the
   *                            protection type.
   * @returns An observable of the api response.
   */
  pauseProtection(
    objects: ProtectedObjectItems,
    objectOptions: PassthroughOptions = {},
    objectActionKey?: ProtectdObjectsActionRequest['objectActionKey']
  ): Observable<ProtectedObjectActionResponse> {
    // TODO: It's unclear how the API for this will behave for autoprotected items.
    // Best case is that we can pass any leaf node here and magneto will fiture out what to do.
    // If not, we will either need to inform the user that autoprotected objects will be ignored,
    // or we could ask if they want to pause the entire autoprotected spec.
    let pauseObjects: PauseActionObjectLevelParams[];

    if (objects.protectedObjects) {
      pauseObjects = objects.protectedObjects.filter(
        object => !object?.objectBackupConfiguration?.isPaused
      ).map(object => ({id: object.id}));
    } else {
      pauseObjects = objects.simpleObjects.map(object => ({id: object.id}));
    }

    return this.api
      .PerformActionOnProtectObjects({
        body: {
          action: ObjectProtectAction.Pause,
          objectActionKey,
          pauseParams: {objects: pauseObjects},
          ...this.getAdditionalObjectActionRequestParams(objects),
        },
        ...objectOptions,
      })
      .pipe(
        tap(
          () => this.snackbar.open(this.translate.instant('protectedObjectDialog.objectsPaused')),
          err => this.ajaxHandler.handler(err)
        )
      );
  }

  /**
   * Resume protection for one or more objects.
   *
   * @param   objects           The objects to resume protection for.
   * @param   objectOptions     The object's passthrough options.
   * @param   objectActionKey   The object's action key specifying the
   *                            protection type.
   * @returns An observable of the api response.
   */
  resumeProtection(
    objects: ProtectedObjectInfo[],
    objectOptions: PassthroughOptions = {},
    objectActionKey?: ProtectdObjectsActionRequest['objectActionKey']
  ): Observable<ProtectedObjectActionResponse> {
    const resumeObjects = objects.filter(object => object?.objectBackupConfiguration?.isPaused);

    return this.api
      .PerformActionOnProtectObjects({
        body: {
          action: ObjectProtectAction.Resume,
          objectActionKey,
          resumeParams: {
            objects: resumeObjects.map(object => ({ id: object.id })),
          },
          ...this.getAdditionalObjectActionRequestParams({protectedObjects: objects}),
        },
        ...objectOptions,
      })
      .pipe(
        tap(
          () => this.snackbar.open(this.translate.instant('protectedObjectDialog.objectsResumed')),
          err => this.ajaxHandler.handler(err)
        )
      );
  }

  /**
   * Start a protection run for one or more objects.
   *
   * @param   objects           The objects to protect now.
   * @param   objectOptions     The object's passthrough options.
   * @param   objectActionKey   The object's action key specifying the
   *                            protection type.
   * @returns An observable of the api response.
   */
  runNow(
    objects: ProtectedObjectInfo[],
    objectOptions: PassthroughOptions = {},
    objectActionKey?: ProtectdObjectsActionRequest['objectActionKey']
  ): Observable<ProtectedObjectActionResponse> {
    // TODO: For on-prem, We will likely need a dialog that would inform the user that this will
    // not apply to protection groups.
    return this.dialogService.showDialog(ObjectRunNowDialogComponent, {
      runningObjects: objects.filter(object => this.isObjectRunning(object)),
      selectedObjects: objects.filter(object => !this.isObjectRunning(object)),
    }).pipe(
      switchMap((runNowAction: ObjectRunNowAction) => {
        const incremental = ObjectRunNowAction.Incremental === runNowAction;
        const fullBackup = ObjectRunNowAction.FullBackup === runNowAction;
        const logBackup = ObjectRunNowAction.Log === runNowAction;
        let backupType;
        if (fullBackup) {
          backupType = 'kFull';
        } else if (logBackup) {
          backupType = 'kLog';
        }
        if (incremental || fullBackup || logBackup) {
          return this.api
            .PerformActionOnProtectObjects({
              body: {
                action: ObjectProtectAction.ProtectNow,
                objectActionKey,
                runNowParams: {
                  objects: objects
                    .filter(object => !this.isObjectRunning(object))
                    .map(object => ({
                      id: object.id,
                      takeLocalSnapshotOnly: false,
                      backupType,
                    })),
                },
                ...this.getAdditionalObjectActionRequestParams({protectedObjects: objects}),
              },
              ...objectOptions,
            })
            .pipe(
              tap(
                () => this.snackbar.open(this.translate.instant('protectedObjectDialog.objectRunStarted')),
                err => this.ajaxHandler.handler(err)
              )
            );
        } else {
          return of(null);
        }
      })
    );
  }

  /**
   * Unprotect one or more objects.
   *
   * @param   objects           The objects to unprotect.
   * @param   objectOptions     The object's passthrough options.
   * @param   objectActionKey   The object's action key specifying the
   *                            protection type.
   * @returns An observable of the api response.
   */
  unprotectObjects(
    objects: ProtectedObjectItems,
    objectOptions: PassthroughOptions = {},
    objectActionKey?: ProtectdObjectsActionRequest['objectActionKey']
  ): Observable<ProtectedObjectActionResponse> {
    let disallowSnapshotDeletion = false;
    if (objectActionKey) {
      disallowSnapshotDeletion = Office365CSMEnvironmentTypes.includes(objectActionKey);
    }

    // TODO: To start with, the API will allow operations here on leaf nodes, but will only unprotect
    // the current protection spec for an object - in cases where an object is autoprotected by multiple
    // objects, this could cause an object to show as protected a few minutes after this call.
    // For on-prem, we will need a dialog or warning that this action will not apply to objects protected
    // by groups.
    return this.dialogService.showDialog(ObjectUnprotectDialogComponent, { objects, disallowSnapshotDeletion }).pipe(
      switchMap((unprotectAction: ObjectUnprotectAction) => {
        const unprotect = ObjectUnprotectAction.Unprotect === unprotectAction;
        const deleteAllSnapshots = ObjectUnprotectAction.DeleteSnapshots === unprotectAction;
        if (unprotect || deleteAllSnapshots) {
          let unprotectObjects: UnprotectActionObjectLevelParams[];

          if (objects.protectedObjects) {
            unprotectObjects = objects.protectedObjects.map(object => ({
              id: object.id,
              deleteAllSnapshots,
              forceUnprotect: true,
            }));
          } else {
            unprotectObjects = objects.simpleObjects.map(object => ({
              id: object.id,
              deleteAllSnapshots,
              forceUnprotect: true,
            }));
          }

          return this.api
            .PerformActionOnProtectObjects({
              body: {
                action: ObjectProtectAction.UnProtect,
                objectActionKey,
                unProtectParams: {
                  objects: unprotectObjects,
                },
                ...this.getAdditionalObjectActionRequestParams(objects),
              },
              ...objectOptions,
            })
            .pipe(
              tap(
                () => this.snackbar.open(this.translate.instant('protectedObjectDialog.objectUnprotected')),
                err => this.ajaxHandler.handler(err)
              )
            );
        } else {
          return of(null);
        }
      })
    );
  }

  /**
   * Method called to get additional object action params based on object info.
   *
   * @param   objects   The objects to perform the action on.
   * @returns the object action request params.
   */
  getAdditionalObjectActionRequestParams(objects: ProtectedObjectItems): Partial<ProtectdObjectsActionRequest> {
    const objectProtectActionParams: Partial<ProtectdObjectsActionRequest> = {};

    let environment;

    if (objects.protectedObjects) {
      environment = objects.protectedObjects?.[0]?.environment;
    } else {
      environment = objects.simpleObjects?.[0]?.environment;
    }

    // Populating protection types when an object is protected by multiple protection types
    // eg: kSQl/kOracle source could be protected as kPhysicalFiles.
    if (Object.keys(ObjectProtectionEnvironmentType).includes(environment)) {
      objectProtectActionParams.snapshotBackendTypes = [ObjectProtectionEnvironmentType[environment]];
    }

    if (environment === Environment.kAWS && objects.protectedObjects) {
      return this.getAwsObjectParams(objects.protectedObjects);
    } else if (environment === Environment.kAWS && objects.simpleObjects &&
               objects.simpleObjects.every(object => object.isProtected && object.isDeleted)) {
      return this.getAwsDeletedObjectParams(objects.simpleObjects);
    }

    return objectProtectActionParams;
  }

  /**
   * Method called to get additional cancel run params based on object info.
   *
   * @param   object   The object to perform the action on.
   * @returns the object action request params.
   */
  getAdditionalCancelRunRequestParams(
    object: ProtectedObjectInfo,
    objectActionKey?: ProtectdObjectsActionRequest['objectActionKey'],
  ): Partial<CancelObjectRunsParams> {
    const objectProtectActionParams: Partial<CancelObjectRunsParams> = {};

    if (object?.environment === Environment.kAWS) {
      return this.getAwsCancelRunParams(object, objectActionKey);
    }
    return objectProtectActionParams;
  }

  /**
   * Method called to get additional object action params for Aws objects.
   *
   * @param   objects   The objects to perform the action on.
   * @returns the object action request params.
   */
  getAwsObjectParams(objects: ProtectedObjectInfo[]): Partial<ProtectdObjectsActionRequest> {
    const objectProtectActionParams: Partial<ProtectdObjectsActionRequest> = {};
    let protectionTypes = [];
    // Find all the protection types across all the objects.
    objects.map(object => {
      const policies = object?.objectBackupConfiguration.policyConfig?.policies;
      const objectType = object?.objectBackupConfiguration?.awsParams?.protectionType;
      if (policies) {
        policies.map(objectPolicy => protectionTypes.push(objectPolicy.protectionType));
      } else if (objectType === Environment.kRDSSnapshotManager) {
        protectionTypes.push(Environment.kRDSSnapshotManager);
      } else if (objectType === Environment.kAuroraSnapshotManager) {
        protectionTypes.push(Environment.kAuroraSnapshotManager);
      } else {
        protectionTypes.push(object?.objectBackupConfiguration.awsParams.protectionType);
      }
    });

    protectionTypes = protectionTypes.map(protectionType => {
      switch (protectionType) {
        case CloudJobType.kNative:
          return Environment.kAWSNative;
        case CloudJobType.kSnapshotManager:
          return Environment.kAWSSnapshotManager;
        default:
          return protectionType;
      }
    });

    objectProtectActionParams.snapshotBackendTypes = [...new Set(protectionTypes)];
    return objectProtectActionParams;
  }

  /**
   * Method called to get additional object action params for deleted Aws objects to unprotect them.
   *
   * @param   objects   The objects to perform the action on.
   * @returns the object action request params.
   */
  getAwsDeletedObjectParams(objects: SimpleObjectInfo[]): Partial<ProtectdObjectsActionRequest> {
    const objectProtectActionParams: Partial<ProtectdObjectsActionRequest> = {};
    const snapshotBackendTypesSet = objects.reduce((accumulator: Set<any>, object) => {
      if (object.objectType === AwsLeafType.kEC2) {
        // For EC2, return both AWS and Cohesity snapshot types. We can do this safely even if the object is protected
        // with only one policy since that will not affect the other one.
        accumulator.add(Environment.kAWSSnapshotManager);
        accumulator.add(Environment.kAWSNative);
      } else if (object.objectType === AwsLeafType.kRDS) {
        accumulator.add(Environment.kRDSSnapshotManager);
      } else if (object.objectType === AwsLeafType.kAurora) {
        accumulator.add(Environment.kAuroraSnapshotManager);
      } else if (object.objectType === AwsLeafType.kS3) {
        accumulator.add(Environment.kAwsS3);
      }
      return accumulator;
    }, new Set<any>());

    objectProtectActionParams.snapshotBackendTypes = [...snapshotBackendTypesSet];
    return objectProtectActionParams;
  }

  /**
   * Method called to get additional cancel run params for Aws objects.
   *
   * @param   object   The object to perform the action on.
   * @returns the object action request params.
   */
  getAwsCancelRunParams(
    object: ProtectedObjectInfo,
    objectActionKey?: ProtectdObjectsActionRequest['objectActionKey'],
  ): Partial<CancelObjectRunsParams> {
    const objectProtectActionParams: Partial<CancelObjectRunsParams> = {};

    if (objectActionKey) {
      objectProtectActionParams.snapshotBackendTypes = [objectActionKey as any];
    } else {
      let protectionTypes = [];
      const policies = object?.objectBackupConfiguration.policyConfig?.policies;
      const objectType = object?.objectBackupConfiguration?.awsParams?.protectionType;
      if (policies) {
        protectionTypes = policies.map(objectPolicy => objectPolicy.protectionType);
      } else if (objectType === Environment.kRDSSnapshotManager) {
        protectionTypes.push(Environment.kRDSSnapshotManager);
      } else if (objectType === Environment.kAuroraSnapshotManager) {
        protectionTypes.push(Environment.kAuroraSnapshotManager);
      } else {
        protectionTypes.push(object?.objectBackupConfiguration.awsParams.protectionType);
      }

      protectionTypes = protectionTypes.map(protectionType => {
        switch (protectionType) {
          case CloudJobType.kNative:
            return Environment.kAWSNative;
          case CloudJobType.kSnapshotManager:
            return Environment.kAWSSnapshotManager;
          default:
            return protectionType;
        }
      });

      objectProtectActionParams.snapshotBackendTypes = [...new Set(protectionTypes)];
    }
    return objectProtectActionParams;
  }

  /**
   * Method called to populate source special params.
   *
   * @param  objects  The objects being protected
   * @returns list of source specific settings
   */
  getSourceSpecialParameters(objects: SimpleObjectInfo[]): SourceSpecialParameter[] {
    if (!objects?.length) {
      return;
    }

    const { environment } = objects[0];
    const sourceSpecialParameters: SourceSpecialParameter[] = [];

    // Populate source specific parameters
    if (environment === Environment.kOracle) {
      sourceSpecialParameters.push({
        sourceId: objects[0].sourceId,
        oracleSpecialParameters: {
          appParamsList: objects.map(object => ({
            databaseAppId: object.id,
            nodeChannelList: [],
          })),
          applicationEntityIds: objects.map(object => object.id),
        },
      });
    }
    return sourceSpecialParameters;
  }
}
