import { AttributeFilter, TimeRangeFilterParams } from '@cohesity/api/reporting';
import { DataFilterValue, Timeframe, timeRangeParameterMap } from '@cohesity/helix';
import { enumGroupMap } from '@cohesity/iris-shared-constants';
import { filterReportingUrlParams } from './filters-maps';

/**
 * Mapping of TimeFrame to dateRange
 */
const timeFrameToDateRange: {[key: number]: TimeRangeFilterParams['dateRange']} = {
  [Timeframe.Past1Hour]: 'Last1Hour',
  [Timeframe.Past24Hours]: 'Last24Hours',
  [Timeframe.Past7Days]: 'Last7Days',
  [Timeframe.Past30Days]: 'Last30Days',
  [Timeframe.Past90Days]: 'Last90Days',
  [Timeframe.Past180Days]: 'Last180Days',
  [Timeframe.Past365Days]: 'Last365Days',
  [Timeframe.PastMonth]: 'LastMonth',
  [Timeframe.Past3Months]: 'Last3Months',
  [Timeframe.Past6Months]: 'Last6Months',
  [Timeframe.PastYear]: 'LastYear',
  [Timeframe.CurrentMonth]: 'CurrentMonth',
  [Timeframe.CurrentYear]: 'CurrentYear',
};

/**
 * Mapping of dateRange to URL parameter.
 */
const dateRangeToParameter: {[key in keyof typeof timeFrameToDateRange]: string} = {
  [timeFrameToDateRange[Timeframe.Past1Hour]]: 'past1Hour',
  [timeFrameToDateRange[Timeframe.Past24Hours]]: 'past24hours',
  [timeFrameToDateRange[Timeframe.Past7Days]]: 'past7days',
  [timeFrameToDateRange[Timeframe.Past30Days]]: 'past30days',
  [timeFrameToDateRange[Timeframe.Past90Days]]: 'past90days',
  [timeFrameToDateRange[Timeframe.Past180Days]]: 'past180days',
  [timeFrameToDateRange[Timeframe.Past365Days]]: 'past365days',
  [timeFrameToDateRange[Timeframe.PastMonth]]: 'pastmonth',
  [timeFrameToDateRange[Timeframe.Past3Months]]: 'past3months',
  [timeFrameToDateRange[Timeframe.Past6Months]]: 'past6months',
  [timeFrameToDateRange[Timeframe.PastYear]]: 'pastyear',
  [timeFrameToDateRange[Timeframe.CurrentMonth]]: 'currentmonth',
  [timeFrameToDateRange[Timeframe.CurrentYear]]: 'currentyear',
};

/**
 * Delimiter for Url parameter.
 */
export const ReportUrlParamDelimiter = '|';

/**
 * Maps the api attribute type to url.
 *
 * @param    attr Api type attribute.
 * @returns  mapping of url parameter name to parameter value.
 */
export function attributeToUrl(
  attr: AttributeFilter[],
): {[key: string]: string[] | string} {
  const retData: {[key: string]: string[] | string} = {};

  attr?.forEach(item => {
    let filterValues;

    switch (item.filterType) {
      case 'In':
        filterValues = item.inFilterParams?.stringFilterValues;

        retData[item.attribute] = item.attribute === 'objectUuid' ?
          // eslint-disable-next-line arrow-body-style
          filterValues?.map((id, i) => item.inFilterParams.attributeLabels[i] + ReportUrlParamDelimiter + id) :
          filterValues;

        // If multiple values, it implies env group is selected.
        if (item.attribute === 'environment' && filterValues?.length > 1) {
          const envGroup = enumGroupMap[filterValues[0]];

          if (envGroup) {
            retData[item.attribute] = [ envGroup ];
          }
        }
        break;
      case 'Systems':
        retData[item.attribute] = item.systemsFilterParams.systemIds;
        break;
      case 'Tenants':
        retData[item.attribute] = item.tenantsFilterParams.tenantIds;
        break;
      case 'TimeRange':
        if (item.timeRangeFilterParams?.dateRange) {
          retData.timeRange = dateRangeToParameter[item.timeRangeFilterParams.dateRange] || 'currentmonth';
        } else {
          retData.startTime = Math.floor((item.timeRangeFilterParams?.lowerBound || 0) / 1000).toString();
          retData.endTime = Math.floor((item.timeRangeFilterParams?.upperBound || 0) / 1000).toString();
        }
        break;
    }
  });
  return retData;
}

/**
 * Maps the api attribute type to generic filter value data.
 *
 * @param    filters Api type attribute.
 * @returns  Generic filter data structure.
 */
export function attributeToFilter(
  filters: AttributeFilter[],
): DataFilterValue<any>[] {
  const params = attributeToUrl(filters);

  return Object.keys(params)
    .filter(key => filterReportingUrlParams.includes(key) && params[key])
    .map((key) => ({
      key: key,
      value: params[key],
      predicate: null
    }));
}

/**
 * Maps the filter attribute type to api.
 *
 * @param    filters Filter type attribute.
 * @returns  Filters data structure for API Call.
 */
export function filterToAttribute(filters: DataFilterValue<any>[]): AttributeFilter[] {
  const retData: AttributeFilter[] = [];
  filters.forEach(item => {
    switch (item.key) {
      case 'systemId':
        if (item.value.length) {
          retData.push({
            attribute: item.key,
            filterType: 'Systems',
            systemsFilterParams: {
              systemIds: item.value.map(value => value.value as string),
              systemNames: item.value.map(value => value.label),
            },
          });
        }
        break;
      case 'tenantsId':
      case 'tenantId':
        if (item.value.length) {
          retData.push({
            attribute: item.key,
            filterType: 'Tenants',
            tenantsFilterParams: {
              tenantIds: item.value.map(value => value.value as string),
              tenantNames: item.value.map(value => value.label),
            },
          });
        }
        break;
      case 'date':
        if (item.value.start || item.value.timeframe) {
          retData.push({
            attribute: item.key,
            filterType: 'TimeRange',
            timeRangeFilterParams: {
              dateRange: timeFrameToDateRange[item.value.timeframe],
              lowerBound: item.value.start?.valueOf() * 1000,
              upperBound: item.value.end?.valueOf() * 1000,
            },
          });
        }
        break;
      default: // rest translate tot in
        if (item.value?.length) {
          let filterValues = item.value;

          if (item.key === 'environment' && filterValues[0].subItems) {
            filterValues = filterValues[0].subItems;
          }

          retData.push({
            attribute: item.key,
            filterType: 'In',
            inFilterParams: {
              attributeDataType: item.key === 'sourceUuids' ? 'StringArray' : 'String',
              stringFilterValues: filterValues.map(value => value.value as string),
              attributeLabels: filterValues.map(value => value.label),
            },
          });
        }
        break;
    }
  });
  return retData;
}

/**
 * Gets URL state params from filter values
 *
 * @param filters filters list selected
 */
export function getUrlParams(filters: DataFilterValue<any>[]) {
  const params: any = {};

  filters.forEach(filterInfo => {

    if (filterInfo.key === 'date') {
      const { start, end, timeframe } = filterInfo.value;

      if (timeframe && timeRangeParameterMap[timeframe]) {
        params.timeRange = timeRangeParameterMap[timeframe];
        params.startTime = undefined;
        params.endTime = undefined;
      } else {
        params.timeRange = undefined;
        if (start) {
          params.startTime = start.valueOf();
        }
        if (end) {
          params.endTime = end.valueOf();
        }
      }
    } else {
      params[filterInfo.key] = filterInfo.value.map(( value ) =>
        filterInfo.key === 'objectUuid' ?
          (value.label + ReportUrlParamDelimiter + value.value) :
          value.value
      );
    }
  });
  return params;
}

/**
 * Maps the date range value to timeframe
 *
 * @param dateRange date range for which the timeframe value is required
 * @returns timeframe value for the given daterange
 */
export function getTimeframeFromDateRange(dateRange: TimeRangeFilterParams['dateRange']): Timeframe {
  let timeframe: Timeframe;
  switch (dateRange) {
    case 'Last1Hour':
      timeframe = Timeframe.Past1Hour;
      break;
    case 'Last24Hours':
      timeframe = Timeframe.Past24Hours;
      break;
    case 'Last7Days':
      timeframe = Timeframe.Past7Days;
      break;
    case 'Last30Days':
      timeframe = Timeframe.Past30Days;
      break;
    case 'Last90Days':
      timeframe = Timeframe.Past90Days;
      break;
    case 'Last180Days':
      timeframe = Timeframe.Past180Days;
      break;
    case 'Last365Days':
      timeframe = Timeframe.Past365Days;
      break;
    case 'LastMonth':
      timeframe = Timeframe.PastMonth;
      break;
    case 'Last3Months':
      timeframe = Timeframe.Past3Months;
      break;
    case 'Last6Months':
      timeframe = Timeframe.Past6Months;
      break;
    case 'LastYear':
      timeframe = Timeframe.PastYear;
      break;
    case 'CurrentMonth':
      timeframe = Timeframe.CurrentMonth;
      break;
    case 'CurrentYear':
      timeframe = Timeframe.CurrentYear;
      break;
  }

  return timeframe;
}

/**
 * Gets filter values.
 *
 * @param    filter  Filter.
 * @param    firstValueOnly  return first value and count if true.
 * @param    countOnly If ture, returns count only if count > 1.
 * @returns  Filter values.
 */
export function getFilterValues(
  filter: AttributeFilter,
  firstValueOnly = false,
  countOnly = false,
): string | string[] {
  const values = filter?.inFilterParams?.attributeLabels ||
    filter?.systemsFilterParams?.systemNames ||
    filter?.tenantsFilterParams?.tenantNames ||
    [];

  if (values?.length && firstValueOnly) {
    if (values.length > 1) {
      if (countOnly) {
        return values.length.toString();
      }
      return `${values[0]} + ${values.length - 1}`;
    }
    return values[0];
  }
  return values;
}

/**
 * Gets filter values in a string.
 *
 * @param    filters  Filters.
 * @returns  Filter values in a string.
 */
export function getFilterValuesString(filters: AttributeFilter[], translate: (key, params?) => string): string {
  const values: string[] = [];

  filters?.forEach(filter => {
    if (filter.timeRangeFilterParams) {
      values.push(translate('timeframe.' + filter.timeRangeFilterParams.dateRange));
    } else {
      values.push(...getFilterValues(filter));
    }
  });
  return values.join(', ');
}
