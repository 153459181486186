// Module: Helios

;(function (angular, undefined) {
  'use strict';

  angular
    .module('C.helios', [])
    .config(configFn)
    .controller('heliosController', heliosControllerFn);

  function configFn($stateProvider) {
    $stateProvider
      .state('helios', {
        url: '^/helios',
        help: 'platform_helios',
        canAccess: 'CLUSTER_MODIFY && FEATURE_FLAGS.heliosEnabled',
        templateUrl: 'app/helios/helios.html',
        controller: 'heliosController as $ctrl',
        title: 'Connect to Helios',
      });
  }

  function heliosControllerFn(_, API, HeliosService, evalAJAX, $window,
    $timeout, cMessage, HELIOS_ENDPOINT, ClusterService, $state,
    FEATURE_FLAGS, cModal) {
    var $ctrl = this;

    var _pollingInterval = 1000;
    var _stopPolling;

    _.assign($ctrl, {
      $onInit: $onInit,
      $onDestroy: $onDestroy,
      triggerUnregister: triggerUnregister,
      updateHeliosConnectionStatus: updateHeliosConnectionStatus,
      updateHeliosConfiguration: updateHeliosConfiguration,
      loading: true,
    });

    /**
     * Initialize this component.
     *
     * @method   init
     */
    function $onInit() {

      $ctrl.loading = true;
      // Get the current configuration from Service
      _getHeliosConfiguration().then(function checkLicensingStatus() {
        // if a old customer is yet to accept license service terms and
        // conditions
        if (FEATURE_FLAGS.licensing &&
          ClusterService.isLicenseAcceptanceNeeded()) {
            if (FEATURE_FLAGS.clusterLicensePageRevamp) {
              $state.go('connected-site-ng.select-mode');
            } else {
              $state.go('connected-site', { registrationType: 'saas' });
            }
        }
      }).finally(function finishLoading() {
        $ctrl.loading = false;
        if ($ctrl.config._isRegistering || $ctrl.config._isConnecting) {
          _initiateTimeout();
        }
      });
    }

    /**
     * Get the current Helios Configuration
     *
     * @method   _getHeliosConfiguration
     * @return   {object}   A promise with the Helios Status or an error.
     */
    function _getHeliosConfiguration() {
      return HeliosService.getHeliosConfiguration().then(
        function getConfiguration(config) {
          // Get and load the configuration
          $ctrl.config = config;
        }, evalAJAX.errorMessage
      );
    }

    /**
     * Open confirmation dialog before disabling helios connection and
     * updates helios configuration.
     *
     * @method   updateHeliosConnectionStatus
     * @param    {boolean}   status   The status of the Helios Registration.
     */
    function updateHeliosConnectionStatus(status) {
      // If we need to claim or enable the connection to the cluster, we will
      // update the registration information.
      if (status) {
        updateHeliosConfiguration(status);
        return;
      }

      // Update the user with information about that disabling connection will
      // stop certain app from being managed.
      var modalOptions = {
        contentKey: 'helios.disableConnectionInfoText',
      };
      cModal.standardModal({}, modalOptions).then(
        // On Success, update the Helios configuration.
        updateHeliosConfiguration.bind(this, status),

        // Set the value back to true if the user cancelled the popup.
        function disableHeliosConnectionError() {
          $ctrl.config.enableMcm = true;
        }
      );
    }

    /**
     * Enables or Disables the connection to Helios.
     *
     * @method   updateHeliosConfiguration
     * @param    {boolean}   status   The status of the Helios Registration.
     */
    function updateHeliosConfiguration(status) {
      if (status && !$ctrl.config._isRegistered) {
        // Trigger a registration call
        $window.location.href = API.private('mcm/registerCluster');
        return;
      }

      // In case if user has opted for connect helios for licensing only,
      // now if he reconnect to helios using toggle btn, his licenseOnly should
      // become false.
      if (FEATURE_FLAGS.licensing) {
        angular.merge($ctrl.config, {licenseOnly: false});
      }

      $ctrl.config.enableMcm = status;

      HeliosService.updateHeliosConfiguration($ctrl.config).catch(
        evalAJAX.errorMessage
      ).finally(function finalize() {
        _getHeliosConfiguration().finally(_initiateTimeout);
      });
    }

    /**
     * Check if polling needs to be done.
     * Polling is initated only in two conditions
     *   - When cluster is still registering
     *   - When cluster is registered but not connected to Helios
     *
     * The success message is shown after successful registration and
     * connection.
     *
     * @method   _initiateTimeout
     */
    function _initiateTimeout() {
      var config = $ctrl.config;
      switch (true) {
        // If it's not enabled and not registered do nothing.
        case !config.enableMcm && !config._isRegistering:
          break;

        // If it's registering or connecting poll for status.
        case config._isRegistering:
        case config._isRegistered && config._isConnecting:
          // Stop existing poll and start a new one.
          $timeout.cancel(_stopPolling);
          _stopPolling = $timeout(function startPoll() {
            _getHeliosConfiguration().finally(_initiateTimeout);
          }, _pollingInterval);
          break;

        // If all is okay, display the message.
        case config._isRegistered && config._isConnected && config.enableMcm:
          cMessage.success({
            titleKey: 'clusterConnectedToHelios',
            textKey: 'heliosAccessToCluster',
            textKeyContext: { url: HELIOS_ENDPOINT }
          });
          break;
      }
    }

    /**
     * Trigger the unregistration of the cluster.
     *
     * @method   triggerUnregister
     */
    function triggerUnregister() {
      var data = {
        clusterIdentifier: ClusterService.clusterInfo.id,
        clusterIncarnation: ClusterService.clusterInfo.incarnationId,
      };

      $ctrl.startUnregister = true;
      HeliosService.unregisterClusterFromHelios(data).then(
        function unregistrationSuccessful() {
          cMessage.success({
            textKey: 'clusterManagement.unregisterSuccessful'
          });
        },
        evalAJAX.errorMessage
      ).finally(function finalize() {
        $ctrl.startUnregister = false;
        _getHeliosConfiguration();
      });
    }

    /**
     * Destroy function
     *
     * @method   destroy
     */
    function $onDestroy() {
      $timeout.cancel(_stopPolling);
    }
  }

})(angular);
