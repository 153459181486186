import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ClusterConfig, IrisContextService, isHeliosTenantUser } from '@cohesity/iris-core';
import { Observable } from 'rxjs';
import { finalize, map, startWith } from 'rxjs/operators';
import { AppServiceManagerService } from 'src/app/app-services';
import { AppStateService, RemoteClusterService, ScopeSelectorService } from 'src/app/core/services';
import { AjsClusterService } from 'src/app/shared/ng-upgrade/services';

/**
 * The cluster scope selector component used to select access cluster or all
 * clusters aggregated view.
 */
@Component({
  selector: 'coh-cluster-scope-selector',
  templateUrl: './cluster-scope-selector.component.html',
  styleUrls: ['./cluster-scope-selector.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ClusterScopeSelectorComponent implements OnInit {
  /**
   * The search field.
   */
  @ViewChild('scopeSearch') searchField: ElementRef;

  /**
   * The threshold for which cluster scope search will be displayed.
   */
  readonly minClustersForSearch = 8;

  /**
   * Indicates if clusters are being loaded.
   */
  loadingClusters = true;

  /**
   * The search term used to filter out the clusters list.
   */
  searchTerm = new UntypedFormControl('');

  /**
   * Indicates whether a cluster context switch is in progress or not.
   *
   * @returns True when switching context else will return false.
   */
  get isSwitchingContext(): boolean {
    return this.appStateService.isSwitchingContext;
  }

  /**
   * The List of registered clusters.
   */
  private clustersList: ClusterConfig[] = [];

  /**
   * The visible List of registered clusters after applying filter by provided
   * search term.
   */
  visibleClustersList$: Observable<ClusterConfig[]>;

  /**
   * These are available cohesity services that the scope selector should show.
   */
  serviceOptions: ClusterConfig[];

  /**
   * The cluster config representing Global scope (Helios clusters + DMaaS data).
   */
  globalOption: ClusterConfig;

  /**
   * The number of services.
   */
  numServices: number;

  /**
   * The number of clusters.
   */
  numClusters: number;

  /**
   * get basic cluster info.
   *
   * @returns  The basic cluster from ajs service.
   */
  get basicClusterInfo(): ClusterConfig {
    return this.ctxService.irisContext.basicClusterInfo;
  }

  /**
   * Provides an icon shape for the provided cluster config.
   *
   * @param cluster   The cluster to provide an icon for.
   */
  iconShape(cluster: ClusterConfig): string {
    const scopeIcon = this.appServiceManager.getServiceByClusterConfig(cluster)?.scopeIcon;
    if (scopeIcon) {
      return scopeIcon;
    }

    if (cluster._allClusters) {
      return 'helix:system-helios';
    }
    if (this.basicClusterInfo.mcmMode && !cluster.connectedToCluster) {
      return 'helix:status-warning-alt';
    }
    return 'helix:cluster';
  }

  /**
   * Indicates if the cluster search field should be displayed.
   */
  get searchEnabled(): boolean {
    return this.numClusters >= this.minClustersForSearch;
  }

  constructor(
    private appServiceManager: AppServiceManagerService,
    private ajsClusterService: AjsClusterService,
    private appStateService: AppStateService,
    private remoteClusterService: RemoteClusterService,
    private scopeSelectorService: ScopeSelectorService,
    private ctxService: IrisContextService
  ) {}

  /**
   * Initialize the component by fetching list of registered clusters list.
   */
  ngOnInit() {
    // Handle Helios only remote clusters list.
    if (this.basicClusterInfo.mcmMode) {
      // Listen to list of remote clusters only on Helios mode.
      this.remoteClusterService.getRemoteClustersList()
        .pipe(
          finalize(() => this.loadingClusters = false)
        )
        .subscribe(
          (clusters) => {
            if (!isHeliosTenantUser(this.ctxService.irisContext)) {
              this.serviceOptions = clusters.filter(
                // Get all services, except cluster manager as this concept didn't
                // exist in this legacy switcher and was introduced in the AppSwitcher enhancement.
                cluster => cluster._cohesityService && cluster._serviceType !== 'clusterManager'
              );
              this.numServices = this.serviceOptions.length;
              this.globalOption = clusters.find(cluster => cluster._globalContext);
            }
            this.numClusters = clusters.reduce(
              (count: number, cluster: ClusterConfig) => cluster._nonCluster ? count : ++count,
              0
            );

            this.clustersList = clusters;
            this.initFilters();
          }
      );
    } else {
      // Getting the list of remote cluster from iris shared store.
      this.clustersList = this.appStateService.remoteClusterList;
      this.numClusters = this.clustersList?.length;
      this.loadingClusters = false;

      // initialize and setup the filters.
      this.initFilters();
    }
  }

  /**
   * Determines whether provided cluster is access cluster or not and Access cluster will present
   * only in on-prem using SPOG and it would be 1st cluster in the remoteClusterList.
   *
   * @param  cluster  The cluster to Test.
   * @returns  Return true for cluster is access cluster else return false.
   */
  isAccessCluster(cluster: ClusterConfig): boolean {
    return this.remoteClusterService.isAccessCluster(cluster);
  }

  /**
   * Determines whether provided cluster is equal to selected cluster.
   *
   * @param  cluster  The cluster to Test.
   * @returns  Return true for selected cluster else return false.
   */
  isSelected(cluster: ClusterConfig) {
    const { selectedScope } = this.appStateService;

    return this.appStateService.getClusterConfigId(selectedScope)
      === this.appStateService.getClusterConfigId(cluster);
  }

  /**
   * Determines whether provided cluster selection is disabled or not.
   *
   * @param  cluster  The cluster to Test.
   * @returns  Return true for cluster selection is disabled else return false.
   */
  isDisabled(cluster: ClusterConfig) {
    if (cluster._nonCluster) {
      return false;
    }

    return this.basicClusterInfo.mcmMode && !cluster.connectedToCluster;
  }

  /**
   * Initialize and setup observable over search term.
   * NOTE: async pipe will internally un-subscribe when visibleClustersList$
   * observer is changed.
   * https://github.com/angular/angular/blob/master/packages/common/src/pipes/async_pipe.ts#L100
   */
  private initFilters() {
    // Observe search term and emits visible clusters list.
    this.visibleClustersList$ = this.searchTerm.valueChanges.pipe(
      // initialize the filter with current search term value.
      startWith(this.searchTerm.value),
      map(searchTerm => this.filterClustersList(this.clustersList, searchTerm))
    );

    // Setting focus of the search field by default so user can immediately
    // start typing a search string (once filters have been initialized).
    // Doing this in a setTimeout to allow a cycle so the field will be rendered
    // before trying to focus it, as the field is hidden until we know there are
    // enough clusters to warrant showing the search input.
    setTimeout(() => {
      this.searchField?.nativeElement?.focus();
    });

  }

  /**
   * Filter the clusters list by provided search term.
   *
   * @param    clustersList  The List of remote clusters.
   * @param    searchTerm    The search term.
   * @returns  The filtered list of remote clusters.
   */
  private filterClustersList(clustersList: ClusterConfig[], searchTerm: string): ClusterConfig[] {
    const filteredClustersList = clustersList.filter(
      cluster => !(cluster._cohesityService || cluster._globalContext)
    );

    if (searchTerm && searchTerm !== '') {
      return filteredClustersList.filter(cluster => cluster.name.toLowerCase().includes(searchTerm.toLowerCase()));
    }
    return filteredClustersList;
  }

  /**
   * Handles cluster scope selection.
   *
   * @param  event  The mouse click event object.
   * @param  cluster  The cluster to select.
   */
  handleScopeSelection(event: MouseEvent, cluster: ClusterConfig) {
    if (this.isSelected(cluster)) {
      // The config is already selected.
      return;
    }

    // preventing browser default radio button selection when clicked.
    event.preventDefault();

    // Set loading so the spinner shows until the app-panel closes.
    // NOTE: this is a bit of hack and if cluster selection fails the panel
    // might get stuck in an infinite loading spinner, but this is not something
    // that should ever happen in theory.
    this.loadingClusters = true;

    if (cluster._nonCluster || this.appStateService.selectedScope._nonCluster) {
      // For straightforward cases, cutting out all the complex logic.
      this.scopeSelectorService.basicScopeSwitch(cluster).subscribe();
    } else {
      // Suppress reloading of the state, when the application is running in Helios
      // The mcm-view.service will determine whether to reload the state or not.
      const suppressReload = !!this.basicClusterInfo.mcmMode;

      this.scopeSelectorService.switchScope(cluster, suppressReload);
    }
  }
}
