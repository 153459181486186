import { ANTLRErrorListener, Lexer, Parser, ParserRuleContext, Vocabulary } from 'antlr4ts';

import { CursorLocation, Suggestion } from './advanced-search.models';
import { ErrorStrategy } from './error-strategy';

/**
 * ANTLR custom error listener.
 */
class ErrorListener implements ANTLRErrorListener<string> {
  /** The provided error message */
  message: string;

  /**
   * Clear the error message.
   */
  clearMessage() {
    this.message = null;
  }

  /**
   * This implementation capture the error message produced by ANTLR DefaultErrorStrategy.
   *
   * @param recognizer What parser got the error. From this object, you can access the context as well as
   * the input stream.
   * @param offendingSymbol The offending token in the input token stream, unless recognizer is a lexer
   * (then it's `undefined`). If no viable alternative error, `e` has token at which we started production
   * for the decision.
   * @param line The line number in the input where the error occurred.
   * @param charPositionInLine The character position within that line where the error occurred.
   * @param msg The message to emit.
   * @param e The exception generated by the parser that led to the reporting of an error. It is `undefined`
   * in the case where the parser was able to recover in line without exiting the surrounding rule.
   */
  syntaxError(recognizer, offendingSymbol, line, charPositionInLine, msg, _e) {
    this.message = `line ${line}:${charPositionInLine} ${msg}`;
  }
}

/**
 * Grammar options act as a abstract interface for advance search components to support different grammars where
 * each grammar contains different vocabulary, lexer, parser and tree generation logic.
 */
export abstract class GrammarOpts<
  GrammarLexer extends Lexer,
  GrammarParser extends Parser,
  ParseContext extends ParserRuleContext
> {
  /**
   * The custom error listener.
   */
  readonly errorListener = new ErrorListener();

  /**
   * The grammar vocabulary.
   */
  abstract vocabulary: Vocabulary;

  /**
   * Get the grammar lexer.
   *
   * @param searchQuery The search query.
   * @returns The grammar lexer.
   */
  abstract lexer(searchQuery: string): GrammarLexer;

  /**
   * Get the grammar parser.
   *
   * @param lexer The grammar's lexer.
   * @returns The grammar parser.
   */
  abstract parser(lexer: GrammarLexer): GrammarParser;

  /**
   * Get the parsed abstract syntax tree(AST).
   *
   * @param parser The grammar's parser.
   * @returns The parsed abstract syntax tree(AST).
   */
  abstract tree(parser: GrammarParser): ParseContext;

  /**
   * Get suggestion by visiting the abstract syntax tree(AST).
   *
   * @param searchQuery The search query.
   * @param cursorLocation The cursor location.
   * @param tree The parsed abstract syntax tree(AST).
   * @returns The suggestion.
   */
  abstract visit(searchQuery: string, cursorLocation: CursorLocation, tree: ParseContext): Suggestion;

  /**
   * Get the error message from report by ANTLR DefaultErrorStrategy.
   *
   * @returns The error message.
   */
  abstract getErrorMessage(): string;

  /**
   * Get the parser for a given search query.
   *
   * @param searchQuery The search query.
   * @returns The suggestion.
   */
  getParser = (searchQuery: string): GrammarParser => {
    const lexer = this.lexer(searchQuery);

    // removing default lexer error listener to reduce un-necessary console logs.
    lexer.removeErrorListeners();
    lexer.addErrorListener(this.errorListener);

    const parser = this.parser(lexer);

    // removing default error & parser listener to reduce un-necessary console logs.
    parser.removeErrorListeners();
    parser.removeParseListeners();

    // using custom error handle.
    parser.errorHandler = new ErrorStrategy();

    // adding custom error listener to report error message produced by ANTLR DefaultErrorStrategy.
    this.errorListener.clearMessage();
    parser.addErrorListener(this.errorListener);

    return parser;
  };

  /**
   * Get the parsed abstract syntax tree(AST) for a given search query.
   *
   * @param searchQuery The search query.
   * @returns The parsed abstract syntax tree(AST).
   */
  getTree = (searchQuery: string) => {
    const parser = this.getParser(searchQuery);
    return this.tree(parser);
  };

  /**
   * Get suggestion by visiting the abstract syntax tree(AST).
   *
   * @param searchQuery The search query.
   * @param cursorLocation The cursor location.
   * @param tree The parsed abstract syntax tree(AST).
   * @returns The suggestion.
   */
  visitTree(searchQuery: string, cursorLocation: CursorLocation, tree: ParseContext): Suggestion {
    const suggestion = this.visit(searchQuery, cursorLocation, tree);
    return {
      ...suggestion,
      errorMessage: this.getErrorMessage(),
    };
  }
}
