<coh-source-special-parameters [form]="form" [nodeContext]="nodeContext"
  [label]="(excludeDisks?.length || includeDisks?.length) ? ('cSourceTreeNode.customized' | translate) : ''"
  (dialogOpened)="updateForm()"
  (saved)="onSaved()">
  <mat-card *ngIf="form" [formGroup]="form">
    <mat-slide-toggle class="margin-top-sm" formControlName="enableDiskFilter"
      cogDataId="enable-disk-inclusion-exclusion"
      [inheritDataId]="true"
      (change)="toggleDisks()">
      {{'enableDiskInclusionExclusion' | translate}}
    </mat-slide-toggle>

    <cog-banner class="margin-top">
      <p>{{'job.settings.exclusionInclusion.acropolis.selectDisks' | translate}}</p>
    </cog-banner>

    <ng-container *ngIf="form.value.enableDiskFilter">
      <mat-radio-group class="margin-top" formControlName="type" (change)="toggleType()">
        <mat-radio-button cogDataId="include-disks-radio-button" [inheritDataId]="true" value="include">
          {{'include' | translate}}
        </mat-radio-button>
        <div class="select-disks-container">
          <mat-hint>{{'job.settings.exclusionInclusion.acropolis.includeHint' | translate}}</mat-hint>
          <ng-container *ngIf="form.value.type === 'include'">
            <ng-container *ngTemplateOutlet="selectDisks; context: {type: 'include'}"></ng-container>
          </ng-container>
        </div>
        <mat-radio-button cogDataId="exclude-disks-radio-button" [inheritDataId]="true" value="exclude">
          {{'exclude' | translate}}
        </mat-radio-button>
        <div class="select-disks-container">
          <mat-hint>{{'job.settings.exclusionInclusion.acropolis.excludeHint' | translate}}</mat-hint>
          <ng-container *ngIf="form.value.type === 'exclude'" >
            <ng-container *ngTemplateOutlet="selectDisks; context: {type: 'exclude'}"></ng-container>
          </ng-container>
        </div>
      </mat-radio-group>

      <ng-template #selectDisks let-type="type">
        <mat-form-field class="margin-top">
          <mat-select cogDataId="include-exclude-disks-selector"
            placeholder="{{(type === 'include' ? 'includeDisks' : 'excludeDisks') | translate}}"
            formControlName="disks" multiple>
            <mat-select-trigger *ngIf="form.value.disks?.length">
              <mat-chip-list>
                <mat-chip class="chip"
                  *ngFor="let disk of form.value.disks; index as i"
                  [removable]="true"
                  (removed)="removeDisk(disk)">
                  {{'enum.controllerType.' + disk.deviceBus | translate}}.{{disk.deviceIndex}}
                  <cog-icon cogDataId="remove-disk-{{i}}" matChipRemove shape="close" size="sm"></cog-icon>
                </mat-chip>
              </mat-chip-list>
            </mat-select-trigger>
            <mat-option *ngFor="let disk of virtualDisks" [value]="disk" cogDataId="disk-{{disk.deviceIndex}}">
              <div>
                {{'enum.controllerType.' + disk.deviceBus | translate}}.{{disk.deviceIndex}}
              </div>
              <ul class="c-ul-inline no-margin-bottom">
                <li class="user-data-xl metadata">
                  {{ 'capacity' | label: (disk.diskSizeBytes | byteSize) }}
                </li>
              </ul>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </ng-template>
    </ng-container>
  </mat-card>
</coh-source-special-parameters>
