import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Api } from '@cohesity/api/private';
import { SourceServiceApi } from '@cohesity/api/v2';
import { httpClientCacheProxy } from '@cohesity/utils';
import { BehaviorSubject } from 'rxjs';

/**
 * Subject for reloading sources.
 */
const reloadSubject$ = new BehaviorSubject<null>(null);

@Injectable({
  providedIn: 'root'
})
export class EnhancedSourceServiceApiService extends SourceServiceApi {
  /**
   * Observable for subject for reloading sources.
   */
  reload$ = reloadSubject$.asObservable();

  constructor(httpClient: HttpClient) {
    super({rootUrl: Api.v2Root}, httpClientCacheProxy(
      httpClient,
      reloadSubject$,
    ));

    return this;
  }
}
