import { InjectionToken } from '@angular/core';

export interface GridConfig {
  /**
   * Gutter size b/w cards in px.
   */
  gutterSize: number;

  /**
   * Dashboard grid cell height in px and Adjacent cells can be grouped to form bigger cards.
   */
  rowHeight: number;
}

const gridConfig: GridConfig = {
  gutterSize: 16,
  rowHeight: 155,
};

export const GRID_CONFIG = new InjectionToken<GridConfig>('gridConfig');

export const GridConfigProvider = {
  provide: GRID_CONFIG,
  useValue: gridConfig
};
