<!-- Domain name -->
<div class="dl-row">
  <dt translate="domainName"></dt>
  <dd>
    {{ mcmSourceInfo.azureParams?.azureTenantId }}
  </dd>
</div>

<!-- Cloud region -->
<div class="dl-row">
  <dt translate="cohesityCloudRegion"></dt>
  <dd>
    {{ mcmSourceInfo.regionId | translate }}
  </dd>
</div>

<!-- Application ID -->
<div class="dl-row">
  <dt translate="sources.azure.application.id"></dt>
  <dd>
    {{ (mcmSourceInfo.azureParams?.applicationCredentials ?? []) | simpleList: {propKey: 'applicationId'} }}
  </dd>
</div>

<!-- List of subscriptions -->
<div class="dl-row">
  <dt translate="sources.azure.subscriptions"></dt>
  <dd>
    <ul class="c-ul clean">
      <li *ngFor="let subscription of mcmSourceInfo.azureParams?.subscriptionDetails ?? []; let last = last"
        [class.margin-bottom-sm]="!last">
        {{ subscription.subscriptionId }}
      </li>
    </ul>
  </dd>
</div>

<!-- Number of SaaS connections -->
<div class="dl-row">
  <dt translate="saasConnections"></dt>
  <dd>
    {{ (mcmSourceInfo.connections ?? []).length }}
  </dd>
</div>
