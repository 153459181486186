import { SelectionModel } from '@angular/cdk/collections';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  ClusterNetworkingEndpoint,
  OracleAppParams,
  OracleHost,
  PhysicalProtectionSource,
  ProtectionSource,
  ProtectionSourceTreeInfo,
  RegisteredSourceInfo,
} from '@cohesity/api/v1';
import { OracleDatabaseHost } from '@cohesity/api/v2';
import {
  CanSelectAnyRowsFn,
  CanSelectRowFn,
  DataTreeNodeContext,
  IsAllSelectedFn,
  TableComponent,
  ToggleSelectAllFn,
} from '@cohesity/helix';
import { flagEnabled, IrisContextService, isDmsScope } from '@cohesity/iris-core';
import { TimePeriodOptions } from '@cohesity/shared-forms';
import { AjaxHandlerService, AutoDestroyable } from '@cohesity/utils';
import { OracleRegistrationService } from 'src/app/modules/protection/oracle/shared/oracle-registration.service';
import {
  ArchiveLogRetentionUnit,
  HostType,
  NodeSelectionType,
  NodeSettingsType,
  OracleBackupType,
  OracleDatabaseType,
  OracleRecoverToOptions,
  PhysicalEntityType,
} from 'src/app/shared/constants';
import { OracleSourceDataNode } from 'src/app/shared/source-tree/protection-source/oracle/oracle-source-data-node';

import { DecoratedOracleDatabaseHost, OracleUtilityService } from '../../services/oracle-utility.service';

/**
 * Oracle Restore Target instance definition.
 */
interface RestoreTarget {
  /**
   * The ID of the instance.
   */
  id: number;

  /**
   * Name of the instance.
   */
  name?: string;

  /**
   * Refernce to protection source.
   */
  source?: ProtectionSource;

  /**
   * Specifies registration information for a root node in a Protection Sources tree.
   */
  registrationInfo?: RegisteredSourceInfo;
}

/**
 * TODO(mythri): Simplify this component by breaking this into smaller chunks.
 * Oracle Mnmc settings component.
 */
@Component({
  selector: 'coh-oracle-node-settings',
  styleUrls: ['./oracle-node-settings.component.scss'],
  templateUrl: './oracle-node-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class OracleNodeSettingsComponent extends AutoDestroyable implements OnInit, AfterViewInit {
  /**
   * The node context, including info about the node and it's selection status.
   */
  @Input() nodeContext: DataTreeNodeContext<OracleSourceDataNode>;

  /**
   * Specifies database host id to database node info mapping.
   */
  @Input() databaseHostIdToNodeInfoMap?: { [key: string]: OracleDatabaseHost };

  /**
   * Selected restore target oracle instance.
   */
  @Input() restoreTarget: RestoreTarget;

  /**
   * Specifies if node settings is for Backup/Recovery.
   */
  @Input() nodeSettingsFor = 'backup';

  /**
   * Channel configuration label.
   */
  @Input() selectionLabel = 'options';

  /**
   * The form to contain the options.
   */
  @Input() form: UntypedFormGroup;

  /**
   * Stores the selected Oracle hosts for Multi node Multi channel support.
   */
  @Input() selectedDatabaseHostList = new SelectionModel<UntypedFormGroup>(true, []);

  /**
   * Selected oracle db params.
   */
  @Input() selectedOptions: OracleAppParams;

  /**
   * Specifies in case if default database host has to be pre-selected.
   */
  @Input() selectDbHostByDefault = false;

  /**
   * Specifies if auto channel selection type has to shown or not.
   */
  @Input() showAutochannelSelection = true;

  /**
   * Specifies if the component has to be shown in read-only mode.
   */
  @Input() isReadOnly = false;

  /**
   * True in case of original loaction restore.
   */
  @Input() restoreLocation: OracleRecoverToOptions;

  /**
   * Specifies if archive log restore option is selected.
   */
  @Input() isArchiveLogRestore: boolean;

  /**
   * Specifies if disaster recovery is enabled or not.
   */
  @Input() isDisasterRecovery: boolean;

  /**
   * Specifies if disaster recovery to rac is enabled or not.
   */
  @Input() restoreToRac: boolean;

  /**
   * Specifies if mnmc node selection can be allowed or not based on feature flag 'enableOracleMnmcNodesForceSelection'.
   */
  allowForceMnmcNodeSelection = flagEnabled(this.contextService.irisContext, 'enableOracleMnmcNodesForceSelection');

  /**
   * Specifies the array of Oracle Hosts available for Multi Node Multi Channel Backup/restore selection.
   */
  availableDatabaseHostList: DecoratedOracleDatabaseHost[] = [];

  /**
   * Specifies the array of database hosts.
   */
  databaseHostList: OracleHost[] = [];

  /**
   * Specifies the map from from the host IP address to the index of the Host info within 'databaseHostList'. This is
   * only used to optimize lookups.
   */
  databaseHostIndexMap = new Map<string, number>();

  /**
   * Specifies the set of host addresses which support the Multi Node/Multi Channel during backup/restore.
   */
  hostAddressSupportingMNMCSet = new Set<string>();

  /**
   * Specifies the array of available parent nodes.
   */
  availableParentNodeList: ClusterNetworkingEndpoint[] = [];

  /**
   * Specifies whether the credentials input is to be displayed.
   */
  canUpdateDatabaseCredentials: boolean;

  /**
   * Specifies whether Oracle Multi Node & Multi Channel settings be in Auto Select mode.
   */
  shouldAutoSelectMnmcSettings = true;

  /**
   * Specifies whether node selection should be disabled.
   * For ActivePassive (type kOracleAPCluster), all nodes should be selected by default and selection modal is disabled.
   */
  isActivePassive = false;

  /**
   * Specifies whether db credentials are required.
   */
  areDbCredentialsRequired = false;

  /**
   * Specifies whether option to select backup type is enabled.
   * Supported backup types: SBT and Image Copy.
   */
  backupTypeSelectEnabled = false;

  /**
   * Archive log retention unit.
   */
  readonly archiveLogRetentionUnit = ArchiveLogRetentionUnit;

  /**
   * Specifies the columns to be rendered for Oracle multi node and channel.
   */
  readonly displayedColumns = [
    'fqdn',
    'hostAddress',
    'channelCount',
  ];

  /**
   * Default time period options to use for archive log retention.
   */
  readonly archiveLogRetentionTimePeriodOptions: TimePeriodOptions[] = [
    { label: 'hour', value: ArchiveLogRetentionUnit.Hours, labelPlural: 'hours' },
    { label: 'day', value: ArchiveLogRetentionUnit.Days, labelPlural: 'days' },
  ].filter(retention => retention.value !== ArchiveLogRetentionUnit.Hours ||
    flagEnabled(this.contextService.irisContext, 'oracleArchiveLogRetentionInHours'));

  /**
   * Oracle channel settings event emitter.
   */
  @Output() updateChannelSettings = new EventEmitter<OracleAppParams>();

  /**
   * Gets a reference to the table in order to look up the data rows that are
   * currently rendered.
   */
  @ViewChild(TableComponent, { static: true }) table: TableComponent<UntypedFormGroup>;

  /**
   * Returns the Form Array for Database hosts.
   *
   * @return Form Array for Database hosts
   */
  get databaseHostFormArray(): UntypedFormArray {
    return this.form.get('databaseHostFormArray') as UntypedFormArray;
  }

  /**
   * Returns the form control for backup type.
   *
   * @return form control for backup type
   */
  get backupType(): UntypedFormControl {
    return this.form.get('backupType') as UntypedFormControl;
  }

  /**
   * Returns the form control for archiveLogRetentionType.
   *
   * @return form control for archiveLogRetentionType
   */
  get archiveLogRetentionType(): UntypedFormControl {
    return this.form.get('archiveLogRetentionType') as UntypedFormControl;
  }

  /**
   * Returns the form control for Channel Option.
   *
   * @return form control for Channel Option
   */
  get channelOption(): UntypedFormControl {
    return this.form.get('channelOption') as UntypedFormControl;
  }

  /**
   * Returns the form control for setDbCredentials toggle.
   *
   * @return form control for setDbCredentials toggle
   */
  get setDbCredentials(): UntypedFormControl {
    return this.form.get('setDbCredentials') as UntypedFormControl;
  }

  /**
   * Returns the dbCredentials form group.
   *
   * @return dbCredentials form group
   */
  get dbCredentials(): UntypedFormGroup {
    return this.form.get('dbCredentials') as UntypedFormGroup;
  }

  /**
   * Returns the form control for setArchiveDelete toggle.
   *
   * @return form control for setArchiveDelete toggle
   */
  get setArchiveDelete(): UntypedFormControl {
    return this.form.get('setArchiveDelete') as UntypedFormControl;
  }

  /**
   * Returns the form control for archiveLogRetentionDays.
   *
   * @return form control for archiveLogRetentionDays
   */
  get archiveLogRetentionDays(): UntypedFormControl {
    return this.form.get('archiveLogRetentionDays') as UntypedFormControl;
  }

  /**
   * Returns the form control for archiveLogRetentionHours.
   *
   * @return form control for archiveLogRetentionHours
   */
  get archiveLogRetentionHours(): UntypedFormControl {
    return this.form.get('archiveLogRetentionHours') as UntypedFormControl;
  }

  /**
   * Returns the form control for continueDgPrimaryBackup.
   *
   * @return form control for continueDgPrimaryBackup
   */
  get continueDgPrimaryBackup(): UntypedFormControl {
    return this.form.get('continueDgPrimaryBackup') as UntypedFormControl;
  }

  /**
   * Returns the form control for globalSbtPath. This is only applicable when auto channel is selected.
   *
   * @return form control for globalSbtPath
   */
  get globalSbtLibraryPath(): UntypedFormControl {
    return this.form.get('globalSbtLibraryPath') as UntypedFormControl;
  }

  /**
   * Returns the current Oracle source data node.
   *
   * @return selected oracle source data node
   */
  get node(): OracleSourceDataNode {
    return this.nodeContext?.node;
  }

  /**
   * Returns the database type string.
   *
   * @return database type
   */
  get databaseTypeString(): string {
    if (this.isRestoreTask) {
      return this.oracleUtilityService.getDbEntityTypeString(this.physicalSourceType);
    }
    return this.oracleUtilityService.getDatabaseTypeString(this.node);
  }

  /**
   * Specifies if the MNMC dialog is opened during restore workflow or not.
   *
   * @return true if the MNMC dialog is opened during restore workflow.
   */
  get isRestoreTask(): boolean {
    return this.nodeSettingsFor === 'recovery';
  }

  /**
   * Returns the parent host type.
   *
   * @return parent host type
   */
  get parentHostType(): string {
    if (this.nodeSettingsFor === 'recovery') {
      return this.parentProtectionSource?.hostType;
    }
    return (this.node?.parentHost?.envSource as any)?.hostType;
  }

  /**
   * The number of rows that can be selected.
   *
   * @return number of rows that can be selected
   */
  get selectableRowCount(): number {
    return (this.table?.renderedData || []).filter(row => this.canSelectRow(row)).length;
  }

  /**
   * Returns true in case if multiple nodes are selected during sbt backup.
   *
   * @return true in case if multiple nodes are selected during sbt backup
   */
  get blockMnmcSelectionForSbtBackupSets(): boolean {
    // In case of Oracle SBT backupsets multiple nodes selection is not allowed.
    if (this.nodeContext?.node?.databaseType === OracleDatabaseType.kRACDatabase) {
      return this.backupType.value === OracleBackupType.kSbt &&
        this.selectedDatabaseHostList.selected?.length > 1;
    }
    return false;
  }

  /**
   * Specifies if the MNMC dialog is opened during backup workflow or not.
   *
   * @return true if the MNMC dialog is opened during backup workflow.
   */
  get isBackupTask(): boolean {
    return this.nodeSettingsFor === 'backup';
  }

  /**
   * Specifies the type of managed Object in a Physical Protection Source.
   *
   * 'kHost' indicates a single instance server.
   * 'kOracleRACCluster' indicates an Oracle Real Application Cluster(RAC).
   * 'kOracleAPCluster' indicates an Oracle Active-Passive Cluster.
   *
   * @return physical protection source type
   */
  get physicalSourceType(): string {
    if (this.isBackupTask) {
      return this.nodeContext.node.parentHost.type;
    }
    return this.parentProtectionSource?.type;
  }

  /**
   * Returns Database type based on selected target.
   *
   * @return database type based on selected target
   */
  get databaseType(): string {
    if (this.isBackupTask) {
      return OracleDatabaseType[this.nodeContext.node.databaseType];
    }
    return this.oracleUtilityService.getOracleDatabaseTypeBasedOnParentHost(this.physicalSourceType);
  }

  /**
   * Returns parent host protection source info.
   *
   * @return parent host protection source info
   */
  get parentProtectionSource(): PhysicalProtectionSource {
    if (this.isBackupTask || this.isOriginalRestore || this.isArchiveLogRestore) {
      return this.nodeContext.node?.parentHost?.data?.protectionSource?.physicalProtectionSource;
    }
    return (this.restoreTarget?.source as ProtectionSourceTreeInfo)?.rootNode?.physicalProtectionSource;
  }

  /**
   * Determines whether source is db authenticated or not.
   *
   * @return boolean to determine whether source is db authenticated or not.
   */
  get isDbAuthenticated(): boolean {
    if (this.isRestoreTask && this.restoreTarget) {
      return this.restoreTarget?.registrationInfo?.isDbAuthenticated;
    }
    return this.nodeContext.node.isDbAuthenticated;
  }

  /**
   * True in case of original location restore.
   *
   * @return boolean to determine if overwrite restore option is selected.
   */
  get isOriginalRestore(): boolean {
    return this.restoreLocation === OracleRecoverToOptions.originalLocation;
  }

  /**
   * Returns id based on selected task type.
   *
   * @return id
   */
  get id(): string | number {
    if (this.isRestoreTask && !this.isOriginalRestore &&
      this.parentProtectionSource?.id?.id) {
      return Number(this.parentProtectionSource.id.id);
    }
    return this.node?.id;
  }

  /**
   * True in case of new view restore.
   *
   * @return boolean to determine if new view option is selected.
   */
  get isViewExposeRestore(): boolean {
    return this.restoreLocation === OracleRecoverToOptions.newView;
  }

  /**
   * True in case of alternate restore.
   *
   * @return boolean to determine if alternate restore option is selected.
   */
  get isAlternateRestore(): boolean {
    return this.restoreLocation === OracleRecoverToOptions.newLocation;
  }

  /**
   * True in case of instant restore.
   *
   * @return boolean to determine if instant restore option is selected.
   */
  get isInstantRestore(): boolean {
    return this.restoreLocation === OracleRecoverToOptions.multiStageRestore;
  }

  /**
   * True if the node selection form has invalid options selected.
   *
   * @return boolean to determine if node selection form has invalid options selected.
   */
  get isDisabled(): boolean {
    if (this.selectedDatabaseHostList?.selected?.length < 1 &&
      this.channelOption.value === NodeSelectionType.Manual) {
      return true;
    }
    return this.form?.invalid;
  }

  /**
   * Returns source database type.
   *
   * @return source database type
   */
  get sourceDatabaseType(): string {
    return OracleDatabaseType[this.nodeContext?.node?.databaseType];
  }

  constructor(
    private ajaxHandlerService: AjaxHandlerService,
    private cdr: ChangeDetectorRef,
    private contextService: IrisContextService,
    private oracleRegistrationService: OracleRegistrationService,
    private oracleUtilityService: OracleUtilityService,
    private formBuilder: UntypedFormBuilder,
  ) {
    super();
  }

  /**
   * Prepares the list of OracleHost which can be selected for Oracle Multi node & Multi channel backup/restore.
   *
   * Algorithm:
   *
   * -- Fetch the Parent hosts supporting the MNMC('hostAddressSupportingMNMCSet').
   * -- Fetch the available parent node list('availableParentNodeList').
   * -- Fetch the host list for the current database('databaseHostList').
   * -- Iterate over the available parent node list to populate Oracle host info('availableDatabaseHostList').
   * -- Within Oracle host info, add boolean to mention MNMC support.
   * -- For 'kRACDatabase' & 'kSingleInstance'(part of RAC), only Hosts within ('databaseHostList') are to be included
   *    for populating 'availableDatabaseHostList'. To improve lookups and access to the hosts info,
   *    'databaseHostIndexMap' is used.
   *
   */
  private prepareDBOptions() {
    if (!this.databaseType) {
      return;
    }

    // Clear db existing db options if any.
    this.databaseHostFormArray.clear();

    // Fetch the set of parent host IPs supporting MNMC.
    this.hostAddressSupportingMNMCSet =
      this.oracleUtilityService.getParentHostAddressSupportingMnmc(this.parentProtectionSource);

    // Fetch the set of available parent Nodes.
    this.availableParentNodeList = this.isOriginalRestore || this.isBackupTask ?
      this.oracleUtilityService.getAvailableParentNodeList(this.nodeContext.node, this.isRestoreTask,
        this.parentProtectionSource) :
      this.oracleUtilityService.getAvailableParentNodeList(null, this.isRestoreTask, this.parentProtectionSource);

    if (this.isBackupTask || this.isOriginalRestore || this.isArchiveLogRestore) {
      // Fetch the Database Host list.
      this.databaseHostList = this.oracleUtilityService.getDatabaseHostList(this.nodeContext.node);

      // Fetch the database host index map.
      this.databaseHostIndexMap = this.oracleUtilityService.getDatabaseHostIndexMap(this.nodeContext.node);
    }

    switch (this.databaseType) {
      case OracleDatabaseType.kSingleInstance:
        this.canUpdateDatabaseCredentials = false;
        switch (this.physicalSourceType) {
          case PhysicalEntityType.kHost:
            this.populateOracleHostInfo(false);
            break;
          case PhysicalEntityType.kOracleAPCluster:
            this.populateOracleHostInfo(false);
            this.isActivePassive = true;
            break;
          case PhysicalEntityType.kOracleRACCluster:
            this.populateOracleHostInfo(!this.isRestoreTask);
            break;
        }
        break;

      case OracleDatabaseType.kRACDatabase:
        this.canUpdateDatabaseCredentials = true;
        this.populateOracleHostInfo(!this.isRestoreTask);

        // Display Port for RACDatabase during backup workflow.
        if (this.nodeSettingsFor !== NodeSettingsType.Restore &&
          !this.displayedColumns.includes('port')) {
          this.displayedColumns.push('port');
        }
        break;
    }
  }

  /**
   * Populates the list of available nodes for multi node/multi channel backup whose subset can then be selected by
   * the user.
   *
   * @param     shouldIncludeOnlyDatabaseHostNodes   Specifies if the nodes to be included should only be the
   *                                                 database hosts.
   */
  private populateOracleHostInfo(shouldIncludeOnlyDatabaseHostNodes: boolean) {
    const hostAddressToAgentIdMap = this.oracleUtilityService.getHostAddressToAgentIdMap(this.parentProtectionSource);
    this.availableParentNodeList.forEach(parentNode => {
      const hostAddress = parentNode.ipv4Addr || parentNode.ipv6Addr;

      // NOTE: Incase of Single Instance DB / Single Oracle RAC node, it is possible that the user
      // registers using shortname of the source due to IP whitelisting.
      // eg: 'singleInstance.eng.cohesity.com' maybe registered using
      // 'singleInstance'. In such a scenario 'hostAddressToAgentIdMap' will
      // contain only 1 <k,v> with the key being the shortname whose lookup
      // using the hostAddress(ipv4/ipv6) will, hence fail. So the 'v' in the
      // <k,v> in 'hostAddressToAgentIdMap' is directly assigned.
      // Incase of oracle source with multiple db nodes kServer entries
      // might not be part of oracle specific host info but still part of hostAddessTAgentIdMap,
      // hence adding the check to assign hostId from hostAddressToAgentIdMap.
      const hostId = (this.databaseType === OracleDatabaseType.kSingleInstance ||
        this.parentProtectionSource.agents?.length === 1) ? hostAddressToAgentIdMap[hostAddress] ||
        Object.values(hostAddressToAgentIdMap)[0] : hostAddressToAgentIdMap[hostAddress];
      const databaseHostIndex = this.databaseHostIndexMap.get(hostAddress) || 0;
      const hostDetails = this.databaseHostList[databaseHostIndex];

      if (shouldIncludeOnlyDatabaseHostNodes && !this.databaseHostIndexMap.has(hostAddress)) {
        return;
      }

      const recommendedChannels = this.isViewExposeRestore ? 1 :
        this.oracleUtilityService.calculateOracleChannnels(hostDetails?.cpuCount);
      const [ channelCount, port, recommendedChannelCount ] = [
        recommendedChannels,

        // TODO(tauseef): Check why port can be absent?
        hostDetails?.ports[0],
        recommendedChannels,
      ];

      const availableDatabaseHost = this.formBuilder.group({
        hostAddress: [hostAddress],
        hostId: [hostId],
        fqdn: [parentNode.fqdn],
        channelCount: [channelCount, [
          Validators.required,
          Validators.min(1),
          Validators.max(255),
        ]],
        port: [port, this.databaseType === OracleDatabaseType.kRACDatabase && !this.isRestoreTask ?
          [Validators.required, Validators.min(1), Validators.max(65535)] : []],

        // Specifies the default number of channels recommended for efficient backup/restore.
        recommendedChannelCount: [recommendedChannelCount]
      });

      if (this.isReadOnly && this.isRestoreTask && hostId && this.databaseHostIdToNodeInfoMap[hostId]) {
        availableDatabaseHost.controls.channelCount.setValue(this.databaseHostIdToNodeInfoMap[hostId]?.channelCount);
      }

      // For 6.5.1 release, SBT is only applicable for Windows host,
      // and Image Copy is only applicable for Linux/AIX
      if (this.parentHostType === HostType.kWindows || this.backupTypeSelectEnabled) {
        availableDatabaseHost.addControl('sbtLibraryPath',
          new UntypedFormControl({value: '', disabled: true}));
      }

      this.databaseHostFormArray.push(availableDatabaseHost);
    });
  }

  /**
   * Determines whether the archive log deletion is enabled.
   *
   * @returns   True, if the FF for the same is enabled.
   */
  isArchivalLogDeletionEnabled(): boolean {
    return flagEnabled(this.contextService.irisContext, 'oracleArchiveLogDeletion') &&
      this.isBackupTask;
  }

  /**
   * Determines whether the current node is supported or not.
   *
   * @param   index index value
   * @return  True, if it is standalone & the host address/fqdn is present in the hostAddressMNMC set.
   */
  isNodeSupported(index: number): boolean {
    return this.hostAddressSupportingMNMCSet.has(this.databaseHostFormArray?.at(index)?.get('hostAddress')?.value) ||
      this.hostAddressSupportingMNMCSet.has(this.databaseHostFormArray?.at(index)?.get('fqdn')?.value) ||
      (this.databaseType === OracleDatabaseType.kSingleInstance &&
      this.hostAddressSupportingMNMCSet.has(this.parentProtectionSource.name)) ||
      this.allowForceMnmcNodeSelection;
  }

  /**
   * Determines whether the current node is selected or not.
   *
   * @param   hostId host id
   * @return  True, if the host id is selected and present in the selected database hostlist.
   */
  isNodeSelected(hostId: string): boolean {
    let isSelected = false;

    if (!this.selectedDatabaseHostList.selected.length || !hostId) {
      return isSelected;
    }

    this.selectedDatabaseHostList.selected.forEach(control => {
      if (control.value?.hostId === hostId) {
        isSelected = true;
      }
    });

    return isSelected;
  }

  /**
   * Determines if the channels requested are more than the recommended number of channels.
   *
   * @param   index   Specifies the index of the node
   * @return  True, if the channel count present exceeds the recommended channel count in the hostAddressMNMC set.
   */
  hasChannelWarning(index: number): boolean {
    return this.databaseHostFormArray?.at(index)?.get('channelCount')?.value >
      this.databaseHostFormArray?.at(index)?.get('recommendedChannelCount')?.value;
  }

  /**
   * Determines if the database is in Oracle DataGuard configuration is available.
   *
   * @returns  True, if the node is an Oracle Database with DataGuard configuration.
   */
  isDatabaseDgInfoAvailable(): boolean {
    if (this.isBackupTask) {
      return Boolean(this.node?.envSource?.dataGuardInfo);
    }
  }

  /**
   * Handles update of the Multi-node/multi-channel settings.
   */
  updateForm() {
    const options = this.nodeSettingsFor === NodeSettingsType.Restore ? this.selectedOptions :
      this.nodeContext?.selection?.currentSelection?.options;
    const hostAddressToAgentId = this.oracleUtilityService.getHostAddressToAgentIdMap(this.parentProtectionSource);
    if (options && options[this.id]) {
      const currentNodeOptions = options[this.id];

      // Preset the MNMC settings.
      if (currentNodeOptions.nodeChannelList && currentNodeOptions.nodeChannelList.length) {
        // Node channel info. Length of this array will always be 1 for RAC and Standalone setups.
        const nodeChannelList = currentNodeOptions.nodeChannelList[0];
        const { databaseNodeList } = nodeChannelList;

        // If there is at least 1 db node.
        if (databaseNodeList && databaseNodeList.length) {
          this.channelOption.setValue(NodeSelectionType.Manual);

          // Pre-select database nodes.
          for (const node of databaseNodeList) {
            let { hostId } = node;

            // From 6.5.1, magneto expects host id instead of host name in databaseNodeList. So, here we,
            // convert the ip to id, so our component works as expected with backward compatibility.
            if (isNaN(node.hostId)) {
              hostId = hostAddressToAgentId[node.hostId];
            }

            const control = this.databaseHostFormArray.controls.
              find(formGroup => formGroup.get('hostId').value === hostId) as UntypedFormGroup;

            if (control) {
              const { channelCount, port, sbtHostParams = {} } = node;
              control.patchValue({ channelCount, port });

              if (sbtHostParams.sbtLibraryPath) {
                control.get('sbtLibraryPath').setValue(sbtHostParams.sbtLibraryPath);
              }
              if (this.canSelectRow(control as UntypedFormGroup) && !this.isNodeSelected(control.value?.hostId)) {
                this.selectedDatabaseHostList.select(control);
              }
            }
          }
        }

        // Archive Log Retention
        const archiveLogRetentionDays = nodeChannelList.archiveLogRetentionDays;
        const archiveLogRetentionHours = nodeChannelList.archiveLogRetentionHours;

        if (archiveLogRetentionDays >= 0) {
          this.setArchiveDelete.setValue(archiveLogRetentionDays >= 0);
          this.archiveLogRetentionDays.setValue(archiveLogRetentionDays);
          this.archiveLogRetentionType.setValue(ArchiveLogRetentionUnit.Days, { emitEvent: false });
        } else if (archiveLogRetentionHours >= 0) {
          this.setArchiveDelete.setValue(archiveLogRetentionHours >= 0);
          this.archiveLogRetentionHours.setValue(archiveLogRetentionHours);
          this.archiveLogRetentionType.setValue(ArchiveLogRetentionUnit.Hours, { emitEvent: false });
        }

        // Whether the database has the Primary role within Data Guard configuration.
        this.continueDgPrimaryBackup.setValue(nodeChannelList.enableDgPrimaryBackup);

        // Backup type select if applicable
        if (this.backupTypeSelectEnabled && nodeChannelList.rmanBackupType) {
          this.backupType.setValue(nodeChannelList.rmanBackupType);
        }
      }
    }
    this.form.updateValueAndValidity();
    this.cdr.detectChanges();
  }

  ngOnInit() {
    this.initForm();
  }

  ngAfterViewInit() {

    // Set default channel option as manual in case auto selection is not allowed.
    if (!this.showAutochannelSelection && this.table.renderedData?.length) {
      this.channelOption.setValue(NodeSelectionType.Manual);
      this.form.updateValueAndValidity();
    }
  }

  /**
   * Method called to intialize form group.
   */
  initForm() {
    // Whether to enable backup type selection.
    // To be enabled when backend supports switching different backup type.
    this.backupTypeSelectEnabled = flagEnabled(this.contextService.irisContext, 'oracleProtectSelectBackupType');

    // Set default backup type based on parent host.
    const defaultBackupType =
      (this.parentHostType === HostType.kWindows || isDmsScope(this.contextService.irisContext)) ?
      OracleBackupType.kSbt : OracleBackupType.kImageCopy;
    this.form.addControl('backupType', new UntypedFormControl(defaultBackupType, Validators.required));
    this.form.addControl('archiveLogRetentionType', new UntypedFormControl(this.archiveLogRetentionUnit.Days));

    // Adding form control to provide option for not deleting archive logs.
    this.form.addControl('unsetArchiveLogDelete', new UntypedFormControl(false));

    // SBT is only supported for Windows host.
    if (this.parentHostType === HostType.kWindows &&
      !this.displayedColumns.includes('sbtLibraryPath')) {
      this.form.addControl('globalSbtLibraryPath', new UntypedFormControl(''));
      this.displayedColumns.push('sbtLibraryPath');
    }

    this.prepareDBOptions();

    // Listens to form control changes.
    this.onFormChanges();

    // Listens to database hosts selection changes and toggles db
    // credentials validation based on the selection.
    this.selectedDatabaseHostList.changed.pipe(this.untilDestroy()).subscribe(() => {
      if (this.channelOption.value === NodeSelectionType.Manual &&
        this.databaseType === OracleDatabaseType.kRACDatabase &&
        this.selectableRowCount > 1 && !this.isDbAuthenticated && !this.isDisasterRecovery) {
        const dbCredentialsRequired = this.selectedDatabaseHostList.selected?.length !== 1;
        this.areDbCredentialsRequired = dbCredentialsRequired;
        this.setDbCredentials.setValue(dbCredentialsRequired);
      }

      // In case of oracle server with sbt backupsets avoid multiple node selection
      // during oracle backup workflow.
      if (this.blockMnmcSelectionForSbtBackupSets) {
        this.form.addControl('removeMnmcSelection', new UntypedFormControl(null, Validators.required));
      } else {
        this.form.removeControl('removeMnmcSelection');
      }

      if (this.channelOption.value === NodeSelectionType.Manual &&
        this.selectedDatabaseHostList.selected?.length === 0) {
        this.databaseHostFormArray.setErrors({invalid: true});
      } else {
        this.databaseHostFormArray.setErrors(null);
      }
    });
    this.cdr.detectChanges();
  }

  /**
   * Listens to form changes to dynamically enable/disable corresponding controls
   */
  private onFormChanges() {
    // Handles toggle of Multi Node & Multi Channel settings mode.
    this.channelOption.valueChanges.pipe(this.untilDestroy()).subscribe((mnmcMode: string) => {
      this.shouldAutoSelectMnmcSettings = mnmcMode !== NodeSelectionType.Manual;

      // Clear selection
      if (mnmcMode === NodeSelectionType.Auto) {
        this.selectedDatabaseHostList.clear();
        this.databaseHostFormArray.disable({ onlySelf: true, emitEvent: false });
        this.setDbCredentials.setValue(false);
        this.areDbCredentialsRequired = false;
        if (this.globalSbtLibraryPath) {
          this.globalSbtLibraryPath.enable({ onlySelf: true, emitEvent: false });
        }

      // Manual node selection
      } else {
        this.databaseHostFormArray.enable({ onlySelf: true, emitEvent: false });

        if (this.globalSbtLibraryPath) {
          this.globalSbtLibraryPath.disable({ onlySelf: true, emitEvent: false });
        }

        // Update each node sbtLibraryPath with the value of globalSbtPath if set.
        if (this.parentHostType === HostType.kWindows && this.globalSbtLibraryPath.value) {
          this.databaseHostFormArray.controls.forEach(control =>
            control.get('sbtLibraryPath').setValue(this.globalSbtLibraryPath.value));
        }

        // Select all nodes for ActivePassive or if there is only 1 selectable node or
        // during disaster recovery of RAC database.
        if (this.isActivePassive || this.selectableRowCount === 1 ||
          ((this.sourceDatabaseType === OracleDatabaseType.kRACDatabase ||
          this.databaseType === OracleDatabaseType.kRACDatabase) && this.isDisasterRecovery)) {
          this.selectAllEligibleNodes();
        }

        // Select database host by default in case if selectDbHostByDefault is set to true.
        if ((this.selectDbHostByDefault || this.isAlternateRestore || this.isInstantRestore || this.isViewExposeRestore)
          && this.selectableRowCount > 1 && !this.isActivePassive && !this.isReadOnly) {
          this.selectEligibleNodeByDefault();
        }

        // Select all nodes choosen during oracle restore.
        if (this.isRestoreTask && this.isReadOnly) {
          this.databaseHostFormArray.controls.forEach((control: UntypedFormGroup) => {
            if (this.canSelectRow(control as UntypedFormGroup) &&
              this.databaseHostIdToNodeInfoMap[control.value.hostId] &&
              !this.isNodeSelected(control.value.hostId)) {
              this.selectedDatabaseHostList.select(control);
            }
          });
        }

        // For RAC database and if db is not already authenticated, then
        // set db credentials is mandatory.
        if (this.databaseType === OracleDatabaseType.kRACDatabase &&
          this.selectableRowCount > 1 && !this.isDbAuthenticated &&
          this.selectedDatabaseHostList.selected?.length > 1 &&
          !this.isAlternateRestore && !this.isViewExposeRestore &&
          !this.isInstantRestore && !this.isDisasterRecovery) {
          this.areDbCredentialsRequired = true;
          this.setDbCredentials.setValue(true);
        }
      }
      this.form.updateValueAndValidity();
    });

    // Handles toggle of set db credentials toggle.
    this.setDbCredentials.valueChanges.pipe(this.untilDestroy()).subscribe((enabled: boolean) => {
      if (!enabled) {
        this.dbCredentials.reset();
      }
      this.dbCredentials[enabled ? 'enable' : 'disable']({ onlySelf: true, emitEvent: false });
      this.form.updateValueAndValidity();
    });

    // Handles toggle of setArchiveDelete toggle.
    this.setArchiveDelete.valueChanges.pipe(this.untilDestroy()).subscribe((enabled: boolean) => {
      this.archiveLogRetentionDays[enabled ? 'enable' : 'disable']({ onlySelf: true, emitEvent: false });
      this.archiveLogRetentionHours[enabled ? 'enable' : 'disable']({ onlySelf: true, emitEvent: false });
      this.form.updateValueAndValidity();
    });

    // Handles backup type selection.
    if (this.backupTypeSelectEnabled) {
      this.backupType.valueChanges.pipe(this.untilDestroy()).subscribe((backupType: string) => {
        if (backupType === OracleBackupType.kSbt && !this.displayedColumns.includes('sbtLibraryPath')) {
          this.displayedColumns.push('sbtLibraryPath');
        } else {
          const sbtPathIndex = this.displayedColumns.findIndex(column => column === 'sbtLibraryPath');
          if (sbtPathIndex > -1) {
            this.displayedColumns.splice(sbtPathIndex, 1);
          }
        }

        // In case of oracle server with sbt backupsets avoid multiple node selection
        // during oracle backup workflow.
        if (this.blockMnmcSelectionForSbtBackupSets) {
          this.form.addControl('removeMnmcSelection', new UntypedFormControl(null, Validators.required));
        } else {
          this.form.removeControl('removeMnmcSelection');
        }
      });
    }

    // Handles archive log retention granularity change.
    this.archiveLogRetentionType.valueChanges.pipe(
      this.untilDestroy()
    ).subscribe((type: string) => {
      if (type === this.archiveLogRetentionUnit.Days && this.archiveLogRetentionHours.value >= 0) {
        this.archiveLogRetentionDays.setValue(this.archiveLogRetentionHours.value);
      } else if (type === this.archiveLogRetentionUnit.Hours && this.archiveLogRetentionDays.value >= 0) {
        this.archiveLogRetentionHours.setValue(this.archiveLogRetentionDays.value);
      }
      this.form.updateValueAndValidity();
    });
  }

  /**
   * Specifies whether global sbt path is applicable.
   */
  hasGlobalSbtLibraryPath(): boolean {
    return this.parentHostType === HostType.kWindows && this.channelOption.value === NodeSelectionType.Auto;
  }

  /**
   * Determines if a given row can be selected or not.
   *
   * @param   row   The row to check.
   * @return  True if the row can be selected. It can be selected if the object is not
   *          missing and the attribute is not equal.
   */
  canSelectRow: CanSelectRowFn<UntypedFormGroup> = (row: UntypedFormGroup) =>
    // Incase of SingleInstance, selection should always be allowed.
    // TODO(tauseef): This logic currently should come through
    // 'hostAddressSupportingMNMCSet' even for short name which currently
    // cannot be confirmed by iris, and the validation for MNMC support for the
    // single instance is relied on Magneto.
    (this.databaseType === OracleDatabaseType.kSingleInstance ||
      (this.hostAddressSupportingMNMCSet.has(row.value.hostAddress) ||
        this.hostAddressSupportingMNMCSet.has(row.value.fqdn)) && !this.isActivePassive) ||
        this.allowForceMnmcNodeSelection;


  /**
   * Determines whether any rows can be selected.
   * This method is defined as a property so that the this value will be bound
   * properly when passed as an input to the table component.
   *
   * @return   True if at least once
   */
  canSelectAnyRows: CanSelectAnyRowsFn = () => (this.selectableRowCount > 0 && !this.isActivePassive) ||
    this.allowForceMnmcNodeSelection;

  /**
   * Determines if a given row can be selected or not.
   *
   * @param   row   The row to check.
   * @return  True if the row can be selected. It can be selected if the object is not
   *          missing and the attribute is not equal.
   */
  canSelectRowFn: CanSelectRowFn<UntypedFormGroup> = (row: UntypedFormGroup) =>
    this.isReadOnly || this.isDisasterRecovery ? false : this.canSelectRow(row);

  /**
   * Determines whether any rows can be selected.
   * This method is defined as a property so that the this value will be bound
   * properly when passed as an input to the table component.
   *
   * @return   True if at least once
   */
  canSelectAnyRowsFn: CanSelectAnyRowsFn = () => this.isReadOnly || this.isDisasterRecovery ? false
    : this.canSelectAnyRows();

  /**
   * Handles the select all button. Only selectable rows should be toggled on or off.
   * This method is defined as a property so that the this value will be bound
   * properly when passed as an input to the table component.
   */
  masterToggle: ToggleSelectAllFn = () => {
    this.isAllSelected() ?
      this.table.selection.clear() :
      this.table.renderedData.forEach(row => this.canSelectRow(row) && this.table.selection.select(row));
  };

  /**
   * Determines if all rows are selected. This ignores rows which are not selectable.
   * This method is defined as a property so that the this value will be bound
   * properly when passed as an input to the table component.
   *
   * @return   whether all possible rows have been selected.
   */
  isAllSelected: IsAllSelectedFn = () => this.selectableRowCount > 0 &&
    this.selectableRowCount === this.selectedDatabaseHostList.selected.length;

  /**
   * Returns corresponding error message based on the formControl errors object.
   *
   * @param formControl The formControl to be checked for error.
   */
  mapErrorMessage(formControl: AbstractControl): string {
    let errorMsg = '';
    const { errors } = formControl;

    if (!errors) {
      return;
    }

    // Get error code from  the formControl errors object.
    // There should be only one error code at a time.
    const error = Object.keys(errors)[0] || null;
    switch (error) {
      case 'required':
        errorMsg = 'errors.required';
        break;
      case 'min':
        errorMsg = 'errors.minValue';
        break;
      case 'max':
        errorMsg = 'errors.maxValue';
        break;
    }
    return errorMsg;
  }

  /**
   * Selects all eligible nodes.
   * For ActivePassive, all nodes supporting MNMC should be selected by default.
   */
  private selectAllEligibleNodes() {
    this.databaseHostFormArray.controls.forEach((control: UntypedFormGroup) => {

      // Select node if it's standalone & the host address/fqdn is present in the hostAddressMNMC set.
      if ((this.hostAddressSupportingMNMCSet.has(control.value.hostAddress) &&
        !this.isNodeSelected(control.value.hostId)) ||
        (this.hostAddressSupportingMNMCSet.has(control.value.fqdn) &&
        !this.isNodeSelected(control.value.fqdn)) ||
        this.databaseType === OracleDatabaseType.kSingleInstance) {
        this.selectedDatabaseHostList.select(control);

        // Select and update default channel configuration in case database host is selected by default.
        if (this.selectDbHostByDefault ||
          (this.isDisasterRecovery && this.databaseType === OracleDatabaseType.kRACDatabase && this.restoreToRac)) {
          this.onSaved();
        }
      }
    });
  }

  /**
   * Select eligible db node by default.
   */
  private selectEligibleNodeByDefault() {
    for (let i = 0; i < this.databaseHostFormArray.controls.length; i++) {
      const databaseHost = this.databaseHostFormArray.controls[i] as UntypedFormGroup;

      // Select node if it's standalone & the host address/fqdn is present in the hostAddressMNMC set.
      if ((this.hostAddressSupportingMNMCSet.has(databaseHost.value.hostAddress) &&
        !this.isNodeSelected(databaseHost.value.hostAddress)) ||
        (this.hostAddressSupportingMNMCSet.has(databaseHost.value.fqdn) &&
        !this.isNodeSelected(databaseHost.value.fqdn)) ||
        this.databaseType === OracleDatabaseType.kSingleInstance) {
        this.selectedDatabaseHostList.select(databaseHost);
        this.onSaved();
        return;
      }
    }
  }

  /**
   * Handles the save of the Multi-node/multi channel settings.
   */
  onSaved() {
    const { value } = this.form;

    // Special case for Windows parent host with auto selection,
    // We want to send all nodes with global sbt path. Otherwise, sending the selected nodes value.
    const dbNodeList = this.hasGlobalSbtLibraryPath() ?
      this.databaseHostFormArray.value.map(node => ({
        ...node,
        sbtLibraryPath: value.globalSbtLibraryPath,
      })) : this.selectedDatabaseHostList.selected.map(formGroup => formGroup.value);

    const databaseNodeList = dbNodeList.map(dbNode => {
      const { hostId, fqdn, channelCount, port, sbtLibraryPath, hostAddress } = dbNode;
      return {
        hostAddress,
        hostId,
        fqdn,
        channelCount,
        port,
        ...(value.backupType === OracleBackupType.kSbt ? {
          sbtHostParams: { sbtLibraryPath }
        } : undefined),
      };
    }) as DecoratedOracleDatabaseHost[];

    const archivelogRetentionType = this.archiveLogRetentionType.value;
    let archiveLogRetentionDays = value.setArchiveDelete && archivelogRetentionType === ArchiveLogRetentionUnit.Days ?
      value.archiveLogRetentionDays : undefined;
    let archiveLogRetentionHours = value.setArchiveDelete && archivelogRetentionType === ArchiveLogRetentionUnit.Hours ?
      value.archiveLogRetentionHours : undefined;

    // Check whether valid oracle backup type is selected, if not provide default
    // value based on host type.
    if (!!value.backupType && !Object.values(OracleBackupType).includes(value.backupType)) {
      value.backupType = (this.parentHostType === HostType.kWindows || isDmsScope(this.contextService.irisContext)) ?
        OracleBackupType.kSbt : OracleBackupType.kImageCopy;
    }

    // Trim spaces within db credentials username input.
    if (value.setDbCredentials && value.dbCredentials?.username) {
      value.dbCredentials.username = value.dbCredentials.username?.trim();
    }

    // Setting archive log retention days value to -1 in case not to delete archive log option is selected.
    if (value.unsetArchiveLogDelete) {
      archiveLogRetentionDays = -1;
      archiveLogRetentionHours = -1;
    }

    // Specifies whether to update db credentials per host or not.
    const dbCredentialsPerHostEnabled = !!flagEnabled(this.contextService.irisContext, 'oracleDbCredentialsPerHostEnabled');
    const options: OracleAppParams | any = {
      databaseAppId: Number(this.id),
      nodeChannelList: [{
        databaseUuid: this.node?.databaseUuid,
        databaseUniqueName: this.node?.databaseUniqueName,
        archiveLogRetentionDays,
        archiveLogRetentionHours,
        enableDgPrimaryBackup: value.continueDgPrimaryBackup,
        rmanBackupType: value.backupType,
        databaseNodeList,
        credentials: dbCredentialsPerHostEnabled ? value.dbCredentials : null,
      }],
    };

    // If db credentials are set, make private API calls to update app owner
    // based on the current logic in legacy protection job.
    // Probably needs a better UX/design for this as we should not have to
    // update source registration on a protection group page.
    const entityId = this.isBackupTask || this.isOriginalRestore || this.isArchiveLogRestore ?
      this.node?.parentHost?.id : Number(this.parentProtectionSource?.id);

    if (value.setDbCredentials && !dbCredentialsPerHostEnabled && entityId) {
      this.oracleRegistrationService.updateDbCredentials(
        entityId,
        value.dbCredentials,
      ).pipe(this.untilDestroy()).subscribe(
        () => this.updateChannelOptions(options),
        error => this.ajaxHandlerService.handler(error));
    } else {
      this.updateChannelOptions(options);
    }
  }

  /**
   * Updates node channel settings on saved.
   *
   * @param  option  OracleAppParams.
   */
  private updateChannelOptions(option: OracleAppParams) {
    if (this.nodeSettingsFor === NodeSettingsType.Restore) {
      return this.updateChannelSettings.emit(option);
    } else {
      this.nodeContext.selection.setOptionsForNode(this.nodeContext.node.id, option);
      this.selectedDatabaseHostList.clear();
      this.cdr.detectChanges();
    }
  }

  /**
   * Return node name to be displayed within MNMC selection table
   *
   * @param index  form array index value
   * @returns Return node name to be displayed
   */
  getNodeName(index: number): string {

    // If fqdn doesn't exists and it's a standalone host return the source name
    if (this.databaseType === OracleDatabaseType.kSingleInstance &&
      !this.databaseHostFormArray?.at(index)?.get('fqdn').value) {
      return this.parentProtectionSource.name;
    }
    return this.databaseHostFormArray?.at(index)?.get('fqdn').value;
  }
}
