import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { DataTreeNodeContext, DataTreeNodeDetail } from '@cohesity/helix';
import { Subject } from 'rxjs';
import { DialogService } from 'src/app/core/services';

import { ProtectionSourceDataNode } from '../protection-source-data-node';

/**
 * This component assists with showing a form containing special parameters for protection sources.
 * It has a label to indicate any custom settings, and a button to launch a dialog.
 *
 * @example
 * <coh-source-special-parameters
 *  [form]="form" [nodeContext]="nodeContext" [label]="systemBackupEnabled ? ('bmr' | translate) : ''"
 *  (dialogOpened)="updateForm()" (saved)="onSaved()">
 *   <ng-container *ngIf="form" [formGroup]="form">
 *     <mat-slide-toggle formControlName="enableSystemBackup">
 *       {{'takeBMRBackup' | translate}}
 *     </mat-slide-toggle>
 *   </ng-container>
 * </coh-source-special-parameters>
 */
@Component({
  selector: 'coh-source-special-parameters',
  templateUrl: './source-special-parameters.component.html',
  styleUrls: ['./source-special-parameters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SourceSpecialParametersComponent implements DataTreeNodeDetail<ProtectionSourceDataNode> {
  /**
   * The node context, including info about the node and it's selection status.
   */
  @Input() nodeContext: DataTreeNodeContext<ProtectionSourceDataNode>;

  /**
   * A reference to the dialog template.
   */
  @ViewChild('dialog', { static: true }) optionsDialog: TemplateRef<any>;

  /**
   * A reference to the current form. This is used to enable or disable the save button based on the form's
   * validity.
   */
  @Input() form: UntypedFormGroup;

  /**
   * A custom label to show - this can be used to indicate non-default options for a source.
   */
  @Input() label: string;

  /**
   * Custom dailog header - this can be used to display custom header instead of default one.
   */
  @Input() dialogHeaderTemplate: MatDialogRef<any>;

  /**
   * This event is emitted whenever the dialog is opened. Components can use this to reset the form to match
   * the currently configured options.
   */
  @Output() dialogOpened = new Subject<void>();

  /**
   * This event is emitted whenever the user saves the dialog and closes the form. This event is used to trigger
   * an update to the source selection options.
   */
  @Output() saved = new Subject<void>();

  /**
   * This event is emitted whenever the user cancels the dialog and closes the form. This event is used to trigger
   * reset to the source selection options.
   */
  @Output() cancelled = new EventEmitter<void>();

  /**
   * Dialog ref of the opened dialog.
   */
  dialogRef: MatDialogRef<any>;

  constructor(private dialog: DialogService) {}

  /**
   * Show the options dialog.
   */
  showOptions() {
    this.dialogOpened.next();
    this.dialogRef = this.dialog.getDialogRef(this.optionsDialog);

    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.saved.next();
      }
    });
  }

  /**
   * Function to close the dialog opened using this component.
   *
   * @param value Value for dialog close.
   */
  closeDialog(value) {
    this.dialogRef.close(value);
  }

  /**
   * Method called on cancelling the dialog opened using this component.
   */
  cancel() {
    this.closeDialog(false);
    this.cancelled.emit();
  }
}
