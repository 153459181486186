import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MomentDatePipe, ThermometerChartData, WindowRef, joinUrl } from '@cohesity/helix';
import { AutoDestroyable, DialogService, DontShowAgainEnum, DontShowAgainService } from '@cohesity/utils';
import { IrisContextService, appConfigMap, isDGaasScope } from '@cohesity/iris-core';
import { NavigationWarningDialogComponent, NavigationWarningDialogData } from '@cohesity/shared-dialogs';
import { TranslateService } from '@ngx-translate/core';
import { SeriesPieOptions } from 'highcharts';
import { ObservableInput } from 'ngx-observable-input';
import { BehaviorSubject, of } from 'rxjs';
import { filter } from 'rxjs/operators';

import { getRiskProperties } from '../../../utils';

/**
 * Interface for component data
 */
export interface PostureAdvisorScore {
  riskScore: number;
  lastScanTime: number;
}

/**
 * @description
 * Posture Advisor Score shows a rating for the Risk Status of the system.
 *
 * @example
 *   <dg-sc-posture-advisor-score [riskScore]="riskScore"></dg-sc-posture-advisor-score>
 */
@Component({
  selector: 'dg-sc-posture-advisor-score',
  templateUrl: './posture-advisor-score.component.html',
  styleUrls: ['./posture-advisor-score.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PostureAdvisorScoreComponent extends AutoDestroyable implements OnInit {
  /**
   * The risk score to be displayed in chart.
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @ObservableInput() @Input('data') data$: BehaviorSubject<PostureAdvisorScore>;

  /**
   * Loading state of the card.
   */
  @Input() loading = false;

  /**
   * Data for the thermometer chart.
   */
  thermometerData: ThermometerChartData[] = [];

  /**
   * Data for pie chart.
   */
  chartData: SeriesPieOptions = null;

  /**
   * High/Medium/Low Risk.
   */
  riskString = '';

  /**
   * The riskString and riskClass based on riskScore
   */
  getRiskProperties = getRiskProperties.bind(null, this.translate);

  /**
   * The last scan time from data.
   */
  lastScanTime = '';

  /**
   * The security posture url for linking
   */
  readonly postureAdvisorURL = `/${joinUrl([appConfigMap.securityCenter.urlSegment, 'security', 'posture-advisor'])}`;

  constructor(readonly translate: TranslateService,
    readonly dialogService: DialogService,
    readonly dontShowAgainService: DontShowAgainService,
    readonly irisContextService: IrisContextService,
    readonly windowRef: WindowRef,
    readonly momentDatePipe: MomentDatePipe) {
    super();
  }

  ngOnInit() {
    this.data$.pipe(
      filter(data => !!data.riskScore),
      this.untilDestroy()
    ).subscribe(data => {
      this.chartData = this.createChartData(data.riskScore);
      this.lastScanTime = this.translate.instant('dg.sc.dashboard.latestScanColon', {
        time: this.momentDatePipe.transform(data.lastScanTime),
      });
    });

    const highRiskProperties = this.getRiskProperties(70);
    const mediumRiskProperties = this.getRiskProperties(90);
    const lowRiskProperties = this.getRiskProperties(100);

    this.thermometerData = [
      {
        mainLabel: lowRiskProperties.str,
        subLabel: '90',
        percent: 10,
        class: lowRiskProperties.class,
      },
      {
        mainLabel: mediumRiskProperties.str,
        subLabel: '70',
        percent: 20,
        class: mediumRiskProperties.class,
      },
      {
        mainLabel: highRiskProperties.str,
        subLabel: '0',
        percent: 70,
        class: highRiskProperties.class,
      },
    ];
  }

  /**
   * Create Chart based on passed data to the component
   *
   * @param  riskScore The risk score of the system
   * @returns Array of values to be displayed in the chart in the order -> ready, notReady and error
   */
  createChartData(riskScore: number): SeriesPieOptions {
    this.riskString = this.getRiskProperties(riskScore).str;

    return {
      type: 'pie',
      data: [
        {
          name: this.translate.instant('risk'),
          y: riskScore,
        },
        {
          y: 100 - riskScore,
        },
      ],
      events: {
        click: $event => {
          $event.preventDefault();
          $event.stopPropagation();
          this.handleChartClick();
        },
      }
    };
  }

  /**
   * This is the click handler for the donut chart.
   */
  handleChartClick() {

    // Setup data for navigation dialog
    const source = this.translate.instant('app.global');
    const destination = this.translate.instant('app.securityCenter');
    const reason = this.translate.instant('dg.postureAdviser');

    const data: NavigationWarningDialogData = {
      title: this.translate.instant('navigation.interrupt.title', { destination }),
      dontShowAgainKey: DontShowAgainEnum.navigationSwitchScope,
      navigationWarningMessage: this.translate.instant('navigation.interrupt.warning.scopeSwitch', { source, destination, reason })
    };

    // Only display the interrupt dialog when coming from global scope and user has not selected "don't show again"
    const interrupt = isDGaasScope(this.irisContextService.irisContext) ||
      this.dontShowAgainService.isDontShowAgain(DontShowAgainEnum.navigationSwitchScope) ?
      of(true) : this.dialogService.open(NavigationWarningDialogComponent, { data });

    interrupt.subscribe((result) => {
      if (result) {
        this.windowRef.nativeDocument.location.href = this.postureAdvisorURL;
      }
    });
  }
}
