import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatLegacyMenu as MatMenu } from '@angular/material/legacy-menu';

import { NavItem } from '../nav-item/nav-item.model';

/**
 * A common actions menu component. This is designed to render a list of NavItem objects
 */
@Component({
  selector: 'cog-actions-menu',
  templateUrl: './actions-menu.component.html',
  styleUrls: ['./actions-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ActionsMenuComponent {
  /**
   * List of menu items.
   */
  @Input() navList: NavItem[];

  /**
   * Template to override the nav item display. It should wrap a nav item
   * component and use directives for router links and state. See the default
   * item template for an example
   */
  @Input() itemTemplate: TemplateRef<any>;

  /**
   * Show larger menu item if size = 'lg'.
   */
  @Input() size: 'lg' | 'md';

  /**
   * Let parent handle accessibility when enter key is pressed.
   */
  @Output() enterKeyPressed = new EventEmitter<NavItem>();

  /**
   * The action menu item. This allows the component to recursive render child menus and link
   * them back to the menu triggers
   */
  @ViewChild('childMenu', { static: true}) public childMenu: MatMenu;

  /**
   * The default menu item template.
   */
  @ViewChild('defaultItemTemplate', {static: false})
  private defaultItemTemplate: TemplateRef<any>;

  /**
   * Returns the item template to use for navigation, either a custom template
   * or the default template
   */
  get menuItemTemplate(): TemplateRef<any> {
    return this.itemTemplate || this.defaultItemTemplate;
  }

  /**
   * This method allows us to intercept the enter key and check to see
   * if there is a href that needs to be followed.  If so, it will programatically
   * click the href.  Otherwise, it will emit an event.
   *
   * @param $event
   * @param item
   */
  handleEnterKey(event, item) {
    if (item.href) {
      const linkElement = event.target.querySelector('a');
      if (linkElement) {
        linkElement.click();
      }
    }
    this.enterKeyPressed.emit(item);
  }

}
