// Module: Impersonation Modal Component

;(function(angular, undefined) {
  'use strict';

  /**
   * @ngdoc module
   * @name C.nav:impersonationModal
   * @function
   *
   * @description
   * Opens a modal to impersonate a tenant.
   */
  angular.module('C.nav')
    .controller('impersonationModalCtrl', impersonationModalCtrl);

  function impersonationModalCtrl(_, TenantService, $uibModalInstance,
    NgTenantService, UserService) {
    var $ctrl = this;

    _.assign($ctrl, {
      okDisabled: true,

      // UI states.
      impersonatedTenant: undefined,
      impersonationMode: true,
      selectedTenant: undefined,

      // Component methods.
      ok: onSubmit,
      onAddNew: onAddNew,
      getOrgMemberships: null,
      onTenantFetch: onTenantFetch,

      // Component life cycle methods.
      $onInit: $onInit,
    });

    /**
     * initialize the component & fetch the data.
     *
     * @method   $onInit
     */
    function $onInit() {
      $ctrl.impersonationMode = !TenantService.isTenantAccountSwitchable();

      if ($ctrl.impersonationMode) {
        $ctrl.impersonatedTenant = NgTenantService.impersonatedTenant;
      } else {
        $ctrl.impersonatedTenant = TenantService.getSelectedAccount();
        $ctrl.getOrgMemberships = UserService.getOrgMemberships;
      }

      $ctrl.selectedTenant = _.cloneDeep($ctrl.impersonatedTenant);
    }

    /**
     * Overrides the ok button function in the modal.
     *
     * @method   onSubmit
     */
    function onSubmit() {
      if (!$ctrl.impersonationMode) {
        TenantService.setSwitchedAccount($ctrl.selectedTenant);
      } else {
        NgTenantService.impersonatedTenant = $ctrl.selectedTenant;
      }

      $uibModalInstance.close($ctrl.selectedTenant);
    }

    /**
     * Called when tenants are fetched by the select-tenant component
     *
     * @method   $onInit
     * @param    tenants List of fetched tenants
     */
    function onTenantFetch(tenants) {
      $ctrl.okDisabled = !tenants.length;
    }

    /**
     * Called when tenants are fetched by the select-tenant component
     *
     * @method  onAddNew
     */
    function onAddNew() {
      $uibModalInstance.dismiss();
    }
  }
})(angular);
