import { Component, Input } from '@angular/core';
import { ProtectionGroupServiceApi } from '@cohesity/api/v2';
import { FiltersComponent, ValueFilterSelection } from '@cohesity/helix';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { PassthroughOptionsService } from 'src/app/core/services';

import { BaseObjectFilterComponent } from './../base-object-filter';

/**
 * This is a reusable filter that can be used to filter for protection groups
 */
@Component({
  selector: 'coh-protection-group-filter',
  templateUrl: './protection-group-filter.component.html',
})
export class ProtectionGroupFilterComponent extends BaseObjectFilterComponent<
  ProtectionGroupServiceApi.GetProtectionGroupsParams
> {
  constructor(
    private protectionGroupService: ProtectionGroupServiceApi,
    private passthroughOptionsService: PassthroughOptionsService,
    filters: FiltersComponent
  ) {
    super(filters);
  }

  /**
   * The name of the property containing the protection group id.
   */
  @Input() property = 'protectionGroup';

  /**
   * Fetch protection groups and map to filter values.
   */
  getFilterValues(): Observable<ValueFilterSelection[]> {
    const params = {
      ...this.requestParams,
      ...this.passthroughOptionsService.requestParams,
    };
    return this.protectionGroupService.GetProtectionGroups(params).pipe(
      filter(groups => !!groups.protectionGroups),
      map(groups =>
        groups.protectionGroups.map(group => ({
          label: group.name,
          value: group.id,
        }))
      )
    );
  }
}
