<button type="button"
  *ngIf="showRemoveButton()"
  cogDataId="remove-button-{{index}}"
  [inheritDataId]="true"
  mat-icon-button matSuffix
  [matTooltip]="'remove' | translate"
  [disabled]="removeActionDisabled"
  (click)="controlRemoved.emit()">
  <cog-icon shape="remove_circle_outline"></cog-icon>
</button>

<button type="button"
  *ngIf="isLast"
  class="add-new-button"
  cogDataId="add-button"
  [inheritDataId]="true"
  mat-icon-button matSuffix
  [matTooltip]="'addNew' | translate"
  [disabled]="addActionDisabled"
  (click)="controlAdded.emit()">
  <cog-icon shape="add_circle_outline"></cog-icon>
</button>
