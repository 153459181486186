import { Environment, HyperVEntities, VMwareEntities } from '@cohesity/iris-shared-constants';

/**
 * Mapping of environment, source type to params key.
 *
 * TODO: add other environment and entity mapping.
 */
export const SourceEntityParams: Record<string, Record<string, string>> = {
  [Environment.kHyperV]: {
    [HyperVEntities.kSCVMMServer]: 'scvmmParams',
    [HyperVEntities.kStandaloneCluster]: 'standaloneClusterParams',
    [HyperVEntities.kStandaloneHost]: 'standaloneHostParams',
  },
  [Environment.kVMware]: {
    [VMwareEntities.kVCenter]: 'vCenterParams',
    [VMwareEntities.kStandaloneHost]: 'esxiParams',
    [VMwareEntities.kvCloudDirector]: 'vcdParams',
  },
};
