/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityV2ApiConfiguration as __Configuration } from '../cohesity-v2api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { FortknoxKmsKeyInfo } from '../models/fortknox-kms-key-info';
import { AddFortknoxKmsKeyReq } from '../models/add-fortknox-kms-key-req';
import { PairFortknoxVaultsClustersReq } from '../models/pair-fortknox-vaults-clusters-req';
import { GetFortknoxVaultsResp } from '../models/get-fortknox-vaults-resp';
import { AddFortknoxVaultsResp } from '../models/add-fortknox-vaults-resp';
import { AddFortknoxVaultsReq } from '../models/add-fortknox-vaults-req';
import { VaultTransferTimeConfigs } from '../models/vault-transfer-time-configs';
import { PerformVaultActionsReq } from '../models/perform-vault-actions-req';
import { VaultTransferTimeConfig } from '../models/vault-transfer-time-config';
@Injectable({
  providedIn: 'root',
})
class FortknoxService extends __BaseService {
  static readonly AddFortKnoxKmsKeyPath = '/mcm/fortknox/kms-keys';
  static readonly PairFortknoxVaultsClustersPath = '/mcm/fortknox/pairing';
  static readonly GetFortknoxVaultsPath = '/mcm/fortknox/vaults';
  static readonly AddFortknoxVaultsPath = '/mcm/fortknox/vaults';
  static readonly GetVaultTransferTimeConfigPath = '/mcm/fortknox/vaults/transfer-time-config';
  static readonly PerformVaultActionsPath = '/mcm/fortknox/vaults/{globalVaultId}/actions';
  static readonly UpdateVaultTransferTimeConfigPath = '/mcm/fortknox/vaults/{globalVaultId}/transfer-time-config';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Add KMS key config for Fortknox.
   *
   * Add KMS key config for Fortknox.
   * @param body Specifies the parameters to add KMS key for FortKnox.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  AddFortKnoxKmsKeyResponse(body: AddFortknoxKmsKeyReq,
    regionId?: string): __Observable<__StrictHttpResponse<FortknoxKmsKeyInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/mcm/fortknox/kms-keys`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FortknoxKmsKeyInfo>;
      })
    );
  }
  /**
   * Add KMS key config for Fortknox.
   *
   * Add KMS key config for Fortknox.
   * @param body Specifies the parameters to add KMS key for FortKnox.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  AddFortKnoxKmsKey(body: AddFortknoxKmsKeyReq,
    regionId?: string): __Observable<FortknoxKmsKeyInfo> {
    return this.AddFortKnoxKmsKeyResponse(body, regionId).pipe(
      __map(_r => _r.body as FortknoxKmsKeyInfo)
    );
  }

  /**
   * An endpoint to pair clusters with fortknox vaults.
   *
   * An endpoint to pair clusters with fortknox vaults.
   * @param body Specifies the parameters to pair claimed clusters with fortknox vaults.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   */
  PairFortknoxVaultsClustersResponse(body: PairFortknoxVaultsClustersReq,
    regionId?: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/mcm/fortknox/pairing`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * An endpoint to pair clusters with fortknox vaults.
   *
   * An endpoint to pair clusters with fortknox vaults.
   * @param body Specifies the parameters to pair claimed clusters with fortknox vaults.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   */
  PairFortknoxVaultsClusters(body: PairFortknoxVaultsClustersReq,
    regionId?: string): __Observable<null> {
    return this.PairFortknoxVaultsClustersResponse(body, regionId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get Fortknox vaults.
   *
   * Get the list of FortKnox vaults for the logged in account.
   * @param regionIds List of region IDs to filter the response.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  GetFortknoxVaultsResponse(regionIds?: null | Array<null | 'us-east-1' | 'us-east-2' | 'us-west-1' | 'us-west-2' | 'ca-central-1' | 'ap-northeast-1' | 'ap-south-1' | 'ap-southeast-1' | 'ap-southeast-2' | 'eu-central-1' | 'eu-west-2' | 'me-south-1' | 'eu-west-3' | 'azure-centralus'>,
    regionId?: string): __Observable<__StrictHttpResponse<GetFortknoxVaultsResp>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (regionIds || []).forEach(val => {if (val != null) __params = __params.append('regionIds', val.toString())});
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/mcm/fortknox/vaults`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetFortknoxVaultsResp>;
      })
    );
  }
  /**
   * Get Fortknox vaults.
   *
   * Get the list of FortKnox vaults for the logged in account.
   * @param regionIds List of region IDs to filter the response.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  GetFortknoxVaults(regionIds?: null | Array<null | 'us-east-1' | 'us-east-2' | 'us-west-1' | 'us-west-2' | 'ca-central-1' | 'ap-northeast-1' | 'ap-south-1' | 'ap-southeast-1' | 'ap-southeast-2' | 'eu-central-1' | 'eu-west-2' | 'me-south-1' | 'eu-west-3' | 'azure-centralus'>,
    regionId?: string): __Observable<GetFortknoxVaultsResp> {
    return this.GetFortknoxVaultsResponse(regionIds, regionId).pipe(
      __map(_r => _r.body as GetFortknoxVaultsResp)
    );
  }

  /**
   * Add Cohesity Fortknox vaults in a given list of regions for the given account.
   *
   * Add Cohesity Fortknox vaults in a given set of regions for the helios account
   * @param body Specifies the parameters to add Fortknox vaults in a region.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  AddFortknoxVaultsResponse(body: AddFortknoxVaultsReq,
    regionId?: string): __Observable<__StrictHttpResponse<AddFortknoxVaultsResp>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/mcm/fortknox/vaults`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AddFortknoxVaultsResp>;
      })
    );
  }
  /**
   * Add Cohesity Fortknox vaults in a given list of regions for the given account.
   *
   * Add Cohesity Fortknox vaults in a given set of regions for the helios account
   * @param body Specifies the parameters to add Fortknox vaults in a region.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  AddFortknoxVaults(body: AddFortknoxVaultsReq,
    regionId?: string): __Observable<AddFortknoxVaultsResp> {
    return this.AddFortknoxVaultsResponse(body, regionId).pipe(
      __map(_r => _r.body as AddFortknoxVaultsResp)
    );
  }

  /**
   * Get transfer time configurations.
   *
   * Get data transfer time configurations of FortKnox vaults. These are backup data transfer configurations of FortKnox vaults.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param globalVaultIds List of FortKnox Global vault IDs.
   * @return Success
   */
  GetVaultTransferTimeConfigResponse(regionId?: string,
    globalVaultIds?: null | Array<string>): __Observable<__StrictHttpResponse<VaultTransferTimeConfigs>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    (globalVaultIds || []).forEach(val => {if (val != null) __params = __params.append('globalVaultIds', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/mcm/fortknox/vaults/transfer-time-config`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<VaultTransferTimeConfigs>;
      })
    );
  }
  /**
   * Get transfer time configurations.
   *
   * Get data transfer time configurations of FortKnox vaults. These are backup data transfer configurations of FortKnox vaults.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param globalVaultIds List of FortKnox Global vault IDs.
   * @return Success
   */
  GetVaultTransferTimeConfig(regionId?: string,
    globalVaultIds?: null | Array<string>): __Observable<VaultTransferTimeConfigs> {
    return this.GetVaultTransferTimeConfigResponse(regionId, globalVaultIds).pipe(
      __map(_r => _r.body as VaultTransferTimeConfigs)
    );
  }

  /**
   * Perform actions on a given FortKnox vault.
   *
   * Perform actions on a given FortKnox vault specified by global vault id.
   * @param params The `FortknoxService.PerformVaultActionsParams` containing the following parameters:
   *
   * - `globalVaultId`: Specifies the global vault id of the vault on which the action needs to be performed.
   *
   * - `body`: Specifies the parameters to perform actions on a vault.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   */
  PerformVaultActionsResponse(params: FortknoxService.PerformVaultActionsParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/mcm/fortknox/vaults/${encodeURIComponent(params.globalVaultId)}/actions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Perform actions on a given FortKnox vault.
   *
   * Perform actions on a given FortKnox vault specified by global vault id.
   * @param params The `FortknoxService.PerformVaultActionsParams` containing the following parameters:
   *
   * - `globalVaultId`: Specifies the global vault id of the vault on which the action needs to be performed.
   *
   * - `body`: Specifies the parameters to perform actions on a vault.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   */
  PerformVaultActions(params: FortknoxService.PerformVaultActionsParams): __Observable<null> {
    return this.PerformVaultActionsResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Update transfer time configuration.
   *
   * Update data transfer time configuration of a FortKnox vault. These configurations apply only for FortKnox vaulting.
   * @param params The `FortknoxService.UpdateVaultTransferTimeConfigParams` containing the following parameters:
   *
   * - `globalVaultId`: Specifies the global vault id of the vault.
   *
   * - `body`: Parameters to update transfer time configuraion of a FortKnox vault.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * @return Success
   */
  UpdateVaultTransferTimeConfigResponse(params: FortknoxService.UpdateVaultTransferTimeConfigParams): __Observable<__StrictHttpResponse<VaultTransferTimeConfig>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/mcm/fortknox/vaults/${encodeURIComponent(params.globalVaultId)}/transfer-time-config`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<VaultTransferTimeConfig>;
      })
    );
  }
  /**
   * Update transfer time configuration.
   *
   * Update data transfer time configuration of a FortKnox vault. These configurations apply only for FortKnox vaulting.
   * @param params The `FortknoxService.UpdateVaultTransferTimeConfigParams` containing the following parameters:
   *
   * - `globalVaultId`: Specifies the global vault id of the vault.
   *
   * - `body`: Parameters to update transfer time configuraion of a FortKnox vault.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * @return Success
   */
  UpdateVaultTransferTimeConfig(params: FortknoxService.UpdateVaultTransferTimeConfigParams): __Observable<VaultTransferTimeConfig> {
    return this.UpdateVaultTransferTimeConfigResponse(params).pipe(
      __map(_r => _r.body as VaultTransferTimeConfig)
    );
  }
}

module FortknoxService {

  /**
   * Parameters for PerformVaultActions
   */
  export interface PerformVaultActionsParams {

    /**
     * Specifies the global vault id of the vault on which the action needs to be performed.
     */
    globalVaultId: string;

    /**
     * Specifies the parameters to perform actions on a vault.
     */
    body: PerformVaultActionsReq;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;
  }

  /**
   * Parameters for UpdateVaultTransferTimeConfig
   */
  export interface UpdateVaultTransferTimeConfigParams {

    /**
     * Specifies the global vault id of the vault.
     */
    globalVaultId: string;

    /**
     * Parameters to update transfer time configuraion of a FortKnox vault.
     */
    body: VaultTransferTimeConfig;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;
  }
}

export { FortknoxService }
