<form (validatedSubmit)="saveConfig()"
  [formGroup]="formGroup"
  cogDataId="helios-default-landing-app"
  cohValidateOnSubmit>
  <h1 mat-dialog-title>
    {{'defaultLandingPage' | translate}}
  </h1>

  <mat-dialog-content class="dialog-options" cogDataId="landing-page">
    <label id="helios-landing-page-label">
      {{'helios.preferences.landingPage' | translate}}
    </label>

    <mat-radio-group
      aria-labelledby="helios-landing-page-label"
      [formControl]="formGroup.controls.defaultLandingPage"
      cogDataId="menu"
      [inheritDataId]="true">
      <mat-radio-button *ngFor="let app of appList" [value]="app.app"
        [cogDataId]="'option-' + app.title"
        [inheritDataId]="true">
        <span class="radio-title">
          {{app.title | translate}}
        </span>
      </mat-radio-button>
    </mat-radio-group>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button
      cogDataId="cancel"
      mat-button
      color="primary"
      cdkFocusInitial
      mat-dialog-close
      [inheritDataId]="true">
      {{'cancel' | translate}}
    </button>

    <button
      cogDataId="save"
      mat-flat-button
      color="primary"
      cogSubmitButton
      [disabled]="loading$ | async"
      [loading]="loading$ | async"
      [inheritDataId]="true">
      {{'save' | translate}}
    </button>
  </mat-dialog-actions>
</form>
