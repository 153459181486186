import { Injectable } from '@angular/core';
import { Resource, ResourceType } from '@cohesity/api/guardian';
import { DataTreeNode, DataTreeSelection, DataTreeTransformer } from '@cohesity/helix';

/**
 * Mapping of resource param name by type of resource.
 * Different resources like region, service, source can have different param names to hold details.
 */
const resourceDetailsParamByType: Partial<Record<ResourceType, keyof Resource>> = {
  [ResourceType.Service]: 'serviceResource',
  [ResourceType.CloudProviderRegion]: 'cloudProviderRegion',
  [ResourceType.Cluster]: 'clusterResource',
  [ResourceType.Source]: 'sourceResource',
  [ResourceType.Aggregator]: 'aggregatorResource',
};

/**
 * Local implementation of DataTreeNode class for managing tree data.
 */
export class ResourceTreeNode implements DataTreeNode<any> {
  /**
   * Gets the name of the resource node.
   */
  get name(): string {
    const param = resourceDetailsParamByType[this.data.resourceType];
    return param ? this.data[param].name : 'resources';
  }

  /**
   * Determines whether the node is expandable.
   */
  get expandable(): boolean {
    return !!this.data.children;
  }

  /**
   * Assembles a unique ID for the node.
   */
  get id() {
    return this.data.id;
  }

  /**
   * Gets the environment type name.
   */
  get type() {
    return this.data.resourceType;
  }

  /**
   * Determines whether the node is selectable.
   */
  isSelectable = true;

  /**
   * Returns the id property as an array of ids
   */
  get ids() {
    return [this.id];
  }

  constructor(public data: any, public level: number) {}

  canSelect(): boolean {
    return true;
  }

  canAutoSelect(): boolean {
    return true;
  }

  canExclude(): boolean {
    return false;
  }
}

/**
 * Basic transformer service to support the Data Tree component.
 */
@Injectable({
  providedIn: 'root',
})
export class ResourceTreeTransformerService implements DataTreeTransformer<any> {
  /**
   * The previous selection (used for comparison & delta detection).
   */
  previousSelection: DataTreeSelection<ResourceTreeNode>;

  getChildren(structuredNode: any): ResourceTreeNode[] {
    return structuredNode.children;
  }

  getLevel(flatNode: ResourceTreeNode): number {
    return flatNode.level;
  }

  getExpandable(flatNode: ResourceTreeNode): boolean {
    return flatNode.expandable;
  }

  /**
   * Only expand certain node types by default.
   *
   * @param   treeNode   The treenode to check.
   * @return  True if the node should be expanded by default.
   */
  shouldExpandNodeOnLoad(treeNode: ResourceTreeNode): boolean {
    return [
      ResourceType.Root,
      ResourceType.Service,
    ].includes(treeNode.type);
  }

  transformData(node: any, level: number): ResourceTreeNode {
    return new ResourceTreeNode(node, level);
  }

  /**
   * The subscribe handler for selection changes.
   *
   * @param   selection   The current selection.
   */
  selectionChangeHandler(selection: DataTreeSelection<ResourceTreeNode>): any {
    return (this.previousSelection = selection);
  }

  /**
   * Convert the data tree selection model to the resource selection model.
   *
   * @param   selection   The selection from the tree.
   * @return  resource selection info.
   */
  transformFromDataTreeSelection(selection: DataTreeSelection<ResourceTreeNode>): string[] {
    return selection.selected
      .filter(item => !item.expandable)
      .concat(selection.autoSelected)
      .map(item => item.id);
  }

  /**
   * Convert source selection to the data tree selection model.
   * source ids can be either selected items or auto selected items, nodes with children are
   * assumed to be auto selected. Nodes can be in the tree multiple times, and should not be
   * duplicated in the selection.
   *
   * @param   allNodes         The unfiltered list of tree nodes.
   * @param   selection  The job selection.
   * @return  A data tree selection model.
   */
  transformToDataTreeSelection(
    allNodes: ResourceTreeNode[],
    resourceSelection: string[]
  ): DataTreeSelection<ResourceTreeNode> {
    const resourceMap: any = {};
    const selection: DataTreeSelection<ResourceTreeNode> = {
      autoSelected: [],
      excluded: [],
      selected: [],
      options: {},
    };

    if (!resourceSelection) {
      return selection;
    }

    const nodesLength = allNodes.length;
    for (let i = 0; i < nodesLength; i++) {
      const node = allNodes[i];

      if ((resourceSelection || []).includes(node.id) && !resourceMap[node.id]) {
        resourceMap[node.id] = node;
        node.expandable ? selection.autoSelected.push(node) : selection.selected.push(node);
      }
    }

    return selection;
  }
}
