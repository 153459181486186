/** Represents generic source authentication. */
export interface GenericSourceAuth {
  /** Account username. */
  username: string;

  /** Account password. */
  password: string;
}

/** Represents Oracle source authentication type. */
export enum OracleSourceAuthType {
  /** Server auth type. */
  ServerAuth = 'osAuth',

  /** Database auth type. */
  DatabaseAuth = 'databaseAuth',
}

/** Represents oracle source authentication. */
export interface OracleSourceAuth {
  /** Type of auth to use for oracle registration. */
  authType: OracleSourceAuthType;

  /** Auth username in case of database auth type. */
  username: string;

  /** Auth password in case of database auth type. */
  password: string;
}

/** Represents the values for Source Autentication types. */
export enum GenericSourceAuthTypes {
  password = 'password',
  sshKey = 'sshKey',
}
