import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ProtectionSource } from '@cohesity/api/v1';
import { filter, startWith } from 'rxjs/operators';

import { ProtectionItemName, SourceSideDeduplication } from '../../../models';
import { BaseProtectionBuilderComponent } from '../../base-protection-builder/base-protection-builder.component';

/**
 * Default value for Source Side Deduplication.
 */
export const DefaultSourceSideDeduplication: SourceSideDeduplication = {
  enabled: false,
  exclusions: [],
};

/**
 * Component for adding source side deduplication exclusions for a protection group
 * in the settings list.
 */
@Component({
  selector: 'coh-settings-list-source-side-deduplication',
  templateUrl: './settings-list-source-side-deduplication.component.html',
  styleUrls: ['./settings-list-source-side-deduplication.component.scss']
})
export class SettingsListSourceSideDeduplicationComponent
  extends BaseProtectionBuilderComponent<SourceSideDeduplication, any> {

  /**
   * Default value for this form group.
   */
  _value = DefaultSourceSideDeduplication;

  /**
   * Source side deduplication form group for the parent adapter form.
   */
  sourceSideDedupeFormGroup = new UntypedFormGroup({
    enabled: new UntypedFormControl(this.value.enabled, Validators.required),
    exclusions: new UntypedFormControl({value: this.value.exclusions, disabled: true}),
  });

  /**
   * Specifies all possible exclusion options for autocomplete.
   */
  allExclusions: ProtectionSource[];

  /**
   * Gets the enabled control within source side deduplication form group.
   *
   * @return   FormControl of the enabled toggle.
   */
  get sourceSideDedupeEnabledControl(): UntypedFormControl {
    return this.sourceSideDedupeFormGroup.get('enabled') as UntypedFormControl;
  }

  /**
   * Gets the exclusion control within source side deduplication form group.
   *
   * @return  FormControl of the exclusion list.
   */
  get exclusionsControl(): UntypedFormControl {
    return this.sourceSideDedupeFormGroup.get('exclusions') as UntypedFormControl;
  }

  /**
   * Initialize form control.
   */
  initFormControl() {
    const objectsFormControl = this.formGroup.parent.get(ProtectionItemName.Objects);

    if (objectsFormControl) {
      objectsFormControl.valueChanges.pipe(
        startWith(objectsFormControl.value),
        filter(value => !!value),
        this.untilDestroy(),
      ).subscribe(value => {
        const isSqlProtection = this.formGroup.parent.get(ProtectionItemName.MsSqlSettings) ? true : false;
        this.allExclusions = [];

        if (isSqlProtection) {
          // If it's in SQL protection, we collect the host of selected DBs and avoid duplicate host.
          const hostIds = [];
          value.sourceTree.dataTreeSelection.currentSelection.selected.forEach(selection => {
            const sqlHost = selection.sqlHost;
            if (!hostIds.includes(sqlHost.id)) {
              hostIds.push(sqlHost.id);
              // Push all selected source to the exclusions options array.
              this.allExclusions.push(sqlHost);
            }
          });
        } else {
          const { sourceIds } = value.selection;

          // Create a map of all selected source ids
          const sourceIdsMap = {};

          for (const sourceId of sourceIds) {
            sourceIdsMap[sourceId] = true;
          }

          for (const dataItem of value.sourceTree.data) {
            for (const node of dataItem.nodes) {
              if (sourceIdsMap[node.protectionSource.id]) {
                // If the source id is selected, push it to the exclusions options
                // array.
                this.allExclusions.push(node.protectionSource);
              }
            }
          }
        }
      });
    }

    // Set disabled state of exclusions list based on changes to the toggle value.
    this.sourceSideDedupeEnabledControl.valueChanges
      .pipe(this.untilDestroy())
      .subscribe(dedupEnabled => {
        if (dedupEnabled) {
          this.exclusionsControl.enable();
        } else {
          this.exclusionsControl.disable();
        }
      });

    if (this.protectionGroup && this.protectionGroup.id) {
      this.value = this.protectionGroup.sourceSideDeduplication;
      this.formControl.setValue(this.value);
    }
  }

  /**
   * Attaches internal form group to the parent component.
   */
  addFormControl() {
    this.formGroup.addControl(this.name, this.sourceSideDedupeFormGroup);
  }
}
