/**
 * Checking whether the IP is within the Apps Subnet IP range.
 *
 * @param appIpStr    App IP Address
 * @param subNetMask  App subnet mask
 * @param matchIpStr  IP to match against the App CIDR
 * @returns true if the IP is matched else false
 */
export const checkForIpMatch = (appIpStr: string, subNetMask: number, matchIpStr: string): boolean => {
  const ipMatchCount = 4 - Math.floor(subNetMask / 8);
  const appIpArr = appIpStr.split('.');
  const matchIpArr = matchIpStr.split('.');
  let isMatched = true;

  for (let i = 0; i < ipMatchCount; i++) {
    if (appIpArr[i] !== matchIpArr[i]) {
      isMatched = false;
    }
  }
  return isMatched;
};

/**
 * Create Netmask Ipv4 Addrs
 *
 * @param     Number    netmaskBits
 * @returns   String    return netmask Ipv4 addrs string
 */
export function createNetmaskAddr(netmaskBits: number): string {
  const mask = [];
  let n: number;
  for (let i = 0; i < 4; i++) {
    n = Math.min(netmaskBits, 8);
    mask.push(256 - Math.pow(2, 8 - n));
    netmaskBits -= n;
  }
  return mask.join('.');
}

/**
 * Create Netmask Ipv6 Addrs
 *
 * @param     Number    netmaskBits
 * @returns   String    return netmask Ipv6 addrs string
 */
export function createIPv6NetmaskAddr(netmaskBits: number): string {
  let mask = '';
  for (let i = 0; i < 8; i++) {
    if (netmaskBits >= 16) {
      mask += 'ffff';
      netmaskBits -= 16;
    } else {
      mask += (Math.pow(2, netmaskBits) - 1).toString(16).padStart(4, '0');
      netmaskBits = 0;
    }
    if (i < 7) {
      mask += ':';
    }
  }
  return mask;
}
