// Modules & Directive: cPostIframe

(function(angular, undefined) {
  'use strict';

  angular.module('C.postIframe', [])
    .component('cPostIframe', {
      bindings: {
        /**
         * @type   {String}   name attribute for iframe,
         * will also be used as target for the form
         */
        name: '<',

        /**
         * @type   {Function} callback to be executed when iframe is loaded
         */
        onload: '&',

        /**
         * @type   {Object}   Params to be passed to the post form
         * Should contain params url and body
         */
        formOptions: '<?',
      },
      controller: 'PostIframeCtrl',
      templateUrl: 'app/global/c-post-iframe/c-post-iframe.html',
    })
    .controller('PostIframeCtrl', PostIframeCtrl);

  function PostIframeCtrl($scope, $element) {
    const $ctrl = this;

    _.assign($ctrl, {
      $onInit: $onInit,
    });

    /**
     * A new iframe should be generated and inserted into DOM directly with interpolated name
     * instead of creating an iframe with variable name in name and changing it after
     */
    function $onInit() {
      const iframe = $element.html(`
        <iframe name="${$ctrl.name}"
          id="${$ctrl.name}" frameborder="0"
          style="width: 100%; min-height: 100%"></iframe>
      `).find('iframe');

      // iframe onload
      iframe.on('load', loadHandler);
      $scope.$on('$destroy', function cIframeOnloadDestroyFn() {
        iframe.off('load', loadHandler);
      });

      /**
       * handler for iframe onload event
       * @method    loadHandler
       */
      function loadHandler() {
        return $ctrl.onload({ element: iframe });
      }
    }
  };
})(angular);
