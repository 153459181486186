import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

/**
 * Data Model of radio group option.
 */
export interface RadioGroupOption {
  /**
   * Name for radio group for display.
   */
  name: string;
}

/**
 * Generic radio group component for recover flow.
 *
 * @example
 *   <coh-settings-list-radio-group
 *     [control]="formGroupControls.someModel"
 *     [options]="someOptions"
 *     [formGroup]="formGroup"
 *     [readOnly]="readOnly">
 *   </coh-settings-list-radio-group>
 */
@Component({
  selector: 'coh-settings-list-radio-group',
  templateUrl: './settings-list-radio-group.component.html'
})
export class SettingsListRadioGroupComponent implements OnInit {
  /**
   * If the component should be displayed as read-only.
   */
  @Input() readOnly: boolean;

  /**
   * Form group for display purpose only used by settings-list.
   */
  @Input() formGroup: UntypedFormGroup;

  /**
   * Form control for radio group.
   */
  @Input() control: UntypedFormControl;

  /**
   * Label to be displayed in the read-only mode.
   */
  @Input() label: string;

  /**
   * Whether to show "New Cohesity View" recover to option.
   */
  @Input() options: RadioGroupOption[];

  /**
   * Initialization.
   */
  ngOnInit() {
    if (!this.control.value) {
      this.control.setValue(this.options[0]);
    }
  }
}
