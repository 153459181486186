// End User License Agreement Service

;(function(angular, undefined) {

  angular.module('C')
    .service('EulaService', eulaServiceFn);

  function eulaServiceFn($rootScope, $q, $uibModal, $window, $http,
    ClusterService, cMessage, NgEulaService) {

    var clusterInfo;
    var eulaType;

    var modalConfig = {
      keyboard: true,
      modalFade: true,
      backdrop: 'static',
      templateUrl: 'app/global/c-eula/eula.html',
      controller: eulaModalController,
      windowClass: 'c-modal-soft c-modal-eula',
    };

    /**
     * Open the EULA in a new window Let the browser do it's thing with this
     * file
     */
    function downloadEula() {
      $window.open(getEulaPath());
    }

    /**
     * returns the html eula path to be used in eula acceptence modal
     *
     * @return {string} path to eula html file
     */
    function getEulaPath() {
      switch (eulaType) {
        case 'hpEula':
          return NgEulaService.hpFile;
        default:
          return NgEulaService.eulaPath;
      }
    }

    /**
     * Instantiate the Modal if user has appropriate permissions
     *
     * @param      {string} type type of EULA modal we are opening, decides
     *                           which eulaPath to use in modal
     *
     * @return     {Promise}  uibModal
     */
    function showModal(type) {
      var deferred;
      var modalPromise;

      eulaType = type;
      clusterInfo = ClusterService.clusterInfo;

      if ($rootScope.basicClusterInfo.mcmMode ||
        $rootScope.user.privs.CLUSTER_MODIFY) {
        modalPromise = $uibModal.open(modalConfig).result;
      } else {
        // if user doesn't have appropriate permissions return a fake
        // promise thats pre-rejected.
        deferred = $q.defer();
        deferred.reject('permission-fail');
        modalPromise = deferred.promise;

        // inform the user they need an admin to approve the EULA
        cMessage.error({
          textKey: 'eula.adminUserRequired',
          persist: true,
        });
      }

      return modalPromise;
    }

    eulaModalController.$inject = ['$rootScope', '$scope', '$uibModalInstance',
      'HeliosService'];
    function eulaModalController($rootScope, $scope, $uibModalInstance,
      HeliosService) {

      /**
       * Scope method to invoke downloadEula from within modal
       */
      $scope.download = downloadEula;

      $scope.isHpEula = function isHpEula() {
        return eulaType === 'hpEula';
      };

      /**
       * Reject the EULA
       */
      $scope.cancel = function cancel() {
        $uibModalInstance.dismiss('cancel');
      };

      /**
       * Accept the EULA
       */
      $scope.agree = function agree() {

        var signedByUser;
        var signedVersion = NgEulaService.version;
        if ($rootScope.basicClusterInfo.mcmMode) {
          signedByUser = HeliosService.getUserExternalUserId();
          signedVersion = NgEulaService.heliosVersion;
        } else {
          signedByUser = clusterInfo.user ?
            clusterInfo.user.username : $rootScope.user.username;
        }

        // Resolve the modal with eula configuration, to be passed on to
        // the license key modal, as the two are submitted together.
        $uibModalInstance.close({
          signedVersion: signedVersion,
          signedByUser: signedByUser,

          // Current time in seconds
          signedTime: Math.floor(Date.now() / 1000),
        });
      };

      /**
       * Initialization function
       */
      function init() {
        $scope.eulaPath = getEulaPath();
        $scope.isEulaPdf = $scope.eulaPath.endsWith('pdf');
        if (!$scope.isEulaPdf) {
          $http.get(getEulaPath()).then(
            function getEulaSuccess(resp) {
              $scope.eulaText = resp.data;
            },
            function getEulaFail(resp) {
              $uibModalInstance.dismiss('eula retrieval failed');
            }
          );
        }
      }

      init();
    }

    return {
      downloadEula: downloadEula,
      showModal: showModal,
    };
  }

}(angular));
