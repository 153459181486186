import { Injectable } from '@angular/core';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';
import { Transition } from '@uirouter/core';

import { StateManagementService } from '../../services';
import { GuardPriority, GuardResult, StateGuard } from '../state-guard';

/**
 * Roles Guard redirects all state traffic to the new Roles page based on the
 * Feature flag ngRoles.
 */
@Injectable({
  providedIn: 'root'
})
export class RolesGuard implements StateGuard {
  /**
   * This guard priority is set to App, which indicates it should run as part of the main application
   * after all bootstrapping and initializing is completed.
   */
  guardPriority = GuardPriority.App;

  /**
   * Match all navigation to on-prem-access-management.roles.
   */
  matchCriteria = {
    to: 'roles',
  };

  /**
   * Constructor.
   */
  constructor(private irisCtx: IrisContextService,
    readonly stateManagementService: StateManagementService) {}

  /**
   * Handles the redirection to the new Roles page based on feature flag.
   * This will help with deeplinked states with the old and new Roles page.
   *
   * @param    transition   The UI Router Transition.
   * @returns  A redirect transition if new Roles page is enabled.
   */
  onStart(transition: Transition): GuardResult {
    const params = transition.params();
    if (flagEnabled(this.irisCtx.irisContext, 'ngRoles') &&
      this.stateManagementService.canUserAccessState('on-prem-access-management.roles', params, true)) {
      return transition.router.stateService.target('on-prem-access-management.roles', params);
    }
  }
}
