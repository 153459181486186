import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Api } from '../api.constants';
import { convertToHttpParams } from '../api.utils';
import { GetRegisteredSourcesParams, RegisteredSource } from '../private-models';

/**
 * Mcm protected sources service.
 */
@Injectable({
  providedIn: 'root',
})
export class McmProtectionSourcesServiceApi {
  constructor(private http: HttpClient) {}

  /**
   * Get the list of registered sources across clusters connected with helios.
   *
   * @param   params   The source registration params.
   * @returns An observable stream of the result.
   */
  getRegisteredSources(params: GetRegisteredSourcesParams = {}): Observable<RegisteredSource[]> {
    return this.http.get<RegisteredSource[]>(
      Api.mcm('protectionSources/registeredSources'),
      { params: convertToHttpParams(params) }
    );
  }
}
