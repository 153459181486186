// HTTP interceptor for setting session locale.
;(function(angular, undefined) {
  'use strict';

  angular.module('C.localeInterceptor', [])
    .service('localeInterceptor', localeInterceptorFn)
    .config(localeInterceptorConfigFn);

  function localeInterceptorConfigFn($httpProvider) {
    $httpProvider.interceptors.push('localeInterceptor');
  }

  function localeInterceptorFn($injector) {
    var localeService = $injector.get('LocaleService');

    return {
      request: function requestFn(request) {
        // If the active locale is different from the user locale, set the
        // session locale to the active locale.
        if (localeService.activeLocale !== localeService.userLocale) {
          request.headers['X-SESSION-LOCALE'] =
            localeService.activeLocale;
        }

        return request;
      }
    };
  }

})(angular);
