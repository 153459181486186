// Component:  Cloud Retrieval: Submitted Searches

;(function(angular, undefined) {
  'use strict';

  var moduleName = 'C.cloud-retrieval';

  angular
    .module(moduleName)
    .component('submittedCloudSearches', {
      controller: SubmittedCloudSearchesControllerFn,
      templateUrl: 'app/protection/cloud-retrieval/cloud-retrieval.submitted-searches.html',
    });

  /**
   * SubmittedCloudSearches Controller
   **************************************************************************/
  function SubmittedCloudSearchesControllerFn(
    $scope, $state, cUtils, evalAJAX, SearchService, DateRangerService,
    PollTaskStatus) {

    var ctrl = this;

    angular.extend(ctrl, {
      // Properties
      $onInit: activate,
      searchDates: DateRangerService.getDateRangerValues(),

      // Methods
    });


    // METHODS
    /**
     * Activate this ngController!
     *
     * @method   activate
     */
    function activate() {
      startPoller();
    }

    /**
     * Starts polling for searchJobs.
     *
     * @method   startPoller
     * @return   {object}   Promise to resolve with the poller's response.
     */
    function startPoller() {
      var pollerConfig = {
        interval: 60,
        isDoneFn: isPollerDone,
        iteratorFn: getData,
        scope: $scope,
      };

      return PollTaskStatus.createPoller(pollerConfig);
    }

    /**
     * Determines if the searchJobs poller done.
     *
     * @method   isPollerDone
     * @return   {boolean}   True if poller done, False otherwise.
     */
    function isPollerDone() {
      return Array.isArray(ctrl.cloudSearches) &&
        ctrl.cloudSearches.every(function eachSearch(job) {
          return job.endTimeUsecs > 0;
        });
    }

    /**
     * Fetch the necessary data for this flow.
     *
     * @method    getData
     */
    function getData() {
      return SearchService.getRemoteVaultSearches().then(
        function dataReceived(data) {
          ctrl.cloudSearches = data.sort(function sortByTime(a, b) {
            return b.startTimeUsecs - a.startTimeUsecs;
          });
        },
        evalAJAX.errorMessage
      )
      .finally(
        function allReceived() {
          ctrl.isDataReady = true;
        }
      );
    }

  }


})(angular);
