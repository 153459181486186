import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { ProtectionSourcesServiceApi } from '@cohesity/api/v1';
import {
  CreateRecoveryRequest,
  ObjectServiceApi,
  ObjectSnapshot,
  ProtectedObject,
  ProtectedObjectsSearchResponseBody,
  Recovery as RecoveryApi,
  RecoveryServiceApi,
  SearchServiceApi,
} from '@cohesity/api/v2';
import { DataFilterValue, DateFilterRange, ValueFilterSelection } from '@cohesity/helix';
import { sanitizeFileName } from '@cohesity/utils';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { PassthroughOptionsService } from 'src/app/core/services';
import { envGroups, Environment, RecoveryAction } from 'src/app/shared';
import { DatePipeWrapper } from 'src/app/shared/pipes';

import { ObjectSearchResult } from '../model/object-search-result';
import { Recovery } from '../model/recovery';
import { RestorePointSelection } from '../model/restore-point-selection';
import { RestoreSearchResult } from '../model/restore-search-result';
import { ObjectSearchProvider } from './object-search-provider';
import { RestoreConfigService } from './restore-config.service';

/**
 * Convenience type to reference a data filter value for a property filter
 */
export type PropertyDataFilterValue = DataFilterValue<ProtectedObject, ValueFilterSelection[]>;

/**
 * Convenience type to reference a data filter value for a date range filter
 */
type DateRangeDataFilterValue = DataFilterValue<ProtectedObject, DateFilterRange<Date>>;

/**
 * Restore Service. This should handle making the API tasks necessary to search
 * for and recovery tasks.
 *
 * This implements ObjectSearchProvider, which is used to provide a search implementation for
 * The object search service.
 */
@Injectable()
export class RestoreService implements ObjectSearchProvider {

  /**
   * Date pipe used to determine a default task name.
   */
  private datePipe: DatePipeWrapper;

  /**
   * This is the restore operation type this restore service is configured for. Each restore component is
   * responsible for setting this value. This is used to look up all of the environments that should be
   * included in search filters.
   */
  restoreTypes: RecoveryAction[];

  /**
   * The list of environments to use for search queries. This is determined by the
   * list of registered adapters registered for for RecoverVMs
   */
  get searchEnvironments(): Environment[] {
    return this.restoreConfig.getSearchEnvironments(this.restoreTypes);
  }

  constructor(
    private recoveryService: RecoveryServiceApi,
    private searchService: SearchServiceApi,
    private objectsService: ObjectServiceApi,
    private restoreConfig: RestoreConfigService,
    private translate: TranslateService,
    private passthroughOptionsService: PassthroughOptionsService,
    private sourceService: ProtectionSourcesServiceApi,
    @Inject(LOCALE_ID) locale: string
  ) {
    this.datePipe = new DatePipeWrapper(locale);
  }

  /**
   * Gets a default task name for a recovery task.
   *
   * @param   taskNameKey   The key to use for translating the task name
   * @returns A default task name.
   */
  getDefaultTaskName(taskNameKey: string = 'recovery.defaultTaskName', params: string[] = []) {
    const dateString = [
      this.datePipe.transform(new Date(), 'mediumDate'),
      this.datePipe.transform(new Date(), 'shortTime'),
    ].join(' ');

    return sanitizeFileName(this.translate.instant(taskNameKey, { dateString, params: params.join('_') }));
  }

  /**
   * Generates the predefined task name as a Windows compatible file path.
   *
   * @param      taskName  The taskName
   * @param      unixPath  Whether this is a unix or windows path. This is generally determined by the os type, but
   *                       all nas operations should also use unix paths.
   * @return     The default folder path for restore
   */
  getDefaultAlternativeDirectory(taskName: string, unixPath: boolean) {
    const rootPath = unixPath ? '/tmp' : 'C:';
    const delimiter = unixPath ? '/' : '\\';
    return `${rootPath}${delimiter}${sanitizeFileName(taskName)}`;
  }

  /**
   * Get the object selection values form an existing recovery object.
   * Everything about the object can be derived from the api model except for the storage domain,
   * which is needed to lock the filter in the search results.
   * This can be retrieved by looking up the info for the first snapshot and using it to fill in the rest
   * currently, recoveries requires that the same storage domain be used for all objects.
   *
   * @param   recoveryApi   the recovery api model.
   * @param   envParams     optional env params.
   * @param   runInstanceId optional param which identifies runinstance id
   * @returns An array of the restore point selections.
   */
  getObjectSelectionsFromRecovery(recoveryApi: RecoveryApi,
    envParams = null, runInstanceId?: number): RestorePointSelection[] {
    const recoverySpecialParams = recoveryApi.recoveryAction === RecoveryAction.RecoverVApps ?
      {treatAsVapp: true} : undefined;
    const recovery = new Recovery(recoveryApi, recoverySpecialParams);

    if (!recovery.objects || !recovery.objects.length) {
      return [];
    }

    return recovery.objects.map(object => {
      const { objectInfo, protectionGroupId, protectionGroupName } = object.reference;
      const isPointInTime = object.isPointInTime;
      const timestampUsecs = object.recoveryPoint;
      const searchObject: RestoreSearchResult = {
        environment: objectInfo.environment,
        id: objectInfo.id,
        name: objectInfo.name,
        protectionGroupId,
        objectType: objectInfo.objectType,
        latestSnapshotsInfo: [{
          runInstanceId: runInstanceId,
          protectionGroupId
        }],
        // Not a big deal if we don't know the protection group name.
        protectionGroupName: protectionGroupName,

        // We need the storage domain id to lock the filters properly
        storageDomainId: recovery.storageDomainId,
        resultType: ObjectSearchResult.objectResultType,
        sourceEnvironment: objectInfo.environment,
        sourceId: objectInfo.sourceId,
        parentSourceName: (objectInfo as any).sourceName,
      };
      const sourceInfo = objectInfo.objectType === 'kVirtualApp' ?
        { objectType: 'kvCloudDirector' } : null;

      return {
        objectInfo: {
          ...searchObject,
          sourceInfo: sourceInfo,

          // If env params are provided, merge with the object.
          ...envParams,
        },
        objectIds: [objectInfo.id],
        restorePointId: object.snapshotId,
        isPointInTime: isPointInTime,
        timestampUsecs: timestampUsecs,
        archiveTargetInfo: object.archivalTargetInfo as any,
        snapshotRunType: (objectInfo as any).snapshotRunType,
      };
    });
  }

  /**
   * Fetches the entity hierarchy data for the specified object to validate if it's available or not
   *
   * @param objectId
   */
  verifyObjectEntityHierarchyData (objectId: number) {
    return this.sourceService.GetProtectionSourcesObjectById({
      id: objectId,
      regionId: this.passthroughOptionsService.regionId
    }).pipe(
      // Mapping error to null as API throws 500 error if object does not or no longer exist
      catchError(() => of(null)),
      map(source => !!source?.id)
    );
  }

  /**
   * Given an object id, group and run id, look up an object and recreate a restore point selection
   * object for it. This can be used to initialize the recovery search form.
   *
   * @param   objectId            The id of the object being recovered.
   * @param   protectionGroupId   The protection group id used to backup the object.
   * @param   runId               The group run containing the snapshot to recover.
   * @param   externalTargetId    The external target id.
   * @returns An object that can be selected directly in the form.
   */
  getObjectRestorePointSelection(
    objectId: number,
    protectionGroupId: string,
    runId: string,
    externalTargetId: number
  ): Observable<RestorePointSelection> {
    objectId = Number(objectId);
    externalTargetId = Number(externalTargetId);
    const runTime = Number(runId.split(':').pop());

    // Use the search service to look up the object and find it in the search results.
    const objectInfo$ = this.getProtectedObjectSearchResult(objectId, protectionGroupId);
    const snapshotInfo$ = this.objectsService
      .GetObjectSnapshots({
        id: objectId,
        toTimeUsecs: runTime,
        fromTimeUsecs: runTime,
        ...this.passthroughOptionsService.requestParams,
      })
      .pipe(
        map(response => response.snapshots),
        map(snapshots => {
          const filteredSnapshots = (snapshots || []).filter(snapshot =>
            snapshot.snapshotTimestampUsecs === runTime
          );

          if (!filteredSnapshots.length) {
            throw new Error(this.translate.instant('noMatchingSnapshotFound'));
          }

          const matchedSnapshot = filteredSnapshots.find(snapshot => {
            if (externalTargetId) {
              // If an externalTargetId is provided, return an archival
              // snapshot which matches the provided externalTargetId.
              return snapshot.externalTargetInfo &&
                snapshot.externalTargetInfo.targetId === externalTargetId;
            }

            // Otherwise return the matched local snapshot.
            return snapshot.snapshotTargetType === 'Local';
          });

          // Fallback to using an archival snapshot if no matching local
          // snapshot.
          return matchedSnapshot || filteredSnapshots[0];
        })
      );

    return forkJoin([objectInfo$, snapshotInfo$]).pipe(
      map(([objectInfo, snapshotInfo]) => ({
        objectInfo: objectInfo,
        objectIds: [objectInfo.id],
        restorePointId: snapshotInfo.id,
        timestampUsecs: snapshotInfo.snapshotTimestampUsecs,
        archiveTargetInfo: snapshotInfo.externalTargetInfo,
        snapshotRunType: snapshotInfo.runType,
      }))
    );
  }

  /**
   * Get the specific protected object by object id and its group id.
   *
   * @param objectId            protected object ID
   * @param protectionGroupId   protection group ID
   */
  getProtectedObjectSearchResult(objectId: number, protectionGroupId: string): Observable<ObjectSearchResult> {
    // Use the search service to look up the object and find it in the search results.
    return this.searchService
      .SearchProtectedObjects({
        protectionGroupIds: [protectionGroupId],
        objectIds: [objectId],
        ...this.passthroughOptionsService.requestParams,
      })
      .pipe(
        map(res => res.objects),
        map(objects => objects.find(object => object.id === objectId)),
        map((object: Required<ProtectedObject>) => new ObjectSearchResult(object)),
      );
  }

  /**
   * Return whether the recovery selection is restoring from a tape archive.
   *
   * @params objects The recovery selection.
   */
  getHasTapeArchival(objects: RestorePointSelection[]): boolean {
    if (!objects || !objects.length) {
      return false;
    }

    return objects.some(
      object =>
        object.archiveTargetInfo &&
        object.archiveTargetInfo.targetType === 'Tape'
    );
  }

  /**
   * Get the qstar restore task uid if a tape recovery - Resubmit.
   *
   * @param objects Objects which are marked for recovery on resubmit.
   */
  getQstarRestoreTaskUid(objects: RestorePointSelection[]): string {
    if (objects[0]?.archiveTargetInfo?.targetType === 'Tape') {
      // For an archival target recovery, all the objects need to belong to the
      // same archival task. So all objects will have the same archivalTaskId
      // here. The "archivalTaskId" here is the "qstarRestoreTaskUid".
      return objects[0].archiveTargetInfo.archivalTaskId;
    }
  }

  /**
   * Creates a new vm recovery.
   *
   * @param   params   The recovery request params
   * @returns An observable of the new recovery task.
   */
  createRecovery(params: CreateRecoveryRequest): Observable<RecoveryApi> {
    return this.recoveryService.CreateRecovery({
      body: params,
      ...this.passthroughOptionsService.requestParams,
    });
  }

  /**
   * Searches for a single object by id. If the object has been protected by multiple protection groups,
   * this will be configured to use the first group only.
   *
   * @param   objectId         The object id to look up
   * @param   environments     The object environments.
   * @param   objectActionKey  The object action key we filter by
   * @returns An observable of the object.
   */
  searchByObjectId(
    objectId: number,
    environments?: Environment[],
    objectActionKey?: Environment
  ): Observable<ObjectSearchResult> {
    return this.searchForObjects('*', [
      {
        key: 'objectId',
        value: [{
          value: objectId
        }],
        predicate: null,
      },
      {
        key: 'environment',
        value: (environments || []).map(environment => ({value: environment})),
        predicate: null,
      },
      {
        key: 'objectActionKey',
        value: objectActionKey ,
        predicate: null,
      }
    ], true).pipe(
      map(res => (res.objects && res.objects[0]) || null),
      map((object: Required<ProtectedObject>) => object && new ObjectSearchResult(object))
    );
  }

  /**
   * Function to search for an object based on provided name and environments.
   *
   * @param objectName The object name.
   * @param environments The object environments.
   * @returns Observable of the object.
   */
  searchByObjectNameAndEnvironments(objectName: string, environments: Environment[]): Observable<ObjectSearchResult> {
    return this.searchForObjects(objectName, [
      {
        key: 'environment',
        value: environments.map(environment => ({value: environment})),
        predicate: null,
      }
    ]).pipe(
      map(res => (res.objects && res.objects[0]) || null),
      map((object: Required<ProtectedObject>) => object && new ObjectSearchResult(object))
    );
  }

  /**
   * Makes the api call for object search and returns the raw results.
   *
   * @param   query                  The search query
   * @param   filters                 The filters to use for the search.
   * @param   allSearchEnvironments  If set to true, this will search all available environments
   *                                 regardless of the current recovery config.
   * @returns The raw response of the search api result.
   */
  searchForObjects(
    query: string,
    filters: DataFilterValue<any>[] = [],
    allSearchEnvironments: boolean = false
  ): Observable<ProtectedObjectsSearchResponseBody> {
    const params: SearchServiceApi.SearchProtectedObjectsParams = {
      searchString: query,
      environments: allSearchEnvironments || !this.restoreTypes ? undefined : this.searchEnvironments as any,
      snapshotActions: (this.restoreTypes || []) as any,
      ...this.passthroughOptionsService.requestParams,
    };

    const sourceFilter: PropertyDataFilterValue = filters.find(filter => filter.key === 'source');
    const protectionGroupFilter: PropertyDataFilterValue = filters.find(filter => filter.key === 'protectionGroup');
    const storageDomainFilter: PropertyDataFilterValue = filters.find(filter => filter.key === 'storageDomain');
    const dateRangeFilter: DateRangeDataFilterValue = filters.find(filter => filter.key === 'dateRange');
    const objectFilter: PropertyDataFilterValue = filters.find(filter => filter.key === 'objectId');
    const environmentGroupFilter: PropertyDataFilterValue = filters.find(filter => filter.key === 'environmentGroup');
    const environmentFilter: PropertyDataFilterValue = filters.find(filter => filter.key === 'environment');
    const objectActionKeyFilter: PropertyDataFilterValue = filters.find(filter => filter.key === 'objectActionKey');


    if (sourceFilter) {
      params.sourceIds = sourceFilter.value.map((entry: ValueFilterSelection) => entry.value as number);
    }

    if (objectFilter) {
      params.objectIds = objectFilter.value.map((entry: ValueFilterSelection) => entry.value as number);
    }

    if (protectionGroupFilter) {
      params.protectionGroupIds = protectionGroupFilter.value.map(
        (entry: ValueFilterSelection) => entry.value as string
      );
    }

    if (environmentGroupFilter && environmentGroupFilter.value.length) {
      const selectedEnvironments = envGroups[environmentGroupFilter.value[0].value as string];

      if (selectedEnvironments) {
        params.environments = selectedEnvironments.filter(env => params.environments.includes(env));
      }
    }

    // This is similar to the environment filter but environments are not grouped together.
    if (environmentFilter && environmentFilter.value.length) {
      params.environments = environmentFilter.value.map(
        (entry: ValueFilterSelection) => entry.value as any
      );

      // In case of Physical Source both the environments needs to be passed here
      // otherwise v2 protected objects doesn't return the file based objects.
      if (params.environments.includes(Environment.kPhysical) &&
        !params.environments.includes(Environment.kPhysicalFiles)) {
        params.environments.push(Environment.kPhysicalFiles);
      }
    }

    if (storageDomainFilter) {
      params.storageDomainIds = storageDomainFilter.value.map((entry: ValueFilterSelection) => entry.value as number);
    }

    if (dateRangeFilter && dateRangeFilter.value) {
      if (dateRangeFilter.value.start) {
        params.filterSnapshotFromUsecs = dateRangeFilter.value.start.valueOf() * 1000;
      }

      if (dateRangeFilter.value.end) {
        params.filterSnapshotToUsecs = dateRangeFilter.value.end.valueOf() * 1000;
      }
    }

    if (objectActionKeyFilter && objectActionKeyFilter.value) {
      params.objectActionKey = objectActionKeyFilter.value as any;
    }

    return this.searchService.SearchProtectedObjects(params);
  }

  /**
   * Runs an object search.
   *
   * @param   query   The search query
   * @param   filters The filters to use for the search.
   * @returns Observable of an array of matching objects.
   */
  doObjectSearch(query: string, filters: DataFilterValue<any>[] = []): Observable<RestoreSearchResult[]> {
    return this.searchForObjects(query, filters).pipe(
      map(res => res.objects),
      map((objects: Required<ProtectedObject>[]) => objects.reduce((searchResults: ObjectSearchResult[], object) => {
        searchResults.push(...object.latestSnapshotsInfo.map(info => new ObjectSearchResult(object, info)));
        return searchResults;
      }, []))
    );
  }

  /**
   * Given an array of object ids, return the restore point selection with their
   * latest snapshot for each of them if available.
   *
   * @param objectIds Array of object ids.
   * @return Array of restore point selection.
   */
  getObjectRestorePointSelectionFromObjectIds(
    objectIds: number[],
  ): Observable<RestorePointSelection[]> {
    const objectsInfo$ = this.searchService
      .SearchProtectedObjects({
        objectIds,
        ...this.passthroughOptionsService.requestParams,
      })
      .pipe(
        map(res => res.objects),
        map((objects: Required<ProtectedObject>[]) => objects.map(
          object => new ObjectSearchResult(object)
        )),
      );
    const snapshotsMap = {};

    for (const objectId of objectIds) {
      snapshotsMap[objectId] = this.objectsService
        .GetObjectSnapshots({
          id: objectId,
          ...this.passthroughOptionsService.requestParams,
        })
        .pipe(
          map(response => response.snapshots || []),
          map(snapshots =>
            // Try to select latest local snapshot, otherwise fallback to
            // whatever is newest.
            snapshots.find(snapshot => snapshot.snapshotTargetType === 'Local') || snapshots[0]
          )
        );
    }

    const snapshotInfoMap$ = forkJoin(snapshotsMap);

    return forkJoin([objectsInfo$, snapshotInfoMap$]).pipe(
      map(([objectsInfo, snapshotInfoMap]: [ObjectSearchResult[], Record<number, ObjectSnapshot>]) =>
        objectsInfo.map(objectInfo => ({
          objectInfo: objectInfo,
          objectIds: [objectInfo.id],
          restorePointId: snapshotInfoMap[objectInfo.id].id,
          timestampUsecs: snapshotInfoMap[objectInfo.id].snapshotTimestampUsecs,
          archiveTargetInfo: snapshotInfoMap[objectInfo.id].externalTargetInfo,
        })))
    );
  }
}
