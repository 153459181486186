//  MODULE: custom pagination functionality

;(function(angular, undefined) {
  'use strict';

  angular.module('smart-table')
    .config(stCustomConfig)
    .directive('stPaginationToggle', stPaginationToggleFn);

  stCustomConfig.$inject = ['stConfig', '$provide'];
  function stCustomConfig(stConfig, $provide) {

    // override the default pagination template provided by smart table
    stConfig.pagination.template = 'app/global/pagination/pagination.html';

    // Decorate stPagination to add tableState().pagination to scope so it can
    // be consumed in the custom pagination template
    $provide.decorator('stPaginationDirective', paginationDecorator);
    paginationDecorator.$inject = ['$delegate', '$translate', 'FEATURE_FLAGS'];
    function paginationDecorator($delegate, $translate, FEATURE_FLAGS) {

      var directive = $delegate[0];

      directive.compile = function newCompileFn() {

        return function newLinkFn(scope, element, attrs, ctrl) {

          // apply the original link function
          directive.link.apply(this, arguments);

          // expose pagination state object on scope for custom template
          scope.pagination = ctrl.tableState().pagination;

          // Show the pagination toggle button if...
          scope.showPaginationToggle =
            // Global feature flag is true AND
            FEATURE_FLAGS.paginationToggleEnabledGlobally &&

            // directive attribute `hidePaginationToggle` is falsy OR
            (!attrs.hidePaginationToggle ||

            // feature flag is true even for performance heavy pages.
            FEATURE_FLAGS.paginationToggleEnabledOnHeavyPages);

          // If pagination is disabled, reset it to always paginate
          // since the pagination toggle is deprecated.
          if (!scope.showPaginationToggle) {
            scope.pagination.disabled = false;
          }

          // pass through an st-name value for accurate IDs.
          scope.pagination.name = attrs.stName ? attrs.stName + '-': '';

          /**
           * Gets the current range label for materialized paginator.
           *
           * @method  getRangeLabel
           * @param   {Number}   pageIndex   The current page index.
           * @return  A string for current range label.
           */
          scope.getRangeLabel = function getRangeLabel(pageIndex) {
            var startIndex = 0;
            var endIndex = 0;

            if (scope.totalItemCount === 0 || scope.stItemsByPage === 0) {
              return $translate.instant('matPaginator.rangeLabelNone', {
                length: scope.totalItemCount
              });
            }

            scope.totalItemCount = Math.max(scope.totalItemCount, 0);
            startIndex = pageIndex * scope.stItemsByPage;

            // If the start index exceeds the list totalItemCount,
            // do not try and fix the end index to the end.
            endIndex = startIndex < scope.totalItemCount ?
              Math.min(startIndex + scope.stItemsByPage, scope.totalItemCount) :
              startIndex + scope.stItemsByPage;

            return $translate.instant('matPaginator.rangeLabel', {
              start: startIndex + 1,
              end: endIndex,
              length: scope.totalItemCount
            });

          }
        };

      };

      return $delegate;
    }
  }

  stPaginationToggleFn.$inject = ['$rootScope'];
  function stPaginationToggleFn($rootScope) {
    return {
      link: toggleLinkFn,
      restrict: 'E',
      require: '^stTable',
      templateUrl: 'app/global/pagination/pagination-toggle.html'
    };

    /**
     * @ngdoc directive
     * @name smart-table:stPaginationToggle
     * @function
     *
     * @description
     * This directive provide a means of toggling pagination (enabling/disabling).
     * This is implemented in the custom pagination template, and implementation
     * elsewhere isn't necessary/recommended.
     *
     * @deprecated   Removing pagination toggle per new Material design.
     *
     * @example
       <st-pagination-toggle></st-pagination-toggle>
     */
    function toggleLinkFn(scope, elem, attrs, tblCtrl) {

      var paginationState = tblCtrl.tableState().pagination;

      var origItemsPerPage;

      paginationState.disabled = !!paginationState.disabled;

      scope.text = $rootScope.text.globalPaginationPagination;

      scope.$watch(function totalItemCountWatcher() {
        return paginationState.totalItemCount;
      }, redrawTable);

      /**
       * Toggles pagination on/off, setting appropriate paginationState values.
       */
      scope.togglePagination = function togglePagination() {
        paginationState.disabled = !paginationState.disabled;
        redrawTable();
      };

      /**
       * Redraw table for paginated data
       *
       * @method   redrawTable
       */
      function redrawTable() {
        origItemsPerPage = origItemsPerPage || paginationState.number;
        paginationState.number = paginationState.disabled ?
          paginationState.totalItemCount : origItemsPerPage;
        paginationState.start = 0;
        paginationState.numberOfPages =
          Math.ceil(paginationState.totalItemCount / paginationState.number);

        tblCtrl.pipe();
      }

    }
  }

})(angular);
