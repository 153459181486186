import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { DnsDelegationZone } from '../internal-dns.models';

/**
 * This component is used to display the details of a DNS Delegation Zone.
 */
@Component({
  selector: 'coh-dns-vips-details',
  templateUrl: './dns-vips-details.component.html',
  styleUrls: ['./dns-vips-details.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DnsVipsDetailsComponent {
  /**
   * Model of the zone.
   */
  @Input() model: DnsDelegationZone;

  /**
   * Index of the model in the model array.
   */
  @Input() index: number;

  /**
   * This event is emitted when the user wants to remove the zone.
   */
  @Output() remove = new EventEmitter<number>();

  /**
   * This event is emitted when the user wants to edit the zone.
   */
  @Output() edit = new EventEmitter<number>();
}
