import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { SnapshotIndicatorStatus } from './snapshot-indicator.models';

@Component({
  selector: 'dg-ar-snapshot-indicator',
  templateUrl: './snapshot-indicator.component.html',
  styleUrls: ['./snapshot-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SnapshotIndicatorComponent implements OnChanges {
  /**
   * indicates weather a snapshot is clean, anomalous or latestCleanSnapshot
   */
  status: SnapshotIndicatorStatus;

  /**
   * Anomalous timestamps for easier lookup
   */
  @Input() anomalousTimestamps: number[];

  /**
   * Latest clean snapshot timestamp
   */
  @Input() latestCleanTimestamp: number;

  /**
   * Current snapshot timestamp
   */
  @Input() snapshotTimestampUsecs: number;

  ngOnChanges(): void {
    if (this.anomalousTimestamps?.includes(this.snapshotTimestampUsecs)) {
      /**
       * tagging a snapshot as anomolous when anomalous timestamps
       * include current snapshot
       */
      this.status = SnapshotIndicatorStatus.anomalous;
    } else if (this.latestCleanTimestamp === this.snapshotTimestampUsecs) {
      /**
       * tagging a snapshot as latest clean snapshot when current snapshot
       * timestamp equals lastest clean snapshot timestamp
       */
      this.status = SnapshotIndicatorStatus.latestClean;
    } else {
      // tagging a snapshot as clean snapshot by default
      this.status = SnapshotIndicatorStatus.clean;
    }
  }
}
