<cog-nested-value-property-filter *cogFilterDef="let filter;
    let params = params;
    quickFilter: true;
    key: property;
    label: label;
    filterType: 'valueProperty';
    position: position"
  [class.hidden]="hideFilter"
  [filterDefParams]="params"
  [filterValues]="filterOptions$ | async"
  [filter]="filter"
  [trackFilters]="true">
</cog-nested-value-property-filter>
