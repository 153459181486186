// COMPONENT: cSearchResults

(function(angular, undefined) {
    'use strict';

    var moduleName = 'C.search';
    var moduleDeps = ['smart-table', 'ngSanitize', 'C.filters'];
    var module;

    try {
        module = angular
            .module(moduleName);
    } catch (e) {
        module = angular
            .module(moduleName, moduleDeps);
    }
    module
        .directive('cSearchResults', DirectiveSearchResults);

    /**
     * @ngdoc directive
     * @name C.search.directive:cSearchResults
     * @description
     *   This directive adds the row selection behavior. Specifically, the
     *   `toggleRowSelect` method. Hypothetically, this should only be applied
     *   to repeating elements.
     *
     * @restrict 'AEC'
     * @element ANY
     * @scope
     * @example  See docs for cSearch directive
     */
    function DirectiveSearchResults(cSearchService) {
        var dirResults = {
            restrict: 'AEC',
            controller: controller,
            link: linkFn,
            scope: true
        };
        var config = {};
        var searchId;

        /** @ngInject */
        function controller($scope) {
            angular.extend($scope, {
                // $SCOPE METHODS
                toggleRowSelect: toggleRowSelect
            });

            /**
             * Toggles the selection of the given row
             *
             * @method     toggleRowSelect
             * @param      {Object}  row     The row to toggle selection on
             */
            function toggleRowSelect(row) {
                var checked = !!row._selected;
                cSearchService[checked ?
                  'selectRows' : 'deselectRows'](searchId, row);
            }
        }

        function linkFn(scope, elem, attrs) {
            searchId = scope.searchId || attrs.searchId || undefined;
            config = cSearchService.registerInstance(searchId);
            angular.extend(scope, {
                collection: config.collection,
                selectedRows: config.selectedRows,
                filters: config.filters
            });
        }

        return dirResults;
    }

})(angular);
