// Decorator:  'ui.router.tabs' Template override

;(function(angular, undefined) {
  'use strict';

  angular
    .module('ui.router.tabs')
    .config(Decorate);

  function Decorate($provide) {
    $provide.decorator('tabsDirective', function tabsDecorator($delegate, $transitions) {
      var directive = $delegate[0];

      directive.templateUrl = function tabsTemplateUrl(elem, attr) {
        // This maintains the custom template functionality of ui-router-tabs
        // directive but overrides the default template.
        return attr.templateUrl || 'app/global/c-tabs-new/c-tabs-new.html';
      };

      // This effectively replaces the link function for the original
      // implementation, as it was written for legacy uiRouter, and we need
      // to listen to transitions hooks instead of
      // $rootScope.$on('$stateChangeWhatever');
      directive.compile = function compileFn() {
        return function newLinkFn(scope, elem, attrs) {
          // Add transition listeners that will update the tabs. This directive
          // was written for legacy uiRouter (0.4.x) so its necessary to add
          // these so tabs get updated correctly.
          $transitions.onStart({}, scope.update_tabs);
          $transitions.onError({}, scope.update_tabs);
          $transitions.onSuccess({}, scope.update_tabs);
        }
      };

      return $delegate;
    });
  }

})(angular);
