<ng-container *ngIf="seriesData; else spinner">
  <ng-container *ngIf="hasSeriesData; else noDataFound">
    <div class="margin-top">
      <ng-container *ngFor="let metric of seriesData.metrics">
        <dg-ar-anomaly-chart
          *ngIf="!hiddenMetricSet.has(metric)"
          cogDataId="anomaly-details-{{ metric }}-chart"
          [seriesData]="metricSeries[metric]"
          [metric]="metric"
          (pointSelection)="pointSelection.emit($event)">
        </dg-ar-anomaly-chart>
      </ng-container>
    </div>
  </ng-container>
</ng-container>

<ng-template #noDataFound>
  <cog-blank-card message="{{ 'noTrendsToShow' | translate }}"></cog-blank-card>
</ng-template>

<ng-template #spinner>
  <cog-spinner></cog-spinner>
</ng-template>
