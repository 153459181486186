import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'coh-app-layout-notice',
  templateUrl: './app-layout-notice.component.html',
  styleUrls: ['./app-layout-notice.component.scss'],

  // Disable visual encapsulation as this component has to apply styles for the
  // entire app.
  encapsulation: ViewEncapsulation.None
})
export class AppLayoutNoticeComponent {
  /**
   * THe message to show in this notice.
   */
  @Input() message: string;
}
