import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { CohesityHelixModule } from '@cohesity/helix';
import { CohesitySharedFormsModule } from '@cohesity/shared-forms';
import { TranslateModule } from '@ngx-translate/core';
import { EditSiteDialogComponent } from './components';
import { DataSitesService } from './services';

@NgModule({
  imports: [
    CohesityHelixModule,
    CohesitySharedFormsModule,
    CommonModule,
    ReactiveFormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
    TranslateModule.forChild(),
  ],
  declarations: [EditSiteDialogComponent],
  providers: [DataSitesService],
  exports: [EditSiteDialogComponent],
})
export class IrisFeaturesDataSitesModule {}
