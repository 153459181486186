import { Injectable } from '@angular/core';
import { RpaasRegionInfo } from '@cohesity/api/v2';
import { Moment } from 'moment';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { NodeActivityStats, RpaasActivityStatSummary } from '../../models/dashboard.models';
import { RpaasStateService } from './rpaas-state.service';

@Injectable()
export class RpaasDashboardService {
  /**
   * Track the current value of the region filter
   */
  private readonly regionFilterSubject = new BehaviorSubject<RpaasRegionInfo[]>(null);

  /**
   * Track the current value of the date range filter
   */
  private readonly dateRangeFilterSubject = new BehaviorSubject<[number, number]>(null);

  /**
   * Track the current value of the region filter
   */
  readonly regionFilter$ = this.regionFilterSubject.asObservable();

  /**
   * Track the current value of the date range filter
   */
  readonly dateRangeFilter$ = this.dateRangeFilterSubject.asObservable();

  /**
   * The list of currently registered regions.
   */
  readonly regionList$ = this.rpaasState.rpaasRegions$.pipe(map(regions => regions.result));

  /**
   * Will be used to navigate to vault protection page whenever connected topology
   * node link is clicked
   */
  readonly gotoVaultProtection$ = new BehaviorSubject<any>(null);

  /**
   * Will be used to navigate to alerts page whenever disconnected topology
   * node link is clicked
   */
  readonly gotoAlerts$ = new BehaviorSubject<any>(null);

  constructor(private rpaasState: RpaasStateService) {}

  /**
   * Set the region filter. When set, all api calls will be filtered to these regions
   *
   * @param regionIds Region ids to filter by. If empty or undefined, this will return aggregated data for all regions
   */
  setRegionFilter(regionIds: RpaasRegionInfo[]) {
    this.regionFilterSubject.next(regionIds);
  }

  /**
   * Set the date filter. When set, all api calls will be filtered to within these dates
   *
   * @param startDate   The start date
   * @param endDate     The end date
   */
  setDateRangeFilter(startDate: Moment, endDate: Moment) {
    this.dateRangeFilterSubject.next([startDate?.valueOf() * 1000, endDate?.valueOf() * 1000]);
  }

  /**
   * Gets the activity stats to show for this node
   *
   * @param   activities Activities for a node from API.
   * @returns Activity stats
   */
  getStats(activities: RpaasActivityStatSummary[]): NodeActivityStats[] {
    const activityStats = (activities || []).reduce(
      (stats, activity) => {
        const { status, count } = activity;
        stats[status] += count;
        return stats;
      },
      {
        Failed: 0,
        Missed: 0,
        Accepted: 0,
        Canceling: 0,
        Finalizing: 0,
        OnHold: 0,
        Running: 0,
        Succeeded: 0,
        Canceled: 0,
        SucceededWithWarning: 0,
      }
    );

    return [
      {
        label: 'succeeded',
        icon: 'helix:status-success-alt!success',
        value: activityStats.Succeeded,
        status: 'Succeeded',
      },
      {
        label: 'warning',
        icon: 'helix:status-warning-alt!warning',
        value: activityStats.SucceededWithWarning,
        status: 'SucceededWithWarning',
      },
      {
        label: 'failed',
        icon: 'helix:status-error-alt!critical',
        value: activityStats.Failed + activityStats.Missed,
        status: 'Failed',
      },
      {
        label: 'running',
        icon: 'helix:status-in-progress-alt!info',
        value: activityStats.Accepted + activityStats.Canceling + activityStats.Finalizing + activityStats.Running,
        status: 'Running',
      },
    ];
  }
}
