import { SelectionModel } from '@angular/cdk/collections';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Optional,
  Output,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { DataFilterValue, KeyedSelectionModel } from '@cohesity/helix';
import { Controls, NgxSubFormRemapComponent, subformComponentProviders, takeUntilDestroyed } from 'ngx-sub-form';
import { debounceTime, filter, tap } from 'rxjs/operators';
import { DialogService } from 'src/app/core/services';
import { envGroups, Environment, FormSectionWithSummaryComponent } from 'src/app/shared';

import { AdvancedSearchOptions, GranularItemSearchParameters } from '../../model/advanced-search-options';
import { RestorePointSelection } from '../../model/restore-point-selection';
import { RestoreSearchResult } from '../../model/restore-search-result';

interface RestoreObjectSelectionForm {
  objects: RestorePointSelection[];
}

/**
 * The object search form combines the components for the search field, results, and selected objects into a single form
 * control that outputs a list of selected objects and snapshots.
 */
@Component({
  selector: 'coh-object-search-form',
  templateUrl: './object-search-form.component.html',
  styleUrls: ['./object-search-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: subformComponentProviders(ObjectSearchFormComponent),
  encapsulation: ViewEncapsulation.None
})
export class ObjectSearchFormComponent
  extends NgxSubFormRemapComponent<RestorePointSelection[], RestoreObjectSelectionForm>
  implements OnInit {
  /**
   * Template to render row details.
   */
  @Input() detailTemplate: TemplateRef<any>;

  /**
   * Whether the form should allow searching or not. If search is hidden, then the selection pane should take up the
   * entire screen.
   */
  @HostBinding('class.hide-search') @Input() hideSearch = false;

  /**
   * Flag to make the selection null if set to true.
   * This is used when we want to hide the radio buttons for the selections.
   */
  @Input() disableSelection = false;

  /**
   * Flag to show a loading spinner when the query is running.
   */
  @Input() searchPending = false;

  /**
   * The most recent set of search results.
   */
  @Input() searchResults: RestoreSearchResult[];

  /**
   * Holds Ids of sources selected from the source filter on the parent
   * component. This is passed to the advanced search dialog to add any source
   * related advanced filter.
   */
  @Input() basicFilters: DataFilterValue<any>[];

  /**
   * Placeholder text to show in the search form.
   */
  @Input() searchPlaceholderText: string;

  /**
   * If set, the text to use for the item selection label
   */
  @Input() selectedItemText: string;

  /**
   * Custom template that can be used to render the entire selection panel.
   */
  @Input() selectionListTemplate: TemplateRef<any>;

  /**
   * Custom template to render the selection details.
   */
  @Input() selectionTemplate: TemplateRef<any>;

  /**
   * Setting this to true will ignore default restore points and allow all restore point logic to be handled
   * manually.
   */
  @Input() ignoreDefaultRestorePoint = false;

  /**
   * Whether the search table supports multiple selections.
   */
  @Input() isMultipleSelection = true;

  /**
   * Whether the implicit objects array will be managed automatically or will
   * be managed by the component using the object search form.
   * Disabling the auto management feature is a temporary requirement for Imanis
   * connectors since there is no API support for returning objects backed up as
   * part of a protection run for now.
   * TODO(karan.sood): Remove this once API support is added for Imanis connectors.
   */
  @Input() autoManageImplicitObjects = true;

  /**
   * Advanced search options for granular recovery.
   */
  @Input() advancedSearchOptions: AdvancedSearchOptions;

  /**
   * Emits advanced filter selection when dialog box for the same is closed.
   */
  @Output() advancedSearchFiltersChanged = new EventEmitter<GranularItemSearchParameters>();

  /**
   * Event indicating that a search should be triggered.
   */
  @Output() queryChanged = new EventEmitter<string>();

  /**
   * Event indicating that a any advance filter should be removed.
   */
  @Output() clearAdvanceFilters = new EventEmitter();

  /**
   * Event indicating that an object has been selected. This can be used to set
   * a restore point selection for the object if it does not provide a default one.
   */
  @Output() selectObject = new EventEmitter<RestoreSearchResult>();

  /**
   * Event indicating that an object has been deselected. This can be used to manage
   * more complex selection scenarios, such as file recovery.
   */
  @Output() deselectObject = new EventEmitter<RestoreSearchResult>();

  /**
   * Output indicating an object snapshot edit has been initiated.
   */
  @Output() editObjectSnapshot = new EventEmitter<RestorePointSelection>();

  /**
   * The current search query.
   */
  query: string;

  /**
   * The table selection model holds the results of any selections made from the search table. Selections are persisted
   * even when the search results are changed.
   */
  selection: SelectionModel<RestoreSearchResult>;

  /**
   * Specifies the count of advanced filters selected for the filtering.
   */
  advancedFilterCount: number;

  /**
   * This is a list of any object ids which are included as part of another object's
   * selection such as a protection group. This is used to prevent selecting objects
   * which are already implicitly selected for recovery.
   */
  _implicitObjectIds: (number | string)[] = [];

  /**
   * Sets the implicit object Ids.
   */
  @Input() set implicitObjectIds(ids: (number | string)[]) {
    this._implicitObjectIds = ids;
    this.deselectDuplicateObjects();
  }

  /**
   * Get implicit object Ids.
   */
  get implicitObjectIds() {
    return this._implicitObjectIds;
  }

  /**
   * Triggers change detection whenever the from group changes.
   */
  onChange = () => this.cdr.detectChanges();

  constructor(
    private dialogService: DialogService,
    private cdr: ChangeDetectorRef,
    @Optional() private parentFormSection: FormSectionWithSummaryComponent) {
    super();
  }

  /**
   * Gets the default form value.
   *
   * @returns The default form value.
   */
  getDefaultValues(): Partial<RestoreObjectSelectionForm> | null {
    return {
      objects: [],
    };
  }

  ngOnInit() {
    this.selection = new KeyedSelectionModel(
      (object: any) => `${object.id}_${object.protectionGroupId}`,
      this.isMultipleSelection
    );

    // If the parent form section switches to edit mode and we only have one
    // object selection, we should show the snapshot dialog immediately, and
    // back out of the edit mode.
    if (this.parentFormSection) {
      this.parentFormSection.editing$.pipe(
        filter(editing => editing && this.hideSearch && this.formGroupValues?.objects?.length === 1),

        // There are a couple of things that set the editing field here. Manually calling detect
        // changes here helps prevent some flicker with the form going back and forth
        tap(() => {
          this.cdr.detectChanges();
          this.parentFormSection.setEditing(false);
          this.cdr.detectChanges();
        }),

        // This whole thing can fire a couple of times, so we debounce it to make sure we only
        // show the dialog once.
        debounceTime(5),
        takeUntilDestroyed(this)
      ).subscribe(() => {
        this.editObject(this.formGroupValues.objects[0]);
      });
    }

    this.selection.changed.pipe(takeUntilDestroyed(this)).subscribe(change => {
      const objectsForm = this.formGroupControls.objects;

      // Push new form controls into the array whenever one is added.
      change.added
        .filter(
          item => !objectsForm.controls.find(control => this.areRestoreObjectsEqual(control.value.objectInfo, item))
        )
        .forEach(item => {
          if (item.defaultRestorePointSelection && !this.ignoreDefaultRestorePoint) {
            this.addRestorePointSelection(item.defaultRestorePointSelection);
          }
          this.selectObject.emit(item);
        });

      // Remove controls from the array when they are cleared.
      change.removed.forEach(item => {
        const controlIndex = objectsForm.controls.findIndex(control =>
          this.areRestoreObjectsEqual(control.value.objectInfo, item)
        );
        if (controlIndex !== -1) {
          objectsForm.removeAt(controlIndex);
        }

        if (this.autoManageImplicitObjects) {
          this._implicitObjectIds = this.getImplicitObjectIds();
        }

        this.deselectObject.emit(item);
      });
      this.cdr.detectChanges();
    });
  }

  /**
   * Adds or updates the form's selection. If the object is already in the selection,
   * this will update the selection with the new snapshot information. Otherwise, it will
   * add the selection to the list.
   *
   * @param   selection   The object selection to add or update.
   */
  addRestorePointSelection(selection: RestorePointSelection) {
    this.addRestorePointSelections([selection]);
  }

  /**
   * Adds or updates the form's selection. If the objects are already in the selection,
   * this will update the selection with the new snapshot information. Otherwise, it will
   * add the selections to the list.
   *
   * @param   selections   The object selections to add or update.
   */
  addRestorePointSelections(selections: RestorePointSelection[]) {
    (selections || []).forEach(selection => {
      const existingIndex = (this.formGroupValues.objects || []).findIndex(object =>
        this.areRestoreObjectsEqual(object.objectInfo, selection.objectInfo)
      );

      if (existingIndex !== -1) {
        this.formGroupControls.objects.at(existingIndex).setValue(selection);
      } else {
        this.formGroupControls.objects.push(new UntypedFormControl(selection));
      }
    });

    const newSelections = (selections || [])
      .map(selection => selection.objectInfo)
      .filter(objectInfo => !this.selection.isSelected(objectInfo));

    if (newSelections.length) {
      // A new item was selected from the table.
      this.selection.select(...newSelections);
    }

    if (envGroups.nas.includes(selections[0]?.objectInfo.environment as Environment)) {
      // In nas, the selected objects follow a different data structure, hence
      // what works for some adapters will not work for nas. The following code
      // works to deselect an object from the lister when
      // an item is removed from the selection detail on the right of the table
      const ids = (selections || [])
      .map(selection => selection.objectIds).flat();

      const newDeselections = this.selection.selected.filter(
        // If the selection (KeyedSelectionModel) does not contain an
        // id from selections param and the id is not a number i.e.
        // it is a FileSearchResult, then add it to the list of items
        // to be deselected.
        item => !ids.includes(item.id) && typeof item.id !== 'number');
      if (newDeselections.length) {
        this.selection.deselect(...newDeselections);
      }
    }

    if (this.autoManageImplicitObjects) {
      this.implicitObjectIds = this.getImplicitObjectIds();
    }
    this.cdr.detectChanges();
  }

  /**
   * Get a list of items that are protected as part of another object selection. This typically applies to
   * objects in a protection group selection.
   *
   * @returns   A list of non-directly selected object ids.
   */
  getImplicitObjectIds(): (number | string)[] {
    const objectIds: (number | string)[] = [];
    (this.formGroupValues.objects || [])
      // Find selections that contain a list of other objects that could potentially be selected separately.
      .filter(selection => selection.objectInfo.id !== selection.objectIds[0])

      // Add each of the ids to our set.
      .forEach(selection => objectIds.push(...selection.objectIds));
    return objectIds;
  }

  /**
   * When a grouped object is selected, check for objects in that group which were already selected and
   * deselect thjem.
   */
  deselectDuplicateObjects() {
    // Find objects that may be selected by a group already.
    const duplicates = this.selection &&
      this.selection.selected &&
      this.selection.selected.length &&
      this.selection.selected.filter(object => this.implicitObjectIds.includes(object.id));

    // Only call if there are actually duplicates, calling with an empty array will still fire selection
    // change events.
    if (duplicates && duplicates.length) {
      this.selection.deselect(...duplicates);
    }
  }

  /**
   * Emit the edit object snapshot event whenever the edit button is selected.
   *
   * @param   object   The object being to edit the snapshot for.
   */
  editObject(object: RestorePointSelection) {
    this.editObjectSnapshot.emit(object);
  }

  /**
   * Remove an object from the selection.
   *
   * @param   object   The object to remove.
   */
  removeObject(object: RestoreSearchResult) {
    this.selection.deselect(object);
  }

  /**
   * Handles the search query changing, emits and events and saves the current value.
   *
   * @param   query   The new search query.
   */
  onQueryChanged(query: string) {
    this.query = query;
    this.advancedFilterCount = 0;
    if (this.clearAdvanceFilters) {
      this.clearAdvanceFilters.emit();
    }
    this.queryChanged.emit(query);
    this.cdr.detectChanges();
  }

  /**
   * Opens advanced search filter dialog box.
   */
  showAdvancedSearch() {
    const { dialogComponent, searchOptions, searchType } = this.advancedSearchOptions;
    const dialogData = {
      searchOptions,
      searchType,
      query: this.query,
      basicFilters: this.basicFilters,
    };

    this.dialogService
      .showDialog(dialogComponent, dialogData, { disableClose: false })
      .pipe(takeUntilDestroyed(this))
      .subscribe((advancedFilterParams: any) => {
        if (advancedFilterParams) {
          this.advancedFilterCount = 0;
          Object.keys(advancedFilterParams).forEach(key => {
            const filterValue = advancedFilterParams[key];
            if (!filterValue ||
              (Array.isArray(filterValue) && !filterValue.length) ||
              Object.values(filterValue)?.some(prop => prop === null)
            ) {
              // Don't count empty filters.
              return;
            }
            ++this.advancedFilterCount;
          });

          // Reset query string when there are advanced filters set.
          if (this.advancedFilterCount) {
            this.query = '';
          }
          this.advancedSearchFiltersChanged.emit(advancedFilterParams);
        }
      });
  }

  /**
   * Get the form controls needed for the sub form.
   *
   * @returns   The form controls object.
   */
  protected getFormControls(): Controls<RestoreObjectSelectionForm> {
    return {
      objects: new UntypedFormArray([]),
    };
  }

  /**
   * Converts from the model type (simple array) to the form type (array wrapped in an object).
   *
   * @param   obj   The model type
   * @returns The form type
   */
  protected transformToFormGroup(objectAndTargets: RestorePointSelection[] | null): RestoreObjectSelectionForm | null {
    const objects = !objectAndTargets ? [] : objectAndTargets;

    this.selection.clear();
    this.selection.select(...objects.map(object => object.objectInfo));

    return { objects };
  }

  /**
   * Converts from the form type (array wrapped in an object) to the model type (simple array).
   *
   * @param   obj   The form type
   * @returns The model type
   */
  protected transformFromFormGroup(formValue: RestoreObjectSelectionForm): RestorePointSelection[] | null {
    return formValue.objects;
  }

  /**
   * Returns whether two RestoreSearchResult objects are equal by comparing relevant fields from both the objects.
   *
   * @param object1 The first object.
   * @param object2 The second object.
   *
   * @returns `true` if both supplied objects are equal, `false` otherwise.
   */
  private areRestoreObjectsEqual(object1: RestoreSearchResult, object2: RestoreSearchResult) {
    return object1.id === object2.id && object1.protectionGroupId === object2.protectionGroupId;
  }
}
