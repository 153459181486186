import { AppModuleConfig, StateAccessContext, StateAccessMap } from 'src/app/app-module.config';

const changePasswordAccess =
  (ctx: StateAccessContext) => ctx.FEATURE_FLAGS.enableForcePasswordChange;

const mfaAccess =
  (ctx: StateAccessContext) => ctx.FEATURE_FLAGS.mfaLoginEnabled || ctx.FEATURE_FLAGS.mfaAdpSupport;

export const LoginConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'login-ng.**',
      url: '^/sign-in',
      loadChildren: () => import('./login.module').then(m => m.LoginModule),
    },
    {
      name: 'logout-ng.**',
      url: '^/sign-out',
      loadChildren: () => import('./login.module').then(m => m.LoginModule),
    },
    {
      name: 'change-password.**',
      url: '^/change-password',
      loadChildren: () => import('./login.module').then(m => m.LoginModule),
    },
    {
      name: 'mfa.**',
      url: '^/mfa',
      loadChildren: () => import('./login.module').then(m => m.LoginModule),
    },
  ],
  getStateAccessMap(): StateAccessMap {
    return {
      'logout-ng': () => true,
      'login-ng': () => true,
      'change-password': changePasswordAccess,
      'mfa.setup': mfaAccess,
      'mfa.verify': mfaAccess,
    };
  }
};
