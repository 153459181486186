import { Component } from '@angular/core';

import { BaseProtectionBuilderComponent } from '../../base-protection-builder/base-protection-builder.component';


/**
 * Default value for skip on errors
 */
export const defaultSkipOnErrorsValue = true;

@Component({
  selector: 'coh-settings-list-skip-file-on-errors',
  templateUrl: './settings-list-skip-file-on-errors.component.html',
})
export class SettingsListSkipFileOnErrorsComponent
  extends BaseProtectionBuilderComponent<boolean, any> {
  /**
   * Default value for Skip File on Errors for NAS Data Protection.
   */
  _value = defaultSkipOnErrorsValue;

  /**
   * Form control init.
   */
  initFormControl() {
    if (this.protectionGroup?.id) {
      this.value = Boolean(this.protectionGroup.continueOnError);
      this.formControl.setValue(this.value);
    }
  }
}
