import { DataTreeNode } from './data-tree.model';
import { DataTreeControl } from './data-tree-control';

/**
 * Utility function that can be used to filter a flat tree list of data tree nodes.
 */
export namespace DataTreeFilterUtils {
  /**
   * This is a helper function for running a search against a data tree to find and exclude specific branches.
   * It can be used to filter a VM source tree to show a folder or physical hierarchy.
   *
   * @param    nodeList          The node list to filter.
   * @param    shouldExclude     A callback to determine whether to include a node and it's children.
   * @erturn   The filtered list of items.
   */
  export function hierarchyExcludeFilter<T extends DataTreeNode<any>>(
    nodeList: T[],
    shouldExclude: (node: T) => boolean
  ) {
    const filteredNodes: T[] = [];
    const excludeLevelStack = [];
    nodeList.forEach(node => {
      if (shouldExclude(node)) {
        // Exclude all child levels
        excludeLevelStack.push(node.level + 1);
      } else if (excludeLevelStack.length && node.level < excludeLevelStack[excludeLevelStack.length - 1]) {
        // If we move up a level, remove excluded levels from the stack until we're back to the current node's level
        while (node.level < excludeLevelStack[excludeLevelStack.length - 1]) {
          excludeLevelStack.pop();
        }
      }
      if (!excludeLevelStack.length) {
        filteredNodes.push(node);
      }
    });
    return filteredNodes;
  }

  /**
   * This is a helper function for running a search against a data tree. As nodes are found that match
   * the predicate, their parents will automatically be included in the filtered list.
   *
   * @param    nodeList                     The node list to filter.
   * @param    treeControl                  The tree control is used to expand explicitly matched nodes
   * @param    matchNode                    A callback function to execute for each node.
   * @param    includeMatchedDescendents    Whether to include the descendants of a matched node. This is
   *                                        true by default, but can be disabled in cases where a node can
   *                                        be exlcuded even its parent is found.
   * @param    expandFoundNodes             If true, this expands all of the matched nodes.
   * @return   The filtered list of items (and their parents) that match the search.
   */
  export function searchFilter<T extends DataTreeNode<any>>(
    nodeList: T[],
    treeControl: DataTreeControl<T>,
    checkNode: (T) => boolean,
    includeMatchedDescendents = true,
    expandFoundNodes = true
  ): T[] {
    const filteredList = [];

    // Implicit parents are the parents of a matched node. They must be included in the results.
    const implicitParents = new Set<number>();

    // Matched nodes are either explict matches or children or explicit matches
    const matchedNodes = new Set<number>();

    // The parent stack tracks the hierearchy of the current node.
    const parentStack: {
      index: number;
      level: number;
    }[] = [];

    nodeList.forEach((node, index) => {
      let parent = parentStack.length && parentStack[parentStack.length - 1];
      if (index > 0 && node.level > nodeList[index - 1].level) {
        // If this node's level is higher than the previous one, we are in a new branch
        // Push the parent (previous node) onto the stack
        parentStack.push({
          index: index - 1,
          level: nodeList[index - 1].level,
        });
        parent = parentStack[parentStack.length - 1];
      } else {
        // This node is at the same level or lower than the last parent
        while (parent && node.level <= parent.level) {
          parentStack.pop();
          parent = parentStack.length && parentStack[parentStack.length - 1];
        }
      }

      if (checkNode(node)) {
        // Determine which parents haven't already been added yet
        // Use a reverse for loop and break as soon an already-added parent is found.
        const parentsToAdd = [];
        for (let pIndex = parentStack.length - 1; pIndex >= 0; pIndex--) {
          if (implicitParents.has(parentStack[pIndex].index) || matchedNodes.has(parentStack[pIndex].index)) {
            break;
          }
          parentsToAdd.unshift(parentStack[pIndex]);
        }

        // Add them to the output, and to the implicit parent list
        parentsToAdd.forEach(p => {
          implicitParents.add(p.index);
          filteredList.push(nodeList[p.index]);
        });

        // Update the matched node list and at it to the filtered list
        matchedNodes.add(index);
        filteredList.push(node);
      } else if (matchedNodes.has(parent.index) && includeMatchedDescendents) {
        // If this node has a matched node in it's parent hierachy, it counts as matched as well.
        filteredList.push(node);
        matchedNodes.add(index);
      }
    });

    if (expandFoundNodes) {
      treeControl.expansionModel.select(...filteredList);
    }
    return filteredList;
  }
}
