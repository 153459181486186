<cog-spinner size="sm" *ngIf="isLoading"></cog-spinner>
<div class="flex-row-xs vertical-align" *ngIf="!isLoading">
  <cog-donut-chart
    [customChartOptions]="customChartOptions"
    colorSetClass="progress-donut-chart-colors"
    [seriesData]="seriesData">
  </cog-donut-chart>
  <div *ngIf="!hideLabel" class="chart-label">{{ percentValue + '%' }}</div>
  <ng-content></ng-content>
</div>
