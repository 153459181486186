import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { CohesityHelixModule } from '@cohesity/helix';
import { HumanizeFromNumbersPipeModule } from '@cohesity/utils';
import { TranslateModule } from '@ngx-translate/core';

import { ChangeTypeStatsComponent } from './change-type-stats.component';

const COMPONENTS = [ChangeTypeStatsComponent];

@NgModule({
  imports: [CohesityHelixModule, CommonModule, HumanizeFromNumbersPipeModule, TranslateModule],
  exports: [...COMPONENTS],
  declarations: [...COMPONENTS],
})
export class ChangeTypeStatsModule {}
