/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityKeplerApiConfiguration as __Configuration } from '../cohesity-kepler-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { SitesResponse } from '../models/sites-response';
import { Site } from '../models/site';
import { CreateSiteRequest } from '../models/create-site-request';
import { UpdateSiteRequest } from '../models/update-site-request';
@Injectable({
  providedIn: 'root',
})
class SitesService extends __BaseService {
  static readonly GetSitesPath = '/site-continuity/sites';
  static readonly CreateSitePath = '/site-continuity/sites';
  static readonly GetSiteByIdPath = '/site-continuity/sites/{id}';
  static readonly UpdateSiteByIdPath = '/site-continuity/sites/{id}';
  static readonly DeleteSiteByIdPath = '/site-continuity/sites/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get a list of sites
   *
   * Returns a list of available sites based on the defined filter criteria.
   * @param params The `SitesService.GetSitesParams` containing the following parameters:
   *
   * - `types`: Specifies the type of the site to filter.
   *
   * - `siteClusterIdentifiers`: Filter the sites response using siteClusterIdentifier.
   *
   * - `names`: Specifies the name of the site to filter.
   *
   * - `ids`: Filter the sites response using given id.
   *
   * @return Specifies the response of a successful get sites operation.
   */
  GetSitesResponse(params: SitesService.GetSitesParams): __Observable<__StrictHttpResponse<SitesResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.types || []).forEach(val => {if (val != null) __params = __params.append('types', val.toString())});
    (params.siteClusterIdentifiers || []).forEach(val => {if (val != null) __params = __params.append('siteClusterIdentifiers', val.toString())});
    (params.names || []).forEach(val => {if (val != null) __params = __params.append('names', val.toString())});
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/site-continuity/sites`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SitesResponse>;
      })
    );
  }
  /**
   * Get a list of sites
   *
   * Returns a list of available sites based on the defined filter criteria.
   * @param params The `SitesService.GetSitesParams` containing the following parameters:
   *
   * - `types`: Specifies the type of the site to filter.
   *
   * - `siteClusterIdentifiers`: Filter the sites response using siteClusterIdentifier.
   *
   * - `names`: Specifies the name of the site to filter.
   *
   * - `ids`: Filter the sites response using given id.
   *
   * @return Specifies the response of a successful get sites operation.
   */
  GetSites(params: SitesService.GetSitesParams): __Observable<SitesResponse> {
    return this.GetSitesResponse(params).pipe(
      __map(_r => _r.body as SitesResponse)
    );
  }

  /**
   * Create a new site
   *
   * Creates a new site with a collection of clusters.
   * @param body Specifies the parameters to create a site.
   * @return Specifies the response of a successful site creation.
   */
  CreateSiteResponse(body: CreateSiteRequest): __Observable<__StrictHttpResponse<Site>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/site-continuity/sites`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Site>;
      })
    );
  }
  /**
   * Create a new site
   *
   * Creates a new site with a collection of clusters.
   * @param body Specifies the parameters to create a site.
   * @return Specifies the response of a successful site creation.
   */
  CreateSite(body: CreateSiteRequest): __Observable<Site> {
    return this.CreateSiteResponse(body).pipe(
      __map(_r => _r.body as Site)
    );
  }

  /**
   * Get a site by ID
   *
   * Gets a site's information using it's ID.
   * @param id Specifies the ID of the site.
   * @return Specifies the response of a successful get site operation.
   */
  GetSiteByIdResponse(id: string): __Observable<__StrictHttpResponse<Site>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/site-continuity/sites/${encodeURIComponent(id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Site>;
      })
    );
  }
  /**
   * Get a site by ID
   *
   * Gets a site's information using it's ID.
   * @param id Specifies the ID of the site.
   * @return Specifies the response of a successful get site operation.
   */
  GetSiteById(id: string): __Observable<Site> {
    return this.GetSiteByIdResponse(id).pipe(
      __map(_r => _r.body as Site)
    );
  }

  /**
   * Update a site by ID
   *
   * Updates a site's information using it's ID.
   * @param params The `SitesService.UpdateSiteByIdParams` containing the following parameters:
   *
   * - `id`: Specifies the ID of the site.
   *
   * - `body`: Specifies the parameters to update a site.
   *
   * @return Specifies the response of a successful site update.
   */
  UpdateSiteByIdResponse(params: SitesService.UpdateSiteByIdParams): __Observable<__StrictHttpResponse<Site>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/site-continuity/sites/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Site>;
      })
    );
  }
  /**
   * Update a site by ID
   *
   * Updates a site's information using it's ID.
   * @param params The `SitesService.UpdateSiteByIdParams` containing the following parameters:
   *
   * - `id`: Specifies the ID of the site.
   *
   * - `body`: Specifies the parameters to update a site.
   *
   * @return Specifies the response of a successful site update.
   */
  UpdateSiteById(params: SitesService.UpdateSiteByIdParams): __Observable<Site> {
    return this.UpdateSiteByIdResponse(params).pipe(
      __map(_r => _r.body as Site)
    );
  }

  /**
   * Delete a site by ID
   *
   * Deletes a site's information using it's ID.
   * @param id Specifies the ID of the site.
   */
  DeleteSiteByIdResponse(id: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/site-continuity/sites/${encodeURIComponent(id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete a site by ID
   *
   * Deletes a site's information using it's ID.
   * @param id Specifies the ID of the site.
   */
  DeleteSiteById(id: string): __Observable<null> {
    return this.DeleteSiteByIdResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module SitesService {

  /**
   * Parameters for GetSites
   */
  export interface GetSitesParams {

    /**
     * Specifies the type of the site to filter.
     */
    types?: Array<'OnPrem' | 'AWS' | 'Saas'>;

    /**
     * Filter the sites response using siteClusterIdentifier.
     */
    siteClusterIdentifiers?: Array<number>;

    /**
     * Specifies the name of the site to filter.
     */
    names?: Array<string>;

    /**
     * Filter the sites response using given id.
     */
    ids?: Array<string>;
  }

  /**
   * Parameters for UpdateSiteById
   */
  export interface UpdateSiteByIdParams {

    /**
     * Specifies the ID of the site.
     */
    id: string;

    /**
     * Specifies the parameters to update a site.
     */
    body: UpdateSiteRequest;
  }
}

export { SitesService }
