// CPULSE - PROGRESS MONITOR
//
// This directive can be utilized two different ways.
// 1)   Specify and endpoint to automatically start polling.
//      This method is designed to show the progress for a single task.
// 2)   Manually pass in values to update progress.
//      This method is designed to update multiple instances from a single API poll,
//      for example to show the progress of multiple sub tasks.

;(function(angular, undefined) {
  'use strict';

  angular.module('C.pulse', [])
    .directive('cPulse', cPulseDirectiveFn);

  function cPulseDirectiveFn($rootScope, $http, $q, $timeout,
    PollTaskStatus, Routes, DateTimeService, cUtils) {
    var cPulseDirective = {
      scope: {
        // {Boolean}  Show the Progress Bar, even when percentage data
        // is 0 or not available
        alwaysShowActiveBar: '@?',
        // {String}  Hex Value
        barColorOverride: '@?',
        // {Boolean}  Show/Hide bar label text, Defaults to false
        barLabelEnabled: '@?',
        // {String}  Text label to overlay progress bar
        barLabelText: '@?',
        // {Function}  Reference to parent controller function to execute after
        // data is updated. Receives progress data(), or status string when
        // completed.
        callback: '=?',
        // {String}  API endpoint for polling method
        endpoint: '@?',
        // {Int}  Interval of milliseconds between API calls, default is 30000,
        // minimum is 1000
        interval: '@?',
        // {Int}  How many times to retry after fail, defaults to 10
        maxRetries: '@?',
        // {Int}  [0-100] Percentage width of the progress bar
        percentage: '@?',
        // {String}  [ready, active, completed, error]
        pollingStatus: '@?',
        // @type {object}  - Output of the pulse response
        pulseData: '=?',
        // {Boolean}   In absence of percentFinished, show Event Message instead
        // of generic running message.
        showEventMessage: '@?',
        // {String}  size class will be applied, but (TODO:) styles have not
        // been written.
        size: '@?',
        // {String}  Class to be applied to status label container
        statusLabelClass: '@?',
        // {Boolean}  Show/Hide status label text, Defaults to false
        statusLabelEnabled: '@?',
        // {String}  Text label displayed under progress bar
        statusLabelText: '@?',
        // {String}  'timeRemaining', 'timeElapsed', 'capacity'
        type: '@?',
        // {String} Text displayed on error when provided
        barLabelTextOnError: '@?'
      },
      restrict: 'AE',
      templateUrl: 'app/global/cPulse/cPulse.html',
      link: cPulseLinkFn,
    };

    function cPulseLinkFn(scope, elem, attrs) {

      /**
       * The cPulse data model. To be updated by
       * PollTaskStatus.getTask.
       *
       * @type {Object}
       */
      var pulseData = {
        progress: {},
        subTaskVec: {},
        taskPath: null,
      };

      // Counter for failed GET calls. If this exceeds maxRetries,
      // cPulse will give up.
      var failCount = 0;

      /**
       * Status flag to determine state of current polling instance
       * @type {String}
       */
      scope.pollingStatus = 'active';

      scope.text = $rootScope.text;

      /**
       * Activate this directive.
       *
       * @method   activate
       */
      function activate() {
        if (scope.type === null) {
          scope.type = 'timeRemaining';
        }
        if (!scope.maxRetries) {
          scope.maxRetries = 10;
        }
        if (!scope.interval) {
          scope.interval = 30000;
        }

        // Set up the CSS object
        updatePulseCSS();

        // Endpoint was specified, so let's kick off the poller
        // If no end point was specified,
        // we will update cPulse values manually
        // based on scope params
        if (scope.endpoint && scope.endpoint !== null) {
          getPulse();
        }
      }

      /**
       * Updates the css for this pulse instance.
       *
       * @method   updatePulseCSS
       */
      function updatePulseCSS() {
        // Ensure percent is an integer (and strip any trailing string
        // characters)
        var percent = parseInt(scope.percentage || 0, 10);

        // Update cPulse styles
        scope.cPulseStyles = {
          'width': percent + '%',
          'bgColor': scope.barColorOverride
        };
      }

      /**
       * Determines if the poller can be terminated by evaluating
       * scope.pollingStatus.
       *
       * @method   canTerminatePoll
       * @return   {Boolean}   True if poll can be terminated
       */
      function canTerminatePoll() {
        return (scope.pollingStatus !== 'active');
      }

      /**
       * AJAX Call to get C-Pulse Data.
       *
       * @method   getPulse
       */
      function getPulse() {
        scope.pollingStatus = 'active';
        PollTaskStatus.getTask(scope.endpoint, scope.interval, scope.maxRetries, canTerminatePoll).then(
          function successPulse(r) {
            pulseData = angular.extend({}, r[r.length - 1].taskVec[0]);
            scope.pulseData = pulseData;
            renderPulseData();
            if (pulseData._pollingStatus === 'error') {
              scope.pollingStatus = 'error';
            } else if (pulseData._pollingStatus === 'completed') {
              scope.pollingStatus = 'completed';
            }
          },
          function errorPulse(r) {
            scope.pulseData = r;
            scope.pollingStatus = 'error';
          },
          function notifyPulse(r) {
            pulseData = angular.extend({}, r[r.length - 1].taskVec[0]);
            scope.pulseData = pulseData;
            renderPulseData();
          }
        ).finally(
          function afterPoll() {
            if (scope.callback && ['canceled', 'completed'].includes(
              scope.pollingStatus)) {
              scope.callback(scope.pollingStatus);
            }
          }
        );
      }

      /**
       * Renders cPulse Data in Time Remaining format.
       *
       * @method   renderPulseDataTimeRemaining
       * @param    {Object}   data   cPulseData
       */
      function renderPulseDataTimeRemaining(data) {
        if (data.expectedTimeRemainingSecs) {
          scope.statusLabelText = (data.expectedTimeRemainingSecs > 0) ?
            [DateTimeService.secsToTime(data.expectedTimeRemainingSecs).time,
            scope.text['cPulse.remaining']].join(' ') :
            '';
        }
        if (data.percentFinished) {
          scope.percentage = data.percentFinished || scope.percentage || 0;
          scope.barLabelText = [cUtils.round(data.percentFinished), '% ',
            scope.text['cPulse.completed']].join('');
        } else {
          scope.barLabelText = ['0% ', scope.text['cPulse.completed']].join('');
        }
        scope.eventMessage = (data.eventVec && data.eventVec[0].eventMsg) ?
          data.eventVec[0].eventMsg :
          '';
      }

      /**
       * Renders cPulse Data in Time Elapsed format.
       *
       * @method   renderPulseDataTimeElapsed
       * @param    {Object}   data   cPulseData
       */
      function renderPulseDataTimeElapsed(data) {
        var startTimeMsecs;
        var nowSecs = new Date().getTime() / 1000;
        if (data.startTimeSecs) {
          scope.statusLabelText =
            DateTimeService.usecsToTime(data.startTimeSecs + nowSecs).time +
            ' ' + scope.text['cPulse.elapsed'];
        }
        if (data.percentFinished) {
          scope.percentage = data.percentFinished || scope.percentage || 0;
          scope.barLabelText = cUtils.round(data.percentFinished) + '% ' +
            scope.text['cPulse.completed'];
        } else {
          scope.barLabelText = '0% ' + scope.text['cPulse.completed'];
        }
      }

      /**
       * Renders cPulse Data in Size/Capacity Format.
       *
       * @method   renderPulseDataSize
       * @param    {Object}   data   cPulseData
       */
      function renderPulseDataSize(data) {
        // PLACEHOLDER FUNCTION
        // TODO: WRITE THIS WHEN USECASE FOR POLLING CAPACITY AND DATA MODELS ARE DEFINED
      }

      /**
       * Render default cPulse complete state.
       *
       * @method    renderPulseDataComplete
       */
      function renderPulseDataComplete() {
        scope.pollingStatus = 'completed';
        scope.percentage = 100;
        scope.barLabelText = '100% ' + scope.text['cPulse.completed'];
        scope.statusLabelEnabled = false;
      }

      /**
       * Render default cPulse error state.
       *
       * @method    renderPulseDataError
       */
      function renderPulseDataError() {
        scope.pollingStatus = 'error';
        scope.barColorOverride = '#be202e';
        scope.barLabelText = scope.barLabelTextOnError ?
          scope.barLabelTextOnError : scope.text.error;
        scope.statusLabelEnabled = false;
      }

      /**
       * Parse the cPulse API response and choose the most appropriate rendering
       * method.
       *
       * @method   renderPulseData
       */
      function renderPulseData() {

        switch(_.get(pulseData, 'progress.status.type')) {

          case 0:
            // Task is still running
            switch (scope.type) {
              case 'timeRemaining':
                renderPulseDataTimeRemaining(pulseData.progress);
                break;

              case 'timeElapsed':
                renderPulseDataTimeElapsed(pulseData.progress);
                break;

              case 'size':
                renderPulseDataSize(pulseData.progress);
                break;

              default:
                renderPulseDataTimeRemaining(pulseData.progress);
            }
            break;

          case 1:
            // Task is a success
            renderPulseDataComplete();
            break;

          case 4:
            // Task is canceled
            scope.pollingStatus = 'canceled';
            break;

          default:
            // If none of the above status matches or the switch expression is
            // undefined, then its an error case.
            renderPulseDataError();
        }

        // Expose the updated data to the parent controller via a callback, if
        // provided.
        if (scope.callback) {
          scope.callback(pulseData);
        }

        // Update the CSS
        updatePulseCSS();
      }

      // Activate
      activate();

      // When passing in percentage from parent scope, we must observe
      // scope.percentage for changes and force a CSS Update.
      attrs.$observe('percentage', function percentageObserver(percent) {
        scope.percentage = percent;
        updatePulseCSS();
      });

    }

    return cPulseDirective;
  }

})(angular);
