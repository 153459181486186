import { Environment, EnvItems } from '@cohesity/iris-shared-constants';

export const nasSources: EnvItems[] = [
  {
    environment: Environment.kIsilon,
  },
  {
    environment: Environment.kNetapp,
  },
  {
    environment: Environment.kGenericNas,
  },
  {
    environment: Environment.kFlashBlade,
  },
  {
    environment: Environment.kGPFS,
  },
  {
    environment: Environment.kElastifile,
  }
];

/**
 * Netapp source types.
 */
export enum NasSourceTypes {
  /**
   * Cluster.
   */
  kCluster = 'kCluster',

  /**
   * VServer or VM.
   */
  kVServer = 'kVServer',
}


/**
 * allow or deny IPs for backup flow traffic.
 */
export enum FilterIps {
  /**
   * whitelist IPs for NAS.
   */
  kWhiteList = 'kWhiteList',

  /**
   * blacklist IPs for NAS.
   */
  kBlackList = 'kBlackList',
}

/**
 * Modes for Generic NAS registration.
 */
export enum NasModes {
  /**
   * NAS in NFS3 mode
   */
  kNfs3 = 'kNfs3',

  /**
   * NAS in NFS4.1 mode.
   */
  kNfs4_1 = 'kNfs4_1',

  /**
   * NAS in SMB mode.
   */
  kCifs1 = 'kCifs1',
}

/**
 * List of NAS types which are not bifrost aware
 */
export const NonBifrsostAwareNasEnvs: Environment[] = [
  /**
   * GPFS NAS
   */
  Environment.kGPFS,
  /**
   * Pure storage Flash blade NAS
   */
  Environment.kFlashBlade,
  /**
   * Elastifile NAS
   */
  Environment.kElastifile
];
