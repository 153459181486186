import { Component, ViewEncapsulation } from '@angular/core';

/**
 * Show glance bar which could contain multiple stat lists.
 *
 * @example
 *   <coh-glance-bar></coh-glance-bar>
 */
@Component({
  selector: 'coh-glance-bar',
  styleUrls: ['./glance-bar.component.scss'],
  template: `<div class="glance-bar"><ng-content></ng-content></div>`,
  encapsulation: ViewEncapsulation.None
})
export class GlanceBarComponent {
}
