import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { userTimezone } from '@cohesity/utils';
import dayjs from 'dayjs/esm';
import objectSupport from 'dayjs/esm/plugin/objectSupport';

dayjs.extend(objectSupport);

/**
 * Data model for dropdown list.
 */
export interface LabelValuePair {
  /**
   * Display value
   */
  label: string;

  /**
   * Value used to make API call.
   */
  value: string | number;
}

/**
 * List of time period.
 */
export const everyList: LabelValuePair[] = [
  {
    label: 'day',
    value: 'day'
  },
  {
    label: 'week',
    value: 'week'
  },
  {
    label: 'month',
    value: 'month'
  }
];

/**
 * Preset Time of the day list.
 */
export const timeList: LabelValuePair[] = [...Array(24).keys()].map(hour => ({
  label: dayjs({hour, minute: 0}).format('h:mma'),
  value: hour
}));

/**
 * Data model for schedule and time form.
 */
export interface ScheduleAndTimeForm {
  /**
   * Every day, week or month.
   */
  every: LabelValuePair;

  /**
   * Day of week. Can be multiple.
   */
  dayOfWeek: string[] | string;

  /**
   * Day of month. Can be multiple.
   */
  dayOfMonth: LabelValuePair[] | LabelValuePair;

  /**
   * Time of the day.
   */
  at: LabelValuePair;

  /**
   * Timezone for the time.
   */
  timezone: string;
}

/**
 * This component allows user to input schedule and time.
 */
@Component({
  selector: 'coh-schedule-and-time-selector',
  styleUrls: ['./schedule-and-time-selector.component.scss'],
  templateUrl: './schedule-and-time-selector.component.html'
})
export class ScheduleAndTimeSelectorComponent implements OnInit {
  /**
   * Parent of the form.
   */
  @Input() parent: FormGroup;

  /**
   * Whether to allow multiple or single selection for the component.
   */
  @Input() allowMultiple = true;

  /**
   * Occupy full width if true.
   */
  @Input() fullWidth = false;

  /**
   * Initial value for the controls.
   */
  private _initialValue: ScheduleAndTimeForm;

  @Input() set initialValue(initialValue: ScheduleAndTimeForm) {
    this._initialValue = initialValue;
    if (initialValue) {
      this.form.controls.every.setValue(initialValue.every);
      this.form.controls.at.setValue(initialValue.at);
      this.form.controls.timezone.setValue(initialValue.timezone);
      if (initialValue.dayOfWeek) {
        this.form.controls.dayOfWeek.setValue(initialValue.dayOfWeek as any);
      }
      if (initialValue.dayOfMonth) {
        this.form.controls.dayOfMonth.setValue(initialValue.dayOfMonth as any);
      }
      this.form.updateValueAndValidity();
    }
  }

  get initialValue(): ScheduleAndTimeForm {
    return this._initialValue;
  }

  /**
   * Form group for the schedule.
   */
  form = new FormGroup({
    every: new FormControl(null, Validators.required),
    dayOfWeek: new FormControl(this.allowMultiple ? [] : null, null),
    dayOfMonth: new FormControl(this.allowMultiple ? [] : null, null),
    at: new FormControl(null, Validators.required),
    timezone: new FormControl(userTimezone, Validators.required),
  });

  /**
   * Every day, week or month options.
   */
  everyList = everyList;

  /**
   * Time of day by the hour options.
   */
  timeList = timeList;

  ngOnInit() {
    this.parent?.setControl('schedule', this.form);
    this.form.controls.every.valueChanges.subscribe(everyValue => {
      if (everyValue.value === 'day') {
        this.form.controls.dayOfWeek.setValidators(null);
        this.form.controls.dayOfMonth.setValidators(null);
      } else if (everyValue.value === 'week') {
        this.form.controls.dayOfWeek.setValidators(Validators.required);
        this.form.controls.dayOfMonth.setValidators(null);
      } else if (everyValue.value === 'month') {
        this.form.controls.dayOfWeek.setValidators(null);
        this.form.controls.dayOfMonth.setValidators(Validators.required);
      }
      this.form.controls.dayOfWeek.updateValueAndValidity();
      this.form.controls.dayOfMonth.updateValueAndValidity();
    });
  }
}
