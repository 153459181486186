// Service: License Service
import { ONE_GiB_BYTE_VALUE } from "../../app/shared/constants/chart-color.constants";

(function (angular, undefined) {
  'use strict';

  angular
    .module('C.licenseServerService', [])
    .service('LicenseServerService', licenseServerServiceFn);

  function licenseServerServiceFn(_, $rootScope, $http, API,
    NgRemoteClusterService, SlideModalService, UpgradeClusterService,
    ClusterService, $q, $injector) {

    return {
      checkClassifiedUser: checkClassifiedUser,
      downloadAuditReport: downloadAuditReport,
      downloadLicenseFile: downloadLicenseFile,
      getLicenseData: getLicenseData,
      generateLicense: generateLicense,
      uploadKey: uploadKey,
      downloadLicenseKeyFile: downloadLicenseKeyFile,
      clusterClaimInstantSyncup: clusterClaimInstantSyncup,
      openUploadModal: openUploadModal,
      openUploadLicensePageModal: openUploadLicensePageModal,
      openGenerateLicenseModal: openGenerateLicenseModal,
      getLicenseOverUsageInfo: getLicenseOverUsageInfo,
      isLicensingSkipped: isLicensingSkipped,
      isClusterSelected: isClusterSelected
    };

    /**
     * This function checks if any cluster is selected within scope. If a cluster is selected,
     * it will return true; otherwise, it will return false.
     *
     * @returns {boolean}
     */
    function isClusterSelected() {
      const ctx = $injector.get('NgIrisContextService')?.irisContext;
      return ctx?.selectedScope?.clusterId && ctx.selectedScope.clusterId !== 1;
    }

    /**
     * function to obtain license data, when license page loads
     *
     * @method  getLicenseData
     * @return  {Promise}  Promise to resolved with license data
     */
    function getLicenseData() {
      var getHeliosLicenseDataFn;
      var clusterIdToNameMaping = {};
      // On cluster, fetch data from nexus
      if (isClusterSelected()) {
        return $http({
          method: 'get',
          url: API.private('nexus/license/account_usage'),
        }).then(
          /**
           *
           * @param {Object} response
           *
           * {
           *    "isTrial": false,
           *    "trialExpiration": 0,
           *    "usage": {
           *      "4132847013241063": [{
           *         "featureName": {String} ,
           *         "currentUsageGiB":{Integer},
           *         "numVm":{Integer}
           *      }],
           *    },
           *    "licensedUsage": [{
           *       "featureName": {String},
           *       "capacityGiB": {Integer},
           *       "expiry": {Integer}
           *    }]
           * }
           */
          function onDataRecieve(response) {
            return _formatSingleClusterData(response.data);
          }
        );
      }

      // On helios, fetch data from minos
      getHeliosLicenseDataFn = $http({
        method: 'get',
        url: API.mcmPublic('licensing/account/usage'),
        headers: {
          'content-type': 'application/json',
        },
      });

      return $q.all({
        remoteClusters: UpgradeClusterService.getMcmClustersForUpgrade(),
        licenseData: getHeliosLicenseDataFn,
      }).then(function (response) {
        response.remoteClusters.map(function clusterIdNamePair(cluster) {
          clusterIdToNameMaping[cluster.clusterId] = cluster.clusterName;
        });

        return _formatMultiClusterData(response.licenseData.data, clusterIdToNameMaping);
      });
    }

    /**
     * function to check wheather the user is classified or not
     *
     * @method checkClassifiedUser
     *
     * @returns {Object} promise object when api completes,
     *
     */
    function checkClassifiedUser() {
      var url = isClusterSelected()
        ? API.private('nexus/account/is_classified')
        : API.mcm(
            ['minos/licensing/v1/account/is_classified?accountId=', $rootScope.user.salesforceAccount.accountId].join(
              ''
            )
          );

      return $http({
        method: 'get',
        url: url,
      }).then(function (response) {
        return response.data;
      });
    }

    /**
     * Function will format the response data for single cluster
     * into desired format. Cluster Api is sending data to GiB. we are
     * converting it to bytes so that we don't have to maintain 2 units in
     * components conditionally.
     *
     * @method  _formatSingleClusterData
     * @param   {Object}  licenseData   the licenseData object
     * @return  {Object}
     */
    function _formatSingleClusterData(licenseData) {
      var clusterId = $rootScope.clusterInfo.id;
      var clusterName = $rootScope.clusterInfo.name;
      var featureLicenseRelativeUsage = [];
      var localData = {};
      var formatedData;

      // Adding count of cluster as 1 as single cluster is selected here.
      var numOfRemoteCluster = { numOfRemoteCluster: 1 };

      licenseData.licensedUsage.map(function mapLicenseUsage(feature) {
        var licenseOverUsage = getLicenseOverUsageInfo(feature.featureName,
          licenseData.featureOverusage);
        var currentFeature = localData[feature.featureName];

        if (currentFeature) {
          var newCapacity = _getTotalUsage(
            currentFeature.capacity, getGiBInBytes(feature.capacityGiB) || feature.numVm);
          currentFeature.capacity = newCapacity;
          currentFeature.licenseOverUsage = licenseOverUsage;
          currentFeature.licenseTypeCapacity[feature.licenseType] =
            (currentFeature.licenseTypeCapacity[feature.licenseType] || 0) + getGiBInBytes(feature.capacityGiB);
          Object.assign(currentFeature, _parseLicensingDataAndEntitlement(licenseData, feature.featureName));
          if (feature) {
            currentFeature.licenses.push(_getSKUDetailsInfo(feature));
          }
        } else {
          var license = [];
          if (feature) {
            license.push(_getSKUDetailsInfo(feature));
          }
          localData[feature.featureName] = {
            considerVm: feature.numVm !== 0,
            featureName: feature.featureName,
            isTrial: licenseData.isTrial,
            capacity: getGiBInBytes(feature.capacityGiB) || feature.numVm,
            usage: 0,
            licenses: license,
            licenseType: feature.licenseType,
            licenseOverUsage: licenseOverUsage,
            licenseTypeCapacity: {}
          };
          Object.assign(localData[feature.featureName],
            _parseLicensingDataAndEntitlement(licenseData, feature.featureName));
          localData[feature.featureName].licenseTypeCapacity
          [feature.licenseType] = getGiBInBytes(feature.capacityGiB) || feature.numVm;
        }
      });

      // if no usage data for selected cluster, let usage for every feature
      // remain zero
      if (licenseData.usage[clusterId]) {
        licenseData.usage[clusterId].map(function (licenseClusterRelation) {
          if (!localData[licenseClusterRelation.featureName]) {
            localData[licenseClusterRelation.featureName] = {
              considerVm: licenseClusterRelation.numVm !== 0,
              featureName: licenseClusterRelation.featureName,
              isTrial: licenseData.isTrial,
              last30DaysClusterData: licenseData.last30DaysUsage,
              last12MonthClusterData: licenseData.last12MonthsAvgUsage,
              capacity: 0,
              numVmCapacity: 0,
              licenseTypeCapacity: { Term: -1 },
              licenseType: undefined,
              usage: getGiBInBytes(licenseClusterRelation.currentUsageGiB) ||
                licenseClusterRelation.numVm,
              licenses: [],
              numOfRemoteCluster,
              _remoteClusterData: [{
                considerVm: licenseClusterRelation.numVm !== 0,
                lastUpdateTime: licenseData.lastUpdateTime,
                usage: 0,
                capacity: getGiBInBytes(licenseClusterRelation.capacityGiB) || licenseClusterRelation.numVm,
                clusters: [{
                  clusterId: clusterId,
                  displayValue: clusterName || clusterId,
                  currentUsageBytes: getGiBInBytes(licenseClusterRelation.currentUsageGiB) || licenseClusterRelation.numVm
                }],
              }],
            };
            Object.assign(localData[licenseClusterRelation.featureName],
              _parseLicensingDataAndEntitlement(licenseData, licenseClusterRelation.featureName));
          } else {
            localData[licenseClusterRelation.featureName].usage
              += getGiBInBytes(licenseClusterRelation.currentUsageBytes) ||
              licenseClusterRelation.numVm;
              localData[licenseClusterRelation.featureName]['_remoteClusterData'] = [{
                considerVm: licenseClusterRelation.numVm !== 0,
                lastUpdateTime: licenseData.lastUpdateTime,
                usage: getGiBInBytes(licenseClusterRelation.currentUsageGiB) || licenseClusterRelation.numVm,
                capacity: getGiBInBytes(licenseClusterRelation.capacityGiB) || licenseClusterRelation.numVm,
                clusters: [{
                  clusterId: clusterId,
                  displayValue: clusterName || clusterId,
                  currentUsageBytes: getGiBInBytes(licenseClusterRelation.currentUsageGiB) || licenseClusterRelation.numVm
                }],
              }];
          }
        });
      }

      Object.keys(localData).map(function (feature) {
        // convert whatever in GiB into kb
        convertLicensingDataAndEntitlementGiBIntoBytes(localData, feature);
        featureLicenseRelativeUsage.push(localData[feature]);
      });
      formatedData = _formatDataForDisplay(
        _filterOutNoLicenseNoUsageFeatures(featureLicenseRelativeUsage));
      formatedData.lastUpdateTime = licenseData.lastUpdateTime;
      // helios licensing feature is internal and hence filtering it out
      formatedData.widgets = formatedData.widgets.filter(res =>
        res.bindings.data !== '$ctrl.data.helios');
      return formatedData;
    }

    /**
     * Function will return the Capacity & expiry of the particular feature.
     * capacity is of GiB which we are converting into bytes as we are doing
     * it for cluster API.
     *
     * @param {Object} feature - Entire feature Object.
     */
    function _getSKUDetailsInfo(feature) {
      if (feature.licenseType === "Consumption") {
        return {
          expiry: feature.expiryTime,
          capacity: 'PPU',
        };
      } else if (['Perpetual','Perpetual-Unlimited'].includes(feature.licenseType)) {
        return {
          expiry: 'Perpetual',
          capacity: feature.capacityBytes || feature.numVm,
        };
      } else {
        return {
          expiry: feature.expiryTime,
          capacity: getGiBInBytes(feature.capacityGiB) || feature.numVm,
        };
      }
    }

    /**
     * Funtion to filter out the cluster Ids
     *
     * @method  filterClusterIds
     * @param   {Object}   licenseData        The licenseData object
     * @param   {string}   dataUsageObject    Object name of the data usage/entitlement
     *
     * @return  {Object}  returns the filtered cluster ids.
     */
    function filterClusterIds(licenseData, dataUsageObject) {
      const usageClusterList = Object.keys(licenseData.usage);
      return Object.keys(licenseData[dataUsageObject]).filter(cluster => usageClusterList.includes(cluster));
    }

    /**
     * Function will format the response data to all data usage and entitlement
     *
     * @method  _formatDataAndEntitlement
     * @param   {Object}   licenseData        The licenseData object
     * @param   {string}   featureName        Name of the feature
     * @param   {string}   dataUsageObject    Object name of the data usage/entitlement
     * @param   {string}   featureObjectName  Object name fo the particular feature.
     *
     * @return  {Object}  returns the data usge/entitlement for each.
     *
     *
     */
    function _formatDataAndEntitlement(licenseData, featureName, dataUsageObject, featureObjectName) {
      if (licenseData[dataUsageObject]) {
        if(dataUsageObject === 'last30DaysEntitlement' || dataUsageObject === 'last12MonthsAvgEntitlement') {
          let clusterIdList = filterClusterIds(licenseData, dataUsageObject);
          let initialSumData = [];
          for (let i = 0; i <= clusterIdList.length; i++) {
            let allClusterUsageAndEntitlement = licenseData[dataUsageObject][clusterIdList[i]];

            if(allClusterUsageAndEntitlement) {
              allClusterUsageAndEntitlement.forEach(clusterData => {
                if (clusterData && clusterData.featureName === featureName) {
                  initialSumData = clusterData[featureObjectName].map((num, idx) => {
                    let tempdata = initialSumData[idx] || 0;
                    return num > tempdata ? num : tempdata;
                  })
                }
              })
            }
          }
          return initialSumData;
        } else {
          // Aggregate the all cluster data for helios.
          if (!isClusterSelected()) {
            let clusterIdList = filterClusterIds(licenseData, dataUsageObject);
            let initialSumData = [];

            for (let i = 0; i <= clusterIdList.length; i++) {
              let allClusterUsageAndEntitlement = licenseData[dataUsageObject][clusterIdList[i]];

              if (allClusterUsageAndEntitlement) {
                for (let j = 0; j <= allClusterUsageAndEntitlement.length; j++) {

                  if (allClusterUsageAndEntitlement[j]
                    && allClusterUsageAndEntitlement[j].featureName === featureName) {

                    initialSumData = allClusterUsageAndEntitlement[j][featureObjectName]
                      .map((num, idx) => {
                        let tempdata = initialSumData[idx] || 0;
                        return num + tempdata;
                      });
                  }
                }
              }
            }
            return initialSumData;
          } else {
            // For Specific cluster side.
            let featureUsageAndEntitlement = licenseData[dataUsageObject][$rootScope.clusterInfo.id];

            if (featureUsageAndEntitlement) {
              for (let i = 0; i <= featureUsageAndEntitlement.length; i++) {
                if (featureUsageAndEntitlement[i]
                  && featureUsageAndEntitlement[i].featureName === featureName) {
                  return featureUsageAndEntitlement[i][featureObjectName];
                }
              }
            }

            return [];
          }
        }
      }
    }

    /**
     * Function will parse the response and return all the data usage.
     *
     * @method  _parseLicensingDataAndEntitlement
     * @param   {Object}   licenseData        The licenseData object
     * @param   {string}   featureName        Name of the feature
     *
     * @return  {Object}  returns the data usge/entitlement for each.
     *
     *
     */
    function _parseLicensingDataAndEntitlement(licenseData, featureName) {
      return {
        last12MonthsAvgEntitlement: _formatDataAndEntitlement(
          licenseData,
          featureName,
          'last12MonthsAvgEntitlement',
          isClusterSelected() ? 'monthlyAvgEntitlement' : 'monthlyAvgEntitlementBytes'
        ),
        last12MonthsAvgUsage: _formatDataAndEntitlement(
          licenseData,
          featureName,
          'last12MonthsAvgUsage',
          isClusterSelected() ? 'monthlyAvgUsage' : 'monthlyAvgUsageBytes'
        ),
        last30DaysEntitlement: _formatDataAndEntitlement(
          licenseData,
          featureName,
          'last30DaysEntitlement',
          isClusterSelected() ? 'dailyEntitlement' : 'dailyEntitlementBytes'
        ),
        last30DaysUsage: _formatDataAndEntitlement(
          licenseData,
          featureName,
          'last30DaysUsage',
          isClusterSelected() ? 'dailyUsage' : 'dailyUsageBytes'
        )
      };

    }

    /**
     * Function will format the response data for single cluster
     * into desired format
     *
     * @method  _formatMultiClusterData
     * @param   {Object}  licenseData The licenseData object
     * @param   {Object}  clusterIdToNameMaping A cluster id to name key value
     *                                          pair
     * @return  {Object}  a configuration object required by widget layout
     *                    component to display widgets
     *
     *
     */
    function _formatMultiClusterData(licenseData, clusterIdToNameMaping) {
      var featureLicenseRelativeUsage = [];
      var localData = {};
      var formatedData;

      /**
       * when showUsageInHelios will be false then usage and overusage will be
       * null. to handle repetitive code for null we are handling at this place.
       */
      licenseData.usage = licenseData.usage || {};
      licenseData.overusage = licenseData.overusage || {}


      licenseData.licensedUsage.map(function mapLicenseUsage(feature) {
        var licenseOverUsage = licenseData.overusage[feature.featureName];
        var currentFeature = localData[feature.featureName];
        if (currentFeature) {
          var newCapacity = _getTotalUsage(
            currentFeature.capacity, feature.capacityBytes || feature.numVm);

          currentFeature._remoteClusterData[0].capacity =
            currentFeature.capacity = newCapacity;

          currentFeature.licenseTypeCapacity[feature.licenseType] =
            (currentFeature.licenseTypeCapacity[feature.licenseType] || 0) + feature.capacityBytes;
          currentFeature.licenseOverUsage = licenseOverUsage;

          Object.assign(currentFeature, _parseLicensingDataAndEntitlement(licenseData, feature.featureName));

          // creating a new entry for every license.
          currentFeature.licenses.push({
            expiry: feature.expiryTime,
            capacity: feature.capacityBytes || feature.numVm,
            licenseOverUsage: licenseOverUsage,
            productDescription: feature.productDescription,
            productInfo: feature.productInfo,
            licenseType: feature.licenseType
          });
        } else {
          localData[feature.featureName] = {
            considerVm: feature.numVm !== 0,
            featureName: feature.featureName,
            isTrial: licenseData.isTrial,
            last30DaysClusterData: licenseData.last30DaysUsage,
            last12MonthClusterData: licenseData.last12MonthsAvgUsage,
            capacity: feature.capacityBytes || feature.numVm,
            usage: 0,
            licenses: [{
              expiry: feature.expiryTime,
              capacity: feature.capacityBytes || feature.numVm,
              numVmCapacity: feature.numVm,
              productDescription: feature.productDescription,
              productInfo: feature.productInfo,
              licenseType: feature.licenseType
            }],
            _remoteClusterData: [{
              considerVm: feature.numVm !== 0,
              lastUpdateTime: licenseData.lastUpdateTime,
              usage: 0,
              capacity: feature.capacityBytes || feature.numVm,
              clusters: [],
              licenseTypeCapacity: {},
            }],
            licenseType: feature.licenseType,
            licenseTypeCapacity: {},
            licenseOverUsage: licenseOverUsage
          };
          Object.assign(localData[feature.featureName],
            _parseLicensingDataAndEntitlement(licenseData, feature.featureName));
          localData[feature.featureName].licenseTypeCapacity
          [feature.licenseType] =
            localData[feature.featureName]._remoteClusterData[0]
              .licenseTypeCapacity[feature.licenseType] =
            feature.capacityBytes || feature.numVm;
        }
      });

      Object.keys(licenseData.usage).map(function (clusterId) {
        licenseData.usage[clusterId].map(function (licenseClusterRelation) {
          if (!localData[licenseClusterRelation.featureName]) {
            var licenseOverUsage = licenseData.overusage[licenseClusterRelation.featureName];
            localData[licenseClusterRelation.featureName] = {
              considerVm: licenseClusterRelation.numVm !== 0,
              featureName: licenseClusterRelation.featureName,
              isTrial: licenseData.isTrial,
              last30DaysClusterData: licenseData.last30DaysUsage,
              last12MonthClusterData: licenseData.last12MonthsAvgUsage,
              capacity: 0,
              licenseTypeCapacity: { Term: -1 },
              usage: licenseClusterRelation.currentUsageBytes ||
                licenseClusterRelation.numVm,
              licenseOverUsage: licenseOverUsage,
              licenses: [],
              licenseType: undefined,
              _remoteClusterData: [{
                considerVm: licenseClusterRelation.numVm !== 0,
                usage: licenseClusterRelation.currentUsageBytes ||
                  licenseClusterRelation.numVm,
                lastUpdateTime: licenseData.lastUpdateTime,
                capacity: 0,
                licenseTypeCapacity: { Term: -1 },
                clusters: [{
                  clusterId: clusterId,
                  displayValue: clusterIdToNameMaping[clusterId] || clusterId,
                  currentUsageBytes: licenseClusterRelation.currentUsageBytes ||
                    licenseClusterRelation.numVm,
                }],
              }],
            };
            Object.assign(localData[licenseClusterRelation.featureName],
              _parseLicensingDataAndEntitlement(licenseData, licenseClusterRelation.featureName));
          } else {
            localData[licenseClusterRelation.featureName].usage
              = localData[licenseClusterRelation.featureName]
                ._remoteClusterData[0].usage = _getTotalUsage(
                  localData[licenseClusterRelation.featureName]
                    ._remoteClusterData[0].usage, licenseClusterRelation
                      .currentUsageBytes || licenseClusterRelation.numVm);

            if (!localData[licenseClusterRelation.featureName]._remoteClusterData[0]
              .clusters.find(cluster => cluster.clusterId === clusterId)) {
              localData[licenseClusterRelation.featureName]._remoteClusterData[0]
                .clusters.push({
                  clusterId: clusterId,
                  displayValue: clusterIdToNameMaping[clusterId] || clusterId,
                  currentUsageBytes: licenseClusterRelation.currentUsageBytes ||
                    licenseClusterRelation.numVm
                });
            }
            Object.assign(localData[licenseClusterRelation.featureName],
              _parseLicensingDataAndEntitlement(licenseData, licenseClusterRelation.featureName));
          }
        });
      });

      Object.keys(localData).map(function (feature) {
        featureLicenseRelativeUsage.push(localData[feature]);
      });

      Object.keys(licenseData.usage).map(function (clusterId) {
        Object.keys(localData).map(function (feature) {
          if (localData[feature] && !localData[feature]._remoteClusterData[0].clusters.find(cluster => cluster.clusterId === clusterId)) {
            localData[feature]._remoteClusterData[0].clusters.push({
              clusterId: clusterId,
              displayValue: clusterIdToNameMaping[clusterId] || clusterId,
              currentUsageBytes: 0
            });
          }
        });
      });

      if (!$rootScope.FEATURE_FLAGS.mixedOnpremLicensingImprovements) {
        Object.keys(localData).forEach(key => {
          if (key.includes('Service') && localData[key].capacity > 0) {
            if (localData['dataPlatform']) {
              localData['dataPlatform'].usage = Math.min(
                localData['dataPlatform'].usage,
                localData['dataPlatform'].capacity
              );
            }
            if (localData['cloudArchive']) {
              localData['cloudArchive'].usage = Math.min(
                localData['cloudArchive'].usage,
                localData['cloudArchive'].capacity
              );
            }
            if (localData['cloudSpin']) {
              localData['cloudSpin'].usage = Math.min(localData['cloudSpin'].usage, localData['cloudSpin'].capacity);
            }
          } else if (key === 'smartFiles' && localData[key].capacity > 0) {
            if (localData['dataPlatform']) {
              localData['dataPlatform'].usage = Math.min(
                localData['dataPlatform'].usage,
                localData['dataPlatform'].capacity
              );
            }
            if (localData['cloudArchive']) {
              localData['cloudArchive'].usage = Math.min(
                localData['cloudArchive'].usage,
                localData['cloudArchive'].capacity
              );
            }
          } else if (key === 'dataProtectReplica' && localData[key].capacity > 0) {
            if (localData['dataPlatform']) {
              localData['dataPlatform'].usage = Math.min(
                localData['dataPlatform'].usage,
                localData['dataPlatform'].capacity
              );
            }
          }
        });
      }

      formatedData = _formatDataForDisplay(
        _filterOutNoLicenseNoUsageFeatures(featureLicenseRelativeUsage));
      formatedData.lastUpdateTime = licenseData.lastUpdateTime;
      // helios licensing feature is internal and hence filtering it out
      formatedData.widgets = formatedData.widgets.filter(res =>
        res.bindings.data !== '$ctrl.data.helios');
      formatedData.showUsageInHelios = licenseData.showUsageInHelios || false;

      return formatedData;
    }

    /**
     * Function to format data which can be used by widget layout component
     *
     * @method _formatDataForDisplay
     * @param {featureLicenseUsageData}
     *
     *  [{
     *    featureName: {String}
     *    usage: {Integer}
     *    licenses: {Object} [{
     *      capacity: {Integer}
     *      expiry: {Object}
     *    }]
     *    capacity: {Integer}
     *  }]
     *
     * @return {Object}
     *    {
     *      widgets: [{
     *        component: "licenseUsageWidget",
     *        posX: 1,
     *        posY: 1,
     *        bindings: {
     *          type: 'dataProtect',
     *          data: '$ctrl.data.dataProtect'
     *        },
     *      }],
     *      widgetData: {
     *        featureName: {
     *          featureName: {String}
     *          usage: {Integer}
     *          licenses: {Object} [{
     *            capacity: {Integer}
     *            expiry: {Object}
     *          }]
     *          capacity: {Integer}
     *        }
     *      }
     *    }
     */
    function _formatDataForDisplay(featureLicenseUsageData) {
      var widgets = [];
      var widgetAllData = [];
      var widgetServiceData = [];
      var widgetData = {};
      var posX = 1;
      var posY = 1;
      var dataProtect = false;
      var dataProtectService = false;

      featureLicenseUsageData.map(function sortWidgetData(data) {
        var hasServiceData = data.featureName.includes("Service");

        if (hasServiceData) {
          widgetServiceData.push(data);
        } else {
          widgetAllData.push(data);
        }

        // Find string containing dataProtect.
        if (data.featureName === 'dataProtect') {
          dataProtect = true;
        }

        // Find string containing dataProtectService.
        if (data.featureName === 'dataProtectService') {
          dataProtectService = true;
        }
      });

      // Sorted widget service Data and all other widget data separately.
      widgetServiceData = [].concat(widgetServiceData).concat(widgetAllData);

      if (dataProtect && dataProtectService) {
        widgetServiceData.forEach(function checkLegacyPlan(response) {
          if (response.featureName === 'dataProtect') {
            response.isLegacyPlan = true;
          }
        });
      }

      widgetServiceData.forEach(function assignWidgetData(feature) {
        var widget = {
          component: 'licenseUsageWidget',
          bindings: {},
          cConfig: {},
        };
        widgetData[feature.featureName] = feature;
        widget.bindings.data = '$ctrl.data.' + feature.featureName;
        widget.posX = posX % 2 === 1 ? 1 : 2;
        posX++;
        widget.posY = parseInt(posY);
        posY += 0.5;
        widgets.push(widget);
      });

      return { widgets: widgets, widgetData: widgetData };
    }

    /**
     * Function to get to total usage till now
     *
     * @method   _getTotalUsage
     * @param    {number}   currentUsageBytes     current usage
     * @param    {number}   valueToAdd       new usage that need to be added
     * @return   {number}  returns the total usage till now
    */

    function _getTotalUsage(currentUsageBytes, valueToAdd) {
      return valueToAdd <= 0 ? currentUsageBytes : (currentUsageBytes <= 0 ? valueToAdd :
        currentUsageBytes + valueToAdd);
    }

    /**
     * function to filter our those features, which has Zero usage and Zero
     * licenses
     *
     * @method   _filterOutNoLicenseNoUsageFeatures
     * @param   {Object}  featureLicenseRelativeUsage
     * @returns {Object}  modifies featureLicenseRelativeUsage and returns
     *                    same object
     */
    function _filterOutNoLicenseNoUsageFeatures(featureLicenseRelativeUsage) {
      featureLicenseRelativeUsage = featureLicenseRelativeUsage
        .filter(function checkForZeroUsageAndNoLicense(feature) {
          if (feature.licenses.length === 0 && (feature.usage === 0 ||
            feature.usage < 0)) {
            return false;
          }
          return true;
        })
      return featureLicenseRelativeUsage;
    }

    /**
     * function to generate License file
     *
     * @method generateLicense
     *
     * @param  {String}   clusterId     The id of cluster for which license is
     *                                  to be generated
     * @param  {Boolean}  isClassified  is the license for a classified user
     */
    function generateLicense(clusterId, isClassified = false) {
      var url = isClassified ?
        'minos/licensing/v1/cluster/generate_license_key' :
        'licenses';

      return $http({
        url: API.mcm(url),
        method: 'POST',
        data: {
          accountId: $rootScope.user.salesforceAccount.accountId,
          clusterId: parseInt(clusterId),
        },
      }).then(function (response) {
        return response.data;
      });
    }

    /**
     * function will open a new tab with a url to download audit report.
     * it will be downloadable link and browser will start downloading
     * an audit report
     * @method downloadAuditReport
     */
    function downloadAuditReport() {
      const ctx = $injector.get('NgIrisContextService')?.irisContext;
      const auditAPI =
        isClusterSelected() && $rootScope.basicClusterInfo.mcmMode
          ? `nexus/license/audit?clusterId=${ctx.selectedScope.clusterId}`
          : 'nexus/license/audit';
      window.open(API.private(auditAPI));
    }

    /**
     *
     * function will open a new tab with a url to download license file.
     * it will be downloadable link and browser will start downloading
     * an audit report
     *
     * @method downloadLicenseFile
     *
     * @param {string} clusterId  The id of cluster for which license is to be
     *                            downloaded
     */
    function downloadLicenseFile(clusterId) {
      window.open(API.mcm(
        'licenses?accountId=' + $rootScope.user.salesforceAccount.accountId + '&clusterId=' + clusterId
      ));
    }

    /**
     * function to activate licensing for classified users using license key
     *
     * @method uploadKey
     *
     * @param {String} key  license key obtained from helios server
     */
    function uploadKey(key) {
      return $http({
        method: 'post',
        url: API.private('nexus/license/upload_key'),
        data: { 'licenseString': key },
      });
    }

    /**
     * API to have instant sync-up after cluster claim process
     *
     * @method clusterClaimInstantSyncup
     */
    function clusterClaimInstantSyncup() {
      $http({
        method: 'post',
        url: API.private('nexus/license/cluster_claimed'),
      });
    }

    /**
     * function to download license Key in text format
     *
     * @method downloadLicenseKeyFile
     *
     * @param {String} clusterId id of cluster
     * @param {String} key       license key string
     */
    function downloadLicenseKeyFile(clusterId, key) {
      var file = new Blob([key], { type: "text/plain;charset=utf-8" });
      var a = document.createElement("a");
      var url = URL.createObjectURL(file);

      a.href = url;
      a.download = "License-key-" + clusterId + ".txt";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }

    /**
     * function to open upload modal.
     *
     * @method openUploadModal
     *
     */
    function openUploadModal() {
      var modalConfig = {
        size: 'xl',
        autoHeight: true,
        resolve: {
          actionButtonKey: false,
          closeButtonKey: false,
          innerComponent: 'uploadDownloadModalWrapper',
          titleKey: 'uploadLicenseFile',
          bindings: {
            uploadComponent: { cb: openUploadLicensePageModal },
          }
        },
      };

      return SlideModalService.newModal(modalConfig);
    }

    /**
     * opens up upload modal which decides what the user is supposed to
     * upload depending on whether the user is on helios or cluster
     *
     * @method  openUploadLicensePageModal
     * @return  {Promise}  Promise to resolve the modal when closed
     */
    function openUploadLicensePageModal() {
      var modalConfig = {
        size: 'md',
        autoHeight: true,
        windowTopClass: 'license-page-modal',
        resolve: {
          actionButtonKey: false,
          closeButtonKey: false,
          innerComponent: 'uploadDownloadModal',
        },
      };
      return SlideModalService.newModal(modalConfig);
    }

    /**
     * opens up Generate License modal
     * It will generate license file/key depending on the type of user
     *
     * @method  openGenerateLicenseModal
     * @return  {Promise}  Promise to resolve the modal when closed
     */
    function openGenerateLicenseModal() {
      var modalConfig = {
        size: 'md',
        autoHeight: true,
        windowTopClass: 'license-page-modal',
        resolve: {
          actionButtonKey: false,
          closeButtonKey: false,
          innerComponent: 'generateLicenseModal',
        },
      };
      return SlideModalService.newModal(modalConfig);
    }

    /**
     * @method isLicensingSkipped.
     * @return {boolean}
     */
    function isLicensingSkipped() {
      return ClusterService.clusterInfo.licenseState.state === 'kSkipped';
    }

    /**
     * Renders the overusage object in license data.
     *
     * @method getLicenseOverUsageInfo
     * @param   {string}  featureName   the license feature name
     * @param   {Object} featureOverUsageObj the overusage object.
     * @return  {Object}
     */
    function getLicenseOverUsageInfo(featureName, featureOverUsageObj) {
      var licenseOverUsage = {};

      if (featureOverUsageObj) {
        for (var i = 0; i < featureOverUsageObj.length; i++) {
          if (featureOverUsageObj[i].featureName === featureName) {
            licenseOverUsage = featureOverUsageObj[i];
            if (!('overusedBytes' in licenseOverUsage)) {
              licenseOverUsage.overusedBytes = getGiBInBytes(licenseOverUsage.overusedGiB);
            }
            break;
          }
        }
      }
      return licenseOverUsage;
    }
  }

  function getGiBInBytes(data) {
    return data * ONE_GiB_BYTE_VALUE
  }

  function convertLicensingDataAndEntitlementGiBIntoBytes(localData, feature) {
    localData[feature]['last12MonthsAvgUsage'] = localData[feature]['last12MonthsAvgUsage'].map(val => getGiBInBytes(val));
    localData[feature]['last12MonthsAvgEntitlement'].forEach(val => val = getGiBInBytes(val));
    localData[feature]['last30DaysUsage'] = localData[feature]['last30DaysUsage'].map(val => getGiBInBytes(val));
    localData[feature]['last30DaysEntitlement'].forEach(val => val = getGiBInBytes(val));
  }
})(angular);
