import { UpdateAlertStateArgs } from '@cohesity/api/alert-server';
import {
  AffectedFile,
  FileChangeType,
  Incidence,
  IncidenceId,
  PatternId,
  PatternSensitivityCount,
  Policy,
  Range,
  ScanRun,
  SensitivityRunDetails,
  ShieldType
} from '@cohesity/api/argus';
import { TagList } from '@cohesity/api/helios-metadata';
import { McmClusterLoc } from '@cohesity/api/private';
import { Alert } from '@cohesity/api/v1';
import {
  ArchivalCopy,
  CommonRecoverObjectSnapshotParams,
  CreateRecoveryRequest,
  RecoverVmwareSnapshotParams,
  Snapshot,
  SnapshotTag,
  Vault
} from '@cohesity/api/v2';
import { ClusterInfo } from '@cohesity/data-govern/shared';
import { NavItem } from '@cohesity/helix';
import { Environment } from '@cohesity/iris-shared-constants';
import { Timeframe } from '@cohesity/utils';
import { Chart, SeriesMapbubbleDataOptions } from 'highcharts';

/**
 *  This file contains interfaces being used in security dashboard.
 */

/**
 * Snapshot tagging action type
 */
export type SnapshotTagActionType = 'applyTag' | 'removeTag';

/**
 * Affected files actions
 */
export enum AffectedFileAction {
  downloadFile = 'downloadFile',
  recoverFile = 'recoverFile',
}

/**
 * Enum of all supported Metrics
 * Update this enum to add / remove support for metric.
 */
export enum MetricType {
  kBytesWritten = 'bytesWritten',
  kCompressionRatio = 'compressionRatio',
  kDeduplicationRatio = 'deduplicationRatio',
  kFilesAdded = 'numFilesAdded',
  kFilesChanged = 'numFilesChanged',
  kFilesDeleted = 'numFilesDeleted',
  kFilesUnchanged = 'numFilesUnchanged',
}

export enum MetricTypeValueType {
  bytes = 'bytes',
  humanizeFromNumber = 'humanizeFromNumber',
  number = 'number',
}

export const MetricTypeValue: { [key in MetricType]: MetricTypeValueType } = {
  [MetricType.kBytesWritten]: MetricTypeValueType.bytes,
  [MetricType.kCompressionRatio]: MetricTypeValueType.number,
  [MetricType.kDeduplicationRatio]: MetricTypeValueType.number,
  [MetricType.kFilesAdded]: MetricTypeValueType.humanizeFromNumber,
  [MetricType.kFilesChanged]: MetricTypeValueType.humanizeFromNumber,
  [MetricType.kFilesDeleted]: MetricTypeValueType.humanizeFromNumber,
  [MetricType.kFilesUnchanged]: MetricTypeValueType.humanizeFromNumber,
};

export enum MetricTypeInSortedOrder {
  kFilesChanged,
  kFilesAdded,
  kFilesDeleted,
  kFilesUnchanged,
  kBytesWritten,
  kCompressionRatio,
  kDeduplicationRatio,
}

/**
 * Anomaly metrics grouped by their characteristic
 * Useful while applying common processing to a certain type of metric
 */
export const GroupedAnomalyMetrics = {
  files: [MetricType.kFilesChanged, MetricType.kFilesAdded, MetricType.kFilesDeleted, MetricType.kFilesUnchanged],
  ratios: [MetricType.kCompressionRatio, MetricType.kDeduplicationRatio],
};

/**
 * The list of metrics which are hidden from the users.
 */
export const hiddenMetrics = [MetricType.kDeduplicationRatio];

/**
 * Baas only account show only Data Written and Entropy charts
 */
export const baasMetrics: MetricType[] = [MetricType.kBytesWritten, MetricType.kCompressionRatio];

/**
 * Change type icon map
 */
export const changeTypeIconMap: { [K in FileChangeType]: string } = {
  kAddedOrModified: 'create',
  kDeleted: 'delete',
  kAffected: 'insert_drive_file',
  kUnaffected: 'insert_drive_file',
  kAny: 'insert_drive_file',
};

/**
 * change type action of each listed affected file
 */
export const changeTypeActions: { [K in FileChangeType]: NavItem[] } = {
  kAddedOrModified: [
    {
      displayName: 'downloadFile',
      icon: 'helix:download',
    },
  ],
  kDeleted: [
    {
      displayName: 'recoverFile',
      icon: 'helix:recover',
    },
  ],
  kAffected: [
    {
      displayName: 'downloadFile',
      icon: 'helix:download',
    },
  ],
  kUnaffected: [
    {
      displayName: 'downloadFile',
      icon: 'helix:download',
    },
  ],
  kAny: [
    {
      displayName: 'downloadFile',
      icon: 'helix:download',
    },
  ],
};

/**
 * All metrics response objects
 * Update this interface to add support for new metric.
 */
interface MetricResponse {
  bytesWritten?: number;
  compressionRatio?: number;
  deduplicationRatio?: number;
  numFilesAdded?: number;
  numFilesChanged?: number;
  numFilesDeleted?: number;
  numFilesUnchanged?: number;
}

/**
 * Property map for anomaly
 */
export interface PropertyMapObject {
  accountId?: string;
  anomalyStrength?: number;
  baasRegionId?: string;
  cid?: string;
  cluster?: string;
  clusterIncarnationId?: number;
  clusterPartitionId?: number;
  entityId?: number;
  environment?: string;
  inlineSnapshotDiff?: boolean;
  isBaas?: string;
  isRpaas?: boolean;
  jobId?: number;
  jobInstanceId?: number;
  jobName?: string;
  jobStartTimeUsecs?: string;
  object?: string;
  parentId?: string;
  protectionEnvType?: string;
  regionId?: string;
  rpaasRegionId?: string;
  runStartTimeUsecs?: string;
  source?: string;
  vaults?: string;
}

/**
 * Main anomaly object
 */
export interface AnomalyAlert extends Alert {
  incidence?: Incidence;
  clusterInfo?: ClusterInfo;
  isExpanded?: boolean;
  properties?: PropertyMapObject;
  selectedSnapshotPoint?: ChartPoint;
  seriesData?: AnomalyChartSeries;
  regionId?: string;
  tags?: SnapshotTag[];
  selectedSnapshotId?: string;
  lastSensitivityRunDetails?: SensitivityRunDetails;
  lastThreatScanDetails?: ScanRun;
  highestSensitivityCount?: PatternSensitivityCount;
  tooltipContext?: AnomalyTooltipContext;
  vaults?: Vault[];
}

export interface AnomalyTooltipContext {
  cluster?: string;
  environment?: string;
  latestTimestampUsecs?: number;
  object?: string;
  organization?: string;
  protectionGroupId?: string;
  policy?: Policy;
  size?: number;
  snapshotCount?: number;
  source?: string;
  type?: string;
}

/**
 * Anomaly sparkline chart series
 */
export interface AnomalyChartSeries {
  anomalousDataPoints?: ChartPoint[];
  chartMetric?: string;
  metrics?: string[];
  dataPointVec?: DataPoint[];
  latestCleanDataPoint?: ChartPoint;
  timestampDataPoints?: {
    [timestampUsecs: number]: DataPoint[];
  };
  transformedDataPoints?: ChartPoint[];
  expiredDataPoints?: ChartPoint[];
  taggedDataPoints?: ChartPoint[];
}

/**
 * Chart point selection object
 */
export interface ChartPoint {
  chartRef?: Chart;
  x?: number;
  y?: number;
  z?: number;
  tagged?: boolean;
}

/**
 * Anomaly stats datapoints
 */
export interface DataPoint extends MetricResponse {
  jobInstanceId?: number;
  tooltip?: string;
  timestampUsecs?: number;
  tags?: SnapshotTag[];
  isAnomalousSnapshot?: boolean;
  snapshotInfo?: SnapshotCopyInfo;

  /**
   * a flag indicating if it can be used for the recovery
   */
  isRecoverable?: boolean;

  /**
   * list of hms tags associated with the datapoint
   */
  hmsTags?: TagList;
}

/**
 * Information containing the local and archival snapshots.
 */
export interface SnapshotCopyInfo {
  localSnapshot?: string;
  archivalSnapshot?: ArchivalCopy[];
  snapshotExpired?: boolean;
}

/**
 * File stats for the snapshot
 */
export interface FileStats {
  added?: number;
  deleted?: number;
  modified?: number;
  total?: number;
}

/**
 * Type for regular file source
 */
export type RegularFileChangeType = 'kDeleted' | 'kAdded' | 'kModified';

/**
 * Common data source file operation
 */
export interface RegularFileOperation {
  filename: string;
  operation: RegularFileChangeType;
}

/**
 * Snapshot information
 */
export interface SnapshotInfo {
  numPages?: number;
  results?: RegularFileOperation[];
}

/**
 * data needed for setting up affected files filter
 */
export interface AffectedFilesFilterArguments {
  anomaly: AnomalyAlert;
  affectedFilesFilters?: AffectedFilesFilter;
  selectedPoint?: ChartPoint;
  seriesData?: AnomalyChartSeries;
  fileStats?: FileStats;
}

/**
 * Anomaly stats for dashboard
 */
export interface AnomalyStats {
  clusters?: number;
  firstOccurrenceTimeUsecs?: number;
  jobs?: number;
  lastOccurrenceTimeUsecs?: number;
  occurrences?: number;
  sources?: number;
}

/**
 * Enum for different recovery types
 */
export enum AnomalyRecoveryOperation {
  restore = 'restore',
  restoreFiles = 'restoreFiles',
  clone = 'clone',
}

/**
 * Restore task for recovery
 */
export interface RestoreTaskInfo {
  clusterId?: number;
  regionId?: string;
  task?: RecoveryTaskRequest;
  operation?: AnomalyRecoveryOperation;
  environment?: Environment;
}

/**
 * Time range for timeframe
 */
export interface TimeRange {
  endTime?: number;
  startTime?: number;
  frame?: Timeframe;
}

/**
 * File recovery dialog meta data
 */
export interface FileRecoveryMetadata {
  username?: string;
  password?: string;
  snapshotTimestampUsec?: number;
}

/**
 * Interface definition that combines the cluster info and the highcharts data option
 */
export interface ClusterLocation extends McmClusterLoc, SeriesMapbubbleDataOptions {
  clusterName: string;
}

/**
 * Enum of all the Snapshot status sent for any backup.
 */
export enum SnapshotStatus {
  StatusActive = 'Active',
  StatusSuccess = 'Success',
  StatusFailed = 'Failed',
  StatusDeleted = 'Deleted',
  StatusExpired = 'Expired',
  StatusUnknown = 'Unknown',
}

/**
 * The recover object type.
 */
export type RecoverObjectType = RecoverVmwareSnapshotParams[] | CommonRecoverObjectSnapshotParams[];

/**
 * The recovery source type local for on-prem clusters and vault for fort-knox.
 */
export enum RecoverySource {
  vault = 'vault',
  local = 'local',
}

/**
 * The recovery task request object.
 */
export interface RecoveryTaskRequest extends CreateRecoveryRequest {
  objects?: any[];
  viewName?: string;
  viewParams?: {
    sourceViewName?: string;
    cloneViewName?: string;
    qos?: {
      id?: number;
    };
    recoveryAction: 'DownloadFilesAndFolders' | 'RecoverFiles';
  };
}

/**
 * Common information needed for apply / remove tag API calls
 */
export interface TaggingCommons {
  /**
   * snapshot of each cluster
   */
  snapshot: Snapshot | null;

  /**
   * Anomaly tag for each snapshot
   */
  tags: SnapshotTag[];
}

/**
 * Params needed for snapshot action confirmation
 */
export interface SnapshotActionConfirmationParams {
  /**
   * Dialog action apply/remove tag
   */
  action: SnapshotTagActionType;

  /**
   * selected snapshot from table list
   */
  selectedSnapshots: DataPoint[];

  /**
   * Common information needed for apply / remove tag API calls
   */
  taggingCommons: TaggingCommons;
}

/**
 * Data to emit when download clicked on each file
 */
export interface AffectedFileEmitterData {
  /**
   * action downloadFile/recoverFile of each file
   */
  action: AffectedFileAction;

  /**
   * Data of clicked affected file
   */
  row: AffectedFile;
}

/**
 * Object containing anomaly related data.
 */
export interface AnomalyInfo {
  /**
   * Timestamp data points
   */
  cleanPoints: string[];

  /**
   * Anomaly data properties
   */
  data: AnomalyAlert;

  /**
   * Holds boolean to check if anomaly present in cart
   */
  isPresentInCart: boolean;

  /**
   * Current selected point on anomaly graph
   */
  selectedPoint: ChartPoint;

  /**
   * Holds the series of graph related data of anomaly
   */
  seriesData: AnomalyChartSeries;
}

/**
 * Params for affected-files component
 */
export interface AffectedFilesParams {
  /**
   * cluster ID
   */
  clusterId: number;

  /**
   * Entity ID
   */
  entityId: number;

  /**
   * Job ID
   */
  jobId: number;

  /**
   * Snapshot timestamp from trends anomaly point
   */
  snapshotTimeUsecs: number;
}

/**
 * Interface that denotes affected files API params for ransomware
 */
export interface AffectedFilesFilter {
  /**
   * Specifies the shield type.
   */
  shieldType: ShieldType;

  /**
   * Specifies the incidence id.
   */
  id: IncidenceId;

  /**
   * Specifies the names of the patterns
   */
  patternNames?: string[];

  /**
   * Specifies the classified patternIds to filter files.
   */
  patternIds?: PatternId[];

  /**
   * Specifies the file change types to filter results.
   */
  changeTypes?: FileChangeType[];

  /**
   * Specifies the pattern sensitivity of files to filter.
   */
  sensitivities?: Range[];
}

/**
 * Interface that denotes sensitivities API params for ransomware
 */
export interface SensitivitiesFilters {
  /**
   * Specifies the shield type.
   */
  shieldType: ShieldType;

  /**
   * Specifies the incidence id.
   */
  id: IncidenceId;

  /**
   * Specifies classified patternIds to filter files.
   */
  patternIds?: PatternId[];

  /**
   * Filter by the change type.
   */
  changeTypes?: FileChangeType[];
}

/**
 * Metric series for anomaly trends chart
 */
export interface MetricSeries {
  [key: string]: AnomalyChartSeries;
}

/**
 * Accepted alert states for suppression and resolution
 */
export type AlertState = UpdateAlertStateArgs['alert_state'];
