import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RestoreTaskWrapper } from '@cohesity/api/v1';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Api } from '../api.constants';
import { convertToHttpParams } from '../api.utils';

/**
 * The following interfaces are temporary and based on API implementation.
 * They will be replaced by v2 and no longer needed.
 */

/**
 * Data structure for entity attribute..
 */
interface EntityParams {
  type?: number;
  id: number;
  parentId: number;
  displayName: string;
}

/**
 * Data structure for objectId attribute.
 */
interface ObjectParams {
  jobId: number;
  jobUid: {
    clusterId: string;
    clusterIncarnationId: string;
    objectId: string;
  };
  entity: EntityParams;
}

/**
 * Data structure for instanceId attribute of get Vulscan params/result.
 */
interface InstanceParams {
  jobInstanceId: number;
  jobStartTimeUsecs: number;
}

/**
 * Data structure for versions attribute of get Vulscan result.
 */
export interface GetVulScanVersionResult {
  instanceId: InstanceParams;
  jobUID?: string;
  jobName?: string;
  result: any;
  scanStatus: string;
  scanTime?: number;
}

/**
 * Parameters for get Vulscan result API.
 */
export interface GetVulScanResultParams {
  objectId: ObjectParams;
  type: 'job' | 'vm';
  versions: {
    instanceId: InstanceParams;
  }[];
}

/**
 * Response for get Vulscan result API.
 */
export interface GetVulScanResult {
  objectId: ObjectParams;
  type: 'job' | 'vm';
  versions: GetVulScanVersionResult[];
}

/**
 * Parameters for create Vulscan task.
 */
export interface CreateVulScanTaskParams {
  objectId?: ObjectParams;
  type?: 'job' | 'vm';
  snapshot?: {
    instanceId: InstanceParams;
    vms?: EntityParams[];
  };
}

/**
 * Specifies the collection of params, allowed for querying restore tasks.
 */
export interface GetRestoreTasksParams {
  /**
   * Ids are the ids of the restore jobs to filter.
   */
  ids?: number[];

  /**
   * `startTimeUsecs` is the start time in usecs. If not set, historical
   * restore operations all the way to the beginning are looked at.
   */
  startTimeUsecs?: number;

  /**
   * `endTimeUsecs` is the end time in usecs. If not set, 'now' is treated as the
   * end time.
   */
  endTimeUsecs?: number;

  /**
   * `limitNumFinishedTasks` limits the number of finished restore tasks
   * returned. The newest finished tasks (by start time) are returned.
   */
  limitNumFinishedTasks?: number;

  /**
   * If `restoreTypes` is set then only the tasks corresponding to specified types
   * will be returned. User can specify `kRecoverVMs`, `kCloneVMs`, `kRestoreFiles`,
   * `kRestoreApp`, `kCloneView`.
   */
  restoreTypes?: string[];

  /**
   * `targetType` can be specified if requesting only tasks belonging to `kLocal`,
   * `kArchival` or `kTape`.
   */
  targetType?: string;

  /**
   * `tenantIds` to filter restore tasks of given tenants.
   */
  tenantIds?: string[];

  /**
   * `allUnderHierarchy` specifies if all the restore tasks under the hierarchy
   * of given tenants be listed. If no tenant id is specified then restore
   * tasks under hierarchy of session user's tenant are listed. It is set true
   * by default for external APIs.
   */
  allUnderHierarchy?: boolean;
}

/**
 * API service for legacy restore tasks.
 */
@Injectable({
  providedIn: 'root',
})
export class RestoreTasksApiService {
  /**
   * Constructor.
   */
  constructor(private http: HttpClient) {}

  /**
   * Queries the server for restore tasks.
   *
   * @param     params   The collection of parameters to pass to the API.
   * @returns   An observable of `RestoreTaskWrapper[]` which produces the list of restore tasks, if there are any.
   */
  getRestoreTasks(params: GetRestoreTasksParams): Observable<RestoreTaskWrapper[]> {
    return this.http.get<RestoreTaskWrapper[]>(Api.private('restoretasks'), {
      params: convertToHttpParams(params),
    });
  }

  /**
   * Queries the server for restore task by ID.
   *
   * @param     id   Restore task ID.
   * @returns   An observable of Restore task.
   */
  getRestoreTask(id: number, headers: HttpHeaders | {[header: string]: string | string[]}): Observable<any> {
    return this.http.get<any[]>(Api.private('restoretasks', id), {headers}).pipe(
      map(response => response && response.length && response[0].restoreTask ? response[0].restoreTask : undefined)
    );
  }

  /**
   * Queries the server for volumes for recovery.
   *
   * @param     params  restore task and snapshot parameters.
   * @returns   An observable of volume list.
   */
  getVolumeInfo(params: any): Observable<any> {
    return this.http.get<any[]>(Api.private('vm/volumeInfo'), {
      params: convertToHttpParams(params),
    }).pipe(
      map((response: any) => response && response.volumeInfos ? response.volumeInfos : [])
    );
  }

  /**
   * Gets status of vulnerability scan app
   *
   * @returns   An observable of scan app status
   */
  getVulScanAppStatus(): Observable<any> {
    return this.http.get<any>(Api.private('vulscan/api/appstatus'));
  }

  /**
   * Gets scanner results for job/vm
   *
   * @param   data    Object in recovery cart
   * @return  An observable of job/vm result
   */
  getVulScanResult(data: GetVulScanResultParams): Observable<any> {
    return this.http.post<any>(Api.private('vulscan/api/restoreinfo'), data).pipe(
      map((response: GetVulScanResult) => response && response.versions ? response.versions : response)
    );
  }

  /**
   * Creates vulnerability scan task for provided entity
   *
   * @param  data    job / vm details
   * @return An observable of result
   */
  createVulScanTask(data: CreateVulScanTaskParams): Observable<any> {
    return this.http.post<any>(Api.private('vulscan/api/ondemandscan'), data).pipe(
      map(response => response.data || {})
    );
  }

  /**
   * Downloads vulnerability scan report
   *
   * @param  path       Sub-url path string from get scan result API
   * @param  clusterId  Cluster ID
   */
  downloadVulScanReport(path: string, clusterId: number) {
    // Need clusterId as query param, So download from helios works
    const url = Api.private(`vulscan/api${path}?clusterId=${clusterId}`);

    // Open link in new tab
    window.open(url, '_blank');
  }
}
