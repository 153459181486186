<div class="object-name">
  <cog-icon [shape]="node.protectionSource | objectIcon : { isProtected: nodeProtected }">
  </cog-icon>
  <h4 class="title">{{node.protectionSource.name}}</h4>
</div>

<!-- General Information -->
<div class="source-meta-grouping object-info-list">
  <ul>
    <!-- NAS Size -->
    <li class="source-meta-line-item" *ngIf="node.logicalSize">
      <span class="source-meta-label">{{'provisionedSize' | translate}}</span>
      {{node.logicalSize | byteSize}}
    </li>

    <!-- Volume Type -->
    <li class="source-meta-line-item" *ngIf="nodeVolumeType">
      <span class="source-meta-label">{{'volumePermission' | translate}}</span>
      {{'sourceTree.metadata.volumeType.'+ nodeVolumeType | translate}}
    </li>

    <!-- NAS SMB username -->
    <li class="source-meta-line-item" *ngIf="environment === 'kGenericNas' &&
      node.registrationInfo?.nasMountCredentials?.username">
      <span class="source-meta-label">{{'username' | translate}}</span>
      {{node.registrationInfo.nasMountCredentials.username}}
    </li>
  </ul>
</div>

<!-- Netapp Shares -->
<div class="source-meta-grouping margin-top-lg"
  *ngIf="environment === 'kNetapp' && envSource.volumeInfo?.cifsShares?.length">
  <h5 class="title">{{'shares' | translate}}</h5>
  <div class="source-meta-line-item" *ngFor="let share of envSource.volumeInfo.cifsShares">
    {{share.name}}
  </div>
</div>

<coh-protection-status-meta class="margin-top-lg"
  [node]="node"
  [protectionLabel]="'sourceTreePub.tooltips.volumeProtected.' +  node.protectionSource.environment | translate">
</coh-protection-status-meta>
