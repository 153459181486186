// Controller: Job Run CloudDeploy Details

;(function(angular, undefined) {
  'use strict';

  angular.module('C.jobRunDetails')
    .controller('jobRunDetailsCloudDeployController', jobRunCloudDeployCtrlFn);

  function jobRunCloudDeployCtrlFn(_, $scope, $state, SourceService,
    RemoteClusterService, PollTaskStatus, JobRunsService, evalAJAX) {

    var jobRunParams = {
      id: +$state.params.id,
      exactMatchStartTimeUsecs: $state.params.startTimeUsecs || undefined,
    };
    var pollingOpts = {
      isDoneFn: isPollerDone,
      iteratorFn: getJobRun,
      scope: $scope,
    };

    // convenience functions and variables
    $scope.SourceService = SourceService;

    $scope.cloudDeployData = {
      ready: false,
    };

    $scope.allClusters = {};


    /**
     * controller activation function
     */
    function activate() {
      getData();
    }

    /**
     * Request data from API and parse it into a useful state
     */
    function getData() {

      RemoteClusterService.getAllClusters().then(
        function getAllClustersSuccess(clusters) {
          $scope.allClusters = clusters;

          // Kick off the poller for Job Run changes
          PollTaskStatus.createPoller(pollingOpts);
        },
        evalAJAX.errorMessage
      ).finally(
        function getAllClustersFinally() {
          $scope.cloudDeployData.ready = true;
        }
      );
    }

    /**
     * Gets the Job run from JobRunsService
     *
     * @return     {promise}  Resolves with the specific protectionRun,
     *                        rejects with server response
     */
    function getJobRun() {

      return JobRunsService.getJobRuns(jobRunParams).then(
        function getJobRunSuccess(jobRuns) {

          if (!jobRuns.length ||
            !jobRuns[0].backupJobRuns.protectionRuns[0]._hasCloudDeployTask) {
            // There is no data for this run or it has no clouddeploy tasks,
            // fall back to the run detail page.
            $state.go('job-run-details.protection', $state.params);
            return;
          }

          $scope.protectionRun = jobRuns[0].backupJobRuns.protectionRuns[0];

          promoteDeepLinkedTask($scope.protectionRun);
        }
      );

    }

    /**
     * In the event that a taskUid param was provided, this function will move
     * the appopriate clouddeploy task to the top of the array to ensure it will
     * be displayed first by the ngRepeat.
     *
     * @method   promoteDeepLinkedTask
     * @param    {object}   jobRun   The job run
     */
    function promoteDeepLinkedTask(jobRun) {
      var rxTasks;
      var deepLinkedTaskIndex;

      // If a taskUid was not provided, there's nothing to do here.
      if (!$state.params.taskUid) { return; }

      rxTasks = jobRun.copyRun._rxTasks;

      deepLinkedTaskIndex = rxTasks.findIndex(function findTaskFn(rxTask) {
        return angular.equals(rxTask.taskUid, $state.params.taskUid);
      });

      // If the task was found, move it to the top of the array so the
      // ngRepeat displays it first.
      if (deepLinkedTaskIndex !== -1) {
        rxTasks.unshift(rxTasks.splice(deepLinkedTaskIndex, 1)[0]);
      }

    }

    /**
     * Used in createPoller's isDoneFn to determine if the poller is done.
     * Detects if the copyRun has finished or has tasks, but none are active.
     *
     * @method    isPollerDone
     * @returns   {Boolean}   True if the copyRun is done and the poller
     *                        should stop.
     */
    function isPollerDone() {
      return (
        // The copyrun has finished OR...
        JobRunsService.isCopyRunFinished($scope.protectionRun.backupRun) ||

        // ...there are rx tasks and none of them are active.
        ($scope.protectionRun.copyRun._rxTasks.length &&
          !JobRunsService.hasActiveTasks($scope.protectionRun.copyRun._rxTasks))
      );
    }

    // activate on load
    activate();
  }

})(angular);
