import { McmObjectActivity } from '@cohesity/api/v2';
import { decorateInterface } from '@cohesity/utils';
import { convertApiStatusToSelfServiceTaskStatus } from '../utils/utils';

/**
 * Supported recovery types for M365 self service.
 */
export enum SelfServiceRecoveryType {
  /**
   * Specifies the worklfow for recovering Mailbox items.
   */
  kMailbox = 'kMailbox',

  /**
   * Specifies the workflow for recovering OneDrive items.
   */
  kOneDrive = 'kOneDrive',
}

/**
 * Specifies the param keyword for each recovery type.
 */
export const SelfServiceParamsTypeMap: { [recoveryType: string]: string } = {
  [SelfServiceRecoveryType.kMailbox]: 'mailboxParams',
  [SelfServiceRecoveryType.kOneDrive]: 'oneDriveParams',
};

/**
 * Represents a recovery card configuration.
 */
export interface RecoveryCardConfig {
  /**
   * The icon to show on the card. Must be a compatible Helix icon shape.
   */
  icon: string;

  /**
   * The title of the card. Should be a translation key.
   */
  title: string;

  /**
   * The description of the card. Should be a translation key.
   */
  description: string;
}

/**
 * Represents a row in the recover list.
 */
export class RecoveryListItem extends decorateInterface<McmObjectActivity>() {
  /**
   * Name of the recovery task.
   */
  readonly name = this.recoveryParams.name;

  /**
   * Type of recovery.
   */
  get recoveryType(): SelfServiceRecoveryType | null {
    const type = this.recoveryParams.recoveryType;

    if (['RecoverMailbox', 'ConvertToPst'].includes(type)) {
      return SelfServiceRecoveryType.kMailbox;
    } else if (['RecoverOneDrive', 'DownloadFilesAndFolders'].includes(type)) {
      return SelfServiceRecoveryType.kOneDrive;
    } else {
      return null;
    }
  }

  /**
   * Returns the untranslated string for the recovery object type.
   */
  get recoveryObjectTypeStr(): string {
    if (this.recoveryType === SelfServiceRecoveryType.kMailbox) {
      return 'mailbox';
    } else if (this.recoveryType === SelfServiceRecoveryType.kOneDrive) {
      return 'oneDrive';
    }
  }

  /**
   * Current user facing status of the task.
   */
  get publicStatus(): RecoveryTaskStatus {
    return convertApiStatusToSelfServiceTaskStatus(this.recoveryParams?.status);
  }

  /**
   * Current status of the task returned by the MCM API.
   */
  readonly status = this.recoveryParams?.status;

  /**
   * Timestamp (from epoch) when this task was created.
   */
  readonly createdUsecs = this.timestampUsecs;
}

/**
 * Represents the IDs of possible actions on a recovery list item.
 */
export enum RecoveryListItemRowActionId {
  /**
   * An action to show the items which are part of the recovery.
   */
  showItems,
}

/**
 * Represents an action on a recovery list item.
 */
export interface RecoveryListItemRowAction {
  /**
   * Unique ID of the action.
   */
  id: RecoveryListItemRowActionId;

  /**
   * The display name of the action. Should be a valid translation string.
   */
  displayName: string;
}

/**
 * Specifies the user visible statuses for Self Service recovery tasks.
 */
export enum RecoveryTaskStatus {
  Canceled = 'canceled',
  Completed = 'completed',
  Failed = 'failed',
  InitiatingRecovery = 'initiatingRecovery',
  InProgress = 'inProgress',
  PstAvailable = 'pstAvailable',
  PstUnavailable = 'pstUnavailable',
};

/**
 * Specifies the progress information about a recovery task.
 */
export interface RecoveryTaskProgressInfo {
  recoveryId: string;
  percentageCompleted: number;
}

/**
 * Specifies the status information about a recovery task.
 */
export interface RecoveryTaskStatusInfo {
  recoveryId: string;
  status: RecoveryTaskStatus;
}
