/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityV2ApiConfiguration as __Configuration } from '../cohesity-v2api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ProtectionGroups } from '../models/protection-groups';
import { ProtectionGroup } from '../models/protection-group';
import { CreateOrUpdateProtectionGroupRequest } from '../models/create-or-update-protection-group-request';
import { UpdateProtectionGroupsState } from '../models/update-protection-groups-state';
import { UpdateProtectionGroupsStateRequest } from '../models/update-protection-groups-state-request';
import { ProtectionGroupRuns } from '../models/protection-group-runs';
import { UpdateProtectionGroupRunResponseBody } from '../models/update-protection-group-run-response-body';
import { UpdateProtectionGroupRunRequestBody } from '../models/update-protection-group-run-request-body';
import { CreateProtectionGroupRunResponseBody } from '../models/create-protection-group-run-response-body';
import { CreateProtectionGroupRunRequest } from '../models/create-protection-group-run-request';
import { PerformRunActionResponse } from '../models/perform-run-action-response';
import { PerformActionOnProtectionGroupRunRequest } from '../models/perform-action-on-protection-group-run-request';
import { ProtectionGroupRun } from '../models/protection-group-run';
import { ProtectionRunsSummary } from '../models/protection-runs-summary';
import { GetProtectionRunProgressBody } from '../models/get-protection-run-progress-body';
import { GetProtectionRunStatsBody } from '../models/get-protection-run-stats-body';
import { McmProtectionGroupsActivity } from '../models/mcm-protection-groups-activity';
import { GetMcmProtectionGroupsActivityReqParams } from '../models/get-mcm-protection-groups-activity-req-params';
@Injectable({
  providedIn: 'root',
})
class ProtectionGroupService extends __BaseService {
  static readonly GetProtectionGroupsPath = '/data-protect/protection-groups';
  static readonly CreateProtectionGroupPath = '/data-protect/protection-groups';
  static readonly UpdateProtectionGroupsStatePath = '/data-protect/protection-groups/states';
  static readonly GetProtectionGroupByIdPath = '/data-protect/protection-groups/{id}';
  static readonly UpdateProtectionGroupPath = '/data-protect/protection-groups/{id}';
  static readonly DeleteProtectionGroupPath = '/data-protect/protection-groups/{id}';
  static readonly GetProtectionGroupRunsPath = '/data-protect/protection-groups/{id}/runs';
  static readonly UpdateProtectionGroupRunPath = '/data-protect/protection-groups/{id}/runs';
  static readonly CreateProtectionGroupRunPath = '/data-protect/protection-groups/{id}/runs';
  static readonly PerformActionOnProtectionGroupRunPath = '/data-protect/protection-groups/{id}/runs/actions';
  static readonly GetProtectionGroupRunPath = '/data-protect/protection-groups/{id}/runs/{runId}';
  static readonly GetRunDebugLogsPath = '/data-protect/protection-groups/{id}/runs/{runId}/debug-logs';
  static readonly GetRunDebugLogsForObjectPath = '/data-protect/protection-groups/{id}/runs/{runId}/objects/{objectId}/debug-logs';
  static readonly GetRunMessagesReportPath = '/data-protect/protection-groups/{id}/runs/{runId}/objects/{objectId}/download-messages';
  static readonly GetRunsReportPath = '/data-protect/protection-groups/{id}/runs/{runId}/objects/{objectId}/downloadFiles';
  static readonly GetProtectionRunsPath = '/data-protect/runs/summary';
  static readonly GetProtectionRunProgressPath = '/data-protect/runs/{runId}/progress';
  static readonly GetProtectionRunStatsPath = '/data-protect/runs/{runId}/stats';
  static readonly GetMcmProtectionGroupsActivityPath = '/mcm/data-protect/protection-group/activity';
  static readonly GetMcmProtectionGroupsPath = '/mcm/data-protect/protection-groups';
  static readonly CreateMcmProtectionGroupPath = '/mcm/data-protect/protection-groups';
  static readonly GetMcmProtectionGroupByIdPath = '/mcm/data-protect/protection-groups/{id}';
  static readonly UpdateMcmProtectionGroupPath = '/mcm/data-protect/protection-groups/{id}';
  static readonly DeleteMcmProtectionGroupPath = '/mcm/data-protect/protection-groups/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get the list of Protection Groups.
   *
   * Get the list of Protection Groups.
   * @param params The `ProtectionGroupService.GetProtectionGroupsParams` containing the following parameters:
   *
   * - `useCachedData`: Specifies whether we can serve the GET request from the read replica cache. There is a lag of 15 seconds between the read replica and primary data source.
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which objects are to be returned.
   *
   * - `storageDomainId`: Filter by Storage Domain id. Only Protection Groups writing data to this Storage Domain will be returned.
   *
   * - `sourceIds`: Filter by Source ids that are associated with Protection Groups. Only Protection Groups associated with the specified Source ids, are returned.
   *
   * - `requestInitiatorType`: Specifies the type of request from UI, which is used for services like magneto to determine the priority of requests.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `pruneSourceIds`: If true, the response will exclude the list of source IDs within the group specified.
   *
   * - `pruneExcludedSourceIds`: If true, the response will not include the list of excluded source IDs in groups that contain this field. This can be set to true in order to improve performance if excluded source IDs are not needed by the user.
   *
   * - `policyIds`: Filter by Policy ids that are associated with Protection Groups. Only Protection Groups associated with the specified Policy ids, are returned.
   *
   * - `office365Workloads`:
   *
   * - `names`: Filter by a list of Protection Group names.
   *
   * - `lastRunReplicationStatus`: Filter by last remote replication run status.<br> 'Running' indicates that the run is still running.<br> 'Canceled' indicates that the run has been canceled.<br> 'Canceling' indicates that the run is in the process of being canceled.<br> 'Failed' indicates that the run has failed.<br> 'Missed' indicates that the run was unable to take place at the scheduled time because the previous run was still happening.<br> 'Succeeded' indicates that the run has finished successfully.<br> 'SucceededWithWarning' indicates that the run finished successfully, but there were some warning messages.<br> 'Paused' indicates that the ongoing run has been paused.<br> 'Skipped' indicates that the run was skipped.
   *
   * - `lastRunLocalBackupStatus`: Filter by last local backup run status.<br> 'Running' indicates that the run is still running.<br> 'Canceled' indicates that the run has been canceled.<br> 'Canceling' indicates that the run is in the process of being canceled.<br> 'Failed' indicates that the run has failed.<br> 'Missed' indicates that the run was unable to take place at the scheduled time because the previous run was still happening.<br> 'Succeeded' indicates that the run has finished successfully.<br> 'SucceededWithWarning' indicates that the run finished successfully, but there were some warning messages.<br> 'Paused' indicates that the ongoing run has been paused.<br> 'Skipped' indicates that the run was skipped.
   *
   * - `lastRunCloudSpinStatus`: Filter by last cloud spin run status.<br> 'Running' indicates that the run is still running.<br> 'Canceled' indicates that the run has been canceled.<br> 'Canceling' indicates that the run is in the process of being canceled.<br> 'Failed' indicates that the run has failed.<br> 'Missed' indicates that the run was unable to take place at the scheduled time because the previous run was still happening.<br> 'Succeeded' indicates that the run has finished successfully.<br> 'SucceededWithWarning' indicates that the run finished successfully, but there were some warning messages.<br> 'Paused' indicates that the ongoing run has been paused.<br> 'Skipped' indicates that the run was skipped.
   *
   * - `lastRunArchivalStatus`: Filter by last cloud archival run status.<br> 'Running' indicates that the run is still running.<br> 'Canceled' indicates that the run has been canceled.<br> 'Canceling' indicates that the run is in the process of being canceled.<br> 'Failed' indicates that the run has failed.<br> 'Missed' indicates that the run was unable to take place at the scheduled time because the previous run was still happening.<br> 'Succeeded' indicates that the run has finished successfully.<br> 'SucceededWithWarning' indicates that the run finished successfully, but there were some warning messages.<br> 'Paused' indicates that the ongoing run has been paused.<br> 'Skipped' indicates that the run was skipped.
   *
   * - `lastRunAnyStatus`: Filter by last any run status.<br> 'Running' indicates that the run is still running.<br> 'Canceled' indicates that the run has been canceled.<br> 'Canceling' indicates that the run is in the process of being canceled.<br> 'Failed' indicates that the run has failed.<br> 'Missed' indicates that the run was unable to take place at the scheduled time because the previous run was still happening.<br> 'Succeeded' indicates that the run has finished successfully.<br> 'SucceededWithWarning' indicates that the run finished successfully, but there were some warning messages.<br> 'Paused' indicates that the ongoing run has been paused.<br> 'Skipped' indicates that the run was skipped.
   *
   * - `isPaused`: Filter by paused or non paused Protection Groups, If not set, all paused and non paused Protection Groups are returned. If true, only paused Protection Groups are returned. If false, only non paused Protection Groups are returned.
   *
   * - `isLastRunSlaViolated`: If true, return Protection Groups for which last run SLA was violated.
   *
   * - `isDeleted`: If true, return only Protection Groups that have been deleted but still have Snapshots associated with them. If false, return all Protection Groups except those Protection Groups that have been deleted and still have Snapshots associated with them. A Protection Group that is deleted with all its Snapshots is not returned for either of these cases.
   *
   * - `isActive`: Filter by Inactive or Active Protection Groups. If not set, all Inactive and Active Protection Groups are returned. If true, only Active Protection Groups are returned. If false, only Inactive Protection Groups are returned. When you create a Protection Group on a Primary Cluster with a replication schedule, the Cluster creates an Inactive copy of the Protection Group on the Remote Cluster. In addition, when an Active and running Protection Group is deactivated, the Protection Group becomes Inactive.
   *
   * - `includeTenants`: If true, the response will include Protection Groups which were created by all tenants which the current user has permission to see. If false, then only Protection Groups created by the current user will be returned.
   *
   * - `includeLastRunInfo`: If true, the response will include last run info. If it is false or not specified, the last run info won't be returned.
   *
   * - `includeGroupsWithDatalockOnly`: Whether to only return Protection Groups with a datalock.
   *
   * - `ids`: Filter by a list of Protection Group ids.
   *
   * - `environments`: Filter by environment types such as 'kVMware', 'kView', etc. Only Protection Groups protecting the specified environment types are returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetProtectionGroupsResponse(params: ProtectionGroupService.GetProtectionGroupsParams): __Observable<__StrictHttpResponse<ProtectionGroups>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.useCachedData != null) __params = __params.set('useCachedData', params.useCachedData.toString());
    (params.tenantIds || []).forEach(val => {if (val != null) __params = __params.append('tenantIds', val.toString())});
    if (params.storageDomainId != null) __params = __params.set('storageDomainId', params.storageDomainId.toString());
    (params.sourceIds || []).forEach(val => {if (val != null) __params = __params.append('sourceIds', val.toString())});
    if (params.requestInitiatorType != null) __headers = __headers.set('requestInitiatorType', params.requestInitiatorType.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.pruneSourceIds != null) __params = __params.set('pruneSourceIds', params.pruneSourceIds.toString());
    if (params.pruneExcludedSourceIds != null) __params = __params.set('pruneExcludedSourceIds', params.pruneExcludedSourceIds.toString());
    (params.policyIds || []).forEach(val => {if (val != null) __params = __params.append('policyIds', val.toString())});
    (params.office365Workloads || []).forEach(val => {if (val != null) __params = __params.append('office365Workloads', val.toString())});
    (params.names || []).forEach(val => {if (val != null) __params = __params.append('names', val.toString())});
    (params.lastRunReplicationStatus || []).forEach(val => {if (val != null) __params = __params.append('lastRunReplicationStatus', val.toString())});
    (params.lastRunLocalBackupStatus || []).forEach(val => {if (val != null) __params = __params.append('lastRunLocalBackupStatus', val.toString())});
    (params.lastRunCloudSpinStatus || []).forEach(val => {if (val != null) __params = __params.append('lastRunCloudSpinStatus', val.toString())});
    (params.lastRunArchivalStatus || []).forEach(val => {if (val != null) __params = __params.append('lastRunArchivalStatus', val.toString())});
    (params.lastRunAnyStatus || []).forEach(val => {if (val != null) __params = __params.append('lastRunAnyStatus', val.toString())});
    if (params.isPaused != null) __params = __params.set('isPaused', params.isPaused.toString());
    if (params.isLastRunSlaViolated != null) __params = __params.set('isLastRunSlaViolated', params.isLastRunSlaViolated.toString());
    if (params.isDeleted != null) __params = __params.set('isDeleted', params.isDeleted.toString());
    if (params.isActive != null) __params = __params.set('isActive', params.isActive.toString());
    if (params.includeTenants != null) __params = __params.set('includeTenants', params.includeTenants.toString());
    if (params.includeLastRunInfo != null) __params = __params.set('includeLastRunInfo', params.includeLastRunInfo.toString());
    if (params.includeGroupsWithDatalockOnly != null) __params = __params.set('includeGroupsWithDatalockOnly', params.includeGroupsWithDatalockOnly.toString());
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    (params.environments || []).forEach(val => {if (val != null) __params = __params.append('environments', val.toString())});
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/data-protect/protection-groups`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ProtectionGroups>;
      })
    );
  }
  /**
   * Get the list of Protection Groups.
   *
   * Get the list of Protection Groups.
   * @param params The `ProtectionGroupService.GetProtectionGroupsParams` containing the following parameters:
   *
   * - `useCachedData`: Specifies whether we can serve the GET request from the read replica cache. There is a lag of 15 seconds between the read replica and primary data source.
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which objects are to be returned.
   *
   * - `storageDomainId`: Filter by Storage Domain id. Only Protection Groups writing data to this Storage Domain will be returned.
   *
   * - `sourceIds`: Filter by Source ids that are associated with Protection Groups. Only Protection Groups associated with the specified Source ids, are returned.
   *
   * - `requestInitiatorType`: Specifies the type of request from UI, which is used for services like magneto to determine the priority of requests.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `pruneSourceIds`: If true, the response will exclude the list of source IDs within the group specified.
   *
   * - `pruneExcludedSourceIds`: If true, the response will not include the list of excluded source IDs in groups that contain this field. This can be set to true in order to improve performance if excluded source IDs are not needed by the user.
   *
   * - `policyIds`: Filter by Policy ids that are associated with Protection Groups. Only Protection Groups associated with the specified Policy ids, are returned.
   *
   * - `office365Workloads`:
   *
   * - `names`: Filter by a list of Protection Group names.
   *
   * - `lastRunReplicationStatus`: Filter by last remote replication run status.<br> 'Running' indicates that the run is still running.<br> 'Canceled' indicates that the run has been canceled.<br> 'Canceling' indicates that the run is in the process of being canceled.<br> 'Failed' indicates that the run has failed.<br> 'Missed' indicates that the run was unable to take place at the scheduled time because the previous run was still happening.<br> 'Succeeded' indicates that the run has finished successfully.<br> 'SucceededWithWarning' indicates that the run finished successfully, but there were some warning messages.<br> 'Paused' indicates that the ongoing run has been paused.<br> 'Skipped' indicates that the run was skipped.
   *
   * - `lastRunLocalBackupStatus`: Filter by last local backup run status.<br> 'Running' indicates that the run is still running.<br> 'Canceled' indicates that the run has been canceled.<br> 'Canceling' indicates that the run is in the process of being canceled.<br> 'Failed' indicates that the run has failed.<br> 'Missed' indicates that the run was unable to take place at the scheduled time because the previous run was still happening.<br> 'Succeeded' indicates that the run has finished successfully.<br> 'SucceededWithWarning' indicates that the run finished successfully, but there were some warning messages.<br> 'Paused' indicates that the ongoing run has been paused.<br> 'Skipped' indicates that the run was skipped.
   *
   * - `lastRunCloudSpinStatus`: Filter by last cloud spin run status.<br> 'Running' indicates that the run is still running.<br> 'Canceled' indicates that the run has been canceled.<br> 'Canceling' indicates that the run is in the process of being canceled.<br> 'Failed' indicates that the run has failed.<br> 'Missed' indicates that the run was unable to take place at the scheduled time because the previous run was still happening.<br> 'Succeeded' indicates that the run has finished successfully.<br> 'SucceededWithWarning' indicates that the run finished successfully, but there were some warning messages.<br> 'Paused' indicates that the ongoing run has been paused.<br> 'Skipped' indicates that the run was skipped.
   *
   * - `lastRunArchivalStatus`: Filter by last cloud archival run status.<br> 'Running' indicates that the run is still running.<br> 'Canceled' indicates that the run has been canceled.<br> 'Canceling' indicates that the run is in the process of being canceled.<br> 'Failed' indicates that the run has failed.<br> 'Missed' indicates that the run was unable to take place at the scheduled time because the previous run was still happening.<br> 'Succeeded' indicates that the run has finished successfully.<br> 'SucceededWithWarning' indicates that the run finished successfully, but there were some warning messages.<br> 'Paused' indicates that the ongoing run has been paused.<br> 'Skipped' indicates that the run was skipped.
   *
   * - `lastRunAnyStatus`: Filter by last any run status.<br> 'Running' indicates that the run is still running.<br> 'Canceled' indicates that the run has been canceled.<br> 'Canceling' indicates that the run is in the process of being canceled.<br> 'Failed' indicates that the run has failed.<br> 'Missed' indicates that the run was unable to take place at the scheduled time because the previous run was still happening.<br> 'Succeeded' indicates that the run has finished successfully.<br> 'SucceededWithWarning' indicates that the run finished successfully, but there were some warning messages.<br> 'Paused' indicates that the ongoing run has been paused.<br> 'Skipped' indicates that the run was skipped.
   *
   * - `isPaused`: Filter by paused or non paused Protection Groups, If not set, all paused and non paused Protection Groups are returned. If true, only paused Protection Groups are returned. If false, only non paused Protection Groups are returned.
   *
   * - `isLastRunSlaViolated`: If true, return Protection Groups for which last run SLA was violated.
   *
   * - `isDeleted`: If true, return only Protection Groups that have been deleted but still have Snapshots associated with them. If false, return all Protection Groups except those Protection Groups that have been deleted and still have Snapshots associated with them. A Protection Group that is deleted with all its Snapshots is not returned for either of these cases.
   *
   * - `isActive`: Filter by Inactive or Active Protection Groups. If not set, all Inactive and Active Protection Groups are returned. If true, only Active Protection Groups are returned. If false, only Inactive Protection Groups are returned. When you create a Protection Group on a Primary Cluster with a replication schedule, the Cluster creates an Inactive copy of the Protection Group on the Remote Cluster. In addition, when an Active and running Protection Group is deactivated, the Protection Group becomes Inactive.
   *
   * - `includeTenants`: If true, the response will include Protection Groups which were created by all tenants which the current user has permission to see. If false, then only Protection Groups created by the current user will be returned.
   *
   * - `includeLastRunInfo`: If true, the response will include last run info. If it is false or not specified, the last run info won't be returned.
   *
   * - `includeGroupsWithDatalockOnly`: Whether to only return Protection Groups with a datalock.
   *
   * - `ids`: Filter by a list of Protection Group ids.
   *
   * - `environments`: Filter by environment types such as 'kVMware', 'kView', etc. Only Protection Groups protecting the specified environment types are returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetProtectionGroups(params: ProtectionGroupService.GetProtectionGroupsParams): __Observable<ProtectionGroups> {
    return this.GetProtectionGroupsResponse(params).pipe(
      __map(_r => _r.body as ProtectionGroups)
    );
  }

  /**
   * Create a Protection Group.
   *
   * Create a Protection Group.
   * @param params The `ProtectionGroupService.CreateProtectionGroupParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to create a Protection Group.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateProtectionGroupResponse(params: ProtectionGroupService.CreateProtectionGroupParams): __Observable<__StrictHttpResponse<ProtectionGroup>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/data-protect/protection-groups`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ProtectionGroup>;
      })
    );
  }
  /**
   * Create a Protection Group.
   *
   * Create a Protection Group.
   * @param params The `ProtectionGroupService.CreateProtectionGroupParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to create a Protection Group.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateProtectionGroup(params: ProtectionGroupService.CreateProtectionGroupParams): __Observable<ProtectionGroup> {
    return this.CreateProtectionGroupResponse(params).pipe(
      __map(_r => _r.body as ProtectionGroup)
    );
  }

  /**
   * Perform an action like pause, resume, active, deactivate on all specified Protection Groups.
   *
   * Perform an action like pause, resume, active, deactivate on all specified Protection Groups. Note that the pause or resume actions will take effect from next Protection Run. Also, user can specify only one type of action on all the Protection Groups. Deactivate and activate actions are independent of pause and resume state. Deactivate and activate actions are useful in case of failover situations. Returns success if the state of all the Protection Groups state is changed successfully.
   * @param params The `ProtectionGroupService.UpdateProtectionGroupsStateParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to perform an action of list of Protection Groups.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateProtectionGroupsStateResponse(params: ProtectionGroupService.UpdateProtectionGroupsStateParams): __Observable<__StrictHttpResponse<UpdateProtectionGroupsState>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/data-protect/protection-groups/states`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UpdateProtectionGroupsState>;
      })
    );
  }
  /**
   * Perform an action like pause, resume, active, deactivate on all specified Protection Groups.
   *
   * Perform an action like pause, resume, active, deactivate on all specified Protection Groups. Note that the pause or resume actions will take effect from next Protection Run. Also, user can specify only one type of action on all the Protection Groups. Deactivate and activate actions are independent of pause and resume state. Deactivate and activate actions are useful in case of failover situations. Returns success if the state of all the Protection Groups state is changed successfully.
   * @param params The `ProtectionGroupService.UpdateProtectionGroupsStateParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to perform an action of list of Protection Groups.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateProtectionGroupsState(params: ProtectionGroupService.UpdateProtectionGroupsStateParams): __Observable<UpdateProtectionGroupsState> {
    return this.UpdateProtectionGroupsStateResponse(params).pipe(
      __map(_r => _r.body as UpdateProtectionGroupsState)
    );
  }

  /**
   * List details about single Protection Group.
   *
   * Returns the Protection Group corresponding to the specified Group id.
   * @param params The `ProtectionGroupService.GetProtectionGroupByIdParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the Protection Group.
   *
   * - `requestInitiatorType`: Specifies the type of request from UI, which is used for services like magneto to determine the priority of requests.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `pruneSourceIds`: If true, the response will exclude the list of source IDs within the group specified.
   *
   * - `pruneExcludedSourceIds`: If true, the response will not include the list of excluded source IDs in groups that contain this field. This can be set to true in order to improve performance if excluded source IDs are not needed by the user.
   *
   * - `includeLastRunInfo`: If true, the response will include last run info. If it is false or not specified, the last run info won't be returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetProtectionGroupByIdResponse(params: ProtectionGroupService.GetProtectionGroupByIdParams): __Observable<__StrictHttpResponse<ProtectionGroup>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.requestInitiatorType != null) __headers = __headers.set('requestInitiatorType', params.requestInitiatorType.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.pruneSourceIds != null) __params = __params.set('pruneSourceIds', params.pruneSourceIds.toString());
    if (params.pruneExcludedSourceIds != null) __params = __params.set('pruneExcludedSourceIds', params.pruneExcludedSourceIds.toString());
    if (params.includeLastRunInfo != null) __params = __params.set('includeLastRunInfo', params.includeLastRunInfo.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/data-protect/protection-groups/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ProtectionGroup>;
      })
    );
  }
  /**
   * List details about single Protection Group.
   *
   * Returns the Protection Group corresponding to the specified Group id.
   * @param params The `ProtectionGroupService.GetProtectionGroupByIdParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the Protection Group.
   *
   * - `requestInitiatorType`: Specifies the type of request from UI, which is used for services like magneto to determine the priority of requests.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `pruneSourceIds`: If true, the response will exclude the list of source IDs within the group specified.
   *
   * - `pruneExcludedSourceIds`: If true, the response will not include the list of excluded source IDs in groups that contain this field. This can be set to true in order to improve performance if excluded source IDs are not needed by the user.
   *
   * - `includeLastRunInfo`: If true, the response will include last run info. If it is false or not specified, the last run info won't be returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetProtectionGroupById(params: ProtectionGroupService.GetProtectionGroupByIdParams): __Observable<ProtectionGroup> {
    return this.GetProtectionGroupByIdResponse(params).pipe(
      __map(_r => _r.body as ProtectionGroup)
    );
  }

  /**
   * Update a Protection Group.
   *
   * Update the specified Protection Group.
   * @param params The `ProtectionGroupService.UpdateProtectionGroupParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the Protection Group.
   *
   * - `body`: Specifies the parameters to update a Protection Group.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateProtectionGroupResponse(params: ProtectionGroupService.UpdateProtectionGroupParams): __Observable<__StrictHttpResponse<ProtectionGroup>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/data-protect/protection-groups/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ProtectionGroup>;
      })
    );
  }
  /**
   * Update a Protection Group.
   *
   * Update the specified Protection Group.
   * @param params The `ProtectionGroupService.UpdateProtectionGroupParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the Protection Group.
   *
   * - `body`: Specifies the parameters to update a Protection Group.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateProtectionGroup(params: ProtectionGroupService.UpdateProtectionGroupParams): __Observable<ProtectionGroup> {
    return this.UpdateProtectionGroupResponse(params).pipe(
      __map(_r => _r.body as ProtectionGroup)
    );
  }

  /**
   * Delete a Protection Group.
   *
   * Returns Success if the Protection Group is deleted.
   * @param params The `ProtectionGroupService.DeleteProtectionGroupParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the Protection Group.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `deleteSnapshots`: Specifies if Snapshots generated by the Protection Group should also be deleted when the Protection Group is deleted.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteProtectionGroupResponse(params: ProtectionGroupService.DeleteProtectionGroupParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.deleteSnapshots != null) __params = __params.set('deleteSnapshots', params.deleteSnapshots.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/data-protect/protection-groups/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete a Protection Group.
   *
   * Returns Success if the Protection Group is deleted.
   * @param params The `ProtectionGroupService.DeleteProtectionGroupParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the Protection Group.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `deleteSnapshots`: Specifies if Snapshots generated by the Protection Group should also be deleted when the Protection Group is deleted.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteProtectionGroup(params: ProtectionGroupService.DeleteProtectionGroupParams): __Observable<null> {
    return this.DeleteProtectionGroupResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get the list of runs for a Protection Group.
   *
   * Get the runs for a particular Protection Group.
   * @param params The `ProtectionGroupService.GetProtectionGroupRunsParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the Protection Group.
   *
   * - `useCachedData`: Specifies whether we can serve the GET request from the read replica cache. There is a lag of 15 seconds between the read replica and primary data source.
   *
   * - `truncateResponse`: If set, magneto will truncate the response if it exceeds max size limit governed by magneto_http_rpc_response_size_limit_bytes
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which objects are to be returned.
   *
   * - `startTimeUsecs`: Start time for time range filter. Specify the start time as a Unix epoch Timestamp (in microseconds), only runs executing after this time will be returned. By default it is endTimeUsecs minus an hour.
   *
   * - `sourceId`: Filter by source id. Only Job Runs protecting the specified source (such as a VM or View) are returned. The source id is assigned by the Cohesity Cluster.
   *
   * - `snapshotTargetTypes`: Specifies the snapshot's target type which should be filtered. Note: this field is only considered when, filterByCopyTaskEndTime is set to true, or else it is ignored.
   *
   * - `runTypes`: Filter by run type. Only protection run matching the specified types will be returned.
   *
   * - `runTags`: Specifies a list of tags for protection runs. If this is specified, only the runs which match these tags will be returned.
   *
   * - `runId`: Specifies the protection run id.
   *
   * - `requestInitiatorType`: Specifies the type of request from UI, which is used for services like magneto to determine the priority of requests.
   *
   * - `replicationRunStatus`: Specifies a list of replication status, runs matching the status will be returned.<br> 'Running' indicates that the run is still running.<br> 'Canceled' indicates that the run has been canceled.<br> 'Canceling' indicates that the run is in the process of being canceled.<br> 'Failed' indicates that the run has failed.<br> 'Missed' indicates that the run was unable to take place at the scheduled time because the previous run was still happening.<br> 'Succeeded' indicates that the run has finished successfully.<br> 'SucceededWithWarning' indicates that the run finished successfully, but there were some warning messages.<br> 'Paused' indicates that the ongoing run has been paused.<br> 'Skipped' indicates that the run was skipped.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `onlyReturnSuccessfulCopyRun`: If set to false, all copy_tasks in any given valid state will be considered. If left empty or set to true, only successful copy_tasks would be considered. Note: this field is only considered when, filterByCopyTaskEndTime is set to true, or else it is ignored.
   *
   * - `onlyReturnShellInfo`: If set, returns only shell info such as run's start time, type, error if any.
   *
   * - `onlyReturnDataMigrationJobs`: Specifies if only data stubbing jobs should be returned. If not set, no data migration job will be returned.
   *
   * - `numRuns`: Specifies the max number of runs. If not specified, at most 100 runs will be returned.
   *
   * - `localBackupRunStatus`: Specifies a list of local backup status, runs matching the status will be returned.<br> 'Running' indicates that the run is still running.<br> 'Canceled' indicates that the run has been canceled.<br> 'Canceling' indicates that the run is in the process of being canceled.<br> 'Failed' indicates that the run has failed.<br> 'Missed' indicates that the run was unable to take place at the scheduled time because the previous run was still happening.<br> 'Succeeded' indicates that the run has finished successfully.<br> 'SucceededWithWarning' indicates that the run finished successfully, but there were some warning messages.<br> 'Paused' indicates that the ongoing run has been paused.<br> 'Skipped' indicates that the run was skipped.
   *
   * - `jobRunStartTimeUsecs`: Return a specific Job Run by specifying a time and a group id. Specify the time when the Job Run started as a Unix epoch Timestamp (in microseconds). If this field is specified, jobId must also be specified.
   *
   * - `includeTenants`: If true, the response will include Protection Group Runs which were created by all tenants which the current user has permission to see. If false, then only Protection Group Runs created by the current user will be returned.
   *
   * - `includeRpoSnapshots`: If true, then the snapshots for Protection Sources protected by Rpo policies will also be returned.
   *
   * - `includeObjectDetails`: Specifies if the result includes the object details for each protection run. If set to true, details of the protected object will be returned. If set to false or not specified, details will not be returned.
   *
   * - `includeExtensionInfo`: Specifies if needs to include proto extensions if they are extended.
   *
   * - `filterByEndTime`: If true, the runs with end time within the specified time range will be returned. Otherwise, the runs with start time in the time range are returned.
   *
   * - `filterByCopyTaskEndTime`: If true, then the details of the runs for which any copyTask completed in the given timerange will be returned. Only one of filterByEndTime and filterByCopyTaskEndTime can be set.
   *
   * - `excludeNonRestorableRuns`: Specifies whether to exclude non restorable runs. Run is treated restorable only if there is atleast one object snapshot (which may be either a local or an archival snapshot) which is not deleted or expired. Default value is false.
   *
   * - `excludeErrorRuns`: Specifies whether to exclude runs with error. If no value is specified, then runs with errors are included.
   *
   * - `endTimeUsecs`: End time for time range filter. Specify the end time as a Unix epoch Timestamp (in microseconds), only runs executing before this time will be returned. By default it is current time.
   *
   * - `cloudSpinRunStatus`: Specifies a list of cloud spin status, runs matching the status will be returned.<br> 'Running' indicates that the run is still running.<br> 'Canceled' indicates that the run has been canceled.<br> 'Canceling' indicates that the run is in the process of being canceled.<br> 'Failed' indicates that the run has failed.<br> 'Missed' indicates that the run was unable to take place at the scheduled time because the previous run was still happening.<br> 'Succeeded' indicates that the run has finished successfully.<br> 'SucceededWithWarning' indicates that the run finished successfully, but there were some warning messages.<br> 'Paused' indicates that the ongoing run has been paused.<br> 'Skipped' indicates that the run was skipped.
   *
   * - `archivalRunStatus`: Specifies a list of archival status, runs matching the status will be returned.<br> 'Running' indicates that the run is still running.<br> 'Canceled' indicates that the run has been canceled.<br> 'Canceling' indicates that the run is in the process of being canceled.<br> 'Failed' indicates that the run has failed.<br> 'Missed' indicates that the run was unable to take place at the scheduled time because the previous run was still happening.<br> 'Succeeded' indicates that the run has finished successfully.<br> 'SucceededWithWarning' indicates that the run finished successfully, but there were some warning messages.<br> 'Paused' indicates that the ongoing run has been paused.<br> 'Skipped' indicates that the run was skipped.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetProtectionGroupRunsResponse(params: ProtectionGroupService.GetProtectionGroupRunsParams): __Observable<__StrictHttpResponse<ProtectionGroupRuns>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.useCachedData != null) __params = __params.set('useCachedData', params.useCachedData.toString());
    if (params.truncateResponse != null) __params = __params.set('truncateResponse', params.truncateResponse.toString());
    (params.tenantIds || []).forEach(val => {if (val != null) __params = __params.append('tenantIds', val.toString())});
    if (params.startTimeUsecs != null) __params = __params.set('startTimeUsecs', params.startTimeUsecs.toString());
    if (params.sourceId != null) __params = __params.set('sourceId', params.sourceId.toString());
    (params.snapshotTargetTypes || []).forEach(val => {if (val != null) __params = __params.append('snapshotTargetTypes', val.toString())});
    (params.runTypes || []).forEach(val => {if (val != null) __params = __params.append('runTypes', val.toString())});
    (params.runTags || []).forEach(val => {if (val != null) __params = __params.append('runTags', val.toString())});
    if (params.runId != null) __params = __params.set('runId', params.runId.toString());
    if (params.requestInitiatorType != null) __headers = __headers.set('requestInitiatorType', params.requestInitiatorType.toString());
    (params.replicationRunStatus || []).forEach(val => {if (val != null) __params = __params.append('replicationRunStatus', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.onlyReturnSuccessfulCopyRun != null) __params = __params.set('onlyReturnSuccessfulCopyRun', params.onlyReturnSuccessfulCopyRun.toString());
    if (params.onlyReturnShellInfo != null) __params = __params.set('onlyReturnShellInfo', params.onlyReturnShellInfo.toString());
    if (params.onlyReturnDataMigrationJobs != null) __params = __params.set('onlyReturnDataMigrationJobs', params.onlyReturnDataMigrationJobs.toString());
    if (params.numRuns != null) __params = __params.set('numRuns', params.numRuns.toString());
    (params.localBackupRunStatus || []).forEach(val => {if (val != null) __params = __params.append('localBackupRunStatus', val.toString())});
    if (params.jobRunStartTimeUsecs != null) __params = __params.set('jobRunStartTimeUsecs', params.jobRunStartTimeUsecs.toString());
    if (params.includeTenants != null) __params = __params.set('includeTenants', params.includeTenants.toString());
    if (params.includeRpoSnapshots != null) __params = __params.set('includeRpoSnapshots', params.includeRpoSnapshots.toString());
    if (params.includeObjectDetails != null) __params = __params.set('includeObjectDetails', params.includeObjectDetails.toString());
    if (params.includeExtensionInfo != null) __params = __params.set('includeExtensionInfo', params.includeExtensionInfo.toString());
    if (params.filterByEndTime != null) __params = __params.set('filterByEndTime', params.filterByEndTime.toString());
    if (params.filterByCopyTaskEndTime != null) __params = __params.set('filterByCopyTaskEndTime', params.filterByCopyTaskEndTime.toString());
    if (params.excludeNonRestorableRuns != null) __params = __params.set('excludeNonRestorableRuns', params.excludeNonRestorableRuns.toString());
    if (params.excludeErrorRuns != null) __params = __params.set('excludeErrorRuns', params.excludeErrorRuns.toString());
    if (params.endTimeUsecs != null) __params = __params.set('endTimeUsecs', params.endTimeUsecs.toString());
    (params.cloudSpinRunStatus || []).forEach(val => {if (val != null) __params = __params.append('cloudSpinRunStatus', val.toString())});
    (params.archivalRunStatus || []).forEach(val => {if (val != null) __params = __params.append('archivalRunStatus', val.toString())});
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/data-protect/protection-groups/${encodeURIComponent(params.id)}/runs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ProtectionGroupRuns>;
      })
    );
  }
  /**
   * Get the list of runs for a Protection Group.
   *
   * Get the runs for a particular Protection Group.
   * @param params The `ProtectionGroupService.GetProtectionGroupRunsParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the Protection Group.
   *
   * - `useCachedData`: Specifies whether we can serve the GET request from the read replica cache. There is a lag of 15 seconds between the read replica and primary data source.
   *
   * - `truncateResponse`: If set, magneto will truncate the response if it exceeds max size limit governed by magneto_http_rpc_response_size_limit_bytes
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which objects are to be returned.
   *
   * - `startTimeUsecs`: Start time for time range filter. Specify the start time as a Unix epoch Timestamp (in microseconds), only runs executing after this time will be returned. By default it is endTimeUsecs minus an hour.
   *
   * - `sourceId`: Filter by source id. Only Job Runs protecting the specified source (such as a VM or View) are returned. The source id is assigned by the Cohesity Cluster.
   *
   * - `snapshotTargetTypes`: Specifies the snapshot's target type which should be filtered. Note: this field is only considered when, filterByCopyTaskEndTime is set to true, or else it is ignored.
   *
   * - `runTypes`: Filter by run type. Only protection run matching the specified types will be returned.
   *
   * - `runTags`: Specifies a list of tags for protection runs. If this is specified, only the runs which match these tags will be returned.
   *
   * - `runId`: Specifies the protection run id.
   *
   * - `requestInitiatorType`: Specifies the type of request from UI, which is used for services like magneto to determine the priority of requests.
   *
   * - `replicationRunStatus`: Specifies a list of replication status, runs matching the status will be returned.<br> 'Running' indicates that the run is still running.<br> 'Canceled' indicates that the run has been canceled.<br> 'Canceling' indicates that the run is in the process of being canceled.<br> 'Failed' indicates that the run has failed.<br> 'Missed' indicates that the run was unable to take place at the scheduled time because the previous run was still happening.<br> 'Succeeded' indicates that the run has finished successfully.<br> 'SucceededWithWarning' indicates that the run finished successfully, but there were some warning messages.<br> 'Paused' indicates that the ongoing run has been paused.<br> 'Skipped' indicates that the run was skipped.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `onlyReturnSuccessfulCopyRun`: If set to false, all copy_tasks in any given valid state will be considered. If left empty or set to true, only successful copy_tasks would be considered. Note: this field is only considered when, filterByCopyTaskEndTime is set to true, or else it is ignored.
   *
   * - `onlyReturnShellInfo`: If set, returns only shell info such as run's start time, type, error if any.
   *
   * - `onlyReturnDataMigrationJobs`: Specifies if only data stubbing jobs should be returned. If not set, no data migration job will be returned.
   *
   * - `numRuns`: Specifies the max number of runs. If not specified, at most 100 runs will be returned.
   *
   * - `localBackupRunStatus`: Specifies a list of local backup status, runs matching the status will be returned.<br> 'Running' indicates that the run is still running.<br> 'Canceled' indicates that the run has been canceled.<br> 'Canceling' indicates that the run is in the process of being canceled.<br> 'Failed' indicates that the run has failed.<br> 'Missed' indicates that the run was unable to take place at the scheduled time because the previous run was still happening.<br> 'Succeeded' indicates that the run has finished successfully.<br> 'SucceededWithWarning' indicates that the run finished successfully, but there were some warning messages.<br> 'Paused' indicates that the ongoing run has been paused.<br> 'Skipped' indicates that the run was skipped.
   *
   * - `jobRunStartTimeUsecs`: Return a specific Job Run by specifying a time and a group id. Specify the time when the Job Run started as a Unix epoch Timestamp (in microseconds). If this field is specified, jobId must also be specified.
   *
   * - `includeTenants`: If true, the response will include Protection Group Runs which were created by all tenants which the current user has permission to see. If false, then only Protection Group Runs created by the current user will be returned.
   *
   * - `includeRpoSnapshots`: If true, then the snapshots for Protection Sources protected by Rpo policies will also be returned.
   *
   * - `includeObjectDetails`: Specifies if the result includes the object details for each protection run. If set to true, details of the protected object will be returned. If set to false or not specified, details will not be returned.
   *
   * - `includeExtensionInfo`: Specifies if needs to include proto extensions if they are extended.
   *
   * - `filterByEndTime`: If true, the runs with end time within the specified time range will be returned. Otherwise, the runs with start time in the time range are returned.
   *
   * - `filterByCopyTaskEndTime`: If true, then the details of the runs for which any copyTask completed in the given timerange will be returned. Only one of filterByEndTime and filterByCopyTaskEndTime can be set.
   *
   * - `excludeNonRestorableRuns`: Specifies whether to exclude non restorable runs. Run is treated restorable only if there is atleast one object snapshot (which may be either a local or an archival snapshot) which is not deleted or expired. Default value is false.
   *
   * - `excludeErrorRuns`: Specifies whether to exclude runs with error. If no value is specified, then runs with errors are included.
   *
   * - `endTimeUsecs`: End time for time range filter. Specify the end time as a Unix epoch Timestamp (in microseconds), only runs executing before this time will be returned. By default it is current time.
   *
   * - `cloudSpinRunStatus`: Specifies a list of cloud spin status, runs matching the status will be returned.<br> 'Running' indicates that the run is still running.<br> 'Canceled' indicates that the run has been canceled.<br> 'Canceling' indicates that the run is in the process of being canceled.<br> 'Failed' indicates that the run has failed.<br> 'Missed' indicates that the run was unable to take place at the scheduled time because the previous run was still happening.<br> 'Succeeded' indicates that the run has finished successfully.<br> 'SucceededWithWarning' indicates that the run finished successfully, but there were some warning messages.<br> 'Paused' indicates that the ongoing run has been paused.<br> 'Skipped' indicates that the run was skipped.
   *
   * - `archivalRunStatus`: Specifies a list of archival status, runs matching the status will be returned.<br> 'Running' indicates that the run is still running.<br> 'Canceled' indicates that the run has been canceled.<br> 'Canceling' indicates that the run is in the process of being canceled.<br> 'Failed' indicates that the run has failed.<br> 'Missed' indicates that the run was unable to take place at the scheduled time because the previous run was still happening.<br> 'Succeeded' indicates that the run has finished successfully.<br> 'SucceededWithWarning' indicates that the run finished successfully, but there were some warning messages.<br> 'Paused' indicates that the ongoing run has been paused.<br> 'Skipped' indicates that the run was skipped.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetProtectionGroupRuns(params: ProtectionGroupService.GetProtectionGroupRunsParams): __Observable<ProtectionGroupRuns> {
    return this.GetProtectionGroupRunsResponse(params).pipe(
      __map(_r => _r.body as ProtectionGroupRuns)
    );
  }

  /**
   * Update runs for a particular Protection Group.
   *
   * Update runs for a particular Protection Group. A user can perform the following actions: 1. Extend or reduce retention of a local, replication and archival snapshots. 2. Can perform resync operation on failed copy snapshots attempts in this Run. 3. Add new replication and archival snapshot targets to the Run. 4. Add or remove legal hold on the snapshots. Only a user with DSO role can perform this operation. 5. Delete the snapshots that were created as a part of this Run. 6. Apply datalock on existing snapshots where a user cannot manually delete snapshots before the expiry time.
   * @param params The `ProtectionGroupService.UpdateProtectionGroupRunParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the Protection Group.
   *
   * - `body`: Specifies the parameters to update a Protection Group Run.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateProtectionGroupRunResponse(params: ProtectionGroupService.UpdateProtectionGroupRunParams): __Observable<__StrictHttpResponse<UpdateProtectionGroupRunResponseBody>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/data-protect/protection-groups/${encodeURIComponent(params.id)}/runs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UpdateProtectionGroupRunResponseBody>;
      })
    );
  }
  /**
   * Update runs for a particular Protection Group.
   *
   * Update runs for a particular Protection Group. A user can perform the following actions: 1. Extend or reduce retention of a local, replication and archival snapshots. 2. Can perform resync operation on failed copy snapshots attempts in this Run. 3. Add new replication and archival snapshot targets to the Run. 4. Add or remove legal hold on the snapshots. Only a user with DSO role can perform this operation. 5. Delete the snapshots that were created as a part of this Run. 6. Apply datalock on existing snapshots where a user cannot manually delete snapshots before the expiry time.
   * @param params The `ProtectionGroupService.UpdateProtectionGroupRunParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the Protection Group.
   *
   * - `body`: Specifies the parameters to update a Protection Group Run.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateProtectionGroupRun(params: ProtectionGroupService.UpdateProtectionGroupRunParams): __Observable<UpdateProtectionGroupRunResponseBody> {
    return this.UpdateProtectionGroupRunResponse(params).pipe(
      __map(_r => _r.body as UpdateProtectionGroupRunResponseBody)
    );
  }

  /**
   * Create a new protection run.
   *
   * Create a new protection run. This can be used to start a run for a Protection Group on demand, ignoring the schedule and retention specified in the protection policy.
   * @param params The `ProtectionGroupService.CreateProtectionGroupRunParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the Protection Group.
   *
   * - `body`: Specifies the parameters to start a protection run.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Accepted
   */
  CreateProtectionGroupRunResponse(params: ProtectionGroupService.CreateProtectionGroupRunParams): __Observable<__StrictHttpResponse<CreateProtectionGroupRunResponseBody>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/data-protect/protection-groups/${encodeURIComponent(params.id)}/runs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CreateProtectionGroupRunResponseBody>;
      })
    );
  }
  /**
   * Create a new protection run.
   *
   * Create a new protection run. This can be used to start a run for a Protection Group on demand, ignoring the schedule and retention specified in the protection policy.
   * @param params The `ProtectionGroupService.CreateProtectionGroupRunParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the Protection Group.
   *
   * - `body`: Specifies the parameters to start a protection run.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Accepted
   */
  CreateProtectionGroupRun(params: ProtectionGroupService.CreateProtectionGroupRunParams): __Observable<CreateProtectionGroupRunResponseBody> {
    return this.CreateProtectionGroupRunResponse(params).pipe(
      __map(_r => _r.body as CreateProtectionGroupRunResponseBody)
    );
  }

  /**
   * Actions on protection group run.
   *
   * Perform various actions on a Protection Group run.
   * @param params The `ProtectionGroupService.PerformActionOnProtectionGroupRunParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the Protection Group.
   *
   * - `body`: Specifies the parameters to perform an action on a protection run.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Accepted
   */
  PerformActionOnProtectionGroupRunResponse(params: ProtectionGroupService.PerformActionOnProtectionGroupRunParams): __Observable<__StrictHttpResponse<PerformRunActionResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/data-protect/protection-groups/${encodeURIComponent(params.id)}/runs/actions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PerformRunActionResponse>;
      })
    );
  }
  /**
   * Actions on protection group run.
   *
   * Perform various actions on a Protection Group run.
   * @param params The `ProtectionGroupService.PerformActionOnProtectionGroupRunParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the Protection Group.
   *
   * - `body`: Specifies the parameters to perform an action on a protection run.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Accepted
   */
  PerformActionOnProtectionGroupRun(params: ProtectionGroupService.PerformActionOnProtectionGroupRunParams): __Observable<PerformRunActionResponse> {
    return this.PerformActionOnProtectionGroupRunResponse(params).pipe(
      __map(_r => _r.body as PerformRunActionResponse)
    );
  }

  /**
   * Get a run for a Protection Group.
   *
   * Get a run for a particular Protection Group.
   * @param params The `ProtectionGroupService.GetProtectionGroupRunParams` containing the following parameters:
   *
   * - `runId`: Specifies a unique run id of the Protection Group run.
   *
   * - `id`: Specifies a unique id of the Protection Group.
   *
   * - `useCachedData`: Specifies whether we can serve the GET request from the read replica cache. There is a lag of 15 seconds between the read replica and primary data source.
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which the run is to be returned.
   *
   * - `requestInitiatorType`: Specifies the type of request from UI, which is used for services like magneto to determine the priority of requests.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `includeTenants`: If true, the response will include Protection Group Runs which were created by all tenants which the current user has permission to see. If false, then only Protection Groups created by the current user will be returned. If it's not specified, it is true by default.
   *
   * - `includeObjectDetails`: Specifies if the result includes the object details for a protection run. If set to true, details of the protected object will be returned. If set to false or not specified, details will not be returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetProtectionGroupRunResponse(params: ProtectionGroupService.GetProtectionGroupRunParams): __Observable<__StrictHttpResponse<ProtectionGroupRun>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.useCachedData != null) __params = __params.set('useCachedData', params.useCachedData.toString());
    (params.tenantIds || []).forEach(val => {if (val != null) __params = __params.append('tenantIds', val.toString())});
    if (params.requestInitiatorType != null) __headers = __headers.set('requestInitiatorType', params.requestInitiatorType.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.includeTenants != null) __params = __params.set('includeTenants', params.includeTenants.toString());
    if (params.includeObjectDetails != null) __params = __params.set('includeObjectDetails', params.includeObjectDetails.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/data-protect/protection-groups/${encodeURIComponent(params.id)}/runs/${encodeURIComponent(params.runId)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ProtectionGroupRun>;
      })
    );
  }
  /**
   * Get a run for a Protection Group.
   *
   * Get a run for a particular Protection Group.
   * @param params The `ProtectionGroupService.GetProtectionGroupRunParams` containing the following parameters:
   *
   * - `runId`: Specifies a unique run id of the Protection Group run.
   *
   * - `id`: Specifies a unique id of the Protection Group.
   *
   * - `useCachedData`: Specifies whether we can serve the GET request from the read replica cache. There is a lag of 15 seconds between the read replica and primary data source.
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which the run is to be returned.
   *
   * - `requestInitiatorType`: Specifies the type of request from UI, which is used for services like magneto to determine the priority of requests.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `includeTenants`: If true, the response will include Protection Group Runs which were created by all tenants which the current user has permission to see. If false, then only Protection Groups created by the current user will be returned. If it's not specified, it is true by default.
   *
   * - `includeObjectDetails`: Specifies if the result includes the object details for a protection run. If set to true, details of the protected object will be returned. If set to false or not specified, details will not be returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetProtectionGroupRun(params: ProtectionGroupService.GetProtectionGroupRunParams): __Observable<ProtectionGroupRun> {
    return this.GetProtectionGroupRunResponse(params).pipe(
      __map(_r => _r.body as ProtectionGroupRun)
    );
  }

  /**
   * Get the debug logs for a run from a Protection Group.
   *
   * Get the debug logs for all objects of a run for a particular Protection Group.
   * @param params The `ProtectionGroupService.GetRunDebugLogsParams` containing the following parameters:
   *
   * - `runId`: Specifies a unique run id of the Protection Group run.
   *
   * - `id`: Specifies a unique id of the Protection Group.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `objectId`: Specifies the id of the object for which debug logs are to be returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  GetRunDebugLogsResponse(params: ProtectionGroupService.GetRunDebugLogsParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.objectId != null) __params = __params.set('objectId', params.objectId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/data-protect/protection-groups/${encodeURIComponent(params.id)}/runs/${encodeURIComponent(params.runId)}/debug-logs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Get the debug logs for a run from a Protection Group.
   *
   * Get the debug logs for all objects of a run for a particular Protection Group.
   * @param params The `ProtectionGroupService.GetRunDebugLogsParams` containing the following parameters:
   *
   * - `runId`: Specifies a unique run id of the Protection Group run.
   *
   * - `id`: Specifies a unique id of the Protection Group.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `objectId`: Specifies the id of the object for which debug logs are to be returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  GetRunDebugLogs(params: ProtectionGroupService.GetRunDebugLogsParams): __Observable<null> {
    return this.GetRunDebugLogsResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get the debug logs for a particular object in a run from a Protection Group.
   *
   * Get the debug logs for a particular object of a run for a particular Protection Group.
   * @param params The `ProtectionGroupService.GetRunDebugLogsForObjectParams` containing the following parameters:
   *
   * - `runId`: Specifies a unique run id of the Protection Group run.
   *
   * - `objectId`: Specifies the id of the object for which debug logs are to be returned.
   *
   * - `id`: Specifies a unique id of the Protection Group.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  GetRunDebugLogsForObjectResponse(params: ProtectionGroupService.GetRunDebugLogsForObjectParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/data-protect/protection-groups/${encodeURIComponent(params.id)}/runs/${encodeURIComponent(params.runId)}/objects/${encodeURIComponent(params.objectId)}/debug-logs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Get the debug logs for a particular object in a run from a Protection Group.
   *
   * Get the debug logs for a particular object of a run for a particular Protection Group.
   * @param params The `ProtectionGroupService.GetRunDebugLogsForObjectParams` containing the following parameters:
   *
   * - `runId`: Specifies a unique run id of the Protection Group run.
   *
   * - `objectId`: Specifies the id of the object for which debug logs are to be returned.
   *
   * - `id`: Specifies a unique id of the Protection Group.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  GetRunDebugLogsForObject(params: ProtectionGroupService.GetRunDebugLogsForObjectParams): __Observable<null> {
    return this.GetRunDebugLogsForObjectResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get the CSV of various Proto Messages for a given run and an object.
   *
   * Get an CSV report for given objectId and run id. Each row in CSV report contains the fields from correspoinding proto message.
   * @param params The `ProtectionGroupService.GetRunMessagesReportParams` containing the following parameters:
   *
   * - `runId`: Specifies a unique run id of the Protection Group run.
   *
   * - `objectId`: Specifies the id of the object for which errors/warnings are to be returned.
   *
   * - `id`: Specifies a unique id of the Protection Group.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `name`: Specifies the name of the source being backed up
   *
   * - `fileType`: Specifies the downloaded type, i.e: inclusion_exclusion_reports, error_files_list. default: error_files_list
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  GetRunMessagesReportResponse(params: ProtectionGroupService.GetRunMessagesReportParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.name != null) __params = __params.set('name', params.name.toString());
    if (params.fileType != null) __params = __params.set('fileType', params.fileType.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/data-protect/protection-groups/${encodeURIComponent(params.id)}/runs/${encodeURIComponent(params.runId)}/objects/${encodeURIComponent(params.objectId)}/download-messages`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Get the CSV of various Proto Messages for a given run and an object.
   *
   * Get an CSV report for given objectId and run id. Each row in CSV report contains the fields from correspoinding proto message.
   * @param params The `ProtectionGroupService.GetRunMessagesReportParams` containing the following parameters:
   *
   * - `runId`: Specifies a unique run id of the Protection Group run.
   *
   * - `objectId`: Specifies the id of the object for which errors/warnings are to be returned.
   *
   * - `id`: Specifies a unique id of the Protection Group.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `name`: Specifies the name of the source being backed up
   *
   * - `fileType`: Specifies the downloaded type, i.e: inclusion_exclusion_reports, error_files_list. default: error_files_list
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  GetRunMessagesReport(params: ProtectionGroupService.GetRunMessagesReportParams): __Observable<null> {
    return this.GetRunMessagesReportResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get the CSV of errors/warnings for a given run and an object.
   *
   * Get an CSV report for given objectId and run id. Report will depend on the query parameter fileType, default will be: success_files_list where each row contains the name of file backedup successfully.
   * @param params The `ProtectionGroupService.GetRunsReportParams` containing the following parameters:
   *
   * - `runId`: Specifies a unique run id of the Protection Group run.
   *
   * - `objectId`: Specifies the id of the object for which errors/warnings are to be returned.
   *
   * - `id`: Specifies a unique id of the Protection Group.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `name`: Specifies the name of the source being backed up
   *
   * - `fileType`: Specifies the downloaded type, i.e: success_files_list, default: success_files_list
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  GetRunsReportResponse(params: ProtectionGroupService.GetRunsReportParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.name != null) __params = __params.set('name', params.name.toString());
    if (params.fileType != null) __params = __params.set('fileType', params.fileType.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/data-protect/protection-groups/${encodeURIComponent(params.id)}/runs/${encodeURIComponent(params.runId)}/objects/${encodeURIComponent(params.objectId)}/downloadFiles`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Get the CSV of errors/warnings for a given run and an object.
   *
   * Get an CSV report for given objectId and run id. Report will depend on the query parameter fileType, default will be: success_files_list where each row contains the name of file backedup successfully.
   * @param params The `ProtectionGroupService.GetRunsReportParams` containing the following parameters:
   *
   * - `runId`: Specifies a unique run id of the Protection Group run.
   *
   * - `objectId`: Specifies the id of the object for which errors/warnings are to be returned.
   *
   * - `id`: Specifies a unique id of the Protection Group.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `name`: Specifies the name of the source being backed up
   *
   * - `fileType`: Specifies the downloaded type, i.e: success_files_list, default: success_files_list
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  GetRunsReport(params: ProtectionGroupService.GetRunsReportParams): __Observable<null> {
    return this.GetRunsReportResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get the list of runs.
   *
   * Get a list of protection runs.
   * @param params The `ProtectionGroupService.GetProtectionRunsParams` containing the following parameters:
   *
   * - `startTimeUsecs`: Start time for time range filter. Specify the start time as a Unix epoch Timestamp (in microseconds), only runs executing after this time will be returned. By default it is endTimeUsecs minus an hour.
   *
   * - `runStatus`: Specifies a list of status, runs matching the status will be returned.<br> 'Running' indicates that the run is still running.<br> 'Canceled' indicates that the run has been canceled.<br> 'Canceling' indicates that the run is in the process of being canceled.<br> 'Failed' indicates that the run has failed.<br> 'Missed' indicates that the run was unable to take place at the scheduled time because the previous run was still happening.<br> 'Succeeded' indicates that the run has finished successfully.<br> 'SucceededWithWarning' indicates that the run finished successfully, but there were some warning messages.<br> 'Skipped' indicates that the run was skipped.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `endTimeUsecs`: End time for time range filter. Specify the end time as a Unix epoch Timestamp (in microseconds), only runs executing before this time will be returned. By default it is current time.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetProtectionRunsResponse(params: ProtectionGroupService.GetProtectionRunsParams): __Observable<__StrictHttpResponse<ProtectionRunsSummary>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startTimeUsecs != null) __params = __params.set('startTimeUsecs', params.startTimeUsecs.toString());
    (params.runStatus || []).forEach(val => {if (val != null) __params = __params.append('runStatus', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.endTimeUsecs != null) __params = __params.set('endTimeUsecs', params.endTimeUsecs.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/data-protect/runs/summary`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ProtectionRunsSummary>;
      })
    );
  }
  /**
   * Get the list of runs.
   *
   * Get a list of protection runs.
   * @param params The `ProtectionGroupService.GetProtectionRunsParams` containing the following parameters:
   *
   * - `startTimeUsecs`: Start time for time range filter. Specify the start time as a Unix epoch Timestamp (in microseconds), only runs executing after this time will be returned. By default it is endTimeUsecs minus an hour.
   *
   * - `runStatus`: Specifies a list of status, runs matching the status will be returned.<br> 'Running' indicates that the run is still running.<br> 'Canceled' indicates that the run has been canceled.<br> 'Canceling' indicates that the run is in the process of being canceled.<br> 'Failed' indicates that the run has failed.<br> 'Missed' indicates that the run was unable to take place at the scheduled time because the previous run was still happening.<br> 'Succeeded' indicates that the run has finished successfully.<br> 'SucceededWithWarning' indicates that the run finished successfully, but there were some warning messages.<br> 'Skipped' indicates that the run was skipped.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `endTimeUsecs`: End time for time range filter. Specify the end time as a Unix epoch Timestamp (in microseconds), only runs executing before this time will be returned. By default it is current time.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetProtectionRuns(params: ProtectionGroupService.GetProtectionRunsParams): __Observable<ProtectionRunsSummary> {
    return this.GetProtectionRunsResponse(params).pipe(
      __map(_r => _r.body as ProtectionRunsSummary)
    );
  }

  /**
   * Get the progress of a run.
   *
   * Get the progress of a run.
   * @param params The `ProtectionGroupService.GetProtectionRunProgressParams` containing the following parameters:
   *
   * - `runId`: Specifies a unique run id of the Protection Run.
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which the run is to be returned.
   *
   * - `startTimeUsecs`: Specifies the time after which the progress task starts in Unix epoch Timestamp(in microseconds).
   *
   * - `runTaskPath`: Specifies the task path of the run or object run. This is applicable only if progress of a protection group with one or more object is required.If provided this will be used to fetch progress details directly without looking actual task path of the object. Objects field is stil expected else it changes the response format.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `objects`: Specifies the objects whose progress will be returned. This only applies to protection group runs and will be ignored for object runs. If the objects are specified, the run progress will not be returned and only the progress of the specified objects will be returned.
   *
   * - `objectTaskPaths`: Specifies the object level task path. This relates to the objectID. If provided this will take precedence over the objects, and will be used to fetch progress details directly without looking actuall task path of the object.
   *
   * - `maxTasksNum`: Specifies the maximum number of tasks to return.
   *
   * - `maxLogLevel`: Specifies the number of levels till which to fetch the event logs. This is applicable only when includeEventLogs is true.
   *
   * - `includeTenants`: If true, the response will include Protection Group Runs which were created by all tenants which the current user has permission to see. If false, then only Protection Groups created by the current user will be returned. If it's not specified, it is true by default.
   *
   * - `includeFinishedTasks`: Specifies whether to return finished tasks. By default only active tasks are returned.
   *
   * - `includeEventLogs`: Specifies whether to include event logs
   *
   * - `excludeObjectDetails`: Specifies whether to return objects. By default all the task tree are returned.
   *
   * - `endTimeUsecs`: Specifies the time before which the progress task ends in Unix epoch Timestamp(in microseconds).
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetProtectionRunProgressResponse(params: ProtectionGroupService.GetProtectionRunProgressParams): __Observable<__StrictHttpResponse<GetProtectionRunProgressBody>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    (params.tenantIds || []).forEach(val => {if (val != null) __params = __params.append('tenantIds', val.toString())});
    if (params.startTimeUsecs != null) __params = __params.set('startTimeUsecs', params.startTimeUsecs.toString());
    if (params.runTaskPath != null) __params = __params.set('runTaskPath', params.runTaskPath.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    (params.objects || []).forEach(val => {if (val != null) __params = __params.append('objects', val.toString())});
    (params.objectTaskPaths || []).forEach(val => {if (val != null) __params = __params.append('objectTaskPaths', val.toString())});
    if (params.maxTasksNum != null) __params = __params.set('maxTasksNum', params.maxTasksNum.toString());
    if (params.maxLogLevel != null) __params = __params.set('maxLogLevel', params.maxLogLevel.toString());
    if (params.includeTenants != null) __params = __params.set('includeTenants', params.includeTenants.toString());
    if (params.includeFinishedTasks != null) __params = __params.set('includeFinishedTasks', params.includeFinishedTasks.toString());
    if (params.includeEventLogs != null) __params = __params.set('includeEventLogs', params.includeEventLogs.toString());
    if (params.excludeObjectDetails != null) __params = __params.set('excludeObjectDetails', params.excludeObjectDetails.toString());
    if (params.endTimeUsecs != null) __params = __params.set('endTimeUsecs', params.endTimeUsecs.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/data-protect/runs/${encodeURIComponent(params.runId)}/progress`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetProtectionRunProgressBody>;
      })
    );
  }
  /**
   * Get the progress of a run.
   *
   * Get the progress of a run.
   * @param params The `ProtectionGroupService.GetProtectionRunProgressParams` containing the following parameters:
   *
   * - `runId`: Specifies a unique run id of the Protection Run.
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which the run is to be returned.
   *
   * - `startTimeUsecs`: Specifies the time after which the progress task starts in Unix epoch Timestamp(in microseconds).
   *
   * - `runTaskPath`: Specifies the task path of the run or object run. This is applicable only if progress of a protection group with one or more object is required.If provided this will be used to fetch progress details directly without looking actual task path of the object. Objects field is stil expected else it changes the response format.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `objects`: Specifies the objects whose progress will be returned. This only applies to protection group runs and will be ignored for object runs. If the objects are specified, the run progress will not be returned and only the progress of the specified objects will be returned.
   *
   * - `objectTaskPaths`: Specifies the object level task path. This relates to the objectID. If provided this will take precedence over the objects, and will be used to fetch progress details directly without looking actuall task path of the object.
   *
   * - `maxTasksNum`: Specifies the maximum number of tasks to return.
   *
   * - `maxLogLevel`: Specifies the number of levels till which to fetch the event logs. This is applicable only when includeEventLogs is true.
   *
   * - `includeTenants`: If true, the response will include Protection Group Runs which were created by all tenants which the current user has permission to see. If false, then only Protection Groups created by the current user will be returned. If it's not specified, it is true by default.
   *
   * - `includeFinishedTasks`: Specifies whether to return finished tasks. By default only active tasks are returned.
   *
   * - `includeEventLogs`: Specifies whether to include event logs
   *
   * - `excludeObjectDetails`: Specifies whether to return objects. By default all the task tree are returned.
   *
   * - `endTimeUsecs`: Specifies the time before which the progress task ends in Unix epoch Timestamp(in microseconds).
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetProtectionRunProgress(params: ProtectionGroupService.GetProtectionRunProgressParams): __Observable<GetProtectionRunProgressBody> {
    return this.GetProtectionRunProgressResponse(params).pipe(
      __map(_r => _r.body as GetProtectionRunProgressBody)
    );
  }

  /**
   * Get the stats for a run.
   *
   * Get the stats for a run.
   * @param params The `ProtectionGroupService.GetProtectionRunStatsParams` containing the following parameters:
   *
   * - `runId`: Specifies a unique run id of the Protection Run.
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which the run is to be returned.
   *
   * - `startTimeUsecs`: Specifies the time after which the stats task starts in Unix epoch Timestamp(in microseconds).
   *
   * - `runTaskPath`: Specifies the task path of the run or object run. This is applicable only if stats of a protection group with one or more object is required. If provided this will be used to fetch stats details directly without looking actual task path of the object. Objects field is stil expected else it changes the response format.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `objects`: Specifies the objects whose stats will be returned. This only applies to protection group runs and will be ignored for object runs. If the objects are specified, the run stats will not be returned and only the stats of the specified objects will be returned.
   *
   * - `objectTaskPaths`: Specifies the object level task path. This relates to the objectID. If provided this will take precedence over the objects, and will be used to fetch stats details directly without looking actuall task path of the object.
   *
   * - `maxTasksNum`: Specifies the maximum number of tasks to return.
   *
   * - `includeTenants`: If true, the response will include Protection Group Runs which were created by all tenants which the current user has permission to see. If false, then only Protection Groups created by the current user will be returned. If it's not specified, it is true by default.
   *
   * - `includeFinishedTasks`: Specifies whether to return finished tasks. By default only active tasks are returned.
   *
   * - `excludeObjectDetails`: Specifies whether to return objects. By default all the task tree are returned.
   *
   * - `endTimeUsecs`: Specifies the time before which the stats task ends in Unix epoch Timestamp(in microseconds).
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetProtectionRunStatsResponse(params: ProtectionGroupService.GetProtectionRunStatsParams): __Observable<__StrictHttpResponse<GetProtectionRunStatsBody>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    (params.tenantIds || []).forEach(val => {if (val != null) __params = __params.append('tenantIds', val.toString())});
    if (params.startTimeUsecs != null) __params = __params.set('startTimeUsecs', params.startTimeUsecs.toString());
    if (params.runTaskPath != null) __params = __params.set('runTaskPath', params.runTaskPath.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    (params.objects || []).forEach(val => {if (val != null) __params = __params.append('objects', val.toString())});
    (params.objectTaskPaths || []).forEach(val => {if (val != null) __params = __params.append('objectTaskPaths', val.toString())});
    if (params.maxTasksNum != null) __params = __params.set('maxTasksNum', params.maxTasksNum.toString());
    if (params.includeTenants != null) __params = __params.set('includeTenants', params.includeTenants.toString());
    if (params.includeFinishedTasks != null) __params = __params.set('includeFinishedTasks', params.includeFinishedTasks.toString());
    if (params.excludeObjectDetails != null) __params = __params.set('excludeObjectDetails', params.excludeObjectDetails.toString());
    if (params.endTimeUsecs != null) __params = __params.set('endTimeUsecs', params.endTimeUsecs.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/data-protect/runs/${encodeURIComponent(params.runId)}/stats`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetProtectionRunStatsBody>;
      })
    );
  }
  /**
   * Get the stats for a run.
   *
   * Get the stats for a run.
   * @param params The `ProtectionGroupService.GetProtectionRunStatsParams` containing the following parameters:
   *
   * - `runId`: Specifies a unique run id of the Protection Run.
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which the run is to be returned.
   *
   * - `startTimeUsecs`: Specifies the time after which the stats task starts in Unix epoch Timestamp(in microseconds).
   *
   * - `runTaskPath`: Specifies the task path of the run or object run. This is applicable only if stats of a protection group with one or more object is required. If provided this will be used to fetch stats details directly without looking actual task path of the object. Objects field is stil expected else it changes the response format.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `objects`: Specifies the objects whose stats will be returned. This only applies to protection group runs and will be ignored for object runs. If the objects are specified, the run stats will not be returned and only the stats of the specified objects will be returned.
   *
   * - `objectTaskPaths`: Specifies the object level task path. This relates to the objectID. If provided this will take precedence over the objects, and will be used to fetch stats details directly without looking actuall task path of the object.
   *
   * - `maxTasksNum`: Specifies the maximum number of tasks to return.
   *
   * - `includeTenants`: If true, the response will include Protection Group Runs which were created by all tenants which the current user has permission to see. If false, then only Protection Groups created by the current user will be returned. If it's not specified, it is true by default.
   *
   * - `includeFinishedTasks`: Specifies whether to return finished tasks. By default only active tasks are returned.
   *
   * - `excludeObjectDetails`: Specifies whether to return objects. By default all the task tree are returned.
   *
   * - `endTimeUsecs`: Specifies the time before which the stats task ends in Unix epoch Timestamp(in microseconds).
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetProtectionRunStats(params: ProtectionGroupService.GetProtectionRunStatsParams): __Observable<GetProtectionRunStatsBody> {
    return this.GetProtectionRunStatsResponse(params).pipe(
      __map(_r => _r.body as GetProtectionRunStatsBody)
    );
  }

  /**
   * Get Protection Group activity on Helios.
   *
   * Get Protection Group activity on Helios.
   * @param params The `ProtectionGroupService.GetMcmProtectionGroupsActivityParams` containing the following parameters:
   *
   * - `rpaasRegionIds`: Filter by a list of region ids. This is used for Rpaas only.
   *
   * - `rpaasGlobalVaultIds`: Filter by a list of FortKnox global vault ids. This is used for FortKnox only. When rpaasGlobalVaultIds is specified, rpaasRegionIds will be ignored.
   *
   * - `regionIds`: Filter by a list of region ids.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `clusterIdentifiers`: Filter by a list of cluster identifiers in format of clusterId:clusterIncarnationId.
   *
   * - `body`: Request parameters to filter Protection Group activity.
   *
   * @return Success
   */
  GetMcmProtectionGroupsActivityResponse(params: ProtectionGroupService.GetMcmProtectionGroupsActivityParams): __Observable<__StrictHttpResponse<McmProtectionGroupsActivity>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.rpaasRegionIds || []).forEach(val => {if (val != null) __params = __params.append('rpaasRegionIds', val.toString())});
    (params.rpaasGlobalVaultIds || []).forEach(val => {if (val != null) __params = __params.append('rpaasGlobalVaultIds', val.toString())});
    (params.regionIds || []).forEach(val => {if (val != null) __params = __params.append('regionIds', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    (params.clusterIdentifiers || []).forEach(val => {if (val != null) __params = __params.append('clusterIdentifiers', val.toString())});
    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/mcm/data-protect/protection-group/activity`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<McmProtectionGroupsActivity>;
      })
    );
  }
  /**
   * Get Protection Group activity on Helios.
   *
   * Get Protection Group activity on Helios.
   * @param params The `ProtectionGroupService.GetMcmProtectionGroupsActivityParams` containing the following parameters:
   *
   * - `rpaasRegionIds`: Filter by a list of region ids. This is used for Rpaas only.
   *
   * - `rpaasGlobalVaultIds`: Filter by a list of FortKnox global vault ids. This is used for FortKnox only. When rpaasGlobalVaultIds is specified, rpaasRegionIds will be ignored.
   *
   * - `regionIds`: Filter by a list of region ids.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `clusterIdentifiers`: Filter by a list of cluster identifiers in format of clusterId:clusterIncarnationId.
   *
   * - `body`: Request parameters to filter Protection Group activity.
   *
   * @return Success
   */
  GetMcmProtectionGroupsActivity(params: ProtectionGroupService.GetMcmProtectionGroupsActivityParams): __Observable<McmProtectionGroupsActivity> {
    return this.GetMcmProtectionGroupsActivityResponse(params).pipe(
      __map(_r => _r.body as McmProtectionGroupsActivity)
    );
  }

  /**
   * Get the list of Protection Groups.
   *
   * Get the list of Protection Groups.
   * @param params The `ProtectionGroupService.GetMcmProtectionGroupsParams` containing the following parameters:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `policyIds`: Filter by Policy ids that are associated with Protection Groups. Only Protection Groups associated with the specified Policy ids, are returned.
   *
   * - `names`: Filter by a list of Protection Group names.
   *
   * - `lastRunAnyStatus`: Filter by last any run status.<br> 'Running' indicates that the run is still running.<br> 'Canceled' indicates that the run has been canceled.<br> 'Canceling' indicates that the run is in the process of being canceled.<br> 'Failed' indicates that the run has failed.<br> 'Missed' indicates that the run was unable to take place at the scheduled time because the previous run was still happening.<br> 'Succeeded' indicates that the run has finished successfully.<br> 'SucceededWithWarning' indicates that the run finished successfully, but there were some warning messages. 'Skipped' indicates that the run was skipped.
   *
   * - `isPaused`: Filter by paused or non paused Protection Groups, If not set, all paused and non paused Protection Groups are returned. If true, only paused Protection Groups are returned. If false, only non paused Protection Groups are returned.
   *
   * - `isLastRunSlaViolated`: If true, return Protection Groups for which last run SLA was violated.
   *
   * - `isDeleted`: If true, return only Protection Groups that have been deleted but still have Snapshots associated with them. If false, return all Protection Groups except those Protection Groups that have been deleted and still have Snapshots associated with them. A Protection Group that is deleted with all its Snapshots is not returned for either of these cases.
   *
   * - `isActive`: Filter by Inactive or Active Protection Groups. If not set, all Inactive and Active Protection Groups are returned. If true, only Active Protection Groups are returned. If false, only Inactive Protection Groups are returned. When you create a Protection Group on a Primary Cluster with a replication schedule, the Cluster creates an Inactive copy of the Protection Group on the Remote Cluster. In addition, when an Active and running Protection Group is deactivated, the Protection Group becomes Inactive.
   *
   * - `includeLastRunInfo`: If true, the response will include last run info. If it is false or not specified, the last run info won't be returned.
   *
   * - `ids`: Filter by a list of Protection Group ids.
   *
   * - `environments`: Filter by environment types such as 'kVMware', 'kView', etc. Only Protection Groups protecting the specified environment types are returned.
   *
   * @return Success
   */
  GetMcmProtectionGroupsResponse(params: ProtectionGroupService.GetMcmProtectionGroupsParams): __Observable<__StrictHttpResponse<ProtectionGroups>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    (params.policyIds || []).forEach(val => {if (val != null) __params = __params.append('policyIds', val.toString())});
    (params.names || []).forEach(val => {if (val != null) __params = __params.append('names', val.toString())});
    (params.lastRunAnyStatus || []).forEach(val => {if (val != null) __params = __params.append('lastRunAnyStatus', val.toString())});
    if (params.isPaused != null) __params = __params.set('isPaused', params.isPaused.toString());
    if (params.isLastRunSlaViolated != null) __params = __params.set('isLastRunSlaViolated', params.isLastRunSlaViolated.toString());
    if (params.isDeleted != null) __params = __params.set('isDeleted', params.isDeleted.toString());
    if (params.isActive != null) __params = __params.set('isActive', params.isActive.toString());
    if (params.includeLastRunInfo != null) __params = __params.set('includeLastRunInfo', params.includeLastRunInfo.toString());
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    (params.environments || []).forEach(val => {if (val != null) __params = __params.append('environments', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/mcm/data-protect/protection-groups`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ProtectionGroups>;
      })
    );
  }
  /**
   * Get the list of Protection Groups.
   *
   * Get the list of Protection Groups.
   * @param params The `ProtectionGroupService.GetMcmProtectionGroupsParams` containing the following parameters:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `policyIds`: Filter by Policy ids that are associated with Protection Groups. Only Protection Groups associated with the specified Policy ids, are returned.
   *
   * - `names`: Filter by a list of Protection Group names.
   *
   * - `lastRunAnyStatus`: Filter by last any run status.<br> 'Running' indicates that the run is still running.<br> 'Canceled' indicates that the run has been canceled.<br> 'Canceling' indicates that the run is in the process of being canceled.<br> 'Failed' indicates that the run has failed.<br> 'Missed' indicates that the run was unable to take place at the scheduled time because the previous run was still happening.<br> 'Succeeded' indicates that the run has finished successfully.<br> 'SucceededWithWarning' indicates that the run finished successfully, but there were some warning messages. 'Skipped' indicates that the run was skipped.
   *
   * - `isPaused`: Filter by paused or non paused Protection Groups, If not set, all paused and non paused Protection Groups are returned. If true, only paused Protection Groups are returned. If false, only non paused Protection Groups are returned.
   *
   * - `isLastRunSlaViolated`: If true, return Protection Groups for which last run SLA was violated.
   *
   * - `isDeleted`: If true, return only Protection Groups that have been deleted but still have Snapshots associated with them. If false, return all Protection Groups except those Protection Groups that have been deleted and still have Snapshots associated with them. A Protection Group that is deleted with all its Snapshots is not returned for either of these cases.
   *
   * - `isActive`: Filter by Inactive or Active Protection Groups. If not set, all Inactive and Active Protection Groups are returned. If true, only Active Protection Groups are returned. If false, only Inactive Protection Groups are returned. When you create a Protection Group on a Primary Cluster with a replication schedule, the Cluster creates an Inactive copy of the Protection Group on the Remote Cluster. In addition, when an Active and running Protection Group is deactivated, the Protection Group becomes Inactive.
   *
   * - `includeLastRunInfo`: If true, the response will include last run info. If it is false or not specified, the last run info won't be returned.
   *
   * - `ids`: Filter by a list of Protection Group ids.
   *
   * - `environments`: Filter by environment types such as 'kVMware', 'kView', etc. Only Protection Groups protecting the specified environment types are returned.
   *
   * @return Success
   */
  GetMcmProtectionGroups(params: ProtectionGroupService.GetMcmProtectionGroupsParams): __Observable<ProtectionGroups> {
    return this.GetMcmProtectionGroupsResponse(params).pipe(
      __map(_r => _r.body as ProtectionGroups)
    );
  }

  /**
   * Create a Protection Group.
   *
   * Create a Protection Group.
   * @param body Specifies the parameters to create a Protection Group.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  CreateMcmProtectionGroupResponse(body: CreateOrUpdateProtectionGroupRequest,
    regionId?: string): __Observable<__StrictHttpResponse<ProtectionGroup>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/mcm/data-protect/protection-groups`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ProtectionGroup>;
      })
    );
  }
  /**
   * Create a Protection Group.
   *
   * Create a Protection Group.
   * @param body Specifies the parameters to create a Protection Group.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  CreateMcmProtectionGroup(body: CreateOrUpdateProtectionGroupRequest,
    regionId?: string): __Observable<ProtectionGroup> {
    return this.CreateMcmProtectionGroupResponse(body, regionId).pipe(
      __map(_r => _r.body as ProtectionGroup)
    );
  }

  /**
   * List details about single Protection Group.
   *
   * Returns the Protection Group corresponding to the specified Group id.
   * @param params The `ProtectionGroupService.GetMcmProtectionGroupByIdParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the Protection Group.
   *
   * - `requestInitiatorType`: Specifies the type of request from UI, which is used for services like magneto to determine the priority of requests.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `includeLastRunInfo`: If true, the response will include last run info. If it is false or not specified, the last run info won't be returned.
   *
   * @return Success
   */
  GetMcmProtectionGroupByIdResponse(params: ProtectionGroupService.GetMcmProtectionGroupByIdParams): __Observable<__StrictHttpResponse<ProtectionGroup>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.requestInitiatorType != null) __headers = __headers.set('requestInitiatorType', params.requestInitiatorType.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.includeLastRunInfo != null) __params = __params.set('includeLastRunInfo', params.includeLastRunInfo.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/mcm/data-protect/protection-groups/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ProtectionGroup>;
      })
    );
  }
  /**
   * List details about single Protection Group.
   *
   * Returns the Protection Group corresponding to the specified Group id.
   * @param params The `ProtectionGroupService.GetMcmProtectionGroupByIdParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the Protection Group.
   *
   * - `requestInitiatorType`: Specifies the type of request from UI, which is used for services like magneto to determine the priority of requests.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `includeLastRunInfo`: If true, the response will include last run info. If it is false or not specified, the last run info won't be returned.
   *
   * @return Success
   */
  GetMcmProtectionGroupById(params: ProtectionGroupService.GetMcmProtectionGroupByIdParams): __Observable<ProtectionGroup> {
    return this.GetMcmProtectionGroupByIdResponse(params).pipe(
      __map(_r => _r.body as ProtectionGroup)
    );
  }

  /**
   * Update a Protection Group.
   *
   * Update the specified Protection Group.
   * @param params The `ProtectionGroupService.UpdateMcmProtectionGroupParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the Protection Group.
   *
   * - `body`: Specifies the parameters to update a Protection Group.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * @return Success
   */
  UpdateMcmProtectionGroupResponse(params: ProtectionGroupService.UpdateMcmProtectionGroupParams): __Observable<__StrictHttpResponse<ProtectionGroup>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/mcm/data-protect/protection-groups/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ProtectionGroup>;
      })
    );
  }
  /**
   * Update a Protection Group.
   *
   * Update the specified Protection Group.
   * @param params The `ProtectionGroupService.UpdateMcmProtectionGroupParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the Protection Group.
   *
   * - `body`: Specifies the parameters to update a Protection Group.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * @return Success
   */
  UpdateMcmProtectionGroup(params: ProtectionGroupService.UpdateMcmProtectionGroupParams): __Observable<ProtectionGroup> {
    return this.UpdateMcmProtectionGroupResponse(params).pipe(
      __map(_r => _r.body as ProtectionGroup)
    );
  }

  /**
   * Delete a Protection Group.
   *
   * Returns Success if the Protection Group is deleted.
   * @param params The `ProtectionGroupService.DeleteMcmProtectionGroupParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the Protection Group.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `deleteSnapshots`: Specifies if Snapshots generated by the Protection Group should also be deleted when the Protection Group is deleted.
   */
  DeleteMcmProtectionGroupResponse(params: ProtectionGroupService.DeleteMcmProtectionGroupParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.deleteSnapshots != null) __params = __params.set('deleteSnapshots', params.deleteSnapshots.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/mcm/data-protect/protection-groups/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete a Protection Group.
   *
   * Returns Success if the Protection Group is deleted.
   * @param params The `ProtectionGroupService.DeleteMcmProtectionGroupParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the Protection Group.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `deleteSnapshots`: Specifies if Snapshots generated by the Protection Group should also be deleted when the Protection Group is deleted.
   */
  DeleteMcmProtectionGroup(params: ProtectionGroupService.DeleteMcmProtectionGroupParams): __Observable<null> {
    return this.DeleteMcmProtectionGroupResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module ProtectionGroupService {

  /**
   * Parameters for GetProtectionGroups
   */
  export interface GetProtectionGroupsParams {

    /**
     * Specifies whether we can serve the GET request from the read replica cache. There is a lag of 15 seconds between the read replica and primary data source.
     */
    useCachedData?: boolean;

    /**
     * TenantIds contains ids of the tenants for which objects are to be returned.
     */
    tenantIds?: Array<string>;

    /**
     * Filter by Storage Domain id. Only Protection Groups writing data to this Storage Domain will be returned.
     */
    storageDomainId?: number;

    /**
     * Filter by Source ids that are associated with Protection Groups. Only Protection Groups associated with the specified Source ids, are returned.
     */
    sourceIds?: Array<number>;

    /**
     * Specifies the type of request from UI, which is used for services like magneto to determine the priority of requests.
     */
    requestInitiatorType?: 'UIUser' | 'UIAuto' | 'Helios';

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * If true, the response will exclude the list of source IDs within the group specified.
     */
    pruneSourceIds?: null | boolean;

    /**
     * If true, the response will not include the list of excluded source IDs in groups that contain this field. This can be set to true in order to improve performance if excluded source IDs are not needed by the user.
     */
    pruneExcludedSourceIds?: boolean;

    /**
     * Filter by Policy ids that are associated with Protection Groups. Only Protection Groups associated with the specified Policy ids, are returned.
     */
    policyIds?: Array<string>;
    office365Workloads?: Array<'kMailbox' | 'kOneDrive' | 'kSharePoint' | 'kPublicFolders' | 'kGroups' | 'kTeams'>;

    /**
     * Filter by a list of Protection Group names.
     */
    names?: Array<string>;

    /**
     * Filter by last remote replication run status.<br> 'Running' indicates that the run is still running.<br> 'Canceled' indicates that the run has been canceled.<br> 'Canceling' indicates that the run is in the process of being canceled.<br> 'Failed' indicates that the run has failed.<br> 'Missed' indicates that the run was unable to take place at the scheduled time because the previous run was still happening.<br> 'Succeeded' indicates that the run has finished successfully.<br> 'SucceededWithWarning' indicates that the run finished successfully, but there were some warning messages.<br> 'Paused' indicates that the ongoing run has been paused.<br> 'Skipped' indicates that the run was skipped.
     */
    lastRunReplicationStatus?: Array<'Accepted' | 'Running' | 'Canceled' | 'Canceling' | 'Failed' | 'Missed' | 'Succeeded' | 'SucceededWithWarning' | 'OnHold' | 'Finalizing' | 'Skipped' | 'Paused'>;

    /**
     * Filter by last local backup run status.<br> 'Running' indicates that the run is still running.<br> 'Canceled' indicates that the run has been canceled.<br> 'Canceling' indicates that the run is in the process of being canceled.<br> 'Failed' indicates that the run has failed.<br> 'Missed' indicates that the run was unable to take place at the scheduled time because the previous run was still happening.<br> 'Succeeded' indicates that the run has finished successfully.<br> 'SucceededWithWarning' indicates that the run finished successfully, but there were some warning messages.<br> 'Paused' indicates that the ongoing run has been paused.<br> 'Skipped' indicates that the run was skipped.
     */
    lastRunLocalBackupStatus?: Array<'Accepted' | 'Running' | 'Canceled' | 'Canceling' | 'Failed' | 'Missed' | 'Succeeded' | 'SucceededWithWarning' | 'OnHold' | 'Finalizing' | 'Skipped' | 'Paused'>;

    /**
     * Filter by last cloud spin run status.<br> 'Running' indicates that the run is still running.<br> 'Canceled' indicates that the run has been canceled.<br> 'Canceling' indicates that the run is in the process of being canceled.<br> 'Failed' indicates that the run has failed.<br> 'Missed' indicates that the run was unable to take place at the scheduled time because the previous run was still happening.<br> 'Succeeded' indicates that the run has finished successfully.<br> 'SucceededWithWarning' indicates that the run finished successfully, but there were some warning messages.<br> 'Paused' indicates that the ongoing run has been paused.<br> 'Skipped' indicates that the run was skipped.
     */
    lastRunCloudSpinStatus?: Array<'Accepted' | 'Running' | 'Canceled' | 'Canceling' | 'Failed' | 'Missed' | 'Succeeded' | 'SucceededWithWarning' | 'OnHold' | 'Finalizing' | 'Skipped' | 'Paused'>;

    /**
     * Filter by last cloud archival run status.<br> 'Running' indicates that the run is still running.<br> 'Canceled' indicates that the run has been canceled.<br> 'Canceling' indicates that the run is in the process of being canceled.<br> 'Failed' indicates that the run has failed.<br> 'Missed' indicates that the run was unable to take place at the scheduled time because the previous run was still happening.<br> 'Succeeded' indicates that the run has finished successfully.<br> 'SucceededWithWarning' indicates that the run finished successfully, but there were some warning messages.<br> 'Paused' indicates that the ongoing run has been paused.<br> 'Skipped' indicates that the run was skipped.
     */
    lastRunArchivalStatus?: Array<'Accepted' | 'Running' | 'Canceled' | 'Canceling' | 'Failed' | 'Missed' | 'Succeeded' | 'SucceededWithWarning' | 'OnHold' | 'Finalizing' | 'Skipped' | 'Paused'>;

    /**
     * Filter by last any run status.<br> 'Running' indicates that the run is still running.<br> 'Canceled' indicates that the run has been canceled.<br> 'Canceling' indicates that the run is in the process of being canceled.<br> 'Failed' indicates that the run has failed.<br> 'Missed' indicates that the run was unable to take place at the scheduled time because the previous run was still happening.<br> 'Succeeded' indicates that the run has finished successfully.<br> 'SucceededWithWarning' indicates that the run finished successfully, but there were some warning messages.<br> 'Paused' indicates that the ongoing run has been paused.<br> 'Skipped' indicates that the run was skipped.
     */
    lastRunAnyStatus?: Array<'Accepted' | 'Running' | 'Canceled' | 'Canceling' | 'Failed' | 'Missed' | 'Succeeded' | 'SucceededWithWarning' | 'OnHold' | 'Finalizing' | 'Skipped' | 'Paused'>;

    /**
     * Filter by paused or non paused Protection Groups, If not set, all paused and non paused Protection Groups are returned. If true, only paused Protection Groups are returned. If false, only non paused Protection Groups are returned.
     */
    isPaused?: boolean;

    /**
     * If true, return Protection Groups for which last run SLA was violated.
     */
    isLastRunSlaViolated?: boolean;

    /**
     * If true, return only Protection Groups that have been deleted but still have Snapshots associated with them. If false, return all Protection Groups except those Protection Groups that have been deleted and still have Snapshots associated with them. A Protection Group that is deleted with all its Snapshots is not returned for either of these cases.
     */
    isDeleted?: boolean;

    /**
     * Filter by Inactive or Active Protection Groups. If not set, all Inactive and Active Protection Groups are returned. If true, only Active Protection Groups are returned. If false, only Inactive Protection Groups are returned. When you create a Protection Group on a Primary Cluster with a replication schedule, the Cluster creates an Inactive copy of the Protection Group on the Remote Cluster. In addition, when an Active and running Protection Group is deactivated, the Protection Group becomes Inactive.
     */
    isActive?: boolean;

    /**
     * If true, the response will include Protection Groups which were created by all tenants which the current user has permission to see. If false, then only Protection Groups created by the current user will be returned.
     */
    includeTenants?: boolean;

    /**
     * If true, the response will include last run info. If it is false or not specified, the last run info won't be returned.
     */
    includeLastRunInfo?: boolean;

    /**
     * Whether to only return Protection Groups with a datalock.
     */
    includeGroupsWithDatalockOnly?: boolean;

    /**
     * Filter by a list of Protection Group ids.
     */
    ids?: Array<string>;

    /**
     * Filter by environment types such as 'kVMware', 'kView', etc. Only Protection Groups protecting the specified environment types are returned.
     */
    environments?: Array<'kVMware' | 'kHyperV' | 'kVCD' | 'kAzure' | 'kGCP' | 'kKVM' | 'kAcropolis' | 'kAWS' | 'kAWSNative' | 'kAwsS3' | 'kAWSSnapshotManager' | 'kRDSSnapshotManager' | 'kAuroraSnapshotManager' | 'kAwsRDSPostgresBackup' | 'kAwsAuroraPostgres' | 'kAwsDynamoDB' | 'kAwsRDSPostgres' | 'kPhysical' | 'kPhysicalFiles' | 'kGPFS' | 'kElastifile' | 'kNetapp' | 'kGenericNas' | 'kIsilon' | 'kFlashBlade' | 'kPure' | 'kIbmFlashSystem' | 'kSQL' | 'kExchange' | 'kAD' | 'kOracle' | 'kView' | 'kRemoteAdapter' | 'kO365' | 'kO365PublicFolders' | 'kO365Teams' | 'kO365Group' | 'kO365Exchange' | 'kO365OneDrive' | 'kO365Sharepoint' | 'kKubernetes' | 'kCassandra' | 'kMongoDB' | 'kCouchbase' | 'kHdfs' | 'kHive' | 'kHBase' | 'kUDA' | 'kSfdc' | 'kSAPHANA'>;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CreateProtectionGroup
   */
  export interface CreateProtectionGroupParams {

    /**
     * Specifies the parameters to create a Protection Group.
     */
    body: CreateOrUpdateProtectionGroupRequest;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateProtectionGroupsState
   */
  export interface UpdateProtectionGroupsStateParams {

    /**
     * Specifies the parameters to perform an action of list of Protection Groups.
     */
    body: UpdateProtectionGroupsStateRequest;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetProtectionGroupById
   */
  export interface GetProtectionGroupByIdParams {

    /**
     * Specifies a unique id of the Protection Group.
     */
    id: string;

    /**
     * Specifies the type of request from UI, which is used for services like magneto to determine the priority of requests.
     */
    requestInitiatorType?: 'UIUser' | 'UIAuto' | 'Helios';

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * If true, the response will exclude the list of source IDs within the group specified.
     */
    pruneSourceIds?: null | boolean;

    /**
     * If true, the response will not include the list of excluded source IDs in groups that contain this field. This can be set to true in order to improve performance if excluded source IDs are not needed by the user.
     */
    pruneExcludedSourceIds?: boolean;

    /**
     * If true, the response will include last run info. If it is false or not specified, the last run info won't be returned.
     */
    includeLastRunInfo?: boolean;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateProtectionGroup
   */
  export interface UpdateProtectionGroupParams {

    /**
     * Specifies the id of the Protection Group.
     */
    id: string;

    /**
     * Specifies the parameters to update a Protection Group.
     */
    body: CreateOrUpdateProtectionGroupRequest;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for DeleteProtectionGroup
   */
  export interface DeleteProtectionGroupParams {

    /**
     * Specifies a unique id of the Protection Group.
     */
    id: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Specifies if Snapshots generated by the Protection Group should also be deleted when the Protection Group is deleted.
     */
    deleteSnapshots?: boolean;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetProtectionGroupRuns
   */
  export interface GetProtectionGroupRunsParams {

    /**
     * Specifies a unique id of the Protection Group.
     */
    id: string;

    /**
     * Specifies whether we can serve the GET request from the read replica cache. There is a lag of 15 seconds between the read replica and primary data source.
     */
    useCachedData?: boolean;

    /**
     * If set, magneto will truncate the response if it exceeds max size limit governed by magneto_http_rpc_response_size_limit_bytes
     */
    truncateResponse?: null | boolean;

    /**
     * TenantIds contains ids of the tenants for which objects are to be returned.
     */
    tenantIds?: Array<string>;

    /**
     * Start time for time range filter. Specify the start time as a Unix epoch Timestamp (in microseconds), only runs executing after this time will be returned. By default it is endTimeUsecs minus an hour.
     */
    startTimeUsecs?: number;

    /**
     * Filter by source id. Only Job Runs protecting the specified source (such as a VM or View) are returned. The source id is assigned by the Cohesity Cluster.
     */
    sourceId?: null | number;

    /**
     * Specifies the snapshot's target type which should be filtered. Note: this field is only considered when, filterByCopyTaskEndTime is set to true, or else it is ignored.
     */
    snapshotTargetTypes?: Array<'Local' | 'Archival' | 'RpaasArchival' | 'StorageArraySnapshot' | 'Remote'>;

    /**
     * Filter by run type. Only protection run matching the specified types will be returned.
     */
    runTypes?: Array<'kAll' | 'kHydrateCDP' | 'kSystem' | 'kStorageArraySnapshot' | 'kIncremental' | 'kFull' | 'kLog'>;

    /**
     * Specifies a list of tags for protection runs. If this is specified, only the runs which match these tags will be returned.
     */
    runTags?: Array<string>;

    /**
     * Specifies the protection run id.
     */
    runId?: string;

    /**
     * Specifies the type of request from UI, which is used for services like magneto to determine the priority of requests.
     */
    requestInitiatorType?: 'UIUser' | 'UIAuto' | 'Helios';

    /**
     * Specifies a list of replication status, runs matching the status will be returned.<br> 'Running' indicates that the run is still running.<br> 'Canceled' indicates that the run has been canceled.<br> 'Canceling' indicates that the run is in the process of being canceled.<br> 'Failed' indicates that the run has failed.<br> 'Missed' indicates that the run was unable to take place at the scheduled time because the previous run was still happening.<br> 'Succeeded' indicates that the run has finished successfully.<br> 'SucceededWithWarning' indicates that the run finished successfully, but there were some warning messages.<br> 'Paused' indicates that the ongoing run has been paused.<br> 'Skipped' indicates that the run was skipped.
     */
    replicationRunStatus?: Array<'Accepted' | 'Running' | 'Canceled' | 'Canceling' | 'Failed' | 'Missed' | 'Succeeded' | 'SucceededWithWarning' | 'OnHold' | 'Finalizing' | 'Skipped' | 'Paused'>;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * If set to false, all copy_tasks in any given valid state will be considered. If left empty or set to true, only successful copy_tasks would be considered. Note: this field is only considered when, filterByCopyTaskEndTime is set to true, or else it is ignored.
     */
    onlyReturnSuccessfulCopyRun?: boolean;

    /**
     * If set, returns only shell info such as run's start time, type, error if any.
     */
    onlyReturnShellInfo?: null | boolean;

    /**
     * Specifies if only data stubbing jobs should be returned. If not set, no data migration job will be returned.
     */
    onlyReturnDataMigrationJobs?: null | boolean;

    /**
     * Specifies the max number of runs. If not specified, at most 100 runs will be returned.
     */
    numRuns?: number;

    /**
     * Specifies a list of local backup status, runs matching the status will be returned.<br> 'Running' indicates that the run is still running.<br> 'Canceled' indicates that the run has been canceled.<br> 'Canceling' indicates that the run is in the process of being canceled.<br> 'Failed' indicates that the run has failed.<br> 'Missed' indicates that the run was unable to take place at the scheduled time because the previous run was still happening.<br> 'Succeeded' indicates that the run has finished successfully.<br> 'SucceededWithWarning' indicates that the run finished successfully, but there were some warning messages.<br> 'Paused' indicates that the ongoing run has been paused.<br> 'Skipped' indicates that the run was skipped.
     */
    localBackupRunStatus?: Array<'Accepted' | 'Running' | 'Canceled' | 'Canceling' | 'Failed' | 'Missed' | 'Succeeded' | 'SucceededWithWarning' | 'OnHold' | 'Finalizing' | 'Skipped' | 'Paused'>;

    /**
     * Return a specific Job Run by specifying a time and a group id. Specify the time when the Job Run started as a Unix epoch Timestamp (in microseconds). If this field is specified, jobId must also be specified.
     */
    jobRunStartTimeUsecs?: null | number;

    /**
     * If true, the response will include Protection Group Runs which were created by all tenants which the current user has permission to see. If false, then only Protection Group Runs created by the current user will be returned.
     */
    includeTenants?: boolean;

    /**
     * If true, then the snapshots for Protection Sources protected by Rpo policies will also be returned.
     */
    includeRpoSnapshots?: null | boolean;

    /**
     * Specifies if the result includes the object details for each protection run. If set to true, details of the protected object will be returned. If set to false or not specified, details will not be returned.
     */
    includeObjectDetails?: boolean;

    /**
     * Specifies if needs to include proto extensions if they are extended.
     */
    includeExtensionInfo?: null | boolean;

    /**
     * If true, the runs with end time within the specified time range will be returned. Otherwise, the runs with start time in the time range are returned.
     */
    filterByEndTime?: boolean;

    /**
     * If true, then the details of the runs for which any copyTask completed in the given timerange will be returned. Only one of filterByEndTime and filterByCopyTaskEndTime can be set.
     */
    filterByCopyTaskEndTime?: boolean;

    /**
     * Specifies whether to exclude non restorable runs. Run is treated restorable only if there is atleast one object snapshot (which may be either a local or an archival snapshot) which is not deleted or expired. Default value is false.
     */
    excludeNonRestorableRuns?: boolean;

    /**
     * Specifies whether to exclude runs with error. If no value is specified, then runs with errors are included.
     */
    excludeErrorRuns?: null | boolean;

    /**
     * End time for time range filter. Specify the end time as a Unix epoch Timestamp (in microseconds), only runs executing before this time will be returned. By default it is current time.
     */
    endTimeUsecs?: number;

    /**
     * Specifies a list of cloud spin status, runs matching the status will be returned.<br> 'Running' indicates that the run is still running.<br> 'Canceled' indicates that the run has been canceled.<br> 'Canceling' indicates that the run is in the process of being canceled.<br> 'Failed' indicates that the run has failed.<br> 'Missed' indicates that the run was unable to take place at the scheduled time because the previous run was still happening.<br> 'Succeeded' indicates that the run has finished successfully.<br> 'SucceededWithWarning' indicates that the run finished successfully, but there were some warning messages.<br> 'Paused' indicates that the ongoing run has been paused.<br> 'Skipped' indicates that the run was skipped.
     */
    cloudSpinRunStatus?: Array<'Accepted' | 'Running' | 'Canceled' | 'Canceling' | 'Failed' | 'Missed' | 'Succeeded' | 'SucceededWithWarning' | 'OnHold' | 'Finalizing' | 'Skipped' | 'Paused'>;

    /**
     * Specifies a list of archival status, runs matching the status will be returned.<br> 'Running' indicates that the run is still running.<br> 'Canceled' indicates that the run has been canceled.<br> 'Canceling' indicates that the run is in the process of being canceled.<br> 'Failed' indicates that the run has failed.<br> 'Missed' indicates that the run was unable to take place at the scheduled time because the previous run was still happening.<br> 'Succeeded' indicates that the run has finished successfully.<br> 'SucceededWithWarning' indicates that the run finished successfully, but there were some warning messages.<br> 'Paused' indicates that the ongoing run has been paused.<br> 'Skipped' indicates that the run was skipped.
     */
    archivalRunStatus?: Array<'Accepted' | 'Running' | 'Canceled' | 'Canceling' | 'Failed' | 'Missed' | 'Succeeded' | 'SucceededWithWarning' | 'OnHold' | 'Finalizing' | 'Skipped' | 'Paused'>;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateProtectionGroupRun
   */
  export interface UpdateProtectionGroupRunParams {

    /**
     * Specifies a unique id of the Protection Group.
     */
    id: string;

    /**
     * Specifies the parameters to update a Protection Group Run.
     */
    body: UpdateProtectionGroupRunRequestBody;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CreateProtectionGroupRun
   */
  export interface CreateProtectionGroupRunParams {

    /**
     * Specifies a unique id of the Protection Group.
     */
    id: string;

    /**
     * Specifies the parameters to start a protection run.
     */
    body: CreateProtectionGroupRunRequest;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for PerformActionOnProtectionGroupRun
   */
  export interface PerformActionOnProtectionGroupRunParams {

    /**
     * Specifies a unique id of the Protection Group.
     */
    id: string;

    /**
     * Specifies the parameters to perform an action on a protection run.
     */
    body: PerformActionOnProtectionGroupRunRequest;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetProtectionGroupRun
   */
  export interface GetProtectionGroupRunParams {

    /**
     * Specifies a unique run id of the Protection Group run.
     */
    runId: string;

    /**
     * Specifies a unique id of the Protection Group.
     */
    id: string;

    /**
     * Specifies whether we can serve the GET request from the read replica cache. There is a lag of 15 seconds between the read replica and primary data source.
     */
    useCachedData?: boolean;

    /**
     * TenantIds contains ids of the tenants for which the run is to be returned.
     */
    tenantIds?: Array<string>;

    /**
     * Specifies the type of request from UI, which is used for services like magneto to determine the priority of requests.
     */
    requestInitiatorType?: 'UIUser' | 'UIAuto' | 'Helios';

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * If true, the response will include Protection Group Runs which were created by all tenants which the current user has permission to see. If false, then only Protection Groups created by the current user will be returned. If it's not specified, it is true by default.
     */
    includeTenants?: boolean;

    /**
     * Specifies if the result includes the object details for a protection run. If set to true, details of the protected object will be returned. If set to false or not specified, details will not be returned.
     */
    includeObjectDetails?: boolean;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetRunDebugLogs
   */
  export interface GetRunDebugLogsParams {

    /**
     * Specifies a unique run id of the Protection Group run.
     */
    runId: string;

    /**
     * Specifies a unique id of the Protection Group.
     */
    id: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Specifies the id of the object for which debug logs are to be returned.
     */
    objectId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetRunDebugLogsForObject
   */
  export interface GetRunDebugLogsForObjectParams {

    /**
     * Specifies a unique run id of the Protection Group run.
     */
    runId: string;

    /**
     * Specifies the id of the object for which debug logs are to be returned.
     */
    objectId: string;

    /**
     * Specifies a unique id of the Protection Group.
     */
    id: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetRunMessagesReport
   */
  export interface GetRunMessagesReportParams {

    /**
     * Specifies a unique run id of the Protection Group run.
     */
    runId: string;

    /**
     * Specifies the id of the object for which errors/warnings are to be returned.
     */
    objectId: string;

    /**
     * Specifies a unique id of the Protection Group.
     */
    id: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Specifies the name of the source being backed up
     */
    name?: string;

    /**
     * Specifies the downloaded type, i.e: inclusion_exclusion_reports, error_files_list. default: error_files_list
     */
    fileType?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetRunsReport
   */
  export interface GetRunsReportParams {

    /**
     * Specifies a unique run id of the Protection Group run.
     */
    runId: string;

    /**
     * Specifies the id of the object for which errors/warnings are to be returned.
     */
    objectId: string;

    /**
     * Specifies a unique id of the Protection Group.
     */
    id: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Specifies the name of the source being backed up
     */
    name?: string;

    /**
     * Specifies the downloaded type, i.e: success_files_list, default: success_files_list
     */
    fileType?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetProtectionRuns
   */
  export interface GetProtectionRunsParams {

    /**
     * Start time for time range filter. Specify the start time as a Unix epoch Timestamp (in microseconds), only runs executing after this time will be returned. By default it is endTimeUsecs minus an hour.
     */
    startTimeUsecs?: number;

    /**
     * Specifies a list of status, runs matching the status will be returned.<br> 'Running' indicates that the run is still running.<br> 'Canceled' indicates that the run has been canceled.<br> 'Canceling' indicates that the run is in the process of being canceled.<br> 'Failed' indicates that the run has failed.<br> 'Missed' indicates that the run was unable to take place at the scheduled time because the previous run was still happening.<br> 'Succeeded' indicates that the run has finished successfully.<br> 'SucceededWithWarning' indicates that the run finished successfully, but there were some warning messages.<br> 'Skipped' indicates that the run was skipped.
     */
    runStatus?: Array<'Accepted' | 'Running' | 'Canceled' | 'Canceling' | 'Failed' | 'Missed' | 'Succeeded' | 'SucceededWithWarning' | 'OnHold' | 'Finalizing' | 'Skipped'>;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * End time for time range filter. Specify the end time as a Unix epoch Timestamp (in microseconds), only runs executing before this time will be returned. By default it is current time.
     */
    endTimeUsecs?: number;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetProtectionRunProgress
   */
  export interface GetProtectionRunProgressParams {

    /**
     * Specifies a unique run id of the Protection Run.
     */
    runId: string;

    /**
     * TenantIds contains ids of the tenants for which the run is to be returned.
     */
    tenantIds?: Array<string>;

    /**
     * Specifies the time after which the progress task starts in Unix epoch Timestamp(in microseconds).
     */
    startTimeUsecs?: number;

    /**
     * Specifies the task path of the run or object run. This is applicable only if progress of a protection group with one or more object is required.If provided this will be used to fetch progress details directly without looking actual task path of the object. Objects field is stil expected else it changes the response format.
     */
    runTaskPath?: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Specifies the objects whose progress will be returned. This only applies to protection group runs and will be ignored for object runs. If the objects are specified, the run progress will not be returned and only the progress of the specified objects will be returned.
     */
    objects?: Array<number>;

    /**
     * Specifies the object level task path. This relates to the objectID. If provided this will take precedence over the objects, and will be used to fetch progress details directly without looking actuall task path of the object.
     */
    objectTaskPaths?: Array<string>;

    /**
     * Specifies the maximum number of tasks to return.
     */
    maxTasksNum?: number;

    /**
     * Specifies the number of levels till which to fetch the event logs. This is applicable only when includeEventLogs is true.
     */
    maxLogLevel?: number;

    /**
     * If true, the response will include Protection Group Runs which were created by all tenants which the current user has permission to see. If false, then only Protection Groups created by the current user will be returned. If it's not specified, it is true by default.
     */
    includeTenants?: boolean;

    /**
     * Specifies whether to return finished tasks. By default only active tasks are returned.
     */
    includeFinishedTasks?: boolean;

    /**
     * Specifies whether to include event logs
     */
    includeEventLogs?: boolean;

    /**
     * Specifies whether to return objects. By default all the task tree are returned.
     */
    excludeObjectDetails?: boolean;

    /**
     * Specifies the time before which the progress task ends in Unix epoch Timestamp(in microseconds).
     */
    endTimeUsecs?: number;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetProtectionRunStats
   */
  export interface GetProtectionRunStatsParams {

    /**
     * Specifies a unique run id of the Protection Run.
     */
    runId: string;

    /**
     * TenantIds contains ids of the tenants for which the run is to be returned.
     */
    tenantIds?: Array<string>;

    /**
     * Specifies the time after which the stats task starts in Unix epoch Timestamp(in microseconds).
     */
    startTimeUsecs?: number;

    /**
     * Specifies the task path of the run or object run. This is applicable only if stats of a protection group with one or more object is required. If provided this will be used to fetch stats details directly without looking actual task path of the object. Objects field is stil expected else it changes the response format.
     */
    runTaskPath?: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Specifies the objects whose stats will be returned. This only applies to protection group runs and will be ignored for object runs. If the objects are specified, the run stats will not be returned and only the stats of the specified objects will be returned.
     */
    objects?: Array<number>;

    /**
     * Specifies the object level task path. This relates to the objectID. If provided this will take precedence over the objects, and will be used to fetch stats details directly without looking actuall task path of the object.
     */
    objectTaskPaths?: Array<string>;

    /**
     * Specifies the maximum number of tasks to return.
     */
    maxTasksNum?: number;

    /**
     * If true, the response will include Protection Group Runs which were created by all tenants which the current user has permission to see. If false, then only Protection Groups created by the current user will be returned. If it's not specified, it is true by default.
     */
    includeTenants?: boolean;

    /**
     * Specifies whether to return finished tasks. By default only active tasks are returned.
     */
    includeFinishedTasks?: boolean;

    /**
     * Specifies whether to return objects. By default all the task tree are returned.
     */
    excludeObjectDetails?: boolean;

    /**
     * Specifies the time before which the stats task ends in Unix epoch Timestamp(in microseconds).
     */
    endTimeUsecs?: number;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetMcmProtectionGroupsActivity
   */
  export interface GetMcmProtectionGroupsActivityParams {

    /**
     * Filter by a list of region ids. This is used for Rpaas only.
     */
    rpaasRegionIds?: Array<string>;

    /**
     * Filter by a list of FortKnox global vault ids. This is used for FortKnox only. When rpaasGlobalVaultIds is specified, rpaasRegionIds will be ignored.
     */
    rpaasGlobalVaultIds?: Array<string>;

    /**
     * Filter by a list of region ids.
     */
    regionIds?: Array<string>;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Filter by a list of cluster identifiers in format of clusterId:clusterIncarnationId.
     */
    clusterIdentifiers?: Array<string>;

    /**
     * Request parameters to filter Protection Group activity.
     */
    body?: GetMcmProtectionGroupsActivityReqParams;
  }

  /**
   * Parameters for GetMcmProtectionGroups
   */
  export interface GetMcmProtectionGroupsParams {

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Filter by Policy ids that are associated with Protection Groups. Only Protection Groups associated with the specified Policy ids, are returned.
     */
    policyIds?: Array<string>;

    /**
     * Filter by a list of Protection Group names.
     */
    names?: Array<string>;

    /**
     * Filter by last any run status.<br> 'Running' indicates that the run is still running.<br> 'Canceled' indicates that the run has been canceled.<br> 'Canceling' indicates that the run is in the process of being canceled.<br> 'Failed' indicates that the run has failed.<br> 'Missed' indicates that the run was unable to take place at the scheduled time because the previous run was still happening.<br> 'Succeeded' indicates that the run has finished successfully.<br> 'SucceededWithWarning' indicates that the run finished successfully, but there were some warning messages. 'Skipped' indicates that the run was skipped.
     */
    lastRunAnyStatus?: Array<'Accepted' | 'Running' | 'Canceled' | 'Canceling' | 'Failed' | 'Missed' | 'Succeeded' | 'SucceededWithWarning' | 'OnHold' | 'Finalizing' | 'Skipped' | 'Paused'>;

    /**
     * Filter by paused or non paused Protection Groups, If not set, all paused and non paused Protection Groups are returned. If true, only paused Protection Groups are returned. If false, only non paused Protection Groups are returned.
     */
    isPaused?: boolean;

    /**
     * If true, return Protection Groups for which last run SLA was violated.
     */
    isLastRunSlaViolated?: boolean;

    /**
     * If true, return only Protection Groups that have been deleted but still have Snapshots associated with them. If false, return all Protection Groups except those Protection Groups that have been deleted and still have Snapshots associated with them. A Protection Group that is deleted with all its Snapshots is not returned for either of these cases.
     */
    isDeleted?: boolean;

    /**
     * Filter by Inactive or Active Protection Groups. If not set, all Inactive and Active Protection Groups are returned. If true, only Active Protection Groups are returned. If false, only Inactive Protection Groups are returned. When you create a Protection Group on a Primary Cluster with a replication schedule, the Cluster creates an Inactive copy of the Protection Group on the Remote Cluster. In addition, when an Active and running Protection Group is deactivated, the Protection Group becomes Inactive.
     */
    isActive?: boolean;

    /**
     * If true, the response will include last run info. If it is false or not specified, the last run info won't be returned.
     */
    includeLastRunInfo?: boolean;

    /**
     * Filter by a list of Protection Group ids.
     */
    ids?: Array<string>;

    /**
     * Filter by environment types such as 'kVMware', 'kView', etc. Only Protection Groups protecting the specified environment types are returned.
     */
    environments?: Array<'kVMware' | 'kHyperV' | 'kVCD' | 'kAzure' | 'kGCP' | 'kKVM' | 'kAcropolis' | 'kAWS' | 'kAWSNative' | 'kAwsS3' | 'kAWSSnapshotManager' | 'kRDSSnapshotManager' | 'kAuroraSnapshotManager' | 'kAwsRDSPostgresBackup' | 'kAwsAuroraPostgres' | 'kAwsDynamoDB' | 'kAwsRDSPostgres' | 'kPhysical' | 'kPhysicalFiles' | 'kGPFS' | 'kElastifile' | 'kNetapp' | 'kGenericNas' | 'kIsilon' | 'kFlashBlade' | 'kPure' | 'kIbmFlashSystem' | 'kSQL' | 'kExchange' | 'kAD' | 'kOracle' | 'kView' | 'kRemoteAdapter' | 'kO365' | 'kO365PublicFolders' | 'kO365Teams' | 'kO365Group' | 'kO365Exchange' | 'kO365OneDrive' | 'kO365Sharepoint' | 'kKubernetes' | 'kCassandra' | 'kMongoDB' | 'kCouchbase' | 'kHdfs' | 'kHive' | 'kHBase' | 'kUDA' | 'kSfdc' | 'kSAPHANA'>;
  }

  /**
   * Parameters for GetMcmProtectionGroupById
   */
  export interface GetMcmProtectionGroupByIdParams {

    /**
     * Specifies a unique id of the Protection Group.
     */
    id: string;

    /**
     * Specifies the type of request from UI, which is used for services like magneto to determine the priority of requests.
     */
    requestInitiatorType?: 'UIUser' | 'UIAuto' | 'Helios';

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * If true, the response will include last run info. If it is false or not specified, the last run info won't be returned.
     */
    includeLastRunInfo?: boolean;
  }

  /**
   * Parameters for UpdateMcmProtectionGroup
   */
  export interface UpdateMcmProtectionGroupParams {

    /**
     * Specifies the id of the Protection Group.
     */
    id: string;

    /**
     * Specifies the parameters to update a Protection Group.
     */
    body: CreateOrUpdateProtectionGroupRequest;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;
  }

  /**
   * Parameters for DeleteMcmProtectionGroup
   */
  export interface DeleteMcmProtectionGroupParams {

    /**
     * Specifies a unique id of the Protection Group.
     */
    id: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Specifies if Snapshots generated by the Protection Group should also be deleted when the Protection Group is deleted.
     */
    deleteSnapshots?: boolean;
  }
}

export { ProtectionGroupService }
