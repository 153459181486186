import { Injectable } from '@angular/core';
import { NavItem } from '@cohesity/helix';
import { IrisContextService, isDmsScope } from '@cohesity/iris-core';
import { StateService } from '@uirouter/core';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { StateManagementService } from 'src/app/core/services';
import { RestoreConfigService } from 'src/app/modules/restore/restore-shared';

import { ViewObjectActionCreator } from './view-object-action-creator';
import { ObjectActionCreator } from './object-action-creator';
import { ObjectActionProvider } from './object-action-provider';
import { ObjectInfoService } from './object-info.service';
import { SimpleObjectInfo } from './object-menu-provider';

@Injectable()
export class ViewObjectActionProvider extends ObjectActionProvider {
  /**
   * The provider for this service is manually set up in object-actions-menu.service, which must provide
   * the list of providers as an array in the correct order. In order to maintain some kind of sanity,
   * the providers are listed here, they should match the order of the constructor args.
   */
  static viewObjectActionProviderDependencies = [
    ObjectInfoService,
    RestoreConfigService,
    StateManagementService,
    StateService,
    ObjectActionCreator,
    IrisContextService,
    ViewObjectActionCreator,
  ];

  exclusiveProtection = false;

  supportsBulkActions = true;

  constructor(
    readonly objectStore: ObjectInfoService,
    readonly restoreConfig: RestoreConfigService,
    readonly stateManagementService: StateManagementService,
    readonly stateService: StateService,
    readonly actionCreator: ObjectActionCreator,
    readonly irisContextService: IrisContextService,
    private viewActionCreator: ViewObjectActionCreator,
  ) {
    super(objectStore, restoreConfig, stateManagementService, stateService, actionCreator, irisContextService);
  }

  /**
   * Creates and returns the view action for the specified object.
   *
   * @param object The protected object.
   * @returns An observable, which yields the NavItem or null.
   */
  getViewCloneAction(object: SimpleObjectInfo): Observable<NavItem> {
    if (!this.objectStore.getProtectedObject) {
      return of(null);
    }

    return this.objectStore.getProtectedObject(
      object.id,
      { accessClusterId: object.accessClusterId, regionId: object.regionId }
    ).pipe(
      map(storeEntry => storeEntry.item),
      map(protectedObject => {
        if (!protectedObject || !protectedObject?.latestSnapshotsInfo) {
          return null;
        }

        return this.viewActionCreator.createViewCloneAction(
          protectedObject,
          { accessClusterId: object.accessClusterId, regionId: object.regionId }
        );
      })
    );
  }

  getObjectActions(object: SimpleObjectInfo): Observable<NavItem[]> {
    if (isDmsScope(this.irisContextService.irisContext)) {
      // DB specific actions are currently non NG and will not supported in DMaaS.
      return super.getObjectActions(object);
    }

    return combineLatest([
      super.getObjectActions(object),
      this.getViewCloneAction(object),
    ]).pipe(
      map(([baseActions, ...viewActions]) => [...baseActions, ...viewActions]),
      map(actions => actions.filter(action => Boolean(action)))
    );
  }
}
