import { Component, Input } from '@angular/core';

/**
 * Size types for icons.
 */
export type IconSize = 'md' | 'lg' | 'xl';

/**
 * Security advisor icon component
 */
@Component({
  selector: 'dg-pa-icon',
  templateUrl: './posture-advisor-icon.component.html',
  styleUrls: ['./posture-advisor-icon.component.scss'],
})
export class PostureAdvisorIconComponent {
  /**
   * Value for condition.
   */
  @Input() scoreValue: number;

  /**
   * Size value for icon.
   */
  @Input() size: IconSize;

  /**
   * Holds class name.
   */
  get iconColor(): string {
    let iconColor;
    switch (true) {
      case this.scoreValue === null:
        iconColor = 'default';
        break;
      case this.scoreValue <= 70:
        iconColor = 'critical';
        break;
      case this.scoreValue <= 90:
        iconColor = 'warning';
        break;
      case this.scoreValue > 90:
        iconColor = 'success';
        break;
    }
    return iconColor;
  }
}
