import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { AutoTableColumnConfig } from '../auto-table.model';

/**
 * Cell Renderer for cog auto table. This can render a cell with the layout:
 * ---------------------------------
 * |      | Primary Text           |
 * | icon |-------------------------
 * |      | Subtitle               |
 * ---------------------------------
 * Or, just an icon, or just text.
 * The component uses Component portal to load a renderer fo each cell region.
 */
@Component({
  selector: 'cog-auto-table-cell',
  templateUrl: './auto-table-cell.component.html',
  styleUrls: ['./auto-table-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutoTableCellComponent {
  /**
   * The row being renderered
   */
  @Input() row: any;

  /**
   * The config for the curent column
   */
  @Input() config: AutoTableColumnConfig;

  /**
   * The translate function
   */
  @Input() translate: (key, params) => string;

  /**
   * Gets the icon value from the row
   */
  get iconValue(): any {
    return this.row && this.row[this.config.icon?.key];
  }

  /**
   * Gets the primary value from the row
   */
  get primaryValue(): any {
    return this.row && this.row[this.config.primary?.key];
  }

  /**
   * Gets the subtitle value from the row
   */
  get subtitleValue(): any {
    return this.row && this.row[this.config.subtitle?.key];
  }
}
