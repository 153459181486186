<h1 mat-dialog-title [innerHTML]="title | translate:data"></h1>
<div mat-dialog-content>
  <ng-container *ngIf="copy">
    <div class="simple-dialog-copy" [innerHTML]="copy | translate:data"></div>
  </ng-container>

  <ng-template [cdkPortalOutlet]="portalObject"></ng-template>
</div>
<div mat-dialog-actions align="end">
  <button class="simple-dialog-decline"
    *ngIf="!data.hideDeclineButton"
    id="button-simple-dialog-decline"
    cogDataId="button-simple-dialog-decline"
    mat-button
    color="primary"
    (click)="declineHandler()"
    cdkFocusInitial>{{dialogDeclineButtonLabel | translate}}</button>
  <button class="simple-dialog-confirm"
    id="button-simple-dialog-confirm"
    cogDataId="button-simple-dialog-confirm"
    mat-flat-button
    color="primary"
    cogSubmitButton
    [disabled]="isSubmitting"
    [loading]="isSubmitting"
    (click)="confirmHandler()">{{dialogConfirmButtonLabel | translate}}</button>
</div>
