import { Component, Input, ViewEncapsulation } from '@angular/core';
import Highcharts, { SeriesSolidgaugeOptions, YAxisOptions } from 'highcharts';

import { HighchartsComponent } from '../core';

/**
 * @description
 * Angular Component wrapper for Highcharts solid gauge chart.
 *
 * @example
 *  <cog-gauge-chart
 *    [seriesData]="gaugeSeries"
 *    colorSetClass="gauge-chart">
 *  </cog-gauge-chart>`
 */
@Component({
  selector: 'cog-gauge-chart',
  templateUrl: './gauge-chart.component.html',
  styleUrls: ['./gauge-chart.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GaugeChartComponent extends HighchartsComponent<SeriesSolidgaugeOptions> {
  /**
   * If non-default chart colors are desired, provide a colorSetClass for an
   * existing colorSet or define a new set in the stylesheet.
   */
  @Input() colorSetClass: string;

  /**
   * Whether or not to show the marker. Defaults to true.
   */
  @Input() showMarker = true;

  /**
   * Gauge series data.
   */
  protected _seriesData: SeriesSolidgaugeOptions[] = [];

  /**
   * Set gauge series data and render chart.
   */
  @Input() set seriesData(seriesData: SeriesSolidgaugeOptions[]) {
    this.clearSeries();
    this._seriesData = seriesData;

    this.chartOptions.series = this._seriesData;
    this.render();
  }

  /**
   * Returns gauge series data.
   */
  get seriesData(): SeriesSolidgaugeOptions[] {
    return this._seriesData;
  }

  /**
   * Specify number of ranges for plot bands. Default to 3.
   */
  @Input() numRanges = 3;

  /**
   * Specify min and max value. Default from 0 to 100.
   */
  @Input() extremes = {
    min: 0,
    max: 100,
  };

  constructor() {
    super({
      chart: {
        type: 'solidgauge',
        spacing: [0, 0, 0, 0],
        styledMode: true,
        events: {
          redraw: (self: any) => {
            this.renderMarker(self.target);
          },
        }
      },
      title: null,
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      pane: {
        center: ['50%', '50%'],
        size: '100%',
        startAngle: -150,
        endAngle: 150,
        shape: 'arc',
      },
      yAxis: {
        lineWidth: 0,
        tickPositions: [],
        minorTickInterval: 0,
        tickAmount: 0,
        plotBands: [],
      },
      plotOptions: {
        solidgauge: {
          borderWidth: 0,
          radius: 80,
          innerRadius: '79%',
          dataLabels: {
            enabled: false,
          },
          animation: false,
        }
      },
    });
  }

  /**
   *  Renders the donut chart.
   */
  render() {
    const { _chart, chartOptions } = this;
    if (_chart) {
      const yAxisOptions = chartOptions.yAxis as YAxisOptions;
      yAxisOptions.min = this.extremes.min;
      yAxisOptions.max = this.extremes.max;
      yAxisOptions.plotBands = this.generatePlotBands();
    }
    super.render();
  }

  /**
   * Generates plot bands option based on number of ranges.
   *
   * @returns   Highcharts Y axis plot bands options.
   */
  private generatePlotBands(): Highcharts.YAxisPlotBandsOptions[] {
    const plotBands = [];

    // Offset for a small gap between 2 plot bands.
    const offset = 2;
    const { min, max } = this.extremes;

    for (let i = 0; i < this.numRanges; i++) {
      const range = Math.floor((max - min) / this.numRanges);

      const from = (i === 0) ? this.extremes.min : (range * i) + offset;
      const to = (i === this.numRanges - 1) ? this.extremes.max : (range * (i + 1)) - offset;

      plotBands.push({
        from,
        to,
        className: `plot-band-color-${i}`,
        outerRadius: '90%',
        thickness: '12%',
      });
    }

    return plotBands;
  }

  /**
   * Renders marker as a circle at the specified input value.
   *
   * @param  chart  solid gauge chart instance.
   */
  private renderMarker(chart) {
    if (!this.showMarker) {
      return;
    }

    const chartData = chart.userOptions.series && chart.userOptions.series[0].data[0];

    // Extract path components to find x and y coordinates for the marker.
    const pathDivided = chart.series[0]?.points[0]?.graphic?.attr('d').split(' ');
    const position = (pathDivided || []).indexOf('L');

    if (!chartData || position < 0) {
      return;
    }

    // x and y coordinates to draw the marker.
    const [x, y] = [
      Number(pathDivided[position + 1]),
      Number(pathDivided[position + 2])
    ];

    // Marker radius in px
    const radius = Math.ceil(chart.chartHeight * 0.8 * 0.12);

    const { max, min } = this.extremes;

    // Limit chart data within min/max range.
    const parsedData = Math.min(Math.max(chartData, min), max);
    const range = Math.floor((max - min) / this.numRanges);

    // Dynamic class name to match plot band colors.
    const colorIndex = Math.min(Math.max(Math.floor(parsedData / range), 0), this.numRanges - 1);
    const className = `plot-band-color-${colorIndex}`;

    chart.renderer
      .circle(x, y, radius)
      .attr({
        class: `gauge-marker ${className}`,
        zIndex: 1,
        startAngle: -150,
        endAngle: 150,
      }).add();
  }
}
