import { NgModule } from '@angular/core';

import { BoolLabelPipe } from './bool-label.pipe';

/**
 * App Module. This is the root angular module.
 */
@NgModule({
  declarations: [BoolLabelPipe],
  exports: [BoolLabelPipe],
  providers: [BoolLabelPipe],
})
export class BoolLabelModule { }
