<!-- Physical Popover Template -->
<div cogDataId="physical-source-info" class="scroll-overflow" *ngIf="!isDmsPhysicalFile">

  <div class="object-name">
    <cog-icon [shape]="node.protectionSource | objectIcon : { isProtected: isNodeProtected,
      applicationType: getApplicationType()}">
    </cog-icon>
    <h4 class="title text-wrap@sm">{{node.protectionSource.name}}</h4>
  </div>

  <!-- Physical Server -->
  <div class="source-meta-grouping" *ngIf="isHost || isSqlCluster || isUnixCluster">
    <ul>
      <!-- Server Type -->
      <li class="source-meta-line-item">
        <span class="source-meta-label">{{'type' | translate}}</span>
        {{ serverType | translate }}
      </li>

      <!-- Number of Processors -->
      <li *ngIf="physicalSource.numProcessors" class="source-meta-line-item">
        <span class="source-meta-label">{{'processors' | translate}}</span>
        {{physicalSource.numProcessors}}
      </li>

      <!-- Memory Size -->
      <li *ngIf="physicalSource.memorySizeBytes" class="source-meta-line-item">
        <span class="source-meta-label">{{'memory' | translate}}</span>
        {{physicalSource.memorySizeBytes | byteSize}}
      </li>

      <!-- Server Size -->
      <li class="source-meta-line-item">
        <span class="source-meta-label">{{'serverSize' | translate}}</span>
        {{node.logicalSize | byteSize}}
      </li>

      <!-- Host Type -->
      <li class="source-meta-line-item">
        <span class="source-meta-label">{{'hostType' | translate}}</span>
        {{ENUM_HOST_TYPE[physicalSource.hostType]}}
      </li>

      <!-- OS Name -->
      <li *ngIf="physicalSource.osName" class="source-meta-line-item">
        <span class="source-meta-label">{{'os' | translate}}</span>
        {{physicalSource.osName}}
      </li>

      <!-- Registered as SQL Host -->
      <li *ngIf="isSqlHost" class="source-meta-line-item">
        {{'registeredAsSqlHost' | translate}}
      </li>

      <!-- Source is a vcs cluster -->
      <li *ngIf="isVcsCluster" class="source-meta-line-item">
        {{'veritasClusterVIP' | translate}}
      </li>
    </ul>
  </div>

  <!-- Agent Information -->
  <coh-agent-information [node]="node" [agents]="agents"></coh-agent-information>

   <!-- Oracle Cluster Information -->
  <div class="source-meta-grouping margin-top-lg" *ngIf="isOracleRacCluster && clusterNetworkingResourceInfo?.length">
    <h5 class="title">{{'nodes' | translate}}</h5>
    <!-- show only node ip's not there alias names -->
    <ul>
      <li *ngFor="let node of clusterNetworkingResourceInfo" class="source-meta-line-item">
        {{node?.endpoints?.length ? (node.endpoints[0]?.ipv4Addr || node.endpoints[0]?.ipv6Addr) : ('-' | translate)}}
      </li>
    </ul>
  </div>

  <!-- MS SQL Information -->
  <div class="source-meta-grouping margin-top-lg" *ngIf="isSqlHost || isSqlCluster">
    <h5 class="title">
      {{ (isWindowsFileServerRole? 'windowsFileServerInstancesInformation' : 'msSqlServerInformation') | translate}}
    </h5>

    <!-- MS SQL Instance -->
    <div>
      <div class="source-meta-label" *ngIf="!isWindowsFileServerRole">
        {{ 'sqlInstances' | translate}}
      </div>
      <ul>
        <li *ngFor="let instance of node.applicationNodes" class="source-meta-line-item">
          {{instance.protectionSource.name}}
        </li>
      </ul>
    </div>

    <!-- MS SQL Cluster -->
    <div class="source-meta-grouping margin-top-lg" *ngIf="isSqlCluster">
      <div class="source-meta-line-item" *ngIf="node.registrationInfo.authenticationStatus !== 'kFinished'">
        <cog-spinner class="pull-left" size="xs"></cog-spinner>
        {{'physical.registeringClusterHosts' | translate}}
      </div>

      <!-- Nodes -->
      <div class="source-meta-line-item" *ngIf="node.registrationInfo.authenticationStatus === 'kFinished'">
        <span class="source-meta-label">
          {{(agents.length > 1 ?
          'sourceTree.popover.multipleNodesInCluster' :
          'sourceTree.popover.oneNodeInCluster')
          | translate: {n: agents.length} }}
        </span>
        <span *ngIf="isConnectionError">
          ({{'physical.sqlCluster.stale' | translate}})
        </span>
        <ul class="c-ul">
          <li *ngFor="let agent of agents" class="source-meta-line-item">
            <span class="source-meta-label">{{agent.name}}</span>
            <span *ngIf="agent.primary">({{'primary' | translate}})</span>
            <div>
              <span>{{agent.version}}</span>
              <span class="{{agent.isAnyError ? 'status-critical' : 'status-ok'}}">
                {{agent.statusKey | translate}}
              </span>
              <span class="status-warning" *ngIf="!agent.isAnyError && agent.upgradability === 'kUpgradable'">
                {{'upgradeAvailable' | translate}}
              </span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <!-- LVM Volume Information -->
  <coh-volume-information *ngIf="!isUnixCluster" [volumes]="lvmVolumes" [label]="isWindows ? 'volumes' : 'lvmVolumes'">
  </coh-volume-information>

  <!-- Non LVM Volume Information -->
  <coh-volume-information *ngIf="!isUnixCluster" [volumes]="nonLvmVolumes" [label]="'nonLvmVolumes'"></coh-volume-information>

  <!-- Shared Volume Information -->
  <coh-volume-information [volumes]="sharedVolumes" [label]="'sharedVolumes'"></coh-volume-information>

  <coh-protection-status-meta *ngIf="!isExchangeHost" class="margin-top-lg" [node]="node">
  </coh-protection-status-meta>
</div>

<div class="flex-row-xs scroll-overflow" *ngIf="isDmsPhysicalFile" cogDataId="physical-files-source-info">
  <cog-icon shape="{{isWindows ? 'helix:object-windows' : 'helix:object-linux'}}" class="server-icon"></cog-icon>
  <div class="source-meta-grouping">
    <span class="dag-node-label">
      <span class="mat-h3">{{physicalSource?.name}}</span>
      <p class="c-ul-inline no-margin-bottom">{{'physicalServer' | translate}}</p>
    </span>

    <!-- Physical Server -->
    <div class="source-meta-grouping margin-top-lg">
      <ul>
        <!-- OS Name -->
        <li *ngIf="physicalSource.osName" class="source-meta-line-item">
          <span class="source-meta-label">{{'os' | translate}}</span>
          {{physicalSource?.osName}}
        </li>

        <!-- Memory Size -->
        <li *ngIf="physicalSource.memorySizeBytes" class="source-meta-line-item">
          <span class="source-meta-label">{{'memory' | translate}}</span>
          {{physicalSource?.memorySizeBytes | byteSize}}
        </li>

        <!-- Server Size -->
        <li class="source-meta-line-item">
          <span class="source-meta-label">{{'serverSize' | translate}}</span>
          {{node?.logicalSize | byteSize}}
        </li>

      </ul>
    </div>

    <!-- Volume Information -->
    <coh-volume-information [volumes]="lvmVolumes" [label]="isWindows ? 'volumes' : 'lvmVolumes'">
    </coh-volume-information>

    <!-- Agent Information -->
    <coh-agent-information [node]="node" [agents]="agents"></coh-agent-information>

    <coh-protection-status-meta class="margin-top-lg" [node]="node"></coh-protection-status-meta>
  </div>
</div>
