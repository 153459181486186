/**
 * Specifies the backup node type.
 */
export enum MongoDBBackupFromNode {
  Primary = 'Primary',
  Secondary = 'Secondary',
  Fixed = 'Fixed',
}

/**
 * Specifies form parameters to register MongoDB source.
 */
export interface MongoDBSourceFormParams  {

  /**
   * MongoDB authentication type.
   */
  authType: null | 'SCRAM' | 'LDAP' | 'NONE' | 'KERBEROS';

  /**
   * Authenticating Database for this cluster. Should be set if 'authType' is 'LDAP' or 'SCRAM'.
   */
  authenticatingDatabase?: null | string;

  /**
   * Specify the MongoS hosts for a sharded cluster and the MongoD hosts for a non-sharded cluster.
   * You can specify a sub-set of the hosts.
   */
  hosts: string[];

  /**
   * Set to true if connection to MongoDB has to be over SSL.
   */
  isSslRequired: boolean;

  /**
   * Specifies the password for the MongoDB cluster. Should be set if 'authType' is 'LDAP' or 'SCRAM'.
   */
  password?: null | string;

  /**
   * Specifies the principal name of the MongoDB cluster. Should be set if 'authType' is 'KERBEROS'.
   */
  principal?: null | string;

  /**
   * MongoDB Secondary node tag. Required only if 'useSecondaryForBackup' is true.
   * The system will use this to identify the secondary nodes for reading backup data.
   */
  secondaryNodeTag?: string;

  /**
   * Specifies backup from node option.
   */
  backupFromNode: string;

  /**
   * Specifies the username of the MongoDB cluster. Should be set if 'authType' is 'LDAP' or 'SCRAM'.
   */
  username?: null | string;
}
