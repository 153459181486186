import { ProtectionSourceNode } from '@cohesity/api/v1';
import {
  DataTreeControl,
  DataTreeFilter,
  DataTreeFilterUtils,
  DataTreeNode,
  DataTreeTransformer,
} from '@cohesity/helix';
import { SourceTreeFilters, TagAttribute, ViewFilterOption, ViewFilterType } from '@cohesity/iris-source-tree';
import { map } from 'rxjs/operators';
import { Environment } from 'src/app/shared/constants';

import { KubernetesSourceDataNode } from './kubernetes-source-data-node';
import { KubernetesProtectionTypes } from './kubernetes.constants';

export class KubernetesViewFilters {
  /**
   * These are the default view filters.
   */
  defaultViewFilters: ViewFilterOption[];

  constructor(
    private filters: SourceTreeFilters<KubernetesSourceDataNode>,
    private treeControl: DataTreeControl<KubernetesSourceDataNode>,
    private dataTransformer: DataTreeTransformer<ProtectionSourceNode>,
  ) {
    this.defaultViewFilters = [
      {
        id: ViewFilterType.Physical,
        tooltip: 'sourceTreePub.tooltips.hierarchyView',
        filter: this.filterPhysicalChildren,
        icon: 'helix:hierarchy-physical',
      },
      {
        id: ViewFilterType.Flat,
        tooltip: 'sourceTreePub.tooltips.flatNamespaceView',
        filter: this.filterNamespaceChildren,
        icon: 'helix:hierarchy-flat',
      },
      {
        // The filter for tags is an observable based on the selected tags observable
        // in source tree filters.
        id: ViewFilterType.Label,
        filter: this.filters.selectedTags$.pipe(map((tags: TagAttribute[]) => this.getTagFilter(tags))),
        tooltip: 'labels',
        icon: 'helix:hierarchy-tag',
        isTag: true,
        isTagLabel: true,
      },
    ];

    this.filters.setViewFilters(this.defaultViewFilters);
  }

  /**
   * Filter callback function to show the phsyical tree hierarchy of a vcenter.
   */
  filterPhysicalChildren: DataTreeFilter<any> = (nodes: KubernetesSourceDataNode[]) =>
    DataTreeFilterUtils.hierarchyExcludeFilter(
      nodes,
      node =>
        (![KubernetesProtectionTypes.kNamespace, KubernetesProtectionTypes.kCluster].includes(
          node.type as KubernetesProtectionTypes))
    );


  /**
   * Filter callback to show a flat list of vms from a vcenter.
   */
  filterNamespaceChildren: DataTreeFilter<any> = (nodes: KubernetesSourceDataNode[]) => {
    const seenNodes = new Set<number | string>();
    return nodes
      .filter(node => {
        const matched = (node.envSource as any).type === KubernetesProtectionTypes.kNamespace;
        if (!matched || seenNodes.has(node.id)) {
          return false;
        }
        seenNodes.add(node.id);
        return true;
      })
      .map(node => this.dataTransformer.transformData(node.data, 0))
      .sort((a: KubernetesSourceDataNode, b: KubernetesSourceDataNode) => a.name.localeCompare(b.name));
  };

  /**
   * Create a data tree filter from a set of selected tags
   *
   * @param   tags   The tags to filter by
   * @returs  A filter function that will select the desired tags.
   */
  getTagFilter(tags: TagAttribute[]): DataTreeFilter<any> {
    // If there are no tags specified yet, show a flat list of all vms
    if (!tags || !tags.length) {
      return this.filterNamespaceChildren;
    }
    return (nodes: KubernetesSourceDataNode[]) => {
      const tagIds = tags.map(tag => Number(tag.id));
      const tagNames = tags.map(tag => tag.name);

      const tagNode = this.createTagNode(tagIds, nodes, tagNames);
      const taggedNodes: DataTreeNode<any>[] = [tagNode];
      for (const node of (tagNode.data.nodes as ProtectionSourceNode[])){
        taggedNodes.push(this.dataTransformer.transformData(node, 1));
      }
      this.treeControl.expand(tagNode);

      return taggedNodes;
    };
  }

  /**
   * Create a dummy node to add to the root of the tree. This should have a level of 0 and all
   * of it's children will be at level 1.
   *
   * @param   tagIds    Array of all the tag ids.
   * @param   allNodes  The unfiltered list of tree nodes.
   * @param   tagNames  Array of tag names.
   * @returns A new tag node that can be added to the tree.
   */
  createTagNode(tagIds: number[],
    allNodes: KubernetesSourceDataNode[],
    tagNames: string[] = []): KubernetesSourceDataNode {
    if (!tagNames || !tagNames.length && tagIds.length) {
      tagNames = tagIds.map(tagId => allNodes.find(node => node.isTag && node.id === tagId))
        .map(node => node && node.name)
        .filter(name => !!name);
    }

    // Find all of the matching tagged nodes
    const taggedNodes = this.filterTaggedNodes(allNodes, tagIds);

    return this.dataTransformer.transformData(
      {
        protectionSource: {
          // The tag id  may contain ids for multiple tags
          id: tagIds.join('_') as any,

          // Include all of the tags in the name
          name: tagNames.join(', '),
          environment: Environment.kKubernetes,
          kubernetesProtectionSource: {
            type: 'kLabel' as any,
          },
        },
        nodes: taggedNodes.map(node => node.data),
      },
      0
    ) as KubernetesSourceDataNode;
  }

  /**
   * Filter a list of nodes for all nodes that match all of the tag ids.
   *
   * @param   nodes  Any nodes that are matched by this one.
   * @param   tagIds         One more tags represented by the node.
   * @returns A new tag node that can be added to the tree.
   */
  private filterTaggedNodes(nodes: KubernetesSourceDataNode[], tagIds: number[]): KubernetesSourceDataNode[] {
    const seenNodes = new Set<number | string>();
    return nodes.filter(node => {
      // Make sure that we don't return duplicates.
      if (!node.tagIds || seenNodes.has(node.id) || node.type !== KubernetesProtectionTypes.kNamespace) {
        return false;
      }
      seenNodes.add(node.id);

      // Must match all of the requested tags.
      return tagIds.every(searchTag => node.tagIds.find(tagId => (tagId as any) === searchTag));
    });
  }
}
