// MODULE: Job Run Details

;(function(angular, undefined) {
  'use strict';

  angular.module('C.jobRunDetails')
    .controller('jobRunDetailsProtectionController',
      jobRunDetailsProtectionControllerFn);

  function jobRunDetailsProtectionControllerFn(
    _, $q ,$rootScope, $scope, $timeout, $state, $filter, $translate, JobActionFormatter,
    DateTimeService, JobActionService, JobRunsService, SourceService,
    DeleteJobRunsModal, JobService, cUtils, PollTaskStatus,
    ENUM_BACKUP_JOB_STATUS, ENUM_BACKUP_JOB_STATUS_LABEL_CLASSNAME,
    ENUM_BACKUP_LOCAL_JOB_STATUS_ICON_NAME, evalAJAX,cMessage, ENV_GROUPS,
    ENV_TYPE_CONVERSION, FEATURE_FLAGS) {

    angular.extend($scope, {
      // convenience functions and variables
      ENUM_BACKUP_JOB_STATUS: ENUM_BACKUP_JOB_STATUS,
      ENUM_BACKUP_JOB_STATUS_LABEL_CLASSNAME:
        ENUM_BACKUP_JOB_STATUS_LABEL_CLASSNAME,
      ENUM_BACKUP_LOCAL_JOB_STATUS_ICON_NAME:
        ENUM_BACKUP_LOCAL_JOB_STATUS_ICON_NAME,
      ENV_GROUPS: ENV_GROUPS,

      /**
       * Text translations for Reports Filter module
       * @type {Object}
       */
      text: $rootScope.text.protectionJobsDetailsRunRunProtection,

      // number of items to show on paginated smart-table
      itemsPerPage: 20,

      pollData: {},
      previousAttemptData: [],
      expandedRows: {},
      expandedDbRows: {},
      protectionDataReady: false,
      expandedTasks: false,
      isLog: false,
      noSnapshotsSelected: true,
      isDeleteObjectsDisabled: false,
      isAddLegalHoldDisabled: false,
      isRemoveLegalHoldDisabled: false,
      isFileStubbingJob: !!$state.params.fileStubbing,

      /**
       * High Level Statistic values for 'At A Glance'
       * @type {Object}
       */
      statusCounts: {
        cancelled: 0,
        error: 0,
        running: 0,
        success: 0,
        total: 0,
        servers: 0,
        db: {
          databases: 0,
          instances: 0,
          success: 0,
          error: 0,
        }
      },
      /**
       * Tab Configuration
       * @type {Array}
       */
      cTabConfig: [{
        name: $scope.text.backupRun,
        value: 'job-run-details.protection',
        params: {
          id: $state.params.id,
          instanceId: $state.params.instanceId,
          startTimeUsecs: $state.params.startTimeUsecs,
        }
      }],

      fileStubbing: !!$state.params.fileStubbing,

      // For gradually moving to server side pagination
      visibleTasks: [],
      getPageData: getPageData,
    });

    // Used for the periodic polling of progressMonitor api to update the
    // tasks progress.
    var endPollerPromise;
    var pollInterval = 30000;

    // Used to avoid fetching event logs.
    var fetchLogs = true;

    // This variable is used to include the params representing the tasks in
    // active paginated area. These parameters are send along with the
    // the progressMonitor API call so that the response will only contain
    // information related to the tasks in the active paginated area.
    var _progressMonitorTaskPathParams = '';

    // Specifies the URL Prefix for fetching the Pulse tree with SubTasks.
    var _paginatedProgressMontiorUrlPrefix =
      'progressMonitors?includeFinishedTasks=true&excludeSubTasks=false';

    // The following parameters are used to reduce redundant sorting and
    // filtering during transition from each active pages in the paginated
    // table.
    var prevFilterPredicateName = '';
    var prevSortPredicate = '';
    var prevSortIsReverse = false;

    // Used to check whether the pagination data is getting loaded for the
    // first time. This is used such that filtering and sorting will be done
    // whenever the table gets loaded for the first time.
    var initialLoad = true;

    // Used to persist the sorted and filtered data if no changes were made
    // to the current configuration.
    var filteredTasks = [];

    /**
     * Get the paginated data from backend. For now, the pagination is done on
     * the client side. This will be later moved to the backend. This function
     * will be invoked whenever the pagination moves from one page to another.
     * Hence this specific method is also used to filter out the task id in
     * the active paginated space.
     *
     * @method getPageData
     *
     * @param   {object}    tableState    The state of the paginated table.
     */
    function getPageData(tableState) {
      var pagination = tableState.pagination;
      var start = pagination.start || 0;
      var number = pagination.number || $scope.itemsPerPage;

      /** The table should filter in three situations:.
       * 1. During Initial Load.
       * 2. When the search filter is cleared, the predicateObject will exist
       *    within the tableState.search but there won't be any _normalized
       *    Entity. In this case also, the filter should be applied.
       * 3. When the search filter changes. This means that there will be a
       *    change in the value of predicateObject._normalizedEntity.name. In
       *    this case both predicateObject and _normaliziedEntity will exist.
       */
      var shouldFilter = (tableState.search.predicateObject) ? (initialLoad ||
        (tableState.search.predicateObject._normalizedEntity ?
        (prevFilterPredicateName !== tableState.search.predicateObject.
        _normalizedEntity.name) : true )): false;
      var shouldSort = tableState.sort.predicate ? (initialLoad ||
        prevSortPredicate !== tableState.sort.predicate ||
        prevSortIsReverse !== tableState.sort.reverse ): false;

      if (initialLoad) {
        filteredTasks = $scope.jobRun.allTasks;
        initialLoad = false;
        // The jobRun structure is decorated with the variable
        // _progressMonitorTaskPath which contains the url for making calls to
        // pulse. Normally this call is made with the key-value
        // 'excludeSubTasks=false'. The response structure will contain a task
        // vector which contains details about the job runs. The details about
        // all the objects are contained in a key 'subTaskVec' if the
        // 'excludeSubTasks' is set to false. Inorder to get a paginated data,
        // we set 'excludeSubTasks' to true and provide the taskPaths of all
        // needed objects in the request payload which will change the response
        // to a flattened structure containing the objects details only
        // at the same level.
        if ($scope.jobRun._progressMonitorTaskPath) {
          $scope.jobRun._progressMonitorTaskPath =
            $scope.jobRun._progressMonitorTaskPath.replace(
              '&excludeSubTasks=false','&excludeSubTasks=true'
            );
        }
      }

      // Filtering on the search word.
      if (shouldFilter) {
        filteredTasks = $filter('filter')($scope.jobRun.allTasks,
          tableState.search.predicateObject);
        prevFilterPredicateName = tableState.search.predicateObject.
        _normalizedEntity ? tableState.search.predicateObject.
        _normalizedEntity.name : '';
      }

      // Sorting the results.
      if (shouldSort) {
        filteredTasks = $filter('orderBy')(filteredTasks,
          tableState.sort.predicate, tableState.sort.reverse);
        prevSortPredicate = tableState.sort.predicate;
        prevSortIsReverse = tableState.sort.
          predicate ? tableState.sort.reverse: false;
      }

      // Slicing the data according to pagination params.
      $scope.visibleTasks = filteredTasks.slice(start, start + number);

      tableState.pagination.numberOfPages =
        Math.ceil(filteredTasks.length / number);

      tableState.pagination.totalItemCount = filteredTasks.length;

      _progressMonitorTaskPathParams = _createPaginatedTaskParams();

      monitorProgress(false);
    }

    /**
     * Creates the  request params, for filtering task ids in the active area
     * of the paginated table, for the progress monitor API
     *
     * @method   _createPaginatedTaskParams
     *
     * @return    {string}    The constructed query param.
     */
    function _createPaginatedTaskParams() {
      return $scope.visibleTasks.reduce(function addParam(params, task) {
        var paginatedParams =
          params + '&taskPathVec=' + task.base.progressMonitorTaskPath;

        (task._appEntities || []).forEach(
          function processAppEntities(appEntity) {
            // See if the task has any database app entites linked to it. If
            // so add that to the query params so as to get the updated
            // event vec for those databases.
            paginatedParams += '&taskPathVec=' +
              task.base.progressMonitorTaskPath + '/copy_snapshot/' +
              appEntity.dbEntity.uuid;
          }
        );

        return paginatedParams;
      }, '');
    }

    /**
     * Process the Job Run data as returned in JobRunDetailsParentController.
     * Job Run details stored in $scope.jobRun.
     *
     * @method   processData
     */
    function processData() {

      $scope.protectionDataReady = false;

      // Set up container array for all job run tasks (both finished and active)
      $scope.jobRun.allTasks = [];

      // are backup snapshots managed on prem or remotely
      $scope.areSnapshotsManagedRemotely =
        ENV_GROUPS.cloudJobsWithoutLocalSnapshot.includes(
          _.get($scope, 'job.jobDescription.type'));

      $scope.isOffice365WorkLoad = ENV_GROUPS.office365.includes(
        _.get($scope, 'job.jobDescription.type'));

      $scope.getJobStatusIcon = JobRunsService.getJobStatusIcon;

      // Format Job Run for consumption in template
      $scope.jobRun =
        JobRunsService.processJobRun($scope.jobRun, $scope.terminatePoll);

      $scope.entityKey =
        SourceService.getEntityKey($scope.job.jobDescription.parentSource.type);

      // Set Database Specific scope variables for template
      if (ENV_GROUPS.databaseSources
          .includes($scope.jobRun.backupRun.base.type)) {
        // Is this a Log run?
        $scope.isLog = $scope.jobRun.backupRun.base.backupType === 2;

        // Set Database Job Run Specific counts
        if ($scope.jobRun.backupRun) {
          // 1. Consider databases count only for sql, oracle 'file based jobs'.
          // 2. Oracle only supports file based jobs,
          //    where as SQL supports both file and volume based.
          // 3. Refer magneto/base/magneto.proto ENUM FullBackupType.
          if (_.get($scope.jobRun.backupRun,
            'envBackupParams.sqlBackupJobParams.fullBackupType') !== 0) {
            $scope.statusCounts.db.databases =
            ($scope.jobRun.backupRun.numSuccessfulAppObjects || 0) +
            ($scope.jobRun.backupRun.numFailedAppObjects || 0) +
            ($scope.jobRun.backupRun.numCancelledAppObjects || 0);
          }

          $scope.statusCounts.db.instances =
            $scope.jobRun.backupRun.numAppInstances || 0;
        }

      }

      // Set Job Level Status
      $scope.jobRun.backupRun._jobStatus =
        JobService.getStatus($scope.jobRun.backupRun);

      // Get SnapshotdeletionInfo
      $scope.jobRun.allTasks.forEach(function eachTask(task) {
        task._snapshotMarkedForDeletion =
          JobRunsService
            .isSnapshotMarkedForDeletion(
              $scope.jobRun.copyRun,
              task._sourceObject.id
            );
      });

      // Kick off Progress Monitoring
      monitorProgress(true);

      // Update Status Counts
      buildStatusCounts();

      // Build Job Run Actions Menu
      getJobActions();

      // Reveal data in the UI / hide the spinner
      $scope.protectionDataReady = true;

    }

    /**
     * Generate Pulse Url with or without subtasks logs and Event Logs
     *
     * @method generatePulseUrlWithOrWithoutSubtasksAndEventLogs
     * @param    {Bool}     excludeSubTasks    Boolean to tell whether to
     *                                         exclude subtasks logs.
     * @param    {Bool}     includeEventLogs   Boolean to tell whether to
     *                                         include Event logs or not.
     * @return   {String}   The url to query Progress Monitor.
     */
    function generatePulseUrlWithOrWithoutSubtasksAndEventLogs(
      excludeSubTasks = false, includeEventLogs = true) {
      var url;
      _paginatedProgressMontiorUrlPrefix.replace(
        'excludeSubTasks=' + !excludeSubTasks,
        'excludeSubTasks=' + excludeSubTasks);
      url = _paginatedProgressMontiorUrlPrefix +
        '&includeEventLogs=' + includeEventLogs +
        _progressMonitorTaskPathParams;
      return url;
    }

    /**
     * Get Task Status from a url.
     *
     * @method getTaskStaus
     * @param   {string}   url                 Task Status Url.
     * @param   {Bool}     isFullData          Whether to make call for
     *                                         full data or the paginated
     *                                         data.
     * @param   {Bool}     includeEventLogs    Boolean to tell whether to
     *                                         include Event logs or not.
     */
    function getTaskStatus(url, isFullData, includeEventLogs) {
      // Get Task Status from all attempts
      PollTaskStatus.getTaskNoPoll(url)
        .then(function getTaskNoPollSuccess(r) {
          if (includeEventLogs) {
            fetchLogs = false;
          }
          var allAttempts = [];
          if (r.data.resultGroupVec.length === 1) {
            // Only one attempt returned, it's so easy!
            if (isFullData){
              $scope.pollData = r.data.resultGroupVec[0].taskVec ?
                r.data.resultGroupVec[0].taskVec[0].subTaskVec : {};
            } else {
              $scope.pollData = r.data.resultGroupVec[0].taskVec ?
                r.data.resultGroupVec[0].taskVec: {};
            }

          } else {
            // For each attempt resultGroupVec we need to update
            // $scope.pollData and update jobRun.allTasks with newly mapped
            // task data
            if (isFullData){
              angular.forEach(r.data.resultGroupVec,
                function parseAttemptData(attempt, i) {
                // Attempts may return no data, so we must check for taskVec
                if (attempt.taskVec && attempt.taskVec.length) {
                  allAttempts = allAttempts.concat(
                    attempt.taskVec[0].subTaskVec);
                }
              });
            } else {
            // There are two calls being made, one which gives the full
            // object info, which is invoked only once at monitorProgress();
            // another which will give only information about the objects
            // in the paginated area. Note that this may or may not contain
            // the job info. As both of these calls, returns the data in
            // different format, the data is transformed here. Specifically,
            // For each attempt in resultGroupVec we need to flatten it into
            // the subTaskVec format returned by the full information call.
            // $scope.pollData and update jobRun.allTasks with newly mapped
            // task data.
              var subTaskVec = [];
              angular.forEach(r.data.resultGroupVec,
                function loopOverResultVec(result, i){
                if (result.taskVec && result.taskVec.length) {
                  subTaskVec.push(result.taskVec[0]);
                }
              });
              if (subTaskVec.length) {
                allAttempts = allAttempts.concat(subTaskVec);
              }
            }
            $scope.pollData = allAttempts;
          }
        }
      )
      .finally(
        function afterGetAllAttemptData() {
          updateAllTasks();
          // if the backupRun is not finished
          // kickoff the poll to monitor progress
          if ($scope.jobRun.backupRun._jobStatus < 2) {
            // Stop the ongoing polling.
            if (endPollerPromise) {
              endPollerPromise.resolve();
              endPollerPromise = null;
            }

            // Kick off Progress Monitoring after instantiating a new promise.
            endPollerPromise = $q.defer();

            if (_progressMonitorTaskPathParams) {
              // Do not include the query for the entire pulse tree while
              // fetching rogress of items in page and also exclude subtasks
              // since we are already adding them as parameters.
              startPoll(generatePulseUrlWithOrWithoutSubtasksAndEventLogs(
                true, includeEventLogs), false);
            } else {
              startPoll($scope.jobRun._progressMonitorTaskPath, true);
            }
          }
        }
      );
    }

    /**
     * Update Progress Monitor by getting Task status
     *
     * @method updateProgressMonitor
     */
    function updateProgressMonitor() {
      if (!fetchLogs) {
        return;
      }
      var url;
      url = generatePulseUrlWithOrWithoutSubtasksAndEventLogs(false, true);
      getTaskStatus(url, false, true);
    }

    /**
    * Monitor Progress. Get Task status data for this Job Run
    *
    * @method   monitorProgress
    *
    * @param   {Bool}  isFullData        Indicates whether to make call for
    *                                    full data or the paginated data. The
    *                                    first call should always be full data
    *                                    as paginated details can only be
    *                                    retrieved when the st-pipe function
    *                                    is invoked.
    * @param   {Bool}  includeEventLogs  Boolean to tell whether to include
    *                                    Event Log.
    */
    function monitorProgress(isFullData, includeEventLogs = false) {
      if ($scope.jobRun._progressMonitorTaskPath) {
        var url;

        // If Job is finished include event logs or
        // if feature flag is off include logs.
        if ($scope.jobRun.backupRun._jobStatus >= 2 ||
          !FEATURE_FLAGS.taskLevelPulseLoggingEnabled) {
          includeEventLogs = true;
        }
        if (isFullData) {
          // This will make a normal pulse call which will return data
          // as a tree where all objects info will be nested with the
          // key 'subTaskVec' within the job run info object.
          url = $scope.jobRun._progressMonitorTaskPath;
        } else {
          // This will make a call which will return data as an array of
          // objects info under the key 'resultGroupVec'. Each of the object
          // will have a key called 'taskVec' which inturn will be associated
          // with the object info. The number of objects and what objects to
          // returned is according to the _progressMonitorTaskPathParams.
          url = _paginatedProgressMontiorUrlPrefix +
            _progressMonitorTaskPathParams;
        }
        url += '&includeEventLogs=' + includeEventLogs;
        getTaskStatus(url, isFullData, includeEventLogs);
      }
    }

    /**
    * Callback for Remote adapter Job Pulse.
    *
    * @method   remoteAdapterProgressCallback
    */
    $scope.remoteAdapterProgressCallback =
      function remoteAdapterProgressCallback(r) {
      if (typeof(r) === 'object') {
        $scope.jobRun.backupRun._eventVecs =
          angular.copy(r.subTaskVec[0].progress.eventVec);
      }
      // If completed, hide the progress monitor
      if (typeof(r) === 'string') {
        $scope.jobRun._progressMonitorTaskPath = null;
      }
    };

    /**
    * Aggregate Job Status Counts.
    *
    * @method   buildStatusCounts
    */
    function buildStatusCounts() {
      // Assign values ot Status Counts
      $scope.statusCounts.success = _getCountByStatus('success');
      $scope.statusCounts.cancelled = _getCountByStatus('cancelled');
      $scope.statusCounts.error = _getCountByStatus('error');
      $scope.statusCounts.running = _getCountByStatus('running') ||
        _.get($scope.jobRun.backupRun, 'activeAttempt.activeTasks.length', 0);
      $scope.statusCounts.total =
        $scope.statusCounts.success +
        $scope.statusCounts.error +
        $scope.statusCounts.cancelled +
        $scope.statusCounts.running;
      $scope.statusCounts.servers = $scope.jobRun.allTasks.length;
      populateDbStatus();
    }

    /**
     * Update the status counts in cache.
     *
     * @method buildStatusCountsInCache
     */
    function buildStatusCountsInCache() {
      $scope.statusCounts.running = 0;
      $scope.statusCounts.success = 0;
      $scope.statusCounts.error = 0;
      $scope.statusCounts.cancelled = 0;
      $scope.jobRun.allTasks.forEach(function getCounts(element) {
        switch (element._status) {
          // Warnings are treated as success as we do have a successful snapshot
          // in them.
          case 2.1:
          case 2.3:
            $scope.statusCounts.success++;
            break;

          case 2.2:
            $scope.statusCounts.error++;
            break;

          case 3:
            $scope.statusCounts.cancelled++;
            break;

          default:
            $scope.statusCounts.running++;
            break;
        }
      });

      $scope.statusCounts.total =
        $scope.statusCounts.success +
        $scope.statusCounts.error +
        $scope.statusCounts.running +
        $scope.statusCounts.cancelled;

      populateDbStatus();
    }

    /**
     * Returns the number of tasks having the given status.
     *
     * @method   _getCountByStatus
     * @param    {string}    [status='success']   One of 'success', 'error',
     *                                            'running', 'cancelled'.
     * @return   {integer}    Number of tasks having given status.
     */
    function _getCountByStatus(status) {
      var backupRun = $scope.jobRun.backupRun;
      var defaultCount = 0;
      var kStatusVec = [];
      var numStatusKey;

      switch (status) {
        case 'error':
          kStatusVec.push('kFailure');
          numStatusKey = 'numFailedTasks';
          break;

        case 'cancelled':
          kStatusVec.push('kCancelled');
          numStatusKey = 'numCancelledTasks';
          break;

        case 'running':
          kStatusVec.push('kRunning');
          numStatusKey = 'numRunningTasks';
          break;

        default:
          kStatusVec.push('kSuccess');
          numStatusKey = 'numSuccessfulTasks';
      }

      defaultCount = backupRun[numStatusKey] || defaultCount;

      // TODO(tauseef): Does this need ot account for Oracle too?
      return _.get(backupRun,
          'envBackupParams.sqlBackupJobParams.fullBackupType') === 0 ?
        // All jobs except File-based SQL use this count.
        defaultCount :

        // File-based SQL jobs need to calculate totals differently. Go into the
        // UI-constructed jobRun.allTasks object and count up all the things
        // with the requested status.
        $scope.jobRun.allTasks.reduce(
          function tasksReducer(total, task) {
            return (task.appEntityStateVec || []).reduce(
              function statusSummer(_unused, appState) {
                return total +=
                  kStatusVec.includes(appState.publicStatus) ? 1 : 0;
              },
              0
            );
          },
          0
        ) || defaultCount;
    }

    /**
     * Sets success and failed fields at the database level for oracle
     * and Sql.
     *
     * @method   populateDbStatus
     */
    function populateDbStatus() {
      if ($scope.jobDescription.parentSource.oracleEntity ||
        $scope.jobDescription.parentSource.sqlEntity) {
        $scope.statusCounts.db.success =
          $scope.jobRun.backupRun.numSuccessfulAppObjects ||
          $scope.statusCounts.db.success || _getCountByStatus('success');
        $scope.statusCounts.db.error =
          $scope.jobRun.backupRun.numFailedAppObjects ||
          $scope.statusCounts.db.error || _getCountByStatus('error');
        $scope.statusCounts.db.running = $scope.statusCounts.db.running ||
          _getCountByStatus('running');
      }
    }

    /**
    * Translate Pulse Task status to Magneto Task Status.
    *
    * @method   pulseToMagnetoStatus
    * @param    {Object}   progress   Progress object as returned from Pulse
    * @return   {Num}   Task Status
    */
    function pulseToMagnetoStatus(progress){

      // If there is no status object, we will assume that the task is in
      // progress, awaiting a retry, or just now starting. In any case, return
      // status 1 (Running)
      if (!progress.status) {
        return 1;
      }

      switch (progress.status.type) {
        case 1:
        // The task succeeded
        return 2.1;
        case 2:
        // The task has an error
        return 2.2;
        default:
        // The task is still inprogress
        return 1;
      }
    }

    /**
     * Ammends a subTask with formatted data to be simply consumed
     * by the GUI.
     *
     * @method   updateTask
     * @param    {Object}   subTask   As provided by Magneto
     * @return   {Object}             Transformed subTask
     */
    function updateTask(subTask) {
      var percentFinishedString = [
        cUtils.round(subTask.progress.percentFinished),
        $scope.text.completed
      ].join('% ');
      var endTimeString = (subTask.progress.expectedTimeRemainingSecs > 0) ?
        [
          DateTimeService
            .secsToTime(subTask.progress.expectedTimeRemainingSecs).time,
          $scope.text.remaining
        ].join(' ') :
        '';
      var currentEventString =
        _.get(subTask.progress, 'eventVec.slice(-1)[0].eventMsg', '');

      var task = {
        _percentage: subTask.progress.percentFinished,
        _barLabel: percentFinishedString,
        _statusLabel: endTimeString,
        _currentEvent: currentEventString,
        _eventVecs: subTask.progress.eventVec,
        _attempts: subTask._attempts,
        _endTimeUsecs: subTask.progress.endTimeSecs * 1000000,
      };

      // For file backups, we have file walker run before the run actually
      // starts. So there are additional attributes we need to account from the
      // pulse. Below block is only for file based backups.
      if (ENV_GROUPS.fileBackups.includes($scope.jobRun.backupRun.base.type) &&
        subTask.progress.attributeVec) {
        var fileWalkDone = false;
        var totalChangedEntityCount = 0;
        var totalEntityCount = 0;

        subTask.progress.attributeVec.forEach(function forRachAttribute(attr){
          var value = attr.value.data.int64Value;
          switch(attr.key) {
            case 'file_walk_done':
              fileWalkDone = !!value;
              break;

            case 'total_changed_entity_count':
              totalChangedEntityCount = value;
              break;

            case 'total_entity_count':
              totalEntityCount = value;
              break;
          }
        });

        _.merge(task,
          {
            _fileWalkDone: fileWalkDone,
            _totalChangedEntityCount: totalChangedEntityCount,
            _totalEntityCount: totalEntityCount,
          });
      }

      return task;
    }

    /**
     * Maps eventVec data returned from pulse to $scope.jobRun.allTasks. Run
     * this function with each pulse response.
     *
     * @method   updateAllTasks
     */
    function updateAllTasks() {
      // If there is no pulse. Generally in case of replication/remote clusters
      // there is no pulse on the runs. It will go through the below block for
      // info in DBs and will return without updating pulse.

      // TODO (Maulik): The second part of the expression assumes first element
      // is the task pulse. We should get the progressMonitorPath given by
      // Magneto here so that we exactly get the task pulse.
      if (!$scope.pollData.length || !$scope.pollData[0].progress) {
        $scope.jobRun.allTasks.forEach(function loopOverTasks(task) {
          // If there is no pulse, just generate the appEntityHash with all
          // the appEntity data.
          task.appEntityHash = {};
          task._appEntities.forEach(function loopOverAppEntities(appEntity) {
            task.appEntityHash[appEntity.appId] = appEntity;
            appEntity.actions = generateAppEntityActionItems(task, appEntity);
          });
        });
        return;
      }

      $scope.pollData.forEach(function filterTasks(subTask) {
        $scope.jobRun.allTasks.forEach(function loopOverTasks(task) {
          // appEntityHash is created to create a list of all tasks which are
          // part of latest finished tasks or active tasks or previous
          // successful attempts and missing in latest finished tasks.
          if (!task.appEntityHash) {
            task.appEntityHash = {};
          }

          // If there are two or attempts made for a task, we need to pick up
          // the latest task which has a higher taskId. This hash keeps track
          // of the latest task for any app entity.
          if (!task.appIdTaskIdHash) {
            task.appIdTaskIdHash = {};
          }

          // Check if the the subTask returned is a database appEntity or not
          // If it is an appEntity, then the taskpath will not have the prefix
          // "task_". Rather it will have the taskpath set to it database uuid.
          if (task.taskId &&  subTask.taskPath && !subTask.
            taskPath.startsWith('task_')) {
            // Get appEntity event messages
            (task._appEntities || []).forEach(
              function processAppEntities(appEntity) {
                if (appEntity.dbEntity.uuid == subTask.taskPath) {
                  appEntity.progress = subTask.progress;

                  updateAppEntityHash(task, appEntity);
                }
              }
            );
          }


          // If the subTask.taskPath includes the taskId of this task
          // then this is the task we want to update
          if (task.taskId &&
            (subTask.taskPath === ['task_', task.taskId].join(''))) {
            // Get appEntity event messages
            (task._appEntities || []).forEach(
              function processAppEntities(appEntity) {
                updateAppEntityPulse(appEntity, subTask);
                appEntity.actions =
                  generateAppEntityActionItems(task, appEntity);

                updateAppEntityHash(task, appEntity);
              }
            );

            // Update the task
            task._progress = updateTask(subTask);

            // After progress updation, update the endtime if the task has ended
            if (task._progress) {
              _.assign(task, {
                  _endDateUsecs: task._progress._endTimeUsecs || (Date.clusterNow() * 1000),
              });
            }
            // If magneto reports this job is in progress (status less than or
            // equal to 1) we'll update the base status with the pulse status
            // else we'll display Magneto's base status which is presumably more
            // accurate.
            if (task._status <= 1) {
              task._status = pulseToMagnetoStatus(subTask.progress);
              task._statusText =
                ENUM_BACKUP_JOB_STATUS[pulseToMagnetoStatus(subTask.progress)];
            }
          } else if (_.get(task, '_attempts[0]')) {
            // subTask.taskPath does not includes the taskId of this task so
            // we'll check for subTask.taskPath against any attempts in _attempts
            task._attempts.forEach(function loopOverAttempts(attempt) {
              if (subTask.taskPath === ['task_', attempt.taskId].join('')) {
                // Get appEntity event messages
                if (attempt._appEntities) {
                  attempt._appEntities.forEach(
                    function loopOverAppEntities(appEntity) {
                      updateAppEntityPulse(appEntity, subTask);
                      appEntity.actions =
                        generateAppEntityActionItems(task, appEntity);

                      // If no prev attempt exists or the taskId is greater than
                      // prev attempt then replace the existing appEntity with
                      // the new appEntity
                      if (!task.appEntityHash[appEntity.appId] ||
                        attempt.taskId > task.appIdTaskIdHash[appEntity.appId]) {
                        task.appIdTaskIdHash[appEntity.appId] = attempt.taskId;
                        task.appEntityHash[appEntity.appId] = appEntity;
                      }
                    }
                  );
                }

                // Update the task
                attempt._progress = updateTask(subTask);

                // After progress updation, update the endtime if the task
                // has ended
                if (task._progress) {
                  _.assign(task, {
                    _endDateUsecs: task._progress._endTimeUsecs || (Date.clusterNow() * 1000),
                  });
                }
              }
            });
          }
        });
      });
    }

    /**
     * Updates appEntityHash of the task with the appEntity provided.
     *
     * @method  updateAppEntityHash
     * @param   {Object}    task        The task whose appEntityhash needs to
     *                                  updated.
     * @param   {Object}    appEntity   The appEntity details.
     */
    function updateAppEntityHash(task, appEntity) {
      // Update the appIdTaskIdHash as this is latest finished task
      task.appIdTaskIdHash[appEntity.appId] = task.taskId;

      // Overwrite any previous attempts as this is latest finsihed
      // task
      task.appEntityHash[appEntity.appId] = appEntity;
    }

    /**
     * Transforms appEntity object and decorates it with pulse.
     *
     * @method   decorateAppEntity
     * @param    {Object}   appEntity   The decorated appEntity
     * @param    {Object}   subTask     The matching poll task
     * @return   {Object}   Decorated appEntity object with pulse
     */
    function updateAppEntityPulse(appEntity, subTask) {
      var pulse =
        getAppEntityPulse(appEntity.progressMonitorTaskPath, subTask);
      var currentEventString;

      // Initialize event message
      if (pulse && pulse.eventVec) {
        currentEventString = pulse.eventVec.slice(-1)[0].eventMsg;
      }

      _.assign(appEntity, {
        progress: pulse,
        currentEvent: currentEventString,
      });
    }

    /**
     * Gets the application entity pulse.
     *
     * @method   getAppEntityPulse
     * @param    {String}   taskPath   The task path of appEntity
     * @param    {Object}   subTask    The matching poll task
     * @return   {Object}   The application entity pulse object
     */
    function getAppEntityPulse(taskPath, subTask) {
      /**
       * We follow the taskPath and find the db task which is nested object
       * inside the subTask
       *
       * The code is dynamic and handles old and new taskPaths. Magneto recently
       * added a batch layer to the taskPath
       *
       * Old taskPath: 'backup_59425193_1/task_59425194/sql_log_backup/MSSQL12.CHANDAN_MSSQLSERVER1_8'
       * New taskPath: 'backup_59425193_1/task_59425194/sql_log_backup/batch_0/MSSQL12.CHANDAN_MSSQLSERVER1_8'
       *
       * Task name of our subTask is task_59425194. So first we strip the
       * taskPath all the way till task_59425194. Now we are left with the tail.
       * We then traverse the subTask using the tail and find the db task
       * MSSQL12.CHANDAN_MSSQLSERVER1_8
       */
      var taskPathKeys;
      var subTaskIndex;
      var fileTask;

      if (!taskPath || !subTask) { return {}; }

      taskPathKeys = taskPath.split('/');

      // The path is splitted into an array. This finds the index of the subTask
      // taskPath
      subTaskIndex = taskPathKeys.indexOf(subTask.taskPath);

      // Removing the head of the path uptil the subTask leaving just the tail
      taskPathKeys.splice(0, subTaskIndex + 1);

      // Using the tail of the progressMonitorTaskPath, traversing the subTask
      // to find the db task. The reducedObject has initial value of the subTask
      // and slowly it finds the final db task object following the taskPathKeys
      // trail.
      fileTask = taskPathKeys.reduce(
        function traverseTaskKeys(reducedObject, taskKey) {
        if (_.get(reducedObject, 'subTaskVec')) {
          reducedObject = reducedObject.subTaskVec.find(
            function findTask(task) {
              return task.taskPath === taskKey;
            }
          );
        }
        return reducedObject || {};
      }, subTask);

      return fileTask.progress || {};
    }

    /**
     * Generates action items for App Entities of a particular run task.
     *
     * @method   generateAppEntityActionItems
     * @param    {Object}   runTask     The run task
     * @param    {Object}   appEntity   The decorated appEntity
     * @return   {Array}    Action items for each appEntity Object
     */
    function generateAppEntityActionItems(runTask, appEntity) {
      var jobId = $scope.job.jobDescription.jobId;
      var items = [];
      var dbEntity = appEntity.dbEntity;
      var dbType = appEntity.dbType;

      if (runTask.base.backupType === 2 ||
        !($scope.jobRun.backupRun._isFinished &&
          !appEntity.error &&
          dbType &&
          dbEntity &&
          !dbEntity.isSystemDb)) {
        return items;
      }

      if ($rootScope.user.privs.RESTORE_MODIFY) {
        items.push({
          icon: 'icn-recover',
          translateKey: 'recover',
          state: 'recover-db.options',

          // From _recover-db.js: ?jobId&entityId&jobInstanceId
          stateParams: {
            dbType: dbType,
            entityId: appEntity.appId,
            jobId: jobId,
            jobInstanceId: runTask.base.jobInstanceId,
            jobRunStartTime: runTask.base.startTimeUsecs,
            jobUid: runTask.base.primaryJobUid,
            sourceId: runTask._sourceObject.id
          },
        });
      }

      // sql or oracle with oracleClone=true
      if ($rootScope.user.privs.CLONE_MODIFY && (dbType === 'sql' ||
         (dbType === 'oracle' && FEATURE_FLAGS.oracleClone))) {
        items.push({
          icon: 'icn-clone',
          translateKey: 'clone',
          state: 'clone-db.options',

          // From _recover-db.js: ?jobId&sourceId&entityId&jobInstanceId
          stateParams: {
            dbType: dbType,
            entityId: appEntity.appId,
            jobId: jobId,
            jobInstanceId: runTask.base.jobInstanceId,

            // Source Id should be of the parent Application(SQL/Oracle)
            // container.
            sourceId: $scope.job.jobDescription.parentSource.id,
          }
        });
      }

      return items;
    }

    /**
     * Terminates this poll
     * evaluates $scope.jobRun.backupRun
     * @return {Boolean} Terminate poll
     */
    $scope.terminatePoll = function terminatePoll() {
      var terminate = true;
      var i = 0;
      var len = $scope.jobRun.allTasks.length;
      for (i; i < len; i++) {
        // If we find a single task with an active status (1),
        // we don't want to terminate the poll
        if ($scope.jobRun.allTasks[i]._status === 1) {
          terminate = false;
          break;
        }
      }
      return terminate;
    };

    /**
     * Handles visibility of 'Download Tiering Logs' button based on feature flag.
     * @returns
     */
    $scope.showDownloadLogsButton = () => FEATURE_FLAGS.tieringLogsDownloadEnabled;

    /**
     * Handles the visibility of 'Download Tiering Logs' button.
     * @returns
     */
    $scope.disableDownloadLogsButtonTitle = () => {
      // job is not finished
      if ($scope?.jobRun?.allTasks?.[0]?._status === 1) {
        return $translate.instant('downloadTieringLogs.disabled.jobRunInProgress');
      }
      // logs are not available (magneto gFlg is disabled)
      if (!$scope?.jobRun?.backupRun?.latestFinishedTasks?.[0]?.currentSnapshotInfo?.tieringReportsInfo?.reportsDirPath) {
        return $translate.instant('downloadTieringLogs.disabled.reportsNotGenerated');
      }
      return '';
    }

    /**
     * Handles the click event on the 'Download Tiering Logs' button.
     */
    $scope.downloadTieringLogs = function downloadTieringLogs() {
      const { base, latestFinishedTasks } = $scope.jobRun.backupRun;
      const { tieringReportsInfo = {}, viewNameToGc } = latestFinishedTasks[0].currentSnapshotInfo;
      const { jobUid, startTimeUsecs } = base;
      const id = `${jobUid.clusterId}:${jobUid.clusterIncarnationId}:${jobUid.objectId}`;
      const runId = `${jobUid.objectId}:${startTimeUsecs}`;
      const targetViewName = viewNameToGc;
      const filePath = `${tieringReportsInfo.reportsDirPath}/${tieringReportsInfo.reportName}`;
      const data = { id, runId, targetViewName, filePath };
      JobRunsService.downloadTieringReports(data);
    }

    /**
     * presents a confirmation modal before deleting job run
     *
     * @return {Void}
     */
    function deleteJobRun() {
      // Show a challenge modal to prevent accidental deletion
      DeleteJobRunsModal.showModal($scope.isLog, true).then(
        function deleteJobConfirmed(response) {
          // Assemble data for API consumption
          var data = {
            jobRuns: [{
              copyRunTargets: [{
                daysToKeep: 0,
                type: 'kLocal',
              }],
              jobUid:
                JobActionFormatter
                  .formatJobUid($scope.jobRun.backupRun.base.jobUid),
              runStartTimeUsecs: parseInt($state.params.startTimeUsecs, 10)
            }]
          };

          JobRunsService.deleteJobRuns(data).then(
            function deleteJobRunsSuccess(response) {
              // successfully deleted, show success message
              // and reload data
              cMessage.success({
                textKey: 'job.deletedOneSnapshot',
              });

              $timeout(
                // Give magneto a brief delay to update this job
                // before refreshing the page again
                function refreshPageDelay() {
                  $state.go($state.params.fileStubbing ?
                  'job-run-details.file-stubbing-runs' : 'job-run-details.protection',
                    $state.params, {reload: true});
                },
                300
              );
            },
            evalAJAX.errorMessage
          );
        }
      );
    }

    /**
     * Transform ProgressMonitorAPI result such that the listeners can update
     * the data.
     *
     * @method  transformPollFn()
     * @param   {Object}     r          Response got from progressMonitor call.
     * @param   {Promise}    deferred   The promise for listeners.
     */
    $scope.transformPollFn = function transformPollFn(r, deferred){
      if (r.data &&
        r.data.resultGroupVec &&
        r.data.resultGroupVec.length &&
        r.data.resultGroupVec[0].taskVec &&
        r.data.resultGroupVec[0].taskVec.length) {

        // The response should contain an array of taskVecs in which the first
        // taskVec contains the details of the job run itself while the rest
        // of the taskVec each contain the details of each of the objects
        // objects protected by the backup job. Not all information will be
        // available. It will contain the objects in the paginated area.
        var data = r.data.resultGroupVec[0].taskVec[0];

        if (data.progress.status.type === 0) {
          // If the task is still running
          data._pollingStatus = 'active';
          deferred.notify(r);
        }

        if (data.progress.status.type !== 0) {
          // If the task is not running
          if (data.progress.percentComplete === 100 || data.
            progress.percentFinished === 100) {
            // If the task is 100% complete
            data.progress._pollingStatus = 'completed';
          } else {
            // If the task is not 100% complete
            // but it's still not running, we will assume an error occurred
            data.progress._pollingStatus = 'error';
          }
          // Send a notification to listeners.
          deferred.notify(r);

          // Let's resolve the promise
          deferred.resolve(r);
        }
      }
    };

    /**
     * processPollResult processes the result of the poll function for
     * invoking the progress monitor API and updates the $scope.pollData
     * accordingly.
     *
     * @method   processPollResult
     * @param    {Object}    r     The response got from polling.
     * @param    {boolean}   includePulseTreeRootNode   Specifies whether root
     *                                                  node is queried.
     */
    function processPollResult(r, includePulseTreeRootNode) {
      if (r.data && r.data.resultGroupVec && r.data.resultGroupVec.length) {
        var allAttempts = [];
        // The response structure contains the information about the job
        // as the first item only when root node is qeuried and the information
        // about the objects are available in the subsequent items.
        // As the job run info is used only in the above transformation
        // function, we can safely purge that particular task and then arrange
        // the taskVec into a flattened list.
        if (includePulseTreeRootNode) {
          r.data.resultGroupVec.shift();
        }

        if (r.data.resultGroupVec.length === 1) {
          // Only one attempt returned, it's so easy!
          $scope.pollData = r.data.resultGroupVec[0].taskVec ?
            r.data.resultGroupVec[0].taskVec: {};
        } else {
          // There are two calls being made, one which gives the full
          // object info, which is invoked only once at monitorProgress();
          // another which will give only information about the objects
          // in the paginated area. Note that this may or may not contain
          // the job info. As both of these calls, returns the data in
          // different format, the data is transformed here. Specifically,
          // For each attempt in resultGroupVec we need to flatten it into
          // the subTaskVec format returned by the full information call.
          // $scope.pollData and update jobRun.allTasks with newly mapped
          // task data.
          var subTaskVec = [];
          angular.forEach(r.data.resultGroupVec,
            function loopOverResultVec(result, i){
            if (result.taskVec && result.taskVec.length) {
              subTaskVec.push(result.taskVec[0]);
            }
          });

          if (subTaskVec.length) {
            allAttempts = allAttempts.concat(subTaskVec);
          }
          $scope.pollData = allAttempts;
        }
      }
    }

    /**
     * Poll for Task level progress monitoring
     *
     * @method   startPoll
     * @param    {String}    taskStatusURL
     * @param    {boolean}   includePulseTreeRootNode   Specifies whether root
     *                                                  node is queried.
     */
    function startPoll(taskStatusURL, includePulseTreeRootNode) {
      // Instantiate an ongoing poll to get updates
      PollTaskStatus.getTask(taskStatusURL, pollInterval, 10,
        $scope.terminatePoll, endPollerPromise.promise, $scope.transformPollFn)
        .then(function onSuccess(r) {
        if (r) {
          processPollResult(r, includePulseTreeRootNode);
          updateAllTasks();
        }
      }, evalAJAX.errorMessage, function notifyPulse(r) {
        processPollResult(r, includePulseTreeRootNode);
        updateAllTasks();
        buildStatusCountsInCache();
      });
    }

    /**
     * Toggles the expanded rows for run tasks. Shows/hides the event msgs
     * coming from the pulse data. When showing event msgs update Progress
     * Monitor.
     *
     * @method   updateTaskRowProperties
     * @param    {Object}   task   Run task
     */
    $scope.updateTaskRowProperties = function updateTaskRowProperties(task) {
      $scope.expandedRows[task.taskId] = !$scope.expandedRows[task.taskId];
      if ($scope.expandedRows[task.taskId] &&
        FEATURE_FLAGS.taskLevelPulseLoggingEnabled) {
        updateProgressMonitor();
      }
    };

    /**
     * Toggles the expanded rows for app entities. Shows/hides the event msgs
     * coming from the pulse data. When showing event msgs update Progress
     * Monitor
     *
     * @method   updateDbTaskRowProperties
     * @param    {Object}   appEntity   The app entity object
     */
    $scope.updateDbTaskRowProperties = function updateDbTaskRowProperties(
      appEntity) {
      $scope.expandedDbRows[appEntity.appId] =
        !$scope.expandedDbRows[appEntity.appId];
      if ($scope.expandedDbRows[appEntity.appId] &&
        FEATURE_FLAGS.taskLevelPulseLoggingEnabled) {
        updateProgressMonitor();
      }
    };

    /**
     * Get Job Actions returns a configuration object for contextual actions
     * menu based on job status
     *
     * @method   getJobActions
     * @return   {Array}   Contextual Menu Configuration Objects
     */
    function getJobActions() {
      $scope.actions = [];

      // modification in job run is not allowed for the user who is not from the
      // organization owning the job.
      if (!$scope.jobDescription._isJobOwner) {
        return;
      }

      $scope.jobRun.allTasks.forEach(function eachTask(task) {
        task._actionItems = $scope.generateActionItems($scope.jobRun, task);
      });

      // Actions at top of page
      if ($rootScope.user.privs.PROTECTION_JOB_OPERATE &&
        $scope.jobRun.backupRun._jobStatus === 1) {
        $scope.actions.push(
          JobActionService.getJobAction('cancel', $scope.job,
            function cancelCallback() {
              $timeout(
                function refreshPageDelay() {
                  $state.go($scope.job.jobDescription._isDataMigrationJob ?
                    'job-run-details.file-stubbing-runs' :
                    'job-run-details.protection',
                    $state.params, {reload: true});
                },
                4000
              );
            }
          )
        );
      }

      if ($rootScope.user.privs.PROTECTION_MODIFY &&
        $scope.jobRun._hasLocalSnapshot &&
        $scope.jobRun.backupRun._jobStatus > 1 &&
        !$scope.jobRun.backupRun.snapshotsDeleted) {
        $scope.actions.push({
          display: $scope.text.delRunSnap,
          action: deleteJobRun,
          disabled: $scope.jobRun.backupRun._wormLocked ||
            $scope.jobRun.backupRun._legalHoldEnabled ||
            ($scope.jobDescription.isDirectArchiveEnabled &&
              $scope.jobRun.backupRun.base.jobInstanceId !==
                $scope.lastRun.backupRun.jobRunId),
        });
      }

    }

    /**
     * Determines if object with a particular id is on legal hold.
     *
     * @method   _isObjectIdOnLegalHold
     * @param    {number}    id   The object identifier
     * @return   {boolean}   True if object is on legal hold, False otherwise.
     */
    function _isObjectIdOnLegalHold(id) {
      return _.get($scope.jobRun.backupRun,
        '_entitiesOnLegalHold', []).includes(id);
    }

    /**
     * Determines the disability conditions for all the actions performed on the
     * run object. Called after any object selection is changed. It further
     * creates the actions again with the new conditions
     *
     * @method   _updateObjectActionsDisability
     */
    function _updateObjectActionsDisability() {
      var selectedObjectIds = $scope.getSelectedObjectIds();
      $scope.isDeleteObjectsDisabled =
        // Run is worm locked
        $scope.jobRun.backupRun._wormLocked ||

        // Run is on legal hold
        $scope.jobRun.backupRun._legalHoldEnabled ||

        // If any selected object is on legal hold
        !!selectedObjectIds.some(_isObjectIdOnLegalHold);

      $scope.isAddLegalHoldDisabled =
        // If a user does not have data security privileges
        !$rootScope.user.privs.DATA_SECURITY ||

        // Run is on legal hold
        $scope.jobRun.backupRun._legalHoldEnabled ||

        // If all selected objects are on legal hold
        selectedObjectIds.every(_isObjectIdOnLegalHold);

      $scope.isRemoveLegalHoldDisabled =
        // If a user does not have data security privileges
        !$rootScope.user.privs.DATA_SECURITY ||

        // Run is not on legal hold and no selected object is on legal hold
        (!$scope.jobRun.backupRun._legalHoldEnabled &&
          !selectedObjectIds.some(_isObjectIdOnLegalHold));

      // Generate the actions with new disability conditions
      _generateObjectActions();
    }

    /**
     * Gets the task object actions. When checkbox on the object is clicked, we
     * get a list of actions that can be performed on task objects.
     *
     * @method   _generateObjectActions
     */
    function _generateObjectActions() {
      $scope.objectActions = [{
        icon: 'icn-delete',
        displayKey: 'delete',
        disabled: $scope.isDeleteObjectsDisabled,
        clickHandler: $scope.deleteObjects,
      },
      {
        icon: 'icn-add-legalhold',
        displayKey: 'addLegalHold',
        disabled: $scope.isAddLegalHoldDisabled,
        clickHandler:
          $scope.generateLegalHoldPayload.bind(null, true, undefined),
      },
      {
        icon: 'icn-remove-legalhold',
        displayKey: 'removeLegalHold',
        disabled: $scope.isRemoveLegalHoldDisabled,
        clickHandler:
          $scope.generateLegalHoldPayload.bind(null, false, undefined),
      }];
    }

    /**
     * This function is called when we select the check all checkbox. Clicking
     * it selects the objects which are eligible to be selected.
     *
     * @method   toggleAllObjects
     * @param    {Array}   tasks   The list of tasks we want to toggle
     */
    $scope.toggleAllObjects = function toggleAllObjects(tasks) {
      // Clear previouly selected runs
      $scope.shared.selectedObjectsMap = {};

      if ($scope.shared.selectAllCheckbox) {
        angular.forEach(
          tasks,
          function selectTask(task) {
            // TODO(maulik): Simplify the code into a function
            // loop though all displayed runs.
            //if the status is not 1 (running)
            if (task.base.status !== 1 &&

              // if snapshots are not deleted or marked for deletion
              !task.snapshotDeleted &&
              !task._snapshotMarkedForDeletion &&

              // if the snapshots are not worm locked
              !$scope.jobRun.backupRun._wormLocked) {
              // Add the tasks to delete hash if above conditions are met.
              $scope.shared.selectedObjectsMap[task.base.sources[0].source.id] =
                task._normalizedEntity.name;
            }
          }
        );
        $scope.noSnapshotsSelected = false;
      } else {
        $scope.noSnapshotsSelected = true;
      }

      // Trigger the disability checks as a checkbox value changed
      _updateObjectActionsDisability();
    };

    /**
     * Triggered when any checkbox is changed and checks for the various actions
     * to be done like showing delete button or hiding it.
     *
     * @method   selectedObjectsChanged
     */
    $scope.selectedObjectsChanged = function selectedObjectsChanged() {
      // Trigger the disability checks as a checkbox value changed
      _updateObjectActionsDisability();
      $scope.noSnapshotsSelected = true;

      // Check if User has deselected any runs
      angular.forEach($scope.shared.selectedObjectsMap,
        function loopObjectsToDelete(objectValue, sourceId) {
        if (!objectValue) {
          // If we find one value set to false, disable the select all button.
          $scope.shared.selectAllCheckbox = false;
        } else {
          // If we find one value set to true or an integer, enable the
          // delete button.
          $scope.noSnapshotsSelected = false;
        }
      });
    };

    /**
     * Handles checkbox toggling for specific object/task actions. Note: This
     * was previously handled via ngTrueValue and ngModel, but it was necessary
     * to move it to a function as ngTrueValue was messing up with NAS mount
     * paths due to character escaping.
     *
     * @method   toggleObjectSelection
     * @params   {object}   task   The task/object to be toggled for deletion.
     */
    $scope.toggleObjectSelection = function toggleObjectSelection(task) {
      // This evaluation needs to explicitly set the unchecked value to false,
      // as the deletion handling is explicitly excluding sourceIds from
      // deletion if the value is false in _run.js: `formatDeleteJobRunsData()`.
      // NOTE: To reduce the risk of incorrectly deleting objects, it may be
      // wise to delete the element from this hash completely.
      $scope.shared.selectedObjectsMap[task.base.sources[0].source.id] =
        $scope.shared.selectedObjectsMap[task.base.sources[0].source.id] ?
          false : task._normalizedEntity.name;

      $scope.selectedObjectsChanged();
    };

    /**
     * Gets the tooltip key.
     *
     * @method   getTooltipKey
     * @param    {object}   jobRun   The job run
     * @param    {object}   task     The task
     * @return   {string}   The tooltip key.
     */
    $scope.getTooltipKey = function getTooltipKey(jobRun, task) {
      switch (true) {
        case !jobRun._hasLocalSnapshot:
          return 'tooltips.noLocalSnapshot';

        case task.snapshotDeleted || task._snapshotMarkedForDeletion:
          return 'snapshotDeleted';

        default:
          return '';
      }
    };

    /**
     * Determines if the previous attempts are failed attempts for a particular
     * task and decide if the previous attempts has to be shown or not.
     *
     * @method   hasPreviousAttempts
     * @param    {Number}   appEntityId   app entity id of the task
     *
     * @return   {Boolean}  true if the attempts have unsuccessful attempts.
     */
    $scope.hasPreviousAttempts = function hasPreviousAttempts(appEntityId) {
      return $scope.jobRun._failedTaskAppIDs.includes(appEntityId);
    };

    /**
     * Determines if the task context menu should be shown or not
     *
     * @method   showContextMenu
     * @param    jobRun   Job Run object
     * @param    task     Run task object
     *
     * @return   {Boolean} true if context menu should be shown, false otherwise
     */
    $scope.showContextMenu = function showContextMenu(jobRun, task) {
      return !jobRun.backupRun.snapshotsDeleted &&
        !task.snapshotDeleted && !task._snapshotMarkedForDeletion &&
        jobRun.backupRun.base.publicStatus !== 'kRunning' &&
        task._actionItems[0];
    }

    // Listen for job run data to be ready
    // TODO(spencer): Refactor this to use $scope.$broadcast instead.
    if ($scope.jobRunDataReady) {
      processData();
    } else {
      $scope.$on('jobRunDataReady', processData);
    }

  }

})(angular);
