import { Component, Input } from '@angular/core';
import { McmSourceRegistration } from '@cohesity/api/v2';

/**
 * Azure source details settings components. Used for displaying the
 * registered Azure source's configuration values.
 */
@Component({
  selector: 'coh-azure-source-details-settings',
  templateUrl: './source-details-settings.component.html'
})
export class AzureSourceDetailsSettingsComponent {
  /**
   * Input MCM source.
   */
  @Input() mcmSourceInfo: McmSourceRegistration = {};
}
