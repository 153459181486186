import { Injectable } from '@angular/core';

import { CalendarConfig } from './calendar.config';

/**
 * The native date calendar config injectable service.
 */
@Injectable()
export class NativeDateCalendarConfig implements CalendarConfig<Date> {
  /**
   * Get the current user UTC offset value used to make adjustments in input/output calendar's date.
   *
   * @return  The current user UTC offset value.
   */
  getCurrentUtcOffset(): number {
    return new Date().getTimezoneOffset() * -1;
  }

  /**
   * Add the number of minutes to the provided date.
   *
   * @param   date      The date.
   * @param   nMinutes  The number of minutes to add.
   * @return  A new date by adding provided minutes.
   */
  addMinutes(date: Date, nMinutes: number): Date {
    return new Date(date.getTime() + nMinutes * 60 * 1000);
  }
}
