import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { CohesityHelixModule } from '@cohesity/helix';

import { ComponentOutletExtensionComponent } from './component-outlet-extension/component-outlet-extension.component';
import { TableColumnExtensionComponent } from './table-column-extension/table-column-extension.component';

@NgModule({
  declarations: [ComponentOutletExtensionComponent, TableColumnExtensionComponent],
  imports: [CommonModule, PortalModule, CohesityHelixModule],
  exports: [ComponentOutletExtensionComponent, TableColumnExtensionComponent],
})
export class PluginSharedModule {}
