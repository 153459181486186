import { Injectable } from '@angular/core';
import { View, ViewServiceApi } from '@cohesity/api/v2';
import { AsyncTableDataSource, DataFilterValue, DataSearchResult } from '@cohesity/helix';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';

/**
 * Data source implementation for the view search table.
 */
@Injectable({
  providedIn: 'root',
})
export class ViewSelectionDataSource extends AsyncTableDataSource<View, DataFilterValue<any, any>[]> {

  /**
   * Indicates whather it's loading data.
   */
  loading$ = new BehaviorSubject<boolean>(false);

  constructor(private viewService: ViewServiceApi) {
    super();
  }

  /**
   * Executes view search
   *
   * @param   filter    The currently applied filter.
   * @param   sort      Sort info.
   * @return  An observable with the data search results.
   */
  getData(filter: DataFilterValue<any, any>[]): Observable<DataSearchResult<View>> {
    if (!filter) {
      return of({ data: [] });
    }

    this.loading$.next(true);

    // generate filter params
    const viewParams: ViewServiceApi.GetViewsParams = {
      viewNames: this.generateFilterParam(filter, 'viewName') as string[],
      storageDomainIds: this.generateFilterParam(filter, 'storageDomain') as number[],
      tenantIds: this.generateFilterParam(filter, 'organization') as string[],
    };

    return this.viewService.GetViews(viewParams)
    .pipe(
      map(views => ({ data: views.views })),
      catchError(() => of({ data: [] })),
      finalize(() => this.loading$.next(false))
    );
  }

  /**
   * Private function to generate StatsService.GetViewBoxStatsParams.
   *
   * @param   selectedFilters   applied filters.
   * @param   filterType   Filter type. Possible values are 'storageDomain', 'organization' or 'viewName'.
   *
   * @return  viewbox id list, or organization name list.
   */
  private generateFilterParam(selectedFilters: DataFilterValue<any, any>[], filterType: string): (string | number)[] {
    const filters: (string | number)[] = [];
    const index = selectedFilters.findIndex(filter => filter.key === filterType);

    if (index > -1) {
      // For view name filter, selectedFilters[index].value is a single string,
      // Just push it into filters.
      if (filterType === 'viewName') {
        filters.push(selectedFilters[index].value);
      } else {
        // For storage domain and organization filter, selectedFilters[index].value is an array
        // Need to loop through the whole list and push each item into filters.
        selectedFilters[index].value.forEach((filterItem) => filters.push(filterItem.value));
      }
    }

    return filters;
  }
}
