<ng-container *ngIf="!pathInputActive && pathTokens$ | async as tokens">
  <button mat-icon-button
    cogDataId="reset-path"
    type="button"
    [matTooltip]="tokens.length ? ('resetPath' | translate) : null"
    (click)="browseToPath.emit(null)">
    <cog-icon [shape]="resetIcon"></cog-icon>
  </button>

  <!-- Showing this when there are more than 5 elements  -->
  <div *ngIf="tokens.length > 5" class="margin-right-sm flex-row">
    <cog-icon shape="navigate_next"></cog-icon>…
  </div>

  <ng-container *ngFor="let item of tokens; let i = index; let last = last">
    <!-- Only showing the recent three when there are more than 5 elements  -->
    <ng-container *ngIf="!!item && (tokens.length <= 5 || (i >= (tokens.length - 3)))">
      <cog-icon *ngIf="i > 0"
        shape="navigate_next"
        class="path-icon"></cog-icon>

      <!-- For all but the last path items, clicking on the button should navigate to that directory, but
      clicking on the drop down should show the menu of sibling directories -->
      <button mat-button
        *ngIf="!last"
        type="button"
        class="path-button"
        cogDataId="select-path-{{tokens[i]}}"
        (click)="browseToPath.emit(tokens.slice(0, i + 1).join('/'))">
        <span cogTruncate="25">{{item}}</span>
      </button>

      <!-- For the last item, the only option is to show the sibling directories -->
      <button mat-button
        *ngIf="last"
        class="path-button"
        cogDataId="select-path-{{tokens[i]}}"
        [matMenuTriggerFor]="folderMenu">
        <span cogTruncate="25">{{item}}</span>
      </button>
      <mat-menu #folderMenu="matMenu">
        <ng-template matMenuContent>
          <button *ngFor="let file of getMenuItems(i) | async"
            mat-menu-item
            cogDataId="select-file-menu-{{file.name}}"
            (click)="browseToPath.emit(file.fullPath)">{{file.name}}</button>
        </ng-template>
      </mat-menu>
    </ng-container>
  </ng-container>
</ng-container>

<button *ngIf="!pathInputActive && allowUserInput"
  mat-icon-button
  class="show-path-input"
  cogDataid="enter-path"
  (click)="showInput(input)">
  <cog-icon shape="edit!outline"></cog-icon>
</button>

<mat-card class="input-card mat-elevation-z6" [class.hidden]="!pathInputActive">
  <cog-icon [shape]="resetIcon" matPrefix></cog-icon>
  <input #input matInput
    class="input-field"
    cogDataId="path-input"
    [formControl]="pathInputControl"
    (keyup.enter)="browseToPathInput(pathInputControl.value)">
  <span class="hint-text"
    matSuffix
    [innerHTML]="(allowOnlyUserInput ? 'pressEnterToSelect' : 'pressEnterToBrowse') | translate">
  </span>
  <button mat-icon-button
    *ngIf="!allowOnlyUserInput"
    cogDataId="enter-path"
    (click)="showPathInput = false">
    <cog-icon shape="close" matSuffix></cog-icon>
  </button>
</mat-card>
