import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

import { DataTreeNodeContext } from '../data-tree-detail.directive';
import { DataTreeSelectionChangeEvent } from '../shared/data-tree-selection-change-event.model';
import { DataTreeSelectionModel } from '../shared/data-tree-selection-model';
import { DataTreeNode } from '../shared/data-tree.model';

/**
 * The auto select column can show to the right of a mat tree node and contains options to auto select
 * or exclude a node.
 * <cog-data-tree>
 *   <ng-container *cogDataTreeDetail="let-ctx">
 *     ...node details
 *     <cog-data-tree-auto-select-column
 *       [nodeContext]="ctx"
 *       autoSelectIcon="helix:autoprotect"
 *       autoSelectedLabel="Auto Selected"
 *       excludedLabel="Excluded">
 *    </cog-data-tree-auto-select-column>
 *  </ng-container>
 * </cog-data-tree>
 */
@Component({
  selector: 'cog-data-tree-auto-select-column',
  templateUrl: './data-tree-auto-select-column.component.html',
  styleUrls: ['./data-tree-auto-select-column.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataTreeAutoSelectColumnComponent<T extends DataTreeNode<any>> {
  /**
   * Returns true if the current node has been explicitly auto selected.
   */
  @HostBinding('class.auto-selected') get isAutoSelected(): boolean {
    return this.nodeContext && this.nodeContext.autoSelected;
  }

  /**
   * Icon shape for button to auto select a node. The icon must support the css color property (mat icon, or svg icon)
   * so that it can display an alternate color when a node is excluded.
   */
  @Input() autoSelectIcon;

  /**
   * The label to show when a node has been auto selected
   */
  @Input() autoSelectedLabel: string;

  /**
   * A callback to determine a node's auto select tooltip.
   */
  @Input() autoSelectTooltipFn: (nodeContext: DataTreeNodeContext<T>) => string;

  /**
   * A callback to determine a node's exclude tooltip.
   */
  @Input() excludeTooltipFn: (nodeContext: DataTreeNodeContext<T>) => string;

  /**
   * The label to show when a new has been excluded from an auto selected.
   */
  @Input() excludedLabel: string;

  /**
   * The current nodeDetailContext
   */
  @Input() nodeContext: DataTreeNodeContext<T>;

  /**
   * Optional function to handle user-initiated selection changes. Will emit the
   * current selection.
   */
  @Output() userSelectionChange = new EventEmitter<DataTreeSelectionChangeEvent<T>>();

  /**
   * Gets the tooltip, if any to show for the auto select button
   */
  get autoSelectTooltip(): string {
    return this.autoSelectTooltipFn ? this.autoSelectTooltipFn(this.nodeContext) : undefined;
  }

  /**
   * Gets the exclude tooltip, if any to show for the exclude button.
   */
  get excludeTooltip(): string {
    return this.excludeTooltipFn ? this.excludeTooltipFn(this.nodeContext) : undefined;
  }

  /**
   * Getter property to check if a node's ancestor is auto selected. This value should be cached and only updated
   * when the selection model changes.
   */
  get isAncestorAutoSelected(): boolean {
    return this.nodeContext.ancestorAutoSelected;
  }

  /**
   * Getter property to check if a node's ancestor is excluded. This value should be cached and only updated
   * when the selection model changes.
   */
  get isAncestorExcluded(): boolean {
    return this.nodeContext.ancestorExcluded;
  }

  /**
   * Gets the current node.
   */
  get node(): T {
    return this.nodeContext.node;
  }

  /**
   * Gets the current selection.
   */
  get selection(): DataTreeSelectionModel<T> {
    return this.nodeContext.selection;
  }

  /**
   * Whether the auto select label should be visible for the current node.
   */
  get showAutoSelectLabel(): boolean {
    return (
      ((this.nodeContext.autoSelected || this.isAncestorAutoSelected) &&
      !(this.nodeContext.excluded || this.isAncestorExcluded)) &&
      !this.node.isGloballyExcluded &&
      !this.node.ineligibleForAutoSelect
    );
  }

  /**
   * Whether the exclude label should be visible for the current node.
   */
  get showExcludeLabel(): boolean {
    return this.nodeContext.excluded ||
      this.isAncestorExcluded ||
      (this.node.isGloballyExcluded && this.isAncestorAutoSelected);
  }

  /**
   * Generates the object to emit in teh selection click handlers.
   */
  get emitSelection(): DataTreeSelectionChangeEvent<T> {
    return {
      type: null,
      node: this.node,
      selection: this.selection.currentSelection,
    };
  }

  /**
   * User-initiated auto-select click handler. Emits the current selection.
   */
  handleAutoSelectClick() {
    this.selection.toggleNodeAutoSelection(this.node);

    if (this.userSelectionChange.observers.length > 0) {
      this.userSelectionChange.emit({ ...this.emitSelection, type: 'autoSelect' });
    }
  }

  /**
   * User-initiated exclude click handler. Emits the current selection.
   */
  handleExclusionClick() {
    this.selection.toggleNodeExclude(this.node);

    if (this.userSelectionChange.observers.length > 0) {
      this.userSelectionChange.emit({ ...this.emitSelection, type: 'exclude' });
    }
  }
}
