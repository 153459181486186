<h2 mat-dialog-title translate>
  bandwidthUsageOptions
</h2>
<form cohValidateOnSubmit [formGroup]="formGroup" (validatedSubmit)="onSubmit()">
  <mat-dialog-content class="mat-typography">
    <ng-container *ngIf="bandwidthRules$ | async; else loading">
      <coh-timezone-selector
        [formControlName]="formControlNames.timezone"
        [label]="'timeZone' | translate"
        cogDataId="timezone-picker">
      </coh-timezone-selector>
      <div *ngFor="let controls of formGroupControls.rules.controls; index as i; first as isFirst; last as isLast"
        class="rule-row"
        [formArrayName]="formControlNames.rules"
        cogDataId="row-{{ i }}">
        <coh-bandwidth-usage-controls [formControl]="controls" [noLabels]="!isFirst"></coh-bandwidth-usage-controls>
        <button mat-icon-button
          *ngIf="!isFirst || formGroupControls.rules.controls.length !== 1"
          color="primary"
          (click)="removeRuleAt(i)"
          cogDataId="remove-rule-at-{{ i }}"
          type="button">
          <cog-icon shape="remove_circle_outline"></cog-icon>
        </button>
        <button mat-icon-button
          *ngIf="isLast"
          color="primary"
          (click)="addRuleAt(i + 1)"
          cogDataId="add-rule-at-{{ i }}"
          type="button">
          <cog-icon shape="add_circle_outline"></cog-icon>
        </button>
      </div>
      <a *ngIf="formGroupControls.rules.length === 0" class="link-btn" cogDataId="add-rule" (click)="addRule()">
        <cog-icon shape="add"></cog-icon>
        {{ 'addBandwidthUsageRule' | translate }}
      </a>
    </ng-container>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button cogCancelButton mat-stroked-button mat-dialog-close cogDataId="cancel-btn" [disabled]="busy$ | async">
      {{ 'cancel' | translate }}
    </button>
    <button cogSubmitButton mat-flat-button type="submit" color="primary" cdkFocusInitial
      [disabled]="formGroup.invalid || (busy$ | async)" [loading]="busy$ | async" cogDataId="save-btn">
      {{ 'save' | translate }}
    </button>
  </mat-dialog-actions>
</form>
<ng-template #loading>
  <cog-spinner></cog-spinner>
</ng-template>
