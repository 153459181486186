import { ProtectionSourceNode } from '@cohesity/api/v1';
import { ProtectdObjectsActionRequest } from '@cohesity/api/v2';
import { DataTreeNode, DataTreeSelection, NavItem } from '@cohesity/helix';
import { SourceSelection } from '@cohesity/iris-source-tree';
import { StateParams } from '@uirouter/core';
import { Observable } from 'rxjs';
import { ActionContextData } from 'src/app/modules/object-details-shared/object-actions-context-data.model';
import { RestorePointSelection } from 'src/app/modules/restore/restore-shared';
import { Environment, RouterTab } from 'src/app/shared';

import { ObjectProtectionGroupInfo } from '../global-search-shared';
import { ObjectActionOptions } from './object-action-options.model';

/**
 * This is a small data structure intended to identify an object with enough information to
 * be able to run the object menu action providers code. This should be easy to convert to
 * from v1 or v2 apis.
 */
export interface SimpleObjectInfo {
  /**
   * The object's environment.
   */
  environment: Environment;

  /**
   * Specifies the backup type for the given entity. This is only needed when
   * the same object can be protected by 2 different types of backups.
   */
  objectActionKey?: ProtectdObjectsActionRequest['objectActionKey'];

  /**
   * Optional list of children that should be excluded when autoprotecting this object.
   */
  excludedIds?: number[];

  /**
   * The object's id
   */
  id: number;

  /**
   * Whether the object is protected as part of an object protection or not.
   */
  isObjectProtected?: boolean;

  /**
   * Whether the object is protected or not.
   */
  isProtected: boolean;

  /**
   * The object's type. Optional in most cases, but needed from the source tree when
   * the object being protected is a tag.
   */
  objectType?: string;

  /**
   * Type of volume.
   */
  volumeType?: string;

  /**
   * The object's parent id
   */
  sourceId: number;

  /**
   * Optional v1 object info. This can be used to prevent additional lookups to the
   * object store.
   */
  v1Object?: ProtectionSourceNode;

  /**
   * The Restore point selection of the selected object.
   */
  restorePointSelection?: RestorePointSelection;

  /**
   * Cluster this object belongs to.
   */
  accessClusterId?: number;

  /**
   * Region this object belongs to.
   */
  regionId?: string;

  /**
   * Storage domain the protected object belongs to.
   */
  storageDomainId?: number;

  /**
   * Optional workload type.
   * Currently, this is only applicable for Office365.
   */
  workloadType?: string;

  /**
   * Whether the object is deleted.
   */
  isDeleted?: boolean;

  /**
   * The Protection Groups protecting this result.
   */
  protectionGroups?: ObjectProtectionGroupInfo[];

  /**
   * If this is set, then only restore point selection is used for the
   * actions of this simple object. If this is not present or set to false,
   * the v2 protected object of the simple object can be used to create the
   * actions.
   */
  useRestorePointSelection?: boolean;

  /**
   * The objects protection type for protected objects. (Optional)
   * Currently, used for AWS and Azure objects and can be extended to other object categories.
   */
  protectionType?: string;
}

/**
 * Determines if service is a menu provider instance implementing the MenuProviderWithContextData methods
 *
 * @param  service
 * @return true if service implements methods in MenuProviderWithContextData and ObjectMenuProvider interfaces
 */
export function isProviderWithContextData
  <T>(service: ObjectMenuProvider | (ObjectMenuProvider & MenuProviderWithContextData<T>)):
  service is ObjectMenuProvider & MenuProviderWithContextData<T>  {
  return 'contextData$' in service && 'setContextData' in service && 'getObjectActions' in service;
}

/**
 * Interface to be implemented on object menu providers exposing context data as public service attributes
 */
export interface MenuProviderWithContextData<T> {
  /**
   * A getter that returns the context data
   */
  get contextData$(): Observable<T>;

  /**
   * Optional service function to set additional context information to determine available object actions
   *
   * @param contextData The context data
   */
  setContextData(contextData: T): void;
}

export interface ObjectMenuProvider {
  /**
   * Specifies whether the exclusive protection is available in this provider.
   */
  exclusiveProtection?: boolean;

  /**
   * This should be set to true if the tree supports actions for more than
   * one object at a time. If it does support bulk actions, it should be
   * configured to work with a standard selection model. The job source tree
   * would not work with this method.
   */
  supportsBulkActions: boolean;

  /**
   * Gets a list of nav item options available for a single object.
   *
   * @param   object        The object to get actions for
   * @returns Any applicable actions
   */
  getObjectActions(object: SimpleObjectInfo): Observable<NavItem[]>;

  /**
   * Gets a list of nav item options available for one or more objects.
   *
   * @param   object   The object to get actions for
   * @returns Any applicable actions that can be run for all items
   */
  getBulkObjectActions(objects: SimpleObjectInfo[]): Observable<NavItem[]>;

  /**
   * Gets a list of nav item options available for one or more objects. This assumes that
   * the selection is done within the context of a source tree, and will have additional
   * information about autoprotected, selected, or excluded items.
   *
   * @param   objects          The object to get actions for
   * @param   sourceSelection  The transformed api tree selection including excluded and special
   *                           params
   * @param objectOptions      The object's action options
   * @returns Any applicable actions that can be run for all items
   */
  getBulkTreeObjectActions(
    objects: DataTreeSelection<DataTreeNode<ProtectionSourceNode>>,
    sourceSelection: SourceSelection,
    objectOptions?: ObjectActionOptions
  ): Observable<NavItem[]>;

  /**
   * Gets a list of tabs for different type of Storages.
   *
   * @param   stateParams   The router state paramaters
   * @param   newWorkload   The new workload
   * @returns Any applicable tab that is supported
   */
  getParentTabs?(stateParams: StateParams, newWorkload?: string): RouterTab[];

  /**
   * Optional service function to set additional context information to determine available object actions
   *
   * @param contextData The context data
   */
  setActionsContextData?<T>(contextData: ActionContextData<T>): void;

  /**
   * Determines if Csm based backup is supported.
   *
   * @returns  True if Csm based backup is supported.
   */
  isCsmBasedBackupEnabled?(env?: string): boolean;

  /**
   * Returns additional context information to determine available object actions
   *
   * @param environment The context environment
   */
  getActionsContextData?<T>(environment: Environment): Observable<T>;
}
