import { Injectable } from '@angular/core';
import {
  AcropolisProtectionGroupParams,
  AwsProtectionGroupParams,
  AzureProtectionGroupParams,
  CreateProtectedObjectsRequest,
  EbsVolumeExclusionParams,
  AzureDiskExclusionParams,
  GcpDiskExclusionParams,
  GcpProtectionGroupParams,
  HyperVObjectProtectionRequest,
  HyperVProtectionGroupParams,
  KvmProtectionGroupParams,
  ProtectedObjectBackupConfig,
  ProtectionGroup,
  VmwareProtectionGroupParams,
} from '@cohesity/api/v2';
import { DataTreeSelection } from '@cohesity/helix';
import { flagEnabled, IrisContextService, isClusterScope, isDmsScope } from '@cohesity/iris-core';
import { flatten } from 'lodash';
import { FeatureFlag } from 'src/app/core/services';
import {
  AcropolisDiskFilterType,
  AzureBackupType,
  AzureSQLEntityTypes,
  cloudGroups,
  CloudJobType,
  Environment,
  HypervDiskFilterType,
  JobEnvParamsV2,
} from 'src/app/shared/constants';
import { ProtectionSourceDataNode } from 'src/app/shared/source-tree/protection-source';

import {
  DefaultEndDate,
  DefaultIndexing,
  DefaultIndexingServers,
} from '../protection-builder/components/settings-list-items';
import { ProtectionGroupObject, ProtectionItemName, ProtectionSettingsListItem } from '../protection-builder/models';
import { ProtectionUtilsService } from '../protection-shared/services';
import {
  DefaultAppConsistentBackup,
  DefaultBackupType,
  DefaultCloudMigration,
  DefaultDiskExclusions,
  DefaultLeverageSanTransport,
  DefaultProtectionType,
  DefaultStorageSnapshot,
} from './components/settings-list-items';

/**
 * All the vm params for the API
 */
type VmParams =
  AcropolisProtectionGroupParams |
  HyperVProtectionGroupParams |
  KvmProtectionGroupParams |
  VmwareProtectionGroupParams;

/**
 * All the cloud params for the API
 */
type CloudParams =
  AwsProtectionGroupParams |
  AzureProtectionGroupParams |
  GcpProtectionGroupParams;

/**
 * Env-specific feature flags to check to determine whether
 * object protection should be enabled.
 */
const objectProtectionEnvFlagMap: Partial<Record<Environment, FeatureFlag>> = {
  [Environment.kAWS]: 'objectProtectionAWS',
  [Environment.kVMware]: 'objectProtectionVMware',
  [Environment.kHyperV]: 'objectProtectionHyperV',
};

/**
 * VM Protection Utilities.
 */
@Injectable({
  providedIn: 'root',
})
export class VmProtectionUtilsService {
  /**
   * Mapping for various cloud protection types with keys to look inside the protectionGroup Params
   */
  private cloudProtectionTypeMap = {
    kAgent: 'agentProtectionTypeParams',
    kNative: 'nativeProtectionTypeParams',
    kSnapshotManager: 'snapshotManagerProtectionTypeParams',
    kAzureSQL: 'azureSqlProtectionTypeParams',
  };

  constructor(private protectionUtilsService: ProtectionUtilsService,
    private irisCtx: IrisContextService,
    private irisContext: IrisContextService) {
  }

  /**
   * Function to return adapter specific params for protection group create.
   *
   * @param   formValue   Value of the form.
   * @param   workloadType selected azure workload type
   * @return   Params to be sent to the API for the adapter.
   */
  getParams(formValue: any, workloadType?: AzureBackupType) {
    if (!formValue[ProtectionItemName.Source]) {
      return {};
    }

    const environment = formValue[ProtectionItemName.Source].protectionSource.environment;
    let params;

    switch (environment) {
      case Environment.kAWS:
      case Environment.kAzure:
      case Environment.kGCP:
        params = this.cloudParams(formValue, workloadType);
        break;
      case Environment.kVMware:
        params = this.vmwareParams(formValue);
        break;
      case Environment.kAcropolis:
        params = this.acropolisParams(formValue);
        break;
      case Environment.kKVM:
        params = this.vmParams(formValue);
        break;
      case Environment.kHyperV:
        params = this.hyperParams(formValue);
    }

    return {
      [JobEnvParamsV2[environment]]: params,
    };
  }

  /**
   * Function to return adapter specific params for object protection create.
   *
   * @param   formValue   Value of the form.
   * @param   isObjectEdit  True if in edit object protection flow
   * @param   workloadType  selected azure workload type
   * @return   Params to be sent to the API for the adapter.
   */
  getObjectParams(formValue: any, isObjectEdit = false, workloadType?: AzureBackupType):
    Pick<CreateProtectedObjectsRequest, 'objects'> {
    const environment = formValue[ProtectionItemName.Source].protectionSource.environment;
    const envParams = JobEnvParamsV2[environment];
    const params = this.getParams(formValue, workloadType)[envParams];
    let objectParams;

    switch (true) {
      case cloudGroups.cloud.includes(environment):
        objectParams = this.getCloudObjectParams(params, environment, workloadType);
        break;
      default:
        objectParams = this.getVmObjectParams(params, environment, isObjectEdit);
    }

    return {
      objects: [
        {
          environment: environment,
          [envParams]: objectParams,
        },
      ],
    };
  }

  /**
   * Gets the cloud object params and transforms them to the objects params needed for created protected objects.
   *
   * @param   params      Object params.
   * @param   environment Source environment type.
   * @param   workloadType selected azure workload type.
   * @return  Object params for protected objects.
   */
  getCloudObjectParams(params: any, environment: Environment, workloadType?: AzureBackupType): any {
    const protectionType = this.cloudProtectionTypeMap[AzureSQLEntityTypes.includes(workloadType) ?
      AzureBackupType.kSQLDatabase : params.protectionType];
    params[protectionType] = this.getVmObjectParams(params[protectionType], environment);
    return params;
  }

  /**
   * Gets the object params and transforms them to the objects params needed for created protected objects.
   *
   * @param   params        Object params.
   * @param   environment Source environment type.
   * @param   isObjectEdit  True if in edit object protection flow.
   * @return  Object params for protected objects.
   */
  getVmObjectParams(params: any, environment: Environment, isObjectEdit = false): any {
    // Tag ids are handled differently for auto protection. Tags are passed directly in the
    // object list. However, we can't support combinations of them, so if the tag id entry
    // includes multiple tags, this will throw an error.
    if (params?.vmTagIds?.length) {
      params.vmTagIds.forEach(tagIds => {
        if (tagIds.length !== 1) {
          throw new Error('object protection only allows one vmtag at a time');
        }
        params.objects.push({
          id: tagIds[0]
        });
      });
      delete params.vmTagIds;
    }

    // For object protection, excluded object ids are set on each object
    if (params?.excludeObjectIds?.length) {
      params.objects.forEach(object => object.excludeObjectIds = [...params.excludeObjectIds]);
      delete params.excludeObjectIds;
    }

    // In object protection, tags are treated as objects. Set excludeVmTagIds as excludeObjectIds
    // on each object. Enabling this for AWS adapter only. Enable for other adapters if required.
    if ([Environment.kAWS, Environment.kAzure].includes(environment)) {
      // For object protection, excluded tag ids are set on each object.
      if (params?.excludeVmTagIds?.length) {
        params.objects.forEach(object =>
          object.excludeObjectIds = (object.excludeObjectIds || []).concat(flatten(params?.excludeVmTagIds)));
        delete params.excludeVmTagIds;
      }
    }

    // Temp fix to automatically exclude leaf node if it has object level setting applied
    // so that the leaf node will be protected manually rather than part of the auto protection spec
    // Only applicable for new creation flow.
    // TODO (Tung): remove once we properly support object level settings for auto protection.
    if (!isObjectEdit) {
      const leavesWithObjectLevelSettings =
      (params?.objects || []).filter(object => object.isAutoprotected).map(object => object.id);
      if (params?.objects?.length > 1 && leavesWithObjectLevelSettings.length) {
        params.objects.forEach(object => {
          // Find parent node with auto protection spec and add the leaf nodes with
          // object level settings to the exclude object id list.
          if (!leavesWithObjectLevelSettings.includes(object.id) && !object.isAutoprotected) {
            object.excludeObjectIds.push(...leavesWithObjectLevelSettings);
          }
        });
      }
    }

    // Support manual object protection edit with exclude disks at object level
    if (params?.objects?.length === 1 && params?.objects?.[0]?.excludeDisks) {
      params.excludeDisks = params.objects[0].excludeDisks;
    }

    return params;
  }

  /**
   * Function to return source selection for protection group edit.
   *
   * @param   group   API protection group.
   * @param   workloadType specifies workload type
   * @return   Object for selection to be used with Source Tree component.
   */
  getSelection(group: ProtectionGroup | ProtectedObjectBackupConfig | ProtectionGroupObject | ProtectionGroupObject,
    workloadType?: AzureBackupType) {
    switch (group.environment) {
      case Environment.kAWS:
      case Environment.kAzure:
      case Environment.kGCP:
        return this.cloudSelection(group, workloadType);
      case Environment.kVMware:
      case Environment.kHyperV:
        return this.vmwareHypervSelection(group);
      case Environment.kAcropolis:
        return this.acropolisSelection(group);
      case Environment.kKVM:
        return this.vmSelection(group);
    }
  }

  /**
   * Function to return the default values of all the vm settings which are
   * hidden in quick protect.
   *
   * @return partially filled protection group object with all the default
   *         values of the hidden vm settings.
   */
  getVmSettingsDefaults(): any {
    const dmsUser = isDmsScope(this.irisContext.irisContext);
    return {
      [ProtectionItemName.AdditionalSettings]: {
        [ProtectionSettingsListItem.EndDate]: DefaultEndDate,
        [ProtectionSettingsListItem.HypervProtectionType]: DefaultProtectionType,
        [ProtectionSettingsListItem.LeverageSanTransport]: DefaultLeverageSanTransport,
        [ProtectionSettingsListItem.LeverageStorageSnapshots]: DefaultStorageSnapshot,
        [ProtectionSettingsListItem.DiskExclusions]: DefaultDiskExclusions,
        [ProtectionSettingsListItem.AppConsistentBackups]: DefaultAppConsistentBackup,
        [ProtectionSettingsListItem.Indexing]: dmsUser ? DefaultIndexing : DefaultIndexingServers,
        [ProtectionSettingsListItem.CloudMigration]: DefaultCloudMigration,
        [ProtectionSettingsListItem.CloudBackupType]: DefaultBackupType,
      },
    };
  }

  /**
   * Given the form value, determine whether object protection should be enabled or not.
   * This only applies to cases where objects could be protected as individual objects or
   * as groups. In DMS, only object protection is enabled.
   *
   * @param   formValue  The current form value.
   * @returns Whether object protection is available.
   */
  shouldAllowObjectProtection(formValue: any): boolean {
    const objectValue = formValue[ProtectionItemName.Objects];
    if (!objectValue) {
      return false;
    }
    const selection: DataTreeSelection<ProtectionSourceDataNode> =
      objectValue.sourceTree.treeService.dataTreeSelection.currentSelection;
    const allObjects = [...selection.selected, ...selection.autoSelected];
    if (!allObjects.length) {
      return false;
    }
    const env = allObjects[0].environment;
    const envFlag = objectProtectionEnvFlagMap[env];

    return this.protectionUtilsService.shouldAllowObjectProtection(allObjects, envFlag);
  }

  /**
   * Function to return vmware or hyperv object related params for protection group API.
   *
   * @param   value   Object of form group values.
   * @return   Objects related values for the "vmwareParams" or "hypervParams" key in a protection
   *           group.
   */
  private vmwareHypervObjectsParams(value: ProtectionGroupObject): VmwareProtectionGroupParams {
    const objectsValue = value[ProtectionItemName.Objects];

    if (!objectsValue?.selection) {
      return {};
    }

    const objects = objectsValue.selection;
    const {missingObjectIds} = objectsValue.sourceTree;
    const vmSpecialParametersMap = {};
    const sourceIds = new Set([...objects.sourceIds]);

    // TODO: simplify sourceSpecialParameters with v2 sources API.
    for (const source of objects.sourceSpecialParameters) {
      // Make sure that source special params are included in the objects list even if they
      // are there as part of an auto protected object.

      if (!missingObjectIds || !missingObjectIds.has(source.sourceId)) {
        sourceIds.add(source.sourceId);
        vmSpecialParametersMap[source.sourceId] = source.vmSpecialParameters;
      }
    }

    return {
      objects: Array.from(sourceIds).map(sourceId => ({
        id: sourceId,
        isAutoprotected: !objects.sourceIds.includes(sourceId),
        excludeDisks:
          (vmSpecialParametersMap[sourceId] || {}).excludeDisks,
        truncateExchangeLogs:
          (vmSpecialParametersMap[sourceId] || {}).truncateExchangeLogs,
      })),
      excludeObjectIds: objects.excludeSourceIds,
      vmTagIds: objects.vmTagIds,
      excludeVmTagIds: objects.excludeVmTagIds,
    };
  }

  /**
   * Function to return vmware params for protection group API.
   *
   * @param   value   Object of form group values.
   * @return   Value for the "vmwareParams" key in a protection group.
   */
  private vmwareParams(value: ProtectionGroupObject): VmwareProtectionGroupParams {
    const settings = value[ProtectionItemName.AdditionalSettings];

    if (!settings) {
      // When patching/updating an existing protection group, settings does not
      // exist.
      return this.vmwareHypervObjectsParams(value);
    }
    const {
      AppConsistentBackups,
      AllowParallelRuns,
      DiskExclusions,
      Indexing,
      CloudMigration,
      PrePostScripts,
      LeverageSanTransport,
      LeverageStorageSnapshots,
      CdpSyncReplication,
    } = ProtectionSettingsListItem;

    const {
      snapshotsCarrier,
      enabled: leverageStorageSnapshotsEnabled,
    } = settings[LeverageStorageSnapshots] || {};

    const dataNetworkTransport =
      (flagEnabled(this.irisCtx.irisContext, 'leverageSanTransport') && !isDmsScope(this.irisContext.irisContext))
        ? { leverageSanTransport: settings[LeverageSanTransport].sanTransportEnabled,
          enableNBDSSLFallback: settings[LeverageSanTransport].allowNbdsslTransportFallback }
        : null;

    // VMWare parallel runs option
    const allowParallelRuns =
      (flagEnabled(this.irisContext.irisContext, 'vmwareParallelRuns') && !isDmsScope(this.irisContext.irisContext))
        ? { allowParallelRuns: settings[AllowParallelRuns] }
        : null;

    return {
      ...this.vmwareHypervObjectsParams(value),
      appConsistentSnapshot: settings[AppConsistentBackups]?.appConsistentBackups,
      fallbackToCrashConsistentSnapshot: settings[AppConsistentBackups]?.crashConsistentBackup,
      skipPhysicalRDMDisks: settings[DiskExclusions].excludePhysicalRdm,
      globalExcludeDisks: settings[DiskExclusions].excludeDisks.disks,
      ...dataNetworkTransport,
      leverageHyperflexSnapshots:
        leverageStorageSnapshotsEnabled && snapshotsCarrier === 'hyperFlex',
      leverageNutanixSnapshots:
        leverageStorageSnapshotsEnabled && snapshotsCarrier === 'nutanix',
      leverageStorageSnapshots:
        leverageStorageSnapshotsEnabled && snapshotsCarrier === 'storageArray',
      cloudMigration: settings[CloudMigration],
      indexingPolicy: this.protectionUtilsService.transformIndexingPolicy(
        settings[Indexing]
      ),
      prePostScript: this.protectionUtilsService.getPrePostScriptsRequestModel(
        settings[PrePostScripts],
      ),
      enableCdpSyncReplication: settings[CdpSyncReplication],
      ...allowParallelRuns,
    };
  }

  /**
   * Function to return hyperv object related params for protection group API.
   *
   * @param   value   Object of form group values.
   * @return   Objects related values for the "hypervParams" key in a protection
   *           group.
   */
  private hypervObjectsParams(
    value: ProtectionGroupObject): HyperVProtectionGroupParams | HyperVObjectProtectionRequest {
    const objectsValue = value[ProtectionItemName.Objects];

    if (!objectsValue?.selection) {
      return {};
    }

    const objects = objectsValue.selection;
    const { missingObjectIds } = objectsValue.sourceTree;
    const vmSpecialParametersMap = {};
    const sourceIds = new Set([...objects.sourceIds]);

    // TODO: simplify sourceSpecialParameters with v2 sources API.
    for (const source of objects.sourceSpecialParameters) {
      // Make sure that source special params are included in the objects list even if they
      // are there as part of an auto protected object.

      if (!missingObjectIds || !missingObjectIds.has(source.sourceId)) {
        sourceIds.add(source.sourceId);
        vmSpecialParametersMap[source.sourceId] = source.vmSpecialParameters;
      }
    }

    return {
      objects: Array.from(sourceIds).map(sourceId => ({
        id: sourceId,
        isAutoprotected: !objects.sourceIds.includes(sourceId),
        excludeDisks:
          (vmSpecialParametersMap[sourceId] || {}).excludeDisks,
        includeDisks:
          (vmSpecialParametersMap[sourceId] || {}).includeDisks,
        truncateExchangeLogs:
          (vmSpecialParametersMap[sourceId] || {}).truncateExchangeLogs,
      })),
      excludeObjectIds: objects.excludeSourceIds,
      vmTagIds: objects.vmTagIds,
      excludeVmTagIds: objects.excludeVmTagIds,
    };
  }

  /**
   * Function to return hyperv params for protection group API.
   *
   * @param   value   Object of form group values.
   * @return   Value for the "hypervParams" key in a protection group.
   */
  private hyperParams(value: ProtectionGroupObject): HyperVProtectionGroupParams | HyperVObjectProtectionRequest {
    const settings = value[ProtectionItemName.AdditionalSettings];

    if (!settings) {
      // When patching/updating an existing protection group, settings does not
      // exist.
      return this.hypervObjectsParams(value);
    }

    const {
      AppConsistentBackups,
      CloudMigration,
      Indexing,
      HypervDiskFilter,
      HypervProtectionType,
    } = ProtectionSettingsListItem;

    // Acropolis global disk
    let globalExcludeDisks = null;
    let globalIncludeDisks = null;

    if (settings[HypervDiskFilter]?.enableDiskFilter &&
      settings[HypervDiskFilter]?.disks?.length) {
        const { type, disks } = settings[HypervDiskFilter];
      if (type === HypervDiskFilterType.Include) {
        globalIncludeDisks = disks;
      } else if (type === HypervDiskFilterType.Exclude) {
        globalExcludeDisks = disks;
      }
    }

    return {
      ...this.hypervObjectsParams(value),
      protectionType: settings[HypervProtectionType],
      appConsistentSnapshot: settings[AppConsistentBackups].appConsistentBackups,
      cloudMigration: settings[CloudMigration],
      fallbackToCrashConsistentSnapshot: settings[AppConsistentBackups].crashConsistentBackup,
      indexingPolicy: this.protectionUtilsService.transformIndexingPolicy(
        settings[Indexing]
      ),
      ...globalExcludeDisks ? { globalExcludeDisks } : undefined,
      ...globalIncludeDisks ? { globalIncludeDisks } : undefined,
      // This is applicable only for object protection
      backupsCopyOnly: settings[AppConsistentBackups].vssBackupType,
    };
  }

  /**
   * Function to return selection model for a vmware or hyperv protection group.
   *
   * @param   group   Vmware or hyperv protection group returned from the API.
   * @return   Object of the value from which protection group source tree
   *           can select items.
   */
  private vmwareHypervSelection(group: ProtectionGroup | ProtectedObjectBackupConfig | ProtectionGroupObject): object {
    const adapterParams = group[JobEnvParamsV2[group.environment]];
    const sourceSpecialParameters = [];
    const objects = adapterParams?.objects || [];

    objects.forEach(object => {
      if (object.excludeDisks || object.truncateExchangeLogs || object.includeDisks) {
        sourceSpecialParameters.push({
          sourceId: object.id,
          vmSpecialParameters: {
            excludeDisks: object.excludeDisks,
            includeDisks: object.includeDisks,
            truncateExchangeLogs: object.truncateExchangeLogs,
          },
        });
      }
    });

    // Filter out objects that are auto protected by ancestors.
    const sourceIds = objects
      .filter(object => !object.isAutoprotected)
      .map(object => object.id);

    return {
      sourceIds,
      excludeSourceIds: adapterParams.excludeObjectIds,
      sourceSpecialParameters,
      vmTagIds: this.removeDuplicateTagLists(adapterParams.vmTagIds),
      excludeVmTagIds: adapterParams.excludeVmTagIds,
    };
  }

  /**
   * Removes duplicate tag List. Duplicates in vmtagIds is not allowed
   */
  removeDuplicateTagLists(vmTagIds: number[][]) {
    const uniqueTagList = [];
    const map = new Map();
    if (vmTagIds) {
      vmTagIds.forEach(item => {
        if (!map.has(JSON.stringify(item))) {
          uniqueTagList.push(item);
          map.set(JSON.stringify(item), true);
        }
      });
    }
    return uniqueTagList;
  }

  /**
   * Function to return vm objects related params for protection group API.
   *
   * @param   value   Object of form group values.
   * @return   Objects related values for the "_adapter_Params" key in a
   *           protection group.
   */
  private vmObjectsParams(value: ProtectionGroupObject): VmParams {
    const objects = value[ProtectionItemName.Objects].selection;

    return {
      objects: objects.sourceIds.map(sourceId => ({
        id: sourceId,
      })),
      excludeObjectIds: objects.excludeSourceIds,
    };
  }

  /**
   * Function to return vm params for protection group API.
   *
   * @param   value   Object of form group values.
   * @return   Value for the "_adapter_Params" key in a protection group.
   */
  private vmParams(value: ProtectionGroupObject): VmParams {
    const settings = value[ProtectionItemName.AdditionalSettings];

    if (!settings) {
      return this.vmObjectsParams(value);
    }

    const {
      AppConsistentBackups,
      Indexing,
    } = ProtectionSettingsListItem;

    return {
      ...this.vmObjectsParams(value),
      indexingPolicy: this.protectionUtilsService.transformIndexingPolicy(
        settings[Indexing]
      ),
      appConsistentSnapshot: settings[AppConsistentBackups]?.appConsistentBackups,
      continueOnQuiesceFailure : settings[AppConsistentBackups]?.crashConsistentBackup,
    };
  }

  /**
   * Function to return acropolis objects related params for protection group API.
   *
   * @param   value   Object of form group values.
   * @return   Objects related values for the "_adapter_Params" key in a
   *           protection group.
   */
  private acropolisObjectsParams(value: ProtectionGroupObject): VmParams {
    const objects = value[ProtectionItemName.Objects].selection;
    const vmSpecialParametersMap = {};

    for (const source of objects.sourceSpecialParameters || []) {
      vmSpecialParametersMap[source.sourceId] = source.vmSpecialParameters;
    }

    const selectedObjects = objects.sourceIds.map(sourceId => {
      const excludeDisks = (vmSpecialParametersMap[sourceId] || {}).excludeDisks;
      const includeDisks = (vmSpecialParametersMap[sourceId] || {}).includeDisks;
      return {
        id: sourceId,
        ...excludeDisks ? { excludeDisks } : undefined,
        ...includeDisks ? { includeDisks } : undefined,
      };
    });

    // If objects are auto selected, vmspecial parameters are added in above
    // step. Hence add vmspecial paramaters if source id is not found in
    // auto selected ids.
    objects.sourceSpecialParameters.forEach(source => {
      if (!objects.sourceIds.includes(source.sourceId)) {
        const excludeDisks = (vmSpecialParametersMap[source.sourceId] || {}).excludeDisks;
        const includeDisks = (vmSpecialParametersMap[source.sourceId] || {}).includeDisks;
        selectedObjects.unshift({
          id: source.sourceId,
          ...excludeDisks ? { excludeDisks } : undefined,
          ...includeDisks ? { includeDisks } : undefined,
        });
      }
    });

    return {
      objects: selectedObjects,
      excludeObjectIds: objects.excludeSourceIds,
    };
  }

  /**
   * Function to return Acropolis vm params for protection group API.
   *
   * @param   value   Object of form group values.
   * @return   Value for the "_adapter_Params" key in a protection group.
   */
  private acropolisParams(value: ProtectionGroupObject): VmParams {
    const settings = value[ProtectionItemName.AdditionalSettings];

    if (!settings) {
      return this.acropolisObjectsParams(value);
    }

    const {
      AcropolisDiskFilter,
      AppConsistentBackups,
      Indexing,
    } = ProtectionSettingsListItem;

    // Acropolis global disk
    let globalExcludeDisks = null;
    let globalIncludeDisks = null;

    if (settings[AcropolisDiskFilter]?.enableDiskFilter &&
      settings[AcropolisDiskFilter]?.disks?.length) {
        const { type, disks } = settings[AcropolisDiskFilter];
      if (type === AcropolisDiskFilterType.Include) {
        globalIncludeDisks = disks;
      } else if (type === AcropolisDiskFilterType.Exclude) {
        globalExcludeDisks = disks;
      }
    }

    return {
      ...this.acropolisObjectsParams(value),
      indexingPolicy: this.protectionUtilsService.transformIndexingPolicy(
        settings[Indexing]
      ),
      appConsistentSnapshot: settings[AppConsistentBackups]?.appConsistentBackups,
      continueOnQuiesceFailure : settings[AppConsistentBackups]?.crashConsistentBackup,
      ...globalExcludeDisks ? { globalExcludeDisks } : undefined,
      ...globalIncludeDisks ? { globalIncludeDisks } : undefined,
    };
  }

  /**
   * Function to return selection model for an Acropolis protection group.
   *
   * @param   group   Acropolis protection group returned from the API.
   * @return   Object of the value from which protection group source tree can select items.
   */
  private acropolisSelection(group: ProtectionGroup | ProtectedObjectBackupConfig | ProtectionGroupObject): object {
    const adapterParams = group[JobEnvParamsV2[group.environment]];

    // If AcropolisDiskFilter feature is enabled, check for included/excluded disks
    const isAcropolisDiskFilterEnabled =
      flagEnabled(this.irisContext.irisContext, 'acropolisDiskFilter')
      && isClusterScope(this.irisContext.irisContext);

    const sourceSpecialParameters = [];

    if (isAcropolisDiskFilterEnabled) {
      const objects = adapterParams?.objects || [];

      objects.forEach(object => {
        if (object.excludeDisks || object.includeDisks) {
          sourceSpecialParameters.push({
            sourceId: object.id,
            vmSpecialParameters: {
              ...object.excludeDisks ? { excludeDisks: object.excludeDisks } : undefined,
              ...object.includeDisks ? { includeDisks: object.includeDisks } : undefined,
            },
          });
        }
      });
    }

    return {
      sourceIds: (adapterParams.objects || []).map(object => object.id),
      sourceSpecialParameters,
      excludeSourceIds: adapterParams.excludeObjectIds,
      vmTagIds: adapterParams.vmTagIds,
      excludeVmTagIds: adapterParams.excludeVmTagIds,
    };
  }

  /**
   * Function to return selection model for a vm protection group.
   *
   * @param   group   Vm protection group returned from the API.
   * @return   Object of the value from which protection group source tree can select items.
   */
  private vmSelection(group: ProtectionGroup | ProtectedObjectBackupConfig | ProtectionGroupObject): object {
    const adapterParams = group[JobEnvParamsV2[group.environment]];

    return {
      sourceIds: (adapterParams.objects || []).map(object => object.id),
      excludeSourceIds: adapterParams.excludeObjectIds,
      vmTagIds: adapterParams.vmTagIds,
      excludeVmTagIds: adapterParams.excludeVmTagIds,
    };
  }

  /**
   * Function to return the cloud properties.
   *
   * @param   value   Object of form group values.
   * @param   workloadType  selected azure workload type
   * @return   Value for the "<cloud>Params" key in a protection group.
   */
  private cloudParams(value: ProtectionGroupObject, workloadType?: AzureBackupType): CloudParams {
    let protectionType =
      value[ProtectionItemName.CloudJobType] ||
      (value['protectionGroup']?.name || value['protectionGroup']?.existingId
        ? CloudJobType.kNative
        : CloudJobType.kSnapshotManager);

    // Enabled it do select knative as default for kAzure DMAAS.
    if (isDmsScope(this.irisContext.irisContext) && flagEnabled(this.irisContext.irisContext, 'dmsAzureProtection')) {
      protectionType = CloudJobType.kNative;
    }
    const selection = value[ProtectionItemName.Objects]?.selection;
    const additionalSettings = value[ProtectionItemName.AdditionalSettings] || {};
    const backupType = additionalSettings.cloudBackupType;
    const ec2SpecialParametersMap = {};
    const gcpSpecialParametersMap = {};
    const azureSpecialParametersMap = {};

    if (AzureSQLEntityTypes.includes(workloadType)) {
      protectionType = 'kAzureSQL';

      return {
        protectionType,
        [this.cloudProtectionTypeMap[protectionType]]: {
          objects: selection?.sourceIds.map(sourceId => ({
            id: sourceId,
          })),
          diskType: 'kPremiumSSDv2'
        }
      };
    }

    selection?.sourceSpecialParameters.map(source => {
      ec2SpecialParametersMap[source.sourceId] = source.ec2SpecialParameters;
      gcpSpecialParametersMap[source.sourceId] = source.gcpSpecialParameters;
      azureSpecialParametersMap[source.sourceId] = source.azureSpecialParameters;
    });

    const protectionParams = {
      ...backupType,
      objects: selection?.sourceIds.map(sourceId => ({
        id: sourceId,
        volumeExclusionParams: (sourceId in ec2SpecialParametersMap) && !!ec2SpecialParametersMap[sourceId] ?
          { volumeIds: ec2SpecialParametersMap[sourceId]?.excludeEbsVolumes, } : undefined,
        diskExclusionNameParams: gcpSpecialParametersMap[sourceId]?.diskExclusionNameParams,
        diskExclusionParams: !!azureSpecialParametersMap[sourceId] && azureSpecialParametersMap[sourceId]?.
          excludeAzureDisks ? { diskIds: azureSpecialParametersMap[sourceId]?.excludeAzureDisks } : undefined,
      })),
      volumeExclusionParams: this.transformGlobalEbsVolumeExclusions(additionalSettings.globalEbsVolumeExclusions),
      gcpDiskExclusionParams: this.transformGcpDiskExclusion(additionalSettings.gcpDiskExclusions),
      diskExclusionParams: this.transformAzureVolumeExclusions(additionalSettings.azureDiskExclusions),
      excludeObjectIds: selection?.excludeSourceIds,
      vmTagIds: selection?.vmTagIds,
      excludeVmTagIds: selection?.excludeVmTagIds,
      indexingPolicy: this.protectionUtilsService.transformIndexingPolicy(additionalSettings.indexing),
      cloudMigration: additionalSettings.cloudMigration,
      cloudPrePostScript: additionalSettings.prePostScripts,
      dataTransferInfo: {
        isPrivateNetwork: additionalSettings.sasUrl?.endPointType === 'private',
        privateNetworkInfoList: additionalSettings.sasUrl?.endPointType === 'private' ?
          additionalSettings.sasUrl.privateEndpointFields.map(endpoint =>
            ({
              location: endpoint.region,
              subnet: {
                id: endpoint.subnet
              },
              vpn: {
                id: endpoint.virtualNetwork
              }
            }))
          : null }
    };

    return flagEnabled(this.irisCtx.irisContext, 'showCsmIngestProtectionPolicy') &&
      isDmsScope(this.irisContext.irisContext) &&
      (value[ProtectionItemName.Source].protectionSource.environment === Environment.kAWS) ? {
        protectionType: protectionType,
        [this.cloudProtectionTypeMap['kSnapshotManager']]: protectionParams,
        [this.cloudProtectionTypeMap['kNative']]: protectionParams,
      } : {
        protectionType: protectionType,
        [this.cloudProtectionTypeMap[protectionType]]: protectionParams,
      };
  }

  /**
   * Transforms globalEbsVolumeExclusions from Protection Group form value to API
   * request(EbsVolumeExclusionParams) for AWS Ec2 Volumes.
   *
   * @param   globalEbsVolumeExclusionParams   Form Global EBS Volume Exclusion parameters.
   */
  transformGlobalEbsVolumeExclusions(globalEbsVolumeExclusionParams: any): EbsVolumeExclusionParams {
    if (!globalEbsVolumeExclusionParams) {
      return;
    }
    return {
      rawQuery: globalEbsVolumeExclusionParams.rawQuery as string,
      tagParamsArray: globalEbsVolumeExclusionParams.tagParamsArray
    };
  }

  /**
   * Transforms azureDiskExclusions from Protection Group form value to API
   * request(AzureDiskExclusionParams) for Azure VM Volumes.
   *
   * @param   azureExclusionsParams   Form Azure Volume Exclusion parameters.
   */
    transformAzureVolumeExclusions(azureExclusionsParams: any): AzureDiskExclusionParams {
      if (!azureExclusionsParams) {
        return;
      }
      return {
        rawQuery: azureExclusionsParams.rawQuery as string,
        tagParamsArray: azureExclusionsParams.tagParamsArray
      };
    }

  /**
   * Transforms gcpDiskExclusions from Protection Group form value to API
   * request(GcpDiskExclusionParams) for GCP disks.
   *
   * @param   gcpDiskExclusions   Form GCP Disk Exclusion parameters.
   */
  transformGcpDiskExclusion(gcpDiskExclusions: any): GcpDiskExclusionParams {
    if (!gcpDiskExclusions) {
      return;
    }
    return {
      rawQuery: gcpDiskExclusions.enableExcludeDisk ? gcpDiskExclusions.rawQuery : null,
      excludeVmWithNoDisk: gcpDiskExclusions.enableExcludeVmWithNoDisk
    };
  }


  /**
   * Use cloudProtectionTypeMap to get the cloudParams for UI from nested API object
   *
   * @param   apiParams   The params returned by the API
   * @param   workloadType  selected azure workload type
   * @return  cloudParams Object
   */
  getCloudParams(params: CloudParams, workloadType?: AzureBackupType): {[key: string]: any} {
    const protectionType = AzureSQLEntityTypes.includes(workloadType) ? 'kAzureSQL' : params.protectionType;
    return flagEnabled(this.irisCtx.irisContext, 'showCsmIngestProtectionPolicy') &&
      isDmsScope(this.irisContext.irisContext) ? {
        protectionType,
        ...params[this.cloudProtectionTypeMap[protectionType]],
        ...params[this.cloudProtectionTypeMap['kNative']],
        ...params[this.cloudProtectionTypeMap['kSnapshotManager']],
      } : {
        protectionType,
        ...params[this.cloudProtectionTypeMap[protectionType]]
      };
  }

  /**
   * Function to return selection model for cloud protection group.
   *
   * @param   group   Cloud protection group returned from the API.
   * @param   workloadType specifies selected workload type.
   * @return   Object of the value from which protection group source tree can select items.
   */
  private cloudSelection(group: ProtectionGroup | ProtectedObjectBackupConfig | ProtectionGroupObject,
    workloadType?: AzureBackupType): object {
    const cloudParams = this.getCloudParams(group[JobEnvParamsV2[group.environment]], workloadType);
    let sourceSpecialParameters = [];

    if (group.environment === Environment.kAWS) {
      sourceSpecialParameters = cloudParams?.objects?.map(object => ({
        sourceId: object.id,
        ec2SpecialParameters: {
          excludeEbsVolumes: object?.volumeExclusionParams?.volumeIds,
        },
      })).filter(options => options.ec2SpecialParameters.excludeEbsVolumes?.length);

      // Set exclusions.
      cloudParams?.objects?.forEach(object =>
        cloudParams.excludeObjectIds = (cloudParams.excludeObjectIds || []).concat(flatten(object?.excludeObjectIds)));
    }

    // Add GCP Disk exclusion params, if any added.
    if (group.environment === Environment.kGCP) {
      sourceSpecialParameters = cloudParams.objects
        .filter(({ diskExclusionNameParams }) => diskExclusionNameParams?.length)
        .map(object => ({
          sourceId: object?.id,
          gcpSpecialParameters: {
            diskExclusionNameParams: object?.diskExclusionNameParams,
          },
        }));
    }

    // Add Azure Disk exclusion params, if any added.
    if (group.environment === Environment.kAzure) {
      sourceSpecialParameters = cloudParams?.objects
        ?.filter(({ diskExclusionParams }) => diskExclusionParams?.diskIds?.length)
        .map(object => ({
          sourceId: object?.id,
          azureSpecialParameters: {
            excludeAzureDisks: object?.diskExclusionParams?.diskIds,
          },
        }));

      // Set exclusions.
      cloudParams.excludeObjectIds = cloudParams?.objects?.flatMap(object => object.excludeObjectIds);
    }

    return {
      sourceIds: cloudParams?.objects?.map(object => object.id),
      excludeSourceIds: cloudParams.excludeObjectIds,
      sourceSpecialParameters: sourceSpecialParameters,
      vmTagIds: cloudParams.vmTagIds,
      excludeVmTagIds: cloudParams.excludeVmTagIds,
    };
  }
}
