import { DataRenderer, TextDataRendererComponent } from '@cohesity/helix';

/**
 * Arrays from the database are returned in the format:
 * {item, item}
 * This renderer strips the curly braces from the result and uses the text
 * renderer to display the values.
 */
export const textArrayRenderer = {
  transform: (renderValues: DataRenderer<string, undefined, undefined>) => ({
    ...renderValues,
    value: renderValues.value.replace('{', '').replace('}', '')
  }),
  component: TextDataRendererComponent,
};
