import { Injectable } from '@angular/core';
import { combineLatest } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { ClusterService, FeatureFlagsService, LocaleService } from 'src/app/core/services';

import { GuardResult, StateGuard } from '../state-guard';
import { GuardPriority } from './../state-guard';
import { ConfigurationService } from '@cohesity/shared/core';

/**
 * Temporary check based on browser local storage for testing IBM baas cluster
 * This check will be removed once backend support is in place.
 */
const isIbmBaasEnabled = () => {
  const localFlags = localStorage.getItem('C.featureFlags');
  return localFlags ? JSON.parse(localFlags).kIBMBaaSEnabled : false;
};

/**
 * The bootstrap guard returns an observable that will resolve after locale settings
 * and basic cluster info have been loaded.
 *
 * When this completes, the BootstrapService.basicClusterInfo is guaranteed to
 * be set.
 */
@Injectable({
  providedIn: 'root',
})
export class BootstrapGuard implements StateGuard {

  /**
   * This guard should be run at the highest priority, before any other guards
   * are run.
   */
  guardPriority = GuardPriority.Bootstrap;

  constructor(
    private clusterService: ClusterService,
    private configurationService: ConfigurationService,
    private featureFlags: FeatureFlagsService,
    private localeService: LocaleService,
  ) { }

  /**
   * This is run on the onStart ui router transition.
   */
  onStart(): GuardResult {
    // Ensure basic cluster info has been loaded
    this.clusterService.getBasicClusterInfo();

    // Make sure that there are valid values for basic cluster info, active locale,
    // and the text messages.
    return combineLatest([
      this.clusterService.basicClusterInfo$,
      this.clusterService.basicClusterInfo$.pipe(
        filter(basicClusterInfo => !!basicClusterInfo),
        take(1),
        tap(basicClusterInfo => {
          if (isIbmBaasEnabled() || basicClusterInfo['clusterDeploymentType'] === 'kIBMBaaS') {
            this.configurationService.addSoftwareTypePath('kIBMBaaS');
          } else if (basicClusterInfo.softwareType && basicClusterInfo.softwareType !== 'kRegular') {
            // Do not add software type for null cases and kRegularcases
            this.configurationService.addSoftwareTypePath(basicClusterInfo.softwareType);
          }
        }),
        switchMap(() => this.configurationService.fetchConfiguration())
      ),
      this.localeService.activeLocale$,
      this.featureFlags.featureFlags$,
    ]).pipe(
      // Return true when basic cluster info, and the locale have both been loaded.
      filter(vals => vals.reduce((prev, v) => prev && !!v, true)),
      map(() => true)
    );
  }
}
