import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { DayVal } from '../../constants';

/**
 * Converts a day value to a display string
 * Input can be either a string (Sunday', etc...) or the number of the day.
 *
 */
@Pipe({
  name: 'dayVal',
  pure: true,
})
export class DayValPipe implements PipeTransform {
  private dayKeys = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

  private tinyDayKeys = [
    'enum.dayShort.Sunday',
    'enum.dayShort.Monday',
    'enum.dayShort.Tuesday',
    'enum.dayShort.Wednesday',
    'enum.dayShort.Thursday',
    'enum.dayShort.Friday',
    'enum.dayShort.Saturday',
  ];

  /**
   * creates the pipe
   *
   * @param translate   The translate service
   */
  constructor(private translate: TranslateService) {}

  /**
   * converts from a value to a display label
   *
   * @param  day    The day, either the index of the day, or a string Value
   * @param  tiny   If true, use the abbreviated string value
   * @return The label for the day value
   */
  transform(day: any, tiny = false): string {
    const key = isNaN(parseInt(day, 10)) ? DayVal[day] : day;
    const keys = tiny ? this.tinyDayKeys : this.dayKeys;
    return this.translate.instant(keys[key]);
  }
}
