// Controller: Helios BuiltIn Reports Details

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.heliosReports')
    .component('heliosBuiltInReports', {
      bindings: {
        /**
         * @type   {String}   reportType   The rendered report type.
         */
        reportType: '@',
      },
      controller: 'HeliosBuiltInReportsCtrl',
      templateUrl: 'app/reports/helios/builtin-reports.html',
    })
    .controller('HeliosBuiltInReportsCtrl', HeliosBuiltInReportsCtrlFn);

  /**
   * Controller for Helios built-in reports.
   */
  function HeliosBuiltInReportsCtrlFn(_, $state, $translate, $timeout,
    API, DateTimeService, ReportsUtil, HeliosReportsService,
    HeliosReportsServiceFormatter) {
    const $ctrl = this;
    let loadTimer = null;

    _.assign($ctrl, {
      // Life-Cycle Hooks.
      $onInit: $onInit,

      // Methods exposed to the template.
      downloadReport: downloadReport,
      scheduleReport: scheduleReport,
      selectReport: selectReport,
      onReportLoad: onReportLoad,
      updateReportPostData: updateReportPostData,

      // Variables exposed to the template.
      reports: [],
      filter: {
        dateRange: {
          start: DateTimeService.getWeekAgo(),
          end: new Date(Date.clusterNow()),
        },
        reportTimeSpan: 7,
        reportTimeSpanType:
          HeliosReportsServiceFormatter.getTimespanType()[0],
        clusters: null,
        sources: null,
        isAllSourcesSelected: false,
        runStatus: null,
        isAllRunStatusSelected: false,
        objectTypes: null,
        isAllObjectTypesSelected: false,
        taskTypes: null,
        isAllTaskTypesSelected: false,
        lastNDays: 7,
        fetchType: {
          label: $translate.instant('allFailures'),
          value: 'kAll'
        },
        taskType: {
          label: $translate.instant('backup'),
          stringValue: 'Backup'
        }
      },
      isLoading: false,
      defaultSelectAllForFields: ['clusters'],

      reportUrl: API.mcm('heliosReports'),
      reportIframeName: 'helios-report-iframe',
      reportFormTarget: null
    });

    /**
     * Initialize this controller.
     *
     * @method     $onInit
     */
    function $onInit() {
      $ctrl.reports = HeliosReportsService.getReportsConfig().map(report => (
        { ...report, reportName: $translate.instant(report.labelKey) }
      ));

      // select the report from URL.
      if (!$ctrl.selectedReport) {
        selectReport($ctrl.reportType);
      }
    }

    /**
     * Selected the provided report.
     *
     * @method   selectReport
     * @param    {Object}   reportType   The report type to be selected.
     */
    function selectReport(reportType) {
      if (reportType) {
        $ctrl.selectedReport =
          _.find($ctrl.reports, ['params.reportType', reportType]);

        $state.go('helios-reports.builtin', {reportType: reportType});
      } else {
        // goto default report if reportType is not provided.
        $state.go('helios-reports.builtin');
      }
    }

    /**
     * Updates the report url for selected report and with applied filter params
     *
     * @method    updateReportPostData
     */
    function updateReportPostData() {
      if (loadTimer) {
        $timeout.cancel(loadTimer);
      }

      const reportPostData = getReportPostData('html');
      if (!_.isEqual($ctrl.reportPostData, reportPostData)) {
        $ctrl.isLoading = true;
        $ctrl.reportFormTarget = $ctrl.reportIframeName;
        $ctrl.reportPostData = reportPostData;

        // Fallback for case when something fails inside iframe
        loadTimer = $timeout(
          function fallbackLoadTimer() {
            if ($ctrl.isLoading) stopLoading();
          }, 90000 // 90 secs
        );
      }
    }

    /**
     * Launch download report modal.
     *
     * @method    downloadReport
     */
    function downloadReport() {
      HeliosReportsService.launchDownloadReport()
        .then(function download(outputFormat) {
          $ctrl.reportFormTarget = '_blank';
          $ctrl.reportPostData = getReportPostData(outputFormat.value);
        }, _.noop);
    }

    /**
     * Launch modify email schedule or send email report modal.
     *
     * @method    scheduleReport
     */
    function scheduleReport() {
      ReportsUtil.openEmailScheduler(
        // selected filter values.
        _.clone($ctrl.filter),

        // reports config.
        {
          fields: $ctrl.selectedReport.filter.floor.concat(
            $ctrl.selectedReport.filter.ceil),
          selectedReport: $ctrl.selectedReport,
          showHeader: true,
        },

        // empty filters dataset.
        {},

        // launch create mode.
        false,

        // show mcmMode email scheduler view.
        true
      );
    }

    /**
     * Handler to hide loader on load of report iframe
     *
     * @method    onReportLoad
     * @param    {DOMElement}   element   reference to iframe element
     */
    function onReportLoad(element) {
      /**
       * Iframe load is called twice,
       * 1. after document load
       * 2. after window load
       * jsvm will be available on window load
       */
      const jsvm = element.contentWindow.J$VM;
      if (jsvm) {
        /**
         * JReports is using J$VM procs chain for loading the report
         * We are pushing another method in to the procs chain, which will be
         * executed after all the existing procs are executed.
         *
         * PS: we need to run the _execProcs manually as iframe load
         * is called after window.onload which is calling the _execProcs for report
         */
        jsvm.exec(null, stopLoading);
        jsvm.Runtime._execProcs();
      }
    }

    /**
     * Utility method to turn off loader
     * @method    onReportLoad
     */
    function stopLoading() {
      $ctrl.isLoading = false;
    }


  /**
   * Utility method to create postData object from
   * report params, report filters and outputFormat
   * @method    getReportPostData
   * @param    {String}   outputFormat   output format of the report
   */
    function getReportPostData(outputFormat) {
      $ctrl.filter["report"] = $ctrl.selectedReport.params;
      return _.assign(
        { outputFormat },
        $ctrl.selectedReport.params,
        HeliosReportsServiceFormatter.getFilterParams($ctrl.filter)
      )
    }
  }
})(angular);