<!-- Antivirus scan filter Component -->
<div class="view-form-field">
  <mat-label>{{'entitiesToScan' | translate}}</mat-label>
  <mat-radio-group [formControl]="formGroupControls.mode" class="flex-row padding-top-sm" [disabled]="readOnly">
    <mat-radio-button [checked]="!formGroupControls.mode.value" cogDataId="all-file-types">
      {{'all' | translate}}
    </mat-radio-button>

    <mat-radio-button [value]="'Blacklist'" cogDataId="blacklist-file-types" class="padding-left">
      {{'allEntitiesExcept' | translate}}
    </mat-radio-button>

    <mat-radio-button [value]="'Whitelist'" cogDataId="whitelist-file-types" class="padding-left">
      {{'onlyTheseEntities' | translate}}
    </mat-radio-button>
  </mat-radio-group>

  <div class="margin-top margin-bottom" *ngIf="!!formGroupControls.mode.value && !readOnly">
    <mat-label>{{(formGroupControls.mode.value === 'Whitelist' ? 'entitiesToScan.label.allowlist' :
      'entitiesToScan.label.denylist') | translate}}</mat-label>

    <!-- File extensions filter -->
    <cog-chip-list-input label="fileExtensions" [control]="formGroupControls.extensionFilterList"
      [validatorFn]="fileExtensionValidatorFn" chipDisplayPrefix="." [transformBeforeAdd]="fileExtensionChipTransformer"
      [separatorKeys]="separatorKeys" hintMessage="chipListInput.hint.onlyEnterKey">
    </cog-chip-list-input>

    <div *ngIf="isS3Only">
      <!-- Object key name prefixes filter -->
      <cog-chip-list-input label="objectKeyNamePrefixes" [control]="formGroupControls.prefixScanFilterList"
        [separatorKeys]="separatorKeys" hintMessage="chipListInput.hint.onlyEnterKey">
      </cog-chip-list-input>

      <!-- object tags filter -->
      <h4 class="margin-top margin-bottom-sm">{{'objectTags' | translate}}</h4>
      <p>{{'objectTagsFilterDescription' | translate}}</p>
      <button *ngIf="!s3TaggingFilterList?.value?.length" cogDataId="add-s3-tags-btn" type="button" mat-stroked-button
        color="primary" (click)="addTagSet()">
        {{'addObjectTag' | translate}}
      </button>
      <ng-container *ngIf="s3TaggingFilterList?.value?.length">
        <div class="row margin-left-xs"
          *ngFor="let tagSetGroup of s3TaggingFilterList.controls; index as i; first as isFirst; last as isLast;">
          <mat-form-field class="col-xs-4">
            <input cogDataId="s3-tag-key-{{i}}-input" matInput [formControl]="tagSetGroup.controls.key"
              placeholder="{{'key' | translate}}">
            <mat-error cogDataId="s3-tag-key-{{i}}-error" *ngIf="tagSetGroup?.controls?.key?.errors?.required">
              {{'errors.required' | translate}}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-xs-4">
            <input cogDataId="s3-tag-value-{{i}}-input" matInput [formControl]="tagSetGroup.controls.value"
              placeholder="{{'value' | translate}}">
            <mat-error cogDataId="s3-tag-value-{{i}}-error" *ngIf="tagSetGroup?.controls?.value?.errors?.required">
              {{'errors.required' | translate}}
            </mat-error>
          </mat-form-field>
          <button mat-icon-button class="col-xs-2" type="button" cogDataId="remove-s3-tag-{{i}}-btn"
            (click)="removeTagSet(i)">
            <cog-icon shape="remove_circle_outline"></cog-icon>
          </button>
          <button mat-icon-button class="col-xs-2" type="button" cogDataId="add-new-s3-tag-btn" *ngIf="isLast"
            (click)="addTagSet()">
            <cog-icon shape="add_circle_outline"></cog-icon>
          </button>
        </div>
      </ng-container>
    </div>
  </div>
</div>
