//  Component: Sticky Footer

;(function(angular, undefined) {
  'use strict';

  var componentConfig = {
    restrict: 'E',
    transclude: true,
    template: '<div class="c-sticky-footer" ng-transclude></div>',
  };

  angular.module('C.stickyFooter', [])
    .component('stickyFooter', componentConfig);

})(angular);
