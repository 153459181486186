import { Injectable } from '@angular/core';
import { StateParams, StateService, TransitionService, UIRouterGlobals } from '@uirouter/core';

/**
 * @description
 * Helper service for tracking router states and params.
 * Can be helpful when navigating back to some state and need to lookup last params used for that state.
 * This service is meant to be used across components that do router transition.
 * Provide and initialize it in your root module where you declare all your components.
 *
 * @example
 *
 *  @NgModule({
 *    providers: [
 *      {
 *        provide: StateHistoryService,
 *        useClass: StateHistoryService,
 *      }
 *    ],
 *  })
 *  export class MyModule {
 *    constructor(stateHistoryService: StateHistoryService) {
 *      stateHistoryService.init();
 *    }
 *  }
 */
@Injectable()
export class StateHistoryService {
  /**
   * Tracks router state name to its last state param.
   */
  private readonly stateHistory = new Map<string, StateParams>();

  /**
   * Indicates if service is initialized.
   */
  private _initialized = false;

  constructor(
    private stateService: StateService,
    private transitionService: TransitionService,
    private uiRouterGlobals: UIRouterGlobals
  ) {}

  /**
   * Initializes services.
   */
  init() {
    if (!this._initialized) {
      this._initialized = true;
      this.transitionService.onStart({}, this.onTransitionStart);
    }
  }

  /**
   * Handles router transition start event and populates history with current state name and params.
   */
  private onTransitionStart = () => {
    const stateName = this.uiRouterGlobals.current.name;
    const stateParams = { ...this.uiRouterGlobals.params } as StateParams;
    this.stateHistory.set(stateName, stateParams);
  };

  /**
   * Returns state params from history based on specified state name.
   */
  getStateParams(stateName: string): StateParams {
    return this.stateHistory.get(stateName);
  }

  /**
   * Returns to specified state with params from stored history.
   */
  goBack(stateName: string) {
    this.stateService.go(stateName, this.getStateParams(stateName));
  }
}
