import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ProtectionRunObject } from '../../../models';

/**
 * @description
 *
 * Displays last run object status for specified source ID.
 */
@Component({
  selector: 'coh-run-status',
  templateUrl: './run-status.component.html',
  styleUrls: ['./run-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RunStatusComponent {
  /**
   * Run objects that contain last run status.
   */
  @Input() runObjects: ProtectionRunObject[];

  /**
   * Source ID will be used to match object ID in runObjects array.
   */
  @Input() sourceId: number;

  /**
   * Object's last run status.
   */
  get status(): string {
    return this.runObjects?.find(object => object.id === this.sourceId)?.status;
  }

}
