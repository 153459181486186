import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import {
  Controls,
  NgxSubFormComponent,
  subformComponentProviders,
  takeUntilDestroyed,
} from 'ngx-sub-form';

import { UntypedFormControl, Validators } from '@angular/forms';
import { RecoverVmVmwareRecoveryOptionsSANTransport } from 'src/app/modules/restore/hypervisor-shared/vmware/models/recover-vm-vmware-recovery-options-form.model';
/**
 * Settings list item to display toggle switch for leveraging SAN Transport for Copy Recovery.
 */
@Component({
  selector: 'coh-settings-list-leverage-san-transport-for-recovery',
  templateUrl: './settings-list-leverage-san-transport-for-recovery.html',
  styleUrls: ['./settings-list-leverage-san-transport-for-recovery.scss'],
  providers: subformComponentProviders(SettingsListLeverageSanTransportForRecoveryComponent),
})
export class SettingsListLeverageSanTransportForRecoveryComponent
  extends NgxSubFormComponent<RecoverVmVmwareRecoveryOptionsSANTransport>
  implements OnChanges, OnInit {

  /**
   * Whether to enable the 'Leverage SAN Transport' option.
   */
  @Input() enableSanTransport: boolean;

  /**
   * Whether to enable the 'Leverage SAN Transport' option.
   */
  @Input() displayCustomizeDiskProvisioningOption: boolean;

  /**
   * The translation key corresponding to selected disk provisioning type.
   */
  get getSelectedDiskProvisioning(): string {
    const diskProvisionType = this.formGroupControls.diskProvisionType.value;
    if (diskProvisionType === 'kThickLazyZeroed') {
      return 'vmRecoveryDiskProvisioningThickProvisionLazyZeroed';
    } else if (diskProvisionType === 'kThickEagerZero') {
      return 'vmRecoveryDiskProvisioningThickProvisionEagerlyZeroed';
    } else if (diskProvisionType === 'kThin') {
      return 'vmRecoveryDiskProvisioningThinProvision';
    } else if (diskProvisionType === 'kBackedUpDiskType') {
      return 'vmRecoveryDiskProvisioningBackedupDisktype';
    }
  }

  /**
   * Component on init.
   */
  ngOnInit() {
    this.formGroupControls.leverageSanTransport.valueChanges.pipe(
      takeUntilDestroyed(this)
    ).subscribe(value => {
      if (value) {
        this.formGroupControls.diskProvisionType.setValue('kThickEagerZero');
        this.formGroupControls.allowNBDSSLTransportFallback.setValue(true);
      } else {
        this.formGroupControls.customizeDiskProvisioning.setValue(false);
        this.formGroupControls.diskProvisionType.setValue('kBackedUpDiskType');
      }
    });
    this.formGroupControls.customizeDiskProvisioning.valueChanges.pipe(
      takeUntilDestroyed(this)
    ).subscribe(value => {
      if (!value) {
        this.formGroupControls.diskProvisionType.setValue('kThickEagerZero');
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.enableSanTransport) {
      if (this.enableSanTransport) {
        this.formGroupControls.leverageSanTransport.enable();
      } else {
        this.formGroupControls.leverageSanTransport.setValue(false);
        this.formGroupControls.allowNBDSSLTransportFallback.setValue(false);
        this.formGroupControls.customizeDiskProvisioning.setValue(false);
        this.formGroupControls.diskProvisionType.setValue('kBackedUpDiskType');
        this.formGroupControls.leverageSanTransport.disable();
      }
    }
  }

  /**
   * The form control for the settings list item.
   */
  getDefaultValues(): Partial<RecoverVmVmwareRecoveryOptionsSANTransport> {
    return {
      leverageSanTransport: false,
      allowNBDSSLTransportFallback: false,
      customizeDiskProvisioning: false,
      diskProvisionType: 'kThickEagerZero',
    };
  }

  /**
   * Function to return all the form controls.
   *
   * @return The form controls object.
   */
  protected getFormControls(): Controls<RecoverVmVmwareRecoveryOptionsSANTransport> {
    return {
      leverageSanTransport: new UntypedFormControl(null, Validators.required),
      allowNBDSSLTransportFallback: new UntypedFormControl(null),
      customizeDiskProvisioning: new UntypedFormControl(null),
      diskProvisionType: new UntypedFormControl(null),
    };
  }
}
