import { Component, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

/**
 * Data structure for the dialog component.
 */
interface DialogContext {
  /**
   * Title of dialog.
   */
  title?: string;

  /**
   * Message or body of dialog.
   */
  message: string;

  /**
   * Message data for message translation.
   */
  messageData: any;

  /**
   * Indicates whether message contains HTML.
   */
  isHtml?: boolean;

  /**
   * Hide cancel button if true.
   */
  hideCancel?: boolean;

  /**
   * Cancel button label.
   */
  cancelLabel?: string;

  /**
   * Confirmation button label.
   */
  confirmLabel?: string;

  /**
   * Confirmation button type.
   */
  confirmButtonType?: string;

  /**
   * Specifies if confirmation input has to be shown or not.
   */
  showConfirmationInput?: boolean;
}

/**
 * A generic confirmation dialog that allows user to customize message, title, and button labels.
 */
@Component({
  selector: 'coh-confirmation-dialog',
  styleUrls: ['./confirmation-dialog.component.scss'],
  templateUrl: './confirmation-dialog.component.html',
})
export class ConfirmationDialogComponent {
  /**
   * Title of dialog.
   */
  title: string = this.context?.title;

  /**
   * Message/Body of dialog.  Default to a generic 'Are you sure?' message
   */
  message = this.context?.message || 'areYouSure';

  /**
   * Message data for translation.
   */
  messageData = this.context?.messageData || {};

  /**
   * Indicates whether message contains HTML..
   */
  isHtml: boolean = this.context?.isHtml;

  /**
   * Hide cancel button if true.
   */
  hideCancel: boolean = this.context?.hideCancel;

  /**
   * Cancel button label. Default to 'Cancel'.
   */
  cancelLabel = this.context?.cancelLabel || 'cancel';

  /**
   * Confirmation button label. Default to 'OK'.
   */
  confirmLabel = this.context?.confirmLabel || 'ok';

  /**
   * Confirmation button type. Default to 'primary'.
   */
  confirmButtonType = this.context?.confirmButtonType || 'primary';

  /**
   * Specifies if confirmation input has to be shown or not.
   */
  confirmationInput = this.context?.showConfirmationInput;

  /**
   * Represents the confirmation dialog form.
   */
  formGroup = new UntypedFormGroup({
    confirm: new UntypedFormControl(null, [Validators.required, Validators.pattern(/^yes$/i)]),
  });

  /**
   * Constructor.
   */
  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public context: DialogContext
  ) {}
}
