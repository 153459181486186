<div>
  <div class="dl-row">
    <dt>{{ 'zookeeperQuorum' | translate }}</dt>
    <dd>{{ hBaseParams?.zookeeperQuorum?.join(', ') }}</dd>
  </div>
  <div class="dl-row">
    <dt>{{ 'sources.hBase.dataRootDir' | translate }}</dt>
    <dd>{{ hBaseParams?.rootDataDirectory }}</dd>
  </div>
  <div class="dl-row" *ngIf="hBaseParams?.kerberosPrincipal">
    <dt>{{ 'kerberosPrincipal' | translate }}</dt>
    <dd>{{ hBaseParams?.kerberosPrincipal }}</dd>
  </div>
</div>
