/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Ability } from '../models/ability';
import { BulkPatchUpdateRegisteredDataSourcesRequest } from '../models/bulk-patch-update-registered-data-sources-request';
import { BulkPatchUpdateRegisteredDataSourcesResponse } from '../models/bulk-patch-update-registered-data-sources-response';
import { DataSource } from '../models/data-source';
import { DataSourceId } from '../models/data-source-id';
import { DataSourceParams } from '../models/data-source-params';
import { Environment } from '../models/environment';
import { GetDataSourcesResponse } from '../models/get-data-sources-response';

@Injectable({
  providedIn: 'root',
})
export class DataSourcesApiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getRegisteredDataSources
   */
  static readonly GetRegisteredDataSourcesPath = '/sources';

  /**
   * Get the list of Registered Data Sources.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRegisteredDataSources()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRegisteredDataSources$Response(params?: {

    /**
     * Filter by a list of registered Data Source ids.
     */
    ids?: null | Array<DataSourceId>;

    /**
     * Filter by a list of Data Source environment types.
     */
    environments?: null | Array<Environment>;

    /**
     * Filter by Data Sources enabled/disabled ability.
     */
    abilities?: null | Array<Ability>;

  }): Observable<StrictHttpResponse<GetDataSourcesResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DataSourcesApiService.GetRegisteredDataSourcesPath, 'get');
    if (params) {

      rb.query('ids', params.ids, {});
      rb.query('environments', params.environments, {});
      rb.query('abilities', params.abilities, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetDataSourcesResponse>;
      })
    );
  }

  /**
   * Get the list of Registered Data Sources.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getRegisteredDataSources$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRegisteredDataSources(params?: {

    /**
     * Filter by a list of registered Data Source ids.
     */
    ids?: null | Array<DataSourceId>;

    /**
     * Filter by a list of Data Source environment types.
     */
    environments?: null | Array<Environment>;

    /**
     * Filter by Data Sources enabled/disabled ability.
     */
    abilities?: null | Array<Ability>;

  }): Observable<GetDataSourcesResponse> {

    return this.getRegisteredDataSources$Response(params).pipe(
      map((r: StrictHttpResponse<GetDataSourcesResponse>) => r.body as GetDataSourcesResponse)
    );
  }

  /**
   * Path part for operation bulkPatchUpdateRegisteredDataSources
   */
  static readonly BulkPatchUpdateRegisteredDataSourcesPath = '/sources';

  /**
   * Patch user specified fields in Registered Data Sources in bulk.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bulkPatchUpdateRegisteredDataSources()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  bulkPatchUpdateRegisteredDataSources$Response(params: {
      body: BulkPatchUpdateRegisteredDataSourcesRequest
  }): Observable<StrictHttpResponse<BulkPatchUpdateRegisteredDataSourcesResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DataSourcesApiService.BulkPatchUpdateRegisteredDataSourcesPath, 'put');
    if (params) {


      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BulkPatchUpdateRegisteredDataSourcesResponse>;
      })
    );
  }

  /**
   * Patch user specified fields in Registered Data Sources in bulk.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `bulkPatchUpdateRegisteredDataSources$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  bulkPatchUpdateRegisteredDataSources(params: {
      body: BulkPatchUpdateRegisteredDataSourcesRequest
  }): Observable<BulkPatchUpdateRegisteredDataSourcesResponse> {

    return this.bulkPatchUpdateRegisteredDataSources$Response(params).pipe(
      map((r: StrictHttpResponse<BulkPatchUpdateRegisteredDataSourcesResponse>) => r.body as BulkPatchUpdateRegisteredDataSourcesResponse)
    );
  }

  /**
   * Path part for operation registerDataSource
   */
  static readonly RegisterDataSourcePath = '/sources/registration';

  /**
   * Register a Data Source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registerDataSource()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registerDataSource$Response(params: {
      body: DataSourceParams
  }): Observable<StrictHttpResponse<DataSource>> {

    const rb = new RequestBuilder(this.rootUrl, DataSourcesApiService.RegisterDataSourcePath, 'post');
    if (params) {


      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DataSource>;
      })
    );
  }

  /**
   * Register a Data Source.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `registerDataSource$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registerDataSource(params: {
      body: DataSourceParams
  }): Observable<DataSource> {

    return this.registerDataSource$Response(params).pipe(
      map((r: StrictHttpResponse<DataSource>) => r.body as DataSource)
    );
  }

  /**
   * Path part for operation getRegisteredDataSourceById
   */
  static readonly GetRegisteredDataSourceByIdPath = '/sources/registration/{id}';

  /**
   * Get the registered Data Source by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRegisteredDataSourceById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRegisteredDataSourceById$Response(params: {

    /**
     * Specifies the Data Source registration id.
     */
    id: DataSourceId;

  }): Observable<StrictHttpResponse<DataSource>> {

    const rb = new RequestBuilder(this.rootUrl, DataSourcesApiService.GetRegisteredDataSourceByIdPath, 'get');
    if (params) {

      rb.path('id', params.id, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DataSource>;
      })
    );
  }

  /**
   * Get the registered Data Source by id.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getRegisteredDataSourceById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRegisteredDataSourceById(params: {

    /**
     * Specifies the Data Source registration id.
     */
    id: DataSourceId;

  }): Observable<DataSource> {

    return this.getRegisteredDataSourceById$Response(params).pipe(
      map((r: StrictHttpResponse<DataSource>) => r.body as DataSource)
    );
  }

  /**
   * Path part for operation updateDataSourceRegistration
   */
  static readonly UpdateDataSourceRegistrationPath = '/sources/registration/{id}';

  /**
   * Update Data Source Registration.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateDataSourceRegistration()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateDataSourceRegistration$Response(params: {

    /**
     * Specifies the Data Source registration id.
     */
    id: DataSourceId;
      body: DataSourceParams
  }): Observable<StrictHttpResponse<DataSource>> {

    const rb = new RequestBuilder(this.rootUrl, DataSourcesApiService.UpdateDataSourceRegistrationPath, 'put');
    if (params) {

      rb.path('id', params.id, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DataSource>;
      })
    );
  }

  /**
   * Update Data Source Registration.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateDataSourceRegistration$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateDataSourceRegistration(params: {

    /**
     * Specifies the Data Source registration id.
     */
    id: DataSourceId;
      body: DataSourceParams
  }): Observable<DataSource> {

    return this.updateDataSourceRegistration$Response(params).pipe(
      map((r: StrictHttpResponse<DataSource>) => r.body as DataSource)
    );
  }

  /**
   * Path part for operation deleteDataSourceRegistration
   */
  static readonly DeleteDataSourceRegistrationPath = '/sources/registration/{id}';

  /**
   * Delete Data Source Registration.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteDataSourceRegistration()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteDataSourceRegistration$Response(params: {

    /**
     * Specifies the Data Source registration id.
     */
    id: DataSourceId;

  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, DataSourcesApiService.DeleteDataSourceRegistrationPath, 'delete');
    if (params) {

      rb.path('id', params.id, {});

    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete Data Source Registration.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteDataSourceRegistration$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteDataSourceRegistration(params: {

    /**
     * Specifies the Data Source registration id.
     */
    id: DataSourceId;

  }): Observable<void> {

    return this.deleteDataSourceRegistration$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
