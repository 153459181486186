import { Memoize } from '@cohesity/utils';

import { ObjectSearchResult } from './object-search-result';
import { RestoreSearchResult } from './restore-search-result';

/**
 * This class is used to configure the recovery search form to show results for a protection group.
 * This class does not implement defaultRestorePointSelection, so the parent component will need to
 * manually fetch run id information when the group is selected from the list.
 *
 * This is currently derived from a list of objects, but should come directly from the search result metadata.
 *
 * @example
 * const searchResult = new ProtectionGroupSearchResult(protectObjects);
 */
export class ProtectionGroupSearchResult implements RestoreSearchResult {

  /**
   * Static property for the result type. Which can be referenced by other classes.
   */
  static readonly protectionGroupResultType = 'protectionGroup';

  readonly resultType = ProtectionGroupSearchResult.protectionGroupResultType;

  get environment(): string {
    return this.latestRun.environment;
  }

  get id(): string {
    return this.latestRun.protectionGroupId;
  }

  get storageDomainId(): number {
    return this.latestRun.storageDomainId;
  }

  get name(): string {
    return this.protectionGroupName;
  }

  get defaultRestorePointId(): string {
    return this.latestRun.protectionRunId;
  }

  get protectionGroupName(): string {
    return this.latestRun.protectionGroupName;
  }

  get restoreTimestampUsecs(): number {
    return this.latestRun.restoreTimestampUsecs;
  }

  get sourceEnvironment(): string {
    return this.latestRun.sourceInfo.environment || this.environment;
  }

  get sourceId(): number {
    return this.latestRun.sourceId;
  }

  get parentSourceName(): string {
    return this.latestRun.parentSourceName;
  }

  get protectionType(): string {
    return this.latestRun.protectionType;
  }

  get objectsProtected(): ObjectSearchResult[]{
    return this.objects;
  }

  /**
   * Checks the object search results for the most recent run and uses that to deterime most of the group's information
   */
  @Memoize()
  private get latestRun(): ObjectSearchResult {
    return this.objects.reduce((mostRecent, current) => {
      if (!mostRecent) {
        return current;
      }
      return current.restoreTimestampUsecs > mostRecent.restoreTimestampUsecs ? current : mostRecent;
    });
  }

  constructor(private objects: ObjectSearchResult[]) {}
}
