<h2 mat-dialog-title>{{ 'editSite' | translate }}</h2>

<mat-dialog-content>
  <form id="site-details-form" [formGroup]="formGroup" cohValidateOnSubmit (validatedSubmit)="saveSite()">
    <ng-container *ngIf="site.type === 'OnPrem'">
      <!-- Clusters List -->
      <div class="margin-bottom">
        <mat-label>{{ 'clusters' | translate }} ({{ site.clusters?.length || 0 }})</mat-label>
      </div>

      <mat-chip-list cogDataId="clusters-list" [attr.aria-label]="'clusterList' | translate">
        <mat-chip *ngFor="let cluster of site.clusters" [removable]="false" cogDataId="cluster-{{cluster.name}}">
          {{ cluster.name }}
        </mat-chip>
      </mat-chip-list>
    </ng-container>

    <ng-container *ngIf="site.type === 'AWS'">
      <p>{{ 'typeColon' | translate }} {{ 'managedByCohesity' | translate }}</p>
      <p>{{ 'regionColon' | translate }} {{ site.awsRegionId }}</p>
    </ng-container>

    <!-- Site Name -->
    <mat-form-field class="margin-top">
      <mat-label for="site-name-input">{{ 'siteName' | translate }}</mat-label>

      <input id="site-name-input"
        cohAutoFocus
        matInput
        cogDataId="site-name-input"
        [formControl]="formGroupControls.name">
      <mat-error>{{ 'errors.required' | translate }}</mat-error>
    </mat-form-field>

    <ng-container *ngIf="site.type === 'OnPrem'">
      <!-- Country -->
      <mat-form-field>
        <mat-label for="site-country-input">{{ 'country' | translate }}</mat-label>
        <input id="site-country-input" matInput cogDataId="site-country-input" [formControl]="formGroupControls.country">
      </mat-form-field>

      <!-- State/Region -->
      <mat-form-field>
        <mat-label for="site-state-input">{{ 'state' | translate }}</mat-label>
        <input id="site-state-input" matInput cogDataId="site-state-input" [formControl]="formGroupControls.state">
      </mat-form-field>

      <!-- City -->
      <mat-form-field>
        <mat-label for="site-city-input">{{ 'city' | translate }}</mat-label>
        <input id="site-city-input" matInput cogDataId="site-city-input" [formControl]="formGroupControls.city">
      </mat-form-field>
    </ng-container>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button
    cogCancelButton
    cogDataId="edit-site-cancel-button"
    mat-dialog-close>
    {{ 'cancel' | translate }}
  </button>

  <button form="site-details-form"
    mat-button
    cogSubmitButton
    [loading]="loading$ | async"
    [disabled]="loading$ | async"
    cogDataId="edit-site-save-button">
    {{ 'save' | translate }}
  </button>
</mat-dialog-actions>
