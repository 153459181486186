<mat-icon
  [attr.aria-label]="ariaLabel"
  [attr.aria-hidden]="ariaHidden"
  [attr.role]="role"
  [inline]="inline"
  [color]="color"
  [fontSet]="solid ? undefined : 'material-icons-outlined'"
  *ngIf="matIcon">
  {{matIcon}}
</mat-icon>
<mat-icon
  [attr.aria-label]="ariaLabel"
  [attr.aria-hidden]="ariaHidden"
  [attr.role]="role"
  [inline]="inline"
  [color]="color"
  [svgIcon]="svgIcon"
  *ngIf="svgIcon">
</mat-icon>
