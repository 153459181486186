import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { Controls, NgxSubFormRemapComponent, subformComponentProviders } from 'ngx-sub-form';
import {
  RenameObjectDetails
} from '../../../components/settings-list-items/settings-list-object-rename/recovery-rename.model';
import { RenamedObject } from '../../../model/recovery-uda-form-options';

/**
 * Form model for the object rename form.
 */
export interface RenamedObjectForm {

  /**
   * Rename details for the objects selected for restore.
   */
  objectRenameDetails: RenameObjectDetails[];
}

/**
 * Settings list item to supply the rename property for the object to be restored.
 */
@Component({
  selector: 'coh-settings-list-rename-object',
  templateUrl: './settings-list-rename-object.component.html',
  styleUrls: ['./settings-list-rename-object.component.scss'],
  providers: subformComponentProviders(SettingsListRenameObjectComponent),
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsListRenameObjectComponent
  extends NgxSubFormRemapComponent<RenamedObject[], RenamedObjectForm>
  implements OnChanges, OnInit {

  /**
   * List of the object names that have been selected for restore.
   */
  @Input() objectNames: string[];

  /**
   * Check if any of the obejcts have been renamed.
   */
  get isAnyObjectRenamed(): boolean {
    return (this.formGroupValues.objectRenameDetails || []).some(object => !!object.renameTo);
  }

  ngOnInit() {
    this.createForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.objectNames) {
      this.createForm();
    }
  }

  constructor(private cdr: ChangeDetectorRef) {
    super();
  }

  /**
   * Create objects rename option form.
   */
  createForm() {
    this.formGroupControls.objectRenameDetails.clear();
    this.objectNames.forEach(objectName => {
      const control = new UntypedFormControl({
        name: objectName?.trim(),
        renameTo: '',
        icon: 'helix:object-db'
      });
      this.formGroupControls.objectRenameDetails.push(control);
    });

    this.cdr.detectChanges();
  }

  protected transformToFormGroup(renamedObjects: RenamedObject[],
    defaults: Partial<RenamedObjectForm>): RenamedObjectForm {
    if (this.objectNames === null) {
      return defaults as RenamedObjectForm;
    }

    return {
      objectRenameDetails: this.objectNames.map(renamedObject => ({
        name: renamedObject?.trim(),
        renameTo: '',
        icon: 'helix:object-db'
      }))
    };
  }

  protected transformFromFormGroup(form: RenamedObjectForm): RenamedObject[] {
    return form.objectRenameDetails;
  }

  getDefaultValues(): Partial<RenamedObjectForm> {
    return {
      objectRenameDetails: []
    };
  }

  protected getFormControls(): Controls<RenamedObjectForm> {
    return {
      objectRenameDetails: new UntypedFormArray([]),
    };
  }
}
