<div class="object-name">
  <cog-icon [shape]="node.protectionSource | objectIcon : { isProtected: isNodeProtected }">
  </cog-icon>
  <h4 class="title">{{node.protectionSource.name}}</h4>
</div>

<!-- General Information -->
<div class="source-meta-grouping object-info-list">
  <ul>
    <!-- Server Type -->
    <li class="source-meta-line-item">
      <span class="source-meta-label">{{'type' | translate}}</span>
      {{ 'azureVm' | translate }}
    </li>

    <!-- Server Size -->
    <li class="source-meta-line-item" *ngIf="node.logicalSize">
      <span class="source-meta-label">{{'size' | translate}}</span>
      {{node.logicalSize | byteSize}}
    </li>

    <!-- location -->
    <li class="source-meta-line-item" *ngIf="azureVMSource?.location">
      <span class="source-meta-label">{{'location' | translate}}</span>
      {{azureVMSource.location | translate}}
    </li>
  </ul>
</div>

<coh-protection-status-meta class="margin-top-lg"
  [node]="node"
  [protectionLabel]="'sourceTreePub.tooltips.protected' | translate">
</coh-protection-status-meta>
