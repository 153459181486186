import { Component, Input, AfterViewInit, forwardRef } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormControl, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

/**
 * Component to to show a button for file upload.
 *
 * @example
 * <coh-upload-button label="CA Certificate"></coh-upload-button>
 */
@Component({
  selector: 'coh-upload-button',
  styleUrls: ['./upload-button.component.scss'],
  templateUrl: './upload-button.component.html',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: forwardRef(() => UploadButtonComponent),
  }]
})
export class UploadButtonComponent implements AfterViewInit, ControlValueAccessor {
  /**
   * List of items to be displayed
   */
  @Input() label: string;

  /**
   * List of items to be displayed
   */
  @Input() required: boolean;

  /**
   * Whether to show the label.
   */
  @Input() showLabel = true;

  /**
   * Tooltip text for the button
   */
  @Input() toolTip: string;

  /**
   * The file type pattern to accept for the file input
   */
  @Input() fileTypePattern = '';

  /**
   * FormGroup for this component
   */
  fileUploadForm = new UntypedFormGroup({});

  /**
   * The current selected file
   */
  file: File;

  /**
   * To read the contents of uploaded file
   */
  fileReader = new FileReader();

  /**
   * Callback to pass the change back to a form control. Defaults to a noop
   */
  propagateChange = (_value: File) => {};

  /**
   * Update the view with a value passed from a form
   *
   * @param   time   the time of day
   */
  writeValue(file: File) {
    this.file = file;
  }

  /**
   * Registers a change event to use to propogate changes
   *
   * @param   fn   the callback function
   */
  registerOnChange(fn: (value: File) => any) {
    this.propagateChange = fn;
  }

  /**
   * Register on touched. Not currently used
   */
  registerOnTouched() {}


  ngAfterViewInit() {
    this.fileUploadForm.addControl('file',
      new UntypedFormControl(null, this.required ? Validators.required : null));
  }

  /**
   * Trigger 'click' event on hidden file input to open file browser
   */
  openFileBrowser(event: any) {
    event.preventDefault();

    const element: HTMLElement = document.getElementById('upload-file-' + this.label + '-hidden');
    element.click();
  }

  /**
   * Callback for file selection
   */
  onFileChange(event: any) {
    this.file = event.target.files[0];
    this.propagateChange(this.file);
  }

  /**
   * Remove selected file when 'x' is clicked
   */
  removeFile() {
    this.file = undefined;
    this.propagateChange(this.file);
  }
}
