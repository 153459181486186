import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ExternalTarget, ExternalTargetServiceApi } from '@cohesity/api/v2';
import { AjaxHandlerService, AutoDestroyable } from '@cohesity/utils';
import { finalize } from 'rxjs/operators';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';
import { StorageDomainInfo } from '../modify-storage-domain/storage-domain-form.model';

/**
 * Dialog to create new Storage Domain for NGCE.
 */
@Component({
  selector: 'coh-ngce-create-storage-domain-dialog',
  templateUrl: './ngce-create-storage-domain-dialog.component.html',
  styleUrls: ['./ngce-create-storage-domain-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NgceCreateStorageDomainComponent extends AutoDestroyable implements OnInit {

  /**
   * Form group control options.
   */
  formGroup: UntypedFormGroup;

  /**
   * Indicates whether add/edit storage domain is in process or not. Defaults to false.
   */
  saving = false;

  /**
   * To check if dialog opened to edit
   */
  isEditMode = false;

  /**
   * List of external target tier type that need to be excluded.
   * key: v1 model key for tier type
   * aliasV2Key: v2 model key for target type
   */
  readonly excludedTierTypes = [];

  constructor(
    public dialogRef: MatDialogRef<NgceCreateStorageDomainComponent>,
    private externalTargetsService: ExternalTargetServiceApi,
    private irisCtx: IrisContextService,
    private ajaxHandlerService: AjaxHandlerService,
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: StorageDomainInfo) {
    super();
  }

  ngOnInit() {
    if (this.data) {
      this.isEditMode = true;
      this.getExternalTargetById(this.data?.vaultId);
    }
    this.formGroup = this.formBuilder.group({
      storageDomainName: ['', [Validators.required]],
      associatedExternalTarget: [{value: '', disabled: this.isEditMode}, [Validators.required]],
      vault: ['']
    });

    this.formGroup.get('associatedExternalTarget').valueChanges.subscribe(value => {
      if (value?.vaultId) {
        this.getExternalTargetById(value.vaultId);
      }
    });

    if (!flagEnabled(this.irisCtx.irisContext, 'enableColdlineNearlineTiersForGcp')) {
      this.excludedTierTypes.push(
        { key: 'kGoogleColdline', aliasV2Key: 'GCPColdline' },
        { key: 'kGoogleNearline', aliasV2Key: 'GCPNearline'}
      );
    }

  }

  /**
   * Get the vault details information
   *
   * @param id  Vault ID
   */
  getExternalTargetById(id: number) {
    this.externalTargetsService.GetExternalTargetById({ id }).pipe(
      this.untilDestroy(),
    )
    .subscribe(target => {
      this.formGroup.get('vault').setValue(target);
      if (this.data) {
        const storageDomainName = target?.cloudDomains.length ? target?.cloudDomains[0].storageDomainName : '';
        this.formGroup.get('storageDomainName').setValue(storageDomainName);
        this.formGroup.get('associatedExternalTarget').setValue(target?.name);
      }
    });
  }

  /**
   * Handle submission of the Storage Domain form.
   */
  onSubmit() {
    if (this.formGroup.invalid) {
      return;
    }
    const dataObj: ExternalTarget = {
      ...this.formGroup.value.vault,
      storageDomainName: this.formGroup.value.storageDomainName
    };
    this.saving = true;
    // For both create/update of storage domain in NGCE use PUT call.
    this.externalTargetsService.UpdateExternalTarget({id: dataObj.id, body: dataObj}).pipe(
      this.untilDestroy(),
      finalize(() => (this.saving = false)),
      )
      .subscribe((sd) => {
        this.dialogRef.close(sd);
      },
      error => this.ajaxHandlerService.handler(error));
  }
}
