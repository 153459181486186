// Component: c-simple-field

;(function(angular, undefined) {
  'use strict';

  angular.module('C.cFilters')
    .controller('SimpleFieldCtrl', SimpleFieldCtrlFn)
    .component('cSimpleField', {
      bindings: {
        /**
         * Optional. If provided then will be used to determine does the this
         * filter field is ready means filters can be applied on this field.
         *
         * @type   {Boolean}
         */
        isLoading: '<?',
      },
      require: {
        // require c-filters controller used with filters.
        cFilters: '?^^cFilters',

        // require c-field controller used to interact with the c-filters.
        cField: '?cField',

        // require ng-model controller used to set reset modal value bases on
        // filter actions like clear, abort or apply.
        ngModel: '?ngModel',
      },

      // transcluding field's edit and view mode DOM which will be shown inside
      // the dropdown body.
      transclude: {
        // the field view DOM which will be used as a dropdown toggle.
        view: 'view',

        // the field edit DOM which will be used as a dropdown body.
        edit: 'edit',
      },
      controller: 'SimpleFieldCtrl',
      templateUrl: 'app/global/c-filters/c-simple-field.html',
    });

  /**
   * Controller for simple filter fields like number, text, search etc fields.
   */
  function SimpleFieldCtrlFn(_, $attrs) {
    var $ctrl = this;

    // empty destructor fn for c-field setup and overridden if defined inside
    // $onInit.
    var cFieldSetupDestructor = angular.noop;

    _.assign($ctrl, {
      // keep date range dropdown hidden by default.
      isOpen: false,

      // by default don't keep date range dropdown open always and this will be
      // dynamically toggled by c-field.
      isAlwaysOpen: false,

      // by default don't show the filter actions like clear, abort and apply
      // buttons they are toggled by initFieldSetup.
      filterActions: false,

      // by default close the dropdown when clicked outside other possible
      // values like 'disabled' and 'always'.
      // 'disabled' is used when date ranges is rendered inside filter modal.
      closeConfig: 'outsideClick',

      // component life cycle methods.
      $onInit: $onInit,
      $onChanges: $onChanges,
      $onDestroy: $onDestroy,
    });

    /**
     * Initialize this component.
     *
     * @method     $onInit
     */
    function $onInit() {
      if (!$ctrl.cFilters) {
        return;
      }

      // Initialize the setup for c-field so that it can interact with field
      // value.
      cFieldSetupDestructor = initFieldSetup();

      // if isLoading is not provided assume field is ready for consumption.
      if ($attrs.hasOwnProperty('isLoading')) {
        $ctrl.cField.startLoading();
      } else {
        $ctrl.cField.endLoading();
      }

    }

    /**
     * Watching for binding changes.
     *
     * @method   $onChanges
     * @param    {Object}   changes   The changes object.
     */
    function $onChanges(changes) {
      if (changes.isLoading) {
        if ($ctrl.isLoading || changes.isLoading.isFirstChange()) {
          $ctrl.cField.startLoading();
        } else {
          $ctrl.cField.endLoading();
        }
      }
    }

    /**
     * $onDestroy cleanup function.
     *
     * @method   $onDestroy
     */
    function $onDestroy() {
      // un-register the field to remove it from c-filters internal collection.
      cFieldSetupDestructor();
    }

    /**
     * Initialize the field setup by registering the c-field API to c-filters
     * used to interact with this field.
     *
     * @method   initFieldSetup
     * @returns  {function}   Function used to destroy the setup done.
     */
    function initFieldSetup() {
      // c-field interfaces implementation for this fields.
      var cFieldApi = {
        abort: simpleFieldAbort,
        apply: simpleFieldApply,
        clear: simpleFieldClear,
        disableAlwaysOpen: disableAlwaysOpen,
        enableAlwaysOpen: enableAlwaysOpen,
        getSelectedValue: getSelectedValue,
      };

      // cached previous value used during restoring the field.
      var previousValue;

      // if set to true then dropdown toggle will prevent restoring the value
      // used when dropdown is closed by apply, clear and abort actions.
      var noCacheUpdates = false;

      // modifying date range config because they are programmatically
      // controlled by c-field setup.
      _.assign($ctrl, {
        alwaysOpen: false,
        closeConfig: 'outsideClick',
        filterActions: true,
        isOpen: false,
        onDropdownToggle: onDropdownToggle,
      });

      /**
       * Perform caching and restoring the selected value when dropdown is
       * toggled because of outside click, uib-dropdown-toggle click or
       * programmatically toggled isOpen flag.
       *
       * NOTE: don't touch cache if date range is updated because of apply,
       * clear or abort actions.
       *
       * @method   onDropdownToggle
       */
      function onDropdownToggle() {
        if (noCacheUpdates) {
          // releasing the lock now because dropdown had be toggled because of
          // apply, clear or abort actions.
          noCacheUpdates = false;
        } else {
          if ($ctrl.isOpen) {
            // dropdown is getting opened so cache the selected value.
            cacheSelectValue();
          } else {
            // dropdown is getting closed because of external reason hence
            // restoring the value.
            restoreSelectValue();
          }
        }
      }

      /**
       * Hide the dropdown on clear, apply or abort actions and preventing
       * onDropdownToggle to modify/update the cache because cache updates are
       * already handled via simpleFieldAbort/Clear/Apply fns.
       *
       * @method   hideDropdown
       */
      function hideDropdown() {
        noCacheUpdates = true;
        $ctrl.isOpen = false;
      }

      /**
       * Set field dropdown to stay always open when filters are shown in the
       * modal.
       *
       * @method   enableAlwaysOpen
       */
      function enableAlwaysOpen() {
        $ctrl.alwaysOpen = true;
        $ctrl.closeConfig = 'disabled';
        $ctrl.filterActions = false;
        $ctrl.isOpen = true;
      }

      /**
       * Reset field value to default state when filters is switched back
       * to in page view.
       *
       * @method   disableAlwaysOpen
       */
      function disableAlwaysOpen() {
        $ctrl.alwaysOpen = false;
        $ctrl.closeConfig = 'outsideClick';
        $ctrl.filterActions = true;
        hideDropdown();
      }

      /**
       * Abort current changes and restore the previously selected value from
       * the cache and used when Cancel button is clicked from the c-filter or
       * field-actions.
       *
       * NOTE: click outside will be captured in onDropdownToggle an naturally
       * we will perform restoring the value.
       *
       * @method   simpleFieldAbort
       */
      function simpleFieldAbort() {
        restoreSelectValue();
        hideDropdown();
      }

      /**
       * Apply the filter with selected value.
       *
       * @method   simpleFieldApply
       */
      function simpleFieldApply() {
        if ($ctrl.alwaysOpen) {
          // update the cache if filter is applied during always open mode.
          cacheSelectValue();
        } else {
          // burst the previously cached value.
          clearSelectValue();
        }

        hideDropdown();
      }

      /**
       * Clear the selected date range.
       *
       * @method   simpleFieldClear
       */
      function simpleFieldClear() {
        // resetting the model.
        $ctrl.ngModel.$setViewValue(null);

        // burst the previously cached value.
        clearSelectValue();

        // close the dropdown now.
        hideDropdown();
      }

      /**
       * Caches selected value used later to abort the modification.
       *
       * @method   cacheSelectValue
       */
      function cacheSelectValue() {
        previousValue = _.clone($ctrl.ngModel.$modelValue);
      }

      /**
       * Restore the selected value to its previous cached value and clear the
       * cache.
       *
       * @method   restoreSelectValue
       */
      function restoreSelectValue() {
        // nothing to restore when always open is set.
        if (!$ctrl.alwaysOpen) {
          $ctrl.ngModel.$setViewValue(previousValue);

          // burst the previously cached value.
          clearSelectValue();
        }
      }

      /**
       * Clear the cached previous value with date range default value.
       *
       * @method   clearSelectValue
       */
      function clearSelectValue() {
        previousValue = null;
      }

      /**
       * Return the selected value.
       *
       * @method   getSelectedValue
       * @returns  {Any}   Selected model value.
       */
      function getSelectedValue() {
        return $ctrl.ngModel.$modelValue;
      }

      /**
       * Initialize the setup by registering the c-field API interfaces used by
       * c-filter and c-field to interact with date-range.
       *
       * @method   initSetup
       */
      function initSetup() {
        $ctrl.cField.registerUiSelect(cFieldApi);
      }

      /**
       * Destroy the registration when date-range is destroyed.
       *
       * @method   destroySetup
       */
      function destroySetup() {
        $ctrl.cField.deRegisterUiSelect(cFieldApi);
      }

      // initializing the setup.
      initSetup();

      // returns a fn used to destroy the setup.
      return destroySetup;
    }
  }

})(angular);
