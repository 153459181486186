import { Injectable, NgZone } from '@angular/core';
import { GraphComponent } from 'yfiles';

/**
 * This service creates the yfiles GraphComponent object and allows it to be accessed from any location the service
 * is injectectedd to.
 */
@Injectable()
export class GraphComponentService {
  private graphComponent: GraphComponent;

  constructor(private zone: NgZone) {}

  /**
   * Gets or creates a yfiles graph component
   *
   * @returns the graph component
   */
  getGraphComponent(): GraphComponent {
    if (!this.graphComponent) {
      this.zone.runOutsideAngular(() => {
        this.graphComponent = new GraphComponent();
      });
    }
    return this.graphComponent;
  }
}
