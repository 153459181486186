import { Pipe, PipeTransform } from '@angular/core';
import { StorageDomain } from '@cohesity/api/v2';
import { AjsUpgradeService } from 'src/app/core/services/ajs-upgrade.service';

/**
 * This pipe used to provides a string value representing a storageDomain's redundancy settings
 *
 * @example
 *   {{ viewbox | ngViewBoxRedundancy}}     RF 1
 */
@Pipe({
  name: 'ngViewBoxRedundancy'
})
export class NgViewBoxRedundancyPipe implements PipeTransform {
  private $rootScope;

  constructor(
    private ajsUpgrade: AjsUpgradeService) {
    this.$rootScope = this.ajsUpgrade.get('$rootScope');
  }

  /**
   * Implements the pipe.
   *
   * @param   storageDomain   The storage domain object.
   * @return  storage domain redundancy status.
   */
  transform(storageDomain: StorageDomain): string {
    if (!storageDomain || !storageDomain.storagePolicy) {
      return '';
    }

    const {erasureCodingParams} = storageDomain.storagePolicy;

    if (!erasureCodingParams?.enabled) {
      // Hardcode to RF2 for physical robo clusters(ENG-121282).
      if (this.$rootScope.isPhysicalRobo) {
        return ('RF 2');
      }
      return ['RF ', storageDomain.storagePolicy?.numDiskFailuresTolerated + 1].join('');
    }

    return [
      'EC ',
      erasureCodingParams?.numDataStripes,
      ':',
      erasureCodingParams?.numCodedStripes,
    ].join('');

  }

}
