/**
 * Structure for holding privileges of a Helios Role
 */
export interface PrivilegesHash {
  accessManagement: any;
  helios: any;
  nonHelios: any;
  service: any;
}

/**
 * List of categories to be rendered for Helios.
 */
export const PrivilegeCategories = ['accessManagement', 'service', 'helios', 'nonHelios'];
