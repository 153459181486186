import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { CohesityHelixModule } from '@cohesity/helix';
import { CohesitySharedFormsModule } from '@cohesity/shared-forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { DialogService } from 'src/app/core/services';
import {
  ModifyNisDialogComponent,
} from 'src/app/modules/access-management/on-prem-access-management/nis/modify-nis-dialog/modify-nis-dialog.component';

import { NgUpgradeModule } from '../ng-upgrade';
import { PipesModule } from '../pipes';
import { ClusterInterfaceSelectorComponent } from './cluster-interface-selector/cluster-interface-selector.component';
import { ExternalTargetSelectorComponent } from './external-target-selector/external-target-selector.component';
import { NisProviderSelectorComponent } from './nis-provider-selector/nis-provider-selector.component';
import { ParentSourceSelectorComponent } from './parent-source-selector/parent-source-selector.component';
import { ReplicationTargetSelectorComponent } from './replication-target-selector/replication-target-selector.component';
import { StorageDomainSelectorComponent } from './storage-domain-selector/storage-domain-selector.component';
import { StorageDomainSynopsisComponent } from './storage-domain-synopsis/storage-domain-synopsis.component';
import { UserAtDomainPickerComponent } from './user-at-domain-picker/user-at-domain-picker.component';
import { ViewQosPolicySelectorComponent } from './view-qos-policy-selector/view-qos-policy-selector.component';
import { ViewSelectionComponent } from './view-selection/view-selection.component';
import { ViewSelectorComponent } from './view-selector/view-selector.component';

const COMPONENTS = [
  ClusterInterfaceSelectorComponent,
  ExternalTargetSelectorComponent,
  ModifyNisDialogComponent,
  NisProviderSelectorComponent,
  ParentSourceSelectorComponent,
  ReplicationTargetSelectorComponent,
  StorageDomainSelectorComponent,
  StorageDomainSynopsisComponent,
  UserAtDomainPickerComponent,
  ViewQosPolicySelectorComponent,
  ViewSelectionComponent,
  ViewSelectorComponent,
];

/**
 * Module for forms related components. This includes components for working with
 * specific types of data (TimePeriodSelector), and components for displaying
 * a single piece of data in a custom form control (TimePicker).
 */
@NgModule({
  imports: [
    CohesityHelixModule,
    CohesitySharedFormsModule,
    CommonModule,
    MatAutocompleteModule,
    NgUpgradeModule,
    NgxMatSelectSearchModule,
    PipesModule,
    ReactiveFormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
    ScrollingModule,
    TranslateModule.forChild(),
  ],
  providers: [DialogService],
  declarations: [COMPONENTS],
  exports: [...COMPONENTS, CohesitySharedFormsModule, MatAutocompleteModule, ReactiveFormsModule],
})
export class FormsModule {}
