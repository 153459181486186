<mat-form-field [class.mat-form-field-invalid]="ngControl.dirty && ngControl.invalid">
  <mat-label>{{label}}</mat-label>
  <mat-select panelClass="cog-mat-select-panel-lg"
    id="{{idPrefix}}-select"
    cogDataId="select"
    [inheritDataId]="true"
    (valueChange)="onSelect($event)"
    [value]="value"
    (openedChange)="openedChange($event)"
    [disabled]="readonly">
    <mat-select-trigger *ngIf="value">
      {{value?.vaultName}}
    </mat-select-trigger>
    <mat-option *ngFor="let vault of vaults$ | async"
      id="{{idPrefix}}-option-{{vault.name}}"
      cogDataId="option-{{vault.name}}"
      [inheritDataId]="true"
      [value]="vault._target">
      <div *ngIf="!cloudEditionEnabled || ['archive', 'viewbox'].includes(context)">
        <ng-container *ngTemplateOutlet="vaultTemplate; context:{vault: vault}"></ng-container>
      </div>
      <div *ngIf="cloudEditionEnabled && !context">
        <ng-container *ngTemplateOutlet="vaultViewboxTemplate; context:{vault: vault}"></ng-container>
      </div>
      <ul *ngIf="restrictToUsageType === 'Rpaas'" class="c-ul-inline meta-data">
        <li *ngIf="vault.archivalParams?.awsParams?.region">
          {{ vault.archivalParams?.awsParams?.region | translate }}
        </li>
        <li *ngIf="vault.archivalParams?.azureParams?.region">
          {{ vault.archivalParams?.azureParams?.region | translate }}
        </li>
        <li *ngIf="showStorageClass && rpaasStorageClass[vault.archivalParams?.awsParams?.storageClass]">
          {{ rpaasStorageClass[vault.archivalParams?.awsParams?.storageClass] | translate }}
        </li>
        <li *ngIf="showStorageClass && rpaasStorageClass[vault.archivalParams?.azureParams?.storageClass]">
          {{ rpaasStorageClass[vault.archivalParams?.azureParams?.storageClass] | translate }}
        </li>
      </ul>
    </mat-option>
    <mat-option #add (click)="!add.disabled && onAdd()"
      *ngIf="showExternalTargetOption"
      id="{{idPrefix}}-register-external-target"
      cogDataId="register-external-target"
      [inheritDataId]="true"
      [disabled]="!('CLUSTER_EXTERNAL_TARGET_MODIFY' | userPrivileges)">
      <coh-add-item-option>
        {{'registerExternalTarget' | translate}}
      </coh-add-item-option>
    </mat-option>

  </mat-select>
</mat-form-field>
<mat-error *ngIf="ngControl.touched && ngControl.invalid">{{'errors.required' | translate}}</mat-error>

<ng-template #vaultTemplate let-vault="vault">
  <strong>{{vault.name}}</strong>
    <ul class="c-ul-inline meta-data"
      *ngIf="restrictToUsageType !== 'Rpaas' && (vault._vaultName || vault._name || vault._typeKey)">
      <li>
        {{ vaultSubText(vault, false) }}
      </li>
      <li *ngIf="vault.enableObjectLock">
        <cog-icon class="object-lock-icon" shape="lock" size="xs"></cog-icon>
        {{'archiveObjectLockEnabled' | translate}}
      </li>
    </ul>
</ng-template>

<ng-template #vaultViewboxTemplate let-vault="vault">
  <strong>{{ vaultStorageDomainName(vault) }}</strong>
    <ul class="c-ul-inline meta-data"
      *ngIf="restrictToUsageType !== 'Rpaas' && (vault._vaultName || vault._name || vault.name)">
      <li>
        {{ vaultSubText(vault, true) }}
      </li>
    </ul>
</ng-template>
