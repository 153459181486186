<mat-form-field>
  <mat-label>{{ filterLabel }} </mat-label>
  <mat-chip-list #chipList>
    <ng-container *ngFor="let tag of value; index as i; last as isLast">
      <mat-chip id="filter-tag-{{i}}"
        [selectable]="true"
        [removable]="true"
        (removed)="remove(i)">
        {{tag.name}}
        <!-- Mat Icon aligns better inside of mat-chip then cog-icon does -->
        <mat-icon id="filter-tag-remove-{{i}}" matChipRemove>cancel</mat-icon>
      </mat-chip>
      <span class="and-chip" *ngIf="!isLast">{{'and' | translate}}</span>
    </ng-container>

    <input #tagInput
      id="tag-input"
      [placeholder]="placeHolderText"
      [formControl]="searchControl"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      [matChipInputAddOnBlur]="true"
      (matChipInputTokenEnd)="add($event)">
    <mat-icon cogDataId="filter-tag-dropdown"
      class="tag-input-caret"
      (click)="tagInput.focus()">arrow_drop_down</mat-icon>

    <mat-autocomplete #auto="matAutocomplete"
      (optionSelected)="selectTags($event.option.value)">
      <mat-optgroup *ngFor="let category of filteredCategories$ | async">
        <span class="group-label">{{category.name}}</span>
        <mat-option *ngFor="let tags of category.tagGroups" [value]="tags">
          <span matTooltip="{{getTagGroupLabel(tags)}}">
            {{getTagGroupLabel(tags)}}
          </span>
        </mat-option>
      </mat-optgroup>
    </mat-autocomplete>
  </mat-chip-list>
</mat-form-field>
