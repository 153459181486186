import { Component, OnInit } from '@angular/core';
import { NavStateService, NavState, RouteNavState } from 'src/app/shared/';

/**
 * This component displays the recovery button, but filters out any recovery actions that are not officially supported
 * by FortKnox workflows. This is not intended to block user's access to the recovery states from other pages.
 */
@Component({
  selector: 'coh-rpaas-recover-button',
  templateUrl: './rpaas-recover-button.component.html',
})
export class RpaasRecoverButtonComponent implements OnInit {

  /**
   * The recovery nav item service does not track recovery actions or environments, it only uses state names. This is a
   * list of state names for recovery actions that FortKnox supports. Some of these pages, may allow users to recover
   * objects from environments not supported by FortKnox, but that is ok for what we need here.
   */
  private allowedStates = [
    'clone-view.search',
    'migrate-vm-ng',
    'recover-db',
    'recover-files',
    'recover-mount-point',
    'recover-physical-server',
    'recover-storage-volume',
    'recover-vm',
    'recover-vmdk',
  ];

  /**
   * A filtered list of recovery items supported by FortKnox
   */
  recoveryItems: NavState[];


  constructor(private navStateService: NavStateService) {

  }
  ngOnInit(): void {
    // Get the original nav list
    const items = this.navStateService.getRecoverStates();

    // Remove unsupported actions from subItem lists
    items.filter((item: RouteNavState) => !!item.subStates?.length)
      .forEach((item: RouteNavState) => item.subStates = item.subStates &&
        item.subStates.filter((subItem: RouteNavState) => this.allowedStates.includes(subItem.state))
      );

    // Filter the top level list.
    this.recoveryItems = items.filter((item: RouteNavState) =>
      !item.state ||
      this.allowedStates.includes(item.state) ||
      item.subStates?.length > 0
    );
  }
}
