import {
  Component,
  Directive,
  ElementRef,
  Input,
  OnChanges,
  Renderer2,
  ViewContainerRef,
  ComponentRef,
  OnDestroy,
} from '@angular/core';

/**
 * Simple spinner component needs to be declared as an entry component so it can be added to
 */
@Component({
  template: `
    <cog-spinner size="xs"></cog-spinner>
  `,
})
export class SubmitSpinnerComponent {}

/**
 * Add consistent styling and loading behavior to a submit button. Note, the button still
 * must have the mat-button property applied for this to work.
 *
 * @example
 * <button mat-button cogSubmitButton loading="true">Submit</button>
 */
@Directive({
  selector: 'button [cogSubmitButton]',
})
export class SubmitButtonDirective implements OnChanges, OnDestroy {
  /**
   * Use this to hide or show the loading spinner.
   */
  @Input() loading = false;

  /**
   * The button's element.
   */
  nativeElement: HTMLElement;

  /**
   * The component ref for the spinner.
   */
  spinnerRef: ComponentRef<SubmitSpinnerComponent>;

  /**
   * Get's the native element for the spinner component.
   */
  get spinnerElement(): HTMLElement {
    return this.spinnerRef && this.spinnerRef.location.nativeElement;
  }

  constructor(
    elementRef: ElementRef,
    private renderer: Renderer2,
    private viewContainerRef: ViewContainerRef,
  ) {
    this.nativeElement = elementRef.nativeElement as HTMLElement;
    this.renderer.addClass(this.nativeElement, 'mat-flat-button');
    this.renderer.addClass(this.nativeElement, 'mat-primary');
    this.renderer.setAttribute(this.nativeElement, 'type', 'submit');
  }

  /**
   * Add the loading spinner to the button.
   */
  addSpinner() {
    if (this.spinnerRef) {
      return;
    }

    this.spinnerRef = this.viewContainerRef.createComponent(SubmitSpinnerComponent);
    this.renderer.appendChild(this.nativeElement, this.spinnerElement);

    // Hide the text without having the button width change.
    this.renderer.setStyle(this.nativeElement, 'color', 'transparent');

    // Position the inserted component to fill the avilable space so the spinner will
    // be centered inside.
    this.renderer.setStyle(this.spinnerElement, 'position', 'absolute');
    this.renderer.setStyle(this.spinnerElement, 'left', 0);
    this.renderer.setStyle(this.spinnerElement, 'right', 0);
    this.renderer.setStyle(this.spinnerElement, 'top', '50%');
    this.renderer.setStyle(this.spinnerElement, 'transform', 'translateY(-50%)');
  }

  /**
   * Cleanup and remove the spinner.
   */
  cleanupSpinner() {
    if (!this.spinnerRef) {
      return;
    }
    this.renderer.removeChild(this.nativeElement, this.spinnerElement);
    this.renderer.removeStyle(this.nativeElement, 'color');
    this.spinnerRef.destroy();
    this.spinnerRef = undefined;
  }


  /**
   * Update the spinner when the loading flag changes.
   */
  ngOnChanges() {
    if (this.loading && !this.spinnerElement) {
      this.addSpinner();
    }

    if (!this.loading && this.spinnerElement) {
      this.cleanupSpinner();
    }
  }

  /**
   * Clean up the spinner when the component is destroyed.
   */
  ngOnDestroy() {
    this.cleanupSpinner();
  }
}
