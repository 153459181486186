// Controller: Delete Tenant Modal

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.tenants')
    .controller('TenantDeleteModalCtrl', TenantDeleteModalCtrlFn);

  function TenantDeleteModalCtrlFn($scope, $uibModalInstance, TenantService,
    tenant, evalAJAX, cMessage) {

    // Override the ok method of the cModal to leverage $scope.submitting
    // and delete the tenant gracefully.
    $scope.ok = function ok() {
      $scope.submitting = true;

      TenantService.deleteTenant(tenant)
        .then(function deleteSuccess() {
          cMessage.success({
            textKey: 'tenants.removeTenantSuccess',
            textKeyContext: tenant,
          });

          // Close the modal when tenant is successfully deleted.
          $uibModalInstance.close();
        }, evalAJAX.errorMessage)
        .finally(function deleteFinally() {
          $scope.submitting = false;
        });
    };
  }

})(angular);