// MODULE: Policy Delete Modal Service
//
// Presents and resolves a delete confirmation modal. For maximum flexibility,
// actual deleting of the policy is to be handled by the calling controller

;(function(angular) {
    'use strict';

    angular.module('C.policies').service('DeletePolicyModalService', DeletePolicyModalServiceFn);

    DeletePolicyModalServiceFn.$inject = ['$uibModal'];
    function DeletePolicyModalServiceFn($uibModal) {

        var currentPolicy;

        var modalConfig = {
            backdrop: 'state',
            keyboard: true,
            modalFade: true,
            templateUrl: 'app/protection/policies/modals/policy-delete-modal.html',
            controller: modalControllerFn,
            resolve: {
                policy: function resolveFn() {
                    return currentPolicy;
                }
            }
        };

        modalControllerFn.$inject = ['$rootScope', '$scope', 'PolicyService', 'JobRunsService', 'evalAJAX', '$uibModalInstance', 'policy'];
        function modalControllerFn($rootScope, $scope, PolicyService, JobRunsService, evalAJAX, $uibModalInstance, policy) {

            /**
             * Text object for template/view
             *
             * @type {Object}
             */
            $scope.text = $rootScope.text.protectionPoliciesModalsPolicyDeleteModal;

            /**
             * function to run when user confirms delete,
             * calls API to delete the policy and
             * resolves the modal instance upon successful deletion
             *
             * @return {Void}
             */
            $scope.deletePolicy = function deletePolicy() {
                $scope.deleting = true;
                PolicyService.deletePolicy($scope.policy.id).then(
                    function deletePolicySuccess() {
                        $uibModalInstance.close(true);
                    },
                    evalAJAX.errorMessage
                ).finally(
                    function delFinally() {
                        $scope.deleting = false;
                    }
                );
            };

            /**
             * function to run when user cancels the deletion, dismisses the modal instance
             *
             * @return {Void}
             */
            $scope.cancel = function cancel() {
                $uibModalInstance.dismiss('cancel');
            };

            /**
             * initilizes the modal, checking for Jobs that
             * are currently associated with the Policy to be
             * deleted
             */
            function init() {
                $scope.policy = policy;
                $scope.policyInfoLoaded = false;
                $scope.policyJobs = [];

                JobRunsService.getPolicyJobs(policy.id).then(
                    function getPolicyJobsSuccess(policyJobs) {
                        $scope.policyJobs = policyJobs;
                    },
                    evalAJAX.errorMessage
                ).finally(
                    function getPolicyJobsFinally() {
                        $scope.policyInfoLoaded = true;
                    }
                );
            }

            init();
        }

        /**
         * PolicyDeleteModalService.open() used to pop the modal confirmation.
         *
         * @param      {object}   policy  The policy to be deleted
         * @return     {promise}  promise to resolve the modal
         */
        this.open = function open(policy) {
            currentPolicy = policy;
            return $uibModal.open(modalConfig).result;
        };
    }

}(angular));
