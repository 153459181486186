<ul class="{{'c-ul-inline no-margin' + (compact ? ' meta-data synopsis' : '')}}" cogDataId="source-metadata-{{nodeName}}">
  <li *ngIf="showSizeData" [innerHTML]="logicalSize | byteSize"></li>
  <li *ngIf="provisionedBytes">{{provisionedBytes | byteSize}} {{'provisioned' | translate}}</li>
  <li *ngIf="leafCount !== undefined">{{leafCountKey | translate: {leaves: leafCount | number} }}</li>
  <li *ngIf="volumeType">
    {{'sourceTree.metadata.volumeType.'+ volumeType | translate}}
  </li>
  <li *ngIf="channelCount">
    {{'sourceTree.metadata.channelCount' | translate: {count: channelCount | number} }}
  </li>
  <li *ngIf="recordCount">
    {{'sourceTree.metadata.recordCount' | translate: {count: recordCount | number} }}
  </li>
  <li *ngIf="usedSfdcUserLicenses">
    {{'sourceTree.metadata.sfdcUserLicenses' | translate: {count: usedSfdcUserLicenses | number} }}
  </li>
  <li *ngIf="dataProtocols && dataProtocols.length > 0">
    {{dataProtocols | simpleList: {translate: true} }}
  </li>
  <li *ngIf="extendedStyle">
    {{ extendedStyle | translate}}
  </li>
  <li *ngIf="serialNumber"><small>{{serialNumber}}</small></li>
  <li *ngIf="tenantOwner"
    innerHTML="{{'entityAssignedToOrganization' | translate : {name: tenantOwner} }}">
  </li>
  <li *ngIf="userOwners?.length === 1"
    innerHTML="{{'entityAssignedToUser' | translate : {name: userOwners[0]} }}">
  </li>
  <li *ngIf="userOwners?.length > 1"
      innerHTML="{{'entityAssignedToUsers' | translate : {name: userOwners | simpleList} }}">
  </li>
  <li *ngIf="groupOwners?.length === 1"
    innerHTML="{{'entityAssignedToGroup' | translate : {name: groupOwners[0]} }}">
  </li>
  <li *ngIf="groupOwners?.length > 1"
      innerHTML="{{'entityAssignedToGroups' | translate : {name: groupOwners | simpleList} }}">
  </li>
</ul>
<coh-source-meta [node]="nodeContext.node.data"></coh-source-meta>
