import { Pipe, PipeTransform } from '@angular/core';

/**
 * orgIdSplit pipe can be leveraged to remove trailing slash ("/") from orgId
 *
 * Example Usage
 * If user has tenantId as 'testorg/'
 * <div>{{user.tenantId | orgIdDistilled}}<div> // testorg
 */
@Pipe({
  name: 'orgIdDistilled'
})
export class OrgIdDistilledPipe implements PipeTransform {

  transform(value: string): string {
    return value ? (value.split('/')[0] || value) : '';
  }

}
