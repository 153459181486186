/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityV2ApiConfiguration as __Configuration } from '../cohesity-v2api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Ldaps } from '../models/ldaps';
import { Ldap } from '../models/ldap';
import { LdapStatus } from '../models/ldap-status';
@Injectable({
  providedIn: 'root',
})
class LDAPService extends __BaseService {
  static readonly GetLdapsPath = '/ldap';
  static readonly UpdateLdapProviderPath = '/ldap';
  static readonly CreateLdapProviderPath = '/ldap';
  static readonly DeleteLdapProviderPath = '/ldap/{id}';
  static readonly GetLdapConnectionStatusPath = '/ldap/{id}/connection-status';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get Groups.
   *
   * Get LDAPs.
   * @param params The `LDAPService.GetLdapsParams` containing the following parameters:
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which LDAPs are to be returned.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `includeTenants`: IncludeTenants specifies if LDAPs of all the tenants under the hierarchy of the logged in user's organization should be returned.
   *
   * - `ids`: Specifies a list of ids to filter.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetLdapsResponse(params: LDAPService.GetLdapsParams): __Observable<__StrictHttpResponse<Ldaps>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.tenantIds || []).forEach(val => {if (val != null) __params = __params.append('tenantIds', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.includeTenants != null) __params = __params.set('includeTenants', params.includeTenants.toString());
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/ldap`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Ldaps>;
      })
    );
  }
  /**
   * Get Groups.
   *
   * Get LDAPs.
   * @param params The `LDAPService.GetLdapsParams` containing the following parameters:
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which LDAPs are to be returned.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `includeTenants`: IncludeTenants specifies if LDAPs of all the tenants under the hierarchy of the logged in user's organization should be returned.
   *
   * - `ids`: Specifies a list of ids to filter.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetLdaps(params: LDAPService.GetLdapsParams): __Observable<Ldaps> {
    return this.GetLdapsResponse(params).pipe(
      __map(_r => _r.body as Ldaps)
    );
  }

  /**
   * Update Ldap provider.
   *
   * Modify Ldap provider with given parameters.
   * @param params The `LDAPService.UpdateLdapProviderParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to update Ldap provider.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateLdapProviderResponse(params: LDAPService.UpdateLdapProviderParams): __Observable<__StrictHttpResponse<Ldap>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/ldap`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Ldap>;
      })
    );
  }
  /**
   * Update Ldap provider.
   *
   * Modify Ldap provider with given parameters.
   * @param params The `LDAPService.UpdateLdapProviderParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to update Ldap provider.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateLdapProvider(params: LDAPService.UpdateLdapProviderParams): __Observable<Ldap> {
    return this.UpdateLdapProviderResponse(params).pipe(
      __map(_r => _r.body as Ldap)
    );
  }

  /**
   * Create Ldap provider.
   *
   * Create Ldap provider with given parameters.
   * @param params The `LDAPService.CreateLdapProviderParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to create Ldap provider.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateLdapProviderResponse(params: LDAPService.CreateLdapProviderParams): __Observable<__StrictHttpResponse<Ldap>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/ldap`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Ldap>;
      })
    );
  }
  /**
   * Create Ldap provider.
   *
   * Create Ldap provider with given parameters.
   * @param params The `LDAPService.CreateLdapProviderParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to create Ldap provider.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateLdapProvider(params: LDAPService.CreateLdapProviderParams): __Observable<Ldap> {
    return this.CreateLdapProviderResponse(params).pipe(
      __map(_r => _r.body as Ldap)
    );
  }

  /**
   * Delete LDAP provider.
   *
   * Delete LDAP provider which will be identified by given Id.
   * @param params The `LDAPService.DeleteLdapProviderParams` containing the following parameters:
   *
   * - `id`: Specifies the LDAP Id.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteLdapProviderResponse(params: LDAPService.DeleteLdapProviderParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/ldap/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete LDAP provider.
   *
   * Delete LDAP provider which will be identified by given Id.
   * @param params The `LDAPService.DeleteLdapProviderParams` containing the following parameters:
   *
   * - `id`: Specifies the LDAP Id.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteLdapProvider(params: LDAPService.DeleteLdapProviderParams): __Observable<null> {
    return this.DeleteLdapProviderResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get LDAP connection status.
   *
   * Get LDAP connection status.
   * @param params The `LDAPService.GetLdapConnectionStatusParams` containing the following parameters:
   *
   * - `id`: Specifies the LDAP id.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetLdapConnectionStatusResponse(params: LDAPService.GetLdapConnectionStatusParams): __Observable<__StrictHttpResponse<LdapStatus>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/ldap/${encodeURIComponent(params.id)}/connection-status`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LdapStatus>;
      })
    );
  }
  /**
   * Get LDAP connection status.
   *
   * Get LDAP connection status.
   * @param params The `LDAPService.GetLdapConnectionStatusParams` containing the following parameters:
   *
   * - `id`: Specifies the LDAP id.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetLdapConnectionStatus(params: LDAPService.GetLdapConnectionStatusParams): __Observable<LdapStatus> {
    return this.GetLdapConnectionStatusResponse(params).pipe(
      __map(_r => _r.body as LdapStatus)
    );
  }
}

module LDAPService {

  /**
   * Parameters for GetLdaps
   */
  export interface GetLdapsParams {

    /**
     * TenantIds contains ids of the tenants for which LDAPs are to be returned.
     */
    tenantIds?: Array<string>;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * IncludeTenants specifies if LDAPs of all the tenants under the hierarchy of the logged in user's organization should be returned.
     */
    includeTenants?: boolean;

    /**
     * Specifies a list of ids to filter.
     */
    ids?: Array<number>;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateLdapProvider
   */
  export interface UpdateLdapProviderParams {

    /**
     * Specifies the parameters to update Ldap provider.
     */
    body: Ldap;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CreateLdapProvider
   */
  export interface CreateLdapProviderParams {

    /**
     * Specifies the parameters to create Ldap provider.
     */
    body: Ldap;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for DeleteLdapProvider
   */
  export interface DeleteLdapProviderParams {

    /**
     * Specifies the LDAP Id.
     */
    id: number;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetLdapConnectionStatus
   */
  export interface GetLdapConnectionStatusParams {

    /**
     * Specifies the LDAP id.
     */
    id: number;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { LDAPService }
