import { Injectable } from '@angular/core';
import { Transition } from '@uirouter/core';
import { map } from 'rxjs/operators';
import { ClusterService, PlatformService, UserService } from 'src/app/core/services';
import { LocalStorageFlashRecoverRegistrationKey } from 'src/app/modules/admin/cluster/remote-disks/onboarding';
import { AjsClusterService } from 'src/app/shared';

import { GuardResult, StateGuard } from '../state-guard';
import { GuardPriority } from './../state-guard';

/**
 * This guard runs for dashboard pages to redirect admin user to register flashblade
 * for remote storage for PXG1 platform which is part of FlashRecover Solution.
 */
@Injectable({
  providedIn: 'root',
})
export class FlashRecoverOnboardingGuard implements StateGuard {
  /**
   * App level guard priority.
   */
  guardPriority = GuardPriority.App;

  /**
   * Match for all the incoming states except few states which have higher precedence.
   */
  matchCriteria: any = {
    to: state => !([
      'login',
      'change-password',
      'eulaNew',
      'flashrecover-onboarding',
      'flashrecover-onboarding.landing',
      'remote-disks.list'
    ].includes(state.name)),
  };
  constructor(
    private userService: UserService,
    private ajsClusterService: AjsClusterService,
    private clusterService: ClusterService,
    private platformService: PlatformService
  ) { }

  /**
   * This is run on the onStart ui router transition.
   */
  onStart(transition: Transition): GuardResult {
    const user = this.userService.user;
    const flashRecoverSkipped = sessionStorage.getItem(LocalStorageFlashRecoverRegistrationKey) || undefined;

    // Return true if:
    //  a. It is helios
    //  b. User is not local admin user
    //  c. Password change is true so that app gets redirected to change-password page first.
    //  d. it is not PXG1 cluster or flashRecover is skipped.
    if (this.clusterService.isMcm ||
      !this.userService.loginData?.isNodeInCluster ||
        !this.userService.isLocalAdminUser() ||
        this.ajsClusterService.isNewCluster() ||
        user.forcePasswordChange ||
        !this.ajsClusterService.clusterInfo._isDisaggregatedStorage ||
        flashRecoverSkipped) {
      return true;
    }

    return this.platformService.getRegisteredRemoteStorages().pipe(
      map((remoteStorages) => {
        if (remoteStorages?.remoteStorages?.length) {
          return true;
        } else {
          return transition.router.stateService.target('flashrecover-onboarding.landing', {force: true});
        }
      })
    );

  }
}
