import { ReportSection } from '../iris-reporting.model';

/**
 * This is the layout config for the protected object report.
 */
export const m365BackupStatusReport: ReportSection[] = [
  {
    title: 'reporting.summary',
    items: [
      {
        id: 'statlistItem',
        componentIds: ['2101'],
        componentNames: ['M365ComplianceGlanceBar'],
        params: {
          items: [
            {
              title: 'reporting.successRate',
              translate: true,
              showChart: true,
              measurement: {
                valueKey: 'countGroupId',
                dataType: 'percent',
                filters: [
                  {
                    filterKey: 'backupStatus',
                    value: 'kSuccess',
                  },
                ],
                aggregation: 'percent',
              },
            },
            {
              title: 'reporting.totalProtectedObjects',
              translate: true,
              measurement: {
                valueKey: 'countGroupId',
                dataType: 'number',
              }
            },
            {
              title: 'reporting.success',
              translate: true,
              measurement: {
                valueKey: 'countGroupId',
                dataType: 'number',
                filter: {
                  filterKey: 'backupStatus',
                  value: 'kSuccess',
                },
              },
            },
            {
              title: 'reporting.warning',
              translate: true,
              measurement: {
                valueKey: 'countGroupId',
                dataType: 'number',
                filter: {
                  filterKey: 'backupStatus',
                  value: 'kWarning',
                },
              },
            },
            {
              title: 'reporting.failed',
              translate: true,
              measurement: {
                valueKey: 'countGroupId',
                dataType: 'number',
                filter: {
                  filterKey: 'backupStatus',
                  value: 'kFailure',
                },
              },
            },
            {
              title: 'reporting.canceled',
              translate: true,
              measurement: {
                valueKey: 'countGroupId',
                dataType: 'number',
                filter: {
                  filterKey: 'backupStatus',
                  value: 'kCanceled',
                },
              },
            },
            {
              title: 'reporting.skipped',
              translate: true,
              measurement: {
                valueKey: 'countGroupId',
                dataType: 'number',
                filter: {
                  filterKey: 'backupStatus',
                  value: 'kSkipped',
                },
              },
            },
            {
              title: 'reporting.running',
              translate: true,
              measurement: {
                valueKey: 'countGroupId',
                dataType: 'number',
                filter: {
                  filterKey: 'backupStatus',
                  value: 'kRunning',
                },
              },
            },
          ],
        },
      },
    ],
  },
  {
    title: 'Backup Success Rate Overview',
    isFullData: false,
    items: [
      {
        id: 'tableItem',
        componentIds: ['2102'],
        componentNames: ['M365ComplianceSummary'],
        params: {
          hideFilter: true,
          columns: [
            {
              title: 'apps',
              translate: true,
              key: 'protectionEnvName',
              primary: {
                key: 'protectionEnvName',
                dataType: 'environmentName'
              },
            },
            {
              title: 'last24Hours',
              translate: true,
              key: 'last24Hours',
              primary: {
                key: 'last24Hours',
                dataType: 'text',
              },
            },
            {
              title: 'last48Hours',
              translate: true,
              key: 'last48Hours',
              primary: {
                key: 'last48Hours',
                dataType: 'text',
              },
            },
            {
              title: 'last72Hours',
              translate: true,
              key: 'last72Hours',
              primary: {
                key: 'last72Hours',
                dataType: 'text',
              },
            },
          ],
        },
      },
    ],
  },
  {
    items: [
      {
        id: 'chartItem',
        componentIds: ['2103'],
        componentNames: ['M365ComplianceChartMailbox'],
        title: 'reporting.backupStatusByMailbox',
        params: {
          isLegendClickable: true,
          customFilterOptions: {
            protectionEnvName: 'kO365Exchange'
          },
          showPercentInLegend: true,
          componentDescription: {
            description: 'reporting.totalObjectsCount',
            valueKey: 'countGroupId',
          },
          chartType: 'pie',
          measurements: [
            {
              valueKey: 'countGroupId',
              filter: {
                filterKey: 'protectionEnvName',
                value: 'kO365Exchange',
              },
            },
          ],
          dimensions: [
            {
              dimensionKey: 'backupStatus',
            },
          ],
        },
      },
      {
        id: 'chartItem',
        componentIds: ['2104'],
        componentNames: ['M365ComplianceChartOneDrive'],
        title: 'reporting.backupStatusByOneDrive',
        params: {
          isLegendClickable: true,
          customFilterOptions: {
            protectionEnvName: 'kO365OneDrive'
          },
          showPercentInLegend: true,
          componentDescription: {
            description: 'reporting.totalObjectsCount',
            valueKey: 'countGroupId',
          },
          chartType: 'pie',
          measurements: [
            {
              valueKey: 'countGroupId',
              filter: {
                filterKey: 'protectionEnvName',
                value: 'kO365OneDrive',
              }
            },
          ],
          dimensions: [
            {
              dimensionKey: 'backupStatus',
            },
          ],
        },
      },
    ],
  },
  {
    items: [
      {
        id: 'chartItem',
        componentIds: ['2105'],
        componentNames: ['M365ComplianceChartSite'],
        title: 'reporting.backupStatusBySharePoint',
        params: {
          isLegendClickable: true,
          customFilterOptions: {
            protectionEnvName: 'kO365Sharepoint'
          },
          showPercentInLegend: true,
          componentDescription: {
            description: 'reporting.totalObjectsCount',
            valueKey: 'countGroupId',
          },
          chartType: 'pie',
          measurements: [
            {
              valueKey: 'countGroupId',
              filter: {
                filterKey: 'protectionEnvName',
                value: 'kO365Sharepoint',
              },
            },
          ],
          dimensions: [
            {
              dimensionKey: 'backupStatus',
            },
          ],
        },
      },
      {
        id: 'chartItem',
        componentIds: ['2106'],
        componentNames: ['M365ComplianceChartTeams'],
        title: 'reporting.backupStatusByTeams',
        params: {
          isLegendClickable: true,
          customFilterOptions: {
            protectionEnvName: 'kO365Teams'
          },
          showPercentInLegend: true,
          componentDescription: {
            description: 'reporting.totalObjectsCount',
            valueKey: 'countGroupId',
          },
          chartType: 'pie',
          measurements: [
            {
              valueKey: 'countGroupId',
              filter: {
                filterKey: 'protectionEnvName',
                value: 'kO365Teams',
              }
            },
          ],
          dimensions: [
            {
              dimensionKey: 'backupStatus',
            },
          ],
        },
      },
    ],
  },
  {
    items: [
      {
        id: 'chartItem',
        componentIds: ['2107'],
        componentNames: ['M365ComplianceChartGroups'],
        title: 'reporting.backupStatusByGroups',
        params: {
          isLegendClickable: true,
          customFilterOptions: {
            protectionEnvName: 'kO365Group'
          },
          showPercentInLegend: true,
          componentDescription: {
            description: 'reporting.totalObjectsCount',
            valueKey: 'countGroupId',
          },
          chartType: 'pie',
          measurements: [
            {
              valueKey: 'countGroupId',
              filter: {
                filterKey: 'protectionEnvName',
                value: 'kO365Group',
              },
            },
          ],
          dimensions: [
            {
              dimensionKey: 'backupStatus',
            },
          ],
        },
      },
      {
        id: 'chartItem',
        componentNames: []
      }
    ],
  },
  {
    title: 'reporting.data',
    isFullData: true,
    items: [
      {
        id: 'tableItem',
        componentIds: ['2100'],
        componentNames: ['M365ComplianceData'],
        params: {
          columns: [
            {
              title: 'objectName',
              translate: true,
              key: 'objectName',
              icon: {
                key: 'protectionEnvName',
                dataType: 'objectTypeIcon',
                renderParams: {
                  environmentKey: 'environment',
                },
              },
              primary: {
                key: 'objectName',
                dataType: 'text',
                renderParams: {
                  width: 'lg',
                },
              },
              subtitle: {
                key: 'protectionEnvName',
                dataType: 'environmentName',
              },
            },
            {
              title: 'source',
              translate: true,
              key: 'sourceName',
              primary: {
                key: 'sourceName',
                dataType: 'text',
                renderParams: {
                  width: 'lg',
                },
              },
            },
            {
              title: 'system',
              translate: 'true',
              enabled: false,
              key: 'system',
              primary: {
                key: 'system',
                dataType: 'text',
              },
            },
            {
              title: 'lastRun',
              translate: 'true',
              key: 'lastRunTime',
              icon: {
                key: 'backupStatus',
                dataType: 'statusIcon',
              },
              primary: {
                key: 'lastRunTime',
                dataType: 'date',
                renderParams: {
                  usecs: true,
                },
              },
            },
            {
              title: 'message',
              translate: 'true',
              key: 'details',
              primary: {
                key: 'details',
                dataType: 'text',
                renderParams: {
                  width: 'xl',
                },
              },
            },
          ],
        },
      },
    ],
  },
];
