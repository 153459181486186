// Component: Create Job Button

;(function(angular, undefined) {

  angular.module('C.createJobButton', ['C.contextMenu'])
    .component('createJobButton', {
      templateUrl: 'app/global/c-job-button/create-job-button.html',
      controller: createJobButtonFn,
      bindings: {
        // button label
        label: '<?',
      },
    });

  function createJobButtonFn($attrs, NavStateService, SHARED_CLASSNAME) {

    var $ctrl = this;

    $ctrl.$onInit = function onInit() {
      $ctrl.jobTypeItems = NavStateService.getProtectStates();
      $ctrl.label = $ctrl.label || 'protect';

      // add a common class on this component which is helpful to add
      // separator margin between subsequest similar/same components
      $attrs.$addClass(SHARED_CLASSNAME.contextBtn);
    };

  }
})(angular);
