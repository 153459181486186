import { AppModuleConfig, CanAccess, StateAccessContext, StateAccessMap } from 'src/app/app-module.config';

const cloneAccess: CanAccess = (ctx: StateAccessContext) => ctx.FEATURE_FLAGS.ngTestAndDev;

export const CloneConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'clone.**',
      url: '^/clone',
      loadChildren: () => import('./clone.module').then(m => m.CloneModule)
    },
  ],

  allClusterMap: {
    'clone': {
      globalContext: true,
    },
    'clone.task-list': {
      globalContext: true,
    },
    'clone.details': {
      globalContext: true,
    },
    'clone.options': {
      globalContext: true,
    },
    'clone.view': {
      globalContext: true,
    },
    'clone.vms': {
      globalContext: true,
    },
  },

  getStateAccessMap(): StateAccessMap {
    return {
      'clone': cloneAccess,
      'clone.task-list': cloneAccess,
      'clone.details': cloneAccess,
      'clone.options': cloneAccess,
      'clone.view': cloneAccess,
      'clone.vms': cloneAccess,
    };
  }
};
