<div class="thermometer-chart-wrapper">
  <!-- Sub Label -->
  <div class="thermometer-label-container margin-right-sm">
    <div *ngFor="let data of chartData" class="thermometer-chart-label"
      [style.flex-grow]="data.percent/100">
      <small class="sub-label">{{data.subLabel}}</small>
    </div>
  </div>

  <!-- Chart -->
  <div class="thermometer-chart-container margin-right-sm"
    [ngClass]="{'transition': transition}">
    <div *ngFor="let data of chartData" class="thermometer-chart-element {{data.class}}"
      [style.flex-grow]="data.percent/100">
    </div>
  </div>

  <!-- Label -->
  <div class="thermometer-label-container">
    <div *ngFor="let data of chartData" class="thermometer-chart-label"
      [style.flex-grow]="data.percent/100">
      <small class="main-label">{{data.mainLabel}}</small>
    </div>
  </div>
</div>
