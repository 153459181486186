import { BehaviorSubject } from 'rxjs';

import { ProtectionItemName } from './protection-item-name.model';
import { BaseProtectionItem, BaseProtectionItemProperties } from './protection-item.model';
import { SettingsListItem } from './settings-list-item.model';

export enum ProtectionSettingsListItem {
  AbortInBlackouts = 'abortInBlackouts',
  QuietTimes = 'quietTimes',
  Alerts = 'alerts',
  AllowNbdsslTransportFallback = 'allowNbdsslTransportFallback',
  AllowParallelRuns = 'allowParallelRuns',
  AppConsistentBackups = 'appConsistentBackups',
  CloudBackupType = 'cloudBackupType',
  CloudMigration = 'cloudMigration',
  Cluster = 'cluster',
  CrashConsistent = 'crashConsistentBackups',
  Description = 'description',
  DiskExclusions = 'diskExclusions',
  IgnorableErrors = 'ignorableErrors',
  IncrementalAfterRestart = 'incrementalAfterRestart',
  InclusionList = 'inclusionList',
  Indexing = 'indexing',
  IsPaused = 'isPaused',
  LeverageSanTransport = 'leverageSanTransport',
  LeverageStorageSnapshots = 'leverageStorageSnapshots',
  PrePostScripts = 'prePostScripts',
  Priority = 'priority',
  ReaderWriter = 'readerSlashWriter',
  QosPolicy = 'qosPolicy',
  RetainOnPureStorageArray = 'retainOnPureStorageArray',
  IsSafeGuardedCopySnapshot = 'isSafeGuardedCopySnapshot',
  Sla = 'sla',
  SourceSideDeduplication = 'sourceSideDeduplication',
  CacheOptimization = 'cacheOptimization',
  StartTime = 'startTime',
  StorageDomain = 'storageDomain',
  CdpSyncReplication = 'cdpSyncReplication',
  EndDate = 'endDate',
  HypervProtectionType = 'hypervProtectionType',

  // The below item is specific to S3 protection only.
  StorageClass = 'storageClass',
  EnableAclBackups = 'enableAclBackups',
  InventoryReportFrequency = 'inventoryReportFrequency',
  BaselineFrequency = 'baselineFrequency',

  // The below are only for NAS Protection Groups.
  ContinuousSnapshots = 'continuousSnapshots',
  FileDatalock = 'fileDatalock',
  FilterIps = 'filterIps',
  Encryption = 'encryption',
  ExclusionsInclusions = 'exclusionsInclusions',
  IsilonChangeList = 'isilonChangeList',
  SkipFileOnErrors = 'skipFileOnErrors',
  ModifySourcePermissions = 'ModifySourcePermissions',
  SnapshotLabel = 'snapshotLabel',
  SnapMirrorBackup = 'snapMirrorBackup',
  Throttling = 'throttling',
  SnapshotBackupPreferences = 'snapshotBackupPreferences',

  // The below item is only for Remote Adapter Protection Groups.
  RemoteView = 'remoteView',

  // The below item is for View Protection Group.
  ExternallyTriggeredViewBackupTarget = 'externallyTriggeredJobParams',
  RemoteViews = 'remoteViews',

  // The below items are only for MS SQL Groups.
  MsSqlBackupCopyOnly = 'msSqlBackupCopyOnly',
  MsSqlBackupType = 'msSqlBackupType',
  MsSqlDatabasesToBackup = 'msSqlDatabasesToBackup',
  MsSqlNativeNumStreams = 'msSqlNativeNumStreams',
  MsSqlNativeWithClause = 'msSqlNativeWithClause',
  MsSqlVolumeBackup = 'msSqlVolumeBackup',
  MsSqlLogChainBreakAutoTriggerOobIncrBackup = 'logChainBreakAutoTriggerOobIncrBackup',
  MsSqlNewDatabaseFoundAutoTriggerOobIncrBackup = 'newDatabaseAutoTriggerOobIncrBackup',

  // The below items are only for Log Backups (SQL Databases).
  LogBackupNumStreams = 'logBackupNumStreams',
  LogBackupWithClause = 'logBackupWithClause',

  // The below items are only for Data Migration Protection Groups.
  MigrationTargetName = 'migrationTargetName',

  // The below items are only for Exchange on Prem Groups.
  TruncateExchangeLog = 'truncateExchangeLog',

  // The below items are only for Physical Protection Groups.
  GlobalExcludePaths = 'globalExcludePaths',

  // The below items are only for Physical Protection Groups.
  GlobalExcludeFS = 'globalExcludeFS',

  // The below items are only for Oracle Protection Groups.
  PersistMountpoints = 'persistMountpoints',
  Vlan = 'vlan',

  // The below items are only for Remote Adapter Groups.
  Scripts = 'scripts',
  View = 'view',

  // The below items are only for NoSQL-Hadoop Protection Groups.
  Concurrency = 'concurrency',
  BandwidthThrottling = 'bandwidthThrottling',
  CassandraDynamicConcurrency = 'cassandraDynamicConcurrency',

  // The below items are only for Universal Data Adapter Protection Groups.
  ScriptArguments = 'scriptArguments',
  MountCount = 'mountCount',

  // The below items are only for Kubernetes Protection Groups.
  LeverageCsiSnapshot = 'leverageCsiSnapshot',
  KubernetesIncludeExcludeLabels = 'KubernetesIncludeExcludeLabels',
  NonSnapshotBackup = 'nonSnapshotBackup',
  VolumeBackupFailure = 'volumeBackupFailure',

  // The below item is for AWS EBS Volume Exclusion parameters at job level.
  GlobalEbsVolumeExclusions = 'globalEbsVolumeExclusions',

  // The below item is for GCP Disk Exclusion parameters at job level.
  GcpDiskExclusions = 'gcpDiskExclusions',

  // The below item is for Azure Disk Exclusion parameters at job level.
  AzureDiskExclusions = 'azureDiskExclusions',

  // The below item is for Azure private / public endpoints.
  SasUrl = 'sasUrl',

  // The below item is for Acropolis Protection Groups.
  AcropolisDiskFilter = 'acropolisDiskFilter',

  // The below item is only applicable for M365 ProtectionGroups(OneDrive &
  // SharePoint).
  PreservationHoldLibrarySettings = 'preservationHoldLibrarySettings',

  // The below item is for Hyperv Protection Groups.
  HypervDiskFilter = 'hypervDiskFilter',

  // The below items are for Azure SQL object protection.
  CopyDatabase = 'copyDatabase',
  CopyDatabaseSku = 'copyDatabaseSku',
  SqlPackageOptions = 'sqlPackageOptions',
  TempDiskSizeGb = 'tempDiskSizeGb',

  // The below items are applicable only for sap hana.
  delta = 'delta',
}

export interface ProtectionSettingsProperties extends BaseProtectionItemProperties {
  titleKey: string;
  settingsVisible?: boolean;
  alwaysOpen?: boolean;
  alwaysEdit?: boolean;
  settingsItems: SettingsListItem[]|BehaviorSubject<SettingsListItem[]>;
}

export interface SettingsProtectionItem extends BaseProtectionItem {
  name:
    ProtectionItemName.Settings |
    ProtectionItemName.AdditionalSettings |
    ProtectionItemName.MsSqlSettings |
    ProtectionItemName.LogBackupSettings;
  properties: ProtectionSettingsProperties;
}

export interface DataMigrationSettingsProtectionItem extends BaseProtectionItem {
  name: ProtectionItemName.DataMigrationSettings;
  properties: ProtectionSettingsProperties;
}
