import { Component, forwardRef, Inject, Injector, Input, OnInit, Optional } from '@angular/core';
import { UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { StorageDomain, StorageDomainServiceApi } from '@cohesity/api/v2';
import { Template, ViewServiceApi } from '@cohesity/api/v2';
import { SnackBarService } from '@cohesity/helix';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';
import { ItemPickerFormControl } from '@cohesity/shared-forms';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { debounceTime, filter, finalize } from 'rxjs/operators';
import { DialogService } from 'src/app/core/services';
import { ONLY_OWN } from 'src/app/shared/directives';

import { AllViewCategories } from '../../models';

/**
 * This component implements the view box selector for use within angular forms.
 * The component will fetch a list of storage domains and groups them to
 * show them recommended and other storage domains based on template id in a
 * mat-select box.
 *
 * @example
 * <coh-recommended-storage-domain-selector formControlName="storageDomain">
 * </coh-recommended-storage-domain-selector>
 */
@Component({
  selector: 'coh-recommended-storage-domain-selector',
  templateUrl: './recommended-storage-domain-selector.component.html',
  styleUrls: ['./recommended-storage-domain-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RecommendedStorageDomainSelectorComponent),
      multi: true,
    },
  ],
})
export class RecommendedStorageDomainSelectorComponent extends ItemPickerFormControl<number> implements OnInit {
  /**
   * Whether add new should be enabled
   */
  @Input() public addNewEnable = false;

  /**
   * Template details
   */
  @Input() template: Template;

  /**
   * Template id.
   */
  @Input() templateId: number;

  /**
   * Whether the component should show in read only mode
   */
  @Input() public readOnly = false;

  /**
   * Default storage domain id to select
   */
  @Input() defaultStorageDomainId: number;

  /**
   * Float label passed on to determine mat-form-field behavior.
   */
  @Input() floatLabel = 'always';

  /**
   * Optional. If enabled, policy selector will have searching.
   */
  @Input() allowSearch = true;

  /**
   * Whether an API call is pending right now.
   */
  loading = false;

  /**
   * Form Control for searching the values.
   */
  searchCtrl = new UntypedFormControl();

  /**
   * Mapping for view categories.
   */
  viewCategoriesMap = AllViewCategories;

  /**
   * Gets the currently selected view object
   *
   * @return   The selected view
   */
  get selectedViewBox(): StorageDomain {
    if (this.value && this.viewBoxes) {
      return this.viewBoxes.find(view => view.id === this.value);
    }
    return undefined;
  }

  /**
   * Convenience/passthrough function for improved getter name.
   */
  get selectedStorageDomain(): StorageDomain {
    return this.selectedViewBox;
  }

  /**
   * Observable containing all filtered view boxes.
   */
  viewBoxes$ = new BehaviorSubject<StorageDomain[]>([]);

  /**
   * Observable containing all filtered view boxes.
   */
  recommendedViewBoxes: StorageDomain[] = [];

  /**
   * Observable containing all filtered view boxes.
   */
  unrecommendedViewBoxes: StorageDomain[] = [];

  /**
   * Determines to hide recommended settings info.
   */
  hideRecommendedInfo = false;

  /**
   * The loaded initial viewboxes.
   */
  private viewBoxes: StorageDomain[];

  /**
   * Used to register new view boxes
   */
  private ajsViewBoxService: any;

  /**
   * Determines whether to show the info about recommended storage domain.
   *
   * @param viewbox The Storage Domain selected in the drop down menu.
   * @return   Boolean
   */
  showInfoAboutRecommendedStorageDomain(viewbox: StorageDomain): boolean {
    // Show warning if not explicitly hidden AND
    return !this.hideRecommendedInfo &&

      // Using a template AND
      !!this.template &&

      // There is at least one recommended storage domain AND
      !!this.recommendedViewBoxes?.length &&

      // The selected Storage Domain is not recommended AND
      !viewbox?.recommended &&

      // Not using the ZDLRA template.
      // NOTE: I don't think this one-off condition should be used.
      // Need to find a better way.
      !this.template.viewParams?.isExternallyTriggeredBackupTarget;
  }

  /**
   * Determines whether to show warning that there are no recommended storage
   * domains on the cluster.
   *
   * @return   Boolean
   */
  showWarningNoRecommendedStorageDomains(): boolean {
    // Show warning if not explicitly hidden AND
    return !this.hideRecommendedInfo &&

      // Using a template AND
      !!this.template &&

      // There are no recommended Storage Domains.
      !this.recommendedViewBoxes?.length;
  }

  /**
   * Creates a new component
   *
   * @param   injector            use to look up the legacy view box service.
   *                              The ajs upgrade service doesn't work with forward ref
   * @param   viewBoxesService    The view box api service
   * @param   [onlyOwnService]    If included, will be used to decorate api params to
   *                              add get own entity params.
   */
  constructor(
    private injector: Injector,
    private dialogService: DialogService,
    private irisCtx: IrisContextService,
    private snackbar: SnackBarService,
    private storageDomainService: StorageDomainServiceApi,
    private translate: TranslateService,
    private viewService: ViewServiceApi,
    @Optional() @Inject(ONLY_OWN) private onlyOwn: boolean = false
  ) {
    super();
  }

  /**
   * Initialize and retrieve the viewbox list
   */
  ngOnInit() {
    this.fetchViewBoxes();
  }


  /**
   * Method called to fetch viewbox list.
   */
  fetchViewBoxes() {
    let params = { includeTenants: !this.onlyOwn };
    params = Object.assign({ viewTemplateId: this.templateId }, params);
    this.loading = true;

    this.ajsViewBoxService = this.injector.get<{[key: string]: Function}>('$injector' as any).get('ViewBoxService');

    combineLatest([
      this.templateId && !this.template ? this.viewService.ReadViewTemplateById({id: this.templateId}) :
        of(this.template),
      this.storageDomainService.GetStorageDomains(params)
    ])
      .pipe(
        this.untilDestroy(),
        finalize(() => this.loading = false)
      )
      .subscribe(([template, resp]) => {
        const viewBoxes = resp.storageDomains;
        this.template = template;
        this.viewBoxes = viewBoxes;
        this.viewBoxes$.next(viewBoxes);

        viewBoxes.forEach(viewbox => {
          // Add to recommended if the viewBox is recommended already or
          // recommend it if it exists in template.
          viewbox.recommended = viewbox.recommended || (this.template?.viewParams?.storageDomainId === viewbox.id);
          viewbox.recommended ? this.recommendedViewBoxes.push(viewbox) :
            this.unrecommendedViewBoxes.push(viewbox);
        });

        if (this.recommendedViewBoxes.length > 0) {
          if (this.defaultStorageDomainId) {
            this.value = this.defaultStorageDomainId;
          } else {
            this.value = this.recommendedViewBoxes.find(viewBox => viewBox.recommended).id;
          }
        } else if (this.autoOpen && !this.readOnly) {
          this.matSelect.open();
        }
      }, (error) => {
        this.snackbar.open(error.error.message, 'error');
      });

    if (this.allowSearch) {
      // If allows search is set, filter results.
      this.searchCtrl.valueChanges.pipe(
        debounceTime(150),
        this.untilDestroy(),
      ).subscribe(searchString => {
        const searchStringRegex = new RegExp(searchString, 'i');
        const filteredValues = this.viewBoxes.filter(
          (value) => value.name.search(searchStringRegex) > -1
        );

        this.viewBoxes$.next(filteredValues);
        const recommendedViewBoxes = [], unrecommendedViewBoxes = [];

        filteredValues.forEach(viewbox => {
          viewbox.recommended ? recommendedViewBoxes.push(viewbox) :
            unrecommendedViewBoxes.push(viewbox);
        });

        this.recommendedViewBoxes = recommendedViewBoxes;
        this.unrecommendedViewBoxes = unrecommendedViewBoxes;
      });
    }
  }

  /**
   * Handle adding new storage, then updates and selects the list when finished
   */
  addStorage() {
    const compressionPolicy = this.template.compress ? 'Low' : 'None';
    const storagePolicyByTemplate = {
      deduplicationEnabled: this.template.dedup,
      compressionPolicy,
      compressionEnabled: this.template.compress,
    };

    if (flagEnabled(this.irisCtx.irisContext, 'ngStorageDomainsModify')) {
      this.dialogService.showDialog('ng-modify-storage-domain-dialog', storagePolicyByTemplate, {
        panelClass: 'modify-storage-domain-container',
        minWidth: '100vw',
        height: '100vh',
      }).pipe(
        filter(value => Boolean(value)),
        this.untilDestroy()
      ).subscribe((newSD: StorageDomain) => {
        if (newSD) {
          this.updateRecommendedViewBoxes(newSD, compressionPolicy);
        }
      });
    } else {
      this.ajsViewBoxService.newViewBoxModal(storagePolicyByTemplate).then((newViewBox) => {
        const storageDomain: StorageDomain = this.ajsViewBoxService.transformViewBoxToStorageDomain(newViewBox);
        this.updateRecommendedViewBoxes(storageDomain, compressionPolicy);
      });
    }
  }

  /**
   * Helper function to update recommendedViewBoxes and unrecommendedViewBoxes list.
   *
   * @param   newViewBox   New added viewbox
   * @param   compressionPolicy   Recommended compression policy.
   */
  private updateRecommendedViewBoxes(newViewBox: StorageDomain, compressionPolicy: 'Low' | 'None') {
    this.viewBoxes.push(newViewBox);
    this.viewBoxes$.next(this.viewBoxes);

    if (newViewBox.storagePolicy.deduplicationParams.enabled === this.template.dedup &&
      newViewBox.storagePolicy.compressionParams.type === compressionPolicy) {
      newViewBox.recommended = true;
      this.recommendedViewBoxes.push(newViewBox);
    } else {
      this.unrecommendedViewBoxes.push(newViewBox);
    }

    this.value = newViewBox.id;
  }
}
