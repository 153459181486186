import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DataIdModule } from '@cohesity/helix';

import { AnomalyTagsComponent } from './anomaly-tags.component';

const COMPONENTS = [AnomalyTagsComponent];

@NgModule({
  imports: [CommonModule, DataIdModule],
  exports: [...COMPONENTS],
  declarations: [...COMPONENTS],
})
export class AnomalyTagsModule {}
