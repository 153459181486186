/**
 * Agent's minimum version for point in time restores.
 */
export const HELP_MAP = {
  index: 999,

  // Cluster
  maintenance: 1097,
  hardware: 1118,
  kms: 1119,
  syslog_server: 1120,
  support_user: 1121,
  mfa_local_user: 1122,
  kerberos: 1123,
  keystone: 1127,
  nis: 1128,
  licensing: 1129,

  // Dashboards
  dashboard_sql: 1216,
  data_protection: 1092,
  dashboard_fileservices: 1093,
  dashboard_cloud: 1094,
  dashboard_cdp: 1095,

  // Recovery
  recovery: 1099,
  restore_exchange_online: 1106,
  restore_onedrive_for_business_data: 1109,
  restore_sharepoint_online: 1110,

  // Sources
  source_snapshot_provider: 1111,

  // Runbook
  dr_runbook: 1096,

  // Data Tiering
  data_tiering: 1117,

  // Health
  monitoring_health: 1113,
  monitoring_health_alerts: 1114,
  monitoring_health_resolution_summary: 1115,
  monitoring_health_notification_settings: 1116,

  // Reports
  reports: 1000,
  reports_agent_status: 1080,
  reports_data_transferred: 1183,
  reports_data_retrieved: 1182,
  reports_jobs: 1026,
  reports_protected_objects_heatmap: 1082,
  reports_protection_runs: 1083,
  reports_topjobs: 1027,
  reports_restore: 1028,
  reports_sources: 1029,
  reports_storage: 1030,
  reports_storage_jobs: 1031,
  reports_storage_vms: 1032,
  reports_storage_viewboxes: 1033,
  reports_storage_organizations: 1059,
  reports_storage_growth: 1034,
  reports_files_category_cluster: 1037,
  reports_files_category_vm: 1039,
  reports_unprotected_vms: 1131,
  reports_available_local_snapshots: 1173,
  reports_protection_details: 1174,
  reports_failed_objects: 1175,
  reports_protection_summary_by_object_type: 1176,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  reports_GDPR_object_summary: 1047,
  reports_user_quotas: 1042,

  // marketplace
  marketplace_apps: 1141,

  // multi-tenancy
  add_organization: 1124,
  platform_organizations: 1061,
  admin_organizations_bifrost: 1051,

  // Networking
  networking: 1136,
  static_route: 1130,

  // CloudRetrieve
  protection_cloudretrieval_searches: 1177,
  protection_cloudretrieval_search: 1178,
  protection_cloudretrieval_search_details: 1179,
  protection_cloudretrieval_retrievals: 1180,

  // SmartFiles
  platform_datamigration: 1090,
  platform_external_new: 1066,
  platform_external: 1065,
  platform_kms: 1048,
  platform_nfs_confirm: 1102,
  platform_nfs_details: 1137,
  platform_nfs_new: 1105,
  platform_nfs_shares: 1060,
  platform_nfs_subnets: 1012,
  platform_nfs: 1046,
  platform_nodes_add_setup: 1057,
  platform_nodes_add_vips: 1058,
  platform_nodes_add: 1018,
  platform_nodes: 1009,
  platform_replication_new_connection: 1073,
  platform_replication_new_pairing: 1074,
  platform_replication: 1072,
  platform_shares: 1060,
  platform_sso: 1079,
  platform_user_quotas: 1024,
  platform_viewboxes_details: 1138,
  platform_viewboxes_new_settings_azure: 1188,
  platform_viewboxes_new_settings: 1142,
  smartfiles_client_connections: 1149,
  smartfiles_consumption: 1146,
  smartfiles_consumption_growth: 1014,
  smartfiles_ideal_use_cases: 1150,
  smartfiles_performance: 1148,
  view_migration: 1046,
  view_templates: 1151,

  admin_snmp: 1132,
  admin_snmp_edit: 1133,
  testdev_clone_view_search: 1134,
  testdev_clone_view_options: 1135,
  protection_recovery_mountpoint_select: 1139,
  protection_recovery_mountpoint_options: 1140,
  reports_vms: 1035,
  reports_vms_inventory: 1036,
  protection_sources: 1006,
  protection_sources_aws_register: 1055,
  protection_sources_type: 1144,
  protection_sources_hypervisor: 1019,
  protection_sources_physical: 1145,
  protection_sources_pure_register: 1147,
  protection_sources_cloud: 1162,
  protection_sources_nas: 1172,
  protection_sources_sqlcluster_register: 1185,
  protection_recovery: 1008,
  protection_recovery_vm: 1011,
  protection_recovery_rds: 9003,

  // File recovery
  protection_recovery_files_search: 1010,
  protection_recovery_files_options: 1098,
  protection_recovery_nas_search: 1169,
  protection_recovery_nas_options: 1170,
  protection_recovery_phys_search: 1186,
  protection_recovery_phys_options: 1187,
  protection_jobs: 1007,

  protection_job_modify_remote_adapter: 1202,
  protection_job_modify_view: 1206,
  protection_job_modify_sql: 1205,
  protection_job_modify_oracle: 1207,
  protection_job_modify_pure: 1201,
  protection_job_modify_physical: 1203,
  protection_job_modify_virtual: 1204,
  protection_job_modify_nas: 1200,

  protection_job_details_views: 1100,
  protection_job_details_vms: 1043,
  protection_policies: 1052,
  policy_manage: 1112,
  protection_policies_new_options: 1053,
  protection_policies_new_confirm: 1056,
  protection_policies_policy_confirm_vms: 1094,
  protection_policies_new_confirm_view: 1084,
  protection_policies_policy_confirm_views: 1097,
  protection_policies_new_sql_options: 1120,
  protection_policies_policy_confirm_sql: 1119,
  protection_policies_new_confirm_sql: 1122,
  protection_policies_policy: 1052,
  protection_policies_rpo: 1219,
  admin_activedirectory: 1075,
  admin_access: 1001,
  admin_access_users: 1157,
  admin_audit: 1160,
  admin_ldap: 1074,
  admin_roles: 1158,
  admin_roles_new: 1159,
  admin_cluster_antivirus: 1069,
  admin_cluster_details: 1002,
  admin_cluster_upgrade: 1003,
  admin_cluster_partitions: 1016,
  admin_cluster_nodes: 1017,
  admin_cluster_viewboxes: 1050,
  admin_cluster: 1018,
  admin_cluster_summary: 1013,
  admin_cluster_partition_new: 1021,
  admin_cluster_vlans: 1081,
  admin_cluster_firewall: 1223,
  monitoring_alerts: 1004,
  monitoring_alerts_resolutions: 1005,
  monitoring_alerts_settings: 1020,
  monitoring_alerts_analytics: 1084,
  monitoring_performance: 1022,
  monitoring_storage: 1023,
  monitoring_diagnostics: 1025,
  monitoring_field_messages: 1076,
  devops_clone: 1038,
  devops_clone_detail: 1041,
  devops_clone_new_options: 1161,
  testdev_clonenew_new_select: 1067,
  testdev_clonenew_new_options: 1068,
  protection_recoverynew: 1064,
  protection_recoverynew_vm_steps_select: 1062,
  protection_recoverynew_vm_steps_options: 1063,
  failover_protection_recoverynew_vm_steps_options: 1070,
  protection_recovery_sql_search: 1107,
  protection_recovery_sql_options: 1108,
  testdev_clone_database_search: 1091,
  testdev_clonenew_new_sql_select: 1125,
  testdev_clonenew_new_sql_options: 1126,
  testdev_clonenew_new_oracle_options: 1085,
  protection_sources_sql_register: 1054,
  protection_sources_sql_unregister: 1088,
  protection_sources_sql_modify: 1103,
  protection_recovery_pure_search: 1154,
  protection_recovery_pure_options: 1155,
  protection_recovery_oracle_search: 1208,
  protection_recovery_oracle_options: 1209,
  protection_sources_oracle: 1211,
  protection_recovery_storage_volume_search: 1169,
  protection_recovery_storage_volume_options: 1170,
  protection_recovery_virtual_disk_search: 1077,
  protection_recovery_virtual_disk_options: 1078,
  customer_managed_keys: 8002,
  helios_about: 8000,
  helios_alerts: 8003,
  helios_cluster_upgrade: 8001,
  helios_global_search: 8002,
  platform_helios_fields: 1076,
  platform_helios: 1051,
  helios_global_reports: 8005,
  migrate_sql_select: 1217,
  migrate_sql_options: 1218,

  // Active Directory
  protection_sources_ad: 1220,
  protection_job_ad: 1221,
  protection_recovery_ad: 1222,

  // Storage Domain
  storage_domain_details: 1015,

  // Azure function app
  azure_function_app: 1044,
};
