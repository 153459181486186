<ng-content></ng-content>

<!-- Select Column is automatically added whenever a selection model is present -->
<ng-container #selectColumn matColumnDef="select">

  <!-- If is flex is set, use the mat-header-cell and mat-cell elements, which
    are based on flex box -->
  <ng-container *ngIf="isFlex">
    <mat-header-cell *matHeaderCellDef>
      <ng-container *ngTemplateOutlet="defaultSelectAllTemplate">
      </ng-container>
    </mat-header-cell>

    <!-- When multiTemplateDataRows is set, the row index is read from dataIndex, rather than index. -->
    <mat-cell *matCellDef="let row; index as i; dataIndex as dataIndex">
      <ng-container *ngTemplateOutlet="defaultSelectRowTemplate;
            context: {
              $implicit: {
                row: row,
                index: i  || dataIndex || 0
              }
            }">
      </ng-container>
    </mat-cell>
  </ng-container>

  <!-- If this isn't a flex table, use the standard html table elements -->
  <ng-container *ngIf="!isFlex">
      <th [attr.aria-label]="intl.table.checkBoxHeader" mat-header-cell *matHeaderCellDef>
        <ng-container *ngTemplateOutlet="defaultSelectAllTemplate">
        </ng-container>
      </th>

      <td mat-cell *matCellDef="let row; index as i; dataIndex as dataIndex">
        <ng-container *ngTemplateOutlet="defaultSelectRowTemplate;
            context: {
              $implicit: {
                row: row,
                index: i || dataIndex || 0
              }
            }">
        </ng-container>
      </td>
    </ng-container>
</ng-container>

<!-- Default template for the select all checkbox. This should be hidden for single select tables -->
<ng-template #defaultSelectAllTemplate>
  <mat-checkbox id="{{name}}-select-all"
    cogDataId="{{name}}-select-all"
    *ngIf="selection.isMultipleSelection()"
    [aria-label]="intl.table.multiAction.selectTablePageItems"
    (change)="$event && toggleSelectAll($event)"
    [checked]="selection.hasValue() && isAllSelected()"
    [indeterminate]="selection.hasValue() && !isAllSelected()"
    [disabled]="!canSelectAnyRows()"
    [matTooltip]="selectAllRowsTooltipFn ? selectAllRowsTooltipFn() : null">
  </mat-checkbox>
</ng-template>

<!-- Default template for select row. This shows a checkboxes or radio button depending
  on the selection model. -->
<ng-template #defaultSelectRowTemplate let-ctx>
  <mat-checkbox
    #selectCheckbox
    *ngIf="selection?.isMultipleSelection()"
    id="{{name}}-select-row-{{ctx.index}}"
    cogDataId="{{name}}-select-row-{{ctx.index}}"
    [aria-label]="getSelectAriaLabel(selectCheckbox)"
    (mousedown)="shiftKeyDown = $event.shiftKey"
    (keypress)="shiftKeyDown = $event.shiftKey"
    (change)="$event && toggleRow(ctx.row, ctx.index, shiftKeyDown)"
    [checked]="selection.isSelected(ctx.row)"
    (click)="stopPropagation($event)"
    [disabled]="!canSelectRow(ctx.row)"
    [matTooltip]="selectRowTooltipFn ? selectRowTooltipFn(ctx.row) : null">
  </mat-checkbox>

  <mat-radio-button
    #selectRadio
    *ngIf="!selection?.isMultipleSelection()"
    id="{{name}}-select-row-{{ctx.index}}"
    cogDataId="{{name}}-select-row-{{ctx.index}}"
    [aria-label]="getSelectAriaLabel(selectRadio)"
    (change)="$event && selection.toggle(ctx.row)"
    [checked]="selection.isSelected(ctx.row)"
    (click)="stopPropagation($event)"
    [disabled]="!canSelectRow(ctx.row)"
    name="{{name}}-radio-group-{{ctx.index}}"
    [matTooltip]="selectRowTooltipFn ? selectRowTooltipFn(ctx.row) : null">
  </mat-radio-button>
</ng-template>

<!-- Multi-action Header Container -->
<ng-content select="cog-table-multi-action"></ng-content>
