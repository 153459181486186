/* tslint:disable */

/**
 * Specifies tag types.
 */
export enum TagType {
  System = 'System',
  Custom = 'Custom',
  ThirdParty = 'ThirdParty'
}
