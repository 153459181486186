import { NgModule } from '@angular/core';

import { DateDurationPipe } from './date-duration.pipe';
import { HumanizeFromUsecsPipe } from './humanize-from-usecs.pipe';
import { ConvertDateUnitsPipe, MomentDatePipe } from './moment-date.pipe';

const pipes = [
  ConvertDateUnitsPipe,
  DateDurationPipe,
  HumanizeFromUsecsPipe,
  MomentDatePipe,
];

@NgModule({
  imports: [...pipes],
  providers: [...pipes],
  exports: [...pipes]
})
export class DatePipesModule {}
