<h1 mat-dialog-title>{{ 'connectionToken' | translate }}</h1>

<div mat-dialog-content>
  <ng-container *ngIf="!loading">
    <div *ngIf="!token">{{ 'tokenNotAvailable' | translate }}</div>
    <mat-list *ngIf="token">
      <mat-list-item>
        <div mat-line>{{ token }}</div>
        <button mat-icon-button
          cogDataId="btn-dms-connection-token-dialog-copy"
          (click)="copyToken()"
          [matTooltip]="'copyToClipboard' | translate"
          matTooltipPosition="above">
          <cog-icon shape="helix:copy"></cog-icon>
        </button>
      </mat-list-item>
      <mat-list-item *ngIf="expiration">
        <div mat-line>
          <span *ngIf="expiration > 0">
            {{ 'expiresInDuration' | translate: { duration: (expiration | humanizeDuration) } }}
          </span>
          <span *ngIf="expiration <= 0">
            {{ 'expiredOnDate' | translate: { date: (expirationDate | cohDate) } }}
          </span>
          <a class="margin-left"
            cogDataId="btn-dms-connection-token-dialog-reset"
            (click)="resetToken()">
            {{ 'extend' | translate }}
          </a>
        </div>
      </mat-list-item>
    </mat-list>
  </ng-container>
</div>

<div mat-dialog-actions align="end">
  <button
    cogDataId="btn-dms-connection-token-dialog-close"
    mat-button
    mat-dialog-close
    cdkFocusInitial>
    {{ 'close' | translate }}
  </button>
</div>
