import { SelectionModel } from '@angular/cdk/collections';
import { Injectable } from '@angular/core';
import { ArchivalExternalTarget } from '@cohesity/api/v1';
import { ExternalTarget, ExternalTargets, ExternalTargetServiceApi } from '@cohesity/api/v2';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';
import { Memoize } from '@cohesity/utils';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { finalize, map, take, tap } from 'rxjs/operators';
import { DialogService, PassthroughOptionsService } from 'src/app/core/services';
import { AjsUpgradeService } from 'src/app/core/services/ajs-upgrade.service';

import { StorageType } from '../../constants';
import { DecoratedExternalTargetSelector } from './external-target-selector.model';

/**
 * mapping to fetch parameters from API response.
 * based on storage type. Check out the definition of external-target in v2 models
 * (external-target.d.ts) for valid property names.
 */
export const parmsAccessMap = {
  [StorageType.AWS]: 'awsParams',
  [StorageType.AZURE]: 'azureParams',
  [StorageType.GCP]: 'gcpParams',
  [StorageType.ORACLE]: 'oracleParams',
  [StorageType.NAS]: 'nasParams',
  [StorageType.QSTART]: 'qstarTapeParams',
  [StorageType.S3C]: 's3CompParams',
};

/**
 * Type of external targets that are loading.
 */
export type RestrictType = 'Archival' | 'Tiering' | 'Rpaas';

/**
 * This service is paired with the external target selector component to provide
 * a list of external targets that can be selected in a select component. The
 * list of available targets is shared globally across all uses of the component.
 * The service also maintains a list of currently selected targets used by any
 * instance of the component which can be used to disable selection of a target
 * already selected by another component.
 *
 * All of api values are typed as any because we're using the angularjs
 * service which does not define types.
 */
@Injectable({
  providedIn: 'root',
})
export class ExternalTargetSelectorService {
  /**
   * An shared observable of all of the vaults currently available.
   */
  public vaults$ = new BehaviorSubject<any[]>(undefined);

  /**
   * Shared observable of rpaas vaults currently available.
   */
  readonly rpaasVaults$ = new BehaviorSubject<any[]>(undefined);

  /**
   * Used to track the selected targets across all component instances.
   */
  private selection = new SelectionModel<number>(true, []);

  /**
   * Used to track if a data refresh is currently in progress. If a refresh is
   * triggered while a load is in progress, it will be ignored.
   */
  private loading = false;

  /**
   * Indicates if rpaas vaults are loading.
   * Some policies can have rpaas and archive targets. Having only one will prevent other from loading.
   */
  private rpaasLoading = false;

  /**
   * Legacy external target service.
   * TODO - move the service implementation to typescript.
   */
  private externalTargetService: any;

  /**
   * Creates the service
   *
   * @param   ajsUpgrade   The ajs upgrade service
   */
  constructor(
    ajsUpgrade: AjsUpgradeService,
    private dialogService: DialogService,
    private irisCtx: IrisContextService,
    private ngExternalTargetService: ExternalTargetServiceApi,
    private passthroughOptionsService: PassthroughOptionsService
  ) {
    this.externalTargetService = ajsUpgrade.get('ExternalTargetService');
    this.dialogService = dialogService;
  }

  /**
   * Sets the selection for an external target.
   *
   * @param   vaultId    The vault id to select
   * @param   selected   Whether to select or deselect the vault.
   */
  public setSelection(vaultId: number, selected: boolean) {
    if (selected) {
      this.selection.select(vaultId);
    } else {
      this.selection.deselect(vaultId);
    }
  }

  /**
   * Checks if a vault is selected or not
   *
   * @param    vaultId   The vault id to check.
   * @return   Whether the vault id is selected or not.
   */
  public isSelected(vaultId: number): boolean {
    return this.selection.isSelected(vaultId);
  }

  /**
   * Triggers an API call to refresh the currently available targets. No load
   * is triggered if a refresh is already in progress.
   */
  refreshTargetList(restrictToUsageType?: RestrictType) {
    if (restrictToUsageType === 'Rpaas') {
      if (!this.rpaasLoading) {
        this.fetchNGTargetList(restrictToUsageType);
      }

      return;
    }

    if (this.loading) {
      return;
    }

    // if ngVaults is enabled, fetch the target list using
    // v2 APIs for external target
    if (this.isNGVaultsEnabled()) {
      this.fetchNGTargetList(restrictToUsageType);
      return ;
    }

    this.loading = true;

    // Convert from a promise to an observable
    from(this.externalTargetService.getTargets(null, {includeMarkedForRemoval: false}))
      .pipe(
        // Decorate the vault with a ArchivalExternalTarget property
        map((vaults: any[]) => vaults.map(vault => this.decorateVault(vault))),

        // Close the subscription after it fires once
        take(1),

        // Reset loading whenever the observable is finished
        finalize(() => (this.loading = false))
      )
      .subscribe((vaults: any[]) => this.vaults$.next(vaults));
  }

  /**
   * Triggers the Register New External Target modal and returns an observable
   * of the modal result. When a new target is added, it will update the list and
   * push the changes.
   *
   * @param   target              The selected External Target.
   * @param   restrictToUsageType kValue of Target usage type which is allowed.
   * @param   context             The context of external target registration(archival or viewbox).
   * @returns observable of type decoratedETSelector in case ngVaults is enabled else
   */
  public registerNew(restrictToUsageType?: string, context?: string): Observable<DecoratedExternalTargetSelector> {
    // start the ng-external-targets creation workflow
    // based on ngVaults feature flag value
    if (this.isNGVaultsEnabled()) {
      let restrictToPurpose: RestrictType = null;

      if (restrictToUsageType) {
        // only 2 purpose options are available currently - Archival and Tiering
        // check if restrictToUsageType contains old enum used for Archival - kArchival
        if (restrictToUsageType === 'kArchival' || restrictToUsageType === 'Archival') {
          restrictToPurpose = 'Archival';
        } else {
          restrictToPurpose = 'Tiering';
        }
      }

      return this.dialogService.showDialog(
        'create-external-target',
        { restrictToPurpose, isSilo: false, context: context }
      )
      .pipe(
        // decorate the vault with ArchiveExternalTarget params
        map(
          (newTarget: ExternalTarget) => {
            // decorate only if valid value received
            if (newTarget) {
              return this.decorateNGVault(newTarget);
            }
            return null;
          }
        ),
        tap(
          // update the vault list with the new vault
          (newTarget) => {
            if (newTarget) {
              const currentVaults = this.vaults$.value || [];
              this.vaults$.next([ ...currentVaults, newTarget ]);
            }
          }
        )
      );
    } else {
      return from(this.externalTargetService.modifyTargetModal(undefined, restrictToUsageType, 'from-takeover')).pipe(
        map(newTarget => this.decorateVault(newTarget)),
        tap(newTarget => {
          this.vaults$.value.push(newTarget);
          this.vaults$.next(this.vaults$.value);
        })
      );
    }
  }

  /**
   * fetches the list of external targets using the v2 API and updates the
   * vaults$ with the response
   *
   * @param restrictToUsageType purpose type of external targets to be fetched
   */
  fetchNGTargetList(restrictToUsageType?: RestrictType) {
    const list$ = this.getNGTargetList(restrictToUsageType).pipe(take(1));

    if (restrictToUsageType === 'Rpaas') {
      // Reset the list each time we fetch it, otherwise, if we switch between clusters we will
      // run this logic on a stale set of vaults.
      this.rpaasVaults$.next(null);
      this.rpaasLoading = true;
      list$.pipe(
        finalize(() => this.rpaasLoading = false)
      ).subscribe(vaults => this.rpaasVaults$.next(vaults?.sort((v1, v2) => v1.name?.localeCompare(v2.name)) || []));
    } else {
      // Reset the list each time we fetch it, otherwise, if we switch between clusters we will
      // run this logic on a stale set of vaults.
      this.vaults$.next(null);
      this.loading = true;
      list$.pipe(
        // Reset loading whenever the observable is finished
        finalize(() => (this.loading = false))
      ).subscribe(vaults => this.vaults$.next(vaults || []));
    }
  }

  /**
   * Returns observable to get external targets from v2 API.
   *
   * @param restrictToUsageType purpose type of external targets to be fetched
   */
  getNGTargetList(restrictToUsageType?: string): Observable<DecoratedExternalTargetSelector[]> {
    const params: ExternalTargetServiceApi.GetExternalTargetsParams = {};

    if (restrictToUsageType) {
      // only 2 purpose options are available currently - Archival and Tiering
      // check if restrictToUsageType contains old enum used for Archival - kArchival
      // fortknox uses ownership context instead of purpose type
      switch (restrictToUsageType) {
        case 'kArchival':
        case 'Archival':
          params.purposeTypes = ['Archival'];
          break;
        case 'Rpaas':
          params.ownershipContexts = ['FortKnox'];
          break;
        case 'Tiering':
        default:
          params.purposeTypes = ['Tiering'];
      }
    }

    if (this.passthroughOptionsService.accessClusterId) {
      params.accessClusterId = this.passthroughOptionsService.accessClusterId;
    }


    return this.ngExternalTargetService.GetExternalTargets(params).pipe(
      // Decorate the vault with a ArchivalExternalTarget property
      map((vaults: ExternalTargets) => vaults.externalTargets?.map(vault => this.decorateNGVault(vault))),

    );
  }

  /**
   * decorates the ng model of external target with the properties required by
   * the selector component - ArchivalExternalTarget. This should be equivalent to
   * decorateNGVault function.
   *
   * @param vault NG external target
   * @returns decorated NG external target with ArchivalExternalTarget properties
   */
  decorateNGVault(vault: ExternalTarget): DecoratedExternalTargetSelector {
    const vaultParamKey = vault?.purposeType || '';
    const params = vault[`${vaultParamKey.toLowerCase()}Params`] || {};
    const storageType = params.storageType;
    const key = parmsAccessMap[storageType];
    const storageClass = params[key]?.storageClass;
    return {
      ...vault,
      _vaultName: storageType,
      _name: storageClass,
      usageType: vaultParamKey === 'Archival' ? 'kArchival' : 'kTiering',
      _tierData: {
        tier: storageClass
      },
      _typeKey: storageType,
      externalTargetType: storageType,
      _target: {
        vaultId: vault.id,
        vaultName: vault.name,
        vaultType: params.storageType === StorageType.QSTART ? 'kTape' : 'kCloud',
        enableObjectLock: vault?.enableObjectLock,
      } as ArchivalExternalTarget
    };
  }

  /**
   * Utility method to decorate archival external target.
   * Note: vault type as any because we are still using ajs method
   * which has additional decorated properties.
   *
   * @param  vault   vault to decorate.
   * @return decorated vault with archival external target.
   */
  decorateVault(vault: any): DecoratedExternalTargetSelector {
    return {
      ...vault,
      _target: {
        vaultId: vault.id,
        vaultName: vault.name,
        vaultType: vault.externalTargetType === 'kQStarTape' ? 'kTape' : 'kCloud',
      } as ArchivalExternalTarget
    };
  }

  /**
   * boolean to indicate if ng flag for external targets is enabled or not.
   *
   * @returns true if ngVaults is enabled; else false
   */
  @Memoize()
  isNGVaultsEnabled(): boolean {
    return flagEnabled(this.irisCtx.irisContext, 'ngVaults');
  }
}
