import { DataRenderer } from '../data-renderer.model';
import { TextDataRendererComponent } from './text-data-renderer/text-data-renderer.component';

/**
 * Converts a percent value to a string.
 *
 * @param value The number value. Should be <= 1.
 * @returns a rounded value with a max of 2 decimal places with a '%' suffix.
 */
function getPercent(value: number): string {
  // Number is in decimal - convert to percent and round to 2 decimal places.
  let rounded = Math.round(value * 10000) / 100;

  if (isNaN(rounded)) {
    rounded = 0;
  }
  return rounded.toString() + '%';
}

/**
 * Renders number as a percentage.
 */
export const percentNumberRenderer = {
  transform: (renderValues: DataRenderer<string, undefined, undefined>) => ({
    ...renderValues,
    value: getPercent(Number(renderValues.value)),
  }),
  component: TextDataRendererComponent,
};
