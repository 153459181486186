/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityV2ApiConfiguration as __Configuration } from '../cohesity-v2api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { OnPremTenantConfig } from '../models/on-prem-tenant-config';
import { TenantConfig } from '../models/tenant-config';
import { ClustersTenantConfigs } from '../models/clusters-tenant-configs';
import { UpdateClustersTenantConfigsResponse } from '../models/update-clusters-tenant-configs-response';
import { ListTenantData } from '../models/list-tenant-data';
import { HeliosTenant } from '../models/helios-tenant';
import { CreateTenantParams } from '../models/create-tenant-params';
import { TenantBackfillBody } from '../models/tenant-backfill-body';
import { ReassociateKeyResponseBody } from '../models/reassociate-key-response-body';
import { ReassociateKeyRequest } from '../models/reassociate-key-request';
import { HeliosTenantsStats } from '../models/helios-tenants-stats';
import { UpdateHeliosTenantBody } from '../models/update-helios-tenant-body';
import { TenantActionBody } from '../models/tenant-action-body';
import { HeliosTenantAssignmentsResult } from '../models/helios-tenant-assignments-result';
import { HeliosTenantAssignmentsParams } from '../models/helios-tenant-assignments-params';
import { AssignClusterToTenantParamsBody } from '../models/assign-cluster-to-tenant-params-body';
import { ConfirmTenantParamsBody } from '../models/confirm-tenant-params-body';
import { TenantMigrationResult } from '../models/tenant-migration-result';
import { TenantMigrationAction } from '../models/tenant-migration-action';
import { TenantsInfo } from '../models/tenants-info';
import { TenantInfo } from '../models/tenant-info';
import { TenantNetwork } from '../models/tenant-network';
import { SwiftParams } from '../models/swift-params';
import { RegisterSwiftParams } from '../models/register-swift-params';
import { UnregisterSwiftParams } from '../models/unregister-swift-params';
import { UpdateTenantBody } from '../models/update-tenant-body';
import { TenantAssignmentProperties } from '../models/tenant-assignment-properties';
import { TenantAssignments } from '../models/tenant-assignments';
import { TenantAssignmentsParams } from '../models/tenant-assignments-params';
@Injectable({
  providedIn: 'root',
})
class TenantService extends __BaseService {
  static readonly GetOnPremTenantConfigPath = '/clusters/tenant-config';
  static readonly UpdateOnPremTenantConfigPath = '/clusters/tenant-config';
  static readonly GetAccountTenantConfigPath = '/mcm/accounts/tenant-config';
  static readonly UpdateAccountTenantConfigPath = '/mcm/accounts/tenant-config';
  static readonly GetClustersTenantConfigPath = '/mcm/clusters/tenant-config';
  static readonly UpdateClustersTenantConfigPath = '/mcm/clusters/tenant-config';
  static readonly GetHeliosTenantsPath = '/mcm/tenants';
  static readonly CreateHeliosTenantPath = '/mcm/tenants';
  static readonly TriggerTenantBackfillTaskPath = '/mcm/tenants/backfill-tasks';
  static readonly ReassociateKeyPath = '/mcm/tenants/reassociate-key';
  static readonly GetTenantStatsPath = '/mcm/tenants/stats';
  static readonly DeleteHeliosTenantPath = '/mcm/tenants/{id}';
  static readonly UpdateHeliosTenantPath = '/mcm/tenants/{id}';
  static readonly PerformHeliosTenantActionPath = '/mcm/tenants/{id}/actions';
  static readonly HeliosAssignPropertiesToTenantPath = '/mcm/tenants/{id}/assignments';
  static readonly AssignClusterToTenantPath = '/mcm/tenants/{id}/clusters';
  static readonly ConfirmTenantPath = '/mcm/tenants/{id}/manage';
  static readonly getTenantMigrationActionsPath = '/tenant-migration/actions';
  static readonly PerformTenantMigrationActionPath = '/tenant-migration/actions';
  static readonly ListTenantsPath = '/tenants';
  static readonly CreateTenantPath = '/tenants';
  static readonly GetTenantSwiftPath = '/tenants/swift';
  static readonly UpdateTenantSwiftPath = '/tenants/swift';
  static readonly RegisterSwiftPath = '/tenants/swift/register';
  static readonly UnregisterSwiftPath = '/tenants/swift/unregister';
  static readonly GetTenantByIDPath = '/tenants/{id}';
  static readonly UpdateTenantPath = '/tenants/{id}';
  static readonly DeleteTenantPath = '/tenants/{id}';
  static readonly PerformTenantActionPath = '/tenants/{id}/actions';
  static readonly GetAssignedPropertiesForTenantPath = '/tenants/{id}/assignments';
  static readonly AssignPropertiesToTenantPath = '/tenants/{id}/assignments';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get Tenants Config.
   *
   * Get Tenant related configurations for the cluster.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetOnPremTenantConfigResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<OnPremTenantConfig>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/clusters/tenant-config`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OnPremTenantConfig>;
      })
    );
  }
  /**
   * Get Tenants Config.
   *
   * Get Tenant related configurations for the cluster.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetOnPremTenantConfig(regionId?: string,
    accessClusterId?: number): __Observable<OnPremTenantConfig> {
    return this.GetOnPremTenantConfigResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as OnPremTenantConfig)
    );
  }

  /**
   * Update Tenants Config.
   *
   * Update Tenant related configurations for the cluster.
   * @param params The `TenantService.UpdateOnPremTenantConfigParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateOnPremTenantConfigResponse(params: TenantService.UpdateOnPremTenantConfigParams): __Observable<__StrictHttpResponse<OnPremTenantConfig>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/clusters/tenant-config`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OnPremTenantConfig>;
      })
    );
  }
  /**
   * Update Tenants Config.
   *
   * Update Tenant related configurations for the cluster.
   * @param params The `TenantService.UpdateOnPremTenantConfigParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateOnPremTenantConfig(params: TenantService.UpdateOnPremTenantConfigParams): __Observable<OnPremTenantConfig> {
    return this.UpdateOnPremTenantConfigResponse(params).pipe(
      __map(_r => _r.body as OnPremTenantConfig)
    );
  }

  /**
   * Get Tenants Config.
   *
   * Get Tenant related configurations for the account.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  GetAccountTenantConfigResponse(regionId?: string): __Observable<__StrictHttpResponse<TenantConfig>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/mcm/accounts/tenant-config`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TenantConfig>;
      })
    );
  }
  /**
   * Get Tenants Config.
   *
   * Get Tenant related configurations for the account.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  GetAccountTenantConfig(regionId?: string): __Observable<TenantConfig> {
    return this.GetAccountTenantConfigResponse(regionId).pipe(
      __map(_r => _r.body as TenantConfig)
    );
  }

  /**
   * Update Tenant's Config.
   *
   * Update Tenant related configurations for the account.
   * @param body undefined
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  UpdateAccountTenantConfigResponse(body: TenantConfig,
    regionId?: string): __Observable<__StrictHttpResponse<TenantConfig>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/mcm/accounts/tenant-config`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TenantConfig>;
      })
    );
  }
  /**
   * Update Tenant's Config.
   *
   * Update Tenant related configurations for the account.
   * @param body undefined
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  UpdateAccountTenantConfig(body: TenantConfig,
    regionId?: string): __Observable<TenantConfig> {
    return this.UpdateAccountTenantConfigResponse(body, regionId).pipe(
      __map(_r => _r.body as TenantConfig)
    );
  }

  /**
   * Get Tenant's config for all clusters.
   *
   * Get Tenant related configurations for all the clusters.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  GetClustersTenantConfigResponse(regionId?: string): __Observable<__StrictHttpResponse<ClustersTenantConfigs>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/mcm/clusters/tenant-config`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ClustersTenantConfigs>;
      })
    );
  }
  /**
   * Get Tenant's config for all clusters.
   *
   * Get Tenant related configurations for all the clusters.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  GetClustersTenantConfig(regionId?: string): __Observable<ClustersTenantConfigs> {
    return this.GetClustersTenantConfigResponse(regionId).pipe(
      __map(_r => _r.body as ClustersTenantConfigs)
    );
  }

  /**
   * Update Tenant's config for clusters.
   *
   * Update Tenant related configurations for a list of clusters.
   * @param body undefined
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  UpdateClustersTenantConfigResponse(body: ClustersTenantConfigs,
    regionId?: string): __Observable<__StrictHttpResponse<UpdateClustersTenantConfigsResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/mcm/clusters/tenant-config`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UpdateClustersTenantConfigsResponse>;
      })
    );
  }
  /**
   * Update Tenant's config for clusters.
   *
   * Update Tenant related configurations for a list of clusters.
   * @param body undefined
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  UpdateClustersTenantConfig(body: ClustersTenantConfigs,
    regionId?: string): __Observable<UpdateClustersTenantConfigsResponse> {
    return this.UpdateClustersTenantConfigResponse(body, regionId).pipe(
      __map(_r => _r.body as UpdateClustersTenantConfigsResponse)
    );
  }

  /**
   * Get a list of tenants.
   * @param params The `TenantService.GetHeliosTenantsParams` containing the following parameters:
   *
   * - `tenantIds`: List of Tenant Ids to filter from.
   *
   * - `statuses`: Status of the tenant on Helios.
   *   Active and Inactive tenants are returned if this field is not specified.
   *   Also, if specified, only helios managed tenants are returned.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `managedOnHelios`: Whether managed on helios or not.
   *
   * - `includeDeletedSystems`: Whether tenants deleted on clusters should be included.
   *
   * - `clusterIdentifiers`: Specifies the list of cluster identifiers. The format is
   *   clusterId:clusterIncarnationId.
   *
   * @return Success
   */
  GetHeliosTenantsResponse(params: TenantService.GetHeliosTenantsParams): __Observable<__StrictHttpResponse<ListTenantData>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.tenantIds || []).forEach(val => {if (val != null) __params = __params.append('tenantIds', val.toString())});
    (params.statuses || []).forEach(val => {if (val != null) __params = __params.append('statuses', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.managedOnHelios != null) __params = __params.set('managedOnHelios', params.managedOnHelios.toString());
    if (params.includeDeletedSystems != null) __params = __params.set('includeDeletedSystems', params.includeDeletedSystems.toString());
    (params.clusterIdentifiers || []).forEach(val => {if (val != null) __params = __params.append('clusterIdentifiers', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/mcm/tenants`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListTenantData>;
      })
    );
  }
  /**
   * Get a list of tenants.
   * @param params The `TenantService.GetHeliosTenantsParams` containing the following parameters:
   *
   * - `tenantIds`: List of Tenant Ids to filter from.
   *
   * - `statuses`: Status of the tenant on Helios.
   *   Active and Inactive tenants are returned if this field is not specified.
   *   Also, if specified, only helios managed tenants are returned.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `managedOnHelios`: Whether managed on helios or not.
   *
   * - `includeDeletedSystems`: Whether tenants deleted on clusters should be included.
   *
   * - `clusterIdentifiers`: Specifies the list of cluster identifiers. The format is
   *   clusterId:clusterIncarnationId.
   *
   * @return Success
   */
  GetHeliosTenants(params: TenantService.GetHeliosTenantsParams): __Observable<ListTenantData> {
    return this.GetHeliosTenantsResponse(params).pipe(
      __map(_r => _r.body as ListTenantData)
    );
  }

  /**
   * Create a new Tenant on Helios.
   * @param body undefined
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  CreateHeliosTenantResponse(body: CreateTenantParams,
    regionId?: string): __Observable<__StrictHttpResponse<HeliosTenant>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/mcm/tenants`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HeliosTenant>;
      })
    );
  }
  /**
   * Create a new Tenant on Helios.
   * @param body undefined
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  CreateHeliosTenant(body: CreateTenantParams,
    regionId?: string): __Observable<HeliosTenant> {
    return this.CreateHeliosTenantResponse(body, regionId).pipe(
      __map(_r => _r.body as HeliosTenant)
    );
  }

  /**
   * Trigger a tenant backfill task.
   *
   * Trigger a tenant backfill task on Helios.
   * @param body Specifies the parameters to trigger a tenant backfill task.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  TriggerTenantBackfillTaskResponse(body: TenantBackfillBody,
    regionId?: string): __Observable<__StrictHttpResponse<TenantBackfillBody>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/mcm/tenants/backfill-tasks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TenantBackfillBody>;
      })
    );
  }
  /**
   * Trigger a tenant backfill task.
   *
   * Trigger a tenant backfill task on Helios.
   * @param body Specifies the parameters to trigger a tenant backfill task.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  TriggerTenantBackfillTask(body: TenantBackfillBody,
    regionId?: string): __Observable<TenantBackfillBody> {
    return this.TriggerTenantBackfillTaskResponse(body, regionId).pipe(
      __map(_r => _r.body as TenantBackfillBody)
    );
  }

  /**
   * Reassociate the api key to another tenant.
   *
   * Reassociate the api key to another tenant.
   * @param body Specifies the parameters to re-associate api key.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  ReassociateKeyResponse(body: ReassociateKeyRequest,
    regionId?: string): __Observable<__StrictHttpResponse<ReassociateKeyResponseBody>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/mcm/tenants/reassociate-key`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ReassociateKeyResponseBody>;
      })
    );
  }
  /**
   * Reassociate the api key to another tenant.
   *
   * Reassociate the api key to another tenant.
   * @param body Specifies the parameters to re-associate api key.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  ReassociateKey(body: ReassociateKeyRequest,
    regionId?: string): __Observable<ReassociateKeyResponseBody> {
    return this.ReassociateKeyResponse(body, regionId).pipe(
      __map(_r => _r.body as ReassociateKeyResponseBody)
    );
  }

  /**
   * Get Tenant Statistics.
   *
   * Get Statistics on Sources and Storage Domains assigned to the
   * Tenants belonging to an account.
   * @param params The `TenantService.GetTenantStatsParams` containing the following parameters:
   *
   * - `tenantIds`: List of Tenant Ids to filter from.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `includeDeleted`: If deleted tenants stats need to be included.
   *
   * @return Success
   */
  GetTenantStatsResponse(params: TenantService.GetTenantStatsParams): __Observable<__StrictHttpResponse<HeliosTenantsStats>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.tenantIds || []).forEach(val => {if (val != null) __params = __params.append('tenantIds', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.includeDeleted != null) __params = __params.set('includeDeleted', params.includeDeleted.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/mcm/tenants/stats`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HeliosTenantsStats>;
      })
    );
  }
  /**
   * Get Tenant Statistics.
   *
   * Get Statistics on Sources and Storage Domains assigned to the
   * Tenants belonging to an account.
   * @param params The `TenantService.GetTenantStatsParams` containing the following parameters:
   *
   * - `tenantIds`: List of Tenant Ids to filter from.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `includeDeleted`: If deleted tenants stats need to be included.
   *
   * @return Success
   */
  GetTenantStats(params: TenantService.GetTenantStatsParams): __Observable<HeliosTenantsStats> {
    return this.GetTenantStatsResponse(params).pipe(
      __map(_r => _r.body as HeliosTenantsStats)
    );
  }

  /**
   * Delete a Tenant on Helios.
   * @param params The `TenantService.DeleteHeliosTenantParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `deleteClusterTenants`: Whether or not to delete the tenants on the cluster, default is true.
   */
  DeleteHeliosTenantResponse(params: TenantService.DeleteHeliosTenantParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.deleteClusterTenants != null) __params = __params.set('deleteClusterTenants', params.deleteClusterTenants.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/mcm/tenants/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete a Tenant on Helios.
   * @param params The `TenantService.DeleteHeliosTenantParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `deleteClusterTenants`: Whether or not to delete the tenants on the cluster, default is true.
   */
  DeleteHeliosTenant(params: TenantService.DeleteHeliosTenantParams): __Observable<null> {
    return this.DeleteHeliosTenantResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Update Tenant properties on Helios.
   * @param params The `TenantService.UpdateHeliosTenantParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `body`:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * @return Success
   */
  UpdateHeliosTenantResponse(params: TenantService.UpdateHeliosTenantParams): __Observable<__StrictHttpResponse<HeliosTenant>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/mcm/tenants/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HeliosTenant>;
      })
    );
  }
  /**
   * Update Tenant properties on Helios.
   * @param params The `TenantService.UpdateHeliosTenantParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `body`:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * @return Success
   */
  UpdateHeliosTenant(params: TenantService.UpdateHeliosTenantParams): __Observable<HeliosTenant> {
    return this.UpdateHeliosTenantResponse(params).pipe(
      __map(_r => _r.body as HeliosTenant)
    );
  }

  /**
   * Perform actions on a Helios Tenant.
   *
   * Perform actions like activate and deactivate on a given Tenant on Helios.
   * @param params The `TenantService.PerformHeliosTenantActionParams` containing the following parameters:
   *
   * - `id`: The Tenant id.
   *
   * - `body`: Specifies the parameters to perform an action on a Tenant.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * @return Success
   */
  PerformHeliosTenantActionResponse(params: TenantService.PerformHeliosTenantActionParams): __Observable<__StrictHttpResponse<HeliosTenant>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/mcm/tenants/${encodeURIComponent(params.id)}/actions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HeliosTenant>;
      })
    );
  }
  /**
   * Perform actions on a Helios Tenant.
   *
   * Perform actions like activate and deactivate on a given Tenant on Helios.
   * @param params The `TenantService.PerformHeliosTenantActionParams` containing the following parameters:
   *
   * - `id`: The Tenant id.
   *
   * - `body`: Specifies the parameters to perform an action on a Tenant.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * @return Success
   */
  PerformHeliosTenantAction(params: TenantService.PerformHeliosTenantActionParams): __Observable<HeliosTenant> {
    return this.PerformHeliosTenantActionResponse(params).pipe(
      __map(_r => _r.body as HeliosTenant)
    );
  }

  /**
   * Assign properties to a tenant.
   *
   * Assign properties like policies etc. to a tenant on a given cluster.
   * The API expects a list of all the assignments (policies etc.) that are
   * supposed to be associated to the Tenant. The list of assignments passed
   * get assigned to the Tenant and anything else that was already assigned
   * gets unassigned.
   * @param params The `TenantService.HeliosAssignPropertiesToTenantParams` containing the following parameters:
   *
   * - `id`: The Tenant id.
   *
   * - `body`:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * @return Success
   */
  HeliosAssignPropertiesToTenantResponse(params: TenantService.HeliosAssignPropertiesToTenantParams): __Observable<__StrictHttpResponse<HeliosTenantAssignmentsResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/mcm/tenants/${encodeURIComponent(params.id)}/assignments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HeliosTenantAssignmentsResult>;
      })
    );
  }
  /**
   * Assign properties to a tenant.
   *
   * Assign properties like policies etc. to a tenant on a given cluster.
   * The API expects a list of all the assignments (policies etc.) that are
   * supposed to be associated to the Tenant. The list of assignments passed
   * get assigned to the Tenant and anything else that was already assigned
   * gets unassigned.
   * @param params The `TenantService.HeliosAssignPropertiesToTenantParams` containing the following parameters:
   *
   * - `id`: The Tenant id.
   *
   * - `body`:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * @return Success
   */
  HeliosAssignPropertiesToTenant(params: TenantService.HeliosAssignPropertiesToTenantParams): __Observable<HeliosTenantAssignmentsResult> {
    return this.HeliosAssignPropertiesToTenantResponse(params).pipe(
      __map(_r => _r.body as HeliosTenantAssignmentsResult)
    );
  }

  /**
   * Assign a Cluster to a tenant.
   * @param params The `TenantService.AssignClusterToTenantParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `body`:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * @return Success
   */
  AssignClusterToTenantResponse(params: TenantService.AssignClusterToTenantParams): __Observable<__StrictHttpResponse<HeliosTenant>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/mcm/tenants/${encodeURIComponent(params.id)}/clusters`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HeliosTenant>;
      })
    );
  }
  /**
   * Assign a Cluster to a tenant.
   * @param params The `TenantService.AssignClusterToTenantParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `body`:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * @return Success
   */
  AssignClusterToTenant(params: TenantService.AssignClusterToTenantParams): __Observable<HeliosTenant> {
    return this.AssignClusterToTenantResponse(params).pipe(
      __map(_r => _r.body as HeliosTenant)
    );
  }

  /**
   * Enable Helios Management for Tenant.
   *
   * For a specific, TenantId enable it to be managed by Helios.
   * @param params The `TenantService.ConfirmTenantParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `body`:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * @return Success
   */
  ConfirmTenantResponse(params: TenantService.ConfirmTenantParams): __Observable<__StrictHttpResponse<HeliosTenant>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/mcm/tenants/${encodeURIComponent(params.id)}/manage`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HeliosTenant>;
      })
    );
  }
  /**
   * Enable Helios Management for Tenant.
   *
   * For a specific, TenantId enable it to be managed by Helios.
   * @param params The `TenantService.ConfirmTenantParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `body`:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * @return Success
   */
  ConfirmTenant(params: TenantService.ConfirmTenantParams): __Observable<HeliosTenant> {
    return this.ConfirmTenantResponse(params).pipe(
      __map(_r => _r.body as HeliosTenant)
    );
  }

  /**
   * Get the status of a tenant migration action
   * @param params The `TenantService.GetTenantMigrationActionsParams` containing the following parameters:
   *
   * - `tenantId`: Specifies the tenant ID of the user on which the action is performed.
   *
   * - `service`: Specifies the cluster service on which this action needs to be performed.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `action_incarnation_id`: Retry count for the action. If an action needs to be retried, then clients will increment action_incarnation_id and can send the same request again
   *
   * - `action`: Specifies the action which will be performed on the tenant.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  getTenantMigrationActionsResponse(params: TenantService.GetTenantMigrationActionsParams): __Observable<__StrictHttpResponse<TenantMigrationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.tenantId != null) __params = __params.set('tenantId', params.tenantId.toString());
    if (params.service != null) __params = __params.set('service', params.service.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.actionIncarnationId != null) __params = __params.set('action_incarnation_id', params.actionIncarnationId.toString());
    if (params.action != null) __params = __params.set('action', params.action.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/tenant-migration/actions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TenantMigrationResult>;
      })
    );
  }
  /**
   * Get the status of a tenant migration action
   * @param params The `TenantService.GetTenantMigrationActionsParams` containing the following parameters:
   *
   * - `tenantId`: Specifies the tenant ID of the user on which the action is performed.
   *
   * - `service`: Specifies the cluster service on which this action needs to be performed.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `action_incarnation_id`: Retry count for the action. If an action needs to be retried, then clients will increment action_incarnation_id and can send the same request again
   *
   * - `action`: Specifies the action which will be performed on the tenant.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  getTenantMigrationActions(params: TenantService.GetTenantMigrationActionsParams): __Observable<TenantMigrationResult> {
    return this.getTenantMigrationActionsResponse(params).pipe(
      __map(_r => _r.body as TenantMigrationResult)
    );
  }

  /**
   * Perform Tenant Migration Action
   *
   * Perform an action on a DMaaS tenant.
   * @param params The `TenantService.PerformTenantMigrationActionParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to perform a tenant migration action.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  PerformTenantMigrationActionResponse(params: TenantService.PerformTenantMigrationActionParams): __Observable<__StrictHttpResponse<TenantMigrationAction>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/tenant-migration/actions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TenantMigrationAction>;
      })
    );
  }
  /**
   * Perform Tenant Migration Action
   *
   * Perform an action on a DMaaS tenant.
   * @param params The `TenantService.PerformTenantMigrationActionParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to perform a tenant migration action.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  PerformTenantMigrationAction(params: TenantService.PerformTenantMigrationActionParams): __Observable<TenantMigrationAction> {
    return this.PerformTenantMigrationActionResponse(params).pipe(
      __map(_r => _r.body as TenantMigrationAction)
    );
  }

  /**
   * Get a list of Tenants.
   * @param params The `TenantService.ListTenantsParams` containing the following parameters:
   *
   * - `statuses`: Filter by current status of tenant.
   *   If left blank, only active and inactive tenants are returned.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `ids`: List of tenantIds to filter.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  ListTenantsResponse(params: TenantService.ListTenantsParams): __Observable<__StrictHttpResponse<TenantsInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.statuses || []).forEach(val => {if (val != null) __params = __params.append('statuses', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/tenants`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TenantsInfo>;
      })
    );
  }
  /**
   * Get a list of Tenants.
   * @param params The `TenantService.ListTenantsParams` containing the following parameters:
   *
   * - `statuses`: Filter by current status of tenant.
   *   If left blank, only active and inactive tenants are returned.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `ids`: List of tenantIds to filter.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  ListTenants(params: TenantService.ListTenantsParams): __Observable<TenantsInfo> {
    return this.ListTenantsResponse(params).pipe(
      __map(_r => _r.body as TenantsInfo)
    );
  }

  /**
   * Create a new Tenant.
   * @param params The `TenantService.CreateTenantParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateTenantResponse(params: TenantService.CreateTenantParams): __Observable<__StrictHttpResponse<TenantInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/tenants`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TenantInfo>;
      })
    );
  }
  /**
   * Create a new Tenant.
   * @param params The `TenantService.CreateTenantParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateTenant(params: TenantService.CreateTenantParams): __Observable<TenantInfo> {
    return this.CreateTenantResponse(params).pipe(
      __map(_r => _r.body as TenantInfo)
    );
  }

  /**
   * Get a Swift configuration.
   *
   * Get a Swift configuration.
   * @param params The `TenantService.GetTenantSwiftParams` containing the following parameters:
   *
   * - `tenantId`: Specifies the tenant Id.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetTenantSwiftResponse(params: TenantService.GetTenantSwiftParams): __Observable<__StrictHttpResponse<SwiftParams>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.tenantId != null) __params = __params.set('tenantId', params.tenantId.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/tenants/swift`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SwiftParams>;
      })
    );
  }
  /**
   * Get a Swift configuration.
   *
   * Get a Swift configuration.
   * @param params The `TenantService.GetTenantSwiftParams` containing the following parameters:
   *
   * - `tenantId`: Specifies the tenant Id.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetTenantSwift(params: TenantService.GetTenantSwiftParams): __Observable<SwiftParams> {
    return this.GetTenantSwiftResponse(params).pipe(
      __map(_r => _r.body as SwiftParams)
    );
  }

  /**
   * Update a Swift configuration.
   *
   * Update a Swift configuration.
   * @param params The `TenantService.UpdateTenantSwiftParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to update a Swift configuration.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateTenantSwiftResponse(params: TenantService.UpdateTenantSwiftParams): __Observable<__StrictHttpResponse<SwiftParams>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/tenants/swift`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SwiftParams>;
      })
    );
  }
  /**
   * Update a Swift configuration.
   *
   * Update a Swift configuration.
   * @param params The `TenantService.UpdateTenantSwiftParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to update a Swift configuration.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateTenantSwift(params: TenantService.UpdateTenantSwiftParams): __Observable<SwiftParams> {
    return this.UpdateTenantSwiftResponse(params).pipe(
      __map(_r => _r.body as SwiftParams)
    );
  }

  /**
   * Register Swift service on a Keystone server.
   *
   * Register Swift service on Keystone server.
   * @param params The `TenantService.RegisterSwiftParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to register a Swift service on Keystone server.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  RegisterSwiftResponse(params: TenantService.RegisterSwiftParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/tenants/swift/register`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Register Swift service on a Keystone server.
   *
   * Register Swift service on Keystone server.
   * @param params The `TenantService.RegisterSwiftParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to register a Swift service on Keystone server.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  RegisterSwift(params: TenantService.RegisterSwiftParams): __Observable<null> {
    return this.RegisterSwiftResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Unregister Swift service from a Keystone server.
   *
   * Unregister Swift service from Keystone server.
   * @param params The `TenantService.UnregisterSwiftParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to unregister a Swift service from Keystone server.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  UnregisterSwiftResponse(params: TenantService.UnregisterSwiftParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/tenants/swift/unregister`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Unregister Swift service from a Keystone server.
   *
   * Unregister Swift service from Keystone server.
   * @param params The `TenantService.UnregisterSwiftParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to unregister a Swift service from Keystone server.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  UnregisterSwift(params: TenantService.UnregisterSwiftParams): __Observable<null> {
    return this.UnregisterSwiftResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get Tenant by ID.
   * @param params The `TenantService.GetTenantByIDParams` containing the following parameters:
   *
   * - `id`: The Tenant id.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetTenantByIDResponse(params: TenantService.GetTenantByIDParams): __Observable<__StrictHttpResponse<TenantInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/tenants/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TenantInfo>;
      })
    );
  }
  /**
   * Get Tenant by ID.
   * @param params The `TenantService.GetTenantByIDParams` containing the following parameters:
   *
   * - `id`: The Tenant id.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetTenantByID(params: TenantService.GetTenantByIDParams): __Observable<TenantInfo> {
    return this.GetTenantByIDResponse(params).pipe(
      __map(_r => _r.body as TenantInfo)
    );
  }

  /**
   * Update Tenant.
   *
   * Update Tenant's properties.
   * @param params The `TenantService.UpdateTenantParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `body`:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateTenantResponse(params: TenantService.UpdateTenantParams): __Observable<__StrictHttpResponse<TenantInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/tenants/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TenantInfo>;
      })
    );
  }
  /**
   * Update Tenant.
   *
   * Update Tenant's properties.
   * @param params The `TenantService.UpdateTenantParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `body`:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateTenant(params: TenantService.UpdateTenantParams): __Observable<TenantInfo> {
    return this.UpdateTenantResponse(params).pipe(
      __map(_r => _r.body as TenantInfo)
    );
  }

  /**
   * Delete Tenant with given ID.
   * @param params The `TenantService.DeleteTenantParams` containing the following parameters:
   *
   * - `id`: The Tenant id.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteTenantResponse(params: TenantService.DeleteTenantParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/tenants/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete Tenant with given ID.
   * @param params The `TenantService.DeleteTenantParams` containing the following parameters:
   *
   * - `id`: The Tenant id.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteTenant(params: TenantService.DeleteTenantParams): __Observable<null> {
    return this.DeleteTenantResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Perform actions on a Tenant.
   *
   * Perform actions like activate and deactivate on a given Tenant.
   * @param params The `TenantService.PerformTenantActionParams` containing the following parameters:
   *
   * - `id`: The Tenant id.
   *
   * - `body`: Specifies the parameters to perform an action on a Tenant.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  PerformTenantActionResponse(params: TenantService.PerformTenantActionParams): __Observable<__StrictHttpResponse<TenantInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/tenants/${encodeURIComponent(params.id)}/actions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TenantInfo>;
      })
    );
  }
  /**
   * Perform actions on a Tenant.
   *
   * Perform actions like activate and deactivate on a given Tenant.
   * @param params The `TenantService.PerformTenantActionParams` containing the following parameters:
   *
   * - `id`: The Tenant id.
   *
   * - `body`: Specifies the parameters to perform an action on a Tenant.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  PerformTenantAction(params: TenantService.PerformTenantActionParams): __Observable<TenantInfo> {
    return this.PerformTenantActionResponse(params).pipe(
      __map(_r => _r.body as TenantInfo)
    );
  }

  /**
   * Get tenant assignments.
   *
   * Get all assigned properties like storage domain, entities, policies,
   * objects, views etc for a given tenant.
   * @param params The `TenantService.GetAssignedPropertiesForTenantParams` containing the following parameters:
   *
   * - `id`: The Tenant id.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetAssignedPropertiesForTenantResponse(params: TenantService.GetAssignedPropertiesForTenantParams): __Observable<__StrictHttpResponse<TenantAssignmentProperties>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/tenants/${encodeURIComponent(params.id)}/assignments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TenantAssignmentProperties>;
      })
    );
  }
  /**
   * Get tenant assignments.
   *
   * Get all assigned properties like storage domain, entities, policies,
   * objects, views etc for a given tenant.
   * @param params The `TenantService.GetAssignedPropertiesForTenantParams` containing the following parameters:
   *
   * - `id`: The Tenant id.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetAssignedPropertiesForTenant(params: TenantService.GetAssignedPropertiesForTenantParams): __Observable<TenantAssignmentProperties> {
    return this.GetAssignedPropertiesForTenantResponse(params).pipe(
      __map(_r => _r.body as TenantAssignmentProperties)
    );
  }

  /**
   * Update assginment of properties for a tenant.
   *
   * Assign/Unassign properties like storage domain, entities, policies etc.
   * to the tenant.
   * The API expects a list of all the assignments (policies etc.) that are
   * supposed to be associated to the Tenant. The list of assignments passed
   * get assigned to the Tenant and anything else that was already assigned
   * gets unassigned.
   * In case a few objects fail the assignment and some objects get assigned,
   * error is returned for all assignments except for policies.
   * @param params The `TenantService.AssignPropertiesToTenantParams` containing the following parameters:
   *
   * - `id`: The Tenant id.
   *
   * - `body`:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  AssignPropertiesToTenantResponse(params: TenantService.AssignPropertiesToTenantParams): __Observable<__StrictHttpResponse<TenantAssignments>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/tenants/${encodeURIComponent(params.id)}/assignments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TenantAssignments>;
      })
    );
  }
  /**
   * Update assginment of properties for a tenant.
   *
   * Assign/Unassign properties like storage domain, entities, policies etc.
   * to the tenant.
   * The API expects a list of all the assignments (policies etc.) that are
   * supposed to be associated to the Tenant. The list of assignments passed
   * get assigned to the Tenant and anything else that was already assigned
   * gets unassigned.
   * In case a few objects fail the assignment and some objects get assigned,
   * error is returned for all assignments except for policies.
   * @param params The `TenantService.AssignPropertiesToTenantParams` containing the following parameters:
   *
   * - `id`: The Tenant id.
   *
   * - `body`:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  AssignPropertiesToTenant(params: TenantService.AssignPropertiesToTenantParams): __Observable<TenantAssignments> {
    return this.AssignPropertiesToTenantResponse(params).pipe(
      __map(_r => _r.body as TenantAssignments)
    );
  }
}

module TenantService {

  /**
   * Parameters for UpdateOnPremTenantConfig
   */
  export interface UpdateOnPremTenantConfigParams {
    body: OnPremTenantConfig;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetHeliosTenants
   */
  export interface GetHeliosTenantsParams {

    /**
     * List of Tenant Ids to filter from.
     */
    tenantIds?: Array<null | string>;

    /**
     * Status of the tenant on Helios.
     * Active and Inactive tenants are returned if this field is not specified.
     * Also, if specified, only helios managed tenants are returned.
     */
    statuses?: Array<null | 'Active' | 'Inactive' | 'MarkedForDeletion' | 'Deleted'>;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Whether managed on helios or not.
     */
    managedOnHelios?: null | boolean;

    /**
     * Whether tenants deleted on clusters should be included.
     */
    includeDeletedSystems?: null | boolean;

    /**
     * Specifies the list of cluster identifiers. The format is
     * clusterId:clusterIncarnationId.
     */
    clusterIdentifiers?: Array<string>;
  }

  /**
   * Parameters for GetTenantStats
   */
  export interface GetTenantStatsParams {

    /**
     * List of Tenant Ids to filter from.
     */
    tenantIds?: null | Array<string>;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * If deleted tenants stats need to be included.
     */
    includeDeleted?: null | boolean;
  }

  /**
   * Parameters for DeleteHeliosTenant
   */
  export interface DeleteHeliosTenantParams {
    id: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Whether or not to delete the tenants on the cluster, default is true.
     */
    deleteClusterTenants?: boolean;
  }

  /**
   * Parameters for UpdateHeliosTenant
   */
  export interface UpdateHeliosTenantParams {
    id: null | string;
    body: UpdateHeliosTenantBody;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;
  }

  /**
   * Parameters for PerformHeliosTenantAction
   */
  export interface PerformHeliosTenantActionParams {

    /**
     * The Tenant id.
     */
    id: string;

    /**
     * Specifies the parameters to perform an action on a Tenant.
     */
    body: TenantActionBody;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;
  }

  /**
   * Parameters for HeliosAssignPropertiesToTenant
   */
  export interface HeliosAssignPropertiesToTenantParams {

    /**
     * The Tenant id.
     */
    id: string;
    body: HeliosTenantAssignmentsParams;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;
  }

  /**
   * Parameters for AssignClusterToTenant
   */
  export interface AssignClusterToTenantParams {
    id: null | string;
    body: AssignClusterToTenantParamsBody;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;
  }

  /**
   * Parameters for ConfirmTenant
   */
  export interface ConfirmTenantParams {
    id: null | string;
    body: ConfirmTenantParamsBody;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;
  }

  /**
   * Parameters for getTenantMigrationActions
   */
  export interface GetTenantMigrationActionsParams {

    /**
     * Specifies the tenant ID of the user on which the action is performed.
     */
    tenantId?: string;

    /**
     * Specifies the cluster service on which this action needs to be performed.
     */
    service?: 'kMagneto' | 'kYoda' | 'kIcebox';

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Retry count for the action. If an action needs to be retried, then clients will increment action_incarnation_id and can send the same request again
     */
    actionIncarnationId?: number;

    /**
     * Specifies the action which will be performed on the tenant.
     */
    action?: 'StartMigration' | 'StopMigration' | 'DisableInlineChanges' | 'EnableInlineChanges';

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for PerformTenantMigrationAction
   */
  export interface PerformTenantMigrationActionParams {

    /**
     * Specifies the parameters to perform a tenant migration action.
     */
    body: TenantMigrationAction;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for ListTenants
   */
  export interface ListTenantsParams {

    /**
     * Filter by current status of tenant.
     * If left blank, only active and inactive tenants are returned.
     */
    statuses?: Array<null | 'Active' | 'Inactive' | 'MarkedForDeletion' | 'Deleted'>;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * List of tenantIds to filter.
     */
    ids?: Array<null | string>;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CreateTenant
   */
  export interface CreateTenantParams {
    body: CreateTenantParams & {network?: TenantNetwork};

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetTenantSwift
   */
  export interface GetTenantSwiftParams {

    /**
     * Specifies the tenant Id.
     */
    tenantId?: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateTenantSwift
   */
  export interface UpdateTenantSwiftParams {

    /**
     * Specifies the parameters to update a Swift configuration.
     */
    body: SwiftParams;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for RegisterSwift
   */
  export interface RegisterSwiftParams {

    /**
     * Specifies the parameters to register a Swift service on Keystone server.
     */
    body: RegisterSwiftParams;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UnregisterSwift
   */
  export interface UnregisterSwiftParams {

    /**
     * Specifies the parameters to unregister a Swift service from Keystone server.
     */
    body: UnregisterSwiftParams;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetTenantByID
   */
  export interface GetTenantByIDParams {

    /**
     * The Tenant id.
     */
    id: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateTenant
   */
  export interface UpdateTenantParams {
    id: null | string;
    body: UpdateTenantBody;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for DeleteTenant
   */
  export interface DeleteTenantParams {

    /**
     * The Tenant id.
     */
    id: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for PerformTenantAction
   */
  export interface PerformTenantActionParams {

    /**
     * The Tenant id.
     */
    id: string;

    /**
     * Specifies the parameters to perform an action on a Tenant.
     */
    body: TenantActionBody;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetAssignedPropertiesForTenant
   */
  export interface GetAssignedPropertiesForTenantParams {

    /**
     * The Tenant id.
     */
    id: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for AssignPropertiesToTenant
   */
  export interface AssignPropertiesToTenantParams {

    /**
     * The Tenant id.
     */
    id: string;
    body: TenantAssignmentsParams;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { TenantService }
