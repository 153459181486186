import { Injectable } from '@angular/core';
import { DateFilterRange, getDateRangeFilter, timeRangeParameterMap } from '@cohesity/helix';
import { StateParams, StateService, UIRouterGlobals } from '@uirouter/core';
import { throttle } from 'lodash';
import moment, { Moment } from 'moment';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { shareReplay, tap } from 'rxjs/operators';

// Session key for saving selected view state - calendar or table.
const viewStateSessionKey = 'coh-protection-group-details_viewState';

// Store incoming all state params.
let savedStateParams: Partial<StateParams> = {};

// State change timeout timer ID.
let stateParamsTimeoutId: number;

/**
 * View state for protection group details page.
 */
export type ViewState = 'calendar' | 'table';

/**
 * Protection Group Details default view state (table or calendar).
 */
export const defaultView: ViewState = 'table';

/**
 * Timeout for delaying reloading run's calendar and table views
 * in case reload function are invoked too often.
 */
export const RELOAD_THROTTLE_TIMEOUT = 10_000;

/**
 * @description
 * Service shared by `ProtectionGroupDetailsComponent` and its sub-components.
 */
@Injectable()
export class ProtectionRunsService {
  /**
   * Sets view state.
   */
  set viewState(viewState: ViewState) {
    this._viewState = viewState;
    this.viewStateSource$.next(viewState);
  }

  /**
   * Returns view state.
   */
  get viewState(): ViewState {
    return this._viewState;
  }

  /**
   * Selected view state.
   */
  private _viewState: ViewState;

  /**
   * View state change subject for detecting viewState changes.
   */
  private readonly viewStateSource$ = new ReplaySubject<ViewState>();

  /**
   * Observable for view state change.
   */
  readonly viewState$ = this.viewStateSource$.pipe(tap(viewState => {
    this.saveStateParams({ viewState }, false);
  }), shareReplay(1));

  /**
   * Calendar view selected calendar date.
   */
  private readonly calendarDateSource$ = new BehaviorSubject<Moment>(moment());

  /**
   * Observable for calendar date change.
   */
  readonly calendarDate$ = this.calendarDateSource$.pipe(tap(date => {
    if (date) {
      this.saveStateParams({ date: date.valueOf() });
    }
  }), shareReplay(1));

  /**
   * Returns selected calendar date.
   */
  get calendarDate(): Moment {
    return this._calendarDate;
  }

  /**
   * Sets calendar date.
   */
  set calendarDate(date: Moment) {
    if (date && !moment(this._calendarDate).isSame(date)) {
      // do not change calendar start and end dates if selected date within same month
      if (!date.isSame(this._calendarDate, 'month')) {
        this.calendarRange$.next(this.getMonthDates(date));
      }

      this._calendarDate = date;
      this.calendarDateSource$.next(date);
    }
  }

  /**
   * Selected calendar date in calendar view.
   */
  private _calendarDate: Moment;

  /**
   * Start and end dates used to query runs.
   */
  readonly calendarRange$ = new ReplaySubject<DateFilterRange<Moment>>();

  /**
   * Start and end dates used to query runs.
   */
  private readonly tableDateRangeSource$ = new BehaviorSubject<DateFilterRange<Moment>>(null);

  /**
   * Sets table view date range.
   */
  set tableDateRange(range: DateFilterRange<Moment>) {
    this.tableDateRangeSource$.next(range);
  }

  /**
   * Observable for detecting table view date range changes.
   */
  readonly tableDateRange$ = this.tableDateRangeSource$.pipe(tap(range => {
    if (range) {
      const { start, end, timeframe } = range;
      this.saveStateParams({
        rangeStart: !timeframe && start ? start.valueOf() : undefined,
        rangeEnd: !timeframe && end ? end.valueOf() : undefined,
        timeRange: timeframe ? timeRangeParameterMap[timeframe] : undefined,
      });
    }
  }), shareReplay(1));

  /**
   * Triggers runs table reload.
   */
  private readonly reloadRunsTableSource$ = new BehaviorSubject<number>(0);

  /**
   * Observable other services and components can use to observe table reload.
   */
  readonly reloadRunsTable$ = this.reloadRunsTableSource$.pipe(shareReplay(1));

  /**
   * Triggers runs calendar reload.
   */
  private readonly reloadCalendarSource$ = new BehaviorSubject<number>(0);

  /**
   * Observable other services and components can use to observe calendar reload.
   */
  readonly reloadCalendar$ = this.reloadCalendarSource$.pipe(shareReplay(1));

  /**
   * Notify listeners to reload protection group details calendar runs.
   */
  readonly reloadCalendar = throttle(() => {
    this.reloadCalendarSource$.next(this.reloadRunsTableSource$.value + 1);
  }, RELOAD_THROTTLE_TIMEOUT);

  /**
   * Notify listeners to reload protection group details table.
   */
  readonly reloadTable = throttle(() => {
    this.reloadRunsTableSource$.next(this.reloadRunsTableSource$.value + 1);
  }, RELOAD_THROTTLE_TIMEOUT);

  constructor(private stateService: StateService, private uiRouterGlobals: UIRouterGlobals) {
    this.initStateParamValues();
  }

  /**
   * Initialize values from state params.
   */
  private initStateParamValues() {
    const { date, timeRange, rangeStart, rangeEnd, viewState = defaultView } = this.uiRouterGlobals.params;

    savedStateParams = {};

    // initialize calendar date from state params
    const timestamp = Number(date);
    let calDate = moment();

    if (!isNaN(timestamp)) {
      calDate = moment(timestamp);
    }

    // initialize calendar start and end month dates for selected date
    this.calendarRange$.next(this.getMonthDates(calDate));
    this.calendarDate = calDate;

    // initialize table date range from state params
    const tr = getDateRangeFilter(timeRange);
    const rs = Number(rangeStart);
    const re = Number(rangeEnd);

    if (tr.timeframe) {
      this.tableDateRange = tr;
    } else if (!isNaN(rs) && !isNaN(re)) {
      this.tableDateRange = {
        start: moment(rs),
        end: moment(re)
      };
    } else {
      // preselect last 7 days if date range for runs table is not set
      this.tableDateRange = getDateRangeFilter('past7days');
    }

    const sessionViewState = sessionStorage.getItem(viewStateSessionKey) as ViewState;
    this.viewState = sessionViewState || viewState;
  }



  /**
   * Saves state params to session storage and updates query params.
   *
   * @param  stateParams  State params to save to session storage.
   * @param  replace      Replace current browser history with new URL params.
   */
  async saveStateParams(stateParams: Partial<StateParams>, replace = true) {
    const { params, transition } = this.uiRouterGlobals;

    // wait for existing transitions to finish to avoid transition errors
    if (transition) {
      await transition.promise;
    }

    savedStateParams = {
      ...params,
      ...savedStateParams,
      ...stateParams,
    };

    clearTimeout(stateParamsTimeoutId);
    stateParamsTimeoutId = window.setTimeout(() => {
      if (savedStateParams.viewState) {
        sessionStorage.setItem(viewStateSessionKey, savedStateParams.viewState);
      }

      // reload state
      this.stateService.go('.', savedStateParams, {
        reload: false,
        location: replace ? 'replace' : true,
      });
    });
  }

  /**
   * Returns an array of start and end dates of the month of specified date.
   *
   * @param    date  Date to get start and end date.
   * @returns  Array of start and end dates for specified month.
   */
  getMonthDates(date: Moment): DateFilterRange<Moment> {
    return {
      start: moment(date).startOf('month'),
      end: moment(date).endOf('month'),
    };
  }
}
