// Component: SQL Dashboard

;(function(angular, undefined) {
  'use strict';

  angular.module('C.dbDetails')
    .controller('DbDetailController', DbDetailCtrlFn)
    .component('dbDetail', {
      templateUrl: 'app/dashboard/sql/detail/detail.html',
      controller: 'DbDetailController',
    });

  /**
   * @ngdoc component
   * @name C.dbDetails:dbDetail
   *
   * @description
   * DB Object Landing Page
   *
   * @example
   * <db-detail></db-detail>
   */
  function DbDetailCtrlFn(_, $filter, $q, $rootScope, $state, $translate,
    DbObjectService, evalAJAX, FEATURE_FLAGS,
    SQL_RECOVERY_MODEL_DISPLAY, SQL_DATABASE_STATE_DISPLAY_KEY,
    StateManagementService) {
    var $ctrl = this;


    var WARNING_STATES = [
      'kRestoring',
      'kRecovering',
      'kRecoveryPending',
      'kSuspect',
      'kCopying',
    ];
    var ERROR_STATES = ['kEmergency'];

    _.assign($ctrl, {
      $onInit: $onInit,
      back: back,
    });

    $ctrl.tabConfig = [{
      headingKey: 'summary',
      route: 'db-details.summary',
      params: $state.params,
    }, {
      headingKey: 'backupActivity',
      route: 'db-details.activity',
      params: $state.params,
    }];

    /**
     * Initialize the detail component
     *
     * @method $onInit
     */
    function $onInit() {
      _.assign($ctrl, $state.params);
      $q.all({
        dbStats: DbObjectService.getDbAppInfo(
          $ctrl.id, $ctrl.sourceId, $ctrl.env),
        restores: DbObjectService.getRestoreTasks($ctrl.env, $ctrl.id),
        snapshots: DbObjectService.findSnapshots(
          $ctrl.id, $ctrl.rootSourceId, $ctrl.env),
      }).then(function onSuccess(results) {
          $ctrl.data = results.dbStats;
          $ctrl.latestSnapshot = results.snapshots.latestSnapshot;
          $ctrl.isProtected =_.get(
            $ctrl.data, 'db.protectedSourcesSummary[0].leavesCount', 0) > 0;
          $ctrl.stats = _getStats($ctrl.data.db, results.restores.stats);
          $ctrl.actionButtons = _getActions(results.dbStats);
      }, evalAJAX.errorMessage);
    }

    /**
     * Go back to the previous page, or fallback to the sql dashboard
     *
     * @method back
     */
    function back() {
      StateManagementService.goToPreviousState('sql-dashboard.databases');
    }

    /**
     * Get page actions. If there are snapshots available, the options should
     * be to recover or clone to the latest snapshot. If it is unprotected, it
     * should show the protect button. It's possible to show all three buttons.
     * On a vmware host, the protect workflow should select the entire host.
     * On a physical host, the protect worflow should select only the database.
     *
     * @method   _getActions
     * @param    {object}    info   The info object returned by the service
     * @return   {array}     The context menu buttons.
     */
    function _getActions(info) {

      var buttons = [];

      if (!$ctrl.isProtected &&
        info.source &&
        info.db &&
        $ctrl.sourceId &&
        $ctrl.env) {
        // If the source is vmware, then we need to auto select the host system
        // when we attempt to protect it. Otherwise, we can select only the
        // database.
        var protectSource =
          [info.source.protectionSource.environment === 'kVMware' ?
          info.source.protectionSource : info.db.protectionSource];

        buttons.push({
          translateKey: 'protect',
          state: 'job-modify',
          stateParams: {
            parentSourceId: info.parentProtectionSource,
            environments: [$ctrl.env],
            parentSourceEnvironment: $ctrl.env,
            protectSources: protectSource,
          },
        });
      }

      if ($ctrl.latestSnapshot) {
        // recover and clone to latest
        var recoverParams = {
          dbType: 'sql',
          jobId: $ctrl.latestSnapshot._jobId,
          jobInstanceId: $ctrl.latestSnapshot.instanceId.jobInstanceId,
          jobRunStartTime: $ctrl.latestSnapshot.snapshotTimestampUsecs,
          jobUid: $ctrl.latestSnapshot._jobUid,
          entityId: $ctrl.id,
          sourceId: $ctrl.rootSourceId,
        };
        buttons.push({
          translateKey: 'recoverToLatest',
          state: 'recover-db.options',
          stateParams: recoverParams,
        }, {
          translateKey: 'cloneToLatest',
          state: 'clone-db.options',
          stateParams: recoverParams,
        });
      }

      return buttons;
    }

    /**
     * Gets db data for the stats list
     *
     * @method   _getStats
     * @param    {object}   db             db object
     * @param    {object}   restoreStats   stats with active clone and recovery
     *                                     counts
     * @return   {array}    list of items for the stats widget
     */
    function _getStats(db, restoreStats) {
      if (!db) {
        return [];
      }
      var dbState = db._envProtectionSource.sqlServerDbState;
      var size = $ctrl.isProtected ?
        db.protectedSourcesSummary[0].totalLogicalSize :
        db.unprotectedSourcesSummary[0].totalLogicalSize;

      return [{
        label: 'status',
        value: $translate.instant(SQL_DATABASE_STATE_DISPLAY_KEY[dbState]),
        classes: {
          'status-ok': dbState === 'kOnline',
          'status-warning': WARNING_STATES.includes(dbState),
          'status-critical': ERROR_STATES.includes(dbState),
        },
      }, {
        label: 'protected',
        value: $filter('boolLabel')($ctrl.isProtected),
      }, {
        label: 'recoveryModel',
        value: $translate.instant(
          SQL_RECOVERY_MODEL_DISPLAY[db._envProtectionSource.recoveryModel]),
      }, {
        label: 'size',
        value: $filter('byteSize')(size),
      }, {
        label: 'recoveries',
        value: restoreStats.activeRecoveries,
      }, {
        label: 'clones',
        value: restoreStats.activeClones,
      }];
    }
  }

})(angular);
