import { isGaiaScope, isGaiaUser } from './gaia-utils';
import { IrisContext } from './iris-context.model';

/**
 * Returns true if the current user is a Data Insights configured user.
 *
 * @param irisContext The current iris context.
 * @returns boolean True if user is Data Insights user.
 */
export function isDiaasUser(ctx: IrisContext): boolean {
  return isGaiaUser(ctx);
}

/**
 * Returns true if the current user is in Data Insights scope.
 *
 * @param irisContext The current iris context.
 * @returns boolean True if the user in Data Insights context.
 */
export function isDiaasScope(ctx: IrisContext): boolean {
  return isGaiaScope(ctx);
}
