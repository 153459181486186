import { Injectable } from '@angular/core';
import { getUserTenantId, IrisContextService, isDmsScope } from '@cohesity/iris-core';
import { Transition } from '@uirouter/core';
import { map } from 'rxjs/operators';

import { DmsService } from '../../services';
import { GuardPriority, GuardResult, StateGuard } from '../state-guard';

/**
 * This guard is to ensure tenant regions is loaded before the page is loaded as
 * page like Dms Source list page assumes tenant regions already loaded.
 */
@Injectable({
  providedIn: 'root'
})
export class TenantRegionsGuard implements StateGuard {
  /**
   * The guard priority for the State.
   */
  guardPriority = GuardPriority.App;

  /**
   * List of states that require tenant regions to be available before loading
   */
  readonly matchedStates = [
    'dashboards.dms',
    'dms-sources.landing',
    'dms-connections',
    'dms-azure-connections',
    'health.alerts',
    'helios-audit-logs.list',
    'object-activity.listing',
  ];

  /**
   * constructor
   */
  constructor(
    private dmsService: DmsService,
    private irisCtx: IrisContextService,
  ) {}

  /**
   * OnStart of UI Router State Transition
   */
  onStart(transition: Transition): GuardResult {
    if (isDmsScope(this.irisCtx.irisContext) && this.matchedStates.includes(transition.to().name)) {
      return this.dmsService
        .getUserTenantRegions({ tenantId: getUserTenantId(this.irisCtx.irisContext) })
        .pipe(
          map(() => true),
        );
    }
    return true;
  }
}
