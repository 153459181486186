import { Component, Input, ViewEncapsulation } from '@angular/core';

/**
 * Component to wrap individual Protection Form Control items into.
 * This components adds items like the cogFormGroup container and
 * title to the Form Control which will be within it.
 *
 */
@Component({
  selector: 'coh-protection-item',
  templateUrl: './protection-item.component.html',
  styleUrls: ['./protection-item.component.scss'],

  // Colors set using apply-helix-themes() don't seem to work without this.
  encapsulation: ViewEncapsulation.None,
})
export class ProtectionItemComponent {
  /**
   * Title of the Protection Item.
   */
  @Input() title: string;

  /**
   * Indicates whether the protection item is in a loading state or not.
   */
  @Input() loading = false;

  /**
   * Different style if read only.
   */
  @Input() readOnly = false;
}
