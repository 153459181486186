// Component: App list

import { isAllClustersScope } from '@cohesity/iris-core';

;(function(angular, undefined) {
  'use strict';

  angular.module('C.appsManagement')
    .component('appList', {
      templateUrl: 'app/apps-management/app-list/app-list.html',
      controller: appListCtrlFn,
      bindings: {
        // @type   {object}   list of apps
        apps: '<',

        // @type   {boolean}   Whether to show loading animation
        loading: '<',

        // @type   {boolean}   Show/hide Run app button.
        showRunApp: '<',

        // @type   {function}   function when run app button is clicked
        runAppHandler: '<',
      },
    });

  /**
   * @ngdoc component
   * @name C.appsManagement:appList
   * @function appListCtrlFn
   *
   * @description
   * Provides list application and related functionality.
   *
   * @example
     <app-list
       apps="$ctrl.apps"
       loading="$ctrl.loading">
     </app-list>
   */
  function appListCtrlFn(_, $state, NgIrisContextService) {
    let $ctrl = this;

    _.assign($ctrl, {
      allClusterInfo: isAllClustersScope(NgIrisContextService.irisContext),

      // Methods
      goToMyApps: goToMyApps,
      viewAppInstances: viewAppInstances,
    });

    /**
     * Navigate to app instance page for given app
     *
     * @method   viewAppInstances
     * @param    {string}   app app id
     * @param    {string}   app app version
     */
    function viewAppInstances(id, version) {
      $state.go('apps-management-instances', {appUid: id, version: version});
    }

    /**
     * Navigate to my apps page based on cluster information
     *
     * @method   goToMyApps
     * @param    {object}   cluster cluster Information
     */
    function goToMyApps(cluster) {
      $state.go('apps-management.apps', {cid: cluster.clusterId});
    }
  }
})(angular);
