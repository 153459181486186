import { AppModuleConfig, StateAccessContext, StateAccessMap } from 'src/app/app-module.config';

const ngClusterCreateAccess =
  (ctx: StateAccessContext) => ctx.FEATURE_FLAGS.ngClusterCreate;

export const ClusterCreateConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'cluster-create.**',
      url: '^/cluster/create',
      loadChildren: () => import('./cluster-create.module').then(m => m.ClusterCreateModule),
    },
  ],

  getStateAccessMap(): StateAccessMap {
    return {
      'cluster-create': ngClusterCreateAccess,
      'cluster-create.welcome': ngClusterCreateAccess,
      'cluster-create.wizard': ngClusterCreateAccess,
      'cluster-create.single-node': ngClusterCreateAccess,
    };
  },
};
