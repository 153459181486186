<cog-settings-list [alwaysOpen]="true" [readOnly]="true" mode="list">
  <cog-settings-list-item
    *ngFor="let item of items"
    label="{{ item.translate ? translate(item.title) : item.title }}"
    labelWidth="sm"
  >
    <cog-settings-list-item-value-display>
      <cog-data-renderer [value]="item.value" [dataType]="item.measurement.dataType" [translate]="translate">
      </cog-data-renderer>
    </cog-settings-list-item-value-display>
  </cog-settings-list-item>
</cog-settings-list>
