// Service: LDAP Service
import { isEntityOwner } from '@cohesity/iris-core';

;(function(angular) {
  'use strict';

  angular
    .module('C')
    .service('LdapService', LdapServiceFn);

  function LdapServiceFn(_, $http, API, cModal, LdapServiceFormatter,
    SlideModalService, TenantService, NgIrisContextService, NgLDAPServiceApi) {

    var ldapCache = {};

    var ldapService = {
      addLdapProvider: addLdapProvider,
      getConnectionStatus: getConnectionStatus,
      getLdapProvider: getLdapProvider,
      getLdapProviderById: getLdapProviderById,
      getLdapProviders: getLdapProviders,
      newLdapProviderModal: newLdapProviderModal,
      openBreakAuthMappingModal: openBreakAuthMappingModal,
      removeLdapProvider: removeLdapProvider,
      resolveLdapInfo: resolveLdapInfo,
      updateLdapProvider: updateLdapProvider,
      viewLdapModal: viewLdapModal,
    };

    /**
     * Gets the list of LDAP providers, updates the cache, and returns the
     * updated cache
     *
     * @method     getLdapProviders
     * @param      {Object}  params   The params for the request.
     * @return     {Object}  Q promise carrying the server's response
     */
    function getLdapProviders(params) {
      params = params || {};
      if (params.allUnderHierarchy) {
        params.includeTenants = params.allUnderHierarchy;
      }
      return NgLDAPServiceApi.GetLdaps(params).toPromise().then(resp => {
        const ldaps = (resp?.ldaps || [])
          .map(LdapServiceFormatter.transformLdapProvider);

        // Update the cache of LDAP Providers
        ldapCache = _.keyBy(ldaps, 'id');

        _.forEach(ldaps, function forEachLdap(ldap) {
          ldap._isLdapOwner = isEntityOwner(NgIrisContextService.irisContext, ldap.tenantId);
        });

        if (params._includeTenantInfo) {
          return TenantService.resolveTenantDetails(ldaps);
        }

        return ldaps;
      });
    }

    /**
     * Gets the LDAP by providing the id.
     *
     * @method   getLdapProviderById
     * @param    {Integer}   ldapId   The id of the LDAP to get.
     * @return   {Object}    Promise resolving to the LDAP with the given id.
     */
    function getLdapProviderById(ldapId) {
      // Set includeTenants true, to get all the ldaps assigned to the tenant
      var params = {
        includeTenants: true,
        ids: ldapId,
      };

      return NgLDAPServiceApi.GetLdaps(params).toPromise().then(resp => {
        const ldap = LdapServiceFormatter.transformLdapProvider(resp?.ldaps?.[0] || {});
        ldap._isLdapOwner = isEntityOwner(NgIrisContextService.irisContext, ldap.tenantId);
        return ldap;
      });
    }

    /**
     * Returns a single LDAP Provider object.
     *
     * @method     getLdapProvider
     * @return     {Object}  from ldap cache
     */
    function getLdapProvider(id) {
      return ldapCache[id] || {};
    }

    /**
     * Returns connection status for specified LDAP Provider. We have to
     * transform both the success and failure responses because the backend
     * error messages are to be transformed into consumable front-end content.
     *
     * @method     _getConnectionStatus
     * @param      {Object}  ldapProvider   LDAP Provider config object
     * @return     {Object}  Q promise carrying the server's response
     */
    function _getConnectionStatus(ldapProvider) {
      const id = ldapProvider.id;
      return NgLDAPServiceApi.GetLdapConnectionStatus({ id }).toPromise()
        .then(LdapServiceFormatter.transformConnectionStatus || {})
        .catch(LdapServiceFormatter.transformConnectionStatus || {});
    }

    /**
     * Gets the connection status of the provided LDAP Provider and updates the
     * LDAP Provider model object.
     *
     * @method    getConnectionStatus
     * @param     {Object}    ldapProvider    ldap provider model object
     */
    function getConnectionStatus(ldapProvider) {
      return _getConnectionStatus(ldapProvider).then(
        function gotConnectionStatus(status) {
          ldapProvider._status = status;
        }
      ).finally(function gotConnectionStatusFinally() {
        ldapProvider._fetchedStatus = true;
      });
    }

    /**
     * Adds an LDAP Provider to the registry.
     *
     * @method     addLdapProvider
     * @param      {Object}  data    Request object
     * @return     {Object}  Q promise carrying the server's response
     */
    function addLdapProvider(data) {
      return NgLDAPServiceApi.CreateLdapProvider(LdapServiceFormatter.untransformLdapProvider(data)).toPromise()
        .then((response) => {
          return LdapServiceFormatter.transformLdapProvider(response || {});
        });
    }

    /**
     * Updates the LDAP Provider entry in the registry.
     *
     * @method     updateLdapProvider
     * @param      {Object}  data    Request object
     * @return     {Object}  Q promise carrying the server's response
     */
    function updateLdapProvider(data) {
      return NgLDAPServiceApi.UpdateLdapProvider(LdapServiceFormatter.untransformLdapProvider(data)).toPromise()
        .then((response) => {
          return LdapServiceFormatter.transformLdapProvider(response || {});
        });
    }

    /**
     * Removes the given LDAP Provider from the registry.
     *
     * @method     removeLdapProvider
     * @param      {Object}  data    Request object
     * @return     {Object}  Q promise carrying the server's response
     */
    function removeLdapProvider(data) {
      return NgLDAPServiceApi.DeleteLdapProvider({ id: data.id }).toPromise();
    }

    /**
     * Resolves the mapped LDAP details for the ads list.
     *
     * @method   resolveLdapInfo
     * @param    {Array}   ads   The list of ads.
     * @returns  {Array}   The modified ads list along with the ldap info.
     */
    function resolveLdapInfo(ads) {
      // Include all the ldaps to view the ldaps tagged to a tenant.
      var params = {
        allUnderHierarchy: true,
      };

      return getLdapProviders(params)
        .then(function gotLdaps(ldaps) {
          var ldapsMap = _.keyBy(ldaps, 'id');

          return _.map(ads, function mapAds(ad) {
            ad._ldap = ldapsMap[ad.ldapProviderId];
            return ad;
          });
        });
    }

    /**
     * Opens a modal prompting the user to indicate which auth provider to
     * remain mapped to the associated Storage Domains.
     *
     * @method     openBreakAuthMappingModal
     *
     * @param      {Object}  authData   The AD, LDAP, and Storage Domain data:
     * @example
     *   {
     *     adDomainName: $ctrl.ad.domainName,
     *     ldapProviderId: $ctrl.ad.ldapProviderId,
     *
     *     // A list of affected Storage Domains. Those which had been mapping
     *     // to this AD + LDAP.
     *     storageDomains:
     *       $ctrl.storageDomainsHashByLdap[$ctrl.ad.ldapProviderId],
     *   }
     * @return     {Object}  cModal instance
     */
    function openBreakAuthMappingModal(authData) {
      var modalConfig = {
        size: 'md',
        templateUrl: 'app/admin/modals/break-auth-mapping.html',
        controller: 'BreakAuthMappingController',
        resolve: {
          authData: function() { return authData; }
        },
      };

      var modalOptions = {
        titleKey: 'ldap.mappedAuthProviderInUse',
        actionButtonKey: false,
        closeButtonKey: false,
      };

      return cModal.standardModal(modalConfig, modalOptions);
    }

    /**
     * Opens and slide modal with "Create LDAP Provider" view.
     *
     * @method     newLdapProviderModal
     * @return     {Object}  promise to resolve the cSlideModal
     */
    function newLdapProviderModal() {
      return SlideModalService.newModal({
        templateUrl: 'app/admin/access-management/ldap/modify.html',
        controller: 'addLdapController as $ctrl',
        size: 'xl',
        keyboard: false,

        // To overrirde the default z-index.
        windowClass: 'c-slide-modal-window c-slide-modal-override-stack-order',
      });
    }

    /**
     * Opens the view LDAP details component inside a modal.
     *
     * @method   viewLdapModal
     * @param    {Object}   ldap   The ldap whose details are to be viewed.
     * @returns  {Object}   Promise to resolve the cSlideModal.
     */
    function viewLdapModal(ldap) {
      return SlideModalService.newModal({
        size: 'xl',
        keyboard: false,
        resolve: {
          innerComponent: 'ldapView',
          idKey: 'view-ldap',
          bindings: {
            ldapId: ldap.id,
            disableModifications: true,
          },
        },
      });
    }
    return ldapService;
  }

})(angular);
