// Component: Add Tgas to Cloud Edition

;(function(angular) {
  'use strict';

  angular.module('C.cloudEditionManager')
    .component('addCeTags', {
      bindings: {
        /**
         * The type of cloud
         *
         * @type {String}
         */
        cloudType: '<',

        /**
         * tags list array to be populated
         *
         * @type  {Object[]}
         */
        tags: '=',

        /**
         * networkTags list array to be populated
         *
         * @type  {Object[]}
         */
        networkTags: '=',

        /**
         * The label to be displayed for "Add" button
         *
         * @type  {String}
         */
        addButtonLabel: '<',

        /**
         * Any custom param for tooltip
         */
        tooltipParam: '<'
      },
      templateUrl: 'app/platform/cloud-edition-manager/add-ce-tags.html',
      controller: 'addCeTagsCtrl',
    })
    .controller('addCeTagsCtrl', addCeTagsCtrlFn);

  /**
   * @ngdoc component
   * @name C.cloudEditionManager:addCeTags
   * @function
   *
   * @description
   * Add Tags to CE to be deployed
   */
  function addCeTagsCtrlFn(_, FORMATS) {

    var $ctrl = this;

    _.assign($ctrl, {
      // properties
      FORMATS: _.assign(FORMATS, {
        networkTags: /^[a-z][a-z\-0-9]*[a-z0-9]$/,
        tags: {
          aws: {
            key: /^[a-zA-Z0-9\+\-_\=.:\/@ ]*$/,
            keyLength: 127,
            value: /^[a-zA-Z0-9\+\-_\=.:\/@ ]*$/,
            valueLength: 255,
          },
          azure: {
            key: /^[^\\<>%&?\/]+$/,
            keyLength: 511,
            value: /.+/,
            valueLength: 255,
          },
          gcp: {
            key: /^[a-zA-Z0-9\-_ ]*$/,
            keyLength: 255,
            value:/^[a-zA-Z0-9\-_ ]*$/,
            valueLength: 255,
          }
        }
      }),
      tags: [],

      // methods
      $onInit,
      addTag: addTag,
      deleteTag: deleteTag,
      doesTagExist: doesTagExist,
      validateNetworkTags: validateNetworkTags,
    });

    /**
     * Delete a tag key/value pair
     *
     * @method  deleteTag
     * @param   {Number}  index  The index of tag to be deleted
     */
    function deleteTag(index) {
      $ctrl.tags.splice(index, 1);
    }

    /**
     * Add a new tag key/value pair
     *
     * @method  addTag
     */
    function addTag(index) {
      // If no tags are present create the empty array
      // without it, users are not able to push new tags
      if (!$ctrl.tags) {
        $ctrl.tags = [];
      }
      $ctrl.tags.push({
        key: undefined,
        value: undefined,
      });
    }

    /**
     * Does the tag already exist
     *
     * @method  doesTagExist

     * @param   {String}  key   The key which is to be searched
     * @param   {Number}  index The index of the current tag
     * @return  {Boolean} True if tag already exists. False otherwise.
     */
    function doesTagExist(key, index) {
      return !$ctrl.tags.find(function findTag(tag, i) {
        return tag.key === key && i !== index;
      });
    }

    /**
     * Validate the names of network tags
     *
     * @method  validateNetworkTags
     * @param   {String[]}   selectedTags     The current selection of email
     *                                        addresses
     * @return  {Boolean}    True if tags are valid. False otherwise
     */
    function validateNetworkTags(selectedTags) {
      // set the flag if even one invalid network tag is found
      return(
        !selectedTags.length || !_.find(selectedTags, function findTag(tag) {
          return !$ctrl.FORMATS.networkTags.test(tag) || tag.length > 63;
        }) || selectedTags.length > 62
      );
    }

    /**
     * Initialize the component
     */
    function $onInit() {
      $ctrl.showNetworkTags = !!$ctrl.networkTags;
    }
  }

})(angular);
