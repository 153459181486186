import { Component, Inject, Input, OnChanges, OnInit, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { HostSettingsCheckResult } from '@cohesity/api/v1';

/**
 * Source health check matrix component. This displays a list of registration
 * health check test results so the user can take corrective action.
 *
 * @example
 *   <coh-source-health-check-matrix
 *     [showAllChecks]="true"
 *     [checks]="node.registrationInfo.registeredAppsInfo[0].hostSettingsCheckResults">
 *   </coh-source-health-check-matrix>
 *
 *   <!-- Or, in AJS -->
 *
 *   <ng2-coh-component-loader
 *     component-id="source-health-check-matrix"
 *     [component-inputs]="{
 *       checks: node.registrationInfo.registeredAppsInfo[0].hostSettingsCheckResults,
 *     }">
 *   </ng2-coh-component-loader>
 *
 *   <!-- Or as a modal/dialog -->
 *
 *   const minConfig = {
 *     component: SourceHealthCheckMatrixComponent,
 *     componentInputs: {
 *       checks: node.registrationInfo.registeredAppsInfo[0].hostSettingsCheckResults,
 *     },
 *   };
 *   DialogService.simpleDialog(null, minConfig).subscribe(console.log);
 */
@Component({
  selector: 'coh-source-health-check-matrix',
  templateUrl: './source-health-check-matrix.component.html',
  styleUrls: ['./source-health-check-matrix.component.scss'],
})
export class SourceHealthCheckMatrixComponent implements OnInit, OnChanges {
  /**
   * The Health checks to display.
   */
  @Input() checks: HostSettingsCheckResult[];

  /**
   * Default is suppress passed checks. But this enables showing all if necessary.
   */
  @Input() showAllChecks = false;

  /**
   * Icon "shapes" do display for each status.
   */
  readonly statusIcons = {
    kPass: 'check',
    kWarning: 'warning',
    kFail: 'close',
  };

  /**
   * Icon color classes for each status.
   */
  readonly statusColors = {
    kPass: 'primary',
    kWarning: 'accent',
    kFail: 'warn',
  };

  /**
   * MatTable columns.
   */
  readonly columns = ['checkName', 'checkStatus', 'messages'];

  /**
   * The list of filtered checks for dispay.
   */
  fiteredChecks: HostSettingsCheckResult[];

  /**
   * Gets checks data.
   */
  get checksData(): HostSettingsCheckResult[] {
    return this.dialogData
      ? this.dialogData.componentInputs.checks
      : this.checks;
  }

  /**
   * Creates an instance of source health check matrix component.
   *
   * @param  dialogData  Optional: Used for passing checks dat ain a modal
   *                     context.
   */
  constructor(@Optional() @Inject(MAT_DIALOG_DATA) private dialogData: any) {}

  /**
   * Component init handler
   */
  ngOnInit() {
    this.filterChecks();
  }

  /**
   * Component bindings change handler
   */
  ngOnChanges() {
    this.filterChecks();
  }

  /**
   * Apply filters to the input checks for display. THis means suppressing passed
   * checks unless showAllChecks is set true.
   */
  filterChecks() {
    const checks = this.checksData;

    if (!Array.isArray(checks) || !checks.length) {
      return;
    }

    this.fiteredChecks = this.showAllChecks
      ? checks
      : checks.filter((check: any) => (check.resultType !== 'kPass'));
  }
}
