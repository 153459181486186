<cog-settings-list-item [formGroup]="formGroup"
  [invalid]="sourceSideDedupeFormGroup.invalid"
  [label]="'sourceSideDeduplication' | translate">
  <cog-settings-list-item-value-display>
    {{sourceSideDedupeEnabledControl.value | boolLabel}}
    <span *ngIf="sourceSideDedupeEnabledControl.value && exclusionsControl.value"
      translate="protectionGroups.sourceSideDedupExclusionSummary"
      [translateParams]="{count: exclusionsControl.value.length}">
    </span>
  </cog-settings-list-item-value-display>
  <cog-settings-list-item-value-editor [formGroup]="sourceSideDedupeFormGroup">
    <mat-slide-toggle id="source-side-dedup-toggle"
      formControlName="enabled">
      {{'sourceSideDeduplication' | translate}}
    </mat-slide-toggle>
    <mat-form-field class="margin-top exclusions-form-field">
      <mat-label>{{ 'exclusions' | translate }}</mat-label>
      <mat-select formControlName="exclusions" multiple>
        <mat-option *ngFor="let exclusion of allExclusions" [value]="exclusion.id">
          {{exclusion.name}}
        </mat-option>
      </mat-select>
      <mat-error>{{'errors.required' | translate}}</mat-error>
    </mat-form-field>
  </cog-settings-list-item-value-editor>
</cog-settings-list-item>
