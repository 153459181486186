<h1 mat-dialog-title> {{ 'selectedObjects' | translate }} </h1>
<div mat-dialog-content>
  <div class="detail-container">
    <div *ngFor="let row of selectedObjects" class="detail">
      <div class="icon-wrapper">
        <cog-icon [shape]="'helix:object-vm'"></cog-icon>
      </div>
      <div>
        <span class="object-name user-data-xl"
          cogDataId="object-detail-{{row.name}}"
          [inheritDataId]="true">
          {{row.name}}
        </span>
        <ul class="c-ul-inline no-margin mat-caption">
          <!-- Outlet to include additional info for the item. -->
          <li *ngIf="row.sourceName">
            <span class="item-label">{{'label' | translate: {label: 'source' | translate} }}</span>
            <span class="user-data-lg auto-truncate"
              [matTooltip]="row.sourceName"
              matTooltipPosition="below">
              {{row.sourceName}}
            </span>
          </li>
          <li *ngIf="row.protectionGroupName">
            <span class="item-label">{{'label' | translate: {label: 'protectionGroup' | translate} }}</span>
            <span class="user-data-lg auto-truncate"
              [matTooltip]="row.protectionGroupName"
              matTooltipPosition="below">
              {{row.protectionGroupName}}
            </span>
          </li>
          <li *ngIf="row.lastBackupUsecs">
            <span class="item-label">{{'label' | translate: {label: 'lastBackup' | translate} }}</span>
            <span class="user-data-lg auto-truncate">{{row.lastBackupUsecs | humanizeFromUsecs}}</span>
          </li>
          <li *ngIf="objectSize">
            <span class="item-label">{{'label' | translate: {label: 'size' | translate} }}</span>
            <span class="user-data-lg auto-truncate">{{row.objectSize | byteSize}}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<div mat-dialog-actions align="end">
  <button
    mat-button
    mat-dialog-close
    color="primary"
    cogDataId="close">
    {{ 'continue' | translate }}
  </button>
</div>
