<cog-dashcard
  [title]="'dg.sc.dashboard.topIssues' | translate">
  <ng-container *ngIf="!loading; else spinner">
    <ng-container *ngTemplateOutlet="topIssuesContent"></ng-container>
  </ng-container>
  <cog-dashcard-header-aux>
    <h5 class="no-margin-bottom">
      <a (click)="dashboardService.navigateToUrl.next('/security/posture-advisor/all-issues')">
        {{ 'dg.sc.dashboard.viewAllIssues' | translate }}
      </a>
    </h5>
  </cog-dashcard-header-aux>
</cog-dashcard>

<ng-template #topIssuesContent>
  <mat-card-content *ngIf="(data$ | async)?.issueList?.securityCriteriaResultList.length; else blankCard">
    <div class="issue-container">
      <mat-card class="issue-tile" *ngFor="let issue of issues"
        (click)="dashboardService.navigateToUrl.next('/security/posture-advisor/all-issues')">
        <span>{{issue}}</span>
        <span class="cluster-info">
          <span class="margin-right-xl">{{'dg.sc.dashboard.nOfmClusters' | translate: {
            n: tableData.issueMap[issue],
            m: tableData.totalClusterCount
          } }}</span>
          <cog-icon class="margin-left-xl" shape="chevron_right"></cog-icon>
        </span>
      </mat-card>
    </div>
  </mat-card-content>
</ng-template>

<ng-template #blankCard>
  <cog-blank-card></cog-blank-card>
</ng-template>

<ng-template #spinner>
  <cog-spinner></cog-spinner>
</ng-template>
