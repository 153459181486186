import { InjectionToken } from '@angular/core';
import { Observable, Subject } from 'rxjs';

/**
 * Data structure for passing data between backdrop and parent components.
 */
export interface BackdropConfig {
  // TODO(alex.logashov): Need new prop to set CSS class to parent backdrop component using config.

  /**
   * Optional data object that will be passed to rendered component inside backdrop component.
   */
  data?: any;
}

/**
 * Injection token that can be used to access the data that was passed in to a backdrop component.
 */
export const BACKDROP_CONFIG = new InjectionToken<any>('BackdropConfig');

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export class PageBackdropRef<T, R = any> {

  /**
   * Event emitter when backdrop is closed.
   */
  private _afterClosed = new Subject<R | undefined>();

  /**
   * Returns observable of backdrop closed event.
   */
  afterClosed(): Observable<R | undefined> {
    return this._afterClosed.asObservable();
  }

  /**
   * Closes backdrop component and dispatches closed event.
   *
   * @param   data   Optional data to be passed to closed event.
   */
  close(data: R = null) {
    this._afterClosed.next(data);
    this._afterClosed.complete();
  }
}
