// Module: SQL Dashboard

;(function(angular, undefined) {

  var dashboardInjections = [
    'C.widgets',
    'C.dbDetails',
    'C.statList',
    'C.sqlDashboardService'
  ];

  angular
    .module('C.sqlDashboard', dashboardInjections)
    .config(sqlDashboardConfigFn);

  function sqlDashboardConfigFn($stateProvider) {
    const canAccess = `PROTECTION_VIEW && FEATURE_FLAGS.sqlDashboardEnabled &&
      isClusterUser &&
      !$root.clusterInfo._allClusters &&
      (!$root.isTenantUser() || FEATURE_FLAGS.sqlDashboardForTenants) &&
      (!$root.isRestrictedUser() ||
        FEATURE_FLAGS.sqlDashboardForRestrictedUsers)`;

    $stateProvider
    .state('sql-dashboard', {
      url: '/dashboard/sql',
      title: 'MS SQL Dashboard',
      help: 'dashboard_sql',
      component: 'sqlDashboard',
      canAccess: canAccess,
      redirectTo: 'sql-dashboard.hosts',
    })
    .state('sql-dashboard.hosts', {
      url: '^/dashboard/sql',
      title: 'MS SQL Dashboard',
      help: 'dashboard_sql',
      canAccess: canAccess,
      parentState: 'sql-dashboard',
      component: 'sqlSourceList',
    })
    .state('sql-dashboard.databases', {
      url: '^/dashboard/sql/dbs',
      title: 'MS SQL Dashboard - Databases',
      help: 'dashboard_sql',
      canAccess: canAccess,
      parentState: 'sql-dashboard',
      component: 'sqlDatabaseList',
    });
  }

})(angular);
