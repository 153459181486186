import { Injectable } from '@angular/core';
import { TargetState, Transition } from '@uirouter/core';
import { map } from 'rxjs/operators';
import { ClusterType } from 'src/app/shared';

import { ClusterService, StateManagementService } from '../../services';
import { GuardPriority, GuardResult, StateGuard } from '../state-guard';

@Injectable({
  providedIn: 'root',
})
export class ApplianceManagerClusterCreateGuard implements StateGuard {
  /**
   * This guard priority is set to App, which indicates it should run as part of the main application
   * after all bootstrapping and initializing is completed.
   */
  guardPriority = GuardPriority.App;

  /**
   * Match cluster create states to do transition to right states according to cluster type.
   */
  matchCriteria = {
    to: 'appliance-manager-setup.**',
  };

  /**
   * Multi node cluster create states.
   */
  multiNodeClusterCreateStates = ['appliance-manager-setup'];

  /**
   * Single node cluster create state.
   * TODO -> Update singleNodeClusterCreateState state, once we have route.
   */
  singleNodeClusterCreateState = 'cluster-create.single-node';

  /**
   * Constructor.
   */
  constructor(readonly stateManagementService: StateManagementService, readonly clusterService: ClusterService) {}

  /**
   * Handles the appropriate redirection of cluster create page for a type of cluster.
   * For Physical and Virtual Edition clusters, redirect to appliance-manager-setup.
   * For Vm Robo and Physical Robo, redirect to single node cluster create.
   *
   * @param    transition   The UI Router Transition.
   * @returns  Promise<Transition> Transition target state of the cluster.
   */
  onStart(transition: Transition): GuardResult {
    return this.clusterService
      .getClusterType()
      .pipe(map(clusterType => this.getTargetState(clusterType, transition)))
      .toPromise();
  }

  /**
   * Get target state according to type of cluster.
   *
   * @param   clusterType  Type of cluster.
   * @param   transition   Transition object to set right target state.
   */
  private getTargetState(clusterType: ClusterType, transition: Transition): TargetState | boolean {
    switch (clusterType) {
      case ClusterType.Physical:
      case ClusterType.VirtualEdition:
        // Its a valid transition. Return true.
        if (this.multiNodeClusterCreateStates.includes(transition.to().name)) {
          return true;
        }

        // Go to welcome state.
        return transition.router.stateService.target(this.multiNodeClusterCreateStates[0]);

      case ClusterType.VmRobo:
      case ClusterType.PhysicalRobo:
        // If transition state is already single node cluster create state, return true.
        if (transition.to().name === this.singleNodeClusterCreateState) {
          return true;
        }
        return transition.router.stateService.target(this.singleNodeClusterCreateState);
    }
  }
}
