import { Environment } from 'src/app/shared/constants';

/**
 * A map of protocols and supported values.
 */
export interface FileSystemMap {
  nfs: string[];
  smb: string[];
  http?: string[];
}

/**
 * List of NAS adapters which do not have logical size determined
 */
export const noSizeAdapters = [
  Environment.kIsilon,
  Environment.kGPFS,
  Environment.kGenericNas
];

/**
 * List of NAS adapters that support mixed mode and do not require mixed mode
 * preference setting while backup.
 */
export const mixedModeSupportedAdapters = [
  Environment.kIsilon
];

/**
 * List of all supported NFS protocols
 */
export const supportedNfsProtocols = ['kNfs', 'kNfs3', 'kNfs4_1'];

/**
 * List of all supported SMB protocols
 */
export const supportedSmbProtocols = ['kCifs', 'kCifs1', 'kCifs2', 'kSmb'];

/**
 * NFS/SMB enum value map for each NAS source environment.
 */
export const filesystemEnvMap: {
  [key: string]: FileSystemMap;
} = {
  // enum values are kNfs = 0, kCifs = 1
  // look for DataProtocol in magneto/base/entities/netapp.proto
  kNetapp: {
    nfs: ['kNfs', 'kNfs4_1'],
    smb: ['kCifs'],
  },

  // enum values are kNfs3 = 1, kCifs1 = 2
  // look for NasProtocol in magneto/base/enums.proto
  kGenericNas: {
    nfs: ['kNfs3'],
    smb: ['kCifs1'],
  },

  // enum values used are kNfs = 0, kSmb = 1
  // look for MountPointInfo in magneto/base/entities/isilon.proto
  kIsilon: {
    nfs: ['kNfs'],
    smb: ['kSmb'],
  },

  // enum values used are kNfs = 0, kSmb = 1
  kFlashBlade: {
    nfs: ['kNfs'],
    smb: ['kCifs2'],
    http: ['kHttp'],
  },

  // enum values are kNfs3 = 1, kCifs1 = 2
  // look for NasProtocol in magneto/base/enums.proto
  kGPFS: {
    nfs: ['kNfs3'],
    smb: ['kCifs1'],
  },

  // enum values are kNfs3 = 1, kCifs1 = 2
  // look for NasProtocol in magneto/base/enums.proto
  kElastifile: {
    nfs: ['kNfs3'],
    smb: ['kCifs1'],
  },
};

/**
 * supported NAS filesystem types
 */
export const supportedFilesystemsMap = {
  // look for DataProtocol in magneto/base/entities/netapp.proto
  kNetapp: ['kNfs', 'kNfs3', 'kNfs4_1', 'kCifs', 'kIscsi', 'kFc', 'kFcache', 'kHttp', 'kNdmp', 'kManagement', 'kNvme'],

  // look for NasProtocol in magneto/base/enums.proto
  kGenericNas: ['kNoProtocol', 'kNfs3', 'kNfs4', 'kNfs4_1', 'kCifs1', 'kCifs2', 'kCifs3'],

  // look for MountPointInfo in magneto/base/entities/isilon.proto
  kIsilon: ['kNfs', 'kSmb'],

  // look for FileSystem in magneto/base/entities/flashblade.proto
  kFlashBlade: ['kNfs', 'kCifs2', 'kHttp'],

  // look for NasProtocol in magneto/base/enums.proto
  kGPFS: ['kNoProtocol', 'kNfs3', 'kNfs4', 'kNfs4_1', 'kCifs1', 'kCifs2', 'kCifs3'],

  // look for NasProtocol in magneto/base/enums.proto
  kElastifile: ['kNoProtocol', 'kNfs3', 'kNfs4', 'kNfs4_1', 'kCifs1', 'kCifs2', 'kCifs3'],
};

/**
 * Protocols supported by NAS.
 */
export const nasProtocols = {
  /**
   * NAS in NFS3 mode
   */
  kNfs3: 'kNfs3',

  /**
   * NAS in NFS4.1 mode.
   */
  // eslint-disable-next-line @typescript-eslint/naming-convention
  kNfs4_1: 'kNfs4_1',

  /**
   * NAS in SMB mode.
   */
  kCifs1: 'kCifs1',

  /**
   * NAS in kNfs mode.
   */
  kNfs: 'kNfs'
};

/**
 * Extended style of a netapp volume.
 */
export const netappExtendedStyle = {
  /**
   * flex volume.
   */
  kFlexVol: 'kFlexVol',

  /**
   * flex group.
   */
  kFlexGroup: 'kFlexGroup',
};

/**
 * supported NAS protocols.
 */
export enum NasProtocols {
  kNoProtocol = 'kNoProtocol',
  kNfs3 = 'kNfs3',
  kNfs4_1 = 'kNfs4_1',
  kCifs1 = 'kCifs1',
  kCifs2 = 'kCifs2',
  kCifs3 = 'kCifs3',
}
