import { Component, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';


/**
 * @description
 * Dashcard layout with expand button. Layout can react to layout changes by using "expanded" flag
 * or listening to "expand" event emitter.
 *
 * @example
 *  <cog-dashcard-expand #myExpandableLayout>
 *    <div [ngClass]="{ 'my-expand-selector': myExpandableLayout.expanded }">
 *      I react to parent expand changes.
 *    </div>
 *    <div *ngIf="myExpandableLayout.expanded">
 *      Show content if expanded.
 *    </div>
 *  </cog-dashcard-expand>
 *
 */
@Component({
  selector: 'cog-dashcard-expand',
  templateUrl: './dashcard-expand.component.html',
  encapsulation: ViewEncapsulation.None
})
export class DashcardExpandComponent {
  /**
   * Flag for setting expanded or collapsed layout state.
   */
  @Input() expanded = false;

  /**
   * Emits expand/collapse layout event.
   */
  @Output() expand = new EventEmitter<boolean>(false);

  /**
   * Toggles between expanded and collapsed view.
   */
  expandToggle() {
    this.expanded = !this.expanded;
    this.expand.emit(this.expanded);
  }
}
