import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { NoEmptyStringValidator } from '@cohesity/shared-forms';

/**
 * Params needed for recovery snapshot selector.
 */
export interface RecoverySnapshotSelectorDialogInput {
  showCredentials: boolean;
  object: string;
  jobStartTimeUsecs: string;
  cleanDataPoints: string[];
  headerMsgKey?: string;
  confirmationMsgKey?: string;
}

/**
 * Component to show information before user proceeds with recovery
 * 1. Snapshot time selection
 * 2. Credentials
 */
@Component({
  templateUrl: './recovery-snapshot-selector.component.html',
})
export class RecoverySnapshotSelectorComponent {
  /**
   * The form group for the dialog
   */
  public form: UntypedFormGroup;

  /**
   * Toggle showing the password
   */
  public showCredentials = false;

  /**
   * List of clean data points
   */
  public cleanDataPoints = [];

  showPassword = false;

  /**
   * Dialog header translation key
   */
  headerMsgKey: string;

  /**
   * Acts dialog confirmation message translation key
   */
  confirmationMsgKey: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogParams: RecoverySnapshotSelectorDialogInput,
    fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<RecoverySnapshotSelectorComponent>
  ) {
    this.cleanDataPoints = this.dialogParams.cleanDataPoints;
    this.showCredentials = this.dialogParams.showCredentials;
    this.form = fb.group({
      snapshotTimestampUsec: [this.cleanDataPoints[0], Validators.required],
    });

    if (this.showCredentials) {
      this.form.addControl('username', new UntypedFormControl(null, [Validators.required, NoEmptyStringValidator]));
      this.form.addControl('password', new UntypedFormControl(null, [Validators.required, NoEmptyStringValidator]));
    }

    // Assign header and confirmation message keys
    // Defaults to file recovery
    const { headerMsgKey = 'instantFileRecovery', confirmationMsgKey = 'anomalyFileRecovery.confirmation' } =
      this.dialogParams;
    this.headerMsgKey = headerMsgKey;
    this.confirmationMsgKey = confirmationMsgKey;
  }

  /**
   * On dialog close, return form value
   */
  recoveryDialogClose() {
    this.dialogRef.close(this.form.value);
  }
}
