import { AppModuleConfig, StateAccessContext, StateAccessMap } from 'src/app/app-module.config';

const heliosAuditLogsEnabled = (ctx: StateAccessContext) =>
  ctx.FEATURE_FLAGS.heliosAuditLogs && ctx.AUDIT_LOG_VIEW;
const heliosAuditLogsViewEnabled = (ctx: StateAccessContext) => ctx.AUDIT_LOG_VIEW;
const heliosAuditLogsSettingsEnabled = (ctx: StateAccessContext) => ctx.AUDIT_LOG_VIEW && !ctx.isHeliosTenantUser;
const singleClusterState = 'ng-audit-logs';

export const HeliosAuditLogsConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'helios-audit-logs.**',
      url: '^/monitoring/audit',
      loadChildren: () => import('./helios-audit-logs.module').then(m => m.HeliosAuditLogsModule)
    },
  ],
  allClusterMap: {
    'helios-audit-logs': {
      heliosOnly: true,
      allClustersState: 'helios-audit-logs',
      singleClusterState,
    },
    'helios-audit-logs.list': {
      heliosOnly: true,
      allClustersState: 'helios-audit-logs.list',
      singleClusterState,
    },
    'helios-audit-logs.settings': {
      heliosOnly: true,
      allClustersState: 'helios-audit-logs.settings',
      singleClusterState,
    },
  },
  getStateAccessMap(): StateAccessMap {
    return {
      'helios-audit-logs': heliosAuditLogsEnabled,
      'helios-audit-logs.list': heliosAuditLogsViewEnabled,
      'helios-audit-logs.settings': heliosAuditLogsSettingsEnabled,
    };
  }
};
