import { Injectable } from '@angular/core';
import { flagEnabled, getConfigByKey, IrisContextService } from '@cohesity/iris-core';
import { HookMatchCriteria, StateObject, Transition } from '@uirouter/core';
import { StateManagementService } from 'src/app/core/services/state-management.service';

import { FeatureFlag } from '../../services';
import { GuardPriority, GuardResult, StateGuard } from '../state-guard';

/**
 * Cluster state context which contains feature flag and target state name.
 */
interface ClusterStateContext {
  /**
   * Feature flag pertaining to cluster state.
   */
  featureFlag?: FeatureFlag;

  /**
   * Config override corresponding to cluster state.
   * Used as alternative to feature flag.
   *
   * In some platforms like, OneHelios appliance manager, cluster summary is NG page,
   * and has different look & feel from regular clusters, and needs to be enabled
   * by default on that platform.  So, we enable it by flag in config_kOneHelios.json
   */
  configKey?: string;

  /**
   * Corresponding target state to go to based on feature flag is enabled/disabled.
   */
  targetState: string;
}

@Injectable({
  providedIn: 'root',
})
export class ClusterGuard implements StateGuard {
  /**
   * This guard priority is set to App, which indicates it should run as part of the main application
   * after all bootstrapping and initializing is completed.
   */
  guardPriority = GuardPriority.App;

  /**
   * Specifies mapping from new state to old state object for all cluster page tabs.
   *
   * Exception: cluster summary doesn't have feature flag since there is no NG page for it yet. So, go to current
   * cluster summary page by default.
   */
  private readonly newToOldClusterStatesMap = new Map<string, ClusterStateContext>([
    ['cluster-new.nodes', {featureFlag: 'newNodesPage',  targetState: 'cluster.nodes'}],
    ['cluster-new.am-summary', {configKey: 'cluster.ngSummary',  targetState: 'cluster.summary'}],
    ['cluster-new.kms', {featureFlag: 'ngKMS',  targetState: 'kms'}],
    ['cluster-new.storage-domains-list', {featureFlag: 'ngStorageDomainsModule',  targetState: 'cluster.viewboxes'}],
    ['ng-storage-domains-modify', {featureFlag: 'ngStorageDomainsModify',  targetState: 'viewbox-new'}],
    ['ng-storage-domains-detail', {featureFlag: 'ngStorageDomainsDetail',  targetState: 'viewbox'}],
  ]);

  /**
   * Specifies mapping from old state to new state object for all cluster page tabs.
   */
 private readonly oldToNewClusterStatesMap = new Map<string, ClusterStateContext>([
    ['cluster.nodes', {featureFlag: 'newNodesPage', targetState: 'cluster-new.nodes'}],
    ['cluster.summary', {configKey: 'cluster.ngSummary',  targetState: 'cluster-new.am-summary'}],
    ['cluster.viewboxes', {featureFlag: 'ngStorageDomainsModule',  targetState: 'cluster-new.storage-domains-list'}],
    ['kms', {featureFlag: 'ngKMS',  targetState: 'cluster-new.kms'}],
    ['viewbox-new', {featureFlag: 'ngStorageDomainsModify',  targetState: 'ng-storage-domains-modify'}],
    ['viewbox-edit', {featureFlag: 'ngStorageDomainsModify',  targetState: 'ng-storage-domains-modify'}],
    ['viewbox', {featureFlag: 'ngStorageDomainsDetail',  targetState: 'ng-storage-domains-detail'}],
  ]);

  /**
   * Match criteria for cluster pages. Match old and ng cluster states and handle transition accordingly.
   */
  matchCriteria: HookMatchCriteria = {
    to: (() => {
      const allStates = new Set<string>();
      this.newToOldClusterStatesMap.forEach((oldState, newState) => allStates.add(newState));
      this.oldToNewClusterStatesMap.forEach((newState, oldState) => allStates.add(oldState));

      // Cluster pages matching criterion method.
      return (state: StateObject) => allStates.has(state.name);
    })(),
  };

  constructor(private irisCtx: IrisContextService,
    private stateManagementService: StateManagementService) { }

  /**
   * For transition to ng states, If feature flag is not enabled, go to old states.
   * For transition to old states, If corresponding feature flag is enabled, go to new states.
   *
   * @param   transition  The ui router transition
   * @returns a redirect transition if feature flag for corresponding page is not enabled.
   */
  onStart(transition: Transition): GuardResult {
    const params = transition.params();
    const transitionName = transition.to().name;

    // If transition state matches with new cluster state and
    // if ng feature flag is disabled or feature flag field is undefined, go to old cluster state.
    // If ng page not enabled by config, go to older cluster state
    if (this.newToOldClusterStatesMap.has(transitionName)) {
      const clusterStatesContext = this.newToOldClusterStatesMap.get(transitionName);
      if (
        !flagEnabled(this.irisCtx.irisContext, clusterStatesContext.featureFlag) &&
        !getConfigByKey(this.irisCtx.irisContext, clusterStatesContext.configKey, false) &&
        this.stateManagementService.canUserAccessState(clusterStatesContext.targetState)
      ) {
        return transition.router.stateService.target(clusterStatesContext.targetState, params, transition.options());
      }
    }

    // If transition state matches with old cluster state and if feature flag is enabled, go to ng cluster state.
    // If ng page not is enabled by config, go to ng cluster state
    if (this.oldToNewClusterStatesMap.has(transitionName)) {
      const clusterStatesContext = this.oldToNewClusterStatesMap.get(transitionName);
      if (
        (flagEnabled(this.irisCtx.irisContext, clusterStatesContext.featureFlag) ||
          getConfigByKey(this.irisCtx.irisContext, clusterStatesContext.configKey, false)) &&
        this.stateManagementService.canUserAccessState(clusterStatesContext.targetState)
      ) {
        return transition.router.stateService.target(clusterStatesContext.targetState, params, transition.options());
      }
    }

    // Return true by default.
    return true;
  }
}
