import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IconModule } from '../icon/icon.module';
import { TagComponent } from './tag.component';


@NgModule({
  imports: [
    CommonModule,
    IconModule,
  ],
  declarations: [TagComponent],
  exports: [TagComponent],
})
export class TagModule { }
