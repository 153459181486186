<mat-expansion-panel
  *ngIf="securityCriteriaList?.length"
  (opened)="panelOpenState = true"
  (closed)="panelOpenState = false"
  hideToggle="true"
  expanded="true">
  <mat-expansion-panel-header class="margin-top-lg">
    <div class="header-content">
      <mat-panel-title>
        <span class="title">
          {{ securityCriteriaList[0].category | translate }}
        </span>
        <cog-icon shape="keyboard_arrow_{{ panelOpenState ? 'up' : 'down' }}"></cog-icon>
      </mat-panel-title>
      <span class="mat-caption">
        {{ enabledSecurityCriteriaLength }}/{{ securityCriteriaList.length }} {{ 'items' | translate }}
      </span>
    </div>
  </mat-expansion-panel-header>
  <div *ngFor="let securityCriteria of securityCriteriaList" class="flex-row-align-items-center security-criteria-item">
    <cog-icon
      class="margin-right"
      [shape]="securityCriteria.isEnabled ? 'helix:status-success!success' : 'helix:status-warning!warning'"
      size="md"></cog-icon>
    {{ ('heliosSecurityAdvisor.' + securityCriteria.name) | translate }}
  </div>
</mat-expansion-panel>
