import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { CohesityHelixModule } from '@cohesity/helix';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { SharedModule } from 'src/app/shared';
import { OnlyOwnProvider } from 'src/app/shared/directives';

import { BrowseSearchResultsComponent } from './browse-search-results/browse-search-results.component';
import { FilterIpConfigComponent } from './filter-ip-config/filter-ip-config.component';
import { PolicySelectComponent } from './policy-select/policy-select.component';
import { ProtectionGroupSelectorComponent } from './protection-group-selector/protection-group-selector.component';
import { RecoverSearchFieldComponent } from './recover-search-field';
import {
  RecoverSearchHeaderComponent,
  RecoverSearchPageComponent,
} from './recover-search-page/recover-search-page.component';
import { SearchObjectIconComponent } from './search-object-icon/search-object-icon.component';
import { SnapshotTargetSelectorComponent } from './snapshot-target-selector/snapshot-target-selector.component';
import { SourceThrottlingComponent } from './source-throttling/source-throttling.component';

const components = [
  BrowseSearchResultsComponent,
  FilterIpConfigComponent,
  PolicySelectComponent,
  ProtectionGroupSelectorComponent,
  RecoverSearchFieldComponent,
  RecoverSearchHeaderComponent,
  RecoverSearchPageComponent,
  SearchObjectIconComponent,
  SnapshotTargetSelectorComponent,
  SourceThrottlingComponent,
];
@NgModule({
  declarations: components,
  imports: [
    CohesityHelixModule,
    FormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
    NgxMatSelectSearchModule,
    SharedModule,
  ],
  exports: components,

  // setting ONLY_OWN injectable token to true which is used in case of multitenancy to fetch source objects,
  // storage domains, views, policy etc things belonging to there tenant eg.
  // 1. Service Provider can't protect VMs owned by Tenants.
  // 2. Service Provider can't use storage domain assigned to tenant when tenantViewboxSharingEnabled is false.
  // 3. Service Provider can use storage domain assigned to tenant when tenantViewboxSharingEnabled is true.
  providers: [OnlyOwnProvider],
})
export class ProtectionSharedModule {}
