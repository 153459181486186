/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityV2ApiConfiguration as __Configuration } from '../cohesity-v2api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { IndexingCloudConfig } from '../models/indexing-cloud-config';
@Injectable({
  providedIn: 'root',
})
class IndexingCloudConfigService extends __BaseService {
  static readonly CreateIndexingCloudConfigPath = '/indexing-cloud-config';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Create a new indexing config for a Tenant on Helios.
   * @param params The `IndexingCloudConfigService.CreateIndexingCloudConfigParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateIndexingCloudConfigResponse(params: IndexingCloudConfigService.CreateIndexingCloudConfigParams): __Observable<__StrictHttpResponse<IndexingCloudConfig>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/indexing-cloud-config`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<IndexingCloudConfig>;
      })
    );
  }
  /**
   * Create a new indexing config for a Tenant on Helios.
   * @param params The `IndexingCloudConfigService.CreateIndexingCloudConfigParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateIndexingCloudConfig(params: IndexingCloudConfigService.CreateIndexingCloudConfigParams): __Observable<IndexingCloudConfig> {
    return this.CreateIndexingCloudConfigResponse(params).pipe(
      __map(_r => _r.body as IndexingCloudConfig)
    );
  }
}

module IndexingCloudConfigService {

  /**
   * Parameters for CreateIndexingCloudConfig
   */
  export interface CreateIndexingCloudConfigParams {
    body: IndexingCloudConfig;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { IndexingCloudConfigService }
