import { ValidatorFn, UntypedFormControl } from '@angular/forms';

/**
 * Function to return an error object if a form control's value is only whitespaces.
 *
 * examples: "   " is not a valid string, "test xyz" is valid string, "" is a valid string
 *
 * @param control The form control.
 * @return null if a valid string, otherwise error object.
 */
export const NoEmptySpaceValidator: ValidatorFn = (control: UntypedFormControl) => {
  const isEmptyspace = control.value?.trim().length === 0 && control.value?.length !== 0;
  return isEmptyspace ? { emptyspace: true } : null;
};
