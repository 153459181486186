// Health Service
;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.healthService', [])
    .service('HealthService', healthServiceFn);

  function healthServiceFn($http, API) {

    return {
      getDashboardData: getDashboardData,
    };

    function getDashboardData() {
      return $http({
        method: 'get',
        url: API.private('tricorder/public/diagnostics/summary'),
      }).then(function getData(resp) {
        return resp.data || [];
      });
    }

  }
})(angular);
