import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { PluggableDatabaseInfo } from '@cohesity/api/v2';
import { AutoDestroyable } from '@cohesity/utils';

@Component({
  selector: 'coh-pluggable-db-list',
  styleUrls: ['./pluggable-db-list.component.scss'],
  templateUrl: './pluggable-db-list.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class PluggableDbListComponent extends AutoDestroyable implements OnInit, OnChanges {
  /**
   * Specifies a callback to update the pdb selection list.
   */
  @Output() updatePdbSelection = new EventEmitter<PluggableDatabaseInfo[]>();

  /**
   * Specifies the current container database.
   */
  @Input() containerDatabase: any;

  /**
   * Specifies whether the selection of pluggable db is hidden.
   */
  @Input() isPluggableDbSelectionHidden = false;

  /**
   * Determines whether the PDB level selection is enabled.
   */
  @Input() pdbSelectionDisabled = false;

  /**
   * Specifies the list of Pluggable databases.
   */
  databaseList = new Array<PluggableDatabaseInfo>();

  /**
   * Specifies a form control over the Mat selection list to track change on the same.
   */
  matSelectionList = new UntypedFormControl([]);

  /**
   * Constructor
   */
  constructor() {
    super();
  }

  /**
   * Handles Db selection.
   *
   * @param   node    Specifies the selected PDB.
   * @param   index   Specifies the index within the database List.
   */
  handleDbSelection() {
    // TODO(mythri): Implement this once support PDB level selection.
  }

  /**
   * Lifecycle hook that is called when any data-bound property changes.
   *
   * @param   changes    Specifies the SimpleChange object.
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes?.containerDatabase?.currentValue) {
      this.containerDatabase = changes.containerDatabase.currentValue;
      this.databaseList = this.containerDatabase?.pluggableDatabaseList || [];

      if (!this.isPluggableDbSelectionHidden) {
        this.matSelectionList.setValue(this.databaseList);
      }
    }
  }

  /**
   * Component Initializer.
   */
  ngOnInit() {
    // Select All pdb's by default.
    if (!this.isPluggableDbSelectionHidden) {
      this.matSelectionList.setValue(this.databaseList);
    }
    this.updatePdbSelection.emit(this.databaseList);

    // Emitting changes on updating pdb selection.
    this.matSelectionList.valueChanges.pipe(this.untilDestroy()).subscribe((newValue) => {
      this.updatePdbSelection.emit(newValue);
    });
  }

}
