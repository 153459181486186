import { AppModuleConfig, StateAccessContext, StateAccessMap } from 'src/app/app-module.config';

const configureAccess = (ctx: StateAccessContext) =>
  ctx.FEATURE_FLAGS.servicesLandingPage && ctx.isHelios;

export const ServicesListingConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'servicesListing.**',
      url: '/global/services',
      loadChildren: () => import('./services-listing.module').then(m => m.ServicesListingModule),
    },
  ],
  allClusterMap: {
    'servicesListing': true,
    'servicesListing.list': true,
  },
  getStateAccessMap(): StateAccessMap {
    return {
      'servicesListing': configureAccess,
      'servicesListing.list': configureAccess,
    };
  },
};
