// MODULE:  View Box Create/Edit: Settings
import { KmsModel } from 'src/app/modules/cluster/kms/models/kms.model';

;(function(angular, undefined) {
  'use strict';

  angular.module('C.viewBoxes')
    .controller('viewBoxModifySettingsController',
      viewBoxModifySettingsControllerFn);

  function viewBoxModifySettingsControllerFn($scope, $rootScope, $translate,
    FEATURE_FLAGS, ViewBoxService, evalAJAX, cModal, ClusterService,
    ngDialogService, NgRemoteStorageServiceApi, _) {

    var quotaPolicyDefaults = ViewBoxService.getQuotaPolicyDefaults();
    var numFailureDomains = $scope.clusterInfo._failureDomainCount;

    // Data and Coded stripes in the view box EC config.
    var viewBoxDataStripes = _.get($scope, 'viewBox.storagePolicy.erasureCodingInfo.numDataStripes');
    var viewBoxCodedStripes = _.get($scope, 'viewBox.storagePolicy.erasureCodingInfo.numCodedStripes');

    // Store the ID of the saved External Target, if exists.
    var originalTargetId = $scope.viewBox.storagePolicy.cloudSpillVaultId;
    var originalTarget;

    var customFailureDomain = $scope.clusterInfo.faultToleranceLevel;

    // Remote starage id passed as input param to open create file systems dialog.
    var remoteStorageId;

    /**
     * True if a remote storage is registered.
     */
    $scope.isRemoteStorageRegistered = false;

    /**
     * True if remote storage capacity is added and file systems are created.
     */
    $scope.isRemoteStorageCapacityAdded = false;

    var domainMap = {
      kNode: {
        initial: 'N',
        singularKey: 'node',
        oneKey: 'oneNode',
        multipleKey: 'xNodes',
      },
      kChassis: {
        initial: 'C',
        singularKey: 'chassis',
        oneKey: 'oneChassis',
        multipleKey: 'xChassis',
      },
      kRack: {
        initial: 'R',
        singularKey: 'rack',
        oneKey: 'oneRack',
        multipleKey: 'xRacks',
      },
    };

    // Remote Disks feature is enabled if the feature flag is true OR
    $scope.remoteDisksEnabled = FEATURE_FLAGS.remoteDisksModule ||
      // Cluster uses Disaggregated Storage.
      $scope.clusterInfo._isDisaggregatedStorage;

    // Map of all possible EC options on this cluster.
    $scope.ecOptionsMap = {};

    _.assign($scope, {
      disableKmsSelectorFn: disableKmsSelectorFn,
      getFailuresLabel: getFailuresLabel,
      getNumDomains: getNumDomains,
      getNumDrives: getNumDrives,
      getCustomDomainType: getCustomDomainType,
      getSetErasureCoding: getSetErasureCoding,
      getSetFaultTolerance: getSetFaultTolerance,
      getTranslateValues: getTranslateValues,
      matchFaultTolerance: matchFaultTolerance,
      onChangeExternalTarget: onChangeExternalTarget,
      onToggleAlert: onToggleAlert,
      onToggleQuota: onToggleQuota,
      showEcConversionWarning: showEcConversionWarning,
      showHigherRateEcWarning : showHigherRateEcWarning,
      showEcWarning: showEcWarning,
      submit: submit,
      toggleEc: toggleEc,
      uniqueName: uniqueName,
      openRemoteStorage: openRemoteStorage,
      getRemoteStoragesInfo: getRemoteStoragesInfo,
    });


    /**
     * Activate function.
     *
     * @method      activate
     */
    function _activate() {

      var viewBox = $scope.viewBox;

      // call function to update context sensitive help link/id
      $scope.updateHelpId($scope.$getActiveStep());

      // ensure Quota policies exist before attempting to set toggle
      // booleans based on their sub properties.
      viewBox.physicalQuota = viewBox.physicalQuota || {};
      viewBox.defaultViewQuotaPolicy =
        viewBox.defaultViewQuotaPolicy || {};

      $scope.toggles = {
        vbQuota: !!viewBox.physicalQuota.hardLimitBytes,
        vbAlert: !!viewBox.physicalQuota.alertLimitBytes,
        viewQuota: !!viewBox.defaultViewQuotaPolicy.hardLimitBytes,
        viewAlert: !!viewBox.defaultViewQuotaPolicy.alertLimitBytes,
      };

      // We don't allow modification of non-LRC view box to use LRC encoding after
      // it has been created. Don't show LRC codes if view box was created with
      // non-LRC code.
      if ($scope.flowSettings.inEditMode && (!viewBoxCodedStripes || viewBoxCodedStripes < 4)) {
        $scope.ecOptionsMap = $scope.clusterInfo.supportedConfig._nonLRCEcOptionsMap;
      } else {
        $scope.ecOptionsMap = $scope.clusterInfo.supportedConfig._ecOptionsMap;
      }

      if (!$scope.flowSettings.inEditMode) {
        toggleEc();
      }

      // Set default state of Override Cluster Threshold.
      $scope.flowSettings.overrideClusterThreshold =
        !_.isUndefined(viewBox.cloudDownWaterfallThresholdPct);

      // Indicates whether Cloud Tier has already been set.
      $scope.cloudTierSettingIsEnabled = $scope.flowSettings.inEditMode &&
        viewBox.storagePolicy.cloudSpillVaultId;

      // Post-Processing is required when data stripes > 8 or coded stripes >=
      // 4 (container EC).
      $scope.postProcessingRequired =
        _.get(viewBox.storagePolicy, 'erasureCodingInfo.numDataStripes') > 8 ||
        _.get(viewBox.storagePolicy, 'erasureCodingInfo.numCodedStripes') >= 4;

      $scope.kmsServerId = viewBox.kmsServerId;

      // Get info about remote storages for PXG1 cluster.
      $scope.getRemoteStoragesInfo();

      _setDefaultFailureTolerance();

    }

    /**
     * Get the number of usable disks from cluster info.
     *
     * @method    _getNumDisks
     * @param     {Array}    diskCountByTier  Disk Tiers with their counts.
     * @returns   {Number}   Total number of usable disks in cluster.
     */
    function _getNumDisks(diskCountByTier) {
      var diskTierMap = diskCountByTier.reduce((map, obj) => {
        map[obj.storageTier] = obj.diskCount;
        return map;
      }, {});
      var hddCount = diskTierMap['SATA-HDD'] || 0;
      var pcieSsdCount = diskTierMap['PCIeSSD'] || 0;
      var sataSsdCount = diskTierMap['SATA-SSD'] || 0;

      if (hddCount > 0) {
        // If there is at least one SATA-HDD then that is the primary storage
        // disk tier.
        return hddCount;
      }

      if (sataSsdCount > 0) {
        // Otherwise, if there is at least one SATA-SSD then that is the primary
        // storage disk tier.
        return sataSsdCount;
      }

      // Otherwise, PCIeSSD is the primary storage disk tier.
      return pcieSsdCount;
    }

    /**
     * Sets the default Fault Tolerance value.
     *
     * @method    _setDefaultFailureTolerance
     * @returns   {String}    String value representing failure tolerance.
     *                        For example: '1:1'
     */
    function _setDefaultFailureTolerance() {
      var clusterInfo = $rootScope.clusterInfo;
      var failureDomainCount = clusterInfo._failureDomainCount;
      var drivesCount = _getNumDisks(clusterInfo.diskCountByTier || []);

      if ($scope.flowSettings.inEditMode) {
        return;
      }

      switch (true) {
        // If num nodes >= 16 recommend 2d:2n.
        case failureDomainCount >= 16:
          return getSetFaultTolerance('2:2');

        // If num nodes < 16 && num drives >= 48 recommend 2d:1n.
        case failureDomainCount < 16 && drivesCount >= 48:
          return getSetFaultTolerance('2:1');

        // Otherwise recommend 1d:1n.
        default:
          return getSetFaultTolerance('1:1');
      }
    }

    /**
     * checks if the user provided name is a unique view box name.
     *
     * @method    uniqueName
     * @return    {Boolean} indicates if View Box name is unique
     */
    function uniqueName(viewBoxName) {
      var vbNameLower;

      if (!viewBoxName) {
        // if user hasn't provided a View Box name,
        // let the required validation handle this
        return true;
      }

      vbNameLower = viewBoxName.toLowerCase();

      if ($scope.viewBoxMapping.hasOwnProperty(vbNameLower)) {
        if ($scope.newViewBox) {
          return false;
        } else if ($scope.viewBox.id === $scope.viewBoxMapping[vbNameLower]) {
          return true;
        } else {
          return false;
        }
      }
      return true;
    }

    /**
     * sends user to next step if form validates
     *
     * @method submit
     */
    function submit() {
      var apiCall;
      var viewBox = $scope.viewBox;
      var toggles = $scope.toggles;

      // Determine if Cloud Tiering is enabled and the target is unspecified.
      // Then set the form validity accordingly because the model is a derived
      // value, and even when undefined it's not `undefined`.
      var isMissingTieringTarget = !$scope.shared.showExternalTargets ? false :
        !$scope.shared.selectedTarget.id;
      if (isMissingTieringTarget) {
        $scope.frmViewBox.externalTarget.$setValidity("required", false);
      }

      if ($scope.frmViewBox.$invalid || !uniqueName()) {
        return;
      }

      $scope.submitting = true;

      // Clean the request object
      viewBox.adDomainName = viewBox.adDomainName === 'nonePublicAccess' ?
        undefined : viewBox.adDomainName;
      viewBox.physicalQuota.hardLimitBytes = toggles.vbQuota ?
        viewBox.physicalQuota.hardLimitBytes : undefined;
      viewBox.physicalQuota.alertLimitBytes = toggles.vbAlert ?
        viewBox.physicalQuota.alertLimitBytes : undefined;
      viewBox.defaultViewQuotaPolicy.hardLimitBytes = toggles.viewQuota ?
        viewBox.defaultViewQuotaPolicy.hardLimitBytes : undefined;
      viewBox.defaultViewQuotaPolicy.alertLimitBytes = toggles.viewAlert ?
        viewBox.defaultViewQuotaPolicy.alertLimitBytes : undefined;
      viewBox.storagePolicy.cloudSpillVaultId =
        $scope.shared.showExternalTargets ?
        $scope.shared.selectedTarget.id : undefined;

      // If Cluster does not support EC, then remove any config.
      if (!$scope.clusterInfo.supportedConfig.supportedErasureCoding.length) {
        viewBox.storagePolicy.erasureCodingInfo = undefined;
      }

      // If Disaggregated Storage, then force RF1 when creating Storage Domain.
      if ($scope.remoteDisksEnabled) {
        viewBox.storagePolicy.erasureCodingInfo = undefined;
        viewBox.storagePolicy.numFailuresTolerated =
          viewBox.storagePolicy.numNodeFailuresTolerated = 0;
      }

      // These two properties are not user editable and should not be included
      // in payload.
      viewBox.physicalQuota.alertThresholdPercentage = undefined;
      viewBox.defaultViewQuotaPolicy.alertThresholdPercentage = undefined;

      // If user disables override, then clear the dependent value.
      if (!$scope.flowSettings.overrideClusterThreshold) {
        viewBox.cloudDownWaterfallThresholdPct = undefined;
      }

      if ($scope.flowSettings.inEditMode) {
        apiCall = ViewBoxService.updateViewBox(viewBox);
      } else {
        apiCall = ViewBoxService.createViewBox(viewBox);
      }

      apiCall.then(
        function apiCallSuccess(respViewBox) {
          $scope.updateViewBox(respViewBox);
          $scope.endFlow('complete');
        },
        function apiCallFail(resp) {
          evalAJAX.errorMessage(resp);

          // re-init the controller, so quota values and toggle are
          // properly reset for any further user interaction
          _activate();
        }
      ).finally(
        function apiCallFinally() {
          $scope.submitting = false;
        }
      );
    }

    /**
     * GetterSetter for Fault Tolerance radio buttons which are derived from two
     * model properties.
     *
     * @method    getSetFaultTolerance
     * @param     {String}   newValue  faultTolerance: '2:2'
     * @return    {String}   model value for the set of radio buttons
     */
    function getSetFaultTolerance(newValue) {
      var storagePolicy = $scope.viewBox.storagePolicy;
      var dataStripes;

      storagePolicy.erasureCodingInfo = storagePolicy.erasureCodingInfo || {};

      if (!_.isUndefined(newValue) && (newValue !== '0:0' ||
        $rootScope.clusterInfo._isVirtualEditionCluster)) {
        storagePolicy.numFailuresTolerated = +newValue.slice(0,1);
        storagePolicy.numNodeFailuresTolerated = +newValue.slice(2,3);

        // Enable EC by default unless:
        // fault tolerance is '0D:0x'
        //   OR
        // fault tolerance is '1D:1x' and Cluster has fewer than 4 failure domains
        //   OR
        // fault tolerance is '2D:2x' and Cluster has fewer than 5 failure domains
        //   OR
        // fault tolerance is '3D:1x' and Cluster has fewer than 4 failure domains
        //   OR
        // fault tolerance is '3D:2x' and Cluster has fewer than 6 failure domains
        //   OR
        // fault tolerance is '3D:3x' and Cluster has fewer than 7 failure domains
        storagePolicy.erasureCodingInfo.erasureCodingEnabled = true;

        if (newValue === '0:0' ||
          (newValue === '1:1' && numFailureDomains < 4) ||
          (newValue === '2:2' && numFailureDomains < 5) ||
          (newValue === '3:1' && numFailureDomains < 4) ||
          (newValue === '3:2' && numFailureDomains < 6) ||
          (newValue === '3:3' && numFailureDomains < 7)) {
          storagePolicy.erasureCodingInfo.erasureCodingEnabled = false;
        } else {
          dataStripes = (newValue === '1:1' || numFailureDomains < 7) ? 2 : 4;

          // Default to 4 data stripes when 3 disk failures are tolerated.
          if (storagePolicy.numFailuresTolerated === 3) {
            dataStripes = 4;
          }

          getSetErasureCoding({
            dataStripes: dataStripes,
            codedStripes: storagePolicy.numFailuresTolerated,
          });
        }
      }

      return _getToleranceValue(storagePolicy);
    }

    /**
     * GetterSetter for Erasure Coding settings which are derived from two model
     * properties.
     *
     * @method    getSetErasureCoding
     * @param     {Object}    newValue    EC option from ui-select
     * @return    {String}    model value for the ui-select
     */
    function getSetErasureCoding(newValue) {
      var storagePolicy = $scope.viewBox.storagePolicy;
      var erasureCodingInfo = storagePolicy.erasureCodingInfo;

      if (!_.isUndefined(newValue)) {
        erasureCodingInfo.numCodedStripes = newValue.codedStripes;
        erasureCodingInfo.numDataStripes = newValue.dataStripes;

        // We default to inline processing when data stripes <= 8 and we are not
        // not using LRC encoding. Otherwise we default to post processing.
        erasureCodingInfo.inlineErasureCoding = newValue.dataStripes <= 8 &&
          newValue.codedStripes < 4;

        // Post-Processing is required when data stripes > 8 or coded stripes
        // >= 4 (container EC).
        $scope.postProcessingRequired = newValue.dataStripes > 8 ||
          newValue.codedStripes >= 4;
      }

      // Return the matching option from the list of options.
      return ($scope.ecOptionsMap[_getToleranceValue(storagePolicy)] || [])
        .find(function matchEcOption(option) {
          return option.codedStripes === erasureCodingInfo.numCodedStripes &&
            option.dataStripes === erasureCodingInfo.numDataStripes;
        });
    }

    /**
     * On toggle Erasure Coding setting, set default dependent values.
     *
     * @method      toggleEc
     */
    function toggleEc() {
      var storagePolicy = $scope.viewBox.storagePolicy;
      var tolerance;

      if (_.get(storagePolicy, 'erasureCodingInfo.erasureCodingEnabled')) {
        tolerance = _getToleranceValue(storagePolicy);

        if (tolerance === '1:1') {
          // If 1D:1N then always default to 2:1.
          getSetErasureCoding({
            dataStripes: 2,
            codedStripes: 1,
          });
        } else {
          // Default to 2:2 unless 7+ failure domains.
          getSetErasureCoding({
            dataStripes: numFailureDomains > 6 ? 4 : 2,
            codedStripes: 2,
          });
        }
      }
    }

    /**
     * Returns the Failure Tolerance string value derived from number of allowed
     * drive failures and failure domain failures.
     *
     * @method      _getToleranceValue
     * @param       {Object}    storagePolicy    View Box storage policy object
     * @returns     {String}    string value representing failure tolerance. For
     *                          example: '1:1'
     */
    function _getToleranceValue(storagePolicy) {
      return storagePolicy.numFailuresTolerated + ':' +
        storagePolicy.numNodeFailuresTolerated;
    }

    // if the flow is ready we can init,
    // otherwise we need to listen for the flowReady broadcast from
    // modify.js so we know all API calls have completed and things
    // are ready to go
    if ($scope.flowReady) {
      _activate();
    } else {
      $scope.$on('flowReady', activate);
    }

    /**
     * On changes to the quota alert toggles, either clears the alert values or
     * pops in default values.
     *
     * Resets the default values if they exceed their respective hard limits.
     *
     * @method     onToggleAlert
     * @param      {String}     quotaType       type of quota
     * @param      {boolean}    toggleState     state of toggle
     */
    function onToggleAlert(quotaType, toggleState) {
      var quotaPolicy = $scope.viewBox[quotaType];

      if (!toggleState) {
        // User disabled Alert, so set `undefined`.
        quotaPolicy.alertLimitBytes = undefined;
      } else if (!quotaPolicy.hardLimitBytes) {
        // There is no Quota, so set default alert value.
        quotaPolicy.alertLimitBytes = quotaPolicyDefaults.alertLimitBytes;
      } else {
        // Adjust to 90% of Quota value.
        quotaPolicy.alertLimitBytes =
          Math.floor(quotaPolicy.hardLimitBytes * 0.90);
      }
    }

    /**
     * On changes to the quota toggles, either clears the quota values or pops
     * in default values.
     *
     * @method    onToggleQuota
     * @param     {string}     quotaType       type of quota
     * @param     {boolean}    toggleState     state of toggle
     */
    function onToggleQuota(quotaType, toggleState) {
      $scope.viewBox[quotaType].hardLimitBytes =
        toggleState ? quotaPolicyDefaults.hardLimitBytes : undefined;

        // Disable CloudTier override if no Physical Quota is set.
        if (!toggleState && quotaType === 'physicalQuota') {
          $scope.flowSettings.overrideClusterThreshold = false;
        }
    }

    /**
     * Determines whether to show a warning about data not being re-encoded
     * after changing one LRC config to other.
     *
     * @method      showEcConversionWarning
     */
    function showEcConversionWarning() {
      var ecSetting = getSetErasureCoding();

      if (!$scope.flowSettings.inEditMode || !ecSetting) {
        return false;
      }

      if (viewBoxCodedStripes >= 4 && ecSetting.codedStripes >= 4) {
        return viewBoxDataStripes != ecSetting.dataStripes;
      }

      return false;
    }

    /**
     * Determines whether to show a warning about using higher rate erasure coding.
     *
     * @method    showEcHigherRateNotSupported
     * @return    boolean    Indicating whether we need to display warning for higher rate erasure coding.
     */
    function showHigherRateEcWarning() {
      var ecSetting = getSetErasureCoding();

      // EC 12:4 and 16:4 are tech preview only, so we need to ensure user get warned before selecting these config.
      if (ecSetting && ecSetting.codedStripes == 4 && (ecSetting.dataStripes == 12 || ecSetting.dataStripes == 16)) {
        return true;
      }

      return false;
    }

    /**
     * Determines whether to show a warning about the combination of indicated
     * fault tolerance and EC setting.
     *
     * @method      showEcWarning
     */
    function showEcWarning() {
      var ecSetting = getSetErasureCoding();
      var numDomainFailures;
      var codedPerFailureDomain;
      var minNumFailureDomains;

      if (!ecSetting) {
        return;
      }

      // Isolate the number of allowed domain failures from "2d:2x" which is the
      // number before the "x".
      numDomainFailures = +getSetFaultTolerance().slice(2, 3);
      // LRC Algo(used for EC X:4) can only tolerate X-1 domain failure.
      if (ecSetting.codedStripes >= 4) {
        codedPerFailureDomain =
          Math.floor((ecSetting.codedStripes - 1) / numDomainFailures);
        minNumFailureDomains =
          Math.ceil((ecSetting.dataStripes + 1) / codedPerFailureDomain)
          + numDomainFailures;
      } else {
        codedPerFailureDomain =
          Math.floor(ecSetting.codedStripes / numDomainFailures);
        minNumFailureDomains =
          Math.ceil(ecSetting.dataStripes / codedPerFailureDomain)
          + numDomainFailures;
      }
      return numFailureDomains <= minNumFailureDomains;
    }

    /**
     * Updates the selected Cloud Tier External Target.
     *
     * @method   onChangeExternalTarget
     * @param    {Object}   newTarget   Newly selected target.
     */
    function onChangeExternalTarget(newTarget) {
      if (!$scope.flowSettings.inEditMode) {
        return;
      }

      // On page load, we only know the id of the saved target, but we want to
      // store the whole target object so we can revert changes. So we save the
      // selected target the first time the select-external-target component
      // sets the full target object.
      if (originalTargetId && !$scope.storedTarget) {
        originalTarget = newTarget;
        $scope.storedTarget = true;
      }

      // Throw challenge modal if target has been changed from what was
      // previously saved.
      if (originalTarget && originalTarget.id !== newTarget.id) {
        return _showExternalTargetWarning(newTarget, originalTarget);
      } else {
        $scope.viewBox.storagePolicy.cloudSpillVaultId = newTarget.id;
      }
    }

    /**
     * Displays warning when user attempts to change the selected Cloud-Tiering
     * External Target.
     *
     * @method   _showExternalTargetWarning
     * @param    {Object}   newTarget   Newly selected target.
     * @param    {Object}   oldTarget   Previously saved target.
     * @return   {Object}   standard modal instance
     */
    function _showExternalTargetWarning(newTarget, oldTarget) {
      var storagePolicy = $scope.viewBox.storagePolicy;
      var options = {
        contentKey: 'viewboxModify.warning.changeExternalTarget',
        contentKeyContext: {
          newTarget: newTarget.name,
          oldTarget: oldTarget.name,
        },
        actionButtonKey: 'continue',
        closeButtonKey: 'cancel',
      };

      return cModal.standardModal({}, options).then(
        function confirmed() {
          // Update the model value.
          storagePolicy.cloudSpillVaultId = newTarget.id;
        },
        function canceled() {
          // Revert the model value.
          storagePolicy.cloudSpillVaultId = oldTarget.id;

          // Revert the select-external-target component model.
          $scope.shared.selectedTarget = oldTarget;
        },
      );
    }

    /**
     * Returns a string representation of allowable failures.
     * For example, '2D:1N'
     *
     * @method  getFailuresLabel
     * @param   {Number}  drives   Number of allowed drive failures.
     * @param   {Number}  domains  Number of allowed node|chassis|rack failures.
     * @return  {String}  Label indicating allowed failures. e.g. '2D:1N'.
     */
    function getFailuresLabel(drives, domains) {
      var customDomainType = domainMap[customFailureDomain];
      return drives + 'D:' + domains + customDomainType.initial;
    }

    /**
     * Returns an object with translation-values for a translation string.
     *
     * @method  getTranslateValues
     * @param   {Number}  numDomains   Number of allowed node|chassis|rack
     *                                 failures.
     * @return  {Object}  Object with translation-values.
     */
    function getTranslateValues(numDomains) {
      return {
        numDomains: getNumDomains(numDomains),
        oneDomain: getNumDomains(1),
        domain: $translate.instant(domainMap[customFailureDomain].singularKey)
      };
    }

    /**
     * Returns a translation string representing the number of allowed
     * node|chassis|rack failures.
     * For example, '2 Racks'.
     *
     * @method  getNumDomains
     * @param   {Number}  numDomains   Number of allowed node|chassis|rack
     *                                 failures.
     * @return  {String}  Object with translation-values.
     */
    function getNumDomains(numDomains) {
      var customDomainType = domainMap[customFailureDomain];

      return numDomains === 1 ?
        $translate.instant(customDomainType.oneKey) :
        $translate.instant(customDomainType.multipleKey, { num: numDomains });
    }

    /**
     * Returns a translation string representing the number of allowed drive
     * failures. For example, '2 Drives'.
     *
     * @method  getNumDrives
     * @param   {Number}  numDrives   Number of allowed drive failures.
     * @return  {String}  Object with translation-values.
     */
    function getNumDrives(numDrives) {
      return numDrives === 1 ?
        $translate.instant('one1Drive') :
        $translate.instant('xDrives', { num: numDrives });
    }

    /**
     * Determines whether provided failure string matches the policy.
     *
     * @method  matchFaultTolerance
     * @param   {Number}  drives   Number of allowed drive failures.
     * @param   {Number}  domains  Number of allowed node|chassis|rack failures.
     * @return  {Boolean}  True if both param values match the storage policy.
     */
    function matchFaultTolerance(drives, domains) {
      var storagePolicy = $scope.viewBox.storagePolicy;
      return drives === storagePolicy.numFailuresTolerated &&
        domains === storagePolicy.numNodeFailuresTolerated;
    }

    /**
     * Returns a translated string for the Custom Failure Domain.
     * For example, 'Rack'.
     *
     * @method  getCustomDomainType
     * @param   {String}  key   Key for domainMap hash.
     *                          For example 'singularKey'.
     * @return  {String}  Translated string for Custom Failure Domain.
     */
    function getCustomDomainType(key) {
      return $translate.instant(domainMap[customFailureDomain][key]);
    }

    /**
     * Rules to determine whether the kms key selector should
     * be disabled or not
     *
     * @param   {string}  keyType             selected KMS key type
     * @param   {number}  key                 selected KMS Key
     *
     * @returns {boolean} True if KMS selection is to be disabled.
     */
    function disableKmsSelectorFn(keyType, key) {
      return $scope.flowSettings.inEditMode &&
        keyType !== KmsModel.KeyTypes.kInternalKMS &&
          key && key === $scope.kmsServerId;
    }

    /**
     * Open dialog to add remote storage. It opens register remote storage
     * dialog if no remote storage registered, then opens dialog to create
     * filesystems.
     */
    function openRemoteStorage() {
      if (!$scope.isRemoteStorageRegistered) {
        ngDialogService.showRegisterRemoteStorageDialog().toPromise()
          .then(
            function registerRemoteStorageSuccess(response) {
              if (response && response.id !== 0) {
                $scope.isRemoteStorageRegistered = true;
                openCreateFileSystemsDialog({remoteStorageId: response.id});
              }
          });
      } else {
        openCreateFileSystemsDialog({remoteStorageId: remoteStorageId});
      }
    }

    /**
     * Open dialog to create file systems on remote storage.
     */
    function openCreateFileSystemsDialog(dialogParam) {
      if (!$scope.isRemoteStorageCapacityAdded) {
        ngDialogService.showCreateFileSystemsDialog(dialogParam).toPromise()
          .then(function createFileSystemSuccess(response) {
            if (response && !!response.id) {
              $scope.isRemoteStorageCapacityAdded = true;
            }
          });
      }
    }

    /**
     * Get registered remote storages info for disaggregated storage platform
     * to determine whether user has to register remote storage before adding
     * any storage domain.
     *
     * @method   getRemoteStoragesInfo
     */
    function getRemoteStoragesInfo() {
      if (!$scope.remoteDisksEnabled) {
        return;
      }
      NgRemoteStorageServiceApi.GetRegisteredRemoteStorageList().toPromise()
        .then(
          function getRemoteStoragesSuccess(response) {
            var remoteStorage;
            if (response.remoteStorages && !!response.remoteStorages.length) {
              remoteStorage = response.remoteStorages[0];
              // If id is non-zero, remote storage is registered.
              if (!!remoteStorage.id) {
                remoteStorageId = remoteStorage.id;
                $scope.isRemoteStorageRegistered = true;
              }
              // Set to true if assigned capacity bytes is non-zero.
              if (!!remoteStorage.flashbladeParams.assignedCapacityBytes) {
                $scope.isRemoteStorageCapacityAdded = true;
              }
            }
          },
          // Enable storage domain add by default in cases of api errors.
          function getRemoteStoragesFail() {
            $scope.isRemoteStorageRegistered = true;
            $scope.isRemoteStorageCapacityAdded = true;
        });
    }
  }

}(angular));
