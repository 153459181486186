/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityApiConfiguration as __Configuration } from '../cohesity-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Notifications } from '../models/notifications';
import { UpdateNotifications } from '../models/update-notifications';
@Injectable({
  providedIn: 'root',
})
class NotificationsService extends __BaseService {
  static readonly GetNotificationsPath = '/public/sessionUser/notifications';
  static readonly UpdateNotificationsPath = '/public/sessionUser/notifications';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * List the notification of the session user.
   * @param params The `NotificationsService.GetNotificationsParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `count`:
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetNotificationsResponse(params: NotificationsService.GetNotificationsParams): __Observable<__StrictHttpResponse<Notifications>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    __body = params.count;
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/sessionUser/notifications`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Notifications>;
      })
    );
  }
  /**
   * List the notification of the session user.
   * @param params The `NotificationsService.GetNotificationsParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `count`:
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetNotifications(params: NotificationsService.GetNotificationsParams): __Observable<Notifications> {
    return this.GetNotificationsResponse(params).pipe(
      __map(_r => _r.body as Notifications)
    );
  }

  /**
   * Perform operations on the notification of the session user.
   *
   * Returns success or failure.
   * @param params The `NotificationsService.UpdateNotificationsParams` containing the following parameters:
   *
   * - `body`: Specifies the list of notificationIds and the operation to be performed.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   */
  UpdateNotificationsResponse(params: NotificationsService.UpdateNotificationsParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/public/sessionUser/notifications`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Perform operations on the notification of the session user.
   *
   * Returns success or failure.
   * @param params The `NotificationsService.UpdateNotificationsParams` containing the following parameters:
   *
   * - `body`: Specifies the list of notificationIds and the operation to be performed.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   */
  UpdateNotifications(params: NotificationsService.UpdateNotificationsParams): __Observable<null> {
    return this.UpdateNotificationsResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module NotificationsService {

  /**
   * Parameters for GetNotifications
   */
  export interface GetNotificationsParams {

    /**
     * This field represents the region id.
     */
    regionId?: string;
    count?: number;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateNotifications
   */
  export interface UpdateNotificationsParams {

    /**
     * Specifies the list of notificationIds and the operation to be performed.
     */
    body: UpdateNotifications;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { NotificationsService }
