import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { CohesityHelixModule } from '@cohesity/helix';
import { SharedListModule } from '@cohesity/shared/list';
import { TranslateModule } from '@ngx-translate/core';

import { SelectTagControlComponent, SourceTreeControlsComponent } from './shared';
import {
  IncludeProtectedObjectsDialogComponent,
} from './shared/include-protected-objects-dialog/include-protected-objects-dialog.component';
import {
  SourceTreeControlExcludeOutletComponent,
} from './shared/source-tree-controls/source-tree-exclude-control-outlet/source-tree-exclude-control-outlet';
import { SourceTreeFilterComponent } from './shared/source-tree-filter/source-tree-filter.component';
import { SourceTreeSelectDirective } from './source-tree-select/source-tree-select.directive';
import { SourceTreeSummaryComponent } from './source-tree-summary/source-tree-summary.component';
import { SourceTreeViewComponent } from './source-tree-view/source-tree-view.component';

const components = [
  IncludeProtectedObjectsDialogComponent,
  SelectTagControlComponent,
  SourceTreeControlsComponent,
  SourceTreeControlExcludeOutletComponent,
  SourceTreeFilterComponent,
  SourceTreeSelectDirective,
  SourceTreeSummaryComponent,
  SourceTreeViewComponent,
];

/**
 * Common functionalities of a source tree like filters, details, actions etc
 */
@NgModule({
  declarations: components,
  imports: [
    CohesityHelixModule,
    CommonModule,
    FormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
    MatAutocompleteModule,
    MatDialogModule,
    ReactiveFormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
    SharedListModule,
    TranslateModule,
  ],
  exports: components,
})
export class SourceTreeModule {}
