import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IrisContextService } from '@cohesity/iris-core';
import { Environment } from 'src/app/shared/constants';

import { NasSourceTreeService } from './nas-source-tree.service';
import { NasViewFilters } from './nas-view-filters';

/**
 * Tree service for Netapp.
 */
@Injectable({
  providedIn: 'root',
})
export class NetappSourceTreeService extends NasSourceTreeService {

  /**
   * Netapp sources do use auto protection.
   */
  get usesAutoProtection(): boolean {
    return true;
  }

  constructor(translate: TranslateService, irisCtx: IrisContextService) {
    super(translate, irisCtx);

    // Netapp also uses custom view filters.
    this.nasViewFilters = new NasViewFilters(this.treeControl, this.filters, irisCtx, Environment.kNetapp);
  }

}
