<cog-spinner *ngIf="isLoading"></cog-spinner>

<ng-container *ngIf="!isLoading">
  <cog-blank-card *ngIf="!hasData"></cog-blank-card>
  <div class="flex-column-md" *ngIf="hasData">
    <mat-card-title>{{ 'anomalyDetails.fileAnalysis.mainLabel' | translate }}</mat-card-title>
    <cog-bar-chart
      [seriesData]="stats.seriesData"
      [categories]="stats.categories"
      [customChartOptions]="stats.customOptions"
      colorSetClass="file-analysis-theme">
    </cog-bar-chart>

    <h3>{{ 'anomalyDetails.fileAnalysis.distributionLabel' | translate }}</h3>
    <cog-bar-chart
      [seriesData]="distribution.seriesData"
      [categories]="distribution.categories"
      [customChartOptions]="distribution.customOptions"
      colorSetClass="file-analysis-theme">
    </cog-bar-chart>
  </div>
</ng-container>
