<mat-radio-group *ngIf="!readOnly"
  [class.no-margin-bottom]="control.value === recoverToOptions.originalLocation"
  [formControl]="control">
  <mat-radio-button
    *ngIf="showOriginalLocation"
    cogDataId="recover-to-original-location-option"
    [value]="recoverToOptions.originalLocation">
    {{labels.original | translate}}
  </mat-radio-button>
  <mat-radio-button
    *ngIf="showNewLocation"
    cogDataId="recover-to-new-location-option"
    [class.margin-left-lg]="showOriginalLocation"
    [value]="recoverToOptions.newLocation"
    [disabled]="disableNewLocation">
    {{labels.new | translate}}
  </mat-radio-button>
  <mat-radio-button
    *ngIf="showOriginalView"
    cogDataId="recover-to-original-view-option"
    [value]="recoverToOptions.originalView">
    {{labels.original | translate}}
  </mat-radio-button>
  <mat-radio-button
    *ngIf="showNewView"
    [value]="recoverToOptions.newView"
    class="margin-left-lg"
    cogDataId="recover-to-new-cohesity-view"
    cohIrisHide="isDmsScope">
    {{ (showViewLabels ? 'chooseAlternateView' : 'newCohesityView') | translate}}
  </mat-radio-button>

  <!-- Renders the custom options for recovery -->
  <ng-container *ngIf="customRadioItemList.length">
    <mat-radio-button *ngFor="let item of customRadioItemList"
      [value]="item.value"
      class="margin-left-lg"
      cogDataId="recover-to-{{item.value}}">
      {{item.label | translate}}
    </mat-radio-button>
  </ng-container>
</mat-radio-group>
<cog-settings-list *ngIf="readOnly"
  class="recover-to-settings-list"
  [alwaysOpen]="true"
  [readOnly]="true"
  [formGroup]="formGroup">
  <cog-settings-list-item
    [label]="'recoverTo' | translate"
    cogFormGroup>
    <cog-settings-list-item-value-display [ngSwitch]="control.value">
      <ng-container *ngSwitchCase="recoverToOptions.newLocation">
        {{labels.new | translate}}
      </ng-container>
      <ng-container *ngSwitchCase="recoverToOptions.originalLocation">
        {{labels.original | translate}}
      </ng-container>
      <ng-container *ngSwitchCase="recoverToOptions.newView">
        {{ newView ? newView : 'newCohesityView' | translate }}
      </ng-container>
      <ng-container *ngSwitchCase="recoverToOptions.originalView">
        {{ labels.original | translate}}
      </ng-container>
      <ng-container *ngSwitchCase="recoverToOptions.convertToPst">
        {{'office365Restore.exportAsPST' | translate}}
      </ng-container>
    </cog-settings-list-item-value-display>
  </cog-settings-list-item>
</cog-settings-list>
