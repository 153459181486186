<div class="flex-column-xs margin-top-lg margin-bottom-lg">
  <mat-slide-toggle
    [formControl]="form.formGroup.controls.mostSecureSettings"
    cogDataId="use-most-secure-settings-toggle">
    {{'viewModify.secureView' | translate}}
    ({{'viewModify.secureView.description' | translate}})
  </mat-slide-toggle>

  <cog-banner type="info" class="margin-left-xl" *ngIf="form.formGroup.value.mostSecureSettings">
    <div *ngIf="hasSmbProtocol">
      {{'viewModify.secureView.smbNote' | translate}}
    </div>
    <div [ngClass]="{'margin-top-lg': hasSmbProtocol}" *ngIf="hasNfsProtocol">
      {{'viewModify.secureView.nfsNote' | translate}}
    </div>
  </cog-banner>
</div>
