import { ChangeDetectionStrategy, Component, Inject, Input, OnChanges, Optional, SimpleChanges } from '@angular/core';
import { DataTreeNodeContext, NavItem } from '@cohesity/helix';
import { SOURCE_TREE_CONTEXT } from '@cohesity/iris-source-tree';
import { Observable, of } from 'rxjs';
import { distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';
import { PassthroughOptionsService } from 'src/app/core/services';
import { ObjectMenuProvider, SimpleObjectInfo } from 'src/app/modules/object-details-shared';

import { SourceTreeContext } from '../../../source-tree-context';
import { ProtectionSourceDataNode } from '../protection-source-data-node';

@Component({
  selector: 'coh-protection-source-node-actions',
  templateUrl: './protection-source-node-actions.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProtectionSourceNodeActionsComponent implements OnChanges {
  /**
   * An observable of actions that should be displayed for the selection.
   */
  actions$: Observable<NavItem[]> = of([]);

  /**
   * The rendered node context.
   */
  @Input() nodeContext: DataTreeNodeContext<ProtectionSourceDataNode>;

  /**
   * The threshold after which the items are wrapped inside a context menu.
   */
  @Input() wrapAfter = 2;

  /**
   * Set when we have any workload type defined.
   */
  @Input() workloadType: string = undefined;

  /**
   * Gets the object menu provider, if any.
   */
  get actionProvider(): ObjectMenuProvider {
    return this.sourceTreeContext && this.sourceTreeContext.objectActionProvider;
  }

  constructor(
    @Optional() @Inject(SOURCE_TREE_CONTEXT) private sourceTreeContext: SourceTreeContext,
    private passthroughOptionsService: PassthroughOptionsService,
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    // Only update the subscription if the actual node has changed. Node context will update any time the
    // selection status of the node has changed, which results in unecessary updates
    if (this.nodeContext?.node !== changes.nodeContext?.previousValue?.node && this.actionProvider) {
      this.actions$ = this.nodeContext.selection.changes$.pipe(
        startWith(null as any),

        // The below call for getObjectInfo really only needs to update when the exclusion list
        // changes for a selection, so we should not let it fire if that hasn't changed.
        map(selection => selection?.excluded),
        distinctUntilChanged(),
        switchMap(() => this.actionProvider.getObjectActions(this.getObjectInfo())),
      );
    }
  }

  /**
   * Converts the protection source node to a format that can be passed to the menu action provider.
   *
   * @returns Object info for the current node.
   */
  getObjectInfo(): SimpleObjectInfo {
    const {
      data: v1Object,
      environment,
      protected: isProtected,
      partialProtected,
      type: objectType,
      isObjectProtected,
    } = this.nodeContext.node;
    const { id, parentId: sourceId } = v1Object.protectionSource;
    const excludedIds = this.nodeContext.selection.currentSelection.excluded
      .filter(
        excluded =>
          this.nodeContext.treeControl.checkAnyAncestor(excluded, parent => parent === this.nodeContext.node) ||
          (excluded.tagIds || []).includes(Number(id))
      )
      .map(excluded => excluded.id as number);
    return {
      id,
      environment,
      sourceId,
      v1Object,

      // Auto protected objects may show as partial protected here. This will force us to
      // check for object protection on those objects, without still having to do a lookup
      // on every single object.
      isProtected: isProtected || partialProtected,
      isObjectProtected,
      objectType,
      excludedIds,
      workloadType: this.workloadType,
      ...this.passthroughOptionsService.requestParams,
    };
  }
}
