/**
 * Source keys for public protection source api. This maps the source environment to
 * the property key containing its data.
 */
export enum SourceKeys {
  _kKuiper = 'cohesityClusterProtectionSource',
  kAD = 'adProtectionSource',
  kAcropolis = 'acropolisProtectionSource',
  kAWS = 'awsProtectionSource',
  kAzure = 'azureProtectionSource',
  kCassandra = 'cassandraProtectionSource',
  kCouchbase = 'couchbaseProtectionSource',
  kElastifile = 'elastifileProtectionSource',
  kExchange = 'exchangeProtectionSource',
  kFlashBlade = 'flashBladeProtectionSource',
  kGCP = 'gcpProtectionSource',
  kGPFS = 'gpfsProtectionSource',
  kGenericNas = 'nasProtectionSource',
  kHBase = 'hbaseProtectionSource',
  kHdfs = 'hdfsProtectionSource',
  kHive = 'hiveProtectionSource',
  kHyperV = 'hypervProtectionSource',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  kHyperVVSS = 'hypervProtectionSource',
  kIbmFlashSystem = 'ibmFlashSystemProtectionSource',
  kIsilon = 'isilonProtectionSource',
  kKVM = 'kvmProtectionSource',
  kMongoDB = 'mongodbProtectionSource',
  kNetapp = 'netappProtectionSource',
  kO365 = 'office365ProtectionSource',
  kOracle = 'oracleProtectionSource',
  kPhysical = 'physicalProtectionSource',
  kPure = 'pureProtectionSource',
  kSQL = 'sqlProtectionSource',
  kUDA = 'udaProtectionSource',
  kView = 'viewProtectionSource',
  kVMware = 'vmWareProtectionSource',
  kHyperFlex = 'hyperFlexProtectionSource',
  kKubernetes = 'kubernetesProtectionSource',
  kNimble = 'nimbleProtectionSource',
  kSfdc = 'sfdcProtectionSource',
  kSAPHANA = 'sapHanaProtectionSource'
}

/**
 * describes the usage of the source
 * Dedicated source cannot be shared among orgs
 * Sharead source can be shared among orgs
 */
export enum SourceUsageType {
  dedicated = 'dedicated',
  shared = 'shared',
}

/**
 * Describes the status of a source with respect to maintenance modes.
 */
export enum SourceStatus {
  UNDER_MAINTENANCE_FINITE = 'underMaintenanceFinite',
  UNDER_MAINTENANCE_INFINITE = 'underMaintenanceInfinite',
  SCHEDULED_MAINTENANCE = 'scheduledMaintenance',
  NO_MAINTENANCE_CONFIGURED = 'noMaintenanceConfigured',
  MAINTENANCE_EXPIRED = 'expired',
}

/**
 * Describes the workflow type in the maintenance mode configuration for a source.
 */
export enum WorkflowType {
  BACKUP_RUN = 'BackupRun'
}

/**
 * Describes the intervention to be applied to the workflow type.
 */
export enum Intervention {
  CANCEL = 'Cancel'
}
