import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ArgusAppService, notStartedStatus } from '@cohesity/data-govern/shared';
import { Environment } from '@cohesity/iris-shared-constants';
import { getClusterIdentifier, ObjectTypeIconPipe } from '@cohesity/utils';
import { map } from 'rxjs/operators';

import { ConcatenatedObjectId, SearchObjectPerSource } from '../../scan.models';

@Component({
  selector: 'dg-scan-object-search-item-detail',
  templateUrl: './object-search-item-detail.component.html',
  styleUrls: ['./object-search-item-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ObjectSearchItemDetailComponent {
  // Selected Search Object
  @Input() obj: SearchObjectPerSource;

  // Icon Size
  @Input() autoTruncate = false;

  // Flag for displaying remove button
  @Input() enableRemove = false;

  // Flag for displaying install connector link
  @Input() showInstallConnectorLink = true;

  // emits object of the item to be removed
  @Output() remove = new EventEmitter<ConcatenatedObjectId>();

  /** The cluster status */
  clusterStatus$ = this.argusAppService.getAppInstallationStatus().pipe(
    map(({ clusterByClusterIdentifierId }) =>
      clusterByClusterIdentifierId?.get(getClusterIdentifier(this.obj?.objectProtectionInfo))?.status
    ),
  );

  /** The app status for which anti ransomware data classification might not be started. */
  readonly notStartedStatus = notStartedStatus;

  // gives object name if present otherwise gives clusterId
  get objectName() {
    return this.obj.objectProtectionInfo.name || this.obj.objectProtectionInfo.clusterId;
  }

  /**
   * Fetch objectType icon
   */
  get objectTypeIcon(): string {
    return this.objectTypeIconPipe.transform(
      this.obj?.object?.environment as Environment,
      this.obj?.object?.objectType,
      this.obj?.object?.osType
    );
  }

  /** The install app link */
  getInstallAppLinkFn$ = this.argusAppService.getInstallAppLinkFn$;

  /** Return the install app link params. */
  getInstallAppLinkParamsFn = this.argusAppService.getInstallAppLinkParamsFn;

  constructor(private argusAppService: ArgusAppService, private objectTypeIconPipe: ObjectTypeIconPipe) {}
}
