import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { distinctUntilChanged, startWith } from 'rxjs/operators';
import { HypervDiskFilterType } from 'src/app/shared';

import {
  BaseProtectionBuilderComponent,
} from '../../../../protection-builder/components/base-protection-builder/base-protection-builder.component';

/**
 * Interface for Hyperv disk info.
 */
interface HypervDisk {
  controllerType: string;
  controllerNumber: number;
  unitNumber: number;
}
/**
 * Interface for Hyperv disk filter.
 */
interface HypervDiskFilter {
  enableDiskFilter: boolean;
  type?: string;
  disks?: HypervDisk[];
}

/**
 * Default value for Hyperv disk filter.
 */
export const defaultHypervDiskFilter: HypervDiskFilter = {
  enableDiskFilter: false,
  type: HypervDiskFilterType.Include,
  disks: [],
};

@Component({
  selector: 'coh-settings-list-hyperv-disk-filter',
  templateUrl: './settings-list-hyperv-disk-filter.component.html',
  styleUrls: ['./settings-list-hyperv-disk-filter.component.scss'],
})
export class SettingsListHypervDiskFilterComponent extends BaseProtectionBuilderComponent<HypervDiskFilter, any>
  implements OnInit {

  /**
   * List of supported controller type
   */
  readonly controllerTypes = ['scsi', 'ide'];

  /**
   * Default protection type value.
   */
  _value: HypervDiskFilter = defaultHypervDiskFilter;

  /**
   * Get the disks form control.
   *
   * @return   FormArray of disks controls.
   */
  get disksControl(): UntypedFormArray {
    return this.diskFilterFormGroup.get('disks') as UntypedFormArray;
  }

  /**
   * Disk exclusions form group for the parent adapter form.
   */
  diskFilterFormGroup = new UntypedFormGroup({
    enableDiskFilter: new UntypedFormControl(false, Validators.required),
    type: new UntypedFormControl(HypervDiskFilterType.Include),
    disks: new UntypedFormArray([]),
  });

  constructor(private translate: TranslateService) {
    super();
  }

  ngOnInit() {
    // Update validators for disks control
    this.diskFilterFormGroup.get('enableDiskFilter').valueChanges.pipe(
      startWith(this.value.enableDiskFilter),
      this.untilDestroy(),
    ).subscribe((isEnabled: boolean) => {
      if (!isEnabled) {
        this.diskFilterFormGroup.removeControl('disks');
      } else {
        this.diskFilterFormGroup.addControl('disks', new UntypedFormArray([], Validators.required));
      }
    });

    // Reset the include/exclude disk selection.
    this.diskFilterFormGroup.get('type').valueChanges.pipe(
      this.untilDestroy(),
      distinctUntilChanged(),
    ).subscribe(() => {
      this.diskFilterFormGroup.removeControl('disks');
      this.diskFilterFormGroup.addControl('disks', new UntypedFormArray([], Validators.required));
    });
  }

  /**
   * Attach internal form group to the parent component.
   */
  addFormControl() {
    this.formGroup.addControl(this.name, this.diskFilterFormGroup);
  }

  /**
   * Form control init.
   */
  initFormControl() {
    if (this.protectionGroup?.hypervGlobalExcludeDisks || this.protectionGroup?.hypervGlobalIncludeDisks) {
      let disks = [];
      if (this.protectionGroup.hypervGlobalExcludeDisks) {
        disks = this.protectionGroup.hypervGlobalExcludeDisks;
        this.value = {
          enableDiskFilter: true,
          type: HypervDiskFilterType.Exclude,
          disks,
        };
      } else if (this.protectionGroup.hypervGlobalIncludeDisks) {
        disks = this.protectionGroup.hypervGlobalIncludeDisks;
        this.value = {
          enableDiskFilter: true,
          type: HypervDiskFilterType.Include,
          disks,
        };
      }

      for (const disk of disks) {
        // Create a new form control item for each new existing disk.
        this.addDiskFormControl(disk);
      }

      this.formControl.patchValue(this.value);
    }
  }

  /**
   * Add a new disk to excluded disks.
   *
   * @param   value   default disk value.
   */
  addDiskFormControl(value: HypervDisk) {
    const { controllerType, controllerNumber, unitNumber } = value || {};

    this.disksControl.push(
      new UntypedFormGroup({
        controllerType: new UntypedFormControl(controllerType, Validators.required),
        controllerNumber: new UntypedFormControl(controllerNumber, [Validators.required, Validators.min(0)]),
        unitNumber: new UntypedFormControl(unitNumber, [Validators.required, Validators.min(0)])
      })
    );
  }

  /**
   * Remove a disk at an index.
   *
   * @param   index   index to remove the disk at.
   */
  removeDiskFormControl(index: number) {
    this.disksControl.removeAt(index);
  }

  /**
   * Returns list of disk addresses as string format.
   *
   * @param disks   List of disks to be filtered
   * @returns       Array of corresponding disk addresses.
   */
  getDiskAddress(disks: HypervDisk[] = []): string[] {
    return disks.map(disk => {
      const { controllerType, controllerNumber, unitNumber } = disk;
      const type = this.translate.instant(`enum.controllerType.${controllerType}`);
      return `${type}.${controllerNumber}.${unitNumber}`;
    });
  }
}
