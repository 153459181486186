import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AttributeFilter, FiltersServiceApi } from '@cohesity/api/reporting';
import { FiltersComponent, ValueFilterSelection } from '@cohesity/helix';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseReportFilterComponent } from '../base-report-filter.component';

@Component({
  selector: 'iris-rpt-tenants-filter',
  templateUrl: './tenants-filter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TenantsFilterComponent extends BaseReportFilterComponent {
  /**
   * allow multiple selection on filter based on certain report condition
   */
  allowMultiple = true;

  constructor(filters: FiltersComponent, private api: FiltersServiceApi) {
    super(filters, 'tenantId');
  }

  getFilterValues(): Observable<ValueFilterSelection[]> {
    return this.api.GetResources({ resourceType: 'Tenants' }).pipe(
      map(response => response?.tenants || []),
      map(tenants =>
        tenants.map(tenant => ({
          label: tenant.name,
          value: tenant.id,
        }))
      )
    );
  }

  toApiValue(filterValues: ValueFilterSelection[]): AttributeFilter {
    if (!filterValues?.length) {
      return null;
    }
    return {
      attribute: this.property,
      filterType: 'Tenants',
      tenantsFilterParams: {
        tenantIds: filterValues.map(value => value.value as string),
        tenantNames: filterValues.map(value => value.label),
      },
    };
  }

  fromApiValue(apiValue: AttributeFilter): ValueFilterSelection[] {
    return (apiValue?.tenantsFilterParams.tenantIds || []).map((value, index) => ({
      label: apiValue.tenantsFilterParams.tenantNames[index],
      value: value,
    }));
  }
}

@Component({
  selector: 'iris-rpt-tenants-filter',
  templateUrl: './tenants-filter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TenantsFilterSingleSelectionComponent extends TenantsFilterComponent {
  /**
   * allow multiple selection on filter based on certain report condition
   */
  allowMultiple = false;
}
