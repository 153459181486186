import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ObjectActivityUIAdapter } from 'src/app/modules/object-activity-shared/models';

/**
 * @description
 *
 * Legal Hold object modal dialog.
 */
@Component({
  selector: 'coh-object-legal-hold-dialog',
  templateUrl: './object-legal-hold-dialog.component.html',
  styleUrls: ['./object-legal-hold-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ObjectLegalHoldDialogComponent {
  /**
   * Activity item from the timeline.
   */
  activity: ObjectActivityUIAdapter;

  constructor(
    @Inject(MAT_DIALOG_DATA) readonly data,
  ) {
    this.activity = data.activity;
  }

  /**
   * getter to check if the activity run is on legal hold.
   */
  get isOnLegalHold(): boolean {
    return this.activity.backupRunParams?.onLegalHold ||
      this.activity.archivalRunParams?.onLegalHold;
  }
}
