import { Component, ContentChildren, EventEmitter, Input, Output, QueryList, ViewEncapsulation } from '@angular/core';

import { BannerComponent } from '../../../banner/banner.component';
import { HelixIntlService } from '../../../../helix-intl.service';
import { IconModule } from '../../../icon/icon.module';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { NgIf, DecimalPipe } from '@angular/common';

/**
 * banners component to aggregate banner components
 * under a paginated parent component
 */
@Component({
  selector: 'cog-banners',
  templateUrl: './banners.component.html',
  styleUrls: ['./banners.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [NgIf, MatLegacyButtonModule, IconModule, DecimalPipe]
})
export class BannersComponent {
  /**
   * set of banners (children) inside the current <cog-banners> tag
   */
  @ContentChildren(BannerComponent) banners: QueryList<BannerComponent>;

  /**
   * input to show/hide close button
   */
  @Input() showCloseButton: boolean;

  /**
   * When close button is clicked; Emit an event
   */
  @Output() closeButtonClick = new EventEmitter<number>();

  /**
   * Emit an event when banner index changed
   */
  @Output() currentBannerIndex = new EventEmitter<number>();

  /**
   * index of the currently displayed banner
   */
  bannerIndex = 0;

  constructor(private intlSvc: HelixIntlService) {}

  /**
   * handler for clicking right button on the banner pagination
   */
  goNext() {
    if (this.bannerIndex < this.banners.length - 1) {
      this.bannerIndex++;
    } else {
      this.bannerIndex = 0;
    }
    this.currentBannerIndex.emit(this.bannerIndex);
  }

  /**
   * handler for clicking left button on the banner pagination
   */
  goPrev() {
    if (this.bannerIndex > 0) {
      this.bannerIndex--;
    } else {
      this.bannerIndex = this.banners.length - 1;
    }
    this.currentBannerIndex.emit(this.bannerIndex);
  }

  /**
   * handler for clicking cross button on the banner
   */
  close() {
    this.closeButtonClick.emit(this.bannerIndex);

    // Banners length is updated after the above emit event which removes a banner
    // So need to make sure bannerIndex < updated banners length
    if (this.banners.length && this.bannerIndex >= this.banners.length)  {
      this.bannerIndex = this.banners.length - 1;
      this.currentBannerIndex.emit(this.bannerIndex);
    }
  }

  /**
   * Gets label from key.
   *
   * @param key String key for translate
   * @returns  Translated text.
   */
  getLabel(key: string): string {
    return this.intlSvc.banner[key];
  }
}
