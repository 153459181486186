import { Injectable } from '@angular/core';
import { NavItem } from '@cohesity/helix';
import { AppType, flagEnabled, IrisContext, PillarType } from '@cohesity/iris-core';
import { TranslateService } from '@ngx-translate/core';

import { ServiceNavProvider } from '../service-nav-provider';

/**
 * This Service configures the disaster recovery navigation menu with iris.
 */
@Injectable({ providedIn: 'root' })
export class DraasNavService implements ServiceNavProvider {
  pillar: PillarType = 'mobility';

  app: AppType = 'draas';

  get serviceNavTitle(): string {
    return this.translate.instant('siteContinuity');
  }

  serviceNavTitleIcon = 'helix:logo-site-continuity';

  serviceNavTitleClass = 'site-continuity';

  showNavBranding = true;

  getNavItems(irisContext: IrisContext): NavItem[] {
    return [
      {
        displayName: 'dashboard',
        state: 'dashboards.disaster-recovery',
        icon: 'dashboard!outline',
      },
      {
        displayName: 'drPlans',
        state: 'disaster-recovery.list.plans',
        icon: 'helix:dr-plans',
      },
      {
        displayName: 'activity',
        state: 'disaster-recovery.activities',
        icon: 'aspect_ratio!outline',
      },
      flagEnabled(irisContext, 'keplerAuditLogs') && {
        displayName: 'auditLogs',
        state: 'helios-audit-logs',
        icon: 'iris:audit-log!outline',
      },
      flagEnabled(irisContext, 'keplerAlertsEnabled') && {
        displayName: 'health',
        state: 'health.alerts',
        icon: 'notifications!outline',
      },
      {
        displayName: 'infrastructure',
        state: '',
        icon: 'iris:inventory',
        subNavList: [
          {
            displayName: 'dataPools',
            state: 'data-pools',
          },
          {
            displayName: 'sites',
            state: 'data-sites.list',
          },
          {
            displayName: 'sources',
            state: 'dms-sources.landing',
          },
        ],
      },
      {
        displayName: 'settings',
        state: 'helios-access-management.users',
        icon: 'settings!outline',
        subNavList: [
          {
            displayName: 'regions',
            state: 'dms.regions',
          },
          {
            displayName: 'saasConnections',
            state: 'dms-connections',
          },
        ],
      },
    ].filter(Boolean);
  }
  constructor(private translate: TranslateService) {}
}
