import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ColumnConfig } from '@cohesity/helix';
import { AutoDestroyable } from '@cohesity/utils';
import { map } from 'rxjs/operators';

import {
  AnomaliesStatus,
  anomaliesTagNames,
  anomalyStatusColors,
  anomalyStatusLabelKeys,
  ClassificationStatus,
  classificationStatusColors,
  classificationStatusLabelKeys,
  classificationTagNames,
  FilterableFields,
  PostureRiskStatus,
  PostureRiskStatusColors,
  PostureRiskStatusLabelKeys,
  postureRiskTagNames,
  ProtectionStatus,
  protectionStatusColors,
  protectionStatusValueLabelKey,
  TableColumns,
  threatsTagNames,
  ThreatStatus,
  threatStatusColors,
  threatStatusLabelKeys,
  VaultingStatus,
  VaultingStatusColors,
  VaultingStatusLabelKeys,
  vaultingTagNames,
} from '../../../../constants';
import { InventoryService } from '../../../../services';
import { ColumnHeaderCell } from '../table-cell.types';

/**
 * interface representing the possible states
 */
interface PossibleState {
  // class denoting the colour of the background
  colorClass: string;

  // state label
  labelKey: string;
}

@Component({
  selector: 'dg-sc-inventory-heat-map-column-header',
  templateUrl: './heat-map-column-header.component.html',
  styleUrls: ['./heat-map-column-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class HeatMapColumnHeaderComponent extends AutoDestroyable implements ColumnHeaderCell, OnInit {
  /**
   * map of column specific tooltip messages
   */
  static tooltipMessageKeys: Record<string, string> = {
    [TableColumns.protection]: 'inventory.tooltip.protection',
    [TableColumns.anomalies]: 'inventory.tooltip.anomalies',
    [TableColumns.threats]: 'inventory.tooltip.threats',
    [TableColumns.classification]: 'inventory.tooltip.classification',
    [TableColumns.postureRisk]: 'inventory.tooltip.postureRisk',
    [TableColumns.vaulting]: 'inventory.tooltip.vaulting',
  };

  /**
   * map of column specific tooltip messages that are shown when filters are applied
   */
  static tooltipMessageKeysWithFilters: Record<string, string> = {
    [TableColumns.anomalies]: 'inventory.tooltip.anomalies_filtered',
    [TableColumns.threats]: 'inventory.tooltip.threats_filtered',
    [TableColumns.classification]: 'inventory.tooltip.classification_filtered',
  };

  /**
   * map of possible states for the supported columns
   */
  static statesMap: Record<string, PossibleState[]> = {
    [TableColumns.protection]: Object.keys(ProtectionStatus)
      .map((key) => ({
        labelKey: protectionStatusValueLabelKey[key],
        colorClass: `state-color-${protectionStatusColors[key]}`,
      })),
    [TableColumns.anomalies]: Object.keys(AnomaliesStatus)
      .filter((key) => !!anomaliesTagNames[key])
      .map((key) => ({
        labelKey: anomalyStatusLabelKeys[key],
        colorClass: `state-color-${anomalyStatusColors[key]}`,
      })),
    [TableColumns.threats]: Object.keys(ThreatStatus)
      .filter((key) => !!threatsTagNames[key])
      .map((key) => ({
        labelKey: threatStatusLabelKeys[key],
        colorClass: `state-color-${threatStatusColors[key]}`,
      })),
    [TableColumns.classification]: Object.keys(ClassificationStatus)
      .filter((key) => !!classificationTagNames[key])
      .map((key) => ({
        labelKey: classificationStatusLabelKeys[key],
        colorClass: `state-color-${classificationStatusColors[key]}`,
      })),
    [TableColumns.postureRisk]: Object.values(PostureRiskStatus)
      .filter((key) => !!postureRiskTagNames[key])
      .map((key) => ({
        labelKey: PostureRiskStatusLabelKeys[key],
        colorClass: `state-color-${PostureRiskStatusColors[key]}`,
      })),
    [TableColumns.vaulting]: Object.keys(VaultingStatus)
      .filter((key) => !!vaultingTagNames[key])
      .map((key) => ({
        labelKey: VaultingStatusLabelKeys[key],
        colorClass: `state-color-${VaultingStatusColors[key]}`,
      })),
  };

  /**
   * Column configuration for which the cell is being rendered.
   */
  @Input() column: ColumnConfig;

  /**
   * Tooltip message key for the column specific message.
   */
  messageKey = '';

  /**
   * List of possible states for the column, this is generated based on the supplied column configuration
   */
  possibleStates: PossibleState[] = [];

  constructor(
    private cdr: ChangeDetectorRef,
    private inventoryService: InventoryService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.messageKey = HeatMapColumnHeaderComponent.tooltipMessageKeys[this.column.key];
    this.possibleStates = HeatMapColumnHeaderComponent.statesMap[this.column.key];

    this.inventoryService.appliedFilters$.pipe(
      this.untilDestroy(),
      map((appliedFilters) => appliedFilters.some(filter => [
        FilterableFields.anomalies,
        FilterableFields.classification,
        FilterableFields.threats,
      ].includes(filter.key as FilterableFields) && filter.value.length > 0)),
    ).subscribe((hasFilters) => {
      const filteredKey = HeatMapColumnHeaderComponent.tooltipMessageKeysWithFilters[this.column.key];
      if (hasFilters && filteredKey) {
        this.messageKey = filteredKey;
      } else {
        this.messageKey = HeatMapColumnHeaderComponent.tooltipMessageKeys[this.column.key];
      }
      this.cdr.detectChanges();
    });

    this.cdr.detectChanges();
  }
}
