import { Component, ViewEncapsulation } from '@angular/core';
import { Template } from '@cohesity/api/v2';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';
import { CreateForm, createFormProviders } from '@cohesity/shared-forms';

import { CreateViewBasicForm } from '../../models';
import { CreateViewFormService } from '../../services';
import { isNFSProtocolSelected, isSMBProtocolSelected, isSwiftProtocolSelected } from '../../utils';

@Component({
  selector: 'coh-create-view-basic-form',
  templateUrl: './create-view-basic-form.component.html',
  styleUrls: ['./create-view-basic-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: createFormProviders(CreateViewBasicFormComponent),
})
export class CreateViewBasicFormComponent {
  /**
   * Form Value for CreateViewBasicForm.
   */
  get basicFormGroupValue() {
    return this.createViewService.currentFormValue?.createViewBasicForm;
  }

  /**
   * The view template info params.
   */
  get viewTemplateInfo(): Template {
    return this.createViewService.createViewData;
  }

  /**
   * Specifies whether swift access protocol is selected or not.
   */
  get isSwiftProtocolSelected() {
    return isSwiftProtocolSelected(this.basicFormGroupValue?.protocolAccess);
  }

  /**
   * Determines whether to recommend a Storage Domain. We do this only when
   * creating a View from a built-in template.
   */
  get isRecommendedStorageDomain(): boolean {
    return this.createViewService.workflow === 'CreateViewFromTemplate' && this.viewTemplateInfo.isDefault;
  }

  /**
   * Determines whether to show the Secure View toggle.
   */
  get showMostSecureSettingsToggle() {
    return this.createViewService.isCreateMode &&
      flagEnabled(this.irisCtx.irisContext, 'viewSettingsSecureByDefault') &&
      this.basicFormGroupValue.category === 'FileServices' &&
      ['CreateTemplate', 'CreateViewFromTemplate'].includes(this.createViewService.workflow) &&
      (isSMBProtocolSelected(this.basicFormGroupValue?.protocolAccess) ||
      isNFSProtocolSelected(this.basicFormGroupValue?.protocolAccess));
  }

  /**
   * Initialize the form.
   */
  form = new CreateForm<CreateViewBasicForm>(this, {
    formControls: this.createViewService.getCreateViewBasicFormControls(),

    // Need to return the rawValue of the form as storageDomainId formControl
    // doesn't return a value if disabled.
    transformFromFormGroup: () => this.form.formGroup.getRawValue(),
  });

  constructor(
    private createViewService: CreateViewFormService,
    private irisCtx: IrisContextService) {}
}
