import { Directive, TemplateRef } from '@angular/core';

/**
 * Allows consumers of searchable-select to provide a custom template for rendering
 * the select trigger.
 */
@Directive({ selector: '[cohSearchableSelectTriggerTemplate]' })
export class SearchableSelectTriggerTemplateDirective {
  constructor(readonly templateRef: TemplateRef<unknown>) {}
}

/**
 * Allows consumers of searchable-select to provide a custom template for rendering
 * the options.
 */
@Directive({ selector: '[cohSearchableSelectItemTemplate]' })
export class SearchableSelectItemTemplateDirective {
  constructor(readonly templateRef: TemplateRef<unknown>) {}
}
