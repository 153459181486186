import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { DataTreeNodeContext } from '@cohesity/helix';
import { IrisContextService, isDmsScope, isIbmBaaSEnabled } from '@cohesity/iris-core';
import { AutoDestroyable } from '@cohesity/utils';
import { shareReplay } from 'rxjs/operators';
import {
  SqlAagDetailsPopoverComponent,
} from 'src/app/shared/sql/components/sql-aag-details/sql-aag-details-popover.component';
import { AagDataMap, SqlUtilityService } from 'src/app/shared/sql/services/sql-utility.service';

import { SqlSourceDataNode } from '../sql-source-data-node';
import { ClusterTypeMap } from 'src/app/shared/constants';


@Component({
  selector: 'coh-sql-source-metadata',
  templateUrl: './sql-source-metadata.component.html',
  styleUrls: ['./sql-source-metadata.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SqlSourceMetadataComponent extends AutoDestroyable implements OnInit {
  /**
   * The node context, including info about the node and it's selection status.
   */
  @Input() nodeContext: DataTreeNodeContext<SqlSourceDataNode>;

  /**
   * The component to show in the popover for AAG
   */
  aagDetailsPopoverComponent = SqlAagDetailsPopoverComponent;

  /**
   * The AAG data.
   */
  aagDataMap: AagDataMap;

  /**
   * Specifies the cluster type.
   */
  clusterType = this.contextService.irisContext.basicClusterInfo.clusterType;

  /**
   * Access to the current node property.
   */
  get node(): SqlSourceDataNode {
    return this.nodeContext && this.nodeContext.node;
  }

  /**
   * Gets whether this is an AAG node.
   */
  get isAagNode(): boolean {
    return this.node.isAagNode;
  }

  /**
   * Returns true if its IBM Cloud cluster.
   */
  get isIbmBaasCluster(): boolean {
    return this.clusterType === ClusterTypeMap.kIBMCloud &&
      isIbmBaaSEnabled(this.contextService.irisContext);
  }

  /**
   * Indicates if this is a DMaaS scope.
   */
  get isDmsScope(): boolean {
    return isDmsScope(this.contextService.irisContext);
  }

  constructor(
    private contextService: IrisContextService,
    private sqlService: SqlUtilityService
  ) {
    super();
  }

  ngOnInit() {
    if (this.isAagNode) {
      if (!this.node.isAagHost) {
        this.sqlService.fetchAagData(this.node.id);
        this.sqlService.getAagData(this.node.id)
          .pipe(this.untilDestroy(), shareReplay(1))
          .subscribe(aagDataMap => this.aagDataMap = aagDataMap);
      } else {
        // TODO(mythri): Handle here based on AAG protection Source info.
      }
    }
  }
}
