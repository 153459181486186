import { Component } from '@angular/core';

/**
 * Styles for these layout components are global.
 */
@Component({
  selector: 'cog-card-section',
  template: '<ng-content></ng-content>',
  standalone: true
})
export class CardSectionComponent {
}
