import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

/**
 * model to track the carousel component's shared state
 */
export interface ICarouselState {
  /**
   * Direction for the slide animation. When set to 'next', carousel items will slide from right to left.
   * When set to 'prev', items will slide from left to right.
   */
  direction: 'next' | 'prev';
}

@Injectable({
  providedIn: 'root'
})
export class CarouselService {
  /**
   * state of the carousel component
   */
  private carouselState = new BehaviorSubject<ICarouselState>({
    direction: 'next'
  });

  /**
   * state observable
   */
  carouselState$ = this.carouselState.asObservable();

  constructor() {}

  /**
   * Updated the state to newly supplied state
   *
   * @param state new state of the carousel component
   */
  setState(state: ICarouselState): void {
    this.carouselState.next(state);
  }
}
