import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 's3KeyMapping'
})
export class S3KeyMappingPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  /**
   * Returns a view's S3 Key Mapping setting string.
   *
   * @example
   * <div class="dl-row">
   * <dd>{{view.s3KeyMappingConfig | s3Mapping }}</dd>
   * </div>
   *
   * @params   s3KeyMapping
   * @returns Mapped s3 mapping.
   */
  transform(s3KeyMapping: string): string {
    let s3Mapping: string;

    if (!s3KeyMapping || (['kHierarchical', 'Hierarchical'].includes(s3KeyMapping))) {
      s3Mapping = 'views.s3.keyPattern.hierarchical';
    } else if (['kObjectId', 'ObjectId'].includes(s3KeyMapping)) {
      s3Mapping = 'views.s3.keyPattern.objectId';
    } else if (['kRandom', 'Random'].includes(s3KeyMapping)) {
      s3Mapping = 'views.s3.keyPattern.random';
    } else if (['kShort', 'Short'].includes(s3KeyMapping)) {
      s3Mapping = 'views.s3.keyPattern.short';
    } else {
      s3Mapping = 'views.s3.keyPattern.long';
    }
    return this.translate.instant(s3Mapping);
  }
}
