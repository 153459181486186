import { Component } from '@angular/core';

import {
  BaseProtectionBuilderComponent,
} from '../../../../protection-builder/components/base-protection-builder/base-protection-builder.component';

/**
 * Default value for cloud migration.
 */
export const DefaultCdpSyncReplication = false;

@Component({
  selector: 'coh-settings-list-cdp-sync-replication',
  templateUrl: './settings-list-cdp-sync-replication.component.html',
})
export class SettingsListCdpSyncReplicationComponent
  extends BaseProtectionBuilderComponent<boolean, any> {
  /**
   * Default cloud migration value.
   */
  _value = DefaultCdpSyncReplication;

  /**
   * Form control init.
   */
  initFormControl() {
    if (this.protectionGroup?.id) {
      this.value = Boolean(this.protectionGroup.enableCdpSyncReplication);
      this.formControl.setValue(this.value);
    }
  }
}
