import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { TranslateService } from '@ngx-translate/core';

/**
 * App paywall message component.
 */
@Component({
  selector: 'coh-app-cluster-message',
  templateUrl: './app-cluster-message.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class AppClusterMessageComponent implements OnInit {

  /** App data. */
  @Input() appName: string;

  message: SafeHtml;

  constructor(private translateService: TranslateService, private sanitizer: DomSanitizer) {

  }
  ngOnInit(): void {
    const appName = this.translateService.instant(this.appName);
    this.message = this.sanitizer.bypassSecurityTrustHtml(this.translateService.instant('appPillar.clusterClaimRequired', { appName }));
  }
}
