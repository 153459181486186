
import { Inject, LOCALE_ID, Optional, Pipe, PipeTransform } from '@angular/core';
import {
  DATE_PIPE_OPTIONS,
  DatePipeOptions,
  MomentDatePipe,
  ValidDateInput,
} from '@cohesity/helix';
/**
 * @description
 * See MomentDatePipe in Helix for more details. This is now purely a wrapper for that pipe
 *
 * @example
 *   // Default
 *   {{ pointInTimeSeconds | cohDate }}
 *
 *   // Angular named patterns
 *   {{ usecStartTime | cohDate:'longDate' }}
 *
 *   // Moment patterns
 *   {{ msecStartTime | cohDate:'#MMM D, Y' }}
 *
 * @export
 */
@Pipe({ name: 'cohDate', pure: true })
export class DatePipeWrapper implements PipeTransform {
  /**
   * The actual date pipe
   */
  private converter: MomentDatePipe;
  constructor(
    @Inject(LOCALE_ID) locale: string,
    @Inject(DATE_PIPE_OPTIONS) @Optional() globalOptions?: DatePipeOptions,
  ) {
    this.converter = new MomentDatePipe(locale, globalOptions);
  }
  /**
   * Same args as Angular's own DatePipe:
   * https://github.com/angular/angular/blob/7.2.7/packages/common/src/pipes/date_pipe.ts#L154
   *
   * @param     value    The date to format.
   * @param     [args]   Same arguments as Angular's DatePipe.transform,
   *                     starting with pattern.
   * @returns   The formatted date string.
   */
  transform(value: ValidDateInput, ...args): string | null {
    return this.converter.transform(value, ...args);
  }
}
