// Generated from libs/grammar/azure-volume-exclusions/Azure.g4 by ANTLR 4.9.0-SNAPSHOT


import { ATN } from "antlr4ts/atn/ATN";
import { ATNDeserializer } from "antlr4ts/atn/ATNDeserializer";
import { CharStream } from "antlr4ts/CharStream";
import { Lexer } from "antlr4ts/Lexer";
import { LexerATNSimulator } from "antlr4ts/atn/LexerATNSimulator";
import { NotNull } from "antlr4ts/Decorators";
import { Override } from "antlr4ts/Decorators";
import { RuleContext } from "antlr4ts/RuleContext";
import { Vocabulary } from "antlr4ts/Vocabulary";
import { VocabularyImpl } from "antlr4ts/VocabularyImpl";

import * as Utils from "antlr4ts/misc/Utils";


export class AzureLexer extends Lexer {
	public static readonly COMMA = 1;
	public static readonly EQ = 2;
	public static readonly NOT_EQ = 3;
	public static readonly OPEN_PAREN = 4;
	public static readonly CLOSE_PAREN = 5;
	public static readonly K_AND = 6;
	public static readonly K_IN = 7;
	public static readonly K_NOT = 8;
	public static readonly K_OR = 9;
	public static readonly IDENTIFIER = 10;
	public static readonly SPACES = 11;

	// tslint:disable:no-trailing-whitespace
	public static readonly channelNames: string[] = [
		"DEFAULT_TOKEN_CHANNEL", "HIDDEN",
	];

	// tslint:disable:no-trailing-whitespace
	public static readonly modeNames: string[] = [
		"DEFAULT_MODE",
	];

	public static readonly ruleNames: string[] = [
		"COMMA", "EQ", "NOT_EQ", "OPEN_PAREN", "CLOSE_PAREN", "K_AND", "K_IN",
		"K_NOT", "K_OR", "IDENTIFIER", "SPACES", "A", "B", "C", "D", "E", "F",
		"G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T",
		"U", "V", "W", "X", "Y", "Z",
	];

	private static readonly _LITERAL_NAMES: Array<string | undefined> = [
		undefined, "','", "'='", "'!='", "'('", "')'",
	];
	private static readonly _SYMBOLIC_NAMES: Array<string | undefined> = [
		undefined, "COMMA", "EQ", "NOT_EQ", "OPEN_PAREN", "CLOSE_PAREN", "K_AND",
		"K_IN", "K_NOT", "K_OR", "IDENTIFIER", "SPACES",
	];
	public static readonly VOCABULARY: Vocabulary = new VocabularyImpl(AzureLexer._LITERAL_NAMES, AzureLexer._SYMBOLIC_NAMES, []);

	// @Override
	// @NotNull
	public get vocabulary(): Vocabulary {
		return AzureLexer.VOCABULARY;
	}
	// tslint:enable:no-trailing-whitespace


	constructor(input: CharStream) {
		super(input);
		this._interp = new LexerATNSimulator(AzureLexer._ATN, this);
	}

	// @Override
	public get grammarFileName(): string { return "Azure.g4"; }

	// @Override
	public get ruleNames(): string[] { return AzureLexer.ruleNames; }

	// @Override
	public get serializedATN(): string { return AzureLexer._serializedATN; }

	// @Override
	public get channelNames(): string[] { return AzureLexer.channelNames; }

	// @Override
	public get modeNames(): string[] { return AzureLexer.modeNames; }

	public static readonly _serializedATN: string =
		"\x03\uC91D\uCABA\u058D\uAFBA\u4F53\u0607\uEA8B\uC241\x02\r\xE3\b\x01\x04" +
		"\x02\t\x02\x04\x03\t\x03\x04\x04\t\x04\x04\x05\t\x05\x04\x06\t\x06\x04" +
		"\x07\t\x07\x04\b\t\b\x04\t\t\t\x04\n\t\n\x04\v\t\v\x04\f\t\f\x04\r\t\r" +
		"\x04\x0E\t\x0E\x04\x0F\t\x0F\x04\x10\t\x10\x04\x11\t\x11\x04\x12\t\x12" +
		"\x04\x13\t\x13\x04\x14\t\x14\x04\x15\t\x15\x04\x16\t\x16\x04\x17\t\x17" +
		"\x04\x18\t\x18\x04\x19\t\x19\x04\x1A\t\x1A\x04\x1B\t\x1B\x04\x1C\t\x1C" +
		"\x04\x1D\t\x1D\x04\x1E\t\x1E\x04\x1F\t\x1F\x04 \t \x04!\t!\x04\"\t\"\x04" +
		"#\t#\x04$\t$\x04%\t%\x04&\t&\x03\x02\x03\x02\x03\x03\x03\x03\x03\x04\x03" +
		"\x04\x03\x04\x03\x05\x03\x05\x03\x06\x03\x06\x03\x07\x03\x07\x03\x07\x03" +
		"\x07\x03\b\x03\b\x03\b\x03\t\x03\t\x03\t\x03\t\x03\n\x03\n\x03\n\x03\v" +
		"\x03\v\x03\v\x07\vj\n\v\f\v\x0E\vm\v\v\x03\v\x03\v\x06\vq\n\v\r\v\x0E" +
		"\vr\x07\vu\n\v\f\v\x0E\vx\v\v\x03\v\x03\v\x03\v\x03\v\x07\v~\n\v\f\v\x0E" +
		"\v\x81\v\v\x03\v\x03\v\x06\v\x85\n\v\r\v\x0E\v\x86\x07\v\x89\n\v\f\v\x0E" +
		"\v\x8C\v\v\x03\v\x03\v\x03\v\x03\v\x07\v\x92\n\v\f\v\x0E\v\x95\v\v\x03" +
		"\v\x03\v\x06\v\x99\n\v\r\v\x0E\v\x9A\x07\v\x9D\n\v\f\v\x0E\v\xA0\v\v\x03" +
		"\v\x03\v\x03\v\x07\v\xA5\n\v\f\v\x0E\v\xA8\v\v\x05\v\xAA\n\v\x03\f\x03" +
		"\f\x03\f\x03\f\x03\r\x03\r\x03\x0E\x03\x0E\x03\x0F\x03\x0F\x03\x10\x03" +
		"\x10\x03\x11\x03\x11\x03\x12\x03\x12\x03\x13\x03\x13\x03\x14\x03\x14\x03" +
		"\x15\x03\x15\x03\x16\x03\x16\x03\x17\x03\x17\x03\x18\x03\x18\x03\x19\x03" +
		"\x19\x03\x1A\x03\x1A\x03\x1B\x03\x1B\x03\x1C\x03\x1C\x03\x1D\x03\x1D\x03" +
		"\x1E\x03\x1E\x03\x1F\x03\x1F\x03 \x03 \x03!\x03!\x03\"\x03\"\x03#\x03" +
		"#\x03$\x03$\x03%\x03%\x03&\x03&\x02\x02\x02\'\x03\x02\x03\x05\x02\x04" +
		"\x07\x02\x05\t\x02\x06\v\x02\x07\r\x02\b\x0F\x02\t\x11\x02\n\x13\x02\v" +
		"\x15\x02\f\x17\x02\r\x19\x02\x02\x1B\x02\x02\x1D\x02\x02\x1F\x02\x02!" +
		"\x02\x02#\x02\x02%\x02\x02\'\x02\x02)\x02\x02+\x02\x02-\x02\x02/\x02\x02" +
		"1\x02\x023\x02\x025\x02\x027\x02\x029\x02\x02;\x02\x02=\x02\x02?\x02\x02" +
		"A\x02\x02C\x02\x02E\x02\x02G\x02\x02I\x02\x02K\x02\x02\x03\x02 \x06\x02" +
		"2;C\\aac|\b\x02--/<??B\\aac|\x07\x02--/<B\\aac|\x05\x02\v\r\x0F\x0F\"" +
		"\"\x04\x02CCcc\x04\x02DDdd\x04\x02EEee\x04\x02FFff\x04\x02GGgg\x04\x02" +
		"HHhh\x04\x02IIii\x04\x02JJjj\x04\x02KKkk\x04\x02LLll\x04\x02MMmm\x04\x02" +
		"NNnn\x04\x02OOoo\x04\x02PPpp\x04\x02QQqq\x04\x02RRrr\x04\x02SSss\x04\x02" +
		"TTtt\x04\x02UUuu\x04\x02VVvv\x04\x02WWww\x04\x02XXxx\x04\x02YYyy\x04\x02" +
		"ZZzz\x04\x02[[{{\x04\x02\\\\||\x02\xD5\x02\x03\x03\x02\x02\x02\x02\x05" +
		"\x03\x02\x02\x02\x02\x07\x03\x02\x02\x02\x02\t\x03\x02\x02\x02\x02\v\x03" +
		"\x02\x02\x02\x02\r\x03\x02\x02\x02\x02\x0F\x03\x02\x02\x02\x02\x11\x03" +
		"\x02\x02\x02\x02\x13\x03\x02\x02\x02\x02\x15\x03\x02\x02\x02\x02\x17\x03" +
		"\x02\x02\x02\x03M\x03\x02\x02\x02\x05O\x03\x02\x02\x02\x07Q\x03\x02\x02" +
		"\x02\tT\x03\x02\x02\x02\vV\x03\x02\x02\x02\rX\x03\x02\x02\x02\x0F\\\x03" +
		"\x02\x02\x02\x11_\x03\x02\x02\x02\x13c\x03\x02\x02\x02\x15\xA9\x03\x02" +
		"\x02\x02\x17\xAB\x03\x02\x02\x02\x19\xAF\x03\x02\x02\x02\x1B\xB1\x03\x02" +
		"\x02\x02\x1D\xB3\x03\x02\x02\x02\x1F\xB5\x03\x02\x02\x02!\xB7\x03\x02" +
		"\x02\x02#\xB9\x03\x02\x02\x02%\xBB\x03\x02\x02\x02\'\xBD\x03\x02\x02\x02" +
		")\xBF\x03\x02\x02\x02+\xC1\x03\x02\x02\x02-\xC3\x03\x02\x02\x02/\xC5\x03" +
		"\x02\x02\x021\xC7\x03\x02\x02\x023\xC9\x03\x02\x02\x025\xCB\x03\x02\x02" +
		"\x027\xCD\x03\x02\x02\x029\xCF\x03\x02\x02\x02;\xD1\x03\x02\x02\x02=\xD3" +
		"\x03\x02\x02\x02?\xD5\x03\x02\x02\x02A\xD7\x03\x02\x02\x02C\xD9\x03\x02" +
		"\x02\x02E\xDB\x03\x02\x02\x02G\xDD\x03\x02\x02\x02I\xDF\x03\x02\x02\x02" +
		"K\xE1\x03\x02\x02\x02MN\x07.\x02\x02N\x04\x03\x02\x02\x02OP\x07?\x02\x02" +
		"P\x06\x03\x02\x02\x02QR\x07#\x02\x02RS\x07?\x02\x02S\b\x03\x02\x02\x02" +
		"TU\x07*\x02\x02U\n\x03\x02\x02\x02VW\x07+\x02\x02W\f\x03\x02\x02\x02X" +
		"Y\x05\x19\r\x02YZ\x053\x1A\x02Z[\x05\x1F\x10\x02[\x0E\x03\x02\x02\x02" +
		"\\]\x05)\x15\x02]^\x053\x1A\x02^\x10\x03\x02\x02\x02_`\x053\x1A\x02`a" +
		"\x055\x1B\x02ab\x05? \x02b\x12\x03\x02\x02\x02cd\x055\x1B\x02de\x05;\x1E" +
		"\x02e\x14\x03\x02\x02\x02fg\x07$\x02\x02gk\t\x02\x02\x02hj\t\x03\x02\x02" +
		"ih\x03\x02\x02\x02jm\x03\x02\x02\x02ki\x03\x02\x02\x02kl\x03\x02\x02\x02" +
		"lv\x03\x02\x02\x02mk\x03\x02\x02\x02np\x07\"\x02\x02oq\t\x03\x02\x02p" +
		"o\x03\x02\x02\x02qr\x03\x02\x02\x02rp\x03\x02\x02\x02rs\x03\x02\x02\x02" +
		"su\x03\x02\x02\x02tn\x03\x02\x02\x02ux\x03\x02\x02\x02vt\x03\x02\x02\x02" +
		"vw\x03\x02\x02\x02wy\x03\x02\x02\x02xv\x03\x02\x02\x02y\xAA\x07$\x02\x02" +
		"z{\x07b\x02\x02{\x7F\t\x02\x02\x02|~\t\x03\x02\x02}|\x03\x02\x02\x02~" +
		"\x81\x03\x02\x02\x02\x7F}\x03\x02\x02\x02\x7F\x80\x03\x02\x02\x02\x80" +
		"\x8A\x03\x02\x02\x02\x81\x7F\x03\x02\x02\x02\x82\x84\x07\"\x02\x02\x83" +
		"\x85\t\x03\x02\x02\x84\x83\x03\x02\x02\x02\x85\x86\x03\x02\x02\x02\x86" +
		"\x84\x03\x02\x02\x02\x86\x87\x03\x02\x02\x02\x87\x89\x03\x02\x02\x02\x88" +
		"\x82\x03\x02\x02\x02\x89\x8C\x03\x02\x02\x02\x8A\x88\x03\x02\x02\x02\x8A" +
		"\x8B\x03\x02\x02\x02\x8B\x8D\x03\x02\x02\x02\x8C\x8A\x03\x02\x02\x02\x8D" +
		"\xAA\x07b\x02\x02\x8E\x8F\x07)\x02\x02\x8F\x93\t\x02\x02\x02\x90\x92\t" +
		"\x03\x02\x02\x91\x90\x03\x02\x02\x02\x92\x95\x03\x02\x02\x02\x93\x91\x03" +
		"\x02\x02\x02\x93\x94\x03\x02\x02\x02\x94\x9E\x03\x02\x02\x02\x95\x93\x03" +
		"\x02\x02\x02\x96\x98\x07\"\x02\x02\x97\x99\t\x03\x02\x02\x98\x97\x03\x02" +
		"\x02\x02\x99\x9A\x03\x02\x02\x02\x9A\x98\x03\x02\x02\x02\x9A\x9B\x03\x02" +
		"\x02\x02\x9B\x9D\x03\x02\x02\x02\x9C\x96\x03\x02\x02\x02\x9D\xA0\x03\x02" +
		"\x02\x02\x9E\x9C\x03\x02\x02\x02\x9E\x9F\x03\x02\x02\x02\x9F\xA1\x03\x02" +
		"\x02\x02\xA0\x9E\x03\x02\x02\x02\xA1\xAA\x07)\x02\x02\xA2\xA6\t\x02\x02" +
		"\x02\xA3\xA5\t\x04\x02\x02\xA4\xA3\x03\x02\x02\x02\xA5\xA8\x03\x02\x02" +
		"\x02\xA6\xA4\x03\x02\x02\x02\xA6\xA7\x03\x02\x02\x02\xA7\xAA\x03\x02\x02" +
		"\x02\xA8\xA6\x03\x02\x02\x02\xA9f\x03\x02\x02\x02\xA9z\x03\x02\x02\x02" +
		"\xA9\x8E\x03\x02\x02\x02\xA9\xA2\x03\x02\x02\x02\xAA\x16\x03\x02\x02\x02" +
		"\xAB\xAC\t\x05\x02\x02\xAC\xAD\x03\x02\x02\x02\xAD\xAE\b\f\x02\x02\xAE" +
		"\x18\x03\x02\x02\x02\xAF\xB0\t\x06\x02\x02\xB0\x1A\x03\x02\x02\x02\xB1" +
		"\xB2\t\x07\x02\x02\xB2\x1C\x03\x02\x02\x02\xB3\xB4\t\b\x02\x02\xB4\x1E" +
		"\x03\x02\x02\x02\xB5\xB6\t\t\x02\x02\xB6 \x03\x02\x02\x02\xB7\xB8\t\n" +
		"\x02\x02\xB8\"\x03\x02\x02\x02\xB9\xBA\t\v\x02\x02\xBA$\x03\x02\x02\x02" +
		"\xBB\xBC\t\f\x02\x02\xBC&\x03\x02\x02\x02\xBD\xBE\t\r\x02\x02\xBE(\x03" +
		"\x02\x02\x02\xBF\xC0\t\x0E\x02\x02\xC0*\x03\x02\x02\x02\xC1\xC2\t\x0F" +
		"\x02\x02\xC2,\x03\x02\x02\x02\xC3\xC4\t\x10\x02\x02\xC4.\x03\x02\x02\x02" +
		"\xC5\xC6\t\x11\x02\x02\xC60\x03\x02\x02\x02\xC7\xC8\t\x12\x02\x02\xC8" +
		"2\x03\x02\x02\x02\xC9\xCA\t\x13\x02\x02\xCA4\x03\x02\x02\x02\xCB\xCC\t" +
		"\x14\x02\x02\xCC6\x03\x02\x02\x02\xCD\xCE\t\x15\x02\x02\xCE8\x03\x02\x02" +
		"\x02\xCF\xD0\t\x16\x02\x02\xD0:\x03\x02\x02\x02\xD1\xD2\t\x17\x02\x02" +
		"\xD2<\x03\x02\x02\x02\xD3\xD4\t\x18\x02\x02\xD4>\x03\x02\x02\x02\xD5\xD6" +
		"\t\x19\x02\x02\xD6@\x03\x02\x02\x02\xD7\xD8\t\x1A\x02\x02\xD8B\x03\x02" +
		"\x02\x02\xD9\xDA\t\x1B\x02\x02\xDAD\x03\x02\x02\x02\xDB\xDC\t\x1C\x02" +
		"\x02\xDCF\x03\x02\x02\x02\xDD\xDE\t\x1D\x02\x02\xDEH\x03\x02\x02\x02\xDF" +
		"\xE0\t\x1E\x02\x02\xE0J\x03\x02\x02\x02\xE1\xE2\t\x1F\x02\x02\xE2L\x03" +
		"\x02\x02\x02\x0E\x02krv\x7F\x86\x8A\x93\x9A\x9E\xA6\xA9\x03\b\x02\x02";
	public static __ATN: ATN;
	public static get _ATN(): ATN {
		if (!AzureLexer.__ATN) {
			AzureLexer.__ATN = new ATNDeserializer().deserialize(Utils.toCharArray(AzureLexer._serializedATN));
		}

		return AzureLexer.__ATN;
	}

}
