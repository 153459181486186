import { Component, EventEmitter, Inject } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Site, UpdateSiteRequest } from '@cohesity/api/kepler';
import { AjaxHandlerService } from '@cohesity/utils';
import { Controls, DataInput, NgxRootFormComponent, takeUntilDestroyed } from 'ngx-sub-form';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { DataSitesService } from '../../services';

/**
 * Interface for Edit Data Site Form
 */
interface EditSiteForm {
  /**
   * Name of the site.
   */
  name: string;

  /**
   * Country of the site.
   */
  country: string;

  /**
   * State/Region of the site.
   */
  state: string;

  /**
   * City of the site.
   */
  city: string;
}

@Component({
  selector: 'coh-edit-site-dialog',
  templateUrl: './edit-site-dialog.component.html',
  styleUrls: ['./edit-site-dialog.component.scss'],
})
export class EditSiteDialogComponent extends NgxRootFormComponent<Site, EditSiteForm> {
  /**
   * Form input value. The DataInput decorator ensures that the form control
   * will be updated whenever this value is changed.
   */
  @DataInput() dataInput: Required<Site>;

  /**
   * Data output emits whenever the form value is changed.
   * Required by ngx-sub-form.
   */
  dataOutput = new EventEmitter<Site>();

  /**
   * Loading status of api call.
   */
  loading$ = new BehaviorSubject<boolean>(false);

  constructor(
    @Inject(MAT_DIALOG_DATA) public site: Site,
    private dialogRef: MatDialogRef<EditSiteDialogComponent>,
    private ajaxHandler: AjaxHandlerService,
    private dataSiteService: DataSitesService
  ) {
    super();
    this.dataInput$.next(site as Required<Site>);
  }

  /**
   * NgxSubform get default form value.
   */
  getDefaultValues(): Partial<EditSiteForm> {
    return {
      name: this.site?.name,
      city: this.site?.locationDetails?.city,
      country: this.site?.locationDetails?.countryName,
      state: this.site?.locationDetails?.regionName,
    };
  }

  /**
   * Gets form controls.
   *
   * @returns   Form controls.
   */
  getFormControls(): Controls<EditSiteForm> {
    return {
      name: new UntypedFormControl(null, Validators.required),
      city: new UntypedFormControl(),
      country: new UntypedFormControl(),
      state: new UntypedFormControl(),
    };
  }

  /**
   * Transforms model data to form data.
   *
   * @param    site   site data to transform.
   * @returns  Edit site form data
   */
  protected transformToFormGroup(site: Site): EditSiteForm {
    return {
      name: site?.name ?? '',
      city: site?.locationDetails?.city ?? '',
      country: site?.locationDetails?.countryName ?? '',
      state: site?.locationDetails?.regionName ?? '',
    };
  }

  /**
   * Updates site info on clicking the save button and close the dialog.
   */
  saveSite() {
    this.loading$.next(true);

    this.dataSiteService
      .updateSite({
        id: this.site.id,
        body: this.getUpdateSiteRequestBody(),
      })
      .pipe(
        takeUntilDestroyed(this),
        finalize(() => this.loading$.next(false))
      )
      .subscribe(
        site => this.dialogRef.close(site),
        error => this.ajaxHandler.handler(error)
      );
  }

  /**
   * Returns the body params for invoking the update site API.
   *
   * @returns An object containing the request body.
   */
  private getUpdateSiteRequestBody(): UpdateSiteRequest {
    const request: UpdateSiteRequest = {
      name: this.formGroupValues.name,
      type: this.site.type,
      clusters: this.site.clusters,
    };

    if (this.site.type === 'OnPrem') {
      request.locationDetails = {
        city: this.formGroupValues.city,
        countryName: this.formGroupValues.country,
        regionName: this.formGroupValues.state,
      };
    }

    return request;
  }
}
