import { StateAccessContext, AppModuleConfig, StateAccessMap } from 'src/app/app-module.config';

const protectModify = (ctx: StateAccessContext) =>
  ctx.FEATURE_FLAGS.ngProtectionGroupGlobalModify &&
  ctx.FEATURE_FLAGS.ngProtectionGroupRemoteAdapterModify &&
  ctx.PROTECTION_MODIFY;

export const RemoteAdapterConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'protection-builder-remote-adapter.**',
      url: '/protection/protect/remote-adapter',
      loadChildren: () => import('./remote-adapter.module').then(m => m.RemoteAdapterModule),
    },
  ],
  getStateAccessMap(): StateAccessMap {
    return {
      'protection-builder-remote-adapter.create': protectModify,
      'protection-builder-remote-adapter.edit': protectModify,
    };
  },
};
