// MODULE: View Box Create/Edit
import { FailureDomainPropKeys } from 'src/app/shared/constants';

;(function(angular, undefined) {
  'use strict';

  var defaultViewBox = {
    name: '',
    clusterPartitionId: undefined,
    storagePolicy: {
      deduplicationEnabled: true,
      inlineDeduplicate: true,
      compressionPolicy: 'kCompressionLow',
      inlineCompress: true,

      // will be defaulted to kEncryptionStrong if
      // cluster level encryption is enabled
      encryptionPolicy: 'kEncryptionNone',
      erasureCodingInfo: {
        erasureCodingEnabled: true,

        // for 2:1 EC, inline is more performant so this is the default value.
        // For all other cases, inline is less performant. This value will be
        // defaulted accordingly on change of numDataStripes:numCodedStripes
        inlineErasureCoding: true,
        numCodedStripes: 1,
        numDataStripes: 2,
      },
      numFailuresTolerated: 1,
      numNodeFailuresTolerated: 1,
    },
    physicalQuota: {},
    defaultViewQuotaPolicy: {},

    // Default CloudTiering Threshold is 60 days. Note: Would reference
    // `TIME.secsPerDay` but this is outside scope of dependencies.
    cloudDownWaterfallThresholdSecs: 86400 * 60,
  };

  // if this flow is instantiated as a modal, this var will hold
  // the parent state's help id so we can restore it when the
  // modal is closed
  var parentHelpId;

  // three distinct controllers, that pass through to sharedControllerFn.
  angular.module('C.viewBoxes')
    .controller('viewBoxModifyController', viewBoxModifyControllerFn)
    .controller('viewBoxCreateModalController', viewBoxCreateModalControllerFn)
    .controller('viewBoxEditModalController', viewBoxEditModalControllerFn);

  /**
   * controller function for state based access to the create/edit policy flows.
   */
  function viewBoxModifyControllerFn($rootScope, $scope, $state, $q, $filter, _,
    FEATURE_FLAGS, evalAJAX, cMessage, ViewBoxService, ExternalTargetService,
    PartitionService, ClusterService, LdapService, NgActiveDirectoryService,
    NgNetgroupWhitelistService, NgKerberosService, VARS) {

    $scope.flowSettings = {
      inEditMode: false,
    };
    if ($state.params.viewBoxId) {
      $scope.flowSettings.inEditMode = true;
      $scope.editViewBoxId = $state.params.viewBoxId || undefined;
    }

    sharedControllerFn.apply(this, arguments);
  }

  /**
   * controller function for modal based (slideModalService) access to the
   * create policy flows. recieves $uibModalIstance in addition to other shared
   * injections.
   */
  function viewBoxCreateModalControllerFn($rootScope, $scope, $state, $q,
    $filter, _, FEATURE_FLAGS, evalAJAX, cMessage, ViewBoxService,
    ExternalTargetService, PartitionService, ClusterService,
    LdapService, NgActiveDirectoryService, NgNetgroupWhitelistService,
    NgKerberosService, VARS, $uibModalInstance, storagePolicy) {

    $scope.flowSettings = {
      inEditMode: false,
    };

    $scope.defaultStoragePolicy = storagePolicy;

    sharedControllerFn.apply(this, arguments);
  }

  /**
   * controller function for modal based (slideModalService) access to the edit
   * policy flows. recieves $uibModalIstance in addition to other shared
   * injections.
   */
  function viewBoxEditModalControllerFn($rootScope, $scope, $state, $q, $filter,
    _, FEATURE_FLAGS, evalAJAX, cMessage, ViewBoxService, ExternalTargetService,
    PartitionService, ClusterService, LdapService, NgActiveDirectoryService,
    NgNetgroupWhitelistService, NgKerberosService, VARS, $uibModalInstance,
    viewBoxId) {

    $scope.flowSettings = {
      inEditMode: true,
    };
    $scope.editViewBoxId = viewBoxId;

    sharedControllerFn.apply(this, arguments);
  }

  /**
   * shared controller function that provides common functionality for standard
   * state based controller access and modal based (slideModalService) access
   */
  function sharedControllerFn($rootScope, $scope, $state, $q, $filter, _,
    FEATURE_FLAGS, evalAJAX, cMessage, ViewBoxService, ExternalTargetService,
    PartitionService, ClusterService, LdapService, NgActiveDirectoryService,
    NgNetgroupWhitelistService, NgKerberosService,
    VARS, $uibModalInstance, viewBoxId, storagePolicy) {

    var authProviderValue = {};

    // Cached translation keys for the Auth Provider ui-select-choices groups.
    var authGroupLabels = {
      ad: 'activeDirectory',
      ldap: 'ldapProviders',
      linked: 'linkedProviders',
      nis: 'nisProviders',
      kerberos: 'kerberosProviderWithLdaps',
    };

    $scope.viewBoxMapping = {};
    $scope.viewBox = {};
    $scope.shared = {};

    $scope.flowSettings.inModal = typeof $uibModalInstance === 'object';
    $scope.$uibModalInstance = $uibModalInstance || undefined;

    // Seed the default value for auth provider ui-select-choices
    $scope.authProviders = [{
      type: 'none',
      value: {},
      displayName: 'nonePublicAccess',
    }];

    if ($scope.flowSettings.inModal) {
      parentHelpId = $state.current.help;
    }

    /**
     * defines the step for use with multi-step.
     * help keys are hashed based on policy.templateDetails.type
     * and then edit/new mode.
     *
     * @type {Array}
     */
    $scope.steps = [{
      templateUrl: 'app/platform/viewboxes/modify/settings/settings.html',
      controller: 'viewBoxModifySettingsController',
      titleKey: 'viewBoxModify.steps.viewBoxSettings',
      help: 'platform_viewboxes_new_settings',
      azureHelp: ClusterService.clusterInfo._isAzureInstall ?
        'platform_viewboxes_new_settings_azure' : undefined,
      awsHelp: undefined,
    }];

    // Exposed methods
    _.assign($scope, {
      authProviderGetterSetterFn: authProviderGetterSetterFn,
      compressionPolicyGetterSetter: compressionPolicyGetterSetter,
      endFlow: endFlow,
      groupProvidersFn: groupProvidersFn,
      updateHelpId: updateHelpId,
      updateViewBox: updateViewBox,
    });

    /**
     * convenience function that allows child states to wholesale replace
     * $scope.viewBox with API response obj
     *
     * @method     updateViewBox
     * @param      {Object}  newViewBox  updated viewBox object to replace
     *                                   existing object
     */
    function updateViewBox(newViewBox) {
      $scope.viewBox = newViewBox;
    }

    /**
     * getter/setter function for compressionPolicy which allows us to display
     * both kCompressionLow and kCompressionHigh as 'enabled' in the UI. This
     * allows kCompressionHigh to be set by the CLI and maintained when editing
     * the View Box in the UI. If user toggles Compression off and back on it
     * will result in a setting of kCompressionLow. Off is `kCompressionNone`
     *
     * @method     compressionPolicyGetterSetter
     * @param      {string}  newValue  new model value provided by ngModel
     * @return     {string}  display value for cToggleSwitch implementation
     */
    function compressionPolicyGetterSetter(newValue) {

      var cToggleDisplayValue;

      // if function called as a setter,
      // assigning the provided value
      if (newValue) {
        $scope.viewBox.storagePolicy.compressionPolicy = newValue;
      }

      switch (true) {
        case !$scope.viewBox.storagePolicy.hasOwnProperty('compressionPolicy'):
          // set a default value since property is missing
          $scope.viewBox.storagePolicy.compressionPolicy = 'kCompressionLow';
          cToggleDisplayValue = 'kCompressionLow';
          // turn inline off, as it serves no purposes with compression disabled
          $scope.viewBox.storagePolicy.inlineCompress = false;
          break;
        case $scope.viewBox.storagePolicy.compressionPolicy === 'kCompressionHigh':
          // cToggleSwitch implementation is set to 'on' for Low. We want to
          // pretend to be kCompressionLow so the toggle is enabled for both cases
          cToggleDisplayValue = 'kCompressionLow';
          break;
        default:
          if (newValue) {
            // turn inline on by default when compression is turned on
            $scope.viewBox.storagePolicy.inlineCompress = true;
          }
          cToggleDisplayValue = $scope.viewBox.storagePolicy.compressionPolicy;
      }

      return cToggleDisplayValue;
    }

    /**
     * cancel the flow, dismiss the modal
     *
     * @method     endFlow
     * @param      {String}  [reason='cancel']  describes reason for ending
     *                                          flow, 'cancel' or 'complete'
     */
    function endFlow(reason) {

      reason = reason || 'cancel';

      if ($scope.flowSettings.inModal) {
        switch (reason) {
          case 'cancel':
            $uibModalInstance.dismiss('user.cancel');
            break;
          case 'complete':
            $scope.$uibModalInstance.close($scope.viewBox);
            break;
        }
        $state.current.help = parentHelpId;
      } else {
        $state.go('cluster.viewboxes');
      }
    }

    /**
     * if edit mode, gets the View Box from the API otherwise uses the default
     * View Box settings.
     *
     * @return     {Object}  to resolve the request for View Box
     */
    function _getViewBox() {
      var failureDomainType = $scope.clusterInfo.faultToleranceLevel || 'kNode';
      var failureDomainCount =
        $scope.clusterInfo[FailureDomainPropKeys[failureDomainType]] || 0;

      var newViewBox;

      // use $q.defer so we can either return API response or
      // default policy object depending on the circumstances
      var deferred = $q.defer();

      if ($scope.flowSettings.inEditMode) {
        // edit mode, need to get View Box object from the API
        ViewBoxService.getViewBox($scope.editViewBoxId).then(
          deferred.resolve,
          deferred.reject
        );
      } else {
        // not in edit mode, this is a new policy and we'll build the
        // View Box object based on above defined default
        newViewBox = angular.copy(defaultViewBox);

        if ($scope.defaultStoragePolicy !== null) {
          newViewBox.storagePolicy = Object.assign(newViewBox.storagePolicy,
            $scope.defaultStoragePolicy);
        }

        // Set default failure tolerance based on global cluster setting. If
        // global value is 1, then set 1d:1n. If global value is 2, then set
        // 2d:2n, etc.
        newViewBox.storagePolicy.numFailuresTolerated =
          newViewBox.storagePolicy.numNodeFailuresTolerated =
          $scope.clusterInfo.metadataFaultToleranceFactor;

        // Default is already true in the `defaultViewBox`, but we should
        // disable this for any cluster with fewer than four nodes.
        newViewBox.storagePolicy.erasureCodingInfo.erasureCodingEnabled =
          failureDomainCount > 3;

        // If fewer than 3 Nodes, then remove Erasure Coding info altogether.
        if (failureDomainCount < 3) {
          newViewBox.storagePolicy.erasureCodingInfo = undefined;
        }

        deferred.resolve(newViewBox);
      }

      return deferred.promise;
    }

    /**
     * sets up $scope.viewBoxMapping to detect duplicate VB names inline
     *
     * @method     _setupViewBoxMapping
     *
     * @return     {Object}  $q promise resolving with viewBoxes data
     */
    function _setupViewBoxMapping() {
      return ViewBoxService.getViewBoxes({
        includeHidden: true,
      }).then(
        function getViewBoxesSuccess(viewBoxes) {
          viewBoxes.forEach(function mapVBs(viewBox) {
            $scope.viewBoxMapping[viewBox.name.toLowerCase()] = viewBox.id;
          });
          return viewBoxes;
        }
      );
    }

    /**
     * retrieves and sets up partitions
     *
     * @method     _getPartitions
     *
     * @return     {Object}  $q promise resolving with list of partitions
     */
    function _getPartitions() {
      return PartitionService.getPartitions().then(
        function getPartitionsSuccess(partitions) {

          // if there's only one partition, make it the default
          if (partitions.length === 1) {
            $scope.viewBox.clusterPartitionId = partitions[0].id;
          }

          $scope.partitions = partitions;

          return partitions;
        }
      );
    }

    /**
     * Fetch the cluster config info
     *
     * @method     _getClusterInfo
     *
     * @return     {Object}  Promise to resolve request for clusterInfo
     */
    function _getClusterInfo() {
      var deferred = $q.defer();

      ClusterService.getClusterInfo().then(
        function clusterInfoReceived() {
          // The service does not returned transformed clusterInfo. Must get it
          // from the updated cache.
          deferred.resolve(ClusterService.clusterInfo);
        },
        deferred.reject
      );

      return deferred.promise;
    }

    /**
     * update the context sensitive help when the step changes. note: updating
     * active step is handled automatically by the multi-step-container
     * directive
     *
     * @param      {Object}  activeStep  as provided by multi-step-container's
     *                                   $scope.$getActiveStep()
     */
    function updateHelpId(activeStep) {
      var mode = $scope.flowSettings.inEditMode ? 'edit' : 'new';
      var currentHelpId;

      $scope.steps.some(function checkStep(step) {
        if (step.controller === activeStep.controller) {
          currentHelpId = step.awsHelp || step.azureHelp || step.help;

          return true;
        }

        return false;
      });

      $state.current.help = currentHelpId;
    }

    /**
     * Gets the auth providers and assembles a list usable by ui-select.
     *
     * @return     {Object}  Compile list of auth providers for ui-select
     */
    function _getAuthProviders() {
      var promises = {
        ads: NgActiveDirectoryService.getActiveDirectories().toPromise(),
        ldaps: LdapService.getLdapProviders(),
        nis: NgNetgroupWhitelistService.getNisProviders().toPromise(),
        kerberos: FEATURE_FLAGS.kerberosEnabled ?
          NgKerberosService.GetKerberosProviders({}).toPromise() : $q.resolve(),
      };

      $q.all(promises).then(
        function getAuthProvidersSuccess(response) {
          var adDomainName = $scope.viewBox.adDomainName;
          var ldapProviderId = $scope.viewBox.ldapProviderId;
          var nisProvider = ($scope.viewBox.nisDomainNameVec || [])[0];
          var kerberosRealmName = $scope.viewBox.kerberosRealmName;

          // Create stubbed initial model object for auth provider ui-select.
          // This object will be populated and then passed into the getterSetter
          // as initial value.
          var initialObj = {
            value: {
              adDomainName: adDomainName,
              ldapProviderId: ldapProviderId,
              nisProvider: nisProvider,
              kerberosRealmName: kerberosRealmName,
            }
          };

          // Populate the initialObj with additional derived values.
          switch (true) {
            case !!adDomainName && !!ldapProviderId:
              initialObj.type = 'linked';
              initialObj.displayName = adDomainName + ' + ' +
                LdapService.getLdapProvider(ldapProviderId).name;
              break;
            case !!adDomainName && !!nisProvider:
              initialObj.type = 'linked';
              initialObj.displayName = adDomainName + ' + ' + nisProvider;
              break;
            case !!ldapProviderId && !kerberosRealmName:
              initialObj.type = 'ldap';
              initialObj.displayName =
                LdapService.getLdapProvider(ldapProviderId).name;
              break;
            case !!adDomainName:
              initialObj.type = 'ad';
              initialObj.displayName = adDomainName;
              break;
            case !!nisProvider:
              initialObj.type = 'nis';
              initialObj.displayName = nisProvider;
              break;
            case !!kerberosRealmName: {
              initialObj.type = 'kerberos';
              initialObj.displayName = kerberosRealmName + ' + ' +
                LdapService.getLdapProvider(ldapProviderId).name;
              break;
            }
            default:
              initialObj.type = 'none';
              initialObj.displayName = 'nonePublicAccess';
          }

          // Assemble the list of Auth Providers for the ui-select-choices.

          // Add each Active Directory to the list, either on its own or with
          // its linked provider.
          (response.ads.activeDirectories || []).forEach(function addAd(ad) {
            // Default temp obj `authProvider` assumes Active Directory not
            // linked with another provider.
            var authProvider = {
              type: 'ad',
              value: {
                adDomainName: ad.domainName,
              },
              displayName: ad.domainName,
            };

            // If AD is linked with another Provider, then update temp obj
            // `authProvider` with derived values.
            if (ad.ldapProviderId) {
              authProvider.type = 'linked';
              authProvider.value.ldapProviderId = ad.ldapProviderId;
              authProvider.displayName = ad.domainName + ' + ' +
                LdapService.getLdapProvider(ad.ldapProviderId).name;
            } else if (ad.nisProviderDomainName) {
              authProvider.type = 'linked';
              authProvider.value.nisProvider = ad.nisProviderDomainName;
              authProvider.displayName =
                ad.domainName + ' + ' + ad.nisProviderDomainName;
            }

            $scope.authProviders.push(authProvider);
          });

          // Add the remaining LDAP Providers which are not linked to an AD.
          (response.ldaps || []).forEach(function addLdap(ldap) {
            // Default temp obj `authProvider` assumes LDAP not linked with an
            // Active Directory.
            var authProvider = {
              type: 'ldap',
              value: {
                ldapProviderId: ldap.id,
              },
              displayName: ldap.name,
            };

            // Only add it to the list if the LDAP is NOT linked with an AD. If
            // it is linked, then it was already added by the AD iterator.
            if (!ldap.adDomainName) {
              $scope.authProviders.push(authProvider);
            }
          });

          // Add the remaining NIS Providers which are not linked to an AD.
          (response.nis.nisProviders ||[]).forEach(function addNis(nis) {
            // Default temp obj `authProvider` assumes NIS not linked with an
            // Active Directory.
            var authProvider = {
              type: 'nis',
              value: {
                nisProvider: nis.domain,
              },
              displayName: nis.domain,
            };

            // Unlike the LDAP object, the NIS object does not include info
            // about linked AD, so we have to iterate over the list of AD
            // objects to find which one (if any) has a linked NIS provider.
            var isNisLinkedToAd =
              $scope.authProviders.some(function addProvider(authProvider) {
                return isNisLinkedToAd =
                  authProvider.value.nisProvider === nis.domain;
              });

            // Only add it to the list if the NIS is NOT linked with an AD. If
            // it is linked, then it was already added by the AD iterator.
            if (!isNisLinkedToAd) {
              $scope.authProviders.push(authProvider);
            }
          });

          if (FEATURE_FLAGS.kerberosEnabled) {
            (response.kerberos.kerberosProviders || [])
              .forEach(function forEach(provider) {
                var authProvider = {
                  type: 'kerberos',
                  value: {
                    kerberosRealmName: provider.realmName,
                    ldapProviderId: provider.ldapProviderId,
                  },
                  displayName: provider.realmName + ' + ' +
                    LdapService.getLdapProvider(provider.ldapProviderId).name,
                };

                $scope.authProviders.push(authProvider);
              });
          }

          // Set initial value
          authProviderGetterSetterFn(initialObj);
        }
      ).finally(
        function getAuthProvidersFinally() {
          $scope.adsFetched = true;
        }
      );
    }

    /**
    * Groups Auth Providers by AD, LDAP, and linked.
    * Used by ui-select
    *
    * @method  groupProvidersFn
    * @param   {String}  provider  auth provider from ui-select
    * @return  {String|undefined}  The group label.
    */
    function groupProvidersFn(provider) {
      // Note: No label for the None group, which is returned as undefined.
      return authGroupLabels[provider.type];
    }

    /**
     * getter/setter function for Auth Provider which is two models presented as
     * one thing in the UI.
     *
     * Note: Because the ui-select model value is an object instead of
     * primitive, we maintain a persistent object reference `authProviderValue`
     * which is declared at top of the controller.
     *
     * @method     authProviderGetterSetterFn
     * @param      {Object}  newValue  new model value provided by ngModel
     * @return     {Object}  model value for ui-select implementation
     */
    function authProviderGetterSetterFn(newValue) {
      if (angular.isDefined(newValue)) {
        $scope.viewBox.adDomainName = newValue.value.adDomainName;
        $scope.viewBox.ldapProviderId = newValue.value.ldapProviderId;
        $scope.viewBox.nisDomainNameVec = [newValue.value.nisProvider];
        $scope.viewBox.kerberosRealmName = newValue.value.kerberosRealmName;
      }

      // Get the latest model values
      _.assign(authProviderValue, {
        value: {
          adDomainName: $scope.viewBox.adDomainName,
          ldapProviderId: $scope.viewBox.ldapProviderId,
          nisProvider: $scope.viewBox.nisDomainNameVec[0],
          kerberosRealmName: $scope.viewBox.kerberosRealmName,
        },
      });

      // Update the other properties in the ui-select choice object
      if (newValue) {
        _.assign(authProviderValue, {
          type: newValue.type,
          displayName: newValue.displayName,
        });
      }

      return authProviderValue;
    }

    /**
     * initialization/activation function.
     */
    function _activate() {
      _getClusterInfo().then(
        function getClusterInfoSuccess(clusterInfo) {
          $scope.clusterInfo = clusterInfo;
          // if cluster level encryption is enabled, set the defaultViewBox
          // encryption value to "on"
          if ($scope.clusterInfo.encryptionEnabled) {
            defaultViewBox.storagePolicy.encryptionPolicy = 'kEncryptionStrong';
          }

          // For Disaggregated Storage clusters, default to NO compression for
          // new Storage Domains.
          if (clusterInfo._isDisaggregatedStorage || FEATURE_FLAGS.remoteDisksModule) {
            defaultViewBox.storagePolicy.compressionPolicy = 'kCompressionNone';
          }

          $scope.shared.faultToleranceSettingsEnabled =
            // Fault Tolerance enabled if NOT Disaggregated Storage AND
            (!FEATURE_FLAGS.remoteDisksModule && !clusterInfo._isDisaggregatedStorage) &&

            // There is at least one supported EC config.
            !!clusterInfo.supportedConfig.supportedErasureCoding.length;

          _setupViewBoxMapping();

          _getViewBox().then(
            function getViewBoxSuccess(viewBox) {
              $scope.viewBox = viewBox;

              if (FEATURE_FLAGS.multiAd) {
                _getAuthProviders();
              }

              _getPartitions();

              $scope.shared.selectedTarget = {
                id: _.get(viewBox, 'storagePolicy.cloudSpillVaultId'),
              };
              $scope.shared.showExternalTargets =
                !!$scope.shared.selectedTarget.id;

            },
            function getViewboxFailFail(response) {
              // show message as provided from api response
              evalAJAX.errorMessage(response, {
                persist: true
              });

              // and abort flow
              $scope.endFlow();
            }
          ).finally(
            function getViewBoxFinally() {
              $scope.$broadcast('flowReady');
              $scope.flowReady = true;
            }
          );
        },
        evalAJAX.errorMessage
      );

      $scope.encryptionChange = function () {
        if ($scope.viewBox.storagePolicy
            && $scope.viewBox.storagePolicy.encryptionPolicy === 'kEncryptionStrong'
            && $scope.clusterInfo._isDisaggregatedStorage) {
              compressionPolicyGetterSetter('kCompressionLow');
            }
      }
    }

    _activate();
  }

}(angular));
