<cog-settings-list-item [invalid]="formGroupErrors"
  cogFormGroup
  [label]="'network' | translate">
  <cog-settings-list-item-value-display>
    <ng-container *ngIf="formGroupValues.interfaceGroup">
      {{'defaultColon' | translate}} {{formGroupValues.interfaceGroup?.ifaceGroupName ||
        formGroupValues.interfaceGroup?.interfaceName}}
    </ng-container>
    <ng-container *ngIf="!formGroupValues.interfaceGroup">
      {{'auto' | translate}}
    </ng-container>
  </cog-settings-list-item-value-display>
  <cog-settings-list-item-value-editor>
    <mat-slide-toggle [formControl]="formGroupControls.auto"
      [inheritDataId]="true"
      class="margin-bottom"
      cogDataId="network-selector-auto-select-toggle">
      {{'autoSelect' | translate}}
    </mat-slide-toggle>
    <coh-cluster-interface-selector
      *ngIf="!formGroupValues.auto"
      [control]="formGroupControls.interfaceGroup"
      [vlans$]="vlans$"
      [populateVlans]="false"
      [showNoneOption]="false"
      [required]="false">
    </coh-cluster-interface-selector>
  </cog-settings-list-item-value-editor>
</cog-settings-list-item>
