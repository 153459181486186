<div class="donut-wrapper" [class.donut-no-title]="!title">
  <span class="donut-hole-content">
    <ng-content></ng-content>
    <small *ngIf="units">{{units}}</small>
  </span>
  <highcharts-chart class="donut-chart"
    [Highcharts]="Highcharts"
    [options]="chartOptions"
    [ngClass]="colorSetClass">
  </highcharts-chart>
</div>
<div class="donut-title" *ngIf="title">{{title}}</div>
