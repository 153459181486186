import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RemoteCluster, StorageDomains } from '@cohesity/api/v2';
import { isObject } from 'lodash';
import { Observable } from 'rxjs';
import { Api } from '../api.constants';
import { convertToHttpParams } from '../api.utils';

/**
 * This local type is because RemoteCluster has `nodeAddresses` from v2 API but
 * `remoteIps` comes from RemoteClusterConnectForm.
 */
interface RemoteClusterParams extends RemoteCluster {
  remoteIps?: string[];
}

/**
 * Remote cluster service.
 */
@Injectable({
  providedIn: 'root',
})
export class RemoteClusterApi {
  constructor(private http: HttpClient) {}

  /**
   * Gets the list of available viewboxes for a given remote cluster.
   *
   * @param cluster The cluster details or the clusterID
   */
  getRemoteViewBoxes(cluster: RemoteClusterParams | number): Observable<StorageDomains> {
    let headers = new HttpHeaders();
    const params = convertToHttpParams({ includeTenants: true });
    let clusterId: number;
    if (isObject(cluster)) {
      // cluster id Zero indicates backend to go and fetch remote cluster view
      // boxes list by using credentials given in header.
      clusterId = 0;
      headers = headers.set('hostname', cluster.nodeAddresses ? cluster.nodeAddresses[0] : cluster.remoteIps[0])
        .set('username', cluster.username)
        .set('password', cluster.password);
    } else {
      clusterId = cluster;
    }
    return this.http.get<StorageDomains>(Api.public('remoteClusterApi', clusterId, encodeURIComponent('/v2/storage-domains')),
      { headers, params });
  }
}
