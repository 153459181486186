import { McmServiceType } from '@cohesity/api/v2';
import { appConfigMap, AppName, ClusterConfig, translateAppName } from '@cohesity/iris-core';
import { TranslateService } from '@ngx-translate/core';

import { ServiceNavProvider } from './service-nav-provider';

/**
 * App service types
 */
export type AppServiceType =
  'applianceManager' |
  'clusterManager' |
  'diaas' |
  'dgaas' |
  'dms' |
  'draas' |
  'dsaas' |
  'global' |
  'piaas' |
  'rpaas' |
  'smartFiles';

/**
 * This is used to configure an application/service (ie, rpaas, draas, etc...) to run within Iris.
 */
export abstract class AppServiceConfig {
  /**
   * The title to use for this service.
   */
  abstract readonly appName: AppName;

  /**
   * This is the name of the service type.
   */
  abstract readonly serviceType: AppServiceType;

  /**
   * The title to use for this service.
   */
  get serviceTitle() {
    return translateAppName(this.translate)(this.appName);
  }

  /**
   * Home/dashboard state to navigate to when the service is active.
   */
  abstract readonly homeState: string;

  /**
   * Whether the service supports global search, if this is false, the app will hide the global search page.
   */
  abstract readonly supportsGlobalSearch: boolean;

  /**
   * Optional nav provider, which can customize the app's navigation.
   */
  readonly navProvider?: ServiceNavProvider;

  /**
   * An icon to use for the scope selector.
   */
  get scopeIcon(): string {
    return appConfigMap[this.appName].icon;
  }

  /**
   * This property has origins in our original SPOG implementation which focused on being able to switch which cluster
   * the user was managing with the cluster scope selector. When new scopes were added, we decorated the cluster config
   * with special properties to special case our scope handling - for instance, and all clusters view, then a dms view,
   * a global view, etc... As we add more cases, the service name is more important than the actual cluster details,
   * but much of our internal logic is still based on the cluster config info. This property is included to ensure that
   * legacy logic can remain unchanged.
   */
  abstract readonly clusterConfigPartial: Partial<ClusterConfig>;

  /**
   * The tag to be used to provided the service context to the backend. This helps
   * to audit the actions taken from the UI.
   */
  readonly auditLogTag?: AuditServiceType;

  constructor(protected translate: TranslateService) {}
}

/**
 * This type defines the possible Audit Service Type (Tags) that are processed by the backend.
 */
export type AuditServiceType = null | McmServiceType['value'];
