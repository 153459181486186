import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Api } from '../api.constants';

/**
 * Protection Job Audit Trail data.
 */
export interface AuditTrail {
  /**
   * Timestamp in usec.
   */
  timestampUsec?: number;

  /**
   * Audit trail user.
   */
  username?: string;

  /**
   * Audit trail changes description.
   */
  changesDescription?: string;
}

/**
 * Private path for Backup Job AUdit.
 */
export const backupJobAuditPath = 'backupjobaudit';

/**
 * @description
 * Protection Groups API service for private APIs.
 */
@Injectable({
  providedIn: 'root',
})
export class ProtectionGroupsApiService {
  constructor(private http: HttpClient, private translate: TranslateService) {}

  /**
   * Returns observable of Job Audit objects.
   *
   * @param    jobId  Job ID.
   * @returns  Protection Job Audit Trail array.
   */
  getJobAudit(jobId: number): Observable<AuditTrail[]> {
    return this.http.get<AuditTrail[]>(Api.private(backupJobAuditPath, jobId)).pipe(
      map((jobs: any[]) => {
        return jobs.map(job => {
          const {
            after: {
              backupJob: {
                jobCreationTimeUsecs = 0,
                lastModificationTimeUsecs = 0,
                lastUpdatedUsername = '',
                pausedNote = ''
              },
            },
            changes = [],
          } = job;

          // If there are no changes, determine the appropriate message
          if (changes.length === 0) {
            if (job.before?.backupJob) {
              changes.push('protectionJobsDetailsJobAudit.noChanges');
            } else {
              changes.push('protectionJobsDetailsJobAudit.firstEntry');
            }
          }

          // Check if both pause-related changes exist
          const hasBothPauseChanges = (
            (changes.includes('KBackupJobFutureRunsPaused') ||
              changes.includes('KBackupJobFutureRunsResumed'))
            && changes.includes('KBackupJobPausedNote')
          );

          // Filter out 'KBackupJobPausedNote' if both pause-related changes exist
          let filteredChanges = changes;

          if(hasBothPauseChanges){
            filteredChanges = changes.filter(change => change !== 'KBackupJobPausedNote');
          }

          // Map changes to their translated descriptions
          const changesDescription = filteredChanges.map(change => {
            return (change === 'KBackupJobFutureRunsPaused' || change === 'KBackupJobPausedNote')
              ? this.translate
                  .instant("protectionGroups.pauseRunsJobDescriptionInAuditTrail", {
                    pausedNote: pausedNote ? `"${pausedNote}"` : ''
                  })
              : this.translate.instant(change)
          }).join(', ');

          return {
            timestampUsec: jobCreationTimeUsecs === lastModificationTimeUsecs
              ? jobCreationTimeUsecs
              : lastModificationTimeUsecs,
            username: lastUpdatedUsername,
            changesDescription,
          };
        });
      })
    );
  }
}
