/**
 * Class for building api urls. The methods are all static, and it is
 * compatible with the old angular js API const definitions.
 */
export class Api {
  /**
   * Root path for api calls.
   */
  static readonly root = '/irisservices/api/v1';

  static readonly elixirRoot = `${Api.root}/public/elixirServices`;

  static readonly agentDeploymentRoot = Api.root + '/agentDeployments';

  static readonly v2Root = '/v2';

  static readonly mcmRoot = '/mcm';

  static readonly sizerRoot = `${Api.mcmRoot}/simulator`;

  static readonly dataExpungeRoot = `expunge`;

  static readonly argusRoot = `${Api.mcmRoot}/argus/api/v1/public`;

  static readonly inventoryMgrRoot = `${Api.mcmRoot}/ims/api/v1/public`;

  static readonly quorumRoot = `${Api.v2Root}${Api.mcmRoot}/quorum`;

  static readonly gaiaRoot = `${Api.v2Root}${Api.mcmRoot}/gaia`;

  static readonly guardianRoot = `${Api.v2Root}${Api.mcmRoot}/guardian`;

  static readonly liveSizerRoot = `/live-sizer`;

  /**
   * Root paths for public, private, and mcm urls.
   */
  static readonly apiRootConstants = {
    public: `${Api.root}/public`,
    private: Api.root,
    mcm: Api.mcmRoot,
    mcmPublic: `${Api.root}/public/mcm`,
  };

  /**
   * Root paths for V2 public, private, and mcm urls.
   */
  static readonly ApiV2RootConstants = {
    public: Api.v2Root,
    private: Api.v2Root,
    mcm: Api.mcmRoot,
  };

  /**
   * Generates a public url.
   *
   * @param   args   An array of arguments to generate the url.
   */
  static public(...args: (string | number)[]) {
    args.unshift(Api.apiRootConstants.public);
    return args.join('/');
  }

  /**
   * Generates a V2 public url.
   *
   * @param   args   An array of arguments to generate the url.
   */
  public static publicV2(...args: (string | number)[]) {
    return [Api.ApiV2RootConstants.public, ...args].join('/');
  }

  /**
   * Generates a V2 private url.
   *
   * @param   args   An array of arguments to generate the url.
   */
  public static privateV2(...args: (string | number)[]) {
    return [Api.ApiV2RootConstants.private, ...args].join('/');
  }

  /**
   * Generates a private url.
   *
   * @param   args   An array of arguments to generate the url.
   */
  static private(...args: (string | number)[]) {
    args.unshift(Api.apiRootConstants.private);
    return args.join('/');
  }

  /**
   * Generates an mcm url.
   *
   * @param   args   An array of arguments to generate the url.
   */
  static mcm(...args: (string | number)[]) {
    args.unshift(Api.apiRootConstants.mcm);
    return args.join('/');
  }

  /**
   * Generates a public mcm url.
   *
   * @param   args   An array of arguments to generate the url.
   */
  static mcmPublic(...args: (string | number)[]) {
    args.unshift(Api.apiRootConstants.mcmPublic);
    return args.join('/');
  }
}
