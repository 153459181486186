<button mat-button
  *ngIf="!isFab && ('RESTORE_MODIFY' | userPrivileges)"
  [ngClass]="buttonClass"
  id="show-recover-sources"
  cogDataId="recover-button"
  color="primary"
  [matMenuTriggerFor]="menu">
  {{ 'recover' | translate }}
  <cog-icon shape="expand_more!outline"></cog-icon>
</button>
<button mat-mini-fab
  *ngIf="isFab && ('RESTORE_MODIFY' | userPrivileges)"
  cogDataId="recover-button"
  [matMenuTriggerFor]="menu">
  <cog-icon shape="add"></cog-icon>
</button>
<mat-menu #menu="matMenu" class="{{ isFab ? 'cog-primary' : '' }}">
  <ng-container *ngFor="let source of sources">
    <!-- Environments supporting no sub menu. -->
    <button mat-menu-item
      id="{{source.id}}"
      uiSref="{{source.state}}"
      [cogDataId]="source.dataId"
      [uiParams]="source.stateParams"
      *ngIf="!source.subStates">
      <span [innerHTML]="source.translateKey | translate"></span>
    </button>

    <!-- Environment supporting sub menu states. -->
    <button mat-menu-item
      id="{{source.id}}"
      [cogDataId]="source.dataId"
      *ngIf="source.subStates?.length"
      [matMenuTriggerFor]="secondLevel">
      <span [innerHTML]="source.translateKey | translate"></span>
    </button>

    <!-- Sub Menu states-->
    <mat-menu #secondLevel="matMenu" xPosition="before"
      class="{{ isFab ? 'cog-primary' : '' }}">
      <ng-container *ngFor="let state of source.subStates">
        <button mat-menu-item
          id="{{state.id}}"
          uiSref="{{state.state}}"
          [cogDataId]="state.dataId"
          [uiParams]="state.stateParams">
          <span [innerHTML]="state.translateKey | translate"></span>
        </button>
        </ng-container>
    </mat-menu>
  </ng-container>

</mat-menu>
