/**
 * Specifies the various type of snapshot targets.
 * This corresponds to the enum SnapshotTarget_Type within main/magneto/base/policy.proto.
 */
export enum SnapshotTargetType {
  /**
   * The snapshot should be kept locally.
   */
  kLocal = 1,

  /**
   * The snapshot should be replicated to a remote cluster.
   */
  kRemote = 2,

  /**
   * The snapshot should be archived to tape or cloud.
   * Refer 'ArchivalTarget' within archival.constants.ts for details.
   */
  kArchival = 3,

  /**
   * The snapshot should be converted and deployed to cloud.
   */
  kCloudDeploy = 4,

  /**
   * The snapshot should be replicated across cloud sources.
   */
  kCloudReplication = 5,
}

/**
 * Ownership Context Types.
 */
export type ArchivalOwnershipContext = null | 'Local' | 'FortKnox';
