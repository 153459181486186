<mat-select
  cogDataId="dropdown"
  [inheritDataId]="true"
  [formControl]="selectControl"
  [panelClass]="isMultiline ? 'cog-mat-select-panel-lg' : ''"
  [compareWith]="compareWith"
  [multiple]="allowMultiple">
  <mat-select-trigger>
    <ng-container
      *ngIf="!allowMultiple && selectedValue$ | async as selectedValue"
      [ngTemplateOutlet]="currentTriggerTemplate"
      [ngTemplateOutletContext]="{ $implicit: selectedValue }">
    </ng-container>

    <ng-container *ngIf="allowMultiple && selectedValue$ | async as selectedValue">
      <mat-chip-list [inheritDataId]="true" cogDataId="chip-list">
        <mat-chip
          *ngFor="let item of selectedValue; index as index"
          (removed)="removeAt(item)"
          [cogDataId]="optionDataIdFn(item)"
          [inheritDataId]="true"
          [removable]="true"
          [selectable]="true">
          <span class="chip-label">
            <ng-container [ngTemplateOutlet]="currentTriggerTemplate" [ngTemplateOutletContext]="{ $implicit: item }">
            </ng-container>
          </span>

          <!-- Mat Icon aligns better inside of mat-chip then cog-icon does -->
          <mat-icon matChipRemove>{{ removeIcon }}</mat-icon>
        </mat-chip>
      </mat-chip-list>
    </ng-container>
  </mat-select-trigger>

  <mat-option cogDataId="option-search" [inheritDataId]="true">
    <ngx-mat-select-search
      [formControl]="searchControl"
      [placeholderLabel]="searchPlaceholderLabel"
      [noEntriesFoundLabel]="searchNoEntriesFoundLabel">
    </ngx-mat-select-search>
  </mat-option>

  <mat-option
    *ngFor="let item of filteredItems$ | async"
    [value]="item"
    [cogDataId]="optionDataIdFn(item)"
    [inheritDataId]="true">
    <ng-container
      *ngTemplateOutlet="
        searchableSelectItemTemplate?.templateRef ?? defaultItemTemplate;
        context: { $implicit: item }
      ">
    </ng-container>
  </mat-option>

  <button
    *ngIf="addButtonLabel"
    mat-button
    (click)="addItemButtonClick.emit()"
    [disabled]="disabled"
    color="primary"
    class="add-item-buton"
    cogDataId="add-item"
    [inheritDataId]="true">
    <!-- mat-icon aligns better inside multi-select than cog-icon -->
    <mat-icon class="margin-right">add_circle_outline</mat-icon>
    {{ addButtonLabel }}
  </button>
</mat-select>

<ng-template #currentTriggerTemplate let-item>
  <ng-container
    [ngTemplateOutlet]="
      selectTriggerTemplate?.templateRef ?? searchableSelectItemTemplate?.templateRef ?? defaultItemTemplate
    "
    [ngTemplateOutletContext]="{ $implicit: item }">
  </ng-container>
</ng-template>

<ng-template #defaultItemTemplate let-item>{{ item }}</ng-template>
