// Module & Component Controller: cInstructions

;(function(angular, undefined) {

  var configOptions = {
    bindings: {
      /**
       * The array of instructions that are shown as steps in this component.
       *
       * The instruction can be of the following type -
       * [...,
       * {
            // Optional title, if not present it will render STEP #.
            title: '?',

            // The icon to be rendered for the instruction.
            icon: 'upload',

            // The description content.
            content: 'bifrostSetup.donwloadVM',

            // The action object, the action button only gets rendered if the
            // action object has callback in it.
            action: {
              // The id of the action button.
              idKey: 'download-vm',

              // The text that is to be displayed on the action button.
              textKey: 'download',

              // The callback function where you can access the instruction that
              // is being accessed.
              callback: function callbackFn(instruction) {...}
            }
          },
          ...]
       */
      instructions: '=',
    },
    controller: 'cInstructionsCtrl',
    templateUrl: 'app/global/c-instructions/c-instructions.html',
  };

  angular.module('C')
    .component('cInstructions', configOptions)
    .controller('cInstructionsCtrl', angular.noop);

  /**
   * @ngdoc component
   * @name C:cInstructions
   * @function
   *
   * @description
   * Layout component that displays the given instructions in a step by step
   * way. The respective instruction can have actions attached to it which can
   * be accessed as a callback at the invoking location. Other than that, this
   * is a dumb component.
   *
   * @example
     <c-instructions instructions='$ctrl.instructions'></c-instructions>
   */
})(angular);
