import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardAuthRequest, MsalGuardConfiguration, MsalRedirectComponent, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { StateService } from '@uirouter/core';
import { Subject, of } from 'rxjs';
import { catchError, delay, filter, switchMap, takeUntil } from 'rxjs/operators';

import { popupWorkflowRedirectUri as redirectUri } from '../../environment';
import { AuthenticatorService } from '../../services/authenticator.service';

/**
 * OAuth2.0 supports the following Grant types:
 * - Authorization Code
 * - PKCE
 * - Client Credentials
 * - Device Code
 * - Refresh Token
 * - Implcit Flow
 * - Password Grant
 *
 * Refer https://datatracker.ietf.org/doc/html/rfc6749 for details.
 *
 *    Self Service Login utilizes the MSAL library to authenticate the users
 * through OpenID Connect(OIDC). OpenID Connect is a thin identity layer built
 * on top of the OAuth 2.0 protocol, which allows clients to verify the
 * identity of an end user based on the authentication performed by an
 * authorization server or identity provider (IdP), as well as to obtain basic
 * profile information about the end user in an inter-operable and REST-like
 * manner. OpenID Connect specifies a RESTful HTTP API, using JSON as a data
 * format.
 *
 * ------------------- AUTHORIZATION CODE FLOW & PKCE -------------------------
 *
 * The OAuth2.0 public clients using the basic Authorization Code flow are
 * susceptible to the authorization code interception attack. This is
 * illustrated through the below diagram:
 *
 *  +~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~+
 *  | End Device   (SPA/Native App)  |
 *  |                                |
 *  | +-------------+   +----------+ | (6) Access Token  +----------+
 *  | |Legitimate   |   | Malicious|<--------------------|          |
 *  | |OAuth 2.0 App|   | App      |-------------------->|          |
 *  | +-------------+   +----------+ | (5) Authorization |          |
 *  |        |    ^          ^       |        Grant      |          |
 *  |        |     \         |       |                   |          |
 *  |        |      \   (4)  |       |                   |          |
 *  |    (1) |       \  Authz|       |                   |          |
 *  |   Authz|        \ Code |       |                   |  Authz   |
 *  | Request|         \     |       |                   |  Server  |
 *  |        |          \    |       |                   |          |
 *  |        |           \   |       |                   |          |
 *  |        v            \  |       |                   |          |
 *  | +----------------------------+ |                   |          |
 *  | |                            | | (3) Authz Code    |          |
 *  | |     Operating System/      |<--------------------|          |
 *  | |         Browser            |-------------------->|          |
 *  | |                            | | (2) Authz Request |          |
 *  | +----------------------------+ |                   +----------+
 *  +~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~+
 *
 * In this attack, the attacker intercepts the authorization code
 * returned from the authorization endpoint within a communication path
 * not protected by Transport Layer Security (TLS), such as inter-
 * application communication within the client's operating system.
 * Once the attacker has gained access to the authorization code, it can
 * use it to obtain the access token.
 *
 * Due to above security flaw, it is always recommended to use Auth code
 * flow with PKCE(Proof Key for Code Exchange). PKCE Grant flow is illustrated
 * below:
 *
 *                                                +-------------------+
 *                                                |   Authz Server    |
 *      +--------+                                | +---------------+ |
 *      |        |--(A)- Authorization Request ---->|               | |
 *      |        |       + t(code_verifier), t_m  | | Authorization | |
 *      |        |                                | |    Endpoint   | |
 *      |        |<-(B)---- Authorization Code -----|               | |
 *      |        |                                | +---------------+ |
 *      | Client |                                |                   |
 *      |        |                                | +---------------+ |
 *      |        |--(C)-- Access Token Request ---->|               | |
 *      |        |          + code_verifier       | |    Token      | |
 *      |        |                                | |   Endpoint    | |
 *      |        |<-(D)------ Access Token ---------|               | |
 *      +--------+                                | +---------------+ |
 *                                                +-------------------+
 *
 *
 * A. The client creates and records a secret named the "code_verifier"
 *    and derives a transformed version "t(code_verifier)" (referred to
 *    as the "code_challenge"), which is sent in the OAuth 2.0
 *    Authorization Request along with the transformation method "t_m".
 *    The client is recommended to use SHA256 tranformation to generate
 *    code_challenge from the code_verifier instead of sending code_verifier
 *    as plain text.
 *    code_challenge = BASE64URL-ENCODE(SHA256(ASCII(code_verifier)))
 *
 * B. The Authorization Endpoint responds as usual but records
 *    "t(code_verifier)" and the transformation method.
 *
 * C. The client then sends the authorization code in the Access Token
 *    Request as usual but includes the "code_verifier" secret generated
 *    at (A).
 *
 * D. The authorization server transforms "code_verifier" and compares
 *    it to "t(code_verifier)" from (B).  Access is denied if they are
 *    not equal.
 *
 * An attacker who intercepts the authorization code at (B) is unable to
 * redeem it for an access token, as they are not in possession of the
 * "code_verifier" secret. This also mitigates the usage of 'client_secret'
 * for Authentication.
 *
 * Refer https://datatracker.ietf.org/doc/html/rfc7636 for details.
 *
 * Specifies the component that extends the MsalRedirectComponent for handling
 * auth_code returned through the OAuth2.0 Auth Code flow using PKCE.
 *
 * Component workflow:
 * Step 1 - OnInit adds subscription to msalSubject$ & inProgress$. These are
 *          responsible for initiating login workflows based on MSAL events.
 * Step 2 - initiateMsftlogin(...) triggeres MSFT Auth workflow.
 * Step 3 - checkAndSetActiveAccount(...) sets the active account for the user
 *          logged in.
 * Step 4 - initateHeliosLogin(...) checks for the presence of OIDC ID token
 *          and initiates Helios Login if the ID token is present else goes
 *          to Step 2.
 * Step 5 - initateHeliosLogin(...) redirects to the Self-Service Dashboard
 *          upon successful login.
 */
@Component({
  selector: 'coh-m365-authenticator',
  templateUrl: './authenticator.component.html',
  styleUrls: ['./authenticator.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AuthenticatorComponent extends MsalRedirectComponent
  implements OnInit, OnDestroy {

  /**
   * Specifies the subject to store whether this component is destoryed.
   */
  private readonly destroying$ = new Subject<void>();

  constructor(
    private authenticatorService: AuthenticatorService,
    private msalAuthService: MsalService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalBroadcastService: MsalBroadcastService,
    private state: StateService,
  ) {
    super(msalAuthService);
  }

  async ngOnInit(): Promise<void> {
    super.ngOnInit();

    await this.patchMsftAuthRequest();

    // @azure/msal-angular uses the event system exposed by @azure/msal-browser
    // Subscribe to events managed by MsalBroadcastService to decide when to
    // trigger MSFT Authentication.
    this.msalBroadcastService.msalSubject$
      .pipe(
        takeUntil(this.destroying$),
        switchMap((eventMessage: EventMessage) => {
          // Auto initiate login if there are is no authentication in progress
          // & no user is already logged in through their MSFT account.
          if (eventMessage.eventType === EventType.HANDLE_REDIRECT_END) {
            if (this.getOidcIdToken()) {
              // Proceed to Helios Login if MSFT ID token is found.
              this.initateHeliosLogin();
            } else {
              // Proceed to acquire MSFT ID token.
              this.initiateMsftlogin();
            }
          }
          return of(null);
        }),
      ).subscribe();

    // The inProgress$ observable is also handled by the MsalBroadcastService,
    // and should be subscribed to when application needs to know the status of
    // interactions, particularly to check that interactions are completed.
    // MSFT recommends checking that the status of interactions to be
    // InteractionStatus.None before calling functions involving user accounts.
    this.msalBroadcastService.inProgress$.pipe(
      filter(status => status === InteractionStatus.None && !this.authenticatorService.isPopupWorkflowEnabled),
      takeUntil(this.destroying$),
    ).subscribe(() => {
      // Set active account.
      this.checkAndSetActiveAccount();

      // Initiate login to Helios.
      this.initateHeliosLogin();
    });
  }

  ngOnDestroy(): void {
    this.destroying$.next(undefined);
    this.destroying$.complete();
  }

  /**
   * Initiates the Auth Code flow using Redirect interaction type.
   * The configuration is stored within MSALGuardConfigFactory defined within
   * M365AuthenticatorModule.
   * Refer msalInstanceFactoryFn within SelfServicePortalModule for details.
   */
  private initiateMsftlogin(): void {
    of(this.msalGuardConfig.authRequest)
      .pipe(
        // Add deliberate delay to show redirection message.
        delay(2000),
        switchMap((auth) =>
          this.authenticatorService.isPopupWorkflowEnabled
            ? this.msalAuthService.loginPopup((auth ?
              { ...auth, redirectUri }
              : { redirectUri }) as RedirectRequest)
            : this.msalAuthService.loginRedirect((auth ? { ...auth } : null) as RedirectRequest)
        )
      )
      .subscribe((response: AuthenticationResult | undefined) => {
        if (this.authenticatorService.isPopupWorkflowEnabled) {
          this.msalAuthService.instance.setActiveAccount(response.account);

          // response.state is guaranteed to contain the tenantId, iff, it is
          // set within this.patchMsftAuthRequest().
          this.initateHeliosLogin(response.state);
        }
      });
  }

  /**
   * Sets the active account within the MSAL Authentication service iff
   * no active account is set but the user is logged in.
   *
   * @returns void
   */
  private checkAndSetActiveAccount(): void {
    const activeAccount = this.msalAuthService.instance.getActiveAccount();
    if (activeAccount) {
      return;
    }
    if (!activeAccount && this.msalAuthService.instance.getAllAccounts().length > 0) {
      const accounts = this.msalAuthService.instance.getAllAccounts();
      this.msalAuthService.instance.setActiveAccount(accounts[0]);
    }
  }

  /**
   * Initiates login to Helios for Self-Service workflow. This is also
   * responsible for fetching the privileges & creating session with UI.
   *
   * @param cohesityTenantId Specifies the tenantID for the Cohesity Account.
   * @returns void
   */
  private initateHeliosLogin(cohesityTenantId?: string): void {
    const idToken = this.getOidcIdToken();

    // Bail out early if the MSFT OIDC ID token is not found.
    if (!idToken) {
      this.initiateMsftlogin();
      return;
    }

    // Initiate Helios Login.
    this.authenticatorService.loginToHelios(idToken, cohesityTenantId)
      .pipe(
        takeUntil(this.destroying$),
        catchError(err => {
          console.log(err);
          // The API server does return a 302 with the redirection to the
          // SelfService dashboard route but the browser fails to handle the
          // same resulting in 404. The below code force redirects to the same.
          //
          // TODO(tauseef): Remove this once the API server bug is fixed.
          // This is likely an issue due to the domain name and may work in
          // test1/SBX/PROD envs.
          // The below state is also responsible for creating the Helios
          // Session in UI.
          this.state.go('self-service-portal.m365', {
            // Mark this user as authenticated since Iris & MSFT have already
            // done the same.
            isAuthenticated: true
          });
          return of(null);
        }))
      .subscribe();

  }

  /**
   * Returns the OpenID Connect ID token which was set through the MSFT Auth
   * workflow.
   *
   * @returns string representing the ID token.
   */
  private getOidcIdToken(): string | null {
    const idToken = this.msalAuthService.instance?.getActiveAccount()?.idToken;
    if (idToken) {
      return idToken;
    }

    if (this.msalAuthService.instance.getAllAccounts().length) {
      return this.msalAuthService.instance.getAllAccounts()[0]?.idToken;
    }

    return null;
  }

  /**
   * Patches the MSAL AuthN workflow with the state parameter containing the
   * tenantId, iff, the URL contains the same as a query parameter.
   *
   * @returns void
   */
  private patchMsftAuthRequest() {
    const params = new URLSearchParams(window.location.search);
    const tenantId = params.get('tenantId');
    const patchedAuthRequest: MsalGuardAuthRequest = {
      // As part of OAuth2.0 Auth Grant, 'state' can hold a value included
      // in the request and is also returned in the token response by the IdP.
      // This can be used in multiple ways:
      // - A randomly generated unique value is typically used for preventing
      // cross site request forgery attacks.
      // - The state is also used to encode information about the user's
      // state in the app before the authentication request occurred.
      //
      // In this case, Cohesity tenantId is being passed as a state parameter
      // within the OIDC AuthN request.
      state: tenantId,
      ...this.msalGuardConfig.authRequest
    };

    this.msalGuardConfig.authRequest = patchedAuthRequest;
  }

}
