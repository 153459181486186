import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Optional, Output, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { ProtectedObjectInfo } from '@cohesity/api/v2';
import {
  CanSelectRowFn,
  IsAllSelectedFn,
  NavItem,
  SnackBarService,
  TableComponent,
  ToggleSelectAllFn,
} from '@cohesity/helix';
import { flagEnabled, IrisContextService, getConfigByKey } from '@cohesity/iris-core';
import { AjaxHandlerService, AutoDestroyable } from '@cohesity/utils';
import { TranslateService } from '@ngx-translate/core';
import { StateParams } from '@uirouter/core';
import { ObservableInput } from 'ngx-observable-input';
import { combineLatest, Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { AjsUpgradeService, ClusterService, PassthroughOptionsService } from 'src/app/core/services';
import { Environment, StatusObject } from 'src/app/shared/constants';
import { isNoSqlHadoopSource } from 'src/app/util/env-utils';

import { ProtectionRun } from '../../models';
import { IProtectionRun } from '../../models/common.models';
import { ProtectionGroup } from '../../models/protection-group.models';
import {
  backupActionIcon,
  backupActionName,
  MenuItem,
  ProtectionRunService,
  SnapshotAction,
  SnapshotActionValue,
} from '../../services/protection-run.service';
import { ProtectionRunsService } from '../../services/protection-runs.service';

/**
 * Table component for Group Details page.
 *
 * @example
 *  <coh-runs-table
 *    [runs]="runs$ | async">
 *  </coh-runs-table>
 */
@Component({
  selector: 'coh-runs-table',
  templateUrl: './runs-table.component.html',
  styleUrls: ['./runs-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RunsTableComponent extends AutoDestroyable implements OnInit {
  /**
   * Protection Runs table source.
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @ObservableInput() @Input('runs') runs$: Observable<IProtectionRun[]>;

  /**
   * Protection Group used to display runs.
   * Either group or object should be used, not both at the same time.
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @ObservableInput() @Input('group') group$: Observable<ProtectionGroup>;

  /**
   * Protection Environment.
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @ObservableInput() @Input('environment') environment$: Observable<Environment>;

  /**
   * Protected Object used to display runs.
   * Either group or object should be used, not both at the same time.
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @ObservableInput() @Input('object') object$: Observable<ProtectedObjectInfo>;

  /**
   * Number of runs limited by API.
   */
  @Input() runsLimit = 0;

  /**
   * All runs that exist in magneto.
   */
  @Input() totalRuns = 0;

  /**
   * Dispatches event when "View All Runs" button is clicked.
   */
  @Output() readonly viewAllClicked = new EventEmitter<number>();

  /**
   * The number of rows that can be selected.
   */
  get selectableRowCount(): number {
    return this.table.renderedData ? this.table.renderedData.filter(row => this.canSelectRow(row)).length : 0;
  }

  /**
   * Stores the selected rows.
   */
  selection: SelectionModel<IProtectionRun>;

  /**
   * Gets a reference to the table in order to look up the data rows that are
   * currently rendered.
   */
  @ViewChild(TableComponent, { static: false }) table: TableComponent<IProtectionRun>;

  /**
   * Gets a reference to the mat-table in order to manually calling the sort whenever duration change.
   */
  @ViewChild('runTableSort') runTableSort = new MatSort();

  /**
   * Columns applicable for kView environment.
   */
  private readonly kViewColumns = [
    'startDate',
    'duration',
    'runType',
    'size',
    'copyTasks',
    'actions',
  ];

  /**
   * Columns if Protection Group is CSM Group or uses SnapDiff.
   */
  private readonly noDataReadWrittenColumns = [
    'startDate',
    'duration',
    'runType',
    'successOrError',
    'slaStatus',
    'copyTasks',
    'actions',
  ];

  /**
   * Columns for Protection Group without data written.
   */
  private readonly noDataWrittenColumns = [
    'startDate',
    'duration',
    'runType',
    'readBytes',
    'successOrError',
    'slaStatus',
    'copyTasks',
    'actions',
  ];

  /**
   * Columns for all other Protection Group environments.
   */
  private readonly defaultColumns = getConfigByKey(this.irisCtx.irisContext, 'protection.runsTableColumns', [
    'startDate',
    'duration',
    'runType',
    'readBytes',
    'writeBytes',
    'successOrError',
    'slaStatus',
    'copyTasks',
    'actions',
  ]);

  /**
   * Table columns.
   */
  columns$: Observable<string[]>;

  /**
   * Menu items for run item.
   */
  menuItems$: Observable<NavItem[]>;

  /**
   * Indicates if progress bars are shown for runs that are in progress.
   */
  readonly pollProgress: boolean = true;

  /**
   * Indicates if data written tab are shown for oracle job
   */
  readonly showOracleDataWrite: boolean = true;

  /**
   * Applicable backup action names.
   */
  readonly backupActionName: SnapshotActionValue = backupActionName;

  /**
   * Applicable backup action icons.
   */
  readonly backupActionIcon: SnapshotActionValue = backupActionIcon;

  /**
   * All available bulk backup actions.
   */
  readonly actions: SnapshotAction[] = [SnapshotAction.Delete];

  /**
   * Injected Ajs DateTimeService service.
   */
  private readonly dateTimeService: any;

  /**
   * Stores the value of the target cluster ID
   */
  targetClusterId$ = this.clusterService.clusterInfo$.pipe(map(cluster => cluster.id));

  /**
   * when delete snapshot in progress
   */
  isDeleting = false;

  constructor(
    @Optional() private pgdService: ProtectionRunsService,
    ajsUpgrade: AjsUpgradeService,
    private ajaxService: AjaxHandlerService,
    private clusterService: ClusterService,
    private irisCtx: IrisContextService,
    private passthroughOptionsService: PassthroughOptionsService,
    private runService: ProtectionRunService,
    private runsService: ProtectionRunsService,
    private snackBarService: SnackBarService,
    private translate: TranslateService
  ) {
    super();
    this.dateTimeService = ajsUpgrade.get('DateTimeService');
    this.pollProgress = flagEnabled(this.irisCtx.irisContext, 'jobLandingPulseEnabled');
    this.showOracleDataWrite = flagEnabled(this.irisCtx.irisContext, 'showOracleDataWrite');
  }

  ngOnInit() {
    this.columns$ = combineLatest([ this.environment$, this.group$, this.object$ ])
      .pipe(map(([ environment, group, object ]) => {
        let columns: string[];
        if (environment === Environment.kView) {
          // Insert tags column for externally triggered view backup runs table.
          if (group.isExternallyTriggered && !this.kViewColumns.includes('externallyTriggeredBackupTag')) {
            this.kViewColumns.splice(1, 0, 'externallyTriggeredBackupTag');
          }

          // remove some columns for kView environment
          columns = [...this.kViewColumns];
        } else if (environment === Environment.kOracle && !this.showOracleDataWrite) {
          // remove data written column for Oracle
          columns = [...this.noDataWrittenColumns];
        } else if (isNoSqlHadoopSource(Environment[environment])) {
          // remove data written column for NoSQL
          columns = [...this.noDataWrittenColumns];
        } else if (group?.isRemotelyManagedGroup || group?.isSnapDiffGroup) {
          // remove data read/write columns for CSM/SnapDiff
          columns = [...this.noDataReadWrittenColumns];
        } else if (!group?.isActive) {
          // remove data written column for Replication on Rx side
          columns = [...this.noDataWrittenColumns];
        } else {
          columns = [...this.defaultColumns];
        }

        if (object) {
          // Exclude these columns for Protected Object
          columns = this.excludeColumns(columns, ['successOrError', 'actions']);
        } else {
          this.selection = new SelectionModel<IProtectionRun>(true, []);

          // Getting the menuItems from the selected run.
          this.menuItems$ = this.selection.changed.pipe(map(
            () => {
              if ( this.selection.selected.length === 1 ) {
                return this.runService.getMenu(this.selection.selected[0] as ProtectionRun)
                  .filter(menu => !menu.disabled);
              }
              return [];
            }
        ));
        }

        return columns;
      }));
  }

  /**
   * Checks if the runType is storage snapshot.
   *
   * @param    run  Protection run row.
   * @returns  True if the current runType is storage snapshot.
   */
  isStorageArraySnapshot(run: IProtectionRun) {
    return run.runType === 'kStorageArraySnapshot';
  }

  /**
   * Returns modified columns based on specified columns to remove.
   *
   * @param  columns  Original set of columns to modify.
   * @param  excludeColumns  Columns to remove from specified `columns` param.
   * @returns  Modified original `columns` set.
   */
  private excludeColumns(columns: string[], excludeColumns: string[]): string[] {
    return [ ...columns.filter(column => !excludeColumns.includes(column)) ];
  }

  /**
   * Select all protection runs for bulk action.
   */
  trackById = (_, { runId }): number => runId;

  /**
   * Determines whether a row can be selected or not.
   * A row should only be selected if isLocalSnapshotsDeleted property is set to false and
   * if there is not any active run in progress.
   * This method is defined as a property so that the this value will be bound
   * properly when passed as an input to the table component.
   *
   * @return   whether a row can be selected or not.
   */
  canSelectRow: CanSelectRowFn<IProtectionRun> = (row: IProtectionRun) => (
    !row.isLocalSnapshotsDeleted
    && !row.isInProgress
    && !this.isStatInProgress(row.replicationStats)
    && !this.isStatInProgress(row.archivalStats)
    && !this.isStatInProgress(row.cloudSpinStats)
  );

  /**
   * Handles the select all button. Only selectable rows should be toggled on or off.
   * This method is defined as a property so that the this value will be bound
   * properly when passed as an input to the table component.
   */
  masterToggle: ToggleSelectAllFn = () => {
    this.isAllSelected()
      ? this.selection?.clear()
      : this.table.renderedData.forEach(row => this.canSelectRow(row) && this.selection?.select(row));
  };

  /**
   * Determines if all rows are selected. This ignores rows which are not selectable.
   * This method is defined as a property so that the this value will be bound
   * properly when passed as an input to the table component.
   *
   * @return   whether all selectable rows have been selected.
   */
  isAllSelected: IsAllSelectedFn = () =>
    this.selectableRowCount > 0 && this.selectableRowCount === this.selection?.selected.length;

  /**
   * Calling the actionHandler() function from runService.
   *
   * @param item the action from menu which will be made
   * @param run the run on which we will make the actions
   */
  handleItem(item: NavItem, run: ProtectionRun) {
    this.runService.actionHandler(item, run).pipe(
      this.untilDestroy()
    )
    .subscribe(
      () => {
        if (this.pgdService) {
          if (item.displayName === MenuItem.EditRun) {
            this.snackBarService.open(this.translate.instant('editRunModal.saved'));
          }
          this.pgdService.reloadTable();
          this.pgdService.reloadCalendar();
        }
      }
    );
  }

  /**
   * Return true if specified action is applicable to any of the selected runs.
   *
   * @action   action
   * @returns  Returns true if selected runs
   */
  hasAction(action: SnapshotAction): boolean {
    return this.selection?.selected.some(run => this.runService.hasSnapshotAction(run, action));
  }

  /**
   * Handles PulseComponent taskFinish event when in-progress run has finished polling.
   * Makes request to protection group API to update runs table.
   */
  runPulseFinished() {
    this.runsService.reloadTable();
  }

  /**
   * Handles snapshot action.
   *
   * @param  run     Protection run that snapshot action is part of.
   */
  isDataLockRun(run: ProtectionRun) {
    const { dataLockConstraints } = run;
    return dataLockConstraints?.mode === 'Compliance' &&
      (!dataLockConstraints.expiryTimeUsecs ||
      this.dateTimeService.getCurrentUsecs() < dataLockConstraints.expiryTimeUsecs);
  }

  /**
   * Handles table bulk action buttons.
   *
   * @param  action  Snapshot action that will be applied to selected runs.
   */
  async handleAction(action: SnapshotAction) {
    switch (action) {
      case SnapshotAction.Delete:
        await this.deleteSnapshots();
        break;
    }
  }

  /**
   * Delete selected run snapshots.
   */
  deleteSnapshots() {
    const deleteRuns = this.selection?.selected.filter(run =>
      this.runService.hasSnapshotAction(run, SnapshotAction.Delete)
    );
    if (deleteRuns.length) {
      this.isDeleting = true;
      this.runService.deleteAllSnapshots(deleteRuns)
        .pipe(
          finalize(() => this.isDeleting = false),
          this.untilDestroy()
        ).subscribe(res => {
          const { failedRuns } = res;
          if (failedRuns && failedRuns.length) {
            this.snackBarService.open(this.translate.instant('deleteAllSnapshots.message.failure', {
              messages: this.runService.getRunUpdateErrors(failedRuns).join(', ')
            }), 'error');
          } else {
            // API may not update immediately
            setTimeout(() => this.runsService.reloadTable(), 3500);
            this.snackBarService.open(this.translate.instant('deleteAllSnapshots.message.success'));
          }
        }, error => this.ajaxService.errorMessage(error));
    }
  }

  /**
   * Handles "View All Runs" button click in runs limit warning banner.
   *
   * @param totalRuns Total number of runs in the API.
   */
  viewMoreRuns(totalRuns: number) {
    this.viewAllClicked.emit(totalRuns);
  }

  /**
   * Hydrate state params with passthrough options.
   *
   * @param params The state params.
   * @returns The hydrated state params.
   */
  hydrateStateParams(params: StateParams): StateParams {
    return {
      ...params,
      ...(this.passthroughOptionsService.routerParams as any),
    };
  }

  /**
   * Checks if the successful and failed objects count is valid.
   *
   * @param run Protection Run.
   * @returns true/ false.
   */
  hasValidObjectsCount(run: ProtectionRun): boolean {
    if (run.isDbRun) {
      return typeof run.successfulAppObjectsCount === 'number' &&
      typeof run.failedAppObjectsCount === 'number';
    }
    return typeof run.successfulObjectsCount === 'number' &&
      typeof run.failedObjectsCount === 'number';
  }

  /**
   * checking if given row is selected or not.
   *
   * @action   action
   * @returns  Returns true if row is selected
   */
  canDisableRow(row: IProtectionRun): boolean {
    return this.isDeleting && this.selection?.selected.some(run => run.runId === row.runId);
  }

  /**
   * assign the duration to Protection run which are in progress.
   *
   * @param duration fetched using run id.
   * @param run Protection Run.
   */
  onRunDuration(duration: number, run: ProtectionRun) {
    run.duration = duration;
    this.table.dataSource.sort = this.runTableSort;
  }

  /**
   * checking if run is in progress
   *
   * @param stats can be archival, replication, cloudspin.
   * @return true if still in progress
   */
  isStatInProgress(stats: StatusObject[] = []) {
    return stats?.some(obj => obj.status === 'Running' || obj.status === 'Accepted');
  }

  /**
   * checking if run is in progress
   *
   * @param row run.
   * @return tooltip text
   */
  selectRowTooltip = (row: IProtectionRun) => {
    if (!this.canSelectRow(row)) {
      return !row.isLocalSnapshotsDeleted ? 'run is in-progress' : this.translate.instant('deleteAllSnapshots.message.success');
    }

    return null;
  };
}
