import { NgModule } from '@angular/core';

import { ShowMorePipe } from './show-more.pipe';

/**
 * App Module. This is the root angular module.
 */
@NgModule({
  declarations: [ShowMorePipe],
  exports: [ShowMorePipe],
  providers: [ShowMorePipe],
})
export class ShowMoreModule { }
