import { Injectable } from '@angular/core';
import { MatLegacySnackBarConfig as MatSnackBarConfig, MatLegacySnackBarRef as MatSnackBarRef } from '@angular/material/legacy-snack-bar';

/**
 * Ajax handler config service for customizing the default error message string.
 */
@Injectable({ providedIn: 'root' })
export class AjaxHandlerConfigService {
  /**
   * Return the default error message text.
   *
   * @returns The error message text.
   */
  getErrorConnectingToServer(): string {
    return 'errorConnectingToServer';
  }

  /**
   * Function to handle error in the app specific config. Not returning
   * a snackbar ref here will result in the error handled in the default way.
   *
   * @param     response   The Http* response object.
   * @param     [args]     Optional MatSnackbarConfig options
   * @returns   The Snackbar reference.
   */
  handleError(_response: any, _args: MatSnackBarConfig): MatSnackBarRef<any> {
    return undefined;
  }
}
