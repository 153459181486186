import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ObservableInput } from 'ngx-observable-input';
import { combineLatest, Observable, of } from 'rxjs';
import { finalize, map, shareReplay, switchMap } from 'rxjs/operators';
import { AjsUpgradeService } from 'src/app/core/services';
import { Environment, StatList, StatusObject } from 'src/app/shared';
import { RunProgressStatsPollerService } from 'src/app/core/services';

import { IProtectionRun, ProtectionRun } from '../../models';
import { ProtectionRunsService } from '../../services';

/**
 * @description
 * Run list item shown in calendar view on Protection Group Details page.
 *
 * @example
 *  <coh-runs-list-item [run]="protectionRun"></coh-runs-list-item>
 */
@Component({
  selector: 'coh-runs-list-item',
  templateUrl: './runs-list-item.component.html',
  styleUrls: ['./runs-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [RunProgressStatsPollerService],
})
export class RunsListItemComponent implements OnInit {
  /**
   * Protection run instance that will be used to populate this list item.
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @ObservableInput() @Input('run') run$: Observable<IProtectionRun>;

  /**
   * Show data read label.
   */
  @Input() hasDataRead = true;

  /**
   * Show data write label.
   */
  @Input() hasDataWritten = true;

  /**
   * Show number of objects protected and total object count.
   */
  @Input() hasObjectCount = true;

  /**
   * Show run actions menu.
   */
  @Input() hasActions = true;

  /**
   * Overall status icon based on all runs within `_run`.
   */
  runStatus$: Observable<StatusObject[]>;

  /**
   * State for this run item.
   */
  @Input() selected = false;


  /**
   * Stat items.
   */
  runStats$: Observable<StatList>;

  /**
   * Injected Ajs DateTimeService service.
   */
  private readonly dateTimeService: any;

  constructor(
    ajsUpgrade: AjsUpgradeService,
    private pollerService: RunProgressStatsPollerService,
    private runsService: ProtectionRunsService,
    private translate: TranslateService
  ) {
    this.dateTimeService = ajsUpgrade.get('DateTimeService');
  }

  /**
   * Initialize component and get latest loaded group.
   */
  ngOnInit() {
    const pollProgress$ = this.run$.pipe(
      switchMap(run => {
        if (run.isInProgress) {

          return this.pollerService.pollRunProgress(run.runId).pipe(
            finalize(() => this.runsService.reloadCalendar())
          );
        }
        return of(null);
      }),
    );

    this.runStats$ = combineLatest([this.run$, pollProgress$]).pipe(
      map(([run, runProgress]) => {
        const {
          archivalStats,
          backupStatus,
          cloudSpinStats,
          defaultState,
          defaultStateParams,
          environment,
          isSlaViolated,
          replicationStats,
          slaStatus,
        } = run;

        run.setBackupStatusName(this.translate.instant('localBackup'));

        const stats: StatList = [];

        // Cohesity Views don't really have an SLA as they are instantaneous snapshots.
        if (slaStatus && environment !== Environment.kView) {
          stats.push({
            value: this.translate.instant(isSlaViolated ? 'missed' : 'met'),
            label: 'slaStatus',
            status: [slaStatus],
            state: defaultState,
            stateParams: defaultStateParams,
          });
        }

        if (backupStatus) {
          stats.push({
            value: this.translate.instant(backupStatus.status),
            label: 'backup',
            status: [backupStatus],
            state: backupStatus.state,
            stateParams: backupStatus.stateParams,
            progress: runProgress?.localRun?.percentageCompleted,
          });
        }

        if (replicationStats) {
          const [repStatus] = replicationStats;

          stats.push({
            value: this.translate.instant(repStatus.status),
            label: 'replication',
            status: replicationStats,
            state: repStatus.state,
            stateParams: repStatus.stateParams,
          });
        }

        if (archivalStats) {
          const [arcStatus] = archivalStats;

          stats.push({
            value: this.translate.instant(arcStatus.status),
            label: 'archival',
            status: archivalStats,
            state: arcStatus.state,
            stateParams: arcStatus.stateParams,
          });
        }

        if (cloudSpinStats) {
          const [cloudSpinStatus] = cloudSpinStats;

          stats.push({
            value: this.translate.instant(cloudSpinStatus.status),
            label: 'cloudSpin',
            status: cloudSpinStats,
            state: cloudSpinStatus.state,
            stateParams: cloudSpinStatus.stateParams,
          });
        }

        return stats;
      }),
      shareReplay(1)
    );

    this.runStatus$ = this.runStats$.pipe(
      map(stats =>
        stats.map(stat => ({
          type: 'status',
          status: stat.status[0].status,
        }))
      ),
      shareReplay(1)
    );
  }

  /**
   * Handles snapshot action.
   *
   * @param  run     Protection run that snapshot action is part of.
   */
  isDataLockRun(run: ProtectionRun) {
    const { dataLockConstraints } = run;
    return dataLockConstraints?.mode === 'Compliance' &&
      (!dataLockConstraints.expiryTimeUsecs ||
      this.dateTimeService.getCurrentUsecs() < dataLockConstraints.expiryTimeUsecs);
  }
}
