import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { AjsUpgradeService } from 'src/app/core/services';
import { from } from 'rxjs';
import { StorageDomain, StorageDomainServiceApi } from '@cohesity/api/v2';
import { IrisContextService } from '@cohesity/iris-core';

/**
 * This defines the filter option interface used by c-search
 */
export interface FilterOption {
  /**
   * This should map to the query property to filter on.
   */
  property: string;

  /**
   * The display value for the filter. This should be translated before it is passed
   * to c-search.
   */
  display: string;

  /**
   * Transform the search value into a value suitable for passing to the backend as a
   * query param.
   *
   * @param   value   The value selected by the filter.
   * @return  A value that can be passed as a query param.
   */
  transformFn: (value: any) => any;

  /**
   * Locked filters cannot be removed by the user.
   */
  locked: boolean;

  /**
   * A value for the filter
   */
  value?: any;
}

/**
 * This defines the filter lookup interface used by c-search.
 */
export interface FilterLookups {
  [key: string]: any[];
}

/**
 * These are the built in filter types that the service knows about. Attempting to specify
 * anything else will cause compilation errors.
 */
export type AvailableFilters = 'viewBoxIds' | 'jobIds' | 'fromTimeUsecs' | 'toTimeUsecs';

/**
 * This is a helper service to simplify configuring and specifying common filters for recovery searches.
 * Pass a list of desired filters to initFilters(), and then pass filterLookups and filters to the
 * search field component.
 */
@Injectable({
  providedIn: 'root',
})
export class RecoverSearchFiltersService {
  /**
   * filterLookups contains a list of available options for a given filter type, such as a
   * list of storage domains or jobs.
   */
  filterLookups: FilterLookups;

  /**
   * filters is a list of the currently configured filters for the search.
   */
  filters: FilterOption[];

  /**
   * Legacy view box service.
   */
  private viewBoxService: any;

  /**
   * Legacy job service.
   */
  private jobService: any;

  /**
   * These are the filters currently provided by the service, more can be added here.
   */
  private availableFilters: {
    [key: string]: FilterOption;
  } = {
    viewBoxIds: {
      property: 'viewBoxIds',
      display: 'viewBox',
      transformFn: names => this.viewBoxTransform(names, this.filterLookups.viewBoxIds),
      locked: false,
    },
    jobIds: {
      property: 'jobIds',
      display: 'protectionJob',
      transformFn: names => this.jobTransform(names, this.filterLookups.jobIds),
      locked: false,
    },
    fromTimeUsecs: {
      property: 'fromTimeUsecs',
      display: 'startDate',
      transformFn: date => new Date(date).getTime() * 1000,
      locked: false,
    },
    toTimeUsecs: {
      property: 'toTimeUsecs',
      display: 'endDate',
      transformFn: date => new Date(date).getTime() * 1000,
      locked: false,
    },
  };

  constructor(
    ajsUpgrade: AjsUpgradeService,
    private irisCtx: IrisContextService,
    private storageDomainService: StorageDomainServiceApi,
    private translate: TranslateService
  ) {
    this.jobService = ajsUpgrade.get('JobService');
  }

  /**
   * Initializes the requested filters.
   *
   * @TODO Move away from using private environment. After the conversion constants have been updated
   * to ng, we can pass the public env and convert that to the private one locally.
   *
   * @param   requestedFilters   A list of filters that should be available for this recovery type.
   * @param   privateEnv         The environment is used to lookup potential jobs for this search type.
   */
  initFilters(requestedFilters: AvailableFilters[], privateEnv: number) {
    this.filterLookups = {};
    this.filters = [];

    requestedFilters.forEach(filterName => {
      if (typeof filterName === 'string') {
        // Make a copy of the filter and translate the display value.
        const filter = { ...this.availableFilters[filterName] };
        filter.display = this.translate.instant(filter.display);
        this.filters.push(filter);
        switch (filterName) {
          case 'viewBoxIds':
            this.storageDomainService.GetStorageDomains({
              includeTenants: this.irisCtx.irisContext.clusterInfo.tenantViewboxSharingEnabled,
            }).subscribe(
              (resp) => (this.filterLookups[filterName] = resp.storageDomains)
            );
            break;
          case 'jobIds':
            from(this.jobService.getJobs({ envTypes: [privateEnv] })).subscribe(
              (jobs: any[]) => (this.filterLookups[filterName] = jobs)
            );
            break;
        }
      }
    });
  }

  /**
   * Uses filterLookups to convert a list of view box names to a list of viewbox ids
   *
   * @param   names   The selected view boxes.
   * @return  The view box ids.
   */
  viewBoxTransform(names: string | string[], viewBoxes: StorageDomain[]) {
    if (!names || !viewBoxes) {
      return [];
    }
    names = [].concat(names);
    return viewBoxes.filter(viewBox => names.includes(viewBox.name)).map(viewBox => viewBox.id);
  }

  /**
   * Uses filterLookups to convert a list of job names to a list of job ids
   *
   * @param   names   The selected jobs.
   * @return  The job ids.
   */
  jobTransform(names: string | string[], jobs: any[]) {
    if (!names || !jobs) {
      return [];
    }
    names = [].concat(names);
    return jobs.filter(job => names.includes(job.name)).map(job => job.jobId);
  }
}
