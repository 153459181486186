import { Component, Injector, OnInit, } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { SnackBarService } from '@cohesity/helix';
import { getAppLandingPages, HeliosLandingPageApp, IrisContextService } from '@cohesity/iris-core';
import { AutoDestroyable } from '@cohesity/utils';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { CustomizationService, EmptyHeliosUserPreferences, UserCustomizations } from 'src/app/core/services';

/**
 * Interface for helios landing page form.
 */
interface HeliosDefaultLandingAppForm {
  /**
   * Key to store the default landing page.
   */
  defaultLandingPage: FormControl<HeliosLandingPageApp>;
}

@Component({
  selector: 'coh-helios-default-landing-app',
  templateUrl: './helios-default-landing-app.component.html',
  styleUrls: ['./helios-default-landing-app.component.scss'],
})
export class HeliosDefaultLandingAppComponent extends AutoDestroyable implements OnInit {
  /**
   * Subject for whether the form is loading.
   */
  loading = new BehaviorSubject<boolean>(false);

  /**
   * Observable for whether the form is loading.
   */
  loading$ = this.loading.asObservable();

  /**
   * List of all apps, including 5 pillar static landing pages.
   * Users can select one of these apps as the default landing page.
   */
  appList = getAppLandingPages(
    this.irisContextService.irisContext,
    this.translateService
  );

  /**
   * Form group to store the customization.
   */
  formGroup = new FormGroup<HeliosDefaultLandingAppForm>({
    defaultLandingPage: new FormControl(null, Validators.required),
  });

  /**
   * Reference to the customizations service.
   */
  customizationService: CustomizationService;

  constructor(
    private irisContextService: IrisContextService,
    private translateService: TranslateService,
    private snackBarService: SnackBarService,
    public dialogRef: MatDialogRef<HeliosDefaultLandingAppComponent>,
    injector: Injector,
  ) {
    super();

    this.customizationService = injector.get(CustomizationService);
  }

  ngOnInit() {
    // Initialize default to customization service value.
    const preferences =
      this.customizationService.userCustomizations?.heliosPreferences ||
      EmptyHeliosUserPreferences;

    const currentValue = this.appList.find(
      app => app.app === preferences.heliosDefaultLandingApp
    )?.app || this.appList[0].app;

    this.formGroup.controls.defaultLandingPage.setValue(currentValue);
  }

  /**
   * Function to store the landing page setting.
   */
  saveConfig() {
    this.loading.next(true);

    const {locale, heliosPreferences} = this.customizationService.userCustomizations;

    const config: UserCustomizations = {
      locale,
      heliosPreferences: {
        ...heliosPreferences,
        heliosDefaultLandingApp: this.formGroup.value.defaultLandingPage,
      }
    };

    this.customizationService.saveUserCustomizations(config).pipe(
      finalize(() => this.loading.next(false)),
      this.untilDestroy(),
    ).subscribe(value => {
      this.snackBarService.open(this.translateService.instant('changesHaveBeenSaved'));
      this.dialogRef.close(value);
    });
  }
}
