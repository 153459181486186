import { Vault } from '@cohesity/api/v1';

/**
 * Enum for Cloud target platform.
 */
export enum CloudTargetPlatform {
  kAmazon = 'amazon',
  kAzure = 'azure',
  kGoogle = 'google',
  kOracle = 'oracle',
}

/**
 * Enum for Cloud Tier.
 */
export enum CloudTier {
  // AWS
  kAmazonS3Standard = 'kAmazonS3Standard',
  kAmazonS3StandardIA = 'kAmazonS3StandardIA',
  kAmazonGlacier = 'kAmazonGlacier',
  kAmazonS3OneZoneIA = 'kAmazonS3OneZoneIA',
  kAmazonS3IntelligentTiering = 'kAmazonS3IntelligentTiering',
  kAmazonS3Glacier = 'kAmazonS3Glacier',
  kAmazonS3GlacierDeepArchive = 'kAmazonS3GlacierDeepArchive',

  // Azure
  kAzureTierHot = 'kAzureTierHot',
  kAzureTierCool = 'kAzureTierCool',
  kAzureTierArchive = 'kAzureTierArchive',

  // Google
  kGoogleStandard = 'kGoogleStandard',
  kGoogleNearline = 'kGoogleNearline',
  kGoogleColdline = 'kGoogleColdline',
  kGoogleRegional = 'kGoogleRegional',
  kGoogleMultiRegional = 'kGoogleMultiRegional',

  // Oracle
  kOracleTierStandard = 'kOracleTierStandard',
  kOracleTierArchive = 'kOracleTierArchive',
}

/**
 * Constants to map cloud platform to cloud tiers.
 * NOTE: DO NOT modify the order fo cloud tiers array.
 * Cloud tiers are sorted in the order from hotter tier to colder tier.
 * AWS also has kAmazonGlacier (Legacy) but it is not supported for tiering.
 */
export const CloudTierMap: {[index: string]: CloudTier[]} = {
  AWS: [
    CloudTier.kAmazonS3Standard,
    CloudTier.kAmazonS3StandardIA,
    CloudTier.kAmazonS3OneZoneIA,
    CloudTier.kAmazonS3Glacier,
    CloudTier.kAmazonS3GlacierDeepArchive,
  ],
  Azure: [
    CloudTier.kAzureTierHot,
    CloudTier.kAzureTierCool,
    CloudTier.kAzureTierArchive,
  ],

  Google: [
    CloudTier.kGoogleStandard,
    CloudTier.kGoogleRegional,
    CloudTier.kGoogleMultiRegional,
    CloudTier.kGoogleNearline,
    CloudTier.kGoogleColdline,
  ],

  Oracle: [
    CloudTier.kOracleTierStandard,
    CloudTier.kOracleTierArchive,
  ],
};

/**
 * Map cloud platform to tiering key from API payload.
 */
export const CloudTieringKeyMap = {
  AWS: 'awsTiering',
  Azure: 'azureTiering',
  Google: 'googleTiering',
  Oracle: 'oracleTiering',
};

/**
 * DMaaS cloud tier map.
 */
export const DMaaSCloudTierMap = {
  kAmazonS3Standard: 'kAmazonS3Standard',
  kAmazonS3Glacier: 'kAmazonS3Glacier',
};

/**
 * DMaaS Tiering default configuration to be set for Helios Data Movement.
 */
export const DMaaSTieringConfiguration: Vault = {
  externalTargetType: 'kAmazon',
  config: {
    amazon: {
      tierType: 'kAmazonS3Standard'
    },
  },
};

/**
 * Tiering default
 */
export const DMaaSTieringDefault = {
  schedule: {
    type: 'Weeks',
    value: 1,
  },
  sourceTier: CloudTier.kAmazonS3Standard,
  targetTier: CloudTier.kAmazonS3Glacier,
};
