import { Component } from '@angular/core';

import { PostureAdvisorService } from '../../services';


@Component({
  selector: 'dg-pa-clusters',
  templateUrl: './clusters.component.html',
  styleUrls: ['./clusters.component.scss'],
})
export class ClustersComponent {
  /**
   * Columns to be displayed in cluster table, column names for standard presentation.
   */
  columns: string[] = ['cluster', 'strength'];

  /**
   * Local variable to store observable of securityRuleDetails$
   */
  securityRuleDetails$ = this.postureAdvisorService.securityRuleDetails$;

  constructor(private postureAdvisorService: PostureAdvisorService) { }
}
