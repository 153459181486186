import { Injectable } from '@angular/core';
import { IrisContextService, isDmsScope } from '@cohesity/iris-core';
import { Transition } from '@uirouter/core';

import { GuardPriority, GuardResult, StateGuard } from '../state-guard';

/**
 * Acitivity Redirect Guard redirects incoming traffic for recoveries list to the activities page
 * for dms.
 */
@Injectable({
  providedIn: 'root',
})
export class ActivityRedirectGuard implements StateGuard {
  /**
   * This guard priority is set to App, which indicates it should run as part of the main application
   * after all bootstrapping and initializing is completed.
   */
  guardPriority = GuardPriority.App;

  /**
   * Match on recovery list state for now. May add protection groups later if needed.
   */
  matchCriteria = {
    to: 'recovery.list',
  };

  /**
   * Constructor.
   */
  constructor(readonly irisContext: IrisContextService) {}

  /**
   * Handles the redirection to the activites page.
   *
   * @param    transition   The UI Router Transition.
   * @returns  A redirect transition if we are in dmaas.
   */
  onStart(transition: Transition): GuardResult {
    const params = transition.params();
    if (isDmsScope(this.irisContext.irisContext)) {
      return transition.router.stateService.target('object-activity.listing', {
        ...params,
        taskType: 'Restore'
      });
    }
  }
}
