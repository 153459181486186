// Service: Source (Public API integration)

;(function(angular, undefined) {
  'use strict';

  angular.module('C.pubSourceService',
    ['C.pubSourceServiceFormatter', 'C.pubJobServiceFormatter',
    'C.pubSourceServiceUtil'])
    .service('PubSourceService', SourceServiceFn);

  function SourceServiceFn(
    _, $http, API, PubSourceServiceFormatter, PubJobServiceFormatter,
    SourceService, cModal, ENV_TYPE_CONVERSION, ENV_GROUPS, evalAJAX,
    SlideModalService, $q, cUtils, TenantService, RemoteClusterService,
    UserService, FEATURE_FLAGS, NgPassthroughOptionsService) {

    return {
      allRootNodes: [],
      bindAagDetailsToNode: bindAagDetailsToNode,
      deleteSourceModal: deleteSourceModal,
      downloadAwsCFT: downloadAwsCFT,
      getAgentsToUpgrade: getAgentsToUpgrade,
      getApplicationServers: getApplicationServers,
      getDabaseUniqueName: getDabaseUniqueName,
      getTenantSources: getTenantSources,
      getObject: getObject,
      getOneDriveId: getOneDriveId,
      getOwnRootNodes: getOwnRootNodes,
      getOwnSource: getOwnSource,
      getOwnSourcesInfo: getOwnSourcesInfo,
      getProtectedSources: getProtectedSources,
      getRegisterableHostObject: getRegisterableHostObject,
      getRestrictedUserSources: getRestrictedUserSources,
      getRootNodes: getRootNodes,
      getSelectedSourceIds: getSelectedSourceIds,
      getSource: getSource,
      getSources: getSources,
      getSourcesInfo: getSourcesInfo,
      getSourcesWithReconciledOwnerInfo: getSourcesWithReconciledOwnerInfo,
      getUniqueAagHosts: getUniqueAagHosts,
      isLogicalSizeAvailable: isLogicalSizeAvailable,
      isProtectedSizeAvailable: isProtectedSizeAvailable,
      openSourceGroupModal: openSourceGroupModal,
      processNodes: processNodes,
      processSourceStats: processSourceStats,
      refreshSource: refreshSource,
      runDiagnostics: runDiagnostics,
      registerSource: registerSource,
      retrySourceRegistration: retrySourceRegistration,
      addRootNodes: addRootNodes,
      showAagNodeSelectDialog: showAagNodeSelectDialog,
      upgradeSource: upgradeSource,
    };

    /**
     * Adds a root node to all root nodes collection.
     * @param {object} rootNodes
     */
    function addRootNodes(rootNodes) {
      if (rootNodes) {
        this.allRootNodes.push(rootNodes);
      }
    }

    /**
     * Gets the sources from root or entire hierarchy filtered according to
     * the user sids or the tenantIds.
     *
     * @method   _getFilteredSources
     * @param    {Object}   params            The filter for the sources. It
     *                                        can consist of array of sids or
     *                                        tenantIds
     * @return   {Object}   Promise resolved with the filtered sources else
     *                      rejected with error.
     */
    function _getFilteredSources(params, extraOpts = {}) {
      var reqParams = _.assign({
        environments: cUtils.onlyStrings(ENV_GROUPS.all),
        includeEntityPermissionInfo: true,
        includeVMFolders: true,
      }, params);
      var options = {
        preventRootNodeSuppression: true,
        ...extraOpts
      };

      return getSources(undefined, reqParams, options);
    }

    /**
     * Gets the restricted sources for the given user.
     *
     * @method   getRestrictedUserSources
     * @param    {String}   sid  The sid of the user.
     * @returns  {Object}   Promise resolved with restricted user sources else
     *                      rejected with error.
     */
    function getRestrictedUserSources(sid) {
      var params = {
        sids: [sid],
        allUnderHierarchy: true,
      };
      return _getFilteredSources(params);
    }

    /**
     * Gets the sources for the given tenant.
     *
     * @method   getTenantSources
     * @param    {String}   tenantId  the tenantId of the tenant.
     * @returns  {Object}   Promise resolved with tenant sources else rejected
     *                      with error.
     */
    function getTenantSources(tenantId, extraReqParams = {}, extraOpts = {}) {
      var params = {
        tenantIds: [tenantId],
        ...extraReqParams
      };
      return _getFilteredSources(params, extraOpts);
    }

    /**
     * Get the array of ids of the selected sources.
     *
     * @method   getSelectedSourceIds
     * @param    {Object[]}   sources   The sources object.
     * @returns  {Array}     Array of the selected sources
     */
    function getSelectedSourceIds(sources) {
      var entityIds = new Set();
      PubJobServiceFormatter.forEachNode(sources, function forEachNode(node) {
        if (node._selectedAncestor) {
          entityIds.add(node.protectionSource.id);
        }
      });

      return Array.from(entityIds).sort();
    }

    /**
     * Returns entity tree for application servers ex. kSQL, kOracle
     *
     * @method getApplicationServers
     * @param     {Object}    params    params to filter response
     * @return    {Object}              Promise to be resolved or rejected
     */
    function getApplicationServers(params) {
      return $http({
        method: 'GET',
        url: API.public('protectionSources/applicationServers'),
        params: params,
      }).then(function applicationServersSuccess(resp) {
        return resp.data || [];
      });
    }

    /**
     * Returns the protected VMs of a particular source if there are any
     *
     * @param    {Object}    params    {id: Number, environment: String}
     * @return   {Array}               array of protected VM objects and their
     *                                 details
     */
    function getProtectedSources(params) {
      return $http({
        method: 'GET',
        url: API.public('protectionSources/protectedObjects'),
        params: params,
        headers: { requestInitiatorType: 'UIUser' },
      }).then(function getProtectedVms(resp) {
        return resp.data || [];
      });
    }

    /**
     * Gets the details for a particular Source object. Used to get details for
     * a child (non-root) source node. For root nodes, use getRootNodes().
     *
     * @method   getObject
     * @param    {number}   id   The id of the desired source object
     * @return   {object}   Promise to resolve with source object details,
     *                      rejects with raw server response.
     */
    function getObject(id) {
      return $http({
        method: 'get',
        url: API.public('protectionSources/objects', id),
      }).then(function getSourceSuccess(resp) {
        return resp.data || [];
      });
    }

    /**
     * get the details of all sources or a particular source filtered by params.
     * this endpoint does not return the sourceTree, it returns top level
     * rootNode details.
     *
     * @method    getSourcesInfo
     * @param     {Object}    params        {ids: [], environmentType: String}
     * @param     {string}    environment   environment to use to process stats.
     * @param     {Object}    options       Options for the node decorator
     * @return    {Array}                   returns top level rootNode details
     */
    function getSourcesInfo(params, environment, options) {
      options = options || {};
      params = params || {};

      // make allUnderHierarchy and includeEntityPermissionInfo true when making
      // a request to get root node info for requested source IDs.
      // get details with entity permission info so that we can disable action
      // for which logged in user is authorized.
      if (params.ids && params.ids.length) {
        cUtils.selfOrDefault(params, 'allUnderHierarchy', true);
        cUtils.selfOrDefault(params, 'includeEntityPermissionInfo', true);
      }
      return $http({
        method: 'get',

        // get entity owner info to determine does logged in user is having
        // privilege to perform sources management operations like
        // un-registration, refresh, edit etc
        params: params,
        url: API.public('protectionSources/registrationInfo'),
      }).then(function getSourcesSuccess(resp) {
        resp.data.views = resp.data.views || [];
        resp.data.rootNodes = PubJobServiceFormatter.decorateRootNodes(
          resp.data.rootNodes || [], options
        );

        if (environment && resp.data.rootNodes.length) {
          resp.data.rootNodes[0]._stats =
            processSourceStats(resp.data.rootNodes[0], environment);
        }

        return resp.data || [];
      });
    }

    /**
     * get the details of all owned sources or a particular source filtered by
     * params. this endpoint does not return the sourceTree, it returns top
     * level rootNode details.
     *
     * @method    getOwnSourcesInfo
     * @param     {Object}    params        {ids: [], environmentType: String}
     * @param     {string}    environment   environment to use to process stats.
     * @param     {Object}    options       Options for the node decorator
     * @return    {Array}                   returns top level rootNode details
     */
    function getOwnSourcesInfo(params, environment, options) {
      params = TenantService.extendWithEntityOwnerParams(_.assign({}, params));
      return getSourcesInfo(params, environment, options);
    }

    /**
     * get the details of all sources with reconciled registered application VMs
     * ownership info when MT is enabled.
     *
     * NOTE: this is a tactical fix given for multi-tenancy expedient customer
     * and tracked by https://jira.cohesity.com/browse/ENG-81003 ticket.
     *
     * Issue: tenant is not able to un-register an vCenter's SQL VM registered
     * as an application because of missing entityPermissionInfo by magneto for
     * such entities and magneto will add inferred entity permission info under
     * https://jira.cohesity.com/browse/ENG-88087 ticket.
     *
     * Impact: We will me making 2 API call to load protection > sources page
     * when multi-tenancy is enabled in the cluster.
     *
     * @method    getSourcesWithReconciledOwnerInfo
     * @param     {Object}    params        {ids: [], environmentType: String}
     * @param     {string}    environment   environment to use to process stats.
     * @param     {Object}    options       Options for the node decorator
     * @return    {Array}                   returns top level rootNode details
     */
    function getSourcesWithReconciledOwnerInfo(params, environment, options) {
      var getSourcesInfoReq = getSourcesInfo(params, environment, options);

      return $q.all({
        getSourcesInfo: getSourcesInfoReq,

        // If MT is disabled then reusing the above source info to construct the
        // sourceIdMap currently else when MT is enabled then making another
        // call to get own source info.
        getOwnSourcesInfo: UserService.user.privs.ORGANIZATION_VIEW &&
          FEATURE_FLAGS.useSourcesWithReconciledOwnerInfo ?
          getOwnSourcesInfo(params, environment, options) : getSourcesInfoReq,
      }).then(function mixSources(response) {
        var sourceIdMap = {};

        // populating the sourceIdMap by application host owned by the logged in
        // user organization.
        (response.getOwnSourcesInfo.rootNodes || []).forEach(
          function eachNode(node) {
            if (node._isApplicationHost) {
              sourceIdMap[node.protectionSource.id] = true;
            }
          }
        );

        // Fixing the application host VMs ownership info.
        (response.getSourcesInfo.rootNodes || []).forEach(
          function eachNode(node) {
            if (node._isApplicationHost && FEATURE_FLAGS.useSourcesWithReconciledOwnerInfo) {
              node._owner.isSourceOwner = UserService.isTenantUser() ||
                !!sourceIdMap[node.protectionSource.id];
            }
          }
        );

        return response.getSourcesInfo;
      });
    }

    /**
     * Get list of sources owned by logged in user organization.
     *
     * @method   getOwnSource
     * @param    {number}   [id]                The id of the desired source.
     * @param    {object}   [params]            Parameters to pass on to API.
     * @param    {object}   [options]           The options inclusive of
     *                                          rootEnviroment and
     *                                          preventRootNodeSuppression.
     * @return   {object}   Promise to resolve with source details, rejects with
     *                      raw server response.
     */
    function getOwnSource(id, params, options) {
      params = TenantService.extendWithEntityOwnerParams(params);
      return getSource(id, params, options);
    }

    /**
     * Gets the details for a particular Source.
     *
     * The options object takes two keys.
     *  - rootEnvironment             The kValue string for the root enviroment.
     *  - preventRootNodeSuppression  True, if you want to suppress the rootNode
     *
     * @method   getSource
     * @param    {number}   [id]                The id of the desired source.
     * @param    {object}   [params]            Parameters to pass on to API.
     * @param    {object}   [options]           The options inclusive of
     *                                          rootEnviroment and
     *                                          preventRootNodeSuppression.
     * @return   {object}   Promise to resolve with source details, rejects with
     *                      raw server response.
     */
    function getSource(id, params, options) {
      var reqParams = _.assign({id: id}, params);
      var reqHeaders = {};

      options = options || {};

      if (options.clusterId) {
        reqHeaders = RemoteClusterService.getApiProxyHeader(options.clusterId);
      }

      // Set requestInitiatorType UIUser in the header if this request needs to
      // be prioritized for faster processing from Magneto.
      if (options.prioritize) {
        reqHeaders.requestInitiatorType = 'UIUser';
      }

      // use client side filtering for excludeTypes filter if below flag is true
      // currently it is used by data-protection > sources > all-objects page
      // for the tenants users to get inherited entityPermissionInfo for the VMs
      // under resource pool which is assigned to the tenant and if server side
      // filtering is used we won't be able to get those inherited properties.
      if (reqParams._useClientSideExcludeTypesFilter) {
        reqParams._clientSideExcludeTypes = reqParams.excludeTypes;
        delete reqParams.excludeTypes;
      }

      // make allUnderHierarchy and includeEntityPermissionInfo true when making
      // a request to get detailed info for requested source ID.
      // get details with entity permission info so that we can disable action
      // for which logged in user is authorized.
      if (id) {
        cUtils.selfOrDefault(reqParams, 'allUnderHierarchy', true);
        cUtils.selfOrDefault(reqParams, 'includeEntityPermissionInfo', true);
      }
      return $http({
        method: 'get',
        params: reqParams,
        headers: reqHeaders,
        url: API.public('protectionSources'),
      }).then(
        function getSourceSuccess(resp) {
          return options.skipTransform ?
            resp.data || [] :
            PubJobServiceFormatter.decorateSources(
              resp.data || [],
              {
                rootEnvironment: options.rootEnvironment,
                jobsByEntityIdsMap: options.jobsByEntityIdsMap,
                preventRootNodeSuppression: options.preventRootNodeSuppression,
                reqParams: reqParams,
                hostEnvironment: options.hostEnvironment,
              }
          );
        }
      );
    }

    /**
     * Gets the details for provided sources.
     *
     * The options object takes two keys.
     *  - rootEnvironment             The kValue string for the root enviroment.
     *  - preventRootNodeSuppression  True, if you want to suppress the rootNode
     *
     * @method   getSource
     * @param    {Array}    [ids]               The ids list of the desired
     *                                          source.
     * @param    {object}   [params]            Parameters to pass on to API.
     * @param    {object}   [options]           The options inclusive of
     *                                          rootEnviroment and
     *                                          preventRootNodeSuppression.
     * @return   {object}   Promise to resolve with source details, rejects with
     *                      raw server response.
     */
    function getSources(ids, params, options) {
      var promises = (ids || []).map(function eachId(id) {
        return getSource(id, params, options);
      });

      // get entire tree when root nodes IDs are not provided.
      if (promises.length === 0) {
        return getSource(undefined, params, options);
      }

      return $q.all(promises).then(function getSourcesSuccess(response) {
        return _.flatten(response);
      });
    }

    /**
     * Get list of root nodes owned by logged in user organization for the
     * Sources based on parameters.
     *
     * @method   getRootNodes
     * @param    {object}   params   The parameters to pass to the API
     * @return   {object}   Promise to resolve with root node sources, rejects
     *                      with raw server response.
     */
    function getOwnRootNodes(params) {
      params = TenantService.extendWithEntityOwnerParams(params);
      return getRootNodes(params);
    }

    /**
     * Gets root nodes for Sources based on parameters.
     *
     * @method   getRootNodes
     * @param    {object}   params   The parameters to pass to the API
     * @return   {object}   Promise to resolve with root node sources, rejects
     *                      with raw server response.
     */
    function getRootNodes(params) {
      var paramsCopy = angular.copy(params || {});

      // TODO(veetesh): remove this block when backend start sending physical
      // sources for kPhysicalFiles env and since consider kPhysical block, file
      // and standlone SQL sources as one kPhysical type do we need to keep this
      // conversion to UI or backend.
      if (paramsCopy.environments &&
        paramsCopy.environments.includes('kPhysicalFiles')) {
        paramsCopy.environments.push('kPhysical');
      }

      let headers;

      if (paramsCopy.headers) {
        headers = paramsCopy.headers;
        delete paramsCopy.headers;
      }

      cUtils.selfOrDefault(paramsCopy, 'allUnderHierarchy', true);
      cUtils.selfOrDefault(paramsCopy, 'includeExternalMetadata', true);
      return $http({
        method: 'get',
        params: paramsCopy,
        headers: headers,
        url: API.public('protectionSources/rootNodes'),
      }).then(
        function getSourceSuccess(resp) {
          // decorate root source node to expose node helper properties.
          return PubJobServiceFormatter.decorateSources(resp.data || []);
        }
      );
    }

    /**
     * retry application registration for a source
     *
     * @method   retrySourceRegistration
     * @param    {Object}   source   The source
     * @param    {String}   env      Environment of the source
     * @return   {Object}   Promise resolved on successful retry submission and
     *                      rejected with error on failure.
     */
    function retrySourceRegistration(source, env) {
      // TODO(veetesh): update params when public SourceService update API is in
      // place.
      if (env === 'kOracle') {
        var modalOpts = {
          templateUrl: 'app/protection/sources/db-manager/oracle-modify.html',
          controller: 'oracleModificationController as $ctrl',
          size: 'xl',
          resolve: {
            pageConfig: {
              hostName: source.protectionSource.physicalProtectionSource.name,
            },
            inModal: true,
          },
        };

        return SlideModalService.newModal(modalOpts);
      }

      // For SQL DBs, retry registration the simple way
      if (env === 'kSQL') {
        return SourceService.updateAppOwner({
          appEnvVec: [env],
          ownerEntity: { id: source.protectionSource.id },
        });
      }

      // Everything else goes through this.
      return SourceService.updateSource(
        PubSourceServiceFormatter.transformSourceFromPublicToPrivate(source)
      );
    }

    /**
     * submit immediate refresh request to sync entity hierarchy tree b/w
     * protection sources and the cohesity cluster.
     *
     * @method   refreshSource
     * @param    {Number}   sourceId   The root node source ID
     * @return   {Object}   Promise resolved on successful refresh request
     *                      submission and rejected with error on failure.
     */
    function refreshSource(sourceId) {
      return $http({
        method: 'POST',
        url: API.public('protectionSources/refresh', sourceId),
      });
    }

    /**
     * submit run diagnostics request to trigger diagnostics collection on
     * the protection source and upload it to the cohesity cluster.
     *
     * @method   runDiagnostics
     * @param    {Number}   sourceId   The root node source ID
     * @return   {Object}   Promise resolved on a successful request
     *                      submission and rejected with error on failure.
     */
    function runDiagnostics(sourceId) {
      return $http({
        method: 'POST',
        url: API.public('protectionSources/diagnostics', sourceId),
      });
    }

    /**
     * Register the source to be available for backup
     *
     * @method   registerSource
     * @param    {Object}    sourceDetails   Object containing source details
     * @return   {Promise}   Promise either resolved or rejected on successful
     *                       or failed request respectively.
     */
    function registerSource(sourceDetails) {
      return $http({
        method: 'POST',
        url: API.public('protectionSources/register'),
        data: sourceDetails,
        headers: NgPassthroughOptionsService.requestHeaders
      });
    }

    /**
     * Gets the aag information and binds it to the given node object.
     *
     * @method   bindAagDetailsToNode
     * @param    {object}   node   The node. If a rootNode is passed, the hostId
     *                             will be extracted from node.id, otherwise it
     *                             will be read from node.protectionSource
     * @return   {object}   Promise to resolve with the enhanced node.
     */
    function bindAagDetailsToNode(node) {
      var hostId = node.id || (node.protectionSource.sqlProtectionSource ?
        node.protectionSource.sqlProtectionSource.ownerId :
        node.protectionSource.id);

      return SourceService.getAagInfo(hostId).then(function received(aagInfo) {
        node._aagDetails = aagInfo;

        return node;
      });
    }

    /**
     * Gets the unique AAG entities. If node list is passed to the method, the
     * the return value will be the nodes from that list that match the AAG
     * in node._aagDetails. If no list is passed, the return will be the host
     * values from node._aagDetails.
     *
     * @method   getUniqueAagHosts
     * @param    {object}     node         The node.
     * @param    {Object[]}   [nodeList]   The node list
     * @return   {array}      The list of unique AAG entities in the AAG group.
     */
    function getUniqueAagHosts(node, nodeList) {
      return Object.values(node._aagDetails)
        .reduce(function makeHash(uHosts, aag) {
          (aag.hosts || []).forEach(function eachHost(entity) {
            var foundNode = !nodeList ? entity : PubJobServiceFormatter
              .findNodesByNodeIds(nodeList, [entity.id])[0];

            if (foundNode) {
              uHosts.push(foundNode);
            }
          });

          return uHosts;
        }, []);
    }

    /**
     * Shows the AAG node select dialog.
     *
     * @method   showAagNodeSelectDialog
     * @param    {object}   node   The node
     * @return   {object}   Promise to resolve/reject with the modal's outcome.
     */
    function showAagNodeSelectDialog(node) {
      var config = {
        size: 'lg',
        templateUrl: 'app/global/c-source-tree/dialogs.c-source-tree.html',

        /* @ngInject */
        controller: function AagDetectedModalController($scope,
          $uibModalInstance, evalAJAX, node) {
          var $ctrl = this;

          /**
           * Init this controller.
           *
           * @method   $onInit
           */
          $ctrl.$onInit = function $onInit() {
            $scope.node = $ctrl.node = node;
            $ctrl.getSetSelection(true);

            if (node._aags && !node._aagDetails) {
              bindAagDetailsToNode(node)
                .catch(evalAJAX.errorMessage);
            }
          };

          /**
           * Getter-Setter for the selection model.
           *
           * @method   getSetSelection
           * @param    {string|boolean}   [choice]   The user's selection.
           * @return   {*}                The current model value.
           */
          $ctrl.getSetSelection = function getSetSelection(choice) {
            if (arguments[0]) {
              $ctrl.aagSelectOption = choice;
              $ctrl.okDisabled = !choice;
            }

            return $ctrl.aagSelectOption;
          };

          /**
           * Modal ok/close method.
           *
           * @method   ok
           */
          $ctrl.ok = function ok() {
            $uibModalInstance.close($ctrl.getSetSelection() || true);
          };
        },
        resolve: { node: function () { return node; } },
      };
      var options = {
        actionButtonKey: 'select',
        closeButtonKey: false,
        titleKey: 'aagOptions',
      };

      return cModal.standardModal(config, options);
    }

    /**
     * Triggers an Upgrade the source that is selected
     *
     * @param    {Object}    params    { entity: [] }
     * @return   {Object}              Return the response data or return {}
     */
    function upgradeSource(params) {
      return $http({
        method: 'POST',
        url: API.public('physicalAgents/upgrade'),
        params: params,
      }).then(function upgradeSourceResp(resp){
        return resp.data || {};
      });
    }

    /**
     * Generates a register app compatible Host object
     *
     * @method    getRegisterableHostObject
     * @param     {object}   node         The source object to extract the host
     *                                    data from.
     * @param     {string}   appEnvType   The env type for the app for
     *                                    registering oracle/SQL
     * @returns   {object}   The generated Host object. Can be empty.
     */
    function getRegisterableHostObject(node, appEnvType) {
      var username = node.registrationInfo.username;

      // Need to Type convert the new public API to private API
      var environments = _.get(node, 'registrationInfo.environments.length') ?
        node.registrationInfo.environments.map(
          function convertPublicToPrivateEnvWrapper(appEnv) {
            return ENV_TYPE_CONVERSION[appEnv];
          }) :
        [appEnvType];

      return (!node || !node.protectionSource) ? {} :
        {
          credentials: !!username ? { username: username } : undefined,
          ownerEntity: node.protectionSource,
          appEnvVec: environments,
          usesPersistentAgent: !username,
        };

    }

    /**
     * Process each node and populate the condition flags
     * Some of the flag indicate whether it is a SQL or Physical node
     *
     * @param   {object}   source   source object
     */
    function processNodes(source) {
      source._isRegistering = false;
      source._isRegError = false;
      source._isUpgradeError = false;

      source._isConnectionError =
        !!_.get(source.registrationInfo, 'refreshErrorMessage', '');

      // Identify as a SQL entity. Not all SQL entities are hosts, so we're
      // doing this check here instead with other SQL checks lower.
      if (source.rootNode.environment === 'kPhysical') {
        source._isSqlEntity = !!source.registrationInfo.environments ?
          source.registrationInfo.environments.includes('kSQL') : false;

        // Determine if registration has been initialized.
        source._isRegistering =
          source.registrationInfo.authenticationStatus === 'kPending';

        // Determine if there was an error in registration verification.
        source._isRegError = !!source.registrationInfo.authenticationErrorMessage;

        var _upgradeInfo =
          source.rootNode.physicalProtectionSource.agents || [{}];
        source._isUpgradeError = !!_upgradeInfo[0].upgradeStatusMessage;
        source._upgradeError = source._isUpgradeError ?
          {
            upgradeMessage: _upgradeInfo[0].upgradeStatusMessage,
            upgradable: _upgradeInfo[0].upgradability === 'kUpgradable'
          } : {};

      }
    }

    /**
     * pop a modal to confirm deletion of a source. If confirmed call the
     * API to delete the source, closing the modal on success
     *
     * @param      {Object}    source           The source to be deleted
     * @return     {object}    promise to resolve deletion request
     */
    function deleteSourceModal(source) {
      var modalConfig = {
        size: 'md',
        templateUrl: 'app/protection/sources/modals/delete-source-pub.html',
        controller: deleteSourceModalControllerFn,
        resolve: {
          Source: function() {
            return source;
          },
        },
      };

      var windowOptions = {
        actionButtonKey: false,
        closeButtonKey: false,
        titleKey: 'unregisterSource',
      };

      /* @ngInject */
      function deleteSourceModalControllerFn($scope, $uibModalInstance,
        cMessage, Source) {

        var $ctrl = this;

        _.assign($ctrl, {
          cancel: cancel,
          deleteSource: deleteSource,
          source: Source,
        });

        function deleteSource() {
          $ctrl.submitting = true;

          /* when unregistering a physical server from the source tree the
          source object does not have a rootNode property, so the
          protectionSource object is used to get the Id */
          var sourceId = Source.rootNode ?
            Source.rootNode.id : source.protectionSource.id;

          SourceService.deleteSource(sourceId)
            .then(function unregisterSourceSuccess(response) {
              cMessage.success({
                textKey: 'unregisterSourceSuccessful',
              });

              $uibModalInstance.close(response);
            }, evalAJAX.errorMessage)
            .finally(function unregisterFinally() {
              $ctrl.submitting = false;
            });
        }

        function cancel() {
          $uibModalInstance.dismiss('cancel');
        }
      }

      return cModal.standardModal(modalConfig, windowOptions);
    }

    /**
     * Return the list of agents to be upgraded.
     *
     * @method  getAgentsToUpgrade
     * @param   {object}   source     The source entity
     * @return  {Array}    agentIds   List of agents to be upgraded
     */
    function getAgentsToUpgrade(source) {
      var agentIds = [];

      // Just to make sure that we have an _agent id when upgrade is triggered
      if (!source || !source._agent.id) {
        return;
      }

      switch (true) {
        case !!source._isSqlCluster || source._type === 'kOracleRACCluster':
          source.rootNode.physicalProtectionSource.agents.forEach(
            function queueUpgradableAgents(agent) {
              if (agent.upgradability === 'kUpgradable' &&
                !agentIds.includes(agent.id)) {
                agentIds.push(agent.id);
              }
            }
          );
          break;

        case ENV_GROUPS.usesAgent.includes(source._environment):
          agentIds = [source._agent.id];
          break;
      }

      return agentIds;
    }

    /**
     * This method will be used to process the source information stats which
     * are rendered in the glance bar.
     * When application objects are to be processed the information of stats
     * are taken from the statsByEnv Array.
     *
     * @method    processSourceInfo
     * @param     {object}   source        The source entity
     * @param     {string}   environment   Environment of the sources
     */
    function processSourceStats(source, environment) {
      var defaultStats = {
        protectedCount: 0,
        protectedSize: 0,
        unprotectedCount: 0,
        unprotectedSize: 0,
      };

      // kO365Outlook is deprecated.
      if (environment === 'kO365Outlook') {
        environment = 'kO365';
      }
      var stats = _.get(source, 'statsByEnv', []).find(
        function findEnv(element) {
          return element.environment === environment;
        }) || defaultStats;

      // If environment is kPhysical, add kPhysicalFiles stats to it as
      // there is no separate kPhysicalFiles source section on ui. Currently,
      // if a source is protected by file based job only, it is shown as not
      // protected on ui.
      if (environment === 'kPhysical') {
        var physicalFileStats = _.chain(source)
          .get('statsByEnv', [])
          .find(['environment', 'kPhysicalFiles'])
          .value();

        if (physicalFileStats) {
          _.keys(stats).forEach(function keysIterator(key) {
            if (['protectedCount', 'unprotectedCount'].includes(key)) {
              stats[key] = stats[key] || physicalFileStats[key];
            } else {
              stats[key] += physicalFileStats[key];
            }
          });

          // In case of physical source details page we are showing combined stats for both
          // Block and File based stats of a physical server. Hence assigning unprotected
          // count as 0 in case if protected count > 0.
          stats.unprotectedCount = stats.protectedCount > 0 ? 0 : stats.unprotectedCount;
        }
      }

      return stats;
    }

    /**
     * Return the protection detail of an object
     *
     * @method   _getDetailsByEnv
     * @param    {object}   protection   The protection source summary object
     * @param    {string}   environment  Env Type
     * @return   {object}   The protection source or undefined
     */
    function _getDetailsByEnv(protection, environment) {
      return protection.find( function findByEnvFn(value) {
        return value.environment === environment;
      }) || {};
    }

    /**
     * Launch Source Group Modal to assign the sources.
     *
     * @method   openSourceGroupModal
     * @param    {Array}    selectedObjects   Selected Objects list
     * @param    {Object}   options           Additional options.
     * @return   {Object}   Promise resolved with selected sources
     */
    function openSourceGroupModal(selectedObjects, options) {
      var modalConfig = {
        component: 'sourceGroupModal',
        size: 'xl',
        resolve: {
          selectedObjects: function getSelectedObjects() {
            return selectedObjects;
          },
          options: function getOptions() {
            return options || {};
          },
        },
      };

      return SlideModalService.newModal(modalConfig);
    }

    /**
     * Determines if logical size is available for a certain source.
     *
     * @method   isLogicalSizeAvailable
     * @param    {object}    stats         The source stats
     * @param    {number}    logicalSize   The logical size of the source
     * @return   {boolean}   True if logical size available, False otherwise.
     */
    function isLogicalSizeAvailable(stats, logicalSize) {
      /**
       * The condition checks for either logicalSize or the sum of protected
       * and unprotected size. If both are undefined or zero, we can assume
       * backend does not know the logical size of the entity and we should not
       * show it on frontend.
       */
      return !!(logicalSize || (stats.protectedSize + stats.unprotectedSize));
    }

    /**
     * Determines if protected size is available for a certain source.
     *
     * @method   isProtectedSizeAvailable
     * @param    {object}    stats   The source stats
     * @return   {boolean}   True if protected size available, False otherwise.
     */
    function isProtectedSizeAvailable(stats) {
      /**
       * The condition will hold true in case protectedCount is 0, which means
       * nothing is protected and we can show 0. In case if the entity or source
       * is protected, we should have some protectedSize to show. In some cases,
       * where backend would send us protectedSize as 0 but we have protected
       * the source, instead of showing 0 we should make the protected size
       * unavailable and not show on frontend.
       */
      return stats && (stats.protectedCount === 0 || stats.protectedSize > 0);
    }

    /**
     * Returns the Drive Id for the given Office365 entity iff the entity is
     * an Office365 User.
     *
     * @param    {object}   o365Source   Specifies the Office 365 source.
     * @return   {string}   OneDrive Id associated with the Office365 user.
     */
    function getOneDriveId(o365Source) {
      // Handles extraction of OneDrive Id from the public search result.
      if (o365Source.office365ProtectionSource.type === 'kUser') {
        return _.get(o365Source,
          'office365ProtectionSource.userInfo.oneDriveId');
      }

      // TODO(tauseef): Handle future cases of drives within Sharepoint.
      return '';
    }

    /**
     * Returns the unique name for the Oracle database. If the Unqiuename is
     * not present then it falls back to the name field.
     *
     * @param     {object}   node   Specifies the protection source.
     * @returns   {string}   Database unique name
     */
    function getDabaseUniqueName(node) {
      return _.get(node, '_envProtectionSource.databaseUniqueName') ||
        _.get(node, '_envProtectionSource.name');
    }

    /**
     * Download the "Cloud Formation Template" for AWS IAM Role
     *
     * @method  downloadAwsCFT
     * @params  {Object}  {filepath: {string}, filename: {string}}
     *                    optional filepath/name to identify the cft file
     * @return  {Object}  Promise to be resolved with downloaded CFT content
     */
    function downloadAwsCFT(params) {
      return $http({
        method: 'GET',
        url: API.public('protectionSources/downloadCftFile'),
        params: params || {},
      }).then(function downloadAwsCFTResp(resp){
        return resp.data || {};
      });
    }
  }

}(angular));
