import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AngularMaterialModule } from '../../angular-material.module';
import { AppFrameModule } from '../app-frame/app-frame.module';
import { DataIdModule } from '../data-id/data-id.module';
import { IconModule } from '../icon/icon.module';
import { PageBackdropComponent } from './page-backdrop/page-backdrop.component';
import {
  PageContentHeaderComponent,
  PageContentHeaderControlsComponent,
  PageContentHeaderIconComponent,
  PageContentHeaderNavigationComponent,
  PageContentHeaderSubtitleComponent,
  PageContentHeaderTagComponent,
} from './page-layouts/page-content-header/page-content-header.component';
import { PageContentComponent } from './page-layouts/page-content/page-content.component';
import {
  PageActionsComponent,
  PageBreadcrumbsComponent,
  PageTitleComponent,
  PageNavComponent,
  PageToolbarComponent,
} from './page-toolbar/page-toolbar.component';
import { PageComponent, PageDrawerAnchorComponent, PageDrawerComponent, PageHeaderComponent } from './page.component';

const COMPONENTS = [
  PageActionsComponent,
  PageTitleComponent,
  PageBackdropComponent,
  PageBreadcrumbsComponent,
  PageComponent,
  PageContentComponent,
  PageContentHeaderComponent,
  PageContentHeaderControlsComponent,
  PageContentHeaderIconComponent,
  PageContentHeaderNavigationComponent,
  PageContentHeaderSubtitleComponent,
  PageContentHeaderTagComponent,
  PageHeaderComponent,
  PageDrawerComponent,
  PageNavComponent,
  PageToolbarComponent,
  PageDrawerAnchorComponent,
];

@NgModule({
  imports: [
    AngularMaterialModule,
    AppFrameModule,
    CommonModule,
    DataIdModule,
    IconModule,
  ],
  declarations: COMPONENTS,
  exports: COMPONENTS,
})
export class PageModule { }
