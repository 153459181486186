import { Directive, EventEmitter, Host, Inject, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { DataTreeFilter, DataTreeSelectComponent, DataTreeSelectionModel, DataTreeSource } from '@cohesity/helix';
import { AutoDestroyable } from '@cohesity/utils';

import { SourceTreeFilters, SourceTreeNode, SourceTreeService } from '../shared';
import { SOURCE_TREE_SERVICE_FACTORY, SourceTreeServiceFactory } from '../shared/source-tree-service.factory';

/**
 * This is a directive that can be applied to a cog-data-tree-select which configures it to work with
 * the source tree logic. Set the sourceNodes and environment inputs and this will take care of data
 * transformations and apply the default view filter to the nodes.
 *
 * @example
 * <mat-form-field >
 *   <mat-label>Target</mat-label>
 *   <cog-data-tree-select cohSourceTreeSelect
 *     [environment]="environment"
 *     [sourceNodes]="availableSources"
 *   </cog-data-tree-select>
 * </mat-form-field>
 */
@Directive({
  selector: 'cog-data-tree-select [cohSourceTreeSelect]',
})
export class SourceTreeSelectDirective<NodeType, TreeNodeType extends SourceTreeNode<NodeType>> extends AutoDestroyable
  implements OnChanges {
  /**
   * The current environment - this determines which service to load.
   */
  @Input() environment;

  /**
   * An array of nodes in a tree structure.
   */
  @Input() sourceNodes: NodeType[] = [];

  /**
   * Additional filters to apply to the source list. This is applied after the view filter and before the
   * search filter.
   */
  @Input() additionalFilters: DataTreeFilter<TreeNodeType>[] = [];

  /**
   * Emits an event whenever data in the tree has loaded.
   */
  @Output() dataInitialized = new EventEmitter<TreeNodeType[]>();

  /**
   * The data tree selection model.
   */
  dataTreeSelection: DataTreeSelectionModel<TreeNodeType>;

  /**
   * The data tree source.
   */
  dataTreeSource: DataTreeSource<NodeType>;

  /**
   * This class manages the filters that can be shown for the tree, and which are currently active. When this changes
   * the tree data source should be updated.
   */
  filters: SourceTreeFilters<TreeNodeType>;

  /**
   * The tree service is created by the factory service and is specific to an adapter.
   */
  treeService: SourceTreeService<NodeType, TreeNodeType>;

  constructor(
    @Host() readonly dataTreeSelect: DataTreeSelectComponent<any>,
    @Inject(SOURCE_TREE_SERVICE_FACTORY) private transformerFactory: SourceTreeServiceFactory
  ) {
    super();

    // Set up a default name and icons functions for the data tree select.
    // Icons will only work if the source is configured to use helix icons.
    dataTreeSelect.nameFn = (node: TreeNodeType) => node.name;
    dataTreeSelect.iconFn = (node: TreeNodeType) => node.icon;

    // By default, use the isLeaf property if it exists to determine if a node can be selected.
    dataTreeSelect.canSelectFn = (node: TreeNodeType) => this.treeService.isLeaf && this.treeService.isLeaf(node);
  }

  /**
   * Update the component when inputs change
   *
   * @param   changes   A map of changed objects.
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes.environment && this.environment) {
      this.initService();
    }

    if (changes.sourceNodes && this.sourceNodes) {
      this.initData();
    }
  }

  /**
   * Reinitialize the service whenver the environment is changed.
   */
  initService() {
    this.cleanUpSubscriptions();

    this.treeService = this.transformerFactory.getSourceTreeService(this.environment);
    this.filters = this.treeService.filters;
    this.dataTreeSource = this.treeService.dataTreeSource;
    this.dataTreeSelect.data = this.dataTreeSource;
    this.dataTreeSelection = new DataTreeSelectionModel(
      this.treeService.treeControl,
      !this.dataTreeSelect.multiSelect,
      false
    );
    this.treeService.dataTreeSelection = this.dataTreeSelection;

    this.filters.resetFilters();

    // Update the data source any time the filters change
    this.filters.treeFilters$
      .pipe(this.untilDestroy())
      .subscribe((filters: any) => (this.dataTreeSelect.filters = [...filters, ...this.additionalFilters]));

    // Call initData in case the data was set before the environment was.
    this.initData();
  }

  /**
   * Update the data on the data tree source whenever it changes.
   */
  initData() {
    if (!this.sourceNodes || !this.treeService) {
      return;
    }
    this.dataTreeSource.data = this.treeService.unwrapRootContainers(this.sourceNodes);
    this.dataInitialized.emit(this.dataTreeSource.treeControl.allDataNodes as any);
  }
}
