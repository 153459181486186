/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityV2ApiConfiguration as __Configuration } from '../cohesity-v2api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { UserAPIKeys } from '../models/user-apikeys';
import { Groups } from '../models/groups';
import { CreateGroupsParams } from '../models/create-groups-params';
import { DeleteGroupsRequest } from '../models/delete-groups-request';
import { GroupParams } from '../models/group-params';
import { UpdateGroupParameters } from '../models/update-group-parameters';
import { Users } from '../models/users';
import { EnableSupportAccessParams } from '../models/enable-support-access-params';
import { TenantAccessResult } from '../models/tenant-access-result';
import { SecurityPrincipals } from '../models/security-principals';
import { AssignedSources } from '../models/assigned-sources';
import { UpdatePasswordResult } from '../models/update-password-result';
import { UpdateSessionUserPasswordRequest } from '../models/update-session-user-password-request';
import { UsersList } from '../models/users-list';
import { CreateUsersParameters } from '../models/create-users-parameters';
import { DeleteUsersRequest } from '../models/delete-users-request';
import { ActiveSessionsCountParams } from '../models/active-sessions-count-params';
import { UserSession } from '../models/user-session';
import { CreateUserSessionRequestParams } from '../models/create-user-session-request-params';
import { UserUiConfig } from '../models/user-ui-config';
import { UserParams } from '../models/user-params';
import { UpdateUserParameters } from '../models/update-user-parameters';
import { SecretKeyEntity } from '../models/secret-key-entity';
import { CreatedUserAPIKey } from '../models/created-user-apikey';
import { CreateOrUpdateAPIKeyRequest } from '../models/create-or-update-apikey-request';
import { UserAPIKey } from '../models/user-apikey';
@Injectable({
  providedIn: 'root',
})
class UserService extends __BaseService {
  static readonly GetAllAPIKeysPath = '/api-keys';
  static readonly GetGroupsPath = '/groups';
  static readonly CreateGroupPath = '/groups';
  static readonly DeleteGroupsPath = '/groups/delete';
  static readonly GetGroupBySIDPath = '/groups/{sid}';
  static readonly UpdateGroupPath = '/groups/{sid}';
  static readonly DeleteGroupPath = '/groups/{sid}';
  static readonly GetUsersMixin1Path = '/mcm/users';
  static readonly EnableSupportAccessPath = '/mcm/users/support-access';
  static readonly GetTenantAccessPath = '/mcm/users/tenant-access';
  static readonly GetSecurityPrincipalsPath = '/security-principals';
  static readonly GetPrincipalSourcesPath = '/security-principals/{sid}/sources';
  static readonly UpdatePrincipalSourcesPath = '/security-principals/{sid}/sources';
  static readonly UpdateSessionUserPasswordPath = '/session-user/password';
  static readonly GetUsersPath = '/users';
  static readonly CreateUsersPath = '/users';
  static readonly DeleteUsersPath = '/users/delete';
  static readonly GetActiveSessionsCountPath = '/users/sessions';
  static readonly CreateSessionPath = '/users/sessions';
  static readonly DeleteSessionPath = '/users/sessions';
  static readonly GetUserUiConfigPath = '/users/ui-config';
  static readonly UpdateUserUiConfigPath = '/users/ui-config';
  static readonly GetUserBySIDPath = '/users/{sid}';
  static readonly UpdateUserPath = '/users/{sid}';
  static readonly DeleteUserPath = '/users/{sid}';
  static readonly RegenerateS3KeyPath = '/users/{sid}/s3-secret-key';
  static readonly GetUserAPIKeysPath = '/users/{userSid}/api-keys';
  static readonly CreateUserAPIKeyPath = '/users/{userSid}/api-keys';
  static readonly GetUserAPIKeyByIdPath = '/users/{userSid}/api-keys/{id}';
  static readonly UpdateUserAPIKeyByIdPath = '/users/{userSid}/api-keys/{id}';
  static readonly DeleteUserAPIKeyByIdPath = '/users/{userSid}/api-keys/{id}';
  static readonly RotateUserAPIKeyPath = '/users/{userSid}/api-keys/{id}/rotate';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get the list of all API keys which are created or owned by the user.
   *
   * Get the list of all API keys which are created or owned by the user.
   * @param params The `UserService.GetAllAPIKeysParams` containing the following parameters:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `ownerSids`: Filter by list of owner (user) SIDs
   *
   * - `isExpired`: If true, the response will only include API keys which has been expired. Returns all keys if the query param is not set.
   *
   * - `isActive`: If true, the response will only include API keys which are active. Returns all keys if the query param is not set.
   *
   * - `ids`: Filter by API Key Ids
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetAllAPIKeysResponse(params: UserService.GetAllAPIKeysParams): __Observable<__StrictHttpResponse<UserAPIKeys>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    (params.ownerSids || []).forEach(val => {if (val != null) __params = __params.append('ownerSids', val.toString())});
    if (params.isExpired != null) __params = __params.set('isExpired', params.isExpired.toString());
    if (params.isActive != null) __params = __params.set('isActive', params.isActive.toString());
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api-keys`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserAPIKeys>;
      })
    );
  }
  /**
   * Get the list of all API keys which are created or owned by the user.
   *
   * Get the list of all API keys which are created or owned by the user.
   * @param params The `UserService.GetAllAPIKeysParams` containing the following parameters:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `ownerSids`: Filter by list of owner (user) SIDs
   *
   * - `isExpired`: If true, the response will only include API keys which has been expired. Returns all keys if the query param is not set.
   *
   * - `isActive`: If true, the response will only include API keys which are active. Returns all keys if the query param is not set.
   *
   * - `ids`: Filter by API Key Ids
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetAllAPIKeys(params: UserService.GetAllAPIKeysParams): __Observable<UserAPIKeys> {
    return this.GetAllAPIKeysResponse(params).pipe(
      __map(_r => _r.body as UserAPIKeys)
    );
  }

  /**
   * Get Groups.
   *
   * Get groups on the Cohesity cluster.
   * @param params The `UserService.GetGroupsParams` containing the following parameters:
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which groups are to be returned.
   *
   * - `sids`: Specifies a list of sids to filter.
   *
   * - `roles`: Specifies a list of roles to filter.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `names`: Specifies a list of group names to filter.
   *
   * - `matchPartialNames`: If true, the names in group names are matched by any partial rather than exactly matched. Default is false.
   *
   * - `includeTenants`: IncludeTenants specifies if groups of all the tenants under the hierarchy of the logged in user's organization should be returned.
   *
   * - `domain`: Specifies the group domain to filter.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetGroupsResponse(params: UserService.GetGroupsParams): __Observable<__StrictHttpResponse<Groups>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.tenantIds || []).forEach(val => {if (val != null) __params = __params.append('tenantIds', val.toString())});
    (params.sids || []).forEach(val => {if (val != null) __params = __params.append('sids', val.toString())});
    (params.roles || []).forEach(val => {if (val != null) __params = __params.append('roles', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    (params.names || []).forEach(val => {if (val != null) __params = __params.append('names', val.toString())});
    if (params.matchPartialNames != null) __params = __params.set('matchPartialNames', params.matchPartialNames.toString());
    if (params.includeTenants != null) __params = __params.set('includeTenants', params.includeTenants.toString());
    if (params.domain != null) __params = __params.set('domain', params.domain.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/groups`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Groups>;
      })
    );
  }
  /**
   * Get Groups.
   *
   * Get groups on the Cohesity cluster.
   * @param params The `UserService.GetGroupsParams` containing the following parameters:
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which groups are to be returned.
   *
   * - `sids`: Specifies a list of sids to filter.
   *
   * - `roles`: Specifies a list of roles to filter.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `names`: Specifies a list of group names to filter.
   *
   * - `matchPartialNames`: If true, the names in group names are matched by any partial rather than exactly matched. Default is false.
   *
   * - `includeTenants`: IncludeTenants specifies if groups of all the tenants under the hierarchy of the logged in user's organization should be returned.
   *
   * - `domain`: Specifies the group domain to filter.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetGroups(params: UserService.GetGroupsParams): __Observable<Groups> {
    return this.GetGroupsResponse(params).pipe(
      __map(_r => _r.body as Groups)
    );
  }

  /**
   * Create Groups
   *
   * If an Active Directory/IdP domain is specified, a new group is added to the Cohesity Cluster for the specified Active Directory/IdP group principal. If the LOCAL domain is specified, a new group is created directly in the default LOCAL domain on the Cohesity Cluster.
   * @param params The `UserService.CreateGroupParams` containing the following parameters:
   *
   * - `body`: Specifies the new group parameters.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateGroupResponse(params: UserService.CreateGroupParams): __Observable<__StrictHttpResponse<Groups>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/groups`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Groups>;
      })
    );
  }
  /**
   * Create Groups
   *
   * If an Active Directory/IdP domain is specified, a new group is added to the Cohesity Cluster for the specified Active Directory/IdP group principal. If the LOCAL domain is specified, a new group is created directly in the default LOCAL domain on the Cohesity Cluster.
   * @param params The `UserService.CreateGroupParams` containing the following parameters:
   *
   * - `body`: Specifies the new group parameters.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateGroup(params: UserService.CreateGroupParams): __Observable<Groups> {
    return this.CreateGroupResponse(params).pipe(
      __map(_r => _r.body as Groups)
    );
  }

  /**
   * Delete Groups
   *
   * If the Cohesity group was created against an Active Directory/IdP, the referenced principal group on the Active Directory/IdP domain is NOT deleted. Only the group on the Cohesity Cluster is deleted.
   * @param params The `UserService.DeleteGroupsParams` containing the following parameters:
   *
   * - `body`: Specifies the delete request payload.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteGroupsResponse(params: UserService.DeleteGroupsParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/groups/delete`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete Groups
   *
   * If the Cohesity group was created against an Active Directory/IdP, the referenced principal group on the Active Directory/IdP domain is NOT deleted. Only the group on the Cohesity Cluster is deleted.
   * @param params The `UserService.DeleteGroupsParams` containing the following parameters:
   *
   * - `body`: Specifies the delete request payload.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteGroups(params: UserService.DeleteGroupsParams): __Observable<null> {
    return this.DeleteGroupsResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get Group by SID
   *
   * Get Group by SID.
   * @param params The `UserService.GetGroupBySIDParams` containing the following parameters:
   *
   * - `sid`: Specify the SID of the group.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetGroupBySIDResponse(params: UserService.GetGroupBySIDParams): __Observable<__StrictHttpResponse<GroupParams>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/groups/${encodeURIComponent(params.sid)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GroupParams>;
      })
    );
  }
  /**
   * Get Group by SID
   *
   * Get Group by SID.
   * @param params The `UserService.GetGroupBySIDParams` containing the following parameters:
   *
   * - `sid`: Specify the SID of the group.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetGroupBySID(params: UserService.GetGroupBySIDParams): __Observable<GroupParams> {
    return this.GetGroupBySIDResponse(params).pipe(
      __map(_r => _r.body as GroupParams)
    );
  }

  /**
   * Update Group
   *
   * Only group settings on the Cohesity Cluster are updated. No changes are made to the referenced group principal on the Active Directory/IdP.
   * @param params The `UserService.UpdateGroupParams` containing the following parameters:
   *
   * - `sid`: Specify the SID of the group.
   *
   * - `body`: Specifies the group information.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateGroupResponse(params: UserService.UpdateGroupParams): __Observable<__StrictHttpResponse<GroupParams>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/groups/${encodeURIComponent(params.sid)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GroupParams>;
      })
    );
  }
  /**
   * Update Group
   *
   * Only group settings on the Cohesity Cluster are updated. No changes are made to the referenced group principal on the Active Directory/IdP.
   * @param params The `UserService.UpdateGroupParams` containing the following parameters:
   *
   * - `sid`: Specify the SID of the group.
   *
   * - `body`: Specifies the group information.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateGroup(params: UserService.UpdateGroupParams): __Observable<GroupParams> {
    return this.UpdateGroupResponse(params).pipe(
      __map(_r => _r.body as GroupParams)
    );
  }

  /**
   * Delete Group
   *
   * If the group on the Cohesity Cluster was added for an Active Directory/IdP group, the referenced principal group on the Active Directory/IdP domain is NOT deleted. Only the group on the Cohesity Cluster is deleted.
   * @param params The `UserService.DeleteGroupParams` containing the following parameters:
   *
   * - `sid`: Specify the SID of the group.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteGroupResponse(params: UserService.DeleteGroupParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/groups/${encodeURIComponent(params.sid)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete Group
   *
   * If the group on the Cohesity Cluster was added for an Active Directory/IdP group, the referenced principal group on the Active Directory/IdP domain is NOT deleted. Only the group on the Cohesity Cluster is deleted.
   * @param params The `UserService.DeleteGroupParams` containing the following parameters:
   *
   * - `sid`: Specify the SID of the group.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteGroup(params: UserService.DeleteGroupParams): __Observable<null> {
    return this.DeleteGroupResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get Users.
   *
   * Get helios users
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  GetUsersMixin1Response(regionId?: string): __Observable<__StrictHttpResponse<Users>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/mcm/users`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Users>;
      })
    );
  }
  /**
   * Get Users.
   *
   * Get helios users
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  GetUsersMixin1(regionId?: string): __Observable<Users> {
    return this.GetUsersMixin1Response(regionId).pipe(
      __map(_r => _r.body as Users)
    );
  }

  /**
   * Enable support access in Helios.
   * @param body undefined
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  EnableSupportAccessResponse(body: EnableSupportAccessParams,
    regionId?: string): __Observable<__StrictHttpResponse<EnableSupportAccessParams>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/mcm/users/support-access`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EnableSupportAccessParams>;
      })
    );
  }
  /**
   * Enable support access in Helios.
   * @param body undefined
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  EnableSupportAccess(body: EnableSupportAccessParams,
    regionId?: string): __Observable<EnableSupportAccessParams> {
    return this.EnableSupportAccessResponse(body, regionId).pipe(
      __map(_r => _r.body as EnableSupportAccessParams)
    );
  }

  /**
   * Get a list of available tenant access available to the logged in User.
   *
   * List Tenant Access
   * @param params The `UserService.GetTenantAccessParams` containing the following parameters:
   *
   * - `tenantIds`: List of Tenant Ids to filter from.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `includeInactive`: By Default, only valid tenant access are returned. If set to true,
   *   inactive or ineffective or expired tenant access would be returned
   *   as well.
   *
   * @return Success
   */
  GetTenantAccessResponse(params: UserService.GetTenantAccessParams): __Observable<__StrictHttpResponse<TenantAccessResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.tenantIds || []).forEach(val => {if (val != null) __params = __params.append('tenantIds', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.includeInactive != null) __params = __params.set('includeInactive', params.includeInactive.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/mcm/users/tenant-access`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TenantAccessResult>;
      })
    );
  }
  /**
   * Get a list of available tenant access available to the logged in User.
   *
   * List Tenant Access
   * @param params The `UserService.GetTenantAccessParams` containing the following parameters:
   *
   * - `tenantIds`: List of Tenant Ids to filter from.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `includeInactive`: By Default, only valid tenant access are returned. If set to true,
   *   inactive or ineffective or expired tenant access would be returned
   *   as well.
   *
   * @return Success
   */
  GetTenantAccess(params: UserService.GetTenantAccessParams): __Observable<TenantAccessResult> {
    return this.GetTenantAccessResponse(params).pipe(
      __map(_r => _r.body as TenantAccessResult)
    );
  }

  /**
   * Get Security Principals.
   *
   * Get Security Principals
   * @param params The `UserService.GetSecurityPrincipalsParams` containing the following parameters:
   *
   * - `sids`: Specifies a list of SIDs.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetSecurityPrincipalsResponse(params: UserService.GetSecurityPrincipalsParams): __Observable<__StrictHttpResponse<SecurityPrincipals>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.sids || []).forEach(val => {if (val != null) __params = __params.append('sids', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/security-principals`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SecurityPrincipals>;
      })
    );
  }
  /**
   * Get Security Principals.
   *
   * Get Security Principals
   * @param params The `UserService.GetSecurityPrincipalsParams` containing the following parameters:
   *
   * - `sids`: Specifies a list of SIDs.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetSecurityPrincipals(params: UserService.GetSecurityPrincipalsParams): __Observable<SecurityPrincipals> {
    return this.GetSecurityPrincipalsResponse(params).pipe(
      __map(_r => _r.body as SecurityPrincipals)
    );
  }

  /**
   * Fetch sources & views assigned to a user/group.
   *
   * Fetches all the sources assigned to a principal.
   * @param params The `UserService.GetPrincipalSourcesParams` containing the following parameters:
   *
   * - `sid`: Specify the SID of the principal.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetPrincipalSourcesResponse(params: UserService.GetPrincipalSourcesParams): __Observable<__StrictHttpResponse<AssignedSources>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/security-principals/${encodeURIComponent(params.sid)}/sources`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AssignedSources>;
      })
    );
  }
  /**
   * Fetch sources & views assigned to a user/group.
   *
   * Fetches all the sources assigned to a principal.
   * @param params The `UserService.GetPrincipalSourcesParams` containing the following parameters:
   *
   * - `sid`: Specify the SID of the principal.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetPrincipalSources(params: UserService.GetPrincipalSourcesParams): __Observable<AssignedSources> {
    return this.GetPrincipalSourcesResponse(params).pipe(
      __map(_r => _r.body as AssignedSources)
    );
  }

  /**
   * Update protection sources assigned to a user/group.
   *
   * Update protection sources assigned to a user/group.
   * @param params The `UserService.UpdatePrincipalSourcesParams` containing the following parameters:
   *
   * - `sid`: Specify the SID of the principal.
   *
   * - `body`: Specify the sources to be assigned to a principal.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdatePrincipalSourcesResponse(params: UserService.UpdatePrincipalSourcesParams): __Observable<__StrictHttpResponse<AssignedSources>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/security-principals/${encodeURIComponent(params.sid)}/sources`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AssignedSources>;
      })
    );
  }
  /**
   * Update protection sources assigned to a user/group.
   *
   * Update protection sources assigned to a user/group.
   * @param params The `UserService.UpdatePrincipalSourcesParams` containing the following parameters:
   *
   * - `sid`: Specify the SID of the principal.
   *
   * - `body`: Specify the sources to be assigned to a principal.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdatePrincipalSources(params: UserService.UpdatePrincipalSourcesParams): __Observable<AssignedSources> {
    return this.UpdatePrincipalSourcesResponse(params).pipe(
      __map(_r => _r.body as AssignedSources)
    );
  }

  /**
   * Update password of the session user.
   *
   * Update password of the session user.
   * @param body Specify the request body of the update password request.
   * @return Success
   */
  UpdateSessionUserPasswordResponse(body: UpdateSessionUserPasswordRequest): __Observable<__StrictHttpResponse<UpdatePasswordResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/session-user/password`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UpdatePasswordResult>;
      })
    );
  }
  /**
   * Update password of the session user.
   *
   * Update password of the session user.
   * @param body Specify the request body of the update password request.
   * @return Success
   */
  UpdateSessionUserPassword(body: UpdateSessionUserPasswordRequest): __Observable<UpdatePasswordResult> {
    return this.UpdateSessionUserPasswordResponse(body).pipe(
      __map(_r => _r.body as UpdatePasswordResult)
    );
  }

  /**
   * Get Users.
   *
   * Get Users.
   * @param params The `UserService.GetUsersParams` containing the following parameters:
   *
   * - `usernames`: Specifies a list of usernames to filter.
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which users are to be returned.
   *
   * - `sids`: Specifies a list of sids to filter.
   *
   * - `roles`: Specifies a list of roles to filter.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `matchPartialNames`: If true, the names in usernames are matched by any partial rather than exactly matched.
   *
   * - `includeTenants`: IncludeTenants specifies if users of all the tenants under the hierarchy of the logged in user's organization should be returned.
   *
   * - `emailAddresses`: Specifies a list of email addresses to filter.
   *
   * - `domain`: Specifies the user domain to filter.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetUsersResponse(params: UserService.GetUsersParams): __Observable<__StrictHttpResponse<UsersList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.usernames || []).forEach(val => {if (val != null) __params = __params.append('usernames', val.toString())});
    (params.tenantIds || []).forEach(val => {if (val != null) __params = __params.append('tenantIds', val.toString())});
    (params.sids || []).forEach(val => {if (val != null) __params = __params.append('sids', val.toString())});
    (params.roles || []).forEach(val => {if (val != null) __params = __params.append('roles', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.matchPartialNames != null) __params = __params.set('matchPartialNames', params.matchPartialNames.toString());
    if (params.includeTenants != null) __params = __params.set('includeTenants', params.includeTenants.toString());
    (params.emailAddresses || []).forEach(val => {if (val != null) __params = __params.append('emailAddresses', val.toString())});
    if (params.domain != null) __params = __params.set('domain', params.domain.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/users`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UsersList>;
      })
    );
  }
  /**
   * Get Users.
   *
   * Get Users.
   * @param params The `UserService.GetUsersParams` containing the following parameters:
   *
   * - `usernames`: Specifies a list of usernames to filter.
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which users are to be returned.
   *
   * - `sids`: Specifies a list of sids to filter.
   *
   * - `roles`: Specifies a list of roles to filter.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `matchPartialNames`: If true, the names in usernames are matched by any partial rather than exactly matched.
   *
   * - `includeTenants`: IncludeTenants specifies if users of all the tenants under the hierarchy of the logged in user's organization should be returned.
   *
   * - `emailAddresses`: Specifies a list of email addresses to filter.
   *
   * - `domain`: Specifies the user domain to filter.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetUsers(params: UserService.GetUsersParams): __Observable<UsersList> {
    return this.GetUsersResponse(params).pipe(
      __map(_r => _r.body as UsersList)
    );
  }

  /**
   * Add one or more users to Cohesity Cluster.
   *
   * Add one or more users to Cohesity Cluster.
   * @param params The `UserService.CreateUsersParams` containing the following parameters:
   *
   * - `body`: If an Active Directory or an IdP domain is specified, a new user is added to the Cohesity Cluster against the specified Active Directory/IdP user principal. If the LOCAL domain is specified, a new user is created directly in the default LOCAL domain on the Cohesity Cluster.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateUsersResponse(params: UserService.CreateUsersParams): __Observable<__StrictHttpResponse<UsersList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/users`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UsersList>;
      })
    );
  }
  /**
   * Add one or more users to Cohesity Cluster.
   *
   * Add one or more users to Cohesity Cluster.
   * @param params The `UserService.CreateUsersParams` containing the following parameters:
   *
   * - `body`: If an Active Directory or an IdP domain is specified, a new user is added to the Cohesity Cluster against the specified Active Directory/IdP user principal. If the LOCAL domain is specified, a new user is created directly in the default LOCAL domain on the Cohesity Cluster.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateUsers(params: UserService.CreateUsersParams): __Observable<UsersList> {
    return this.CreateUsersResponse(params).pipe(
      __map(_r => _r.body as UsersList)
    );
  }

  /**
   * Delete one or more Cohesity users.
   *
   * Delete one or more Cohesity users.
   * @param params The `UserService.DeleteUsersParams` containing the following parameters:
   *
   * - `body`: If the Cohesity user was created against an Active Directory/IdP user, the referenced principal user on the Active Directory/IdP domain is NOT deleted. Only the user on the Cohesity Cluster is deleted.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteUsersResponse(params: UserService.DeleteUsersParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/users/delete`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete one or more Cohesity users.
   *
   * Delete one or more Cohesity users.
   * @param params The `UserService.DeleteUsersParams` containing the following parameters:
   *
   * - `body`: If the Cohesity user was created against an Active Directory/IdP user, the referenced principal user on the Active Directory/IdP domain is NOT deleted. Only the user on the Cohesity Cluster is deleted.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteUsers(params: UserService.DeleteUsersParams): __Observable<null> {
    return this.DeleteUsersResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get sessions count
   *
   * Get the number of user sessions.
   * @param params The `UserService.GetActiveSessionsCountParams` containing the following parameters:
   *
   * - `sids`: Filter sessions based on user sids.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetActiveSessionsCountResponse(params: UserService.GetActiveSessionsCountParams): __Observable<__StrictHttpResponse<ActiveSessionsCountParams>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.sids || []).forEach(val => {if (val != null) __params = __params.append('sids', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/users/sessions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ActiveSessionsCountParams>;
      })
    );
  }
  /**
   * Get sessions count
   *
   * Get the number of user sessions.
   * @param params The `UserService.GetActiveSessionsCountParams` containing the following parameters:
   *
   * - `sids`: Filter sessions based on user sids.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetActiveSessionsCount(params: UserService.GetActiveSessionsCountParams): __Observable<ActiveSessionsCountParams> {
    return this.GetActiveSessionsCountResponse(params).pipe(
      __map(_r => _r.body as ActiveSessionsCountParams)
    );
  }

  /**
   * Create a user session
   *
   * Create a user session
   * @param params The `UserService.CreateSessionParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to create a user session
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateSessionResponse(params: UserService.CreateSessionParams): __Observable<__StrictHttpResponse<UserSession>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/users/sessions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserSession>;
      })
    );
  }
  /**
   * Create a user session
   *
   * Create a user session
   * @param params The `UserService.CreateSessionParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to create a user session
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateSession(params: UserService.CreateSessionParams): __Observable<UserSession> {
    return this.CreateSessionResponse(params).pipe(
      __map(_r => _r.body as UserSession)
    );
  }

  /**
   * Delete user sessions
   *
   * Deletes all sessions for given user sid or system wide sessions
   * @param params The `UserService.DeleteSessionParams` containing the following parameters:
   *
   * - `sid`: Specifies a user sid. If sid is not given system wide sessions are deleted.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteSessionResponse(params: UserService.DeleteSessionParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sid != null) __params = __params.set('sid', params.sid.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/users/sessions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete user sessions
   *
   * Deletes all sessions for given user sid or system wide sessions
   * @param params The `UserService.DeleteSessionParams` containing the following parameters:
   *
   * - `sid`: Specifies a user sid. If sid is not given system wide sessions are deleted.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteSession(params: UserService.DeleteSessionParams): __Observable<null> {
    return this.DeleteSessionResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get user UI config.
   *
   * Get customized UI config for the logged in user.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetUserUiConfigResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<UserUiConfig>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/users/ui-config`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserUiConfig>;
      })
    );
  }
  /**
   * Get user UI config.
   *
   * Get customized UI config for the logged in user.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetUserUiConfig(regionId?: string,
    accessClusterId?: number): __Observable<UserUiConfig> {
    return this.GetUserUiConfigResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as UserUiConfig)
    );
  }

  /**
   * Update user UI config.
   *
   * Update customized UI config for the logged in user.
   * @param params The `UserService.UpdateUserUiConfigParams` containing the following parameters:
   *
   * - `body`: Specifies the user UI config.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateUserUiConfigResponse(params: UserService.UpdateUserUiConfigParams): __Observable<__StrictHttpResponse<UserUiConfig>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/users/ui-config`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserUiConfig>;
      })
    );
  }
  /**
   * Update user UI config.
   *
   * Update customized UI config for the logged in user.
   * @param params The `UserService.UpdateUserUiConfigParams` containing the following parameters:
   *
   * - `body`: Specifies the user UI config.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateUserUiConfig(params: UserService.UpdateUserUiConfigParams): __Observable<UserUiConfig> {
    return this.UpdateUserUiConfigResponse(params).pipe(
      __map(_r => _r.body as UserUiConfig)
    );
  }

  /**
   * Get User by SID.
   *
   * Get User by SID.
   * @param params The `UserService.GetUserBySIDParams` containing the following parameters:
   *
   * - `sid`: Specify the SID of the user.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetUserBySIDResponse(params: UserService.GetUserBySIDParams): __Observable<__StrictHttpResponse<UserParams>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/users/${encodeURIComponent(params.sid)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserParams>;
      })
    );
  }
  /**
   * Get User by SID.
   *
   * Get User by SID.
   * @param params The `UserService.GetUserBySIDParams` containing the following parameters:
   *
   * - `sid`: Specify the SID of the user.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetUserBySID(params: UserService.GetUserBySIDParams): __Observable<UserParams> {
    return this.GetUserBySIDResponse(params).pipe(
      __map(_r => _r.body as UserParams)
    );
  }

  /**
   * Update User information.
   *
   * Update an existing user on the Cohesity Cluster. Only user settings on the Cohesity Cluster are updated. No changes are made to the referenced user principal on the Active Directory/IdP.
   * @param params The `UserService.UpdateUserParams` containing the following parameters:
   *
   * - `sid`: Specify the SID of the user.
   *
   * - `body`: Specifies the user information.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateUserResponse(params: UserService.UpdateUserParams): __Observable<__StrictHttpResponse<UserParams>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/users/${encodeURIComponent(params.sid)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserParams>;
      })
    );
  }
  /**
   * Update User information.
   *
   * Update an existing user on the Cohesity Cluster. Only user settings on the Cohesity Cluster are updated. No changes are made to the referenced user principal on the Active Directory/IdP.
   * @param params The `UserService.UpdateUserParams` containing the following parameters:
   *
   * - `sid`: Specify the SID of the user.
   *
   * - `body`: Specifies the user information.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateUser(params: UserService.UpdateUserParams): __Observable<UserParams> {
    return this.UpdateUserResponse(params).pipe(
      __map(_r => _r.body as UserParams)
    );
  }

  /**
   * Delete a Cohesity (LOCAL/IdP/AD) user.
   *
   * Delete a Cohesity user.
   * @param params The `UserService.DeleteUserParams` containing the following parameters:
   *
   * - `sid`: Specify the SID of the user.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteUserResponse(params: UserService.DeleteUserParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/users/${encodeURIComponent(params.sid)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete a Cohesity (LOCAL/IdP/AD) user.
   *
   * Delete a Cohesity user.
   * @param params The `UserService.DeleteUserParams` containing the following parameters:
   *
   * - `sid`: Specify the SID of the user.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteUser(params: UserService.DeleteUserParams): __Observable<null> {
    return this.DeleteUserResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Reset S3 secret access key
   *
   * Reset the S3 secret access key for the specified user on the Cohesity Cluster. Admin users who have the Manage Users privilege can generate keys for other users. When generating a new key, anyone using the old key will lose access until they retrieve and use the newly generated key. The user must have the following privilege to access this endpoint, 'Manage S3 Keys'.
   * @param params The `UserService.RegenerateS3KeyParams` containing the following parameters:
   *
   * - `sid`: Specify the SID of the user.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  RegenerateS3KeyResponse(params: UserService.RegenerateS3KeyParams): __Observable<__StrictHttpResponse<SecretKeyEntity>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/users/${encodeURIComponent(params.sid)}/s3-secret-key`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SecretKeyEntity>;
      })
    );
  }
  /**
   * Reset S3 secret access key
   *
   * Reset the S3 secret access key for the specified user on the Cohesity Cluster. Admin users who have the Manage Users privilege can generate keys for other users. When generating a new key, anyone using the old key will lose access until they retrieve and use the newly generated key. The user must have the following privilege to access this endpoint, 'Manage S3 Keys'.
   * @param params The `UserService.RegenerateS3KeyParams` containing the following parameters:
   *
   * - `sid`: Specify the SID of the user.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  RegenerateS3Key(params: UserService.RegenerateS3KeyParams): __Observable<SecretKeyEntity> {
    return this.RegenerateS3KeyResponse(params).pipe(
      __map(_r => _r.body as SecretKeyEntity)
    );
  }

  /**
   * Get the list of API keys owned by the user.
   *
   * Returns the list of API keys owned by the user. For security reasons there is no way to retrieve the key itself after it's created.
   * @param params The `UserService.GetUserAPIKeysParams` containing the following parameters:
   *
   * - `userSid`: Specify the SID of the API key owner.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `isExpired`: If true, the response will only include API keys which has been expired. Returns all keys if the query param is not set.
   *
   * - `isActive`: If true, the response will only include API keys which are active. Returns all keys if the query param is not set.
   *
   * - `ids`: Filter by API Key Ids
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetUserAPIKeysResponse(params: UserService.GetUserAPIKeysParams): __Observable<__StrictHttpResponse<UserAPIKeys>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.isExpired != null) __params = __params.set('isExpired', params.isExpired.toString());
    if (params.isActive != null) __params = __params.set('isActive', params.isActive.toString());
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/users/${encodeURIComponent(params.userSid)}/api-keys`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserAPIKeys>;
      })
    );
  }
  /**
   * Get the list of API keys owned by the user.
   *
   * Returns the list of API keys owned by the user. For security reasons there is no way to retrieve the key itself after it's created.
   * @param params The `UserService.GetUserAPIKeysParams` containing the following parameters:
   *
   * - `userSid`: Specify the SID of the API key owner.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `isExpired`: If true, the response will only include API keys which has been expired. Returns all keys if the query param is not set.
   *
   * - `isActive`: If true, the response will only include API keys which are active. Returns all keys if the query param is not set.
   *
   * - `ids`: Filter by API Key Ids
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetUserAPIKeys(params: UserService.GetUserAPIKeysParams): __Observable<UserAPIKeys> {
    return this.GetUserAPIKeysResponse(params).pipe(
      __map(_r => _r.body as UserAPIKeys)
    );
  }

  /**
   * Create a new user API key.
   *
   * Create a new user API key.
   * @param params The `UserService.CreateUserAPIKeyParams` containing the following parameters:
   *
   * - `userSid`: Specify the SID of the API key owner.
   *
   * - `body`: Request to create a new API Key
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateUserAPIKeyResponse(params: UserService.CreateUserAPIKeyParams): __Observable<__StrictHttpResponse<CreatedUserAPIKey>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/users/${encodeURIComponent(params.userSid)}/api-keys`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CreatedUserAPIKey>;
      })
    );
  }
  /**
   * Create a new user API key.
   *
   * Create a new user API key.
   * @param params The `UserService.CreateUserAPIKeyParams` containing the following parameters:
   *
   * - `userSid`: Specify the SID of the API key owner.
   *
   * - `body`: Request to create a new API Key
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateUserAPIKey(params: UserService.CreateUserAPIKeyParams): __Observable<CreatedUserAPIKey> {
    return this.CreateUserAPIKeyResponse(params).pipe(
      __map(_r => _r.body as CreatedUserAPIKey)
    );
  }

  /**
   * Get the API key by id.
   *
   * Get the API key by id.
   * @param params The `UserService.GetUserAPIKeyByIdParams` containing the following parameters:
   *
   * - `userSid`: Specify the SID of the API key owner.
   *
   * - `id`: Specify the id of the API key.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetUserAPIKeyByIdResponse(params: UserService.GetUserAPIKeyByIdParams): __Observable<__StrictHttpResponse<UserAPIKey>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/users/${encodeURIComponent(params.userSid)}/api-keys/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserAPIKey>;
      })
    );
  }
  /**
   * Get the API key by id.
   *
   * Get the API key by id.
   * @param params The `UserService.GetUserAPIKeyByIdParams` containing the following parameters:
   *
   * - `userSid`: Specify the SID of the API key owner.
   *
   * - `id`: Specify the id of the API key.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetUserAPIKeyById(params: UserService.GetUserAPIKeyByIdParams): __Observable<UserAPIKey> {
    return this.GetUserAPIKeyByIdResponse(params).pipe(
      __map(_r => _r.body as UserAPIKey)
    );
  }

  /**
   * Update a user API key.
   *
   * Update a user API key.
   * @param params The `UserService.UpdateUserAPIKeyByIdParams` containing the following parameters:
   *
   * - `userSid`: Specify the SID of the API key owner.
   *
   * - `id`: Specify the id of the API key.
   *
   * - `body`: Request to update a user API key
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateUserAPIKeyByIdResponse(params: UserService.UpdateUserAPIKeyByIdParams): __Observable<__StrictHttpResponse<UserAPIKey>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/users/${encodeURIComponent(params.userSid)}/api-keys/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserAPIKey>;
      })
    );
  }
  /**
   * Update a user API key.
   *
   * Update a user API key.
   * @param params The `UserService.UpdateUserAPIKeyByIdParams` containing the following parameters:
   *
   * - `userSid`: Specify the SID of the API key owner.
   *
   * - `id`: Specify the id of the API key.
   *
   * - `body`: Request to update a user API key
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateUserAPIKeyById(params: UserService.UpdateUserAPIKeyByIdParams): __Observable<UserAPIKey> {
    return this.UpdateUserAPIKeyByIdResponse(params).pipe(
      __map(_r => _r.body as UserAPIKey)
    );
  }

  /**
   * Delete a user API key.
   *
   * Delete a user API key.
   * @param params The `UserService.DeleteUserAPIKeyByIdParams` containing the following parameters:
   *
   * - `userSid`: Specify the SID of the API key owner.
   *
   * - `id`: Specify the id of the API key.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteUserAPIKeyByIdResponse(params: UserService.DeleteUserAPIKeyByIdParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/users/${encodeURIComponent(params.userSid)}/api-keys/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete a user API key.
   *
   * Delete a user API key.
   * @param params The `UserService.DeleteUserAPIKeyByIdParams` containing the following parameters:
   *
   * - `userSid`: Specify the SID of the API key owner.
   *
   * - `id`: Specify the id of the API key.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteUserAPIKeyById(params: UserService.DeleteUserAPIKeyByIdParams): __Observable<null> {
    return this.DeleteUserAPIKeyByIdResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Refresh an existing user API key.
   *
   * Refresh an existing user API key.
   * @param params The `UserService.RotateUserAPIKeyParams` containing the following parameters:
   *
   * - `userSid`: Specify the SID of the API key owner.
   *
   * - `id`: Specify the id of the API key.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  RotateUserAPIKeyResponse(params: UserService.RotateUserAPIKeyParams): __Observable<__StrictHttpResponse<CreatedUserAPIKey>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/users/${encodeURIComponent(params.userSid)}/api-keys/${encodeURIComponent(params.id)}/rotate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CreatedUserAPIKey>;
      })
    );
  }
  /**
   * Refresh an existing user API key.
   *
   * Refresh an existing user API key.
   * @param params The `UserService.RotateUserAPIKeyParams` containing the following parameters:
   *
   * - `userSid`: Specify the SID of the API key owner.
   *
   * - `id`: Specify the id of the API key.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  RotateUserAPIKey(params: UserService.RotateUserAPIKeyParams): __Observable<CreatedUserAPIKey> {
    return this.RotateUserAPIKeyResponse(params).pipe(
      __map(_r => _r.body as CreatedUserAPIKey)
    );
  }
}

module UserService {

  /**
   * Parameters for GetAllAPIKeys
   */
  export interface GetAllAPIKeysParams {

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Filter by list of owner (user) SIDs
     */
    ownerSids?: Array<string>;

    /**
     * If true, the response will only include API keys which has been expired. Returns all keys if the query param is not set.
     */
    isExpired?: boolean;

    /**
     * If true, the response will only include API keys which are active. Returns all keys if the query param is not set.
     */
    isActive?: boolean;

    /**
     * Filter by API Key Ids
     */
    ids?: Array<string>;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetGroups
   */
  export interface GetGroupsParams {

    /**
     * TenantIds contains ids of the tenants for which groups are to be returned.
     */
    tenantIds?: Array<string>;

    /**
     * Specifies a list of sids to filter.
     */
    sids?: Array<string>;

    /**
     * Specifies a list of roles to filter.
     */
    roles?: Array<string>;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Specifies a list of group names to filter.
     */
    names?: Array<string>;

    /**
     * If true, the names in group names are matched by any partial rather than exactly matched. Default is false.
     */
    matchPartialNames?: boolean;

    /**
     * IncludeTenants specifies if groups of all the tenants under the hierarchy of the logged in user's organization should be returned.
     */
    includeTenants?: boolean;

    /**
     * Specifies the group domain to filter.
     */
    domain?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CreateGroup
   */
  export interface CreateGroupParams {

    /**
     * Specifies the new group parameters.
     */
    body: CreateGroupsParams;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for DeleteGroups
   */
  export interface DeleteGroupsParams {

    /**
     * Specifies the delete request payload.
     */
    body: DeleteGroupsRequest;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetGroupBySID
   */
  export interface GetGroupBySIDParams {

    /**
     * Specify the SID of the group.
     */
    sid: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateGroup
   */
  export interface UpdateGroupParams {

    /**
     * Specify the SID of the group.
     */
    sid: string;

    /**
     * Specifies the group information.
     */
    body: UpdateGroupParameters;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for DeleteGroup
   */
  export interface DeleteGroupParams {

    /**
     * Specify the SID of the group.
     */
    sid: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetTenantAccess
   */
  export interface GetTenantAccessParams {

    /**
     * List of Tenant Ids to filter from.
     */
    tenantIds?: Array<null | string>;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * By Default, only valid tenant access are returned. If set to true,
     * inactive or ineffective or expired tenant access would be returned
     * as well.
     */
    includeInactive?: null | boolean;
  }

  /**
   * Parameters for GetSecurityPrincipals
   */
  export interface GetSecurityPrincipalsParams {

    /**
     * Specifies a list of SIDs.
     */
    sids: Array<string>;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetPrincipalSources
   */
  export interface GetPrincipalSourcesParams {

    /**
     * Specify the SID of the principal.
     */
    sid: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdatePrincipalSources
   */
  export interface UpdatePrincipalSourcesParams {

    /**
     * Specify the SID of the principal.
     */
    sid: string;

    /**
     * Specify the sources to be assigned to a principal.
     */
    body: AssignedSources;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetUsers
   */
  export interface GetUsersParams {

    /**
     * Specifies a list of usernames to filter.
     */
    usernames?: Array<string>;

    /**
     * TenantIds contains ids of the tenants for which users are to be returned.
     */
    tenantIds?: Array<string>;

    /**
     * Specifies a list of sids to filter.
     */
    sids?: Array<string>;

    /**
     * Specifies a list of roles to filter.
     */
    roles?: Array<string>;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * If true, the names in usernames are matched by any partial rather than exactly matched.
     */
    matchPartialNames?: boolean;

    /**
     * IncludeTenants specifies if users of all the tenants under the hierarchy of the logged in user's organization should be returned.
     */
    includeTenants?: boolean;

    /**
     * Specifies a list of email addresses to filter.
     */
    emailAddresses?: Array<string>;

    /**
     * Specifies the user domain to filter.
     */
    domain?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CreateUsers
   */
  export interface CreateUsersParams {

    /**
     * If an Active Directory or an IdP domain is specified, a new user is added to the Cohesity Cluster against the specified Active Directory/IdP user principal. If the LOCAL domain is specified, a new user is created directly in the default LOCAL domain on the Cohesity Cluster.
     */
    body: CreateUsersParameters;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for DeleteUsers
   */
  export interface DeleteUsersParams {

    /**
     * If the Cohesity user was created against an Active Directory/IdP user, the referenced principal user on the Active Directory/IdP domain is NOT deleted. Only the user on the Cohesity Cluster is deleted.
     */
    body: DeleteUsersRequest;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetActiveSessionsCount
   */
  export interface GetActiveSessionsCountParams {

    /**
     * Filter sessions based on user sids.
     */
    sids?: Array<string>;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CreateSession
   */
  export interface CreateSessionParams {

    /**
     * Specifies the parameters to create a user session
     */
    body: CreateUserSessionRequestParams;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for DeleteSession
   */
  export interface DeleteSessionParams {

    /**
     * Specifies a user sid. If sid is not given system wide sessions are deleted.
     */
    sid?: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateUserUiConfig
   */
  export interface UpdateUserUiConfigParams {

    /**
     * Specifies the user UI config.
     */
    body: UserUiConfig;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetUserBySID
   */
  export interface GetUserBySIDParams {

    /**
     * Specify the SID of the user.
     */
    sid: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateUser
   */
  export interface UpdateUserParams {

    /**
     * Specify the SID of the user.
     */
    sid: string;

    /**
     * Specifies the user information.
     */
    body: UpdateUserParameters;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for DeleteUser
   */
  export interface DeleteUserParams {

    /**
     * Specify the SID of the user.
     */
    sid: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for RegenerateS3Key
   */
  export interface RegenerateS3KeyParams {

    /**
     * Specify the SID of the user.
     */
    sid: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetUserAPIKeys
   */
  export interface GetUserAPIKeysParams {

    /**
     * Specify the SID of the API key owner.
     */
    userSid: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * If true, the response will only include API keys which has been expired. Returns all keys if the query param is not set.
     */
    isExpired?: boolean;

    /**
     * If true, the response will only include API keys which are active. Returns all keys if the query param is not set.
     */
    isActive?: boolean;

    /**
     * Filter by API Key Ids
     */
    ids?: Array<string>;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CreateUserAPIKey
   */
  export interface CreateUserAPIKeyParams {

    /**
     * Specify the SID of the API key owner.
     */
    userSid: string;

    /**
     * Request to create a new API Key
     */
    body: CreateOrUpdateAPIKeyRequest;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetUserAPIKeyById
   */
  export interface GetUserAPIKeyByIdParams {

    /**
     * Specify the SID of the API key owner.
     */
    userSid: string;

    /**
     * Specify the id of the API key.
     */
    id: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateUserAPIKeyById
   */
  export interface UpdateUserAPIKeyByIdParams {

    /**
     * Specify the SID of the API key owner.
     */
    userSid: string;

    /**
     * Specify the id of the API key.
     */
    id: string;

    /**
     * Request to update a user API key
     */
    body: CreateOrUpdateAPIKeyRequest;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for DeleteUserAPIKeyById
   */
  export interface DeleteUserAPIKeyByIdParams {

    /**
     * Specify the SID of the API key owner.
     */
    userSid: string;

    /**
     * Specify the id of the API key.
     */
    id: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for RotateUserAPIKey
   */
  export interface RotateUserAPIKeyParams {

    /**
     * Specify the SID of the API key owner.
     */
    userSid: string;

    /**
     * Specify the id of the API key.
     */
    id: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { UserService }
