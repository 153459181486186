import { TagParams } from '@cohesity/api/v1';
import {
  AndOrExpContext,
  AttributesContext,
  AveLexer,
  AveParser,
  AveVisitor,
  ComparisonOperatorContext,
  ExprContext,
  LogicalOperatorContext,
  MultiArgContext,
  MultiArgsExpContext,
  ParenExpContext,
  ParseContext,
  RangeOperatorContext,
  SingleArgContext,
  SingleArgExpContext,
} from '@cohesity/grammar/aws-volume-exclusions';
import {
  CursorLocation,
  getLeafNodes,
  getNodeLocation,
  getParent,
  getResults,
  getSelectedNode,
  GrammarOpts,
  Result,
  Suggestion,
  SuggestionType,
} from '@cohesity/helix';
import { CharStreams, CommonTokenStream, ParserRuleContext, Vocabulary } from 'antlr4ts';
import { AbstractParseTreeVisitor, ErrorNode, TerminalNode } from 'antlr4ts/tree';

import { AveTagParamsVisitor } from './ave-tag-params-visitor';

/**
 * The map of precomputed search suggestion.
 */
export type SuggestionMap = Record<
 | 'comparisonOperator'
 | 'attributes'
 | 'logicalOperator'
 | 'multiArg'
 | 'operator'
 | 'singleArg'
 | 'rangeOperator',
 Suggestion
>;

/**
 * The possible value for open params.
 */
export const openParenResult: Result = { label: 'OPEN_PAREN', value: '(' };

 /**
  * The possible value for close params.
  */
export const closeParenResult: Result = { label: 'CLOSE_PAREN', value: ')' };

 /**
  * The possible value for comma.
  */
export const commaResult: Result = { label: 'COMMA', value: ',' };

/**
 * The AVE grammar suggestion visitor.
 * All the example comments given follow the pattern below:
 * 1. '|' represents the cursor location in the current input.
 * 2. The string represnts the current input given by the user.
 * On the basis of the cursor position and given input, we return appropriate suggestions.
 *
 * Eg. 1
 * example "uniqu|eTag = 'Cohesity-1' "
 * This represents that the user has typed this string in the input: uniqueTag = 'Cohesity-1'.
 * And they have placed the cursor at position 6 => uniqu|eTag = 'Cohesity-1'.
 * So we return the suggestions for the keys.
 *
 * Eg. 2
 * example "uniqueTag = 'Cohesity-1' AND squad IN ( 'nimbus', 'cirrus' )"
 * This represents that the user has typed this string in the input:
 * uniqueTag = 'Cohesity-1' AND squad IN ( 'nimbus', 'cirrus' ).
 * And they have placed the cursor at position 12 => uniqueTag =| 'Cohesity-1' AND squad IN ( 'nimbus', 'cirrus' ).
 * So we return the suggestions for the operator.
 */
export class AveSuggestionVisitor extends AbstractParseTreeVisitor<Suggestion> implements AveVisitor<Suggestion> {
  /**
   * Get the suggestion for AttributesContext.
   *
   * @param  type The suggestion type.
   * @param  node The node.
   * @return      The suggestion for AttributesContext.
   */
  static getAttributes(type: SuggestionType, node?: ParserRuleContext): Suggestion {
    // For empty search query, node will absent.

    // For all cases below, we return this suggestion.
    // example "|"
    // example "    |    "
    // example "        |"
    // example "|        "
    // example "|      uniqueTag = 'Cohesity-1'"
    // example "   |   uniqueTag = 'Cohesity-1'"
    // example "      |uniqueTag = 'Cohesity-1'"
    // example "      visib|ility = 'Cohesity-1'"
    // example "      uniqueTag| = 'Cohesity-1'"
    // example "      uniqueTag|     = 'Cohesity-1'"
    // example "uniqueTag| = 'Cohesity-1' "
    // example "uniqu|eTag = 'Cohesity-1' "
    // example "|uniqueTag = 'Cohesity-1' "
    // example "uniqueTag = 'Cohesity-1' AND name = 'admin'|"
    // example "uniqueTag = 'Cohesity-1' AND name| = 'admin'"
    // example "uniqueTag = 'Cohesity-1' AND n|ame = 'admin'"
    // example "uniqueTag = 'Cohesity-1' AND name = 'admin'"
    // example "uniqueTag = 'Cohesity-1' AND   |   viewer.name = 'admin'"
    return {
      results: [openParenResult, ...precomputedSuggestion.attributes.results],
      type,

      // the selected node text which is used to highlight & filter the options.
      text: node?.text || '',

      // when text location is empty then above text will be added at the end of the search query.
      textLocation: node ? getNodeLocation(node) : null,
      field: 'key',
    };
  }

  /**
   * Get the suggestion for LogicalOperatorContext.
   *
   * @param  type The suggestion type.
   * @param  node The node.
   * @return      The suggestion for LogicalOperatorContext.
   */
  static getLogicalOperator(type: SuggestionType, node?: ParserRuleContext): Suggestion {
    // Node will not be there for a valid expression where user tries to add one more condition.
    // example "uniqueTag = 'Cohesity-1' |"
    // example "uniqueTag = 'Cohesity-1'  |  "
    // example "uniqueTag = 'Cohesity-1'     |"
    return {
      ...precomputedSuggestion.logicalOperator,
      type,
      text: node?.text || '',
      textLocation: node ? getNodeLocation(node) : null,
    };
  }

  /**
   * Get the suggestion for ComparisonOperatorContext.
   *
   * @param  type The suggestion type.
   * @param  node The node.
   * @return      The suggestion for ComparisonOperatorContext.
   */
  static getComparisonOperator(type: SuggestionType, node: ParserRuleContext): Suggestion {
    // example "uniqueTag =| Cohesity-1 "
    // example "uniqueTag |= Cohesity-1 "
    // example "uniqueTag   |   = Cohesity-1 "
    return {
      ...precomputedSuggestion.comparisonOperator,
      type,
      text: node.text,
      textLocation: getNodeLocation(node),
    };
  }

  /**
   * Get the suggestion for RangeOperatorContext.
   *
   * @param  type The suggestion type.
   * @param  node The node.
   * @return      The suggestion for RangeOperatorContext.
   */
  static getRangeOperator(type: SuggestionType, node: ParserRuleContext): Suggestion {
    // example "uniqueTag   |   IN  'Cohesity-1' "
    // example "uniqueTag      |IN  'Cohesity-1' "
    // example "uniqueTag      I|N  'Cohesity-1' "
    // example "uniqueTag       IN| 'Cohesity-1' "
    return {
      results: [
        ...precomputedSuggestion.rangeOperator.results
      ],
      type,
      text: node ? (node.text === 'NOT' ? 'NOT IN' : node.text) : '',
      textLocation: getNodeLocation(node),
    };
  }

  /**
   * Get the suggestion for OperatorContext.
   *
   * @param  type The suggestion type.
   * @param  node The node.
   * @return      The suggestion for RangeOperatorContext.
   */
  static getOperator(type: SuggestionType, node: ParserRuleContext): Suggestion {
    // example "uniqueTag   |   IN  'Cohesity-1' "
    // example "uniqueTag      |IN  'Cohesity-1' "
    // example "uniqueTag      I|N  'Cohesity-1' "
    // example "uniqueTag       IN| 'Cohesity-1' "
    return {
      results: [
        ...precomputedSuggestion.comparisonOperator.results,
        ...precomputedSuggestion.rangeOperator.results
      ],
      type,
      text: node ? (node.text === 'NOT' ? 'NOT IN' : node.text) : '',
      textLocation: getNodeLocation(node),
    };
  }

  /**
   * Get the suggestion for SingleArgContext.
   *
   * @param  type          The suggestion type.
   * @param  node          The node.
   * @param  attributesCtx The attributes node context.
   * @return               The suggestion for StrArgContext.
   */
  static getSingleArg(type: SuggestionType, node: ParserRuleContext, attributesCtx: ParserRuleContext): Suggestion {
    return {
      ...precomputedSuggestion.singleArg,
      type,
      text: node.text,
      textLocation: getNodeLocation(node),
      field: attributesCtx.text,
    };
  }

  /**
   * Get the suggestion for MultiArgContext.
   *
   * @param  type   The suggestion type.
   * @param  node   The node.
   * @param  parent The parent.
   * @return        The suggestion for MultiStrContext.
   */
  static getMultiArg(type: SuggestionType, node: ParserRuleContext, parent: MultiArgContext): Suggestion {
    const commaNodes = parent.COMMA();

    if (node instanceof TerminalNode && commaNodes.indexOf(node) !== -1) {
      return {
        results: [
          commaResult,
          parent.exception ? closeParenResult : null,
        ].filter(Boolean),
        type,
        text: node?.text,
        textLocation: getNodeLocation(node),
      };
    }

    return {
      ...precomputedSuggestion.multiArg,
      type,
      text: node.text,
      textLocation: getNodeLocation(node),
    };
  }

  constructor(private searchQuery: string, private cursorLocation: CursorLocation, private vocabulary: Vocabulary,
    private hasException: boolean) {
    super();
  }

  /**
   * Return the default suggestion.
   */
  defaultResult(): Suggestion {
    return null;
  }

  /**
   * Return the aggregate suggestion.
   *
   * @param aggregate The current suggestion.
   * @param nextResult The next suggestion.
   * @returns Return the aggregate suggestion.
   */
  aggregateResult(aggregate: Suggestion, nextResult: Suggestion): Suggestion {
    return nextResult ?? aggregate;
  }

  /**
   * Visit the AST parse node.
   *
   * @param ctx The parser AST node.
   * @returns The search suggestion.
   */
  visitParse(ctx: ParseContext): Suggestion {
    const isEmpty = !(this.searchQuery || '').trim().length;
    if (isEmpty) {
      // example "|"
      // example "    |    "
      // example "        |"
      // example "|        "
      return AveSuggestionVisitor.getAttributes(SuggestionType.success);
    }

    const leafNodes = getLeafNodes(ctx);
    const selected = getSelectedNode(leafNodes, this.cursorLocation);
    const parent = selected?.parent;
    const exceptionNode = ctx?.exception?.context as ParserRuleContext;
    const type = this.hasException ? SuggestionType.error : SuggestionType.success;
    const exprNode = getParent(selected, [
      SingleArgExpContext, MultiArgsExpContext, ParenExpContext, AndOrExpContext,
    ]) as SingleArgExpContext | MultiArgsExpContext | ParenExpContext | AndOrExpContext;

    if (this.hasException) {
      switch (true as boolean) {
        case parent instanceof AttributesContext: {
          return AveSuggestionVisitor.getAttributes(type, selected);
        }

        case parent instanceof LogicalOperatorContext: {
          return AveSuggestionVisitor.getLogicalOperator(type, selected);
        }

        case parent instanceof ComparisonOperatorContext: {
          return AveSuggestionVisitor.getOperator(type, selected);
        }

        case parent instanceof RangeOperatorContext: {
          return AveSuggestionVisitor.getOperator(type, selected);
        }

        case parent instanceof SingleArgContext: {
          const argExpCtx = exprNode as
            | SingleArgExpContext
            | MultiArgsExpContext;
          const attributesCtx = argExpCtx.attributes();

          return AveSuggestionVisitor.getSingleArg(type, selected, attributesCtx);
        }

        case parent instanceof MultiArgContext: {
          if (selected instanceof SingleArgContext) {
            const argExpCtx = exprNode as
              | SingleArgExpContext
              | MultiArgsExpContext;
            const attributesCtx = argExpCtx.attributes();

            return AveSuggestionVisitor.getSingleArg(type, selected, attributesCtx);
          }

          return AveSuggestionVisitor.getMultiArg(
            type,
            selected,
            parent as MultiArgContext
          );
        }

        case parent instanceof SingleArgExpContext &&
          selected instanceof SingleArgContext: {
            const singleArgExpCtx = parent as SingleArgExpContext;
            const attributesCtx = singleArgExpCtx.attributes();

            return {
              results: [...precomputedSuggestion.singleArg.results],
              type,
              text: selected?.text,
              textLocation: getNodeLocation(selected),
              field: attributesCtx.text,
            };
          }

        case parent instanceof MultiArgsExpContext &&
          selected instanceof TerminalNode: {
            return {
              results: [openParenResult],
              type,
              text: selected?.text,
              textLocation: getNodeLocation(selected),
            };
          }

        case parent instanceof MultiArgsExpContext &&
          selected instanceof MultiArgContext: {
            const multiArgExpCtx = parent as MultiArgsExpContext;
            const attributesCtx = multiArgExpCtx.attributes();

            return {
              ...precomputedSuggestion.multiArg,
              type,
              text: selected?.text,
              textLocation: getNodeLocation(selected),
              field: attributesCtx.text,
            };
          }

        case parent instanceof AndOrExpContext &&
          exceptionNode instanceof ExprContext: {
            const attributesSuggestion = AveSuggestionVisitor.getAttributes(type, selected);

            return {
              ...attributesSuggestion,
              results: [...attributesSuggestion.results],
            };
          }

        case parent instanceof ParenExpContext &&
          selected instanceof ExprContext: {
            return AveSuggestionVisitor.getAttributes(type, selected);
          }

        case parent instanceof ParenExpContext: {
          const selectedNodeIndex = parent.children.findIndex(
            (child) => child === selected
          );

          if (selectedNodeIndex === 0) {
            return {
              results: [openParenResult],
              type,
              text: selected.text,
              textLocation: getNodeLocation(selected),
            };
          }
          break;
        }
        case parent instanceof ExprContext &&
          (exceptionNode instanceof ParenExpContext): {
            // example "|    uniqueTag"
            // example "  |  uniqueTag"
            // example "     |uniqueTag"
            // example "|uniqueTag"
            // example "uniqueTag| "
            // example "unique|Tag "
            // example "uniqueTag = 'Cohesity-1' OR v|"
            // example "uniqueTag = 'Cohesity-1' OR |uniqueTag"
            // example "uniqueTag = 'Cohesity-1' OR unique|Tag"
            // example "uniqueTag = 'Cohesity-1' OR uniqueTag|"

            // example "uniqueTag = 'Cohesity-1' AND ( uniqueTag = 'Cohesity-2'  OR uniqueTag|"
            return AveSuggestionVisitor.getAttributes(type, selected);
          }

        case parent instanceof ExprContext &&
          exceptionNode instanceof ExprContext &&
          selected instanceof ErrorNode: {
            if (exceptionNode.childCount === 1) {
              return AveSuggestionVisitor.getAttributes(type, selected);
            }
            return {
              results: [
                ...precomputedSuggestion.comparisonOperator.results,
                ...precomputedSuggestion.rangeOperator.results
              ],
              type,
              text: selected?.text || '',
              textLocation: null,
            };
          }

        case parent instanceof ParseContext &&
          exceptionNode instanceof SingleArgExpContext: {
            return AveSuggestionVisitor.getLogicalOperator(type, selected);
          }

        case parent instanceof ParseContext &&
          exceptionNode instanceof ParenExpContext: {
            const parensCtx = exceptionNode as ParenExpContext;
            const expressionCtx = parensCtx.expr();
            const exceptionChild = expressionCtx.exception?.context;

            // tag = Cohesity AND ( squad = cirrus AND ( visibility NOT IN ( devs ) |
            // tag = Cohesity AND ( squad = cirrus AND ( visibility NOT IN ( devs ) AND env = test |
            // tag = Cohesity OR ( ( squad IN ( cirrus ) AND env |
            if (exceptionChild instanceof MultiArgsExpContext) {
              // example "visibility = 'domain' AND ( visibility = 'contractors'  OR visibility IN ( 'contractors' |"
              if (exceptionChild.childCount === 5) {
                return {
                  results: [commaResult, closeParenResult],
                  type,
                  text: '',
                  textLocation: null,
                };
              }

              // example "uniqueTag = 'Cohesity-1' AND ( uniqueTag IN |"
              // example "uniqueTag = 'Cohesity-1' AND ( uniqueTag IN   |   "
              // example "uniqueTag = 'Cohesity-1' AND ( uniqueTag IN      |"
              // example "uniqueTag = 'Cohesity-1' AND ( uniqueTag NOT IN |     "
              // example "uniqueTag = 'Cohesity-1' AND ( uniqueTag NOT IN   |   "
              // example "uniqueTag = 'Cohesity-1' AND ( uniqueTag NOT IN      |"
              // example "uniqueTag = 'Cohesity-1' AND ( uniqueTag IN |("
              // example "uniqueTag = 'Cohesity-1' AND ( uniqueTag IN (|"
              return {
                results: [openParenResult],
                type,
                text: '',
                textLocation: null,
              };
            } else if (exceptionChild instanceof ParenExpContext &&
                exceptionChild.expr() instanceof SingleArgExpContext) {
              const suggestion = AveSuggestionVisitor.getLogicalOperator(type);
              return {
                ...suggestion,
                results: [...suggestion.results, closeParenResult],
              };
            } else if (exceptionChild instanceof ParenExpContext &&
                exceptionChild.expr() instanceof MultiArgsExpContext) {
              if (exceptionChild.expr().childCount >= 5) {
                if (exceptionChild.expr().children[exceptionChild.expr().children.length - 1] instanceof ErrorNode) {
                  return {
                    results: [commaResult, closeParenResult],
                    type,
                    text: '',
                    textLocation: null,
                  };
                }
                return {
                  results: [...precomputedSuggestion.logicalOperator.results],
                  type,
                  text: '',
                  textLocation: null,
                };
              }
              return {
                results: [openParenResult],
                type,
                text: '',
                textLocation: null,
              };
            } else if (exceptionChild instanceof ParenExpContext &&
                exceptionChild.expr() instanceof AndOrExpContext) {
              if (exceptionChild.expr().childCount >= 3) {
                if (exceptionChild.expr().children[2] instanceof SingleArgExpContext) {
                  return {
                    results: [
                      ...precomputedSuggestion.logicalOperator.results,
                      closeParenResult,
                    ],
                    type,
                    text: '',
                    textLocation: null,
                  };
                } else if (exceptionChild.expr().children[2] instanceof MultiArgsExpContext) {
                  if (exceptionChild.expr().children[2].childCount >= 5) {
                    return {
                      results: [closeParenResult, commaResult],
                      type,
                      text: '',
                      textLocation: null,
                    };
                  }
                  return {
                    results: [openParenResult],
                    type,
                    text: '',
                    textLocation: null,
                  };
                }
                return {
                  results: [
                    ...precomputedSuggestion.comparisonOperator.results,
                    ...precomputedSuggestion.rangeOperator.results,
                  ],
                  type,
                  text: selected.text ?? '',
                  textLocation: null,
                };
              }
              return {
                results: [openParenResult],
                type,
                text: '',
                textLocation: null,
              };
            } else if (exceptionChild instanceof ExprContext) {
              const hasIncompleteMultiCharOperator =
                exceptionChild.childCount === 2;

              return {
                results: [
                  ...precomputedSuggestion.comparisonOperator.results,
                  ...precomputedSuggestion.rangeOperator.results
                ],
                type,
                text: hasIncompleteMultiCharOperator ? selected.text : '',
                textLocation: null,
              };
            }

            const logicalOperatorSuggestion =
              AveSuggestionVisitor.getLogicalOperator(type);

            return {
              ...logicalOperatorSuggestion,
              results: [...logicalOperatorSuggestion.results, closeParenResult],
            };
          }

        case parent instanceof ParseContext &&
          exceptionNode instanceof MultiArgsExpContext: {
            // example "uniqueTag IN ( 'Cohesity-1' |"
            if (exceptionNode.childCount === 5) {
              return {
                results: [commaResult, closeParenResult],
                type,
                text: '',
                textLocation: null,
              };
            }

            // example "uniqueTag IN |"
            // example "uniqueTag IN   |   "
            // example "uniqueTag IN      |"
            // example "uniqueTag NOT IN |     "
            // example "uniqueTag NOT IN   |   "
            // example "uniqueTag NOT IN      |"
            // example "uniqueTag IN |("
            // example "uniqueTag IN (|"
            return {
              results: [openParenResult],
              type,
              text: '',
              textLocation: null,
            };
          }

        case parent instanceof ParseContext &&
          exceptionNode instanceof ExprContext: {
            const hasIncompleteMultiCharOperator = exceptionNode.childCount === 2;

            return {
              results: [
                ...precomputedSuggestion.comparisonOperator.results,
                ...precomputedSuggestion.rangeOperator.results
              ],
              type,
              text: hasIncompleteMultiCharOperator ? selected.text : '',
              textLocation: null,
            };
          }

        case parent instanceof ParseContext && selected instanceof ErrorNode: {
          return {
            results: [...precomputedSuggestion.logicalOperator.results],
            type,
            text: selected?.text || '',
            textLocation: getNodeLocation(selected),
          };
        }

        case !parent && exceptionNode instanceof SingleArgExpContext: {
          return AveSuggestionVisitor.getLogicalOperator(type, selected);
        }
      }

      // example "uniqueTag IN ( 'Cohesity-1' # 'Cohesity-1' , 'Cohesity-2' )|"
      // Exception is empty in case the key/value matches
      // keywords : NOT, IN, etc.
      return {
        results: ctx.exception ? getResults(ctx.exception, this.vocabulary) : [],
        type,
        text: '',
        textLocation: null,
      };
    }

    switch (true as boolean) {
      case parent instanceof AttributesContext: {
        return AveSuggestionVisitor.getAttributes(type, selected);
      }

      case parent instanceof LogicalOperatorContext: {
        return AveSuggestionVisitor.getLogicalOperator(type, selected);
      }

      case parent instanceof ComparisonOperatorContext: {
        return AveSuggestionVisitor.getOperator(type, selected);
      }

      case parent instanceof RangeOperatorContext: {
        return AveSuggestionVisitor.getOperator(type, selected);
      }

      case parent instanceof SingleArgContext: {
        const argExpCtx = exprNode as SingleArgExpContext | MultiArgsExpContext;
        const attributesCtx = argExpCtx.attributes();

        return AveSuggestionVisitor.getSingleArg(type, selected, attributesCtx);
      }

      case parent instanceof MultiArgContext: {
        return AveSuggestionVisitor.getMultiArg(
          type,
          selected,
          parent as MultiArgContext
        );
      }

      case parent instanceof ParenExpContext: {
        const parensCtx = parent as ParenExpContext;

        if (
          selected instanceof TerminalNode &&
          selected === parensCtx.OPEN_PAREN()
        ) {
          return {
            results: [openParenResult],
            type,
            text: selected?.text,
            textLocation: getNodeLocation(selected),
          };
        } else if (
          selected instanceof TerminalNode &&
          selected === parensCtx.CLOSE_PAREN()
        ) {
          return {
            results: [closeParenResult],
            type,
            text: selected?.text,
            textLocation: getNodeLocation(selected),
          };
        }

        break;
      }

      case parent instanceof MultiArgsExpContext: {
        const multiArgsExpCtx = parent as MultiArgsExpContext;

        if (
          selected instanceof TerminalNode &&
          selected === multiArgsExpCtx.OPEN_PAREN()
        ) {
          return {
            results: [openParenResult],
            type,
            text: selected?.text,
            textLocation: getNodeLocation(selected),
          };
        } else if (
          selected instanceof TerminalNode &&
          selected === multiArgsExpCtx.CLOSE_PAREN()
        ) {
          return {
            results: [closeParenResult],
            type,
            text: selected?.text,
            textLocation: getNodeLocation(selected),
          };
        }

        break;
      }

      case parent instanceof ParseContext: {
        // example "uniqueTag = 'Cohesity-1' |"
        // example "uniqueTag = 'Cohesity-1'  |  "
        // example "uniqueTag = 'Cohesity-1'     |"
        return AveSuggestionVisitor.getLogicalOperator(type);
      }
    }
  }
}

/**
 * AVE grammar options for advance search.
 */
export class AveGrammarOpts extends GrammarOpts<AveLexer, AveParser, ParseContext> {
  /**
   * The AVE grammar vocabulary.
   */
  vocabulary = AveParser.VOCABULARY;

  /**
   * The Processed form of the Query.
   */
  tagParamsArray: TagParams[] = [];

  /**
   * Keeps track of the previously entered query so that the tag params visitor is
   * only triggered when the query changes and not on every cursor position change.
   */
  previousQuery = '';

  /**
   * Get the AVE grammar lexer.
   *
   * @param  searchQuery The search query.
   * @return             The grammar lexer.
   */
  lexer(searchQuery: string): AveLexer {
    const chars = CharStreams.fromString(searchQuery);
    return new AveLexer(chars);
  }

  /**
   * Get the Ave grammar parser.
   *
   * @param  lexer The grammar's lexer.
   * @return       The grammar parser.
   */
  parser(lexer: AveLexer): AveParser {
    const tokens = new CommonTokenStream(lexer);
    return new AveParser(tokens);
  }

  /**
   * Get the parsed abstract syntax tree(AST) for AVE grammar.
   *
   * @param  parser The grammar's parser.
   * @return        The parsed abstract syntax tree(AST).
   */
  tree(parser: AveParser): ParseContext {
    return parser.parse();
  }

  /**
   * Get suggestion by visiting the abstract syntax tree(AST).
   *
   * @param  searchQuery    The search query.
   * @param  cursorLocation The cursor location.
   * @param  tree           The parsed abstract syntax tree(AST).
   * @return                The suggestion.
   */
  visit(searchQuery: string, cursorLocation: CursorLocation, tree: ParseContext): Suggestion {
    // We need to check for this.getErrorMessage() in order to look for errors from lexer.
    const hasException = !!tree.exception || !!this.getErrorMessage();

    // Construct tag params array only when the query is valid and changed.
    if (this.previousQuery !== searchQuery) {
      this.tagParamsArray = [];
      if (!hasException) {
        const visitor = new AveTagParamsVisitor();
        const { tagParamsArray } = visitor.visit(tree);
        this.tagParamsArray = tagParamsArray;
      }
    }
    this.previousQuery = searchQuery;

    // Return suggestions.
    const suggestionVisitor = new AveSuggestionVisitor(searchQuery, cursorLocation, this.vocabulary, hasException);
    return suggestionVisitor.visit(tree);
  }

  /**
   * Get the error message from report by ANTLR DefaultErrorStrategy.
   *
   * @return The error message.
   */
  getErrorMessage(): string {
    return this.errorListener.message;
  }
}

/**
 * Returns the map of pre computed suggestions (possible value formats).
 */
export const precomputedSuggestion: Partial<SuggestionMap> = (() => {
  const aveGrammarOpts = new AveGrammarOpts();
  const parser = aveGrammarOpts.getParser('');
  const extractSuggestion = (node: ParserRuleContext): Suggestion => ({
    type: null,
    text: null,
    results: getResults(node.exception, aveGrammarOpts.vocabulary),
  });

  const out: Partial<SuggestionMap> = {
    comparisonOperator: (() =>
      extractSuggestion(parser.comparisonOperator()))(),
    attributes: (() => extractSuggestion(parser.attributes()))(),
    logicalOperator: (() => extractSuggestion(parser.logicalOperator()))(),
    multiArg: (() => extractSuggestion(parser.multiArg()))(),

    // For 'NOT', we have to show the user, NOT IN as suggestion.
    rangeOperator: (() => {
      const suggestion = extractSuggestion(parser.rangeOperator());
      return {
        ...suggestion,
        results: suggestion.results.map((res: Result) => {
          if (res.value === 'NOT') {
            return {
              ...res,
              value: 'NOT IN',
            };
          }
          return res;
        }),
      };
    })(),
    singleArg: (() => extractSuggestion(parser.singleArg()))(),
  };

  return Object.freeze(out);
})();
