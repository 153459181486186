import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';

import { DataIdModule } from '../data-id/data-id.module';
import { IconModule } from '../icon/icon.module';
import { AppSwitcherComponent } from './app-switcher.component';

@NgModule({
  imports: [
    CommonModule,
    DataIdModule,
    IconModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatTooltipModule,
    AppSwitcherComponent
  ],
  exports: [AppSwitcherComponent]
})
export class AppSwitcherModule { }
