import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { CohesityHelixModule } from '@cohesity/helix';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'coh-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    CohesityHelixModule,
    TranslateModule,
  ],
})
export class LogoutComponent {

  /**
   * The heading for the logout page.
   */
  @Input()
  logoutHeading = 'logout.heading';

  /**
   * Whether to show the login again link.
   */
  @Input()
  displayLoginLink = true;

  /**
   * A class applied to the host to trigger
   * specific styling.  The most common case it's generally
   * used for ibm aqua styling of the logout page.
   */
  @HostBinding('class')
  @Input()
  modifierClass = '';

  /**
   * Whether to display the copyright section.
   */
  @Input()
  displayCopyright = false;

  /**
   * The company name to display in the copyright section.
   * Only needed if the displayCopyright is true.
   */
  @Input()
  companyName;

  /**
   * This event signals to the calling component that user would like to login again.
   * Since there is no routing in this module, we emit this event, and the routing
   * can be handled by the calling component.
   */
  @Output()
  goToLogin = new EventEmitter();

  /**
   * This method handles the click event on the login in again link.
   */
  handleLoginAgain() {
    this.goToLogin.emit();
  }

}
