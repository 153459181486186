import { AccessScope, Resource } from '@cohesity/api/guardian';
import { AccessTableRow, ResourceSelectionSummary } from '../models';

/**
 * Transform access scope fetch response to table format
 *
 * @param accessScopes - An array of access scopes.
 * @param resourcesByIdMap - map of resources by ID.
 * @returns Data represented in tabular format.
 */
export const transformApiResponseToTableData = (
  accessScopes: AccessScope[] = [],
  resourcesByIdMap: Map<string, Resource>
): AccessTableRow[] =>
  (accessScopes || []).map(scope => ({
    id: scope.id,
    name: scope.name,
    description: scope.description,
    resources: (scope.resourceIds ?? []).map(id => resourcesByIdMap.get(id)).filter(resource => !!resource),
  }));

/**
 * Get resource selection summary for a particular access scope.
 * Returns object representing resource count by type.
 * eg. Cloud services: 2, regions: 3, source types: 4, sources: 5
 *
 * @param resources Seleted resources
 * @returns resource selection summary
 */
export const getResourceSelectionSummary = (resources: Resource[] = []): ResourceSelectionSummary => {
  const resourceSelectionSummary: ResourceSelectionSummary = {};

  resources.forEach(resource => {
    if (resource) {
      resourceSelectionSummary[resource.resourceType] = (resourceSelectionSummary[resource.resourceType] || 0) + 1;
    }
  });

  return resourceSelectionSummary;
};
