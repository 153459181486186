import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { CohesityHelixModule } from '@cohesity/helix';
import { HumanizeFromNumbersPipeModule } from '@cohesity/utils';
import { TranslateModule } from '@ngx-translate/core';

import { MetricTypePipeModule } from '../../pipes';
import { AnomalyChartComponent } from './anomaly-chart.component';

const COMPONENTS = [AnomalyChartComponent];

@NgModule({
  imports: [
    CohesityHelixModule,
    CommonModule,
    HumanizeFromNumbersPipeModule,
    MetricTypePipeModule,
    TranslateModule
  ],
  exports: [...COMPONENTS],
  declarations: [...COMPONENTS],
})
export class AnomalyChartModule {}
