import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { MomentDatePipe } from '@cohesity/helix';
import { Options } from 'highcharts';

import { Measurement, StatlistReportItemInput } from '../../../iris-reporting.model';
import { ChartReportsService } from '../../chart-reports.service';
import { BaseReportItemRendererComponent } from '../base-report-item-renderer.component';

/**
 * A statlist report item.
 */
@Component({
  selector: 'iris-rpt-statlist-item',
  templateUrl: './statlist-item.component.html',
  styleUrls: ['./statlist-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MomentDatePipe],
})
export class StatlistItemComponent extends BaseReportItemRendererComponent implements OnChanges {
  /**
   * The statlist's input config
   */
  @Input() items: StatlistReportItemInput[] = [];

  /**
   * Custom pie chart options. Updated to disable animation when running in jsreport.
   */
  pieChartOptions: Options = { };

  constructor(
    private chartService: ChartReportsService,
    private momentDatePipe: MomentDatePipe,
  ) {
    super();
  }

  ngOnChanges(): void {
    if (this.isPrintMedia) {
      this.pieChartOptions = {
        plotOptions: {
          pie: {
            animation: false
          }
        }
      };
    }
    if (this.items && this.data) {
      this.items.forEach(item => {
        if (item.measurement) {
          if (item.measurement.dataType === 'text') {
            // Assign value from first item in data array in case data type is text
            item.value = this.data?.[0]?.[item.measurement.valueKey];
          } else if (item.measurement.dataType === 'percent' && item.measurement.totalKey) {
            // Calculate percentage based specific total value (using totalKey), not count
            const chartDataValue = this.chartService.getChartData(this.data, [{
              dataTypes: 'bytes',
              valueKey: item.measurement.valueKey
            } as Measurement]);
            const chartDataTotal = this.chartService.getChartData(this.data, [{
              dataTypes: 'bytes',
              valueKey: item.measurement.totalKey
            } as Measurement]);
            const value = chartDataValue.seriesData.get(
              this.chartService.getDataLabel(item.measurement.valueKey)
            )?.data[0];
            const total = chartDataTotal.seriesData.get(
              this.chartService.getDataLabel(item.measurement.totalKey)
            )?.data[0];

            item.value = value / total;
          } else {
            const chartData = this.chartService.getChartData(this.data, [item.measurement]);
            item.value = chartData.seriesData.get(this.chartService.getDataLabel(item.measurement.valueKey))?.data[0];
          }
        }
      });
    }
  }

  /**
   * Gets metric item label with dates if needed.
   *
   * @param    item  Item Configuration.
   * @returns  Item label.
   */
  getItemLabel(item: StatlistReportItemInput): string {
    const label = item.translate ? this.translate(item.title) : item.title;

    if (item.showTitleDate) {
      const filterDate = this.getFilterDate();

      if (filterDate) {
        const dateString = this.momentDatePipe.transform(filterDate, this.dateFormat);

        return `${label} (${dateString})`;
      }
    }
    return label;
  }
}
