import * as Highcharts from 'highcharts';
import { Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';

import { ByteSizeService } from '../../byte-size/byte-size.service';

/**
 * @description
 * This component displays the Treemap Chart.
 *
 * @example
 *   <cog-treemap-chart [data]="data" [labels]="{back: 'Back'}" [showDetails]="true"></cog-treemap-chart>
 */
@Component({
  selector: 'cog-treemap-chart',
  templateUrl: './treemap-chart.component.html',
  styleUrls: ['./treemap-chart.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TreemapChartComponent {
  /**
   * Highcharts imports.
   */
  readonly Highcharts: typeof Highcharts = Highcharts;

  /**
   * Chart configuration options.
   */
  chartOptions: Highcharts.Options = {
    chart: {
      type: 'treemap',
      spacing: [0, 0, 0, 0],
      backgroundColor: 'transparent',
      styledMode: true
    },
    title: undefined,
    credits: {
      enabled: false
    },

    // Cannot show tooltip for top level data only without drilldown because
    // 2nd level rects are displayed over top level rect so mouse over top
    // level rect will not happen.
    tooltip: {
      // Uses any here to avoid type checking error when returning false
      formatter: (): any => {
        const point = (<any>this.chart).hoverPoint as Highcharts.PointOptionsObject;

        return this.showDetails ? point.description : false;
      }
    },
    series: []
  };

  /**
   * Utilizes highcharts.colorsets.scss for theming.
   */
  @HostBinding('attr.class') cssClass = 'cog-chart cog-treemap-chart';

  /**
   * Allows parent to specify whether this is a expanded view or normal view.
   * For expanded view, it will allow drilldown and show tooltip.
   */
  @Input() showDetails = false;

  /**
   * An object contains indexed and translated strings for display.
   */
  @Input() labels: { [key: string]: string } = {};

  /**
   * Custom Highcharts series options that will override the chart series options.
   */
  @Input() customChartSeriesOptions: Highcharts.PlotTreemapOptions = {};

  /**
   * Custom Highcharts options that will override default chart series options.
   */
  @Input() customChartOptions: Highcharts.Options = {};

  /**
   * The Treemap chart data for display.
   */
  private _data: Highcharts.PointOptionsObject[];

  /**
   * Returns the Treemap chart data.
   */
  @Input() get data(): Highcharts.PointOptionsObject[] {
    return this._data;
  }

  /**
   * Sets Treemap chart data and render chart.
   */
  set data(seriesData: Highcharts.PointOptionsObject[]) {
    this._data = seriesData;
    this.render();
  }

  /**
   * Highcharts Chart instance.
   */
  chart: Highcharts.Chart;

  /**
   * Constructor.
   */
  constructor(private byteSizeService: ByteSizeService) {}

  /**
   * Clear all series.
   */
  clearSeries() {
    while (this.chart.series[0]) {
      this.chart.series[0].remove();
    }
  }

  /**
   * Sets Chart instance async by Highcharts' chartInstance callback.
   */
  setChartInstance(chart: Highcharts.Chart) {
    this.chart = chart;
    this.render();
  }

  /**
   * Renders chart with recently updated series data.
   */
  render() {
    if (!this.chart) {
      return;
    }

    this.clearSeries();

    const defaultChartOptions: Highcharts.SeriesTreemapOptions = {
      type: 'treemap',

      // Options: 'stripes', 'squarified', 'strip', 'sliceAndDice' (default)
      layoutAlgorithm: 'squarified',
      allowTraversingTree: this.showDetails,
      animationLimit: 1000,
      dataLabels: {
        enabled: false,
        allowOverlap: true
      },
      levels: [
        {
          level: 1,
          dataLabels: {
            enabled: true,
            align: 'left',
            verticalAlign: 'top'
          }
        },
        {
          level: 2,
          dataLabels: {
            enabled: false
          }
        }
      ],
      data: this._data
    };

    // Sets more Treemap specific configurations
    // Mix default and custom chart options.
    const treemapSeries: Highcharts.SeriesTreemapOptions =
      Object.assign(defaultChartOptions, this.customChartSeriesOptions);

    // drillUpButton type does not has text attribute but using
    // "lang: { drillUpText: 'Back' }" when creating chart (recommended by
    // highcharts) does not work.
    // Reference: https://github.com/DefinitelyTyped/DefinitelyTyped/blob/master/types/highcharts/index.d.ts#L2559
    (<any>treemapSeries.traverseUpButton) = {
      text: this.labels.back
    };

    if (this.customChartOptions && this.chart.update) {
      this.chartOptions = Object.assign(this.chartOptions, this.customChartOptions);
      this.chart.update(this.chartOptions);
    }

    this.chart.addSeries(treemapSeries, true, true);
  }
}
