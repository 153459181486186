import { AppModuleConfig, StateAccessContext, StateAccessMap } from 'src/app/app-module.config';

const configureAccess = (ctx: StateAccessContext) =>
  ctx.FEATURE_FLAGS.rpaasEnabled;

export const DashboardConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'rpaasDashboard.**',
      url: '/rpaas/dashboard',
      loadChildren: () => import('./dashboard.module').then(m => m.DashboardModule),
    },
    {
      name: 'rpaasAnomalies.**',
      url: '/rpaas/anomalies',
      loadChildren: () => import('./dashboard.module').then(m => m.DashboardModule),
    },
  ],
  allClusterMap: {
    'rpaasDashboard': true,
    'rpaasDashboard.summary': true,
    'rpaasDashboard.anomalies': true,
    'rpaasAnomalies.details': true,
    'rpaasAnomalies.recovery': true,
  },
  getStateAccessMap(): StateAccessMap {
    return {
      'rpaasDashboard': configureAccess,
      'rpaasDashboard.summary': configureAccess,
      'rpaasDashboard.anomalies': configureAccess,
      'rpaasAnomalies.details': configureAccess,
      'rpaasAnomalies.recovery': configureAccess,
    };
  },
};
