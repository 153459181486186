<div [formGroup]="formGroup">
  <ng-container *ngIf="filteredProtectionGroups$ | async as filteredGroups">
    <!-- Protection Group Type Selection. Don't show these radio buttons if
      there's no registered source as in that case, no existing groups can be
      selected. -->
    <mat-radio-group *ngIf="!editMode && (filteredGroups.length || enableObjectProtection)"
      formControlName="type">
      <mat-radio-button id="radio-group-new-group" [value]="protectionGroupOptions.new"
        cogDataId="radio-button-new-group">
        {{'newGroup' | translate}}
      </mat-radio-button>
      <mat-radio-button *ngIf="filteredGroups.length"
        id="radio-group-existing-group" [value]="protectionGroupOptions.existing"
        cogDataId="radio-group-existing-group">
        <span *ngIf="!(snapMirrorConfig$ | async)?.view; else snapDiffGroup">
          {{'existingGroup' | translate}}
        </span>
      </mat-radio-button>
      <mat-radio-button *ngIf="enableObjectProtection"
        id="radio-button-no-group" [value]="protectionGroupOptions.object"
        cogDataId="radio-button-no-group">
        {{'noGroup' | translate}}
      </mat-radio-button>
    </mat-radio-group>

    <!-- Protection Group Name when new group is selected -->
    <mat-form-field *ngIf="formGroup.get('type').value === protectionGroupOptions.new"
      class="margin-top">
      <mat-label>{{'name' | translate}}</mat-label>
      <input [cohAutoFocus]="!disableAutoFocus"
        formControlName="name"
        cogDataId="protection-group-name"
        id="group-name"
        matInput
        required>
      <mat-error>
        {{'errors.required' | translate}}
      </mat-error>
    </mat-form-field>

    <!-- Protection Group Selection when existing group is selected-->
    <mat-form-field *ngIf="formGroup.get('type').value === protectionGroupOptions.existing"
      class="margin-top">
      <mat-label>{{'group' | translate}}</mat-label>
      <mat-select [placeholder]="'selectGroup' | translate"
        formControlName="existingId"
        cogDataId="protection-group-select"
        id="protection-group-select"
        required>
        <mat-option *ngFor="let group of filteredGroups"
          [cogDataId]="group.name | lowercase"
          [inheritDataId]="true"
          [value]="group.id">
          {{group.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </ng-container>

  <cog-banner
    *ngIf="(snapMirrorConfig$ | async)?.view && formGroup.get('type').value === protectionGroupOptions.existing"
    status="warn">
    {{ 'overrideIncrementalPrefix' | translate}}
  </cog-banner>
</div>

<ng-template #snapDiffGroup>
  {{'existingSnapDiffGroup' | translate}}
</ng-template >
