import { Component } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';
import { nasEnvironments } from '@cohesity/iris-shared-constants';

import { BaseProtectionBuilderComponent } from '../../base-protection-builder/base-protection-builder.component';


/**
 * Default value for abort in blackouts.
 */
export const DefaultAbortInBlackouts = false;

@Component({
  selector: 'coh-settings-list-cloud-migration',
  templateUrl: './settings-list-abort-in-blackouts.component.html',
})
export class SettingsListAbortInBlackoutsComponent
  extends BaseProtectionBuilderComponent<boolean, any> {
  /**
   * Default abort in blackouts value.
   */
  _value = DefaultAbortInBlackouts;

  constructor(private irisCtx: IrisContextService) {
    super();
  }

  /**
   * Form control init.
   */
  initFormControl() {
    // we want env in both creation and edit flow.
    const env = this.formGroup.parent?.value?.source?._environment || this.protectionGroup?.environment;
    if (
      !flagEnabled(this.irisCtx.irisContext, 'pauseRunsEnabled')
      || !nasEnvironments.includes(env)
    ) {
      this.formGroup.addControl(this.name, new UntypedFormControl(this.value));
    } else {
      // Otherwise remove the form control
      this.formGroup.removeControl(this.name);
    }

    if (this.protectionGroup?.abortInBlackouts) {
      this.value = Boolean(this.protectionGroup.abortInBlackouts);
      this.formControl.setValue(this.value);
    }
  }
}
