<div class="object-name">
  <cog-icon [shape]="node.protectionSource | objectIcon : { isProtected: isNodeProtected }">
  </cog-icon>
  <h4 class="title">{{node.protectionSource.name}}</h4>
</div>

<!-- General Information -->
<div class="source-meta-grouping object-info-list">
  <ul>
    <!-- Database Size -->
    <li class="source-meta-line-item" *ngIf="node.logicalSize">
      <span class="source-meta-label">{{'provisionedSize' | translate}}</span>
      {{node.logicalSize | byteSize}}
    </li>
  </ul>
</div>

<coh-protection-status-meta class="margin-top-lg"
  [node]="node"
  [protectionLabel]="'sourceTreePub.tooltips.volumeProtected.' +  node.protectionSource.environment | translate">
</coh-protection-status-meta>
