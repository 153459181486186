<cog-settings-list-item [formGroup]="formGroup"
  [invalid]="formGroup.invalid"
  [label]="'mountsOrVips' | translate">
  <cog-settings-list-item-value-display>
    {{ formGroupValues.value }}
  </cog-settings-list-item-value-display>
  <cog-settings-list-item-value-editor>
    <div class="flex-row">
      <mat-form-field>
        <input
          matInput
          [formControlName]="formControlNames.value"
          [inheritDataId]="true"
          [placeholder]="'mountsOrVips' | translate"
          cogDataId="input-mount-points"
          type="number"
          min="1">
      </mat-form-field>
    </div>
  </cog-settings-list-item-value-editor>
</cog-settings-list-item>
