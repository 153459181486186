import { Component, Input, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { get } from 'lodash';
import { DialogService } from 'src/app/core/services';
import { TimelineDialogComponent, TimelineDialogData } from 'src/app/shared';
import { Recovery } from '../../model/recovery';
import { RecoveryFileObject } from '../../model/recovery-file-object';

/**
 * Recovery detail page file objects table.
 *
 * @example
 * <coh-recovery-file-table [recovery]="recovery"></coh-recovery-file-table>
 */
@Component({
  selector: 'coh-recovery-file-table',
  styleUrls: ['./recovery-file-table.component.scss'],
  templateUrl: './recovery-file-table.component.html',
  encapsulation: ViewEncapsulation.None
})
export class RecoveryFileTableComponent {
  /**
   * Recovery
   */
  @Input() recovery: Recovery;

  /**
   * Old Recovery data (restore task in old UI)
   */
  private progressMontiorTaskPaths: string[];

  /**
   * Setter for old Recovery data (restore task in old UI) and update object teardown data.
   */
  @Input() set recoveryV1(recoveryV1: any) {
    this.progressMontiorTaskPaths =
      get(recoveryV1, 'performRestoreTaskState.objectsProgressMonitorTaskPaths', [])
      .map(path => `${this.recovery.progressTaskId}/${path}`);
  }

  /**
   * Recovery file object table column names.
   */
  readonly columns = [
    'name',
    'status',
    'message'
  ];

  constructor(
    private dialogService: DialogService,
    private translate: TranslateService,
  ) {
  }

  /**
   * Track by function for the data table
   *
   * @param    index  Item index.
   * @param    item   The item.
   * @returns  The item's absolute path.
   */
  trackByPath(index: number, item: RecoveryFileObject): string {
    return item.absolutePath;
  }

  /**
   * Opens modal window with timeline for object activities.
   */
  openActivityModal({ name = '-', messages }: RecoveryFileObject, progressTaskId: string) {
    if (progressTaskId) {
      this.dialogService.showDialog(TimelineDialogComponent, {
        title: this.translate.instant('recoveryActivityFor', {name}),
        errorMessages: messages,
        task: progressTaskId,
      } as TimelineDialogData, { disableClose: false });
    }
  }
}
