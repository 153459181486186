import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SpinnerModule } from '@cohesity/helix';
import { ParcelModule } from 'single-spa-angular/parcel';

import { PluginOutletComponent } from './plugin-outlet.component';

@NgModule({
  imports: [
    CommonModule,
    ParcelModule,
    SpinnerModule,
  ],
  declarations: [PluginOutletComponent],
  exports: [PluginOutletComponent],
})
export class PluginOutletModule { }
