import { EnvItem } from '../source/source-types.constants';
import { Environment } from './environment.constants';

/**
 * The Environments which are used as backup job type only and their exist no protection source entity with that type.
 * JobEnvironment is a subset of Environment enum.
 *
 * NOTE: Magneto owns this and kept at `main/magneto/base/enums.proto` file.
 */
export type JobEnvironment =
  Environment.kAWSNative |
  Environment.kAWSSnapshotManager |
  Environment.kAwsS3 |
  Environment.kAzureNative |
  Environment.kAzureSnapshotManager |
  Environment.kGCPNative |
  Environment.kHyperVVSS |
  Environment.kO365Exchange |
  Environment.kO365Group |
  Environment.kO365OneDrive |
  Environment.kO365Outlook |
  Environment.kO365PublicFolders |
  Environment.kO365Sharepoint |
  Environment.kO365Teams |
  Environment.kPhysicalFiles |
  Environment.kPuppeteer |
  Environment.kRDSSnapshotManager |
  Environment.kVCD;

/**
 * The list environment which used as backup job type only.
 */
export const JobEnvironments: JobEnvironment[] = [
  Environment.kAWSNative,
  Environment.kAWSSnapshotManager,
  Environment.kAwsS3,
  Environment.kAzureNative,
  Environment.kAzureSnapshotManager,
  Environment.kGCPNative,
  Environment.kHyperVVSS,
  Environment.kO365Exchange,
  Environment.kO365Group,
  Environment.kO365OneDrive,
  Environment.kO365Outlook,
  Environment.kO365PublicFolders,
  Environment.kO365Sharepoint,
  Environment.kO365Teams,
  Environment.kPhysicalFiles,
  Environment.kPuppeteer,
  Environment.kRDSSnapshotManager,
  Environment.kVCD
];

/**
 * The data structure for the map of job environment type to its protection source type.
 *
 * @example
 *   // kPhysicalFiles is a backup job type for kPhysical type of protection sources.
 *   EnvItem<Environment.kPhysicalFiles>.sourceEnvironment = Environment.kPhysical;
 */
export type JobToSourceEnvironment = {
  [K in JobEnvironment]: EnvItem<K>['sourceEnvironment'][]
};

/**
 * The map of job environment type to its protection source type.
 */
export const JobToSourceEnvironment: JobToSourceEnvironment = {
  kAwsS3: [Environment.kAWS],
  kAWSNative: [Environment.kAWS],
  kAWSSnapshotManager: [Environment.kAWS],
  kAzureNative: [Environment.kAzure],
  kAzureSnapshotManager: [Environment.kAzure],
  kGCPNative: [Environment.kGCP],
  kHyperVVSS: [Environment.kHyperV],
  kO365Exchange: [Environment.kO365],
  kO365Group: [Environment.kO365],
  kO365OneDrive: [Environment.kO365],
  kO365Outlook: [Environment.kO365],
  kO365PublicFolders: [Environment.kO365],
  kO365Sharepoint: [Environment.kO365],
  kO365Teams: [Environment.kO365],
  kPhysicalFiles: [Environment.kPhysical],
  kPuppeteer: [Environment.kRemoteAdapter],
  kRDSSnapshotManager: [Environment.kAWS],
  kVCD: [Environment.kVMware],
};
