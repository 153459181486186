// DateTime Service - formatting utilities for times and dates

;(function(angular, undefined) {
  'use strict';

  angular.module('C').service('DateTimeService', DateTimeServiceFn);

  function DateTimeServiceFn(_, $rootScope, cUtils, tmhDynamicLocale,
    $translate, LocaleService, LOCALE_SETTINGS) {

    var dateTimeSvc = {
      beginningOfDay: beginningOfDay,
      clusterNow: clusterNow,
      dateLocaleLoaded: false,
      dateToMsecs: dateToMsecs,
      dateToTimeObject: dateToTimeObject,
      dateToUsecs: dateToUsecs,
      endOfDay: endOfDay,
      formatDate: formatDate,
      generateMomentWithTimezone: generateMomentWithTimezone,
      getCurrentUsecs: getCurrentUsecs,
      getDateTimeInputFormat: getDateTimeInputFormat,
      getDatePickerPlaceholder: getDatePickerPlaceholder,
      getDatePickerFormat: getDatePickerFormat,
      getDatesInRange: getDatesInRange,
      getDayAgoMs: getDayAgoMs,
      getFormatedDateRange: getFormatedDateRange,
      getFullDateTimeFormat: getFullDateTimeFormat,
      getIsoDateFormat: getIsoDateFormat,
      getLongDateFormat: getLongDateFormat,
      getLongDayOfWeekFormat: getLongDayOfWeekFormat,
      getNotAvailableFormat: getNotAvailableFormat,
      getPitTimeFormat: getPitTimeFormat,
      getOneYearFromNow: getOneYearFromNow,
      getPreferredDateFormat: getPreferredDateFormat,
      getPreferredFormat: getPreferredFormat,
      getPreferredTimeFormat: getPreferredTimeFormat,
      getShortDayOfWeekFormat: getShortDayOfWeekFormat,
      getOneMonthFromNow: getOneMonthFromNow,
      getNDaysFromNow: getNDaysFromNow,
      getShortHourMeridianFormat: getShortHourMeridianFormat,
      getShortMonthDayFormat: getShortMonthDayFormat,
      getWeekAgo: getWeekAgo,
      getWeekStart: getWeekStart,
      humanizeDuration: humanizeDuration,
      isDateMicroseconds: isDateMicroseconds,
      isToday: isToday,
      msecsToDate: msecsToDate,
      msecsToFormattedDate: msecsToFormattedDate,
      objectTimeToMsecs: objectTimeToMsecs,
      objectTimeToString: objectTimeToString,
      secsToDate: secsToDate,
      secsToFormattedDate: secsToFormattedDate,
      secsToTime: secsToTime,
      secsToUsecs: secsToUsecs,
      setupClusterDateLocale: setupClusterDateLocale,
      setupClusterNow: setupClusterNow,
      timeObjectToDate: timeObjectToDate,
      usecsToDate: usecsToDate,
      usecsToFormattedDate: usecsToFormattedDate,
      usecsToMinutes: usecsToMinutes,
      usecsToMsecs: usecsToMsecs,
      usecsToTime: usecsToTime,
      timeInMins: timeInMins,
    };

    // Subscribe to date locale changes and update the service.
    if (LocaleService.dateLocale$) {
      LocaleService.dateLocale$.subscribe(function dateLoaded(localeData) {
        if (!localeData) {
          return;
        }
        dateTimeSvc.setupClusterDateLocale(
          localeData,
          LocaleService.activeLocale);
      });
    }


    /**
     * Get the date time format which can be given as an input for
     * js Date object / momentjs etc.
     *
     * @method   getDateTimeInputFormat
     * @return   {string}   input date format
     */
    function getDateTimeInputFormat() {
      return LOCALE_SETTINGS.dateTimeInputFormat;
    }


    /**
     * ISO date format
     *
     * @method    getIsoDateFormat
     * @returns   {string}   iso date format string
     */
    function getIsoDateFormat() {
      return LOCALE_SETTINGS.isoDateFormat;
    }

    /**
     * Get the formatted date range value used to to show selected range and
     * used inside filter pills.
     *
     * @example
       {start: '2018-12-05T12:44:53.177Z', end: '2018-12-27T12:44:53.177Z'}
       'Dec 20, 2018 - Dec 27, 2018'
     *
     * @method   getFormatedDateRange
     * @param    {Object}   rangeObj     The date range object.
     * @param    {String}   [dtFormat]   The output date format.
     * @return   {String}   formatted date range string.
     */
    function getFormatedDateRange(rangeObj, dtFormat) {
      dtFormat = dtFormat || getPreferredDateFormat();

      // early exit for invalid date range values.
      if (!rangeObj ||
        !rangeObj.start ||
        !rangeObj.end ||
        !_.isDate(rangeObj.start) ||
        !_.isDate(rangeObj.end)) {
        return $translate.instant('naNotAvailable');
      }

      return [
        formatDate(rangeObj.start, dtFormat),
        formatDate(rangeObj.end, dtFormat)
      ].join(' - ');
    }

    /**
     * Given two dates, returns an array of Dates between them. Chronological
     * order of inputs does not matter. Does not manipulate the time of each
     * date.
     *
     * @function   getDatesInRange
     * @param      {Date|Number|Moment}   start           Any moment compatible
     *                                                    date format.
     * @param      {Date|Number|Moment}   [end=start]     Any moment compatible
     *                                                    date format.
     * @returns    {Date[]}   The sorted list of dates between the two inputs.
     */
    function getDatesInRange(start, end) {
      var datesList = [];

      // Used if swapping order of inputs.
      var tmpDate;

      start = moment(start).toDate();
      tmpDate = _.clone(start);
      end = moment(end || start).toDate();

      // In case the dates are passed in reverse order, swap them.
      if (start > end) {
        start = end;
        end = tmpDate;
      }

      while (start <= end) {
        datesList.push(start);
        start = moment(start).add(1, 'day').toDate();
      }
      return datesList;
    }

    /**
     * intializing moment and tmhDynamicLocale to use the cluster locale
     *
     * @param  {object} data         JSON data specific to the loaded locale
     *                               that includes different date formats to be
     *                               used by moment
     *
     * @param  {string} localeString the locale string ex('en-us')
     */
    function setupClusterDateLocale(data, localeString) {
      moment.locale(localeString);
      tmhDynamicLocale.set(localeString);

      angular.merge(LOCALE_SETTINGS, data);
      dateTimeSvc.dateLocaleLoaded = true;
    }

    // Will hold the offset value between the cluster time and user's system
    // time. This will be used in Date.clusterNow(), a more trustworthy version
    // of Date.now()
    var msecsOffset;

    /**
     * sets up Date.clusterNow(), a more reliable version of Date.now() that
     * provides msecs from epoch based on the cluster's time rather than the
     * user's system time.
     * NOTE: this will always be available, as app loading is blocked on login
     * until clusterInfo is successfully retrieved.
     *
     * @param      {object}  clusterInfo  The cluster information
     */
    function setupClusterNow(clusterInfo) {
      if (!Date.clusterNow && clusterInfo.currentTimeMsecs) {
        // cache the difference between user's system now and the cluster's
        // now
        msecsOffset =
          Date.now() - clusterInfo.currentTimeMsecs;

        // add the function to Date!
        Date.clusterNow = function clusterNow() {
          return Date.now() - msecsOffset;
        };

      }
    }

    /**
     * Provides msecs from epoch based on the cluster's time rather than the
     * user's system time.
     * NOTE: this will always be available, as app loading is blocked on login
     * until clusterInfo is successfully retrieved.

     * @return {object}  The msecs from epoch based on the cluster's time.
     */
    function clusterNow() {
      return Date.clusterNow();
    }

    /**
     * A wrapper function around moment. dateObjects are formatted using moment
     * using dateFormat arg for display purposes
     *
     * @param  {object | string}   date could be a date object or a Unix seconds
     * @param  {string}   dateFormat desired date format
     * @param  {string}   timezone   desired timezone in  which time is required
     *
     * @return {string}              formatted date object
     */
    function formatDate(date, dateFormat, timezone) {
      return timezone ?
        moment(date).tz(timezone).format(dateFormat):
        moment(date).format(dateFormat);
    }

    /**
     * Generates moment object with timezone property.
     *
     * @method   generateMomentWithTimezone
     * @param    {Date}     date       Native Date Object
     * @param    {String}   timezone   desired time zone info
     *
     * @return   {Object}   Moment object
     */
    function generateMomentWithTimezone(date, timezone) {
      if (!date) { return; }
      if (!timezone) {
        return moment(date);
      }
      return moment(date).tz(timezone);
    }

    /**
     * Date format functions below.
     *
     * For all different date formats checkout moment.js display docs.
     * http://momentjs.com/docs/#/displaying/
     */

    /**
     * Returns fullDateTime format string that is loaded from locale_settings.json
     * This format will display a date object as ex: 09/19/2017 12:30:45
     *
     * @return {string} 'MM/DD/YYYY H:mm:sszz'
     */
    function getFullDateTimeFormat() {
      return LOCALE_SETTINGS.fullDateTimeFormat;
    }

    /**
     * returns the default format string or [TODO:] users preferred format for date and time
     * @return {String} preferred date/time format or default if preferred not set
     */
    function getPreferredFormat() {
      // TODO: have this function check the users format preference
      return LOCALE_SETTINGS.preferredFormat;
    }

    /**
     * returns the default format string or [TODO:] users preferred format for date
     * @return {String} preferred date format or default if preferred not set
     */
    function getPreferredDateFormat() {
      // TODO: have this function check the users format preference
      return LOCALE_SETTINGS.preferredDateFormat;
    }

    /**
     * returns the default format string or [TODO:] users preferred format for time
     * @return {String} preferred date format or default if preferred not set
     */
    function getPreferredTimeFormat() {
      // TODO: have this function check the users format preference
      return LOCALE_SETTINGS.preferredTimeFormat;
    }

    /**
     * Our date picker uses uppercase MM for months, which represents minutes in
     * our formatter.
     * TODO: figure out how to normalize this.
     *
     * @return     {string}  string representing date format
     */
    function getDatePickerFormat() {
      return LOCALE_SETTINGS.uibBootstrapDatePickerFormat;
    }

    /**
     * Because the date picker format has capital 'M' we need a separate entity
     * for the actual user-facing placeholder.
     *
     * @return     {string}  string representing date format placeholder
     */
    function getDatePickerPlaceholder() {
      return LOCALE_SETTINGS.uibBootstrapDatePickerPlaceholder;
    }

    function getOneMonthFromNow (endOfDay) {
      return moment().add(30, 'days');
    }

    /**
     * function to return time after any no of given days
     *
     * @method  getNDaysFromNow
     * @param   {Integer} numOfDays
     * @returns {Integer} Epoch Time after n days
     */
    function getNDaysFromNow(numOfDays) {
      return moment().add(numOfDays, 'days');
    }
    /**
     * Get a date which is one month from now.
     * Used for starting trial period of licensing.
     *
     * @method  getOneMonthFromNow
     * @return {Date} Date object
     */
    function getOneMonthFromNow () {
      return moment().add(30, 'days');
    }

    /**
     * Returns longDay format string that is loaded from locale_settings.json
     * This format will display a date object as ex: Sunday
     *
     * @return {string} 'dddd'
     */
    function getLongDayOfWeekFormat() {
      return LOCALE_SETTINGS.longDayOfWeekFormat;
    }

    /**
     * Returns longDate format string that is loaded from locale_settings.json
     * This format will display a date object as ex: April 01, 2017
     *
     * @return {string}  'MMMM DD, YYYY'
     */
    function getLongDateFormat() {
      return LOCALE_SETTINGS.longDateFormat;
    }

    /**
     * Returns shortDay format string that is loaded from locale_settings.json
     * This format will display a date object as ex: Sun
     *
     * @return {string} 'ddd'
     */
    function getShortDayOfWeekFormat() {
      return LOCALE_SETTINGS.shortDayOfWeekFormat;
    }

    /**
     * Returns shortMonthDay format string that is loaded from locale_settings.json
     * This format will display a date object as ex: 1/09
     *
     * @return {string} 'M/DD'
     */
    function getShortMonthDayFormat() {
      return LOCALE_SETTINGS.shortMonthDayFormat;
    }

    /**
     * Returns shortHourMeridian format string that is loaded from locale_settings.json
     * This format will display a date object as ex: 1am
     *
     * @return {string} 'ha'
     */
    function getShortHourMeridianFormat() {
      return LOCALE_SETTINGS.shortHourMeridianFormat;
    }

    /**
     * Returns naNotAvailable format string that is loaded from locale_settings.json
     * This format is a fallback for date objects that are undefined ex: 'N/A'
     *
     * @return {string} 'N/A'
     */
    function getNotAvailableFormat() {
      return LOCALE_SETTINGS.notAvailableFormat;
    }

    /**
     * Returns PIT time format string that is loaded from locale_settings.json.
     * This formate will display a date object as example: 10:02:45 am
     *
     * @return {string} 'h:mm:ssa'
     */
    function getPitTimeFormat() {
      return LOCALE_SETTINGS.PitTimeFormat;
    }

    /**
     * Convert microseconds to a formatted time string.
     *
     * If possible avoid using this function directly, and make use of the
     * c-filter that calls it: {{task._durationUsecs | usecsToTime}}
     *
     * @param      {Integer}  durationUsecs  (microseconds)
     * @param      {Boolean}  [verbose]      indicates the type of string to use
     *                                       for output
     * @return     {Object}   { time: formatted string }
     */
    function usecsToTime(durationUsecs, verbose) {
      var text = $rootScope.text.servicesDateTimeService;
      var timeText = verbose ? text.duration : text.durationAbbreviated;
      var years = 0;
      var months = 0;
      var weeks = 0;
      var days = 0;
      var hours = 0;
      var minutes = 0;
      var seconds;
      var usecs;

      if (cUtils.isNumeric(durationUsecs)) {
        usecs = parseInt(+durationUsecs, 10);
        seconds = Math.floor(usecs / 1000000);
      } else {
        return {
          time: text.na
        };
      }

      if (seconds <= 0) {
        return {
          time: ['< 1', timeText.second].join(verbose ? ' ' : '')
        };
      }
      if (seconds < 60) {
        return {
          time: formatTime(seconds, timeText.second, verbose)
        };
      }
      years = Math.floor(seconds / 31449600);
      seconds = seconds % 31449600;

      // Months require special handling. We only display months if exactly
      // divisible by 30 days which is the internal standard.
      const monthsRemainder = seconds % (86400 * 30);
      if (!monthsRemainder) {
        months = Math.floor(seconds / (86400 * 30));
        seconds = monthsRemainder;
      }

      // Weeks require special handling. We only display weeks if exactly
      // divisible by 7 days.
      const weeksRemainder = seconds % 604800;
      if (!weeksRemainder) {
        weeks = Math.floor(seconds / 604800);
        seconds = seconds % 604800;
      }

      // If the total is exactly 31 days, then we will display '1 month'.
      // Otherwise, display number of days.
      if (seconds === 86400 * 31) {
        days = 31;
        seconds = 0;
      } else {
        days = Math.floor(seconds / 86400);
        seconds = seconds % 86400;
      }

      hours = Math.floor(seconds / 3600);
      seconds = seconds % 3600;
      minutes = Math.floor(seconds / 60);
      seconds = Math.floor(seconds % 60);

      return {
        time: [
          formatTime(years, timeText.year, verbose),
          formatTime(months, timeText.month, verbose),
          formatTime(weeks, timeText.week, verbose),
          formatTime(days, timeText.day, verbose),
          formatTime(hours, timeText.hour, verbose),
          formatTime(minutes, timeText.minute, verbose),
          formatTime(seconds, timeText.second, verbose)
        ].join(' ')
      };
    }

    /**
     * Private helper function used by usecsToTime to pluralize as necessary and
     * skip empty values
     *
     * @param      {Integer}  num      The number
     * @param      {String}   str      The descriptive string
     * @param      {Boolean}  verbose  indicates if short or long string is being used..
     *                                 short version doesn't need pluralization
     * @return     {String}   readable string version of time for output
     */
    function formatTime(num, str, verbose) {
      if (num === 0) {
        return '';
      }
      if (num > 1 && verbose) {
        str += 's';
      }
      return [num, str].join(verbose ? ' ' : '');
    }


    /**  Convenience wrapper function
     *   references usecsToTime
     *   @param  {Integer} secs (seconds)
     *   @param  {Boolean} suppress the suffix of the represented duration
     *   @return {Object}     {
     *                         time: String with or without suffix
     *                      }
     */
    function secsToTime(secs, verbose) {
      return usecsToTime(secs * 1000000, verbose);
    }

    /**
     * takes a date and optional time and converts it to milliseconds
     * @param  {String} dt      The date to be converted to milliseconds
     * @param  {String} [tm]    The optional time to be converted to milliseconds
     * @return {String}         The milliseconds as converted from the provided Date and/or Time
     */
    function dateToMsecs(dt, tm) {
      var convertDate;
      if (tm) {
        convertDate = new Date(dt + ' ' + tm);
      } else {
        convertDate = new Date(dt);
      }
      return Date.parse(convertDate);
    }

    /**
     * takes a milliseconds value and converts it into a Javascript based Date,
     * @param  {Integer}    milliseconds
     * @return {Date}       The date, as converted form the provided milliseconds
     */
    function msecsToDate(msecs) {
      if (!msecs) {
        return LOCALE_SETTINGS.notAvailableFormat;
      }

      return new Date(msecs);
    }

    /**
     * takes a seconds value and converts it into a Javascript based Date.
     *
     * @method     secsToDate
     * @param      {integer}  secs    Seconds input
     * @return     {date}     The date, as converted form the provided seconds
     */
    function secsToDate(secs) {
      if (!secs) {
        return LOCALE_SETTINGS.notAvailableFormat;
      }

      return new Date(secs * 1000);
    }

    /**
     * Converts time in milliseconds to a formatted string.
     *
     * @method   msecsToFormattedDate
     * @param    {Integer}    msecs
     * @param    {String}     dtFormat   preferred format for date
     * @param    {string}     timezone   desired timezone for date
     *
     * @return   {String}     The date as a formatted string, converted from the
     *                        provided milliseconds
     */
    function msecsToFormattedDate(msecs, dtFormat, timezone) {
      if (!msecs) {
        return LOCALE_SETTINGS.notAvailableFormat;
      }

      dtFormat = dtFormat ? dtFormat : getPreferredFormat();

      return formatDate(msecs, dtFormat, timezone);
    }

    /**
     * Converts time in microseconds to a formatted string.
     *
     * @method  usecsToFormattedDate
     * @param   {Integer}    usecs          time in microseconds
     * @param   {String}     dtFormat       preferred format for date
     *
     * @return  {String}     The date as a formatted string, converted from the
     *                       provided microseconds
     */
    function usecsToFormattedDate(usecs, dtFormat) {
      if (!usecs) {
        return LOCALE_SETTINGS.notAvailableFormat;
      }

      return msecsToFormattedDate(usecs / 1000, dtFormat);
    }

    /**
     * takes a microseconds value and converts it into a formatted string
     * @param  {Integer}    microseconds
     * @return {String}     The date as a formatted string, converted from the provided microseconds
     */
    function secsToFormattedDate(secs, dtFormat) {
      if (!secs) {
        return LOCALE_SETTINGS.notAvailableFormat;
      }

      return msecsToFormattedDate(secs * 1000, dtFormat);
    }

    /**
     * takes a date and optional time and converts it to microseconds
     * @param  {String} dt      The date to be converted to microseconds
     * @param  {String} [tm]    The optional time to be converted to usecs (microseconds)
     * @return {String}         The microseconds as converted from the provided Date and/or Time
     */
    function dateToUsecs(dt, tm) {
      var convertDate;

      if (tm) {
        convertDate = new Date(dt + ' ' + tm);
      } else {
        convertDate = new Date(dt);
      }

      return convertDate.getTime() * 1000;
    }

    /**
     * takes a usec value and converts it into a minutes value,
     * @param  {Integer}    usecs (microseconds)
     * @return {Integer}    minutes converted from provided usecs
     */
    function usecsToMinutes(usecs) {
      if (!usecs) {
        return LOCALE_SETTINGS.notAvailableFormat;
      }

      // ensure usecs is an integer
      if (usecs == parseInt(usecs, 10)) {
        return usecs / 60000000;
      }
      return 0;
    }

    /**
     * takes a microseconds value and converts it into a Javascript Milliseconds
     * Date. Also includes Microseconds delta for rehydration of dates > usecs
     * later.
     *
     * @param    {Integer}   usecs   Microseconds since epoch.
     * @return   {Date}      Millisecons Date as converted form the provided
     *                       usecs.
     */
    function usecsToDate(usecs) {
      if (!usecs) {
        return LOCALE_SETTINGS.notAvailableFormat;
      }

      return new Date(usecs / 1000);
    }

    /**
     * Determines if a date is in microseconds.
     *
     * @method   isDateMicroseconds
     * @param    {number|date}   date   The date to test (accepts ms and usec
     *                                  values).
     * @return   {boolean}       True if date microseconds, False otherwise.
     */
    function isDateMicroseconds(date) {
      // Coerce the date to an int in case a Date is passed.
      return _.isDate(date) ? false : (date * 1) > 9999999999999;
    }

    /**
     * Determines if a given date is Today. Handles micro+milliseconds.
     *
     * See http://momentjs.com/docs/#/query/is-same/ for possible arg types.
     *
     * @method   isToday
     * @param    {*}         date   The date
     * @return   {boolean}   True if the date is Today
     */
    function isToday(date) {
      date = isDateMicroseconds(date) ? date / 1000 : date;
      return moment(date).isSame(Date.now(), 'day');
    }

    /**
     * Gets the current timestamp in microseconds.
     *
     * @method   getCurrentUsecs
     * @return   {number}   Current timestamp in microseconds.
     */
    // Todo(maulik): Change the name of the method to show it uses cluster time
    function getCurrentUsecs() {
      return Date.clusterNow() * 1000;
    }

    /**
     * this function takes a date  and returns
     * the beginning of the week... this function assumes Monday as the beginning of the week
     * if Sunday is the beginning of the week we can set diff = d.getDate() - day;
     * @param  {Date} d     Optional date object for which we want the beginning of the week
     * @return {Date}       The date representing the beginning of the week
     */
    function getWeekStart(d) {
      d = new Date(d);
      var day = d.getDay(),
        diff = d.getDate() - day + (day === 0 ? -6 : 1), // adjust when day is sunday
        theDay = new Date(d.setDate(diff));
      return new Date(theDay.getFullYear(), theDay.getMonth(), theDay.getDate());
    }

    /**
     * gets a date Object for one week ago from current day,
     * Note: this will be the same day of the week as today, so if you are working
     * with fs and only want seven days, you'll need to adjust
     * using returnedVal.setDate(returnedVal.getDate() + 1)
     * TODO: consider a flag for this function that will do this for us
     * @return {Object}   javascript date object
     */
    function getWeekAgo() {
      var d = new Date(Date.clusterNow());
      d.setDate(d.getDate() - 7);
      return d;
    }

    /**
     * convenience function that returns the millisecond value for
     * one day ago (for 24 hour charts)
     *
     * @return {Integer} milliseconds since epoch for one day ago
     */
    function getDayAgoMs() {
      var dt = new Date(Date.clusterNow());
      dt.setDate(dt.getDate() - 1);
      return dt.getTime();
    }

    /**
     * returns a JS Date Object one year from the current timestamp
     * @return {Boolean} [endOfDay=true] indicates of return should be
     *                                   the end of day or current time
     * @return {Object}                  one year from now
     */
    function getOneYearFromNow(endOfDay) {

      var theDate = new Date();

      // default endOfDay to true;
      endOfDay = endOfDay !== false;

      if (endOfDay) {
        theDate = dateTimeSvc.endOfDay(theDate);
      }

      theDate.setFullYear(theDate.getFullYear() + 1);

      return theDate;

    }

    /**
     * takes a javascript dateObject and returns it with the same date but
     * the hours/minutes/seconds set to the first minute of the day
     * @param  {Object} dateObject javascript date Object
     * @return {Object}            modified javascript date Object
     */
    function beginningOfDay(dateObject) {
      dateObject.setHours(0);
      dateObject.setMinutes(0);
      dateObject.setSeconds(0, 0);
      return dateObject;
    }

    /**
     * takes a javascript dateObject and returns it with the same date but
     * the hours/minutes/seconds set to the last minute of the day
     * @param  {Object} dateObject javascript date Object
     * @return {Object}            modified javascript date Object
     */
    function endOfDay(dateObject) {
      if (!dateObject.setHours) {
        return dateObject;
      }
      dateObject.setHours(23);
      dateObject.setMinutes(59);
      dateObject.setSeconds(59, 999);
      return dateObject;
    }

    /**
     * objectTimeToString - transforms a timeObject into a string
     * @timeObject {object} -
     *     {
     *         hour: int
     *         minute: int
     *     }
     *  @returns String
     */
    function objectTimeToString(timeObject) {
      var suffix = (timeObject.hour < 12 ? 'am' : 'pm'),
        hour = (timeObject.hour <= 12 ? timeObject.hour : (timeObject.hour - 12)),
        minute = timeObject.minute < 10 ? '0' + timeObject.minute : timeObject.minute;

      if (hour === 0) {
        hour = 12;
      }

      return hour + ':' + minute + suffix;
    }

    /**
     * objectTimeToMsecs - transforms a timeObject into a Date object
     * @timeObject {object} -
     *     {
     *         hour: int
     *         minute: int
     *     }
     *  @returns Date
     */
    function objectTimeToMsecs(timeObject) {
      var d = new Date();
      // zero out seconds and milliseconds for a stable model
      var t = d.setHours(timeObject.hour, timeObject.minute, 0, 0);
      return t;
    }

    /**
     * takes a javascript date object and converts it to a Cohesity time object
     *
     * @method   dateToTimeObject
     * @param    {Date}     dt               date to be converted
     * @param    {Boolean}  preserveSeconds  True if seconds is to be preserved
     * @return   {Object}   time object as used by Cohesity backend
     */
    function dateToTimeObject(dt, preserveSeconds) {

      if (!dt) {
        return {};
      }

      var timeObject = {
        hour: dt.getHours(),
        minute: dt.getMinutes(),
      };

      if (preserveSeconds) {
        timeObject.second = dt.getSeconds();
      }
      return timeObject;
    }

    /**
     * takes a Cohesity API friendly time object and converts it to javascript date
     *
     * @param  {Object} timeObject API friendly time object
     * @return {Date}              javascript date
     */
    function timeObjectToDate(timeObject) {
      var dt = new Date();

      // if timeObject isn't valid return date as-is
      if (!timeObject || !timeObject.hasOwnProperty('hour') || !timeObject.hasOwnProperty('minute')) {
        return dt;
      }

      // zero out seconds and milliseconds for a stable model
      dt.setHours(timeObject.hour, timeObject.minute, 0, 0);
      return dt;
    }

    /**
     * Convert a large quantity of time units to a friendly display. For
     * example, 2880 minutes => '2 days'. This is a wrapper for 3rd party
     * moment.duration().humanize()
     *
     * @method     humanizeDuration
     * @param      {number}  value   The number of units
     * @param      {string}  unit    The unit of time (e.g. 'minutes', 'days')
     * @return     {string}  humanized description of the duration
     */
    function humanizeDuration(value, unit) {
      return moment.duration(value, unit).humanize();
    }

    /**
     * Converts a usecs date into ms.
     *
     * @method   usecsToMsecs;
     * @param    {number}   usecs   The usec date to convert.
     * @return   {number}   The same date in ms.
     */
    function usecsToMsecs(usecs) {
      return Math.floor(usecs / 1000);
    }

    /**
     * Converts a secs value into a usecs value.
     *
     * @method    secsToUsecs
     * @param     {number}   secs The secs value.
     * @return    {number}   The `secs` value converted into usecs.
     */
    function secsToUsecs(secs) {
      return secs * 1000000;
    }

    /**
     * Return the number of minutes passed from the start of the day
     *
     * @method   timeInMins
     * @param    {Object}  timeObject  Object - {hour : int, minute : int}.
     *
     * @return   {Integer}             Time in minutes.
     */
    function timeInMins(timeObject) {
      return timeObject.hour * 60 + timeObject.minute;
    }

    return dateTimeSvc;
  }

})(angular);
