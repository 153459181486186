import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NavItem } from '@cohesity/helix';
import { flagEnabled, IrisContextService, isDmsScope, isDraasScope } from '@cohesity/iris-core';
import { filter } from 'rxjs/operators';
import { DialogService } from 'src/app/core/services';

@Component({
  selector: 'coh-dms-register-source',
  templateUrl: './dms-register-source.component.html',
})
export class DmsRegisterSourceComponent {
  /**
   * The style for the input button.
   */
  @Input() buttonType: 'default' | 'stroke' = 'default';

  /**
   * Should the parent trigger a source refresh.
   */
  @Output() shouldRefresh = new EventEmitter<boolean>(false);

  /**
   * Returns whether user has privilege to add sources.
   */
  get hasRegisterPrivileges(): boolean {
    return this.irisCtx.irisContext.privs.PROTECTION_SOURCE_MODIFY;
  }

  /**
   * Represents the sources can be registered for DMaaS scope.
   */
  readonly dmsAvailableSources: NavItem[] = [
    {
      displayName: 'virtualMachines',
      name: 'vm',
      action: () => this.register('add-dms-hypervisor-dialog'),
    },
    flagEnabled(this.irisCtx.irisContext, 'dmsNasRegistration') ? {
      displayName: 'nas',
      name: 'nas',
      action: () => this.register('add-dms-nas-dialog'),
    } : null,
    flagEnabled(this.irisCtx.irisContext, 'dmsSqlRegistration') ? {
      displayName: 'msSqlServer',
      name: 'sql',
      action: () => this.register('add-dms-sql-dialog'),
    } : null,
    flagEnabled(this.irisCtx.irisContext, 'dmsOffice365Registration') ? {
      displayName: 'microsoft365',
      name: 'microsoft365',
      action: () => this.register('add-dms-office365-dialog'),
    } : null,
    flagEnabled(this.irisCtx.irisContext, 'dmsAwsRegistration') ? {
      displayName: 'aws',
      name: 'aws',
      action: () => this.register('add-dms-aws-ec2-dialog'),
    } : null,
    flagEnabled(this.irisCtx.irisContext, 'dmsAzureRegistration') ? {
      displayName: 'azure',
      name: 'azure',
      action: () => this.register('add-dms-azure-registration-dialog'),
    } : null,
    flagEnabled(this.irisCtx.irisContext, 'dmsOracleRegistration') ? {
      displayName: 'oracle',
      name: 'oracle',
      action: () => this.register('add-dms-oracle-dialog'),
    } : null,
    flagEnabled(this.irisCtx.irisContext, 'dmsPhysicalRegistration') ? {
      displayName: 'physicalServer',
      name: 'physical',
      action: () => this.register('add-dms-physical-dialog'),
    } : null,
    flagEnabled(this.irisCtx.irisContext, 'dmsSfdcRegistration') ? {
      displayName: 'salesforce',
      name: 'salesforce',
      action: () => this.register('add-dms-sfdc-dialog'),
    } : null,
    flagEnabled(this.irisCtx.irisContext, 'dmsUdaRegistration') &&
      flagEnabled(this.irisCtx.irisContext, 'dmsUdaEnabled') ? {
      displayName: 'uda',
      name: 'uda',
      action: () => this.register('add-dms-uda-dialog'),
    } : null,
  ].filter(Boolean);

  /**
   * Represents the sources can be registered for DRaaS scope.
   */
  readonly draasAvailableSources: NavItem[] = [
    {
      displayName: 'virtualMachines',
      name: 'vm',
      action: () => this.register('add-dms-hypervisor-dialog'),
    },
    {
      displayName: 'aws',
      name: 'aws',
      action: () => this.register('add-dms-aws-ec2-dialog'),
    },
  ];

  /**
   * Return all available sources based on the scope.
   */
  get availableSources(): NavItem[] {
    if (isDmsScope(this.irisCtx.irisContext)) {
      return this.dmsAvailableSources;
    }

    if (isDraasScope(this.irisCtx.irisContext)) {
      return this.draasAvailableSources;
    }

    return [];
  }

  constructor(
    private dialogService: DialogService,
    private irisCtx: IrisContextService,
  ) { }

  /**
   * Opens a registration dialog for the selected item.
   *
   * @param   type   The component type to be rendered.
   */
  register(type: string, dialogData?: any) {
    this.dialogService.showDialog(type, dialogData)
      .pipe(filter(result => !!result))
      .subscribe(() => this.shouldRefresh.emit(true));
  }

}
