import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Controls, NgxSubFormComponent, subformComponentProviders, takeUntilDestroyed } from 'ngx-sub-form';
import { FilterIps } from 'src/app/modules/protection/protection-builder/models';

@Component({
  selector: 'coh-settings-list-ip-filters',
  templateUrl: './settings-list-ip-filters.component.html',
  providers: subformComponentProviders(SettingsListIpFiltersComponent),
})
export class SettingsListIpFiltersComponent
  extends NgxSubFormComponent<FilterIps> implements OnInit {
  /**
   * Component on init.
   */
  ngOnInit() {
    this.formGroupControls.enabled.valueChanges.pipe(
      takeUntilDestroyed(this)
    ).subscribe(value => {
      if (!value) {
        this.formGroupControls.filterIpConfig.setValue(null);
        this.formGroupControls.filterIpConfig.setValidators(null);
        this.formGroupControls.filterIpConfig.setErrors(null);
      } else {
        this.formGroupControls.filterIpConfig.setValidators(Validators.required);
      }
    });
  }

  getDefaultValues(): Partial<FilterIps> {
    return {
      enabled: false,
    };
  }

  /**
   * Function to return all the form controls.
   *
   * @return The form controls object.
   */
  protected getFormControls(): Controls<FilterIps> {
    return {
      enabled: new UntypedFormControl(null, Validators.required),
      filterIpConfig: new UntypedFormControl(),
    };
  }
}
