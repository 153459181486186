import { Component } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { CreateView } from '@cohesity/api/v2';
import { CreateForm, createFormProviders } from '@cohesity/shared-forms';

import { FlatKeyTypes, ObjectKeyTypes, ObjectServicesMappingConfigByType, StructuredKeyLengths } from '../../../models';

/**
 * Internal form interface.
 */
export interface ObjectServicesMappingConfigForm {
  keyType: ObjectKeyTypes;
  flatType: FlatKeyTypes;
  keyLength: StructuredKeyLengths;
}
@Component({
  selector: 'coh-create-view-object-keys',
  templateUrl: './view-object-keys.component.html',
  styleUrls: ['./view-object-keys.component.scss'],
  providers: createFormProviders(ViewObjectKeysComponent),
})
export class ViewObjectKeysComponent {

  /**
   * The list of object key types.
   */
  objectKeyTypes = ObjectServicesMappingConfigByType['objectKeyTypes'];

  /**
   * Returns the list of flat key types.
   */
  flatKeyTypes = ObjectServicesMappingConfigByType['flatKeyTypes'];

  /**
   * Returns the list of key lengths.
   */
  structuredKeyLengths = ObjectServicesMappingConfigByType['structuredKeyLengths'];

  /**
   * Initialize the form.
   */
  form = new CreateForm<CreateView['objectServicesMappingConfig'], ObjectServicesMappingConfigForm>(this, {
    formControls: {
      keyType: new UntypedFormControl('ObjectId', Validators.required),
      flatType: new UntypedFormControl('Structured'),
      keyLength: new UntypedFormControl('Short'),
    },
    transformFromFormGroup: this.transformFromFormGroup.bind(this),
    transformToFormGroup: this.transformToFormGroup.bind(this),
  });

  /**
   * Transform the form group value to be used as the input form value.
   */
  transformToFormGroup(value: CreateView['objectServicesMappingConfig']): ObjectServicesMappingConfigForm {
    const defaultValue = this.form.formGroup.getRawValue();

    // Return default value if null.
    if (!value) {
      return defaultValue;
    }

    // When keytype is not 'Flat', can use it directly.
    if (value === 'Hierarchical' || value === 'ObjectId') {
      return {
        ...defaultValue,
        keyType: value,
      };
    }

    // When value is 'Random', keyType is 'Flat'.
    if (value === 'Random') {
      return {
        ...defaultValue,
        keyType: 'Flat',
        flatType: value,
      };
    }

    // When value is 'Short' or 'Long', keyType is 'Flat' & flatType is 'Structured'.
    return {
      ...defaultValue,
      keyType: 'Flat',
      keyLength: value,
    };
  }

  /**
   * Transform the form value to be used as the output form value.
   */
  transformFromFormGroup(value: ObjectServicesMappingConfigForm): CreateView['objectServicesMappingConfig'] {
    // Returne the keyType directly if keyType is not 'Flat'
    if (value.keyType === 'Hierarchical' || value.keyType === 'ObjectId') {
      return value.keyType;
    }

    // If flatType is 'Random', return 'Random'
    // If flatType is 'Structured', return 'Short' or 'Long'
    return value.flatType === 'Structured' ? value.keyLength : value.flatType;
  }
}
