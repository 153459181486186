
import { Component } from '@angular/core';

// simple ng-content components for use in various dashcard layouts.

@Component({
  selector: 'cog-dashcard-header-aux',
  template: '<ng-content></ng-content>',
})
export class DashcardHeaderInfoComponent { }

@Component({
  selector: 'cog-dashcard-top',
  template: '<ng-content></ng-content>',
})
export class DashcardTopComponent { }

@Component({
  selector: 'cog-dashcard-chart',
  template: '<ng-content></ng-content>',
})
export class DashcardChartComponent { }

@Component({
  selector: 'cog-dashcard-legend',
  template: '<ng-content></ng-content>',
})
export class DashcardLegendComponent { }

@Component({
  selector: 'cog-dashcard-footer',
  template: '<ng-content></ng-content>',
})
export class DashcardFooterComponent { }
