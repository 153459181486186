// Component: Enable AV on a View

;(function(angular) {
  'use strict';

  angular.module('C.antivirus')
    .controller('AddSecuredViewCtrl', AddSecuredViewCtrlFn);

  function AddSecuredViewCtrlFn(_, $state, evalAJAX, ViewService,
    AntivirusService, $uibModalInstance, view) {

    var $ctrl = this;

    var parentHelpId;

    _.assign($ctrl, {
      isInModal: !!$uibModalInstance,
      isEditMode: !!view,
      view: view,

      $onInit: $onInit,
      endFlow: endFlow,
      filterViews: filterViews,
      onSubmit: onSubmit,
    });

    if ($ctrl.isInModal) {
      parentHelpId = $state.current.help;
    }

    /**
     * Calls when component is initialized
     *
     * @method   $onInit
     */
    function $onInit() {
      if ($ctrl.isInModal) {
        $state.current.help = 'antivirus_add_secured_view';
      }

      _fetchSecuredViews();
    }

    /**
     * Issue add/update API.
     *
     * @method   onSubmit
     */
    function onSubmit() {
      // Update View with AV settings

      if ($ctrl.newSecuredViewForm.$invalid) {
        return;
      }

      $ctrl.view.antivirusScanConfig.isEnabled = true;

      /**
       * 1. The View object is obtained from GET views API which does not
       *    include a complete Views object.
       *
       * 2. There is an overzealous global transform method which stubs certain
       *    properties when undefined.
       *
       * 3. Since all Views fetched by this component will be missing certain
       *    properties, and because they are stubbed by the transform, this
       *    results in mutating the View during the PUT operation.
       *
       * The Per ENG-168703, we don't want to submit the following properties.
       * Just let the backend keep the existing values.
       */
      $ctrl.view.smbPermissionsInfo = undefined;
      $ctrl.view.nfsRootPermissions = undefined;

      _updateView($ctrl.view);
    }

    /**
     * Fetches the list of all secured Views.
     *
     * @method   _fetchSecuredViews
     */
    function _fetchSecuredViews() {
      AntivirusService.getSecuredViews().then(
        function getSecuredViewsSuccess(views) {
          $ctrl.securedViewIds = _.map(views, 'viewId');
        },
        evalAJAX.errorMessage
      ).finally(function getSecuredViewsDone() {
        $ctrl.loadedSecuredViews = true;
      });
    }

    /**
     * Updates existing View with AV settings.
     *
     * @method   _updateView
     * @param    {object}   updatedView   The updated View.
     */
    function _updateView(updatedView) {
      $ctrl.inProgress = true;

      ViewService.editView(updatedView).then(
        function updateViewSuccess(view) {
          endFlow('complete');
        },
        evalAJAX.errorMessage
      ).finally(function updateViewFinally() {
        $ctrl.inProgress = false;
      });
    }

    /**
     * Cancels the flow and dismisses the modal, if necessary
     *
     * @method     endFlow
     * @param      {String}  [reason='cancel']  describes reason for ending
     *                                          flow, 'cancel' or 'complete'
     */
    function endFlow(reason) {
      reason = reason || 'cancel';

      if ($ctrl.isInModal) {
        switch (reason) {
          case 'cancel':
            $uibModalInstance.dismiss('user.cancel');
            break;
          case 'complete':
            $uibModalInstance.close($ctrl.view);
            break;
        }
        $state.current.help = parentHelpId;
      } else {
        $state.go('antivirus.secured-views');
      }
    }

    /**
     * Filters out any View which already has AV enabled. This method is passed
     * into the select-view component.
     *
     * @method      filterViews
     * @param       {Object}     view     View to test.
     * @returns     {Boolean}    false to exclude because Antivirus is already
     *                           configured.
     */
    function filterViews(view) {
      return _isProtocolSupported(view) &&
        !$ctrl.securedViewIds.includes(view.viewId);
    }

    /**
     * Determines whether Antivirus is supported for the provided View.
     *
     * @method   _isProtocolSupported
     * @param    {Object}    view   View object.
     * @returns  {Boolean}   True if view contains SMB protocol.
     */
    function _isProtocolSupported(view) {
      return ['kAll', 'kSMBOnly'].includes(view.protocolAccess)
    }
  }

})(angular);
