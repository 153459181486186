import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import {
  BackupPolicy,
  BlackoutWindow,
  FullScheduleAndRetention,
  HeliosPolicyResponse,
  HeliosRetention,
  ProtectionPolicyResponse
} from '@cohesity/api/v2';
import { IrisContextService, isDmsScope } from '@cohesity/iris-core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { PolicyUtils } from 'src/app/modules/policy-shared/protection-policy-utils';

import { CloudTier, CloudTieringKeyMap } from '../../constants';
import { ExternalTargetSelectorService } from '../../forms';
import { HumanizeFromDaysPipe } from '../../pipes';

/**
 * TimeObject interface.
 */
interface TimeObject {
  hour: number;
  minute: number;
}
/**
 * Grouped Blackout Periods interface.
 */
interface GroupedBlackout {
  days: string[];
  startTime: TimeObject;
  endTime: TimeObject;
}

/**
 * Grouped data movement interface.
 */
interface GroupedDataMovement {
  targetTier: CloudTier;
  schedule: {
    frequency: number;
    unit: string;
  };
}

/**
 * Type for Policy summary info.
 */
type PolicySummary = ProtectionPolicyResponse | HeliosPolicyResponse;

@Component({
  selector: 'coh-policy-summary',
  templateUrl: './policy-summary.component.html',
  styleUrls: ['./policy-summary.component.scss'],
  providers: [HumanizeFromDaysPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PolicySummaryComponent implements OnInit {

  /**
   * Protection Policy with details.
   */
  @Input() policy: PolicySummary;

  /**
   * Specifies whether the current policy is Helios policy.
   */
  @Input() isHeliosPolicy = false;

  /**
   * Protection policy type.
   */
  @Input() policyType: string;

  /**
   * Specifies whether policy is selected for externally triggered backup or not.
   */
  @Input() isExternallyTriggered = false;

  /**
   * RPaaS external targets.
   */
  private readonly rpaasVaults$: Observable<any[]> = this.externalTargetsService.rpaasVaults$.pipe(shareReplay(1));

  /**
   * Map RPaaS vault ID to vault's region.
   */
  readonly rpaasRegionById$: Observable<{ [vaultId in number]: string }> = this.rpaasVaults$.pipe(
    map(vaults =>
      vaults?.reduce((acc, vault) => {
        acc[vault.id] = vault.archivalParams?.awsParams?.region;
        return acc;
      }, {})
    ),
    shareReplay(1),
  );

  /**
   * Map RPaaS vault ID to vault's storage class.
   */
  readonly storageClassById$: Observable<{ [vaultId in number]: string }> = this.rpaasVaults$.pipe(
    map(vaults => {
      const res = vaults?.filter(
        vault => ['AmazonS3Glacier', 'AmazonS3StandardIA'].includes(vault.archivalParams?.awsParams?.storageClass));

      if (res?.length > 0) {
        return res?.reduce((acc, vault) => {
          acc[vault.id] = vault.archivalParams?.awsParams?.storageClass;
          return acc;
        }, {});
      } else {
        return null;
      }
    }),
    shareReplay(1),
  );

  /**
   * Array of Schedule units in order.
   */
  readonly scheduleUnitOrder = ['Days', 'Weeks', 'Months', 'Years'];

  /**
   * Specifies whether the component is in DMaaS scope.
   */
  private isDmsScope = false;

  constructor(
    private externalTargetsService: ExternalTargetSelectorService,
    private translate: TranslateService,
    private humanizeFromDaysPipe: HumanizeFromDaysPipe,
    private irisContextService: IrisContextService,
  ) { }

  ngOnInit() {
    this.isDmsScope = isDmsScope(this.irisContextService.irisContext);
    this.externalTargetsService.refreshTargetList('Rpaas');
  }

  /**
   * Groups blackout period formModel based on startTime and endTime.
   *
   * @param   blackouts  Array of blackoutPeriod.
   * @return  Grouped Blackout period.
   */
  groupBlackouts(blackouts: BlackoutWindow[]): GroupedBlackout[] {
    const indexedPeriods = {};
    const values = [];

    if (blackouts && blackouts.length) {
      // build a map of start/end time so we can push related days into it
      blackouts.forEach(blackout => {
        const timeFrameKey = [
          JSON.stringify(blackout.startTime),
          JSON.stringify(blackout.endTime)
        ].join();

        if (indexedPeriods[timeFrameKey]) {
          indexedPeriods[timeFrameKey].days.push(blackout.day);
        } else {
          indexedPeriods[timeFrameKey] = {
            days: [blackout.day],
            startTime: blackout.startTime,
            endTime: blackout.endTime,
          };
        }
      });

      // now build our transformedBlackoutPeriods based on the index map
      for (const indexedPeriod in indexedPeriods) {
        if (indexedPeriods[indexedPeriod]) {
          values.push({
            days: indexedPeriods[indexedPeriod].days,
            startTime: indexedPeriods[indexedPeriod].startTime,
            endTime: indexedPeriods[indexedPeriod].endTime,
          });
        }
      }
    }
    return values;
  }

  /**
   * Constructs label for run schedules and target schedules
   * Run schedules include: incremental, full, bmr, log
   * Target schedules include: extended retention,
   *
   * @param   schedule   Run schedule or target schedule.
   * @return  Translated label for policy summary.
   */
  constructScheduleLabel(schedule: any): string {
    return PolicyUtils.constructScheduleLabel(schedule, this.translate, this.humanizeFromDaysPipe);
  }

  /**
   * Returns true if the policy is CAD policy.
   */
  isCadPolicy(): boolean {
    return PolicyUtils.isCadPolicy(this.policy);
  }

  /**
   * Return true if the current policy has datalock enabled.
   *
   * @param   policy   Protection policy info.
   */
  isDataLockPolicy(): boolean {
    return PolicyUtils.isDataLockPolicy(this.policy);
  }

  /**
   * Returns true if the policy is a replicated policy.
   *
   * @param   policy   Protection policy info.
   */
  isReplicatedPolicy(): boolean {
    return (this.policy as ProtectionPolicyResponse)?.isReplicated;
  }

  /**
   * Groups data movement Api model to UI model.
   *
   * @returns  The array of GroupedDataMovement.
   */
  groupDataMovement(): GroupedDataMovement[] {
    const { regular } = this.policy.backupPolicy as BackupPolicy;
    let tiers = [];

    // In DMaaS policy, tiering data is part of regular retention and only supports AWS tiers.
    if (this.isDmsScope) {
      tiers = (regular?.retention as HeliosRetention)?.tiers;
    } else {
      // Fetching tiering info for CAD policy.
      // CAD policy supports multiple cloud platforms including AWS, Azure, GCP, Oracle.
      const { tierSettings } = regular?.primaryBackupTarget?.archivalTargetSettings ?? {};
      const platformKey = CloudTieringKeyMap[tierSettings?.cloudPlatform];
      tiers = tierSettings?.[platformKey]?.tiers;
    }

    if (!tiers?.length) {
      return [];
    }

    const targetTiers = tiers.map(tier => this.isDmsScope ? tier.type : tier.tierType);
    const movingFrequencies = tiers.map(tier => ({
      frequency: tier.moveAfter,
      unit: this.isDmsScope ? tier.unit : tier.moveAfterUnit,
    }));

    // The first tier is always the default tier.
    // Ignore as we don't display the default tier in UI.
    targetTiers.shift();

    return targetTiers.map((targetTier: CloudTier, index: number) => ({
      targetTier,
      schedule: movingFrequencies[index],
    }));
  }

  /**
   * Constructs label for data movement tiering.
   *
   * @param   dataMovement   Data movement schedule and tier info.
   * @return  Translated label for data movement summary.
   */
  constructDataMovementLabel(dataMovement: GroupedDataMovement): string {
    if (!dataMovement) {
      return;
    }

    const { targetTier, schedule } = dataMovement;
    const prefix = 'policyDetails.dataMovement';
    const suffix = schedule.frequency === 1 ? 'singular' : 'plural';

    return this.translate.instant(`${prefix}.${schedule.unit}.${suffix}`, {
      targetTier: this.translate.instant(`enum.cloudTier.${targetTier}`),
      duration: schedule.frequency,
    });
  }

  /**
   * Sort full backup schedules based on schedule unit.
   *
   * @param    backups   Array of full backup schedules with retention.
   * @returns  Sorted full backup schedules.
   */
  sortFullSchedules(backups: FullScheduleAndRetention[]): FullScheduleAndRetention[] {
    return backups?.sort((a: FullScheduleAndRetention, b: FullScheduleAndRetention) =>
      this.scheduleUnitOrder.indexOf(a.schedule?.unit) - this.scheduleUnitOrder.indexOf(b.schedule?.unit));
  }
}
