/**
 * Component: globalPolicies
 */

;(function globalPolicies(angular) {
  'use strict';

  angular.module('C.policies')
    .controller('GlobalPoliciesCtrl', globalPoliciesControllerFn)
    .component('globalPolicies', {
      templateUrl: 'app/protection/policies/global-policies/global-policies.html',
      controller: 'GlobalPoliciesCtrl',
    });

  /**
   * Global Policies Controller Fn.
   *
   * @param {*} _ Lodash.
   * @param {*} $rootScope $rootScope.
   * @param {*} PolicyService PolicyService.
   * @param {*} evalAJAX EvalAJAX.
   * @param {*} $state $state.
   */
  function globalPoliciesControllerFn(_, $rootScope, PolicyService, evalAJAX) {
    var $ctrl = this;

    _.assign($ctrl, {
      loading: false,
      templates: [],

      $onInit: $onInit,
      deleteTemplate: deleteTemplate,
    });

    /**
     * Lifecycle when controller is initialized.
     *
     * @function   $onInit
     */
    function $onInit() {
      fetchGlobalPolicies();
    }

    /**
     * Builds and returns the context actions for a given global policy.
     *
     * @function    buildTemplateActions
     * @param       {Object}  policy  To build context actions for.
     * @return      {Array}   Actions available for the global policy.
     */
    function buildTemplateActions(policy) {
      var config = [];

      if (!$rootScope.user.privs.PROTECTION_POLICY_MODIFY) {
        return config;
      }

      config.push(
        {
          translateKey: 'copyTemplate',
          icon: 'icn-copy',
          state: 'policy-modify',
          stateParams: {
            // make a copy of the Policy to ensure no circular references will
            // be created, since actions config object will be attached directly
            // to the Policy.
            copyPolicy: angular.copy(policy),
          },
        },
        {
          translateKey: 'editTemplate',
          icon: 'icn-edit',
          state: 'policy-modify',
          disabled: policy._wormLocked && !$rootScope.user.privs.DATA_SECURITY,
          disabledTooltipKey: 'policy.editPolicyWormLockedTooltip',
          stateParams: {
            policyId: policy.id,
          },
        },
        {
          translateKey: 'deleteTemplate',
          icon: 'icn-delete',
          action: function deleteTemplateWrapper() {
            $ctrl.deleteTemplate(policy);
          },
        }
      );

      return config;
    }

    /**
     * Helper function to fetch global policy templates.
     *
     * @function    fetchGlobalPolicies
     */
    function fetchGlobalPolicies() {
      $ctrl.loading = true;
      PolicyService.getGlobalPolicies()
        .then(function getTemplates(templates) {
          templates.forEach(function postProcessTemplates(template) {
            template._actionsMenu = buildTemplateActions(template);
          });
          $ctrl.templates = templates;
        }, evalAJAX.errorMessage)
        .finally(function finalizeTemplates() {
          $ctrl.loading = false;
        });
    }

    /**
     * Remove policy template.
     *
     * @function deleteTemplate
     */
    function deleteTemplate() {
      // TODO: handle delete template
    }
  }
})(angular);
