import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { ConcatenatedObjectId, SearchObjectPerSource } from '../../scan.models';

@Component({
  selector: 'dg-scan-object-selection-cart',
  templateUrl: './object-selection-cart.component.html',
  styleUrls: ['./object-selection-cart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ObjectSelectionCartComponent {
  // List of selected objects
  @Input() selectedObjects: SearchObjectPerSource[];

  // emits object of the item to be removed
  @Output() removeFromCart = new EventEmitter<ConcatenatedObjectId>();
}
