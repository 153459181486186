import { Directive, Host, Input, OnDestroy, OnInit } from '@angular/core';
import { MatGridTile } from '@angular/material/grid-list';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { TileSize, TileSizeEnum, defaultTileConfig } from './grid-reflow.model';
import { GridListReflowColsDirective } from './mat-grid-list-reflow-cols.directive';

/**
 * This directive helps simplify the application of reflow column definitions based on view size.
 *
 * @example
 * <mat-grid-tile
 *   cogGridTileReflowColspan>
 *
 * @example
 * <mat-grid-tile
 *   cogGridTileReflowColspan="lg">
 */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'mat-grid-tile [cogGridTileReflowColspan]',
})
export class GridTileReflowColspanDirective implements OnDestroy, OnInit {
  /**
   * Optional.  This can be one of the keys from the gridTileReflowRulesMap.
   * Otherwise, it will default to sm.
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('cogGridTileReflowColspan') tileSize: TileSize = TileSizeEnum.sm;

  /**
   * Use to clean up subscriptions on destroy.
   */
  private destroy = new Subject<void>();

  constructor(@Host() private matGridTile: MatGridTile, private hostGridList: GridListReflowColsDirective) {
    this.setColspan();
  }

  /**
   * Register for grid tile configuration changes from it's host grid list.
   */
  ngOnInit(): void {
    this.hostGridList.tileConfig$
      .pipe(
        takeUntil(this.destroy),
        map(tileConfig => tileConfig[this.tileSize])
      )
      .subscribe(colspan => {
        this.setColspan(colspan);
      });
  }

  ngOnDestroy(): void {
    this.destroy.next();
  }

  /**
   * Set the colspan property of the host component matGridTile
   *
   * @param colspan
   */
  private setColspan(colspan: number = defaultTileConfig.sm) {
    this.matGridTile.colspan = colspan;
  }
}
