<ng-container *ngIf="sourceNodes$ | async as sourceNodes; else loading">
  <ng-container *ngIf="protectionGroup$ | async as protectionGroup; else loading">
    <ng-container *ngIf="job$ | async as job">
      <ng-container *ngIf="protectionRun$ | async as run">
        <coh-objects-select-tree #sourceTree
          [environment]="getSourceEnvironment(protectionGroup.environment)"
          [job]="job"
          [data]="sourceNodes"
          [run]="run">
        </coh-objects-select-tree>
        <header class="group-actions-header">
          <cog-icon size="lg" [shape]="{status: (protectionGroup.protectionType ?? protectionGroup.environment), type: 'environment'} | statusIconMap">
          </cog-icon>
          <div>
            <h2 mat-dialog-title translate>
              runProtectionNow
            </h2>
            <mat-card-subtitle>
              <ul class="subtitle-list">
                <li [matTooltip]="protectionGroup.name">{{ protectionGroup.name }}</li>
                <ng-container *ngIf="policy$ | async as policy">
                  <li *ngIf="!policy.isReplicated" [matTooltip]="policy.name">
                    {{ 'policy' | label: policy.name }}
                  </li>
                </ng-container>
              </ul>
            </mat-card-subtitle>
          </div>
        </header>
        <ng-container *ngIf="sourceTree.hasProtectedObjects || allowEmptyProtectionGroup; else noProtectedObjectsMessage">
          <form *ngIf="formGroup$ | async as formGroup; else loading" cohValidateOnSubmit cogFormFocusedHighlight
            [useNativeDomQuery]="true" [formGroup]="formGroup" (validatedSubmit)="onSubmit()">
            <mat-dialog-content class="mat-typography" *ngIf="policy$ | async as policy">
              <cog-settings-list [alwaysOpen]="true" cogFormGroup>
                <coh-group-backup-form [formGroup]="formGroup"
                  [protectionGroup]="protectionGroup"
                  [policy]="policy"
                  [sourceNodes]="sourceNodes$ | async"
                  [job]="job$ | async"
                  [run]="protectionRun$ | async"
                  [jobNodes]="sourceTree.dataTreeSource.filteredData$ | async">
                </coh-group-backup-form>
                <coh-group-archive-form *ngFor="let archiveForm of formGroup.archiveFormArray" [formGroup]="archiveForm"
                  [protectionGroup]="protectionGroup" [policy]="policy">
                </coh-group-archive-form>
                <coh-group-cloudvault-form *ngFor="let rpaasForm of formGroup.rpaasFormArray" [formGroup]="rpaasForm"
                  [protectionGroup]="protectionGroup" [policy]="policy">
                </coh-group-cloudvault-form>
                <coh-group-replication-form *ngFor="let replicationForm of formGroup.replicationFormArray"
                  [formGroup]="replicationForm" [protectionGroup]="protectionGroup" [policy]="policy">
                </coh-group-replication-form>
              </cog-settings-list>
            </mat-dialog-content>
            <mat-dialog-actions align="end">
              <button cogCancelButton mat-button mat-dialog-close color="primary" cogDataId="cancel-btn">
                {{ 'cancel' | translate }}
              </button>
              <button cogSubmitButton mat-flat-button type="submit" color="primary" cdkFocusInitial
                [loading]="busy$ | async" cogDataId="save-btn">
                {{ 'run' | translate }}
              </button>
            </mat-dialog-actions>
          </form>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
<ng-template #loading>
  <mat-dialog-content>
    <cog-spinner></cog-spinner>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close color="primary" cogDataId="cancel-btn">
      {{ 'cancel' | translate }}
    </button>
  </mat-dialog-actions>
</ng-template>
<ng-template #noProtectedObjectsMessage>
  <mat-dialog-content>
    <cog-banner cogDataId="no-sources-banner" status="warn">
      {{ 'backupNowModal.noObjectsMessage' | translate }}
    </cog-banner>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close color="primary" cogDataId="cancel-btn">
      {{ 'cancel' | translate }}
    </button>
  </mat-dialog-actions>
</ng-template>
