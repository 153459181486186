<cdk-virtual-scroll-viewport *ngIf="data && treeSelection && treeControl.dataNodes"
  itemSize="48"
  minBufferPx="1000"
  maxBufferPx="2000">
  <ng-container *cdkVirtualFor="let item of data.currentView$ | async"></ng-container>
  <mat-tree [dataSource]="data"
    [treeControl]="treeControl">
    <mat-tree-node *matTreeNodeDef="let node">
      <cog-data-tree-node [node]="node"
        [selection]="treeSelection"
        [treeControl]="treeControl"
        [treeDetails]="treeDetails.toArray()"
        [nodeDecoratorFn]="nodeDecoratorFn">
      </cog-data-tree-node>
    </mat-tree-node>
  </mat-tree>
</cdk-virtual-scroll-viewport>
