// Module and Component: cJobObjects

;(function(angular, undefined) {
  'use strict';

  var modName = 'C.jobObjects';

  // TODO: add C.evalAjax when the following CR is committed:
  // https://cohesity-review.appspot.com/257207006
  var moduleDependencies = [
    'C.pubJobService',
    'C.pubJobServiceFormatter',
    'C.constants',
    'C.utils',
  ];
  var componentName = 'cJobObjects';
  var options = {
    bindings: {

      /**
       * Required protection job setting.
       *
       * @type   {Object}   job    protection job setting
       */
      job: '=',

      /**
       * Optional tree. If not provided, it will be fetched by the component.
       *
       * @type   {Object}   jobTree    jobtree object, includes meta info and
       * the list of tree nodes
       */
      jobTree: '=?',

      /**
       * Optional viewOnly prop.
       * if true then entity tree will be in view mode and else edit mode.
       *
       * @type   {boolean}   viewOnly
       */
      viewOnly: '=?',

      /**
       * Optional function to expose and handle a "select objects" link.
       *
       * @type   {function}   selectObjects
       */
      selectObjects: '&?',
    },
    require: { ngModel: 'ngModel' },
    controller: 'JobObjectsCtrl',
    templateUrl: 'app/protection/jobs/job-objects/job-objects.html',
  };

  angular
    .module(modName, moduleDependencies)
    .controller('JobObjectsCtrl', cJobObjectsCtrlFn)
    .component(componentName, options);

  /**
   * @ngdoc component
   * @name C.jobObjects:cJobObjects
   * @function
   *
   * @description
   * Provides a shopping cart style listing of objects protected by a given Job.
   *
   * @example
   * Omit tree and the component will get it internally.
   * <c-job-objects job="$ctrl.job"></c-entity-tree>
   *
   * Can provide the tree externally, and provide a function for modifying objs.
   * <c-job-objects
   *   job="$ctrl.job"
   *   job-tree="$ctrl.jobTree"
   *   select-objects="$ctrl.selectObjects()"
   * ></c-entity-tree>
   *
   * Used for display purposes only (job detail page).
   * <c-job-objects job="$ctrl.job" view-only>
   * </c-entity-tree>
   */
  function cJobObjectsCtrlFn($scope, PubJobService, PubJobServiceFormatter,
    evalAJAX, ENV_GROUPS, _) {
    var $ctrl = this;

    /**
     * c-entity-tree options
     *
     * @type   {Object}    entityTreeOptions
     * @type   {Object}    entityTreeOptions.selectedObjects
     * @type   {boolean}   entityTreeOptions.selectedObjects.hideExcludedNodes
     * @type   {Object}    entityTreeOptions.excludeObjects
     * @type   {boolean}   entityTreeOptions.selectedObjects.isExcludedBranch
     */
    $ctrl.entityTreeOptions = {
      selectedObjects: {
        hideExcludedNodes: true,
        onUpdate: updateSelectedSourcesList,
      },
      excludeObjects: {
        isExcludedBranch: true,
        onUpdate: updateSelectedSourcesList,
      },
    };

    /**
     * (Possibly) abbreviated list of selected Job Sources. Will be fully
     * representative of the selections when < $ctrl.state.viewLimit
     *
     * @type   {Object[]}   shortListSources
     */
    $ctrl.shortListSources = [];

    /**
     * list of all selected Job Sources
     *
     * @type   {Object[]}   sourcesAll
     */
    $ctrl.sourcesAll = [];

    /**
     * list of exclude job Sources
     *
     * @type   {Object[]}   autoProtectedNodes
     */
    $ctrl.excludeSources = [];

    /**
     * list auto protected job Nodes
     *
     * @type   {Object[]}   autoProtectedNodes
     */
    $ctrl.autoProtectedNodes = [];

    /**
     * holds component UI state.
     *
     * @type   {Object}    state
     * @type   {boolean}   state.loadingTree  is tree loaded
     * @type   {boolean}   state.expandCart   show less or all selected nodes
     * @type   {number}    state.viewLimit    number of nodes to show by default
     */
    $ctrl.state = {
      loadingTree: false,
      expandCart: false,
      showMore: false,
      viewLimit: 3,
      autoProtectSupported: true,
      sourceSpecialParamSupported: false,
    };

    /**
     * Initialization function
     *
     * @method   $onInit
     */
    $ctrl.$onInit = function $onInit() {

      // If the tree wasn't bound, it should be loaded manually.
      if (!$ctrl.jobTree) {
        loadTree();
      } else if ($ctrl.jobTree && $ctrl.jobTree.tree.length) {
        $ctrl.state.loadingTree = false;
        updateSelectedSourcesList();
      }

      $ctrl.state.autoProtectSupported =
        ENV_GROUPS.autoProtectSupported.includes($ctrl.job.environment);

      $ctrl.state.sourceSpecialParamSupported =
        ENV_GROUPS.sourceSpecialParamSupported.includes($ctrl.job.environment);

      // Watch for relevant changes in the job and update the displayed sources
      // list accordingly. NOTE: This previously watched the entire job object.
      // While convenient, this has some performance downsides as some steps in
      // the job flow decorate the job object with _selectedSources (including
      // child nodes). When many objects are selected or something low level
      // in the tree, this causes the digest cycle to slow to a crawl.
      $scope.$watch('$ctrl.job.sourceIds', updateSelectedSourcesList, true);
      $scope.$watch('$ctrl.job.excludeSourceIds',
        updateSelectedSourcesList, true);
      $scope.$watch('$ctrl.job.vmTagIds', updateSelectedSourcesList, true);
      $scope.$watch('$ctrl.job.excludeVmTagIds',
        updateSelectedSourcesList, true);
      $scope.$watch('$ctrl.job.sourceSpecialParameters',
        updateSelectedSourcesList, true);
    };

    /**
     * binding change listener
     *
     * @method   $onChanges
     * @param    {object}   changesObj   The changes object
     */
    $ctrl.$onChanges = function $onChanges(changesObj) {

      // on tree change filter out selected sources to show.
      if (changesObj.jobTree && changesObj.jobTree.tree.length) {
        $ctrl.state.loadingTree = false;
        updateSelectedSourcesList();
      }
    };

    /**
     * Calculates and returns the "more" count for use in the "> N More" link.
     *
     * @method   getMoreCount
     * @return   {integer}   The more count.
     */
    $ctrl.getMoreCount = function getMoreCount() {
      return $ctrl.sourcesAll.length +
        $ctrl.excludeSources.length -
        $ctrl.shortListSources.length;
    };

    /**
     * Load the tree for the provided job.
     *
     * @method   loadTree
     */
    function loadTree() {
      $ctrl.state.loadingTree = true;

      // reset the tree
      $ctrl.jobTree = {};

      PubJobService.getJobTree($ctrl.job).then(
        function getTreeSuccess(jobTree) {
          $ctrl.jobTree = jobTree;
        },
        evalAJAX.errorMessage
      ).finally(
        function getTreeFinally() {
          $ctrl.state.loadingTree = false;
        }
      );
    }

    /**
     * update selected sources list
     *
     * @method   updateSelectedSourcesList
     */
    function updateSelectedSourcesList() {
      $ctrl.sourcesAll = PubJobServiceFormatter.findNodesByNodeIds(
        $ctrl.jobTree.tree,
        $ctrl.job.sourceIds.concat($ctrl.job.vmTagIds)
      );

      // Sort the list, pushing auto protected nodes to the bottom.
      $ctrl.sourcesAll =
        _.orderBy($ctrl.sourcesAll, ['_isAutoProtected'], ['asc']);

      $ctrl.excludeSources = PubJobServiceFormatter.findNodesByNodeIds(
        $ctrl.jobTree.tree,
        $ctrl.job.excludeSourceIds.concat($ctrl.job.excludeVmTagIds)
      );

      $ctrl.autoProtectedNodes =
        PubJobServiceFormatter.findAutoProtectedNodes($ctrl.sourcesAll);

      $ctrl.selectedLeafObjects = $ctrl.sourcesAll.filter(
        function getLeaves(node) {
          return node._isLeaf;
        }
      );

      $ctrl.shortListSources = $ctrl.sourcesAll.slice(0, $ctrl.state.viewLimit);

      $ctrl.state.showMore = !!$ctrl.getMoreCount();

      $ctrl.ngModel.$setViewValue($ctrl.sourcesAll);
    }
  }

})(angular);
