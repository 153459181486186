import { Injectable } from '@angular/core';

import { PersistService } from './persist.service';

/**
 * They key for save and retrieve
 * from the PersistService
 */
export const DONT_SHOW_AGAIN_KEY = 'dontShowAgain';

/**
 * This enum is used to standardize the values
 * that are used to store when user's tell
 * us to not show them this thing again.
 */
export enum DontShowAgainEnum {
  navigationToReportStorageConsumptionByCluster = 'navigationWarning:/reporting/detail/storage-consumption-cluster',
  navigationToReportStorageConsumptionByService = 'navigationWarning:/reporting/detail/service-consumption',
  navigationToReportProtectionRuns = 'navigationWarning:/reporting/detail/protection-runs',
  navigationSwitchScope = 'navigationWarning:scopeChange',
}

/**
 * This is a thin layer over the PersistService that allows
 * an array of items to be stored under 1 key.  If the item exists
 * then the user has asked for that item to never be seen again.
 */
@Injectable({
  providedIn: 'root',
})
export class DontShowAgainService {

  constructor(private persistService: PersistService) {
  }

  /**
   * Whether this key has been set to not show again
   *
   * @param key - to lookup value
   * @returns boolean - true if set to not show again
   */
  isDontShowAgain(key: string): boolean {
    const keysAsString = this.persistService.get(DONT_SHOW_AGAIN_KEY) || '';
    return !!keysAsString.includes(key);
  }

  /**
   * Return the array of keys not to show again
   *
   * @returns the array of keys or empty array
   */
  private getDontShowAgainKeys(): string[] {
    return JSON.parse(this.persistService.get(DONT_SHOW_AGAIN_KEY) || '[]');
  }

  /**
   * Sets whether or not to remember the key and not show again.
   *
   * @param key - the key used to retrieve this value
   * @param value - boolean to remember the key or not
   * @returns void
   */
  dontShowAgain(key: string, value: boolean) {
    const isIgnored = this.isDontShowAgain(key);
    if (isIgnored === value) {
      return;
    }
    const keys = this.getDontShowAgainKeys();
    if (value) {
      keys.push(key);
    } else {
      // Remove the key from the array
      const index = keys.indexOf(key);
      if (index > -1) {
        keys.splice(index, 1);
      }
    }
    this.persistService.set(DONT_SHOW_AGAIN_KEY, JSON.stringify(keys));
  }
}
