// Component:  Days Selector

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.selectDays', [])
    .component('selectDays', {
      controller: selectDaysControllerFn,
      require: {
        ngModel: 'ngModel',
      },
      templateUrl: 'app/global/days-selector/days-selector.html',
      bindings: {
        // @type   {String}    name                     a name for the widget
        name: '@?',
        // @type   {Boolean}   [single=false]           Should only a single
        //                                              day selection be allowed
        single: '<?',
        // @type   {Boolean}   [showAllOption=false]    Should "everyday"
        //                                              checkbox be shown
        showAllOption: '@?',
      },
    });

  /**
   * @ngdoc component
  * @name C.selectDays:selectDays
   * @function
   *
   * @description
   *   Reusable component for selecting a set of days in array format with
   *   kValues.  i.e. ['kMonday', 'kWednesday']
   *
   * @examples
     <select-days
       name="externalElementNameDays"
       required
       ng-model="schedulingPolicy.dailySchedule.days"></select-days>

     <select-days
       name="externalElementNameDay"
       required
       single="true"
       ng-model="policy.incrementalSchedulingPolicy.monthlySchedule.day">
     </select-days>
   */
  function selectDaysControllerFn($timeout, $attrs) {

    /**
     * Controller reference.
     *
     * @type   {object}
     */
    var $ctrl = this;

    /**
     * Initialize this component.
     *
     * @method   init
     */
    $ctrl.$onInit = function init() {
      $ctrl.name = $ctrl.name || 'daysSelector';
      $ctrl.id = $ctrl.name;

      $ctrl.days = [
        'kSunday',
        'kMonday',
        'kTuesday',
        'kWednesday',
        'kThursday',
        'kFriday',
        'kSaturday',
      ];

      // update internal model on external model updates
      $ctrl.ngModel.$render = syncModelValue;
    };

    /**
     * Sets the state of "everyday" checkbox to checked/unchecked based on the
     * state of other days if they are clicked individually
     *
     * @method   checkEverydayStatus
     * @param    {boolean}   checked   Is everyday clicked explicitly
     */
    function checkEverydayStatus(isEveryDayClicked) {
      $ctrl.internalModel = $ctrl.internalModel || [];

      if (angular.isDefined(isEveryDayClicked)) {
        $ctrl.isAllSelected = isEveryDayClicked;
        if (isEveryDayClicked) {
          $ctrl.internalModel = angular.copy($ctrl.days);
        } else {
          $ctrl.internalModel = [];
        }
      } else {
        $ctrl.isAllSelected = ($ctrl.internalModel.length === 7);
      }
    }

    /**
     * Updates the external model based on changes to the internal model, fired
     * via uiSelects on-change and on-remove callbacks
     */
    $ctrl.updateExternalModel =
      function updateExternalModel(isEveryDayClicked) {

        if ($ctrl.showAllOption) {
          checkEverydayStatus(isEveryDayClicked);
        }

        syncModelValue($ctrl.internalModel);

        // If dealing with an array (multi day selector), handle external
        // required validation manually because an empty array would still pass
        // "required" validation. Set the  validity based on the length of the
        // array to ensure at least one day has been selected.
        if ($ctrl.ngModel.$validators.required && !$ctrl.single) {
          $ctrl.ngModel.$setValidity('required', !!$ctrl.internalModel.length);
        }
      };

    /**
     * Sync internal & external model values.
     *
     * @method   syncModelValue
     * @param    {Object[]}   newValue   The new model value to set
     */
    function syncModelValue(newValue) {
      if (arguments.length) {
        $ctrl.ngModel.$setViewValue(newValue);
        return;
      }

      if ($ctrl.ngModel.$isEmpty($ctrl.ngModel.$modelValue) ||
        (!$ctrl.single && !angular.isArray($ctrl.ngModel.$modelValue))) {
        // initialize internal model
        $ctrl.internalModel = undefined;

        // initialize external model in next digest cycle and setting it to
        // undefined to make required validation works as expected
        $timeout(function setExternalModel() {
          $ctrl.ngModel.$setViewValue(undefined);
        });
      } else {
        $ctrl.internalModel = $ctrl.ngModel.$modelValue;
      }

      checkEverydayStatus();
    }

  }

})(angular);
