import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {
  Controls,
  NgxSubFormComponent,
  subformComponentProviders,
  TypedFormGroup,
} from 'ngx-sub-form';
import { getHostOSTypeLabelKey } from 'src/app/shared';
import { DynamicFormComponent } from 'src/app/modules/restore/restore-shared';
import { UdaRegistrationFormComponentProperties } from 'src/app/modules/sources-shared/models';
import { GenericSourceFormParams } from './models/generic-source-form.params';

/**
 * Source registration form component for a generic UDA source type.
 */
@Component({
  selector: 'coh-uda-generic-source',
  templateUrl: './uda-generic-source.component.html',
  providers: subformComponentProviders(UdaGenericSourceComponent),
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UdaGenericSourceComponent
  extends NgxSubFormComponent<GenericSourceFormParams>
  implements OnDestroy {

  /**
   * The parent form group.
   */
  @Input() parent: TypedFormGroup<GenericSourceFormParams>;

   /**
    * The parent form control.
    */
  @Input() formControl: AbstractControl;

   /**
    * Whether the form has been rendered in edit mode.
    */
  @Input() editMode = false;

  /**
   * List of supported host operating system types.
   */
  hostOsTypes: string[] = ['kAix', 'kLinux', 'kWindows'];

  /**
   * Function that return the label key for a given host os type.
   */
  getHostOSTypeLabelKey = getHostOSTypeLabelKey;

  protected getFormControls(): Controls<GenericSourceFormParams> {
    return {
      hostOsType: new UntypedFormControl(null, Validators.required),
      primaryFieldParams:  new UntypedFormControl(null),
    };
  }
}

/**
 * Wrapper to support rendering of UDA source registration form component
 * inside dynamic form portal.
 */
 @Component({
  template: `
    <coh-uda-generic-source
      [parent]="parent"
      [formControl]="formControl"
      [editMode]="properties?.editMode || false">
    </coh-uda-generic-source>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UdaGenericSourceWrapperComponent
  implements DynamicFormComponent<UdaGenericSourceComponent, GenericSourceFormParams> {

  /**
   * Additional properties passed for this protection item.
   */
  properties: UdaRegistrationFormComponentProperties;

  /**
   * The instance of the actual form options component.
   */
  @ViewChild(UdaGenericSourceComponent, { static: true })
  instance: UdaGenericSourceComponent;

  constructor(
    @Inject(AbstractControl) readonly formControl: AbstractControl,
    @Inject(UntypedFormGroup) readonly parent: TypedFormGroup<GenericSourceFormParams>
  ) {}
}
