import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { BlankCardModule, DataIdModule, IconModule, SpinnerModule } from '@cohesity/helix';
import { ClearSubscriptions } from '@cohesity/utils';
import { TranslateModule } from '@ngx-translate/core';

import { AccessTableRowAction, AccessTableRowActionId } from '../../models';
import { AccessScopeListingService } from '../../services';
import { ObjectAccessRolesTableComponent } from '../object-access-roles-table/object-access-roles-table.component';

@Component({
  standalone: true,
  imports: [
    BlankCardModule,
    CommonModule,
    DataIdModule,
    IconModule,
    MatButtonModule,
    MatDialogModule,
    ObjectAccessRolesTableComponent,
    SpinnerModule,
    TranslateModule,
  ],
  selector: 'coh-object-access-role-list',
  templateUrl: './object-access-role-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ObjectAccessRoleListComponent extends ClearSubscriptions {
  constructor(
    public accessScopeListingService: AccessScopeListingService,

  ) {
    super();
    this.accessScopeListingService.fetchData();
  }

  /**
   * Callback for the "rowAction" event on the table.
   *
   * @param event The event object.
   */
  onTableRowAction(event: AccessTableRowAction): void {
    switch (event.action) {
      case AccessTableRowActionId.delete:
        this.accessScopeListingService.openDeleteDialog(event.rowItem.id);
        break;
      case AccessTableRowActionId.edit:
        this.accessScopeListingService.onAddEditButtonClick('edit', event.rowItem);
        break;
      default:
        console.log('Action not supported');
        break;
    }
  }

}
