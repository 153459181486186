import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

import { TranslateFn } from '../../data-renderers/data-renderer.model';
import { ColumnConfig } from '../column-config.model';

@Component({
  selector: 'cog-table-config-form',
  templateUrl: './table-config-form.component.html',
  styleUrls: ['./table-config-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TableConfigFormComponent {
  /**
   * Translate function.
   */
  @Input() translate: TranslateFn;

  /** The safe copy of column. */
  private _columns: ColumnConfig[];

  /**
   * The initial columns input.
   */
  @Input() set columns(columns: ColumnConfig[]) {
    // setting enabled true by default if its not defined.
    this._columns = columns.map(column => ({ ...column, enabled: column.enabled ?? true }));
  }

  get columns() {
    return this._columns || [];
  }

  /**
   * Enable column reordering.
   */
  @Input() enableReordering = false;

  /**
   * Fires whenever the user edits the columns
   */
  @Output() columnsChange = new EventEmitter<ColumnConfig[]>(true);

  /**
   * Returns title translation.
   *
   * @param    title  Original title key.
   * @returns  Title translation.
   */
  getTitleTranslation(title: string): string {
    if (this.translate) {
      return this.translate(title);
    }
    return title;
  }

  /**
   * Toggle the column visibity.
   *
   * @param columnToggled The column toggled.
   */
  toggleColumnVisibility(columnToggled: ColumnConfig) {
    columnToggled.enabled = !columnToggled.enabled;
    this.columnsChange.emit([...this.columns]);
  }

  /**
   * Reorder the columns.
   *
   * @param event DragDrop event containing the item moved to-from location details.
   */
  reorderColumns(event: CdkDragDrop<ColumnConfig[]>) {
    moveItemInArray<ColumnConfig>(this.columns, event.previousIndex, event.currentIndex);
    this.columnsChange.emit([...this.columns]);
  }
}
