import { Component, Inject, OnInit } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { UntypedFormControl } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { HeliosAccountsServiceApi, HeliosAccount, HeliosAccounts } from '@cohesity/api/v2';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { AutoDestroyable } from '@cohesity/utils';
import { AjsUpgradeService, UserService } from 'src/app/core/services';
import { CompanyAccount } from '../company-account-table/company-account-table.component';

/**
 * This component displays a list of company accounts and allow users to switch to.
 */
@Component({
  selector: 'coh-switch-account-dialog',
  styleUrls: ['./switch-account-dialog.component.scss'],
  templateUrl: './switch-account-dialog.component.html'
})
export class SwitchAccountDialogComponent extends AutoDestroyable implements OnInit {
  /**
   * Legacy HeliosService AngularJS service.
   */
  private ajsHeliosService: any;

  /**
   * Legacy UserService AngularJS service.
   */
  private ajsUserService: any;

  /**
   * True if the current logged in user is a support user.
   */
  isSupportUser = false;

  /**
   * Async list of company accounts.
   */
  accounts$: Observable<CompanyAccount[]>;

  /**
   * Stores the selected row.
   */
  selection = new SelectionModel<CompanyAccount>(false, []);

  /**
   * Case ID form control for support user.
   */
  caseIdControl = new UntypedFormControl();

  /**
   * Stores the error message for case ID input form.
   */
  errorMessage: string;

  /**
   * Constructor.
   */
  constructor(
    private ajsUpgrade: AjsUpgradeService,
    private heliosAccountsService: HeliosAccountsServiceApi,
    private dialogRef: MatDialogRef<SwitchAccountDialogComponent>,
    private translate: TranslateService,
    private userService: UserService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super();
    this.ajsHeliosService = ajsUpgrade.get('HeliosService');
    this.isSupportUser = this.ajsHeliosService.isSalesforceUser('support');
  }

  /**
   * Initialization.
   */
  ngOnInit() {
    if (!this.isSupportUser) {
      this.accounts$ = this.heliosAccountsService.GetAccounts({}).pipe(
        catchError(() => of({})),
        map((response: HeliosAccounts) => {
          if (response && response.accounts) {
            return response.accounts.map((account: HeliosAccount) =>
              ({name: account.companyName, id: account.salesforceAccountId})
            );
          }
          return [];
        })
      );
    }
  }

  /**
   * Switch account for the user.
   */
  switchAccount() {
    const oldAccountId = this.ajsHeliosService.getImpersonatedSalesforceAccountId();
    let newAccountId: string;

    if (this.isSupportUser) {
      const supportCaseId = this.caseIdControl.value.trim();

      this.errorMessage = undefined;
      this.heliosAccountsService.GetAccounts({ supportCaseId }).pipe(
        this.untilDestroy(),
        tap((response: HeliosAccounts) => {
          if (response?.accounts?.length) {
            const account = response.accounts[0];

            newAccountId = account.salesforceAccountId;
            this.ajsHeliosService.setSalesforceAccount({
              id: newAccountId,
              name: account.companyName,
              caseId: supportCaseId
            });
          } else {
            this.errorMessage = this.translate.instant('noCompanyFoundFromCaseId');
          }
        }),
        catchError((error) => {
          if (error && error.error && error.error.message) {
            this.errorMessage = error.error.message;
          } else {
            this.errorMessage = this.translate.instant('getCompanyByCaseIdError');
          }
          return of(null);
        }),
        filter((response: HeliosAccounts) => Boolean(response?.accounts?.length)),
        switchMap(() => this.userService.updateImpersonationUser(oldAccountId, newAccountId)),
      ).subscribe(() => {
        this.dialogRef.close();

        // Go to home page and refresh to get rid of data in memory
        location.assign('/');
      });
    } else {
      const {selected} = this.selection;

      if (selected && selected.length) {
        this.ajsHeliosService.setSalesforceAccount(selected[0]);
        this.userService.updateImpersonationUser(oldAccountId, selected[0].id).subscribe(() => {
          this.dialogRef.close();

          // Go to home page and refresh to get rid of data in memory
          location.assign('/');
        });
      }
    }
  }
}
