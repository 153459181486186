/* eslint-disable */
import { Injectable } from '@angular/core';

/**
 * Global configuration for CohesityApiSizer services
 */
@Injectable({
  providedIn: 'root',
})
export class CohesityApiSizerConfiguration {
  rootUrl: string = '/simulator';
}

export interface CohesityApiSizerConfigurationInterface {
  rootUrl?: string;
}
