<div *ngIf="label">{{ label }}</div>
<button mat-button [matMenuTriggerFor]="selectorMenu">
  <cog-icon *ngIf="iconName" [shape]="iconName" class="margin-right-xs"></cog-icon>
  {{ selectedOption?.label }}
  <mat-icon>arrow_drop_down</mat-icon>
</button>
<mat-menu #selectorMenu="matMenu">
  <button mat-menu-item *ngFor="let option of options"
    [class.active]="isSelected(option)"
    (click)="value = option.value">
    <mat-icon>{{ isSelected(option) ? 'check' : '' }}</mat-icon>
    {{ option.label }}
  </button>
</mat-menu>
