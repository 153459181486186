import { Directive, Injector, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

@Directive({
  selector: 'ajs-search'
})
export class SearchComponent extends UpgradeComponent {
  /**
   * Optional: Fn to preprocess response from server.
   */
  @Input() preProcessResultsFn: Function;

  /**
   * Optional: Wheather to exclude jobs or not while cloning or recovering the VMs.
   */
  @Input() excludeJobs: boolean;

  /**
   * Required: The full collection, optionally preProcessed.
   */
  @Input() collection: any[];

  /**
   * Change event output for collection.
   */
  @Output() collectionChange = new EventEmitter<any[]>();

  /**
   * Required: Unique identifier of this cSearch instance.
   */
  @Input() searchId: string;

  /**
   * Optional: Collection of available filters. Converted to url.
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('filters') filterProps: any[];

  /**
   * Optional: Endpoint URL to query
   */
  @Input() endpoint: string;

  /**
   *   Optional: Hash of filter lookup options. Each hash key _must_ match a filterProps[n].property
   * NOTE: Some clone/recover implementations are providing this option
   * and others are leveraging the template fallbacks to $parent.$parent.*
   */
  @Input() filterOptions: Object;


  /**
   * ptional: Determines if this instance can add multiple items to the cart at once. Default: false.
   */
  @Input() multiSelect: boolean;

  /**
   * Optional: One of ['activeDirectory', vm', 'file', 'sql', 'oracle', 'mountPoint']
   */
  @Input() searchType: string;

  /**
   * Optional: Provided a pre-defined search query.
   */
  @Input() searchQuery: string;

  /**
   * Optional: Boolean values specifying whether the search is to be
   * executed on Private or Public IRIS APIs.
   * Default: false(Execution will occue on private APIs).
   */
  @Input() searchPublicApi: boolean;

  /**
   * Optional: Hide the search input so that the component can be controlled from external inputs.
   */
  @Input() hideInput: boolean;

  /**
   * Optional: Use to toggle whether the showAddFilters are showing. This is used along with hide
   * inputs so that the filter button can be controlled by a separate component.
   */
  @Input() showAddFilters: boolean;

  /**
   * The change event output for showAddFilters.
   */
  @Output() showAddFiltersChange = new EventEmitter<boolean>();

  /**
   * Optional: This is updated by the component whenever data is being loaded. This is used along with hide
   * inputs so that a loading spinner can be shown by a separate component.
   */
  @Input() dataLoading: boolean;

  /**
   * The change event output for dataLoading.
   */
  @Output() dataLoadingChange = new EventEmitter<boolean>();

  constructor(injector: Injector, elementRef: ElementRef) {
    super('cSearch', elementRef, injector);
  }
}
