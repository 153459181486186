import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WindowRef } from '@cohesity/helix';
import { LifeCycles } from 'single-spa';

import { CustomProps } from '../../shared';

@Injectable()
export class ScriptLoaderService {
  constructor(
    private http: HttpClient,
    private windowRef: WindowRef,
  ) {}

  /**
   * Loads the script packaged using UMD packaging.
   *
   * NOTE: loading manifest.json containing the main.js file with webpack contenthash used for cache bursting.
   *
   * @param publicPath The script publicPath.
   * @param globalVarName The UMD module exported global variable name.
   * @returns Promise resolved with loaded UMD module exported value else rejected with error.
   */
  loadScript = (publicPath: string, globalVarName: string) =>
    this.http.get<Record<string, string>>(`${publicPath}/assets-manifest.json?cacheBuster=${+Date.now()}`)
      .toPromise()
      .then(manifest => new Promise<LifeCycles<CustomProps>>((resolve, reject) => {
        const scriptSrc = manifest['main.js'] || 'main.js';

        // creating a script tag to load the script manually.
        const scriptEl = this.windowRef.nativeDocument.createElement('script') as HTMLScriptElement;

        scriptEl.src = `${publicPath}/${scriptSrc}`;
        scriptEl.async = true;
        scriptEl.type = 'module';

        // on successful loading resolve with exported UMD module value.
        scriptEl.onload = () => resolve(window[globalVarName]);

        // on error reject with error.
        scriptEl.onerror = err => reject(err);

        // adding script element into the dom to activate it.
        this.windowRef.nativeDocument.body.appendChild(scriptEl);
      }));
}
