import { Injectable, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppPillarsService {
  /**
   * Hide unsubscribed services in app switcher if true.
   */
  @Input() set hideUnsubscribedServices(hideUnsubscribedServices: boolean) {
    this.hideUnsubscribedServicesSubject.next(hideUnsubscribedServices);
  }

  get hideUnsubscribedServices(): boolean {
    return this.hideUnsubscribedServicesSubject.value;
  }

  /**
   * Behavior Subject of hideUnsubscribedServices.
   */
  private hideUnsubscribedServicesSubject = new BehaviorSubject<boolean>(false);

  /**
   * Async hideUnsubscribedServices.
   */
  public hideUnsubscribedServices$ = this.hideUnsubscribedServicesSubject.asObservable();
}
