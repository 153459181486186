<!-- Only show this setting for VMWare VMs. -->
<cog-settings-list-item [formGroup]="formGroup"
  [invalid]="formControl.invalid"
  [label]="'leverageSanTransportForDataProtection' | translate">
  <cog-settings-list-item-value-display>
    <p>
      {{sanTransportFormGroup.value.sanTransportEnabled | boolLabel}}
    </p>
    <p *ngIf="showAllowNbdsslTransportFallback">
      {{'allowNbdsslTransportFallbackColon' | translate}}
      {{sanTransportFormGroup.value.allowNbdsslTransportFallback | boolLabel}}
    </p>
  </cog-settings-list-item-value-display>
  <cog-settings-list-item-value-editor [formGroup]="sanTransportFormGroup"
    cogDataId="settings-leverage-san-transport">
    <mat-slide-toggle formControlName="sanTransportEnabled" cogDataId="sanTransportEnabledToggle" class="leverage-san-transport-toggle"
    >
      {{'leverageSanTransportForDataProtection' | translate}}
    </mat-slide-toggle>
    <mat-icon class="margin-left-sm hint-icon" [cogPopover]="popoverTemplate" *ngIf="isDisabled">info</mat-icon>

    <mat-slide-toggle class="margin-top-sm"
    formControlName="allowNbdsslTransportFallback"
      *ngIf="showAllowNbdsslTransportFallback"
      cogDataId="allowNbdsslTransportFallbackToggle">
      {{'allowNbdsslTransportFallback' | translate}}
    </mat-slide-toggle>
  </cog-settings-list-item-value-editor>

  <ng-template #popoverTemplate>
    <div class="san-toggle-info-popover-container">
      {{ getDisabledInfoMsg() | translate}}
    </div>
  </ng-template>

</cog-settings-list-item>
