<div class="flex-end dialog-header">
  <h2 mat-dialog-title>{{ 'generateCohesityCertificateDialog.title' | translate }}</h2>
</div>

<mat-dialog-content>
  <coh-generate-cohesity-certificate-interface (interfaceData)="setGenerateCohesityCertificateInterfaceData($event)">
  </coh-generate-cohesity-certificate-interface>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <!-- Cancel button -->
  <button
    mat-button
    mat-dialog-close
    cogCancelButton
    cdkFocusInitial
  >
    {{ 'cancel' | translate }}
  </button>

  <!-- Save button -->
  <button
    mat-button
    cogSubmitButton
    [disabled]="this.interfaceData ? false : true"
    (click)="onGenerate()"
  >
    {{ 'generateCohesityCertificateDialog.generateCertificate' | translate }}
  </button>
</mat-dialog-actions>
