import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, Validators, FormControl, FormGroup } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
} from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';

import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogModule as MatDialogModule,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import {
  MatLegacyFormFieldModule as MatFormFieldModule
} from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { DataIdModule } from '@cohesity/helix';
import { CohesitySharedFormsModule } from '@cohesity/shared-forms';
import { ClearSubscriptions } from '@cohesity/utils';

/**
 * The interface to pass request data to this dialog.
 */
export interface PauseRunsDialogData {
  /**
   * Dialog title
   */
  title: string;

  /**
   * Dialog content
   */
  subtitle?: string;
}

/**
 * The interface to return a response to the caller.
 */
export interface PauseRunsDialogResponse {
  note?: string;
}

/**
 * @description
 *
 * Add note dialog window when pause runs.
 */
@Component({
  selector: 'coh-protection-group-pause-runs-dialog',
  templateUrl: './protection-group-pause-runs-dialog.component.html',
  styleUrls: ['./protection-group-pause-runs-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    DataIdModule,
    MatButtonModule,
    MatDialogModule,
    TranslateModule,
    CohesitySharedFormsModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatCheckboxModule
  ]
})
export class ProtectionGroupPauseRunsDialogComponent extends ClearSubscriptions implements OnInit {
  /**
   * Data injected into the dialog by parent component.
   */
  readonly data: PauseRunsDialogData = inject(MAT_DIALOG_DATA);

  /**
   * Maximum characters for note
   */
  readonly noteMaxLength = 128;

  /**
   * Represents the form.
   */
  pauseRunsForm = new FormGroup({
    note: new FormControl<string>('', {
      validators: [Validators.required, Validators.maxLength(this.noteMaxLength)],
      updateOn: 'change'
    }),
    enableAddNote: new FormControl<boolean>(true)
  });

  constructor(
    public dialogRef: MatDialogRef<PauseRunsDialogData>
  ) {
    super();
  }

  ngOnInit() {
    const enableAddNoteSubscribe = this.pauseRunsForm.controls.enableAddNote?.valueChanges
      .subscribe(checked => {
        const noteControl = this.pauseRunsForm.get('note');
        if (checked) {
          noteControl?.enable();
        } else {
          noteControl?.disable();
          noteControl?.setValue('');
        }
      });
    this.subscriptions.push(enableAddNoteSubscribe);

    // Ensures validation runs immediately on first touch, not just on blur or after submission
    const noteSubscribe = this.pauseRunsForm.controls.note?.valueChanges
      .subscribe(()=> this.pauseRunsForm.get('note').markAsTouched());
    this.subscriptions.push(noteSubscribe);
  }

  /**
   * Handler for the 'validatedSubmit' event on the form.
   */
  onConfirm(): void {
    const resp: PauseRunsDialogResponse = {
      note: this.pauseRunsForm.value.enableAddNote ? this.pauseRunsForm.value.note : ''
    };
    this.dialogRef.close(resp);
  }
}
