import { Component, Input } from '@angular/core';
import { McmClusterServiceApi } from '@cohesity/api/private';
import { FiltersComponent, ValueFilterSelection } from '@cohesity/helix';
import { hasClusters, IrisContextService } from '@cohesity/iris-core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseObjectFilterComponent } from './../base-object-filter';

/**
 * This is a reusable filter that can be used to filter for clusters.
 */
@Component({
  selector: 'coh-cluster-filter',
  templateUrl: './cluster-filter.component.html',
})
export class ClusterFilterComponent extends BaseObjectFilterComponent<null> {
  /**
   * The name of the property containing the cluster id.
   */
  @Input() property = 'cluster';

  /**
   * Can be disabled to allow for quick select of only one cluster.
   */
  @Input() multiSelect = true;

  /**
   * Get whether the user has registered clusters
   */
  get hasClusters(): boolean {
    return hasClusters(this.irisContext.irisContext);
  }

  constructor(
    filters: FiltersComponent,
    private clusterApi: McmClusterServiceApi,
    private irisContext: IrisContextService
  ) {
    super(filters);
  }

  /**
   * Fetch clusters when the component initializes or the params change.
   */
  getFilterValues(): Observable<ValueFilterSelection[]> {
    if (!this.hasClusters) {
      return of([]);
    }

    return this.clusterApi.getClustersConnectionStatus().pipe(
      map(clusters =>
        clusters
          .map(({ clusterId, clusterIncarnationId, name }) => ({
            label: name,
            value: `${clusterId}:${clusterIncarnationId}`,
          }))
      )
    );
  }
}
