import { Pipe, PipeTransform } from '@angular/core';
import { Range } from '@cohesity/api/argus';
import { TranslateService } from '@ngx-translate/core';

@Pipe({ name: 'range' })
export class RangePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(range: Range): string {
    return range ? this.translateService.instant(`argus.enum.range.${range}`) : null;
  }
}
