import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

interface Feature {
  name: string;
}

@Component({
  selector: 'coh-tenant-migration-details-dialog',
  templateUrl: './helios-tenant-migration-dialog.component.html',
  styleUrls: ['./helios-tenant-migration-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeliosTenantMigrationDialogComponent {
  displayedColumns: string[] = ['name'];
  /**
   * title of the modal.
   */
  title: string;

  /**
   * Description of the modal.
   */
  description: string;

  /**
   * Sub title that is rendered above the feature list.
   */
  subtitle: string;

  /**
   * List of disabled features that are displayed in the modal.
   */
  features: Feature[];

  constructor(
    @Inject(MAT_DIALOG_DATA) readonly data,
    public dialogRef: MatDialogRef<HeliosTenantMigrationDialogComponent>,
  ) {
    this.title = data.title;
    this.description = data.description;
    this.subtitle = data.subtitle;
    this.features = [
      { name: 'New source protections' },
      { name: 'Protection edits' },
      { name: 'Unprotect sources' },
      { name: 'Trigger new runs from User Interface' },
      { name: 'Security center' },
      { name: 'Ransomware' },
      { name: 'Licensing' },
      { name: 'Alerts' },
    ];
  }
}
