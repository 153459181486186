import { Range } from '@cohesity/api/argus';
import { ValueFilterSelection } from '@cohesity/helix';
import { sortSensitivity } from '../sensitivity-pattern-chips';
import { RangePipe } from './range.pipe';

/**
 * The list of sorted ranges enum values used by riskLevel and sensitivity.
 */
export const sortedRanges = Object.keys(Range).sort(sortSensitivity) as Range[];

/**
 * The list of unsorted ranges enum values used by riskLevel and sensitivity.
 */
export const unsortedRanges = Object.keys(Range) as Range[];

/**
 * Return the list of filter values for the sorted range enum used by riskLevel and sensitivity.
 *
 * @param rangePipe The range pipe instance.
 * @returns The list of filter values.
 */
export const getSortedRangesFilterValues = (rangePipe: RangePipe): ValueFilterSelection[] =>
  sortedRanges.map(range => ({ label: rangePipe.transform(range), value: range }));

/**
 * Return the list of filter values for the range enum used by riskLevel and sensitivity.
 *
 * @param rangePipe The range pipe instance.
 * @returns The list of filter values.
 */
export const getRangesFilterValues = (rangePipe: RangePipe): ValueFilterSelection[] =>
  unsortedRanges.map(range => ({ label: rangePipe.transform(range), value: range }));
