// Service: cScheduleInfo Service

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.reports')
    .factory('cScheduleInfoService', cScheduleInfoServiceFn);

  function cScheduleInfoServiceFn(_,JobService, SourceService, TenantService,
    ViewBoxCacheService, ExternalTargetService, DAY_PERIODICITY, NavStateService) {

    return {
      rebuildFilterValues: rebuildFilterValues,
    };

    /**
     * when editing a report we need to convert all ids back to human readable
     * values, so when the modal is opened the user can see the filters they
     * have previously selected and be able to modify them
     *
     * @param     {object}    reportFilters       reportFilters object for the
     *                                            scheduled report.
     * @param     {object}    schedule            schedule object.
     * @param     {object}    reportJobParameter  reportFilters object.
     * @return    {object}    a filters object containing filter Names and Ids
     *                        that are used in the c-reports-controls component
     */
    function rebuildFilterValues(reportFilters, schedule, reportJobParameter) {
      var filters = {
        consecutiveFailures: reportFilters.consecutiveFailures,
        lastNDays: reportFilters.lastNDays,
        outputFormat: reportJobParameter.reports[0].outputFormat,
        receiverEmails: reportJobParameter.receiverEmails,
        registeredSourceIds: reportFilters.registeredSourceIds,
        registeredSourceId : reportFilters.registeredSourceId,
        reportId: schedule.id,
        reportType: reportJobParameter.reports[0].type,
        rollup: reportFilters.rollup,
        runStatus: reportFilters.runStatus,
        scheduleHour: schedule.schedules[0].hour,
        sid: reportFilters.sid,
        timezone: reportFilters.timezone,
        unixUid: reportFilters.unixUid,
        vaultIds: reportFilters.vaultIds,
        viewName: reportFilters.viewName,
        jobId: reportFilters.jobId,
      };

      if (reportFilters.viewName) {
        filters.typeAhead = {
          name: reportFilters.viewName,
          id: reportFilters.viewName,
        };
      }

      Object.assign(filters, {
        typeAhead: getEntitiesFromIds(
          JobService.jobsHash,
          reportFilters.jobId, 'name')[0],

        viewBox: getEntitiesFromIds(
          ViewBoxCacheService.viewBoxes,
          reportFilters.viewBoxId, 'name')[0],

        multiSelectTypeAhead: getEntitiesFromIds(
          SourceService.entitiesHash,
          reportFilters.objectIds, 'displayName'),

        registeredSources: getEntitiesFromIds(
          SourceService.sourcesHash, reportFilters.registeredSourceIds ||
          reportFilters.registeredSourceId, 'displayName'),

        selectedTargetNames: getEntitiesFromIds(
          ExternalTargetService.targetNameMapById,
          reportFilters.objectIds),
      });

      if (filters.viewBox) {
        filters.viewBox.viewBoxId = filters.viewBox.id;
      }

      // read from environment in the case of ObjHeatmapReport
      const objectTypeEnum =
        reportFilters.objectType || reportFilters.environment;
      if (objectTypeEnum) {
        filters.objectType = _.find(NavStateService.getObjectTypes(), {
          enum: objectTypeEnum,
        });
      }

      filters.scheduleDays = schedule.schedules.map(
        function mapSchedules(schedule) {
          return DAY_PERIODICITY[schedule.day];
      });

      // Picking the timezome from 1st schedule because we don't support
      // multiple schedules with differnt timezone.
      if (schedule.schedules.length) {
        filters.scheduleTimezone = schedule.schedules[0].timezone;
      }

      return TenantService.extendWithTenantParams(filters,
        reportFilters.tenantIds);
    }

    /**
     * Utility method to check if given id exists in the entityHash and
     * add it to the list conditionally
     *
     * @param     {object}    entityHash      EntityHash containing
     *                                          all entities with id as key
     * @param     {object}    ids             List of ids to
     *                                          fetch from EntityHash
     * @param     {object}    key             key to be used as name
     * @return    {Array}     List of matched entities
     *                          in the format { id, name }
     */
    function getEntitiesFromIds(entityHash = {}, ids = [], key) {
      return [].concat(ids).reduce((out, id) => (!entityHash[id] ? out :
          [...out, { id, name: key ? entityHash[id][key] : entityHash[id] }]
      ), []);
    }
  }
})(angular);
