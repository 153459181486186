import { Directive, Input, OnChanges, SimpleChanges, TemplateRef } from '@angular/core';
import { LegacyMenuPositionX as MenuPositionX } from '@angular/material/legacy-menu';

import {
  DataFilter,
  DateRangeFilter,
  NumberPropertyFilter,
  SearchPropertyFilter,
  ValuePropertyFilter,
} from '../comparators/index';

/**
 * This includes known filter types, or an instance of a data filter.
 * This makes it easier simpler to create common filters.
 */
export type FilterType = 'searchProperty' | 'valueProperty' | 'numberProperty' | 'dateRange' | DataFilter<any>;

/**
 * Params set on `FilterDefDirective` that can be passed to filter component it's attached to.
 */
export interface FilterDefParams {
  /**
   * Indicates if the filter is a "quick" filter or not.
   */
  quickFilter?: boolean;

  /**
   * Allows for customization of the xPosition of the quick filter mat popover.
   */
  quickFilterPositionX?: MenuPositionX;

  /**
   * Optional grouping string which allows multiple filters to be grouped into
   * a singular presentation.
   */
  filterGroup?: string;

  allowSelectionFromSingleFilter?: boolean;
}

/**
 * This is passed to the template to make it easy to access the DataFilter value.
 */
export interface FilterDefContext {
  $implicit: DataFilter<any>;

  /**
   * Extra params from `FilterDefDirective` for filter component use.
   */
  params: FilterDefParams;
}

/**
 * This is a structural directive which is used to manage and edit data filters.
 * The inputs to the filter def define how the filter itself work, and the content
 * of the filter defines how the filters edit controls work.
 *
 * Filter defs are placed inside of cog-filters components to create a list of
 * applied filters.
 *
 * @example
 * <cog-filters>
 *  <ng-template cogFilterDef key="id" label="ID" filterType="searchProperty" let-filter>
 *    <cog-search-property-filter [filter]="filter"></cog-search-property-filter>
 *  </ng-template>
 * </cog-filters>
 */
@Directive({
  selector: '[cogFilterDef]',
})
export class FilterDefDirective implements OnChanges {
  /**
   * The filter type to use. This evaluates to a string of know types, or a
   * custom Data Filter implementation
   */
  @Input('cogFilterDefFilterType') filterType: FilterType;

  /**
   * The property key to use for the filter. This should correspond to a property
   * on the data type being filtered.
   */
  @Input('cogFilterDefKey') key: string;

  /**
   * A ui friendly name for the filter. This will show in the the filter chips
   */
  @Input('cogFilterDefLabel') label: string;

  /**
   * A note to be displayed in the open menu.
   */
  @Input('cogFilterDefNote') note: string;

  /**
   * When quick filter is set to true, the filter will be shown inline on the page.
   */
  @Input('cogFilterDefQuickFilter') quickFilter = false;

  /**
   * When quick filter is set to true, the filter will be shown inline on the page.
   */
  @Input('cogFilterDefQuickFilterPositionX') quickFilterPositionX: MenuPositionX = 'after';

  /**
   * When filterGroup is specified, filters with the same group name will appear in menu popup
   * grouped together, so multiple filters under the same group can be applied at once.
   */
  @Input('cogFilterDefFilterGroup') filterGroup: string;

  @Input('cogFilterDefAllowSelectionFromSingleFilter') allowSelectionFromSingleFilter = false;

  /**
   * Visually hide the filter def after it has been created.
   */
  @Input('cogFilterDefHidden') hidden = false;

  /**
   * The filter group label for quick filter view for column alignment.
   */
  @Input('cogFilterDefQuickFilterGroupLabel') quickFilterGroupLabel: string;

  /**
   * Position of filter inside cog-filters.
   */
  @Input('cogFilterDefPosition') position: number;

  /**
   * This is the context for the filter template.
   */
  context: FilterDefContext;

  /**
   * The actual data filter used by this component.
   */
  filter: DataFilter<any>;

  /**
   * Constructor for the directive
   *
   * @param   template   The template for the filter edit definition.
   */
  constructor(public template: TemplateRef<FilterDefContext>) { }

  /**
   * Update the filter and values when the directive inputs change.
   *
   * @param   changes   A map of modified properties
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes.filterType) {
      this.filter = this.initFilter(this.filterType);
      if (this.filter) {
        this.filter.label = this.label;
        this.filter.note = this.note;
        this.filter.key = this.key;
        this.context = {
          params: {
            allowSelectionFromSingleFilter: this.allowSelectionFromSingleFilter,
            filterGroup: this.filterGroup,
            quickFilter: this.quickFilter,
            quickFilterPositionX: this.quickFilterPositionX,
          },
          $implicit: this.filter,
        };
      }
    }

    if (changes.label && this.filter) {
      this.filter.label = this.label;
    }

    if (changes.note && this.filter) {
      this.filter.note = this.note;
    }

    if (changes.key && this.filter) {
      this.filter.key = this.key;
    }
  }

  /**
   * Given a filter type, return an instance of a data filter.
   *
   * @param   filterType   Either a string or an instance of a Data Filter.
   * @return  A new, or existing data filter.
   */
  initFilter(filterType: FilterType): DataFilter<any> {
    switch (true) {
      case filterType && typeof filterType !== 'string':
        return filterType as DataFilter<any>;
      case filterType === 'searchProperty':
        return new SearchPropertyFilter();
      case filterType === 'numberProperty':
        return new NumberPropertyFilter();
      case filterType === 'valueProperty':
        return new ValuePropertyFilter();
      case filterType === 'dateRange':
        return new DateRangeFilter();
    }
    return undefined;
  }
}
