import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { map, scan, shareReplay, startWith, switchMap } from 'rxjs/operators';
import { HelixIntlService } from '../../helix-intl.service';
import { ThemeService } from './theme.service';
import { AsyncPipe } from '@angular/common';
import { IconModule } from '../icon/icon.module';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';

@Component({
  selector: 'cog-dark-mode-toggle',
  templateUrl: './dark-mode-toggle.component.html',
  standalone: true,
  imports: [MatLegacyButtonModule, MatLegacyTooltipModule, IconModule, AsyncPipe]
})
export class DarkModeToggleComponent implements OnDestroy, OnInit {

  /**
   * Facilitates programmatic toggle of the current theme.
   */
  readonly toggle = new Subject<boolean>();

  /**
   * An observable which emits whether the dark mode is enabled or not.
   */
  readonly darkMode$ = this.themeService.darkModeEnabled$.pipe(
    switchMap(enabled =>
      this.toggle.pipe(
        startWith(enabled),
        scan(state => !state)
      )
    )
  );

  /**
   * The label to show for the icon.
   */
  readonly label$ = this.themeService.darkModeEnabled$.pipe(
    map(enabled => (enabled ? this.intl.darkModeDisable : this.intl.darkModeEnable)),
    shareReplay(1)
  );

  /**
   * The shape of the icon.
   */
  readonly iconShape$ = this.themeService.darkModeEnabled$.pipe(
    map(enabled => (enabled ? 'wb_sunny!outline' : 'helix:dark-mode')),
    shareReplay(1)
  );

  /**
   * Reference to the created subscription.
   */
  private sub: Subscription;

  constructor(private intl: HelixIntlService, readonly themeService: ThemeService) {}

  ngOnDestroy() {
    this.sub?.unsubscribe();
  }

  ngOnInit() {
    this.sub = this.toggle.subscribe(() => {
      this.toggleMode();
    });
  }

  /**
   * Updates the current theme to it's opposite mode
   */
  toggleMode() {
    this.themeService.toggleMode();
  }
}
