import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

/**
 * Interface for a tracking event.
 */
export interface HelixTrackingEvent {
  /**
   * Id of the tracking event.
   */
  id?: string;

  /**
   * Key of the tracking event.
   */
  key?: string;

  /**
   * Any additional properties of the tracking event.
   */
  properties?: any;

  /**
   * Element of the tracking event.
   */
  element?: HTMLElement;

  /**
   * The data id of the clicked element.
   */
  dataId?: string;

  /**
   * If this is present, use this over dataId for event tracking.
   */
  eventTrackingId?: string;
}

/**
 * This service is used by helix components to send tracking events to services
 * which are listening to the public observable in this service.
 */
@Injectable({
  providedIn: 'root'
})
export class EventTrackingService {
  /**
   * Subject to hold the current tracking event value.
   */
  private event = new Subject<HelixTrackingEvent>();

  /**
   * Observable to emit the current tracking event value.
   */
  event$ = this.event.asObservable();

  /**
   * Function to call to send a tracking event from helix.
   *
   * @param event The tracking event.
   */
  send(event: HelixTrackingEvent) {
    this.event.next(event);
  }
}
