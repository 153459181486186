<!-- Whitelist dialog Component -->
<div class="whitelist-dialog" *ngIf="!isDeleteAction">
  <h1 mat-dialog-title>
    {{(data.type === 'add' ? 'addWhitelist' : 'editWhitelist') | translate}}
  </h1>
  <form [formGroup]="whitelistForm">
    <mat-dialog-content class="whitelist-form">
      <div class="row">
        <mat-form-field class="col-xs-12 margin-bottom">
          <mat-label>{{'subnet' | translate}}</mat-label>
          <input formControlName="ip" cogDataId="subnet-ip-input" matInput>
          <mat-icon *ngIf="hasError('ip', 'pattern')" class="status-critical" matSuffix>
            error_outline
          </mat-icon>
          <mat-hint>
            {{'views.subnetIpAndMaskOrBits' | translate}}
          </mat-hint>
          <mat-error *ngIf="hasError('ip', 'required')" cogDataId="subnet-required-error">
            {{ 'errors.required' | translate }}
            <div>{{'views.subnetIpAndMaskOrBits' | translate}}</div>
          </mat-error>
          <mat-error *ngIf="hasError('ip', 'pattern')" cogDataId="subnet-format-error">
            {{ 'errors.patterns.IP' | translate }}
            <div>{{'views.subnetIpAndMaskOrBits' | translate}}</div>
          </mat-error>
          <mat-error *ngIf="hasError('ip', 'duplicate')" cogDataId="subnet-duplicate-error">
            {{ 'errors.duplicateSubnetEntry' | translate }}
          </mat-error>
        </mat-form-field>

        <div class="col-xs-12 radio-group"
          [class.hidden]="data.viewProtocols ?
          !(data.viewProtocols.includes('NFS') || data.viewProtocols.includes('NFS4')) : false">
          <mat-label class="col-xs-3 pull-left">{{'nfsPermissions' | translate}}</mat-label>
          <mat-radio-group formControlName="nfsAccess">
            <mat-radio-button *ngFor="let item of nfsPermissions; let first = first;"
              [value]="item"
              cogDataId="nfs-permission-{{item}}"
              class="col-xs-3">
              {{item | translate}}
            </mat-radio-button>
          </mat-radio-group>
        </div>

        <div class="col-xs-12 radio-group"
          [class.hidden]="data.viewProtocols ? !data.viewProtocols.includes('SMB') : false">
          <mat-label class="col-xs-3 pull-left">{{'smbPermissions' | translate}}</mat-label>
          <mat-radio-group formControlName="smbAccess">
            <mat-radio-button *ngFor="let item of smbPermissions; let first = first;"
              [value]="item"
              cogDataId="smb-permission-{{item}}"
              class="col-xs-3">
              {{item | translate}}
            </mat-radio-button>
          </mat-radio-group>
        </div>

        <div class="col-xs-12 radio-group"
          [class.hidden]="data.viewProtocols ? !data.viewProtocols.includes('S3') : false">
          <mat-label class="col-xs-3 pull-left">{{'s3Permissions' | translate}}</mat-label>
          <mat-radio-group formControlName="s3Access">
            <mat-radio-button *ngFor="let item of s3Permissions; let first = first;"
              [value]="item"
              cogDataId="s3-permission-{{item}}"
              class="col-xs-3">
              {{item | translate}}
            </mat-radio-button>
          </mat-radio-group>
        </div>

        <div class="col-xs-12 radio-group" *ngIf="!data.hideNfsSquash">
          <mat-label class="col-xs-3 pull-left">{{'nfsSquash' | translate}}</mat-label>
          <mat-radio-group formControlName="nfsSquash">
            <mat-radio-button *ngFor="let item of nfsSquashList; let first = first;"
              [value]="item"
              cogDataId="nfs-squash-permission-{{item}}"
              class="col-xs-3">
              {{item | translate}}
            </mat-radio-button>
          </mat-radio-group>
        </div>

        <div class="col-xs-12 margin-top" *ngIf="!data.isGlobal && !data.hideNfsSquash">
          <cog-banner status="warn" *ngIf="whitelistForm.value?.nfsSquash === 'kAllSquash'">
            <p>{{ 'allSquashInfo' | translate:data?.squashConfig?.nfsAllSquash }}</p>
            <p>{{ 'allSquashAction' | translate}}</p>
          </cog-banner>

          <cog-banner status="warn" *ngIf="whitelistForm.value?.nfsSquash === 'kRootSquash'">
            <p>{{ 'rootSquashInfo' | translate:data?.squashConfig?.nfsRootSquash }}</p>
            <p>{{ 'allSquashAction' | translate}}</p>
          </cog-banner>
        </div>

        <mat-form-field class="col-xs-12 margin-top-lg">
          <mat-label>{{'views.whitelist.description.title' | translate}}</mat-label>
          <input matInput formControlName="description" cogDataId="subnet-description">
        </mat-form-field>
      </div>

    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button
        cogCancelButton
        type="button"
        [disabled]="loading"
        mat-button
        color="primary"
        cogDataId="subnet-ip-{{data.type}}-cancel-btn"
        mat-dialog-close>
        {{'cancel' | translate}}
      </button>
      <button
        cogSubmitButton
        type="submit"
        mat-flat-button
        color="primary"
        [loading]="loading"
        cogDataId="subnet-ip-{{data.type}}-btn"
        (click)="onSubmit()">
        {{(data.type === 'add' ? 'add' : 'save') | translate}}
      </button>
    </mat-dialog-actions>
  </form>
</div>

<!-- Whitelist Delete Dialog template -->
<form class="delete-dialog" *ngIf="isDeleteAction"
  [formGroup]="whitelistDeleteForm">
  <h1 mat-dialog-title>
    {{'deleteWhitelist' | translate}}
  </h1>

  <mat-dialog-content class="whitelist-form">
    <span class="status-info margin-bottom"
      translate="views.whitelist.deleteSubnetIp.info"
      [translateParams]="{ ip: data.existingWhitelist?._ip }">
    </span>

    <mat-form-field class="col-xs-8 no-padding">
      <input cogDataId="confirm-delete-whitelist-input"
        required
        matInput
        placeholder="{{'typeYesToConfirm' | translate}}"
        formControlName="confirmDelete">
        <mat-error cogDataId="confirm-required-invalid"
          *ngIf="whitelistDeleteForm.controls.confirmDelete.errors?.required">
          {{'errors.required' | translate}}
        </mat-error>
        <mat-error cogDataId="confirm-pattern-pattern"
          *ngIf="whitelistDeleteForm.controls.confirmDelete.errors?.pattern">
          {{'mustInputYes' | translate}}
        </mat-error>
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button cogCancelButton
      type="button"
      mat-button
      color="primary"
      cogDataId="subnet-ip-delete-cancel-btn"
      mat-dialog-close
      (click)="cancel()">
      {{'cancel' | translate}}
    </button>
    <button
      cogSubmitButton
      color="warn"
      type="submit"
      cogDataId="subnet-ip-delete-btn"
      (click)="onSubmit()"
      mat-flat-button>
      {{'delete' | translate}}
    </button>
  </mat-dialog-actions>
</form>
