// Controller: Recover DB: Nodes Selector Modal for Recovery

;(function(angular, undefined) {
  'use strict';

  var moduleName = 'C.dbRestore';

  angular
    .module(moduleName)
    .controller('dbNodesModalController', dbNodesModalControllerFn);



  /**
   * @ngdoc component
   * @name DbModalNodes
   *
   * @description
   * This component shows up a modal where in user can do nodes selection
   */
  function dbNodesModalControllerFn(_, $scope, entity, parentEntity,
    PUB_TO_PRIVATE_ENV_STRUCTURES, NETWORKING_INFO_MAP, ENV_TYPE_CONVERSION,
    SourceService, evalAJAX, isAlternateRestore, prevDbCredentials,
    prevFormData, isActivePassive, isViewExposeRestore) {

    var $ctrl = this;

    // declare component methods
    _.assign($ctrl, {
      closeModal: closeModal,
      saveNodes: saveNodes,

      // Component lifecycle methods
      $onInit: $onInit,
    });

    /**
     * Initilaize data
     *
     * @method _initializeCtrl
     */
    function _initializeCtrl() {
      _.assign($ctrl, {
        canSaveSettings: false,
        hostType: _.get(parentEntity, '_appTypeEntity.hostType'),
        entity: entity,
        parentEntity: parentEntity,
        isAlternateRestore: isAlternateRestore,
        isActivePassive: isActivePassive,
        isViewExposeRestore: isViewExposeRestore,
        dbCredentials: _.cloneDeep(prevDbCredentials),
        nodeSelectType: 'auto',
        formData: _.cloneDeep(prevFormData),
        selectAllByDefault: false,
        host: {
          // env vec initialised to kOracle
          appEnvVec: [ENV_TYPE_CONVERSION.kOracle],
          usesPersistentAgent: true,
          ownerEntity: undefined,

          // for registering oracle db with credentials populate this field
          appCredentialsVec: [
            {
              envType: ENV_TYPE_CONVERSION.kOracle,
              credentials: {
                username: undefined,
                password: undefined,
              },
            },
          ],
        },
      });
    }

    /**
     * Initialize all the things!
     *
     * @method     $onInit
     */
    function $onInit() {
      var serverTypes = PUB_TO_PRIVATE_ENV_STRUCTURES.kPhysical.entityTypes;
      var dbTypes = PUB_TO_PRIVATE_ENV_STRUCTURES.kOracle.entityTypes;

      // Initialize data
      _initializeCtrl();

      // In case of alternate restore currently we don't consider
      // db credentials.
      if (!isAlternateRestore) {
        // ------------ db credentials ---------------------------------------
        $ctrl.isDbAuthenticated = !!_.chain(parentEntity)
        .get('appEntity.registeredEntityInfo.appCredentialsVec',
          [])
        .value().length;

        // Persist the username and password if user has already
        // entered them.
        if ($ctrl.dbCredentials) {
          $ctrl.host = $ctrl.dbCredentials;
        }

        // Get the data required for db authentication form.
        SourceService.getSources({
          entityId: _.get(entity, 'ownerId'),
        }).then(
            function populatePhysicalServers(res) {
              $ctrl.host.ownerEntity = res.entityHierarchy.entity;
          },
          evalAJAX.errorMessage);
      }

      // ------------ nodes ---------------------------------------
      // If some nodes are selected previously
      // nodes selection form.
      if (_.some($ctrl.formData, ['isSelected', true])) {
        $ctrl.nodeSelectType = 'manual';
      }

      var agentIdToHostAddressMap =
        SourceService.getOracleAgentIdToHostAddressMap(
          parentEntity._appTypeEntity);

      if(!$ctrl.formData) {
        for (var i = 0; i < $ctrl.formData.length; i++) {
          var node = $ctrl.formData[i];
          var host = node.ip;
          if (!isNaN(host)) {
            node.ip = _.get(agentIdToHostAddressMap, host + '[0]');
          }
        }
      }

      // Fill up the  formData only if its not filled previously
      if ($ctrl.formData.length === 0) {
        switch($ctrl.parentEntity._appTypeEntity.type) {
          case serverTypes.kHost:
            _fillStandaloneNodesFormData();
            break;

          case serverTypes.kOracleRACCluster:
            _fillClusterNodesFormData(isAlternateRestore || isViewExposeRestore);
            break;

          case serverTypes.kOracleAPCluster:
            $ctrl.isActivePassive = true;
            _fillClusterNodesFormData(isAlternateRestore || isViewExposeRestore);
            break;
        }
      }
    }

    /**
     * Fill the nodes info for the  oracle standalone machine.
     *
     * @method   _fillStandaloneNodesFormData
     */
    function _fillStandaloneNodesFormData() {
      // Currently for Standalone setup we get the ip from the
      // parent host's "name" and fqdn from "hostname".
      var ip = parentEntity._appTypeEntity.name;
      var fqdn = parentEntity._appTypeEntity.hostname;
      var nodesWhichSupportMultiNodeMultiChannel =
        getMnmcSupportedNodes(parentEntity);

      _addToFormData(fqdn, ip, nodesWhichSupportMultiNodeMultiChannel
        .includes(fqdn) || nodesWhichSupportMultiNodeMultiChannel.includes(ip));
    }

    /**
     * Fill the nodes info for Oraclecluster
     *
     * @method   _fillClusterNodesFormData
     * @param   {boolean}    isAlternateRestore    alternate restore flag
     */
    function _fillClusterNodesFormData(isAlternateRestore) {
      var nodesFromDb;
      var nodesFromParent = _.chain(parentEntity)
        .get('_appTypeEntity.networkingInfo.resourceVec', [])
        .filter(function filterNodes(item) {
          return item.type === NETWORKING_INFO_MAP.kServer;
        })
        .value();
      var nodesWhichSupportMultiNodeMultiChannel =
        getMnmcSupportedNodes(parentEntity);

      if (isAlternateRestore) {
        _.forEach(nodesFromParent,
          function forEachNodeFromParent(fromParent) {
            var nodeFromParentHost = _.get(fromParent, 'endpointVec[0]', {});
            var ip = nodeFromParentHost.ipv4Addr || nodeFromParentHost.ipv6Addr;
            _addToFormData(nodeFromParentHost.fqdn, ip,
              nodesWhichSupportMultiNodeMultiChannel.includes(nodeFromParentHost.fqdn) ||
              nodesWhichSupportMultiNodeMultiChannel.includes(ip));
          });
      } else {
        nodesFromDb = _.chain(entity).get('dbEntityInfo.hostVec',[]).value();

        // 1. We have the fqdn data only in the parent host nodes i.e
        //    _appTypeEntity.networkingInfo.resourceVec
        //    Note: This is the superset of nodes.
        // 2. We have our required nodes data at database level i.e.
        //    dbEntityInfo.hostVec
        // 3. Iterate through 1 & 2 and create a formData with each node
        //    containing ip, fqdn, channels, port data.
        _.forEach(nodesFromDb, function getEachDbNode(fromDb) {
          var port = _.get(fromDb, 'portnumVec[0]');
          var cpuCount  = fromDb.numCpu;
          _.forEach(nodesFromParent,
            function forEachNodeFromParent(fromParent) {
              var nodeFromParentHost = _.get(fromParent, 'endpointVec[0]', {});
              var ip = nodeFromParentHost.ipv4Addr ||
                nodeFromParentHost.ipv6Addr;
              var fqdn = nodeFromParentHost.fqdn;
              var inFormData = _.some($ctrl.formData, ['ip', ip]);

              if (!inFormData) {
                // Push the required nodes into formData
                // which is used in the template to show the nodes.
                _addToFormData(fqdn, ip,
                  nodesWhichSupportMultiNodeMultiChannel.includes(fqdn) ||
                  nodesWhichSupportMultiNodeMultiChannel.includes(ip),
                  cpuCount, port);
              }
            });
        });
      }
    }

    /**
     * Get Multi Node Multi Channel supported nodes
     *
     * @method  getMnmcSupportedNodes
     * @param   {Object}    entity    physical sevrer entity
     * @returns supported node ip's
     */
    function getMnmcSupportedNodes(entity) {
      var mnmcSupportedAgents =  _.chain(entity)
        .get('_appTypeEntity.agentStatusVec', [])
        .filter(['oracleMultiNodeMultiChannelSupported', true])
        .map('id').value();
      var agentIdToHostAddressMap =
        SourceService.getOracleAgentIdToHostAddressMap(entity._appTypeEntity);
      return mnmcSupportedAgents.map(function iterSupportedAgents(agentId) {
        return _.get(agentIdToHostAddressMap, agentId + '[0]');
      });
    }

    /**
     * Add to form data
     *
     * @method   _addToFormData
     * @param    {String}    fqdn         Fully qualified domain name
     * @param    {String}    ip           Node ip
     * @param    {boolean}   isSupported  Does this node support Multi  Node
     *                                    Multi Channel
     * @param    {Number}    [cpuCount]   Machine cpu count
     * @param    {Number}    [port]       Required port for multi selection
     */
    function _addToFormData(fqdn, ip, isSupported, cpuCount, port) {
      var recommendedChannels = $ctrl.isViewExposeRestore? 1 :
        SourceService.calculateOracleChannnels(cpuCount);
      $ctrl.formData.push({
        isSelected: false,
        isSupported: isSupported,
        fqdn: fqdn,
        ip: ip,
        channels: recommendedChannels,

        // This is the default number of channels recommended for
        // efficient backup/restore.
        recommendedChannels: recommendedChannels,
        port : port,
        isDisabled: $ctrl.selectAllByDefault,
      });
    }

    /**
     * on click save
     *
     * @method   saveNodes
     *
     * @param    {Object}   nodesSelectorForm   The From object
     */
    function saveNodes(nodesSelectorForm) {
      var response = {
        formData: [],
        dbCredentials: undefined,
      };

      if (nodesSelectorForm.$invalid) {
        return;
      }
      if ($ctrl.nodeSelectType === 'auto' &&
          typeof $ctrl.sbtLibraryPath !== 'undefined') {
        // Auto select first node and update the sbt library path.
        $ctrl.nodeSelectType = 'manual'
        $ctrl.formData[0].isSelected = true
        $ctrl.formData[0].sbtLibraryPath = $ctrl.sbtLibraryPath
      }

      if ($ctrl.nodeSelectType === 'manual') {
        response = {
          formData: _.cloneDeep($ctrl.formData),
          dbCredentials: _.cloneDeep(
            $ctrl.host.appCredentialsVec[0].credentials.username &&
            $ctrl.host.appCredentialsVec[0].credentials.password ? $ctrl.host :
            undefined),
          isActivePassive: $ctrl.isActivePassive,
        }
      }

      closeModal(response);
    }

    /**
     * Closes this modal.
     *
     * @method  closeModal
     * @param   {object}   [result]   The result object
     */
    function closeModal(result) {
      $scope.$close(result);
    }
  }
})(angular);
