<!-- Ignore confirmation dialog template -->
<h2 mat-dialog-title>{{ 'anomalyDetails.ignoreHeader' | translate }}</h2>
<mat-dialog-content class="margin-bottom">
  <div>{{ ignoreConfirmationTranslationKey | translate }}</div>
  <div class="margin-top">{{ 'anomalyDetails.ignoreFooterNote' | translate }}</div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close [disabled]="isSubmitting" cogDataId="anomaly-cancel-ignore">
    {{ 'cancel' | translate }}
  </button>
  <button
    mat-flat-button
    cdkFocusInitial
    cogSubmitButton
    cogDataId="anomaly-confirm-ignore"
    color="primary"
    (click)="confirm()"
    [loading]="isSubmitting"
    [disabled]="isSubmitting">
    {{ 'confirm' | translate }}
  </button>
</mat-dialog-actions>
