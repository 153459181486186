<mat-card>
  <div class="card-background-icon-container">
    <cog-icon class="card-background-icon" [shape]="cardConfig.icon" size="xl"></cog-icon>
  </div>

  <mat-card-title class="margin-bottom-lg margin-top-sm">
    <cog-icon class="margin-right-sm" [shape]="cardConfig.icon" size="xl"></cog-icon>
    <div class="title-text">{{ cardConfig.title | translate }}</div>
  </mat-card-title>

  <mat-card-content>
    <section class="content-section flex-column flex-justify-space-between">
      <p class="description-text">{{ cardConfig.description | translate }}</p>
      <section class="flex-row flex-justify-flex-end margin-top-sm">
        <cog-icon shape="arrow_forward" size="lg"></cog-icon>
      </section>
    </section>
  </mat-card-content>
</mat-card>
