import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

import { AnomalyChartSeries, ChartPoint, hiddenMetrics } from '../../security-shared.models';

/**
 * Component to manage all the charts
 *
 * @example
 *  <dg-ar-anomaly-trends
 *    [seriesData]="seriesData"
 *    [metricSeries]="metricSeries"
 *    (pointSelection)="pointSelectionHandler($event)">
 *  </dg-ar-anomaly-trends>
 */
@Component({
  selector: 'dg-ar-anomaly-trends',
  templateUrl: './anomaly-trends.component.html',
})
export class AnomalyTrendsComponent implements OnChanges {
  /**
   * Anomaly chart series data
   */
  @Input() seriesData: AnomalyChartSeries = null;

  /**
   * Holds the processed information for rendering each metric in chart.
   */
  @Input() metricSeries: { [key: string]: AnomalyChartSeries } = {};

  /**
   * Emit point selection for host component
   */
  @Output() pointSelection = new EventEmitter<ChartPoint>();

  /**
   * Metrics to hide
   */
  @Input() hiddenMetrics: string[] = [];

  /**
   * Gets boolean if seriesData has some data points and metrics
   */
  get hasSeriesData(): boolean {
    return Boolean(this.seriesData?.dataPointVec?.length && this.seriesData?.metrics?.length);
  }

  /** The set of metric that are hidden for the users */
  hiddenMetricSet: Set<string>;

  ngOnChanges() {
    this.hiddenMetricSet = new Set([...hiddenMetrics, ...this.hiddenMetrics]);
  }
}
