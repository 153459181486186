;(function(angular, undefined) {
  'use strict';

  angular
    .module('C')
    .service('ProtectionDetailsUtil', protectionDetailsUtilFn);

  function protectionDetailsUtilFn($parse) {
    var ProtectionDetailsUtil = {
      getSortedSnapshots: getSortedSnapshots,
      getStatsSinceLastSuccessfulRun: getStatsSinceLastSuccessfulRun,
      getTotalErrorCount: getTotalErrorCount,
      groupObjects: groupObjects,
    };

    /**
     * this report allows the user to filter data by multiple objects, to better
     * visualize this data, each collection of snapshot runs info should be
     * grouped within the object name it relates to. the main use of this
     * function is to reformat data so it is easily consumable by the template
     *
     * @param     {array}    objects    an array of 'objects' that need to be
     *                                  grouped by name
     *
     * @param     {string}    prop      key / property to group by
     *
     * @return    {Object}             an object grouped by object name ex:
     *                                 {
     *                                   centos7Arvind2: [snapshotsInfo],
     *                                   win7_Ribhu: [snapshotsInfo],
     *                                 }
     */
    function groupObjects(objects, prop) {
      var getter = $parse(prop);

      return objects.reduce(function groupTypes(accumulator, item) {
        var key = getter(item);

        accumulator[key] = accumulator[key] || [];
        accumulator[key] = [].concat(item.snapshotsInfo);

        return accumulator;
      }, {});
    }

    /**
     * Parse the protection objects array returned from the API call
     * and put all snapshots into one array to calculate summary row stats
     *
     * @param  {array} snapshots array of objects containing snapshot data
     *
     * @return {array} array of snapshots from all the jobs
     */
    function getSnapshots(objects) {
      return objects.reduce(function concatSnapshots(accumulator, object) {
        if (object.snapshotsInfo && object.snapshotsInfo.length) {
          return accumulator.concat(object.snapshotsInfo);
        }

        return accumulator;
      }, []);
    }

    /**
     * Sorts data in descending order bas off lastRunEndTimeUsecs
     * needed to get lastSuccessfulRun stats
     *
     * @param  {array} snapshots array of objects contains snapshot data
     *
     * @return {array} returns sorted snapshots array
     */
    function sortSnapshots(snapshots) {
      return snapshots.sort(function sortRunEndTime(a, b) {
        return b.lastRunEndTimeUsecs - a.lastRunEndTimeUsecs;
      });
    }

    /**
     * Calls getSnapshots to concat all snapshots into one array which returns
     * a new array of snapshots to be sorted.
     *
     * @param  {array} objects array of objects containing snapshot data
     *
     * @return {array} sorted array of snapshots
     */
    function getSortedSnapshots(objects) {
      return sortSnapshots(getSnapshots(objects));
    }

    /**
     * Counts total number of snapshot errors based of
     * runStatus key
     *
     * @return {number} total number of snapshot errors
     */
    function getTotalErrorCount(snapshots) {
      var errorCount = 0;

      snapshots.forEach(
        function totalErrorCount(snapshot, index) {
          if (snapshot.runStatus === 'kError') {
            errorCount++;
          }
      });

      return errorCount;
    }

    /**
     * Calculate data needed for stats since the last successful run
     *
     * @param  {array} snapshots array of objects containing snapshot data
     *
     * @return {object} object containing aggregated data since lastSuccessful
     *                         run to be used in summary row stats
     */
    function getStatsSinceLastSuccessfulRun(snapshots) {
      var lastRunSuccessStats = {
        errorCount: 0,
        lastRunSuccess: 'naNotApplicable',
        runType: 'naNotApplicable',
      };

      var currentSnapshot = snapshots.find(
        function findSnapshot(snapshot, index) {
          if (snapshot.runStatus === 'kError') {
            lastRunSuccessStats.errorCount++;
          }

          return snapshot.runStatus === 'kSuccess';
      });

      if (currentSnapshot) {
        lastRunSuccessStats.lastRunSuccess = currentSnapshot.lastRunEndTimeUsecs;
        lastRunSuccessStats.runType = currentSnapshot.runType;
      }

      return lastRunSuccessStats;
    }

    return ProtectionDetailsUtil;
  }
})(angular);
