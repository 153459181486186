// Component: Lightbox Component

;(function(angular) {
  'use strict';

  angular.module('C.lightbox', ['slickCarousel'])
    .controller('LightboxCtrl', LightboxCtrlFn)
    .component('lightbox', {
      templateUrl: 'app/apps-management/app-store/lightbox/lightbox.html',
      controller: 'LightboxCtrl',
      transclude: true,
      bindings: {
        /**
         * Configuration for light box.
         *
         * @type {Object}
         */
        config: '=',

        /**
         * App image information needed to be displayed.
         *
         * @type {Array}
         */
        images: '<',
      },

      // Required outside things. These show up on `this.ngModel` in the
      // controller.
      require: {
        ngModel: 'ngModel',
      },
    });

  /**
   * @ngdoc component
   * @name C.appsManagement: lightbox
   * @function
   *
   * @description
   * Reusable component provides a lightbox for images
   *
   * @example
      <lightbox
        config="config"
        images="imagesData"
        ng-model="data"
      </lightbox>
   */
  function LightboxCtrlFn() {
    var $ctrl = this;
    var body;

    _.assign($ctrl, {
      slickConfigLoaded: true,

      // Methods
      hideLightBox: hideLightBox,

      // Component life-cycle methods
      $onInit: $onInit,
    });

    /**
     * Initializes the controller
     *
     * @method   $onInit
     */
    function $onInit() {
      $ctrl.ngModel.$render = function $render() {
        body = angular.element('body');

        // Adding class in order to hide scroll from the parent element.
        if ($ctrl.ngModel.$viewValue) {
          body.addClass('no-scroll');
        }
      };
    }

    /**
     * Close light box on click of back button
     *
     * @method   hideLightBox
     */
    function hideLightBox() {
      // Set ngModel value as false in order to hide the lightbox.
      $ctrl.ngModel.$setViewValue(false);
      body.removeClass('no-scroll');
    }
  }
})(angular);
