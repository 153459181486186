// Proxy Service

;(function(angular, undefined) {

  angular.module('C').service('ProxyService', proxyServiceFn);

  function proxyServiceFn($http, API) {

    const UI_PROXY = "IRIS_UI_PROXY_SERVER";

    return {
      deleteUIProxyConf: deleteUIProxyConf,
      getProxyConf: getProxyConf,
      getProxySupportedServices: getProxySupportedServices,
      getUIProxy: getUIProxy,
      updateUIProxyConf: updateUIProxyConf,
    };

    /**
     * This method returns object containing service kValues.
     *
     * @method    getProxySupportedServices
     * @returns   {Array}    Proxy supported services
     */
    function getProxySupportedServices() {
      return ['kHelios'];
    }

    /**
     *  This method gets current proxy configuration.
     *
     * @method    getProxyConf
     * @returns   {Object}    Promise with list of proxies.
     */
    function getProxyConf() {
      return $http({
        method: 'get',
        url: API.private('proxyServers'),
      }).then(function proxyConfSuccess(response) {
        return response.data || [];
      });
    }

    /**
     * This method filters UI proxy.
     *
     * @method    getUIProxy
     * @param     {Array}    List of proxies
     * @returns   {Object}    UI proxy
     */
    function getUIProxy(proxies) {
      return proxies.find(function getProxy(proxy) {
        return (proxy.name === UI_PROXY);
      });
    }

    /**
     * This method updates UI proxy information.
     *
     * @method    updateUIProxyConf
     * @param     {Object}    data    Proxy information to be submitted
     * @returns   {Object}    Promise to resolve the request.
     */
    function updateUIProxyConf(data) {
      return $http({
        method: 'put',
        url: API.private('proxyServers/' + UI_PROXY),
        data: data,
      });
    }

    /**
     * This method deletes UI proxy information.
     *
     * @method    deleteUIProxyConf
     * @param     {string}    proxyName    Proxy information to be submitted
     * @returns   {Object}    Promise to resolve the request.
     */
    function deleteUIProxyConf(proxyName = "") {
      return $http({
        method: 'delete',
        url: API.private('proxyServers/' + (proxyName ? proxyName : UI_PROXY)),
      });
    }

  }

})(angular);
