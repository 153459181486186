import { ResourceType } from '@cohesity/api/argus';
import { ValueFilterSelection } from '@cohesity/helix';

import { ResourceTypePipe } from './resource-type.pipe';

export const ResourceTypeIcon: Record<ResourceType, string> = {
  [ResourceType.builtIn]: 'argus:built-in',
  [ResourceType.custom]: 'argus:custom',
};

/**
 * The list of sorted ResourceType enum values used by pattern type filter.
 */
export const sortedResourceType = Object.keys(ResourceType) as ResourceType[];

/**
 * Return the list of filter values for the ResourceType enum used by pattern type filter.
 *
 * @param resourceTypePipe The resource type pipe instance.
 * @returns The list of filter values.
 */
export const getResourceTypeFilterValues = (resourceTypePipe: ResourceTypePipe): ValueFilterSelection[] =>
  sortedResourceType.map(type => ({ label: resourceTypePipe.transform(type), value: type }));
