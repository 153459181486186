import { FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ComponentInterface, CreateFormOptions, ToFormGroup } from './create-form.types';

/**
 * Custom create form class used to create forms using CVA with functions.
 *
 * @example
 *  // Need to provide the CVA providers using 'createFormProviders'
 *  @Component({
 *    selector: ...,
 *    templateUrl: ...,
 *    providers: createFormProviders(<COMPONENT>),
 *  })
 *
 *  ...
 *
 *  // Initalize the form like this.
 *  form = new CreateForm<string, { name: string }>(this, {
 *    formControls: {
 *      name: new FormControl(null, Validators.required),
 *    },
 *    transformFromFormGroup: () => this.form.formGroup.value.name,
 *    transformToFormGroup: name => ({ name }),
 *  });
 *
 *
 *
 * @property {FormGroup} formGroup The internal form group.
 * @property {ControlType} value The outer form value.
 */
export const CreateForm = (class CreateForm<ControlType, FormType = ControlType> {
  /**
   * The current form control value
   */
  private _value: ControlType;

  /**
   * The current form control value.
   */
  get value(): ControlType {
    return this._value;
  }

  /**
   * Sets the form control value and propogates the change
   */
  set value(value: ControlType) {
    this._value = value;
    this.propagateChange(value);
  }

  /**
   * Internal subject to cleanup the formGroup subscriptions.
   */
  private onDestroy$ = new Subject<void>();

  /**
   * The component's form group which is publically exposed
   */
  formGroup: FormGroup<ToFormGroup<FormType>>;

  /**
   * Define the form by passing the current component (this) and the options.
   *
   * @param component The current component.
   * @param options The inner form options.
   */
  constructor(private component: ComponentInterface, private options: CreateFormOptions<ControlType, FormType>) {
    this.setupComponentMethods();
    this.setupInnerForm();
  }

  /**
   * Assign the control value accessor properties to the component alongside
   * publically exposed 'value' and 'formGroup'.
   */
  private setupComponentMethods() {
    Object.assign(this.component, {
      registerOnChange: this.registerOnChange,
      registerOnTouched: this.registerOnTouched,

      // Custom validator.
      // TODO: Add validations according to the inner form controls.
      validate: () => {
        if (this.formGroup.invalid) {
          return { invalid: true };
        }
        return null;
      },

      // Writes the initial value from the external form. Also updates the
      // components formControl.
      writeValue: (value: ControlType) => {
        const transformedValue = this.options.transformToFormGroup ? this.options.transformToFormGroup(value) : value;

        if (transformedValue){
          this.formGroup.setValue({ ...(transformedValue as { [K in keyof FormType]: any }) }, { emitEvent: false });
        }

        if (value) {
          this.writeValue(value);
        }
      },
      setDisabledState: (isDisabled: boolean) => {
        isDisabled ? this.formGroup.disable() : this.formGroup.enable();
      }
    });

    // Override the ngOnDestroy method on the component so as to cleanup the
    // valueChanges listener on the inner formGroup. Need to do this on the
    // constructor proto.
    const componentProto = this.component.constructor.prototype;
    const originalOnDestroy = componentProto.ngOnDestroy;

    componentProto.ngOnDestroy = () => {
      if (originalOnDestroy) {
        originalOnDestroy.call(this.component);
      }
      this.onDestroy$.complete();
    };
  }

  /**
   * Setup the inner form group and attach the value change listener to update
   * the CVA form value.
   */
  private setupInnerForm() {
    // Initialize the form group with the formControls provided.
    this.formGroup = new FormGroup<ToFormGroup<FormType>>(this.options.formControls);

    // If transformFromFormGroup is defined, then set the default value to the
    // formControl using the same function. Needs a setTimeout for addressing
    // a corner case when the formControl was hidden behind an ngIf and there
    // was a race condition between the original writeValue and this writeValue.
    if (this.options.transformFromFormGroup) {
      setTimeout(() => {
        this.writeValue(this.options.transformFromFormGroup(this.formGroup.value as FormType));
      }, 100);
    }

    // On inner form group changes, update the external form and the form value.
    this.formGroup.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(value => {
        const transformedValue = this.options.transformFromFormGroup ?
          this.options.transformFromFormGroup(value as FormType) : value as ControlType;

        this.writeValue(transformedValue);
      });
  }

  /**
   * Callback to pass the change event back to a form control. Defaults to a noop
   */
  private propagateChange = (_value: ControlType) => { };

  /**
   * Callback to pass the onTouch event back to a form control. Defaults to a noop
   */
  private propagateOnTouch = () => { };

  /**
   * Update the view with a value passed from a form
   *
   * @param   value   the new form control value
   */
  private writeValue = (value: ControlType) => {
    this.value = value;
  };

  /**
   * Registers a change event handler to use to propogate changes
   *
   * @param   fn   the callback function
   */
  private registerOnChange = (fn: (value: ControlType) => any) => {
    this.propagateChange = fn;
  };

  /**
   * Register on touched event handler.
   */
  private registerOnTouched = (fn: () => any) => {
    this.propagateOnTouch = fn;
  };
});
