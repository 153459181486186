// MODULE: Job Details

;(function(angular, undefined) {
  'use strict';


  angular
    .module('C.jobDetails')
    .controller('jobDetailsProtectionJobController', jobDetailsProtectionJobControllerFn);

  function jobDetailsProtectionJobControllerFn(
    _, $rootScope, $scope, $state, $httpParamSerializer, DateTimeService,
    JobRunsService, JobRunsServiceFormatter, evalAJAX, cUtils,
    ENUM_BACKUP_JOB_STATUS, ENUM_BACKUP_JOB_STATUS_LABEL_CLASSNAME,
    ENUM_BACKUP_LOCAL_JOB_STATUS_ICON_NAME, ENUM_JOB_RUN_TYPE, ENV_GROUPS,
    ENV_TYPE_CONVERSION) {

    // convenience functions and variables
    $scope.ENUM_BACKUP_JOB_STATUS = ENUM_BACKUP_JOB_STATUS;
    $scope.ENUM_BACKUP_JOB_STATUS_LABEL_CLASSNAME =
      ENUM_BACKUP_JOB_STATUS_LABEL_CLASSNAME;
    $scope.ENUM_BACKUP_LOCAL_JOB_STATUS_ICON_NAME =
      ENUM_BACKUP_LOCAL_JOB_STATUS_ICON_NAME;
    $scope.ENUM_JOB_RUN_TYPE = ENUM_JOB_RUN_TYPE;
    $scope.ENV_GROUPS = ENV_GROUPS;
    $scope.cUtils = cUtils;

    $scope.isFileStubbingJob = !!$state.params.fileStubbing;

    /**
     * Text translations for Reports Filter module
     * @type {Object}
     */
    $scope.text = angular.extend(
      $scope.text,
      $rootScope.text.protectionJobsDetailsJobDetails
    );

    /**
     * Job Runs Object
     * Object to store all transformed job runs returned from API
     * @type {Array}
     */
    $scope.jobRuns = [];

    $scope.initJobRuns = false;

    $scope.noSnapshotsSelected = true;

    /**
     * respond to cDateRanger date changes updates restoreParams object
     * and then initiates an api call to update $scope data
     *
     * @method     updateDateParams
     * @return     {Void}
     */
    $scope.updateDateParams = function updateDateParams() {
      $scope.selectedFilters.timeObject.from =
        DateTimeService.dateToUsecs($scope.dates.startDate);
      $scope.selectedFilters.timeObject.until =
        DateTimeService.dateToUsecs($scope.dates.endDate);
      getJobRuns();
    };



    /**
     * Gets Job Runs
     * @return {Object} Job Runs
     */
    function getJobRuns() {
      $scope.fetching = true;

      // Get Job Runs for the charts and the jobruns table
      $scope.runsParams = {
        id: +$scope.jobId,
        numRuns: 1000,
        runTypes: $scope.selectedFilters.runTypes,
        excludeTasks: true,
        onlyReturnDataMigrationJobs: $scope.isFileStubbingJob,
      };

      // If time filters are set, then add them to the runsParams
      if (typeof $scope.selectedFilters.timeObject.from === 'number') {
        $scope.runsParams.startTimeUsecs = $scope.selectedFilters.timeObject.from;
      }
      if (typeof $scope.selectedFilters.timeObject.until === 'number') {
        $scope.runsParams.endTimeUsecs = $scope.selectedFilters.timeObject.until;
      }

      JobRunsService.getJobRuns($scope.runsParams).then(
        function getJobRunsSuccess(jobs) {

          $scope.jobRuns = [];

          if (!jobs.length) {
            return;
          }

          // are backup snapshots managed on prem or remotely
          $scope.areSnapshotsManagedRemotely =
            ENV_GROUPS.cloudJobsWithoutLocalSnapshot.includes(
              _.get(jobs[0], 'backupJobRuns.jobDescription.type'));

          $scope.getJobStatusIcon = JobRunsService.getJobStatusIcon;

          // Loop through each job Run result. Transform data before rendering
          // so smart-table can filter on this table.
          jobs[0].backupJobRuns.protectionRuns.forEach(function eachRun(protectionRun) {
            var jobRun =
              JobRunsServiceFormatter.transformProtectionRun(protectionRun);
            var backupRun = angular.extend({}, jobRun.backupRun);
            var copyRun = angular.extend({}, jobRun.copyRun);

            // get View snapshot info for the Clone option
            if (ENV_GROUPS.spanFS.includes($scope.job.type)) {
              backupRun.snapshotUsecs = backupRun.base.startTimeUsecs;
            }

            // Add Copy Task info
            backupRun._copyTasks = JobRunsService.getCopyTasks(
              protectionRun,
              jobs[0].backupJobRuns.jobDescription.jobUid,
              jobs[0].backupJobRuns.jobDescription.isActive
            );

            if (backupRun.base.progressMonitorTaskPath) {
              backupRun.base._runStatusUrl = [
                'progressMonitors?taskPathVec=',
                backupRun.base.progressMonitorTaskPath,
                '&includeFinishedTasks=false&excludeSubTasks=true'
              ].join('');
            }

            // If this backupRun has a snapshot that is neither deleted nor marked
            // for deletion expose the delete snapshots icon.
            $scope.shared.hasDeleteableRuns = $scope.shared.hasDeleteableRuns ||
            (!backupRun.snapshotsDeleted && !backupRun._snapshotsMarkedForDeletion);

            backupRun._actionsMenu =
              getJobRunActions($scope.job, backupRun, copyRun);

            $scope.jobRuns.push(backupRun);
          });
        },
        evalAJAX.errorMessage
      ).finally(
        function afterGetJobRuns() {
          // If hasDeleteableRuns has been set to true. Let's refresh the
          // actions menu.
          if ($scope.shared.hasDeleteableRuns) {
            $scope.shared.getJobActions();
          }

          $scope.fetching = false;
          $scope.initJobRuns = true;
          mapSummaryDataToRunsData();
        }
      );
    }

    /**
     * Get Job Run Actions
     * returns a configuration object for contextual actions menu based on job
     * status
     *
     * @param      {Object}   job       Job object
     * @param      {Object}   run       Job run object
     * @param      {Object}   copyRun   Job copy run object
     * @return     {Array} Contextual Menu Configuration Objects
     */
    function getJobRunActions(job, run, copyRun) {
      var actions = [];

      // If the current user doesn't own the job, don't show the context menu.
      if (!job._isJobOwner) {
        return actions;
      }

      if (JobRunsService.isValidJobRunAction('edit', run, job, copyRun)) {
        actions.push(
          JobRunsService.getJobRunAction('edit', job, run)
        );
      }

      if (job.type === 5 && $scope.user.privs.CLONE_MODIFY &&
        run.base.endTimeUsecs && !run.snapshotsDeleted &&
        !$scope.isDeleteSnapshotMode) {
        actions.push({
          translateKey: 'cloneView',
          icon: 'icn-clone',
          action: function cloneView() {
            $state.go('clone-view.options', {
              view: $scope.jobSummary._view,
              snapshotUsecs: run.snapshotUsecs,
              jobId: $scope.jobSummary.jobDescription.jobId,
              inactive: $scope.jobSummary._view._isInactive,
              protected: true,
            });
          },
        });
      }

      return actions;
    }

    /**
     * TODO: what is this?
     */
    function mapSummaryDataToRunsData() {
      var taskPath;
      var jobInstanceId;
      var progressMonitorParams = {};

      // Only run this function if jobSummary is ready and there is an active
      // attempt.
      if ($scope.jobSummary.jobDescription &&
        _.get($scope.jobSummary, 'lastProtectionRun.backupRun.activeAttempt') &&
        $scope.jobRuns.length) {

        jobInstanceId = $scope.jobSummary.lastProtectionRun.backupRun.activeAttempt.base.jobInstanceId;
        taskPath = $scope.jobSummary.lastProtectionRun.backupRun.activeAttempt.base.progressMonitorTaskPath;

        // Because a job can only have a single active instance, we simply check
        // the first run returned from backUpJobRuns to see if the jobInstanceId
        // matches the jobInstanceID from the active attempt in jobSummary.
        if (jobInstanceId === $scope.jobRuns[0].base.jobInstanceId) {
          // We have a match. First set up params for the progress monitoring
          // URL.
          progressMonitorParams = {
            taskPathVec: taskPath,
            includeFinishedTasks: true,
            excludeSubTasks: true,
          };

          // Join our params to a string and set the string on the run object.
          $scope.jobRuns[0]._progressMonitorTaskPath = [
            'progressMonitors?=',
            $httpParamSerializer(progressMonitorParams)
          ].join('');

          // Define callback
          $scope.jobRuns[0]._pollingCallback = function pollCallback(status) {
            if (status === 'completed') {
              $scope.jobRuns[0].base._status = 2.1;
              $state.go('job-details.job',
                { id: $scope.jobId },
                { reload: true }
              );
            }
            if (status === 'error') {
              $scope.jobRuns[0].base._status = 2.2;
              $state.go('job-details.job',
                { id: $scope.jobId },
                { reload: true }
              );
            }
          };
        } else {
          $scope.jobRuns[0]._runningNoProgressData = true;
        }
      }

    }

    /**
     * Toggles between selected all/no runs for snapshot deletion
     */
    $scope.toggleAllRuns = function toggleAllRuns() {
      // Clear previouly selected runs
      $scope.shared.runsToDelete = {};
      if ($scope.shared.selectAllCheckbox) {
        angular.forEach(
          $scope.jobRunsDisplay,
          function selectRun(run) {
            // loop though all displayed runs.
            // if the status is not 1 (running)
            if (run.base.status !== 1 &&

              // if snapshots are not deleted or marked for deletion
              !run.snapshotsDeleted &&
              !run._snapshotsMarkedForDeletion &&

              // if the snapshots are not worm locked
              !run._wormLocked) {
              // Add the runs to delete if above conditions are met.
              $scope.shared.runsToDelete[run.base.startTimeUsecs] = run;
            }
          }
        );
      }
    };

    // If scope.jobSummaryReady is true, then jobSummaryData was loaded on a
    // previous tab load, or the API has already returned a response on this tab
    // load. We can go ahead and kick off our call to get runs.
    if ($scope.jobSummaryReady) {
      getJobRuns();
    }

    /**
     * Evaluates scope.shared.runsToDelete.
     * Enable/Disable submit buttons.
     * Deselect 'select all' if need be.
     */
    $scope.$watchCollection(
      'shared.runsToDelete',
      function deleteRunsChanged(nv, ov) {
        var key;
        $scope.shared.hasLogRun = false;
        $scope.noSnapshotsSelected = true;

        // Check if User has deselected any runs
        for (key in $scope.shared.runsToDelete) {
          if ($scope.shared.runsToDelete[key].base &&
            $scope.shared.runsToDelete[key].base.backupType === 2 &&
            $scope.job.type === 3) {
            $scope.shared.hasLogRun = true;
          }
          if ($scope.shared.runsToDelete[key] === false) {
            // If we find one value set to false, disable the select all button.
            $scope.shared.selectAllCheckbox = false;
          } else {
            // If we find one value set to true or an integer, enable the
            // 'Delete Snapshots' button.
            $scope.noSnapshotsSelected = false;
          }

          // If we've set both of the previous scope vars to false
          if (!$scope.noSnapshotsSelected && !$scope.shared.runsToDelete[key]) {
            // If this isn't an SQL job OR if it is an SQL job but we've already
            // found a log run Break out of this loop early.
            if ($scope.job.type !== 3 || $scope.shared.hasLogRun) {
              break;
            }
          }
        }
      }
    );

    // Listen for filter change event, then getJobRuns
    $scope.$on('applyFilters', getJobRuns);

    // Listen for jobSummaryReady event, then getJobRuns
    $scope.$on('jobSummaryReady', getJobRuns);
  }

})(angular);
