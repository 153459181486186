/* tslint:disable */
export enum ObjectType {
  kAAG = 'kAAG',
  kAAGRootContainer = 'kAAGRootContainer',
  kAccount = 'kAccount',
  kAppInstance = 'kAppInstance',
  kApplication = 'kApplication',
  kAuroraCluster = 'kAuroraCluster',
  kAuroraTag = 'kAuroraTag',
  kAvailabilitySet = 'kAvailabilitySet',
  kAvailabilityZone = 'kAvailabilityZone',
  kBucket = 'kBucket',
  kCatalog = 'kCatalog',
  kCluster = 'kCluster',
  kClusterComputeResource = 'kClusterComputeResource',
  kCollection = 'kCollection',
  kComputeOptions = 'kComputeOptions',
  kComputeResource = 'kComputeResource',
  kContainer = 'kContainer',
  kCustomProperty = 'kCustomProperty',
  kDAGRootContainer = 'kDAGRootContainer',
  kDatabase = 'kDatabase',
  kDatacenter = 'kDatacenter',
  kDatastore = 'kDatastore',
  kDistributedVirtualPortgroup = 'kDistributedVirtualPortgroup',
  kDomain = 'kDomain',
  kDomainController = 'kDomainController',
  kEC2Instance = 'kEC2Instance',
  kExchangeDAG = 'kExchangeDAG',
  kExchangeDAGDatabase = 'kExchangeDAGDatabase',
  kExchangeDAGDatabaseCopy = 'kExchangeDAGDatabaseCopy',
  kExchangeNode = 'kExchangeNode',
  kExchangeStandaloneDatabase = 'kExchangeStandaloneDatabase',
  kFileset = 'kFileset',
  kFilesystem = 'kFilesystem',
  kFileSystem = 'kFileSystem',
  kFolder = 'kFolder',
  kGraphUser = 'kGraphUser',
  kGroup = 'kGroup',
  kGroups = 'kGroups',
  kHost = 'kHost',
  kHostCluster = 'kHostCluster',
  kHostGroup = 'kHostGroup',
  kHostSystem = 'kHostSystem',
  kHypervHost = 'kHypervHost',
  kIAMUser = 'kIAMUser',
  kInstance = 'kInstance',
  kInstanceType = 'kInstanceType',
  kKeyPair = 'kKeyPair',
  kKeyspace = 'kKeyspace',
  kKmsKey = 'kKmsKey',
  kLabel = 'kLabel',
  kMailbox = 'kMailbox',
  kMetadata = 'kMetadata',
  kMountPoint = 'kMountPoint',
  kNamespace = 'kNamespace',
  kNetwork = 'kNetwork',
  kNetworkSecurityGroup = 'kNetworkSecurityGroup',
  kO365Exchange = 'kO365Exchange',
  kO365OneDrive = 'kO365OneDrive',
  kO365Sharepoint = 'kO365Sharepoint',
  kObject = 'kObject',
  kOpaqueNetwork = 'kOpaqueNetwork',
  kOracleAPCluster = 'kOracleAPCluster',
  kOracleRACCluster = 'kOracleRACCluster',
  kOrg = 'kOrg',
  kOrganization = 'kOrganization',
  kOrgMetadata = 'kOrgMetadata',
  kOtherHypervisorCluster = 'kOtherHypervisorCluster',
  kOutlook = 'kOutlook',
  kOVirtManager = 'kOVirtManager',
  kPDB = 'kPDB',
  kPersistentVolume = 'kPersistentVolume',
  kPersistentVolumeClaim = 'kPersistentVolumeClaim',
  kPrismCentral = 'kPrismCentral',
  kProject = 'kProject',
  kPublicFolder = 'kPublicFolder',
  kPublicFolders = 'kPublicFolders',
  kPureProtectionGroup = 'kPureProtectionGroup',
  kPVC = 'kPVC',
  kRACRootContainer = 'kRACRootContainer',
  kRDSInstance = 'kRDSInstance',
  kRDSOptionGroup = 'kRDSOptionGroup',
  kRDSParameterGroup = 'kRDSParameterGroup',
  kRDSSubnet = 'kRDSSubnet',
  kRDSTag = 'kRDSTag',
  kRegion = 'kRegion',
  kResourceGroup = 'kResourceGroup',
  kResourcePool = 'kResourcePool',
  kRootContainer = 'kRootContainer',
  kRootPublicFolder = 'kRootPublicFolder',
  kS3Bucket = 'kS3Bucket',
  kS3Tag = 'kS3Tag',
  kSCVMMServer = 'kSCVMMServer',
  kService = 'kService',
  kSite = 'kSite',
  kSites = 'kSites',
  kSnapshotManagerPermit = 'kSnapshotManagerPermit',
  kStandaloneCluster = 'kStandaloneCluster',
  kStandaloneHost = 'kStandaloneHost',
  kStorageAccount = 'kStorageAccount',
  kStorageArray = 'kStorageArray',
  kStorageBlob = 'kStorageBlob',
  kStorageContainer = 'kStorageContainer',
  kStorageDomain = 'kStorageDomain',
  kStorageKey = 'kStorageKey',
  kStoragePod = 'kStoragePod',
  kStoragePolicy = 'kStoragePolicy',
  kSubnet = 'kSubnet',
  kSubscription = 'kSubscription',
  kSubTaskPermit = 'kSubTaskPermit',
  kTable = 'kTable',
  kTableSpace = 'kTableSpace',
  kTag = 'kTag',
  kTagCategory = 'kTagCategory',
  kTeam = 'kTeam',
  kTeams = 'kTeams',
  kUser = 'kUser',
  kUsers = 'kUsers',
  kVCenter = 'kVCenter',
  kvCloudDirector = 'kvCloudDirector',
  kView = 'kView',
  kViewBox = 'kViewBox',
  kVirtualApp = 'kVirtualApp',
  kVirtualAppTemplate = 'kVirtualAppTemplate',
  kVirtualDatacenter = 'kVirtualDatacenter',
  kVirtualMachine = 'kVirtualMachine',
  kVirtualNetwork = 'kVirtualNetwork',
  kVNicProfile = 'kVNicProfile',
  kVolume = 'kVolume',
  kVPC = 'kVPC',
  kVPCConnector = 'kVPCConnector',
  kVserver = 'kVserver',
  kWindowsCluster = 'kWindowsCluster',
  kZone = 'kZone'
}
