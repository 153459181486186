import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { Point, SeriesBubbleOptions, SeriesClickEventObject, SeriesPackedbubbleOptions } from 'highcharts';

import { HighchartsComponent } from '../core/index';

/**
 * Type of bubble series options.
 */
export type BubbleOptionsType = SeriesBubbleOptions | SeriesPackedbubbleOptions;

/**
 * Bubble chart component.
 */
@Component({
  selector: 'cog-bubble-chart',
  templateUrl: './bubble-chart.component.html',
  styleUrls: ['./bubble-chart.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BubbleChartComponent extends HighchartsComponent<BubbleOptionsType> {

  /**
   * Emits bubble chart click events.
   */
  @Output() bubbleClick = new EventEmitter<SeriesClickEventObject>();

  /**
   * Default bubble chart options.
   * Will always be present unless overwritten.
   */
  readonly defaultSeriesOptions: BubbleOptionsType = {
    type: 'bubble',
    maxSize: '35%',
    minSize: '20%'
  };

  /**
   * Stores bubble series.
   */
  protected _seriesData: BubbleOptionsType[] = [];

  /**
   * Sets bubble series.
   */
  @Input() set seriesData(seriesData: BubbleOptionsType[]) {
    this.clearSeries();

    this._seriesData = seriesData.map((options: BubbleOptionsType) => ({
      ...this.defaultSeriesOptions,
      ...options
    } as BubbleOptionsType));

    this.chartOptions.series = this._seriesData;

    super.render();
  }

  /**
   * Returns bubble series.
   */
  get seriesData(): BubbleOptionsType[] {
    return this._seriesData;
  }

  constructor() {
    super({
      chart: {
        type: 'bubble',
        spacing: [0, 0, 0, 0],
        styledMode: true,
      },

      title: null,

      legend: {
        enabled: false,
      },

      credits: {
        enabled: false,
      },

      xAxis: {
        visible: false,
      },

      yAxis: {
        visible: false,
      },

      series: [],

      plotOptions: {
        bubble: {
          events: {
            click: (event: SeriesClickEventObject) => this.bubbleClick.emit(event)
          },
        },
      },
    });
  }

  /**
   * Selects data point.
   *
   * @param  point     Series data point.
   * @param  selected  True if selection is truthy.
   */
  selectData(point: Point, selected: boolean = true) {
    point.select(selected);
  }
}
