<div class="cog-snack-bar-status-icon" *ngIf="hasIcon">
  <cog-icon [shape]="statusShape" size="md"></cog-icon>
</div>

<div #containerElement class="message-container">
  <span #messageElement [innerHTML]="data.messageText" cogDataId="snack-bar-action-message">
  </span>
  <div class="action-items">
    <button mat-stroked-button *ngIf="hasAction" type="button" cogDataId="snack-bar-action-button"
      (click)="snackBarRef.dismissWithAction()">
      {{ data.actionText}}
    </button>

    <button *ngIf="error" cogDataId="snack-bar-copy-to-clipboard-button" mat-stroked-button type="button"
      [matTooltip]="intl.copyToClipboard" (click)="copyMessage(messageElement.innerText)">
      Copy
    </button>

    <button mat-icon-button *ngIf="allowDismiss" type="button" cogDataId="snack-bar-dismiss-button"
      (click)="snackBarRef.dismiss()">
      <cog-icon shape="close"></cog-icon>
    </button>
  </div>
</div>
