import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AppConfig } from '@cohesity/iris-core';

@Component({
  selector: 'mf-app-outlet',
  templateUrl: './app-outlet.component.html',
})
export class AppOutletComponent implements OnInit, OnDestroy {
  /**
   * The list of library names.
   */
  libNames: string[] = [];

  /**
   * Indicate whether app are being loaded or not.
   */
  isLoading = false;

  /**
   * App configurations list.
   */
  @Input() set appConfigs(appConfigs: AppConfig[]) {
    // filter out apps lacking main file path.
    this.libNames = appConfigs.filter(app => app.publicPath).map(app => app.libName);
  }

  ngOnInit() {
    window.addEventListener('single-spa:app-change', this.appChange);
    window.addEventListener('single-spa:before-app-change', this.beforeAppChange);
  }

  ngOnDestroy() {
    window.removeEventListener('single-spa:app-change', this.appChange);
    window.removeEventListener('single-spa:before-app-change', this.beforeAppChange);
  }

  beforeAppChange = () => this.isLoading = true;

  appChange = () => this.isLoading = false;
}
