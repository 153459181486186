import { Component } from '@angular/core';

@Component({
  selector: 'coh-settings-list-reader-writer',
  templateUrl: './settings-list-reader-writer.component.html',
})

export class SettingsListReaderWriterComponent {

  /**
   * Number of readers for protection
   */
  readerCount = 1;

  /**
   * Number of writers for protection
   */
  writerCount = 1;
}
