import { Injectable, Injector, isDevMode, Type } from '@angular/core';
import { AppBannerService, BannerConfig } from '@cohesity/helix';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, startWith, switchMap } from 'rxjs/operators';

import { IrisContextService, isFullyAuthenticated } from '../iris-context';
import { BaseBannerConfig, defaultBannerConfigs } from './configs';

/**
 * This service is written specifically to address rendering banners in the UI.
 * Since this is provided in root, the service will be capable of rendering
 * the banner based on the context in which it is loading.
 */
@Injectable({
  providedIn: 'root',
})
export class BannerConfigsService {
  /** A counter used to trigger banners updates forcefully. */
  private forceUpdateCount = new BehaviorSubject(0);

  private _bannerConfigs = new BehaviorSubject<BannerConfig[]>([]);

  bannerConfigs$ = this._bannerConfigs.asObservable();

  /**
   * Used to decide whether to log error or not
   */
  private isDevMode = isDevMode();

  constructor(private bannerSvc: AppBannerService, private injector: Injector, private irisCtx: IrisContextService) {}

  /**
   * Loads the banner to be rendered in the app layout.
   */
  loadBanners(customBannerConfigs: Type<BaseBannerConfig>[] = []): Observable<BannerConfig[]> {
    return combineLatest([
      this.irisCtx.irisContext$.pipe(
        map(ctx => isFullyAuthenticated(ctx)),
        distinctUntilChanged(),
        filter(isLoggedIn => isLoggedIn),
      ),
      this.forceUpdateCount.pipe(startWith(this.forceUpdateCount.value + 1)),
    ]).pipe(
      switchMap(() =>
        combineLatest(
          [...customBannerConfigs, ...defaultBannerConfigs].map(bannerConfig =>
            this.injector.get(bannerConfig).bannerInfo$.pipe(catchError((err) => {
              if (this.isDevMode) {
                console.warn('BannerConfig has an error', err);
              }
              return of(null);
            }))
          )
        )
      ),
      debounceTime(100), // this gets very chatty.
      map((bannerConfigs: BannerConfig[]) => bannerConfigs.reduce((out, banners) => out.concat(banners), [])),
      map(bannerConfigs => {
        this._bannerConfigs.next(bannerConfigs);

        bannerConfigs.filter(Boolean).forEach(config => {
          if (config.isVisible) {
            this.bannerSvc.add(config);
          } else {
            this.bannerSvc.remove(config.id);
          }
        });
        return bannerConfigs;
      })
    );
  }

  /**
   * Forcefully update the banners used when user had done the required config which might clear the
   * currenlty visible banner.
   */
  updateBanners() {
    this.forceUpdateCount.next(this.forceUpdateCount.value + 1);
  }
}
