import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { SnapshotTagsList, TaggingServiceServiceApi } from '@cohesity/api/v2';
import { AjaxHandlerService, AutoDestroyable } from '@cohesity/utils';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

import {
  DataPoint,
  SnapshotActionConfirmationParams,
  SnapshotTagActionType,
  TaggingCommons,
} from './../../security-shared.models';

/**
 * Component to show information before user proceeds with apply/remove snapshot tag
 */
@Component({
  templateUrl: './snapshot-action-confirmation.component.html',
})
export class SnapshotActionConfirmationComponent extends AutoDestroyable {
  /**
   * Indicates whether we are loading data.
   */
  isSubmitting = false;

  /**
   * Action title for dialog
   */
  get action(): SnapshotTagActionType {
    return this.data.action;
  }

  /**
   * Dialog description content
   */
  get description(): string {
    return this.translateService.instant(`anomalySnapshotList.${this.data.action}.confirmation`, {
      tagLabel: this.taggingCommons?.tags[0]?.label || '',
      snapshotCount: this.selectedSnapshots.length,
    });
  }

  /**
   * Common information needed for apply / remove tag API calls
   */
  get taggingCommons(): TaggingCommons {
    return (
      this.data?.taggingCommons || {
        snapshot: null,
        tags: [],
      }
    );
  }

  /**
   * Selected snapshot list
   */
  get selectedSnapshots(): DataPoint[] {
    return this.data?.selectedSnapshots || [];
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: SnapshotActionConfirmationParams,
    private ajaxService: AjaxHandlerService,
    private dialogRef: MatDialogRef<SnapshotActionConfirmationComponent, boolean>,
    private heliosTaggingService: TaggingServiceServiceApi,
    private translateService: TranslateService
  ) {
    super();
  }

  /**
   * Handles actions as per table selection
   */
  actionHandler() {
    const request: SnapshotTagsList = this.selectedSnapshots.map(item => {
      const { timestampUsecs, jobInstanceId } = item;
      const snapshot = { ...this.taggingCommons.snapshot };
      snapshot['runStartTimeUsecs'] = timestampUsecs;
      snapshot['runId'] = jobInstanceId;
      return { snapshot, tags: this.taggingCommons.tags };
    });

    let snapshotTagAction: Observable<SnapshotTagsList> = null;
    switch (this.data.action) {
      case 'applyTag':
        snapshotTagAction = this.heliosTaggingService.AddSnapshotsTags(request);
        break;

      case 'removeTag':
        snapshotTagAction = this.heliosTaggingService.RemoveSnapshotsTags(request);
        break;
    }
    this.isSubmitting = true;
    snapshotTagAction
      .pipe(
        this.untilDestroy(),
        finalize(() => {
          this.isSubmitting = false;
        })
      )
      .subscribe(() => this.dialogRef.close(true), this.ajaxService.handler);
  }
}
