// MODULE: Remove Server from Job Modal Controller

;(function(angular, undefined) {
  'use strict';

  angular.module('C')
    .controller('RemoveServerFromJobController', removeServerFromJobModalFn);

  function removeServerFromJobModalFn($rootScope, $scope, evalAJAX, JobService,
    SourceService, $uibModalInstance, node) {

    var nodeId;

    $scope.text = $rootScope.text.protectionJobsModalsRemoveServerFromJob;

    /**
     * Removes the Server from its previous Job and resolve the modal
     */
    $scope.removeServer = function removeServer() {

      var sourcesIndex;

      // make a copy of the job, as removing the Server from the
      // referenced job will decrease sources.length by one, possibly
      // exposing the 'delete job' buttons and text if the job started
      // with two Servers
      var theJob = {
        backupJob: angular.copy($scope.jobs[0])
      };

      theJob.backupJob.sources.some(function findSourceIndex(source, index) {
        if (source.entities[0].id === nodeId) {

          sourcesIndex = index;
          return true;
        }
      });

      theJob.backupJob.sources.splice(sourcesIndex, 1);

      // find any cutom params for the Server and remove them
      if (theJob.backupJob.backupSourceParams &&
        theJob.backupJob.backupSourceParams.length) {

        theJob.backupJob.backupSourceParams.some(
          function findRemoveSourceParams(sourceParams, index) {
            if (sourceParams.sourceId === nodeId) {
              // custom params for the server found,
              // remove them from the job
              theJob.backupJob.backupSourceParams.splice(index, 1);
              return true;
            }
          }
        );
      }

      $scope.submitting = true;

      JobService.updateJob(theJob).then(
        function updateJobSuccess(resp) {
          $uibModalInstance.close('removed');
        },
        evalAJAX.errorMessage
      ).finally(
        function updateJobFinally() {
          $scope.submitting = false;
        }
      );

    };

    /**
     * delete's servers previous Job (with or without snapshots) and
     * resolves modal
     *
     * @param      {boolean}  deleteSnapshots  indicates if snapshots should
     *                                         be deleted or not
     */
    $scope.deleteJob = function deleteJob(deleteSnapshots) {

      var opts = {
        deleteSnapshots: deleteSnapshots
      };

      $scope.submitting = true;
      $scope.deletingSnapshots = deleteSnapshots;

      JobService.deleteJob($scope.jobs[0].jobId, opts).then(
        function deleteJobSuccess(resp) {
          $uibModalInstance.close('deleted');
        },
        evalAJAX.errorMessage
      ).finally(
        function deleteJobFinally() {
          $scope.submitting = false;
          $scope.deletingSnapshots = false;
        }
      );

    };

    /**
     * abort mission. cancel the modal
     */
    $scope.cancel = function cancel() {
      $uibModalInstance.dismiss('cancel');
    };

    /**
     * initiates the modal by loading/finding Jobs
     * for the provided Server
     */
    function init() {
      // private API node vs public API node. This ternary allows this modal
      // to serve both cases.
      nodeId = node.entity ? node.entity.id : node.protectionSource.id;

      var params = {
        // Include all job types which might be limited to a single
        // protection job. Naturally Physical servers (6) have this
        // limitation. We also have to include SQL (3) jobs because
        // there may be a job protecting a SQL instance on a physical
        // server. Same for Active Directory (29).
        envTypes: [3, 6, 29],
        isDeleted: false,
      };

      $scope.loading = true;

      JobService.getJobs(params).then(
        function getJobsSuccess(jobs) {

          $scope.jobs = jobs.filter(
            function findRelevantJobs(job) {
              return job.sources.some(function findInSources(source) {
                return source.entities[0].id === nodeId;
              });
            }
          );

          if (!$scope.jobs.length) {
            $uibModalInstance.dismiss('no-jobs');
          }
        },
        function getJobsFail(resp) {
          evalAJAX.errorMessage(resp);
          $uibModalInstance.dismiss('api-error');
        }
      ).finally(
        function getJobsFinally() {
          $scope.loading = false;
        }
      );
    }

    // Do the Dew
    init();

  }

}(angular));
