/**
 * This file contains constants common to the nosql-hadoop connectors.
 */
import { HBaseConnectParams, HdfsConnectParams, HiveConnectParams } from '@cohesity/api/v1';
import {
  RecoverCassandraParams,
  RecoverCassandraSnapshotParams,
  RecoverCouchbaseParams,
  RecoverCouchbaseSnapshotParams,
  RecoverHbaseParams,
  RecoverHbaseSnapshotParams,
  RecoverHdfsParams,
  RecoverHdfsSnapshotParams,
  RecoverHiveParams,
  RecoverHiveSnapshotParams,
  RecoverMongodbParams,
  RecoverMongodbSnapshotParams,
} from '@cohesity/api/v2';
import { Environment, EnvItems } from '@cohesity/iris-shared-constants';
import { RecoverCouchbaseFormOptions } from 'src/app/modules/restore/recover-couchbase/model';
import { CreateRecoveryForm } from 'src/app/modules/restore/restore-shared';

/**
 * EnvItems list for Hadoop sources.
 */
export const hadoopEnvItems: EnvItems[] = [
  {
    environment: Environment.kHBase
  },
  {
    environment: Environment.kHdfs
  },
  {
    environment: Environment.kHive
  }
];

/**
 * Ssh authentication method for testing connection to a host.
 */
export enum SshAuthenticationMethod {

  /**
   * Password based authentication.
   */
  kPassword = 'kPassword',

  /**
   * Private key based authentication.
   */
  kPrivateKey = 'kPrivateKey',
}

/**
 * Hadoop distribution types.
 */
export enum HadoopDistribution {

  /**
   * Cloudera.
   */
  CDH = 'CDH',

  /**
   * Hortonworks.
   */
  HDP = 'HDP',
}

/**
 * Mapping of Object properties to entity types required at the time of recovery.
 */
export const objectPropertiesMap: { [key1 in Environment]?: { [key2 in string]?: string[] } } = {
  kCassandra: {
    kKeyspace: [
      'replication',
    ],
    kTable: [
      'compaction',
      'compression',
    ],
  },
  kMongoDB: {
    kCollection: [
      'shardKeyJson'
    ],
  }
};

/**
 * Mapping of Advanced source config to entity types.
 */
export const AdvancedConfigMap: { [key1 in Environment]?: string[] } = {
  kHdfs: [
    'dfs.replication',
  ]
};

/**
 * Aggregated type for all the Hadoop environments.
 */
export type HadoopEnvironment = Environment.kHBase | Environment.kHdfs | Environment.kHive;

/**
 * Aggregated type for all the V2 source params for Hadoop environments.
 */
export type HadoopConnectParams = HBaseConnectParams | HdfsConnectParams | HiveConnectParams;

/*
 * Hadoop authentication types.
 */
export enum HadoopAuthType {

  /**
   * Kerberos authentication.
   */
  KERBEROS = 'KERBEROS',

  /**
   * No authentication.
   */
  NONE = 'NONE',
}

/*
 * Hdfs connection types.
 */
export enum HdfsConnectionType {

  /**
   * DFS protocol.
   */
  DFS = 'DFS',

  /**
   * WEBHDFS protocol.
   */
  WEBHDFS = 'WEBHDFS',

  /**
   * HTTPFS-LB protocol.
   */
  HTTPFSLB = 'HTTPFSLB',

  /**
   * HTTPFS protocol.
   */
  HTTPFS = 'HTTPFS',
}

/**
 * Hadoop source edit mode types.
 */
export enum HadoopSourceEditMode {

  /**
   * Source configuration is refreshed.
   */
  RECONFIGURE = 'RECONFIGURE',

  /**
   * Optional HBase/Hive sources can be appened to an existing HDFS source.
   */
  APPEND = 'APPEND',
}

/**
 * Aggregated type for all the NoSQL recovery snapshot params.
 */
export type NoSqlRecoverySnapshotParams =  RecoverCassandraSnapshotParams |
  RecoverCouchbaseSnapshotParams |
  RecoverHbaseSnapshotParams |
  RecoverHdfsSnapshotParams |
  RecoverHiveSnapshotParams |
  RecoverMongodbSnapshotParams;

/**
 * Aggregated type for all the NoSQL recovery job params.
 */
export type NoSqlRecoveryJobParams =  RecoverCassandraParams |
  RecoverCouchbaseParams |
  RecoverHbaseParams |
  RecoverHdfsParams |
  RecoverHiveParams |
  RecoverMongodbParams;

/**
 * Job type Backup/Recovery.
 */
export type JobType = 'Recovery' | 'Backup';

/**
 * Parent object type map
 */
export const parentObjectTypeMap: { [key in Environment]?: string } = {
  kCassandra: 'kKeyspace',
  kCouchbase: 'kBucket',
  kHBase: 'kNamespace',
  kHdfs: 'hdfsFolders',
  kHive: 'kDatabase',
  kMongoDB: 'kDatabase',
};

/**
 * Leaf object type map
 */
export const leafObjectTypeMap: { [key in Environment]?: string } = {
  kCassandra: 'kTable',
  kHBase: 'kTable',
  kHdfs: 'hdfsFiles',
  kHive: 'kTable',
  kMongoDB: 'kCollection',
};

/**
 * Shorthand for the CreateRecoveryForm typed for Couchbase.
 */
export type CouchbaseRecoveryForm = CreateRecoveryForm<RecoverCouchbaseFormOptions>;

/**
 * Protection job path type for HDFS.
 */
export enum HdfsPathType {

  Specific = 'specific',

  All = 'allPath',
}

/**
 * Protect entire HDFS source constant.
 */
export const HdfsProtectEntireSource = '/*';
