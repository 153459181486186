/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { GetAnomaliesResponse } from '../models/get-anomalies-response';
import { ShieldType } from '../models/shield-type';
import { TimestampMsecs } from '../models/timestamp-msecs';

@Injectable({
  providedIn: 'root',
})
export class AnomaliesApiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAnomalies
   */
  static readonly GetAnomaliesPath = '/anomalies';

  /**
   * Get the list of discovered Anomalies.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAnomalies()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAnomalies$Response(params?: {

    /**
     * Search Anomalies by &#x27;shield name&#x27; or &#x27;rule name&#x27;.
     */
    searchString?: null | string;

    /**
     * Filter by a shield types.
     */
    shieldTypes?: null | Array<ShieldType>;

    /**
     * Start time in milli seconds to filter Anomalies.
     */
    startTimeMsecs?: TimestampMsecs;

    /**
     * End time in milli seconds to filter Anomalies.
     */
    endTimeMsecs?: TimestampMsecs;

  }): Observable<StrictHttpResponse<GetAnomaliesResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AnomaliesApiService.GetAnomaliesPath, 'get');
    if (params) {

      rb.query('searchString', params.searchString, {});
      rb.query('shieldTypes', params.shieldTypes, {});
      rb.query('startTimeMsecs', params.startTimeMsecs, {});
      rb.query('endTimeMsecs', params.endTimeMsecs, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetAnomaliesResponse>;
      })
    );
  }

  /**
   * Get the list of discovered Anomalies.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAnomalies$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAnomalies(params?: {

    /**
     * Search Anomalies by &#x27;shield name&#x27; or &#x27;rule name&#x27;.
     */
    searchString?: null | string;

    /**
     * Filter by a shield types.
     */
    shieldTypes?: null | Array<ShieldType>;

    /**
     * Start time in milli seconds to filter Anomalies.
     */
    startTimeMsecs?: TimestampMsecs;

    /**
     * End time in milli seconds to filter Anomalies.
     */
    endTimeMsecs?: TimestampMsecs;

  }): Observable<GetAnomaliesResponse> {

    return this.getAnomalies$Response(params).pipe(
      map((r: StrictHttpResponse<GetAnomaliesResponse>) => r.body as GetAnomaliesResponse)
    );
  }

}
