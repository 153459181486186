import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBarConfig as MatSnackBarConfig, MatLegacySnackBarRef as MatSnackBarRef } from '@angular/material/legacy-snack-bar';
import { SnackBarService } from '@cohesity/helix';
import { defaultErrorConfig } from '@cohesity/utils';
import { TranslateService } from '@ngx-translate/core';
import { QuorumError } from 'src/app/core';

/**
 * Interface for quorum error.
 */
interface QuorumErrorInfo {
  /**
   * The snackbar message for the quorum error.
   */
  message: string;

  /**
   * The status for the snackbar message.
   */
  status: 'error' | 'success';

  /**
   * Whether to attempt exit of the workflow which resulted in the quorum error.
   */
  attemptWorkflowExit: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class QuorumErrorService {
  constructor(
    private matDialog: MatDialog,
    private snackBarService: SnackBarService,
    private translateService: TranslateService,
  ) {
  }

  /**
   * Function to return the message string and status of a quorum error.
   *
   * @param error The quorum error.
   * @return The quorom error details.
   */
  getQuorumErrorInfo(error: QuorumError): QuorumErrorInfo {
    const {options: {type, isRequestExistsAlready, id} = {}} = error;

    switch (type) {
      case 'GroupRequired':
        return {
          message: this.translateService.instant('rpaas.onboarding.quorumGroup.subtitle'),
          status: 'error',
          attemptWorkflowExit: false,
        };
      case 'ApprovalRequired': {
        if (isRequestExistsAlready) {
          // Error snackbar messages have an option to copy the message to
          // clipboard. Show the snackbar message as error if there is an id in
          // the message so that there is an option to copy the message.
          return {
            message: this.translateService.instant('quorum.approvalErrorMessageRetry', {id}),
            status: 'error',
            attemptWorkflowExit: true,
          };
        }

        return {
          message: this.translateService.instant('quorum.approvalErrorMessage'),
          status: 'success',
          attemptWorkflowExit: true,
        };
      }
    }
  }

  /**
   * Function to attempt closing the workflow which caused the quorum
   * error to be displayed. This is useful in cases such as recovery,
   * where after the quorum request is created, the recovery form should be
   * closed.
   *
   * @return Whether there was a workflow exit.
   */
  attemptWorkflowExit(stateManagementService): boolean {
    if (this.matDialog.openDialogs?.length) {
      // Hack - if a dialog is opened, most likely the dialog would be
      // closed in the subscribe block (subscribe block will not be executed
      // for API calls returned with a quorum response).
      this.matDialog.openDialogs.forEach(dialog => dialog.close());

      return true;
    }

    if (document.querySelector('.cog-takeover-theme')) {
      // Hack - if in a takeover form page, most likely the takeover form would
      // be closed in the subscribe block (subscribe block will not be executed
      // for API calls returned with a quorum response).
      stateManagementService?.goToPreviousState();

      return true;
    }

    return false;
  }

  /**
   * Function to handle an API call which returned with a quorum response.
   *
   * @param error The error object.
   * @param args Optional. MatSnackbarConfig options.
   */
  handleQuorumError(error: QuorumError,
    args: MatSnackBarConfig = defaultErrorConfig, stateManagementService = null): MatSnackBarRef<any> {
    const {message, status, attemptWorkflowExit} = this.getQuorumErrorInfo(error);

    if (attemptWorkflowExit && this.attemptWorkflowExit(stateManagementService)) {
      // Don't show "go back" in this case.
      return this.snackBarService.open(message, status, true, args);
    }

    const snackBarRef = this.snackBarService.open(
      message,
      status,
      true,
      args,
      this.translateService.instant('goBack'),
    );

    snackBarRef.onAction().subscribe(() => stateManagementService?.goToPreviousState());

    return snackBarRef;
  }
}
