import { AppModuleConfig, StateAccessContext, StateAccessMap } from 'src/app/app-module.config';
import { environment } from 'src/environments/environment';

const exampleAccess = (ctx: StateAccessContext) => ctx.FEATURE_FLAGS.uiExamplesPage || !environment.production;
export const ExamplesConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'ng2-examples.**',
      url: '/ng2-examples',
      loadChildren: () => import('./examples.module').then(m => m.ExamplesModule),
    },
  ],
  allClusterMap: {
    'ng2-examples': {
      singleClusterState: 'ng2-examples',
      allClustersState: 'ng2-examples',
      globalContext: true,
    },
  },
  getStateAccessMap(): StateAccessMap {
    return [
      'ng2-examples.api',
      'ng2-examples.buttons',
      'ng2-examples.carousel',
      'ng2-examples.cluster-selection-tree',
      'ng2-examples.dateFormatPicker',
      'ng2-examples.forms',
      'ng2-examples.page-dialog',
      'ng2-examples.pulse',
      'ng2-examples.runbookTraget',
      'ng2-examples.snapshot-selector',
      'ng2-examples.source-tree',
      'ng2-examples.tables',
      'ng2-examples.tabs.tab1',
      'ng2-examples.tabs.tab2',
      'ng2-examples.tabs.tab3',
      'ng2-examples.tabs',
      'ng2-examples.text-diff',
      'ng2-examples.time-field',
      'ng2-examples.timeFormatPicker',
      'ng2-examples.timeline',
      'ng2-examples.typography',
      'ng2-examples',
    ].reduce((map, state) => Object.assign(map, { [state]: exampleAccess }), {});
  },
};
