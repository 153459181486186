<fieldset [formGroup]="formGroup">
  <div [formArrayName]="formControlNames.listItems"
    *ngFor="let control of formGroupControls.listItems.controls; let i = index">
    <coh-text-input-list-item
      [formControl]="control"
      (removeItem)="removeListItem(i)"
      (addItem)="addListItem(i + 1)"
      [label]="i === 0 ? label : ''"
      [allowAdd]="allowAdd(i)"
      [allowRemove]="formGroupControls.listItems.length !== 1">
    </coh-text-input-list-item>
  </div>
</fieldset>
