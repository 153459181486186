import { Injectable } from '@angular/core';
import {
  AxisLabelsFormatterContextObject,
  AxisTickPositionerCallbackFunction,
  FormatterCallbackFunction,
  getMagnitude,
  normalizeTickInterval,
  Point,
} from 'highcharts';

import { ByteSizeService } from '../../byte-size/public_api';

/**
 * Some data types require special formatting in the UI. This provides callbacks that can be used to specify
 * label formatters, tick positioners, etc... that are specific to data types.
 */
export interface HighchartsDataConfig {
  /**
   * Custom positioner for axis labels. Used to make nicer tick values or bytes values.
   */
  tickPositioner?: AxisTickPositionerCallbackFunction;

  /**
   * Formatter for tooltip labels
   */
  tooltipPointFormatter?: FormatterCallbackFunction<Point>;

  /**
   * Formatter or axis labels
   */
  axisLabelFormatter?: FormatterCallbackFunction<AxisLabelsFormatterContextObject>;
}

/**
 * This service provides data-type-specific formatters and configurations that can be put directly
 * into highcharts option configs.
 */
@Injectable({ providedIn: 'root' })
export class HighchartsDataConfigService {
  constructor(private bytesService: ByteSizeService) {}

  /**
   * Gets a configuration object with formatters based on a data type
   *
   * @param dataType  This is purpposely typed as a string, since some charts may work with data types
   *                  that are not defined or recognized in highcharts. In those cases, this service
   *                  will simply return an empty object.
   * @returns  A data config with formatters that can be used with chart options configs.
   */
  getConfigForDataType(dataType: string): HighchartsDataConfig {
    switch (dataType) {
      case 'bytes':
        return this.getBytesConfig();
      case 'number':
        return this.getNumberConfig();
      case 'percentage':
        return this.getNumberConfig(true);
    }
    return {
      tickPositioner: null,
      tooltipPointFormatter: null,
      axisLabelFormatter: null,
    };
  }

  /**
   * Bytes formatters and tick positions
   *
   * @returns A config for bytes data types
   */
  private getBytesConfig(): HighchartsDataConfig {
    // Highcharts uses 'this' in its callback reference, so we save a reference to the this
    // context here and use the full function syntax instead of =>.
    const self = this;
    return {
      // Use y value for tooltip labels.
      tooltipPointFormatter: function () {
        return self.bytesService.bytesToSize(this.y).displayValue;
      },

      // Use the axis max to get the preferred units and label the rest of the values based on that.
      axisLabelFormatter: function () {
        const preferredUnit = self.bytesService.bytesToSize(this.axis.max).unit;
        return self.bytesService.bytesToSize(this.value as number, 1, false, preferredUnit).displayValue;
      },

      // We want nice, even numbers in bytes for y axis labels. This determines are preferred units, uses
      // those values for the highcharts tick calculations, and then converts back to the raw bytes values
      // and lets the label formatter give readable strings later on.
      // The type definition for `AxisTickPositionerCallbackFunction`, does not include the min/max
      // values for arguments, and the Axis definition does not include tickInterval, so there is some
      // manual casting here to get around those issues.
      tickPositioner: function (this: any, min: number, max: number) {
        const preferredUnit = max ? self.bytesService.bytesToSize(max).unit : 'Bytes';
        const minBytes = min && (self.bytesService.bytesToSize(min, 1, false, preferredUnit).size as number);
        const maxBytes = max && (self.bytesService.bytesToSize(max, 1, false, preferredUnit).size as number);
        let tickIntervalBytes =
          max && self.bytesService.bytesToSize(this.tickInterval, 1, false, preferredUnit).size as number;

        // pick round numbers when possible. If the tick interval is less than one, just leave it as is.
        if (tickIntervalBytes > 1) {
          tickIntervalBytes = Math.floor(tickIntervalBytes);
        }
        tickIntervalBytes = normalizeTickInterval(
          tickIntervalBytes,
          void 0,
          getMagnitude(tickIntervalBytes),
          tickIntervalBytes <= 0.5,
          !!this.tickAmount
        );
        const positions: number[] = this.getLinearTickPositions(tickIntervalBytes, minBytes, maxBytes);
        return positions.map(position => self.bytesService.sizeToBytes(position, preferredUnit));
      } as AxisTickPositionerCallbackFunction,
    };
  }

  /**
   * Number formatters and tick positions
   *
   * @returns A config for number data types
   */
  private getNumberConfig(isPercentage = false): HighchartsDataConfig {
    return {
      // Use y value for tooltip labels.
      tooltipPointFormatter: function () {
        const y = Math.round(this.y * 100) / 100;

        return `${y}${isPercentage ? '%' : ''}`;
      },

      axisLabelFormatter: function () {
        return `${this.value}${isPercentage ? '%' : ''}`;
      },

      tickPositioner: null,
    } as HighchartsDataConfig;
  }
}
