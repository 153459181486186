// Component: c-filters

;(function(angular, undefined) {
  'use strict';

  angular.module('C.cFilters', ['ui.select', 'ui.bootstrap', 'C.uibDropdown'])
    .controller('FiltersCtrl', FiltersCtrlFn)
    .component('cFilters', {
      // Attribute bindings
      bindings: {
        /**
         * Provide the function to be executed when filters need to be applied.
         *
         * @type   {function}
         */
        applyFilter: '&',
      },

      // transcluding filter fields from outside to inside with filter pills.
      transclude: {
        // floor fields are shown always and generally they are top 3-4 fields
        // for a page.
        floorFields: 'floorFields',

        // Optionally provide ceil fields that are shown only when all filter
        // are shown in modal.
        ceilFields: '?ceilFields',
      },
      controller: 'FiltersCtrl',
      templateUrl: 'app/global/c-filters/c-filters.html',
    });

  /**
   * $ngdoc Component
   * @name C.cFilters:cFilters
   *
   * @description
   *   c-filters act as a container for the filter fields and allow is to
   *   interact then fields value by exposing apply, clear and abort APIs used
   *   by filter pills and filter header in modal view.
   *
   *   provided apply-filter fn will be called when filters are modified by the
   *   user.
   *
   * @example
      <c-filters class="c-form-sleek"
        apply-filter="$ctrl.loadData()">

        <floor-fields>
          <div class="flex-row-sm vertical-align">
            <ui-select
              c-field
              c-field-label="jobType"
              c-field-value="kVal"
              name="jobTypeFilter"
              ng-model="$ctrl.filters.jobTypeFilter">
              ...
            </ui-select>

            ...
          </div>
        </floor-fields>

        <ceil-fields>
          <select-tenant
            multiple
            c-field
            c-field-label="organization"
            c-field-value="name"
            result-as="tenantId"
            name="jobTenantFilter"
            ng-model="$ctrl.filters.jobTenantFilter">
            ...
          </select-tenant>
        </ceil-fields>
      </c-filters>
   */
  function FiltersCtrlFn(_, $timeout, SlideModalService) {
    var $ctrl = this;

    _.assign($ctrl, {
      // component properties
      fields: [],
      registeredFieldMap: {},
      shownFilters: true,
      shownViewAllFilters: false,

      // component methods
      startLoading: startLoading,
      endLoading: endLoading,
      deRegisterField: deRegisterField,
      registerField: registerField,
      toggleFilters: toggleFilters,
      toggleViewAllFilters: toggleViewAllFilters,

      // c-filters methods used to interact with c-field.
      abort: abort,
      apply: apply,
      clear: clear,
      abortAll: abortAll,
      applyAll: applyAll,
      clearAll: clearAll,
      closeAndAbortAll: closeAndAbortAll,
      closeAndApplyAll: closeAndApplyAll,
      closeAndClearAll: closeAndClearAll,
    });

    /**
     * Registers c-field API interface user later to perform bulk operations
     * like clear all, abort all etc
     *
     * @method   registerField
     * @param    {String}  name           The unique field name.
     * @param    {Object}  fieldInterface   The c-field API interface.
     */
    function registerField(name, fieldInterface) {
      if (!$ctrl.registeredFieldMap[name]) {
        $ctrl.registeredFieldMap[name] = {};
        startLoading(name);
      }

      _.assign($ctrl.registeredFieldMap[name], fieldInterface);
      updateFilters();
    }

    /**
     * Mark field loading and to prevent applying filter if all fields are not
     * ready.
     *
     * @method   startLoading
     * @param    {String}  name           The unique field name.
     */
    function startLoading(name) {
      $ctrl.registeredFieldMap[name].isLoading = true;
    }

    /**
     * Mark field loaded and try applying the filter if all other fields are
     * loaded.
     *
     * @method   endLoading
     * @param    {String}  name           The unique field name.
     */
    function endLoading(name) {
      $ctrl.registeredFieldMap[name].isLoading = false;
      updateFilters();
    }

    /**
     * Removed the registered c-field API interface.
     *
     * @method   deRegisterField
     * @param    {String}  name           The unique field name.
     */
    function deRegisterField(name) {
      delete $ctrl.registeredFieldMap[name];
      updateFilters();
    }

    /**
     * Toggle b/w only pills view and filter with floor fields.
     * floor fields are top 3,4 fields that is most used in a page.
     *
     * @method   toggleFilters
     */
    function toggleFilters() {
      $ctrl.shownFilters = !$ctrl.shownFilters;
    }

    /**
     * Toggle b/w all fields view and most used fields view.
     *
     * @method   toggleViewAllFilters
     */
    function toggleViewAllFilters() {
      $ctrl.shownViewAllFilters = !$ctrl.shownViewAllFilters;

      // resetting form when toggling b/w inline and modal view because we are
      // already preventing apply filter if form is invalid.
      $ctrl.filterForm.$setPristine();

      // loop over each registered field and prepare them for view all mode.
      _.forEach($ctrl.registeredFieldMap, function eachFiled(fieldInterface) {
        if ($ctrl.shownViewAllFilters) {
          fieldInterface.enableAlwaysOpen();
        } else {
          fieldInterface.disableAlwaysOpen();
        }
      });

      // launch the modal and hide the model programmatically when view all is
      // toggled off.
      if ($ctrl.shownViewAllFilters) {
        $ctrl.modalRef = launchModal();
      } else {
        $ctrl.modalRef.close();
        $ctrl.modalRef = null;
      }
    }

    /**
     * Launches the modal returns the modal instance used to programmatically
     * close the modal.
     *
     * @method   launchModal
     * @returns  {Object}  The modal instance.
     */
    function launchModal() {
      var modalInstance = SlideModalService.newModal({
        size: 'full',
        returnModalInstance: true,
        windowTopClass: 'c-filters-modal',
        resolve: {
          innerComponent: 'cPreRenderedModal',
          titleKey: null,
          actionButtonKey: null,
          closeButtonKey: null,
          hideModalHeaderCloseBtn: true,
          bindings: {
            // providing the selector to get filters element that will be shown
            // inside the modal by cPreRenderedModal component.
            selector: 'c-filters',
          },
        },
      });

      // aborting the changes when modal is closed by escape or any other reason
      modalInstance.result.catch($ctrl.closeAndAbortAll);
      return modalInstance;
    }

    /**
     * Abort the changes for the provided field and update the filter view.
     *
     * @method   abort
     * @param    {Object}  fieldInterface   The c-field API interface.
     */
    function abort(fieldInterface) {
      fieldInterface.abort();
      updateFilters();
    }

    /**
     * Apply the selected value for the provided field and update the filter
     * view.
     *
     * @method   apply
     * @param    {Object}  fieldInterface   The c-field API interface.
     */
    function apply(fieldInterface) {
      // show form invalid error message when form is invalid.
      if ($ctrl.filterForm.$invalid) {
        $ctrl.filterForm.$setSubmitted();
        return;
      }

      fieldInterface.apply();
      updateFilters();
    }

    /**
     * Clear the selected value for the provided field and update the filter
     * view.
     *
     * @method   clear
     * @param    {Object}  fieldInterface   The c-field API interface.
     */
    function clear(fieldInterface, index) {
      fieldInterface.clear(index);
      updateFilters();
    }

    /**
     * Apply the selected value for the all the field and update the filter
     * view.
     *
     * @method   abortAll
     */
    function abortAll() {
      _.forEach($ctrl.registeredFieldMap, function eachFiled(fieldInterface) {
        fieldInterface.abort();
      });
      updateFilters();
    }

    /**
     * Apply the selected value for the all the field and update the filter
     * view.
     *
     * @method   applyAll
     */
    function applyAll() {
      _.forEach($ctrl.registeredFieldMap, function eachFiled(fieldInterface) {
        fieldInterface.apply();
      });
      updateFilters();
    }

    /**
     * Clear the selected value for the all the field and update the filter
     * view.
     *
     * @method   clearAll
     */
    function clearAll() {
      _.forEach($ctrl.registeredFieldMap, function eachFiled(fieldInterface) {
        fieldInterface.clear();
      });
      updateFilters();
    }

    /**
     * Close the modal and abort the selected value for the all the field.
     *
     * @method   closeAndAbortAll
     */
    function closeAndAbortAll() {
      $ctrl.toggleViewAllFilters();
      $ctrl.abortAll();
    }

    /**
     * Close the modal and apply the selected value for the all the field.
     *
     * @method   closeAndApplyAll
     */
    function closeAndApplyAll() {
      // show form invalid error message when form is invalid.
      if ($ctrl.filterForm.$invalid) {
        $ctrl.filterForm.$setSubmitted();
        return;
      }

      $ctrl.toggleViewAllFilters();
      $ctrl.applyAll();
    }

    /**
     * Close the modal and clear the selected value for the all the field.
     *
     * @method   closeAndClearAll
     */
    function closeAndClearAll() {
      $ctrl.toggleViewAllFilters();
      $ctrl.clearAll();
    }

    /**
     * Refresh the filter pills and apply the filter changes.
     *
     * @method   updateFilters
     */
    function updateFilters() {
      var isLoading = _.some($ctrl.registeredFieldMap,
        function eachFiled(fieldInterface) {
          return fieldInterface.isLoading;
        }
      );

      // prevent applying filter changes until all fields are loaded.
      if (!isLoading) {
        refreshFilters();

        if ($ctrl.filterForm.$invalid) {
          // setting form submitted manually because updateReportUrl is not
          // getting called by normal input type submit button.
          $ctrl.filterForm.$setSubmitted();
          return;
        }

        // apply filter in next digest cycle to give time to angularJS to update
        // ng-model and other bindings.
        $timeout($ctrl.applyFilter);
      }
    }

    /**
     * Update internal list of fields used for c-pills with applied filters.
     *
     * @method   refreshFilters
     */
    function refreshFilters() {
      $ctrl.fields = _.map($ctrl.registeredFieldMap,
        function eachFiled(fieldInterface) {
          // get field pills values
          return fieldInterface.getPillValues();
        }).filter(function eachFiled(fieldInterface) {
          // filter out empty filter fields.
          return fieldInterface.values.length;
      });
    }
  }
})(angular);
