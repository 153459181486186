import { IrisContext, getUserPrivilegeCheck } from '@cohesity/iris-core';
import { PermissionPredicate } from './has-permission.directive';

/**
 * Helper method to check for permissions.
 *
 * @param permissions the list of permissions to test for.
 * @param irisContext iris context.
 * @param predicate the predicate for testing permissions.
 * @returns true if permission is there else false.
 */
export const HasCustomRBACPermissions = (permissions: string[], irisContext: IrisContext, predicate: PermissionPredicate = 'hasAny') => {
  const legacyPrivileges = ['DGAAS_VIEW', 'DGAAS_MODIFY'];
  // new privileges will be removed after this code is in prod.
  const newPrivileges = [
    'DGAAS_DOWNLOAD_DC_SCAN_SUMMARY_REPORT',
    'DGAAS_DOWNLOAD_THREAT_SCAN_SUMMARY_REPORT',
    'DGAAS_INTEGRATE_THIRD_PARTY_THREAT_INTELLIGENCE_FEED',
    'DGAAS_MANAGE_CUSTOM_YARA_RULES',
    'DGAAS_MANAGE_DC_POLICY',
    'DGAAS_MANAGE_DC_SCAN',
    'DGAAS_MANAGE_THREAT_SCAN',
    'DGAAS_VIEW_DC_SCAN',
    'DGAAS_VIEW_THREAT_SCAN',
    'RANSOMWARE_DOWNLOAD_AFFECTED_FILES',
    'RANSOMWARE_MANAGE_RULES',
    'RANSOMWARE_VIEW_RULES',
    'SECOPS_MANAGE_POSTURE_ADVISOR',
    'SECOPS_VIEW_POSTURE_ADVISOR',
  ];
  const isCustomRbacEnabled = newPrivileges.some((permission) => getUserPrivilegeCheck(permission)(irisContext));
  const filteredLegacyPrivileges = permissions.filter((perm) => legacyPrivileges.includes(perm));

  /**
   * If custom RBAC is not enabled, and permissions list has legacy privileges
   * then test only for legacy privileges.
   */
  if (!isCustomRbacEnabled && filteredLegacyPrivileges.length) {
    permissions = filteredLegacyPrivileges;
  } else if (isCustomRbacEnabled && filteredLegacyPrivileges.length) {
    permissions = permissions.filter((permission) => !filteredLegacyPrivileges.includes(permission));
  }

  if (predicate === 'hasAny') {
    return permissions.some((permission) => getUserPrivilegeCheck(permission)(irisContext));
  } else if (predicate === 'hasAll') {
    return permissions.every((permission) => getUserPrivilegeCheck(permission)(irisContext));
  }
};
