/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityV2ApiConfiguration as __Configuration } from '../cohesity-v2api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Releases } from '../models/releases';
@Injectable({
  providedIn: 'root',
})
class SoftwareManagementService extends __BaseService {
  static readonly GetReleasesPath = '/mcm/software-mgmt/releases';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get all releases present in the db.
   *
   * Get all releases present in the db.
   * @param releaseType Get releases of particular release type
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  GetReleasesResponse(releaseType?: null | string,
    regionId?: string): __Observable<__StrictHttpResponse<Releases>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (releaseType != null) __params = __params.set('releaseType', releaseType.toString());
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/mcm/software-mgmt/releases`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Releases>;
      })
    );
  }
  /**
   * Get all releases present in the db.
   *
   * Get all releases present in the db.
   * @param releaseType Get releases of particular release type
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  GetReleases(releaseType?: null | string,
    regionId?: string): __Observable<Releases> {
    return this.GetReleasesResponse(releaseType, regionId).pipe(
      __map(_r => _r.body as Releases)
    );
  }
}

module SoftwareManagementService {
}

export { SoftwareManagementService }
