import { DataRenderer, IconDataRendererComponent } from '@cohesity/helix';
import { enumGroupMap, Environment, ObjectTypeToIconMap, OsVariantIconMap } from '@cohesity/iris-shared-constants';
import { envGroupIconMap } from './env-group-icon-renderer';

/**
 * Params used for rendering an object icon in a table
 */
export interface ObjectIconParams {
  /**
   * The row/data key containing the object's environment type
   */
  environmentKey: string;

  /**
   * The row/data key containing the object's os type. This is optional
   */
  osKey?: string;

  /**
   * The row/data key containing the object's protection type. This is optional.
   */
  protectionStatusKey?: string;
}

/**
 * Returns the helix object icon
 *
 * @param   objectType      The type of the object being rendered.
 * @param   renderParams    Additional params needed to parse the extra info from the secondary value.
 * @param   secondaryValue  Secondary value containing environment, protection status, etc...
 * @returns A renderer config containing the helix icon as the value.
 */

function getObjectIcon(objectType: string, renderParams: ObjectIconParams, secondaryValue: any): string {
  const { environmentKey, osKey, protectionStatusKey } = renderParams || {};

  const environment: Environment = secondaryValue && environmentKey && secondaryValue[environmentKey];
  const osType = secondaryValue && osKey && secondaryValue[osKey];
  const isProtected = secondaryValue && protectionStatusKey && secondaryValue[protectionStatusKey] === 'Protected';
  let objectIcon: string;

  // We can't render anything without environment. OS type is optional.
  if (!environment) {
    return '';
  }

  if (objectType) {
    const environmentGroup = ObjectTypeToIconMap[environment] || {};
    objectIcon = environmentGroup[objectType] || 'helix:object-generic';

    if (osType && OsVariantIconMap[objectIcon] && OsVariantIconMap[objectIcon][osType]) {
      objectIcon = OsVariantIconMap[objectIcon][osType];
    }
  } else if (environment === Environment.kAzure && !isProtected) {
    // Use only if objectType is not available with azure environment, utilised
    // only in reports.
    objectIcon = 'helix:object-azure-resting';
  } else {
    // Use environment only if objectType is not available.
    const envGroup = enumGroupMap[environment];

    objectIcon = envGroupIconMap[envGroup] || 'helix:object-generic';
  }

  if (protectionStatusKey && isProtected) {
    objectIcon += '-protected';
  }

  return objectIcon;
}

/**
 * Renders an object icon based on the type, environment, protection status and os type.
 */
export const objectIconRenderer = {
  transform: (renderValues: DataRenderer<string, ObjectIconParams, any>) => ({
    ...renderValues,
    value: getObjectIcon(renderValues.value, renderValues.renderParams, renderValues.secondaryValue),
  }),
  component: IconDataRendererComponent,
};
