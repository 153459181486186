export enum ArchivalTargetIcon {
  kCloud = 'icn-cloud',
  kTape = 'icn-tape',
}

export enum ArchivalTarget {
  kCloud = 0,
  kTape = 1,
  kNas = 2
}

/**
 * Ownership context indicates whether a target is for local (cluster) use, or for rpaas. This is for v1 apis only.
 */
export enum OwnershipContext {
  kOwnershipContextLocal = 'kOwnershipContextLocal',
  kOwnershipContextFortKnox = 'kOwnershipContextFortKnox'
}

/**
 * The possible target types includes local and cloud.
 */
export type TargetTypes = 'local' | 'Tape' | 'kTape' | 'Nas' | 'kNas' | 'Cloud' | 'kCloud' | 'kStorageArraySnapshot';
