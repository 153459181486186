// COMPONENT: Cloud Retrieval: Retrieval Settings modal (single Job and multiple
//            Jobs).

// NOTE: Translation namespace: 'cloudSearchSettingsModal'


;(function(angular, undefined) {
  'use strict';

  var moduleName = 'C.cloud-retrieval';

  angular
    .module(moduleName)
    .controller('CloudSearchSettingsModalController', CloudSearchSettingsModalControllerFn);

  function CloudSearchSettingsModalControllerFn(DateRangerService,
    $uibModalInstance, task, modifiedHash, FEATURE_FLAGS) {

    var ctrl = this;
    var nowUsecs = Date.now() * 1000;

    angular.merge(ctrl, {
      task: task,
      willOverwriteJobSettings: !!(task.searchJobUid &&
        // If only one job is modified, "Edit all" is effectively the same
        // thing, so we won't waste the user's time messaging about this.
        Object.keys(modifiedHash).length > 1),
      updatedTask: angular.copy(task),
      includeSnapshotRange: !!task._selectedDateRange,
      includeRestorePoint: !!task._snapshot,
      dateRange: task._selectedDateRange ||
        DateRangerService.generateRangeObject(),
      showSnapshotSelection: true,

      // Methods
      includeSnapshotChanged: includeSnapshotChanged,
      isRestorePointSelected: isRestorePointSelected,
      saveSettings: saveSettings,
      selectRestorePoint: selectRestorePoint,
    });

    /**
     * Activation routines for this controller.
     *
     * @method   activate
     */
    function activate() {
      // Do not show snapshot selection for Oracle if feature flag is disabled.
      if (ctrl.task.environment === 'kOracle' &&
        !FEATURE_FLAGS.oracleCloudSearchDownloadSnapshot) {
        ctrl.showSnapshotSelection = false;
        ctrl.includeRestorePoint = false;
      }
      // Default date range to day for meta data download.
      ctrl.dateRange.range = 'day';
    }

    /**
     * ngChange handler for toggling includeRestorePoint.
     *
     * @method   includeSnapshotChanged
     */
    function includeSnapshotChanged() {
      var task = ctrl.updatedTask;

      if (!task.jobUid) {
        // If this is master settings, just set snapshot to the boolean.
        task._snapshot = ctrl.includeRestorePoint;
      } else {
        // If this is job settings, set snapshot to false OR previously selected
        // snapshot.
        task._snapshot = !ctrl.includeRestorePoint ?
          false :
          task.protectionJobRuns[0];
      }
    }

    /**
    * Determines if the given restorePoint is pre-selected or not. First tests
    * if a pre-selected snapshot's jobRunId is the same as the one given, then
    * if it's the first in the list (in the case of no previous selection).
    *
    * @method   isRestorePointSelected
    * @param    {object}    restorePoint   The restorePoint to check selection
    *                                      for.
    * @return   {boolean}   True if it's selected, false otherwise.
    */
    function isRestorePointSelected(restorePoint) {
      var jobRuns = ctrl.updatedTask.protectionJobRuns;

      return (ctrl.updatedTask._snapshot &&
        ctrl.updatedTask._snapshot.jobRunId === restorePoint.jobRunId &&
        ctrl.updatedTask._snapshot.archiveTaskUid.id ===
        restorePoint.archiveTaskUid.id) ||
        (restorePoint.jobRunId === jobRuns[0].jobRunId &&
        restorePoint.archiveTaskUid.id === jobRuns[0].archiveTaskUid.id);
    }

    /**
     * updates the model with the selected restore point
     *
     * @method   selectRestorePoint
     * @param    {object}   restorePoint   The selected restore point
     */
    function selectRestorePoint(restorePoint) {
      ctrl.updatedTask._snapshot = restorePoint;
    }

    /**
     * Save the chosen settings and close this modal.
     *
     * @method   saveSettings
     */
    function saveSettings(form) {
      if (!form.$valid) {
        return;
      }

      ctrl.updatedTask._selectedDateRange = ctrl.includeSnapshotRange &&
        ctrl.dateRange;

      // If this modal is in Master Settings mode
      ctrl.updatedTask._snapshot = ctrl.updatedTask.searchJobUid ?
        // Just set this to the selected boolean, otherwise
        ctrl.includeRestorePoint :
        // Set it to the chosen snapshot, or false if unchecked.
        ctrl.includeRestorePoint && ctrl.updatedTask._snapshot;

      $uibModalInstance.close(ctrl.updatedTask);
    }


    // Activate this controller
    activate();
  }

})(angular);
