<cog-table name="recovery-file-listing-table"
  [source]="recovery.files || []">
  <table mat-table
    matSort
    matSortActive="name"
    matSortDirection="asc"
    [trackBy]="trackByPath">

    <ng-container matColumnDef="name">
      <th mat-header-cell cogDataId="file-object-name-col" *matHeaderCellDef mat-sort-header>
        {{ 'fileOrFolder' | translate }}
      </th>
      <td mat-cell *matCellDef="let fileObject">
        <div class="cog-table-object">
          <cog-icon [shape]="fileObject.isDirectory ? 'helix:object-folder' : 'description!outline'">
          </cog-icon>
          <div class="cog-table-object-body">
            <div class="flex-truncate-wrapper">
              <div class="cog-table-object-name flex-truncate-text">
                {{ fileObject.name }}
              </div>
            </div>
            <ul>
              <li>{{ 'sourcePathColon' | translate }} {{ fileObject.path }}</li>
            </ul>
            <ul *ngIf="fileObject.destinationDir">
              <li>{{ 'destinationPathColon' | translate }} {{ fileObject.destinationDir }}</li>
            </ul>
          </div>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell cogDataId="file-object-status-col" *matHeaderCellDef mat-sort-header>
        {{ 'status' | translate }}
      </th>
      <td mat-cell *matCellDef="let fileObject">
        {{ ('enum.fileStatus.' + fileObject.status) | translate }}
      </td>
    </ng-container>

    <ng-container matColumnDef="message">
      <th *matHeaderCellDef cogDataId="file-object-message-col" mat-header-cell mat-sort-header colspan="2">
        {{ 'message' | translate }}
      </th>
      <td mat-cell *matCellDef="let fileObject">
        <ng-container *ngIf="!fileObject.messages?.length">{{ 'naNotAvailable' | translate }}</ng-container>
        <ul class="file-message-container">
          <li *ngFor="let message of fileObject.messages">
            <div class="flex-truncate-wrapper">
              <div class="flex-truncate-text user-data-xl"
                [matTooltip]="message"
                matTooltipPosition="above">
                {{ message }}
              </div>
            </div>
          </li>
        </ul>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr mat-row *matRowDef="let i = index; let row; columns: columns"
      [cogDataId]="'file-object-anchor-' + (row.name | lowercase)"
      [class.no-activity-row]="!progressMontiorTaskPaths[i]?.length"
      (click)="openActivityModal(row, progressMontiorTaskPaths[i])"></tr>

    <!-- NoData Footer -->
    <ng-container matColumnDef="noData">
      <td [attr.colspan]="columns.length" mat-footer-cell *matFooterCellDef>
        {{ 'noObjectsFound' | translate }}
      </td>
    </ng-container>

    <tr class="nodata-row" mat-footer-row [class.hidden]="recovery?.files?.length" *matFooterRowDef="['noData']"></tr>
  </table>
  <mat-paginator></mat-paginator>
</cog-table>
