// Component: VLANs list

;(function (angular, undefined) {
  'use strict';

  var vlansConfig = {
    bindings: {
      /**
       * Optional provide vlans list to show. If present, then don't fetch VLANs
       * and just display provide VLANs.
       */
      vlans: '=?',

      /**
       * Optional function if provided then it will be used to determine if a
       * vlan is selectable or not else by default all vlans are considered
       * selectable when selection is enabled.
       */
      canSelectVlan: '=?',

      /**
       * Optional function if provided then it will be used to get the tooltip
       * for the vlan which is not selectable else by default no tooltip will be
       * shown.
       */
      getDisableTooltip: '=?',

      /**
       * Optional if provided then it will be used to filter out vlans received
       * from the API.
       */
      filterVlans: '=?',

      /**
       * Optional attribute sniffed value. If present, then view, edit VLANs
       * navigation will happen in modal
       */
      // inModal: '?',

      /**
       * Optional attribute sniffed value. If present, then view, edit VLANs
       * navigation will happen in modal
       */
      // inViewMode: '?',

      /**
       * Optional attribute sniffed value. If present, then show the add VLAN
       * link at bottom of the table.
       */
      // allowAddVlan: '?',

      /**
       * Optional attribute sniffed value. To show vLANs modify action inline
       * like edit/delete etc.
       */
      // inlineActions: '?',

      /**
       * Optional attribute sniffed value.  If present then delete VLAN action
       * will only remove VLAN from the list else it will open the VLAN deletion
       * modal.
       */
      // removeVlanFromListOnDelete: '?',

      /**
       * Optional attribute sniffed value. If present then disabling editing
       * shared vlans.
       */
      // disableEditingSharedVlans: '?',

      /**
       * Optional provided a callback which will be called when a vlan was removed
       * from the list.
       */
      onRemove: '&?',

      /**
       * Optional attribute sniffed value. If present, then hide the filters and
       * vice-versa.
       */
      // noFilters: '?',

      /**
       * Optional attribute sniffed value. If present, then allow vLAN's VIPs
       * modification inline.
       */
      // allowEditVipsInline: '?',
    },
    require: {
      /**
       * Optional provide ngModel. If present, allow VLAN selection and selected
       * VLANs are provided via ngModel binding.
       */
      ngModel: '?ngModel',
    },
    templateUrl: 'app/platform/vlans/vlan-list.html',
    controller: 'VlanListCtrl',
  };

  angular
    .module('C.vlan')
    .controller('VlanListCtrl', vlanListCtrlFn)
    .component('vlanList', vlansConfig);

  function vlanListCtrlFn(_, $attrs, VlanService, evalAJAX, TenantService,
    $rootScope, SlideModalService, NetworkService) {

    var $ctrl = this;
    var oldVlanUids;
    var vlanProvided = $attrs.hasOwnProperty('vlans');

    _.assign($ctrl, {
      // initialize if VLANs are not provided
      vlans: vlanProvided ? $ctrl.vlans : [],
      vlansReady: vlanProvided,
      isIpV6Supported: NetworkService.isIpV6Supported(),
      allowAddVlan: $attrs.hasOwnProperty('allowAddVlan'),
      allowEditVipsInline: $attrs.hasOwnProperty('allowEditVipsInline'),
      inlineActions: $attrs.hasOwnProperty('inlineActions'),
      inModal: $attrs.hasOwnProperty('inModal'),
      inViewMode: $attrs.hasOwnProperty('inViewMode'),
      noFilters: $attrs.hasOwnProperty('noFilters'),
      removeVlanFromListOnDelete:
        $attrs.hasOwnProperty('removeVlanFromListOnDelete'),
      disableEditingSharedVlans:
        $attrs.hasOwnProperty('disableEditingSharedVlans'),

      addVlanModal: addVlanModal,
      canSelectVlan: $ctrl.canSelectVlan || _.constant(true),
      filterVlans: $ctrl.filterVlans || _.constant(true),
      getVlans: getVlans,
      getDisableTooltip: $ctrl.getDisableTooltip || _.constant(),
      getInterfaceGroupName: VlanService.getInterfaceGroupName,
      interfaceGroupNameComparator: interfaceGroupNameComparator,
      modifyVlanVips: modifyVlanVips,
      toggleSelection: toggleSelection,
      viewVlanModal: viewVlanModal,

      filter: {
        tenantIds: [],
      },

      // don't fetch the VLANs if already provided via binding
      $onInit: vlanProvided ? undefined : getVlans,

      // update the view when VLANs are already provided or updated
      $doCheck: vlanProvided ? $doCheck : undefined,
    });

    /**
     * prepare VLANs on vlans binding updates
     *
     * @method   $doCheck
     */
    function $doCheck() {
      var vlanUids = _.map($ctrl.vlans, '_uid').sort();
      if (!_.isEqual(oldVlanUids, vlanUids)) {
        oldVlanUids = vlanUids;
        prepareVlans();
      }
    }

    /**
     * Toggle VLAN selection
     *
     * @method   toggleSelection
     * @param    {Object}   vlan   The vlan to toggle selection
     */
    function toggleSelection(vlan) {
      var selectedVlansMap = $ctrl.ngModel.$modelValue;

      if (selectedVlansMap[vlan._uid]) {
        delete selectedVlansMap[vlan._uid];
      } else {
        selectedVlansMap[vlan._uid] = vlan;
      }

      $ctrl.ngModel.$setViewValue(selectedVlansMap);
    }

    /**
     * Select the provided VLAN.
     *
     * @method   selectVlan
     * @param    {Object}   vlan   The vlan to select.
     */
    function selectVlan(vlan) {
      var selectedVlansMap = $ctrl.ngModel.$modelValue;

      selectedVlansMap[vlan._uid] = vlan;
      $ctrl.ngModel.$setViewValue(selectedVlansMap);
    }

    /**
     * Remove the provided VLAN.
     *
     * @method   removeVlan
     * @param    {Object}   vlan   The vlan to remove.
     */
    function removeVlan(vlan) {
      var selectedVlansMap = $ctrl.ngModel.$modelValue;

      delete selectedVlansMap[vlan._uid];
      $ctrl.ngModel.$setViewValue(selectedVlansMap);
    }

    /**
     * Gets the vlans.
     *
     * @method   getVlans
     */
    function getVlans() {
      var params = TenantService.extendWithTenantParams({
        _includeTenantInfo: true,
      }, $ctrl.filter.tenantIds);

      // Don't filter out primary interface, In some cases,
      // primary interface is tagged(ENG-147970).
      _.assign(params, {skipPrimaryAndBondIface: false});

      $ctrl.vlansReady = false;

      VlanService.getVlans(params).then(
        function getVlansSuccess(vlans) {
          $ctrl.vlans = vlans.filter($ctrl.filterVlans);

          // Skip vlan interface with id 0.
          $ctrl.vlans = $ctrl.vlans.filter(
            function filterPrimaryInterface(vlan) {
              return vlan.id && vlan.id !== 0;
            });
          return prepareVlans();
        }, evalAJAX.errorMessage)
        .finally(function afterGotResponse() {
          $ctrl.vlansReady = true;
        });
    }

    /**
     * prepare VLANs list for view by adding actions menu.
     *
     * @method   prepareVlans
     * @return   {Object}   Promise resolved when vlans list is ready after
     *                      resolving tenant info & setting up action menu
     */
    function prepareVlans() {
      if (!$ctrl.inViewMode) {
        $ctrl.vlans.forEach(function eachVlan(vlan) {
          vlan._actionsMenu = buildVlanActions(vlan);
        });
      }
    }

    /**
     * builds and returns the context actions for a given vlan
     *
     * @method     buildVlanActions
     * @param      {object}  vlan  to build context actions for
     * @return     {array}   actions available for the vlan
     */
    function buildVlanActions(vlan) {
      if (vlan.allTenantAccess && $ctrl.disableEditingSharedVlans) {
        return [];
      }

      return [
        $ctrl.allowEditVipsInline ? {
          translateKey: 'vip.addVip',
          icon: 'icn-xs icn-add enclosed',
          action: $ctrl.inModal ? function editVlanWrapper() {
            modifyVlanVips(vlan);
          } : undefined,
          state: $ctrl.inModal ? undefined : 'networking.vips',
          stateParams: {
            defaultIfaceGroupName: vlan.ifaceGroupName,
          },
        } : undefined,
        {
          translateKey: 'vlan.editVlan',
          icon: 'icn-edit',
          action: $ctrl.inModal ? function editVlanWrapper() {
            editVlanModal(vlan);
          } : undefined,
          state: $ctrl.inModal ? undefined : 'vlan',
          stateParams: {
            id: vlan.ifaceGroupName,
            mode: 'edit',
          },
        },
        {
          translateKey: 'vlan.deleteVlan',
          icon: $ctrl.removeVlanFromListOnDelete ? 'icn-cancel' : 'icn-delete',
          action: function deleteVlanWrapper() {
            if ($ctrl.removeVlanFromListOnDelete) {
              _.remove($ctrl.vlans, ['_uid', vlan._uid]);

              if (_.isFunction($ctrl.onRemove)) {
                $ctrl.onRemove(vlan);
              }
            } else {
              deleteVlan(vlan);
            }
          },
        },
      ].filter(Boolean);
    }

    /**
     * Delete the requested vlan.
     *
     * @method   deletePartition
     * @param    {object}   vlan   vlan to delete
     */
    function deleteVlan(vlan) {
      VlanService.deleteVlanModal(vlan).then(
        function deleteVlanSuccess() {
          // Removes deleted vlan from vlans list
          _.remove($ctrl.vlans, ['_uid', vlan._uid]);
        }
      );
    }

    /**
     * Add a vlan config modal and refresh the list.
     *
     * @method   addVlanModal
     */
    function addVlanModal() {
      VlanService.addVlanModal().then(
        function onSuccess(addedVlan) {
          // select the VLAN if it is shared with all tenants.
          if (addedVlan.allTenantAccess) {
            selectVlan(addedVlan);
          }
          $ctrl.vlans.push(addedVlan);
          prepareVlans();
        }
      );
    }

    /**
     * Edit vlan config modal and refresh the list.
     *
     * @method   editVlanModal
     */
    function editVlanModal(vlan) {
      VlanService.editVlanModal(vlan).then(
        function onSuccess(updatedVlan) {
          if (updatedVlan.allTenantAccess) {
            // select the VLAN if it is shared with all tenants.
            selectVlan(updatedVlan);
          } else if (vlan.allTenantAccess && !updatedVlan.allTenantAccess) {
            // removing the updated VLAN because keeping it selected may or may
            // not be true as allTenantAccess or subnet etc would have been
            // modified and selection should pass canSelectVlan() test.
            removeVlan(updatedVlan);
          }
          updateVlans(vlan, updatedVlan);
        }
      );
    }

    /**
     * view vlan config in modal
     *
     * @method   viewVlanModal
     */
    function viewVlanModal(vlan, $event) {
      if ($ctrl.inModal) {
        $event.preventDefault();
        VlanService.viewVlanModal(vlan);
      }
    }

    /**
     * Launch modify vlan VIPs component in modal view.
     *
     * @method   modifyVlanVips
     * @param   {Object}  vlan   The vlan.
     */
    function modifyVlanVips(vlan) {
      var modalConfig = {
        size: 'xl',
        resolve: {
          innerComponent: 'vipList',
          actionButtonKey: null,
          closeButtonKey: null,
          idKey: 'tenant-vlan-vips',
          titleKey: null,
          bindings: {
            // disable filter and changing selected interface group for vip-list
            // component.
            noFilters: true,
            defaultIfaceGroupName: vlan.ifaceGroupName,
          },
        },
      };

      return SlideModalService.newModal(modalConfig)
        .then(function getSelectedVlans(updatedVlan) {
          updateVlans(vlan, updatedVlan);
        }, function onClose(updatedVlan) {
          // updatedVlan will not be present on escape key press.
          if (updatedVlan) {
            updateVlans(vlan, updatedVlan);
          }
        });
    }

    /**
     * Update the vlan list by patching the updated vlans with modifications.
     *
     * @method  updateVlans
     * @param   {Object}  vlan          The original vlan which got updated.
     * @param   {Object}  updatedVlan   The updated vlan.
     */
    function updateVlans(vlan, updatedVlan) {
      // find and patch updated vlan in the vlan list.
      _.assign(_.find($ctrl.vlans, ['_uid', vlan._uid]), updatedVlan);
      prepareVlans();
    }

    /**
     * Sort interface groups name by prefixes alphabets.
     * If they are the same, sort their suffixes, ids.
     *
     * @method  interfaceGroupNameComparator
     * @param   {string}  name1   an interface group name
     * @param   {string}  name2   an interface group name
     * @return  {number}          return -1 if name1 has smaller number or texts
     */
    function interfaceGroupNameComparator(name1, name2) {
      // If we don't get strings, just compare by index
      if (name1.type !== 'string' || name2.type !== 'string') {
        return (name1.index < name2.index) ? -1 : 1;
      } else if (name1.value.includes('.') && name2.value.includes('.')) {
        var name1SubString = name1.value.split('.');
        var name2SubString = name2.value.split('.');
        var ifaceName1 = name1SubString[0];
        var id1 = Number(name1SubString[1]);
        var ifaceName2 = name2SubString[0];
        var id2 = Number(name2SubString[1]);

        if (ifaceName1 == ifaceName2) {
          return (id1 < id2) ? -1 : 1;
        } else {
          return ifaceName1.localeCompare(ifaceName2);
        }
      } else {
        return name1.localeCompare(name2);
      }
    }
  }

})(angular);
