import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { LegacyThemePalette as ThemePalette } from '@angular/material/legacy-core';
import { MatLegacySliderChange as MatSliderChange } from '@angular/material/legacy-slider';
import { Subject } from 'rxjs';

/**
 * Range slider form definition.
 */
export interface RangeSliderValue {
  /**
   * Minimum value for range slider.
   */
  min: number;

  /**
   * Maximum value for range slider.
   */
  max: number;
}

/**
 * Range slider component.
 *
 * @example
 *  <cog-range-slider [formControl]="selectedRange"
 *    [min]="min"
 *    [max]="max"
 *    [color]="themeColor"
 *    [tabIndex]="tabIndex"
 *    [step]="step"
 *    [thumbLabel]="thumbLabel"
 *    [tickInterval]="tickInterval"
 *    [displayWith]="displayWithFn"
 *    (valueChange)="emitValueChange($event)">
 *  </cog-range-slider>
 */
@Component({
  selector: 'cog-range-slider',
  templateUrl: './range-slider.component.html',
  styleUrls: ['./range-slider.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RangeSliderComponent),
      multi: true,
    },
  ],
})
export class RangeSliderComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit, ControlValueAccessor {
  /**
   * Emits whenever the component is destroyed.
   * Subscriptions can use 'takeUntil(this.destroy)' to automatically clean up whenever the component is destroyed.
   */
  private destroy = new Subject<void>();

  /**
   * The maximum value that the slider can have.
   */
  @Input() max = 100;

  /**
   * The minimum value that the slider can have.
   */
  @Input() min = 0;

  /**
   * Determines whether the component is disabled.
   */
  @Input() disabled: boolean;

  /**
   * Optional material color value to use for the slider.
   */
  @Input() color: ThemePalette;

  /**
   * Tab index value to specify to which to fall back to if no range value is set.
   */
  @Input() tabIndex: number;

  /**
   * The values at which the thumb will snap.
   */
  @Input() step = 1;

  /**
   * Specifies whether to show the thumb label.
   */
  @Input() thumbLabel: boolean;

  /**
   * Specifies the interval to show ticks in the slider.
   */
  @Input() tickInterval: number;

  /**
   * Method that will be used to format the value before it is displayed in
   * the thumb label.
   */
  @Input() displayWith: (value: number) => string | number;

  /**
   * Emits when the raw value of the slider changes. This is here primarily
   * to facilitate the two-way binding for the `value` input.
   */
  @Output() readonly valueChange: EventEmitter<RangeSliderValue> = new EventEmitter<RangeSliderValue>();

  /**
   * Maximum range slider component ref.
   */
  @ViewChild('sMax', {read: ElementRef}) sliderMax: ElementRef;

  /**
   * Tab index value to specify to which to fall back to if no max value is set.
   */
  get maxTabIndex(): number {
    return (this.tabIndex || 0) + 1;
  }

  /**
   * Specifies the slider fill bar element ref.
   */
  fillBarElementRef: HTMLElement;

  /**
   * Selected slider range value.
   */
  selectedRange: RangeSliderValue;

  /**
   * The placeholder method populated by forms API registerOnChange method which
   * is used to update changes from view to modal.
   */
  onChange: (value: RangeSliderValue) => void = () => {};

  /**
   * The placeholder method populated by forms API registerOnTouched method which
   * is used to mark a form field should be considered blurred or "touched".
   */
  onTouched = () => {};

  constructor(private renderer: Renderer2) { }

  registerOnChange(fn: (value: RangeSliderValue) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  /**
   * Update the view on model changes is request programmatic via forms API.
   *
   * @param   val The range selection of slider.
   */
  writeValue(rangeValue: RangeSliderValue): void {
    // updating the internal form with new values.
    if (rangeValue) {
      this.selectedRange = rangeValue;
    }
  }

  /**
   * Function that is called by the forms API when the control status changes to
   * or from 'DISABLED'. Depending on the status, it enables or disables the
   * appropriate DOM element.
   *
   * @param disabled  disabled state
   */
  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  ngOnInit() {
    this.resetRange();
  }

  ngOnChanges() {
    this.populateRangeFillBar(this.selectedRange);
  }

  ngAfterViewInit() {
    this.fillBarElementRef = this.sliderMax.nativeElement.querySelector('.mat-slider-track-fill');
    this.populateRangeFillBar(this.selectedRange);
  }

  ngOnDestroy() {
    this.destroy.next();
  }

  /**
   * Update selection on mat-slider changes.
   *
   * @param param  The mat slider change event.
   * @param type   The range slider value key type on which the value has
   *               to be updated(eg: 'min'/'max').
   */
  onRangeSelection({ value }: MatSliderChange, type: keyof RangeSliderValue) {
    this.selectedRange[type] = value;

    if (this.selectedRange.min < this.selectedRange.max) {
      this.valueChange.next(this.selectedRange);
      this.populateRangeFillBar(this.selectedRange);

      // update external model on internal changes.
      this.onChange(this.selectedRange);
    } else {
      this.resetRange();
    }
  }

  /**
   * Method called to populate the fillbar between min and max value of range slider.
   *
   * @param  value Slider form value
   */
  populateRangeFillBar(value: RangeSliderValue) {
    if (!this.fillBarElementRef) {
      return;
    }

    // Calculating width and position gap between selected range of values on slider.
    const positionGap = this.max - this.min;
    const width = ((value.max - value.min) / positionGap) * 100;
    const xPosLeft = ((value.min - this.min) / positionGap) * 100;
    this.renderer.setStyle(this.fillBarElementRef, 'width', width + '%');
    this.renderer.setStyle(this.fillBarElementRef, 'margin-left', xPosLeft + '%');
  }

  /**
   * Method called to reset selected range to value provided or defaults to initial value.
   *
   * @param value selected range value
   */
  resetRange(value?: RangeSliderValue) {
    this.selectedRange = { min: value?.min || this.min, max: value?.min || this.max };
    this.valueChange.next(this.selectedRange);
    this.populateRangeFillBar(this.selectedRange);
    this.onChange(this.selectedRange);
  }
}
