<div class="container">
  <div class="row padding-top">
    <div class="col-xs-4">
      <qrcode [qrdata]="userMfaInfo?.totpUri"
        width="250"
        allowEmptyString="true"
        errorCorrectionLevel="M">
      </qrcode>
    </div>
    <div class="col-xs-6 col-offset-2">
      <p translate>mfa.setup.description</p>
      <ul class="mfa-setup-list">
        <li [innerHTML]="'mfa.setup.step1' | translate:{docs:docLink}"></li>
        <li translate>mfa.setup.step2</li>
      </ul>
      <ng-container *ngIf="userMfaInfo; else spinner">
        <div class="mfa-auth-details">
          <form class="mfa-screen">
            <mat-form-field>
              <mat-label translate>account</mat-label>
              <input type="text" matInput [value]="userMfaInfo.userId" readonly>
            </mat-form-field>
            <mat-form-field>
              <mat-label translate>secretKey</mat-label>
              <input matInput [value]="userMfaInfo.totpSecretKey" readonly
                [type]="hideSecretKey ? 'password' : 'text'">
                <mat-icon matSuffix mat-icon-button (click)="hideSecretKey = !hideSecretKey">
                  {{hideSecretKey ? 'visibility_off' : 'visibility'}}
                </mat-icon>
            </mat-form-field>
            <div class="copy-secret">
              <cog-icon shape="content_copy" size="sm" inline="true"></cog-icon>
              <a translate [cdkCopyToClipboard]="userMfaInfo.totpSecretKey">
                copySecretKey
              </a>
            </div>
          </form>
        </div>
        <div class="col-xs-12 margin-top" *ngIf="!disableContinueButton">
          <button
            type="button"
            *ngIf="showAlternateMethod"
            cogDataId="use-alternate-btn"
            mat-button
            color="primary"
            (click)="alternateActionHandler.emit(true)">
            {{ 'mfa.verify.alternativeVerification' | translate }}
          </button>
          <button class="continue-btn"
            type="button"
            cogDataId="continue-btn"
            mat-flat-button
            color="primary"
            (click)="next.emit(true)">
            {{'continue' | translate}}
          </button>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<!-- Spinner -->
<ng-template #spinner>
  <cog-spinner></cog-spinner>
</ng-template>
