// Decorator: uibDaypicker decorator

;(function(angular, undefined) {
  'use strict';

  angular
    .module('ui.bootstrap')
    .config(configFn);

  function configFn($provide) {
    $provide.decorator('uibDaypickerDirective',
      function templateChanger(_, $delegate) {
        var directive = $delegate[0];
        var compile = directive.compile;

        /**
         * fix for angular bootstrap (0.2.2) template issue where custom class
         * is a one time binding:
         * https://github.com/angular-ui/bootstrap/commit/2a2e5de7d89a7a2b55268ec415ee60ba4315972e#commitcomment-12332382
         */
        directive.templateUrl = function tmplHijack(elem, attrs) {
          return attrs.templateUrl ||
            'app/global/c-uib-datepicker/c-uib-datepicker-day.html';
        };

        /**
         * New compile function for uibDaypickerDirective. Without this, there
         * is no way to detect if the active date is changed on uib-datepicker.
         *
         * @param    {Object}  element   The element
         * @param    {Array}   attrs     The attributes
         * @return   {Function}
         */
        directive.compile = function directiveCompile(element, attrs) {
          var link = compile.apply(this, arguments);

          return function directiveLink(scope, element, attrs, ctrls) {
            link.apply(this, arguments);

            // Wrap both of these methods to emit an event after executing
            // the original method.
            ['select', 'move'].forEach(function methodWrapper(methodName) {
              var uibMethod = scope[methodName];
              scope[methodName] = function() {
                uibMethod.apply(this, arguments);
                scope.$emit('uibDaypicker.activeDateChanged',
                  this.activeDt.date);
              };
            });

            function dateChangeEmitter() {
              scope.$emit('uibDaypicker.activeDateChanged', this.activeDt.date);
            }
          };
        };

        return $delegate;
      }
    );
  }

})(angular);
