/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityApiConfiguration as __Configuration } from '../cohesity-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { McmSalesforceUsersList } from '../models/mcm-salesforce-users-list';
@Injectable({
  providedIn: 'root',
})
class UsersService extends __BaseService {
  static readonly GetMcmSalesforceUsersPath = '/public/mcm/users/salesforceUsers';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get all users registered on current Salesforce account.
   *
   * Lists users for current Salesforce account.
   * @param regionId This field represents the region id.
   * @return Success
   */
  GetMcmSalesforceUsersResponse(regionId?: string): __Observable<__StrictHttpResponse<McmSalesforceUsersList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/mcm/users/salesforceUsers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<McmSalesforceUsersList>;
      })
    );
  }
  /**
   * Get all users registered on current Salesforce account.
   *
   * Lists users for current Salesforce account.
   * @param regionId This field represents the region id.
   * @return Success
   */
  GetMcmSalesforceUsers(regionId?: string): __Observable<McmSalesforceUsersList> {
    return this.GetMcmSalesforceUsersResponse(regionId).pipe(
      __map(_r => _r.body as McmSalesforceUsersList)
    );
  }
}

module UsersService {
}

export { UsersService }
