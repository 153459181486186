import { Pipe, PipeTransform } from '@angular/core';
import { MetricType, MetricTypePipe } from '@cohesity/data-govern/anti-ransomware';
import { TranslateService } from '@ngx-translate/core';

/**
 * This pipe returns translated anomaly cause.
 */
@Pipe({
  name: 'anomalyCause',
})
export class AnomalyCausePipe extends MetricTypePipe implements PipeTransform {
  /**
   * translation key mapping for known anomaly causes.
   */
  private readonly anomalyCauseTranslations: Record<string, string> = {
    kAddedFileExtensionChange: 'anomaly.chartMetric.addedFileExtensionChanges',
    kDeletedFileExtensionChange: 'anomaly.chartMetric.deletedFileExtensionChanges',
    kFileExtensionChange: 'anomaly.chartMetric.fileExtensionChanges',
    kRansomwareExtension: 'anomaly.chartMetric.ransomwareExtensions',
  };

  constructor(private transService: TranslateService) {
    super(transService);
  }

  /**
   * Return the transformed anomaly cause.
   *
   * @param anomalyCause The anomaly cause.
   * @returns The translated anomaly cause.
   */
  transform(anomalyCause: string): string {
    // anomaly cause is super set of Metric type, reusing the existing Metric type pipe to get the translation
    const metricType = super.transform(MetricType[anomalyCause]);
    if (metricType) {
      return metricType;
    }

    const translateKey = this.anomalyCauseTranslations[anomalyCause] || null;
    return translateKey ? this.transService.instant(translateKey) : '';
  }
}
