<div class="cluster-switcher-container">
  <mat-form-field *ngIf="clusterConfigs?.length">
    <cog-icon shape="filter_list" class="cluster-filter-list-icon"></cog-icon>
    <mat-select
      cogDataId="cluster-switcher"
      [formControl]="clusterSelector"
      [disabled]="isSelectDisabled"
      (selectionChange)="changeScope()">

      <mat-select-trigger *ngIf="appPillars">
        <ng-container *ngIf="clusterSelector?.value._allClusters">
          <ng-container *ngTemplateOutlet="dataProtectNavBanner"></ng-container>
        </ng-container>
        <div *ngIf="!clusterSelector?.value?._allClusters" class="cluster-single-select-trigger">
          <button
            class="cluster-back-icon"
            type="button"
            mat-icon-button
            cohStopPropagation
            (click)="selectAllClusters()">
            <cog-icon shape="arrow_back" size="sm"></cog-icon>
          </button>
          <div class="cluster-select-trigger-text">
            <span class="mat-display-1 no-margin-bottom cluster-select-trigger-label">{{ 'cluster' | translate }}</span>
            <span [matTooltip]="clusterSelector?.value?.name" class="cluster-select-trigger-name">
              {{ clusterSelector?.value?.name }}
            </span>
          </div>
        </div>
      </mat-select-trigger>

      <ng-container *ngIf="!loadingClusters; else loadingMatOption">

        <mat-option>
          <ngx-mat-select-search
            [formControl]="clusterSearchCtrl"
            [noEntriesFoundLabel]="'noMatchingItems' | translate"
            [placeholderLabel]="'search' | translate"></ngx-mat-select-search>
        </mat-option>

        <!-- options -->
        <mat-option *ngFor="let clusterConfig of searchFilteredConfigs; index as i"
          [id]="'scope-cluster-radio-' + i"
          [cogDataId]="clusterConfig.name"
          [inheritDataId]="true"
          [value]="clusterConfig"
          [disabled]="isDisabled(clusterConfig)"
          [class.hidden]="appPillars && clusterConfig._allClusters">
          <div class="flex-row vertical-align">
            <div>
              {{ clusterConfig.name }}
            </div>
            <div class="spacer"></div>
            <cog-icon
              *ngIf="clusterConfig.connectedToCluster && clusterConfig.claimType === 'kIBMStorageProtect'"
              [inactive]="!clusterConfig.dataplaneUiRedirectUrl"
              shape="launch"></cog-icon>
          </div>
        </mat-option>
      </ng-container>

      <!-- loading option -->
      <ng-template #loadingMatOption>
        <mat-option *ngIf="loadingClusters">
          <div class="flex-row vertical-align flex-justify-center">
            <cog-spinner size="xs"></cog-spinner>
          </div>
        </mat-option>
      </ng-template>
    </mat-select>
  </mat-form-field>
  <div class="no-cluster-nav-banner" *ngIf="!clusterConfigs?.length">
    <ng-container *ngTemplateOutlet="dataProtectNavBanner"></ng-container>
  </div>
</div>

<ng-template #dataProtectNavBanner>
  <div class="cluster-all-select-trigger">
    <cog-icon shape="helix:pillar2-protection" class="cluster-select-trigger-icon"></cog-icon>
    <span>{{ 'dataProtectNoSpace' | translate }}</span>
  </div>
</ng-template>
