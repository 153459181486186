import { Clipboard } from '@angular/cdk/clipboard';
import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { SnackBarService } from '@cohesity/helix';
import { AjaxHandlerService, AutoDestroyable } from '@cohesity/utils';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';

import { DmsConnectionToken, DmsConnectionTokenService } from '../../dms-connection-token.service';

/**
 * This component shows connection token info and allow reset token.
 */
@Component({
  selector: 'coh-dms-connection-token-dialog',
  styleUrls: ['./dms-connection-token-dialog.component.scss'],
  templateUrl: './dms-connection-token-dialog.component.html'
})
export class DmsConnectionTokenDialogComponent extends AutoDestroyable  {
  /**
   * Whether it is loading the token.
   */
  loading: boolean;

  /**
   * Token.
   */
  token: string;

  /**
   * Token Expiration Date.
   */
  expirationDate: number;

  /**
   * Token Expiration in milliseconds.
   */
  expiration: number;

  /**
   * Constructor.
   */
  constructor(
    private ajaxService: AjaxHandlerService,
    private clipboard: Clipboard,
    private dialogRef: MatDialogRef<DmsConnectionTokenDialogComponent>,
    private dmsConnectionTokenService: DmsConnectionTokenService,
    private snackBarService: SnackBarService,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public connection: any
  ) {
    super();
    this.loading = true;
    this.dmsConnectionTokenService.getConnectionToken(connection).pipe(
      this.untilDestroy(),
      finalize(() => this.loading = false),
    ).subscribe((tokenInfo: DmsConnectionToken) => {
      this.token = tokenInfo.token;
      // Set expiration date if available.
      this.expirationDate = tokenInfo.expiration ? tokenInfo.expiration : null;
      this.expiration = tokenInfo.expiration ? tokenInfo.expiration / 1000 - new Date().getTime() : null;
    });
  }

  /**
   * Reset token.
   */
  copyToken() {
    this.clipboard.copy(this.token);
    const msg = this.translate.instant('copiedToClipboard', {
      item: this.token,
    });
    this.snackBarService.open(msg, 'success');
  }

  /**
   * Reset token.
   */
  resetToken() {
    this.dmsConnectionTokenService.renewConnectionToken(this.connection.groupId).pipe(
      this.untilDestroy(),
    ).subscribe(
      (tokenInfo: DmsConnectionToken) => {
        this.token = tokenInfo.token;
        this.expirationDate = tokenInfo.expiration;
        this.expiration = tokenInfo.expiration / 1000 - new Date().getTime();
        this.snackBarService.open(this.translate.instant('connectionTokenResetSuccessMessage', 'success'));
      },
      err => this.ajaxService.errorMessage(err?.error?.message ? err : {
        error: { message: this.translate.instant('connectionTokenResetFailMessage') }
      })
    );
  }
}
