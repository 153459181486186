// Extension: Extends MomentJS with .toUsecDate()

;(function(moment, undefined) {
  'use strict';

  // No moment! Exit.
  if (!moment && !moment.fn) { return; }

  moment.toUsecDate = moment.fn.toUsecDate = function momentToUsecs() {
    return this.toDate() * 1000;
  };

})(moment);
