import { Injectable } from '@angular/core';
import { DecoratedProtectionSourceNode } from 'src/app/shared/models';

@Injectable({
  providedIn: 'root',
})
export class EntityHierarchyService {
  /**
   * Returns leaf entities protected within the given node.
   *
   * @param     node   Specifies the selected/Deselected node.
   * @returns   Number of protected leaf entities.
   */
  calculateProtectedLeafCount(node: DecoratedProtectionSourceNode): number {
    if (!node || !node.protectedSourcesSummary) {
      return 0;
    }
    return node.protectedSourcesSummary[0].leavesCount || 0;
  }

  /**
   * Returns leaf entities unprotected within the given node.
   *
   * @param     node   Specifies the selected/Deselected node.
   * @returns   Number of unprotected leaf entities.
   */
  calculateUnprotectedLeafCount(node: DecoratedProtectionSourceNode): number {
    if (!node || !node.unprotectedSourcesSummary) {
      return 0;
    }
    return node.unprotectedSourcesSummary[0].leavesCount || 0;
  }

  /**
   * Decorates the entity and its child nodes with the required properties.
   * TODO(tauseef): See if DataTreeNode can be extended and used within the entity-selection-list.
   *
   * @param   node   Specifies the entity node to be decorated.
   */
  decorateEntity(node: DecoratedProtectionSourceNode) {
    if (!node) {
      return;
    }
    node._environment = node.protectionSource.environment;

    // TODO(tauseef): Add additional properties if needed.

    // Iterate over children to decorate them too.
    if (node.nodes && node.nodes.length) {
      node.nodes.forEach(childNode => this.decorateEntity(childNode));
    }
  }
}
