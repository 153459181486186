import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { AjsHttpInterceptor } from './ajs-http.interceptor';
import { PassthroughWarningHttpInterceptor } from './passthrough-warning.interceptor';
import { QuorumHttpInterceptor } from './quorum-http.interceptor';
import { RemoteClustersInterceptor } from './remote-clusters.interceptor';
import { ServiceContextInterceptor } from './service-context-interceptor';
import { RequestInitiatorTypeInterceptor } from './request-initiator-type.interceptor';

export const REQUEST_INTERCEPTORS = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AjsHttpInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: PassthroughWarningHttpInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: QuorumHttpInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ServiceContextInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: RemoteClustersInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: RequestInitiatorTypeInterceptor,
    multi: true,
  }
];
