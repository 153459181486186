// Directive: Custom Validations directive.

(function(angular, undefined) {
  'use strict';

  var dirName = 'cValidate';

  angular.module('C.validate', [])
    .directive(dirName, cValidateFn);

  /**
   * @ngdoc directive
   * @name C.validate:cValidate
   * @function
   *
   * @description
   * Heavily inspired by https://github.com/angular-ui/ui-validate.
   *
   * Simple directive to ease creation of custom and one-off model validators
   * without using page-controller hacks.
   *
   * Like ngClass, this directive accepts a hash of validators. The key is the
   * validator name, and the value is a quoted angular expression. It also
   * accepts a single quoted angular expression which uses the default validator
   * name "cValidate".
   *
   * TODO: Make this work with raw angular expressions without quotes, like
   * ngClass.
   *
   * @example
     <fieldset field-status msg-custom="Custom Validator">
       <input type="text" name="myInput"
         c-validate="{custom: '$modelValue >= 0'}">
     </fieldset>
  */
  function cValidateFn() {
    return {
      link: linkFn,
      require: 'ngModel',
      restrict: 'A',
    };

    /**
     * Angular Directive Link Fn.
     *
     * @method   linkFn
     */
    function linkFn(scope, elm, attrs, model) {
      var vExpressions = scope.$eval(attrs.cValidate);

      // If the passed expression is a singular (unnamed validator), create the
      // hash with a default vlidator name: the normalized name of the
      // directive.
      if (angular.isString(vExpressions)) {
        vExpressions = { validator: vExpressions };
      }

      // With each key+value pair of the expressions hash, setup a validator
      // function (by key) and a watcher for changes to the expression (value).
      angular.forEach(vExpressions, function eachValidator(vExpr, vName) {
        model.$validators[vName] = function validator(modelValue, viewValue) {
          return !!scope.$eval(vExpr, {
            $modelValue: modelValue,
            $viewValue: viewValue,
            $name: model.$name,
          });
        };

        // Live validate as the expressions change
        scope.$watch(
          function getEvaluatedExpression() { return scope.$eval(vExpr); },
          model.$validate
        );
      });
    }
  }

})(angular);
