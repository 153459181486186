import { Directive, ElementRef, Injector, Input } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

@Directive({
  selector: 'ajs-pulse-logs-list'
})
export class PulseLogsListComponent extends UpgradeComponent {
  /**
   * Determines whether we want to show the expandable button or not
   */
  @Input() showButton: boolean;

  /**
   * List of pulse event objects to show even messages
   */
  @Input() events: Object[];
  constructor(injector: Injector, elementRef: ElementRef) {
    super('cPulseEventsList', elementRef, injector);
  }
}
