import { Component, ContentChild } from '@angular/core';

import { PageContentHeaderComponent } from '../page-content-header/page-content-header.component';

/**
 * @description
 * Common page content layout wrapper when used within `PageComponent`.
 *
 * @example
 *  <cog-page-content>
 *    Page content body.
 *  </cog-page-content>
 */
@Component({
  selector: 'cog-page-content',
  templateUrl: './page-content.component.html',
  styleUrls: ['./page-content.component.scss']
})
export class PageContentComponent {
  /**
   * ContentChild of the "Top" child component. Used for layout purposes.
   */
  @ContentChild(PageContentHeaderComponent, { static: false })
  pageHeader: PageContentHeaderComponent;
}
