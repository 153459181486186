<div *ngIf="clipboardContent" [class.inline]="inline">
  <div *ngIf="headerKey" [class.margin-bottom-sm]="!inline">
    {{headerKey | translate}}
  </div>
  <div class="clipboard-container" [class.margin-left]="inline">
    <span class="clipboard-content">{{clipboardContent}}</span>
    <!-- Hidden input text to get the clipboard content -->
    <textarea #content
      matInput
      class="visuallyhidden"
      tabindex="-1"
      type="text"
      cogDataId="{{dataId}}-clipboard-content"
      value="{{clipboardContent}}"
      [readonly]="true">
    </textarea>
    <ng-container *ngIf="!inline">
      <ng-container *ngTemplateOutlet="copyButton"></ng-container>
    </ng-container>
  </div>
  <ng-container *ngIf="inline">
    <ng-container *ngTemplateOutlet="copyButton"></ng-container>
  </ng-container>
</div>

<ng-template #copyButton>
  <button mat-icon-button
    type="button"
    cogDataId="copy-{{dataId}}-to-clipboard-button"
    (click)="copy()"
    matTooltip="{{tooltipText | translate}}">
    <cog-icon shape="helix:copy"></cog-icon>
  </button>
</ng-template>
