import { CommonModule } from '@angular/common';
import { HasPermissionDirective } from './has-permission.directive';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [
    HasPermissionDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    HasPermissionDirective
  ]
})
export class RbacModule { }
