import { Injectable } from '@angular/core';
import { IrisContextService } from '@cohesity/iris-core';
import { RawParams, StateObject, Transition } from '@uirouter/core';

import { StateManagementService, V1V2ApiUtilsService } from '../../services';
import { GuardPriority, GuardResult, StateGuard } from '../state-guard';

/**
 * Redirect states for restore, based on feature flags and state names.
 */
const redirectMap = {
  // MS SQL redirects
  'recover-db': 'recover-ms-sql',
  'recover-db.options': 'recover-ms-sql',

  // Files redirects
  'recover-files': 'recover-files-ng',
  'recover-files.search': 'recover-files-ng',
  'recover-files.options': 'recover-files-ng',

  // Hypervisor redirects
  'recover-vm': 'recover-vm-ng',
  'recover-vm.recover-options': 'recover-vm-ng',

  // Azure SQL redirects
  'recover-azure-sql': 'recover-azure-sql-ng',
  'recover-azure-sql.recover-options': 'recover-azure-sql-ng',

  // RDS redirects
  'recover-rds': 'recover-rds-ng',
  'recover-rds.search': 'recover-rds-ng',
  'recover-rds.options': 'recover-rds-ng',

  // Virtual Disk redirects
  'recover-vmdk': 'recover-virtual-disk-ng',
  'recover-vmdk.options': 'recover-virtual-disk-ng',

  // Instant Volume Mount redirects
  'recover-mount-point': 'instant-volume-mount-ng',
  'recover-mount-point.options': 'instant-volume-mount-ng',

  // Physical server redirects
  'recover-physical-server': 'recover-physical-server-ng',
  'recover-physical-server.options': 'recover-physical-server-ng',

  // Storage volume redirects
  'recover-storage-volume': 'recover-storage-volume-ng',
  'recover-storage-volume.nas-options': 'recover-storage-volume-ng',
  'recover-storage-volume.pure-options': 'recover-storage-volume-ng',

  // Kubernetes redirects
  'kubernetes-recovery': 'recover-kubernetes-ng',
  'kubernetes-recovery.search': 'recover-kubernetes-ng',
  'kubernetes-recovery.search.show-cart': 'recover-kubernetes-ng',
  'kubernetes-recovery.restore': 'recover-kubernetes-ng',

  // TODO(Tauseef/Yash): Add redirects here, once all office365 ui flows are
  // ng-fied.
};

/**
 * Array of legacy states to match on for redirect.
 */
const matchStates = Object.keys(redirectMap);

/**
 * Restore Guard redirects all state traffic to the ng restore states if
 * the feature flags are eanbled.
 */
@Injectable({
  providedIn: 'root',
})
export class RestoreGuard implements StateGuard {
  /**
   * This guard priority is set to App, which indicates it should run as part of
   * the main application after all bootstrapping and initializing is completed.
   */
  guardPriority = GuardPriority.App;

  /**
   * Match all navigation to jobs (classic ui state).
   */
  matchCriteria = {
    to: (state: StateObject, transition: Transition) => matchStates.includes(transition.targetState().name()),
  };

  constructor(
    private irisCtx: IrisContextService,
    private stateManagementService: StateManagementService,
    private v1v2util: V1V2ApiUtilsService,
  ) {}

  /**
   * Convert old recovery flow params to the new recovery params model. The new
   * flow requires the full jobID with cluster id and cluster incarnation id and
   * the job run start time. If job is specified and those properties are not
   * set this will throw an error indicating that the updates params are not
   * present and need to be updated.
   *
   * @param   params   The state params from the ajs flow
   * @returns Updates params that can be used for the ng restore flow.
   */
  convertToNgParams(params: RawParams): RawParams {

    if (!params.jobId) {
      return params;
    }

    if (params.jobId && !params.jobUid) {
      throw new Error(`Redirects to ng recovery flows requires the jobUid param any time the jobId is used.
      Ensure this is added to the state params`);
    }

    if (params.jobInstanceId && !params.jobRunStartTime) {
      throw new Error(`Redirects to ng recovery flows requires the jobRunStartTime param any time the jobInstanceId
       is used. Ensure this are added to the state params`);
    }
    const newParams = { ...params };

    // For recoveries that support the PIT timeline, e.g. kSQL and kOracle,
    // rearrange the PIT params a little.
    if (newParams.logTimeMs) {
      newParams.pointInTimeMsec = newParams.logTimeMs;
      delete newParams.logTimeMs;
    }

    return {
      ...newParams,

      // Object protected objects will show '-1' for jobId, but jobUid will have a full value in it.
      // Make sure we don't set a protection group id here.
      protectionGroupId: String(params.jobId) !== '-1' ?
        this.v1v2util.generateV2protectionGroupId(newParams.jobUid) : null,
      runId: [newParams.jobId, newParams.jobRunStartTime].join(':'),
      objectId: newParams.entityId,
      sourceId: newParams.sourceId || (newParams.sourceEntity && newParams.sourceEntity.id),
    };
  }

  /**
   * Redirect to the correct restore page with params.
   *
   * @param   transition  The ui router transition
   * @returns a redirect transtion if the corresponding feature flag is enabled.
   */
  onStart(transition: Transition): GuardResult {
    const targetName = transition.targetState().name();
    const params = transition.params();
    const ngParams = this.convertToNgParams(params);
    let redirectState = redirectMap[targetName];

    // mysql and oracle both have same state name recover-db in classic UI.
    // So handle redirection for oracle here.
    if ((targetName === 'recover-db' || targetName === 'recover-db.options' || targetName === 'recover-db.search')
      && params.dbType === 'oracle') {
      redirectState = 'recover-oracle';
    }

    if (this.stateManagementService.canUserAccessState(redirectState, ngParams)) {
      return transition.router.stateService.target(redirectState, ngParams);
    }
  }
}
