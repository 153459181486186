import { ChangeDetectionStrategy, Component, Optional } from '@angular/core';
import { AttributeFilter } from '@cohesity/api/reporting';
import { FiltersComponent, ValueFilterSelection } from '@cohesity/helix';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';
import { Observable, of } from 'rxjs';

import { BaseReportFilterComponent } from '../base-report-filter.component';

/**
 * Possible Run Status Values. This is a subset of all possible enum values.
 */
const runStatus = [
  'kRunning',
  'kSuccess',
  'kWarning',
  'kFailure',
  'kCanceled',
  'kSkipped',
];

class BaseRunStatusFilterComponent extends BaseReportFilterComponent {
  constructor(
    @Optional() private irisCtx: IrisContextService,
    filters: FiltersComponent,
    property: string,
  ) {
    super(filters, property);
  }

  getFilterValues(): Observable<ValueFilterSelection[]> {
    return of(
      runStatus.filter(status =>
        status !== 'kSkipped' || flagEnabled(this.irisCtx.irisContext, 'skippedStatusHelios')
      ).map(value => ({
        value,
        label: this.translate(`enum.backupJob.${value}`),
      }))
    );
  }

  toApiValue(filterValues: ValueFilterSelection[]): AttributeFilter {
    if (!filterValues?.length) {
      return null;
    }
    return {
      attribute: this.property,
      filterType: 'In',
      inFilterParams: {
        attributeDataType: 'String',
        stringFilterValues: filterValues.map(value => value.value as string),
        attributeLabels: filterValues.map(value => value.label),
      },
    };
  }

  fromApiValue(apiValue: AttributeFilter): ValueFilterSelection[] {
    return (apiValue?.inFilterParams.stringFilterValues || []).map((value, index) => ({
      label: apiValue.inFilterParams.attributeLabels[index],
      value: value,
    }));
  }
}

@Component({
  selector: 'iris-rpt-run-status-filter',
  templateUrl: './run-status-filter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RunStatusFilterComponent extends BaseRunStatusFilterComponent {
  label = 'runStatus';

  constructor(irisCtx: IrisContextService, filters: FiltersComponent) {
    super(irisCtx, filters, 'status');
  }
}

@Component({
  selector: 'iris-rpt-last-run-status-filter',
  templateUrl: './run-status-filter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LastRunStatusFilterComponent extends BaseRunStatusFilterComponent {
  constructor(irisCtx: IrisContextService, filters: FiltersComponent) {
    super(irisCtx, filters, 'lastRunStatus');
  }
}

@Component({
  selector: 'iris-rpt-last-run-status-with-label-filter',
  templateUrl: './run-status-filter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LastRunStatusLabelFilterComponent extends BaseRunStatusFilterComponent {
  label = 'lastRunStatus';

  constructor(irisCtx: IrisContextService, filters: FiltersComponent) {
    super(irisCtx, filters, 'lastRunStatus');
  }
}

@Component({
  selector: 'iris-rpt-task-status-filter',
  templateUrl: './run-status-filter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskStatusFilterComponent extends BaseRunStatusFilterComponent {
  label = 'status';

  constructor(irisCtx: IrisContextService, filters: FiltersComponent) {
    super(irisCtx, filters, 'status');
  }
}

@Component({
  selector: 'iris-rpt-backup-status-with-label-filter',
  templateUrl: './run-status-filter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BackupStatusLabelFilterComponent extends BaseRunStatusFilterComponent {
  label = 'backupStatus';

  constructor(irisCtx: IrisContextService, filters: FiltersComponent) {
    super(irisCtx, filters, 'backupStatus');
  }
}
