import { Injectable } from '@angular/core';
import { McmClusterConnectionStatus } from '@cohesity/api/private';
import { ComponentsPreviewParams, ComponentsServiceApi, ExternalTarget, FiltersServiceApi } from '@cohesity/api/reporting';
import {
  HeliosPolicyResponse,
  ObjectEnvironmentArchivalStats,
  ObjectServiceApi,
  PolicyServiceApi,
  RpaasRegionInfo,
} from '@cohesity/api/v2';
import {
  awsColdStorageEntitlement,
  awsWarmStorageEntitlement,
  azureColdStorageEntitlement,
  azureHotStorageEntitlement,
  IrisContextService,
} from '@cohesity/iris-core';
import { getApiFilterParam, getApiParams, ReportComponentName } from '@cohesity/iris-reporting';
import { AsyncBehaviorSubject, AsyncStateModel, updateWithStatus, userTimezone } from '@cohesity/utils';
import { groupBy } from 'lodash';
import { BehaviorSubject, combineLatest, forkJoin, Observable, of } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  startWith,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';

import { coldStorageClasses, FortknoxStorageClass, warmStorageClasses } from '../../constants';
import {
  RpaasActivityStatSummary,
  RpaasTopologyGraphData,
  RpaasTopologyGraphEdge,
  RpaasTopologyGraphNode,
  RpaasTopologyItemStyle,
  VaultUsageByStorageClass,
  VaultUsageData,
} from '../../models/dashboard.models';
import { RpaasDashboardService } from './rpaas-dashboard.service';
import { RpaasStateService } from './rpaas-state.service';

/**
 * We are using the reporting api to get vaulting activity by status. This enum maps the older enum value that the
 * reporting api returns to the newer v2 statuses.
 */
const runStatusEnumMapping = {
  kAccepted: 'Accepted',
  kCanceling: 'Canceling',
  kCanceled: 'Canceled',
  kFailure: 'Failed',
  kMissed: 'Missed',
  kOnHold: 'OnHold',
  kRunning: 'Running',
  kSkipped: 'Skipped',
  kSuccess: 'Succeeded',
  kWarning: 'SucceededWithWarning',
};

@Injectable()
export class RpaasSummaryDashboardService {
  /**
   * Async behavior subject to track the vaulted policies list
   */
  private vaultedPoliciesSubject = new AsyncBehaviorSubject<HeliosPolicyResponse[]>();

  /**
   * Async behavior subject to track the activity list
   */
  private activitySubject = new AsyncBehaviorSubject<RpaasActivityStatSummary[]>();

  /**
   * Async behavior subject to track the external targets
   */
  private externalTargetsSubject = new AsyncBehaviorSubject<ExternalTarget[]>();

  /**
   * Async behavior subject to track the region usage data
   */
  private regionUsageSubject = new AsyncBehaviorSubject<VaultUsageByStorageClass>();

  /**
   * Async behavior subject to track the region usage data
   */
  private envArchivalStatsSubject = new AsyncBehaviorSubject<ObjectEnvironmentArchivalStats[]>();

  /**
   * Behavior subject to track the report parameters
   */
  public reportParamsSubject = new BehaviorSubject<ComponentsServiceApi.GetComponentPreviewParams>(null);

  /**
   * Observable of archival run stats.
   */
  envArchivalStats$ = this.envArchivalStatsSubject.pipe(map(subject => subject.result));

  /**
   * Observable indicating if archival run stats are loading.
   */
  envArchivalStatsLoading$ = this.envArchivalStatsSubject.pipe(map(subject => subject.loading));

  /**
   * This tracks whether the activity api call is currently in progress.
   */
  activityLoading$ = this.activitySubject.pipe(map(activity => activity.loading));

  /**
   * This returns just the activity stats, grouped by type and status. It is used for the activity card
   */
  activityStats$ = this.activitySubject.pipe(map(activity => activity.result));

  /**
   * Observable of external targets.
   */
  private readonly externalTargets$ = this.externalTargetsSubject.pipe(map(subject => subject.result));

  /**
   * Observable indicating if external targets are loading.
   */
  externalTargetsLoading$ = this.externalTargetsSubject.pipe(map(subject => subject.loading));

  /**
   * Rest activities and regions when the filters change
   */
  regionFilter$ = this.dashboardFilterService.regionFilter$.pipe(
    tap(() => {
      this.activitySubject.reset();
      this.regionUsageSubject.reset();
      this.envArchivalStatsSubject.reset();
    }),
    shareReplay(1)
  );

  /**
   * Rest activities and regions when the filters change
   */
  dateRangeFilter$ = this.dashboardFilterService.dateRangeFilter$.pipe(
    tap(() => {
      this.activitySubject.reset();
      this.regionUsageSubject.reset();
      this.envArchivalStatsSubject.reset();
    }),
    shareReplay(1)
  );

  /**
   * Combine the region filter and the vaulted policy list to include only policies that are in our filter.
   */
  filteredPolicies$ = combineLatest([this.vaultedPoliciesSubject, this.regionFilter$]).pipe(
    filter(([apiCall]) => !apiCall.loading && apiCall.success),
    map(([apiCall, regionNames]) => [apiCall.result || [], (regionNames || []).map(region => region.vaultName)]),
    map(([policies, regionNames]: [HeliosPolicyResponse[], string[]]) =>
      policies.filter(
        policy =>
          !regionNames?.length ||
          policy.remoteTargetPolicy.rpaasTargets.find(target => regionNames.includes(target.targetName))
      )
    )
  );

  /**
   * This tracks whether the region usage api call is currently in progress.
   */
  regionUsageLoading$ = this.regionUsageSubject.pipe(map(usage => usage.loading));

  /**
   * This returns just the activity stats, grouped by type and status. It is used for the activity card
   */
  readonly regionUsage$ = this.regionUsageSubject.pipe(map(usage => usage.result));

  /**
   * This tracks whether any of the topology graph api dependencies are currently loading.
   */
  topologyLoading$ = combineLatest([
    this.cyberVaultingState.clustersList$,
    this.cyberVaultingState.rpaasRegions$,
    this.vaultedPoliciesSubject,
    this.activitySubject,
  ]).pipe(map(apiCalls => apiCalls.some(apiCall => apiCall.loading || !apiCall.success)));

  /**
   * This returns the topology data needed to render the dashboard graph
   */
  topologyData$: Observable<RpaasTopologyGraphData> = combineLatest([
    this.topologyLoading$,
    this.cyberVaultingState.clusterMap$,
    this.cyberVaultingState.rpaasRegionMap$,
    this.filteredPolicies$,
    this.activitySubject,
    this.dateRangeFilter$,
    this.regionFilter$,
  ]).pipe(
    filter(([loading]) => !loading),
    map(
      ([, clusterMap, regionMap, filteredPolicies, activities, dateRange, regionFilter]: [
        boolean,
        Map<string, McmClusterConnectionStatus>,
        Map<string, RpaasRegionInfo>,
        HeliosPolicyResponse[],
        AsyncStateModel<RpaasActivityStatSummary[]>,
        [number, number],
        RpaasRegionInfo[]
      ]) => this.getTopologyData(clusterMap, regionMap, filteredPolicies, activities.result, dateRange, regionFilter)
    )
  );

  /**
   * The list of currently registered regions.
   */
  private readonly regionList$ = this.cyberVaultingState.rpaasRegions$.pipe(map(regions => regions.result));

  /**
   * Activity data for cold vaults
   */
  readonly coldVaultsActivity$ = combineLatest([this.activityStats$, this.regionList$]).pipe(
    filter(([activity, regions]) => activity?.length > 0 && regions?.length > 0),
    map(([activity, regions]) => {
      const coldVaults = regions.filter(region =>
        ['AmazonS3Glacier', 'kAmazonS3Glacier', 'AzureArchiveBlob'].includes(region.storageClass));
      const vaultsByName = groupBy(coldVaults, 'vaultName');

      return activity.filter(act => Boolean(vaultsByName[act.regionId]));
    })
  );

  /**
   * Activity data for warm vaults
   */
  readonly warmVaultsActivity$ = combineLatest([this.activityStats$, this.regionList$]).pipe(
    filter(([activity, regions]) => activity?.length > 0 && regions?.length > 0),
    map(([activity, regions]) => {
      const warmVaults = regions.filter(region =>
        ['AmazonS3StandardIA', 'kAmazonS3StandardIA', 'AzureCoolBlob'].includes(region.storageClass));
      const vaultsByName = groupBy(warmVaults, 'vaultName');

      return activity.filter(act => Boolean(vaultsByName[act.regionId]));
    })
  );

  /**
   * Toggle warm storage info based on filters and subscriptions
   */
  readonly showWarmSubscription$: Observable<boolean> = this.regionFilter$.pipe(
    startWith([]),
    map((regions = []) => {
      const { irisContext } = this.irisContext;
      const awsWarmVaultActive = awsWarmStorageEntitlement(irisContext)?.isActive;
      const azureHotActive = azureHotStorageEntitlement(irisContext)?.isActive;

      return (awsWarmVaultActive || azureHotActive) && (
        regions?.length === 0 || regions?.some(region => warmStorageClasses.includes(region.storageClass)));
    }),
    shareReplay(1)
  );

  /**
   * Toggle cold storage info based on filters and subscriptions
   */
  readonly showColdSubscription$: Observable<boolean> = this.regionFilter$.pipe(
    startWith([]),
    map((regions = []) => {
      const { irisContext } = this.irisContext;

      const awsColdVaultActive = awsColdStorageEntitlement(irisContext)?.isActive;
      const azureColdActive = azureColdStorageEntitlement(irisContext)?.isActive;

      return (awsColdVaultActive || azureColdActive) && (
        regions?.length === 0 || regions?.some(region => coldStorageClasses.includes(region.storageClass)));
    }),
    shareReplay(1)
  );

  /**
   * Adjust dashboard row span based on subscription
   */
  readonly cardRowSpan$: Observable<number> = combineLatest([
    this.showWarmSubscription$,
    this.showColdSubscription$
  ]).pipe(
    startWith([]),
    map(([showWarmSubscription, showColdSubscription]) => showColdSubscription && showWarmSubscription ? 2 : 1),
    shareReplay(1),
  );

  constructor(
    private componentsService: ComponentsServiceApi,
    private dashboardFilterService: RpaasDashboardService,
    private filtersService: FiltersServiceApi,
    private cyberVaultingState: RpaasStateService,
    private policyApi: PolicyServiceApi,
    private objectService: ObjectServiceApi,
    private irisContext: IrisContextService,
  ) {}

  /**
   * Fetches the initial set of data needed to render the dashboard
   */
  fetchDashboardData() {
    this.cyberVaultingState.fetchClusterList();

    this.policyApi
      .GetHeliosPolicies({
        types: ['OnPremPolicy'],
      })
      .pipe(
        map(({ policies }) =>
          (policies || []).filter(policy =>
            policy.remoteTargetPolicy?.rpaasTargets?.find(target => target.targetType === 'Cloud')
          )
        ),
        updateWithStatus(this.vaultedPoliciesSubject)
      )
      .subscribe();

    // Activity and usage data rely on the filtered data
    const filtersChanged$ = combineLatest([this.dateRangeFilter$, this.regionFilter$]).pipe(
      distinctUntilChanged(),
      debounceTime(5),
      filter(([dateRange]) => !!dateRange?.[0] && !!dateRange?.[1])
    );

    filtersChanged$
      .pipe(
        switchMap(([dateRange, rpaasRegions]) => this.getAggregatedStatSummary(dateRange, rpaasRegions)),
        updateWithStatus(this.activitySubject)
      )
      .subscribe();

    filtersChanged$
      .pipe(
        switchMap(([dateRange, rpaasRegions]) => this.getUsageDataFromReport(dateRange, rpaasRegions)),
        updateWithStatus(this.regionUsageSubject),

        // This subject sometimes does not  update the loading  status when the api call completes,
        // leaving the UI in a permanent loading state. This code ensures that the subject gets updated
        tap(() =>
          this.regionUsageSubject.next({
            ...this.regionUsageSubject.value,
            loading: false,
          })
        )
      )
      .subscribe();

    filtersChanged$
      .pipe(
        switchMap(([dateRange, rpaasRegions]) =>
          this.objectService.GetObjectArchivalRunStats({
            rpaasGlobalVaultIds: rpaasRegions?.map(region => region.globalVaultId),
            rpaasOnly: true,
            fromTimeUsecs: dateRange?.[0],
            toTimeUsecs: dateRange?.[1],
          })
        ),
        map(resp => resp?.stats || []),

        // Decorate the response and ensure that we have a value for each property as expected.
        tap(stats =>
          stats.forEach(stat => {
            stat.numSuccessfulObjects = stat.numSuccessfulObjects || 0;
            stat.numUnsuccessfulObjects = stat.numUnsuccessfulObjects || 0;
          })
        ),
        updateWithStatus(this.envArchivalStatsSubject),

        // This subject sometimes does not  update the loading  status when the api call completes,
        // leaving the UI in a permanent loading state. This code ensures that the subject gets updated
        tap(() =>
          this.envArchivalStatsSubject.next({
            ...this.envArchivalStatsSubject.value,
            loading: false,
          })
        )
      )
      .subscribe();

    this.fetchExternalTargets();
  }

  /**
   * Parse the activity values and group them by cluster, vault and status
   *
   * @param dateRange The selected date range
   * @param regions The selected region ids to filter on
   * @returns An array of rolled up stat values.
   */
  getAggregatedStatSummary(dateRange: number[], regions: RpaasRegionInfo[]): Observable<RpaasActivityStatSummary[]> {
    const targetNames = regions?.map(region => region.vaultName) || [];

    const params: ComponentsPreviewParams = {
      components: [
        {
          name: 'VaultActivity',
          reportType: 'ProtectionActivity',
          aggs: {
            groupedAttributes: ['status', 'targetName', 'systemId'],
            aggregatedAttributes: [
              {
                aggregationType: 'count',
                attribute: 'runStartTimeUsecs',
              },
            ],
          },
        },
      ],
      filters: [
        getApiFilterParam('date', { lowerBound: dateRange?.[0], upperBound: dateRange?.[1] }),
        getApiFilterParam('activityType', [{ value: 'Vault' }]),
        targetNames?.length &&
          getApiFilterParam(
            'targetName',
            targetNames.map(value => ({ value }))
          ),
      ].filter(value => !!value),
      timezone: userTimezone,
    };

    return this.componentsService.GetComponentsPreview(params).pipe(
      // Convert the results to activity summary values
      map(
        componentData =>
          componentData.components[0].data?.map((row: any) => ({
            clusterIdentifier: row.systemId,
            regionId: row.targetName,
            count: row.countRunStartTimeUsecs,
            status: runStatusEnumMapping[row.status],
          })) || []
      )
    );
  }

  /**
   * Builds the topology graph list from all of the dependant api calls
   *
   * @param   clusters        The cluster connectivity list
   * @param   regions         The list of configured regions
   * @param   dateRange       The date range filiter that was used to look up these results
   * @param   vaultedPolicies Vaulted policies determine what the graph edges are
   * @param   activities      Activity data determines what the node and edge statuses are
   * @param   dateRange       The currently applied date range filter
   * @param   regionsFilter   The currently applied regions filter
   * @returns A list of topology nodes and edges
   */
  private getTopologyData(
    clusterMap: Map<string, McmClusterConnectionStatus>,
    regionMap: Map<string, RpaasRegionInfo>,
    vaultedPolicies: HeliosPolicyResponse[],
    activities: RpaasActivityStatSummary[],
    dateRange: [number, number],
    filteredRegions: RpaasRegionInfo[]
  ): RpaasTopologyGraphData {
    const nodeMap = new Map<string, RpaasTopologyGraphNode>();
    const edgeMap = new Map<string, RpaasTopologyGraphEdge>();

    this.initGraphFromConnectivityData(
      vaultedPolicies,
      clusterMap,
      regionMap,
      dateRange,
      filteredRegions,
      nodeMap,
      edgeMap
    );
    this.updateGraphStylesFromActivity(activities, nodeMap, edgeMap);

    return {
      nodes: [...nodeMap.values()],
      edges: [...edgeMap.values()],
    };
  }

  /**
   * Initialize the graph based entirely on connectivity data. This is derived from the configured policies that we have
   * We can check against the clusterMap to find out if a cluster is connected or not. At the end, we should have a
   * graph with either noActivity or disconnected styles applied to all of the nodes.
   *
   * @param vaultedPolicies All available policies
   * @param clusterMap  A map of cluster info
   * @param regionMap A map of region info
   * @param dateRange The date range filiter that was used to look up these results
   * @param   regionsFilter   The currently applied regions filter
   * @param nodeMap A map of nodes to add data to
   * @param edgeMap A map of edges to add data to
   */
  private initGraphFromConnectivityData(
    vaultedPolicies: HeliosPolicyResponse[],
    clusterMap: Map<string, McmClusterConnectionStatus>,
    regionMap: Map<string, RpaasRegionInfo>,
    dateRange: [number, number],
    regionsFilter: RpaasRegionInfo[],
    nodeMap: Map<string, RpaasTopologyGraphNode>,
    edgeMap: Map<string, RpaasTopologyGraphEdge>
  ) {
    // Always create nodes for each vault. If a vault doesn't have any policies connected to it, it will show
    // in the graph unconnected to everything else.

    [...regionMap.values()]
      .filter(
        vault =>
          !regionsFilter?.length ||
          regionsFilter.find(filteredRegion => filteredRegion.globalVaultId === vault.globalVaultId)
      )
      .forEach(vault =>
        nodeMap.set(vault.vaultName, {
          label: vault.vaultName,
          labelPlacement: 'South',
          id: vault.globalVaultId,
          type: 'vault',
          style: 'noActivity',
          activities: [],
          dateRange,
        })
      );

    vaultedPolicies.forEach(policy => {
      const cluster = clusterMap.get(policy.clusterIdentifier);
      nodeMap.set(policy.clusterIdentifier, {
        label: cluster.name,
        labelPlacement: 'North',
        id: policy.clusterIdentifier,
        type: 'cluster',
        style: cluster.connectedToCluster ? 'noActivity' : 'disconnected',
        activities: [],
        dateRange,
      });

      (policy.remoteTargetPolicy?.rpaasTargets || []).find(({ targetName }) => {
        const vault = regionMap.get(targetName);
        if (vault) {
          const edgeId = `${policy.clusterIdentifier}_${vault.globalVaultId}`;

          edgeMap.set(edgeId, {
            from: policy.clusterIdentifier,
            to: vault.globalVaultId,
            id: edgeId,
            style: cluster.connectedToCluster ? 'noActivity' : 'disconnected',
            activities: [],
          });
        }
      });
    });
  }

  /**
   * Parse activity data and update node and edge styles as necessary. A node or edge's style should be the
   * "most severe" status of the activity for a given period.
   *
   * @param activities A list of rpaas activity
   * @param policyMap A map of policy info
   * @param nodeMap A map of nodes to update styles on
   * @param edgeMap A map of edges to update styles on
   */
  private updateGraphStylesFromActivity(
    activities: RpaasActivityStatSummary[],
    nodeMap: Map<string, RpaasTopologyGraphNode>,
    edgeMap: Map<string, RpaasTopologyGraphEdge>
  ) {
    activities.forEach(activity => {
      const sourceNode = nodeMap.get(activity.clusterIdentifier);
      if (sourceNode) {
        sourceNode.activities.push(activity);
        sourceNode.style = this.getUpdatedStyle(sourceNode.style, activity.status);
      }

      const targetNode = nodeMap.get(activity.regionId);
      if (targetNode) {
        // We do not change the vault style, we only want to show errors in the clusters and edges
        targetNode.activities.push(activity);
      }

      if (sourceNode && targetNode) {
        const edge = edgeMap.get(`${sourceNode.id}_${targetNode.id}`);
        if (edge) {
          edge.activities.push(activity);
          edge.style = this.getUpdatedStyle(edge.style, activity.status);
        }
      }
    });
  }

  /**
   * Given a current style, and style derived from activity, determine which one takes precedence
   *
   * @param currentStyle The style currently applied to the node or edge
   * @param activityStatus The style from the activvity
   * @returns The style to apply
   */
  private getUpdatedStyle(
    currentStyle: RpaasTopologyItemStyle,
    activityStatus: RpaasActivityStatSummary['status']
  ): RpaasTopologyItemStyle {
    const statusMap: Record<RpaasActivityStatSummary['status'], RpaasTopologyItemStyle> = {
      Failed: 'error',
      Missed: 'error',
      Accepted: 'inProgress',
      Canceling: 'inProgress',
      Finalizing: 'inProgress',
      OnHold: 'inProgress',
      Running: 'inProgress',
      Skipped: 'warning',
      Succeeded: 'success',
      Canceled: 'success',
      SucceededWithWarning: 'warning',
    };

    const styleValue: Record<RpaasTopologyItemStyle, number> = {
      disconnected: 0,
      error: 1,
      warning: 2,
      success: 3,
      inProgress: 4,
      noActivity: 5,
    };

    const newStyle = statusMap[activityStatus];
    return styleValue[currentStyle] < styleValue[newStyle] ? currentStyle : newStyle;
  }

  /**
   * Fetch external targets from report id.
   */
  private fetchExternalTargets() {
    this.filtersService
      .GetResources({ resourceType: 'ExternalTargets' })
      .pipe(
        map(resources => resources?.externalTargets || []),
        updateWithStatus(this.externalTargetsSubject)
      )
      .subscribe();
  }

  /**
   * A wrapper function to get total storage consumed in bytes from report api but mainly
   * handling the mapping from regionIds to targetIds which requires extra API call.
   *
   * @param dateRange  Date range.
   * @param filteredRegions  List of regions.
   * @returns  storage consumed in bytes.
   */
  getUsageDataFromReport(
    dateRange: number[],
    filteredRegions: RpaasRegionInfo[]
  ): Observable<VaultUsageByStorageClass> {
    const regions$ =
      filteredRegions?.length > 0
        ? of(filteredRegions)
        : this.regionList$.pipe(
          filter(regions => regions?.length > 0),
          take(1)
        );

    const externalTargets$ = this.externalTargets$.pipe(
      filter(targets => targets?.length > 0),
      take(1)
    );

    return combineLatest([regions$, externalTargets$]).pipe(
      filter(([regions, externalTargets]) => regions?.length > 0 && externalTargets?.length > 0),
      take(1),
      map(([regions, externalTargets]) => {
        const vaultNames = regions.map(region => region.vaultName);
        const targets = externalTargets.filter(t => vaultNames.includes(t.name));
        const targetsByName = groupBy(targets, 'name');
        const vaultsByStorageClass = groupBy(regions, 'storageClass');
        const targetsByStorageClass: { [storageClass in FortknoxStorageClass]?: ExternalTarget[] } = {};
        regions.forEach(region => {
          const { storageClass } = region;

          vaultsByStorageClass[storageClass]
            .filter(vault => Boolean(targetsByName[vault.vaultName]))
            .forEach(vault => {
              if (!targetsByStorageClass[storageClass]) {
                targetsByStorageClass[storageClass] = [];
              }

              targetsByStorageClass[storageClass].push(...targetsByName[vault.vaultName]);
            });
        });

        return targetsByStorageClass;
      }),
      switchMap(targetsByStorageClass => {
        const storageClasses = Object.keys(targetsByStorageClass);

        if (storageClasses?.length > 0) {
          const req = {};

          storageClasses.forEach(
            storageClass =>
              (req[storageClass] = this.getReportComponentData(
                this.getReportParams(dateRange, targetsByStorageClass[storageClass])
              ))
          );

          return forkJoin(req);
        }

        return of(null);
      })
    );
  }

  /**
   * Gets report API call parameters from filters.
   *
   * @param dateRange    Date range.
   * @param targets    Report filter targets which is region.
   * @returns  Report API call parameters.
   */
  getReportParams(dateRange: number[], targets: ExternalTarget[]): ComponentsServiceApi.GetComponentPreviewParams {
    const params = getApiParams(null, {
      targetId: targets.map(target => ({
        label: `${target.name} (${target.systemName})`,
        value: target.id,
      })),
      managedBy: [
        {
          label: 'FortKnox',
          value: 'FortKnox',
        },
      ],
      date: {
        lowerBound: dateRange?.[0],
        upperBound: dateRange?.[1],
      },
    });
    this.reportParamsSubject.next(params);
    return params;
  }

  /**
   * Gets the total storage consumed in bytes and trend chart data from report api, and
   * map to usageData.
   *
   * @param    params  Parameters based on filter used to make API call.
   * @returns  total storage consumed in bytes.
   */
  getReportComponentData(params: ComponentsServiceApi.GetComponentPreviewParams): Observable<VaultUsageData> {
    return forkJoin([
      this.componentsService
        .GetComponentPreview({
          ...params,
          id: ReportComponentName.externalTargetSummary,
        })
        .pipe(map(result => (result.component?.data?.[0] as any)?.sumScRetainedBytes || 0)),
      this.componentsService
        .GetComponentPreview({
          ...params,
          id: ReportComponentName.externalTargetTrend,
        })
        .pipe(
          // Map report trend chart data to usage data.
          map(response => {
            const data = response.component?.data;

            if (!data?.length) {
              return [];
            }

            const result: Record<number, number> = {};
            data.forEach((item: any) => {
              if (!result[item.timestampUsecs]) {
                result[item.timestampUsecs] = 0;
              }
              result[item.timestampUsecs] += item.sumScRetainedBytes;
            });
            return Object.keys(result)
              .sort()
              .map(key => ({
                timestampUsecs: +key,
                usageBytes: +result[key],
              }));
          })
        ),
    ]).pipe(
      map(([totalUsage, usageData]) => {
        const { 0: start, [usageData.length - 1]: end } = usageData;
        const changePercent =
          start?.usageBytes && end?.usageBytes ? (end.usageBytes - start.usageBytes) / start.usageBytes : 0;

        return {
          totalUsage,
          usageData,
          changePercent,
        } as VaultUsageData;
      })
    );
  }
}
