/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityV2ApiConfiguration as __Configuration } from '../cohesity-v2api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ActiveDirectories } from '../models/active-directories';
import { ActiveDirectory } from '../models/active-directory';
import { CreateActiveDirectoryRequest } from '../models/create-active-directory-request';
import { UpdateActiveDirectoryRequest } from '../models/update-active-directory-request';
import { CentrifyZones } from '../models/centrify-zones';
import { DomainControllersResponse } from '../models/domain-controllers-response';
import { McmActiveDirectoryList } from '../models/mcm-active-directory-list';
import { McmActiveDirectory } from '../models/mcm-active-directory';
import { CreateOrUpdateActiveDirectoryRequest } from '../models/create-or-update-active-directory-request';
import { ActiveDirectoryPrincipalList } from '../models/active-directory-principal-list';
import { SearchActiveDirectoryPrincipalsParams } from '../models/search-active-directory-principals-params';
import { TrustedDomainsPerDomainNameList } from '../models/trusted-domains-per-domain-name-list';
import { GetTrustedDomainsRequest } from '../models/get-trusted-domains-request';
import { TrustedDomainsList } from '../models/trusted-domains-list';
import { DeletedActiveDirectoryResponse } from '../models/deleted-active-directory-response';
import { DeleteMcmActiveDirectoryParams } from '../models/delete-mcm-active-directory-params';
import { TrustedDomainParams } from '../models/trusted-domain-params';
@Injectable({
  providedIn: 'root',
})
class ActiveDirectoryService extends __BaseService {
  static readonly GetActiveDirectoryPath = '/active-directories';
  static readonly CreateActiveDirectoryPath = '/active-directories';
  static readonly GetActiveDirectoryByIdPath = '/active-directories/{id}';
  static readonly UpdateActiveDirectoryPath = '/active-directories/{id}';
  static readonly DeleteActiveDirectoryPath = '/active-directories/{id}';
  static readonly GetCentrifyZonesPath = '/centrify-zones';
  static readonly GetDomainControllersPath = '/domain-controllers';
  static readonly GetHeliosActiveDirectoryPath = '/mcm/active-directories';
  static readonly CreateHeliosActiveDirectoryPath = '/mcm/active-directories';
  static readonly GetDomainControllersMixin1Path = '/mcm/active-directories/domain-controllers';
  static readonly SearchActiveDirectoryPrincipalsPath = '/mcm/active-directories/principals/search';
  static readonly GetTrustedDomainListPath = '/mcm/active-directories/trusted-domains';
  static readonly RefreshTrustedDomainsPath = '/mcm/active-directories/trusted-domains/refresh';
  static readonly UpdateHeliosActiveDirectoryPath = '/mcm/active-directories/{domainName}';
  static readonly DeleteHeliosActiveDirectoryPath = '/mcm/active-directories/{domainName}';
  static readonly GetTrustedDomainsPath = '/trusted-domains';
  static readonly TriggerTrustedDomainsDiscoveryPath = '/trusted-domains';
  static readonly UpdateTrustedDomainsPath = '/trusted-domains';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get the list of Active Directories.
   *
   * Get the list of Active Directories.
   * @param params The `ActiveDirectoryService.GetActiveDirectoryParams` containing the following parameters:
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which Active Directories are to be returned.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `includeTenants`: If true, the response will include Active Directories which were created by all tenants which the current user has permission to see. If false, then only Active Directories created by the current user will be returned.
   *
   * - `ids`: Filter by a list of Active Directory Ids.
   *
   * - `domainNames`: Filter by a list of Active Directory domain names.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetActiveDirectoryResponse(params: ActiveDirectoryService.GetActiveDirectoryParams): __Observable<__StrictHttpResponse<ActiveDirectories>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.tenantIds || []).forEach(val => {if (val != null) __params = __params.append('tenantIds', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.includeTenants != null) __params = __params.set('includeTenants', params.includeTenants.toString());
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    (params.domainNames || []).forEach(val => {if (val != null) __params = __params.append('domainNames', val.toString())});
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/active-directories`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ActiveDirectories>;
      })
    );
  }
  /**
   * Get the list of Active Directories.
   *
   * Get the list of Active Directories.
   * @param params The `ActiveDirectoryService.GetActiveDirectoryParams` containing the following parameters:
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which Active Directories are to be returned.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `includeTenants`: If true, the response will include Active Directories which were created by all tenants which the current user has permission to see. If false, then only Active Directories created by the current user will be returned.
   *
   * - `ids`: Filter by a list of Active Directory Ids.
   *
   * - `domainNames`: Filter by a list of Active Directory domain names.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetActiveDirectory(params: ActiveDirectoryService.GetActiveDirectoryParams): __Observable<ActiveDirectories> {
    return this.GetActiveDirectoryResponse(params).pipe(
      __map(_r => _r.body as ActiveDirectories)
    );
  }

  /**
   * Create an Active Directory.
   *
   * Create an Active Directory.
   * @param params The `ActiveDirectoryService.CreateActiveDirectoryParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to create an Active Directory.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateActiveDirectoryResponse(params: ActiveDirectoryService.CreateActiveDirectoryParams): __Observable<__StrictHttpResponse<ActiveDirectory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/active-directories`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ActiveDirectory>;
      })
    );
  }
  /**
   * Create an Active Directory.
   *
   * Create an Active Directory.
   * @param params The `ActiveDirectoryService.CreateActiveDirectoryParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to create an Active Directory.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateActiveDirectory(params: ActiveDirectoryService.CreateActiveDirectoryParams): __Observable<ActiveDirectory> {
    return this.CreateActiveDirectoryResponse(params).pipe(
      __map(_r => _r.body as ActiveDirectory)
    );
  }

  /**
   * Get an Active Directory by id.
   *
   * Get an Active Directory by id.
   * @param params The `ActiveDirectoryService.GetActiveDirectoryByIdParams` containing the following parameters:
   *
   * - `id`: Specifies id of an Active Directory.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `prefix`: Specifies a prefix, only security principals with name or sAMAccountName having this prefix (ignoring cases) will be returned. This field is appliciable and mandatory if 'includeSecurityPrincipals' is set to true.
   *
   * - `objectClass`: Specifies a list of object classes, only security principals with object class in this list will be returned. This field is appliciable if 'includeSecurityPrincipals' is set to true.
   *
   * - `includeSecurityPrincipals`: Specifies whether to include Security Principals of the Active Directory in response.
   *
   * - `includeDomainControllers`: Specifies whether to include Domain Controllers of the Active Directory in response.
   *
   * - `includeCentrifyZones`: Specifies whether to include Centrify Zones of the Active Directory in response.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetActiveDirectoryByIdResponse(params: ActiveDirectoryService.GetActiveDirectoryByIdParams): __Observable<__StrictHttpResponse<ActiveDirectory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.prefix != null) __params = __params.set('prefix', params.prefix.toString());
    (params.objectClass || []).forEach(val => {if (val != null) __params = __params.append('objectClass', val.toString())});
    if (params.includeSecurityPrincipals != null) __params = __params.set('includeSecurityPrincipals', params.includeSecurityPrincipals.toString());
    if (params.includeDomainControllers != null) __params = __params.set('includeDomainControllers', params.includeDomainControllers.toString());
    if (params.includeCentrifyZones != null) __params = __params.set('includeCentrifyZones', params.includeCentrifyZones.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/active-directories/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ActiveDirectory>;
      })
    );
  }
  /**
   * Get an Active Directory by id.
   *
   * Get an Active Directory by id.
   * @param params The `ActiveDirectoryService.GetActiveDirectoryByIdParams` containing the following parameters:
   *
   * - `id`: Specifies id of an Active Directory.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `prefix`: Specifies a prefix, only security principals with name or sAMAccountName having this prefix (ignoring cases) will be returned. This field is appliciable and mandatory if 'includeSecurityPrincipals' is set to true.
   *
   * - `objectClass`: Specifies a list of object classes, only security principals with object class in this list will be returned. This field is appliciable if 'includeSecurityPrincipals' is set to true.
   *
   * - `includeSecurityPrincipals`: Specifies whether to include Security Principals of the Active Directory in response.
   *
   * - `includeDomainControllers`: Specifies whether to include Domain Controllers of the Active Directory in response.
   *
   * - `includeCentrifyZones`: Specifies whether to include Centrify Zones of the Active Directory in response.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetActiveDirectoryById(params: ActiveDirectoryService.GetActiveDirectoryByIdParams): __Observable<ActiveDirectory> {
    return this.GetActiveDirectoryByIdResponse(params).pipe(
      __map(_r => _r.body as ActiveDirectory)
    );
  }

  /**
   * Update an Active Directory.
   *
   * Update an Active Directory.
   * @param params The `ActiveDirectoryService.UpdateActiveDirectoryParams` containing the following parameters:
   *
   * - `id`: Specifies id of an Active Directory.
   *
   * - `body`: Request to update an Active Directory.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateActiveDirectoryResponse(params: ActiveDirectoryService.UpdateActiveDirectoryParams): __Observable<__StrictHttpResponse<ActiveDirectory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/active-directories/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ActiveDirectory>;
      })
    );
  }
  /**
   * Update an Active Directory.
   *
   * Update an Active Directory.
   * @param params The `ActiveDirectoryService.UpdateActiveDirectoryParams` containing the following parameters:
   *
   * - `id`: Specifies id of an Active Directory.
   *
   * - `body`: Request to update an Active Directory.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateActiveDirectory(params: ActiveDirectoryService.UpdateActiveDirectoryParams): __Observable<ActiveDirectory> {
    return this.UpdateActiveDirectoryResponse(params).pipe(
      __map(_r => _r.body as ActiveDirectory)
    );
  }

  /**
   * Delete an Active Directory.
   *
   * Delete an Active Directory.
   * @param params The `ActiveDirectoryService.DeleteActiveDirectoryParams` containing the following parameters:
   *
   * - `id`: Specifies id of an Active Directory.
   *
   * - `activeDirectoryAdminUsername`: Specifies the username of the Active Directory Admin.
   *
   * - `activeDirectoryAdminPassword`: Specifies the password of the Active Directory Admin.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `forceRemove`: To force delete the Active directory from cluster. This will skip all the checks that prevents cluster from leaving an AD domain.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteActiveDirectoryResponse(params: ActiveDirectoryService.DeleteActiveDirectoryParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.activeDirectoryAdminUsername != null) __headers = __headers.set('activeDirectoryAdminUsername', params.activeDirectoryAdminUsername.toString());
    if (params.activeDirectoryAdminPassword != null) __headers = __headers.set('activeDirectoryAdminPassword', params.activeDirectoryAdminPassword.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.forceRemove != null) __params = __params.set('forceRemove', params.forceRemove.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/active-directories/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete an Active Directory.
   *
   * Delete an Active Directory.
   * @param params The `ActiveDirectoryService.DeleteActiveDirectoryParams` containing the following parameters:
   *
   * - `id`: Specifies id of an Active Directory.
   *
   * - `activeDirectoryAdminUsername`: Specifies the username of the Active Directory Admin.
   *
   * - `activeDirectoryAdminPassword`: Specifies the password of the Active Directory Admin.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `forceRemove`: To force delete the Active directory from cluster. This will skip all the checks that prevents cluster from leaving an AD domain.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteActiveDirectory(params: ActiveDirectoryService.DeleteActiveDirectoryParams): __Observable<null> {
    return this.DeleteActiveDirectoryResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get Centrify Zones.
   *
   * Get Centrify zones for a specified domain.
   * @param params The `ActiveDirectoryService.GetCentrifyZonesParams` containing the following parameters:
   *
   * - `domainName`: Specifies the FQDN of the domain name.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetCentrifyZonesResponse(params: ActiveDirectoryService.GetCentrifyZonesParams): __Observable<__StrictHttpResponse<CentrifyZones>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.domainName != null) __params = __params.set('domainName', params.domainName.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/centrify-zones`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CentrifyZones>;
      })
    );
  }
  /**
   * Get Centrify Zones.
   *
   * Get Centrify zones for a specified domain.
   * @param params The `ActiveDirectoryService.GetCentrifyZonesParams` containing the following parameters:
   *
   * - `domainName`: Specifies the FQDN of the domain name.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetCentrifyZones(params: ActiveDirectoryService.GetCentrifyZonesParams): __Observable<CentrifyZones> {
    return this.GetCentrifyZonesResponse(params).pipe(
      __map(_r => _r.body as CentrifyZones)
    );
  }

  /**
   * Get Domain Controllers of specified domains.
   *
   * Get Domain Controllers of specified domains.
   * @param params The `ActiveDirectoryService.GetDomainControllersParams` containing the following parameters:
   *
   * - `domainNames`: Specifies a list of domain names.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `connectionId`: Specifies the Id of the connection which the connector belongs to.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetDomainControllersResponse(params: ActiveDirectoryService.GetDomainControllersParams): __Observable<__StrictHttpResponse<DomainControllersResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.domainNames || []).forEach(val => {if (val != null) __params = __params.append('domainNames', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.connectionId != null) __params = __params.set('connectionId', params.connectionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/domain-controllers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DomainControllersResponse>;
      })
    );
  }
  /**
   * Get Domain Controllers of specified domains.
   *
   * Get Domain Controllers of specified domains.
   * @param params The `ActiveDirectoryService.GetDomainControllersParams` containing the following parameters:
   *
   * - `domainNames`: Specifies a list of domain names.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `connectionId`: Specifies the Id of the connection which the connector belongs to.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetDomainControllers(params: ActiveDirectoryService.GetDomainControllersParams): __Observable<DomainControllersResponse> {
    return this.GetDomainControllersResponse(params).pipe(
      __map(_r => _r.body as DomainControllersResponse)
    );
  }

  /**
   * Get the list of Active Directories.
   *
   * Get the list of Active Directories for MCM. Note: This api is only supported in Multi-Cluster Manager (MCM).
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param domainNames Filter by a list of Active Directory domain names.
   * @return Success
   */
  GetHeliosActiveDirectoryResponse(regionId?: string,
    domainNames?: Array<string>): __Observable<__StrictHttpResponse<McmActiveDirectoryList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    (domainNames || []).forEach(val => {if (val != null) __params = __params.append('domainNames', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/mcm/active-directories`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<McmActiveDirectoryList>;
      })
    );
  }
  /**
   * Get the list of Active Directories.
   *
   * Get the list of Active Directories for MCM. Note: This api is only supported in Multi-Cluster Manager (MCM).
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param domainNames Filter by a list of Active Directory domain names.
   * @return Success
   */
  GetHeliosActiveDirectory(regionId?: string,
    domainNames?: Array<string>): __Observable<McmActiveDirectoryList> {
    return this.GetHeliosActiveDirectoryResponse(regionId, domainNames).pipe(
      __map(_r => _r.body as McmActiveDirectoryList)
    );
  }

  /**
   * Register an Active Directory.
   *
   * Register an Active Directory in MCM. Note: This api is only supported in Multi-Cluster Manager (MCM).
   * @param body Specifies the parameters to create an Active Directory.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  CreateHeliosActiveDirectoryResponse(body: CreateOrUpdateActiveDirectoryRequest,
    regionId?: string): __Observable<__StrictHttpResponse<McmActiveDirectory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/mcm/active-directories`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<McmActiveDirectory>;
      })
    );
  }
  /**
   * Register an Active Directory.
   *
   * Register an Active Directory in MCM. Note: This api is only supported in Multi-Cluster Manager (MCM).
   * @param body Specifies the parameters to create an Active Directory.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  CreateHeliosActiveDirectory(body: CreateOrUpdateActiveDirectoryRequest,
    regionId?: string): __Observable<McmActiveDirectory> {
    return this.CreateHeliosActiveDirectoryResponse(body, regionId).pipe(
      __map(_r => _r.body as McmActiveDirectory)
    );
  }

  /**
   * Get Domain Controllers of specified domains.
   *
   * Get Domain Controllers of specified domains. Note: This api is only supported in Multi-Cluster Manager (MCM).
   * @param params The `ActiveDirectoryService.GetDomainControllersMixin1Params` containing the following parameters:
   *
   * - `domainNames`: Specifies a list of domain names.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `connectionId`: Specifies the Id of the connection which the connector belongs to.
   *
   * @return Success
   */
  GetDomainControllersMixin1Response(params: ActiveDirectoryService.GetDomainControllersMixin1Params): __Observable<__StrictHttpResponse<DomainControllersResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.domainNames || []).forEach(val => {if (val != null) __params = __params.append('domainNames', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.connectionId != null) __params = __params.set('connectionId', params.connectionId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/mcm/active-directories/domain-controllers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DomainControllersResponse>;
      })
    );
  }
  /**
   * Get Domain Controllers of specified domains.
   *
   * Get Domain Controllers of specified domains. Note: This api is only supported in Multi-Cluster Manager (MCM).
   * @param params The `ActiveDirectoryService.GetDomainControllersMixin1Params` containing the following parameters:
   *
   * - `domainNames`: Specifies a list of domain names.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `connectionId`: Specifies the Id of the connection which the connector belongs to.
   *
   * @return Success
   */
  GetDomainControllersMixin1(params: ActiveDirectoryService.GetDomainControllersMixin1Params): __Observable<DomainControllersResponse> {
    return this.GetDomainControllersMixin1Response(params).pipe(
      __map(_r => _r.body as DomainControllersResponse)
    );
  }

  /**
   * Search AD Principals by prefix
   *
   * Searches for the principals with the passed search key. Note: This api is only supported in Multi-Cluster Manager (MCM).
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param body Request body params to create a principal
   * @return Success
   */
  SearchActiveDirectoryPrincipalsResponse(regionId?: string,
    body?: SearchActiveDirectoryPrincipalsParams): __Observable<__StrictHttpResponse<ActiveDirectoryPrincipalList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/mcm/active-directories/principals/search`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ActiveDirectoryPrincipalList>;
      })
    );
  }
  /**
   * Search AD Principals by prefix
   *
   * Searches for the principals with the passed search key. Note: This api is only supported in Multi-Cluster Manager (MCM).
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param body Request body params to create a principal
   * @return Success
   */
  SearchActiveDirectoryPrincipals(regionId?: string,
    body?: SearchActiveDirectoryPrincipalsParams): __Observable<ActiveDirectoryPrincipalList> {
    return this.SearchActiveDirectoryPrincipalsResponse(regionId, body).pipe(
      __map(_r => _r.body as ActiveDirectoryPrincipalList)
    );
  }

  /**
   * Get trusted domains of specified domains.
   *
   * Get trusted domains of specified domains. Note: This api is only supported in Multi-Cluster Manager (MCM).
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param body Specifies a list of domain names with their machine name.
   * @return Success
   */
  GetTrustedDomainListResponse(regionId?: string,
    body?: GetTrustedDomainsRequest): __Observable<__StrictHttpResponse<TrustedDomainsPerDomainNameList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/mcm/active-directories/trusted-domains`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TrustedDomainsPerDomainNameList>;
      })
    );
  }
  /**
   * Get trusted domains of specified domains.
   *
   * Get trusted domains of specified domains. Note: This api is only supported in Multi-Cluster Manager (MCM).
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param body Specifies a list of domain names with their machine name.
   * @return Success
   */
  GetTrustedDomainList(regionId?: string,
    body?: GetTrustedDomainsRequest): __Observable<TrustedDomainsPerDomainNameList> {
    return this.GetTrustedDomainListResponse(regionId, body).pipe(
      __map(_r => _r.body as TrustedDomainsPerDomainNameList)
    );
  }

  /**
   * Refresh trusted domains of AD config.
   *
   * Refresh trusted domains of AD config. Note: This api is only supported in Multi-Cluster Manager (MCM).
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param domainName Specifies the domain name of AD config
   * @return Success
   */
  RefreshTrustedDomainsResponse(regionId?: string,
    domainName?: null | string): __Observable<__StrictHttpResponse<TrustedDomainsList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (domainName != null) __params = __params.set('domainName', domainName.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/mcm/active-directories/trusted-domains/refresh`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TrustedDomainsList>;
      })
    );
  }
  /**
   * Refresh trusted domains of AD config.
   *
   * Refresh trusted domains of AD config. Note: This api is only supported in Multi-Cluster Manager (MCM).
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param domainName Specifies the domain name of AD config
   * @return Success
   */
  RefreshTrustedDomains(regionId?: string,
    domainName?: null | string): __Observable<TrustedDomainsList> {
    return this.RefreshTrustedDomainsResponse(regionId, domainName).pipe(
      __map(_r => _r.body as TrustedDomainsList)
    );
  }

  /**
   * Update an Active Directory.
   *
   * Update an Active Directory. Note: This api is only supported in Multi-Cluster Manager (MCM).
   * @param params The `ActiveDirectoryService.UpdateHeliosActiveDirectoryParams` containing the following parameters:
   *
   * - `domainName`: Domain name of the active directory to update.
   *
   * - `body`: Specifies the parameters to update an Active Directory.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * @return Success
   */
  UpdateHeliosActiveDirectoryResponse(params: ActiveDirectoryService.UpdateHeliosActiveDirectoryParams): __Observable<__StrictHttpResponse<McmActiveDirectory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/mcm/active-directories/${encodeURIComponent(params.domainName)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<McmActiveDirectory>;
      })
    );
  }
  /**
   * Update an Active Directory.
   *
   * Update an Active Directory. Note: This api is only supported in Multi-Cluster Manager (MCM).
   * @param params The `ActiveDirectoryService.UpdateHeliosActiveDirectoryParams` containing the following parameters:
   *
   * - `domainName`: Domain name of the active directory to update.
   *
   * - `body`: Specifies the parameters to update an Active Directory.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * @return Success
   */
  UpdateHeliosActiveDirectory(params: ActiveDirectoryService.UpdateHeliosActiveDirectoryParams): __Observable<McmActiveDirectory> {
    return this.UpdateHeliosActiveDirectoryResponse(params).pipe(
      __map(_r => _r.body as McmActiveDirectory)
    );
  }

  /**
   * Unregister an Active Directory.
   *
   * Unregister an Active Directory. Note: This api is only supported in Multi-Cluster Manager (MCM).
   * @param params The `ActiveDirectoryService.DeleteHeliosActiveDirectoryParams` containing the following parameters:
   *
   * - `domainName`: Domain name of the active directory to delete.
   *
   * - `body`: Specifies the parameters to delete an Active Directory.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * @return Success
   */
  DeleteHeliosActiveDirectoryResponse(params: ActiveDirectoryService.DeleteHeliosActiveDirectoryParams): __Observable<__StrictHttpResponse<DeletedActiveDirectoryResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/mcm/active-directories/${encodeURIComponent(params.domainName)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DeletedActiveDirectoryResponse>;
      })
    );
  }
  /**
   * Unregister an Active Directory.
   *
   * Unregister an Active Directory. Note: This api is only supported in Multi-Cluster Manager (MCM).
   * @param params The `ActiveDirectoryService.DeleteHeliosActiveDirectoryParams` containing the following parameters:
   *
   * - `domainName`: Domain name of the active directory to delete.
   *
   * - `body`: Specifies the parameters to delete an Active Directory.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * @return Success
   */
  DeleteHeliosActiveDirectory(params: ActiveDirectoryService.DeleteHeliosActiveDirectoryParams): __Observable<DeletedActiveDirectoryResponse> {
    return this.DeleteHeliosActiveDirectoryResponse(params).pipe(
      __map(_r => _r.body as DeletedActiveDirectoryResponse)
    );
  }

  /**
   * Get Trusted Domains.
   *
   * Get Trusted Domains for a specified domain.
   * @param params The `ActiveDirectoryService.GetTrustedDomainsParams` containing the following parameters:
   *
   * - `domainName`: Specifies the FQDN of an Active directory domain.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetTrustedDomainsResponse(params: ActiveDirectoryService.GetTrustedDomainsParams): __Observable<__StrictHttpResponse<TrustedDomainParams>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.domainName != null) __params = __params.set('domainName', params.domainName.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/trusted-domains`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TrustedDomainParams>;
      })
    );
  }
  /**
   * Get Trusted Domains.
   *
   * Get Trusted Domains for a specified domain.
   * @param params The `ActiveDirectoryService.GetTrustedDomainsParams` containing the following parameters:
   *
   * - `domainName`: Specifies the FQDN of an Active directory domain.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetTrustedDomains(params: ActiveDirectoryService.GetTrustedDomainsParams): __Observable<TrustedDomainParams> {
    return this.GetTrustedDomainsResponse(params).pipe(
      __map(_r => _r.body as TrustedDomainParams)
    );
  }

  /**
   * Rediscover trusted domains.
   *
   * Re-trigger the trusted domains of an Active Directory.
   * @param params The `ActiveDirectoryService.TriggerTrustedDomainsDiscoveryParams` containing the following parameters:
   *
   * - `rediscover`: Specifies if trusted domains should be rediscovered.
   *
   * - `domainName`: Specifies the FQDN of an Active directory domain.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  TriggerTrustedDomainsDiscoveryResponse(params: ActiveDirectoryService.TriggerTrustedDomainsDiscoveryParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.rediscover != null) __params = __params.set('rediscover', params.rediscover.toString());
    if (params.domainName != null) __params = __params.set('domainName', params.domainName.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/trusted-domains`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Rediscover trusted domains.
   *
   * Re-trigger the trusted domains of an Active Directory.
   * @param params The `ActiveDirectoryService.TriggerTrustedDomainsDiscoveryParams` containing the following parameters:
   *
   * - `rediscover`: Specifies if trusted domains should be rediscovered.
   *
   * - `domainName`: Specifies the FQDN of an Active directory domain.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  TriggerTrustedDomainsDiscovery(params: ActiveDirectoryService.TriggerTrustedDomainsDiscoveryParams): __Observable<null> {
    return this.TriggerTrustedDomainsDiscoveryResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Update trusted domains.
   *
   * To update trusted domains of an Active Directory.
   * @param params The `ActiveDirectoryService.UpdateTrustedDomainsParams` containing the following parameters:
   *
   * - `domainName`: Specifies the FQDN of an Active directory domain.
   *
   * - `body`: Specifies the trusted domains params.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateTrustedDomainsResponse(params: ActiveDirectoryService.UpdateTrustedDomainsParams): __Observable<__StrictHttpResponse<TrustedDomainParams>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.domainName != null) __params = __params.set('domainName', params.domainName.toString());
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/trusted-domains`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TrustedDomainParams>;
      })
    );
  }
  /**
   * Update trusted domains.
   *
   * To update trusted domains of an Active Directory.
   * @param params The `ActiveDirectoryService.UpdateTrustedDomainsParams` containing the following parameters:
   *
   * - `domainName`: Specifies the FQDN of an Active directory domain.
   *
   * - `body`: Specifies the trusted domains params.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateTrustedDomains(params: ActiveDirectoryService.UpdateTrustedDomainsParams): __Observable<TrustedDomainParams> {
    return this.UpdateTrustedDomainsResponse(params).pipe(
      __map(_r => _r.body as TrustedDomainParams)
    );
  }
}

module ActiveDirectoryService {

  /**
   * Parameters for GetActiveDirectory
   */
  export interface GetActiveDirectoryParams {

    /**
     * TenantIds contains ids of the tenants for which Active Directories are to be returned.
     */
    tenantIds?: Array<string>;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * If true, the response will include Active Directories which were created by all tenants which the current user has permission to see. If false, then only Active Directories created by the current user will be returned.
     */
    includeTenants?: boolean;

    /**
     * Filter by a list of Active Directory Ids.
     */
    ids?: Array<number>;

    /**
     * Filter by a list of Active Directory domain names.
     */
    domainNames?: Array<string>;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CreateActiveDirectory
   */
  export interface CreateActiveDirectoryParams {

    /**
     * Specifies the parameters to create an Active Directory.
     */
    body: CreateActiveDirectoryRequest;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetActiveDirectoryById
   */
  export interface GetActiveDirectoryByIdParams {

    /**
     * Specifies id of an Active Directory.
     */
    id: number;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Specifies a prefix, only security principals with name or sAMAccountName having this prefix (ignoring cases) will be returned. This field is appliciable and mandatory if 'includeSecurityPrincipals' is set to true.
     */
    prefix?: string;

    /**
     * Specifies a list of object classes, only security principals with object class in this list will be returned. This field is appliciable if 'includeSecurityPrincipals' is set to true.
     */
    objectClass?: Array<'User' | 'Group' | 'Computer' | 'WellKnownPrincipal'>;

    /**
     * Specifies whether to include Security Principals of the Active Directory in response.
     */
    includeSecurityPrincipals?: boolean;

    /**
     * Specifies whether to include Domain Controllers of the Active Directory in response.
     */
    includeDomainControllers?: boolean;

    /**
     * Specifies whether to include Centrify Zones of the Active Directory in response.
     */
    includeCentrifyZones?: boolean;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateActiveDirectory
   */
  export interface UpdateActiveDirectoryParams {

    /**
     * Specifies id of an Active Directory.
     */
    id: number;

    /**
     * Request to update an Active Directory.
     */
    body: UpdateActiveDirectoryRequest;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for DeleteActiveDirectory
   */
  export interface DeleteActiveDirectoryParams {

    /**
     * Specifies id of an Active Directory.
     */
    id: number;

    /**
     * Specifies the username of the Active Directory Admin.
     */
    activeDirectoryAdminUsername: string;

    /**
     * Specifies the password of the Active Directory Admin.
     */
    activeDirectoryAdminPassword: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * To force delete the Active directory from cluster. This will skip all the checks that prevents cluster from leaving an AD domain.
     */
    forceRemove?: boolean;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetCentrifyZones
   */
  export interface GetCentrifyZonesParams {

    /**
     * Specifies the FQDN of the domain name.
     */
    domainName: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetDomainControllers
   */
  export interface GetDomainControllersParams {

    /**
     * Specifies a list of domain names.
     */
    domainNames: Array<string>;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Specifies the Id of the connection which the connector belongs to.
     */
    connectionId?: null | number;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetDomainControllersMixin1
   */
  export interface GetDomainControllersMixin1Params {

    /**
     * Specifies a list of domain names.
     */
    domainNames: Array<string>;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Specifies the Id of the connection which the connector belongs to.
     */
    connectionId?: null | number;
  }

  /**
   * Parameters for UpdateHeliosActiveDirectory
   */
  export interface UpdateHeliosActiveDirectoryParams {

    /**
     * Domain name of the active directory to update.
     */
    domainName: string;

    /**
     * Specifies the parameters to update an Active Directory.
     */
    body: CreateOrUpdateActiveDirectoryRequest;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;
  }

  /**
   * Parameters for DeleteHeliosActiveDirectory
   */
  export interface DeleteHeliosActiveDirectoryParams {

    /**
     * Domain name of the active directory to delete.
     */
    domainName: string;

    /**
     * Specifies the parameters to delete an Active Directory.
     */
    body: DeleteMcmActiveDirectoryParams;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;
  }

  /**
   * Parameters for GetTrustedDomains
   */
  export interface GetTrustedDomainsParams {

    /**
     * Specifies the FQDN of an Active directory domain.
     */
    domainName: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for TriggerTrustedDomainsDiscovery
   */
  export interface TriggerTrustedDomainsDiscoveryParams {

    /**
     * Specifies if trusted domains should be rediscovered.
     */
    rediscover: boolean;

    /**
     * Specifies the FQDN of an Active directory domain.
     */
    domainName: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateTrustedDomains
   */
  export interface UpdateTrustedDomainsParams {

    /**
     * Specifies the FQDN of an Active directory domain.
     */
    domainName: null | string;

    /**
     * Specifies the trusted domains params.
     */
    body: TrustedDomainParams;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { ActiveDirectoryService }
