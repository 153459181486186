import { DataFilterValue, ValueFilterSelection } from '@cohesity/helix';
import { envGroups, Environment } from '@cohesity/iris-shared-constants';
import { TranslateService } from '@ngx-translate/core';

/**
 * Constructs group filter value with translation
 *
 * @param translateService translation instance
 * @returns consolidated filter options
 */
export const getEnvGroupConstantsFilterValues = (translateService: TranslateService): ValueFilterSelection[] => {
  const values: ValueFilterSelection[] = [];
  const translateMapper: { [key: string]: string } = {
    vms: 'virtualMachines',
    dbs: 'databases',
  };
  Object.keys(envGroups).forEach(envKey => {
    const envValues = envGroups[envKey];
    const translateKey = translateMapper[envKey] ?? envKey;
    values.push({
      label: translateService.instant(translateKey),
      value: envKey,
      subItems: envValues
        .map((envValue: Environment) => {
          const subTranslateKey =
            envValue === Environment.kAWS ? `enum.environment.${envValue}.filter` : `enum.environment.${envValue}`;
          return {
            label: translateService.instant(subTranslateKey),
            value: envValue,
          };
        })
        .filter((envFilter: ValueFilterSelection) => envFilter.value !== Environment.kHyperVVSS),
    });
  });
  return values;
};

/**
 * Prunes environment filter values based on supported FF
 */
export const pruneEnvGroup = (
  filterValues: ValueFilterSelection[],
  supportedEnvs: Environment[]
): ValueFilterSelection[] =>
  filterValues
    .map(environmentGroups => ({
      ...environmentGroups,
      subItems: environmentGroups?.subItems.filter(environmentFilter =>
        supportedEnvs.includes(environmentFilter.value)
      ),
    }))
    .filter(environmentGroups => environmentGroups.subItems.length > 0);

/**
 * Extracts environment values from filter's data object
 */
export const getEnvironmentFilterValues = (filterItem: DataFilterValue<any>) => {
  if (filterItem.value[0]?.subItems?.length) {
    return filterItem.value[0].subItems.map(item => item.value);
  } else {
    return filterItem.value.map(item => item.value);
  }
};
