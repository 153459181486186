<div class="retention-container">
  <mat-label *ngIf="label">{{label | translate}}
    <cog-icon shape="info" size="sm"
      *ngIf="!!infoTooltip"
      [matTooltip]="infoTooltip | translate"
      matTooltipPosition="right"></cog-icon>
  </mat-label>
  <div class="retention-selector">
    <!-- Grain quantity input -->
    <mat-form-field *ngIf="showInput"
      class="retention-input"
      cogDataId="retention-selector"
      floatLabel="never">
      <input matInput
        type="number"
        matTooltip="{{isDisabled ? (disabledTooltip | translate) : ''}}"
        [formControl]="grainQuantityCtrl"
        [placeholder]="placeholder | translate"
        cogDataId="{{id}}-grain-input"
        (input)="updateValue()">
    </mat-form-field>

    <!-- Grain selector -->
    <mat-form-field class="grain-selector">
      <mat-select
        matTooltip="{{isDisabled ? (disabledTooltip | translate) : ''}}"
        [formControl]="grainCtrl"
        cogDataId="{{id}}-grain-selector"
        (selectionChange)="updateValue()">
        <mat-option *ngFor="let item of grains"
          [inheritDataId]="true"
          cogDataId="option-{{item.unit}}"
          [value]="item">
          {{item.label | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div *ngIf="showInput">
    <mat-error *ngIf="grainQuantityCtrl.errors?.required" cogDataId="retention-grain-quantity-required-error">
      {{'errors.required' | translate}}
    </mat-error>
    <mat-error *ngIf="grainQuantityCtrl.errors?.pattern" cogDataId="retention-grain-quantity-pattern-error">
      {{'errors.naturalNumber' | translate}}
    </mat-error>
    <mat-error *ngIf="grainQuantityCtrl.errors?.min" cogDataId="retention-grain-quantity-min-error">
      {{ 'errors.min' | translate: {value: grainQuantityCtrl.errors?.min.min} }}
    </mat-error>
  </div>
</div>
