
import { RestoreSearchResult } from './restore-search-result';

/**
 * This class groups multiple UDA search results together into a single objec that can be used
 * within a restore point selection. This assumes that all of the files share the same source id,
 * object it, and protection group.
 */
export class UdaSearchResultGroup implements RestoreSearchResult {

  // The below properties are implementations for the RestoreSearchResult interface.
  readonly resultType: string;
  readonly environment: string;
  readonly id: string | number;
  readonly name = null;
  readonly objectId: number;
  readonly indexedObjectId: string;
  readonly objectName: string;
  readonly objectType: string;
  readonly sourceEnvironment: string;
  readonly protectionGroupId: string;
  readonly protectionGroupName: string;
  readonly restoreTimestampUsecs: number;
  readonly sourceId: number;
  readonly parentSourceName: string;
  readonly storageDomainId: number;

  constructor(readonly udaSearchResults: RestoreSearchResult[]) {
    const udaSearchResult = udaSearchResults[0];
    this.environment = udaSearchResult.environment;
    this.id = udaSearchResult.id;
    this.name = udaSearchResult.name;
    this.indexedObjectId = udaSearchResult.indexedObjectId;
    this.objectType = udaSearchResult.objectType;
    this.protectionGroupId = udaSearchResult.protectionGroupId;
    this.protectionGroupName = udaSearchResult.protectionGroupName;
    this.restoreTimestampUsecs = udaSearchResult.restoreTimestampUsecs;
    this.sourceEnvironment = udaSearchResult.sourceEnvironment;
    this.sourceId = udaSearchResult.sourceId;
    this.parentSourceName = udaSearchResult.parentSourceName;
    this.storageDomainId = udaSearchResult.storageDomainId;
    this.resultType = udaSearchResult.resultType;
  }
}
