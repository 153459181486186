import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';

import { IconModule } from '../icon/icon.module';
import { BannerComponent } from './banner.component';
import { BannersComponent } from './components/banners/banners.component';


@NgModule({
  imports: [
    CommonModule,
    IconModule,
    MatIconModule,
    MatButtonModule,
    BannerComponent, BannersComponent
  ],
  exports: [BannerComponent, BannersComponent]
})
export class BannerModule { }
