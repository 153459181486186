import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

/**
 * Converts a input qos policy enum values to its text value.
 *
 * @example
 *   {{ 'kBackupHDD' | qosPolicy }}            'Backup HDD (Default)'
 *   {{ 'kBackupSSD' | qosPolicy }}            'Backup SSD'
 *   {{ null         | qosPolicy }}            -
 *   {{ null         | qosPolicy : true }}     '' // empty string
 */
@Pipe({
  name: 'qosPolicy'
})
export class QosPolicyPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(value: null | '' | undefined | 'kBackupHDD' | 'kBackupSSD', noNullState = false): string {
    if (!value) {
      return noNullState ? '' : this.translate.instant('naNotAvailable');
    }

    return this.translate.instant(`enum.job.qosPolicy.${value}`);
  }
}
