import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Pattern, ResourceType } from '@cohesity/api/argus';
import { joinUrl } from '@cohesity/helix';
import { appConfigMap } from '@cohesity/iris-core';
import { AutoDestroyable } from '@cohesity/utils';
import { TranslateService } from '@ngx-translate/core';
import { ObservableInput } from 'ngx-observable-input';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

/**
 * @description
 * Component that shows pattern distribution in the default policy
 *
 * @example
 *   <dg-sc-default-policy-patterns [data]="cardData"></dg-sc-default-policy-patterns
 */
@Component({
  selector: 'dg-sc-default-policy-patterns',
  templateUrl: './default-policy-patterns.component.html',
  styleUrls: ['./default-policy-patterns.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DefaultPolicyPatternsComponent extends AutoDestroyable implements OnInit {

  /**
   * link to policies page
   */
  readonly reviewLink = `/${joinUrl([appConfigMap.securityCenter.urlSegment, 'data-classification/policies'])}`;

  /**
   * The data recieved by the component using which presented data is generated.
   */
  @ObservableInput() @Input() data: BehaviorSubject<Pattern[]>;

  /**
   * Loading state of the card.
   */
  @Input() loading = false;

  /**
   * total number of builtIn Patterns
   */
  builtInPatterns = 0;

  /**
   * total number of custom Patterns
   */
  customPatterns = 0;

  constructor(readonly translate: TranslateService) {
    super();
  }

  ngOnInit(): void {
    this.data.pipe(
      filter(data => !!data),
      this.untilDestroy(),
    ).subscribe((data) => {
      this.updatePatternDistribution(data);
    });
  }

  /**
   * Generates the builtin vs custom patterns distribution for the given pattern data
   *
   * @param data list of patterns
   */
  updatePatternDistribution(data: Pattern[]) {
    const stats = data.reduce((acc, pattern) => {
      if (pattern.buildType === ResourceType.builtIn) {
        acc.builtInPatterns++;
      } else {
        acc.customPatterns++;
      }

      return acc;
    }, {
      builtInPatterns: 0,
      customPatterns: 0
    });

    this.builtInPatterns = stats.builtInPatterns;
    this.customPatterns = stats.customPatterns;
  }
}
