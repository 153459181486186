/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityV2ApiConfiguration as __Configuration } from '../cohesity-v2api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { GetDataAccessSessionsResponseParams } from '../models/get-data-access-sessions-response-params';
import { CreateDataAccessSessionResponseParams } from '../models/create-data-access-session-response-params';
import { CreateDataAccessSessionRequestParams } from '../models/create-data-access-session-request-params';
import { SearchGraphNodesResponseParams } from '../models/search-graph-nodes-response-params';
import { SearchGraphNodesRequestParams } from '../models/search-graph-nodes-request-params';
import { GetGraphNodesDetailsResult } from '../models/get-graph-nodes-details-result';
import { GetGraphNodeDetailsRequestParams } from '../models/get-graph-node-details-request-params';
@Injectable({
  providedIn: 'root',
})
class DataAccessorService extends __BaseService {
  static readonly GetDataAccessSessionsPath = '/data-access/snapshots/sessions';
  static readonly CreateDataAccessSessionPath = '/data-access/snapshots/sessions';
  static readonly TearDownDataAccessSessionPath = '/data-access/snapshots/sessions/{sessionId}';
  static readonly SearchGraphNodesPath = '/data-access/snapshots/sessions/graph-nodes/query';
  static readonly GetGraphNodesDetailsPath = '/data-access/snapshots/sessions/{sessionId}/graph-nodes';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Lists all the Data Access Sessions
   *
   * Lists the Data Access Sessions.
   * @param params The `DataAccessorService.GetDataAccessSessionsParams` containing the following parameters:
   *
   * - `statuses`: Specifies the list of session states to filter data access sessions
   *
   * - `startTimeUsecs`: Returns the data access sessions which are started after the specific time. This value should be in Unix timestamp epoch in microseconds.
   *
   * - `snapshotEnvironments`: Specifies the snapshot environment types to filter data access sessions.
   *
   * - `sessionIds`: Filter Data Access Sessions for given session ids.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `paginationCookie`: Specifies a cookie which can be passed in by the user in order to retrieve the next page of results.
   *
   * - `endTimeUsecs`: Returns the data access sessions which are started before the specific time. This value should be in Unix timestamp epoch in microseconds.
   *
   * - `count`: Specifies the number of objects to be fetched for the specified pagination cookie.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetDataAccessSessionsResponse(params: DataAccessorService.GetDataAccessSessionsParams): __Observable<__StrictHttpResponse<GetDataAccessSessionsResponseParams>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.statuses || []).forEach(val => {if (val != null) __params = __params.append('statuses', val.toString())});
    if (params.startTimeUsecs != null) __params = __params.set('startTimeUsecs', params.startTimeUsecs.toString());
    (params.snapshotEnvironments || []).forEach(val => {if (val != null) __params = __params.append('snapshotEnvironments', val.toString())});
    (params.sessionIds || []).forEach(val => {if (val != null) __params = __params.append('sessionIds', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.paginationCookie != null) __params = __params.set('paginationCookie', params.paginationCookie.toString());
    if (params.endTimeUsecs != null) __params = __params.set('endTimeUsecs', params.endTimeUsecs.toString());
    if (params.count != null) __params = __params.set('count', params.count.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/data-access/snapshots/sessions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetDataAccessSessionsResponseParams>;
      })
    );
  }
  /**
   * Lists all the Data Access Sessions
   *
   * Lists the Data Access Sessions.
   * @param params The `DataAccessorService.GetDataAccessSessionsParams` containing the following parameters:
   *
   * - `statuses`: Specifies the list of session states to filter data access sessions
   *
   * - `startTimeUsecs`: Returns the data access sessions which are started after the specific time. This value should be in Unix timestamp epoch in microseconds.
   *
   * - `snapshotEnvironments`: Specifies the snapshot environment types to filter data access sessions.
   *
   * - `sessionIds`: Filter Data Access Sessions for given session ids.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `paginationCookie`: Specifies a cookie which can be passed in by the user in order to retrieve the next page of results.
   *
   * - `endTimeUsecs`: Returns the data access sessions which are started before the specific time. This value should be in Unix timestamp epoch in microseconds.
   *
   * - `count`: Specifies the number of objects to be fetched for the specified pagination cookie.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetDataAccessSessions(params: DataAccessorService.GetDataAccessSessionsParams): __Observable<GetDataAccessSessionsResponseParams> {
    return this.GetDataAccessSessionsResponse(params).pipe(
      __map(_r => _r.body as GetDataAccessSessionsResponseParams)
    );
  }

  /**
   * Create Data Access Session
   *
   * Create data access session.
   * @param params The `DataAccessorService.CreateDataAccessSessionParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to create data access session
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateDataAccessSessionResponse(params: DataAccessorService.CreateDataAccessSessionParams): __Observable<__StrictHttpResponse<CreateDataAccessSessionResponseParams>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/data-access/snapshots/sessions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CreateDataAccessSessionResponseParams>;
      })
    );
  }
  /**
   * Create Data Access Session
   *
   * Create data access session.
   * @param params The `DataAccessorService.CreateDataAccessSessionParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to create data access session
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateDataAccessSession(params: DataAccessorService.CreateDataAccessSessionParams): __Observable<CreateDataAccessSessionResponseParams> {
    return this.CreateDataAccessSessionResponse(params).pipe(
      __map(_r => _r.body as CreateDataAccessSessionResponseParams)
    );
  }

  /**
   * Tear down data access session for a given id
   *
   * Tear down data access session for a given id.
   * @param params The `DataAccessorService.TearDownDataAccessSessionParams` containing the following parameters:
   *
   * - `sessionId`: Specifies the id of the data access session.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  TearDownDataAccessSessionResponse(params: DataAccessorService.TearDownDataAccessSessionParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/data-access/snapshots/sessions/${encodeURIComponent(params.sessionId)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Tear down data access session for a given id
   *
   * Tear down data access session for a given id.
   * @param params The `DataAccessorService.TearDownDataAccessSessionParams` containing the following parameters:
   *
   * - `sessionId`: Specifies the id of the data access session.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  TearDownDataAccessSession(params: DataAccessorService.TearDownDataAccessSessionParams): __Observable<null> {
    return this.TearDownDataAccessSessionResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Search Graph nodes
   *
   * Search nodes in the graph for a given session id
   * @param params The `DataAccessorService.SearchGraphNodesParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to query nodes in the graph for a given session id.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  SearchGraphNodesResponse(params: DataAccessorService.SearchGraphNodesParams): __Observable<__StrictHttpResponse<SearchGraphNodesResponseParams>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/data-access/snapshots/sessions/graph-nodes/query`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SearchGraphNodesResponseParams>;
      })
    );
  }
  /**
   * Search Graph nodes
   *
   * Search nodes in the graph for a given session id
   * @param params The `DataAccessorService.SearchGraphNodesParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to query nodes in the graph for a given session id.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  SearchGraphNodes(params: DataAccessorService.SearchGraphNodesParams): __Observable<SearchGraphNodesResponseParams> {
    return this.SearchGraphNodesResponse(params).pipe(
      __map(_r => _r.body as SearchGraphNodesResponseParams)
    );
  }

  /**
   * Get Graph Nodes details
   *
   * Get graph nodes details and it's relations for list node id.
   * @param params The `DataAccessorService.GetGraphNodesDetailsParams` containing the following parameters:
   *
   * - `sessionId`: Specifies the id of a session.
   *
   * - `nodeIds`: Specifies the id of the graph node.
   *
   * - `body`: Specifies the parameters to get node details in the graph for given node ids.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetGraphNodesDetailsResponse(params: DataAccessorService.GetGraphNodesDetailsParams): __Observable<__StrictHttpResponse<GetGraphNodesDetailsResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    (params.nodeIds || []).forEach(val => {if (val != null) __params = __params.append('nodeIds', val.toString())});
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/data-access/snapshots/sessions/${encodeURIComponent(params.sessionId)}/graph-nodes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetGraphNodesDetailsResult>;
      })
    );
  }
  /**
   * Get Graph Nodes details
   *
   * Get graph nodes details and it's relations for list node id.
   * @param params The `DataAccessorService.GetGraphNodesDetailsParams` containing the following parameters:
   *
   * - `sessionId`: Specifies the id of a session.
   *
   * - `nodeIds`: Specifies the id of the graph node.
   *
   * - `body`: Specifies the parameters to get node details in the graph for given node ids.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetGraphNodesDetails(params: DataAccessorService.GetGraphNodesDetailsParams): __Observable<GetGraphNodesDetailsResult> {
    return this.GetGraphNodesDetailsResponse(params).pipe(
      __map(_r => _r.body as GetGraphNodesDetailsResult)
    );
  }
}

module DataAccessorService {

  /**
   * Parameters for GetDataAccessSessions
   */
  export interface GetDataAccessSessionsParams {

    /**
     * Specifies the list of session states to filter data access sessions
     */
    statuses?: Array<'Pending' | 'Established' | 'RefreshInitiated' | 'Terminated'>;

    /**
     * Returns the data access sessions which are started after the specific time. This value should be in Unix timestamp epoch in microseconds.
     */
    startTimeUsecs?: number;

    /**
     * Specifies the snapshot environment types to filter data access sessions.
     */
    snapshotEnvironments?: Array<'kVMware' | 'kHyperV' | 'kAzure' | 'kGCP' | 'kKVM' | 'kAcropolis' | 'kAWS' | 'kPhysical' | 'kGPFS' | 'kElastifile' | 'kNetapp' | 'kGenericNas' | 'kIsilon' | 'kFlashBlade' | 'kPure' | 'kIbmFlashSystem' | 'kSQL' | 'kExchange' | 'kAD' | 'kOracle' | 'kView' | 'kRemoteAdapter' | 'kO365' | 'kKubernetes' | 'kCassandra' | 'kMongoDB' | 'kCouchbase' | 'kHdfs' | 'kHive' | 'kSAPHANA' | 'kHBase' | 'kUDA' | 'kSfdc' | 'kExperimentalAdapter'>;

    /**
     * Filter Data Access Sessions for given session ids.
     */
    sessionIds?: Array<string>;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Specifies a cookie which can be passed in by the user in order to retrieve the next page of results.
     */
    paginationCookie?: string;

    /**
     * Returns the data access sessions which are started before the specific time. This value should be in Unix timestamp epoch in microseconds.
     */
    endTimeUsecs?: number;

    /**
     * Specifies the number of objects to be fetched for the specified pagination cookie.
     */
    count?: number;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CreateDataAccessSession
   */
  export interface CreateDataAccessSessionParams {

    /**
     * Specifies the parameters to create data access session
     */
    body: CreateDataAccessSessionRequestParams;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for TearDownDataAccessSession
   */
  export interface TearDownDataAccessSessionParams {

    /**
     * Specifies the id of the data access session.
     */
    sessionId: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for SearchGraphNodes
   */
  export interface SearchGraphNodesParams {

    /**
     * Specifies the parameters to query nodes in the graph for a given session id.
     */
    body: SearchGraphNodesRequestParams;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetGraphNodesDetails
   */
  export interface GetGraphNodesDetailsParams {

    /**
     * Specifies the id of a session.
     */
    sessionId: string;

    /**
     * Specifies the id of the graph node.
     */
    nodeIds: Array<string>;

    /**
     * Specifies the parameters to get node details in the graph for given node ids.
     */
    body: GetGraphNodeDetailsRequestParams;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { DataAccessorService }
