// COMPONENT: Recover NAS: Options step

;(function(angular, undefined) {
  'use strict';

  var moduleName = 'C.restoreNas';

  angular
    .module(moduleName)
    .controller('nasRestoreOptionsController', nasRestoreOptionsControllerFn);

  function nasRestoreOptionsControllerFn($rootScope, $scope, SearchService,
    JobRunsService, RestoreService, SourceService, $q, $state, $stateParams,
    SlideModalService, cMessage, evalAJAX, ENV_GROUPS, ENUM_ENV_TYPE) {

    // The job type name which gets inserted into the default task name and
    // default view name
    var jobTypeInfix;

    $scope.ENV_GROUPS = ENV_GROUPS;

    angular.extend($scope, {
      // GENERAL SCOPE VARS
      entity: $scope.shared.cart[0],

      // SCOPE METHODS
      selectRestorePoint: selectRestorePoint,
    });


    // METHODS
    /**
     * Initialize all the things!
     *
     * @method     recoverAsMountPointOptionsInit
     */
    function activate() {
      if ($stateParams.entityId || $stateParams.jobId) {
        // This is an abbreviated flow
        setupAbbreviatedFlow();
      } else if (!$scope.shared.cart.length) {
        // Nothing to recover.
        $scope.startFlowOver();
      } else {
        // Normal flow
        updateRestoreParentSource();
        setupDefaultNames();
      }
    }

    /**
     * Set the default task and view names
     *
     * @method     setupDefaultNames
     */
    function setupDefaultNames() {
      jobTypeInfix = $scope.shared.cart[0] &&
        ENUM_ENV_TYPE[$scope.shared.cart[0]._type];
      $scope.shared.task.viewName =
        RestoreService.getDefaultTaskName('Mount', jobTypeInfix);
      $scope.shared.task.name =
        RestoreService.getDefaultTaskName($scope.flowType, jobTypeInfix);
    }

    /**
     * Conditionally updates the parent source of the task
     *
     * @method   updateRestoreParentSource
     */
    function updateRestoreParentSource() {
      if (!$scope.shared.task.restoreParentSource &&
        !!$scope.shared.filterLookups.registeredSourceIds &&
        $scope.shared.filterLookups.registeredSourceIds.length &&
        $scope.shared.task.objects.length) {
          // Convenience to preselect the volume's parent SAN, if one
          // hasn't already been selected.
          $scope.shared.filterLookups.registeredSourceIds.some(
            function findParent(san) {
              if (san.entity.id === $scope.shared.task.objects[0].entity.parentId) {
                $scope.shared.task.restoreParentSource = san.entity;
                return true;
              }
            }
          );
      }
    }

    /**
     * Function to make some scope changes for Direct object selection.
     *
     * @method     setupAbbreviatedFlow
     */
    function setupAbbreviatedFlow() {
      var foundMatch = false;

      $scope.isLoading = true;
      $scope.isAbbreviated = true;

      // Fetch by entityId & jobId
      SearchService.nasSearch({
        entityIds: $stateParams.entityId,
        jobIds: [$stateParams.jobId],
      })
      .then(function getNasSuccess(resp) {
        if (resp && resp.length) {
          // Parse the response for the matching entity and add it to the cart
          foundMatch = resp.some(
            function findMatchingEntity(entity, ii) {
              var vmDoc = entity.vmDocument;

              // Using == in here because stateParams are strings.
              // First, check if entity & job ids are the same.
              if (($stateParams.entityId == vmDoc.objectId.entity.id &&
                $stateParams.jobId == vmDoc.objectId.jobId) ||

                // otherwise check if jobId is the same (in the absence of an
                // entityId)
                (!$stateParams.entityId &&
                $stateParams.jobId == vmDoc.objectId.jobId)) {

                // Preselect snapshot?
                if ($stateParams.jobInstanceId) {
                  entity._snapshot = vmDoc.versions.find(
                    function findSnapshot(run) {
                      return $stateParams.jobInstanceId ==
                        run.instanceId.jobInstanceId;
                    }
                  );
                }

                // Add it to the cart now
                $scope.addToCart(entity, false);

                // More setup after the cart has been updated.
                updateRestoreParentSource();
                setupDefaultNames();

                return true;
              }
            }
          );
        }

        // No matching entity was found, show an error
        if (!foundMatch) {
          cMessage.error({
            titleKey: 'noVolumeFound',
            textKey: 'recover.nas.options.noEntityFound'
          });
        }

      }, evalAJAX.errorMessage)
      .finally(function getNasFinally() {
        $scope.isLoading = false;
      });
    }

    /**
     * Open the snapshot selector to let the user choose a different restore
     * point.
     *
     * @method     selectRestorePoint
     * @param      {object}  row     The search result entity in question
     */
    function selectRestorePoint(row) {
      var modalOpts = {
        templateUrl: 'app/protection/recovery/common/snapshot-selector/common.snapshot-selector.html',
        controller: 'commonRestoreSnapshotModalController',
        size: 'lg',
        resolve: {
          task: angular.copy($scope.shared.task),
          entity: row
        }
      };

      SlideModalService
        .newModal(modalOpts)
        .then(function snapshotSelectedFn(resp) {
          angular.extend(row, {
            _snapshot: resp.snapshot,
            _archiveTarget: resp.archiveTarget
          });
        });
    }

    // Activate!
    activate();
  }

})(angular);
