import { Component, OnInit } from '@angular/core';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';

import { LicenseNotificationService, Notification } from './license-notification.service';

/**
 * The cluster license notifications.
 *
 * @example
 *   coh-license-notification></coh-license-notification>
 */
@Component({
  selector: 'coh-license-notification',
  templateUrl: './license-notification.component.html',
})
export class LicenseNotificationComponent implements OnInit {
  /** var to save list of notification */
  public notifications: Notification[] = [];

  constructor(private licenseNotificationService: LicenseNotificationService, private irisCtx: IrisContextService) {}

  /**
   * component will call getNotifications function and will assign all notifications in its own variable
   */
  ngOnInit() {
    if (
      flagEnabled(this.irisCtx.irisContext, 'licensing') &&
      !flagEnabled(this.irisCtx.irisContext, 'forceLicensing')
    ) {
      this.licenseNotificationService.getNotifications().subscribe(notifications => {
        this.notifications = notifications;
      });
    }
  }
}
