<div class="verify-container container">
  <p translate>mfa.alternate.step1</p>
  <p translate>mfa.alternate.step2</p>

  <form class="emailForm" [formGroup]="formGroup"
    cohValidateOnSubmit (validatedSubmit)="verifyToken()">
    <mat-form-field floatLabel="always">
      <mat-label translate>emailAddress</mat-label>
      <input cogDataId="mfa-email-address"
        type="email"
        matInput
        [value]="userMfaInfo?.email"
        readonly>
    </mat-form-field>

    <small translate>mfa.verify.verifyUsingEmailDesc</small>
    <button class="email-code-btn"
      type="button"
      cogDataId="email-code-btn"
      mat-button
      color="primary"
      (click)="sendEmailCode()">
      {{'mfa.verify.emailMyCode' | translate}}
    </button>
    <p class="margin-top-lg" translate>mfa.verify.codeFromEmail</p>

    <mat-form-field class="no-margin" floatLabel="always">
      <mat-label translate>securityCode</mat-label>
      <input cogDataId="mfa-email-token"
        type="text"
        matInput
        [formControlName]="formControlNames.emailToken">
      <mat-error *ngIf="formGroupControls.emailToken.invalid">
        {{'errors.required' | translate}}
      </mat-error>
    </mat-form-field>
    <cog-banner *ngIf="verified" class="verified-email" status="success">
      {{'mfa.code.verified' | translate}}
    </cog-banner>

    <div class="padding-top button-footer">
      <button class="prev-btn"
        *ngIf="mfa.isHybridAuthNeeded"
        type="button"
        cogDataId="previous-btn"
        mat-button
        color="primary"
        (click)="previous.emit(true)">
        {{'back' | translate}}
      </button>
      <button cogSubmitButton class="next-btn"
        *ngIf="!verified"
        type="button"
        cogDataId="mfa-verify-btn"
        mat-flat-button
        color="primary">
        {{'verify' | translate}}
      </button>
      <button class="next-btn"
        *ngIf="verified"
        type="button"
        cogDataId="next-btn"
        mat-flat-button
        color="primary"
        (click)="next.emit(true)">
        {{'complete' | translate}}
      </button>
    </div>
  </form>
</div>
