import { Injectable, Optional } from '@angular/core';
import { LegacyDateAdapter as DateAdapter } from '@angular/material/legacy-core';
import moment, { Moment } from 'moment';

import { MaxDateOptions, maxDateOptionsMap, MinDateOptions, minDateOptionsMap } from './date-options';
import { DateRange, getMomentDateRange, Timeframe, timeframeOptionsValues } from './date-range';

/**
 * A hack to check moment date object and add functions when date adapter assumes native Date class.
 *
 * @param dateObject  Moment date object.
 */
export function checkMoment(dateObject: any) {
  if (moment.isMoment(dateObject) && !(dateObject as any).getFullYear) {
    (dateObject as any).getFullYear = () => dateObject.year();
    (dateObject as any).getMonth = () => dateObject.month();
    (dateObject as any).getDate = () => dateObject.date();
    (dateObject as any).getTime = () => dateObject.valueOf();
    (dateObject as any).getHours = () => dateObject.hours();
    (dateObject as any).getMinutes = () => dateObject.minutes();
    (dateObject as any).getSeconds = () => dateObject.seconds();
    (dateObject as any).getMilliseconds = () => dateObject.milliseconds();
  }
}

/**
 * Date Adapter Utils contains helper methods.
 */
@Injectable()
export class DateAdapterUtils<D> {
  constructor(
    @Optional() public dateAdapter: DateAdapter<D>
  ) {}

  /**
   * Converts Moment date into DateAdapter date.
   *
   * @param    date  Moment date.
   * @returns  Return DateAdapter date.
   */
  momentToDate(date: Moment): D {
    return date?.isValid() ? this.dateAdapter.parse(date.valueOf(), date.format()) : undefined;
  }

  /**
   * Converts into DateAdapter date into Moment date.
   *
   * @param    date  DateAdapter date.
   * @returns  Return Moment date.
   */
  dateToMoment(date: D): Moment {
    if (date instanceof Date) {
      return moment(date.valueOf());
    } else if (moment.isMoment(date)) {
      return date.clone();
    }

    throw new Error('DateAdapterUtils supports Native Date or Moment Date');
  }

  /**
   * Return the number of milliseconds for a given date.
   *
   * @param date The date instance.
   * @returns Return the number of milliseconds for a given date.
   */
  toMsecs(date: D): number {
    return this.dateToMoment(date).valueOf();
  }

  /**
   * Checks if the date range is valid or not.
   *
   * @param dateRange The date range to test.
   * @returns Whether date range is valid or not.
   */
  isValidDateRange(dateRange: DateRange<D>): boolean {
    return this.dateAdapter.isDateInstance(dateRange.start) &&
      this.dateAdapter.isValid(dateRange.start) &&
      this.dateAdapter.isDateInstance(dateRange.end) &&
      this.dateAdapter.isValid(dateRange.end);
  }

  /**
   * Return input date range if valid else return null.
   *
   * @param dateRange The date range to test.
   * @returns Return input date range if valid else return null.
   */
  getValidDateRangeOrNull(dateRange: DateRange<D>): DateRange<D> | null {
    return this.isValidDateRange(dateRange) ? dateRange : null;
  }

  /**
   * Checks if two dates are equal.
   *
   * @param first The first date to check.
   * @param second The second date to check.
   * @returns Whether the two dates are equal.
   *          Null dates are considered equal to other null dates.
   */
  isSameDate(first: D, second: D): boolean {
    if (
      !this.dateAdapter.isDateInstance(first) ||
      !this.dateAdapter.isDateInstance(second) ||
      !this.dateAdapter.isValid(first) ||
      !this.dateAdapter.isValid(second)
    ) {
      return false;
    }
    return moment(this.dateToMoment(first)).isSame(this.dateToMoment(second));
  }

  /**
   * Return the date range having start & end date of generic date type.
   *
   * @param timeframe The timeframe.
   * @returns Return the date range having start & end date of generic date type.
   */
  getGenericDateRange(timeframe: Timeframe): DateRange<D> {
    const dateRange = getMomentDateRange(timeframe);
    return {
      end: this.momentToDate(dateRange.end),
      start: this.momentToDate(dateRange.start),
      timeframe: dateRange.timeframe,
    };
  }

  /**
   * Checks if two date ranges are equal.
   *
   * @param first The first date range to check.
   * @param second The second date rang  to check.
   * @param hasNoOptionLabel  Do not check start, end if filter has no option label.
   * @returns Whether the two date ranges are equal.
   */
  isSameDateRange(first: DateRange<D>, second: DateRange<D>, hasNoOptionLabel = false): boolean {
    if (first && second && first.timeframe === second.timeframe) {
      if (first.timeframe === Timeframe.NoDate && hasNoOptionLabel) {
        return true;
      }
      return this.isSameDate(first.start, second.start) && this.isSameDate(first.end, second.end);
    }
    return false;
  }

  /**
   * returns minDate in usable format
   */
  getMinDate(minDate?: MinDateOptions | D): D | null {
    if (this.dateAdapter.getValidDateOrNull(minDate)) {
      // input is a valid date return date
      return minDate as D;
    }

    if (MinDateOptions[minDate as MinDateOptions]) {
      // if an option is given as input return the date corresponding to the option
      return this.momentToDate(minDateOptionsMap.get(minDate as MinDateOptions));
    }

    // if input is null, return null
    return null;
  }

  /**
   * returns maxDate in usable format
   */
  getMaxDate(maxDate: null | MaxDateOptions | D): D | null {
    if (this.dateAdapter.getValidDateOrNull(maxDate)) {
      // input is a valid date return date
      return maxDate as D;
    }

    if (MaxDateOptions[maxDate as MaxDateOptions]) {
      // if an option is given as input return the date corresponding to the option
      return this.momentToDate(maxDateOptionsMap.get(maxDate as MaxDateOptions));
    }

    // if input is null, return null
    return null;
  }

  /**
   * guesses the time frame corresponding to a given dateRange from a set of timeframe options
   *
   * @param dateRange the date range for which timeframe is to be guessed
   * @param timeframeOptions the list of timeframe from which time frame is to be guessed
   * @returns standard timeframe option if guessed else custom timeframe option
   */
  guessTimeframe(dateRange: DateRange<D>, timeframeOptions?: Timeframe[]): Timeframe {
    const { start, end, timeframe } = dateRange;

    // beginning of 1,3,6 and 12 months in the past respectively.
    const  prevMonthsBeg = [
      this.momentToDate(moment().clone().subtract(1, 'M').startOf('month')),
      this.momentToDate(moment().clone().subtract(3, 'M').startOf('month')),
      this.momentToDate(moment().clone().subtract(6, 'M').startOf('month')),
      this.momentToDate(moment().clone().subtract(12, 'M').startOf('month'))
    ];

    // Checking if end is in future comparing it with current moment if end is in future then use future date options
    // thus keeping difference as (end - start) else keep past date options and keep difference as (start - end)
    // Intentionally not using isAfer method here as full day options like past7days have end as EOD which will be
    // after current moment and thus cause issues.
    let diffMsec = (this.dateToMoment(end)).diff(moment()) >= Timeframe.Day ?
      this.dateToMoment(end).diff(this.dateToMoment(start)) :
      this.dateToMoment(start).diff(this.dateToMoment(end));

    // populate timeframe options with all selectable options if not provided by user
    if(!timeframeOptions) {
      timeframeOptions = timeframeOptionsValues;
    }

    // correction for complete months (2 extra milliseconds)
    // 1 extra millisecond for full day correction (as done above)
    // another extra millisecond for adjustment at date-range.ts (please check date-range.ts)
    if (diffMsec - 2 <= Timeframe.PastMonth && prevMonthsBeg.find(date => this.isSameDate(start, date))) {
      diffMsec = diffMsec - 2;
      if (timeframeOptions.includes(diffMsec)) {
        return Timeframe[Timeframe[diffMsec]];
      }
    }

    // correction for complete days (an extra millisecond)
    // refer date-range.ts for 24 hours
    // for cases > 24hours 1 millisecond is added as end of day is considered a millisecond before EOD
    if (diffMsec - 1 <= Timeframe.Past24Hours) {
      diffMsec = diffMsec - 1;
      if (timeframeOptions.includes(diffMsec)) {
        return Timeframe[Timeframe[diffMsec]];
      }
    }

    // correction for current month/year (3 extra milliseconds)
    // 1 extra millisecond for full day correction (as done above)
    // 2 extra milliseconds for adjustment at date-range.ts (please check date-range.ts)
    if (diffMsec - 3 <= Timeframe.CurrentMonth) {
      diffMsec = diffMsec - 3;
      if (timeframeOptions.includes(diffMsec)) {
        return Timeframe[Timeframe[diffMsec]];
      }
    }

    // incase no correction is required (all cases < 24 hours)
    if (timeframeOptions.includes(diffMsec)) {
      return Timeframe[Timeframe[diffMsec]];
    }

    // if the currTimeFrame is not in the given options set the timeframe to custom
    return timeframe || Timeframe.Custom;
  }
}
