/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityApiConfiguration as __Configuration } from '../cohesity-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { RegisterMcmPushTokenParams } from '../models/register-mcm-push-token-params';
@Injectable({
  providedIn: 'root',
})
class HeliosPushNotificationsService extends __BaseService {
  static readonly RegisterMcmPushTokenPath = '/public/mcm/pushTokens';
  static readonly UnregisterMcmPushTokenPath = '/public/mcm/pushTokens/{pushToken}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Register a push notification token with Helios.
   *
   * Register a push notification token with Helios.
   * @param Body undefined
   * @param regionId This field represents the region id.
   * @return Success
   */
  RegisterMcmPushTokenResponse(Body: RegisterMcmPushTokenParams,
    regionId?: string): __Observable<__StrictHttpResponse<RegisterMcmPushTokenParams>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = Body;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/mcm/pushTokens`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RegisterMcmPushTokenParams>;
      })
    );
  }
  /**
   * Register a push notification token with Helios.
   *
   * Register a push notification token with Helios.
   * @param Body undefined
   * @param regionId This field represents the region id.
   * @return Success
   */
  RegisterMcmPushToken(Body: RegisterMcmPushTokenParams,
    regionId?: string): __Observable<RegisterMcmPushTokenParams> {
    return this.RegisterMcmPushTokenResponse(Body, regionId).pipe(
      __map(_r => _r.body as RegisterMcmPushTokenParams)
    );
  }

  /**
   * Unregister a push notification token with Helios.
   *
   * Unregister a push notification token with Helios.
   * @param pushToken Specifies the push notification token being unregistered.
   * @param regionId This field represents the region id.
   */
  UnregisterMcmPushTokenResponse(pushToken: string,
    regionId?: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/public/mcm/pushTokens/${encodeURIComponent(pushToken)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Unregister a push notification token with Helios.
   *
   * Unregister a push notification token with Helios.
   * @param pushToken Specifies the push notification token being unregistered.
   * @param regionId This field represents the region id.
   */
  UnregisterMcmPushToken(pushToken: string,
    regionId?: string): __Observable<null> {
    return this.UnregisterMcmPushTokenResponse(pushToken, regionId).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module HeliosPushNotificationsService {
}

export { HeliosPushNotificationsService }
