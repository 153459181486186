<div cogTableActionsRow [hidden]="hidden$ | async">
  <ng-content></ng-content>
  <cog-spinner size="sm" *ngIf="actionsLoading$ | async"></cog-spinner>
  <ng-container *ngFor="let item of actions$ | async">
    <button *ngIf="item.state"
      cog-button-small
      mat-stroked-button
      color="primary"
      cogDataId="action-button-{{item.displayName}}"
      [inheritDataId]="true"
      [uiSref]="item.state"
      [uiParams]="item.stateParams">
      <cog-icon [shape]="item.icon"></cog-icon>
      {{ item.displayName | translate }}
    </button>

    <button *ngIf="item.action"
      cog-button-small
      mat-stroked-button
      color="primary"
      cogDataId="action-button-{{item.displayName}}"
      [inheritDataId]="true"
      [matTooltip]="item.displayName | translate"
      (click)="item.action()">
      <cog-icon [shape]="item.icon"></cog-icon>
      {{ item.displayName | translate }}
    </button>
  </ng-container>
</div>
