import { Injectable } from '@angular/core';
import { ProtectionSourceNode, ProtectionSourcesServiceApi } from '@cohesity/api/v1';
import { flagEnabled, IrisContextService, isDmsScope } from '@cohesity/iris-core';
import { Environment } from '@cohesity/iris-shared-constants';
import { SourceTreeDataProvider } from '@cohesity/iris-source-tree';
import { Observable } from 'rxjs';
import { PassthroughOptionsService } from 'src/app/core/services';
import { AwsWorkloadInclusionMap, AwsWorkloadType } from 'src/app/shared/constants';

@Injectable()
export class AwsSourceDataProvider implements SourceTreeDataProvider<ProtectionSourceNode, AwsWorkloadType> {
  constructor(
    readonly sourceService: ProtectionSourcesServiceApi,
    readonly irisContext: IrisContextService,
    readonly passthroughOptionsService: PassthroughOptionsService
  ) {}

  /**
   * Load the entire source tree.
   *
   * @param sourceId            The parent id to load the tree for.
   * @param environment         The environment to load the tree for.
   * @param workloadType        The current workflow to use within the tree.
   * @param allUnderHierarchy   Whether to include all objects all nodes a user has access to
   * @returns An observable with the entire tree data
   */
  fetchTree(
    sourceId: number,
    environment: Environment,
    workloadType?: AwsWorkloadType,
    allUnderHierarchy?: boolean
  ): Observable<ProtectionSourceNode[]> {
    const excludeAwsTypes: ProtectionSourcesServiceApi.ListProtectionSourcesParams['excludeAwsTypes'] = [];
    const dmsScope = isDmsScope(this.irisContext.irisContext);

    // If workload type is provided, we need to exclude all AWS types that are not
    // included in the workload type.
    if (workloadType) {
      const allAwsTypes = Object.entries(AwsWorkloadInclusionMap)
        .filter(([key]) => flagEnabled(this.irisContext.irisContext, 'awsDynamoDbEnabled') || key !== AwsWorkloadType.kDynamoDB)
        .flatMap(([, value]) => value);

      excludeAwsTypes.push(...allAwsTypes.filter(type => !AwsWorkloadInclusionMap[workloadType].includes(type)));
    } else {
      if (!dmsScope && !flagEnabled(this.irisContext.irisContext, 's3OnPremProtection')) {
        excludeAwsTypes.push(...AwsWorkloadInclusionMap[AwsWorkloadType.kS3]);
      }
    }

    return this.sourceService.ListProtectionSources({
      id: sourceId,
      allUnderHierarchy: allUnderHierarchy || false,
      environment,
      excludeTypes: ['kResourcePool'],
      excludeAwsTypes,
      includeEntityPermissionInfo: true,
      includeVMFolders: true,
      includeSystemVApps: true,
      includeExternalMetadata: true,
      ...this.passthroughOptionsService.requestParams,
    });
  }
}
