// View Service
import { isEntityOwner } from '@cohesity/iris-core';

;(function(angular, undefined) {

  angular.module('C')
    .service('RemoteClusterServiceFormatter', RemoteClusterServiceFormatterFn);

  function RemoteClusterServiceFormatterFn(NetworkService, UserService, NgIrisContextService) {

    var service = {
      transformRemote: transformRemote,
    };

    /**
     * transforms a view as returned from API, adding derived information.
     *
     * @param  {object} view as returned form API
     * @return {object}       updated view
     */
    function transformRemote(remote) {
      remote = remote || {};

      // True if user's belongs to the remote connections cluster's owner
      // organization else false and used to prevent SP from modifying tenants
      // resources.
      remote._isClusterOwner = isEntityOwner(NgIrisContextService.irisContext, remote.tenantId);

      // Indicate whether the logged-in user can view the cluster details eg.
      // for Tenant it will be false for seeded remote connection which are
      // setup by SP admin to setup replication for tenants backups.
      remote._canViewClusterDetails = !(UserService.isTenantUser() && !remote._isClusterOwner);

      remote.viewBoxPairInfo = remote.viewBoxPairInfo || [];

      // Set correct interfaceName based on returned params
      // This is then used as default selected interface
      switch (true) {
        case remote.networkInterfaceGroup !== undefined:
          remote._ifaceName = remote.networkInterfaceGroup;
          break;
        case !!remote.networkInterface:
          remote._ifaceName = remote.networkInterface;
          break;
        case remote.vlanId !== undefined &&
          remote.ifaceName !== undefined:
          remote._ifaceName = NetworkService.vlanToIfaceName(
            remote.ifaceName, remote.vlanId);
          break;
        default:
          remote._ifaceName = undefined;
      }

      remote._autoSelect = !remote._ifaceName;

      remote.clusterIdentifier = [
        remote.clusterId,
        remote.clusterIncarnationId
      ].join(':');

      return remote;
    }

    return service;
  }

})(angular);
