// Module: find-intersection utility
// Note: use _.intersection instead of this

;(function(angular, undefined) {
  'use strict';

  angular.module('C.utils').config(cUtilsDecorator);

  /**
   * cUtils Decorator
   *
   * @method   cUtilsDecorator
   * @param    {object}   $provide   The provide recipe
   */
  function cUtilsDecorator($provide) {
    $provide.decorator('cUtils', function addFindIntersection($delegate) {
      $delegate.findIntersection = findIntersection;

      return $delegate;
    });
  }

  /**
   * find the Intersection between 2 array of objects
   *
   * @example
        var listOne = [
          {
            controllerType: 'IDE',
            busNumber: 0,
            unitNumber: 0,
          },
          {
            controllerType: 'IDE',
            busNumber: 0,
            unitNumber: 1,
          },
          {
            controllerType: 'SATA',
            busNumber: 1,
            unitNumber: 1,
          }
        ];

        var listTwo = [
          {
            controllerType: 'SATA',
            busNumber: 1,
            unitNumber: 1,
            fileName: 'IDE://x.x.x.x/ide.sata'
          },
          {
            controllerType: 'IDE',
            busNumber: 0,
            unitNumber: 1,
            fileName: 'IDE://x.x.x.x/ide.txt'
          }
        ];

        function getUniqueKey(item) {
          return [
            item.busNumber,
            item.unitNumber,
            item.controllerType
          ].join('.');
        }

        var commonItemsFromListOne =
          findIntersection(listOne, listTwo, getUniqueKey);
        // [
        //   {
        //     controllerType: 'SATA',
        //     busNumber: 1,
        //     unitNumber: 1,
        //   },
        //   {
        //     controllerType: 'IDE',
        //     busNumber: 0,
        //     unitNumber: 1,
        //   }
        // ]

        var commonItemsFromListTwo =
          findIntersection(listTwo, listOne, getUniqueKey);
        // [
        //   {
        //     controllerType: 'SATA',
        //     busNumber: 1,
        //     unitNumber: 1,
        //     fileName: 'IDE://x.x.x.x/ide.sata',
        //   },
        //   {
        //     controllerType: 'IDE',
        //     busNumber: 0,
        //     unitNumber: 1,
        //     fileName: 'IDE://x.x.x.x/ide.txt',
        //   }
        // ]

        findIntersection([1, 2, 3, 4], [7, 1]); // [1]
        findIntersection([1, 2, 3, 4], [8]); // []
        findIntersection([], []); // []
   *
   * @method   findIntersection
   * @param   {Object[]}  listOne                 The list one
   * @param   {Object[]}  listTwo                 The list two
   * @param   {Function}  getUniqueKeyFn          return uniqueKey for each
                                                    list item
   * @param   {Function}  [getListTwoUniqueKeyFn] return uniqueKey for each
                                                    item of listTwo else use
                                                    getUniqueKeyFn.
   * @return  {Object[]}  common items between the 2 list from listOne
   */
  function findIntersection(listOne, listTwo, getUniqueKeyFn,
    getListTwoUniqueKeyFn) {
    var listOneMap;
    var listTwoMap;
    var listOneMapKeys;
    var listTwoMapKeys;
    var smallerListKeys;
    var otherList;
    getUniqueKeyFn = getUniqueKeyFn || function defaultGetUniqueKeyFn(item) {
      return item;
    };

    getListTwoUniqueKeyFn = getListTwoUniqueKeyFn || getUniqueKeyFn;

    listOneMap = listOne.reduce(function eachItem(acc, item) {
      acc[getUniqueKeyFn(item)] = item;

      return acc;
    }, {});
    listTwoMap = listTwo.reduce(function eachItem(acc, item) {
      acc[getListTwoUniqueKeyFn(item)] = item;

      return acc;
    }, {});

    listOneMapKeys = Object.keys(listOneMap);
    listTwoMapKeys = Object.keys(listTwoMap);

    if (listOneMapKeys.length >= listTwoMapKeys.length) {
      smallerListKeys = listTwoMapKeys;
      otherList = listOneMap;
    } else {
      smallerListKeys = listOneMapKeys;
      otherList = listTwoMap;
    }

    return smallerListKeys.reduce(function eachKey(acc, key) {
      if (otherList.hasOwnProperty(key)) {
        acc.push(listOneMap[key]);
      }

      return acc;
    }, []);
  }

})(angular);
