import { BehaviorSubject, Observable, pipe, throwError, UnaryFunction } from 'rxjs';
import { catchError, filter, finalize, map, tap } from 'rxjs/operators';

/**
 * The state model for an async operation. This can be used to track status,
 * loading, and error messages.
 *
 * @param   D   The response type for the action.
 */
export interface AsyncStateModel<D> {
  /**
   * The results of the current async operation.
   */
  result: D;

  /**
   * This is set to true whenever the call is in progress.
   */
  loading: boolean;

  /**
   * This is set to true when the call is completed successfully.
   */
  success: boolean;

  /**
   * The error, if any for the async operation.
   */
  error: any;
}

/**
 * Initial state for async values.
 */
export const initialAsyncState: AsyncStateModel<any> = {
  result: undefined,
  loading: false,
  success: false,
  error: undefined,
};

/**
 * This extends behavior subject to simplify the type syntax and setting the initial
 * value.
 */
export class AsyncBehaviorSubject<D> extends BehaviorSubject<AsyncStateModel<D>> {

  /**
   * Gets an observable of the loading value
   */
  get loading$(): Observable<boolean> {
    return this.pipe(map(value => value.loading));
  }

  constructor(initialValue: AsyncStateModel<D> = initialAsyncState) {
    super(initialValue);
  }

  /**
   * Gets an observable of the results value. This will emit whenever the results are valid
   *
   * @param onlyWhenComplete By default, the results will only emit when the api call is completed and has a non null
   *                         response. We can override this to access the result even if a new load is in progress
   * @param allowEmptyResult By default, we only return results if they are non-null. This can be updated to allow the
   *                         observable to emit empty responses.
   * @returns An observable of the async state mapped to the response value.
   */
  getResult(onlyWhenComplete = true, allowEmptyResult = false): Observable<D> {
    return this.pipe(
      filter(value => (!!value.result || allowEmptyResult) && (!onlyWhenComplete || !value.loading)),
      map(value => value.result)
    );
  }

  /**
   * Reset the subject to its initial state.
   */
  reset() {
    this.next(initialAsyncState);
  }
}

/**
 * This is an rxjs operator that assists with updating an async model. It emits a series of updates as an operation
 * executes.
 * First, it sets an initial state, indicating that the subject is loading.
 * - On success, it updates the result and sets the success flag
 * - On error, it updates the error and sets the success flag to false
 * - On finalize, it resets the loading property.
 *
 * @example
 * service.doSomething().pipe(updateWithStatus(subject));
 *
 * @param subject     The async subject to update.
 * @param reset       Reset the value whenever a new call is made
 * @param skipLoading This will skip setting the loading flag to true while the api call is in progress.
 * @returns   A pipe operator.
 */
export function updateWithStatus<D>(
  subject: AsyncBehaviorSubject<D>,
  reset: boolean = false,
  skipLoading: boolean = false
): UnaryFunction<Observable<D>, Observable<D>> {
  if (!subject) {
    throw new Error('pass an existing subject');
  }
  subject.next({
    ...(reset ? initialAsyncState : subject.value),
    loading: !skipLoading,
  });

  return pipe(
    tap((result: D) =>
      subject.next({
        ...subject.value,
        result: result,
        success: true,
        error: undefined,
      })
    ),
    catchError(error => {
      subject.next({
        ...subject.value,
        result: undefined,
        success: false,
        error: error,
      });
      return throwError(error);
    }),
    finalize(() =>
      subject.next({
        ...subject.value,
        loading: false,
      })
    )
  );
}
