// Module: Vmdk Recovery
import { recoveryGroup } from 'src/app/shared/constants';

;(function(angular, undefined){
  'use strict';

  var moduleName = 'C.vmdkRecovery';
  var moduleDeps = [];

  angular
    .module(moduleName, moduleDeps)
    .config(VmdkRecoveryConfigFn)
    .controller('VmdkRecoveryController', VmdkRecoveryControllerFn);

  /**
   * Vmdk Recovery Config: States & Routing
   **************************************************************************/
  function VmdkRecoveryConfigFn($stateProvider) {
    var modifyRestore = ctx => {
      return ctx.RESTORE_MODIFY &&
        ctx.FEATURE_FLAGS.recoverVmdk &&
        ctx.canAccessSomeEnv(recoveryGroup.vmdk) &&
        !ctx.isDmsScope;
    };

    $stateProvider
    .state('recover-vmdk', {
        url: '/protection/recovery/vmdk',
        title: 'VMDK Recovery',
        canAccess: modifyRestore,
        parentState: 'recover',
        templateUrl: 'app/protection/recovery/vmdk/vmdk-parent.html',
        controller: 'VmdkRecoveryController as $ctrl',
        redirectTo: 'recover-vmdk.search'
      })
      .state('recover-vmdk.search', {
        url: '^/protection/recovery/vmdk/search',
        title: 'VMDK Recovery: Search VMs',
        help: 'protection_recovery_virtual_disk_search',
        canAccess: modifyRestore,
        parentState: 'recover-vmdk',
        templateUrl: 'app/protection/recovery/vmdk/vmdk-search.html',
        controller: 'VmdkRecoverySearchController as $ctrl',
      })
      .state('recover-vmdk.options', {
        url: '^/protection/recovery/vmdk/options',
        title: 'VMDK Recovery: Options',
        help: 'protection_recovery_virtual_disk_options',
        canAccess: modifyRestore,
        parentState: 'recover-vmdk',
        templateUrl: 'app/protection/recovery/vmdk/vmdk-options.html',
        controller: 'VmdkRecoveryOptionsController as $ctrl',
      });
  }

  /**
   * Vmdk Recovery Parent Controller
   **************************************************************************/
  function VmdkRecoveryControllerFn($scope, RestoreService,
    VmdkRecoveryService) {

    angular.extend($scope, {
      /**
       * Ordered list of state names in this recovery flow
       *
       * @type       {Array}
       */
      stateNames: VmdkRecoveryService.getRecoverStateNames(),

      /**
       * Object for sharing within and across descendant $scopes
       *
       * @type       {Object}
       */
      shared: VmdkRecoveryService.getSharedState(),

      // Scope methods
      getOStype: VmdkRecoveryService.getOsType,
      $onInit: $onInit,
    });

    $scope.$watch('shared.cart', _cartUpdateHandler, true);

    /**
     * Methods
     ******************************************************************/

    /**
     * Initialize all the things!
     *
     * @method     _activate
     */
    function $onInit() {
      $scope.shared.task = VmdkRecoveryService.initRecoverTask();
    }

    /**
     * Handles $watch events for the task cart.
     *
     * @method   _cartUpdateHandler
     * @param    {array}   nv   The current cart value
     * @param    {array}   ov   The previous cart value
     */
    function _cartUpdateHandler(nv, ov) {
      // Empty cart or same entity
      if (!nv || !nv.length || angular.equals(nv, ov)) {
        return;
      }

      // Build the objects in the task object when a source is added to cart
      $scope.shared.task.objects =
        RestoreService.generateRestoreTaskObjects(nv);
    }
  }
})(angular);
