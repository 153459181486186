// Component: Stat List Component

;(function(angular, undefined) {

  var componentConfig = {
    controller: 'StatListController',
    templateUrl: 'app/global/c-stat-list/c-stat-list.html',
    bindings: {
      clean: '=?',
      config: '<',
      isLoading: '=?',
    },
  };

  angular.module('C.statList', [])
    .controller('StatListController', statListCtrlFn)
    .component('cStatList', componentConfig);

  /**
   * @ngdoc component
   * @name cStatList
   * @description
   * Displays a stat list from a config array
   *
   * @param   {boolean}   clean     Whether to use stat-list-clean or not.
   *                                stat-list-clean hides the separators between
   *                                items.
   *                                Defaults to false
   *
   * @param   {Object[]}  config    Array of objects to show in the stat list:
   *                                {
   *                                  label: string;          translation key
   *                                  value: number|string;   value to display
   *                                  valueKey: string;       translate key
   *                                  valueContext: Object;   params for value
   *                                                          translate
   *                                  classes: Object;        Sets ng-classes to
   *                                                          display status
   *                                  iconClasses:            If set, will use
   *                                                          c-media and
   *                                                          display an icon
   *                                  filterName: string;     defines the
   *                                                          angular filter for
   *                                                          the value
   *                                }[]
   *
   * @param   {boolean}   isLoading Whether to show spinner or not.
   *                                Defaults to false
   *
   * If an item's value is a number, it will be displayed with a number filter
   * Otherwise, it will be displayed as a string. If additional formatting
   * needs to be done, it can set when the config object is created.
   *
   * @example
   * <c-stat-list clean="true" config="$ctrl.config" is-loading="true">
   * </c-stat-list>
   */
  function statListCtrlFn() {
    var $ctrl = this;
  }

})(angular);
