import { Component } from '@angular/core';
import { ProtectionSourceNode } from '@cohesity/api/v1';
import { PopoverRef } from '@cohesity/helix';
import { TranslateService } from '@ngx-translate/core';

import { SourceKeys } from '../../constants';

@Component({
  selector: 'coh-aws-popover',
  templateUrl: './aws-popover.component.html',
  styleUrls: ['./aws-popover.component.scss'],
})
export class AwsPopoverComponent {

  /**
   * Access to the current node property.
   */
  get node(): ProtectionSourceNode {
    return this.popoverRef.data;
  }

  /**
   * Access to the Aws Protection Source object.
   */
  get awsSource(): any {
    const key = SourceKeys[this.node.protectionSource.environment];
    return this.node.protectionSource[key];
  }

  /**
   * Whether node is protected or not
   */
  isNodeProtected: boolean;

  /**
   * Node type.
   */
  type: string;

  /**
   * Constructor.
   */
  constructor(
    private popoverRef: PopoverRef<ProtectionSourceNode>,
    private translate: TranslateService,
  ) {
    const protectionSummary = this.node.protectedSourcesSummary;

    this.isNodeProtected = !!(protectionSummary &&
      protectionSummary[0].leavesCount && protectionSummary[0].leavesCount > 0);
    this.type = this.translate.instant(`enum.sourceType.kAWS.${this.awsSource.type}`);

    if (!this.popoverRef || !this.popoverRef.data) {
      throw new Error('Popover data is not defined');
    }
  }

  /**
   * Returns whether Aws node has popover or not based on node specific logic
   *
   * @param    node Protection source node
   * @returns  true/false whether node has popover or not
   */
  static hasPopover(node: ProtectionSourceNode): boolean {
    const key = SourceKeys[node.protectionSource.environment];

    return [
      'kEC2Instance',
      'kRDSInstance',
      'kAuroraCluster',
      'kS3Bucket',
    ].includes(node.protectionSource[key].type);
  }
}
