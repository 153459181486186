<div class="object-name">
  <cog-icon [shape]="node.protectionSource | objectIcon : {
    isProtected: isNodeProtected,
    workloadType: workload
  }">
  </cog-icon>
  <h4 class="title">{{node.protectionSource.name}}</h4>
</div>

<ul class="object-info-list">
  <!-- SMTP Address -->
  <li *ngIf="!!primarySMTPAddress" class="source-meta-line-item">
    <span class="source-meta-label">{{'primarySMTPAddress' | translate}}</span>
    {{primarySMTPAddress}}
  </li>

  <!-- Logical size(This is handled automatically by the office365-source tree
    service based on the workload in view) -->
  <li *ngIf="logicalSize" class="source-meta-line-item">
    <span class="source-meta-label">{{'size' | translate}}</span>
    {{logicalSize | byteSize}}
  </li>

  <!-- Metadata applicable to kSite -->
  <!-- Sharepoint Site Web URL -->
  <li *ngIf="!!siteWebUrl" class="source-meta-line-item">
    <span class="source-meta-label">{{'url' | translate}}</span>
    <a [href]="siteWebUrl" target="_blank">{{siteWebUrl}}</a>
  </li>

  <div *ngIf="!isMetaDataLoading">
    <!-- Member count (Show member count even if it is 0)-->
    <li *ngIf="!!office365Source.groupInfo" class="source-meta-line-item">
      <span class="source-meta-label">{{'Members' | translate}}</span>
      {{groupMemberCount}}
    </li>

    <!-- Metadata applicable to kUser -->
    <!-- City -->
    <li *ngIf="!!city" class="source-meta-line-item">
      <span class="source-meta-label">{{'city' | translate}}</span>
      {{city}}
    </li>

    <!-- Country -->
    <li *ngIf="!!country" class="source-meta-line-item">
      <span class="source-meta-label">{{'country' | translate}}</span>
      {{country}}
    </li>

    <!-- Department -->
    <li *ngIf="!!department" class="source-meta-line-item">
      <span class="source-meta-label">{{'department' | translate}}</span>
      {{department}}
    </li>

    <!-- Designation -->
    <li *ngIf="!!designation" class="source-meta-line-item">
      <span class="source-meta-label">{{'designation' | translate}}</span>
      {{designation}}
    </li>

  </div>

  <!-- Show spinner when metadata is loading -->
  <cog-spinner *ngIf="isMetaDataLoading" size="sm">
  </cog-spinner>

</ul>

<coh-protection-status-meta class="margin-top-lg"
  [node]="node"
  [groupEnv]="protectionGroupEnvMap[workload]">
</coh-protection-status-meta>
