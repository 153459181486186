import { Pipe, PipeTransform } from '@angular/core';
import { AjsUpgradeService } from 'src/app/core/services/ajs-upgrade.service';
import { UpgradePipe } from './upgrade-pipe';

@Pipe({
  name: 'snapshotTargetIcon',
  pure: true,
})
export class SnapshotTargetIconPipe extends UpgradePipe implements PipeTransform {
  constructor(upgradeService: AjsUpgradeService) {
    super(upgradeService, 'snapshotTargetIcon');
  }
}
