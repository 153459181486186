import { EnvItem } from '../source/source-types.constants';
import { Environment } from './environment.constants';

/**
 * The Environments which supports application level backups like kSQL, kOracle or kAD etc.
 * eg. Oracle database backup contains only the database records & table instated of volume or full disk backup of the
 *     machine running the oracle database hence such backup are called application level backups.
 * ApplicationEnvironment is a subset of Environment enum.
 *
 * NOTE: Magneto owns this and kept at `main/magneto/base/enums.proto` file.
 *
 */
export type ApplicationEnvironment =
  Environment.kAD |
  Environment.kExchange |
  Environment.kOracle |
  Environment.kSQL;

/**
 * The list of Environments which supports application level backups.
 */
export const ApplicationEnvironments: ApplicationEnvironment[] = [
  Environment.kAD,
  Environment.kExchange,
  Environment.kOracle,
  Environment.kSQL,
];

/**
 * The data structure for the map of application environment type to its host protection source type.
 */
export type ApplicationToSourceEnvironment = {
  [K in ApplicationEnvironment]: EnvItem<K>['hostEnvironment'][]
};

/**
 * The map of application environment type to its host protection source type.
 */
export const ApplicationToSourceEnvironment: ApplicationToSourceEnvironment = {
  kAD: [Environment.kPhysical],
  kExchange: [Environment.kPhysical, Environment.kVMware],
  kOracle: [Environment.kPhysical],
  kSQL: [Environment.kPhysical, Environment.kVMware, Environment.kSQL],
};
