<div class="flex-row vertical-align flex-justify-space-between">
  <div>
    <h3 class="no-margin title">{{'netgroupWhitelist' | translate}}</h3>
    <p *ngIf="enableAction">{{'views.netgroupWhitelistDescription' | translate}}</p>
  </div>
  <button
    *ngIf="enableAction && !isLoading"
    class="margin-bottom"
    type="button"
    cogDataId="add-netgroup-whitelist-btn"
    mat-stroked-button
    color="primary"
    (click)="modifyNetgroupWhitelist(false)">
    {{ 'add' | translate }}
  </button>
</div>

<cog-spinner *ngIf="isLoading" size="sm"></cog-spinner>
<!-- netgroup whitelist table -->
<cog-table name="netgroup-whitelist-table"
  *ngIf="nisNetgroups?.length"
  [source]="nisNetgroups"
  id="netgroup-whitelist-table">
  <table mat-table matSort>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header cogDataId="netgroup-name-col">
        {{ 'netgroupName' | translate }}
      </th>
      <td mat-cell *matCellDef="let row">
        <span class="user-data">{{ row.name }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="domain">
      <th mat-header-cell *matHeaderCellDef mat-sort-header cogDataId="nis-provider-col">
        {{ 'nisProvider' | translate }}
      </th>
      <td mat-cell *matCellDef="let row">
        <span class="user-data">{{ row.domain }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="nfsAccess">
      <th mat-header-cell *matHeaderCellDef mat-sort-header cogDataId="nfs-permissions-col">
        {{ 'nfsPermissions' | translate }}
      </th>
      <td mat-cell *matCellDef="let row">
        <span>{{ row.nfsAccess | translate }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="nfsSquash">
      <th mat-header-cell *matHeaderCellDef mat-sort-header cogDataId="nis-squash-col">
        {{ 'nfsSquash' | translate }}
      </th>
      <td mat-cell *matCellDef="let row">
        <span>{{ row.nfsSquash | translate }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row">
        <button
          type="button"
          class="actions-button"
          mat-icon-button
          [matTooltip]="'edit' | translate"
          cogDataId="modify-netgroup-whitelist-{{ row.id }}"
          (click)="modifyNetgroupWhitelist(true, row)">
          <cog-icon shape="helix:edit-outline"></cog-icon>
        </button>
        <button mat-icon-button
          type="button"
          class="actions-button"
          [matTooltip]="'delete' | translate"
          cogDataId="delete-netgroup-whitelist-{{ row.id }}"
          (click)="deleteNetgroup(row)">
          <cog-icon shape="delete_outline"></cog-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr mat-row *matRowDef="let row; columns: columns"></tr>
  </table>

  <mat-paginator [pageSize]="10" id="netgroup-whitelist-paginator"></mat-paginator>
</cog-table>

<p *ngIf="!nisNetgroups?.length && !enableAction" translate>none</p>
