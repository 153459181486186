/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
/**
 * This is a map of environment types to the key used in v1 jobs for the parameter
 * config. This includes keys *only* for those Job types which have backup params.
 */
import { Environment } from '@cohesity/iris-shared-constants';
import { ProtectionStatusType } from './icon.constants';

export enum JobEnvParamsV1 {
  kAWS = 'awsParameters',
  kAWSSnapshotManager = 'awsSnapshotParameters',
  kAzure = 'azureParameters',
  kAzureSnapshotManager = 'azureSnapshotParameters',
  kCassandra = 'cassandraParameters',
  kCouchbase = 'couchbaseParameters',
  kElastifile = 'nasParameters',
  kFlashBlade = 'nasParameters',
  kGCP = 'gcpParameters',
  kGenericNas = 'nasParameters',
  kGPFS = 'nasParameters',
  kHBase = 'hbaseParameters',
  kHdfs = 'hdfsParameters',
  kHive = 'hiveParameters',
  kHyperV = 'hypervParameters',
  kHyperVVSS = 'hypervParameters',
  kIsilon = 'nasParameters',
  kKubernetes = 'kubernetesParameters',
  kMongoDB = 'mongodbParameters',
  kNetapp = 'nasParameters',

  // TODO(pg): Should be nimbleParameters
  kNimble = 'pureParameters',
  kO365Outlook = 'office365Parameters',
  kOracle = 'oracleParameters',
  kPhysical = 'physicalParameters',
  kPhysicalFiles = 'physicalParameters',
  kPure = 'pureParameters',
  kSQL = 'sqlParameters',
  kUDA = 'udaParameters',
  kVMware = 'vmwareParameters',
  kIbmFlashSystem = 'ibmFlashSysyemParameters',
  kSAPHANA = 'sapHanaParameters',
}

/**
 * This is a map of environment types to the key used in v2 jobs for the parameter
 * config.
 */
export const JobEnvParamsV2: { [key in Environment]?: string; } = {
  [Environment.kAcropolis]: 'acropolisParams',
  [Environment.kAD]: 'adParams',
  [Environment.kAWS]: 'awsParams',
  [Environment.kAzure]: 'azureParams',
  [Environment.kCassandra]: 'cassandraParams',
  [Environment.kCouchbase]: 'couchbaseParams',
  [Environment.kElastifile]: 'elastifileParams',
  [Environment.kExchange]: 'exchangeParams',
  [Environment.kFlashBlade]: 'flashbladeParams',
  [Environment.kGCP]: 'gcpParams',
  [Environment.kGenericNas]: 'genericNasParams',
  [Environment.kGPFS]: 'gpfsParams',
  [Environment.kHBase]: 'hbaseParams',
  [Environment.kHdfs]: 'hdfsParams',
  [Environment.kHive]: 'hiveParams',
  [Environment.kHyperV]: 'hypervParams',
  [Environment.kIsilon]: 'isilonParams',
  [Environment.kKubernetes]: 'kubernetesParams',
  [Environment.kKVM]: 'kvmParams',
  [Environment.kMongoDB] : 'mongodbParams',
  [Environment.kNetapp]: 'netappParams',
  [Environment.kO365]: 'office365Params',
  [Environment.kO365Exchange]: 'office365Params',
  [Environment.kO365ExchangeCSM]: 'office365Params',
  [Environment.kO365Group]: 'office365Params',
  [Environment.kO365OneDrive]: 'office365Params',
  [Environment.kO365OneDriveCSM]: 'office365Params',
  [Environment.kO365PublicFolders]: 'office365Params',
  [Environment.kO365Sharepoint]: 'office365Params',
  [Environment.kO365SharepointCSM]: 'office365Params',
  [Environment.kO365Teams]: 'office365Params',
  [Environment.kOracle]: 'oracleParams',
  [Environment.kPhysical]: 'physicalParams',
  [Environment.kPure]: 'pureParams',
  [Environment.kIbmFlashSystem]: 'ibmFlashSystemParams',
  [Environment.kRemoteAdapter]: 'remoteAdapterParams',
  [Environment.kSQL]: 'mssqlParams',
  [Environment.kUDA]: 'udaParams',
  [Environment.kView]: 'viewParams',
  [Environment.kVMware]: 'vmwareParams',
  [Environment.kSfdc]: 'sfdcParams',
  [Environment.kSAPHANA]: 'sapHanaParams'
};

/**
 * This is map of environment types to the key used in v2 recover jobs for the recover params.
 */
export const RecoverSourceEnvParamsV2: { [key in Environment]?: string; } = {
  [Environment.kCassandra]: 'recoverCassandraParams',
  [Environment.kCouchbase]: 'recoverCouchbaseParams',
  [Environment.kHBase]: 'recoverHbaseParams',
  [Environment.kHdfs]: 'recoverHdfsParams',
  [Environment.kHive]: 'recoverHiveParams',
  [Environment.kMongoDB] : 'recoverMongodbParams',
  [Environment.kUDA] : 'recoverUdaParams',
  [Environment.kSAPHANA]: 'recoverSapHanaParams',
  [Environment.kO365]: 'recoverO365Params'
};

/**
 * Specifies the list of jobs run states which means run is in progress.
 */
export const JobRunInProgressStates: ProtectionStatusType[] = ['Running', 'Canceling', 'OnHold'];

/**
 * Specifies the list of job run states which means run is finished successfully.
 */
export const JobRunFinishedSuccessfullyStates: ProtectionStatusType[] = ['Succeeded', 'SucceededWithWarning'];

/**
 * Specifies the list of job run states which means run is finished un-successfully.
 */
export const JobRunFinishedUnsuccessfullyStates: ProtectionStatusType[] = ['Canceled', 'Failed', 'Missed'];

/**
 * Specifies the list of job run states which means run is finished eg. succeeded, failed or cancelled.
 */
export const JobRunFinishedStates = [...JobRunFinishedSuccessfullyStates, ...JobRunFinishedUnsuccessfullyStates];

/**
 * Job run types.
 */
export enum JobRunType {
  kFull = 'kFull',
  kHydrateCDP = 'kHydrateCDP',
  kLog = 'kLog',
  kRegular = 'kRegular',
  kStorageArraySnapshot = 'kStorageArraySnapshot',
  kSystem = 'kSystem',
}

/**
 * UI keys for job run types.
 */
export const JobRunTypeUIKey = {
  [JobRunType.kFull]: 'enum.jobRunType.kFull',
  [JobRunType.kRegular]: 'enum.jobRunType.kRegular',
  [JobRunType.kSystem]: 'enum.jobRunType.kSystem',
  [JobRunType.kStorageArraySnapshot]: 'enum.jobRunType.kStorageArraySnapshot',
};

/**
 * Mapping of adapters to default concurrency values for protection/recovery jobs.
 */
export const JobStreamConcurrencyMap: { [key in Environment]?: number; } = {
  [Environment.kCassandra]: 16,
  [Environment.kCouchbase]: 16,
  [Environment.kHBase]: 16,
  [Environment.kHdfs]: 16,
  [Environment.kHive]: 16,
  [Environment.kMongoDB]: 16,
  [Environment.kUDA]: 16,
  [Environment.kSAPHANA]: 16,
};
