import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TooltipChangeDetectionDirective } from './tooltip-change-detection.directive';

@NgModule({
  declarations: [TooltipChangeDetectionDirective],
  exports: [TooltipChangeDetectionDirective],
  imports: [CommonModule]
})
export class TooltipChangeDetectionModule { }
