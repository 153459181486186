import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { ComponentType } from '@angular/cdk/portal';
import { Injectable, TemplateRef } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Subscription } from 'rxjs';

import { AppPanelComponent } from './app-panel.component';

/**
 * Type of component that will be attached to AppPanelComponent.
 */
export type ComponentOrTemplate<T> = ComponentType<T> | TemplateRef<T>;

/**
 * Data will be injected into AppPanelComponent.
 */
export interface DialogData {
  /**
   * Component that should be attached to AppPanelComponent.
   */
  component: ComponentOrTemplate<any>;
}

/**
 * @description
 * Service for managing app overlay panel.
 * App overlay panel is used for displaying components in non-modal overlay window.
 *
 * @example
 *
 *    const dialogRef = appPanelService.open(MyComponent);
 *    dialogRef.afterClosed().subscribe(() => console.log('modal has been closed'));
 */
@Injectable({
  providedIn: 'root'
})
export class AppPanelService {
  /**
   * Indiates the top position based on presence of nav bar, app banners, etc
   */
  public dialogPositionTop: number;

  /**
   * Store reference to currently open overlay.
   */
  private dialogRef: MatDialogRef<any, AppPanelComponent>;

  /**
   * Internal subscription to closing dialog.
   */
  private closeDialogSub: Subscription;

  /**
   * Stores last attached component.
   */
  private _componentOrTemplate: any;

  constructor(private dialog: MatDialog) { }

  /**
   * Opens specified component next to specified HTML element.
   *
   * @param    componentOrTemplate  Component or template to attach to app panel overlay.
   * @param    options   Additional set of options for the dialog.
   * @returns  Returns AppPanelRef that can be used to listen for overlay events.
   */
  open<T, D>(
    componentOrTemplate: ComponentOrTemplate<T>,
    options: MatDialogConfig<D> = {}
  ): MatDialogRef<T, AppPanelComponent> {
    // Prevent reattaching same component if it's already open.
    if (!this.isComponentAttached(componentOrTemplate)) {
      this.dispose();

      this._componentOrTemplate = componentOrTemplate;

      const dialogConfig: MatDialogConfig = Object.assign({
        closeOnNavigation: true,
        hasBackdrop: true,
        backdropClass: 'cog-app-panel-backdrop',
        panelClass: 'cog-app-panel',
        scrollStrategy: new NoopScrollStrategy()
      }, options);

      if (this.dialogPositionTop) {
        dialogConfig.position = { top: `${this.dialogPositionTop}px` };
      }

      this.dialogRef = this.dialog.open(AppPanelComponent, dialogConfig);

      const appPanelComponent: AppPanelComponent = this.dialogRef.componentInstance as any;
      appPanelComponent.attach(componentOrTemplate);

      this.closeDialogSub = this.dialogRef.afterClosed().subscribe(() => this.dispose());
    }

    return this.dialogRef;
  }

  /**
   * Removes active overlay.
   */
  dispose() {
    this._componentOrTemplate = null;

    if (this.closeDialogSub) {
      this.closeDialogSub.unsubscribe();
    }

    if (this.dialogRef) {
      this.dialogRef.close();
      this.dialogRef = null;
    }
  }

  /**
   * Checks if specified component is already part of AppPanel.
   * This check is useful to prevent reopening same component.
   *
   * @param    component  Component type to check is instance is already open in AppPanel.
   * @returns  True is specified component type is already open in AppPanel.
   */
  isComponentAttached<T>(component: ComponentOrTemplate<T>): boolean {
    if (this.dialogRef) {
      return this._componentOrTemplate === component;
    }

    return false;
  }
}
