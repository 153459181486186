// Angular 'values'

(function(angular, undefined) {
  'use strict';

  angular.module('C')
    .value('clusterState', {
      found: false,
    });

}(angular));
