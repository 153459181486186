import { Component, Inject, Injector } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { DontShowAgainService } from '@cohesity/utils';

/**
 * This defines the type of data you need to pass into the
 * NavigationWarningDialogComponent.
 */
export interface NavigationWarningDialogData {
  title: string;
  dontShowAgainKey: string;
  navigationWarningMessage: string;
}

/**
 * This component warns user of navigation
 * and allow them to dismiss the warning and not show again.
 */
@Component({
  selector: 'coh-navigation-warning',
  templateUrl: './navigation-warning-dialog.component.html',
  styleUrls: ['./navigation-warning-dialog.component.scss']
})
export class NavigationWarningDialogComponent {

  /**
   * The service that helps remember user preference
   * to not be interrupted by this dialog again
   */
  dontShowAgainService: DontShowAgainService;

  /**
   * The title to display in the dialog.
   */
  get title() {
    return this.data.title;
  }

  /**
   * the message to display in the dialog.
   */
  get message() {
    return this.data.navigationWarningMessage;
  }

  constructor(
    private injector: Injector,
    public dialogRef: MatDialogRef<NavigationWarningDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: NavigationWarningDialogData,
  ) {
    this.dontShowAgainService = this.injector.get(DontShowAgainService);
  }

  /**
   * The method to handle the request from the user to not show this dialog again.
   *
   * @param   checked   Flag to permanently ignore.
   */
  toggleNavigationWarning(checked: boolean) {
    this.dontShowAgainService.dontShowAgain(this.data.dontShowAgainKey, checked);
  }
}
