import { Injectable } from '@angular/core';
import {
  GetRegistrationInfoResponse,
  ProtectionSource,
  ProtectionSourcesServiceApi,
  SfdcObjectFields,
} from '@cohesity/api/v1';
import {
  CommonSearchIndexedObjectsRequestParams,
  ConstructMetaInfoRequest,
  ConstructMetaInfoResult,
  GetObjectSnapshotsResponseBody,
  ObjectServiceApi,
  SearchIndexedObjectsRequest,
  SearchServiceApi,
  SfdcRecords,
} from '@cohesity/api/v2';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PassthroughOptionsService } from 'src/app/core/services';
import { AjaxHandlerService, Environment } from 'src/app/shared/constants';

import { ObjectSearchProvider } from '../../restore-shared';
import { RestoreTargetInfo } from '../../restore-shared/model/recover-to-source';
import { RestoreSearchResult } from '../../restore-shared/model/restore-search-result';

/**
 * This is a recover search provider specific to the Salesforce.
 */
@Injectable()
export class RecoverSfdcSearchService implements ObjectSearchProvider {
  constructor(
    private ajaxService: AjaxHandlerService,
    private objectsService: ObjectServiceApi,
    private protectionSourcesServiceApi: ProtectionSourcesServiceApi,
    private passthroughOptionsService: PassthroughOptionsService,
    private searchService: SearchServiceApi,
  ) { }

  /**
   * Indexed object type.
   */
  readonly objectType: CommonSearchIndexedObjectsRequestParams['objectType'] = 'SfdcRecords';

  /**
   * Indexed object type.
   */
  objectMutationType: 'All' | 'Added' | 'Removed' | 'Changed' = 'All';

  /**
   * Use the regular search, then add protection groups to the result.
   *
   * @param   query   The search query
   * @param   filter  The applied filters.
   * @returns Observable of an array of matching objects.
   */
  doObjectSearch(): Observable<RestoreSearchResult[]> {
    return null;
  }

  /**
   * Gets all registered source names and ids.
   *
   * @returns  Observable of users profiles.
   */
  getSalesforceSources(): Observable<RestoreTargetInfo[]> {
    // Construct payload
    const params = {
      ...this.passthroughOptionsService.requestParams,
    };

    return this.protectionSourcesServiceApi.ListProtectionSourcesRegistrationInfo(params).pipe(
      map(info => this.transformRecoverToData(info)),
    );
  }

  /**
   * Gets all depandant object list.
   *
   * @returns  Observable of users profiles.
   */
  getDepandantObjects(params: ConstructMetaInfoRequest,
    snapshotId: string): Observable<ConstructMetaInfoResult> {
    // Construct payload
    const metaInfoParams: ObjectServiceApi.ConstructMetaInfoParams = {
      snapshotId: snapshotId,
      body: params,
      ...this.passthroughOptionsService.requestParams,
    };

    return this.objectsService.ConstructMetaInfo(metaInfoParams);
  }

  /**
   * Gets all snapshot for the object.
   *
   * @returns  Observable of users profiles.
   */
  getObjectSnapshots(params: ObjectServiceApi.GetObjectSnapshotsParams): Observable<GetObjectSnapshotsResponseBody> {
    return this.objectsService.GetObjectSnapshots({
      ...params,
      ...this.passthroughOptionsService.requestParams,
    });
  }

  /**
   * Gets all records for the specified snapshot.
   *
   * @returns  Observable of salesforce records.
   */
  getSnapshotRecords(snapshotId: string,
    objectName: string, mutationTypes: string, queryString: string, cookie: string): Observable<SfdcRecords> {
    // Construct payload
    if (mutationTypes === 'Changed') {
      this.objectMutationType = 'Changed';
    } else if (mutationTypes === 'Removed') {
      this.objectMutationType = 'Removed';
    }
    const searchParams: SearchIndexedObjectsRequest = {
      objectType: this.objectType,
      sfdcParams: {
        snapshotId,
        objectName,
        mutationTypes: [this.objectMutationType],
        queryString,
      },
      count: 100,
      paginationCookie: cookie
    };

    return this.searchService.SearchIndexedObjects({
      body: searchParams,
      ...this.passthroughOptionsService.requestParams
    }).pipe(
      this.ajaxService.catchAndHandleError(),
      map((result) => result || {}),
      map(result => (result['sfdcRecords'] || {}))
    );
  }

  /**
   * Gets all fields for the specified object.
   *
   * @returns  Observable of salesforce fields.
   */
  getObjectFields(objectId: number): Observable<SfdcObjectFields[]> {

    const params: ProtectionSourcesServiceApi.ListProtectionSourcesParams = {
      id: objectId,
      includeSfdcFields: true,
      ...this.passthroughOptionsService.requestParams,
    };
    const result: SfdcObjectFields[] = [];
    return this.protectionSourcesServiceApi.ListProtectionSources(params).pipe(
      this.ajaxService.catchAndHandleError(),
      map(sources => sources[0].protectionSource),
      map((source: ProtectionSource) => {
        const objFields = source?.sfdcProtectionSource?.objectInfo?.fields;
        (objFields || []).forEach(fields => {
          result.push({
            name: fields.name,
          });
        });
        return result;
      }),
    );
  }

  /**
   * Transform user profiles data.
   *
   * @returns  list of sources for recovery.
   */
  transformRecoverToData(info: GetRegistrationInfoResponse): RestoreTargetInfo[] {
    let sources: RestoreTargetInfo[];
    if (info.rootNodes) {
      sources = info.rootNodes.filter(node => node.rootNode.environment === Environment.kSfdc).map(node => {
        const customName = node.rootNode.customName || '';
        return { id: node.rootNode.id, name: node.rootNode.name, customName: customName } as RestoreTargetInfo;
      });
    }
    return sources || [];
  }
}
