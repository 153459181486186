// Component: widget wrapper

;(function(angular, undefined) {

  // shared state used to ensure one child widgets layout shown at a time.
  var hideAlreadyShownChildWidgets;

  angular.module('C.widgets')
    .component('widgetWrapper', {
      bindings: {
        /**
         * widget
         * @type   {Object}   Properties {
         *   component:   {String}   Component to render in grid cell.
         *   posX:        {Number}   X coordinates of widget cell.
         *   posY:        {Number}   Y coordinates of widget cell.
         *   colSpan:     {Number}   number of grid cells widget should span
         *                           horizontally (left to right).
         *   rowSpan:     {Number}   number of grid cells widget should span
         *                           vertically (top to bottom).
         *   bindings:    {Object}   Optional bindings for the component.
         *   scope:       {Object}   Optional scope under which widget to be
         *                           compiled else parentScope would be used.
         * }
         *
         * @example
         * widgets = {
         *    component:      'jobRun',
         *    posX:           2,
         *    posY:           1,
         *    colSpan:        2,
         *    rowSpan:        1,
         *    bindings: {
         *      viewType:     'pie',
         *      data:         '$ctrl.chartData',
         *      titleKey:     'The title is {{$ctrl.name}}',
         *      onClick:      '$ctrl.handleOnClick(message)',
         *    },
         * }
         *
         * <job-run
            view-type="pie"
            data="$ctrl.chartData"
            title-key="The title is {{$ctrl.name}}"
            on-click="$ctrl.handleOnClick(message)"></job-run>
         */
        widget: '=',

        // @type   {Object}   parentScope   The scope Object to be used to
        //                                  compile the widget
        parentScope: '=',

        /**
         * Optional config
         * @type   {Object}   Properties {
         *   nColumn:     {Number}   [3]          Number of grid columns needed,
         *   minHeight:   {String}   [15.625rem]  min height of grid cell,
         *   gutter:      {String}   [0.625rem]   gutter width b/w cells,
         *   autoHeight:  {boolean}  [false]      allow widgets to expand
         * }
         */
        config: '=?',
      },
      require: {
        widgetsLayout: '^^?widgetsLayout',
      },
      transclude: true,
      controller: widgetWrapperFn,
      template: [
        '<c-compile-component',
          'component="{{$ctrl.widget.component}}"',
          'bindings="{{$ctrl.widget.bindings}}"',
          'compile-scope="$ctrl.parentScope"></c-compile-component>',
          '<span class="widget-connector"></span>'
      ].join(' '),
    });

  /**
   * @ngdoc component
   * @name C.widgets:widgetWrapper
   * @function
   *
   * @description
   * This component render the widget component and place it in the correct
   * grid cell.
   *
   * @example
   * <widget-wrapper
      widget="$ctrl.widget"
      parent-scope="$ctrl.parentScope"
      config="$ctrl.config"></widget-wrapper>
   */
  function widgetWrapperFn(_, $element) {
    var $ctrl = this;

    _.assign($ctrl, {
      // component life cycle methods
      $onChanges: $onChanges,
      $doCheck: updateWidgetStyling,

      // widget wrapper API
      addChildWidgetsInline: addChildWidgetsInline,
      toggleChildWidgetsVisibility: toggleChildWidgetsVisibility,
    });

    /**
     * Watch for binding changes and act on them accordingly.
     *
     * @method   $onChanges
     * @param    {Object}   changes   The binding changes Object
     */
    function $onChanges(changes) {
      updateWidgetStyling();
    }

    /**
     * toggle the child widgets visibility
     *
     * @method   toggleChildWidgetsVisibility
     */
    function toggleChildWidgetsVisibility() {
      if (hideAlreadyShownChildWidgets) {
        hideAlreadyShownChildWidgets($ctrl.widget);
        hideAlreadyShownChildWidgets = undefined;
      }

      $ctrl.widget.cConfig.showChildWidgets =
        !$ctrl.widget.cConfig.showChildWidgets;

      if ($ctrl.widget.cConfig.showChildWidgets) {
        hideAlreadyShownChildWidgets = function hideChildWidgets(shownWidget) {
          if (shownWidget !== $ctrl.widget) {
            $ctrl.widget.cConfig.showChildWidgets = false;
          }
          hideAlreadyShownChildWidgets = undefined;
        };
      }
    }

    /**
     * Add child widgets in a rows
     *
     * @method   addChildWidgetsInline
     * @param    {Object}   cWidgets   The child widgets config
     * @param    {Object}   cConfig    The child layout config
     */
    function addChildWidgetsInline(cWidgets, cConfig) {
      var posY = 0;

      // init child layout config and inherit config from parent layout
      $ctrl.widget.cConfig =
        _.assign({}, $ctrl.widget.cConfig, $ctrl.config, cConfig);

      // adding position cords for child widgets
      $ctrl.widget.cWidgets = cWidgets.map(
        function eachData(widget, index) {
          if (index % $ctrl.config.nColumn === 0) {
            posY++;
          }

          return _.assign(
            {
              // compile widget with parent widget scope else use it from
              // widget.scope
              scope: $ctrl.parentScope,
              posX: (index % $ctrl.config.nColumn) + 1,
              posY: posY,
              rowSpan: 1,
              colSpan: 1,
            },
            widget
          );
        }
      );
    }

    /**
     * update CSS grid cell widget positing.
     *
     * @method   updateWidgetStyling
     */
    function updateWidgetStyling() {
      var styleStr = '';
      var widgetStyling = {
        'grid-area': [
          $ctrl.widget.posY + $ctrl.widget.offsetY,
          $ctrl.widget.posX + $ctrl.widget.offsetX,
          ($ctrl.widget.posY + $ctrl.widget.offsetY + $ctrl.widget.rowSpan),
          ($ctrl.widget.posX + $ctrl.widget.offsetX + $ctrl.widget.colSpan)
        ].join('/'),
        '-ms-grid-row': (2 * ($ctrl.widget.posY + $ctrl.widget.offsetY) - 1),
        '-ms-grid-row-span': (2 * $ctrl.widget.rowSpan - 1),
        '-ms-grid-column': (2 * ($ctrl.widget.posX + $ctrl.widget.offsetX) - 1),
        '-ms-grid-column-span': (2 * $ctrl.widget.colSpan - 1),
      };
      if (!$ctrl.config.autoHeight) {
        // Unless autoHeight is set, use a fixed height for the widget so that
        // it won't dynamically expand. Otherwise only child widgets layout are
        // allowed to have a dynamic height.
        widgetStyling.height = [
          'calc(', $ctrl.widget.rowSpan,'*',$ctrl.config.minHeight,')'
        ].join('');
      }

      styleStr = Object.keys(widgetStyling).map(
        function eachStyle(key) {
          return key + ': ' + widgetStyling[key];
        }
      ).join('; ');

      $element.attr('style', styleStr);
    }
  }

})(angular);
