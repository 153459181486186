import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';

/**
 * Settings list item to supply the restore script arguments for UDA.
 */
@Component({
  selector: 'coh-settings-list-restore-script-arguments',
  templateUrl: './settings-list-restore-script-arguments.component.html',
  styleUrls: ['./settings-list-restore-script-arguments.component.scss'],
})
export class SettingsListRestoreScriptArgumentsComponent implements OnChanges, OnInit {

  /**
   * The form control for the settings list item.
   */
  @Input() control: UntypedFormControl;

  /**
   * Flag to check if this is a required field.
   */
  @Input() required = false;

  /**
   * Flag to check if this is a read only field.
   */
  @Input() readonly = false;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.required || changes.control) {
      this.control.clearValidators();
      this.control.setValidators([
        this.required ? Validators.required : Validators.nullValidator
      ]);
    }
  }

  /**
   * Component init.
   */
  ngOnInit() {
    const value = this.control.value || '';
    this.control.setValue(value);
  }
}
