<ng-container>
  <div class="file-upload-wrap margin-bottom-lg">
    <mat-label *ngIf="showLabel">{{label | translate}}</mat-label>
    <div class="margin-top-sm file-upload-button-wrap">

      <ng-content select="[beforeInputContent]"></ng-content>

      <div *ngIf="!file">
        <input id="upload-file-{{label}}-hidden"
          cogDataId="upload-file-{{label}}-hidden"
          type="file"
          [accept]="fileTypePattern"
          (change)="onFileChange($event)">

        <button mat-stroked-button color="primary"
          matTooltip="{{toolTip}}"
          cogDataId="upload-file-{{label}}"
          id="upload-file-{{label}}"
          type="button"
          (click)="openFileBrowser($event)">
          <mat-icon>attach_file</mat-icon>
          {{'selectFile' | translate}}
        </button>

        <mat-error *ngIf="required && !file" class="margin-top-sm">
          {{ 'errors.required' | translate }}
        </mat-error>
      </div>

      <div *ngIf="file">
        <span class="file-name">{{file.name}}
          <mat-icon class="cross" (click)="removeFile()">clear</mat-icon>
        </span>
      </div>

      <ng-content select="[afterInputContent]"></ng-content>
    </div>
  </div>
</ng-container>
