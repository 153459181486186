import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ProtectionJob, ProtectionSourceNode } from '@cohesity/api/v1';
import {
  ArchivalConfig,
  ProtectionGroupServiceApi,
  ReplicationConfig,
  RpaasConfig,
  RunCloudReplicationConfig,
  RunReplicationConfig,
  RunTargetsConfiguration,
} from '@cohesity/api/v2';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';
import { AjaxHandlerService, AutoDestroyable } from '@cohesity/utils';
import { BehaviorSubject, combineLatest, Observable, of, throwError } from 'rxjs';
import { catchError, map, shareReplay, switchMap } from 'rxjs/operators';
import { Environment, JobToSourceEnvironment, TypedFormGroup } from 'src/app/shared';
import { PolicyResponse, ProtectionPolicyService } from 'src/app/shared/policy';
import { defaultGroupActionFormValues, GroupActionFromModel, ProtectionGroup, ProtectionRun } from '../../models';
import { ObjectsTreeService } from '../../services/objects-tree.service';
import { GroupActionArchiveForm, GroupActionArchiveFormArray } from './group-archive-form/group-archive-form.component';
import { GroupActionCloudVaultForm, GroupActionCloudVaultFormArray } from './group-cloudvault-form/group-cloudvault-form.component';
import {
  GroupActionReplicationForm,
  GroupActionReplicationFormArray
} from './group-replication-form/group-replication-form.component';

/**
 * Root Group Action Form.
 */
export class GroupActionForm extends TypedFormGroup<GroupActionFromModel> {
  /**
   * All archive forms inside root group action form.
   */
  get archiveFormArray(): AbstractControl[] {
    const archiveForms = this.get('archiveFormArray') as GroupActionArchiveFormArray;
    return archiveForms?.controls;
  }

  /**
   * All rpaas forms inside root group action form.
   */
  get rpaasFormArray(): AbstractControl[] {
    const rpaasForms = this.get('rpaasFormArray') as GroupActionCloudVaultFormArray;
    return rpaasForms?.controls;
  }

  /**
   * All replication forms inside root group action form.
   */
  get replicationFormArray(): AbstractControl[] {
    const replicationForms = this.get('replicationFormArray') as GroupActionReplicationFormArray;
    return replicationForms?.controls;
  }

  /**
   * Selected objects from backup form.
   */
  get backupFormObjects(): AbstractControl {
    return this.get('backupForm.objects');
  }

  /**
   * Backup form object selection dropdown form control.
   */
  get backupFormScope(): AbstractControl {
    return this.get('backupForm.backupObjectsScope');
  }

  /**
   * Backup form backup type form control.
   */
  get backupFormRunType(): AbstractControl {
    return this.get('backupForm.backupType');
  }

  constructor(initialValue: GroupActionFromModel = defaultGroupActionFormValues) {
    super({
      backupForm: new UntypedFormGroup({
        backupObjectsScope: new UntypedFormControl(initialValue.backupForm.backupObjectsScope, Validators.required),
        backupType: new UntypedFormControl(initialValue.backupForm.backupType, Validators.required),
        objects: new UntypedFormControl(initialValue.backupForm.objects),
      }),
    });
  }

  /**
   * Populates archive forms inside root group action form.
   *
   * @param archivalTargets Archival targets used to populate archive forms.
   */
  addArchivalTargets(archivalTargets: ArchivalConfig[]) {
    this.setControl('archiveFormArray', new GroupActionArchiveFormArray(archivalTargets));
  }

  /**
   * Populates replication forms inside root group action form.
   *
   * @param replicationTargets Archival targets used to populate replication forms.
   */
  addReplicationTargets(replicationTargets: ReplicationConfig[]) {
    this.setControl('replicationFormArray', new GroupActionReplicationFormArray(replicationTargets));
  }

  /**
   * Populates RPaaS CloudVault forms inside root group action form.
   *
   * @param rpaasTargets Archival targets used to populate replication forms.
   */
  addCloudVaultTargets(rpaasTargets: RpaasConfig[]) {
    this.setControl('rpaasFormArray', new GroupActionCloudVaultFormArray(rpaasTargets));
  }
}

/**
 * @description
 * Protection Group Actions (Run Now) modal window.
 */
@Component({
  selector: 'coh-group-action-modal',
  templateUrl: './group-action-modal.component.html',
  styleUrls: ['./group-action-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupActionModalComponent extends AutoDestroyable implements OnInit {
  /**
   * Protection Group instance used to populate form data.
   */
  readonly protectionGroup$: Observable<ProtectionGroup>;

  /**
   * Protection Run instance used to populate form data.
   */
  readonly protectionRun$: Observable<ProtectionRun>;

  /**
   * Policy information used by `protectionGroup`.
   */
  policy$: Observable<PolicyResponse>;

  /**
   * Root form group for group action modal window.
   */
  formGroup$: Observable<GroupActionForm>;

  /**
   * Block form interaction when there are pending API requests.
   */
  readonly busy$ = new BehaviorSubject<boolean>(false);

  /**
   * Source tree nodes.
   */
  sourceNodes$: Observable<ProtectionSourceNode[]>;

  /**
   * Protection job.
   */
  job$: Observable<ProtectionJob>;

  /**
   * Whether to allow run now on an empty protection group.
   */
  allowEmptyProtectionGroup = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) readonly data: { protectionGroup: ProtectionGroup },
    private ajaxService: AjaxHandlerService,
    private dialogRef: MatDialogRef<GroupActionModalComponent>,
    private irisContextService: IrisContextService,
    private objectsTreeService: ObjectsTreeService,
    private policyService: ProtectionPolicyService,
    private protectionGroupService: ProtectionGroupServiceApi
  ) {
    super();
    this.protectionGroup$ = of(data.protectionGroup);

    this.protectionRun$ = this.protectionGroup$.pipe(
      switchMap(protectionGroup => this.protectionGroupService.GetProtectionGroupRun({
        id: protectionGroup?.id,
        runId: protectionGroup?.lastRun?.runId,
        includeObjectDetails: true,
      })),
      map(runRef => new ProtectionRun(runRef)),
      catchError(() => of(new ProtectionRun({}))),
      shareReplay(1)
    );
  }

  ngOnInit() {
    const { environment } = this.data.protectionGroup;

    /**
     * Skip the source call for Office 365 flows when the
     * ngRunNowOffice365 flag is on. This helps to avoid fetching large paylods
     * when there are large number of objects in the source.
     */
    const skipSourceNodeCalls = this.getSourceEnvironment(environment as Environment) === Environment.kO365
      && flagEnabled(this.irisContextService.irisContext, 'ngRunNowOffice365');

    this.job$ = this.objectsTreeService.getJob(this.data.protectionGroup.jobId, skipSourceNodeCalls)
      .pipe(
        map(res => {
          const ret = {...res};
          // Extract the ids from sources and populate the sourceIds array
          // When the skipSourceNodeCalls flag is used sourceIds won't be returned from the api
          if (res.sources) {
            ret.sourceIds = res.sources.map(obj => obj.id);
          }
          return ret;
        }),
        shareReplay(1));

    this.sourceNodes$ = this.job$.pipe(
      switchMap(job => {
        if (skipSourceNodeCalls) {
          /**
           * Build the dummy subset of the Source tree from the information
           * returned by the jobs
           * api. This can be done safely as we are only displaying the objects
           * that are part of the job.
           */
          return this.objectsTreeService.buildSourceTreeFromJob(job, environment as Environment);
        }
        return this.objectsTreeService.getSourceTree(job.parentSourceId, environment as Environment);
      }),
      shareReplay(1)
    );

    this.policy$ = this.protectionGroup$.pipe(
      switchMap(protectionGroup => {
        if (protectionGroup.policyId) {
          return this.policyService.getPolicyById(protectionGroup.policyId);
        }

        return of(null);
      }),
      catchError(err => {
        this.ajaxService.handler(err);
        return throwError(err);
      }),
      shareReplay(1)
    );

    this.formGroup$ = this.policy$.pipe(
      map(policy => {
        const formGroup = new GroupActionForm();
        // TODO: update policy as any to policy: ProtectionPolicyResponse when
        // iris_team in main is updated with dataprotect_team.
        if ((policy as any)?.cascadedTargetsConfig?.length) {
          (policy as any).cascadedTargetsConfig.forEach(config => {
            if (config.remoteTargets?.archivalTargets?.length) {
              formGroup.addArchivalTargets(config.remoteTargets.archivalTargets as ArchivalConfig[]);
            }
            if (config.remoteTargets?.replicationTargets?.length) {
              formGroup.addReplicationTargets(config.remoteTargets.replicationTargets as ReplicationConfig[]);
            }
            if (config.remoteTargets?.rpaasTargets?.length) {
              formGroup.addCloudVaultTargets(config.remoteTargets.rpaasTargets as RpaasConfig[]);
            }
          });
        } else if (typeof policy?.remoteTargetPolicy === 'object') {
          if (policy.remoteTargetPolicy?.archivalTargets?.length) {
            formGroup.addArchivalTargets(policy.remoteTargetPolicy.archivalTargets as ArchivalConfig[]);
          }
          if (policy.remoteTargetPolicy?.replicationTargets?.length) {
            formGroup.addReplicationTargets(policy.remoteTargetPolicy.replicationTargets as ReplicationConfig[]);
          }
          if (policy.remoteTargetPolicy?.rpaasTargets?.length) {
            formGroup.addCloudVaultTargets(policy.remoteTargetPolicy.rpaasTargets as RpaasConfig[]);
          }
        }

        return formGroup;
      }),
      shareReplay(1)
    );

    // Whether to allow run now on an empty protection group.
    // Currently, only applicable for VMWare.
    this.allowEmptyProtectionGroup = environment === Environment.kVMware &&
      flagEnabled(this.irisContextService.irisContext, 'emptyProtectionGroupVm');
  }

  /**
   * Returns API request params config for each target.
   *
   * @param form Group action form.
   * @returns Run targets config.
   */
  private getTargetConfig(form: GroupActionForm): RunTargetsConfiguration {
    const archiveTargets = form.archiveFormArray
      ?.filter(control => control.enabled)
      .map((control: GroupActionArchiveForm) => ({
        id: control.targetId,
        archivalTargetType: control.targetType,
        retention: {
          duration: control.retentionDuration,
          unit: control.retentionUnit,
        },
      }));

    const rpaasTargets = form.rpaasFormArray
      ?.filter(control => control.enabled)
      .map((control: GroupActionCloudVaultForm) => ({
        id: control.targetId,
        archivalTargetType: control.targetType,
        retention: {
          duration: control.retentionDuration,
          unit: control.retentionUnit,
        },
      }));

    const replicationTargets: RunReplicationConfig[] = form.replicationFormArray
      ?.filter(
        (control: GroupActionReplicationForm) => control.get('enabled')?.value && control.type === 'RemoteCluster'
      )
      .map((control: GroupActionReplicationForm) => ({
        id: control.targetId,
        retention: {
          duration: control.retentionDuration,
          unit: control.retentionUnit,
        },
      }));

    const cloudReplications: RunCloudReplicationConfig[] = form.replicationFormArray
      ?.filter(
        (control: GroupActionReplicationForm) =>
          control.get('enabled')?.value && ['AWS', 'Azure'].includes(control.type as any)
      )
      .map((control: GroupActionReplicationForm) => ({
        id: control.targetId,
        targetType: control.type as any,
        awsTarget: {
          ...control.awsTargetConfig,
        },
        retention: {
          duration: control.retentionDuration,
          unit: control.retentionUnit,
        },
      }));

    if (archiveTargets?.length || replicationTargets?.length || cloudReplications?.length || rpaasTargets?.length) {
      const targetsConfig: RunTargetsConfiguration = {};

      if (archiveTargets?.length || rpaasTargets?.length) {
        targetsConfig.archivals = [...(archiveTargets || []), ...(rpaasTargets || [])];
      }

      if (replicationTargets?.length) {
        targetsConfig.replications = replicationTargets;
      }

      if (cloudReplications?.length) {
        targetsConfig.cloudReplications = cloudReplications;
      }

      return targetsConfig;
    }
  }

  /**
   * Handles form submit action.
   */
  onSubmit() {
    this.busy$.next(true);
    combineLatest([this.formGroup$, this.protectionGroup$])
      .pipe(
        this.untilDestroy(),
        switchMap(([form, protectionGroup]) =>
          this.protectionGroupService.CreateProtectionGroupRun({
            id: protectionGroup.id,
            body: {
              runType: form.backupFormRunType.value,
              objects: form.backupFormObjects.value.map(id => ({ id })),
              targetsConfig: this.getTargetConfig(form),
            },
          })
        ),
        catchError(err => {
          this.ajaxService.handler(err);
          this.busy$.next(false);
          return throwError(err);
        })
      )
      .subscribe(() => this.dialogRef.close(true));
  }

  /**
   * The source environment.
   */
  getSourceEnvironment(environment: Environment): Environment {
    return JobToSourceEnvironment[environment] ? JobToSourceEnvironment[environment][0] : environment;
  }
}
