
<ng-container *ngIf="(stats$ | async) as stats">
  <cog-statlist class="half-card-statlist" valueSize="md" cogDataId="activity-link">
    <a
      cogStatlistItem
      cogDataId="running"
      iconName="helix:status-in-progress-alt!info"
      iconSize="xs"
      [inheritDataId]="true"
      [label]="'running' | translate"
      (click)="this.performGotoVaultProtection(['Running'])">
      {{ stats.running | number }}
    </a>
    <a
      cogStatlistItem
      cogDataId="failed"
      iconName="helix:status-error-alt!critical"
      iconSize="xs"
      [inheritDataId]="true"
      [label]="'failed' | translate"
      (click)="this.performGotoVaultProtection(['Failed'])">
      {{ stats.failed | number }}
    </a>
    <a
      cogStatlistItem
      cogDataId="completed"
      iconName="helix:status-success-alt!success"
      iconSize="xs"
      [inheritDataId]="true"
      [label]="'succeeded' | translate"
      (click)="this.performGotoVaultProtection(['Succeeded'])">
      {{ stats.completed | number }}
    </a>
  </cog-statlist>
</ng-container>
