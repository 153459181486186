import { CreateView, Template } from '@cohesity/api/v2';
import { pick } from 'lodash';
import { ViewInfo } from '../views.model';
import { CreateViewBasicForm } from './basic-form';
import { DefaultCreateViewValue } from './default-value';

/**
 * The interface for the dialog form.
 */
export interface CreateViewDialogForm {
  createViewBasicForm: CreateViewBasicForm;
}

/**
 * The interface for the create view data.
 *
 * @extends  {Template} Used for creating view from Template.
 */
export interface CreateViewData extends Template {
  /**
   * The full view data.
   */
  viewParams?: ViewInfo;

  // used for creating view from a Storage Domain.
  restrictViewBoxId?: number;

  // Used for earlier workflows which used 'modify-view-dialog' component for
  // redirecting after the dialog operations. In the newer implementation,
  // 'create-view-dialog-new' the redirect logic is being handled inside the
  // component it's being called from.
  disableRouting?: boolean;

  // used for snapdiff s3 view create
  isSnapdiffS3View?: boolean;

  isEditMode?: boolean;
}

/**
 * The overrides for the Create View Dialog workflow.
 *
 * @see {DEFAULT_VIEW_CONFIG}
 */
export const CreateViewDialogOverrides: CreateView = {
  ...pick(DefaultCreateViewValue, [
    'qos',
    'caseInsensitiveNamesEnabled',
    'enableKerberosAuthentication',
    'enableKerberosIntegrity',
    'enableKerberosPrivacy',
    'enableSmbViewDiscovery',
    'securityMode',
    'selfServiceSnapshotConfig',
    'sharePermissions',
    'smbPermissionsInfo',
  ]),
};
