import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  Optional,
  SkipSelf,
  ViewEncapsulation
} from '@angular/core';
import { DataTreeNodeContext, DataTreeNodeDetail } from '@cohesity/helix';
import { IrisContextService, isDmsScope } from '@cohesity/iris-core';
import { Environment } from '@cohesity/iris-shared-constants';
import { SOURCE_TREE_CONTEXT } from '@cohesity/iris-source-tree';
import { AutoDestroyable } from '@cohesity/utils';
import { take } from 'rxjs/operators';
import { AzureActionContextData } from 'src/app/modules/object-details-shared';
import { SourceTreeContext } from 'src/app/shared/source-tree';
import { AzureSourceDataNode } from 'src/app/shared/source-tree/protection-source/azure/azure-source-data-node';

/**
 * Details view for an azure protection-source based node. This renders a protected/partial protected icon and
 * the node's name including an environment specific connection status for subscriptions without saas connection(s).
 */
@Component({
  selector: 'coh-azure-protection-source-detail',
  templateUrl: './azure-protection-source-detail.component.html',
  styleUrls: ['./azure-protection-source-detail.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AzureProtectionSourceDetailComponent extends AutoDestroyable implements DataTreeNodeDetail<AzureSourceDataNode>{

  /**
   * The node context, including info about the node and its selection status.
   */
  private _nodeContext: DataTreeNodeContext<AzureSourceDataNode>;

  /**
   * Sets the node context
   *
   * @param value The node context input value
   */
  @Input() set nodeContext(value: DataTreeNodeContext<AzureSourceDataNode>) {
    this._nodeContext = value;
    if(value && value.node.type === 'kSubscription') {
      this.init();
    }
  }

  /**
   * Returns the node context
   */
  get nodeContext() {
    return this._nodeContext;
  }

  /**
   * Returns true if node is subscription and has at least a saas connection
   */
  hasSaasConnection = false;

  /**
   * Specifies if additional context data is available
   */
  hasAdditionalContextData = false;

  /**
   * Specifies if current app context is in DMaaS
   */
  get isDms() {
    return isDmsScope(this.irisCtx.irisContext);
  }

  /**
   * Specifies if info badge for subscriptions without saas connection should be shown
   */
  get showInfoBadge(): boolean {
    return this.isDms && (this.nodeContext?.node?.type === 'kSubscription') && this.hasAdditionalContextData && !this.hasSaasConnection;
  }

  constructor(
    private irisCtx: IrisContextService,
    @Inject(SOURCE_TREE_CONTEXT) @Optional() @SkipSelf() private readonly sourceTreeContext?: SourceTreeContext,
  ) {
    super();
  }

  /**
   * Initializes component attribute state
   */
  init(): void {
    const objectMenuProvider = this.sourceTreeContext?.objectActionProvider;
    if (!objectMenuProvider) {
      return;
    }
    objectMenuProvider.getActionsContextData(Environment.kAzure)
      .pipe(this.untilDestroy(), take(1))
      .subscribe((contextData: AzureActionContextData) => {
        this.hasAdditionalContextData = !!contextData;
        if (contextData) {
          this.hasSaasConnection = (contextData.connections ?? [])
            .some(conn => this.nodeContext.node.name === conn.rigelCloudInfraInfo?.azureRigelInfraInfo?.subscriptionId);
        }
      });
  }

}
