// Component: Antivirus Settings for a View

;(function(angular, undefined) {
  'use strict';

  angular.module('C.viewAvSettings', [])
    .component('viewAvSettings', {
      templateUrl: 'app/antivirus/secured-views/view-av-settings.html',
      controller: ViewAvSettingsCtrlFn,
      bindings: {
        // @type   {object}   view object
        view: '=',

        // @type   {boolean}   Indicates whether to make visual adjustments
        // since it is nested inside another set of shutters.
        nested: '<?',
      },
    });

  // Default value for `maximumScanFileSize` (25 MiB)
  var maximumScanFileSizeDefaultBytes = 26214400;

  // Maximum allowed value for `maximumScanFileSize` (125 MiB)
  var maximumScanFileSizeUpperLimitBytes = 131072000;

  // Default number of seconds before scan times out: 180 seconds.
  var defaultScanTimeoutUsecs = 180000000;

  /**
   * @ngdoc component
   * @name C.viewAvSettings
   * @function ViewAvSettingsCtrlFn
   *
   * @description
   * Provides component which manages the list of AV settings shutters for a
   * View.
   *
   * @example
   * <view-av-settings></view-av-settings>
   */
  function ViewAvSettingsCtrlFn(_, $scope) {
    var $ctrl = this;

    var defaultAntivirusScanConfig = {
      _fileFilterType: 'kAll',
      blockAccessOnScanFailure: false,
      isEnabled: true,
      maximumScanFileSize: maximumScanFileSizeDefaultBytes,
      scanFilter: {
        isEnabled: false,
        fileExtensionsList: [],
      },
      scanOnAccess: false,
      scanOnClose: true,
      scanTimeoutUsecs: defaultScanTimeoutUsecs,
    };

    _.assign($ctrl, {
      maxScanFileSizeUpperLimitBytes: maximumScanFileSizeUpperLimitBytes,
      filterTypeKeys: {
        kAll: 'allFiles',
        kBlacklist: 'allFilesExceptColon',
        kWhitelist: 'onlyTheseFilesColon',
      },

      $onInit: $onInit,
      getSetFileExtensionList: getSetFileExtensionList,
      getSetScanTrigger: getSetScanTrigger,
      getSetTimeoutSeconds: getSetTimeoutSeconds,
      setScanFilterMode: setScanFilterMode,
    });

    /**
     * Calls when component is initialized
     *
     * @method   $onInit
     */
    function $onInit() {
      var view = $ctrl.view;
      var scanConfig;

      // Otherwise, set some dependent values.
      scanConfig = view.antivirusScanConfig || {};
      scanConfig._fileFilterType = (scanConfig.scanFilter || {}).mode || 'kAll';
    }

    /**
     * Gets and Sets the two dependent values from which we derived the single
     * user-facing scan trigger.
     *
     * @method    getSetScanTrigger
     * @param     {String}    newVal    The newly selected scan trigger.
     * @returns   {String}    The selected scan trigger.
     */
    function getSetScanTrigger(newVal) {
      var scanConfig =
        $ctrl.view.antivirusScanConfig = $ctrl.view.antivirusScanConfig || {};

      // Set the new model values.
      switch (newVal) {
        case 'openAndClose':
          scanConfig.scanOnAccess = true;
          scanConfig.scanOnClose = true;
          break;

        case 'open':
          scanConfig.scanOnAccess = true;
          scanConfig.scanOnClose = false;
          break;

        case 'close':
          scanConfig.scanOnAccess = false;
          scanConfig.scanOnClose = true;
          break;
      }

      // Return the derived value.
      switch(true) {
        case scanConfig.scanOnAccess && scanConfig.scanOnClose:
          return 'openAndClose';

        case scanConfig.scanOnAccess:
          return 'open';

        case scanConfig.scanOnClose:
          return 'close';

        default:
          return '';
      }
    }

    /**
     * Gets/Sets number of seconds before scan timeout. The backend expects
     * microseconds but the UI only displays seconds.
     *
     * @method    getSetTimeoutSeconds
     * @param     {Number}    newVal    The new number of seconds.
     * @returns   {Number}    The number of seconds.
     */
    function getSetTimeoutSeconds(newVal) {
      $ctrl.view.antivirusScanConfig = $ctrl.view.antivirusScanConfig || { scanTimeoutUsecs: 0 };

      if (arguments.length) {
        $ctrl.view.antivirusScanConfig.scanTimeoutUsecs = newVal * 1000000;
      }

      return $ctrl.view.antivirusScanConfig.scanTimeoutUsecs / 1000000;
    }

    /**
     * Sets the dependent values from which we derive the single
     * user-facing scan filter mode.
     *
     * @method    setScanFilterMode
     * @param     {String}    mode    The newly selected scan filter mode.
     * @returns   {String}    The selected scan filter mode.
     */
    function setScanFilterMode(mode) {
      var scanFilter = _.get($ctrl.view, 'antivirusScanConfig.scanFilter', {});

      switch (mode) {
        case 'kAll':
          scanFilter.isEnabled = false;
          scanFilter.mode = undefined;
          break;

        case 'kBlacklist':
          scanFilter.isEnabled = true;
          scanFilter.mode = 'kBlacklist';
          break;

        case 'kWhitelist':
          scanFilter.isEnabled = true;
          scanFilter.mode = 'kWhitelist';
          break;
      }
    }

    /**
     * Gets/Sets the list of file extensions on the whitelist or blacklist.
     *
     * @method    getSetFileExtensionList
     * @param     {String}    list    The new csv string of extensions.
     * @returns   {String}    The csv string of extensions.
     */
    function getSetFileExtensionList(list) {
      var avConfig = $ctrl.view.antivirusScanConfig;

      if (arguments.length) {
        avConfig.scanFilter.fileExtensionsList = !list ? [] : list.split(',');
      } else {
        avConfig.scanFilter.fileExtensionsList =
          avConfig.scanFilter.fileExtensionsList || [];
      }

      return avConfig.scanFilter.fileExtensionsList.join(',');
    }

    // Watch the View object to see if it was updated externally. If so, stub
    // the defaults.
    $scope.$watch(function() { return $ctrl.view.name; }, function() {
      if (!_.get($ctrl.view, 'antivirusScanConfig.isEnabled')) {
        _.assignIn($ctrl.view,
          { antivirusScanConfig: defaultAntivirusScanConfig });
      }
    }, true);

  }

})(angular);
