/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityV2ApiConfiguration as __Configuration } from '../cohesity-v2api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Notifications } from '../models/notifications';
@Injectable({
  providedIn: 'root',
})
class HeliosNotificationsService extends __BaseService {
  static readonly GetHeliosNotificationsPath = '/mcm/banner/notifications';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get helios notifications
   *
   * Get all types of notifications.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  GetHeliosNotificationsResponse(regionId?: string): __Observable<__StrictHttpResponse<Notifications>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/mcm/banner/notifications`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Notifications>;
      })
    );
  }
  /**
   * Get helios notifications
   *
   * Get all types of notifications.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  GetHeliosNotifications(regionId?: string): __Observable<Notifications> {
    return this.GetHeliosNotificationsResponse(regionId).pipe(
      __map(_r => _r.body as Notifications)
    );
  }
}

module HeliosNotificationsService {
}

export { HeliosNotificationsService }
