import { Component, Input, HostBinding } from '@angular/core';
import { LegacyThemePalette as ThemePalette } from '@angular/material/legacy-core';
import { NgIf } from '@angular/common';
import { MatLegacyProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';

/**
 * Mapping of size values to Angular Material compatible diameter values (px because Material).
 */
const sizeToDiameter = {
  xs: 16,
  sm: 24,
  md: 48,
  lg: 80,
};

/**
 * A wrapper component for Angular Material's SpinnerComponent, an indeterminate
 * circular loading spinner. While a "header" input is provided, this should be
 * used only when its unclear as to what the spinner is indicating. General
 * usage (like a table loading data or a page load) should omit a header and use
 * a simple visual spinner.
 *
 * @example
 * <cog-spinner size="lg"></cog-spinner>
 */
@Component({
  selector: 'cog-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  standalone: true,
  imports: [MatLegacyProgressSpinnerModule, NgIf]
})
export class SpinnerComponent {
  /**
   * The size of the spinner.
   */
  @Input() size: 'xs' | 'sm' | 'md' | 'lg' = 'md';

  /**
   * Optional header to be rendered with spinner.
   */
  @Input() header: string;

  /**
   * Optional material color value to use for the spinner.
   */
  @Input() color: ThemePalette = 'accent';

  /**
   * Indicates if the spinner should be redendered as an inline element.
   */
  @HostBinding('class.spinner-inline')
  @Input() inline = false;

  /**
   * Indicates if the spinner should be redendered with an overlay.
   */
  @HostBinding('class.spinner-overlay')
  @Input() isOverlay = false;

  /**
   * Optionally aligns content to the left. The default implementation is
   * centered within the block element.
   */
  @HostBinding('class.spinner-left-aligned')
  @Input() leftAligned = false;

  /**
   * Provides the diameter value for the MatSpinner.
   */
  get diameter(): number {
    return sizeToDiameter[this.size];
  }

}
