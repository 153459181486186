<div class="topology-button-group" *ngIf="data?.nodes?.length > 5 || data?.showScale">
  <button mat-icon-button (click)="scaleIndex = scaleIndex - 1" [disabled]="scaleIndex === 0">
    <mat-icon>remove_circle_outline</mat-icon>
  </button>
  <mat-slider
    [max]="scaleList.length - 1"
    [min]="0"
    [(ngModel)]="scaleIndex">
  </mat-slider>
  <button mat-icon-button (click)="scaleIndex = scaleIndex + 1" [disabled]="scaleIndex >= scaleList.length - 1">
    <mat-icon>add_circle_outline</mat-icon>
  </button>
</div>
<div #container
  class="topology-container"
  (window:resize)="resize()"
  [ngStyle]="{'height.%': data?.nodes?.length > 5 ? 90 : 100}">
  <div #tooltip
    class="topology-tooltip"
    [class.grid-tooltip]="data?.clickForTooltip"
    [hidden]="!graphElement"
    [ngStyle]="getTooltipPosition()">
    <coh-topology-element
      *ngIf="graphElement"
      [element]="graphElement"
      [nodes]="data?.nodes"></coh-topology-element>
  </div>
  <!-- Image (svg) used by d3 to draw the topology graph -->
  <div class="topology-graph" [ngClass]="'topology-scale-' + scale" [ngStyle]="{
    'width.px': svgStyle.width,
    'height.px': svgStyle.height,
    'margin-top.px': svgStyle.marginTop
  }">
    <svg>
      <defs>
        <marker id="dpt-arrow-start" refX="0" refY="4" markerWidth="8" markerHeight="8" orient="auto">
          <path class="topology-edge" d="M8,0 L0,4 L8,8"></path>
        </marker>
        <marker id="dpt-arrow-start-disconnected" refX="0" refY="4" markerWidth="8" markerHeight="8" orient="auto">
          <path class="topology-edge topology-edge-disconnected topology-marker-disconnected" d="M8,0 L0,4 L8,8"></path>
        </marker>
        <marker id="dpt-arrow-start-error" refX="0" refY="4" markerWidth="8" markerHeight="8" orient="auto">
          <path class="topology-edge topology-edge-error" d="M8,0 L0,4 L8,8"></path>
        </marker>
        <marker id="dpt-arrow-start-inProgress" refX="0" refY="4" markerWidth="8" markerHeight="8" orient="auto">
          <path class="topology-edge topology-edge-inProgress" d="M8,0 L0,4 L8,8"></path>
        </marker>
        <marker id="dpt-arrow-start-noActivity" refX="0" refY="4" markerWidth="8" markerHeight="8" orient="auto">
          <path class="topology-edge topology-edge-noActivity" d="M8,0 L0,4 L8,8"></path>
        </marker>
        <marker id="dpt-arrow-start-success" refX="0" refY="4" markerWidth="8" markerHeight="8" orient="auto">
          <path class="topology-edge topology-edge-success" d="M8,0 L0,4 L8,8"></path>
        </marker>
        <marker id="dpt-arrow-start-warning" refX="0" refY="4" markerWidth="8" markerHeight="8" orient="auto">
          <path class="topology-edge topology-edge-warning" d="M8,0 L0,4 L8,8"></path>
        </marker>
        <marker id="dpt-arrow-end" refX="8" refY="4" markerWidth="8" markerHeight="8" orient="auto">
          <path class="topology-edge" d="M0,0 L8,4 L0,8"></path>
        </marker>
        <marker id="dpt-arrow-end-disconnected" refX="8" refY="4" markerWidth="8" markerHeight="8" orient="auto">
          <path class="topology-edge topology-edge-disconnected topology-marker-disconnected" d="M0,0 L8,4 L0,8"></path>
        </marker>
        <marker id="dpt-arrow-end-error" refX="8" refY="4" markerWidth="8" markerHeight="8" orient="auto">
          <path class="topology-edge topology-edge-error" d="M0,0 L8,4 L0,8"></path>
        </marker>
        <marker id="dpt-arrow-end-inProgress" refX="8" refY="4" markerWidth="8" markerHeight="8" orient="auto">
          <path class="topology-edge topology-edge-inProgress" d="M0,0 L8,4 L0,8"></path>
        </marker>
        <marker id="dpt-arrow-end-noActivity" refX="8" refY="4" markerWidth="8" markerHeight="8" orient="auto">
          <path class="topology-edge topology-edge-noActivity" d="M0,0 L8,4 L0,8"></path>
        </marker>
        <marker id="dpt-arrow-end-success" refX="8" refY="4" markerWidth="8" markerHeight="8" orient="auto">
          <path class="topology-edge topology-edge-success" d="M0,0 L8,4 L0,8"></path>
        </marker>
        <marker id="dpt-arrow-end-warning" refX="8" refY="4" markerWidth="8" markerHeight="8" orient="auto">
          <path class="topology-edge topology-edge-warning" d="M0,0 L8,4 L0,8"></path>
        </marker>
        <filter id="shadow" filterUnits="userSpaceOnUse">
          <feGaussianBlur stdDeviation="2" result="coloredBlur"></feGaussianBlur>
          <feMerge>
            <feMergeNode in="coloredBlur"></feMergeNode>
            <feMergeNode in="SourceGraphic"></feMergeNode>
          </feMerge>
        </filter>
      </defs>
    </svg>
  </div>
</div>
