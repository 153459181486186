/* tslint:disable */

/**
 * Specifies the scan trigger.
 */
export enum ScanTrigger {
  manually = 'manually',
  anomaly = 'anomaly',
  schedule = 'schedule'
}
