<!-- Hide this setting here temporarily if regionId is set in readonly mode -->
<cog-settings-list-item *ngIf="!properties.quickProtect && !passthroughOptions.regionId"
  [invalid]="formGroup.get(name)?.invalid"
  [label]="'storageDomain' | translate"
  [isReadOnly]="isSnapMirrorConfigDone">
  <cog-settings-list-item-value-display>
    {{ sdSelector?.selectedStorageDomain?.name }}
    <ng-container *ngTemplateOutlet="incompatibleMsg"></ng-container>
  </cog-settings-list-item-value-display>
  <cog-settings-list-item-value-editor [formGroup]="formGroup">
    <ng-container *ngTemplateOutlet="selector"></ng-container>
    <ng-container *ngTemplateOutlet="incompatibleMsg"></ng-container>
  </cog-settings-list-item-value-editor>
</cog-settings-list-item>

<coh-show-hide-edit-control-wrapper
  *ngIf="properties.quickProtect"
  [invalid]="formControl.pristine || formControl.invalid"
  [success]="formControl.valid"
  [title]="'storageDomain' | translate"
  iconShape="helix:db">
  <coh-show-hide-edit-control-edit>
    <ng-container *ngTemplateOutlet="selector"></ng-container>
    <ng-container *ngTemplateOutlet="incompatibleMsg"></ng-container>
  </coh-show-hide-edit-control-edit>
  <coh-show-hide-edit-control-summary *ngIf="properties.quickProtect">
    <coh-storage-domain-synopsis
      *ngIf="formControl.value"
      [showName]="true"
      [storageDomain]="sdSelector?.selectedStorageDomain">
    </coh-storage-domain-synopsis>
  </coh-show-hide-edit-control-summary>
</coh-show-hide-edit-control-wrapper>

<ng-template #selector>
  <div [formGroup]="formGroup">
    <coh-storage-domain-selector
      *ngIf="showSelectorInQuickProtect"
      [addNewEnable]="true"
      [autoOpen]="false"
      [class.quick-protect]="properties.quickProtect"
      [defaultStorageDomainId]="protectionGroup?.storageDomainId"
      [floatLabel]="properties.quickProtect ? null : 'never'"
      [formControlName]="name"
      [readOnly]="!!protectionGroup?.id">
    </coh-storage-domain-selector>
    <mat-error *ngIf="formControl.errors?.required">
      {{'errors.required' | translate}}
    </mat-error>
  </div>
</ng-template>

<!-- Incompatible SD message -->
<ng-template #incompatibleMsg>
  <cog-banner status="warn" *ngIf="isSdInCompatible$ | async">
    {{'job.viewBoxAndPolicyIncompatible' | translate}}
    <a cohHelpLink helpKeyInline="platform_replication" translate="learnMore"></a>
  </cog-banner>
</ng-template>
