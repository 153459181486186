// Component: policySynopsis

;(function(angular, undefined) {
  'use strict';

  var componentName = 'policySynopsis';
  var configOptions = {
    templateUrl: 'app/protection/policies/policy-synopsis/policy-synopsis.html',

    // Attribute bindings
    bindings: {
      /**
       * The Policy to provide a synopsis snippet for
       *
       * Default: enabled.
       *
       * @type   {boolean}
       */
      policy: '<',
    },
  };

  /**
   * @ngdoc component
   * @name C.policies:policySynopsis
   * @function
   *
   * @description
   * Provides a short summary of a policies configuration.
   *
   * @example
     <policy-synopsis policy="policy"></policy-synopsis>
   */
  angular.module('C.policies')
    .component(componentName, configOptions);

})(angular);
