import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormControl, Validators } from '@angular/forms';
import { AWSTargetConfig, DataLockConfig, ReplicationConfig } from '@cohesity/api/v2';
import { AutoDestroyable } from '@cohesity/utils';
import { ObservableInput } from 'ngx-observable-input';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PolicyUtils } from 'src/app/modules/policy-shared/protection-policy-utils';
import { TimePeriodSelectorForm, TypedFormGroup } from 'src/app/shared';
import { PolicyResponse } from 'src/app/shared/policy';

import {
  BaseTargetForm,
  GroupActionReplicationFormModel,
  ProtectionGroup,
  ReplicationType,
  RetentionUnit,
} from '../../../models';

/**
 * Group Action Replication From section populated from policy information.
 */
export class GroupActionReplicationForm
  extends TypedFormGroup<GroupActionReplicationFormModel>
  implements BaseTargetForm {
  /**
   * Replication target name.
   */
  get targetName(): string {
    return this.targetControl?.value?.clusterName;
  }

  /**
   * Replication target ID.
   */
  get targetId(): number {
    return this.targetControl?.value?.clusterId;
  }

  /**
   * Target selector control.
   */
  get targetControl(): AbstractControl {
    return this.get('target');
  }

  /**
   * Retention units like 'Week', 'Day', 'Month'.
   */
  get retentionUnit(): RetentionUnit {
    return this.get('retention')?.value?.type;
  }

  /**
   * Retention duration number.
   */
  get retentionDuration(): number {
    return this.get('retention')?.value?.value;
  }

  /**
   * Replication retention form group.
   */
  get retentionForm(): TimePeriodSelectorForm {
    return this.get('retention') as TimePeriodSelectorForm;
  }

  /**
   * True if this replication form is enabled and its values should be submitted.
   */
  get enabled(): boolean {
    return this.get('enabled')?.value;
  }

  get typeControl(): AbstractControl {
    return this.get('type');
  }

  /**
   * Replication target type like AWS, RemoteCluster
   */
  get type(): ReplicationType {
    return this.typeControl?.value;
  }

  /**
   * Source selector control.
   */
  get sourceControl(): AbstractControl {
    return this.get('source');
  }

  /**
   * Specifies the source id of the AWS protection source registered on Cohesity cluster.
   */
  awsTargetConfig: AWSTargetConfig;

  constructor(initialValue: GroupActionReplicationFormModel) {
    super({
      enabled: new UntypedFormControl(initialValue.enabled),
      onlySuccessful: new UntypedFormControl(initialValue.onlySuccessful),
      retention: new TimePeriodSelectorForm(initialValue.retention, PolicyUtils.retentionTypeOptions),
      source: new UntypedFormControl(null),
      // Target doesn't need validation for AWS as it will always have value
      target: new UntypedFormControl(initialValue.target,
        [initialValue.type === 'AWS' ? Validators.nullValidator : Validators.required]),
      type: new UntypedFormControl(initialValue.type),
    });

    this.awsTargetConfig = initialValue?.awsTargetConfig;

    this.markAsTouched();
  }
}

/**
 * Group Action Replication Form.
 */
export class GroupActionReplicationFormArray extends UntypedFormArray {
  constructor(replicationConfigs: ReplicationConfig[]) {
    super(
      replicationConfigs.map(
        replicationConfig =>
          new GroupActionReplicationForm({
            enabled: true,
            onlySuccessful: true,
            retention: {
              value: replicationConfig.retention.duration,
              type: replicationConfig.retention.unit,
            },
            target: replicationConfig.remoteTargetConfig,
            type: replicationConfig.targetType,
            awsTargetConfig: replicationConfig.awsTargetConfig,
          })
      )
    );
  }
}

/**
 * @description
 *
 * Group action modal replication form.
 */
@Component({
  selector: 'coh-group-replication-form',
  templateUrl: './group-replication-form.component.html',
  styleUrls: ['./group-replication-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupReplicationFormComponent extends AutoDestroyable implements OnInit {
  /**
   * Protection group instance.
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @ObservableInput() @Input('protectionGroup') protectionGroup$: Observable<ProtectionGroup>;

  /**
   * Protection group policy data.
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @ObservableInput() @Input('policy') policy$: Observable<PolicyResponse>;

  /**
   * Group Action Replication form group.
   */
  @Input() formGroup: GroupActionReplicationForm;

  /**
   * BehaviorSubject that stores the dataLock config of the current target.
   */
  readonly dataLockConfig$ = new BehaviorSubject<DataLockConfig>(null);

  /**
   * Specifies all the target types.
   */
  readonly targetTypes: { key: string; label: string }[] = [
    {
      key: 'RemoteCluster',
      label: 'remoteCluster',
    },
    {
      key: 'AWS',
      label: 'aws',
    },
  ];

  constructor() {
    super();
  }

  ngOnInit() {
    this.formGroup?.typeControl.valueChanges.pipe(this.untilDestroy()).subscribe(type => {
      const { targetControl, sourceControl } = this.formGroup;

      switch (type) {
        case 'AWS':
          targetControl.setValidators(null);
          sourceControl.setValidators([Validators.required]);
          break;
        case 'RemoteCluster':
          targetControl.setValidators([Validators.required]);
          sourceControl.setValidators(null);
          break;
      }

      targetControl.updateValueAndValidity();
      sourceControl.updateValueAndValidity();

      // Updating target part as it is the only one that can became without value upon changing the Type
      this.formGroup.targetControl.markAsTouched();
      this.formGroup.targetControl.markAsDirty();

      this.formGroup.updateValueAndValidity({ onlySelf: true, emitEvent: false });
    });

    combineLatest([
      this.formGroup?.targetControl.valueChanges,
      this.policy$,
    ]).pipe(
      this.untilDestroy(),
      map(([currentTarget, policy]) => {
        const replicationTargets = (policy.remoteTargetPolicy?.replicationTargets ?? []) as ReplicationConfig[];
        return replicationTargets
          .find(target => target.remoteTargetConfig?.clusterId === currentTarget?.clusterId);
      }),
    ).subscribe(target => this.dataLockConfig$.next(target?.retention?.dataLockConfig));
  }
}
