<cog-dashcard
  [title]="'patterns' | translate">
  <ng-container *ngIf="!loading; else spinner">
    <ng-container *ngTemplateOutlet="sensitiveDataCardContent"></ng-container>
  </ng-container>

  <cog-dashcard-header-aux>
    <h5 class="no-margin-bottom">
      <a [href]="reviewLink">{{ 'review' | translate }}</a>
    </h5>
  </cog-dashcard-header-aux>
</cog-dashcard>

<ng-template #sensitiveDataCardContent>
  <mat-card-content *ngIf="data; else blankCard">
    <div class="sensitive-data-and-legend donut-chart-theme margin-top-lg">
      <cog-donut-chart
        [reflowOnFirstRender]="true"
        [seriesData]="[chartData]">
        <span cogDataId="total-patterns">
          {{ totalPatterns | number }}
          <label>{{ 'patterns' | translate }}</label>
        </span>
      </cog-donut-chart>

      <cog-statlist [vertical]="true">
        <cog-statlist-item cogDataId="sensitive-data-legend-{{legend.label}}"
          *ngFor="let legend of chartLegends; index as i;"
          [label]="legend.label"
          labelLegend="highcharts-color-{{i}}">
          {{ legend.value | number }}
        </cog-statlist-item>
      </cog-statlist>
    </div>
  </mat-card-content>
</ng-template>

<ng-template #blankCard>
  <cog-blank-card size="sm"></cog-blank-card>
</ng-template>

<ng-template #spinner>
  <cog-spinner></cog-spinner>
</ng-template>
