import { Injectable } from '@angular/core';
import { OneHeliosServiceApi, PlatformServiceApi } from '@cohesity/api/v2';
import { BANNER_PRIORITY, BannerConfig } from '@cohesity/helix';
import { BaseBannerConfig, IrisContext, IrisContextService, isOneHeliosAppliance } from '@cohesity/iris-core';
import { TranslateService } from '@ngx-translate/core';
import { StateService } from '@uirouter/core';
import { forkJoin, of } from 'rxjs';
import { catchError, map, shareReplay, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class OneHeliosSetupBannerConfig extends BaseBannerConfig {
  /**
   * Unique banner representation
   */
  id = 'one-helios';

  /**
   * Provides banner configuration as an observable
   */
  bannerInfo$ = this.irisCtx.irisContext$.pipe(
    switchMap(ctx => this.isOneHeliosSetupFailed(ctx)),
    map(failedConfig => [
      {
        id: this.id,
        priority: BANNER_PRIORITY.HIGH,
        isVisible: failedConfig.isHeliosInstallationFailed || failedConfig.isKubernetesUnhealthy,
        status: 'critical',
        allowClose: false,
        text: failedConfig.isKubernetesUnhealthy
          ? this.translate.instant('applianceManager.clusterSetup.setup.k8s.failed.description1')
          : failedConfig.isHeliosInstallationFailed
          ? this.translate.instant('applianceManager.clusterSetup.setup.helios.failed.description1')
          : '',
        actionText: this.translate.instant('viewDetails'),
        closeCallback: this.closeCallback,
        actionCallback: this.gotoHeliosSetupPage.bind(this),
      } as BannerConfig,
    ])
  );

  constructor(
    private irisCtx: IrisContextService,
    private oneHeliosService: OneHeliosServiceApi,
    private platformServiceApi: PlatformServiceApi,
    private stateService: StateService,
    private translate: TranslateService
  ) {
    super();
  }

  /**
   * Determines if the banner should be allowed based on the context
   *
   * @param ctx - Iris context
   * @returns True if the banner should be allowed, false otherwise
   */
  private allowBanner(ctx: IrisContext): boolean {
    return isOneHeliosAppliance(ctx) && this.isLoggedIn(ctx) && ctx.privs.CLUSTER_VIEW;
  }

  /**
   * Determines if the setup for Helios and Kubernetes is failed
   *
   * @returns An observable that emits the failed setup conditions
   */
  private isOneHeliosSetupFailed(irisContext: IrisContext) {
    // Define observables for fetching Helios installation logs and Kubernetes health status.
    const fetchHeliosInstallLogs$ = this.allowBanner(irisContext)
      ? this.oneHeliosService.InstallLogs().pipe(catchError(() => of({ heliosInstallStatus: 'Failed' })))
      : of(null);

    const fetchK8sHealthStatus$ = this.allowBanner(irisContext)
      ? this.platformServiceApi
          .GetKubernetesInfraHealthStatus()
          .pipe(catchError(() => of({ overallK8SState: 'Initializing', healthStatus: 'Error' })))
      : of(null);

    return forkJoin({
      heliosInstallationLogs: fetchHeliosInstallLogs$,
      kubernetesHealthStatus: fetchK8sHealthStatus$,
    }).pipe(
      map(({ heliosInstallationLogs, kubernetesHealthStatus }) => {
        // Determine if the Helios installation has failed.
        const isHeliosInstallationFailed = heliosInstallationLogs?.heliosInstallStatus === 'Failed';

        // Determine if Kubernetes setup has failed.
        const isKubernetesUnhealthy =
          kubernetesHealthStatus?.overallK8SState === 'Initializing' &&
          kubernetesHealthStatus?.healthStatus === 'Error';

        return {
          isHeliosInstallationFailed,
          isKubernetesUnhealthy,
        };
      }),
      shareReplay(1)
    );
  }

  /**
   * Redirects to helios setup page.
   */
  private gotoHeliosSetupPage() {
    this.stateService.go('appliance-manager-setup');
  }
}
