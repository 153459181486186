// Angular router tree command type.
type CreateUrlTreeCommandsType = any[];

// The return value type for get link fn.
type GetLinkFnReturnType = CreateUrlTreeCommandsType|string|null|undefined;

/**
 * The get link fn type.
 */
export type GetLinkFn = (...args: any) => GetLinkFnReturnType;

/**
 * The get link params type.
 */
export type GetLinkParamsFn = (...args: any) => any;

/**
 * Return the empty get link fn.
 *
 * @returns Return null as empty link value.
 */
export const emptyLinkFn: GetLinkFn = () => null;

/**
 * Return the empty get links params fn.
 *
 * @returns Return null as empty link params.
 */
export const emptyLinkParamsFn: GetLinkParamsFn = () => null;

/**
 * Return get link fn which will return the passed value.
 *
 * @param value The get link return value.
 * @returns The get link fn returning the input return value.
 */
export const simpleLinkFn = (value: GetLinkFnReturnType): GetLinkFn => () => value;

/**
 * Nav params types can be of any type.
 */
export interface NavParams {
  [key: string]: any;
}

/**
 * Currently supported options values.
 */
export interface NavOptions {
  reload?: boolean;
  notify?: boolean;
}
