// Component: c-filters-actions.

;(function(angular, undefined) {
  'use strict';

  angular.module('C.cFilters')
    .component('cFiltersActions', {
      require: {
        // find c-filters controller who is exposing utility fn used to perform
        // bulk clear, apply or abort filter operations.
        cFilters: '^^cFilters',
      },
      templateUrl: 'app/global/c-filters/c-filters-actions.html',
    });

})(angular);
