<cog-icon [ngClass]="iconSize ? 'no-data icon-size-' + iconSize : 'no-data'" [shape]="type"></cog-icon>

<ng-container *ngIf="showMessage">
  <h2 *ngIf="size === 'sm'">{{ message }}</h2>
  <h1 *ngIf="size === 'md'">{{ message }}</h1>
  <h1 *ngIf="size === 'lg'" class="lg-title">{{ message }}</h1>
</ng-container>

<p *ngIf="subMessage" class="sub-message cog-body-lg">{{ subMessage }}</p>
<ng-content></ng-content>
