import { Injectable } from '@angular/core';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';
import { Transition } from '@uirouter/core';

import { McmViewService } from '../../services';
import { GuardPriority, GuardResult, StateGuard } from '../state-guard';

/**
 * Sources Guard redirects all state traffic to the new source page based on the
 * Feature flag ngSourceEnabled.
 */
@Injectable({
  providedIn: 'root'
})
export class SourcesGuard implements StateGuard {
  /**
   * This guard priority is set to App, which indicates it should run as part of the main application
   * after all bootstrapping and initializing is completed.
   */
  guardPriority = GuardPriority.App;

  /**
   * Match all navigation to sources-new.
   */
  matchCriteria = {
    to: 'sources-new',
  };

  /**
   * Constructor.
   */
  constructor(private irisCtx: IrisContextService, readonly mcmView: McmViewService) {}

  /**
   * Handles the redirection to the new source page based on feature flag.
   * This will help with deeplinked states with the old and new sources page.
   *
   * @param    transition   The UI Router Transition.
   * @returns  A redirect transition if new sources page is enabled.
   */
  onStart(transition: Transition): GuardResult {
    const params = transition.params();
    if (flagEnabled(this.irisCtx.irisContext, 'ngSourceEnabled') &&
      this.mcmView.canAccessState('sources-ng')) {
      return transition.router.stateService.target('sources-ng', params);
    }
  }
}
