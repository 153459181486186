import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MenuOption } from '@cohesity/helix';
import { Granularity, Timeframe } from '@cohesity/utils';
import { TranslateService } from '@ngx-translate/core';
import { find } from 'lodash';
import { Subject } from 'rxjs';

@Component({
  selector: 'coh-timeframe-selector',
  templateUrl: './timeframe-selector.component.html',
})
export class TimeframeSelectorComponent implements OnInit {

  /**
   * The currently selected option.
   */
  private _value: Timeframe = 'kDay';

  /**
   * The options available for selection.
   */
  readonly defaultOptions: Timeframe[] = [
    'kDay',
    'kWeek',
    'kMonth',
    'kQuarter'
  ];

  /**
   * All selector options with kValues and labels.
   */
  selectorOptions: MenuOption[] = [];

  /**
   * All options for timeframe selector.
   * If no option is provided as input, use default options.
   */
  @Input() options = this.defaultOptions;

  /**
   * The setter for value input/output.
   */
  @Input()
  set value(newValue: Timeframe) {
    if (typeof newValue === 'undefined') {
      this.valueChange.next(this._value);
    } else if (newValue !== this._value) {
      this._value = newValue;
      this.valueChange.next(newValue);
    }
  }

  /**
   * The getter for value input/output.
   */
  get value(): Timeframe {
    return this._value;
  }

  /**
   * Optionally pass arrowNavigation if the timeframe should be controlled with
   * arrows as well.
   *
   * NOTE - 'shiftTime' fn would also be needed which would get called on click.
   */
  @Input() arrowNavigation = false;

  /**
   * Gets the selected option.
   */
  get selectedOption() {
    return find(this.selectorOptions, { value: this.value});
  }

  /**
   * Function which runs when the arrow navigation buttons are clicked.
   */
  @Output() shiftTime = new EventEmitter<boolean>();

  /**
   * Updates the output value.
   */
  @Output() valueChange = new Subject<Timeframe>();

  constructor(private translate: TranslateService) { }

  /**
   * Init component.
   */
  ngOnInit() {
    this.selectorOptions = this.options.map(option => ({
      value: option,
      label: this.arrowNavigation ? Granularity[option] :
        this.translate.instant(`enum.timeframe.${option}`)
    }));
  }
}
