import { Injectable } from '@angular/core';
import { Transition } from '@uirouter/core';

import { StateManagementService } from '../../services';
import { GuardPriority, GuardResult, StateGuard } from '../state-guard';

/**
 * SSO Guard redirects all state traffic to the new SSO page based on the
 * Feature flag ngSSO.
 */
@Injectable({
  providedIn: 'root',
})
export class SSOGuard implements StateGuard {
  /**
   * This guard priority is set to App, which indicates it should run as part of the main application
   * after all bootstrapping and initializing is completed.
   */
  guardPriority = GuardPriority.App;

  /**
   * Match old state to access-management.ng-sso.
   */
  matchCriteria = {
    to: 'access-management.sso',
  };

  /**
   * Constructor.
   */
  constructor(readonly stateManagementService: StateManagementService) {}

  /**
   * Handles the redirection to the ng sso page based on feature flag.
   * This will help with deeplinked states with the old and new sso page.
   *
   * @param    transition   The UI Router Transition.
   * @returns  A redirect transition if new sso page is enabled.
   */
  onStart(transition: Transition): GuardResult {
    const params = transition.params();
    if (this.stateManagementService.canUserAccessState('access-management.ng-sso', params, true)) {
      return transition.router.stateService.target('access-management.ng-sso', params);
    }
  }
}
