/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AccountId } from '../models/account-id';
import { ExecuteRuleParams } from '../models/execute-rule-params';
import { ExecuteRuleResp } from '../models/execute-rule-resp';
import { GetRulesResp } from '../models/get-rules-resp';
import { IsEnabled } from '../models/is-enabled';
import { Rule } from '../models/rule';
import { RuleTypeList } from '../models/rule-type-list';
import { RuleUuid } from '../models/rule-uuid';
import { RuleUuidList } from '../models/rule-uuid-list';
import { TenantId } from '../models/tenant-id';
import { UpdateRuleParams } from '../models/update-rule-params';

@Injectable({
  providedIn: 'root',
})
export class HeliosRulesApiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation listRulesOp
   */
  static readonly ListRulesOpPath = '/rules';

  /**
   * List the rules.
   *
   * Returns a list of rules based on the defined filter criteria.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listRulesOp()` instead.
   *
   * This method doesn't expect any request body.
   */
  listRulesOp$Response(params?: {

    /**
     * Sf Account Id
     */
    account_id?: AccountId;

    /**
     * Should only be passed in case of DMaas. Should be format &lt;CloudOpAccountID&gt;:&lt;TenantID&gt; Warning please passed in case of DMaas account only.
     */
    tenant_id?: TenantId;

    /**
     * Specifies rule uuids to filter the rules.
     */
    ruleUuids?: RuleUuidList;

    /**
     * Specifies rule types to filter the rules.
     */
    ruleTypes?: RuleTypeList;

    /**
     * Specifies enabled/disabled to filter the rules.
     */
    isEnabled?: IsEnabled;

  }): Observable<StrictHttpResponse<GetRulesResp>> {

    const rb = new RequestBuilder(this.rootUrl, HeliosRulesApiService.ListRulesOpPath, 'get');
    if (params) {

      rb.header('account_id', params.account_id, {});
      rb.header('tenant_id', params.tenant_id, {});
      rb.query('ruleUuids', params.ruleUuids, {});
      rb.query('ruleTypes', params.ruleTypes, {});
      rb.query('isEnabled', params.isEnabled, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetRulesResp>;
      })
    );
  }

  /**
   * List the rules.
   *
   * Returns a list of rules based on the defined filter criteria.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listRulesOp$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listRulesOp(params?: {

    /**
     * Sf Account Id
     */
    account_id?: AccountId;

    /**
     * Should only be passed in case of DMaas. Should be format &lt;CloudOpAccountID&gt;:&lt;TenantID&gt; Warning please passed in case of DMaas account only.
     */
    tenant_id?: TenantId;

    /**
     * Specifies rule uuids to filter the rules.
     */
    ruleUuids?: RuleUuidList;

    /**
     * Specifies rule types to filter the rules.
     */
    ruleTypes?: RuleTypeList;

    /**
     * Specifies enabled/disabled to filter the rules.
     */
    isEnabled?: IsEnabled;

  }): Observable<GetRulesResp> {

    return this.listRulesOp$Response(params).pipe(
      map((r: StrictHttpResponse<GetRulesResp>) => r.body as GetRulesResp)
    );
  }

  /**
   * Path part for operation createRuleOp
   */
  static readonly CreateRuleOpPath = '/rules';

  /**
   * Creates a rule.
   *
   * Creates a new rule. These rules are specified by rule types and for each rule type we have a specific handler. By using the /rules/execute api, these rules can be evaluated for the given data.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createRuleOp()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createRuleOp$Response(params: {

    /**
     * Sf Account Id
     */
    account_id?: AccountId;

    /**
     * Should only be passed in case of DMaas. Should be format &lt;CloudOpAccountID&gt;:&lt;TenantID&gt; Warning please passed in case of DMaas account only.
     */
    tenant_id?: TenantId;
      body: Rule
  }): Observable<StrictHttpResponse<Rule>> {

    const rb = new RequestBuilder(this.rootUrl, HeliosRulesApiService.CreateRuleOpPath, 'post');
    if (params) {

      rb.header('account_id', params.account_id, {});
      rb.header('tenant_id', params.tenant_id, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Rule>;
      })
    );
  }

  /**
   * Creates a rule.
   *
   * Creates a new rule. These rules are specified by rule types and for each rule type we have a specific handler. By using the /rules/execute api, these rules can be evaluated for the given data.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createRuleOp$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createRuleOp(params: {

    /**
     * Sf Account Id
     */
    account_id?: AccountId;

    /**
     * Should only be passed in case of DMaas. Should be format &lt;CloudOpAccountID&gt;:&lt;TenantID&gt; Warning please passed in case of DMaas account only.
     */
    tenant_id?: TenantId;
      body: Rule
  }): Observable<Rule> {

    return this.createRuleOp$Response(params).pipe(
      map((r: StrictHttpResponse<Rule>) => r.body as Rule)
    );
  }

  /**
   * Path part for operation updateRuleByUuidOp
   */
  static readonly UpdateRuleByUuidOpPath = '/rules/{uuid}';

  /**
   * Update a rule by uuid.
   *
   * Update a rule by uuid.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateRuleByUuidOp()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateRuleByUuidOp$Response(params: {

    /**
     * Sf Account Id
     */
    account_id?: AccountId;

    /**
     * Should only be passed in case of DMaas. Should be format &lt;CloudOpAccountID&gt;:&lt;TenantID&gt; Warning please passed in case of DMaas account only.
     */
    tenant_id?: TenantId;

    /**
     * Specifies the uuid of the rule.
     */
    uuid: RuleUuid;
      body: UpdateRuleParams
  }): Observable<StrictHttpResponse<Rule>> {

    const rb = new RequestBuilder(this.rootUrl, HeliosRulesApiService.UpdateRuleByUuidOpPath, 'put');
    if (params) {

      rb.header('account_id', params.account_id, {});
      rb.header('tenant_id', params.tenant_id, {});
      rb.path('uuid', params.uuid, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Rule>;
      })
    );
  }

  /**
   * Update a rule by uuid.
   *
   * Update a rule by uuid.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateRuleByUuidOp$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateRuleByUuidOp(params: {

    /**
     * Sf Account Id
     */
    account_id?: AccountId;

    /**
     * Should only be passed in case of DMaas. Should be format &lt;CloudOpAccountID&gt;:&lt;TenantID&gt; Warning please passed in case of DMaas account only.
     */
    tenant_id?: TenantId;

    /**
     * Specifies the uuid of the rule.
     */
    uuid: RuleUuid;
      body: UpdateRuleParams
  }): Observable<Rule> {

    return this.updateRuleByUuidOp$Response(params).pipe(
      map((r: StrictHttpResponse<Rule>) => r.body as Rule)
    );
  }

  /**
   * Path part for operation deleteRuleByUuidOp
   */
  static readonly DeleteRuleByUuidOpPath = '/rules/{uuid}';

  /**
   * Delete a tag by uuid.
   *
   * Delete a rule by Uuid.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteRuleByUuidOp()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteRuleByUuidOp$Response(params: {

    /**
     * Sf Account Id
     */
    account_id?: AccountId;

    /**
     * Should only be passed in case of DMaas. Should be format &lt;CloudOpAccountID&gt;:&lt;TenantID&gt; Warning please passed in case of DMaas account only.
     */
    tenant_id?: TenantId;

    /**
     * Specifies the uuid of the rule.
     */
    uuid: RuleUuid;

  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, HeliosRulesApiService.DeleteRuleByUuidOpPath, 'delete');
    if (params) {

      rb.header('account_id', params.account_id, {});
      rb.header('tenant_id', params.tenant_id, {});
      rb.path('uuid', params.uuid, {});

    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete a tag by uuid.
   *
   * Delete a rule by Uuid.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteRuleByUuidOp$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteRuleByUuidOp(params: {

    /**
     * Sf Account Id
     */
    account_id?: AccountId;

    /**
     * Should only be passed in case of DMaas. Should be format &lt;CloudOpAccountID&gt;:&lt;TenantID&gt; Warning please passed in case of DMaas account only.
     */
    tenant_id?: TenantId;

    /**
     * Specifies the uuid of the rule.
     */
    uuid: RuleUuid;

  }): Observable<void> {

    return this.deleteRuleByUuidOp$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation executeRuleOp
   */
  static readonly ExecuteRuleOpPath = '/rules/execute';

  /**
   * Executes rule.
   *
   * Execute rule specified by the rule type. Evaluates the rule on the given data and takes the required action.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `executeRuleOp()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  executeRuleOp$Response(params: {

    /**
     * Sf Account Id
     */
    account_id?: AccountId;

    /**
     * Should only be passed in case of DMaas. Should be format &lt;CloudOpAccountID&gt;:&lt;TenantID&gt; Warning please passed in case of DMaas account only.
     */
    tenant_id?: TenantId;
      body: ExecuteRuleParams
  }): Observable<StrictHttpResponse<ExecuteRuleResp>> {

    const rb = new RequestBuilder(this.rootUrl, HeliosRulesApiService.ExecuteRuleOpPath, 'post');
    if (params) {

      rb.header('account_id', params.account_id, {});
      rb.header('tenant_id', params.tenant_id, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExecuteRuleResp>;
      })
    );
  }

  /**
   * Executes rule.
   *
   * Execute rule specified by the rule type. Evaluates the rule on the given data and takes the required action.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `executeRuleOp$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  executeRuleOp(params: {

    /**
     * Sf Account Id
     */
    account_id?: AccountId;

    /**
     * Should only be passed in case of DMaas. Should be format &lt;CloudOpAccountID&gt;:&lt;TenantID&gt; Warning please passed in case of DMaas account only.
     */
    tenant_id?: TenantId;
      body: ExecuteRuleParams
  }): Observable<ExecuteRuleResp> {

    return this.executeRuleOp$Response(params).pipe(
      map((r: StrictHttpResponse<ExecuteRuleResp>) => r.body as ExecuteRuleResp)
    );
  }

}
