<div class='flex-row'>
  <mat-form-field class="filter-input-field" floatLabel="never">
    <!-- Use a simple input for single select recoveries -->
    <input
      *ngIf="!multiItem"
      id="input-search-query"
      type="text"
      matInput
      [placeholder]="placeholder"
      [formControl]="searchControl">

      <!-- Use a mat chip list input for multiple select searches -->
    <mat-chip-list #chipList *ngIf="multiItem">
      <mat-chip
        *ngFor="let item of selectedItems"
        [selectable]="true"
        [removable]="true"
        (removed)="removeSelectedItem.emit(item)">
        <ng-container *ngTemplateOutlet="itemChipTemplate || defaultChipTemplate; context: {$implicit: item}">
        </ng-container>
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
      <input
        id="input-search-query"
        type="text"
        matInput
        [matChipInputFor]="chipList"
        [placeholder]="placeholder"
        [formControl]="searchControl">
    </mat-chip-list>
    <cog-spinner size="xs" matSuffix *ngIf="searchLoading"></cog-spinner>
  </mat-form-field>
  <button
    id="recovery-items"
    class="margin-left-sm"
    type="button"
    mat-raised-button
    *ngIf="multiItem"
    color="primary"
    (click)="recoverItems.emit(selectedItems)"
    [disabled]="!selectedItems.length">
    {{recoverButtonLabel}}
  </button>
  <button
    id="show-filters"
    class="margin-left-sm show-filters"
    mat-icon-button
    (click)="showAddFilters = !showAddFilters">
    <cog-icon shape="filter_list"></cog-icon>
  </button>
</div>

<ajs-search
  (collectionChange)="setSearchResults($event)"
  [(dataLoading)]="searchLoading"
  [endpoint]="endpoint"
  [filterOptions]="filterLookups"
  [filters]="filters"
  [hideInput]="true"
  [preProcessResultsFn]="preProcessResultsFn"
  [searchPublicApi]="searchPublicApi"
  [searchQuery]="searchQuery$ | async"
  [searchType]="searchType"
  [showAddFilters]="showAddFilters"
  [searchId]="searchId">
</ajs-search>

<div class="no-results" translate *ngIf="isEmpty && !searchLoading">noResultsFound</div>

<ng-template #defaultChipTemplate let-item>{{item}}</ng-template>