import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { MatLegacyMenuPanel as MatMenuPanel } from '@angular/material/legacy-menu';

import { HelixIntlService } from '../../../../helix-intl.service';

/**
 * @description
 * Button used for quick filters.
 * This component should not be used directly and will be used automatically by `FiltersComponent`.
 */
@Component({
  selector: 'cog-quick-filter-button',
  templateUrl: './quick-filter-button.component.html',
  styleUrls: ['./quick-filter-button.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class QuickFilterButtonComponent {
  /**
   * Button label.
   */
  @Input() label: string;

  /**
   * Optional - allow the parent to pass in more information
   * for the aria label.  Like the menu item and the selected item
   * example: "Group Type - Acropolis - Selected"
   */
  @Input() ariaLabel: string;

  /**
   * Selected state of this button.
   */
  @Input() isSelected = false;

  /**
   * Whether the filter is locked.
   */
  @Input() locked = false;

  /**
   * Whether to show the clear icon.
   */
  @Input() noClear = false;

  /**
   * Apply form style class for quick filter button.
   */
  @Input() formStyle = false;

  /**
   * Whether the associated menu with the quick filter is opened.
   */
  @Input() isMenuOpen = false;

  /**
   * Indicates if this filter is a flyout menu.
   */
  @HostBinding('class.quick-filter-is-menu')
  get isMenu(): boolean {
    return true;
  }

  /**
   * This MatMenuPanel property is accessible when this is an internally
   * managed menu.  Allows us to add the panelId that this button triggers in
   * the aria-controls attribute.
   */
  @Input() matMenuTriggerFor: MatMenuPanel;

  /**
   * This property is accessible when this is an externally (selfManaged) menu.
   * In this case the parent is responsible to pass in the menu panel id
   * associated with this trigger. This allows us to set the aria-controls attribute.
   */
  @Input()
  selfManagedMenuPanelId: string;

  /**
   * Emits event when clear button is clicked in selected state.
   */
  @Output() clearClicked = new EventEmitter();

  constructor(public intl: HelixIntlService) {}

  /**
   * Handles clear button click event when button `isSelected` state is truthy.
   */
  clear(event: MouseEvent) {
    // If no clear is set, exit early and allow the click to propogate through
    // so that the options panel gets displayed.
    if (this.noClear) {
      return;
    }

    if (this.isSelected) {
      event.stopImmediatePropagation();
      if (!this.locked) {
        this.clearClicked.emit();
      }
    }
  }
}
