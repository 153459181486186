import { Component, Input } from '@angular/core';
import { MongoDBConnectParams, ProtectionSourceTreeInfo } from '@cohesity/api/v1';
import { get } from 'lodash';

/**
 * MongoDB source details settings components. Used for displaying the
 * registered MongoDB source's configuration values.
 */
@Component({
  selector: 'coh-mongodb-source-details-settings',
  templateUrl: './source-details-settings.component.html'
})
export class MongoDBSourceDetailsSettingsComponent {

  /**
   * Input source.
   */
  @Input() source: ProtectionSourceTreeInfo;

  /**
   * Getter for retrieving MongoDBParams from source input.
   */
  get mongodbParams(): MongoDBConnectParams {
    return get(this.source, 'registrationInfo.mongodbParams') as MongoDBConnectParams;
  }
}
