import { AAGGroup, AgentInformation, AppResource, MssqlConnectionResponseParams, ResourceEndpoint } from '@cohesity/api/v2';
import { DataTreeNode } from '@cohesity/helix';

/**
 * Interface for SQL source connection
 */
export interface SqlSourceConnection extends MssqlConnectionResponseParams {
  /**
   * List of AAG groups discovered.
   */
  aagGroups: AAGGroup[];

  /**
   * Error message if any.
   */
  error?: { errorCode: string; message: string };
}

/**
 * Enum for SQL Node Type.
 */
export enum SqlNodeType {
  Aag = 'aag',
  Fci = 'fci',
  Server = 'server',
}

/**
 * Enum for SQL Agent Connection status.
 */
export enum SqlAgentStatus {
  Healthy = 'Healthy',
  Unhealthy = 'Unhealthy',
  Unreachable = 'Unreachable',
  Unregistered = 'Unregistered',
  Unknown = 'Unknown',
  Error = 'Error',
}

/**
 * Map sql node type to icon.
 */
const iconTypes = {
  aag: 'helix:object-multi-db',
  fci: 'helix:object-cluster',
  server: 'helix:object-windows',
};

/**
 * Decorates discovered sql node with properties for SQL application discovery.
 */
export class SqlNode implements DataTreeNode<any> {

  /**
   * Node is always selectable.
   */
  isSelectable = true;

  /**
   * Gets the name of current node or fqdn or ip if available.
   */
  get name() {
    return this.data.name || this.fqdn || this.ip_single;
  }

  /**
   * Gets current node type.
   */
  get type(): SqlNodeType {
    return this.data.type || SqlNodeType.Server;
  }

  /**
   * Gets icon based on type.
   */
  get typeIcon() {
    return iconTypes[this.type];
  }

  /**
   * Gets expandable status of current node.
   * Only AAG node is expandable.
   */
  get expandable(): boolean {
    return (!!this.data.servers || !!this.data.fciClusters) && this.type === SqlNodeType.Aag;
  }

  /**
   * Gets id of current node.
   */
  get id() {
    return this.preferredEndpoint?.preferredAddress || this.fqdn || this.name;
  }

  /**
   * Gets array of id. Required by DataTreeNode.
   */
  get ids() {
    return [this.id];
  }

  /**
   * Returns resource information.
   */
  get resourceInfo(): AppResource {
    return this.data?.resourceInfo;
  }

  /**
   * Returns agent information.
   */
  get agentInfo(): AgentInformation {
    return this.data?.agentInfo;
  }

  /**
   * Returns all resource endpoints.
   */
  get endpoints(): ResourceEndpoint[] {
    return this.resourceInfo?.endpoints;
  }

  /**
   * Returns the preferred endpoint information.
   * The preferred endpoint has the isPreferredEndpoint
   * boolean set, or failing that, the first endpoint in
   * the list.
   */
  get preferredEndpoint(): ResourceEndpoint {
    let index = 0;

    if (this.endpoints?.length > 0) {
      for (let i = 0; i < this.endpoints?.length; i++) {
        if (this.endpoints[i].isPreferredEndpoint) {
          index = i;
          break;
        }
      }
    }
    return this.endpoints?.[index];
  }

  /**
   * Returns fqdn.
   */
  get fqdn(): string {
    if (!this.endpoints) {
      return '';
    }
    let myFqdn = this.preferredEndpoint?.fqdn;
    // Try to get the preferred FQDN but ensure we have
    // any non-empty fqdn to avoid ugly UX.
    for (let i = 0; (i < this.endpoints?.length && myFqdn?.length === 0); i++) {
      if (this.endpoints[i].fqdn?.length > 0) {
        myFqdn = this.endpoints[i].fqdn;
      }
    }
    return myFqdn;
  }

  /**
   * Returns a single ip address.
   */
  get ip_single(): string {
    if (!this.endpoints) {
      return '';
    }
    let myIP = this.preferredEndpoint?.ipv4addr;
    // Try to get the preferred ipv4addr but ensure we have
    // any non-empty ipv4addr to avoid ugly UX.
    for (let i = 0; (i < this.endpoints?.length && myIP?.length === 0); i++) {
      myIP = this.endpoints[i].ipv4addr;
    }
    return myIP;
  }

  /**
   * Returns ip addresses with comma delimiter.
   */
  get ip(): string {
    if (!this.endpoints || !this.endpoints?.length) {
      return '';
    }

    const addresses = new Set<string>();
    for (let i = 0; i < this.endpoints?.length; i++) {
      if (this.endpoints[i].fqdn?.length > 0) {
        addresses.add(this.endpoints[i].fqdn);
      }
      if (this.endpoints[i].ipv4addr?.length > 0) {
        addresses.add(this.endpoints[i].ipv4addr);
      }
      if (this.endpoints[i].ipv6addr?.length > 0) {
        addresses.add(this.endpoints[i].ipv6addr);
      }
    }

    return Array.from(addresses).join(', ');
  }

  /**
   * Returns number of child nodes.
   * Some advanced configurations may have AAG over FCI, therefore will need to
   * include both the servers plus the fciClusters inside the AAG group.
   */
  get childrenCount(): number {
    return (this.data.servers?.length || 0) + (this.data.fciClusters?.length || 0);
  }

  /**
   * Returns true if the current node should be default selected.
   */
  get isSelectedByDefault(): boolean {
    return !!this.data.isSelectedByDefault;
  }

  /**
   * Returns true if the current node is the first of type.
   * This is used to display a header for each node type (fci, aag, servers).
   */
  get isFirstOfType(): boolean {
    return this.level === 0 && this.data.isFirstOfType;
  }

  /**
   * Returns the error message of the current node.
   */
  get error(): string | null {
    return this.data.error;
  }

  /**
   * Whether to select all children. Only used for FCI node.
   */
  _allChildrenSelected = false;

  /**
   * Returns whether all children of FCI node are selected for registration.
   */
  get allChildrenSelected(): boolean {
    return this._allChildrenSelected;
  }

  /**
   * Sets _allChildrenSelected for FCI node.
   */
  set allChildrenSelected(selected: boolean) {
    this._allChildrenSelected = selected;
  }

  /**
   * Constructor.
   */
  constructor(readonly data: any, readonly level: number) { }

  /**
   * Returns true if current node is not of server type or has unregistered status.
   */
  canSelect(): boolean {
    return this.type !== SqlNodeType.Server ||
      this.agentInfo?.connectionStatus === SqlAgentStatus.Unregistered;
  }

  /**
   * Not applicable for Sql node.
   */
  canAutoSelect(): boolean {
    return false;
  }

  /**
   * Not applicable for Sql node.
   */
  canExclude(): boolean {
    return false;
  }
}
