import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { diffJson } from 'diff';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { HelixIntlService } from '../../helix-intl.service';
import type { Change } from 'diff';

/**
 * Enum for classNames
 */
enum ClassName {
  ADDED_LINE = 'added-line',
  HIDE_LINE = 'hide-line',
  REMOVED_LINE = 'removed-line',
  UNCHANGED_LINE = 'unchanged-line',
}

@Component({
  selector: 'cog-text-diff',
  styleUrls: ['./text-diff.component.scss'],
  templateUrl: './text-diff.component.html',
  standalone: true,
  imports: [CommonModule, MatCheckboxModule, ReactiveFormsModule],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextDiffComponent implements OnInit {
  /**
   * The original text for comparison.
   */
  @Input() originalText = '';

  /**
   * The modified text for comparison.
   */
  @Input() modifiedText = '';

  /**
   * Specifies the display mode for showing changes.
   */
  @Input() displayMode: 'original' | 'modified' | 'default' = 'default';

  /**
   * Specifies whether to display the diff in side-by-side view.
   */
  @Input() sideBySide = true;

  /**
   * Enables or disables the filtering of changes to display only the changed lines.
   */
  @Input() enableFiltering = false;

  /**
   * Label for the checkbox filter.
   */
  @Input() filterLabel = this.intlService['onlyShowLinesWithDifferences'];

  /**
   * Specifies whether to remove borders and spacing from the code-container class.
   */
  @Input() removeBorderAndSpacing = false;

  /**
   * FormControl for managing the state of the checkbox, initialized with a value of false.
   */
  checkboxControl = new FormControl(false);

  /**
   * BehaviorSubject contains changes between the original and modified text.
   */
  changes$ = new BehaviorSubject<Change[]>([]);

  /**
   * Observable of changes between the original and modified text.
   */
  visibleData$ = combineLatest([this.changes$, this.checkboxControl.valueChanges.pipe(startWith(false))]).pipe(
    map(([changes, displayOnlyChangedLines]) => this.getDataToDisplay(changes, displayOnlyChangedLines))
  );

  constructor(public intlService: HelixIntlService) {}

  ngOnInit(): void {
    // Filter changes based on displayMode options.
    this.filterChangesBasedOnDisplayMode();

    // If either 'originalText' or 'modifiedText' is falsy (undefined, empty string, etc.),
    // set 'sideBySide' to false, indicating that the side-by-side view is not applicable.
    if (!this.originalText || !this.modifiedText) {
      this.sideBySide = false;
    }
  }

  /**
   * Filters changes based on the displayMode option.
   */
  private filterChangesBasedOnDisplayMode(): void {
    const diffData = diffJson(this.jsonBeautify(this.originalText), this.jsonBeautify(this.modifiedText));

    if (this.displayMode === 'original') {
      this.changes$.next(diffData.filter(change => !change.added || change.removed));
    } else if (this.displayMode === 'modified') {
      this.changes$.next(diffData.filter(change => !change.removed || change.added));
    } else {
      this.changes$.next(diffData);
    }
  }

  /**
   * jsonBeautify takes a JSON string as input and returns a properly formatted JSON string.
   *
   * @param jsonString - The JSON string to be formatted.
   * @returns string -  A properly formatted JSON string.
   */
  jsonBeautify(jsonString: string): string {
    try {
      const jsObject = JSON.parse(jsonString);
      return JSON.stringify(jsObject, null, 6);
    } catch (error) {
      // If jsonString is undefined, return an empty string as fallback value.
      return jsonString ?? '';
    }
  }

  /**
   * Returns the CSS class for a given diff item.
   *
   * @param line - The diff object.
   * @param column - Whether we are looking for left view class.
   * @returns The CSS class for the diff object.
   */
  getClass(line: Change, column: 'left' | 'right' = 'right'): string {
    // If the line is unchanged, return the UNCHANGED_LINE class.
    if (!line.added && !line.removed) {
      return ClassName.UNCHANGED_LINE;
    }

    // Determine whether the line is removed or not.
    const isRemoved =
      // If side-by-side view is true and the line is removed in left column,
      // or side-by-side view is false and the line is removed.
      (line.removed && this.sideBySide && column === 'left') || (line.removed && !this.sideBySide);

    // Determine whether the line is added or not.
    const isAdded =
      // If side-by-side view is true and the line is added in right column,
      // or side-by-side view is false and the line is added.
      (line.added && this.sideBySide && column === 'right') || (line.added && !this.sideBySide);

    // Return the REMOVED_LINE class if the line is removed,
    // or the ADDED_LINE class if the line is added,
    // or the UNCHANGED_LINE and HIDE_LINE class if the line is unchanged.
    return isRemoved
      ? ClassName.REMOVED_LINE
      : isAdded
        ? ClassName.ADDED_LINE
        : `${ClassName.UNCHANGED_LINE} ${ClassName.HIDE_LINE}`;
  }

  /**
   * Retrieves data to display based on whether to display only changed lines or all lines.
   *
   * @param displayOnlyChangedLines A boolean indicating whether to display only changed lines.
   * @returns An array of Change objects based on the specified condition.
   */
  getDataToDisplay(changes: Change[], displayOnlyChangedLines: boolean): Change[] {
    return displayOnlyChangedLines ? changes.filter(change => change.added || change.removed) : changes;
  }
}
