import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { DataTreeNodeContext, DataTreeNodeDetail } from '@cohesity/helix';
import { IrisContextService, isDmsScope, flagEnabled } from '@cohesity/iris-core';
import { Environment, NasModes } from 'src/app/shared/constants';
import { netappExtendedStyle } from 'src/app/shared/source-tree/protection-source/nas/nas.constants';

import { ProtectionSourceDataNode } from '../protection-source-data-node';
import { SourceNodeMetadata } from './source-node-metadata';

/**
 * This renderes metadata for a protection source node, including number of children
 * and. The node should have a SourceNodeMetaData object containing information to be
 * displayed.
 */
@Component({
  selector: 'coh-protection-source-metadata',
  templateUrl: './protection-source-metadata.component.html',
  styleUrls: ['./protection-source-metadata.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProtectionSourceMetadataComponent implements DataTreeNodeDetail<ProtectionSourceDataNode> {

  /**
   * If true, a more compact version of the metadata will be displayed.
   */
  @Input() compact = false;

  /**
   * The node context, including info about the node and it's selection status.
   */
  @Input() nodeContext: DataTreeNodeContext<ProtectionSourceDataNode>;

  /**
   * Checks the node for metadata and returns it if it exists.
   */
  get metadata(): SourceNodeMetadata {
    return (this.nodeContext.node as any).metadata;
  }

  /**
   * Retrieves the name of the object.
   */
  get nodeName(): string {
    return this.nodeContext.node.name;
  }

  /**
   * Retrives the extended style if it exists for a volume.
   * this only applies to Netapp.
   */
  get extendedStyle(): string {
    return this.metadata?.extendedStyle &&
    netappExtendedStyle[this.metadata.extendedStyle];
  }

  /**
   * Retrieves logical size, if set, from the node metadata.
   */
  get logicalSize(): number {
    return this.metadata && this.metadata.logicalSize;
  }

  /**
   * Retrieves logical size, if set, from the node metadata.
   */
  get provisionedBytes(): number {
    return this.metadata && this.metadata.provisionedBytes;
  }

  /**
   * Node serial number.
   */
  get serialNumber(): string {
    return this.metadata && this.metadata.serialNumber;
  }

  // TODO(maulik): Move NAS specific metadata to separate component
  /**
   * Retrieves Volume type for Netapp source node.
   */
  get volumeType(): string {
    return this.metadata && this.metadata.volumeType;
  }

  /**
   * Retrieves Channel count for Office 365 Team node.
   */
  get channelCount(): number {
    return this.metadata && this.metadata.channelCount;
  }

  /**
   * Retrieves Records count for Salesforce object node.
   */
  get recordCount(): number {
    return this.metadata && this.metadata.recordCount;
  }

  /**
   * Retrieves total count of salesforce user licenses.
   */
  get totalSfdcUserLicenses(): number {
    return this.metadata && this.metadata.totalSfdcUserLicenses;
  }

  /**
   * Retrieves count of used salesforce user licenses.
   */
  get usedSfdcUserLicenses(): number {
    return this.metadata && this.metadata.usedSfdcUserLicenses;
  }

  /**
   * Retreives leaf count,if set, from the node metadata.
   */
  get leafCount(): number {
    return this.metadata && this.metadata.leafCount;
  }

  /**
   * Data protocols (used in NAS).
   */
  get dataProtocols(): string[] {
    if (
      !flagEnabled(this.irisContext.irisContext, 'nasNfsv41ProtectionEnabled') ||
      (isDmsScope(this.irisContext.irisContext) &&
        !flagEnabled(this.irisContext.irisContext, 'nfsVersionPreferenceEnabled'))
    ) {
      return this.metadata?.dataProtocols?.filter(protocol => protocol !== NasModes.kNfs4_1);
    }
    return this.metadata?.dataProtocols;
  }

  /**
   * Get a list of group owners if there are any
   */
  get groupOwners(): string[] {
    return this.nodeContext.node.groupOwners;
  }

  /**
   * Get the Tenant owner if there is one
   */
  get tenantOwner(): string {
    return !isDmsScope(this.irisContext.irisContext) && this.nodeContext.node.tenantOwner;
  }

  /**
   * Get a list of user owners if there are any
   */
  get userOwners(): string[] {
    return this.nodeContext.node.userOwners;
  }

  /**
   * Builds the leaf count translate string, based on the node environment type.
   * Some nodes (like RDS) need extra information besides the environment.
   * That information might be available as nodeIdentifierKey in the metadata.
   */
  get leafCountKey(): string {
    let key = this.metadata.nodeIdentifierKey ?
      this.metadata.nodeIdentifierKey : this.nodeContext.node.environment;
    if (this.nodeContext.node.environment === Environment.kO365) {
      key = this.nodeContext.node.data?.protectionSource?.office365ProtectionSource?.type;
    }
    if (['kAWS', 'kAzure'].includes(key) && this.nodeContext.node.workloadType) {
      key = this.nodeContext.node.workloadType;
    }
    return `sourceTree.metadata.leavesCount.${key}`;
  }

  /**
   * Returns true if the object size should be rendered on the UI
   */
  get showSizeData(): boolean {
    return this.logicalSize >= 0 &&
      ((this.nodeContext.node.workloadType && (this.nodeContext.node.workloadType !== 'kS3Bucket')) ||
        !(['kS3Bucket', 'kS3Tag'].includes(this.nodeContext.node.type)));
  }

  constructor(private irisContext: IrisContextService) {

  }
}
