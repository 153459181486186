// Module: Cloud Edition Manager Page

;(function(angular) {
  'use strict';

  angular.module('C.cloudEditionManager')
    .component('cloudEditionNew', {
      bindings: {
        // @type {String}  The type of cloud (aws/azure/gcp)
        type: '<',
      },
      templateUrl: 'app/platform/cloud-edition-manager/new.html',
      controller: 'cloudEditionNewController',
    })
    .controller('cloudEditionNewController', cloudEditionNewCtrlFn);

  /**
   * @ngdoc component
   * @name C.cloudEditionManager:cloudEditionNew
   * @function
   *
   * @description
   * Deploy a new Cohesity DataPlatform for cloud
   */
  function cloudEditionNewCtrlFn() {
    var $ctrl = this;
  }

})(angular);