import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { FilerAppStatusPipe } from './filer-app-status.pipe';

const PIPES = [
  FilerAppStatusPipe,
];

@NgModule({
  imports: [TranslateModule.forChild()],
  providers: [...PIPES],
  exports: [...PIPES],
  declarations: [...PIPES],
})
export class FilerAppStatusPipeModule {}
