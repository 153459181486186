import { NgModule } from '@angular/core';

import { EnumKeysPipe } from './enum-keys.pipe';

/**
 * App Module. This is the root angular module.
 */
@NgModule({
  declarations: [EnumKeysPipe],
  exports: [EnumKeysPipe],
  providers: [EnumKeysPipe],
})
export class EnumKeysModule { }
