import { Injectable } from '@angular/core';
import { TagCategory, TagSubCategory, TagType } from '@cohesity/api/helios-metadata';
import { SearchServiceApi } from '@cohesity/api/v2';
import { Observable, of } from 'rxjs';
import {
  FilterFieldOption,
  IFieldFilterService,
  ThreatStatus,
  threatStatusLabelKeys,
  threatsTagNames,
} from '../../constants';
import { getCombinedUniqueString, getExcludedTagNames } from '../../utils';

@Injectable({
  providedIn: 'root',
})
export class ThreatsFilterService implements IFieldFilterService {
  /**
   * static list of threat filter options
   */
  private threatStatusValueOptions: FilterFieldOption[] = Object.values(ThreatStatus)
    .filter((status) => ![ThreatStatus.unknown, ThreatStatus.no_scans].includes(status))
    .map((status) => ({
      key: status,
      labelKey: threatStatusLabelKeys[status],
    }));

  /**
   * Threats status filter value generator.
   *
   * @returns observable with filter options
   */
  getFilterOptions(): Observable<FilterFieldOption[]> {
    return of(this.threatStatusValueOptions);
  }

  /**
   * consolidates threats filters into a format that API understands
   *
   * @param selectedValues currently applied threats filters
   * @param appliedApiFilters currently applied api filters
   * @returns threats filter that API understands
   */
  transformFilterValues(
    selectedValues: string[],
    appliedApiFilters: SearchServiceApi.SearchObjectsParams
  ): SearchServiceApi.SearchObjectsParams {
    const apiFilters: SearchServiceApi.SearchObjectsParams = {};

    if (selectedValues.length) {
      apiFilters.tagTypes = [getCombinedUniqueString(appliedApiFilters.tagTypes, TagType.System)];
      apiFilters.tagCategories = [getCombinedUniqueString(appliedApiFilters.tagCategories, TagCategory.Security)];
      apiFilters.tagSubCategories = [
        getCombinedUniqueString(appliedApiFilters.tagSubCategories, TagSubCategory.Threats),
      ];
      apiFilters.threatTags = [
        getCombinedUniqueString(selectedValues.map((value) => threatsTagNames[value]).filter(Boolean))];

      const excludedTagNames = getExcludedTagNames<ThreatStatus>(
        appliedApiFilters.tagNamesExcluded ?? [],
        selectedValues,
        this.threatStatusValueOptions,
        threatsTagNames,
      );
      if (excludedTagNames.length > 0) {
        apiFilters.tagNamesExcluded = excludedTagNames;
      }
    }

    return apiFilters;
  }
}
