<dg-ar-scan-run-status *ngIf="!incidence?.antiRansomwareDetails?.isBaas; else notApplicable"
  [skuType]="SkuTypeEnum.dataClassification"
  [environment]="incidence?.antiRansomwareDetails?.environment"
  [scanStatus]="lastSensitivityRunDetails?.status"
  [message]="lastSensitivityRunDetails?.message"
  [appStatus]="appStatus"
  [getInstallAppLinkFn]="getInstallAppLinkFn"
  [getInstallAppLinkParamsFn]="getInstallAppLinkParamsFn"
  [adapterAccessService]="dcAdapterAccessService">
  <ng-container *ngIf="lastSensitivityRunDetails?.totalPatternCount; else noSensitiveFiles">
    <cog-icon
      size="xs"
      class="margin-right-sm sensitivity-{{ highestSensitivityCount?.sensitivity }}"
      shape="fiber_manual_record">
    </cog-icon>
    {{
      'totalSensitivityCount'
        | translate
          : {
              count: (highestSensitivityCount?.count | number),
              range: (highestSensitivityCount?.sensitivity | range),
              total: (lastSensitivityRunDetails?.totalPatternCount | number)
            }
    }}
  </ng-container>

  <ng-template #noSensitiveFiles>
    {{ 'sensitivePatternsNotPresent' | translate }}
  </ng-template>
</dg-ar-scan-run-status>

<ng-template #notApplicable>
  {{ 'naNotApplicableLetters' | translate }}
</ng-template>
