// Component: Storage Array Network (ie. Pure) details

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.sourceSANDetails', ['smart-table'])
    .component('cSanDetailsPub', {
      bindings: {
        /**
         * Required header attribute
         *
         * @type   {String}
         */
        header: '@',

        /**
         * Required volumes to show
         *
         * @type  {Object[]}
         */
        volumes: '=',

        /**
         * Required selected counts objects
         *
         * @type  {object}
         */
        selectedObjectsCounts: '=',

        /**
         * Optional options
         *
         * @type  {object}  Properties {
         *   // provide this option to expose checkboxes for selection
         *   canSelect: bool [true],
         *
         *   // provide this option if a user can only select one node
         *   singleSelect: bool [false],
         *
         *   // provide this option if singleSelect is enabled, it will be
         *   // populated with selected volume node.
         *   singleSelectNode: object [undefined],
         *
         *  // this gives you the ability to hide the sticky footer if needed
         *  disableStickeyFooter: Boolean [false]
         * }
         */
        options: '=?',
      },

      // contents are transcluded into the sticky-footer
      transclude: true,
      controller: componentControllerFn,
      templateUrl: 'app/global/c-san-details/c-san-details-public.html',
    });

  /**
   * @ngdoc component
   * @name C:cSanDetailsPub
   *
   * @description
   *   volumes list sources view for Storage Array Network (ie. kPure)
   *
   * @example
      <example module="C">
        <c-san-details
          volumes="sourceTree"
          options="{ canSelect: true }"
          selected-objects-counts="selectedObjectsCounts">

          <!-- transcluded content goes into stickyFooter -->
          <button type="submit">Save</button>
        </c-san-details>
      </example>
   */
  function componentControllerFn(PubJobServiceFormatter) {
    var $ctrl = this;

    /**
     * volume filters
     *
     * NOTE: If this dropdown adds additional filters against other properties,
     * this show all reset will break.
     */
    var sanVolumeFilters = [
      {
        nameKey: 'showAll',
        property: '_isProtected',
        value: null,
      },
      {
        nameKey: 'sourceTreePub.tooltips.protected',
        icon: 'protected',
        property: '_isProtected',
        value: true,
      },
      {
        nameKey: 'unprotected',
        property: '_isProtected',
        value: false,
      }
    ];

    // properties exposed to controller instance
    angular.extend($ctrl, {
      // filters for volume list
      sanVolumeFilters: sanVolumeFilters,
      selectedSanVolumeFilter: null,

      // filtered volumes list
      filteredVolumes: [],

      // selection state
      selectAll: false,

      // controller function exposed to template
      selectOneNode: selectOneNode,
      clickCheckbox: clickCheckbox,
      toggleAllSelection: toggleAllSelection,
      areAllVolumesSelected: areAllVolumesSelected,

      // component life cycle methods
      $onInit: $onInit,
    });

    /**
     * component initialization
     *
     * @method   $onInit
     */
    function $onInit() {
      if ($ctrl.options.singleSelect) {
        prepareVolumesForSingleSelection();
      }
    }

    /**
     * update the volumes as per single selected volume option.
     * un-select the volumes which are not in singleSelectNode options else
     * select the volume and update the singleSelectNode options with correct
     * volumes ref.
     *
     * @method   prepareVolumesForSingleSelection
     */
    function prepareVolumesForSingleSelection() {
      PubJobServiceFormatter.forEachNode(
        $ctrl.volumes,
        function eachNode(volume){
          if ($ctrl.options.singleSelectNode &&
            volume.protectionSource.id ===
            $ctrl.options.singleSelectNode.protectionSource.id) {
            selectVolume(volume);
            angular.extend($ctrl.options.singleSelectNode, volume);
          } else {
            unselectVolume(volume);
          }
        }
      );
    }

    /**
     * select a volume
     *
     * @method   selectVolume
     * @param    {Object}       volume   Volume to be selected.
     */
    function selectVolume(volume) {
      PubJobServiceFormatter.selectNode(volume, {
        selectedObjectsCounts: $ctrl.selectedObjectsCounts,
        tree: $ctrl.volumes,
      });
    }

    /**
     * unselect a volume
     *
     * @method   unselectVolume
     * @param    {Object}         volume   Volume to be unselected.
     */
    function unselectVolume(volume) {
      PubJobServiceFormatter.unselectNode(volume, {
        selectedObjectsCounts: $ctrl.selectedObjectsCounts,
        tree: $ctrl.volumes,
      });
    }

    /**
     * toggle volume selection
     *
     * @method   clickCheckbox
     * @param    {Object}        volume    Clicked volume
     */
    function clickCheckbox(volume) {
      if (volume._isSelected) {
        unselectVolume(volume);
      } else {
        selectVolume(volume);
      }
    }

    /**
     * select one volume
     *
     * @method   selectOneNode
     * @param    {Object}    newVolume     The selected volume
     */
    function selectOneNode(newVolume) {
      // un-select the current selection and then select the newVolume
      if ($ctrl.options.singleSelectNode) {
        unselectVolume($ctrl.options.singleSelectNode);
      }

      selectVolume(newVolume);
      $ctrl.options.singleSelectNode = newVolume;
    }

    /**
     * Indicates if all filtered volumes are currently selected. Also updates
     * the $ctrl.selectAll property
     *
     * @method   areAllVolumesSelected
     * @return   {boolean}   true of all filtered volumes are selected
     */
    function areAllVolumesSelected() {
      $ctrl.selectAll = false;

      // mark selectAll true if all node are selected and don't test for single
      // select mode since all node can't be selected in that case
      if (!$ctrl.options.singleSelect) {
        $ctrl.selectAll = $ctrl.filteredVolumes.every(
          function doseVolumeSelected(vol) {
            return vol._isSelected;
          }
        );
      }

      return $ctrl.selectAll;
    }

    /**
     * select/unselect all filtered volumes and toggles $ctrl.selectAll property
     *
     * @method   toggleAllSelection
     */
    function toggleAllSelection() {
      var actionFn;

      $ctrl.selectAll = !$ctrl.selectAll;
      actionFn = $ctrl.selectAll ? selectVolume : unselectVolume;
      $ctrl.filteredVolumes.forEach(actionFn);
    }
  }

})(angular);
