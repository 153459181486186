import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { ObjectSearchResult, ProtectionGroupSearchResult, RestoreSearchResult } from 'src/app/modules/restore/restore-shared';


/**
 * @description
 *
 * Confirmation dialog window when creating recovery.
 */
@Component({
  selector: 'coh-recover-vm-vmware-protection-group-dialog',
  templateUrl: './recover-vm-vmware-protection-group-dialog.component.html',
  styleUrls: ['./recover-vm-vmware-protection-group-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecoverVmVmwareProtectionGroupDialogComponent implements OnInit{

  // Objects in protection Group.
  protectedGroupObjects: ObjectSearchResult[];

  constructor(
    @Inject(MAT_DIALOG_DATA) readonly data: RestoreSearchResult,
    public dialogRef: MatDialogRef<RecoverVmVmwareProtectionGroupDialogComponent>,
  ) {}

  ngOnInit(): void {
    this.protectedGroupObjects = (this.data as any as ProtectionGroupSearchResult).objectsProtected;
  }

  /**
   * Gets the confirm action wanted by the user
   */
  onConfirm() {
    this.dialogRef.close(true);
  }
}
