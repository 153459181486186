<cog-dashcard
  [title]="'policies' | translate">
  <ng-container *ngIf="!loading; else spinner">
    <ng-container *ngTemplateOutlet="cardContent"></ng-container>
  </ng-container>

  <cog-dashcard-header-aux>
    <h5 class="no-margin-bottom">
      <a [href]="reviewLink">{{ 'review' | translate }}</a>
    </h5>
  </cog-dashcard-header-aux>
</cog-dashcard>

<ng-template #cardContent>
  <mat-card-content *ngIf="(data | async).length > 0; else blankCard">
    <div class="pattern-distribution-summary">
      <span>{{ 'dg.sc.dashboard.policyPatterns' | translate: { num: builtInPatterns + customPatterns } }}</span>
    </div>
    <div class="pattern-distribution-container">
      <div class="pattern-distribution">
        <div class="distribution-entry">
          <span class="type-bubble builtin"></span>
          <span class="pattern-title">
            {{'argus.enum.resourceType.builtIn.patterns' | translate: { num: builtInPatterns } }}
          </span>
        </div>
        <div class="distribution-entry">
          <span class="type-bubble custom"></span>
          <span class="pattern-title">
            {{'argus.enum.resourceType.custom.patterns' | translate: { num: customPatterns } }}
          </span>
        </div>
      </div>
      <div class="pattern-distribution-bar">
        <div class="completed-area" [style.width.%]="(builtInPatterns * 100) / (builtInPatterns + customPatterns)">
        </div>
      </div>
    </div>
    <div>
      <div class="pattern" *ngFor="let pattern of (data | async)">
        <span class="type-bubble" [class]="pattern.buildType?.toLowerCase()"></span>
        <span class="pattern-title">{{ pattern.name }}</span>
      </div>
    </div>
  </mat-card-content>
</ng-template>

<ng-template #blankCard>
  <cog-blank-card size="sm"></cog-blank-card>
</ng-template>

<ng-template #spinner>
  <cog-spinner></cog-spinner>
</ng-template>
