import { AppModuleConfig, StateAccessMap } from 'src/app/app-module.config';
import { ServicesListingConfig } from './modules/services-listing/services-listing.config';

/**
 * States and access configurations for global scope are kept here.
 */
export const GlobalConfig: AppModuleConfig = {
  futureStates: [
    ...ServicesListingConfig.futureStates,
  ],
  allClusterMap: {
    ...ServicesListingConfig.allClusterMap,
  },
  getStateAccessMap(): StateAccessMap {
    return {
      ...ServicesListingConfig.getStateAccessMap(),
    };
  },
};
