import { Injectable } from '@angular/core';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';
import { Transition } from '@uirouter/core';

import { McmViewService } from '../../services';
import { GuardPriority, GuardResult, StateGuard } from '../state-guard';

/**
 * Job Modify Guard redirects all state traffict to the ng protection-builder state if the feature flag
 * is eanbled.
 */
@Injectable({
  providedIn: 'root',
})
export class JobModifyGuard implements StateGuard {
  /**
   * This guard priority is set to App, which indicates it should run as part of the main application
   * after all bootstrapping and initializing is completed.
   */
  guardPriority = GuardPriority.App;

  /**
   * Match all navigation to job-modify.
   * Skip navigation originating from protection-builder.
   */
  matchCriteria = {
    to: 'job-modify',
  };

  constructor(private irisCtx: IrisContextService,
    private mcmViewService: McmViewService) {}

  /**
   * Redirect to protection-builder with the params.
   *
   * @param   transition  The ui router transition
   * @returns a redirect transtion if the protection group feature flag is enabled.
   */
  onStart(transition: Transition): GuardResult {
    const params = transition.params();
    if (flagEnabled(this.irisCtx.irisContext, 'ngProtectionGroupGlobalModify') && !params.force &&
      this.mcmViewService.canAccessState('protection-builder', params.cid)) {
      return transition.router.stateService.target('protection-builder', params);
    }
  }
}
