/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityV2ApiConfiguration as __Configuration } from '../cohesity-v2api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { SupportUserConfig } from '../models/support-user-config';
import { SuccessResp } from '../models/success-resp';
import { UpdateSupportUserParams } from '../models/update-support-user-params';
import { ValidateSupportUserCredParams } from '../models/validate-support-user-cred-params';
@Injectable({
  providedIn: 'root',
})
class SupportService extends __BaseService {
  static readonly GetSupportUserConfigPath = '/support-user/config';
  static readonly UpdateSupportUserConfigPath = '/support-user/config';
  static readonly ValidateSupportUserCredsPath = '/support-user/config/validate';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get support user configuration.
   *
   * Cohesity provides a support user account for improved security and you need to use the support user account to log in to the Cohesity cluster bash shell using SSH. This endpoint returns the current support user configuration on the Cohesity cluster.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetSupportUserConfigResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<SupportUserConfig>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/support-user/config`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SupportUserConfig>;
      })
    );
  }
  /**
   * Get support user configuration.
   *
   * Cohesity provides a support user account for improved security and you need to use the support user account to log in to the Cohesity cluster bash shell using SSH. This endpoint returns the current support user configuration on the Cohesity cluster.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetSupportUserConfig(regionId?: string,
    accessClusterId?: number): __Observable<SupportUserConfig> {
    return this.GetSupportUserConfigResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as SupportUserConfig)
    );
  }

  /**
   * Update support user configuration.
   *
   * Update support user's configuration. This allows you to update the support user's password and/or grant sudo access to the user.
   * @param params The `SupportService.UpdateSupportUserConfigParams` containing the following parameters:
   *
   * - `body`: Specifies the support user configuration.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateSupportUserConfigResponse(params: SupportService.UpdateSupportUserConfigParams): __Observable<__StrictHttpResponse<SuccessResp>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/support-user/config`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SuccessResp>;
      })
    );
  }
  /**
   * Update support user configuration.
   *
   * Update support user's configuration. This allows you to update the support user's password and/or grant sudo access to the user.
   * @param params The `SupportService.UpdateSupportUserConfigParams` containing the following parameters:
   *
   * - `body`: Specifies the support user configuration.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateSupportUserConfig(params: SupportService.UpdateSupportUserConfigParams): __Observable<SuccessResp> {
    return this.UpdateSupportUserConfigResponse(params).pipe(
      __map(_r => _r.body as SuccessResp)
    );
  }

  /**
   * Validates the support user credentials.
   *
   * Validates the support user credentials.
   * @param params The `SupportService.ValidateSupportUserCredsParams` containing the following parameters:
   *
   * - `body`: Specifies the support user credentials.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  ValidateSupportUserCredsResponse(params: SupportService.ValidateSupportUserCredsParams): __Observable<__StrictHttpResponse<SuccessResp>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/support-user/config/validate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SuccessResp>;
      })
    );
  }
  /**
   * Validates the support user credentials.
   *
   * Validates the support user credentials.
   * @param params The `SupportService.ValidateSupportUserCredsParams` containing the following parameters:
   *
   * - `body`: Specifies the support user credentials.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  ValidateSupportUserCreds(params: SupportService.ValidateSupportUserCredsParams): __Observable<SuccessResp> {
    return this.ValidateSupportUserCredsResponse(params).pipe(
      __map(_r => _r.body as SuccessResp)
    );
  }
}

module SupportService {

  /**
   * Parameters for UpdateSupportUserConfig
   */
  export interface UpdateSupportUserConfigParams {

    /**
     * Specifies the support user configuration.
     */
    body: UpdateSupportUserParams;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for ValidateSupportUserCreds
   */
  export interface ValidateSupportUserCredsParams {

    /**
     * Specifies the support user credentials.
     */
    body: ValidateSupportUserCredParams;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { SupportService }
