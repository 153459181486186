import { Pipe, PipeTransform } from '@angular/core';
import { DateDurationPipe, HelixIntlService } from '@cohesity/helix';
import { unitOfTime } from 'moment';

/**
 * @ngdoc Pipe
 * @description
 *    Converts a duration to humanized string. By default, the value is assumed to be in milliseconds. The units of the
 *    given value can be supplied as pipe argument. Supply the third argument to use it in full format.
 *
 *    Leverages the package 'humanize-duration' (https://www.npmjs.com/package/humanize-duration) to make it more
 *    accurate as this is a known issue in moment.humanize (https://github.com/moment/moment/issues/348)
 *
 * @example
 *    {{1000 | humanizeDuration}} => '1s'
 *    {{2000 | humanizeDuration}} => '2s'
 *    {{600000 | humanizeDuration}} => '10m'
 *    {{624100 | humanizeDuration}} => '10m 24s'
 *    {{2 | humanizeDuration:'days'}} => '2d'
 *    {{30 | humanizeDuration:'h':true}} => '1 day, 6 hours'
 *    {{14 | humanizeDuration:'d':true}} => '2 weeks'
 */
@Pipe({
  name: 'humanizeDuration',
})
export class HumanizeDurationPipe implements PipeTransform {
  /**
   * The actual duration pipe
   */
  private converter: DateDurationPipe;

  constructor(intlSvc: HelixIntlService) {
    this.converter = new DateDurationPipe(intlSvc);
  }

  /**
   * Transforms the duration (considering `unit` if supplied) to human readable string.
   *
   * @param    input   The value to transform.
   * @param    unit    The input value unit.
   * @param    fullFormat True, if the output should be in full format.
   * @return   The human readable string of the duration.
   */
  transform(input: number, unit?: unitOfTime.DurationConstructor, fullFormat?: boolean): string {
    return this.converter.transform(input, unit, fullFormat);
  }
}
