import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { CohesityHelixModule } from '@cohesity/helix';
import { TranslateModule } from '@ngx-translate/core';
import { UIRouterModule } from '@uirouter/angular';
import { DynamicComponentLoaderModule } from 'src/app/dynamic-component-loader';

import { PipesModule } from '../pipes';
import { AggregatedSummaryPopoverComponent } from './aggregated-summary-popover/aggregated-summary-popover.component';
import { AwsPopoverComponent } from './aws-popover/aws-popover.component';
import { AzureEntraIDPopoverComponent } from './azure-entra-id-popover/azure-entra-id-popover.component';
import { AzureSqlPopoverComponent } from './azure-sql-popover/azure-sql-popover.component';
import { AzureVMPopoverComponent } from './azure-vm-popover/azure-vm-popover.component';
import { ExchangePopoverComponent } from './exchange-popover/exchange-popover.component';
import { HypervisorPopoverComponent } from './hypervisor-popover/hypervisor-popover.component';
import { NasPopoverComponent } from './nas-popover/nas-popover.component';
import { Office365PopoverComponent } from './office365-popover/office365-popover.component';
import { OraclePopoverComponent } from './oracle-popover/oracle-popover.component';
import { AgentInformationComponent } from './physical-popover/agent-information/agent-information.component';
import { PhysicalPopoverComponent } from './physical-popover/physical-popover.component';
import { VolumeInformationComponent } from './physical-popover/volume-information/volume-information.component';
import { ProtectionStatusMetaComponent } from './protection-status-meta/protection-status-meta.component';
import { SapHanaPopoverComponent } from './saphana-popover/saphana-popover.component';
import { SourceMetaComponent } from './source-meta.component';
import { SqlPopoverComponent } from './sql-popover/sql-popover.component';

@NgModule({
  declarations: [
    SourceMetaComponent,
    AgentInformationComponent,
    AggregatedSummaryPopoverComponent,
    AwsPopoverComponent,
    AzureSqlPopoverComponent,
    AzureEntraIDPopoverComponent,
    AzureVMPopoverComponent,
    ExchangePopoverComponent,
    HypervisorPopoverComponent,
    NasPopoverComponent,
    Office365PopoverComponent,
    OraclePopoverComponent,
    PhysicalPopoverComponent,
    ProtectionStatusMetaComponent,
    SapHanaPopoverComponent,
    SqlPopoverComponent,
    VolumeInformationComponent,
  ],
  imports: [
    CohesityHelixModule,
    CommonModule,
    DynamicComponentLoaderModule,
    MatExpansionModule,
    PipesModule,
    TranslateModule,
    UIRouterModule,
  ],
  exports: [
    SourceMetaComponent,
    AggregatedSummaryPopoverComponent,
  ],
})
export class SourceMetaModule {}
