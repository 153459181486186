// Component: Cloud Deploy Run Detail

;(function(angular) {
  'use strict';

  angular.module('C.jobDetails')
    .component('cloudDeployDetails', {
      bindings: {
        /**
         * the job run for which details are to be shown
         *
         * @type   {Object}
         */
        jobRun: '<',

        /**
         * The parent job object for the job run
         *
         *  @type  {Object}
         */
        job: '<',

        /**
         * Collection of all cluster objects where cloudDeploy is happening
         *
         * @type {Object}
         */
        allClusters: '<',

        /**
         * The callback for cancel button
         *
         * @type {Function}
         */
        onCancel: '<',

        /**
         * Whether the component is being used in replication page
         *
         * @type: {Boolean}
         */
        isCloudReplication: '<'
      },
      controller: 'cloudDeployDetailCtrl',
      templateUrl: 'app/protection/jobs/details/run/cloud-deploy-details.html',
    })
    .controller('cloudDeployDetailCtrl', cloudDeployDetailCtrlFn);

  /**
   * @ngdoc component
   * @name C.jobDetails:cloudDeployDetails
   * @function
   *
   * @description
   * Display detials of a cloud deploy task
   */
  function cloudDeployDetailCtrlFn(_, $scope, $translate, cModal, cUtils,
    JobRunsService, PollTaskStatus, DateTimeService, evalAJAX,
    ENUM_COPY_SUBTASK_STATUS, FEATURE_FLAGS) {

    var $ctrl = this;

    var defaultStatusCounts = {
      error: 0,
      running: 0,
      success: 0,
      total: 0,
    };

    _.assign($ctrl, {
      cloudDeployTasks: [],
      confirmed: false,
      entityNamesHash: {},
      progressTasksHash: {},
      expandedRows: {},
      ENUM_COPY_SUBTASK_STATUS: ENUM_COPY_SUBTASK_STATUS,
      FEATURE_FLAGS: FEATURE_FLAGS,

      // methods
      $onChanges: $onChanges,
      isTaskRunning: JobRunsService.isCopyTaskRunning,
      getSortingStatus: getSortingStatus,
      launchAllInstances: launchAllInstances,
      openLaunchInstanceModal: openLaunchInstanceModal,
      toggleExpandedRow: toggleExpandedRow,
    });

    /**
     * Aggregate overall job status counts and Logical byte sizes for
     * individual copy tasks.
     *
     * @method   $onChanges
     */
    function $onChanges() {
      var jobRun = $ctrl.jobRun;

      $ctrl.cloudDeployTasks.length = 0;

      jobRun.copyRun._rxTasks.forEach(function eachTask(task) {
        // Do nothing for non cloud deploy jobs
        if (task._isReplication ||

          // The compoenet is being used in replication page but task is
          // cloudDeploy
          ($ctrl.isCloudReplication && !task._isCloudReplication) ||

          // The compoenet is being used in cloudDeploy page but task is
          // cloudReplication
          (!$ctrl.isCloudReplication && task._isCloudReplication)) {

          return;
        }

        task.statusCounts = angular.copy(defaultStatusCounts);

        var numSucceeded = 0;
        var numCanceled = 0;
        var numFailed = 0;
        var numRunning = 0;
        var backupRunTask = null;
        var attempts = [];

        if (task._allCopySubTasks && task._allCopySubTasks.length) {
          task._allCopySubTasks.forEach(function eachSubTask(subTask) {
            subTask.actionItems = [];
            if (subTask.status === 4 && !subTask.error) {
              numSucceeded++;
              if (FEATURE_FLAGS.launchCloudDeploy && !_isTaskExpired(subTask) &&
                task.publicStatus !== 'kRunning' && task._isCloudDeploy) {
                subTask.actionItems.push({
                  icon: 'icn-recover',
                  translateKey: 'launchInstance',
                  action: openLaunchInstanceModal.bind(this, subTask),
                });
              }
            } else if (subTask.error) {
              numFailed++;
            } else if (subTask.status === 3) {
              numRunning++;
            } else if (subTask.status === 5) {
              numCanceled++;
            }

            // find the corresponding backup run
            attempts = attempts.concat(jobRun.backupRun.finishedAttempts || []);

            if (jobRun.backupRun.activeAttempt) {
              attempts.push(jobRun.backupRun.activeAttempt);
            }

            attempts.some(function eachAttempt(attempt) {
              backupRunTask =
                (attempt.finishedTasks || []).find(function eachRunTask(obj) {
                  return obj.taskId === subTask.backupTaskId;
                });
              return angular.isObject(backupRunTask);
            });

            // find the logical size from the corresponding backup run
            subTask.logicalSize =
              _.get(backupRunTask, 'base.totalLogicalBackupSizeBytes');

            // AWS Region where snapshots are being replicated
            subTask._region = _.get(subTask, ['snapshotTarget',
              'cloudDeployTarget', 'deployVmsToCloudParams',
              'replicateSnapshotsToAwsParams', 'region', 'displayName'
            ].join('.'));
          });
        }

        // Assign values to Status Counts
        task.statusCounts.success = numSucceeded;
        task.statusCounts.error = numFailed;
        task.statusCounts.running = numRunning;
        task.statusCounts.canceled = numCanceled;
        task.statusCounts.total = task.statusCounts.success +
          task.statusCounts.error + task.statusCounts.running +
          task.statusCounts.canceled;

        $ctrl.cloudDeployTasks.push(task);

        if (!$ctrl.initiated) {
          $ctrl.entityNamesHash =
            _prepareEntityNamesHash(jobRun.copyRun._rxTasks);

          // Start pulse polling for each task
          _.each(task._allCopySubTasks, _getPulseData);
        }
      });

      $ctrl.initiated = true;
    }

    /**
     * Gets the pulse data.
     *
     * @method   getPulseData
     * @return   {object}   Promise to resolve with the results of the
     *                      pollingIterator Fn.
     */
    function _getPulseData(task) {
      return PollTaskStatus.createPoller({
        interval: 5,
        isDoneFn: _.bind(_isPollingDone, this, task),
        iteratorFn: _.bind(_pollingIterator, this, task),
        scope: $scope,
      });
    }

    /**
     * Function executed at every iteration of the poller.
     *
     * @method   _pollingIterator
     * @return   {object}   Promise to resolve with the poller response.
     */
    function _pollingIterator(task) {
      // Using the yoda progress monitor root path, fetch their pulse data
      var pulseOptions = {
        taskPathVec: task.progressMonitorTaskPath,
        includeFinishedTasks: true,
        excludeSubTasks: false,
      };

      return _isPollingDone(task) ?

        // Resolve early with nothing if there are no active indexing subTasks.
        // This will prevent unnecessary polling.
        $q.resolve([]) :

        // Otherwise we will poll for the indexing subTasks
        PollTaskStatus.getProgress(pulseOptions)
          .then(function pulseReceived(resp) {
            if (resp.resultGroupVec[0].taskVec[0]) {
              // progressMonitorTaskPath is a combination of taskUid keys.
              // So it is a unique hash.
              $ctrl.progressTasksHash[task.progressMonitorTaskPath] =
                resp.resultGroupVec[0].taskVec[0];

              _decorateProgressObject(
                $ctrl.progressTasksHash[task.progressMonitorTaskPath]);
            }
          }, evalAJAX.errorMessage);
    }

    /**
     * Determines if polling is done.
     *
     * @method   _isPollingDone
     * @return   {boolean}   True if polling done, False otherwise.
     */
    function _isPollingDone(task) {
      return !!_.get($ctrl.progressTasksHash[task.jobInstanceId],
        'progress.endTimeSecs');
    }

    /**
     * Prepares an entity hash with key as entity id and value as entity
     * displayname
     *
     * @method   _prepareEntityNamesHash
     * @param    {Array}    tasks   The tasks
     * @return   {Object}   The entity hash
     */
    function _prepareEntityNamesHash(tasks) {
      return tasks.reduce(function prepareHash(accumulator, task) {
        accumulator[task.jobInstanceId] = _.get(task,
          'snapshotTarget.cloudDeployTarget.targetEntity.displayName');
        return accumulator;
      }, {});
    }

    /**
     * Provides a sorting value for object level clouddeploy status, as we can't
     * sort on the raw status value since success and error share a status value
     * (4 = finished).
     *
     * @method   getSortingStatus
     * @param    {object}   subTask   The sub task object
     * @return   {number}   For success case, returns 99, all others returns
     *                      status integer
     */
    function getSortingStatus(subTask) {
      return (subTask.status && !subTask.error) ?
        99 : subTask.status;
    };

    /**
     * Opens a new launch instance modal.
     *
     * @method   openLaunchInstanceModal
     * @param    {Object}   subTask    The sub task object
     * @param    {Boolean}  deployAll  Should launch all instances?
     * @param    {Object}   copyTask   Launch all instances for this copyTask
     */
    function openLaunchInstanceModal(subTask, deployAll, copyTask) {
      var modalConfig = {
        controller: 'launchCloudDeployInstanceController',
        controllerAs: '$ctrl',
        templateUrl:
          'app/protection/jobs/details/run/launch-cloud-deploy-instance.html',
        size: 'lg',
        resolve: {
          opts: {
            getLaunchMetadata: function targetsGetter() {
              var target =
                subTask ? subTask.snapshotTarget : copyTask.snapshotTarget;

              return {
                job: $ctrl.job,
                targetObj: target.cloudDeployTarget,
                tasks: deployAll ? $ctrl.jobRun
                  .copyRun._rxTasks.find(function findTask(task) {
                    return task.taskUid.objectId ===
                      copyTask.taskUid.objectId;
                  })._allCopySubTasks.filter(
                    function filterTasks(task) {
                      return !task.error && !_isTaskExpired(task);
                    }
                  ) : [subTask],
              };
            }
          },
        },
      };

      cModal.standardModal(modalConfig, {
        actionButtonKey: false,
        closeButtonKey: false,
        titleKey: false,
      });
    };

    /**
     * Checks if the given task has expired
     *
     * @method  _isTaskExpired
     * @param   {object}   task   task object for the task to be checked
     * @return  {boolean}  True if the task has expired. False otherwise.
     */
    function _isTaskExpired(task) {
      return (DateTimeService.getCurrentUsecs() > task.expiryTimeUsecs);
    }

    /**
     * Launch all instances of cloudSpin
     * @param {Object} task  The task object for which instances are to be
     *                       launched
     */
    function launchAllInstances(task) {

      // subTask -> null
      // deployAll -> true
      // copyTask -> task
      openLaunchInstanceModal.call(this, null, true, task);
    };

    /**
     * Toggles the expanded state of subTask
     * @method  toggleExpandedRow
     * @param   {Object}  key  key of row to be expanded/collapsed
     */
    function toggleExpandedRow(key) {
      $ctrl.expandedRows[key]  = !$ctrl.expandedRows[key];
    };

    /**
     * Add some UI friendly properties to display the progress of the task
     *
     * @method _decorateProgressObject
     * @param  {subTask} subTask  The subTask object
     */
    function _decorateProgressObject(subTask) {
      var percentFinishedString = [
        cUtils.round(subTask.progress.percentFinished),
        $translate.instant('completed'),
      ].join('% ');

      var endTimeString = (subTask.progress.expectedTimeRemainingSecs > 0) ? [
          DateTimeService
            .secsToTime(subTask.progress.expectedTimeRemainingSecs).time,
          $translate.instant('remaining'),
        ].join(' ') : '';

      _.assign(subTask.progress, {
        _percentage: subTask.progress.percentFinished,
        _barLabel: percentFinishedString,
        _statusLabel: endTimeString,
      });
    }
  }

})(angular);