import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Inject,
  Input,
  OnChanges,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ProtectionJob } from '@cohesity/api/v1';
import { DataFilterValue, DataTreeFilterUtils, FiltersComponent } from '@cohesity/helix';
import { IrisContextService } from '@cohesity/iris-core';
import { ProtectionSourcesServiceApi } from '@cohesity/api/v1';
import { PureProtectionTypes } from  'src/app/shared/source-tree/protection-source/pure/pure.constants';
import {
  BaseSourceTreeComponent,
  SOURCE_TREE_SERVICE_FACTORY,
  SourceSelection,
  SourceTreeContextProvider,
  SourceTreeNode,
  SourceTreeServiceFactory,
  ViewFilterType,
  ViewFilterOption,
} from '@cohesity/iris-source-tree';
import { TranslateService } from '@ngx-translate/core';
import { UIRouterGlobals } from '@uirouter/angular';
import { isEqual } from 'lodash';
import { combineLatest, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, shareReplay, startWith } from 'rxjs/operators';
import { ProtectionRunObjectStatus } from 'src/app/shared';
import { Environment } from '@cohesity/iris-shared-constants';
import { ProtectionRun } from '../../../models';
import { ProtectionSourceDataNode } from 'src/app/shared/source-tree';

/**
 * @description
 *
 * Objects tree for selecting backup objects.
 */
@Component({
  selector: 'coh-objects-select-tree',
  templateUrl: './objects-select-tree.component.html',
  styleUrls: ['./objects-select-tree.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ObjectsSelectTreeComponent),
      multi: true,
    },
    SourceTreeContextProvider
  ],
})
export class ObjectsSelectTreeComponent<NodeType, TreeNodeType extends SourceTreeNode<NodeType>>
  extends BaseSourceTreeComponent<NodeType, TreeNodeType>
  implements ControlValueAccessor, OnChanges, OnInit {
  /**
   * Source tree custom filters.
   */
  @ViewChild(FiltersComponent, { static: true })
  private readonly treeFilters: FiltersComponent;

  /**
   * The Protection Job object which is needed by some adapters for tree logic.
   */
  @Input() job: ProtectionJob;

  /**
   * Last protection run data that contains objects info.
   */
  @Input() run: ProtectionRun;

  /**
   * Source tree columns.
   */
  readonly columns = ['name', 'runStatus'];

  /**
   * Backup status filter values.
   */
  readonly backupStatusFilter = [
    {
      label: this.translate.instant('backupFailed'),
      value: 'backupFailed',
    },
    {
      label: this.translate.instant('backupSucceeded'),
      value: 'backupSucceeded',
    },
    {
      label: this.translate.instant('backupCanceled'),
      value: 'backupCanceled',
    },
  ];

  /**
   * True if protected objects are still in source tree.
   */
  private _hasProtectedObjects = true;

  /**
   * Returns true if source tree has objects related to protection group.
   */
  get hasProtectedObjects(): boolean {
    return this._hasProtectedObjects;
  }

  /**
   * Returns true if job is related to pure environment.
   */
  get isPure(): boolean {
    return this.job?.environment === Environment.kPure;
  }

  /**
   * Returns true if job is related to IBM FlashSystem environment.
   */
  get isIbmFlashSystem(): boolean {
    return this.job?.environment === Environment.kIbmFlashSystem;
  }

  constructor(
    @Inject(SOURCE_TREE_SERVICE_FACTORY) transformerFactory: SourceTreeServiceFactory,
    cdr: ChangeDetectorRef,
    uiRouterGlobals: UIRouterGlobals,
    private translate: TranslateService,
    private irisContext: IrisContextService,
    readonly protectionSourcesServiceApi: ProtectionSourcesServiceApi,
  ) {
    super(cdr, transformerFactory, uiRouterGlobals);
  }

  ngOnInit() {
    // Check for tag filters and remove them. The old tag filter view does not work well on the sources details page
    const viewFilters = this.filters?.getViewFilters();
    this.filters?.setViewFilters(viewFilters.filter(viewFilter => viewFilter.id !== ViewFilterType.Tag));
    if ((this.isPure|| this.isIbmFlashSystem) && (this.job?.id || this.job?.sourceIds?.length)) {
      // In kPure, if pureProtectionGroupViewEnabled FF is enabled and we are in edit mode, then
      // we hide the flat view if the job is protecting a PPG and hide the hierarchy view
      // if the job is protecting volume(s).
      const editModeViewFilter = this.filters.getViewFilters();
      this.protectionSourcesServiceApi.ListProtectionSources({
        id: this.job?.sourceIds[0],
        allUnderHierarchy: false,
      }).pipe(
        this.untilDestroy(),
      ).subscribe(source => {
        let newFilter: ViewFilterOption[];
        if (source[0]?.protectionSource?.pureProtectionSource?.type === PureProtectionTypes.kVolume ||
          source[0]?.protectionSource?.ibmFlashSystemProtectionSource?.type === PureProtectionTypes.kVolume) {
          newFilter = editModeViewFilter.filter(viewFilter => viewFilter.id === ViewFilterType.Flat);
        } else {
          newFilter = editModeViewFilter.filter(viewFilter => viewFilter.id === ViewFilterType.Physical);
        }
        this.filters.setSelectedViewFilter(newFilter[0]);
      });
    }
  }

  /**
   * Update the component when inputs change
   *
   * @param   changes   A map of changed objects.
   */
  ngOnChanges(changes) {
    super.ngOnChanges(changes);

    if (changes.isSingleSelect && this.dataTreeSelection) {
      this.dataTreeSelection.toggleSelectionMode(this.isSingleSelect);
      this.handleSelectionChange();
    }
  }

  /**
   * Override the parent control to listen for changes to the tree selection and sync them with
   * the form control value.
   */
  initService() {
    super.initService();
    this.treeService.job = this.job;

    this._hasProtectedObjects =
      DataTreeFilterUtils.searchFilter(
        this.treeService.treeControl.allDataNodes,
        this.treeService.treeControl,
        node => {
          const matchesRunObjects = this.run.objects.some(object => object.id === node.protectionSource.id);

          return matchesRunObjects || this.isProtected(node);
        },
        false,
        false
      ).length > 0;

    const treeFilters$: Observable<DataFilterValue<any>[]> = this.treeFilters.filterValues$.pipe(
      startWith([]),
      debounceTime(0),
      distinctUntilChanged(isEqual),
      shareReplay(1)
    );

    // return all run statuses based on filter selection
    const backupStatusFilter$: Observable<ProtectionRunObjectStatus[]> = treeFilters$.pipe(
      map(filters => {
        switch (filters.find(({ key }) => key === 'backupStatus')?.value[0]?.value) {
          case 'backupFailed':
            return ['Failed', 'kFailed'];
          case 'backupSucceeded':
            return ['kSuccessful', 'Succeeded', 'SucceededWithWarning', 'kWarning'];
          case 'backupCanceled':
            return ['Canceled', 'Canceling', 'kCanceled'];
          default:
            return [];
        }
      })
    );

    const searchNameQuery$: Observable<string> = treeFilters$.pipe(
      map(filters => filters.find(({ key }) => key === 'nameSearch')?.value)
    );

    const sourceFilters$ = combineLatest([backupStatusFilter$, searchNameQuery$, this.filters.treeFilters$]);

    sourceFilters$.pipe(this.untilDestroy()).subscribe((sourceFilters: any[]) => {
      const [backupStatusFilters, searchNameQuery, treeFilters] = sourceFilters;

      if (this.isPure) {
        this.dataTreeSource.filters = [
          nodes => {
            if (!nodes?.length) {
              return [];
            }
            // for pure source,if job protects ppgs, select the ppg nodes which are
            // protected by the current job and filter its children
            // if job protects only volumes select volumes only
            const protectionSourceDataNodes = nodes as unknown as ProtectionSourceDataNode[];
            let rootNode: ProtectionSourceDataNode;
            let ppgNodes = [];

            protectionSourceDataNodes.forEach(node => {
              const nodeId = node.protectionSource.id;
              const runStatus = this.run.objects.find(object => object.id === nodeId)?.status;

              if ((!backupStatusFilters.length || backupStatusFilters.includes(runStatus)) &&
              (!searchNameQuery || node.name?.toLowerCase().search(searchNameQuery.toLowerCase()) > -1) &&
              this.isProtected(node)) {
                ppgNodes.push(node);
                // if node is not a leaf, then the protection group protects the ppgs,
                // so select its children also
                if (!node.isLeaf) {
                  node.childIds.forEach(childId => {
                    ppgNodes = ppgNodes.concat(protectionSourceDataNodes.filter(
                      protectionSourceDataNode => protectionSourceDataNode.isLeaf &&
                      protectionSourceDataNode.protectionSource.id===childId));
                  });
                }
              }

              if (node.level===0) {
                // filter the root since above condition doesn't satisfy for
                // root node.
                rootNode = node;
              }
            });

          // this is required because tree filters in ppg assume the initial
          // node is root node.
          if (ppgNodes.length !== 0) {
            ppgNodes.unshift(rootNode);
          }
          return ppgNodes;
        },
          ...treeFilters,
        ];
      }else {
        this.dataTreeSource.filters = [
          nodes =>
            DataTreeFilterUtils.searchFilter(
              nodes,
              this.treeService.treeControl,
              node => {
                const nodeId = node.protectionSource.id;
                const runStatus = this.run.objects.find(object => object.id === nodeId)?.status;

                return (
                  // if backup status filters is selected, check if it matches this node's status
                  (!backupStatusFilters.length || backupStatusFilters.includes(runStatus)) &&
                  (!searchNameQuery || node.name?.toLowerCase().search(searchNameQuery.toLowerCase()) > -1) &&
                  this.isProtected(node)
                );
              },
              false,
              true
            ),
          ...treeFilters,
        ];
      }
    });
  }

  /**
   * Returns true is node is protected.
   *
   * @param  node  Node to check for protection.
   * @returns True if node is protected.
   */
  private isProtected(node: any): boolean {
    const nodeId = node.protectionSource.id;

    const isManuallySelected = this.isPure ? this.job.sourceIds?.includes(nodeId):
    this.job.sourceIds?.includes(nodeId) && node.isLeaf;

    const isAutoProtected = this.treeService.treeControl.checkAnyAncestor(node, parent =>
      this.job.sourceIds?.includes(parent.id as number)
    );

    const isExcluded = this.job?.excludeSourceIds?.includes(nodeId);
    const isParentExcluded = this.treeService.treeControl.checkAnyAncestor(node, parent =>
      this.job.excludeSourceIds?.includes(parent.id as number)
    );

    const isTagProtected = this.job?.vmTagIds?.some(tagIds => tagIds?.every(tagId => node.tagIds?.includes(tagId)));

    const isTagExcluded = this.job?.excludeVmTagIds?.find(tagIds =>
      tagIds?.every(tagId => node.tagIds?.includes(tagId))
    );

    return (
      (isManuallySelected || isAutoProtected || isTagProtected) && !isExcluded && !isParentExcluded && !isTagExcluded
    );
  }

  /**
   * Callback to pass the change event back to a form control. Defaults to a noop
   */
  propagateChange = (_value: SourceSelection) => {};

  /**
   * Update the view with a value passed from a form
   *
   * @param   value   the new form control value
   */
  writeValue(value: SourceSelection) {
    this.selection = value;
    this.initSelection();
  }

  /**
   * Registers a change event handler to use to propogate changes
   *
   * @param   fn   the callback function
   */
  registerOnChange(fn: (value: SourceSelection) => any) {
    this.propagateChange = fn;
  }

  /**
   * Register on touched. Not currently used, but required by the interface
   */
  registerOnTouched() {}
}
