import { IrisContext } from './iris-context.model';

/**
 * Returns true if the current user is in Platform Insights scope.
 *
 * @param irisContext The current iris context.
 * @returns boolean True if the user in DIaaS context.
 */
export function isPiaasScope(irisContext: IrisContext): boolean {
  return Boolean(irisContext?.selectedScope?._serviceType === 'piaas');
}
