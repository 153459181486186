import { ValidatorFn, UntypedFormControl } from '@angular/forms';

/**
 * Function to return an error object if a form control's value is whitespace.
 *
 * @param control The form control.
 * @return null if a valid string, otherwise error object.
 */
export const NoEmptyStringValidator: ValidatorFn = (control: UntypedFormControl) => {
  const isWhitespace = (control.value || '').trim().length === 0;
  return isWhitespace ? { emptyString: true } : null;
};
