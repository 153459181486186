import { Injectable } from '@angular/core';
import { ValueFilterSelection } from '@cohesity/helix';
import { flagEnabled, IrisContext, IrisContextService, isDmsScope } from '@cohesity/iris-core';
import { dmsEnvironments, dmsGroups, EnvGroup, envGroups, Environment } from '@cohesity/iris-shared-constants';
import { FilterValuesList, NestedFilterConfig } from '@cohesity/utils';
import { TranslateService } from '@ngx-translate/core';
import { isNil } from 'lodash';
import { Observable, of } from 'rxjs';
import { AdaptorAccessService } from 'src/app/core/services';


@Injectable({
  providedIn: 'root'
})
export class EnvironmentGroupsService {
  constructor(
    private adaptorAccessService: AdaptorAccessService,
    private irisContextService: IrisContextService,
    private translateService: TranslateService,
  ) {
  }

  /**
   * Get applicable environments and return as filter values.
   */
  getFilterValues(showOnlyTopLevelFor: EnvGroup[]): Observable<ValueFilterSelection[]> {
    const ctx = this.irisContextService.irisContext;
    const envGroupList = isDmsScope(ctx) ? dmsGroups : envGroups;
    const chosenEnvGroups = this.adaptorAccessService.getEnvironmentGroups(envGroupList);
    let nestedValues = chosenEnvGroups.map((filterVal): NestedFilterConfig => {
      let envSubValues = this.adaptorAccessService.getEnvironmentGroupEnvironments(filterVal, envGroupList);
      let prefix = 'enum.environment.';

      // Remove HyperVVSS filter from subvalues for virtual machines
      if (filterVal === 'vms') {
        envSubValues = envSubValues.filter(subEnv => subEnv !== Environment.kHyperVVSS);
      }

      // In DMS scope, not all environments are applicable.
      // TODO: use adaptor access service for this purpose.
      if (isDmsScope(ctx)) {
        switch (filterVal) {
          case 'office365':
            envSubValues = this.getSupportedO365Applications(ctx);
            break;
          case 'aws':
            envSubValues = this.getSupportedAwsApplications(ctx);
            prefix = 'environmentGroupFilter.aws.';
            break;
          case 'azure':
            envSubValues = this.getSupportedAzureApplications(ctx);
            // Overriding default behavior to remove single valued sub environments
            // As a result of the feature flags applied on the sub environments
            return {
              name: filterVal,
              prefix: 'environmentGroupFilter.azure.',
              subValues: envSubValues
            };
          case 'dbs':
            envSubValues = envSubValues.filter(env => {
              // Disable UDA (as an adapter) based on feature flag.
              if (env === Environment.kUDA) {
                return flagEnabled(ctx, 'dmsUdaEnabled');
              }
              return dmsEnvironments.includes(env as Environment);
            });
            break;
          default:
            envSubValues = envSubValues.filter(env => dmsEnvironments.includes(env as Environment));
            break;
        }
      }

      return {
        name: filterVal,
        prefix,
        // If there is one or less environments, don't display a sub list as the
        // parent level can adequately represent that one item.
        subValues: envSubValues.length > 1 ? envSubValues : undefined,
      };
    });

    if (isDmsScope(ctx)) {
      // Salesforce and Azure source type will always have one sub values.
      // Adding condition to avoid filter based on sub values for specified environments.
      const singleValueEnvironments = ['salesforce', 'azure'];
      nestedValues = nestedValues.filter(mappedValues => mappedValues.subValues?.length > 0 ||
        singleValueEnvironments.includes(mappedValues.name));
    }

    // Return in ValueFilterSelection type.
    let result = new FilterValuesList([
      {
        name: 'environments',
        prefix: 'enum.envGroup.longName.',
        values: [],
        nestedValues,
      }
    ], this.translateService).environments;

    // Need to use different label for AWS for filter
    const group = result?.find(g => g.value === 'vms');
    if (group) {
      const aws = group.subItems.find(i => i.value === 'kAWS');

      if (aws) {
        aws.label = this.translateService.instant('enum.environment.kAWS.filter');
      }
    }

    // Remove sub filters if specified by parent.
    result = result?.map(filterValue => ({
      ...filterValue,
      subItems: showOnlyTopLevelFor.includes(filterValue.value as EnvGroup) ? [] : filterValue.subItems,
    }));

    return of(result);
  }

  optionsToValueLabelMap(options: ValueFilterSelection[]): { [key: string]: string } {
    const valueToLabel: {[key: string]: string} = {};
    ( options || []).forEach(valueItem => {
      if (valueItem?.value && valueItem?.label) {
        valueToLabel[valueItem.value] = valueItem.label;
      }

      valueItem?.subItems?.forEach(subValueItem => {
        if (subValueItem?.value && subValueItem?.label) {
          valueToLabel[subValueItem.value] = subValueItem.label;
        }
      });
    });
    return valueToLabel;
  }

  /**
   * Returns list of supported Office365 application specific environment.
   */
  private getSupportedO365Applications(ctx: IrisContext): Environment[] {
    if (!flagEnabled(ctx, 'dmsOffice365ApplicationFilter')) {
      return null;
    }

    return [
      Environment.kO365Exchange,
      flagEnabled(ctx, 'dmsOffice365OneDriveWorkload') ? Environment.kO365OneDrive : null,
      flagEnabled(ctx, 'dmsOffice365SharepointWorkload') ? Environment.kO365Sharepoint : null,
      flagEnabled(ctx, 'dmsOffice365TeamsWorkload') ? Environment.kO365Teams : null,
      flagEnabled(ctx, 'dmsOffice365GroupsWorkload') ? Environment.kO365Group : null,
    ].filter(value => !isNil(value));
  }

  /**
   * Returns list of supported Amazon application specific environment.
   */
  private getSupportedAwsApplications(ctx: IrisContext): Environment[] {
    return [
      Environment.kAWSSnapshotManager,
      flagEnabled(ctx, 'dmsAwsRdsSupportEnabled') ? Environment.kRDSSnapshotManager : null,
      flagEnabled(ctx, 'dmsAwsS3Protection') ? Environment.kAwsS3 : null,
    ].filter(value => !isNil(value));
  }

  /**
   * Returns list of supported Amazon application specific environment.
   */
  private getSupportedAzureApplications(ctx: IrisContext): Environment[] {
    return [
      flagEnabled(ctx, 'dmsAzureVmWorkload') ? Environment.kAzureSnapshotManager : null,
      flagEnabled(ctx, 'dmsAzureSqlWorkload') ? Environment.kAzureSQL : null,
      flagEnabled(ctx, 'dmsAzureEntraIdWorkload') ? Environment.kAzureEntraID : null,
    ].filter(value => !isNil(value));
  }
}
