import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { DataIdModule, IconModule } from '@cohesity/helix';
import { TranslateModule } from '@ngx-translate/core';
import { UIRouterModule } from '@uirouter/angular';

import { RouterTabsComponent } from './router-tabs.component';

/**
 *  The module for Cohesity Tabs
 */
@NgModule({
  declarations: [RouterTabsComponent],
  exports: [RouterTabsComponent],
  imports: [
    CommonModule,
    IconModule,
    DataIdModule,
    MatTabsModule,
    UIRouterModule,
    TranslateModule.forChild(),
  ]
})
export class RouterTabsModule { }
