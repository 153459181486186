import { Pipe, PipeTransform } from '@angular/core';

import { addCacheBuster, HelixAssetsOptions } from './../helix-assets-options';
import { prefixSlash } from '../../../util';

/**
 * helix asset url will add the required cache butser and assets public path to the url.
 */
@Pipe({
  name: 'helixAssetUrl',
  standalone: true
})
export class HelixAssetUrlPipe implements PipeTransform {
  constructor(private helixAssetsOptions: HelixAssetsOptions) {}

  /**
   * Return the url with cache butser and helix assets public path.
   *
   * @param url The url.
   * @param returnAsCssUrl Optional flag if true then new url will be compatable with CSS.
   * @returns The url with cache butser and helix assets public path.
   */
  transform(url: string, returnAsCssUrl = false): string | null {
    if (url) {
      const newUrl = addCacheBuster([this.helixAssetsOptions.options.location, url], this.helixAssetsOptions.options);
      return returnAsCssUrl ? `url(${prefixSlash(newUrl)})` : newUrl;
    }

    return null;
  }
}
