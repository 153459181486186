/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityV2ApiConfiguration as __Configuration } from '../cohesity-v2api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CloudRetrieveTasks } from '../models/cloud-retrieve-tasks';
import { CreateCloudRetrieveTaskRespBody } from '../models/create-cloud-retrieve-task-resp-body';
import { CreateCloudRetrieveTaskRequest } from '../models/create-cloud-retrieve-task-request';
import { CloudRetrieveTask } from '../models/cloud-retrieve-task';
@Injectable({
  providedIn: 'root',
})
class CloudRetrieveTaskService extends __BaseService {
  static readonly GetCloudRetrieveTasksPath = '/data-protect/retrieve';
  static readonly CreateCloudRetrieveTaskPath = '/data-protect/retrieve';
  static readonly GetCloudRetrieveTaskByJobIdPath = '/data-protect/retrieve/{jobId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get the list of cloud retrieve tasks.
   *
   * Get the list of cloud retrieve tasks.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetCloudRetrieveTasksResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<CloudRetrieveTasks>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/data-protect/retrieve`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CloudRetrieveTasks>;
      })
    );
  }
  /**
   * Get the list of cloud retrieve tasks.
   *
   * Get the list of cloud retrieve tasks.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetCloudRetrieveTasks(regionId?: string,
    accessClusterId?: number): __Observable<CloudRetrieveTasks> {
    return this.GetCloudRetrieveTasksResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as CloudRetrieveTasks)
    );
  }

  /**
   * Create a cloud retrieve task.
   *
   * Create a cloud retrieve task.
   * @param params The `CloudRetrieveTaskService.CreateCloudRetrieveTaskParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to create a cloud retrieve.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateCloudRetrieveTaskResponse(params: CloudRetrieveTaskService.CreateCloudRetrieveTaskParams): __Observable<__StrictHttpResponse<CreateCloudRetrieveTaskRespBody>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/data-protect/retrieve`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CreateCloudRetrieveTaskRespBody>;
      })
    );
  }
  /**
   * Create a cloud retrieve task.
   *
   * Create a cloud retrieve task.
   * @param params The `CloudRetrieveTaskService.CreateCloudRetrieveTaskParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to create a cloud retrieve.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateCloudRetrieveTask(params: CloudRetrieveTaskService.CreateCloudRetrieveTaskParams): __Observable<CreateCloudRetrieveTaskRespBody> {
    return this.CreateCloudRetrieveTaskResponse(params).pipe(
      __map(_r => _r.body as CreateCloudRetrieveTaskRespBody)
    );
  }

  /**
   * List details about the cloud retrieve task with the specific job id.
   *
   * Returns the cloud retrieve task corresponding to the job id.
   * @param params The `CloudRetrieveTaskService.GetCloudRetrieveTaskByJobIdParams` containing the following parameters:
   *
   * - `jobId`: Specifies a job id of the cloud retrieve task.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetCloudRetrieveTaskByJobIdResponse(params: CloudRetrieveTaskService.GetCloudRetrieveTaskByJobIdParams): __Observable<__StrictHttpResponse<CloudRetrieveTask>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/data-protect/retrieve/${encodeURIComponent(params.jobId)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CloudRetrieveTask>;
      })
    );
  }
  /**
   * List details about the cloud retrieve task with the specific job id.
   *
   * Returns the cloud retrieve task corresponding to the job id.
   * @param params The `CloudRetrieveTaskService.GetCloudRetrieveTaskByJobIdParams` containing the following parameters:
   *
   * - `jobId`: Specifies a job id of the cloud retrieve task.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetCloudRetrieveTaskByJobId(params: CloudRetrieveTaskService.GetCloudRetrieveTaskByJobIdParams): __Observable<CloudRetrieveTask> {
    return this.GetCloudRetrieveTaskByJobIdResponse(params).pipe(
      __map(_r => _r.body as CloudRetrieveTask)
    );
  }
}

module CloudRetrieveTaskService {

  /**
   * Parameters for CreateCloudRetrieveTask
   */
  export interface CreateCloudRetrieveTaskParams {

    /**
     * Specifies the parameters to create a cloud retrieve.
     */
    body: CreateCloudRetrieveTaskRequest;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetCloudRetrieveTaskByJobId
   */
  export interface GetCloudRetrieveTaskByJobIdParams {

    /**
     * Specifies a job id of the cloud retrieve task.
     */
    jobId: number;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { CloudRetrieveTaskService }
