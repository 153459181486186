import { Injectable } from '@angular/core';
import { GetRegistrationInfoResponse, ProtectionSourcesServiceApi } from '@cohesity/api/v1';
import {
  CommonNoSqlRecoveryOptions,
  NoSqlObjectProperty,
  RecoverCassandraNoSqlObjectParams,
  RecoverCassandraSnapshotParams,
} from '@cohesity/api/v2';
import { flagEnabled, IrisContextService, isDmsScope } from '@cohesity/iris-core';
import { TranslateService } from '@ngx-translate/core';
import { get } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserService } from 'src/app/core/services';
import {
  Environment,
  JobEnvParamsV2,
  NoSqlRecoverySnapshotParams,
  RecoverSourceEnvParamsV2,
} from 'src/app/shared/constants';

import {
  NoSqlObjectDetails,
  NoSqlObjectPropertyOption,
  NoSqlObjectRename,
  NoSqlObjectRenameOption,
} from '../model/nosql-form-options';
import { NoSQLRecoveryObject } from '../model/nosql-recovery-object';
import { NoSqlSearchResultGroup } from '../model/nosql-search-result-group';
import { NoSqlObjectSearchResult, NoSqlProtectionGroupSearchResult, NoSqlSearchResult } from '../model/nosql-search-results';
import { RestoreTargetInfo } from '../model/recover-to-source';
import { Recovery } from '../model/recovery';
import { RestorePointSelection } from '../model/restore-point-selection';

@Injectable({
  providedIn: 'root'
})
export class NoSqlService {

  /**
   * Returns whether current user is in DMaaS scope.
   */
  get isDmsScope(): boolean {
    return isDmsScope(this.irisCtx.irisContext);
  }

  constructor(
    private protectionSourcesApi: ProtectionSourcesServiceApi,
    private irisCtx: IrisContextService,
    private translate: TranslateService,
    private userService: UserService,
  ) {}

  /**
   * Transform registered source data.
   *
   * @param info The source info node.
   * @returns  list of sources for recovery.
   */
  transformProtectionSourceToTargetData(info: GetRegistrationInfoResponse): RestoreTargetInfo[] {
    let sources: RestoreTargetInfo[];
    if (info.rootNodes) {
      const featureFlag = flagEnabled(this.irisCtx.irisContext, 'nosqlAllowUnverifiedSourceForRecovery');
      sources = info.rootNodes
        .filter(
          node =>
            featureFlag ||
            !(node.registrationInfo.refreshErrorMessage || node.registrationInfo.authenticationErrorMessage)
        )
        .map(node => {
          const customName = node.rootNode.customName || '';
          return { id: node.rootNode.id, name: node.rootNode.name, customName: customName } as RestoreTargetInfo;
        });
    }
    return sources || [];
  }

  /**
   * Gets all registered source name and id.
   *
   * @param env Enviornment type to filter the result.
   * @returns  Observable of registered mongodb source names.
   */
  getSourcesByEnvironment(env: string): Observable<RestoreTargetInfo[]> {
    return this.protectionSourcesApi.ListProtectionSourcesRegistrationInfo({
      environments: [Environment[env]]
    }).pipe(map(info => this.transformProtectionSourceToTargetData(info)));
  }

  /**
   * Filters the selection for objects and returns a list of snapshot ids for the selected objects.
   *
   * @param   selection   The items selected in the form.
   * @returns List of objects and the shopshot to which they need to be restored to.
   */
  getNoSqlObjectSnapshots(selections: RestorePointSelection[]): RecoverCassandraSnapshotParams[] {
    if (!selections || !selections.length) {
      return null;
    }

    const selection = selections[0];
    const selectedObjects: NoSqlSearchResult[] = this.getNoSqlSelectedObjects(selections);
    const snapshotToRestore: RecoverCassandraSnapshotParams = {
      snapshotId: selection.restorePointId
    };

    const objectsToRestore: RecoverCassandraNoSqlObjectParams[] = (selectedObjects || [])
      .map(object => ({
        objectName: object.name,
      }));

    snapshotToRestore.objects = objectsToRestore;

    if (selection.isPointInTime) {
      snapshotToRestore.pointInTimeUsecs = selection.timestampUsecs;
    }

    return [snapshotToRestore];
  }

  /**
   * Filters the selection for objects and returns a list of selected objects.
   *
   * @param   selection   The items selected in the form.
   * @returns List of objects to which they need to be restored to.
   */
  getNoSqlSelectedObjects(selections: RestorePointSelection[]): NoSqlSearchResult[] {
    if (!selections || !selections.length) {
      return [];
    }

    const selection = selections[0];
    const objectsInfo: NoSqlSearchResultGroup = selection.objectInfo as NoSqlSearchResultGroup;
    const selectedObjects: NoSqlSearchResult[] = objectsInfo.noSqlObjectSearchResults || [];

    let objectsToRestore: NoSqlSearchResult[] = [];
    // Filter out the protection groups and return the list of selected objects.
    if (
      !(selectedObjects.length === 1 &&
      selectedObjects[0].resultType === NoSqlProtectionGroupSearchResult.searchResultType)
    ) {
      objectsToRestore = (selectedObjects || [])
        .filter(object => object.resultType === NoSqlObjectSearchResult.searchResultType);
    }

    return objectsToRestore;
  }

  /**
   * Validates if object selection is of protection group type.
   *
   * @param   selection   The items selected in the form.
   * @returns if result type is of protection group.
   */
  isPGSearchResultType(selections: RestorePointSelection[]): boolean {

    const selection = selections[0];
    const objectsInfo: NoSqlSearchResultGroup = selection.objectInfo as NoSqlSearchResultGroup;
    const selectedObjects: NoSqlSearchResult[] = objectsInfo.noSqlObjectSearchResults || [];

    return selectedObjects[0].resultType === NoSqlProtectionGroupSearchResult.searchResultType;
  }

  /**
   * Add rename individual object mapping to the request.
   *
   * @param   objectDetails   The object details from the form.
   * @param   objectList   List of selected objects.
   * @returns List of objects to which they need to be restored to.
   */
  renameNoSqlSnapshotObjects(objectDetails: NoSqlObjectRename[], objectList: any) {
    objectDetails.forEach(item => {
      if (item.renameTo && item.renameTo.trim()) {
        const searchedObject =  objectList.find(object => object.objectName === item.name);
        if (searchedObject) {
          searchedObject.renameTo = item.renameTo;
        }
      }
    });
    return objectList;
  }

  /**
   * Add object properties mapping to the request.
   *
   * @param   objectDetails   The object details from the form.
   * @param   objectList   List of selected objects.
   * @returns List of objects to which they need to be restored to.
   */
  addNoSqlObjectsProperties(objectDetails: NoSqlObjectDetails[], objectList: any) {
    objectDetails.forEach(item => {
      if (item?.objectProperties?.length > 0) {
        const searchedObject =  objectList.find(object => object.objectName === item.name);
        if (searchedObject) {
          const objectProperties: NoSqlObjectProperty[] = [];
          item.objectProperties.forEach(object => {
            if (object && object.key !== '' && object.value !== '') {
              objectProperties.push({
                key: object.key,
                value: object.value,
              });
            }
          });
          searchedObject.objectProperties = objectProperties;
        }
      }
    });
    return objectList;
  }

  /**
   * Add object rename and properties mapping to the request.
   *
   * @param   rename   Object rename option from the form.
   * @param   objectProperties    Object properties option from the form.
   * @param   snapshots   List of object and snapshots.
   * @returns List of objects and the shopshot to which they need to be restored to.
   */
  addObjectsRenameProperties(rename: NoSqlObjectRenameOption,
    objectProperties: NoSqlObjectPropertyOption,
    snapshots: any) {
    if (!snapshots || !snapshots.length) {
      return null;
    }
    let objectList = snapshots[0].objects;
    if (rename?.objectDetails?.length) {
      objectList = this.renameNoSqlSnapshotObjects(rename.objectDetails, objectList);
    }
    if (objectProperties?.objectDetails?.length) {
      objectList = this.addNoSqlObjectsProperties(objectProperties.objectDetails, objectList);
    }
    snapshots[0].objects = objectList;
    return snapshots;
  }

  /**
   * show/hide rename option component.
   *
   * @param   selection   The items selected in the form..
   * @param   isRecoverTo    Recover to option selection.
   * @returns show/hide rename option component.
   */
  showRenameOption(selections: RestorePointSelection[], isRecoverTo: boolean): boolean {
    if (!isRecoverTo) {
      return false;
    } else if (selections && selections.length) {
      if (selections[0].objectInfo.resultType ===
        NoSqlProtectionGroupSearchResult.searchResultType) {
        return false;
      }
    }
    return true;
  }

  /**
   * Get CommonNoSQLRecoveryOptions for given NoSQL recovery job.
   *
   * @param     recovery   Recovery job.
   * @returns   CommonNoSQLRecoveryOptions for corresponding recovery job.
   */
  getCommonNoSQLRecoveryOptions(recovery: Recovery): CommonNoSqlRecoveryOptions {
    const recoveryEnvironment: string = recovery.environment;

    // e.g. cassandraParams, hdfsParams ...
    const jobParams = JobEnvParamsV2[recoveryEnvironment];

    // e.g. recoverCassandraParams, recoverHdfsParams ...
    const recoverJobParams = RecoverSourceEnvParamsV2[recoveryEnvironment];

    return get(recovery, ['reference', jobParams, recoverJobParams]);
  }

  /**
   * Get warnings for given NoSQL recovery job.
   *
   * @param     recovery   Recovery job.
   * @returns   List of warnings for corresponding recovery job.
   */
  getWarningsForNoSQLRecoveryJob(recovery: Recovery): string[] {
    const commonNoSQLRecoveryOptions = this.getCommonNoSQLRecoveryOptions(
      recovery);

    if (commonNoSQLRecoveryOptions &&
        commonNoSQLRecoveryOptions.warnings) {
      return commonNoSQLRecoveryOptions.warnings;
    } else {
      return [];
    }
  }

  /**
   * Get SnapshotParams[] for given NoSQL Recovery job.
   *
   * @param   recovery   Recovery job.
   * @returns SnapshotParams[] for corresponding recovery job.
   */
  getSnapshotsForNoSqlRecoveryJob(recovery: Recovery): NoSqlRecoverySnapshotParams {
    const recoveryEnvironment: string = recovery.environment;

    // e.g. cassandraParams, hdfsParams ...
    const jobParams = JobEnvParamsV2[recoveryEnvironment];

    // e.g. recoverCassandraParams, recoverHdfsParams ...
    const recoverJobParams = RecoverSourceEnvParamsV2[recoveryEnvironment];

    return recovery.reference[jobParams][recoverJobParams].snapshots as NoSqlRecoverySnapshotParams;
  }

  /**
   * NoSQL/Hadoop connectors have different recovery schema.
   * If the recovery job is of type NoSQL or Hadoop, create RecoveryObject[] from the SnapshotParams
   * provided in recovery schema.
   *
   * @param   recovery   Recovery job.
   * @returns list of RecoveryObject.
   */
  getRecoveryObjectsForNoSqlRecoveryJob(recovery: Recovery): NoSQLRecoveryObject[] {

    // NoSQL/Hadoop recovery jobs doesn't support selection of objects across different snapshots.
    // Get the first snapshot and iterate the objects within it.
    const snapshotParams = this.getSnapshotsForNoSqlRecoveryJob(recovery)[0];
    let recoveryObjects: NoSQLRecoveryObject[] = [];
    const environment: string = snapshotParams.objectInfo.environment;
    const id: string = snapshotParams.objectInfo.id;

    if (snapshotParams.objects && snapshotParams.objects.length) {
      recoveryObjects = snapshotParams.objects.map(object => {
        const snapshot = {
          ...snapshotParams,
          objectInfo: {
            id,

            // For DMaaS, there will always be a single object per snapshot and
            // its name is present in the objectInfo.
            name: this.isDmsScope && environment !== Environment.kSAPHANA ?
              snapshotParams.objectInfo.uuid : object.objectName,
            environment: environment
          }
        };
        return new NoSQLRecoveryObject(snapshot, object);
      });
    } else {

      // For Jobtag recovery objects are not populated.
      // Add a dummy RecoveryObject with 'Protection Group' as objectName.
      recoveryObjects.push(new NoSQLRecoveryObject({
        ...snapshotParams,
        objectInfo: {
          id: id,
          name: this.translate.instant('job'),
        }
      }, { objectName: this.translate.instant('job') }));
    }

    return recoveryObjects;
  }

  /**
   * Returns whether the user has the option to overwrite existing objects
   * during recovery. This option is restricted to only non-restricted users,
   * to avoid the scenario of a restricted user overwriting an object to which
   * it did not have access.
   *
   * @returns boolean indicating whether overwrite existing objects option is
   * available
   */
  hasRecoveryOverwriteObjectsOption(): boolean {
    return !this.userService.isRestrictedUser();
  }

  /**
   * Check if System keyspaces is selected for recovery.
   *
   * @param   objects        Restore point object selection list.
   * @returns   True if system keyspace is selected.
   */
  isSystemKeyspaceRestore(objects: RestorePointSelection[]): boolean {
    return (objects?.length && objects[0]?.
      objectInfo.keyspaceType === 'kSystem') ? true : false;
  }
}
