import { Directive, Input } from '@angular/core';
import { DataRenderer } from '../data-renderer.model';

/**
 * A base class that can simplify writing rendering components.
 */
@Directive()
export class BaseRendererComponent<ValueType, ParamsType = any, SecondaryType = any>
implements DataRenderer<ValueType, ParamsType, SecondaryType> {
  /**
   * The primary value for the renderer to show
   */
  @Input() value: ValueType;

  /**
   * An object with additional property that will determine how the value should
   * be rendered. If this is set, the renderer params should also be set and include
   * proprty names that can be read from here.
   */
  @Input() secondaryValue: SecondaryType;

  /**
   * The renderer config for the icon/title/subtitle being rendered
   */
  @Input() renderParams: ParamsType;

  /**
   * The translate function
   */
  @Input() translate: (key: string, params?: any) => string;
}
