/* tslint:disable */

/**
 * Specifies the index of the week in a month.
 * Used in combination with day to define the day in the month.
 * 'First' indicates that the first week should be chosen for specified
 * day of every month.
 * 'Second' indicates that the second week should be chosen for specified
 * day of every month.
 * 'Third' indicates that the third week should be chosen for specified
 * day of every month.
 * 'Fourth' indicates that the fourth week should be chosen for specified
 * day of every month.
 * 'Last' indicates that the last week should be chosen for specified
 * day of every month.
 */
export enum NumWeekInMonth {
  First = 'First',
  Second = 'Second',
  Third = 'Third',
  Fourth = 'Fourth',
  Last = 'Last'
}
