import { AppModuleConfig, StateAccessContext, StateAccessMap } from 'src/app/app-module.config';

const syslogSettingsAccess = (ctx: StateAccessContext) => ctx.FEATURE_FLAGS.syslogSettingsEnabled;

export const SyslogSettingsConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'cluster-new.syslog.**',
      url: '^/cluster/syslog',
      loadChildren: () => import('./syslog.module').then(m => m.SyslogSettingsModule),
    },
  ],

  getStateAccessMap(): StateAccessMap {
    return {
      'cluster-new.syslog': syslogSettingsAccess,
      'cluster-new.syslog.list': syslogSettingsAccess,
    };
  },
};
