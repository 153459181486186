import { AppModuleConfig, StateAccessContext, StateAccessMap } from 'src/app/app-module.config';

const clusterModify = (ctx: StateAccessContext) => ctx.CLUSTER_MODIFY;

export const AppsConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'ng-apps-management.**',
      url: '^/apps-management/settings',
      loadChildren: () => import('./apps.module').then(m => m.AppsModule),
    },
  ],
  allClusterMap: {
    'ng-apps-management': false,
  },
  getStateAccessMap(): StateAccessMap {
    return {
      'ng-apps-management': clusterModify,
    };
  },
};
