/* eslint-disable */
import { Injectable } from '@angular/core';

/**
 * Global configuration for CohesityV2Api services
 */
@Injectable({
  providedIn: 'root',
})
export class CohesityV2ApiConfiguration {
  rootUrl: string = '/v2';
}

export interface CohesityV2ApiConfigurationInterface {
  rootUrl?: string;
}
