import { Component } from '@angular/core';
import { BaseProtectionBuilderComponent } from 'src/app/modules/protection/protection-builder/components/base-protection-builder/base-protection-builder.component';

/**
 * Default value for parallel runs toggle.
 */
export const DefaultParallelRuns = false;

@Component({
  selector: 'coh-settings-list-parallel-runs',
  templateUrl: './settings-list-parallel-runs.component.html',
})
export class SettingsListParallelRunsComponent
  extends BaseProtectionBuilderComponent<boolean, any> {

  /**
   * Default parallel runs toggle value.
   */
  _value = DefaultParallelRuns;

  /**
   * Form control init.
   */
  initFormControl() {
    if (this.protectionGroup?.allowParallelRuns) {
      this.value = Boolean(this.protectionGroup.allowParallelRuns);
      this.formControl.setValue(this.value);
    }
  }
}
