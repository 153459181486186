import { ProtectionJob } from '@cohesity/api/v1';
import { NasProtocol, TimeOfDay } from '@cohesity/api/v2';
import { SourceSelection } from '@cohesity/iris-source-tree';
import { EnvironmentType, JobEnvParamsV1 } from 'src/app/shared';

import {
  AlertingPolicy,
  Priority,
  ProtectionGroupObject,
  PureRetention,
  QosPolicy,
} from './protection-group-object.model';


/**
 * V1 implementation for protection group adapter.
 */
export class ProtectionGroupObjectV1Adapter implements ProtectionGroupObject {

  constructor(private job: ProtectionJob) {}

  get alertOnStatus(): AlertingPolicy {
    return this.job.alertingPolicy;
  }

  get cloudMigration(): boolean {
    // TODO: Verify if this setting is correct.
    return this.job.cloudParameters && this.job.cloudParameters.failoverToCloud;
  }

  get description(): string {
    return this.job.description;
  }

  get environment(): EnvironmentType {
    return this.job.environment as any;
  }

  get fallbackToCrashConsistentSnapshot(): boolean {
    return this.envParameters && this.envParameters.fallbackToCrashConsistent;
  }

  get id(): number {
    return this.job.id;
  }

  get leverageHyperflexSnapshots(): boolean {
    return this.job.leverageStorageSnapshotsForHyperflex;
  }

  get leverageNutanixSnapshots(): boolean {
    return this.job.leverageNutanixSnapshots;
  }

  get leverageStorageSnapshots(): boolean {
    return this.job.leverageStorageSnapshots;
  }

  get migrationTargetName() {
    return '';
  }

  get name(): string {
    return this.job.name;
  }

  get nasProtocol(): NasProtocol {
    if (this.job.environmentParameters.nasParameters) {
      return {
        nasProtocol: this.job.environmentParameters.nasParameters.nasProtocol
      };
    }
  }

  get policyId(): string {
    return this.job.policyId;
  }

  get priority(): Priority {
    return this.job.priority;
  }

  get pureParams(): PureRetention {
    // TODO: Nilesh Implement this
    return undefined;
  }

  get qosPolicy(): QosPolicy {
    return this.job.qosType;
  }

  get quiesce(): boolean {
    return this.job.quiesce;
  }

  get selectedSourceId(): number {
    return this.job.parentSourceId;
  }

  get selection(): SourceSelection {
    return {
      excludeSourceIds: this.job.excludeSourceIds,
      sourceIds: this.job.sourceIds,
      sourceSpecialParameters: this.job.sourceSpecialParameters,
      vmTagIds: this.job.vmTagIds,
      excludeVmTagIds: this.job.excludeVmTagIds,
    };
  }

  get sla(): any {
    // TODO: The job params don't seem to match what the settings config is doing.
    // This needs to be addressed and fixed.
    return undefined;
  }

  get storageDomainId(): number {
    return this.job.viewBoxId;
  }

  get time(): TimeOfDay {
    return this.job.startTime as any;
  }

  get timeZone(): string {
    return this.job.timezone;
  }

  get excludeDisks(): any[] {
    return [];
  }

  get excludePhysicalRdm(): boolean {
    return this.envParameters && this.envParameters.skipPhysicalRDMDisks;
  }

  get remoteView(): any {
    return {
      name: this.job.remoteViewName,
      enabled: this.job.createRemoteView,
    };
  }

  /**
   * Get the job environment parameters based on the job's environment property.
   *
   * @returns The environment params. This is typed to any, since the possible values are different.
   */
  private get envParameters(): any {
    return this.job.environmentParameters[JobEnvParamsV1[this.job.environment]];
  }
}
