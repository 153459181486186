// service/factory: cFocus
// directive: cFocus
//-----------------------------//

angular.module('C.focus', [])

// Use this service by injecting cFocus dependency
// and then calling it as a function
.factory('cFocus', ['$timeout', '$window',
    function($timeout, $window) {
        /**
         * moves the browser focus to the element having
         * the ID value provided. Will select any existing
         * text if selectText boolean is true.
         * example: cFocus('idOfElement', true);
         * @param  {String} id of the element to be focused
         * @param  {Boolean} selectText  select existing text in the input field?
         * @return {Void}
         */
        return function cFocus(id, selectText) {
            // default selectText to false
            selectText = !!selectText;
            // timeout makes sure that is invoked after any other event has been triggered.
            // e.g. click events that need to run before the focus or
            // inputs elements that are in a disabled state but are enabled
            // when those events are triggered.
            $timeout(function() {
                var element = $window.document.getElementById(id);
                if (element && selectText) {
                    element.select();
                } else if (element) {
                    element.focus();
                }
            });
        };
    }
])

// this directive allows for defining an event to initiate a focus
// and the id of the element that will recieve focus.
// ex: <button c-focus-event="click" c-focus-event-id="our-text-field">some button</button>
// when clicking on the above button, the field with id="our-text-field" will receive focus
.directive('cFocusEvent', ['cFocus',
    function(cFocus) {
        return function(scope, elem, attr) {
            elem.on(attr.cFocusEvent, function() {
                cFocus(attr.cFocusEventId);
            });

            // Removes bound events in the element itself
            // when the scope is destroyed
            scope.$on('$destroy', function() {
                element.off(attr.cFocusEvent);
            });
        };
    }
]);
