import { DomainController } from '@cohesity/api/v2';

/**
 * Doamin controller status indicator.
 */
export const DomainControllerStatus = {
  Reachable: {
    statusLabel: 'reachable', iconType: 'success',
  },
  Unreachable: {
    statusLabel: 'unreachable', iconType: 'error',
  },
  Incompatible: {
    statusLabel: 'incompatible', iconType: 'cancel',
  },
  Flapping: {
    statusLabel: 'flapping', iconType: 'warning',
  },
  ClockSkew: {
    statusLabel: 'clockSkew', iconType: 'warning',
  }
};

/**
 * Available domain controller statuses. Added 'Unreachable' (default)
 */
export type AvailableDomainControllerStatus = 'Unreachable' | DomainController['status'];

/**
 * The icon map for each domain controller status.
 */
export const DomainControllerStatusIconMap: Record<AvailableDomainControllerStatus, string> = {
  Reachable: 'check_circle!success',
  Incompatible: 'cancel!critical',
  Flapping: 'warning!warning',
  Unreachable: 'error!critical',
  ClockSkew: 'warning!warning',
};
