import { Injectable } from '@angular/core';
import { SearchServiceApi } from '@cohesity/api/v2';
import { Observable, of } from 'rxjs';
import {
  FilterFieldOption,
  IFieldFilterService,
  ProtectionStatus,
  protectionStatusValueLabelKey,
} from '../../constants';

@Injectable({
  providedIn: 'root',
})
export class ProtectionFilterService implements IFieldFilterService {
  /**
   * static list of protection status filter options
   */
  private protectionStatusValueOptions: FilterFieldOption[] = Object.values(ProtectionStatus)
    .map((status) => ({
      key: status,
      labelKey: protectionStatusValueLabelKey[status],
    }));

  /**
   * Protection status option generator
   *
   * @returns All static options for the possible protection statuses
   */
  getFilterOptions(): Observable<FilterFieldOption[]> {
    return of(this.protectionStatusValueOptions);
  }

  /**
   * consolidated protection status filters into a format that API understands
   *
   * @param selectedValues currently applied protection filters
   * @returns protection status filter that API understands
   */
  transformFilterValues(selectedValues: string[]): SearchServiceApi.SearchObjectsParams {
    const apiFilters: SearchServiceApi.SearchObjectsParams = {};

    if (selectedValues.length) {
      const isProtectedSelected = selectedValues.includes(protectionStatusValueLabelKey[ProtectionStatus.protected]);
      const isNotProtectedSelected = selectedValues.includes(
        protectionStatusValueLabelKey[ProtectionStatus.unprotected]
      );

      if (!isProtectedSelected || !isNotProtectedSelected) {
        apiFilters.isProtected = isProtectedSelected;
      }
    }

    return apiFilters;
  }
}
