import { TotpKeyInfo } from '@cohesity/api/v2';

/**
 * The interface to MFA Verification
 */
export interface MfaVerification {

  /**
   * The security token which is used for authentication.
   */
  securityToken: MfaSecurityToken;

  /**
   * The email token which is used for authentication.
   */
  emailToken: MfaEmailToken;
}

/**
 * The interface for security token for MFA.
 */
export interface MfaSecurityToken {
  /**
   * The security token which is used for authentication.
   */
  securityToken: string;
}

/**
 * The interface for email token for MFA.
 */
export interface MfaEmailToken {
  /**
   * The email token which is used for authentication.
   */
  emailToken: string;
}

/**
 * The interface for user MFA setup.
 */
export interface UserMfaToken extends TotpKeyInfo {
  /**
   * The user id associated with MFA setup.
   */
  userId?: string;

  /**
   * The email address of the logged user.
   */
  email?: string;
}

/**
 * Enum for the supported MFA types.
 */
export enum MFAType {
  totp = 'Totp',
  email = 'Email'
}
