import { BezierEdgeStyleRenderer, IRenderContext, Visual, SvgVisual } from 'yfiles';

/**
 * This class extends the bezier edge renderer to include a css style property based on the
 * edge's property
 */
export class StyledBezierEdgeRenderer extends BezierEdgeStyleRenderer {
  constructor(
    /**
     * The name of the property on the edge tag that contains the style property
     */
    private tagStyleProperty: string,

    /**
     * A class name that should always be applied to the edge
     */
    private edgeClass: string = 'edge'
  ) {
    super();
  }

  /**
   * Creates the visual for the edge.
   *
   * @param context The context that contains the information needed to create the visual.
   * @returns The edge Visual
   */
  createVisual(context: IRenderContext): Visual {
    const visual = super.createVisual(context);
    const edgeStyle = this.edge?.tag?.[this.tagStyleProperty];
    let className = this.edgeClass;
    if (edgeStyle) {
      className += ' ' + edgeStyle;
    }
    if (className && visual instanceof SvgVisual) {
      visual.svgElement.setAttribute('class', className);
    }
    return visual;
  }
}
