import { UntypedFormControl, ValidatorFn } from '@angular/forms';

/**
 * username form control validator.
 *
 * @param   control  Form control to validate the username input.
 * @return  Form control error object.
 */
export const usernameValidator: ValidatorFn = (control: UntypedFormControl) => {
  if (!control.value) {
    return null;
  }
  const invalidChars = /["/+=;,?*%&<>[\]:|]/;
  // Username must be less than 256 chars and must not contain certain characters.
  if (control.value.length > 256) {
    return { usernameLengthError: true };
  }

  if (invalidChars.test(control.value)) {
    return { usernameInvalidCharsError: true };
  }

  return null;
};
