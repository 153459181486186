import { Injectable } from '@angular/core';
import { UdaConnectParams } from '@cohesity/api/v1';
import { UdaSourceRegistrationParams } from '@cohesity/api/v2';
import { IrisContextService, isDmsScope } from '@cohesity/iris-core';
import {
  UdaRegistrationFormParams,
  UdaRegistrationFormTransformer,
  UdaRegistrationPrimaryFieldParams,
} from 'src/app/modules/sources-shared/models';
import { GenericSourceFormParams } from './generic-source-form.params';

/**
 * Transformer that converts between iris UDA source registration request and
 * the UDA source registration form data.
 */
@Injectable({
  providedIn: 'root',
})
export class UdaGenericSourceFormTransformer implements UdaRegistrationFormTransformer<GenericSourceFormParams> {

  /**
   * Indicates if the current user is in DMaaS context.
   */
  get isDmsScope(): boolean {
    return isDmsScope(this.contextService.irisContext);
  }

  constructor(
    private contextService: IrisContextService,
  ) {}


  /**
   * Helper method for transforming the source registration data captured from
   * the user to a type accepted by the registration iris API.
   *
   * @param   updatedform   Registration data captured for the UDA source.
   * @param   originalForm   Registration data for the UDA source pre-edit.
   *                         This can be null for the 'create' workflow.
   * @returns Transformed output required for registering the UDA source.
   */
  transformFromRegistrationForm(
    updatedform: UdaRegistrationFormParams<GenericSourceFormParams>,
    originalForm: UdaRegistrationFormParams<GenericSourceFormParams> = null
  ): UdaSourceRegistrationParams {

    // Mount view is not supported in DMaaS.
    const mountView = this.isDmsScope ?
      false : (
        // originalForm is truthy for an edit workflow. We take the mountView
        // flag from the original form as this is a disabled field and not
        // part of the updatedform.
        originalForm ?
          originalForm.sourceParams.primaryFieldParams.mountView :
          updatedform.sourceParams.primaryFieldParams.mountView || false
      );
    return {
      sourceType: updatedform.sourceType,
      osType: updatedform.sourceParams.hostOsType as any,
      hosts: updatedform.sourceParams.primaryFieldParams.hosts,
      credentials: updatedform.sourceParams.primaryFieldParams.credentials,
      scriptDir: updatedform.sourceParams.primaryFieldParams.scriptDir,
      mountView: mountView,

      // Contents of viewParams (i.e: mountDir) are deprecated now. We pass
      // null to ensure backward compatibility.
      viewParams: null,
      sourceRegistrationArgs: updatedform.sourceParams.primaryFieldParams.sourceArgs,
      sourceRegistrationArguments: null,
    };
  }

  /**
   * Helper method to convert the primary field params fetched via api to a
   * format accepted by the UI.
   *
   * @param   udaConnectParams   V1 source registration data.
   * @returns Primary fields params to be consumed by UI.
   */
  transformConnectParamsToPrimaryFieldParams(udaConnectParams: UdaConnectParams): UdaRegistrationPrimaryFieldParams {
    return {
      hosts: udaConnectParams.hosts,
      credentials: {
        username: udaConnectParams?.credentials?.username || null,
        password: null,
      },
      showCustomOptions: udaConnectParams.mountView || !!udaConnectParams.sourceArgs,
      mountView: udaConnectParams.mountView ?? false,
      mountDir: udaConnectParams.mountDir || null,
      scriptDir: udaConnectParams.scriptDir,
      sourceArgs: udaConnectParams.sourceArgs || null,
    };
  }

  /**
   * Convert UDA source registration v1 API model to the registration form
   * model.
   *
   * @param   udaParams   V1 source registration data.
   * @returns  Source registration data to be consumed by UI.
   */
  transformV1ToRegistrationForm(
    udaParams: UdaConnectParams
  ): UdaRegistrationFormParams<GenericSourceFormParams> {
    return {
      sourceType: udaParams.sourceType,
      sourceParams: {
        hostOsType: udaParams.hostType,
        primaryFieldParams: this.transformConnectParamsToPrimaryFieldParams(udaParams),
      }
    };
  }

  /**
   * Convert UDA source registration v2 API model to the registration form
   * model.
   *
   * @param   udaParams   V2 source registration data.
   * @returns  Source registration data to be consumed by UI.
   */
  transformV2ToRegistrationForm(
    udaParams: UdaSourceRegistrationParams
  ): UdaRegistrationFormParams<GenericSourceFormParams> {
    return {
      sourceType: udaParams.sourceType,
      sourceParams: {
        hostOsType: udaParams.osType,
        primaryFieldParams: {
          hosts: udaParams.hosts,
          credentials: {
            username: udaParams?.credentials?.username || null,
            password: null,
          },
          showCustomOptions: udaParams.mountView || !!udaParams.sourceRegistrationArgs,
          mountView: udaParams.mountView ?? false,
          mountDir: udaParams?.viewParams?.mountDir || null,
          scriptDir: udaParams.scriptDir,
          sourceArgs: udaParams.sourceRegistrationArgs || null,
        },
      }
    };
  }
}
