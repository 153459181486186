import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { ArchivalTargetSummaryInfo } from '@cohesity/api/v2';
import { flagEnabled, IrisContextService, isDmsScope } from '@cohesity/iris-core';
import { TranslateService } from '@ngx-translate/core';
import { UIRouterGlobals } from '@uirouter/core';
import { Environment, JobRunType, leafObjectTypeMap, parentObjectTypeMap } from 'src/app/shared/constants';
import { isNoSqlHadoopSource } from 'src/app/util/env-utils';
import { DialogService } from 'src/app/core/services';

import { NoSqlSearchResultGroup } from '../../model/nosql-search-result-group';
import { NoSqlSearchResult } from '../../model/nosql-search-results';
import { ObjectSearchResult } from '../../model/object-search-result';
import { ProtectionGroupSearchResult } from '../../model/protection-group-search-result';
import { RestorePointSelection } from '../../model/restore-point-selection';
import { ViewSelectedObjectsDialogComponent } from '../view-selected-objects-dialog/view-selected-objects-dialog.component';
import { FileSearchResultGroup } from '../../model/file-search-result-group';

/**
 * Displays summary information for items selected for a recovery.
 * The main rules are:
 * 1. If a single item is selected, show its name. If more than one is selected, show the number.
 * 2. Check for protection groups, and use the same rules to show the name or the number.
 * 3. The object count is determined by checking the length of objectIds - this will count all of
 *    the objects included within a protection group run.
 * 4. If all objects are set to recovery from their default restore point, the date will show as "latest".
 * 5. If a single object is selected, or all snapshots are the same (non-latest) date, the date will show,.
 * 6. If different recovery points are shown, the label will show as 'Mixed'.
 */
@Component({
  selector: 'coh-object-selection-summary',
  templateUrl: './object-selection-summary.component.html',
  styleUrls: ['./object-selection-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ObjectSelectionSummaryComponent {
  /**
   * The selected recovery points to show details for.
   */
  @Input() selection: RestorePointSelection[];

  /**
   * The label to use for the object type.
   */
  @Input() objectLabel: string;

  /**
   * The label to use for the snapshot.
   */
  @Input() snapshotLabel: string;

  /**
   * The label to use for the parent object type.
   */
  @Input() parentObjectLabel: string;

  /**
   * The label to use for the leaf object type.
   */
  @Input() leafObjectLabel: string;

  /**
   * Determines whether to show snapshot label or not.
   */
  @Input() showSnapshotLabel = true;

  /**
   * Determines whether to show location label or not.
   */
  @Input() showLocationLabel = true;

  /**
   * Determines type of vmware.
   */
  @Input() vmwareType = '';

  // Flag for vmware Ui improvement.
  // Show dialog only for vmware and it is not dmaas environment and it is not
  // file level recovery and it is not virtual disk or instant volume mount
  // recovery.
  get recoveryVmwareUiImprovementEnabled(): boolean {
    return this.selection?.[0]?.objectInfo?.environment === Environment.kVMware &&
      flagEnabled(this.irisContextService.irisContext, 'recoveryVmwareUiImprovement') &&
      !isDmsScope(this.irisContextService.irisContext) &&
      !['virtualDisks', 'instantVolumeMount'].includes(this.vmwareType) &&
      !(this.selection?.[0]?.objectInfo?.resultType === FileSearchResultGroup.fileResultGroupType);
  }

  /**
   * Gets the list of object restore points (non protection groups) being recovered.
   */
  get objects(): RestorePointSelection[] {
    if (!this.selection) {
      return [];
    }
    return this.selection.filter(item => item?.objectInfo?.resultType === ObjectSearchResult.objectResultType);
  }

  /**
   * Gets the total object count - this includes the number of objects protected within a backup run.
   */
  get objectCount(): number {
    if (!this.selection) {
      return 0;
    }
    return this.selection.reduce((totalCount, item) => totalCount + item?.objectIds?.length, 0);
  }

  /**
   * Gets the protection group selections being recovered.
   */
  get protectionGroups(): RestorePointSelection[] {
    if (!this.selection) {
      return [];
    }
    return this.selection.filter(
      item => item?.objectInfo?.resultType === ProtectionGroupSearchResult.protectionGroupResultType
    );
  }

  /**
   * Recoverying latest snapshot if the selectin timestamps matches the objectInfo latest timestamp for
   * all selections.
   */
  get isLatestSnapshot(): boolean {
    if (!this.selection) {
      return false;
    }
    return this.selection.every(item => item?.timestampUsecs === item?.objectInfo?.restoreTimestampUsecs ||
      item?.timestampUsecs === -1);
  }

  /**
   * Mixed snapshots are when at least one snapshot is different from another.
   */
  get isMixedSnapshot(): boolean {
    if (!this.selection || this.selection.length <= 1) {
      return false;
    }
    return this.selection.some(item => item?.timestampUsecs !== this.snapshotTimestampUsecs);
  }

  /**
   * Get the location type information for the snapshot.
   */
  get snapshotLocationType(): 'local' | 'mixed' | 'archival' | 'storageArray' | 'dms' {
    if (isDmsScope(this.irisContextService.irisContext)) {
      return 'dms';
    }

    const restorePoint = this.selection?.[0];

    if (!restorePoint) {
      return;
    }

    const archiveTargetName = restorePoint?.archiveTargetInfo?.targetName;
    const mixed = this.selection?.some(
      item => item?.archiveTargetInfo?.targetName !== archiveTargetName
    );

    if (mixed) {
      // Return mixed if not all snapshots are in the same location
      return 'mixed';
    }

    if (restorePoint.snapshotRunType === JobRunType.kStorageArraySnapshot) {
      return 'storageArray';
    }

    if (!archiveTargetName) {
      // Show local for snapshots.
      return 'local';
    }

    return 'archival';
  }

  /**
   * Look up the archive target info for the first selected object.
   */
  get archivalTargetInfo(): ArchivalTargetSummaryInfo {
    return this.selection?.[0]?.archiveTargetInfo;
  }

  /**
   * Gets the timestamp for the first object.
   */
  get snapshotTimestampUsecs(): number {
    if (!this.selection || !this.selection.length) {
      return null;
    }
    return this.selection[0].timestampUsecs;
  }

  /**
   * Gets the object name when a single object is being recovered. A protection group with one object will
   * show the number of objects rather than the object name, since the group information only includes the
   * object's id.
   */
  get objectName(): string {
    if (this.objects.length === 1) {
      return this.objects[0]?.objectInfo?.name;
    }
    return null;
  }

  /**
   * Gets the protection group name when a single protection group is being recovered.
   */
  get protectionGroupName(): string {
    if (this.protectionGroups.length === 1) {
      return this.protectionGroups[0]?.objectInfo?.name;
    }
    return null;
  }

  /**
   * Gets the Environment.
   */
  get isNoSqlHadoopEnviroment(): boolean {
    if (!this.selection || !this.selection.length) {
      return false;
    }
    return isNoSqlHadoopSource(this.selection[0]?.objectInfo?.environment as Environment);
  }

  /**
   * Gets the list of object restore points (non protection groups) being recovered.
   */
  get noSQLObjects(): RestorePointSelection[] {
    if (!this.selection) {
      return [];
    }
    return this.selection.filter(item => item?.objectInfo?.resultType === NoSqlSearchResultGroup.searchResultType);
  }

  /**
   * Gets the object selection group from the restore point.
   */
  get noSQLObjectInfo(): NoSqlSearchResult[] {
    if (!this.selection) {
      return null;
    }
    const searchResult = this.selection[0]?.objectInfo as NoSqlSearchResultGroup;
    return searchResult.noSqlObjectSearchResults || [];
  }

  /**
   * Gets the total parent object count - this includes the number of objects protected within a backup run.
   */
  get parentObjectCount(): number {
    return this.noSQLObjectInfo.filter((object) =>
      object.objectType === parentObjectTypeMap[object.environment]).length;
  }

  /**
   * Gets the total leaf object count - this includes the number of objects protected within a backup run.
   */
  get leafObjectCount(): number {
    return this.noSQLObjectInfo?.filter((object) => object.objectType === leafObjectTypeMap[object.environment]).length;
  }

  /**
   * Gets the parent object name when a single object is being recovered.
   */
  get parentObjectName(): string | number {
    const Objects = this.noSQLObjectInfo?.filter((object) =>
      object.objectType === parentObjectTypeMap[object.environment]);
    return Objects.length === 1 ? Objects[0].name : Objects.length;
  }

  /**
   * Gets the leaf object name when a single object is being recovered.
   */
  get leafObjectName(): string | number {
    const Objects = this.noSQLObjectInfo?.filter((object) =>
      object.objectType === leafObjectTypeMap[object.environment]);
    return Objects.length === 1 ? Objects[0].name : Objects.length;
  }

  constructor(
    private irisContextService: IrisContextService,
    private translateService: TranslateService,
    readonly uiRouterGlobals: UIRouterGlobals,
    private dialogService: DialogService,
  ) {
  }

  // Show view selected objects dialog.
  viewSelectedObjects() {
    this.dialogService.showDialog(ViewSelectedObjectsDialogComponent, this.selection);
  }
}
