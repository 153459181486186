//  MODULE: cCharts

(function(angular, undefined) {

  angular.module('C.charts', [])
    // TODO: remove chart examples, including this route config, for production build
    .config(configFn)
    .directive('cChart', cChartFn);

  function configFn(Highcharts) {
    // turn off highcharts UTC default setting.
    // Charts will use the appropriate timezone settings
    Highcharts.setOptions({
      global: {
        useUTC: false
      }
    });
  }

  function cChartFn(
    $timeout, DateTimeService, cUtils, $translate, Highcharts,
    CHART_CONFIG, CHART_COLORS) {

    /**
     * Number decimals to display for any bytes calculation.
     * For example: 5.231 TiB
     */
    var bytesPrecision = 3;

    return {
      restrict: 'E',
      scope: {
        // Single config object for chart setup.
        // This can include any desired properties from highcharts api.
        // Can/should also include "loading" boolean property to display spinner
        // and "chartType" for dictating the default chart config per options
        // below.
        chartConfig: '=',
        // chartExtremes is used for sharing zoom levels between multiple
        // datetime charts, as seen in performance.js
        chartExtremes: '=?',

        // Optional attribute sniffed value, if present then reset the zoom
        // while initializing the chart else use scope.chartExtremes value.
        // resetZoomOnInit: '?',
      },
      templateUrl: 'app/global/cCharts/cCharts.html',
      link: function cChartLinkFn(scope, elem, attrs) {

        // Wrap Highcharts object around highcharts-more
        // Since additional chart types like arearange, areaspline are not available
        require('highcharts/highcharts-more')(Highcharts);

        var $element = angular.element(elem);
        var chartHeight;
        // Highcharts mimicks php's strftime date formating options:
        // http://php.net/manual/en/function.strftime.php
        // TODO: Sync these with locale_settings.json
        var customDateTimeLabelFormats = {
          millisecond: '%H:%M:%S.%L',
          second: '%H:%M:%S',
          minute: '%H:%M',
          hour: '%l:%M%P',
          day: '%m/%d',
          week: '%m/%d',
          month: '%b \'%y',
          year: '%Y'
        };

        // chartInstanceOptions will hold the configuration for this specific
        // chart, which will ultimately be a combination of the provided
        // chartType, passed in series data, and any additionalConfig that's
        // passed
        var chartInstanceOptions = {};

        // defaultChartConfigs will be predefined chart styles/types
        var defaultChartConfigs = [];

        // compactChartConfigs will be predefined compact override configs for
        // charts
        var compactChartConfigs = [];

        /**
         * Shorthand pass-through for $translate.instant.
         */
        var xlate = $translate.instant;

        var afterRenderTimeout;
        var configTimeout;
        var xAxisDateTime;
        var applyLabelClass;
        var customReduxRedraw;
        var edgeExtend;

        // function for special use cases in which we need to determine data
        // after chart initialiation and inject it into a DOM element within the
        // chart
        function afterRender() {
          // force a redraw of the chart, this fixes some layout issues where
          // highcharts extends outside of its bounding box, then add rendered
          // class which will animate the opacity to reveal the chart
          afterRenderTimeout = $timeout(function() {
            var titleElem;

            if (['pie', 'donut', 'storageDonut'].includes(scope.chartConfig.chartType) &&
              !scope.chartConfig.loading) {
              titleElem = document.getElementById('chart-title-count-' + scope.chartId);
              if (!titleElem) {
                // if title element doesn't exist, do nothing
                // but allow the function to continue
              } else if (scope.chartConfig.chartType === 'storageDonut') {
                titleElem.innerHTML = cUtils.bytesToSize(scope.chart.series[0].total).string;
              } else {
                titleElem.innerHTML = scope.chart.series[0].total;
              }

            }
            scope.chart.reflow();
            elem.find('.c-chart').addClass('rendered');

            // if we haven't already done so, set an explicit height on the
            // c-chart element, this will prevent content jumping. if we need to
            // destroy and rebuild the chart on config changes.
            if (!chartHeight) {
              chartHeight = $element.find('.c-chart').height();
              $element.css('height', chartHeight);
            }
            afterRenderTimeout = false;
          }, 500);
        }

        // generate a div ID for this chart
        scope.chartId = 'chart-' + Math.floor(Math.random() * 100000);

        // if present then reset the zoom while initializing the chart.
        scope.resetZoomOnInit = attrs.hasOwnProperty('resetZoomOnInit');

        // 'basic' chart configuration
        //
        // {
        //     series: [{
        //         name: 'minutes',
        //         data: [
        //             ['Norcal Backup', 46],
        //             ['Social Backup', 59],
        //             ['Clone Job', 63],
        //             ['Restore VM 1', 53],
        //             ['Backup Job A', 31],
        //             ['Clone Job 2', 69],
        //             ['Backup Job B', 54]
        //         ]
        //     }]
        // }

        defaultChartConfigs.basic = {
          loading: false,
          chart: {
            type: 'column',
            renderTo: scope.chartId,
            plotBorderWidth: 1,
            plotBorderColor: CHART_COLORS.plotBorder,
            marginTop: 20,
            backgroundColor: 'transparent',
            plotBackgroundColor: CHART_COLORS.plotBackground,
            height: 250,
            style: {
              fontFamily: 'var(--hlx-typography-font-family, inherit)',
            }
          },
          series: [{
            minPointLength: 3,
            data: []
          }],
          credits: {
            enabled: false
          },
          title: {
            text: null
          },
          colors: [CHART_COLORS.brand],
          plotOptions: {
            column: {
              borderWidth: 0
            },
            series: {
              stickyTracking: false,
              pointWidth: 18
            }
          },
          xAxis: {
            type: 'category',
            tickColor: 'transparent',
            labels: {
              formatter: hcTranslateFormatter,
              rotation: 0,
              style: {
                fontSize: '11px',
                lineHeight: '12px',
                color: '#868686',
                fontFamily: 'var(--hlx-typography-font-family, inherit)',
              }
            }
          },
          yAxis: {
            min: 0,
            tickPixelInterval: 20,
            tickColor: CHART_COLORS.plotBorder,
            gridLineColor: CHART_COLORS.gridLines,
            alternateGridColor: CHART_COLORS.alternateGridColor,
            title: {
              text: null,
              align: 'high',
              offset: -22,
              rotation: 0,
              y: -10,
              style: {
                fontSize: '11px',
                lineHeight: '12px',
                color: '#868686'
              }
            },
            labels: {
              formatter: hcTranslateFormatter,
              x: -6,
              step: 2,
              style: {
                color: '#868686'
              }
            }
          },
          zAxis: {
            labels: {
              formatter: hcTranslateFormatter,
            },
          },
          legend: {
            labelFormatter: hcTranslateFormatter,
            enabled: false,
          },
          tooltip: {
            pointFormat: ['<b>{point.y:.1f} ', xlate('GiB'), '</b>'].join(''),
          }
        };

        // 'basicBytes' chart configuration copies 'basic' settings and replaces
        // a few values so our chart will scale bytes automatically
        defaultChartConfigs.basicBytes =
          angular.merge({}, defaultChartConfigs.basic, {
            chart: {
              marginTop: 1,
              // 1 pixel margins on left and right prevent
              // plotBorder from being cut off
              marginRight: 1,
              marginLeft: 1,
              marginBottom: 20,
              height: 250,
              backgroundColor: 'transparent',
            },
            xAxis: {
              tickColor: 'transparent',
              type: 'datetime',
              startOnTick: false,
              endOnTick: false,
              dateTimeLabelFormats: customDateTimeLabelFormats,
              minPadding: 0.06,
              maxPadding: 0.04,
              labels: {
                // unassign 'basic' chart's label formatter so Highcharts will
                // handle the datetime labeling.
                formatter: undefined,
              },
            },
            legend: {
              enabled: true,
              layout: 'horizontal',
              verticalAlign: 'top',
              align: 'right',
              borderWidth: 0,
              y: -9,
              x: -30,
            },
            yAxis: {
              gridLineColor: CHART_COLORS.gridLines,
              alternateGridColor: 'transparent',
              title: {
                text: null,
              },
              labels: {
                useHTML: true,
                align: 'left',
                y: 10,
                x: 2,
                formatter: bytesLabelFormatter,
              },
            },
            tooltip: {
              useHTML: true,
              pointFormat: undefined,
              formatter: bytesTooltipFormatter,
            },
          }
        );

        // 'basicHorizontal' chart configuration
        //
        // {
        //     series: [{
        //         name: 'space consumed',
        //         data: [285, 248, 179, 160, 135]
        //     }]
        // }
        //
        // for data Labels, also pass this via additionalConfig, otherwise
        // bars will be numbered only
        // {
        //     xAxis: {
        //         categories: [
        //          'Norcal Backup Job',
        //          'Socal Backup Job',
        //          'Backup Job A',
        //          'Backup Job B',
        //          'Backup C',
        //         ]
        //     }
        // }

        defaultChartConfigs.basicHorizontal = {
          loading: false,
          chart: {
            renderTo: scope.chartId,
            type: 'bar',
            plotBorderWidth: 1,
            plotBorderColor: CHART_COLORS.plotBorder,
            backgroundColor: 'transparent',
            plotBackgroundColor: CHART_COLORS.plotBackground,
            style: {
              fontFamily: 'var(--hlx-typography-font-family, inherit)',
            }
          },
          credits: {
            enabled: false
          },
          colors: [CHART_COLORS.brand],
          title: {
            text: null
          },
          xAxis: {
            tickColor: 'transparent',
            title: {
              text: null
            },
            labels: {
              align: 'left',
              x: 2,
              y: 21,
              style: {
                fontSize: '12px',
                fontFamily: 'var(--hlx-typography-font-family, inherit)',
                color: CHART_COLORS.axisLabels,
                whiteSpace: 'nowrap'
              }
            }
          },
          yAxis: {
            min: 0,
            tickPixelInterval: 50,
            tickColor: CHART_COLORS.plotBorder,
            alternateGridColor: CHART_COLORS.alternateGridColor,
            gridLineColor: CHART_COLORS.gridLines,
            title: {
              text: null
            },
            showFirstLabel: false,
            labels: {
              align: 'right',
              style: {
                fontSize: '12px',
                color: CHART_COLORS.axisLabels
              }
            }
          },
          tooltip: {
            valueSuffix: [undefined, xlate('millions')].join(' '),
          },
          plotOptions: {
            column: {
              borderWidth: 0
            },
            series: {
              stickyTracking: false,
              pointWidth: 15
            }
          },
          legend: {
            enabled: false
          }
        };

        // 'donut' chart configuration
        //
        // {
        //     series: [{
        //         type: 'donut',
        //         name: 'jobs',
        //         data: [
        //             ['Running', 20],
        //             ['Success', 164],
        //             ['Scheduled', 26],
        //             ['Error', 14]
        //         ]
        //     }]
        // }

        defaultChartConfigs.donut = {
          loading: false,
          chart: {
            renderTo: scope.chartId,
            backgroundColor: 'transparent',
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            spacing: 0,
            marginBottom: 20,
            height: 225,
            style: {
              fontFamily: 'var(--hlx-typography-font-family, inherit)',
            }
          },
          credits: {
            enabled: false
          },
          colors: [
            CHART_COLORS.brandDark,
            CHART_COLORS.green,
            CHART_COLORS.yellow,
            CHART_COLORS.red,
          ],
          title: {
            useHTML: true,
            text: [
              '<div id="chart-title-count-',
              scope.chartId,
              '" class="donut-chart-title-count"></div>',
              xlate(scope.chartConfig.titleText),
            ].join(''),
            align: 'left',
            floating: true,
            style: {
              fontFamily: 'var(--hlx-typography-font-family, inherit)',
              fontSize: '18px',
              color: CHART_COLORS.pieAndDonutTitle
            }
          },
          tooltip: {
            pointFormat: '<b>{point.percentage:.1f}%</b>',
            backgroundColor: 'rgba(255, 255, 255, 1)',
          },
          plotOptions: {
            series: {
              stickyTracking: false,
              states: {
                hover: {
                  enabled: false,
                },
              },
            },
            pie: {
              size: '75%',
              center: ['55%', '55%'],
              allowPointSelect: false,
              cursor: 'pointer',
              dataLabels: {
                enabled: false
              },
              showInLegend: true
            }
          },
          legend: {
            align: 'left',
            x: -6,
            y: -18,
            useHTML: true,
            labelFormatter: function donutLabelFormatterFn() {
              this.name = xlate(this.name);
              var labelName = this.name;
              if (labelName.length > 20) {
                labelName = [labelName.slice(0, 18), '...'].join('');
              }
              return [
                '<div style="position: relative; top: -1px;" title="',
                this.name,
                '">',
                labelName,
                ' <span class="legend-count">',
                this.y,
                '</span></div>',
              ].join('');
            },
            layout: 'vertical',
            itemMarginBottom: 10,
            itemStyle: {
              fontFamily: 'var(--hlx-typography-font-family, inherit)',
              color: CHART_COLORS.legendText
            }
          },
          series: [{
            size: '100%',
            innerSize: '85%',
          }]
        };

        // 'storageDonut' chart configuration. copies donut settings and
        // replaces a few values so our chart will display converted bytes to
        // appropriate size
        defaultChartConfigs.storageDonut = angular.merge({}, defaultChartConfigs.donut, {
          // this color order is intentional, as we can show free and used
          // space, and add an additional used space breakdown if needed, as
          // seen on monitoring > storage > select viewbox
          colors: [
            CHART_COLORS.subtle,
            CHART_COLORS.green,
            CHART_COLORS.brandDark
          ],
          legend: {
            labelFormatter: function storageDonutLabelFormatterFn() {
              this.name = xlate(this.name);
              var labelName = this.name;
              if (labelName.length > 20) {
                labelName = [labelName.slice(0, 18), '...'].join('');
              }
              return [
                '<div style="position: relative; top: -1px;" title="',
                this.name,
                '">',
                labelName,
                ' <span class="legend-count">',
                cUtils.bytesToSize(this.y).string,
                '</span></div>',
              ].join('');
            },
          }
        });


        // 'pie' chart configuration
        //
        // {
        //     series: [{
        //       type: 'pie',
        //       name: 'jobs',
        //       data: [
        //         ['Running', 20],
        //         ['Success', 164],
        //         ['Scheduled', 26],
        //         ['Error', 14]
        //       ]
        //     }]
        // }

        defaultChartConfigs.pie = {
          loading: false,
          chart: {
            renderTo: scope.chartId,
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            spacing: 0,
            style: {
              fontFamily: 'var(--hlx-typography-font-family, inherit)',
            }
          },
          credits: {
            enabled: false
          },
          colors: [
            CHART_COLORS.brandDark,
            CHART_COLORS.green,
            CHART_COLORS.yellow,
            CHART_COLORS.red,
          ],
          title: {
            useHTML: true,
            text: [
              '<div id="chart-title-count-',
              scope.chartId,
              '" class="pie-chart-title-count"></div> ',
              xlate(scope.chartConfig.titleText),
            ].join(''),
            align: 'right',
            floating: true,
            style: {
              fontFamily: 'var(--hlx-typography-font-family, inherit)',
              fontSize: '24px',
              color: CHART_COLORS.pieAndDonutTitle,
              textAlign: 'right'
            }
          },
          tooltip: {
            pointFormat: [
              '<b>',
              xlate('percentOfTotalJobs'),
              '</b>',
            ].join(''),
          },
          plotOptions: {
            series: {
              stickyTracking: false
            },
            pie: {
              borderColor: 'transparent',
              size: 185,
              center: ["27%", "50%"],
              allowPointSelect: false,
              cursor: 'pointer',
              dataLabels: {
                enabled: false
              },
              showInLegend: true
            }
          },
          legend: {
            useHTML: true,
            labelFormatter: function labelFormatter() {
              return [
                '<div class="pie-chart-label" style="background-color:',
                this.color,
                '"><span>',
                xlate(this.name),
                '</span><span class="pie-chart-jobs">',
                this.y,
                ' jobs</span></div>',
              ].join('');
            },
            layout: 'vertical',
            // symbolWidth: 250,
            // symbolHeight: 30,
            symbolWidth: 0,
            symbolHeight: 0,
            symbolRadius: 0,
            symbolPadding: 0,
            itemMarginBottom: 10,
            // itemDistance: 40, horizontal legend only
            itemStyle: {
              fontFamily: 'var(--hlx-typography-font-family, inherit)',
              color: CHART_COLORS.pieAndDonutLegendText,
              fontSize: '16px'
            }
          }
        };



        // 'stacked' chart configuration
        //
        // {
        //     series: [{
        //         name: 'SLA compliant jobs',
        //         data: [46, 50, 76, 40, 60, 25, 29, 20, 30, 50, 67, 72, 80,
        //          65, 50, 45, 57, 32, 48, 50, 67, 69, 80, 73]
        //     }, {
        //         name: 'SLA jobs in danger',
        //         data: [6, 4, 10, 12, 7, 0, 5, 10, 15, 16, 7, 9, 8, 4, 12,
        //          14, 15, 16, 12, 5, 6, 6, 8, 9]
        //     }, {
        //         name: 'SLA jobs in breach',
        //         data: [2, 1, 2, 0, 0, 0, 3, 4, 0, 1, 2, 3, 4, 0, 6, 2, 4, 5,
        //          0, 6, 7, 5, 4, 3]
        //     }]
        // }
        //
        // stacked also needs categories array passed in
        //
        // categories:  ['00:00','01:00','02:00','03:00','04:00','05:00',
        //  '06:00','07:00','08:00','09:00','10:00','11:00','12:00','13:00',
        //  '14:00','15:00','16:00','17:00','18:00','19:00','20:00','21:00',
        //  '22:00','23:00']
        //

        defaultChartConfigs.stacked = {
          loading: false,
          chart: {
            renderTo: scope.chartId,
            type: 'column',
            plotBorderWidth: 1,
            plotBorderColor: CHART_COLORS.plotBorder,
            backgroundColor: 'transparent',
            plotBackgroundColor: CHART_COLORS.plotBackground,
            marginTop: 20,
            marginBottom: 50,
            height: 250,
            style: {
              fontFamily: 'var(--hlx-typography-font-family, inherit)',
            }
          },
          credits: {
            enabled: false
          },
          colors: [
            CHART_COLORS.brand,
            CHART_COLORS.green,
            CHART_COLORS.yellow,
            CHART_COLORS.red,
            CHART_COLORS.gold,
          ],
          title: {
            text: null
          },
          xAxis: {
            tickColor: 'transparent',
            labels: {
              y: 24,
              style: {
                color: CHART_COLORS.axisLabels,
                fontSize: '10px'
              }
            }
          },
          yAxis: {
            tickPixelInterval: 30,
            tickColor: CHART_COLORS.plotBorder,
            alternateGridColor: CHART_COLORS.alternateGridColor,
            gridLineColor: CHART_COLORS.gridLines,
            opposite: true,
            min: 0,
            title: {
              text: null
            },
            labels: {
              style: {
                color: CHART_COLORS.axisLabels,
                fontSize: '12px'
              }
            }
          },
          legend: {
            align: 'left',
            verticalAlign: 'bottom',
            x: 20,
            y: 20,
            floating: true,
            backgroundColor: 'transparent',
            borderWidth: 0,
            shadow: false,
            itemStyle: {
              fontFamily: 'var(--hlx-typography-font-family, inherit)',
              color: CHART_COLORS.legendText
            }
          },
          tooltip: {
            formatter: function formatter() {
              return [
                '<b>',
                this.x,
                '</b><br>',
                xlate(this.series.name),
                ': ',
                this.y,
                '<br>Total: ',
                this.point.stackTotal,
              ].join('');
            }
          },
          plotOptions: {
            column: {
              borderWidth: 0,
              stacking: 'normal'
            },
            series: {
              stickyTracking: false,
              pointWidth: 25
            }
          }
        };


        // 'stackedPie' chart configuration
        //
        // series data is complicated. see cChartsExamples.js
        //
        defaultChartConfigs.stackedPie = {
          loading: false,
          chart: {
            renderTo: scope.chartId,
            type: 'pie',
            backgroundColor: 'transparent',
            plotBackgroundColor: 'transparent',
            style: {
              fontFamily: 'var(--hlx-typography-font-family, inherit)',
            }
          },
          credits: {
            enabled: false
          },
          title: {
            text: null
          },
          plotOptions: {
            series: {
              stickyTracking: false
            },
            pie: {
              shadow: false,
              center: ['30%', '40%']
            }
          },
          tooltip: {
            valueSuffix: ' TiB'
          },
          // for now, this additional series data will be overwritten by whats
          // passed in, so it also needs to be pass in for proper styling
          series: [{
            size: '50%',
            dataLabels: {
              formatter: function formatter() {
                return this.y > 5 ?
                  [xlate(this.point.name), '<br>', this.y, ' ', xlate('TiB')].join('') :
                  null;
              },
              distance: -40,
              y: -10,
              style: {
                color: CHART_COLORS.stackedPieOverlayText,
                fontSize: '13px',
                lineHeight: '14px'
              }
            }
          }, {
            size: '60%',
            innerSize: '50%',
            dataLabels: {
              connectorWidth: 0,
              connectorPadding: 0,
              distance: 15,
              formatter: function formatter() {
                // display only if larger than 1
                // NOTE: This check for "Free" will breakdown when translated.
                return (this.y > 1 && this.point.name !== 'Free') ?
                  ['<b>', xlate(this.point.name), '</b><br>', this.y, ' ', xlate('TiB')].join('') :
                  null;
              },
              style: {
                fontSize: '12px',
                lineHeight: '13px'
              }
            }
          }]
        };

        // 'line'
        // {
        //     series: [{
        //         name: 'Read',
        //         color: '#4371c3',
        //         data: [100,180,280,50,120,210,320,405,600,440,360,520,840,
        //          1350,1280,1190,1080,1710,1600,1050,1320,1610,1680,1000]
        //     }, {
        //         name: 'Write',
        //         color: '#ffc000',
        //         data: [20,12,48,80,270,392,336,387,440,572,804,962,630,840,
        //          896,374,432,285,300,210,308,414,408,475]
        //     }]
        // }
        //
        // recommended additionalConfig:
        //     additionalConfig: {
        //         yAxis: {
        //             min: 0,
        //             title: {
        //                 text: 'IOPS/sec'
        //             }
        //         }
        //     }
        //
        // line also needs categories array passed in
        // categories: ['8am','9am','10am','11am','12pm','1pm','2pm','3pm',
        //  '4pm','5pm','6pm','7pm','8pm','9pm','10pm','11pm','12am','1am',
        //  '2am','3am','4am','5am','6am','7am']
        //

        defaultChartConfigs.line = {
          loading: false,
          chart: {
            renderTo: scope.chartId,
            backgroundColor: 'transparent',
            plotBackgroundColor: CHART_COLORS.plotBackground,
            plotBorderWidth: 1,
            plotBorderColor: CHART_COLORS.plotBorder,
            events: {
              redraw: function() {
                applyLabelClass.call(this);
              }
            },
            style: {
              fontFamily: 'var(--hlx-typography-font-family, inherit)',
            }
          },
          plotOptions: {
            series: {
              stickyTracking: false,
              connectNulls: true
            }
          },
          credits: {
            enabled: false
          },
          title: {
            text: null
          },
          xAxis: {
            minPadding: 0.07,
            maxPadding: 0.01,
            dateTimeLabelFormats: customDateTimeLabelFormats,
            labels: {
              style: {
                color: CHART_COLORS.axisLabels
              }
            }
          },
          yAxis: {
            title: {
              text: null,
              style: {
                color: CHART_COLORS.axisLabels
              }
            },
            labels: {
              align: 'left',
              y: 11,
              x: 2,
              style: {
                color: CHART_COLORS.axisLabels
              }
            },
            showFirstLabel: false,
            gridLineColor: CHART_COLORS.gridLines,
            alternateGridColor: CHART_COLORS.alternateGridColor
          },
          legend: {
            layout: 'horizontal',
            verticalAlign: 'bottom',
            borderWidth: 0,
            itemStyle: {
              fontFamily: 'var(--hlx-typography-font-family, inherit)',
              color: CHART_COLORS.legendText
            }
          },
          tooltip: {
            valueDecimals: 2
          },
          colors: CHART_COLORS.neutralPair1
        };

        //
        // compact configuration options for 'line' charts
        //
        compactChartConfigs.line = {
          xAxis: {
            labels: {
              enabled: false
            },
            tickLength: 5,
            tickPosition: 'inside',
            startOnTick: false
          },
          chart: {
            marginTop: 1,
            // 1 pixel margins on left and right prevent
            // plotBorder from being cut off
            marginRight: 1,
            marginLeft: 1,
            marginBottom: 0,
            height: 150
          },
          legend: {
            layout: 'horizontal',
            verticalAlign: 'top',
            align: 'right',
            borderWidth: 0,
            y: -9
          },
          yAxis: {
            gridLineColor: CHART_COLORS.gridLines,
            alternateGridColor: 'transparent',
            title: {
              text: null
            }
          }
        };

        // Line chart with partially dotted lines & arearange to show
        // upper and lower bound
        // data: {
        //   dataPointVec: [
        //     [unix TS, dataPoint]
        //     [1534896000000, 1539316278886.4],
        //     [1534982400000, 1550311395164.16],
        //     [1535068800000, 1561306511441.92],
        //     [1535155200000, 1561306511441.92],
        //     [1535241600000, 1572301627719.68],
        //   ],
        //  rangePointVec:[
        //     [unix TS, lowerBoundBytes, upperBoundBytes],
        //     [1534896000000, 1039316278886, 1950311395164],
        //   ]
        //     physicalCapacityBytes: 1649267441664
        // };
        defaultChartConfigs.line_prediction = {
          time: {
            useUTC: true,
          },
          chart: {
            renderTo: scope.chartId,
            marginRight: 50,
            marginTop: 20,
            style: {
              fontFamily: 'var(--hlx-typography-font-family, inherit)',
            },
          },
          credits: {
            enabled: false,
          },
          title: {
            text: null,
          },
          legend: {
            enabled: false,
          },
          plotOptions: {
            series: {
              marker: {
                enabled: false,
              },
            },
            arearange: {
              states: {
                hover: {
                  enabled: false,
                },
              },
            },
          },
          xAxis: {
            tickColor: 'transparent',
            type: 'datetime',
            dateTimeLabelFormats: customDateTimeLabelFormats,

            // 1 Vertical line
            // current date
            plotLines: [{
              color: CHART_COLORS.brand,
              width: 2,
              zIndex: 5,
              label: {
                useHTML: true,
                text: '<p class="highcharts-plotline-dot">' + xlate('today') + '</p>',
                x: -22,
                y: 0,
                rotation: 360,
              },
            }],
          },
          yAxis: {
            // 3 Horizontal lines

            // 1. solid red -- total capacity
            // 2. red-dash -- 90% predicted capacity
            // 3. yellow-dash -- 80% predicted capacity
            title: {
              text: null,
            },
            labels: {
              formatter: function labelFormatter() {
                if (this.value < this.chart.options.totalCapacity * 0.8) {
                  return cUtils.bytesToSize(this.value).string;
                }
              },
            },
            lineWidth: 1,
            gridLineColor: 'transparent',
            plotLines: [{
              color: CHART_COLORS.gold,
              width: 2,
              dashStyle: 'dash',
              zIndex: 4,
              label: {
                align: 'right',
                x: 35,
                y: 2,
              },
            },
            {
              color: CHART_COLORS.red,
              width: 2,
              zIndex: 4,
              dashStyle: 'shortdash',
              label: {
                align: 'right',
                x: 35,
                y: 2,
              },
            },
            {
              color: CHART_COLORS.red,
              width: 2,
              label: {
                align: 'right',
                x: 40,
                y: 2,
              },
            },
            {
              color: CHART_COLORS.red,
              width: 0,
              label: {
                useHTML: true,
                align: 'left',
                x: -50,
                y: 0,
              },
            },
            ],
          },
          series: [
            {
              color: CHART_COLORS.brand,
              type: 'area',
              zoneAxis: 'x',
              zIndex: 1,
              zones: [{
                className: 'zone-0',
                fillColor: 'none',
              },
              {
                dashStyle: 'dot',
                fillColor: 'none',
              },
              ],
            },
            {
              type: 'arearange',
              lineWidth: 0,
              fillColor: CHART_COLORS.skyBlue,
              fillOpacity: 0.3,
            }
          ],
        };

        // 'bytesLine' chart configuration.
        // copies donut settings and replaces a few values so our chart will
        // display converted bytes to appropriate size
        defaultChartConfigs.bytesLine = angular.merge({},
          defaultChartConfigs.line,
          {
            yAxis: {
              labels: {
                formatter: function bytesLineYAxisLabelFormatter() {
                  if (scope.chartConfig && scope.chartConfig.yAxisLabelAppend) {
                    return cUtils.bytesToSize(this.value).string +
                      scope.chartConfig.yAxisLabelAppend;
                  }
                  return cUtils.bytesToSize(this.value).string;
                }
              }
            },
            tooltip: {
              formatter: function bytesLineTooltipFormatter() {
                let tooltipValueAppend = '';

                // Append tooltip value when value is provided.
                if (this.series.options && this.series.options.tooltipValueAppend) {
                  tooltipValueAppend = this.series.options.tooltipValueAppend;
                }

                var xLabel = DateTimeService.msecsToFormattedDate(
                  new Date(this.x).getTime()
                );
                return [
                  xLabel,
                  '<br>',
                  xlate(this.series.name),
                  ': ',
                  cUtils.bytesToSize(this.y, bytesPrecision).string,
                  tooltipValueAppend,
                ].join('');
              }
            }
          }
        );

        // 'bytesLine' chart configuration.
        // copies donut settings and replaces a few values so our chart will
        // display converted bytes to appropriate size
        defaultChartConfigs.bytesLineNew = angular.merge({},
          defaultChartConfigs.line,
          {
            xAxis: {
              type: 'datetime',
            },
            yAxis: {
              type: 'logarithmic',
              labels: {
                formatter: function bytesLineYAxisLabelFormatter() {
                  if (scope.chartConfig && scope.chartConfig.yAxisLabelAppend) {
                    return cUtils.bytesToSize(this.value).string +
                      scope.chartConfig.yAxisLabelAppend;
                  }
                  return cUtils.bytesToSize(this.value).string;
                },
              },
            },
            tooltip: {
              formatter: function bytesLineTooltipFormatter() {
                var Label = DateTimeService
                  .msecsToFormattedDate(new Date(this.x).getTime());
                return [Label, '<br>', ': ',
                  cUtils.bytesToSize(this.y, bytesPrecision).string].join('');
              },
            },
            colors: CHART_COLORS.neutralPair4,
            plotOptions: {
              areaspline: {
                fillOpacity: 0.5,
                fillColor: {
                  linearGradient: {
                    x1: 0,
                    y1: 0,
                    x2: 0,
                    y2: 1,
                  },
                  stops: [
                    [0, CHART_COLORS.colorPrimary],
                    [1, Highcharts.Color(CHART_COLORS.colorPrimary)
                      .setOpacity(0).get('rgba')],
                  ],
                },
                lineWidth: 1,
                states: {
                  hover: {
                    lineWidth: 1,
                  },
                },
                threshold: null,
              },
            },
          }
        );

        compactChartConfigs.bytesLine = compactChartConfigs.line;

        /**
         * Processes a chart config object for translatable strings.
         *
         * @method     processTranslations
         * @param      {object}  config  The configuration object.
         */
        function processTranslations(config) {
          // API Refs
          // https://www.highcharts.com/docs/chart-concepts/labels-and-string-formatting/
          // http://api.highcharts.com/highcharts/tooltip.formatter

          if (!config || !Object.keys(config).length) {
            return;
          }

          // Series stuffs
          if (Array.isArray(config.series)) {
            config.series.forEach(function eachSeries(series) {
              var seriesData;

              // Not all series have a name
              if (angular.isString(series.name)) {
                series.name = xlate(series.name);
              }

              // Series data. Not all series have a data list.
              if (Array.isArray(seriesData = series.data)) {
                seriesData.forEach(function eachSeriesDatum(datum, dd) {
                  if (angular.isString(datum)) {
                    seriesData[dd] = xlate(datum);
                  }
                  if (Array.isArray(datum)) {
                    datum.forEach(function eachSubDatum(subDatum, sd) {
                      if (angular.isString(subDatum)) {
                        datum[sd] = xlate(subDatum);
                      }
                    });
                  }
                });
              }
            });
          }

          // Chart Subtitle
          if (config.subtitle && angular.isString(config.subtitle.text)) {
            config.subtitle.text = xlate(config.subtitle.text);
          }

          // Chart Title
          if (config.title && angular.isString(config.title.text)) {
            config.title.text = xlate(config.title.text);
          }

          // config.xAxis.categories[]
          // config.yAxis.categories[]
          // config.zAxis.categories[]
        }

        /**
         * Generic reusable chart formatter Fn.
         *
         * @method     hcTranslateFormatter
         * @return     {string}  The translated string, or the raw value.
         */
        function hcTranslateFormatter() {
          if (angular.isString(this.name || this.value)) {
            return xlate(this.name || this.value);
          }
          return this.name || this.value;
        }

        /**
         * calls our other redraw functions, passing this to them
         * @return {void}
         */
        customReduxRedraw = function customReduxRedraw() {
          // 'call' our functions so we can pass
          // on 'this' in proper context
          applyLabelClass.call(this);
        };

        /**
         * sets a class on the c-chart elem based on number of items in the
         * series. This will apply styles from cCharts.scss to hide every nth
         * label depending on the appropriate case.
         *
         * @method   applyLabelClass
         */
        applyLabelClass = function applyLabelClass() {

          // if charts needs label skipping, add labelSkipping: true
          // property to the config object
          if (!scope.chartConfig.labelSkipping) {
            return;
          }

          var seriesLength = 0;
          var computedClass = '';
          var labelClasses = [
            'chart-labels-all',
            'chart-labels-step-2',
            'chart-labels-step-3',
            'chart-labels-none',
          ];

          if (scope.chart.series[0] && scope.chart.series[0].data) {
            seriesLength = scope.chart.series[0].data.length;
          }

          if (seriesLength <= 14) {
            computedClass = labelClasses[0];
          } else if (seriesLength <= 30) {
            computedClass = labelClasses[1];
          } else if (seriesLength <= 60) {
            computedClass = labelClasses[2];
          } else { //greater than 60, no labels
            computedClass = labelClasses[3];
          }

          // remove any previously applied classes
          for (var index = 0; index < labelClasses.length; index++) {
            elem.removeClass(labelClasses[index]);
          }

          // apply the newly computed class
          elem.addClass(computedClass);
        };

        /**
         * TODO: vet for cleanup. dataRedux no longer calls this function Helper
         * that extends area and lines to the left and right plot edges. For
         * 'area' plots only.
         *
         * Has to be invoked with .call(), giving Highchart as the this
         * reference and Highchart event as the second parameter.
         *
         * @method    edgeExtend
         */
        edgeExtend = function edgeExtend() {
          for (var l = this.series.length, i = 0; i < l; i++) {
            // Get current series.
            var series = this.series[i];

            if (series.options.type === 'area') {
              // Get inner-chart box.
              var box = this.plotBox;

              // Take areas path.
              var areaPath = series.areaPath;

              // Add start point.
              // Right after the first element (M).
              areaPath.splice(1, 0, 0, areaPath[2], 'L');

              // Add Last points upper area end.
              // Remove penultimate point.
              // Replace it with a new point reaching to the width of chart and growing to the height of last element.
              // And add the bottom-right corner.
              areaPath.splice(-6, 3, 'L', box.width, areaPath[areaPath.length - 7], 'L', box.width, box.height);

              // Make the last points X be zero - that will result in bottom left corner.
              areaPath[areaPath.length - 2] = 0;

              // Replace value (redraw).
              series.area.element.attributes.d.value = areaPath.join(' ');

              var graphPath = series.graphPath;

              // Add start point.
              // Right after the first element (M).
              graphPath.splice(1, 0, 0, graphPath[2], 'L');

              // Add end point.
              graphPath.push('L', box.width, graphPath[graphPath.length - 1]);

              // area graph can't support a single data point
              // so this property won't exist in such cases
              if (series.hasOwnProperty('graph')) {
                series.graph.element.attributes.d.value = graphPath.join(' ');
              }
            }
          }
        };

        // 'dataRedux'
        //
        // {
        //     series: [{
        //         name: 'data read (TiB)',
        //         type : 'column',
        //         data: [36, 31, 28, 30, 38, 34, 40],
        //         zIndex: 3
        //     }, {
        //         name: 'data written (TiB)',
        //         type: 'column',
        //         data: [8, 2, 1.5, 2, 2.2, 2, 2],
        //         zIndex: 3
        //     }, {
        //         name: 'data reduction',
        //         type: 'area',
        //         data: [4.5, 15.5, 18.667, 15, 17.273, 17, 20],
        //         zIndex: 2
        //     }]
        // }
        //
        // currently needs categories array passed in
        // categories: ['1/1', '1/2', '1/3', '1/4', '1/5', '1/6', '1/7']
        //
        defaultChartConfigs.dataRedux = {
          loading: false,
          chart: {
            renderTo: scope.chartId,
            backgroundColor: 'transparent',
            plotBackgroundColor: CHART_COLORS.plotBackground,
            plotBorderWidth: 1,
            plotBorderColor: CHART_COLORS.plotBorder,
            // We make both, the load event and redraw event to
            // extend point, area edges.
            events: {
              redraw: customReduxRedraw
            },
            style: {
              fontFamily: 'var(--hlx-typography-font-family, inherit)',
            }
          },
          plotOptions: {
            column: {
              borderWidth: 0,
              pointPadding: 0,
              shadow: false
            },
            series: {
              stickyTracking: false,
              minPointLength: 3
            }
          },
          credits: {
            enabled: false
          },
          title: {
            text: null
          },
          xAxis: {
            tickColor: 'transparent',
            type: 'datetime',
            startOnTick: false,
            endOnTick: false,
            dateTimeLabelFormats: customDateTimeLabelFormats,
            labels: {
              style: {
                color: CHART_COLORS.axisLabels
              }
            },
            minPadding: 0.06,
            maxPadding: 0.04
          },
          yAxis: [{
            // primary y-axis
            title: {
              text: null
            },
            labels: {
              useHTML: true,
              formatter: bytesLabelFormatter,
              align: 'left',
              y: 11,
              x: 2,
              style: {
                color: CHART_COLORS.axisLabels
              }
            },
            showFirstLabel: false,
            allowDecimals: false,
            gridLineColor: CHART_COLORS.gridLines,
            alternateGridColor: CHART_COLORS.alternateGridColor
          }, {
            // secondary y-axis
            title: {
              text: null,
              style: {
                color: CHART_COLORS.redux
              }
            },
            labels: {
              format: '{value}x',
              align: 'right',
              y: 11,
              x: -2,
              style: {
                color: CHART_COLORS.redux
              }
            },
            showFirstLabel: false,
            opposite: true,
            allowDecimals: false,
            gridLineColor: CHART_COLORS.gridLines,
            alternateGridColor: CHART_COLORS.alternateGridColor
          }],
          tooltip: {
            formatter: function formatter() {
              if (this.series.index === 2) {
                return this.y + 'x';
              }
              return cUtils.bytesToSize(this.y, bytesPrecision).string;
            }
          },
          legend: {
            layout: 'horizontal',
            align: 'top',
            verticalAlign: 'top',
            borderWidth: 0,
            itemStyle: {
              color: CHART_COLORS.legendText
            }
          },
          colors: CHART_COLORS.neutralPair4.concat([CHART_COLORS.redux])
        };


        //
        // compact configuration options for 'dataRedux' charts
        //
        compactChartConfigs.dataRedux = {
          xAxis: {
            startOnTick: false
          },
          chart: {
            marginTop: 1,
            // 1 pixel margins on left and right prevent
            // plotBorder from being cut off
            marginRight: 1,
            marginLeft: 1,
            marginBottom: 20,
            height: 250
          },
          legend: {
            layout: 'horizontal',
            verticalAlign: 'top',
            align: 'right',
            borderWidth: 0,
            y: -9,
            x: -30
          },
          yAxis: {
            gridLineColor: CHART_COLORS.gridLines,
            alternateGridColor: 'transparent',
            title: {
              text: null
            }
          }
        };


        // 'trendLine'
        defaultChartConfigs.trendLine = {
          loading: false,
          chart: {
            renderTo: scope.chartId,
            backgroundColor: null,
            plotBackgroundColor: null,
            plotBorderWidth: 0,
            type: 'spline',
            height: 80,
            marginTop: 0,
            marginLeft: 0,
            marginRight: 0,
            style: {
              fontFamily: 'var(--hlx-typography-font-family, inherit)',
            }
          },
          plotOptions: {
            series: {
              connectNulls: true,
              tooltip: {
                headerFormat: '',
                pointFormatter: function bytesLineTooltipFormatter() {
                  var xLabel;
                  xLabel = DateTimeService.msecsToFormattedDate(new Date(this.x).getTime());
                  return [xLabel, '<br>', this.series.name, ': ', cUtils.bytesToSize(this.y, bytesPrecision).string].join('');
                }
              },
            },
            spline: {
              marker: {
                enabled: false
              }
            }
          },
          credits: {
            enabled: false
          },
          title: {
            text: null
          },
          xAxis: {
            type: 'datetime',
            startOnTick: false,
            endOnTick: false,
            dateTimeLabelFormats: customDateTimeLabelFormats,
            labels: {
              style: {
                color: CHART_COLORS.axisLabels
              }
            },
            lineColor: 'transparent',
            tickColor: CHART_COLORS.axisLabels,
            minPadding: 0,
            maxPadding: 0
          },
          yAxis: [{
            // primary y-axis
            title: {
              text: null
            },
            labels: {
              enabled: false
            },
            gridLineWidth: 0,
            minorGridLineWidth: 0
          }],
          legend: {
            enabled: false
          },
          colors: CHART_COLORS.neutralPair4.concat([CHART_COLORS.redux])
        };


        /**
         * reusable function for formating tooltips to scaled bytes
         *
         * @return     {string}  the scaled byte value/string
         */
        function bytesTooltipFormatter() {
          return cUtils.bytesToSize(this.y, bytesPrecision).string.replace(' ', '&nbsp;');
        }

        /**
         * reusable function for formating labels to scaled bytes
         *
         * @return     {string}  the scaled byte value/string
         */
        function bytesLabelFormatter() {
          return cUtils.bytesToSize(this.value).string.replace(' ', '&nbsp;');
        }

        /**
         * determines if a pie/donut chart has any data to display
         *
         * TODO: have this check our chartConfig instead of the generated chart
         * so we can run it before the chart is created in order to prevent a
         * flash of 'no data' message.
         *
         * @param {Array} series chartConfig series array
         * @return {Boolean} true if chart has data to display, false if not
         */
        function pieHasData(series) {
          var totalDataPoints = 0;
          var i = 0;
          var j = 0;
          var seriesLength = series.length;
          var dataLength;
          var currentSeries;
          var currentData;

          // determine if the chart has any data
          for (i; i < seriesLength; i++) {
            currentSeries = series[i];
            dataLength = currentSeries.data.length;
            for (j = 0; j < dataLength; j++) {
              currentData = currentSeries.data[j];

              if (currentData[1] > 0) {
                totalDataPoints += currentData[1];
              }
              // if (currentData.hasOwnProperty('y')) {
              //     totalDataPoints += currentData.y;
              // } else if (currentData.hasOwnProperty('stackTotal')) {
              //     totalDataPoints += currentData.stackTotal;
              // } else if (currentData.hasOwnProperty('total')) {
              //     totalDataPoints += currentData.total;
              // }

              // if we found data, lets return from the function,
              // as we don't care about getting an accurate count
              // just that we know if there's data to show or not
              if (totalDataPoints) {
                return true;
              }
            }
          }

          return false;
        }

        /**
         * determines if a non-pie/donut chart has any data to display
         *
         * TODO: have this check our chartConfig instead of the generated chart
         * so we can run it before the chart is created in order to prevent a
         * flash of 'no data' message.
         *
         * @param {Array} series chartConfig series array
         * @return {Boolean} true if chart has data to display, false if not
         */
        function chartHasData(series) {
          var i = 0;
          var seriesLength = series.length;
          var currentSeries;

          // determine if the chart has any data
          // TODO: this doesn't work when a series has all null values
          for (i; i < seriesLength; i++) {
            currentSeries = series[i];
            if (currentSeries.data && currentSeries.data.length) {
              return true;
            }
          }

          return false;
        }

        /**
         * destroy the chart
         * @return {Void}
         */
        function destroyChart() {
          // destroy the chart
          scope.chart.destroy();
        }

        /**
         * build our instance options and create the chart
         * @return {Void}
         */
        function initiatlizeChart() {
          // setup our configuration based on a copy of default config
          // in case the chart needs to be destroyed and recreated
          var chartInstanceOptions =
            angular.copy(defaultChartConfigs[scope.chartConfig.chartType]);

          // add compact options if appropriate
          if (scope.chartConfig.compact) {
            angular.merge(
              chartInstanceOptions,
              compactChartConfigs[scope.chartConfig.chartType]
            );
            if (scope.chartConfig.stacked === false) {
              chartInstanceOptions.chart.marginBottom = 1;
            }
          }

          angular.merge(chartInstanceOptions, scope.chartConfig);

          processTranslations(chartInstanceOptions);

          // check for data, two separate functions as we don't want to count
          // zero values as 'data' for donuts/pies, but we do want to count zero
          // values as 'data' for other charts.
          if (['pie', 'donut', 'storageDonut'].includes(chartInstanceOptions.chartType)) {
            scope.hasData = pieHasData(chartInstanceOptions.series);
          } else {
            scope.hasData = chartHasData(chartInstanceOptions.series);
          }

          // reinit chart
          scope.chart = new Highcharts.Chart(chartInstanceOptions);

          // set local scope chartLoading based on chartConfig value
          // this prevents our template from jumping the gun on removing
          // the loading spinner
          scope.chartLoading = !!chartInstanceOptions.loading;

          if (scope.resetZoomOnInit) {
            scope.chart.zoomOut();
          } else if (scope.chartExtremes &&
            scope.chartExtremes.minimum !== null &&
            scope.chartExtremes.maximum !== null) {
            scope.chart.xAxis[0].setExtremes(
              scope.chartExtremes.minimum, scope.chartExtremes.maximum);
          }

          afterRender();
        }

        // check for scope.chartConfig.chartType, else default to basic
        if (!defaultChartConfigs[scope.chartConfig.chartType]) {
          scope.chartConfig.chartType = 'basic';
        }

        /** @type {Object} datetime xAxis object for easy inclusion */
        xAxisDateTime = {
          type: 'datetime',
          dateTimeLabelFormats: customDateTimeLabelFormats,
          labels: {
            enabled: true,
            style: {
              color: CHART_COLORS.axisLabels
            }
          }
        };

        // if chartConfig calls for xAxisDateTime
        // inject the datetime into our config
        if (scope.chartConfig.xAxisDateTime) {
          // if chartConfig includes an xAxis merge,
          // otherwise create it
          if (scope.chartConfig.hasOwnProperty('xAxis')) {
            angular.merge(scope.chartConfig.xAxis, xAxisDateTime);
          } else {
            scope.chartConfig.xAxis = xAxisDateTime;
          }
        }

        // timeout our chart initialization for one digest loop so
        // angular can prep the template (inject the id value)
        configTimeout = $timeout(function chartConfigTimeout() {

          initiatlizeChart();

          scope.$watch(
            'chartConfig',
            function chartConfigWatcher(newConfig, oldConfig) {
              if (newConfig !== oldConfig) {
                destroyChart();
                initiatlizeChart();
              }
            },
            true
          );

          if (scope.chartExtremes) {
            scope.$watch(
              'chartExtremes',
              function chartExtremesWatcher(newExtremes, oldExtremes) {
                var minDiff = Math.abs(newExtremes.minimum - oldExtremes.minimum);
                var maxDiff = Math.abs(newExtremes.maximum - oldExtremes.maximum);
                // only update chart extremes if the extremes changed
                // by a significant amount. This helps prevent digest looping
                if (minDiff >= 1 || maxDiff >= 1) {
                  scope.chart.xAxis[0].setExtremes(newExtremes.minimum, newExtremes.maximum);
                }
              },
              true
            );
          }

          configTimeout = false;
        });

        // on destroy, cancel any outstanding timeouts
        scope.$on('$destroy', function scopeDestroy() {
          if (afterRenderTimeout) {
            $timeout.cancel(afterRenderTimeout);
          }
          if (configTimeout) {
            $timeout.cancel(configTimeout);
          }
        });
      }
    };
  }

})(angular);
