import { Component } from '@angular/core';
import { mountRootParcel } from 'single-spa';

import { PluginLoaderService } from './../services';

@Component({
  selector: 'mf-plugin-outlet',
  templateUrl: './plugin-outlet.component.html',
  styleUrls: ['./plugin-outlet.component.scss'],
})
export class PluginOutletComponent {
  mountRootParcel = mountRootParcel;

  get isLoaded() {
    return this.pluginLoaderService.isLoaded;
  }

  get parcelConfigs() {
    return this.pluginLoaderService.parcelConfigs;
  }

  constructor(private pluginLoaderService: PluginLoaderService) {}
}
