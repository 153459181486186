<div *ngIf="!selected && (run$ | async) as run" class="collapsed-run">
  <div class="run-status-icon">
    <cog-icon size="sm" [shape]="runStatus$ | async | statusIconReduce"></cog-icon>
  </div>
  <div class="timestamp">
    {{ run.startDate | cohDate: 'shortTime' }}
  </div>
  <div class="run-type">
    {{ 'enum.jobRunType.' + run.runType | translate }}
  </div>
</div>

<div class="expanded-run"
  *ngIf="selected && (run$ | async) as run"
  [uiSref]="run.defaultState"
  [uiParams]="run.defaultStateParams">
  <div class="run-status-icon">
    <cog-icon [shape]="runStatus$ | async | statusIconReduce" size="lg"></cog-icon>
  </div>
  <div class="attributes">
    <div *ngIf="isDataLockRun(run)" class="attribute">
      <span translate>datalock</span>
      <cog-icon shape="lock" size="sm" [inline]="true"></cog-icon>
    </div>
    <div *ngIf="run.onLegalHold || run.hasLegalHoldTargets" class="attribute">
      <span translate>legalHoldApplied</span>
      <cog-icon shape="gavel" size="sm"></cog-icon>
    </div>
  </div>
  <div class="timestamp">
    {{ run.startDate | cohDate: 'shortTime' }} - {{ 'enum.jobRunType.' + run.runType | translate }}
  </div>
  <coh-stat-list [stats]="runStats$ | async"></coh-stat-list>
  <div class="footer" [ngSwitch]="run.environment">
    <ul *ngSwitchDefault class="c-ul-inline no-margin">
      <li *ngIf="hasDataRead">
        {{ 'dataReadColon' | translate }} <strong>{{ run.readBytes | byteSize }}</strong>
      </li>
      <li *ngIf="hasDataWritten" [matTooltip]="'dataWrittenDesc' | translate" matTooltipPosition="above">
        {{ 'dataWrittenColon' | translate }} <strong>{{ run.writeBytes | byteSize }}</strong>
      </li>
    </ul>
    <ul *ngSwitchCase="'kView'" class="c-ul-inline no-margin">
      <li>{{ 'sizeColon' | translate }} <strong>{{ run.totalBytes | byteSize }}</strong></li>
    </ul>

    <coh-snapshot-deleted [deleted]="run.isLocalSnapshotsDeleted">
      <a class="item-run-link"
        *ngIf="hasObjectCount"
        [uiSref]="run.defaultState"
        [uiParams]="run.defaultStateParams">
        {{ 'numOfObjectsProtected' | translate: {
          protected: run.isDbRun ? run.successfulAppObjectsCount : run.successfulObjectsCount,
          total: run.isDbRun ? run.totalAppObjectsCount : run.totalObjectsCount }
        }}
      </a>
    </coh-snapshot-deleted>
  </div>
  <coh-runs-menu *ngIf="hasActions"
    [run]="run"
    cogDataId="menu"
    [inheritDataId]="true">
  </coh-runs-menu>
</div>
