<!-- Push notification configuration dialog template -->
<div class="flex-row">
  <cog-icon class="margin-right margin-top-xs" shape="settings" size="md"></cog-icon>
  <h2 mat-dialog-title>{{ 'settings' | translate }}</h2>
</div>
<form
  id="configure-push-notification-form"
  [formGroup]="form"
  *ngIf="!loading"
  cohValidateOnSubmit
  (validatedSubmit)="onSubmit()"
  cogDataId="anomaly-settings">
  <mat-dialog-content>
    <!-- Title and Description -->
    <h4 class="margin-top">{{ 'anomalySettings.title' | translate }}</h4>
    <small>{{ dialogParams.description }}</small>

    <div class="field-margin">
      <!-- Threshold slider -->
      <mat-label>{{ 'anomalySettings.actionThreshold' | translate }}</mat-label>
      <div class="flex-row-xs vertical-align slider-margin">
        <mat-slider
          cogDataId="slider"
          [inheritDataId]="true"
          color="primary"
          class="flex-grow"
          [max]="100"
          [min]="1"
          formControlName="threshold">
        </mat-slider>
        <mat-form-field class="threshold-value">
          <input
            matInput
            readonly
            cogDataId="threshold-slider-input"
            [inheritDataId]="true"
            [value]="form.get('threshold').value" />
          <span matSuffix>%</span>
        </mat-form-field>
      </div>
    </div>

    <ng-container *ngIf="ransomwareUnifiedDashboardEnabled">
      <div *ngIf="(hasNotificationsConfigured$ | async) === false"
        class="c-message-inline icon-message info">
        <p innerHTML="{{'anomalySettings.alertsNotificationMessage' | translate}}"></p>
      </div>

      <a mat-stroked-button
        cogDataId="alert-notifications-link"
        href="/health/notification-rules?serviceType=dms">
        {{'anomalySettings.alertsNotificationLink' | translate}}
      </a>
    </ng-container>

    <!-- Block recovery threshold -->
    <div class="margin-top">
      <mat-checkbox cogDataId="block-recovery" [inheritDataId]="true" formControlName="blockRestore">
        {{ 'anomalySettings.blockRecovery' | translate }}
      </mat-checkbox>
    </div>

    <!-- Tag Name -->
    <mat-form-field class="field-margin">
      <mat-label>{{ 'anomalySettings.tagName' | translate }}</mat-label>
      <input matInput formControlName="tagName" cogDataId="tag-name" [inheritDataId]="true" />
      <mat-error *ngIf="form.controls.tagName.errors?.required" cogDataId="tag-name-required-error">
        {{ 'errors.required' | translate }}
      </mat-error>
      <mat-error *ngIf="form.controls.tagName.errors?.maxlength" cogDataId="tag-name-max-length-error">
        {{ 'errors.maxlength' | translate: { value: form.controls.tagName.errors?.maxlength.requiredLength } }}
      </mat-error>
      <mat-error *ngIf="form.controls.tagName.errors?.minlength" cogDataId="tag-name-min-length-error">
        {{ 'errors.minlength' | translate: { value: form.controls.tagName.errors?.minlength.requiredLength } }}
      </mat-error>
    </mat-form-field>

    <!-- DC and Threat scans -->
    <div class="margin-top flex-column-md" *ngIf="showOtherScanOptions">
      <div class="flex-column-sm">
        <mat-slide-toggle
          class="margin-top"
          cogDataId="threat-scans"
          formControlName="threatScans"
          [inheritDataId]="true"
          [disabled]="!disableThreatHuntingToggle">
          <div class="flex-column">
            <span>{{ 'dg.td.detectionRuns' | translate }}</span>
            <small>{{ 'dg.td.usingBuiltInThreats' | translate }}</small>
          </div>
        </mat-slide-toggle>
        <div *ngIf="!disableThreatHuntingToggle">
          <span>{{ 'dg.td.purchaseSku.text' | translate }}</span>
          <a class="margin-left-xs" (click)="upgradePlan()">
            {{ 'upgradeNow' | translate }}
          </a>
        </div>
      </div>
      <div class="flex-column-sm">
        <mat-slide-toggle
          class="margin-top"
          cogDataId="data-sensitivity-scans"
          formControlName="dataSensitivityScans"
          [inheritDataId]="true"
          [disabled]="!disableDataSensitivityToggle">
          <div class="flex-column">
            <span>{{ 'dg.dc.dataClassificationScans' | translate }}</span>
            <small>{{ 'dg.dc.usingBasePatterns' | translate }}</small>
          </div>
        </mat-slide-toggle>
        <div *ngIf="!disableDataSensitivityToggle">
          <span>{{ 'dg.dc.purchaseSku.text' | translate }}</span>
          <a class="margin-left-xs" (click)="upgradePlan()">
            {{ 'upgradeNow' | translate }}
          </a>
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close cogCancelButton cogDataId="configuration-cancel" [inheritDataId]="true">
      {{ 'cancel' | translate }}
    </button>
    <button mat-button cogSubmitButton cdkFocusInitial cogDataId="configuration-submit" [inheritDataId]="true">
      {{ 'save' | translate }}
    </button>
  </mat-dialog-actions>
</form>
<cog-spinner *ngIf="loading"></cog-spinner>
