<!-- Prefix -->
<mat-form-field class="col-xs-12">
  <input cogDataId="rule-filter-prefix-input"
    matInput
    placeholder="{{'prefix' | translate}}"
    [formControl]="formGroupControls.prefix">
  <mat-error class="padding-top" cogDataId="rule-filter-prefix-invalid" *ngIf="formGroupControls.prefix?.errors?.required">
    {{'errors.required' | translate}}
  </mat-error>
</mat-form-field>
<mat-hint class="col-xs-12">{{'filterPrefixExplain' | translate}}</mat-hint>

<!-- Object Tags -->
<div class="col-xs-12">
  <h4 class="margin-top-sm margin-bottom-sm">{{'objectTags' | translate}}</h4>
  <p>{{'views.s3lcm.objectTagsNote' | translate}}</p>
  <button *ngIf="!formGroupValues.tags?.length"
    cogDataId="add-rule-filter-tag-btn"
    type="button"
    mat-stroked-button
    color="primary"
    (click)="addTag()">
    {{'addObjectTag' | translate}}
  </button>
</div>

<ng-container *ngIf="formGroupValues.tags?.length">
  <div class="row margin-left-xs"
    *ngFor="let tagControlGroup of formGroupControls.tags.controls; index as i; first as isFirst; last as isLast;">
    <mat-form-field class="col-xs-4">
      <input cogDataId="rule-filter-tag-key-input-{{i}}"
        matInput
        [formControl]="tagControlGroup.controls.key"
        placeholder="{{'key' | translate}}">
        <mat-error cogDataId="rule-filter-key-invalid" *ngIf="tagControlGroup?.controls?.key?.errors?.required">
          {{'errors.required' | translate}}
        </mat-error>
    </mat-form-field>
    <mat-form-field class="col-xs-4">
      <input cogDataId="rule-filter-tag-value-input-{{i}}"
        matInput
        [formControl]="tagControlGroup.controls.value"
        placeholder="{{'value' | translate}}">
        <mat-error cogDataId="rule-filter-value-invalid" *ngIf="tagControlGroup?.controls?.value?.errors?.required">
          {{'errors.required' | translate}}
        </mat-error>
    </mat-form-field>
    <button mat-icon-button
      class="col-xs-2"
      type="button"
      cogDataId="remove-domain-{{i}}"
      (click)="removeTag(i)">
      <cog-icon shape="remove_circle_outline"></cog-icon>
    </button>
    <button mat-icon-button
      class="col-xs-2"
      type="button"
      cogDataId="add-domain"
      *ngIf="isLast"
      (click)="addTag()">
      <cog-icon shape="add_circle_outline"></cog-icon>
    </button>
  </div>
</ng-container>
