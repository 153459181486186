<h1 mat-dialog-title>
  {{ (isEditMode ? 'editAccessControlPermission' : 'addAccessControlPermission') | translate }}
</h1>

<form cogDataId="modify-acl-form" [formGroup]="form.formGroup">
  <mat-dialog-content class="margin-bottom">
    <div class="row no-margin-left">
      <!-- User -->
      <mat-form-field class="col-xs-12 no-padding-left" cogDataId="user-list" *ngIf="!loadingUser; else spinner">
        <mat-label>{{'user' | translate}}</mat-label>
        <mat-select [formControl]="form.formGroup.controls.grantee"
          [compareWith]="compareUserOrGroupSelection"
          cogDataId="select-acl-user-">
          <mat-option [inheritDataId]="true"
            cogDataId="-search-user">
            <ngx-mat-select-search
              [formControl]="form.formGroup.controls.userSearchValue"
              [noEntriesFoundLabel]="'noMatchingItems' | translate"
              [placeholderLabel]="'search' | translate">
            </ngx-mat-select-search>
          </mat-option>
          <mat-optgroup label="{{'groups' | translate}}">
            <mat-option *ngFor="let group of groups; let i = index;"
              [value]="group"
              [inheritDataId]="true"
              [cogDataId]="'group-option-' + group.group">{{group.group}}
            </mat-option>
          </mat-optgroup>
          <mat-optgroup label="{{'users' | translate}}">
            <mat-option *ngFor="let user of users; let i = index;"
              [value]="user"
              [inheritDataId]="true"
              [cogDataId]="'user-option-' + user.userId">{{user.displayName}}
            </mat-option>
          </mat-optgroup>
        </mat-select>
      </mat-form-field>

      <!-- permissions -->
      <div class="row no-padding-left">
        <mat-label class="col-xs-12 margin-bottom">{{'bucketAcl' | translate}}</mat-label>
        <mat-checkbox class="col-xs-3"
          cogDataId="bucket-full-permission"
          [formControl]="form.formGroup.controls.fullPermission">
          {{ 'fullControl' | translate }}
        </mat-checkbox>
        <mat-checkbox class="col-xs-3"
          cogDataId="bucket-write-permission"
          [formControl]="form.formGroup.controls.writePermission">
          {{ 'write' | translate }}
        </mat-checkbox>
        <mat-checkbox class="col-xs-6"
          cogDataId="bucket-acl-write-permission"
          [formControl]="form.formGroup.controls.aclWritePermission">
          {{ 'updateAccessControlPolicy' | translate }}
        </mat-checkbox>
        <mat-checkbox class="col-xs-3 margin-top"
          cogDataId="bucket-read-permission"
          [formControl]="form.formGroup.controls.readPermission">
          {{ 'read' | translate }}
        </mat-checkbox>
        <mat-checkbox class="col-xs-6 margin-top"
          cogDataId="bucket-acl-read-permission"
          [formControl]="form.formGroup.controls.aclReadPermission">
          {{ 'readAccessControlPolicy' | translate }}
        </mat-checkbox>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button
      cogCancelButton
      cogDataId="add-acl-cancel-btn"
      type="button"
      mat-button
      color="primary"
      (click)="closeDialog()">
      {{'cancel' | translate}}
    </button>
    <button
      cogSubmitButton
      cogDataId="add-acl-btn"
      color="primary"
      type="submit"
      (click)="modifyAcl()"
      mat-flat-button>
      {{ (isEditMode ? 'save' : 'add') | translate }}
    </button>
  </mat-dialog-actions>
</form>

<ng-template #spinner>
  <cog-spinner></cog-spinner>
</ng-template>
