import { HttpClient, HttpHeaders, HttpParams, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IrisContextService, flagEnabled } from '@cohesity/iris-core';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import {
  HELIOS_AUTHN_TYPE_KEY,
  HELIOS_MCM_LOGIN_URL,
  HELIOS_TENANT_ID_HEADER_KEY,
  MSFT_AUTHN_TYPE_VAL,
  OPENID_AUTH_TOKEN_HEADER_KEY,
  PARAMETER_CODEC
} from '../utils/utils';

/**
 * Specifies the service to expose methods to trigger login call to helios.
 * This is needed since the /mcm/login API used by Helios is not Publicly
 * documented and has no swagger spec. The proposal to add swagger spec was
 * rejected since these are meant to be kept internal for now.
 */
@Injectable()
export class AuthenticatorService {
  /**
   * Indicates whether popup authentication workflow is enabled
   */
  isPopupWorkflowEnabled = flagEnabled(
    this.irisCtx.irisContext,
    'office365SelfServicePopupAuthWorkflowEnabled'
  );

  constructor(
    private irisCtx: IrisContextService,
    protected http: HttpClient
  ) { }

  /**
   * Sends a GET request to /mcm/login endpoint with the ID token in the header
   * for Authentication. The server validates the ID token provided. Once the
   * validation is done server creates a Helios Session and sets the Cookie.
   * It is the client's responsibility to then create the Helios Session.
   * Clients can additionally pass Cohesity Tenant ID as well to differentiate
   * between sources which are common across tenants. This however is not a
   * real world scenarios but is common within internal Cohesity setups.
   *
   * @param idToken Specifies the ID token.
   * @param cohesityTenantId Specifies the tenantID for the Cohesity Account.
   * @returns Authentication response.
   */
  loginToHelios(idToken: string, cohesityTenantId?: string): Observable<any> {
    let params = new HttpParams({
      encoder: PARAMETER_CODEC
    });
    params = params.set(HELIOS_AUTHN_TYPE_KEY, MSFT_AUTHN_TYPE_VAL);

    let headers = new HttpHeaders();
    headers = headers.set(OPENID_AUTH_TOKEN_HEADER_KEY, idToken);

    if (cohesityTenantId) {
      headers = headers.set(HELIOS_TENANT_ID_HEADER_KEY, cohesityTenantId);
    }

    const body: any = null;
    const req = new HttpRequest<any>(
      'GET',
      HELIOS_MCM_LOGIN_URL,
      body,
      {
        headers: headers,
        params: params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(r => r instanceof HttpResponse));
  }
}
