<h2 class="mat-dialog-title">
  {{'deleteStorageDomainTitle' | translate: {storageDomainName: dialogParams.storageDomain?.name} }}
</h2>

<ng-container *ngIf="isLoading$ | async">
  <div class="margin-top margin-bottom">
    <cog-spinner [inline]="true" size="sm" class="pull-left"></cog-spinner>
    {{'waitWhileFetchingData' | translate}}
  </div>
  <button mat-dialog-close mat-stroked-button class="pull-right">
    {{'cancel' | translate}}
  </button>
</ng-container>

<ng-container *ngIf="!(isLoading$ | async) && !canDeleteImmediately">

  <h5>{{'deleteStorageDomainExplain' | translate}}</h5>

  <cog-stepper-header id="stepper-header" [matStepperRef]="stepper"></cog-stepper-header>

  <form class="deleteSDForm" cogFormFocusedHighlight [formGroup]="deleteSDForm">
    <mat-horizontal-stepper #stepper cogStepper [selectedIndex]="stepperIndex">
      <!-- Step 1 -->
      <mat-step [stepControl]="deleteSDForm" [editable]="true"
        [label]="'numProtectionJobs' | translate: { num: protectionGroups?.length }">
        <div class="content-area">
          <coh-sd-protection-groups-list [protectionGroupList]="protectionGroups">
          </coh-sd-protection-groups-list>
        </div>
        <div class="button-footer margin-top">
          <mat-checkbox cogDataId="delete-all-sd-protection-jobs" class="col-xs-12 margin-bottom"
            formControlName="deleteAllProtectionJobsStatus" *ngIf="protectionGroups.length !== 0">
            {{'deleteAllProtectionJobsOfStorageDomain' | translate}}
          </mat-checkbox>
          <button mat-raised-button matStepperNext color="primary"
            [disabled]="!deleteSDForm.controls.deleteAllProtectionJobsStatus.value && protectionGroups.length !== 0">
            {{'next' | translate}}
          </button>
          <button mat-button cogCancelButton mat-dialog-close>
            {{'cancel' | translate}}
          </button>
        </div>
      </mat-step>

      <!-- Step 2 -->
      <mat-step [stepControl]="deleteSDForm" [editable]="true"
        [label]="'numRecoveriesInProgress' | translate: { num: recoveryList?.length }">
        <div class="content-area">
          <coh-sd-recoveries-list [recoveryList]="recoveryList"></coh-sd-recoveries-list>
        </div>
        <div class="button-footer">
          <mat-checkbox cogDataId="delete-all-sd-recoveries" class="col-xs-12 margin-bottom"
            formControlName="deleteAllRecoveriesStatus" *ngIf="recoveryList.length !== 0">
            {{'deleteAllRecoveriesOfStorageDomain' | translate}}
          </mat-checkbox>
          <button mat-raised-button matStepperPrevious color="primary" class="margin-right-sm">
            {{ 'previous' | translate }}
          </button>
          <button mat-raised-button color="primary" matStepperNext
            [disabled]="!deleteSDForm.controls.deleteAllRecoveriesStatus.value && recoveryList.length !== 0">
            {{'next' | translate}}
          </button>
          <button mat-button cogCancelButton mat-dialog-close>
            {{'cancel' | translate}}
          </button>
        </div>
      </mat-step>

      <!-- Step 3 -->
      <mat-step [stepControl]="deleteSDForm" [editable]="true"
        [label]="'numTestDevClones' | translate: { num: cloneList?.length }">
        <div class="content-area">
          <coh-sd-clones-list [cloneList]="cloneList"></coh-sd-clones-list>
        </div>
        <div class="button-footer">
          <mat-checkbox cogDataId="delete-all-sd-clones" class="col-xs-12 margin-bottom"
            formControlName="deleteAllClonesStatus" *ngIf="cloneList.length !== 0">
            {{'deleteAllClonesOfStorageDomain' | translate}}
          </mat-checkbox>
          <button mat-raised-button matStepperPrevious color="primary" class="margin-right-sm">
            {{ 'previous' | translate }}
          </button>
          <button mat-raised-button color="primary" matStepperNext
            [disabled]="!deleteSDForm.controls.deleteAllClonesStatus.value && cloneList.length !== 0">
            {{'next' | translate}}
          </button>
          <button mat-button cogCancelButton mat-dialog-close>
            {{'cancel' | translate}}
          </button>
        </div>
      </mat-step>

      <!-- Step 4 -->
      <mat-step [stepControl]="deleteSDForm" [editable]="true"
        [label]="'numView' | translate: { num: viewList?.length }">
        <div class="content-area">
          <coh-sd-views-list [viewList]="viewList"></coh-sd-views-list>
        </div>
        <div class="button-footer">
          <mat-checkbox cogDataId="delete-all-sd-views" class="col-xs-12 margin-bottom"
            formControlName="deleteAllViewsStatus" *ngIf="viewList.length !== 0">
            {{'deleteAllViewsOfStorageDomain' | translate}}
          </mat-checkbox>
          <button mat-raised-button matStepperPrevious color="primary" class="margin-right-sm">
            {{ 'previous' | translate }}
          </button>
          <button mat-raised-button color="primary" matStepperNext
            [disabled]="!deleteSDForm.controls.deleteAllViewsStatus.value && viewList.length !== 0">
            {{'next' | translate}}
          </button>
          <button mat-button cogCancelButton mat-dialog-close>
            {{'cancel' | translate}}
          </button>
        </div>
      </mat-step>

      <!-- Step 5 -->
      <mat-step [stepControl]="deleteSDForm" [editable]="true" label="{{'summary' | translate}}">
        <div class="margin-top">
          <ng-container *ngTemplateOutlet="finalAction"></ng-container>
        </div>
      </mat-step>
    </mat-horizontal-stepper>
  </form>
</ng-container>

<ng-container *ngIf="canDeleteImmediately">
  <ng-container *ngTemplateOutlet="finalAction"></ng-container>
</ng-container>

<ng-template #finalAction>
  <mat-dialog-content [class.content-area]="!canDeleteImmediately">
    <div class="delete-sd-summary margin-top margin-bottom" *ngIf="!canDeleteImmediately">
      <ul>
        <li>
          <span>{{protectionGroups?.length}}</span>
          {{'protectionJobs' | translate}}
        </li>
        <li>
          <span>{{recoveryList?.length}}</span>
          {{'recoveriesInProgress' | translate}}
        </li>
        <li>
          <span>{{cloneList?.length}}</span>
          {{'testDevClones' | translate}}
        </li>
        <li>
          <span>{{viewList?.length}}</span>
          {{'views' | translate}}
        </li>
      </ul>
    </div>

    <div class="margin-top-xl" *ngIf="!canDeleteImmediately">
      {{'actionCantUndone' | translate}}
    </div>
    <mat-form-field class="no-padding"
      [class.col-xs-5]="!canDeleteImmediately">
      <input cogDataId="confirm-delete-sd-input"
        required
        matInput
        placeholder="{{'typeYesToConfirm' | translate}}"
        [formControl]="deleteSDForm.get('confirmDeleteStorageDomain')">
      <mat-error cogDataId="text-must-type-yes-to-confirm"
        *ngIf="deleteSDForm.controls.confirmDeleteStorageDomain.errors?.required">
        {{'errors.required' | translate}}
      </mat-error>
      <mat-error cogDataId="text-format-type-yes-to-confirm"
        *ngIf="deleteSDForm.controls.confirmDeleteStorageDomain.errors?.pattern">
        {{'mustInputYes' | translate}}
      </mat-error>
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions class="margin-top"
    [class.button-footer]="!canDeleteImmediately" align="end">
    <button *ngIf="!canDeleteImmediately"
      cogDataId="final-action-previous"
      mat-raised-button
      color="primary"
      class="margin-right-sm"
      (click)="stepper.previous()">
      {{ 'previous' | translate }}
    </button>
    <button cogCancelButton mat-dialog-close mat-button>
      {{'cancel' | translate}}
    </button>
    <button cogDataId="submit-delete-sd"
      mat-flat-button
      cogSubmitButton
      color="warn"
      [disabled]="deleteSDForm.invalid"
      (click)="deleteSD()">
      {{'deleteStorageDomain' | translate}}
    </button>
  </mat-dialog-actions>
</ng-template>
