/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Environment } from '../models/environment';
import { GetIocAffectedFilesResponse } from '../models/get-ioc-affected-files-response';
import { GetScannedObjectsResponse } from '../models/get-scanned-objects-response';
import { GetScansResponse } from '../models/get-scans-response';
import { ObjectId } from '../models/object-id';
import { ObjectType } from '../models/object-type';
import { OutputFormat } from '../models/output-format';
import { PaginationToken } from '../models/pagination-token';
import { Scan } from '../models/scan';
import { ScanActionParams } from '../models/scan-action-params';
import { ScanActionResponse } from '../models/scan-action-response';
import { ScanHealthStatus } from '../models/scan-health-status';
import { ScanId } from '../models/scan-id';
import { ScanParams } from '../models/scan-params';
import { ScanThreatCategory } from '../models/scan-threat-category';
import { ScanThreatFamily } from '../models/scan-threat-family';
import { ScanTrigger } from '../models/scan-trigger';
import { Severity } from '../models/severity';
import { SnapshotId } from '../models/snapshot-id';
import { TimestampMsecs } from '../models/timestamp-msecs';
import { TimestampUsecs } from '../models/timestamp-usecs';

@Injectable({
  providedIn: 'root',
})
export class IoCsScansApiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getScans
   */
  static readonly GetScansPath = '/ioc/scans';

  /**
   * Get a list of Indicators of Compromise (IOCs) scans.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getScans()` instead.
   *
   * This method doesn't expect any request body.
   */
  getScans$Response(params?: {

    /**
     * Specifies the filter by type of the scan run statuses.
     */
    statuses?: Array<ScanHealthStatus>;

    /**
     * Specifies the filter by detected Indicators of Compromise (IOCs) severities.
     */
    severities?: Array<Severity>;

    /**
     * Specifies the filter by object ids.
     */
    objectIds?: Array<ObjectId>;

    /**
     * Specifies the filter by snapshot ids.
     */
    snapshotIds?: Array<SnapshotId>;

    /**
     * Specifies the filter by minimum number of affected files.
     */
    minAffectedFileCount?: null | number;

    /**
     * Specifies the filter by maximum number of affected files.
     */
    maxAffectedFileCount?: null | number;

    /**
     * Specifies the filter by scan start time in milli seconds.
     */
    startTimeMsecs?: TimestampMsecs;

    /**
     * Specifies the filter by scan end time in milli seconds.
     */
    endTimeMsecs?: TimestampMsecs;

    /**
     * Specifies the filter by how scan was triggered (manually or ransomware alert triggered).
     */
    scanTriggers?: Array<ScanTrigger>;

    /**
     * Specifies filter by environment types of objects included in scan.
     */
    environments?: Array<Environment>;

    /**
     * Specifies filter by objectTypes of objects included in scan.
     */
    objectTypes?: Array<ObjectType>;

    /**
     * Specifies the maximum number of scans per response and it should be less than 100.
     */
    pageSize?: null | number;

    /**
     * Specifies the pagination token used to get the next page.
     */
    paginationToken?: PaginationToken;

  }): Observable<StrictHttpResponse<GetScansResponse>> {

    const rb = new RequestBuilder(this.rootUrl, IoCsScansApiService.GetScansPath, 'get');
    if (params) {

      rb.query('statuses', params.statuses, {});
      rb.query('severities', params.severities, {});
      rb.query('objectIds', params.objectIds, {});
      rb.query('snapshotIds', params.snapshotIds, {});
      rb.query('minAffectedFileCount', params.minAffectedFileCount, {});
      rb.query('maxAffectedFileCount', params.maxAffectedFileCount, {});
      rb.query('startTimeMsecs', params.startTimeMsecs, {});
      rb.query('endTimeMsecs', params.endTimeMsecs, {});
      rb.query('scanTriggers', params.scanTriggers, {});
      rb.query('environments', params.environments, {});
      rb.query('objectTypes', params.objectTypes, {});
      rb.query('pageSize', params.pageSize, {});
      rb.query('paginationToken', params.paginationToken, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetScansResponse>;
      })
    );
  }

  /**
   * Get a list of Indicators of Compromise (IOCs) scans.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getScans$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getScans(params?: {

    /**
     * Specifies the filter by type of the scan run statuses.
     */
    statuses?: Array<ScanHealthStatus>;

    /**
     * Specifies the filter by detected Indicators of Compromise (IOCs) severities.
     */
    severities?: Array<Severity>;

    /**
     * Specifies the filter by object ids.
     */
    objectIds?: Array<ObjectId>;

    /**
     * Specifies the filter by snapshot ids.
     */
    snapshotIds?: Array<SnapshotId>;

    /**
     * Specifies the filter by minimum number of affected files.
     */
    minAffectedFileCount?: null | number;

    /**
     * Specifies the filter by maximum number of affected files.
     */
    maxAffectedFileCount?: null | number;

    /**
     * Specifies the filter by scan start time in milli seconds.
     */
    startTimeMsecs?: TimestampMsecs;

    /**
     * Specifies the filter by scan end time in milli seconds.
     */
    endTimeMsecs?: TimestampMsecs;

    /**
     * Specifies the filter by how scan was triggered (manually or ransomware alert triggered).
     */
    scanTriggers?: Array<ScanTrigger>;

    /**
     * Specifies filter by environment types of objects included in scan.
     */
    environments?: Array<Environment>;

    /**
     * Specifies filter by objectTypes of objects included in scan.
     */
    objectTypes?: Array<ObjectType>;

    /**
     * Specifies the maximum number of scans per response and it should be less than 100.
     */
    pageSize?: null | number;

    /**
     * Specifies the pagination token used to get the next page.
     */
    paginationToken?: PaginationToken;

  }): Observable<GetScansResponse> {

    return this.getScans$Response(params).pipe(
      map((r: StrictHttpResponse<GetScansResponse>) => r.body as GetScansResponse)
    );
  }

  /**
   * Path part for operation createScan
   */
  static readonly CreateScanPath = '/ioc/scans';

  /**
   * Create a new IOCs scan.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createScan()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createScan$Response(params: {
      body: ScanParams
  }): Observable<StrictHttpResponse<Scan>> {

    const rb = new RequestBuilder(this.rootUrl, IoCsScansApiService.CreateScanPath, 'post');
    if (params) {


      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Scan>;
      })
    );
  }

  /**
   * Create a new IOCs scan.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createScan$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createScan(params: {
      body: ScanParams
  }): Observable<Scan> {

    return this.createScan$Response(params).pipe(
      map((r: StrictHttpResponse<Scan>) => r.body as Scan)
    );
  }

  /**
   * Path part for operation performActionOnIocScan
   */
  static readonly PerformActionOnIocScanPath = '/ioc/scans/actions';

  /**
   * Perform Actions on IOCs scan.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `performActionOnIocScan()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  performActionOnIocScan$Response(params: {
      body: ScanActionParams
  }): Observable<StrictHttpResponse<ScanActionResponse>> {

    const rb = new RequestBuilder(this.rootUrl, IoCsScansApiService.PerformActionOnIocScanPath, 'post');
    if (params) {


      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ScanActionResponse>;
      })
    );
  }

  /**
   * Perform Actions on IOCs scan.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `performActionOnIocScan$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  performActionOnIocScan(params: {
      body: ScanActionParams
  }): Observable<ScanActionResponse> {

    return this.performActionOnIocScan$Response(params).pipe(
      map((r: StrictHttpResponse<ScanActionResponse>) => r.body as ScanActionResponse)
    );
  }

  /**
   * Path part for operation getScanById
   */
  static readonly GetScanByIdPath = '/ioc/scans/{id}';

  /**
   * Get IOCs scan by ID.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getScanById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getScanById$Response(params: {

    /**
     * The scan id.
     */
    id: ScanId;

  }): Observable<StrictHttpResponse<Scan>> {

    const rb = new RequestBuilder(this.rootUrl, IoCsScansApiService.GetScanByIdPath, 'get');
    if (params) {

      rb.path('id', params.id, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Scan>;
      })
    );
  }

  /**
   * Get IOCs scan by ID.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getScanById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getScanById(params: {

    /**
     * The scan id.
     */
    id: ScanId;

  }): Observable<Scan> {

    return this.getScanById$Response(params).pipe(
      map((r: StrictHttpResponse<Scan>) => r.body as Scan)
    );
  }

  /**
   * Path part for operation updateScan
   */
  static readonly UpdateScanPath = '/ioc/scans/{id}';

  /**
   * Update IOCs scan.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateScan()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateScan$Response(params: {

    /**
     * The scan id.
     */
    id: ScanId;
      body: ScanParams
  }): Observable<StrictHttpResponse<Scan>> {

    const rb = new RequestBuilder(this.rootUrl, IoCsScansApiService.UpdateScanPath, 'put');
    if (params) {

      rb.path('id', params.id, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Scan>;
      })
    );
  }

  /**
   * Update IOCs scan.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateScan$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateScan(params: {

    /**
     * The scan id.
     */
    id: ScanId;
      body: ScanParams
  }): Observable<Scan> {

    return this.updateScan$Response(params).pipe(
      map((r: StrictHttpResponse<Scan>) => r.body as Scan)
    );
  }

  /**
   * Path part for operation deleteScan
   */
  static readonly DeleteScanPath = '/ioc/scans/{id}';

  /**
   * Delete IOCs scan.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteScan()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteScan$Response(params: {

    /**
     * The scan id.
     */
    id: ScanId;

  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, IoCsScansApiService.DeleteScanPath, 'delete');
    if (params) {

      rb.path('id', params.id, {});

    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete IOCs scan.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteScan$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteScan(params: {

    /**
     * The scan id.
     */
    id: ScanId;

  }): Observable<void> {

    return this.deleteScan$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getIocAffectedFiles
   */
  static readonly GetIocAffectedFilesPath = '/ioc/scans/{id}/affected-files';

  /**
   * Get the affected files reported for given scan.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getIocAffectedFiles$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIocAffectedFiles$Json$Response(params: {

    /**
     * The scan id.
     */
    id: ScanId;

    /**
     * Specifies the search string with file name or path name.
     */
    searchTerm?: string;

    /**
     * Specifies the filter by file extensions.
     */
    fileExtensions?: null | Array<string>;

    /**
     * Specifies the filter by object ids.
     */
    objectIds?: Array<ObjectId>;

    /**
     * Specifies the filter by snapshot ids.
     */
    snapshotIds?: Array<SnapshotId>;

    /**
     * Specifies the filter by snapshot start time of Run in Unix epoch Timestamp(in microseconds).
     */
    snapshotStartTimeUsecs?: TimestampUsecs;

    /**
     * Specifies the filter by snapshot end time of Run in Unix epoch Timestamp(in microseconds).
     */
    snapshotEndTimeUsecs?: TimestampUsecs;

    /**
     * Specifies the filter by detected Indicators of Compromise (IOCs) severities.
     */
    severities?: Array<Severity>;

    /**
     * Specifies the filter by threat family.
     */
    threatFamilies?: Array<ScanThreatFamily>;

    /**
     * Specifies the filter by threat category.
     */
    threatCategories?: Array<ScanThreatCategory>;

    /**
     * Specifies filter by environment types of objects included in scan.
     */
    environments?: Array<Environment>;

    /**
     * Specifies filter by objectTypes of objects included in scan.
     */
    objectTypes?: Array<ObjectType>;

    /**
     * Specifies the format of the output such as csv or json(default).
     */
    outputFormat?: OutputFormat;

    /**
     * Specifies the maximum number of file paths returned per response and it should be less that 1000.
     */
    pageSize?: null | number;

    /**
     * Specifies the pagination token used to get next page.
     */
    paginationToken?: PaginationToken;

  }): Observable<StrictHttpResponse<GetIocAffectedFilesResponse>> {

    const rb = new RequestBuilder(this.rootUrl, IoCsScansApiService.GetIocAffectedFilesPath, 'get');
    if (params) {

      rb.path('id', params.id, {});
      rb.query('searchTerm', params.searchTerm, {});
      rb.query('fileExtensions', params.fileExtensions, {});
      rb.query('objectIds', params.objectIds, {});
      rb.query('snapshotIds', params.snapshotIds, {});
      rb.query('snapshotStartTimeUsecs', params.snapshotStartTimeUsecs, {});
      rb.query('snapshotEndTimeUsecs', params.snapshotEndTimeUsecs, {});
      rb.query('severities', params.severities, {});
      rb.query('threatFamilies', params.threatFamilies, {});
      rb.query('threatCategories', params.threatCategories, {});
      rb.query('environments', params.environments, {});
      rb.query('objectTypes', params.objectTypes, {});
      rb.query('outputFormat', params.outputFormat, {});
      rb.query('pageSize', params.pageSize, {});
      rb.query('paginationToken', params.paginationToken, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetIocAffectedFilesResponse>;
      })
    );
  }

  /**
   * Get the affected files reported for given scan.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getIocAffectedFiles$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIocAffectedFiles$Json(params: {

    /**
     * The scan id.
     */
    id: ScanId;

    /**
     * Specifies the search string with file name or path name.
     */
    searchTerm?: string;

    /**
     * Specifies the filter by file extensions.
     */
    fileExtensions?: null | Array<string>;

    /**
     * Specifies the filter by object ids.
     */
    objectIds?: Array<ObjectId>;

    /**
     * Specifies the filter by snapshot ids.
     */
    snapshotIds?: Array<SnapshotId>;

    /**
     * Specifies the filter by snapshot start time of Run in Unix epoch Timestamp(in microseconds).
     */
    snapshotStartTimeUsecs?: TimestampUsecs;

    /**
     * Specifies the filter by snapshot end time of Run in Unix epoch Timestamp(in microseconds).
     */
    snapshotEndTimeUsecs?: TimestampUsecs;

    /**
     * Specifies the filter by detected Indicators of Compromise (IOCs) severities.
     */
    severities?: Array<Severity>;

    /**
     * Specifies the filter by threat family.
     */
    threatFamilies?: Array<ScanThreatFamily>;

    /**
     * Specifies the filter by threat category.
     */
    threatCategories?: Array<ScanThreatCategory>;

    /**
     * Specifies filter by environment types of objects included in scan.
     */
    environments?: Array<Environment>;

    /**
     * Specifies filter by objectTypes of objects included in scan.
     */
    objectTypes?: Array<ObjectType>;

    /**
     * Specifies the format of the output such as csv or json(default).
     */
    outputFormat?: OutputFormat;

    /**
     * Specifies the maximum number of file paths returned per response and it should be less that 1000.
     */
    pageSize?: null | number;

    /**
     * Specifies the pagination token used to get next page.
     */
    paginationToken?: PaginationToken;

  }): Observable<GetIocAffectedFilesResponse> {

    return this.getIocAffectedFiles$Json$Response(params).pipe(
      map((r: StrictHttpResponse<GetIocAffectedFilesResponse>) => r.body as GetIocAffectedFilesResponse)
    );
  }

  /**
   * Get the affected files reported for given scan.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getIocAffectedFiles$Csv()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIocAffectedFiles$Csv$Response(params: {

    /**
     * The scan id.
     */
    id: ScanId;

    /**
     * Specifies the search string with file name or path name.
     */
    searchTerm?: string;

    /**
     * Specifies the filter by file extensions.
     */
    fileExtensions?: null | Array<string>;

    /**
     * Specifies the filter by object ids.
     */
    objectIds?: Array<ObjectId>;

    /**
     * Specifies the filter by snapshot ids.
     */
    snapshotIds?: Array<SnapshotId>;

    /**
     * Specifies the filter by snapshot start time of Run in Unix epoch Timestamp(in microseconds).
     */
    snapshotStartTimeUsecs?: TimestampUsecs;

    /**
     * Specifies the filter by snapshot end time of Run in Unix epoch Timestamp(in microseconds).
     */
    snapshotEndTimeUsecs?: TimestampUsecs;

    /**
     * Specifies the filter by detected Indicators of Compromise (IOCs) severities.
     */
    severities?: Array<Severity>;

    /**
     * Specifies the filter by threat family.
     */
    threatFamilies?: Array<ScanThreatFamily>;

    /**
     * Specifies the filter by threat category.
     */
    threatCategories?: Array<ScanThreatCategory>;

    /**
     * Specifies filter by environment types of objects included in scan.
     */
    environments?: Array<Environment>;

    /**
     * Specifies filter by objectTypes of objects included in scan.
     */
    objectTypes?: Array<ObjectType>;

    /**
     * Specifies the format of the output such as csv or json(default).
     */
    outputFormat?: OutputFormat;

    /**
     * Specifies the maximum number of file paths returned per response and it should be less that 1000.
     */
    pageSize?: null | number;

    /**
     * Specifies the pagination token used to get next page.
     */
    paginationToken?: PaginationToken;

  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, IoCsScansApiService.GetIocAffectedFilesPath, 'get');
    if (params) {

      rb.path('id', params.id, {});
      rb.query('searchTerm', params.searchTerm, {});
      rb.query('fileExtensions', params.fileExtensions, {});
      rb.query('objectIds', params.objectIds, {});
      rb.query('snapshotIds', params.snapshotIds, {});
      rb.query('snapshotStartTimeUsecs', params.snapshotStartTimeUsecs, {});
      rb.query('snapshotEndTimeUsecs', params.snapshotEndTimeUsecs, {});
      rb.query('severities', params.severities, {});
      rb.query('threatFamilies', params.threatFamilies, {});
      rb.query('threatCategories', params.threatCategories, {});
      rb.query('environments', params.environments, {});
      rb.query('objectTypes', params.objectTypes, {});
      rb.query('outputFormat', params.outputFormat, {});
      rb.query('pageSize', params.pageSize, {});
      rb.query('paginationToken', params.paginationToken, {});

    }
    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/csv'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Get the affected files reported for given scan.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getIocAffectedFiles$Csv$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIocAffectedFiles$Csv(params: {

    /**
     * The scan id.
     */
    id: ScanId;

    /**
     * Specifies the search string with file name or path name.
     */
    searchTerm?: string;

    /**
     * Specifies the filter by file extensions.
     */
    fileExtensions?: null | Array<string>;

    /**
     * Specifies the filter by object ids.
     */
    objectIds?: Array<ObjectId>;

    /**
     * Specifies the filter by snapshot ids.
     */
    snapshotIds?: Array<SnapshotId>;

    /**
     * Specifies the filter by snapshot start time of Run in Unix epoch Timestamp(in microseconds).
     */
    snapshotStartTimeUsecs?: TimestampUsecs;

    /**
     * Specifies the filter by snapshot end time of Run in Unix epoch Timestamp(in microseconds).
     */
    snapshotEndTimeUsecs?: TimestampUsecs;

    /**
     * Specifies the filter by detected Indicators of Compromise (IOCs) severities.
     */
    severities?: Array<Severity>;

    /**
     * Specifies the filter by threat family.
     */
    threatFamilies?: Array<ScanThreatFamily>;

    /**
     * Specifies the filter by threat category.
     */
    threatCategories?: Array<ScanThreatCategory>;

    /**
     * Specifies filter by environment types of objects included in scan.
     */
    environments?: Array<Environment>;

    /**
     * Specifies filter by objectTypes of objects included in scan.
     */
    objectTypes?: Array<ObjectType>;

    /**
     * Specifies the format of the output such as csv or json(default).
     */
    outputFormat?: OutputFormat;

    /**
     * Specifies the maximum number of file paths returned per response and it should be less that 1000.
     */
    pageSize?: null | number;

    /**
     * Specifies the pagination token used to get next page.
     */
    paginationToken?: PaginationToken;

  }): Observable<string> {

    return this.getIocAffectedFiles$Csv$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation getScannedObjects
   */
  static readonly GetScannedObjectsPath = '/ioc/scanned-objects';

  /**
   * Get a list of objects for which TH scans were triggered.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getScannedObjects()` instead.
   *
   * This method doesn't expect any request body.
   */
  getScannedObjects$Response(params?: {

  }): Observable<StrictHttpResponse<GetScannedObjectsResponse>> {

    const rb = new RequestBuilder(this.rootUrl, IoCsScansApiService.GetScannedObjectsPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetScannedObjectsResponse>;
      })
    );
  }

  /**
   * Get a list of objects for which TH scans were triggered.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getScannedObjects$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getScannedObjects(params?: {

  }): Observable<GetScannedObjectsResponse> {

    return this.getScannedObjects$Response(params).pipe(
      map((r: StrictHttpResponse<GetScannedObjectsResponse>) => r.body as GetScannedObjectsResponse)
    );
  }

}
