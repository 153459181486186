import { Injectable } from '@angular/core';
import { Cluster } from '@cohesity/api/v1';

import { ClusterService } from './cluster.service';

/**
 * This service is used for retrieving EULA related information. It is downgraded to AngularJS as NgEulaService.
 */
@Injectable({
  providedIn: 'root',
})
export class EulaService {
  /**
   * The current EULA version that should be validate against. This should be compared to the signed version to
   * determine if the user should be shown and required to accept an updated EULA.
   *
   */
  readonly version = 4;

  /**
   * Same concept as above except this applies to Helios usage only.
   */
  readonly heliosVersion = 9;

  /**
   * The base path where raw EULA files are stored.
   */
  private basePath = '/assets/eula/';

  /**
   * The path to the non-helios EULA file. Access this via eulaPath().
   */
  private standardEulaFile = `${this.basePath}cohesity-eula-${this.version}.html`;

  /**
   * The path to the helios EULA file. Access this via eulaPath().
   */
  private heliosFile = `${this.basePath}cohesity-helios-eula-${this.heliosVersion}.html`;

  /**
   * The path to the HP specific EULA file. This is provided when running on HP hardware.
   */
  hpFile = `${this.basePath}hp-eula.html`;

  /**
   * The path to the MCM On Prem EULA PDF
   */
  private mcmOnPremEulaPdf = `${this.basePath}eula.pdf`;

  /**
   * Determines which EULA path to provide based on mcmMode/helios flag.
   */
  get eulaPath() {
    // Default mcmMode boolean to false. This is probably not necessary for prod but helps avoid mocking this service
    // in tests that might utilize it.
    const mcmMode = this.clusterSvc.basicClusterInfo ? this.clusterSvc.basicClusterInfo.mcmMode : false;

    const mcmOnPremMode = this.clusterSvc.basicClusterInfo ? this.clusterSvc.basicClusterInfo.mcmOnPremMode : false;

    return mcmOnPremMode ? this.mcmOnPremEulaPdf : mcmMode ? this.heliosFile : this.standardEulaFile;
  }

  /**
   * Determines whether EULA acceptance is needed before proceeding
   * with workflows.
   */
  isEulaNeeded(clusterInfo: Cluster): boolean {
    const { eulaConfig = null } = clusterInfo;
    return eulaConfig?.signedVersion && eulaConfig.signedVersion < this.version;
  }

  constructor(private clusterSvc: ClusterService) {}
}
