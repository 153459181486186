import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AngularMaterialModule } from '../../angular-material.module';
import { AppBannerModule } from '../app-banner/app-banner.module';
import { DataIdModule } from '../data-id/data-id.module';
import { HelixAssetsModule } from '../helix-assets';
import { IconModule } from '../icon/icon.module';
import { NavItemModule } from '../nav-item/nav-item.module';
import { AppBrandingComponent } from './app-branding/app-branding.component';
import {
  AppBarLeftComponent,
  AppBarRightComponent,
  AppContentComponent,
  AppFrameComponent,
  AppSideNavFooterComponent,
} from './app-frame.component';
import { AppNavComponent } from './app-nav/app-nav.component';
import { NavBannerComponent } from './nav-banner/nav-banner.component';

const COMPONENTS = [
  AppBarLeftComponent,
  AppBarRightComponent,
  AppBrandingComponent,
  AppContentComponent,
  AppFrameComponent,
  AppNavComponent,
  AppSideNavFooterComponent,
  NavBannerComponent,
];

/**
 * Module for all components related to the application frame. This includes
 * the top app bar, and drawer components.
 */
@NgModule({
  imports: [
    AngularMaterialModule,
    AppBannerModule,
    CommonModule,
    DataIdModule,
    HelixAssetsModule,
    IconModule,
    NavItemModule,
  ],
  declarations: COMPONENTS,
  exports: [...COMPONENTS, NavItemModule]
})
export class AppFrameModule { }
