import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared';

import { GlobalSearchInputComponent } from './components/global-search-input/global-search-input.component';
import { GlobalSearchItemIconComponent } from './components/global-search-item-icon/global-search-item-icon.component';

@NgModule({
  declarations: [GlobalSearchInputComponent, GlobalSearchItemIconComponent],
  exports: [GlobalSearchInputComponent, GlobalSearchItemIconComponent],
  imports: [SharedModule],
})
export class GlobalSearchSharedModule {
}
