import { Injectable } from '@angular/core';
import { AjsUpgradeService } from 'src/app/core/services/ajs-upgrade.service';

/**
 * Angular replacement for legacy ClusterService service.
 * Some methods are pass-through for now and will be replaced.
 */
@Injectable({
  providedIn: 'root'
})
export class AjsActiveDirectoryService {
  /**
   * Legacy ActiveDirectory AngularJS service.
   */
  private ajsActiveDirectoryService: any;

  constructor(private ajsUpgrade: AjsUpgradeService) {
    this.ajsActiveDirectoryService = this.ajsUpgrade.get('ActiveDirectoryService');
  }

  /**
   * Update cluster setting.
   *
   * @param   param    Request object used in searchPrincipals function.
   */
  searchPrincipal(param) {
    return this.ajsActiveDirectoryService.searchPrincipals(param);
  }

}
