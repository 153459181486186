// Component: sources-mini-view

;(function (angular, undefined) {
  'use strict';

  var modName = 'C.pubSourceTree';
  var componentName = 'sourcesMiniView';
  var options = {
    bindings: {
      // @type  {Array}  List of nodes to be grouped based  node on environment
      // type
      tree: '=',

      /**
       * Optional attribute sniffed value, If present then the rendered source
       * tree editing(removal) is allowed but selection will be disabled.
       */
      // modifyMode: '?',

      /**
       * Optional provide restricted user sid used to determine logic for node
       * removal
       */
      sid: '<?',

      /**
       * Optional provide tenant id used to determine logic for node removal
       */
      tenantId: '<?',

      /**
       * Optional bool to skip decorate node for assignment
       */
      skipDecorateNodes: '<?',
    },
    controller: 'SourcesMiniViewCtrl',
    templateUrl:
      'app/global/c-source-tree/sources-mini-view/sources-mini-view.html',
  };

  angular
    .module(modName)
    .controller('SourcesMiniViewCtrl', SourcesMiniViewCtrlFn)
    .component(componentName, options);

  /**
   * @ngdoc component
   * @name C.pubSourceTree:sourcesMiniView
   * @function
   *
   * @description
   * Displays provided source tree for view or modification mode and used to
   * show/modify assigned sources to restricted user or tenant.
   *
   * @example
     <sources-mini-view
        modify-mode
        tenant-id="$ctrl.tenant.tenantId"
        tree="$ctrl.tenant._sources"></sources-mini-view>
   */
  function SourcesMiniViewCtrlFn(_, $attrs, FEATURE_FLAGS, PubJobServiceFormatter) {
    var $ctrl = this;

    // declare component methods
    _.assign($ctrl, {
      // Indicate the purpose of usage for sourceGroupModal component i.e. for assignToUser or assignToTenant.
      purpose: '',
      // component life-cycle methods
      $onInit: $onInit,

      sourceGroupOpts: {},
    });

    /**
     * Initializes the controller.
     *
     * @method     $onInit
     */
    function $onInit() {
      _.assign($ctrl, {
        hasSid: $attrs.hasOwnProperty('sid'),
        hasTenantId: $attrs.hasOwnProperty('tenantId'),
        modifyMode: $attrs.hasOwnProperty('modifyMode'),
      });

      if ($ctrl.hasSid) {
        $ctrl.purpose = 'assignToUser';
      } else if ($ctrl.hasTenantId) {
        $ctrl.purpose = 'assignToTenant';
      }

      if (FEATURE_FLAGS.restrictedUserWithSourceFilters ||
        FEATURE_FLAGS.tenantUserWithSourceFilters) {
        $ctrl.sourceGroupOpts.noFilters = false;
      }

      if (!$ctrl.skipDecorateNodes) {
        PubJobServiceFormatter.forEachNode($ctrl.tree, function eachNode(node, index, list, path) {
          PubJobServiceFormatter.decorateSourceForAssignment(node, {
            purpose: $ctrl.purpose,
            pathToRoot: path,
            tenantId: $ctrl.tenantId,
          });
        });
      }
    }
  }

})(angular);
