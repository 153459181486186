<ng-container *ngFor="let aagObject of data | keyvalue">
  <h4 [innerHTML]="'aagNamed' | translate: {name: aagObject.key}"></h4>
  <ul class="hosts-list flex-row-lg">
    <li class="flex-row-xs vertical-align"
      *ngFor="let host of aagObject.value.hosts"
      [class.status-critical]="host.unknownHostName">
      <span [ngSwitch]="!!host.environment"
        [matTooltip]="host.errorMessage || (host | sourceType)">
        <cog-icon shape="help" *ngSwitchDefault></cog-icon>
        <cog-icon *ngSwitchCase="true"
          [class]="{ protectionSource: host } | sourceIcon"></cog-icon>
      </span>
      <span>{{host.name || host.unknownHostName}}</span>
    </li>
  </ul>
</ng-container>
