import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ChangeDetectionStrategy, Component, ElementRef, forwardRef, Input, ViewChild } from '@angular/core';
import { UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatLegacyAutocompleteTrigger as MatAutocompleteTrigger } from '@angular/material/legacy-autocomplete';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import { MatLegacyFormFieldControl as MatFormFieldControl } from '@angular/material/legacy-form-field';
import { ItemPickerFormControl } from '@cohesity/shared-forms';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { TagAttribute, TagCategory } from '../source-tree-filters';

/**
 * Auto complete search for source tree tags.
 *
 * @example
 * <coh-select-tag-control
 *  valueChange)="updateViewFilter('tagFilter')"
 *  [formControl]="selectedTags"
 *  [tagCategories]="tagCategories$ | async">
 * </coh-select-tag-control>
 */
@Component({
  selector: 'coh-select-tag-control',
  templateUrl: './select-tag-control.component.html',
  styleUrls: ['./select-tag-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectTagControlComponent),
      multi: true,
    },
    {
      provide: MatFormFieldControl,
      useExisting: SelectTagControlComponent,
    },
  ],
})
export class SelectTagControlComponent extends ItemPickerFormControl<TagAttribute[]> {
  /* Get filter form field label depending on the catgory of the filter.
   * If category is 'All Labels', then return 'Labels' else return 'Tags'.
   */
  get filterLabel(): string {
    if (this.isTagLabel) {
      return this.translate.instant('labels');
    } else {
      return this.translate.instant('tags');
    }
  }

  /* Get place holder text for filter form field depending on the form label.
   * If form label is 'Labels', then return 'selectLabels' else return 'selectTags'.
   */
  get placeHolderText(): string {
    if (this.isTagLabel) {
      return this.translate.instant('selectLabels');
    } else {
      return this.translate.instant('selectTags');
    }
  }

  /**
   * Sets the tag categories input.
   */
  @Input() set tagCategories(tagCategories: TagCategory[]) {
    this.tagCategories$.next(tagCategories);
  }

  /**
   * Available tag categories to select from in the list. Each category shows as a group within the
   * autocomplete dropdown.
   */
  get tagCategories(): TagCategory[] {
    return this.tagCategories$.value;
  }

  /**
   * Check if it is a tag or label.
   */
  @Input() isTagLabel: boolean;

  /**
   * The current filtered list of tag categories. Filters are run on eached categorie's tag
   * groups, and categories with no in-filter groups are hidden. This also filters out values
   * which are currently selected.
   */
  filteredCategories$: Observable<TagCategory[]>;

  /**
   * The control for the tag search input.
   */
  searchControl = new UntypedFormControl();

  /**
   * When entered into the searchControl, these values trigger adding a tag.
   */
  separatorKeysCodes: number[] = [ENTER, COMMA];

  /**
   * The search input control.
   */
  @ViewChild('tagInput', { static: true }) private searchInput: ElementRef<HTMLInputElement>;

  /**
   * The auto complete trigger, used to check if the panel is open and open it after a user
   * inputs a value.
   */
  @ViewChild(MatAutocompleteTrigger, { static: true }) private autoCompleteTrigger: MatAutocompleteTrigger;

  /**
   * Behavior subject to track changes to tag category input.
   */
  private tagCategories$ = new BehaviorSubject<TagCategory[]>([]);

  constructor(private translate: TranslateService) {
    super();

    // Configure the filter to update based on the search value.
    this.filteredCategories$ = combineLatest([
      this.searchControl.valueChanges.pipe(startWith('')),
      this.tagCategories$,
      this.valueChange.pipe(startWith([])),
    ]).pipe(map(([query]) => this.filterTags(query)));
  }

  /**
   * Select a tag when the mat chip input fires. This runs a query based on the
   * current search query an selects the first matching tag in the list.
   *
   * @param   event   The mat chip input event.
   */
  add(event: MatChipInputEvent) {
    if (this.autoCompleteTrigger.panelOpen || !event.value) {
      return;
    }
    const matches = this.filterTags(event.value);

    // Ignore if there are no matches
    if (!matches.length) {
      return;
    }
    // Add the first matching result to the selection
    this.selectTags(matches[0].tagGroups[0]);

    this.autoCompleteTrigger.openPanel();
  }

  /**
   * Formats a tag group (array of tags) for display by joining the names on ','.
   *
   * @param   tags   One or more tag attributes.
   * @returns  The tag(s) display name.
   */
  getTagGroupLabel(tags: TagAttribute[]): string {
    return tags.map(tag => tag.name).join(', ');
  }

  /**
   * Removes a tag from the selection at the specified index.
   *
   * @param   index   The index of the tag to remove.
   */
  remove(index: number) {
    const value = [...this.value];
    value.splice(index, 1);
    this.value = value;
  }

  /**
   * Adds tags to the selection. Ensure that duplicates aren't added, and focuse the input when
   * finished so the user can continue adding tags.
   *
   * @param   tags   The tags to select.
   */
  selectTags(tags: TagAttribute[]) {
    const currentValue = this.value || [];
    // Don't add duplicate tags
    tags = tags.filter(tag => !currentValue.find(selectedTag => selectedTag.id === tag.id));

    this.value = [...currentValue, ...tags];
    this.searchControl.setValue(null);
    this.searchInput.nativeElement.value = '';
    this.searchInput.nativeElement.focus();
  }

  /**
   * Filters the available tags.
   *
   * @param    query   The search query to filter on. This also gets called when an item is selected from
   *                   the dropwdown, which passes a TagAttribute instance rather than a string.
   * @returns  An array of TagAttributes matching the current search, and are not already selected.
   */
  private filterTags(query: string | TagAttribute): TagCategory[] {
    const search = typeof query === 'string' ? query.toLowerCase() : null;
    return this.tagCategories
      .map(category => {
        // Remove tags which have already been selected so that they don't show in the drop down.
        const unselectedGroup: TagAttribute[][] = category.tagGroups;
        const currentValue = this.value || [];

        category.tagGroups.filter(
          tags => !tags.every(tag => currentValue.find(selectedTag => selectedTag.id === tag.id))
        );

        return {
          name: category.name,
          tagGroups: unselectedGroup.filter(tags =>
            tags.some(tag => !search || tag.name.toLowerCase().indexOf(search) > -1)
          ),
        };
      })
      .filter(category => category.tagGroups.length);
  }
}
