import { animate, AnimationEvent, keyframes, state, style, transition, trigger } from '@angular/animations';
import { ComponentPortal, ComponentType, Portal, TemplatePortal } from '@angular/cdk/portal';
import { Component, HostBinding, Injector, OnDestroy, TemplateRef, ViewEncapsulation } from '@angular/core';

import { BACKDROP_CONFIG, BackdropConfig, PageBackdropRef } from './page-backdrop-ref';

/**
 * Default backdrop open and close animation.
 */
export const backdropOpenCloseAnimation = trigger('openClose', [
  state('open', style({
    height: '*',
    visibility: 'visible',
    opacity: 1,
  })),
  state('void, close', style({
    height: 0,
    visibility: 'hidden',
    opacity: 0,
  })),
  transition('* => void, * => close',
    animate(`75ms cubic-bezier(0.4, 0, 1, 1)`, keyframes([
      style({ visibility: 'hidden', opacity: 0, offset: 0 }),
      style({ height: 0, offset: 1 })
    ]))),
  transition('* => open',
    animate(`275ms cubic-bezier(0, 0, 0.2, 1)`, keyframes([
      style({ visibility: 'visible', offset: 0 }),
      style({ height: '*', offset: 0.9 }),
      style({ opacity: 1, offset: 1 })
    ])))
]);


/**
 * @description
 * Page backdrop container that will render component specified using `PageBackdropService`.
 * This component is instantiated by `PageComponent` and not meant to be used directly.
 */
@Component({
  selector: 'cog-page-backdrop',
  templateUrl: './page-backdrop.component.html',
  encapsulation: ViewEncapsulation.None,
  animations: [ backdropOpenCloseAnimation ]
})
export class PageBackdropComponent implements OnDestroy {

  /**
   * Add CSS selector for styling to host component.
   */
  @HostBinding('class.cog-page-backdrop') cssClass = true;

  /**
   * Portal where attached component or template will be rendered.
   */
  portal: Portal<any>;

  /**
   * Animation state for `@openClose` trigger.
   */
  currentState: 'open' | 'close' = 'close';

  constructor(private _injector: Injector) {}

  /**
   * Destroys component.
   */
  ngOnDestroy() {
    this.dispose();
  }

  /**
   * Attach the given ComponentPortal to this PortalOutlet using the ComponentFactoryResolver.
   *
   * @param    componentOrTemplateRef  Portal to be attached to the portal outlet.
   * @returns  Reference to the created component.
   */
  attach<T>(componentOrTemplateRef: ComponentType<T> | TemplateRef<T>,
    config: BackdropConfig = null): PageBackdropRef<T> {

    this.currentState = 'open';

    const backdropRef = new PageBackdropRef();
    backdropRef.afterClosed().subscribe(() => this.currentState = 'close');

    if (componentOrTemplateRef instanceof TemplateRef) {
      this.portal = new TemplatePortal<T>(componentOrTemplateRef, null, <any>{
        $implicit: config, backdropRef
      });
    } else {
      const injector = Injector.create({
        parent: this._injector,
        providers: [
          { provide: BACKDROP_CONFIG, useValue: config },
          { provide: PageBackdropRef, useValue: backdropRef },
        ],
      });

      this.portal = new ComponentPortal(componentOrTemplateRef, null, injector);
    }

    return backdropRef;
  }

  /**
   * Removes all renders components from backdrop.
   */
  dispose() {
    if (this.portal && this.portal.isAttached) {
      this.portal.detach();
      this.portal = null;
    }
  }

  /**
   * Handles `@openClose` animation complete event.
   */
  animationDone(event: AnimationEvent) {
    if (event.toState === 'close') {
      this.dispose();
    }
  }
}
