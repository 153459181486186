// Module: API Keys

;(function(angular) {
  'use strict';

  angular
    .module('C.apiKeys', [])
    .config(configFn);

  function configFn($stateProvider) {
    // API Key states are available to all users, even without any privileges.
    $stateProvider
      .state('access-management.api-keys', {
        title: 'API Keys',
        url: '^/admin/access/api-keys',
        fallbackState: 'access-management',
        canAccess: 'FEATURE_FLAGS.apiKeysEnabled && !$root.isTenantUser()',
        views: {
          'tab-view': {
            controller: 'apiKeyListCtrl as $ctrl',
            templateUrl: 'app/admin/access-management/api-keys/list.html',
          },
        },
      })
      .state('modify-api-key', {
        title: 'View API Key',
        url: '^/admin/access/api-keys/view/:userSid/:id',
        canAccess: 'FEATURE_FLAGS.apiKeysEnabled && !$root.isTenantUser()',
        controller: 'modifyApiKeyCtrl as $ctrl',
        templateUrl: 'app/admin/access-management/api-keys/modify.html',
        params: {
          id: '',
          userSid: '',
          apiKeyToken: '',
        },
      })
      .state('add-api-key', {
        title: 'Add API Key',
        url: '^/admin/access/api-keys/add',
        canAccess: 'FEATURE_FLAGS.apiKeysEnabled && !$root.isTenantUser()',
        controller: 'modifyApiKeyCtrl as $ctrl',
        templateUrl: 'app/admin/access-management/api-keys/modify.html',
      });
  }

})(angular);
