import { DecommissionCloudRigelStatusResult, ProvisionCloudRigelStatusResult, SaaSConnectorGroup } from '@cohesity/api/rms';
import { McmSourceInfo } from '@cohesity/api/v2';
import { NavItem } from '@cohesity/helix';
import { decorateInterface } from '@cohesity/utils';

import { DmsConnectionSource } from './dms-connection-source';
import type { DmsConnection } from './dms-connection';

/**
 * Data model for DMaaS connector group.
 */
export class DmsConnectorGroup extends decorateInterface<SaaSConnectorGroup>() {
  /** actions for connector group. */
  actions?: NavItem[];

  /** sources assigned to the connector group. */
  sources?: DmsConnectionSource[];

  /** True if any one of the connector is connected */
  isHealthy?: boolean;

  /** Source info (tagged source) from API call */
  sourceInfos?: McmSourceInfo[];

  /** Status of a rigel provision or decommission */
  cloudStatus?: ProvisionCloudRigelStatusResult | DecommissionCloudRigelStatusResult;

  deletionStatus?: string;

  constructor(
    connectorGroup: SaaSConnectorGroup,
    public groupId?: number,
    public parent?: DmsConnection,
  ) {
    super(connectorGroup);
    this.isHealthy = this.status === 'Connected';
  }
}
