import { NgModule } from '@angular/core';

import { HumanizeFromNumbersPipe } from './humanize-from-numbers.pipe';

const PIPES = [
  HumanizeFromNumbersPipe,
];

@NgModule({
  imports: [],
  providers: [...PIPES],
  exports: [...PIPES],
  declarations: [...PIPES],
})
export class HumanizeFromNumbersPipeModule {}
