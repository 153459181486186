import { Injectable } from '@angular/core';
import { IrisContextService } from '@cohesity/iris-core';
import { filter, map } from 'rxjs/operators';

import { GuardResult, StateGuard } from '../state-guard';
import { GuardPriority } from './../state-guard';

/**
 * Context guard ensures that the iris context value is set before any application
 * components are loaded.
 */
@Injectable({
  providedIn: 'root',
})
export class ContextGuard implements StateGuard {

  /**
   * This guard runs just before the app guards.
   */
  guardPriority = GuardPriority.IrisContext;

  constructor(
    private irisContext: IrisContextService,
  ) { }

  /**
   * This is run on the onStart ui router transition.
   */
  onStart(): GuardResult {
    return this.irisContext.irisContext$.pipe(
      filter(context => !!context),
      map(() => true)
    );
  }
}
