import { CommonModule } from '@angular/common';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { AngularMaterialModule, CohesityHelixModule } from '@cohesity/helix';
import {
  LocalizedEmailComponent,
  LocalizedEmailListComponent,
  SelectLocaleComponent
} from './index';

const COMPONENTS = [
  LocalizedEmailComponent,
  LocalizedEmailListComponent,
  SelectLocaleComponent,
];

@NgModule({
  imports: [
    AngularMaterialModule,
    CommonModule,
    MatSelectModule,
    ReactiveFormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
    CohesityHelixModule,
    TranslateModule.forChild(),
  ],
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS],
})

export class LocalizationModule { }
