import { Injectable } from '@angular/core';
import { AjsUpgradeService } from 'src/app/core/services/ajs-upgrade.service';
import { StateContext } from 'src/app/core/state/state-context';
import { ClusterConfig } from 'src/app/models';

@Injectable({
  providedIn: 'root'
})
export class AjsRemoteClusterService {
  /**
   *
   * Injected ajs services.
   */
  private ajsService: any;

  constructor(ajsUpgrade: AjsUpgradeService) {
    this.ajsService = ajsUpgrade.get('RemoteClusterService');
  }

  /**
   * Updates the cluster currently being accessed via single-pane-of-glass
   * functionality (altClusterSelector).
   *
   * @param  cluster  The cluster to switch to.
   * @param  suppressReload  Indicates if state reload should be suppressed.
   * @param  toState  Navigate the this safe state after changing the context.
   * @returns Promise resolved on successful context changes else
   * rejected with error.
   */
  public changeClusterContext(cluster: ClusterConfig, suppressReload = false, toState: StateContext): Promise<any> {
    return this.ajsService.changeClusterContext(cluster, suppressReload, toState);
  }

  /**
   * Returns a promise to resolve a request for a list of remote clusters registered for remote access.
   *
   * @returns Promise resolved with list of of remote access cluster else rejected with error.
   */
  public getRemoteAccessClusters(): Promise<ClusterConfig[]> {
    return this.ajsService.getRemoteAccessClusters();
  }
}
