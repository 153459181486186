import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CommonReportItemsModule } from './common-report-items/common-report-items.module';
import { CoreModule } from './core/core.module';
import { FiltersModule } from './filters';

@NgModule({
  imports: [CommonModule, CoreModule, CommonReportItemsModule],
  exports: [CoreModule, FiltersModule],
})
export class IrisReportingModule {}
