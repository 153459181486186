<cog-settings-list-item cogFormGroup
  [invalid]="control.invalid"
  [label]="'customOptions' | translate">
  <cog-settings-list-item-value-display>
    {{ control.value || ('none' | translate) }}
  </cog-settings-list-item-value-display>
  <cog-settings-list-item-value-editor>
    <mat-form-field>
      <input matInput
        [formControl]="control"
        [inheritDataId]="true"
        cogDataId="input-recovery-script-arguments"
        [readonly]="readonly"
        [placeholder]="'customOptions' | translate">
      <mat-error *ngIf="control.errors?.required">
        {{ 'errors.required' | translate }}
      </mat-error>
    </mat-form-field>
  </cog-settings-list-item-value-editor>
</cog-settings-list-item>
