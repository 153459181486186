import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

/**
 * Pipe to get the translated string for a tag type
 */
@Pipe({
  name: 'tagTypeLabel',
})
export class TagTypeLabelPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  /**
   * Transforms the given tag type with the provided configuration
   *
   * @param tagType tag type that need to be translated
   * @param full true, need full version appended with 'Tags'
   * @returns translated string
   */
  transform(tagType: string, full: boolean = false): string {
    return full
      ? this.translate.instant(`tags.types.${tagType}Tags`)
      : this.translate.instant(`tags.types.${tagType}`);
  }
}
