import { AppModuleConfig, StateAccessContext, StateAccessMap } from 'src/app/app-module.config';

const access = (ctx: StateAccessContext) => ctx.FEATURE_FLAGS.objectDetails;

export const ObjectDetailsConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'object-details.**',
      url: '/objects/details',
      loadChildren: () => import('./object-details.module').then(m => m.ObjectDetailsModule),
    },
    {
      name: 'object-run.**',
      url: '/objects/run',
      loadChildren: () => import('./object-details.module').then(m => m.ObjectDetailsModule),
    },
    {
      name: 'object-browse.**',
      url: '/objects/browse',
      loadChildren: () => import('./object-details.module').then(m => m.ObjectDetailsModule),
    },
  ],
  allClusterMap: {
    'object-details': true,
    'object-details.landing': true,
    'object-details.landing.summary': true,
    'object-details.landing.activity': true,
    'object-details.landing.details': true,
    'object-details.landing.runs': true,
    'object-run.landing.archive': true,
    'object-run.landing.backup': true,
    'object-run.landing.cloud-archive': true,
    'object-run.landing': true,
    'object-run': true,
    'object-browse': true,
    'object-browse.landing': true,
    'object-browse.landing.summary': true
  },
  getStateAccessMap(): StateAccessMap {
    return {
      'object-details': access,
      'object-details.landing': access,
      'object-details.landing.summary': access,
      'object-details.landing.activity':
        ctx => access(ctx) && ctx.FEATURE_FLAGS.objectDetailsActivity,
      'object-details.landing.details': access,
      'object-details.landing.runs': access,
      'object-run.landing.archive': access,
      'object-run.landing.backup': access,
      'object-run.landing.cloud-archive': access,
      'object-run.landing': access,
      'object-run': access,
      'object-browse': access,
      'object-browse.landing': access,
      'object-browse.landing.summary': access,
    };
  },
};
