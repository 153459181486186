<div [formGroup]="form.formGroup" cogDataId="custom-subnet-order">
  <cog-banner status="info" class="margin-bottom">
    <p class="cog-body" [innerHtml]="'gcpMultiSubnet.subnetInformation' | translate"></p>
  </cog-banner>

  <div formArrayName="customSubnets"
    *ngFor="let setting of customSubnetsFormArray.controls; let i = index"
    class="flex-row-md"
    [cogDataId]="i"
    [inheritDataId]="true">

    <ng-container [formGroupName]="i">
      <mat-form-field>
        <mat-label>{{ 'projectId' | translate }}</mat-label>
        <mat-select formControlName="projectId"
          cogDataId="projectId"
          [inheritDataId]="true">
          <mat-option *ngFor="let value of getProjectIdList()"
            [value]="value"
            [cogDataId]="value"
            [inheritDataId]="true">
            {{ value }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="setting.controls.projectId.errors?.required">
          {{ 'errors.required' | translate }}
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ 'vpc' | translate }}</mat-label>
        <mat-select formControlName="vpc"
          cogDataId="vpc"
          [inheritDataId]="true">
          <mat-option *ngFor="let value of getVpcList(i)"
            [value]="value"
            [cogDataId]="value"
            [inheritDataId]="true">
            {{ value }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="setting.controls.vpc.errors?.required">
          {{ 'errors.required' | translate }}
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ 'subnet' | translate }}</mat-label>
        <mat-select formControlName="subnet"
          cogDataId="subnet"
          [inheritDataId]="true">
          <mat-option *ngFor="let value of getSubnetList(i)"
            [value]="value"
            [cogDataId]="value"
            [inheritDataId]="true">
            {{ value }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="setting.controls.subnet.errors?.required">
          {{ 'errors.required' | translate }}
        </mat-error>
      </mat-form-field>
    </ng-container>

    <coh-form-array-action-control
      cogDataId="actions"
      [inheritDataId]="true"
      [formArrayLength]="customSubnetsFormArray.controls.length"
      [index]="i"
      [addActionDisabled]="addActionDisabled(i)"
      (controlAdded)="addSubnetConfig(i)"
      (controlRemoved)="removeSubnetConfig(i)">
    </coh-form-array-action-control>
  </div>

  <!-- Show the error if two or more subnets have the same configs -->
  <cog-banner status="critical"
    class="margin-bottom"
    *ngIf="form.formGroup.controls.customSubnets.errors?.unique">
    <p>{{ 'gcpMultiSubnet.error.uniqueSubnet' | translate }}</p>
  </cog-banner>
</div>