// Directive: Poptips (proxy for tooltips like uibTooltip)

// NOTE: it was recently discovered that this component can have some unexpected
// side-effects in some scenarios. The first instance was in the cSourceTreePub
// where this component caused multiple checkboxes to appear before each node
// when the User toggled auto-protect on and off multiple times for a protection
// job.

// WARNING: Use this component cautiously until confidence is higher that the
// above NOTE is resolved.

// TODO(spencer): RCA the above. It is likely caused by the recompilation step
// in the linkFn return.

;(function(angular, undefined) {
  'use strict';

  var dirName = 'poptip';

  /**
   * @ngdoc directive
   * @name poptips.directive:poptip
   * @restrict 'A'
   * @element ANY
   * @scope
   * priority: 0
   * @description
   *   Tooltip wrapper utilizing uibPopover to emulate uibTooltips (because it
   *   has better handling of variable width content). Leverages common config
   *   attributes from uibTooltips.
   *
   * @example
   *   <i class="icn {{::node | sourceIcon}}"
   *     poptip="{{::node | sourceType}}"
   *     poptip-enable="boolean"></i>
   */
  angular.module('ui.bootstrap')
    .directive('poptip', popTips);

  function popTips($log, $compile) {
    var proxyRoots = [dirName, 'tooltip'];

    return {
      restrict: 'A',
      scope: false,
      terminal: true,
      priority: 0,
      compile: popTipsCompile,
    };

    function popTipsCompile(elem, attrs) {
      // Add the uibPopover properties
      elem.attr({
        'uib-popover': attrs.poptip,
        'popover-enable': _getProxyAttributeValue('Enable'),
        'popover-trigger': _getProxyAttributeValue('Trigger') ||
          '\'mouseenter\'',
        'popover-placement': _getProxyAttributeValue('Placement') || 'auto',
        'popover-class': 'poptip',
      });

      // Remove these attributes from the DOM so they don't (re)compile in the
      // Link Fn.
      elem.removeAttr(dirName);

      // TODO: Remove this when all instances of uibTooltip have been removed
      // from the codebase, or we decide on guidelines for when to use which.
      if (attrs.uibTooltip) {
        elem.removeAttr('uibTooltip');
        $log.error(
          'You have used the directive "' + dirName +
          '" in conjunction with "uibTooltip". Please',
          'replace attribute "uib-tooltip" with "poptip" before proceeding.'
        );
        return;
      }

      /**
       * Utility for fetching the value of a predefined priority ordered list of
       * attributes by suffix.
       *
       * See `proxyRoots` for priority order.
       *
       * @example
       *   Given the input value 'Enable', this will search for the attribute
       *   `tooltipEnable` followed by `poptipEnable` on this Directive's
       *   element and return the first found value. This is used to handle
       *   values passed for uibTooltip while transitioning it out of use.
       *
       * @method   _getProxyAttributeValue
       * @param    {string}   attrSuffix   The attribute Suffix.
       * @return   {*}   The value found, or null if not found.
       */
      function _getProxyAttributeValue(attrSuffix) {
        return proxyRoots.reduce(function eachProxy(foundOut, attrRoot) {
          if (!foundOut) {
            return attrs[attrRoot + attrSuffix];
          }
          return foundOut;
        }, null);
      }

      return function linkFn(scope, elem, attr) {
        // Recompile this node (without itself)
        $compile(elem)(scope);
      };
    }
  }

})(angular);
