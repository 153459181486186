import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MultitaskTableComponent } from './multitask-table.component';
import { downgradeComponent } from '@angular/upgrade/static';
import { TranslateModule } from '@ngx-translate/core';
import { MultitaskControlsComponent } from './multitask-controls/multitask-controls.component';

// AJS Downgrade
declare const angular: any;
angular
  .module('C')
  .directive('cohMultitaskTable', downgradeComponent({
    component: MultitaskTableComponent
  }))
  .directive('cohMultitaskControls', downgradeComponent({
    component: MultitaskControlsComponent
  }));

@NgModule({
  imports: [
    TranslateModule.forChild(),
    CommonModule
  ],
  declarations: [
    MultitaskTableComponent,
    MultitaskControlsComponent
  ]
})
export class MultitaskTableModule { }
