import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DynamicComponentLoaderModule } from 'src/app/dynamic-component-loader';
import { SharedModule } from 'src/app/shared';

import { CompanyAccountTableComponent } from './company-account-table/company-account-table.component';
import { SwitchAccountDialogComponent } from './switch-account-dialog/switch-account-dialog.component';

const components = [
  CompanyAccountTableComponent,
  SwitchAccountDialogComponent,
];

/**
 * This is company account module.
 */
@NgModule({
  imports: [
    CommonModule,
    DynamicComponentLoaderModule.forChild(SwitchAccountDialogComponent, 'switch-account-dialog'),
    SharedModule,
  ],
  declarations: components
})
export class CompanyAccountModule {}
