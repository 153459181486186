import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AngularMaterialModule } from '../../angular-material.module';
import { LoadingMaskModule } from '../loading-mask/index';
import { SpinnerModule } from '../spinner/index';
import { DashcardExpandComponent } from './dashcard-expand/dashcard-expand.component';
import {
  DashcardLayoutStandardComponent,
} from './dashcard-layouts/dashcard-layout-standard/dashcard-layout-standard.component';
import {
  DashcardChartComponent,
  DashcardFooterComponent,
  DashcardHeaderInfoComponent,
  DashcardLegendComponent,
  DashcardTopComponent,
} from './dashcard-layouts/dashcard-layouts-common.component';
import { DashcardComponent } from './dashcard.component';

const COMPONENTS = [
  DashcardChartComponent,
  DashcardComponent,
  DashcardExpandComponent,
  DashcardFooterComponent,
  DashcardHeaderInfoComponent,
  DashcardLayoutStandardComponent,
  DashcardLegendComponent,
  DashcardTopComponent,
];

@NgModule({
  imports: [
    AngularMaterialModule,
    CommonModule,
    LoadingMaskModule,
    SpinnerModule,
  ],
  declarations: COMPONENTS,
  exports: COMPONENTS,
})
export class DashcardModule { }
