import { Injectable } from '@angular/core';
import { NavItem } from '@cohesity/helix';
import { IrisContextService, isDmsScope } from '@cohesity/iris-core';
import { StateService } from '@uirouter/core';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { StateManagementService } from 'src/app/core/services';
import { RestoreConfigService } from 'src/app/modules/restore/restore-shared';
import { RecoveryAction } from 'src/app/shared';

import { KubernetesObjectActionCreator } from './kubernetes-object-action-creator';
import { ObjectActionCreator } from './object-action-creator';
import { ObjectActionProvider } from './object-action-provider';
import { checkObjectsForBulkAction } from './object-action-utils';
import { ObjectInfoService } from './object-info.service';
import { SimpleObjectInfo } from './object-menu-provider';

@Injectable()
export class KubernetesObjectActionProvider extends ObjectActionProvider {
  /**
   * The provider for this service is manually set up in object-actions-menu.service, which must provide
   * the list of providers as an array in the correct order. In order to maintain some kind of sanity,
   * the providers are listed here, they should match the order of the constructor args.
   */
  static kubernetesObjectActionProviderDependencies = [
    ObjectInfoService,
    RestoreConfigService,
    StateManagementService,
    StateService,
    ObjectActionCreator,
    IrisContextService,
    KubernetesObjectActionCreator,
  ];

  exclusiveProtection = false;

  supportsBulkActions = true;

  constructor(
    readonly objectStore: ObjectInfoService,
    readonly restoreConfig: RestoreConfigService,
    readonly stateManagementService: StateManagementService,
    readonly stateService: StateService,
    readonly actionCreator: ObjectActionCreator,
    readonly irisContextService: IrisContextService,
    private kubernetesActionCreator: KubernetesObjectActionCreator,
  ) {
    super(objectStore, restoreConfig, stateManagementService, stateService, actionCreator, irisContextService);
  }

  /**
   * Creates and returns the kubernetes action for the specified object.
   *
   * @param objects The protected objects.
   * @returns An observable, which yields the NavItem or null.
   */
  getKubernetesRecoverAction(objects: SimpleObjectInfo[]): Observable<NavItem> {
    const type = RecoveryAction.RecoverNamespaces;

    if (!checkObjectsForBulkAction(objects, type)) {
      return of(null);
    }

    if (!this.objectStore.getProtectedObject) {
      return of(null);
    }

    return super.getProtectedObjectSearch(objects).pipe(
      map(infos => infos.filter(info => info && super.canRecover(type, info))),
      map(infos => (!infos.length ? null : this.kubernetesActionCreator.createBulkKubernetesRecoverAction(
        infos,
        null,
        { accessClusterId: objects[0].accessClusterId, regionId: objects[0].regionId }
      )))
    );
  }

  getObjectActions(object: SimpleObjectInfo): Observable<NavItem[]> {
    if (isDmsScope(this.irisContextService.irisContext)) {
      // DB specific actions are currently non NG and will not supported in DMaaS.
      return super.getObjectActions(object);
    }

    return combineLatest([
      super.getObjectActions(object),
      this.getKubernetesRecoverAction([object]),
    ]).pipe(
      map(([baseActions, ...kubernetesActions]) => [...baseActions, ...kubernetesActions]),
      map(actions => actions.filter(action => Boolean(action)))
    );
  }

  getBulkObjectActions(objects: SimpleObjectInfo[]): Observable<NavItem[]> {
    return combineLatest([
      super.getBulkObjectActions(objects)
    ]).pipe(
      map(([baseActions, ...kubernetesActions]) => [...baseActions, ...kubernetesActions]),
      map(actions => actions.filter(action => Boolean(action)))
    );
  }
}
