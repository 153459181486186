import { ElementRef, Injector, Renderer2, OnChanges, OnDestroy, SimpleChanges, Directive } from '@angular/core';
import { AnchorUISref, UIRouter, UISref, UIView } from '@uirouter/angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NavParams } from '../iris-router.models';

import { RouterLinkAdapter } from './router-link-adapter';
import { RouterLinkAnchorDirective } from './router-link.directive';

/**
 * Implements an adapter for iris router link adapter based on ui-router.
 */
@Directive()
export class UIRouterLinkAdapter implements RouterLinkAdapter, OnChanges, OnDestroy {
  /**
   * A reference to the ui router directive instance.
   */
  readonly uiSref: UISref = null;

  /**
   * This resolves to whatever ui-router resolves the link to.
   */
  targetState$: Observable<{ state: string; params: NavParams }>;

  constructor(injector: Injector) {
    const anchorLink = injector.get(RouterLinkAnchorDirective, null);
    this.uiSref = new UISref(
      injector.get(UIRouter),
      anchorLink ? new AnchorUISref(injector.get(ElementRef), injector.get(Renderer2)) : null,
      injector.get(UIView.PARENT_INJECT)
    );
    this.targetState$ = this.uiSref.targetState$.pipe(
      map(target => ({ state: target?.name(), params: target?.params() }))
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.link) {
      this.uiSref.state = changes.link.currentValue;
    }
    if (changes.params) {
      this.uiSref.params = changes.params.currentValue;
    }
    if (changes.options) {
      this.uiSref.options = changes.options.currentValue;
    }
    this.uiSref.ngOnChanges(changes);
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnDestroy() {
    // Do nothing
  }

  go(button: number, ctrlKey: boolean, metaKey: boolean): boolean {
    return this.uiSref.go(button, ctrlKey, metaKey);
  }
}
