import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ExtensionPointConfig } from './extension-point-config';
import { NGXLogger } from 'ngx-logger';

/**
 * This service is used to track registered extensions throughout the application.
 */
@Injectable({
  providedIn: 'root',
})
export class PluginRegistryService {
  constructor(private logger: NGXLogger) { }
  /**
   * Subject of all registered extension outlets.
   */
  private extensions = new BehaviorSubject<{
    [key: string]: ExtensionPointConfig[];
  }>({});

  /**
   * Adds an extension for the specified extension point.
   *
   * @param   extensionPoint   The name of the extension point.
   * @param   config           The config object that describes how the extension will be rendered.
   */
  addExtensionOutlet<E extends ExtensionPointConfig>(extensionPoint: string, config: E) {
    const currentExtensions = { ...this.extensions.value };

    if (!currentExtensions[extensionPoint]) {
      currentExtensions[extensionPoint] = [];
    }
    if (!currentExtensions[extensionPoint].find(entry => entry.id === config.id)) {
      currentExtensions[extensionPoint].push(config);
      this.extensions.next({
        ...currentExtensions,
      });
    } else {
      this.logger.debug(`Ignore ading extensionPoint ${extensionPoint}, it is already registered`);
    }
  }

  /**
   * Gets the list of extension registered for a given extension point
   *
   * @param   extensionPoint   The name of the extension point.
   * @param   type             The type of extension point to look up.
   * @returns The list of available extensions.
   */
  getRegisteredExtensions(extensionPoint: string, type?: string): Observable<ExtensionPointConfig[]> {
    return this.extensions.pipe(
      map(extensions => extensions[extensionPoint] || []),
      map(extension => extension.filter(entry => !type || entry.type === type))
    );
  }

  /**
   * Removes an extension for the specified extension point.
   *
   * @param   extensionPoint   The name of the extension point.
   * @param   id               The id of the extension point to remove.
   */
  removeExtensionOutlet(extensionPoint: string, id: string) {
    const currentExtensions = { ...this.extensions.value };

    if (!currentExtensions[extensionPoint]) {
      return;
    }
    const index = currentExtensions[extensionPoint].findIndex(entry => entry.id === id);
    if (index !== -1) {
      currentExtensions[extensionPoint].splice(index, 1);
      this.extensions.next({
        ...currentExtensions,
      });
    } else {
      this.logger.debug(`Ignore removing extensionPoint ${extensionPoint}, it is not registered`);
    }
  }
}
