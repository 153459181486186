import { Directive, Injector, ElementRef } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

@Directive({
  selector: 'ajs-global-search-input',
})
export class GlobalSearchInputComponent extends UpgradeComponent {
  constructor(injector: Injector, elementRef: ElementRef) {
    super('globalSearchInput', elementRef, injector);
  }
}
