import { ChangeDetectionStrategy, Component } from '@angular/core';

/**
 * This can be added to a mat select list to simplify the ui for launching an
 * a dialog to add content to a list.
 *
 * @example
 * <mat-select>
 *    <mat-option *ngFor"....">...</mat-option>
 *    <mat-option>
 *      <coh-add-item-option>Add Storage Domain</coh-add-item-option>
 *    </mat-option>
 * </mat-select>
 */
@Component({
  selector: 'coh-add-item-option',
  templateUrl: './add-item-option.component.html',
  styleUrls: ['./add-item-option.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddItemOptionComponent {
}
