import { Component, Input, OnChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Vlan, VlanServiceApi } from '@cohesity/api/v1';
import { AutoDestroyable } from '@cohesity/utils';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AjsUpgradeService, PassthroughOptionsService } from 'src/app/core/services';

@Component({
  selector: 'coh-cluster-interface-selector',
  templateUrl: './cluster-interface-selector.component.html',
})
export class ClusterInterfaceSelectorComponent
  extends AutoDestroyable implements OnChanges {
  /**
   * Form control for cluster interface selector.
   */
  @Input() control: UntypedFormControl;

  /**
   * Specifies if the form control is required or not.
   */
  @Input() required = true;

  /**
   * Specifies the label for cluster interface selector.
   */
  @Input() label: string;

  /**
   * An observable stream of datastores.
   */
  @Input() vlans$ = new BehaviorSubject<Vlan[]>([]);

  /**
   * Specifies if the we have to auto populate vlan list or not.
   */
  @Input() populateVlans = true;

  /**
   * Specified if none option has to be shown in the  mat-select dropdown list.
   */
  @Input() showNoneOption = true;

  /**
   * Whether an API call is in progress.
   */
  loading = false;

  /**
   * AJS VLAN Service.
   */
  vlanService: any;

  /**
   * Initial value for the control.
   */
  initialValue: any;

  constructor(
    private vlanServiceApi: VlanServiceApi,
    ajsUpgrade: AjsUpgradeService,
    private passthroughOptionsService: PassthroughOptionsService
  ) {
    super();

    this.vlanService = ajsUpgrade.get('VlanService');
  }

  /**
   * Component on change.
   */
  ngOnChanges() {
    this.initialValue = this.initialValue || this.control.value;

    if (this.vlans$.value?.length || !this.populateVlans) {
      this.populateDefaultValue();
      return;
    }

    this.loading = true;
    const transformVlan = this.vlanService.transformVlan.bind(this);

    // allUnderHierarchy is set to true always to access every entity which
    // are a part of SP admin or the SP admin's tenants.

    const params = {
      allUnderHierarchy: false,
      accessClusterId: this.passthroughOptionsService.accessClusterId
    };

    this.vlanServiceApi.GetVlans(params).pipe(
      this.untilDestroy(),
      finalize(() => this.loading = false)
    ).subscribe((values: Vlan[]) => {
      this.control.enable();

      // Transform all vlan values using ajs transformVlan function to augment
      // with CIDR display value.
      this.vlans$.next(values.map(transformVlan));
      this.populateDefaultValue();
    });
  }

  /**
   * Method called to populated vlan values by default.
   */
  populateDefaultValue() {
    if (this.initialValue) {
      // Select a value by values if an initial value is provided
      const foundItem = this.vlans$.value.find(item => (item.id === this.initialValue.id) &&
        (this.initialValue.ifaceGroupName === item.ifaceGroupName));

      if (foundItem) {
        this.control.setValue(foundItem);
      }
    }
  }
}
