// Controller: Storage by View Boxes

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.reports')
    .controller('reportsStorageViewboxesController', reportsStorageViewboxesControllerFn);

  function reportsStorageViewboxesControllerFn($scope, $filter, ReportsService,
    evalAJAX, cUtils, CHART_COLORS, cReportsControlsUtil, ReportsUtil) {

    $scope.storageViewBoxesConfig =
      ReportsUtil.getReportConfig('kStorageConsumedByViewBoxReport')();

    $scope.viewboxes = [];
    $scope.plottedVBCountPhysical = ' ';
    $scope.plottedVBCountLogical = ' ';

    $scope.getData = getData;
    $scope.downloadReport = downloadReport;

    /**
     * actiavet function
     */
    function activate() {
      var defaultFilters = cReportsControlsUtil.getDefaultFilters({
        lastNDays: 7,
      });

      getData(defaultFilters);
    }

    /**
     * builds a params object based on current filter settings
     *
     * @param      {object}    filters    filters from c-reports-controls
     *
     * @return {Object} params object for API submission
     */
    function getParams(filters) {
      return {
        msecsBeforeEndTime: (filters.lastNDays * 86400000)
      };
    }

    /**
     * requests a csv download via ReportsService
     *
     * @return {Void}
     */
    function downloadReport() {
      var params = getParams(cReportsControlsUtil.filters);
      params.outputFormat = 'csv';

      ReportsService.getStorageByViewbox(params);
    }

    /**
     * gets and parses the report data from the API
     *
     * @param      {object}    filters    filters from c-reports-controls
     *
     * @return {Void}
     */
    function getData(filters) {

      $scope.dataReady = false;
      $scope.vbByPhysicalChart.loading = true;
      $scope.vbByLogicalChart.loading = true;
      $scope.nDaysAgo = filters.lastNDays;

      ReportsService.getStorageByViewbox(getParams(filters)).then(
        function getStorageByViewboxSuccess(response) {
          $scope.viewboxes = response.data.map(
            function mapViewboxes(viewbox, index) {

              if (viewbox.avgGrowthPhysicalPct !== undefined &&
                viewbox.avgGrowthPhysicalPct !== null) {
                viewbox._physicalGrowth =
                  cUtils.round(viewbox.avgGrowthPhysicalPct, 2) + '%';
              }

              if (viewbox.avgGrowthLogicalPct !== undefined &&
                viewbox.avgGrowthLogicalPct !== null) {
                viewbox._logicalGrowth =
                  cUtils.round(viewbox.avgGrowthLogicalPct, 2) + '%';
              }

              if (viewbox.physicalDataTSDataPts &&
                viewbox.physicalDataTSDataPts.length &&
                viewbox.physicalDataTSDataPts.length === $scope.nDaysAgo + 1) {
                viewbox._physicalSizeBytesNDaysAgo =
                  viewbox.physicalDataTSDataPts[0].data.int64Value;
              }

              return viewbox;
          });

          parseDataForCharts($scope.viewboxes);
        },
        evalAJAX.errorMessage
      ).finally(
        function getStorageByViewboxFinally() {
          $scope.vbByPhysicalChart.loading = false;
          $scope.vbByLogicalChart.loading = false;
          $scope.dataReady = true;
        }
      );
    }


    /**
     * generates chart data based on View Boxes provided from API
     * and transformed in getData()
     *
     * @param  {Array} vbs returned based on filter params
     * @return {Void}
     */
     function parseDataForCharts(vbs) {
      var i = 0;
      var numViewBoxes = vbs.length || 0;
      var maxChartObjects = 10;
      var physicalDataArray = [];
      var logicalDataArray = [];

      $scope.dataReady = false;
      $scope.vbByPhysicalChart.series[0].data = [];
      $scope.vbByLogicalChart.series[0].data = [];

      if (numViewBoxes) {
        for (i; i < numViewBoxes; i++) {
          var physicalWritten = (vbs[i].physicalSizeBytes ? vbs[i].physicalSizeBytes : null);
          var logicalWritten = (vbs[i].logicalSizeBytes ? vbs[i].logicalSizeBytes : null);

          if (physicalWritten !== null) {
            physicalDataArray.push({
              name: vbs[i].viewBoxName,
              value: physicalWritten
            });
          }

          if (logicalWritten !== null) {
            logicalDataArray.push({
              name: vbs[i].viewBoxName,
              value: logicalWritten
            });
          }
        }

        // sort data arrays in descending order and limit to 10
        physicalDataArray = $filter('orderBy')(physicalDataArray, '-value');
        logicalDataArray = $filter('orderBy')(logicalDataArray, '-value');
        physicalDataArray = $filter('limitTo')(physicalDataArray, maxChartObjects);
        logicalDataArray = $filter('limitTo')(logicalDataArray, maxChartObjects);

        // transform our data for use with highcharts
        physicalDataArray = physicalDataArray.map(function(viewBox) {
          return [viewBox.name, viewBox.value];
        });
        logicalDataArray = logicalDataArray.map(function(viewBox) {
          return [viewBox.name, viewBox.value];
        });

        $scope.plottedVBCountPhysical = physicalDataArray.length;
        $scope.plottedVBCountLogical = logicalDataArray.length;

        // Add data to the charts
        $scope.vbByPhysicalChart.series[0].data = physicalDataArray;
        $scope.vbByLogicalChart.series[0].data = logicalDataArray;
      }
    }

    $scope.vbByPhysicalChart = {
      chartType: 'storageDonut',
      loading: true,
      colors: CHART_COLORS.tenColorPalette(),
      series: [{
        type: 'pie',
        name: 'vbs',
        data: []
      }],
      chart: {
        height: 225
      },
      title: {
        text: null
      },
      legend: {
        y: 0,
        itemMarginBottom: 8
      }
    };

    $scope.vbByLogicalChart = {
      chartType: 'storageDonut',
      loading: true,
      colors: CHART_COLORS.tenColorPalette(),
      series: [{
        type: 'pie',
        name: 'vbs',
        data: []
      }],
      title: {
        text: null
      },
      legend: {
        y: 0,
        itemMarginBottom: 8
      }
    };

    activate();
  }
})(angular);
