<ng-container *ngIf="filteredNavItems.length">
  <coh-nav-item-button
    [buttonClassName]="filteredNavItems.length > 1 ? 'primary-split-action' : ''"
    [navItem]="filteredNavItems[0]"
    navButtonType="flat">
  </coh-nav-item-button>

  <button *ngIf="filteredNavItems.length > 1"
    [color]="color"
    [inheritDataId]="true"
    [matMenuTriggerFor]="moreOptions"
    [matTooltip]="'moreOptions' | translate"
    class="secondary-split-action"
    cogDataId="secondary"
    mat-flat-button
    type="button">
    <mat-icon>arrow_drop_down</mat-icon>
  </button>
</ng-container>

<mat-menu #moreOptions="matMenu" xPosition="before">
  <coh-nav-item-button
    *ngFor="let filteredNavItem of (filteredNavItems | slice: 1)"
    [buttonClassName]="filteredNavItems.length > 1 ? 'primary-split-action' : ''"
    [navItem]="filteredNavItem"
    navButtonType="menu">
  </coh-nav-item-button>
</mat-menu>
