// COMPONENT: Name or description of this Component

;(function(angular, undefined) {
  'use strict';

  var moduleName = 'C.fileRecovery';
  var moduleDeps;

  angular
    .module(moduleName, moduleDeps)
    .controller('recoverFilesCartController', recoverFilesCartControllerFn);


  function recoverFilesCartControllerFn($rootScope, $scope, SourceService,
    RestoreService, $uibModalInstance, taskCart, NgTenantService,
    ExternalTargetService) {
    angular.extend($scope, {
      // GENERAL SCOPE VARS
      taskCart: taskCart,

      // TEXT STRINGS
      text: $rootScope.text.protectionRecoveryFilesFilesCartModalText,

      // SCOPE METHODS
      triggerAction: triggerAction,
      removeFromCart: removeFromCart,
      showDownloadLink: showDownloadLink,
      isDownloadDisabled: isDownloadDisabled,
      isFileRecoveryDisabled: isFileRecoveryDisabled,
      isFolderPresentInCart: isFolderPresentInCart,
      getTargetTooltip: ExternalTargetService.getTargetName,
      getTargetType: ExternalTargetService.getTargetType,
    });


    // METHODS

    /**
     * Initialize this controller
     *
     * @method     recoverFilesCartInit
     */
    function recoverFilesCartInit() {}

    /**
     * Determines whether File Recovery is Disabled
     *
     * @return    {Boolean}    true if File Recovery should be disabled
     */
    function isFileRecoveryDisabled() {
      var recoveryStateObj =
        RestoreService.getFileRecoveryDisabledState($scope.taskCart[0]);

      $scope.disableRecoveryMessage = recoveryStateObj.message;

      return recoveryStateObj.isDisabled;
    }

    /**
     * Determines if the 'Download Now' option should be disabled.
     * If the option is disabled, also update the scope with appropriate
     * tooltip message to be displayed.
     *
     * @method    isDownloadDisabled
     * @returns   {boolean}     True if the option should be disabled.
     *                          False otherwise
     */
    function isDownloadDisabled() {
      var cart = $scope.taskCart;
      var isSymlinkItemInCart = !!_.find(cart, ['_type', 'symlink']);

      switch (true) {
        // If SP is impersonating as a tenant
        case !!NgTenantService.impersonatedTenant:
          $scope.disableDownloadMessage =
            'recovery.disableMessage.onlyTenantDownloadSupported';
          return true;

        // empty cart
        case !cart.length:
          $scope.disableDownloadMessage = 'recovery.disableMessage.emptyCart';
          return true;

        // cart has a symlink
        case isSymlinkItemInCart:
          $scope.disableDownloadMessage =
            'recovery.disableMessage.symlinkDownloadUnsupported';
          return true;
      }

      $scope.disableDownloadMessage = '';
      return false;
    }

    /**
     * Determines if the 'Download Now' option should be shown.
     *
     * @method    showDownloadLink
     * @returns   {boolean}     True if the option should be shown.
     *                          False otherwise
     */
    function showDownloadLink() {
      return $rootScope.FEATURE_FLAGS.downloadFilesAndFoldersEnabled &&
        // User should have RESTORE_DOWNLOAD privilege
        ($scope.user.privs.RESTORE_DOWNLOAD ||
          // Or show when SP is impersonating as tenant,
          // but this case should be disabled
          NgTenantService.impersonatedTenant);
    }

    /**
     * check if a folder is present in cart
     *
     * @method isFolderPresentInCart
     * @return {Boolean} True if cart has a folder. False otherwise.
     */
    function isFolderPresentInCart() {
      return _.findIndex($scope.taskCart, ['isDirectory', true]) > -1;
    }

    /**
     * Remove files from the cart.
     *
     * @method   removeFromCart
     * @param    {Object}   entity   File entity
     * @return   {Array}    TaskCart in it's updated state
     */
    function removeFromCart(entity) {
      taskCart.some(function itemInCartLocatorFn(cartEntity, ii) {
        // Compare filename & entityId
        var match =
          (entity.fileDocument.filename ===
            cartEntity.fileDocument.filename) &&
          SourceService.isSameEntity(
            entity.fileDocument.objectId.entity,
            cartEntity.fileDocument.objectId.entity);
        if (match) {
          entity._snapshotIndex = '0';
          entity._snapshot = entity._versions[0];
          entity._selected = undefined;
          taskCart.splice(ii, 1);
        }
        return match;
      });

      return taskCart;
    }

    /**
     * Trigger the action as per the button selected on the modal. Sends the
     * action of the button clicked and closes the modal.
     *
     * @method   triggerAction
     * @param    {String}     action   Available actions for snapshot are
     *                                 ['next', 'downloadNow', 'close']
     * @return   {object}   $Q Promise with the intended action
     */
    function triggerAction(action) {
      return $uibModalInstance.close(action);
    }

    // Initialize!
    recoverFilesCartInit();
  }

})(angular);
