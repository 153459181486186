import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { isEqual } from 'lodash';
import moment from 'moment';
import { AjsUpgradeService } from 'src/app/core/services/ajs-upgrade.service';

import { DayVal, WeekdayList, WeekendList } from '../constants';


/**
 * @description
 * Assemble a human friendly display string from the provided the array of days.
 *
 * @example
 *   {{window.days | humanizeFromDays | translate}}
 *   // Output ['Sunday', 'Saturday']: "Weekends"
 *   // Output ['Tuesday']: "Tuesday"
 *   // Output ['Monday', 'Wednesday', 'Friday']: "Mon, Wed, Fri"
 */
@Pipe({
  name: 'humanizeFromDays'
})
export class HumanizeFromDaysPipe implements PipeTransform {
  /**
   * Injected Ajs service.
   */
  dateTimeService: any;

  constructor(
    private translate: TranslateService,
    ajsUpgradeService: AjsUpgradeService,
  ) {
    this.dateTimeService = ajsUpgradeService.get('DateTimeService');
  }

  transform(days: string[] = []): string {
    let dayString = '';

    days = days.sort();

    switch (true) {
      case days.length === 1:
        // A single day of the week localized by momentjs.
        dayString = moment(days[0], 'dddd')
          .format(this.dateTimeService.getLongDayOfWeekFormat());
        break;

      case isEqual(days, WeekendList):
        // Every weekend day
        dayString = this.translate.instant('weekends');
        break;

      case isEqual(days, WeekdayList):
        // Every weekday
        dayString = this.translate.instant('weekdays');
        break;

      default:
        days = this.sortDays(days);

        // CSV list of abbreviated days localized by momentjs.
        dayString = days.map((day: string) => moment(day, 'dddd')
          .format(this.dateTimeService.getShortDayOfWeekFormat())).join(', ');
    }

    return dayString;
  }

  /**
   * Sort days according to calendar order.
   *
   * @param   daysList         Array of kValue days.
   * @return  calendar ordered array of kValue days.
   */
  sortDays(daysList: string[]) {
    const weekdayOrder = Object.entries(DayVal).map(([, value]) => value);
    return daysList.sort((a, b) => weekdayOrder.indexOf(a) - weekdayOrder.indexOf(b));
  }
}
