// COMPONENT: SQL Management: Unregister SQL Server

// TODO this: After review and LG:
// - Reindent to 2 spaces
// - Clean up @jsdocs, time permitting

;(function(angular, undefined) {
    'use strict';

    var moduleName = 'C.sql-manager';

    angular
        .module(moduleName)
        .controller('unregisterSQLHostController', unregisterSQLHostControllerFn);

    function unregisterSQLHostControllerFn(StateManagementService, $scope,
      $interpolate, SourceService, $state, $stateParams, evalAJAX, cMessage) {

        angular.extend($scope, {
            // GENERAL SCOPE VARS
            data: {
                type: 3,
                // ownerEntity: {},
            },

            // SCOPE METHODS
            cancel: goBack,
            isLoading: isLoading,
            unregisterSQLHost: unregisterSQLHost,
        });


        // METHODS

        /**
         * Initialize all the things!
         *
         * @method     unregisterSQLHostInit
         */
        function unregisterSQLHostInit() {
            getEntityInfo($stateParams.entityId);
        }

        /**
         * Submit function for the short form to kick of the unregistration
         * operations on the server
         *
         * @method     unregisterSQLHost
         */
        function unregisterSQLHost() {
            $scope.unregistering = true;
            SourceService
                .unregisterAppOwner($scope.data)
                .then(function unregisterSuccessFn(resp) {
                    // This server response is a simple boolean.
                    if (resp) {
                        goBack();
                        showSuccess();
                    } else {
                        // In the off chance the request is successful, but
                        // returns false, show an error cMessage
                        unregisterError(resp);
                    }
                }, evalAJAX.errorMessage)
                .finally(function unregisterFinallyFn(resp) {
                    $scope.unregistering = false;
                });
        }

        /**
         * Show a success cMessage
         *
         * @method     showSuccess
         */
        function showSuccess() {
            cMessage.success({
                textKey: 'unregisterSql.success',
                textKeyContext: data.ownerEntity,
            });
        }

        /**
         * General handler of errors, used separately from
         * evalAJAX.errorMessage. Currently only displays an error cMessage.
         *
         * @method     unregisterError
         */
        function unregisterError() {
            cMessage.error({
              textKey: 'unregisterSql.error',
              textKeyContext: data.ownerEntity,
              persist: true,
            });
        }

        /**
         * Convenience to check a couple scope params that are un/set when a
         * server action is active
         *
         * @method     isLoading
         * @return     {boolean}  True if a server action is active, False if
         *                        not
         */
        function isLoading() {
            return $scope.loadingEntity || $scope.unregistering;
        }

        /**
         * Go back to the previous view
         *
         * @method     goBack
         */
        function goBack() {
          StateManagementService.goToPreviousState('sources-new');
        }

        /**
         * Get the info for the passed in VM Entity
         *
         * @method   getEntityInfo
         * @param    {number}   id   The Entity Id.
         * @return   {object}   Promise to resolve with the requested host.
         */
        function getEntityInfo(id) {
            $scope.loadingEntity = true;
            return SourceService.getEntitiesById(id)
                .then(function entityReceived(resp) {
                    $scope.data.ownerEntity = resp[0];
                    return resp;
                }, evalAJAX.errorMessage)
                .finally(function entityFinally(resp) {
                    $scope.loadingEntity = false;
                });
        }


        // Initialize!
        unregisterSQLHostInit();
    }

})(angular);
