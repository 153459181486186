// CONTROLLER: Reports: Storage by Jobs

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.reports')
    .controller('reportsStorageController', reportsStorageControllerFn);

  function reportsStorageControllerFn(
    $rootScope, $scope, $state, $stateParams, $log, ReportsService,
    evalAJAX, cUtils, DateTimeService, cReportsControlsUtil, FEATURE_FLAGS) {

    $scope.DateTimeService = DateTimeService;
    $scope.cUtils = cUtils;

    $scope.text = $rootScope.text.reportsStorageStorage;

    $scope.storageReportControlsConfig = {
      showReportsSelector: true,
    };

    // Show date-range filters if feature flag is enabled.
    if (FEATURE_FLAGS.extendedClusterWideStorageReport) {
      _.assign($scope.storageReportControlsConfig, {
        showHeader: true,
        showDateRangeFilter: true,
        showApplyFiltersBtn: true,
      });
    }

    $scope.dataReady = false;
    $scope.cluster = {};
    $scope.physicalUsageBytesString = $rootScope.text.naNotAvailable;
    $scope.storageEfficiencyString = $rootScope.text.naNotAvailable;
    $scope.getData = getData;

    /**
     * activate controller
     */
    function activate() {
      getData(cReportsControlsUtil.getDefaultFilters());
    }

    /**
     * get report data
     */
    function getData(filters) {
      var params = {
        startTimeMsecs: filters.timeObject.from.valueOf(),
        endTimeMsecs: filters.timeObject.until.valueOf(),
      };

      // The number of days between the start time and end time.
      $scope.nDays = moment(filters.timeObject.until)
        .diff(filters.timeObject.from, 'days');

      $scope.dataReady = false;
      $scope.sevenDayStorageChart.loading = true;

      ReportsService.getClusterStorage(params).then(function(r) {
        if (evalAJAX.success(r)) {
          $scope.cluster = r.data;

          // Build xAxis Categories from todays date, going back 7 days.
          var xCatArray = [];
          var chartDateFormat = DateTimeService.getShortMonthDayFormat();
          var i = 0;
          var xLabel;

          for (i; i < $scope.nDays; i++) {
            // 86400000 milliseconds = 1 day
            xLabel = params.endTimeMsecs - (86400000 * i);
            xCatArray.unshift(DateTimeService.formatDate(xLabel, chartDateFormat));
          }

          // Build Storage Data Arrays for Chart
          if (r.data.physicalUsageBytes && r.data.physicalUsageBytes.length) {
            var physicalUsageArray = [];
            var n = 0;
            var length = r.data.physicalUsageBytes.length;

            // Add the value to each array
            for (n; n < length; n++) {
              physicalUsageArray.push(r.data.physicalUsageBytes[n]);
            }

            // Convert Values to Human Readable Values
            var maxPhysicalValue;
            var tpUnit;

            maxPhysicalValue = Math.max.apply(Math, physicalUsageArray);
            tpUnit = cUtils.bytesToSize(maxPhysicalValue).unit;

            // Convert values to tpUnit
            for (var physX = 0, physLen = physicalUsageArray.length; physX < physLen; physX++) {
              physicalUsageArray[physX] = cUtils.round(cUtils.bytesToUnit(physicalUsageArray[physX], tpUnit) * 100) / 100;
            }

            $scope.physicalUsageBytesString = cUtils.bytesToSize($scope.getLastValue($scope.cluster.physicalUsageBytes)).string;
            $scope.storageEfficiencyString = cUtils.round($scope.getLastValue($scope.cluster.dataReductionRatio), 2) + 'x';

            // Update Chart with Data
            $scope.sevenDayStorageChart.series[0].data = physicalUsageArray;
            $scope.sevenDayStorageChart.xAxis.categories = xCatArray;
            $scope.sevenDayStorageChart.yAxis.labels.format = '{value} ' + tpUnit;
            $scope.sevenDayStorageChart.tooltip.valueSuffix = ' ' + tpUnit;
          } else {
            $scope.sevenDayStorageChart.series[0].data = [];
          }
        }

        $scope.dataReady = true;
        $scope.sevenDayStorageChart.loading = false;
      });
    }

    $scope.getLastValue = function getLastValue(array) {
      if (array && array.length) {
        array.reverse();
        return array[0];
      } else {
        return null;
      }
    };

    // 7 Day Storage Growth Chart
    $scope.sevenDayStorageChart = {
      chartType: 'line',
      loading: true,
      series: [{
        name: 'Physical Use',
        data: []
      }],
      legend: {
        layout: 'horizontal',
        verticalAlign: 'top',
        align: 'left',
        borderWidth: 0
      },
      chart: {
        height: 300,
        marginTop: 50,
        marginLeft: 1
      },
      xAxis: {
        type: 'category',
        categories: []
      },
      yAxis: {
        title: {
            text: null
        },
        labels: {
            // to be updated based on calculations
            format: ''
        },
        min: 0
      },
      tooltip: {
        valueSuffix: ''
      }
    };

    activate();
  }
})(angular);
