/**
 * Agent's minimum version for point in time restores.
 */
export const AGENT_MIN_VERSION_PIT_RESTORE = 6;

/**
 * Specifies the type of cluster resource configured on the given Cohesity box.
 * This is in sync with message Resource defined wihtin main/magneto/agents/base/agent.proto.
 */
export enum ClusterNetworkingResourceType {
  /**
   * Specifies a standalone server.
   */
  kServer = 'kServer',

  /**
   * Specifies a Windows Cluster.
   */
  kCluster = 'kCluster',

  /**
   * Specifies a clustered Microsoft SQL Server.
   */
  kSQL = 'kSQL',

  /**
   * Specifies an Oracle RAC(Real Application Cluster).
   */
  kOracleRAC = 'kOracleRAC',

  /**
   * Specifies a Microsoft Active Directory (AD) Domain cotroller.
   */
  kAD = 'kAD',

  /**
   * Specifies an Oracle Active-Passive Cluster.
   */
  kOracleAP = 'kOracleAP',

  /**
   * Specifies a Microsoft Exchange on-premise server (DAG or Standalone).
   */
  kExchange = 'kExchange',
}
