/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityApiConfiguration as __Configuration } from '../cohesity-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { BandwidthLimit } from '../models/bandwidth-limit';
import { ClusterPublicKeys } from '../models/cluster-public-keys';
import { CreateClusterResult } from '../models/create-cluster-result';
import { CreateCloudClusterParameters } from '../models/create-cloud-cluster-parameters';
import { ExpandCloudClusterParameters } from '../models/expand-cloud-cluster-parameters';
import { ClusterCreationProgressResult } from '../models/cluster-creation-progress-result';
import { IoPreferentialTier } from '../models/io-preferential-tier';
import { CreatePhysicalClusterParameters } from '../models/create-physical-cluster-parameters';
import { ExpandPhysicalClusterParameters } from '../models/expand-physical-cluster-parameters';
import { ServiceStateResult } from '../models/service-state-result';
import { ChangeServiceStateResult } from '../models/change-service-state-result';
import { ChangeServiceStateParameters } from '../models/change-service-state-parameters';
import { UpgradeClusterResult } from '../models/upgrade-cluster-result';
import { UpgradeClusterParameters } from '../models/upgrade-cluster-parameters';
import { CreateVirtualClusterParameters } from '../models/create-virtual-cluster-parameters';
import { ExternalClientSubnets } from '../models/external-client-subnets';
@Injectable({
  providedIn: 'root',
})
class ClustersService extends __BaseService {
  static readonly GetBackgroundActivitySchedulePath = '/public/cluster/backgroundActivitySchedule';
  static readonly UpdateBackgroundActivitySchedulePath = '/public/cluster/backgroundActivitySchedule';
  static readonly GetClusterKeysPath = '/public/cluster/keys';
  static readonly DestroyClusterPath = '/public/clusters';
  static readonly CreateCloudClusterPath = '/public/clusters/cloudEdition';
  static readonly ExpandCloudClusterPath = '/public/clusters/cloudEdition/nodes';
  static readonly GetClusterCreationProgressPath = '/public/clusters/creationProgress';
  static readonly GetIoPreferentialTierPath = '/public/clusters/ioPreferentialTier';
  static readonly PutIoPreferentialTierPath = '/public/clusters/ioPreferentialTier';
  static readonly RemoveNodePath = '/public/clusters/nodes/{id}';
  static readonly CreatePhysicalClusterPath = '/public/clusters/physicalEdition';
  static readonly ExpandPhysicalClusterPath = '/public/clusters/physicalEdition/nodes';
  static readonly ListServiceStatesPath = '/public/clusters/services/states';
  static readonly ChangeServiceStatePath = '/public/clusters/services/states';
  static readonly UpgradeClusterPath = '/public/clusters/software';
  static readonly CreateVirtualClusterPath = '/public/clusters/virtualEdition';
  static readonly GetExternalClientSubnetsPath = '/public/externalClientSubnets';
  static readonly UpdateExternalClientSubnetsPath = '/public/externalClientSubnets';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get the Apollo Throttling schedule.
   *
   * Sends a request to get the apollo throttling settings applied for the cluster.
   * @param regionId This field represents the region id.
   * @param accessClusterId This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetBackgroundActivityScheduleResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<BandwidthLimit>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/cluster/backgroundActivitySchedule`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BandwidthLimit>;
      })
    );
  }
  /**
   * Get the Apollo Throttling schedule.
   *
   * Sends a request to get the apollo throttling settings applied for the cluster.
   * @param regionId This field represents the region id.
   * @param accessClusterId This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetBackgroundActivitySchedule(regionId?: string,
    accessClusterId?: number): __Observable<BandwidthLimit> {
    return this.GetBackgroundActivityScheduleResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as BandwidthLimit)
    );
  }

  /**
   * Update the Apollo Throttling schedule.
   *
   * Sends a request to update the apollo throttling settings for the cluster.
   * Returns the updated apollo throttling settings of the cluster.
   * @param regionId This field represents the region id.
   * @param accessClusterId This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  UpdateBackgroundActivityScheduleResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<BandwidthLimit>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/public/cluster/backgroundActivitySchedule`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BandwidthLimit>;
      })
    );
  }
  /**
   * Update the Apollo Throttling schedule.
   *
   * Sends a request to update the apollo throttling settings for the cluster.
   * Returns the updated apollo throttling settings of the cluster.
   * @param regionId This field represents the region id.
   * @param accessClusterId This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  UpdateBackgroundActivitySchedule(regionId?: string,
    accessClusterId?: number): __Observable<BandwidthLimit> {
    return this.UpdateBackgroundActivityScheduleResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as BandwidthLimit)
    );
  }

  /**
   * List the Public Keys for the cluster.
   *
   * Returns the Public Keys for the cluster.
   * @param regionId This field represents the region id.
   * @param accessClusterId This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetClusterKeysResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<ClusterPublicKeys>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/cluster/keys`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ClusterPublicKeys>;
      })
    );
  }
  /**
   * List the Public Keys for the cluster.
   *
   * Returns the Public Keys for the cluster.
   * @param regionId This field represents the region id.
   * @param accessClusterId This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetClusterKeys(regionId?: string,
    accessClusterId?: number): __Observable<ClusterPublicKeys> {
    return this.GetClusterKeysResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as ClusterPublicKeys)
    );
  }

  /**
   * Destroy a Cohesity Cluster.
   *
   * Sends a request to destroy a Cohesity Cluster and returns some information
   * about the operation and each Node.
   *
   * WARNING: This is a destructive operation.
   * @param regionId This field represents the region id.
   * @param accessClusterId This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   */
  DestroyClusterResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/public/clusters`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Destroy a Cohesity Cluster.
   *
   * Sends a request to destroy a Cohesity Cluster and returns some information
   * about the operation and each Node.
   *
   * WARNING: This is a destructive operation.
   * @param regionId This field represents the region id.
   * @param accessClusterId This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   */
  DestroyCluster(regionId?: string,
    accessClusterId?: number): __Observable<null> {
    return this.DestroyClusterResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Create a new Cloud Edition Cohesity Cluster.
   *
   * Sends a request to create a new Cloud Edition Cohesity Cluster and returns
   * the IDs, name, and software version of the new cluster. Also returns the
   * status of each node.
   * @param params The `ClustersService.CreateCloudClusterParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateCloudClusterResponse(params: ClustersService.CreateCloudClusterParams): __Observable<__StrictHttpResponse<CreateClusterResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/clusters/cloudEdition`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CreateClusterResult>;
      })
    );
  }
  /**
   * Create a new Cloud Edition Cohesity Cluster.
   *
   * Sends a request to create a new Cloud Edition Cohesity Cluster and returns
   * the IDs, name, and software version of the new cluster. Also returns the
   * status of each node.
   * @param params The `ClustersService.CreateCloudClusterParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateCloudCluster(params: ClustersService.CreateCloudClusterParams): __Observable<CreateClusterResult> {
    return this.CreateCloudClusterResponse(params).pipe(
      __map(_r => _r.body as CreateClusterResult)
    );
  }

  /**
   * Expand a Cloud Edition Cohesity Cluster.
   *
   * Sends a request to expand a Cloud Edition Cohesity Cluster and returns some
   * information about the request and each new Node.
   * @param params The `ClustersService.ExpandCloudClusterParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  ExpandCloudClusterResponse(params: ClustersService.ExpandCloudClusterParams): __Observable<__StrictHttpResponse<CreateClusterResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/clusters/cloudEdition/nodes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CreateClusterResult>;
      })
    );
  }
  /**
   * Expand a Cloud Edition Cohesity Cluster.
   *
   * Sends a request to expand a Cloud Edition Cohesity Cluster and returns some
   * information about the request and each new Node.
   * @param params The `ClustersService.ExpandCloudClusterParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  ExpandCloudCluster(params: ClustersService.ExpandCloudClusterParams): __Observable<CreateClusterResult> {
    return this.ExpandCloudClusterResponse(params).pipe(
      __map(_r => _r.body as CreateClusterResult)
    );
  }

  /**
   * Check the progress of the creation of a new Cohesity Cluster.
   *
   * Sends a request to check the progress of the creation of a new Cohesity
   * Cluster and returns some information about the creation process along
   * with an estimated time remaining and completion percentage.
   * @param regionId This field represents the region id.
   * @param accessClusterId This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetClusterCreationProgressResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<ClusterCreationProgressResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/clusters/creationProgress`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ClusterCreationProgressResult>;
      })
    );
  }
  /**
   * Check the progress of the creation of a new Cohesity Cluster.
   *
   * Sends a request to check the progress of the creation of a new Cohesity
   * Cluster and returns some information about the creation process along
   * with an estimated time remaining and completion percentage.
   * @param regionId This field represents the region id.
   * @param accessClusterId This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetClusterCreationProgress(regionId?: string,
    accessClusterId?: number): __Observable<ClusterCreationProgressResult> {
    return this.GetClusterCreationProgressResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as ClusterCreationProgressResult)
    );
  }

  /**
   * Return the IO preferential tiers of the cluster.
   *
   * Get the IO preferential tiers of the cluster.
   * @param regionId This field represents the region id.
   * @param accessClusterId This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetIoPreferentialTierResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<IoPreferentialTier>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/clusters/ioPreferentialTier`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<IoPreferentialTier>;
      })
    );
  }
  /**
   * Return the IO preferential tiers of the cluster.
   *
   * Get the IO preferential tiers of the cluster.
   * @param regionId This field represents the region id.
   * @param accessClusterId This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetIoPreferentialTier(regionId?: string,
    accessClusterId?: number): __Observable<IoPreferentialTier> {
    return this.GetIoPreferentialTierResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as IoPreferentialTier)
    );
  }

  /**
   * Update the IO preferential tiers and return the updated IO preferential tiers of the cluster.
   *
   * Update the IO preferential tiers of the cluster.
   * @param params The `ClustersService.PutIoPreferentialTierParams` containing the following parameters:
   *
   * - `Body`:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  PutIoPreferentialTierResponse(params: ClustersService.PutIoPreferentialTierParams): __Observable<__StrictHttpResponse<IoPreferentialTier>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.Body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/public/clusters/ioPreferentialTier`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<IoPreferentialTier>;
      })
    );
  }
  /**
   * Update the IO preferential tiers and return the updated IO preferential tiers of the cluster.
   *
   * Update the IO preferential tiers of the cluster.
   * @param params The `ClustersService.PutIoPreferentialTierParams` containing the following parameters:
   *
   * - `Body`:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  PutIoPreferentialTier(params: ClustersService.PutIoPreferentialTierParams): __Observable<IoPreferentialTier> {
    return this.PutIoPreferentialTierResponse(params).pipe(
      __map(_r => _r.body as IoPreferentialTier)
    );
  }

  /**
   * Remove a Node from a Cohesity Cluster.
   *
   * Sends a request to remove a Node from a Cohesity Cluster.
   *
   * WARNING: This is a destructive operation.
   * @param params The `ClustersService.RemoveNodeParams` containing the following parameters:
   *
   * - `id`: Specifies the ID of the node being removed.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   */
  RemoveNodeResponse(params: ClustersService.RemoveNodeParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/public/clusters/nodes/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Remove a Node from a Cohesity Cluster.
   *
   * Sends a request to remove a Node from a Cohesity Cluster.
   *
   * WARNING: This is a destructive operation.
   * @param params The `ClustersService.RemoveNodeParams` containing the following parameters:
   *
   * - `id`: Specifies the ID of the node being removed.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   */
  RemoveNode(params: ClustersService.RemoveNodeParams): __Observable<null> {
    return this.RemoveNodeResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Create a new Physical Edition Cohesity Cluster.
   *
   * Sends a request to create a new Physical Edition Cohesity Cluster and returns
   * the IDs, name, and software version of the new cluster. Also returns the
   * status of each node.
   * @param params The `ClustersService.CreatePhysicalClusterParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreatePhysicalClusterResponse(params: ClustersService.CreatePhysicalClusterParams): __Observable<__StrictHttpResponse<CreateClusterResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/clusters/physicalEdition`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CreateClusterResult>;
      })
    );
  }
  /**
   * Create a new Physical Edition Cohesity Cluster.
   *
   * Sends a request to create a new Physical Edition Cohesity Cluster and returns
   * the IDs, name, and software version of the new cluster. Also returns the
   * status of each node.
   * @param params The `ClustersService.CreatePhysicalClusterParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreatePhysicalCluster(params: ClustersService.CreatePhysicalClusterParams): __Observable<CreateClusterResult> {
    return this.CreatePhysicalClusterResponse(params).pipe(
      __map(_r => _r.body as CreateClusterResult)
    );
  }

  /**
   * Expand a Physical Edition Cohesity Cluster.
   *
   * Sends a request to expand a Physical Edition Cohesity Cluster and returns some
   * information about the request and each new Node.
   * @param params The `ClustersService.ExpandPhysicalClusterParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  ExpandPhysicalClusterResponse(params: ClustersService.ExpandPhysicalClusterParams): __Observable<__StrictHttpResponse<CreateClusterResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/clusters/physicalEdition/nodes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CreateClusterResult>;
      })
    );
  }
  /**
   * Expand a Physical Edition Cohesity Cluster.
   *
   * Sends a request to expand a Physical Edition Cohesity Cluster and returns some
   * information about the request and each new Node.
   * @param params The `ClustersService.ExpandPhysicalClusterParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  ExpandPhysicalCluster(params: ClustersService.ExpandPhysicalClusterParams): __Observable<CreateClusterResult> {
    return this.ExpandPhysicalClusterResponse(params).pipe(
      __map(_r => _r.body as CreateClusterResult)
    );
  }

  /**
   * List the states of the services on the Cluster.
   *
   * Sends a request to list the states of all of the services on a Cluster.
   * @param regionId This field represents the region id.
   * @param accessClusterId This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  ListServiceStatesResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<Array<ServiceStateResult>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/clusters/services/states`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ServiceStateResult>>;
      })
    );
  }
  /**
   * List the states of the services on the Cluster.
   *
   * Sends a request to list the states of all of the services on a Cluster.
   * @param regionId This field represents the region id.
   * @param accessClusterId This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  ListServiceStates(regionId?: string,
    accessClusterId?: number): __Observable<Array<ServiceStateResult>> {
    return this.ListServiceStatesResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as Array<ServiceStateResult>)
    );
  }

  /**
   * Change the state of one or more services on a Cohesity Cluster.
   *
   * Sends a request to either stop, start, or restart one or more of the services
   * on a Cohesity Cluster and returns a message describing the result.
   *
   * WARNING: This is a destructive operation.
   * @param params The `ClustersService.ChangeServiceStateParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  ChangeServiceStateResponse(params: ClustersService.ChangeServiceStateParams): __Observable<__StrictHttpResponse<ChangeServiceStateResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/clusters/services/states`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ChangeServiceStateResult>;
      })
    );
  }
  /**
   * Change the state of one or more services on a Cohesity Cluster.
   *
   * Sends a request to either stop, start, or restart one or more of the services
   * on a Cohesity Cluster and returns a message describing the result.
   *
   * WARNING: This is a destructive operation.
   * @param params The `ClustersService.ChangeServiceStateParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  ChangeServiceState(params: ClustersService.ChangeServiceStateParams): __Observable<ChangeServiceStateResult> {
    return this.ChangeServiceStateResponse(params).pipe(
      __map(_r => _r.body as ChangeServiceStateResult)
    );
  }

  /**
   * Upgrade a Cohesity Cluster.
   *
   * Sends a request to upgrade the software version of a Cohesity Cluster
   * and returns a message specifying the result. Before using this, you need to
   * use the /public/packages endpoint to upload a new package to the Cluster.
   * @param params The `ClustersService.UpgradeClusterParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpgradeClusterResponse(params: ClustersService.UpgradeClusterParams): __Observable<__StrictHttpResponse<UpgradeClusterResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/public/clusters/software`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UpgradeClusterResult>;
      })
    );
  }
  /**
   * Upgrade a Cohesity Cluster.
   *
   * Sends a request to upgrade the software version of a Cohesity Cluster
   * and returns a message specifying the result. Before using this, you need to
   * use the /public/packages endpoint to upload a new package to the Cluster.
   * @param params The `ClustersService.UpgradeClusterParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpgradeCluster(params: ClustersService.UpgradeClusterParams): __Observable<UpgradeClusterResult> {
    return this.UpgradeClusterResponse(params).pipe(
      __map(_r => _r.body as UpgradeClusterResult)
    );
  }

  /**
   * Create a new Virtual Edition Cohesity Cluster.
   *
   * Sends a request to create a new Virtual Edition Cohesity Cluster and returns
   * the IDs, name and software version of the new cluster.
   * @param params The `ClustersService.CreateVirtualClusterParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateVirtualClusterResponse(params: ClustersService.CreateVirtualClusterParams): __Observable<__StrictHttpResponse<CreateClusterResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/clusters/virtualEdition`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CreateClusterResult>;
      })
    );
  }
  /**
   * Create a new Virtual Edition Cohesity Cluster.
   *
   * Sends a request to create a new Virtual Edition Cohesity Cluster and returns
   * the IDs, name and software version of the new cluster.
   * @param params The `ClustersService.CreateVirtualClusterParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateVirtualCluster(params: ClustersService.CreateVirtualClusterParams): __Observable<CreateClusterResult> {
    return this.CreateVirtualClusterResponse(params).pipe(
      __map(_r => _r.body as CreateClusterResult)
    );
  }

  /**
   * List the external Client Subnets for the cluster.
   *
   * Returns the external Client Subnets for the cluster.
   * @param params The `ClustersService.GetExternalClientSubnetsParams` containing the following parameters:
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which objects are to be
   *   returned.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `allUnderHierarchy`: AllUnderHierarchy specifies if objects of all the tenants under the
   *   hierarchy of the logged in user's organization should be returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetExternalClientSubnetsResponse(params: ClustersService.GetExternalClientSubnetsParams): __Observable<__StrictHttpResponse<ExternalClientSubnets>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.tenantIds || []).forEach(val => {if (val != null) __params = __params.append('tenantIds', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.allUnderHierarchy != null) __params = __params.set('allUnderHierarchy', params.allUnderHierarchy.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/externalClientSubnets`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ExternalClientSubnets>;
      })
    );
  }
  /**
   * List the external Client Subnets for the cluster.
   *
   * Returns the external Client Subnets for the cluster.
   * @param params The `ClustersService.GetExternalClientSubnetsParams` containing the following parameters:
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which objects are to be
   *   returned.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `allUnderHierarchy`: AllUnderHierarchy specifies if objects of all the tenants under the
   *   hierarchy of the logged in user's organization should be returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetExternalClientSubnets(params: ClustersService.GetExternalClientSubnetsParams): __Observable<ExternalClientSubnets> {
    return this.GetExternalClientSubnetsResponse(params).pipe(
      __map(_r => _r.body as ExternalClientSubnets)
    );
  }

  /**
   * Update the external Client Subnet of the Cluster.
   *
   * Returns the updated external Client Subnets of the cluster.
   * @param params The `ClustersService.UpdateExternalClientSubnetsParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * - `Body`:
   *
   * @return Success
   */
  UpdateExternalClientSubnetsResponse(params: ClustersService.UpdateExternalClientSubnetsParams): __Observable<__StrictHttpResponse<ExternalClientSubnets>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    __body = params.Body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/public/externalClientSubnets`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ExternalClientSubnets>;
      })
    );
  }
  /**
   * Update the external Client Subnet of the Cluster.
   *
   * Returns the updated external Client Subnets of the cluster.
   * @param params The `ClustersService.UpdateExternalClientSubnetsParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * - `Body`:
   *
   * @return Success
   */
  UpdateExternalClientSubnets(params: ClustersService.UpdateExternalClientSubnetsParams): __Observable<ExternalClientSubnets> {
    return this.UpdateExternalClientSubnetsResponse(params).pipe(
      __map(_r => _r.body as ExternalClientSubnets)
    );
  }
}

module ClustersService {

  /**
   * Parameters for CreateCloudCluster
   */
  export interface CreateCloudClusterParams {
    body: CreateCloudClusterParameters;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for ExpandCloudCluster
   */
  export interface ExpandCloudClusterParams {
    body: ExpandCloudClusterParameters;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for PutIoPreferentialTier
   */
  export interface PutIoPreferentialTierParams {
    Body: IoPreferentialTier;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for RemoveNode
   */
  export interface RemoveNodeParams {

    /**
     * Specifies the ID of the node being removed.
     */
    id: number;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CreatePhysicalCluster
   */
  export interface CreatePhysicalClusterParams {
    body: CreatePhysicalClusterParameters;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for ExpandPhysicalCluster
   */
  export interface ExpandPhysicalClusterParams {
    body: ExpandPhysicalClusterParameters;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for ChangeServiceState
   */
  export interface ChangeServiceStateParams {
    body: ChangeServiceStateParameters;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpgradeCluster
   */
  export interface UpgradeClusterParams {
    body: UpgradeClusterParameters;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CreateVirtualCluster
   */
  export interface CreateVirtualClusterParams {
    body: CreateVirtualClusterParameters;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetExternalClientSubnets
   */
  export interface GetExternalClientSubnetsParams {

    /**
     * TenantIds contains ids of the tenants for which objects are to be
     * returned.
     */
    tenantIds?: Array<string>;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * AllUnderHierarchy specifies if objects of all the tenants under the
     * hierarchy of the logged in user's organization should be returned.
     */
    allUnderHierarchy?: boolean;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateExternalClientSubnets
   */
  export interface UpdateExternalClientSubnetsParams {

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
    Body?: ExternalClientSubnets;
  }
}

export { ClustersService }
