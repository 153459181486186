import { DirEntry } from '@cohesity/api/private';

/**
 * This model is used within the volume browser to represent file objects within the browser UI.
 */
export interface Document {
  /**
   * The name of the file
   */
  name: string;

  /**
   * The full path of the file, including the volume and name. The format must be:
   *
   * <volume name>/<path>.../<name>
   *
   * There should never be a leading / in the name.
   */
  fullPath: string;

  /**
   * The sanitized path of the file, in cases where fullPath has a prefix.
   * This is required only for display purposes in volume selection detail.
   *
   * e.g. {/OneDrives/Onedrive-<driveId>/metadata/rocksdb/}.../<name>
   * The entire string inside {} is a prefix to be removed.
   * There should never be a leading / in the name.
   */
  sanitizedPath?: string;

  /**
   * The name of the volume comtaining the document
   */
  volume?: string;

  /**
   * True if this is the root volume object
   */
  type: 'Volume' | 'Directory' | 'File' | 'Symlink';

  /**
   * Shorthand property for easier checks for folders/files.
   */
  isFolder?: boolean;

  /**
   * The size of the file - not valid for directories or volumes
   */
  size?: number;

  /**
   * The last modified time of the file or directory. Not valid for volumes.
   */
  lastModifiedUsecs?: number;

  /**
   * Index node id for a particular version of file or directory. Not valid for volumes.
   */
  inodeId?: number;

  /**
   * The original entry object, as received from the API.
   */
  dirEntry?: DirEntry;
}

/**
 * This modal is used within the volume browser to check file stat within the browser UI.
 */
export interface DocumentStat {
  /**
   * The file type
   */
  type: 'Volume' | 'Directory' | 'File' | 'Symlink';

  /**
   * The size of the file - not valid for directories or volumes
   */
  size?: number;

  /**
   * The last modified time of the file or directory. Not valid for volumes.
   */
  lastModifiedUsecs?: number;
}

/**
 * This is used to represent an api response for a list of files or volumes. It includes the list of
 * files and a cookie that can be used to fetch additional values.
 */
export interface DocumentsResponse {
  /**
   * The files returned by the response
   */
  documents: Document[];

  /**
   * If this cookie is set, there are more files available to load. The cookie should be passed to the API in
   * order to fetch more files.
   */
  cookie?: number | string;
}

/**
 * Map of v1 file types to v2 file types.
 */
export const FileTypeMap = {
  kDirectory: 'Directory',
  kFile: 'File',
  kSymlink: 'Symlink',
};

/**
 * Map file stat api object types to v2 file types.
 */
export const FileStatMap = {
  1: 'File',
  2: 'Directory',
  3: 'Symlink',
};

/**
 * Interface if cookies are passed as a param.
 */
export interface FolderBrowserCookies {
  directoryCookie?: string;
  volumeCookie?: string;
}
