import { Component, Input, ViewEncapsulation } from '@angular/core';
import { IconSize } from '@cohesity/helix';

/**
 * @description
 * Component to display "Snapshot Deleted" message instead of transcluded content.
 *
 * @example
 *  <coh-snapshot-deleted [deleted]="run.isLocalSnapshotsDeleted">
 *   <span>This content is displayed when Snapshot is not deleted</span>
 *  </coh-snapshot-deleted>
 *
 */
@Component({
  selector: 'coh-snapshot-deleted',
  templateUrl: './snapshot-deleted.component.html',
  styleUrls: ['./snapshot-deleted.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SnapshotDeletedComponent {
  /**
   * True if snapshot is deleted.
   */
  @Input() deleted = false;

  /**
   * Displays snapshot deleted as icon instead of label.
   */
  @Input() useIcon = false;

  /**
   * Icon size for deleted snapshot icon.
   */
  @Input() iconSize: IconSize = 'md';
}
