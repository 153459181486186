import {
  Attribute,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewEncapsulation,
} from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { IconComponent, IconService, parseIconSvgName } from '@cohesity/helix';
import { of, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';

/**
 * Icon sizes are set manually on the SVG rather than with CSS here. Note that these values are in px rather than rem,
 * since svg doesn't use rem.
 */
const svgIconSizes = {
  xs: 12,
  sm: 16,
  md: 24,
  lg: 32,
  xl: 48,
};

/**
 * This is a variation of cog-icon that renders icons directly in an svg instead of using html and mat-icon. This class
 * extends the original icon component, but instead of rendering a template, it looks up the icon directly and appends
 * it to the element. This can be used directly inside of yfiles node components
 *
 * @example
 * <g hyf-icon shape="helix-cluster" size="lg"></g>
 *
 * This will render:
 * <g hyf-icon>
 *   <svg width="32" height="32" viewbox="0 0 24 24">
 *      ...cog-icon-svg content
 *   </svg>
 * </g>
 */
@Component({
  // Note the odd selector, this won't render if you don't use an attribute for it
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'g [hyf-icon]',
  styleUrls: ['./svg-icon-group.component.scss'],
  template: '',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SvgIconGroupComponent extends IconComponent implements OnInit, OnDestroy {
  /**
   * Clean up the subscription when the component is destroyed
   */
  private iconSub: Subscription;

  /**
   * Add the warn style based on the color
   */
  @HostBinding('class.mat-warn') get colorWarn() {
    return this.color === 'warn';
  }

  /**
   * Add the primary style based on the color
   */
  @HostBinding('class.mat-primary') get colorPrimary() {
    return this.color === 'primary';
  }

  /**
   * Add the accent style based on the color
   */
  @HostBinding('class.mat-accent') get colorAccent() {
    return this.color === 'accent';
  }

  /**
   * Add a no color style if none is set.
   */
  @HostBinding('class.mat-no-color') get colorDefault() {
    return !this.color;
  }

  constructor(
    @Attribute('aria-hidden') ariaHidden: string,
    cdr: ChangeDetectorRef,
    private iconReg: MatIconRegistry,
    iconService: IconService,
    elementRef: ElementRef,
    private renderer: Renderer2
  ) {
    super(ariaHidden, cdr, iconReg, iconService, elementRef);
  }

  ngOnInit() {
    this.iconSub = this.iconLoaded
      .pipe(
        switchMap(() => {
          if (!this.svgIcon) {
            return of(null);
          } else if (this.matIcon) {
            throw new Error('svg-icon-group only works with SVG icons');
          }
          const [namespace, name] = parseIconSvgName(this.shape);

          return this.iconReg.getNamedSvgIcon(name, namespace);
        })
      )
      .subscribe((svg: SVGElement) => {
        if (!svg) {
          this._elementRef.nativeElement.innerHTML = '';
        } else {
          const size = this.size || 'md';
          svg.setAttribute('width', svgIconSizes[size].toString());
          svg.setAttribute('height', svgIconSizes[size].toString());
          this.renderer.appendChild(this._elementRef.nativeElement, svg);
        }
      });
    this.iconLoaded.emit();
  }

  ngOnDestroy() {
    if (!this.iconSub?.closed) {
      this.iconSub.unsubscribe();
      this.iconSub = null;
    }
  }
}
