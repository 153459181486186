import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ProtectionJob, ProtectionSourceNode } from '@cohesity/api/v1';
import { ProtectionSourceDataNode } from 'src/app/shared/source-tree/protection-source';
import { Environment, JobToSourceEnvironment } from 'src/app/shared';

import { ProtectionGroup, ProtectionRun } from '../../../models';

/**
 * Data interface for objects selection dialog.
 */
interface ObjectsSelectionDialogData {
  job: ProtectionJob;
  protectionGroup: ProtectionGroup;
  protectionRun: ProtectionRun;
  sourceNodes: ProtectionSourceNode[];
  selectedIds: number[];
}

/**
 * @description
 *
 * Modal contains objects selection tree based on protection group and run provided.
 */
@Component({
  selector: 'coh-objects-select-dialog',
  templateUrl: './objects-select-dialog.component.html',
  styleUrls: ['./objects-select-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ObjectsSelectDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) readonly data: ObjectsSelectionDialogData) {}

  /**
   * Returns source IDs from source tree for selected nodes.
   *
   * @param selection Selected nodes.
   * @returns Source IDs for selected nodes.
   */
  getSelectedSourceIds(selection: ProtectionSourceDataNode[]): number[] {
    // if it is a pure source related job and job has only ppgs
    // in the job, select only ppg ids
    if ([Environment.kPure, Environment.kIbmFlashSystem].includes(this.data?.job?.environment as Environment)
      && !selection?.[0]?.isLeaf) {
      return selection?.map(node => node.protectionSource.id);
    }
    return selection?.filter(node => node.isLeaf)?.map(node => node.protectionSource.id);
  }

  /**
   * The source environment.
   */
   getSourceEnvironment(environment: string): Environment {
    return JobToSourceEnvironment[environment] ? JobToSourceEnvironment[environment][0] : environment;
  }
}
