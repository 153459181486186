// Decorators for treeControl directives

;(function(angular, undefined) {
  'use strict';

  var modName = 'C.pubSourceTree';

  angular.module(modName)
    .config(Config);

  function Config($provide) {
    $provide.decorator('setNodeToDataDirective', setNodeWithTestId);
  }

  /**
   * @ngdoc decorator
   * @name        setNodeToDataDirective
   * @method      setNodeWithTestId
   *
   * @description
   * Decorator tree-control node with unique ID which is the path to reach that
   * node from root
   *
   * @example
     id="node-1-0-3-1"
     id="node-1-0"
     id="node-1"
   */
  function setNodeWithTestId($delegate) {
    var originalLinkFn = $delegate[0].link;

    // deleting the old link fn.
    delete $delegate[0].link;

    // decorating compile fn.
    $delegate[0].compile = function newCompileFn() {
      return {
        pre: addNodeIdPreLinkFn,
        post: originalLinkFn,
      };
    };

    /**
     * Adds a node identifier pre link function.
     *
     * @method   addNodeIdPostLinkFn
     * @param    {Object}   $scope     The scope
     * @param    {Object}   $element   The element
     */
    function addNodeIdPreLinkFn($scope, $element) {
      // Get node IDs and keep them in the scope for later use
      $scope.$nodeId = getNodeId($scope);
      $scope.$nodeIdString = $scope.$nodeId.join('-');

      $element.attr('id', 'node-' + $scope.$nodeIdString);
    }

    /**
     * Returns the unique IDs for the node which is the path to reach the node.
     *
     * @method   getNodeId
     * @param    {Object}    $scope   The scope Object
     * @return   {Number[]}  The node's unique IDs
     */
    function getNodeId($scope) {
      var nodeIds = [];
      var current = $scope;

      // bubble up in the scope hierarchy and collect all $index till tree root
      // to create path to reach the node.
      do {
        // collect $index from scope which is added by ng-repeat
        if (current.hasOwnProperty('$index')) {
          nodeIds.unshift(current.$index);
        }
        current = current.$parent;

        // keep bubbling until you reaches tree synteticRoot (added by
        // treeControl) or reached rootScope to break the bubbling.
        // synteticRoot is a typo in tree-control lib that we are using
        // https://github.com/wix/angular-tree-control/issues/261
      } while(current &&
        (current.hasOwnProperty('synteticRoot') ? false : current.$id !== 1)
      );

      return nodeIds;
    }

    return $delegate;
  }

})(angular);
