import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { CohesityHelixModule, DataIdModule } from '@cohesity/helix';
import { TranslateModule } from '@ngx-translate/core';

import { RpaasStorageClassUsageModule } from '../rpaas-storage-class-usage';
import { RpaasDataCardComponent } from './rpaas-data-card.component';

@NgModule({
  imports: [
    CohesityHelixModule,
    CommonModule,
    DataIdModule,
    RpaasStorageClassUsageModule,
    TranslateModule,
  ],
  exports: [RpaasDataCardComponent],
  declarations: [RpaasDataCardComponent],
})
export class RpaasDataCardModule {}
