<div class="source-meta-grouping margin-top-lg" *ngIf="volumes.length">
  <h5 class="title">{{label | translate}}</h5>

  <ul>
    <li *ngFor="let volume of volumes" class="source-meta-line-item">
      <span class="source-meta-label">{{volume?.label || ('volume' | translate)}}</span>


      <span [matTooltip]="volume.guid ? ('volumeGuidColon' | translate : { guid: volume.guid }) : null">
        {{ volume?.mountPoints?.length ? volume?.mountPoints[0] : ('noMountPoint' | translate) }}
      </span>
    </li>
  </ul>
</div>
