<div class="dialog-header">
  <h2 mat-dialog-title>{{ 'importExternalCertificateDialog.title' | translate }}</h2>
  <cog-stepper-header [matStepperRef]="stepper">
  </cog-stepper-header>
</div>

<mat-horizontal-stepper
  class="container"
  #stepper
  cogStepper
  [@.disabled]="true"
  [linear]="true">
  <mat-step [label]="'importExternalCertificateDialog.upload' | translate">
    <coh-upload-import-external-certificate-interface
      (interfaceDataFile)="interfaceUploadDataFile($event)"
    >
    </coh-upload-import-external-certificate-interface>
  </mat-step>

  <mat-step [label]="'importExternalCertificateDialog.settings' | translate">
    <coh-settings-import-external-certificate-interface
      (interfaceDataFile)="interfaceSettingsDataFile($event)"
    >
    </coh-settings-import-external-certificate-interface>
  </mat-step>
</mat-horizontal-stepper>

<mat-dialog-actions align="end">
  <!-- Back button -->
  <button
    mat-button
    (click)="onBack()"
    cdkFocusInitial
    *ngIf="!isUploadStep"
  >
    {{ 'back' | translate }}
  </button>

  <!-- Cancel button -->
  <button
    mat-button
    mat-dialog-close
    cohCancelButton
    cdkFocusInitial
  >
    {{ 'cancel' | translate }}
  </button>

  <!-- Continue button -->
  <button
    mat-button
    cohSubmitButton
    [disabled]="!isUploadFormValid"
    (click)="onContinue()"
    type="submit"
    *ngIf="isUploadStep"
  >
    {{ 'continue' | translate }}
  </button>

  <!-- Complete button -->
  <button
    mat-button
    cohSubmitButton
    [disabled]="!isSettingsFormValid"
    (click)="onComplete()"
    type="submit"
    *ngIf="!isUploadStep"
  >
    {{ 'complete' | translate }}
  </button>
</mat-dialog-actions>