import { InjectionToken, Type } from '@angular/core';
import { Observable } from 'rxjs';

import { DocumentsResponse, DocumentStat, FolderBrowserCookies } from './folder-browser.models';

/**
 * Interface for the service to be used for folder browsing.
 */
export interface FolderBrowserProvider {

  /**
   * Set to true if you want to use volumes ie. call listVolumes instead of listFiles
   */
  usesVolumes?: boolean;

  /**
   * Look up stats for a file, to check if it exists and what kind of file it is.
   *
   * @param fullPath The full path to check
   * @param splitPath Alternate entry with volume and path split as an array. The file browser requires this, other
   *                  browsing implementations may not need it.
   * @param cookies  Optional cookies object containing the volume cookie.
   * @returns  An observable with the DocumentStat response.
   */
  statFile(fullPath: string, splitPath: string[], cookies?: FolderBrowserCookies): Observable<DocumentStat>;

  /**
   * Function required to make the API call to do the browsing.
   *
   * @param   fullPath   The full path to browse to.
   * @param   fetchMore  If true, this will attempt to load more items.
   * @param   splitPath  Optional, split the path into volume and path using the internal cache.
   * @param   cookies    Optional, send cookies (directory and volume) if needed.
   * @param   maxEntries Optional, The filter to use for the search to return total records in a single api call
   * @returns  An observable with the DocumentsResponse response.
   */
  listFiles(
    fullPath: string,
    fetchMore?: boolean,
    splitPath?: string[],
    cookies?: FolderBrowserCookies,
    maxEntries?: number): Observable<DocumentsResponse>;

  /**
   * Function to call if we need to list the volumes.
   */
  listVolumes?(): Observable<DocumentsResponse>;
}

/**
 * The provider token for folder browser.
 */
export const folderBrowserToken = new InjectionToken<FolderBrowserProvider>('folder-browser-token');

/**
 * Utitlity provide the folderBrowser to the component.
 *
 * @param folderBrowserProvider The provider funtion.
 */
export function folderBrowserProviderFn(folderBrowserProvider: Type<FolderBrowserProvider>) {
  return [{
    provide: folderBrowserToken,
    useFactory: (provider: Type<FolderBrowserProvider>) => provider,
    deps: [folderBrowserProvider],
  }];
}
