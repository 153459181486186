import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { AppStateService } from './app-state.service';
import { DmsService } from './dms.service';
import { PassthroughOptionsService } from './passthrough-options.service';
import { IrisContextService, isTenantUser } from '@cohesity/iris-core';

@Injectable({
  providedIn: 'root'
})
export class PassthroughNameService {
  constructor(
    private appStateService: AppStateService,
    private dmsService: DmsService,
    private passthroughOptionsService: PassthroughOptionsService,
    private irisContextService: IrisContextService
  ) {
  }

  /**
   * Function to return the name of the passthrough locations.
   *
   * return The passthrough location name object.
   */
  getPassthroughName$(): Observable<{cid: string; regionId: string}> {
    return forkJoin([
      of(this.getClusterName(this.passthroughOptionsService.accessClusterId)),
      (isTenantUser(this.irisContextService.irisContext)) ? of(''):
      this.getRegionName(this.passthroughOptionsService.regionId),
    ]).pipe(
      map(([clusterName, regionName]) => ({cid: clusterName, regionId: regionName})),
    );
  }

  /**
   * Function to get the cluster name of a specific cluster id.
   *
   * @param clusterId The cluster id.
   * @return The cluster name.
   */
  getClusterName(clusterId: string|number): string {
    const matchedCluster = this.appStateService.remoteClusterList?.find(
      cluster => cluster.clusterId === Number(clusterId)
    );

    return matchedCluster?.name;
  }

  /**
   * Function to get the region name of a specific region id.
   *
   * @param regionId The region id.
   * @return The region name.
   */
  getRegionName(regionId: string): Observable<string> {
    return this.dmsService.getConfiguredRegions().pipe(
      take(1),
      map(regions => regions.find(region => region.id === regionId)),
      map(matchedRegion => matchedRegion?.name),
    );
  }
}
