import { Component, Input, OnInit } from '@angular/core';
import { ProtectionSourceNode, ProtectionSourcesServiceApi } from '@cohesity/api/v1';
import { KeyValuePair } from '@cohesity/api/v2';
import { IrisContextService, isDmsScope } from '@cohesity/iris-core';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';
import {
  CustomArgDisplayEntry,
  Environment,
  getCustomArgumentsListForDisplay,
  JobEnvParamsV2,
  RecoverSourceEnvParamsV2
} from 'src/app/shared';
import { UdaConnectorConfigService } from 'src/app/shared/uda/services';
import { Recovery } from '../../model/recovery';

interface RecoverJobOptions {
  /**
   * A suffix that is to be applied to all recovered objects.
   */
  suffix?: string;

  /**
   * Set to true to overwrite an existing object at the destination.
   * If set to false, and the same object exists at the destination,
   * then recovery will fail for that object.
   */
  overwrite?: boolean;

  /**
   * Specifies the maximum number of concurrent IO Streams that will be
   * created to exchange data with the cluster.
   */
  concurrency?: number;

  /**
   * Specifies whether to start database or not. This is only applicable for
   * sap hana in dms.
   */
  startDatabase?: boolean;

  /**
   * Specifies the maximum network bandwidth that each concurrent IO
   * Stream can use for exchanging data with the cluster.
   */
  bandwidthMBPS?: number;

  /**
   * Specifies the target directory where files and folders are to be recovered.
   */
  targetDirectory?: string;

  /**
   * Specifies the target directory where files and folders are to be recovered.
   */
  logRestoreDirectory?: string;

  /**
   * Specifies wheater documents were appended at the time of recovery.
   */
  appendDocuments?: boolean;

  /**
   * Set to true to recover only the bucket configurations.
   * No documents will be recovered.
   */
  ddlOnlyRecovery?: boolean;

  /**
   * If set to true existing users will be replaced with users
   * from the bucket being recovered.
   */
  overwriteUsers?: boolean;

  /**
   * Selected Data centers for this cluster.
   */
  selectedDataCenters?: string[];

  /**
   * Number of view mounts.
   */
  mounts?: number;

  /**
   * Custom recovery arguments to be passed to recovery scripts for supporting adapters.
   */
  recoveryArgs?: string;

  /**
   * Target recovery datasource Id.
   */
  recoverTo?: number;

  /**
   * Specifies the map of custom arguments to be supplied to the restore job script.
   */
  recoveryJobArguments: KeyValuePair[];
}

/**
 * Shows details for the recovery options set during the creation of the recovery
 * job. This should be merged with recovery-options component once approved by UX.
 * Currently this is being used by adapters which have spawn a single restore task
 * to magent the entire recovery process and the actual work is delegated to either
 * an app as in the case of Imanis or the agent in the case of UDA.
 */
@Component({
  selector: 'coh-recovery-options-details',
  templateUrl: './recovery-options-details.component.html',
})
export class RecoveryOptionsDetailsComponent implements OnInit {
  /**
   * Recovery job for which detail options are to be shown.
   */
  @Input() recovery: Recovery;

  /**
   * RecoverJobParams of NoSQL - Hadoop adaptors.
   */
  recoverJobOptions: RecoverJobOptions;

  /** Make Environment available to template. */
  Environment = Environment;

  /** Make recovery environment availabe to template. */
  recoveryEnvironment: string;

  /**
   * List of UDA custom job arguments for display. Each entry contains a pre-translated
   * label for the argument and a value for it.
   */
  customArguments$: Observable<CustomArgDisplayEntry[]>;

  /**
   * Returns whether current user is in DMaaS scope.
   */
  get isDmsScope(): boolean {
    return isDmsScope(this.irisContextService.irisContext);
  }


  ngOnInit() {
    this.recoveryEnvironment = this.recovery.environment;

    // e.g. cassandraParams, hdfsParams ...
    const jobParams = JobEnvParamsV2[this.recoveryEnvironment];

    // e.g. recoverCassandraParams, recoverHdfsParams ...
    const recoverJobParams = RecoverSourceEnvParamsV2[this.recoveryEnvironment];

    // Get RecoverJob Options.
    this.recoverJobOptions = this.recovery.
      reference[jobParams][recoverJobParams] as RecoverJobOptions;

    if (this.recoveryEnvironment === Environment.kUDA) {
      this.generateUdaCustomJobArgumentsList();
    }
  }

  constructor(
    private sourceService: ProtectionSourcesServiceApi,
    private irisContextService: IrisContextService,
    private udaConfigService: UdaConnectorConfigService
  ) { }

  /**
   * For UDA, genearte a list of custom job arguments for display. The names for
   * such arguments are retrieved from the UDA dynamic config (based on the source
   * type and host OS type for the recovery target) and the values are fetched from
   * the recovery job options.
   */
  private generateUdaCustomJobArgumentsList() {
    const recoveryTargetDatasourceId = this.recoverJobOptions.recoverTo;

    if (!this.isDmsScope) {
      // Dynamic config based arguments are not supported in DMaaS. These are
      // only available for on-prem sources.
      this.customArguments$ = combineLatest([
        this.getSourceRegistrationInfo(recoveryTargetDatasourceId).pipe(
          filter((sourceInfo) => !!sourceInfo?.registrationInfo?.udaParams),
          map((sourceInfo) => sourceInfo?.registrationInfo?.udaParams),
        ),
        this.udaConfigService.udaSourceHostOsTypeToConfigs$
      ]).pipe(
        map(([udaParams, configMap]) => {
          const sourceType = udaParams.sourceType;
          const hostOsType = udaParams.hostType;

          // Fetch the dynamic registration form configuration for the source
          // type + host OS type combination.
          const dynamicForm = configMap.get(sourceType + hostOsType)?.recoveryWorkflow?.dynamicForm;
          return getCustomArgumentsListForDisplay(
            dynamicForm,
            this.recoverJobOptions.recoveryJobArguments
          );
        }),
      );
    }
  }

  /**
   * Get the source registration information for the recovery target using its ID.
   *
   * It is important to return 'null' here incase the source ID is not available
   * on the cluster. This case could point to the scenario where the recovery target
   * datasource has been unregistered from the cluster.
   *
   * (For UDA, this will cause the custom job arguments to not appear in the recovery
   * job options section as these depend on the UDA config for the UDA source.)
   *
   * @param   sourceId   Source ID of the recovery target.
   * @returns Observable of the source registration information.
   */
  getSourceRegistrationInfo(sourceId: number): Observable<ProtectionSourceNode> {
    if (!sourceId) {
      return of(null);
    }

    return this.sourceService.ListProtectionSources({ id: sourceId })
      .pipe(
        catchError(_ => of([])),
        map(sources => sources?.length ? sources[0] : {}),
      );
  }
}
