import { Component, ViewEncapsulation } from '@angular/core';

/**
 * This component renders a container
 * around the coh-stats-list to help
 * reduce repeated styling of this element
 * in various places within iris
 *
 */
@Component({
  selector: 'coh-stats-container',
  templateUrl: './stats-container.component.html',
  styleUrls: ['./stats-container.component.scss'],
  encapsulation:  ViewEncapsulation.None,
})
export class StatsContainerComponent {
}
