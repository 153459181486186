import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { CohesityHelixModule } from '@cohesity/helix';
import { CohesitySharedFormsModule } from '@cohesity/shared-forms';
import { TranslateModule } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { PersistService } from '@cohesity/utils';

import { AuthType, LoginCredentials, LoginForm, localStorageKey } from '../../models';
import { LoginData } from '../../services';
import { AuthModeToggleComponent } from '../auth-mode-toggle/auth-mode-toggle.component';
import { SalesforceLoginComponent } from '../salesforce-login/salesforce-login.component';

/**
 * This login component presents various login inputs
 * to the user based on the configured authentication type.
 *
 * It handles the UX portion of the login, but does not
 * handle the actual authentication call, which should
 * be passed in as a property of this component.
 *
 * Additionally, the host app can decide what the actions
 * it should take on authentication success or failure.
 *
 * Example:
 *
 * <coh-login
 *  (authSuccess)="handleLoginSuccess($event)"
 *  (authFailure)="handleLoginFailure($event)"
 *  [authFunction]="authFunction"
 *  [authModes]="['local','sso','ad']"
 *  [initialAuthMode]="'local'"
 *  [domains]="domains"
 *  ></coh-login>
 */
@Component({
  selector: 'coh-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [
    AuthModeToggleComponent,
    CommonModule,
    CohesityHelixModule,
    CohesitySharedFormsModule,
    ReactiveFormsModule,
    SalesforceLoginComponent,
    TranslateModule,
  ],
})
export class LoginComponent implements OnInit, OnDestroy {
  /**
   * This displays the forgot password link in the ui.
   */
  supportsForgotPassword = false;

  /**
   * The authentication type is used to configure
   * the component with the appropriate toggles to allow
   * the user to switch between different authentication modes.
   */
  @Input()
  get authModes() {
    return this._authModes;
  }

  set authModes(authTypes) {
    if (authTypes && authTypes.length) {
      this._authModes = authTypes;
    }
  }

  _authModes: AuthType[] = ['local', 'ad', 'sso'];

  /**
   * The authMode to first display the form as.
   * Defaults to 'local'.
   */
  @Input()
  get initialAuthMode() {
    return this._initialAuthMode;
  }

  set initialAuthMode(authMode) {
    if (authMode) {
      this._initialAuthMode = authMode;
      this.changeAuthMode(this._initialAuthMode);
    }
  }

  _initialAuthMode: AuthType = 'local';

  /**
   * The current authentication type, or mode,
   * that the component has rendered.
   */
  get currentAuthMode() {
    return this._currentAuthMode || this._initialAuthMode;
  }

  set currentAuthMode(authType: AuthType) {
    this._currentAuthMode = authType;
    this.updateAuthFormDisplay();
    this.authModeChanged.emit(this._currentAuthMode);
  }

  _currentAuthMode: AuthType;

  /**
   * The authentication function to call when credentials are submitted.
   * This auth function should take the login credentials type as an argument
   * and return a promise.
   */
  @Input()
  authFunction: (loginCredentials: LoginCredentials, authType: AuthType) => Promise<LoginData>;

  _domains: string[];
  /**
   * In the case of AD, the available domains need to be passed in as strings.
   */
  @Input()
  get domains() {
    return this._domains;
  }

  set domains(domains) {
    if (domains?.length) {
      this._domains = domains;
      this.cdr.markForCheck();
    }
  }

  /**
   * Notification of successful login.
   */
  @Output()
  authSuccess = new EventEmitter<LoginData>();

  /**
   * Notification of failed login.
   */
  @Output()
  authFailure = new EventEmitter<string>();

  /**
   * The authentication mode being presented
   * to the user has been changed by the user.
   */
  @Output()
  authModeChanged = new EventEmitter<AuthType>();

  /**
   * Login form
   */
  loginForm: FormGroup = new FormGroup<LoginForm>(this.renderAuthModeControls());

  /**
   * Indicates when login form has pending API calls.
   */
  busy$ = new BehaviorSubject<boolean>(false);

  /**
   * The max length of any text input.  This is just
   * an arbitrary number now to help prevent large request
   * body denial of service through the browser.
   */
  maxInputLength = 100;

  /**
   * The timer to re-enable the submit button after
   * a short delay.
   */
  private timer;

  constructor(
    private cdr: ChangeDetectorRef,
    private persistService: PersistService,
    ) { }

  ngOnInit() {
    this.changeAuthMode(this.initialAuthMode);
  }

  ngOnDestroy(): void {
    clearTimeout(this.timer);
  }

  /**
   * Handles the login form submit.
   */
  onSubmit() {
    if (!this.loginForm.valid) {
      return;
    }

    const loginCredentials = {
      username: this.loginForm.get('username').value,
      ...(this.loginForm.get('password') && { password: this.loginForm.get('password').value }),
      ...(this.loginForm.get('domain') && { domain: this.loginForm.get('domain').value }),
      ...(this.loginForm.get('rememberme') && { rememberme: this.loginForm.get('rememberme').value }),
    };

    this.handleAuthFunctionCall(loginCredentials as LoginCredentials, this._currentAuthMode);
  }

  /**
   * Calls the authentication function specified by
   * the input parameter.  Addtionally, emits
   * informational events for success or failure.
   */
  private handleAuthFunctionCall(credentials: LoginCredentials, authType: AuthType) {
    this.busy$.next(true);
    // Remember Me ( AD only )
    if (this.currentAuthMode === 'ad' && credentials.rememberme) {
      this.persistService.set(localStorageKey, {
        username: credentials.username,
        domain: credentials.domain,
      });
    } else {
      this.persistService.remove(localStorageKey);
    }
    this.authFunction(credentials, authType).then(
      response => {
        this.authSuccessHandler(response);
      },
      errorResponse => {
        this.authFailedHandler(errorResponse);
      }
    );
  }

  /**
   * The handler for the successful login attempt.
   *
   * @param response The response from the server.
   */
  authSuccessHandler(response) {
    // speak with UX about how to handle the submit button when awaiting response.
    this.timer = setTimeout(() => this.busy$.next(false), 1000);
    this.authSuccess.emit(response);
  }

  /**
   * The handler for the failed login attempt.
   *
   * @param response The response from the server.
   */
  authFailedHandler(response) {
    this.busy$.next(false);
    this.authFailure.emit(response);
    this.resetFormAfterFailure();
  }

  /**
   * Resets the form appropriately after a failed login attempt.
   */
  resetFormAfterFailure() {
    const password = this.loginForm.get('password');
    if (password) {
      password.reset();
    }
    this.loginForm.markAsPristine();
  }

  /**
   * This method changes the auth type of the form,
   * which in turn will update the login form appropriately.
   *
   * @param authType The new auth type to switch to.
   */
  changeAuthMode(authType: AuthType) {
    if (this._currentAuthMode !== authType) {
      this.currentAuthMode = authType;
      this.updateAuthFormDisplay();
      this.authModeChanged.emit(authType);
    }
  }

  /**
   * This handles the event emitted from the
   * AuthModeToggle component when a user clicks
   * one of the buttons to toggle auth modes.
   *
   * @param authType The authType that was changed.
   */
  handleAuthModeChanged(authType: AuthType) {
    this.changeAuthMode(authType);
  }

  /**
   * This method updates the login form
   * based on the currentAuthMode.
   */
  private updateAuthFormDisplay() {
    const username = this.loginForm.get('username')?.value;
    this.loginForm = new FormGroup<LoginForm>(this.renderAuthModeControls());
    if (username) {
      this.loginForm.get('username').setValue(username);
    }
    this.cdr.markForCheck();
  }

  /**
   * This method handles generating the correct FormControls
   * for various authTypes.
   *
   * @param authType the authType to render the controls for.  defaults to currentAuthMode.
   * @returns
   */
  private renderAuthModeControls(authType: AuthType = this.currentAuthMode): LoginForm {
    switch (authType) {
      case 'sso':
        return this.renderAuthModeSsoForm();
      case 'ad':
        return this.renderAuthModeAdForm();
      case 'salesforce':
        return this.renderAuthModeSalesforceForm();
      default:
        return this.renderAuthModeLocalForm();
    }
  }

  /**
   * This method returns an empty form configuration since salesforce
   * javascript renders it's own form.
   *
   * @returns {} An empty object since form is rendered via salesforce script.
   */
  renderAuthModeSalesforceForm(): LoginForm {
    return {};
  }

  /**
   * This generates the FormControls for the local form
   *
   * @returns loginForm elements for local.
   */
  private renderAuthModeLocalForm(): LoginForm {
    return {
      username: new FormControl('', this.getStandardValidators()),
      password: new FormControl('', this.getStandardValidators()),
    };
  }

  /**
   * This generates the FormControls for the sso form
   *
   * @returns loginForm elements for sso.
   */
  private renderAuthModeSsoForm(): LoginForm {
    return {
      username: new FormControl('', [...this.getStandardValidators(), Validators.email]),
    };
  }

  /**
   * This generates the FormControls for the ad form.
   *
   * @returns loginForm elements for ad.
   */
  private renderAuthModeAdForm(): LoginForm {
    const rememberedAdCredentials = this.persistService.get(localStorageKey);
    return {
      username: new FormControl(rememberedAdCredentials?.username || '', this.getStandardValidators()),
      password: new FormControl('', this.getStandardValidators()),
      domain: new FormControl(rememberedAdCredentials?.domain || '', this.getStandardValidators()),
      rememberme: new FormControl(rememberedAdCredentials ? true : false),
    };
  }

  /**
   * Returns a set of standard validators for use with
   * all the input elements in the login form.
   *
   * @returns A set of standard validators.
   */
  private getStandardValidators() {
    return [Validators.required, Validators.maxLength(this.maxInputLength)];
  }

  /**
   * @todo implement for AD
   */
  getCachedDomain() {
    // var cachedDomain =
    //     (localStorageService.get('domain') || '').toLowerCase();
  }
}
