import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

import { AngularMaterialModule } from '../../angular-material.module';
import { DataIdModule } from '../data-id/data-id.module';
import { IconModule } from '../icon/icon.module';
import { TooltipChangeDetectionModule } from '../tooltip-change-detection/tooltip-change-detection.module';
import { DataTreeAutoSelectColumnComponent } from './data-tree-auto-select-column/data-tree-auto-select-column.component';
import { DataTreeDetailOutletComponent } from './data-tree-detail-outlet/data-tree-detail-outlet.component';
import { DataTreeDetailDirective } from './data-tree-detail.directive';
import { DataTreeNodeCheckboxComponent } from './data-tree-node-checkbox/data-tree-node-checkbox.component';
import { DataTreeNodeComponent } from './data-tree-node/data-tree-node.component';
import { DataTreeSelectAllCheckboxComponent } from './data-tree-select-all-checkbox/data-tree-select-all-checkbox.component';
import { DataTreeSelectComponent } from './data-tree-select/data-tree-select.component';
import { DataTreeTableRowExpandComponent } from './data-tree-table-row-expand/data-tree-table-row-expand.component';
import { DataTreeViewRepeaterDirective } from './data-tree-table/data-tree-repeater-strategy';
import { DataTreeTableComponent } from './data-tree-table/data-tree-table.component';
import { DataTreeComponent } from './data-tree.component';

const exportedComponents = [
  DataTreeAutoSelectColumnComponent,
  DataTreeComponent,
  DataTreeDetailDirective,
  DataTreeDetailOutletComponent,
  DataTreeNodeCheckboxComponent,
  DataTreeSelectComponent,
  DataTreeSelectAllCheckboxComponent,
  DataTreeTableComponent,
  DataTreeTableRowExpandComponent,
  DataTreeViewRepeaterDirective
];

@NgModule({
  imports: [
    AngularMaterialModule,
    NgxMatSelectSearchModule,
    CommonModule,
    IconModule,
    DataIdModule,
    ReactiveFormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
    TooltipChangeDetectionModule
  ],
  declarations: [...exportedComponents, DataTreeNodeComponent],
  exports: [...exportedComponents],
})
export class DataTreeModule {}
