// Tenant Service

;(function (angular, undefined) {
  'use strict';

  // The switchable tenant account object.
  var _selectedAccount;

  var _cachedTenants = [];

  // Cache the getTenants API call and the query params.
  var pendingRequest = {};

  angular.module('C.tenantService', [])
    .config(ConfigFn)
    .service('TenantService', TenantServiceFn)
    .service('AddTenantImpersonationHeader', AddTenantImpersonationHeaderFn);

  function TenantServiceFn(_, $http, API, SlideModalService, cModal, $q,
    $rootScope, PubJobServiceFormatter, $httpParamSerializer, $window,
    RemoteAccessClusterService, ViewBoxCacheService, CHART_COLORS, evalAJAX,
    NgTenantService, NgExternalConnectionServiceApi, FEATURE_FLAGS) {

    // subscribe to impersonatedTenant value changes which is kept with
    // tenant-service.ts as an observable.
    watchImpersonatedTenantChanges();

    return {
      addBifrostProxyModal: addBifrostProxyModal,
      addTenant: addTenant,
      assignADs: assignADs,
      assignJobs: assignJobs,
      assignLdaps: assignLdaps,
      assignPolicies: assignPolicies,
      assignSources: assignSources,
      assignSwiftConfig: assignSwiftConfig,
      assignTenantAdLdapModal: assignTenantAdLdapModal,
      assignTenantGroups: assignTenantGroups,
      assignTenantPoliciesModal: assignTenantPoliciesModal,
      assignTenantPrincipalsModal: assignTenantPrincipalsModal,
      assignTenantUsers: assignTenantUsers,
      assignTenantVlansModal: assignTenantVlansModal,
      assignViewBoxes: assignViewBoxes,
      assignViews: assignViews,
      assignVlans: assignVlans,
      deleteTenant: deleteTenant,
      extendWithEntityOwnerParams: extendWithEntityOwnerParams,
      extendWithTenantParams: extendWithTenantParams,
      getBifrostTenantsCapabilities: getBifrostTenantsCapabilities,
      getKeystones: getKeystons,
      getKeystoneById: getKeystoneById,
      getSelectedAccount: getSelectedAccount,
      getSelectedAccountId: getSelectedAccountId,
      getTenantById: getTenantById,
      getTenantByIds: getTenantByIds,
      getTenantConfigProxyUploadUrl: getTenantConfigProxyUploadUrl,
      getTenantProxies: getTenantProxies,
      getTenantProxy: getTenantProxy,
      getTenants: getTenants,
      getTenantsMap: getTenantsMap,
      getUserTenantInfo: getUserTenantInfo,
      impersonationHeader: 'X-IMPERSONATE-TENANT-ID',
      isTenantAccountSwitchable: isTenantAccountSwitchable,
      openDeleteTenantModal: openDeleteTenantModal,
      openSwitchAccountModal: openSwitchAccountModal,
      resolveSingleTenantDetails: resolveSingleTenantDetails,
      resolveTenantDetails: resolveTenantDetails,
      setSwitchedAccount: setSwitchedAccount,
      switchAccountHeader: 'X-SWITCH-TENANT-ID',
      toggleTenantStatus: toggleTenantStatus,
      transformTenant: transformTenant,
      updateTenant: updateTenant,
      getDefaultBifrostConnection: getDefaultBifrostConnection
    };

    /**
     * subscribe to impersonatedTenant value changes which is kept with
     * tenant-service.ts as an observable.
     *
     * @method   watchImpersonatedTenantChanges
     */
    function watchImpersonatedTenantChanges() {
      NgTenantService.impersonatedTenant$.asObservable().subscribe(
        function onChanges() {
          // burst the caches during impersonation because tenant may not have the
          // same viewboxes that SP admin had before impersonation.
          ViewBoxCacheService.viewBoxes = {};
        }
      );
    }

    /**
     * Returns the tenant proxy config upload url.
     *
     * @method   getTenantConfigProxyUploadUrl
     * @return   {String}    The tenant proxy config upload url.
     */
    function getTenantConfigProxyUploadUrl() {
      return 'http://[ip-address]:29994/upload';
    }

    /**
     * Returns the list of tenant proxies configured.
     *
     * @method   getTenantProxies
     * @param    {String}   [tenantId]   Optionally provide ID of the tenant for
     *                                   resource is needed else backend will
     *                                   extract tenant id from session user.
     * @return   {promise}    returns a promise resolved with tenant proxies
     *                        info or rejected with error
     */
    function getTenantProxies(tenantId) {
      return $http({
        method: 'GET',
        params: {ids: tenantId || undefined},
        url: API.public('tenants/proxies'),
      }).then(function gotTenants(resp) {
        return resp.data || [];
      });
    }

    /**
     * Download tenant proxy VM(image) or trust certificate x509.
     *
     * @method   getTenantProxy
     * @param    {String}   type               The file type config or image to
     *                                         download.
     * @param    {String}   [tenantId]         Optionally provide ID of the tenant for
     *                                         resource is needed else backend will
     *                                         extract tenant id from session user.
     * @param    {number}   [connectionId]     Optionally provide connection id to uniquely
     *                                         identify bifrost connection configuration.
     *
     * @param    {String}   [deploymentType]   Deployment target type for the hyx vm image.
     */
    function getTenantProxy(type, tenantId, connectionId, deploymentType) {
      tenantId = tenantId || NgTenantService.impersonatedTenantId;
      var swVersion;
      var downloadConfigUrl;

      // downloading VM image from support portal because VM image is too big
      // to be packaged with cohesity builds.
      if (type === 'image') {
        swVersion = $rootScope.clusterInfo._clusterSoftwareVersion;

        deploymentType = deploymentType || 'vmware';
        // cohesity_hyperv_bifrost_<branch_name>_<release>.zip
        const filenamePrefix = deploymentType === 'vmware' ? 'cohesity-hyx-' : 'cohesity_hyperv_bifrost_';
        const filenameSuffix = deploymentType === 'vmware' ? '.ova' : '.zip';

        $window.open([
          'https://downloads.cohesity.com/hidden/Hybrid-Extender/',
          swVersion.version + '/',
          filenamePrefix + swVersion.version,
          '_' + swVersion.buildType + filenameSuffix
        ].join(''));
        return;
      }

      const queryParams = $httpParamSerializer({
        id: tenantId || undefined,
        connectionId: connectionId || undefined
      });

      downloadConfigUrl =
        RemoteAccessClusterService.decorateApiPathForRemote(API.public([
          'tenants/proxy/config?',
          queryParams
        ].join('')));

      // validating the config before initiating the download.
      $http({
        method: 'GET',
        url: downloadConfigUrl,
        params: { validateOnly: true },
      }).then(function onSuccess() {
        $window.open(downloadConfigUrl);
      }, evalAJAX.errorMessage);
    }

    /**
     * Returns the list or tenants exist in the cluster
     *
     * @method   getTenants
     * @param    {String[]}   properties    Array of properties for the tenant.
     *                                      Could take the values ViewBox, Vlan,
     *                                      ProtectionPolicy, Entity
     * @param    {Object}     extraParams   Optional parameter for the API.
     *                                      eg. - {
     *                                              status: ['Active', 'Deleted'
     *                                                       ,'Deactive'],
     *                                            }
     * @return   {promise}    returns a promise resolved with tenant list or
     *                        rejected with error
     */
    function getTenants(properties, extraParams) {
      var params = {};

      // If it is not an array and is a boolean, the property gets passed down
      // the API. If it is undefined then it doesn't. So making this check will
      // ensure that if the properties is not an array, then make it by default
      // undefined.
      if (!_.isArray(properties)) {
        properties = undefined;
      }

      params = _.assign({properties: properties}, extraParams);

      return NgTenantService.impersonatedTenant ? $q.resolve(_cachedTenants) :
        _getTenants(params).then(function gotTenants(resp) {
          // If the list includes the deleted tenants then sort the list
          // by first active/deleted status and subsequently its alphabetically
          // sorted. By default it will be alphabeted.
          var tenants = _.chain(resp.data)
            .map(transformTenant)
            .orderBy(['_statusValue', '_nameLowerCase'], ['asc', 'asc'])
            .value();

          _cachedTenants = !NgTenantService.impersonatedTenant ? tenants : [];
          return tenants;
        });
    }

    /**
     * Get tenants details for provided tenant ids.
     *
     * @method   getTenantByIds
     * @param    {String[]}    tenantIds   The IDs of tenant to get.
     * @param    {Object}      params  Additional params for the tenant api.
     * @return   {Object}      Promise resolved with found tenants else rejected
     *                         with error
     */
    function getTenantByIds(tenantIds, params) {
      params = {
        ...(params || {}),
        ids: tenantIds,
      };

      return _getTenants(params).then(function gotTenants(resp) {
        let tenantsInfo = [];
        // Need to put in these check due to weird bug seen - ENG-326057
        // where backend was sending empty response i.e. '' as data.
        if (resp.data && Array.isArray(resp.data) && resp.data.length) {
          tenantsInfo = resp.data;
        }
        return tenantsInfo.map(transformTenant);
      });
    }

    /**
     * Wrap the get tenants API call to fix multiple API calls by caching the
     * already pending call.
     *
     * @method   _getTenants
     * @param    {Object}   params   The original query params.
     * @return   {Object}   The API promise, either a new one or the cached one.
     */
    function _getTenants(params) {
      // Return the pending request promise if it exists to save the extra API
      // calls.
      if (pendingRequest.promise && _.isEqual(pendingRequest.params, params)) {
        return pendingRequest.promise;
      }

      // Cache the query params.
      pendingRequest.params = params;

      pendingRequest.promise = $http({
          method: 'GET',
          url: API.public('tenants'),
          params: params,
        }).then(function gotTenants(resp) {
          if (params._includeBifrostCapabilities) {
            const bifrostEnabledTenantIds = _.chain(resp.data).filter({ bifrostEnabled: true }).map('tenantId').value();

            if (bifrostEnabledTenantIds.length) {
              return getBifrostTenantsCapabilities(bifrostEnabledTenantIds).then(
                ({ tenantCapabilities, clusterCapabilities }) => {
                resp.data.forEach(tenant => {
                    tenant._bifrostCapabilities = tenantCapabilities ?
                      (tenantCapabilities[tenant.tenantId] || {}) : {};
                    tenant._clusterCapabilities = clusterCapabilities || {};
                });
                return resp;
              });
            }
          }

          return resp;
        }).finally(function finallyGotData() {
          pendingRequest = {};
        }).onAbort(function onRequestAbort() {
          // perform cleanups when request is aborted
          pendingRequest = {};
        });

      return pendingRequest.promise;
    }

    /**
     * Get all tenants or provided tenant details map with key are tenantId and
     * value as the details
     *
     * @method   getTenantByIds
     * @param    {String[]}    tenantIds   The IDs of tenant to get.
     * @param    {Object}      params  Additional params for the tenant api.
     * @return   {Object}      Promise resolved with found tenants else rejected
     *                         with error
     */
    function getTenantsMap(tenantIds, params) {
      return getTenantByIds(tenantIds, params)
        .then(function gotTenants(tenants) {
          return _.keyBy(tenants, 'tenantId');
        });
    }

    /**
     * Gets the active tenant info for the user that has logged into the tenant.
     *
     * @method    getTenantInfo
     * @returns   {Object}   The tenant name and tenantId of the active tenant.
     */
    function getUserTenantInfo() {
      return _.find($rootScope.user.orgMembership,
        ['tenantId', $rootScope.user.tenantId]);
    }

    /**
     * resolve the tenantId with tenant details from provided map.
     *
     * TODO(veetesh): UI should not resolve the tenant info for remote
     * clusters since backend is planing to do that in 6.1.1 hence this
     * ugly hack https://cohesity.atlassian.net/browse/ENG-42096
     *
     * @param   {Object[]}  list   The list of items having tenantId that need
     * to resolved with tenant details.
     * @param   {String}    [pathToTenantId='tenantId']   The path to reach
     * tenantId inside a item in the list and default considered as 'tenantId'.
     * @param   {Object}    Promise resolved with resolved list of tenant info
     *                      if present else rejected with error
     */
    function resolveTenantDetails(list, pathToTenantId) {
      pathToTenantId = pathToTenantId || 'tenantId';

      // construct path to reach tenantInfo or tenantInfoList.
      var parts = pathToTenantId.split('.');
      parts.pop();
      var pathToTenantInfo = parts.concat('_tenant').join('.');
      var pathToTenantInfoList = parts.concat('_tenants').join('.');

      // early exit when list can not be resolved or list is undefined.
      if(!$rootScope.user.privs.ORGANIZATION_VIEW || !_.get(list, 'length')) {
        return $q.resolve(list);
      }

      var tenantIdHash = {};
      list.forEach(function eachListItem(item) {
        var tenantIds = [].concat(_.get(item, pathToTenantId));
        tenantIds.forEach(function eachTenantId(tenantId) {
          tenantId && (tenantIdHash[tenantId] = true);
        });
      });

      var tenantIds = Object.keys(tenantIdHash);
      var params = {
        status: ['Active', 'Deleted', 'Deactivated'],
        // Skip any non-existant tenantIds instead of throwing
        skipInvalidIds: true
      };

      return getTenantsMap(tenantIds, params).then(
        function gotTenantsMap(tenantsMap) {
          /**
           * Return tenant info for provided tenantId from received tenantsMap.
           *
           * @method  _getTenantInfo
           * @param   {String}   tenantId   The tenantId.
           * @return  {Object}   Return tenant info for provided tenantId.
           */
          function _getTenantInfo(tenantId) {
            return tenantsMap[tenantId] || (tenantId && { tenantId });
          }

          // loop over list and add tenantInfo.
          list.forEach(function eachItem(item) {
            var tenantIds = _.get(item, pathToTenantId);

            // resolving multiple tenantIds.
            if (_.isArray(tenantIds)) {
              _.set(item, pathToTenantInfoList, tenantIds.map(_getTenantInfo));
            } else {
              _.set(item, pathToTenantInfo, _getTenantInfo(tenantIds));
            }

            return item;
          });

          return list;
      });
    }

    /**
     * resolve the tenantId with tenant details from provided item.
     *
     * @param   {Object[]}  item   The item having tenantId that need to
     * resolved with tenant details.
     * @param   {String}    [pathToTenantId='tenantId']   The path to reach
     * tenantId inside a item in the list and default considered as 'tenantId'.
     * @param   {Object}    Promise resolved with tenant info.
     */
    function resolveSingleTenantDetails(item, pathToTenantId) {
      return resolveTenantDetails([item], pathToTenantId).then(
        function gotTenantDetailsResolved(items) {
          return items[0];
        }
      );
    }

    /**
     * Get an tenant details for provided tenant id.
     *
     * @method   getTenantById
     * @param    {String}     tenantId   The ID of the tenant to get.
     * @param    {Object}     params     Additional params for the tenant api.
     * @return   {Object}     Promise resolved with found tenant else rejected
     *                        with error
     */
    function getTenantById(tenantId, params) {
      return getTenantByIds([tenantId], params).then(tenantList => tenantList[0]);
    }

    /**
     * Add the provided tenant in the cluster.
     *
     * @method   addTenant
     * @param    {Object}   tenant   The tenant to add.
     * @return   {Object}   Promise resolved with created tenant else rejected
     *                      with error
     */
    function addTenant(tenant) {
      return $http({
        method: 'POST',
        url: API.public('tenants'),
        data: tenant,
      }).then(function gotTenant(resp) {
        return transformTenant(resp.data || {});
      });
    }

    /**
     * Update an existing tenant with updated settings.
     *
     * @method   updateTenant
     * @param    {Object}   tenant   The tenant to update.
     * @return   {Object}   Promise resolved with updated tenant else rejected
     *                      with error
     */
    function updateTenant(tenant) {
      return $http({
        method: 'PUT',
        url: API.public('tenants'),
        data: tenant,
      }).then(function gotTenant(resp) {
        return transformTenant(resp.data || {});
      });
    }

    /**
     * Deletes a tenant from the cluster.
     *
     * @method   deleteTenant
     * @param    {Object}   tenant   The tenant to remove.
     * @return   {Object}   Promise resolved with success on delete success
     *                      else rejected with error
     */
    function deleteTenant(tenant) {
      return $http({
        method: 'DELETE',
        url: API.public('tenants'),
        data: {
          tenantId: tenant.tenantId,
        },
      });
    }

    /**
     * Assign Active Directories to the tenant.
     *
     * @method   assignADs
     * @param    {String}   tenantId      ID of the tenant to modify.
     * @param    {Array}    domainNames   Updated AD domainNames list.
     * @return   {Object}   Promise resolved on updates success else rejected
     *                      with error.
     */
    function assignADs(tenantId, domainNames) {
      return $http({
        method: 'PUT',
        url: API.public('tenants/activeDirectory'),
        data: {
          tenantId: tenantId,
          activeDirectoryDomains: domainNames,
        },
      }).then(function gotAssignedADs(res) {
        return res.data || [];
      });
    }

    /**
     * Assign LDAPs to the tenant.
     *
     * @method   assignLdaps
     * @param    {String}   tenantId   ID of the tenant to modify.
     * @param    {Array}    ldapIds    Updated LDAP ids list.
     * @return   {Object}   Promise resolved on updates success else rejected
     *                      with error.
     */
    function assignLdaps(tenantId, ldapIds) {
      return $http({
        method: 'PUT',
        url: API.public('tenants/ldapProvider'),
        data: {
          tenantId: tenantId,
          ldapProviderIds: ldapIds,
        },
      }).then(function gotAssignedADs(res) {
        return res.data || [];
      });
    }

    /**
     * Assign swift config(keystone and swift roles) to the tenant.
     *
     * @method   assjgnSwiftConfig
     * @param    {String}   tenantId   ID of the tenant to modify.
     * @param    {number}   keystoneId    Updated keystone id.
     * @param    {array}    operatorRoles    Updated Swift role list.
     * @return   {Object}   Promise resolved on updates success else rejected
     *                      with error.
     */
    function assignSwiftConfig(tenantId, keystoneId, operatorRoles) {
      return $http({
        method: 'PUT',
        url: API.publicV2('tenants/swift'),
        data: {
          tenantId: tenantId,
          keystoneId: keystoneId,
          operatorRoles: operatorRoles,
        },
      }).then(function gotAssignedADs(res) {
        return res.data || {};
      });
    }

    /**
     * Assign the users to the provided tenant
     *
     * @method   assignTenantUsers
     * @param    {String}   tenantId   ID of the tenant to modify
     * @param    {Array}    sids       Updated users sids list
     * @return   {Object}   Promise resolved on updates success else rejected
     *                      with error.
     */
    function assignTenantUsers(tenantId, sids) {
      return $http({
        method: 'PUT',
        url: API.public('tenants/users'),
        data: {
          sids: sids,
          tenantId: tenantId,
        },
      }).then(function gotAssignedUsers(resp) {
        return resp.data || [];
      });
    }

    /**
     * Assign the groups to the provided tenant
     *
     * @method   assignTenantGroups
     * @param    {String}   tenantId   ID of the tenant to modify
     * @param    {Array}    sids       Updated groups sids list
     * @return   {Object}   Promise resolved on updates success else rejected
     *                      with error.
     */
    function assignTenantGroups(tenantId, sids) {
      return $http({
        method: 'PUT',
        url: API.public('tenants/groups'),
        data: {
          sids: sids,
          tenantId: tenantId,
        },
      }).then(function gotAssignedUsers(resp) {
        return resp.data || [];
      });
    }

    /**
     * Assign the ViewBoxes to the tenant.
     *
     * @method   assignViewBoxes
     * @param    {String}   tenantId   ID of the tenant to modify
     * @param    {Array}    viewBoxIds Updated viewBoxes IDs list
     * @return   {Object}   Promise resolved with tagged viewboxIds else
     *                      rejected with an error.
     */
    function assignViewBoxes(tenantId, viewBoxIds) {
      return $http({
        method: 'PUT',
        url: API.public('tenants/viewBox'),
        data: {
          viewBoxIds: viewBoxIds,
          tenantId: tenantId,
        }
      }).then(function gotViewBoxes(resp) {
        return (resp.data.viewBoxIds || []);
      });
    }

    /**
     * Assign the VLANS to the tenant.
     *
     * @method   assignVlans
     * @param    {String}   tenantId   ID of the tenant to modify
     * @param    {Array}    vlanIfaceNames    Updated vlans names list
     * @return   {Object}   Promise resolved with tagged vlanIfaceNames else
     *                      rejected with an error.
     */
    function assignVlans(tenantId, vlanIfaceNames, headers) {
      return $http({
        method: 'PUT',
        url: API.public('tenants/vlan'),
        data: {
          vlanIfaceNames: vlanIfaceNames,
          tenantId: tenantId,
        },
        headers: headers,
      }).then(function gotVlans(resp) {
        return (resp.data.vlanIfaceNames || []);
      });
    }

    /**
     * Assign the Views to the tenant.
     *
     * @method   assignViews
     * @param    {String}   tenantId   ID of the tenant to modify
     * @param    {Array}    viewNames  Updated view names list
     * @return   {Object}   Promise resolved with tagged views else
     *                      rejected with an error.
     */
    function assignViews(tenantId, viewNames) {
      return $http({
        method: 'PUT',
        url: API.public('tenants/view'),
        data: {
          viewNames: viewNames,
          tenantId: tenantId,
        }
      }).then(function gotViews(resp) {
        return (resp.data.viewNames || []);
      });
    }

    /**
     * Assign the Policies to the tenant.
     *
     * @method   assignPolicies
     * @param    {String}   tenantId   ID of the tenant to modify
     * @param    {Array}    policyIds  Updated polices IDs list
     * @return   {Object}   Promise resolved with tagged policyIds else
     *                      rejected with an error.
     */
    function assignPolicies(tenantId, policyIds) {
      return $http({
        method: 'PUT',
        url: API.public('tenants/policy'),
        data: {
          policyIds: policyIds,
          tenantId: tenantId,
        }
      }).then(function gotPolicies(resp) {
        return (resp.data.policyIds || []);
      });
    }

    /**
     * Assign the Jobs to the tenant.
     *
     * @method   assignJobs
     * @param    {String}   tenantId   ID of the tenant to modify
     * @param    {Array}    jobs       Updated jobs list
     * @return   {Object}   Promise resolved with tagged policyIds else
     *                      rejected with an error.
     */
    function assignJobs(tenantId, jobs) {
      return $http({
        method: 'PUT',
        url: API.public('tenants/protectionJob'),
        data: {
          protectionJobIds: _.map(jobs, 'id'),
          tenantId: tenantId,
        }
      }).then(function gotJobs(resp) {
        return (resp.data.protectionJobs || []);
      });
    }

    /**
     * Assign the Sources with tenant.
     *
     * @method   assignSources
     * @param    {String}   tenantId   ID of the tenant to modify
     * @param    {Array}    entityIds  Updated entity IDs list
     * @return   {Object}   Promise resolved with tagged entityIds else
     *                      rejected with an error.
     */
    function assignSources(tenantId, entityIds) {
      return $http({
        method: 'PUT',
        url: API.public('tenants/entity'),
        data: {
          entityIds: entityIds,
          tenantId: tenantId,
        }
      }).then(function gotPolicies(resp) {
        return (resp.data.entityIds || []);
      });
    }

    /**
     * Toggles the active status for the tenant. According to the active status,
     * Tenant login is enabled/disabled and jobs are paused/unpaused.
     *
     * @method   toggleTenantStatus
     * @param    {Object}   tenant   The tenant to activate.
     * @return   {Object}   Promise resolved with updated tenant on
     *                      activation/deactivation success
     *                      else rejected with error
     */
    function toggleTenantStatus(tenant) {
      // Just pass the required param tenant name, as tenant object may get huge
      var context = {
        name: tenant.name,
      };

      // Clone the object as assign just changes the active status on click.
      return cModal.standardModal({}, {
        titleKey: tenant.active ?
          'access.modal.specific.tenant.deactivate.title' :
          'access.modal.specific.tenant.activate.title',
        contentKey: tenant.active ?
          'access.modal.specific.tenant.deactivate.text' :
          'access.modal.specific.tenant.activate.text',
        actionButtonKey: tenant.active ? 'deactivate' : 'activate',
        titleKeyContext: context,
        contentKeyContext: context,
        closeButtonKey: 'cancel',
        truncateHeader: true,
      }).then(function proceedToggling() {
        var tenantObject = {
          tenantId: tenant.tenantId,
          active: !tenant.active,
          name: tenant.name,
        };
        return updateTenant(tenantObject);
      });
    }

    /**
     * Opens the tenant deletion modal to delete the tenant.
     *
     * @method   openDeleteTenantModal
     * @param    {Object}   tenant   The tenant to be deleted.
     * @return   {Object}   Promise resolved with tenant deletion success
     *                      else rejected with error
     */
    function openDeleteTenantModal(tenant) {
      var modalConfig = {
        templateUrl:
          'app/admin/access-management/tenants/delete-tenant-modal/delete-tenant-modal.html',
        controller: 'TenantDeleteModalCtrl',
        resolve: {
          tenant: function resolveTenant() {
            return _.pick(tenant, ['name', 'tenantId']);
          },
        },
      };

      var modalOptions = {
        titleKey: 'access.modal.specific.tenant.delete.title',
        actionButtonKey: 'delete',
        titleKeyContext: {
          name: tenant.name,
        },
        closeButtonKey: 'cancel',
        truncateHeader: true,
      };

      return cModal.standardModal(modalConfig, modalOptions);
    }

    /**
     * Transform tenant data with derived & helper properties for faster lookups
     *
     * @method   transformTenant
     * @param    {Object}   tenant   The tenant to transform
     * @return   {Object}   transformed tenant object.
     */
    function transformTenant(tenant) {
      var out = {
        deletionFinished: !!tenant.deletionFinished,

        // default tenant properties
        clusterHostname: undefined,
        clusterIps: [],
        entityIds: [],
        policyIds: [],
        protectionJobs: [],
        tenantIpWhitelists: [],
        viewBoxIds: [],
        views: [],
        vlanIfaceNames: [],

        // enable service provider to receive all tenants alerts emails.
        // TODO(veetesh): remove below commented code when backend enables
        // modifying tenant emails configuration and it would be considered
        // default enabled in 6.1.1
        // https://cohesity.atlassian.net/browse/ENG-49345
        // receiveTenantAlertEmails: true,
      };

      var activeStatus = tenant.hasOwnProperty('active') ? tenant.active : true;

      _.assign(out, tenant, {
        active: activeStatus,
        _color: activeStatus ? CHART_COLORS.green : CHART_COLORS.yellow,
        _statusKey: activeStatus ? 'active' : 'inactive',
        _statusValue: activeStatus ? 1 : 2,

        // testing against boolean true to ensure bifrost is already enable or
        // not.
        _isBifrostAlreadyEnabled: tenant.bifrostEnabled === true,
        _bifrostCapabilities: tenant._bifrostCapabilities || {},
        _nameLowerCase: (tenant.name || '').toLowerCase(),
      });

      if (tenant.deleted) {
        out._color = CHART_COLORS.red;
        out._statusKey = tenant.deletionFinished ? 'deleted' :
          'tenants.markedForDeletion';
        out._statusValue = tenant.deletionFinished ? 3 : 4;
      }

      return out;
    }

    /**
     * Opens the assign tenant AD/LDAP modal
     *
     * @method   assignTenantAdLdapModal
     * @param    {String}   tenantId           ID of the tenant to modify
     * @param    {Object}   selectedADs        Selected ADs map
     * @param    {Object}   selectedLdaps      Selected LDAPs map
     * @param    {Object}   selectedKeystone   Selected Keystone map
     * @param    {Object}   keystoneList       All available Keystones
     * @param    {Object}   tenantPrincipals   Tenant assigned principals list.
     * @return   {Object}   Promise resolved with selected users
     */
    function assignTenantAdLdapModal(
      tenantId, selectedADs, selectedLdaps, selectedKeystone, keystoneList, tenantPrincipals) {
      var modalConfig = {
        component: 'tenantAdLdapModal',
        size: 'xl',
        resolve: {
          selectedADs: selectedADs,
          selectedLdaps: selectedLdaps,
          selectedKeystone: selectedKeystone,
          keystoneList: keystoneList,
          tenantId: tenantId,
          tenantPrincipals: { principals: tenantPrincipals || [] },
        },
      };

      return SlideModalService.newModal(modalConfig);
    }

    /**
     * Open the assign tenant principals modal.
     *
     * @method   assignTenantPrincipalsModal
     * @param    {Object}     selectedUsers        Selected users map
     * @param    {Object}     tenant      The tenant which assign user/groups to.
     * @return   {Object}    Promise resolved with selected users
     */
    function assignTenantPrincipalsModal(selectedUsers, tenant) {
      /**
       * Check if the principal can be selected or not.
       *
       * @method   _canSelectPrincipal
       * @param    {Object}   principal   The principal to be checked.
       * @return   {Boolean}  True, if selectable and vice-versa.
       */
      function _canSelectPrincipal(principal) {
        // For multi-tenancy, restricted users and users with no roles are
        // disabled for selection.
        return !principal.restricted && !_.isEmpty(principal.roles);
      }

      var modalConfig = {
        size: 'xl',
        keyboard: false,
        resolve: {
          innerComponent: 'userGroups',
          idKey: 'assign-tenant-principals',
          actionButtonKey: 'assign',
          titleKey: 'tenants.assignUserGroups',
          bindings: {
            ngModel: selectedUsers,
            navigationType: 'modal',
            canSelectPrincipal: _canSelectPrincipal,
            allowUserCreation: true,
            allowModifications: true,
            tenantInformation: tenant,
            purpose: 'tenant-assignment',
          },
        },
      };

      return SlideModalService.newModal(modalConfig)
        .then(function gotTenantPrincipals(res) {
          // Return only the ngModel (the selected principals).
          return res.ngModel;
        });
    }

    /**
     * Opens the assign tenant policies modal
     *
     * @method   assignTenantPoliciesModal
     * @param    {Array}      jobsList               The selected jobs list
     * @param    {Object}     sourceTree             The selected source tree
     * @param    {Array}      policiesList           The selected policies list
     * @param    {Array}      viewboxesList          The selected viewboxes list
     * @param    {Array}      views                  The selected views list
     * @param    {Function}   markPolicyForRemoval   The callback fn used to
     * determine if policy should be marked for removal else remove it for
     * selected list of policy
     * @return   {Object}  Promise resolved with selected policies and jobs
     */
    function assignTenantPoliciesModal(jobsList, sourceTree, policiesList,
      viewboxesList, viewsList, markPolicyForRemoval) {
      var modalConfig = {
        component: 'tenantPoliciesModal',
        size: 'xl',
        resolve: {
          selectedJobsMap: _.keyBy(jobsList, 'id'),
          sourceIds: function getSourceIds() {
            var sourceIds = [];

            PubJobServiceFormatter.forEachNode(
              sourceTree,
              function eachNode(node) {
                sourceIds.push(node.protectionSource.id);

                if (node.applicationNodes && node.applicationNodes.length) {
                  PubJobServiceFormatter.forEachNode(node.applicationNodes, appNode => {
                    sourceIds.push(appNode.protectionSource.id);
                  });
                }
              }
            );

            return sourceIds;
          },
          selectedPoliciesMap: _.keyBy(policiesList, 'id'),
          selectedViewboxesIds: function getViewboxesIds() {
            return _.map(viewboxesList, 'id');
          },
          selectedViewMagnetoIds: function getViewIds() {
            return _.map(viewsList, 'viewProtection.magnetoEntityId');
          },
          markPolicyForRemoval: function getMarkPolicyForRemovalFn() {
            return markPolicyForRemoval;
          },
        },
      };

      return SlideModalService.newModal({
        ...modalConfig,
        useNativeMerge: true
      });
    }

    /**
     * Get all keystones using V2 api.
     *
     *  @method    getKeystons
     *  @returns   {Object}   Promise resolved with all available keystones else
     *                        rejected with an error.
     */
    function getKeystons() {
      return $http({
        method: 'GET',
        params: {includeTenants: true},
        url: API.publicV2('keystones'),
      }).then(function gotKeystones(resp) {
        return resp.data || [];
      });
    }

    /**
     * Get keystone detail by ID using V2 api.
     *
     *  @method    getKeystoneById
     *  @param     {number}   keyStoneId   ID of the keystone.
     *  @returns   {Object}   Promise resolved with keystone object else
     *                        rejected with an error.
     */
    function getKeystoneById(keyStoneId) {
      return $http({
        method: 'GET',
        url: API.publicV2('keystones/'+keyStoneId),
      }).then(function gotKeystones(resp) {
        return resp.data;
      });
    }

    /**
     * Gets the switched tenant object. Returns the default tenant (which the
     * user has logged into) by default.
     *
     * @method    getSelectedAccount
     * @returns   {Object}   The tenant the user has switched to.
     */
    function getSelectedAccount() {
      return _selectedAccount || getUserTenantInfo();
    }

    /**
     * Gets the switched tenantId. Defaults to the user tenantId.
     *
     * @method    getSelectedAccountId
     * @returns   {String}   The switched tenant ID.
     */
    function getSelectedAccountId() {
      return _.get(getSelectedAccount(), 'tenantId');
    }

    /**
     * Sets the switched tenant when the tenant user switches the tenant.
     *
     * @method   setSwitchedAccount
     */
    function setSwitchedAccount(tenant) {
      _selectedAccount = tenant;
    }

    /**
     * Check if the tenant user has multiple tenants to switch from.
     *
     * @method   isTenantAccountSwitchable
     */
    function isTenantAccountSwitchable() {
      return FEATURE_FLAGS.enableAccountSwitching &&
        _.get($rootScope.user, '_hasMultipleAccounts');
    }

    /**
     * Opens the impersonation modal which handles the case for both
     * impersonation and tenant account switching.
     *
     * @method    openSwitchAccountModal
     * @returns   {Object}   Promise resolved with modal data.
     */
    function openSwitchAccountModal() {
      var modalConfig = {
        size: 'smd',
        controller: 'impersonationModalCtrl',
        templateUrl: 'app/global/nav/impersonation-modal.html',
      };

      var options = {
        actionButtonKey: 'switch',
        titleKey: isTenantAccountSwitchable() ? 'switch' : 'impersonate',
      };

      return cModal.standardModal(modalConfig, options);
    }

    /**
     * launch assign tenant VLANs modal
     *
     * @method   assignTenantVlansModal
     * @param    {Array}    selectedVlans   Selected VLANs list
     * @param    {Object}   tenant          The tenant.
     * @return   {Object}   Promise resolved with selected VLANs
     */
    function assignTenantVlansModal(selectedVlans, tenant) {
      /**
       * Determine whether a vlan can be selected or not and used to disable
       * vlans w/o VIPs assignment to tenant. Note for tenants with bifrost
       * enabled, we allow vlans w/o VIPs.
       *
       * @method   _canSelectVlan
       * @param    {Object}   vlan   The vlan to be checked.
       * @return   {Boolean}  True, if selectable else false.
       */
      function _canSelectVlan(vlan) {
        // vlan shared with all tenants can't be un-selected because it assumed
        // to be explicitly shared with all tenants.
        if (vlan.allTenantAccess) {
          return false;
        }
        return tenant.bifrostEnabled || !!_.get(vlan, 'ips.length', 0);
      }

      /**
       * Return the tooltip translation key when vlan is not selectable.
       *
       * @method   _getDisableTooltip
       * @param    {Object}   vlan   The vlan.
       * @return   {String}  The tooltip translation key.
       */
      function _getDisableTooltip(vlan) {
        if (vlan.allTenantAccess) {
          return 'tenants.disabledSharedVlanSelection';
        }
        return 'tenants.disabledVlanSelection';
      }

      /**
       * Filter out vlans which can't be assigned to to the tenant.
       *
       * @method   _filterVlans
       * @param    {Object}   vlan   The vlan.
       * @return   {Boolean}  Return True if vlan can be assigned else false.
       */
      function _filterVlans(vlan) {
        // only allow un-assigned vlans or vlans which are already assigned to
        // the tenant.
        return !vlan.tenantId ||
          vlan.tenantId === tenant.tenantId ||
          vlanUids.includes(vlan._uid);
      }

      // collect the assignable vlans uids.
      var vlanUids = _.map(selectedVlans || {}, function eachVlan(vlan) {
        return vlan.allTenantAccess ? null : vlan._uid;
      }).filter(Boolean);
      var modalConfig = {
        size: 'xl',
        resolve: {
          innerComponent: 'vlanList',
          actionButtonKey: 'assign',
          idKey: 'tenant-vlans',
          titleKey: 'tenants.assignVlans',
          bindings: {
            noFilters: true,
            allowAddVlan: true,
            allowEditVipsInline: true,
            ngModel: selectedVlans || {},
            filterVlans: _filterVlans,
            canSelectVlan: _canSelectVlan,
            getDisableTooltip: _getDisableTooltip,
          },
        },
      };

      return SlideModalService.newModal(modalConfig)
        .then(function getSelectedVlans(res) {
          // Return only the ng-model value.
          return res.ngModel;
        });
    }

    /**
     * Opens a modal showing steps to add bifrost proxy.
     *
     * @method   addBifrostProxyModal
     * @param    {Array}    instructions   The list of instructions to render
     *                                     inside the cInstructions component.
     * @return   {Object}   Promise resolved on modal close.
     */
    function addBifrostProxyModal(instructions) {
      var modalConfig = {
        size: 'xl',
        windowClass: 'c-slide-modal-window',
        autoHeight: true,
        resolve: {
          innerComponent: 'cInstructions',
          idKey: 'tenant-add-bifrost',
          actionButtonKey: null,
          closeButtonKey: null,
          titleKey: 'bifrostSettings.howToConfigureModal',
          bindings: {
            instructions: instructions,
          },
        },
      };

      return SlideModalService.newModal(modalConfig);
    }

    /**
     * Extend provided params with get own entity params.
     * entity likes job, polices, viewboxes, view, source, remote-connection etc
     *
     *
     * @method   extendWithEntityOwnerParams
     * @param    {object}   params      The request prams.
     * @return   {object}   Return modified params with get own entity params.
     */
    function extendWithEntityOwnerParams(params) {
      return _.assign(params, {allUnderHierarchy: false});
    }

    /**
     * Extend provided params with tenants params.
     *
     * @method   extendWithTenantParams
     * @param    {object}   params      API filter parameters to extend.
     * @param    {Array}    tenantIds   A list of tenant Ids.
     * @return   {object}   Return modified params with tenants params.
     */
    function extendWithTenantParams(params, tenantIds) {
      // Return the incoming params when MT is disabled.
      if (!$rootScope.clusterInfo ||
        !$rootScope.clusterInfo.multiTenancyEnabled) {
        return params;
      }

      return _.assign(params, {
        tenantIds: tenantIds && tenantIds.length ? tenantIds : undefined,

        // allUnderHierarchy is set to true always to access every entity which
        // are a part of SP admin or the SP admin's tenants.
        allUnderHierarchy: true,
      });
    }

    /**
     * Get bifrost tenant Capabilities
     *
     * @method   getBifrostTenantsCapabilities
     * @param    {Array}    tenantIds   A list of tenant Ids.
     * @return   {Object}   Promise resolved with tenant capabilities
     *                        for provided tenant ids and also all the
     *                        capabilities supported by the cluster.
     */
    function getBifrostTenantsCapabilities(tenantIds) {
      return $http({
        method: 'GET',
        url: API.private('bifrostTenantCapabilities'),
        params: { tenantIds: [].concat(tenantIds) },
      }).then(response => {
        const { data: { tenantCapabilities, allClusterCapabilities }} = response;

        return {
          // map of capabilities by tenant ID
          tenantCapabilities: tenantCapabilities?.reduce((out, current) => {
            const capabilitiesMap = (current.capabilities || []).reduce((capabilitiesMapOut, capability) => {
              return { ...capabilitiesMapOut, [capability]: true };
            }, {});
            return { ...out, [current.tenantId]: capabilitiesMap };
          }, {}),

          // all capabilities supported by the cluster
          clusterCapabilities: allClusterCapabilities?.reduce((capabilitiesMapOut, capability) => {
            return { ...capabilitiesMapOut, [capability]: true };
          }, {}),
        };
      });
    }

    /**
     * This method fetches the default bifrost connection details for a given
     * tenant id.
     *
     * @method  getDefaultBifrostConnection
     * @param   {number}        tenantId for which bifrost connection is to be fetched.
     * @returns {number | null}          A always resolved Promise with default bifrost
     *                                   connection or null value.
     */
    function getDefaultBifrostConnection(tenantId) {
      if (!FEATURE_FLAGS.enableHyxRealmClusterCompatChanges) {
        return Promise.resolve(null);
      }

      return new Promise((res) => {
        const defaultBifrostConnection = NgExternalConnectionServiceApi.GetBifrostConnection({
          tenantId,
          defaultConnectionOnly: true
        }).toPromise();

        return defaultBifrostConnection.then(
          (resp) => resp.BifrostConnections
        ).then(
          (list) => {
            if (list && list.length) {
              return res(list[0]);
            }
            return res(null);
          }
        ).catch(() => res(null));
      });
    }
  }

  /**
   * Config fn to add interceptor for adding impersonation tenant id in the
   * header.
   *
   * @method   ConfigFn
   * @param    {Object}   $httpProvider  The httpProvider object.
   */
  function ConfigFn($httpProvider) {
    $httpProvider.interceptors.push('AddTenantImpersonationHeader');
  }

  /**
   * Modifies the request header by adding required header to tell backend to
   * execute the following request as a tenant.
   *
   * @method   AddTenantImpersonationHeaderFn
   * @param    {Object}   $injector  The injector used to get service references
   */
  function AddTenantImpersonationHeaderFn($injector) {
    var $rootScope;
    var NgTenantService;
    var TenantService;

    return {
      request: function addRequestHeaders(requestConfig) {
        var headers = requestConfig.headers;

        $rootScope = $rootScope || $injector.get('$rootScope');
        NgTenantService = NgTenantService || $injector.get('NgTenantService');
        TenantService = TenantService || $injector.get('TenantService');

        if (NgTenantService.impersonatedTenant &&
          !headers.hasOwnProperty(TenantService.impersonationHeader)) {
          headers[TenantService.impersonationHeader] =
            NgTenantService.impersonatedTenant.tenantId;
        } else if (TenantService.isTenantAccountSwitchable() &&
          !headers.hasOwnProperty(TenantService.switchAccountHeader)) {
          headers[TenantService.switchAccountHeader] =
            TenantService.getSelectedAccountId();
        }

        return requestConfig;
      },
    };
  }
})(angular);
