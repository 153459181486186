import { Injectable } from '@angular/core';
import { TagCategory, TagSubCategory, TagType } from '@cohesity/api/helios-metadata';
import { SearchServiceApi } from '@cohesity/api/v2';
import { Observable, of } from 'rxjs';
import {
  ClassificationStatus,
  FilterFieldOption,
  IFieldFilterService,
  classificationStatusLabelKeys,
  classificationTagNames,
} from '../../constants';
import { getCombinedUniqueString, getExcludedTagNames } from '../../utils';

@Injectable({
  providedIn: 'root',
})
export class ClassificationFilterService implements IFieldFilterService {
  /**
   * static list of classification filter options
   */
  private classificationStatusValueOptions: FilterFieldOption[] = Object.values(ClassificationStatus)
    .filter((status) => ![ClassificationStatus.noScans, ClassificationStatus.unknown].includes(status))
    .map((status) => ({
      key: status,
      labelKey: classificationStatusLabelKeys[status],
    }));

  /**
   * classification status filter value generator.
   *
   * @returns observable with filter options
   */
  getFilterOptions(): Observable<FilterFieldOption[]> {
    return of(this.classificationStatusValueOptions);
  }

  /**
   * consolidates classification filters into a format that API understands
   *
   * @param selectedValues currently applied classification filters
   * @param appliedApiFilters currently applied api filters
   * @returns classification filter that API understands
   */
  transformFilterValues(
    selectedValues: string[],
    appliedApiFilters: SearchServiceApi.SearchObjectsParams
  ): SearchServiceApi.SearchObjectsParams {
    const apiFilters: SearchServiceApi.SearchObjectsParams = {};

    if (selectedValues.length) {
      apiFilters.tagTypes = [getCombinedUniqueString(appliedApiFilters.tagTypes, TagType.System)];
      apiFilters.tagCategories = [getCombinedUniqueString(appliedApiFilters.tagCategories, TagCategory.Security)];
      apiFilters.tagSubCategories = [
        getCombinedUniqueString(appliedApiFilters.tagSubCategories, TagSubCategory.Classification),
      ];
      apiFilters.dataClassificationTags = [
        getCombinedUniqueString(selectedValues.map((value) => classificationTagNames[value]).filter(Boolean))];

      const excludedTagNames = getExcludedTagNames<ClassificationStatus>(
        appliedApiFilters.tagNamesExcluded ?? [],
        selectedValues,
        this.classificationStatusValueOptions,
        classificationTagNames,
      );
      if (excludedTagNames.length > 0) {
        apiFilters.tagNamesExcluded = excludedTagNames;
      }
    }

    return apiFilters;
  }
}
