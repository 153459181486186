// Module: Alerts Management, Resolve Resolution Listing

;(function(angular, undefined) {
  'use strict';

  angular.module('C.alerts')
    .controller('alertsResolveListingCtrl', AlertsResolveListingCtrlFn);

  function AlertsResolveListingCtrlFn($translate, $scope, $state, cModal,
    cMessage, AlertService, evalAJAX) {
    // ensure we have at least one selected alert otherwise redirect
    if (!$scope.selectedAlerts.length) {
      $state.go('alerts');
    }

    /**
     * Tells smart-table how many items to display per page
     * @type {Number}
     */
    $scope.itemsPerPage = 20;

    $scope.allResolutions = [];

    AlertService.getOwnResolutions({maxResolutions: 1000}).then(
      function getResolutionsSuccess(result) {
        if (angular.isArray(result.data) && result.data && result.data.length) {
          $scope.allResolutions = result.data;
        } else {
          // no resolutions were returned, display a message and
          // send the user back a step so they can create a new resolution
          cMessage.error({
            titleKey: 'noResolutions',
            textKey: 'createANewResolution',
            persist: true,
          });
          $state.go('alerts.resolve');
        }
      },
      evalAJAX.errorMessage
    );

    /**
     * cancels action and returns user back to the resolution step
     */
    $scope.cancel = function() {
      $state.go('alerts.resolve');
    };

    /**
     * user selected a resolution, present a challenge modal
     * before making API call and returning user to the
     * main alerts page if successful
     */
    $scope.selectResolution = function selectResolution(resolution) {
      var options = {
        title: $translate.instant(
          'monitoring.alertsResolveListing.modal.title'),
        content: $translate.instant(
          'monitoring.alertsResolveListing.modal.text'),
        actionButtonText: $translate.instant(
          'monitoring.alertsResolveListing.modal.applyResolution'),
      };

      // Show the modal.
      cModal.showModal({}, options).then(function() {
        // build API call data structure
        var updateParams = {
          alertIdList: []
        };
        /**
         * loop through selected alerts and add each ID to our API call data
         * structure
         */
        angular.forEach($scope.selectedAlerts, function(currentAlert,
          index) {
          updateParams.alertIdList.push(currentAlert.id);
        });

        // make the API call
        AlertService.addAlertsToResolution(resolution.resolutionDetails
          .resolutionId, updateParams).then(
          function addAlertSuccess(result) {
            // API call was successful, redirect back to main alerts page
            $scope.resetData();
            cMessage.success({
              textKey: 'monitoring.alertsResolveListing.modal.successText',
            });
            $state.go('alerts');
          },
          evalAJAX.errorMessage
        );
      });
    };
  }

})(angular);
