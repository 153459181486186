/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityV2ApiConfiguration as __Configuration } from '../cohesity-v2api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ExecuteRunbookActionRequest } from '../models/execute-runbook-action-request';
@Injectable({
  providedIn: 'root',
})
class RunbooksService extends __BaseService {
  static readonly PerformRunbookActionPath = '/runbook/actions';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Perform actions based on the passed args
   *
   * Perform actions like power-off vms, power-off vApp, cloud resource cleanup etc. based on the args that are passed.
   * @param params The `RunbooksService.PerformRunbookActionParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to perform action.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  PerformRunbookActionResponse(params: RunbooksService.PerformRunbookActionParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/runbook/actions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Perform actions based on the passed args
   *
   * Perform actions like power-off vms, power-off vApp, cloud resource cleanup etc. based on the args that are passed.
   * @param params The `RunbooksService.PerformRunbookActionParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to perform action.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  PerformRunbookAction(params: RunbooksService.PerformRunbookActionParams): __Observable<null> {
    return this.PerformRunbookActionResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module RunbooksService {

  /**
   * Parameters for PerformRunbookAction
   */
  export interface PerformRunbookActionParams {

    /**
     * Specifies the parameters to perform action.
     */
    body: ExecuteRunbookActionRequest;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { RunbooksService }
