import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  DoCheck,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
} from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';
import { MatLegacyFormFieldControl as MatFormFieldControl } from '@angular/material/legacy-form-field';
import { OnChange, OnTouched, TypedControlValueAccessor } from '../typed-control-value-accessor';
import { ClearSubscriptions } from '@cohesity/utils';
import { ObservableInput } from 'ngx-observable-input';
import { combineLatest, Observable, Subject } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';

import {
  SearchableSelectItemTemplateDirective,
  SearchableSelectTriggerTemplateDirective,
} from './item-template.directive';
import { ComparatorFn, DataIdProviderFn, SearchFn } from './searchable-select.model';

/**
 * Mat Select control value type
 */
type controlType<ItemType> = ItemType | ItemType[];

/**
 * Renders a mat-select component with a search bar to filter the options. This component is CVA enabled.
 */
@Component({
  selector: 'coh-searchable-select',
  templateUrl: './searchable-select.component.html',
  styleUrls: ['./searchable-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: MatFormFieldControl, useExisting: SearchableSelectComponent }],
})
export class SearchableSelectComponent<ItemType>
  extends ClearSubscriptions
  implements
    MatFormFieldControl<controlType<ItemType>>,
    TypedControlValueAccessor<controlType<ItemType>>,
    OnInit,
    AfterViewInit,
    OnDestroy,
    DoCheck {
  /**
   * A field to keep track of the next unique ID for component instance.
   */
  static nextId = 0;

  /**
   * Array of items for the mat-select options.
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @ObservableInput([]) @Input('items') items$: Observable<ItemType[]>;

  /**
   * Callback to facilitate searching items.
   */
  @Input() searchFn: SearchFn<ItemType>;

  /**
   * Callback to allow comparison between two items.
   */
  @Input() compareWith: ComparatorFn<controlType<ItemType>>;

  /**
   * Callback to generate custom data-id values for mat-select options.
   */
  @Input() optionDataIdFn: DataIdProviderFn<ItemType>;

  /**
   * Placeholder label for the search input.
   */
  @Input() searchPlaceholderLabel: string;

  /**
   * Text to show when there is no matching item in the options matching the search term.
   */
  @Input() searchNoEntriesFoundLabel: string;

  /**
   * Indicates whether the dropdown is multi-line.
   */
  @Input() isMultiline = false;

  /**
   * Optional. "multiple" for mat-select.
   */
  @Input() allowMultiple = false;

  /**
   * The remove icon to use.
   */
  @Input() removeIcon: 'close' | 'cancel' = 'cancel';

  /**
   * Label for add new item button.
   * This label is also used to decide whether the button needs to be shown.
   */
  @Input() addButtonLabel: string;

  /**
   * Event emitter for add item button click.
   */
  @Output() addItemButtonClick = new EventEmitter<void>();

  // start: MatFormFieldControl overrides
  id = `searchable-select-${SearchableSelectComponent.nextId++}`;
  focused = false;
  required = true;
  placeholder = '';

  get value(): controlType<ItemType> | null {
    return this.selectControl.value;
  }

  set value(value: controlType<ItemType> | null) {
    this.selectControl.setValue(value);
    this.stateChanges.next();
  }

  get empty() {
    return !this.value;
  }

  get shouldLabelFloat() {
    return !this.empty;
  }

  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.selectControl.disable() : this.selectControl.enable();
    this.stateChanges.next();
  }

  get errorState(): boolean {
    return this.ngControl?.touched && this.ngControl?.invalid;
  }

  readonly stateChanges = new Subject<void>();
  // end: MatFormFieldControl overrides

  /**
   * The form control for the mat-select.
   */
  readonly selectControl = new FormControl<controlType<ItemType>>(null);

  /**
   * The form control for the search input.
   */
  readonly searchControl = new FormControl<string>('');

  /**
   * Collection of options, filtered by the term.
   */
  filteredItems$: Observable<ItemType[]>;

  /**
   * The currently selected value.
   */
  selectedValue$: Observable<controlType<ItemType>>;

  /**
   * Reference to the custom item template provided by the consumer.
   */
  @ContentChild(SearchableSelectItemTemplateDirective)
  readonly searchableSelectItemTemplate: SearchableSelectItemTemplateDirective;

  /**
   * Reference to the custom select trigger template provided by the consumer.
   */
  @ContentChild(SearchableSelectTriggerTemplateDirective)
  readonly selectTriggerTemplate: SearchableSelectTriggerTemplateDirective;

  /**
   * Indicates whether the component is disabled.
   */
  private _disabled = false;

  // start - CVA provided callbacks.
  private onChange: OnChange<controlType<ItemType>>;
  private onTouch: OnTouched;
  // end - CVA provided callbacks.

  constructor(@Optional() @Self() readonly ngControl: NgControl, private elementRef: ElementRef) {
    super();

    if (this.ngControl != null) {
      // Setting the value accessor directly (instead of using
      // the providers) to avoid running into a circular import.
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    this.filteredItems$ = combineLatest([
      this.items$,
      this.searchControl.valueChanges.pipe(startWith(''), debounceTime(150)),
    ]).pipe(map(([items, term]) => this.searchFn(items, term)));

    this.selectedValue$ = combineLatest([
      this.items$,
      this.selectControl.valueChanges.pipe(startWith(this.selectControl.value)),
    ]).pipe(
      map(([items, controlValue]) => {
        if (this.allowMultiple) {
          return items.filter(item =>
            ((controlValue ?? []) as ItemType[]).find(value => this.compareWith(item, value))
          );
        }
        return items.find(item => this.compareWith(item, controlValue as ItemType) ?? null) as ItemType;
      })
    );
  }

  ngDoCheck(): void {
    // Invoke a state change if the form control has become invalid.
    if (this.ngControl?.touched && this.ngControl?.invalid) {
      this.stateChanges.next();
    }
  }

  /**
   * Triggers a state change which causes the material form control to gain focus.
   */
  @HostListener('focusin')
  onFocusIn() {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  /**
   * Triggers a state change which causes the material form control to lose focus.
   *
   * @param event The focus event object.
   */
  @HostListener('focusout', ['$event'])
  onFocusOut(event: FocusEvent) {
    if (!this.elementRef.nativeElement.contains(event.relatedTarget as Element)) {
      this.focused = false;
      this.onTouch();
      this.stateChanges.next();
    }
  }

  ngAfterViewInit(): void {
    this.subscriptions.push(this.selectControl.valueChanges.subscribe(val => this.onChange(val)));
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.stateChanges.complete();
  }

  /**
   * Removes a value from the selection.
   *
   * @param removedItem Item to be removed.
   */
  removeAt(removedItem: ItemType) {
    this.selectControl.setValue(
      (this.selectControl.value as ItemType[]).filter(item => !this.compareWith(item, removedItem))
    );
  }

  // start: MatFormFieldControl overrides
  setDescribedByIds() {}
  onContainerClick() {}
  // end: MatFormFieldControl overrides

  // start: CVA overrides
  writeValue(value: ItemType): void {
    this.value = value;
  }

  registerOnChange(fn: OnChange<controlType<ItemType>>): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: OnTouched): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  // end: CVA overrides
}
