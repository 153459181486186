import { ClusterInfo } from '@cohesity/api/v2';
import { ValueFilterSelection } from '@cohesity/helix';
import { URL_SUPPORTED_FILTERS } from '../software-upgrades.constant';
import { ClusterUpgradeInfo } from './decorated-cluster-upgrade-info';

/**
 * cluster listing filter model
 */
export interface ClusterListingFilters {
  // filter by cluster name
  cluster: ValueFilterSelection[];
  // filter by current version of cluster
  currentVersion: ValueFilterSelection[];
  // filter by upgrade status of cluster
  upgradeStatus: ValueFilterSelection[];
  // search text applied
  searchText: string;
  // filter by cluster health
  clusterHealth: ValueFilterSelection[];
  // filter by connection status
  connected: ValueFilterSelection[];
  // filter by connection status
  backupStatus: ValueFilterSelection[];
  // filter by number of views
  hasViews: ValueFilterSelection[];
}

/**
 * cluster upgrade status keys
 */
export enum StatusKey {
  // cluster is still in upgrading state
  upgrading = 'upgrading',
  // cluster is ready to upgrade with available versions
  upgradeReady = 'upgradeReady',
  // cluster upgrade failed
  upgradeFailed = 'upgradeFailed',
  // cluster upgraded to latest version
  upToDate = 'upToDate',
  // cluster upgrade schedule initiated
  upgradeSchedule = 'upgradeSchedule',
}

/**
 * clutser action context menus
 */
export enum ClusterActionId {
  // updates cluster location
  updateLocation = 'update-location',
  // instant cluster upgrade
  upgradeNow = 'upgrade-now',
  // upgrade cluster on datetime basis
  scheduleUpgrade = 'schedule-upgrade',
  // edit already created cluster schedule
  editScheduledUpgrade = 'edit-scheduled-upgrade',
  // delete already created cluster schedule
  deleteScheduledUpgrade = 'delete-scheduled-upgrade',
  // view cluster upgrade logs
  viewLog = 'view-log',
  // unregisters a cluster
  unregister = 'unregister',
  // recover configurations
  import = 'import',
  // backup configurations
  export = 'export',
}

/**
 * refers to list of allowed cluster health values
 */
export type ClusterHealth = ClusterInfo['health'] | 'Warning';

/**
 * refers to the list of allowed status strings for cluster upgrade status
 */
export type ClusterUpgradeStatus = ClusterInfo['status'];

/**
 * model for cluster stat. It contains number of
 * cluster having a particular status.
 * e.g. connected/disconnected, healthy/critical etc.
 */
export interface ClusterListStat {
  /**
   * count of cluster having `key` upgrade status
   */
  count: number;

  /**
   * param key of the stat property.
   */
  key: string;

  /**
   * Value of the the stat property.
   */
  value: string | number | boolean;

  /**
   * label for showing the property stat.
   */
  label: string;

  /**
   * icon appropriate for stat.
   */
  icon?: string;

  /**
   * UI Param object for the stat
   */
  params?: UpgradesURLQueryParams;
}

/**
 * Query parameters keys
 */
export type UpgradesURLQueryKey = typeof URL_SUPPORTED_FILTERS[number];

/**
 * Url query params which are used in listing table filters as well
 */
export type UpgradesURLQueryParams = {
  [key in UpgradesURLQueryKey]?: string | string[];
};

export type ClusterIdentifier = string;

/**
 * Map of cluster identifier vs cluster attribute value
 */
export type ClusterIdentifierAttributeValueMap<T> = Map<ClusterIdentifier, T>;

/**
 * Misc attributes which are required to be amended separately into cluster info
 * response
 */
export type ClusterMiscAttributes = Pick<ClusterUpgradeInfo, 'clusterHealth' | 'gflagRecipes'>;

/**
 * Summary Data for SmartFiles clusters.
 */
export interface SmartFilesSummaryData {
  /**
   * Total logical data by views for all SmartFiles clusters combined.
   */
  totalLogical: number;

  /**
   * Total number of clusters with at least one View.
   */
  numClusters: number;

  /**
   * Total number if Views for all clusters combined.
   */
  numViews: number;
}

/**
 * Smartfiles cluster table columns
 */
export interface SmartFilesTableColumns {
  /**
   * Total logical data ingested for all Views on the cluster
   */
  totalLogicalData: number;

  /**
   * Total data reduction of logical data for all Views on the cluster.
   * Displayed as a multiplier with one decimal place.
   */
  dataReduction: number;

  /**
   * 30 day growth rate of total logical data for all Views on the cluster.
   * Displayed as a percentage with one decimal place.
   */
  logicalGrowthRate: number;
}

/**
 * Enum represeting all Possible AuthMethods for cluster Upgrades.
 */
export enum ClusterUpgradeAuthMethods {
  /**
   * Represents the 'None' authentication method.
   * This is used when no authentication is required.
   */
  None = 'None',

  /**
   * Represents the 'Basic' authentication method.
   * This is used for basic authentication with a username and password.
   */
  Basic = 'Basic',

  /**
   * Represents the 'Custom' authentication method.
   * This is used for custom authentication headers.
   */
  Custom = 'Custom'
}
