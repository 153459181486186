export const dataSiteDetails = {
  id: 1,
  name: 'San Francisco, CA',
  type: 'Cohesity',
  clusterName: 'PrimaryCluster',
  version: '6.6',
  nodeCount: 4,
  resource: {
    cpu: 32,
    memory: 51,
    storage: 28,
  },
  connectivity: {
    outgoing: 'Houston, TX',
    incoming: 'Denver, CO',
  }
};

export const dataSiteReadinessHistory = {
  dataPointVec: [
    {
      timestampMsecs: 1555852607633,
      data: {
        int64Value: 80,
      },
    },
    {
      timestampMsecs: 1555856207633,
      data: {
        int64Value: 82,
      },
    },
    {
      timestampMsecs: 1555859807633,
      data: {
        int64Value: 95,
      },
    },
    {
      timestampMsecs: 1555863407633,
      data: {
        int64Value: 69,
      },
    },
    {
      timestampMsecs: 1555867007633,
      data: {
        int64Value: 40,
      },
    },
    {
      timestampMsecs: 1555870607633,
      data: {
        int64Value: 70,
      },
    },
    {
      timestampMsecs: 1555874207633,
      data: {
        int64Value: 28,
      },
    },
    {
      timestampMsecs: 1555877807633,
      data: {
        int64Value: 95,
      },
    },
    {
      timestampMsecs: 1555881407633,
      data: {
        int64Value: 100,
      },
    },
    {
      timestampMsecs: 1555885007633,
      data: {
        int64Value: 76,
      },
    },
    {
      timestampMsecs: 1555888607633,
      data: {
        int64Value: 55,
      },
    },
    {
      timestampMsecs: 1555892207633,
      data: {
        int64Value: 88,
      },
    },
    {
      timestampMsecs: 1555895807633,
      data: {
        int64Value: 90,
      },
    },
    {
      timestampMsecs: 1555899407633,
      data: {
        int64Value: 43,
      },
    },
    {
      timestampMsecs: 1555903007633,
      data: {
        int64Value: 17,
      },
    },
    {
      timestampMsecs: 1555906607633,
      data: {
        int64Value: 68,
      },
    },
    {
      timestampMsecs: 1555910207633,
      data: {
        int64Value: 85,
      },
    },
    {
      timestampMsecs: 1555913807633,
      data: {
        int64Value: 100,
      },
    },
    {
      timestampMsecs: 1555917407633,
      data: {
        int64Value: 70,
      },
    },
    {
      timestampMsecs: 1555921007633,
      data: {
        int64Value: 87,
      },
    },
    {
      timestampMsecs: 1555924607633,
      data: {
        int64Value: 60,
      },
    },
    {
      timestampMsecs: 1555928207633,
      data: {
        int64Value: 90,
      },
    },
    {
      timestampMsecs: 1555931807633,
      data: {
        int64Value: 87,
      },
    },
    {
      timestampMsecs: 1555935407633,
      data: {
        int64Value: 90,
      },
    },
  ],
};
