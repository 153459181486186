/**
 * Values to populate time format selection and used in cohDate when saved.
 */
export enum HourFormat {
  Hour12 = '12HourClock',
  Hour24 = '24HourClock',
}

/**
 * Customizable time format and time zone.
 */
export interface TimeFormat {
  hourFormat?: HourFormat;
  timeZone?: string;
}
