// COMPONENT: Cluster Upgrade pulse & tasks list

;(function(angular, undefined) {
  'use strict';

  /**
   * @ngdoc component
   * @name C.clusterUpgrade:cClusterUpgradePulse
   * @function
   *
   * @description
   * Displays Cluster Upgrade progress bar and subtasks, plus errors.
   *
   * @example
     <example module="C.clusterUpgrade">
         <c-cluster-upgrade-pulse
             to-version="{{clusterInfo.targetSoftwareVersion}}"
             show-tasks="true"
             pulse-callback="callbackFnName"
             pulse-interval="10000"></c-cluster-upgrade-pulse>
     </example>
   */
  var moduleName = 'C.clusterUpgrade';
  var componentName = 'cClusterUpgradePulse';
  var config = {
    bindings: {
      // @type       {string} Package version we're upgrading to.
      toVersion: '@',
      // @type       {boolean=} Whether we need to display the Upgrade
      // subtasks
      showTasks: '=?',
      // @type       {string} Id of the cluster we're upgrading.
      clusterId: '@',
      // @type       {function=} Callback function called at each interval
      // after internal function has completed.
      pulseCallback: '&?',
      // @type       {integer=} Interval period in milliseconds.
      pulseInterval: '&?',
    },
    templateUrl: 'app/admin/cluster/upgrade/upgrade-pulse.html',
    controller: 'UpgradePulseController',
  };

  angular
    .module(moduleName)
    .controller('UpgradePulseController', upgradePulseControllerFn)
    .component(componentName, config);

  function upgradePulseControllerFn(
    _, $scope, ClusterService, UpgradeClusterService, NodeService) {

    var $ctrl = this;

    // declare component methods
    _.assign($ctrl, {
      // component life cycle methods
      $onInit: $onInit,

      cancelUpgrade: cancelUpgrade,
    });

    /**
     * initialize component context
     *
     * @method   initializeCtrl
     */
    function initializeCtrl() {
      // $SCOPE VARS
      // TODO: clean up this scope requirements
      _.assign($scope, {
        showTasks: false,
      });

      _.assign($ctrl, {
        // The internal callback. Calls configured pulseCallback at the end, if
        // provided.
        callback: pollingCallbackFn,

        // if no custom callback is provided, set it to noop
        pulseCallback: $ctrl.pulseCallback || angular.noop,

        // Ensure the interval is an integer, or set undefined and use the
        // cPulse defaults
        pulseInterval: +$ctrl.pulseInterval || undefined,
      });
    }

    /**
     * This component's init function. For use with ngComponent's $onInit.
     *
     * @method     $onInit
     */
    function $onInit() {
      initializeCtrl();
      getNodesHash();
      $ctrl.progressEndpoint =
        ClusterService.getUpgradeTaskPath($ctrl.toVersion);
    }

    /**
     * Cancel the runnig upgrade on the cluster
     *
     * @method     cancelUpgrade
     */
    function cancelUpgrade() {
      var cancelUpgradeParams = {
        clusterId: Number($ctrl.clusterId),
        targetSwVersion: $ctrl.toVersion,
        cancel: true,
      };

      UpgradeClusterService.cancelUpgrade(cancelUpgradeParams);
    }

    /**
     * cPulse callback function. ALso executes the optional pulseCallback if
     * provided.
     *
     * @method     pollingCallbackFn
     * @param      {object}  data    The pulse response data.
     * @return     {object}  The data response.
     */
    function pollingCallbackFn(data) {
      $ctrl.data = data;
      $ctrl.upgradeInProgress = !!(data && data._pollingStatus === 'active');
      $ctrl.upgradeFinished = !!(data && data._pollingStatus === 'completed');
      $ctrl.upgradeTasks = ClusterService.getPulseTasks(data, $ctrl.nodesHash);

      // progress status type is 1 for success
      $ctrl.upgradeSuccess = (
        data && data.progress &&
        data.progress.status.type === 1 &&
        data.progress.percentFinished === 100
      );

      /**
       * @type       {object=} Possible error state in Upgrade progress
       * @example
         {
           type: {0-8},
           errorMsg: "string"
         }
       */
      $ctrl.progressError = (data.error && data.error.type > 0) ?
        data.error : undefined;

      // Execute a custom callback, if provided.
      $ctrl.pulseCallback(data);
      return data;
    }

    /**
     * Gets the nodes hash.
     *
     * @method     getNodesHash
     * @return     {object}  The nodes hashed by node.id.
     */
    function getNodesHash() {
      return NodeService.getClusterNodes().then(function receivedNodesFn(resp) {
        if (Array.isArray(resp.data)) {
          $ctrl.nodesHash =
            resp.data.reduce(function nodesReducerFn(nodes, node) {
            nodes[node.id] = node;
            return nodes;
          }, {});
        }
        return resp;
      });
    }
  }

})(angular);
