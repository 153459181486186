import { AppModuleConfig, StateAccessContext, StateAccessMap } from 'src/app/app-module.config';

const configureAccess = (ctx: StateAccessContext) =>
  (ctx.isDmsUser || ctx.isDraasUser) && ctx.clusterInfo?._cohesityService;

export const DmsConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'dms.**',
      url: '/dms',
      loadChildren: () => import('./dms.module').then(m => m.DmsModule),
    }
  ],
  allClusterMap: {
    'dms.setup': true,
    'dms.regions': true,
    'dms.tenant-migration': true,
  },
  getStateAccessMap(): StateAccessMap {
    return {
      'dms.setup': configureAccess,
      'dms.regions': configureAccess,
      'dms.tenant-migration': configureAccess,
    };
  }
};
