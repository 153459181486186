import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AzureProtectionSource, ProtectionSourceNode } from '@cohesity/api/v1';
import { flagEnabled, IrisContextService, isDmsScope } from '@cohesity/iris-core';
import { AutoDestroyable } from '@cohesity/utils';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'coh-app-private-endpoints-dialog-row',
  templateUrl: './private-endpoints-dialog-row.component.html',
  styleUrls: ['./private-endpoints-dialog-row.component.scss'],
})
export class PrivateEndpointsDialogRowComponent extends AutoDestroyable implements OnInit {
  /**
   * The form group for this component.
   */
  @Input() privateEndpointField: FormGroup;

  /**
   * The source of the selected objects for recovery.
   */
  @Input() protectionSource$: Observable<ProtectionSourceNode>;

  /**
   * The list of usable VNET options.
   */
  @Input() restrictedVNETs?: number[] = [];

  /**
   * List of regions available for selection.
   */
  regions: ProtectionSourceNode[];

  /**
   * List of virtual networks available for selection.
   */
  virtualNetworks$: Observable<ProtectionSourceNode[]>;
  /**
   * List of subnets available for selection.
   */
  subnets$: Observable<ProtectionSourceNode[]>;

  constructor(private irisContext: IrisContextService) {
    super();
  }

  ngOnInit() {

    this.virtualNetworks$ = this.protectionSource$.pipe(
      map(source => this.getVNetOptions(source, this.privateEndpointField.value.region))
    );

    // Get the list of subnets that belong to the selected virtual network.
    this.subnets$ = combineLatest([
      this.privateEndpointField.get('virtualNetwork').valueChanges,
      this.virtualNetworks$,
    ]).pipe(
      filter(([vid, vn]) => !!vid && !!vn),
      map(([vid, vn]) => this.getNodesByTypeOneLvl(vid, vn, 'kSubnet'))
    );
  }

  /**
   * Get the list of virtual networks that belong to the selected region.
   *
   * @param source The parent source node
   * @param region The target region
   * @return Virtual Network node options
   */
  getVNetOptions(source: ProtectionSourceNode, region: string) {
    // If restricted VNETs are provided (implying the flag is enabled),
    // limit the selectable options to the restricted VNETs,
    // otherwise allow all available vnet options as default if restriction flag is not enabled
    const dmsVNETRestriction = isDmsScope(this.irisContext.irisContext) &&
      flagEnabled(this.irisContext.irisContext, 'dmsAzureSaaSConnectionVNETRestriction');
    return this.getNodesByType(source, 'kVirtualNetwork', region)
      .filter(node => dmsVNETRestriction ? (this.restrictedVNETs ?? []).includes(node.protectionSource.id) : true);
  }

  /**
   * Get the child nodes of a parent node given it's id, type of child nodes and the list of nodes.
   *
   * @param id    Id of the parent node.
   * @param nodes List of all nodes.
   * @param type  Type of the child nodes to filter.
   * @returns     The list of child nodes of given type.
   */
  getNodesByTypeOneLvl(
    id: number,
    nodes: ProtectionSourceNode[],
    type: AzureProtectionSource['type']
  ): ProtectionSourceNode[] {
    const vnode = nodes.find(vn => vn.protectionSource.id === id);
    return (
      vnode.nodes?.filter(
        (node: ProtectionSourceNode) => node.protectionSource?.azureProtectionSource?.type === type
      ) || []
    );
  }

  /**
   * Given a tree, get all the nodes of specific type and belong to given location.
   *
   * @param sourceNode  Root node of the tree.
   * @param type        Type of nodes to filter.
   * @param location    Location of the nodes.
   * @returns           The list of child nodes of given type and location.
   */

  getNodesByType(sourceNode: ProtectionSourceNode, type: any, location?: string): ProtectionSourceNode[] {
    const result = [];
    sourceNode?.nodes?.forEach((nodeLvlOne: ProtectionSourceNode) => {
      nodeLvlOne?.nodes?.forEach((nodeLvlTwo: ProtectionSourceNode) => {
        nodeLvlTwo?.nodes?.forEach((nodeLvlThree: ProtectionSourceNode) => {
          if (location) {
            if (
              nodeLvlThree?.protectionSource?.azureProtectionSource?.type === type &&
              nodeLvlThree?.protectionSource?.azureProtectionSource?.location === location
            ) {
              result.push(nodeLvlThree);
            }
          } else {
            if (nodeLvlThree?.protectionSource?.azureProtectionSource?.type === type) {
              result.push(nodeLvlTwo);
            }
          }
        });
      });
    });
    // Activate VirtualNettwork Field - enforce valueChanges
    this.privateEndpointField.get('virtualNetwork').enable();
    return result;
  }
}
