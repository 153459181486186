import { Component, inject, OnInit, Optional } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Role } from '@cohesity/api/v1';
import { AppPanelComponent } from '@cohesity/helix';
import { flagEnabled, IrisContextService, isDmsSelfServiceUser, isMcm, isOneHeliosAppliance, isPrivilegedUser } from '@cohesity/iris-core';
import { IS_IBM_AQUA_ENV } from '@cohesity/shared/core';
import { AutoDestroyable } from '@cohesity/utils';
import { StateService } from '@uirouter/core';
import { takeUntil } from 'rxjs/operators';
import {
  AjsUpgradeService,
  DialogService,
  HeliosAccessManagementService,
  McmViewService,
  TenantScopeService,
  TenantService,
  UserService
} from 'src/app/core/services';
import { CompanyAccount, SwitchAccountDialogComponent } from 'src/app/modules/company-account';
import { heliosProductionHostnames } from 'src/environments/app-environment.model';

/**
 * User Panel component for displaying user information and managing user states.
 */
@Component({
  selector: 'coh-user-panel',
  templateUrl: './user-panel.component.html',
  styleUrls: ['./user-panel.component.scss']
})
export class UserPanelComponent extends AutoDestroyable implements OnInit {
  /**
   * List of possible roles.
   */
  roles: Role[] = [];

  /**
   * Legacy TenantService AngularJS service.
   */
  private ajsTenantService: any;

  /**
   * Legacy UserService AngularJS service.
   */
  private ajsUserService: any;

  /**
   * Legacy HeliosService AngularJS service.
   */
  private ajsHeliosService: any;

  /**
   * Indicates whether cluster is running in Helios mode.
   */
  isHelios: boolean;

  /**
   * Indicates whether the UI is running in IBM Aqua mode.
   */
  readonly isIBMAquaEnv = inject(IS_IBM_AQUA_ENV);

  constructor(
    @Optional() private dialogRef: MatDialogRef<AppPanelComponent>,
    ajsUpgrade: AjsUpgradeService,
    private dialogService: DialogService,
    private tenantScopeService: TenantScopeService,
    private tenantService: TenantService,
    private userService: UserService,
    private mcmViewService: McmViewService,
    private irisContextService: IrisContextService,
    private accessManagementService: HeliosAccessManagementService,
    private state: StateService,
  ) {
    super();

    const ctx = this.irisContextService.irisContext;
    this.ajsHeliosService = ajsUpgrade.get('HeliosService');
    this.ajsTenantService = ajsUpgrade.get('TenantService');
    this.ajsUserService = ajsUpgrade.get('UserService');
    this.isHelios = isMcm(ctx);
  }

  ngOnInit() {
    if (this.isHelios) {
      this.accessManagementService.getRoles()
        .pipe(takeUntil(this._destroy))
        .subscribe(roles => this.roles = roles);
    }
  }

  /**
   * Returns current session user.
   */
  get user(): any {
    return this.userService.user;
  }

  /**
   * Returns if user is a sales user.
   */
  get isSalesforceUser(): boolean {
    return this.ajsHeliosService.isSalesforceUser();
  }

  /**
   * Returns privs for current user.
   */
  get privs(): any {
    return this.userService.privs;
  }

  /**
   * Formatted login time of user.
   */
  get loginTime(): string {
    return this.ajsUserService.getLastLoginTime();
  }

  /**
   * Returns true is user is impersonated via different tenant.
   */
  get isImpersonated(): boolean {
    return this.userService.isImpersonated;
  }

  /**
   * Indicate whether user can use impersonation.
   *
   * @returns   True if user can impersonation else false.
   */
  get impersonationMode(): boolean {
    return !this.ajsTenantService.isTenantAccountSwitchable();
  }

  /**
   * The selected tenant during impersonation or switched account.
   */
  get tenant(): any {
    return (
      this.impersonationMode ?
        this.tenantService.impersonatedTenant :
        this.ajsTenantService.getSelectedAccount()
    ) || { name: 'Cohesity' };
  }

  /**
   * Indicate whether ng user profile is enabled or not.
   */
  get ngUserProfileEnabled(): boolean {
    return flagEnabled(this.irisContextService.irisContext, 'ngUserProfile');
  }

  /**
   * Indicate whether UI Customization is enabled or not.
   */
  get uiCustomizationEnabled(): boolean {
    const irisContext = this.irisContextService.irisContext;
    const isUiCustomizationDisabled = isOneHeliosAppliance(irisContext) || this.isHelios || this.isImpersonated;

    const uiCustomizationFlag = flagEnabled(irisContext, 'uiCustomization');
    const mcmUserPreferencesFlag = flagEnabled(irisContext, 'mcmUserPreferences');

    return (
      (!isUiCustomizationDisabled && uiCustomizationFlag) ||
      (mcmUserPreferencesFlag && !isOneHeliosAppliance(irisContext))
    );
  }

  /**
   * Whether user is a sales or support user so that user can switch company account.
   * Note that there is no need to check feature flags heliosPrivilegedAccess,
   * heliosPrivilegedAccessSales because enable the flag(s) in production is difficult.
   * And the risk is low as the user is internal user.
   */
  get canSwitchCompanyAccount(): boolean {
    return isPrivilegedUser(this.irisContextService.irisContext, 'support') ||
      isPrivilegedUser(this.irisContextService.irisContext, 'sales');
  }

  /**
   * Returns the company account sales/support user selected.
   */
  get salesforceAccount(): CompanyAccount {
    if (this.canSwitchCompanyAccount) {
      return this.ajsHeliosService.getSalesforceAccount();
    }
  }

  /**
   * Determines whether to show tenant ID along with the username.
   *
   * @returns True iff current user is not a Self Service user.
   *          False, otherwise.
   */
  get showTenantId(): boolean {
    return !isDmsSelfServiceUser(this.irisContextService.irisContext);
  }

  /**
   * Determines whether to show the toggle to go to Self Service Portal
   * View. This is only applicable for internal DMaaS setups.
   *
   * @returns True, iff the flag for Self Service is enabled & env is not PROD.
   */
  get showSelfServicePortalGoto(): boolean {
    return flagEnabled(this.irisContextService.irisContext, 'dmsOffice365SelfServiceRecoveryEnabled') &&
      !heliosProductionHostnames.includes(window.location.hostname) &&
      !this.isIBMAquaEnv;
  }

  /**
   * Provides a presentational list of roles assigned to the current user.
   *
   * @param userRoles  list of roles for the user
   * @returns  presentational string for user roles
   */
  rolesString(userRoles: string[]): string {
    return userRoles.map(userRole => this.roles.find(role => role.name === userRole)?.label || userRole).join(', ');
  }

  /**
   * Handles logout action.
   */
  logout() {
    const oldAccountId = this.ajsHeliosService.getImpersonatedSalesforceAccountId();

    this.mcmViewService.showFrame$.next(false);
    this._closeUserPanel();
    this.userService.logout().finally(() => {
      this.userService.logStopImpersonation(oldAccountId)
        .pipe(takeUntil(this._destroy))
        .subscribe();
      this.tenantService.clearImpersonatedTenant();
      this.ajsTenantService.setSwitchedAccount();
    });
  }

  /**
   * Close the user panel dialog.
   */
  private _closeUserPanel() {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  /**
   * Opens the impersonation modal.
   *
   * @return Promise for opening impersonation modal.
   */
  openSwitchAccountModal(): Promise<any> {
    this._closeUserPanel();
    return this.tenantScopeService.openSwitchAccountModal();
  }

  /**
   * Switches back to the original user after impersonation.
   *
   * @return Promise for updating after impersonation is done.
   */
  quitImpersonation(): Promise<any> {
    this._closeUserPanel();
    return this.tenantScopeService.quitImpersonation();
  }

  /**
   * Opens a switch Salesforce account modal.
   */
  openSwitchSalesforceAccountModal() {
    this._closeUserPanel();
    this.dialogService.showDialog(SwitchAccountDialogComponent);
  }

  /**
   * Opens user settings modal via AJS service.
   */
  openUserSettings() {
    this.ajsUserService.userSettingsModal();
    this._closeUserPanel();
  }

  /**
   * Handles switching to the MSFT Self Service Portal.
   * This is only used internally for ease of testing since many accounts
   * tend to use the same MSFT source.
   */
  gotoSelfServicePortal() {
    const tenantId = this.userService.dmsTenantIdWithoutAccountId;

    // Logout is necessary to create Helios Session with privileges for only
    // Self Service workflows.
    this.logout();
    window.open(`self-service-portal/auth?tenantId=${tenantId}`, '_blank').focus();
  }
}
