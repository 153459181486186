import { Injectable } from '@angular/core';
import { Transition } from '@uirouter/core';

import { flagEnabled, IrisContextService } from '@cohesity/iris-core';
import { GuardPriority, GuardResult, StateGuard } from '../state-guard';


/**
 * Jobs landing page Guard redirects all state traffic to the ng groups landing state if
 * the feature flag is enabled.
 */
@Injectable({
  providedIn: 'root',
})
export class JobDetailsGuard implements StateGuard {
  /**
   * This guard priority is set to App, which indicates it should run as part of the main application
   * after all bootstrapping and initializing is completed.
   */
  guardPriority = GuardPriority.App;

  /**
   * Match all navigation to jobs (classic ui state).
   */
  matchCriteria = {
    to: 'job-details.job',
  };

  constructor(private irisCtx: IrisContextService) {}

  /**
   * Redirect to groups landing page with the params.
   *
   * @param   transition  The ui router transition
   * @returns a redirect transition if the protection group feature flag is enabled.
   */
  onStart(transition: Transition): GuardResult {
    let params = transition.params();

    // Redirect to new protection group detail page if the flag is enabled.
    if (flagEnabled(this.irisCtx.irisContext, 'ngProtectionGroup') && !params.force) {
      // Add the id as a groupId, and the new state guard will correct it to the
      // new id format (clusterId:clusterIncarnationId:jobId) before resolving.
      params = {
        ...params,
        groupId: params.id,
      };

      return transition.router.stateService.target('protection-group.group', params);
    }
  }
}
