import { ConnectionPositionPair, Overlay, OverlayConfig, PositionStrategy } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ElementRef, Injectable, Injector } from '@angular/core';

import { PopoverContent, PopoverRef } from './popover-ref';
import { PopoverComponent } from './popover.component';

/**
 * Popover config class passed as param to PopoverService open function
 */
export interface PopoverConfig {
  panelClass?: string[];
  preferredPositions: ConnectionPositionPair[];
  popoverOffsetX: number;
  popoverOffsetY: number;
  backdropClass: string;
}

/**
 * Default positioning preference order for popover which will be passed in
 * overlay config.
 */
export const defaultPreferredPostions: ConnectionPositionPair[] = [
  // Left part of overlay anchors to right of origin
  {
    originX: 'end',
    originY: 'center',
    overlayX: 'start',
    overlayY: 'center'
  },

  // Mid right of overlay anchors to left mid of origin element
  {
    originX: 'start',
    originY: 'center',
    overlayX: 'end',
    overlayY: 'center'
  },

  // Top mid of overlay anchors to botton mid of origin element
  {
    originX: 'center',
    originY: 'bottom',
    overlayX: 'center',
    overlayY: 'top',
  },

  // Top of origin element
  {
    originX: 'center',
    originY: 'top',
    overlayX: 'center',
    overlayY: 'bottom',
  },
];

/*
 * Default horizontal offset between popover and origin html element in pixels
 */
export const defaultPopoverOffsetX = 2;

/*
 * Default vertical offset between popover and origin html element in pixels
 */
export const defaultPopoverOffsetY = 2;

/**
 * Default style class for backdrop
 */
export const defaultBackdropClass = 'cdk-overlay-transparent-backdrop';


/**
 * Default PopoverConfig object
 */
const defaultPopoverConfig: PopoverConfig = {
  preferredPositions: defaultPreferredPostions,
  popoverOffsetX: defaultPopoverOffsetX,
  popoverOffsetY: defaultPopoverOffsetY,
  backdropClass: defaultBackdropClass
};

/**
 * PopoverService which provides open function to open popover. It uses Angular
 * CDK overlay component underneath.
 * This is an internal service which is not supposed to be used directly by
 * other components. It is exposed via cogPopover directive.
 */
@Injectable()
export class PopoverService {

  constructor(
    private overlay: Overlay,
    private injector: Injector
  ) {}

  /**
   * Opens popover by anchoring to origin and creates  PopoverComponent by passing
   * content as part of popoverRef.
   *
   * @param    origin         ElementRef of origin to anchor overlay to
   * @param    content        content to be passed to PopoverComponent
   * @param    data           optional data to be passed to PopoverComponent
   * @param    popoverConfig  optional popoverConfig object used in creating overlay
   * @returns   Returns PopoverRef that can be used to listen for overlay events
   */
  open<T>(
    origin: ElementRef,
    content: PopoverContent,
    data?: T,
    popoverConfig?: Partial<PopoverConfig>,
    hasBackdrop?: boolean,
  ): PopoverRef<T> {

    if (!origin || !content) {
      return undefined;
    }

    const overlayConfig = this.getOverlayConfig(
      origin,
      {
        ...defaultPopoverConfig,
        ...(popoverConfig || null),
      },
      hasBackdrop,
    );
    const overlayRef = this.overlay.create(overlayConfig);

    // popoverRef is passed to create PopoverComponent using ComponentPortal
    const popoverRef = new PopoverRef<T>(overlayRef, content, data);

    // Injector instance which is required for creating ComponentPortal instance
    const injector = Injector.create({
      parent: this.injector,
      providers: [{ provide: PopoverRef, useValue: popoverRef }],
    });

    // Attach PopoverComponent to overlayRef instance
    overlayRef.attach(new ComponentPortal(PopoverComponent, null, injector));
    return popoverRef;
  }

  /**
   * Constructs overlay config using origin and default position and scroll
   * strategy
   *
   * @param    origin  origin html element to anchor the overlay
   * @returns  Returns OverlayConfig that is used to pass in to create overlay
   */
  private getOverlayConfig(
    origin: ElementRef,
    popoverConfig: PopoverConfig,
    hasBackdrop?: boolean,
  ): OverlayConfig {
    return new OverlayConfig({
      panelClass: popoverConfig.panelClass,
      hasBackdrop: hasBackdrop,
      backdropClass: popoverConfig.backdropClass,
      positionStrategy: this.getOverlayPositionStrategy(origin, popoverConfig),
      scrollStrategy: this.overlay.scrollStrategies.noop()
    });
  }

  /**
   * Builds position strategy using OverlayPositionBuilder instance.
   *
   * @param    origin  origin html element to connect the overlay to
   * @return  PositionStrategy that is used to pass in OverlayConfig
   */
  private getOverlayPositionStrategy(
    origin: ElementRef,
    popoverConfig: PopoverConfig
  ): PositionStrategy {
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo(origin)
      .withPositions(popoverConfig.preferredPositions)
      .withDefaultOffsetX(popoverConfig.popoverOffsetX)
      .withDefaultOffsetY(popoverConfig.popoverOffsetY)
      .withFlexibleDimensions(false)
      .withPush(true);
    return positionStrategy;
  }
}
