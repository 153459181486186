import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { uniqBy } from 'lodash';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';

/**
 * View protocol name mapper.
 */
export const ProtocolNameMapping = {
  NFS: 'nfsV3',
  NFS4: 'nfsV4',
  SMB: 'smb',
  S3: 's3',
  Swift: 'swift',
};

/**
 * File Services protocol model definition.
 */
export interface ProtocolModel {
  /**
   * Determines whether the protocol should be hidden or not.
   */
  hidden?: boolean;

  /**
   * Protocol value.
   */
  type: string;

  /**
   * Mode of protocol access.
   *   'ReadOnly'
   *   'ReadWrite'
   */
  mode?: 'ReadOnly' | 'ReadWrite';
}

/**
 * Protocol selector component for creation of views.
 *
 * @example
 *   <coh-protocol-selector></coh-protocol-selector>
 */
@Component({
  selector: 'coh-protocol-selector',
  templateUrl: './protocol-selector.component.html',
})
export class ProtocolSelectorComponent {
  /**
   * Form control options.
   */
  @Input() control: UntypedFormControl;

  /**
   * View category info.
   */
  @Input() category: string;

  /**
   * Indicates whether cluster is running in Helios mode.
   */
  @Input() isHelios: boolean;

  /**
   * Mapping of view protocols.
   */
  viewProtocols = ProtocolNameMapping;

  /**
   * Model definition for Backup target protocols.
   */
  readWriteProtocolList: ProtocolModel[] = [
    {
      type: 'NFS',
      mode: 'ReadWrite',
    },
    {
      type: 'NFS4',
      mode: 'ReadWrite',
      hidden: !flagEnabled(this.irisCtx.irisContext, 'ngViewNFS4Enabled'),
    },
    {
      type: 'SMB',
      mode: 'ReadWrite',
    },
  ];

  /**
   * Object services protocols list.
   */
  objectServicesProtocolList: ProtocolModel[] = [
    {
      type: 'S3',
      mode: 'ReadWrite',
    },
    {
      type: 'Swift',
      mode: 'ReadWrite',
    },
  ];

  /**
   * Model definition for read only protocols.
   */
  viewReadOnlyProtocolList: ProtocolModel[] = [
    {
      type: 'S3',
      mode: 'ReadOnly',
    },
  ];

  /**
   * Archive services protocols list.
   */
  archiveServicesProtocolList: ProtocolModel[] = [
    {
      type: 'NFS',
      mode: 'ReadWrite',
    },
    {
      type: 'NFS4',
      mode: 'ReadWrite',
      hidden: !flagEnabled(this.irisCtx.irisContext, 'ngViewNFS4Enabled'),
    },
    {
      type: 'SMB',
      mode: 'ReadWrite',
    },
    {
      type: 'S3',
      mode: 'ReadWrite',
    },
  ];

  /**
   * Returns read/write protocol list based on view category.
   */
  get viewReadWriteProtocols() {
    const currentValue = [];
    (this.control.value || []).forEach(value => {
      if (value.mode === 'ReadOnly') {
        currentValue.push(value.type);
      }
    });

    if (this.category === 'ObjectServices') {
      return this.objectServicesProtocolList.filter(protocol => !currentValue.includes(protocol.type));
    }

    if (this.category === 'ArchiveServices') {
      return this.archiveServicesProtocolList.filter(protocol => !currentValue.includes(protocol.type));
    }

    return this.readWriteProtocolList.filter(protocol => !currentValue.includes(protocol.type));
  }

  /**
   * Determines whether swift read/write protocol is selected.
   */
  get isSwiftOnly() {
    if (this.category !== 'ObjectServices') {
      return false;
    }

    const currentValue = [];

    (this.control.value || []).forEach(value => {
      if (value.mode === 'ReadWrite' && value.type === 'Swift') {
        currentValue.push(value.type);
      }
    });

    return currentValue.length > 0;
  }

  /**
   * Returns read only protocols list.
   */
  get viewReadOnlyProtocols() {
    const currentValue = [];
    (this.control.value || []).forEach(value => {
      if (value.mode === 'ReadWrite') {
        currentValue.push(value.type);
      }
    });
    return this.viewReadOnlyProtocolList.filter(protocol => !currentValue.includes(protocol.type));
  }

  constructor(
    private irisCtx: IrisContextService,
  ) { }

  /**
   * This is used when value for mat-select is an object
   * Compares view protocols for selection
   *
   * @param p1    view protocol.
   * @param p2    view protocol.
   */
  compareProtocols(p1: ProtocolModel, p2: ProtocolModel): boolean {
    if (p1 && p2) {
      return p1.type === p2.type && p1.mode === p2.mode;
    }
    return false;
  }

  /**
   * Method called on selection change of view protocols.
   *
   * @param   change   protocol selection change.
   * @param   mode     mode of protocol selection eg ReadOnly/ReadWrite.
   */
  updateViewProtocols(change: MatSelectChange, mode: string) {
    const previousValue = this.control.value.filter(value => value.mode !== mode);
    this.control.setValue(uniqBy((previousValue || []).concat(change.value), 'type'));
  }

}
