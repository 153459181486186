import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { AclGrant, AclGrantee, UserServiceApi, UsersList } from '@cohesity/api/v2';
import { CreateForm, createFormProviders } from '@cohesity/shared-forms';
import { AutoDestroyable } from '@cohesity/utils';
import { takeUntilDestroyed } from 'ngx-sub-form';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';

import { AclPermission } from '../../models';

/**
 * Acl dialog form model.
 */
interface AclFormModel {
  grantee: AclGrantee;
  readPermission?: boolean;
  writePermission?: boolean;
  aclReadPermission?: boolean;
  aclWritePermission?: boolean;
  fullPermission?: boolean;
  userSearchValue?: string;
}

interface AclUserPermission {
  type: 'RegisteredUser';
  userId: string;
  displayName: string;
}

@Component({
  selector: 'coh-s3-acl-dialog',
  templateUrl: './s3-acl-dialog.component.html',
  styleUrls: ['./s3-acl-dialog.component.scss'],
  providers: [createFormProviders(S3AclDialogComponent)],
})
export class S3AclDialogComponent extends AutoDestroyable implements OnInit {
  /**
   * Indicated whether it's edit mode or create mode.
   */
  isEditMode: boolean;

  /**
   * Hard coded group selection.
   */
  readonly groups = [
    {
      type: 'Group',
      group: 'AuthenticatedUsersGroup',
    },
    {
      type: 'Group',
      group: 'AllUsersGroup',
    },
    {
      type: 'Group',
      group: 'LogDeliveryGroup',
    }
  ];

  /**
   * ACL Permission users selection model.
   */
  users: AclUserPermission[];

  /**
   * Indicates whether user API is loading or not.
   */
  loadingUser = false;

  /**
   *  Create form
   * */
  form = new CreateForm<AclFormModel>(this, {
    formControls: {
      grantee: new UntypedFormControl(null, Validators.required),
      readPermission: new UntypedFormControl(null),
      writePermission: new UntypedFormControl(null),
      aclReadPermission: new UntypedFormControl(null),
      aclWritePermission: new UntypedFormControl(null),
      fullPermission: new UntypedFormControl(null),
      userSearchValue: new UntypedFormControl(null),
    },
  });

  constructor(
    private usersService: UserServiceApi,
    public dialogRef: MatDialogRef<S3AclDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: AclGrant
  ) {
    super();
  }

  ngOnInit(): void {
    this.getUsers();
  }

  /**
   * Get all users registered to current cluster.
   */
  getUsers() {
    this.loadingUser = true;
    const userParams: UserServiceApi.GetUsersParams = {};
    this.usersService.GetUsers(userParams)
      .pipe(
        finalize(() => this.loadingUser = false),
        takeUntilDestroyed(this),
      )
      .subscribe((users: UsersList) => {
        const usersWith3AccountId = users.users.filter(user => !!user.s3AccountParams?.s3AccountId);
        this.users = usersWith3AccountId.map(user => ({
          type: 'RegisteredUser',
          userId: user.s3AccountParams?.s3AccountId,
          displayName: user.username,
        }));

        this.subscribeToUserSearchChanges();

        if (this.dialogData) {
          this.isEditMode = true;
          this.initForm(this.dialogData);
        }
      });
  }

  /**
   * Initialize form using current ACL.
   *
   * @params   aclGrant   The current acl permission.
   */
  initForm (aclGrant: AclGrant) {
    const {grantee, permissions} = aclGrant;
    const currentAclPermission: AclFormModel = {
      grantee: grantee,
      fullPermission: permissions.includes('FullControl'),
      readPermission: permissions.includes('Read'),
      writePermission: permissions.includes('Write'),
      aclReadPermission: permissions.includes('ReadACP'),
      aclWritePermission: permissions.includes('WriteACP'),
      userSearchValue: null,
    };
    this.form.formGroup.patchValue(currentAclPermission);
  }

  /**
   * Search user function.
   */
  subscribeToUserSearchChanges() {
    const originalUserList = [...this.users];
    this.form.formGroup.controls.userSearchValue.valueChanges.pipe(
      takeUntilDestroyed(this),
      debounceTime(400),
      distinctUntilChanged(),
    ).subscribe((searchString: string) => {
      if (!!searchString && searchString?.trim() !== '' && searchString?.trim() !== null) {
        const filteredList = this.users.filter(user =>
          user.displayName.toLowerCase().includes(searchString.toLowerCase()));
        this.users = filteredList;
      } else {
        this.users = originalUserList;
      }
    });
  }

  /**
   * Close dialog and emit form values to parent component.
   */
  closeDialog() {
    if (this.isEditMode) {
      this.dialogRef.close(this.convertFormValueToAclConfigObject());
    } else {
      this.dialogRef.close();
    }
  }

  /**
   * Create or edit ACL.
   */
  modifyAcl() {
    if (!this.form.formGroup.valid) {
      this.form.formGroup.markAllAsTouched();
      return;
    }
    this.dialogRef.close(this.convertFormValueToAclConfigObject());
  }

  /**
   * Convert form value to API friendly AclGrant object.
   *
   * @return   AclGrant object.
   */
  private convertFormValueToAclConfigObject(): AclGrant {
    const {
      grantee,
      readPermission,
      writePermission,
      aclReadPermission,
      aclWritePermission,
      fullPermission,
    } = this.form.value;

    // If user user, then should delete displayName property.
    if (grantee.type === 'RegisteredUser') {
      delete (grantee as any).displayName;
    }

    const permissionList: AclPermission[] = [];
    if (readPermission) {
      permissionList.push('Read');
    }
    if (writePermission) {
      permissionList.push('Write');
    }
    if (aclReadPermission) {
      permissionList.push('ReadACP');
    }
    if (aclWritePermission) {
      permissionList.push('WriteACP');
    }
    if (fullPermission) {
      permissionList.push('FullControl');
    }

    return {
      grantee: grantee,
      permissions: permissionList,
    };
  }

  /**
   * Helper function to determine which user/group is selected.
   */
  compareUserOrGroupSelection(object1: AclGrantee, object2: AclGrantee): boolean {
    return object1.group === object2.group || object1.userId === object2.userId;
  }

}
