import { Injectable } from '@angular/core';
import { SourceServiceApi } from '@cohesity/api/v2';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { DmsService } from 'src/app/core/services';
import { CommonDmsClusterConnectionService } from './common-dms-cluster-connection.service';

import { DmsConnectionService } from './dms-connection.service';
import { DmsConnection, DmsConnectionMetric } from './models/dms-connection';
import { DmsConnector } from './models/dms-connector';

/**
 * This service is used for the connections page and connections tab only.
 */
@Injectable()
export class DmsConnectionListService {
  /**
   * connection Stats Subject for the glance bar.
   */
  private statsSubject = new BehaviorSubject<DmsConnectionMetric>(null);

  /**
   * Async connection Stats for the glance bar.
   */
  stats$ = this.statsSubject.asObservable();

  /**
   * Observable of sources and source registration map. Source registration map
   * is used to find the association with connections.
   */
  sources$ = this.commonDmsClusterConnectionService.getSourceDetails();
  /**
   * Observable of connection list with stats.
   */
  connections$ = this.commonDmsClusterConnectionService.getConnectionsList().pipe(
    // Updates the stats for each connection, if called outside after filters are applied,
    // the associated APIs are called twice so placed here.
    switchMap((connections: DmsConnection[]) => {
      if (!connections?.length) {
        return of([]);
      }
      return this.getConnectionStats(connections);
    }),
  );

  /**
   * Observable of connection list with connector groups and connectors.
   */
  connectionsWithChildren$ = this.commonDmsClusterConnectionService.getConnectionsList(undefined, undefined, true).pipe(
    // Updates the stats for each connection, if called outside after filters are applied,
    // the associated APIs are called twice so placed here.
    switchMap((connections: DmsConnection[]) => {
      if (!connections?.length) {
        return of([]);
      }
      return this.getConnectionStats(connections);
    }),
  );

  /**
   * Observable of connection list with stats and connectors.
   */
  connectionsWithConnectors$ = this.commonDmsClusterConnectionService.getConnectionsList().pipe(
    // Gets connectors for each connection
    switchMap((connections: DmsConnection[]) => {
      if (!connections?.length) {
        return of([]);
      }
      return forkJoin(
        connections.map(connection => this.dmsConnectionService.getConnectors(connection.groupId))
      ).pipe(
        map((connectorsList: DmsConnector[][]) => {
          connections.forEach((connection, index) => {
            connection.connectors = connectorsList[index];
            if (connectorsList[index]?.length) {
              connection.lastConnectionTimeUsecs = Math.max(
                ...connectorsList[index].map(c => c.lastConnectionTimeUsecs)
              );
            }
          });
          return connections;
        })
      );
    }),

    // Updates the stats for each connection, if called outside after filters are applied,
    // the associated APIs are called twice so placed here.
    switchMap((connections: DmsConnection[]) => this.getConnectionStats(connections)),
  );

  /**
   * Observable of connection list with stats and connectors.
   */
  connectionsWithGroups$ = this.commonDmsClusterConnectionService.getConnectionsList(null, null, true).pipe(
    // Updates the stats for each connection, if called outside after filters are applied,
    // the associated APIs are called twice so placed here.
    switchMap((connections: DmsConnection[]) => this.getConnectionStats(connections)),
  );

  constructor(
    protected commonDmsClusterConnectionService: CommonDmsClusterConnectionService,
    protected dmsConnectionService: DmsConnectionService,
    protected dmsService: DmsService,
    protected mcmSourceService: SourceServiceApi,
  ) {}

  /**
   * Gets all the stats for each connection. This may be triggered separately so it is inside
   * a separate function.
   *
   * @param    connections  Connections.
   * @param    metrics List of metrics to be retrieved
   * @returns  Observable of connections with stats data in it.
   */
  getConnectionStats(connections: DmsConnection[]): Observable<DmsConnection[]> {
    if (!connections?.length) {
      return of([]);
    }
    return forkJoin(
      (connections || []).map(connection => {
        // Do not get stats if connection is not connected or setup is in progress.
        if (['NotConnected', 'SetupInProgress'].includes(connection.status)) {
          return of({});
        }
        return this.dmsConnectionService.getAllStats(connection.groupId);
      })
    ).pipe(
      map((statsList: DmsConnectionMetric[]) => {
        connections.forEach((connection, index) => {
          statsList[index].Utilization = this.dmsConnectionService.getUtilization(statsList[index]);
          connection.stats = statsList[index];
        });
        return connections;
      }),
    );
  }

  /**
   * Observable of overall connection stats.
   */
  getStats(connections: DmsConnection[]): DmsConnectionMetric {
    const sum = connections.reduce(
      (a, b) => ({
        Cpu: a.Cpu + (b.stats.Cpu || 0),
        Memory: a.Memory + (b.stats.Memory || 0),
        ReadIos: a.ReadIos + (b.stats.ReadIos || 0),
        WriteIos: a.WriteIos + (b.stats.WriteIos || 0)
      }),
      {
        Cpu: 0,
        Memory: 0,
        ReadIos: 0,
        WriteIos: 0
      }
    );

    // CPU, memory are in percentage so we use average
    // Read, write Iops are accumulated/grouped so we use sum
    const stats: DmsConnectionMetric = {
      Cpu: connections.length ? (sum.Cpu / connections.length) : 0,
      Memory: connections.length ? (sum.Memory / connections.length) : 0,
      ReadIos: sum.ReadIos,
      WriteIos: sum.WriteIos,
      Connected: 0,
      NotConnected: 0,
      SetupInProgress: 0
    };

    // Count the connections based on status
    connections.forEach(connection => stats[connection.status]++);
    return stats;
  }
}
