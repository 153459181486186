import { Component, Input, Output } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { REGEX_FORMATS } from 'src/app/shared/constants/formats.constants';

/**
 * File extension list component.
 *
 * @example
 *   <coh-file-extension-list
 *     [mode]="'Whitelist'"
 *     [extensionList]="previousList"
 *     (valueChange)="onChangeHandler($event)">
 *   </coh-file-extension-list>
 */
@Component({
  selector: 'coh-file-extension-list',
  templateUrl: './file-extension-list.component.html',
  styleUrls: ['./file-extension-list.component.scss'],
})
export class FileExtensionListComponent {
  /**
   * File extension list.
   */
  @Input() extensionList: string[];

  /**
   * File system settings selection mode. Default value is 'Blacklist'.
   */
  @Input() mode = 'Blacklist';

  /**
   * Specifies whether only S3 protocol is selected or not.
   */
  @Input() isS3Only = false;

  /**
   * File extension list custom message based on operation.
   */
  @Input() message = '';

  /**
   * Whether user can add/remove file extensions. Defaults to true;
   */
  @Input() enableAction = true;

  /**
   * Emits value changes in extension list.
   */
  @Output() valueChange = new Subject<string[]>();

  /**
   * File extension input.
   */
  fileExtension = new UntypedFormControl(null, [Validators.pattern(REGEX_FORMATS.extension)]);

  /**
   * Method called to add extensions into the extension list.
   */
  addExtensions() {
    // File extension input string value.
    const value = this.fileExtension.value;
    const extensionList = this.extensionList || [];

    // Exit early if no string submitted.
    if (!value || this.fileExtension.invalid) {
      return;
    }

    // Splitting single string into array of extensions and iterate over the list.
    value.split(/[,\s]+/).forEach((extension: string) => {
      // Trim everything before and including the last period or asterisk.
      // eslint-disable-next-line no-useless-escape
      extension = extension.trim().replace(/.*[\.\*]/g, '');

      // If extension is not a duplicate, then add it. Otherwise, ignore it.
      if (extension && !extensionList.includes(extension)) {
        extensionList.push(extension);
        this.valueChange.next(extensionList);

        this.extensionList = extensionList;
      }
    });

    // Reset text input
    this.fileExtension.reset();
  }

  /**
   * Method called to remove file extension from the extension list.
   *
   * @param   extension   selected file extension
   */
  removeExtension(extension: string) {
    this.extensionList = this.extensionList.filter(value => value !== extension);
    this.valueChange.next(this.extensionList);
  }

}
