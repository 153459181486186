// MODULE: Recover Archive Task Detail Page

// TODO(spencer): After review and LG:
// - Clean up @jsdocs, time permitting

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.recovery')
    .controller('recoveryTaskArchiveDetailController',
      recoveryTaskArchiveDetailControllerFn);

  function recoveryTaskArchiveDetailControllerFn(
    $rootScope, $scope, $state, $stateParams, $timeout, evalAJAX,
    RestoreService, ExternalTargetService, JobService, cMessage, _,
    ENUM_RESTORE_TYPE, ENUM_RESTORE_TASK_STATUS, FEATURE_FLAGS) {

    var $ctrl = this;

    // Timeout holder
    var reloadTimeout;

    // Includes recover & clone task types for SQL & Oracle.
    var DATABASE_RESTORE_TYPES = [4, 7];

    _.assign($scope, {
      ENUM_RESTORE_TASK_STATUS: ENUM_RESTORE_TASK_STATUS,
      ENUM_RESTORE_TYPE: ENUM_RESTORE_TYPE,
      text: _.assign({}, $rootScope.text.protectionRecoveryDetail,
        $rootScope.text.protectionRecoveryDetailLocal),
      dataReady: false,
      ngRecoveryEnabled: FEATURE_FLAGS.ngRecovery,

      // retrieve archive task details from the API
      pollData: {},
      objects: [],
      view: {},
      job: {},
      expandedRows: [],
      restoreTaskSubTaskVec: [],
      retrievalInProgress: false,
      retrievalStatus: null,
      retrievalStatusErrorMessage: null,
      archiveJobType: null,
      cancelTask: cancelTask,
      teardownTask: teardownTask,
      canTearDownTask: RestoreService.canTearDownTask,
      canCancelTask: RestoreService.canCancelTask,
      downloadFile: downloadFile,
    });

    /**
     * Initialize this controller!
     *
     * @method    init
     */
    function init() {
      _.assign($ctrl, {
        canRetryRestoreTask: RestoreService.canRetryRestoreTask,
        retryRestoreTask: RestoreService.retryRestoreTask,
      });

      getTaskDetails().then(
        function getTaskSuccess(task) {
          var objects = _getTaskObjects(task.performRestoreTaskState);

          if (!task) {
            // Invalid data response, likely an invalid ID was passed to API
            // YE SHALL NOT PASSS!!!
            $state.go('recover');
            return;
          }

          // GET JOB DATA
          if (objects.length) {
            JobService.getJob(_getJobUid(task.performRestoreTaskState).objectId)
              .then(
                function getJobSuccess(job) {
                  $scope.job = job;
                },
                evalAJAX.errorMessage
              );
          }

          // Get External Target Info
          if (_.get(objects, '[0].archivalTarget.vaultId')) {
            ExternalTargetService.getTarget(objects[0].archivalTarget.vaultId)
              .then(
                function getTargetSuccess(externalTarget) {
                  $scope.externalSourceTarget = externalTarget;
                }
              );
          }

          // QUESTION: Does anyone remember why this is a copy instead of the
          // direct object ref to the array?
          $scope.objects = angular.copy(objects);

          // This is a File recover Task. Do some File recover things. This
          // shoehorns in support for recover file from archive. Not elegant,
          // but expedient.
          if (task.performRestoreTaskState.restoreFilesTaskState) {
            processFilesTask(task);
          }

          if (task.performRestoreTaskState.progressMonitorTaskPath) {
            /* Set endpoint for task API call. Assigning this Fn to two vars
             * because the include templates for DB Cloud tasks are shared with
             * Local tasks, which was written separately and uses different
             * method names.
             */
            $scope.restoreTaskEndpoint =
            $scope.taskStatusURL = [
              'progressMonitors?taskPathVec=',
              task.performRestoreTaskState.progressMonitorTaskPath,
              '&includeFinishedTasks=true&excludeSubTasks=true'
            ].join('');
          }

          // Set Type and Progress Flags
          if ($scope.taskStatusURL && task._status < 3) {
            $scope.retrievalInProgress = true;
          }

        }
      )
      .finally(function getTaskFinally() {
        $scope.dataReady = true;
        if ($scope.restoreTaskType === ENUM_RESTORE_TYPE.kRecoverDisks) {
          $scope.isVmdkRecoverTask = true;
          _initVmdk();
        }
      });
    }

    /**
     * Gets teh jobUid of a RestoreTask object. Accounts for differences in RestoreTasks by type.
     *
     * @method   _getJobUid
     * @param    {object}   taskState   The given RestoreTask.TaskState
     * @return   {object}   The found jobUid object, or an empty object.
     */
    function _getJobUid(taskState) {
      // These would be DB & App restore task objects.
      return _.get(taskState, 'retrieveArchiveTaskVec[0].jobUid') ||
        // This would be most other tasks.
        _.get(taskState, 'objects[0].jobUid', {});
    }

    /**
     * Gets the list of restore Objects from the given task. This accounts for
     * the inconsistencies in the RestoreTask protos by type and just gives the
     * requested objects.
     *
     * @method   _getTaskObjects
     * @param    {object}   taskState   The RestoreTask to get Objects from.
     * @return   {array}    The list of objects found on the task.
     */
    function _getTaskObjects(taskState) {
      var objects = taskState.objects;

      if (!_.get(taskState, 'base')) { return; }

      if (Array.isArray(objects) && !$scope.isSharePointRecoveryTask) {
        return objects;
      }

      switch (true) {
        // DB & App Recovery
        case !!taskState.restoreAppTaskState:
          objects =
            taskState.restoreAppTaskState.restoreAppParams.restoreAppObjectVec;
          break;

        // Not sure which type this is?
        case !!_.get(taskState, 'restoreInfo.restoreEntityVec'):
          objects = taskState.restoreInfo.restoreEntityVec;
          break;
      }

      // Return the found objects Array, or an empty one.
      return objects || [];
    }

    /**
     * Cancel this task.
     *
     * @method     cancelTask
     */
    function cancelTask() {
      var cancelTaskParam = {
        id: $stateParams.id,
      };

      var restoreInfo = $scope.restoreTask.performRestoreTaskState.restoreInfo;

      var mountVolumesTaskState =
        $scope.restoreTask.performRestoreTaskState.mountVolumesTaskState;

      if (restoreInfo) {
        _.merge(cancelTaskParam, {
          entityType: restoreInfo.type,
        });
      }

      // For mount volume restore task, entityType is available on
      // mountVolumesTaskState
      if (mountVolumesTaskState && mountVolumesTaskState.mountInfo) {
        _.merge(cancelTaskParam, {
          entityType: mountVolumesTaskState.mountInfo.type,
        });
      }

      // Show a confirmation modal before canceling.
      RestoreService.cancelTaskModal(cancelTaskParam).then(reloadOnTimeout);
    }

    /**
     * Gets the archiveTarget of a given RestoreTask.  Accounts for differences in RestoreTask types.
     *
     * @method   _getArchiveTarget
     * @param    {object}   taskState   The given RestoreTask.TaskState
     * @return   {object}   The found archiveTarget, or an empty object.
     */
    function _getArchiveTarget(taskState) {
      // First we get the archiveTarget if it's a cloud task (DBS)
      return _.get(taskState, 'retrieveArchiveTaskVec[0].archivalTarget') ||
        // Next we try teh more common location.
        _.get(taskState, 'objects[0].archivalTarget', {});
    }

    /**
     * Get the task by id.
     *
     * @method    getTaskDetails
     * @param     {integer}   id    The task id
     * @return    {object}          Promise to resolve with the task object, or
     *                              a the raw server response if error.
     */
    function getTaskDetails() {
      return RestoreService.getArchiveTask($stateParams.id).then(
        function getTaskSuccess(task) {
          if (!task) {
            // Invalid data response, likely an invalid ID was passed to API
            $state.go('recover');
            return;
          }

          $scope.restoreTask = task;


          // The Restore Task type
          $scope.restoreTaskType = task.performRestoreTaskState.base.type || 1;

          // AD tasks will show as 4, but have a restoreInfo type of 29.
          // Check for this here and update the restoreTaskType if necessary.
          if (_.get($scope,
            'restoreTask._envTaskParams.restoreInfo.type') === 29) {
            $scope.isAdRecoverTask = true;
          }

          // Set Archive Job Type
          $scope.archiveJobType =
            _getArchiveTarget(task.performRestoreTaskState).type || 0;

          // As per magneto proto, there is no targetEntity for file download
          // task. However, targetEntity is available for file recover task.
          // 3: kRestoreFiles 14: kDownloadFiles
          $scope.isFileDownloadTask =
            [3, 14].includes($scope.restoreTaskType) &&
            !$scope.restoreTask.performRestoreTaskState.restoreFilesTaskState
              .restoreParams.targetEntity;

          // kConvertToPst
          $scope.isPstExportRecoveryTask =
            $scope.restoreTaskType === ENUM_RESTORE_TYPE.kConvertToPst;

          // SharePoint recovery
          $scope.isSharePointRecoveryTask =
            $scope.restoreTaskType === ENUM_RESTORE_TYPE.kRecoverSites;

          // Reference ENUM_RESTORE_TYPE for types
          $scope.isDbRecoverTask =
            DATABASE_RESTORE_TYPES.includes($scope.restoreTaskType) &&
            !$scope.isAdRecoverTask;

          // Kubernetes recovery
          $scope.isKubernetesRecoverTask =
            $scope.restoreTaskType === ENUM_RESTORE_TYPE.kRecoverNamespaces;

          return task;
        },
        evalAJAX.errorMessage
      );
    }

    /**
     * Handles the pulse iteration response.
     *
     * @method    archiveProgressCallback
     * @param     {object}   pulse   The pulse response.
     */
    $scope.archiveProgressCallback = function archiveProgressCallback(pulse) {
      if (typeof(pulse) === 'object') {
        if (pulse.progress.status.errorMsg) {
          $scope.retrievalInProgress = false;
          $scope.retrievalStatusErrorMessage = pulse.progress.status.errorMsg;
        }
        if (pulse.progress.eventVec) {
          $scope.retrievalStatusEventMessage =
            pulse.progress.eventVec[0].eventMsg;

          $scope.restoreTaskSubTaskVec = pulse.progress.eventVec;
        }
      }
      // If completed, hide the progress monitor
      if (typeof(pulse) === 'string') {
        $scope.retrievalInProgress = false;
        reloadOnTimeout();
      }
    };

    /* Assigning this Fn to two vars because the include templates for DB Cloud
     * tasks are shared with Local tasks, which was written separately and uses
     * different method names.
     */
    $scope.appTaskCallback = $scope.archiveProgressCallback;

    /**
     * Calls the getTask function on a timeout
     *
     * @method     reloadOnTimeout
     *
     * @param   {Number}    Number of milliseconds
     */
    function reloadOnTimeout(intervalMsec) {
      reloadTimeout = $timeout(init, intervalMsec || 5000);
    }

    /**
     * Tear down this task.
     *
     * @method     teardownTask
     * @param      {object}    [task]    The restoreTask object to tear down.
     *                                   Defaults to the $scope copy.
     */
    function teardownTask(task) {
      task = task || $scope.restoreTask;
      // Show a confirmation modal before destroying.
      RestoreService.teardownTaskModal(task)
        .then(function teardownStarted() {
          // call for a reload so user can see when
          // destroy has completed automatically
          reloadOnTimeout();
        });
    }

    /**
     * Recover File task response processing. See comments within for specific
     * processing tasks.
     *
     * @method   processFilesTask
     * @param   {object}   restoreTask
     */
    function processFilesTask(restoreTask) {
      // Generate the pulse URL to show retrieval progress
      $scope.taskStatusURL = generatePulseUrl(restoreTask);

      // Set up the objects for display in the details table
      if ($scope.isPstExportRecoveryTask) {
        $scope.objects = $scope.restoreTask.performRestoreTaskState.objects;
      } else {
        $scope.objects =
          RestoreService.decorateRestoreTaskObjects($scope.restoreTask);
      }

      setCloudDownloadReady();
    }

    /**
     * Sets the cloud download ready state. Polling download task object until
     * download is ready.
     *
     * @method   setCloudDownloadReady
     */
    function setCloudDownloadReady() {
      // If it is not download task, return
      if (!$scope.isFileDownloadTask && !$scope.isPstExportRecoveryTask) {
        return;
      }

      // Polling for isCloudDownloadReady state only for in-progress download
      // task
      if ($scope.restoreTask.performRestoreTaskState.base.publicStatus ===
        'kRunning') {
        $scope.isDownloadReady = false;
        reloadOnTimeout(10000);
      } else {
        $scope.isDownloadReady = true;
        $scope.downloadFileName =
          RestoreService.getDownloadFileName($scope.restoreTask);
        $timeout.cancel(reloadTimeout);
      }
    }

    /**
     * Common helper method to generate a cPulse url from a given
     * task object.
     *
     * NOTE: This may eventually diverge based on recovery type.
     *
     * @method    generatePulseUrl
     * @param     {object}   task   The restoreTask object derive
     *                              the url from.
     * @returns   {string|undefined}    The URL generated, or undefined if not
     *                                  applicable.
     */
    // TODO(spencer): Post 4.0, move this to cPulse service and point all
    //                instances there instead.
    function generatePulseUrl(task) {
      // If the requisite properties are missing, bail out early.
      if (!(task && task.performRestoreTaskState &&
        task.performRestoreTaskState.progressMonitorTaskPath)) {
        return;
      }

      return [
        'progressMonitors?taskPathVec=',
        task.performRestoreTaskState.progressMonitorTaskPath,
        '&includeFinishedTasks=true&excludeSubTasks=false'
      ].join('');
    }

    /**
     * Download the cloud snapshot from download task.
     *
     * @method   downloadFile
     */
    function downloadFile() {
      var taskState = $scope.restoreTask.performRestoreTaskState;

      if (!taskState.objects[0]) {
        return;
      }

      RestoreService.downloadFileFromRecoveryTask(taskState);

      // Set cMessage data
      cMessage.success({
        textKey: 'protectionRecoveryDetail.downloadSuccessText',
        textKeyContext: {
          restoreTask: $scope.restoreTask,
          fileName: $scope.downloadFileName,
        },
      });
    }

    /**
     * Restore vmdk specific initialization routine.
     *
     * @funciton   _initVmdk
     */
    function _initVmdk() {
      $ctrl.vmdkTabsConfig = [{
        headingKey: 'details',
        route: 'recover-detail-archive.vmdk-details',
      }, {
        headingKey: 'activityLog',
        route: 'recover-detail-archive.vmdk-log',
      }];

      // Set the entry page.
      if ($state.current.name === 'recover-detail-archive') {
        $state.go('recover-detail-archive.vmdk-details');
      }
    }

    // Init the things!
    init();

    // clean up on $scope destroy. removes timeout if active
    $scope.$on('$destroy', function cleanup() {
      $timeout.cancel(reloadTimeout);
    });
  }

})(angular);
