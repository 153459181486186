import { Recovery, Recoveries } from '@cohesity/api/v2';
/**
 * Data structure for stat item in `StatListComponent`.
 */
export interface StatListItem<T> {
  /**
   * Value displayed to the right of icon.
   */
  value?: string | number;

  /**
   * Small text displayed under the icon.
   */
  label?: string;

  /**
   * Optional icon for stat list.
   */
  icon?: string;

  /**
   * Status object used to generate icons as alternative to `icon`.
   */
  status?:  T[];

  /**
   * Will display progress bar instead of value field.
   */
  progress?: number;

  /**
   * Optional duration value in microseconds displayed with progress bar.
   */
  duration?: number;

  /**
   * State for UIRouter state management
   */
  state?: string;

  /**
   * State parameters for UIRouter state management
   */
  stateParams?: any;
}

/**
 * Data structure for StatList
 */
export type StatList<T> = StatListItem<T>[];

/**
 * Maps the status labels to the status used via api.
 */
export const statlabelStatusMap = {
  metSla: 'kMet',
  missedSla: 'kMissed',
  succeeded: 'Succeeded',
  warning: 'SucceededWithWarning',
  failed: 'Failed',
  running: 'Running',
  canceled: 'Canceled',
  skipped: 'Skipped',
};

/**
 * This function takes in a response from the recovery api
 * and transforms it into a statlist model.
 *
 * @param response - the response from the recovery status api.
 * @returns a statlist model containing all the possible recovery status.
 */
export function createStatListFromRecoveries<T>(response: Recoveries): StatList<T> {
  const stats: Record<string, number> = {};

  // tally the numbers per status
  (response?.recoveries || []).forEach((recovery: Recovery) => {
    if (!stats[recovery.status]) {
      stats[recovery.status] = 0;
    }
    stats[recovery.status]++;
  });

  return [
    {
      label: 'succeeded',
      icon: 'helix:status-success-alt!success',
      value: stats.Succeeded,
    },
    {
      label: 'warning',
      icon: 'helix:status-warning-alt!warning',
      value: stats.SucceededWithWarning,
    },
    {
      label: 'failed',
      icon: 'helix:status-error-alt!critical',
      value: stats.Failed,
    },
    {
      label: 'running',
      icon: 'helix:status-in-progress-alt!info',
      value: stats.Running,
    },
    {
      label: 'canceled',
      icon: 'helix:status-cancel-alt!warning',
      value: stats.Canceled,
    },
  ] as StatList<T>;
}
