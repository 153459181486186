import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Range } from '@cohesity/api/argus';
import { joinUrl } from '@cohesity/helix';
import { appConfigMap } from '@cohesity/iris-core';
import { AutoDestroyable } from '@cohesity/utils';
import { TranslateService } from '@ngx-translate/core';
import { SeriesPieOptions } from 'highcharts';
import { ObservableInput } from 'ngx-observable-input';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

/**
 * Format for legend data.
 */
interface LegendData {
  label: string;
  value: number;
}

/**
 * Pattern Sensitive Distribution card data.
 */
export interface PatternSensitiveDistribution {
  /**
   * Count of high sensitivity patterns.
   */
  numHighSensitivityPatterns: number;

  /**
   * Count of medium sensitivity patterns.
   */
  numMediumSensitivityPatterns: number;

  /**
   * Count of low sensitivity patterns.
   */
  numLowSensitivityPatterns: number;
}

/**
 * @description
 * Component that visualizes sensitive data distribution across all know patterns
 *
 * @example
 *   <dg-sc-pattern-sensitive-distribution [data]="cardData"></dg-sc-pattern-sensitive-distribution
 */
@Component({
  selector: 'dg-sc-pattern-sensitive-distribution',
  templateUrl: './pattern-sensitive-distribution.component.html',
  styleUrls: ['./pattern-sensitive-distribution.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatternSensitiveDistributionComponent extends AutoDestroyable implements OnInit {

  /**
   * link to patterns page
   */
  readonly reviewLink = `/${joinUrl([appConfigMap.securityCenter.urlSegment, 'data-classification/patterns'])}`;

  /**
   * The data recieved by the component using which chart data is generated.
   */
  @ObservableInput() @Input() data: BehaviorSubject<PatternSensitiveDistribution>;

  /**
   * Loading state of the card.
   */
  @Input() loading = false;

  /**
   * A count of total number of patterns
   */
  totalPatterns = 0;

  /**
   * data using which the chart would be rendered
   */
  chartData: SeriesPieOptions;

  /**
   * list of lengends
   */
  chartLegends: LegendData[] = [];

  constructor(readonly translate: TranslateService) {
    super();
  }

  ngOnInit(): void {
    this.data.pipe(
      filter(data => !!data),
      this.untilDestroy(),
    ).subscribe((data) => {
      this.updateChartData(data);
    });
  }

  /**
   * Updates chart data based on the received input
   */
  updateChartData(data: PatternSensitiveDistribution) {
    this.totalPatterns = data.numHighSensitivityPatterns +
      data.numMediumSensitivityPatterns +
      data.numLowSensitivityPatterns;

    const dataSet = [
      {
        y: data.numHighSensitivityPatterns,
        name: this.translate.instant(`argus.enum.range.${Range.high}.sensitivity`),
      },
      {
        y: data.numMediumSensitivityPatterns,
        name: this.translate.instant(`argus.enum.range.${Range.medium}.sensitivity`),
      },
      {
        y: data.numLowSensitivityPatterns,
        name: this.translate.instant(`argus.enum.range.${Range.low}.sensitivity`),
      },
    ];

    this.chartData = {
      type: 'pie',
      data: dataSet,
    };

    this.chartLegends = dataSet.map(entry => ({ label: entry.name, value: entry.y }));
  }
}
