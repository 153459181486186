;(function(angular, undefined) {
  'use strict';

  var moduleDeps = [ 'C.retention', 'C.selectReplicationTarget' ];

  angular
    .module('C.backupNow', moduleDeps)
    .controller('JobBackupNowController', jobBackupNowControllerFn);

  /**
   * Controller Fn
   *
   * @method   jobBackupNowControllerFn
   */
  function jobBackupNowControllerFn($uibModalInstance, jobId, UserService,
    clusterId, nodeId, _, JobDetailsService, JobBackupNowDbService,
    JobBackupNowService, $timeout, JobService, JobRunsService, PolicyService,
    RemoteClusterService, evalAJAX, cMessage, DEFAULT_DAYS_TO_KEEP,
    FEATURE_FLAGS, ENV_TYPE_CONVERSION, HADOOP_ENVIRONMENTS, NOSQL_ENVIRONMENTS,
    PubJobService, NgProtectionGroupService, NgClusterService, ExternalTargetService,
    PubSourceService, NgProtectionGroupServiceApi) {

    var $ctrl = this;

    _.assign($ctrl, {
      okDisabled: true,
      usePolicyDefaults: FEATURE_FLAGS.runNowPolicyDefaultsEnabled,
      FEATURE_FLAGS: FEATURE_FLAGS,

      // Initialize config object for c-entity-tree
      treeConfig: {
        // To be added after getting Job from API.
        // job: undefined
        objectsList: [],
        treeOptions: {
          entityTreeHeaderText: 'backupNowModal.entityTreeHeader',
          showEntityTreeHeader: true,
          hideExcludedNodes: true,
          showActionIcons: false,
          enableCheckbox: true,
          onUpdate: onNodeSelectionChange,
        },
      },
      backupNowDetails: {
        // If a nodeId is passed, then we need to preselect the passed node and
        // show the tree first. Otherwise start with protecting everything in
        // the job.
        backupAll: !nodeId,
        selectedBackupType: {},
      },
      backupAllOptions: [
        {
          displayKey: 'backupNowModal.backupAllObjects',
          value: true,
        },
        {
          displayKey: 'backupNowModal.backupOnlySelectedObjects',
          value: false,
        },
      ],
      replicationOptions: ['remoteCluster'],

      replicationTargets: [],
      archivalTargets: [],
      cloudVaultTargets: [],
      preSetArchivals: [],
      disabledArchivalTargetIds: [],
      disabledReplicationTargetIds: [],
      archivalPolicies: {},
      replicationPolicies: {},


      isCloudArchiveDirect: false,

      hasRpaasTargets: false,

      // Methods
      $onInit: $onInit,
      backupNow: backupNow,
      canModifyConfig: canModifyConfig,
      cancel: cancel,
      cloudEditionEnabled: cloudEditionEnabled,
      canUserArchive: canUserArchive,
      canUserReplicate: canUserReplicate,
      changeBackupType: changeBackupType,
      getTargets: getTargets,
      isLogRetentionSelected: isLogRetentionSelected,
      insertNewConfig: insertNewConfig,
      isArchivalEnabled: isArchivalEnabled,
      isBackupAllowed: isBackupAllowed,
      isReplicationAllowed: isReplicationAllowed,
      isRpaasEnabled: isRpaasEnabled,
      isSourceNotInPolicy: isSourceNotInPolicy,
      removeConfig: removeConfig,
      retentionValidationCheck: retentionValidationCheck,
      usePolicyDefaultsToggled: usePolicyDefaultsToggled,
    });

    /**
     * Loads the Job before calling follow-up function to load other necessary
     * data.
     *
     * @method   $onInit
     */
    function $onInit() {

      // If a clusterId was provided/resolved, then set the modal context.
      if (clusterId) {
        RemoteClusterService.setModalClusterContext(clusterId);
      }

      JobService.getJob(jobId, {
        // returnCompleteSourceTree query parameter
        // returns the complete source tree in case of autoprotect of
        // the container node for one drive & mailbox
        returnCompleteSourceTree: true,
      }).then(
        function getJobSuccess(job) {
          $ctrl.job = $ctrl.treeConfig.job = job;

          $ctrl.isSqlJob = !!_.get(job, 'envBackupParams.sqlBackupJobParams');
          $ctrl.isOracleJob = job.type === ENV_TYPE_CONVERSION.kOracle;
          $ctrl.isExchangeJob = job.type === ENV_TYPE_CONVERSION.kExchange;
          $ctrl.isNoSQLHadoopJob = HADOOP_ENVIRONMENTS.includes(ENV_TYPE_CONVERSION[job.type]) ||
            NOSQL_ENVIRONMENTS.includes(ENV_TYPE_CONVERSION[job.type]);
          $ctrl.isUdaJob = job.type === ENV_TYPE_CONVERSION.kUDA;

          $ctrl.isFileStubbingJob = $ctrl.job._isDataMigrationJob;
          $ctrl.isLogBackupJob = false;

          if ($ctrl.isNoSQLHadoopJob && job.type === ENV_TYPE_CONVERSION.kCassandra) {
            let cassandraParams = _.get(job, 'envBackupParams.nosqlBackupJobParams.cassandraBackupJobParams');
            if (cassandraParams && cassandraParams.isOnlyLogBackupJob) {
              $ctrl.isLogBackupJob = cassandraParams.isOnlyLogBackupJob;
            }
          }

          _getDependencies();

          NgProtectionGroupService.getGroup(
            `${clusterId}:${job.jobUid.clusterIncarnationId}:${jobId}`,
          ).toPromise().then(group =>
            NgProtectionGroupService.getPolicy(group.policyId).toPromise().then(
              policy => {
                const {
                  backupPolicy: {
                    regular: {
                      primaryBackupTarget: {
                        archivalTargetSettings: {
                          targetId
                        } = {},
                        targetType
                      } = {},
                      retention: {
                        unit: retentionUnit,
                        duration: retentionDuration
                      } = {},
                      logRetention: {
                        unit: logRetentionUnit,
                        duration: logRetentionDuration
                      } = {},
                    } = {}
                  } = {},
                  remoteTargetPolicy: {
                    archivalTargets = [],
                    rpaasTargets = [],
                  } = {}
                } = {} = policy;
                // Check if it is a CAD job.
                $ctrl.isCloudArchiveDirect =
                  (!$ctrl.cloudEditionEnabled() && !FEATURE_FLAGS.ngArchivalForNgceEnabled) &&
                  ($ctrl.job.isDirectArchiveEnabled || targetType === 'Archival');
                $ctrl.hasRpaasTargets = rpaasTargets.length > 0;
                $ctrl.isCascadedReplication = !!(
                  policy.cascadedTargetsConfig && policy.cascadedTargetsConfig.length);

                if ($ctrl.isCloudArchiveDirect && !$ctrl.cloudEditionEnabled()) {
                  const config = generateUniqueTargetConfig();
                  if (targetId) {
                    config.target = {
                      vaultId: targetId
                    };

                    if (retentionDuration && retentionUnit) {
                      config.daysToKeep = moment.duration(retentionDuration, retentionUnit).asDays();
                    }

                    if (logRetentionDuration && logRetentionUnit) {
                      config.daysToKeepLog = moment.duration(logRetentionDuration, logRetentionUnit).asDays();
                    }

                    $ctrl.archivalTargets = [config];
                  } else if (archivalTargets.length > 0) {
                    // in case of CAD v1 use remoteTargetPolicy instead of primaryBackupTarget
                    $ctrl.archivalTargets = _.uniqBy(archivalTargets, target => target.targetId).map(target => {
                      const cfg = {};

                      const {
                        targetId: vaultId,
                        retention: {
                          duration,
                          unit,
                        } = {},
                      } = target;

                      cfg.target = { vaultId };

                      if (duration && unit) {
                        cfg.daysToKeep = moment.duration(duration, unit).asDays();
                      }

                      return cfg;
                    });
                  }
                }
              })
            );
        },
        evalAJAX.errorMessage
      );

      if (FEATURE_FLAGS.awsSnapshotManager) {
        $ctrl.replicationOptions.unshift('aws');
      }
    }

    /**
     * Traverse the tree and mark the node as excluded to prevent them from
     * selection
     *
     * @method   _markNodesExcluded
     * @param     {Object[]}   tree                 The node tree (private proto).
     * @param     {number[]}   excludedIds          Excluded Source Ids
     * @param     {boolean}    parentNodeExcluded   True if parent node is excluded
     */
    function _markNodesExcluded(tree, excludedIds, parentNodeExcluded) {
      // Traverse the tree and mark excluded nodes with exclusions
      tree.forEach(treeNode => {
        // If parent node is excluded then mark all children as excluded
        if (parentNodeExcluded || excludedIds.includes(treeNode.entity.id)) {
          treeNode._isExcluded = true;
          treeNode._isSelected = false;
        }

        // If node has children, recursively mark the children and pass on
        // parent exclusion value to children
        if (treeNode.children) {
          _markNodesExcluded(treeNode.children, excludedIds, treeNode._isExcluded);
        }
      });
    }

    /**
     * Check whether source tree should be fetched for a UDA job.
     *
     * @method   _fetchSourceTreeForUDAJob
     * @return   {Boolean} True if source tree should be fetched for the UDA job.
     */
    function _fetchSourceTreeForUDAJob() {

      // We return true if both the following feature flags - 'udaEntityHierarchyEnabled'
      // and 'udaAllowCustomObjectSelectionForOOBRuns' are enabled and the UDA backup job
      // has 'entity hierarchy' support.
      return FEATURE_FLAGS.udaEntityHierarchyEnabled &&
        FEATURE_FLAGS.udaAllowCustomObjectSelectionForOOBRuns &&
        _.get($ctrl.job, 'envBackupParams.udaBackupJobParams.entitySupport', false);
    }

    /**
     * A helper function to set backup types and the highlighted type.
     *
     * * @param     {Object}    policyDetails    Job policy details.
     * * @param     {Boolean}   skipLogBackup    Whether to skip log backup.
     */
    function _setBackupTypes(policyDetails, skipLogBackup) {
      // Get the correct backup types according to policy details
      let backupTypes =
        JobBackupNowService.buildBackupTypeList(policyDetails, $ctrl.job.parentSource) || [];

      $ctrl.backupTypes = skipLogBackup ? backupTypes.filter((type) => type.enum !== 'kLog') : backupTypes;

      $ctrl.backupNowDetails.selectedBackupType =
        JobBackupNowService.getDefaultBackupType($ctrl.backupTypes, $ctrl.isLogBackupJob);
    }

    /**
     * Activate function populates $ctrl values to be used in template. calls
     * getter functions to populate replication and archival targets arrays.
     *
     * @method   _getDependencies
     */
    function _getDependencies() {
      var policyDetails;

      PolicyService.getPolicy($ctrl.job.policyId
        , true /** force fetch policy, modal loads cached/stale data when loading run now.*/).then(
        function getPolicySuccess(policy) {
          policyDetails = $ctrl.job._policyDetails = policy;

          $ctrl.isCascadedReplication = !!policy.cascadedTargetsConfig.length;
          // Building policy maps.
          policy.snapshotArchivalCopyPolicies.forEach(archivalPolicy => {
            $ctrl.archivalPolicies[archivalPolicy.target.vaultId] = JSON.parse(JSON.stringify(archivalPolicy));
          });
          policy.snapshotReplicationCopyPolicies.forEach(replicationPolicy => {
            $ctrl.replicationPolicies[replicationPolicy.target.clusterId] =
              JSON.parse(JSON.stringify(replicationPolicy));
          });
          // Copy archival targets and replication targets into backup now modal
          if (FEATURE_FLAGS.enableRunNowPolicyArchiveReplicationSettings) {
            const replicationClusterIds = new Set();
            Object.assign($ctrl.archivalTargets,
              policyDetails.snapshotArchivalCopyPolicies);
            Object.assign($ctrl.replicationTargets,
              policyDetails.snapshotReplicationCopyPolicies.map(
                function eachReplication(target) {
                  if (!target.cloudTarget) {
                    target._replicateTo = 'remoteCluster';
                    if (FEATURE_FLAGS.dedupeReplicationTargets && replicationClusterIds.has(target.clusterId)) {
                      return;
                    }
                    replicationClusterIds.add(target.clusterId);
                  } else if (target.cloudTarget.type === 'kAWS') {
                    target._replicateTo = 'aws';
                  }

                  return target;
                }
              ).filter(
                target => !!target
              ));

            // policyDetails.snapshotArchivalCopyPolicies doesn't have all info required to separate RPaaS targets
            // make extra vaults API call to populate data
            ExternalTargetService.getTargets(null, {
              includeMarkedForRemoval: false,
              includeFortKnoxVault: true
            }).then(res => {
              if (res && res.length > 0) {
                const keyBy = _.keyBy($ctrl.archivalTargets, 'target.vaultId');
                res.forEach(target => {
                  const vault = keyBy[target.id];
                  if (vault) {
                    Object.assign(vault.target, target);
                  }
                });

                $ctrl.cloudVaultTargets = $ctrl.archivalTargets.filter(
                  ({target}) => target.vaultOwnership === 'kOwnershipContextFortKnox');
                $ctrl.archivalTargets = $ctrl.archivalTargets.filter(
                  ({target}) => target.vaultOwnership === 'kOwnershipContextLocal');
              }
            });
          }

          // For UDA, if externally triggered runs are enabled,
          // maybe disable log backup run now based on etEnableRunNow.
          let udaJobParams = _.get($ctrl.job, 'envBackupParams.udaBackupJobParams');
          if ($ctrl.isUdaJob && FEATURE_FLAGS.udaEtLogBackup && udaJobParams && udaJobParams.etLogBackup) {
            // Get source info
            let params = {
                allUnderHierarchy: false,
            };
            PubSourceService.getSource($ctrl.job.parentSource.id, params).then(function getSource(source) {
              const udaParams = source[0]?.registrationInfo?.udaParams ?? null;
              const enableLogRunNow = udaParams?.etEnableRunNow ?? false;
              _setBackupTypes(policyDetails, !enableLogRunNow);
            }, evalAJAX.errorMessage);
          } else {
            _setBackupTypes(policyDetails, false);
          }
        },
        evalAJAX.errorMessage
      );

      // Skip fetching source tree for NoSql adapters.ENG-157124.
      // Skip fetching of source tree for UDA jobs which do not have support for
      // entity hierarchy.
      if ($ctrl.isNoSQLHadoopJob || ($ctrl.isUdaJob && !_fetchSourceTreeForUDAJob())) {
        // Hide object and tree related options for NoSql & UDA jobs in the template.
        $ctrl.noObjectsOrEntities = true;
        $ctrl.objectsListLoaded = true;
        $ctrl.okDisabled = false;
      } else {
        JobDetailsService.getSourceTree($ctrl.job).then(
          function gotSources() {
            // For sql and exchange based jobs, process the list a little differently to
            // handle the different selection rules.
            $ctrl.treeConfig.objectsList = ($ctrl.isSqlJob || $ctrl.isExchangeJob) ?
              JobBackupNowDbService.getObjectsList(JobDetailsService.tree[0].children) :
              JobDetailsService.getObjectsList();

            if (!$ctrl.backupNowDetails.backupAll) {
              // TODO: Enhancement: scroll this node into view, if found, to help
              // the User visually locate it. This is hard to do while this is
              // configured as a state controller rather than a component with
              // better lifecycle hooks.
              _markNodeSelected($ctrl.treeConfig.objectsList, nodeId);
            }

            // Some Job types do not have objects or entities so related options
            // in the template will be hidden.
            $ctrl.noObjectsOrEntities = JobService.isJobTypeWithoutEntities(
              $ctrl.treeConfig);

            // For a SQL job, if there are exclude filters in the job, apply those
            // exclusions to the tree and disable the nodes from selection
            if ($ctrl.isSqlJob && $ctrl.job.sourceFilters &&
              $ctrl.job.sourceFilters.excludeSourceFilterVec) {
                PubJobService.getExcludeSourceIds($ctrl.job).then(
                  function getExcludeIds(excludedIds) {
                    // Mark the excluded nodes which need to be disabled
                    _markNodesExcluded($ctrl.treeConfig.objectsList, excludedIds);
                  }
                );
            }

            $ctrl.objectsListLoaded = true;
            $ctrl.okDisabled = false;
          }, evalAJAX.errorMessage);
      }
    }

    /**
     * Find the given nodeId in the tree and mark the node as selected for
     * backup.
     *
     * @function   _markNodeSelected
     * @param      {Object[]}   tree     The node tree (private proto).
     * @param      {Number}     nodeId   The nodeId to locate and mark.
     * @returns    {Boolean}    True when the node is found. False when not
     *                          found.
     */
    function _markNodeSelected(tree, nodeId) {
      // Nothing to do without both args
      if (!nodeId || !_.get(tree, 'length')) { return false; }

      tree.find(function findSelectedNode(node) {
        if (node.entity.id === nodeId) {
          node._isSelectedForBackup = true;

          // Delay this a bit to let the DOM render first. It's a little jarring
          // for Users to potentially flash a double modal immediately. This may
          // open a challenge/confirmation modal if it's an AAG or System DB.
          $timeout(onNodeSelectionChange.bind(null, node), 750);

          // We've found our node: cease the search.
          return true;
        }

        return node.entity.vmwareEntity && !FEATURE_FLAGS.sqlSelectDatabasesVMware ?
          // If this is a vmwareEntity and the feature is disabled, we can't
          // select children, so move the search along to the next node tree.
          false :

          // Otherwise, recurse down this node's children to look for the node.
          !!_markNodeSelected(node.children, nodeId);
      });
    }

    /**
     * Sends selected data from modal to JobService to start job, modal will
     * close on success.
     *
     * @method   backupNow
     */
    function backupNow() {
      var selectedModalData;
      var objectsList;
      if ($ctrl.jobBackupNowForm.$invalid) { return; }

      $ctrl.submitting = true;

      // For a list of dbs, we need extract only the selected databases from the
      // tree selection.
      objectsList = $ctrl.backupNowDetails.backupAll ? [] :
        $ctrl.treeConfig.objectsList;

      if (objectsList.length) {
        objectsList =
          JobBackupNowService.getSelectedObjects(objectsList, $ctrl.job);
      }

      if (!$ctrl.backupNowDetails.backupAll && !objectsList.length) {
        cMessage.error({
          textKey: 'jobActionService.noObjectSelectedToBackup',
        });
        $ctrl.submitting = false;
        return;
      }

      const startJobPromise =
        FEATURE_FLAGS.enableV2ApiForDbAdaptor ? startJobV2(objectsList) : startJobV1(objectsList);

      startJobPromise.then(
        function startJobSuccess(response) {
          cMessage.success({
            textKey: 'jobActionService.runSuccess.text',
            timeout: 4000,
          });

          RemoteClusterService.clearModalClusterContext();
          $uibModalInstance.close(response);
        }, evalAJAX.errorMessage)
        .finally(function startJobFinished() {
          $ctrl.submitting = false;
        });
    }

    /**
     * V1 version of Start job API
     *
     * @method   startJobV1
     * @param    {object}  objectsList list of objects to backup.
     * @returns  Start job promise from V1 API
     */
    function startJobV1(objectsList) {
      const selectedModalData = {
        archivalTargets: [...$ctrl.archivalTargets, ...$ctrl.cloudVaultTargets],
        replicationTargets: $ctrl.replicationTargets,
        objectsList: objectsList,
        runType: $ctrl.backupNowDetails.selectedBackupType.enum,
        usePolicyDefaults: $ctrl.usePolicyDefaults,
      };

      return JobService.startJob(selectedModalData, $ctrl.job?.jobId);
    }

    /**
     * V2 version of Start job API
     *
     * @method   startJobV2
     * @param    {object}  objectsList list of objects to backup.
     * @returns  Start job promise from V2 API
     */
    function startJobV2(objectsList) {
      const bodyParams = {
        runType: $ctrl.backupNowDetails.selectedBackupType.enum,
        objects: objectsList,
        targetsConfig: {
          archivals: [...$ctrl.archivalTargets, ...$ctrl.cloudVaultTargets],
          replications: $ctrl.replicationTargets,
          usePolicyDefaults: $ctrl.usePolicyDefaults,
        }
      };
      const { clusterId, clusterIncarnationId, objectId } = $ctrl.job?.jobUid || {};
      const groupId = `${clusterId}:${clusterIncarnationId}:${objectId}`;
      return NgProtectionGroupServiceApi.CreateProtectionGroupRun({
        id: groupId,
        body: bodyParams,
      })?.toPromise();
    }

    /**
     * Closes modal no values passed
     *
     * @method   cancel
     */
    function cancel() {
      RemoteClusterService.clearModalClusterContext();
      $uibModalInstance.dismiss('cancel');
    }

    /**
     * Return true if cluster is NGCE.
     *
     * @method  cloudEditionEnabled
     * @return  {Boolean}   True if cluster is NGCE
     */
    function cloudEditionEnabled() {
      return NgClusterService.isClusterNGCE;
    }

    /**
     * Change Backup type for the new run
     *
     * @method   changeBackupType
     * @param    {Object}   backupType   The backup type object
     */
    function changeBackupType(backupType) {
      if (backupType.enum === 'kLog') {
        // Exit early and skip teh rest if this flag is on and this is a SQL
        // job.
        if (isArchivalEnabled()) { return; }

        // If we select log backup, then we need to hide archival and empty the
        // archival targets list after saving a copy as it might be needed if
        // backup type is again changed back to incremental/full.
        $ctrl.preSetArchivals = _.cloneDeep($ctrl.archivalTargets);
        $ctrl.archivalTargets.length = 0;
      } else if ($ctrl.archivalTargets.length === 0) {
        $ctrl.archivalTargets = _.cloneDeep($ctrl.preSetArchivals);
      }
    }

    /**
     * Gets a new unique target configuration.
     *
     * @method   generateUniqueTargetConfig
     * @return   {object}   The unique configuration.
     */
    function generateUniqueTargetConfig() {
      return { daysToKeep: DEFAULT_DAYS_TO_KEEP };
    }

    /**
     * Returns targets array based on type
     */
    function getTargets(type) {
      switch (type) {
        case 'archive': return $ctrl.archivalTargets;
        case 'cloudVault': return $ctrl.cloudVaultTargets;
        default: return $ctrl.replicationTargets;
      }
    }

    /**
     * Adds a Repication or Archival Config to the list.
     *
     * @method   insertNewConfig
     * @param    {string}   [type='replicate']   The type: 'replicate', or
     *                                           'archive'.
     */
    function insertNewConfig(type) {
      // Determine which targets list to add to.
      var list = getTargets(type);

      list.push(generateUniqueTargetConfig());
    }

    /**
     * Removes a Replication or Archival Config from the list.
     *
     * @method   removeConfig
     * @param    {string}   [type='replicate']   The type: 'replicate', or
     *                                           'archive'.
     * @param    {object}   removedTarget      The target config to remove.
     */
    function removeConfig(type, removedTarget) {
      // Determine which targets list to remove from.
      var list = getTargets(type);
      var disabledList;

      // Loop over the selected list until the matching config object is found
      // and remove it.
      list.some(function removeGivenTarget(target, index) {
        if (angular.equals(removedTarget, target)) {
          list.splice(index, 1);
        }
      });

      // When target is removed it needs to be enabled again for selection
      disabledList = (type === 'archive' || type === 'cloudVault') ?
        $ctrl.disabledArchivalTargetIds :
        $ctrl.disabledReplicationTargetIds;

      JobRunsService
        .removeTargetFromDisabledList(disabledList, type, removedTarget);
    }

    /**
     * Handle changes when an item in the tree is clicked. For sql file based
     * jobs and Exchange jobs we need to apply additional selection logic.
     *
     * @method onNodeSelectionChange
     * @param   {object}   node
     */
    function onNodeSelectionChange(node) {
      if ($ctrl.isSqlJob) {
        JobBackupNowDbService.updateNodeSelection(
          JobDetailsService.tree[0].children,
          node
        );
      }
      if ($ctrl.isExchangeJob) {
        JobBackupNowDbService.updateExchangeNodeSelection(
          JobDetailsService.tree[0].children,
          node
        );
      }

      // Expand SharePoint site if there is a sub-site.
      // This informs user that we do not automatically select sub-sites
      // when the parent site is selected. Parent site & sub-sites can be
      // protected independently.
      if (node._isSharePointSite &&
        node._inJobDescendants &&
        node._inJobDescendants.length) {
        node._isExpanded = true;
      }
    }

    /**
     * Determines if the archival is supported for a given job
     *
     * @method   isArchivalEnabled
     * @return   {Boolean}   True, if archival is supported, false otherwise.
     */
    function isArchivalEnabled() {
      if ($ctrl.isSqlJob && FEATURE_FLAGS.sqlLogArchival) {
        return true;
      }

      if ($ctrl.isOracleJob && FEATURE_FLAGS.oracleLogArchivalEnabled) {
        return true;
      }

      if ($ctrl.isUdaJob && FEATURE_FLAGS.udaLogArchivalEnabled) {
        return true;
      }
      // Archive should be enabled for NGCE cluster.
      if ($ctrl.cloudEditionEnabled() && FEATURE_FLAGS.ngArchivalForNgceEnabled) {
        return true;
      }

      return $ctrl.backupNowDetails.selectedBackupType.enum !== 'kLog';
    }

    /**
     * Function to determine if backup form is enabled for a job or not
     *
     * @method  isBackupAllowed
     * @return  {Boolean}   True if backup form is enabled. False otherwise
     */
    function isBackupAllowed() {
      return !$ctrl.isFileStubbingJob;
    }

    /**
     * Disables adding and removing form config items for archival and replication.
     * Enable config edits for NGCE clusters for both archive and replication.
     *
     * @method  canModifyConfig
     * @param   {string}   [type='replicate']   The type: 'replicate', or 'archive'.
     * @return  {Boolean}   True if add config button is enabled. False otherwise
     */
    function canModifyConfig(type) {
      return !$ctrl.isCloudArchiveDirect || (type === 'replicate' && cloudEditionEnabled() ||
        (cloudEditionEnabled && FEATURE_FLAGS.ngArchivalForNgceEnabled));
    }

    /**
     * Check whether user has access to archive.
     *
     * @method   canUserArchive
     * @return   {Boolean}   True, if user can access archive.
     */
    function canUserArchive() {
      return UserService.user.privs.CLUSTER_EXTERNAL_TARGET_VIEW;
    }

    /**
     * Check whether user has access to replicate.
     *
     * @method   canUserReplicate
     * @return   {Boolean}   True, if user can access replicate.
     */
    function canUserReplicate() {
      return UserService.user.privs.CLUSTER_REMOTE_VIEW;
    }

    /**
     * Function to determine if replication is enabled for a job or not
     *
     * @method  isReplicationAllowed
     * @return  {Boolean}   True if replication is enabled. False otherwise
     */
    function isReplicationAllowed() {
      return !$ctrl.isFileStubbingJob &&
      (!$ctrl.isCloudArchiveDirect || cloudEditionEnabled()) ||
        ($ctrl.isCascadedReplication &&
          FEATURE_FLAGS.cascadedReplicationAndArchivalEnabled);
    }

    /**
     * Check whether a source is available as a replication target in the
     * policy
     *
     * @method isSourceNotInPolicy
     * @param  {Object}  source The source to be checked
     * @return {Boolean} True if source is not in current policy
     */
    function isSourceNotInPolicy(source) {
      return (!($ctrl.job._policyDetails.snapshotReplicationCopyPolicies || [])
        .find(function findSource(policy) {
          return _.get(policy, 'cloudTarget.id') === source.protectionSource.id;
        }));
    }

    /**
     *  Validate if the given retention is short than its datalock setting.
     *
     * @method retentionValidationCheck
     * @param integer retention
     * @param string  type
     * @param integer target
     */
    function retentionValidationCheck(retention, type, target) {
      let targetPolicy;

      if (!target || !type) {
        return;
      }

      switch (type) {
        case 'replicate':
          targetPolicy = $ctrl.replicationPolicies[target.clusterId];
          break;

        case 'archive':
          targetPolicy = $ctrl.archivalPolicies[target.id];
          break;
      }

      target._targetRetentionDateError = targetPolicy && targetPolicy.datalockConfig &&
        targetPolicy.datalockConfig.daysToKeep > retention;
    }

    /**
     * If policy defaults toggle is turned off, we show the default targets
     * according to the policy targets. So dependencies are loaded when the
     * toggle is turned off.
     *
     * @method usePolicyDefaultsToggled
     */
    function usePolicyDefaultsToggled() {
      if (!$ctrl.usePolicyDefaults) {
        _getDependencies();
      }
    }

    /**
     * Checks if RPaaS target can be added.
     *
     * @method isRpaasEnabled
     */
    function isRpaasEnabled() {
      return $ctrl.hasRpaasTargets && !$ctrl.usePolicyDefaults &&
      $ctrl.isArchivalEnabled() && !$ctrl.isFileStubbingJob &&
      $ctrl.canUserArchive() && !$ctrl.cloudEditionEnabled();
    }

    /**
     * Returns boolean to show retention options based on selected run type.
     *
     * @method isLogRetentionSelected
     * @param  {object} target   selected target
     * @return {boolean} boolean to show retention options.
     */
    function isLogRetentionSelected(target) {
      return $ctrl.backupNowDetails.selectedBackupType?.enum === 'kLog' &&
        target?.daysToKeepLog >= 0 &&
        FEATURE_FLAGS.enableLogRetentionForExternalTargets;
    }
  }
})(angular);
