// MODULE: Cluster Setup

;(function(angular, undefined) {
  'use strict';

  angular.module('C.clusterSetup', [])
    .config(clusterSetupConfigFn)
    .controller('clusterSetupParentController', ClusterSetupParentControllerFn);

  function clusterSetupConfigFn($stateProvider) {

    $stateProvider
      .state('cluster-setup', {
        name: 'Cohesity Cluster Setup',
        url: '/admin/cluster/setup',
        parentState: 'cluster',
        canAccess: 'true',
        views: {
          '': {
            templateUrl: 'app/views/page-layouts/ls.html',
            controller: 'clusterSetupParentController'
          },
          'col-l@cluster-setup': {
            templateUrl: 'app/platform/nodes/add/add.html',
          }
        }
      })
      .state('cluster-setup.detect', {
        url: '^/admin/cluster/setup/detect',
        parentState: 'cluster',
        canAccess: 'true',
        views: {
          'col-l@cluster-setup': {
            templateUrl: 'app/admin/cluster/setup/detect.html',
            controller: 'clusterSetupDetectController'
          }
        }
      })
      .state('cluster-setup.select', {
        url: '^/admin/cluster/setup/select',
        parentState: 'cluster',
        canAccess: 'true',
        views: {
          'nodes-content@cluster-setup': {
            templateUrl: 'app/platform/nodes/add/discover/discover.html',
            controller: 'clusterSetupNodesSelectController'
          }
        }
      })
      .state('cluster-setup.nodes', {
        url: '^/admin/cluster/setup/nodes',
        parentState: 'cluster',
        canAccess: 'true',
        views: {
          'nodes-content@cluster-setup': {
            templateUrl: 'app/admin/cluster/setup/nodes.html',
            controller: 'clusterSetupNodesController'
          }
        }
      })
      .state('cluster-setup.details', {
        url: '^/admin/cluster/setup/details',
        parentState: 'cluster',
        canAccess: 'true',
        views: {
          'nodes-content@cluster-setup': {
            templateUrl: 'app/admin/cluster/setup/details.html',
            controller: 'clusterSetupDetailsController'
          }
        }
      })
      .state('cluster-setup.confirm', {
        url: '^/admin/cluster/setup/confirm',
        parentState: 'cluster',
        canAccess: 'true',
        views: {
          'col-l@cluster-setup': {
            templateUrl: 'app/admin/cluster/setup/confirm.html',
            controller: 'clusterSetupConfirmController'
          }
        }
      });
  }

  function ClusterSetupParentControllerFn($rootScope, $scope, $state, $log,
    $transitions, ClusterService, clusterState, localStorageService,
    IP_FAMILY, FORMATS) {

    var _cleanupTransition;

    // if cluster exists, user shouldnt be here.
    if (clusterState.found) {
      $state.goHome();
    } else {
      // Flag indicating user is in setupFlow
      $scope.setupMode = true;
    }

    // Determine if VirtualRobo before rendering setup
    ClusterService.getHardwareInfo().finally(
      function getHardwareInfoFinally() {
        $scope.shared.isHardwareInfoLoaded = true;

        // redirect to detect state if not directly specified
        if ($state.current.name === 'cluster-setup') {
          $state.go('cluster-setup.detect', {}, {
            location: 'replace'
          });
        }
      }
    );

    // Objects Shared between child states
    $scope.shared = {
      discoveredNodes: [],
      discoveredChassis: [],
      newClusterNodeCount: 0,
      clusterSWVersion: null,
      cluster: {},
      isHardwareInfoLoaded: false,
      ipFamily: IP_FAMILY.IPv4,
    };

    // Config object for setup steps
    $scope.addNodeSteps = [{
      label: 'selectNodes',
      active: false,
    }, {
      label: 'networkSettings',
      active: false,
    }, {
      label: 'clusterSettings',
      active: true,
    }];

    /**
     * Set a copy of setup data in local storage to recall later should we
     * encounter setup errors
     *
     * @method     storeSettingsInLocalStorage
     */
    $scope.storeSettingsInLocalStorage = function storeSettingsInLocalStorage() {
      localStorageService.set('newClusterSettings', $scope.shared.cluster);
      localStorageService.set('discoveredNodes', $scope.shared.discoveredNodes);
      localStorageService.set('discoveredChassis', $scope.shared.discoveredChassis);
      localStorageService.set('newClusterNodeCount',  $scope.shared.newClusterNodeCount);
    };

    /**
     * Update cluster setup configuration from local storage if they exist
     *
     * @method     restoreSettingsFromLocalStorage
     */
    $scope.restoreSettingsFromLocalStorage = function restoreSettingsFromLocalStorage() {
      // If the shared cluster object is empty attempt to refresh shared.cluster
      // from local storage. This provides a path for the user to 'GO BACK'
      // without losing their in-progress settings and helps for the case when
      // cluster creation fails after we have reassigned this nodes ip address
      // (and the ip address change forces a window refresh)
      if (!$scope.shared.cluster.nodes &&
        localStorageService.get('newClusterSettings')) {
        $scope.shared.cluster =
          localStorageService.get('newClusterSettings');
      }

      if (!$scope.shared.discoveredNodes.length &&
        localStorageService.get('discoveredNodes')) {
        $scope.shared.discoveredNodes =
          localStorageService.get('discoveredNodes');
      }

      if (!$scope.shared.discoveredChassis.length &&
        localStorageService.get('discoveredChassis')) {
        $scope.shared.discoveredChassis =
          localStorageService.get('discoveredChassis');
      }

      if (!$scope.shared.newClusterNodeCount &&
        localStorageService.get('newClusterNodeCount')) {
        $scope.shared.newClusterNodeCount =
          localStorageService.get('newClusterNodeCount');
      }
    };

    /**
     * Clean up Local Storage Data
     *
     * @method     clearSettingsFromLocalStorage
     */
    $scope.clearSettingsFromLocalStorage = function clearSettingsFromLocalStorage() {
      localStorageService.remove('newClusterNodeCount');
      localStorageService.remove('newClusterSettings');
      localStorageService.remove('discoveredNodes');
      localStorageService.remove('discoveredChassis');
    };

    /**
     * Validate min number of nodes are selected.
     *
     * @method     minNodesSelected
     * @param      {Array}    nodes   array of the nodes to evaluate
     * @return     {Boolean}  true if array includes minimum number of nodes
     */
    $scope.minNodesSelected = function minNodesSelected(nodes) {
      var count = 0;
      angular.forEach(nodes, function isSelected(node) {
        count += node.selected ? 1 : 0;
      });

      // For physical robo clusters, only one node need to be selected.
      if ($rootScope.isPhysicalRobo) {
        return count == 1;
      }
      // TODO: When we add support for Cloud setup, backend should provide
      // number of minimum nodes in the basicClusterInfo API so this value is
      // not hard-coded.
      return count >= 3;
    };

    // Listens for state change and manages c-stepper active state
    _cleanupTransition = $transitions.onSuccess({}, function evalState(trans) {

      // Reset all steps to inactive before marking the correct one as active.
      angular.forEach($scope.addNodeSteps, function parseStep(step) {
        step.active = false;
      });

      switch (trans.$to().name) {
        case 'cluster-setup':
          $scope.addNodeSteps[0].active = true;
          break;
        case 'cluster-setup.select':
          $scope.addNodeSteps[0].active = true;
          break;
        case 'cluster-setup.nodes':
          $scope.addNodeSteps[1].active = true;
          break;
        case 'cluster-setup.details':
          $scope.addNodeSteps[2].active = true;
          break;
      }
    });

    /**
     * Get IP format regex based on ipFamily.
     */
    $scope.getIpFormat = function getIpFormat() {
      return $scope.shared.ipFamily === IP_FAMILY.IPv6 ? FORMATS.IPv6 : FORMATS.IPv4;
    };

    // clean up when $scope is destroyed
    $scope.$on('$destroy', _cleanupTransition);

  }

})(angular);
