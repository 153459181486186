import { Pipe, PipeTransform } from '@angular/core';
import { ByteSizeService } from '@cohesity/helix';
import { TranslateService } from '@ngx-translate/core';

/**
 * Converts provided input numerator & denominator bytes values into there human readable form.
 *
 * Usage:
 *   {{ numerator | bytesFraction : denominator }}
 *
 * Example:
 *   {{ 1024 * 2 | bytesFraction : 1024 * 5 }}               // "2 / 5 KiB"
 *   {{ 1024 * 2 | bytesFraction : 1024 * 1024 * 5 }}        // "2 KiB / 5 MiB"
 *   {{ null | bytesFraction : null }}                       // "-"
 *   {{ 1024 * 2 | bytesFraction : null }}                   // "-"
 *   {{ null | bytesFraction : 1024 * 5 }}                   // "-"
 */
@Pipe({
  name: 'bytesFraction',
})
export class BytesFractionPipe implements PipeTransform {
  constructor(
    private byteSizeService: ByteSizeService,
    private translate: TranslateService,
  ) {}

  /**
   * Transform the empty values inputs to hyphen(-) else keep the original input.
   *
   * @param numerator The input value.
   * @param customNaText The custom not available text and if present then it will be used in place of 'naNotAvailable'
   * translation.
   */

  /**
   * Transform the the provided numerator & denominator into human readable form.
   *
   * @param numerator The numerator value.
   * @param denominator The denominator value.
   */
  transform(numerator: number, denominator: number): string {
    if (typeof numerator !== 'number' || typeof denominator !== 'number') {
      return this.translate.instant('naNotAvailable');
    }

    const numeratorByteSize = this.byteSizeService.bytesToSize(numerator);
    const denominatorByteSize = this.byteSizeService.bytesToSize(denominator);
    const key = numeratorByteSize.unit === denominatorByteSize.unit ? 'size' : 'displayValue';

    return `${numeratorByteSize[key]} / ${denominatorByteSize.displayValue}`;
  }
}
