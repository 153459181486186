/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityApiConfiguration as __Configuration } from '../cohesity-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { McmGetSupportCasesResponseBody } from '../models/mcm-get-support-cases-response-body';
@Injectable({
  providedIn: 'root',
})
class HeliosSupportService extends __BaseService {
  static readonly McmGetSupportCasesPath = '/public/mcm/supportCases';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get support cases for a given account.
   *
   * Get support cases for a given account.
   * @param params The `HeliosSupportService.McmGetSupportCasesParams` containing the following parameters:
   *
   * - `status`: Specifies the status to filter the support cases.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `maxCount`: Specifies the number of support cases to be returned in the response. Default value is 25.
   *
   * - `from`: Specifies the starting index to fetch the support cases. Default value is zero.
   *
   * @return Success
   */
  McmGetSupportCasesResponse(params: HeliosSupportService.McmGetSupportCasesParams): __Observable<__StrictHttpResponse<McmGetSupportCasesResponseBody>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.status != null) __params = __params.set('status', params.status.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.maxCount != null) __params = __params.set('maxCount', params.maxCount.toString());
    if (params.from != null) __params = __params.set('from', params.from.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/mcm/supportCases`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<McmGetSupportCasesResponseBody>;
      })
    );
  }
  /**
   * Get support cases for a given account.
   *
   * Get support cases for a given account.
   * @param params The `HeliosSupportService.McmGetSupportCasesParams` containing the following parameters:
   *
   * - `status`: Specifies the status to filter the support cases.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `maxCount`: Specifies the number of support cases to be returned in the response. Default value is 25.
   *
   * - `from`: Specifies the starting index to fetch the support cases. Default value is zero.
   *
   * @return Success
   */
  McmGetSupportCases(params: HeliosSupportService.McmGetSupportCasesParams): __Observable<McmGetSupportCasesResponseBody> {
    return this.McmGetSupportCasesResponse(params).pipe(
      __map(_r => _r.body as McmGetSupportCasesResponseBody)
    );
  }
}

module HeliosSupportService {

  /**
   * Parameters for McmGetSupportCases
   */
  export interface McmGetSupportCasesParams {

    /**
     * Specifies the status to filter the support cases.
     */
    status?: 'kInProgress' | 'kClosed' | 'kResolved' | 'kOpen' | 'kWaitingForCustomer' | 'kWaitingRMA' | 'kNew' | 'kPending' | 'kUnknown';

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Specifies the number of support cases to be returned in the response. Default value is 25.
     */
    maxCount?: null | number;

    /**
     * Specifies the starting index to fetch the support cases. Default value is zero.
     */
    from?: null | number;
  }
}

export { HeliosSupportService }
