// Clone Task Details

;(function(angular, undefined) {
  'use strict';

    var configOptions = {
    controller: 'cloneDetailCtrl',
    template: require('raw-loader!./detail.html').default,
    bindings: {
      /**
       * Optional attribute. If present, this is the default back state to navigate to if there is no history
       */
      backState: '<?',
    }
  };


  angular.module('C.devOps')
    .controller('cloneDetailCtrl', CloneDetailCtrlFn)
    .config(ConfigFn)
    .component('cloneDetail', configOptions);

  function ConfigFn($stateProvider) {
    $stateProvider
      .state('clone-detail', {
        url: '/more/devops/clone/detail/{id}',
        help: 'devops_clone_detail',
        title: 'Clone Detail',
        canAccess: 'CLONE_VIEW',
        params: {
          id: { type: 'string' },
        },
        component: 'cloneDetail',
        parentState: 'devops.clone',
      });
  }

  function CloneDetailCtrlFn(_, $rootScope, $scope, $state, $q, $filter,
    $timeout, cUtils, ViewService, RestoreService, SourceService,
    JobService, DateTimeService, ExternalTargetService,
    CloneService, ClusterService, evalAJAX, PollTaskStatus, ENUM_UI_CLONE_OBJECT_STATUS,
    ENUM_UI_CLONE_OBJECT_STATUS_CLASSNAME, ENV_TYPE_CONVERSION,
    FEATURE_FLAGS, RESTORE_TYPE, RESTORE_TASK_UI_STATUS, StateManagementService) {

    var $ctrl = this;
    var reloadOnTimeout;
    var reloadTimeout;

    // convenience functions and variables
    $scope.ENUM_UI_CLONE_OBJECT_STATUS = ENUM_UI_CLONE_OBJECT_STATUS;
    $scope.ENUM_UI_CLONE_OBJECT_STATUS_CLASSNAME =
      ENUM_UI_CLONE_OBJECT_STATUS_CLASSNAME;
    $scope.ENV_TYPE_CONVERSION = ENV_TYPE_CONVERSION;

    $scope.text = $rootScope.text.devopsCloneDetailDetail;

    $scope.restoreTask = {};
    $scope.pollData = {};
    $scope.dataReady = false;
    $scope.isTaskRunning = false;
    $scope.isTaskScheduled = false;
    $scope.expandedRows = {};
    $scope.cloneTaskType = null;
    $scope.ownerTask = {};

    $scope.canCancelTask = RestoreService.canCancelTask;
    $scope.canTearDownTask = RestoreService.canTearDownTask;
    $scope.isTaskDestroying = RestoreService.isTaskDestroying;

    _.assign($ctrl, {
      canRefreshClone: CloneService.isRefreshable,
      canRetryRestoreTask: RestoreService.canRetryRestoreTask,
      retryRestoreTask: RestoreService.retryRestoreTask,
      RESTORE_TASK_UI_STATUS: RESTORE_TASK_UI_STATUS,
      RESTORE_TYPE: RESTORE_TYPE,
      vlans: null,
    });

    /**
     * Calls the API and updates $scope variables for display
     *
     * @method     getData
     */
    function getData() {
      var promises = [

        // Get Qos Principals
        ViewService.getQosPrincipals(),

        // Retrieve task details from the API
        $scope.getTask(),
      ];

      $ctrl.vlans = ClusterService.clusterInfo._vlans;

      return $q.all(promises).then(
        function getDataSuccess(resps) {
          var taskStatusURL;
          var restoreTaskState;
          var qosPolicies = resps[0];
          var entityVec;

          // if task is admitted or running
          $scope.isTaskRunning = [1, 2].indexOf(resps[1][0]._status) > -1;
          $scope.hasDestroyCloneState = !!resps[1][0].destroyClonedTaskStateVec;
          $scope.isTaskScheduled = [0, 5].includes(resps[1][0]._status);

          // Set up Clone Objects
          restoreTaskState = ($scope.restoreTask.ownerRestoreWrapperProto &&
            $scope.restoreTask.ownerRestoreWrapperProto.performRestoreTaskState) ?
            $scope.restoreTask.ownerRestoreWrapperProto.performRestoreTaskState :
            $scope.restoreTask.performRestoreTaskState;

          // Set cloneObjects based on the restore task type
          switch (restoreTaskState.base.type) {
            // SQL
            case 7:
              $scope.isCloneApp = Array.isArray(
                restoreTaskState.restoreAppTaskState.restoreAppParams.restoreAppObjectVec
              );
              $scope.isCloneServer = !$scope.isCloneApp;

              $scope.cloneObjects = [].concat(

                // SQL DB clone
                restoreTaskState.restoreAppTaskState.restoreAppParams.restoreAppObjectVec ||

                // SQL Server clone
                restoreTaskState.restoreAppTaskState.restoreAppParams.ownerRestoreInfo.ownerObject ||

                // Fallback
                []
              );
              break;

            // Everything else
            default:
              $scope.cloneObjects = restoreTaskState.objects || [];
          }

          $scope.cloneObjects.forEach(
            function setStatusString(object) {
              if (restoreTaskState.restoreInfo &&
                restoreTaskState.restoreInfo.restoreEntityVec) {

                entityVec = restoreTaskState.restoreInfo.restoreEntityVec;
              } else if (restoreTaskState.cloudDeployInfo &&
                restoreTaskState.cloudDeployInfo.cloudDeployEntityVec) {

                entityVec =
                  restoreTaskState.cloudDeployInfo.cloudDeployEntityVec;
              }

              // Copy status for restoreEntityVec.
              if (entityVec) {
                entityVec.forEach(
                  function copyStatusFromRestoreEntity(restoreEntity) {
                    // TODO(shyam): Magneto is not populating entity.id because of a bug.
                    // Remove check with vmwareEntity.uuid once magneto bug is fixed.
                    if (restoreEntity.entity.id === object.entity.id ||
                      (restoreEntity.entity.vmwareEntity &&
                      restoreEntity.entity.vmwareEntity.uuid === object.entity.vmwareEntity.uuid)) {

                      object.status = restoreEntity.status;
                      object.publicStatus = restoreEntity.publicStatus;
                      object.error = restoreEntity.error;
                    }
                  }
                );
              } else if(restoreTaskState?.restoreVmwareVmParams){
                object.status = restoreTaskState.base.publicStatus;
                object.publicStatus = restoreTaskState.base.publicStatus;
              }

              // Set Archive Job Type
              if (object.archivalTarget) {
                object.externalTargetName =
                  ExternalTargetService.targetNameMapById[object.archivalTarget.vaultId];
              }

              // Set Normalized Entity Properties for easy template display
              object._normalizedEntity = SourceService.normalizeEntity(object.entity);
            }
          );

          // Ammend View Params with Qos Policy name for display
          angular.merge($scope.restoreTask.performRestoreTaskState, {
            viewParams: {
              _qosPolicy: $filter('filter')(qosPolicies,
                function filterQosPolicyById(pol) {
                  return (resps[1][0].performRestoreTaskState.viewParams &&
                    resps[1][0].performRestoreTaskState.viewParams.qosMappingVec &&
                    resps[1][0].performRestoreTaskState.viewParams.qosMappingVec.length &&
                    (pol.id === resps[1][0].performRestoreTaskState.viewParams.qosMappingVec[0].principalId));
                }),
            },
          });

          // Set Archive Job Type
          $scope.cloneTaskType = resps[1][0].performRestoreTaskState.base.type;

          // GET JOB DATA
          if ($scope.restoreTask.performRestoreTaskState.objects &&
            $scope.restoreTask.performRestoreTaskState.objects.length &&
            $scope.restoreTask.performRestoreTaskState.objects[0].jobUid) {

            JobService.getJob($scope.restoreTask.performRestoreTaskState.objects[0].jobUid.objectId)
              .then(
                function getJobSuccess(job) {
                  $scope.job = job;
                },

                evalAJAX.errorMessage
              );
          }

          // Set endpoint for task API call
          if (resps[1][0].performRestoreTaskState.progressMonitorTaskPath) {
            taskStatusURL = [
              'progressMonitors?taskPathVec=',
              resps[1][0].performRestoreTaskState.progressMonitorTaskPath,
              '&includeFinishedTasks=true&excludeSubTasks=false',
            ].join('');

            //Attach to scope for passing into cPulse component
            $scope.taskStatusURL = taskStatusURL;
          }

          if ($scope.hasDestroyCloneState &&
            [5, 6].includes(resps[1][0]._status)) {

            // Task is destroying right now. Reload data from Magneto on
            // timeout.
            reloadOnTimeout();

            // Do not pass go, do not collect $200
            return;
          }

          // If one or more tasks are running, we need to kick off the
          // progress monitor loop.
          if ($scope.isTaskRunning && !$scope.hasDestroyCloneState) {
            // Instantiate a poll if we have active tasks
            PollTaskStatus.getTask(taskStatusURL, 5000, 10, canTerminatePoll)
              .then(
                function successPulse(r) {
                  if (r && r._pollingStatus !== 'error') {
                    angular.extend($scope.pollData, r[0].taskVec[0]);
                  } else {
                    $scope.pollError = true;
                  }
                },

                undefined,
                function notifyPulse(r) {
                  angular.extend($scope.pollData, r[0].taskVec[0]);
                }
              );
          } else {
            // Just ping the progress monitor once so we can get the sub task data
            if (taskStatusURL) {
              PollTaskStatus.getTaskNoPoll(taskStatusURL).then(
                function getTaskSuccess(r) {
                  if (r.data.resultGroupVec[0] &&
                    r.data.resultGroupVec[0].taskVec) {
                    $scope.pollData = r.data.resultGroupVec[0].taskVec[0];
                  }
                }
              );
            }
          }

          initCloneRefreshActions();
        },

        function getDataFail(response) {
          $scope.isTaskRunning = false;
          evalAJAX.errorMessage(response);
        }
      ).finally(
        function getDataFinally() {
          // Collapse all expanded rows
          $scope.expandedRows = {};
          $scope.dataReady = true;
        }
      );
    }

    /**
     * Navigates to previous router state. If a back state was supplied as input, we will use that as the fallback
     * state. Otherwise,
     */
    $scope.goBack = function() {
      if ($ctrl.backState) {
        StateManagementService.goToPreviousState($ctrl.backState);
      } else {
        $state.go('devops.clone');
      }

    }

    /**
     * Calls getTask service and updates the restoreTask object.
     *
     * @method     getTask
     */
    $scope.getTask = function getTask() {
      return RestoreService.getTask($state.params.id).then(
        function getTaskSuccess(response) {

          var restoreTaskState;
          var byteRateObj;

          $scope.restoreTask = response[0];

          restoreTaskState = $scope.restoreTask.performRestoreTaskState;

          if (restoreTaskState) {
            if (restoreTaskState.retrieveArchiveTaskUidVec) {
              $scope.isRetrievalTask = true;
            }

            // Update dynamic at-a-glance info
            if (restoreTaskState.retrieveArchiveTaskVec) {
              $scope.restoreTask.retrievalInfo =
                restoreTaskState.retrieveArchiveTaskVec[0].retrievalInfo;

                getArchiveMediaInfo(restoreTaskState);

              // Calculate transfer rate
              byteRateObj =
                cUtils.bytesToSize($scope.restoreTask.retrievalInfo.avgLogicalTransferRateBps, 1);

              // TODO: make this i18n friendly
              if (byteRateObj.string !== $rootScope.text.naNotAvailable) {
                $scope.restoreTask._rate =
                [byteRateObj.string, $scope.text.perSecond].join('');
              }
            }
          }

          return response;
        },

        evalAJAX.errorMessage
      );
    };

    /**
     * Function to retrieve qstar archive tape details
     *
     * @method     getArchiveMediaInfo
     * @param      {object}  restoreTaskState  Restore task data.
     */
    function getArchiveMediaInfo(restoreTaskState) {
      var archivalTaskVec = restoreTaskState.retrieveArchiveTaskVec[0];
      var archivalTaskId = _.get(archivalTaskVec, 'archiveTaskUid');

      $scope.isQstar = [1, 'kTape'].includes(_.get(archivalTaskVec, 'archivalTarget.type'));
      if ($scope.isQstar && Object.keys(archivalTaskId).length) {
          ViewService.getQstarArchivalTapeDetails({
            clusterId: archivalTaskId.clusterId,
            clusterIncarnationId: archivalTaskId.clusterIncarnationId,
            qstarArchiveJobId: archivalTaskId.objectId
          }).then(function getArchiveMediaInfo(res) {
            $scope.archiveMediaInfo = res;
          });
      }
    }

    /**
     * Cancels this task.
     *
     * @method     cancelTask
     */
    $scope.cancelTask = function cancelTask() {
      var restoreTaskState = $scope.restoreTask.performRestoreTaskState;
      // Show a confirmation modal before canceling.
      RestoreService.cancelTaskModal({
        id: $state.params.id,
        entityType:
          _.get(restoreTaskState, 'restoreInfo.type') ||
          _.get(restoreTaskState, 'restoreParentSource.type'),
      }, 'clone').then(reloadOnTimeout);
    };

    /**
     * Determines if the task pulse poll can terminate by evaluating
     * $scope.pollData for errors.
     *
     * @method    canTerminatePoll
     * @returns   {Boolean}   True if the poll is done or can be terminated.
     */
    function canTerminatePoll() {
      return ($scope.pollData.subTaskVec.progress &&
        ($scope.pollData.subTaskVec.progress.status.errorMsg ||
        $scope.pollData.subTaskVec.progress.percentFinished === 100));
    }

    /**
     * presents a challenge modal, open promise.resolve of modal calls the API
     * to destroy the clone task
     *
     * @method     destroyCloneTask
     */
    $scope.destroyCloneTask = function destroyCloneTask() {

      // Collapse all expanded rows
      $scope.expandedRows = {};

      RestoreService.teardownTaskModal($scope.restoreTask)
        .then(
          function destroySuccess(restoreTask) {
            // call for a reload so user can see when
            // destroy has completed automatically
            reloadOnTimeout();
          }
        );
    };

    /**
     * Updates progress monitoring data for each active task based on results
     * from poll.
     *
     * @method     updateAllTasks
     * @param      {object}  pollData  The latest pollData object.
     */
    function updateAllTasks(pollData) {
      // Sanity check
      if (!pollData || !Object.keys(pollData).length) {
        return;
      }

      if (pollData.progress &&
        pollData.progress.eventVec) {
        $scope.ownerTask = $scope.pollData;
      }

      // If no subTasks, finish now.
      if (!pollData.subTaskVec) {
        return;
      }

      // Loop though all subTaskVecs
      angular.forEach(
        pollData.subTaskVec,
        function eachTask(subTask) {
          // VMWare has eventVec field on parent instead of subtasks
          if (!_.get(subTask, 'progress.eventVec') &&
            _.get(pollData, 'progress.eventVec')) {

            _.set(subTask, 'progress.eventVec', pollData.progress.eventVec);
          }

          if ($scope.cloneTaskType === 7) {
            filterSQLTasksAndObjectsStatus(subTask);
          } else {
            filterTaskObjectsStatus(subTask);
          }

        }
      );
    }

    /**
     * Matches Pulse subTask status data to Task Objects
     *
     * @method     filterTaskObjectsStatus
     * @param      {Object}  subTask  The sub task
     */
    function filterTaskObjectsStatus(subTask) {
      $scope.cloneObjects.forEach(

        // Loop though each object in our restoreTask data set
        // We will match the taskPath of each object with the taskPath of each
        // subTask returned from progress monitor API
        function evaluateObject(object, objectIndex) {
          var percentFinishedString;
          var endTimeString;
          var messageString;
          var timeRemaining;

          if (object._taskPath === subTask.taskPath) {

            // Assign a few vars to update our recover task
            percentFinishedString = [
              cUtils.round(subTask.progress.percentFinished),
              '% ',
              $scope.text.completed
            ].join('');
            timeRemaining = subTask.progress.expectedTimeRemainingSecs;
            endTimeString = (timeRemaining > 0) ? [
              DateTimeService.secsToTime(timeRemaining).time,
              $scope.text.remaining
            ].join(' ') : '';

            // Set up Message String for Progress object
            messageString = '';

            if (object.error) {
              // If there is an object specific error, let's show that
              messageString = object.error.errorMsg;
            }

            // Finally update the base task
            $scope.cloneObjects[objectIndex]._progress = {
              percentage: subTask.progress.percentFinished,
              barLabel: percentFinishedString,
              statusLabel: endTimeString,
              endTimeSecs: subTask.progress.endTimeSecs,
              startTimeSecs: subTask.progress.startTimeSecs,
              eventVecs: subTask.progress.eventVec,
              message: messageString,
              statusType: subTask.progress.status.type,
            };

            $scope.cloneObjects[objectIndex]._subTasks = angular.copy(subTask);
          }
        }
      );
    }

    /**
     * Match SQL Clone specific Pulse subTask status data to Task Objects.
     *
     * @method     filterSQLTasksAndObjectsStatus
     * @param      {object}  subTask  The sub task
     */
    function filterSQLTasksAndObjectsStatus(subTask) {
      var percentFinishedString;
      var endTimeString;
      var messageString;
      var timeRemaining;

      // "_restore" included here because that's the task path returned in
      // SQL clone attach flows.
      if (!/(clone_|_restore)/i.test(subTask.taskPath)) {
        return;
      }

      // Assign a few vars to update our recover task
      percentFinishedString = [

        // TODO(spencer): Make this translatable.
        cUtils.round(subTask.progress.percentFinished),
        '% ',
        $scope.text.completed,
      ].join('');
      timeRemaining = subTask.progress.expectedTimeRemainingSecs;
      endTimeString = timeRemaining ?

        // TODO(spencer): Make this translatable.
        [
          DateTimeService.secsToTime(timeRemaining).time,
          $scope.text.remaining
        ].join(' ') : '';

      if ($scope.cloneObjects[0].error) {
        // If there is an object specific error, let's show that
        messageString = $scope.cloneObjects[0].error.errorMsg;
      }

      // Finally update the base task
      $scope.cloneObjects[0]._progress = {
        percentage: subTask.progress.percentFinished,
        barLabel: percentFinishedString,
        statusLabel: endTimeString,
        endTimeSecs: subTask.progress.endTimeSecs,
        startTimeSecs: subTask.progress.startTimeSecs,
        eventVecs: subTask.progress.eventVec,
        message: messageString,
        statusType: subTask.progress.status.type,
      };

      $scope.cloneObjects[0]._subTasks = subTask;
    }

    /**
     * Initializes possible actions on a clone, related to refresh. A clone
     * can be refreshed using the latest backup snapshot, or a PIT between the
     * snapshots.
     */
    function initCloneRefreshActions() {
      $ctrl.cloneRefreshActions = [
        {
          translateKey: 'cloneRefresh.contextMenu.latestSnapshot',
          icon: 'icn-refresh',
          action: function refreshFromLatest() {
            CloneService.confirmCloneRefresh($scope.restoreTask)
              .then(reloadOnTimeout);
          },
        },
        {
          translateKey: 'cloneRefresh.contextMenu.snapshot',
          icon: 'icn-refresh',
          action: function refreshFromPit() {
            CloneService.openPitSelectionModal($scope.restoreTask)
              .then(reloadOnTimeout);
          },
        }
      ];
    }

    /**
     * calls the getData function on a timeout
     *
     * @method     reloadOnTimeout
     */
    reloadOnTimeout = function reloadOnTimeout() {
      reloadTimeout = $timeout(getData, 2000);
    };

    // Watch the pollData object for changes from getTasks
    $scope.$watchCollection('pollData', updateAllTasks);

    // clean up on $scope destroy. removes timeout if active
    $scope.$on('$destroy', function cloneDetailScopeDestroy() {
      $timeout.cancel(reloadTimeout);
    });

    /**
     * Toggles the taskId of expanded rows in $scope.expandedRows
     *
     * @method     toggleExpandedRow
     * @param      {string}  uuid    uuid of a task row to be expanded/collapsed
     */
    $scope.toggleExpandedRow = function toggleExpandedRow(uuid) {
      if ($scope.expandedRows[uuid]) {
        delete $scope.expandedRows[uuid];
      } else {
        $scope.expandedRows[uuid] = true;
      }
    };

    /**
     * Determines if clone refreshes can be shown.
     *
     * @method    canShowCloneRefreshes
     * @returns   {boolean}   `true` if the clone refreshes can be shows,
     *                        `false` otherwise.
     */
    $ctrl.canShowCloneRefreshes = function canShowCloneRefreshes() {
      return FEATURE_FLAGS.oracleCloneRefresh &&
        _.get($scope.restoreTask, '_cloneAppInfo._taskType') ===
        ENV_TYPE_CONVERSION.kOracle;
    };

    /**
     * Callback for cPulse. This is invoked with the progress data everytime
     * the poller runs.
     *
     * @method   updateTaskEvents
     * @param    {Object}   pulseData   The pulse data object, or a string
     *                                  indicating the state of job.
     */
    $ctrl.updateTaskEvents = function updateTaskEvents(pulseData) {
      // The pulse data is sent as string when the task is finished (with or
      // without error) or canceled.
      var isDone = _.isString(pulseData);

      var refreshTask = _.find($scope.restoreTask._refreshInfo._tasks, {
        progressMonitorTaskPath: pulseData.taskPath,
      });

      if (refreshTask && !isDone) {
        refreshTask._pulseEvents = pulseData.progress.eventVec;
      }

      if (isDone) {
        reloadOnTimeout();
      }
    };

    /**
     * Toggles a task's event messages display. If the event messages aren't
     * available, it tries to load those first.
     *
     * @method   toggleTaskEvents
     * @param    {Object}   task   The clone refresh task object.
     */
    $ctrl.toggleTaskEvents = function toggleTaskEvents(task) {
      task._loadingPulse = true;

      if (_.isEmpty(task._pulseEvents)) {
        // Load event messages if those aren't already available.
        PollTaskStatus.getTaskNoPoll(task._progressMonitorURL)
          .then(function taskDataReceived(res) {
            task._pulseEvents = _.get(res, 'data.resultGroupVec[0]' +
              '.taskVec[0].progress.eventVec', []);
          })
          .catch(evalAJAX.errorMessage)
          .finally(function finalizePulseFetch() {
            task._loadingPulse = false;
          });
      } else {
        task._loadingPulse = false;
      }

      // Toggle the pulse events container.
      $scope.toggleExpandedRow(task.base.taskId);
    };

    $ctrl.$onInit = function() {
      // Use a generic text if the back state is supplied, otherwise use the older text that says back to clone.
      $scope.backText = $ctrl.backState ? 'back' : 'devopsCloneDetailDetail.backToClone';

      getData();
      ExternalTargetService.getTargets();
    }
  }

})(angular);
