import { ProtectionSourceNode } from '@cohesity/api/v1';
import { ProtectedObjectInfo } from '@cohesity/api/v2';
import { Environment } from 'src/app/shared';

/**
 * Determine if a protection source node is protected or not based on the protection summary.
 *
 * @param source       The protection source node to check.
 * @param environment  The environment to check for protection. If this is not set, it will use the
 *                     primary environment for the node.
 * @returns            True if the source node is protected.
 */
export function isSourceProtected(source: ProtectionSourceNode, environment?: Environment): boolean {
  if (!source) {
    return false;
  }
  const env = environment || source.protectionSource.environment;
  const protectedSource = (source.protectedSourcesSummary || []).find(sum => sum.environment === env);
  return protectedSource?.leavesCount > 0;
}

/**
 * Function to return whether an object is auto protected or not.
 *
 * @param object The protected object info.
 */
export function isObjectAutoProtected(object: ProtectedObjectInfo): boolean {
  return Boolean(object?.objectBackupConfiguration?.isAutoProtectConfig);
}
