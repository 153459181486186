// Component: Antivirus Threats

;(function(angular, undefined) {
  'use strict';

  angular.module('C.antivirus')
    .component('threats', {
      templateUrl: 'app/antivirus/threats/threats.html',
      controller: threatsCtrlFn,
    });

  /**
   * @ngdoc component
   * @name C.antivirus:threats
   * @function threatsCtrlFn
   *
   * @description
   * Provides component which manages the AV Threats.
   *
   * @example
     <threats></threats>
   */
  function threatsCtrlFn(_, $state, cModal, evalAJAX, cMessage,
    AntivirusService) {

    var $ctrl = this;

    // Batch actions for selected threats.
    var bulkThreatActions = [
      {
        icon: 'icn-quarantine',
        displayKey: 'quarantineFiles',
        labelKey: 'quarantineFiles',
        clickHandler: function confirmUpdateThreat() {
          _confirmUpdateThreat(_getSelectedThreats(), 'kQuarantine')
        },
      },
      {
        icon: 'icn-unquarantine',
        displayKey: 'unquarantineFiles',
        labelKey: 'unquarantineFiles',
        clickHandler: function confirmUpdateThreat() {
          _confirmUpdateThreat(_getSelectedThreats(), 'kUnquarantine')
        },
      },
      {
        icon: 'icn-reset',
        displayKey: 'resetFiles',
        labelKey: 'resetFiles',
        clickHandler: function confirmUpdateThreat() {
          _confirmUpdateThreat(_getSelectedThreats(), 'kReset')
        },
      },
      {
        icon: 'icn-delete',
        displayKey: 'deleteFiles',
        labelKey: 'deleteFiles',
        clickHandler: function confirmDeleteThreat() {
          _confirmUpdateThreat(_getSelectedThreats(), 'kDelete')
        },
      },
    ];

    _.assign($ctrl, {
      // Set initial values from cache. This will be updated after page init.
      securedViews: AntivirusService.getCachedListOfSecuredViews(),
      bulkThreatActions: bulkThreatActions,
      dataLockedViewIds: {},
      fileDataLockedViewIds: {},
      filterOptions: {
        remediation: [
          {
            name: 'quarantined',
            value: 'kQuarantine',
          },
          {
            name: 'unquarantined',
            value: 'kUnquarantine',
          },
        ],
      },
      filters: {
        remediation: undefined,
        viewNames: undefined,
      },
      loadingThreats: true,
      remediationKeys: {
        kQuarantine: 'kQuarantined',
        kUnquarantine: 'kUnquarantined',
      },
      threats: [],
      totalSelected: 0,
      totalNotSelectable: 0,
      viewsHash: {},

      $onInit: $onInit,
      onFilter: onFilter,
      isEverySelectableFileSelected: isEverySelectableFileSelected,
      isWholePageSelected: isWholePageSelected,
      toggleRowSelection: toggleRowSelection,
      toggleSelectAll: toggleSelectAll,
    });

    /**
     * Initializes and fetches data
     *
     * @method   $onInit
     */
    function $onInit() {
      $ctrl.isFiltered = _isFilterActive();
      _setFilterState($state.params);
      _getSecuredViews().then(_discoverDataLockedViews).finally(
        function gotSecuredViewsFinally() {
          _fetchInfectedFiles($state.params);
        }
      );

      // Check for Providers.
      AntivirusService.getAvProviderList().then(
        function gotProviders(providers) {
          if (!providers.length) {
            $ctrl.noProvidersConfigured = true;
          }
        }
      );
    }

    /**
     * Determines if there is an active filter;
     *
     * @method      _isFilterActive
     * @returns     {Boolean}    True if any filter is active.
     */
    function _isFilterActive() {
      var params = $state.params;
      return params.includeUnquarantinedFiles ||
        params.includeQuarantinedFiles ||
        !!params.path ||
        !!params.viewNames;
    }

    /**
     * Fetches the list of Secured Views and updates the filter options. This is
     * issued on page load after the cached values are used for initial
     * rendering.
     *
     * @method   _getSecuredViews
     * @returns  {Object}    Promise to return a list of Secured Views.
     */
    function _getSecuredViews() {
      return AntivirusService.getSecuredViews().then(
        function gotSecuredViews(views) {
          return $ctrl.securedViews = views;
        },
        evalAJAX.errorMessage
      );
    }

    /**
     * Assembles the lists of DataLocked Views and Views with File DataLock
     * enabled.
     *
     * @method   _discoverDataLockedViews
     * @param    {Array}    views     A list of Secured Views.
     */
    function _discoverDataLockedViews(views) {
      views.forEach(
        function forEachView(view) {
          if (view._isDataLockView) {
            $ctrl.dataLockedViewIds[view.viewId] = view;
          } else if (view._isFileDataLockEnabled) {
            $ctrl.fileDataLockedViewIds[view.viewId] = view;
          };
        }
      );
    }

    /**
     * Sets the initial state of filters based on the state params.
     *
     * @method    _setFilterState
     */
    function _setFilterState(params) {
      var filters = $ctrl.filters;

      filters.path = params.path;
      filters.viewNames = params.viewNames;

      switch (true) {
        case _.isUndefined(params.includeQuarantinedFiles):
          filters.remediation = undefined;
          break;

        case !_.isUndefined(params.includeQuarantinedFiles) &&
          params.includeQuarantinedFiles === 'false':
          filters.remediation = 'kUnquarantine';
          break;

        default:
          filters.remediation = 'kQuarantine';
      }
    }

    /**
     * Fetches the list of infected files.
     *
     * @method   _fetchInfectedFiles
     * @param    {Object}    params     The query params.
     */
    function _fetchInfectedFiles(params) {
      params = params || $state.params;
      $ctrl.loadingThreats = true;

      AntivirusService.getThreats(params).then(
        function getThreatsSuccess(threats) {
          $ctrl.threats = threats.map(_addContextMenus);
          toggleSelectAll({target: { checked: false}});
        },
        evalAJAX.errorMessage
      ).finally(function getThreatsDone() {
        $ctrl.loadingThreats = false;
        $ctrl.stats = _compileStats($ctrl.threats);
      });
    }

    /**
     * Compiles a stats for the list of Threats. This is temporary until backend
     * provides the full stats for all threats in the API response.
     *
     * @method    _compileStats
     * @param     {Array}     threats     List of Threats
     * @returns   {Object}    Compiled stats for the list of Threats.
     */
    function _compileStats(threats) {
      var stats = {
        quarantined: 0,
        unquarantined: 0,
        views: 0,
      };
      $ctrl.viewsHash = {};

      threats.forEach(function forEachThreat(threat) {
        if (threat.remediationState === 'kQuarantine') {
          stats.quarantined++;
        } else {
          stats.unquarantined++;
        }

        if (!$ctrl.viewsHash[threat.viewName]) {
          $ctrl.viewsHash[threat.viewName] = threat;
          stats.views++;
        }
      });

      $ctrl.statsReady = true;

      return stats;
    }

    /**
     * Adds context menus to a threat object.
     *
     * @method   _addContextMenus
     * @param    {Object}   threat   Threat object.
     * @returns  {Object}   Decorated threat object.
     */
    function _addContextMenus(threat) {
      // Decorate DataLock states.
      threat._isDataLockView =
        $ctrl.dataLockedViewIds[threat.viewId];
      threat._isFileDataLockEnabled =
        $ctrl.fileDataLockedViewIds[threat.viewId];

      // No actions allowed for a DataLock View.
      if (threat._isDataLockView) {
        return threat;
      }

      if (threat.remediationState === 'kQuarantine') {
        threat._contextMenus = [
          {
            icon: 'icn-unquarantine',
            translateKey: 'unquarantineFile',
            action: function confirmUpdateThreat() {
              _confirmUpdateThreat(threat, 'kUnquarantine')
            }
          },
          {
            icon: 'icn-delete',
            translateKey: 'deleteFile',
            action: function confirmUpdateThreat() {
              _confirmUpdateThreat(threat, 'kDelete')
            }
          }
        ];
      } else {
        threat._contextMenus = [
          {
            icon: 'icn-quarantine',
            translateKey: 'quarantineFile',
            action: function confirmUpdateThreat() {
              _confirmUpdateThreat(threat, 'kQuarantine')
            }
          },
          {
            icon: 'icn-reset',
            translateKey: 'resetFile',
            action: function confirmUpdateThreat() {
              _confirmUpdateThreat(threat, 'kReset')
            }
          },
        ];
      }

      return threat;
    }

    /**
     * Fetches new list of Threats according to the active filters.
     *
     * @method     onFilter
     */
    function onFilter() {
      $state.go('.', _getQueryParams($ctrl.filters), { location: 'replace' });
    }

    /**
     * Assembles the request object to fetch Threats API.
     *
     * @method      _getQueryParams
     * @returns     {Object}    Query params.
     */
    function _getQueryParams(filters) {
      var queryParams = {
        path: filters.path,
        viewNames: filters.viewNames,
      };

      // If the remediation state filter was activated, then include these
      // params. Otherwise, do not to clutter the query string.
      if (_.isUndefined(filters.remediation)) {
        queryParams.includeQuarantinedFiles =
          queryParams.includeUnquarantinedFiles = undefined;
      } else {
        queryParams.includeQuarantinedFiles =
          filters.remediation !== 'kUnquarantine';
        queryParams.includeUnquarantinedFiles =
          filters.remediation !== 'kQuarantine';
      }

      return queryParams;
    }

    /**
     * Reduces the threats to an object of threat ids.
     *
     * @method    _getThreatIds
     * @param     {Object}   threat    Threat
     * @returns   {Object}   Set of threat ids.
     */
    function _getThreatIds(threat) {
      return {
        viewId: threat.viewId,
        entityId: threat.entityId,
        rootInodeId: threat.rootInodeId,
        remediationState: threat.remediationState,
      };
    }

    /**
     * Filters out the selections which are invalid for the intended action.
     *
     * @method    _filterInvalidSelections
     * @param     {List}     threats     List of Threats.
     * @param     {String}   action      The intended action.
     * @returns   {Array}    List of Id objects.
     */
    function _filterInvalidSelections(threats, action) {
      // Only certain actions are valid for a given remediate state.
      var allowedRemediationState =
        ['kReset', 'kQuarantine'].includes(action) ?
        'kUnquarantine' : 'kQuarantine';

      return _.reduce(threats, function(threatIds, threat) {
        if (threat.remediationState === allowedRemediationState) {
          threatIds.push(_getThreatIds(threat));
        }
        return threatIds;
      }, []);
    }

    /**
     * Presents a challenge modal before allowing user to update the remediation
     * state of a Threat.
     *
     * @method    _confirmUpdateThreat
     * @param     {Array|Object}   threats    The Threat object or objects.
     * @returns   {Object}   promise to resolve modal.
     */
    function _confirmUpdateThreat(threats, action) {
      var modalConfig;
      var threatIds;
      var options;

      threats = [].concat(threats);
      threatIds = _filterInvalidSelections(threats, action);

      if (!threatIds.length) {
        return cMessage.error({
          textKey: 'antivirus.invalidOperation',
        });
      }

      modalConfig = {
        controller: UpdateThreatControllerFn,
        resolve: {
          threatIds: function resolveThreat() { return threatIds; },
          action: function resolveAction() { return action; },
        },
      };

      options = {
        contentKeyContext: {
          filename: threats[0]._filename,
          count: threatIds.length
        },
        closeButtonKey: 'cancel',
      };

      if (threats.length > 1) {
        switch(action) {
          case 'kQuarantine':
            options.titleKey = 'quarantineFiles';
            options.contentKey = 'antivirus.threats.quarantine.text';
            options.actionButtonKey = 'quarantineFiles';
            break;

          case 'kUnquarantine':
            options.titleKey = 'unquarantineFiles';
            options.contentKey = 'antivirus.threats.unquarantine.text';
            options.actionButtonKey = 'unquarantineFiles';
            break;

          case 'kReset':
            options.titleKey = 'resetFiles';
            options.contentKey = 'antivirus.threats.reset.text';
            options.actionButtonKey = 'resetFiles';
            break;

          case 'kDelete':
            options.titleKey = 'deleteFiles';
            options.contentKey = 'antivirus.threats.delete.text';
            options.actionButtonKey = 'deleteFiles';
            break;
        }
      } else {
        switch(action) {
          case 'kQuarantine':
            options.titleKey = 'quarantineFile';
            options.contentKey = 'antivirus.threat.quarantine.text';
            options.actionButtonKey = 'quarantineFile';
            break;

          case 'kUnquarantine':
            options.titleKey = 'unquarantineFile';
            options.contentKey = 'antivirus.threat.unquarantine.text';
            options.actionButtonKey = 'unquarantineFile';
            break;

          case 'kReset':
            options.titleKey = 'resetFile';
            options.contentKey = 'antivirus.threat.reset.text';
            options.actionButtonKey = 'resetFile';
            break;

          case 'kDelete':
            options.titleKey = 'deleteFile';
            options.contentKey = 'antivirus.threat.delete.text';
            options.actionButtonKey = 'deleteFile';
            break;
        }
      }


      return cModal.standardModal(modalConfig, options)
        .then(_fetchInfectedFiles, _.noop);
    }

    /**
     * Returns a list of selected Threats.
     *
     * @method    _getSelectedThreats
     * @returns   {Array}    List of selected threats.
     */
    function _getSelectedThreats() {
      return _.filter($ctrl.threats, '_selected');
    }

    /**
     * Toggles selection state of all the rows. This has special handling for
     * client vs server side filtering.
    */
    function toggleSelectAll(e, filteredList) {
      var isChecked = e.target.checked;
      var selectedCount = 0;
      var notSelectableCount = 0;

      // Use the client-side filtered list if passed in. Otherwise use the full
      // list provided from server response.
      var list = filteredList || $ctrl.threats;

      if (isChecked) {
        // If selecting all, then we need to apply to only the filtered list.
        list.forEach(function(threat) {
          // May only select files which are not in a DataLock View.
          if (!threat._isDataLockView) {
            threat._selected = true;
            selectedCount++;
          } else {
            notSelectableCount++;
          }
        });
      } else {
        // If deselecting all, then we need to clear the whole unfiltered list.
        $ctrl.threats.forEach(function(threat) {
          threat._selected = false;
        });
      }

      $ctrl.totalSelected = selectedCount;
      $ctrl.totalNotSelectable = notSelectableCount;
    }

    /**
     * Toggle selection state of given row.
     *
     * @method    toggleRowSelection
     * @param     {Object}    row    Row object
     */
    function toggleRowSelection(row) {
      // If DataLock View then don't do anything.
      if (row._isDataLockView) {
        return;
      }
      row._selected = !row._selected;
      $ctrl.totalSelected += row._selected ? 1 : -1;
    }

    /**
     * Determines whether every selectable row in current page is selected.
     *
     * @method   isWholePageSelected
     * @param    {Number}   listLength   Number of rows in the current page.
     * @returns  {Boolean}  True if the the whole page is selected.
     */
    function isWholePageSelected(listLength) {
      return listLength < $ctrl.threats.length &&
        $ctrl.totalSelected + $ctrl.totalNotSelectable === listLength;
    }

    /**
     * Determines whether every selectable row is selected across all pages.
     *
     * @method   isEverySelectableFileSelected
     * @returns  {Boolean}  True if the all selectable rows are selected.
     */
    function isEverySelectableFileSelected() {
      return $ctrl.totalSelected &&
        $ctrl.totalSelected + $ctrl.totalNotSelectable === $ctrl.threats.length;
    }
  }

  /* @ngInject */
  function UpdateThreatControllerFn($uibModalInstance, evalAJAX,
    AntivirusService, cMessage, threatIds, action) {

    var $ctrl = this;

    var params = {
      infectedFileIds: threatIds,
      remediationState:
        ['kReset', 'kDelete'].includes(action) ? undefined : action,
    };

    var apiFn = action === 'kDelete' ?
      AntivirusService.deleteThreats : AntivirusService.updateThreats;

    /**
     * Handles onClick the primary button in the standardModal.
     */
    $ctrl.ok = function okayThen() {
      $ctrl.submitting = true;
      apiFn(params).then(
        function updateThreatSuccess(response) {
          var successes = response.updateSucceededInfectedFiles ||
            response.deleteSucceededInfectedFiles;
          var failures = response.updateFailedInfectedFiles ||
            response.deleteFailedInfectedFiles;

          if (_.get(failures, 'length')) {
            // Display partial success message.
            cMessage.warn({
              titleKey: 'antivirus.partialSuccess',
              textKey: 'antivirus.partialSuccess.text',
              textKeyContext: {
                total: successes.length + failures.length,
                failed: failures.length
              },
            });
          }

          $uibModalInstance.close();
        },
        evalAJAX.errorMessage
      ).finally(function updateThreatFinally() {
        $ctrl.submitting = false;
      });
    };
  }
})(angular);
