<div class="tree-controls">
  <cog-filters cogDataId="tree-filters" [inheritDataId]="true">
    <cog-value-property-filter *cogFilterDef="let filter;
        let params = params;
        quickFilter true;
        key: 'backupStatus';
        label: 'backupStatus' | translate;
        filterType: 'valueProperty'"
      [filterDefParams]="params"
      [filter]="filter"
      [allowMultiple]="false"
      [autoApply]="true"
      [filterValues]="backupStatusFilter">
    </cog-value-property-filter>
    <cog-search-property-filter
      cogDataId="run-now-objects-search"
      [inheritDataId]="true"
      *cogFilterDef="let filter;
        key: 'nameSearch';
        label: 'name' | translate;
        quickFilter: true;
        filterType: 'searchProperty'"
      [filter]="filter"
      [placeholder]="'name' | translate">
    </cog-search-property-filter>
  </cog-filters>
  <cog-slicer *ngIf="(filters?.viewFilters$ | async)?.length && !isPure && !isIbmFlashSystem">
    <mat-button-toggle-group [value]="filters.selectedViewFilter$ | async"
      (change)="filters.setSelectedViewFilter($event.value)"
      cogDataId="tree-view-toggle"
      [inheritDataId]="true">
      <ng-container *ngFor="let viewFilter of filters.viewFilters$ | async; let index = index">
        <mat-button-toggle id="view-option-{{index}}"
          [cogDataId]="(viewFilter.id || viewFilter.tooltip || viewFilter.label) | translate"
          [inheritDataId]="true"
          [trackingEvent]="{key: 'source_tree_view_filter_clicked', properties: {
            value: ((viewFilter.tooltip || viewFilter.label) | translate)
          }}"
          [value]="viewFilter"
          [matTooltip]="viewFilter.tooltip | translate">
          <cog-icon class="{{viewFilter.icon}}" [shape]="viewFilter.icon"
            *ngIf="viewFilter.icon"></cog-icon>
        </mat-button-toggle>
      </ng-container>
    </mat-button-toggle-group>
  </cog-slicer>
</div>
<cog-data-tree-table *ngIf="treeService" class="compact-tree" [data]="dataTreeSource"
  [treeSelection]="dataTreeSelection" [treeControl]="treeService.treeControl">
  <mat-table>
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef="let ctx">
        <cog-data-tree-select-all-checkbox cogDataId="select-all-checkbox"
          [inheritDataId]="true"
          [dataTreeSource]="dataTreeSource"
          [treeSelection]="dataTreeSelection"
          *ngIf="!hideSelectAllObjectCheckbox">
        </cog-data-tree-select-all-checkbox>
        {{ 'name' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let ctx; index as i">
        <cog-data-tree-table-row-expand [context]="ctx"></cog-data-tree-table-row-expand>
        <cog-data-tree-node-checkbox [nodeContext]="ctx"
          [checkboxTooltipFn]="checkboxTooltip"
          autoSelectedIcon="helix:autoprotect-checkbox"
          ancestorExcludedIcon="cancel"
          [cogDataId]="ctx.node.name"
          [inheritDataId]="true">
        </cog-data-tree-node-checkbox>
        <span
          *ngIf="!ctx.node.icon"
          class="source-icon {{ ctx.node.data | sourceIcon }}"
          [class.protected]="ctx.node.protected"
          [class.protected-partial]="ctx.node.partialProtected"
        ></span>
        <cog-icon *ngIf="ctx.node.icon" [shape]="ctx.node.icon"></cog-icon>
        {{ ctx.node.name }}
        <span *ngIf="ctx.node.logicalSize" class="margin-left-lg">
          {{ ctx.node.logicalSize | byteSize }}
        </span>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="runStatus">
      <mat-header-cell *matHeaderCellDef="let ctx">
        {{ 'lastRunStatus' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let ctx; index as i">
        <coh-run-status [runObjects]="run?.objects"
          [sourceId]="ctx.node?.protectionSource?.id">
        </coh-run-status>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: columns"></mat-row>
  </mat-table>
</cog-data-tree-table>
