// ELEMENT: cSelect
//
// styled select list or dropdown
// extends the angular bootstrap UI styled select list
//
// label for field (optional)
// callback - name of callback defined in parent
// json list of values and names for list

angular.module('C.select', []).directive('cSelect',
    function cSelectFn() {
        return {
            scope: {
                label: '@',
                callback: '&',
                options: '=',
                updateLabel: '@',
                resetLabel: '=',
                userInput: '=?bind',
                ngDisabled: '=?',
                typeAhead: '@' // enable typeAhead filtering
            },
            restrict: 'E',
            replace: false,
            templateUrl: 'app/global/formElements/c-select.html',
            link: function(scope, element, attrs) {

                scope.defaultLabel = scope.label || 'Select...';
                scope.noFilteredOptsMessage = 'No options found';
                scope.userInput = !scope.userInput ? scope.defaultLabel : scope.userInput;
                scope.filterOptsEnabled = false;

                scope.clickItem = function(name, value, $event) {
                    scope.label = name;
                    scope.callback({
                        value: value,
                        label: name
                    });
                    scope.userInput = name;
                    scope.filterOptsEnabled = false;
                };

                scope.clickButton = function($event) {
                    $event.preventDefault();
                    $event.stopPropagation();
                    var parentWidth = $($event.currentTarget).parent('.c-select-list').width();
                    var ddmenu = $($event.currentTarget).next('.dropdown-menu');
                    $(ddmenu).width(parentWidth - 18);
                };

                scope.$watch('updateLabel', function updateLabel(nv, ov) {
                    if (nv !== ov) {
                        scope.label = nv;
                    }
                });

                scope.$watch('resetLabel', function setDefault() {
                    scope.label = scope.defaultLabel;
                });

                scope.$watch('userInput', function filterOptions(nv, ov) {
                    if (nv !== ov) {
                        scope.filteredOptsFound = false;
                        scope.options = scope.options.map(
                            function stringMatch(opt) {
                                var optName = opt.name.toLowerCase();
                                var userInput = nv.toLowerCase() || '';
                                if (scope.filterOptsEnabled) {
                                    if (optName.indexOf(userInput) > -1) {
                                        opt.hidden = false;
                                        scope.filteredOptsFound = true;
                                    } else {
                                        opt.hidden = true;
                                    }
                                } else {
                                    opt.hidden = false;
                                }
                                return opt;
                            }
                        );
                    }
                });

            }
        };
    }
);
