import { SearchObject } from '@cohesity/api/v2';
import { isObjectProtected } from '../utils';

/**
 * Inventory Object models which holds computed fields required across the colums. This helps in avoiding
 * repeated computations for the same fields across the different columns.
 */
export class InventoryObject {
  /**
   * A flag indicating if the object is protected
   */
  isObjectProtected: boolean;

  /**
   * raw search data reference
   */
  objectData: SearchObject;

  constructor(rawSearchObject: SearchObject) {
    this.objectData = rawSearchObject;

    /**
     * populates the repeatedly required fields and store those on the model
     */
    this.isObjectProtected = isObjectProtected(rawSearchObject);
  }
}
