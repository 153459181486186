import { Component, Input } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Credentials } from '@cohesity/api/v2';
import { CreateForm, createFormProviders } from '../create-form';

/**
 * @description
 * Component to help with Credentials input.
 */
@Component({
  selector: 'coh-credentials',
  templateUrl: './credentials.component.html',
  styleUrls: ['./credentials.component.scss'],
  providers: createFormProviders(CredentialsComponent),
})
export class CredentialsComponent {
  /**
   * Pass id to provide the context to the input cogDataIds.
   */
  @Input() id = '';

  /**
   * Placeholder for username label.
   */
  @Input() usernameLabel = 'username';

  /**
   * If we don't want to show the password field.
   */
  @Input() showPassword = true;

  /**
   * Mark the fields required if this input is passed.
   */
  @Input() set required(required: boolean) {
    if (required) {
      this.form.formGroup.controls.username.setValidators(Validators.required);

      if (this.showPassword) {
        this.form.formGroup.controls.password.setValidators(Validators.required);
      }
    }
  };

  /**
   * The internal form group.
   */
  form = new CreateForm<Partial<Credentials>>(this, {
    formControls: {
      username: new FormControl(null),
      ...(this.showPassword ? { password: new FormControl(null) } : {}),
    },
  });
}
