<a *ngIf="label" (click)="showOptions()">{{label}}</a>
<button (click)="showOptions()"
  id="node-edit-anchor-{{nodeContext.node.id}}"
  mat-icon-button
  type="button">
  <cog-icon shape="edit"></cog-icon>
</button>

<ng-template #dialog>
  <ng-container *ngIf="dialogHeaderTemplate">
    <ng-container *ngTemplateOutlet="dialogHeaderTemplate; context:{dialogRef: dialogRef}"></ng-container>
  </ng-container>
  <h1 *ngIf="!dialogHeaderTemplate" mat-dialog-title>
    {{'serverOptions.optionsFor' | translate: {name: nodeContext.node.name} }}
  </h1>
  <div mat-dialog-content class="dialog-content">
    <ng-content></ng-content>
  </div>
  <div mat-dialog-actions align="end">
    <button id="server-options-cancel-button"
      type="button"
      mat-button
      (click)="cancel()">
      {{'cancel' | translate}}
    </button>
    <button id="server-options-save-button"
      [disabled]="!form?.valid"
      mat-flat-button
      cdkFocusInitial
      type="submit"
      color="primary"
      (click)="closeDialog(true)">
      {{'save' | translate}}
    </button>
  </div>
</ng-template>
