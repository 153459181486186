// COMPONENT: cByteMeter

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.byteMeter', ['ui.bootstrap'])
    .component('cByteMeter', {
      bindings: {
        used: '<',
        total: '<',
        warningThreshold: '<?',
        criticalThreshold: '<?',
        overrideUsageLabel: '<?',
      },
      transclude: {
        byteMeterLabel: '?byteMeterLabel',
      },
      controller: cByteMeterControllerFn,
      templateUrl: 'app/global/c-byte-meter/c-byte-meter.html',
    });

  /**
   * @ngdoc component
   * @name C.byteMeter:cByteMeter
   * @function
   *
   * @description
   * Reusable component provides a styled progress bar showing bytes used
   * of total bytes available
   *
   * @example
      <c-byte-meter
        override-usage-label="viewBox.dirWalkPending ?
          'stillCalculatingEllipsis' : undefined"
        used="view.logicalUsageBytes"
        total="viewBox.defaultViewQuotaPolicy.hardLimitBytes"
        warning-threshold="viewBox.defaultViewQuotaPolicy.alertLimitBytes"
        critical-threshold="viewBox.defaultViewQuotaPolicy.hardLimitBytes">
      </c-byte-meter>
   */
  function cByteMeterControllerFn() {
    var ctrl = this;

    /**
     * component onchange function
     */
    ctrl.$onChanges = function onChanges(changes) {

      // set uibProgressbar[type] value
      switch(true) {

        case !!ctrl.criticalThreshold && ctrl.used >= ctrl.criticalThreshold:
          ctrl.colorStyle = 'danger';
          break;

        case !!ctrl.warningThreshold && ctrl.used >= ctrl.warningThreshold:
          ctrl.colorStyle = 'warning';
          break;

        case !!ctrl.criticalThreshold || !!ctrl.warningThreshold:
          // if either threshold was provided but wasn't triggered, success!
          ctrl.colorStyle = 'success';
          break;

        default:
          // no thresholds were provided. display is strictly informational.
          ctrl.colorStyle = 'info';

      }

    };
  }

})(angular);
