// MODULE: Storage Controller

angular.module('C.statistics').controller('StorageController', ['$rootScope', '$scope', '$q', 'cUtils', 'evalAJAX', 'StatsService', 'DateTimeService', 'AlertService', 'CHART_COLORS',
    function($rootScope, $scope, $q, cUtils, evalAJAX, StatsService, DateTimeService, AlertService, CHART_COLORS) {

        angular.extend($scope.text, $rootScope.text.monitoringStorageStorage);


        // Storage Chart
        $scope.storageChart = {
            chartType: 'storageDonut',
            loading: true,
            titleText: 'totalSize',
            series: [{
                type: 'pie',
                name: 'Storage',
                data: []
            }]
        };


        // Data Redux Chart
        $scope.dataReduxChart = {
            chartType: 'dataRedux',
            loading: true,
            compact: true,
            chart: {
                height: 250
            },
            series: [{
                name: 'Total Logical Data Written',
                type: 'column',
                data: [],
                zIndex: 3
            }, {
                name: 'Total Physical Data Written',
                type: 'column',
                data: [],
                zIndex: 3
            }, {
                name: 'Data Reduction',
                type: 'line',
                yAxis: 1,
                data: [],
                zIndex: 4
            }],
            yAxis: [{}, {}, {}],
            xAxis: {
                // placholder for minTickInterval updates
            }
        };

        // boolean to prevent computeClusterStats function from
        // being run more than once, since the cluster data doesn't change
        var clusterStatsComputed = false;

        /**
         * sets up our parameters based on current values and
         * calls the various functions that handle chart setup
         * @return {void}
         */
        $scope.updateCharts = function updateCharts() {

            var timeParams = $scope.getTimeParams(true);

            if ($scope.currentEntity.dataRedux) {
                buildDataReduxChart(timeParams);
            }

            if (!clusterStatsComputed) {
                computeClusterStats();
            }

            if ($scope.currentEntity.stats) {
                buildStorageChart();
            }

        };

        // when the parent controller broadcasts updateCharts message
        // obey the broadcast and run the function
        $scope.$on('updateCharts', $scope.updateCharts);

        /**
         * adjust config option so highcharts won't place tick marks
         * on x-axis in between our intervals
         * for instance: marking 12:00's on a 7 day chart
         * @param {[type]} params [description]
         */
        function setMinTickIntervals(params) {
            // TODO: fix this... rollup is no longer available via this "params"
            var interval = params.rollup * 1000;
            $scope.dataReduxChart.xAxis.minTickInterval = interval;
        }



        /**
         * Fetch data for Reduction Rate Timeseries and put the chart together
         *
         * @param      {Object}  timeParams  The time parameters
         */
        function buildDataReduxChart(timeParams) {

            var reduxRollups = {
                day: 3600,// 1 hour
                week: 86400, // 1 day
                month: 86400
            };
            var promiseArray = [];
            var physicalData = [];
            var logicalData = [];
            var logicalParams;
            var physicalParams;

            // Prepare 'Logical Data Written' request params
            logicalParams = {
                entityId: $scope.currentEntity.id,
                schemaName: $scope.currentEntity.dataRedux.logical.schemaName,
                metricName: $scope.currentEntity.dataRedux.logical.metric,
                rollupFunction: $scope.currentEntity.dataRedux.logical.rollupFn,
                range: $scope.dateInfo.currentRange,
                startTimeMsecs: timeParams.startTimeMsecs,
                // use custom rollup for redux chart, as we are plotting on a bar chart
                // rather than a line chart
                rollupIntervalSecs: reduxRollups[$scope.dateInfo.currentRange]
            };

            // Prepare 'Physical Data Written' request params
            physicalParams = {
                entityId: $scope.currentEntity.id,
                schemaName: $scope.currentEntity.dataRedux.physical.schemaName,
                metricName: $scope.currentEntity.dataRedux.physical.metric,
                rollupFunction: $scope.currentEntity.dataRedux.logical.rollupFn,
                range: $scope.dateInfo.currentRange,
                startTimeMsecs: timeParams.startTimeMsecs,
                // use custom rollup for redux chart, as we are plotting on a bar chart
                // rather than a line chart
                rollupIntervalSecs: reduxRollups[$scope.dateInfo.currentRange]
            };

            // clear chart data, and set charts as loading
            $scope.dataReduxChart.loading = true;

            promiseArray.push(
                StatsService.getTimeSeries(logicalParams),
                StatsService.getTimeSeries(physicalParams)
            );

            $q.all(promiseArray).then(
                function qAllSuccess(responses) {
                    if (responses[0].data && responses[0].data.dataPointVec) {
                        logicalData = StatsService.buildIntervalDataSet(responses[0].data.dataPointVec, logicalParams);
                    }
                    if (responses[1].data && responses[1].data.dataPointVec) {
                        physicalData = StatsService.buildIntervalDataSet(responses[1].data.dataPointVec, physicalParams);
                    }
                    processData();
                },
                function qAllError(response) {
                    evalAJAX.errorMessage(response);
                    // TODO: is this appropriate failure handling?
                    $scope.dataReduxChart.series[0].data = [];
                    $scope.dataReduxChart.series[1].data = [];
                    $scope.dataReduxChart.series[2].data = [];
                }
            ).finally(
                function qAllFinally() {
                    $scope.dataReduxChart.loading = false;
                }
            );

            var processData = function() {
                var reduxSeries = [];
                var dataPointCount = logicalData.length;
                var currentReduxValue;

                for (var i = 0; i < dataPointCount; i++) {
                    currentReduxValue = logicalData[i] && physicalData[i] ? cUtils.round(logicalData[i] / physicalData[i], 2) : null;
                    reduxSeries.push(currentReduxValue);
                }

                $scope.dataReduxChart.series[0].data = logicalData;
                $scope.dataReduxChart.series[0].pointInterval = logicalParams.rollupIntervalSecs * 1000;
                $scope.dataReduxChart.series[0].pointStart = logicalParams.startTimeMsecs;
                $scope.dataReduxChart.series[1].data = physicalData;
                $scope.dataReduxChart.series[1].pointInterval = physicalParams.rollupIntervalSecs * 1000;
                $scope.dataReduxChart.series[1].pointStart = physicalParams.startTimeMsecs;
                $scope.dataReduxChart.series[2].data = reduxSeries;
                $scope.dataReduxChart.series[2].pointInterval = physicalParams.rollupIntervalSecs * 1000;
                $scope.dataReduxChart.series[2].pointStart = physicalParams.startTimeMsecs;
                $scope.dataReduxChart.loading = false;
            };

        }

        // stats objects to hold storage related data
        $scope.clusterStorageStats = {};
        $scope.viewboxStorageStats = {};

        /**
         * builds the storage chart and determines data in
         * the case of type === viewbox
         * @return {Void}
         */
        function buildStorageChart() {
            var localUsagePerfStats;
            var cloudUsagePerfStats;
            var logicalStats;
            var capacityUsed;
            var capacityUsedBytesToSize;
            var clusterCapacityUsed;
            var viewBoxTotalCapacityUsed;

            // always reset viewboxStorageStats as entity has changed,
            // leave clusterStorageStats unchanged, as these values don't change
            $scope.viewboxStorageStats = {};

            localUsagePerfStats = $scope.currentEntity.stats.localUsagePerfStats || {};
            cloudUsagePerfStats = $scope.currentEntity.stats.cloudUsagePerfStats || {};
            logicalStats = $scope.currentEntity.stats.logicalStats || {};

            capacityUsed = localUsagePerfStats.totalPhysicalUsageBytes ? localUsagePerfStats.totalPhysicalUsageBytes : 0;

            // clear our storage chart and viewboxStorageStats
            $scope.storageChart.series[0].data = [];
            $scope.viewboxStorageStats = {};

            if ($scope.currentEntity.type === 'cluster') {
                $scope.storageChart = angular.merge($scope.storageChart, {
                    series: [{
                        data: [
                            [$scope.text.storageChart.free, localUsagePerfStats.physicalCapacityBytes - capacityUsed],
                            [$scope.text.storageChart.used, capacityUsed]
                        ]
                    }]
                });
            } else if ($scope.currentEntity.type === 'viewbox') {
                // calculate viewbox specific info
                $scope.viewboxStorageStats.capacityTotal = localUsagePerfStats.physicalCapacityBytes;
                $scope.viewboxStorageStats.physicalUsed = capacityUsed ? capacityUsed : 0;
                $scope.viewboxStorageStats.capacityFree = localUsagePerfStats.physicalCapacityBytes - capacityUsed;
                $scope.viewboxStorageStats.logicalUsed = logicalStats.totalLogicalUsageBytes ? logicalStats.totalLogicalUsageBytes : 0;
                $scope.viewboxStorageStats.cloudUsed = (cloudUsagePerfStats && cloudUsagePerfStats.totalPhysicalUsageBytes) ? cloudUsagePerfStats.totalPhysicalUsageBytes : 0;

                viewBoxTotalCapacityUsed = $scope.currentEntity.stats.usagePerfStats.totalPhysicalUsageBytes ? $scope.currentEntity.stats.usagePerfStats.totalPhysicalUsageBytes : 0;

                $scope.viewboxStorageStats.redux = viewBoxTotalCapacityUsed ? cUtils.round(logicalStats.totalLogicalUsageBytes / viewBoxTotalCapacityUsed, 2) : null;

                clusterCapacityUsed = $scope.clusterStats.localUsagePerfStats.totalPhysicalUsageBytes;

                $scope.storageChart = angular.merge($scope.storageChart, {
                    series: [{
                        data: [
                            [$scope.text.storageChart.free, $scope.clusterStats.localUsagePerfStats.physicalCapacityBytes - clusterCapacityUsed],
                            [$scope.text.storageChart.otherUsed, clusterCapacityUsed - capacityUsed],
                            [$scope.currentEntity.displayName, capacityUsed]
                        ]
                    }]
                });
            }

            $scope.storageChart.loading = false;

        }

        /**
         * Single run function that will calculate cluster stats for display,
         * as some of these stats will persist when user switches to viewbox mode
         * @return {Void}
         */
        function computeClusterStats() {

            var localUsagePerfStats;
            var cloudUsagePerfStats;
            var logicalStats;
            var capacityUsed;
            var capacityUsedBytesToSize;

            if ($scope.clusterStats) {
                localUsagePerfStats = $scope.clusterStats.localUsagePerfStats;
                cloudUsagePerfStats = $scope.clusterStats.cloudUsagePerfStats;
                logicalStats = $scope.clusterStats.logicalStats;

                if (localUsagePerfStats !== undefined) {
                    capacityUsed = localUsagePerfStats.totalPhysicalUsageBytes ? localUsagePerfStats.totalPhysicalUsageBytes : 0;
                    $scope.clusterStorageStats.minUsablePhysicalCapacityBytes = localUsagePerfStats.minUsablePhysicalCapacityBytes;
                    $scope.clusterStorageStats.redux = ($scope.clusterStats.dataReductionRatio ? cUtils.round($scope.clusterStats.dataReductionRatio, 2) : null);
                    $scope.clusterStorageStats.capacityTotal = localUsagePerfStats.physicalCapacityBytes ? localUsagePerfStats.physicalCapacityBytes : 0;
                    $scope.clusterStorageStats.physicalUsed = capacityUsed ? capacityUsed : 0;
                    $scope.clusterStorageStats.capacityFree = localUsagePerfStats.physicalCapacityBytes - capacityUsed;
                    $scope.clusterStorageStats.logicalUsed = logicalStats.totalLogicalUsageBytes ? logicalStats.totalLogicalUsageBytes : 0;
                    $scope.clusterStorageStats.cloudUsed = (cloudUsagePerfStats && cloudUsagePerfStats.totalPhysicalUsageBytes) ? cloudUsagePerfStats.totalPhysicalUsageBytes : undefined;
                }
            }

            clusterStatsComputed = true;

        }

    }
]);
