import { InjectionToken, Type } from '@angular/core';
import { DataFilterValue } from '@cohesity/helix';
import { Observable } from 'rxjs';

import { RestoreSearchResult } from '../model/restore-search-result';

/**
 * Search provider interface.
 */
export interface ObjectSearchProvider {
  /**
   * Runs an object search for VMs.
   *
   * @param   query   The search query
   * @param   filters The currently applied filters.
   * @returns Observable of an array of matching objects.
   */
  doObjectSearch(query: string, filters: DataFilterValue<any>[]): Observable<RestoreSearchResult[]>;
}

/**
 * Token for a search provider.
 */
export const searchProviderToken = new InjectionToken<ObjectSearchProvider>('search-provider-token');

/**
 * Provider factory, exported as a function to make angular AOT compiler happy
 *
 * @param   provider   The provider instance to use for object search.
 * @retunrs The provider.
 */
export function searchProviderFactory(provider: Type<ObjectSearchProvider>) {
  return provider;
}

/**
 * Configure an object search provider. Components can provide a custom search
 * implementation. Add this to a component or modules providers list to set the
 * search provider.
 */
export function objectSearchProvider(searchProvider: Type<ObjectSearchProvider>) {
  return [{ provide: searchProviderToken, useFactory: searchProviderFactory, deps: [searchProvider] }];
}
