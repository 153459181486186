import { Injectable } from '@angular/core';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';
import { StateObject, Transition } from '@uirouter/core';

import { StateManagementService } from '../../services';
import { GuardPriority, GuardResult, StateGuard } from '../state-guard';

/**
 * Redirect states for source details, based on feature flags and state names.
 */
const redirectMap = {
  'source-details': 'source-details-ng.objects',
  'source-details.protected': 'source-details-ng.objects',
  'source-details.all': 'source-details-ng.objects',
};

/**
 * Array of legacy states to match on for redirect.
 */
const matchStates = Object.keys(redirectMap);
/**
 * Source details Guard redirects all state traffic to the new details page based on the
 * Feature flag ngSourceDetailsEnabledGlobally.
 */
@Injectable({
  providedIn: 'root'
})
export class SourceDetailsGuard implements StateGuard {
  /**
   * This guard priority is set to App, which indicates it should run as part of the main application
   * after all bootstrapping and initializing is completed.
   */
  guardPriority = GuardPriority.App;

  /**
   * Match all navigation to source details
   */
  matchCriteria = {
    to: (state: StateObject, transition: Transition) => matchStates.includes(transition.targetState().name()),
  };

  /**
   * Constructor.
   */
  constructor(private irisCtx: IrisContextService, private stateManagementService: StateManagementService) {}

  /**
   * Handles the redirection to the new source deatils page based on feature flag.
   * This will help with deeplinked states with the old and new source detail pages.
   *
   * @param    transition   The UI Router Transition.
   * @returns  A redirect transition if new sources details page is enabled.
   */
  onStart(transition: Transition): GuardResult {
    if (!flagEnabled(this.irisCtx.irisContext, 'ngSourceDetailsEnabledGlobally')) {
      return;
    }

    const targetName = transition.targetState().name();
    const params = transition.params();
    const redirectState = redirectMap[targetName];

    if (this.stateManagementService.canUserAccessState(redirectState, params)) {
      return transition.router.stateService.target(redirectState, params);
    }
  }
}
