import { AnomalyChartSeries, MetricType, MetricTypeInSortedOrder } from './../security-shared.models';

/**
 * Filter out unsupported metrics.
 *
 * @param response The AnomalyChartSeries repsonse.
 * @returns The pruned AnomalyChartSeries repsonse.
 */
export const pruneUnsupportedMetrics = (response: AnomalyChartSeries) => {
  const metrics = [...new Set([response.chartMetric, ...response.metrics])];

  // Filter unsupported metrics & put it in proper order
  // chartMetric gets the priority and should be shown first
  response.metrics = metrics.filter(metric => MetricType[metric]);

  if (!response.metrics.includes(response.chartMetric)) {
    response.chartMetric = response?.metrics?.[0];
  }

  return response;
};

/**
 * Add all the metrics in sorted order.
 *
 * @param response The AnomalyChartSeries repsonse.
 * @returns The updated AnomalyChartSeries repsonse.
 */
export const addAllMetrics = (response: AnomalyChartSeries) => {
  const supportedMetrics = Object.keys(MetricTypeInSortedOrder).filter(v => isNaN(Number(v)));

  return {
    ...response,
    metrics: [...new Set([response.chartMetric, ...supportedMetrics])],
  };
};
