/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ClusterConfigParams } from '../models/cluster-config-params';
import { ClusterConfigResponse } from '../models/cluster-config-response';

@Injectable({
  providedIn: 'root',
})
export class ClusterConfigApiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getClusterConfig
   */
  static readonly GetClusterConfigPath = '/cluster-config';

  /**
   * Get all DataHawk Clusters configuration.
   *
   * Privileges: DGAAS_VIEW
   *
   * Get all DataHawk Clusters Configuration.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getClusterConfig()` instead.
   *
   * This method doesn't expect any request body.
   */
  getClusterConfig$Response(params?: {

  }): Observable<StrictHttpResponse<ClusterConfigResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ClusterConfigApiService.GetClusterConfigPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClusterConfigResponse>;
      })
    );
  }

  /**
   * Get all DataHawk Clusters configuration.
   *
   * Privileges: DGAAS_VIEW
   *
   * Get all DataHawk Clusters Configuration.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getClusterConfig$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getClusterConfig(params?: {

  }): Observable<ClusterConfigResponse> {

    return this.getClusterConfig$Response(params).pipe(
      map((r: StrictHttpResponse<ClusterConfigResponse>) => r.body as ClusterConfigResponse)
    );
  }

  /**
   * Path part for operation updateClusterConfig
   */
  static readonly UpdateClusterConfigPath = '/cluster-config';

  /**
   * Update DataHawk Clusters configuration.
   *
   * Privileges: DGAAS_MODIFY
   *
   * Updates DataHawk Clusters Configuration.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateClusterConfig()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateClusterConfig$Response(params: {
      body: ClusterConfigParams
  }): Observable<StrictHttpResponse<ClusterConfigResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ClusterConfigApiService.UpdateClusterConfigPath, 'put');
    if (params) {


      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClusterConfigResponse>;
      })
    );
  }

  /**
   * Update DataHawk Clusters configuration.
   *
   * Privileges: DGAAS_MODIFY
   *
   * Updates DataHawk Clusters Configuration.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateClusterConfig$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateClusterConfig(params: {
      body: ClusterConfigParams
  }): Observable<ClusterConfigResponse> {

    return this.updateClusterConfig$Response(params).pipe(
      map((r: StrictHttpResponse<ClusterConfigResponse>) => r.body as ClusterConfigResponse)
    );
  }

}
