import { AppModuleConfig, StateAccessContext, StateAccessMap } from 'src/app/app-module.config';

export const GlobalSearchConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'ng-search.**',
      url: '^/search2',
      loadChildren: () => import('./global-search.module').then(m => m.GlobalSearchModule),
    },
  ],
  allClusterMap: {
    'ng-search': {
      singleClusterState: 'ng-search',
      allClustersState: 'ng-search',
      globalContext: true,
    },
  },
  getStateAccessMap(): StateAccessMap {
    return {
      'ng-search': (ctx: StateAccessContext) =>
        ctx.OBJECT_SEARCH && (ctx.FEATURE_FLAGS.ngGlobalSearchDms || ctx.FEATURE_FLAGS.ngGlobalSearchSingleCluster),
    };
  },
};
