import { Component, TemplateRef, Type } from '@angular/core';

import { PopoverContent, PopoverRef } from './popover-ref';

/**
 * @description
 * This is an internal component which is used to render content inside overlay.
 * It renders either of templateRef or Component. Default render method is
 * component. It is only supposed to be used inside PopoverService
 */
@Component({
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
})
export class PopoverComponent {

  /**
   * renderMethod could be either template or component
   * based on content passed to popover.
   */
  readonly renderMethod: 'template' | 'component' = 'component';

  /**
   * Popover content of type TemplateRef or Type
   */
  readonly content: PopoverContent;

  /** The ngTemplateOutlet content */
  contentTemplateRef: TemplateRef<any>;

  /** The ngComponentOutlet content */
  contentComponentRef: Type<any>;

  constructor(public popoverRef: PopoverRef) {
    this.content = this.popoverRef.content;

    this.contentTemplateRef = this.contentComponentRef = null;

    if (this.content instanceof TemplateRef) {
      this.renderMethod = 'template';
      this.contentTemplateRef = this.content;
    } else {
      this.renderMethod = 'component';
      this.contentComponentRef = this.content;
    }
  }
}
