/**
 * Specifies the indexing status of any snapshot version.
 */
export enum IndexingStatusType {
  // Yoda has started cracked file indexing.
  kStarted = 1,

  // Cracked file indexing has been completed according to the type of object.
  kDone = 2,

  // During archival restore, magneto sends add snapshot without localreplicas.
  // This is the state set for this request since yoda can't index it.
  kNoIndex = 3,

  // Icebox has started restore of cracked file index from an archive.
  kIceboxRestoreStarted = 4,

  // An error occurred during Icebox restore. There is no cracked file index
  // present.

  kIceboxRestoreError = 5,

  // Cracked file indexing has been skipped due to indexing backlog.
  kSkipped = 6,
}
