import { UntypedFormControl, ValidatorFn } from '@angular/forms';

import { REGEX_FORMATS } from '@cohesity/utils';

/**
 * Validator for fully qualified domain name (fqdn) or IP address.
 *
 * @param control The form control whose value will be validated.
 * @return null if a valid string, otherwise error object.
 */
export const IpOrDomainNameValidator: ValidatorFn = (control: UntypedFormControl) => {
  if (!control.value) {
    return null;
  }

  const isValid = REGEX_FORMATS.domainName.test(control.value || '');
  return isValid ? null : { invalidIpOrDomainName: true };
};
