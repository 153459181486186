import { AppModuleConfig, StateAccessContext, StateAccessMap } from 'src/app/app-module.config';

const vipsVlansAccess = (ctx: StateAccessContext) => ctx.FEATURE_FLAGS.ngVipsVlanEnabled && ctx.CLUSTER_MODIFY;

export const VipsVlansConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'networking.vipsVlans.**',
      url: '^/platform/networking/vipsVlans',
      loadChildren: () => import('./vips-vlans.module').then(m => m.VipsVlansModule)
    },
  ],
  getStateAccessMap(): StateAccessMap {
    return {
      'networking.vipsVlans': vipsVlansAccess,
    };
  }
};
