// Module: Server Options

;(function(angular) {
  'use strict';

  angular.module('C.jobModify')
    .controller('serverOptionsPubController', serverOptionsPubControllerFn);

  function serverOptionsPubControllerFn(thisNode, thisJob, $uibModalInstance,
    PubJobServiceFormatter, cUtils, SourceService, evalAJAX, PubSourceService,
    ENV_TYPE_CONVERSION, NgOracleUtilityService) {

    var $ctrl = this;

    // config for Server Options modal for different environment types
    var serverOptionsConfig = {
      kVMware: {
        state: {
          isExcludeDisksEnabled: false,
          excludedDisks: [],
          truncateExchangeLog: false,
        },
        onSave: saveForVMWare,
        onActivate: activateForVMWare,
      },
      kPhysical: {
        state: {
          areAllVolumesProtected: true,
          enableSystemBackup: false,
          truncateExchangeLog: false,

          // tracks volumes to be included in backup.
          volumes: [],

          // for reporting missing Volumes to user
          missingVolumesGuid: [],
        },
        onSave: saveForPhysical,
        onActivate: activateForPhysical,
        volumesBMRSelectionCount: volumesBMRSelectionCount,
        toggleAllVolumeSelection: toggleAllVolumeSelection,
      },
      kOracle: {
        state: {
          canSaveSettings: false,

          // lookup for the databse nodes
          nodesLookup: [],

          // captures all form data for nodes
          formData: [],

          // Select all nodes for MNMC by default.
          selectAllByDefault: false,

          // Number of days after which Archive log will be deleted.
          deleteArchiveAfter: undefined,

          // Specifies whether the database having the Primary role within
          // Data Guard configuration is to be backed up.
          enableDgPrimaryBackup: true,

          // flag to check if all nodes are selected
          nodeSelectType: 'auto',
          nodeListInJob: [],
          host: {
            // env vec initialised to kOracle
            appEnvVec: [ENV_TYPE_CONVERSION.kOracle],
            usesPersistentAgent: true,
            ownerEntity: undefined,

            // for registering oracle db with credentials populate this field
            appCredentialsVec: [
              {
                envType: ENV_TYPE_CONVERSION.kOracle,
                credentials: {
                  username: undefined,
                  password: undefined,
                },
              },
            ],
          },
        },
        onSave: saveForOracle,
        onActivate: activateForOracle,
      },
    };

    angular.extend($ctrl, {

      // thisNode and thisJob to be provided via resolver
      thisNode: thisNode,
      thisJob: thisJob,

      // Server Options UI states
      state: {},

      // Server Options view methods
      $onInit: $onInit,
      cancelModal: cancelModal,
      canSaveSettings: canSaveSettings,
      saveSettings: saveSettings,
    });

    /**
     * set Application Parameters to UI state
     *
     * @method   setApplicationParameters
     * @param    {Object}   selectedParameters   selected source parameters
     */
    function setApplicationParameters(selectedParameters) {
      if (thisNode._isExchangeHost) {
        $ctrl.state.truncateExchangeLog =
          selectedParameters.applicationParameters.truncateExchangeLog;
      }
    }

    /**
     * Gets the application parameters setting for selected source
     *
     * @method   getApplicationParameters
     * @return   {Object}   The application parameters setting.
     */
    function getApplicationParameters() {
      if (thisNode._isExchangeHost) {
        return {
          applicationParameters: {
            truncateExchangeLog: $ctrl.state.truncateExchangeLog,
          }
        };
      }

      return {};
    }

    /**
     * initalize UI state for kVMware source.
     *
     * @method   activateForVMWare
     */
    function activateForVMWare() {
      var excludedDisks = [];
      var vmWareProtectionSource = thisNode._envProtectionSource;
      var selectedParameters =
        thisJob._sourceSpecialParametersMap[thisNode.protectionSource.id];

      // early exit if no virtual disk to choose from.
      if (!selectedParameters ||
        !vmWareProtectionSource.virtualDisks ||
        !vmWareProtectionSource.virtualDisks.length) {
        return;
      }

      // set application Parameters for VMware
      setApplicationParameters(selectedParameters.vmwareSpecialParameters);

      // get excluded disks list
      excludedDisks = _.intersectionBy(
        vmWareProtectionSource.virtualDisks,
        selectedParameters.vmwareSpecialParameters.excludedDisks,
        function getUniqueKey(disk) {
          return [disk.controllerType, disk.busNumber, disk.unitNumber].join('-');
        }
      );

      // set UI state properties
      angular.extend($ctrl.state, {
        isExcludeDisksEnabled: !!excludedDisks.length,
        excludedDisks: excludedDisks,
      });
    }

    /**
     * handle save action for kVMware source.
     *
     * @method   saveForVMWare
     */
    function saveForVMWare() {
      var updates = angular.extend(
        {
          excludedDisks: $ctrl.state.excludedDisks,
        },
        getApplicationParameters()
      );

      // if isExcludeDisksEnabled then update the selected excludedDisks else
      // remove special parameters for the source.
      if ($ctrl.state.isExcludeDisksEnabled ||
        $ctrl.state.truncateExchangeLog) {
        PubJobServiceFormatter.updateSourceSpecialParameters(
          thisJob,
          thisNode,
          updates
        );
      } else {
        PubJobServiceFormatter.removeSourceSpecialParameters(thisJob, thisNode);
      }
    }

    /**
     * getSelectedVolumes by finding intersection between selectedVolumesGuid &
     * volumes list.
     *
     * @method   getSelectedVolumes
     * @param    {Object[]}   volumes               available volumes list
     * @param    {Object[]}   selectedVolumesGuid   selected volumes Guid list
     * @return   {Object[]}   list of all volumes with selected volume having
     *                        _selected field true
     */
    function getSelectedVolumes(volumes, selectedVolumesGuid) {

      // find common volumes
      var commonVolumes = cUtils.findIntersection(
        volumes,
        selectedVolumesGuid,
        function getUniqueKey(volume) {

          // selectedVolumesGuid is a array of volume Guid string values.
          return typeof volume === 'string' ? volume : volume.guid;
        }
      );

      // mark common volumes selected
      commonVolumes.forEach(function forEachVolume(volume) {
        volume._selected = true;
      });

      return commonVolumes;
    }

    /**
     * Gets the volumes that are previously selected but now not present for in
     * the source either they are removed or renamed.
     *
     * @method   getMissingVolumes
     * @param    {Object[]}   volumes               available volumes list
     * @param    {Object[]}   selectedVolumesGuid   selected volumes Guid list
     * @return   {Object[]}   list of missing volumes
     */
    function getMissingVolumes(volumes, selectedVolumesGuid) {
      var selectedVolumesGuidMap = volumes.reduce(
        function forEachVolume(acc, volume) {
          acc[volume.guid] = volume;

          return acc;
        },
        {}
      );

      return selectedVolumesGuid.filter(
        function forEachVolumeGuid(guid) {
          return !selectedVolumesGuidMap.hasOwnProperty(guid);
        }
      );
    }

    /**
     * initalize UI state for kPhysical source.
     *
     * @method   activateForPhysical
     */
    function activateForPhysical() {
      var selectedVolumes = [];
      var selectedVolumesGuid = [];
      var missingVolumesGuid = [];
      var physicalProtectionSource = thisNode._envProtectionSource;
      var selectedParameters =
        thisJob._sourceSpecialParametersMap[thisNode.protectionSource.id];

      // early exit if no volumes to choose from
      if (!selectedParameters || !selectedParameters.physicalSpecialParameters ||
        !physicalProtectionSource.volumes ||
        !physicalProtectionSource.volumes.length) {
        return;
      }

      // set application Parameters for Physical
      setApplicationParameters(selectedParameters.physicalSpecialParameters);

      selectedVolumesGuid =
        selectedParameters.physicalSpecialParameters.volumeGuid || [];

      if (selectedVolumesGuid.length) {

        // get selected volumes list from available LVM volumes only.
        selectedVolumes = getSelectedVolumes(
          thisNode._volumesLvm,
          selectedVolumesGuid
        );

        // find the volumes which are previously selected but not found in
        // currently selectedVolumes list
        missingVolumesGuid =
          getMissingVolumes(selectedVolumes, selectedVolumesGuid);
      }

      // update the UI state for physical source
      angular.extend($ctrl.state, {

        // no selectedVolumesGuid then by default all volumes are protected
        areAllVolumesProtected: !selectedVolumesGuid.length,

        // is BMR enabled
        enableSystemBackup:
          selectedParameters.physicalSpecialParameters.enableSystemBackup,
        volumes: selectedVolumes,
        missingVolumesGuid: missingVolumesGuid,
      });
    }

    /**
     * handle save action for kPhysical source
     *
     * @method   saveForPhysical
     */
    function saveForPhysical() {
      var selectedVolumes;
      var updates;

      if (!$ctrl.state.areAllVolumesProtected) {
        selectedVolumes =
          $ctrl.thisNode.protectionSource.physicalProtectionSource.volumes
            .filter(function forEachVolume(volume) {
              return volume._selected;
            }
          ).map(function forEachVolume(volume) {
            return volume.guid;
          });

        // there should be at least one selectedVolumes or all volumes to
        // protect so if no volumes selected then select all volumes by default.
        if (!selectedVolumes.length) {
          selectedVolumes = undefined;
        }
      }

      // Build source special parameters
      updates = angular.extend(
        {
          enableSystemBackup: $ctrl.state.enableSystemBackup || undefined,
          volumeGuid: selectedVolumes,
        },
        getApplicationParameters()
      );

      if (!updates.enableSystemBackup && !updates.volumeGuid) {
        PubJobServiceFormatter.removeSourceSpecialParameters(thisJob, thisNode);
      } else {
        PubJobServiceFormatter.updateSourceSpecialParameters(
          thisJob,
          thisNode,
          updates
        );
      }
    }

    /**
     * returns the number of volumes and BMR selection count used by an hidden
     * input field for validation purpose only. eg. if 4 volumes selected and
     * BMR is disabled then count would be 4 and if BMR is enabled then count
     * would be 5.
     * volumesBMRSelectionCount is used as getterSetter in ng-model.
     *
     * @method   volumesBMRSelectionCount
     * @return   {number}   The count of number volumes and BMR selection.
     */
    function volumesBMRSelectionCount() {
      return (thisNode.protectionSource.physicalProtectionSource.volumes || [])
        .reduce(
          function forEachVolumes(acc, volume) {
            return acc + (volume._selected ? 1 : 0);
          },
          $ctrl.state.enableSystemBackup ? 1 : 0
        );
    }

    /**
     * reflect toggling allVolumesProtected changes to all available volumes
     * if areAllVolumesProtected is true the make volume._selected false
     * if areAllVolumesProtected is false then make volume._selected true
     *
     * @method   toggleAllVolumeSelection
     */
    function toggleAllVolumeSelection() {
      $ctrl.thisNode._volumesLvm.forEach(function forEachVolume(volume) {
        volume._selected = !$ctrl.state.areAllVolumesProtected;
      });
    }

    /**
     * init function for kOracle env source
     *
     * @method   activateForOracle
     */
    function activateForOracle() {
      var databaseType = thisNode._envProtectionSource.dbType;
      var serverId = thisNode.protectionSource.parentId;
      var dbId = thisNode.protectionSource.id;
      var currentDgPrimaryBackupState = _.chain(thisJob).get('_oracleMnmc')
        .get(serverId).get(dbId).get('enableDgPrimaryBackup').value();
      var parentHost = _.get(thisNode, '_parentHostProtectionSource');
      var agentIdToHostAddressMap =
        NgOracleUtilityService.getAgentIdToHostAddressMap(parentHost);

      // ------------- nodes -------------------------------------------------

      // Get the nodes which are already selected inside the job.
      $ctrl.state.nodeListInJob =  _.chain(thisJob).get('_oracleMnmc')
        .get(serverId).get(dbId).get('databaseNodeList', []).value();

      // From 6.5.1, magneto expects host id instead of host name in
      // databaseNodeList. So, here we convert the ids to ips, so our
      // component works as expected with backward compatibility.
      $ctrl.state.nodeListInJob.forEach(function iterNodeList(node) {
        // If node is id, replace it with corresponding node ip.
        if(!isNaN(node.node)) {
          node.node = _.get(agentIdToHostAddressMap, node.node + '[0]');
        }
      });

      // Set the archive log delete after if already exists.
      $ctrl.state.deleteArchiveAfter = _.chain(thisJob).get('_oracleMnmc')
        .get(serverId).get(dbId).get('archiveLogKeepDays').value();

      // Set the toggle to continue backups from DataGuard Primary database.
      if (_.isBoolean(currentDgPrimaryBackupState)) {
        $ctrl.state.enableDgPrimaryBackup = currentDgPrimaryBackupState;
      }

      // If the job has nodes selected show the
      // nodes selection form.
      if ($ctrl.state.nodeListInJob.length > 0) {
        $ctrl.state.nodeSelectType = 'manual';
      }

      switch(databaseType) {
        case 'kSingleInstance':
          _fillOracleSingleInstanceNodesFormData(agentIdToHostAddressMap);
          break;

        case 'kRACDatabase':
          _fillOracleRacNodesFormData(agentIdToHostAddressMap);
          break;
      }

      // ------------ db credentials -----------------------------------------
      // Persist the username and password if user has already
      // entered them.
      if (thisJob._dbCredentials) {
        $ctrl.state.host = thisJob._dbCredentials;
      }

      // Get the data required for db authentication form.
      SourceService.getSources({
        entityId: _.get(thisNode.protectionSource, 'parentId'),
      }).then(
          function populatePhysicalServers(res) {
            $ctrl.state.host.ownerEntity = res.entityHierarchy.entity;
        },
        evalAJAX.errorMessage);
    }


    /**
     * Fill the nodes info for Oracle single instance database.
     *
     * @method   _fillOracleSingleInstanceNodesFormData
     *
     * @param   {object}    nodeIdToIpMap     Agent id to node name has object
     */
    function _fillOracleSingleInstanceNodesFormData(nodeIdToIpMap) {
      var channels;
      var fqdn;
      var ip;
      var isSupported;
      var nodeAlreadySelectedInJob;
      var nodesFromParentHost;
      var nodeIpsFromDb;
      var port;
      var agentsWhichSupportMultiNodeMultiChannel = _.chain(thisNode)
        .get('_parentHostProtectionSource.agents', [])
        .filter(['oracleMultiNodeChannelSupported', true])
        .map('id')
        .value();

      var nodesWhichSupportMultiNodeMultiChannel =
        agentsWhichSupportMultiNodeMultiChannel.map(
          function iterAgentId(agentId) {
            return _.get(nodeIdToIpMap, agentId + '[0]');
          }
        );

      if (thisNode._parentHostProtectionSource.type === 'kOracleAPCluster') {
        // Select all nodes for active passive.
        $ctrl.state.selectAllByDefault = true;

        nodesFromParentHost = _.chain(thisNode)
          .get('_parentHostProtectionSource.networkingInfo.resourceVec', [])
          .filter(['type', 'kServer'])
          .map(function eachNode(node) {
            return _.get(node, 'endpoints[0]', {})
          })
          .value();

        channels = SourceService.calculateOracleChannnels(
          _.get(thisNode, '_envProtectionSource.hosts[0].cpuCount'));

        port = _.get(thisNode, '_envProtectionSource.hosts[0].ports[0]');

        _.forEach(nodesFromParentHost, function eachNode(node) {
          ip = node.ipv4Addr || node.ipv6Addr;
          fqdn = node.fqdn;
          isSupported = nodesWhichSupportMultiNodeMultiChannel
            .includes(ip) || nodesWhichSupportMultiNodeMultiChannel
            .includes(fqdn);

          // Check if the node is already selected in the job.
          nodeAlreadySelectedInJob = $ctrl.state.nodeListInJob.find(
            function isSelectedInJobApi(nodeInfo) {
              return nodeInfo.node === ip;
            });

          $ctrl.state.formData.push({
              isDisabled: true,
              isSelected: isSupported,
              fqdn: fqdn,
              ip: ip,
              channels: _.get(nodeAlreadySelectedInJob, 'channelCount', channels),
              isSupported: isSupported,

              // This is the default number of channels recommended for efficient
              // backup/restore.
              recommendedChannels: channels,
              port: _.get(nodeAlreadySelectedInJob, 'port', port),
            });
        });
      } else {
        if (thisNode._parentHostProtectionSource.type === 'kOracleRACCluster') {
          nodeIpsFromDb = _.get(thisNode,
            '_envProtectionSource.hosts[0].ipAddresses', []);
          nodesFromParentHost = _.chain(thisNode)
            .get('_parentHostProtectionSource.networkingInfo.resourceVec', [])
            .filter(['type', 'kServer'])
            .map(function eachNode(node) {
              return _.get(node, 'endpoints[0]', {})
            })
            .value();

          _.forEach(nodesFromParentHost, function eachNode(node) {
            var nodeIp = node.ipv4Addr || node.ipv6Addr;
            if (nodeIpsFromDb.includes(nodeIp)) {
              ip = nodeIp;
              fqdn = node.fqdn;
            }
          });
        } else {
          // Currently for Single Instance we get the ip from the
          // parent host's "name" attribute.
          ip = thisNode._parentHostProtectionSource.name;

          // Currently for Single Instance we get the ip from the
          // parent host's "hostname" attribute.
          fqdn = _.get(thisNode._parentHostProtectionSource, 'hostName');
        }

        isSupported = nodesWhichSupportMultiNodeMultiChannel
          .includes(ip) || nodesWhichSupportMultiNodeMultiChannel
          .includes(fqdn);

        channels = SourceService.calculateOracleChannnels(
          _.get(thisNode, '_envProtectionSource.hosts[0].cpuCount'));

        port = _.get(thisNode, '_envProtectionSource.hosts[0].ports[0]');

        // Check if the node is already selected in the job.
        nodeAlreadySelectedInJob = $ctrl.state.nodeListInJob.find(
          function isSelectedInJobApi(nodeInfo) {
            return nodeInfo.node === ip;
          });

        $ctrl.state.formData.push({
          isSelected: !!nodeAlreadySelectedInJob,
          fqdn: fqdn,
          ip: ip,
          channels: _.get(nodeAlreadySelectedInJob, 'channelCount', channels),
          isSupported: isSupported,

          // This is the default number of channels recommended for efficient
          // backup/restore.
          recommendedChannels: channels,
          port: _.get(nodeAlreadySelectedInJob, 'port', port),
        });
      }
    }

    /**
     * Fill the nodes info for Oracle RAC.
     *
     * @method   _fillOracleRacNodesFormData
     *
     * @param   {object}    nodeIdToIpMap     Agent id to node name has object
     */
    function _fillOracleRacNodesFormData(nodeIdToIpMap) {
      // Get the nodes data from database and the parent host.
      var nodesFromDb  = _.get(thisNode, '_envProtectionSource.hosts', []);

      var nodesFromParentHost = _.chain(thisNode)
        .get('_parentHostProtectionSource.networkingInfo.resourceVec', [])
        .filter(['type', 'kServer'])
        .value();

      var agentsWhichSupportMultiNodeMultiChannel = _.chain(thisNode)
        .get('_parentHostProtectionSource.agents', [])
        .filter(['oracleMultiNodeChannelSupported', true])
        .map('id')
        .value();

      var nodesWhichSupportMultiNodeMultiChannel =
        agentsWhichSupportMultiNodeMultiChannel.map(
          function iterAgentId(agentId) {
            return _.get(nodeIdToIpMap, agentId + '[0]');
          }
        );

      // 1. We have the fqdn data only in the parent host nodes i.e
      //    _parentHostProtectionSource.networkingInfo.resourceVec
      //    Note: This is the superset of nodes.
      // 2. We have our required nodes data at database level i.e.
      //    _envProtectionSource.hosts
      // 3. Iterate through 1 & 2 and create a formData with each node
      //    containing ip, fqdn, channels, port data.
      _.forEach(nodesFromDb, function getEachDbNode(fromDb) {
        var ipAddressses = fromDb.ipAddresses || [];
        var port = _.get(fromDb, 'ports[0]');
        var channels = SourceService.calculateOracleChannnels(fromDb.cpuCount);

          _.forEach(nodesFromParentHost,
            function forEachNodeFromParent(fromParent) {
              var nodeFromParentHost = _.get(fromParent, 'endpoints[0]', {});
              var ip = nodeFromParentHost.ipv4Addr ||
                nodeFromParentHost.ipv6Addr;
              var fqdn = nodeFromParentHost.fqdn;
              var nodeAlreadySelectedInJob = false;

                if (ipAddressses.includes(ip)) {
                  // Check if node is already selected in the job.
                  nodeAlreadySelectedInJob = $ctrl.state.nodeListInJob
                    .find(function isSelectedInJobApi(nodeInfo) {
                        return nodeInfo.node === ip;
                    });

                  // Push the required nodes into formData
                  // which is used in the template to show the nodes.
                  $ctrl.state.formData.push({
                    isSelected: !!nodeAlreadySelectedInJob,
                    fqdn: fqdn,
                    ip: ip,
                    channels: _.get(nodeAlreadySelectedInJob, 'channelCount',
                      channels),
                    isSupported: nodesWhichSupportMultiNodeMultiChannel
                      .includes(ip) || nodesWhichSupportMultiNodeMultiChannel
                      .includes(fqdn),

                    // This is the default number of channels recommended for
                    // efficient backup/restore.
                    recommendedChannels: channels,
                    port: _.get(nodeAlreadySelectedInJob, 'port', port),
                  });
                }
            });
      });
    }

    /**
     * handle save action for kOracle source
     *
     * @method   saveForOracle
     */
    function saveForOracle() {
      var databaseNodeList = [];
      var selectedMnmcDbNodeData;

      $ctrl.state.formData.forEach(function forEachNode(node){
        var databaseNodeListItem;

        if (node.isSelected) {
          databaseNodeListItem = {
            node: node.ip,
            channelCount: node.channels,
            port: node.port,
          };
          databaseNodeList.push(databaseNodeListItem);
        }
      });

      selectedMnmcDbNodeData = {};

      // Save the MNMC node data to the job.
      if (databaseNodeList.length > 0) {
        _.assign(selectedMnmcDbNodeData, {
          databaseNodeList: databaseNodeList,
        });
      }

      if (typeof $ctrl.state.deleteArchiveAfter !== 'undefined') {
        _.assign(selectedMnmcDbNodeData, {
          archiveLogKeepDays: $ctrl.state.deleteArchiveAfter,
        });
      }

      _.assign(selectedMnmcDbNodeData, {
        databaseUniqueName: PubSourceService.getDabaseUniqueName(thisNode),
        databaseUuid: _.get(thisNode, '_envProtectionSource.uuid'),
      });

      selectedMnmcDbNodeData.enableDgPrimaryBackup =
        $ctrl.state.enableDgPrimaryBackup;
      _setSelectedMnmcDbNodeData(selectedMnmcDbNodeData);


      // Fill in the db credentials data
      thisJob._dbCredentials = $ctrl.state.host.appCredentialsVec[0]
        .credentials.username && $ctrl.state.host.appCredentialsVec[0]
        .credentials.password ? $ctrl.state.host : undefined;
    }

    /**
     * Set the selected oracle database node.
     *
     * @method   _setSelectedMnmcDbNodeData
     */
    function _setSelectedMnmcDbNodeData(nodeData) {
      var parentId = thisNode.protectionSource.parentId;
      var sourceId = thisNode.protectionSource.id;

      // Set the empty object to avoid javascript errors
      if (!thisJob._oracleMnmc[parentId]) {
        thisJob._oracleMnmc[parentId] = {};
      }

      if (!thisJob._oracleMnmc[parentId][sourceId]) {
        thisJob._oracleMnmc[parentId][sourceId] = {};
      }

      thisJob._oracleMnmc[parentId][sourceId] = nodeData;
    }

    /**
     * initialization function
     *
     * @method $onInit
     */
    function $onInit() {
      angular.extend(
        $ctrl,
        serverOptionsConfig[thisNode.protectionSource.environment] || {}
      );

      $ctrl.onActivate();
    }

    /**
     * handle save server options action
     *
     * @method   saveSettings
     * @param    {Obejct}   serverOptionsForm   The From object
     */
    function saveSettings(serverOptionsForm) {
      if (serverOptionsForm.$invalid) {
        return;
      }

      $ctrl.onSave();
      $uibModalInstance.close();
    }

    /**
     * cancels the modal instance
     *
     * @method   cancelModal
     */
    function cancelModal() {
      $uibModalInstance.dismiss();
    }

    /**
     * Determines if the server settings can be saved based on the environment.
     *
     * @method   canSaveSettings
     * @return   {Boolean}   True, if settings can be saved. False, otherwise.
     */
    function canSaveSettings() {
      switch ($ctrl.thisNode._environment) {
        case 'kOracle':
          return $ctrl.state.canSaveSettings;
        default:
          return true;
      }

    }
  }

}(angular));
