import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

import { ListIssuesDialogParams } from '../../models/list-issues-dialog-params';

@Component({
  selector: 'dg-pa-issues-dialog',
  templateUrl: './issues-dialog.component.html',
  styleUrls: ['./issues-dialog.component.scss'],
})
export class IssuesDialogComponent {

  /**
   * Parameter needed to filter issues by cluster id
   */
  clusterId: number;

  /**
   * Parameter needed to display or not the mat-paginator or filter issues if cluster id not present
   */
  clusterRegionName: string;

  /**
   * Constructor
   */
  constructor(@Inject(MAT_DIALOG_DATA) public dialogParams: ListIssuesDialogParams) {
    this.clusterId = this.dialogParams.clusterId;
    this.clusterRegionName = this.dialogParams.clusterRegionName;
  }
}
