/**
 * enum containing all the supported columns for the inventory table
 */
export enum TableColumns {
  objectName = 'objectName',
  verdict = 'verdict',
  protection = 'protection',
  anomalies = 'anomalies',
  threats = 'threats',
  classification = 'classification',
  vaulting = 'vaulting',
  vulnerability = 'vulnerability',
  postureRisk = 'posture-risk',
  tags = 'tags',
}
