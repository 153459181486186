// Component: cControlboard

;(function(angular, undefined) {
  'use strict';

  /**
   * @ngdoc directive
   * @name C.controlboard:cControlboard
   * @function
   *
   * @description
   * controlboard for grouping and displaying
   * filtering and searching options for data display
   *
   * @example
     <c-controlboard>
         <cb-option>
             <cb-control>
                <c-date-ranger-toggle
                  show-date-ranger="showDateRanger"
                  dates="dates"></c-date-ranger-toggle>
             </cb-control>
             <cb-panel>
                <c-date-ranger
                  show-date-ranger="showDateRanger"
                  on-save="updateDateParams()"
                  dates="dates"></c-date-ranger>
             </cb-panel>
         </cb-option>
         <cb-option>
             <cb-control class="pull-right">
                <button class="c-btn" ng-click="doSomething()">
                  Control Button
                </button>
             </cb-control>
          </cb-option>
     </c-controlboard>
   */
  var moduleName = 'C.controlboard';
  var directiveName = 'cControlboard';
  var directiveOpts = {
    restrict: 'E',
    transclude: true,
    link: postLinkFn,
    templateUrl: 'app/global/c-controlboard/c-controlboard.html'
  };

  angular
    .module(moduleName, [])
    .directive(directiveName, directiveOptsFn);

  function directiveOptsFn() {
    return directiveOpts;
  }

  /**
   * linking function for cControlboard directive
   */
  function postLinkFn(scope, elem, attrs) {

    var filters = elem.find('cb-option');
    var controlsContainer =
      angular.element(elem.find('.c-controlboard-controls')[0]);
    var panelsContainer =
      angular.element(elem.find('.c-controlboard-panels')[0]);
    var controls = [];
    var panels = [];
    var index = 0;
    var currentOpt;
    var optControl;
    var optPanel;

    for (index; index < filters.length; index++) {
      currentOpt = angular.element(filters[index]);
      // a filter requires a <control>,
      optControl = angular.element(currentOpt.find('cb-control')[0]);
      if (optControl.length) {
        // TODO: element needs to be compiled after adding ng-class
        // if (currentOpt.attr('active')) {
        //     optControl.attr('ng-class',
        //      ["{'active': ", currentOpt.attr('active'), "}"].join(''));
        // }
        controls.push(optControl);
      }

      // a panel is optional.
      optPanel = angular.element(currentOpt.find('cb-panel')[0]);
      if (optPanel.length) {
        panels.push(optPanel[0]);
      }

    }

    controlsContainer.append(controls);
    panelsContainer.append(panels);

    elem.find('div[ng-transclude]').remove();
  }

})(angular);
