// MODULE: Login form

import { isMcmGovCloud, isOneHeliosAppliance } from '@cohesity/iris-core';

;(function(angular, undefined) {
  'use strict';

  angular.module('C.login', ['C.eula'])
    .config(configFn)
    .controller('LoginFormController', LoginFormControllerFn);

  function configFn($stateProvider, $urlRouterProvider) {
    $stateProvider
      .state('login', {
        url: '/login?{onboardingToken}&{enableFreeTrial}',
        title: 'Login',
        canAccess: 'true',
        params: {

          // Onboarding token to enroll customer into DMaaS from marketplace.
          onboardingToken: {
            type: 'string',
            squash: true,
          },

          // enables the free trial for the customer.
          enableFreeTrial: {
            type: 'bool',
            squash: true,
          },
        },
        templateUrl: 'app/login/login.html',
        controller: 'LoginFormController as $ctrl',
      })
      .state('logout', {
        url: '/logout',
        title: 'Logout',
        canAccess: 'true',
        component: 'logoutPage',
      })
      .state('forbidden', {
        url: '/forbidden',
        title: '403 - Forbidden',
        canAccess: 'true',
        templateUrl: 'app/login/forbidden.html',
      });
  }

  function LoginFormControllerFn(_, $rootScope, $http, $scope, $state, $q, $timeout,
    UserService, evalAJAX, ClusterService, LicenseService, cMessage, $window,
    FEATURE_FLAGS, localStorageService, moment, cFocus, EulaService,
    $stateParams, NgClusterService, NgSfdcRenderer, NgIrisContextService,
    ngDialogService, NG_IS_IBM_AQUA_ENV) {
    var $ctrl = this;
    var rememberedAdCredentials = localStorageService.get('rememberedAdCredentials');

    _.assign($ctrl, {
      basicClusterInfo: ClusterService.basicClusterInfo,
      checkingValidCertificate: false,
      clusterIdpConfigured: false,
      loginDescKey: 'login.ssoDesc',
      orgSelection: false,
      ssoOrganization: '',
      tenantIdpConfigured: false,
      loading: false,
      isSignup: false,
      showNewFlow: false,
      mcmLoginMode: rememberedAdCredentials ? 'ad' : 'user',
      loginInProgress: false,
      rememberMe: rememberedAdCredentials ? true : false,
      description: 'toCohesityDataCloud',
      copyrightCompany: 'cohesityInc',
      illustration: '/assets/i/cohesity-data-cloud-login.svg',
      displayM365LoginRedirect: false
    });

    $scope.credentials = {
      ...{
        username: '',
        password: '',
        domain: 'LOCAL',
      },
      ...rememberedAdCredentials,
    };

    $scope.domainOptionsList = [];

    $ctrl.useSSO = false;

    // Focus the user on the input when switching between
    // SSO and regular login
    $ctrl.toggleSSO = () => {
      $ctrl.useSSO = !$ctrl.useSSO;
      $timeout(() => {
        $ctrl.useSSO ? cFocus('username') : cFocus('sfid-username');
      }, 500);
    }

    if (NG_IS_IBM_AQUA_ENV) {
      $ctrl.description = 'toIbmDataCloud',
      $ctrl.copyrightCompany = 'ibmCorporation',
      $ctrl.illustration = '/assets/i/ibm-data-cloud-login.svg'
    }

    if (ClusterService.basicClusterInfo.mcmOnPremMode) {
      $ctrl.displayM365LoginRedirect = FEATURE_FLAGS.office365SelfServiceRecoveryEnabled && !NG_IS_IBM_AQUA_ENV;
    } else {
      $ctrl.displayM365LoginRedirect = FEATURE_FLAGS.dmsOffice365SelfServiceRecoveryEnabled && !NG_IS_IBM_AQUA_ENV;
    }

    if (isMcmGovCloud(NgIrisContextService.irisContext)) {
      // Show a notice to gocvloud users before they can login.
      setTimeout(() => ngDialogService.showGovCloudNoticeDialog());
    }

    /**
     * Validates and returns whether integrated experience is supported.
     *
     * @returns  True if the login page show integrated login experience
     */
    function isIntegratedExperienceSupported() {
      if (!FEATURE_FLAGS.enableNewHeliosLogin) {
        return false;
      }

      // The following are not supported
      // 1. Not Helios mode (On-Prem Context)
      // 2. Helios On-Prem is not supported
      // 3. AWS Onboarding workflow is not supported
      // 4. Free Trial workflow is not supported.
      switch (true) {
        case !$scope.basicClusterInfo.mcmMode:
          return false;
        case $scope.basicClusterInfo.mcmOnPremMode:
          return false;
        case !!$stateParams.onboardingToken:
          return false;
        case !!$stateParams.enableFreeTrial:
          return false;
        default:
          return true;
      }
    }

    /**
     * initilization/activation function
     *
     * @method   activate
     */
    function activate() {
      $ctrl.copyrightYear = moment().year();

      $ctrl.isSignup = !!$stateParams.onboardingToken;

      // Integrated Flow is not available for Helios On-Prem.
      $ctrl.showNewFlow = isIntegratedExperienceSupported();

      // Domain comparison should be case insensitive, thus lowercase.
      var cachedDomain =
        (localStorageService.get('domain') || '').toLowerCase();

      $scope.domainOptionsList = $scope.basicClusterInfo.domains;

      if ($ctrl.showNewFlow) {
        NgClusterService.getMcmConfiguration().toPromise()
          .then(function getConfig(config) {
            NgSfdcRenderer.addSfdcTags(config);
          });
        return;
      }

      // if authenticationType is 'kPasswordOnly' the user has not enabled
      // two-factor auth so we should move forward with our normal login flow
      // and find the correct domaun from localStorage.
      // Else the user has enabled two-factor authentication so we should
      // check for a valid certificate, and reset the domain to 'LOCAL'
      if ($scope.basicClusterInfo.authenticationType === 'kPasswordOnly') {
        $scope.basicClusterInfo.domains.find(function findDomain(domain) {

          // Compare using the lowercase value, but assign the whatever case is
          // in basicClusterInfo.domains[].
          if (domain.toLowerCase() === cachedDomain) {
            $scope.credentials.domain = domain;
            return true;
          }
        });
      } else {
        _checkValidCertificate();

        $scope.credentials.domain = 'LOCAL';
      }

      // Single Sign On Checks
      $ctrl.tenantIdpConfigured = $scope.basicClusterInfo.idpTenantExists;
      $ctrl.clusterIdpConfigured = $scope.basicClusterInfo.idpConfigured;

      // If only the tenant have SSO configured and cluster does not have SSO
      // configured. Enable the org selection by default.
      $ctrl.orgSelection =
        $ctrl.tenantIdpConfigured && !$ctrl.clusterIdpConfigured;

      // Determine the Login description to be shown for the SSO.
      switch(true) {
        case $ctrl.tenantIdpConfigured && $ctrl.clusterIdpConfigured:
          $ctrl.loginDescKey = 'login.ssoDesc';
          break;
        case $ctrl.tenantIdpConfigured:
          $ctrl.loginDescKey = 'login.ssoDescTenant';
          break;
        case $ctrl.clusterIdpConfigured:
          $ctrl.loginDescKey = 'login.ssoDescCluster';
          break;
      }

      cFocus('username');
    }

    /**
     * if the user has two-factor auth enabled we need to check if they
     * are using a valid certificate or not and reroute them to the correct
     * page
     */
    function _checkValidCertificate() {
      $ctrl.checkingValidCertificate = true;

      UserService.authenticate({}).then(function loginSuccess() {
        ClusterService.getClusterInfoWithLocale().then(
          function getClusterInfoSuccess() {
            $rootScope.clusterInfo = ClusterService.clusterInfo;

            postLogin();
          }, evalAJAX.errorMessage);
      }).catch(function failedLogin(resp) {
        // User has enabled Two-Factor Auth but is not using any certificates,
        // keep them on the login page and allow them to login with Local Admin
        // credentials
        if (resp.data.errorCode === 'KStatusUnauthorized') {
          $scope.userStatusUnauthorized = true;
        }

        // User has enabled Two-Factor Auth but is using an invalid certificate
        // re route them to a 403 forbidden page
        else {
          cMessage.error({
            textKey: resp.data.message,
          });
          $state.go('forbidden');
        }
      }).finally(function authCheckDone() {
        $ctrl.checkingValidCertificate = false;
      });
    }

    $scope.changeLoginMode = function changeLoginMode(mode) {
      const validModes = ['user', 'ad', 'sso'];
      if (!validModes.includes(mode)) {
        console.error('Invalid mode provided');
        return;
      }

      $ctrl.mcmLoginMode = mode;
    }

    /**
     * Handles the setup process for One Helios Appliance.
     * If the cluster creation is in progress, navigates to 'appliance-manager-setup'.
     * Otherwise, proceeds to handle the cluster detection.
     */
    function handleOneHeliosSetup(isFreeNode) {
      // Redirect to appliance-manager-setup page if the node is free
      if (isFreeNode) {
        $state.go('appliance-manager-setup');
      } else {
        ClusterService.isClusterCreateInProgress().then(inProgress => {
          if (inProgress) {
            $state.go('appliance-manager-setup');
          } else {
            handleClusterDetected();
          }
        });
      }
    }

    /**
     * Handles the setup process for a free node.
     * If the cluster creation is in progress, navigates to 'cluster-setup.confirm'.
     * If the feature flag for ngClusterCreate is enabled, navigates to 'cluster-create'.
     * Otherwise, navigates to 'cluster-setup'.
     */
    function handleClusterSetup() {
      ClusterService.isClusterCreateInProgress().then(inProgress => {
        if (inProgress) {
          $state.go('cluster-setup.confirm');
        } else if (FEATURE_FLAGS.ngClusterCreate) {
          $state.go('cluster-create');
        } else {
          $state.go('cluster-setup');
        }
      });
    }

    /**
     * Handles the process of retrieving and setting the cluster information.
     * If successful, sets the cluster info to $rootScope and calls postLogin().
     * Otherwise, calls the shared error handler function.
     */
    function handleClusterDetected() {
      ClusterService.getClusterInfoWithLocale().then(
        function getClusterInfoSuccess() {
          $rootScope.clusterInfo = ClusterService.clusterInfo;
          postLogin();
        },
        sharedErrorHandlerFn
      );
    }

    /**
     * validates the login form and takes appropriate next step based on
     * cluster/node status
     *
     * @method   login
     */
    $scope.login = function login() {

      if ($ctrl.loginForm.$invalid) {
        return;
      }
      $scope.spinning = true;

      //Sanitize user input.
      if ($scope.credentials.domain) {
        $scope.credentials.domain = encodeURI($scope.credentials.domain.trim());
      }
      UserService.authenticate($scope.credentials).then(
        function authenticateSuccess(loginData) {

          // If MFA authentication is needed, then user service return a
          // null to indicate that additional proces]sing is needed before
          // UI is authenticated.
          if (!loginData) {
            return;
          }

          const isFreeNode = !loginData.isNodeInCluster;
          if (isOneHeliosAppliance(NgIrisContextService.irisContext)) {
            handleOneHeliosSetup(isFreeNode);
          } else if (isFreeNode) {
            handleClusterSetup();
          } else {
            handleClusterDetected();
          }
        },
        sharedErrorHandlerFn
      );
    };

    /**
     * Triggers the Single Sign On Authentication by redirecting to the
     * /mcm/login. The backend now calls the salesforce endpoints to perform
     * the authorization flow.
     *
     * @method   loginHelios
     * @param    isSSO   boolean   Indicates whether it is Helios SSO login.
     */
    $scope.loginToHelios = function loginToHelios(isSSO) {
      if (isSSO && !_.get($scope.credentials, 'username.length')) {
        cMessage.error({
          textKey: 'login.usernameRequired',
        });
        return;
      }

      var onboardingToken = $rootScope.$stateParams.onboardingToken;
      // Set the localStorage to check for login attempt when app bootstrap
      // after SSO authentication.
      var redirectUrl = UserService.getPreLoginRequestUrl();
      var username = $scope.credentials.username;
      var params = [
        username ? 'username=' + encodeURIComponent(username) : '',
        'type=' + (isSSO ? 'kSso' : 'kSalesforce'),
        redirectUrl ? 'redirectTo=' + redirectUrl : '',
        onboardingToken && !!onboardingToken.length ?
          `onboardingToken=${onboardingToken}` : '',
        $stateParams.enableFreeTrial ? 'enableFreeTrial=true' : '',
      ].filter(function filterParams(param) {
        return param.length;
      });

      var location = '/mcm/login?' + params.join('&');
      $window.location.href = location;
    };

    /**
     * Triggers the Authenticattion with Helios On-Prem system.
     * This request will be performed as a request and response and not as SSP
     * redirects.
     *
     * @method   loginToHeliosOnPrem
     */
    $scope.loginToHeliosOnPrem = function loginToHeliosOnPrem() {
      if ($ctrl.mcmLoginMode === 'sso') {
        return $scope.loginToHelios(true);
      }

      if ($ctrl.mcmLoginMode === 'user') {
        $scope.credentials.domain = 'LOCAL';
      }

      if ($ctrl.mcmLoginMode === 'ad') {
        $ctrl.loginInProgress = true;
      }

      if ($ctrl.mcmLoginMode === 'ad' && $ctrl.rememberMe) {
        localStorageService.set('rememberedAdCredentials', {
          username: $scope.credentials.username,
          domain: $scope.credentials.domain
        });
      } else {
        localStorageService.remove('rememberedAdCredentials');
      }

      UserService.authenticate($scope.credentials).then(
        function authenticateSuccess(user) {
          /**
           * Should not proceed furthur if MFA enabled on heliosOnPremMode
           * MFA handled in above UserService.authenticate
           */
          if (!user) {
            $ctrl.loginInProgress = false;
            return;
          }

          // After successful authentication with Helios.
          // Get the cluster information.
          ClusterService.getClusterInfoWithLocale().then(
            function getClusterInfoSuccess() {
              $rootScope.clusterInfo = ClusterService.clusterInfo;

              // TODO(Sam): Add EULA and licensing to the Helios On Prem app.
              ClusterService.updateRootWithLicenseSuccess();
              $ctrl.loginInProgress = false;
              $state.goHome();
            },
            sharedErrorHandlerFn
          );
        },
        sharedErrorHandlerFn
      );
    };

    /**
     * Opens the sign up form for DMaaS.
     *
     * @method   signupForDms
     */
    $scope.signupForDms = function signupForDms() {
      var params = [
        'signupType=aws',
        $stateParams.onboardingToken ?
          `onboardingToken=${$stateParams.onboardingToken}` : null,
      ].filter(Boolean).join('&');

      $window.location.href = `/mcm/dmaas/signup?${params}`;
    }

    /**
     * Triggers the SSO Login when IdP is enabled in the cluster
     *
     * @method   ssoLogin
     */
    $ctrl.ssoLogin = function ssoLogin() {
      if ($ctrl.ssoLoginForm.$invalid) {
        return;
      }

      // If an organization is not selected, clear the input field.
      if (!$ctrl.orgSelection) {
        $ctrl.ssoOrganization = undefined;
      }

      UserService.authenticateIDP($ctrl.ssoOrganization);
    };

    /**
     * kicks the API's error message and stops form spinner
     *
     * @method   sharedErrorHandlerFn
     * @param    {object}   response   The API response
     */
    function sharedErrorHandlerFn(response) {
      // Reset password to avoid attackers access to failed password
      $scope.credentials.password = '';
      if ($ctrl.loginForm) $ctrl.loginForm.$setPristine();
      if ($ctrl.ssoLoginForm) $ctrl.ssoLoginForm.$setPristine();
      $timeout(function () {
        cFocus('password');
      }, 500);

      evalAJAX.errorMessage(response);
      $scope.spinning = false;
      $ctrl.loginInProgress = false;
    }

    /**
     * checks for available Cohesity software updates and provides an actionable
     * cMessage if software updates are found
     *
     * @method   checkForUpdates
     */
    function checkForUpdates() {
      // Check if this cluster has new packages
      ClusterService
        .checkForNewPackages()
        .then(function displayUpdatesNotification(data) {
          if (data.hasNewPackages) {
            cMessage.info({
              titleKey: 'upgradeMessage.title',
              textKey: 'upgradeMessage.text',
              acknowledgeTextKey: 'upgrade',
              dismissTextKey: 'dismiss',
              persist: true,
            }).then(
              function upgradeClicked() {
                ClusterService.acknowledgeUpgrades();
                $state.go('cluster-upgrade');
              },

              // cMessage dismissed
              ClusterService.acknowledgeUpgrades
            );
          }
        });
    }

    /**
     * Post Login logical flow. Should User see EULA? Should we check for
     * Updates? Redirect user as necessary.
     *
     * @method   postLogin
     */
    function postLogin() {

      checkEulaAcceptance().then(
        function licenseSuccess() {
          UserService.loginSuccess();

          if ($rootScope.user.privs &&
            $rootScope.user.privs.CLUSTER_UPGRADE) {
            // Can this user modify the cluster/perform an upgrade?
            // If so lets check for updates.
            checkForUpdates();
          }
          UserService.redirectPostLogin();
        },
        function eulaCanceled() {
          UserService.logout();

          // Force a reload of this state so app will be re-bootstrapped. This
          // is necessary because $rootScope.basicClusterInfo is cleared on
          // logout but is needed in this controller/template.
          $state.reload();
        }
      ).finally(
        function stopSpinning() {
          $scope.spinning = false;
        }
      );
    }

    /**
     * Check if HP Eula is accepted.
     *
     * @method   checkHpEulaAcceptence
     * @return   {object}   promise object to be resolved or rejected
     */
    function checkHpEulaAcceptence() {
      if (FEATURE_FLAGS.licensing) {
        $state.go('eula', {eulaType : 'hpEula'});
      } else {
        return EulaService.showModal('hpEula');
      }
    }

    /**
     * Present the License Key modal
     *
     * @method   requestLicenseKey
     * @param    {object}    eulaConfig   The eula configuration, sans license
     *                                    key
     * @param    {<type>}    deferred     The deferred
     * @return   {promise}   promise to resolve request for license key input
     */
    function requestLicenseKey(eulaConfig, deferred) {
      return LicenseService.showModal(eulaConfig).then(
        deferred.resolve,
        deferred.reject
      );
    }

    /**
     * Check if the EULA needs to be accepted. If so, launch the license flow
     * modals
     *
     * @method   checkEulaAcceptance
     * @return   {object}   promise to resolve check for eula acceptance
     */
    function checkEulaAcceptance() {
      var deferred = $q.defer();
      if (!FEATURE_FLAGS.licensing) {
        if (ClusterService.isEulaNeeded()) {
          // User has not yet accepted EULA or there is a new EULA
          EulaService.showModal().then(
            function eulaAccepted(eulaConfig) {
              // Check if EULA is accepted by the customer already. This will be
              // empty when customer is configuring the cluster.
              // At this point, the clusterInfo EULA does not have the new signed
              // version, even though this happens inside the EULA modal success.
              if (!!ClusterService.clusterInfo.eulaConfig) {
                ClusterService.updateEula(eulaConfig).then(
                  deferred.resolve,
                  deferred.reject
                );
              } else if (ClusterService.isHpEulaNeeded()) {
                // Check if cluster is using HP hardware, if true open HP Eula
                // modal
                checkHpEulaAcceptence().then(
                  function hpEulaSuccess(hpEulaConfig) {
                    requestLicenseKey(eulaConfig, deferred);

                  }, deferred.reject);
              } else {
                requestLicenseKey(eulaConfig, deferred);
              }

            }, deferred.reject);
        } else {
          deferred.resolve('eula-already-accepted');
        }

        // if licensing is disable for feature flag, just make license
        // success true so it wont stop rendering of nav bar and footer
        ClusterService.updateRootWithLicenseSuccess();
      } else {
        // TODO:Manthan check HP EULA condition later
        if (ClusterService.isEulaNeeded()) {
          // User has not yet accepted EULA or there is a new EULA
          $state.go('eulaNew', { eulaType: 'cohesity' })
        } else if (ClusterService.isNewCluster() &&
            ClusterService.isLicenseAcceptanceNeeded()) {
            if (FEATURE_FLAGS.clusterLicensePageRevamp) {
              $state.go('connected-site-ng.select-mode');
            } else {
              $state.go('connected-site', { registrationType: 'saas' });
            }
        }
        else {
          // Old user need to accept licensing
          if (ClusterService.isLicenseAcceptanceNeeded()) {
            ClusterService.showLicenseNotDeployedWarning();
          }

          ClusterService.updateRootWithLicenseSuccess();
          ClusterService.showLicenseExpiryMsg();
          deferred.resolve();
        }
      }
      return deferred.promise;
    }
    activate();
  }
})(angular);
