<cog-settings-list-item
  [invalid]="formControl.invalid"
  [label]="'alerts' | translate">

  <cog-settings-list-item-value-display>
    <ul class="c-ul-inline">
      <li>
        {{'alertOnColon' | translate}}
        <span *ngIf="formControl.value.backupRunStatus.length">
          {{formControl.value.backupRunStatus | simpleList: { translate: true } }}
        </span>
        <span *ngIf="!formControl.value.backupRunStatus.length">
          {{'none' | translate}}
        </span>
      </li>
      <li *ngIf="formControl.value.alertTargets.length">
        {{'emailRecipientsColon' | translate}}
        {{formControl.value.alertTargets.length}}
      </li>
    </ul>
  </cog-settings-list-item-value-display>

  <cog-settings-list-item-value-editor>
    <div class="margin-bottom">
      <mat-label>{{'alertOnColon' | translate}}</mat-label>
    </div>
    <mat-checkbox cogDataId="alert-on-{{option}}"
      class="padding-right"
      *ngFor="let option of statusOptions"
      [checked]="formControl.value.backupRunStatus.includes(option)"
      (change)="handleAlertSelection($event, option)">
      {{option | translate}}
    </mat-checkbox>

    <div class="margin-top-lg">
      <mat-label>{{'emailRecipientsColon' | translate}}</mat-label>
    </div>
    <div class="alert-target-wrapper"
      [formGroup]="alertTarget"
      *ngFor="let alertTarget of alertTargets; let index = index;">
      <mat-form-field [hideRequiredMarker]="true" class="email-recipient-type margin-right-sm">
        <mat-select cogDataId="recipient-type" formControlName="recipientType">
          <mat-option *ngFor="let recipientType of recipientTypes" [value]="recipientType">
            {{recipientType | translate}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="alertTarget.get('recipientType').hasError('required')">
          {{'errors.required' | translate}}
        </mat-error>
      </mat-form-field>
      <mat-form-field [hideRequiredMarker]="true" floatLabel="never">
        <input
          [placeholder]="'emailAddress' | translate"
          formControlName="emailAddress"
          [cogDataId]="'alert-email-address-' + index"
          matInput>
        <mat-error *ngIf="alertTarget.get('emailAddress').hasError('required')">
          {{'errors.required' | translate}}
        </mat-error>
        <mat-error *ngIf="alertTarget.get('emailAddress').hasError('email')">
          {{'errors.email' | translate}}
        </mat-error>
      </mat-form-field>

      <coh-select-locale *ngIf="localizationEnabled"
        [cogDataId]="'alert-select-locale-' + index"
        class="margin-left-sm"
        formControlName="language"
        [hideLabel]="true">
      </coh-select-locale>

      <button [cogDataId]="'remove-alert-email-button-' + index"
        type="button"
        (click)="removeAlertTarget(index)"
        mat-icon-button>
        <cog-icon shape="clear"></cog-icon>
      </button>
    </div>

    <div class="margin-top-sm">
      <button cogDataId="add-alert-email-button"
        type="button"
        (click)="addAlertTarget()"
        color="primary"
        mat-stroked-button>
        {{'add' | translate}}
      </button>
    </div>
  </cog-settings-list-item-value-editor>
</cog-settings-list-item>
