import { Injectable } from '@angular/core';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';
import { Transition } from '@uirouter/core';
import { combineLatest } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AppServiceManagerService } from 'src/app/app-services';

import { NavService } from '../../app-layout/nav.service';
import { CustomizationService } from '../../services';
import { GuardPriority, GuardResult, StateGuard } from '../state-guard';

/**
 * Dashboards Guard
 * Handles redirecting the base dashboard state. This allows for clicks to simply go to "dashboards" and the
 * app to decide the most appropriate dashboard.
 */
@Injectable({
  providedIn: 'root',
})
export class DashboardsGuard implements StateGuard {
  /**
   * This guard priority is set to App, which indicates it should run as part of the main application
   * After all bootstrapping and initializing is completed.
   */
  guardPriority = GuardPriority.App;

  /**
   * Match requests for the abstract dashboards state and determine the users
   * preferred dashboard before further redirection.
   */
  matchCriteria = {
    to: 'dashboards',
  };

  constructor(
    private appServiceManager: AppServiceManagerService,
    private irisContextService: IrisContextService,
    private customizationService: CustomizationService,
    private navService: NavService,
  ) {}

  /**
   * Redirects base dashboards state to the appropriate dashboard.
   *
   * @param   transition  The UI router transition
   * @returns a redirect transtion.
   */
  onStart(transition: Transition): GuardResult {
    const irisContext = this.irisContextService.irisContext;

    // NOTE: Because our scope selection is not properly state guarded and prioritized
    // this is a hack to handle sending to the correct dashboard on initial (re)load.
    if (Object.keys(irisContext.selectedScope).length === 0) {
      const selectedScope = JSON.parse(localStorage.getItem('C.selectedCluster') || '{}');
      const selectedService = this.appServiceManager.getServiceByClusterConfig(selectedScope);

      switch (true) {
        case !!selectedService?.homeState:
          return transition.router.stateService.target(selectedService?.homeState);
      }
    }
    // NOTE: End of hack.

    // Check if the Active Service has a default state and treat that as the "dashboard"..
    // Unless the homeState for a service is the generic "dashboards", which would
    // create a redirect loop here.
    // Using such a homeState and bypassing it in this check allows the logic
    // in this guard to be applied to determine the correct dashboard for which
    // to send the user.
    const activeService = this.appServiceManager.getActiveService();
    if (activeService?.homeState !== 'dashboards') {
      return transition.router.stateService.target(activeService.homeState);
    }

    const dashboardPref = this.customizationService.userCustomizations?.preferences?.preferredDashboard;

    if (!Object.keys(irisContext.selectedScope).length) {
      // if selectedScope has not been set, attempt to get dashboardsList$ below
      // will not work and this will block the entire application. "selectedScope" needs
      // to be moved into a proper state guard as part of the boostrapping
      // process. In the meantime, Fallback to preferred dashboard or summary.
      // TODO: investigate the impact of this on DMaaS / Global context. Might be
      // able to hack a redirect from the summary dashboard based on context checking?
      return transition.router.stateService.target(dashboardPref || 'dashboards.summary');
    }

    return combineLatest([
      this.navService.dashboardsList$,
      this.customizationService.hiddenDashboards$,
    ]).pipe(
      take(1),
      map(([dashboards, hiddenBoards]) => {
        const dashPrefNavItem = dashboards.find(board => board.state === dashboardPref);
        const isPrefHidden = hiddenBoards.includes(dashPrefNavItem?.state);

        // If customizations is enabled, filter the list so it doesn't include hidden items.
        dashboards = flagEnabled(irisContext, 'uiCustomization')
          ? dashboards.filter(board => !hiddenBoards.includes(board.state))
          : dashboards;

        // If the feature is enabled, use the user's preference if its not on the hidden dashboards list,
        // otherwise fallback to the first nav item.
        const useUserPreference = flagEnabled(irisContext, 'uiCustomization') && dashPrefNavItem && !isPrefHidden;

        const transitionDashboardStateName = useUserPreference ? dashPrefNavItem.state : dashboards[0]?.state;

        const toParams = transition.params();
        return transition.router.stateService.target(
          transitionDashboardStateName,
          { serviceType: toParams.serviceType || null },
          { reload: true }
        );
      })
    );
  }
}
