import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';

import { DataTreeNodeContext } from '../data-tree-detail.directive';
import {
  DataTreeSelectionChangeEvent,
  DataTreeSelectionChangeEventType,
} from '../shared/data-tree-selection-change-event.model';
import { DataTreeSelectionModel } from '../shared/data-tree-selection-model';
import { DataTreeNode } from '../shared/data-tree.model';

/**
 * The data tree node is responsible for showing the expand/collapse buttons, selection, and
 * auto selection options for a node. Node details are projected via ng-content. This is used
 * internally by DataTreeComponent and is not exposed outside of this module.
 *
 * @example
 * <cog-data-tree-node-checkbox *ngIf="data && treeSelection && treeControl.dataNodes"
 *   [node]="node"
 *   [autoSelectIcon]="autoSelectIcon"
 *   [excludedIcon]="autoSelectIcon"
 *   [noModifierClass]="false"
 *   [autoSelectedIcon]="autoSelectedIcon"
 *   [ancestorExcludedIcon]="ancestorExcludedIcon"
 *   [selection]="treeSelection"
 *   [autoSelectedIcon]="autoSelectedIcon">
 *   <ng-container *ngTemplateOutlet="treeDetail.template; context: {$implicit: node}"></ng-container>
 * </cog-data-tree-node-checkbox>
 */
@Component({
  selector: 'cog-data-tree-node-checkbox',
  templateUrl: './data-tree-node-checkbox.component.html',
  styleUrls: ['./data-tree-node-checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataTreeNodeCheckboxComponent<T extends DataTreeNode<any>> implements OnInit, OnDestroy {
  /**
   * Hide the entire component if the node is not selectable.
   */
  @HostBinding('class.not-selectable') get notSelectable() {
    return !this.nodeContext || !this.node.isSelectable;
  }

  /**
   * The current node being rendered.
   */
  @Input() nodeContext: DataTreeNodeContext<T>;

  /**
   * Icon shape to indicate that a node has been auto selected or excluded. The icon must support the css color property
   * (mat icon, or svg icon) so that it can display an alternate color when a node is excluded.
   */
  @Input() autoSelectedIcon: string;

  /**
   * Icon shape to indicate that a node has been excluded. The icon must support the css color property
   * (mat icon, or svg icon) so that it can display an alternate color when a node is excluded.
   */
  @Input() excludedIcon?: string;

  /**
   * If set to true, skips applying modifier classes like 'primary' and 'warn' when node is selected or excluded
   */
  @Input() noModifierClass = false;

  /**
   * Icon shape to indicate that a node's ancestor has been excluded. This will be shown to indicate that the user
   * cannot select that node directly.
   */
  @Input() ancestorExcludedIcon;

  /**
   * A callback function get the auto select menu label.
   *
   * @param   node               The current node.
   * @param   state              Information about the selection state of the node
   * @return  The autoselect menu label
   */
  @Input() autoSelectObjectLabelFn: (nodeContext: DataTreeNodeContext<T>) => string;

  /**
   * A callback function get the a exclude menu label.
   *
   * @param   node               The current node.
   * @param   state              Information about the selection state of the node
   * @return  The autoselect menu label
   */
  @Input() excludeObjectLabelFn: (nodeContext: DataTreeNodeContext<T>) => string;

  /**
   * A callback function get the a tooltip for the current tooltip. There are several options for the checkbox state.
   *
   * @param   node               The current node.
   * @param   state              Information about the selection state of the node
   * @return  The node's tooltip.
   */
  @Input() checkboxTooltipFn: (nodeContext: DataTreeNodeContext<T>) => string;

  /**
   * Label to show for checkbox menu that will manually select child objects.
   */
  @Input() selectChildrenLabel: string;

  /**
   * If set to true, the checkbox can be used to trigger a menu with options to
   * select child objects, auto select the object, or exclude the object (if it's a tag).
   * This replaces the functionality of data-tree-auto-select-column. To use this, selectChildrenLabel,
   * autoSelectObjectLabelFn, and excludeObjectLabelFn must also be set.
   */
  @Input() useCheckboxMenu = false;

  /**
   * Optional function to handle user-clicks. Will emit the current selection.
   */
  @Output() userSelectionChange = new EventEmitter<DataTreeSelectionChangeEvent<T>>();

  /**
   * Get the auto select menu label
   */
  get autoSelectObjectLabel(): string {
    return this.autoSelectObjectLabelFn ? this.autoSelectObjectLabelFn(this.nodeContext) : undefined;
  }

  /**
   * Get the exclude menu label
   */
  get excludeObjectLabel(): string {
    return this.excludeObjectLabelFn ? this.excludeObjectLabelFn(this.nodeContext) : undefined;
  }

  /**
   * Getter property to check if a node's ancestor is excluded. This value should be cached and only updated
   * when the selection model changes.
   */
  get isAncestorExcluded(): boolean {
    return this.nodeContext.ancestorExcluded;
  }

  /**
   * Returns true if the node cannot be selected.
   */
  get isDisabled(): boolean {
    return !this.nodeContext.canSelect;
  }

  /**
   * Getter property to check if a node is ineliglbe for auto select
   */
  get ineligibleForAutoSelect(): boolean {
    return this.nodeContext.node.ineligibleForAutoSelect;
  }

  /**
   * Gets the current node.
   */
  get node(): T {
    return this.nodeContext.node;
  }

  /**
   * Gets the current selection.
   */
  get selection(): DataTreeSelectionModel<T> {
    return this.nodeContext.selection;
  }

  /**
   * Gets the auto select tooltip to show, as configured by the intl file. The file provides
   * a callback to create an auto protect or auto exclude tooltip based on the current node.
   */
  get tooltip(): string {
    return this.checkboxTooltipFn ? this.checkboxTooltipFn(this.nodeContext) : undefined;
  }

  /**
   * Determines whether to show the auto select icon or the standard checkbox.
   */
  get showAutoSelectIcon(): boolean {
    return (
      this.nodeContext.autoSelected ||
      (this.nodeContext.ancestorAutoSelected && !this.nodeContext.node.ineligibleForAutoSelect) ||
      this.isAncestorExcluded ||
      this.nodeContext.excluded
    );
  }

  /**
   * Determines whether to show a 'special' checkbox, which will show a menu when clicked.
   */
  get showCheckboxMenu(): boolean {
    const {selected, canAutoSelect, node: { isTag } , canExclude, autoSelected } = this.nodeContext;
    return (
      this.useCheckboxMenu &&
      !this.showAutoSelectIcon &&
      !selected &&

      // Auto select can be shown
      (canAutoSelect && !isTag) ||
        // Special case to show exclusion for a tag.
        (canAutoSelect && isTag && canExclude && !autoSelected)
    );
  }

  /**
   * Subscription for tracking when to run detect changes on selection changes
   */
  private detectChangesSub: Subscription;

  constructor(private cdr: ChangeDetectorRef) {

  }

  ngOnInit() {
    this.detectChangesSub = this.selection.changes$.subscribe(() => this.cdr.detectChanges());
  }

  ngOnDestroy() {
    if (this.detectChangesSub?.unsubscribe) {
      this.detectChangesSub.unsubscribe();
      this.detectChangesSub = null;
    }
  }

  /**
   * Toggles node exclusion - if a node is auto selected already, this will unselect it.
   * Otherwise, it acts as a simple toggle for exclusion.
   */
  toggleExclude() {
    if (this.nodeContext.autoSelected) {
      this.selection.toggleNodeAutoSelection(this.node);
      this.handleUserClick('autoSelect');
    } else {
      this.selection.toggleNodeExclude(this.node);
      this.handleUserClick('exclude');
    }
  }

  /**
   * User click handler. Emits the current selection.
   */
  handleUserClick(type: DataTreeSelectionChangeEventType = 'select') {
    if (type === 'select') {
      this.selection.toggleNodeSelection(this.node);
    }

    this.userSelectionChange.emit({
      type: type,
      node: this.node,
      selection: this.selection.currentSelection,
    });
  }

  /**
   * User-initiated auto-select click handler. Emits the current selection.
   */
  handleAutoSelectClick() {
    this.selection.toggleNodeAutoSelection(this.node);

    this.userSelectionChange.emit({
      node: this.node,
      selection: this.selection.currentSelection,
      type: 'autoSelect',
    });
  }

  /**
   * User-initiated exclude click handler. Emits the current selection.
   */
  handleExclusionClick() {
    this.selection.toggleNodeExclude(this.node);

    this.userSelectionChange.emit({ node: this.node, selection: this.selection.currentSelection, type: 'exclude' });
  }
}
