// COMPONENT: Clone View parent controller
import { recoveryGroup } from 'src/app/shared/constants';

;(function(angular, undefined) {
  'use strict';

  var moduleName = 'C.cloneView';
  var moduleDeps = ['C.retrievalOptions'];
  var flowTypes = ['clone'];

  // These vars are overwritten by states in the parent controller below
  var flowType = flowTypes[0];
  var isClone = true;
  var isRecover = false;

  angular
    .module(moduleName, moduleDeps)
    .config(cloneViewConfigFn)
    .controller('cloneViewParentController', cloneViewParentControllerFn);

  function cloneViewConfigFn($stateProvider) {
    var modifyAccess = ctx => {
      return ctx.CLONE_MODIFY && ctx.canAccessSomeEnv(recoveryGroup.view, 'clone');
    };

    $stateProvider
      .state('clone-view', {
        url: '^/more/devops/clone/view',

        // CSH keys are set dynamically at each step based on the flowType,
        title: 'Clone View',
        data: {
          flowType: flowType,
        },
        canAccess: 'CLONE_VIEW',
        parentState: 'devops',
        views: {
          '': {
            templateUrl: 'app/views/page-layouts/ls.html',
          },
          'col-l@clone-view': {
            controller: 'cloneViewParentController',
            templateUrl: 'app/devops/clone/views/view.parent.html',
          },
        },
        redirectTo: 'clone-view.search'
      })
      .state('clone-view.search', {
        url: '^/more/devops/clone/view/search',

        // CSH keys are set dynamically at each step based on the flowType,
        title: 'Clone View: Search',
        help: 'testdev_clone_view_search',
        canAccess: modifyAccess,
        parentState: 'clone-view',
        views: {
          'canvas@clone-view': {
            controller: 'CloneViewSearchCtrl',
            templateUrl: 'app/devops/clone/views/view.search.html',
          },
        },
      })
      .state('clone-view.options', {
        /**
         * Params required for different scenarios
         * Unprotected View: view
         * Inactive/DR View: view, snapshotUsecs, jobId, inactive=true, protected=true
         * Protected View (current only): view, protected=true
         * Protected View (spec snapshot): view, protected=true, snapshotUsecs, jobId
         */
        url: '^/more/devops/clone/view/options',

        // CSH keys are set dynamically at each step based on the flowType,
        title: 'Clone View: Options',
        help: 'testdev_clone_view_options',
        canAccess: modifyAccess,
        parentState: 'clone-view',
        params: {
          view: null,

          // When true, default to Current View instead of latest snapshot
          cloneCurrentView: null,

          // Optional param to specify an archivalTarget
          archiveTarget: null,
          snapshotUsecs: null,

          // TODO(spencer): Add support for jobInstanceId instead of snapshotUsecs
          jobId: null,
          protected: false,
          inactive: false,
        },
        views: {
          'canvas@clone-view': {
            controller: 'cloneViewOptionsController',
            templateUrl: 'app/devops/clone/views/view.options.html',
          },
        },
      });
  }

  /**
   * cloneViewParent Controller
   **************************************************************************/
  function cloneViewParentControllerFn(
    $scope, $q, $translate, SlideModalService, ExternalTargetService,
    ViewService, ViewServiceFormatter, ClusterService, SourceService,
    ViewBoxService, JobService, SearchService, RestoreService, cMessage,
    $interpolate, $state, evalAJAX, featureFlagsService,
    StateManagementService) {

    /**
     * The default CloneArg object. Required properties are uncommented.
     * @type       {CloneArg}
     */
    $scope.defaultRestoreTask = {
      name: '',
      objects: [],
      viewName: '',
      action: 5,
      /*
       * This is where the view params go from the old flow
       * @type {CloneViewRequest}
       */
      viewParams: {
        sourceViewName: '',
        cloneViewName: '',
      },
    };

    // Update these in the broader flow scope for use in each step
    flowType = $state.$current.data.flowType || flowType;
    isClone = 'clone' === flowType;
    isRecover = !isClone;

    angular.extend($scope, {
      // GENERAL SCOPE VARS
      flowType: flowType,
      isClone: isClone,
      isRecover: isRecover,
      canSelectSnapshot: true,
      clusters: {},

      // Read-write sharable object for sub views
      shared: {
        dirtyFields: {},
        filterLookups: {},
        endpoint: SearchService.getSearchUrl('view'),
        results: [],
        pagedResults: [],
        filters: [],
        searchId: [flowType, 'view'].join('-'),
        dataLockView: false,
        dataLockViewOptions: [{
          nameKey: 'cloneView',
          dataLockViewValue: false,
        }, {
          nameKey: 'cloneAsDataLockView',
          dataLockViewValue: true,
        },],

        // tomorrow is the earliest dataLock allowed
        dataLockViewMinimum: moment().add(1, 'd'),
      },
      form: {},

      // SCOPE METHODS
      submitTask: submitTask,
      addToCart: addToCart,
      removeFromCart: removeFromCart,
      isRemoteObject: isRemoteObject,
      isProtectedView: isProtectedView,
      isInCart: isInCart,
      isForbidden: isForbidden,
      openSnapshotSelector: openSnapshotSelector,
      generateDefaultTaskName: generateDefaultTaskName,
      setupAbbreviatedFlow: setupAbbreviatedFlow,
      updateCloneViewName: updateCloneViewName,
      getViewDetails: getViewDetails,
      cancelFlow: cancelFlow,
      redirectAlert: redirectAlert,
      getTargetName: ExternalTargetService.getTargetName,
      preProcessSearchResults: SearchService.transformViewResults,
      validateViewName: validateViewName,
    });

    // WATCHERS
    // Watch the cart for changes and to the things
    $scope.$watch('shared.cart', function cartWatcherFn(cart, oldCart) {
      if (cart.length) {
        // If the entity selected is deleted or inactive and no snapshot is
        // already selected, force pre-selection to the first available
        // snapshot. There's no notion of "current view" for these Views.
        if ((cart[0]._isDeleted || cart[0]._isInactive) && !cart[0]._snapshot) {
          cart[0]._snapshot = cart[0].vmDocument.versions[0];
        }

        // Update the task accordingly
        updateTaskWithEntity(cart[0]);
      }
    }, true);

    // METHODS
    /**
     * Activate this controller.
     *
     * @method     activate
     */
    function activate() {
      fetchDependencies();
      setupSteps();
      initTask();
    }

    /**
     * Cancel out of this flow and remove this current view from the browser
     * history.
     *
     * @method     cancelFlow
     */
    function cancelFlow() {
      var baseState = 'devops.clone';

      StateManagementService.goToPreviousState(baseState);
    }

    /**
     * Gets a View's details.
     *
     * @method     getViewDetails
     * @param      {string}   viewName  The view name
     * @return     {promise}  Promise to resolve with the requested View, or
     *                        if in error then the raw server response.
     */

    // NOTE: This won't work if we implement multiple view restore later on.
    function getViewDetails(viewId) {
      return ViewService.getViewById(viewId, { includeInactive: true }).then(
        function getViewSuccessFn(view) {
          return $scope.shared.sourceView = view;
        },
        function noViewFound() {
          // We want this to fail silently and pass on an empty View object
          // because the Clone operation does not require a successful GET view
          // response. This will always happen when cloning a snapshot of a
          // deleted View.
          return $scope.shared.sourceView = {};
        },
      );
    }

    /**
     * Raises warnings for a View name with certain characters and protocols.
     */
    function validateViewName() {
      $scope.shared.nameWarningKeys = ViewService.getViewNameWarnings(
        $scope.shared.task.viewName,
        $scope.shared.task.viewParams.protocolAccess
      );
    }

    /**
     * Updates both instances of the new view name in the task
     *
     * @method     updateCloneViewName
     * @param      {string}  cloneViewName  The clone view name
     * @return     {object}  The updated restore task
     */
    function updateCloneViewName(cloneViewName) {
      cloneViewName = (cloneViewName || '').trim();
      $scope.validateViewName();

      return angular.merge($scope.shared.task, {
        viewName: cloneViewName,
        viewParams: {
          cloneViewName: cloneViewName,
        },
      });
    }

    /**
     * Sets up the abbreviated clone flow. This drops (the search step and
     * fetches a few more bits of info).
     *
     * This abbreviated flow accommodates Failover workflows and cloning
     * from the Views listing & detail pages.
     *
     * @method     setupAbbreviatedFlow
     * @param      {object}  params  $stateParams object
     */
    function setupAbbreviatedFlow(params) {
      var queryParams = {};
      var promises = {};
      var view;

      // Do we have enough info to continue?
      if (!params.view) {
        // No? Lets bail on this method
        return;
      }

      // Remove the search step
      $scope.stateNames.splice(1, 1);

      $scope.isAbbreviatedFlow = true;
      $scope.canSelectSnapshot = !params.inactive;
      $scope.isLoading = true;

      /**
       * Process the params and determine which service to fetch data from
       * because only protected Views will be found by the search service
       * which includes snapshots.
       *
       * NOTE: IF YOU MAKE CHANGES, TEST ALL CLONE WORKFLOWS!
       *
       *   1. Clone unprotected View from Views landing page
       *   2. Clone protected View from Views landing page
       *   3. Clone Remote View from Views landing page
       *
       *   4. Clone snapshot of a protected View from the View Details > Protection page
       *   5. Clone snapshot of a Remote View from the View details > Protection page
       *
       *   6. Clone snapshot of a protected View from Protection Run page
       *   7. Clone snapshot of a protected View from Test & Dev page
       */
      if (params.view && params.protected && params.jobId) {
        // This View is protected locally or is a Remote View replicated from another cluster.
        queryParams = {
          vmName: (params.view.name || params.view.displayName).trim(),
          jobIds: params.jobId,
        };
        promises.viewSearch = SearchService.viewSearch(queryParams);
      } else if (params.view) {
        // This View is not protected and thus has no snapshots
        promises.getView = ViewService.getView((params.view.name).trim(), {
          includeInactive: params.inactive,
        });
      }

      $q.all(promises).then(function promiseSuccessFn(responses) {
        var workingEntity = {};

        // This is the VM search response. This is a protected View.
        if (Array.isArray(responses.viewSearch)) {
          if (responses.viewSearch.length) {
            workingEntity = responses.viewSearch.length === 1 ?

              // If there's one result, just use it
              responses.viewSearch[0] :

              // oehterwise look for it in all the results.
              responses.viewSearch.filter(function findEntityFn(view) {
                return params.view.viewProtection &&
                  (params.view.viewProtection.magnetoEntityId ===
                  view.vmDocument.objectId.entity.id);
              });

            // NOTE: The following three lines is a 1st phase fix for ENG-35050.
            // 2nd phase fix will be to enhance the snapshot modal to handle an
            // entity with snapshots from multiple jobs.
            if (Array.isArray(workingEntity)) {
              workingEntity = workingEntity[0];
            }
          } else {
            workingEntity = ViewServiceFormatter.transformViewToEntity(params.view);
          }

          // If this is an inactive View (DR), default to the latest version
          if (params.inactive) {
            workingEntity._isInactive = true;
          }

          // If the Clone action was initiated from a View page (rather than a
          // protection job page), this param will be true and we want to
          // default to Current View instead of the latest snapshot.
          if (params.cloneCurrentView) {
            workingEntity._snapshot = undefined;
            workingEntity._snapshotIndex = undefined;
          }

          // Add the result to the cart
          addToCart(workingEntity);

          // Update the selected snapshot if we've specified one
          if (params.snapshotUsecs) {
            updateTaskSnapshot(params.snapshotUsecs);
          }

          if (params.archiveTarget) {
            updateTaskArchiveTarget(params.archiveTarget);
          }

          // If this View is inactive, or has multiple jobs protecting it,
          // prevent snapshot selection.
          $scope.canSelectSnapshot = !(responses.viewSearch.length > 1 || params.inactive);
        }

        // This is the view lookup response. This is an unprotected View.
        if (responses.getView) {
          workingEntity = ViewServiceFormatter.transformViewToEntity(responses.getView);

          if (params.inactive) {
            workingEntity._isInactive = true;
          }

          addToCart(workingEntity);
        }

        return responses;
      }, evalAJAX.errorMessage)
      .finally(function getViewFinallyFn() {
        $scope.isLoading = false;
      });
    }

    /**
     * Opens a snapshot selector.
     *
     * @method     openSnapshotSelector
     * @param      {object}  entity  The entity to chose a snapshot from.
     */
    function openSnapshotSelector(entity) {
      var modalOpts = {
        templateUrl: 'app/devops/clone/views/view.snapshot-selector-modal.html',
        controller: 'viewSnapshotModalController',
        size: 'lg',
        resolve: {
          task: angular.copy($scope.shared.task),
          entity: entity,
        },
      };

      SlideModalService

        // Open the modal
        .newModal(modalOpts)

        // Receive response from modal
        .then(function snapshotSelectedFn(resp) {
          entity._snapshot = resp.snapshot;
          entity._archiveTarget = resp.archiveTarget;
          updateTaskWithEntity(entity);
        });
    }

    /**
     * Convenience function to check if a field is dirty.
     *
     * Dirty in this case means: the form object is initialized, the field
     * is not empty, and the field is $dirty.
     *
     * @method     isFieldDirty
     * @param      {string}   fieldName  The field name
     * @return     {boolean}  True if field dirty, False otherwise.
     */
    function isFieldDirty(fieldName) {
      return !!($scope.form.restoreViewTask &&
          $scope.form.restoreViewTask[fieldName] &&
          $scope.form.restoreViewTask[fieldName].$modelValue &&
          $scope.form.restoreViewTask[fieldName].$dirty);
    }

    /**
     * Update the task with anything from the given entity.
     *
     * @method     updateTaskWithEntity
     * @param      {object}  entity  The entity
     * @return     {object}  The restore task
     */
    function updateTaskWithEntity(entity) {
      // These updates occur even if the details lookup fails
      angular.merge($scope.shared.task, {
        viewParams: {
          sourceViewName: entity.vmDocument.objectId.entity.viewEntity.name,
          viewBoxId: entity.vmDocument.viewBoxId,
          viewId: entity.vmDocument.objectId.entity.id,
        },
      });
      $scope.shared.task.objects = updateTaskObjects();

      if (!isFieldDirty('viewName')) {
        updateCloneViewName(generateDefaultViewName(entity));
      }

      // Update the task with info from the View Details
      getViewDetails(entity.vmDocument.objectId.entity.viewEntity.uid.objectId)
        .then(function viewDetailsSuccessFn(view) {
          entity._isInactive = view._isInactive;

          // These updates are a convenience to duplicate the original view
          angular.merge($scope.shared.task, {
            viewParams: {
              qos: (view.qos &&
                !isFieldDirty('qosPolicy')) ? view.qos :
                $scope.shared.task.viewParams.qos,
              description: (view.description &&
                !isFieldDirty('viewDescription')) ? view.description :
                $scope.shared.task.viewParams.description,
              protocolAccess: (view.protocolAccess &&
                !isFieldDirty('protocolAccess')) ?  view.protocolAccess :
                $scope.shared.task.viewParams.protocolAccess,

              // No UI for these params, but to "clone", the new view should be
              // identical when unchanged by the user
              allowMountOnWindows: view.allowMountOnWindows,
              storagePolicyOverride: view.storagePolicyOverride,
            },

            // This is a reference to the source View protocol access value.
            _sourceProtocol: view.protocolAccess ||
              $scope.shared.task.viewParams.protocolAccess,
          });

          return view;
        }, evalAJAX.errorMessage);

      return $scope.shared.task;
    }

    /**
     * Generates a task-compatible Objects list from the cart based on the
     * selected snapshots & targets.
     *
     * @method     updateTaskObjects
     * @return     {array}  Task-compatible list of objects.
     */
    function updateTaskObjects() {
      return $scope.shared.cart.map(function mapCartFn(item, ii) {
        return {
          jobUid: (item._snapshot) ?
            angular.copy(item.vmDocument.objectId.jobUid) :
            undefined,
          jobId: (item._snapshot) ?
            item.vmDocument.objectId.jobId :
            undefined,
          jobInstanceId: (item._snapshot) ?
            item._snapshot.instanceId.jobInstanceId :
            undefined,
          startTimeUsecs: (item._snapshot) ?
            item._snapshot.instanceId.jobStartTimeUsecs :
            undefined,
          entity: angular.copy(item.vmDocument.objectId.entity),
          archivalTarget: (item._archiveTarget) ?
            item._archiveTarget.target.archivalTarget :
            undefined,
        };
      });
    }

    /**
     * Generate a default viewName based on the given entity.
     *
     * @method     generateDefaultViewName
     * @param      {object}  entity  The entity
     * @return     {string}  The default viewName string.
     */
    function generateDefaultViewName(entity) {
      return [
        entity.vmDocument.objectId.entity.viewEntity.name,
        $translate.instant('copy'),
      ].join('-');
    }

    /**
     * Removes the given entity from the cart.
     *
     * Currently just empties the cart. Will do more when handling
     * multi-item flow.
     *
     * @method     removeFromCart
     * @param      {object}  entity  The entity to remove.
     * @return     {array}  The cart without the removed entity.
     */
    function removeFromCart(entity) {
      $scope.shared.cart.length = 0;

      return $scope.shared.cart;
    }

    /**
     * Adds the given entity to the task/cart.
     *
     * @method     addToCart
     * @param      {object}  entity  The entity to add.
     * @return     {array}  The cart
     */
    function addToCart(entity) {
      // TODO: Update this to handle overlap & dupe detection when
      // implementing multi-item restore.
      if (!isForbidden(entity)) {
        $scope.shared.cart[0] = entity;

        if (!$scope.isAbbreviatedFlow) {
          $state.go($scope.stateNames[2]);
        }
      }

      return $scope.shared.cart;
    }

    /**
     * Determines if the given entity is a remote object (replication job).
     *
     * @method     isRemoteObject
     * @param      {object}   entity  The entity to check.
     * @return     {boolean}  True if remote object, False otherwise.
     */
    function isRemoteObject(entity) {
      return !!entity._isInactive;
    }

    /**
     * Checks if the given entity is already in the cart or not.
     *
     * @method     isInCart
     * @param      {object}   entity  The entity to check.
     * @return     {boolean}  True if it's already in the cart. False if
     *                        not.
     */
    function isInCart(entity) {
      if (!entity || !$scope.shared.cart.length) {
        return false;
      }

      return SourceService
        .isSameEntity(entity.vmDocument.objectId.entity,
               $scope.shared.cart[0].vmDocument.objectId.entity);
    }

    /**
     * Convenience function to check multiple criteria to determine if a
     * given entity can be added to the cart/task.
     *
     * Currently analogous with isInCart.
     *
     * @method     isForbidden
     * @param      {entity}   entity  The entity to check
     * @return     {boolean}  True if forbidden, False otherwise.
     */
    function isForbidden(entity) {
      return isInCart(entity);
    }

    /**
     * Determines if a given view is protected or not.
     *
     * @method     isProtectedView
     * @param      {object}   entity  The View entity
     * @return     {boolean}  True if protected view, False otherwise.
     */
    function isProtectedView(entity) {
      // TODO: Wire this up if we're ever able to index unprotected Views
      // in ElasticSearch (>= 3.0). For now, only protected Views are
      // indexed and searchable in this flow.
      return true;
    }

    /**
     * Initialize the shared.task & cart
     *
     * @method     initTask
     * @return     {object}  The bootstrapped task
     */
    function initTask() {
      $scope.shared.task = angular.extend($scope.defaultRestoreTask, {
        name: generateDefaultTaskName(),
      });
      $scope.shared.cart = [];

      return $scope.shared.task;
    }

    /**
     * Generate the default taskName
     *
     * @method     generateDefaultTaskName
     * @return     {string}  The default task name
     */
    function generateDefaultTaskName() {
      return RestoreService.getDefaultTaskName(flowType, 'View');
    }

    /**
     * Updates the local cluster info hash
     *
     * @method     updateClusterHash
     * @param      {array|object}  clusters  One or a list of cluster info
     *                                       objects
     * @return     {array}        The updated list of clusters
     */
    function updateClusterHash(clusters) {
      if (!clusters) {
        return $scope.clusters;
      }

      [].concat(clusters)
        .forEach(function eachClusterUpdaterFn(cluster) {
          $scope.clusters[cluster.id] = cluster;
        });

      return $scope.clusters;
    }

    /**
     * Fetch filter lookup data, etc.
     *
     * @method     fetchDependencies
     * @return     {object}  Promise responses from various services
     */
    function fetchDependencies() {
      var promises = [

        // Get all ViewBox references
        ViewBoxService.getOwnViewBoxes(),

        // Get all Jobs references
        JobService.getJobs({ envTypes: [4] }),

        // Get all Sources references
        SourceService.getSources({ onlyReturnOneLevel: true, envTypes: [1] }),

        // Get known VMs
        SourceService.getVirtualMachines(),

        // Fetch QoS Policies
        ViewService.getQosPrincipals(),
      ];

      return $q.all(promises)
        .then(function depsFetchedFn(resps) {
          $scope.shared.filterLookups.viewBoxIds = resps[0];
          $scope.shared.filterLookups.jobIds = resps[1].map(function augmentJobsFn(job) {
              return angular.merge(job, {
                jobName: job.name,
              });
            });

          $scope.shared.filterLookups.registeredSourceIds = SourceService.flatSources;
          $scope.shared.filterLookups.entityIds = resps[3];
          $scope.shared.filterLookups.qosPolicies = resps[4];
          $scope.shared.filterLookups.clusters = updateClusterHash(ClusterService.clusterInfo);
        }, evalAJAX.errorMessage)
        .finally();
    }

    /**
     * Submit the task to Magneto.
     *
     * @method     submitTask
     */
    function submitTask() {
      var serviceMethod = (isClone) ? 'cloneView' : 'recoverView';

      $scope.isSubmitting = true;

      // if DataLock view is not selected, ensure no dataLockExpiryUsecs value is
      // provided to the backend
      if (!$scope.shared.dataLockView) {
        $scope.shared.task.viewParams.dataLockExpiryUsecs = undefined;
      }

      if ($scope.shared.retrievalOption) {
        _.set($scope.shared.task, 'vaultRestoreParams.glacier.retrievalType',
          $scope.shared.retrievalOption);
      }

      RestoreService[serviceMethod]($scope.shared.task).then(
        function taskAcceptedFn(restoreTask) {
          var stateName = [$scope.flowType, 'detail'].join('-');
          var stateParams = {
            id: restoreTask.performRestoreTaskState.base.taskId,
          };
          $state.go(stateName, stateParams);
        },
        evalAJAX.errorMessage
      ).finally(
        function taskFinallyFn() {
          $scope.isSubmitting = false;
        }
      );
    }

    /**
     * Destroy the task to prevent resubmission.
     *
     * @method     emptyCart
     */
    function emptyCart() {
      $scope.shared.cart.length = 0;
    }

    /**
     * Sets up the flow steps for cStepper
     *
     * @method     setupSteps
     */
    function setupSteps() {
      $scope.stateNames = [
        isClone ? 'clone-view' : 'recover',
        $interpolate('{{flowType}}-view.search')($scope),
        $interpolate('{{flowType}}-view.options')($scope),
      ];
    }

    /**
     * Show an informational cMessage when redirecting the user
     * automatically to another view
     *
     * @method     redirectAlert
     * @param      {string=}  messageKey  The ui.json key
     */
    function redirectAlert(messageKey) {
      cMessage.info({
        titleKey: 'clone.view.redirect',
        textKey: messageKey,

        // Note: passing $scope as context didn't work for some reason
        textKeyContext: {
          flowType: $scope.flowType,
        },
        persist: true,
        timeout: 5000,
      });
    }

    /**
     * Update the task object with a predefined snapshot by stateParams.
     * Used in abbreviated flow.
     *
     * @method     updateTaskSnapshot
     * @param      {integer}  usecs   Microsecond snapshot start time
     * @return     {boolean}  True if successful, False otherwise.
     */
    function updateTaskSnapshot(usecs) {
      return $scope.shared.cart[0].vmDocument.versions
        .some(function restorePointFinderFn(restorePoint) {
          if (!usecs || +usecs === restorePoint.instanceId.jobStartTimeUsecs) {
            $scope.shared.cart[0]._snapshot = restorePoint;

            return true;
          }
        });
    }

    /**
     * Set the selected archiveTarget.
     *
     * @method     updateTaskArchiveTarget
     * @param      {object}   target  The archive target
     * @return     {boolean}  True if successful, False otherwise.
     */
    function updateTaskArchiveTarget(target) {
      return !!($scope.shared.cart[0]._archiveTarget = target);
    }

    // Activate!
    activate();
  }

})(angular);
