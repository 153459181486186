<ng-container *ngIf="config.template">
  <ng-container *ngTemplateOutlet="config.template; context: { $implicit: row }"></ng-container>
</ng-container>

<div class="list-item-status-container" *ngIf="!config.template">
  <cog-icon *ngIf="icon" [shape]="icon" class="margin-right-sm"></cog-icon>
  <ng-container *ngIf="config.iconTemplate">
    <ng-container *ngTemplateOutlet="config.iconTemplate; context: { $implicit: row }"></ng-container>
  </ng-container>
  <div class="list-item-text-container" [class.overflow-container]="config.handleOverflow">
    <a *ngIf="config.state" [uiSref]="config.state" [uiParams]="params">
      <coh-list-item-column-content [columnValue]="columnValue" [config]="config"></coh-list-item-column-content>
    </a>
    <coh-list-item-column-content
      *ngIf="!config.state"
      [columnValue]="columnValue"
      [config]="config"
      [matTooltip]="config.handleOverflow ? columnValue : null"
      matTooltipPosition="below">
    </coh-list-item-column-content>
    <div class="list-item-description mat-caption margin-top-xs" *ngIf="config.description">
      {{ row[config.description] }}
    </div>
  </div>
  <span
    *ngIf="config.popover"
    class="icn-view margin-left-sm"
    [cogPopover]="config.popover"
    [popoverData]="popoverData"
    (click)="$event.stopPropagation()">
  </span>
</div>
