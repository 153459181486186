import { isOneHeliosAppliance } from '@cohesity/iris-core';
import {
  AppModuleConfig,
  StateAccessContext,
  StateAccessMap
} from 'src/app/app-module.config';

const viewAccess = (ctx: StateAccessContext) =>
  ctx.PRINCIPAL_VIEW && (ctx.FEATURE_FLAGS.ngUsersAndGroups || isOneHeliosAppliance(ctx.irisContext));

const modifyAccess = (ctx: StateAccessContext) =>
  ctx.PRINCIPAL_MODIFY && (ctx.FEATURE_FLAGS.ngUsersAndGroups || isOneHeliosAppliance(ctx.irisContext));

export const UsersGroupsConfig: AppModuleConfig = {
  futureStates: [
  ],
  allClusterMap: {
    'on-prem-users-edit': {
      heliosOnly: false,
      globalContext: false,
      singleClusterState: 'on-prem-users-edit',
      allClustersState: 'dashboards',
    },
    'on-prem-users-view': {
      heliosOnly: false,
      globalContext: false,
      singleClusterState: 'on-prem-users-view',
      allClustersState: 'dashboards',
    },
  },
  getStateAccessMap(): StateAccessMap {
    return {
      'on-prem-users-edit': modifyAccess,
      'on-prem-users-view': viewAccess,
    };
  },
};
