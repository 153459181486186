import { Pipe, PipeTransform } from '@angular/core';

/**
 * Principal Icon Types.
 */
type PrincipalIconTypes = 'kUser' | 'kGroup' | 'kWellKnownPrincipal' | 'kComputer'
  | 'User' | 'Group' | 'WellKnownPrincipal' | 'Computer';

/**
 * Returns an icon class string that matches the provided principal. This can be
 * any of the principal types: 'kUser', 'kGroup', 'kWellKnownPrincipal' or 'kComputer'
 */
@Pipe({
  name: 'principalIcon',
  pure: true,
})

export class PrincipalIconPipe implements PipeTransform {
/**
 * @param   PrincipalIcon   The principal icon value
 * @returns The correct principal icon.
 */
  transform(PrincipalIcon: PrincipalIconTypes): string {
    switch (PrincipalIcon) {
      case 'kUser':
      case 'User':
        return 'person_outline';

      case 'kGroup':
      case 'Group':
      case 'kWellKnownPrincipal':
      case 'WellKnownPrincipal':
        return 'helix:object-user-group';

      case 'kComputer':
      case 'Computer':
        return 'helix:object-server';

      default:
        return '';
    }
  }

}
