<div class="dl-row">
  <dt translate="environment"></dt>
  <dd>{{ sfdcParams?.endpointType }}</dd>
</div>
<div class="dl-row">
  <dt translate="apiRequestLimit"></dt>
  <dd>{{ sfdcParams?.dailyApiLimit }}</dd>
</div>
<div class="dl-row">
  <dt translate="apiConcurrentLimit"></dt>
  <dd>{{ sfdcParams?.concurrentApiRequestsLimit }}</dd>
</div>
<div class="dl-row">
  <dt translate="users"></dt>
  <dd>{{('sourceTree.metadata.sfdcUserLicenses' | translate: {count: usedSalesforceLicenses | number})}}</dd>
</div>
<div class="dl-row">
  <dt translate="appId"></dt>
  <dd>{{ sfdcParams?.consumerKey }}</dd>
</div>
<div class="dl-row">
  <dt translate="soapEndpointUrl"></dt>
  <dd>{{ sfdcParams?.soapEndpointUrl }}</dd>
</div>
<div class="dl-row">
  <dt translate="metadataEndpointUrl"></dt>
  <dd>{{ sfdcParams?.metadataEndpointUrl }}</dd>
</div>
