import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconModule } from '@cohesity/helix';

import { PostureAdvisorIconComponent } from './posture-advisor-icon.component';


@NgModule({
  declarations: [
    PostureAdvisorIconComponent,
  ],
  exports: [
    PostureAdvisorIconComponent,
  ],
  imports: [
    CommonModule,
    IconModule,
  ]
})
export class PostureAdvisorIconModule { }
