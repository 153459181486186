<div class="status-icon-container" *ngIf="icons">
  <span class="copy-status-icon" *ngFor="let icon of displayIcons">
    <span class="icn-md status-{{icon.taskType}}-{{icon.taskStatus}}"
      matTooltip="{{icon.tooltip | translate}}">
    </span>
  </span>
  <span class="more-icon-toggle-container"
    (mouseover)="showIconPanel = !showIconPanel"
    (mouseout)="showIconPanel = !showIconPanel">
    <span class="more-icon-toggle"
      *ngIf="showMoreIcon">
       + {{hiddenCount}}
    </span>
    <div class="more-icon-panel group-container"
      [ngClass]="{'active': showIconPanel}">
      <span class="copy-status-icon" *ngFor="let icon of hiddenIcons">
        <span class="icn-md status-{{icon.taskType}}-{{icon.taskStatus}}"
          matTooltip="{{icon.tooltip | translate}}">
        </span>
      </span>
    </div>
  </span>
</div>
<span class="icn-md" *ngIf="!icons">
  {{'naNotApplicable' | translate}}
</span>
