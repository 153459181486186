import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';
import { ExcludeOptions, ExcludeResults } from '@cohesity/iris-source-tree';
import { AutoDestroyable } from '@cohesity/utils';
import { BehaviorSubject } from 'rxjs';
import { DialogService } from 'src/app/core/services';

import { SqlExcludeDialogComponent } from './sql-exclude-dialog/sql-exclude-dialog.component';

/**
 * Renders exclusion filter controls for a SQL sources in a source tree.
 *
 * @example
 * <coh-sql-exclude
 *  [excludeOptions]="excludeOptions"
 *  [excludeResults$]="excludeResults$">
 * </coh-sql-exclude>
 */
@Component({
  selector: 'coh-sql-exclude',
  templateUrl: './sql-exclude.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class SqlExcludeComponent extends AutoDestroyable {

  constructor(
    private dialogService: DialogService,
    private irisContextService: IrisContextService,
  ) {
    super();
  }

  /**
   * Exclude options to be passed to exclude filters dialog
   */
  @Input() excludeOptions: ExcludeOptions;

  /**
   * Emit exclude results event when user closes the exclude filters dialog
   */
  @Input() excludeResults$: BehaviorSubject<ExcludeResults>;

  /**
   * Opens exclude filters dialog when user clicks on the exclude button
   */
  openExcludeDialog() {
    const dialogData = { excludeOptions: this.excludeOptions };

    this.dialogService
      .showDialog(SqlExcludeDialogComponent, dialogData)
      .pipe(this.untilDestroy())
      .subscribe((excludeResults: ExcludeResults) => {
        if (excludeResults) {
          this.excludeResults$.next(excludeResults);
        }
      });
  }

  /**
   * Checks whether sql exclude feature is enabled or not
   *
   * @returns  True if feature is enabled. False otherwise.
   */
  get featureEnabled(): boolean {
    return flagEnabled(this.irisContextService.irisContext, 'sqlExcludeFilters');
  }
}
