import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { PillarItem } from '../../app-pillars.model';

/**
 * App paywall message component.
 */
@Component({
  selector: 'coh-app-paywall-message',
  templateUrl: './app-paywall-message.component.html',
  styleUrls: ['./app-paywall-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppPaywallMessageComponent {
  /** App data. */
  @Input() app: PillarItem;

  /**
   * Goto upgrade page
   */
  visit() {
    window.open(this.app.upgradeUrl, '_blank');
  }
}
