import { DOCUMENT } from '@angular/common';
import {
  ContentChildren,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  Output,
  QueryList,
} from '@angular/core';

import { FloatingFormMenuDirective } from '../floating-form-menu/floating-form-menu.directive';
import { FormGroupDirective } from '../form-group/form-group.directive';

/**
 * Directive to set focused highlight style on a form group on mouse click
 * and Tab keyup events. This is used together with cogFormGroup directive
 * and cogFloatingFormMenu directive if there is a floating menu on the form.
 *
 * @example
 *   <form [formGroup]="form"
 *    cogFormFocusedHighlight
 *    [(activeFormGroupId)]="activeFormGroupId"
 *    [useNativeDomQuery]="false">
 *    <div cogFormGroup section="section1">FormGroup1</div>
 *    <mat-card cogFormGroup hasAttachedMenu="true" section="section2">FormGroup2</mat-card>
 *    <mat-card cogFloatingFormMenu section="section2">Floating Menu</mat-card>
 *   </form>
 */
@Directive({
  selector: '[cogFormFocusedHighlight]'
})
export class FormFocusedHighlightDirective {

  /**
   * Active formGroup id.
   */
  private _activeFormGroupId: string;

  /**
   * Input for activeFormGroupId.
   * This allows host component programatically update the active form group.
   */
  @Input() set activeFormGroupId(id: string) {
    this._activeFormGroupId = id;
    this.activeFormGroupChanged(
      (this.formGroupsQueryList || []).find(formGroup => formGroup.id === id)
    );
  }

  /**
   * Getter for active formGroup id.
   */
  get activeFormGroupId(): string {
    return this._activeFormGroupId;
  }

  /**
   * Specifies whether to use native element reference for highlighting.
   * Use this approach when you have nested <ng-content> or nested child components inside a form.
   * Note this native element approach does not support floating menu
   * and programmatically switching active form group from parent host.
   *
   * ContentChildren currently does not retrieve elements or directives that are in
   * other components' templates. For more info:
   * https://github.com/angular/angular/issues/20810
   * https://github.com/angular/angular/issues/8563
   *
   */
  @Input() useNativeDomQuery = false;

  /**
   * EventEmitter to notify host component when active formGroup id changes.
   */
  @Output() activeFormGroupIdChange = new EventEmitter<string>();

  /**
   * QueryList of all form group directive inside host.
   */
  @ContentChildren(FormGroupDirective, {descendants: true})
  formGroupsQueryList: QueryList<FormGroupDirective>;

  /**
   * QueryList of all floating form menu directives.
   */
  @ContentChildren(FloatingFormMenuDirective, {descendants: true})
  floatingMenuQueryList: QueryList<FloatingFormMenuDirective>;

  /**
   * Document reference.
   */
  private document: Document;

  constructor(
    @Inject(DOCUMENT) document: any,
    private elementRef: ElementRef,
  ) {
    // Typing document as any and then assigning as Document type to
    // avoid Angular complaining about being unable to resolve type Document.
    // Ref: https://github.com/angular/angular/issues/20351#issuecomment-446025223
    this.document = document as Document;
  }

  /**
   * On click, gets the mouse event target and sets active form group
   * using event target element.
   *
   * @param   event  MouseEvent.
   */
  @HostListener('click', ['$event']) onClick(event: MouseEvent) {
    const targetElement = event.target as HTMLElement;

    // Only set active form group if the floating menus were not clicked on.
    const isFloatingMenuClicked =
      this.floatingMenuQueryList.find(menu => menu.containsElement(targetElement));
    if (!isFloatingMenuClicked) {
      this.setActiveFormGroup(targetElement);
    }
  }

  /**
   * On Keyup, checks if a tab key was pressed then sets active form group
   * using the current active element.
   *
   * @param  event  KeyboardEvent.
   */
  @HostListener('document:keyup', ['$event']) onKeyup(event: KeyboardEvent) {
    // Retrieve current active element
    // and set the highlight active class on the containing form-group.
    if (event.key === 'Tab') {
      const activeEl = this.document.activeElement;
      this.setActiveFormGroup(activeEl);
    }
  }

  /**
   * Sets active form group inside a form.
   *
   * @param   targetEl   Click event target or current active element.
   */
  setActiveFormGroup(targetEl: HTMLElement | Element) {
    if (!targetEl) {
      return;
    }

    // Use native element reference to highlight form group.
    if (this.useNativeDomQuery) {
      const formGroupEls: HTMLElement[] =
        Array.from(this.elementRef.nativeElement.querySelectorAll('[cogFormGroup]'));

      for (const formGroupEl of formGroupEls) {
        const childFormGroupEls: HTMLElement[] =
          Array.from(formGroupEl.querySelectorAll('[cogFormGroup]'));
        const childIsActive = childFormGroupEls.some(node => node.contains(targetEl));

        // this cogFormGroup should be consider active if it has no child cogFormGroups
        // that are active (if they are active they will take precedents)  and contains
        // the target element of the focus/click event.
        const isActive = !childIsActive && formGroupEl.contains(targetEl);

        formGroupEl.classList.toggle('active', isActive);
      }

    // Use Angular ContentChildren QueryList to highlight form group.
    } else {
      const activeFormGroups = this.formGroupsQueryList.filter(formGroup => formGroup.containsElement(targetEl));

      // If there is an active formGroup, update the id and notify host component.
      if (activeFormGroups.length) {
        // If there are multiple "active" form groups, this is presumably due to
        // nesting. Take the last from the set, assuming its the deepest child.
        const lastActiveFormGroup = activeFormGroups[activeFormGroups.length - 1];
        this._activeFormGroupId = lastActiveFormGroup.id;
        this.activeFormGroupIdChange.emit(this._activeFormGroupId);
        this.activeFormGroupChanged(lastActiveFormGroup);
      }
    }
  }

  /**
   * On changing active form group, update the active state
   * and adjust the position the floating menu(s) appropriately.
   *
   * @param  activeFormGroup   Reference of the current active form group directive.
   */
  activeFormGroupChanged(activeFormGroup: FormGroupDirective) {
    if (!activeFormGroup || !this.formGroupsQueryList) {
      return;
    }

    // Used to cache the floating menu associate with active form group.
    let activeFloatingMenu: FloatingFormMenuDirective;

    // Go through each form group element and update the active form group.
    this.formGroupsQueryList.map((formGroup: FormGroupDirective) => {
      formGroup.isActive = formGroup === activeFormGroup;

      // Find menu associate with current form group using section identifier if any.
      const floatingMenu = this.floatingMenuQueryList
        .find(menu => menu.section === formGroup.section);

      if (formGroup.isActive && floatingMenu) {
        // If there is a floating menu attached, set the floating position.
        if (formGroup.hasAttachedMenu) {
          const { top: activeFormGroupTop } = formGroup.getPosition();
          const { top: formContainerTop } =
            this.elementRef.nativeElement.getBoundingClientRect();

          floatingMenu.isHidden = false;

          // Set floating menu top position using current active form group position
          // minus form container offset.
          floatingMenu.top = activeFormGroupTop - formContainerTop;

          // Cache the active floating menu.
          activeFloatingMenu = floatingMenu;
        } else {
          floatingMenu.isHidden = true;
        }
      } else if (floatingMenu && floatingMenu !== activeFloatingMenu) {
        // If there is floating menu associate with a form group that is not active
        // and it is not the same active floating menu already associated with
        // another active form group, hide it.
        floatingMenu.isHidden = true;
      }
    });
  }
}
