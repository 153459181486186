import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AttributeFilter, FiltersServiceApi } from '@cohesity/api/reporting';
import { FiltersComponent, ValueFilterSelection } from '@cohesity/helix';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseReportFilterComponent } from '../base-report-filter.component';

@Component({
  selector: 'iris-rpt-protection-group-filter',
  templateUrl: './protection-group-filter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProtectionGroupFilterComponent extends BaseReportFilterComponent {

  constructor(filters: FiltersComponent, private api: FiltersServiceApi) {
    super(filters, 'groupId');
  }

  getFilterValues(): Observable<ValueFilterSelection[]> {
    return this.api.GetResources({ resourceType: 'ProtectionGroups' }).pipe(
      map(response => response?.protectionGroups || []),
      map(protectionGroups =>
        protectionGroups.map(group => ({
          label: this.getLabelWithSystemName(group.name, group.systemName),
          value: group.id,
        }))
      )
    );
  }

  toApiValue(filterValues: ValueFilterSelection[]): AttributeFilter {
    if (!filterValues?.length) {
      return null;
    }
    return {
      attribute: this.property,
      filterType: 'In',
      inFilterParams: {
        attributeDataType: 'String',
        stringFilterValues: filterValues.map(value => value.value as string),
        attributeLabels: filterValues.map(value => value.label),
      }
    };
  }

  fromApiValue(apiValue: AttributeFilter): ValueFilterSelection[] {
    return (apiValue?.inFilterParams.stringFilterValues || []).map((value, index) => ({
      label: apiValue.inFilterParams.attributeLabels[index],
      value: value,
    }));
  }
}
