import { Component, Input, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { SeriesPieOptions } from 'highcharts';

import { HighchartsComponent } from '../core/index';

/**
 * @description
 * Angular Component wrapper for Highcharts donut pie Chart.
 *
 * @example
 *  <cog-donut-chart
 *    [seriesData]="series">
 *  </cog-donut-chart>`
 */
@Component({
  selector: 'cog-donut-chart',
  templateUrl: './donut-chart.component.html',
  styleUrls: ['./donut-chart.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DonutChartComponent extends HighchartsComponent<SeriesPieOptions> implements AfterViewInit {

  // If non-default chart colors are desired, provide a colorSetClass for an
  // existing colorSet or define a new set in the stylesheet.
  @Input() colorSetClass: string;

  // The title for the chart
  @Input() title: string;

  // Optional units for display in the donut hole.
  @Input() units: string;

  /**
   * Donut series data.
   */
  protected _seriesData: SeriesPieOptions[] = [];

  /**
   * Set donut series data and render chart.
   */
  @Input() set seriesData(donutSeriesData: SeriesPieOptions[]) {
    this.clearSeries();
    this._seriesData = donutSeriesData;

    this.chartOptions.series = this._seriesData;
    this.render();
  }

  /**
   * Returns donut series data.
   */
  get seriesData(): SeriesPieOptions[] {
    return this._seriesData;
  }

  constructor() {
    super({
      chart: {
        type: 'pie',
        margin: [-10, -10, -10, -10],
        spacing: [0, 0, 0, 0],
        slicedOffset: 0,
        backgroundColor: 'transparent',
        styledMode: true,
      },
      title: null,
      credits: {
        enabled: false,
      },
      plotOptions: {
        pie: {
          allowPointSelect: false,
          dataLabels: {
            enabled: false,
          },
          size: '100%',
          innerSize: '85%',
          showInLegend: false,
        },
      },
      tooltip: {
        useHTML: true,
        formatter: function FormatDonutChartTooltip() {
          return `${this.key}: <strong>${new Intl.NumberFormat().format(this.y)}</strong>`;
        },
        backgroundColor: 'rgba(255, 255, 255, 1)',
      },
      series: [],
    });
  }

  /**
   *  Renders the donut chart.
   */
  render() {
    const { _chart, chartOptions } = this;
    if (_chart) {
      // Set the size of each series so that the inner series has smaller size.
      chartOptions?.series?.forEach((series: SeriesPieOptions, index: number) => {
        series.size = `${100 - (index * 15)}%`;
      });

      // Adjust the innerSize based on the number of series being
      // displayed so that each can have 15% of the donuts width/size.
      _chart.options.plotOptions.pie.innerSize = `${100 - (this._seriesData?.length * 15)}%`;
    }

    super.render();
  }

  ngAfterViewInit() {
    // If the chart has no title, re-render so the chart will fill up all available space
    // and will still show the animation. NOTE: Firing a window resize event would achieve
    // the same layout update but would kill highchart animations.
    if (!this.title) {
      this.render();
    }
  }

}
