import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PhysicalVolume } from '@cohesity/api/v1';

@Component({
  selector: 'coh-volume-information',
  templateUrl: './volume-information.component.html',
  styleUrls: ['../physical-popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VolumeInformationComponent {
  /**
   * Access to the volumes of the node.
   */
  @Input() volumes: PhysicalVolume[];

  /**
   * Label info to be displayed on UI.
   */
  @Input() label: string;
}
