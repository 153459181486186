// Component: Data migration file up-tiering Component

;(function(angular, undefined) {
  'use strict';

  var configOptions = {
    bindings: {
      /**
       * The job ID.
       *
       * @type   {number}
       */
      jobId: '=',
    },
    require: {
      cModalHoc: '^^?cModalHoc',
    },
    controller: 'FileUpTieringCtrl',
    templateUrl: 'app/protection/jobs/modify2/file-up-tiering/file-up-tiering.html',
  };

  angular.module('C.jobModify')
    .controller('FileUpTieringCtrl', fileUpTieringCtrlFn)
    .component('fileUpTiering', configOptions);

  function fileUpTieringCtrlFn(_, $q, evalAJAX, $filter, cUtils, FORMATS, PubJobService) {
    var $ctrl = this;

    _.assign($ctrl, {
      FORMATS: FORMATS,

      // UI states.
      isLoading: false,
      submitting: false,
      upTieringJob: null,

      // Component methods.
      save: save,

      // Component life-cycle methods.
      $onInit: $onInit,
    });

    /**
     * Activate the controller
     *
     * @method   $onInit
     */
    function $onInit() {
      // overriding the modal _onClose & cancel method to return selected value
      // when modal is closed or cancelled.
      _.assign($ctrl.cModalHoc, {
        onModalSave: save,
        onModalCancel: cancel,
        onModalClose: cancel,
      });

      getData();
    }

    /**
     * Fetch job details.
     *
     * @method   getData
     */
    function getData() {
      $ctrl.isLoading = true;
      PubJobService.getJob($ctrl.jobId, undefined, { onlyReturnDataMigrationJobs: true })
        .then(function gotData(tieringJob) {
          initTieringJob(tieringJob);
        })
        .catch(evalAJAX.errorMessage)
        .finally(function onFinally() {
          $ctrl.isLoading = false;
        });
    }

    /**
     * create or update the up-tiering data migration job.
     *
     * @method   save
     */
    function save() {
      var actionFn = $ctrl.upTieringJob.id ? PubJobService.updateJob : PubJobService.createJob;

      $ctrl.submitting = true;
      return actionFn(getUpTieringJobParams($ctrl.upTieringJob))
        .catch(evalAJAX.errorMessage)
        .finally(function onFinally() {
          $ctrl.submitting = false;
        });
    }

    /**
     * handles on cancel action for modal
     *
     * @method   cancel
     */
    function cancel() {
      return $q.resolve();
    }

    /**
     * Prepare the up-tiering payload.
     *
     * @method   getUpTieringJobParams
     */
    function getUpTieringJobParams(upTieringJob) {
      let out = cUtils.simpleCopy(upTieringJob);
      let dataUptierJobParameters = {
        ...out._envParams.dataMigrationJobParameters,

        // swapping the params from dataMigrationJobParameters to dataUptierJobParameters
        // because file-stubbing-settings component is compatible dataMigrationJobParameters.
        sourceViewName: out._envParams.dataMigrationJobParameters.targetViewName,
        hotFileWindow: out._envParams.dataMigrationJobParameters.coldFileWindow,
        coldFileWindow: undefined,
        targetViewName: undefined,

        // file size policy doesn't works with up-tiering jobs.
        fileSizePolicy: undefined,
        fileSizeBytes: undefined,
      };

      if (_.isEmpty(out._envParams.filePathFilters.protectFilters)) {
        delete out._envParams.filePathFilters.protectFilters;
      }

      if (_.isEmpty(out._envParams.filePathFilters.excludeFilters)) {
        delete out._envParams.filePathFilters.excludeFilters;
      }

      if (_.isEmpty(out._envParams.filePathFilters)) {
        delete out._envParams.filePathFilters;
      }

      out._envParams.dataMigrationJobParameters = null;
      out._envParams.dataUptierJobParameters = dataUptierJobParameters;
      return out;
    }

    /**
     * Initialize the tiering job.
     *
     * @method   initTieringJob
     */
    function initTieringJob(tieringJob) {
      var upTieringJob = cleanClone(tieringJob);

      if (upTieringJob._isDownTieringJob) {
        // use down-tiering job to construct ui-tiering job.
        upTieringJob.name = [
          tieringJob.name,
          'uptiering',
          $filter('usecsToDate')(Date.clusterNow() * 1000)
        ].join('-')
          // replaces commas with spaces
          .replace(/,/g, '')
          // replaces : and spaces with _
          .replace(/[\s]+|:/g, '_');

        upTieringJob.environmentParameters = { nasParameters: {} };
      } else if (upTieringJob._isUpTieringJob) {
        upTieringJob._envParams = {
          ...upTieringJob._envParams,
          dataUptierJobParameters: undefined,
          dataMigrationJobParameters: {
            ...upTieringJob._envParams.dataUptierJobParameters,

            // swapping the params from dataUptierJobParameters to dataMigrationJobParameters
            // because file-stubbing-settings component is compatible dataMigrationJobParameters.
            targetViewName: upTieringJob._envParams.dataUptierJobParameters.sourceViewName,
            coldFileWindow: upTieringJob._envParams.dataUptierJobParameters.hotFileWindow,
            sourceViewName: undefined,
            hotFileWindow: undefined,
          },
        };
      }

      upTieringJob._envParams.filePathFilters = {
        protectFilters: _.get(upTieringJob._envParams, 'filePathFilters.protectFilters', []),
        excludeFilters: _.get(upTieringJob._envParams, 'filePathFilters.excludeFilters', []),
      };

      $ctrl.upTieringJob = upTieringJob;
    }

    /**
     * Perform the clean cloning of the tiering job.
     *
     * @method   cleanClone
     */
    function cleanClone(tieringJob) {
      const keysToSkip = tieringJob._isDownTieringJob ? [
        'createRemoteView',
        'creationTimeUsecs',
        'dedupDisabledSourceIds',
        'excludeSourceIds',
        'excludeVmTagIds',
        'id',
        'indexingPolicy',
        'isActive',
        'isDeleted',
        'isNativeFormat',
        'isPaused',
        'modificationTimeUsecs',
        'modifiedByUser',
        'sourceSpecialParameters',
        'startTime',
        'uid',
        'vmTagIds',
      ] : [];
      const includedSpecialKeys = [
        '_envParams',
        '_isDataMigrationJob',
        '_isDownTieringJob',
        '_isUpTieringJob',
      ];

      return JSON.parse(JSON.stringify(tieringJob, function replacer(key, value) {
        if (((/^_\w*/g.exec(key)) !== null && includedSpecialKeys.indexOf(key) === -1) ||
          keysToSkip.indexOf(key) !== -1) {
          return undefined;
        }
        return value;
      }));
    }
  }

})(angular);
