<cog-settings-list-item cogFormGroup *ngIf="protectionGroup$ | async as protectionGroup" cogDataId="replication-form"
  [invalid]="formGroup.invalid">
  <cog-settings-list-item-value-display>
    <coh-group-action-target-info type="replication" [scheduled]="formGroup.enabled">
      <ng-container *ngTemplateOutlet="subtitleInfo; context: { formGroup: formGroup }"></ng-container>
    </coh-group-action-target-info>
  </cog-settings-list-item-value-display>
  <cog-settings-list-item-value-editor>
    <fieldset class="target-form" [formGroup]="formGroup">
      <coh-group-action-target-info type="replication" [scheduled]="formGroup.enabled">
        <ng-container *ngTemplateOutlet="subtitleInfo; context: { formGroup: formGroup }"></ng-container>
      </coh-group-action-target-info>
      <mat-slide-toggle formControlName="enabled" cogDataId="enable-replication">
        {{ 'runReplication' | translate }}
      </mat-slide-toggle>
      <div *ngIf="formGroup.enabled" class="indent-controls">
        <div class="flex-controls">
          <mat-form-field>
            <mat-label>{{'replicateTo' | translate}}</mat-label>
            <mat-select formControlName="type" cogDataId="select-type">
              <mat-option *ngFor="let type of targetTypes" [cogDataId]="type.label" [value]="type.key"
                [inheritDataId]="true">
                {{type.label | translate}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <coh-time-period-selector cogDataId="replicate-retention" [label]="'retainFor' | translate"
            [form]="formGroup.retentionForm">
          </coh-time-period-selector>
        </div>
        <ng-container [ngSwitch]="formGroup.type">
          <coh-replication-target-selector *ngSwitchCase="'RemoteCluster'" cogDataId="replication-target"
            formControlName="target">
          </coh-replication-target-selector>
          <coh-parent-source-selector *ngSwitchCase="'AWS'" formControlName="source" [label]="'source' | translate"
            [environments]="['kAWS']" [allowedEnvTypes]="['kAWS']"
            [selectedSourceId]="formGroup.awsTargetConfig?.sourceId" [autoOpen]="false">
          </coh-parent-source-selector>
        </ng-container>
      </div>
    </fieldset>
  </cog-settings-list-item-value-editor>
</cog-settings-list-item>

<ng-template #subtitleInfo let-formGroup="formGroup">
  <ul class="subtitle-list">
    <ng-container [ngSwitch]="formGroup.enabled">
      <ng-container *ngSwitchCase="true">
        <li *ngIf="formGroup.targetName">
          {{ formGroup.targetName }}
        </li>
        <li *ngIf="formGroup.retentionUnit && formGroup.retentionDuration">
          {{ 'policySynopsis.retention.' + formGroup.retentionUnit | translate: {
          duration: formGroup.retentionDuration
          } }}
        </li>
        <li *ngIf="(dataLockConfig$ | async) as dataLockConfig">
          {{ 'policySynopsis.datalock.' + dataLockConfig.unit | translate: {
            duration: dataLockConfig.duration
          } }}
        </li>
      </ng-container>
      <ng-container *ngSwitchCase="false">
        <span translate>replicationWillNotRun</span>
      </ng-container>
    </ng-container>
  </ul>
</ng-template>
