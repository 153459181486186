import { BifrostConnection } from '@cohesity/api/v2';
import { decorateInterface } from '@cohesity/utils';


/**
 * enum for bifrost connection health
 */
export enum BifrostConnectionHealth {
  /**
   * healthy or connected state where connection
   * has at least 1 connector
   */
  connected = 'connected',
  /**
   * unhealthy or not connected state where connection has 0
   * connectors
   */
  notConnected = 'notConnected',
  /**
   * unknown is for all other cases
   */
  unknown = 'unknown'
}

/**
 * Augmented bifrost connection model with UI specific properties as well
 */
export class DecoratedBifrostConnection extends decorateInterface<BifrostConnection>() {

  /**
   * Health status of a connections
   */
  health: BifrostConnectionHealth;

  /**
   * Health icon of a connection
   */
  icon: string;

  /**
   * True if this connection is default connection for a particular tenant
   */
  isDefault?: boolean;

  constructor(connection: BifrostConnection) {
    super(connection);

    switch (connection.connectors?.length) {
      case 0:
        this.health = BifrostConnectionHealth.notConnected;
        this.icon = 'helix:status-error!critical';
        break;

      default:
        this.health = BifrostConnectionHealth.connected;
        this.icon = 'helix:status-success!success';
        break;
    }

    this.isDefault = this.name?.includes('default_realm_for');

    this.name = this.isDefault ? `defaultConnection` : this.name;
  }
}
