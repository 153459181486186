<cog-slicer>
  <mat-button-toggle-group
    class="cog-chip-toggles"
    cogDataId="snapshot-selector-view-switcher"
    (change)="onToggleGroupChange($event)"
    [class.timeline-default]="defaultViewSwitcherView === 'timeline'"
    [value]="view">
    <mat-button-toggle value="list" cogDataId="view-list" [inheritDataId]="true">
      {{ 'list' | translate }}
    </mat-button-toggle>

    <mat-button-toggle value="timeline" cogDataId="view-timeline" [inheritDataId]="true">
      {{ 'timeline' | translate }}
    </mat-button-toggle>
  </mat-button-toggle-group>
</cog-slicer>
