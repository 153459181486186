import { CommonModule } from '@angular/common';
import { Component, ChangeDetectionStrategy, Input, EventEmitter, Output } from '@angular/core';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { CohesityHelixModule } from '@cohesity/helix';
import { TranslateModule } from '@ngx-translate/core';

import { AuthType } from '../../models';

/**
 * This auth mode toggle component handles the display
 * logic to present the available auth modes, starting
 * with the default auth mode to the user
 * and allows the user to toggle between them.  It emits
 * an event when the user selects a different auth mode.
 *
 * <coh-auth-mode-toggle
 *  [defaultAuthMode]="defaultAuthMode"
 *  [currentAuthMode]="currentAuthMode"
 *  [authModes]="authModes"
 *  (authModeChanged)="handleAuthModeChanged($event)"
 *  ></coh-auth-mode-toggle>
 */
@Component({
  selector: 'coh-auth-mode-toggle',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './auth-mode-toggle.component.html',
  styleUrls: ['./auth-mode-toggle.component.scss'],
  imports: [
    CommonModule,
    CohesityHelixModule,
    TranslateModule,
  ],
})
export class AuthModeToggleComponent {

  /**
   * The currently configured auth mode.
   * This can be the defaultAuthMode at first,
   * but as the user toggles, it changes to
   * what was selected.
   */
  @Input()
  currentAuthMode: AuthType;

  /**
   * The authModes is used to configure
   * the component with the appropriate toggles to allow
   * the user to switch between different authentication modes.
   */
  @Input()
  authModes: AuthType[];

  /**
   * The authentication mode being presented
   * to the user has been changed by the user.
   */
  @Output()
  authModeChanged = new EventEmitter<AuthType>();

  /**
   * This method is called when the user
   * changes the authMode by toggling one of the buttons.
   *
   * @param authType The new auth type to switch to.
   */
  changeAuthMode(authType: AuthType) {
    if (this.currentAuthMode !== authType) {
      this.currentAuthMode = authType;
      this.authModeChanged.emit(this.currentAuthMode);
    }
  }
}
