<div mat-dialog-title translate>
  vaultingWindow
  <h3 class="subheader">
    {{ data.vault.vaultName }}
  </h3>
</div>
<form cohValidateOnSubmit [formGroup]="formGroup"
  (validatedSubmit)="onSubmit()">
  <mat-dialog-content class="mat-typography">
    <mat-slide-toggle formControlName="enabled"
      cogDataId="toggle-backup-window">
      {{ 'vaultingWindow' | translate }}
    </mat-slide-toggle>
    <ng-container *ngIf="formGroup.get('enabled').value">
      <ng-container *ngFor="let scheduleSubForm of scheduleForm.controls; index as i">
        <form [formGroup]="scheduleSubForm" class="schedule-form">
          <div class="schedule-container">
            <coh-schedule-selector [hidePeriodicitySelector]="true"
              label="{{'backupOn' | translate}}"
              [form]="scheduleSubForm.get('weekDays')"
              cogDataId="week-select-{{ i }}"
              [inheritDataId]="true">
            </coh-schedule-selector>
            <div class="time-picker">
              <coh-time-picker formControlName="startTime"
                label="{{ 'startTime' | translate }}"
                cogDataId="start-time-{{ i }}"
                [inheritDataId]="true">
              </coh-time-picker>
              <mat-error *ngIf="scheduleSubForm.errors?.invalidTime">
                {{ 'rpaas.backupWindow.invalidTimeError' | translate }}
              </mat-error>
            </div>
            <coh-time-picker formControlName="endTime"
              class="time-picker"
              label="{{ 'endTime' | translate }}"
              cogDataId="end-time-{{ i }}"
              [inheritDataId]="true">
            </coh-time-picker>
            <button mat-icon-button
              color="primary"
              (click)="removeScheduleAt(i)"
              cogDataId="remove-schedule-{{ i }}"
              type="button"
              [disabled]="scheduleForm.controls.length <= 1">
              <cog-icon shape="remove_circle_outline"></cog-icon>
            </button>
            <button mat-icon-button
              color="primary"
              (click)="addSchedule()"
              cogDataId="add-schedule-{{ i }}"
              type="button">
              <cog-icon shape="add_circle_outline"></cog-icon>
            </button>
          </div>
        </form>
      </ng-container>
    </ng-container>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button cogCancelButton
      mat-button
      mat-dialog-close
      cogDataId="cancel-btn">
      {{ 'cancel' | translate }}
    </button>
    <button cogSubmitButton
      mat-flat-button
      type="submit"
      color="primary"
      cdkFocusInitial
      [loading]="busy$ | async"
      [disabled]="formGroup.invalid || (busy$ | async)"
      cogDataId="save-btn">
      {{ 'save' | translate }}
    </button>
  </mat-dialog-actions>
</form>
