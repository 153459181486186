<cog-page class="cog-takeover-theme" pageWidth="md">
  <cog-page-header>
    <cog-page-toolbar
      [tallHeader]="true"
      [title]="(isEditMode ? 'editRule' : 'createRule') | translate"
      (back)="backClick.emit()"
      [backTooltip]="'goBack' | translate">
    </cog-page-toolbar>
  </cog-page-header>
  <cog-page-content>
    <form
      [formGroup]="formGroup"
      cogFormFocusedHighlight
      cogDataId="security-rule-modify"
      (submit)="submitSecurityRule()">
      <mat-card cogFormGroup section="securityRuleModify"
        cogDataId="security-rule-name">
        <div class="flex-row">
          <mat-form-field class="col-xs-6">
            <mat-label for="input-rule-name">
              {{ 'ruleName' | translate }}
            </mat-label>
            <input
              matInput
              cogDataId="security-rule-name-input"
              [inheritDataId]="true"
              [placeholder]="'untitled' | translate"
              formControlName="name"/>
            <mat-error *ngIf="formControlNames?.name?.required">
              {{ 'errors.required' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
      </mat-card>
      <mat-card
        class="security-criteria-list"
        cogFormGroup
        section="securityRuleModify"
        cogDataId="security-criteria-list"
        [inheritDataId]="true">
        <span class="security-rule-definition">{{ 'definition' | translate }}</span>
        <dg-pa-security-criteria-select-list-component
          category="accessControl"
          [formControlName]="formControlNames.accessControlList">
        </dg-pa-security-criteria-select-list-component>
        <dg-pa-security-criteria-select-list-component
          category="configuration"
          [formControlName]="formControlNames.configurationList">
        </dg-pa-security-criteria-select-list-component>
        <dg-pa-security-criteria-select-list-component
          category="smartFiles"
          [formControlName]="formControlNames.smartFilesList">
        </dg-pa-security-criteria-select-list-component>
      </mat-card>
      <div class="margin-top-xl">
        <button cogDataId="save-security-rule-button"
          type="submit"
          cogSubmitButton
          [loading]="submitting"
          mat-flat-button color="primary">
          {{ 'save' | translate }}
        </button>
        <button
          cogDataId="cancel-security-rule-button"
          cogCancelButton
          mat-button
          (click)="backClick.emit()">
          {{ 'cancel' | translate }}
        </button>
      </div>
    </form>
  </cog-page-content>
</cog-page>
