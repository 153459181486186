import { Injectable } from '@angular/core';
import { ReplicationTarget } from '@cohesity/api/v1';
import { RemoteCluster, RemoteClustersServiceApi } from '@cohesity/api/v2';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DialogService, PassthroughOptionsService } from 'src/app/core/services';
import { AjsUpgradeService } from 'src/app/core/services/ajs-upgrade.service';

@Injectable({
  providedIn: 'root',
})
export class ReplicationTargetSelectorService {
  /**
   * Instance of legacy Ajs RemoteClusterService
   */
  private _ajsRemoteClusterService: any;

  /**
   * The list of shared clusters shared among multiple components
   */
  readonly sharedClusters$ = new BehaviorSubject<RemoteCluster[]>([]);

  /**
   * Creates a new service
   *
   * @param   remoteClusterService        The remote clusters api service
   * @param   translate                    Translate Service
   */
  constructor(
    private dialogService: DialogService,
    private irisContextService: IrisContextService,
    private remoteClusterService: RemoteClustersServiceApi,
    private passthroughOptionsService: PassthroughOptionsService,
    ajsUpgrade: AjsUpgradeService
  ) {
    this._ajsRemoteClusterService = ajsUpgrade.get('RemoteClusterService');
  }

  /**
   * Gets the modal to add new cluster and adds the new cluster to the list of
   * shared clusters
   *
   * @param   viewBoxId   Viewbox id.
   * @param   clusterId   Source cluster ID. (optional)
   */
  registerNew(viewBoxId, clusterId?) {
    // Enable the new register remote cluster workflow when flag is enabled.
    let registerRemoteCluster: Observable<RemoteCluster>;

    if (flagEnabled(this.irisContextService.irisContext, 'ngRemoteClusters')) {
      registerRemoteCluster = this.dialogService.showDialog('register-remote-cluster-dialog', { accessClusterId: clusterId });
    } else {
      registerRemoteCluster = from(this._ajsRemoteClusterService.registerRemoteSlider(undefined, 'from-takeover'));
    }
    return registerRemoteCluster?.pipe(
      map((newCluster: RemoteCluster) => {
        if (!newCluster || !newCluster.purpose.includes('Replication')) {
          return;
        }
        const decoratedNewCluster =
          this.decorateClusters(newCluster, viewBoxId)[0];
        this.sharedClusters$.value.push(decoratedNewCluster);
        this.sharedClusters$.next(this.sharedClusters$.value);
        return decoratedNewCluster;
      })
    );
  }

  /**
   * Gets remote clusters and sets a stream of shared clusters.
   *
   * @param    viewBoxId    View box id
   * @param    clusterId    Cluster id
   * @param    tenantIds    Tenant ids
   */
  fetchData(viewBoxId, tenantIds?) {
    this.remoteClusterService
      .GetRemoteClusters({
        purpose: ['Replication'],
        accessClusterId: this.passthroughOptionsService.accessClusterId,
      }).pipe(
        map(clusters => this.decorateClusters(clusters.remoteClusters, viewBoxId))
      ).subscribe(
        (clusters: RemoteCluster[]) => {
          if (tenantIds?.length) {

            // Show a remote cluster if it doesn't belong to a tenant already,
            // or if it belongs to a tenant and the policy is being assigned to that
            // tenant. If a policy is being assigned to multiple tenants, don't
            // show clusters that were registered by tenants since a remote cluster
            // can only belong to one tenant
            clusters = clusters.filter(cluster => !cluster.tenantId ||
              tenantIds.length === 1 ? cluster.tenantId === tenantIds[0] : false);
          }

          return this.sharedClusters$.next(clusters);
        }
      );
  }

  /**
   * Decorates all the clusters with target information and compatible viewbox
   * pairing info.
   *
   * @param   clusters   The list of remote clusters
   * @param   viewBoxId   The view box id of the RPO Policy
   */
  decorateClusters(clusters, viewBoxId) {
    clusters = [].concat(clusters);
    clusters.forEach(cluster => {
      // Decorating the clusters with ReplicationTarget properties
      cluster._target = {
        clusterId: cluster.clusterId,
        clusterName: cluster.clusterName,
      } as ReplicationTarget;

      // Check for viewBox pairing and disable the targets which have no
      // pairing with the replicated cluster.
      if (viewBoxId && cluster.replicationParams?.storageDomainPairs) {
        cluster._hasCompatibleViewBoxPairing =
          cluster.replicationParams?.storageDomainPairs.some(viewBox => viewBoxId === viewBox.localStorageDomainId);
      } else if (cluster.clusterName === 'registerRemoteCluster') {
        cluster._hasCompatibleViewBoxPairing = true;
      }
    });

    return clusters;
  }
}
