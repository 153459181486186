import { Directive, ElementRef, Injector, Input } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

@Directive({
  selector: 'ajs-context-menu'
})
export class ContextMenuComponent extends UpgradeComponent {
  @Input() items: Object[];
  constructor(injector: Injector, elementRef: ElementRef) {
    super('cContextMenu', elementRef, injector);
  }
}
