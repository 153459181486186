/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityApiConfiguration as __Configuration } from '../cohesity-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { GetJobRunInfoResult } from '../models/get-job-run-info-result';
import { GetAllJobRunsResult } from '../models/get-all-job-runs-result';
import { GetObjectsDetailsResult } from '../models/get-objects-details-result';
@Injectable({
  providedIn: 'root',
})
class MonitoringService extends __BaseService {
  static readonly GetJobRunInfoPath = '/public/monitoring/jobRunInfo';
  static readonly GetAllJobRunsPath = '/public/monitoring/jobs';
  static readonly GetRunObjectsDetailsPath = '/public/monitoring/objectDetails';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * List info related to a job run.
   *
   * Returns the run details for the job run.
   * @param params The `MonitoringService.GetJobRunInfoParams` containing the following parameters:
   *
   * - `jobType`: Specifies the job type of the needed run.
   *
   * - `jobRunId`: Specifies the job run id of the needed run.
   *
   * - `jobId`: Specifies the job id of the needed run.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetJobRunInfoResponse(params: MonitoringService.GetJobRunInfoParams): __Observable<__StrictHttpResponse<GetJobRunInfoResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.jobType != null) __params = __params.set('jobType', params.jobType.toString());
    if (params.jobRunId != null) __params = __params.set('jobRunId', params.jobRunId.toString());
    if (params.jobId != null) __params = __params.set('jobId', params.jobId.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/monitoring/jobRunInfo`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetJobRunInfoResult>;
      })
    );
  }
  /**
   * List info related to a job run.
   *
   * Returns the run details for the job run.
   * @param params The `MonitoringService.GetJobRunInfoParams` containing the following parameters:
   *
   * - `jobType`: Specifies the job type of the needed run.
   *
   * - `jobRunId`: Specifies the job run id of the needed run.
   *
   * - `jobId`: Specifies the job id of the needed run.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetJobRunInfo(params: MonitoringService.GetJobRunInfoParams): __Observable<GetJobRunInfoResult> {
    return this.GetJobRunInfoResponse(params).pipe(
      __map(_r => _r.body as GetJobRunInfoResult)
    );
  }

  /**
   * List runs of all the jobs.
   *
   * Returns the job runs for the filters.
   *
   * Specifying parameters can alter the results that are returned.
   * @param params The `MonitoringService.GetAllJobRunsParams` containing the following parameters:
   *
   * - `startTimeMsecs`: Specifies the start time of the time range of interest.
   *
   * - `endTimeMsecs`: Specifies the end time of the time range of interest.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `pageSize`: Specifies the size of the page in case of pagination of response.
   *
   * - `page`: Specifies the page number in case of pagination of response.
   *
   * - `jobTypes`: Specifies the job types for which runs are needed.
   *
   * - `envTypes`: Specifies the environment types of the job.
   *   Supported environment types such as 'kView', 'kSQL', 'kVMware', etc.
   *   NOTE: 'kPuppeteer' refers to Cohesity's Remote Adapter.
   *   'kVMware' indicates the VMware Protection Source environment.
   *   'kHyperV' indicates the HyperV Protection Source environment.
   *   'kSQL' indicates the SQL Protection Source environment.
   *   'kView' indicates the View Protection Source environment.
   *   'kPuppeteer' indicates the Cohesity's Remote Adapter.
   *   'kPhysical' indicates the physical Protection Source environment.
   *   'kPure' indicates the Pure Storage Protection Source environment.
   *   'Nimble' indicates the Nimble Storage Protection Source environment.
   *   'kAzure' indicates the Microsoft's Azure Protection Source environment.
   *   'kNetapp' indicates the Netapp Protection Source environment.
   *   'kAgent' indicates the Agent Protection Source environment.
   *   'kGenericNas' indicates the Generic Network Attached Storage Protection
   *   Source environment.
   *   'kAcropolis' indicates the Acropolis Protection Source environment.
   *   'kPhsicalFiles' indicates the Physical Files Protection Source environment.
   *   'kIsilon' indicates the Dell EMC's Isilon Protection Source environment.
   *   'kGPFS' indicates IBM's GPFS Protection Source environment.
   *   'kKVM' indicates the KVM Protection Source environment.
   *   'kAWS' indicates the AWS Protection Source environment.
   *   'kExchange' indicates the Exchange Protection Source environment.
   *   'kHyperVVSS' indicates the HyperV VSS Protection Source
   *   environment.
   *   'kOracle' indicates the Oracle Protection Source environment.
   *   'kGCP' indicates the Google Cloud Platform Protection Source environment.
   *   'kFlashBlade' indicates the Flash Blade Protection Source environment.
   *   'kAWSNative' indicates the AWS Native Protection Source environment.
   *   'kO365' indicates the Office 365 Protection Source environment.
   *   'kO365Outlook' indicates Office 365 outlook Protection Source environment.
   *   'kHyperFlex' indicates the Hyper Flex Protection Source environment.
   *   'kGCPNative' indicates the GCP Native Protection Source environment.
   *   'kAzureNative' indicates the Azure Native Protection Source environment.
   *   'kKubernetes' indicates a Kubernetes Protection Source environment.
   *   'kElastifile' indicates Elastifile Protection Source environment.
   *   'kAD' indicates Active Directory Protection Source environment.
   *   'kRDSSnapshotManager' indicates AWS RDS Protection Source environment.
   *   'kCassandra' indicates Cassandra Protection Source environment.
   *   'kMongoDB' indicates MongoDB Protection Source environment.
   *   'kCouchbase' indicates Couchbase Protection Source environment.
   *   'kHdfs' indicates Hdfs Protection Source environment.
   *   'kHive' indicates Hive Protection Source environment.
   *   'kHBase' indicates HBase Protection Source environment.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetAllJobRunsResponse(params: MonitoringService.GetAllJobRunsParams): __Observable<__StrictHttpResponse<Array<GetAllJobRunsResult>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startTimeMsecs != null) __params = __params.set('startTimeMsecs', params.startTimeMsecs.toString());
    if (params.endTimeMsecs != null) __params = __params.set('endTimeMsecs', params.endTimeMsecs.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    (params.jobTypes || []).forEach(val => {if (val != null) __params = __params.append('jobTypes', val.toString())});
    (params.envTypes || []).forEach(val => {if (val != null) __params = __params.append('envTypes', val.toString())});
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/monitoring/jobs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<GetAllJobRunsResult>>;
      })
    );
  }
  /**
   * List runs of all the jobs.
   *
   * Returns the job runs for the filters.
   *
   * Specifying parameters can alter the results that are returned.
   * @param params The `MonitoringService.GetAllJobRunsParams` containing the following parameters:
   *
   * - `startTimeMsecs`: Specifies the start time of the time range of interest.
   *
   * - `endTimeMsecs`: Specifies the end time of the time range of interest.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `pageSize`: Specifies the size of the page in case of pagination of response.
   *
   * - `page`: Specifies the page number in case of pagination of response.
   *
   * - `jobTypes`: Specifies the job types for which runs are needed.
   *
   * - `envTypes`: Specifies the environment types of the job.
   *   Supported environment types such as 'kView', 'kSQL', 'kVMware', etc.
   *   NOTE: 'kPuppeteer' refers to Cohesity's Remote Adapter.
   *   'kVMware' indicates the VMware Protection Source environment.
   *   'kHyperV' indicates the HyperV Protection Source environment.
   *   'kSQL' indicates the SQL Protection Source environment.
   *   'kView' indicates the View Protection Source environment.
   *   'kPuppeteer' indicates the Cohesity's Remote Adapter.
   *   'kPhysical' indicates the physical Protection Source environment.
   *   'kPure' indicates the Pure Storage Protection Source environment.
   *   'Nimble' indicates the Nimble Storage Protection Source environment.
   *   'kAzure' indicates the Microsoft's Azure Protection Source environment.
   *   'kNetapp' indicates the Netapp Protection Source environment.
   *   'kAgent' indicates the Agent Protection Source environment.
   *   'kGenericNas' indicates the Generic Network Attached Storage Protection
   *   Source environment.
   *   'kAcropolis' indicates the Acropolis Protection Source environment.
   *   'kPhsicalFiles' indicates the Physical Files Protection Source environment.
   *   'kIsilon' indicates the Dell EMC's Isilon Protection Source environment.
   *   'kGPFS' indicates IBM's GPFS Protection Source environment.
   *   'kKVM' indicates the KVM Protection Source environment.
   *   'kAWS' indicates the AWS Protection Source environment.
   *   'kExchange' indicates the Exchange Protection Source environment.
   *   'kHyperVVSS' indicates the HyperV VSS Protection Source
   *   environment.
   *   'kOracle' indicates the Oracle Protection Source environment.
   *   'kGCP' indicates the Google Cloud Platform Protection Source environment.
   *   'kFlashBlade' indicates the Flash Blade Protection Source environment.
   *   'kAWSNative' indicates the AWS Native Protection Source environment.
   *   'kO365' indicates the Office 365 Protection Source environment.
   *   'kO365Outlook' indicates Office 365 outlook Protection Source environment.
   *   'kHyperFlex' indicates the Hyper Flex Protection Source environment.
   *   'kGCPNative' indicates the GCP Native Protection Source environment.
   *   'kAzureNative' indicates the Azure Native Protection Source environment.
   *   'kKubernetes' indicates a Kubernetes Protection Source environment.
   *   'kElastifile' indicates Elastifile Protection Source environment.
   *   'kAD' indicates Active Directory Protection Source environment.
   *   'kRDSSnapshotManager' indicates AWS RDS Protection Source environment.
   *   'kCassandra' indicates Cassandra Protection Source environment.
   *   'kMongoDB' indicates MongoDB Protection Source environment.
   *   'kCouchbase' indicates Couchbase Protection Source environment.
   *   'kHdfs' indicates Hdfs Protection Source environment.
   *   'kHive' indicates Hive Protection Source environment.
   *   'kHBase' indicates HBase Protection Source environment.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetAllJobRuns(params: MonitoringService.GetAllJobRunsParams): __Observable<Array<GetAllJobRunsResult>> {
    return this.GetAllJobRunsResponse(params).pipe(
      __map(_r => _r.body as Array<GetAllJobRunsResult>)
    );
  }

  /**
   * List details of objects in a job run.
   *
   * Returns the objects details for the job run.
   * @param params The `MonitoringService.GetRunObjectsDetailsParams` containing the following parameters:
   *
   * - `jobType`: Specifies the job type of the needed run.
   *
   * - `jobRunId`: Specifies the job run id of the needed run.
   *
   * - `jobId`: Specifies the job id of the needed run.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetRunObjectsDetailsResponse(params: MonitoringService.GetRunObjectsDetailsParams): __Observable<__StrictHttpResponse<Array<GetObjectsDetailsResult>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.jobType != null) __params = __params.set('jobType', params.jobType.toString());
    if (params.jobRunId != null) __params = __params.set('jobRunId', params.jobRunId.toString());
    if (params.jobId != null) __params = __params.set('jobId', params.jobId.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/monitoring/objectDetails`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<GetObjectsDetailsResult>>;
      })
    );
  }
  /**
   * List details of objects in a job run.
   *
   * Returns the objects details for the job run.
   * @param params The `MonitoringService.GetRunObjectsDetailsParams` containing the following parameters:
   *
   * - `jobType`: Specifies the job type of the needed run.
   *
   * - `jobRunId`: Specifies the job run id of the needed run.
   *
   * - `jobId`: Specifies the job id of the needed run.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetRunObjectsDetails(params: MonitoringService.GetRunObjectsDetailsParams): __Observable<Array<GetObjectsDetailsResult>> {
    return this.GetRunObjectsDetailsResponse(params).pipe(
      __map(_r => _r.body as Array<GetObjectsDetailsResult>)
    );
  }
}

module MonitoringService {

  /**
   * Parameters for GetJobRunInfo
   */
  export interface GetJobRunInfoParams {

    /**
     * Specifies the job type of the needed run.
     */
    jobType: string;

    /**
     * Specifies the job run id of the needed run.
     */
    jobRunId: number;

    /**
     * Specifies the job id of the needed run.
     */
    jobId: number;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetAllJobRuns
   */
  export interface GetAllJobRunsParams {

    /**
     * Specifies the start time of the time range of interest.
     */
    startTimeMsecs: number;

    /**
     * Specifies the end time of the time range of interest.
     */
    endTimeMsecs: number;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Specifies the size of the page in case of pagination of response.
     */
    pageSize?: number;

    /**
     * Specifies the page number in case of pagination of response.
     */
    page?: number;

    /**
     * Specifies the job types for which runs are needed.
     */
    jobTypes?: Array<string>;

    /**
     * Specifies the environment types of the job.
     * Supported environment types such as 'kView', 'kSQL', 'kVMware', etc.
     * NOTE: 'kPuppeteer' refers to Cohesity's Remote Adapter.
     * 'kVMware' indicates the VMware Protection Source environment.
     * 'kHyperV' indicates the HyperV Protection Source environment.
     * 'kSQL' indicates the SQL Protection Source environment.
     * 'kView' indicates the View Protection Source environment.
     * 'kPuppeteer' indicates the Cohesity's Remote Adapter.
     * 'kPhysical' indicates the physical Protection Source environment.
     * 'kPure' indicates the Pure Storage Protection Source environment.
     * 'Nimble' indicates the Nimble Storage Protection Source environment.
     * 'kAzure' indicates the Microsoft's Azure Protection Source environment.
     * 'kNetapp' indicates the Netapp Protection Source environment.
     * 'kAgent' indicates the Agent Protection Source environment.
     * 'kGenericNas' indicates the Generic Network Attached Storage Protection
     * Source environment.
     * 'kAcropolis' indicates the Acropolis Protection Source environment.
     * 'kPhsicalFiles' indicates the Physical Files Protection Source environment.
     * 'kIsilon' indicates the Dell EMC's Isilon Protection Source environment.
     * 'kGPFS' indicates IBM's GPFS Protection Source environment.
     * 'kKVM' indicates the KVM Protection Source environment.
     * 'kAWS' indicates the AWS Protection Source environment.
     * 'kExchange' indicates the Exchange Protection Source environment.
     * 'kHyperVVSS' indicates the HyperV VSS Protection Source
     * environment.
     * 'kOracle' indicates the Oracle Protection Source environment.
     * 'kGCP' indicates the Google Cloud Platform Protection Source environment.
     * 'kFlashBlade' indicates the Flash Blade Protection Source environment.
     * 'kAWSNative' indicates the AWS Native Protection Source environment.
     * 'kO365' indicates the Office 365 Protection Source environment.
     * 'kO365Outlook' indicates Office 365 outlook Protection Source environment.
     * 'kHyperFlex' indicates the Hyper Flex Protection Source environment.
     * 'kGCPNative' indicates the GCP Native Protection Source environment.
     * 'kAzureNative' indicates the Azure Native Protection Source environment.
     * 'kKubernetes' indicates a Kubernetes Protection Source environment.
     * 'kElastifile' indicates Elastifile Protection Source environment.
     * 'kAD' indicates Active Directory Protection Source environment.
     * 'kRDSSnapshotManager' indicates AWS RDS Protection Source environment.
     * 'kCassandra' indicates Cassandra Protection Source environment.
     * 'kMongoDB' indicates MongoDB Protection Source environment.
     * 'kCouchbase' indicates Couchbase Protection Source environment.
     * 'kHdfs' indicates Hdfs Protection Source environment.
     * 'kHive' indicates Hive Protection Source environment.
     * 'kHBase' indicates HBase Protection Source environment.
     */
    envTypes?: Array<'kVMware' | 'kHyperV' | 'kSQL' | 'kView' | 'kPuppeteer' | 'kPhysical' | 'kPure' | 'kIbmFlashSystem' | 'kNimble' | 'kAzure' | 'kNetapp' | 'kAgent' | 'kGenericNas' | 'kAcropolis' | 'kPhysicalFiles' | 'kIsilon' | 'kGPFS' | 'kKVM' | 'kAWS' | 'kExchange' | 'kHyperVVSS' | 'kOracle' | 'kGCP' | 'kFlashBlade' | 'kAWSNative' | 'kO365' | 'kO365Outlook' | 'kHyperFlex' | 'kGCPNative' | 'kAzureNative' | 'kKubernetes' | 'kElastifile' | 'kAD' | 'kRDSSnapshotManager' | 'kCassandra' | 'kMongoDB' | 'kCouchbase' | 'kHdfs' | 'kHive' | 'kHBase' | 'kSAPHANA'>;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetRunObjectsDetails
   */
  export interface GetRunObjectsDetailsParams {

    /**
     * Specifies the job type of the needed run.
     */
    jobType: string;

    /**
     * Specifies the job run id of the needed run.
     */
    jobRunId: number;

    /**
     * Specifies the job id of the needed run.
     */
    jobId: number;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { MonitoringService }
