import { Injectable } from '@angular/core';
import { DmsServiceApi, GetTenantRegionsResponse } from '@cohesity/api/dms';
import { BANNER_PRIORITY, BannerConfig } from '@cohesity/helix';
import { BaseBannerConfig, IrisContext, IrisContextService, dmsTenantId } from '@cohesity/iris-core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { DialogService } from 'src/app/core/services';
import { HeliosTenantMigrationDialogComponent } from 'src/app/shared/dialogs/index';
import { TenantStatuses } from 'src/app/shared';

@Injectable({
  providedIn: 'root',
})
export class HeliosTenantMigrationBanner extends BaseBannerConfig {
  /**
   * Unique banner representation
   */
  id = 'ng-helios-tenant-migration-banner';

  /**
   * provides banner config as observable
   */
  bannerInfo$ = this.irisCtx.irisContext$.pipe(
    filter(() => false),
    switchMap(ctx => this.getTenantStatus(ctx)),
    map((regions: GetTenantRegionsResponse) => ([{
      id: this.id,
      priority: BANNER_PRIORITY.HIGH,
      status: 'info',
      isVisible: this.allowBanner(regions),
      allowClose: false,
      text: this.translateService.instant('helios.tenant.migration.inprogress'),
      actionText: this.translateService.instant('helios.tenant.migration.viewdetails'),
      actionCallback: () => {
        this.dialogService
          .showDialog(HeliosTenantMigrationDialogComponent, {
            title: 'helios.tenant.migration.dialog.header',
            description: 'helios.tenant.migration.dialog.details',
            subtitle: 'helios.tenant.migration.dialog.subheader'
          }).subscribe(() => {});
      },
    } as BannerConfig]))
  );

  constructor(
    private irisCtx: IrisContextService,
    private translateService: TranslateService,
    private dialogService: DialogService,
    private dmsService: DmsServiceApi,
  ) {
    super();
  }

  /**
   * Returns an observable that emits the current status of the Helios cluster.
   *
   * @param irisContext - The IrisContext object containing the current user's
   * authentication information and the Helios cluster URL.
   *
   * @returns An observable that emits the tenant statuses in different regions.
   */
  getTenantStatus(ctx: IrisContext): Observable<GetTenantRegionsResponse> {
    return this.dmsService.GetTenantRegions({
      tenantId: dmsTenantId(ctx),
    });
  }

  /**
   * Returns if the tenant is under migration.
   *
   * @param regions: The response from the call to the GetTenantRegions API
   * @returns true/false based on whether the tenant is under migration.
   */
  allowBanner(regions: GetTenantRegionsResponse): boolean {
    if (regions?.tenantRegionInfoList?.length) {
      return regions.tenantRegionInfoList.some(regionInfo =>
        regionInfo.status === TenantStatuses.TenantMigrationInProgress);
    }
    return false;
  }
}
