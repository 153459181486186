import { Pipe, PipeTransform } from '@angular/core';
import { Environment, ObjectType } from '@cohesity/api/argus';
import { SearchObject } from '@cohesity/api/v2';
import { ObjectTypeIconPipe } from '@cohesity/utils';

/**
 * Returns icon based on SearchObject data
 */
@Pipe({
  name: 'objectProtectionIcon',
})
export class ObjectProtectionIconPipe implements PipeTransform {
  constructor(private objectTypeIconPipe: ObjectTypeIconPipe) {}

  transform(object: SearchObject): string {
    const isProtected = object?.objectProtectionInfos?.some(
      info => info.protectionGroups?.length > 0 || info.objectBackupConfiguration?.length > 0
    );
    // reuse the icon pipe to get the object icon
    const objectIcon = this.objectTypeIconPipe.transform(
      Environment[object?.environment],
      ObjectType[object?.objectType]
    );

    if (!objectIcon) {
      // Fallback to a generic icon if everything else fails. This icon doesn't
      // support the state.
      return 'helix:object-generic';
    }

    // if object is protected, return the protected version of the icon.
    return isProtected ? `${objectIcon}-protected` : objectIcon;
  }
}
