import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormControl, Validators } from '@angular/forms';
import { Component as ReportComponent } from '@cohesity/api/reporting';
import { Controls, DataInput, NgxAutomaticRootFormComponent } from 'ngx-sub-form';

import { getColumnConfigs } from '../../../config';
import { CustomChartInput, TranslateFn } from '../../../iris-reporting.model';
import {
  AvailableDimension,
  AvailableMeasurement,
  CustomReportsService,
  ReportColumnConfigs,
} from '../../custom-reports.service';
import { BubbleChartItemComponent } from '../bubble-chart-item/bubble-chart-item.component';
import { ColumnChartItemComponent } from '../column-chart-item/column-chart-item.component';
import { DonutChartItemComponent } from '../donut-chart-item/donut-chart-item.component';
import { LineChartItemComponent } from '../line-chart-item/line-chart-item.component';
import { PieChartItemComponent } from '../pie-chart-item/pie-chart-item.component';
import { StackedColumnChartItemComponent } from '../stacked-column-chart-item/stacked-column-chart-item.component';

/**
 * These are the currently known chart types.
 */
export type ChartType = 'bubble' | 'column' | 'donut' | 'line' | 'pie' | 'stacked';

/**
 * The form type - this is the same as the CustomChartInput interface, with the
 * addition of the chart type.
 */
export interface CustomChartConfig extends CustomChartInput {
  chartType: ChartType;
}

/**
 * This is the form interface, the main difference is that the dimensions are separated
 * into xAxis and groupBy. The values also include the labels to use for the select options.
 */
interface CustomChartForm {
  measurements: AvailableMeasurement[];
  xAxis: AvailableDimension;
  groupBy: AvailableDimension;
  chartType: ChartType;
}

/**
 * This form presents options for choosing what measurements and dimensions to use for a
 * chart as well as the type of chart to display the data with.
 */
@Component({
  selector: 'iris-rpt-chart-config-form',
  templateUrl: './chart-config-form.component.html',
  styleUrls: ['./chart-config-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartConfigFormComponent extends NgxAutomaticRootFormComponent<CustomChartConfig, CustomChartForm>
  implements OnChanges {
  /**
   * This is the data's component configuration. This is passed sto the custom chart service, along with
   * the report type to determine what properties are available for the data we are working with.
   */
  @Input() component: ReportComponent;

  /**
   * Translate function.
   */
  @Input() translate: TranslateFn;

  /**
   * This is a list of available chart types along with methods that can be used to determine which
   * charts can be use to render a set of data. This could eventually be extracted to a service
   * or an injection token if needed.
   */
  chartConfig = [
    {
      label: 'bubbleChart',
      canRender: BubbleChartItemComponent.canRender,
      chartType: 'bubble',
    },
    {
      label: 'columnChart',
      canRender: ColumnChartItemComponent.canRender,
      chartType: 'column',
    },
    {
      label: 'donutChart',
      canRender: DonutChartItemComponent.canRender,
      chartType: 'donut',
    },
    {
      label: 'lineChart',
      canRender: LineChartItemComponent.canRender,
      chartType: 'line',
    },
    {
      label: 'pieChart',
      canRender: PieChartItemComponent.canRender,
      chartType: 'pie',
    },
    {
      label: 'stackedColumnChart',
      canRender: StackedColumnChartItemComponent.canRender,
      chartType: 'stacked',
    }
  ];

  /**
   * This is a subset of chartConfig, based on the currently selected
   * measurements and dimensions.
   */
  availableCharts: ChartConfigFormComponent['chartConfig'] = [];

  /**
   * This is a map of column to configuration data, which is retrieved based on the
   * component's report type.
   */
  columnConfig: ReportColumnConfigs;

  /**
   * This is the list of all available measurements that can be selected for the component.
   */
  availableMeasurements: AvailableMeasurement[];

  /**
   * This is the list of all available x axis dimensions that can be selected for the component.
   */
  availableAxisDimensions: AvailableDimension[];

  /**
   * This is the list of all available grouping dimensions that can be selected for the component.
   */
  availableGroupDimensions: AvailableDimension[];

  /**
   * The config form input
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @DataInput() @Input('chartConfig') dataInput: Required<CustomChartConfig> | null | undefined;

  /**
   * Fires whenever the user edits the chart config.
   */
  // eslint-disable-next-line @angular-eslint/no-output-rename
  @Output('chartConfigChange') dataOutput = new EventEmitter<CustomChartConfig>();

  constructor(private reportService: CustomReportsService) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.component && this.component) {
      this.columnConfig = getColumnConfigs(this.component.reportType);
      const attributes = this.reportService.getAttributeNamesFromComponent(
        this.component,
        Object.keys(this.columnConfig || {})
      );
      this.availableMeasurements = this.reportService.getAvailableMeasurements(attributes, this.columnConfig);
      this.availableAxisDimensions = this.reportService.getAvailableDimensions(attributes, false, this.columnConfig);
      this.availableGroupDimensions = this.reportService.getAvailableDimensions(attributes, true, this.columnConfig);
    }
  }

  /**
   * Convert to the form group value.
   *
   * @param obj The control value.
   * @returns The form group value.
   */
  protected transformToFormGroup(obj: CustomChartConfig | null): CustomChartForm | null {
    if (!obj) {
      return null;
    }
    const [xAxis, groupBy] = obj.dimensions;
    return {
      chartType: obj.chartType,
      measurements: obj.measurements.map(metric => ({
        label: (this.availableMeasurements || [])
          .find(({ measurement }) => measurement.valueKey === metric.valueKey)?.label,
        measurement: metric,
      })),
      xAxis: {
        label: (this.availableAxisDimensions || [])
          .find(({ dimension }) => dimension.dimensionKey === xAxis.dimensionKey)?.label,
        dimension: xAxis,
      },
      groupBy: groupBy ?
        {
          label: this.availableGroupDimensions?.find(
            ({ dimension }) => dimension.dimensionKey === groupBy.dimensionKey
          )?.label,
          dimension: groupBy,
        } : null,
    };
  }

  /**
   * Convert to the control value.
   *
   * @param formValue The form value.
   * @returns The control value.
   */
  protected transformFromFormGroup(formValue: CustomChartForm): CustomChartConfig | null {
    if (!formValue) {
      return null;
    }
    const dimensions = [formValue.xAxis.dimension];
    if (formValue.groupBy) {
      dimensions.push(formValue.groupBy.dimension);
    }
    return {
      measurements: formValue.measurements.map(({ measurement }) => measurement),
      chartType: formValue.chartType,
      dimensions,
    };
  }

  /**
   * Override writeValue to update the list of available charts when the value is first set.
   *
   * @param obj The new control value.
   */
  writeValue(obj: Required<CustomChartConfig> | null): void {
    super.writeValue(obj);
    this.availableCharts = this.getAvailableCharts();
  }

  /**
   * Handle updating the form group when a mat-select option is clicked. The mat-selects are not form controls
   * because their values are simply based on the labels. When one is clicked, we use the measurement or dimension
   * value associated with it to set the form group control.
   *
   * If the current chartType is not supported after the update, then automatically choose the first supported
   * chart type.
   *
   * @param control The form control to update
   * @param available The available dimensions or measurements
   * @param selectedLabel The label that was selected.
   */
  updateControl(
    control: AbstractControl,
    available: (AvailableDimension | AvailableMeasurement)[],
    selectedLabel: string
  ) {
    control.setValue(available.find(({ label }) => label === selectedLabel));
    this.availableCharts = this.getAvailableCharts();
    const canRender = !!this.availableCharts.find(chart => chart.chartType === this.formGroupValues.chartType);
    if (!canRender) {
      this.formGroupControls.chartType.setValue(this.availableCharts[0].chartType as ChartType);
    }
  }

  /**
   * Get the list of available charts based on form's value.
   */
  getAvailableCharts(): ChartConfigFormComponent['chartConfig'] {
    const value = this.transformFromFormGroup(this.formGroup.value);
    return this.chartConfig.filter(config =>
      config.canRender({ measurements: value.measurements, dimensions: value.dimensions })
    );
  }

  /**
   * Get the form controls needed for the sub form.
   *
   * @returns   The form controls object.
   */
  protected getFormControls(): Controls<CustomChartForm> {
    return {
      measurements: new UntypedFormArray([], Validators.required),
      xAxis: new UntypedFormControl(null, Validators.required),
      groupBy: new UntypedFormControl(null),
      chartType: new UntypedFormControl(null),
    };
  }
}
