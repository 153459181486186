import { BehaviorSubject, Observable } from 'rxjs';

import { BannerStatus } from '../banner';

/**
 * Default banner location
 */
export enum BannerLocation {
  top = 'top',
}

/**
 * Banner priority enums
 */
export enum BANNER_PRIORITY {
  HIGH = 10,
  DEFAULT = 5,
  LOW = 0,
}

/**
 * Custom action callback function
 */
export type ActionCallbackFn = () => void;

/**
 * Custom close callback function
 */
export type CloseCallbackFn = (id: string) => void;

/**
 * Secondary Button interface
 */
export interface SecondaryButton {
  actionText: string;
  actionCallback: ActionCallbackFn;
}

/**
 * A banner.
 */
export interface BannerConfig {
  /**
   * Unique ID string for ewach banners
   */
  id: string;

  /**
   * Holds boolean to show/hide banner
   */
  isVisible: boolean;

  /**
   * Status of banner
   */
  status: BannerStatus;

  /**
   * Priority to organize banners in order
   */
  priority: BANNER_PRIORITY;

  /**
   * Inner content of banner
   */
  text: string;

  /**
   * Title of the banner
   */
  title?: string;

  /**
   * Hover tooltip on banner content
   */
  tooltip?: string;

  /**
   * Banner button action label
   */
  actionText?: string;

  /**
   * Banner button action callback
   */
  actionCallback?: ActionCallbackFn;

  /**
   * Secondary action buttons list
   */
  secondaryButtons?: SecondaryButton[];

  /**
   * To show/hide close 'X' button of each banner
   */
  allowClose?: boolean;

  /**
   * Close button callback
   */
  closeCallback?: CloseCallbackFn;

  /**
   * Banner location. Eg. 'top'
   */
  location?: BannerLocation;
}

/**
 * Location based banner list container
 */
export interface Location {
  /**
   * Behaviour subject that holds list of banners
   */
  behaviorSubject: BehaviorSubject<BannerConfig[]>;

  /**
   * Holds list of banner as observable
   */
  observable: Observable<BannerConfig[]>;
}
