import { ProtectionSourceNode, UdaObject } from '@cohesity/api/v1';
import { Environment, UdaObjectTypeIconMap } from 'src/app/shared/constants';

import { ProtectionSourceDataNode } from '../shared/protection-source-data-node';
import { HasMetadata, SourceNodeMetadata } from '../shared/protection-source-metadata/source-node-metadata';

/**
 * Represents an active UDA source node and job tree selection behavior.
 */
export class UdaSourceDataNode extends ProtectionSourceDataNode implements HasMetadata {

  /**
   * Stores the metadata for the source node.
   */
  readonly metadata: SourceNodeMetadata;

  /**
   * Stores the UDA objectInfo.
   */
  readonly objectInfo: UdaObject;

  constructor(data: ProtectionSourceNode, readonly level: number) {
    super(Environment.kUDA, data, level, []);
    const udaProtectionSource = data.protectionSource?.udaProtectionSource || null;
    this.objectInfo = udaProtectionSource ? udaProtectionSource.objectInfo : null;

    this.metadata = {
      logicalSize: this.logicalSize,
      leafCount: this.expandable ? this.leafCount : undefined,
      nodeIdentifierKey: Environment.kUDA + '.' + this.envSource.type
    };
  }

  /**
   * Whether the node is a leaf which can be directly selected or not.
   */
  get isLeaf() {
    return this.objectInfo ? this.objectInfo.isLeaf : true;
  }

  /**
   * Get the helix icon name to use for rendering the icon next to the tree node.
   */
  get icon(): string {


    // UDA passes is leaf info inside objectInfo property and not as Type,
    // type of each object in UDA is 'kObject'.
    const objectType = this.protectionSource?.udaProtectionSource?.objectInfo?.objectType || 'Database';
    // Defaults to database Icon
    const iconName = UdaObjectTypeIconMap[objectType] || 'helix:object-db';

    // Object nodes which are auto protected should show the auto protected icon too
    const suffix = (this.isAutoProtectedByObject || (!this.isLeaf && this.isObjectProtected)) ? '-auto' :
      this.protected ? '-protected' :
      this.partialProtected ? '-partial' : '';

    return iconName.concat(suffix);
  }
}
